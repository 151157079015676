import { AdvancedUserActivityTableColumn } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-table-column'

import {
  AdvancedUserActivityActions,
  AdvancedUserActivityActionTypes
} from './actions'
import { AdvancedUserActivityListState } from './state'

const defaultAdvancedUserActivityColumns: AdvancedUserActivityTableColumn[] = [
  AdvancedUserActivityTableColumn.eventType,
  AdvancedUserActivityTableColumn.eventInformation,
  AdvancedUserActivityTableColumn.user,
  AdvancedUserActivityTableColumn.timeUtc
]
export const initialAdvancedUserActivityListState: AdvancedUserActivityListState = {
  table: {
    columns: defaultAdvancedUserActivityColumns,
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  eventTypes: {
    userEvents: [],
    adminEvents: []
  },
  currentPageUserActivityOriginalEvents: [],
  filterForSave: undefined,
  savedReports: [],
  selectedSavedReport: undefined,
  drawerIsLoading: false
}

export function advancedUserActivityEvents(
  previousState: AdvancedUserActivityListState | undefined,
  action: AdvancedUserActivityActions
): AdvancedUserActivityListState {
  const state = previousState || initialAdvancedUserActivityListState

  switch (action.type) {
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_ACTIVITY:
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.events,
          totalResults: action.payload.totalResults
        }
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_ACTIVITY_LOADING:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: action.payload.isLoading
        }
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_CURRENT_PAGE_USER_ACTIVITY_ORIGINAL_EVENTS:
      return {
        ...state,
        currentPageUserActivityOriginalEvents: action.payload.originalEvents
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_FOR_EVENT:
      return {
        ...state,
        selectedUser: action.payload.selectedUser
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_FILTER_FOR_SAVE:
      return {
        ...state,
        filterForSave: action.payload.filterForSave
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_ACTIVITY_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.payload.eventTypes
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_SELECTED_SAVED_REPORT:
      return {
        ...state,
        selectedSavedReport: action.payload.selectedSavedReport
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_REMOVE_SELECTED_SAVED_REPORT:
      return {
        ...state,
        selectedSavedReport: undefined
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_SAVED_REPORTS:
      return {
        ...state,
        savedReports: action.payload.savedReports
      }
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_DRAWER_LOADING:
      return {
        ...state,
        drawerIsLoading: action.payload.isLoading
      }

    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_FOR_EVENT:
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_ACTIVITY:
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_ACTIVITY_EVENT_TYPES:
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_SAVED_REPORTS:
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_UPDATE_REPORT:
    case AdvancedUserActivityActionTypes.ADVANCED_REPORT_SAVE_REPORT:
    default: {
      return state
    }
  }
}

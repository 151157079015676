import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  Heading300,
  RowContainer,
  SingleColumnContentContainer
} from '@lastpass/lastkit'

import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { createDashboardContentComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/DashboardContent'
import { createRemindInactiveUsersBlockComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/RemindInactiveUsersBlock'
import { createUnresponsiveUsersComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/UnresponsiveUsers'
import { createUserActivityChartComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/UserActivityHistoryChart'
import { createUserAdoptionBoxComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/UserAdoptionBox'
import { createUserAdoptionHistoryChartComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/UserAdoptionHistoryChart'
import { UrlEncryptionMigrationOverviewCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/url-encryption-migration-overview/UrlEncryptionMigrationOverviewCard'
import { BlockHeader } from '@lastpass/admin-console-dependencies/ui/home/BlockHeader'

import { FeatureFlags, useFeatureFlags } from '../common/FeatureFlags'
import { UrlLoggingPolicyBanner } from '../policies/general/UrlLoggingPolicyBanner'

const Spacer = styled.div`
  margin-bottom: 24px;
`

const FirstColumn = styled.div`
  width: 500px;
`

const SecondColumn = styled.div`
  flex-grow: 1;
  margin-left: 24px;
`

const CompanyInfoBlock = styled.div`
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 32px;
`

const CompanyNameBlock = styled.strong`
  font-weight: 600;
`

const SecondColumnWithBorder = styled.div`
  border-top: 1px solid ${props => props.theme.colors.grayBorder};
  padding-top: 24px;
`

const UserAdoptionHistoryChart = createUserAdoptionHistoryChartComponent<
  AppState
>()

const UserActivityChart = createUserActivityChartComponent<AppState>()

const UnresponsiveUsersBox = createUnresponsiveUsersComponent<AppState>()

const RemindInactiveUsersBlock = createRemindInactiveUsersBlockComponent<
  AppState
>()

const UserAdoptionBox = createUserAdoptionBoxComponent<AppState>()
const DashboardContent = createDashboardContentComponent<AppState>()

export const DashboardMainContent: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const isUrlLoggingGracePeriodBannerEnabled = useFeatureFlags(
    FeatureFlags.isUrlLoggingGracePeriodBannerEnabled
  )
  const companyDetails = useSelector<AppState, CompanyDetails>(
    state => state.companyInfo.details
  )

  const isMigrateToVaultFormatVersionV2Enabled = useSelector(
    (state: AppState) =>
      state.dashboard.urlEncryptionMigrationState
        .isMigrateToVaultFormatVersionV2Enabled
  )

  useEffect(() => {
    dispatch(dashboardActions.getUrlEncryptionMigrationState())
  }, [dispatch])

  const {
    accountNumber,
    name: companyName,
    canBuyLicenses,
    isBillingUsageBased,
    parentCompanyId
  } = companyDetails
  const isChildCompany = parentCompanyId !== null

  return (
    <DashboardContent>
      <SingleColumnContentContainer
        isBannerDisplayed={isUrlLoggingGracePeriodBannerEnabled}
      >
        {isUrlLoggingGracePeriodBannerEnabled && (
          <div
            css={css`
              margin-bottom: 1.5rem;
            `}
          >
            <UrlLoggingPolicyBanner />
          </div>
        )}
        {isMigrateToVaultFormatVersionV2Enabled && (
          <RowContainer>
            <UrlEncryptionMigrationOverviewCard />
          </RowContainer>
        )}
        <RowContainer>
          <Heading300 data-qa={'DashboardHeader'}>
            <Trans>Dashboard</Trans>
          </Heading300>
        </RowContainer>
        <CompanyInfoBlock data-qa={'CompanyInfo'}>
          <CompanyNameBlock data-qa={'CompanyName'}>
            {companyName}
          </CompanyNameBlock>{' '}
          <Trans>Account number</Trans> {accountNumber}
        </CompanyInfoBlock>

        <BlockHeader
          title={msg`Adoption`}
          helperTooltip={msg`
              How effective is your LastPass rollout? Are your users activating their accounts?
            `}
        />
        <RowContainer data-qa={'AdoptionBlock'}>
          <FirstColumn>
            <Spacer>
              <UserAdoptionBox
                isChildCompany={isChildCompany}
                showBuyLicenses={canBuyLicenses}
                isBillingUsageBased={isBillingUsageBased}
              />
            </Spacer>
            <UnresponsiveUsersBox />
            <RemindInactiveUsersBlock />
          </FirstColumn>
          <SecondColumn>
            <UserAdoptionHistoryChart />
            <SecondColumnWithBorder>
              <UserActivityChart />
            </SecondColumnWithBorder>
          </SecondColumn>
        </RowContainer>
      </SingleColumnContentContainer>
    </DashboardContent>
  )
}

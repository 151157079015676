import { msg } from '@lingui/macro'
import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { PasswordManagerTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

export function createEnablePasswordManagersSaga(
  userService: UACServices.Services
) {
  return function* enablePasswordManagersSaga(
    action: ReturnType<typeof userDrawerActions.enablePasswordManagers>
  ) {
    const devices = action.payload.devices
    const enable = action.payload.enable
    const userId = action.payload.userId
    const enableTexts =
      devices.length === 1
        ? {
            success: msg`Device has been enabled.`,
            fail: msg`Device hasn't been enabled.`
          }
        : {
            success: msg`The selected devices have been enabled.`,
            fail: msg`None of the selected devices have been enabled.`,
            partial: msg`Some devices haven't been enabled.`
          }
    const disableTexts =
      devices.length === 1
        ? {
            success: msg`Device has been disabled.`,
            fail: msg`Device hasn't been disabled.`
          }
        : {
            success: msg`The selected devices have been disabled.`,
            fail: msg`None of the selected devices have been disabled.`,
            partial: msg`Some devices haven't been disabled.`
          }
    const currentText = enable ? enableTexts : disableTexts

    yield put(userDrawerActions.setUserTrustedDevicesLoading(true))

    const editedDevices = devices.map(device => {
      return {
        ...device,
        isEnabled: enable
      }
    })

    try {
      const results: UACServices.EditLpDeviceAPI.Responses[] = yield all(
        editedDevices.map(device =>
          call(userService.savePasswordManager, device, userId)
        )
      )

      const failedLpDevices: PasswordManagerTrustedDevice[] = []
      const successLpDevices: PasswordManagerTrustedDevice[] = []

      results.forEach((result, index) => {
        if (result.type === UACServices.UserAppsAPI.SUCCESS) {
          successLpDevices.push(devices[index])
        } else {
          failedLpDevices.push(devices[index])
        }
      })

      if (failedLpDevices.length === devices.length) {
        yield put(
          globalActions.setNotification({
            message: currentText.fail,
            type: NotificationType.alert,
            autoDismiss: false
          })
        )
      } else if (successLpDevices.length === devices.length) {
        yield put(
          globalActions.setNotification({
            message: currentText.success,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
      } else {
        yield put(
          globalActions.setNotification({
            message: currentText.partial,
            type: NotificationType.alert,
            autoDismiss: false,
            details: {
              type: 'lpdevices',
              devices: failedLpDevices
            }
          })
        )
      }
    } finally {
      yield put(
        userDrawerActions.getUserTrustedDevices({
          query: {},
          path: { id: userId }
        })
      )
    }
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

export const errorNotification = {
  message: msg`Admin level hasn't been updated.`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const duplicateErrorNotification = {
  message: msg`There's already an admin level with that name`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const getCurrentAdminLevel = (state: AppState) =>
  state.adminLevelDrawer.adminLevel

export function createEditPartialAdminLevelSaga(
  editPartialAdminLevelService: UACServices.Services
) {
  return function* editPartialAdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.editPartialAdminLevel>
  ) {
    try {
      const { id, name, description, permissions } = action.payload

      try {
        const adminLevelResponse: UACServices.AdminLevelAPI.Responses = yield call(
          editPartialAdminLevelService.adminLevel,
          id
        )

        if (adminLevelResponse.type === UACServices.AdminLevelSaveAPI.SUCCESS) {
          const {
            id,
            mspCidOverrideAdminLevel,
            mspCidOverridePermissions,
            permissions: currentPermissions
          } = adminLevelResponse.body

          if (
            !currentPermissions.includes(
              AdminPermissions.managedCompanyCidOverride
            ) &&
            permissions.includes(AdminPermissions.managedCompanyCidOverride)
          ) {
            yield put(
              adminLevelDrawerActions.setCurrentAdminLevel({
                id,
                name,
                description,
                permissions,
                mspCidOverridePermissions: [],
                mspCidOverrideAdminLevel: MspCidOverrideLevel.empty,
                userCount: 0,
                isCustom: false
              })
            )
            yield put(push(`/users/admin/${id}/managed-admin-permissions`))
          } else {
            const result: UACServices.AdminLevelSaveAPI.Responses = yield call(
              editPartialAdminLevelService.adminLevelPartialEdit,
              {
                id,
                name,
                description,
                permissions,
                mspCidOverrideAdminLevel: permissions.includes(
                  AdminPermissions.managedCompanyCidOverride
                )
                  ? mspCidOverrideAdminLevel
                  : MspCidOverrideLevel.none,
                mspCidOverridePermissions: permissions.includes(
                  AdminPermissions.managedCompanyCidOverride
                )
                  ? mspCidOverridePermissions
                  : []
              }
            )

            if (result.type === UACServices.AdminLevelSaveAPI.SUCCESS) {
              const adminLevelId = result.body.id
              yield put(adminListActions.getAdminList())
              yield put(adminLevelDrawerActions.reset())

              yield put(push(`/users/admin/${adminLevelId}`))
            } else {
              throw new UnknownServiceError()
            }
          }
        } else {
          throw new UnknownServiceError()
        }
      } catch (e) {
        yield put(globalActions.setNotification(errorNotification))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

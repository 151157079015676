import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  Select,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { NewUrlRuleForm } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/state'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 28px;
`

const DropdownAligner = styled.span`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`

const DropdownWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
`
const DropdownLabelWrapper = styled.div`
  margin-left: -5px;
  margin-right: -5px;
  width: 100%;
`

export const AddURLRuleDrawerForm: React.FunctionComponent = () => {
  const newUrlFormState: NewUrlRuleForm = useSelector(
    (state: AppState) => state.newURLForm.newUrlRuleForm
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  return (
    <Formik
      initialValues={{
        domainOrHost: '',
        path: '',
        isExactHostMatch: false,
        isExactPortMatch: false
      }}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={values => {
        setIsBlockingPromptEnabled(false)
        dispatch(
          urlRulesActions.addUrlRule({
            domain: values.domainOrHost,
            path: values.path,
            exactHost: values.isExactHostMatch,
            exactPort: values.isExactPortMatch,
            id: '',
            type: ''
          })
        )
        history.push('/advanced/enterpriseOptions/urlRules')
      }}
    >
      {formikProps => {
        return (
          <>
            {newUrlFormState.isLoading ? (
              <Loading color={'blue900'} active={true} />
            ) : null}
            <Form>
              <FormContainer onChange={() => setIsBlockingPromptEnabled(true)}>
                <InputContainer>
                  <Field
                    data-qa="AddUrlRuleDomainOrHostInput"
                    name="domainOrHost"
                    enableReinitialize={true}
                  >
                    {formData => (
                      <TextInput
                        data-qa="AddUrlRuleDomainOrHostTextInput"
                        name={formData.field.name}
                        value={formData.field.value}
                        placeholder={msg`Required`}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Domain or host</Trans>
                      </TextInput>
                    )}
                  </Field>
                </InputContainer>
                <InputContainer>
                  <Field data-qa="AddUrlRulePathInput" name="path">
                    {formData => (
                      <TextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        placeholder={msg`Required`}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Path</Trans>
                      </TextInput>
                    )}
                  </Field>
                </InputContainer>
                <DropdownAligner>
                  <DropdownWrapper>
                    <InputContainer>
                      <Field name="isExactHostMatch">
                        {formData => (
                          <Select
                            dataQa="ExactHostMatchDropdown"
                            fluid
                            onChange={option => {
                              setIsBlockingPromptEnabled(true)
                              formikProps.setFieldValue(
                                formData.field.name,
                                option && option['value'] === 'true'
                              )
                            }}
                            options={[
                              { value: 'true', label: i18n._(msg`Yes`) },
                              { value: 'false', label: i18n._(msg`No`) }
                            ]}
                            defaultValue={
                              formData.field.value == true
                                ? { value: 'true', label: i18n._(msg`Yes`) }
                                : { value: 'false', label: i18n._(msg`No`) }
                            }
                          >
                            <DropdownLabelWrapper>
                              <Trans>Exact host match</Trans>
                            </DropdownLabelWrapper>
                          </Select>
                        )}
                      </Field>
                    </InputContainer>
                  </DropdownWrapper>
                  <DropdownWrapper>
                    <InputContainer>
                      <Field name="isExactPortMatch">
                        {formData => (
                          <Select
                            dataQa="ExactPortMatchDropdown"
                            fluid
                            onChange={option => {
                              setIsBlockingPromptEnabled(true)
                              formikProps.setFieldValue(
                                formData.field.name,
                                option && option['value'] === 'true'
                              )
                            }}
                            options={[
                              { value: 'true', label: i18n._(msg`Yes`) },
                              { value: 'false', label: i18n._(msg`No`) }
                            ]}
                            defaultValue={
                              formData.field.value == true
                                ? { value: 'true', label: i18n._(msg`Yes`) }
                                : { value: 'false', label: i18n._(msg`No`) }
                            }
                          >
                            <DropdownLabelWrapper>
                              <Trans>Exact port match</Trans>
                            </DropdownLabelWrapper>
                          </Select>
                        )}
                      </Field>
                    </InputContainer>
                  </DropdownWrapper>
                </DropdownAligner>
              </FormContainer>

              <FooterContainer>
                <DrawerButtonsContainer>
                  <LocationLink to="/advanced/enterpriseOptions/urlRules">
                    <TextButton
                      data-qa="CancelButton"
                      css={css`
                        margin-right: 10px;
                      `}
                    >
                      <Trans>Cancel</Trans>
                    </TextButton>
                  </LocationLink>

                  <PrimaryButton
                    data-qa="AddUrlRuleButton"
                    disabled={
                      !formikProps.values[Object.keys(formikProps.values)[0]]
                    }
                    css={css`
                      margin-right: 5px;
                    `}
                    type="button"
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    <Trans>Add URL rule</Trans>
                  </PrimaryButton>
                </DrawerButtonsContainer>
              </FooterContainer>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as AppsIllustration } from '@lastpass/assets/svg/admin-console/applications/apps-illustration.svg'
import { Loading } from '@lastpass/components'
import {
  DrawerTable as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'

import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

const StyledNoAppIcon = styled(AppsIllustration)`
  width: 180px;
  height: 180px;
`

export interface UsersTableComponentProps {
  table: Table<User>
  checkable?: {
    addSelectedRecords: (userList: User[]) => void
    removeSelectedRecords: (userList: User[]) => void
    setSelectedRecords: (userList: User[]) => void
  }
  title: React.ReactElement
  text: React.ReactElement
  actionButton: React.ReactElement
}

export const UsersTableComponent: React.FunctionComponent<UsersTableComponentProps> = props => {
  return (
    <TableComponent
      table={props.table}
      checkable={props.checkable}
      noRecordsView={
        <TableView
          icon={<StyledNoAppIcon data-qa="NoAppsIcon" />}
          title={props.title}
          text={props.text}
          actionButton={props.actionButton}
        />
      }
      noResultsView={
        <TableView
          icon={<StyledNoAppIcon data-qa="NoAppsIcon" />}
          title={<Trans>Sorry, no results match your search</Trans>}
          text={<Trans>Try different keywords or check your spelling</Trans>}
          actionButton={
            <TableViewButton onClick={() => {}}>
              <Trans>Clear search query</Trans>
            </TableViewButton>
          }
        />
      }
      loadingView={<Loading color="blue900" active={true} />}
      columns={[
        {
          name: <Trans>Email</Trans>,
          renderer: (record: User) => record.name
        }
      ]}
      isFilterApplied={false}
      actions={[]}
    />
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

import { DELAYED_JOB_SUCCESS } from '../user-add'

export const SUCCESS = 'success'
export const PARTIALSUCCESS = 'partialSuccess'
export const FAILURE = 'failure'
export const FAIL_EMAIL_TEMPLATES_NOT_APPROVED =
  'error_email_template_not_approved'

const success = () => createResponse(SUCCESS)
const delayedSuccess = () => createResponse(DELAYED_JOB_SUCCESS)
const partialSuccess = failureReasons =>
  createResponse(PARTIALSUCCESS, failureReasons)
const failure = failureReasons => createResponse(FAILURE, failureReasons)
const emailTemplatesNotApproved = () =>
  createResponse(FAIL_EMAIL_TEMPLATES_NOT_APPROVED)

export const Responses = {
  success,
  partialSuccess,
  failure,
  delayedSuccess,
  emailTemplatesNotApproved
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    endpoint: string,
    bodyJson: {},
    queryString?: string
  ) {
    const [response, status] = await fetch(
      queryString
        ? `bulk/users/${endpoint}${queryString}`
        : `bulk/users/${endpoint}`,
      'POST',
      bodyJson,
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    )

    if (status === StatusCodes.OK && response) {
      const bulkResponse = JSON.parse(response)
      if (bulkResponse.content) {
        switch (bulkResponse.content.status) {
          case SUCCESS:
            return success()
          case PARTIALSUCCESS:
            return partialSuccess(bulkResponse.content.failureReasons)
          case DELAYED_JOB_SUCCESS:
            return delayedSuccess()
          default:
            if (
              bulkResponse.content.globalFailureReason ===
              FAIL_EMAIL_TEMPLATES_NOT_APPROVED
            ) {
              return emailTemplatesNotApproved()
            }
            return failure(bulkResponse.content.failureReasons)
        }
      }
    }

    throw new UnknownServiceError()
  }
}

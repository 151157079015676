import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { MfaApplicationGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  Group,
  User
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { mfaApplicationListActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'
import {
  MfaApplication,
  MfaApplicationType
} from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { mapMfaApplicationResponse } from '@lastpass/admin-console-dependencies/ui/common/mappings/mfaapp-mappers'

export function* mapUsersToTable() {
  const usersList = yield select((state: AppState) => state.usersList.table)
  const mappedUsers: User[] = usersList.results.map(entry => {
    return {
      id: entry.id,
      name: entry.email
    }
  })
  const users: Table<User> = {
    results: mappedUsers,
    totalResults: mappedUsers.length,
    checkedRecords: [],
    isLoading: false,
    isAllSelected: false,
    resultsPerPage: 25
  }
  yield put(mfaAppsDrawerActions.setUsers(users))
}

export function* mapGroupsToTable() {
  const groupList = yield select((state: AppState) => state.groupsList.table)
  const mappedGroups: Group[] = groupList.results.map(entry => {
    return {
      id: entry.id,
      name: entry.name
    }
  })
  const groups: Table<Group> = {
    results: mappedGroups,
    totalResults: mappedGroups.length,
    checkedRecords: [],
    isLoading: false,
    isAllSelected: false,
    resultsPerPage: 25
  }

  yield put(mfaAppsDrawerActions.setGroups(groups))
}

export function createSetSelectedMfaApplication(
  applicationsServices: UACServices.Services,
  getUserListSaga,
  getGroupListSaga
) {
  return function* setSelectedMfaApplication(
    action: ReturnType<typeof mfaApplicationListActions.setSelectedApplication>
  ) {
    try {
      yield put(mfaAppsDrawerActions.setDrawerLoading(true))

      if (action.payload.app.type !== MfaApplicationType.azureAD) {
        const response = yield call(
          applicationsServices.getMfaApplication,
          action.payload.app.key
        )
        if (response.type === MfaApplicationGetAPI.SUCCESS) {
          const application: MfaApplication = mapMfaApplicationResponse(
            response.body
          )
          yield put(mfaAppsDrawerActions.setApp(application))
        }
      } else {
        yield put(mfaAppsDrawerActions.setApp(action.payload.app))
      }

      const application = yield select(
        (state: AppState) => state.mfaAppsDrawer.app
      )

      if (application.userIds.length > 0) {
        yield call(getUserListSaga, {
          payload: {
            params: {
              query: {
                filter: {
                  userId: application.userIds
                }
              }
            },
            resultsPerPage: application.userIds.length
          }
        })
        yield call(mapUsersToTable)
      }

      if (application.groupIds.length > 0) {
        yield call(getGroupListSaga, {
          payload: {
            params: {
              query: {
                filter: {
                  groupId: application.groupIds
                }
              }
            },
            resultsPerPage: application.groupIds.length
          }
        })
        yield call(mapGroupsToTable)
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(mfaAppsDrawerActions.setDrawerLoading(false))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { NewAddUser } from '@lastpass/admin-console-dependencies/state/users/view/add/state'

export const SUCCESS = 'success'
export const PARTIAL_SUCCESS = 'partialSuccess'
export const FAIL = 'fail'
export const FAILURE = 'failure'
export const FAIL_EMAIL_TEMPLATES_NOT_APPROVED = 'emailTemplatesNotApproved'
export const FAIL_REQUESTER_USER_EMAIL_NOT_VERIFIED =
  'error_requester_user_email_not_verified'
export const ERROR_EMAIL_TEMPLATES_NOT_APPROVED =
  'error_email_template_not_approved'
export const DELAYED_JOB_SUCCESS = 'delayedJobSuccess'
export const DELAYED_JOB_PARTIAL_SUCCESS = 'delayedJobPartialSuccess'

const success = () => createResponse(SUCCESS)
const partialSuccess = (failedUsers: { [key: string]: string }[]) =>
  createResponse(PARTIAL_SUCCESS, { failedUsers })
const fail = (failedUsers: { [key: string]: string }[]) =>
  createResponse(FAIL, { failedUsers })
const delayedJobSuccess = () => createResponse(DELAYED_JOB_SUCCESS)
const delayedJobPartialSuccess = (failedUsers: { [key: string]: string }[]) =>
  createResponse(DELAYED_JOB_PARTIAL_SUCCESS, { failedUsers })
const emailTemplatesNotApproved = () =>
  createResponse(FAIL_EMAIL_TEMPLATES_NOT_APPROVED)
const requesterUserEmailNotVerified = () =>
  createResponse(FAIL_REQUESTER_USER_EMAIL_NOT_VERIFIED)

export const Responses = {
  success,
  partialSuccess,
  fail,
  delayedJobSuccess,
  delayedJobPartialSuccess,
  emailTemplatesNotApproved,
  requesterUserEmailNotVerified
}

export type Responses = ResponseTypes<typeof Responses>

function getResponse(responseJson, newUsers) {
  switch (responseJson.content.globalFailureReason) {
    case ERROR_EMAIL_TEMPLATES_NOT_APPROVED:
      return emailTemplatesNotApproved()
    case FAIL_REQUESTER_USER_EMAIL_NOT_VERIFIED:
      return requesterUserEmailNotVerified()
  }

  if (
    Object.keys(responseJson.content.failureReasons).length === newUsers.length
  ) {
    return fail(responseJson.content.failureReasons)
  }

  switch (responseJson.content.status) {
    case FAILURE:
      return fail(responseJson.content.failureReasons)
    case PARTIAL_SUCCESS:
      return partialSuccess(responseJson.content.failureReasons)
    case SUCCESS:
      return success()
    case DELAYED_JOB_SUCCESS:
      return delayedJobSuccess()
    case DELAYED_JOB_PARTIAL_SUCCESS:
      return delayedJobPartialSuccess(responseJson.content.failureReasons)
    default:
      throw new UnknownServiceError()
  }
}

export function create(fetch: AuthFetch) {
  return async function service(
    users: NewAddUser[],
    sendMfaInvite: boolean,
    sendLpInvite: boolean
  ) {
    const newUsers = users.map(user => {
      return {
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || ''
      }
    })
    const [response, status] = await fetch(
      'bulk/users/',
      'POST',
      {
        passwordManagementInvite: sendLpInvite,
        lastPassMfaInvite: sendMfaInvite,
        companyUsers: newUsers
      },
      {
        'content-type': 'application/json'
      }
    )

    if (status === StatusCodes.OK && response) {
      const responseJson = JSON.parse(response)
      return getResponse(responseJson, newUsers)
    }
    throw new UnknownServiceError()
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

export function createGetAdAttributeName(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getAdAttributeName() {
    try {
      yield put(federatedLoginActions.setLoading(true))
      try {
        const adAttributeNameResult: UACServices.AdAttributeGetAPI.Responses = yield call(
          enterpriseOptionServices.adAttributeGet
        )
        yield put(
          federatedLoginActions.setAdAttributeName(
            adAttributeNameResult.body.adAttribute
          )
        )
      } finally {
        yield put(federatedLoginActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

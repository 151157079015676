import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as ActiveDirectoryIcon } from '@lastpass/assets/svg/admin-console/directories/icon-active-directory.svg'

import {
  BoldWrapper,
  StyledPrimaryButton
} from './DirectoriesPageCopiedComponent'
import { IntegrationCard } from './IntegrationCard'

interface ADConnectorIntegrationProps {
  hasApiKey?: boolean
  reset?: () => void
  trackPrimaryButtonClick: () => unknown
  lastSync?: string
  version?: string
}

export const AdConnectorIntegrationCard: FunctionComponent<ADConnectorIntegrationProps> = props => {
  return (
    <IntegrationCard
      expand={props.hasApiKey}
      logo={<ActiveDirectoryIcon data-qa={'ADConnectorIcon'} />}
      title={<Trans>AD Connector</Trans>}
      subtitle={
        <Trans>
          Set up the on-premise Active Directory Sync Client to support
          provisioning and management processes in LastPass.
        </Trans>
      }
      description={
        <div>
          <Trans>
            Creating, disabling, and deleting user profiles in AD results in a
            corresponding action for LastPass accounts. User groups can be
            synced to LastPass for policy designations, Shared Folder
            assignments, and SAML application provisioning.
          </Trans>
          <div>
            <a
              href="https://support.logmeininc.com/lastpass/help/set-up-the-lastpass-active-directory-connector-lp010057"
              data-qa={'ADConnectorViewSetupInstructionLink'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>View setup instructions</Trans>
            </a>
          </div>
        </div>
      }
      primaryButton={
        <StyledPrimaryButton
          data-qa={'ADConnectorDownloadConnectorButton'}
          onClick={() => {
            window.location.replace(
              'https://download.cloud.lastpass.com/ad/LastPassADConnector.msi'
            )
          }}
        >
          <Trans>Download AD Connector</Trans>
        </StyledPrimaryButton>
      }
      primaryText={
        <>
          <BoldWrapper>
            <Trans>Using an LDAP server?</Trans>
          </BoldWrapper>{' '}
          <Trans>
            We recommend our{' '}
            <a href="/advanced/enterpriseApi" data-qa={'ADConnectorLDAPLink'}>
              Provisioning API for LastPass integration.
            </a>
          </Trans>
        </>
      }
      secondaryButton={
        props.hasApiKey ? (
          <StyledPrimaryButton
            onClick={() => {
              if (props.reset) {
                props.reset()
              }
            }}
          >
            <Trans>Reset API key</Trans>
          </StyledPrimaryButton>
        ) : (
          undefined
        )
      }
      secondaryText={
        props.hasApiKey ? (
          <Trans>
            You{"'"}ve already set up an AD connector. To reset its API key,
            click Reset.
          </Trans>
        ) : (
          undefined
        )
      }
      lastSync={props.lastSync}
      version={props.version}
    />
  )
}

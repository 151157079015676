import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Papa from 'papaparse'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { decrypt } from '@lastpass/encryption'
import { isCbc as isEncrypted } from '@lastpass/encryption/vault-encryption'

import {
  getSharedFolderEncryptionKeysWithId,
  SharedFolderEncryptionKeyWithId
} from '@lastpass/admin-console-dependencies/services/security-reports/decrypt-shared-folder-encryption-keys'
import { truncateUrlToHostAndPathname } from '@lastpass/admin-console-dependencies/services/security-reports/truncate-url-to-host-and-pathname'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { useDecryptSecurityReportOnComplete } from './use-decrypt-security-report-on-complete'
import { UrlsInSharedFolderData } from './use-parse-security-report'

interface DecryptedUrlsInSharedFolderData extends UrlsInSharedFolderData {
  Decrypted: 0 | 1
}

export const useDecryptUrlsInSharedFoldersReport = () => {
  const [numberOfDecryptedCsvRows, setNumberOfDecryptedCsvRows] = useState(0)
  const dispatch = useDispatch()
  const onComplete = useDecryptSecurityReportOnComplete()

  const { decryptedPrivateSharingKey, vaultKey } = useSelector(
    (state: AppState) => state.global
  )
  const decryptionData = useSelector(
    (state: AppState) => state.securityReportDrawer.decryptionData
  )

  const parseAndSaveCsv = useCallback(
    async (blob: Blob, abortController: AbortController) => {
      const csvContents: DecryptedUrlsInSharedFolderData[] = []
      let numberOfUrlsWithDecryptionFailure = 0

      const sharedFolderEncryptionKeysWithId = await getSharedFolderEncryptionKeysWithId(
        decryptionData,
        decryptedPrivateSharingKey,
        vaultKey,
        abortController
      )

      return Papa.parse(blob, {
        worker: false,
        download: false,
        skipEmptyLines: true,
        fastMode: true,
        header: true,
        chunk: async function(result, parser) {
          parser.pause()

          if (abortController.signal.aborted) {
            parser.abort()
            return
          }
          const rows: UrlsInSharedFolderData[] = result.data

          for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex]

            const decryptedRow = await decryptUrlsInSharedFoldersRow(
              sharedFolderEncryptionKeysWithId,
              row
            )

            if (isEncrypted(decryptedRow.URL)) {
              numberOfUrlsWithDecryptionFailure++
            }
            csvContents.push(decryptedRow)

            setNumberOfDecryptedCsvRows(prevState => prevState + 1)
          }

          parser.resume()
        },
        complete: async function() {
          if (abortController.signal.aborted) {
            return
          }

          onComplete(csvContents, numberOfUrlsWithDecryptionFailure)
          setNumberOfDecryptedCsvRows(0)
        },
        error: function() {
          dispatch(
            globalActions.setDialog({
              type: 'error-decrypting-url-data-dialog'
            })
          )
        }
      })
    },
    [decryptedPrivateSharingKey, decryptionData, dispatch, onComplete, vaultKey]
  )
  return { parseAndSaveCsv, numberOfDecryptedCsvRows }
}

const decryptUrlsInSharedFoldersRow = async (
  sharedFolderEncryptionKeysWithId: SharedFolderEncryptionKeyWithId[],
  row: UrlsInSharedFolderData
): Promise<DecryptedUrlsInSharedFolderData> => {
  let decryptedUrl = ''
  let decryptedColumnValue: DecryptedUrlsInSharedFolderData['Decrypted'] = 0
  const isUrlDecrypted = decryptedUrl => decryptedUrl !== ''

  for (const sharedFolderEncryptionKeyWithId of sharedFolderEncryptionKeysWithId) {
    if (sharedFolderEncryptionKeyWithId.sharedFolderId === row.SharedFolderId) {
      decryptedUrl = await decrypt(
        row.URL,
        sharedFolderEncryptionKeyWithId.decryptedSharedFolderEncryptionKey,
        'base64'
      )
    }
    if (isUrlDecrypted(decryptedUrl)) break
  }

  if (isUrlDecrypted(decryptedUrl)) {
    decryptedUrl = truncateUrlToHostAndPathname(decryptedUrl)
    decryptedColumnValue = 1
  } else {
    decryptedUrl = row.URL
  }

  return {
    'Shared folder name': row['"Shared folder name"'].replace(/"/g, ''),
    URL: decryptedUrl,
    'Vault Item Identifier': row['"Vault Item Identifier"'],
    Deleted: row.Deleted,
    'Last password change': row['"Last password change"'].replace(/"/g, ''),
    Decrypted: decryptedColumnValue
  }
}

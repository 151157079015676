import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

interface AddURLRuleDrawerProps {
  closeLink: string
  children: ReactElement
}

export const AddURLRuleDrawer: React.FunctionComponent<AddURLRuleDrawerProps> = ({
  closeLink,
  children
}) => {
  return (
    <Drawer
      mainTitle={<></>}
      subTitle={<Trans>Add URL rule</Trans>}
      closeLink={closeLink}
    >
      {children}
    </Drawer>
  )
}

import React from 'react'

import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  CompanyInfoState,
  SubscriptionType
} from '@lastpass/admin-console-dependencies/state/company/state'
import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'

export interface PermissionContextProps {
  subscription: SubscriptionType
  permissions: AdminPermissions[]
  requireSubscription: (...requiredSubscription: SubscriptionType[]) => boolean
  requirePermission: (...requiredPermission: AdminPermissions[]) => boolean
}

export const PermissionContext = React.createContext<PermissionContextProps>({
  subscription: SubscriptionType.none,
  permissions: [],
  requireSubscription: () => false,
  requirePermission: () => false
})

type CheckPermissionProps = LastPassComponentProps<{
  subscription: SubscriptionType
  permissions: AdminPermissions[]
}>

export const PermissionContextComponent: React.FunctionComponent<CheckPermissionProps> = ({
  state,
  children
}) => {
  return (
    <PermissionContext.Provider
      value={{
        subscription: state.subscription,
        permissions: state.permissions,
        requireSubscription: (...requiredSubscriptions: SubscriptionType[]) =>
          requiredSubscriptions.includes(state.subscription),
        requirePermission: (...requiredPermissions: AdminPermissions[]) =>
          requiredPermissions.some(requiredPermission =>
            state.permissions.includes(requiredPermission)
          )
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export function createCheckPermissionContext<
  TState extends { companyInfo: CompanyInfoState; global: GlobalState }
>() {
  return connectRedux(
    PermissionContextComponent,
    (state: TState) => {
      return {
        subscription: state.companyInfo.details.subscription,
        permissions: state.global.permissions
      }
    },
    {}
  )
}

import React from 'react'

import { FormikProps } from 'formik'

import { PermissionAreaType } from '@lastpass/admin-console-dependencies/types/permissions/permission-area-type'
import { PermissionCategory } from '@lastpass/admin-console-dependencies/types/permissions/permission-category'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

import { Areas } from './Areas'
import { ExpandableBlock } from './ExpandableBlock'

export interface CategoriesProps {
  formik: FormikProps<AdminFormValues>
  categories: PermissionCategory[]
  disabled?: boolean
}

const deprecatedPermissionAreas: PermissionAreaType[] = [
  PermissionAreaType.reportingSsoLoginActivity,
  PermissionAreaType.reportingSamlResponse,
  PermissionAreaType.reportingMfaUserActivity,
  PermissionAreaType.reportingMfaAdminActivity
]

export const Categories: React.FunctionComponent<CategoriesProps> = ({
  categories,
  formik,
  disabled
}) => {
  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  return (
    <>
      {categories.map((category, index) => (
        <ExpandableBlock title={category.permissionCategoryTitle} key={index}>
          <Areas
            formik={formik}
            areas={category.permissionAreas.filter(
              area =>
                !isNoPasswordDeprecated ||
                (isNoPasswordDeprecated &&
                  !deprecatedPermissionAreas.includes(area.permissionAreaType))
            )}
            disabled={disabled}
          />
        </ExpandableBlock>
      ))}
    </>
  )
}

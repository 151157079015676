import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { Loading } from '@lastpass/components'
import { IconButton } from '@lastpass/lastkit'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaAppsDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'

import { createMfaApplicationOperationsDropdown } from './MfaApplicationOperationDropdown'
import { MfaAppsDrawerPage } from './MfaAppsDrawerPage'

const ApplicationOperationsDropdown = createMfaApplicationOperationsDropdown<
  AppState
>()

export interface MfaAppsDrawerProps {
  closeLink: string
  initialStep: MfaAppsDrawerSteps
  onMfaAppsDrawerClosed?: () => void
}

const getTitle = step => {
  switch (step) {
    case MfaAppsDrawerSteps.create:
    case MfaAppsDrawerSteps.finish: {
      return <Trans>Set up integration</Trans>
    }
    case MfaAppsDrawerSteps.edit: {
      return <Trans>Edit integration</Trans>
    }
    case MfaAppsDrawerSteps.assign:
    case MfaAppsDrawerSteps.newAssign:
      return <Trans>Assign users {'&'} groups</Trans>
    case MfaAppsDrawerSteps.allSet:
      return <Trans>All set!</Trans>
  }
}

export const MfaAppsDrawerComponent: React.FunctionComponent<MfaAppsDrawerProps> = ({
  initialStep,
  onMfaAppsDrawerClosed,
  closeLink
}) => {
  const dispatch = useDispatch()

  const state = useSelector((state: AppState) => state.mfaAppsDrawer)
  useEffect(() => {
    return () => {
      dispatch(mfaAppsDrawerActions.discard())
    }
  }, [dispatch])

  const backButton =
    state.actualStep === MfaAppsDrawerSteps.newAssign ? (
      <LocationLink
        to={'#'}
        onClick={() => {
          dispatch(mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.assign))
        }}
      >
        <IconButton icon={ChevronLeft} data-qa={'BackButton'} />
      </LocationLink>
    ) : (
      <></>
    )

  const actualStep = state.actualStep ? state.actualStep : initialStep
  const mainTitle = getTitle(actualStep)
  return (
    <Drawer
      mainTitle={mainTitle}
      miscTitle={<></>}
      closeLink={closeLink}
      headerNodes={
        <>
          {backButton}
          {actualStep === MfaAppsDrawerSteps.edit && (
            <ApplicationOperationsDropdown />
          )}
        </>
      }
      onClose={onMfaAppsDrawerClosed}
    >
      {state.isLoading && <Loading color="blue900" active={true} />}
      <MfaAppsDrawerPage step={actualStep} closeLink={closeLink} />
    </Drawer>
  )
}

import { GroupDrawerActions, GroupDrawerActionTypes } from './actions'
import { GroupDrawerState } from './state'

export const initialState: GroupDrawerState = {
  group: {
    id: '',
    name: '',
    userCount: 0,
    securityScore: 0
  },
  hasFederatedUsers: false,
  groupLoading: true,
  users: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  policies: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function groupDrawerReducer(
  previousState: GroupDrawerState | undefined,
  action: GroupDrawerActions
): GroupDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case GroupDrawerActionTypes.RESET: {
      return initialState
    }
    case GroupDrawerActionTypes.SET_CURRENT_GROUP: {
      return {
        ...state,
        group: action.payload.group
      }
    }
    case GroupDrawerActionTypes.SET_CURRENT_GROUP_HAS_FEDERATED_USERS: {
      return {
        ...state,
        hasFederatedUsers: action.payload.hasFederatedUsers ?? false
      }
    }
    case GroupDrawerActionTypes.SET_GROUP_LOADING: {
      return {
        ...state,
        groupLoading: action.payload
      }
    }
    //GROUP USERS
    case GroupDrawerActionTypes.SET_GROUP_USERS: {
      return {
        ...state,
        users: {
          results: action.payload.users,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case GroupDrawerActionTypes.SET_GROUP_USERS_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: action.payload
        }
      }
    }
    case GroupDrawerActionTypes.ADD_SELECTED_GROUP_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          checkedRecords: state.users.checkedRecords.concat(
            action.payload.userList
          )
        }
      }
    }
    case GroupDrawerActionTypes.REMOVE_SELECTED_GROUP_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          checkedRecords: state.users.checkedRecords.filter(
            el => !action.payload.userList.includes(el)
          )
        }
      }
    }
    case GroupDrawerActionTypes.SET_SELECTED_GROUP_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          checkedRecords: action.payload.userList
        }
      }
    }
    // GROUP POLICIES
    case GroupDrawerActionTypes.SET_GROUP_POLICIES: {
      return {
        ...state,
        policies: {
          results: action.payload.policies,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case GroupDrawerActionTypes.SET_GROUP_POLICIES_LOADING: {
      return {
        ...state,
        policies: {
          ...state.policies,
          isLoading: action.payload
        }
      }
    }
  }
  return state
}

import {
  AdminActivityTypes,
  UserActivityTypes
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-types'

export interface ActivityEventType {
  label: string
  value: string
}

export interface ActivityEventQueryParams {
  search: string
  eventType: ActivityEventType
  from: { label: string; value: string }
  to: { label: string; value: string }
  more: boolean
}

export interface ActivityEvent {
  type: UserActivityTypes | AdminActivityTypes
  data: string
  ip: string
  dateTimeUtc: string
  userEmail: string
  userName: string
}

export interface ActivityEventRequestParams {
  fromDateTimeUtc: string
  toDateTimeUtc: string
  keyword: string
  eventType: UserActivityTypes | AdminActivityTypes
}

export enum ActivityEventUserType {
  user = 'user',
  admin = 'admin'
}

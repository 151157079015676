import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UrlRuleOptions } from '@lastpass/admin-console-dependencies/types/url-rule-options'

export const SUCCESS = 'success'

const success = (payload: UrlRuleOptions) => createResponse(SUCCESS, payload)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch('url-rules/options', 'GET')

      if (status === StatusCodes.OK && response) {
        const urlRulesOptions: UrlRuleOptions = JSON.parse(response).content
        return success(urlRulesOptions)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Chip, ChipContainer, ChipGroup } from '@lastpass/lastkit'

import { useCalculateChartRateThreshold } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-calculate-chart-rate-threshold'
import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'

const ChipValue = styled.span<{ textColor?: string }>`
  color: ${props => props.textColor ?? props.theme.colors.neutral700};
`

interface Props {
  invitedUsersCount: number
  expiredInvitationsCount: number
  enrollmentRate: number
  enrollmentRateTooltip: ReactElement
}

export const EnrollmentRateAccordionChips: FC<Props> = ({
  invitedUsersCount,
  expiredInvitationsCount,
  enrollmentRate,
  enrollmentRateTooltip
}) => {
  const chartRateThresholds = useCalculateChartRateThreshold(enrollmentRate)
  const invitedUsersCountColor = useCountColor(invitedUsersCount)
  const expiredInvitationsCountColor = useCountColor(expiredInvitationsCount)

  return (
    <ChipContainer>
      <Chip tooltip={enrollmentRateTooltip}>
        <ChipValue textColor={chartRateThresholds.colors.primary}>
          {enrollmentRate}
          {'%'}
        </ChipValue>
      </Chip>
      <ChipGroup data-qa="NumbersChipGroup">
        <Chip>
          <Trans>Pending invitations</Trans>
          {':'}&nbsp;
          <ChipValue textColor={invitedUsersCountColor}>
            {invitedUsersCount}
          </ChipValue>
        </Chip>
        <Chip>
          <Trans>Expired invitations</Trans>
          {':'}&nbsp;
          <ChipValue textColor={expiredInvitationsCountColor}>
            {expiredInvitationsCount}
          </ChipValue>
        </Chip>
      </ChipGroup>
    </ChipContainer>
  )
}

import { getPolicyValue } from '@lastpass/admin-console-dependencies/sagas/policies/general/drawer/enable-general-policy'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  InitialTeamsPolicyModelData,
  TeamsPolicy,
  TeamsPolicyModelData,
  TeamsPolicyValueType
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export const preparePolicyData = (
  policy: TeamsPolicy,
  initialTeamsPolicyModelData: InitialTeamsPolicyModelData | null
): TeamsPolicyModelData[] => {
  let policyData: TeamsPolicyModelData[] = [
    {
      appliesTo: initialTeamsPolicyModelData
        ? initialTeamsPolicyModelData.appliesTo
        : AppliesToOptions.all,
      policyValue: getPolicyValue(policy.policyValueType),
      policyNotes:
        policy.policyValueType === TeamsPolicyValueType.restrictedadmin
          ? '1'
          : '',
      cpid: null,
      exclusiveList: initialTeamsPolicyModelData
        ? initialTeamsPolicyModelData.exclusiveList
        : [],
      inclusiveList: initialTeamsPolicyModelData
        ? initialTeamsPolicyModelData.inclusiveList
        : [],
      exclusiveGroupList: initialTeamsPolicyModelData
        ? initialTeamsPolicyModelData.exclusiveGroupList
        : [],
      inclusiveGroupList: initialTeamsPolicyModelData
        ? initialTeamsPolicyModelData.inclusiveGroupList
        : []
    }
  ]

  if (policy.policyData) {
    policyData = policy.policyData
  }

  return policyData
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { getCurrentUrlFilters } from './enable-general-policy'
import { getPolicyInfo } from './get-general-policy'

export function createDisableGeneralPolicySaga(
  policyService: UACServices.Services
) {
  return function* disableGeneralPolicySaga(
    action: ReturnType<
      typeof generalPoliciesDrawerActions.disableCurrentGeneralPolicy
    >
  ) {
    const currentUrlFilters = yield select(getCurrentUrlFilters)
    try {
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Disable policy`,
        text: msg`Disable the policy and erase all associated configurations and data?`,
        discardText: msg`Cancel`,
        confirmText: msg`Disable`
      }

      const policyKey = action.payload.policyKey
      if (policyKey) {
        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
            false,
            policyKey
          )
        )

        yield put(globalActions.setDialog(dialog))
        const confirmClick = yield take([
          GlobalActionTypes.CONFIRM_DIALOG,
          GlobalActionTypes.DISCARD_DIALOG
        ])

        if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
          yield put(
            push(
              `/policies/generalpolicies${currentUrlFilters.location.search ||
                ''}`
            )
          )
          const policyMap = yield select(getPolicyInfo)
          const policy = policyMap[policyKey]

          if (!policy.checked) {
            yield put(
              generalPoliciesListActions.getGeneralPoliciesList({
                query: getQueryParams(
                  currentUrlFilters.location,
                  Namespace.policies
                ),
                path: {}
              })
            )
            return
          }

          const result: UACServices.GeneralPolicySwitchOFFSaveAPI.Responses = yield call(
            policyService.generalPolicySwitchOffSave,
            policyKey
          )

          if (
            result.type === UACServices.GeneralPolicySwitchOFFSaveAPI.SUCCESS
          ) {
            yield put(
              generalPoliciesListActions.getGeneralPoliciesList({
                query: getQueryParams(
                  currentUrlFilters.location,
                  Namespace.policies
                ),
                path: {}
              })
            )
            yield put(
              globalActions.setNotificationSuccess(msg`Policy changes saved.`)
            )
          } else {
            yield put(
              globalActions.setNotificationAlert(
                msg`Policy changes haven't been saved.`
              )
            )
          }
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

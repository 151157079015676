import { DataFetchParams } from '@lastpass/routing'
import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  AvailableApplication,
  Group,
  Role,
  SamlKey,
  SelectedApplication,
  User,
  UsernameAttribute
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { UserDrawerUrlParams } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
export interface QueryParams {
  search: string
  skip: number
}

export enum Entities {
  availableUsers = 'availableUsers',
  users = 'users',
  availableGroups = 'availableGroups',
  groups = 'groups',
  availableRoles = 'availableRoles',
  roles = 'roles'
}

export enum ApplicationDrawerActionTypes {
  SELECT_APP_CLICKED = '[applications-drawer] select-app-clicked',
  DISCARD = '[applications-drawer] discard',
  FINISH = '[applications-drawer] finish',
  SAVE_AND_EXIT = '[applications-drawer] save-and-exit',
  SAVE_AND_CONTINUE = '[applications-drawer] save-and-continue',
  SAVE_AND_ASSIGN_USERS = '[applications-drawer] save-and-assigns-users',
  ASSIGN_USERS_CLICKED = '[applications-drawer] assign-users-clicked',
  NEXT_STEP = '[applications-drawer] next-step',
  SET_STEP = '[applications-drawer] set-step',
  ASSIGN = '[application-drawer] assign',
  UNASSIGN = '[application-drawer] unassign',
  GET_AVAILABLE_APPLICATIONS = '[applications-drawer] get-available-applications',
  SET_AVAILABLE_APPLICATIONS = '[applications-drawer] set-available-applications',
  SET_SELECTED_APPLICATION = '[applications-drawer] set-selected-application',
  SET_DRAWER_LOADING = '[applications-drawer] set-drawer-loading',
  SET_SAML_KEYS = '[applications-drawer] set-saml-keys',
  GET_INITIAL_DATA = '[applications-drawer] get-initial-data',
  SET_USERNAME_ATTRIBUTES = '[applications-drawer] set-username-attributes',
  GET_USER_LIST = '[applications-drawer] get-user-list',
  SET_AVAILABLE_USER_LIST = '[applications-drawer] set-available-user-list',
  SET_USER_LIST_LOADING = '[applications-drawer] set-user-list-loading',
  GET_GROUP_LIST = '[applications-drawer] get-group-list',
  SET_GROUP_LIST_LOADING = '[applications-drawer] set-group-list-loading',
  SET_AVAILABLE_GROUP_LIST = '[applications-drawer] set-available-group-list',
  SET_USER_LIST_SEARCH_INPUT = '[application-drawer] set-user-list-search-input',
  SET_GROUP_LIST_SEARCH_INPUT = '[application-drawer] set-group-list-search-input',
  GET_ROLE_LIST = '[application-drawer] get-role-list',
  SET_ROLE_LIST_SEARCH_INPUT = '[application-drawer] set-role-list-search-input',
  SET_ROLE_LIST_LOADING = '[application-drawer] set-role-list-loading',
  SET_AVAILABLE_ROLE_LIST = '[application-drawer] set-available-role-list',
  SELECT_TABLE_ELEMENT = '[application-drawer] select-table-element',
  DESELECT_TABLE_ELEMENT = '[applicaition-darwer] deselect-table-element',
  SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS = '[allpication-drawer] select-or-deselect-all-table-elements',
  GET_ASSIGNED_ELEMENTS = '[application-drawer] get-assigned-elements',
  SET_ASSIGNED_ELEMENTS = '[application-drawer] set-assigned-elements',
  SET_ASSIGN_PAGE_OPENED = '[application-drawer] set-assign-page-opened',
  VIEW_ASSIGN_USERS = '[applications-drawer] view-assign-users',
  VIEW_CONFIGURE_APP = '[applications-drawer] view-configure-app',
  CLICK_CONFIGURE_APP = '[applications-drawer] click-configure-app',
  VIEW_SELECT_APP = '[applications-drawer] view-select-app',
  SSO_APP_SAVED = '[applications-drawer] sso-app-saved',
  SHOW_TABLE = '[applications-drawer] show-table',
  DELETE_APPLICATION = '[application-drawer] delete-application',
  ENABLE_MULTIFACTOR = '[application-drawer] enable-multifactor',
  ASSIGN_TEST_USER_CLICKED = '[applications-drawer] assign-test-user-clicked',
  ASSIGN_TEST_USER_VIEWED = '[applications-drawer] assign-test-user-viewed',
  TEST_LOGIN_CLICKED = '[applications-drawer] test-login-clicked',
  TEST_LOGIN_VIEWED = '[applications-drawer] test-login-viewed',
  SET_TEST_LOGIN_SUCCESS_STATE = '[applications-drawer] set-test-login-success-state'
}

export const applicationsDrawerSegmentActions = {
  selectAppClicked: (
    action: SegmentPropertyType<'SSO Select App Clicked', 'Action'>,
    appName: string | undefined
  ) =>
    track(createAction(ApplicationDrawerActionTypes.SELECT_APP_CLICKED, {}), {
      segment: uacEvent('SSO Select App Clicked', {
        Action: action,
        'SSO App Name': appName
      })
    }),
  assignUsersClicked: () =>
    track(createAction(ApplicationDrawerActionTypes.ASSIGN_USERS_CLICKED, {}), {
      segment: uacEvent('SSO Assign Users Clicked')
    }),
  assignUsersViewed: () =>
    track(createAction(ApplicationDrawerActionTypes.VIEW_ASSIGN_USERS, {}), {
      segment: uacEvent('SSO Assign Users Viewed')
    }),
  assignTestUserClicked: (
    appName: string,
    assignmentChoice: SegmentPropertyType<
      'SSO Assign a test user Clicked',
      'Assignment choice'
    >,
    action: SegmentPropertyType<'SSO Assign a test user Clicked', 'Action'>
  ) =>
    track(
      createAction(ApplicationDrawerActionTypes.ASSIGN_TEST_USER_CLICKED, {}),
      {
        segment: uacEvent('SSO Assign a test user Clicked', {
          'SSO App Name': appName,
          'Assignment choice': assignmentChoice,
          Action: action
        })
      }
    ),
  assignTestUserViewed: (appName: string) =>
    track(
      createAction(ApplicationDrawerActionTypes.ASSIGN_TEST_USER_VIEWED, {}),
      {
        segment: uacEvent('SSO Assign a test user Viewed', {
          'SSO App Name': appName
        })
      }
    ),
  testLoginClicked: (
    appName: string,
    loginSuccessState: string,
    action: SegmentPropertyType<'SSO Test login Clicked', 'Action'>
  ) =>
    track(createAction(ApplicationDrawerActionTypes.TEST_LOGIN_CLICKED, {}), {
      segment: uacEvent('SSO Test login Clicked', {
        'SSO App Name': appName,
        'Login success state': loginSuccessState,
        Action: action
      })
    }),
  testLoginViewed: (appName: string) =>
    track(createAction(ApplicationDrawerActionTypes.TEST_LOGIN_VIEWED, {}), {
      segment: uacEvent('SSO Test login Viewed', {
        'SSO App Name': appName
      })
    }),
  ssoAppSaved: (appName: string, isCustom: boolean) =>
    track(createAction(ApplicationDrawerActionTypes.SSO_APP_SAVED, {}), {
      segment: uacEvent('SSO App Added', {
        'SSO App Name': appName,
        'Is Custom': isCustom
      })
    }),
  saveAndAssignUsers: (
    selectedApp: SelectedApplication,
    users: User[],
    groups: Group[],
    roles: Role[]
  ) =>
    track(
      createAction(ApplicationDrawerActionTypes.SAVE_AND_ASSIGN_USERS, {
        selectedApp,
        users,
        groups,
        roles
      }),
      {
        segment: uacEvent('SSO App Added', {
          'Assigned Users': users.length,
          'SSO App Name': selectedApp.name,
          'Is Custom': selectedApp.isCustom
        })
      }
    ),
  configureAppViewed: () =>
    track(createAction(ApplicationDrawerActionTypes.VIEW_CONFIGURE_APP, {}), {
      segment: uacEvent('SSO Configure App Viewed')
    }),
  configureAppClicked: (
    action: SegmentPropertyType<'SSO Configure App Clicked', 'Action'>
  ) =>
    track(createAction(ApplicationDrawerActionTypes.CLICK_CONFIGURE_APP, {}), {
      segment: uacEvent('SSO Configure App Clicked', { Action: action })
    }),
  selectAppViewed: () =>
    track(createAction(ApplicationDrawerActionTypes.VIEW_SELECT_APP, {}), {
      segment: uacEvent('SSO Select App Viewed')
    })
}

export const applicationsDrawerActions = {
  selectApp: (
    action: SegmentPropertyType<'SSO Select App Clicked', 'Action'>,
    appName: string | undefined
  ) => applicationsDrawerSegmentActions.selectAppClicked(action, appName),
  discard: () => createAction(ApplicationDrawerActionTypes.DISCARD),
  finish: () => createAction(ApplicationDrawerActionTypes.FINISH),
  onAssignUsersClicked: () =>
    applicationsDrawerSegmentActions.assignUsersClicked(),
  saveAndExit: (
    app: SelectedApplication,
    formValues,
    finallyCallback,
    isAnyEntityAssigned: boolean
  ) =>
    createAction(ApplicationDrawerActionTypes.SAVE_AND_EXIT, {
      app,
      formValues,
      finallyCallback,
      isAnyEntityAssigned
    }),
  saveAndContinue: () =>
    createAction(ApplicationDrawerActionTypes.SAVE_AND_CONTINUE),
  nextStep: (nextStep: ApplicationDrawerSteps) =>
    createAction(ApplicationDrawerActionTypes.NEXT_STEP, { nextStep }),
  setStep: (step: ApplicationDrawerSteps, application: SelectedApplication) =>
    createAction(ApplicationDrawerActionTypes.SET_STEP, { step, application }),
  getAvailableApplications: () =>
    createAction(ApplicationDrawerActionTypes.GET_AVAILABLE_APPLICATIONS),
  setAvailableApplications: (list: AvailableApplication[]) =>
    createAction(ApplicationDrawerActionTypes.SET_AVAILABLE_APPLICATIONS, {
      list
    }),
  setSelectedApplication: (selectedApp: SelectedApplication | null) =>
    createAction(ApplicationDrawerActionTypes.SET_SELECTED_APPLICATION, {
      selectedApp
    }),
  setDrawerLoading: (loading: boolean) =>
    createAction(ApplicationDrawerActionTypes.SET_DRAWER_LOADING, { loading }),
  getInitialData: (params: DataFetchParams<{}, UserDrawerUrlParams>) =>
    createAction(ApplicationDrawerActionTypes.GET_INITIAL_DATA, { params }),
  setSamlKeys: (samlKeys: SamlKey[]) =>
    createAction(ApplicationDrawerActionTypes.SET_SAML_KEYS, { samlKeys }),
  setUsernameAttributes: (attributes: UsernameAttribute[]) =>
    createAction(ApplicationDrawerActionTypes.SET_USERNAME_ATTRIBUTES, {
      attributes
    }),
  getUserList: (params: DataFetchParams<QueryParams>, resultsPerPage = 1000) =>
    createAction(ApplicationDrawerActionTypes.GET_USER_LIST, {
      params,
      resultsPerPage
    }),
  setUserListSearchInput: (searchString: string) =>
    createAction(ApplicationDrawerActionTypes.SET_USER_LIST_SEARCH_INPUT, {
      searchString
    }),
  setAvailableUserList: (
    list: User[],
    totalResults: number,
    resultsPerPage = 1000
  ) =>
    createAction(ApplicationDrawerActionTypes.SET_AVAILABLE_USER_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  setUserListLoading: (loading: boolean) =>
    createAction(ApplicationDrawerActionTypes.SET_USER_LIST_LOADING, {
      loading
    }),
  addSelectedUsers: (records: User[]) =>
    createAction(ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableUsers
    }),
  removeSelectedUsers: (records: User[]) =>
    createAction(ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableUsers
    }),
  setSelectedUsers: (records: User[]) =>
    createAction(
      ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableUsers
      }
    ),
  addSelectedUsersToUnassign: (records: User[]) =>
    createAction(ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users
    }),
  removeSelectedUsersToUnassign: (records: User[]) =>
    createAction(ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users
    }),
  setSelectedUsersToUnassign: (records: User[]) =>
    createAction(
      ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.users
      }
    ),
  getGroupList: (params: DataFetchParams<QueryParams>, resultsPerPage = 1000) =>
    createAction(ApplicationDrawerActionTypes.GET_GROUP_LIST, {
      params,
      resultsPerPage
    }),
  setGroupListSearchInput: (searchString: string) =>
    createAction(ApplicationDrawerActionTypes.SET_GROUP_LIST_SEARCH_INPUT, {
      searchString
    }),
  setGroupListLoading: (loading: boolean) =>
    createAction(ApplicationDrawerActionTypes.SET_GROUP_LIST_LOADING, {
      loading
    }),
  setAvailableGroupList: (
    list: Group[],
    totalResults: number,
    resultsPerPage = 1000
  ) =>
    createAction(ApplicationDrawerActionTypes.SET_AVAILABLE_GROUP_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  addSelectedGroups: (records: Group[]) =>
    createAction(ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableGroups
    }),
  removeSelectedGroups: (records: Group[]) =>
    createAction(ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableGroups
    }),
  setSelectedGroups: (records: Group[]) =>
    createAction(
      ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableGroups
      }
    ),
  addSelectedGroupsToUnassign: (records: Group[]) =>
    createAction(ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups
    }),
  removeSelectedGroupsToUnassign: (records: Group[]) =>
    createAction(ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups
    }),
  setSelectedGroupsToUnassign: (records: Group[]) =>
    createAction(
      ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.groups
      }
    ),
  getRoleList: (params: DataFetchParams<QueryParams>, resultsPerPage = 1000) =>
    createAction(ApplicationDrawerActionTypes.GET_ROLE_LIST, {
      params,
      resultsPerPage
    }),
  setRoleListSearchInput: (searchString: string) =>
    createAction(ApplicationDrawerActionTypes.SET_ROLE_LIST_SEARCH_INPUT, {
      searchString
    }),
  setRoleListLoading: (loading: boolean) =>
    createAction(ApplicationDrawerActionTypes.SET_ROLE_LIST_LOADING, {
      loading
    }),
  setAvailableRoleList: (
    list: Role[],
    totalResults: number,
    resultsPerPage = 1000
  ) =>
    createAction(ApplicationDrawerActionTypes.SET_AVAILABLE_ROLE_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  addSelectedRoles: (records: Role[]) =>
    createAction(ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableRoles
    }),
  removeSelectedRoles: (records: Role[]) =>
    createAction(ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableRoles
    }),
  setSelectedRoles: (records: Role[]) =>
    createAction(
      ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableRoles
      }
    ),
  addSelectedRolesToUnassign: (records: Role[]) =>
    createAction(ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.roles
    }),
  removeSelectedRolesToUnassign: (records: Role[]) =>
    createAction(ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.roles
    }),
  setSelectedRolesToUnassign: (records: Role[]) =>
    createAction(
      ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.roles
      }
    ),
  assign: () => createAction(ApplicationDrawerActionTypes.ASSIGN),
  unassign: () => createAction(ApplicationDrawerActionTypes.UNASSIGN),
  getAssignedElements: (appId: string) =>
    createAction(ApplicationDrawerActionTypes.GET_ASSIGNED_ELEMENTS, {
      appId
    }),
  setAssignedElements: (users: User[], groups: Group[], roles: Role[]) =>
    createAction(ApplicationDrawerActionTypes.SET_ASSIGNED_ELEMENTS, {
      users,
      groups,
      roles
    }),
  setAssignPageOpened: (opened: boolean) =>
    createAction(ApplicationDrawerActionTypes.SET_ASSIGN_PAGE_OPENED, {
      opened
    }),
  onAssignUsersViewed: () =>
    applicationsDrawerSegmentActions.assignUsersViewed(),
  onSsoAppSavedWithTracking: (appName: string, isCustom: boolean) =>
    applicationsDrawerSegmentActions.ssoAppSaved(appName, isCustom),
  onSsoAppSaved: () =>
    createAction(ApplicationDrawerActionTypes.SSO_APP_SAVED, {}),
  onConfigureAppViewed: () =>
    applicationsDrawerSegmentActions.configureAppViewed(),
  onConfigureAppClicked: (
    action: SegmentPropertyType<'SSO Configure App Clicked', 'Action'>
  ) => applicationsDrawerSegmentActions.configureAppClicked(action),
  onSelectAppViewed: () => applicationsDrawerSegmentActions.selectAppViewed(),
  showTable: (table: string) =>
    createAction(ApplicationDrawerActionTypes.SHOW_TABLE, {
      table
    }),
  deleteApplication: (appId: string, appName: string) =>
    createAction(ApplicationDrawerActionTypes.DELETE_APPLICATION, {
      appId,
      appName
    }),
  enableMultifactor: (policyKey: string, users: User[], groups: Group[]) =>
    createAction(ApplicationDrawerActionTypes.ENABLE_MULTIFACTOR, {
      policyKey,
      users,
      groups
    }),
  setTestLoginSucessState: () =>
    createAction(ApplicationDrawerActionTypes.SET_TEST_LOGIN_SUCCESS_STATE, {})
}

export type ApplicationDrawerActions = ActionsUnion<
  typeof applicationsDrawerActions
>

import React, { useState } from 'react'

import styled from '@emotion/styled'
import { FormikErrors } from 'formik'

import { ReactComponent as TrashIcon } from '@lastpass/assets/svg/admin-console/icon-trash.svg'
import { Checkbox } from '@lastpass/lastkit/components/Checkbox'
import { CheckboxCell } from '@lastpass/lastkit/components/Table'

import { ExtendableFormCell } from './ExtendableFormCell'

export interface ExtendableFormRowProps<T> {
  record: T
  recordIndex: number
  lastRecord: boolean
  checkboxDisabled: boolean
  addRecord: Function
  columns: {
    name: string
    width?: string
    required?: boolean
  }[]
  checkedRecords: T[]
  checkable: {
    addSelectedRecords: (records: T[]) => void
    removeSelectedRecords: (records: T[]) => void
    setSelectedRecords: (records: T[]) => void
  }
  focus?: boolean
  remove?: (records: T | T[]) => void
  setRecordField: (row: number, column: string, value: string) => void
  isDuplicate: boolean
  error?: FormikErrors<T> | string
  searchInputFocused: boolean
  setSearchInputFocused: Function
}

const StyledRow = styled.div<{
  error: boolean
  checked?: boolean
}>`
  display: flex;
  flex-direction: row;
  height: ${props => (props.error ? '80px' : 'auto')};
  background: ${props =>
    props.checked ? props.theme.colors.blue200 : 'transparent'};
  box-shadow: none;
  &:hover {
    background: ${props =>
      props.checked ? props.theme.colors.blue200 : props.theme.colors.blue100};
  }
  width: 100%;
`

const StyledIconButton = styled.button<{ isHovering?: boolean }>`
  visibility: ${props => (props.isHovering ? 'visible' : 'hidden')};
  background: none;
  cursor: pointer;
  outline: none;
  border: none;
  align-self: center;
  &:focus {
    outline: auto;
  }
  text-align: center;
  display: flex;
`

const StyledTrashIcon = styled(TrashIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: ${props => props.theme.colors.red700};
`

export const ExtendableFormRow = <T extends {}>(
  extendableFormRowProps: ExtendableFormRowProps<T>
) => {
  const [hover, setHover] = useState(false)
  return (
    <StyledRow
      error={extendableFormRowProps.error != undefined}
      checked={extendableFormRowProps.checkedRecords.includes(
        extendableFormRowProps.record
      )}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <CheckboxCell contentWidth={'auto'}>
        <Checkbox
          onChange={event =>
            event.currentTarget.checked
              ? extendableFormRowProps.checkable.addSelectedRecords([
                  extendableFormRowProps.record
                ])
              : extendableFormRowProps.checkable.removeSelectedRecords([
                  extendableFormRowProps.record
                ])
          }
          checked={extendableFormRowProps.checkedRecords.includes(
            extendableFormRowProps.record
          )}
          disabled={extendableFormRowProps.checkboxDisabled}
          data-qa={'AddUserFormCheckbox'}
        />
      </CheckboxCell>

      {extendableFormRowProps.columns.map((column, columnIndex) => {
        const keys = ['email', 'firstName', 'lastName']
        return (
          <ExtendableFormCell
            value={extendableFormRowProps.record[keys[columnIndex]]}
            updateValue={(value: string) => {
              extendableFormRowProps.setRecordField(
                extendableFormRowProps.recordIndex,
                keys[columnIndex],
                value
              )
            }}
            key={columnIndex}
            focus={extendableFormRowProps.focus}
            searchInputFocused={extendableFormRowProps.searchInputFocused}
            setSearchInputFocused={extendableFormRowProps.setSearchInputFocused}
            columnIndex={columnIndex}
            lastFieldOfLastRecord={
              extendableFormRowProps.lastRecord &&
              columnIndex == extendableFormRowProps.columns.length - 1
            }
            lastRecord={extendableFormRowProps.lastRecord}
            name={keys[columnIndex]}
            width={column.width}
            required={column.required}
            recordIndex={extendableFormRowProps.recordIndex}
            addRecord={extendableFormRowProps.addRecord}
            error={
              extendableFormRowProps.error &&
              extendableFormRowProps.error[
                Object.keys(extendableFormRowProps.record)[columnIndex]
              ]
            }
            showDuplicateError={
              columnIndex == 0 && extendableFormRowProps.isDuplicate
            }
          />
        )
      })}
      {!extendableFormRowProps.focus && (
        <StyledIconButton
          isHovering={hover}
          type="button"
          onClick={() => {
            extendableFormRowProps.remove &&
              extendableFormRowProps.remove(extendableFormRowProps.record)
            extendableFormRowProps.checkable.removeSelectedRecords([
              extendableFormRowProps.record
            ])
          }}
        >
          <StyledTrashIcon data-qa={'ClearFieldsButton'} />
        </StyledIconButton>
      )}
    </StyledRow>
  )
}

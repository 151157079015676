import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { UserLevelWithPermissions } from '@lastpass/admin-console-dependencies/types/user-level'
import { AdminLevelDrawerUsersTable } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/users/AdminLevelDrawerUsersTable'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'

interface ViewAndEditAssignedUsersDrawerProps {
  closeLink: string
  backButton?: React.ReactElement
}

export const ViewAndEditAssignedUsersDrawer: React.FunctionComponent<ViewAndEditAssignedUsersDrawerProps> = ({
  closeLink,
  backButton
}) => {
  const adminLevelState: UserLevelWithPermissions = useSelector(
    (state: AppState) => state.adminLevelDrawer.adminLevel
  )

  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      adminLevelDrawerActions.getCurrentAdminLevel({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  useUnmountEffect(() => {
    adminLevelDrawerActions.reset()
  })

  return (
    <Drawer
      mainTitle={<UserLevelLabel userLevel={adminLevelState} />}
      closeLink={closeLink}
      headerNodes={backButton}
    >
      <AdminLevelDrawerUsersTable />
    </Drawer>
  )
}

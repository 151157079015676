import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading100 } from '@lastpass/lastkit'

interface PasswordlessAllSetProps {
  isEnabledForAllUsers: boolean
  groups: string[]
  users: string[]
  excludedGroups: string[]
  excludedUsers: string[]
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
`

const BlockHeader = styled.h6`
  font-weight: ${props => props.theme.fonts.weight.semibold};
  margin-top: 24px;
  margin-bottom: 4px;
`
const maxElementsInList = 5

const getItemsList = (items: string[]) =>
  items.length > maxElementsInList
    ? items.length
    : items.map((item, index) => <div key={index}>{item}</div>)

const getBlock = (groups: string[], users: string[]) => (
  <>
    {groups.length > 0 ? (
      <>
        <BlockHeader data-qa="AssignedGroups">
          <Trans>Groups</Trans>
        </BlockHeader>
        {getItemsList(groups)}
      </>
    ) : null}
    {users.length > 0 ? (
      <>
        <BlockHeader data-qa="AssignedUsers">
          <Trans>Users</Trans>
        </BlockHeader>
        {getItemsList(users)}
      </>
    ) : null}
  </>
)

export const PasswordlessAllSet: React.FunctionComponent<PasswordlessAllSetProps> = ({
  users,
  groups,
  isEnabledForAllUsers,
  excludedGroups,
  excludedUsers
}) => {
  let includedContent: React.ReactElement | null = null
  let excludedContent: React.ReactElement | null = null
  if (!isEnabledForAllUsers) {
    if (groups.length > 0 || users.length > 0) {
      includedContent = getBlock(groups, users)
    }

    if (excludedGroups.length > 0 || excludedUsers.length > 0) {
      excludedContent = (
        <>
          <Heading100
            data-qa="AllSetHeader"
            css={css`
              margin-top: 24px;
            `}
          >
            <Trans>All users except</Trans>
          </Heading100>
          {getBlock(excludedGroups, excludedUsers)}
        </>
      )
    }
  }

  return (
    <MainContainer>
      <Trans>
        Assigned users should receive their invitation emails soon. Once
        enrolled, they must verify their identity using LastPass Authenticator
        each time they log in to a protected service.
      </Trans>{' '}
      {includedContent}
      {excludedContent}
    </MainContainer>
  )
}

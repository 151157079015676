import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'

export const SUCCESS = 'success'
export const FAIL = 'fail'

const success = () => createResponse(SUCCESS)
const fail = () => createResponse(FAIL)

export const Responses = {
  success,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(adminLevels: UserLevelDetails[]) {
    const [response, status] = await fetch(
      'bulk/adminLevels',
      'DELETE',
      adminLevels.map(adminLevelDetails => adminLevelDetails.id),
      { ['content-type']: 'application/json' }
    )

    if (status === StatusCodes.OK) {
      const result = JSON.parse(response)
      if (result.status === 'ok') {
        return success()
      } else if (result.status === 'serverFailure') {
        return fail()
      }
    }
    throw new UnknownServiceError()
  }
}

import { call } from 'redux-saga/effects'
import { put, select } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/get-user-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export const getApplicationDrawerState = (state: AppState) =>
  state.applicationDrawer

export function createUserList(applicationsServices: UACServices.Services) {
  return function* getUserList(
    action: ReturnType<typeof applicationsDrawerActions.getUserList>
  ) {
    try {
      const applicationDrawerState: ApplicationDrawerState = yield select(
        getApplicationDrawerState
      )
      const resultsPerPage = action.payload.resultsPerPage
      const searchQuery = action.payload.params.query.search
      const searchKey = searchQuery
        ? searchQuery
        : applicationDrawerState.usersSearchInput
      const skip = action.payload.params.query.skip
        ? action.payload.params.query.skip
        : 1

      yield put(applicationsDrawerActions.setUserListLoading(true))
      try {
        const SSOApplicationUserListResult: Responses = yield call(
          applicationsServices.getIdentityUsers,
          searchKey,
          resultsPerPage,
          skip
        )
        if (
          SSOApplicationUserListResult.type ===
          UACServices.SSOIdentityUserAPI.SUCCESS
        ) {
          const ssoIdentityUsersResponse =
            SSOApplicationUserListResult.body.userResponse.results
          const ssoIdentityUserList: User[] = ssoIdentityUsersResponse.map(
            (user: User) => {
              return {
                id: user.id,
                name: user.name
              }
            }
          )
          const filteredResultList = ssoIdentityUserList.filter(
            usersFromResult =>
              applicationDrawerState.users.results.filter(
                usersFromState => usersFromState.id == usersFromResult.id
              ).length === 0
          )
          yield put(
            applicationsDrawerActions.setAvailableUserList(
              filteredResultList,
              filteredResultList.length,
              resultsPerPage
            )
          )
        }
      } finally {
        yield put(applicationsDrawerActions.setUserListLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

const wait = waitTime => new Promise(resolve => setTimeout(resolve, waitTime))

export const fetchRetry = async (
  input: RequestInfo | URL,
  init: RequestInit | undefined,
  maxNumberOfRetries = 3,
  waitTime = 1000
): Promise<Response> => {
  if (maxNumberOfRetries < 1) {
    maxNumberOfRetries = 1
  }

  const retryOnStatusCodes: StatusCodes[] = [
    StatusCodes.REQUEST_TIMEOUT,
    StatusCodes.TOO_MANY_REQUESTS,
    StatusCodes.INTERNAL_SERVER_ERROR,
    StatusCodes.BAD_GATEWAY,
    StatusCodes.SERVICE_UNAVAILABLE,
    StatusCodes.GATEWAY_TIMEOUT,
    StatusCodes.INSUFFICIENT_STORAGE
  ]

  let response
  for (let tryCnt = 0; tryCnt <= maxNumberOfRetries; tryCnt++) {
    let error
    try {
      response = await fetch(input, init)
    } catch (e) {
      error = e
    }

    if (error || retryOnStatusCodes.includes(response?.status)) {
      if (maxNumberOfRetries === tryCnt) {
        if (error) {
          throw error
        } else {
          return response
        }
      }
      console.error(
        `Failed to fetch ${input}. #${tryCnt + 1} re-try in ${waitTime}ms.`
      )
      await wait(waitTime)
    } else {
      break
    }
  }

  return response
}

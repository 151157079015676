import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'

import { RadioButton } from '@lastpass/lastkit'

const RadioButtonContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  span {
    vertical-align: middle;
  }
`

export const SyncTypeSelection: FC<{
  isSpecifiedSelected: boolean
  setIsSpecifiedSelected: (value: boolean) => void
}> = ({ isSpecifiedSelected, setIsSpecifiedSelected }) => {
  return (
    <>
      <RadioButtonContainer data-qa="GoogleIntegrationSyncOptionsSyncAllUsersRadioButton">
        <RadioButton
          option={{ value: 'allusers', label: msg`Sync all users and groups` }}
          key={'sync-all-users'}
          checked={!isSpecifiedSelected}
          onChange={() => {
            setIsSpecifiedSelected(false)
          }}
        />
      </RadioButtonContainer>
      <RadioButtonContainer data-qa="GoogleIntegrationSyncOptionsSyncSpecifiedUsersRadioButton">
        <RadioButton
          option={{
            value: 'specifiedusers',
            label: msg`Sync selected users and groups`
          }}
          checked={isSpecifiedSelected}
          key={'sync-all-users'}
          onChange={() => {
            setIsSpecifiedSelected(true)
          }}
        />
      </RadioButtonContainer>
    </>
  )
}

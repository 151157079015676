import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as FilterIcon } from '@lastpass/assets/svg/admin-console/icon-filter.svg'
import { Pagination, SearchInput, TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'

import { FlexContainer } from '../shared/AdvancedUserActivityStyledComponents'
import { AdvancedUserActivityDateRange } from './AdvancedUserActivityDateRange'
import { AdvancedUserActivityReportSelector } from './AdvancedUserActivityReportSelector'

const FilterCtaContainer = styled.div`
  display: flex;
  white-space: nowrap;

  svg {
    margin: 12px 7px 12px 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

export const AdvancedUserActivityListSearch: React.FunctionComponent<{
  state: AdvancedUserActivityListState
}> = ({ state }) => {
  const queryParams = useQueryParams(Namespace.userReports)
  const updateQuery = useUpdateQuery(Namespace.userReports)
  const routeMatch = useRouteMatch()

  if (!routeMatch) {
    return null
  }

  const { url } = routeMatch

  return (
    <Wrapper>
      <FlexContainer
        css={css`
          gap: 24px;
        `}
      >
        <AdvancedUserActivityReportSelector />
        <SearchInput
          css={css`
            margin: 0;
          `}
          onChange={event => {
            updateQuery({
              search: event.target.value || undefined,
              skip: 0
            })
          }}
          value={queryParams.search || ''}
          placeholder={msg`Search event, information or user...`}
          data-qa={'AdvancedReportsSearchField'}
        />
        <AdvancedUserActivityDateRange />

        <LocationLink to={`${url}/filter`}>
          <FilterCtaContainer>
            <FilterIcon />
            <TextButton data-qa={'FilterAdvancedReportsButton'} blue>
              <Trans>Filter reports</Trans>
            </TextButton>
          </FilterCtaContainer>
        </LocationLink>
      </FlexContainer>

      <FlexContainer>
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={state.table.totalResults}
          resultsPerPage={state.table.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip })
          }}
        />
      </FlexContainer>
    </Wrapper>
  )
}

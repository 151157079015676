import React, { PropsWithChildren } from 'react'

import styled from '@emotion/styled'

export interface TabNavigationItemProps {
  active?: boolean
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void
}

const Container = styled.div<{ active?: boolean }>`
  position: relative;
  background: ${props => (props.active ? props.theme.colors.blue200 : '')};
  border-radius: 3px;
  padding: 9px 12px;
  font-weight: ${props => props.theme.fonts.weight.semibold};
  letter-spacing: 0.24px;
  font-size: 14px;
  line-height: 24px;
  color: ${props =>
    props.active ? props.theme.colors.blue700 : props.theme.colors.neutral900};
`

const ActiveTabIndicator = styled.div`
  height: 2px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: ${props => props.theme.colors.blue700};
  border-radius: 0px 0px 3px 3px;
`

export const TabNavigationItem: React.FunctionComponent<PropsWithChildren<
  TabNavigationItemProps
>> = props => {
  return (
    <Container {...props}>
      {props.children}
      {props.active && <ActiveTabIndicator data-qa={'TabMenuSelected'} />}
    </Container>
  )
}

import React, { ReactElement } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as SunSetIcon } from '@lastpass/assets/svg/admin-console/sun-set-icon.svg'
import { Heading100 } from '@lastpass/lastkit'

export const EmptyPageTextBlock = styled.div`
  margin-bottom: 30px;
  line-height: 1.5;
`

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 65vh;
`

const StyledSunSetIcon = styled(SunSetIcon)`
  margin-bottom: 20px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 15px;
`

const BodyContainer = styled.div`
  text-align: left;
  max-width: 600px;
`

interface EmptyPageAfterSystemUpgradeProps {
  title: ReactElement
}

export const EmptyPageAfterSystemUpgrade: React.FunctionComponent<EmptyPageAfterSystemUpgradeProps> = ({
  title,
  children
}) => {
  return (
    <StyledCenter>
      <StyledSunSetIcon data-qa="EmptyPageSunSetIcon" />
      <StyledHeading100 data-qa="EmptyPageTitle">{title}</StyledHeading100>
      <BodyContainer data-qa="EmptyPageBody">{children}</BodyContainer>
    </StyledCenter>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  ServiceError,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { Policy } from '@lastpass/admin-console-dependencies/types/policy'

export const SUCCESS = 'success'

const success = (policies: Policy[], totalResults: number) =>
  createResponse(SUCCESS, { policies, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userId: string, queryPath: string) {
    const [response, status] = await fetch(
      'users/' + userId + '/policies' + queryPath,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const result = JSON.parse(response)
      if (result.status === 'ok') {
        const policies = JSON.parse(response).content.items
        const totalResults = JSON.parse(response).content.totalCount
        return success(policies, totalResults)
      } else {
        throw new ServiceError(result.status)
      }
    }
    throw new UnknownServiceError()
  }
}

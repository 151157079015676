import React from 'react'

import css from '@emotion/css'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as IconArrowDown } from '@lastpass/assets/svg/admin-console/icon-arrow-down.svg'
import { ReactComponent as IconArrowUp } from '@lastpass/assets/svg/admin-console/icon-arrow-up.svg'

import { TableAction } from '@lastpass/admin-console-dependencies/types/record-action'
import { Table as TableType } from '@lastpass/admin-console-dependencies/types/table'

import { CaptionSemiboldStyle } from '../styles'
import { ThemeColor } from '../theme'
import { Divider } from './Divider'
import { Dropdown } from './Dropdown'
import { DropdownListItem } from './DropdownListItem'
import { IconButton } from './IconButton'

interface MoreActionProps<T> {
  actions: TableAction<T>[]
  table: TableType<T>
  visibleActionsLength: number
  executeAction
}

export const buttonStyle = css`
  border: 1px solid transparent;
  box-shadow: none;
  height: 16px;
  width: 26px;
  border-radius: 0;
  padding: 0 7px;
  background: transparent;
  &:hover {
    border: 1px solid transparent;
  }
`

export const HeaderTextButton = styled.a<{ color?: ThemeColor }>`
  ${CaptionSemiboldStyle}
  white-space: nowrap;
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.blue700};
  cursor: pointer;
  margin-right: 24px;
  &:hover {
    color: ${props =>
      props.color ? props.theme.colors.neutral900 : props.theme.colors.blue800};
  }
`

export const HeaderText = styled.span<{ color?: ThemeColor }>`
  ${CaptionSemiboldStyle}
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.blue700};
  cursor: pointer;
  margin: 0;
  padding: 0;
  vertical-align: top;
  &:hover{
    color: ${props =>
      props.color ? props.theme.colors.neutral900 : props.theme.colors.blue800};
  }
`

export const Button = styled(props => (
  <IconButton icon={IconArrowDown} color="blue700" {...props} />
))`
  ${buttonStyle}
`

export const ExpandedButton = styled(props => (
  <IconButton icon={IconArrowUp} color="blue700" {...props} />
))`
  ${buttonStyle}
`

const moreActions = () => (
  <HeaderText>
    <Trans>More actions&nbsp;</Trans>
  </HeaderText>
)

export const MoreAction = <T extends {}>({
  actions,
  table,
  visibleActionsLength,
  executeAction
}: MoreActionProps<T>) => {
  const otherActions = actions.slice(visibleActionsLength, actions.length)
  const firstCriticalActionIndex: number = otherActions.findIndex(action => {
    return action.isCritical
  })

  const hasDivider = firstCriticalActionIndex > 0

  return (
    <Dropdown
      css={css`
        height: 13px;
        top: 0;
      `}
      button={Button}
      expandedButton={ExpandedButton}
      customLabel={moreActions}
      data-qa="TableMoreActionButton"
    >
      {actions &&
        otherActions.map((action, index) => {
          const isValid = action.isValid
            ? action.isValid
            : () => {
                return true
              }

          return (
            !action.hidden &&
            !action.isCritical &&
            isValid(table.checkedRecords) &&
            !(action.singleItem && table.checkedRecords.length !== 1) && (
              <DropdownListItem
                key={index}
                data-qa={action.tag ?? 'TableHeaderMoreActionDropdownItem'}
              >
                <p>
                  <HeaderTextButton
                    onClick={executeAction(action)}
                    color={action.color}
                  >
                    {action.name}
                  </HeaderTextButton>
                </p>
              </DropdownListItem>
            )
          )
        })}
      {hasDivider && <Divider />}
      {actions &&
        otherActions.map((action, index) => {
          const isValid = action.isValid
            ? action.isValid
            : () => {
                return true
              }

          return (
            action.isCritical &&
            !action.hidden &&
            isValid(table.checkedRecords) &&
            !(action.singleItem && table.checkedRecords.length !== 1) && (
              <DropdownListItem
                key={index}
                data-qa={action.tag ?? 'TableHeaderMoreActionDropdownItem'}
              >
                <p>
                  <HeaderTextButton
                    onClick={executeAction(action)}
                    color={action.color}
                  >
                    {action.name}
                  </HeaderTextButton>
                </p>
              </DropdownListItem>
            )
          )
        })}
    </Dropdown>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'
import { MfaTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

export const SUCCESS = 'success'
export const FAIL = 'fail'

// Fail respose is needed because it's a single endpoint used for bulk actions
const success = () => createResponse(SUCCESS)
const fail = () => createResponse(FAIL)

export const Responses = {
  success,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    mfaDevice: MfaTrustedDevice,
    userId: string,
    isEnabled: boolean
  ) {
    const [response, status] = await fetch(
      `users/${userId}/trusted-devices/mfa-applications/${mfaDevice.id}`,
      'PUT',
      { isEnabled: isEnabled },
      { 'content-type': 'application/json' }
    )
    if (response && status === StatusCodes.OK) {
      return success()
    } else {
      return fail()
    }
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPolicySwitchONSaveAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { convertFileToBase64String } from '@lastpass/admin-console-dependencies/services/file-converter'
import { RSASecureIDRadius } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

function* getLogoBase64(logo: File | string | null | undefined) {
  if (!logo) {
    return ''
  }

  let base64str: string
  if (typeof logo === 'string') {
    base64str = logo
  } else {
    base64str = yield call(convertFileToBase64String, logo)
  }

  return base64str.replace(/^data:.+;base64,/, '')
}

export function createSaveRsaSettings(
  multifactorServices: UACServices.Services
) {
  return function* saveRsaSettings(
    action: ReturnType<typeof multifactorDrawerActions.saveRsaSettings>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))

      const formValues = action.payload.rsaConfig
      const rsaPolicyData = action.payload.policyData
      const rsaConfig: RSASecureIDRadius = {
        failureMessage: formValues.failureMessage,
        hasRadiusSharedSecret: formValues.hasRadiusSharedSecret,
        logo124x124: yield call(getLogoBase64, formValues.logo124x124),
        logo124x124Length: formValues.logo124x124Length,
        logo190x41: yield call(getLogoBase64, formValues.logo190x41),
        logo190x41Length: formValues.logo190x41Length,
        radiusServerIp: formValues.radiusServerIp,
        radiusSharedSecret: formValues.radiusSharedSecret,
        radiusSharedSecretLength: formValues.radiusSharedSecretLength,
        radiusTimeout: Number(formValues.radiusTimeout),
        serviceName: formValues.serviceName
      }

      try {
        const [saveRsaSettingsResponse, saveRsaPolicyDataResponse] = yield all([
          call(multifactorServices.rsaSecureIDRadiusSave, rsaConfig),
          call(multifactorServices.generalPolicySwitchOnSave, rsaPolicyData)
        ])

        if (
          saveRsaSettingsResponse.type ===
            UACServices.RSASecureIDRadiusSaveAPI.SUCCESS &&
          saveRsaPolicyDataResponse.type ===
            GeneralPolicySwitchONSaveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else if (
          saveRsaPolicyDataResponse.type ===
          GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`This policy can't have multiple configurations.`
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components'
import { ContentContainer, Heading300, TextButton } from '@lastpass/lastkit'
import { AnimatedSwitch } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  managedCompaniesActions,
  ManagedCompanyListQueryParams
} from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import { ManagedCompaniesState } from '@lastpass/admin-console-dependencies/state/home/managed-companies/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { IndividualCompanyAgreementDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/individual-company/IndividualCompanyAgreementDrawer'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { ManagedCompanyAggregate } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/aggregate/ManagedCompanyAggregate'
import { CreateManagedCompanyDrawer } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/create-managed-company-drawer/CreateManagedCompanyDrawer'
import { ManagedCompanyDetailsDrawerComponent } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/details-drawer/ManagedCompanyDetailsDrawer'
import { AllocateLicensesDrawer } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/license-operation-drawers/AllocateLicensesDrawer'
import { ReturnLicensesDrawer } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/license-operation-drawers/ReturnLicensesDrawer'
import { ManagedCompanyList } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/ManagedCompanyList'

const HeadingContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ManagedCompaniesPage: FC = () => {
  const permissions = useContext(PermissionContext)
  const managedCompaniesState: ManagedCompaniesState = useSelector(
    (state: AppState) => state.managedCompanies
  )

  const queryParams: ManagedCompanyListQueryParams = useQueryParams(
    Namespace.managedCompanies
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(managedCompaniesActions.getList({ query: queryParams, path: {} }))
  }, [dispatch, queryParams])

  const match = useRouteMatch()

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const isMspSupportEnabled = useFeatureFlags(FeatureFlags.isMspSupportEnabled)

  if (!match) {
    return null
  }
  const detailsDrawerPath = `${match.path}/:id`
  const pageUrl = match.url

  const createManagedCompanyPermission = isRoleBasedAccessControlEnabled
    ? AdminPermissions.addManagedCompany
    : AdminPermissions.managedCompanyAccess

  const canCreateManagedCompanies = permissions.requirePermission(
    createManagedCompanyPermission
  )

  return (
    <>
      <ContentContainer>
        {managedCompaniesState.isLoading && <Loading color="blue900" active />}
        <HeadingContainer>
          <Heading300 data-qa="ManagedCompanyListHeader">
            <Trans>Managed companies</Trans>
          </Heading300>
          {isMspSupportEnabled && (
            <TextButton
              data-qa="SupportButton"
              blue
              onClick={() => {
                dispatch(
                  globalActions.setDialog({ type: 'msp-support-dialog' })
                )
              }}
            >
              <Trans>Support</Trans>
            </TextButton>
          )}
        </HeadingContainer>
        <ManagedCompanyAggregate {...managedCompaniesState.aggregate} />
        <ManagedCompanyList table={managedCompaniesState.table} />
      </ContentContainer>
      <AnimatedSwitch timeout={200}>
        {canCreateManagedCompanies && (
          <Route path={`${match.path}/create`}>
            <CreateManagedCompanyDrawer closeLink={pageUrl} />
          </Route>
        )}
        <Route path={`${detailsDrawerPath}/allocate-licenses`}>
          <AllocateLicensesDrawer
            closeLink={pageUrl}
            backPath={detailsDrawerPath}
          />
        </Route>
        <Route path={`${detailsDrawerPath}/return-licenses`}>
          <ReturnLicensesDrawer
            closeLink={pageUrl}
            backPath={detailsDrawerPath}
          />
        </Route>
        <Route path={`${detailsDrawerPath}/agreements`}>
          <IndividualCompanyAgreementDrawer
            closeLink={pageUrl}
            backLink={match.path}
          />
        </Route>
        <Route path={detailsDrawerPath}>
          <ManagedCompanyDetailsDrawerComponent closeLink={pageUrl} />
        </Route>
      </AnimatedSwitch>
    </>
  )
}

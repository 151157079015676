import React, { FunctionComponent, useState } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { BodyBoldStyle, BodyRegularStyle } from '../styles'

const FileUploadContainer = styled.div`
  display: flex;
`

const FileUploadLabel = styled.label`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.blue700};
  cursor: pointer;
`

const FileUploadInput = styled.input`
  display: none;
`

const DeleteFile = styled.div`
  ${BodyRegularStyle};
  margin-left: 14px;
  color: ${props => props.theme.colors.blue700};
  cursor: pointer;
`

const FileDetailsLabel = styled.label`
  ${BodyRegularStyle};
  margin-left: 24px;
`
interface FileInputProps {
  label: MessageDescriptor
  onChange: (e: React.ChangeEvent<HTMLInputElement>, file: File | null) => void
  deleteFile: Function
  acceptedFileTypes?: string
  id?: string
}

export const FileUploader: FunctionComponent<FileInputProps> = ({
  label,
  onChange,
  deleteFile,
  acceptedFileTypes,
  id
}) => {
  const [fileName, setFileName] = useState('')
  const acceptedTypes = acceptedFileTypes ? acceptedFileTypes : ''

  return (
    <FileUploadContainer>
      <FileUploadLabel
        data-qa="FileUploadButton"
        htmlFor={'fileUploadComponent' + id}
      >
        {i18n._(label)}
      </FileUploadLabel>
      <FileUploadInput
        type={'file'}
        accept={acceptedTypes}
        id={'fileUploadComponent' + id}
        onChange={e => {
          if (e && e.currentTarget && e.currentTarget.files) {
            const file = e.currentTarget.files[0] || null
            const fileNameInner = file ? e.currentTarget.files[0].name : ''
            setFileName(fileNameInner)
            onChange(e, file)
          }
        }}
      />
      {fileName.length > 0 ? (
        <>
          <FileDetailsLabel>{fileName}</FileDetailsLabel>
          <DeleteFile
            onClick={() => {
              setFileName('')
              deleteFile()
            }}
          >
            <Trans>Delete</Trans>
          </DeleteFile>
        </>
      ) : null}
    </FileUploadContainer>
  )
}

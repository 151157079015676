import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  GeneralPolicyUsersAndGroupsActions,
  GeneralPolicyUsersAndGroupsActionTypes
} from './actions'
import { GeneralPolicyUsersAndGroupsFormState } from './state'

export const initialState: GeneralPolicyUsersAndGroupsFormState = {
  policyUsersAndGroups: {
    policyKey: '',
    policyConfigIndex: 0,
    policyConfigFormData: {
      appliesTo: AppliesToOptions.all,
      users: [],
      groups: []
    }
  },

  usersAndGroupsNeedsRefresh: false,
  isUsersAndGroupsOpen: false,
  hasReceivedServerResponse: false,
  policyConfigUsers: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  policyConfigGroups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  toBeRemovedGroups: [],
  toBeRemovedUsers: []
}

export function generalPolicyUsersAndGroupsReducer(
  previousState: GeneralPolicyUsersAndGroupsFormState | undefined,
  action: GeneralPolicyUsersAndGroupsActions
): GeneralPolicyUsersAndGroupsFormState {
  const state = previousState || initialState
  switch (action.type) {
    case GeneralPolicyUsersAndGroupsActionTypes.RESET: {
      return initialState
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS: {
      return {
        ...state,
        policyUsersAndGroups: {
          policyKey: action.payload.policyUsersAndGroups.policyKey,
          policyConfigIndex:
            action.payload.policyUsersAndGroups.policyConfigIndex,
          policyConfigFormData: {
            ...action.payload.policyUsersAndGroups.policyConfigFormData
          }
        },
        policyConfigUsers: {
          results:
            action.payload.policyUsersAndGroups.policyConfigFormData.users,
          totalResults:
            action.payload.policyUsersAndGroups.policyConfigFormData.users
              .length,
          resultsPerPage:
            action.payload.policyUsersAndGroups.policyConfigFormData.users
              .length,
          checkedRecords: state.toBeRemovedUsers
        },
        policyConfigGroups: {
          results:
            action.payload.policyUsersAndGroups.policyConfigFormData.groups,
          totalResults:
            action.payload.policyUsersAndGroups.policyConfigFormData.groups
              .length,
          resultsPerPage:
            action.payload.policyUsersAndGroups.policyConfigFormData.groups
              .length,
          checkedRecords: state.toBeRemovedGroups
        },
        usersAndGroupsNeedsRefresh: true
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_NEEDS_REFRESH: {
      return {
        ...state,
        policyUsersAndGroups: {
          ...state.policyUsersAndGroups,
          policyKey: action.payload.policyKey,
          policyConfigIndex: action.payload.configKey
        },
        usersAndGroupsNeedsRefresh: action.payload.usersAndGroupsNeedsRefresh
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.RESET_GENERAL_POLICY_USERS_AND_GROUPS_TABLES: {
      return {
        ...state,
        policyConfigUsers: {
          results: [...initialState.policyConfigUsers.results],
          totalResults: initialState.policyConfigUsers.totalResults,
          resultsPerPage: initialState.policyConfigUsers.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        },
        policyConfigGroups: {
          results: [...initialState.policyConfigGroups.results],
          totalResults: initialState.policyConfigGroups.totalResults,
          resultsPerPage: initialState.policyConfigGroups.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        },
        toBeRemovedUsers: [],
        toBeRemovedGroups: []
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_LOADING: {
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          isLoading: action.payload
        }
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_POLICY_USERS: {
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          checkedRecords: state.policyConfigUsers.checkedRecords.concat(
            action.payload.userList
          )
        },
        toBeRemovedUsers: state.toBeRemovedUsers.concat(action.payload.userList)
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_POLICY_USERS: {
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          checkedRecords: state.policyConfigUsers.checkedRecords.filter(
            el => !action.payload.userList.includes(el)
          )
        },
        toBeRemovedUsers: state.toBeRemovedUsers.filter(
          el => !action.payload.userList.includes(el)
        )
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_POLICY_USERS: {
      let newToBeRemovedUsers: TeamsPolicyUserInfo[] = []
      if (action.payload.userList.length === 0) {
        newToBeRemovedUsers = state.toBeRemovedUsers.filter(
          el => !state.policyConfigUsers.results.includes(el)
        )
      } else {
        const newlySelectedUsers = action.payload.userList.filter(
          el => !state.toBeRemovedUsers.includes(el)
        )
        newToBeRemovedUsers = [...state.toBeRemovedUsers, ...newlySelectedUsers]
      }

      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          checkedRecords: [...newToBeRemovedUsers]
        },
        toBeRemovedUsers: newToBeRemovedUsers
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_GROUPS_LOADING: {
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          isLoading: action.payload
        }
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_POLICY_GROUPS: {
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          checkedRecords: state.policyConfigGroups.checkedRecords.concat(
            action.payload.groupList
          )
        },
        toBeRemovedGroups: state.toBeRemovedGroups.concat(
          action.payload.groupList
        )
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_POLICY_GROUPS: {
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          checkedRecords: state.policyConfigGroups.checkedRecords.filter(
            el => !action.payload.groupList.includes(el)
          )
        },
        toBeRemovedGroups: state.toBeRemovedGroups.filter(
          el => !action.payload.groupList.includes(el)
        )
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_POLICY_GROUPS: {
      let newToBeRemovedGroups: TeamsPolicyGroup[] = []
      if (action.payload.groupList.length === 0) {
        newToBeRemovedGroups = state.toBeRemovedGroups.filter(
          el => !state.policyConfigGroups.results.includes(el)
        )
      } else {
        const newlySelectedGroups = action.payload.groupList.filter(
          el => !state.toBeRemovedGroups.includes(el)
        )
        newToBeRemovedGroups = [
          ...state.toBeRemovedGroups,
          ...newlySelectedGroups
        ]
      }
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          checkedRecords: [...newToBeRemovedGroups]
        },
        toBeRemovedGroups: [...newToBeRemovedGroups]
      }
    }

    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_CURRENTLY_APPLIES_TO: {
      return {
        ...state,
        policyCurrentlyAppliesTo: action.payload.policyCurrentlyAppliesTo
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_OPEN: {
      return {
        ...state,
        isUsersAndGroupsOpen: action.payload.isUsersAndGroupsOpen
      }
    }
    case GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_RECEIVED_RESPONSE_FROM_SERVER: {
      return {
        ...state,
        hasReceivedServerResponse: action.payload.hasServerResponded
      }
    }
  }
  return state
}

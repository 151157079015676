import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton,
  TextInput,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { LocationLink, LocationRedirect } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import {
  groupDrawerActions,
  GroupDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  flex-basis: 100%;
  margin-right: 12px;
  margin-bottom: 28px;
`

export const EditGroup: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const groupDrawerState: GroupDrawerState['group'] = useSelector(
    (state: AppState) => state.groupDrawer.group
  )
  const pathParams: GroupDrawerUrlParams = usePathParams()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const groupId = pathParams.id
  const [formSubmitted, setFormSubmitted] = useState(false)

  useDrawerSubtitle(<Trans>Group Details</Trans>)

  if (groupDrawerState.id === '') {
    return <Loading color={'blue900'} active={true} />
  }

  return (
    <Formik
      enableReinitialize
      initialValues={groupDrawerState}
      onSubmit={values => {
        dispatch(groupDrawerActions.editGroup(values))
        setFormSubmitted(true)
        setIsBlockingPromptEnabled(false)
      }}
    >
      {formikProps => {
        return (
          <Form onSubmit={formikProps.handleSubmit}>
            {formSubmitted && (
              <LocationRedirect to={`/users/groups/${groupId}`} />
            )}
            <FormContainer onChange={() => setIsBlockingPromptEnabled(true)}>
              <InputContainer>
                <Field name="name">
                  {formData => (
                    <TextInput
                      data-qa="GroupNameInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      placeholder={msg`Group name`}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                    >
                      <Trans>Group name</Trans>
                    </TextInput>
                  )}
                </Field>
              </InputContainer>
            </FormContainer>
            <StyledSpacer />
            <FooterContainer>
              <DrawerButtonsContainer>
                <LocationLink to={`/users/groups/${groupId}`}>
                  <TextButton
                    css={css`
                      margin-right: 10px;
                    `}
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>

                <PrimaryButton
                  disabled={!!Object.keys(formikProps.errors).length}
                  onClick={() => {
                    setIsBlockingPromptEnabled(false)
                  }}
                  type="submit"
                >
                  <Trans>Save changes</Trans>
                </PrimaryButton>
              </DrawerButtonsContainer>
            </FooterContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

import React from 'react'

import styled from '@emotion/styled'

import { ReactComponent as Ellipses } from '@lastpass/assets/svg/admin-console/ellipses.svg'
import { BodyRegular, Heading100 } from '@lastpass/lastkit'

const ServiceContainer = styled.div`
  display: flex;
`

const AlignedServiceTextContainer = styled(ServiceContainer)`
  align-items: center;
  width: 100%;
  margin-left: 16px;
`

const ServiceTextContainer = styled.div`
  width: 100%;
  margin-left: 16px;
`

const IconWithEllipses = ({ icon }) => {
  const IconContainer = styled.div`
    position: relative;
  `

  const StyledEllipses = styled(Ellipses)`
    width: 48px;
    height: 48px;
    color: ${props => props.theme.colors.blue200};
  `

  const StyledIcon = styled(icon)`
    width: 24px;
    height: 24px;
    position: absolute;
    left: 12px;
    top: 12px;
    color: ${props => props.theme.colors.neutral700};
  `

  return (
    <IconContainer>
      <StyledEllipses />
      <StyledIcon />
    </IconContainer>
  )
}

export interface ServicePros {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  heading: React.ReactElement
  body?: React.ReactElement | undefined
}

export const ProtectedService: React.FunctionComponent<{
  service: ServicePros
}> = ({ service }) => {
  return (
    <ServiceContainer>
      <IconWithEllipses icon={service.icon} />
      {service.body ? (
        <ServiceTextContainer>
          <Heading100>{service.heading}</Heading100>
          {service.body && <BodyRegular>{service.body}</BodyRegular>}
        </ServiceTextContainer>
      ) : (
        <AlignedServiceTextContainer>
          <Heading100>{service.heading}</Heading100>
        </AlignedServiceTextContainer>
      )}
    </ServiceContainer>
  )
}

import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/admin-console/icon-close.svg'

import { ThemeColor } from '../theme'

export interface NotificationProps {
  text: MessageDescriptor | string | undefined
  component?: FunctionComponent
  icon: ReactElement
  backgroundColor: ThemeColor
  onDismissClick: Function
  onDetailsClick?: Function
}

const Container = styled.div`
  min-height: 48px;
  background-color: ${props => props.theme.colors.white};

  border: 1px solid #b9c0c9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(33, 48, 68, 0.12);
  border-radius: 4px;
  min-width: 320px;
`

const Icon = styled.div<{ color: ThemeColor }>`
  position: absolute;
  width: 48px;
  height: 48px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors[props.color]};
  border-radius: 4px 0 0 4px;
`

const Text = styled.div`
  padding: 12px 48px 12px 64px;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 14px;
  line-height: 24px;
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 18px;
  width: 12px;
  height: 12px;
  color: ${props => props.theme.colors.neutral700};

  &:hover {
    color: ${props => props.theme.colors.neutral900};
  }
`

const StyledLink = styled.a`
  cursor: pointer;
  margin: 0px 8px;
  color: ${props => props.theme.colors.blue700};
`

export const Notification: FunctionComponent<NotificationProps> = ({
  ...props
}) => {
  return (
    <Container data-qa={'NotificationPoUp'}>
      <Icon
        color={props.backgroundColor}
        data-qa={'NotificationPopUpIcon_' + props.backgroundColor}
      >
        {props.icon}
      </Icon>
      <Text data-qa={'NotificationPopUpText'}>
        {typeof props.text === 'string'
          ? props.text
          : !!props.text && i18n._(props.text)}
        {props.onDetailsClick && (
          <StyledLink
            onClick={() => props.onDetailsClick && props.onDetailsClick()}
          >
            {i18n._(msg`View details`)}
          </StyledLink>
        )}
        {props.component !== undefined ? (
          <span data-qa="NotificationPopUpComponent">
            <props.component />
          </span>
        ) : null}
      </Text>
      <StyledCloseIcon
        data-qa={'NotificationPopUpCloseButton'}
        onClick={() => {
          props.onDismissClick()
        }}
      />
    </Container>
  )
}

import React, { FC, useState } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  BodyBold,
  BodyRegularStyle,
  Checkbox,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ManagedCompanyDetachConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface ManagedCompanyDetachConfirmDialogProps {
  state: ManagedCompanyDetachConfirmDialog
  actions: typeof globalActions
}

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

const CompanyNameContainer = styled(StyledParagraph)`
  margin-bottom: 16px;
`

const CheckBoxContainer = styled.div`
  padding-top: 8px;
`

export const ManagedCompanyDetachConfirmDialogComponent: FC<ManagedCompanyDetachConfirmDialogProps> = ({
  state,
  actions
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const discardDialog = () => {
    actions.discardDialog()
    actions.emptyDialog()
  }

  const confirmDialog = () => {
    actions.confirmDialog()
  }

  const Content: FC = () => (
    <>
      <CompanyNameContainer data-qa="ManagedCompanyDetachConfirmDialogCompanyName">
        <Trans>Company:</Trans> <BodyBold>{state.managedCompanyName}</BodyBold>
      </CompanyNameContainer>
      <StyledParagraph>
        <Trans>
          If you detach this managed company from your primary account, you‘ll
          lose access to this company from the primary account.
        </Trans>
      </StyledParagraph>
      <StyledParagraph>
        <Trans>
          Allocated licenses are returned to the primary account and the
          detached account will require its own licenses. All data for the
          managed company is carried over to the detached account.
        </Trans>
      </StyledParagraph>
      <CheckBoxContainer>
        <Checkbox
          checked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          data-qa="ManagedCompanyDetachConfirmDialogCheckbox"
        >
          <Trans>
            I understand. Permanently and irreversibly detach this managed
            company.
          </Trans>
        </Checkbox>
      </CheckBoxContainer>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={discardDialog}
      width="480px"
      title={msg`Detach company`}
      content={<Content />}
      buttons={[
        <TextButton
          onClick={discardDialog}
          key="discard"
          data-qa="ManagedCompanyDetachConfirmDialogCancelButton"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          red
          onClick={confirmDialog}
          key="confirm"
          disabled={!isChecked}
          data-qa="ManagedCompanyDetachConfirmDialogConfirmButton"
        >
          <Trans>Detach</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

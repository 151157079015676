import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'

export function createGetMasterPasswordValidationDataSaga(
  service: UACServices.Services
) {
  return function* getMasterPasswordValidationData() {
    try {
      yield put(securityReportDrawerActions.setIsLoading(true))
      const response: UACServices.CurrentAdminPrivateRsaSharingKeyAPI.Responses = yield call(
        service.currentAdminPrivateRsaSharingKey
      )

      if (
        response.type !==
        UACServices.CurrentAdminPrivateRsaSharingKeyAPI.SUCCESS
      ) {
        throw new UnknownServiceError()
      }

      yield put(
        securityReportDrawerActions.setMasterPasswordValidationData(
          response.body
        )
      )

      yield put(securityReportDrawerActions.setIsLoading(false))
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { duoSecuritySettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetDuoSecuritySettings(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getDuoSecuritySettings() {
    try {
      yield put(duoSecuritySettingsActions.setDuoSecuritySettingsLoading(true))
      try {
        const duoSecuritySettingsResult: UACServices.DuoSecuritySettingsGetAPI.Responses = yield call(
          enterpriseOptionServices.duoSecuritySettingsGet
        )
        yield put(
          duoSecuritySettingsActions.setDuoSecuritySettings(
            duoSecuritySettingsResult.body.duoSecuritySettings
          )
        )
      } finally {
        yield put(
          duoSecuritySettingsActions.setDuoSecuritySettingsLoading(false)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

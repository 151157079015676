import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/admin-console/icon-close2.svg'

import { CaptionSemiboldStyle } from '../styles'

interface FilterBadgeProps {
  removeBadge: Function
  dataQa?: string
}

const Container = styled.div`
  ${CaptionSemiboldStyle}
  display: inline-block;
  outline: none;
  padding: 4px 8px;
  text-align: center;
  border-radius: ${props => props.theme.radius.full};
  background-color: ${props => props.theme.colors.blue100};
  color: ${props => props.theme.colors.neutral900};
  margin-right: 8px;
  margin-top: 8px;
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 12px;
  height: 12px;
  margin-left: 4px;
  margin-bottom: -2px;
  color: ${props => props.theme.colors.neutral700};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.neutral900};
  }
`

export const FilterBadge: FunctionComponent<FilterBadgeProps> = props => {
  return (
    <Container data-qa={props.dataQa}>
      {props.children}
      <StyledCloseIcon
        data-qa="RemoveIcon"
        onClick={() => props.removeBadge()}
      />
    </Container>
  )
}

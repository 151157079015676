import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { duoSecuritySettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/actions'
import { DuoSecuritySettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSaveDuoSecuritySettings(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveDuoSecuritySettings(
    action: ReturnType<
      typeof duoSecuritySettingsActions.saveDuoSecuritySettings
    >
  ) {
    try {
      const duoSecuritySettings: DuoSecuritySettingsState =
        action.payload.duoSettings
      yield put(duoSecuritySettingsActions.setDuoSecuritySettingsLoading(true))
      try {
        const duoSecuritySettingsResult: UACServices.DuoSecuritySettingsSaveAPI.Responses = yield call(
          enterpriseOptionServices.duoSecuritySettingsSave,
          duoSecuritySettings
        )
        if (
          duoSecuritySettingsResult.type ===
          UACServices.DuoSecuritySettingsSaveAPI.SUCCESS
        ) {
          yield put(
            duoSecuritySettingsActions.setDuoSecuritySettings(
              duoSecuritySettings
            )
          )
          yield put(
            globalActions.setNotification({
              message: msg`Duo Security Settings Save Success`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`Duo Security Settings Save Failed`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(
          duoSecuritySettingsActions.setDuoSecuritySettingsLoading(false)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

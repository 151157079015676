import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { LegacyVpnItem } from '../legacyvpn'

export enum LegacyVpnListActionTypes {
  GET_LEGACY_VPN_LIST = '[legacy-vpn-list] get',
  SET_LEGACY_VPN_LIST = '[legacy-vpn-list] set',
  GET_STARTED = '[legacy-vpn-list] get-started'
}

export const legacyVpnListActions = {
  getLegacyVpnList: () =>
    createAction(LegacyVpnListActionTypes.GET_LEGACY_VPN_LIST),
  setLegacyVpnListWithTracking: (list: LegacyVpnItem[]) =>
    track(
      createAction(LegacyVpnListActionTypes.SET_LEGACY_VPN_LIST, { list }),
      {
        segment: uacEvent('Legacy & VPN Overview Viewed', {
          isEmpty: !list || !list.length,
          'App Count': list && list.length,
          Source: 'Legacy VPN Overview'
        })
      }
    ),
  setLegacyVpnList: (list: LegacyVpnItem[]) =>
    createAction(LegacyVpnListActionTypes.SET_LEGACY_VPN_LIST, { list }),
  getStarted: () =>
    track(createAction(LegacyVpnListActionTypes.GET_STARTED), {
      segment: uacEvent('Add Passwordless App Clicked', {
        'App Count': 0,
        Type: 'VPN'
      })
    })
}

export type LegacyVpnListActions = ActionsUnion<typeof legacyVpnListActions>

import React, { FC } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'

import { ReactComponent as Dot } from '@lastpass/assets/svg/admin-console/ellipses.svg'
import { Theme, ThemeColor } from '@lastpass/lastkit/theme'

import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'
import { SecurityScoreLevelMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SecurityScoreLevelMapping'

interface SecurityScoreBadgeStatus {
  colorForDot: ThemeColor
}

export const StyledDot = styled(Dot, {
  shouldForwardProp: (prop: string) => isPropValid(prop)
})<SecurityScoreBadgeStatus>`
  color: ${props => props.theme.colors[props.colorForDot]};
  width: 8px;
  height: 8px;
  margin-right: 5px;
`

interface SecurityScoreBadgeProps {
  level: SecurityScoreLevel | null
  score?: number | null
}

const formatNumericScore = (score: number): number => Number(score.toFixed(1))

export const SecurityScoreBadge: FC<SecurityScoreBadgeProps> = ({
  level,
  score
}) => {
  let background: keyof Theme['colors']

  switch (level) {
    case SecurityScoreLevel.low:
      background = 'red700'
      break
    case SecurityScoreLevel.average:
      background = 'yellow700'
      break
    case SecurityScoreLevel.high:
      background = 'green700'
      break
    case SecurityScoreLevel.highest:
      background = 'green700'
      break
    default:
      background = 'neutral500'
      break
  }

  return (
    <span>
      <StyledDot colorForDot={background} />{' '}
      <span data-qa="SecurityScoreBadgeLevel">
        {SecurityScoreLevelMapping[String(level)]}
      </span>
      {typeof score === 'number' && (
        <>
          {' '}
          <span data-qa="SecurityScoreBadgeScore">{`(${formatNumericScore(
            score
          )}%)`}</span>
        </>
      )}
    </span>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

export const NoKeysWereSharedErrorDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(distributeRecoveryKeysActions.trackRecoveryKeySharingErrorViewed())
  }, [dispatch])

  const content = (
    <>
      <StyledParagraph>
        <Trans>No keys were shared.</Trans>
      </StyledParagraph>
      <StyledParagraph>
        <Trans>
          Please try again. If the problem persists, contact LastPass support:{' '}
          <a
            href="https://support.lastpass.com"
            target="_blank"
            rel="noreferrer"
          >
            https://support.lastpass.com
          </a>
        </Trans>
      </StyledParagraph>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      width="650px"
      title={msg`Error sharing recovery keys invisibly`}
      titleColor="red"
      content={content}
      buttons={[
        <PrimaryButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          data-qa="ErrorSharingRecoveryKeysInvisiblyDialogConfirmButton"
        >
          <Trans>OK</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

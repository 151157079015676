import { EnterpriseApiActions, EnterpriseApiActionTypes } from './actions'
import { EnterpriseApiState } from './state'

export const initialState: EnterpriseApiState = {
  alreadyHasHash: false,
  apiExamples: undefined,
  companyId: '',
  emailVerified: false,
  inProgress: false,
  provisioningHash: undefined,
  whitelistedIps: undefined
}

export const enterpriseApiReducer = (
  previousState: EnterpriseApiState | undefined,
  action: EnterpriseApiActions
): EnterpriseApiState => {
  const state = previousState || initialState
  switch (action.type) {
    case EnterpriseApiActionTypes.ENTERPRISE_API_GET:
      return {
        ...state
      }

    case EnterpriseApiActionTypes.ENTERPRISE_API_SET:
      return {
        ...state,
        alreadyHasHash: action.payload.hasHash,
        emailVerified: action.payload.emailVerified,
        whitelistedIps: action.payload.whitelistedIps,
        apiExamples: action.payload.apiExamples
      }

    case EnterpriseApiActionTypes.SET_PROVISIONING_HASH:
      return {
        ...state,
        hash: action.payload.hash
      }

    case EnterpriseApiActionTypes.SET_INPROGRESS:
      return {
        ...state,
        inProgress: action.payload
      }

    default:
      return state
  }
}

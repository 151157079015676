import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState
} from 'react'

import styled from '@emotion/styled'

import { Collapsible, CollapsibleProps } from '@lastpass/lastkit/components'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

export interface OptionsCardProps extends CollapsibleProps {
  subtitle?: ReactElement
  onExpansion?: Function
  isInitialExpand: boolean
  closeOtherCards?: (expandStatus: boolean) => void
}

const ContentContainer = styled.div`
  margin-top: 20px;
`
const Container = styled.div`
  margin-left: 8px;
`
const StyledSubtitle = styled.div`
  ${BodyRegularStyle};
  margin-top: 8px;
`

export const OptionsCard: FunctionComponent<OptionsCardProps> = ({
  subtitle,
  onExpansion,
  ...props
}) => {
  const [isCardExpanded, setIsCardExpanded] = useState(props.isInitialExpand)
  useEffect(() => {
    if (isCardExpanded && !!onExpansion) {
      onExpansion()
    }
    setIsCardExpanded(props.isInitialExpand)
  }, [onExpansion, isCardExpanded, props.isInitialExpand])
  return (
    <Collapsible
      {...props}
      expanded={isCardExpanded}
      onExpand={() => {
        if (props.closeOtherCards) {
          props.closeOtherCards(!isCardExpanded)
        }
        setIsCardExpanded(!isCardExpanded)
      }}
      headerMargin={'0 0 0 8px'}
      chevron
    >
      <Container>
        {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
        <ContentContainer>{props.children}</ContentContainer>
      </Container>
    </Collapsible>
  )
}

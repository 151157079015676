import { takeLatest } from 'redux-saga/effects'

import { keyRotation } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { FederatedLoginActionTypes } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

export function createFederatedKeyRotationSaga(
  userService: UACServices.Services
) {
  return function*() {
    yield takeLatest(
      FederatedLoginActionTypes.START_KEY_ROTATION,
      keyRotation,
      userService
    )
  }
}

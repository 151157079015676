import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  AlienVaultIntegrationConfig,
  AzureSentinelIntegrationConfig,
  LogRhythmIntegrationConfig,
  QRadarIntegrationConfig,
  SplunkIntegrationConfig
} from '@lastpass/admin-console-dependencies/types/siem-integrations'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = () => createResponse(SUCCESS)
const failure = (payload: { error: string }) => createResponse(FAILURE, payload)

export const Responses = {
  success,
  failure
}

export type Responses = ResponseTypes<typeof Responses>

export type ValidationProblemDetails = {
  title?: string
  status?: StatusCodes
  errors: Record<string, string[]>
  isConnectionTestFailure?: boolean
}

export function create(fetch: AuthFetch) {
  async function createFetch<TConfig>(url: string, body: Partial<TConfig>) {
    try {
      const [response, status] = await fetch(url, 'POST', body, {
        'Content-Type': 'application/json'
      })

      if (status === StatusCodes.NO_CONTENT) {
        return success()
      }

      if (response && status === StatusCodes.BAD_REQUEST) {
        const details: ValidationProblemDetails = JSON.parse(response)
        if (details.isConnectionTestFailure) {
          return failure({ error: details.errors[''].pop() ?? '' })
        } else {
          throw new UnknownServiceError()
        }
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }

  return {
    splunk: async (config: SplunkIntegrationConfig) =>
      createFetch<SplunkIntegrationConfig>(
        'v3/siem-integrations/splunk',
        config
      ),
    azureSentinel: async (config: AzureSentinelIntegrationConfig) =>
      createFetch<AzureSentinelIntegrationConfig>(
        'v3/siem-integrations/azure-sentinel',
        config
      ),
    qRadar: async (config: QRadarIntegrationConfig) =>
      createFetch<QRadarIntegrationConfig>(
        'v3/siem-integrations/qradar',
        config
      ),
    logRhythm: async (config: LogRhythmIntegrationConfig) =>
      createFetch<LogRhythmIntegrationConfig>(
        'v3/siem-integrations/logrhythm',
        config
      ),
    alienVault: async (config: AlienVaultIntegrationConfig) =>
      createFetch<AlienVaultIntegrationConfig>(
        'v3/siem-integrations/alienvault',
        config
      )
  }
}

import React, { FunctionComponent, useContext } from 'react'
import {
  match,
  matchPath,
  Route,
  useLocation,
  useRouteMatch
} from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { IconButton, PrimaryButton } from '@lastpass/lastkit'
import { AnimatedSwitch, LocationLink } from '@lastpass/routing'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { AddGroupDrawer } from '@lastpass/admin-console-dependencies/ui/users/groups/add/AddGroupDrawer'
import { NewGroupForm } from '@lastpass/admin-console-dependencies/ui/users/groups/add/NewGroupForm'
import { AssignGroupUsersDrawer } from '@lastpass/admin-console-dependencies/ui/users/groups/add-users/AssignGroupUsersDrawer'
import {
  GroupDrawer,
  GroupDrawerProps
} from '@lastpass/admin-console-dependencies/ui/users/groups/GroupDrawer'
import { GroupsList } from '@lastpass/admin-console-dependencies/ui/users/groups/GroupList'
import { GroupOperationsDropdown } from '@lastpass/admin-console-dependencies/ui/users/groups/GroupOperationsDropdown'

import { AssignGroupUsersDrawerPage } from './drawer/add-users/AssignGroupUsersDrawerPage'
import { EditGroupDrawerPage } from './drawer/edit-group/EditGroupDrawerPage'
import { GroupDetailsDrawerPage } from './drawer/GroupDetailsDrawerPage'

function addBackButtonsIfNeeded(
  exactDrawerMatch: boolean | null,
  addUserNewMatch: match<{}> | null,
  addUserMatch: match<{}> | null,
  drawerProps: GroupDrawerProps,
  drawerMatch: match<{}> | null,
  canModifyGroups: boolean
) {
  // If the drawer is shown but it is not exact that means a child view is shown
  if (
    (!exactDrawerMatch && !addUserNewMatch) ||
    (addUserMatch && addUserMatch.isExact)
  ) {
    drawerProps.backButton = (
      <LocationLink to={(drawerMatch && drawerMatch.url) || '/'}>
        <IconButton icon={ChevronLeft} data-qa={'BackButton'} />
      </LocationLink>
    )
  }

  if (exactDrawerMatch) {
    drawerProps.assignUsersButton = canModifyGroups ? (
      <LocationLink
        to={`${location.pathname}/add`}
        discardnamespace={Namespace.addUsersToGroup}
      >
        <PrimaryButton data-qa={'GroupDetailAssignUsersButton'}>
          <Trans>Assign users</Trans>
        </PrimaryButton>
      </LocationLink>
    ) : (
      <></>
    )
    drawerProps.moreButton = (
      <GroupOperationsDropdown editGroupLink={`${location.pathname}/edit`} />
    )
  }
}

export const GroupsViewPageComponent: FunctionComponent = () => {
  const routeMatch = useRouteMatch()
  const location = useLocation()
  const permissions = useContext(PermissionContext)
  const canModifyGroups = permissions.requirePermission(
    AdminPermissions.groupsModify
  )

  if (routeMatch) {
    const { url, path } = routeMatch
    const drawerPath = `${path}/:id`
    const drawerMatch = matchPath(location.pathname, drawerPath)
    const addUserMatch = matchPath(location.pathname, `${drawerPath}/add`)
    const addUserNewMatch = matchPath(
      location.pathname,
      `${drawerPath}/add-new`
    )
    const drawerProps: GroupDrawerProps = {
      closeLink: url
    }
    const exactDrawerMatch = drawerMatch && drawerMatch.isExact

    addBackButtonsIfNeeded(
      exactDrawerMatch,
      addUserNewMatch,
      addUserMatch,
      drawerProps,
      drawerMatch,
      canModifyGroups
    )

    return (
      <>
        <GroupsList />
        <AnimatedSwitch timeout={200}>
          {canModifyGroups && (
            <Route path={`${path}/new`}>
              <AddGroupDrawer {...drawerProps}>
                <NewGroupForm />
              </AddGroupDrawer>
            </Route>
          )}
          {canModifyGroups && (
            <Route path={[`${drawerPath}/add`, `${drawerPath}/add-new`]}>
              <AssignGroupUsersDrawer {...drawerProps}>
                <AssignGroupUsersDrawerPage />
              </AssignGroupUsersDrawer>
            </Route>
          )}
          {canModifyGroups && (
            <Route path={`${drawerPath}/edit`}>
              <GroupDrawer {...drawerProps}>
                <EditGroupDrawerPage />
              </GroupDrawer>
            </Route>
          )}
          <Route path={drawerPath}>
            <GroupDrawer {...drawerProps}>
              <GroupDetailsDrawerPage />
            </GroupDrawer>
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

export const GroupsViewPage = GroupsViewPageComponent

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

import { stagedUsersFilter } from './get-staged-users'

export function createInviteStagedUsersSaga(
  dashboardService: UACServices.Services
) {
  return function* inviteStagedUsersSaga() {
    try {
      const query = buildQuery({
        ...stagedUsersFilter
      })

      const result: UACServices.InviteUsersWithFilterAPI.Responses = yield call(
        dashboardService.inviteUsersWithFilter,
        query
      )
      if (result.type === UACServices.InviteUsersWithFilterAPI.SUCCESS) {
        yield put(
          globalActions.setNotification({
            message: msg`New invitations have been sent to the selected users.`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
        yield put(push('/dashboard'))
      } else if (
        result.type ===
        UACServices.InviteUsersWithFilterAPI.FAIL_EMAIL_TEMPLATES_NOT_APPROVED
      ) {
        yield put(
          globalActions.setNotification({
            message: msg`A member of the LastPass team must approve your template. Approval can take up to 24 hours.`,
            type: NotificationType.warning,
            autoDismiss: true
          })
        )
      } else {
        yield put(
          globalActions.setNotification({
            message: msg`New invitations haven't been sent to the selected users.`,
            type: NotificationType.alert,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

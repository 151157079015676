import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AddressIcon } from '@lastpass/assets/svg/admin-console/icon-address.svg'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-users.svg'
import {
  CardTable,
  Collapsible,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import {
  groupDrawerActions,
  GroupDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

interface GroupUsersSummaryCardProps {
  detailLink: string
  usersLink: string
  assignUsersLink: string
  expand: boolean
  onExpand: Function
}

const StyledUserIcon = styled(UserIcon)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const GroupUsersSummaryCard: React.FunctionComponent<GroupUsersSummaryCardProps> = ({
  detailLink,
  usersLink,
  assignUsersLink,
  expand,
  onExpand
}) => {
  const groupDrawerUsersState: GroupDrawerState['users'] = useSelector(
    (state: AppState) => state.groupDrawer.users
  )
  const queryParams = useQueryParams()
  const pathParams: GroupDrawerUrlParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    if (expand) {
      dispatch(
        groupDrawerActions.getGroupUsers(
          { path: pathParams, query: queryParams },
          5
        )
      )
    }
  }, [dispatch, pathParams, expand, queryParams])

  const permissions = useContext(PermissionContext)
  const canModifyGroups = permissions.requirePermission(
    AdminPermissions.groupsModify
  )

  return (
    <Collapsible
      title={<Trans>Users</Trans>}
      expanded={expand}
      onInitialExpand={() => {
        dispatch(
          groupDrawerActions.getGroupUsers(
            { path: pathParams, query: queryParams },
            5
          )
        )
      }}
      loading={groupDrawerUsersState.isLoading}
      onExpand={onExpand}
      qadata={'UsersCard'}
    >
      {groupDrawerUsersState && (
        <CardTable
          qadata={'GroupLevelUsersList'}
          table={groupDrawerUsersState}
          columns={[
            {
              name: <></>,
              component: StyledUserIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Name</Trans>,
              renderer: (record: User) => getFullName(record)
            },
            {
              name: <Trans>Email</Trans>,
              renderer: (record: User) => record.email
            }
          ]}
          footerNodes={
            <>
              <TextButton blue>
                <StyledLink to={usersLink} data-qa={'GoToUsersPageButton'}>
                  <Trans>Go to Users page</Trans>
                </StyledLink>
              </TextButton>
              <TextButton blue>
                <StyledLocationLink
                  to={detailLink}
                  data-qa={'ViewAllUsersButton'}
                  discardnamespace={Namespace.groupUsers}
                >
                  <Trans>
                    View {'&'} edit all users:{' '}
                    {groupDrawerUsersState &&
                      groupDrawerUsersState.totalResults}
                  </Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<AddressIcon />}
              title={<Trans>No users assigned yet</Trans>}
              text={
                <Trans>Make this group useful by assigning some users.</Trans>
              }
              actionButton={
                canModifyGroups ? (
                  <TableViewButton>
                    <StyledLink
                      to={assignUsersLink}
                      data-qa={'AssignUsersButton'}
                    >
                      <Trans>Assign users</Trans>
                    </StyledLink>
                  </TableViewButton>
                ) : (
                  <></>
                )
              }
            />
          }
        />
      )}
    </Collapsible>
  )
}

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'

import { EnumDictionary } from '../EnumValues'

export const PolicyUsersMapping: EnumDictionary<
  AppliesToOptions,
  ReactElement
> = {
  [AppliesToOptions.all]: <Trans>All users</Trans>,
  [AppliesToOptions.inclusive]: <Trans>Only these users/groups</Trans>,
  [AppliesToOptions.exclusive]: <Trans>All except these user/groups</Trans>
}

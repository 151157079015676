import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SecureAuthSettingsActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/actions'

import { createGetSecureAuthSettings } from './get-secure-auth-settings'
import { createSaveSecureAuthSettings } from './save-secure-auth-settings'

export function createSecureAuthSettingsSaga(
  secureAuthSettingsService: UACServices.Services
) {
  const getSecureAuthSettingsSaga = createGetSecureAuthSettings(
    secureAuthSettingsService
  )
  const saveSecureAuthSettingsSaga = createSaveSecureAuthSettings(
    secureAuthSettingsService
  )

  return function*() {
    yield takeEvery(
      SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_GET,
      getSecureAuthSettingsSaga
    )
    yield takeEvery(
      SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_SAVE,
      saveSecureAuthSettingsSaga
    )
  }
}

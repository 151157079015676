import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'
import { PasswordManagerTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

export const SUCCESS = 'success'
export const FAIL = 'fail'

// Fail respose is needed because it's a single endpoint used for bulk actions
const success = () => createResponse(SUCCESS)
const fail = () => createResponse(FAIL)

export const Responses = {
  success,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    lpDevice: PasswordManagerTrustedDevice,
    userId: string
  ) {
    const [response, status] = await fetch(
      `users/${userId}/trusted-devices/lastpass-applications/${lpDevice.id}`,
      'DELETE'
    )
    if (response && status === StatusCodes.OK) {
      return success()
    } else {
      return fail()
    }
  }
}

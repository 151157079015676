import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { msg, Plural, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import {
  BodyBold,
  BodyRegular,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ActivateUsersDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { UserBase } from '@lastpass/admin-console-dependencies/types/user'

const StyledList = styled.ul`
  margin-top: 16px;
  margin-left: 8px;
`

const StyledListItem = styled.li`
  list-style: disc;
  margin-left: 20px;
`

interface DialogProps {
  state: ActivateUsersDialog
  actions: typeof globalActions
}

const Content: FunctionComponent<{
  users: UserBase[]
  numberOfUsers: number
}> = ({ users, numberOfUsers }) => (
  <BodyRegular data-qa="DialogContent">
    {numberOfUsers === 1 ? (
      <Trans>
        You’re about to activate this user:{' '}
        <BodyBold>{users[0].email}</BodyBold>
      </Trans>
    ) : (
      <Trans>
        You’re about to activate <BodyBold>{numberOfUsers}</BodyBold> users.
      </Trans>
    )}
    <StyledList>
      <StyledListItem>
        <Plural
          value={numberOfUsers}
          one="They’ll gain access to their vault"
          other="Each will gain access to their vault"
        />
      </StyledListItem>
      <StyledListItem>
        <Plural
          value={numberOfUsers}
          one="They’ll consume a seat"
          other="Each will consume a seat"
        />
      </StyledListItem>
    </StyledList>
  </BodyRegular>
)

export const ActivateUsersDialogComponent: FunctionComponent<DialogProps> = ({
  state,
  actions
}) => {
  const numberOfUsers = state.isAllSelected
    ? state.allUserCount
    : state.users.length
  const { _ } = useLingui()
  const discardText = _(msg`Cancel`)
  const confirmText = _(
    numberOfUsers === 1 ? msg`Activate` : msg`Activate ${numberOfUsers} users`
  )
  const title = numberOfUsers === 1 ? msg`Activate user?` : msg`Activate users?`

  return (
    <DetailsDialog
      closeDialog={() => {
        actions.discardDialog()
        actions.emptyDialog()
      }}
      width="480px"
      title={title}
      content={<Content users={state.users} numberOfUsers={numberOfUsers} />}
      buttons={[
        <TextButton
          onClick={() => {
            actions.discardDialog()
            actions.emptyDialog()
          }}
          key="discard"
        >
          {discardText}
        </TextButton>,
        <PrimaryButton
          onClick={() => {
            actions.confirmDialog()
            actions.emptyDialog()
          }}
          key="confirm"
        >
          {confirmText}
        </PrimaryButton>
      ]}
    />
  )
}

import { ReactElement } from 'react'

import { MessageDescriptor } from '@lingui/core'

import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { OpenIdProvider } from '@lastpass/federation'
import { RadioButtonOption } from '@lastpass/lastkit/components/RadioButton'

import { MspBillingDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { OperationOriginType } from '@lastpass/admin-console-dependencies/state/users/view/operations/operation-origin-types'
import { UserBase } from '@lastpass/admin-console-dependencies/types/user'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'

import { VaultReEncryptionOfferStage } from '../sagas/users/federated-login/vault-re-encryption/vault-re-encryption-offer'
import { NotificationProps } from './notification-type'

export interface EmptyDialog {
  type: 'empty'
}

export interface NotificationDialog {
  type: 'notificationdialog'
  notification: NotificationProps
}

export interface TextDialog {
  type: 'textdialog'
  title: MessageDescriptor
  text: MessageDescriptor
}

export interface ConfirmDialog {
  type: 'confirmdialog'
  title: MessageDescriptor
  text?: MessageDescriptor
  content?: ReactElement
  discardText: MessageDescriptor
  confirmText: MessageDescriptor
}

export interface ManagedCompanyConfirmDialog
  extends Omit<ConfirmDialog, 'content' | 'type'> {
  type: 'managed-company-confirm-dialog'
  managedCompanyName: string
}

export interface ManagedCompanyDetachConfirmDialog {
  type: 'managed-company-detach-confirm-dialog'
  managedCompanyName: string
}

export interface ManagedCompanyExportUserCountsDialog {
  type: 'managed-company-export-user-counts-dialog'
}

export interface ConfirmWithRadio {
  type: 'confirmwithradio'
  title: MessageDescriptor
  text: MessageDescriptor
  discardText: MessageDescriptor
  confirmText: MessageDescriptor
  radioButtons: RadioButtonOption[]
}

export interface UpsellDialog {
  type: 'upselldialog'
  mainText: MessageDescriptor
  secondaryText: MessageDescriptor
  discardText: MessageDescriptor
  confirmText: MessageDescriptor
  mfaDialogContext: MfaUpsellDialogContext
}

export interface SsoUpsellDialog {
  type: 'ssoupselldialog'
}

export interface SsoUpsellContactSupportDialog {
  type: 'ssoupsellcontactsupportdialog'
}

export enum MfaUpsellDialogContext {
  legacyvpn = 'legacyvpn',
  passwordless = 'passwordless',
  workstation = 'workstation',
  multifactor = 'multifactor',
  adminActivityReporting = 'adminReports',
  userActivityReporting = 'userReports',
  ssoAppDrawer = 'ssoAppDrawer',
  default = 'default'
}

export interface MfaUpsellDialog {
  type: 'mfaupselldialog'
  dialogContext: MfaUpsellDialogContext
  eCommEnabled: boolean
}

export enum SharedFolderDialogueType {
  create = 'create',
  users = 'users',
  apps = 'apps'
}

export interface SharedFolderDialog {
  type: 'sharedfolderdialog'
  mainText: MessageDescriptor
  secondaryText: MessageDescriptor
  learnMoreText: MessageDescriptor
  skipText: MessageDescriptor
  confirmText: MessageDescriptor
  shareType: SharedFolderDialogueType
  lastpassQueryParams: string
}

export interface InvalidFileDialog {
  type: 'invalidfiledialog'
}

export interface BaseSupportDialog {
  type: string
}

export interface SupportDialog extends BaseSupportDialog {
  type: 'supportdialog'
}

export interface MspSupportDialog extends BaseSupportDialog {
  type: 'msp-support-dialog'
}

export interface SuccessDialog {
  type: 'successdialog'
  title: MessageDescriptor
  content?: ReactElement | MessageDescriptor
}

export interface MfaUsageNotificationDialog {
  type: 'mfausagenotificationdialog'
  mfaDialogContext: MfaUpsellDialogContext
}

export enum GoogleIntegrationSyncOptionsDialogConfirmType {
  allEnable = 'allenable',
  allExit = 'allexit',
  partialEnable = 'partialenable',
  partialExit = 'partialexit'
}

export interface GoogleIntegrationSyncOptionsDialog {
  type: 'googleintegrationsyncoptionsdialog'
  partialSyncEnabled: boolean
  initialSetup: boolean
}

export interface MspPaymentInfoDialog {
  type: 'msp-payment-info-dialog'
  mspBillingDetails: MspBillingDetails
}

export interface ResetMfaSharedSecretsDialogType {
  type: 'reset-mfa-shared-secrets-dialog'
}

export interface KeyRotationGeneralDialog {
  type: 'key-rotation-general-dialog'
  provider: OpenIdProvider
}
export interface KeyRotationStatusDialog {
  type: 'key-rotation-status-dialog'
  providerName?: string
}

export interface KeyRotationProgressDialog {
  type: 'key-rotation-progress-dialog'
}

export interface KeyRotationK1InputDialog {
  type: 'key-rotation-k1-input-dialog'
  providerName: string
}

export interface KeyRotationCopyK1Dialog {
  type: 'key-rotation-copy-k1-dialog'
  providerName: string
  k1: string
}

export interface KeyRotationSaveK1Dialog {
  type: 'key-rotation-save-k1-dialog'
  k1: string
}

export interface KeyRotationStartProcessDialog {
  type: 'key-rotation-start-process-dialog'
}

export interface KeyRotationUpdateK2ComponentsDialog {
  type: 'key-rotation-update-k2-components-dialog'
  providerName: string
}

export interface KeyRotationAlertDialog {
  type: 'key-rotation-alert-dialog'
  title: MessageDescriptor
  subtitle: MessageDescriptor
  text: MessageDescriptor
  secondaryText?: MessageDescriptor
  errorCodeText?: MessageDescriptor
  buttonText?: MessageDescriptor
}

export interface KeyRotationInconsistentGenericErrorDialog {
  type: 'key-rotation-inconsistent-generic-error-dialog'
  errorCodeText: MessageDescriptor
}

export interface KeyRotationInconsistentConnectionErrorDialog {
  type: 'key-rotation-inconsistent-connection-error-dialog'
  errorCodeText: MessageDescriptor
}

export interface KeyRotationAuthenticateCompanyAdminDialog {
  type: 'key-rotation-authenticate-company-admin-dialog'
  providerName: string
  isIdpUrlInvalid: boolean
}

export interface KeyRotationContinueDialog {
  type: 'key-rotation-continue-dialog'
  title: MessageDescriptor
  subtitle: MessageDescriptor
}

export interface KeyRotationInstructionsDialog {
  type: 'key-rotation-instructions-dialog'
  title: MessageDescriptor
  subtitle: MessageDescriptor
  checkboxText: MessageDescriptor
  contentText?: MessageDescriptor
}
export interface KeyRotationCompanyWideDoneDialog {
  type: 'key-rotation-company-wide-done-dialog'
}

export interface KeyRotationAccessDeniedDialog {
  type: 'key-rotation-access-denied-dialog'
  errorCodeText: MessageDescriptor
}

export interface ForceVaultReEncrytionConfirmationDialog {
  type: 'force-vault-re-encryption-confirmation-dialog'
  isGroup: boolean
  email?: string
}

export interface VaultReEncryptionDialog {
  type: 'vault-re-encryption-dialog'
  title: MessageDescriptor
}

export interface EditVaultReEncryptionDialog {
  type: 'edit-vault-re-encryption-dialog'
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}

export interface VaultReEncryptionOfferDialog {
  type: 'vault-re-encryption-offer-dialog'
  title: MessageDescriptor
  mainText: MessageDescriptor
  secondaryText: MessageDescriptor
  confirmText: MessageDescriptor
  discardText: MessageDescriptor
  backStage: VaultReEncryptionOfferStage
  nextStage: VaultReEncryptionOfferStage
  cancelStage: VaultReEncryptionOfferStage
}

export interface VaultReEncryptionOfferDetailsDialog {
  type: 'vault-re-encryption-offer-details-dialog'
}

export interface IterationCountDestroySessionsDialog {
  type: 'iteration-count-destroy-sessions-dialog'
}

export enum SystemUpgradeProcessDialogType {
  start = 'start',
  loading = 'loading',
  completed = 'completed',
  error = 'error'
}

export interface SystemUpgradeProcessDialog {
  type: 'system-upgrade-process-dialog'
  dialogType: SystemUpgradeProcessDialogType
}

export interface EnrollmentRateDialog {
  type: 'enrollment-rate-dialog'
}

export interface ActiveUsageRateDialog {
  type: 'active-usage-rate-dialog'
}
export interface PermissionRequiredToManagePoliciesDialog {
  type: 'permission-required-to-manage-policies-dialog'
}
export interface RecoveryKeySharingConfirmDialogType {
  type: 'recovery-key-sharing-confirm-dialog'
  users: UserBase[]
  origin: OperationOriginType
  isAllSelected: boolean
  filter?: UserFilter
}

export interface MasterPasswordConfirmDialogType {
  type: 'master-password-confirm-dialog'
  description: MessageDescriptor
  onConfirm?: Function
  nextDialog?: DialogTypes
}

export interface RecoveryKeySharingProgressDialogType {
  type: 'recovery-key-sharing-progress-dialog'
}

export interface RecoveryKeySharingEndDialogType {
  type: 'recovery-key-sharing-end-dialog'
}

export interface UrlDecryptionConfirmDialogType {
  type: 'url-decryption-confirm-dialog'
}

export interface UrlDecryptionProgressDialogType {
  type: 'url-decryption-progress-dialog'
}

export interface NoKeysWereSharedErrorDialogType {
  type: 'no-keys-were-shared-error-dialog'
}

export interface RecipientAlreadyHasTheKeysErrorDialogType {
  type: 'recipient-already-has-the-keys'
}

export interface SomeKeysWereSharedErrorDialogType {
  type: 'some-keys-were-shared-error-dialog'
}

export interface DisableUsersDialog {
  type: 'disable-users-dialog'
  users: UserBase[]
  isAllSelected: boolean
  allUserCount: number
}

export interface ActivateUsersDialog {
  type: 'activate-users-dialog'
  users: UserBase[]
  isAllSelected: boolean
  allUserCount: number
}

export interface NotAllUrlDataCouldBeDecryptedDialogType {
  type: 'not-all-url-data-could-be-decrypted-dialog'
  numberOfUrls: number
  numberOfDecryptedUrls: number
  csvFile: Blob
}

export interface ErrorDecryptingUrlDataDialogType {
  type: 'error-decrypting-url-data-dialog'
}

export interface TransferVaultConfirmationWordDialogType {
  type: 'transfer-vault-confirmation-word-dialog'
  targetUserEmail: string
  sourceUserEmail: string
}

export interface ManageProvisionedUserConfirmationDialogType {
  type: 'manage-provisioned-user-confirmation-dialog'
  users: UserBase[]
  allUserCount: number
  isAllSelected: boolean
  areAllUsersProvisioned: boolean
}

export interface DeleteUserConfirmationWordDialogType {
  type: 'delete-user-confirmation-word-dialog'
  users: UserBase[]
  allUserCount: number
  isAllSelected: boolean
}

export interface RemoveFromCompanyConfirmationWordDialogType {
  type: 'remove-from-company-confirmation-word-dialog'
  users: UserBase[]
  allUserCount: number
  isAllSelected: boolean
}

export interface ProcessingReportDialogType {
  controller: AbortController
  type: 'processing-report-dialog'
}

export interface SecurityScoreHistoryDialog {
  type: 'security-score-history-dialog'
}

export type DialogTypes =
  | NotificationDialog
  | TextDialog
  | EmptyDialog
  | ConfirmDialog
  | SuccessDialog
  | ManagedCompanyConfirmDialog
  | ManagedCompanyDetachConfirmDialog
  | ManagedCompanyExportUserCountsDialog
  | ConfirmWithRadio
  | UpsellDialog
  | SsoUpsellDialog
  | MfaUpsellDialog
  | SsoUpsellContactSupportDialog
  | SharedFolderDialog
  | InvalidFileDialog
  | SupportDialog
  | MspSupportDialog
  | MfaUsageNotificationDialog
  | GoogleIntegrationSyncOptionsDialog
  | MspPaymentInfoDialog
  | ResetMfaSharedSecretsDialogType
  | KeyRotationGeneralDialog
  | KeyRotationStatusDialog
  | KeyRotationProgressDialog
  | KeyRotationK1InputDialog
  | KeyRotationCopyK1Dialog
  | KeyRotationSaveK1Dialog
  | KeyRotationStartProcessDialog
  | KeyRotationUpdateK2ComponentsDialog
  | KeyRotationAlertDialog
  | KeyRotationInconsistentGenericErrorDialog
  | KeyRotationInconsistentConnectionErrorDialog
  | KeyRotationAuthenticateCompanyAdminDialog
  | KeyRotationContinueDialog
  | KeyRotationInstructionsDialog
  | KeyRotationCompanyWideDoneDialog
  | KeyRotationAccessDeniedDialog
  | VaultReEncryptionDialog
  | ForceVaultReEncrytionConfirmationDialog
  | EditVaultReEncryptionDialog
  | VaultReEncryptionOfferDialog
  | VaultReEncryptionOfferDetailsDialog
  | IterationCountDestroySessionsDialog
  | SystemUpgradeProcessDialog
  | EnrollmentRateDialog
  | ActiveUsageRateDialog
  | PermissionRequiredToManagePoliciesDialog
  | RecoveryKeySharingConfirmDialogType
  | MasterPasswordConfirmDialogType
  | RecoveryKeySharingProgressDialogType
  | RecoveryKeySharingEndDialogType
  | UrlDecryptionConfirmDialogType
  | UrlDecryptionProgressDialogType
  | NoKeysWereSharedErrorDialogType
  | SomeKeysWereSharedErrorDialogType
  | RecipientAlreadyHasTheKeysErrorDialogType
  | DisableUsersDialog
  | ActivateUsersDialog
  | NotAllUrlDataCouldBeDecryptedDialogType
  | ErrorDecryptingUrlDataDialogType
  | TransferVaultConfirmationWordDialogType
  | ManageProvisionedUserConfirmationDialogType
  | DeleteUserConfirmationWordDialogType
  | RemoveFromCompanyConfirmationWordDialogType
  | ProcessingReportDialogType
  | SecurityScoreHistoryDialog

import styled from '@emotion/styled'

export const Heading300 = styled.h1`
  color: ${props => props.theme.colors.neutral900};
  font-weight: ${props => props.theme.fonts.weight.semibold};
  letter-spacing: 0.24px;
  font-size: 28px;
  line-height: 40px;
`

export const Heading200 = styled.h2`
  color: ${props => props.theme.colors.neutral900};
  font-weight: ${props => props.theme.fonts.weight.semibold};
  letter-spacing: 0.24px;
  font-size: 24px;
  line-height: 32px;
`

export const Heading100 = styled.h3`
  color: ${props => props.theme.colors.neutral900};
  font-weight: ${props => props.theme.fonts.weight.semibold};
  letter-spacing: 0.24px;
  font-size: 18px;
  line-height: 24px;
`

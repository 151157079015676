import {
  GeneralPoliciesListActions,
  GeneralPoliciesListActionTypes
} from './actions'
import { GeneralPoliciesListState } from './state'

export const initialState: GeneralPoliciesListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  policyMap: {}
}

export function generalPoliciesListReducer(
  previousState: GeneralPoliciesListState | undefined,
  action: GeneralPoliciesListActions
): GeneralPoliciesListState {
  const state = previousState || initialState
  switch (action.type) {
    case GeneralPoliciesListActionTypes.SET_GENERAL_POLICIES_LIST: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: []
        },
        policyMap: {
          ...action.payload.policyMap
        }
      }
    }
    case GeneralPoliciesListActionTypes.ADD_GENERAL_POLICIES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: table.results.concat(action.payload.generalPolicies)
        }
      }
    }
    case GeneralPoliciesListActionTypes.ADD_SELECTED_GENERAL_POLICIES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.concat(
            action.payload.generalPolicies
          )
        }
      }
    }
    case GeneralPoliciesListActionTypes.REMOVE_SELECTED_GENERAL_POLICIES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.filter(
            el => !action.payload.generalPolicies.includes(el)
          )
        }
      }
    }
    case GeneralPoliciesListActionTypes.SET_SELECTED_GENERAL_POLICIES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: action.payload.generalPolicies
        }
      }
    }
    case GeneralPoliciesListActionTypes.SET_GENERAL_POLICIES_LOADING: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          isLoading: action.payload.loading
        }
      }
    }
  }
  return state
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (mappingKeysResponse: string[]) =>
  createResponse(SUCCESS, mappingKeysResponse)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(`company/mappingkeys`, 'GET')
      if (status === StatusCodes.OK && response) {
        const mfaAppAddResponse = JSON.parse(response)
        return success(mfaAppAddResponse.content)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

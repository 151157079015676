import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { DetailsDialog, PrimaryButton } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationAlertDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
`
const FooterBoxContainer = styled.div`
  ${BodyRegularStyle};
  min-height: 50px;
`

const StyledBodyText = styled.div`
  ${BodyRegularStyle};
`

interface KeyRotationAlertDialogProps {
  state: KeyRotationAlertDialog
  actions: typeof globalActions
}

export const KeyRotationAlertDialogComponent: FunctionComponent<KeyRotationAlertDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={state.title}
      subTitle={state.subtitle}
      disableCloseOnBackground
      content={
        <TextBoxContainer>
          <StyledBodyText>{i18n._(state.text)}</StyledBodyText>
        </TextBoxContainer>
      }
      footer={
        <FooterBoxContainer>
          {state.secondaryText && (
            <StyledBodyText>{i18n._(state.secondaryText)}</StyledBodyText>
          )}
          {state.errorCodeText && (
            <StyledBodyText>{i18n._(state.errorCodeText)}</StyledBodyText>
          )}
        </FooterBoxContainer>
      }
      buttons={[
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => dispatch(globalActions.confirmDialog())}
          key="confirm"
        >
          {state.buttonText ? i18n._(state.buttonText) : <Trans>Got it</Trans>}
        </PrimaryButton>
      ]}
    />
  )
}

import React, { FC, ReactNode, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import { PrimaryButton } from '@lastpass/lastkit'

import * as UACServices from '@lastpass/admin-console-dependencies/server/index'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

interface SendEmailButtonProps {
  successMessage: MessageDescriptor
  onIsLoading: (isLoading: boolean) => void
  buttonText: ReactNode
  odataFilter: string
}

export const SendEmailButton: FC<SendEmailButtonProps> = ({
  successMessage,
  onIsLoading,
  buttonText,
  odataFilter
}) => {
  const dispatch = useDispatch()
  const { inviteUsersWithFilter } = useContext(ServerContext)

  const sendEmail = useCallback(async (): Promise<void> => {
    try {
      onIsLoading(true)
      const result = await inviteUsersWithFilter(odataFilter)
      if (result.type === UACServices.InviteUsersWithFilterAPI.SUCCESS) {
        dispatch(
          globalActions.setNotification({
            type: NotificationType.success,
            message: successMessage,
            autoDismiss: true
          })
        )
      } else if (
        result.type ===
        UACServices.InviteUsersWithFilterAPI.FAIL_EMAIL_TEMPLATES_NOT_APPROVED
      ) {
        dispatch(
          globalActions.setNotification({
            message: msg`A member of the LastPass team must approve your template. Approval can take up to 24 hours.`,
            type: NotificationType.warning,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      onIsLoading(false)
    }
  }, [
    dispatch,
    inviteUsersWithFilter,
    odataFilter,
    onIsLoading,
    successMessage
  ])

  return <PrimaryButton onClick={sendEmail}>{buttonText}</PrimaryButton>
}

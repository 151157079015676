import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

export function createGetOpenIdSetup(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getOpenIdSetup() {
    try {
      yield put(globalActions.setIsLoading(true))
      yield put(federatedLoginActions.setLoading(true))
      try {
        const openIdSetupResult: UACServices.OpenIdSetupGetAPI.Responses = yield call(
          enterpriseOptionServices.openIdSetupGet
        )
        yield put(
          federatedLoginActions.setOpenIdSetup(
            openIdSetupResult.body.openIdSetup
          )
        )
      } finally {
        yield put(federatedLoginActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(globalActions.setIsLoading(false))
    }
  }
}

import { UserFilterActions, UserFilterActionTypes } from './actions'
import { UserFilterState } from './state'

export const initialState: UserFilterState = {
  idMapping: {}
}

export function userFilterReducer(
  state: UserFilterState | undefined = initialState,
  action: UserFilterActions
): UserFilterState {
  switch (action.type) {
    case UserFilterActionTypes.SET_USERS_LIST_BY_ID_LIST: {
      const idMapping = {}
      action.payload.users.forEach(user => (idMapping[user.value] = user.label))
      return {
        ...state,
        idMapping
      }
    }
  }
  return state
}

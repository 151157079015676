import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { SymantecVipSettings, SymantecVipSettingsFormData } from './state'

export enum SymantecVipSettingsActionTypes {
  SYMANTEC_VIP_SETTINGS_GET = '[symantec-vip-settings] get',
  SYMANTEC_VIP_SETTINGS_SET = '[symantec-vip-settings] set',
  SYMANTEC_VIP_SETTINGS_SAVE = '[symantec-vip-settings] save',
  SYMANTEC_VIP_SETTINGS_SET_LOADING = '[symantec-vip-settings] set-loading'
}

export const symantecVipSettingsActions = {
  getSymantecVipSettings: () =>
    createAction(SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_GET),
  setSymantecVipSettings: (symantecVipOptions: SymantecVipSettings) =>
    createAction(SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_SET, {
      symantecVipOptions
    }),
  setSymantecVipSettingsLoading: (loading: boolean) =>
    createAction(
      SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_SET_LOADING,
      {
        loading
      }
    ),
  saveSymantecVipSettings: (symantecVipOptions: SymantecVipSettingsFormData) =>
    createAction(SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_SAVE, {
      symantecVipOptions
    })
}

export type SymantecVipSettingsActions = ActionsUnion<
  typeof symantecVipSettingsActions
>

import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'

import { Theme } from '../theme'
import { BodyRegular } from './BodyText'

export interface NumberedListProps {
  items: ReactElement[]
}

const SytledOl = styled.ol`
  list-style: none;
  counter-reset: my-counter;
`

const StyledLi = styled.li`
  counter-increment: my-counter;
  display: flex;
  align-items: center;
  margin-top: 16px;
  &:before {
    color: ${Theme.colors.neutral900};
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    background: ${Theme.colors.blue200};
    border-radius: 104px;
    width: 24px;
    height: 24px;
    content: counter(my-counter);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
`

const StyledBodyRegular = styled(BodyRegular)`
  max-width: 480px;
`

export const NumberedList: FunctionComponent<NumberedListProps> = ({
  ...props
}) => {
  const itemList = props.items.map((item, key) => (
    <StyledLi key={key}>
      <StyledBodyRegular>{item}</StyledBodyRegular>
    </StyledLi>
  ))

  return (
    <>
      <SytledOl>{itemList}</SytledOl>
    </>
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  PrimaryButton,
  StyledSpacer,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'

import { NavigationBarComponent } from '@lastpass/admin-console-dependencies//ui/applications/mfa/drawersteps/assign/NavigationBar'
import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { Group } from '@lastpass/admin-console-dependencies/state/applications/application'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import {
  MfaAppsDrawerState,
  MfaAppsDrawerSteps
} from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { Entities } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { GroupsTableComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/Assign/GeneralApplicationsGroupsTable'
import { UsersTableComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/Assign/GeneralApplicationsUsersTable'

export interface AssignProps {
  closeLink: string
}

export const AssignComponent: React.FunctionComponent<AssignProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const state: MfaAppsDrawerState = useSelector(
    (state: AppState) => state.mfaAppsDrawer
  )

  const unassignDisabled =
    state.users.checkedRecords.length === 0 &&
    state.groups.checkedRecords.length === 0

  return (
    <>
      {
        <NavigationBarComponent
          usersTotalResult={state.users.totalResults}
          groupsTotalResult={state.groups.totalResults}
        />
      }
      {state.showTable === Entities.users ? (
        <UsersTableComponent
          table={state.users}
          checkable={{
            addSelectedRecords: (users: User[]) =>
              dispatch(mfaAppsDrawerActions.addSelectedUsersToUnassign(users)),
            removeSelectedRecords: (users: User[]) =>
              dispatch(
                mfaAppsDrawerActions.removeSelectedUsersToUnassign(users)
              ),
            setSelectedRecords: (users: User[]) =>
              dispatch(mfaAppsDrawerActions.setSelectedUsersToUnassign(users))
          }}
          title={<Trans>No users assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some users.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                dispatch(
                  mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.newAssign)
                )
              }}
            >
              <TableViewButton data-qa={'AssignUsersToMfaAppButton'}>
                <Trans>Assign users</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      {state.showTable === Entities.groups ? (
        <GroupsTableComponent
          table={state.groups}
          checkable={{
            addSelectedRecords: (groups: Group[]) =>
              dispatch(
                mfaAppsDrawerActions.addSelectedGroupsToUnassign(groups)
              ),
            removeSelectedRecords: (groups: Group[]) =>
              dispatch(
                mfaAppsDrawerActions.removeSelectedGroupsToUnassign(groups)
              ),
            setSelectedRecords: (groups: Group[]) =>
              dispatch(mfaAppsDrawerActions.setSelectedGroupsToUnassign(groups))
          }}
          title={<Trans>No groups assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some groups.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                dispatch(
                  mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.newAssign)
                )
              }}
            >
              <TableViewButton data-qa={'AssignGroupsToMfaAppButton'}>
                <Trans>Assign groups</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => history.push(closeLink)}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            css={css`
              margin-right: 16px;
            `}
            id="UnassignButton"
            type="submit"
            key="Unassign"
            onClick={() => {
              dispatch(mfaAppsDrawerActions.unassign())
            }}
            disabled={unassignDisabled}
            data-qa="UnassignButton"
          >
            <Trans>Unassign</Trans>
          </PrimaryButton>
          <PrimaryButton
            id="ContinueButton"
            type="submit"
            key="Continue"
            onClick={() => {
              state.app.userIds = state.users.results.map(user =>
                user.id.toString()
              )
              state.app.groupIds = state.groups.results.map(group =>
                group.id.toString()
              )
              if (state.app) {
                dispatch(mfaAppsDrawerActions.saveApp(state.app))
              }
              dispatch(mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.allSet))
            }}
            disabled={false}
            data-qa="ContinueButton"
          >
            <Trans>Save & continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  StoreEnhancer
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { SagaMiddleware } from 'redux-saga'

import { acronisApiReducer } from '@lastpass/admin-console-dependencies/state/advanced/acronis-api/reducer'
import { enterpriseApiReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/reducer'
import { equivalentDomains } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/equivalent-domains/reducer'
import { multifactorOptionsReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/account-info/reducer'
import { duoSecuritySettingsReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/reducer'
import { enabledMultifactorOptionsReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/reducer'
import { rsaSecureIDRadiusReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/reducer'
import { secureAuthSettingsReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/reducer'
import { symantecVipSettingsReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/reducer'
import { neverOnlyUrls } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/neveronlyurls/reducer'
import { splunkIntegrationReducer } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/reducer'
import {
  newURLForm,
  urlRuleOptionsForm,
  urlRules
} from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/reducer'
import { psaIntegrationsReducer } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/reducer'
import { legacyVpnListReducer } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/reducer'
import { mfaAppsDrawerReducer } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/reducer'
import { mfaApplicationListReducer } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/reducer'
import { applicationDrawerReducer } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/reducer'
import { applicationEmptyListReducer } from '@lastpass/admin-console-dependencies/state/applications/saml/emptylist/reducer'
import { applicationReducer } from '@lastpass/admin-console-dependencies/state/applications/saml/list/reducer'
import { workstationListReducer } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/reducer'
import { assignReducer } from '@lastpass/admin-console-dependencies/state/common/assign/reducer'
import { groupFilterReducer } from '@lastpass/admin-console-dependencies/state/common/group-filter/reducer'
import { userFilterReducer } from '@lastpass/admin-console-dependencies/state/common/user-filter/reducer'
import { companyInfoReducer } from '@lastpass/admin-console-dependencies/state/company/reducer'
import { dashboardDrawerReducer } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/reducer'
import { dashboardReducer } from '@lastpass/admin-console-dependencies/state/dashboard/reducer'
import { globalReducer } from '@lastpass/admin-console-dependencies/state/global/reducer'
import { managedCompanyOperationsReducer } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/reducer'
import { managedCompaniesReducer } from '@lastpass/admin-console-dependencies/state/home/managed-companies/reducer'
import { generalPoliciesDrawerReducer } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/reducer'
import { generalPoliciesListReducer } from '@lastpass/admin-console-dependencies/state/policies/general/list/reducer'
import { generalPolicyAssignUsersAndGroupsReducer } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/reducer'
import { generalPolicySettingsReducer } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/reducer'
import { generalPolicyUsersAndGroupsReducer } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/reducer'
import { advancedPolicyDrawerReducer } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/reducer'
import { multifactorDrawerReducer } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/reducer'
import { multifactorPageReducer } from '@lastpass/admin-console-dependencies/state/policies/multifactor/reducer'
import { passwordlessContainerReducer } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/reducer'
import { passwordlessDrawerReducer } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/reducer'
import { advancedUserActivityEvents } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/reducer'
import { loginReportEvents } from '@lastpass/admin-console-dependencies/state/reports/login/reducer'
import { samlResponseReducer } from '@lastpass/admin-console-dependencies/state/reports/saml/reducer'
import { securityReportDrawerReducer } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/reducer'
import { securityReportEvents } from '@lastpass/admin-console-dependencies/state/reports/security/reducer'
import { sharedFolderDrawerReducer } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/reducer'
import { sharedFolderListReducer } from '@lastpass/admin-console-dependencies/state/shared-folders/list/reducer'
import { systemUpgradeReducer } from '@lastpass/admin-console-dependencies/state/upgrade/reducer'
import { assignAdminLevelUsersDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/admin/add-users/reducer'
import { adminLevelDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/reducer'
import { adminListReducer } from '@lastpass/admin-console-dependencies/state/users/admin/list/reducer'
import { directoriesIntegrationsReducer } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/reducer'
import { federatedLoginReducer } from '@lastpass/admin-console-dependencies/state/users/federated-login/reducer'
import { newGroupDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/groups/add/reducer'
import { assignGroupUsersDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/reducer'
import { groupDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/reducer'
import { groupsListReducer } from '@lastpass/admin-console-dependencies/state/users/groups/list/reducer'
import { addUsersDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/view/add/reducer'
import { usersDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/view/drawer/reducer'
import { usersListReducer } from '@lastpass/admin-console-dependencies/state/users/view/list/reducer'
import { distributeRecoveryKeysReducer } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/reducer'
import { transferVaultDrawerReducer } from '@lastpass/admin-console-dependencies/state/users/view/transfer-vault/reducer'

const createReducer = history =>
  combineReducers({
    router: connectRouter(history),
    global: globalReducer,
    companyInfo: companyInfoReducer,
    // Users
    usersList: usersListReducer,
    usersDrawer: usersDrawerReducer,
    addUsersDrawer: addUsersDrawerReducer,
    transferVaultDrawer: transferVaultDrawerReducer,
    //Groups
    groupsList: groupsListReducer,
    assignGroupUsersDrawer: assignGroupUsersDrawerReducer,
    newGroupDrawer: newGroupDrawerReducer,
    groupDrawer: groupDrawerReducer,
    //Admin
    adminList: adminListReducer,
    adminLevelDrawer: adminLevelDrawerReducer,
    assignAdminLevelUsersDrawer: assignAdminLevelUsersDrawerReducer,
    //Directories
    integrations: directoriesIntegrationsReducer,
    //reports
    loginReportEvents: loginReportEvents,
    securityReportEvents: securityReportEvents,
    securityReportDrawer: securityReportDrawerReducer,
    advancedUserActivityEvents: advancedUserActivityEvents,
    samlResponse: samlResponseReducer,
    //enterprise options
    equivalentDomains: equivalentDomains,
    urlRules: urlRules,
    newURLForm: newURLForm,
    urlRuleOptionsForm: urlRuleOptionsForm,
    neverOnlyUrls: neverOnlyUrls,
    splunkIntegration: splunkIntegrationReducer,
    //multifactor-options
    enabledMultifactorOptions: enabledMultifactorOptionsReducer,
    duoSecuritySettings: duoSecuritySettingsReducer,
    multifactorOptions: multifactorOptionsReducer,
    rsaSecureIDRadius: rsaSecureIDRadiusReducer,
    symantecVipSettings: symantecVipSettingsReducer,
    secureAuthSettings: secureAuthSettingsReducer,
    //policies
    generalPoliciesList: generalPoliciesListReducer,
    generalPoliciesDrawer: generalPoliciesDrawerReducer,
    generalPolicySettings: generalPolicySettingsReducer,
    generalPolicyUsersAndGroups: generalPolicyUsersAndGroupsReducer,
    generalPolicyAssignUsersAndGroups: generalPolicyAssignUsersAndGroupsReducer,
    passwordlessContainer: passwordlessContainerReducer,
    multifactorPage: multifactorPageReducer,
    multifactorDrawer: multifactorDrawerReducer,
    advancedPolicyDrawer: advancedPolicyDrawerReducer,
    assign: assignReducer,
    userFilter: userFilterReducer,
    groupFilter: groupFilterReducer,
    passwordlessDrawer: passwordlessDrawerReducer,
    //enterprise api
    enterpriseApi: enterpriseApiReducer,
    //acronis api
    acronisApi: acronisApiReducer,
    //applications
    sharedFolderList: sharedFolderListReducer,
    sharedFolderDrawer: sharedFolderDrawerReducer,
    federatedLogin: federatedLoginReducer,
    applicationList: applicationReducer,
    applicationDrawer: applicationDrawerReducer,
    applicationEmptyList: applicationEmptyListReducer,
    mfaApplicationList: mfaApplicationListReducer,
    mfaAppsDrawer: mfaAppsDrawerReducer,
    // dashboard
    dashboard: dashboardReducer,
    dashboardDrawer: dashboardDrawerReducer,
    legacyVpnList: legacyVpnListReducer,
    workstationList: workstationListReducer,
    managedCompanies: managedCompaniesReducer,
    managedCompanyOperations: managedCompanyOperationsReducer,
    systemUpgrade: systemUpgradeReducer,
    distributeRecoveryKeys: distributeRecoveryKeysReducer,
    // psa
    psaIntegrations: psaIntegrationsReducer
  })
const appReducer = createReducer(history)

export type AppState = ReturnType<typeof appReducer>
const composeEnhancers = composeWithDevTools({
  // Specify custom devTools options
})

export function createAppStore(
  history: History,
  sagaMiddleware: SagaMiddleware,
  ...additionalMiddlewares: Middleware[]
) {
  const middlewares = [sagaMiddleware, ...additionalMiddlewares]
  const enhancer: StoreEnhancer =
    process.env.NODE_ENV === 'development'
      ? composeEnhancers(applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares)
  return createStore(createReducer(history), undefined, enhancer)
}

import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'

export function createAdminLevelSaga(userService: UACServices.Services) {
  return function* getAdminlevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.getCurrentAdminLevel>
  ) {
    try {
      const id = action.payload.params.path.id
      if (id) {
        const result: UACServices.AdminLevelAPI.Responses = yield call(
          userService.adminLevel,
          id
        )
        if (result.type === UACServices.AdminLevelAPI.SUCCESS) {
          const currentAdminLevel = result.body

          yield put(
            adminLevelDrawerActions.setCurrentAdminLevel(currentAdminLevel)
          )
        } else if (result.type === UACServices.AdminLevelAPI.NOT_FOUND) {
          yield put(push('/users/admin'))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

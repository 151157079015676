import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { DetailsDialog, PrimaryButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { UrlDecryptionProgressDialogContent } from './UrlDecryptionProgressDialogContent'

const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 30px;
`

export const UrlDecryptionProgressDialog = () => {
  const dispatch = useDispatch()

  const controller = new AbortController()

  const handleAbort = () => {
    controller.abort()
    dispatch(globalActions.emptyDialog())
  }

  return (
    <DetailsDialog
      closeDialog={handleAbort}
      width="634px"
      title={msg`Decrypting URL data`}
      disableCloseOnBackground
      content={
        <>
          <StyledLoadingContainer>
            <Loading color="blue900" active={true} />
          </StyledLoadingContainer>
          <UrlDecryptionProgressDialogContent controller={controller} />
        </>
      }
      buttons={[
        <PrimaryButton
          onClick={handleAbort}
          key="cancel"
          data-qa="UrlDecryptionProgressDialogCancelButton"
        >
          <Trans>Cancel</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import React from 'react'

import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { Fade } from '@lastpass/animation'

interface SpinnerProps {
  active?: boolean
  color: string
  isFull?: boolean
  isBackgroundOverlayed?: boolean
}

export const SPINNER_Z_INDEX = 100

const Bump = keyframes`
  0% {
    top: 0;
  }
  25% {
    top: -7px;
  }
  50% {
    top: 8px;
  }
  75% {
    top: -10px;
  }
  100% {
    top: 0;
  }
`

const Ellipsis = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  div {
    position: relative;
    margin: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${props => props.theme.colors[props.color] || '#d32d27'};
  }
  div:nth-of-type(1) {
    animation-name: ${Bump};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0.1s;
  }
  div:nth-of-type(2) {
    animation-name: ${Bump};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0.3s;
  }
  div:nth-of-type(3) {
    animation-name: ${Bump};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
  }
  div:nth-of-type(4) {
    height: 38px;
    width: 4px;
    border-radius: 3px;
  }
`

export function Loading({
  color,
  isFull = true,
  active,
  isBackgroundOverlayed = false
}: SpinnerProps) {
  const containerCss = css`
    z-index: ${SPINNER_Z_INDEX};
    background-color: ${isBackgroundOverlayed
      ? `#1d304980`
      : `rgba(255, 255, 255, 0.8)`};
    ${isFull && `position: absolute;`}
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  `

  return (
    <Fade
      timeout={500}
      in={active}
      css={containerCss}
      data-qa="Ellipsis"
      unmountOnExit
    >
      <Ellipsis color={color}>
        <div />
        <div />
        <div />
        <div />
      </Ellipsis>
    </Fade>
  )
}

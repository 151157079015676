import { StatusCodes } from 'http-status-codes'

import { AuthFetchRetry } from '@lastpass/http'

import { KeyRotationError } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-error'
import { KeyRotationErrors } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-error-codes'
import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (keyRotationAlpToken: string) =>
  createResponse(SUCCESS, keyRotationAlpToken)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetchRetry) {
  return async function service() {
    let isConflict = false
    let response: string | null
    let status: number

    try {
      ;[response, status] = await fetch(
        'company/knowledge-component-rotations',
        'POST',
        {},
        {
          'content-type': 'application/json'
        }
      )
    } catch {
      throw new KeyRotationError({
        feErrorCode: KeyRotationErrors.AlpGetSessionTokenRequestConnectionFailed
      })
    }

    try {
      if (status === StatusCodes.OK && response) {
        const sessionToken = JSON.parse(response).content.sessionToken
        return success(sessionToken)
      } else if (status === StatusCodes.CONFLICT) {
        isConflict = true
      }
    } catch {
      // do nothing
    }
    if (isConflict) {
      throw new KeyRotationError({
        feErrorCode: KeyRotationErrors.AlpGetSessionTokenRequestConflict,
        httpErrorCode: status
      })
    }
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.AlpGetSessionTokenRequestFailed,
      httpErrorCode: status
    })
  }
}

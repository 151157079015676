import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegular,
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import {
  MfaAppsDrawerState,
  MfaAppsDrawerSteps
} from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'

import { MfaAdvancedSettingsComponent } from '../MfaAdvancedSettingsComponent'
import { HowToInstall } from './install/HowToInstall'

export interface EditIntegrationProps {
  closeLink: string
}

const SecretContainer = styled.div`
  margin-top: 12px;
`

const CancelButton = styled(TextButton)`
  margin-right: 16px;
`

const StyledHeading100 = styled(Heading100)`
  margin-top: 24px;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-top: 16px;
`

export const EditIntegrationComponent: React.FunctionComponent<EditIntegrationProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const drawerState = useSelector<AppState, MfaAppsDrawerState>(
    state => state.mfaAppsDrawer
  )
  const application = drawerState.app

  const isApplicationAzureAd = application.type === MfaApplicationType.azureAD

  return (
    <>
      <TextInput
        value={application.name}
        data-qa="MfaAppNameInputField"
        maxLength={50}
        onChange={e => {
          setIsBlockingPromptEnabled(true)
          dispatch(mfaAppsDrawerActions.setName(e.target.value))
        }}
        disabled={application.type === MfaApplicationType.azureAD}
      >
        <Trans>Name</Trans>
      </TextInput>
      <StyledHeading100>
        <Trans>Integration key & secret</Trans>
      </StyledHeading100>
      <StyledBodyRegular>
        <Trans>
          If you’ve lost the secret, you’ll have to set up a new integration.
          For your own security, we can’t display it.
        </Trans>
      </StyledBodyRegular>
      <SecretContainer>
        <TextInput
          data-qa="AppKeyField"
          disabled
          copyToClipboard
          value={application.key}
          actionOnCopy={() => {
            dispatch(
              mfaAppsDrawerActions.setNotification(CopyToClipboardNotification)
            )
          }}
        >
          <Trans>Key</Trans>
        </TextInput>
      </SecretContainer>
      <HowToInstall mfaAppType={application.type} />
      {!isApplicationAzureAd && (
        <MfaAdvancedSettingsComponent isReadonly={true} />
      )}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <CancelButton
            key="Cancel"
            onClick={() => history.push(closeLink)}
            data-qa="CancelButton"
          >
            <Trans>Cancel</Trans>
          </CancelButton>
          {!isApplicationAzureAd && (
            <PrimaryButton
              id="SaveButton"
              key="Save"
              onClick={() => {
                setIsBlockingPromptEnabled(false)
                dispatch(mfaAppsDrawerActions.saveApp(application))
                dispatch(
                  mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.assign)
                )
              }}
              disabled={!drawerState.canSave}
              data-qa="SaveButton"
            >
              <Trans>Save & edit users</Trans>
            </PrimaryButton>
          )}
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { BodyRegular, Collapsible, WithTooltip } from '@lastpass/lastkit'
import { Select } from '@lastpass/lastkit'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaAppsDrawerState } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'

export interface MappingKeyProps {
  isReadonly: boolean
}

const StyledBody = styled(BodyRegular)`
  margin-top: 16px;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 20px;
  width: 20px;
  margin: auto;
  margin-left: 18px;
`

const StyledLocationLink = styled.a`
  margin-top: 32px;
`

const SelectContainer = styled.div`
  display: flex;
  align-content: center;
`

export const MfaAdvancedSettingsComponent: React.FunctionComponent<MappingKeyProps> = ({
  isReadonly
}) => {
  const dispatch = useDispatch()
  const drawerState = useSelector<AppState, MfaAppsDrawerState>(
    state => state.mfaAppsDrawer
  )
  const application = drawerState.app

  const options = drawerState.potentialMappingKeys.map(
    (mappingKey: string) => ({
      label: mappingKey,
      value: mappingKey
    })
  )

  return (
    <Collapsible
      containerCssOverride={css`
        margin: 25px 0 0 0;
        padding: 0;
      `}
      title={<Trans>Advanced settings</Trans>}
      simple
      qadata="MfaAppsAdvancedSettings"
    >
      <StyledBody>
        <Trans>Username attribute</Trans>
      </StyledBody>
      <SelectContainer>
        <Select
          classNamePrefix="list"
          dataQa="SelectMappingKeyDropdown"
          options={options}
          fluid
          isDisabled={isReadonly}
          placeholder={application.mappingKey}
          onChange={selectedItem => {
            const { value } = selectedItem as { label: string; value: string }
            dispatch(mfaAppsDrawerActions.setMappingKey(value))
          }}
        />
        <WithTooltip
          tooltip={
            <>
              <Trans>
                Choose the user directory attribute that contains the username
                LastPass can use to identify users upon login to this app. The
                value of this attribute can be populated either manually or
                automatically using either SCIM or the LastPass Active Directory
                Connector.
              </Trans>{' '}
              <StyledLocationLink
                href="https://support.lastpass.com/help/lastpass-admin-toolkit-lastpass-mfa-apps"
                data-qa="LearnMoreAboutMappingKeyLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Learn more</Trans>
              </StyledLocationLink>
            </>
          }
        >
          <StyledHelpIcon data-qa="MfaAppsAdvancedSettingsHelpIcon" />
        </WithTooltip>
      </SelectContainer>
    </Collapsible>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'
import { RouteProps } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import { BodyRegular, Heading300, TabNavigation } from '@lastpass/lastkit'
import { RoutedTabNavigationItem } from '@lastpass/lastkit'
import { TabContainer } from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { enterpriseApiActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/actions'
import { EnterpriseApiState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { ApiReference } from '@lastpass/admin-console-dependencies/ui/advanced/enterpriseApi/documentation/ApiReference'
import { Documentation } from '@lastpass/admin-console-dependencies/ui/advanced/enterpriseApi/documentation/Documentation'
import { ExampleUsage } from '@lastpass/admin-console-dependencies/ui/advanced/enterpriseApi/documentation/ExampleUsage'
import { ResponseError } from '@lastpass/admin-console-dependencies/ui/advanced/enterpriseApi/documentation/ResponseError'
import { ProvisioningHash } from '@lastpass/admin-console-dependencies/ui/advanced/enterpriseApi/ProvisioningHash'

const PageItemContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 18px;
`

const SubPageItemContainer = styled(PageItemContainer)`
  max-width: 760px;
`

const StyledTabContainer = styled(TabContainer)`
  padding-bottom: 32px;
`

type EnterpriseApiPageProps = LastPassComponentProps<
  EnterpriseApiState,
  typeof enterpriseApiActions,
  {}
>

export const EnterpriseApiPage: React.FunctionComponent<EnterpriseApiPageProps> = ({
  state,
  actions
}) => {
  const dispatch = useDispatch()

  const { accountNumber } = useSelector<AppState, CompanyDetails>(
    state => state.companyInfo.details
  )

  useEffect(() => {
    dispatch(enterpriseApiActions.getEnterpriseApi())
  }, [dispatch])

  const match = useRouteMatch()
  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const defaultRoute = {
      route: {
        path: `${matchPath}`,
        exact: true
      },
      url: `${matchUrl}`
    }
    const [apiReference, responseError, exampleUsage] = [
      'api-reference',
      'response-error',
      'example-usage'
    ].map(path => {
      const route: RouteProps = {
        path: `${matchPath}/${path}`
      }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    })

    if (state.inProgress) {
      return <Loading color="blue900" active={true} />
    } else {
      return (
        <>
          <PageItemContainer>
            <Heading300 data-qa={'EnterpriseApiPageHeader'}>
              <Trans>Enterprise API</Trans>
            </Heading300>
            <BodyRegular>
              <Trans>Account number</Trans>
              {': ' + accountNumber}
            </BodyRegular>
          </PageItemContainer>
          <PageItemContainer>
            <ProvisioningHash
              alreadyHasHash={state.alreadyHasHash}
              hash={state.hash}
              generateHash={actions.generateProvisioningHash}
              resetHash={() => actions.generateProvisioningHash(true)}
            />
          </PageItemContainer>
          <StyledTabContainer data-qa={'TabContainer'}>
            <TabNavigation>
              <RoutedTabNavigationItem
                data-qa={'TabMenuDocumentation'}
                to={defaultRoute.url}
                route={defaultRoute.route}
              >
                <Trans>Documentation</Trans>
              </RoutedTabNavigationItem>
              <RoutedTabNavigationItem
                data-qa={'TabMenuApiReference'}
                to={apiReference.url}
                route={apiReference.route}
              >
                <Trans>API reference</Trans>
              </RoutedTabNavigationItem>
              <RoutedTabNavigationItem
                data-qa={'TabMenuResponseError'}
                to={responseError.url}
                route={responseError.route}
              >
                <Trans>Response / Error</Trans>
              </RoutedTabNavigationItem>
              <RoutedTabNavigationItem
                data-qa={'TabMenuExampleUsage'}
                to={exampleUsage.url}
                route={exampleUsage.route}
              >
                <Trans>Example usage</Trans>
              </RoutedTabNavigationItem>
            </TabNavigation>
          </StyledTabContainer>
          <SubPageItemContainer>
            <Switch>
              <Route {...defaultRoute.route}>
                <Documentation companyId={'123456'} />
              </Route>
              <Route {...apiReference.route}>
                <ApiReference examples={state.apiExamples || []} />
              </Route>
              <Route {...responseError.route}>
                <ResponseError />
              </Route>
              <Route {...exampleUsage.route}>
                <ExampleUsage />
              </Route>
            </Switch>
          </SubPageItemContainer>
        </>
      )
    }
  }
  return null
}

export function createEnterpriseApiPage<
  TState extends { enterpriseApi: EnterpriseApiState }
>() {
  return connectRedux(
    EnterpriseApiPage,
    (state: TState) => state.enterpriseApi,
    enterpriseApiActions
  )
}

export type createEnterpriseApiPageComponent = ReturnType<
  typeof createEnterpriseApiPage
>

import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import _ from 'lodash'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components'
import {
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import {
  AdvancedUserActivityEventId,
  AdvancedUserActivityFilter
} from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'
import { groupId } from '@lastpass/admin-console-dependencies/types/group'
import { SelectUserGroups } from '@lastpass/admin-console-dependencies/ui/common/SelectUserGroups'
import { SelectUsers } from '@lastpass/admin-console-dependencies/ui/common/SelectUsers'

import { FlexContainer } from '../shared/AdvancedUserActivityStyledComponents'
import { SelectEvents } from './components/SelectEvents'

export interface AdvancedUserActivityFilterDrawerProps {
  closeLink: string
  title: ReactElement<string>
}

const styleMarginRight = css`
  margin-right: 16px;
`

const InputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 28px;
  width: 100%;
`

export const AdvancedUserActivityFilterDrawer: React.FunctionComponent<AdvancedUserActivityFilterDrawerProps> = ({
  closeLink,
  title
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const updateQuery = useUpdateQuery(Namespace.userReports)
  const queryParams = useQueryParams(Namespace.userReports)
  const { selectedSavedReport, drawerIsLoading } = useSelector<
    AppState,
    AdvancedUserActivityListState
  >(state => state.advancedUserActivityEvents)
  const { currentUser } = useSelector<AppState, GlobalState>(
    state => state.global
  )

  const [groupFilters, setGroupFilters] = useState<groupId[]>(
    (queryParams.filter && queryParams.filter.group) || []
  )
  const [userFilters, setUserFilters] = useState<string[]>(
    (queryParams.filter && queryParams.filter.user) || []
  )
  const [userEventFilters, setUserEventFilters] = useState<
    AdvancedUserActivityEventId[]
  >((queryParams.filter && queryParams.filter.userEvents) || [])
  const [adminEventFilters, setAdminEventFilters] = useState<
    AdvancedUserActivityEventId[]
  >((queryParams.filter && queryParams.filter.adminEvents) || [])

  const getFilters = (): AdvancedUserActivityFilter => {
    const filters: AdvancedUserActivityFilter = {}
    if (userFilters.length) {
      filters.user = userFilters
    }
    if (groupFilters.length) {
      filters.group = groupFilters
    }
    if (userEventFilters.length) {
      filters.userEvents = userEventFilters
    }
    if (adminEventFilters.length) {
      filters.adminEvents = adminEventFilters
    }
    return filters
  }

  useEffect(() => {
    if (queryParams.filter) {
      setGroupFilters(queryParams.filter.group || [])
      setUserFilters(queryParams.filter.user || [])
      setUserEventFilters(queryParams.filter.userEvents || [])
      setAdminEventFilters(queryParams.filter.adminEvents || [])
    }
  }, [queryParams])

  const onApplyChanges = (event?) => {
    updateQuery({
      ...queryParams,
      filter: getFilters()
    })

    history.push({
      pathname: closeLink,
      search: location.search
    })

    event &&
      event.target &&
      dispatch(advancedUserActivityActions.removeSelectedSavedReport())
  }

  const onSaveFilters = () => {
    dispatch(advancedUserActivityActions.setFilterForSave(getFilters()))
    history.push({
      pathname: `${closeLink}/save`,
      search: location.search
    })
  }

  const onUpdateReport = () => {
    dispatch(advancedUserActivityActions.updateReport(getFilters()))
    onApplyChanges()
  }

  return (
    <Drawer closeLink={closeLink} mainTitle={title}>
      {drawerIsLoading && <Loading color="blue900" active={true} />}
      <FlexContainer
        css={css`
          flex-wrap: wrap;
        `}
      >
        <InputContainer>
          <SelectUsers updateSelection={setUserFilters} filters={userFilters} />
        </InputContainer>

        <InputContainer>
          <SelectUserGroups
            updateSelection={setGroupFilters}
            filters={groupFilters}
          />
        </InputContainer>

        <InputContainer>
          <SelectEvents
            updateUserEventSelection={setUserEventFilters}
            updateAdminEventSelection={setAdminEventFilters}
            userEventFilters={userEventFilters}
            adminEventFilters={adminEventFilters}
          />
        </InputContainer>
      </FlexContainer>

      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          <LocationLink to={closeLink}>
            <TextButton css={styleMarginRight} data-qa="DiscardChangesButton">
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>

          {selectedSavedReport &&
            (selectedSavedReport.adminEmail === currentUser.email ||
              selectedSavedReport.adminEmail === '') && (
              <>
                <PrimaryButton
                  data-qa="UpdateReportButton"
                  css={styleMarginRight}
                  onClick={onUpdateReport}
                  type="button"
                  disabled={_.isEqual(queryParams.filter, getFilters())}
                >
                  <Trans>Save changes</Trans>
                </PrimaryButton>
              </>
            )}

          <PrimaryButton
            data-qa="SaveReportButton"
            css={styleMarginRight}
            onClick={onSaveFilters}
            type="button"
          >
            <Trans>Save as new</Trans>
          </PrimaryButton>

          <PrimaryButton
            onClick={onApplyChanges}
            type="submit"
            disabled={_.isEqual(queryParams.filter, getFilters())}
          >
            <Trans>Apply filters</Trans>
          </PrimaryButton>
        </DrawerButtonsContainer>
      </FooterContainer>
    </Drawer>
  )
}

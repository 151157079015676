import styled from '@emotion/styled'

import { BodySemiboldStyle, CaptionBoldStyle } from '../styles'
import { ThemeColor } from '../theme'

export const PrimaryButton = styled.button<{
  red?: boolean
  customColors?: {
    backgroundColor: ThemeColor
    textColor: ThemeColor
    hoverBackgroundColor: ThemeColor
    focusBackgroundColor: ThemeColor
  }
}>`
  ${BodySemiboldStyle}
  height: 40px;
  min-width: 135px;
  border-radius: ${props => props.theme.radius.full};
  outline: none;
  background: ${props =>
    (props.customColors &&
      props.theme.colors[props.customColors.backgroundColor]) ||
    (props.red && props.theme.colors.red700) ||
    props.theme.colors.blue};
  color: ${props =>
    (props.customColors && props.theme.colors[props.customColors.textColor]) ||
    props.theme.colors.neutral};
  border-style: none;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    background: ${props =>
      (props.customColors &&
        props.theme.colors[props.customColors.hoverBackgroundColor]) ||
      (props.red && props.theme.colors.red800) ||
      props.theme.colors.blue800};
  }
  &:active {
    background: ${props =>
      props.red ? props.theme.colors.red900 : props.theme.colors.blue900};
  }
  &:focus {
    background: ${props =>
      (props.customColors &&
        props.theme.colors[props.customColors.focusBackgroundColor]) ||
      (props.red && props.theme.colors.red700) ||
      props.theme.colors.blue700};
    border: ${props => '2px solid ' + props.theme.colors.teal500};
  }
  &:disabled {
    border: ${props => '1px solid ' + props.theme.colors.neutral300};
    background: ${props => props.theme.colors.neutral200};
    cursor: auto;
    color: ${props => props.theme.colors.neutral500};
  }
`

export const SmallPrimaryButton = styled.button`
  ${CaptionBoldStyle};
  height: 24px;
  border-radius: ${props => props.theme.radius.full};
  outline: none;
  background: ${props => props.theme.colors.yellow600};
  color: ${props => props.theme.colors.neutral900};
  border-style: none;
  padding: 0 12px;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.yellow700};
  }
`

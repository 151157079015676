import React, { FunctionComponent, useRef } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { DetailsDialog, PrimaryButton, TextButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

interface InvalidFileDialogProps {
  actions: typeof globalActions
}

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 16px;
`

export const InvalidFileDialogComponent: FunctionComponent<InvalidFileDialogProps> = ({
  actions
}) => {
  const dispatch = useDispatch()
  const uploadRef = useRef<HTMLInputElement>(null)

  return (
    <DetailsDialog
      closeDialog={() => dispatch(globalActions.emptyDialog())}
      width="480px"
      title={msg`Error: Invalid file type.`}
      subTitle={msg`Try again with a valid csv file.`}
      content={
        <>
          <input
            accept=".csv"
            id="myInput"
            type="file"
            ref={uploadRef}
            style={{ display: 'none' }}
            onChange={e => {
              e.target.files &&
                actions.processPendingUsersUpload(e.target.files, [])
              actions.emptyDialog()
            }}
          />
          <StyledPrimaryButton
            type="button"
            onClick={() => {
              uploadRef && uploadRef.current && uploadRef.current.click()
            }}
            data-qa="UploadSCVButton"
          >
            <Trans>Choose another file</Trans>
          </StyledPrimaryButton>

          <a
            href={`${window.location.origin}/assets/example-users.csv`}
            key="download"
            onClick={() => actions.emptyDialog()}
            data-qa="DownloadCSVTemplateLink"
          >
            <TextButton>
              <Trans>Download CSV template</Trans>
            </TextButton>
          </a>
        </>
      }
    />
  )
}

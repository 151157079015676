import {
  createApplication,
  Group,
  SelectedApplication,
  User
} from '@lastpass/admin-console-dependencies/state/applications/application'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

const customAppName = 'Custom SSO App'

export function mapFormFieldsToAppProps(
  fieldValues,
  app: SelectedApplication
): SelectedApplication {
  return createApplication(
    app.isCustom,
    fieldValues.nickName,
    app.applicationId,
    app.defaultPartnerName ? app.defaultPartnerName : '',
    app.defaultIdentityProvider,
    fieldValues.signAssertion,
    fieldValues.signRequest,
    fieldValues.signResponse,
    fieldValues.encryptAssertion,
    app.customLogoUrl,
    app.isCustom ? customAppName : app.name,
    fieldValues.ACS,
    fieldValues.identifier,
    fieldValues.relayState,
    fieldValues.entityId,
    fieldValues.role,
    fieldValues.identityProvider,
    fieldValues.stepUp,
    fieldValues.SAMLSignatureMethod !== 'SHA1',
    fieldValues.certificateId,
    app.id,
    fieldValues.samlAttributes,
    fieldValues.partnerCertificate
  )
}

export function mapGetAppResponseToSelectedApplication(
  applicationResponse
): SelectedApplication {
  return {
    id: applicationResponse.Id,
    applicationId: applicationResponse.ApplicationId,
    HTMLInfo: applicationResponse.HTMLInfo,
    name: applicationResponse.Name,
    applicationName: applicationResponse.ApplicationName,
    securityEmail: applicationResponse.SecurityEmail,
    isSaml: applicationResponse.IsSaml,
    isVpn: applicationResponse.IsVpn,
    isCustom: applicationResponse.IsCustom,
    allowEdit: applicationResponse.AllowEdit,
    attributes: applicationResponse.Attributes,
    relayState: applicationResponse.RelayState,
    partnerName: applicationResponse.PartnerName,
    defaultPartnerName: applicationResponse.DefaultPartnerName,
    role: applicationResponse.Role,
    identityProvider: applicationResponse.IdentityProvider,
    defaultIdentityProvider: applicationResponse.DefaultIdentityProvider,
    signAssertion: applicationResponse.SignAssertion,
    wantAuthnRequestSigned: applicationResponse.WantAuthnRequestSigned,
    signSAMLResponse: applicationResponse.SignSAMLResponse,
    encryptAssertion: applicationResponse.EncryptAssertion,
    appKey: applicationResponse.AppKey,
    authType: applicationResponse.AuthType,
    useStepUpAuthentication: applicationResponse.UseStepUpAuthentication,
    useSHA256ForSigning: applicationResponse.UseSHA256ForSigning,
    webAddress: applicationResponse.WebAddress,
    samlKeyId: applicationResponse.SamlKeyId,
    customLogoUrl: applicationResponse.CustomLogoUrl,
    partnerCertificateFingerprint:
      applicationResponse.PartnerCertificateFingerprint,
    partnerCertificate: applicationResponse.PartnerCertificate,
    clearPartnerCertificate: applicationResponse.ClearPartnerCertificate,
    samlAttributes: applicationResponse.SamlAttributes
    //TODO: CustomLogo, CustomLogoFileName, ClearCustomLogo
  }
}

export function addIdAndNameToSavedApp(
  appId: string,
  appName: string,
  app: SelectedApplication
): SelectedApplication {
  const result = app
  result.id = appId
  result.name = appName
  return result
}

export function MapTeamsPolicyUserInfo(users: User[]): TeamsPolicyUserInfo[] {
  const result: TeamsPolicyUserInfo[] = users
    .filter((user: User) => user.lastPassId)
    .map(
      (user: User): TeamsPolicyUserInfo => ({
        uid: user.lastPassId ? user.lastPassId.toString() : '',
        email: user.name,
        name: '',
        parent: ''
      })
    )

  return result
}

export function MapTeamsPolicyGroup(groups: Group[]): TeamsPolicyGroup[] {
  const result: TeamsPolicyGroup[] = groups
    .filter((group: Group) => group.lastPassId)
    .map(
      (group: Group): TeamsPolicyGroup => ({
        id: group.lastPassId ? group.lastPassId.toString() : '',
        name: group.name,
        userscount: 0
      })
    )

  return result
}

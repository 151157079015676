import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

export function create(fetch: AuthFetch) {
  return async function() {
    try {
      const [response, status] = await fetch('pendo/token', 'GET')
      if (status === StatusCodes.OK && response) {
        return createResponse(SUCCESS, JSON.parse(response).content)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import {
  AssignmentStatus,
  AvailabilityStatus,
  PsaCompanyContractAssignmentOption
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'
import { MessageTypes } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/types/message-types'

export interface AgreementSelectionDetails {
  initiallySelectedOptionId: string | null
  initiallySelectedOptionStatus: AssignmentStatus
  selectedOptionId: string | null
  selectedOptionStatus: AssignmentStatus
  numberOfAvailableOptions: number
  selectedOptionAvailability?: AvailabilityStatus
}

export interface AgreementSelectionMessage {
  message: MessageDescriptor
  type: MessageTypes
}

export interface AgreementSelectionMessages {
  [companyId: number]: null | AgreementSelectionMessage
}

type GetInitialMessageType = (
  numberOfAvailableOptions: number,
  selectedOptionAvailability?: AvailabilityStatus
) => null | AgreementSelectionMessage

const getInitialMessages: GetInitialMessageType = (
  numberOfAvailableOptions,
  selectedOptionAvailability
) => {
  let message: null | AgreementSelectionMessage = null

  if (!numberOfAvailableOptions) {
    message = {
      message: msg`We couldn’t find any agreements for this company in your PSA suite.`,
      type: MessageTypes.INFO
    }
  } else if (selectedOptionAvailability === AvailabilityStatus.NOT_AVAILABLE) {
    message = {
      message: msg`The agreement previously selected for this company seems unavailable in your PSA suite.`,
      type: MessageTypes.WARNING
    }
  }
  return message
}

type GetAgreementSelectionMessageType = (
  details: AgreementSelectionDetails
) => null | AgreementSelectionMessage

export const getAgreementSelectionMessage: GetAgreementSelectionMessageType = ({
  initiallySelectedOptionId,
  initiallySelectedOptionStatus,
  selectedOptionId,
  selectedOptionStatus,
  selectedOptionAvailability,
  numberOfAvailableOptions
}) => {
  let message: null | AgreementSelectionMessage = null

  if (
    initiallySelectedOptionId === selectedOptionId &&
    initiallySelectedOptionStatus === selectedOptionStatus &&
    selectedOptionStatus !== AssignmentStatus.EXCLUDED
  ) {
    message = getInitialMessages(
      numberOfAvailableOptions,
      selectedOptionAvailability
    )
  } else if (initiallySelectedOptionId && !selectedOptionId) {
    message = {
      message: msg`To ensure successful billing updates, go back and select an agreement for each company.`,
      type: MessageTypes.WARNING
    }
  }

  return message
}

type GetAgreementSelectionMessages = (
  initialAssignmentsMap: AssignmentsMapping,
  actualAssignmentsMap: AssignmentsMapping,
  assignmentOptions: PsaCompanyContractAssignmentOption[]
) => AgreementSelectionMessages

export const getAgreementSelectionMessages: GetAgreementSelectionMessages = (
  initialAssignmentsMap,
  actualAssignmentsMap,
  assignmentOptions
) => {
  const messages = {}

  assignmentOptions.forEach(({ company, contractAssignment }) => {
    const initialAssignmentsMapItem = initialAssignmentsMap[company.id]
    const actualAssignmentsMapItem = actualAssignmentsMap[company.id]

    if (initialAssignmentsMapItem && actualAssignmentsMapItem) {
      messages[company.id] = getAgreementSelectionMessage({
        initiallySelectedOptionId: initialAssignmentsMapItem.contractId,
        initiallySelectedOptionStatus:
          initialAssignmentsMapItem.assignmentStatus,
        selectedOptionId: actualAssignmentsMapItem.contractId,
        selectedOptionStatus: actualAssignmentsMapItem.assignmentStatus,
        selectedOptionAvailability: actualAssignmentsMapItem.availabilityStatus,
        numberOfAvailableOptions: contractAssignment.contracts.length
      })
    }
  })

  return messages
}

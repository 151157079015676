import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SecureAuthSettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/state'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(secureAuthSettings: SecureAuthSettingsState) {
    try {
      const [response, status] = await fetch(
        'v3/company/secure-auth-settings',
        'POST',
        {
          applicationId: secureAuthSettings.applicationId,
          applicationKey: secureAuthSettings.applicationKey,
          realm: secureAuthSettings.realm
        },
        {
          'Content-Type': 'application/json'
        }
      )
      if (status === StatusCodes.OK && response) {
        const { status: saveSuccess } = JSON.parse(response)
        if (saveSuccess === 'ok') {
          return success()
        }
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

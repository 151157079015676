import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { FederatedLoginActionTypes } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

import { createGetOpenIdSetup } from './get-open-id-setup'
import { createSaveOpenIdSetup } from './save-open-id-setup'

export function createOpenIdSetupSaga(
  openIdSetupService: UACServices.Services
) {
  const getOpenIdSetupSaga = createGetOpenIdSetup(openIdSetupService)
  const saveOpenIdSetupSaga = createSaveOpenIdSetup(openIdSetupService)

  return function*() {
    yield takeEvery(
      FederatedLoginActionTypes.OPEN_ID_SETUP_GET,
      getOpenIdSetupSaga
    )
    yield takeEvery(
      FederatedLoginActionTypes.OPEN_ID_SETUP_SAVE,
      saveOpenIdSetupSaga
    )
  }
}

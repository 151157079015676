import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  ActivityEvent,
  ActivityEventRequestParams,
  ActivityEventUserType
} from '@lastpass/admin-console-dependencies/types/activity-event'

export const SUCCESS = 'success'

export interface Response {
  events: ActivityEvent[]
  lastCheckedDateTimeUtc: string
  areMoreEventsAvailable: boolean
}

const success = (response: Response) => createResponse(SUCCESS, response)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

const createQueryString = (params: ActivityEventRequestParams): string =>
  new URLSearchParams({ ...params }).toString()

export function create(fetch: AuthFetch, userType: ActivityEventUserType) {
  return async function service(params: ActivityEventRequestParams) {
    const [response, status] = await fetch(
      `reports/${userType}-activity/events?${createQueryString(params)}`,
      'GET'
    )
    if (status !== StatusCodes.OK || !response) {
      throw new UnknownServiceError()
    }

    const parsedResponse: Response = JSON.parse(response).content

    return success(parsedResponse)
  }
}

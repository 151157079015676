import React, { FC } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
`
const Text = styled.div`
  color: ${props => props.theme.colors.blue700};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

const LinkIcon = ({ icon }) => {
  const StyledIcon = styled(icon)`
    width: 16px;
    height: 16px;
  `
  return <StyledIcon />
}

interface ActionLinkProps {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  text: MessageDescriptor
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  dataQa?: string
}

export const ActionLink: FC<ActionLinkProps> = ({
  icon,
  text,
  onClick,
  dataQa
}) => {
  const { _ } = useLingui()

  return (
    <Container data-qa={dataQa} onClick={onClick}>
      {icon && <LinkIcon icon={icon} data-qa="LinkIcon" />}
      <Text> {_(text)}</Text>
    </Container>
  )
}

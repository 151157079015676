import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { AnimatedSwitch } from '@lastpass/routing'

import { AddURLRuleDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/AddUrlDrawer'
import { AddURLRuleDrawerForm } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/AddUrlDrawerForm'
import { URLRuleOptionsDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/UrlRuleOptionsDrawer'
import { UrlRuleOptionsDrawerForm } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/UrlRuleOptionsDrawerForm'
import { UrlRulesList } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/UrlRulesList'

export function UrlRules() {
  const match = useRouteMatch()
  if (match) {
    const { path } = match

    return (
      <>
        <UrlRulesList />
        <AnimatedSwitch timeout={200}>
          <Route path={`${path}/add`}>
            <AddURLRuleDrawer closeLink="/advanced/enterpriseOptions/urlRules">
              <AddURLRuleDrawerForm />
            </AddURLRuleDrawer>
          </Route>
          <Route path={`${path}/options`}>
            <URLRuleOptionsDrawer closeLink="/advanced/enterpriseOptions/urlRules">
              <UrlRuleOptionsDrawerForm />
            </URLRuleOptionsDrawer>
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

import styled from '@emotion/styled'

import { SearchInput } from '@lastpass/lastkit'

export const PartialSyncItemListContainer = styled.div`
  margin-top: -5px;
  height: 240px;
  overflow: auto;
  border: 1px solid #d5d9de;
  position: relative;
`
export const PartialSyncItemContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: ${props =>
    props.selected ? props.theme.colors.blue50 : 'transparent'};
`
export const CheckBoxContainer = styled.div`
  margin-right: 16px;
`

export const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  max-width: 100%;
  margin-right: 0px;
`

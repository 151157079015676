import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton } from '@lastpass/lastkit/components/PrimaryButton'
import { TabNavigation } from '@lastpass/lastkit/components/TabNavigation'
import { TabNavigationItem } from '@lastpass/lastkit/components/TabNavigationItem'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import {
  MfaAppsDrawerState,
  MfaAppsDrawerSteps
} from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { Entities } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

interface NavigationBarProps {
  usersTotalResult: number
  groupsTotalResult: number
  searchInput?: ReactJSXElement
}

export const NavigationBarComponent: React.FunctionComponent<NavigationBarProps> = ({
  usersTotalResult,
  groupsTotalResult,
  searchInput
}) => {
  const dispatch = useDispatch()
  const state: MfaAppsDrawerState = useSelector(
    (state: AppState) => state.mfaAppsDrawer
  )

  return (
    <TableContainer>
      {searchInput}
      <StyledTabContainer data-qa={'TabNavigationBar'}>
        <TabNavigation>
          <div
            data-qa={'AssignUsersTab'}
            onClick={() => {
              dispatch(mfaAppsDrawerActions.showTable(Entities.users))
            }}
          >
            <StyledTabNavigationItem
              active={state.showTable === Entities.users}
            >
              <Trans>Users</Trans>
              {` (${usersTotalResult})`}
            </StyledTabNavigationItem>
          </div>
          <div
            data-qa={'AssignGroupsTab'}
            onClick={() => {
              dispatch(mfaAppsDrawerActions.showTable(Entities.groups))
            }}
          >
            <StyledTabNavigationItem
              active={state.showTable === Entities.groups}
            >
              <Trans>Groups</Trans>
              {` (${groupsTotalResult})`}
            </StyledTabNavigationItem>
          </div>
        </TabNavigation>
        {!searchInput && (
          <PrimaryButton
            data-qa={'NewAssignButton'}
            onClick={() => {
              dispatch(
                mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.newAssign)
              )
            }}
          >
            <Trans>Assign users {'&'} groups</Trans>
          </PrimaryButton>
        )}
      </StyledTabContainer>
    </TableContainer>
  )
}

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { AdAttributeState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSaveAdAttributeName(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveAdAttributeName(
    action: ReturnType<typeof federatedLoginActions.saveAdAttributeName>
  ) {
    try {
      const adAttributeName: AdAttributeState = action.payload.adAttribute
      yield put(federatedLoginActions.setLoading(true))
      try {
        const adAttributeNameResult: UACServices.AdAttributeSaveAPI.Responses = yield call(
          enterpriseOptionServices.adAttributeSave,
          adAttributeName
        )
        if (
          adAttributeNameResult.type === UACServices.AdAttributeSaveAPI.SUCCESS
        ) {
          yield put(federatedLoginActions.getAdAttributeName())
          yield put(
            globalActions.setNotification({
              message: msg`AD Connector Configuration Successful`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`AD Connector Configuration Failed`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(federatedLoginActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import { SharedFolderDrawerUrlParams } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'
import {
  SharedFolder,
  sharedFolderListActions
} from '@lastpass/admin-console-dependencies/state/shared-folders/list/actions'
import { SharedFolderListState } from '@lastpass/admin-console-dependencies/state/shared-folders/list/state'

export interface SharedFolderDrawerProps {
  closeLink: string
}

export const SharedFolderDrawer: React.FunctionComponent<SharedFolderDrawerProps> = ({
  closeLink,
  children
}) => {
  const pathParams: SharedFolderDrawerUrlParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(sharedFolderListActions.getSharedFolderList())
  }, [dispatch])

  const sharedFolderListState: SharedFolderListState = useSelector(
    (state: AppState) => state.sharedFolderList
  )

  const title: SharedFolder = sharedFolderListState.table.results.filter(
    (value: SharedFolder) => {
      return value.shareid === pathParams.id
    }
  )[0]

  return (
    <Drawer
      closeLink={closeLink}
      mainTitle={(title && <>{title.name}</>) || <></>}
    >
      {children}
    </Drawer>
  )
}

import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { GeneralPolicySettings, GeneralPolicySettingsForm } from './state'

export interface GeneralPolicySettingsUrlParams {
  id: string
  configId: string
}

export interface GeneralPolicySettingQueryParams {
  search: string
  skip: number
}

export enum GeneralPolicySettingsActionTypes {
  RESET = '[general-policy-settings] reset',
  GET_GENERAL_POLICY_SETTINGS = '[general-policy-settings] get-general-policy-settings',
  SET_GENERAL_POLICY_SETTINGS = '[general-policy-settings] set-general-policy-settings',
  SET_GENERAL_POLICY_SETTINGS_LOADING = '[general-policy-settings] set-general-policy-loading',
  SAVE_GENERAL_POLICY_SETTINGS = '[general-policy-settings] save-general-policy-settings',
  SET_GENERAL_POLICY_SETTINGS_NEEDS_REFRESH = '[general-policy-settings] set-general-policy-needs-refresh',
  SET_GENERAL_POLICY_SETTINGS_FORM_SUBMIT_SUCCESS = '[general-policy-settings] set-general-policy-settings-form-submit-success',
  SET_GENERAL_POLICY_SETTINGS_OPEN = '[general-policy-settings] set-general-policy-settings-open'
}

export const generalPolicySettingsActions = {
  reset: () => createAction(GeneralPolicySettingsActionTypes.RESET),
  getGeneralPolicySettings: (
    params: DataFetchParams<{}, GeneralPolicySettingsUrlParams>
  ) =>
    createAction(GeneralPolicySettingsActionTypes.GET_GENERAL_POLICY_SETTINGS, {
      params
    }),
  setGeneralPolicySettings: (policySettings: GeneralPolicySettings) =>
    createAction(GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS, {
      policySettings
    }),
  setGeneralPolicySettingsLoading: (loading: boolean) =>
    createAction(
      GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_LOADING,
      loading
    ),
  saveGeneralPolicySettings: (policyFormData: GeneralPolicySettingsForm) =>
    createAction(
      GeneralPolicySettingsActionTypes.SAVE_GENERAL_POLICY_SETTINGS,
      {
        policyFormData
      }
    ),
  setGeneralPolicySettingsNeedsRefresh: (
    settingsNeedsRefresh: boolean,
    policyKey: string,
    configKey: number
  ) =>
    createAction(
      GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_NEEDS_REFRESH,
      { settingsNeedsRefresh, policyKey, configKey }
    ),
  setGeneralPolicySettingsFormSubmitSuccess: (isSuccess: boolean) =>
    createAction(
      GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_FORM_SUBMIT_SUCCESS,
      { isSuccess }
    ),
  setGeneralPolicySettingsOpen: (isSettingsOpen: boolean) =>
    createAction(
      GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_OPEN,
      { isSettingsOpen }
    )
}

export type GeneralPolicySettingsActions = ActionsUnion<
  typeof generalPolicySettingsActions
>

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { Group } from '@lastpass/admin-console-dependencies/types/group'

export const SUCCESS = 'success'

const success = (groups: Group[], totalResults: number) =>
  createResponse(SUCCESS, { groups, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userId: string, queryPath: string) {
    const [response, status] = await fetch(
      'users/' + userId + '/groups' + queryPath,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const groups = JSON.parse(response).content
      const totalResults = JSON.parse(response).content.length
      return success(groups, totalResults)
    }
    throw new UnknownServiceError()
  }
}

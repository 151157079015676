import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

interface UpsellDialogCellProps {
  icon: React.ComponentType
  title: React.ReactNode
  subtitle: React.ReactNode
}

const StyledDescriptionBold = styled.span`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1d3049;
`

const StyledDescriptionNormal = styled.span`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1d3049;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 15px;
`

const Cell = styled.div`
  display: flex;
  width: 345px;
  padding: 15px;
`

export const UpsellDialogCell: FunctionComponent<UpsellDialogCellProps> = ({
  ...props
}) => {
  const Icon = props.icon

  return (
    <Cell>
      <IconContainer>
        <Icon />
      </IconContainer>
      <DescriptionContainer>
        <StyledDescriptionBold>{props.title}</StyledDescriptionBold>
        <StyledDescriptionNormal>{props.subtitle}</StyledDescriptionNormal>
      </DescriptionContainer>
    </Cell>
  )
}

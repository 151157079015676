import shajs from 'sha.js'

export function hash(value: string): string {
  return shajs('sha256')
    .update(value)
    .digest('hex')
}

// the sha.js' hash above doesn't work correctly with binary data
export async function sha256(value: Buffer): Promise<ArrayBuffer> {
  return await crypto.subtle.digest({ name: 'SHA-256' }, value)
}

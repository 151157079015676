import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import {
  DetailsDialog,
  DetailsDialogProps,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmationWordInput } from '@lastpass/admin-console-dependencies/ui/global/ConfirmationWordInput'

interface ConfirmationWordDialogProps {
  confirmationWord: string
  title: DetailsDialogProps['title']
  content: DetailsDialogProps['content']
  confirmButtonText: MessageDescriptor
  onConfirmPayload?: unknown
  headerExtension?: DetailsDialogProps['headerExtension']
}

const ConfirmationWordInputContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  background-color: ${props => props.theme.colors.blue100};
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
`

export const ConfirmationWordDialog: FC<ConfirmationWordDialogProps> = ({
  confirmationWord,
  title,
  content,
  confirmButtonText,
  onConfirmPayload,
  headerExtension
}) => {
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<
    boolean
  >(true)

  const dispatch = useDispatch()
  const { _ } = useLingui()

  const ContentWrapper = (
    <>
      {content}
      <ConfirmationWordInputContainer>
        <ConfirmationWordInput
          confirmationWord={confirmationWord}
          onChange={isConfirmationWordCorrect => {
            setIsConfirmButtonDisabled(!isConfirmationWordCorrect)
          }}
        />
      </ConfirmationWordInputContainer>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="526px"
      title={title}
      content={ContentWrapper}
      disableMaxHeight
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(globalActions.discardDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            dispatch(globalActions.confirmDialog(onConfirmPayload || undefined))
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          disabled={isConfirmButtonDisabled}
          red
        >
          {_(confirmButtonText)}
        </PrimaryButton>
      ]}
      headerExtension={headerExtension}
    />
  )
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { ReactComponent as DevicesIcon } from '@lastpass/assets/svg/admin-console/icon-devices.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerTable,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit/components'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { WorkstationTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['trustedDevices']['workstation'],
  typeof userDrawerActions
>

export const TrustedWorkstationsTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: trustedDevices,
  actions
}) => {
  const queryParams = useQueryParams()
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserTrustedDevices({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Registered devices</Trans>)
  if (!trustedDevices) {
    return <Loading color={'blue900'} active={true} />
  } else {
    return (
      <DrawerTable
        qadata={'UserLevelDevicesWorkstationLoginList'}
        table={trustedDevices}
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Device</Trans>,
            renderer: (record: WorkstationTrustedDevice) => record.name
          },
          {
            name: <Trans>Registration</Trans>,
            noTooltip: true,
            renderer: function LastLoginRenderer(
              record: WorkstationTrustedDevice
            ) {
              return (
                <TimeSinceActivity
                  lastActivityTime={record.registrationTimeUtc}
                />
              )
            }
          },
          {
            name: <Trans>Last login</Trans>,
            noTooltip: true,
            renderer: function LastLoginRenderer(
              record: WorkstationTrustedDevice
            ) {
              return (
                <TimeSinceActivity lastActivityTime={record.lastLoginTimeUtc} />
              )
            }
          },
          {
            name: <Trans>Status</Trans>,
            renderer: function ActiveStatusRenderer() {
              return <Trans>Active</Trans>
            }
          }
        ]}
        checkable={{
          setSelectedRecords: actions.setSelectedWorkstations,
          removeSelectedRecords: actions.removeSelectedWorkstations,
          addSelectedRecords: actions.addSelectedWorkstations
        }}
        noRecordsView={
          <TableView
            icon={<DevicesIcon />}
            title={
              <Trans>
                This user hasn{"'"}t yet activated Workstation Login
              </Trans>
            }
          />
        }
        actions={[
          {
            name: <Trans>Delete workstations</Trans>,
            callback: (records: WorkstationTrustedDevice[]) => {
              pathParams.id &&
                actions.deleteWorkstations(records, pathParams.id)
            },
            isValid: () => {
              return true
            },
            color: 'red700'
          }
        ]}
      />
    )
  }
}

export function createTrustedWorkstationsTableComponent<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    TrustedWorkstationsTableComponent,
    (state: TState) => state.usersDrawer.trustedDevices.workstation,
    userDrawerActions
  )
}

export type UserDrawerTrustedDevicesTable = ReturnType<
  typeof createTrustedWorkstationsTableComponent
>

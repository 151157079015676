import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { LoginReportActionTypes } from '@lastpass/admin-console-dependencies/state/reports/login/actions'
import { SecurityReportActionTypes } from '@lastpass/admin-console-dependencies/state/reports/security/actions'
import { UserActivityActionTypes } from '@lastpass/admin-console-dependencies/state/reports/view/actions'
import { AddUsersDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'
import { UserDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UserListActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { UserOperationActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { DistributeRecoveryKeysActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import { TransferVaultDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/transfer-vault/actions'

// reports
import { createExportWeakSecurityScore } from '../reports/export-weak-security-score'
import { createGetLoginReportEvents } from '../reports/get-login-report-events'
import { createGetSecurityReportEvents } from '../reports/get-security-report-events'
// add
import { createAddUsersSaga } from './add/add-users'
import { createProcessPendingAddUsersUploadSaga } from './add/add-users-process-upload'
import { createSendUserVerificationEmailSaga } from './add/send-user-verification-email'
import { createExportUsersSaga } from './export-users'
import { createGetCurrentAdminLevelSaga } from './get-current-admin-level'
import { createGetUserTableSettings } from './get-user-table-settings'
// operations
import { createAcceptUserSaga } from './operations/user-accept'
import { createActivateUserSaga } from './operations/user-activate'
import { createDeleteUserSaga } from './operations/user-delete'
import { createDeselectForFederationSaga } from './operations/user-deselect-for-federation'
import { createDestroyAllSessionsForSelectedUsersSaga } from './operations/user-destroy-all-sessions'
import { createDisableUserSaga } from './operations/user-disable'
import { createUserDisableMultifactorSaga } from './operations/user-disable-multifactor'
import { createGetRecoveryKeySharingDetails } from './operations/user-distribute-recovery-key/get-recovery-sharing-key-details'
import { createDistributeRecoveryKeySaga } from './operations/user-distribute-recovery-key/user-distribute-recovery-key'
import {
  createInviteUsersSaga,
  createReInviteUsersSaga
} from './operations/user-invitation'
import { createLockDeviceForLPMFASaga } from './operations/user-lock-device-for-lpmfa'
import { createRejectUserSaga } from './operations/user-reject'
import { createRemoveUserFromCompanySaga } from './operations/user-remove-from-company'
import { createRequirePasswordChangeSaga } from './operations/user-require-password-change'
import { createSelectForFederationSaga } from './operations/user-select-for-federation'
import { createSelectForVaultReEncryption } from './operations/user-select-for-vault-re-encryption'
import { createResendLPMFAInvitationSaga } from './operations/user-send-lp-mfa-reinvite'
import { createUnlockDeviceForLPMFASaga } from './operations/user-unlock-device-for-lpmfa'
import { createSaveUserProfileSaga } from './save-user'
import { createSaveUserTableSettings } from './save-user-table-settings'
import { createTransferVaultDrawerSaga } from './transfer-vault/drawer'
import { createTransferVaultOpenWindowSaga } from './transfer-vault/open-window'
// trusted devices
import { createDeleteLpDeviceSaga } from './trusted-devices/lp-device-delete'
import { createSavePasswordManagerSaga } from './trusted-devices/lp-device-edit'
import { createEnablePasswordManagersSaga } from './trusted-devices/lp-device-enable'
import { createMfaDeviceLockSaga } from './trusted-devices/mfa-device-lock'
import { createMfaDeviceUnlockSaga } from './trusted-devices/mfa-device-unlock'
import { createDeleteWorkstationSaga } from './trusted-devices/workstation-delete'
// root
import { createUserLevelsSaga } from './user-levels'
import { createUserSitesSaga } from './user-sites'
import { createAccountDetailsSaga } from './users-account-details'
import { createUserAppsSaga } from './users-apps'
import { createUserProfileSaga } from './users-drawer'
import { createUserGroupsSaga } from './users-groups'
import { createUserListSaga } from './users-list'
import { createUserOperationsSaga } from './users-operations'
import { createUserPoliciesSaga } from './users-policies'
import { createUsersSelectionSaga } from './users-selection'
import { createUserSharedFoldersSaga } from './users-shared-folders'
import { createUsersTrustedDevicesSaga } from './users-trusted-devices'

export function createUsersSaga(userService: UACServices.Services) {
  const getUserListSaga = createUserListSaga(userService)
  const usersSelectionSaga = createUsersSelectionSaga()
  const getUserDrawerSaga = createUserProfileSaga(userService)
  const getAccountDetailsSaga = createAccountDetailsSaga(userService)
  const getUserAppsSaga = createUserAppsSaga(userService)
  const getUserGroupsSaga = createUserGroupsSaga(userService)
  const getUserSitesSaga = createUserSitesSaga(userService)
  const getUserPoliciesSaga = createUserPoliciesSaga(userService)
  const getUserSharedFoldersSaga = createUserSharedFoldersSaga(userService)
  const getUserLevelsSaga = createUserLevelsSaga(userService)
  const getCurrentAdminLevelSaga = createGetCurrentAdminLevelSaga(userService)
  const saveUserProfileSaga = createSaveUserProfileSaga(userService)
  const getUsersTrustedDevicesSaga = createUsersTrustedDevicesSaga(userService)
  const savePasswordManagerSaga = createSavePasswordManagerSaga(userService)
  const getUserOperationsSaga = createUserOperationsSaga(userService)
  const addUsersSaga = createAddUsersSaga(userService)
  const processPendingAddUsersUploadSaga = createProcessPendingAddUsersUploadSaga()
  const exportWeakSecurityScore = createExportWeakSecurityScore()
  const getSecurityReportEvents = createGetSecurityReportEvents(userService)
  const getLoginReportEvents = createGetLoginReportEvents(userService)
  const deleteUserSaga = createDeleteUserSaga(userService)
  const requirePasswordChangeSaga = createRequirePasswordChangeSaga(userService)
  const inviteUsersSaga = createInviteUsersSaga(userService)
  const reInviteUsersSaga = createReInviteUsersSaga(userService)
  const resendLPMFAInvitationSaga = createResendLPMFAInvitationSaga(userService)
  const lockDeviceForLPMFASaga = createLockDeviceForLPMFASaga(userService)
  const unLockDeviceForLPMFASaga = createUnlockDeviceForLPMFASaga(userService)
  const disableUserSaga = createDisableUserSaga(userService)
  const activateUserSaga = createActivateUserSaga(userService)
  const removeUserFromCompanySaga = createRemoveUserFromCompanySaga(userService)
  const exportUsersSaga = createExportUsersSaga(userService)
  const disableMultifactorSaga = createUserDisableMultifactorSaga(userService)
  const acceptUserSaga = createAcceptUserSaga(userService)
  const rejectUserSaga = createRejectUserSaga(userService)
  const destroyAllSessionsForSelectedUsersSaga = createDestroyAllSessionsForSelectedUsersSaga(
    userService
  )
  const unlockMfaDeviceSaga = createMfaDeviceUnlockSaga(userService)
  const lockMfaDeviceSaga = createMfaDeviceLockSaga(userService)
  const deleteWorkstationSaga = createDeleteWorkstationSaga(userService)
  const deleteLpDeviceSaga = createDeleteLpDeviceSaga(userService)
  const enablePasswordManagerSaga = createEnablePasswordManagersSaga(
    userService
  )
  const saveUserTableSettingsSaga = createSaveUserTableSettings()
  const getUserTableSettings = createGetUserTableSettings()
  const selectForFederation = createSelectForFederationSaga(userService)
  const deselectForFederation = createDeselectForFederationSaga(userService)
  const selectForVaultReEncryption = createSelectForVaultReEncryption(
    userService
  )
  const transferVaultDrawerSaga = createTransferVaultDrawerSaga(userService)
  const transferVaultOpenWindowSaga = createTransferVaultOpenWindowSaga()
  const sendUserVerificationEmail = createSendUserVerificationEmailSaga(
    userService
  )
  const distributeRecoveryKeySaga = createDistributeRecoveryKeySaga(userService)
  const getRecoveryKeySharingDetails = createGetRecoveryKeySharingDetails(
    userService
  )

  return function*() {
    yield takeLatest(UserListActionTypes.GET_USER_LIST, getUserListSaga)
    yield takeEvery(
      [
        UserListActionTypes.ADD_SELECTED_USERS,
        UserListActionTypes.REMOVE_SELECTED_USERS,
        UserListActionTypes.SET_SELECTED_USERS
      ],
      usersSelectionSaga
    )
    yield takeEvery(
      UserListActionTypes.GET_CURRENT_ADMIN_LEVEL,
      getCurrentAdminLevelSaga
    )
    yield takeEvery(
      reduxAction =>
        reduxAction.type === UserListActionTypes.SET_USER_LIST_COLUMNS &&
        reduxAction.payload.saveSettings === true,
      saveUserTableSettingsSaga
    )
    yield takeEvery(
      UserListActionTypes.GET_USER_LIST_COLUMNS,
      getUserTableSettings
    )
    yield takeEvery(UserDrawerActionTypes.GET_USER_PROFILE, getUserDrawerSaga)
    yield takeEvery(
      UserDrawerActionTypes.GET_ACCOUNT_DETAILS,
      getAccountDetailsSaga
    )
    yield takeEvery(UserDrawerActionTypes.GET_USER_APPS, getUserAppsSaga)
    yield takeEvery(UserDrawerActionTypes.GET_USER_GROUPS, getUserGroupsSaga)
    yield takeEvery(UserDrawerActionTypes.GET_USER_SITES, getUserSitesSaga)
    yield takeEvery(UserDrawerActionTypes.GET_USER_LEVELS, getUserLevelsSaga)
    yield takeEvery(
      UserDrawerActionTypes.SAVE_USER_PROFILE,
      saveUserProfileSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.GET_USER_POLICIES,
      getUserPoliciesSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.GET_USER_SHARED_FOLDERS,
      getUserSharedFoldersSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.GET_USER_TRUSTED_DEVICES,
      getUsersTrustedDevicesSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.SAVE_PASSWORD_MANAGER,
      savePasswordManagerSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.GET_USER_OPERATIONS,
      getUserOperationsSaga
    )
    yield takeEvery(AddUsersDrawerActionTypes.ADD_NEW_USERS, addUsersSaga)
    yield takeEvery(
      AddUsersDrawerActionTypes.PROCESS_PENDING_USERS_UPLOAD,
      processPendingAddUsersUploadSaga
    )
    yield takeEvery(
      UserActivityActionTypes.REPORT_WEAK_SECURITY_SCORE_EXPORT,
      exportWeakSecurityScore
    )
    yield takeLatest(
      AddUsersDrawerActionTypes.SEND_USER_VERIFICATION_EMAIL,
      sendUserVerificationEmail
    )
    yield takeEvery(
      SecurityReportActionTypes.REPORT_GET_SECURITY_REPORT,
      getSecurityReportEvents
    )
    yield takeEvery(
      LoginReportActionTypes.REPORT_GET_LOGIN_REPORT,
      getLoginReportEvents
    )
    yield takeLatest(UserOperationActionTypes.DELETE_USERS, deleteUserSaga)
    yield takeLatest(
      UserOperationActionTypes.REQUIRE_PASSWORD_CHANGE,
      requirePasswordChangeSaga
    )
    yield takeLatest(
      UserOperationActionTypes.DISABLE_MULTIFACTOR,
      disableMultifactorSaga
    )
    yield takeLatest(UserOperationActionTypes.INVITE_USERS, inviteUsersSaga)
    yield takeLatest(UserOperationActionTypes.REINVITE_USERS, reInviteUsersSaga)
    yield takeLatest(
      UserOperationActionTypes.RESEND_LP_MFA_INVITE,
      resendLPMFAInvitationSaga
    )
    yield takeLatest(
      UserOperationActionTypes.LOCK_DEVICE_FOR_LPMFA,
      lockDeviceForLPMFASaga
    )
    yield takeLatest(
      UserOperationActionTypes.UNLOCK_DEVICE_FOR_LPMFA,
      unLockDeviceForLPMFASaga
    )
    yield takeLatest(UserOperationActionTypes.DISABLE_USERS, disableUserSaga)
    yield takeLatest(UserOperationActionTypes.ACTIVATE_USERS, activateUserSaga)
    yield takeLatest(
      UserOperationActionTypes.REMOVE_USERS_FROM_COMPANY,
      removeUserFromCompanySaga
    )
    yield takeEvery(UserListActionTypes.EXPORT_USERS, exportUsersSaga)
    yield takeLatest(UserOperationActionTypes.ACCEPT_USERS, acceptUserSaga)
    yield takeLatest(UserOperationActionTypes.REJECT_USERS, rejectUserSaga)

    yield takeLatest(
      UserOperationActionTypes.DESTROY_ALL_SESSIONS,
      destroyAllSessionsForSelectedUsersSaga
    )

    yield takeLatest(
      UserOperationActionTypes.DESTROY_ALL_SESSIONS_FOR_ITERATION_COUNT_POLICY,
      destroyAllSessionsForSelectedUsersSaga
    )

    yield takeEvery(
      UserDrawerActionTypes.UNLOCK_SELECTED_MFA_DEVICES,
      unlockMfaDeviceSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.LOCK_SELECTED_MFA_DEVICES,
      lockMfaDeviceSaga
    )
    yield takeEvery(
      UserDrawerActionTypes.DELETE_SELECTED_WORKSTATIONS,
      deleteWorkstationSaga
    )
    yield takeEvery(UserDrawerActionTypes.DELETE_LP_DEVICES, deleteLpDeviceSaga)
    yield takeEvery(
      UserDrawerActionTypes.ENABLE_PASSWORD_MANAGER,
      enablePasswordManagerSaga
    )
    yield takeLatest(
      UserOperationActionTypes.SELECT_FOR_FEDERATION,
      selectForFederation
    )
    yield takeLatest(
      TransferVaultDrawerActionTypes.GET_TRANSFER_VAULT_DRAWER_DATA,
      transferVaultDrawerSaga
    )
    yield takeLatest(
      TransferVaultDrawerActionTypes.OPEN_WINDOW,
      transferVaultOpenWindowSaga
    )
    yield takeLatest(
      UserOperationActionTypes.DESELECT_FOR_FEDERATION,
      deselectForFederation
    )
    yield takeLatest(
      UserOperationActionTypes.SELECT_FOR_VAULT_RE_ENCRYPTION,
      selectForVaultReEncryption
    )
    yield takeLatest(
      DistributeRecoveryKeysActionTypes.DISTRIBUTE_RECOVERY_KEYS,
      distributeRecoveryKeySaga
    )
    yield takeLatest(
      DistributeRecoveryKeysActionTypes.GET_RECOVERY_SHARING_KEY_DETAILS,
      getRecoveryKeySharingDetails
    )
  }
}

import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'

export function createGetGroupSaga(userService: UACServices.Services) {
  return function* getGroupSaga(
    action: ReturnType<typeof groupDrawerActions.getGroupUsers>
  ) {
    try {
      const id = action.payload.params.path.id
      if (id) {
        yield put(groupDrawerActions.setGroupLoading(true))
        try {
          const result: UACServices.GroupAPI.Responses = yield call(
            userService.group,
            id
          )
          if (result.type === UACServices.GroupAPI.SUCCESS) {
            const currentGroupResponse = result.body
            yield put(
              groupDrawerActions.setCurrentGroup({
                id: currentGroupResponse.id,
                name: currentGroupResponse.name,
                securityScore: currentGroupResponse.securityScore,
                userCount: currentGroupResponse.userCount
              })
            )
            yield put(
              groupDrawerActions.setCurrentGroupHasFederatedUsers(
                currentGroupResponse.hasFederatedUsers
              )
            )
          } else if (result.type === UACServices.GroupAPI.NOT_FOUND) {
            yield put(push('/users/groups'))
          }
        } finally {
          yield put(groupDrawerActions.setGroupLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

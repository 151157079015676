import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { CardContainer } from '@lastpass/lastkit/components/CardContainer'
import { Heading100 } from '@lastpass/lastkit/components/Heading'
import { Table } from '@lastpass/lastkit/components/Table'

import { SecurityScoreDetails as SecurityScoreDetailsModel } from '@lastpass/admin-console-dependencies/types/security-score-details'
import { Table as TableType } from '@lastpass/admin-console-dependencies/types/table'

interface SecurityScoreDetailsProps {
  data: SecurityScoreDetailsModel
}

const Container = styled(CardContainer)`
  padding-top: 16px;
  margin-top: 16px;
`

interface TableItem {
  label: ReactElement
  value: number | ReactElement
}

export const SecurityScoreDetails: FunctionComponent<SecurityScoreDetailsProps> = ({
  data
}: SecurityScoreDetailsProps) => {
  const items: TableItem[] = [
    {
      label: <Trans>Master password score</Trans>,
      value: (
        <span data-qa="SecurityScoreDetailsMasterPasswordScore">
          {data.masterPasswordScore !== null ? (
            data.masterPasswordScore
          ) : (
            <Trans>N/A</Trans>
          )}
        </span>
      )
    },
    {
      label: <Trans>Security challenge score</Trans>,
      value: data.securityChallengeScore
    },
    {
      label: <Trans>Average password strength</Trans>,
      value: data.averagePasswordStrength
    },
    {
      label: <Trans>Number of blank passwords</Trans>,
      value: data.blankPasswordsCount
    },
    {
      label: <Trans>Number of duplicate passwords</Trans>,
      value: data.duplicatePasswordsCount
    },
    {
      label: <Trans>Number of sites with duplicate passwords</Trans>,
      value: data.sitesWithDuplicatePasswords
    },
    {
      label: <Trans>Number of weak passwords</Trans>,
      value: data.weakPasswordsCount
    }
  ]

  const tableData: TableType<TableItem> = {
    results: items,
    resultsPerPage: items.length,
    totalResults: items.length,
    checkedRecords: []
  }

  return (
    <Container data-qa={'SecurityScoreDetailsContainer'}>
      <Heading100 style={{ margin: '0 16px' }}>
        <Trans>Security score details</Trans>
      </Heading100>
      <Table
        table={tableData}
        columns={[
          {
            name: <Trans>Category</Trans>,
            renderer: function categoryRenderer({ label }: TableItem) {
              return label
            }
          },
          {
            name: <Trans>Score</Trans>,
            renderer: function valueRenderer({ value }: TableItem) {
              return <>{value}</>
            }
          }
        ]}
      />
    </Container>
  )
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { Theme } from '@lastpass/lastkit'
import { Badge } from '@lastpass/lastkit/components'

import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { FederatedStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/FederatedStatusMapping'

export interface FederatedStatusBadgeProps {
  status: User['provisionFederatedStatus']
}

export const FederatedStatusBadge = ({ status }: FederatedStatusBadgeProps) => {
  const label = (status && FederatedStatusMapping[status]) || (
    <Trans>Unknown</Trans>
  )
  let background: keyof Theme['colors'] = 'teal'

  switch (status) {
    case FederatedStatus.notEligibleForFederation:
      background = 'purple'
      break
    case FederatedStatus.notSelected:
      background = 'red700'
      break
    case FederatedStatus.selected:
      background = 'yellow'
      break
    case FederatedStatus.federated:
      background = 'teal'
      break
    default:
      break
  }
  return (
    <Badge
      colors={{
        backgroundColor: background,
        textColor: 'white'
      }}
      data-qa="FederatedStatusBadge"
    >
      {label}
    </Badge>
  )
}

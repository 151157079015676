import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as FolderIcon } from '@lastpass/assets/svg/admin-console/icon-folder.svg'
import { ReactComponent as FolderIconTable } from '@lastpass/assets/svg/admin-console/icon-folder-table.svg'
import {
  DrawerTable,
  Pagination,
  RowContainer,
  SearchInput,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { SharedFolder } from '@lastpass/admin-console-dependencies/types/shared-folder'

import { UserPermissions } from './UserSharedFoldersPermissions'

const StyledFolderTableIcon = styled(FolderIconTable)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['sharedFolders'],
  typeof userDrawerActions
>

export const UserDrawerSharedFoldersTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: sharedFolders
}) => {
  const queryParams = useQueryParams(Namespace.sharedfoldersdrawer)
  const updateQuery = useUpdateQuery(Namespace.sharedfoldersdrawer)
  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserSharedFolders({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Shared folders</Trans>)

  if (sharedFolders) {
    return (
      <>
        <RowContainer>
          <SearchInput
            value={queryParams.search}
            placeholder={msg`Search shared folders`}
            onChange={e =>
              updateQuery({
                search: e.target.value || undefined,
                skip: undefined
              })
            }
            data-qa={'SearchFolderInputField'}
          />
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={sharedFolders.totalResults}
            resultsPerPage={sharedFolders.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </RowContainer>
        <DrawerTable
          qadata={'UserLevelFullSharedFoldersList'}
          table={sharedFolders}
          noRecordsView={
            <TableView
              icon={<FolderIcon />}
              title={<Trans>This user doesn’t have any shared folders</Trans>}
              text={
                <Trans>
                  Shared folders are for sharing passwords and other info
                  between LastPass users.
                </Trans>
              }
            />
          }
          noResultsView={
            <TableView
              icon={<FolderIcon />}
              title={<Trans>Sorry, no results match your search</Trans>}
              text={
                <Trans>Try different keywords or check your spelling</Trans>
              }
            />
          }
          isFilterApplied={!!queryParams.search}
          columns={[
            {
              name: <></>,
              component: StyledFolderTableIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Folder Name</Trans>,
              renderer: (record: SharedFolder) => record.name
            },
            {
              name: <Trans>Permissions</Trans>,
              renderer: function PermissionRenderer(record: SharedFolder) {
                return <UserPermissions record={record} />
              }
            }
          ]}
        />
      </>
    )
  }
  return null
}

export function createUserDrawerSharedFoldersTable<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserDrawerSharedFoldersTableComponent,
    (state: TState) => state.usersDrawer.sharedFolders,
    userDrawerActions
  )
}

export type UserDrawerSharedFoldersTable = ReturnType<
  typeof createUserDrawerSharedFoldersTable
>

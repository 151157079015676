import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createGetDirectorySettings(userServices: UACServices.Services) {
  return function* getDirectorySettings(
    action: ReturnType<typeof directoriesActions.getDirectorySettings>
  ) {
    try {
      yield put(globalActions.setIsLoading(true))

      const [directorySettingsResult, googleProvisioningResult] = yield all([
        call(userServices.directorySettings),
        call(userServices.googleProvisioningInfoGet)
      ])

      if (directorySettingsResult) {
        yield put(
          directoriesActions.setDirectorySettings(
            directorySettingsResult.body.settings
          )
        )
      }

      if (googleProvisioningResult) {
        const {
          hasProvisionToken,
          enabled,
          adminEmail,
          integrationExists,
          partialSyncEnabled,
          groupIdsToSync,
          userIdsToSync
        } = googleProvisioningResult.body.googleInfo
        yield put(
          directoriesActions.setGoogleProvisioningInfo({
            hasProvisionToken,
            enabled,
            adminEmail,
            integrationExists,
            partialSyncEnabled,
            groupIdsToSync: groupIdsToSync || [],
            userIdsToSync: userIdsToSync || []
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(globalActions.setIsLoading(false))

      const { callback } = action.payload
      if (callback) {
        yield call(callback)
      }
    }
  }
}

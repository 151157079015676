import React from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as QRadarIcon } from '@lastpass/assets/svg/admin-console/siem-integrations/icon-qRadar.svg'
import { Heading100, LogoCard, WithTooltip } from '@lastpass/lastkit'

import { QRadarIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledCardContainer,
  StyledDescriptionRow,
  StyledInactiveExternalLink
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { QRadarForm } from './QRadarForm'

interface QRadarIntegrationCardProps {
  config: QRadarIntegrationConfig
}

export const QRadarIntegrationCard: React.FunctionComponent<QRadarIntegrationCardProps> = ({
  config
}) => (
  <LogoCard
    dataQaName="SiemQRadarCard"
    logo={<QRadarIcon />}
    title={
      <Heading100>
        <Trans>IBM QRadar</Trans>
      </Heading100>
    }
    subtitle={
      <Trans>
        Configure LastPass to send audit events to an IBM QRadar instance in
        near real-time.
      </Trans>
    }
  >
    <StyledCardContainer>
      <StyledDescriptionRow>
        <Trans>LastPass related events will be visible in IBM QRadar.</Trans>
        <WithTooltip
          tooltip={<Trans>Documentation still to come</Trans>}
          placement="right"
        >
          <StyledInactiveExternalLink
            data-qa="SiemQRadarInstructionsLink"
            href="#" // Waiting for documents to be created
            target="_blank"
            onClick={e => e.preventDefault()}
          >
            <Trans>View setup instructions</Trans>
          </StyledInactiveExternalLink>
        </WithTooltip>
      </StyledDescriptionRow>
      <QRadarForm config={config} />
    </StyledCardContainer>
  </LogoCard>
)

import localForage from 'localforage'

import { CidOverrideParams } from '@lastpass/admin-console-dependencies/types/cid-override-params'
import { RecentCompaniesRepositoryType } from '@lastpass/admin-console-dependencies/types/recent-managed-companies'

import { createRepository } from './local-forage-repository-factory'

const key = 'recent-managed-companies'
const maxLength = 5

export const RecentManagedCompaniesRepository = {
  ...createRepository<RecentCompaniesRepositoryType>(key),
  set: async (
    companyData: CidOverrideParams,
    permission: boolean
  ): Promise<RecentCompaniesRepositoryType> => {
    const {
      recentManagedCompanies: managedCompanies = [],
      isAllowedToCreateManagedCompanies: currentPermission
    } = (await RecentManagedCompaniesRepository.get()) || {}

    const recentManagedCompanies = [
      companyData,
      ...managedCompanies
        .filter(({ companyId }) => companyData.companyId !== companyId)
        .slice(0, maxLength - 1)
    ]

    const newState: RecentCompaniesRepositoryType = {
      recentManagedCompanies,
      isAllowedToCreateManagedCompanies: currentPermission ?? permission
    }
    return localForage.setItem<RecentCompaniesRepositoryType>(key, newState)
  },

  removeOneItem: async (id: string): Promise<RecentCompaniesRepositoryType> => {
    const currentState = await localForage.getItem<
      RecentCompaniesRepositoryType
    >(key)

    const recentManagedCompanies: CidOverrideParams[] = currentState?.recentManagedCompanies.filter(
      ({ companyId }) => id !== companyId
    )
    const newState: RecentCompaniesRepositoryType = {
      recentManagedCompanies,
      isAllowedToCreateManagedCompanies:
        currentState.isAllowedToCreateManagedCompanies
    }
    return localForage.setItem<RecentCompaniesRepositoryType>(key, newState)
  }
}

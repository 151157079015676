import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { DetailsDialog } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { createSecurityScoreHistoryChartComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/SecurityScoreHistoryChart'

export const SecurityScoreHistoryDialog: FC = () => {
  const dispatch = useDispatch()
  const SecurityScoreHistoryChart = createSecurityScoreHistoryChartComponent<
    AppState
  >()
  const close = (): void => {
    dispatch(globalActions.discardDialog())
    dispatch(globalActions.emptyDialog())
  }

  return (
    <DetailsDialog
      closeDialog={close}
      title={msg`Security score`}
      content={<SecurityScoreHistoryChart />}
      width="780px"
      disableMaxHeight
    />
  )
}

import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  GeneralPolicyUsersAndGroupsQueryParams,
  GeneralPolicyUsersAndGroupsUrlParams
} from '../policy-users/actions'
import { GeneralPolicyUsersAndGroups } from '../policy-users/state'

export enum GeneralPolicyAssignUsersAndGroupsActionTypes {
  RESET = '[general-policy-assign-users-and-groups] reset',
  GET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS = '[general-policy-assign-users-and-groups] get-general-policy-assign-users-and-groups',
  SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS = '[general-policy-assign-users-and-groups] set-general-policy-assign-users-and-groups',
  SAVE_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS = '[general-policy-assign-users-and-groups] save-general-policy-assign-users-and-groups',
  SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_NEEDS_REFRESH = '[general-policy-assign-users-and-groups] set-general-policy-needs-refresh',

  SEARCH_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS = '[general-policy-assign-users-and-groups] search-general-policy-users-and-groups',
  SET_GENERAL_ASSIGN_POLICY_USERS_LOADING = '[general-policy-assign-users-and-groups] set-policy-users-loading',
  ADD_SELECTED_GENERAL_ASSIGN_POLICY_USERS = '[general-policy-assign-users-and-groups] assign-selected-users',
  REMOVE_SELECTED_GENERAL_ASSIGN_POLICY_USERS = '[general-policy-assign-users-and-groups] remove-selected-users',
  SET_SELECTED_GENERAL_ASSIGN_POLICY_USERS = '[general-policy-assign-users-and-groups] set-selected-users',

  SET_GENERAL_ASSIGN_POLICY_GROUPS_LOADING = '[general-policy-assign-users-and-groups] set-policy-groups-loading',
  ADD_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS = '[general-policy-assign-users-and-groups] assign-selected-groups',
  REMOVE_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS = '[general-policy-assign-users-and-groups] remove-selected-groups',
  SET_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS = '[general-policy-assign-users-and-groups] set-selected-groups',

  RESET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_TABLES = '[general-policy-assign-users-and-groups] reset-policy-assign-users-and-groups-table',

  SET_GENERAL_POLICY_CURRENTLY_APPLIES_TO = '[general-policy-assign-users-and-groups] set-policy-curently-applies-to',
  CHECK_GENERAL_POLICY_APPLIES_TO = '[general-policy-assign-users-and-groups] check-policy-applies-to',
  SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_OPEN = '[general-policy-assign-users-and-groups] set-general-policy-assign-users-and-groups-open',
  SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_RECEIVED_RESPONSE_FROM_SERVER = '[general-policy-assign-users-and-groups] set-general-policy-assign-users-and-groups-received-response-from-server',
  UPDATE_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_TO_BE_ADDED = '[general-policy-assign-users-and-groups] update-general-policy-assign-users-and-groups-to-be-added'
}

export const generalPolicyAssignUsersAndGroupsActions = {
  reset: () => createAction(GeneralPolicyAssignUsersAndGroupsActionTypes.RESET),
  getGeneralPolicyAssignUsersAndGroups: (
    params: DataFetchParams<
      GeneralPolicyUsersAndGroupsQueryParams,
      GeneralPolicyUsersAndGroupsUrlParams
    >
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.GET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      {
        params
      }
    ),
  setGeneralPolicyAssignUsersAndGroups: (
    policyAssignUsersAndGroups: GeneralPolicyUsersAndGroups
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      {
        policyAssignUsersAndGroups
      }
    ),
  saveGeneralPolicyAssignUsersAndGroups: (
    policyFormData: GeneralPolicyUsersAndGroups
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SAVE_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      {
        policyFormData
      }
    ),
  setGeneralPolicyAssignUsersAndGroupsNeedsRefresh: (
    assignUsersAndGroupsNeedsRefresh: boolean,
    policyKey: string,
    configKey: number
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_NEEDS_REFRESH,
      { assignUsersAndGroupsNeedsRefresh, policyKey, configKey }
    ),
  searchGeneralPolicyAssignUsersAndGroups: (
    searchKey: string,
    policyKey: string,
    policyConfigId: number
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SEARCH_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      {
        searchKey,
        policyKey,
        policyConfigId
      }
    ),
  setGeneralPolicyAssignUsersLoading: (loading: boolean) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_ASSIGN_POLICY_USERS_LOADING,
      loading
    ),
  addSelectedGeneralPolicyAssignUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_ASSIGN_POLICY_USERS,
      {
        userList
      }
    ),
  removeSelectedGeneralPolicyAssignUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_ASSIGN_POLICY_USERS,
      {
        userList
      }
    ),
  setSelectedGeneralPolicyAssignUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_ASSIGN_POLICY_USERS,
      {
        userList
      }
    ),
  setGeneralPolicyAssignGroupsLoading: (loading: boolean) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_ASSIGN_POLICY_GROUPS_LOADING,
      loading
    ),
  addSelectedGeneralPolicyAssignGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS,
      {
        groupList
      }
    ),
  removeSelectedGeneralPolicyAssignGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS,
      {
        groupList
      }
    ),
  setSelectedGeneralPolicyAssignGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS,
      {
        groupList
      }
    ),
  resetGeneralPolicyTables: () =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.RESET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_TABLES
    ),
  setGeneralPolicyCurrentlyAppliesTo: (
    policyCurrentlyAppliesTo: AppliesToOptions
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_CURRENTLY_APPLIES_TO,
      { policyCurrentlyAppliesTo }
    ),
  checkGeneralPolicyAppliesTo: (
    policyAppliesTo: AppliesToOptions,
    params: DataFetchParams<
      GeneralPolicyUsersAndGroupsQueryParams,
      GeneralPolicyUsersAndGroupsUrlParams
    >
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.CHECK_GENERAL_POLICY_APPLIES_TO,
      { policyAppliesTo, params }
    ),
  setGeneralPolicyAssignUsersAndGroupsOpen: (
    isAssignUsersAndGroupsOpen: boolean
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_OPEN,
      { isAssignUsersAndGroupsOpen }
    ),
  setGeneralPolicyAssignUsersAndGroupsHasReceivedServerResponse: (
    hasServerResponded: boolean
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_RECEIVED_RESPONSE_FROM_SERVER,
      { hasServerResponded }
    ),
  updateGeneralpolicyAssignUsersAndGroupsToBeAdded: (
    toBeAddedUsers: TeamsPolicyUserInfo[],
    toBeAddedGroups: TeamsPolicyGroup[]
  ) =>
    createAction(
      GeneralPolicyAssignUsersAndGroupsActionTypes.UPDATE_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_TO_BE_ADDED,
      {
        toBeAddedUsers,
        toBeAddedGroups
      }
    )
}

export type GeneralPolicyAssignUsersAndGroupsActions = ActionsUnion<
  typeof generalPolicyAssignUsersAndGroupsActions
>

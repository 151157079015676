import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetSecurityScoreDataSaga(
  dashboardServices: UACServices.Services
) {
  return function* getSecurityScoreDataSaga() {
    try {
      const result: UACServices.SecurityScoreDataAPI.Responses = yield call(
        dashboardServices.getSecurityScoreData
      )
      if (result.type === UACServices.SecurityScoreDataAPI.SUCCESS) {
        yield put(dashboardActions.setSecurityScoreData(result.body))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { useEffect } from 'react'

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void | undefined,
  capturing?: boolean
) => {
  const handleClickOutside = (e: MouseEvent) => {
    if (ref && ref.current && !ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, !!capturing)
    return () => {
      document.removeEventListener('click', handleClickOutside, !!capturing)
    }
  })
}

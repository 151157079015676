import { put, select } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPoliciesListState } from '@lastpass/admin-console-dependencies/state/policies/general/list/state'
import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicyAssignUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/state'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyGroup,
  TeamsPolicyMap,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPolicyInfo } from '../drawer/get-general-policy'
import { getGeneralPoliciesList } from '../policy-settings/save-general-policy-settings'
import { getPolicyAssignUsersAndGroupsState } from './get-general-policy-assign-users'

export const getCurrentUrlFilters = state => state.router

const getPolicyConfigUsers = (
  toBeAddedUsers: TeamsPolicyUserInfo[],
  existingUserList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] => {
  const usersList = [...existingUserList, ...toBeAddedUsers]
  return usersList
}
const getPolicyConfigGroups = (
  toBeAddedGroups: TeamsPolicyGroup[],
  existingGroupList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] => {
  const groupsList = [...existingGroupList, ...toBeAddedGroups]
  return groupsList
}
const preparePolicyData = (
  policy: TeamsPolicy,
  policyFormData: GeneralPolicyUsersAndGroups,
  toBeAddedUsers: TeamsPolicyUserInfo[],
  toBeAddedGroups: TeamsPolicyGroup[]
): TeamsPolicyModelData[] => {
  const policyData = policy.policyData.map((policyConfigInfo, index) => {
    if (index === policyFormData.policyConfigIndex) {
      policyConfigInfo = {
        ...policyConfigInfo,
        appliesTo: policyFormData.policyConfigFormData.appliesTo,
        inclusiveList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.inclusive && policyConfigInfo.inclusiveList
            ? getPolicyConfigUsers(
                toBeAddedUsers,
                policyConfigInfo.inclusiveList
              )
            : [],
        inclusiveGroupList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.inclusive && policyConfigInfo.inclusiveGroupList
            ? getPolicyConfigGroups(
                toBeAddedGroups,
                policyConfigInfo.inclusiveGroupList
              )
            : [],
        exclusiveList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.exclusive && policyConfigInfo.exclusiveList
            ? getPolicyConfigUsers(
                toBeAddedUsers,
                policyConfigInfo.exclusiveList
              )
            : [],
        exclusiveGroupList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.exclusive && policyConfigInfo.exclusiveGroupList
            ? getPolicyConfigGroups(
                toBeAddedGroups,
                policyConfigInfo.exclusiveGroupList
              )
            : []
      }
    }
    return policyConfigInfo
  })
  return policyData
}

export function createSaveGeneralPolicyAssignUsersAndGroupsSaga() {
  return function* saveGeneralPolicyAssignUsersAndGroupsSaga(
    action: ReturnType<
      typeof generalPolicyAssignUsersAndGroupsActions.saveGeneralPolicyAssignUsersAndGroups
    >
  ) {
    const policyFormData = action.payload.policyFormData
    const policyKey = policyFormData.policyKey
    if (policyKey) {
      try {
        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy = policyMap[policyKey]
        const policyAssignUsersAndGroupsFormState: GeneralPolicyAssignUsersAndGroupsFormState = yield select(
          getPolicyAssignUsersAndGroupsState
        )
        const toBeAddedUsers =
          policyAssignUsersAndGroupsFormState.toBeAddedUsers
        const toBeAddedGroups =
          policyAssignUsersAndGroupsFormState.toBeAddedGroups

        const policyData = preparePolicyData(
          policy,
          policyFormData,
          toBeAddedUsers,
          toBeAddedGroups
        )

        const generalPoliciesList: GeneralPoliciesListState = yield select(
          getGeneralPoliciesList
        )
        policyMap[policyKey].policyData = policyData
        policyMap[policyKey].isChanged = true
        yield put(
          generalPoliciesListActions.setGeneralPoliciesList(
            generalPoliciesList.table.results,
            policyMap,
            generalPoliciesList.table.totalResults,
            generalPoliciesList.table.resultsPerPage
          )
        )
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      } finally {
        yield put(
          generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroupsHasReceivedServerResponse(
            true
          )
        )
        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
            false,
            policyKey
          )
        )
      }
    }
  }
}

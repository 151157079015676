import React, { FunctionComponent, useContext } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'
import { Checkbox, WithTooltip } from '@lastpass/lastkit'

import { OpenIdSetupState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import {
  CheckBoxWithToolTip,
  StyledChildRow,
  StyledPrimaryButton,
  StyledTextInput
} from './StyledComponents'

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 9px;
`

interface OpenIdSetupFormProps {
  initialValues: {
    authority: string
    clientId: string
    isEnabled: boolean
    isEmailHintDisabled: boolean
  }
  handleSubmit: (openIdSetup: OpenIdSetupState) => void
  authority: string
  clientId: string
  isEnabled: boolean
  isOktaWithAdc: boolean
  provider: OpenIdProvider
  isEmailHintDisabled: boolean
}

export const PingOneSetupForm: FunctionComponent<OpenIdSetupFormProps> = props => {
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={values =>
        props.handleSubmit({
          openIdUrl: values.authority.trim(),
          clientId: values.clientId.trim(),
          isEnabled: values.isEnabled,
          useOktaAuthServerToStoreCompanyKey: false,
          useLastPassADConnectorToSyncUsers: false,
          provider: OpenIdProvider.PingOne,
          isEmailHintDisabled: values.isEmailHintDisabled,
          pkceEnabled: true,
          useAzureMdmFlow: false
        })
      }
    >
      {formikProps => {
        return (
          <>
            <StyledChildRow>
              <Field name="authority">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="AuthorityInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>OpenID Connect metadata document URL</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="clientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="ClientIdInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Application (Client) ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="isEnabled">
                {formData => {
                  return (
                    <Checkbox
                      data-qa="IsEnabledCheckbox"
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Enabled</Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              <Field name="isEmailHintDisabled">
                {formData => {
                  return (
                    <CheckBoxWithToolTip>
                      <Checkbox
                        data-qa="DontSendHintCheckbox"
                        name={formData.field.name}
                        checked={formData.field.value}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.checked
                          )
                        }}
                        disabled={!hasDirectoriesAndFederationModifyPermission}
                      >
                        <Trans>Don{"'"}t send username/email hint to IdP</Trans>
                      </Checkbox>
                      <WithTooltip
                        data-qa="DontSendHintTooltip"
                        tooltip={
                          <Trans>
                            This prevents the username/email field from
                            populating automatically upon user login
                          </Trans>
                        }
                        placement="bottom"
                      >
                        <StyledHelpIcon />
                      </WithTooltip>
                    </CheckBoxWithToolTip>
                  )
                }}
              </Field>
              <StyledPrimaryButton
                data-qa="PingOneSaveChangesButton"
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                disabled={!hasDirectoriesAndFederationModifyPermission}
              >
                <Trans>Save changes</Trans>
              </StyledPrimaryButton>
            </StyledChildRow>
          </>
        )
      }}
    </Formik>
  )
}

import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { SecurityReportListState } from '@lastpass/admin-console-dependencies/state/reports/security/state'
import {
  RiskType,
  SecurityReportEvent
} from '@lastpass/admin-console-dependencies/types/security-report'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { ImpactedUsersSecurityReportDrawer } from '@lastpass/admin-console-dependencies/ui/reports/security/ImpactedUsersSecurityReportDrawer'
import { OnTheFlySecurityReportDrawer } from '@lastpass/admin-console-dependencies/ui/reports/security/OnTheFlySecurityReportDrawer'
import { UrlsInSharedFoldersGeneratedSecurityReportDrawer } from '@lastpass/admin-console-dependencies/ui/reports/security/UrlsInSharedFoldersGeneratedSecurityReportDrawer'
import { UrlsInVaultsGeneratedSecurityReportDrawer } from '@lastpass/admin-console-dependencies/ui/reports/security/UrlsInVaultsGeneratedSecurityReportDrawer'

export interface SecurityReportDrawerFactoryProps {
  closeLink: string
}

export const SecurityReportDrawerFactory: React.FunctionComponent<SecurityReportDrawerFactoryProps> = ({
  closeLink
}) => {
  const {
    exportUsersWithLowIterationCount,
    exportSharedFoldersAccessedByLowIterationCountUsers,
    exportLinkedAccountIterationCount,
    exportMfaProviders
  } = useContext(ServerContext)

  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )

  const { id } = useParams<{ id: string }>()
  const { table: eventsTable } = useSelector<AppState, SecurityReportListState>(
    state => state.securityReportEvents
  )

  const event = eventsTable.results.find(
    (event: SecurityReportEvent) => event.id === id
  )

  if (!event) {
    return null
  }

  switch (event.id) {
    case RiskType.urlsInVaults:
      return (
        <UrlsInVaultsGeneratedSecurityReportDrawer
          closeLink={closeLink}
          event={event}
        />
      )
    case RiskType.urlsInSharedFolders:
      return (
        <UrlsInSharedFoldersGeneratedSecurityReportDrawer
          closeLink={closeLink}
          event={event}
        />
      )
    case RiskType.lowIterationCount:
      return (
        <OnTheFlySecurityReportDrawer
          closeLink={closeLink}
          event={event}
          fetchCSV={() =>
            exportUsersWithLowIterationCount(isAccountStatusRenamed)
          }
          fileName="user-iteration-counts.csv"
        />
      )
    case RiskType.sharedFoldersAccessedByLowIterationCountUsers:
      return (
        <OnTheFlySecurityReportDrawer
          closeLink={closeLink}
          event={event}
          fetchCSV={exportSharedFoldersAccessedByLowIterationCountUsers}
          fileName="shared-folders-accessed-by-low-iteration-count-users.csv"
        />
      )
    case RiskType.linkedAccountIterationCount:
      return (
        <OnTheFlySecurityReportDrawer
          closeLink={closeLink}
          event={event}
          fetchCSV={() =>
            exportLinkedAccountIterationCount(isAccountStatusRenamed)
          }
          fileName="linked-account-iteration-count.csv"
        />
      )
    case RiskType.mfa:
      return (
        <OnTheFlySecurityReportDrawer
          closeLink={closeLink}
          event={event}
          fetchCSV={exportMfaProviders}
          fileName="mfa-providers.csv"
        />
      )
    default:
      return (
        <ImpactedUsersSecurityReportDrawer
          closeLink={closeLink}
          event={event}
        />
      )
  }
}

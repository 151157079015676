export interface TrustedDevice {
  device: string
  status: boolean
  type: TrustedDeviceType
}

export interface MfaTrustedDevice {
  id: string
  type: string
  name: string
  lastLoginTimeUtc: string
  isEnabled: boolean
}

export interface PasswordManagerTrustedDevice {
  id: string
  uuid: string
  label: string
  type: string
  isEnabled: boolean
}

export interface WorkstationTrustedDevice {
  id: string
  isEnabled: boolean
  lastLoginTimeUtc: string
  name: string
  registrationTimeUtc: string
  type: string
}

export interface TrustedDevices {
  lastPassApplications: PasswordManagerTrustedDevice[]
  lastPassMfaApplications: MfaTrustedDevice[]
  workstations: WorkstationTrustedDevice[]
}

export enum TrustedDeviceType {
  lastPassApplications = 'lastPassApplications',
  lastPassMfaApplications = 'lastPassMfaApplications',
  workstations = 'workstations'
}

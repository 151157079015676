export interface Config {
  middlewareApiUrl: string
  authServerUrl: string
  serverClientId: string
  lastPassBaseAddress: string
  identityIframeUrl: string
  segmentUrl: string
  oldAdminConsoleUrl: string
  paywallEcommCheckoutUrl: string
  ldClientSideId: string
  pendoSigningKeyName: string
  isPendoDesigner: boolean
  googleApiKey: string
  alpApiUrl: string
  launchDarklyMockAddress: string
}

/**
 * In production we will initialize window.__uac_conf from config.js (config.js replaced by kubernetes for each env)
 * For development, we initialize automatically with configuration from .env files
 */

// @ts-ignore
const config = window.__uac_conf || process.env

const oldAdminConsolePath = '/company/?resetconsole=true#!/dashboard'

const defaultConfig: Config = {
  middlewareApiUrl: config.REACT_APP_MIDDLEWARE_API_URL as string,
  authServerUrl: config.REACT_APP_AUTH_SERVER_URL as string,
  serverClientId: config.REACT_APP_AUTH_SERVER_CLIENT_ID as string,
  lastPassBaseAddress: config.REACT_APP_LASTPASS_BASE_ADDRESS as string,
  identityIframeUrl: config.REACT_APP_IDENTITY_IFRAME_URL as string,
  paywallEcommCheckoutUrl: config.REACT_APP_PAYWALL_ECOMM_CHECKOUT_URL as string,
  segmentUrl: config.REACT_APP_SEGMENT_TRACKING_URL as string,
  oldAdminConsoleUrl: (config.REACT_APP_LASTPASS_BASE_ADDRESS +
    oldAdminConsolePath) as string,
  ldClientSideId: config.REACT_APP_LAUNCHDARKLY_CLIENTSIDE_ID as string,
  pendoSigningKeyName: config.REACT_APP_PENDO_SIGNING_KEY_NAME as string,
  googleApiKey: config.REACT_APP_GOOGLE_API_KEY as string,
  alpApiUrl: config.REACT_APP_ALP_API_URL as string,
  isPendoDesigner: config.REACT_APP_IS_PENDO_DESIGNER === 'true',
  launchDarklyMockAddress: config.REACT_APP_LAUNCHDARKLY_MOCK_ADDRESS as string
}

const applyLastPassEuConfig = () => {
  defaultConfig.lastPassBaseAddress = config.REACT_APP_LASTPASS_EU_BASE_ADDRESS as string
  defaultConfig.segmentUrl = config.REACT_APP_SEGMENT_EU_TRACKING_URL as string
  defaultConfig.oldAdminConsoleUrl = (config.REACT_APP_LASTPASS_EU_BASE_ADDRESS +
    oldAdminConsolePath) as string
}

export { defaultConfig, applyLastPassEuConfig }

import { push } from 'connected-react-router'
import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { preparePolicyData } from '@lastpass/admin-console-dependencies/services/policies/policy-initializer'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { TeamsPolicyMap } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getPolicyInfo = (state: AppState) =>
  state.generalPoliciesList.policyMap

export function createGetGeneralPolicySaga() {
  return function* getGeneralPolicySaga(
    action: ReturnType<
      typeof generalPoliciesDrawerActions.getCurrentGeneralPolicy
    >
  ) {
    try {
      const policyKey = action.payload.params.path.id

      if (policyKey) {
        yield put(generalPoliciesDrawerActions.setGeneralPolicyLoading(true))
        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDrawerOpen(true)
        )
        try {
          const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
          const policy = policyMap[policyKey]
          if (policy) {
            if (!policy.policyData) {
              policy.policyData = preparePolicyData(policy, null)
            }
            yield put(
              generalPoliciesDrawerActions.setCurrentGeneralPolicy(policy)
            )
            yield put(
              generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
                false,
                policyKey
              )
            )
          } else {
            yield put(
              generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
                true,
                policyKey
              )
            )
            if (Object.keys(policyMap).length !== 0) {
              yield put(push('/policies/generalpolicies'))
            }
          }
        } finally {
          yield put(generalPoliciesDrawerActions.setGeneralPolicyLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

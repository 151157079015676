import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyBold, Heading100 } from '@lastpass/lastkit'

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;

  hr {
    border-top: none;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
    margin: 24px 0;
  }
`

const Link = styled.a`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const OrderedList = styled.ol`
  margin: 17px 0;
  counter-reset: howToEnrollList;

  li {
    counter-increment: howToEnrollList;
    margin-top: 16px;
    padding-left: 32px;
    position: relative;
  }

  li:before {
    content: counters(howToEnrollList, '.') ' ';
    background: ${props => props.theme.colors.blue200};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    font-weight: 600;
    margin-right: 8px;
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const PasswordlessDescription = () => {
  return (
    <MainContainer>
      <Heading100>
        <Trans>How to enroll users</Trans>
      </Heading100>
      <OrderedList>
        <li>
          <Trans>
            You assign users {'&'} groups and send invitation emails.
          </Trans>
        </li>
        <li>
          <Trans>
            Users open the invitation email and install the{' '}
            <BodyBold>LastPass Authenticator</BodyBold> app.
          </Trans>
        </li>
        <li>
          <Trans>They pair their device using a QR code.</Trans>
        </li>
      </OrderedList>
      <div>
        <Trans>
          Once enrolled, assigned users logging into SSO apps will be challenged
          to verify their identity with the{' '}
          <BodyBold>LastPass Authenticator</BodyBold> instead of a password.
        </Trans>{' '}
        <br />
        <Link
          data-qa="PasswordlessLink"
          href="https://link.lastpass.com/passwordless-authentication"
          target="_blank"
        >
          <Trans>Learn more</Trans>
        </Link>
      </div>
      <hr />
    </MainContainer>
  )
}

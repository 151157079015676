import React from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as FormIcon } from '@lastpass/assets/svg/admin-console/icon-form.svg'
import { Collapsible, TableView } from '@lastpass/lastkit'

export const UserSitesSummaryCard: React.FunctionComponent = () => {
  return (
    <Collapsible title={<Trans>Sites</Trans>} qadata="SitesCard">
      <TableView
        qadata="SitesCardInfo"
        icon={<FormIcon />}
        title={<Trans>Site login data has moved</Trans>}
        text={<Trans>Go to Reporting to view site login events.</Trans>}
      />
    </Collapsible>
  )
}

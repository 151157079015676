import { MessageDescriptor } from '@lingui/core'

import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import {
  DialogTypes,
  MfaUpsellDialogContext
} from '@lastpass/admin-console-dependencies/types/dialog-types'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

import { AddUsersDrawerActionTypes } from '../users/view/add/actions'
import { NewAddUser } from '../users/view/add/state'

export enum GlobalActionTypes {
  SET_CURRENT_USER = '[current-user] set',
  GET_CURRENT_USER = '[current-user] get',
  SET_CURRENT_USER_PERMISSIONS = '[current-user] set-permission',

  SET_CURRENT_USER_LANGUAGE = '[current-user] set-language',

  SET_NOTIFICATION = '[notification] set',
  CLEAR_NOTIFICATION = '[notification] clear',

  SET_DIALOG = '[dialog] set-dialog',
  CONFIRM_DIALOG = '[dialog] confirm',
  DISCARD_DIALOG = '[dialog] discard',
  EMPTY_DIALOG = '[dialog] empty-dialog',
  MORE_INFO_CLICKED_ON_DIALOG = '[dialog] more-info',

  SHOW_MFA_UPSELL_DIALOG = '[upsell-dialog] show-dialog',
  SHOW_MFA_ADDON_UPSELL_DIALOG = '[upsell-dialog] show-mfa-addon-dialog',
  SHOW_GLOBAL_ERROR = '[global-alert] show-global-error',

  SHOW_MFA_USAGE_NOTIFICATION = '[dialog] show-mfa-usage-notification',

  START_ONBOARDIN_TOUR = '[onboarding-tour] start-tour',

  SHOW_CONFIRM_DIALOG_ON_DISCARD_FORM = '[admin-level-drawer] show-confirm-dialog-on-discard-form',

  MFA_APPLICATION_GETSTARTED = '[action] mfa-applications-get-started',
  PASSWORDLESS_GETSTARTED = '[action] passwordless-get-started',
  SET_IS_LOADING = '[action] set-is-loading',

  SHOW_MSP_PAYMENT_INFO_DIALOG = '[msp-payment-dialog] show-msp-payment-info-dialog',
  CONTACT_LINK_CLICKED_ON_MSP_DIALOG = '[msp-payment-dialog] contact-link-clicked',
  REMINDER_CLICKED_ON_MSP_DIALOG = '[msp-payment-dialog] remind-me-clicked',

  SET_DECRYPTED_PRIVATE_SHARING_KEY = '[action] set-decrypted_private_sharing_key',
  SET_VAULT_KEY = '[action] set-vault-key',
  MANAGE_PROVISIONED_USERS_CONTINUE_WITH_ALL_USERS_DIALOG = '[dialog] manage-provisioned-users-continue-with-all',
  MANAGE_PROVISIONED_USERS_CONTINUE_WITH_MANUALLY_ADDED_USERS_DIALOG = '[dialog] manage-provisioned-users-continue-with-manually-added-users'
}

const SourceMap: {
  [s: string]: SegmentPropertyType<'Identity Paywall Clicked', 'Source'>
} = {
  legacyvpn: 'Legacy VPN Overview',
  passwordless: 'Passwordless Policies',
  workstation: 'Workstation Login Overview',
  multifactor: 'MFA Apps Overview',
  ssoAppDrawer: 'Add SSO App Drawer',
  adminReports: 'MFA Admin Activity Reporting',
  userReports: 'MFA User Activity Reporting',
  default: undefined
}

export const globalActions = {
  setCurrentUser: (currentUser: CurrentUser) =>
    createAction(GlobalActionTypes.SET_CURRENT_USER, { currentUser }),
  getCurrentUser: () => createAction(GlobalActionTypes.GET_CURRENT_USER, {}),
  setCurrentUserPermission: (permissions: AdminPermissions[]) =>
    createAction(GlobalActionTypes.SET_CURRENT_USER_PERMISSIONS, permissions),
  setCurrentUserLanguage: (lang: string) =>
    createAction(GlobalActionTypes.SET_CURRENT_USER_LANGUAGE, lang),

  setNotification: (notification: NotificationProps) =>
    createAction(GlobalActionTypes.SET_NOTIFICATION, { notification }),
  setNotificationAlert: (text: MessageDescriptor | string) =>
    createAction(GlobalActionTypes.SET_NOTIFICATION, {
      notification: {
        message: text,
        type: NotificationType.alert,
        autoDismiss: false
      }
    }),
  setNotificationSuccess: (text: MessageDescriptor | string) =>
    createAction(GlobalActionTypes.SET_NOTIFICATION, {
      notification: {
        message: text,
        type: NotificationType.success,
        autoDismiss: true
      }
    }),
  clearNotification: (delay?: number) =>
    createAction(GlobalActionTypes.CLEAR_NOTIFICATION, { delay }),

  setDialog: (dialog: DialogTypes) =>
    createAction(GlobalActionTypes.SET_DIALOG, { dialog }),

  setDialogWithSegment: (dialog: DialogTypes, trackingInformation) =>
    track(createAction(GlobalActionTypes.SET_DIALOG, { dialog }), {
      segment: uacEvent(trackingInformation.name, {
        ...trackingInformation.trackingData
      })
    }),

  emptyDialog: () => createAction(GlobalActionTypes.EMPTY_DIALOG),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmDialog: (data?: string | { [key: string]: any }) =>
    createAction(GlobalActionTypes.CONFIRM_DIALOG, { data }),
  discardDialog: () => createAction(GlobalActionTypes.DISCARD_DIALOG),
  moreInfoClicked: () =>
    createAction(GlobalActionTypes.MORE_INFO_CLICKED_ON_DIALOG),
  confirmDialogSegment: (
    eCommEnabled: boolean,
    paywallContext: SegmentPropertyType<
      'Identity Paywall Clicked',
      'Paywall Context'
    >,
    identityPaywallClickedAction: SegmentPropertyType<
      'Identity Paywall Clicked',
      'Action'
    >,
    context?: MfaUpsellDialogContext,
    data?: string
  ) =>
    track(createAction(GlobalActionTypes.CONFIRM_DIALOG, { data }), {
      segment: uacEvent('Identity Paywall Clicked', {
        'eComm Enabled': eCommEnabled,
        'Paywall Context': paywallContext,
        Product: context ? 'MFA' : 'SSO',
        Source: context ? SourceMap[context] : 'SSO Apps Overview',
        Action: identityPaywallClickedAction
      })
    }),
  discardDialogSegment: (
    eCommEnabled: boolean,
    paywallContext: SegmentPropertyType<
      'Identity Paywall Clicked',
      'Paywall Context'
    >,
    identityPaywallClickedAction: SegmentPropertyType<
      'Identity Paywall Clicked',
      'Action'
    >,
    context?: MfaUpsellDialogContext
  ) =>
    track(createAction(GlobalActionTypes.DISCARD_DIALOG, {}), {
      segment: uacEvent('Identity Paywall Clicked', {
        'eComm Enabled': eCommEnabled,
        'Paywall Context': paywallContext,
        Product: context ? 'MFA' : 'SSO',
        Source: context ? SourceMap[context] : 'SSO Apps Overview',
        Action: identityPaywallClickedAction
      })
    }),
  mfaApplicationsGetStarted: (newRoute?: string) =>
    track(
      createAction(GlobalActionTypes.MFA_APPLICATION_GETSTARTED, {
        newRoute
      }),
      {
        segment: uacEvent('Add MFA App Clicked', {
          'App Count': 0,
          Source: 'MFA Apps Overview'
        })
      }
    ),
  passwordlessGetStarted: (newRoute?: string) =>
    track(
      createAction(GlobalActionTypes.PASSWORDLESS_GETSTARTED, {
        newRoute
      }),
      {
        segment: uacEvent('Add Passwordless Policy Clicked', {
          'Policy Count': 0,
          Type: 'Overview'
        })
      }
    ),
  showMfaUpsellDialog: (
    eCommEnabled: boolean,
    context: MfaUpsellDialogContext,
    updateHistory?: (route: string) => void,
    newRoute?: string
  ) =>
    track(
      createAction(GlobalActionTypes.SHOW_MFA_UPSELL_DIALOG, {
        context,
        updateHistory,
        newRoute
      }),
      {
        segment: uacEvent('Identity Paywall Viewed', {
          'Paywall Context': 'Trial',
          Product: 'MFA',
          'eComm Enabled': eCommEnabled,
          Source: SourceMap[context]
        })
      }
    ),
  showMfaUsageNotificationDialog: (
    context: MfaUpsellDialogContext,
    newRoute?: string
  ) =>
    track(
      createAction(GlobalActionTypes.SHOW_MFA_USAGE_NOTIFICATION, {
        context,
        newRoute
      }),
      {
        segment: uacEvent('MSP Add-on Warning Viewed', {
          Product: 'MFA',
          Source: SourceMap[context]
        })
      }
    ),
  discardMfaUsageDialogSegment: (
    context: MfaUpsellDialogContext,
    mfaUsageDialogClickedAction: SegmentPropertyType<
      'MSP Add-on Warning Clicked',
      'Action'
    >
  ) =>
    track(createAction(GlobalActionTypes.DISCARD_DIALOG, {}), {
      segment: uacEvent('MSP Add-on Warning Clicked', {
        Product: 'MFA',
        Source: SourceMap[context],
        Action: mfaUsageDialogClickedAction
      })
    }),
  confirmMfaUsageDialogSegment: (
    context: MfaUpsellDialogContext,
    mfaUsageDialogClickedAction: SegmentPropertyType<
      'MSP Add-on Warning Clicked',
      'Action'
    >
  ) =>
    track(createAction(GlobalActionTypes.CONFIRM_DIALOG, {}), {
      segment: uacEvent('MSP Add-on Warning Clicked', {
        Product: 'MFA',
        Source: SourceMap[context],
        Action: mfaUsageDialogClickedAction
      })
    }),
  paywallTrackingAction: (
    numberFailed: number,
    numberSucceeded: number,
    data?: string
  ) =>
    track(createAction(GlobalActionTypes.CONFIRM_DIALOG, { data }), {
      segment: uacEvent('Users Removed from Admin Level', {
        'Number Failed': numberFailed,
        'Number Succeeded': numberSucceeded
      })
    }),
  showMfaAddonUpsellDialog: (
    context: MfaUpsellDialogContext,
    eCommEnabled: boolean
  ) =>
    track(
      createAction(GlobalActionTypes.SHOW_MFA_ADDON_UPSELL_DIALOG, {
        context,
        eCommEnabled
      }),
      {
        segment: uacEvent('Identity Paywall Viewed', {
          'Paywall Context': 'Purchase',
          Product: 'MFA',
          'eComm Enabled': eCommEnabled,
          Source: SourceMap[context]
        })
      }
    ),
  processPendingUsersUpload: (
    selectorFiles: FileList,
    currentFormPendingUsers: NewAddUser[]
  ) =>
    createAction(AddUsersDrawerActionTypes.PROCESS_PENDING_USERS_UPLOAD, {
      selectorFiles,
      currentFormPendingUsers
    }),
  showGlobalError: (show: boolean) =>
    createAction(GlobalActionTypes.SHOW_GLOBAL_ERROR, { showError: show }),
  startOnboardingTour: () =>
    createAction(GlobalActionTypes.START_ONBOARDIN_TOUR),
  showConfirmDialogOnDiscard: (
    dialog: ConfirmDialog,
    closeLink: string,
    unblock?: Function
  ) =>
    createAction(GlobalActionTypes.SHOW_CONFIRM_DIALOG_ON_DISCARD_FORM, {
      dialog,
      closeLink,
      unblock
    }),
  setIsLoading: (isLoading: boolean) =>
    createAction(GlobalActionTypes.SET_IS_LOADING, {
      isLoading
    }),
  showMspPaymentInfoDialog: () =>
    createAction(GlobalActionTypes.SHOW_MSP_PAYMENT_INFO_DIALOG, {}),
  confirmExportUserCounts: (fromDateUtc: string, toDateUtc: string) =>
    createAction(GlobalActionTypes.CONFIRM_DIALOG, { fromDateUtc, toDateUtc }),
  confirmMspPaymentInfoDialog: () =>
    track(createAction(GlobalActionTypes.CONFIRM_DIALOG, {}), {
      segment: uacEvent('MSP Trial Enter Payment Clicked')
    }),
  contactMspPaymentPartner: () =>
    track(
      createAction(GlobalActionTypes.CONTACT_LINK_CLICKED_ON_MSP_DIALOG, {}),
      {
        segment: uacEvent('MSP Trial Contact Link Clicked')
      }
    ),
  setMspPaymentReminder: () =>
    track(createAction(GlobalActionTypes.REMINDER_CLICKED_ON_MSP_DIALOG, {}), {
      segment: uacEvent('MSP Trial Set Payment Reminder Clicked')
    }),
  setDecryptedPrivateSharingKey: (privateSharingKey: string) =>
    createAction(
      GlobalActionTypes.SET_DECRYPTED_PRIVATE_SHARING_KEY,
      privateSharingKey
    ),
  setVaultKey: (vaultKey: Buffer) =>
    createAction(GlobalActionTypes.SET_VAULT_KEY, vaultKey),
  confirmManageProvisionedUsersDialogWithAllUsers: () =>
    createAction(
      GlobalActionTypes.MANAGE_PROVISIONED_USERS_CONTINUE_WITH_ALL_USERS_DIALOG
    ),
  confirmManageProvisionedUsersDialogWithManuallyAddedUsers: () =>
    createAction(
      GlobalActionTypes.MANAGE_PROVISIONED_USERS_CONTINUE_WITH_MANUALLY_ADDED_USERS_DIALOG
    )
}

export type GlobalActions = ActionsUnion<typeof globalActions>

import { call, put, take } from 'redux-saga/effects'

import { FederatedOAuthManager } from '@lastpass/federation'

import { keyRotationDebug } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { KeyRotationAuthenticateCompanyAdminDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function* keyRotationAuthenticateCompanyAdmin(providerName: string) {
  let accessToken: string | undefined
  let isIdpUrlInvalid = false

  yield put(federatedLoginActions.setLoading(false))
  while (!accessToken) {
    const keyRotationAuthenticateCompanyAdminDialog: KeyRotationAuthenticateCompanyAdminDialog = {
      type: 'key-rotation-authenticate-company-admin-dialog',
      providerName,
      isIdpUrlInvalid
    }
    yield put(
      globalActions.setDialog(keyRotationAuthenticateCompanyAdminDialog)
    )
    const authenticateCompanyAdminAction = yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])
    if (
      authenticateCompanyAdminAction.type != GlobalActionTypes.CONFIRM_DIALOG
    ) {
      return false
    }

    const authenticationData = authenticateCompanyAdminAction.payload.data

    yield put(federatedLoginActions.setKeyRotationAuth(authenticationData))

    keyRotationDebug('IDP: Admin authentication')

    try {
      yield put(federatedLoginActions.setLoading(true))
      FederatedOAuthManager.initialize(authenticationData)
      accessToken = yield call(FederatedOAuthManager.login)
      keyRotationDebug('IDP: Admin authentication was successful')
    } catch (error) {
      isIdpUrlInvalid =
        error instanceof Error && error.message !== 'Popup window closed'
      keyRotationDebug('IDP: Admin authentication failed')
      yield put(federatedLoginActions.setLoading(false))
    }
  }

  return true
}

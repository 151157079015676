import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

const normalizeAdminPermissionType = perm =>
  AdminPermissions[`${perm.charAt(0).toLowerCase()}${perm.slice(1)}`]

export function createEditAdminLevelSaga(
  editAdminLevelService: UACServices.Services
) {
  return function* editAdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.editAdminLevel>
  ) {
    try {
      const {
        id,
        name,
        description,
        permissions,
        closeLink,
        isPartial
      } = action.payload

      try {
        if (!isPartial) {
          const result: UACServices.AdminLevelSaveAPI.Responses = yield call(
            editAdminLevelService.adminLevelEdit,
            {
              id,
              name,
              description,
              permissions: permissions.filter(
                permission =>
                  permission !== AdminPermissions.managedCompanyCidOverride
              )
            }
          )

          if (result.type === UACServices.AdminLevelSaveAPI.SUCCESS) {
            const adminLevelId = result.body.id
            yield put(adminListActions.getAdminList())
            yield put(
              globalActions.setNotification({
                message: msg`Changes saved`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )

            if (closeLink) {
              yield put(push(closeLink))
            } else {
              yield put(push(`/users/admin/${adminLevelId}/add`))
            }
          } else {
            throw new UnknownServiceError()
          }
        } else {
          const adminLevelResponse: UACServices.AdminLevelAPI.Responses = yield call(
            editAdminLevelService.adminLevel,
            id
          )

          yield put(
            globalActions.setNotification({
              message: msg`Changes saved`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )

          if (
            adminLevelResponse.type === UACServices.AdminLevelSaveAPI.SUCCESS
          ) {
            const {
              id,
              mspCidOverrideAdminLevel,
              mspCidOverridePermissions,
              permissions: currentPermissions,
              userCount
            } = adminLevelResponse.body

            if (
              !currentPermissions.includes(
                AdminPermissions.managedCompanyCidOverride
              ) &&
              permissions.includes(AdminPermissions.managedCompanyCidOverride)
            ) {
              yield put(
                adminLevelDrawerActions.setCurrentAdminLevel({
                  id,
                  name,
                  description,
                  permissions: permissions.map(perm =>
                    normalizeAdminPermissionType(perm)
                  ),
                  userCount,
                  mspCidOverridePermissions: [],
                  mspCidOverrideAdminLevel: MspCidOverrideLevel.empty,
                  isCustom: true
                })
              )
              yield put(push(`/users/admin/${id}/managed-admin-permissions`))
            } else {
              const result: UACServices.AdminLevelPartialEditAPI.Responses = yield call(
                editAdminLevelService.adminLevelPartialEdit,
                {
                  id,
                  name,
                  description,
                  permissions,
                  mspCidOverrideAdminLevel: permissions.includes(
                    AdminPermissions.managedCompanyCidOverride
                  )
                    ? mspCidOverrideAdminLevel
                    : MspCidOverrideLevel.none,
                  mspCidOverridePermissions: permissions.includes(
                    AdminPermissions.managedCompanyCidOverride
                  )
                    ? mspCidOverridePermissions
                    : []
                }
              )

              if (
                result.type === UACServices.AdminLevelPartialEditAPI.SUCCESS
              ) {
                const adminLevelId = result.body.id
                yield put(adminListActions.getAdminList())
                yield put(adminLevelDrawerActions.reset())

                yield put(push(`/users/admin/${adminLevelId}`))
              } else {
                throw new UnknownServiceError()
              }
            }
          } else {
            throw new UnknownServiceError()
          }
        }
      } catch (e) {
        yield put(
          globalActions.setNotification({
            message: msg`Admin level hasn't been updated.`,
            type: NotificationType.alert,
            autoDismiss: false
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

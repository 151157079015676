import { push } from 'connected-react-router'
import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'

export const currentUrlFiltersSelector = (state: AppState) => state.router

export function* redirectToList() {
  const currentUrlFilters = yield select(currentUrlFiltersSelector)
  const query = getQueryParams(
    currentUrlFilters.location,
    Namespace.managedCompanies
  )
  yield put(managedCompaniesActions.getList({ query, path: {} }))
  yield put(
    push(`/home/managed-companies${currentUrlFilters.location.search || ''}`)
  )
}

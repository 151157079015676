import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as FolderIcon } from '@lastpass/assets/svg/admin-console/icon-folder.svg'
import { ReactComponent as FolderIconTable } from '@lastpass/assets/svg/admin-console/icon-folder-table.svg'
import {
  CardTable,
  Collapsible,
  TableView,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDetailsActions } from '@lastpass/admin-console-dependencies/state/users/details/actions'
import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { SharedFolder } from '@lastpass/admin-console-dependencies/types/shared-folder'

import { UserPermissions } from '../UserSharedFoldersPermissions'

export type UserSharedFolderCardProps = LastPassComponentProps<
  UsersDrawerState['sharedFolders'],
  typeof userDrawerActions & typeof userDetailsActions,
  {
    detailLink: string
    sharedFoldersLink: string
    expand: boolean
    onExpand: Function
  }
>

const StyledFolderTableIcon = styled(FolderIconTable)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const UserSharedFoldersSummaryCardComponent: React.FunctionComponent<UserSharedFolderCardProps> = ({
  state: sharedFolders,
  props,
  actions
}) => {
  const pathParams: UserDrawerUrlParams = usePathParams()

  useEffect(() => {
    if (props.expand) {
      actions.getUserSharedFolders({ path: pathParams, query: {} }, 5)
    }
  }, [actions, pathParams, props.expand])
  return (
    <Collapsible
      title={<Trans>Shared folders</Trans>}
      expanded={props.expand}
      onInitialExpand={() => {
        actions.getUserSharedFolders({ path: pathParams, query: {} }, 5)
      }}
      loading={sharedFolders.isLoading}
      onExpand={props.onExpand}
      qadata={'SharedFoldersCard'}
    >
      {sharedFolders && (
        <CardTable
          qadata={'UserLevelSharedFolderList'}
          table={sharedFolders}
          columns={[
            {
              name: <></>,
              component: StyledFolderTableIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Folder Name</Trans>,
              renderer: (record: SharedFolder) => record.name
            },
            {
              name: <Trans>Permissions</Trans>,
              renderer: function PermissionRenderer(record: SharedFolder) {
                return <UserPermissions record={record} />
              }
            }
          ]}
          footerNodes={
            <>
              <TextButton blue onClick={actions.goToSharedFoldersPage}>
                <StyledLink
                  to={props.sharedFoldersLink}
                  data-qa={'GoToSharedFoldersButton'}
                >
                  <Trans>Go to Shared Folders</Trans>
                </StyledLink>
              </TextButton>
              <TextButton blue>
                <StyledLocationLink
                  to={props.detailLink}
                  data-qa={'ViewAllFoldersButton'}
                  discardnamespace={Namespace.sharedFolders}
                >
                  <Trans>
                    View all shared folders:{' '}
                    {sharedFolders && sharedFolders.totalResults}
                  </Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<FolderIcon />}
              title={
                <Trans>This user doesn{"'"}t have any shared folders.</Trans>
              }
              text={
                <Trans>
                  Shared folders are for sharing passwords and other info
                  between LastPass users.
                </Trans>
              }
              actionButton={
                <TextButton onClick={actions.goToSharedFoldersPage}>
                  <StyledLink
                    to={props.sharedFoldersLink}
                    data-qa={'GoToSharedFoldersButton'}
                  >
                    <Trans>Go to shared folders</Trans>
                  </StyledLink>
                </TextButton>
              }
            />
          }
        />
      )}
    </Collapsible>
  )
}

export function createUserSharedFoldersSummaryCard<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserSharedFoldersSummaryCardComponent,
    (state: TState) => state.usersDrawer.sharedFolders,
    { ...userDrawerActions, ...userDetailsActions }
  )
}

export type UserSharedFoldersSummaryCard = ReturnType<
  typeof createUserSharedFoldersSummaryCard
>

import { call } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { EntityType } from '@lastpass/admin-console-dependencies/server/policies/passwordless/get-uacids-bylastpassids'
import { UnknownServiceError } from '@lastpass/admin-console-dependencies/server/responses'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import {
  FilterOption,
  UserOperationJob,
  UserOperationJobTypes
} from '@lastpass/admin-console-dependencies/types/user-operation-job'

function* mapUserIds(
  service: UACServices.Services,
  lastpassIds?: TeamsPolicyUserInfo[]
) {
  let result: string[] | undefined

  if (lastpassIds && lastpassIds.length > 0) {
    const response: UACServices.GetUacIdsByLastPassIdApi.Responses = yield call(
      service.getUacIdsByLastPassIds,
      lastpassIds.map(u => u.uid),
      EntityType.User
    )

    if (response.type != UACServices.GetUacIdsByLastPassIdApi.SUCCESS) {
      throw new UnknownServiceError()
    }

    result = response.body.ids
  }

  return result
}

function* mapGroupIds(
  service: UACServices.Services,
  lastpassIds?: TeamsPolicyGroup[]
) {
  let result: string[] | undefined

  if (lastpassIds && lastpassIds.length > 0) {
    const response: UACServices.GetUacIdsByLastPassIdApi.Responses = yield call(
      service.getUacIdsByLastPassIds,
      lastpassIds.map(u => u.id),
      EntityType.Group
    )

    if (response.type != UACServices.GetUacIdsByLastPassIdApi.SUCCESS) {
      throw new UnknownServiceError()
    }

    result = response.body.ids
  }

  return result
}

function validateRequest(userOperationJob: UserOperationJob): boolean {
  return !(
    userOperationJob.filter.filterOption != FilterOption.All &&
    (!userOperationJob.filter.companyGroupIds ||
      userOperationJob.filter.companyGroupIds.length === 0) &&
    (!userOperationJob.filter.companyUserIds ||
      userOperationJob.filter.companyUserIds.length === 0)
  )
}

export function* sendMfaInvitation(
  policyDatas: TeamsPolicyModelData[],
  policyService: UACServices.Services
) {
  const policyData: TeamsPolicyModelData = policyDatas[0]

  const userOperationJobParameter: UserOperationJob | null = {
    job: UserOperationJobTypes.Mfainvitation,
    filter: {
      filterOption: FilterOption.All
    }
  }

  switch (policyData.appliesTo) {
    case AppliesToOptions.all:
      break
    case AppliesToOptions.inclusive:
      userOperationJobParameter.filter.filterOption = FilterOption.Inclusive
      userOperationJobParameter.filter.companyUserIds = yield mapUserIds(
        policyService,
        policyData.inclusiveList
      )
      userOperationJobParameter.filter.companyGroupIds = yield mapGroupIds(
        policyService,
        policyData.inclusiveGroupList
      )
      break
    case AppliesToOptions.exclusive:
      userOperationJobParameter.filter.filterOption = FilterOption.Exclusive
      userOperationJobParameter.filter.companyUserIds = yield mapUserIds(
        policyService,
        policyData.exclusiveList
      )
      userOperationJobParameter.filter.companyGroupIds = yield mapGroupIds(
        policyService,
        policyData.exclusiveGroupList
      )
      break
  }

  const isValid = validateRequest(userOperationJobParameter)

  if (isValid) {
    const sendMfaInvitationResult: UACServices.UserOperationsJobAPI.Responses = yield call(
      policyService.userOperationJob,
      userOperationJobParameter
    )

    if (
      sendMfaInvitationResult.type !== UACServices.UserOperationsJobAPI.SUCCESS
    ) {
      throw new UnknownServiceError()
    }
  }
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as ArrowDown } from '@lastpass/assets/svg/admin-console/icon-arrow-down.svg'

import {
  AssignmentStatus,
  AvailabilityStatus,
  PsaCompanyContractAssignment,
  PsaCompanyContractAssignmentOption
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { AgreementSelector } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/common/AgreementSelector'
import { AgreementSelectionMessages } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/helpers/get-agreement-selection-message'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'

const StyledArrowDown = styled(ArrowDown)`
  color: ${props => props.theme.colors.iconColorDefault};
  width: 12px;
  margin-left: 4px;
`

const StyledArrowUp = styled(StyledArrowDown)`
  transform: rotate(180deg);
`

function getOrderIcon(orderDirection: OrderDirections) {
  return orderDirection === OrderDirections.ASC ? (
    <StyledArrowUp data-qa="OrderDirectionASC" />
  ) : (
    <StyledArrowDown data-qa="OrderDirectionDESC" />
  )
}

export enum OrderDirections {
  ASC,
  DESC
}

export const getTableColumns = (
  actualAssignmentsMap: AssignmentsMapping,
  orderDirection,
  messages: AgreementSelectionMessages,
  onSelect: (
    assignment: PsaCompanyContractAssignment,
    availability: AvailabilityStatus
  ) => void
) => {
  return [
    {
      name: (
        <>
          <Trans>Company name</Trans>
          {getOrderIcon(orderDirection)}
        </>
      ),
      renderer: (assignment: PsaCompanyContractAssignmentOption) =>
        assignment.company.name,
      columnOrderByName: 'name'
    },
    {
      name: <Trans>Agreement</Trans>,
      noTooltip: true,
      renderer: function createAgreementSelector(
        assignmentOption: PsaCompanyContractAssignmentOption
      ) {
        const assignmentsMapItem =
          actualAssignmentsMap[assignmentOption.company.id]

        const actuallySelectedId = assignmentsMapItem
          ? assignmentsMapItem.contractId
          : null
        const actualSelectionStatus = assignmentsMapItem
          ? assignmentsMapItem.assignmentStatus
          : AssignmentStatus.NOT_ASSIGNED

        const { company, contractAssignment } = assignmentOption
        const { contracts } = contractAssignment
        const message = messages[company.id]?.message

        return (
          <AgreementSelector
            actualSelectionStatus={actualSelectionStatus}
            actuallySelectedId={actuallySelectedId}
            company={company}
            contracts={contracts}
            selectionMessage={message}
            onSelect={onSelect}
          />
        )
      },
      contentWidth: '586px'
    }
  ]
}

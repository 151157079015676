import styled from '@emotion/styled'

import { AnimatedSwitchClass } from '../AnimatedSwitch'

export const RouteFade = styled.div`
  transition: opacity 0.2s;
  opacity: 1;
  &.${AnimatedSwitchClass}-enter {
    opacity: 0;
  }
  &.${AnimatedSwitchClass}-enter-active {
    opacity: 1;
  }
  &.${AnimatedSwitchClass}-exit {
    opacity: 1;
  }
  &.${AnimatedSwitchClass}-exit-active {
    opacity: 0;
  }
`

import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  CardContainer,
  Heading100,
  Pagination,
  RowContainer,
  Table,
  TableView
} from '@lastpass/lastkit'

import { DashboardDrawerQueryParams } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { Table as TableType } from '@lastpass/admin-console-dependencies/types/table'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'

export interface SecurityScoreCardProps {
  users: TableType<Partial<User>>
  queryParams: DashboardDrawerQueryParams
  updateQuery: Function
  noRecordsText: React.ReactElement
  qaData: string
}

const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  padding: 18px;
`

export const SecurityScoreCard: React.FunctionComponent<SecurityScoreCardProps> = ({
  users,
  queryParams,
  updateQuery,
  noRecordsText,
  qaData
}) => {
  const columns = [
    {
      name: <Trans>Name</Trans>,
      renderer: (record: Partial<User>) => getFullName(record)
    },
    {
      name: <Trans>Email</Trans>,
      renderer: (record: Partial<User>) => (record.email ? record.email : '')
    }
  ]

  const loading = <Loading color="blue900" active={true} />

  return (
    <CardContainer
      css={css`
        margin-top: 24px;
      `}
    >
      <StyledRowContainer>
        <Heading100>
          <Trans>Affected users</Trans>
        </Heading100>
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={users.totalResults}
          resultsPerPage={users.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </StyledRowContainer>
      <Table
        qadata={qaData}
        table={users}
        columns={columns}
        loadingView={loading}
        actions={[]}
        noRecordsView={<TableView icon={<UserIcon />} title={noRecordsText} />}
      />
    </CardContainer>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import DayPicker, {
  DateUtils,
  DayModifiers,
  Modifier,
  NavbarElementProps
} from 'react-day-picker'

import styled from '@emotion/styled'
import moment from 'moment'

import { ReactComponent as IconArrow } from '@lastpass/assets/svg/admin-console/icon-arrow-left.svg'

import 'react-day-picker/lib/style.css'
import { CaptionSemiboldStyle } from '../styles'

interface DateRangePickerProps {
  setStartDate: (startDate: Date) => void
  setEndDate: (endDate?: Date) => void
  defaultStartDate?: Date
  defaultEndDate?: Date
  updateValues: boolean
  maxDate?: Date
  minDate?: Date
  maxSelectableDays?: number
}

export const CalendarContainer = styled.div`
  ${CaptionSemiboldStyle};

  .Range
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colors.blue200} !important;
    color: ${props => props.theme.colors.neutral900};
    border-radius: 0;
  }
  .DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--end) {
    &:hover {
      border-radius: 0;
      background-color: ${props => props.theme.colors.blue200} !important;
      color: ${props => props.theme.colors.blue700};
    }
  }
  .DayPicker-Day--start:not(.DayPicker-Day--outside),
  .DayPicker-Day--end:not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colors.blue700} !important;
    color: ${props => props.theme.colors.white};
  }
  .DayPicker-Day--disabled {
    color: ${props => props.theme.colors.neutral500};
  }
  .Range .DayPicker-Day {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
  }
  .DayPicker-Day--start {
    border-radius: 1000px 0 0 1000px !important;
  }
  .DayPicker-Day--end {
    border-radius: 0 1000px 1000px 0 !important;
  }
  .DayPicker-Day--startOfWeekDay.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    border-radius: 1000px 0 0 1000px !important;
  }
  .DayPicker-Day--endOfWeekDay.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    border-radius: 0 1000px 1000px 0 !important;
  }
  .DayPicker-Caption {
    text-align: center;
  }
  .DayPicker-Day--today:not(.DayPicker-Day--selected) {
    color: ${props => props.theme.colors.blue700} !important;
  }
  .DayPicker-Caption {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
  }
`

export const IconArrowLeft = styled(IconArrow)`
  max-height: 12px;
  cursor: pointer;
`

export const IconArrowRight = styled(IconArrow)`
  max-height: 12px;
  transform: rotate(180deg);
  cursor: pointer;
`

export const NavContainer = styled.div`
  position: absolute;
  padding: 19px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`

const Navbar: FunctionComponent<Partial<NavbarElementProps>> = ({
  onPreviousClick,
  onNextClick,
  className
}) => {
  if (onPreviousClick && onNextClick && className) {
    return (
      <NavContainer className={className} data-qa="NavContainer">
        <IconArrowLeft
          onClick={() => onPreviousClick()}
          data-qa="IconArrowLeft"
        />
        <IconArrowRight
          onClick={() => onNextClick()}
          data-qa="IconArrowRight"
        />
      </NavContainer>
    )
  }
  return null
}

export const DateRangePicker: FunctionComponent<DateRangePickerProps> = props => {
  const [from, setFrom] = useState<Date | undefined>(props.defaultStartDate)
  const [to, setTo] = useState<Date | undefined>(props.defaultEndDate)
  const [enteredTo, setEnteredTo] = useState<Date | undefined>(
    props.defaultEndDate
  )

  useEffect(() => {
    if (props.updateValues) {
      setFrom(props.defaultStartDate)
      setTo(props.defaultEndDate)
      setEnteredTo(props.defaultEndDate)
    }
  }, [props])

  const isSelectingFirstDay = (day: Date, fromDate?: Date, toDate?: Date) => {
    const isBeforeFirstDay = fromDate && DateUtils.isDayBefore(day, fromDate)
    const isRangeSelected = fromDate && toDate

    return !fromDate || isBeforeFirstDay || isRangeSelected
  }

  const setStartDate = (date: Date) => {
    setFrom(date)
    props.setStartDate(date)
  }

  const setEndDate = (date?: Date) => {
    setTo(date)
    props.setEndDate(date)
  }

  const handleResetClick = (day: Date) => {
    setStartDate(day)
    setEndDate(undefined)
    setEnteredTo(undefined)
  }

  const handleDayClick = (day: Date, dayModifiers: DayModifiers) => {
    if (dayModifiers.disabled) {
      return
    }

    if (from && to && day >= from && day <= to) {
      handleResetClick(day)
      return
    }
    if (isSelectingFirstDay(day, from, to)) {
      setStartDate(day)
      setEndDate(undefined)
      setEnteredTo(undefined)
    } else {
      setEndDate(day)
      setEnteredTo(day)
    }
  }

  const handleDayMouseEnter = (day: Date, dayModifiers: DayModifiers) => {
    if (dayModifiers.disabled) {
      return
    }

    if (!isSelectingFirstDay(day, from, to)) {
      setEnteredTo(day)
    }
  }

  const modifiers = {
    start: from,
    end: enteredTo,
    startOfWeekDay: { daysOfWeek: [0] },
    endOfWeekDay: { daysOfWeek: [6] }
  }

  const disabledDays: Modifier[] = []
  if (props.maxDate) {
    disabledDays.push({ after: props.maxDate })
  }

  if (props.minDate) {
    disabledDays.push({ before: props.minDate })
  }

  if (props.maxSelectableDays && from && !to) {
    const maxSelectableDate: Date = moment(from)
      .add(props.maxSelectableDays, 'days')
      .toDate()

    disabledDays.push({ after: maxSelectableDate })
  }

  const firstDayOfLastMonth = moment()
    .subtract(1, 'months')
    .startOf('month')
    .toDate()

  const currentMonth =
    from && firstDayOfLastMonth > from ? from : firstDayOfLastMonth

  const selectedDays = from && enteredTo && [from, { from, to: enteredTo }]

  return (
    <CalendarContainer data-qa="CalendarContainer">
      <DayPicker
        disabledDays={disabledDays}
        className="Range"
        numberOfMonths={2}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        navbarElement={<Navbar />}
        firstDayOfWeek={0}
        initialMonth={firstDayOfLastMonth}
        month={currentMonth}
      />
    </CalendarContainer>
  )
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'

import { Heading100 } from '@lastpass/lastkit'

const DownloadLink = styled.a`
  display: block;
  margin-top: 10px;
`

export interface DownloadBlockProps {
  title: MessageDescriptor
  textForADFS30Link: MessageDescriptor
  textForADFS40Link: MessageDescriptor
  dataQaName: string
}

export const AdDownloadBlock: FunctionComponent<DownloadBlockProps> = props => {
  return (
    <>
      <Heading100>{i18n._(props.title)}</Heading100>
      <DownloadLink
        href="https://download.cloud.lastpass.com/adfs_cas/LastPassADFS30.msi"
        data-qa={`${props.dataQaName}LastPassADFS30-DownloadLink`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n._(props.textForADFS30Link)}
      </DownloadLink>
      <DownloadLink
        href="https://download.cloud.lastpass.com/adfs_cas/LastPassADFS40.msi"
        data-qa={`${props.dataQaName}LastPassADFS40-DownloadLink`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n._(props.textForADFS40Link)}
      </DownloadLink>
    </>
  )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as LockIcon } from '@lastpass/assets/svg/admin-console/icon-lock.svg'
import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import { ReactComponent as UnlockIcon } from '@lastpass/assets/svg/admin-console/icon-unlock.svg'
import {
  BodyRegular,
  CaptionBold,
  Dropdown,
  DropdownListItem,
  Heading200,
  IconButton,
  PrimaryButton
} from '@lastpass/lastkit'

import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import { PasswordlessDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'
import { TeamsPolicyModelData } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { UsersAndGroups } from '@lastpass/admin-console-dependencies/ui/policies/multifactor/UsersAndGroups'

const ComponentContainer = styled.div`
  border: 1px solid #b9c0c9;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(29, 48, 73, 0.16),
    0px 2px 2px rgba(29, 48, 73, 0.04), 0px 1px 2px rgba(29, 48, 73, 0.04);
  border-radius: 4px;
  padding: 16px;
`

const StyledIcon = ({ icon }) => {
  const Result = styled(icon)`
    margin-right: 8px;
  `
  return <Result />
}

const StyledHeading200 = ({ isEnabled }) => {
  const StyledHeading = styled(Heading200)`
    color: ${isEnabled
      ? props => props.theme.colors.neutral900
      : props => props.theme.colors.red700};
  `

  return (
    <StyledHeading>
      {isEnabled ? (
        <Trans>
          Require passwordless verification via LastPass Authenticator
        </Trans>
      ) : (
        <Trans>Passwordless is disabled</Trans>
      )}
    </StyledHeading>
  )
}

const HeadingContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-top: 8px;
  margin-bottom: 16px;
`

const UsersAndGroupsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 173px;
  margin-top: 8px;
`

const StyledCaptionBold = styled(CaptionBold)`
  color: ${props => props.theme.colors.neutral500};
  flex-grow: 1;
  align-self: center;
`

const Divider = styled.hr`
  margin-top: 16px;
  border: 1px solid ${props => props.theme.colors.neutral200};
`
const StyledDropdown = styled(Dropdown)`
  z-index: 1;
`
const HeaderButton = props => <IconButton icon={More} {...props} />

export interface RequirePasswordlessViaLastPassAuthProps {
  isEnabled: boolean
  policyData: TeamsPolicyModelData
}

export const RequirePasswordlessViaLastPassAuth: React.FunctionComponent<RequirePasswordlessViaLastPassAuthProps> = ({
  isEnabled,
  policyData
}) => {
  const dispatch = useDispatch()
  const actions = {
    disablePolicy: () => dispatch(passwordlessActions.disablePolicy()),
    enableRequirePasswordlessViaLastPassPolicy: () =>
      dispatch(passwordlessActions.enableRequirePasswordlessViaLastPassPolicy())
  }

  const usersCount = isEnabled ? (
    <UsersAndGroups
      inclusiveUsers={policyData.inclusiveList || []}
      exclusiveUsers={policyData.exclusiveList || []}
      inclusiveGroups={policyData.inclusiveGroupList || []}
      exclusiveGroups={policyData.exclusiveGroupList || []}
    />
  ) : (
    <Trans>No users</Trans>
  )

  const history = useHistory()

  const onEditPolicyUsersClicked = () => {
    dispatch(
      passwordlessDrawerActions.setDrawerStep(
        PasswordlessDrawerSteps.description
      )
    )
    history.push('/applications/passwordless/ssoapps/policy')
  }

  return (
    <ComponentContainer data-qa="passwordless-policy-state-card">
      <HeadingContainer>
        <StyledTitle data-qa="requirePasswordlessTitle">
          {isEnabled ? (
            <StyledIcon icon={LockIcon} />
          ) : (
            <StyledIcon icon={UnlockIcon} />
          )}
          <StyledHeading200 isEnabled={isEnabled} />
        </StyledTitle>
        {isEnabled && (
          <StyledDropdown button={HeaderButton} data-qa="PolicyMoreButton">
            <DropdownListItem
              red
              data-qa="PolicyDisablePolicy"
              onClick={actions.disablePolicy}
            >
              <Trans>Disable policy</Trans>
            </DropdownListItem>
          </StyledDropdown>
        )}
      </HeadingContainer>
      <StyledBodyRegular data-qa="requirePasswordlessSubTitle">
        {isEnabled ? (
          <Trans>
            Newly assigned users will be prompted to enroll upon next login to a
            protected service.
          </Trans>
        ) : (
          <Trans>
            Users are not prompted for passwordless login verification anywhere.
          </Trans>
        )}
      </StyledBodyRegular>
      <Divider />
      <UsersAndGroupsContainer>
        <StyledCaptionBold data-qa="requirePasswordlessUsersAndGroups">
          {usersCount}
        </StyledCaptionBold>
        {isEnabled ? (
          <StyledPrimaryButton
            id="editPolicyUsers"
            data-qa="editPolicyUsers"
            type="button"
            onClick={onEditPolicyUsersClicked}
          >
            <Trans>Edit policy users</Trans>
          </StyledPrimaryButton>
        ) : (
          <StyledPrimaryButton
            id="enablePolicy"
            data-qa="enablePolicy"
            type="button"
            onClick={actions.enableRequirePasswordlessViaLastPassPolicy}
          >
            <Trans>Enable</Trans>
          </StyledPrimaryButton>
        )}
      </UsersAndGroupsContainer>
    </ComponentContainer>
  )
}

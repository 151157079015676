import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/get-available-applications'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { applicationEmptyListActions } from '@lastpass/admin-console-dependencies/state/applications/saml/emptylist/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createAvailableApplicationsForEmptyList(
  applicationsServices: UACServices.Services
) {
  return function* getAvailableApplicationsForEmptyList() {
    try {
      yield put(applicationEmptyListActions.setApplicationsLoading(true))
      const result: Responses = yield call(
        applicationsServices.getAvailableApplications
      )
      if (result.type === UACServices.SSOAvailableApplicationsAPI.SUCCESS) {
        yield put(
          applicationEmptyListActions.setAvailableApplications(
            result.body.availableApplicationsResponse.results
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(applicationEmptyListActions.setApplicationsLoading(false))
    }
  }
}

import { FederatedOAuthManager } from '../../auth/oidc-auth-service'
import {
  createFederationFetch,
  FederationFetch
} from '../federation-api-factory'
import { AzureAdBatchUpdateUsersK1Response } from './batch-update-users-k-1'
import { AzureAdGetUsersResponse } from './get-users'
import { AzureAdUpdateUserK1Response } from './update-user-k-1'

const GRAPH_API_HOSTS = {
  com: 'graph.microsoft.com',
  us: 'graph.microsoft.us'
}

export const initialize = () => {
  const authority = FederatedOAuthManager.getAuthority()

  const graphApiHost = authority.includes('microsoftonline.us')
    ? GRAPH_API_HOSTS.us
    : GRAPH_API_HOSTS.com

  const federationFetch: FederationFetch<| AzureAdGetUsersResponse
  | AzureAdUpdateUserK1Response
  | AzureAdBatchUpdateUsersK1Response> = createFederationFetch(
    `https://${graphApiHost}`
  )
  return federationFetch
}

import React from 'react'

import { css } from '@emotion/react'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { DateRangeDropdown } from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { toDateStr } from '@lastpass/admin-console-dependencies/services/text-operations/date-string'

export const AdvancedUserActivityDateRange = () => {
  const queryParams = useQueryParams(Namespace.userReports)
  const updateQuery = useUpdateQuery(Namespace.userReports)

  const setDateRange = (startDate: Date, endDate: Date) => {
    updateQuery({
      startDate: toDateStr(startDate),
      endDate: toDateStr(endDate)
    })
  }

  const endDate = queryParams.endDate && new Date(queryParams.endDate)
  const startDate = queryParams.startDate && new Date(queryParams.startDate)

  return (
    <div
      css={css`
        > div > div,
        > div > button {
          margin-left: 0;
        }
      `}
    >
      <DateRangeDropdown
        dataQa={'DateOptionsDropdown'}
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        setRange={setDateRange}
      />
    </div>
  )
}

import React, { useEffect } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { Chart, ChartItem, DateRangeDropdown } from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import {
  DashboardState,
  SecurityScoreHistoryState
} from '@lastpass/admin-console-dependencies/state/dashboard/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { ChartTop, UpdatedComponent } from './UserAdoptionHistoryChart'

type SecurityScoreHistoryChartProps = LastPassComponentProps<
  SecurityScoreHistoryState,
  typeof dashboardActions
>

const RowSpacer = styled.div<{ isNewSecurityDashboardEnabled: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.isNewSecurityDashboardEnabled ? 'flex-end' : 'space-between'};
`

const Container = styled.div`
  display: block;
  width: 100%;
`

const Header = styled.h6`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
`

const formatNumber = (num: number) =>
  (num || 0).toLocaleString(undefined, { maximumFractionDigits: 1 })

export const SecurityScoreHistoryChartComponent: React.FunctionComponent<SecurityScoreHistoryChartProps> = ({
  state,
  actions
}) => {
  useEffect(() => {
    actions.getSecurityScoreHistory(
      state.startDate || null,
      state.endDate || null
    )
  }, [actions, state.endDate, state.startDate])

  const isNewSecurityDashboardEnabled = useFeatureFlags(
    FeatureFlags.isNewSecurityDashboardEnabled
  )

  const chartItems: ChartItem[] = state.items.map(x => ({
    date: x.date,
    value: x.score,
    tooltipText: formatNumber(x.score)
  }))

  const dateRangeCutOffDate = new Date('2021-03-16')

  return (
    <Container data-qa={'SecurityScoreHistoryChart'}>
      <RowSpacer isNewSecurityDashboardEnabled={isNewSecurityDashboardEnabled}>
        {!isNewSecurityDashboardEnabled && (
          <Header>
            <Trans>Average security score</Trans>
          </Header>
        )}
        <ChartTop>
          <UpdatedComponent updateTime={state.lastUpdateDateTimeUtc} />
          <DateRangeDropdown
            defaultStartDate={state.startDate}
            defaultEndDate={state.endDate}
            setRange={(startDate, endDate) => {
              actions.setSecurityScoreHistoryPeriod(startDate, endDate)
            }}
            minDate={dateRangeCutOffDate}
          />
        </ChartTop>
      </RowSpacer>

      {state.loading && <Loading color={'blue900'} active={true} />}
      <Chart
        items={chartItems}
        tooltipText={'{tooltipText}%'}
        height={'345px'}
        minValue={0}
        maxValue={100}
        minDateValue={state.startDate}
      />
    </Container>
  )
}

export function createSecurityScoreHistoryChartComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    SecurityScoreHistoryChartComponent,
    (state: TState) => state.dashboard.securityScoreHistory,
    dashboardActions
  )
}

export type SecurityScoreHistoryChart = ReturnType<
  typeof createSecurityScoreHistoryChartComponent
>

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'

import { EnumDictionary } from '../EnumValues'

export const MFAStatusMapping: EnumDictionary<MFAStatus, ReactElement> = {
  [MFAStatus.active]: <Trans>Active</Trans>,
  [MFAStatus.invited]: <Trans>Invited</Trans>,
  [MFAStatus.locked]: <Trans>Locked</Trans>,
  [MFAStatus.na]: <Trans>Disabled</Trans>,
  [MFAStatus.notInvited]: <Trans>Not Invited</Trans>,
  [MFAStatus.notRequired]: <>-</>
}

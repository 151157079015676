import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { msg, Plural, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AppsIllustration } from '@lastpass/assets/svg/admin-console/applications/apps-illustration.svg'
import { ReactComponent as AlertIcon } from '@lastpass/assets/svg/icon-warning-triangle.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  Pagination,
  PrimaryButton,
  SearchInput,
  Table as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { Application } from '@lastpass/admin-console-dependencies/state/applications/application'
import {
  applicationListActions,
  ApplicationListQueryParams,
  applicationListSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { ApplicationEmptyListComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/ApplicationEmptyList'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { SystemUpgradeReconfigAppsAlert } from '@lastpass/admin-console-dependencies/ui/upgrade/system/SystemUpgradeReconfigAppsAlert'

type ApplicationListProps = LastPassComponentProps<
  ApplicationListState,
  typeof applicationListActions,
  {}
>

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const AlignedContainer = styled.div`
  display: flex;
  justify-content: right;
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 4px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const StyledContainer = styled.div`
  display: flex;
`

const AppLimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const StyledNoAppIcon = styled(AppsIllustration)`
  width: 180px;
  height: 180px;
`

const StyledAlertIcon = styled(AlertIcon)`
  height: 18px;
  width: 18px;
  color: ${props => props.theme.colors.red600};
  float: right;
`

const StyledAppIcon = styled.img`
  float: left;
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 7px;
  margin-right: 9px;
`

const Bold = styled.span`
  font-weight: bold;
`

const defaultApplicationLimit = 3

const getApplicationCountLimit = (companyDetails: CompanyDetails) => {
  return companyDetails &&
    companyDetails.permissions &&
    companyDetails.permissions.applicationCountLimit
    ? companyDetails.permissions.applicationCountLimit
    : defaultApplicationLimit
}

const isUnlimitedAppsAllowed = (companyDetails: CompanyDetails): boolean => {
  return Boolean(
    companyDetails &&
      companyDetails.permissions &&
      companyDetails.permissions.allowUnlimitedApplicationsCount
  )
}

const isSsoPaywallEnabled = (companyDetails: CompanyDetails) => {
  return (
    companyDetails.permissions != null &&
    !isUnlimitedAppsAllowed(companyDetails)
  )
}

const getServiceNameParam = (url: string) =>
  new URL(url).searchParams.get('service')

export const ApplicationListComponent: React.FunctionComponent<ApplicationListProps> = ({
  state,
  actions
}) => {
  const queryParams: ApplicationListQueryParams = useQueryParams(Namespace.apps)
  const updateQuery = useUpdateQuery(Namespace.apps)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      applicationListActions.getApplicationList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const isFilterApplied = !!queryParams.search
  const isTableEmpty = !state.table.results.length && !isFilterApplied

  useEffect(() => {
    dispatch(
      applicationListSegmentActions.ssoAppsOverviewViewed(
        state.table.results.length
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAlternateSsoAppConfigurationEnabled = useFeatureFlags(
    FeatureFlags.isAlternateSsoAppConfigurationEnabled
  )

  const isNopasswordMigrationEnabled = useFeatureFlags(
    FeatureFlags.isNopasswordMigrationEnabled
  )

  const getRecordLink = (record: Application) => {
    if (isAlternateSsoAppConfigurationEnabled) {
      return record.legacyLastPassSSOEditUrl
        ? `${matchUrl}/0#${getServiceNameParam(
            record.legacyLastPassSSOEditUrl
          )}`
        : `${matchUrl}/${record.id}`
    }

    return record.legacyLastPassSSOEditUrl
      ? record.legacyLastPassSSOEditUrl
      : `${matchUrl}/${record.id}`
  }

  const getRecordLinkDestination = (record: Application) => {
    if (
      isAlternateSsoAppConfigurationEnabled ||
      !record.legacyLastPassSSOEditUrl
    ) {
      return ''
    }

    return '_blank'
  }

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const history = useHistory()

  const ssoPaywallIsEnabled = isSsoPaywallEnabled(companyDetails)

  const maximumAppsWithPaywall = getApplicationCountLimit(companyDetails)

  const paywallShouldRestrict =
    state.table.totalResults >= maximumAppsWithPaywall && ssoPaywallIsEnabled

  let remainingApps = maximumAppsWithPaywall - state.table.totalResults
  if (remainingApps < 0) {
    remainingApps = 0
  }

  const paywall = ssoPaywallIsEnabled ? (
    <AppLimitContainer data-qa="ApplicationsPageAppLimitDiv">
      <div data-qa="SsoPaywallMessage">
        {/* eslint-disable @lastpass/rules/i18n */}
        <Plural
          value={remainingApps}
          _0={
            <span>
              You’ve reached your 3 app limit. Get <Bold>Advanced SSO</Bold> for
              more.
            </span>
          }
          one={
            <span>
              You can add 1 more app. Get <Bold>Advanced SSO</Bold> for more.
            </span>
          }
          other={
            <span>
              You can add # more apps. Get <Bold>Advanced SSO</Bold> for more.
            </span>
          }
        />
      </div>
      <StyledPrimaryButton
        onClick={
          CompanyDetailsHelper.isTouchSalesEnabled(companyDetails)
            ? actions.showSsoUpsellContactSupportDialog
            : actions.showSsoUpsellDialog
        }
        key={'purchase_sso_addon'}
        data-qa="ApplicationsPagePurchaseSsoButton"
      >
        <Trans>Get Advanced SSO</Trans>
      </StyledPrimaryButton>
    </AppLimitContainer>
  ) : null

  const header = isTableEmpty ? null : (
    <>
      <RowContainer>
        <Heading300 data-qa="ApplicationsPageHeader">
          <Trans>Applications</Trans>
        </Heading300>
        <AlignedContainer>
          {paywall}
          <StyledPrimaryButton
            disabled={paywallShouldRestrict}
            key="add applications"
            data-qa="ApplicationsPageAddApplicationButton"
            onClick={() => {
              actions.addNewApp(state.table.totalResults)
              history.push('/applications/saml/add')
            }}
          >
            <Trans>Add app</Trans>
          </StyledPrimaryButton>
        </AlignedContainer>
      </RowContainer>
      <SystemUpgradeReconfigAppsAlert />
      <SearchContainer>
        <StyledContainer>
          <SearchInput
            onChange={event => {
              updateQuery({
                search: event.target.value || undefined,
                skip: undefined
              })
            }}
            value={queryParams.search || ''}
            placeholder={msg`Search applications...`}
            data-qa="ApplicationsPageSearchApplicationField"
          />
        </StyledContainer>
        <StyledContainer>
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={state.table.totalResults}
            resultsPerPage={state.table.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </StyledContainer>
      </SearchContainer>
    </>
  )

  return (
    <>
      {header}
      <TableComponent
        qadata="ApplicationsList"
        hideHeaderWhenEmpty={true}
        table={state.table}
        getRecordLink={getRecordLink}
        recordLinkDestination={getRecordLinkDestination}
        noRecordsView={<ApplicationEmptyListComponent />}
        noResultsView={
          <TableView
            icon={<StyledNoAppIcon data-qa="NoAppsIcon" />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={
              <Trans>
                Try searching for different keywords and check your spelling for
                any typos.
              </Trans>
            }
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined
                  })
                }}
                data-qa="ClearSearchButton"
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: function ApplicationCellRenderer(record: Application) {
              return (
                <>
                  <StyledAppIcon src={record.logo.imageUrl} />
                  {record.name}
                  {isNopasswordMigrationEnabled && record.hasNpMigratedApp && (
                    <StyledAlertIcon data-qa="ReConfigAppAlert" />
                  )}
                </>
              )
            }
          }
        ]}
        isFilterApplied={isFilterApplied}
      />
    </>
  )
}

export function createApplicationsList<
  TState extends { applicationList: ApplicationListState }
>() {
  return connectRedux(
    ApplicationListComponent,
    (state: TState) => state.applicationList,
    applicationListActions
  )
}

export type ApplicationsList = ReturnType<typeof createApplicationsList>

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { enabledMultifactorOptionsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetEnabledMultifactorOptions(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getEnabledMultifactorOptions() {
    try {
      yield put(
        enabledMultifactorOptionsActions.setEnabledMultifactorOptionsLoading(
          true
        )
      )
      try {
        const enabledMultifactorOptionsResult: UACServices.EnabledMultifactorOptionsGetAPI.Responses = yield call(
          enterpriseOptionServices.enabledMultifactorOptionsGet
        )
        yield put(
          enabledMultifactorOptionsActions.setEnabledMultifactorOptions(
            enabledMultifactorOptionsResult.body.enabledMultifactorOptions
          )
        )
      } finally {
        yield put(
          enabledMultifactorOptionsActions.setEnabledMultifactorOptionsLoading(
            false
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

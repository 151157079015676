import { saveAs } from 'file-saver'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { pendingExportNotification } from '@lastpass/admin-console-dependencies/sagas/home/managed-companies/operations/helpers/pending-export-notification'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'
export const isExportManagedCompaniesLoadingSelector = (state: AppState) =>
  state.managedCompanyOperations.isExportManagedCompaniesLoading

export function createExportManagedCompaniesSaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*() {
    const isLoading = yield select(isExportManagedCompaniesLoadingSelector)
    if (isLoading) {
      yield put(globalActions.setNotification(pendingExportNotification))
      return
    }

    try {
      yield put(
        managedCompanyOperationActions.setIsExportManagedCompaniesLoading(true)
      )
      const result: UACServices.exportManagedCompaniesAPI.Responses = yield call(
        managedCompaniesServices.exportManagedCompanies
      )

      if (result.type !== UACServices.exportManagedCompaniesAPI.SUCCESS) {
        yield put(globalActions.setNotification(genericFailedNotification))
      }

      const csv = result.body
      const blobToSave = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      })
      yield call(saveAs, blobToSave, 'managed-companies.csv')
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(
        managedCompanyOperationActions.setIsExportManagedCompaniesLoading(false)
      )
    }
  }
}

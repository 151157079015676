export enum PermissionAreaType {
  dashboard = 'dashboard',
  users = 'users',
  managedCompanies = 'managedCompanies',
  addManagedCompany = 'addManagedCompany',
  suspendManagedCompany = 'suspendManagedCompany',
  detachManagedCompany = 'detachManagedCompany',
  managedCompanyLicenseManagement = 'managedCompanyLicenseManagement',
  configureManagedServiceProviderPsa = 'configureManagedServiceProviderPsa',
  usersMasterPasswordReset = 'usersMasterPasswordReset',
  usersPasswordsSettings = 'usersPasswordsSettings',
  usersSessionsDestroy = 'usersSessionsDestroy',
  groups = 'groups',
  directoriesAndFederation = 'directoriesAndFederation',
  sso = 'sso',
  legacyAndVpn = 'legacyAndVpn',
  passwordApps = 'passwordApps',
  sharedFolders = 'sharedFolders',
  mfa = 'mfa',
  policies = 'policies',
  generalReports = 'generalReports',
  reportingSsoLoginActivity = 'reportingSsoLoginActivity',
  reportingSamlResponse = 'reportingSamlResponse',
  reportingMfaUserActivity = 'reportingMfaUserActivity',
  reportingMfaAdminActivity = 'reportingMfaAdminActivity',
  advancedEnterpriseOptions = 'advancedEnterpriseOptions',
  advancedInstallSoftware = 'advancedInstallSoftware',
  emailNotifications = 'emailNotifications',
  advancedEnterpriseApi = 'advancedEnterpriseApi',
  keys = 'keys',
  userLevelMfa = 'userLevelMfa'
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import {
  BodyRegular,
  BodySemiboldStyle,
  Checkbox,
  TextInput,
  WithTooltip
} from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { companyHasMfa } from '@lastpass/admin-console-dependencies/services/get-company-entitlements'
import {
  CompanyDetails,
  CompanyInfoState
} from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { AdvancedSetupLastpass } from './AdvancedSetupLastpass'

const BodyText = styled.div`
  margin-top: 16px;
  margin-bottom: 11px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 9px;
`

const StyledLabel = styled.label<{ fluid?: boolean }>`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

export type SetupLastPassComponentProps = LastPassComponentProps<
  { companyDetails: CompanyDetails },
  typeof globalActions,
  {
    formikProps
    userAttributes
    selectedAppId
  }
>

interface StepUpCheckBoxProps {
  formikProps
  onChange?: Function
  onClick?: Function
}

const StepUpCheckBoxComponent: React.FunctionComponent<StepUpCheckBoxProps> = ({
  formikProps,
  onChange,
  onClick
}) => {
  let cssString
  if (onClick) {
    cssString = css`
      opacity: 50%;
    `
  }
  return (
    <>
      <StyledLabel>
        <Trans>Step-up authentication</Trans>
        <CheckboxContainer
          css={cssString}
          onClick={() => {
            onClick && onClick()
          }}
        >
          <Checkbox
            data-qa={'StepUpCheckbox'}
            name={'stepUp'}
            checked={formikProps.values.stepUp}
            onChange={() => {
              onChange && onChange()
            }}
            disabled={!!cssString}
          >
            <Trans>Always require LastPass MFA upon login to this app</Trans>
          </Checkbox>
          <WithTooltip
            tooltip={
              <Trans>
                Force users to confirm their identity using the LastPass{' '}
                Authenticator app upon each login to this app.
              </Trans>
            }
            placement="bottom"
          >
            <StyledHelpIcon />
          </WithTooltip>
        </CheckboxContainer>
      </StyledLabel>
    </>
  )
}

export const SetupLastPassComponent: React.FunctionComponent<SetupLastPassComponentProps> = ({
  state,
  actions,
  props
}) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )
  useEffect(() => {
    props.formikProps.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let stepUpComp = (
    <StepUpCheckBoxComponent
      formikProps={props.formikProps}
      onClick={() => {
        if (!CompanyDetailsHelper.isUserBusinessTrialEligible(companyDetails)) {
          actions.showMfaAddonUpsellDialog(
            MfaUpsellDialogContext.ssoAppDrawer,
            CompanyDetailsHelper.isEcommEnabled(companyDetails)
          )
        } else {
          actions.showMfaUpsellDialog(
            CompanyDetailsHelper.isEcommEnabled(companyDetails),
            MfaUpsellDialogContext.ssoAppDrawer
          )
        }
      }}
    />
  )

  if (companyHasMfa(state.companyDetails)) {
    stepUpComp = (
      <StepUpCheckBoxComponent
        formikProps={props.formikProps}
        onChange={() =>
          props.formikProps.setFieldValue(
            'stepUp',
            !props.formikProps.values.stepUp
          )
        }
      />
    )
  }
  return (
    <>
      <BodyRegular>
        <BodyText>
          <Trans>
            LastPass needs to know the app’s URI to the Assertion Consumer
            Service (ACS) to be able to authenticate users. This is provided by
            the app.
          </Trans>
        </BodyText>
      </BodyRegular>
      <FormContainer>
        <InputContainer>
          <Field data-qa="ACSInput" name="ACS" enableReinitialize={true}>
            {formData => (
              <TextInput
                toolTip={i18n._(
                  msg`The URL to which LastPass sends authentication assertions after authenticating a user. It may also be known as Post-Back URL, Reply URL, Single Sign-On URL, or Service Provider URL.`
                )}
                error={props.formikProps.errors.ACS}
                errorText={props.formikProps.errors.ACS}
                name={formData.field.name}
                value={formData.field.value}
                placeholder={msg`e.g. https://my.example.com`}
                onChange={e => formData.field.onChange(e)}
                data-qa={'ASCInputField'}
              >
                <Trans>ACS</Trans>
              </TextInput>
            )}
          </Field>
        </InputContainer>
        <InputContainer>{stepUpComp}</InputContainer>
        <AdvancedSetupLastpass
          formikProps={props.formikProps}
          userAttributes={props.userAttributes}
        />
      </FormContainer>
    </>
  )
}

export function createSetupLastPass<
  TState extends { companyInfo: CompanyInfoState }
>() {
  return connectRedux(
    SetupLastPassComponent,
    (state: TState) => {
      return {
        companyDetails: state.companyInfo.details
      }
    },
    globalActions
  )
}

export type SetupLastPass = ReturnType<typeof createSetupLastPass>

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import {
  BodySemibold,
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationRedirect } from '@lastpass/routing'

import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'

import { calcluateAddress, defaultRadius, MapComponent } from './MapComponent'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 16px;
`

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 24px;
`
const StyledTextArea = styled.textarea`
  background: ${props => props.theme.colors.white};
  height: 80px;
  width: 537px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const StyledLabel = styled(BodySemibold)`
  color: ${props => props.theme.colors.neutral600};
`

export interface MapPageProps {
  closeLink: string
}

interface FormData {
  address: string | null
  center: {
    lat: number
    lng: number
  } | null
  radius: number | null
  policyNotes?: string
}

function canSubmit(initialData: FormData, currentFormData: FormData): boolean {
  return (
    !!currentFormData.center &&
    !(
      initialData.center &&
      initialData.center.lat === currentFormData.center.lat &&
      initialData.center.lng === currentFormData.center.lng &&
      initialData.radius === currentFormData.radius &&
      initialData.policyNotes === currentFormData.policyNotes
    )
  )
}

const inMeters = (inKilometers: number): number => inKilometers * 1000

const inKilometers = (inMeter: number | null): number =>
  (inMeter ? inMeter : 0) / 1000

const inMiles = (inMeter: number): number => inKilometers(inMeter) / 1.61

export const MapPage: React.FunctionComponent<MapPageProps> = ({
  closeLink
}) => {
  const advancedPolicyDrawerState: AdvancedPolicyDrawerState = useSelector(
    (state: AppState) => state.advancedPolicyDrawer
  )
  const dispatch = useDispatch()

  const history = useHistory()
  if (advancedPolicyDrawerState.policy === null) {
    return <LocationRedirect to={closeLink} />
  }

  const isAdding = typeof advancedPolicyDrawerState.dataIndex === 'undefined'

  const policyDescriptionLines = advancedPolicyDrawerState.policy.policyDescription
    .split('<br/>')
    .filter(line => line.trim().length > 0)

  const [latitude, longitude, radius] = advancedPolicyDrawerState.policyValue
    ? advancedPolicyDrawerState.policyValue
        .split(',')
        .map(value => Number(value))
    : [null, null, null]

  const initialValues: FormData =
    !isAdding && latitude && longitude && radius
      ? {
          address: `${latitude}, ${longitude}`,
          center: { lat: latitude, lng: longitude },
          radius: inMeters(radius),
          policyNotes: advancedPolicyDrawerState.policyNotes
        }
      : {
          address: null,
          center: null,
          radius: null,
          policyNotes: advancedPolicyDrawerState.policyNotes
        }

  const handleSubmit = (values: FormData) => {
    dispatch(
      advancedPolicyDrawerActions.setPolicyConfig(
        values.policyNotes,
        values.center
          ? `${values.center.lat},${values.center.lng},${inKilometers(
              values.radius
            )}`
          : null
      )
    )
    isAdding
      ? dispatch(
          advancedPolicyDrawerActions.nextStep(AdvancedPolicyDrawerSteps.assign)
        )
      : dispatch(advancedPolicyDrawerActions.savePolicyConfig(closeLink))
  }

  const getDisplayValue = (radius: number | null): JSX.Element | string =>
    radius ? (
      <Trans>
        {inKilometers(radius).toFixed(2)} km ({inMiles(radius).toFixed(2)} mi)
      </Trans>
    ) : (
      '-'
    )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {formikProps => (
        <>
          <Heading100
            css={css`
              margin-bottom: 16px;
            `}
          >
            {advancedPolicyDrawerState.policy !== null
              ? advancedPolicyDrawerState.policy.policyTitle
              : ''}
          </Heading100>
          <div>
            {policyDescriptionLines.map((line, index) => (
              <p key={index}> {line}</p>
            ))}
          </div>
          <br />
          <FormContainer>
            <StyledLabel>
              <Trans>Set your trusted location</Trans>
            </StyledLabel>
            <MapComponent
              apiKey={defaultConfig.googleApiKey}
              center={formikProps.values.center}
              radius={formikProps.values.radius}
              onMapClicked={(lat, lng) => {
                calcluateAddress(lat, lng).then(response => {
                  formikProps.setFieldValue('address', response)
                })
                formikProps.setFieldValue('center', { lat, lng })
                formikProps.setFieldValue(
                  'radius',
                  formikProps.values.radius || defaultRadius
                )
              }}
              onCircleMoved={(lat, lng) => {
                calcluateAddress(lat, lng).then(response => {
                  formikProps.setFieldValue('address', response)
                })
                formikProps.setFieldValue('center', { lat, lng })
                formikProps.setFieldValue(
                  'radius',
                  formikProps.values.radius || defaultRadius
                )
              }}
              onRadiusChanged={e => {
                formikProps.setFieldValue('radius', e)
              }}
            />
            <StyledLabel>
              <Trans>Zone Information</Trans>
            </StyledLabel>
            <StyledLabel>
              <Trans>Location</Trans>
              {`: ` + (formikProps.values.address || '-')}
            </StyledLabel>
            <StyledLabel>
              <Trans>Radius</Trans>
              {': '}
              {getDisplayValue(formikProps.values.radius)}
            </StyledLabel>
            <br />
            <TextAreaContainer>
              <StyledLabel>
                <Trans>Notes</Trans>
              </StyledLabel>
              <Field>
                {(formData: FieldProps) => {
                  return (
                    <StyledTextArea
                      data-qa="policyNotes"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                    />
                  )
                }}
              </Field>
            </TextAreaContainer>
          </FormContainer>
          <StyledSpacer />
          <StyledFormFooter>
            <ButtonsContainer>
              <TextButton
                css={css`
                  margin-right: 16px;
                `}
                key="Discard"
                onClick={() => history.push(closeLink)}
                type="button"
                data-qa="DiscardButton"
              >
                <Trans>Cancel</Trans>
              </TextButton>
              <PrimaryButton
                id="ContinueButton"
                key="Continue"
                onClick={() => {
                  handleSubmit(formikProps.values)
                }}
                disabled={!canSubmit(initialValues, formikProps.values)}
                type="submit"
                data-qa="ContinueButton"
              >
                {isAdding ? (
                  <Trans>Continue</Trans>
                ) : (
                  <Trans>Save {'&'} exit</Trans>
                )}
              </PrimaryButton>
            </ButtonsContainer>
          </StyledFormFooter>
        </>
      )}
    </Formik>
  )
}

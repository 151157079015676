import _ from 'lodash'

import {
  AssignAdminLevelUsersActions,
  AssignAdminLevelUsersActionTypes
} from './actions'
import { AssignAdminLevelUsersDrawerState } from './state'

export const initialState: AssignAdminLevelUsersDrawerState = {
  users: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function assignAdminLevelUsersDrawerReducer(
  previousState: AssignAdminLevelUsersDrawerState | undefined,
  action: AssignAdminLevelUsersActions
): AssignAdminLevelUsersDrawerState {
  const state = previousState || initialState
  const users = state.users
  switch (action.type) {
    case AssignAdminLevelUsersActionTypes.RESET: {
      return initialState
    }
    case AssignAdminLevelUsersActionTypes.SET_ASSIGN_USER_LIST_ADMIN_LEVEL: {
      return {
        ...state,
        users: {
          ...users,
          results: action.payload.users,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          isLoading: false
        }
      }
    }
    case AssignAdminLevelUsersActionTypes.CLEAR_USER_LIST_ADMIN_LEVEL: {
      return {
        ...state,
        users: initialState.users
      }
    }
    case AssignAdminLevelUsersActionTypes.SET_ADMIN_LEVEL_USERS_ASSIGN_TABLE_LOADING: {
      return {
        ...state,
        users: {
          ...users,
          isLoading: action.payload.loading
        }
      }
    }

    case AssignAdminLevelUsersActionTypes.ADD_SELECTED_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          ...users,
          checkedRecords: users.checkedRecords.concat(action.payload.userList)
        }
      }
    }
    case AssignAdminLevelUsersActionTypes.REMOVE_SELECTED_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          ...users,
          checkedRecords: _.differenceWith(
            users.checkedRecords,
            action.payload.userList,
            _.isEqual
          )
        }
      }
    }
    case AssignAdminLevelUsersActionTypes.SET_SELECTED_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          ...users,
          checkedRecords: action.payload.userList
        }
      }
    }
  }
  return state
}

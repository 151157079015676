import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'

export enum TeamsPolicyStatus {
  all = 'all',
  enabled = 'enabled',
  disabled = 'disabled'
}

export enum TeamsPolicyValueType {
  checkbox = 'checkbox',
  textarea = 'textarea',
  adminusers = 'adminusers',
  restrictedadmin = 'restrictedadmins',
  none = 'none'
}

export enum TeamsPolicyCategories {
  default = 'default',
  recommended = 'recommended',
  /* eslint-disable-next-line */
  access_controls = 'access_controls',
  /* eslint-disable-next-line */
  password_rules = 'password_rules',
  /* eslint-disable-next-line */
  account_restrictions = 'account_restrictions',
  administration = 'administration',
  multifactor = 'multifactor',
  passwordless = 'passwordless',
  other = 'other'
}

export enum TeamsPolicyWithoutPasswordlessCategories {
  default = 'default',
  recommended = 'recommended',
  /* eslint-disable-next-line */
  access_controls = 'access_controls',
  /* eslint-disable-next-line */
  password_rules = 'password_rules',
  /* eslint-disable-next-line */
  account_restrictions = 'account_restrictions',
  administration = 'administration',
  multifactor = 'multifactor',
  other = 'other'
}

export interface TeamsPolicyUserInfo {
  uid: string
  email: string
  name: string
  parent: string
}

export interface TeamsPolicyGroup {
  id: string
  name: string
  userscount: number
}

export interface TeamsPolicyGroupResponse {
  cgid: string
  cid: string
  name: string
  description: string
  userscount: string
  score: string | null
}

export interface TeamsPolicyData {
  policyValue: string | null
  policyNotes: string
  cpid: number | null
  exclusiveList?: TeamsPolicyUserInfo[]
  inclusiveList?: TeamsPolicyUserInfo[]
  exclusiveGroupList?: TeamsPolicyGroup[]
  inclusiveGroupList?: TeamsPolicyGroup[]
}

export interface TeamsPolicyResponse {
  policy_key: string
  policyData?: TeamsPolicyData[]
  switchable: boolean
  category: string
  policy_type: string
  switched_on: boolean
  short_description: string
  description: string
  title: string
}

export interface TeamsPolicyModelData extends TeamsPolicyData {
  appliesTo: AppliesToOptions
}

export interface TeamsPolicy {
  policyKey: string
  policyData: TeamsPolicyModelData[]
  policyTitle: string
  policyShortDescription: string
  policyDescription: string
  policyValueType: TeamsPolicyValueType
  type?: string
  checked: boolean
  adminUsers?: string[]
  category: string
  switchable: boolean
  isChanged?: boolean
}

export interface TeamsPolicyResponseMap {
  [name: string]: TeamsPolicyResponse
}

export interface TeamsPolicyMap {
  [name: string]: TeamsPolicy
}

export interface SaveTeamsPolicySwitchOffData {
  policyKey: string
}

export interface SaveTeamsPolicySwitchOnData
  extends SaveTeamsPolicySwitchOffData {
  policyData: TeamsPolicyModelData[]
}

export interface InitialTeamsPolicyModelData {
  appliesTo: AppliesToOptions
  exclusiveList?: TeamsPolicyUserInfo[]
  inclusiveList?: TeamsPolicyUserInfo[]
  exclusiveGroupList?: TeamsPolicyGroup[]
  inclusiveGroupList?: TeamsPolicyGroup[]
}

export const paywalledPolicies = [
  'overridelastpassauthmethods',
  'lpauthenticatorgeoloc',
  'lpauthenticatorrequireextraauth',
  'restrictmfabytimerange'
]

export const advancedPolicies = [
  'overridelastpassauthmethods',
  'iprestrict',
  'countryrestrict',
  'restrictmfabytimerange',
  'lpauthenticatorgeoloc',
  'lpauthenticatorrequireextraauth'
]

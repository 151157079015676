import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'

export function createGetCompanyTemplatesSaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*() {
    yield put(managedCompaniesActions.setIsLoading(true))
    try {
      const result: UACServices.getCompanyTemplatesAPI.Responses = yield call(
        managedCompaniesServices.getCompanyTemplates
      )
      if (result.type === UACServices.getCompanyTemplatesAPI.SUCCESS) {
        yield put(managedCompaniesActions.setCompanyTemplates(result.body))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(managedCompaniesActions.setIsLoading(false))
    }
  }
}

import React, { ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { SecurityScoreBadge, WithTooltip } from '@lastpass/lastkit'

import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import { EnumDictionary } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'
import { FederatedStatusBadge } from '@lastpass/admin-console-dependencies/ui/users/view/FederatedStatusBadge'
import { MfaBadge } from '@lastpass/admin-console-dependencies/ui/users/view/MfaBadge'
import { StatusBadge } from '@lastpass/admin-console-dependencies/ui/users/view/StatusBadge'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'
import {
  AdminLevelTooltip,
  FederatedStatusTooltip,
  MasterPasswordResetRightsTooltip,
  MfaStatusTooltip,
  MfaTooltip,
  SecurityScoreTooltip,
  StatusTooltip
} from '@lastpass/admin-console-dependencies/ui/users/view/UserListTooltips'

import { MultifactorProviderIconMapping } from '../MultifactorProviderMapping'

const StyledHeader = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
`

export const getIsCreatedByCompanyText = (
  isCreatedByCompany: User['isCreatedByCompany']
): ReactElement => {
  switch (isCreatedByCompany) {
    case true:
      return <Trans>Yes</Trans>
    case false:
      return <Trans>No</Trans>
    default:
      return <Trans>N/A</Trans>
  }
}

export const UserColumnMapping: EnumDictionary<
  UserListTableColumn,
  TableColumn<User>
> = {
  [UserListTableColumn.email]: {
    name: <Trans>Email</Trans>,
    renderer: (record: User) => record.email,
    columnOrderByName: 'email',
    headerName: UserListTableColumn[Number(UserListTableColumn.email)]
  },
  [UserListTableColumn.name]: {
    name: <Trans>Name</Trans>,
    renderer: (record: User) => getFullName(record),
    columnOrderByName: 'fullName',
    headerName: UserListTableColumn[Number(UserListTableColumn.name)]
  },
  [UserListTableColumn.lastpassStatus]: {
    name: (
      <StyledHeader>
        <span className={'onboarding-tour-step-2'}>
          <Trans>LastPass status</Trans>
        </span>
        <WithTooltip tooltip={<StatusTooltip />} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function StatusBadgerenderer(record: User) {
      return (
        <span className={'onboarding-tour-step-2'}>
          <StatusBadge status={record.accountStatus} />
        </span>
      )
    },
    noTooltip: true,
    columnOrderByName: 'accountStatus',
    headerName: UserListTableColumn[Number(UserListTableColumn.lastpassStatus)]
  },
  [UserListTableColumn.mfaStatus]: {
    name: (
      <StyledHeader>
        <Trans>Passwordless status</Trans>
        <WithTooltip tooltip={<MfaStatusTooltip />} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function MfaBadgeRenderer(record: User) {
      return <MfaBadge status={record.mfaStatus} />
    },
    noTooltip: true,
    columnOrderByName: 'mfaStatus',
    headerName: UserListTableColumn[Number(UserListTableColumn.mfaStatus)]
  },
  [UserListTableColumn.lastInviteEmail]: {
    name: <Trans>Last invitation</Trans>,
    renderer: function LastInviteRenderer(record: User) {
      return (
        <TimeSinceActivity lastActivityTime={record.inviteEmailSentTimeUtc} />
      )
    },
    noTooltip: true,
    columnOrderByName: 'inviteEmailSentTimeUtc',
    headerName: UserListTableColumn[Number(UserListTableColumn.lastInviteEmail)]
  },
  [UserListTableColumn.defaultMultifactor]: {
    name: (
      <StyledHeader>
        <Trans> Enabled multifactor</Trans>
        <WithTooltip tooltip={<MfaTooltip />} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function UserLevelRenderer(record: User) {
      return (
        <>
          {record.defaultMultiFactorType
            ? MultifactorProviderIconMapping[record.defaultMultiFactorType]
            : ''}
        </>
      )
    },
    noTooltip: true,
    columnOrderByName: 'defaultMultiFactorType',
    headerName:
      UserListTableColumn[Number(UserListTableColumn.defaultMultifactor)]
  },
  [UserListTableColumn.lastLogin]: {
    name: <Trans>Last login</Trans>,
    noTooltip: true,
    renderer: function LastLoginRenderer(record: User) {
      return <TimeSinceActivity lastActivityTime={record.lastLoginTimeUtc} />
    },
    headerName: UserListTableColumn[Number(UserListTableColumn.lastLogin)]
  },
  [UserListTableColumn.securityScore]: {
    name: (
      <StyledHeader>
        <Trans>Security score</Trans>
        <WithTooltip tooltip={<SecurityScoreTooltip />} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function SecurityScoreRenderer({
      securityScore,
      securityChallengeScore
    }: User) {
      const score =
        typeof securityChallengeScore === 'number'
          ? securityChallengeScore
          : undefined
      return securityScore ? (
        <SecurityScoreBadge level={securityScore} score={score} />
      ) : (
        <Trans>N/A</Trans>
      )
    },
    columnOrderByName: 'securityChallengeScore',
    headerName: UserListTableColumn[Number(UserListTableColumn.securityScore)]
  },
  [UserListTableColumn.lastPasswordChange]: {
    name: <Trans>Last master password change</Trans>,
    noTooltip: true,
    renderer: function LastPasswordChangeRenderer(record: User) {
      return (
        <TimeSinceActivity
          lastActivityTime={record.lastMasterPasswordChangeTimeUtc}
        />
      )
    },
    columnOrderByName: 'lastMasterPasswordChangeTimeUtc',
    headerName:
      UserListTableColumn[Number(UserListTableColumn.lastPasswordChange)]
  },
  [UserListTableColumn.adminLevel]: {
    name: (
      <StyledHeader>
        <Trans>Admin level</Trans>
        <WithTooltip tooltip={<AdminLevelTooltip />} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function AdminLevelRenderer(record: User) {
      return <UserLevelLabel userLevel={record.adminLevel} />
    },
    columnOrderByName: 'adminLevelId',
    headerName: UserListTableColumn[Number(UserListTableColumn.adminLevel)]
  },
  [UserListTableColumn.provisionFederatedStatus]: {
    name: (
      <StyledHeader>
        <Trans>Federated status</Trans>
        <WithTooltip tooltip={<FederatedStatusTooltip />} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function FederatedStatusRenderer(record: User) {
      return <FederatedStatusBadge status={record.provisionFederatedStatus} />
    },
    columnOrderByName: 'provisionFederatedStatus',
    noTooltip: true,
    headerName:
      UserListTableColumn[Number(UserListTableColumn.provisionFederatedStatus)]
  },
  [UserListTableColumn.superAdminKeyOwnershipForUser]: {
    name: (
      <StyledHeader>
        <Trans>Master password reset rights</Trans>
        <WithTooltip
          tooltip={<MasterPasswordResetRightsTooltip />}
          width="450px"
        >
          <StyledHelpIcon />
        </WithTooltip>
      </StyledHeader>
    ),
    renderer: function MasterPasswordResetRightsRenderer(record: User) {
      if (
        record.superAdminKeyOwnershipForUser.hasActingAdmin &&
        !record.superAdminKeyOwnershipForUser.hasOthers
      )
        return <Trans>You</Trans>
      else if (
        !record.superAdminKeyOwnershipForUser.hasActingAdmin &&
        record.superAdminKeyOwnershipForUser.hasOthers
      )
        return <Trans>Others</Trans>
      else if (
        record.superAdminKeyOwnershipForUser.hasActingAdmin &&
        record.superAdminKeyOwnershipForUser.hasOthers
      )
        return <Trans>You and others</Trans>
      else return <Trans>Nobody</Trans>
    },
    noTooltip: true
  },
  [UserListTableColumn.isCreatedByCompany]: {
    name: <Trans>Created by company</Trans>,
    noTooltip: true,
    renderer: function Renderer({ isCreatedByCompany }: User) {
      return getIsCreatedByCompanyText(isCreatedByCompany)
    },
    columnOrderByName: 'isCreatedByCompany',
    headerName:
      UserListTableColumn[Number(UserListTableColumn.isCreatedByCompany)]
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { Policy } from '@lastpass/admin-console-dependencies/types/policy'

export const SUCCESS = 'success'

const success = (policies: Policy[], totalResults: number) =>
  createResponse(SUCCESS, { policies, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(groupId: string, queryPath: string) {
    const [response, status] = await fetch(
      'groups/' + groupId + '/policies' + queryPath,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const policies = JSON.parse(response).content.items
      const totalResults = JSON.parse(response).content.totalCount
      return success(policies, totalResults)
    }
    throw new UnknownServiceError()
  }
}

import React, { FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { Loading } from '@lastpass/components'
import {
  CaptionRegularStyle,
  Chart,
  ChartItem,
  DateRangeDropdown,
  EmptyChart,
  WithTooltip
} from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import {
  DashboardState,
  UserAdoptionHistoryItem
} from '@lastpass/admin-console-dependencies/state/dashboard/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

type UserAdoptionHistoryChartProps = LastPassComponentProps<
  DashboardState,
  typeof dashboardActions
>

const RowSpacer = styled.div<{ isUtilizationDashboardEnabled: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.isUtilizationDashboardEnabled ? 'flex-end' : 'space-between'};
`

const Container = styled.div`
  display: block;
  width: 100%;
`

const Header = styled.h6`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
`

const UpdatedLabel = styled.label`
  ${CaptionRegularStyle}
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
`

export const ChartTop = styled.div`
  display: flex;
  align-items: center;
`

export const UpdatedComponent: FunctionComponent<{
  updateTime?: Date
}> = props => {
  return (
    <>
      {props.updateTime && (
        <UpdatedLabel>
          <Trans>
            Updated:{' '}
            {moment
              .utc(props.updateTime)
              .local()
              .format('L LT')}
          </Trans>
        </UpdatedLabel>
      )}
    </>
  )
}

const formatNumber = (num: number) =>
  (num || 0).toLocaleString(undefined, { maximumFractionDigits: 1 })

const getTooltipText = (item: UserAdoptionHistoryItem) =>
  `${formatNumber(item.enrollmentRate)}%`

export const UserAdoptionHistoryChartComponent: React.FunctionComponent<UserAdoptionHistoryChartProps> = ({
  state,
  actions
}) => {
  const enrollmentData = state.userAdoptionHistory
  const showChart = state.dashboardDisplaySettings.hasEnrolledUsers

  const history = useHistory()
  const isUtilizationDashboardEnabled = useFeatureFlags(
    FeatureFlags.isUtilizationDashboardEnabled
  )
  useEffect(() => {
    showChart &&
      actions.getUserAdoptionHistory(
        enrollmentData.startDate || null,
        enrollmentData.endDate || null
      )
  }, [actions, enrollmentData.endDate, enrollmentData.startDate, showChart])

  const chartItems: ChartItem[] = enrollmentData.items.map(
    (x): ChartItem => ({
      date: x.date,
      value: x.enrollmentRate,
      tooltipText: getTooltipText(x)
    })
  )

  const dateRangeCutOffDate = new Date('2021-03-16')

  return (
    <Container data-qa={'UserAdoptionHistoryChart'}>
      <RowSpacer isUtilizationDashboardEnabled={isUtilizationDashboardEnabled}>
        {!isUtilizationDashboardEnabled && (
          <Header
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Trans>Enrolled rate</Trans>
            <WithTooltip
              tooltip={
                <Trans>
                  How many invited users have activated their account
                  (enrolled)? How many have an active or expired invitation?
                  Updated daily.{' '}
                  <a
                    href="https://support.logmeininc.com/lastpass/help/what-are-the-enrollment-and-utilization-rates"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </Trans>
              }
            >
              <StyledHelpIcon />
            </WithTooltip>
          </Header>
        )}
        {showChart && (
          <ChartTop>
            <UpdatedComponent
              updateTime={state.userAdoptionHistory.lastUpdateDateTimeUtc}
            />
            <DateRangeDropdown
              defaultStartDate={enrollmentData.startDate}
              defaultEndDate={enrollmentData.endDate}
              setRange={(startDate, endDate) => {
                actions.setUserAdoptionHistoryPeriod(startDate, endDate)
              }}
              minDate={dateRangeCutOffDate}
            />
          </ChartTop>
        )}
      </RowSpacer>

      {enrollmentData.loading && showChart && (
        <Loading color={'blue900'} active={true} />
      )}
      {showChart ? (
        <Chart
          items={chartItems}
          tooltipText={'{tooltipText}'}
          height={'300px'}
          minValue={0}
          maxValue={100}
          minDateValue={enrollmentData.startDate}
        />
      ) : (
        <EmptyChart
          title={<Trans>Track your enrollment rate</Trans>}
          subtitle={
            <Trans>
              See the ratio of invited users to those who have activated their
              account.
            </Trans>
          }
          button={{
            text: <Trans>Add users </Trans>,
            onClick: () => {
              history.push('/dashboard/add')
            }
          }}
        />
      )}
    </Container>
  )
}

export function createUserAdoptionHistoryChartComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    UserAdoptionHistoryChartComponent,
    (state: TState) => state.dashboard,
    dashboardActions
  )
}

export type UserAdoptionHistoryChart = ReturnType<
  typeof createUserAdoptionHistoryChartComponent
>

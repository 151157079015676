import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { processFailedResult } from '@lastpass/admin-console-dependencies/sagas/applications/saml/application-common'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/save-application-server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { SelectedApplication } from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { applicationListActions } from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  addIdAndNameToSavedApp,
  mapFormFieldsToAppProps
} from '@lastpass/admin-console-dependencies/ui/common/mappings/ssoapp-mappers'

export const getCurrentUrlFilters = (state: AppState) => state.router
export const getTestLoginStatus = (state: AppState) =>
  state.applicationDrawer.testLoginSuccessState

function* processSucceededResult(
  currentUrlFilters,
  application,
  editAppResponse,
  action
) {
  if (action.payload.formValues.closeAfterSave) {
    yield put(
      applicationsDrawerActions.onSsoAppSavedWithTracking(
        application.name,
        application.isCustom
      )
    )
  } else {
    yield put(applicationsDrawerActions.onSsoAppSaved())
  }
  yield put(
    applicationsDrawerActions.setSelectedApplication(
      addIdAndNameToSavedApp(
        editAppResponse.body.response.Value.AppGUID,
        editAppResponse.body.response.Value.AppName,
        application
      )
    )
  )

  const message = application.id ? msg`App changes saved.` : msg`App added.`
  yield put(
    globalActions.setNotification({
      message: message,
      type: NotificationType.success,
      autoDismiss: true
    })
  )

  yield put(
    applicationListActions.getApplicationList({
      query: getQueryParams(currentUrlFilters.location, 'apps'),
      path: {}
    })
  )
  const testLoginStepShouldBeDisplayed = yield select(getTestLoginStatus)

  if (action.payload.formValues.closeAfterSave) {
    yield put(push(action.payload.formValues.closeLink))
  } else if (testLoginStepShouldBeDisplayed) {
    yield put(
      applicationsDrawerActions.nextStep(ApplicationDrawerSteps.testLogin)
    )
  } else if (action.payload.isAnyEntityAssigned) {
    yield put(applicationsDrawerActions.nextStep(ApplicationDrawerSteps.assign))
  } else {
    yield put(
      applicationsDrawerActions.nextStep(ApplicationDrawerSteps.assignTestUser)
    )
  }
}

export function createAddApplication(
  applicationsServices: UACServices.Services
) {
  return function* addApplication(
    action: ReturnType<typeof applicationsDrawerActions.saveAndExit>
  ) {
    try {
      const currentUrlFilters = yield select(getCurrentUrlFilters)

      yield put(applicationsDrawerActions.setDrawerLoading(true))

      try {
        const application: SelectedApplication = mapFormFieldsToAppProps(
          action.payload.formValues,
          action.payload.app
        )
        const editAppResponse: Responses = yield call(
          applicationsServices.addSSOApplication,
          application
        )

        if (editAppResponse.type === UACServices.SSOApplicationsAPI.SUCCESS) {
          if (editAppResponse.body.response.Succeeded) {
            yield processSucceededResult(
              currentUrlFilters,
              application,
              editAppResponse,
              action
            )
          } else {
            yield processFailedResult(editAppResponse.body.response.ErrorCode)
          }
        }
      } finally {
        yield put(applicationsDrawerActions.setDrawerLoading(false))
        action.payload.finallyCallback()
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'

import { EnumDictionary } from '../EnumValues'

export const AdminLevelMapping: EnumDictionary<AdminLevel, ReactElement> = {
  [AdminLevel.user]: <Trans>User</Trans>,
  [AdminLevel.admin]: <Trans>Admin</Trans>,
  [AdminLevel.superAdmin]: <Trans>Super admin</Trans>,
  [AdminLevel.legacyHelpdeskAdmin]: <Trans>Legacy helpdesk admin</Trans>,
  [AdminLevel.helpdeskAdmin]: <Trans>Helpdesk admin</Trans>
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { unresponsiveUsersFilter } from './unresponsive-users/get-unresponsive-users'

export function createGetUserAdoptionUnresponsiveUsersSaga(
  usersServices: UACServices.Services
) {
  return function* getUserAdoptionHistorySaga() {
    try {
      const query = buildQuery({
        ...unresponsiveUsersFilter,
        top: 0
      })
      const result: UACServices.UsersAPI.Responses = yield call(
        usersServices.users,
        query
      )
      if (result.type === UACServices.UserAdoptionHistoryAPI.SUCCESS) {
        yield put(
          dashboardActions.setUnresponsiveUsers(result.body.totalResults)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

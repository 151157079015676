import React, { FC } from 'react'

import { msg } from '@lingui/macro'

import { ReactComponent as ExternalLinkIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/external-link.svg'

import { ActionLink } from '@lastpass/admin-console-dependencies/ui/home/components/action-link/ActionLink'

export interface LearnMoreLinkProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  dataQa?: string
}

export const LearnMoreLink: FC<LearnMoreLinkProps> = ({ onClick, dataQa }) => (
  <ActionLink
    icon={ExternalLinkIcon}
    text={msg`Learn how it’s calculated`}
    onClick={onClick}
    dataQa={dataQa && dataQa + 'LearnMoreAction'}
  />
)

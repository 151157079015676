import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as PolicyIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  ButtonsContainer,
  PrimaryButton,
  SearchInput,
  StyledFormFooter,
  StyledSpacer,
  TableView,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPoliciesListState } from '@lastpass/admin-console-dependencies/state/policies/general/list/state'
import {
  paywalledPolicies,
  TeamsPolicy,
  TeamsPolicyMap
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import { AddPolicyDrawerHome } from '@lastpass/admin-console-dependencies/ui/policies/general/AddPolicyDrawerHome'
import { PolicyCard } from '@lastpass/admin-console-dependencies/ui/policies/general/PolicyCard'
import { PolicyGroupCard } from '@lastpass/admin-console-dependencies/ui/policies/general/PolicyGroupCard'

const StyledSearchInput = styled(SearchInput)`
  margin-bottom: 15px;
`

const TopLevelContainer = styled.div`
  margin-top: 15px;
`
const StyledCardContainer = styled.div`
  margin-bottom: 15px;
`

const LoadingContainer = styled.div`
  margin: 16px 0;
  position: relative;
  min-height: 225px;
`

const StyledPolicyGroupCard = styled(PolicyGroupCard)`
  cursor: pointer;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  cursor: pointer;
`

const SpacedStyledTabNavigationItem = styled(StyledTabNavigationItem)`
  margin-left: 10px;
`

const PolicyContainer = styled.div`
  margin-top: 16px;
`

enum expansionGroups {
  default = 'default',
  recommended = 'recommended',
  /* eslint-disable-next-line */
  access_controls = 'access_controls',
  /* eslint-disable-next-line */
  password_rules = 'password_rules',
  /* eslint-disable-next-line */
  account_restrictions = 'account_restrictions',
  administration = 'administration',
  multifactor = 'multifactor',
  other = 'other'
}
const expansionGroupHeadingMap = {
  [expansionGroups.default]: <Trans>Default</Trans>,
  [expansionGroups.recommended]: <Trans>Recommended</Trans>,
  [expansionGroups.access_controls]: <Trans>Access controls</Trans>,
  [expansionGroups.password_rules]: <Trans>Password rules</Trans>,
  [expansionGroups.account_restrictions]: <Trans>Account restrictions</Trans>,
  [expansionGroups.administration]: <Trans>Administration</Trans>,
  [expansionGroups.multifactor]: <Trans>Multifactor</Trans>,
  [expansionGroups.other]: <Trans>Other</Trans>
}

export interface PolicyGroupsMap {
  [type: string]: ReactElement[]
}
export interface TabNavigationForAddPolicyProps {
  policyGroupMap: PolicyGroupsMap
  selectedTab: string
  setSelectedTab: (value: React.SetStateAction<string>) => void
}
const TabNavigationForAddPolicy: FunctionComponent<TabNavigationForAddPolicyProps> = ({
  policyGroupMap,
  selectedTab,
  setSelectedTab
}) => {
  return (
    <TabNavigation>
      {policyGroupMap[expansionGroups.default].length > 0 && (
        <div
          onClick={() => {
            setSelectedTab(expansionGroups.default)
          }}
        >
          <StyledTabNavigationItem
            active={selectedTab === expansionGroups.default}
          >
            <Trans>Default</Trans>
          </StyledTabNavigationItem>
        </div>
      )}
      {policyGroupMap[expansionGroups.recommended].length > 0 && (
        <div
          onClick={() => {
            setSelectedTab(expansionGroups.recommended)
          }}
        >
          <SpacedStyledTabNavigationItem
            active={selectedTab === expansionGroups.recommended}
          >
            <Trans>Recommended</Trans>
          </SpacedStyledTabNavigationItem>
        </div>
      )}
      <div
        onClick={() => {
          setSelectedTab('all')
        }}
      >
        <SpacedStyledTabNavigationItem active={selectedTab === 'all'}>
          <Trans>All</Trans>
        </SpacedStyledTabNavigationItem>
      </div>
    </TabNavigation>
  )
}

function doNotDisplayPolicy(policyKey: string) {
  return !paywalledPolicies.includes(policyKey)
}

const populatePolicyGroupMap = (
  policyGroupMap: PolicyGroupsMap,
  policyMap: TeamsPolicyMap,
  setSelectedPolicy: (
    value: React.SetStateAction<TeamsPolicy | undefined>
  ) => void,
  searchterm: string,
  selectedPolicy: TeamsPolicy | undefined
) => {
  if (Object.values(policyMap).length > 0) {
    const expansionGroupsValues: string[] = Object.values(expansionGroups)

    Object.values(policyMap)
      .filter(policy => doNotDisplayPolicy(policy.policyKey))
      .forEach((value: TeamsPolicy) => {
        if (value.category && expansionGroupsValues.includes(value.category)) {
          if (searchterm != '' && searchterm != undefined) {
            if (
              value.policyTitle.toLowerCase().includes(searchterm.toLowerCase())
            ) {
              policyGroupMap[value.category].push(
                <PolicyCard
                  key={value.policyKey}
                  highlight={selectedPolicy === value}
                  onHighlight={(policyValue: TeamsPolicy) => {
                    setSelectedPolicy(policyValue)
                  }}
                  value={value}
                />
              )
            }
          } else {
            policyGroupMap[value.category].push(
              <div key={value.policyKey}>
                <PolicyCard
                  highlight={selectedPolicy === value}
                  onHighlight={(allPolicyValue: TeamsPolicy) => {
                    setSelectedPolicy(allPolicyValue)
                  }}
                  value={value}
                />
              </div>
            )
          }
        }
      })
  }
}

export const AddPolicyDrawerPage: FunctionComponent = () => {
  const generalPoliciesListState: GeneralPoliciesListState = useSelector(
    (state: AppState) => state.generalPoliciesList
  )

  const queryParams = useQueryParams(Namespace.policies)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      generalPoliciesListActions.getGeneralPoliciesList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])
  const expand = {
    [expansionGroups.default]: false,
    [expansionGroups.recommended]: false,
    [expansionGroups.access_controls]: false,
    [expansionGroups.password_rules]: false,
    [expansionGroups.account_restrictions]: false,
    [expansionGroups.administration]: false,
    [expansionGroups.multifactor]: false,
    [expansionGroups.other]: false
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const policyGroupMap: PolicyGroupsMap = {}

  Object.keys(expansionGroups).forEach(groupName => {
    const result: ReactElement[] = []
    policyGroupMap[groupName] = result
  })

  const [searchterm, setSearchTerm] = useState<string>('')
  const [selectedPolicy, setSelectedPolicy] = useState<TeamsPolicy>()
  const [selectedTab, setSelectedTab] = useState<string>('all')

  populatePolicyGroupMap(
    policyGroupMap,
    generalPoliciesListState.policyMap,
    setSelectedPolicy,
    searchterm,
    selectedPolicy
  )

  useEffect(() => {
    if (searchterm != '' && searchterm != undefined) {
      if (
        (selectedTab === expansionGroups.default &&
          policyGroupMap[expansionGroups.default].length === 0) ||
        (selectedTab === expansionGroups.recommended &&
          policyGroupMap[expansionGroups.recommended].length === 0)
      ) {
        setSelectedTab('all')
      }
    }
  }, [searchterm, policyGroupMap, selectedTab])

  const history = useHistory()
  const expandRef = useRef(expand)
  const onExpand = (expandProp: expansionGroups) => () => {
    expandRef.current[expandProp] = !expandRef.current[expandProp]
  }

  const numberOfMatchingPolicies = Object.keys(policyGroupMap)
    .map(groupType => policyGroupMap[groupType].length)
    .reduce((a, b) => a + b)

  return (
    <>
      {generalPoliciesListState.table.isLoading ? (
        <LoadingContainer>
          <Loading color="blue900" active={true} />
        </LoadingContainer>
      ) : (
        <AddPolicyDrawerHome>
          <div>
            <StyledSearchInput
              onChange={event => {
                setSearchTerm(event.target.value)
              }}
              value={searchterm}
              placeholder={msg`Search policies...`}
              data-qa={'PolicyDrawerSearchField'}
            />
            <TabNavigationForAddPolicy
              policyGroupMap={policyGroupMap}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
          <PolicyContainer>
            {selectedTab === expansionGroups.default && (
              <TopLevelContainer>
                {policyGroupMap[expansionGroups.default]}
              </TopLevelContainer>
            )}
            {selectedTab === expansionGroups.recommended && (
              <TopLevelContainer>
                {policyGroupMap[expansionGroups.recommended]}
              </TopLevelContainer>
            )}
            {numberOfMatchingPolicies === 0 && (
              <TableView
                small
                icon={<PolicyIcon />}
                title={<Trans>Sorry, no results match your search</Trans>}
                text={
                  <Trans>
                    Try searching for different keywords and check your spelling
                    for any typos.
                  </Trans>
                }
              />
            )}
            {selectedTab === 'all' &&
              Object.keys(policyGroupMap).map((groupType, index) => {
                return (
                  <StyledCardContainer key={`${groupType}-${index}`}>
                    {policyGroupMap[groupType].length > 0 && (
                      <StyledPolicyGroupCard
                        expand={expandRef.current[groupType]}
                        onExpand={onExpand(groupType as expansionGroups)}
                        title={expansionGroupHeadingMap[groupType]}
                      >
                        {policyGroupMap[groupType]}
                      </StyledPolicyGroupCard>
                    )}
                  </StyledCardContainer>
                )
              })}
          </PolicyContainer>
        </AddPolicyDrawerHome>
      )}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key={'Cancel'}
            type="button"
            onClick={() => {
              history.push('/policies/generalpolicies')
            }}
            data-qa={'CancelButton'}
          >
            <Trans>Cancel</Trans>
          </TextButton>
          <PrimaryButton
            type="submit"
            key={'Continue'}
            disabled={selectedPolicy === undefined}
            data-qa={'ContinueButton'}
            onClick={() => {
              selectedPolicy
                ? history.push(
                    '/policies/generalpolicies/' + selectedPolicy.policyKey
                  )
                : history.push('/policies/generalpolicies')
            }}
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

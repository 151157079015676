import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as Triangle } from '@lastpass/assets/svg/admin-console/icon-warning-triangle.svg'

import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'

const CustomTriangle = styled(Triangle)`
  width: 15px;
  height: 15px;
  margin: 0px 6px -2px 0px;
`

const Background = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.red800};
`

const Message = styled.div`
  padding: 8px 0;
  color: ${props => props.theme.colors.white};
  font-weight: ${props => props.theme.fonts.weight.semibold};
  font-size: 12px;
  line-height: 16px;
`

export const ErrorMessage: React.FunctionComponent = () => {
  const text = i18n._(
    msg`Something went wrong. Please refresh the page and try again.`
  )
  return (
    <Background>
      <Message>
        <CustomTriangle /> {text}
      </Message>
    </Background>
  )
}

export const GlobalAlert: React.FunctionComponent = () => {
  const globalState: GlobalState = useSelector(
    (state: AppState) => state.global
  )
  return globalState.alert.showError ? <ErrorMessage /> : null
}

import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { saveAs } from 'file-saver'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotAllUrlDataCouldBeDecryptedDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface NotAllUrlDataCouldBeDecryptedDialogProps {
  state: NotAllUrlDataCouldBeDecryptedDialogType
}

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

export const NotAllUrlDataCouldBeDecryptedDialog: FunctionComponent<NotAllUrlDataCouldBeDecryptedDialogProps> = ({
  state: { numberOfDecryptedUrls, numberOfUrls: numberOfEncryptedUrls, csvFile }
}) => {
  const dispatch = useDispatch()

  const { downloadReportName } = useSelector(
    (state: AppState) => state.securityReportDrawer
  )

  const handleClick = () => {
    saveAs(csvFile, `${downloadReportName}.csv`)
    dispatch(globalActions.emptyDialog())
  }

  const content = (
    <>
      <StyledParagraph>
        <Trans>
          We ran into a problem while decrypting URL data in your report. Some
          records were successfully decrypted ({numberOfDecryptedUrls} /{' '}
          {numberOfEncryptedUrls}).
        </Trans>
      </StyledParagraph>
      <StyledParagraph>
        <Trans>
          Pro tip: For best results when decrypting URLs, ask a super admin with
          more recovery keys to share theirs with you invisibly before you start
          the process.
        </Trans>
      </StyledParagraph>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      width="650px"
      title={msg`Not all URL data could be decrypted`}
      titleColor="red"
      disableCloseOnBackground
      content={content}
      buttons={[
        <TextButton
          onClick={handleClick}
          key="discard"
          data-qa="NotAllUrlDataCouldBeDecryptedDialogCancelButton"
        >
          <Trans>Download decryption results</Trans>
        </TextButton>,
        <PrimaryButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          data-qa="NotAllUrlDataCouldBeDecryptedDialogConfirmButton"
        >
          <Trans>Ok</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { invokeApi } from './operation-base'

export function createLockDeviceForLPMFASaga(
  userService: UACServices.Services
) {
  return function* lockDeviceForLPMFASaga(
    action: ReturnType<typeof userOperationActions.lockDeviceForLPMFAAction>
  ) {
    try {
      const users = action.payload.userOperationPayload.users

      let text: MessageDescriptor = msg`Lock the LastPass Authenticator app for the selected users?`
      if (users.length === 1) {
        text = msg`Lock the LastPass Authenticator app for this user? ${users[0].email}`
      }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Lock LastPass MFA device`,
        text,
        discardText: msg`Cancel`,
        confirmText: msg`Lock`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`The LastPass Authenticator app hasn't been locked for the selected users.`,
            MultipleUsersSuccess: msg`The LastPass Authenticator app has been locked for the selected users.`,
            SingleUserFail:
              users.length > 0
                ? msg`The LastPass Authenticator app hasn't been locked for this user: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`The LastPass Authenticator app has been locked for this user: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`The LastPass Authenticator app hasn't been locked for some users.`
          },
          'locklastpassmfadevice',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.LOCK_DEVICE_FOR_LPMFA,
          action.payload.userOperationPayload.filter
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

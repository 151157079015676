import { StatusCodes } from 'http-status-codes'

export class KeyRotationError extends Error {
  feErrorCode: string
  sessionToken: string
  httpErrorCode: StatusCodes | string
  constructor(errorDetails: {
    feErrorCode: string
    sessionToken?: string
    httpErrorCode?: StatusCodes
  }) {
    super(errorDetails.feErrorCode)
    this.feErrorCode = errorDetails.feErrorCode
    this.sessionToken = errorDetails.sessionToken || ''
    this.httpErrorCode = errorDetails.httpErrorCode || ''
  }
}

import React, { ReactElement } from 'react'
import { useHistory } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { CardContainer, PrimaryButton } from '@lastpass/lastkit'
import { StyledFormFooter } from '@lastpass/lastkit/components/Drawer'

export const DrawerContainer = styled.div`
  margin-bottom: 60px;
`

export const DetailsContainer = styled(CardContainer)`
  padding-top: 16px;
  margin-bottom: 16px;
`

export const DetailsBoxTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 0 16px;
  font-weight: bold;
`

export const DetailsBoxBody = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 12px 16px 12px 16px;
`

export const Footer = styled(StyledFormFooter)`
  border-top: 1px solid ${props => props.theme.colors.neutral200};
  text-align: right;
`

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 25px;
`

export const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
`

const StyledPath = styled.span`
  font-weight: 600;
`

const StyledList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 10px;
  color: ${props => props.theme.colors.blue700};
`

export const NotAvailableContainer = styled.label`
  color: ${props => props.theme.colors.neutral500};
`

export interface DetailsBoxProps {
  title: ReactElement
}

export interface SystemUpgradeDrawerProps {
  closeLink: string
}

export interface SystemUpgradeDrawerFooterProps {
  closeLink: string
}

export interface SystemUpgradeDrawerStyledLinkProps {
  dataQa: string
  url: string
}

export interface LegacyConsolePathProps {
  isNotAvailable: boolean
  path?: ReactElement
}

export const DetailsBox: React.FunctionComponent<DetailsBoxProps> = ({
  title,
  children
}) => {
  return (
    <DetailsContainer>
      <DetailsBoxTitle>{title}</DetailsBoxTitle>
      <DetailsBoxBody>{children}</DetailsBoxBody>
    </DetailsContainer>
  )
}

export const SystemUpgradeFeatureNotAvailable: React.FunctionComponent = () => {
  return (
    <NotAvailableContainer>
      <Trans>Not available</Trans>
    </NotAvailableContainer>
  )
}

export const SystemUpgradeDrawerStyledLink: React.FunctionComponent<SystemUpgradeDrawerStyledLinkProps> = ({
  dataQa,
  url,
  children
}) => {
  const history = useHistory()
  return (
    <StyledLink href="#" data-qa={dataQa} onClick={() => history.push(url)}>
      {children}
    </StyledLink>
  )
}

export const SystemUpgradeDrawerFooter: React.FunctionComponent<SystemUpgradeDrawerFooterProps> = ({
  closeLink
}) => {
  const history = useHistory()

  return (
    <Footer>
      <StyledPrimaryButton
        data-qa="UnderstandButton"
        onClick={() => {
          history.push(closeLink)
        }}
      >
        <Trans>I understand</Trans>
      </StyledPrimaryButton>
    </Footer>
  )
}

export const LegacyConsolePath: React.FunctionComponent<LegacyConsolePathProps> = ({
  isNotAvailable,
  path
}) => {
  const legacyConsoleName = 'identity.lastpass.com: '

  return (
    <div>
      {legacyConsoleName}
      {isNotAvailable ? (
        <SystemUpgradeFeatureNotAvailable data-qa="NotAvailableLabel" />
      ) : (
        <StyledPath data-qa="PathData">{path}</StyledPath>
      )}
    </div>
  )
}

export const SimilarPoliciesList: React.FunctionComponent = () => {
  return (
    <div>
      <StyledList>
        <li>
          <StyledLink
            href="/policies/generalpolicies/iprestrict"
            target="_blank"
            data-qa="ipRestrictLink"
          >
            <Trans>Restrict access by IP address</Trans>
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="/policies/generalpolicies/iprestrictnomobile"
            target="_blank"
            data-qa="ipRestrictNoMobileLink"
          >
            <Trans>Restrict access by IP address (mobile excluded)</Trans>
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="/policies/generalpolicies/dnsrestrict"
            target="_blank"
            data-qa="dnsRestrictLink"
          >
            <Trans>Restrict access by reverse DNS</Trans>
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="/policies/generalpolicies/countryrestrict"
            target="_blank"
            data-qa="countryRestrictLink"
          >
            <Trans>Restrict access by country</Trans>
          </StyledLink>
        </li>
      </StyledList>
    </div>
  )
}

export const AdvancedPolicyPath: React.FunctionComponent = () => {
  return (
    <span>
      <StyledLink
        href="/policies/multifactor/add-advanced"
        target="_blank"
        data-qa="addAdvancedPolicyLink"
      >
        <Trans>Policies</Trans>
        {' > '}
        <Trans>Multifactor</Trans>
        {' > '}
        <Trans>Add an advanced policy</Trans>
      </StyledLink>
    </span>
  )
}

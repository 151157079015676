import React, { FC, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { AnimatedSwitch } from '@lastpass/routing'

import {
  ActivityEvent,
  ActivityEventUserType
} from '@lastpass/admin-console-dependencies/types/activity-event'
import { ActivityDetailDrawer } from '@lastpass/admin-console-dependencies/ui/reports/view/ActivityDetailDrawer'
import { ActivityList } from '@lastpass/admin-console-dependencies/ui/reports/view/ActivityList'

interface ReportsViewPageProps {
  type: ActivityEventUserType
}

export const ReportsViewPage: FC<ReportsViewPageProps> = ({ type }) => {
  const match = useRouteMatch()
  const [events, setEvents] = useState<ActivityEvent[]>([])

  if (!match) {
    return null
  }

  const { url, path } = match
  const drawerPath = `${path}/:id`

  return (
    <>
      <ActivityList
        type={type}
        events={events}
        onEventsChange={newActivityList => {
          setEvents(newActivityList)
        }}
      />
      <AnimatedSwitch timeout={200}>
        <Route path={drawerPath}>
          <ActivityDetailDrawer
            closeLink={url}
            events={events}
            userType={type}
          />
        </Route>
      </AnimatedSwitch>
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (sharedFolderApiResponse: SharedFolderApiResponse) =>
  createResponse(SUCCESS, sharedFolderApiResponse)

export const Responses = {
  success
}

export interface SharedFolderApiType {
  shareid: string
  name: string
  deleted: string
  totalusers: string
  score: number
  itemscount: number
}

export interface SharedFolderApiResponse {
  sharedfolders: SharedFolderApiType[]
  superadmin: boolean
}

export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await teamsapi(
        'my-company/sharedfolders',
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const result: SharedFolderApiResponse = JSON.parse(response)
        return success(result)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

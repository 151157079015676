import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { EquivalentDomainsType } from '@lastpass/admin-console-dependencies/types/equivalent-domains'

export enum EquivalentDomainsActionTypes {
  EQUIVALENT_DOMAINS_GET = '[equivalent-domains] get',
  EQUIVALENT_DOMAINS_SET = '[equivalent-domains] set',
  EQUIVALENT_DOMAINS_LOADING_SET = '[equivalent-domains] set-loading',
  ADD_SELECTED_EQUIVALENT_DOMAINS = '[admin-list] add-selected-equivalent-domains',
  REMOVE_SELECTED_EQUIVALENT_DOMAINS = '[admin-list] remove-selected-equivalent-domains',
  SET_SELECTED_EQUIVALENT_DOMAINS = '[admin-list] set-selected-equivalent-domains',
  EQUIVALENT_DOMAINS_CREATE = '[equivalent-domains] create',
  EQUIVALENT_DOMAINS_DELETE = '[equivalent-domains] delete',
  EQUIVALENT_DOMAINS_ADD_NEW = '[equivalent-domains] add-new'
}

export const equivalentDomainsActions = {
  getEquivalentDomains: () =>
    createAction(EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_GET),
  setEquivalentDomains: (equivalentDomainsList: EquivalentDomainsType[]) =>
    createAction(EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_SET, {
      equivalentDomainsList: equivalentDomainsList
    }),
  setEquivalentDomainsLoading: (isLoading: boolean) =>
    createAction(EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_LOADING_SET, {
      isLoading: isLoading
    }),
  addSelectedEquivalentDomains: (
    equivalentDomainsList: EquivalentDomainsType[]
  ) =>
    createAction(EquivalentDomainsActionTypes.ADD_SELECTED_EQUIVALENT_DOMAINS, {
      equivalentDomainsList
    }),
  removeSelectedEquivalentDomains: (
    equivalentDomainsList: EquivalentDomainsType[]
  ) =>
    createAction(
      EquivalentDomainsActionTypes.REMOVE_SELECTED_EQUIVALENT_DOMAINS,
      { equivalentDomainsList }
    ),
  setSelectedEquivalentDomains: (
    equivalentDomainsList: EquivalentDomainsType[]
  ) =>
    createAction(EquivalentDomainsActionTypes.SET_SELECTED_EQUIVALENT_DOMAINS, {
      equivalentDomainsList
    }),
  createEquivalentDomains: (equivalentDomains: string) =>
    createAction(EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_CREATE, {
      equivalentDomains
    }),
  addNewEquivalentDomains: (equivalentDomains: EquivalentDomainsType) =>
    createAction(EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_ADD_NEW, {
      equivalentDomains
    }),
  deleteEquivalentDomains: (equivalentDomainsList: EquivalentDomainsType[]) =>
    createAction(EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_DELETE, {
      equivalentDomainsList
    })
}

export type EquivalentDomainsActions = ActionsUnion<
  typeof equivalentDomainsActions
>

import { DashboardActions, DashboardActionTypes } from './actions'
import { DashboardState } from './state'

const sevenDays = 7 * 24 * 60 * 60 * 1000
const thirtyDays = 30 * 24 * 60 * 60 * 1000

export const initialState: DashboardState = {
  dashboardDisplaySettings: {
    hasEnrolledUsers: true
  },
  securityScoreHistory: {
    items: [],
    loading: true,
    startDate: new Date(new Date().getTime() - sevenDays),
    endDate: new Date(),
    lastUpdateDateTimeUtc: undefined
  },
  securityScoreData: {
    averageScore: undefined,
    averagePasswordStrength: undefined,
    weakMasterPassword: undefined,
    reusedMasterPassword: undefined,
    weakScore: undefined
  },
  userAdoptionHistory: {
    items: [],
    loading: false,
    startDate: new Date(new Date().getTime() - thirtyDays),
    endDate: new Date(),
    lastUpdateDateTimeUtc: undefined
  },
  userActivityHistory: {
    items: [],
    loading: true,
    startDate: new Date(new Date().getTime() - thirtyDays),
    endDate: new Date(),
    lastUpdateDateTimeUtc: undefined
  },
  unresponsiveUserCount: 0,
  remainingLicenses: 0,
  stagedUserCount: 0,
  totalLicenses: 0,
  userStateData: {
    inactiveUsersCount: null
  },
  urlEncryptionMigrationState: {
    totalEnrolledUserCount: '-',
    migratedUserCount: '-',
    isLoading: false,
    isMigrateToVaultFormatVersionV2Enabled: false
  }
}

export function dashboardReducer(
  previousState: DashboardState | undefined,
  action: DashboardActions
): DashboardState {
  const state = previousState || initialState
  switch (action.type) {
    case DashboardActionTypes.SET_SECURITY_SCORE_HISTORY: {
      return {
        ...state,
        securityScoreHistory: {
          ...state.securityScoreHistory,
          items: action.payload.items,
          lastUpdateDateTimeUtc: action.payload.lastUpdated
        }
      }
    }
    case DashboardActionTypes.SET_SECURITY_SCORE_HISTORY_LOADING: {
      return {
        ...state,
        securityScoreHistory: {
          ...state.securityScoreHistory,
          loading: action.payload.loading
        }
      }
    }
    case DashboardActionTypes.SET_SECURITY_SCORE_HISTORY_PERIOD: {
      return {
        ...state,
        securityScoreHistory: {
          ...state.securityScoreHistory,
          startDate: action.payload.historyStartDate,
          endDate: action.payload.historyEndDate
        }
      }
    }
    case DashboardActionTypes.SET_SECURITY_SCORE_DATA: {
      return { ...state, securityScoreData: action.payload }
    }
    case DashboardActionTypes.SET_USER_ADOPTION_HISTORY: {
      return {
        ...state,
        userAdoptionHistory: {
          ...state.userAdoptionHistory,
          items: action.payload.items,
          lastUpdateDateTimeUtc: action.payload.lastUpdated
        }
      }
    }
    case DashboardActionTypes.SET_USER_ADOPTION_HISTORY_LOADING: {
      return {
        ...state,
        userAdoptionHistory: {
          ...state.userAdoptionHistory,
          loading: action.payload.loading
        }
      }
    }
    case DashboardActionTypes.SET_USER_ADOPTION_HISTORY_PERIOD: {
      return {
        ...state,
        userAdoptionHistory: {
          ...state.userAdoptionHistory,
          startDate: action.payload.historyStartDate,
          endDate: action.payload.historyEndDate
        }
      }
    }
    case DashboardActionTypes.SET_USER_ACTIVITY_HISTORY: {
      return {
        ...state,
        userActivityHistory: {
          ...state.userActivityHistory,
          items: action.payload.items,
          lastUpdateDateTimeUtc: action.payload.lastUpdated
        }
      }
    }
    case DashboardActionTypes.SET_USER_ACTIVITY_HISTORY_LOADING: {
      return {
        ...state,
        userActivityHistory: {
          ...state.userActivityHistory,
          loading: action.payload.loading
        }
      }
    }
    case DashboardActionTypes.SET_USER_ACTIVITY_HISTORY_PERIOD: {
      return {
        ...state,
        userActivityHistory: {
          ...state.userActivityHistory,
          startDate: action.payload.historyStartDate,
          endDate: action.payload.historyEndDate
        }
      }
    }
    case DashboardActionTypes.SET_UNRESPONSIVE_USERS: {
      return {
        ...state,
        unresponsiveUserCount: action.payload.unresponsiveUsers
      }
    }
    case DashboardActionTypes.SET_USER_ADOPTION: {
      return {
        ...state,
        stagedUserCount: action.payload.dashboardState.stagedUserCount,
        remainingLicenses: action.payload.dashboardState.remainingLicenses,
        totalLicenses: action.payload.dashboardState.totalLicenses
      }
    }
    case DashboardActionTypes.SET_USER_STATE_DATA: {
      return {
        ...state,
        userStateData: action.payload
      }
    }
    case DashboardActionTypes.SET_DISPLAY_SETTINGS: {
      return {
        ...state,
        dashboardDisplaySettings: {
          ...state.dashboardDisplaySettings,
          ...action.payload
        }
      }
    }
    case DashboardActionTypes.SET_URL_ENCRYPTION_MIGRATION_STATE: {
      return {
        ...state,
        urlEncryptionMigrationState: {
          ...state.urlEncryptionMigrationState,
          totalEnrolledUserCount: action.payload.totalEnrolledUserCount,
          migratedUserCount: action.payload.migratedUserCount,
          isMigrateToVaultFormatVersionV2Enabled:
            action.payload.isMigrateToVaultFormatVersionV2Enabled
        }
      }
    }
    case DashboardActionTypes.SET_URL_ENCRYPTION_MIGRATION_STATE_LOADING: {
      return {
        ...state,
        urlEncryptionMigrationState: {
          ...state.urlEncryptionMigrationState,
          isLoading: action.payload
        }
      }
    }
  }
  return state
}

import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps, Formik, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton, TextInput } from '@lastpass/lastkit/components'

import { secureAuthSettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/actions'
import { SecureAuthSettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/state'

import { OptionsCard } from './OptionsCard'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 516px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const InputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 16px;

  &:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 12px;
  }
`
const StyledButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin: 24px 0 12px 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const StyledSubTitleContainer = styled.div`
  max-width: 740px;
`

interface SecureAuthSettingsProps {
  expand?: boolean
  onFormActivity: (formStatus: boolean) => void
  closeOtherCards: (expandStatus: boolean) => void
}

const SecureAuthSubTitle: FunctionComponent = () => {
  return (
    <StyledSubTitleContainer>
      <Trans>
        LastPass supports SecureAuth authentication. You must provide LastPass
        with your SecureAuth application ID, application key, and realm.
        LastPass uses an outbound firewall so your server{`'`}s IP must be
        explicitly allowed by our Operations team. Please contact your LastPass
        account representative to request a change.
      </Trans>
    </StyledSubTitleContainer>
  )
}

const hasFormDataChanged = (
  formData: Record<string, string>,
  secureAuthSettingsData: SecureAuthSettingsState,
  onFormActivity: (formStatus: boolean) => void
): boolean => {
  const hasDataChanged = !(
    formData.secureAuthSettingsApplicationId ===
      secureAuthSettingsData.applicationId &&
    formData.secureAuthSettingsApplicationKey ===
      secureAuthSettingsData.applicationKey &&
    formData.secureAuthSettingsRealm === secureAuthSettingsData.realm
  )
  onFormActivity(hasDataChanged)
  return hasDataChanged
}

export const SecureAuthSettingsCard: FunctionComponent<SecureAuthSettingsProps> = ({
  expand,
  onFormActivity,
  closeOtherCards
}) => {
  const secureAuthSettingsState: SecureAuthSettingsState = useSelector(
    (state: AppState) => state.secureAuthSettings
  )
  const dispatch = useDispatch()
  const initialValues = {
    secureAuthSettingsApplicationId: secureAuthSettingsState.applicationId,
    secureAuthSettingsApplicationKey: secureAuthSettingsState.applicationKey,
    secureAuthSettingsRealm: secureAuthSettingsState.realm
  }
  const formref = useRef<FormikProps<typeof initialValues>>(null)
  useEffect(() => {
    if (!expand) {
      if (formref.current) {
        formref.current.resetForm()
      }
    }
  }, [expand])
  return (
    <OptionsCard
      title={<Trans>SecureAuth</Trans>}
      subtitle={<SecureAuthSubTitle />}
      loading={secureAuthSettingsState.loading}
      isInitialExpand={!!expand}
      onExpansion={secureAuthSettingsActions.getSecureAuthSettings}
      closeOtherCards={closeOtherCards}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formref}
        onSubmit={values => {
          dispatch(
            secureAuthSettingsActions.saveSecureAuthSettings({
              applicationId: values.secureAuthSettingsApplicationId,
              applicationKey: values.secureAuthSettingsApplicationKey,
              realm: values.secureAuthSettingsRealm
            })
          )
        }}
      >
        {formikProps => {
          return (
            <>
              <FormContainer>
                <InputContainer>
                  <Field name="secureAuthSettingsApplicationId">
                    {(formData: FieldProps) => {
                      return (
                        <StyledTextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Application ID</Trans>
                        </StyledTextInput>
                      )
                    }}
                  </Field>
                </InputContainer>
                <InputContainer>
                  <Field name="secureAuthSettingsApplicationKey">
                    {(formData: FieldProps) => {
                      return (
                        <StyledTextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          type="password"
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Application key</Trans>
                        </StyledTextInput>
                      )
                    }}
                  </Field>
                </InputContainer>
                <InputContainer>
                  <Field name="secureAuthSettingsRealm">
                    {(formData: FieldProps) => {
                      return (
                        <StyledTextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Realm</Trans>
                        </StyledTextInput>
                      )
                    }}
                  </Field>
                </InputContainer>
              </FormContainer>
              <StyledButton
                disabled={
                  !hasFormDataChanged(
                    formikProps.values,
                    secureAuthSettingsState,
                    onFormActivity
                  )
                }
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
              >
                <Trans>Update</Trans>
              </StyledButton>
            </>
          )
        }}
      </Formik>
    </OptionsCard>
  )
}

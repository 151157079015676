/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Config } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as BillingIcon } from '@lastpass/assets/svg/admin-console/icon-billing.svg'
import { ReactComponent as CompanyProfileIcon } from '@lastpass/assets/svg/admin-console/icon-company-profile.svg'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { ReactComponent as NewspaperIcon } from '@lastpass/assets/svg/admin-console/icon-newspaper.svg'
import { ReactComponent as OnboardingIcon } from '@lastpass/assets/svg/admin-console/icon-onboarding.svg'
import { ReactComponent as SignoutIcon } from '@lastpass/assets/svg/admin-console/icon-signout.svg'
import { ReactComponent as SwitchIcon } from '@lastpass/assets/svg/admin-console/icon-switch.svg'
import { ReactComponent as ToolkitIcon } from '@lastpass/assets/svg/admin-console/icon-toolkit.svg'
import { ReactComponent as VaultIcon } from '@lastpass/assets/svg/admin-console/icon-vault.svg'
import { ReactComponent as Logo } from '@lastpass/assets/svg/LastPass-Logo-White.svg'
import { BodySemiboldStyle } from '@lastpass/lastkit/styles/body-text'
import { useWindowResizeEffect } from '@lastpass/ui'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { CompanyDropdown } from '@lastpass/admin-console-dependencies/ui/global/CompanyDropdown'

import { Divider } from './Divider'
import { Dropdown } from './Dropdown'
import { DropdownListItem } from './DropdownListItem'
import { PrimaryButton } from './PrimaryButton'
import {
  TopNavigationState,
  TopNavigationStateProvider
} from './top-navigation-context'
import { TopNavigationItem } from './TopNavigationItem'
import { UserDropdown } from './UserDropdown'

const Header = styled.div`
  display: flex;
  background: ${props => props.theme.colors.neutral800};
  box-shadow: ${props => props.theme.elevations.elevation100};
`

const LogoWrapper = styled.div`
  padding: 20px 0;
  margin: 0 56px 0 24px;
  line-height: 0;
  cursor: pointer;
`

const StyledLogo = styled(Logo)`
  height: 16px;
  width: 104px;
`

const ItemContainer = styled.div`
  display: flex;
  position: relative;
  min-width: 0;
  line-height: 56px;
  margin-right: 0px;
`

const DropdownWrapper = styled.div<{ hidden: boolean }>`
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`

const MoreItemButton = styled(TopNavigationItem)`
  margin: 0;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  align-self: center;
`

const RightContainer = styled.div`
  margin: 0 0 0 auto;
  white-space: nowrap;
  z-index: 1;
  display: flex;
`

const DropdownButton = props => {
  return (
    <MoreItemButton data-qa="TopNavigationMoreButton" {...props}>
      <Trans>More</Trans>
    </MoreItemButton>
  )
}

const DividerFullWidth = styled.div`
  background: ${props => props.theme.colors.neutral200};
  height: 1px;
`

const CompanyDataListItem = styled.div`
  background-color: ${props => props.theme.colors.blue50};
  padding: 8px 24px;
  width: 205px;
`

const StyledCompanyName = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`
const StyledCompanyAccountNumber = styled.div`
  color: ${props => props.theme.colors.neutral600};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const BuyLicensesButton: React.FunctionComponent<{
  buyLicensesLink: string
  isUserAdminOrSuperAdmin: boolean
}> = props => {
  return props.isUserAdminOrSuperAdmin ? (
    <StyledPrimaryButton
      data-qa="BuyLicensesButton"
      customColors={{
        backgroundColor: 'yellow600',
        textColor: 'neutral900',
        hoverBackgroundColor: 'yellow700',
        focusBackgroundColor: 'yellow700'
      }}
      onClick={() => {
        window.open(props.buyLicensesLink, '_blank')
      }}
    >
      <Trans>Buy licenses</Trans>
    </StyledPrimaryButton>
  ) : (
    <></>
  )
}

const GetHelpButton = styled.div`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 0 12px;
`

const StyledHelpIcon = styled(HelpIcon)`
  height: 16px;
  width: 16px;
`

const StyledCompanyDropdown = styled(CompanyDropdown)`
  margin: 0 12px;
`

function getHiddenItemsIndex(container: HTMLElement, moreItem: HTMLElement) {
  const containerWidth = container.clientWidth
  const itemElements = Array.from(
    container.children as HTMLCollectionOf<HTMLElement>
  )
  const itemWidths = itemElements
    .filter(element => element !== moreItem)
    .map((element, index, elements) => {
      if (index < elements.length - 1) {
        return (
          (element.nextElementSibling as HTMLElement).offsetLeft -
          element.offsetLeft +
          19
        )
      }
      return element.offsetWidth
    })
  let totalItemWidth = 0
  for (let i = 0; i < itemWidths.length; ++i) {
    const itemWidth = itemWidths[i]
    if (totalItemWidth + itemWidth > containerWidth) {
      /**
       * If there is not enough space for the more item and
       * actual items then we need to hide one more actual item
       */
      if (containerWidth - totalItemWidth < moreItem.offsetWidth) {
        return i - 1
      } else {
        return i
      }
    }
    totalItemWidth += itemWidth
  }
  return -1
}

const ConsumerSwitchToOld: FunctionComponent<{
  switchToOldConsoleClicked: Function
  oldAdminConsoleUrl: string
  isUserAdminOrSuperAdmin: boolean
}> = props => {
  return props.isUserAdminOrSuperAdmin ? (
    <DropdownListItem
      icon={SwitchIcon}
      data-qa="UsersDropDownItemSwitchOldAdmin"
      onClick={() => {
        props.switchToOldConsoleClicked()
        window.open(props.oldAdminConsoleUrl, '_blank')
      }}
    >
      <Trans>Switch to old console</Trans>
    </DropdownListItem>
  ) : (
    <></>
  )
}

export interface TopNavigationProps {
  logout(): void
  billingClicked: Function
  companyProfileClicked: Function
  newsAndUpdatesClicked: Function
  switchToOldConsoleClicked: Function
  firstname: string
  lastname: string
  email: string
  items: JSX.Element[]
  showBuyLicenses: boolean
  buyLicensesLink: string
  startOnboardingAction: Function
  config: Partial<Config>
  cidOverride?: string
  hasLastPassSupport: boolean
  isUserAdminOrSuperAdmin: boolean
  isMsp: boolean
  isAggregator: boolean
  companyName: string | null
  companyAccountNumber: number
  isContactSupportAllowed: boolean
}

function getAdminConsoleBaseUrl(props: TopNavigationProps): string {
  return (
    `${props.config.lastPassBaseAddress}/company/?resetconsole=true` +
    (props.cidOverride ? `&childcid=${props.cidOverride}#!` : `#!`)
  )
}

export const TopNavigation: React.FunctionComponent<TopNavigationProps> = props => {
  const containerDOM = useRef<HTMLDivElement>(null)
  const moreDOM = useRef<HTMLDivElement>(null)
  const childrenArray = props.items
  const [items, setItems] = useState({
    main: childrenArray,
    hidden: [] as JSX.Element[]
  })
  const hideItems = () => {
    if (containerDOM.current && moreDOM.current) {
      const hiddenItemsIndex = getHiddenItemsIndex(
        containerDOM.current,
        moreDOM.current
      )
      if (hiddenItemsIndex > -1) {
        const main = childrenArray.slice(0, hiddenItemsIndex)
        const hidden = childrenArray.slice(hiddenItemsIndex)
        if (main.length !== items.main.length) {
          setItems({
            main,
            hidden
          })
        }
      } else if (items.hidden.length > 0) {
        setItems({
          main: childrenArray,
          hidden: []
        })
      }
    }
  }

  const dispatch = useDispatch()
  /**
   * Check the menu item container to see if anything has overflowed
   * in which case move it into the More sub menu
   */
  useWindowResizeEffect(hideItems, [items, childrenArray])
  useLayoutEffect(hideItems, [])
  useEffect(hideItems, [props.showBuyLicenses, props.email])

  const oldAdminConsoleBase = getAdminConsoleBaseUrl(props)

  // TODO: LP-39178
  const showLegacyAdminConsoleItems = true

  const shouldShowCompanyProfile = () =>
    props.isUserAdminOrSuperAdmin && showLegacyAdminConsoleItems

  const shouldShowBilling = () => props.isUserAdminOrSuperAdmin

  const shouldShowMspSupport =
    useFeatureFlags(FeatureFlags.isMspSupportEnabled) && props.isMsp

  const shouldShowSupportSite = () =>
    props.isUserAdminOrSuperAdmin &&
    props.hasLastPassSupport &&
    showLegacyAdminConsoleItems &&
    !shouldShowMspSupport

  const shouldShowTraining = () =>
    props.isUserAdminOrSuperAdmin && showLegacyAdminConsoleItems

  const permissions = useContext(PermissionContext)
  const hasCidOverridePermission = permissions.requirePermission(
    AdminPermissions.managedCompanyCidOverride
  )

  const shouldShowCompanyDropdown =
    props.isAggregator ||
    (props.isMsp && hasCidOverridePermission) ||
    !!props.cidOverride

  return (
    <Header>
      <LogoWrapper>
        <Link to={'/dashboard'} data-qa="LPLogoLink">
          <StyledLogo data-qa="LPLogo" />
        </Link>
      </LogoWrapper>
      <ItemContainer ref={containerDOM}>
        <TopNavigationStateProvider value={TopNavigationState.Main}>
          {items.main}
          <DropdownWrapper hidden={!items.hidden.length}>
            <Dropdown button={DropdownButton}>
              <div ref={moreDOM}>{items.hidden}</div>
            </Dropdown>
          </DropdownWrapper>
          <TopNavigationStateProvider value={TopNavigationState.Hidden}>
            {items.hidden}
          </TopNavigationStateProvider>
        </TopNavigationStateProvider>
      </ItemContainer>
      <RightContainer>
        {!!props.showBuyLicenses && (
          <BuyLicensesButton
            buyLicensesLink={props.buyLicensesLink}
            isUserAdminOrSuperAdmin={props.isUserAdminOrSuperAdmin}
          />
        )}
        {props.isContactSupportAllowed && (
          <GetHelpButton
            data-qa="TopMenuGetHelpButton"
            onClick={() => {
              dispatch(globalActions.setDialog({ type: 'supportdialog' }))
            }}
          >
            <StyledHelpIcon />
            <Trans>Get help</Trans>
          </GetHelpButton>
        )}
        {shouldShowCompanyDropdown && <StyledCompanyDropdown />}
        <UserDropdown
          firstName={props.firstname}
          lastName={props.lastname}
          email={props.email}
          css={css`
            margin: 0 12px;
          `}
        >
          {!shouldShowCompanyDropdown && (
            <>
              <CompanyDataListItem data-qa={'TopMenuCompanyDataListItem'}>
                {props.companyName && (
                  <StyledCompanyName
                    data-qa={'TopMenuCompanyDataListItemCompanyName'}
                  >
                    {props.companyName}
                  </StyledCompanyName>
                )}
                <StyledCompanyAccountNumber
                  data-qa={'TopMenuCompanyDataListItemCompanyAccountNumber'}
                >
                  <Trans>Account number</Trans>
                  {': '}
                  {props.companyAccountNumber}
                </StyledCompanyAccountNumber>
              </CompanyDataListItem>

              <DividerFullWidth />
            </>
          )}

          <DropdownListItem
            icon={VaultIcon}
            data-qa="UsersDropDownItemGoToMyVault"
            onClick={() => {
              const url: string = props.config.lastPassBaseAddress + '/home.php'
              window.open(url, '_blank')
            }}
          >
            <Trans>Open my vault</Trans>
          </DropdownListItem>

          <Divider />

          {showLegacyAdminConsoleItems && (
            <ConsumerSwitchToOld
              switchToOldConsoleClicked={props.switchToOldConsoleClicked}
              oldAdminConsoleUrl={oldAdminConsoleBase + '/dashboard'}
              isUserAdminOrSuperAdmin={props.isUserAdminOrSuperAdmin}
            />
          )}

          <Divider />

          {shouldShowCompanyProfile() && (
            <DropdownListItem
              icon={CompanyProfileIcon}
              data-qa="UsersDropDownItemCompanyProfile"
              onClick={() => {
                props.companyProfileClicked()
                const url: string =
                  oldAdminConsoleBase + '/settings/company-profile'

                window.open(url, '_blank')
              }}
            >
              <Trans>Company profile</Trans>
            </DropdownListItem>
          )}

          {shouldShowBilling() && (
            <DropdownListItem
              icon={BillingIcon}
              data-qa="UsersDropDownItemBilling"
              onClick={() => {
                props.billingClicked()
                const url: string =
                  oldAdminConsoleBase + '/settings/company-profile#invoices'
                window.open(url, '_blank')
              }}
            >
              <Trans>Billing</Trans>
            </DropdownListItem>
          )}
          {shouldShowMspSupport && (
            <DropdownListItem
              icon={HelpIcon}
              data-qa="UsersDropDownItemMspSupport"
              onClick={() => {
                dispatch(
                  globalActions.setDialog({ type: 'msp-support-dialog' })
                )
              }}
            >
              <Trans>Support</Trans>
            </DropdownListItem>
          )}
          {shouldShowSupportSite() && (
            <>
              <DropdownListItem
                icon={HelpIcon}
                data-qa="UsersDropDownItemSupportSite"
                onClick={() => {
                  const url = 'https://link.lastpass.com/help-support-site'
                  window.open(url, '_blank')
                }}
              >
                <Trans>Support site</Trans>
              </DropdownListItem>
              <DropdownListItem
                icon={ToolkitIcon}
                data-qa="UsersDropDownItemAdminResources"
                onClick={() => {
                  const url =
                    'https://support.logmeininc.com/lastpass/help/use-the-lastpass-enterprise-admin-toolkit-lp010027'
                  window.open(url, '_blank')
                }}
              >
                <Trans>Admin resources</Trans>
              </DropdownListItem>
            </>
          )}
          {shouldShowTraining() && (
            <>
              <DropdownListItem
                icon={OnboardingIcon}
                data-qa="UsersDropDownItemAdminTraining"
                onClick={() => {
                  const url = 'http://link.lastpass.com/InpAdmLpEmb'
                  window.open(url, '_blank')
                }}
              >
                <Trans>Attend admin training</Trans>
              </DropdownListItem>
              <DropdownListItem
                icon={OnboardingIcon}
                data-qa="UsersDropDownItemUserTraining"
                onClick={() => {
                  const url = 'http://link.lastpass.com/InpUsrLpEmb'
                  window.open(url, '_blank')
                }}
              >
                <Trans>Attend user training</Trans>
              </DropdownListItem>
              <DropdownListItem
                icon={NewspaperIcon}
                data-qa="UsersDropDownItemNews"
                onClick={() => {
                  props.newsAndUpdatesClicked()
                  window.open(
                    'https://lastpass.com/upgrade.php?fromwebsite=1&releasenotes=1',
                    '_blank'
                  )
                }}
              >
                <Trans>News and updates</Trans>
              </DropdownListItem>
            </>
          )}
          <DropdownListItem
            icon={SignoutIcon}
            onClick={props.logout}
            data-qa="UsersDropDownItemLogout"
          >
            <Trans>Log out</Trans>
          </DropdownListItem>
        </UserDropdown>
      </RightContainer>
    </Header>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SystemLog } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = (result: { logs: SystemLog[]; numberOfItems: number }) =>
  createResponse(SUCCESS, result)
const failure = (reason: string) => createResponse(FAILURE, reason)

export const Responses = {
  success,
  failure
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(query: string) {
    try {
      const [response, status] = await fetch(
        `google-workspace-provisioning/system-logs${query}`,
        'GET'
      )

      const responseData = response ? JSON.parse(response) : {}

      if (status === StatusCodes.OK && response) {
        return success({
          logs: responseData.content.items,
          numberOfItems: responseData.content.totalCount
        })
      } else {
        return failure(responseData.message || '')
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

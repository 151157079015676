import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'

import { EnumDictionary } from '../EnumValues'

export const FederatedStatusMapping: EnumDictionary<
  FederatedStatus,
  ReactElement
> = {
  [FederatedStatus.notEligibleForFederation]: <Trans>Not eligible</Trans>,
  [FederatedStatus.notSelected]: <Trans>Not federated</Trans>,
  [FederatedStatus.selected]: <Trans>Selected</Trans>,
  [FederatedStatus.federated]: <Trans>Federated</Trans>
}

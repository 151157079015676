import React from 'react'

import { Trans } from '@lingui/macro'

import { PrimaryButton } from '@lastpass/lastkit'

export const ExportReportButton: React.FunctionComponent = () => {
  return (
    <PrimaryButton data-qa="ExportReportButton">
      <Trans>Export report</Trans>
    </PrimaryButton>
  )
}

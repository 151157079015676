import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { RouteFade } from '@lastpass/routing/animations'

import { GeneralPolicyDetails } from '@lastpass/admin-console-dependencies/ui/policies/general/GeneralPolicyDetails'

export function GeneralPoliciesDetailsDrawerPage() {
  const match = useRouteMatch()
  if (match) {
    const { url, path } = match
    return (
      <>
        <Route path={path} exact>
          <RouteFade>
            <GeneralPolicyDetails
              editSettingslink={`${url}/settings`}
              editUsersLink={`${url}/edit-users`}
            />
          </RouteFade>
        </Route>
      </>
    )
  }
  return null
}

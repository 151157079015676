import { msg } from '@lingui/macro'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as ProvisionedUsersHandler from '@lastpass/admin-console-dependencies/sagas/users/view/operations/helpers/handle-provisioned-users'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { RemoveFromCompanyConfirmationWordDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { SharedFoldersAndSitesAccessType } from '@lastpass/admin-console-dependencies/types/shared-folders-and-sites-access'

import { invokeApi } from './operation-base'
export const totalSelectedUsersCountSelector = (state: AppState) =>
  state.usersList.table.totalResults

export function createRemoveUserFromCompanySaga(
  userService: UACServices.Services
) {
  return function* removeUserFromCompanySaga(
    action: ReturnType<typeof userOperationActions.removeUsersFromCompany>
  ) {
    try {
      const { isAllSelected } = action.payload.userOperationPayload
      let { users, filter } = action.payload.userOperationPayload
      let totalSelectedUsersCount = yield select(
        totalSelectedUsersCountSelector
      )

      if (isAllSelected) {
        const handlerResult = yield call(
          ProvisionedUsersHandler.handleAllUserSelection,
          users,
          filter,
          totalSelectedUsersCount,
          userService.userCount
        )

        if (!handlerResult.shouldProceed) {
          return
        }

        filter = handlerResult.usersFilter
        totalSelectedUsersCount = handlerResult.selectedUsersCount
      } else {
        users = yield call(
          ProvisionedUsersHandler.handleSelectionWithinPage,
          users,
          totalSelectedUsersCount
        )
      }

      if (users === undefined) {
        return
      }

      const dialog: RemoveFromCompanyConfirmationWordDialogType = {
        type: 'remove-from-company-confirmation-word-dialog',
        users,
        isAllSelected,
        allUserCount: totalSelectedUsersCount
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users have been removed.`,
            MultipleUsersSuccess: msg`The selected users have been removed.`,
            SingleUserFail:
              users.length > 0
                ? msg`User hasn't been removed: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User has been removed: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users haven't been removed from the company.`
          },
          'removeuserfromcompany',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            deleteShare:
              confirmClick.payload.data !==
              SharedFoldersAndSitesAccessType.allow,
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.REMOVE_USERS_FROM_COMPANY,
          filter
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const ERROR = 'error'

const success = () => createResponse(SUCCESS)
const error = (message: string) => createResponse(ERROR, message)

export const Responses = {
  success,
  error
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(key: string) {
    try {
      const [response, status] = await fetch(`company/mfaapps/${key}`, 'DELETE')
      const parsedResponse = JSON.parse(response)
      if (status === StatusCodes.OK && !parsedResponse.message) {
        return success()
      } else {
        return error(parsedResponse.message)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { ReactComponent as GoogleIcon } from '@lastpass/assets/svg/admin-console/directories/icon-google.svg'
import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'
import {
  BodyRegularStyle,
  Checkbox,
  LogoCard,
  TextInput
} from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  FederatedLoginState,
  OpenIdSetupState
} from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import {
  BoldWrapper,
  StyledCardContainer,
  StyledExternalLink,
  StyledPrimaryButton,
  StyledSubtitle
} from './StyledComponents'
import { VaultReEncryptionBlock } from './VaultReEncryptionBlock'

const StyledChildRow = styled.div`
  ${BodyRegularStyle};
  display: flex;
  flex-direction: row;

  color: ${props => props.theme.colors.neutral700};
  margin-bottom: 18px;

  label {
    margin-right: 30px;
  }
`

const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 560px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

interface OpenIdSetupFormProps {
  handleSubmit: (openIdSetup: OpenIdSetupState) => void
  clientId: string
  enabled: boolean
  provider: OpenIdProvider
}

const getClientIds = clientId => {
  try {
    return JSON.parse(clientId)
  } catch {
    return {
      spaClientId: clientId,
      iosClientId: '',
      androidClientId: '',
      desktopClientId: ''
    }
  }
}

const GoogleSetupForm: FunctionComponent<OpenIdSetupFormProps> = ({
  handleSubmit,
  clientId,
  enabled,
  provider
}) => {
  const isGoogle = provider === OpenIdProvider.Google
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )

  const clientIdCollection = getClientIds(clientId)
  const validationSchema = Yup.object().shape({
    googleWebClientId: Yup.string()
      .required(i18n._(msg`Web application Client ID is required`))
      .nullable(),
    googleiOSClientId: Yup.string()
      .required(i18n._(msg`iOS Client ID is required`))
      .nullable(),
    googleAndroidClientId: Yup.string()
      .required(i18n._(msg`Android Client ID is required`))
      .nullable(),
    googleDesktopClientId: Yup.string()
      .required(i18n._(msg`Desktop app Client ID is required`))
      .nullable()
  })
  const initialValues = {
    googleWebClientId: isGoogle ? clientIdCollection.spaClientId : '',
    googleiOSClientId: isGoogle ? clientIdCollection.iosClientId : '',
    googleAndroidClientId: isGoogle ? clientIdCollection.androidClientId : '',
    googleDesktopClientId: isGoogle ? clientIdCollection.desktopClientId : '',
    googleEnabled: isGoogle ? enabled : false
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      enableReinitialize={true}
      onSubmit={values =>
        handleSubmit({
          openIdUrl:
            'https://accounts.google.com/.well-known/openid-configuration',
          clientId: JSON.stringify({
            spaClientId: values.googleWebClientId.trim(),
            iosClientId: values.googleiOSClientId.trim(),
            androidClientId: values.googleAndroidClientId.trim(),
            desktopClientId: values.googleDesktopClientId.trim()
          }).replace(/\\"/g, '"'),
          isEnabled: values.googleEnabled,
          useOktaAuthServerToStoreCompanyKey: false,
          useLastPassADConnectorToSyncUsers: false,
          provider: OpenIdProvider.Google,
          isEmailHintDisabled: false,
          pkceEnabled: false,
          useAzureMdmFlow: false
        })
      }
    >
      {formikProps => {
        return (
          <>
            <StyledChildRow>
              <Field name="googleWebClientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="GoogleWebClientIdInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      error={!!formikProps.errors.googleWebClientId}
                      errorText={<>{formikProps.errors.googleWebClientId}</>}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Web application Client ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="googleiOSClientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="GoogleIosClientIdInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      error={!!formikProps.errors.googleiOSClientId}
                      errorText={<>{formikProps.errors.googleiOSClientId}</>}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>iOS Client ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="googleAndroidClientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="GoogleAndroidClientIdInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      error={!!formikProps.errors.googleAndroidClientId}
                      errorText={
                        <>{formikProps.errors.googleAndroidClientId}</>
                      }
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Android Client ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="googleDesktopClientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="GoogleDesktopClientIdInput"
                      toolTip={i18n._(
                        msg`Federated login on desktop is planned for early 2022`
                      )}
                      name={formData.field.name}
                      value={formData.field.value}
                      error={!!formikProps.errors.googleDesktopClientId}
                      errorText={
                        <>{formikProps.errors.googleDesktopClientId}</>
                      }
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Desktop app Client ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="googleEnabled">
                {formData => {
                  return (
                    <Checkbox
                      data-qa="GoogleEnabledCheckbox"
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Enabled</Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              <StyledPrimaryButton
                data-qa="GoogleSaveChangesButton"
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                disabled={
                  !formikProps.isValid ||
                  !formikProps.dirty ||
                  !hasDirectoriesAndFederationModifyPermission
                }
              >
                <Trans>Save changes</Trans>
              </StyledPrimaryButton>
            </StyledChildRow>
          </>
        )
      }}
    </Formik>
  )
}

export const GoogleSetupCard: FunctionComponent<{
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}> = ({ automatedVaultReEncryptionPolicy }) => {
  const dispatch = useDispatch()
  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )
  const currentAdminLevel: string = useSelector((state: AppState) => {
    return state.usersList.currentAdminLevel
  })

  const { openId, adfs }: FederatedLoginState = useSelector(
    (state: AppState) => state.federatedLogin
  )
  const { provider, isEnabled, clientId } = openId
  const isGoogle = provider === OpenIdProvider.Google

  useEffect(() => {
    dispatch(federatedLoginActions.getOpenIdSetup())
  }, [dispatch])
  return (
    <LogoCard
      dataQaName="GoogleCard"
      logo={<GoogleIcon />}
      highlighted={isGoogle && isEnabled}
      title={
        <Heading100>
          <Trans>Google Workspace</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from Google Workspace. They
          {"'"}ll log in to LastPass with their Google Workspace credentials
          instead of their master password.
        </Trans>
      }
    >
      <StyledCardContainer>
        <StyledChildRow>
          <BoldWrapper>
            <Trans>Configure Google Workspace</Trans>
          </BoldWrapper>
          <StyledExternalLink
            data-qa="GoogleInstructionsLink"
            href="https://support.logmeininc.com/lastpass/help/set-up-federated-login-for-lastpass-using-google-workspace"
            target="_blank"
          >
            <Trans>Follow these instructions</Trans>
          </StyledExternalLink>
        </StyledChildRow>
        <StyledSubtitle>
          <Trans>
            Complete this form using information from your Google Workspace
            admin portal.
          </Trans>
        </StyledSubtitle>
        {(!isGoogle && isEnabled) || adfs.isEnabled ? (
          <div data-qa="IdpAlreadyConfiguredMessageOnGoogle">
            <OpenIdProviderConfigurationMessage provider="Google Workspace" />
          </div>
        ) : (
          <GoogleSetupForm
            handleSubmit={(openIdSetup: OpenIdSetupState) => {
              dispatch(
                federatedLoginActions.saveOpenIdSetup(
                  openIdSetup,
                  openId,
                  openIdSetup.isEnabled &&
                    isAutomatedVaultReEncryptionEnabled &&
                    !automatedVaultReEncryptionPolicy.isEnabled
                )
              )
            }}
            clientId={clientId}
            provider={provider}
            enabled={isEnabled}
          />
        )}
        {currentAdminLevel === AdminLevel.superAdmin &&
          isEnabled &&
          isGoogle &&
          isAutomatedVaultReEncryptionEnabled && (
            <VaultReEncryptionBlock
              onClickReEncryption={() => {
                dispatch(
                  federatedLoginActions.startVaultReEncryption({
                    ...automatedVaultReEncryptionPolicy
                  })
                )
              }}
              isAutomatedVaultReEncryptionPolicyEnabled={
                automatedVaultReEncryptionPolicy.isEnabled
              }
            />
          )}
      </StyledCardContainer>
    </LogoCard>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { DuoSecuritySettingsGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { DuoSecuritySettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createGetDuoConfig(services: UACServices.Services) {
  return function* getDuoConfig() {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      try {
        const response = yield call(services.duoSecuritySettingsGet)

        if (response.type === DuoSecuritySettingsGetAPI.SUCCESS) {
          const settings: DuoSecuritySettingsState =
            response.body.duoSecuritySettings

          yield put(
            multifactorDrawerActions.setDuoConfig({
              api_hostname: settings.apiHostName,
              integration_key: settings.integrationKey,
              secret_key: settings.secretKey
            })
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, select } from 'redux-saga/effects'
import { put } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/workstations/get-workstation-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { workstationListActions } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/actions'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export const getCompanyDetails = (state: AppState) => state.companyInfo.details

export function createWorkstationList(
  workstationServices: UACServices.Services
) {
  return function* getWorkstationList() {
    try {
      const companyDetails: CompanyDetails = yield select(getCompanyDetails)
      const havePermission =
        CompanyDetailsHelper.isWorkstationAllowed(companyDetails) &&
        CompanyDetailsHelper.isPasswordlessAllowed(companyDetails)
      if (havePermission) {
        const workstationListResult: Responses = yield call(
          workstationServices.getWorkstationList
        )
        if (
          workstationListResult.type === UACServices.WorkstationListAPI.SUCCESS
        ) {
          const workstationListResponse =
            workstationListResult.body.workstationListResponse
          yield put(
            workstationListActions.setWorkstationListWithTracking(
              workstationListResponse.results,
              workstationListResponse.total
            )
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

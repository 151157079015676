import React, { FunctionComponent, useContext } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { TextButton } from '@lastpass/lastkit'
import { CardContainer } from '@lastpass/lastkit/components/CardContainer'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

const Container = styled(CardContainer)`
  padding-top: 16px;
  margin-top: 16px;
`

const DescriptionContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 16px;
`

const LinkContainer = styled(DescriptionContainer)`
  margin: 16px 16px 16px 8px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
export interface ProvisioningSectionProps {
  ADSyncClientLink: string
  ProvisioningAPILink: string
}

export const ProvisioningSection: FunctionComponent<ProvisioningSectionProps> = props => {
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )

  return (
    <Container>
      <Heading100 style={{ margin: '0 16px' }}>
        <Trans>Automatic provisioning</Trans>
      </Heading100>
      <DescriptionContainer>
        <Trans>
          Automatic provisioning is available through the following services:
        </Trans>
      </DescriptionContainer>
      <LinkContainer>
        {hasDirectoriesAndFederationModifyPermission && (
          <TextButton blue>
            <StyledLink
              to={props.ADSyncClientLink}
              target={'_blank'}
              data-qa={'ADSyncClientButton'}
            >
              <Trans>AD Sync client</Trans>
            </StyledLink>
          </TextButton>
        )}
        <TextButton blue>
          <StyledExternalLink
            href={props.ProvisioningAPILink}
            target={'_blank'}
            data-qa={'ProvisioningAPIButton'}
          >
            <Trans>Provisioning API</Trans>
          </StyledExternalLink>
        </TextButton>
      </LinkContainer>
    </Container>
  )
}

import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicyUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import {
  GeneralPolicyUsersAndGroups,
  GeneralPolicyUsersAndGroupsFormState
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyMap,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getPolicyInfo = (state: AppState) =>
  state.generalPoliciesList.policyMap

export const getPolicyUsersAndGroupsState = (state: AppState) =>
  state.generalPolicyUsersAndGroups

export const getPolicyConfigUsersList = (
  policyData: TeamsPolicyModelData,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyUserInfo[] => {
  let usersList: TeamsPolicyUserInfo[] = []
  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData.appliesTo
  ) {
    return usersList
  }
  if (policyData.appliesTo === AppliesToOptions.inclusive) {
    usersList = policyData.inclusiveList ? policyData.inclusiveList : []
  } else if (policyData.appliesTo === AppliesToOptions.exclusive) {
    usersList = policyData.exclusiveList ? policyData.exclusiveList : []
  }
  return usersList
}
export const getPolicyConfigGroupsList = (
  policyData: TeamsPolicyModelData,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyGroup[] => {
  let groupsList: TeamsPolicyGroup[] = []
  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData.appliesTo
  ) {
    return groupsList
  }
  if (policyData.appliesTo === AppliesToOptions.inclusive) {
    groupsList = policyData.inclusiveGroupList
      ? policyData.inclusiveGroupList
      : []
  } else if (policyData.appliesTo === AppliesToOptions.exclusive) {
    groupsList = policyData.exclusiveGroupList
      ? policyData.exclusiveGroupList
      : []
  }
  return groupsList
}

const applyUsersSearchFilter = (
  usersList: TeamsPolicyUserInfo[],
  searchFilter?: string
): TeamsPolicyUserInfo[] => {
  let filteredUsersList: TeamsPolicyUserInfo[] = []
  if (searchFilter) {
    filteredUsersList = usersList.filter(user => {
      const hasName =
        user.name &&
        user.name.toLowerCase().includes(searchFilter.toLowerCase())
          ? true
          : false
      const hasEmail: boolean =
        user.email &&
        user.email.toLowerCase().includes(searchFilter.toLowerCase())
          ? true
          : false
      return hasName || hasEmail
    })
  }
  return searchFilter ? filteredUsersList : usersList
}

const applyGroupsSearchFilter = (
  groupsList: TeamsPolicyGroup[],
  searchFilter?: string
): TeamsPolicyGroup[] => {
  let filteredGroupsList: TeamsPolicyGroup[] = []
  if (searchFilter) {
    filteredGroupsList = groupsList.filter(user => {
      const hasName =
        user.name &&
        user.name.toLowerCase().includes(searchFilter.toLowerCase())
          ? true
          : false
      return hasName
    })
  }
  return searchFilter ? filteredGroupsList : groupsList
}

export function createGetGeneralPolicyUsersAndGroupsSaga() {
  return function* getGeneralPolicyUsersAndGroupsSaga(
    action: ReturnType<
      typeof generalPolicyUsersAndGroupsActions.getGeneralPolicyUsersAndGroups
    >
  ) {
    try {
      const policyKey = action.payload.params.path.id
      const policyConfigIdParam = action.payload.params.path.configId
      const searchFilter = action.payload.params.query.search

      if (policyKey && policyConfigIdParam) {
        yield put(
          generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersLoading(true)
        )
        yield put(
          generalPolicyUsersAndGroupsActions.setGeneralPolicyGroupsLoading(true)
        )
        yield put(
          generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroupsOpen(
            true
          )
        )
        try {
          const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
          const policyUsersAndGroupsFormState: GeneralPolicyUsersAndGroupsFormState = yield select(
            getPolicyUsersAndGroupsState
          )

          const policyCurrentlyAppliesTo =
            policyUsersAndGroupsFormState.policyCurrentlyAppliesTo

          const policy = policyMap[policyKey]
          const policyConfigId = parseInt(policyConfigIdParam)
          if (policy) {
            const policyUsers = getPolicyConfigUsersList(
              policy.policyData[policyConfigId],
              policyCurrentlyAppliesTo
            )
            const policyGroups = getPolicyConfigGroupsList(
              policy.policyData[policyConfigId],
              policyCurrentlyAppliesTo
            )

            const policyUsersSearchFilter = applyUsersSearchFilter(
              policyUsers,
              searchFilter
            )
            const policyGroupsSearchFilter = applyGroupsSearchFilter(
              policyGroups,
              searchFilter
            )
            const policyConfigUsersAndGroups: GeneralPolicyUsersAndGroups = {
              policyKey: policy.policyKey,
              policyConfigIndex: policyConfigId,
              policyConfigFormData: {
                appliesTo: policy.policyData[policyConfigId].appliesTo,
                users: policyUsersSearchFilter,
                groups: policyGroupsSearchFilter
              }
            }
            yield put(
              generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroups(
                policyConfigUsersAndGroups
              )
            )
            yield put(
              generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroupsNeedsRefresh(
                false,
                policyKey,
                policyConfigId
              )
            )
          } else {
            yield put(
              generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroupsNeedsRefresh(
                true,
                policyKey,
                policyConfigId
              )
            )
          }
        } finally {
          yield put(
            generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersLoading(
              false
            )
          )
          yield put(
            generalPolicyUsersAndGroupsActions.setGeneralPolicyGroupsLoading(
              false
            )
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

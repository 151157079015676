import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { splunkIntegrationActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/actions'
import { SplunkIntegrationState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSaveSplunkIntegration(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveSplunkIntegration(
    action: ReturnType<typeof splunkIntegrationActions.saveSplunkIntegration>
  ) {
    try {
      const splunkIntegration: SplunkIntegrationState = action.payload.splunk
      yield put(splunkIntegrationActions.setSplunkIntegrationLoading(true))
      try {
        const splunkIntegrationResult: UACServices.SplunkIntegrationSaveAPI.Responses = yield call(
          enterpriseOptionServices.splunkIntegrationSave,
          splunkIntegration
        )
        if (
          splunkIntegrationResult.type ===
          UACServices.SplunkIntegrationSaveAPI.SUCCESS
        ) {
          yield put(splunkIntegrationActions.getSplunkIntegration())
          yield put(
            globalActions.setNotification({
              message: msg`Splunk Integration Save Success`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`Splunk Integration Save Failed`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(splunkIntegrationActions.setSplunkIntegrationLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { ManagedCompanyStatus } from '@lastpass/admin-console-dependencies/types/managed-company'

import { EnumDictionary } from '../EnumValues'

export const ManagedCompanyStatusMapping: EnumDictionary<
  ManagedCompanyStatus,
  ReactElement
> = {
  [ManagedCompanyStatus.active]: <Trans>Active</Trans>,
  [ManagedCompanyStatus.new]: <Trans>New</Trans>,
  [ManagedCompanyStatus.suspended]: <Trans>Suspended</Trans>
}

import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { msg, Trans } from '@lingui/macro'

import { ReactComponent as IconGreen } from '@lastpass/assets/svg/admin-console/security-dashboard/groups-with-low-security-score-green.svg'
import { ReactComponent as IconRed } from '@lastpass/assets/svg/admin-console/security-dashboard/groups-with-low-security-score-red.svg'
import { PrimaryButton } from '@lastpass/lastkit'

import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'
import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

export interface GroupsWithLowSecurityScoreCardProps {
  count: number
  dataQa?: string
}

export const GroupsWithLowSecurityScoreCard: FC<GroupsWithLowSecurityScoreCardProps> = ({
  count,
  dataQa
}) => {
  const countColor = useCountColor(count)
  return (
    <ActionCard
      icon={count === 0 ? IconGreen : IconRed}
      count={count}
      countColor={countColor}
      title={msg`Groups with a low security score`}
      description={
        <Trans>
          View groups with users who should strengthen their stored passwords
          and security settings.
        </Trans>
      }
      action={
        count > 0 && (
          <Link to="/users/groups">
            <PrimaryButton data-qa="GroupsWithLowSecurityScoreButton">
              <Trans>View groups</Trans>
            </PrimaryButton>
          </Link>
        )
      }
      dataQa={dataQa}
    />
  )
}

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { createWorkstationList } from '@lastpass/admin-console-dependencies/ui/applications/workstations/WorkstationList'

const WorkstationList = createWorkstationList<AppState>()

export const WorkstationsPage = ({ cidOverride }) => {
  const match = useRouteMatch()
  if (match) {
    return <WorkstationList cidOverride={cidOverride} />
  }
  return null
}

import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import {
  SaveTeamsPolicySwitchOnData,
  TeamsPolicy
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { sendMfaInvitation } from './send-mfa-invitation'

export const getCurrentMfaPolicy = (state: AppState) =>
  state.passwordlessContainer.RequirePasswordlessViaLastPassPolicy

export function createSavePolicyUsersAndGroupsSaga(
  policyService: UACServices.Services
) {
  return function* savePolicyUsersAndGroupsSaga(
    action: ReturnType<
      typeof passwordlessDrawerActions.savePolicyUsersAndGroups
    >
  ) {
    try {
      const policySaveData: SaveTeamsPolicySwitchOnData = {
        policyKey: action.payload.policyKey,
        policyData: action.payload.policyData
      }

      const result: UACServices.GeneralPolicySwitchONSaveAPI.Responses = yield call(
        policyService.generalPolicySwitchOnSave,
        policySaveData
      )

      if (result.type === UACServices.GeneralPolicySwitchONSaveAPI.SUCCESS) {
        yield call(sendMfaInvitation, action.payload.policyData, policyService)

        yield put(
          globalActions.setNotificationSuccess(msg`Policy changes saved.`)
        )

        const currentPolicy: TeamsPolicy = yield select(getCurrentMfaPolicy)
        yield put(
          passwordlessActions.setRequirePasswordlessViaLastPassPolicy({
            ...currentPolicy,
            checked: true,
            policyData: action.payload.policyData
          })
        )
      } else {
        yield put(
          globalActions.setNotificationAlert(
            msg`Policy changes haven't been saved.`
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { adminOperationActions } from '@lastpass/admin-console-dependencies/state/users/admin/operations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createDeleteAdminLevelSaga(
  removeAdminLevelService: UACServices.Services
) {
  return function* deleteAdminLevelSaga(
    action: ReturnType<typeof adminOperationActions.deleteAdmins>
  ) {
    try {
      const adminLevels = action.payload
      const deleteAdminLevelTexts =
        adminLevels.length === 1
          ? {
              confirm: msg`Delete this admin level? ${adminLevels[0].name}`,
              success: msg`Admin level has been deleted: ${adminLevels[0].name}`,
              fail: msg`Admin level not deleted: ${adminLevels[0].name}`
            }
          : {
              confirm: msg`Delete the selected admin levels?`,
              success: msg`The selected admin levels have been deleted.`,
              fail: msg`None of the selected admin levels have been deleted.`
            }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Delete admin levels`,
        text: deleteAdminLevelTexts.confirm,
        discardText: msg`Cancel`,
        confirmText: msg`Delete`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const result: UACServices.AdminLevelDeleteAPI.Responses = yield call(
          removeAdminLevelService.adminLevelDelete,
          adminLevels
        )
        switch (result.type) {
          case UACServices.AdminLevelDeleteAPI.SUCCESS: {
            yield put(push('/users/admin/'))
            yield put(
              globalActions.setNotification({
                message: deleteAdminLevelTexts.success,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
            break
          }
          case UACServices.AdminLevelDeleteAPI.FAIL: {
            yield put(
              globalActions.setNotification({
                message: deleteAdminLevelTexts.fail,
                type: NotificationType.alert,
                autoDismiss: true
              })
            )
            break
          }
          default:
            throw new UnknownServiceError()
        }
      }
      yield put(adminListActions.getAdminList())
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

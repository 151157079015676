import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { enabledMultifactorOptionsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/actions'
import { MultifactorOption } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSaveEnabledMultifactorOptions(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveEnabledMultifactorOptions(
    action: ReturnType<
      typeof enabledMultifactorOptionsActions.saveEnabledMultifactorOptions
    >
  ) {
    try {
      const enabledMultifactorOptions: MultifactorOption[] =
        action.payload.options
      yield put(
        enabledMultifactorOptionsActions.setEnabledMultifactorOptionsLoading(
          true
        )
      )
      try {
        const enabledMultifactorOptionsResult: UACServices.EnabledMultifactorOptionsSaveAPI.Responses = yield call(
          enterpriseOptionServices.enabledMultifactorOptionsSave,
          enabledMultifactorOptions
        )
        if (
          enabledMultifactorOptionsResult.type ===
          UACServices.EnabledMultifactorOptionsSaveAPI.SUCCESS
        ) {
          yield put(
            enabledMultifactorOptionsActions.setEnabledMultifactorOptions(
              enabledMultifactorOptions
            )
          )
          yield put(
            globalActions.setNotification({
              message: msg`Multifactor options settings Save Success`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`Multifactor options settings Save Failed`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(
          enabledMultifactorOptionsActions.setEnabledMultifactorOptionsLoading(
            false
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'
import { RouteProps, useRouteMatch } from 'react-router'
import { LinkProps } from 'react-router-dom'

import { LocationLink } from '@lastpass/routing'

import { TabNavigationItem, TabNavigationItemProps } from './TabNavigationItem'

export const RoutedTabNavigationItem: React.FunctionComponent<TabNavigationItemProps &
  LinkProps & { route: RouteProps }> = ({ children, ...props }) => {
  const match = useRouteMatch(props.route)

  const linkProps = { ...props, route: undefined }
  return (
    <LocationLink {...linkProps}>
      <TabNavigationItem active={!!match}>{children}</TabNavigationItem>
    </LocationLink>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { Site } from '@lastpass/admin-console-dependencies/types/site'

export const SUCCESS = 'success'

const success = (sites: Site[], totalResults: number) =>
  createResponse(SUCCESS, { sites, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userId: string, query: string) {
    const [response, status] = await fetch(
      'users/' + userId + '/sites' + query,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const sites = JSON.parse(response).content.items
      const totalResults = JSON.parse(response).content.totalCount
      return success(sites, totalResults)
    }
    throw new UnknownServiceError()
  }
}

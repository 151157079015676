import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserActivityHistoryItem } from '@lastpass/admin-console-dependencies/state/dashboard/state'

export const SUCCESS = 'success'

const success = (items: UserActivityHistoryItem[], lastUpdated: Date) =>
  createResponse(SUCCESS, { items, lastUpdated })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

const formatDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

export function create(fetch: AuthFetch) {
  return async function service(
    historyStartDate: Date | null,
    historyEndDate: Date | null
  ) {
    let url = 'dashboard/activeusers/history/?'
    if (historyStartDate) {
      url += `startDateUtc=${formatDate(historyStartDate)}&`
    }

    if (historyEndDate) {
      url += `endDateUtc=${formatDate(historyEndDate)}`
    }

    const [response, status] = await fetch(url, 'GET')
    if (status === StatusCodes.OK && response) {
      const parsedResponse = JSON.parse(response)
      const items: UserActivityHistoryItem[] = parsedResponse.content.data.map(
        (x): UserActivityHistoryItem => ({
          date: new Date(x.dateUtc),
          activeUserRate: x.userWithActivityPercentage,
          activeUserCount: x.userWithActivityCount
        })
      )
      const lastUpdated = parsedResponse.content.lastUpdatedDateTimeUtc
      return success(items, lastUpdated)
    }

    throw new UnknownServiceError()
  }
}

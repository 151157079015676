import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'
import buildQuery from 'odata-query'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components'
import {
  SecurityScoreBadge,
  Table as TableComponent,
  TableView
} from '@lastpass/lastkit'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import { UserColumnMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/column-mappings/UserColumnMapping'

interface RankedUser extends User {
  rank: number
}

export const SecurityLeaderboard: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { users } = useContext(ServerContext)
  const [table, setTable] = useState<Table<RankedUser>>({
    totalResults: 0,
    results: [],
    resultsPerPage: 10,
    checkedRecords: [],
    isLoading: true
  })

  const getRankedUsers = (users: User[]): RankedUser[] =>
    users.map((user, index) => ({ ...user, rank: index + 1 }))

  const getUserList = useCallback(async () => {
    try {
      const query = buildQuery({
        orderBy: 'securityChallengeScore desc',
        top: 10,
        filter: { securityChallengeScore: { ne: null } }
      })

      const result = await users(query)

      if (result.type === UACServices.UsersAPI.SUCCESS) {
        const rankedUsers = getRankedUsers(result.body.userList)

        setTable({
          totalResults: result.body.totalResults,
          results: rankedUsers,
          resultsPerPage: 10,
          checkedRecords: [],
          isLoading: false
        })
      }
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    }
  }, [dispatch, users])

  useEffect(() => {
    getUserList()
  }, [getUserList])

  const rankColumn: TableColumn<RankedUser> = {
    name: <Trans>Rank</Trans>,
    renderer: function RankRenderer({ rank }) {
      return <>{rank}</>
    }
  }

  const securityRatingBadgeColumn: TableColumn<RankedUser> = {
    name: <Trans>Security score</Trans>,
    renderer: function SecurityScorerenderer({
      securityScore,
      securityChallengeScore
    }: User) {
      return (
        <SecurityScoreBadge
          level={securityScore}
          score={securityChallengeScore}
        />
      )
    }
  }

  const columnsToShow: TableColumn<RankedUser>[] = [
    rankColumn,
    UserColumnMapping[UserListTableColumn.email],
    UserColumnMapping[UserListTableColumn.name],
    securityRatingBadgeColumn
  ]

  return (
    <TableComponent
      qadata="DashboardSecurityLeaderboard"
      table={table}
      noRecordsView={
        <TableView
          icon={<UserIcon />}
          title={<Trans>No data yet</Trans>}
          text={
            <Trans>
              {`None of your users have a security score yet. Tip: The "Report
                  security score" policy may be disabled.`}
            </Trans>
          }
        />
      }
      columns={columnsToShow}
      loadingView={<Loading color="blue900" active={true} />}
    />
  )
}

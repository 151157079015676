import React from 'react'

import { Trans } from '@lingui/macro'

import { Theme } from '@lastpass/lastkit'
import { Badge } from '@lastpass/lastkit/components'

import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'
import { MFAStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MfaStatusMapping'

export interface MfaBadgeProps {
  status: MFAStatus
}

export const MfaBadge = (props: MfaBadgeProps) => {
  const label = MFAStatusMapping[props.status] || <Trans>Unknown</Trans>
  let background: keyof Theme['colors'] = 'teal'
  let textColor: keyof Theme['colors'] = 'white'
  let isBadge = true

  switch (props.status) {
    case MFAStatus.active:
      background = 'teal'
      textColor = 'white'
      break
    case MFAStatus.notInvited:
      background = 'neutral200'
      textColor = 'neutral900'
      break
    case MFAStatus.invited:
      background = 'purple'
      textColor = 'white'
      break
    case MFAStatus.locked:
      background = 'yellow'
      textColor = 'white'
      break
    case MFAStatus.na:
      background = 'red700'
      textColor = 'white'
      break
    case MFAStatus.notRequired:
      isBadge = false
      break
    default:
      break
  }
  return isBadge ? (
    <Badge
      colors={{
        backgroundColor: background,
        textColor: textColor
      }}
    >
      {label}
    </Badge>
  ) : null
}

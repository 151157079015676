import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Migration from '@lastpass/admin-console-dependencies/server/applications/saml/get-is-migrated'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'

export function createIsMigrated(applicationsServices: UACServices.Services) {
  return function* getIsMigrated() {
    try {
      const getIsMigratedResponse: Migration.Responses = yield call(
        applicationsServices.getIsMigrated
      )

      if (
        getIsMigratedResponse &&
        getIsMigratedResponse.type === Migration.SUCCESS
      ) {
        const isMigrated = getIsMigratedResponse.body.Value
        yield put(systemUpgradeActions.setIsMigrated(isMigrated))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import { takeDialogInteractions } from '@lastpass/admin-console-dependencies/sagas/utils/take-dialog-interactions'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { ActionWithPayload } from '@lastpass/admin-console-dependencies/state/action'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { PsaCompanyContractAssignment } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

const DEFAULT_SUCCESSFUL_SAVE_REDIRECT_URL = '/advanced/psaIntegrations'

export function createSavePsaAgreementsSaga(services: UACServices.Services) {
  return function* savePsaAgreementsSaga(
    action: ReturnType<typeof psaIntegrationsActions.savePsaAgreements>
  ) {
    try {
      const {
        assignments,
        isConfirmRequired,
        successUrl = DEFAULT_SUCCESSFUL_SAVE_REDIRECT_URL,
        successAction
      } = action.payload

      if (isConfirmRequired) {
        const dialog = getNoAgreementSelectedConfirmDialog()
        yield put(globalActions.setDialog(dialog))
        const dialogInteractions = yield call(takeDialogInteractions)

        if (dialogInteractions.isDiscarded) return

        if (dialogInteractions.isConfirmed) {
          yield saveAgreements(assignments, successUrl, successAction)
        }
      } else {
        yield saveAgreements(assignments, successUrl, successAction)
      }
    } catch {
      yield put(setErrorBanner())
    } finally {
      yield put(psaIntegrationsActions.setDrawerInProgress(false))
    }
  }

  function setErrorBanner(): ActionWithPayload<
    GlobalActionTypes.SET_NOTIFICATION,
    {
      notification: NotificationProps
    }
  > {
    return globalActions.setNotification({
      message: msg`Something went wrong. Check your connection and try again.`,
      type: NotificationType.alert,
      autoDismiss: true
    })
  }

  function setSuccessBanner(): ActionWithPayload<
    GlobalActionTypes.SET_NOTIFICATION,
    {
      notification: NotificationProps
    }
  > {
    return globalActions.setNotification({
      message: msg`Settings were saved.`,
      type: NotificationType.success,
      autoDismiss: true
    })
  }

  function* saveAgreements(
    assignments: PsaCompanyContractAssignment[],
    successUrl: string,
    successAction
  ) {
    yield put(psaIntegrationsActions.setDrawerInProgress(true))

    const response: UACServices.SavePsaAgreementsAPI.Responses = yield call(
      services.savePsaAgreements,
      assignments
    )

    if (response.type === UACServices.SavePsaAgreementsAPI.SUCCESS) {
      yield put(push(successUrl))

      if (successAction) {
        yield put(successAction)
      }

      yield put(psaIntegrationsActions.getPsaProviderDetails())
      yield put(setSuccessBanner())
      yield put(psaIntegrationsActions.setDrawerContainsUnsavedChanges(false))
    } else {
      yield put(setErrorBanner())
    }
  }

  function getNoAgreementSelectedConfirmDialog() {
    const dialog: ConfirmDialog = {
      type: 'confirmdialog',
      confirmText: msg`Save anyway`,
      discardText: msg`Back`,
      text: msg`To ensure successful billing updates, go back and select an agreement for each company.`,
      title: msg`Billing only gets updated for companies with an agreement`
    }
    return dialog
  }
}

import { GroupFilter } from '@lastpass/admin-console-dependencies/types/group-filter'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'

export const buildFilterArray = (filters: UserFilter | GroupFilter) => {
  const filterObjectKeys = !!filters && Object.keys(filters)

  return (
    filterObjectKeys &&
    filters &&
    filterObjectKeys.map(filterName => {
      if (Array.isArray(filters[filterName])) {
        return parseArrayFilters(filters, filterName)
      }
      return { [filterName]: filters[filterName] }
    })
  )
}

const parseArrayFilters = (
  filters: UserFilter | GroupFilter,
  filterName: string
) => {
  return {
    or: filters[filterName].map(filterValue => {
      switch (filterName) {
        case 'inviteEmailSentTimeUtc':
          return { [filterName]: { lt: new Date(filterValue) } }
        case 'userId':
          return { [filterName]: parseInt(filterValue, 10) }
        case 'groupId':
          return { [filterName]: parseInt(filterValue, 10) }
        default:
          return { [filterName]: filterValue }
      }
    })
  }
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading300 } from '@lastpass/lastkit'

import { DirectoriesIntegrations } from '@lastpass/admin-console-dependencies/ui/users/directories/DirectoriesIntegrations'

const StyledHeader = styled(Heading300)`
  margin-left: 24px;
`

const StyledContainer = styled.div`
  margin: 24px;
`

export function DirectoriesIntegrationsPage() {
  return (
    <>
      <StyledHeader>
        <Trans>Directory integrations</Trans>
      </StyledHeader>
      <StyledContainer>
        <DirectoriesIntegrations />
      </StyledContainer>
    </>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { rsaSecureIDRadiusActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetRSASecureIDRadius(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getRSASecureIDRadius() {
    try {
      yield put(rsaSecureIDRadiusActions.setRSASecureIDRadiusLoading(true))
      try {
        const rsaSecureIDRadiusResult: UACServices.RSASecureIDRadiusGetAPI.Responses = yield call(
          enterpriseOptionServices.rsaSecureIDRadiusGet
        )
        yield put(
          rsaSecureIDRadiusActions.setRSASecureIDRadius(
            rsaSecureIDRadiusResult.body.rsaSecureIDRadius
          )
        )
      } finally {
        yield put(rsaSecureIDRadiusActions.setRSASecureIDRadiusLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

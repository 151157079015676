import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { PolicyAdminUser } from '@lastpass/admin-console-dependencies/types/policy'
import { policyTexts } from '@lastpass/admin-console-dependencies/types/policy-mapping'
import {
  InitialTeamsPolicyModelData,
  TeamsPolicy,
  TeamsPolicyModelData
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export interface GeneralPoliciesDrawerUrlParams {
  id: string
  configId: string
}

export interface GeneralPoliciesDrawerQueryParams {
  search: string
  skip: number
}

export enum GeneralPoliciesDrawerActionTypes {
  RESET = '[general-policies-drawer] reset',
  GET_CURRENT_GENERAL_POLICY = '[general-policies-drawer] get-current-general-policy',
  SET_CURRENT_GENERAL_POLICY = '[general-policies-drawer] set-current-general-policy',
  SET_GENERAL_POLICY_LOADING = '[general-policies-drawer] set-general-policy-loading',
  SET_CURRENT_GENERAL_POLICY_DATA_NEEDS_REFRESH = '[general-policies-drawer] set-current-general-policy-data-needs-refresh',
  SET_CURRENT_GENERAL_POLICY_DRAWER_OPEN = '[general-policies-drawer] set-current-general-policy-drawer-open',
  ENABLE_CURRENT_GENERAL_POLICY = '[general-policies-drawer] enable-current-general-policy',
  DISABLE_CURRENT_GENERAL_POLICY = '[general-policies-drawer] disable-current-general-policy',
  ADD_CONFIG_CURRENT_GENERAL_POLICY = '[general-policies-drawer] add-config-current-general-policy',
  REMOVE_CONFIG_CURRENT_GENERAL_POLICY = '[general-policies-drawer] remove-config-current-general-policy',
  GET_ADMIN_LIST = '[general-policies-drawer] get-admin-list',
  SET_ADMIN_LIST = '[general-policies-drawer] set-admin-list',
  ADD_SELECTED_ADMINS = '[general-policies-drawer] add-selected-admins',
  REMOVE_SELECTED_ADMINS = '[general-policies-drawer] remove-selected-admins',
  SET_SELECTED_ADMINS = '[general-policies-drawer] set-selected-admins',
  ENABLE_ADMIN_POLICY = '[general-policies-drawer] enable-admin-policy',
  SET_CURRENT_POLICY_DATA = '[general-policies-drawer] set_current-policy-data',
  UPDATE_CURRENT_POLICY_DATA = '[general-policies-drawer] update_current-policy-data',
  SET_UNSAVED_POLICY = '[general-policies-drawer] set_unsaved_policy'
}

export const generalPoliciesDrawerActions = {
  reset: () => createAction(GeneralPoliciesDrawerActionTypes.RESET),
  getCurrentGeneralPolicy: (
    params: DataFetchParams<{}, GeneralPoliciesDrawerUrlParams>
  ) =>
    createAction(GeneralPoliciesDrawerActionTypes.GET_CURRENT_GENERAL_POLICY, {
      params
    }),
  setCurrentGeneralPolicy: (policy: TeamsPolicy) =>
    createAction(GeneralPoliciesDrawerActionTypes.SET_CURRENT_GENERAL_POLICY, {
      policy
    }),
  setGeneralPolicyLoading: (loading: boolean) =>
    createAction(
      GeneralPoliciesDrawerActionTypes.SET_GENERAL_POLICY_LOADING,
      loading
    ),
  setCurrentGeneralPolicyDataNeedsRefresh: (
    drawerNeedsRefresh: boolean,
    policyKey: string
  ) =>
    createAction(
      GeneralPoliciesDrawerActionTypes.SET_CURRENT_GENERAL_POLICY_DATA_NEEDS_REFRESH,
      { drawerNeedsRefresh, policyKey }
    ),
  setCurrentGeneralPolicyDrawerOpen: (isDrawerOpen: boolean) =>
    createAction(
      GeneralPoliciesDrawerActionTypes.SET_CURRENT_GENERAL_POLICY_DRAWER_OPEN,
      { isDrawerOpen }
    ),
  enableCurrentGeneralPolicy: (
    policyKey: string,
    initialTeamsPolicyModelData: InitialTeamsPolicyModelData | null = null
  ) =>
    track(
      createAction(
        GeneralPoliciesDrawerActionTypes.ENABLE_CURRENT_GENERAL_POLICY,
        {
          policyKey,
          initialTeamsPolicyModelData
        }
      ),
      {
        segment: uacEvent('Policy Enabled', {
          Source: 'Policy Detail Page',
          'Policy Name': policyTexts[policyKey]
            ? policyTexts[policyKey].title.id
            : 'Unknown'
        })
      }
    ),
  disableCurrentGeneralPolicy: (policyKey: string) =>
    track(
      createAction(
        GeneralPoliciesDrawerActionTypes.DISABLE_CURRENT_GENERAL_POLICY,
        {
          policyKey
        }
      ),
      {
        segment: uacEvent('Policy Disabled', {
          Source: 'Policy Detail Page',
          'Policy Name': policyTexts[policyKey]
            ? policyTexts[policyKey].title.id
            : 'Unknown'
        })
      }
    ),
  addConfigCurrentGeneralPolicy: (policyKey: string) =>
    createAction(
      GeneralPoliciesDrawerActionTypes.ADD_CONFIG_CURRENT_GENERAL_POLICY,
      {
        policyKey
      }
    ),
  removeConfigCurrentGeneralPolicy: (
    policyKey: string,
    policyConfigIndex: number
  ) =>
    createAction(
      GeneralPoliciesDrawerActionTypes.REMOVE_CONFIG_CURRENT_GENERAL_POLICY,
      {
        policyKey,
        policyConfigIndex
      }
    ),
  getAdminList: () =>
    createAction(GeneralPoliciesDrawerActionTypes.GET_ADMIN_LIST),
  setAdminList: (admins: PolicyAdminUser[]) =>
    createAction(GeneralPoliciesDrawerActionTypes.SET_ADMIN_LIST, { admins }),
  addSelectedAdmins: (admins: PolicyAdminUser[]) =>
    createAction(GeneralPoliciesDrawerActionTypes.ADD_SELECTED_ADMINS, {
      admins
    }),
  removeSelectedAdmins: (admins: PolicyAdminUser[]) =>
    createAction(GeneralPoliciesDrawerActionTypes.REMOVE_SELECTED_ADMINS, {
      admins
    }),
  setSelectedAdmins: (admins: PolicyAdminUser[]) =>
    createAction(GeneralPoliciesDrawerActionTypes.SET_SELECTED_ADMINS, {
      admins
    }),
  enableAdminPolicy: (policyKey: string, admins: PolicyAdminUser[]) =>
    createAction(GeneralPoliciesDrawerActionTypes.ENABLE_ADMIN_POLICY, {
      policyKey,
      admins
    }),
  setCurrentPolicyData: (policyData: TeamsPolicyModelData) =>
    createAction(GeneralPoliciesDrawerActionTypes.SET_CURRENT_POLICY_DATA, {
      policyData
    }),
  updateCurrentPolicyData: (policyData: TeamsPolicyModelData, index: number) =>
    createAction(GeneralPoliciesDrawerActionTypes.UPDATE_CURRENT_POLICY_DATA, {
      policyData,
      index
    }),
  setUnsavedPolicy: (policy: TeamsPolicy) =>
    createAction(GeneralPoliciesDrawerActionTypes.SET_UNSAVED_POLICY, {
      policy
    })
}

export type GeneralPoliciesDrawerActions = ActionsUnion<
  typeof generalPoliciesDrawerActions
>

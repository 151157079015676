import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicyAssignUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/state'
import {
  GeneralPolicyUsersAndGroupsQueryParams,
  GeneralPolicyUsersAndGroupsUrlParams
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import { GeneralPolicyEditAssignedItemsForm } from '@lastpass/admin-console-dependencies/ui/policies/general/GeneralPolicyEditAssignedItemsForm/GeneralPolicyEditAssignedItemsForm'

export interface GeneralPolicyAssignUsersProps {
  shouldHideUsersOptions: boolean
}

export const AssignGeneralPolicyUsers: React.FunctionComponent<GeneralPolicyAssignUsersProps> = ({
  shouldHideUsersOptions
}) => {
  const queryParams: GeneralPolicyUsersAndGroupsQueryParams = useQueryParams(
    Namespace.policyassignusers
  )
  const updateQuery = useUpdateQuery(Namespace.policyassignusers)
  const pathParams: GeneralPolicyUsersAndGroupsUrlParams = usePathParams()

  const generalPolicyAssignUsersAndGroupsFormState: GeneralPolicyAssignUsersAndGroupsFormState = useSelector(
    (state: AppState) => state.generalPolicyAssignUsersAndGroups
  )
  const dispatch = useDispatch()

  const match = useRouteMatch()
  const matchUrl = match && match.url

  useEffect(() => {
    dispatch(
      generalPolicyAssignUsersAndGroupsActions.getGeneralPolicyAssignUsersAndGroups(
        { query: queryParams, path: pathParams }
      )
    )
  }, [dispatch, pathParams, queryParams])
  const policyConfiglink = `/policies/generalpolicies/${generalPolicyAssignUsersAndGroupsFormState.policyAssignUsersAndGroups.policyKey}`
  const editUsersLink = `${policyConfiglink}/edit-users/${generalPolicyAssignUsersAndGroupsFormState.policyAssignUsersAndGroups.policyConfigIndex}`

  return (
    <GeneralPolicyEditAssignedItemsForm
      shouldHideUsersOptions={shouldHideUsersOptions}
      policyUsersAndGroups={
        generalPolicyAssignUsersAndGroupsFormState.policyAssignUsersAndGroups
      }
      usersAndGroupsNeedsRefresh={
        generalPolicyAssignUsersAndGroupsFormState.assignUsersAndGroupsNeedsRefresh
      }
      policyConfigUsers={
        generalPolicyAssignUsersAndGroupsFormState.policyConfigUsers
      }
      policyConfigGroups={
        generalPolicyAssignUsersAndGroupsFormState.policyConfigGroups
      }
      usersChangesList={
        generalPolicyAssignUsersAndGroupsFormState.toBeAddedUsers
      }
      groupsChangesList={
        generalPolicyAssignUsersAndGroupsFormState.toBeAddedGroups
      }
      policyCurrentlyAppliesTo={
        generalPolicyAssignUsersAndGroupsFormState.policyCurrentlyAppliesTo
      }
      hasReceivedServerResponse={
        generalPolicyAssignUsersAndGroupsFormState.hasReceivedServerResponse
      }
      currentUrl={matchUrl || ''}
      searchString={queryParams.search}
      id={pathParams.id}
      configId={pathParams.configId}
      isForAssign={true}
      cancelLink={shouldHideUsersOptions ? editUsersLink : policyConfiglink}
      onSubmitted={formData =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.saveGeneralPolicyAssignUsersAndGroups(
            formData
          )
        )
      }
      onReset={() => dispatch(generalPolicyAssignUsersAndGroupsActions.reset())}
      onSearchStringUpdated={(searchString?: string) =>
        updateQuery({ search: searchString })
      }
      onCheckGeneralPolicyAppliesTo={(appliesTo, params) =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.checkGeneralPolicyAppliesTo(
            appliesTo,
            params
          )
        )
      }
      onAddSelectedGroups={groupList =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.addSelectedGeneralPolicyAssignGroups(
            groupList
          )
        )
      }
      onRemoveSelectedGroups={groupList =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.removeSelectedGeneralPolicyAssignGroups(
            groupList
          )
        )
      }
      onSetSelectedGroups={groupList =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.setSelectedGeneralPolicyAssignGroups(
            groupList
          )
        )
      }
      onAddSelectedUsers={userList =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.addSelectedGeneralPolicyAssignUsers(
            userList
          )
        )
      }
      onRemoveSelectedUsers={userList =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.removeSelectedGeneralPolicyAssignUsers(
            userList
          )
        )
      }
      onSetSelectedUsers={userList =>
        dispatch(
          generalPolicyAssignUsersAndGroupsActions.setSelectedGeneralPolicyAssignUsers(
            userList
          )
        )
      }
      onSetGeneralPolicyUsersAndGroupsAppliesToForAssign={() => {}}
    />
  )
}

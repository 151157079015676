import styled from '@emotion/styled'

import { BodySemiboldStyle } from '../styles'

export interface TextButtonProps {
  blue?: boolean
  red?: boolean
}

export const TextButton = styled.button<TextButtonProps>`
  ${BodySemiboldStyle}
  border: none;
  background: none;
  text-decoration: none;
  text-transform: none;
  outline: none;
  color: ${props =>
    (props.blue && props.theme.colors.blue700) ||
    (props.red && props.theme.colors.red700) ||
    props.theme.colors.neutral700};

  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue800};
  }
  &:focus {
    color: ${props => props.theme.colors.neutral700};
  }
  &:disabled {
    color: ${props => props.theme.colors.neutral500};
  }
  &:active {
    color: ${props => props.theme.colors.neutral700};
  }
`

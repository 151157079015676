import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import { UnknownServiceError } from '@lastpass/admin-console-dependencies/server/responses'

export function createAcronisApi(fetch: AuthFetch) {
  function createFetch<TResult>(
    url: string,
    method: string,
    getResult: (response: string) => TResult,
    body?: {}
  ) {
    return async function() {
      try {
        const [response, status] = await fetch(url, method, body)
        if (status === StatusCodes.OK && response) {
          return getResult(response)
        }
      } catch (e) {
        throw new UnknownServiceError()
      }
    }
  }

  return {
    getProvisioningHash: createFetch<boolean>(
      'company/acronis-api-key',
      'GET',
      response => {
        const keyResponse = JSON.parse(response).content
        return keyResponse.hasSecret
      }
    ),
    generateProvisioningHash: createFetch<string>(
      'company/acronis-api-key',
      'POST',
      response => {
        const keyResponse = JSON.parse(response).content
        return keyResponse.secret
      },
      {}
    )
  }
}

export type AcronisApiServiceType = ReturnType<typeof createAcronisApi>

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { NeverOnlyUrls } from '@lastpass/admin-console-dependencies/types/never-only-urls'
export const SUCCESS = 'success'

const success = (urlRules: NeverOnlyUrls) =>
  createResponse(SUCCESS, { urlRules })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'v3/company/company-never-only-urls',
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const urlRulesParsed = JSON.parse(response)
        const urlRulesResponse: NeverOnlyUrls = {
          neverUrls: urlRulesParsed['content']['neverUrls'].toString(),
          onlyUrls: urlRulesParsed['content']['onlyUrls'].toString()
        }
        return success(urlRulesResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { decrypt } from '@lastpass/encryption/rsa'

import { UsersWithRecoveryKeys } from '@lastpass/admin-console-dependencies/types/recovery-keys/user-recovery-keys'

export type UsersWithDecryptedRecoveryKeys = { [email: string]: Buffer }

export const getUsersWithDecryptedRecoveryKeys = async (
  abortController: AbortController,
  decryptedPrivateSharingKey: string,
  usersWithEncryptedRecoveryKeys: UsersWithRecoveryKeys
): Promise<UsersWithDecryptedRecoveryKeys> => {
  const usersWithDecryptedRecoveryKeys: UsersWithDecryptedRecoveryKeys = {}

  const userEmails = Object.keys(usersWithEncryptedRecoveryKeys)

  for (const email of userEmails) {
    const encryptedRecoveryKey = usersWithEncryptedRecoveryKeys[email]
    if (abortController.signal.aborted) {
      break
    }
    const decryptedRecoveryKeyBuffer = await decryptRecoveryKey(
      encryptedRecoveryKey,
      decryptedPrivateSharingKey
    )

    usersWithDecryptedRecoveryKeys[email] = decryptedRecoveryKeyBuffer
  }
  return usersWithDecryptedRecoveryKeys
}

export const decryptRecoveryKey = async (
  encryptedRecoveryKey: string,
  decryptedPrivateSharingKey: string
): Promise<Buffer> => {
  const decryptedRecoveryKeyBuffer = await decrypt(
    Buffer.from(encryptedRecoveryKey, 'hex'),
    Buffer.from(decryptedPrivateSharingKey, 'hex')
  )

  return decryptedRecoveryKeyBuffer
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { PsaCompanyContractAssignment } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    companyContractAssignment: PsaCompanyContractAssignment[]
  ) {
    try {
      const [response, status] = await fetch(
        `v3/psa-contracts`,
        'POST',
        {
          companyContractAssignment
        },
        { 'Content-Type': 'application/json' }
      )

      if (status === StatusCodes.OK && response) {
        return success()
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

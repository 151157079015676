import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Mfa from '@lastpass/admin-console-dependencies/server/applications/mfa'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/delete-application-server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { mfaApplicationListActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createUpdateMfaApplication(
  applicationsServices: UACServices.Services
) {
  return function* updateMfaApplication(
    action: ReturnType<typeof mfaAppsDrawerActions.saveApp>
  ) {
    try {
      yield put(mfaAppsDrawerActions.setDrawerLoading(true))

      const name = `${action.payload.app.type}#${action.payload.app.name}`
      const response: Responses = yield call(
        applicationsServices.updateMfaApplication,
        action.payload.app.key,
        name,
        action.payload.app.mappingKey,
        !(
          action.payload.app.userIds.length > 0 ||
          action.payload.app.groupIds.length > 0
        ),
        action.payload.app.userIds,
        action.payload.app.groupIds
      )

      if (response.type === Mfa.MfaApplicationUpdateAPI.SUCCESS) {
        yield put(
          globalActions.setNotification({
            message: msg`App changes saved.`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )

        const currentUrlFilters = yield select(getCurrentUrlFilters)
        yield put(
          mfaApplicationListActions.getMfaApplicationList({
            query: getQueryParams(currentUrlFilters.location, 'apps'),
            path: {}
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(mfaAppsDrawerActions.setDrawerLoading(false))
    }
  }
}

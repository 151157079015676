import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegular,
  CardContainer,
  Collapsible,
  Heading100,
  Heading300
} from '@lastpass/lastkit'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

import { PermissionTree } from '../permission-tree/PermissionTree'

const StyledBodyText = styled(BodyRegular)`
  display: inline-block;
  margin-top: 8px;
`

export interface AdminLevelPermissionsTreeCardProps {
  formik: FormikProps<AdminFormValues>
  disabled?: boolean
  hasManagedAdminPermissions?: boolean
}

export const AdminLevelPermissionsTreeCard: React.FunctionComponent<AdminLevelPermissionsTreeCardProps> = ({
  formik,
  disabled,
  hasManagedAdminPermissions = false
}) => {
  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const shouldShowManagedPermissions =
    disabled && isRoleBasedAccessControlEnabled && hasManagedAdminPermissions

  const {
    permissionCategories,
    managedPermissionCategories,
    adminLevel: { mspCidOverrideAdminLevel = MspCidOverrideLevel.admin }
  } = useSelector<AppState, AdminLevelDrawerState>(
    state => state.adminLevelDrawer
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      adminLevelDrawerActions.getAdminPermissions(
        isRoleBasedAccessControlEnabled
      )
    )

    hasManagedAdminPermissions &&
      dispatch(adminLevelDrawerActions.getManagedAdminPermissions())
  }, [dispatch, isRoleBasedAccessControlEnabled, hasManagedAdminPermissions])

  return (
    <CardContainer
      data-qa={
        isRoleBasedAccessControlEnabled && hasManagedAdminPermissions
          ? 'ManagedPermissionsCard'
          : 'PermissionsCard'
      }
      css={css`
        margin-top: 16px;
        border: 0;
      `}
    >
      <Collapsible
        data-qa="CardHeader"
        expanded={true}
        title={
          hasManagedAdminPermissions ? (
            <Trans>Managed company admin level</Trans>
          ) : (
            <Trans>Permissions</Trans>
          )
        }
      >
        {shouldShowManagedPermissions && (
          <>
            <StyledBodyText>
              <Trans>Managed company permissions</Trans>
            </StyledBodyText>

            <Heading300 data-qa="CurrentAdminLevel">
              {mspCidOverrideAdminLevel}
            </Heading300>

            {mspCidOverrideAdminLevel === MspCidOverrideLevel.custom && (
              <Heading100>
                <Trans>Permissions</Trans>
              </Heading100>
            )}
          </>
        )}

        {(!shouldShowManagedPermissions ||
          mspCidOverrideAdminLevel === MspCidOverrideLevel.custom) && (
          <PermissionTree
            formik={formik}
            disabled={disabled}
            permissionCategories={
              isRoleBasedAccessControlEnabled && hasManagedAdminPermissions
                ? managedPermissionCategories
                : permissionCategories
            }
          />
        )}
      </Collapsible>
    </CardContainer>
  )
}

export const articleIds = [
  '15five',
  '23video',
  '360facility',
  '4me',
  '6sense',
  '7geese',
  '8x8',
  '9slides',
  'absorb',
  'aca-aponix',
  'academy-lms-by-praetorian-digital',
  'accellion',
  'acl',
  'adallom',
  'adobe-aem',
  'adobe-cq',
  'adobe-creative-cloud',
  'adobe-sign',
  'adp',
  'adprobo',
  'aha',
  'airtable',
  'akamai',
  'alertops',
  'amazon-appstream',
  'amazon-redshift',
  'amp-portal',
  'anodot',
  'answerhub',
  'appblade',
  'appdynamics',
  'appian',
  'arcgis-online',
  'ariba-contract-mgmt',
  'artifactory',
  'arxspan',
  'asana',
  'aspera-faspex',
  'assembla',
  'atiim',
  'atipica',
  'atlassian-access',
  'atlassian-cloud',
  'aurion',
  'aviso',
  'aws',
  'bamboohr',
  'bambu',
  'base-crm',
  'benchling',
  'benetrac',
  'benevity',
  'betterworks',
  'biba',
  'bigpanda',
  'bime',
  'blissbook',
  'blue-ocean-brain',
  'bluejeans',
  'bluescape',
  'bmc-appzone',
  'bmc-atrium',
  'bob',
  'bonusly',
  'box',
  'brainshark',
  'brainstorm',
  'branch',
  'brandfolder',
  'breezy-hr',
  'brightedge',
  'brightfunds',
  'brightidea',
  'bt-cloud-phone',
  'bugsnag',
  'buildkite',
  'bullhorn',
  'bynder',
  'canvas-lms',
  'cardboard',
  'centurylink',
  'certify',
  'chartio',
  'chromeriver',
  'cisco-identity-service',
  'clari',
  'clarizen',
  'clearcompany',
  'clearpass',
  'clearslide',
  'clearstory',
  'cloudbees',
  'cloudlock',
  'cloudmine',
  'cloudpassage',
  'code42',
  'coderpad',
  'coggle',
  'collective-health',
  'compas',
  'comscore',
  'concur',
  'contently',
  'contractworks',
  'cornerstone-ondemand',
  'coupa',
  'crashplan-pro',
  'crossknowledge',
  'crosslead',
  'culture-amp',
  'culturehq',
  'customshow',
  'databook',
  'datacastle',
  'datadog',
  'dealersocket',
  'degreed',
  'desk-com',
  'deskpro',
  'detectify',
  'digicert',
  'docebo',
  'docsend',
  'docurated',
  'docusign',
  'dropbox',
  'droplr',
  'druva',
  'dsi',
  'dynamic-signal',
  'echosign',
  'edubrite',
  'efront',
  'egencia',
  'egnyte',
  'ekarda',
  'eleap',
  'elogic',
  'emarketer',
  'emobus',
  'employeechannel',
  'engagedly',
  'engagio',
  'envoy',
  'epromo',
  'erp-maestro',
  'estratex',
  'etico-software',
  'euromonitor',
  'everlaw',
  'evernote',
  'everyonesocial',
  'evident-io',
  'expensable',
  'expensify',
  'ezrentout',
  'f5-big-ip',
  'factiva',
  'favro',
  'finario',
  'findo',
  'flatterfiles',
  'float',
  'floqast',
  'flowdock',
  'fm-systems',
  'fossa',
  'fotoweb',
  'frankly',
  'freshdesk',
  'freshservice',
  'front',
  'fuze',
  'gaggle',
  'gaggleamp',
  'geolearning',
  'getsaydo',
  'github',
  'gliffy',
  'global-relay',
  'globoforce',
  'golinks',
  'gooddata',
  'google-cloud-platform',
  'gotoassist',
  'gotomeeting',
  'gototraining',
  'gotowebinar',
  'gr8-people',
  'greenhouse',
  'grovo',
  'gsuite',
  'guidespark',
  'hackerrank',
  'happyfox',
  'hellosign',
  'highfive',
  'highq',
  'highspot',
  'hightail',
  'hireright',
  'hirevue',
  'honest-buildings',
  'hootsuite',
  'hostanalytics',
  'hosted-graphite',
  'huddle',
  'i-get-it',
  'ibm-connections-cloud',
  'ibm-gers',
  'ibm-smartcloud',
  'ideascale',
  'idmworks',
  'ignitecast',
  'iipay',
  'ilandman',
  'imeet-central',
  'impraise',
  'ims-health',
  'incentfit',
  'ingeniux',
  'innotas',
  'insideview',
  'inspired',
  'instacheckin',
  'intralinks',
  'invisionapp',
  'ipfolio',
  'irbmanager',
  'irise',
  'itprotv',
  'jama-software',
  'jira',
  'jitbit',
  'jive',
  'jobscore',
  'jobvite',
  'join-me',
  'jostle',
  'jtask',
  'jubilant',
  'kanjoya',
  'kato',
  'keas',
  'kentik',
  'kinaxis',
  'kindling',
  'kintone',
  'kissflow',
  'klipfolio',
  'klue',
  'knowbe4',
  'knowledgehound',
  'kore',
  'lattice',
  'leankit',
  'lessonly',
  'lever',
  'ligo',
  'linkedin-learning',
  'linkedin',
  'liquidfiles',
  'liquidplanner',
  'listentool',
  'livechat',
  'livehive',
  'liveperson',
  'liveramp',
  'logic-monitor',
  'logit',
  'looker',
  'looop',
  'lucidchart',
  'lumity',
  'lynda',
  'maas360',
  'mambu',
  'manageengine',
  'mangoapps',
  'maxxpoint',
  'menlo-security',
  'meraki',
  'merlin-guides',
  'mimeo',
  'mindflash',
  'mixpanel',
  'moat',
  'mobi-wireless-management',
  'monday-com',
  'motivosity',
  'moxtra',
  'mulesoft',
  'mypayportal',
  'mypolicies',
  'namely',
  'nationbuilder',
  'netdocuments',
  'netexam',
  'netscaler-gateway',
  'new-relic',
  'nexonia',
  'nimbleschedule',
  'nmbrs',
  'noggin-oca',
  'norton-zone',
  'observable',
  'office-365',
  'okta',
  'omnilert',
  'ondmarc',
  'one-codex',
  'openair',
  'opsgenie',
  'oracle-crm',
  'oracle-fusion',
  'oracle-hyperion-epm-cloud',
  'oracle-hyperion-epm',
  'pacific-timesheet',
  'pagerduty',
  'pagetiger',
  'palo-alto-network',
  'panorama9',
  'pathgather',
  'pathsavvy',
  'peakon',
  'pendo',
  'pilgrim-smartsolve',
  'pivotal-tracker',
  'pivotal',
  'plangrid',
  'planmyleave',
  'platform9',
  'pleasereview',
  'popin',
  'postbeyond',
  'productip',
  'projectplace',
  'prolease',
  'proofhq',
  'proxyclick',
  'mfa-pulsesecure-vpn-integ',
  'qminder',
  'qpharma',
  'qstream',
  'qualaroo',
  'qubole',
  'quickhelp',
  'reflektive',
  'relativity',
  'remedyforce-bmc',
  'replicon',
  'reprints-desk',
  'retrium',
  'rightscale',
  'ringcentral',
  'riskanalytics',
  'rolepoint',
  'rollbar',
  'ruvixx',
  'safetystratus',
  'salesforce',
  'saleshood',
  'salsify',
  'samanage',
  'sap-cloud',
  'sauce-labs',
  'scaleft',
  'sciforma',
  'seaurchin-io',
  'secret-server',
  'sendergen',
  'sendsafely',
  'sentry',
  'sequr',
  'servicenow',
  'sharefile',
  'shareworks',
  'sharpen',
  'sharpr',
  'showpad',
  'sighten-io',
  'sightplan',
  'signalfx',
  'sigopt',
  'simppler',
  'sisense',
  'siterra',
  'skeddly',
  'skilljar',
  'skyhigh-networks',
  'skyprep',
  'skytap',
  'slack',
  'sli-do',
  'small-improvements',
  'smarteru',
  'smartfile',
  'smartsheet',
  'snapengage',
  'snaplogic',
  'snowflake',
  'socialcast',
  'spacebase',
  'splunk-cloud',
  'spoke',
  'stackla',
  'startmeeting',
  'statushub',
  'successfactors',
  'sugarcrm',
  'sumologic',
  'sumtotal-learning',
  'sumtotal-systems',
  'surveymonkey',
  'symantec-endpoint-protection',
  'symantec-web-security-service',
  'syncplicity',
  'synerion',
  'tableau',
  'talentlms',
  'taleo',
  'talkdesk',
  'talla',
  'teamable',
  'teamviewer',
  'telus-business-connect',
  'textexpander',
  'textmagic',
  'thinair',
  'thirdpartytrust',
  'thomsons-online-benefits',
  'thousandeyes',
  'tracegains',
  'trakstar',
  'trello',
  'trustar',
  'udemy',
  'ultipro',
  'userecho',
  'uservoice',
  'veracode',
  'verkada',
  'versionone',
  'victorops',
  'vision-wireless',
  'vodeclic',
  'volunteermatch',
  'wake',
  'watchdox',
  'webdam',
  'webex',
  'wegowise',
  'wellworks-for-you',
  'wgm-apps',
  'when-i-work',
  'whistic',
  'whosonlocation',
  'widen',
  'wiredrive',
  'wombat-security',
  'workboard',
  'workfront',
  'workiva',
  'workplace',
  'workramp',
  'workstars',
  'workterra',
  'wrike',
  'xaitporter',
  'xendo',
  'xpand',
  'yambla',
  'yammer',
  'yapmo',
  'yardi-elearning',
  'yardi-voyager',
  'yardi',
  'yodeck',
  'yourcause',
  'zendesk',
  'zesty',
  'ziflow',
  'zinc-it',
  'zoho-one',
  'zoho',
  'zoom',
  'zoomforth',
  'zscaler',
  'zugata',
  'zuora'
]

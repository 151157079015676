import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { neverOnlyUrlActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/neveronlyurls/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createSaveNeverOnlyUrlRulesSaga(
  neverOnlyUrlServices: UACServices.Services
) {
  return function* saveNeverOnlyUrlRules(
    action: ReturnType<typeof neverOnlyUrlActions.setNeverOnlyUrls>
  ) {
    try {
      yield put(neverOnlyUrlActions.setNeverOnlyUrlsLoading(true))
      try {
        yield call(
          neverOnlyUrlServices.neverOnlyUrlsSet,
          action.payload.neverOnlyUrls
        )
      } finally {
        yield put(neverOnlyUrlActions.setNeverOnlyUrlsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

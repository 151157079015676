import { LegacyVpnListActions, LegacyVpnListActionTypes } from './actions'
import { LegacyVpnListState } from './state'

export const initialState: LegacyVpnListState = {
  table: {
    results: [],
    resultsPerPage: 1,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  getStarted: false
}

export function legacyVpnListReducer(
  previousState: LegacyVpnListState | undefined,
  action: LegacyVpnListActions
): LegacyVpnListState {
  const state = previousState || initialState

  switch (action.type) {
    case LegacyVpnListActionTypes.SET_LEGACY_VPN_LIST: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.list,
          totalResults: action.payload.list.length
        }
      }
    }
    case LegacyVpnListActionTypes.GET_STARTED:
      return {
        ...state,
        getStarted: true
      }
  }

  return state
}

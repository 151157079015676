import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps, Formik, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import {
  Checkbox,
  Dropdown,
  DropdownListItem,
  IconButton,
  PrimaryButton
} from '@lastpass/lastkit/components'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { enabledMultifactorOptionsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/actions'
import {
  EnabledMultifactorOptionsState,
  MultifactorOption
} from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/state'

import { OptionsCard } from './OptionsCard'

const FormContainer = styled.div`
  display: block;
`
const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const StyledButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin: 24px 10px 12px 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const CheckboxContainer = styled.div`
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 1;
  margin-right: 24px;
`
const StyledLabel = styled.label`
  ${BodyRegularStyle}
`

const HeaderButton = props => <IconButton icon={More} {...props} />
const StyledDropdown = styled(Dropdown)`
  height: 40px;
  width: 120px;
  margin: 24px 10px 12px 0;
  z-index: 1;
`

interface EnabledMultifactorOptionsProps {
  expand?: boolean
  closeOtherCards: (expandStatus: boolean) => void
  onFormActivity: (formStatus: boolean) => void
}

const isSubmitDisabled = (
  formValues: Record<string, boolean>,
  options: MultifactorOption[],
  onFormActivity: (formStatus: boolean) => void
): boolean => {
  let disable = true
  for (const option of options) {
    if (formValues[option.id] !== option.selected) {
      disable = false
      break
    }
  }
  onFormActivity(!disable)
  return disable
}

export const EnabledMultifactorOptionsCard: FunctionComponent<EnabledMultifactorOptionsProps> = ({
  expand,
  closeOtherCards,
  onFormActivity
}) => {
  const dispatch = useDispatch()
  const enabledMultifactorOptionsState: EnabledMultifactorOptionsState = useSelector(
    (state: AppState) => state.enabledMultifactorOptions
  )

  const formData = enabledMultifactorOptionsState.options.reduce(
    (accumulator, currentValue) => {
      return { ...accumulator, [currentValue.id]: currentValue.selected }
    },
    {}
  )
  const optionIdToNameMap = enabledMultifactorOptionsState.options.reduce(
    (accumulator, currentValue) => {
      return { ...accumulator, [currentValue.id]: currentValue.name }
    },
    {}
  )
  const formDataKeys = Object.keys(formData)
  const formref = useRef<FormikProps<typeof formData>>(null)
  useEffect(() => {
    if (!expand) {
      if (formref.current) {
        formref.current.resetForm()
      }
    }
  }, [expand])

  useEffect(() => {
    dispatch(enabledMultifactorOptionsActions.getEnabledMultifactorOptions())
  }, [dispatch])

  return (
    <OptionsCard
      title={<Trans>Enabled multifactor options</Trans>}
      loading={enabledMultifactorOptionsState.loading}
      isInitialExpand={!!expand}
      onExpansion={
        enabledMultifactorOptionsActions.getEnabledMultifactorOptions
      }
      closeOtherCards={closeOtherCards}
    >
      <Formik
        enableReinitialize
        initialValues={formData}
        innerRef={formref}
        onSubmit={values => {
          const optionsData = enabledMultifactorOptionsState.options.map(
            option => {
              option.selected = values[option.id]
              return option
            }
          )
          dispatch(
            enabledMultifactorOptionsActions.saveEnabledMultifactorOptions(
              optionsData
            )
          )
        }}
      >
        {formikProps => {
          return (
            <FormContainer>
              <CheckboxesContainer>
                {formDataKeys.map(optionId => (
                  <CheckboxContainer key={optionId}>
                    <Field
                      name={optionId}
                      render={(formProps: FieldProps) => (
                        <Checkbox
                          id={optionId}
                          name={formProps.field.name}
                          checked={formProps.field.value}
                          onChange={e => {
                            formikProps.setFieldValue(
                              formProps.field.name,
                              e.currentTarget.checked
                            )
                          }}
                        >
                          <StyledLabel>
                            {optionIdToNameMap[optionId]}
                          </StyledLabel>
                        </Checkbox>
                      )}
                    />
                  </CheckboxContainer>
                ))}
              </CheckboxesContainer>
              <StyledButton
                disabled={isSubmitDisabled(
                  formikProps.values,
                  enabledMultifactorOptionsState.options,
                  onFormActivity
                )}
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
              >
                <Trans>Update</Trans>
              </StyledButton>
              <StyledDropdown button={HeaderButton} data-qa="MoreActionsButton">
                <DropdownListItem
                  red
                  data-qa="ResetMfaSharedSecretsDialog"
                  onClick={() => {
                    dispatch(
                      enabledMultifactorOptionsActions.resetMfaSharedSecrets()
                    )
                  }}
                >
                  <Trans>Reset MFA shared secrets</Trans>
                </DropdownListItem>
              </StyledDropdown>
            </FormContainer>
          )
        }}
      </Formik>
    </OptionsCard>
  )
}

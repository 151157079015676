import React, { FC, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as HouseIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/opporunities-box/house.svg'
import { ReactComponent as PolicyDisabledIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/opporunities-box/policy-disabled.svg'
import { PrimaryButton, TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { OpportunitiesBox } from '@lastpass/admin-console-dependencies/ui/home/components/opportunities-box/OpportunitiesBox'

const StyledTextButton = styled(TextButton)`
  padding: 0;
`

interface OpportunitiesBoxProps {
  usersCountWithStarterKitDisabled: number
  usersCountWithUnclaimedFaab: number
}

export const AdoptionDashboardOpportunitiesBox: FC<OpportunitiesBoxProps> = ({
  usersCountWithStarterKitDisabled,
  usersCountWithUnclaimedFaab
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const permissions = useContext(PermissionContext)
  const haveAccessToPolicies = permissions.requirePermission(
    AdminPermissions.policiesModify
  )

  const openPermissionRequiredToManagePoliciesDialog = () => {
    dispatch(
      globalActions.setDialog({
        type: 'permission-required-to-manage-policies-dialog'
      })
    )
  }

  const starterKitDisabledDescription = (
    <p data-qa="OpportunityBoxStarterKitDisabledDescription">
      <Trans>
        When the starter kit policy is enabled, users can see a side panel in
        their vault that helps them get the most out of LastPass.
      </Trans>{' '}
      <StyledTextButton blue>
        <LocationLink
          to="https://link.lastpass.com/onboarding-user-experience"
          data-qa="StarterKitDisabledLearnHowItWorksLink"
          target="_blank"
        >
          <Trans>Learn how it works</Trans>
        </LocationLink>
      </StyledTextButton>
    </p>
  )

  const starterKitButton = (
    <span data-qa="OpportunityBoxStarterKitDisabledActionEnableStarterKit">
      <PrimaryButton
        data-qa="EnableStarterKitButton"
        onClick={() => {
          if (haveAccessToPolicies) {
            history.push(`/policies/generalpolicies/showsecondaryonboarding`)
          } else {
            openPermissionRequiredToManagePoliciesDialog()
          }
        }}
      >
        <Trans>Enable starter kit</Trans>
      </PrimaryButton>
    </span>
  )

  const unclaimedFamiliesAccountsDescription = (
    <p data-qa="OpportunityBoxUnclaimedFamiliesAccountsDescription">
      <Trans>
        Protect your organization by encouraging users to separate their
        personal life from their work life.
      </Trans>{' '}
      <StyledTextButton blue>
        <LocationLink
          to="https://link.lastpass.com/faab-admin-faq"
          data-qa="UnclaimedFamiliesAccountsLearnHowItWorksLink"
          target="_blank"
        >
          <Trans>Learn how it works</Trans>
        </LocationLink>
      </StyledTextButton>
    </p>
  )

  const cardData = [
    {
      title: (
        <p data-qa="OpportunityBoxStarterKitDisabledTitle">
          <Trans>Starter kit disabled</Trans>
        </p>
      ),
      icon: PolicyDisabledIcon,
      count: usersCountWithStarterKitDisabled,
      description: starterKitDisabledDescription,
      action: starterKitButton,
      key: 'starterKit'
    },
    {
      title: (
        <span data-qa="OpportunityBoxUnclaimedFamiliesAccountsTitle">
          <Trans>Unclaimed Families accounts</Trans>
        </span>
      ),
      icon: HouseIcon,
      count: usersCountWithUnclaimedFaab,
      description: unclaimedFamiliesAccountsDescription,
      action: <></>,
      key: 'faab'
    }
  ]

  return (
    <OpportunitiesBox
      cardData={cardData}
      title={<Trans>Opportunities to boost usage and improve security</Trans>}
    />
  )
}

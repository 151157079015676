import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { RSASecureIDRadiusActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/actions'

import { createGetRSASecureIDRadius } from './get-rsa-secure-id-radius'
import { createSaveRSASecureIDRadius } from './save-rsa-secure-id-radius'

export function createRSASecureIDRadiusSaga(
  rsaSecureIDRadiusService: UACServices.Services
) {
  const getRSASecureIDRadiusSaga = createGetRSASecureIDRadius(
    rsaSecureIDRadiusService
  )
  const saveRSASecureIDRadiusSaga = createSaveRSASecureIDRadius(
    rsaSecureIDRadiusService
  )

  return function*() {
    yield takeEvery(
      RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_GET,
      getRSASecureIDRadiusSaga
    )
    yield takeEvery(
      RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_SAVE,
      saveRSASecureIDRadiusSaga
    )
  }
}

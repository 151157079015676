import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { SmartCrypto } from '@lastpass/cryptography'
import {
  BodyRegularStyle,
  PrimaryButton,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { DetailsDialog } from '@lastpass/lastkit'

import { prepareRecoveryKey } from '@lastpass/admin-console-dependencies/services/recovery-keys/prepare-recovery-key'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'
import { MasterPasswordConfirmDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'

const InputContainer = styled.div`
  margin-top: 20px;
`

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`

const StyledTextInput = styled(TextInput)`
  max-width: unset;
`

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 30px;
`

interface MasterPasswordConfirmFormValues {
  email: string
  password: string
}

export const MasterPasswordConfirmDialog: FC<{
  state: MasterPasswordConfirmDialogType
}> = ({ state }) => {
  const dispatch = useDispatch()

  const email = useSelector<AppState, string>(
    state => state.global.currentUser.email
  )

  const { isLoading, masterPasswordValidationData } = useSelector(
    (state: AppState) => state.securityReportDrawer
  )

  useEffect(() => {
    dispatch(securityReportDrawerActions.getMasterPasswordValidationData())
  }, [dispatch])

  const {
    encryptedPrivateRsaKey,
    requesterAdminIterationCount
  } = masterPasswordValidationData

  const validate = values => {
    return prepareRecoveryKey(
      values.email,
      values.password,
      requesterAdminIterationCount,
      encryptedPrivateRsaKey
    ).then(decryptedPrivateSharingKey => {
      let errors = {}
      if (decryptedPrivateSharingKey == '') {
        errors = {
          ...errors,
          password: i18n._(msg`Check your master password and try again`)
        }
      }
      dispatch(
        globalActions.setDecryptedPrivateSharingKey(decryptedPrivateSharingKey)
      )
      return errors
    })
  }

  const onSubmit = async values => {
    const derivedMasterKey = await SmartCrypto.symmetric.deriveMasterKey(
      values.password,
      values.email,
      false,
      requesterAdminIterationCount
    )
    const vaultKey = Buffer.from(
      await SmartCrypto.symmetric.exportKey(derivedMasterKey, false),
      'base64'
    )

    dispatch(globalActions.setVaultKey(vaultKey))

    if (state.onConfirm) {
      dispatch(state.onConfirm())
    }
    if (state.nextDialog) {
      dispatch(globalActions.setDialog(state.nextDialog))
    }
  }

  const masterPasswordSchema = Yup.object().shape({
    password: Yup.string().required(
      i18n._(msg`Check your master password and try again`)
    )
  })

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="634px"
      disableCloseOnBackground
      title={msg`Confirm your master password`}
      content={
        <>
          <StyledParagraph>{i18n._(state.description)}</StyledParagraph>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validate={validate}
            initialValues={{
              email: email,
              password: ''
            }}
            onSubmit={(values: MasterPasswordConfirmFormValues) => {
              onSubmit(values)
            }}
            validationSchema={masterPasswordSchema}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <InputContainer>
                  <StyledTextInput
                    id="email"
                    name="email"
                    type="email"
                    onBlur={formik.handleBlur}
                    data-qa="MasterPasswordEmail"
                    value={formik.values.email}
                    disabled
                  >
                    <Trans>Email</Trans>
                  </StyledTextInput>
                </InputContainer>
                <InputContainer>
                  <StyledTextInput
                    data-qa="MasterPasswordInput"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="password"
                    error={!!formik.errors.password}
                    errorText={<>{formik.errors.password}</>}
                  >
                    <Trans>Master password</Trans>
                  </StyledTextInput>
                </InputContainer>
                <>
                  {isLoading && (
                    <StyledLoadingContainer>
                      <Loading color="blue900" active={true} />
                    </StyledLoadingContainer>
                  )}
                </>

                <ButtonsContainer>
                  <TextButton
                    css={css`
                      margin-right: 18px;
                    `}
                    onClick={() => {
                      dispatch(globalActions.discardDialog())
                      dispatch(globalActions.emptyDialog())
                    }}
                    key="discard"
                    data-qa="MasterPasswordCancelButton"
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                  <PrimaryButton
                    data-qa="MasterPasswordConfirmButton"
                    type="submit"
                    key="confirm"
                    disabled={isLoading}
                  >
                    <Trans>Confirm</Trans>
                  </PrimaryButton>
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { debounce } from 'lodash'

import { InputProps, TextInput } from '@lastpass/lastkit'

interface ConfirmationWordInputProps {
  confirmationWord: string
  onChange: (isConfirmationWordCorrect: boolean) => void
}

const debounceInterval = 500
const defaultValue = ''

const ErrorTextContainer = styled.span`
  text-wrap: nowrap;
`

export const ConfirmationWordInput: FC<ConfirmationWordInputProps> = ({
  confirmationWord,
  onChange
}) => {
  const [value, setValue] = useState<string>(defaultValue)
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const upperCaseConfirmationWord = confirmationWord.toUpperCase()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetHasErrors = useCallback(
    debounce((isConfirmationWordCorrect: boolean): void => {
      setHasErrors(!isConfirmationWordCorrect)
    }, debounceInterval),
    []
  )

  useEffect(() => {
    return () => {
      debouncedSetHasErrors.cancel()
    }
  }, [debouncedSetHasErrors])

  const handleChange: InputProps['onChange'] = event => {
    const newValue = event.target.value
    const isConfirmationWordCorrect = upperCaseConfirmationWord === newValue

    setHasErrors(false)
    setValue(newValue)
    newValue !== defaultValue &&
      debouncedSetHasErrors(isConfirmationWordCorrect)
    onChange(isConfirmationWordCorrect)
  }

  const getErrorText = useCallback((): ReactElement | undefined => {
    if (!hasErrors) {
      return undefined
    }

    return (
      <ErrorTextContainer>
        <Trans>
          Enter exactly as shown (case sensitive): {upperCaseConfirmationWord}
        </Trans>
      </ErrorTextContainer>
    )
  }, [hasErrors, upperCaseConfirmationWord])

  return (
    <TextInput
      required
      name="ConfirmationWordInput"
      data-qa="ConfirmationWordInput"
      placeholder={msg`Enter ${upperCaseConfirmationWord}`}
      value={value}
      onChange={handleChange}
      error={hasErrors}
      errorText={getErrorText()}
      autoComplete="off"
    >
      <Trans>To continue, enter {upperCaseConfirmationWord}</Trans>
    </TextInput>
  )
}

import { useMemo } from 'react'

export const useQueryParams = (namespace?: string) => {
  const urlSearchParams =
    namespace && new URLSearchParams(location.search).get(namespace)

  return useMemo(() => {
    try {
      if (urlSearchParams) {
        return JSON.parse(Buffer.from(urlSearchParams, 'base64').toString())
      }
    } catch (e) {
      // continue regardless of error
    }
    return {}
  }, [urlSearchParams])
}

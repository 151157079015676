import { useEffect } from 'react'

export function useBrowserSafeguard() {
  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault()
      event.returnValue = '' // return value has to be set, but text is ignored in newer browser versions
    }
    window.addEventListener('beforeunload', handleTabClose)
    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [])
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'
import { SystemUpgradeDrawerHeadersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SystemUpgradeMapping'

import {
  DetailsBox,
  DrawerContainer,
  LegacyConsolePath,
  SimilarPoliciesList,
  SystemUpgradeDrawerFooter,
  SystemUpgradeDrawerProps,
  SystemUpgradeDrawerStyledLink,
  SystemUpgradeFeatureNotAvailable
} from './SystemUpgradeDrawerResources'

const detailsBoxContent = [
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatFor],
    dataQa: 'ipAllowDenyDrawerWhatFeatureForContainer',
    mainContent: (
      <Trans>
        This policy allowed/denied passwordless access to LastPass accounts from
        permitted/restricted IP addresses.
      </Trans>
    )
  },
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.where],
    dataQa: 'ipAllowDenyDrawerFeatureWhereContainer',
    mainContent: (
      <ul>
        <li>
          <Trans>New console</Trans>
          {': '}
          <SystemUpgradeDrawerStyledLink
            url="/policies/passwordless/ip"
            dataQa="ipAllowDenyDrawerNewConsoleLink"
          >
            <Trans>Policies</Trans>
            {' > '}
            <Trans>Passwordless</Trans>
            {' > '}
            <Trans>IP allow/deny</Trans>
          </SystemUpgradeDrawerStyledLink>
        </li>
        <li>
          <Trans>Old console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <LegacyConsolePath
            isNotAvailable={false}
            path={<Trans>Policy{' > '}IP</Trans>}
          />
        </li>
      </ul>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[
        SystemUpgradeDrawerHeader.whatsHappening
      ],
    dataQa: 'ipAllowDenyDrawerFeatureWhatHappeningContainer',
    mainContent: (
      <Trans>
        This policy will be replaced by similar policies, as described below.
      </Trans>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatToDo],
    dataQa: 'ipAllowDenyDrawerFeatureWhatToDoContainer',
    mainContent: (
      <>
        <Trans>
          To achieve similar goals after upgrade, use these similar policies:
        </Trans>
        <SimilarPoliciesList />
      </>
    )
  }
]

export const IpAllowDenyDrawer: React.FunctionComponent<SystemUpgradeDrawerProps> = ({
  closeLink
}) => (
  <Drawer
    mainTitle={<Trans>IP allow/deny</Trans>}
    subTitle={<Trans>Details</Trans>}
    closeLink={closeLink}
    data-qa="ipAllowDenyDrawer"
  >
    <DrawerContainer data-qa="ipAllowDenyDrawerContentContainer">
      {detailsBoxContent.map(content => (
        <DetailsBox
          key={content.dataQa}
          title={content.title}
          data-qa={content.dataQa}
        >
          {content.mainContent}
        </DetailsBox>
      ))}
    </DrawerContainer>
    <SystemUpgradeDrawerFooter
      closeLink={closeLink}
      data-qa="ipAllowDenyDrawerFooter"
    />
  </Drawer>
)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import {
  DirectoriesIntegrationsState,
  Provider
} from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { AdConnectorIntegrationCard } from './AdConnectorIntegrationCard'
import { AuditLogDrawer } from './AuditLogDrawer'
import { AzureAdIntegrationCard } from './AzureAdIntegrationCard'
import { GoogleIntegrationCard } from './GoogleIntegrationCard'
import { OktaIntegrationCard } from './OktaIntegrationCard'
import { OneLoginIntegrationCard } from './OneLoginIntegrationCard'
import { PingOneIntegrationCard } from './PingOneIntegrationCard'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const DirectoriesIntegrations: React.FunctionComponent = () => {
  const integrationsState: DirectoriesIntegrationsState = useSelector(
    (state: AppState) => state.integrations
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(directoriesActions.getDirectoryIntegrations())
  }, [dispatch])

  const isPingOneEnabled = useFeatureFlags(FeatureFlags.isPingOneEnabled)

  const match = useRouteMatch()

  if (!match) {
    return null
  }

  const { path, url } = match
  return (
    <>
      <StyledContainer>
        <AdConnectorIntegrationCard
          hasApiKey={integrationsState.ADConnector.hasApiKey}
          reset={() => {
            dispatch(directoriesActions.resetADConnectorApiKey())
          }}
          trackPrimaryButtonClick={() =>
            dispatch(
              directoriesActions.reportConfigureDirectoryClicked(
                Provider.ADConnector
              )
            )
          }
          lastSync={integrationsState.ADConnector.lastSync}
          version={integrationsState.ADConnector.version}
        />
        <AzureAdIntegrationCard
          expand={integrationsState.AzureAD.hasProvisionToken}
          getToken={() => {
            dispatch(
              directoriesActions.getDirectoryProvisionToken(Provider.AzureAD)
            )
          }}
          lastSync={integrationsState.AzureAD.lastSync}
          provisionToken={integrationsState.AzureAD.provisionToken}
          hasProvisionToken={integrationsState.AzureAD.hasProvisionToken}
          cid={integrationsState.AccountInfo.companyLastPassId}
        />
        <OktaIntegrationCard
          expand={integrationsState.Okta.hasProvisionToken}
          getToken={() => {
            dispatch(
              directoriesActions.getDirectoryProvisionToken(Provider.Okta)
            )
          }}
          lastSync={integrationsState.Okta.lastSync}
          provisionToken={integrationsState.Okta.provisionToken}
          hasProvisionToken={integrationsState.Okta.hasProvisionToken}
          cid={integrationsState.AccountInfo.companyLastPassId}
        />
        <OneLoginIntegrationCard
          expand={integrationsState.OneLogin.hasProvisionToken}
          getToken={() => {
            dispatch(
              directoriesActions.getDirectoryProvisionToken(Provider.OneLogin)
            )
          }}
          lastSync={integrationsState.OneLogin.lastSync}
          provisionToken={integrationsState.OneLogin.provisionToken}
          hasProvisionToken={integrationsState.OneLogin.hasProvisionToken}
          cid={integrationsState.AccountInfo.companyLastPassId}
        />
        <GoogleIntegrationCard
          expand={integrationsState.Google.hasProvisionToken}
          lastSync={integrationsState.Google.lastSync}
          provisionToken={integrationsState.Google.provisionToken}
          hasProvisionToken={integrationsState.Google.hasProvisionToken}
          adminEmail={integrationsState.Google.adminEmail}
          enabled={integrationsState.Google.enabled}
          integrationExists={integrationsState.Google.integrationExists}
          partialSyncEnabled={integrationsState.Google.partialSyncEnabled}
        />
        {isPingOneEnabled && (
          <PingOneIntegrationCard
            expand={integrationsState.PingOne.hasProvisionToken}
            getToken={() => {
              dispatch(
                directoriesActions.getDirectoryProvisionToken(Provider.PingOne)
              )
            }}
            lastSync={integrationsState.PingOne.lastSync}
            provisionToken={integrationsState.PingOne.provisionToken}
            hasProvisionToken={integrationsState.PingOne.hasProvisionToken}
            cid={integrationsState.AccountInfo.companyLastPassId}
          />
        )}
      </StyledContainer>
      <Route path={`${path}/audit-log`}>
        <AuditLogDrawer basePath={url} />
      </Route>
    </>
  )
}

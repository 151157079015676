import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import {
  BodyRegular,
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  IconButton,
  PrimaryButton,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { DataFetchParams, LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { RequirePasswordlessViaLastPassPolicyKey } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/get-initial-data'
import {
  GeneralPolicyUsersAndGroupsQueryParams,
  GeneralPolicyUsersAndGroupsUrlParams
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import {
  PasswordlessDrawerState,
  PasswordlessDrawerSteps
} from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import { TeamsPolicyModelData } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { GeneralPolicyEditAssignedItemsForm } from '@lastpass/admin-console-dependencies/ui/policies/general/GeneralPolicyEditAssignedItemsForm/GeneralPolicyEditAssignedItemsForm'

import { PasswordlessAllSet } from './PasswordlessAllSet'
import { PasswordlessDescription } from './PasswordlessDescription'

const StyledBodyRegular = styled(BodyRegular)`
  margin-bottom: 24px;
`
export interface PasswordlessDrawerProps {
  closeLink: string
}

type PasswordlessDrawerComponentProps = LastPassComponentProps<
  PasswordlessDrawerState,
  typeof passwordlessDrawerActions,
  PasswordlessDrawerProps
>

const getTitle = (step: PasswordlessDrawerSteps) => {
  switch (step) {
    case PasswordlessDrawerSteps.description: {
      return <Trans>Set up passwordless for SSO</Trans>
    }
    case PasswordlessDrawerSteps.assign: {
      return <Trans>Users {'&'} groups</Trans>
    }
    case PasswordlessDrawerSteps.edit: {
      return <Trans>Assign users {'&'} groups</Trans>
    }
    case PasswordlessDrawerSteps.allset: {
      return <Trans>All set!</Trans>
    }
  }
}

const getAllSetContent = (policyData: TeamsPolicyModelData) => {
  const includedUsers = (policyData.inclusiveList || []).map(x => x.email)
  const includedGroups = (policyData.inclusiveGroupList || []).map(x => x.name)
  const excludedUsers = (policyData.exclusiveList || []).map(x => x.email)
  const excludedGroups = (policyData.exclusiveGroupList || []).map(x => x.name)

  return (
    <PasswordlessAllSet
      isEnabledForAllUsers={policyData.appliesTo === AppliesToOptions.all}
      groups={includedGroups}
      users={includedUsers}
      excludedUsers={excludedUsers}
      excludedGroups={excludedGroups}
    />
  )
}

const getShouldDisplayFooter = (currentStep: PasswordlessDrawerSteps) =>
  currentStep !== PasswordlessDrawerSteps.edit &&
  currentStep !== PasswordlessDrawerSteps.assign

const getContinueButtonText = (currentStep: PasswordlessDrawerSteps) =>
  currentStep === PasswordlessDrawerSteps.allset ? (
    <Trans>Save {'&'} finish</Trans>
  ) : (
    <Trans>Continue</Trans>
  )

const userAssignmentMessage = (
  <Trans>
    Users you assign will receive an invitation email. Once enrolled, they must
    verify their identity using LastPass Authenticator each time they log in to
    a protected service.
  </Trans>
)

export const PasswordlessDrawerComponent: React.FunctionComponent<PasswordlessDrawerComponentProps> = ({
  props,
  state,
  actions
}) => {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const matchUrl = match && match.url

  useEffect(() => {
    dispatch(passwordlessDrawerActions.initDrawerPolicy())
  }, [dispatch])

  const history = useHistory()
  const currentStep = state.step || PasswordlessDrawerSteps.description
  const mainTitle = getTitle(currentStep)
  const editFormState = state.editFormState
  const assignFormState = state.assignFormState
  let continueAction: () => void
  let backButtonAction: (() => void) | null = null
  let content: React.ReactElement
  switch (currentStep) {
    case PasswordlessDrawerSteps.allset:
      content = getAllSetContent(state.policyDataToSave[0])
      continueAction = () => {
        actions.savePolicyUsersAndGroups(
          RequirePasswordlessViaLastPassPolicyKey,
          state.policyDataToSave
        )
        history.push(props.closeLink)
      }
      break
    case PasswordlessDrawerSteps.assign:
      content = (
        <>
          <StyledBodyRegular>{userAssignmentMessage}</StyledBodyRegular>
          <GeneralPolicyEditAssignedItemsForm
            shouldHideUsersOptions={true}
            policyUsersAndGroups={assignFormState.policyUsersAndGroups}
            usersAndGroupsNeedsRefresh={state.usersAndGroupsNeedsRefresh}
            policyConfigUsers={assignFormState.policyConfigUsers}
            policyConfigGroups={assignFormState.policyConfigGroups}
            usersChangesList={assignFormState.usersList}
            groupsChangesList={assignFormState.groupsList}
            policyCurrentlyAppliesTo={state.policyCurrentlyAppliesTo}
            hasReceivedServerResponse={false}
            currentUrl={matchUrl || ''}
            searchString={state.searchString}
            isForAssign={true}
            cancelLink={props.closeLink}
            onSubmitted={formData =>
              actions.calculatePolicyDataToSave(formData, true)
            }
            onReset={actions.resetDrawerForms}
            onSearchStringUpdated={actions.updateSearchString}
            onCheckGeneralPolicyAppliesTo={() => {}}
            onAddSelectedGroups={actions.addSelectedPolicyAssignGroups}
            onRemoveSelectedGroups={actions.removeSelectedPolicyAssignGroups}
            onSetSelectedGroups={actions.setSelectedPolicyAssignGroups}
            onAddSelectedUsers={actions.addSelectedPolicyAssignUsers}
            onRemoveSelectedUsers={actions.removeSelectedPolicyAssignUsers}
            onSetSelectedUsers={actions.setSelectedPolicyAssignUsers}
            onSetGeneralPolicyUsersAndGroupsAppliesToForAssign={
              actions.setPolicyCurrentlyAppliesTo
            }
          />
        </>
      )
      continueAction = () =>
        actions.setDrawerStep(PasswordlessDrawerSteps.allset)
      backButtonAction = () => {
        actions.setDrawerStep(PasswordlessDrawerSteps.edit)
      }
      break
    case PasswordlessDrawerSteps.edit:
      content = (
        <>
          <StyledBodyRegular>{userAssignmentMessage}</StyledBodyRegular>
          <GeneralPolicyEditAssignedItemsForm
            shouldHideUsersOptions={false}
            policyUsersAndGroups={editFormState.policyUsersAndGroups}
            usersAndGroupsNeedsRefresh={state.usersAndGroupsNeedsRefresh}
            policyConfigUsers={editFormState.policyConfigUsers}
            policyConfigGroups={editFormState.policyConfigGroups}
            usersChangesList={editFormState.usersList}
            groupsChangesList={editFormState.groupsList}
            policyCurrentlyAppliesTo={state.policyCurrentlyAppliesTo}
            hasReceivedServerResponse={false}
            currentUrl={matchUrl || ''}
            searchString={state.searchString}
            isForAssign={false}
            cancelLink={props.closeLink}
            isPolicyDisabled={!state.policy.checked}
            onSwitchToAssign={() => {
              actions.setDrawerStep(PasswordlessDrawerSteps.assign)
            }}
            onSubmitted={formData =>
              actions.calculatePolicyDataToSave(formData, false)
            }
            onReset={actions.resetDrawerForms}
            onSearchStringUpdated={actions.updateSearchString}
            onCheckGeneralPolicyAppliesTo={(
              policyAppliesTo: AppliesToOptions,
              params: DataFetchParams<
                GeneralPolicyUsersAndGroupsQueryParams,
                GeneralPolicyUsersAndGroupsUrlParams
              >
            ) =>
              actions.checkPolicyAppliesTo(policyAppliesTo, params.query.search)
            }
            onAddSelectedGroups={actions.addSelectedPolicyGroups}
            onRemoveSelectedGroups={actions.removeSelectedPolicyGroups}
            onSetSelectedGroups={actions.setSelectedPolicyGroups}
            onAddSelectedUsers={actions.addSelectedPolicyUsers}
            onRemoveSelectedUsers={actions.removeSelectedPolicyUsers}
            onSetSelectedUsers={actions.setSelectedPolicyUsers}
            onSetGeneralPolicyUsersAndGroupsAppliesToForAssign={
              actions.setPolicyCurrentlyAppliesTo
            }
          />
        </>
      )
      continueAction = () =>
        actions.setDrawerStep(PasswordlessDrawerSteps.allset)
      break
    default:
      content = <PasswordlessDescription />
      continueAction = () => {
        actions.getPolicyUsersAndGroups()
        actions.setDrawerStep(PasswordlessDrawerSteps.edit)
      }
      break
  }

  const headerNodes = backButtonAction ? (
    <IconButton
      icon={ChevronLeft}
      data-qa="BackButton"
      onClick={backButtonAction}
    />
  ) : (
    undefined
  )

  return (
    <Drawer
      mainTitle={mainTitle}
      closeLink={props.closeLink}
      headerNodes={headerNodes}
    >
      {content}
      {getShouldDisplayFooter(currentStep) && (
        <>
          <StyledSpacer />
          <FooterContainer>
            <DrawerButtonsContainer>
              <LocationLink to={props.closeLink}>
                <TextButton
                  data-qa="PasswordlessDrawerCancelButton"
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  <Trans>Cancel</Trans>
                </TextButton>
              </LocationLink>
              <PrimaryButton
                data-qa="PasswordlessDrawerContinueButton"
                onClick={continueAction}
              >
                {getContinueButtonText(currentStep)}
              </PrimaryButton>
            </DrawerButtonsContainer>
          </FooterContainer>
        </>
      )}
    </Drawer>
  )
}

export function createPasswordlessDrawer<
  TState extends { passwordlessDrawer: PasswordlessDrawerState }
>() {
  return connectRedux(
    PasswordlessDrawerComponent,
    (state: TState) => state.passwordlessDrawer,
    passwordlessDrawerActions
  )
}

export type PasswordlessDrawer = ReturnType<typeof createPasswordlessDrawer>

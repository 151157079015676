import { AcronisApiActions, AcronisApiActionTypes } from './actions'
import { AcronisApiState } from './state'

export const initialState: AcronisApiState = {
  alreadyHasHash: false,
  inProgress: false,
  provisioningHash: undefined
}

export const acronisApiReducer = (
  state: AcronisApiState | undefined = initialState,
  action: AcronisApiActions
): AcronisApiState => {
  switch (action.type) {
    case AcronisApiActionTypes.ACRONIS_API_SET:
      return {
        ...state,
        alreadyHasHash: action.payload.alreadyHasHash
      }

    case AcronisApiActionTypes.SET_PROVISIONING_HASH:
      return {
        ...state,
        provisioningHash: action.payload.provisioningHash
      }

    case AcronisApiActionTypes.SET_INPROGRESS:
      return {
        ...state,
        inProgress: action.payload
      }

    default:
      return state
  }
}

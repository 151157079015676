import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  sharedFolderDrawerActions,
  SharedFolderScore
} from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'

export interface SharedFolderScoreObject {
  score?: string
  numaccts?: string
  avgstrength?: string
  numblanksites?: string
  numduppasswords?: string
  numdupsites?: string
  numweak?: string
}
const defaultScore = '0'

export function createGetSharedFolderScores(
  sharedFolderServices: UACServices.Services
) {
  return function* getSharedFolderScores(
    action: ReturnType<
      typeof sharedFolderDrawerActions.getSharedFolderScoresList
    >
  ) {
    try {
      yield put(sharedFolderDrawerActions.setSharedFolderScoresLoading(true))
      if (
        action.payload.params.path.id &&
        action.payload.params.path.id != ''
      ) {
        const sharedFolderScoresResult: UACServices.ShareFoldersScoresAPI.Responses = yield call(
          sharedFolderServices.sharedFoldersScoresGet,
          action.payload.params.path.id
        )

        const result: SharedFolderScoreObject = sharedFolderScoresResult.body

        const scoreArray: SharedFolderScore[] = [
          {
            measurement: msg`Security challenge score`,
            score: result.score || defaultScore
          },
          {
            measurement: msg`Number of items`,
            score: result.numaccts || defaultScore
          },
          {
            measurement: msg`Average password strength`,
            score: result.avgstrength || defaultScore
          },
          {
            measurement: msg`Number of blank passwords`,
            score: result.numblanksites || defaultScore
          },
          {
            measurement: msg`Number of duplicate passwords`,
            score: result.numduppasswords || defaultScore
          },
          {
            measurement: msg`Number of apps with duplicate passwords`,
            score: result.numdupsites || defaultScore
          },
          {
            measurement: msg`Number of weak passwords`,
            score: result.numweak || defaultScore
          }
        ]

        yield put(
          sharedFolderDrawerActions.setSharedFolderScoresList(scoreArray)
        )
        yield put(sharedFolderDrawerActions.setSharedFolderScoresLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

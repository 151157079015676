import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

import { GlobalActions, GlobalActionTypes } from './actions'
import { GlobalState } from './state'

export const initialState: GlobalState = {
  currentUser: {
    email: '',
    firstName: '',
    lastName: '',
    userId: ''
  },
  notification: { type: NotificationType.hidden, message: undefined },
  dialog: { type: 'empty' },
  alert: { showError: false },
  permissions: [],
  isLoading: false,
  decryptedPrivateSharingKey: '',
  vaultKey: Buffer.from('')
}

export function globalReducer(
  previousState: GlobalState | undefined,
  action: GlobalActions
): GlobalState {
  const state = previousState || initialState
  switch (action.type) {
    case GlobalActionTypes.SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload.currentUser }
      }
    }

    case GlobalActionTypes.SET_CURRENT_USER_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload
      }
    }

    case GlobalActionTypes.SET_CURRENT_USER_LANGUAGE: {
      return {
        ...state,
        currentUser: { ...state.currentUser, language: action.payload }
      }
    }

    case GlobalActionTypes.SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload.notification
      }
    }

    case GlobalActionTypes.SET_DIALOG: {
      return {
        ...state,
        dialog: action.payload.dialog
      }
    }

    case GlobalActionTypes.EMPTY_DIALOG: {
      return {
        ...state,
        dialog: { type: 'empty' }
      }
    }

    case GlobalActionTypes.SHOW_GLOBAL_ERROR: {
      return {
        ...state,
        alert: { showError: action.payload.showError }
      }
    }

    case GlobalActionTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    }

    case GlobalActionTypes.SET_DECRYPTED_PRIVATE_SHARING_KEY: {
      return {
        ...state,
        decryptedPrivateSharingKey: action.payload
      }
    }

    case GlobalActionTypes.SET_VAULT_KEY: {
      return {
        ...state,
        vaultKey: action.payload
      }
    }
  }

  return state
}

import React, {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState
} from 'react'
import AnimateHeight from 'react-animate-height'

import styled from '@emotion/styled'

import { Loading } from '@lastpass/components/Loading'
import { useOutsideClick } from '@lastpass/ui'

export interface ButtonProps extends HTMLAttributes<HTMLElement> {
  expanded?: boolean
}

export interface DropdownProps extends ButtonProps {
  button: React.ComponentType<ButtonProps>
  expandedButton?: React.ComponentType<ButtonProps>
  customLabel?: React.ComponentType
  arrangerTopMargin?: number
  arrangerRight?: string
  arrangerLeft?: string
  loading?: boolean
  onInitialExpand?: Function
  setExpanded?: Function
}

const Container = styled.div`
  position: relative;
  display: inline-block;
`
const LoadingContainer = styled.div`
  margin: 16px 0;
  position: relative;
  min-height: 225px;
`

export interface DropdownArrangerProps {
  topMargin?: number
  right?: string
  left?: string
}

const DropdownArranger = styled.div<DropdownArrangerProps>`
  position: absolute;
  margin-top: ${props => props.topMargin || 5}px;
  top: 100%;
  right: ${({ right }) => (right ? right : '0%')};
  left: ${({ left }) => left && left};
  z-index: 10;
`

const DropdownList = styled.div`
  list-style: none;
  background: ${props => props.theme.colors.neutral};
  border: 1px solid ${props => props.theme.colors.neutral200};
  box-shadow: ${props => props.theme.elevations.elevation300};
  border-radius: ${props => props.theme.radius.pixel8};
  min-width: 200px;
  width: max-content;
  overflow: hidden;
`

export const Dropdown: FunctionComponent<DropdownProps> = ({
  onInitialExpand,
  loading,
  ...props
}) => {
  const [expanded, setExpanded] = useState(props.expanded || false)
  const calledOnInitialExpand = useRef(false)
  const Button = props.button
  const ExpandedButton = props.expandedButton || Button
  const Label = props.customLabel

  const containerRef = useRef<HTMLDivElement>(null)
  const onOutsideClick = () => {
    setExpanded(false)
  }
  const handleCick = () => {
    setExpanded(!expanded)
    if (props.setExpanded) {
      props.setExpanded(!expanded)
    }
    if (onInitialExpand && !calledOnInitialExpand.current) {
      calledOnInitialExpand.current = true
      onInitialExpand()
    }
  }
  useOutsideClick(containerRef, onOutsideClick, true)

  useEffect(() => {
    setExpanded(props.expanded || false)
  }, [props.expanded])

  return (
    <Container {...props} ref={containerRef}>
      {Label ? (
        <span onClick={handleCick}>
          <Label />
        </span>
      ) : (
        ''
      )}
      {!expanded && <Button onClick={handleCick} expanded={expanded} />}
      {expanded && <ExpandedButton onClick={handleCick} expanded={expanded} />}

      <DropdownArranger
        topMargin={props.arrangerTopMargin}
        right={props.arrangerRight}
        left={props.arrangerLeft}
      >
        <AnimateHeight height={expanded ? 'auto' : 0}>
          <DropdownList>
            {loading ? (
              <LoadingContainer>
                <Loading color="blue900" active={true} />
              </LoadingContainer>
            ) : (
              props.children
            )}
          </DropdownList>
        </AnimateHeight>
      </DropdownArranger>
    </Container>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UtilizationData } from '@lastpass/admin-console-dependencies/state/dashboard/state'

export const SUCCESS = 'success'

const success = (data: UtilizationData) => createResponse(SUCCESS, data)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('dashboard/utilization-info', 'GET')
    if (status === StatusCodes.OK && response) {
      const parsedResponse: UtilizationData = JSON.parse(response).content
      return success(parsedResponse)
    }

    throw new UnknownServiceError()
  }
}

import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as ComingSoonIcon } from '@lastpass/assets/svg/admin-console/icon-coming-soon.svg'
import { BodyRegular, Heading100 } from '@lastpass/lastkit'

import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'

interface ComingSoonProps {
  oldUrl: string
}

const Container = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledComingSoonIcon = styled(ComingSoonIcon)`
  height: 120px;
  width: 120px;
  margin-bottom: 26px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 16px;
`

const StyledLink = styled.a`
  font-weight: 600;
`

export const ComingSoon: FunctionComponent<ComingSoonProps> = ({ oldUrl }) => {
  const companyInfo: CompanyInfoState = useSelector(
    (state: AppState) => state.companyInfo
  )
  const oldConsoleUrl = `${
    defaultConfig.lastPassBaseAddress
  }/company/?resetconsole=true${
    companyInfo.cidOverride ? '&childcid=' + companyInfo.cidOverride : ''
  }#!${oldUrl}`

  return (
    <Container>
      <StyledComingSoonIcon />
      <StyledHeading100>
        <Trans>Coming soon!</Trans>
      </StyledHeading100>
      <BodyRegular>
        <Trans>
          We{"'"}re still moving this functionality to the new Admin Console.
          <br />
          For now, please use the{' '}
          <StyledLink href={oldConsoleUrl}>previous version</StyledLink>.
        </Trans>
      </BodyRegular>
    </Container>
  )
}

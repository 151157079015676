import { MfaApplicationType } from '../mfa-application'
import {
  Entities,
  MfaAppsDrawerActions,
  MfaAppsDrawerActionTypes
} from './actions'
import { MfaAppsDrawerState, MfaAppsDrawerSteps } from './state'

export const defaultMfaAppMappingKey = 'email'

export const initialState: MfaAppsDrawerState = {
  isLoading: false,
  actualStep: null,
  history: [],
  app: {
    name: '',
    type: MfaApplicationType.undefined,
    key: '',
    secret: '',
    mappingKey: defaultMfaAppMappingKey,
    allUser: true,
    userIds: [],
    groupIds: []
  },
  canSave: false,
  potentialMappingKeys: [],
  showTable: Entities.users,
  availableUsers: {
    results: [],
    totalResults: 0,
    resultsPerPage: 25,
    checkedRecords: [],
    isLoading: false
  },
  usersSearchInput: '',
  users: {
    results: [],
    totalResults: 0,
    resultsPerPage: 25,
    checkedRecords: [],
    isLoading: false
  },
  availableGroups: {
    results: [],
    totalResults: 0,
    resultsPerPage: 25,
    checkedRecords: [],
    isLoading: false
  },
  groupsSearchInput: '',
  groups: {
    results: [],
    totalResults: 0,
    resultsPerPage: 25,
    checkedRecords: [],
    isLoading: false
  }
}

const canSave = (appName: string, appType: MfaApplicationType): boolean =>
  appName.length > 0 && appType !== MfaApplicationType.undefined

export function mfaAppsDrawerReducer(
  previousState: MfaAppsDrawerState | undefined,
  action: MfaAppsDrawerActions
): MfaAppsDrawerState {
  const state = previousState || initialState
  const history = state.history
  const app = state.app
  switch (action.type) {
    case MfaAppsDrawerActionTypes.SET_NAME: {
      return {
        ...state,
        app: { ...app, name: action.payload.appName },
        canSave: canSave(action.payload.appName, app.type)
      }
    }
    case MfaAppsDrawerActionTypes.SET_APP: {
      return {
        ...state,
        app: {
          ...app,
          name: action.payload.app.name,
          type: action.payload.app.type,
          key: action.payload.app.key,
          secret: action.payload.app.secret,
          mappingKey: action.payload.app.mappingKey,
          allUser: !(
            action.payload.app.userIds.length > 0 ||
            action.payload.app.groupIds.length > 0
          ),
          userIds: action.payload.app.userIds,
          groupIds: action.payload.app.groupIds
        },
        canSave: canSave(action.payload.app.name, action.payload.app.type)
      }
    }
    case MfaAppsDrawerActionTypes.SELECT_APP: {
      app.type = action.payload.type
      return {
        ...state,
        app: {
          ...app,
          type: action.payload.type,
          name: action.payload.name
        },
        canSave: canSave(action.payload.name, action.payload.type)
      }
    }
    case MfaAppsDrawerActionTypes.NEXT_STEP: {
      const nextStep = action.payload.nextStep
      return {
        ...state,
        actualStep: nextStep
      }
    }
    case MfaAppsDrawerActionTypes.DISCARD: {
      return {
        ...initialState,
        app: { ...initialState.app, type: MfaApplicationType.undefined }
      }
    }
    case MfaAppsDrawerActionTypes.GO_BACK: {
      const previousStep = history.pop() as MfaAppsDrawerSteps
      return {
        ...state,
        history: history,
        actualStep: previousStep,
        canSave: canSave(app.name, app.type)
      }
    }
    case MfaAppsDrawerActionTypes.SET_DRAWER_LOADING: {
      return {
        ...state,
        isLoading: action.payload.loading
      }
    }
    case MfaAppsDrawerActionTypes.FINISH: {
      if (state.actualStep) history.push(state.actualStep)
      return {
        ...state,
        history: history,
        actualStep: MfaAppsDrawerSteps.finish
      }
    }
    case MfaAppsDrawerActionTypes.SET_MAPPING_KEY: {
      return {
        ...state,
        app: {
          ...app,
          mappingKey: action.payload.mappingKey
        }
      }
    }
    case MfaAppsDrawerActionTypes.SET_POTENTIAL_MAPPING_KEYS: {
      return {
        ...state,
        potentialMappingKeys: action.payload.mappingKeys
      }
    }
    case MfaAppsDrawerActionTypes.SHOW_TABLE: {
      return {
        ...state,
        showTable: action.payload.table
      }
    }
    case MfaAppsDrawerActionTypes.SET_USERS: {
      return {
        ...state,
        users: action.payload
      }
    }
    case MfaAppsDrawerActionTypes.SET_GROUPS: {
      return {
        ...state,
        groups: action.payload
      }
    }
    case MfaAppsDrawerActionTypes.SELECT_TABLE_ELEMENT: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          checkedRecords: state[action.payload.entity].checkedRecords.concat(
            action.payload.records
          )
        }
      }
    }
    case MfaAppsDrawerActionTypes.DESELECT_TABLE_ELEMENT: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          checkedRecords: state[action.payload.entity].checkedRecords.filter(
            el => !action.payload.records.includes(el)
          )
        }
      }
    }
    case MfaAppsDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          checkedRecords: action.payload.records
        }
      }
    }
    case MfaAppsDrawerActionTypes.ASSIGN: {
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.concat(
            state.availableUsers.checkedRecords
          ),
          totalResults:
            state.users.results.length +
            state.availableUsers.checkedRecords.length
        },
        groups: {
          ...state.groups,
          results: state.groups.results.concat(
            state.availableGroups.checkedRecords
          ),
          totalResults:
            state.groups.results.length +
            state.availableGroups.checkedRecords.length
        }
      }
    }
    case MfaAppsDrawerActionTypes.UNASSIGN: {
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.filter(
            user => !state.users.checkedRecords.includes(user)
          ),
          checkedRecords: state.users.checkedRecords.filter(
            user => !state.users.checkedRecords.includes(user)
          ),
          totalResults:
            state.users.totalResults - state.users.checkedRecords.length
        },
        groups: {
          ...state.groups,
          results: state.groups.results.filter(
            group => !state.groups.checkedRecords.includes(group)
          ),
          checkedRecords: state.groups.checkedRecords.filter(
            group => !state.groups.checkedRecords.includes(group)
          ),
          totalResults:
            state.groups.totalResults - state.groups.checkedRecords.length
        }
      }
    }
    case MfaAppsDrawerActionTypes.SET_USER_LIST_SEARCH_INPUT: {
      return {
        ...state,
        usersSearchInput: action.payload.searchString
      }
    }
    case MfaAppsDrawerActionTypes.SET_AVAILABLE_USER_LIST: {
      return {
        ...state,
        availableUsers: {
          ...state.availableUsers,
          results: action.payload.list,
          resultsPerPage: action.payload.resultsPerPage,
          totalResults: action.payload.totalResults,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case MfaAppsDrawerActionTypes.SET_USER_LIST_LOADING: {
      return {
        ...state,
        availableUsers: {
          ...state.availableUsers,
          isLoading: action.payload.loading
        }
      }
    }
    case MfaAppsDrawerActionTypes.SET_GROUP_LIST_SEARCH_INPUT: {
      return {
        ...state,
        groupsSearchInput: action.payload.searchString
      }
    }
    case MfaAppsDrawerActionTypes.SET_AVAILABLE_GROUP_LIST: {
      return {
        ...state,
        availableGroups: {
          ...state.availableGroups,
          results: action.payload.list,
          resultsPerPage: action.payload.resultsPerPage,
          totalResults: action.payload.totalResults,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case MfaAppsDrawerActionTypes.SET_GROUP_LIST_LOADING: {
      return {
        ...state,
        availableGroups: {
          ...state.availableGroups,
          isLoading: action.payload.loading
        }
      }
    }
  }
  return state
}

import {
  MfaApplication,
  MfaApplicationType
} from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import {
  initialState,
  MfaApp
} from '@lastpass/admin-console-dependencies/types/mfa-applications-teams'
import { OAuthKey } from '@lastpass/admin-console-dependencies/types/oauth-keys'

export function extractMfaAppType(name: string): [MfaApplicationType, string] {
  const regexp = /((^\d)#)/
  const match = regexp.exec(name)

  if (match) {
    const extractedName: string = name.substring(match[1].length)
    const extractedType = match[2] as MfaApplicationType

    if (Object.values(MfaApplicationType).includes(extractedType)) {
      return [extractedType, extractedName]
    } else {
      return [MfaApplicationType.undefined, name]
    }
  } else {
    return [MfaApplicationType.undefined, name]
  }
}

export function mapMfaApplicationResponse(app: MfaApp): MfaApplication {
  const [type, name] =
    app.applicationName === 'Microsoft Azure AD'
      ? [MfaApplicationType.azureAD, 'Microsoft Azure AD']
      : extractMfaAppType(app.applicationName)
  return {
    key: app.integrationKey,
    name: name,
    secret: '',
    type: type,
    mappingKey: app.mappingKey,
    allUser: app.allUser,
    userIds: app.userIds || [],
    groupIds: app.groupIds || []
  }
}

export function mapMfaApplicationListResponse(
  apps: MfaApp[]
): MfaApplication[] {
  const result: MfaApplication[] = apps.map(
    (app: MfaApp): MfaApplication => {
      return mapMfaApplicationResponse(app)
    }
  )

  return result
}

export function oauthKeyToMfaApp(name: string, oauthKey: OAuthKey): MfaApp {
  return {
    ...initialState,
    applicationName: name,
    integrationKey: oauthKey.clientId,
    integrationSecret: oauthKey.clientSecret,
    mappingKey: ''
  }
}

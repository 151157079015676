import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as MoreIcon } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import { Dropdown, DropdownListItem, IconButton } from '@lastpass/lastkit'

import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'

export const AdvancedUserActivityDeleteButton: React.FC = () => {
  const dispatch = useDispatch()

  const { selectedSavedReport, savedReports } = useSelector<
    AppState,
    AdvancedUserActivityListState
  >(state => state.advancedUserActivityEvents)

  const { currentUser } = useSelector<AppState, GlobalState>(
    state => state.global
  )

  const MoreButton = props => (
    <IconButton icon={MoreIcon} data-qa={'DeleteReportMoreIcon'} {...props} />
  )

  if (!selectedSavedReport) {
    return null
  }

  const { adminEmail } = selectedSavedReport

  return (
    <>
      {(adminEmail === currentUser.email || adminEmail === '') && (
        <Dropdown button={MoreButton}>
          <DropdownListItem
            red
            onClick={() =>
              dispatch(
                advancedUserActivityActions.deleteSelectedSavedReport(
                  selectedSavedReport,
                  savedReports
                )
              )
            }
            data-qa={'DeleteReportButton'}
          >
            <Trans>Delete saved report</Trans>
          </DropdownListItem>
        </Dropdown>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  SearchInput,
  Table as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { PrimaryButton } from '@lastpass/lastkit/components/PrimaryButton'

import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { UrlRulesListState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/state'
import { UrlRule } from '@lastpass/admin-console-dependencies/types/url-rule'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const RowSpacer = styled.div`
  display: flex;
  justify-content: space-between;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 24px;
  margin-bottom: 24px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const StyledRow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

const ButtonContainer = styled.div``

export const UrlRulesList: React.FunctionComponent = () => {
  const urlRulesState: UrlRulesListState = useSelector(
    (state: AppState) => state.urlRules
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(urlRulesActions.getUrlRules())
  }, [dispatch])

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const [searchQuery, setSearchQuery] = useState('')

  const filteredResults = urlRulesState.table.results.filter(urlRule => {
    const lowerRuleName = urlRule.domain.toLowerCase()
    return lowerRuleName.indexOf(searchQuery.toLowerCase()) >= 0
  })
  const urlRules = {
    ...urlRulesState.table,
    results: filteredResults
  }

  return (
    <>
      <HeaderContainer>
        <Heading300 data-qa={'UrlRulesPageHeader'}>
          <Trans>URL rules</Trans>
        </Heading300>
        <ButtonContainer>
          <Link to={matchUrl + '/options'}>
            <StyledPrimaryButton
              data-qa={'UrlRulesOptionsButton'}
              disabled={urlRulesState.table.isLoading}
              key={'options'}
              onClick={() => {}}
            >
              <Trans>Options</Trans>
            </StyledPrimaryButton>
          </Link>

          <Link to={matchUrl + '/add'}>
            <StyledPrimaryButton
              data-qa={'UrlRulesAddButton'}
              disabled={urlRulesState.table.isLoading}
              key={'add url rule'}
              onClick={() => {}}
            >
              <Trans>Add URL rule</Trans>
            </StyledPrimaryButton>
          </Link>
        </ButtonContainer>
      </HeaderContainer>
      <RowSpacer>
        <RowContainer>
          <SearchInput
            data-qa={'SearchUrlRules'}
            onChange={event => {
              setSearchQuery(event.target.value || '')
            }}
            value={searchQuery}
            placeholder={msg`Search event, information or user...`}
          />
        </RowContainer>
      </RowSpacer>

      <TableComponent
        table={urlRules}
        getRecordLink={() => ``}
        noRecordsView={
          <TableView
            data-qa={'NoRecordsTable'}
            icon={<UserIcon />}
            title={<Trans>No URL rules data</Trans>}
            text={<Trans>No url rules returned.</Trans>}
          />
        }
        noResultsView={
          <TableView
            data-qa={'NoResultTable'}
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
            actionButton={
              <TableViewButton
                data-qa={'ClearSearchButton'}
                onClick={() => setSearchQuery('')}
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Domain</Trans>,
            renderer: function UrlRuleDomainRenderer(record: UrlRule) {
              return <StyledRow>{record.domain}</StyledRow>
            }
          },
          {
            name: <Trans>Path</Trans>,
            renderer: function UrlRulePathRenderer(record: UrlRule) {
              return <StyledRow>{record.path}</StyledRow>
            }
          },
          {
            name: <Trans>Host matching</Trans>,
            renderer: function UrlRuleHostMatchingRenderer(record: UrlRule) {
              return (
                <StyledRow>
                  {record.exactHost ? <Trans>Yes</Trans> : <Trans>No</Trans>}
                </StyledRow>
              )
            },
            noTooltip: true
          },
          {
            name: <Trans>Port matching</Trans>,
            renderer: function UrlRulePortMatchingenderer(record: UrlRule) {
              return (
                <StyledRow>
                  {record.exactPort ? <Trans>Yes</Trans> : <Trans>No</Trans>}
                </StyledRow>
              )
            },
            noTooltip: true
          },
          {
            name: <Trans>Type</Trans>,
            renderer: function UrlRuleTypeRenderer(record: UrlRule) {
              return <StyledRow>{record.type}</StyledRow>
            },
            noTooltip: true
          }
        ]}
        checkable={{
          addSelectedRecords: record =>
            dispatch(urlRulesActions.addSelectedRules(record)),
          removeSelectedRecords: record =>
            dispatch(urlRulesActions.removeSelectedRules(record)),
          setSelectedRecords: record =>
            dispatch(urlRulesActions.setSelectedRules(record))
        }}
        actions={[
          {
            name: <Trans>Delete selected URL rules</Trans>,
            color: 'red700',
            callback: (records: UrlRule[]) => {
              dispatch(urlRulesActions.deleteUrlRules(records))
            },
            isValid: () => true
          }
        ]}
        disableIndividualRowHover={() => true}
        isFilterApplied={searchQuery != ''}
      />
    </>
  )
}

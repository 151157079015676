import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'

export enum MultifactorActionTypes {
  REMOVE_POLICY = '[multifactor-drawer] remove-policy',
  FETCH_INITIAL_DATA = '[multifactor] fetch-initial-data',
  SET_LOADING = '[multifactor] set-loading',
  SET_REQUIRE_OF_POLICIES = '[multifactor] set-require-of-policies',
  SET_ADDITIONAL_POLICIES = '[multifactor] set-additional-policies',
  EDIT_REQUIRE_OF_POLICY_AVAILABILITY = '[multifactor] edit-require-of-policy-availability',
  DISABLE_POLICY = '[multifactor] disable-policy',
  EDIT_POLICY_ASSIGNMENTS = '[multifactor] edit-policy-assignments',
  CONFIGURE_ADVANCED_POLICY = '[multifactor] configure-advanced-policy',
  POLICIES_OVERVIEW_VIEWED = '[multifactor] policies-overview-viewed',
  ADD_ADVANCED_POLICY = '[multifactor] add-advanced-policy'
}

export const multifactorActions = {
  removePolicy: (policyKey: string) =>
    createAction(MultifactorActionTypes.REMOVE_POLICY, {
      policyKey
    }),
  fetchInitialData: () =>
    createAction(MultifactorActionTypes.FETCH_INITIAL_DATA),
  setLoading: (loading: boolean) =>
    createAction(MultifactorActionTypes.SET_LOADING, { loading }),
  setRequireOfPolicies: (requireOfPolicies: TeamsPolicy[]) =>
    createAction(
      MultifactorActionTypes.SET_REQUIRE_OF_POLICIES,
      requireOfPolicies
    ),
  setAdditionalPolicies: (additionalPolicies: TeamsPolicy[]) =>
    createAction(
      MultifactorActionTypes.SET_ADDITIONAL_POLICIES,
      additionalPolicies
    ),
  editRequireOfPolicyAvailability: (isEnable: boolean, policyKey: string) =>
    createAction(MultifactorActionTypes.EDIT_REQUIRE_OF_POLICY_AVAILABILITY, {
      isEnable,
      policyKey
    }),
  disablePolicy: (policy: TeamsPolicy, dataIndex?: number) =>
    createAction(MultifactorActionTypes.DISABLE_POLICY, {
      policy,
      dataIndex
    }),
  editPolicyAssignments: (policy: TeamsPolicy, dataIndex?: number) =>
    createAction(MultifactorActionTypes.EDIT_POLICY_ASSIGNMENTS, {
      policy,
      dataIndex
    }),
  configureAdvancedPolicy: (policy: TeamsPolicy, dataIndex?: number) =>
    createAction(MultifactorActionTypes.CONFIGURE_ADVANCED_POLICY, {
      policy,
      dataIndex
    }),
  mfaPoliciesOverviewViewed: (policyCount: number) =>
    track(
      createAction(MultifactorActionTypes.POLICIES_OVERVIEW_VIEWED, {
        policyCount
      }),
      {
        segment: uacEvent('MFA Policies Overview Viewed', {
          'Policy Count': policyCount
        })
      }
    ),
  showAddAdvancedMfaPolicy: (newRoute?: string) =>
    track(
      createAction(MultifactorActionTypes.ADD_ADVANCED_POLICY, {
        newRoute
      }),
      {
        segment: uacEvent('Add Advanced Policy Clicked')
      }
    )
}

export type MultifactorActions = ActionsUnion<typeof multifactorActions>

import React, { FC, ReactNode } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as TipsIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/opporunities-box/tips.svg'
import { LogoCard } from '@lastpass/lastkit'

import {
  OpportunityCard,
  OpportunityCardProps
} from '@lastpass/admin-console-dependencies/ui/home/components/card/OpportunityCard'

const AccordionContentContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 16px;
`
const Title = styled.span`
  color: ${props => props.theme.colors.neutral900};
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const TipsIconContainer = styled.span`
  margin-right: 8px;
`
const StyledOpportunityCard = styled(OpportunityCard)`
  border-right: solid 1px ${props => props.theme.colors.neutral200};
  &:last-child {
    border-right: initial;
  }
`

const StyledLogoCard = styled(LogoCard)`
  border-color: ${props => props.theme.colors.blue300};
  background-color: ${props => props.theme.colors.blue100};
`

interface CardData extends OpportunityCardProps {
  key: string
}

interface OpportunitiesBoxProps {
  cardData: CardData[]
  title: ReactNode
}

export const OpportunitiesBox: FC<OpportunitiesBoxProps> = ({
  cardData,
  title
}) => {
  const titleWrapper = (
    <>
      <TipsIconContainer>
        <TipsIcon data-qa="OpportunityBoxIcon" />
      </TipsIconContainer>
      <Title data-qa="OpportunityBoxTitle">{title}</Title>
    </>
  )

  return (
    <StyledLogoCard dataQaName="OpportunitiesBox" title={titleWrapper} expand>
      <AccordionContentContainer data-qa="OpportunityBoxContentContainer">
        {cardData.map(({ key, ...cardProps }) => (
          <StyledOpportunityCard {...cardProps} key={key} />
        ))}
      </AccordionContentContainer>
    </StyledLogoCard>
  )
}

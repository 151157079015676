import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'
import { StatusCodes } from 'http-status-codes'
import moment from 'moment'

import { AuthFetch } from '@lastpass/http'

import {
  adminActivityReportTranslationMap,
  userActivityReportTranslationMap
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-report-translations'
import {
  AdminActivityTypes,
  UserActivityTypes
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-types'
import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  ActivityEvent,
  ActivityEventRequestParams,
  ActivityEventUserType
} from '@lastpass/admin-console-dependencies/types/activity-event'

export const SUCCESS = 'success'

export interface Response {
  events: ActivityEvent[]
}

export interface ExportedActivityEvent {
  [key: string]: string
}

const success = (exportOutput: ExportedActivityEvent[]) =>
  createResponse(SUCCESS, exportOutput)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

const createQueryString = (params: ActivityEventRequestParams): string =>
  new URLSearchParams({ ...params }).toString()

const translateEventType = (
  userType: ActivityEventUserType,
  type: UserActivityTypes | AdminActivityTypes
): string =>
  i18n._(
    userType === ActivityEventUserType.user
      ? userActivityReportTranslationMap[type]
      : adminActivityReportTranslationMap[type]
  )

const mapResponseToExportOutput = (
  userType: ActivityEventUserType,
  events: ActivityEvent[]
) =>
  events.map(event => ({
    [i18n._(msg`Email`)]: event.userEmail,
    [i18n._(msg`Time`)]: moment(event.dateTimeUtc).format(
      'MMMM D, YYYY [at] h:mm:ss A'
    ),
    [i18n._(msg`IP address`)]: event.ip,
    [i18n._(msg`Event type`)]: translateEventType(userType, event.type),
    [i18n._(msg`Event information`)]: event.data,
    [i18n._(msg`User`)]: event.userName
  }))

export function create(fetch: AuthFetch, userType: ActivityEventUserType) {
  return async function service(params: ActivityEventRequestParams) {
    const [response, status] = await fetch(
      `reports/${userType}-activity/events/export?${createQueryString(params)}`,
      'GET'
    )
    if (status !== StatusCodes.OK || !response) {
      throw new UnknownServiceError()
    }

    const { events }: Response = JSON.parse(response).content
    const exportOutput: ExportedActivityEvent[] = mapResponseToExportOutput(
      userType,
      events
    )

    return success(exportOutput)
  }
}

// eslint-disable-next-line simple-import-sort/imports
import React, { useEffect } from 'react'

import { LoginReportListState } from '@lastpass/admin-console-dependencies/state/reports/login/state'
import { loginReportActions } from '@lastpass/admin-console-dependencies/state/reports/login/actions'
import {
  Table as TableComponent,
  TableView,
  Heading300
} from '@lastpass/lastkit'
import { Loading } from '@lastpass/components/Loading'
import { Trans, msg, plural } from '@lingui/macro'
import styled from '@emotion/styled'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'

import { LoginReportEvent } from '@lastpass/admin-console-dependencies/types/login-report'
import { Chevron } from '@lastpass/components'
import { Line } from 'react-chartjs-2'
import { i18n } from '@lingui/core'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '@lastpass/admin-console/src/app-store'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const StyledRow = styled.div`
  margin-left: 5px;
`

const CanvasContainer = styled.div`
  padding: 24px;
  height: 300px;
`

export const LoginReportList: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loginReportActions.getLoginReports())
  }, [dispatch])

  const loginReportListState: LoginReportListState = useSelector(
    (state: AppState) => state.loginReportEvents
  )

  const data = canvas => {
    const ctx = canvas.getContext('2d')
    const gradientFill = ctx.createLinearGradient(0, 250, 0, 100)
    gradientFill.addColorStop(0, 'rgba(183, 203, 239, 0)')
    gradientFill.addColorStop(1, 'rgba(183, 203, 239, 1)')
    return {
      labels: loginReportListState.table.results.map(entry => {
        return new Date(entry.dateUtc).toLocaleString(undefined, {
          timeZone: 'utc',
          month: 'short',
          day: 'numeric'
        })
      }),
      datasets: [
        {
          fill: true,
          backgroundColor: gradientFill,
          lineTension: 0.0,
          borderColor: '#3B70D4',
          borderWidth: 2.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#3B70D4',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          data: loginReportListState.table.results.map(entry => {
            return entry.numberOfLogins
          })
        }
      ]
    }
  }
  const options = i18n => {
    return {
      tooltips: {
        bodyAlign: 'center',
        bodyFontColor: '#000',
        backgroundColor: '#fff',
        borderColor: '#B9C0C9',
        borderWidth: 1,
        shadowOffsetX: 3, // no effect
        shadowOffsetY: 3, // no effect
        shadowBlur: 10, // no effect
        shadowColor: 'rgba(0, 0, 0, 0.5)', // no effect
        custom: function(tooltip) {
          if (!tooltip) return
          // disable displaying the color box;
          tooltip.displayColors = false
        },
        callbacks: {
          // use label callback to return the desired label
          label: function(tooltipItem) {
            return i18n._(
              msg`${plural(tooltipItem.yLabel, {
                one: '# login',
                other: '# logins'
              })} on ${tooltipItem.xLabel}`
            )
          },
          // remove title
          title: function() {
            return
          }
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            offset: true
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            type: 'linear',
            ticks: { maxTicksLimit: 6, beginAtZero: true, padding: 22 },
            gridLines: {
              drawBorder: false
            }
          }
        ]
      },
      maintainAspectRatio: false
    }
  }

  const SmallChevron = styled(Chevron)`
    margin: 0 10px 0 0;
    width: 8px;
    height: 8px;
    span {
      top: 2px;
      width: 5px;
      height: 1px;
    }
  `

  return (
    <>
      <HeaderContainer>
        <Heading300 data-qa={'LoginReportPageHeader'}>
          <Trans>Login report</Trans>
        </Heading300>

        <Trans>Shown the last 7 days</Trans>
      </HeaderContainer>

      <CanvasContainer>
        <Line data={data} options={options(i18n)} />
      </CanvasContainer>

      <TableComponent
        table={loginReportListState.table}
        noRecordsView={<></>}
        noResultsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Day</Trans>,
            renderer: function LoginReportDayRenderer(
              record: LoginReportEvent
            ) {
              return (
                <StyledRow>
                  {new Date(record.dateUtc).toLocaleString(undefined, {
                    timeZone: 'utc',
                    month: 'short',
                    day: 'numeric'
                  })}
                </StyledRow>
              )
            },
            noTooltip: true
          },
          {
            name: <Trans>Number of logins</Trans>,
            renderer: function LoginReportNumberOfLoginsRenderer(
              record: LoginReportEvent
            ) {
              return <StyledRow>{record.numberOfLogins}</StyledRow>
            },
            noTooltip: true
          },
          {
            name: <Trans>Change from previous day</Trans>,
            // eslint-disable-next-line react/display-name
            renderer: function LoginReportNumberOfLoginsRenderer(
              record: LoginReportEvent
            ) {
              return (
                <StyledRow>
                  {record.percentageChangeFromPreviousDay > 0 ? (
                    <SmallChevron direction="up" color="#00A878" />
                  ) : record.percentageChangeFromPreviousDay < 0 ? (
                    <SmallChevron direction="down" color="#D32D27" />
                  ) : (
                    ''
                  )}
                  {record.percentageChangeFromPreviousDay
                    ? (record.percentageChangeFromPreviousDay <= 0 ? '' : '+') +
                      Math.round(record.percentageChangeFromPreviousDay * 100) +
                      '%'
                    : ''}
                </StyledRow>
              )
            },
            noTooltip: true
          }
        ]}
        disableIndividualRowHover={() => true}
      />
    </>
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

const StyledErrorText = styled.p`
  display: flex;
  align-items: center;
`

export const MAXIMUM_ADMIN_LEVELS = 100

export const AdminLevelsErrorText: React.FunctionComponent = () => (
  <StyledErrorText data-qa="AdminLevelErrorText">
    <Trans>
      Limit reached. You can have {MAXIMUM_ADMIN_LEVELS} admin levels.
    </Trans>
  </StyledErrorText>
)

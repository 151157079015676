import { StatusCodes } from 'http-status-codes'

import { BulkRotateAlpData } from '@lastpass/federation/types/key-rotation'
import { AuthFetchRetry } from '@lastpass/http'

import { KeyRotationError } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-error'
import { KeyRotationErrors } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-error-codes'
import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetchRetry) {
  return async function service(
    sessionToken,
    bulkRotateAlpData: BulkRotateAlpData[]
  ) {
    let httpStatusCode: number
    try {
      const dataToSave = bulkRotateAlpData.map(item => ({
        kC2: item.k2.toString('base64'),
        fragmentId: item.fragmentId,
        id: item.id
      }))
      const [
        ,
        status
      ] = await fetch(
        `company/knowledge-component-rotations/${sessionToken}/keys`,
        'POST',
        dataToSave,
        { 'content-type': 'application/json' }
      )

      if (status === StatusCodes.OK) {
        return success()
      }
      httpStatusCode = status
    } catch {
      throw new KeyRotationError({
        feErrorCode:
          KeyRotationErrors.AlpSaveKeyRotationDataRequestConnectionFailed,
        sessionToken
      })
    }
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.AlpSaveKeyRotationDataRequestFailed,
      sessionToken,
      httpErrorCode: httpStatusCode
    })
  }
}

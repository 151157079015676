import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { NeverOnlyUrls } from '@lastpass/admin-console-dependencies/types/never-only-urls'

export enum NeverOnlyUrlActionTypes {
  NEVER_ONLY_URLS_GET = '[never-only-urls] get',
  NEVER_ONLY_URLS_SET = '[never-only-urls] set',
  NEVER_ONLY_URLS_LOADING_SET = '[never-only-urls] set-loading',
  NEVER_ONLY_URLS_SAVE = '[never-only-urls] save'
}

export const neverOnlyUrlActions = {
  getNeverOnlyUrls: () =>
    createAction(NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_GET),
  setNeverOnlyUrls: (neverOnlyUrls: NeverOnlyUrls) =>
    createAction(NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_SET, {
      neverOnlyUrls
    }),
  setNeverOnlyUrlsLoading: (isLoading: boolean) =>
    createAction(NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_LOADING_SET, {
      isLoading
    }),
  saveNeverOnlyUrls: (neverOnlyUrls: NeverOnlyUrls) =>
    createAction(NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_SAVE, {
      neverOnlyUrls
    })
}

export type NeverOnlyUrlActions = ActionsUnion<typeof neverOnlyUrlActions>

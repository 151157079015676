import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import {
  paywalledPolicies,
  TeamsPolicy,
  TeamsPolicyCategories,
  TeamsPolicyResponseMap,
  TeamsPolicyStatus
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import { mapGetPolicyListResultToPolicyMapWithoutPasswordless } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

const searchMatch = (
  title: string,
  description: string,
  searchTerm?: string
): boolean => {
  return (
    (!!searchTerm &&
      (title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        description.toLowerCase().includes(searchTerm.toLowerCase()))) ||
    !searchTerm
  )
}

const categoryMatch = (
  category: string,
  searchCategories?: TeamsPolicyCategories[]
): boolean => {
  return (
    (!!searchCategories && searchCategories.some(e => e === category)) ||
    !Array.isArray(searchCategories) ||
    !searchCategories.length
  )
}

const statusMatch = (
  status: boolean,
  searchStatus?: TeamsPolicyStatus[]
): boolean => {
  return (
    (!!searchStatus &&
      searchStatus.some(
        e =>
          e ===
          (status ? TeamsPolicyStatus.enabled : TeamsPolicyStatus.disabled)
      )) ||
    !Array.isArray(searchStatus) ||
    !searchStatus.length
  )
}

export const filterPaywalledPolicies = (
  generalPoliciesResponse: TeamsPolicyResponseMap
): TeamsPolicyResponseMap => {
  const filteredPolicyResponse: TeamsPolicyResponseMap = {}
  Object.keys(generalPoliciesResponse)
    .filter(
      (policyKey: string) =>
        !paywalledPolicies.includes(
          generalPoliciesResponse[policyKey].policy_key
        )
    )
    .forEach(
      (policyKey: string) =>
        (filteredPolicyResponse[policyKey] = generalPoliciesResponse[policyKey])
    )

  return filteredPolicyResponse
}

export function createGetGeneralPoliciesList(
  generalPoliciesServices: UACServices.Services
) {
  return function* getGeneralPoliciesList(
    action: ReturnType<typeof generalPoliciesListActions.getGeneralPoliciesList>
  ) {
    try {
      const searchQuery = action.payload.params.query.search
      const categoryQuery = action.payload.params.query.category
      const statusQuery = action.payload.params.query.status

      yield put(generalPoliciesListActions.setGeneralPoliciesLoading(true))
      try {
        const generalPoliciesListResult: UACServices.GeneralPoliciesListGetAPI.Responses = yield call(
          generalPoliciesServices.generalPoliciesListGet
        )
        if (
          generalPoliciesListResult.type ===
          UACServices.GeneralPoliciesListGetAPI.SUCCESS
        ) {
          const generalPoliciesResponse =
            generalPoliciesListResult.body.generalPoliciesListResponse

          const prefilteredGeneralPoliciesList = yield call(
            filterPaywalledPolicies,
            generalPoliciesResponse
          )

          const [
            generalPoliciesMap,
            generalPoliciesList
          ] = mapGetPolicyListResultToPolicyMapWithoutPasswordless(
            prefilteredGeneralPoliciesList
          )

          const filteredResults = generalPoliciesList.filter(
            (innerResult: TeamsPolicy) => {
              return (
                searchMatch(
                  innerResult.policyTitle,
                  innerResult.policyShortDescription,
                  searchQuery
                ) &&
                categoryMatch(innerResult.category, categoryQuery) &&
                statusMatch(innerResult.checked, statusQuery)
              )
            }
          )

          yield put(
            generalPoliciesListActions.setGeneralPoliciesList(
              filteredResults,
              generalPoliciesMap,
              filteredResults.length,
              filteredResults.length
            )
          )
          yield put(
            generalPoliciesListActions.updateGeneralPoliciesDrawers(
              generalPoliciesMap
            )
          )
        }
      } finally {
        yield put(generalPoliciesListActions.setGeneralPoliciesLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as WorkstationLogin } from '@lastpass/assets/svg/admin-console/applications/workstation-login-illustration.svg'
import { BodyRegular, Heading300, PrimaryButton } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { PostTrialMfaAlert } from '@lastpass/admin-console-dependencies/ui/global/PostTrialMfaAlert'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 140px;
`

const StyledHeading = styled(Heading300)`
  margin-top: 32px;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
`

const StyledBodyRegular = styled(BodyRegular)`
  display: flex;
  justify-content: center;
  width: 560px;
  text-align: center;
`

const StyledLocationLink = styled.a`
  margin-top: 40px;
`

export const WorkstationEmptyList = ({ actions }) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isTiralEligible = CompanyDetailsHelper.isUserBusinessTrialEligible(
    companyDetails
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const postTrialAlert =
    !userHasMfaAddon && !isTiralEligible ? <PostTrialMfaAlert /> : null

  const onGetStartedClicked = () => {
    if (CompanyDetailsHelper.isMspOrManaged(companyDetails)) {
      actions.showMfaUsageNotificationDialog(MfaUpsellDialogContext.workstation)
      return
    }

    if (
      userHasMfaAddon ||
      CompanyDetailsHelper.isPermissionNull(companyDetails)
    ) {
      actions.getStarted()
      return
    }
    if (isTiralEligible) {
      actions.showMfaUpsellDialog(
        CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
        MfaUpsellDialogContext.workstation
      )
    } else {
      actions.showMfaAddonUpsellDialog(
        MfaUpsellDialogContext.workstation,
        CompanyDetailsHelper.isEcommEnabled(companyDetails) || true
      )
    }
  }

  return (
    <StyledContainer>
      <WorkstationLogin />
      <StyledHeading>
        <Trans>Protect Windows and macOS logins with LastPass</Trans>
      </StyledHeading>
      <StyledTextContainer>
        <StyledBodyRegular>
          <Trans>
            Enforce multifactor authentication options (app push, TOTP, SMS,
            phone call, or YubiKey), or enable passwordless logins on user
            workstations.
          </Trans>
        </StyledBodyRegular>
      </StyledTextContainer>
      {postTrialAlert}
      <StyledPrimaryButton
        data-qa={'ApplicationsWorkstationsGetStarted'}
        onClick={onGetStartedClicked}
      >
        <Trans>Get started</Trans>
      </StyledPrimaryButton>
      <StyledLocationLink
        href="https://support.logmeininc.com/lastpass/help/what-is-lastpass-workstation-login"
        data-qa={'LearnMoreAboutWorkstationsLink'}
        target="_blank"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </StyledContainer>
  )
}

import { StatusCodes } from 'http-status-codes'

import { fetchRetry } from '@lastpass/http/fetch-retry'

import { CidOverrideRepository } from '@lastpass/admin-console-dependencies/repositories'

import { urlEncode } from './fetch'
import { OAuthManager } from './oauth-manager'
import { FetchAPI } from './types'

export function createFetchUAC<DefaultType, ResolvedType>(
  url = '',
  getAuthToken: () => Promise<string>,
  authenticate: () => Promise<void>,
  logout: () => Promise<void>,
  fetchAPI: FetchAPI,
  responseHandler: ResponseHandler<DefaultType, ResolvedType>,
  defaultHeaders?: {}
) {
  return async function fetchUAC(
    path: string,
    method: string,
    body?: {},
    headers?: {},
    abortSignal?: AbortSignal
  ): Promise<[DefaultType | ResolvedType, Response['status']]> {
    const authToken = await getAuthToken()
    if (!authToken) {
      return responseHandler.default
    }
    const response = await fetchAPI(`${url}/${path}`, {
      signal: abortSignal,
      ...{
        method: method,
        headers: {
          ...defaultHeaders,
          ...headers,
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify(body)
      }
    })

    if (response.status === StatusCodes.UNAUTHORIZED) {
      await authenticate()
    }

    if (response.status === StatusCodes.FORBIDDEN) {
      let urlParams
      try {
        const responseText = await response.text()
        const responseJson = JSON.parse(responseText)
        urlParams = urlEncode({
          reason: responseJson.status,
          errorId: responseJson.errorId
        })
      } finally {
        await CidOverrideRepository.remove()
        window.location.replace(
          `/error/403.html${urlParams ? '?' + urlParams : ''}`
        )
      }
      return responseHandler.default
    }

    return await responseHandler.resolve(response)
  }
}

export interface ResponseHandler<DefaultType, ResolvedType> {
  default: [DefaultType, Response['status']]
  resolve: (response: Response) => Promise<[ResolvedType, Response['status']]>
}

export const textResponseHandler: ResponseHandler<string, string> = {
  default: ['', 0],
  resolve: async (response: Response): Promise<[string, number]> => {
    const text = await response.text()
    return [text, response.status]
  }
}

export const blobResponseHandler: ResponseHandler<null, Blob> = {
  default: [null, 0],
  resolve: async (response: Response): Promise<[Blob, number]> => {
    const blob = await response.blob()
    return [blob, response.status]
  }
}

export const createAuthFetch = (middlewareUrl = '', defaultHeaders?: {}) =>
  createFetchUAC(
    middlewareUrl,
    OAuthManager.getAuthToken,
    OAuthManager.login,
    OAuthManager.logout,
    window.fetch,
    textResponseHandler,
    defaultHeaders
  )

export const createAuthFetchBlob = (middlewareUrl = '', defaultHeaders?: {}) =>
  createFetchUAC(
    middlewareUrl,
    OAuthManager.getAuthToken,
    OAuthManager.login,
    OAuthManager.logout,
    window.fetch,
    blobResponseHandler,
    defaultHeaders
  )

export const createAuthFetchRetry = (middlewareUrl = '', defaultHeaders?: {}) =>
  createFetchUAC(
    middlewareUrl,
    OAuthManager.getAuthToken,
    OAuthManager.login,
    OAuthManager.logout,
    fetchRetry,
    textResponseHandler,
    defaultHeaders
  )

export type AuthFetch = ReturnType<typeof createAuthFetch>
export type AuthFetchBlob = ReturnType<typeof createAuthFetchBlob>
export type AuthFetchRetry = ReturnType<typeof createAuthFetchRetry>

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { LoginReportEvent } from '@lastpass/admin-console-dependencies/types/login-report'

export const SUCCESS = 'success'

const success = (events: LoginReportEvent[]) => createResponse(SUCCESS, events)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

const defaultNumberOfDays = 7

export function create(fetch: AuthFetch) {
  return async function service(numberOfDays: number = defaultNumberOfDays) {
    const [response, status] = await fetch(
      `reports/user-activity/recent-site-logins?numberOfDays=${numberOfDays}`,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const result = JSON.parse(response).content.dailySiteLogins
      return success(result)
    }

    throw new UnknownServiceError()
  }
}

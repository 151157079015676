import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  HeaderContainer,
  Heading300,
  RoutedTabNavigationItem,
  TabContainer,
  TabNavigation
} from '@lastpass/lastkit'

import { userActivityActions } from '@lastpass/admin-console-dependencies/state/reports/view/actions'
import { ActivityEventUserType } from '@lastpass/admin-console-dependencies/types/activity-event'

import { ReportsViewPage } from './view/ReportsViewPage'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

export const GeneralReportsPage: React.FunctionComponent = () => {
  const match = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActivityActions.trackGeneralReportsOverviewViewed())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!match) {
    return null
  }

  const { path: matchPath, url: matchUrl } = match
  const [userActivity, adminActivity] = ['userActivity', 'adminActivity'].map(
    path => {
      const route: RouteProps = { path: `${matchPath}/${path}` }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    }
  )

  return (
    <>
      <PageHeaderContainer data-qa="PageHeaderContainer">
        <Heading300 data-qa="GeneralReportsPageHeader">
          <Trans>General reports</Trans>
        </Heading300>
      </PageHeaderContainer>
      <TabContainer data-qa="TabContainer">
        <TabNavigation>
          <RoutedTabNavigationItem
            data-qa="TabMenuUserActivity"
            to={userActivity.url}
            route={userActivity.route}
          >
            <Trans>User activity</Trans>
          </RoutedTabNavigationItem>
          <RoutedTabNavigationItem
            data-qa="TabMenuAdminActivity"
            to={adminActivity.url}
            route={adminActivity.route}
          >
            <Trans>Admin activity</Trans>
          </RoutedTabNavigationItem>
        </TabNavigation>
      </TabContainer>
      <Switch>
        <Route {...userActivity.route}>
          <ReportsViewPage
            type={ActivityEventUserType.user}
            key={ActivityEventUserType.user}
          />
        </Route>
        <Route {...adminActivity.route}>
          <ReportsViewPage
            type={ActivityEventUserType.admin}
            key={ActivityEventUserType.admin}
          />
        </Route>
      </Switch>
    </>
  )
}

import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { LogoCard } from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'
import {
  BodyRegularStyle,
  CaptionSemiboldStyle
} from '@lastpass/lastkit/styles'

export interface IntegrationCardProps {
  logo: ReactElement
  title: ReactElement
  subtitle: ReactElement
  expand?: boolean
  installed?: boolean
  description?: ReactElement
  primaryButton?: ReactElement
  primaryText?: ReactElement
  secondaryButton?: ReactElement
  secondaryText?: ReactElement
  lastSync?: string
  version?: string
  copied?: boolean
}

const StyledInstalledLogo = styled.div`
  background: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  width: 80px;
  height: 24px;
  border-radius: 4px;
  margin-left: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const StyledChildRow = styled.div`
  ${BodyRegularStyle}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => props.theme.colors.neutral700};
  margin-bottom: 4px;
`

const StyledChildRowText = styled.span`
  display: inline-block;
  width: 100%;
`

const StyledChildRowButton = styled.div`
  margin-left: auto;
  margin-right: 16px;
`

const StyledVersionSyncWrapper = styled.span`
  font-weight: ${props => props.theme.fonts.weight.regular};
`

const StyledVersionSyncRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledChildColumn = styled.div`
  ${CaptionSemiboldStyle}
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.neutral700};
`

const StyledChildRowPrimary = styled(StyledChildRow)<{
  hasSecondaryText: boolean
}>`
  margin-bottom: ${props => (props.hasSecondaryText ? '60px' : '30px')};
`
const StyledChildRowSecondary = styled(StyledChildRow)`
  margin-bottom: 30px;
`

export const IntegrationCard: FunctionComponent<IntegrationCardProps> = props => {
  return (
    <LogoCard
      logo={props.logo}
      title={
        <>
          <Heading100>{props.title}</Heading100>
          {props.installed && (
            <StyledInstalledLogo>
              <Trans>Installed</Trans>
            </StyledInstalledLogo>
          )}
        </>
      }
      subtitle={props.subtitle}
      expand={props.expand}
      highlighted={props.installed}
      description={props.description}
      dataQaName={'DirectoryIntegration'}
    >
      <>
        {((props.lastSync && props.lastSync != '') ||
          (props.version && props.version != '')) && (
          <StyledChildColumn>
            {props.lastSync && props.lastSync != '' && (
              <StyledVersionSyncRow>
                <div>
                  <Trans>Last sync</Trans>
                  {': '}
                  <StyledVersionSyncWrapper data-qa="LastSyncDateTime">
                    {' '}
                    {props.lastSync}
                  </StyledVersionSyncWrapper>
                </div>
              </StyledVersionSyncRow>
            )}
            {props.version && props.version != '' && (
              <StyledVersionSyncRow>
                <div>
                  <Trans>Version</Trans>
                  {': '}
                  <StyledVersionSyncWrapper>
                    {props.version}
                  </StyledVersionSyncWrapper>
                </div>
              </StyledVersionSyncRow>
            )}
          </StyledChildColumn>
        )}
        <StyledChildRow>
          {props.description && (
            <StyledChildRowText>{props.description}</StyledChildRowText>
          )}
        </StyledChildRow>
        {props.primaryText || props.primaryButton ? (
          <StyledChildRowPrimary
            hasSecondaryText={!!props.secondaryText && !!props.secondaryButton}
          >
            {props.primaryText && (
              <StyledChildRowText>{props.primaryText}</StyledChildRowText>
            )}
            {props.primaryButton && (
              <StyledChildRowButton>{props.primaryButton}</StyledChildRowButton>
            )}
          </StyledChildRowPrimary>
        ) : (
          <></>
        )}
        {props.secondaryText || props.secondaryButton ? (
          <StyledChildRowSecondary>
            {props.secondaryText && (
              <StyledChildRowText>{props.secondaryText}</StyledChildRowText>
            )}
            {props.secondaryButton && (
              <StyledChildRowButton>
                {props.secondaryButton}
              </StyledChildRowButton>
            )}
          </StyledChildRowSecondary>
        ) : (
          <></>
        )}
      </>
    </LogoCard>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ActiveDirectoryIcon } from '@lastpass/assets/svg/admin-console/directories/icon-active-directory.svg'
import { Loading } from '@lastpass/components/Loading'
import { LogoCard } from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import { AdConnectorForm } from './AdConnectorForm'
import { AdDownloadBlock } from './AdDownloadBlock'
import { AdSetupForm } from './AdSetupForm'
import {
  BoldWrapper,
  StyledCardContainer,
  StyledChildRow,
  StyledExternalLink,
  StyledSubtitle
} from './StyledComponents'

export const AdfsSetupCard: FunctionComponent = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(federatedLoginActions.getAdfsSetup())
    dispatch(federatedLoginActions.getAdAttributeName())
  }, [dispatch])

  const state = useSelector<AppState, FederatedLoginState>(
    appState => appState.federatedLogin
  )

  const isAdfs = !state.adfs.isPingFederate

  return (
    <LogoCard
      dataQaName="AdfsCard"
      logo={<ActiveDirectoryIcon />}
      highlighted={state.adfs.isEnabled}
      title={
        <Heading100>
          <Trans>Active Directory Federated Services (ADFS)</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from ADFS. They{"'"}ll log in to LastPass
          with their AD credentials instead of their master password.
        </Trans>
      }
    >
      {state.loading ? (
        <Loading color="blue900" active={true} />
      ) : (
        <StyledCardContainer>
          <StyledChildRow>
            <BoldWrapper>
              <Trans>Configure ADFS</Trans>
            </BoldWrapper>
            <StyledExternalLink
              data-qa="AdfsInstructionsLink"
              href="https://link.lastpass.com/help-federated-adfs"
              target="_blank"
            >
              <Trans>Follow these instructions</Trans>
            </StyledExternalLink>
          </StyledChildRow>
          <StyledSubtitle>
            <Trans>
              To configure federated login on your organization, log in to your
              ADFS server and copy the relevant details to the form below.
            </Trans>
          </StyledSubtitle>
          {(state.adfs.isPingFederate && state.adfs.isEnabled) ||
          state.openId.isEnabled ? (
            <div data-qa="IdpAlreadyConfiguredMessageOnAdfs">
              <OpenIdProviderConfigurationMessage provider="ADFS" />
            </div>
          ) : (
            <>
              <AdSetupForm
                key={`adfs ${state.adfs.providerUrl} - ${state.adfs.publicKey} - ${state.adfs.isEnabled}`}
                initialValues={{
                  providerUrl: isAdfs ? state.adfs.providerUrl : '',
                  publicKey: isAdfs ? state.adfs.publicKey : '',
                  isEnabled: isAdfs ? state.adfs.isEnabled : false,
                  useCompanyWideKey: isAdfs
                    ? state.adfs.useCompanyWideKey
                    : true,
                  providerLoginUrl: isAdfs ? state.adfs.providerLoginUrl : '',
                  isPingFederate: false
                }}
                dataQaName="Adfs"
              />
              {state.adAttributeVisible && (
                <AdConnectorForm
                  key={`adconnect ${state.adAttribute.adAttributeName}`}
                  customAttribute={
                    isAdfs ? state.adAttribute.adAttributeName : ''
                  }
                  dataQaName="Adfs"
                />
              )}
              <AdDownloadBlock
                title={msg`LastPass custom attribute store`}
                textForADFS30Link={msg`Download for ADFS Server 3.0`}
                textForADFS40Link={msg`Download for ADFS Server 4.0`}
                dataQaName="Adfs"
              />
            </>
          )}
        </StyledCardContainer>
      )}
    </LogoCard>
  )
}

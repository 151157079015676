import React, { FC, ReactNode } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'

import {
  DonutChart,
  DonutChartPercentageInfo
} from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/DonutChart'
import {
  Legend,
  LegendItem
} from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/Legend'

const Container = styled.div`
  display: flex;
  gap: 40px;
  justify-content: initial;
`
const LegendContainer = styled.div`
  display: flex;
  align-items: center;
`

export interface DonutChartWithLegendProps {
  totalText: MessageDescriptor
  totalTooltip?: ReactNode
  percentageInfo: DonutChartPercentageInfo
  items: LegendItem[]
  dataQa?: string
}

export const DonutChartWithLegend: FC<DonutChartWithLegendProps> = ({
  totalText,
  totalTooltip,
  percentageInfo,
  items,
  dataQa
}) => {
  const grandTotal = items
    .map(item => item.value)
    .reduce((sum, currentValue) => sum + currentValue, 0)

  return (
    <Container data-qa={dataQa}>
      <DonutChart
        items={items}
        percentageInfo={percentageInfo}
        dataQa={dataQa}
      />
      <LegendContainer data-qa={dataQa && dataQa + 'LegendContainer'}>
        <Legend
          legendTotal={{
            text: totalText,
            value: grandTotal,
            tooltip: totalTooltip
          }}
          data={items}
          dataQa={dataQa}
        />
      </LegendContainer>
    </Container>
  )
}

export enum AdminPermissions {
  dashboardView = 'DashboardView',

  managedCompanyAccess = 'ManagedCompanyAccess',
  managedCompanyView = 'ManagedCompanyView',
  managedCompanyCidOverride = 'ManagedCompanyCidOverride',
  addManagedCompany = 'AddManagedCompany',
  suspendManagedCompany = 'SuspendManagedCompany',
  detachManagedCompany = 'DetachManagedCompany',
  managedCompanyLicenseManagement = 'ManagedCompanyLicenseManagement',
  configureManagedServiceProviderPsa = 'ConfigureManagedServiceProviderPsa',

  usersView = 'UsersView',
  usersModify = 'UsersModify',
  usersSitesView = 'SitesView',
  usersMasterPasswordReset = 'UsersMasterPasswordReset',
  usersPasswordsSettingsModify = 'UsersPasswordsSettingsModify',
  usersSessionsDestroy = 'UsersSessionsDestroy',
  groupsModify = 'GroupsModify',
  adminsModify = 'AdminsModify',
  adminsSuperAdminAssign = 'AdminsSuperAdminAssign',
  directoriesAndFederationModify = 'DirectoriesAndFederationModify',

  ssoModify = 'SsoModify',
  legacyAndVpnModify = 'LegacyAndVpnModify',
  passwordAppsModify = 'PasswordAppsModify',
  sharedFoldersModify = 'SharedFoldersModify',
  mfaModify = 'MfaModify',
  userLevelMfaModify = 'UserLevelMfaModify',

  policiesModify = 'PoliciesModify',

  generalReportsView = 'GeneralReportsView',
  reportingSsoLoginActivityView = 'ReportingSsoLoginActivityView',
  reportingSamlResponseView = 'ReportingSamlResponseView',
  reportingMfaUserActivityView = 'ReportingMfaUserActivityView',
  reportingMfaAdminActivityView = 'ReportingMfaAdminActivityView',

  enterpriseOptionsModify = 'AdvancedEnterpriseOptionsModify',
  installSoftwareModify = 'AdvancedInstallSoftwareModify',
  emailNotificationsModify = 'AdvancedEmailNotificationsModify',
  enterpriseApiModify = 'AdvancedEnterpriseApiModify',
  keysModify = 'KeysModify',

  companyProfileModify = 'CompanyProfileModify',
  siemIntegrationModify = 'SiemIntegrationModify',

  viewManagedServiceProviderPsa = 'ViewManagedServiceProviderPsa'
}

import React, { FC } from 'react'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { BodyRegular, Drawer, DrawerProps, Table } from '@lastpass/lastkit'

import {
  AffectedSecurityReportUser,
  RiskType,
  SecurityReportEvent
} from '@lastpass/admin-console-dependencies/types/security-report'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'
import { WeakSecurityScoreReportButton } from '@lastpass/admin-console-dependencies/ui/reports/view/components/WeakSecurityScoreReportButton'

interface ImpactedUsersSecurityReportDrawerProps {
  closeLink: string
  event: SecurityReportEvent
}

const columns: TableColumn<AffectedSecurityReportUser>[] = [
  {
    name: <Trans>Email</Trans>,
    renderer: ({ email }) => email
  },
  {
    name: <Trans>Name</Trans>,
    renderer: ({ name }) => name
  }
]

export const ImpactedUsersSecurityReportDrawer: FC<ImpactedUsersSecurityReportDrawerProps> = ({
  closeLink,
  event
}) => {
  const headerNodes: DrawerProps['headerNodes'] =
    event.id === RiskType.weakSecurityScore ? (
      <WeakSecurityScoreReportButton users={event.usersAffected} />
    ) : (
      undefined
    )

  return (
    <Drawer
      closeLink={closeLink}
      mainTitle={<>{i18n._(event.riskName)}</>}
      data-qa="ImpactedUsersSecurityReportDrawer"
      headerNodes={headerNodes}
    >
      <>
        <Trans>Impacted users</Trans>
        <Table
          table={{
            results: event.usersAffected,
            resultsPerPage: 1,
            totalResults: 1,
            checkedRecords: []
          }}
          columns={columns}
          data-qa="ImpactedUsersSecurityReportDrawerTable"
        />
      </>
      {event.descriptions?.map(description => (
        <BodyRegular
          key={description.message.id}
          data-qa="ImpactedUsersSecurityReportDrawerDescription"
        >
          {i18n._(description.message)}
        </BodyRegular>
      ))}
    </Drawer>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/get-available-applications'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createAvailableApplications(
  applicationsServices: UACServices.Services
) {
  return function* getAvailableApplications() {
    try {
      yield put(applicationsDrawerActions.setDrawerLoading(true))
      try {
        const result: Responses = yield call(
          applicationsServices.getAvailableApplications
        )
        if (result.type === UACServices.SSOAvailableApplicationsAPI.SUCCESS) {
          yield put(
            applicationsDrawerActions.setAvailableApplications(
              result.body.availableApplicationsResponse.results
            )
          )
        }
      } finally {
        yield put(applicationsDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, Route, useLocation, useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { IconButton } from '@lastpass/lastkit'
import { AnimatedSwitch, LocationLink } from '@lastpass/routing'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { AddUsersDrawer } from '@lastpass/admin-console-dependencies/ui/users/view/add/AddUsersDrawer'
import { createCustomizeUserListDrawerPage } from '@lastpass/admin-console-dependencies/ui/users/view/customize/CustomizeUserListDrawerPage'
import { UserListDrawer } from '@lastpass/admin-console-dependencies/ui/users/view/CustomizeUserListDrawer'
import { createFilterUserListDrawerPage } from '@lastpass/admin-console-dependencies/ui/users/view/filter/FilterUserListDrawerPage'
import { TransferVaultDrawer } from '@lastpass/admin-console-dependencies/ui/users/view/transfer-vault/TransferVaultDrawer'
import {
  createUserDrawer,
  UserDrawerProps
} from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawer'
import { UserList } from '@lastpass/admin-console-dependencies/ui/users/view/UserList'
import { UserOperationsDropdown } from '@lastpass/admin-console-dependencies/ui/users/view/UserOperationsDropdown'

import { AddUsersDrawerPage } from './drawer/add/AddUsersDrawerPage'
import { UsersViewDrawerPage } from './drawer/UsersViewDrawerPage'

const UserDrawer = createUserDrawer<AppState>()
const FilterUserListDrawer = createFilterUserListDrawerPage<AppState>()
const CustomizeUserListDrawerPage = createCustomizeUserListDrawerPage<
  AppState
>()

export function UsersViewPage() {
  const { isTransferVaultHidden } = useSelector(
    (state: AppState) => state.usersList
  )
  const match = useRouteMatch()
  const location = useLocation()
  const permissions = useContext(PermissionContext)
  const canModifyUsers = permissions.requirePermission(
    AdminPermissions.usersModify
  )

  if (match) {
    const { url, path } = match
    const drawerPath = `${path}/:id`
    const drawerMatch = matchPath(location.pathname, drawerPath)
    const editDevicePath = `${path}/:id/trusted-devices/password-manager/:deviceId`
    const editDeviceDrawerMatch = matchPath(location.pathname, editDevicePath)
    const drawerProps: UserDrawerProps = {
      closeLink: url
    }

    // If the drawer is shown but it is not exact that means a child view is shown
    if (drawerMatch && !drawerMatch.isExact) {
      drawerProps.backButton = (
        <LocationLink to={drawerMatch.url}>
          <IconButton icon={ChevronLeft} />
        </LocationLink>
      )
    }
    // If the drawer is shown and we are on the edit trusted device page, the back button takes te user to trusted device page
    if (drawerMatch && editDeviceDrawerMatch && editDeviceDrawerMatch.isExact) {
      drawerProps.backButton = (
        <LocationLink to={`${drawerMatch.url}/trusted-devices`}>
          <IconButton icon={ChevronLeft} />
        </LocationLink>
      )
    }
    if (drawerMatch && drawerMatch.isExact) {
      drawerProps.moreButton = <UserOperationsDropdown />
    }

    return (
      <>
        <UserList />
        <AnimatedSwitch timeout={200}>
          {canModifyUsers && (
            <Route path={`${path}/add`}>
              <AddUsersDrawer {...drawerProps}>
                <AddUsersDrawerPage cancelLink="/users/view" />
              </AddUsersDrawer>
            </Route>
          )}
          <Route path={`${path}/customize`}>
            <UserListDrawer
              closeLink={url}
              mainTitle={<Trans>Table view settings</Trans>}
            >
              <CustomizeUserListDrawerPage />
            </UserListDrawer>
          </Route>
          <Route path={`${path}/filter`}>
            <UserListDrawer
              closeLink={url}
              mainTitle={<Trans>Filter users</Trans>}
            >
              <FilterUserListDrawer />
            </UserListDrawer>
          </Route>
          {!isTransferVaultHidden && (
            <Route path={`${drawerPath}/transfer-vault`}>
              <TransferVaultDrawer closeLink={url} />
            </Route>
          )}
          <Route path={drawerPath}>
            <UserDrawer {...drawerProps}>
              <UsersViewDrawerPage />
            </UserDrawer>
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

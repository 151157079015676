import React from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field } from 'formik'

import {
  BodyBoldStyle,
  BodySemiboldStyle,
  Checkbox,
  Collapsible,
  Select,
  TextInput
} from '@lastpass/lastkit'
import { FileUploader } from '@lastpass/lastkit/components/FileUpload'

import { CustomAttributes } from './CustomAttributes'

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const StyledTextInput = styled(TextInput)`
  margin-right: 32px;
`

const StyledSelect = styled(Select)`
  margin-right: 32px;
`

const DropdownLabelWrapper = styled.div`
  margin-left: -5px;
  margin-right: -5px;
  width: 100%;
`

const StyledLabel = styled.label<{ fluid?: boolean }>`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

const StyledCustomAttributesLabel = styled.label<{ fluid?: boolean }>`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.neutral600};
`

const CheckboxContainer = styled.div`
  padding-top: 6px;
  display: flex;
  flex-direction: column;
`

const SHA1 = 'SHA1'
const SHA256 = 'SHA256'

export const AdvancedSetupLastpass = ({ formikProps, userAttributes }) => {
  return (
    <Collapsible
      title={<Trans>Advanced settings</Trans>}
      onExpand={formikProps.validateForm}
      simple
      qadata="SetUpLastPassCardAdvancedSettings"
    >
      <InputContainer>
        <Field name="entityId" enableReinitialize={true}>
          {formData => (
            <TextInput
              toolTip={i18n._(
                msg`A unique identifier for the SAML entity (app) you’re adding. It acts as the metadata URL and may also be known as Issuer ID or App ID.`
              )}
              data-qa={'EntityIdInput'}
              error={formikProps.errors.entityId}
              errorText={formikProps.errors.entityId}
              name={formData.field.name}
              value={formData.field.value}
              onChange={e => formData.field.onChange(e)}
            >
              <Trans>Entity ID</Trans>
            </TextInput>
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <Field name="nickName" enableReinitialize={true}>
          {formData => (
            <StyledTextInput
              data-qa={'NickNameInput'}
              error={formikProps.errors.nickName}
              errorText={formikProps.errors.nickName}
              name={formData.field.name}
              value={formData.field.value}
              placeholder={msg`Optional`}
              onChange={e => formData.field.onChange(e)}
            >
              <Trans>Nickname</Trans>
            </StyledTextInput>
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <Field name="role" enableReinitialize={true}>
          {formData => (
            <StyledTextInput
              data-qa={'RoleInput'}
              error={formikProps.errors.role}
              errorText={formikProps.errors.role}
              name={formData.field.name}
              value={formData.field.value}
              placeholder={msg`Optional`}
              onChange={e => formData.field.onChange(e)}
            >
              <Trans>Role</Trans>
            </StyledTextInput>
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <Field name="identityProvider" enableReinitialize={true}>
          {formData => (
            <StyledTextInput
              data-qa={'IdentityProviderInput'}
              error={formikProps.errors.identityProvider}
              errorText={formikProps.errors.identityProvider}
              name={formData.field.name}
              value={formData.field.value}
              onChange={e => formData.field.onChange(e)}
            >
              <Trans>Identity provider</Trans>
            </StyledTextInput>
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <Field name="relayState" enableReinitialize={true}>
          {formData => (
            <StyledTextInput
              data-qa={'RelayStateInput'}
              error={formikProps.errors.relayState}
              errorText={formikProps.errors.relayState}
              name={formData.field.name}
              value={formData.field.value}
              placeholder={msg`Optional`}
              onChange={e => formData.field.onChange(e)}
            >
              <Trans>Relay state</Trans>
            </StyledTextInput>
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <Field name="identifier" enableReinitialize={true}>
          {formData => (
            <StyledSelect
              dataQa="IdentifierDropDown"
              fluid
              options={userAttributes}
              value={userAttributes.find(
                option => option.value === formData.field.value
              )}
              onChange={option =>
                formikProps.setFieldValue(
                  formData.field.name,
                  option ? option['value'] : 'Email'
                )
              }
            >
              <DropdownLabelWrapper>
                <Trans>Identifier</Trans>
              </DropdownLabelWrapper>
            </StyledSelect>
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <StyledLabel>
          <Trans>SAML signature method</Trans>
          <CheckboxContainer>
            <Checkbox
              data-qa={'SAMLSignatureMethodSHA1'}
              checked={formikProps.values.SAMLSignatureMethod === SHA1}
              onChange={e =>
                formikProps.setFieldValue(
                  'SAMLSignatureMethod',
                  e.target.checked ? SHA1 : SHA256
                )
              }
            >
              {SHA1}
            </Checkbox>
            <Checkbox
              data-qa={'SAMLSignatureMethodSHA256'}
              checked={formikProps.values.SAMLSignatureMethod === SHA256}
              onChange={e =>
                formikProps.setFieldValue(
                  'SAMLSignatureMethod',
                  e.target.checked ? SHA256 : SHA1
                )
              }
            >
              {SHA256}
            </Checkbox>
          </CheckboxContainer>
        </StyledLabel>
      </InputContainer>
      <InputContainer>
        <StyledLabel>
          <Trans>Signing and encryption</Trans>
          <CheckboxContainer>
            <Checkbox
              data-qa={'SignAssertion'}
              checked={formikProps.values.signAssertion}
              onChange={() =>
                formikProps.setFieldValue(
                  'signAssertion',
                  !formikProps.values.signAssertion
                )
              }
            >
              <Trans>Sign assertion</Trans>
            </Checkbox>
            <Checkbox
              data-qa={'EncryptAssertion'}
              checked={formikProps.values.encryptAssertion}
              onChange={() =>
                formikProps.setFieldValue(
                  'encryptAssertion',
                  !formikProps.values.encryptAssertion
                )
              }
            >
              <Trans>Encrypt assertion</Trans>
            </Checkbox>
            <Checkbox
              data-qa={'SignRequest'}
              checked={formikProps.values.signRequest}
              onChange={() =>
                formikProps.setFieldValue(
                  'signRequest',
                  !formikProps.values.signRequest
                )
              }
            >
              <Trans>Sign request</Trans>
            </Checkbox>
            <Checkbox
              data-qa={'SignResponse'}
              checked={formikProps.values.signResponse}
              onChange={() =>
                formikProps.setFieldValue(
                  'signResponse',
                  !formikProps.values.signResponse
                )
              }
            >
              <Trans>Sign response</Trans>
            </Checkbox>
          </CheckboxContainer>
        </StyledLabel>
      </InputContainer>
      <InputContainer>
        <Field name="partnerCertificate" enableReinitialize={true}>
          {formData => (
            <FileUploader
              label={msg`Upload partner certificate`}
              onChange={(e, file) => {
                formikProps.setFieldValue(formData.field.name, file)
                e.target.value = ''
              }}
              deleteFile={() => {
                formikProps.setFieldValue(formData.field.name, null)
              }}
              acceptedFileTypes={'.pem,.cer'}
            />
          )}
        </Field>
      </InputContainer>
      <InputContainer>
        <StyledCustomAttributesLabel>
          <Trans>Custom attributes</Trans>
        </StyledCustomAttributesLabel>
        <CustomAttributes
          formikPropsValues={formikProps.values}
          customAttributesOption={userAttributes}
        />
      </InputContainer>
    </Collapsible>
  )
}

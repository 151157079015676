import React, { ChangeEvent, FC, FormEvent, useState } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import {
  CardContainer,
  DetailCell,
  DetailsContainer,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

const StyledDetailCell = styled(DetailCell)`
  && {
    display: block;
  }
`

const StyledCardContainer = styled(CardContainer)`
  padding: 8px 16px 24px 16px;
`

export interface LicenseDrawerDetails {
  label: MessageDescriptor
  name: string
}

interface LicenseOperationFormProps {
  closeLink: string
  sourceDetails: LicenseDrawerDetails
  targetDetails: LicenseDrawerDetails
  valueDescriptionOfAction: MessageDescriptor
  onSubmit: (numberOfLicenses: number) => void
}

export const LicenseOperationForm: FC<LicenseOperationFormProps> = ({
  closeLink,
  sourceDetails,
  targetDetails,
  valueDescriptionOfAction,
  onSubmit
}) => {
  const [numberOfLicenses, setNumberOfLicenses] = useState<number>()

  const isSubmissionDisabled =
    numberOfLicenses === undefined || numberOfLicenses < 1

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (numberOfLicenses && !isSubmissionDisabled) {
      onSubmit(numberOfLicenses)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (!Number.isNaN(value)) {
      setNumberOfLicenses(value)
    }
  }

  return (
    <form onSubmit={handleSubmit} data-qa="ManagedCompanyLicenseOperationForm">
      <StyledCardContainer>
        <DetailsContainer>
          <StyledDetailCell
            field={i18n._(sourceDetails.label)}
            value={sourceDetails.name}
            qa="ManagedCompanyLicenseOperationFromCell"
          />
          <StyledDetailCell
            field={i18n._(targetDetails.label)}
            value={targetDetails.name}
            qa="ManagedCompanyLicenseOperationToCell"
          />
        </DetailsContainer>
        <TextInput
          data-qa="ManagedCompanyLicenseOperationNumberField"
          type="number"
          min="1"
          onChange={handleChange}
          value={numberOfLicenses && String(numberOfLicenses)}
          placeholder={msg`e.g. 10`}
        >
          {i18n._(valueDescriptionOfAction)}
        </TextInput>
      </StyledCardContainer>
      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          <LocationLink to={closeLink}>
            <TextButton
              css={css`
                margin-right: 10px;
              `}
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>
          <PrimaryButton
            type="submit"
            disabled={isSubmissionDisabled}
            data-qa="ManagedCompanyLicenseOperationSaveButton"
          >
            <Trans>Save changes</Trans>
          </PrimaryButton>
        </DrawerButtonsContainer>
      </FooterContainer>
    </form>
  )
}

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { saveAs } from 'file-saver'
import Papa from 'papaparse'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { DownloadReportName } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/state'

export interface UrlsInSharedFolderData {
  'Shared folder name': string
  URL: string
  'Vault Item Identifier': string
  Deleted: string
  'Last password change': string
  SharedFolderId?: string
}

export interface UrlsInVaultsData {
  Email: string
  URL: string
  'Vault Item Identifier': string
  Deleted: string
  'Last password change': string
}

type EncryptedSecurityReportData = UrlsInSharedFolderData | UrlsInVaultsData

export const useParseSecurityReport = (
  downloadReportName: DownloadReportName
) => {
  const dispatch = useDispatch()

  const securityReportFiles = useSelector(
    (state: AppState) => state.securityReportDrawer.securityReportFiles
  )
  const reportFile = securityReportFiles[0]

  const parseSecurityReport = useCallback(
    (controller: AbortController) => {
      let csvContents: EncryptedSecurityReportData[] = []

      return Papa.parse(reportFile, {
        worker: false,
        download: false,
        skipEmptyLines: true,
        fastMode: true,
        header: true,
        chunk: async function(result, parser) {
          if (controller.signal.aborted) {
            return
          }
          parser.pause()

          const rows: EncryptedSecurityReportData[] = result.data

          csvContents = rows.map(row => getCsvRow(row, downloadReportName))

          parser.resume()
        },
        complete: async function() {
          if (controller.signal.aborted) {
            return
          }

          const csv = Papa.unparse(csvContents)
          const BOM = '\uFEFF'
          const csvFile = new Blob([BOM + csv], {
            type: 'text/csv;charset=utf-8;'
          })

          saveAs(csvFile, `${downloadReportName}.csv`)
          dispatch(globalActions.emptyDialog())
        },
        error: function() {
          dispatch(globalActions.setNotification(genericFailedNotification))
        }
      })
    },
    [dispatch, downloadReportName, reportFile]
  )

  return parseSecurityReport
}

const getCsvRow = (
  row,
  reportName: DownloadReportName
): EncryptedSecurityReportData => {
  let csvRow: EncryptedSecurityReportData
  switch (reportName) {
    case 'urls-in-vaults':
      csvRow = {
        Email: row.Email,
        URL: row.URL,
        'Vault Item Identifier': row['"Vault Item Identifier"'],
        Deleted: row.Deleted,
        'Last password change': row['"Last password change"']
      } as UrlsInVaultsData
      break
    case 'urls-in-shared-folders':
      csvRow = {
        'Shared folder name': row['"Shared folder name"'].replace(/"/g, ''),
        URL: row.URL,
        'Vault Item Identifier': row['"Vault Item Identifier"'],
        Deleted: row.Deleted,
        'Last password change': row['"Last password change"'].replace(/"/g, '')
      } as UrlsInSharedFolderData
      break
  }
  return csvRow
}

import React from 'react'

import styled from '@emotion/styled'

import { BodySemiboldStyle } from '../styles'
import {
  TopNavigationState,
  useTopNavigationState
} from './top-navigation-context'

interface Props {
  navState: TopNavigationState
  active?: boolean
}
const Item = styled.div<Props>`
  ${BodySemiboldStyle};
  visibility: ${props =>
    props.navState === TopNavigationState.Hidden ? 'hidden' : 'inherit'};
  background-color: ${props => props.theme.colors.neutral800};
  color: ${props =>
    props.active ? props.theme.colors.neutral : props.theme.colors.neutral200};
  padding-right: 20px;
  padding-left: 12px;
  height: ${props => (props.active ? '60px' : '56px')};
  line-height: ${props => (props.active ? '48px' : '56px')};
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.colors.neutral};
  }
`

const ActiveTabIndicator = styled.div`
  position: relative;
  height: 8px;
  background: ${props => props.theme.colors.blue};
  border-radius: ${props => props.theme.radius.full};
  margin: -4px 0px 0 0px;
`

interface TopNavigationItemProps {
  active?: boolean
}

export const TopNavigationItem: React.FunctionComponent<TopNavigationItemProps> = ({
  children,
  ...props
}) => {
  const topNavState = useTopNavigationState()
  return (
    <Item navState={topNavState} active={props.active} {...props}>
      {topNavState == TopNavigationState.Main && props.active && (
        <ActiveTabIndicator data-qa={'TopNavigationPageSelected'} />
      )}
      {children}
    </Item>
  )
}

import { call, put } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'

import { permissionCategoriesMapper } from './get-admin-permissions'

export const getCompanyDetails = (state: AppState) => state.companyInfo.details

export function createGetManagedAdminPermissionsSaga(
  userService: UACServices.Services
) {
  return function* getAdminPermissionsSaga() {
    try {
      const result: UACServices.ManagedAdminPermissionCategoriesAPI.Responses = yield call(
        userService.managedAdminPermissionCategories
      )
      if (
        result.type === UACServices.ManagedAdminPermissionCategoriesAPI.SUCCESS
      ) {
        const {
          availableMspCidOverrideAdminLevels,
          mspCidOverridePermissionsCategories
        } = result.body

        yield put(
          adminLevelDrawerActions.setManagedAdminPermissions(
            permissionCategoriesMapper(mspCidOverridePermissionsCategories),
            availableMspCidOverrideAdminLevels
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

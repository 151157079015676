import { push } from 'connected-react-router'
import { put, take } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { legacyVpnListActions } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/actions'
import { workstationListActions } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function createShowMfaUsageNotificationSaga() {
  return function* showMfaUsageNotificationSaga(
    actions: ReturnType<typeof globalActions.showMfaUsageNotificationDialog>
  ) {
    try {
      const newRoute = actions.payload.newRoute

      yield put(
        globalActions.setDialog({
          type: 'mfausagenotificationdialog',
          mfaDialogContext: actions.payload.context
        })
      )

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.DISCARD_DIALOG) {
        yield put(globalActions.emptyDialog())
      }

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        switch (actions.payload.context) {
          case MfaUpsellDialogContext.legacyvpn:
            yield put(legacyVpnListActions.getStarted())
            break
          case MfaUpsellDialogContext.workstation:
            yield put(workstationListActions.getStarted())
            break
          case MfaUpsellDialogContext.passwordless:
          case MfaUpsellDialogContext.multifactor:
            yield newRoute && put(push(newRoute))
            break
        }
        yield put(globalActions.emptyDialog())
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

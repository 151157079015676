import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AppIcon } from '@lastpass/assets/svg/admin-console/icon-app.svg'
import { ReactComponent as FormIcon } from '@lastpass/assets/svg/admin-console/icon-form.svg'
import {
  DrawerTable,
  Pagination,
  RowContainer,
  SearchInput,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { Site } from '@lastpass/admin-console-dependencies/types/site'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['sites'],
  typeof userDrawerActions
>

const StyledAppIcon = styled(AppIcon)`
  width: 16px;
  height: 16px;
`

export const UserDrawerSitesTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: sites
}) => {
  const queryParams = useQueryParams(Namespace.sites)
  const updateQuery = useUpdateQuery(Namespace.sites)
  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserSites({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])
  useDrawerSubtitle(<Trans>Sites</Trans>)
  if (sites) {
    return (
      <>
        <RowContainer>
          <SearchInput
            flexGrow
            value={queryParams.search}
            placeholder={msg`Search Sites`}
            onChange={e =>
              updateQuery({
                search: e.target.value || undefined,
                skip: undefined
              })
            }
            data-qa={'SearchSitesInputField'}
          />
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={sites.totalResults}
            resultsPerPage={sites.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </RowContainer>
        <DrawerTable
          qadata={'UserLevelFullSitesList'}
          table={sites}
          noRecordsView={
            <TableView
              icon={<FormIcon />}
              title={<Trans>This user hasn’t saved any sites yet</Trans>}
              text={<Trans>Once they do, you’ll see a list here</Trans>}
            />
          }
          noResultsView={
            <TableView
              icon={<FormIcon />}
              title={<Trans>Sorry, no results match your search</Trans>}
              text={
                <Trans>Try different keywords or check your spelling</Trans>
              }
            />
          }
          isFilterApplied={!!queryParams.search}
          columns={[
            {
              name: <></>,
              component: StyledAppIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Site Name</Trans>,
              renderer: function SiteNameRenderer(record: Site) {
                return (
                  <>
                    {record.domain}
                    {record.isDeleted && (
                      <>
                        &nbsp;(<Trans>Deleted</Trans>)
                      </>
                    )}
                  </>
                )
              }
            },
            {
              name: <Trans>Last login</Trans>,
              noTooltip: true,
              renderer: function LastLoginRenderer(record: Site) {
                return (
                  <TimeSinceActivity
                    lastActivityTime={record.lastLoginTimeUtc}
                  />
                )
              }
            }
          ]}
        />
      </>
    )
  }
  return null
}

export function createUserDrawerSitesTable<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserDrawerSitesTableComponent,
    (state: TState) => state.usersDrawer.sites,
    userDrawerActions
  )
}

export type UserDrawerSitesTable = ReturnType<typeof createUserDrawerSitesTable>

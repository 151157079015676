import React from 'react'

import styled from '@emotion/styled'

import { BodyBoldStyle, BodyRegularStyle } from '../styles'
import { TextButton } from './TextButton'

export interface TableViewProps {
  qadata?: string
  icon: React.ReactElement
  title: React.ReactElement
  text?: React.ReactElement
  actionButton?: React.ReactElement
  small?: boolean
}

export const Container = styled.div<{ small: boolean }>`
  width: 100%;
  ${props => !props.small && 'height: 100%;'}
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TableViewButton = styled(TextButton)`
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
  margin-top: 8px;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue800};
  }
`

const Text = styled.p`
  ${BodyRegularStyle}
  color: ${props => props.theme.colors.neutral700};
`

const Title = styled.p`
  ${BodyBoldStyle}
  margin-top: 16px;
`

export function TableView(props: TableViewProps) {
  return (
    <Container small={!!props.small} data-qa={props.qadata}>
      {props.icon}
      <Title>{props.title}</Title>
      {props.text && <Text>{props.text}</Text>}
      {props.actionButton}
    </Container>
  )
}

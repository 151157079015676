import { FunctionComponent, PropsWithChildren } from 'react'
import { connect } from 'react-redux'

import { ActionsObject } from '@lastpass/admin-console-dependencies/state/action'

import { LastPassComponentProps } from './component-props'

export const connectRedux = <
  TAppState,
  TState,
  TActions extends ActionsObject,
  TProps
>(
  component: FunctionComponent<
    LastPassComponentProps<TState, TActions, TProps>
  >,
  selector: (state: TAppState) => TState,
  actions?: TActions
): FunctionComponent<TProps> =>
  // @ts-ignore
  connect(
    selector,
    actions,
    (stateProps, dispatchProps, ownProps: PropsWithChildren<TProps>) => {
      const { children, ...otherOwnProps } = ownProps
      return {
        children: children,
        props: otherOwnProps,
        actions: dispatchProps,
        state: stateProps
      }
    }
    // @ts-ignore
  )(component)

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
export function createGetAdminLevelUsersSaga(
  userService: UACServices.Services
) {
  return function* getAdminLevelUsersSaga(
    action: ReturnType<typeof adminLevelDrawerActions.getAdminLevelUsers>
  ) {
    try {
      const id = action.payload.params.path.id
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        search: encodeSearch(action.payload.params.query.search),
        top: resultsPerPage
      })
      if (id) {
        yield put(adminLevelDrawerActions.setAdminLevelUsersLoading(true))
        try {
          const result: UACServices.AdminLevelUsersAPI.Responses = yield call(
            userService.adminLevelUsers,
            id,
            query
          )
          if (result.type === UACServices.AdminLevelUsersAPI.SUCCESS) {
            const users = result.body.users
            const totalResults = result.body.totalResults
            yield put(
              adminLevelDrawerActions.setAdminLevelUsers(
                users,
                totalResults,
                resultsPerPage
              )
            )
          }
        } finally {
          yield put(adminLevelDrawerActions.setAdminLevelUsersLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

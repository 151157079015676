import React from 'react'

import { Trans } from '@lingui/macro'

import { Collapsible } from '@lastpass/lastkit/components'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

import { AccountDetails } from '../AccountDetails'

export type AccountDetailsCardProps = LastPassComponentProps<
  {
    account: UsersDrawerState['accountDetails']
    loadingInfo: UsersDrawerState['accountDetailsLoading']
    isFederatedLoginEnabled: boolean
  },
  typeof userDrawerActions,
  {
    viewFullLink: string
    expand: boolean
    onExpand: Function
  }
>

export const AccountDetailsCardComponent: React.FunctionComponent<AccountDetailsCardProps> = ({
  state: accountDetails,
  props,
  actions
}) => {
  const pathParams: UserDrawerUrlParams = usePathParams()

  return (
    <Collapsible
      title={<Trans>Account details</Trans>}
      expanded={props.expand}
      onInitialExpand={() =>
        actions.getAccountDetails({ path: pathParams, query: {} })
      }
      loading={accountDetails.loadingInfo}
      onExpand={props.onExpand}
      qadata={'AccountDetailsCard'}
    >
      {accountDetails && (
        <AccountDetails
          data={accountDetails.account}
          viewFullLink={props.viewFullLink}
          isFederatedLoginEnabled={accountDetails.isFederatedLoginEnabled}
        />
      )}
    </Collapsible>
  )
}

export function createAccountDetailsCard<
  TState extends {
    usersDrawer: UsersDrawerState
    companyInfo: CompanyInfoState
  }
>() {
  return connectRedux(
    AccountDetailsCardComponent,
    (state: TState) => {
      return {
        account: state.usersDrawer.accountDetails,
        loadingInfo: state.usersDrawer.accountDetailsLoading,
        isFederatedLoginEnabled:
          state.companyInfo.details.isFederatedLoginEnabled
      }
    },
    userDrawerActions
  )
}

export type AccountDetailsCard = ReturnType<typeof createAccountDetailsCard>

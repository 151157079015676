import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  BodyRegular,
  ButtonsContainer,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { AdminLevelPermissionsTreeCard } from '../permission-tree-card/AdminLevelPermissionsTreeCard'

const InputContainer = styled.div`
  margin-bottom: 20px;
`

const StyledDescription = styled(BodyRegular)`
  display: inline-block;
  margin-top: -18px;
  margin-bottom: 12px;
`

export interface AdminFormValues {
  levelName?: string
  levelDescription?: string
  levelPermission: AdminPermissions[]
  mspCidOverrideAdminLevel?: string
  mspCidOverridePermissions?: AdminPermissions[]
  isAssignUsers?: boolean
}

interface AdminLevelFormParams {
  id?: string
}

export interface AdminLevelFormProps {
  closeLink: string
  formValues?: AdminFormValues
  editMode?: boolean
}

export const AdminLevelForm: React.FunctionComponent<AdminLevelFormProps> = ({
  closeLink,
  formValues,
  editMode
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch<AdminLevelFormParams>()
  const id = match?.params?.id || ''

  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const isLevelNameOrAdminLevelEmpty = formikProps =>
    !(formikProps.values.levelName && formikProps.values.levelPermission.length)

  const hasCidOverridePermission = values =>
    values.levelPermission.includes(AdminPermissions.managedCompanyCidOverride)

  const adminSchema = Yup.object().shape({
    levelName: Yup.string()
      .max(
        300,
        i18n._(msg`Maximum length for admin level name is 300 characters.`)
      )
      .required(i18n._(msg`Admin level name is required`)),
    levelDescription: Yup.string().max(
      300,
      i18n._(msg`Maximum length for admin level description is 300 characters.`)
    )
  })

  return (
    <>
      {isRoleBasedAccessControlEnabled && (
        <StyledDescription>
          <Trans>
            {`Select the permissions to be applied when logged in to your own
            company's Admin Console.`}
          </Trans>
        </StyledDescription>
      )}
      <Formik
        initialValues={{
          levelName: formValues?.levelName ?? '',
          levelDescription: formValues?.levelDescription ?? '',
          levelPermission: formValues?.levelPermission ?? [],
          mspCidOverrideAdminLevel: formValues?.mspCidOverrideAdminLevel ?? '',
          mspCidOverridePermissions:
            formValues?.mspCidOverridePermissions ?? [],
          isAssignUsers: false
        }}
        onSubmit={(values: AdminFormValues) => {
          setIsBlockingPromptEnabled(false)
          if (editMode) {
            dispatch(
              adminLevelDrawerActions.editAdminLevel({
                id,
                name: values.levelName ?? '',
                description: values.levelDescription ?? '',
                permissions: values.levelPermission,
                closeLink,
                isPartial: isRoleBasedAccessControlEnabled
              })
            )
          } else {
            dispatch(
              adminLevelDrawerActions.addNewAdminLevel({
                name: values.levelName ?? '',
                description: values.levelDescription ?? '',
                permissions: values.levelPermission,
                isPartial:
                  isRoleBasedAccessControlEnabled &&
                  hasCidOverridePermission(values),
                closeLink: !values.isAssignUsers ? closeLink : undefined
              })
            )
          }
        }}
        validationSchema={adminSchema}
      >
        {formik => (
          <Form
            onChange={() => setIsBlockingPromptEnabled(true)}
            onSubmit={formik.handleSubmit}
          >
            <InputContainer>
              <TextInput
                id="levelName"
                name="levelName"
                type="text"
                placeholder={msg`Required`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data-qa="NewAdminLevelName"
                value={formik.values.levelName}
                error={!!formik.errors.levelName}
                errorText={<>{formik.errors.levelName}</>}
              >
                <Trans>Admin level name</Trans>
              </TextInput>
            </InputContainer>
            <InputContainer>
              <TextInput
                id="levelDescription"
                name="levelDescription"
                type="text"
                placeholder={msg`Optional`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.levelDescription}
                error={!!formik.errors.levelDescription}
                errorText={<>{formik.errors.levelDescription}</>}
                data-qa="NewAdminLevelDescription"
              >
                <Trans>Description</Trans>
              </TextInput>
            </InputContainer>
            <AdminLevelPermissionsTreeCard formik={formik} />
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <TextButton
                  css={css`
                    margin-right: 16px;
                  `}
                  data-qa="CancelButton"
                  type="button"
                  onClick={() => {
                    history.push(closeLink)
                  }}
                >
                  <Trans>Cancel</Trans>
                </TextButton>
                {!editMode && (
                  <>
                    <TextButton
                      css={css`
                        margin-right: 16px;
                      `}
                      data-qa="SaveAndExitButton"
                      type="submit"
                      disabled={
                        isLevelNameOrAdminLevelEmpty(formik) ||
                        hasCidOverridePermission(formik.values)
                      }
                      onClick={() => {
                        formik.setFieldValue('isAssignUsers', false)
                      }}
                    >
                      <Trans>Save {'&'} exit</Trans>
                    </TextButton>
                    <PrimaryButton
                      data-qa="SaveAndAssignButton"
                      type="submit"
                      disabled={isLevelNameOrAdminLevelEmpty(formik)}
                      onClick={() => {
                        formik.setFieldValue('isAssignUsers', true)
                      }}
                    >
                      <Trans>Save {'&'} continue</Trans>
                    </PrimaryButton>
                  </>
                )}
                {editMode && (
                  <PrimaryButton
                    css={css`
                      margin-right: 16px;
                    `}
                    data-qa="SaveChangesButton"
                    type="submit"
                    disabled={isLevelNameOrAdminLevelEmpty(formik)}
                    onClick={() => {
                      formik.setFieldValue('isAssignUsers', false)
                    }}
                  >
                    <Trans>Save changes</Trans>
                  </PrimaryButton>
                )}
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

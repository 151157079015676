import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { msg, Trans } from '@lingui/macro'

import { ReactComponent as IconGreen } from '@lastpass/assets/svg/admin-console/security-dashboard/reused-master-password-green.svg'
import { ReactComponent as IconRed } from '@lastpass/assets/svg/admin-console/security-dashboard/reused-master-password-red.svg'
import { PrimaryButton } from '@lastpass/lastkit'

import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'
import { ActionSuccess } from '@lastpass/admin-console-dependencies/ui/home/components/action-success/ActionSuccess'
import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

export interface ReusedMasterPasswordsCardProps {
  count: number
  dataQa?: string
}

export const ReusedMasterPasswordsCard: FC<ReusedMasterPasswordsCardProps> = ({
  count,
  dataQa
}) => {
  const countColor = useCountColor(count)
  return (
    <ActionCard
      icon={count === 0 ? IconGreen : IconRed}
      count={count}
      countColor={countColor}
      title={msg`Reused master passwords`}
      description={
        <Trans>
          Users should never use their master password as the password for sites
          in their vault.
        </Trans>
      }
      action={
        count === 0 ? (
          <ActionSuccess message={msg`No reused master passwords`} />
        ) : (
          <Link to="/home/security-dashboard/reusedmasterpasswordlist">
            <PrimaryButton data-qa="ReusedMasterPasswordsButton">
              <Trans>See what to do</Trans>
            </PrimaryButton>
          </Link>
        )
      }
      dataQa={dataQa}
    />
  )
}

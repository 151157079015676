import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as GoogleIcon } from '@lastpass/assets/svg/admin-console/directories/icon-google.svg'
import {
  BodyBoldStyle,
  BodyRegularStyle,
  BodySemiboldStyle,
  Checkbox,
  PrimaryButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

import {
  StyledInstructionDiv,
  StyledSetupDiv
} from './DirectoriesPageCopiedComponent'
import { IntegrationCard } from './IntegrationCard'

interface GoogleIntegrationProps {
  expand?: boolean
  lastSync?: string
  provisionToken?: string
  hasProvisionToken: boolean
  adminEmail?: string
  enabled: boolean
  integrationExists: boolean
  partialSyncEnabled: boolean
}

const ProvisionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  position: relative;
  display: flex;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SyncSettings = styled.div`
  margin-top: 6px;
  ${BodyBoldStyle};
`

const AdminEmail = styled(TextInput)`
  max-width: none;
  margin-right: 20px;
`

const FieldContainer = styled.div`
  margin-top: 16px;
`
const ProvisionToken = styled.textarea`
  ${BodyRegularStyle};
  background: ${props => props.theme.colors.neutral200};
  border: ${props => '1px solid ' + props.theme.colors.neutral300};
  width: 100%;
  box-sizing: border-box;
  border-radius: ${props => props.theme.radius.pixel4};
  margin-right: 20px;
  ${props => props.disabled && 'color:' + props.theme.colors.neutral500};
`

const StyledLabel = styled.label`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

const FileUploadLabel = styled.label`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.blue700};
  cursor: pointer;
`

const FileUploadInput = styled.input`
  display: none;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 16px;
`

export const StyledButton = styled(PrimaryButton)`
  width: 202px;
  margin-left: 18px;
`

const StyledLink = styled(LocationLink)`
  color: ${props => props.theme.colors.blue.light};
  text-decoration: none;
`

const BlueTextButton = styled.button<{ disabled: boolean }>`
  color: ${props => props.theme.colors.blue};
  display: inline-block;
  margin-left: 18px;
  border: 0px;
  background: transparent;
  font-size: inherit;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export interface ProvisionToken {
  [key: string]: string
}

export const provisionTokenErrors = {
  invalidFormat: msg`JSON upload failed. Malformed JSON.`,
  wrongType: msg`JSON upload failed. Type must be service_account.`,
  missingField: msg`JSON upload failed. Missing fields in JSON file.`,
  invalidField: msg`JSON upload failed. Invalid fields in JSON file.`,
  invalidFieldValue: msg`JSON upload failed. Invalid field values in JSON file.`
}

const provisionTokenFields = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url'
]

const provisionTokenOptionalFields = ['universe_domain']

function testProvisionTokenFields(provisionToken: ProvisionToken) {
  for (const field of provisionTokenFields) {
    if (!provisionToken[field]) {
      return false
    }
  }

  return true
}

function testProvisionTokenType(provisionToken: ProvisionToken) {
  return provisionToken.type === 'service_account'
}

function testProvisionTokenFieldValues(provisionToken: ProvisionToken) {
  for (const field of provisionTokenFields) {
    if (typeof provisionToken[field] !== 'string') {
      return false
    }
  }

  for (const field of provisionTokenOptionalFields) {
    if (provisionToken[field] && typeof provisionToken[field] !== 'string') {
      return false
    }
  }

  return true
}

function testProvisionTokenExtraFields(provisionToken: ProvisionToken) {
  const allProvisionTokenFields = provisionTokenFields.concat(
    provisionTokenOptionalFields
  )
  for (const key of Object.keys(provisionToken)) {
    if (!allProvisionTokenFields.includes(key)) {
      return false
    }
  }

  return true
}

function validateProvisionToken(
  jsonString: string,
  callback: (message: MessageDescriptor) => void
) {
  const {
    missingField,
    wrongType,
    invalidField,
    invalidFieldValue,
    invalidFormat
  } = provisionTokenErrors

  try {
    const provisionToken = JSON.parse(jsonString)

    const validators = [
      testProvisionTokenFields,
      testProvisionTokenType,
      testProvisionTokenExtraFields,
      testProvisionTokenFieldValues
    ]
    const errors = [missingField, wrongType, invalidField, invalidFieldValue]

    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i]
      if (!validator(provisionToken)) {
        callback(errors[i])
        return false
      }
    }

    return true
  } catch {
    callback(invalidFormat)

    return false
  }
}

export const GoogleIntegrationCard: FunctionComponent<GoogleIntegrationProps> = props => {
  const dispatch = useDispatch()
  const isSyncing = useSelector(
    (state: AppState) => state.integrations.isSyncing
  )

  const validationSchema = Yup.object().shape({
    adminEmail: Yup.string()
      .email(i18n._(msg`Invalid email address`))
      .required(i18n._(msg`Email address is required`))
      .nullable()
  })

  const initialValues = {
    adminEmail: props.adminEmail,
    enabled: props.enabled,
    provisionToken: props.provisionToken,
    hasProvisionToken: props.hasProvisionToken,
    integrationExists: props.integrationExists,
    partialSyncEnabled: props.partialSyncEnabled
  }

  const match = useRouteMatch()

  const handleSyncClick = () => {
    dispatch(directoriesActions.syncStarted())
    dispatch(directoriesActions.syncGoogleUsers())
  }

  const handleSyncOptionsUpdate = (
    {
      adminEmail,
      provisionToken,
      enabled
    }: {
      adminEmail: string
      provisionToken: string
      enabled: boolean
    },
    callback: () => void
  ) => {
    dispatch(
      directoriesActions.updateGoogleSyncOptions(
        props.partialSyncEnabled,
        adminEmail,
        provisionToken,
        enabled,
        callback
      )
    )
  }

  const handleIntegrationLogsClick = () => {
    dispatch(directoriesActions.reportIntegrationLogsClicked())
  }

  const handleNotification = (notification: MessageDescriptor) => {
    dispatch(
      globalActions.setNotification({
        type: NotificationType.alert,
        autoDismiss: true,
        message: notification
      })
    )
  }

  if (!match) {
    return null
  }

  const { url } = match

  const syncSettings = props.integrationExists ? (
    props.partialSyncEnabled ? (
      <SyncSettings>
        <Trans>Current sync setting: Specified users</Trans>
      </SyncSettings>
    ) : (
      <SyncSettings>
        <Trans>Current sync setting: All users</Trans>
      </SyncSettings>
    )
  ) : null

  return (
    <IntegrationCard
      expand={props.expand}
      logo={<GoogleIcon data-qa="GoogleIcon" />}
      title={<Trans>Google Workspace</Trans>}
      subtitle={
        <Trans>
          Automatically provision users to LastPass from Google Workspace.
        </Trans>
      }
      description={
        <StyledSetupDiv>
          <div>
            <Trans>
              Creating, disabling, and deleting user profiles in Google
              Workspace results in a corresponding action for LastPass accounts.
            </Trans>
          </div>
          <div>
            <Trans>
              User groups can be synced to LastPass for policy designations,
              Shared Folder assignments, and SAML application provisioning.
            </Trans>
          </div>
          <div>
            <Trans>
              User data will be periodically synced by default, or you can sync
              manually on this page.
            </Trans>
          </div>
          {syncSettings}
          <StyledInstructionDiv>
            <a
              href="https://support.logmeininc.com/lastpass/help/set-up-federated-login-for-lastpass-using-google-workspace"
              data-qa="GoogleSetupInstructionLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>View setup instructions</Trans>
            </a>
          </StyledInstructionDiv>
        </StyledSetupDiv>
      }
      primaryText={
        <>
          <ProvisionInfoContainer>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values, formikActions) => {
                formikActions.setSubmitting(true)
                dispatch(
                  directoriesActions.saveGoogleIntegration(
                    values.adminEmail ? values.adminEmail : '',
                    values.provisionToken ? values.provisionToken : '',
                    values.enabled,
                    props.integrationExists,
                    () => {
                      formikActions.setSubmitting(false)
                    }
                  )
                )
              }}
              isInitialValid={validationSchema.isValidSync(initialValues)}
            >
              {formikProps => (
                <>
                  <Field name="adminEmail">
                    {formData => (
                      <AdminEmail
                        data-qa="GoogleIntegrationAdminEmail"
                        name={formData.field.name}
                        value={formData.field.value}
                        error={!!formikProps.errors.adminEmail}
                        errorText={<>{formikProps.errors.adminEmail}</>}
                        onChange={e => formData.field.onChange(e)}
                        placeholder={msg`Email`}
                      >
                        <Trans>Admin email</Trans>
                      </AdminEmail>
                    )}
                  </Field>
                  {props.integrationExists && (
                    <FieldContainer>
                      <Field name="enabled">
                        {formData => (
                          <Checkbox
                            data-qa="GoogleIntegrationEnabled"
                            name={formData.field.name}
                            checked={formData.field.value}
                            onChange={e => {
                              formikProps.setFieldValue(
                                formData.field.name,
                                e.currentTarget.checked
                              )
                            }}
                          >
                            <Trans>Enabled</Trans>
                          </Checkbox>
                        )}
                      </Field>
                    </FieldContainer>
                  )}
                  <FieldContainer>
                    <Field name="provisionToken">
                      {formData => (
                        <>
                          {formData.field.value ? (
                            <StyledLabel>
                              <Trans>Uploaded JSON file</Trans>
                              <Container>
                                <Row>
                                  <ProvisionToken
                                    value={formData.field.value}
                                    data-qa="GoogleProvisioningToken"
                                    rows={5}
                                    disabled
                                  />
                                </Row>
                              </Container>
                            </StyledLabel>
                          ) : formikProps.values.hasProvisionToken ? (
                            <span>
                              <Trans>
                                Your JSON file was successfully uploaded. The
                                content of this file is hidden for your privacy.
                                You can reupload the file with the button below.
                              </Trans>{' '}
                            </span>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Field>
                  </FieldContainer>
                  <Footer>
                    <FileUploadLabel
                      data-qa="GoogleIntegrationFileUploadButton"
                      htmlFor="googleIntegrationFileUploadComponent"
                    >
                      <Trans>Upload credentials.json</Trans>
                    </FileUploadLabel>
                    <FileUploadInput
                      type="file"
                      accept=".json"
                      id="googleIntegrationFileUploadComponent"
                      data-qa="googleIntegrationFileUpload"
                      onChange={e => {
                        if (e.target && e.target.files) {
                          const fileReader = new FileReader()

                          fileReader.onloadend = () => {
                            const token = String(fileReader.result)

                            if (
                              validateProvisionToken(token, handleNotification)
                            ) {
                              formikProps.setFieldValue('provisionToken', token)
                            }
                          }

                          fileReader.readAsText(e.target.files[0])

                          // Resetting value of the file input so the same file can be uploaded after correction
                          e.target.value = ''
                        }
                      }}
                    />
                    <ButtonContainer>
                      <StyledLink
                        data-qa="GoogleAuditLogLink"
                        to={`${url}${
                          url.endsWith('/') ? '' : '/'
                        }audit-log/integration-logs`}
                        onClick={handleIntegrationLogsClick}
                      >
                        <Trans>View integration logs</Trans>
                      </StyledLink>
                      {props.integrationExists && (
                        <BlueTextButton
                          onClick={() => {
                            const {
                              adminEmail = '',
                              provisionToken = '',
                              enabled
                            } = formikProps.values
                            formikProps.setSubmitting(true)

                            handleSyncOptionsUpdate(
                              {
                                adminEmail,
                                provisionToken,
                                enabled
                              },
                              () => {
                                formikProps.setSubmitting(false)
                              }
                            )
                          }}
                          data-qa="GoogleSyncOptionsButton"
                          disabled={formikProps.isSubmitting}
                        >
                          <Trans>Sync options</Trans>
                        </BlueTextButton>
                      )}
                      <StyledButton
                        data-qa="GoogleIntegrationSyncButton"
                        onClick={handleSyncClick}
                        disabled={
                          !props.hasProvisionToken ||
                          !formikProps.values.enabled ||
                          formikProps.dirty ||
                          isSyncing
                        }
                      >
                        <Trans>Sync users</Trans>
                      </StyledButton>
                      <StyledButton
                        data-qa="GoogleIntegrationSaveButton"
                        type="submit"
                        onClick={() => {
                          formikProps.handleSubmit()
                        }}
                        disabled={
                          !formikProps.isValid ||
                          !formikProps.dirty ||
                          formikProps.isSubmitting ||
                          (!formikProps.values.hasProvisionToken &&
                            !formikProps.values.provisionToken)
                        }
                      >
                        <Trans>Save changes</Trans>
                      </StyledButton>
                    </ButtonContainer>
                  </Footer>
                </>
              )}
            </Formik>
          </ProvisionInfoContainer>
        </>
      }
      lastSync={props.lastSync}
    />
  )
}

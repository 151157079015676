import React from 'react'

import { Trans } from '@lingui/macro'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'
import { SystemUpgradeDrawerHeadersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SystemUpgradeMapping'

import {
  DetailsBox,
  DrawerContainer,
  LegacyConsolePath,
  StyledLink,
  SystemUpgradeDrawerFooter,
  SystemUpgradeDrawerProps,
  SystemUpgradeFeatureNotAvailable
} from './SystemUpgradeDrawerResources'

const detailsBoxContent = [
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatFor],
    dataQa: 'customAttributesDrawerWhatFeatureForContainer',
    mainContent: (
      <Trans>
        Custom attributes were SAML attributes that allowed extra flexibility
        when configuring SSO apps with unique requirements. Some organizations
        also used them to add unique information about their users to the Admin
        Console.
      </Trans>
    )
  },
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.where],
    dataQa: 'customAttributesDrawerFeatureWhereContainer',
    mainContent: (
      <ul>
        <li>
          <Trans>New console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <Trans>Old console</Trans>
          {': '}
          <StyledLink
            href={`${defaultConfig.lastPassBaseAddress}/company/?resetconsole=true#!/users/`}
            target="_blank"
            data-qa="customAttributesDrawerOldConsoleLink"
          >
            <Trans>Users</Trans>
            {' > '}
            <Trans>User</Trans>
            {' > '}
            <Trans>Edit User Directory attributes</Trans>
          </StyledLink>
        </li>
        <li>
          <LegacyConsolePath
            isNotAvailable={false}
            path={
              <Trans>
                Users{' > '}User settings{' > '}User attributes
              </Trans>
            }
          />
        </li>
      </ul>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[
        SystemUpgradeDrawerHeader.whatsHappening
      ],
    dataQa: 'customAttributesDrawerFeatureWhatHappeningContainer',
    mainContent: (
      <Trans>
        This feature has been retired along with the legacy Identity console at
        identity.lastpass.com.
      </Trans>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatToDo],
    dataQa: 'customAttributesDrawerFeatureWhatToDoContainer',
    mainContent: (
      <Trans>
        After upgrade, you can use this feature in the Old console under Users{' '}
        {'>'} User {'>'} Edit User Directory attributes.
      </Trans>
    )
  }
]

export const CustomAttributesDrawer: React.FunctionComponent<SystemUpgradeDrawerProps> = ({
  closeLink
}) => (
  <Drawer
    mainTitle={<Trans>Custom attributes</Trans>}
    subTitle={<Trans>Details</Trans>}
    closeLink={closeLink}
    data-qa="customAttributesDrawer"
  >
    <DrawerContainer data-qa="customAttributesDrawerContentContainer">
      {detailsBoxContent.map(content => (
        <DetailsBox
          key={content.dataQa}
          title={content.title}
          data-qa={content.dataQa}
        >
          {content.mainContent}
        </DetailsBox>
      ))}
    </DrawerContainer>
    <SystemUpgradeDrawerFooter
      closeLink={closeLink}
      data-qa="customAttributesDrawerFooter"
    />
  </Drawer>
)

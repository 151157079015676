import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const FAILURE = 'failure'
export const SYNC_IN_PROGRESS = 'sync_in_progress'

const success = () => createResponse(SUCCESS)
const failure = (reason: string) => createResponse(FAILURE, reason)
const syncInProgress = () => createResponse(SYNC_IN_PROGRESS)

export const Responses = {
  success,
  failure,
  syncInProgress
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'google-workspace-provisioning/sync-users',
        'POST',
        {},
        { 'content-type': 'application/json' }
      )
      const responseJson = (response && JSON.parse(response)) || {}

      if (status === StatusCodes.OK) {
        return success()
      }

      if (status === StatusCodes.TOO_MANY_REQUESTS) {
        return syncInProgress()
      }

      return failure(responseJson.message || '')
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'

export function createGetCurrentAdminLevelSaga(
  userService: UACServices.Services
) {
  return function* getCurrentAdminLevel() {
    try {
      const result: UACServices.CurrentAdminLevelAPI.Responses = yield call(
        userService.currentAdminLevel
      )
      if (result.type === UACServices.CurrentAdminLevelAPI.SUCCESS) {
        const currentAdminLevel: string = result.body
        yield put(userListActions.setCurrentAdminLevel(currentAdminLevel))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UsernameAttribute } from '@lastpass/admin-console-dependencies/state/applications/application'

export const SUCCESS = 'success'

const success = (usernameAttributes: UsernameAttribute[]) =>
  createResponse(SUCCESS, { usernameAttributes })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await identityApi(
        `api/v2/Users/attributes`,
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const userNameAttributesResponse = JSON.parse(response)
        return success(
          userNameAttributesResponse.Value.filter(attr => !attr.System).map(
            attr => ({
              value: attr.AttributeGUID,
              label: attr.Name,
              serverAttribute: true
            })
          )
        )
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

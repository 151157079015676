import { GoogleGroup } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

export interface GoogleGroupBranch {
  id: string
  name: string
  selected: boolean
  children: GoogleGroupBranch[]
}

interface ExtendedGoogleGroup extends GoogleGroup {
  parent: string
}

const createGroupObject = (
  groups: GoogleGroup[]
): { [key: string]: ExtendedGoogleGroup } => {
  const groupObject = {}

  groups.forEach(group => {
    groupObject[group.id] = { ...group }
  })

  groups.forEach(group => {
    if (group.children) {
      const invalidChildren: string[] = []

      group.children.forEach(child => {
        if (Object.keys(groupObject).includes(String(child))) {
          groupObject[child].parent = group.id
        } else {
          invalidChildren.push(child)
        }
      })

      groupObject[group.id].children = group.children.filter(
        child => !invalidChildren.includes(child)
      )
    }
  })

  return groupObject
}

const getGroupBranch = (
  group: GoogleGroup,
  groupObject: { [key: string]: ExtendedGoogleGroup }
) => {
  return {
    id: group.id,
    name: group.name,
    selected: group.selected,
    children:
      group.children && group.children.length > 0
        ? group.children.map(childId =>
            getGroupBranch(groupObject[childId], groupObject)
          )
        : []
  }
}

export const buildGoogleGroupTree = (
  groups: GoogleGroup[]
): GoogleGroupBranch[] => {
  const tree: GoogleGroupBranch[] = []

  if (!groups) {
    return tree
  }

  const groupObject = createGroupObject(groups)

  Object.keys(groupObject).forEach(key => {
    const group = groupObject[key]

    if (!group.parent) {
      const branch = getGroupBranch(group, groupObject)

      tree.push(branch)
    }
  })

  return tree
}

export const getSelectedGroups = (
  groups: GoogleGroupBranch[]
): GoogleGroup[] => {
  const selectedGroups = groups
    .filter(group => group.selected)
    .map(group => ({
      id: group.id,
      name: group.name,
      selected: true,
      children: group.children.map(child => child.id)
    }))
  const children = groups.reduce(
    (a, b) => [...a, ...b.children],
    [] as GoogleGroupBranch[]
  )
  const selectedChildGroups =
    children.length > 0 ? getSelectedGroups(children) : []
  return [...selectedGroups, ...selectedChildGroups]
}

export const filterGoogleGroupTree = (
  tree: GoogleGroupBranch[],
  filterCondition: (branch: GoogleGroupBranch) => boolean
): GoogleGroupBranch[] => {
  return tree
    .map(branch => ({
      branch,
      filteredChildBranches: filterGoogleGroupTree(
        branch.children,
        filterCondition
      )
    }))
    .filter(
      ({ branch, filteredChildBranches }) =>
        filterCondition(branch) || filteredChildBranches.length > 0
    )
    .map(({ branch, filteredChildBranches }) => ({
      ...branch,
      children: filteredChildBranches
    }))
}

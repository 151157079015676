import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  Pagination,
  PrimaryButton,
  SearchInput,
  SecurityScoreBadge,
  Table as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { getSecurityScore } from '@lastpass/admin-console-dependencies/services/seurity-score'
import {
  groupListActions,
  GroupListQueryParams
} from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { GroupsListState } from '@lastpass/admin-console-dependencies/state/users/groups/list/state'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { groupOperationsMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/GroupOperationsMapping'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

export enum scoreLimit {
  veryStrong = 79,
  strong = 59,
  average = 39,
  weak = 19
}

export const GroupsList: React.FunctionComponent = () => {
  const groupsListState: GroupsListState = useSelector(
    (state: AppState) => state.groupsList
  )
  const queryParams: GroupListQueryParams = useQueryParams(Namespace.groups)
  const updateQuery = useUpdateQuery(Namespace.groups)

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      groupListActions.getGroupsList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  const permissions = useContext(PermissionContext)
  const canModifyGroups = permissions.requirePermission(
    AdminPermissions.groupsModify
  )

  return (
    <>
      <RowContainer>
        <Heading300 data-qa={'GroupsPageHeader'}>
          <Trans>Groups</Trans>
        </Heading300>

        <div>
          {/* Not needed for GA
          <StyledDropdown button={HeaderButton} data-qa={'ExportGroupButton'}>
            <DropdownListItem>
              <Trans>Export groups</Trans>
            </DropdownListItem>
            <Divider />
            <DropdownListItem data-qa={'ExportOptionAll'}>
              <Trans>All</Trans>
            </DropdownListItem>
          </StyledDropdown>
          */}
          {canModifyGroups && (
            <LocationLink
              to={'/users/groups/new'}
              onClick={() =>
                dispatch(groupListActions.reportAddGroupButtonClicked())
              }
            >
              <PrimaryButton
                key={'new group'}
                data-qa={'GroupsPageCreateNewGroupButton'}
              >
                <Trans>New group</Trans>
              </PrimaryButton>
            </LocationLink>
          )}
        </div>
      </RowContainer>

      <RowContainer>
        <SearchInput
          onChange={event => {
            updateQuery({
              search: event.target.value || undefined,
              skip: undefined
            })
          }}
          value={queryParams.search || ''}
          placeholder={msg`Search groups...`}
          data-qa={'GroupsPageSearchGroupsInputField'}
        />
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={groupsListState.table.totalResults}
          resultsPerPage={groupsListState.table.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </RowContainer>

      <TableComponent
        qadata={'GroupsList'}
        table={groupsListState.table}
        checkable={
          canModifyGroups
            ? {
                addSelectedRecords: groups =>
                  dispatch(groupListActions.addSelectedGroups(groups)),
                removeSelectedRecords: groups =>
                  dispatch(groupListActions.removeSelectedGroups(groups)),
                setSelectedRecords: groups =>
                  dispatch(groupListActions.setSelectedGroups(groups))
              }
            : undefined
        }
        getRecordLink={(record: Group) => `${matchUrl}/${record.id}`}
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No groups created yet.</Trans>}
            text={<Trans>Manage your users in groups</Trans>}
            actionButton={
              canModifyGroups ? (
                <LocationLink
                  to={'/users/groups/new'}
                  onClick={() =>
                    dispatch(groupListActions.reportAddGroupButtonClicked())
                  }
                >
                  <TableViewButton data-qa={'GroupsTableCreateNewGroupButton'}>
                    <Trans>Create a group</Trans>
                  </TableViewButton>
                </LocationLink>
              ) : (
                <></>
              )
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined
                  })
                }}
                data-qa={'ClearSearchButton'}
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Group name</Trans>,
            renderer: (record: Group) => record.name
          },
          {
            name: <Trans>Security score</Trans>,
            renderer: function SharedFolderSecurityScoreRenderer(
              record: Group
            ) {
              return (
                <SecurityScoreBadge
                  level={getSecurityScore(record.securityScore)}
                />
              )
            }
          },
          {
            name: <Trans>Users</Trans>,
            renderer: (record: Group) => record.userCount.toString()
          }
        ]}
        actions={
          canModifyGroups && groupsListState.table.operations
            ? groupsListState.table.operations.map(
                userOperation =>
                  groupOperationsMapping(groupOperationActions, dispatch)[
                    userOperation
                  ]
              )
            : []
        }
        isFilterApplied={!!queryParams.search}
      />
    </>
  )
}

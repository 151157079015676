import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as GeneralReportsIcon } from '@lastpass/assets/svg/admin-console/icon-general-reports.svg'
import { ReactComponent as MFAActivityIcon } from '@lastpass/assets/svg/admin-console/icon-mfa-activity.svg'
import { ReactComponent as ShieldIcon } from '@lastpass/assets/svg/admin-console/icon-shield.svg'
import { ReactComponent as SSOLoginActivityIcon } from '@lastpass/assets/svg/admin-console/icon-sso-login-activity.svg'
import { ContentContainer, SidebarContainer } from '@lastpass/lastkit'
import { SideNavigation } from '@lastpass/lastkit/components/SideNavigation'
import { RoutedSideNavigationItem } from '@lastpass/routing'
import { getDefaultUrl, SideMenuItem } from '@lastpass/routing/get-default-url'

import { getIdentityIFrameUrl } from '@lastpass/admin-console-dependencies/services'
import { policyHelper } from '@lastpass/admin-console-dependencies/services/policy-helper'
import { SubscriptionType } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { PasswordlessContainerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { CheckPermission } from '@lastpass/admin-console-dependencies/ui/common/CheckPermission'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { LoginReportList } from '@lastpass/admin-console-dependencies/ui/reports/login/LoginReportList'

import { AdvancedGeneralReportsPage } from './AdvancedGeneralReportsPage'
import { AdvancedGeneralReportsPageOverlay } from './AdvancedGeneralReportsPageOverlay'
import { GeneralReportsPage } from './GeneralReportsPage'
import { SamlResponsesPage } from './saml/SamlResponsesPage'
import { SecurityReportPage } from './security/SecurityReportPage'

export const ReportsPage: FunctionComponent = () => {
  const permissions = useContext(PermissionContext)
  const isNewReporting = useFeatureFlags(FeatureFlags.isNewReporting)
  const isNativeSamlReportEnabled = useFeatureFlags(
    FeatureFlags.isNativeSamlReportEnabled
  )
  const match = useRouteMatch()
  const cidOverride = useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(passwordlessActions.fetchPasswordlessStatus())
  }, [dispatch])

  const passwordlessState = useSelector<AppState, PasswordlessContainerState>(
    state => state.passwordlessContainer
  )

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const isPasswordlessOn =
    passwordlessState?.RequirePasswordlessViaLastPassPolicy?.checked &&
    !policyHelper.isRequiredPolicyUnused(
      passwordlessState.RequirePasswordlessViaLastPassPolicy
    )

  const reportsPagesVisibility = {
    isGeneralReportsVisible: permissions.requirePermission(
      AdminPermissions.generalReportsView
    ),
    isReportingSsoLoginActivityView: permissions.requirePermission(
      AdminPermissions.reportingSsoLoginActivityView
    ),
    isReportingSamlResponseView: permissions.requirePermission(
      AdminPermissions.reportingSamlResponseView
    ),
    isReportingMfaUserActivityView: permissions.requirePermission(
      AdminPermissions.reportingMfaUserActivityView
    ),
    isReportingMfaAdminActivityView: permissions.requirePermission(
      AdminPermissions.reportingMfaAdminActivityView
    )
  }

  const getSideMenuItems = (
    matchUrl: string,
    matchPath: string
  ): SideMenuItem[] => {
    const sideMenuItems: SideMenuItem[] = []
    if (reportsPagesVisibility.isGeneralReportsVisible) {
      sideMenuItems.push({
        url: `${matchUrl}/generalReports${
          isNewReporting ? '' : '/userActivity'
        }`,
        route: { path: `${matchPath}/generalReports` },
        icon: GeneralReportsIcon,
        qaData: 'SideMenuGeneralReports',
        header: <Trans>General reports</Trans>,
        content: isNewReporting ? (
          <AdvancedGeneralReportsPage />
        ) : (
          <GeneralReportsPage />
        )
      })
      sideMenuItems.push({
        url: `${matchUrl}/loginReport`,
        route: {
          path: `${matchPath}/loginReport`
        },
        icon: SSOLoginActivityIcon,
        qaData: 'SideMenuSiteLoginActivity',
        header: <Trans>Site login activity</Trans>,
        content: <LoginReportList />
      })
      sideMenuItems.push({
        url: `${matchUrl}/securityReport`,
        route: { path: `${matchPath}/securityReport` },
        icon: SSOLoginActivityIcon,
        qaData: 'SideMenuSecurityReport',
        header: <Trans>Security reports</Trans>,
        content: <SecurityReportPage />
      })
    }
    if (
      !isNoPasswordDeprecated &&
      reportsPagesVisibility.isReportingSsoLoginActivityView
    ) {
      sideMenuItems.push({
        url: `${matchUrl}/apps`,
        route: { path: `${matchPath}/apps` },
        icon: SSOLoginActivityIcon,
        qaData: 'SideMenuSsoLoginActivity',
        header: <Trans>SSO login activity</Trans>,
        content: (
          <iframe
            css={css`
              width: 100%;
              height: 100%;
            `}
            src={getIdentityIFrameUrl(
              `${defaultConfig.identityIframeUrl}/Audit/Apps`,
              cidOverride
            )}
          />
        )
      })
    }
    if (
      !isNoPasswordDeprecated &&
      reportsPagesVisibility.isReportingSamlResponseView
    ) {
      sideMenuItems.push({
        url: `${matchUrl}/saml`,
        route: { path: `${matchPath}/saml` },
        icon: ShieldIcon,
        qaData: 'SideMenuSamlResponse',
        header: <Trans>SAML response</Trans>,
        content: isNativeSamlReportEnabled ? (
          <SamlResponsesPage />
        ) : (
          <iframe
            css={css`
              width: 100%;
              height: 100%;
            `}
            src={getIdentityIFrameUrl(
              `${defaultConfig.identityIframeUrl}/Audit/SAML`,
              cidOverride
            )}
          />
        )
      })
    }
    if (
      !isNoPasswordDeprecated &&
      reportsPagesVisibility.isReportingMfaUserActivityView &&
      (!isNpPasswordlessFeatureDisabled || isPasswordlessOn)
    ) {
      sideMenuItems.push({
        url: `${matchUrl}/users`,
        route: { path: `${matchPath}/users` },
        icon: MFAActivityIcon,
        qaData: 'SideMenuMfaUserActivity',
        header: <Trans>MFA user activity</Trans>,
        content: (
          <CheckPermission
            requiredSubscription={[
              SubscriptionType.mfa,
              SubscriptionType.iDaaS
            ]}
            showDialog={{
              redirectAfterClose: `${matchUrl}/generalReports${
                isNewReporting ? '' : '/userActivity'
              }`
            }}
            source={MfaUpsellDialogContext.userActivityReporting}
          >
            <iframe
              css={css`
                width: 100%;
                height: 100%;
              `}
              src={getIdentityIFrameUrl(
                `${defaultConfig.identityIframeUrl}/Audit/Users`,
                cidOverride
              )}
            />
          </CheckPermission>
        )
      })
    }
    if (
      !isNoPasswordDeprecated &&
      reportsPagesVisibility.isReportingMfaAdminActivityView &&
      (!isNpPasswordlessFeatureDisabled || isPasswordlessOn)
    ) {
      sideMenuItems.push({
        url: `${matchUrl}/admins`,
        route: { path: `${matchPath}/admins` },
        icon: MFAActivityIcon,
        qaData: 'SideMenuMfaAdminActivity',
        header: <Trans>MFA admin activity</Trans>,
        content: (
          <CheckPermission
            requiredSubscription={[
              SubscriptionType.mfa,
              SubscriptionType.iDaaS
            ]}
            showDialog={{
              redirectAfterClose: `${matchUrl}/generalReports${
                isNewReporting ? '' : '/userActivity'
              }`
            }}
            source={MfaUpsellDialogContext.adminActivityReporting}
          >
            <iframe
              css={css`
                width: 100%;
                height: 100%;
              `}
              src={getIdentityIFrameUrl(
                `${defaultConfig.identityIframeUrl}/Audit/Admins`,
                cidOverride
              )}
            />
          </CheckPermission>
        )
      })
    }

    return sideMenuItems
  }

  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const sideMenuItems = getSideMenuItems(matchUrl, matchPath)
    return (
      <>
        {isNewReporting && (
          <AdvancedGeneralReportsPageOverlay
            url={`${matchUrl}/generalReports`}
          />
        )}
        <SidebarContainer>
          <SideNavigation>
            {sideMenuItems.map(item => (
              <RoutedSideNavigationItem
                key={item.qaData}
                data-qa={item.qaData}
                className={item.className}
                to={item.url}
                route={item.route}
                icon={item.icon}
              >
                {item.header}
              </RoutedSideNavigationItem>
            ))}
          </SideNavigation>
        </SidebarContainer>
        <ContentContainer>
          <Switch>
            {sideMenuItems.map(item => (
              <Route key={item.qaData} {...item.route}>
                {item.content}
              </Route>
            ))}
            <Route>
              <Redirect to={getDefaultUrl(sideMenuItems)} />
            </Route>
          </Switch>
        </ContentContainer>
      </>
    )
  }
  return null
}

import React, { FC } from 'react'

import { useTheme } from '@emotion/react'
import { msg } from '@lingui/macro'

import { ActionLinkCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionLinkCard'
import { DonutChartWithLegend } from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/DonutChartWithLegend'

export interface LicenseConsumptionRateDonutChartCardProps {
  licensesConsumedCount: number
  availableLicensesCount: number
  licenseConsumptionRate: number
  dataQa: string
}

export const LicenseConsumptionRateDonutChartCard: FC<LicenseConsumptionRateDonutChartCardProps> = ({
  licensesConsumedCount,
  availableLicensesCount,
  licenseConsumptionRate,
  dataQa
}) => {
  const { colors } = useTheme()

  return (
    <ActionLinkCard
      dataQa={dataQa}
      learnMoreAction={() =>
        window.open(
          'https://support.lastpass.com/help/what-are-the-enrollment-and-utilization-rates',
          '_blank',
          'noopener, noreferrer'
        )
      }
    >
      <DonutChartWithLegend
        totalText={msg`Total licenses`}
        items={[
          {
            value: licensesConsumedCount,
            label: msg`Consumed`,
            backgroundColor: colors.blue700
          },
          {
            value: availableLicensesCount,
            label: msg`Available for new users`,
            backgroundColor: colors.blue100
          }
        ]}
        percentageInfo={{
          value: licenseConsumptionRate
        }}
        dataQa={dataQa && dataQa + 'DonutChart'}
      />
    </ActionLinkCard>
  )
}

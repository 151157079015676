import { DataFetchParams } from '@lastpass/routing'
import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'

import { UserOperationsType } from '../operations/user-operations'

export enum UserListSegmentActionTypes {
  ADD_USER_BUTTON_CLICKED = '[user-operation-segment] add-user-button-clicked'
}

export const StatusTypeMapper = (
  status: AccountStatus
): SegmentPropertyType<'Export Users Clicked', 'Account Status'> => {
  const statusMap = {
    active: 'Active',
    na: 'All',
    awaitingApproval: 'Awaiting Aproval',
    disabled: 'Disabled',
    invited: 'Invited',
    staged: 'Staged',
    expiredInvitation: 'ExpiredInvitation'
  }
  return statusMap[status]
}

export const userListSegmentActions = {
  addUserButtonClicked: () =>
    track(
      createAction(UserListSegmentActionTypes.ADD_USER_BUTTON_CLICKED, {}),
      {
        segment: uacEvent('Add Users Button Clicked', {
          Source: 'Users Overview'
        })
      }
    )
}

export enum UserListActionTypes {
  SET_USER_LIST = '[user-list] set',
  GET_USER_LIST = '[user-list] get',
  ADD_USERS = '[user-list] add-users',
  ADD_SELECTED_USERS = '[user-list] add-selected-users',
  REMOVE_SELECTED_USERS = '[user-list] remove-selected-users',
  SET_SELECTED_USERS = '[user-list] set-selected-users',
  SET_USERS_LOADING = '[user-list] set-table-loading',
  SET_USER_OPERATIONS = '[user-list] set-user-operations',
  EXPORT_USERS = '[user-list] export-users',
  SET_IS_EXPORT_USERS_LOADING = '[user-list] set-is-export-users-loading',
  SET_COMPANY_USER_COUNT = '[user-list] set-company-user-count',
  GET_USER_LIST_COLUMNS = '[user-list] get-user-list-columns',
  SET_USER_LIST_COLUMNS = '[user-list] set-user-list-columns',
  RESET_USER_LIST_COLUMNS = '[user-list] reset-user-list-columns',
  SET_SELECT_ALL_USERS = '[user-list] set-select-all-users',
  GET_CURRENT_ADMIN_LEVEL = '[user-list] get-current-admin-level',
  SET_CURRENT_ADMIN_LEVEL = '[user-list] set-current-admin-level',
  GET_IS_TRANSFER_VAULT_HIDDEN = '[user-list] get-is-transfer-vault-hidden',
  SET_IS_TRANSFER_VAULT_HIDDEN = '[user-list] set-is-transfer-vault-hidden'
}

export interface UserListQueryParams {
  search: string
  skip: number
  filter: UserFilter
  orderBy: string
}

export const userListActions = {
  setUserList: (list: User[], totalResults: number, resultsPerPage: number) =>
    createAction(UserListActionTypes.SET_USER_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  getUserList: (
    params: DataFetchParams<UserListQueryParams>,
    resultsPerPage = 25
  ) =>
    track(
      createAction(UserListActionTypes.GET_USER_LIST, {
        params,
        resultsPerPage
      }),
      {
        segment: uacEvent('Users Overview Viewed', {
          Source: 'Users Overview',
          'User Count': resultsPerPage
        })
      }
    ),
  addSelectedUsers: (users: User[]) =>
    createAction(UserListActionTypes.ADD_SELECTED_USERS, { users }),
  removeSelectedUsers: (users: User[]) =>
    createAction(UserListActionTypes.REMOVE_SELECTED_USERS, { users }),
  setSelectedUsers: (users: User[]) =>
    createAction(UserListActionTypes.SET_SELECTED_USERS, { users }),
  setSelectAllUsers: (allUsersSelected: boolean) =>
    createAction(UserListActionTypes.SET_SELECT_ALL_USERS, {
      allUsersSelected
    }),
  addUsers: (users: User[]) =>
    track(createAction(UserListActionTypes.ADD_USERS, { users }), {
      segment: uacEvent('Add Users Button Clicked', {
        Source: 'Users Overview'
      })
    }),
  setUsersLoading: (loading: boolean) =>
    createAction(UserListActionTypes.SET_USERS_LOADING, { loading }),
  setUserOperations: (userOperations: UserOperationsType[]) =>
    createAction(UserListActionTypes.SET_USER_OPERATIONS, { userOperations }),
  exportUsers: (
    userFilter: UserFilter,
    isLastLoginIncluded = false,
    isAccountStatusRenamed = false,
    isCreatedByCompanyColumnEnabled = false
  ) =>
    track(
      createAction(UserListActionTypes.EXPORT_USERS, {
        userFilter,
        isLastLoginIncluded,
        isAccountStatusRenamed,
        isCreatedByCompanyColumnEnabled
      }),
      {
        segment: uacEvent('Export Users Clicked', {
          Source: 'Users Overview'
        })
      }
    ),
  setIsExportUsersLoading: (isLoading: boolean) =>
    createAction(UserListActionTypes.SET_IS_EXPORT_USERS_LOADING, isLoading),
  reportAddUserButtonClicked: () =>
    userListSegmentActions.addUserButtonClicked(),
  setCompanyUserCount: (count: number) =>
    createAction(UserListActionTypes.SET_COMPANY_USER_COUNT, count),
  getUserListColumns: () =>
    createAction(UserListActionTypes.GET_USER_LIST_COLUMNS),
  setUserListColumns: (
    columns: UserListTableColumn[],
    saveSettings?: boolean
  ) =>
    createAction(UserListActionTypes.SET_USER_LIST_COLUMNS, {
      columns,
      saveSettings
    }),
  resetUserListColumns: () =>
    createAction(UserListActionTypes.RESET_USER_LIST_COLUMNS),
  getCurrentAdminLevel: () =>
    createAction(UserListActionTypes.GET_CURRENT_ADMIN_LEVEL),
  setCurrentAdminLevel: (currentAdminLevel: string) =>
    createAction(
      UserListActionTypes.SET_CURRENT_ADMIN_LEVEL,
      currentAdminLevel
    ),
  getIsTransferVaultHidden: () =>
    createAction(UserListActionTypes.GET_IS_TRANSFER_VAULT_HIDDEN),
  setIsTransferVaultHidden: (isTransferVaultHidden: boolean) =>
    createAction(
      UserListActionTypes.SET_IS_TRANSFER_VAULT_HIDDEN,
      isTransferVaultHidden
    )
}

export type UserListActions = ActionsUnion<typeof userListActions>

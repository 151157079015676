import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SharedFolderDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'
import { SharedFolderListActionTypes } from '@lastpass/admin-console-dependencies/state/shared-folders/list/actions'

import { createGetSharedFolderScores } from './get-shared-folder-scores'
import { createGetSharedFolderSites } from './get-shared-folder-sites'
import { createGetSharedFolderUsers } from './get-shared-folder-users'
import { createGetSharedFolders } from './get-shared-folders'
import { createNewSharedFolderSaga } from './new-shared-folder'
import { createSharedFolderAddSitesSaga } from './shared-folder-add-sites'
import { createSharedFolderAddUsersSaga } from './shared-folder-add-users'

export function createSharedFolderSaga(userService: UACServices.Services) {
  const getSharedFoldersSaga = createGetSharedFolders(userService)
  const newSharedFolderSaga = createNewSharedFolderSaga()
  const sharedFolderAddSitesSaga = createSharedFolderAddSitesSaga()
  const sharedFolderAddUsersSaga = createSharedFolderAddUsersSaga()
  const sharedFoldersUsersSaga = createGetSharedFolderUsers(userService)
  const sharedFolderSitesSaga = createGetSharedFolderSites(userService)
  const sharedFolderScoreSaga = createGetSharedFolderScores(userService)

  return function*() {
    yield takeEvery(
      SharedFolderListActionTypes.GET_SHARED_FOLDER_LIST,
      getSharedFoldersSaga
    )
    yield takeEvery(
      SharedFolderListActionTypes.NEW_SHARED_FOLDER,
      newSharedFolderSaga
    )
    yield takeEvery(
      SharedFolderDrawerActionTypes.SHARED_FOLDER_ADD_SITES,
      sharedFolderAddSitesSaga
    )
    yield takeEvery(
      SharedFolderDrawerActionTypes.SHARED_FOLDER_ADD_USERS,
      sharedFolderAddUsersSaga
    )
    yield takeEvery(
      SharedFolderDrawerActionTypes.GET_SHARED_FOLDER_USERS_LIST,
      sharedFoldersUsersSaga
    )
    yield takeEvery(
      SharedFolderDrawerActionTypes.GET_SHARED_FOLDER_SITES_LIST,
      sharedFolderSitesSaga
    )
    yield takeEvery(
      SharedFolderDrawerActionTypes.GET_SHARED_FOLDER_SCORE,
      sharedFolderScoreSaga
    )
  }
}

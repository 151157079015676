export enum FederationErrors {
  IdpGetUsersRequestFailed = 'F.IDP.E01',
  IdpGetUsersRequestConnectionFailed = 'F.IDP.E01.CE',
  IdpGetUsersRequestAccessDenied = 'F.IDP.E01.AD',
  IdpBatchUpdateUsersFailed = 'F.IDP.E02',
  IdpBatchUpdateUsersConnectionFailed = 'F.IDP.E02.CE',
  IdpBatchUpdateUsersBatchLimitExceeded = 'F.IDP.E02.BL',
  IdpBatchUpdateUsersAccessDenied = 'F.IDP.E02.AD',
  IdpUpdateUserFailed = 'F.IDP.E03',
  IdpUpdateUserConnectionFailed = 'F.IDP.E03.CE',
  IdpUpdateUserAccessDenied = 'F.IDP.E03.AD',

  MissingCompanyOrUserWideIdpData = 'F.ROT.E01',
  FragmentIdValidationFailed = 'F.ROT.E02',
  KeyRotationMpMissmatchError = 'F.ROT.E03'
}

export const FederationErrorsDescriptions: Record<FederationErrors, string> = {
  [FederationErrors.IdpGetUsersRequestFailed]: 'Idp get users request failed.',
  [FederationErrors.IdpGetUsersRequestConnectionFailed]:
    'Idp get users request connection failed.',
  [FederationErrors.IdpGetUsersRequestAccessDenied]:
    'Idp get users request failed due to access denied.',
  [FederationErrors.IdpBatchUpdateUsersFailed]:
    'Idp batch update users K1 request failed.',
  [FederationErrors.IdpBatchUpdateUsersConnectionFailed]:
    'Idp batch update users K1 request connection failed.',
  [FederationErrors.IdpBatchUpdateUsersBatchLimitExceeded]:
    'Maximum batch limit is exceeded in idp batch update users K1.',
  [FederationErrors.IdpBatchUpdateUsersAccessDenied]:
    'Idp batch update users request failed due to access denied.',
  [FederationErrors.IdpUpdateUserFailed]: 'Idp update user k1 request failed.',
  [FederationErrors.IdpUpdateUserConnectionFailed]:
    'Idp update user k1 request connection failed.',
  [FederationErrors.IdpUpdateUserAccessDenied]:
    'Idp update user request failed due to access denied.',

  [FederationErrors.MissingCompanyOrUserWideIdpData]:
    'Missing company or user-wide IDP data.',
  [FederationErrors.FragmentIdValidationFailed]:
    'Fragment-id validation failed.',
  [FederationErrors.KeyRotationMpMissmatchError]:
    'Old and new master passwords do not match.'
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as AppsIllustration } from '@lastpass/assets/svg/admin-console/applications/apps-illustration.svg'
import { Loading } from '@lastpass/components/Loading'
import { BodyRegular, Heading300, PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import {
  createApplication,
  SelectedApplication
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import {
  applicationEmptyListActions,
  applicationEmptyListSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/emptylist/actions'
import { ApplicationEmptyListState } from '@lastpass/admin-console-dependencies/state/applications/saml/emptylist/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHeading = styled(Heading300)`
  margin-top: 32px;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
`

const StyledBodyRegular = styled(BodyRegular)`
  display: flex;
  justify-content: center;
`

const StyledLocationLink = styled.a`
  margin-top: 32px;
`

const AppTiles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 814px;
  height: 100px;

  margin-bottom: 50px;
`
const AppTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 150px;
  height: 100px;

  border: 1px solid #bac0ca;
  box-shadow: 0px 0px 2px rgba(29, 48, 73, 0.16),
    0px 2px 2px rgba(29, 48, 73, 0.04), 0px 1px 2px rgba(29, 48, 73, 0.04);
  border-radius: 4px;

  margin-top: 24px;
`
export const StyledAppIcon = styled.img`
  float: left;
  width: 48px;
  height: 48px;
  object-fit: contain;
`

export interface CompositeApplication {
  displayName: string
  application: SelectedApplication
  qaAttributeValue: string
  toLink: string
}

export const ApplicationEmptyListComponent: React.FunctionComponent = () => {
  const state = useSelector<AppState, ApplicationEmptyListState>(
    state => state.applicationEmptyList
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (!state.availableApplications.length) {
      dispatch(applicationEmptyListActions.getAvailableApplications())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAlternateSsoAppConfigurationEnabled = useFeatureFlags(
    FeatureFlags.isAlternateSsoAppConfigurationEnabled
  )

  const availableApplications = state ? state.availableApplications : []

  const highlightedApplicationNames = [
    {
      name: 'Slack',
      displayName: 'Slack',
      qaAttributeValue: 'SlackTile',
      lpName: 'slack'
    },
    {
      name: 'Zoom',
      displayName: 'Zoom',
      qaAttributeValue: 'ZoomTile',
      lpName: 'zoom'
    },
    {
      name: 'Salesforce',
      displayName: 'Salesforce',
      qaAttributeValue: 'SalesforceTile',
      lpName: 'salesforce'
    },
    {
      name: 'BambooHR',
      displayName: 'Bamboo HR',
      qaAttributeValue: 'BambooHrTile',
      lpName: 'bamboohr'
    },
    {
      name: 'Amazon Web Service',
      displayName: 'AWS',
      qaAttributeValue: 'AwsTile',
      lpName: 'aws'
    }
  ]

  const highlightedApplications: CompositeApplication[] = []

  highlightedApplicationNames.forEach(appName => {
    const app = availableApplications.find(app => app.text === appName.name)
    const application = app
      ? createApplication(
          false,
          app.text,
          app.id,
          app.defaultPartnerName,
          app.defaultIdentityProvider,
          app.signAssertion,
          app.wantAuthnRequestSigned,
          app.signSAMLResponse,
          app.encryptAssertion,
          app.logo.imageUrl
        )
      : createApplication(false)
    let toLink = '/applications/saml/add'
    if (isAlternateSsoAppConfigurationEnabled) {
      //append navigation for lastpass php apps
      toLink = `${toLink}#${appName.lpName}`
    }
    highlightedApplications.push({
      displayName: appName.displayName,
      application: application,
      qaAttributeValue: appName.qaAttributeValue,
      toLink: toLink
    })
  })

  return (
    <StyledContainer>
      {state && state.isLoading && <Loading color="blue900" active={true} />}
      <AppsIllustration />
      <StyledHeading>
        <Trans>Make LastPass your single sign-on identity provider</Trans>
      </StyledHeading>
      <StyledTextContainer>
        <StyledBodyRegular>
          <Trans>
            Fewer passwords, less risk. Add SSO capable apps and let your users
            log into them using their LastPass account.
          </Trans>
        </StyledBodyRegular>
      </StyledTextContainer>
      <AppTiles data-qa={'HighlightedAppsContainer'}>
        {highlightedApplications.map(app => {
          return (
            app.application.name.length && (
              <LocationLink
                key={app.application.name}
                to={app.toLink}
                data-qa={app.qaAttributeValue}
                onClick={() => {
                  dispatch(
                    applicationsDrawerActions.setStep(
                      ApplicationDrawerSteps.configure,
                      app.application
                    )
                  )
                  dispatch(
                    applicationEmptyListSegmentActions.highlightedSsoAppClicked(
                      app.application
                    )
                  )
                }}
              >
                <AppTile>
                  <StyledAppIcon src={app.application.customLogoUrl} />
                  <StyledBodyRegular>
                    <Trans>Add {app.displayName}</Trans>
                  </StyledBodyRegular>
                </AppTile>
              </LocationLink>
            )
          )
        })}
      </AppTiles>
      <LocationLink
        to={'/applications/saml/add'}
        onClick={applicationEmptyListActions.addNewApp}
      >
        <StyledPrimaryButton data-qa={'ApplicationsSamlAddApplicationButton'}>
          <Trans>Search the catalog</Trans>
        </StyledPrimaryButton>
      </LocationLink>
      <StyledLocationLink
        href="https://link.lastpass.com/sso-apps-admin"
        data-qa={'LearnMoreAboutSSOLink'}
        target="_blank"
      >
        <Trans>Learn more about single sign-on</Trans>
      </StyledLocationLink>
    </StyledContainer>
  )
}

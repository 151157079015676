import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { BodyBold, BodyRegular, BodyRegularStyle } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  ConfirmDialog,
  IterationCountDestroySessionsDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'

import { ConfirmDialogComponent } from './ConfirmDialog'

interface IterationCountDestroySessionsDialogComponentProps {
  state: IterationCountDestroySessionsDialog
  actions: typeof globalActions
}

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

const Content: FC = () => (
  <>
    <StyledParagraph>
      <Trans>
        <BodyBold>Tip</BodyBold>: To apply the hash iteration policy as soon as
        possible, destroy all sessions for all selected users on all devices.
      </Trans>
    </StyledParagraph>
    <BodyRegular>
      <Trans>
        The policy will be applied to each user when they log back in to
        LastPass.
      </Trans>
    </BodyRegular>
  </>
)

export const IterationCountDestroySessionsDialogComponent: FC<IterationCountDestroySessionsDialogComponentProps> = ({
  state,
  actions
}) => {
  const type = 'confirmdialog' as const
  const dialogState: ConfirmDialog = {
    ...state,
    type,
    title: msg`Destroy all sessions now?`,
    discardText: msg`Don’t destroy sessions`,
    confirmText: msg`Destroy all sessions`,
    content: <Content />
  }

  return <ConfirmDialogComponent state={dialogState} actions={actions} />
}

import Analytics from 'analytics-node'

import { AdminConsole, Plan } from '@lastpass/tracking/tracking-plans/uac'

import { Observable } from '@lastpass/admin-console-dependencies/types/observe-store'
import { Platform } from '@lastpass/admin-console-dependencies/types/platform'
import { UserType } from '@lastpass/admin-console-dependencies/types/user-type'

import { LMIAPI } from '../lmiapi'
import { createCustomBatchedTrackForSafariExtension } from './custom-batch'
import { SegmentService } from './types'

export interface SegmentEventMetadata {
  /**
   * Defines the platform where the event ocurred
   */
  platform: Platform
  version: string
}

interface SegmentUser {
  userId: string
  /**
   *  https://segment.com/docs/connections/spec/identify/#traits
   */
  traits?: {
    'Account Type'?: UserType
    'Enterprise Version'?: string
    'Admin Console'?: AdminConsole
  }
}

export interface EventFilter {
  events: (keyof Plan)[]
  enabled: () => boolean
}

/**
 * Create a segment service to track events for the logged in user
 *
 * @param segmentBaseUrl base url of the reverse proxy's segment endpoint
 */
export function createSegmentClientService(
  $baseUrl: Observable<string | undefined>,
  $user: Observable<SegmentUser | undefined>,
  eventMetadata: Partial<SegmentEventMetadata> = {},
  eventFilters: EventFilter[] = [],
  lmiapi?: LMIAPI
): SegmentService {
  /**
   * We use a dummy write key and replace the header on
   * an NGINX reverse proxy server side
   */
  let client
  $baseUrl.subscribe(host => {
    client = new Analytics('SEGMENT_WRITE_KEY', {
      host
    })
  })

  let currentUser: SegmentUser | undefined

  $user.subscribe(nextUser => {
    currentUser = nextUser
  })

  const getUserId = () => {
    return currentUser && currentUser.userId
  }

  const isEnabled = (event: string) => {
    const eventFilter = eventFilters.find(filter =>
      filter.events.includes(event as keyof Plan)
    )
    if (eventFilter) {
      return eventFilter.enabled()
    }
    return true
  }

  const customBatchedTrackForSafariExtension = createCustomBatchedTrackForSafariExtension(
    lmiapi,
    10000,
    10
  )

  return (event, properties) => {
    const userId = getUserId()
    if (!userId) {
      // TODO handle anonymousId
      return
    }

    if (isEnabled(event)) {
      const method: Platform | undefined = eventMetadata.platform

      const payload = {
        userId,
        event,
        properties: {
          ...properties,
          method,
          lpversion: eventMetadata.version,
          ...(currentUser && currentUser.traits)
        },
        context: {
          /**
           * We can easily add more metadata here if needed but this is
           * what the base analytics-js library provides.
           *
           * https://segment.com/docs/connections/spec/common/#context
           */
          page: {
            path: location.pathname,
            search: location.search,
            title: document.title,
            url: location.href,
            referrer: document.referrer
          },
          userAgent: navigator.userAgent
        }
      }

      if (method !== Platform.SAFARI) {
        client.track(payload)
      } else {
        customBatchedTrackForSafariExtension(payload)
      }
    }
  }
}

export const accountTypeMapper = (
  isTrial: boolean,
  subscriptionEndDate: string
): UserType => {
  const now = new Date().getTime()
  const endDate = new Date(subscriptionEndDate).getTime()

  if (!isTrial && now < endDate) {
    return UserType.ENTERPRISE
  }

  if (!isTrial && now > endDate) {
    return UserType.ENTERPRISE_EXPIRED
  }

  if (isTrial && now < endDate) {
    return UserType.ENTERPRISE_TRIAL
  }

  if (isTrial && now > endDate) {
    return UserType.ENTERPRISE_TRIAL_EXPIRED
  }

  return UserType.ENTERPRISE
}

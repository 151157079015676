import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import MsiInstaller from '@lastpass/assets/png/admin-console/MsiInstaller.png'
import { Heading200 } from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

const TextContent = styled.div`
  ${BodyRegularStyle}
`

const PageContentContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 48px;
  margin-bottom: 18px;
  max-width: 760px;
`

const PageItemContainer = styled(Heading200)`
  margin-top: 40px;
  margin-bottom: 25px;
  padding: 0 24px;
`

const StyledTextContent = styled(TextContent)`
  margin-bottom: 24px;
`

export const WindowsManualPageContainer: React.FunctionComponent = () => {
  return (
    <>
      <PageItemContainer data-qa={'PageItemContainerWindowsGuiInstall'}>
        <Trans>Instructions - Microsoft Windows manual GUI install</Trans>
      </PageItemContainer>
      <PageContentContainer>
        <StyledTextContent>
          <li>
            <Trans>Download and run the Windows LastPass Installer.</Trans>
          </li>
          <li>
            <Trans>
              Users must have Administrative access rights. The installer may
              prompt users for their Administrator{"'"}s credentials.
            </Trans>
          </li>
          <li>
            <Trans>
              See more details in the{' '}
              <a href={'http://link.lastpass.com/help-msi-installer-guide'}>
                <Trans>User Guide</Trans>
              </a>
              .
            </Trans>
          </li>
        </StyledTextContent>
        <img src={MsiInstaller} />
      </PageContentContainer>
    </>
  )
}

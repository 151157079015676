import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { BodyBold, DetailsDialog } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'
import { useBrowserSafeguard } from '@lastpass/ui/hooks'

import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { KeyRotationProgressDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
`
const StyledText = styled.div`
  margin-bottom: 20px;
`
const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 30px;
`
const EstimationTextBoxContainer = styled.div`
  ${BodyRegularStyle};
  text-align: center;
`
const StyledEstimationText = styled.div`
  ${BodyRegularStyle};
`

interface KeyRotationProgressDialogProps {
  state: KeyRotationProgressDialog
}

export const KeyRotationProgressDialogComponent: FunctionComponent<KeyRotationProgressDialogProps> = () => {
  const { keyRotationEstimation } = useSelector<AppState, FederatedLoginState>(
    appState => appState.federatedLogin
  )

  useBrowserSafeguard()

  const generateEstimationText = (
    percentage: number,
    remainingTime: number | null
  ) => {
    if (!remainingTime) {
      return msg`Estimating time remaining`
    }
    if (remainingTime < 60) {
      return msg`${percentage}% complete. Estimated time remaining: Less than a minute`
    }
    const roundedMinutes = Math.round(remainingTime / 60)
    if (roundedMinutes === 1) {
      return msg`${percentage}% complete. Estimated time remaining: 1 minute`
    }
    if (roundedMinutes < 60) {
      return msg`${percentage}% complete. Estimated time remaining: ${roundedMinutes} minutes`
    }
    const roundedToHalfAnHour = Math.round((remainingTime / 3600) * 2) / 2
    if (roundedToHalfAnHour === 1) {
      return msg`${percentage}% complete. Estimated time remaining: About 1 hour`
    }
    return msg`${percentage}% complete. Estimated time remaining: About ${roundedToHalfAnHour} hours`
  }

  return (
    <DetailsDialog
      closeDialog={() => {}}
      width="600px"
      title={msg`Keep this window open`}
      hideCloseIcon
      disableCloseOnBackground
      content={
        <>
          <TextBoxContainer>
            <StyledText>
              <Trans>
                Don’t close this window. This could take a few minutes depending
                on how many users are in your organization.
              </Trans>
            </StyledText>
            <StyledText>
              <Trans>
                <BodyBold>Users are not impacted</BodyBold> during this process.
              </Trans>
            </StyledText>
          </TextBoxContainer>
          <StyledLoadingContainer>
            <Loading color="blue900" active={true} />
          </StyledLoadingContainer>
        </>
      }
      footer={
        <EstimationTextBoxContainer>
          <StyledEstimationText>
            <Trans>Generating new knowledge components...</Trans>
          </StyledEstimationText>
          <StyledEstimationText>
            {i18n._(
              generateEstimationText(
                keyRotationEstimation.percentage,
                keyRotationEstimation.remainingTime
              )
            )}
          </StyledEstimationText>
        </EstimationTextBoxContainer>
      }
    />
  )
}

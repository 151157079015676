import * as cbc from './aes/cbc'
import * as ecb from './aes/ecb'

export type VaultEncryptionFormat = 'base64' | 'binary'

export function isCbc(data: string): boolean {
  return data[0] === '!'
}

function parseCBC(encrypted: string, format: VaultEncryptionFormat) {
  switch (format) {
    case 'base64': {
      const encryptedPieces = encrypted.split('|', 2)
      return {
        iv: Buffer.from(encryptedPieces[0].substring(1), 'base64'),
        value: Buffer.from(encryptedPieces[1], 'base64')
      }
    }
    case 'binary':
      return {
        iv: Buffer.from(encrypted.substring(1, 17), 'binary'),
        value: Buffer.from(encrypted.substring(17), 'binary')
      }
  }
  throw Error('Unsupported BufferEncoding type: ' + format)
}

export async function decrypt(
  encrypted: string,
  key: Buffer,
  format: VaultEncryptionFormat
): Promise<string> {
  if (encrypted) {
    try {
      if (isCbc(encrypted)) {
        const decrypted = await cbc.decrypt(parseCBC(encrypted, format), key)
        return decrypted.toString('utf-8')
      }
      return await ecb.decrypt(Buffer.from(encrypted, format), key)
    } catch (e) {
      return ''
    }
  }
  return encrypted
}

export async function encrypt(
  value: string,
  key: Buffer,
  format: VaultEncryptionFormat
): Promise<string> {
  if (value) {
    const encrypted = await cbc.encrypt(Buffer.from(value, 'utf-8'), key)

    switch (format) {
      case 'base64':
        return `!${encrypted.iv.toString('base64')}|${encrypted.value.toString(
          'base64'
        )}`
      case 'binary':
        return `!${encrypted.iv.toString('binary')}${encrypted.value.toString(
          'binary'
        )}`
      default:
        throw Error('Unsupported BufferEncoding type: ' + format)
    }
  }
  return value
}

function cbcEncode(data: string): string {
  return (
    '!' +
    Buffer.from(data.substring(1, 17), 'binary').toString('base64') +
    '|' +
    Buffer.from(data.substring(17), 'binary').toString('base64')
  )
}

function ecbEncode(data: string): string {
  return Buffer.from(data, 'binary').toString('base64')
}

export function base64Encode(data: string): string {
  return isCbc(data) ? cbcEncode(data) : ecbEncode(data)
}

function isPrivateKeyValid(key: string) {
  return (
    key.indexOf('LastPassPrivateKey<') === 0 &&
    key.indexOf('>LastPassPrivateKey') > 0
  )
}

export async function decryptPrivateKey(encrypted: string, key: Buffer) {
  try {
    if (isCbc(encrypted)) {
      const decrypted = await decrypt(encrypted, key, 'binary')
      if (isPrivateKeyValid(decrypted)) {
        return decrypted.substring(19, decrypted.length - 19)
      }
    } else {
      const decrypted = (
        await cbc.decrypt(
          { iv: key.subarray(0, 16), value: Buffer.from(encrypted, 'hex') },
          key
        )
      ).toString()
      if (isPrivateKeyValid(decrypted)) {
        return decrypted.substring(19, decrypted.length - 19)
      }
    }
  } catch {
    // Returned below
  }
  return ''
}

import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import { Dropdown, DropdownListItem, IconButton } from '@lastpass/lastkit'

import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'
import { adminOperationActions } from '@lastpass/admin-console-dependencies/state/users/admin/operations/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

interface Operation {
  title: React.ReactElement
  isRed: boolean
  callback: () => void
  dataQa: string
}

const StyledIconButton = styled(IconButton)`
  margin-left: 16px;
`

const MoreButton = props => (
  <StyledIconButton
    icon={More}
    data-qa="AdminOperationsDropdownMoreButton"
    {...props}
  />
)

export const AdminOperationsDropdown: React.FunctionComponent = () => {
  const adminLevelDrawerState: AdminLevelDrawerState = useSelector(
    (state: AppState) => state.adminLevelDrawer
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const handleDeleteAdminClick = adminLevelDrawerState =>
    dispatch(
      adminOperationActions.deleteAdmins([adminLevelDrawerState.adminLevel])
    )
  const permissions = useContext(PermissionContext)
  const canModifyAdminLevels = permissions.requirePermission(
    AdminPermissions.adminsModify
  )

  const baseOperations: Operation[] = [
    {
      title: <Trans>Edit admin level details</Trans>,
      isRed: false,
      callback: () => history.push(`${history.location.pathname}/edit`),
      dataQa: 'EditAdminLevelButton'
    },
    {
      title: <Trans>Delete admin level</Trans>,
      isRed: true,
      callback: () => handleDeleteAdminClick(adminLevelDrawerState),
      dataQa: 'DeleteAdminLevelButton'
    }
  ]

  const operations =
    isRoleBasedAccessControlEnabled &&
    (adminLevelDrawerState.adminLevel.mspCidOverrideAdminLevel === 'admin' ||
      adminLevelDrawerState.adminLevel.mspCidOverridePermissions?.length)
      ? [
          baseOperations[0],
          {
            title: <Trans>Edit managed admin level details</Trans>,
            isRed: false,
            callback: () =>
              history.push(
                `${history.location.pathname}/managed-admin-permissions`
              ),
            dataQa: 'EditManagedPermissionsButton'
          },
          baseOperations[1]
        ]
      : baseOperations

  return canModifyAdminLevels ? (
    <Dropdown button={MoreButton}>
      {operations.map((operation, index) => (
        <DropdownListItem
          key={index}
          red={operation.isRed}
          onClick={operation.callback}
          data-qa={operation.dataQa}
        >
          {operation.title}
        </DropdownListItem>
      ))}
    </Dropdown>
  ) : (
    <Dropdown button={MoreButton}>
      <DropdownListItem key="noAction" noClick={true}>
        <Trans>No actions available</Trans>
      </DropdownListItem>
    </Dropdown>
  )
}

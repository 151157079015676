import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export function create(fetch: AuthFetch) {
  return async function service() {
    const [, status] = await fetch('users/inactive/reminders', 'POST')

    if (status === StatusCodes.OK) {
      return success()
    }
    throw new UnknownServiceError()
  }
}

import React, { ReactElement } from 'react'

import { Collapsible } from '@lastpass/lastkit/components'

export interface PolicyGroupCardProps {
  expand: boolean
  onExpand: Function
  title: ReactElement
}

export const PolicyGroupCard: React.FunctionComponent<PolicyGroupCardProps> = props => {
  return (
    <Collapsible
      title={props.title}
      expanded={props.expand}
      onExpand={props.onExpand}
    >
      {props.children}
    </Collapsible>
  )
}

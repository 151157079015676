import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AppsIllustration } from '@lastpass/assets/svg/admin-console/applications/workstation-login-illustration.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  PrimaryButton,
  SearchInput,
  Table as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { legacyVpnListActions } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/actions'
import { LegacyVpnListState } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/state'
import {
  mfaApplicationListActions,
  MfaApplicationListQueryParams
} from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'
import { MfaApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/state'
import { MfaApplication } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdvancedMfaMessage } from '@lastpass/admin-console-dependencies/ui/global/AdvancedMfaMessage'

import { MfaAppIcon } from './MfaAppIcon'
import { MfaAppsEmptyList } from './MfaAppsEmptyList'

type MfaApplicationListProps = LastPassComponentProps<
  {
    mfaApplicationState: MfaApplicationListState
    legacyVpnState: LegacyVpnListState
  },
  typeof mfaApplicationListActions &
    typeof legacyVpnListActions &
    typeof globalActions,
  {}
>

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const AlignedContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`

const VerticalAlignedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: middle;
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 4px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
  justify-content: middle;
`

const StyledContainer = styled.div`
  display: flex;
`

const StyledNoAppIcon = styled(AppsIllustration)`
  width: 180px;
  height: 180px;
`

const StyledAppIcon = styled.div`
  max-width: 32px;
  margin-right: 16px;
  display: flex;
  float: left;
`

export const MfaAppListComponent: React.FunctionComponent<MfaApplicationListProps> = ({
  state,
  actions
}) => {
  const queryParams: MfaApplicationListQueryParams = useQueryParams(
    Namespace.apps
  )
  const updateQuery = useUpdateQuery(Namespace.apps)

  const dispatch = useDispatch()

  const match = useRouteMatch()
  const matchUrl = match && match.url

  useEffect(() => {
    dispatch(
      mfaApplicationListActions.getMfaApplicationList({
        query: queryParams,
        path: {}
      })
    )
    dispatch(legacyVpnListActions.getLegacyVpnList())
  }, [dispatch, queryParams])

  const isFilterApplied = !!queryParams.search
  const isTableEmpty =
    !state.mfaApplicationState.table.results.length && !isFilterApplied

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)
  const advancedMfaMessage = userHasMfaAddon ? (
    <AdvancedMfaMessage
      isEmpty={state.mfaApplicationState.table.totalResults === 0}
      nonMspMessage={msg`This feature is available with the Advanced MFA add-on.`}
    />
  ) : null

  const header = isTableEmpty ? null : (
    <>
      <RowContainer>
        <Heading300 data-qa="MfaAppsPageHeader">
          <Trans>MFA apps</Trans>
        </Heading300>
        <AlignedContainer>
          <VerticalAlignedContainer>
            {advancedMfaMessage}
          </VerticalAlignedContainer>
          <LocationLink
            to="/applications/mfa/add"
            onClick={() =>
              actions.addNewApp(state.mfaApplicationState.table.totalResults)
            }
          >
            <StyledPrimaryButton
              key="add mfa app"
              data-qa="MfaAppsPageAddMfaAppButton"
            >
              <Trans>Add app</Trans>
            </StyledPrimaryButton>
          </LocationLink>
        </AlignedContainer>
      </RowContainer>
      <SearchContainer>
        <StyledContainer>
          <SearchInput
            onChange={event => {
              updateQuery({
                search: event.target.value || undefined
              })
            }}
            value={queryParams.search || ''}
            placeholder={msg`Search MFA apps...`}
            data-qa="MfaAppsPageSearchMfaAppField"
          />
        </StyledContainer>
      </SearchContainer>
    </>
  )

  return userHasMfaAddon ? (
    <>
      {header}
      <TableComponent
        qadata="MfaAppList"
        hideHeaderWhenEmpty={true}
        table={state.mfaApplicationState.table}
        getRecordLink={(record: MfaApplication) => `${matchUrl}/${record.key}`}
        onRowClick={(record: MfaApplication) =>
          actions.setSelectedApplication(record)
        }
        noRecordsView={<MfaAppsEmptyList actions={actions} />}
        noResultsView={
          <TableView
            icon={<StyledNoAppIcon data-qa="NoAppsIcon" />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={
              <Trans>
                Try searching for different keywords and check your spelling for
                any typos.
              </Trans>
            }
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined
                  })
                }}
                data-qa="ClearSearchButton"
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: function ApplicationCellRenderer(record: MfaApplication) {
              return (
                <>
                  <StyledAppIcon>
                    <MfaAppIcon mfaAppType={record.type} />
                  </StyledAppIcon>
                  <span>{record.name}</span>
                </>
              )
            }
          }
        ]}
        isFilterApplied={isFilterApplied}
      />
    </>
  ) : (
    <MfaAppsEmptyList actions={actions} />
  )
}

export function crateMfaAppList<
  TState extends {
    mfaApplicationList: MfaApplicationListState
    legacyVpnList: LegacyVpnListState
  }
>() {
  return connectRedux(
    MfaAppListComponent,
    (state: TState) => ({
      mfaApplicationState: state.mfaApplicationList,
      legacyVpnState: state.legacyVpnList
    }),
    {
      ...mfaApplicationListActions,
      ...legacyVpnListActions,
      ...globalActions
    }
  )
}

export type ApplicationsList = ReturnType<typeof crateMfaAppList>

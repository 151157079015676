import { put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createDirectoryIntegrationsSaga() {
  return function* getDirectoryIntegrationsSaga() {
    try {
      yield put(directoriesActions.setDirectoryIntegrationsLoading(true))
      try {
        yield put(directoriesActions.getDirectoryAccountInformation())
        yield put(directoriesActions.getDirectorySettings())
        yield put(directoriesActions.getADConnectorVersionLastSync())
        yield put(directoriesActions.getADConnectorApiKeyExistence())
      } finally {
        yield put(directoriesActions.setDirectoryIntegrationsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'

export function createGetAdminUsersSaga(policyService: UACServices.Services) {
  return function* getAdminUsersSaga() {
    try {
      const result: UACServices.AdminUsersAPI.Responses = yield call(
        policyService.getAdminUsers
      )
      if (result.type === UACServices.AdminUsersAPI.SUCCESS) {
        yield put(
          generalPoliciesDrawerActions.setAdminList(result.body.adminUsers)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'

import { ErrorMessage } from './GlobalAlert'

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorMessage />
          {this.props.children}
        </>
      )
    }

    return this.props.children
  }
}

export enum Federation {
  k1Length = 33
}

export enum OpenIdProvider {
  ADConnector = 'adConnector',
  AzureAD = 'azureAD',
  Google = 'google',
  Okta = 'okta',
  OneLogin = 'oneLogin',
  PingOne = 'pingOne'
}

export const openIdProviderNameMapping: Record<OpenIdProvider, string> = {
  [OpenIdProvider.ADConnector]: 'AD Connector',
  [OpenIdProvider.AzureAD]: 'Azure AD',
  [OpenIdProvider.Google]: 'Google Workspace',
  [OpenIdProvider.Okta]: 'Okta',
  [OpenIdProvider.OneLogin]: 'OneLogin',
  [OpenIdProvider.PingOne]: 'PingOne'
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

export const errorNotification = {
  message: msg`Admin level hasn't been created.`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const duplicateErrorNotification = {
  message: msg`There's already an admin level with that name`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const getAdminLevel = (state: AppState) =>
  state.adminLevelDrawer.adminLevel
export function createAddNewManagedAdminLevelSaga(
  addNewManagedAdminLevelService: UACServices.Services
) {
  return function* addNewManagedAdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.addNewManagedAdminLevel>
  ) {
    try {
      const { permissions: managedPermissions, closeLink } = action.payload

      try {
        yield put(
          adminLevelDrawerActions.setAdminLevelManagedPermissions(
            managedPermissions
          )
        )
        const { description, name, permissions } = yield select(getAdminLevel)

        const result: UACServices.AdminLevelSaveAPI.Responses = yield call(
          addNewManagedAdminLevelService.adminLevelSave,
          {
            name,
            description,
            mspCidOverridePermissions: managedPermissions,
            permissions,
            mspCidOverrideAdminLevel: managedPermissions.length
              ? MspCidOverrideLevel.custom
              : MspCidOverrideLevel.admin
          }
        )

        switch (result.type) {
          case UACServices.AdminLevelSaveAPI.SUCCESS: {
            const adminLevelId = result.body.id
            yield put(adminListActions.getAdminList())

            if (closeLink) {
              yield put(push(closeLink))
            } else {
              yield put(push(`/users/admin/${adminLevelId}/add`))
            }

            break
          }
          case UACServices.AdminLevelSaveAPI.DUPLICATE_NAME: {
            yield put(globalActions.setNotification(duplicateErrorNotification))
            break
          }
          default:
            throw new UnknownServiceError()
        }
      } catch (e) {
        yield put(globalActions.setNotification(errorNotification))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

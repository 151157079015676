import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/save-application-server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  Group,
  Role,
  User
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetAssignedElements(
  applicationsServices: UACServices.Services
) {
  return function* getAssignedElements(
    action: ReturnType<typeof applicationsDrawerActions.getAssignedElements>
  ) {
    try {
      yield put(applicationsDrawerActions.setDrawerLoading(true))

      try {
        const response: Responses = yield call(
          applicationsServices.getAssignedElements,
          action.payload.appId
        )

        if (response.type === UACServices.SSOApplicationsAPI.SUCCESS) {
          if (response.body.response.Succeeded) {
            const elementsResponse = response.body.response.Value

            const usersList: User[] = elementsResponse.Users.map(user => {
              return {
                id: user.Id.toLowerCase(),
                name: user.Name,
                lastPassId: user.LastPassId
              }
            })

            const groupList: Group[] = elementsResponse.Groups.map(group => {
              return {
                id: group.Id.toLowerCase(),
                name: group.Name,
                lastPassId: group.LastPassId,
                isAllUsersGroup: group.IsAllUsersGroup
              }
            })

            const roleList: Role[] = elementsResponse.Roles.map(role => {
              return {
                id: role.Id.toLowerCase(),
                name: role.Name
              }
            })

            yield put(
              applicationsDrawerActions.setAssignedElements(
                usersList,
                groupList,
                roleList
              )
            )
          } else {
            yield put(globalActions.setNotification(genericFailedNotification))
          }
        }
      } finally {
        yield put(applicationsDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

function getRandomInt(min, max) {
  // Create byte array and fill with 1 random number
  const byteArray = new Uint8Array(1)
  window.crypto.getRandomValues(byteArray)

  const range = max - min + 1
  const maxRange = 256
  if (byteArray[0] >= Math.floor(maxRange / range) * range) {
    return getRandomInt(min, max)
  }

  return min + (byteArray[0] % range)
}

export const generateRandomString = (length: number) => {
  const charset = [
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '1234567890',
    '!@#$%^&*'
  ].join('')

  let str = ''
  for (let i = 0; i < length; i++) {
    str += charset.charAt(getRandomInt(0, charset.length - 1))
  }

  return str
}

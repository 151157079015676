import React, { useEffect } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular, Heading100, PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { DashboardState } from '@lastpass/admin-console-dependencies/state/dashboard/state'

type UnresponsiveUsersDataProps = LastPassComponentProps<
  DashboardState,
  typeof dashboardActions
>

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.blue100};
  border-radius: ${props => props.theme.radius.pixel4};
  padding: 18px 24px;
`

export const StyledTop = styled.div`
  margin-bottom: 12px;
`

export const StyledBottom = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${props => props.theme.colors.grayBorder};
  padding-top: 12px;
  justify-content: space-between;
  align-items: center;
`

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
`

export const CallToActionBoxHeading = styled(Heading100)`
  line-height: 20px;
`

export const CallToActionBoxBody = styled(Heading100)`
  font-size: 14px;
`

export const UnresponsiveUsers: React.FunctionComponent<UnresponsiveUsersDataProps> = ({
  state,
  actions
}) => {
  useEffect(() => {
    actions.getUnresponsiveUsers()
  }, [actions])
  return (
    <Container data-qa={'UnresponsiveUsersBox'}>
      <StyledTop>
        <CallToActionBoxHeading>
          <Trans>Reinvite unresponsive users</Trans>
        </CallToActionBoxHeading>
        <BodyRegular>
          <Trans>
            Send a follow-up email to folks who haven{"'"}t responded in a week.
            Help them make their way on board.
          </Trans>
        </BodyRegular>
      </StyledTop>

      <StyledBottom>
        <LeftPanel>
          <CallToActionBoxHeading data-qa={'UnresponsiveUserCount'}>
            {state.unresponsiveUserCount}
          </CallToActionBoxHeading>
          <CallToActionBoxBody>
            <Trans>Unresponsive users</Trans>
          </CallToActionBoxBody>
        </LeftPanel>
        <LocationLink to={'/dashboard/invite'}>
          <PrimaryButton
            data-qa={'ViewAndReinviteButton'}
            onClick={() => {
              actions.reinviteUsersButtonClicked()
              actions.getUnresponsiveUsers()
            }}
          >
            <Trans>View & reinvite</Trans>
          </PrimaryButton>
        </LocationLink>
      </StyledBottom>
    </Container>
  )
}
export function createUnresponsiveUsersComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    UnresponsiveUsers,
    (state: TState) => state.dashboard,
    dashboardActions
  )
}

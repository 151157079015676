import React, { FunctionComponent } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { getAccountStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/AccountStatusMapping'

interface SelectAccountStatusProps {
  updateSelection: (update: AccountStatus[]) => void
  filters?: AccountStatus[]
}

interface AccountStatusSelectOption {
  value: AccountStatus
  label: string
}

export const SelectAccountStatus: FunctionComponent<SelectAccountStatusProps> = props => {
  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )
  const accountStatusMapping = getAccountStatusMapping(isAccountStatusRenamed)

  const optionValues: AccountStatus[] = [
    AccountStatus.active,
    AccountStatus.invited,
    AccountStatus.expiredInvitation,
    AccountStatus.awaitingApproval,
    AccountStatus.disabled,
    AccountStatus.staged
  ]
  const accountStatus: AccountStatusSelectOption[] = Object.values(
    optionValues
  ).map(value => ({
    value,
    label: (accountStatusMapping[value] as unknown) as string
  }))
  return (
    <Select
      dataQa={'AccountStatusDropdown'}
      options={accountStatus}
      value={accountStatus.filter(
        status => props.filters && props.filters.includes(status.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={i18n._(msg`LastPass status`)}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateSelection(selectedItems.map(status => status.value))
        } else {
          props.updateSelection([])
        }
      }}
      fluid
    >
      <Trans>Account status</Trans>
    </Select>
  )
}

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { IpPolicy } from '@lastpass/admin-console-dependencies/state/policies/passwordless/passwordless'

export const SUCCESS = 'success'

const success = (response: IpPolicy[]) => createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [responseBlackList, statusBlackList] = await identityApi(
        `api/v2/IP/?isBlackListed=true`,
        'GET'
      )

      const [responseWhiteList, statusWhiteList] = await identityApi(
        `api/v2/IP/?isBlackListed=false`,
        'GET'
      )

      if (
        statusBlackList === StatusCodes.OK &&
        responseBlackList &&
        statusWhiteList === StatusCodes.OK &&
        responseWhiteList
      ) {
        const blackListResponse = JSON.parse(responseBlackList)
        const whitelistResponse = JSON.parse(responseWhiteList)
        return success([...blackListResponse, ...whitelistResponse])
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

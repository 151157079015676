import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/icon-warning.svg'
import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'

import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'

import { StyledWarningBox } from './StyledComponents'

const StyledWarningIcon = styled(WarningIcon)`
  color: ${props => props.theme.colors.yellow900};
  min-width: 20px;
  height: 20px;
  margin-right: 14px;
`

export type Provider =
  | 'Azure AD'
  | 'Google Workspace'
  | 'Okta'
  | 'OneLogin'
  | 'PingOne'
  | 'PingFederate'
  | 'ADFS'
interface OpenIdMessageProps {
  provider: Provider
}
enum ADProvider {
  ADFS = 'adfs',
  PingFederate = 'pingFederate'
}

const WarningBox: FunctionComponent = ({ children }) => (
  <StyledWarningBox>
    <StyledWarningIcon data-qa="ConfigurationMessageWarningIcon" />
    {children}
  </StyledWarningBox>
)

export const OpenIdProviderConfigurationMessage: FunctionComponent<OpenIdMessageProps> = ({
  provider
}) => {
  const [enabledProvider, setEnabledProvider] = useState('')

  const { openId, adfs } = useSelector<AppState, FederatedLoginState>(
    appState => appState.federatedLogin
  )

  useEffect(() => {
    if (adfs.isEnabled) {
      setEnabledProvider(
        adfs.isPingFederate ? ADProvider.PingFederate : ADProvider.ADFS
      )
    } else if (openId.isEnabled) {
      setEnabledProvider(openId.provider)
    }
  }, [adfs.isEnabled, adfs.isPingFederate, openId.isEnabled, openId.provider])

  switch (enabledProvider) {
    case OpenIdProvider.AzureAD:
      return (
        <WarningBox>
          <Trans>
            To configure {provider}, you must first disable Azure AD.
          </Trans>
        </WarningBox>
      )
    case OpenIdProvider.Google:
      return (
        <WarningBox>
          <Trans>
            To configure {provider}, you must first disable Google Workspace.
          </Trans>
        </WarningBox>
      )
    case OpenIdProvider.PingOne:
      return (
        <WarningBox>
          <Trans>
            To configure {provider}, you must first disable PingOne.
          </Trans>
        </WarningBox>
      )
    case OpenIdProvider.OneLogin:
      return (
        <WarningBox>
          <Trans>
            To configure {provider}, you must first disable OneLogin.
          </Trans>
        </WarningBox>
      )
    case OpenIdProvider.Okta:
      return (
        <WarningBox>
          <Trans>To configure {provider}, you must first disable Okta.</Trans>
        </WarningBox>
      )
    case ADProvider.ADFS:
      return (
        <WarningBox>
          <Trans>To configure {provider}, you must first disable ADFS.</Trans>
        </WarningBox>
      )
    case ADProvider.PingFederate:
      return (
        <WarningBox>
          <Trans>
            To configure {provider}, you must first disable PingFederate.
          </Trans>
        </WarningBox>
      )
    default:
      return null
  }
}

import { Response as ActivityEventsResponse } from './get-activity-events'

export const activityEventsResponseCacher = () => {
  let eventsCache: { [page: number]: ActivityEventsResponse } = {}

  const cacheForPage = (
    page: number,
    activityEventsResponse: ActivityEventsResponse
  ): void => {
    eventsCache[page] = activityEventsResponse
  }

  const isPageAlreadyCached = (page: number): boolean => page in eventsCache

  const getResponseForPage = (page: number): ActivityEventsResponse | null => {
    if (!isPageAlreadyCached(page)) {
      return null
    }

    return eventsCache[page]
  }

  const clearCache = (): void => {
    eventsCache = {}
  }

  return {
    cacheForPage,
    getResponseForPage,
    clearCache
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { LocationRedirect } from '@lastpass/routing'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { MultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { MultifactorTypeAuthMethodMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

import { DuoSecurityConfig } from './configPages/DuoSecurityConfigPage'
import { RsaConfig } from './configPages/RsaConfigPage'
import { SecureAuthConfig } from './configPages/SecureAuthConfigPage'
import { SymantecConfig } from './configPages/SymantecConfigPage'

export interface ConfigureMfaMethodProps {
  closeLink: string
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
`

export const ConfigureMfaMethod: React.FunctionComponent<ConfigureMfaMethodProps> = ({
  closeLink
}) => {
  const dispatch = useDispatch()
  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )

  const option =
    multifactorDrawerState.selectedMultifactorOption == null
      ? MultifactorOptionType.TotpApp
      : multifactorDrawerState.selectedMultifactorOption

  const authMethod = MultifactorTypeAuthMethodMapping[option]

  useEffect(() => {
    dispatch(
      multifactorDrawerActions.multifactorConfigureMfaMethodViewed(authMethod)
    )
  }, [authMethod, dispatch])

  let configForm: React.ReactElement
  const isLastStep = location.pathname.endsWith('/configure')
  switch (multifactorDrawerState.selectedMultifactorOption) {
    case MultifactorOptionType.DuoSecurity:
      configForm = (
        <DuoSecurityConfig closeLink={closeLink} isLastStep={isLastStep} />
      )
      break
    case MultifactorOptionType.SymantecVip:
      configForm = (
        <SymantecConfig closeLink={closeLink} isLastStep={isLastStep} />
      )
      break
    case MultifactorOptionType.RsaSecureId:
      configForm = <RsaConfig closeLink={closeLink} isLastStep={isLastStep} />
      break
    case MultifactorOptionType.SecureAuth:
      configForm = (
        <SecureAuthConfig closeLink={closeLink} isLastStep={isLastStep} />
      )
      break
    default:
      configForm = <LocationRedirect to="/policies/multifactor" />
      break
  }

  return <MainContainer>{configForm}</MainContainer>
}

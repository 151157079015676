import React from 'react'

import { ReactComponent as AdfsLogo } from '@lastpass/assets/svg/admin-console/applications/mfa/adfs-install.svg'
import { ReactComponent as AzureADLogo } from '@lastpass/assets/svg/admin-console/applications/mfa/azure-ad.svg'
import { ReactComponent as MacWorkstationLogo } from '@lastpass/assets/svg/admin-console/applications/mfa/mac-workstation.svg'
import { ReactComponent as UniversalProxyLogo } from '@lastpass/assets/svg/admin-console/applications/mfa/universal-proxy.svg'
import { ReactComponent as WindowsWorkstationLogo } from '@lastpass/assets/svg/admin-console/applications/mfa/windows-workstation.svg'

import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'

interface MfaAppIconProps {
  mfaAppType: MfaApplicationType
}

export const MfaAppIcon = (props: MfaAppIconProps) => {
  switch (props.mfaAppType) {
    case MfaApplicationType.windows:
      return <WindowsWorkstationLogo />
    case MfaApplicationType.mac:
      return <MacWorkstationLogo />
    case MfaApplicationType.azureAD:
      return <AzureADLogo />
    case MfaApplicationType.adfs:
      return <AdfsLogo />
    case MfaApplicationType.universalProxy:
      return <UniversalProxyLogo />
    default:
      return null
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as ProvisionedUsersHandler from '@lastpass/admin-console-dependencies/sagas/users/view/operations/helpers/handle-provisioned-users'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'

import { invokeApi } from './operation-base'

export const totalSelectedUsersCountSelector = (state: AppState) =>
  state.usersList.table.totalResults

export function createDeleteUserSaga(userService: UACServices.Services) {
  return function* deleteUserSaga(
    action: ReturnType<typeof userOperationActions.deleteUsers>
  ) {
    try {
      const { isAllSelected } = action.payload.userOperationPayload
      let { users, filter } = action.payload.userOperationPayload
      let totalSelectedUsersCount = yield select(
        totalSelectedUsersCountSelector
      )

      if (isAllSelected) {
        const handlerResult = yield call(
          ProvisionedUsersHandler.handleAllUserSelection,
          users,
          filter,
          totalSelectedUsersCount,
          userService.userCount
        )

        if (!handlerResult.shouldProceed) {
          return
        }

        filter = handlerResult.usersFilter
        totalSelectedUsersCount = handlerResult.selectedUsersCount
      } else {
        users = yield call(
          ProvisionedUsersHandler.handleSelectionWithinPage,
          users,
          totalSelectedUsersCount
        )
      }

      if (users === undefined) {
        return
      }

      yield put(
        globalActions.setDialog({
          type: 'delete-user-confirmation-word-dialog',
          users,
          isAllSelected,
          allUserCount: totalSelectedUsersCount
        })
      )

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users have been deleted.`,
            MultipleUsersSuccess: msg`The selected users have been deleted from LastPass.`,
            SingleUserFail:
              users.length > 0
                ? msg`User not deleted: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User has been deleted from LastPass: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users haven't been deleted from the company.`
          },
          'deleteuser',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.DELETE_USERS,
          filter
        )
        yield put(push('/users/view'))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

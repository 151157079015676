import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { FederatedLoginActionTypes } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

import { createGetAdAttributeName } from './get-ad-attribute-name'
import { createGetAdfsSetup } from './get-adfs-setup'
import { createSaveAdAttributeName } from './save-ad-attribute-name'
import { createSaveAdfsSetup } from './save-adfs-setup'
import { createSetAdAttributeVisibility } from './set-ad-attribute-visibility'

export function createAdfsSetupSaga(adfsSetupService: UACServices.Services) {
  const getAdfsSetupSaga = createGetAdfsSetup(adfsSetupService)
  const saveAdfsSetupSaga = createSaveAdfsSetup(adfsSetupService)
  const getAdAttributeNameSaga = createGetAdAttributeName(adfsSetupService)
  const saveAdAttributeNameSaga = createSaveAdAttributeName(adfsSetupService)
  const toggleAdAttributeVisibility = createSetAdAttributeVisibility()

  return function*() {
    yield takeEvery(FederatedLoginActionTypes.ADFS_SETUP_GET, getAdfsSetupSaga)
    yield takeEvery(
      FederatedLoginActionTypes.ADFS_SETUP_SAVE,
      saveAdfsSetupSaga
    )
    yield takeEvery(
      FederatedLoginActionTypes.AD_ATTRIBUTE_GET,
      getAdAttributeNameSaga
    )
    yield takeEvery(
      FederatedLoginActionTypes.AD_ATTRIBUTE_SAVE,
      saveAdAttributeNameSaga
    )
    yield takeEvery(
      FederatedLoginActionTypes.AD_ATTRIBUTE_VISIBLE_SET,
      toggleAdAttributeVisibility
    )
  }
}

const currencies = {
  EUR: {
    text: 'Euro',
    symbol: '€'
  },
  USD: {
    text: 'US Dollar',
    symbol: '$'
  },
  GBP: {
    text: 'Pound Sterling',
    symbol: '£'
  },
  AUD: {
    text: 'Australian Dollar',
    symbol: '$'
  },
  CAD: {
    text: 'Canadian Dollar',
    symbol: '$'
  },
  BRL: {
    text: 'Brazilian Real',
    symbol: 'R$'
  },
  MXN: {
    text: 'Mexican Peso',
    symbol: '$'
  },
  CHF: {
    text: 'Swiss Franc',
    symbol: 'CHF'
  },
  JPY: {
    text: 'Yen',
    symbol: '¥'
  }
}

export const formatCurrencySymbol = (currency: string): string => {
  if (currencies[currency] !== undefined) {
    return currencies[currency].symbol
  }

  return currency
}

export const formatPrice = (price: number): string => price.toFixed(2)

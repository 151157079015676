import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

interface SeparatorLineProps {
  color?: string
  height?: number
}

const Line = styled.hr<SeparatorLineProps>`
  border-top: ${props =>
    `${props.height}px solid ${props.color || props.theme.colors.grayBorder}`};
`

export const SeparatorLine: FunctionComponent<SeparatorLineProps> = ({
  color,
  height = 1
}) => {
  return <Line data-qa="SeparatorLine" color={color} height={height} />
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  Heading300,
  RowContainer,
  SingleColumnContentContainer
} from '@lastpass/lastkit'

import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { securityDashboardActions } from '@lastpass/admin-console-dependencies/state/home/security-dashboard/actions'
import { UserSecurityLeaderboardBlock } from '@lastpass/admin-console-dependencies/ui/home/security-dashboard/user-security-leaderboard-block/UserSecurityLeaderboardBlock'

import { UserSecurityBlock } from './user-security-block/UserSecurityBlock'

const CompanyInfoBlock = styled.div`
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 32px;
`

const CompanyNameBlock = styled.strong`
  font-weight: 600;
`

export const SecurityDashboardPageContent: FunctionComponent = () => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    state => state.companyInfo.details
  )
  const { accountNumber, name: companyName } = companyDetails

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(securityDashboardActions.securityDashboardViewed())
  }, [dispatch])

  return (
    <SingleColumnContentContainer>
      <RowContainer>
        <Heading300 data-qa="SecurityDashboardHeader">
          <Trans>Security dashboard</Trans>
        </Heading300>
      </RowContainer>
      <CompanyInfoBlock data-qa="CompanyInfo">
        <CompanyNameBlock data-qa="CompanyName">{companyName}</CompanyNameBlock>{' '}
        <Trans>Account number</Trans> {accountNumber}
      </CompanyInfoBlock>
      <UserSecurityBlock />
      <UserSecurityLeaderboardBlock />
    </SingleColumnContentContainer>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { neverOnlyUrlActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/neveronlyurls/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetNeverOnlyUrlRulesSaga(
  neverOnlyUrlServices: UACServices.Services
) {
  return function* getNeverOnlyUrlRules() {
    try {
      yield put(neverOnlyUrlActions.setNeverOnlyUrlsLoading(true))
      try {
        const neverOnlyUrlsGetResponse: UACServices.GetNeverOnlyUrlsApi.Responses = yield call(
          neverOnlyUrlServices.neverOnlyUrlsGet
        )
        yield put(
          neverOnlyUrlActions.setNeverOnlyUrls(
            neverOnlyUrlsGetResponse.body.urlRules
          )
        )
      } finally {
        yield put(neverOnlyUrlActions.setNeverOnlyUrlsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = () => createResponse(SUCCESS)
const failure = () => createResponse(FAILURE)

export const responses = {
  success,
  failure
}

export type Responses = ResponseTypes<typeof responses>

export type SimpleOperation = 'activate' | 'suspend'

export function create(fetch: AuthFetch) {
  return async function service(
    type: SimpleOperation,
    id: ManagedCompany['id']
  ) {
    try {
      const [response, status] = await fetch(
        `company/managed-companies/${id}/${type}`,
        'POST'
      )
      if (status === StatusCodes.OK && response) {
        return success()
      }

      return failure()
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

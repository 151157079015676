import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { ReactComponent as PingIdentityIcon } from '@lastpass/assets/svg/admin-console/directories/icon-pingidentity.svg'
import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'
import { LogoCard } from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import { PingOneSetupForm } from './PingOneSetupForm'
import {
  BoldWrapper,
  StyledCardContainer,
  StyledChildRow,
  StyledExternalLink,
  StyledSubtitle
} from './StyledComponents'
import { VaultReEncryptionBlock } from './VaultReEncryptionBlock'

const PingOneSetupFormWrapper: FunctionComponent<{
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}> = ({ automatedVaultReEncryptionPolicy }) => {
  const dispatch = useDispatch()

  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )

  const { openId }: FederatedLoginState = useSelector(
    (state: AppState) => state.federatedLogin
  )
  const {
    provider,
    isEnabled,
    openIdUrl,
    clientId,
    isEmailHintDisabled,
    useOktaAuthServerToStoreCompanyKey
  } = openId

  const isPingOne = provider === OpenIdProvider.PingOne

  const initialValues = {
    authority: isPingOne ? openIdUrl : '',
    clientId: isPingOne ? clientId : '',
    isEnabled: isPingOne ? isEnabled : false,
    isEmailHintDisabled: isPingOne ? isEmailHintDisabled : true
  }

  return (
    <PingOneSetupForm
      initialValues={initialValues}
      key={`pingone ${openIdUrl} - ${clientId} - ${isEnabled}`}
      handleSubmit={openIdSetup => {
        dispatch(
          federatedLoginActions.saveOpenIdSetup(
            openIdSetup,
            openId,
            openIdSetup.isEnabled &&
              isAutomatedVaultReEncryptionEnabled &&
              !automatedVaultReEncryptionPolicy.isEnabled
          )
        )
      }}
      authority={openIdUrl}
      clientId={clientId}
      isOktaWithAdc={useOktaAuthServerToStoreCompanyKey}
      provider={provider}
      isEnabled={isEnabled}
      isEmailHintDisabled={isEmailHintDisabled}
    />
  )
}

export const PingOneSetupCard: FunctionComponent<{
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}> = ({ automatedVaultReEncryptionPolicy }) => {
  const dispatch = useDispatch()
  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )
  const { openId, adfs }: FederatedLoginState = useSelector(
    (state: AppState) => state.federatedLogin
  )
  const { provider, isEnabled } = openId
  const currentAdminLevel: string = useSelector((state: AppState) => {
    return state.usersList.currentAdminLevel
  })
  const isPingOne = provider === OpenIdProvider.PingOne

  useEffect(() => {
    dispatch(federatedLoginActions.getOpenIdSetup())
  }, [dispatch])
  return (
    <LogoCard
      dataQaName="PingOneCard"
      logo={<PingIdentityIcon />}
      highlighted={isPingOne && isEnabled}
      title={
        <Heading100>
          <Trans>PingOne</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from PingOne. They{"'"}ll log in to
          LastPass with their PingOne credentials instead of their master
          password.
        </Trans>
      }
    >
      <StyledCardContainer>
        <StyledChildRow>
          <BoldWrapper>
            <Trans>Configure PingOne</Trans>
          </BoldWrapper>
          <StyledExternalLink
            data-qa="PingOneInstructionsLink"
            href="http://link.lastpass.com/help-federated-pingone"
            target="_blank"
          >
            <Trans>Follow these instructions</Trans>
          </StyledExternalLink>
        </StyledChildRow>
        <StyledSubtitle>
          <Trans>
            Complete this form using information from your PingOne portal.
          </Trans>
        </StyledSubtitle>
        {(!isPingOne && isEnabled) || adfs.isEnabled ? (
          <div data-qa="IdpAlreadyConfiguredMessageOnPingOne">
            <OpenIdProviderConfigurationMessage provider="PingOne" />
          </div>
        ) : (
          <PingOneSetupFormWrapper
            automatedVaultReEncryptionPolicy={automatedVaultReEncryptionPolicy}
          />
        )}
        {currentAdminLevel === AdminLevel.superAdmin &&
          isEnabled &&
          isPingOne &&
          isAutomatedVaultReEncryptionEnabled && (
            <VaultReEncryptionBlock
              onClickReEncryption={() => {
                dispatch(
                  federatedLoginActions.startVaultReEncryption({
                    ...automatedVaultReEncryptionPolicy
                  })
                )
              }}
              isAutomatedVaultReEncryptionPolicyEnabled={
                automatedVaultReEncryptionPolicy.isEnabled
              }
            />
          )}
      </StyledCardContainer>
    </LogoCard>
  )
}

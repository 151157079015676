import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as Arrow } from '@lastpass/assets/svg/admin-console/icon-arrow-left.svg'

import { CaptionBoldStyle, CaptionSemiboldStyle } from '../styles'

export interface PaginationProps {
  compactMode?: boolean
  skip: number
  resultsPerPage: number
  totalResults: number
  updateSkip: (skip: number) => void
}

const IconWrapper = styled.div`
  display: inline-block;
  margin: auto 4px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
  vertical-align: middle;
`

const PaginationContainer = styled.div`
  ${CaptionSemiboldStyle}
  user-select: none;
  line-height: 40px;
  white-space: nowrap;
`

const PaginationElement = styled.div`
  margin: auto 4px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
  vertical-align: middle;
`

const BoldPaginationElement = styled(PaginationElement)`
  ${CaptionBoldStyle}
`

const ActiveElement = styled.div`
  background: red;
  height: 20px;
  width: fit-content;
  padding: 0px 4px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  color: ${props => props.theme.colors.blue};
  background: ${props => props.theme.colors.neutral100};
  vertical-align: middle;
`

function getCompactModeParameters(page: number) {
  let compactModeRange = [-1, 0, 1]
  let compactModeDistance = 1

  if (page > 999) {
    compactModeRange = [0]
    compactModeDistance = 0
  }

  return {
    compactModeRange,
    compactModeDistance
  }
}

const DEFAULT_LARGE_RANGE = [-4, -3, -2, -1, 0, 1, 2, 3, 4]
const DEFAULT_SMALL_RANGE = [-2, -1, 0, 1, 2]
const DEFAULT_DISTANCE = 2
const FEW_PAGES_LIMIT = 5

export function Pagination({
  compactMode = false,
  skip,
  resultsPerPage,
  totalResults,
  updateSkip
}: PaginationProps) {
  const totalPages = Math.ceil(totalResults / resultsPerPage)
  const currentPage = skip / resultsPerPage + 1

  const { compactModeRange, compactModeDistance } = getCompactModeParameters(
    currentPage
  )

  const range = compactMode ? compactModeRange : DEFAULT_SMALL_RANGE

  const distance = compactMode ? compactModeDistance : DEFAULT_DISTANCE

  return (
    <PaginationContainer>
      {totalPages > 1 && (
        <>
          {currentPage != 1 && (
            <IconWrapper
              onClick={() => updateSkip(skip - resultsPerPage)}
              css={css`
                margin-right: 8px;
              `}
              data-qa={'PaginationPrevious'}
            >
              <Arrow
                css={css`
                  height: 12px;
                  margin: 0 5px -2px 5px;
                `}
              />

              <Trans>Prev</Trans>
            </IconWrapper>
          )}

          {/*  If current page > FEW_PAGES_LIMIT and we are  more than distance + 1 places away from the first page */}
          {totalPages > FEW_PAGES_LIMIT && currentPage > distance + 1 && (
            <IconWrapper onClick={() => updateSkip(0)}>
              <PaginationElement>{1}</PaginationElement>
              {currentPage > distance + 2 && (
                <PaginationElement>{'\u2026'}</PaginationElement>
              )}
            </IconWrapper>
          )}

          {/* Map the helper array, to render page numbers.
      If total number of pages > FEW_PAGES_LIMIT, we show 2 numbers before and 2 after the current page.
      If the total number of pages <= FEW_PAGES_LIMIT than we show all available page numbers before and after the current page */}
          {(totalPages > FEW_PAGES_LIMIT ? range : DEFAULT_LARGE_RANGE).map(
            val => {
              if (currentPage + val > 0 && currentPage + val <= totalPages)
                return (
                  <BoldPaginationElement
                    key={val}
                    onClick={() =>
                      updateSkip((currentPage - 1 + val) * resultsPerPage)
                    }
                    data-qa={'PaginationNumber'}
                  >
                    {val == 0 ? (
                      <ActiveElement>{currentPage + val}</ActiveElement>
                    ) : (
                      currentPage + val
                    )}
                  </BoldPaginationElement>
                )
            }
          )}

          {/*  If current page > FEW_PAGES_LIMIT and we are  more than distance + 1 places away from the last page */}
          {totalPages > FEW_PAGES_LIMIT && currentPage < totalPages - distance && (
            <IconWrapper
              onClick={() => updateSkip((totalPages - 1) * resultsPerPage)}
            >
              {currentPage <= totalPages - (distance + 2) && (
                <PaginationElement>{'\u2026'}</PaginationElement>
              )}
              <PaginationElement data-qa={'PaginationLastPage'}>
                {totalPages}
              </PaginationElement>
            </IconWrapper>
          )}

          {currentPage != totalPages && (
            <IconWrapper
              onClick={() => updateSkip(skip + resultsPerPage)}
              css={css`
                margin-left: 8px;
              `}
              data-qa={'PaginationNext'}
            >
              <Trans>Next</Trans>
              <Arrow
                css={css`
                  height: 12px;
                  margin: 0 5px -2px 5px;
                  transform: rotate(180deg);
                `}
              />
            </IconWrapper>
          )}
        </>
      )}
    </PaginationContainer>
  )
}

import { RiskType } from '@lastpass/admin-console-dependencies/types/security-report'

export const onlyExportRiskTypes: RiskType[] = [
  RiskType.lowIterationCount,
  RiskType.sharedFoldersAccessedByLowIterationCountUsers,
  RiskType.mfa,
  RiskType.linkedAccountIterationCount,
  RiskType.urlsInVaults,
  RiskType.urlsInSharedFolders
]

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import { buildFilterArray } from '@lastpass/admin-console-dependencies/sagas/users/view/helpers/build-filter-array'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupListActions } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'

export function createGroupsListSaga(groupListService: UACServices.Services) {
  return function* getGroupsListSaga(
    action: ReturnType<typeof groupListActions.getGroupsList>
  ) {
    try {
      const groupFilters =
        action.payload.params.query && action.payload.params.query.filter

      const filters = groupFilters && buildFilterArray(groupFilters)
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        filter: filters ? { and: filters } : undefined,
        search: encodeSearch(action.payload.params.query.search),
        top: resultsPerPage
      })
      yield put(groupListActions.setGroupsLoading(true))
      try {
        const result: UACServices.GroupListAPI.Responses = yield call(
          groupListService.groupList,
          query
        )
        if (result.type === UACServices.GroupListAPI.SUCCESS) {
          const groupsList = result.body.groupsList
          const totalResults = result.body.totalResults
          yield put(
            groupListActions.setGroupsList(
              groupsList,
              totalResults,
              resultsPerPage
            )
          )
        }
      } finally {
        yield put(groupListActions.setGroupsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

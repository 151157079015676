import { put, select } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { TeamsPolicyMap } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getPolicyInfo = state => state.generalPoliciesList.policyMap

export const getPolicyAssignUsersAndGroupsState = state =>
  state.generalPolicyAssignUsersAndGroups

export function createGetGeneralPolicyAssignUsersAndGroupsSaga() {
  return function* getGeneralPolicyAssignUsersAndGroupsSaga(
    action: ReturnType<
      typeof generalPolicyAssignUsersAndGroupsActions.getGeneralPolicyAssignUsersAndGroups
    >
  ) {
    try {
      const policyKey = action.payload.params.path.id
      const policyConfigIdParam = action.payload.params.path.configId
      const searchFilter = action.payload.params.query.search

      if (policyKey && policyConfigIdParam) {
        yield put(
          generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroupsOpen(
            true
          )
        )

        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy = policyMap[policyKey]
        const policyConfigId = parseInt(policyConfigIdParam)
        if (policy) {
          if (searchFilter) {
            yield put(
              generalPolicyAssignUsersAndGroupsActions.searchGeneralPolicyAssignUsersAndGroups(
                searchFilter,
                policyKey,
                policyConfigId
              )
            )
          } else {
            const policyConfigAssignUsersAndGroups: GeneralPolicyUsersAndGroups = {
              policyKey: policy.policyKey,
              policyConfigIndex: policyConfigId,
              policyConfigFormData: {
                appliesTo: policy.policyData[policyConfigId].appliesTo,
                users: [],
                groups: []
              }
            }
            yield put(
              generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroups(
                policyConfigAssignUsersAndGroups
              )
            )
            yield put(
              generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroupsNeedsRefresh(
                false,
                policyKey,
                policyConfigId
              )
            )
          }
        } else {
          yield put(
            generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroupsNeedsRefresh(
              true,
              policyKey,
              policyConfigId
            )
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (provisionToken: string) =>
  createResponse(SUCCESS, { provisionToken })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'company/generate-scim-api-key',
        'POST'
      )
      if (status === StatusCodes.OK && response) {
        const { status, content } = JSON.parse(response)
        return success(status === 'ok' ? content : '')
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { TeamsPolicyValueType } from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  GeneralPolicySettingsActions,
  GeneralPolicySettingsActionTypes
} from './actions'
import { GeneralPolicySettingsFormState } from './state'

export const initialState: GeneralPolicySettingsFormState = {
  policySettings: {
    policyKey: '',
    policyValueType: TeamsPolicyValueType.checkbox,
    policyDescription: '',
    policyConfigIndex: 0,
    policyConfigFormData: {
      policyEnabled: false,
      policyNotes: '',
      policyValue: ''
    }
  },
  loading: false,
  formSubmitSuccess: false,
  settingsNeedsRefresh: false,
  isSettingsOpen: false
}

export function generalPolicySettingsReducer(
  previousState: GeneralPolicySettingsFormState | undefined,
  action: GeneralPolicySettingsActions
): GeneralPolicySettingsFormState {
  const state = previousState || initialState
  switch (action.type) {
    case GeneralPolicySettingsActionTypes.RESET: {
      return initialState
    }
    case GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS: {
      return {
        ...state,
        policySettings: {
          policyKey: action.payload.policySettings.policyKey,
          policyDescription: action.payload.policySettings.policyDescription,
          policyValueType: action.payload.policySettings.policyValueType,
          policyConfigIndex: action.payload.policySettings.policyConfigIndex,
          policyConfigFormData: {
            ...action.payload.policySettings.policyConfigFormData
          }
        }
      }
    }
    case GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_NEEDS_REFRESH: {
      return {
        ...state,
        policySettings: {
          ...state.policySettings,
          policyKey: action.payload.policyKey,
          policyConfigIndex: action.payload.configKey
        },
        settingsNeedsRefresh: action.payload.settingsNeedsRefresh
      }
    }
    case GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_FORM_SUBMIT_SUCCESS: {
      return {
        ...state,
        formSubmitSuccess: action.payload.isSuccess
      }
    }
    case GeneralPolicySettingsActionTypes.SET_GENERAL_POLICY_SETTINGS_OPEN: {
      return {
        ...state,
        isSettingsOpen: action.payload.isSettingsOpen
      }
    }
  }
  return state
}

import React from 'react'

import { FormikProps } from 'formik'

import { PermissionCategory } from '@lastpass/admin-console-dependencies/types/permissions/permission-category'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

import { Categories } from './components/Categories'
import { PermissionTreeHeader } from './components/PermissionTreeHeader'

interface PermissionTreeProps {
  formik: FormikProps<AdminFormValues>
  disabled?: boolean
  permissionCategories: PermissionCategory[]
}

export const PermissionTree: React.FunctionComponent<PermissionTreeProps> = ({
  formik,
  disabled,
  permissionCategories
}) => {
  return (
    <>
      <PermissionTreeHeader />
      <Categories
        formik={formik}
        categories={permissionCategories}
        disabled={disabled}
      />
    </>
  )
}

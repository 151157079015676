import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { ReactComponent as CopyDuplicateIcon } from '@lastpass/assets/svg/admin-console/copy-duplicate-icon.svg'
import { ReactComponent as OktaIcon } from '@lastpass/assets/svg/admin-console/directories/icon-okta.svg'
import {
  Federation,
  openIdProviderNameMapping
} from '@lastpass/federation/lib/federation-enums'
import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'
import { generateRandomString } from '@lastpass/federation/utils/random-string-generator'
import {
  BodyRegular,
  Checkbox,
  Heading100,
  LogoCard,
  PrimaryButton,
  TextInput
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  FederatedLoginState,
  OpenIdSetupState
} from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import { KcRotationAndVaultReEncryptionBlock } from './KcRotationAndVaultReEncryptionBlock'
import { KeyRotationBlock } from './KeyRotationBlock'
import {
  BoldWrapper,
  CheckBoxWithToolTip,
  GeneratedKeyContainer,
  GeneratedKeyTextInputContainer,
  StyledCardContainer,
  StyledChildRow,
  StyledExternalLink,
  StyledPrimaryButton,
  StyledSubtitle,
  StyledTextInput
} from './StyledComponents'

interface OpenIdSetupFormProps {
  handleSubmit: (openIdSetup: OpenIdSetupState) => void
  authority: string
  clientId: string
  enabled: boolean
  isOktaWithAdc: boolean
  provider: OpenIdProvider
  useOktaToStoreCompanyKey: boolean
  isEmailHintDisabled: boolean
  onAuthServerEnabled: () => void
  pkceEnabled: boolean
}

const OktaSetupForm: FunctionComponent<OpenIdSetupFormProps> = ({
  handleSubmit,
  authority,
  clientId,
  enabled,
  isOktaWithAdc,
  provider,
  useOktaToStoreCompanyKey,
  isEmailHintDisabled,
  onAuthServerEnabled,
  pkceEnabled
}) => {
  const isPkceOptInEnabled = useFeatureFlags(FeatureFlags.isPkceOptInEnabled)
  const isOkta = provider === OpenIdProvider.Okta
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )

  return (
    <Formik
      initialValues={{
        oktaAuthority: isOkta ? authority : '',
        oktaClientId: isOkta ? clientId : '',
        oktaEnabled: isOkta ? enabled : false,
        oktaUseAdConnector: isOkta ? isOktaWithAdc : false,
        oktaAuthCompanyWideKey: isOkta ? useOktaToStoreCompanyKey : false,
        oktaIsEmailHintDisabled: isOkta ? isEmailHintDisabled : false,
        oktaPkceEnabled: pkceEnabled
      }}
      onSubmit={values =>
        handleSubmit({
          openIdUrl: values.oktaAuthority.trim(),
          clientId: values.oktaClientId.trim(),
          isEnabled: values.oktaEnabled,
          useLastPassADConnectorToSyncUsers: values.oktaUseAdConnector,
          useOktaAuthServerToStoreCompanyKey: values.oktaAuthCompanyWideKey,
          provider: OpenIdProvider.Okta,
          isEmailHintDisabled: values.oktaIsEmailHintDisabled,
          pkceEnabled: values.oktaPkceEnabled,
          useAzureMdmFlow: false
        })
      }
    >
      {formikProps => {
        return (
          <>
            <StyledChildRow>
              <Field name="oktaAuthority">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="OktaAuthorityInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Open URL</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="oktaClientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="OktaClientIdInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Client ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="oktaEnabled">
                {formData => {
                  return (
                    <Checkbox
                      data-qa="OktaEnabledCheckbox"
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Enabled</Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              <Field name="oktaUseAdConnector">
                {formData => {
                  return (
                    <Checkbox
                      data-qa="OktaUseAdConnectorCheckbox"
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Use LastPass AD connector to sync users</Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              {isPkceOptInEnabled && (
                <Field name="oktaPkceEnabled">
                  {formData => {
                    return (
                      <CheckBoxWithToolTip>
                        <Checkbox
                          data-qa="OktaPkceEnabled"
                          name={formData.field.name}
                          checked={formData.field.value}
                          onChange={e => {
                            formikProps.setFieldValue(
                              formData.field.name,
                              e.currentTarget.checked
                            )
                          }}
                          disabled={
                            !hasDirectoriesAndFederationModifyPermission
                          }
                          toolTip={i18n._(
                            msg`Enable Proof Key for Code Exchange flow upon user login.`
                          )}
                        >
                          <Trans>Enable PKCE flow</Trans>
                        </Checkbox>
                      </CheckBoxWithToolTip>
                    )
                  }}
                </Field>
              )}
            </StyledChildRow>
            <StyledChildRow>
              <Field name="oktaAuthCompanyWideKey">
                {formData => {
                  return (
                    <Checkbox
                      data-qa="OktaAuthCompanyWideKeyCheckbox"
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                        if (e.currentTarget.checked) {
                          onAuthServerEnabled()
                        }
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>
                        Use Okta authorization servers to store company-wide key
                      </Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              <Field name="oktaIsEmailHintDisabled">
                {formData => {
                  return (
                    <CheckBoxWithToolTip>
                      <Checkbox
                        data-qa="OktaEmailHintDisabledCheckbox"
                        name={formData.field.name}
                        checked={formData.field.value}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.checked
                          )
                        }}
                        disabled={!hasDirectoriesAndFederationModifyPermission}
                        toolTip={i18n._(
                          msg`This prevents the username/email field from populating automatically upon user login`
                        )}
                      >
                        <Trans>Don{"'"}t send username/email hint to IdP</Trans>
                      </Checkbox>
                    </CheckBoxWithToolTip>
                  )
                }}
              </Field>
              <StyledPrimaryButton
                data-qa="OktaSaveChangesButton"
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                disabled={!hasDirectoriesAndFederationModifyPermission}
              >
                <Trans>Save changes</Trans>
              </StyledPrimaryButton>
            </StyledChildRow>
          </>
        )
      }}
    </Formik>
  )
}

const OktaGenerateKeyBlock: FunctionComponent<{ onCopy: () => void }> = ({
  onCopy
}) => {
  const initialGeneratedKey = generateRandomString(Federation.k1Length)
  const [generatedKey, setGeneratedKey] = useState(initialGeneratedKey)
  return (
    <>
      <Heading100>
        <Trans>Random company-wide key</Trans>
      </Heading100>
      <BodyRegular>
        <Trans>
          Copy this randomly generated key to Okta or generate a new one before
          copying. Once saved in Okta, you won{"'"}t need it again.
        </Trans>
        <br />
        <Trans>
          <b>Security note</b>: This locally generated key isn{"'"}t stored by
          LastPass in any way.
        </Trans>
      </BodyRegular>
      <GeneratedKeyContainer>
        <GeneratedKeyTextInputContainer>
          <TextInput
            name="oktaGeneratedKey"
            data-qa="oktaGeneratedKey"
            value={generatedKey}
            actionOnCopy={onCopy}
            readOnly
            copyToClipboard
            copyIcon={<CopyDuplicateIcon />}
          />
        </GeneratedKeyTextInputContainer>
        <PrimaryButton
          data-qa="OktaGenerateKeyButton"
          onClick={() =>
            setGeneratedKey(generateRandomString(Federation.k1Length))
          }
        >
          <Trans>Regenerate key</Trans>
        </PrimaryButton>
      </GeneratedKeyContainer>
    </>
  )
}

export const OktaSetupCard: FunctionComponent<{
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}> = ({ automatedVaultReEncryptionPolicy }) => {
  const dispatch = useDispatch()
  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )
  const currentAdminLevel: string = useSelector((state: AppState) => {
    return state.usersList.currentAdminLevel
  })
  const { openId, adfs }: FederatedLoginState = useSelector(
    (state: AppState) => state.federatedLogin
  )
  const {
    provider,
    isEnabled,
    clientId,
    pkceEnabled,
    openIdUrl,
    useLastPassADConnectorToSyncUsers,
    useOktaAuthServerToStoreCompanyKey,
    isEmailHintDisabled
  } = openId
  const isOkta = provider === OpenIdProvider.Okta
  const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin

  useEffect(() => {
    dispatch(federatedLoginActions.getOpenIdSetup())
  }, [dispatch])
  return (
    <LogoCard
      dataQaName={'OktaCard'}
      logo={<OktaIcon />}
      highlighted={isOkta && isEnabled}
      title={
        <Heading100>
          <Trans>Okta</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from Okta. They{"'"}ll log in to LastPass
          with their Okta credentials instead of their master password.
        </Trans>
      }
    >
      <StyledCardContainer>
        <StyledChildRow>
          <BoldWrapper>
            <Trans>Configure Okta</Trans>
          </BoldWrapper>
          <StyledExternalLink
            data-qa="OktaInstructionsLink"
            href={'http://link.lastpass.com/help-federated-login-okta'}
            target="_blank"
          >
            <Trans>Follow these instructions</Trans>
          </StyledExternalLink>
        </StyledChildRow>
        <StyledSubtitle>
          <Trans>
            Complete this form using information from your Okta portal.
          </Trans>
        </StyledSubtitle>
        {(!isOkta && isEnabled) || adfs.isEnabled ? (
          <div data-qa="IdpAlreadyConfiguredMessageOnOkta">
            <OpenIdProviderConfigurationMessage provider="Okta" />
          </div>
        ) : (
          <>
            <OktaSetupForm
              key={`okta ${openIdUrl} - ${clientId} - ${isEnabled}`}
              handleSubmit={(openIdSetup: OpenIdSetupState) => {
                dispatch(
                  federatedLoginActions.saveOpenIdSetup(
                    openIdSetup,
                    openId,
                    openIdSetup.isEnabled &&
                      isAutomatedVaultReEncryptionEnabled &&
                      !automatedVaultReEncryptionPolicy.isEnabled
                  )
                )
              }}
              authority={openIdUrl}
              clientId={clientId}
              isOktaWithAdc={useLastPassADConnectorToSyncUsers}
              provider={provider}
              enabled={isEnabled}
              useOktaToStoreCompanyKey={useOktaAuthServerToStoreCompanyKey}
              isEmailHintDisabled={isEmailHintDisabled}
              onAuthServerEnabled={() => {
                dispatch(
                  globalActions.setNotification({
                    message: msg`To use the Authorization Server, you must have an API Access Management subscription from Okta.`,
                    type: NotificationType.warning,
                    autoDismiss: true
                  })
                )
              }}
              pkceEnabled={pkceEnabled}
            />
            {!isEnabled && (
              <OktaGenerateKeyBlock
                onCopy={() => {
                  dispatch(
                    globalActions.setNotification(CopyToClipboardNotification)
                  )
                }}
              />
            )}
            {isSuperAdmin &&
            isEnabled &&
            isOkta &&
            isAutomatedVaultReEncryptionEnabled ? (
              <KcRotationAndVaultReEncryptionBlock
                onClickKcRotation={() => {
                  dispatch(
                    federatedLoginActions.startedKeyRotation({
                      provider: OpenIdProvider.Okta,
                      isCompanyWideK1: true
                    })
                  )
                }}
                onClickReEncryption={() => {
                  dispatch(
                    federatedLoginActions.startVaultReEncryption({
                      ...automatedVaultReEncryptionPolicy
                    })
                  )
                }}
                providerName={openIdProviderNameMapping[OpenIdProvider.Okta]}
                isAutomatedVaultReEncryptionPolicyEnabled={
                  automatedVaultReEncryptionPolicy.isEnabled
                }
              />
            ) : (
              isSuperAdmin &&
              isEnabled &&
              isOkta && (
                <KeyRotationBlock
                  onClick={() => {
                    dispatch(
                      federatedLoginActions.startedKeyRotation({
                        provider: OpenIdProvider.Okta,
                        isCompanyWideK1: true
                      })
                    )
                  }}
                  isCompanyWideK1={true}
                />
              )
            )}
          </>
        )}
      </StyledCardContainer>
    </LogoCard>
  )
}

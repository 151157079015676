const GetCsvInjectionSafeProperty = (property: string): string => {
  const formulaStarts = ['=', '+', '-', '@']

  if (formulaStarts.some(starter => property.includes(starter))) {
    return `'${property}`
  }

  property = property.replace(new RegExp('\\n', 'g'), ' ')
  property = property.replace(new RegExp('\\r', 'g'), ' ')
  property = property.replace(new RegExp('"', 'g'), '""')

  return property
}

export const ObjectToCsv = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  columnDelimiter = ',',
  lineDelimiter = '\n'
) => {
  let result, ctr

  if (data === null || !data.length) {
    return null
  }

  const keys = Object.keys(data[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach(item => {
    ctr = 0
    keys.forEach(key => {
      if (ctr > 0) {
        result += columnDelimiter
      }

      let cellValue = item[key]
      if (typeof cellValue === 'string') {
        cellValue = GetCsvInjectionSafeProperty(item[key])

        result += cellValue.includes(columnDelimiter)
          ? `"${cellValue}"`
          : cellValue
      }

      ctr++
    })
    result += lineDelimiter
  })

  return result
}

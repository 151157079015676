import React, { useRef } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Theme } from '../theme'

export interface CodeExampleProps {
  code: string
  allowCopyToClipboard?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 24px;
`

const CodeContainer = styled.div`
  background-color: ${Theme.colors.neutral900};
  color: #d1d9e1;
  font-size: 14px;
  line-height: 24px;
  padding: 16px 24px;
  border-radius: 3px;
  overflow: auto;
`

const CopyContainer = styled.div`
  color: ${Theme.colors.blue};
  align-self: flex-end;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`

export const CodeExample: React.FunctionComponent<CodeExampleProps> = (
  props: CodeExampleProps
) => {
  const copyRef = useRef<HTMLTextAreaElement>(null)

  function copyToClipboard() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(props.code)
    } else if (copyRef.current) {
      copyRef.current.focus()
      copyRef.current.select()
      document.execCommand('copy')
    }
  }

  return (
    <Container>
      {props.allowCopyToClipboard && (
        <CopyContainer onClick={() => copyToClipboard()}>
          <Trans>Copy to clipboard</Trans>
          {!navigator.clipboard && (
            <textarea
              ref={copyRef}
              css={css`
                position: absolute;
                left: -1000px;
              `}
              defaultValue={props.code}
            />
          )}
        </CopyContainer>
      )}
      <CodeContainer>
        <pre>{props.code}</pre>
      </CodeContainer>
    </Container>
  )
}

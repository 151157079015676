import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'
import { RouteProps } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Heading300, TabNavigation } from '@lastpass/lastkit'
import { RoutedTabNavigationItem } from '@lastpass/lastkit'
import { HeaderContainer, TabContainer } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { AcronisApiPage } from '@lastpass/admin-console-dependencies/ui/advanced/acronisApi/AcronisApiPage'
import { SiemIntegrations } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/SiemIntegrations'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

export const IntegrationsPage: React.FunctionComponent = () => {
  const isSiemIntegrationEnabled = useFeatureFlags(
    FeatureFlags.isSiemIntegrationEnabled
  )

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isAcronisApiEnabled =
    CompanyDetailsHelper.isMsp(companyDetails) ||
    CompanyDetailsHelper.isAggregatorMsp(companyDetails)

  const match = useRouteMatch()

  if (!match) {
    return null
  }
  const { path: matchPath, url: matchUrl } = match
  const [siemIntegrations, acronisApi] = ['siemIntegrations', 'acronisApi'].map(
    path => {
      const route: RouteProps = { path: `${matchPath}/${path}` }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    }
  )

  return (
    <>
      <PageHeaderContainer data-qa="PageHeaderContainer">
        <Heading300 data-qa="IntegrationsPageHeader">
          <Trans>Integrations</Trans>
        </Heading300>
      </PageHeaderContainer>
      <TabContainer data-qa="TabContainer">
        <TabNavigation>
          {isAcronisApiEnabled && (
            <RoutedTabNavigationItem
              data-qa="TabMenuAcronisIntegration"
              to={acronisApi.url}
              route={acronisApi.route}
            >
              <Trans>Acronis Cyber Protect</Trans>
            </RoutedTabNavigationItem>
          )}
          {isSiemIntegrationEnabled && (
            <RoutedTabNavigationItem
              data-qa="TabMenuSiemIntegration"
              to={siemIntegrations.url}
              route={siemIntegrations.route}
            >
              <Trans>SIEM integrations</Trans>
            </RoutedTabNavigationItem>
          )}
        </TabNavigation>
      </TabContainer>
      <Switch>
        {isAcronisApiEnabled && (
          <Route {...acronisApi.route}>
            <AcronisApiPage />
          </Route>
        )}
        {isSiemIntegrationEnabled && (
          <Route {...siemIntegrations.route}>
            <SiemIntegrations />
          </Route>
        )}
      </Switch>
    </>
  )
}

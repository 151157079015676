import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (ids: string[]) => createResponse(SUCCESS, { ids })

export const Responses = {
  success
}

export enum EntityType {
  User,
  Group
}

export type Responses = ResponseTypes<typeof Responses>

export function create(middlewareApi: AuthFetch) {
  return async function service(ids: string[], entityType: EntityType) {
    try {
      let servicePath

      switch (entityType) {
        case EntityType.User:
          servicePath = 'lastpassids/users'
          break
        case EntityType.Group:
          servicePath = 'lastpassids/groups'
          break
      }

      const [response, status] = await middlewareApi(servicePath, 'POST', ids, {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })

      if (status === StatusCodes.OK && response) {
        const parsedResponse = JSON.parse(response)
        return success([...parsedResponse.content])
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

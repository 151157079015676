import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { BodyBoldStyle, WithTooltip } from '@lastpass/lastkit'

const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100%;
`
const StyledLink = styled.button`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.blue700};
  padding: 0;
  outline: none;
  border-style: none;
  background: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`
const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
`

export interface PsaBillingUpdateLinkProps {
  text: ReactElement
  qaId: string
  isDisabled?: boolean
  onClick: () => void
}

export const PsaBillingUpdateLink: FunctionComponent<PsaBillingUpdateLinkProps> = ({
  text,
  qaId,
  isDisabled = false,
  onClick
}) => {
  const renderToolTip = () => (
    <WithTooltip
      tooltip={
        <Trans>
          Prerequisite: Import companies and select products for billing first.
        </Trans>
      }
      width="450px"
      data-qa={`${qaId}Tooltip`}
    >
      <StyledHelpIcon data-qa="trigger" />
    </WithTooltip>
  )

  return (
    <StyledLinkContainer>
      <StyledLink onClick={onClick} disabled={isDisabled} data-qa={qaId}>
        {text}
      </StyledLink>
      {isDisabled && renderToolTip()}
    </StyledLinkContainer>
  )
}

import { User, UserManager } from 'oidc-client-ts'

let manager: UserManager

const initialize = (settings: {
  applicationURL: string
  applicationID: string
}): UserManager => {
  const baseUri = window.location.origin + process.env.PUBLIC_URL
  manager = new UserManager({
    authority: settings.applicationURL,
    metadataUrl: settings.applicationURL,
    client_id: settings.applicationID,
    redirect_uri: `${baseUri}/auth-popup`,
    response_type: 'code',
    response_mode: 'fragment',
    scope: 'openid',
    silent_redirect_uri: `${baseUri}/auth-silent`,
    automaticSilentRenew: true,
    revokeTokensOnSignout: true
  })
  return manager
}

const login = async (): Promise<User> => {
  return await manager.signinPopup()
}

const getAccessToken = async (): Promise<string> => {
  const user: User | null = await manager.getUser()

  if (!user?.access_token) {
    await login()
    return ''
  }

  return user.access_token
}

const getAuthority = (): string => manager.settings.authority || ''

export const FederatedOAuthManager = {
  getAuthority,
  initialize,
  login,
  getAccessToken
}

import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AccountDetails } from '@lastpass/admin-console-dependencies/types/account-details'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { Policy } from '@lastpass/admin-console-dependencies/types/policy'
import { SharedFolder } from '@lastpass/admin-console-dependencies/types/shared-folder'
import { Site } from '@lastpass/admin-console-dependencies/types/site'
import { SSOApp } from '@lastpass/admin-console-dependencies/types/sso-app'
import {
  MfaTrustedDevice,
  PasswordManagerTrustedDevice,
  TrustedDevices,
  WorkstationTrustedDevice
} from '@lastpass/admin-console-dependencies/types/trusted-device'
import { UserProfile } from '@lastpass/admin-console-dependencies/types/user'
import { UserLevelObject } from '@lastpass/admin-console-dependencies/types/user-level'

import { UserListQueryParams } from '../list/actions'
import { UserOperationsType } from '../operations/user-operations'

export interface UserDrawerUrlParams {
  id: string
}

export interface UserDrawerQueryParams {
  search: string
  skip: number
}

export enum UserDrawerActionTypes {
  RESET = '[user-drawer] reset',

  // Profile
  GET_USER_PROFILE = '[user-profile] get',
  SET_USER_PROFILE = '[user-profile] set',
  CLEAR_USER_PROFILE = '[user-profile] clear',
  SET_USER_PROFILE_LOADING = '[user-profile] set-details-loading',
  SAVE_USER_PROFILE = '[user-profile] save',

  // User levels
  GET_USER_LEVELS = '[user-levels] get',
  SET_USER_LEVELS = '[user-levels] set',
  CLEAR_USER_LEVELS = '[user-levels] clear',
  SET_USER_LEVELS_LOADING = '[user-levels] set-loading',

  // Account Details
  GET_ACCOUNT_DETAILS = '[account-details] get',
  SET_ACCOUNT_DETAILS = '[account-details] set',
  CLEAR_ACCOUNT_DETAILS = '[account-details] clear',
  SET_ACCOUNT_DETAILS_LOADING = '[account-details] set-loading',

  // Apps
  GET_USER_APPS = '[user-apps] get',
  SET_USER_APPS = '[user-apps] set',
  CLEAR_USER_APPS = '[user-apps] clear',
  SET_USER_APPS_LOADING = '[user-apps] set-loading',

  // Groups
  GET_USER_GROUPS = '[user-groups] get',
  SET_USER_GROUPS = '[user-groups] set',
  CLEAR_USER_GROUPS = '[user-groups] clear',
  SET_USER_GROUPS_LOADING = '[user-groups] set-loading',

  // Sites
  GET_USER_SITES = '[user-sites] get',
  SET_USER_SITES = '[user-sites] set',
  CLEAR_USER_SITES = '[user-sites] clear',
  SET_USER_SITES_LOADING = '[user-sites] set-loading',

  // Policies
  GET_USER_POLICIES = '[user-policies] get',
  SET_USER_POLICIES = '[user-policies] set',
  CLEAR_USER_POLICIES = '[user-policies] clear',
  SET_USER_POLICIES_LOADING = '[user-policies] set-loading',

  // Shared Folders
  GET_USER_SHARED_FOLDERS = '[user-shared-folders] get',
  SET_USER_SHARED_FOLDERS = '[user-shared-folders] set',
  CLEAR_USER_SHARED_FOLDERS = '[user-shared-folders] clear',
  SET_USER_SHARED_FOLDERS_LOADING = '[user-shared-folders] set-loading',

  // Trusted Devices
  GET_USER_TRUSTED_DEVICES = '[user-trusted-devices] get',
  SET_USER_TRUSTED_DEVICES = '[user-trusted-devices] set',
  CLEAR_USER_TRUSTED_DEVICES = '[user-trusted-devices] clear',
  SET_USER_TRUSTED_DEVICES_LOADING = '[user-trusted-devices] set-loading',

  // LP Devices
  SET_SELECTED_LP_DEVICES = '[user-trusted-devices] set-selected-lp',
  ADD_SELECTED_LP_DEVICES = '[user-trusted-devices] add-selected-lp',
  REMOVE_SELECTED_LP_DEVICES = '[user-trusted-devices] remove-selected-lp',
  SAVE_PASSWORD_MANAGER = '[user-trusted-devices] save-password-manager',
  DELETE_LP_DEVICES = '[user-trusted-devices] delete-password-manager',
  ENABLE_PASSWORD_MANAGER = '[user-trusted-devices] enable-password-manager',

  // MFA Devices
  SET_SELECTED_MFA_DEVICES = '[user-trusted-devices] set-selected-mfa',
  ADD_SELECTED_MFA_DEVICES = '[user-trusted-devices] add-selected-mfa',
  REMOVE_SELECTED_MFA_DEVICES = '[user-trusted-devices] remove-selected-mfa',
  LOCK_SELECTED_MFA_DEVICES = '[user-trusted-devices] lock-selected-mfa-devices',
  UNLOCK_SELECTED_MFA_DEVICES = '[user-trusted-devices] unlock-selected-mfa-devices',

  // Workstations
  SET_SELECTED_WORKSTATIONS = '[user-trusted-devices] set-selected-workstations',
  ADD_SELECTED_WORKSTATIONS = '[user-trusted-devices] add-selected-workstations',
  REMOVE_SELECTED_WORKSTATIONS = '[user-trusted-devices] remove-selected-workstations',
  DELETE_SELECTED_WORKSTATIONS = '[user-trusted-devices] delete-selected-workstations',

  // User Operations
  GET_USER_OPERATIONS = '[user-operations] get',
  SET_USER_OPERATIONS = '[user-operations] set',
  CLEAR_USER_OPERATIONS = '[user-operations] clear',
  SET_USER_OPERATIONS_LOADING = '[user-operations] set-loading',

  // User Operations
  DELETE_USER = '[user] delete'
}

export const userDrawerActions = {
  reset: () => createAction(UserDrawerActionTypes.RESET),

  // Profile
  getUserProfile: (params: DataFetchParams<{}, UserDrawerUrlParams>) =>
    createAction(UserDrawerActionTypes.GET_USER_PROFILE, { params }),
  setUserProfile: (userProfile: UserProfile) =>
    createAction(UserDrawerActionTypes.SET_USER_PROFILE, userProfile),
  clearUserProfile: () =>
    createAction(UserDrawerActionTypes.CLEAR_USER_PROFILE),
  setUserProfileLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_PROFILE_LOADING, { loading }),
  saveUserProfile: (
    userProfile: UserProfile,
    id: string,
    userListParams: DataFetchParams<UserListQueryParams>
  ) =>
    createAction(UserDrawerActionTypes.SAVE_USER_PROFILE, {
      userProfile,
      id,
      userListParams
    }),

  // User levels
  getUserLevels: () => createAction(UserDrawerActionTypes.GET_USER_LEVELS),
  setUserLevels: (userLevels: UserLevelObject[]) =>
    createAction(UserDrawerActionTypes.SET_USER_LEVELS, userLevels),
  clearUserLevels: () => createAction(UserDrawerActionTypes.CLEAR_USER_LEVELS),
  setUserLevelsLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_LEVELS_LOADING, { loading }),

  // Account Details
  getAccountDetails: (params: DataFetchParams<{}, UserDrawerUrlParams>) =>
    createAction(UserDrawerActionTypes.GET_ACCOUNT_DETAILS, { params }),
  setAccountDetails: (accountDetails: AccountDetails) =>
    createAction(UserDrawerActionTypes.SET_ACCOUNT_DETAILS, accountDetails),
  clearAccountDetails: () =>
    createAction(UserDrawerActionTypes.CLEAR_ACCOUNT_DETAILS),
  setAccountDetailsLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_ACCOUNT_DETAILS_LOADING, {
      loading
    }),

  // Apps
  getUserApps: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(UserDrawerActionTypes.GET_USER_APPS, {
      params,
      resultsPerPage
    }),
  setUserApps: (apps: SSOApp[], totalResults: number, resultsPerPage: number) =>
    createAction(UserDrawerActionTypes.SET_USER_APPS, {
      apps,
      totalResults,
      resultsPerPage
    }),
  clearUserApps: () => createAction(UserDrawerActionTypes.CLEAR_USER_APPS),
  setUserAppsLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_APPS_LOADING, { loading }),

  // Groups
  getUserGroups: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(UserDrawerActionTypes.GET_USER_GROUPS, {
      params,
      resultsPerPage
    }),
  setUserGroups: (
    groups: Group[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(UserDrawerActionTypes.SET_USER_GROUPS, {
      groups,
      totalResults,
      resultsPerPage
    }),
  clearUserGroups: () => createAction(UserDrawerActionTypes.CLEAR_USER_GROUPS),
  setUserGroupsLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_GROUPS_LOADING, { loading }),

  // Sites
  getUserSites: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(UserDrawerActionTypes.GET_USER_SITES, {
      params,
      resultsPerPage
    }),
  setUserSites: (apps: Site[], totalResults: number, resultsPerPage: number) =>
    createAction(UserDrawerActionTypes.SET_USER_SITES, {
      apps,
      totalResults,
      resultsPerPage
    }),
  clearUserSites: () => createAction(UserDrawerActionTypes.CLEAR_USER_SITES),
  setUserSitesLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_SITES_LOADING, { loading }),

  // Policies
  getUserPolicies: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(UserDrawerActionTypes.GET_USER_POLICIES, {
      params,
      resultsPerPage
    }),
  setUserPolicies: (
    policies: Policy[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(UserDrawerActionTypes.SET_USER_POLICIES, {
      policies,
      totalResults,
      resultsPerPage
    }),
  clearUserPolicies: () =>
    createAction(UserDrawerActionTypes.CLEAR_USER_POLICIES),
  setUserPoliciesLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_POLICIES_LOADING, { loading }),

  // Shared Folders
  getUserSharedFolders: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(UserDrawerActionTypes.GET_USER_SHARED_FOLDERS, {
      params,
      resultsPerPage
    }),
  setUserSharedFolders: (
    sharedFolders: SharedFolder[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(UserDrawerActionTypes.SET_USER_SHARED_FOLDERS, {
      sharedFolders,
      totalResults,
      resultsPerPage
    }),
  clearUserSharedFolders: () =>
    createAction(UserDrawerActionTypes.CLEAR_USER_SHARED_FOLDERS),
  setUserSharedFoldersLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_SHARED_FOLDERS_LOADING, {
      loading
    }),

  // Trusted Devices
  getUserTrustedDevices: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>
  ) => createAction(UserDrawerActionTypes.GET_USER_TRUSTED_DEVICES, { params }),
  setUserTrustedDevices: (trustedDevices: TrustedDevices) =>
    createAction(
      UserDrawerActionTypes.SET_USER_TRUSTED_DEVICES,
      trustedDevices
    ),
  clearUserTrustedDevices: () =>
    createAction(UserDrawerActionTypes.CLEAR_USER_TRUSTED_DEVICES),
  setUserTrustedDevicesLoading: (loading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_TRUSTED_DEVICES_LOADING, {
      loading
    }),

  // LP Devices
  setSelectedLpDevices: (devices: PasswordManagerTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.SET_SELECTED_LP_DEVICES, { devices }),
  addSelectedLpDevices: (devices: PasswordManagerTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.ADD_SELECTED_LP_DEVICES, { devices }),
  removeSelectedLpDevices: (devices: PasswordManagerTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.REMOVE_SELECTED_LP_DEVICES, { devices }),
  savePasswordManager: (device: PasswordManagerTrustedDevice, userId: string) =>
    createAction(UserDrawerActionTypes.SAVE_PASSWORD_MANAGER, {
      device,
      userId
    }),
  deletePasswordManagers: (
    devices: PasswordManagerTrustedDevice[],
    userId: string
  ) =>
    createAction(UserDrawerActionTypes.DELETE_LP_DEVICES, { devices, userId }),
  enablePasswordManagers: (
    devices: PasswordManagerTrustedDevice[],
    enable: boolean,
    userId: string
  ) =>
    createAction(UserDrawerActionTypes.ENABLE_PASSWORD_MANAGER, {
      devices,
      enable,
      userId
    }),

  // MFA Devices
  setSelectedMfaDevices: (mfaDevices: MfaTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.SET_SELECTED_MFA_DEVICES, {
      mfaDevices
    }),
  addSelectedMfaDevices: (mfaDevices: MfaTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.ADD_SELECTED_MFA_DEVICES, {
      mfaDevices
    }),
  removeSelectedMfaDevices: (mfaDevices: MfaTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.REMOVE_SELECTED_MFA_DEVICES, {
      mfaDevices
    }),
  lockMfaDevices: (mfaDevices: MfaTrustedDevice[], userId: string) =>
    createAction(UserDrawerActionTypes.LOCK_SELECTED_MFA_DEVICES, {
      mfaDevices,
      userId
    }),
  unlockMfaDevices: (mfaDevices: MfaTrustedDevice[], userId: string) =>
    createAction(UserDrawerActionTypes.UNLOCK_SELECTED_MFA_DEVICES, {
      mfaDevices,
      userId
    }),

  // Workstations
  setSelectedWorkstations: (workstations: WorkstationTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.SET_SELECTED_WORKSTATIONS, {
      workstations
    }),
  addSelectedWorkstations: (workstations: WorkstationTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.ADD_SELECTED_WORKSTATIONS, {
      workstations
    }),
  removeSelectedWorkstations: (workstations: WorkstationTrustedDevice[]) =>
    createAction(UserDrawerActionTypes.REMOVE_SELECTED_WORKSTATIONS, {
      workstations
    }),
  deleteWorkstations: (
    workstations: WorkstationTrustedDevice[],
    userId: string
  ) =>
    createAction(UserDrawerActionTypes.DELETE_SELECTED_WORKSTATIONS, {
      workstations,
      userId
    }),

  // User Operations
  getUserOperations: (
    params: DataFetchParams<UserDrawerQueryParams, UserDrawerUrlParams>
  ) =>
    createAction(UserDrawerActionTypes.GET_USER_OPERATIONS, {
      params
    }),
  setUserOperations: (operations: UserOperationsType[]) =>
    createAction(UserDrawerActionTypes.SET_USER_OPERATIONS, operations),
  clearUserOperations: () =>
    createAction(UserDrawerActionTypes.CLEAR_USER_OPERATIONS),
  setUserOperationsLoading: (isLoading: boolean) =>
    createAction(UserDrawerActionTypes.SET_USER_OPERATIONS_LOADING, isLoading),
  // User Operations
  deleteUser: () => createAction(UserDrawerActionTypes.DELETE_USER)
}

export type UserDrawerActions = ActionsUnion<typeof userDrawerActions>

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SamlResponsesActionTypes } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'

import { createGetSamlResponses } from './get-saml-responses'

export function createSamlResponsesSaga(userService: UACServices.Services) {
  const getSamlResponses = createGetSamlResponses(userService)

  return function*() {
    yield takeEvery(
      SamlResponsesActionTypes.GET_SAML_RESPONSES,
      getSamlResponses
    )
  }
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { BodyRegularStyle } from '@lastpass/lastkit'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { useDecryptSecurityReportsFactory } from './hooks/use-decrypt-security-reports-factory'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
  text-align: center;
`

export const UrlDecryptionProgressDialogContent: FunctionComponent<{
  controller: AbortController
}> = ({ controller }) => {
  const dispatch = useDispatch()
  const securityReportDrawer = useSelector(
    (state: AppState) => state.securityReportDrawer
  )
  const {
    parseAndSaveCsv,
    numberOfDecryptedCsvRows
  } = useDecryptSecurityReportsFactory()

  useEffect(() => {
    ;(async () => {
      try {
        await parseAndSaveCsv(
          securityReportDrawer.securityReportFiles[0],
          controller
        )
      } catch (error) {
        dispatch(globalActions.setNotification(genericFailedNotification))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller, securityReportDrawer.securityReportFiles])

  const totalNumberOfCsvRows = securityReportDrawer.totalNumberOfCsvRows - 1
  return (
    <>
      {!numberOfDecryptedCsvRows ? (
        <StyledParagraph>
          <Trans>
            Step 1 of 2: Preparing data. Do not close this page until decryption
            finishes.
          </Trans>
        </StyledParagraph>
      ) : (
        <StyledParagraph>
          <Trans>
            Step 2 of 2: {numberOfDecryptedCsvRows} / {totalNumberOfCsvRows}{' '}
            records processed. Don’t close this page during processing.
          </Trans>
        </StyledParagraph>
      )}
    </>
  )
}

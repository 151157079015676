import { css } from '@emotion/react'

import { Theme } from '../theme'

const BodyText = css`
  color: ${Theme.colors.neutral900};
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
`

export const BodyBoldStyle = css`
  ${BodyText}
  font-weight: ${Theme.fonts.weight.bold};
`

export const BodySemiboldStyle = css`
  ${BodyText}
  font-weight: ${Theme.fonts.weight.semibold};
`

export const BodyRegularStyle = css`
  ${BodyText}
  font-weight: ${Theme.fonts.weight.regular};
`

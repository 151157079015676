export interface Logo {
  imageUrl: string
  storageUrl: string
}

export interface Application {
  id: string
  logo: Logo
  name: string
  legacyLastPassSSOEditUrl: string | null
  isSaml: boolean
  isUserAssigned: boolean
  hasNpMigratedApp: boolean
}

export interface SelectedApplication {
  id: string
  applicationId: string
  isSaml: boolean
  name: string
  HTMLInfo: string
  applicationName: string
  securityEmail: string | null
  isVpn: boolean
  isCustom: boolean
  allowEdit: boolean
  attributes: string
  relayState: string | null
  partnerName: string | null
  defaultPartnerName: string | null
  role: string | null
  identityProvider: string | null
  defaultIdentityProvider: string | null
  signAssertion: boolean
  wantAuthnRequestSigned: boolean
  signSAMLResponse: boolean
  encryptAssertion: boolean
  appKey: string | null
  authType: string | null
  useStepUpAuthentication: boolean
  useSHA256ForSigning: boolean | null
  webAddress: string | null
  samlKeyId: string | null
  partnerCertificateFingerprint: string | null
  partnerCertificate: string | null
  clearPartnerCertificate: boolean
  customLogoUrl: string
  samlAttributes: SamlAttribute[]
  //TODO: CustomLogo, CustomLogoFileName, ClearCustomLogo
}

export interface AvailableApplication {
  id: string
  text: string
  url?: string
  logo: Logo
  defaultPartnerName?: string
  defaultIdentityProvider?: string | null
  editUrl: boolean
  signAssertion: boolean
  wantAuthnRequestSigned: boolean
  signSAMLResponse: boolean
  encryptAssertion: boolean
}

export interface ApplicationMap {
  total: number
  results: Application[]
}

export interface AvailableApplicationMap {
  total: number
  results: AvailableApplication[]
}

export function createApplication(
  isCustom: boolean,
  name = '',
  applicationId = '',
  defaultPartnerName = '',
  defaultIdentityProvider: string | null = '',
  signAssertion = false,
  wantAuthnRequestSigned = false,
  signSAMLResponse = false,
  encryptAssertion = false,
  logoUrl = '',
  applicationName = '',
  HTMLInfo = '',
  attributes = '',
  relayState = '',
  partnerName = '',
  role = '',
  identityProvider = '',
  useStepUpAuthentication = false,
  useSHA256ForSigning = false,
  samlKeyId = '',
  id = '',
  samlAttributes: SamlAttribute[] = [],
  partnerCertificate = null
): SelectedApplication {
  return {
    id: id,
    name: name,
    isSaml: true,
    applicationId: applicationId,
    HTMLInfo: HTMLInfo,
    securityEmail: null,
    applicationName: applicationName,
    isVpn: false,
    isCustom: isCustom,
    allowEdit: false,
    attributes: attributes,
    relayState: relayState,
    partnerName: partnerName,
    defaultPartnerName: defaultPartnerName,
    role: role,
    identityProvider: identityProvider,
    defaultIdentityProvider: defaultIdentityProvider,
    signAssertion: signAssertion,
    wantAuthnRequestSigned: wantAuthnRequestSigned,
    signSAMLResponse: signSAMLResponse,
    encryptAssertion: encryptAssertion,
    appKey: null,
    authType: null,
    useStepUpAuthentication: useStepUpAuthentication,
    useSHA256ForSigning: useSHA256ForSigning,
    webAddress: null,
    samlKeyId: samlKeyId,
    partnerCertificateFingerprint: null,
    partnerCertificate: partnerCertificate,
    clearPartnerCertificate: false,
    customLogoUrl: logoUrl,
    samlAttributes: samlAttributes
  }
}

export interface EditApplicationResponseContainer {
  ErrorCode: string
  Message: string
  Succeeded: boolean
  Value
}

export interface SamlKey {
  fingerprint: string
  fingerprint256: string
  id: string
  name: string
  certificate: string
}

export interface ClientOauth {
  enabled: boolean
  keys: OauthKeys[]
}

export interface OauthKeys {
  clientId: string
  clientSecret: string
  callbackUrl: string
}

export interface UsernameAttribute {
  value: string
  label: string
  serverAttribute?: boolean
}

// Assign

export interface User {
  id: string
  name: string
  lastPassId?: number
}

export interface UserMap {
  total: number
  results: User[]
}

export interface Group {
  id: string
  name: string
  lastPassId?: number
  isAllUsersGroup?: boolean
}

export interface GroupResponse {
  Id: string
  Name: string
}

export interface GroupMap {
  ErrorCode: string
  Message: string | null
  Succeeded: boolean
  Value: GroupResponse[]
}

export interface Role {
  id: string
  name: string
}

export interface RoleResponse {
  Id: string
  Name: string
}

export interface RoleMap {
  ErrorCode: string
  Message: string | null
  Succeeded: boolean
  Value: RoleResponse[]
}

export interface SamlAttribute {
  Id?: string
  AttributeId?: string
  Type?: string
  Name?: string
  ConstantValue?: string
}

export interface MigrationInfo {
  ErrorCode: string
  Message: string
  Succeeded: boolean
  Value: boolean
}

export interface ManagedMigrationCompanyInfo {
  Id: string
  Name: string
}

export interface ManagedMigrationResponse {
  ErrorCode: string
  Message?: string
  Succeeded: boolean
  Value: ManagedMigrationCompanyInfo[]
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UrlEncryptionMigrationState } from '@lastpass/admin-console-dependencies/state/dashboard/state'

export const SUCCESS = 'success'

const success = (
  urlEncryptionMigrationState: Omit<
    UrlEncryptionMigrationState,
    'isMigrateToVaultFormatVersionV2Enabled'
  >
) => createResponse(SUCCESS, urlEncryptionMigrationState)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export const create = (fetch: AuthFetch) => {
  return async function service() {
    const [response, status] = await fetch(
      'dashboard/url-encryption-migration-state',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const parsedResponse: UrlEncryptionMigrationState = JSON.parse(response)
        .content
      return success(parsedResponse)
    }

    throw new UnknownServiceError()
  }
}

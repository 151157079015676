import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (count: number) => createResponse(SUCCESS, { count })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(queryPath: string) {
    const [response, status] = await fetch('users/count' + queryPath, 'GET')
    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const count = responseData.content.count
      return success(count)
    }
    throw new UnknownServiceError()
  }
}

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'

import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { GeneralPoliciesDrawerState } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/state'

export interface GeneralPoliciesDrawerProps {
  closeLink: string
  backButton?: ReactElement
  title: ReactElement
  displayPolicyTitle?: boolean
  children?: ReactElement
}

export const GeneralPoliciesDrawer: React.FunctionComponent<GeneralPoliciesDrawerProps> = ({
  closeLink,
  backButton,
  title,
  displayPolicyTitle,
  children
}) => {
  const pathParams = usePathParams()
  const generalPoliciesDrawerState: GeneralPoliciesDrawerState = useSelector(
    (state: AppState) => state.generalPoliciesDrawer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      generalPoliciesDrawerActions.getCurrentGeneralPolicy({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])
  useUnmountEffect(() => {
    dispatch(generalPoliciesDrawerActions.reset())
  })

  return (
    <Drawer
      mainTitle={
        displayPolicyTitle ? (
          <>{generalPoliciesDrawerState.policy.policyTitle}</>
        ) : (
          <></>
        )
      }
      subTitle={title}
      closeLink={closeLink}
      headerNodes={<>{backButton}</>}
    >
      {children}
    </Drawer>
  )
}

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AdressIcon } from '@lastpass/assets/svg/admin-console/icon-address.svg'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-users.svg'
import {
  CardTable,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

const StyledUserIcon = styled(UserIcon)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

interface AdminLevelUsersSummaryTableProps {
  users: Table<User>
  detailLink: string
  assignUsersLink: string
}

export const AdminLevelUsersSummaryTable: React.FunctionComponent<AdminLevelUsersSummaryTableProps> = ({
  users,
  detailLink,
  assignUsersLink
}) => {
  const permissions = useContext(PermissionContext)
  const canModifyAdmins = permissions.requirePermission(
    AdminPermissions.adminsModify
  )

  const columns = [
    {
      name: <></>,
      component: StyledUserIcon,
      contentWidth: 'auto'
    },
    {
      name: <Trans>Name</Trans>,
      renderer: (record: User) => getFullName(record)
    },
    {
      name: <Trans>Email</Trans>,
      renderer: (record: User) => record.email
    }
  ]

  return (
    <CardTable
      qadata="AdminLevelUsersList"
      table={users}
      columns={columns}
      footerNodes={
        <>
          <TextButton blue>
            <StyledLink
              to="/users/list"
              data-qa="AdminRolesGoToUsersPageButton"
            >
              <Trans>Go to Users page</Trans>
            </StyledLink>
          </TextButton>
          {canModifyAdmins && (
            <TextButton blue>
              <StyledLocationLink
                to={detailLink}
                data-qa="AdminRolesViewAllUsersButton"
                discardnamespace={Namespace.adminUsers}
              >
                <Trans>
                  View {'&'} edit all users: {users && users.totalResults}
                </Trans>
              </StyledLocationLink>
            </TextButton>
          )}
        </>
      }
      noRecordsView={
        <TableView
          icon={<AdressIcon />}
          title={<Trans>No users assigned to this admin level yet</Trans>}
          text={
            <Trans>Manage admin users by assigning them to admin levels</Trans>
          }
          actionButton={
            canModifyAdmins ? (
              <TableViewButton>
                <StyledLink
                  to={assignUsersLink}
                  data-qa="AdminRolesAssignUsersButton"
                >
                  <Trans>Assign users</Trans>
                </StyledLink>
              </TableViewButton>
            ) : (
              <></>
            )
          }
        />
      }
    />
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { MfaAppsDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { crateMfaAppList } from '@lastpass/admin-console-dependencies/ui/applications/mfa/MfaAppList'
import { MfaAppsDrawerComponent } from '@lastpass/admin-console-dependencies/ui/applications/mfa/MfaAppsDrawer'

interface MfaAppsPageProps {
  onClose?: () => void
}

const MfaAppsList = crateMfaAppList<AppState>()

export const MfaAppsPage: React.FunctionComponent<MfaAppsPageProps> = props => {
  const match = useRouteMatch()

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const hasMfa = CompanyDetailsHelper.userHasMfaAddon(companyDetails)
  const isTrial = CompanyDetailsHelper.isUserOnBusinessTrial(companyDetails)

  if (match) {
    const { path } = match
    return hasMfa || isTrial ? (
      <>
        <MfaAppsList />
        <AnimatedSwitch timeout={200}>
          <Route path={`${path}/add`}>
            <MfaAppsDrawerComponent
              closeLink={`${path}`}
              initialStep={MfaAppsDrawerSteps.create}
              onMfaAppsDrawerClosed={props.onClose}
            />
          </Route>
          <Route path={`${path}/:id`}>
            <MfaAppsDrawerComponent
              closeLink={`${path}`}
              initialStep={MfaAppsDrawerSteps.edit}
            />
          </Route>
        </AnimatedSwitch>
      </>
    ) : (
      <>
        <MfaAppsList />
      </>
    )
  }
  return null
}

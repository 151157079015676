import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { EnterpriseApiActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/actions'

import { createGenerateProvisioningHash } from './generate-provisioning-hash'
import { createGetEnterpriseApi } from './get-enterprise-api'

export function createEnterpriseApiSaga(uacServices: UACServices.Services) {
  const getEnterpriseApi = createGetEnterpriseApi(uacServices.enterpriseApi)
  const generateHash = createGenerateProvisioningHash(uacServices.enterpriseApi)

  return function*() {
    yield takeEvery(
      EnterpriseApiActionTypes.ENTERPRISE_API_GET,
      getEnterpriseApi
    )
    yield takeEvery(
      EnterpriseApiActionTypes.GENERATE_PROVISIONING_HASH,
      generateHash
    )
  }
}

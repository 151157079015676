import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Field, Formik } from 'formik'

import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { RadioButton } from '@lastpass/lastkit/components/RadioButton'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmWithRadio } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface RadioButtonDialogProps {
  state: ConfirmWithRadio
  actions: typeof globalActions
}

const RadioSectionContainer = styled.div`
  ${BodyRegularStyle}
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex-wrap: wrap;
`

export const RadioButtonDialogComponent: FunctionComponent<RadioButtonDialogProps> = ({
  state,
  actions
}) => {
  const discardText = i18n._(state.discardText)
  const confirmText = i18n._(state.confirmText)

  return (
    <Formik
      initialValues={{ radioValue: state.radioButtons[0].value }}
      onSubmit={values => {
        actions.confirmDialog(values.radioValue)
        actions.emptyDialog()
      }}
    >
      {formikProps => {
        return (
          <DetailsDialog
            closeDialog={() => {
              actions.discardDialog()
              actions.emptyDialog()
            }}
            buttons={[
              <TextButton
                data-qa="Discard"
                onClick={() => {
                  actions.discardDialog()
                  actions.emptyDialog()
                }}
                key="discard"
              >
                {discardText}
              </TextButton>,
              <PrimaryButton
                data-qa="Confirm"
                red
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                key="confirm"
              >
                {confirmText}
              </PrimaryButton>
            ]}
            width="480px"
            title={state.title}
            subTitle={state.text}
            content={
              <RadioSectionContainer>
                <Field name="radioValue">
                  {formData => {
                    return state.radioButtons.map(rb => {
                      return (
                        <RadioButton
                          data-qa="RadioButtonDialogRadioButton"
                          option={rb}
                          checked={formData.field.value === rb.value}
                          key={'radio' + rb.value}
                          onChange={() => {
                            formData.form.setFieldValue('radioValue', rb.value)
                          }}
                        />
                      )
                    })
                  }}
                </Field>
              </RadioSectionContainer>
            }
          />
        )
      }}
    </Formik>
  )
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as AzureSentinelIcon } from '@lastpass/assets/svg/admin-console/siem-integrations/icon-azure-sentinel.svg'
import { Heading100, LogoCard } from '@lastpass/lastkit'

import { AzureSentinelIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledCardContainer,
  StyledDescriptionRow,
  StyledExternalLink
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { AzureSentinelForm } from './AzureSentinelForm'

interface AzureSentinelIntegrationCardProps {
  config: AzureSentinelIntegrationConfig
}

export const AzureSentinelIntegrationCard: React.FunctionComponent<AzureSentinelIntegrationCardProps> = ({
  config
}) => (
  <LogoCard
    dataQaName="SiemAzureSentinelCard"
    logo={<AzureSentinelIcon />}
    title={
      <Heading100>
        <Trans>Azure Sentinel</Trans>
      </Heading100>
    }
    subtitle={
      <Trans>
        Configure LastPass to send audit events to an Azure Sentinel instance in
        near real-time.
      </Trans>
    }
  >
    <StyledCardContainer>
      <StyledDescriptionRow>
        <Trans>
          LastPass related events will be visible in Azure Sentinel.
        </Trans>
        <StyledExternalLink
          data-qa="SiemAzureSentinelInstructionsLink"
          href="https://link.lastpass.com/integrate-azure-sentinel-with-lastpass"
          target="_blank"
        >
          <Trans>View setup instructions</Trans>
        </StyledExternalLink>
      </StyledDescriptionRow>
      <AzureSentinelForm config={config} />
    </StyledCardContainer>
  </LogoCard>
)

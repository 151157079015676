import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'

export function createGetAdvancedUserActivityEvents(
  userActivityServices: UACServices.Services
) {
  return function* getAdvancedUserActivityEvents(
    action: ReturnType<typeof advancedUserActivityActions.getUserActivity>
  ) {
    try {
      yield put(advancedUserActivityActions.setUserActivityLoading(true))

      const advancedUserActivityResult: UACServices.AdvancedUserActivityEventsAPI.Responses = yield call(
        userActivityServices.advancedUserActivityEvents,
        action.payload.query
      )

      yield put(
        advancedUserActivityActions.setUserActivity(
          advancedUserActivityResult.body.events,
          advancedUserActivityResult.body.totalResults
        )
      )

      yield put(
        advancedUserActivityActions.setCurrentPageUserActivityOriginalEvents(
          advancedUserActivityResult.body.originalEvents
        )
      )

      yield put(advancedUserActivityActions.setUserActivityLoading(false))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

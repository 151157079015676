import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { saveAs } from 'file-saver'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as InfoIcon } from '@lastpass/assets/svg/admin-console/icon-information.svg'
import { Loading } from '@lastpass/components'
import {
  BodyRegularStyle,
  BodySemibold,
  Heading200,
  LogoCard,
  PrimaryButton,
  WithTooltip
} from '@lastpass/lastkit'
import { ProgressBar } from '@lastpass/lastkit/components/ProgressBar'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { UrlEncryptionMigrationState } from '@lastpass/admin-console-dependencies/state/dashboard/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

const StyledLogoCard = styled(LogoCard)`
  position: relative;
  background-color: ${props => props.theme.colors.blue50};
  margin-bottom: 1rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 1rem 1rem 0;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${BodyRegularStyle};
  color: ${props => props.theme.colors.neutral900};
`

const StyledInformationIcon = styled(InfoIcon)`
  position: relative;
  top: 2px;
  right: 1px;
  width: 14px;
  height: 14px;
`

export const UrlEncryptionMigrationOverviewCard = () => {
  const [isExporting, setIsExporting] = useState(false)

  const { exportUrlEncryptionMigrationState } = useContext(ServerContext)
  const { _ } = useLingui()
  const {
    migratedUserCount,
    totalEnrolledUserCount,
    isLoading
  }: UrlEncryptionMigrationState = useSelector(
    (state: AppState) => state.dashboard.urlEncryptionMigrationState
  )
  const dispatch = useDispatch()

  const canCaculateProgress =
    typeof migratedUserCount === 'number' &&
    typeof totalEnrolledUserCount === 'number'

  const migrationProgressInPercentage = canCaculateProgress
    ? (migratedUserCount / totalEnrolledUserCount) * 100
    : 0

  const tooltipContent = _(
    msg`Enrolled user: The user has joined your account and logged in at least once.`
  )
  const content = (
    <div data-qa="MigrationOverviewCardContent">
      {migrationProgressInPercentage === 100 ? (
        <Trans>
          All URLs stored in enrolled users’ vaults are now encrypted. Well
          done!
        </Trans>
      ) : (
        <Trans>
          {migratedUserCount} / {totalEnrolledUserCount} enrolled users{' '}
          <WithTooltip tooltip={tooltipContent} width="450px">
            <StyledInformationIcon fill="#6F7780" />
          </WithTooltip>{' '}
          have encrypted their URLs.
        </Trans>
      )}
      <BodySemibold>
        <a
          data-qa="LearnMoreLink"
          href="https://link.lastpass.com/about-url-encryption-admins"
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;<Trans>Learn how this protects your organization</Trans>
        </a>
      </BodySemibold>
    </div>
  )

  const handleClick = async () => {
    try {
      setIsExporting(true)
      const { body: csvContent } = await exportUrlEncryptionMigrationState()

      const blobToSave = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8;'
      })
      saveAs(blobToSave, 'url-encryption-migration-state.csv')
    } catch {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      setIsExporting(false)
    }
  }

  return (
    <StyledLogoCard
      title={
        <Heading200 data-qa="MigrationOverviewCardTitle">
          {migrationProgressInPercentage === 100 ? (
            <Trans>URL encryption completed</Trans>
          ) : (
            <Trans>URL encryption in progress</Trans>
          )}
        </Heading200>
      }
      expand
    >
      {isLoading && <Loading color="blue900" active={true} />}
      <Wrapper>
        <ProgressBar progress={migrationProgressInPercentage} />
        <ContentWrapper>
          {content}
          <PrimaryButton
            data-qa="ExportUrlEncryptionDataButton"
            onClick={handleClick}
            disabled={isExporting}
          >
            {isExporting ? (
              <Trans>Generating...</Trans>
            ) : (
              <Trans>Export URL encryption data</Trans>
            )}
          </PrimaryButton>
        </ContentWrapper>
      </Wrapper>
    </StyledLogoCard>
  )
}

import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import {
  BodyRegularStyle,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import {
  AssignmentStatus,
  AvailabilityStatus,
  PsaAgreementsState,
  PsaCompanyContractAssignment
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import { AgreementSelector } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/common/AgreementSelector'
import { getAgreementSelectionMessage } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/helpers/get-agreement-selection-message'
import { AssignmentMappingValue } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'
import { PsaDrawerContext } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/drawer/PsaDrawer'

const ButtonSpacing = css`
  margin-right: 16px;
`

const StyledTextButton = styled(TextButton)`
  ${ButtonSpacing}
`

const StyledPrimaryButton = styled(PrimaryButton)`
  ${ButtonSpacing}
`

const StyledContainer = styled.div`
  ${BodyRegularStyle}
  margin: -15px 0 24px 0;
`

const StyledLink = styled(LocationLink)`
  display: flex;
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

const StyledLinkTextButton = styled(TextButton)`
  padding-left: 0;
`

const AgreementSelectorContainer = styled.div`
  & > div {
    justify-content: flex-start;
  }
  div svg {
    margin-top: 20px;
  }
`

interface IndividualCompanyAgreementDrawerContentProps {
  managedCompanyId: string
}

export const IndividualCompanyAgreementDrawerContent: FunctionComponent<IndividualCompanyAgreementDrawerContentProps> = ({
  managedCompanyId
}) => {
  const { closeDrawer } = useContext(PsaDrawerContext)
  const { assignmentOptions, isLoading } = useSelector<
    AppState,
    PsaAgreementsState
  >((state: AppState) => state.psaIntegrations.agreements)
  const dispatch = useDispatch()

  const noSelection: AssignmentMappingValue = {
    contractId: null,
    contractName: null,
    assignmentStatus: AssignmentStatus.NOT_ASSIGNED,
    availabilityStatus: AvailabilityStatus.AVAILABLE
  }

  const [assignedAgreement, setAssignedAgreement] = useState<
    AssignmentMappingValue
  >(noSelection)
  const [newAssignedAgreement, setNewAssignedAgreement] = useState<
    AssignmentMappingValue
  >(noSelection)

  const assignmentOption = assignmentOptions[0] || ''

  const descriptionWithoutAgreements = (
    <Trans>
      Remember: To select agreements, you must first select products
      corresponding to LastPass.
    </Trans>
  )

  const selectProductsLink = (
    <StyledLinkTextButton data-qa="productsLink">
      <StyledLink to={'/advanced/psaIntegrations/billing/products'}>
        <Trans>Select products</Trans>
      </StyledLink>
    </StyledLinkTextButton>
  )

  const handleAssignmentChange = (
    {
      contractId,
      contractName,
      assignmentStatus
    }: PsaCompanyContractAssignment,
    availabilityStatus: AvailabilityStatus
  ) => {
    setNewAssignedAgreement({
      contractId,
      contractName,
      assignmentStatus,
      availabilityStatus
    })

    dispatch(psaIntegrationsActions.setDrawerContainsUnsavedChanges(true))
  }

  const handleSave = () => {
    const isConfirmRequired = !newAssignedAgreement.contractId
    const { contractId, assignmentStatus, contractName } = newAssignedAgreement
    const successUrl = `/home/managed-companies/${managedCompanyId}`
    const newAssignment = {
      companyId: Number(managedCompanyId),
      contractId,
      contractName,
      assignmentStatus
    }
    const successAction = managedCompaniesActions.setPsaDetails(
      Number(managedCompanyId),
      {
        contractName
      }
    )

    dispatch(
      psaIntegrationsActions.savePsaAgreements(
        [newAssignment],
        isConfirmRequired,
        successUrl,
        successAction
      )
    )
  }

  useEffect(() => {
    if (assignmentOption) {
      const {
        assignedContractId,
        assignmentStatus
      } = assignmentOption.contractAssignment
      const assignedContract = assignmentOption.contractAssignment.contracts.find(
        ({ id }) => id === assignedContractId
      )
      const assignedContractName = assignedContract?.name || ''
      const availabilityStatus =
        assignedContract?.availability || AvailabilityStatus.AVAILABLE
      const initialAssignment: AssignmentMappingValue = {
        contractId: assignedContractId,
        contractName: assignedContractName,
        assignmentStatus: assignmentStatus,
        availabilityStatus
      }
      setAssignedAgreement(initialAssignment)
      setNewAssignedAgreement(initialAssignment)
    }
  }, [assignmentOption])

  const messageDefinition = getAgreementSelectionMessage({
    initiallySelectedOptionId: assignedAgreement.contractId,
    initiallySelectedOptionStatus: assignedAgreement.assignmentStatus,
    selectedOptionId: newAssignedAgreement.contractId,
    selectedOptionStatus: newAssignedAgreement.assignmentStatus,
    selectedOptionAvailability: newAssignedAgreement.availabilityStatus,
    numberOfAvailableOptions: assignmentOption
      ? assignmentOption.contractAssignment.contracts.length
      : 0
  })

  return (
    <>
      {isLoading ? (
        <Loading color="blue900" active={true} isFull={false} />
      ) : (
        <AgreementSelectorContainer data-qa="AgreementSelectorContainer">
          {assignmentOption.contractAssignment &&
          assignmentOption.contractAssignment.contracts.length ? (
            <>
              <AgreementSelector
                actuallySelectedId={newAssignedAgreement.contractId}
                actualSelectionStatus={newAssignedAgreement.assignmentStatus}
                company={assignmentOption.company}
                contracts={assignmentOption.contractAssignment.contracts}
                labelText={<Trans>Agreement for billing</Trans>}
                selectionMessage={messageDefinition?.message}
                onSelect={handleAssignmentChange}
                isMessagePositionedRight={true}
              />
            </>
          ) : (
            <StyledContainer>
              {descriptionWithoutAgreements} {selectProductsLink}
            </StyledContainer>
          )}
        </AgreementSelectorContainer>
      )}
      <FooterContainer>
        <DrawerButtonsContainer>
          <StyledTextButton
            data-qa="DiscardButton"
            type="button"
            onClick={closeDrawer}
          >
            <Trans>Discard</Trans>
          </StyledTextButton>

          <StyledPrimaryButton
            data-qa="SaveButton"
            type="submit"
            onClick={handleSave}
            disabled={
              newAssignedAgreement.contractId ===
                assignedAgreement.contractId &&
              newAssignedAgreement.assignmentStatus ===
                assignedAgreement.assignmentStatus
            }
          >
            <Trans>Save changes</Trans>
          </StyledPrimaryButton>
        </DrawerButtonsContainer>
      </FooterContainer>
    </>
  )
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import { takeDialogInteractions } from '@lastpass/admin-console-dependencies/sagas/utils/take-dialog-interactions'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function createSwitchPsaProviderSaga() {
  return function* switchPsaProviderSaga(
    action: ReturnType<typeof psaIntegrationsActions.switchPsaProvider>
  ) {
    try {
      yield put(psaIntegrationsActions.setDrawerInProgress(true))

      const dialog = getProviderChangeConfirmDialog()
      yield put(globalActions.setDialog(dialog))
      const { isConfirmed, isDiscarded } = yield call(takeDialogInteractions)
      if (isDiscarded) return

      if (isConfirmed) {
        yield put(
          push(`/advanced/psaIntegrations/credentials/${action.payload}/edit`)
        )
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(psaIntegrationsActions.setDrawerInProgress(false))
    }
  }

  function getProviderChangeConfirmDialog() {
    const dialogText = msg`Billing updates through the old provider will no longer be possible, and PSA credentials will be deleted. Imported managed companies remain in LastPass unchanged.`

    const dialog: ConfirmDialog = {
      type: 'confirmdialog',
      confirmText: msg`Switch`,
      discardText: msg`Cancel`,
      text: dialogText,
      title: msg`Switch PSA provider?`
    }
    return dialog
  }
}

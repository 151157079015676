export const xorBuffers = (b1: Buffer, b2: Buffer): Buffer => {
  const length = Math.max(b1.length, b2.length)
  const buffer = Buffer.allocUnsafe(length)

  for (let i = 0; i < length; ++i) {
    buffer[i] = b1[i] ^ b2[i]
  }

  return buffer
}

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { EnterpriseAPIExampleModel } from './state'

export enum EnterpriseApiActionTypes {
  ENTERPRISE_API_GET = '[enterprise-api] get',
  ENTERPRISE_API_SET = '[enterprise-api] set',
  GENERATE_PROVISIONING_HASH = '[enterprise-api] generate hash',
  SET_PROVISIONING_HASH = '[enterprise-api] set hash',
  SET_INPROGRESS = '[enterprise-api] set in progress'
}

export const enterpriseApiActions = {
  getEnterpriseApi: () =>
    createAction(EnterpriseApiActionTypes.ENTERPRISE_API_GET),
  setEnterpriseApi: (
    hasHash: boolean,
    emailVerified: boolean,
    whitelistedIps: string,
    apiExamples: EnterpriseAPIExampleModel[]
  ) =>
    createAction(EnterpriseApiActionTypes.ENTERPRISE_API_SET, {
      hasHash,
      emailVerified,
      whitelistedIps,
      apiExamples
    }),
  generateProvisioningHash: (reset?: boolean) =>
    createAction(EnterpriseApiActionTypes.GENERATE_PROVISIONING_HASH, {
      reset
    }),
  setProvisioningHash: (hash: string) =>
    createAction(EnterpriseApiActionTypes.SET_PROVISIONING_HASH, { hash }),
  setInProgress: (inProgress: boolean) =>
    createAction(EnterpriseApiActionTypes.SET_INPROGRESS, inProgress)
}

export type EnterpriseApiActions = ActionsUnion<typeof enterpriseApiActions>

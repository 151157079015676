import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Collapsible } from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserDrawerHome } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerHome'

import { AdminLevelUsersSummaryTable } from './AdminLevelUsersSummaryTable'

const USERS_PER_PAGE = 5

interface AdminLevelUsersSummaryCardProps {
  url: string
}

export const AdminLevelUsersSummaryCard: React.FunctionComponent<AdminLevelUsersSummaryCardProps> = ({
  url
}) => {
  const users: Table<User> = useSelector(
    (state: AppState) => state.adminLevelDrawer.users
  )

  const queryParams = useQueryParams()
  const pathParams = usePathParams()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      adminLevelDrawerActions.getAdminLevelUsers(
        { path: pathParams, query: queryParams },
        USERS_PER_PAGE
      )
    )
  }, [dispatch, pathParams, queryParams])

  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  return (
    <UserDrawerHome>
      <Collapsible
        title={<Trans>Users</Trans>}
        expanded={isExpanded}
        loading={users.isLoading}
        onExpand={() => setIsExpanded(!isExpanded)}
        qadata="UsersCard"
      >
        <AdminLevelUsersSummaryTable
          users={users}
          detailLink={`${url}/users`}
          assignUsersLink={`${url}/add`}
        />
      </Collapsible>
    </UserDrawerHome>
  )
}

import React, { FunctionComponent, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps, Switch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as AdoptionDashboardIcon } from '@lastpass/assets/svg/admin-console/icon-adoption-dashboard.svg'
import { ReactComponent as PaperClipIcon } from '@lastpass/assets/svg/admin-console/icon-paper-clip.svg'
import { ReactComponent as SecurityDashboardIcon } from '@lastpass/assets/svg/admin-console/icon-security-dashboard.svg'
import { SidebarContainer } from '@lastpass/lastkit'
import { SideNavigation } from '@lastpass/lastkit/components/SideNavigation'
import { RoutedSideNavigationItem } from '@lastpass/routing'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { DashboardPage } from '../dashboard/DashboardPage'
import { ManagedCompaniesPage } from './managed-companies/ManagedCompaniesPage'
import { NewSecurityDashboardPage } from './new-security-dashboard/NewSecurityDashboardPage'
import { SecurityDashboardPage } from './security-dashboard/SecurityDashboardPage'

export const HomePage: FunctionComponent<{}> = () => {
  const permissions = useContext(PermissionContext)

  const haveAccessToDashboard = permissions.requirePermission(
    AdminPermissions.dashboardView
  )

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const isNewSecurityDashboardEnabled = useFeatureFlags(
    FeatureFlags.isNewSecurityDashboardEnabled
  )

  const managedCompaniesPermission = isRoleBasedAccessControlEnabled
    ? AdminPermissions.managedCompanyView
    : AdminPermissions.managedCompanyAccess

  const shouldShowMspPage =
    CompanyDetailsHelper.isMsp(companyDetails) &&
    permissions.requirePermission(managedCompaniesPermission)

  const [adoptionDashboard, securityDashboard, managedCompanies] = [
    '/dashboard',
    '/home/security-dashboard',
    '/home/managed-companies'
  ].map(path => {
    const route: RouteProps = { path: `${path}` }
    const url = `${path}`
    return {
      route,
      url
    }
  })

  return (
    <>
      <SidebarContainer>
        <SideNavigation>
          {haveAccessToDashboard && (
            <RoutedSideNavigationItem
              data-qa="SideMenuAdoptionDashboard"
              to={adoptionDashboard.url}
              route={adoptionDashboard.route}
              icon={AdoptionDashboardIcon}
            >
              <Trans>Adoption dashboard</Trans>
            </RoutedSideNavigationItem>
          )}
          <RoutedSideNavigationItem
            data-qa="SideMenuSecurityDashboard"
            to={securityDashboard.url}
            route={securityDashboard.route}
            icon={SecurityDashboardIcon}
          >
            <Trans>Security dashboard</Trans>
          </RoutedSideNavigationItem>
          {shouldShowMspPage && (
            <RoutedSideNavigationItem
              data-qa="SideMenuManagedCompanies"
              to={managedCompanies.url}
              route={managedCompanies.route}
              icon={PaperClipIcon}
            >
              <Trans>Managed companies</Trans>
            </RoutedSideNavigationItem>
          )}
        </SideNavigation>
      </SidebarContainer>
      <Switch>
        <Route {...adoptionDashboard.route}>
          <DashboardPage />
        </Route>
        <Route {...securityDashboard.route}>
          {isNewSecurityDashboardEnabled ? (
            <NewSecurityDashboardPage />
          ) : (
            <SecurityDashboardPage />
          )}
        </Route>
        {shouldShowMspPage && (
          <Route {...managedCompanies.route}>
            <ManagedCompaniesPage />
          </Route>
        )}
      </Switch>
    </>
  )
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as AddressIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/address.svg'
import { TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { ActionSuccess } from '@lastpass/admin-console-dependencies/ui/home/components/action-success/ActionSuccess'
import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

const Link = styled(TextButton)`
  padding: 0;
`

export interface StagedUsersCardProps {
  count: number
  dataQa?: string
}

export const StagedUsersCard: FC<StagedUsersCardProps> = ({
  count,
  dataQa
}) => (
  <ActionCard
    icon={AddressIcon}
    count={count}
    title={msg`Staged users`}
    description={
      <Trans>
        These users can’t enroll since they haven’t been sent an invitation.
      </Trans>
    }
    action={
      count === 0 ? (
        <ActionSuccess message={msg`You don’t have any staged users`} />
      ) : (
        <LocationLink to="/dashboard/invite-staged">
          <Link blue>
            <Trans>Invite staged users</Trans>
          </Link>
        </LocationLink>
      )
    }
    dataQa={dataQa}
  />
)

import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Autocomplete } from '@lastpass/lastkit/components/Autocomplete'

import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import {
  AdvancedUserActivityEventId,
  AdvancedUserActivityEventType
} from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'

const AutocompleteContainer = styled.div`
  display: flex;
  gap: 24px;
`

const Separator = styled.div`
  color: ${props => props.theme.colors.neutral600};
  font-size: 12px;
  font-weight: ${props => props.theme.fonts.weight.semibold};
  margin-bottom: 7px;
  padding-bottom: 2px;
  overflow: hidden;
  &::after {
    border-top: 1px solid ${props => props.theme.colors.neutral300};
    content: '';
    display: inline-block;
    margin-left: 5px;
    margin-right: -100%;
    vertical-align: middle;
    width: 100%;
  }
`
interface SelectEventsProps {
  updateUserEventSelection: (update: AdvancedUserActivityEventId[]) => void
  updateAdminEventSelection: (update: AdvancedUserActivityEventId[]) => void
  userEventFilters?: AdvancedUserActivityEventId[]
  adminEventFilters?: AdvancedUserActivityEventId[]
}

export const createEventMappings = (
  userEvents: AdvancedUserActivityEventType[],
  adminEvents: AdvancedUserActivityEventType[]
) => {
  const userEventMapping = {}
  const adminEventMapping = {}
  userEvents.forEach(
    userEvent => (userEventMapping[userEvent.value] = userEvent.label)
  )
  adminEvents.forEach(
    adminEvent => (adminEventMapping[adminEvent.value] = adminEvent.label)
  )

  return {
    userEventMapping,
    adminEventMapping
  }
}

export const SelectEvents: FunctionComponent<SelectEventsProps> = ({
  updateUserEventSelection,
  updateAdminEventSelection,
  userEventFilters = [],
  adminEventFilters = []
}) => {
  const {
    eventTypes: { userEvents, adminEvents }
  } = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )

  const { userEventMapping, adminEventMapping } = createEventMappings(
    userEvents,
    adminEvents
  )

  return (
    <>
      <Separator>
        <Trans>Event types</Trans>
      </Separator>
      <AutocompleteContainer>
        <Autocomplete
          data-qa={'UserEventsAutocomplete'}
          placeholder={i18n._(msg`Show all user events`)}
          searchPlaceholder={msg`Search user events`}
          options={userEvents}
          isMulti
          fluid
          value={userEventFilters.map(userEventValue => ({
            value: userEventValue,
            label: userEventMapping[userEventValue] || ''
          }))}
          onChange={selectedItems => {
            if (!Array.isArray(selectedItems)) {
              return
            }
            updateUserEventSelection(selectedItems.map(item => item.value))
          }}
        >
          <Trans>User event</Trans>
        </Autocomplete>
        <Autocomplete
          data-qa={'AdminEventsAutocomplete'}
          placeholder={i18n._(msg`Show all admin events`)}
          searchPlaceholder={msg`Search admin events`}
          options={adminEvents}
          isMulti
          fluid
          value={adminEventFilters.map(adminEventValue => ({
            value: adminEventValue,
            label: adminEventMapping[adminEventValue] || ''
          }))}
          onChange={selectedItems => {
            if (!Array.isArray(selectedItems)) {
              return
            }
            updateAdminEventSelection(selectedItems.map(item => item.value))
          }}
        >
          <Trans>Admin event</Trans>
        </Autocomplete>
      </AutocompleteContainer>
    </>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetURLRuleOptions(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getURLRuleOptions() {
    try {
      yield put(urlRulesActions.setURLRuleOptionsLoading(true))

      const urlRuleOptionsResult: UACServices.UrlRuleOptionsApi.Responses = yield call(
        enterpriseOptionServices.urlRuleOptions
      )

      yield put(
        urlRulesActions.setURLRuleOptions({
          isUrlRulesCaseInsensitive:
            urlRuleOptionsResult.body.isUrlRulesCaseInsensitive,
          isLoading: false
        })
      )

      yield put(urlRulesActions.setURLRuleOptionsLoading(false))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as PasswordlessEmptyLogo } from '@lastpass/assets/svg/admin-console/policies/passwordless-empty-logo.svg'
import { BodyRegular, Heading300, PrimaryButton } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import { PasswordlessDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { PostTrialMfaAlert } from '@lastpass/admin-console-dependencies/ui/global/PostTrialMfaAlert'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHeading = styled(Heading300)`
  margin-top: 32px;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: center;
  vertical-align: middle;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
`

const StyledBodyRegular = styled(BodyRegular)`
  display: flex;
  width: 55%;
  align-self: center;
`

const StyledLocationLink = styled.a`
  margin-top: 32px;
`

const StyledLogo = styled(PasswordlessEmptyLogo)`
  margin-top: 140px;
`

export const PasswordlessEmptyList = ({ actions }) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const dispatch = useDispatch()
  const isTrialEligible = CompanyDetailsHelper.isUserBusinessTrialEligible(
    companyDetails
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const postTrialAlert =
    !userHasMfaAddon && !isTrialEligible ? <PostTrialMfaAlert /> : null

  const onGetStartedClick = () => {
    if (CompanyDetailsHelper.isMspOrManaged(companyDetails)) {
      actions.showMfaUsageNotificationDialog(
        MfaUpsellDialogContext.passwordless,
        '/applications/passwordless/ssoapps/policy'
      )
      return
    }

    if (
      userHasMfaAddon ||
      CompanyDetailsHelper.isPermissionNull(companyDetails)
    ) {
      dispatch(
        passwordlessDrawerActions.setDrawerStep(
          PasswordlessDrawerSteps.description
        )
      )
      actions.passwordlessGetStarted(
        '/applications/passwordless/ssoapps/policy'
      )
      return
    }
    if (isTrialEligible) {
      actions.showMfaUpsellDialog(
        CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
        MfaUpsellDialogContext.passwordless
      )
    } else {
      actions.showMfaAddonUpsellDialog(
        MfaUpsellDialogContext.passwordless,
        CompanyDetailsHelper.isEcommEnabled(companyDetails) || true
      )
    }
  }

  return (
    <StyledContainer>
      <StyledLogo />
      <StyledHeading>
        <Trans>
          Streamline SSO app logins with passwordless authentication
        </Trans>
      </StyledHeading>
      <StyledTextContainer>
        <StyledBodyRegular>
          <Trans>
            Let your users leverage biometric authentication on their mobile
            devices to access SSO apps without a password.
          </Trans>
        </StyledBodyRegular>
      </StyledTextContainer>
      {postTrialAlert}
      <StyledPrimaryButton
        data-qa={'PasswordlessAddUsers'}
        onClick={onGetStartedClick}
      >
        <Trans>Get started</Trans>
      </StyledPrimaryButton>
      <StyledLocationLink
        href="https://link.lastpass.com/passwordless-authentication"
        data-qa={'LearnMoreAboutPasswordlessLink'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </StyledContainer>
  )
}

import React, { ReactElement } from 'react'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

interface DashboardDrawerComponentProps {
  closeLink: string
  title: ReactElement
}

export const DashboardDrawer: React.FunctionComponent<DashboardDrawerComponentProps> = props => {
  return (
    <Drawer mainTitle={props.title} closeLink={props.closeLink}>
      {props.children}
    </Drawer>
  )
}

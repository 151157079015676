import React, { useState } from 'react'

import styled from '@emotion/styled'

interface ImageWithFallbackProps {
  src: string | undefined
  fallbackSrc?: string
  alt: string | undefined
  title?: string
  height?: number
  width?: number
}

export const ImageWithFallback = ({
  src,
  fallbackSrc,
  alt,
  title,
  height,
  width
}: ImageWithFallbackProps) => {
  const [error, setError] = useState(false)
  const onError = () => {
    setError(true)
  }
  const primarySrc = error ? undefined : src
  const Placeholder = styled.span`
    height: ${height}px;
    width: ${width}px;
    padding: inherit;
    margin: inherit;
  `

  if (primarySrc || fallbackSrc) {
    return (
      <img
        src={error && fallbackSrc ? fallbackSrc : primarySrc}
        alt={alt}
        title={title}
        height={height}
        width={width}
        onError={error ? undefined : onError}
      />
    )
  } else {
    return <Placeholder />
  }
}

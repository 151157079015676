export const convertFileToBase64String = file => {
  return new Promise(function(resolve) {
    const reader = new FileReader()
    reader.onload = function() {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

export const convertBase64StringToFile = b64String => {
  return fetch(b64String)
    .then(function(res) {
      return res.arrayBuffer()
    })
    .then(function(buf) {
      return new File([buf], 'file')
    })
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { CaptionRegularStyle } from '@lastpass/lastkit'

import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { getAccountStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/AccountStatusMapping'

const BoldText = styled.span`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const TooltipStyle = styled.div`
  ${CaptionRegularStyle};
  line-height: 16px;
  width: 100%;
  text-align: left;

  & ul {
    list-style-type: disc;

    & li {
      margin-left: 16px;
    }
  }
`

export const StatusTooltip: FC = () => {
  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )
  const accountStatusMapping = getAccountStatusMapping(isAccountStatusRenamed)

  return (
    <TooltipStyle>
      <Trans>
        <BoldText>LastPass status</BoldText> shows progress on the onboarding
        journey:
      </Trans>
      <br />
      <ul>
        <li>
          <Trans>
            <BoldText>Enrolled</BoldText> (formerly Active): The user has joined
            your account and logged in at least once.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Pending invitation</BoldText> (formerly Invited): An email
            has been sent, but the user hasn’t yet enrolled.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>{accountStatusMapping.expiredInvitation}</BoldText>: The
            user didn{"'"}t enroll in time. Send them a new invitation.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>{accountStatusMapping.staged}</BoldText>: The user’s
            account has been set up, but no invitation email has been sent.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>{accountStatusMapping.disabled}</BoldText>: The user is
            part of your account, but their access has been disabled by an
            admin.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>{accountStatusMapping.awaitingApproval}</BoldText>: An
            admin must manually approve the user after import from the AD Sync
            client.
          </Trans>
        </li>
      </ul>
    </TooltipStyle>
  )
}

export const MfaStatusTooltip: FC = () => {
  return (
    <TooltipStyle>
      <Trans>
        <BoldText>Passwordless status</BoldText> shows passwordless onboarding
        progress:
      </Trans>
      <br />
      <ul>
        <li>
          <Trans>
            <BoldText>Active</BoldText>: The user has successfully enrolled for
            passwordless authentication and paired the LastPass Authenticator
            mobile app to their LastPass account.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Invited</BoldText>: The user has received an invitation to
            enroll for passwordless authentication and pair the LastPass
            Authenticator mobile app to their LastPass account.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Locked</BoldText>: The user{"'"}s LastPass Authenticator
            mobile app is locked because either an admin has manually restricted
            their access or suspicious activity was detected.
          </Trans>
        </li>
      </ul>
    </TooltipStyle>
  )
}

export const MfaTooltip: FC = () => {
  return (
    <TooltipStyle>
      <Trans>
        <BoldText>Enabled multifactor</BoldText> shows the active multifactor
        tool for each user. Users must verify their identity with this tool
        after authenticating with their master password.
      </Trans>
      <br />
      <Trans>
        To choose which multifactor tools users can activate, go to{' '}
        <BoldText>
          Advanced &gt; Enterprise options &gt; Multifactor options
        </BoldText>
        . To require use of a specific multifactor tool, go to{' '}
        <BoldText>Policies &gt; Multifactor</BoldText>.
      </Trans>
    </TooltipStyle>
  )
}

export const AdminLevelTooltip: FC = () => {
  return (
    <TooltipStyle>
      <Trans>Admin levels:</Trans>
      <br />
      <ul>
        <li>
          <Trans>
            <BoldText>Admins</BoldText> can access the entire Admin Console and
            have extensive rights to deploy, configure, and manage LastPass
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Helpdesk admins</BoldText> have limited rights
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Super admins</BoldText> can reset the master password of
            any user in your account, and they have extensive rights to deploy,
            configure, and manage LastPass
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Custom admins</BoldText> have whatever specific
            permissions you choose for them
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Legacy helpdesk admins</BoldText> can manage LastPass
            according to permissions set in the legacy version of the Admin
            Console
          </Trans>
        </li>
      </ul>
    </TooltipStyle>
  )
}

export const FederatedStatusTooltip: FC = () => {
  return (
    <TooltipStyle data-qa="FederatedStatusTooltip">
      <Trans>
        <BoldText>Federated status:</BoldText>
      </Trans>
      <br />
      <ul>
        <li>
          <Trans>
            <BoldText>Federated:</BoldText> The user logs in to LastPass through
            a federated identity provider.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Not federated:</BoldText> The user logs in to LastPass
            using their master password and hasn{"'"}t yet been selected for
            federation in the Admin Console.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Selected:</BoldText> An admin has selected the user for
            federation in the Admin Console.
          </Trans>
        </li>
        <li>
          <Trans>
            <BoldText>Not eligible:</BoldText> An admin added the user to
            LastPass manually; the user can{"'"}t use a federated identity
            provider to log in to LastPass.
          </Trans>
        </li>
      </ul>
    </TooltipStyle>
  )
}

export const SecurityScoreTooltip = () => (
  <TooltipStyle data-qa="SecurityScoreTooltip">
    <Trans>
      <BoldText>Security score</BoldText> is an aggregate measurement that
      factors in the strength of a user{"'"}s master password and passwords in
      their vault.
      <br />
      For details, go to{' '}
      <BoldText>Account details &gt; View full account details</BoldText> for
      any user.
    </Trans>
  </TooltipStyle>
)

export const MasterPasswordResetRightsTooltip = () => {
  return (
    <TooltipStyle data-qa="MasterPasswordResetRightsTooltip">
      <Trans>
        <BoldText>Master password reset rights</BoldText>
        <br />
        See who has the right to reset the user’s master password. To learn more
        about resetting master passwords, visit{' '}
        <a
          href="https://link.lastpass.com/help-super-admin-reset-mp"
          target="_blank"
          rel="noopener noreferrer"
        >
          this support article.
        </a>
      </Trans>
    </TooltipStyle>
  )
}

import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'
import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'
import { TrustedDeviceType } from '@lastpass/admin-console-dependencies/types/trusted-device'

import { UserDrawerActions, UserDrawerActionTypes } from './actions'

export const initialState: UsersDrawerState = {
  profile: {
    id: '',
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    department: '',
    customAttributes: [],
    isRecoveryKeySharingAllowed: false
  },
  userLevels: [],
  accountDetails: {
    linkedAccountEnabled: false,
    securityScoreLevel: SecurityScoreLevel.na,
    accountStatus: AccountStatus.na,
    mfaStatus: MFAStatus.na,
    provisionFederatedStatus: FederatedStatus.notEligibleForFederation,
    inviteEmailSentTimeUtc: undefined,
    defaultMultiFactorType: undefined,
    adminLevel: { id: '', name: '', isCustom: false },
    lastLoginTimeUtc: '',
    superAdminKeyOwnershipForUser: null
  },
  apps: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true
  },
  groups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true
  },
  sites: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true
  },
  policies: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true
  },
  sharedFolders: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true
  },
  operations: [],
  isOperationsLoading: false,
  trustedDevices: {
    mfa: {
      results: [],
      resultsPerPage: 25,
      totalResults: 0,
      checkedRecords: [],
      isLoading: true
    },
    passwordManager: {
      results: [],
      resultsPerPage: 25,
      totalResults: 0,
      checkedRecords: [],
      isLoading: true
    },
    workstation: {
      results: [],
      resultsPerPage: 25,
      totalResults: 0,
      checkedRecords: [],
      isLoading: true
    }
  }
}

export function usersDrawerReducer(
  previousState: UsersDrawerState | undefined,
  action: UserDrawerActions
): UsersDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case UserDrawerActionTypes.RESET: {
      return initialState
    }
    // User Profile
    case UserDrawerActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        profile: action.payload
      }
    }
    case UserDrawerActionTypes.SET_USER_PROFILE_LOADING: {
      return {
        ...state,
        loadingProfile: action.payload.loading
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_PROFILE: {
      return {
        ...state,
        profile: initialState.profile,
        accountDetails: initialState.accountDetails
      }
    }
    // Account Details
    case UserDrawerActionTypes.SET_ACCOUNT_DETAILS: {
      return {
        ...state,
        accountDetails: action.payload
      }
    }
    case UserDrawerActionTypes.SET_ACCOUNT_DETAILS_LOADING: {
      return {
        ...state,
        accountDetailsLoading: action.payload.loading
      }
    }
    // SSO Apps
    case UserDrawerActionTypes.SET_USER_APPS: {
      return {
        ...state,
        apps: {
          results: action.payload.apps,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case UserDrawerActionTypes.SET_USER_APPS_LOADING: {
      return {
        ...state,
        apps: {
          ...state.apps,
          isLoading: action.payload.loading
        }
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_APPS: {
      return {
        ...state,
        apps: initialState.apps
      }
    }

    // Groups
    case UserDrawerActionTypes.SET_USER_GROUPS: {
      return {
        ...state,
        groups: {
          results: action.payload.groups,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case UserDrawerActionTypes.SET_USER_GROUPS_LOADING: {
      return {
        ...state,
        groups: {
          ...state.groups,
          isLoading: action.payload.loading
        }
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_GROUPS: {
      return {
        ...state,
        groups: initialState.groups
      }
    }

    // SITES
    case UserDrawerActionTypes.SET_USER_SITES: {
      return {
        ...state,
        sites: {
          results: action.payload.apps,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case UserDrawerActionTypes.SET_USER_SITES_LOADING: {
      return {
        ...state,
        sites: {
          ...state.sites,
          isLoading: action.payload.loading
        }
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_SITES: {
      return {
        ...state,
        sites: initialState.sites
      }
    }

    // Policies
    case UserDrawerActionTypes.SET_USER_POLICIES: {
      return {
        ...state,
        policies: {
          results: action.payload.policies,
          totalResults: action.payload.totalResults,
          resultsPerPage: 25,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case UserDrawerActionTypes.SET_USER_POLICIES_LOADING: {
      return {
        ...state,
        policies: {
          ...state.policies,
          isLoading: action.payload.loading
        }
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_POLICIES: {
      return {
        ...state,
        policies: initialState.policies
      }
    }
    // Shared folders
    case UserDrawerActionTypes.SET_USER_SHARED_FOLDERS: {
      return {
        ...state,
        sharedFolders: {
          results: action.payload.sharedFolders,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case UserDrawerActionTypes.SET_USER_SHARED_FOLDERS_LOADING: {
      return {
        ...state,
        sharedFolders: {
          ...state.sharedFolders,
          isLoading: action.payload.loading
        }
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_SHARED_FOLDERS: {
      return {
        ...state,
        sharedFolders: initialState.sharedFolders
      }
    }
    // USER LEVELS
    case UserDrawerActionTypes.SET_USER_LEVELS: {
      return {
        ...state,
        userLevels: action.payload
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_LEVELS: {
      return {
        ...state,
        userLevels: initialState.userLevels
      }
    }
    // Trusted devices
    case UserDrawerActionTypes.SET_USER_TRUSTED_DEVICES: {
      const summaryList = action.payload.lastPassApplications
        .map(pw => ({
          device: pw.type,
          type: TrustedDeviceType.lastPassApplications,
          status: pw.isEnabled
        }))
        .concat(
          action.payload.lastPassMfaApplications.map(mfa => ({
            device: mfa.name,
            type: TrustedDeviceType.lastPassMfaApplications,
            status: mfa.isEnabled
          }))
        )
        .concat(
          action.payload.workstations.map(workstation => ({
            device: workstation.name,
            type: TrustedDeviceType.workstations,
            status: true
          }))
        )
        .sort((a, b) => a.device.localeCompare(b.device))

      return {
        ...state,
        trustedDevices: {
          all: {
            results: summaryList.slice(0, 5),
            totalResults: summaryList.length,
            resultsPerPage: 25,
            checkedRecords: [],
            isLoading: false
          },
          passwordManager: {
            results: action.payload.lastPassApplications,
            totalResults: action.payload.lastPassApplications.length,
            resultsPerPage: 25,
            checkedRecords: [],
            isLoading: false
          },
          mfa: {
            results: action.payload.lastPassMfaApplications,
            totalResults: action.payload.lastPassMfaApplications.length,
            resultsPerPage: 25,
            checkedRecords: [],
            isLoading: false
          },
          workstation: {
            results: action.payload.workstations,
            totalResults: action.payload.workstations.length,
            resultsPerPage: 25,
            checkedRecords: [],
            isLoading: false
          }
        }
      }
    }
    case UserDrawerActionTypes.SET_USER_TRUSTED_DEVICES_LOADING: {
      if (
        state.trustedDevices.all &&
        state.trustedDevices.passwordManager &&
        state.trustedDevices.mfa &&
        state.trustedDevices.workstation
      ) {
        const trustedDevices = state.trustedDevices
        const allTrustedDevices = state.trustedDevices.all
        const lpTrustedDevices = state.trustedDevices.passwordManager
        const mfaTrustedDevices = state.trustedDevices.mfa
        const workstationTrustedDevices = state.trustedDevices.workstation
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            all: {
              ...allTrustedDevices,
              isLoading: action.payload.loading
            },
            passwordManager: {
              ...lpTrustedDevices,
              isLoading: action.payload.loading
            },
            mfa: {
              ...mfaTrustedDevices,
              isLoading: action.payload.loading
            },
            workstation: {
              ...workstationTrustedDevices,
              isLoading: action.payload.loading
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.SET_SELECTED_LP_DEVICES: {
      if (state.trustedDevices.passwordManager) {
        const trustedDevices = state.trustedDevices
        const trustedPasswordManagers = state.trustedDevices.passwordManager
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            passwordManager: {
              ...trustedPasswordManagers,
              checkedRecords: action.payload.devices
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.ADD_SELECTED_LP_DEVICES: {
      if (state.trustedDevices.passwordManager) {
        const trustedDevices = state.trustedDevices
        const trustedPasswordManagers = state.trustedDevices.passwordManager
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            passwordManager: {
              ...trustedPasswordManagers,
              checkedRecords: trustedPasswordManagers.checkedRecords.concat(
                action.payload.devices
              )
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.REMOVE_SELECTED_LP_DEVICES: {
      if (state.trustedDevices.passwordManager) {
        const trustedDevices = state.trustedDevices
        const trustedPasswordManagers = state.trustedDevices.passwordManager
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            passwordManager: {
              ...trustedPasswordManagers,
              checkedRecords: trustedPasswordManagers.checkedRecords.filter(
                el => !action.payload.devices.includes(el)
              )
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.SET_SELECTED_MFA_DEVICES: {
      if (state.trustedDevices.mfa) {
        const trustedDevices = state.trustedDevices
        const trustedMfaDevices = state.trustedDevices.mfa
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            mfa: {
              ...trustedMfaDevices,
              checkedRecords: action.payload.mfaDevices
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.ADD_SELECTED_MFA_DEVICES: {
      if (state.trustedDevices.mfa) {
        const trustedDevices = state.trustedDevices
        const trustedMfaDevices = state.trustedDevices.mfa
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            mfa: {
              ...trustedMfaDevices,
              checkedRecords: trustedMfaDevices.checkedRecords.concat(
                action.payload.mfaDevices
              )
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.REMOVE_SELECTED_MFA_DEVICES: {
      if (state.trustedDevices.mfa) {
        const trustedDevices = state.trustedDevices
        const trustedMfaDevices = state.trustedDevices.mfa
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            mfa: {
              ...trustedMfaDevices,
              checkedRecords: trustedMfaDevices.checkedRecords.filter(
                el => !action.payload.mfaDevices.includes(el)
              )
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.SET_SELECTED_WORKSTATIONS: {
      if (state.trustedDevices.workstation) {
        const trustedDevices = state.trustedDevices
        const trustedWorkstations = state.trustedDevices.workstation
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            workstation: {
              ...trustedWorkstations,
              checkedRecords: action.payload.workstations
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.ADD_SELECTED_WORKSTATIONS: {
      if (state.trustedDevices.workstation) {
        const trustedDevices = state.trustedDevices
        const trustedWorkstations = state.trustedDevices.workstation
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            workstation: {
              ...trustedWorkstations,
              checkedRecords: trustedWorkstations.checkedRecords.concat(
                action.payload.workstations
              )
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.REMOVE_SELECTED_WORKSTATIONS: {
      if (state.trustedDevices.workstation) {
        const trustedDevices = state.trustedDevices
        const trustedWorkstations = state.trustedDevices.workstation
        return {
          ...state,
          trustedDevices: {
            ...trustedDevices,
            workstation: {
              ...trustedWorkstations,
              checkedRecords: trustedWorkstations.checkedRecords.filter(
                el => !action.payload.workstations.includes(el)
              )
            }
          }
        }
      }
      break
    }
    case UserDrawerActionTypes.CLEAR_USER_TRUSTED_DEVICES: {
      return {
        ...state,
        trustedDevices: initialState.trustedDevices
      }
    }
    // USER OPERATIONS
    case UserDrawerActionTypes.SET_USER_OPERATIONS: {
      return {
        ...state,
        operations: action.payload
      }
    }
    case UserDrawerActionTypes.CLEAR_USER_OPERATIONS: {
      return {
        ...state,
        operations: initialState.operations
      }
    }
    case UserDrawerActionTypes.SET_USER_OPERATIONS_LOADING: {
      return {
        ...state,
        isOperationsLoading: action.payload
      }
    }
  }
  return state
}

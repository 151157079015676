import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { AdAttributeState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(adAttributeState: AdAttributeState) {
    try {
      const attributeName = adAttributeState.adAttributeName
      const [response, status] = await fetch(
        'company/adfs-attribute-name',
        'POST',
        attributeName,
        { 'content-type': 'application/json' }
      )
      const responseJson =
        status === StatusCodes.OK && response && JSON.parse(response)
      if (responseJson && responseJson.status === 'ok') {
        return success()
      } else {
        return fail()
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

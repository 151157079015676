import React, { FunctionComponent, ImgHTMLAttributes } from 'react'

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  src2x: string
}

const RETINA_RATIO = 1.25

export const RetinaImage: FunctionComponent<Props> = ({
  src,
  src2x,
  ...props
}) => {
  if (window.devicePixelRatio > RETINA_RATIO) {
    return <img src={src2x} {...props} />
  }
  return <img src={src} {...props} />
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { addNewGroupActions } from '@lastpass/admin-console-dependencies/state/users/groups/add/actions'
import { UserDrawerProps } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawer'

export const AddGroupDrawer: React.FunctionComponent<UserDrawerProps> = ({
  closeLink,
  children
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(addNewGroupActions.reset())
    }
  }, [dispatch])

  return (
    <Drawer
      mainTitle={<></>}
      subTitle={<Trans>New group</Trans>}
      closeLink={closeLink}
    >
      {children}
    </Drawer>
  )
}

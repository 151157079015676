import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { addNewGroupActions } from '@lastpass/admin-console-dependencies/state/users/groups/add/actions'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  width: 100%;
`

export const NewGroupForm: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  const newGroupFormState = useSelector(
    (state: AppState) => state.newGroupDrawer.newGroupForm
  )
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const form = useRef<FormikProps<{ groupName: string }>>(null)
  useEffect(() => {
    if (form.current) {
      if (newGroupFormState.validitySuccess) {
        form.current.submitForm()
      } else if (newGroupFormState.validityFail) {
        form.current.validateField('groupName')
      }
    }
  }, [newGroupFormState.validitySuccess, newGroupFormState.validityFail])
  const validateGroupName = (value?: string) =>
    !!value &&
    value.trim().length > 0 &&
    !newGroupFormState.validitySuccess &&
    newGroupFormState.validityFail

  return (
    <Formik
      initialValues={{ groupName: '' }}
      enableReinitialize={true}
      validateOnBlur={false}
      innerRef={form}
      onSubmit={() => {
        setIsBlockingPromptEnabled(false)
      }}
    >
      {formikProps => {
        return (
          <>
            {newGroupFormState.isLoading && <Loading color="blue900" active />}
            <Form>
              <FormContainer
                onChange={() => {
                  setIsBlockingPromptEnabled(true)
                }}
              >
                <InputContainer>
                  <Field name="groupName" validate={validateGroupName}>
                    {formData => (
                      <TextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        placeholder={msg`Required`}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                        error={!!formikProps.errors.groupName}
                        errorText={
                          formikProps.errors.groupName ? (
                            <Trans>
                              There{"'"}s already a group with that name
                            </Trans>
                          ) : (
                            undefined
                          )
                        }
                        data-qa="NewGroupInputGroupNameField"
                      >
                        <Trans>Group name</Trans>
                      </TextInput>
                    )}
                  </Field>
                </InputContainer>
              </FormContainer>

              <FooterContainer>
                <DrawerButtonsContainer>
                  <LocationLink to={`/users/groups`}>
                    <TextButton
                      type="button"
                      css={css`
                        margin-right: 16px;
                      `}
                      data-qa="NewGroupCancelButton"
                    >
                      <Trans>Cancel</Trans>
                    </TextButton>
                  </LocationLink>
                  <TextButton
                    blue
                    css={css`
                      margin-right: 16px;
                    `}
                    disabled={
                      !formikProps.values[Object.keys(formikProps.values)[0]]
                    }
                    onClick={() => {
                      dispatch(
                        addNewGroupActions.addNewGroup(
                          formikProps.values[
                            Object.keys(formikProps.values)[0]
                          ],
                          false
                        )
                      )
                    }}
                    type="submit"
                    data-qa="NewGroupSaveAndExitButton"
                  >
                    <Trans>Save</Trans>
                    {' & '} <Trans>Exit</Trans>
                  </TextButton>
                  <PrimaryButton
                    disabled={
                      !formikProps.values[Object.keys(formikProps.values)[0]]
                    }
                    type="button"
                    onClick={() => {
                      setIsBlockingPromptEnabled(false)
                      dispatch(
                        addNewGroupActions.addNewGroup(
                          formikProps.values[
                            Object.keys(formikProps.values)[0]
                          ],
                          true
                        )
                      )
                    }}
                    data-qa="NewGroupSaveAndAssignUsersButton"
                  >
                    <Trans>Save</Trans>
                    {' & '}
                    <Trans>Assign users</Trans>
                  </PrimaryButton>
                </DrawerButtonsContainer>
              </FooterContainer>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

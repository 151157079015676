import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading100 } from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'
import { NumberedList } from '@lastpass/lastkit/components/NumberedList'
import { BodyRegularStyle, BodySemiboldStyle } from '@lastpass/lastkit/styles'

const TextContent = styled.div`
  ${BodyRegularStyle}
`

const PageContentContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
  max-width: 760px;
`

const PageItemContainer = styled(Heading100)`
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 0 24px;
`

const StyledTextContent = styled(TextContent)`
  margin-bottom: 15px;
  white-space: nowrap;
`
const Title = styled.div`
  ${BodySemiboldStyle};
  margin-bottom: 8px;
`

export const MacManualPageContainer: React.FunctionComponent = () => {
  const appleStoreLink = 'https://link.lastpass.com/lastpass-mac-app-download'
  return (
    <>
      <PageItemContainer data-qa={'PageItemContainerMacOsGuiInstall'}>
        <Trans>How to manually install the LastPass Mac App</Trans>
      </PageItemContainer>
      <PageContentContainer>
        <Title>
          <Trans>Share these instructions with your users:</Trans>
        </Title>
        <StyledTextContent>
          <NumberedList
            items={[
              <Trans key="1">Make sure you have an Apple ID.</Trans>,
              <>
                <Trans key="2">
                  Visit the LastPass Password Manager page in the Apple App
                  Store:
                </Trans>
                <a
                  href={appleStoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'  '} {appleStoreLink}
                </a>
              </>,
              <Trans key="3">To install the app, select GET.</Trans>
            ]}
          />
        </StyledTextContent>
        <StyledTextContent>
          <Trans>
            Pro tip: You may want to remind users that they can uninstall the
            app by moving LastPass from the Applications folder to the Trash.
          </Trans>
        </StyledTextContent>
      </PageContentContainer>
    </>
  )
}

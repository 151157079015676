import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'
export const PARTIAL_SUCCESS = 'partialSuccess'
export const FAILURE = 'failure'

export interface FailedReason {
  [key: string]: string
}

const success = () => createResponse(SUCCESS)
const partialSuccess = (failedUsers: FailedReason) =>
  createResponse(PARTIAL_SUCCESS, { failedUsers })
const fail = () => createResponse(FAILURE)

export const Responses = {
  success,
  partialSuccess,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    selectedUsers: User[],
    adminLevelId: string,
    fetchMethod: string
  ) {
    const [response, status] = await fetch(
      `bulk/adminLevels/${adminLevelId}/users`,
      fetchMethod,
      selectedUsers.map(user => user.id),
      { 'content-type': 'application/json' }
    )

    if (status === StatusCodes.OK) {
      const result = JSON.parse(response)
      const { status, failureReasons } = result.content

      switch (status) {
        case SUCCESS: {
          return success()
        }
        case PARTIAL_SUCCESS: {
          return partialSuccess(failureReasons)
        }
        case FAILURE: {
          return fail()
        }
      }
    }

    throw new UnknownServiceError()
  }
}

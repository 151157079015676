import styled from '@emotion/styled'

import { CaptionBoldStyle } from '../styles'
import { ThemeColor } from '../theme'

export interface BadgeStatus {
  colors: {
    backgroundColor: ThemeColor
    textColor: ThemeColor
  }
  size?: string
}

export const Badge = styled.div<BadgeStatus>`
  ${CaptionBoldStyle}
  display: inline-block;
  outline: none;
  padding: 0 16px;
  min-width: 80px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: ${props => props.theme.radius.full};
  background-color: ${props =>
    props.theme.colors[props.colors.backgroundColor]};
  color: ${props => props.theme.colors[props.colors.textColor]};
`

import React from 'react'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

export interface AddPolicyDrawerProps {
  closeLink?: string
}

export const AddPolicyDrawer: React.FunctionComponent<AddPolicyDrawerProps> = ({
  closeLink,
  ...props
}) => (
  <Drawer
    mainTitle={<Trans>New policy</Trans>}
    subTitle={<Trans>New policy</Trans>}
    closeLink={closeLink ? closeLink : ''}
  >
    {props.children}
  </Drawer>
)

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular, Heading300 } from '@lastpass/lastkit'

import { acronisApiActions } from '@lastpass/admin-console-dependencies/state/advanced/acronis-api/actions'
import { AcronisHash } from '@lastpass/admin-console-dependencies/ui/advanced/acronisApi/components/AcronisHash'

const PageItemContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 18px;
`

export const AcronisApiPage: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(acronisApiActions.getAcronisApi())
  }, [dispatch])

  return (
    <>
      <PageItemContainer>
        <Heading300 data-qa="AcronisApiPageHeader">
          <Trans>Acronis Cyber Protect</Trans>
        </Heading300>
        <BodyRegular>
          <Trans>
            Manage LastPass users, reset master passwords, control multifactor,
            and destroy user sessions directly from Acronis Cyber Protect.
          </Trans>
        </BodyRegular>
      </PageItemContainer>
      <PageItemContainer>
        <AcronisHash />
      </PageItemContainer>
    </>
  )
}

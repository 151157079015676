import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getSecurityScore } from '@lastpass/admin-console-dependencies/services/seurity-score'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { sharedFolderListActions } from '@lastpass/admin-console-dependencies/state/shared-folders/list/actions'

export function createGetSharedFolders(
  sharedFolderServices: UACServices.Services
) {
  return function* getSharedFolders() {
    try {
      yield put(sharedFolderListActions.setSharedFolderListLoading(true))
      const sharedFolderResult: UACServices.ShareFoldersAPI.Responses = yield call(
        sharedFolderServices.sharedFoldersGet
      )
      const result = sharedFolderResult.body.sharedfolders.map(sharedFolder => {
        return {
          shareid: sharedFolder.shareid,
          name: sharedFolder.name,
          securityScore: getSecurityScore(sharedFolder.score),
          numberOfApps: sharedFolder.itemscount,
          totalusers: Number.parseInt(sharedFolder.totalusers),
          deleted: Number.parseInt(sharedFolder.deleted)
        }
      })

      yield put(
        sharedFolderListActions.setSharedFolderList(result, result.length, 0)
      )

      yield put(sharedFolderListActions.setSharedFolderListLoading(false))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

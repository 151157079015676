export enum KeyRotationErrorPostfixes {
  ConnectionError = '.CE',
  BatchLimitExceeded = '.BL',
  AccessDenied = '.AD'
}

export enum KeyRotationErrors {
  IdpGetUsersRequestFailed = 'R.IDP.E01',
  IdpGetUsersRequestConnectionFailed = 'R.IDP.E01.CE',
  IdpGetUsersRequestAccessDenied = 'R.IDP.E01.AD',
  IdpBatchUpdateUsersFailed = 'R.IDP.E02',
  IdpBatchUpdateUsersConnectionFailed = 'R.IDP.E02.CE',
  IdpBatchUpdateUsersBatchLimitExceeded = 'R.IDP.E02.BL',
  IdpBatchUpdateUsersAccessDenied = 'R.IDP.E02.AD',
  IdpBatchUpdateUsersFailedCleanup = 'R.IDP.E03',
  IdpBatchUpdateUsersConnectionFailedCleanup = 'R.IDP.E03.CE',
  IdpBatchUpdateUsersBatchLimitExceededCleanup = 'R.IDP.E03.BL',
  IdpBatchUpdateUsersAccessDeniedCleanup = 'R.IDP.E03.AD',
  IdpCleanupPartiallySuccessful = 'R.IDP.E04',

  AlpGetSessionTokenRequestFailed = 'R.ALP.E01',
  AlpGetSessionTokenRequestConnectionFailed = 'R.ALP.E01.CE',
  AlpGetSessionTokenRequestConflict = 'R.ALP.E02',
  AlpGetKeyRotationDataRequestFailed = 'R.ALP.E03',
  AlpGetKeyRotationDataRequestConnectionFailed = 'R.ALP.E03.CE',
  AlpSaveKeyRotationDataRequestFailed = 'R.ALP.E04',
  AlpSaveKeyRotationDataRequestConnectionFailed = 'R.ALP.E04.CE',
  AlpSyncKeyRotationDataRequestFailed = 'R.ALP.E05',
  AlpSyncKeyRotationDataRequestConnectionFailed = 'R.ALP.E05.CE',

  AllFragmentIdValidationFailed = 'R.ROT.E03',
  KeyRotationMpMissmatchError = 'R.ROT.E04',
  AlpSyncFailedIdpDataSuccessfullyReverted = 'R.ROT.E05',
  AlpSyncFailedIdpDataPartiallyReverted = 'R.ROT.E06',
  AlpSyncAndIdpRevertFailed = 'R.ROT.E07',
  AlpSyncAndIdpRevertConnectionFailed = 'R.ROT.E07.CE',
  AlpSyncAndIdpRevertBatchLimitExceeded = 'R.ROT.E07.BL',
  AlpSyncAndIdpRevertAccessDenied = 'R.ROT.E07.AD',
  UnsynchronisedUsersAlpSyncFailed = 'R.ROT.E08',
  UnsynchronisedUsersAlpSyncConnectionFailed = 'R.ROT.E08.CE',
  UnsynchronisedUsersIdpBatchUpdateFailed = 'R.ROT.E09',
  UnsynchronisedUsersIdpBatchUpdateConnectionFailed = 'R.ROT.E09.CE',
  UnsynchronisedUsersIdpBatchUpdateBatchLimitExceeded = 'R.ROT.E09.BL',
  UnsynchronisedUsersIdpBatchUpdateAccesDenied = 'R.ROT.E09.AD'
}

export const FederationErrorsDescriptions: Record<KeyRotationErrors, string> = {
  [KeyRotationErrors.IdpGetUsersRequestFailed]: 'Idp get users request failed.',
  [KeyRotationErrors.IdpGetUsersRequestConnectionFailed]:
    'Idp get users request connection failed.',
  [KeyRotationErrors.IdpGetUsersRequestAccessDenied]:
    'Idp get users request failed due to access denied.',
  [KeyRotationErrors.IdpBatchUpdateUsersFailed]:
    'Idp batch update users K1 + self-healing data request failed.',
  [KeyRotationErrors.IdpBatchUpdateUsersConnectionFailed]:
    'Idp batch update users K1 + self-healing data request connection failed.',
  [KeyRotationErrors.IdpBatchUpdateUsersBatchLimitExceeded]:
    'Maximum batch limit is exceeded in idp batch update users K1 + self-healing data.',
  [KeyRotationErrors.IdpBatchUpdateUsersAccessDenied]:
    'Access denied in idp batch update users K1 + self-healing data.',
  [KeyRotationErrors.IdpBatchUpdateUsersFailedCleanup]:
    'Idp batch update users request failed. (cleanup self-healing data)',
  [KeyRotationErrors.IdpBatchUpdateUsersConnectionFailedCleanup]:
    'Idp batch update users request connection failed. (cleanup self-healing data)',
  [KeyRotationErrors.IdpBatchUpdateUsersBatchLimitExceededCleanup]:
    'Maximum batch limit is exceeded in idp batch update. (cleanup self-healing data)',
  [KeyRotationErrors.IdpBatchUpdateUsersAccessDeniedCleanup]:
    'Access denied in idp batch update. (cleanup self-healing data)',
  [KeyRotationErrors.IdpCleanupPartiallySuccessful]:
    'Idp cleanup partially successful.)',

  [KeyRotationErrors.AlpGetSessionTokenRequestFailed]:
    'Alp get session token request failed.',
  [KeyRotationErrors.AlpGetSessionTokenRequestConnectionFailed]:
    'Alp get session token request connection failed.',
  [KeyRotationErrors.AlpGetSessionTokenRequestConflict]:
    'Alp get session token request failed with conflict.',
  [KeyRotationErrors.AlpGetKeyRotationDataRequestFailed]:
    'Alp get rotation data request failed.',
  [KeyRotationErrors.AlpGetKeyRotationDataRequestConnectionFailed]:
    'Alp get rotation data request connection failed.',
  [KeyRotationErrors.AlpSaveKeyRotationDataRequestFailed]:
    'Alp save key rotation data request failed.',
  [KeyRotationErrors.AlpSaveKeyRotationDataRequestConnectionFailed]:
    'Alp save key rotation data request connection failed.',
  [KeyRotationErrors.AlpSyncKeyRotationDataRequestFailed]:
    'Alp sync key rotation data request failed.',
  [KeyRotationErrors.AlpSyncKeyRotationDataRequestConnectionFailed]:
    'Alp sync key rotation data request connection failed.',

  [KeyRotationErrors.AllFragmentIdValidationFailed]:
    'All fragment-id validation failed.',
  [KeyRotationErrors.KeyRotationMpMissmatchError]:
    'Old and new master passwords do not match.',
  [KeyRotationErrors.AlpSyncFailedIdpDataSuccessfullyReverted]:
    'ALP sync failed. IDP data successfully reverted.',
  [KeyRotationErrors.AlpSyncFailedIdpDataPartiallyReverted]:
    'ALP sync failed. IDP data partially reverted.',
  [KeyRotationErrors.AlpSyncAndIdpRevertFailed]:
    'ALP sync and IDP revert failed.',
  [KeyRotationErrors.AlpSyncAndIdpRevertConnectionFailed]:
    'ALP sync and IDP revert connection failed.',
  [KeyRotationErrors.AlpSyncAndIdpRevertBatchLimitExceeded]:
    'ALP sync and IDP revert failed due to batch limit is exceeded.',
  [KeyRotationErrors.AlpSyncAndIdpRevertAccessDenied]:
    'ALP sync and IDP revert failed due to access denied.',
  [KeyRotationErrors.UnsynchronisedUsersAlpSyncFailed]:
    'ALP sync request failed during unsynchronised users fix.',
  [KeyRotationErrors.UnsynchronisedUsersAlpSyncConnectionFailed]:
    'ALP sync request connection failed during unsynchronised users fix.',
  [KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateFailed]:
    'Idp batch update request failed during unsynchronised users fix.',
  [KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateConnectionFailed]:
    'Idp batch update request connection failed during unsynchronised users fix.',
  [KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateBatchLimitExceeded]:
    'Idp batch limit is exceeded during unsynchronised users fix.',
  [KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateAccesDenied]:
    'Idp batch update access denied during unsynchronised users fix.'
}

import React from 'react'
import { matchPath, Route, Switch, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import SwitchTransition from 'react-transition-group/SwitchTransition'

export const AnimatedSwitchClass = 'route'

export const AnimatedSwitch: React.FunctionComponent<CSSTransition['props']> = ({
  children,
  ...props
}) => {
  const location = useLocation()
  // Use the matched Url as the key for the CSS transition
  const matchUrls = React.Children.map(children, child => {
    if (React.isValidElement<Route>(child)) {
      if (child && child.props) {
        const match = matchPath(location.pathname, child.props)
        if (match) {
          return match.url
        }
      }
    }
  })
  /**
   * Since this is a switch only one Route can be displayed
   * so include the index of the match incase there are multiple
   * routes with the same path but different configuration
   */
  const firstMatchIndex = matchUrls.findIndex(url => !!url)
  return (
    <TransitionGroup>
      <SwitchTransition>
        <CSSTransition
          {...props}
          key={`${firstMatchIndex}:${matchUrls[firstMatchIndex]}`}
          classNames={AnimatedSwitchClass}
        >
          <Switch location={location}>{children}</Switch>
        </CSSTransition>
      </SwitchTransition>
    </TransitionGroup>
  )
}

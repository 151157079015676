import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { DirectoryIntegrationBase } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
export const SUCCESS = 'success'

const success = (lastSyncVersion: DirectoryIntegrationBase) =>
  createResponse(SUCCESS, { lastSyncVersion })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await teamsapi(
        'my-company/showadcverforlastsync',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const lastSyncVersion = JSON.parse(response)
        return success(lastSyncVersion)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { UserListQueryParams } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'

import { MapAdminLevelNameForTracking } from '../drawer/actions'

export interface AssignAdminLevelUsersListQueryParams {
  search: string
  skip: number
}

export interface AssignAdminLevelUsersDrawerUrlParams {
  id: string
}

export enum AssignAdminLevelUsersActionTypes {
  RESET = '[admin-level-assign-user-drawer] reset',

  //populate users
  SET_ASSIGN_USER_LIST_ADMIN_LEVEL = '[admin-level-user-list-assign] set',
  GET_ASSIGN_USER_LIST_ADMIN_LEVEL = '[admin-level-user-list-assign] get',
  CLEAR_USER_LIST_ADMIN_LEVEL = '[admin-level-user-list-assign] clear',

  ADD_SELECTED_ADMIN_LEVEL_USERS = '[admin-level-user-list-assign] assign-selected-users',
  REMOVE_SELECTED_ADMIN_LEVEL_USERS = '[admin-level-user-list-assign] remove-selected-users',
  SET_SELECTED_ADMIN_LEVEL_USERS = '[admin-level-user-list-assign] set-selected-users',
  SET_CURRENT_PAGE_ADMIN_LEVEL_USERS = '[admin-level-user-list-assign set-current-page',
  SET_ADMIN_LEVEL_USERS_ASSIGN_TABLE_LOADING = '[admin-level-user-list-assign] set-table-loading',

  // Assign users to admin-level
  ADD_USERS_TO_ADMIN_LEVEL = '[admin-level-users-add] set',
  UNASSIGN_USERS_FROM_ADMIN_LEVELS = '[admin-level-users] unassign-selected-users'
}

export const assignAdminLevelUsersActions = {
  reset: () => createAction(AssignAdminLevelUsersActionTypes.RESET),

  setAdminAssignUserList: (
    users: User[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(
      AssignAdminLevelUsersActionTypes.SET_ASSIGN_USER_LIST_ADMIN_LEVEL,
      {
        users,
        totalResults,
        resultsPerPage
      }
    ),
  getAdminAssignUserList: (
    params: DataFetchParams<
      AssignAdminLevelUsersListQueryParams,
      AssignAdminLevelUsersDrawerUrlParams
    >,
    resultsPerPage = 25
  ) =>
    createAction(
      AssignAdminLevelUsersActionTypes.GET_ASSIGN_USER_LIST_ADMIN_LEVEL,
      {
        params,
        resultsPerPage
      }
    ),
  clearAdminAssignUserList: () =>
    createAction(AssignAdminLevelUsersActionTypes.CLEAR_USER_LIST_ADMIN_LEVEL),
  setAdminAssignUsersTableLoading: (loading: boolean) =>
    createAction(
      AssignAdminLevelUsersActionTypes.SET_ADMIN_LEVEL_USERS_ASSIGN_TABLE_LOADING,
      { loading }
    ),
  addSelectedAdminUsers: (userList: User[]) =>
    createAction(
      AssignAdminLevelUsersActionTypes.ADD_SELECTED_ADMIN_LEVEL_USERS,
      {
        userList
      }
    ),
  removeSelectedAdminUsers: (userList: User[]) =>
    createAction(
      AssignAdminLevelUsersActionTypes.REMOVE_SELECTED_ADMIN_LEVEL_USERS,
      {
        userList
      }
    ),
  setSelectedAdminUsers: (userList: User[]) =>
    createAction(
      AssignAdminLevelUsersActionTypes.SET_SELECTED_ADMIN_LEVEL_USERS,
      {
        userList
      }
    ),

  addUsersToAdmin: (
    userList: User[],
    adminLevel: UserLevelDetails,
    queryParams: UserListQueryParams | {},
    url: string,
    fromUserAction: boolean
  ) =>
    track(
      createAction(AssignAdminLevelUsersActionTypes.ADD_USERS_TO_ADMIN_LEVEL, {
        userList,
        adminLevel,
        queryParams,
        url,
        fromUserAction
      }),
      {
        segment: uacEvent('Assign Users to Admin Level Clicked', {
          'Admin Level': MapAdminLevelNameForTracking(adminLevel)
        })
      }
    ),

  unassignSelectedUsers: (
    users: User[],
    queryParams: UserListQueryParams | {}
  ) =>
    createAction(
      AssignAdminLevelUsersActionTypes.UNASSIGN_USERS_FROM_ADMIN_LEVELS,
      {
        users,
        queryParams
      }
    )
}

export type AssignAdminLevelUsersActions = ActionsUnion<
  typeof assignAdminLevelUsersActions
>

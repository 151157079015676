export enum PolicyCode {
  endchallengescore = 'endchallengescore',
  iprestrict = 'iprestrict',
  iprestrictnomobile = 'iprestrictnomobile',
  dnsrestrict = 'dnsrestrict',
  countryrestrict = 'countryrestrict',
  torrestrict = 'torrestrict',
  norecovery = 'norecovery',
  disableoffline = 'disableoffline',
  allowmasterpasswordsave = 'allowmasterpasswordsave',
  prohibitmobile = 'prohibitmobile',
  prohibitunrestrictedmobile = 'prohibitunrestrictedmobile',
  prohibitunrestrictedmobileadmin = 'prohibitunrestrictedmobileadmin',
  disallowjailbrokenmobile = 'disallowjailbrokenmobile',
  logoffclosebrowser = 'logoffclosebrowser',
  logoffidle = 'logoffidle',
  logofflock = 'logofflock',
  logoffscreen = 'logoffscreen',
  logoffshutdown = 'logoffshutdown',
  killother = 'killother',
  websiteautologoffoverride = 'websiteautologoffoverride',
  pwlen = 'pwlen',
  pwdigits = 'pwdigits',
  pwlower = 'pwlower',
  pwupper = 'pwupper',
  pwspecial = 'pwspecial',
  numdiffcharsets = 'numdiffcharsets',
  sitepwlen = 'sitepwlen',
  noexport = 'noexport',
  noimport = 'noimport',
  noshare = 'noshare',
  noshareout = 'noshareout',
  noshareexceptfolders = 'noshareexceptfolders',
  sharedomainrestriction = 'sharedomainrestriction',
  norevert = 'norevert',
  hideidentities = 'hideidentities',
  nonotes = 'nonotes',
  hidefingerprint = 'hidefingerprint',
  nobookmarklets = 'nobookmarklets',
  nohint = 'nohint',
  login_site_prompt = 'login_site_prompt',
  copyview_site_prompt = 'copyview_site_prompt',
  requirechange = 'requirechange',
  requirechangereuse = 'requirechangereuse',
  noreusemasterpass = 'noreusemasterpass',
  nomultifactordisable = 'nomultifactordisable',
  requirelastpassauth = 'requirelastpassauth',
  requireyubikey = 'requireyubikey',
  requiresesame = 'requiresesame',
  requiregoogleauth = 'requiregoogleauth',
  requiretoopher = 'requiretoopher',
  requireduo = 'requireduo',
  requiresecureauth = 'requiresecureauth',
  requiretransakt = 'requiretransakt',
  requiresalesforcehash = 'requiresalesforcehash',
  requiresecurid = 'requiresecurid',
  requiresymantecvip = 'requiresymantecvip',
  requireinwebo = 'requireinwebo',
  requiremultifactor = 'requiremultifactor',
  requiremultifactorafterxdays = 'requiremultifactorafterxdays',
  restricttrust = 'restricttrust',
  disableofflinega = 'disableofflinega',
  googleauthtimeoffset = 'googleauthtimeoffset',
  singleyubikey = 'singleyubikey',
  duoonlyusername = 'duoonlyusername',
  enterpriseconsolemultifactor = 'enterpriseconsolemultifactor',
  nooutofbandauto = 'nooutofbandauto',
  preferduowebsdk = 'preferduowebsdk',
  useparentmultifactor = 'useparentmultifactor',
  no2faforaiprange = 'no2faforaiprange',
  disallowemail = 'disallowemail',
  noupgradeprompts = 'noupgradeprompts',
  emaillockout = 'emaillockout',
  emailnewuser = 'emailnewuser',
  superadminshared = 'superadminshared',
  superadminrecovery = 'superadminrecovery',
  notifyonevent = 'notifyonevent',
  precreatesharingkey = 'precreatesharingkey',
  accountrecoveryemail = 'accountrecoveryemail',
  notifyonlogin = 'notifyonlogin',
  preventsfadminnotifications = 'preventsfadminnotifications',
  restrictemaildomain = 'restrictemaildomain',
  disableemailchange = 'disableemailchange',
  logusername = 'logusername',
  logfullurl = 'logfullurl',
  logname = 'logname',
  notifysharingkeycreated = 'notifysharingkeycreated',
  requirepin = 'requirepin',
  logoffbackground = 'logoffbackground',
  enablelogging = 'enablelogging',
  disallowmasterpasswordsavemobile = 'disallowmasterpasswordsavemobile',
  nolinkpersonal = 'nolinkpersonal',
  nochangepersonal = 'nochangepersonal',
  savesitestopersonal = 'savesitestopersonal',
  requirelinkedacct = 'requirelinkedacct',
  sendmpstrength = 'sendmpstrength',
  pwndlistscan = 'pwndlistscan',
  passwordexp = 'passwordexp',
  nofieldupsf = 'nofieldupsf',
  pollinterval = 'pollinterval',
  restrictedadmins = 'restrictedadmins',
  sendchallengescore = 'sendchallengescore',
  maxlockoutattemptsoverride = 'maxlockoutattemptsoverride',
  lockoutintervaloverride = 'lockoutintervaloverride',
  disableemailverificationoverride = 'disableemailverificationoverride',
  overridesessionlifetime = 'overridesessionlifetime',
  noformfill = 'noformfill',
  dontsendemailuponaccountdeletion = 'dontsendemailuponaccountdeletion',
  requirelastpassmfa = 'requirelastpassmfa',
  requiremicrosoftauth = 'requiremicrosoftauth',
  preventemail_accountchange = 'preventemail_accountchange',
  preventemail_sapasswordreset = 'preventemail_sapasswordreset',
  mobilelockoptionoverride = 'mobilelockoptionoverride',
  accountselectionbasedonemail = 'accountselectionbasedonemail',
  restrictenterpriseapitoeventreporting = 'restrictenterpriseapitoeventreporting',
  disablecloudapps = 'disablecloudapps',
  disableautofill = 'disableautofill',
  lpauthenticatorgeoloc = 'lpauthenticatorgeoloc',
  dark_web_monitoring = 'dark_web_monitoring',
  exclude_password = 'exclude_password',
  allowpasswordlesslogin = 'allowpasswordlesslogin',
  disable_two_factor_code = 'disable_two_factor_code',
  dontnotifyuserwhenaddedtosharedfolder = 'dontnotifyuserwhenaddedtosharedfolder',
  overridelastpassauthmethods = 'overridelastpassauthmethods',
  prohibitfamilyasabenefit = 'prohibitfamilyasabenefit',
  requirepasswordlessvialastpassauth = 'requirepasswordlessvialastpassauth',
  showsecondaryonboarding = 'showsecondaryonboarding',
  hashIterationCountForMasterPassword = 'hashiterationcountformasterpassword',
  requirelastpassmfaaftergraceperiod = 'requirelastpassmfaaftergraceperiod'
}

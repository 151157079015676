import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { OpenIdProvider } from '@lastpass/federation'
import { track } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import {
  createFederatedLoginEnabledSegment,
  createKeyRotationFinishedSegment,
  createKeyRotationStartedSegment,
  createPkceEnabledSegment
} from './segment-actions'
import {
  AdAttributeState,
  AdfsSetupState,
  KeyRotationAuth,
  KeyRotationSetupState,
  OpenIdSetupState
} from './state'

export enum FederatedLoginActionTypes {
  SET_LOADING = '[federated-loading] set',

  ADFS_SETUP_GET = '[federated-adfs] get',
  ADFS_SETUP_SET = '[federated-adfs] set',
  ADFS_SETUP_SAVE = '[federated-adfs] save',

  AD_ATTRIBUTE_GET = '[federated-ad-attribute] get',
  AD_ATTRIBUTE_SET = '[federated-ad-attribute] set',
  AD_ATTRIBUTE_SAVE = '[federated-ad-attribute] save',
  AD_ATTRIBUTE_VISIBLE_SET = '[federated-ad-attribute] visibility',

  OPEN_ID_SETUP_GET = '[federated-open-id] get',
  OPEN_ID_SETUP_SET = '[federated-open-id] set',
  OPEN_ID_SETUP_SAVE = '[federated-open-id] save',

  SET_KEY_ROTATION_REMAINING_TIME = '[federated-key-rotation] set-remaining-time',
  SET_KEY_ROTATION_PERCENTAGE = '[federated-key-rotation] set-percentage',
  SET_DEFAULT_KEY_ROTATION_ESTIMATION_VALUES = '[federated-key-rotation] set-default-estimation-values',
  SET_KEY_ROTATION_AUTH_VALUES = '[federated-key-rotation] set-auth-values',

  START_KEY_ROTATION = '[federated-key-rotation] start',
  FINISHED_KEY_ROTATION = '[federated-key-rotation] finished',

  START_VAULT_RE_ENCRYPTION = '[vault-re-encryption] start',
  START_VAULT_RE_ENCRYPTION_OFFER = '[vault-re-encryption] offer start'
}

export const federatedLoginActions = {
  setLoading: (loading: boolean) =>
    createAction(FederatedLoginActionTypes.SET_LOADING, { loading }),

  getAdfsSetup: () => createAction(FederatedLoginActionTypes.ADFS_SETUP_GET),
  setAdfsSetup: (adfsSetup: AdfsSetupState) =>
    createAction(FederatedLoginActionTypes.ADFS_SETUP_SET, { adfsSetup }),
  saveAdfsSetup: (adfsSetup: AdfsSetupState) =>
    createAction(FederatedLoginActionTypes.ADFS_SETUP_SAVE, { adfsSetup }),

  getAdAttributeName: () =>
    createAction(FederatedLoginActionTypes.AD_ATTRIBUTE_GET),
  setAdAttributeName: (adAttribute: AdAttributeState) =>
    createAction(FederatedLoginActionTypes.AD_ATTRIBUTE_SET, {
      adAttribute
    }),
  saveAdAttributeName: (adAttribute: AdAttributeState) =>
    createAction(FederatedLoginActionTypes.AD_ATTRIBUTE_SAVE, {
      adAttribute
    }),

  setAdAttributeVisible: (visible: boolean) =>
    createAction(FederatedLoginActionTypes.AD_ATTRIBUTE_VISIBLE_SET, visible),

  getOpenIdSetup: () =>
    createAction(FederatedLoginActionTypes.OPEN_ID_SETUP_GET),
  setOpenIdSetup: (openIdSetup: OpenIdSetupState) =>
    createAction(FederatedLoginActionTypes.OPEN_ID_SETUP_SET, { openIdSetup }),
  saveOpenIdSetup: (
    openIdSetup: OpenIdSetupState,
    oldOpenIdSetup: OpenIdSetupState,
    isAutomatedVaultReEncryptionOfferRequired: boolean
  ) => {
    if (openIdSetup.isEnabled) {
      if (!oldOpenIdSetup.isEnabled) {
        return track(
          createAction(FederatedLoginActionTypes.OPEN_ID_SETUP_SAVE, {
            openIdSetup,
            isAutomatedVaultReEncryptionOfferRequired
          }),
          createFederatedLoginEnabledSegment(
            openIdSetup.provider,
            openIdSetup.pkceEnabled
          )
        )
      }

      if (openIdSetup.pkceEnabled && !oldOpenIdSetup.pkceEnabled) {
        return track(
          createAction(FederatedLoginActionTypes.OPEN_ID_SETUP_SAVE, {
            openIdSetup,
            isAutomatedVaultReEncryptionOfferRequired
          }),
          createPkceEnabledSegment(openIdSetup.provider)
        )
      }
    }

    return createAction(FederatedLoginActionTypes.OPEN_ID_SETUP_SAVE, {
      openIdSetup,
      isAutomatedVaultReEncryptionOfferRequired
    })
  },

  startedKeyRotation: (keyRotationSetup: KeyRotationSetupState) =>
    track(
      createAction(
        FederatedLoginActionTypes.START_KEY_ROTATION,
        keyRotationSetup
      ),
      createKeyRotationStartedSegment(keyRotationSetup.provider)
    ),
  finishedKeyRotation: (provider: OpenIdProvider) =>
    track(
      createAction(FederatedLoginActionTypes.FINISHED_KEY_ROTATION),
      createKeyRotationFinishedSegment(provider)
    ),
  setKeyRotationRemainingTime: (keyRotationRemainingTime: number) =>
    createAction(FederatedLoginActionTypes.SET_KEY_ROTATION_REMAINING_TIME, {
      keyRotationRemainingTime
    }),
  setKeyRotationPercentage: (keyRotationProgressPercentage: number) =>
    createAction(FederatedLoginActionTypes.SET_KEY_ROTATION_PERCENTAGE, {
      keyRotationProgressPercentage
    }),
  setKeyRotationAuth: (keyRotationAuth: KeyRotationAuth) =>
    createAction(FederatedLoginActionTypes.SET_KEY_ROTATION_AUTH_VALUES, {
      keyRotationAuth
    }),
  setDefaultKeyRotationEstimationValues: () =>
    createAction(
      FederatedLoginActionTypes.SET_DEFAULT_KEY_ROTATION_ESTIMATION_VALUES
    ),
  startVaultReEncryption: (
    automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
  ) =>
    createAction(FederatedLoginActionTypes.START_VAULT_RE_ENCRYPTION, {
      automatedVaultReEncryptionPolicy
    }),
  startVaultReEncryptionOffer: () =>
    createAction(FederatedLoginActionTypes.START_VAULT_RE_ENCRYPTION_OFFER)
}

export type FederatedLoginActions = ActionsUnion<typeof federatedLoginActions>

import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import { Dropdown, DropdownListItem, IconButton } from '@lastpass/lastkit'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'

const StyledDropdown = styled(Dropdown)`
  margin-left: auto;
`

interface PsaOperationsDropdownProps {
  provider: string
}

export const PsaOperationsDropdown: FunctionComponent<PsaOperationsDropdownProps> = ({
  provider
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleOpenPsaEditDrawer = () => {
    dispatch(
      psaIntegrationsActions.setPsaEditCredentialsFormValues(null, provider)
    )
    history.push(`${url}/credentials/${provider}/edit`)
  }

  const handleDeletePsaCredential = () => {
    dispatch(psaIntegrationsActions.deletePsaCredentials(provider))
  }

  return (
    <StyledDropdown
      button={props => (
        <IconButton
          {...props}
          icon={More}
          data-qa="PsaOperationsDropdownButton"
        />
      )}
    >
      <DropdownListItem
        data-qa="PsaOperationsEditButton"
        onClick={handleOpenPsaEditDrawer}
      >
        <Trans>Edit credentials</Trans>
      </DropdownListItem>
      <DropdownListItem
        red
        data-qa="PsaOperationsDeleteButton"
        onClick={handleDeletePsaCredential}
      >
        <Trans>Remove integration</Trans>
      </DropdownListItem>
    </StyledDropdown>
  )
}

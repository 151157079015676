import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { UserLevelWithPermissions } from '@lastpass/admin-console-dependencies/types/user-level'
import { AssignAdminLevelUsersDrawerTable } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/add/components/AssignAdminLevelUsersDrawerTable'
import { AdminLevelDescription } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-description/AdminLevelDescription'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'

const DescriptionContainer = styled.div`
  margin-bottom: 17px;
`

interface AssignUsersDrawerProps {
  closeLink: string
  backButton?: React.ReactElement
}

export const AssignUsersDrawer: React.FunctionComponent<AssignUsersDrawerProps> = ({
  closeLink,
  backButton
}) => {
  const adminLevelState: UserLevelWithPermissions = useSelector(
    (state: AppState) => state.adminLevelDrawer.adminLevel
  )

  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      adminLevelDrawerActions.getCurrentAdminLevel({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  useUnmountEffect(() => {
    adminLevelDrawerActions.reset()
  })

  return (
    <Drawer
      mainTitle={<UserLevelLabel userLevel={adminLevelState} />}
      closeLink={closeLink}
      headerNodes={backButton}
    >
      <DescriptionContainer>
        <AdminLevelDescription
          description={adminLevelState.description}
          adminLevelId={adminLevelState.id}
        />
      </DescriptionContainer>
      <AssignAdminLevelUsersDrawerTable />
    </Drawer>
  )
}

import { call, put, select } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import { PasswordlessDrawerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPasswordlessDrawerState } from './get-policy-users'

const getPolicyConfigUsersList = (
  policyData: TeamsPolicyModelData[] | undefined,
  configId: number,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyUserInfo[] => {
  if (!policyData || policyData.length === 0) {
    return []
  }

  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData[configId].appliesTo
  ) {
    return []
  }

  if (policyData[configId].appliesTo === AppliesToOptions.inclusive) {
    return policyData[configId].inclusiveList || []
  } else if (policyData[configId].appliesTo === AppliesToOptions.exclusive) {
    return policyData[configId].exclusiveList || []
  }

  return []
}

const getPolicyConfigGroupsList = (
  policyData: TeamsPolicyModelData[] | undefined,
  configId: number,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyGroup[] => {
  if (!policyData || policyData.length === 0) {
    return []
  }

  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData[configId].appliesTo
  ) {
    return []
  }

  if (policyData[configId].appliesTo === AppliesToOptions.inclusive) {
    return policyData[configId].inclusiveGroupList || []
  } else if (policyData[configId].appliesTo === AppliesToOptions.exclusive) {
    return policyData[configId].exclusiveGroupList || []
  }

  return []
}

const applyUsersFilters = (
  searchList: TeamsPolicyUserInfo[],
  existingUsersList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] =>
  searchList.filter(user =>
    existingUsersList.every(existingUser => existingUser.uid !== user.uid)
  )

const applyGroupsFilters = (
  searchList: TeamsPolicyGroup[],
  existingGroupsList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] =>
  searchList.filter(group =>
    existingGroupsList.every(existingGroup => existingGroup.id !== group.id)
  )

const updateToBeAddedUsers = (
  tobeAddedUsers: TeamsPolicyUserInfo[],
  searchList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] => {
  const updatedToBeAddedUsers = tobeAddedUsers.map(user => {
    const newRecord = searchList.find(searchUser => searchUser.uid === user.uid)
    return newRecord || user
  })
  return updatedToBeAddedUsers
}

const updateToBeAddedGroups = (
  tobeAddedGroups: TeamsPolicyGroup[],
  searchList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] => {
  const updatedToBeAddedGroups = tobeAddedGroups.map(group => {
    const newRecord = searchList.find(
      searchGroup => searchGroup.id === group.id
    )
    return newRecord || group
  })
  return updatedToBeAddedGroups
}

export function createSearchAssignUsersAndGroupsPolicySaga(
  policyService: UACServices.Services
) {
  return function* searchAssignUsersAndGroupsPolicySaga(
    action: ReturnType<
      typeof passwordlessDrawerActions.searchPolicyAssignUsersAndGroups
    >
  ) {
    const { searchKey, policyConfigId } = action.payload

    yield put(passwordlessDrawerActions.setPolicyAssignUsersLoading(true))
    yield put(passwordlessDrawerActions.setPolicyAssignGroupsLoading(true))
    try {
      const drawerState: PasswordlessDrawerState = yield select(
        getPasswordlessDrawerState
      )
      const policy = drawerState.policy
      const policyAssignUsersAndGroupsFormState = drawerState.assignFormState
      const policyCurrentlyAppliesTo: AppliesToOptions =
        drawerState.policyCurrentlyAppliesTo ||
        (policy.policyData &&
          policy.policyData.length > 0 &&
          policy.policyData[action.payload.policyConfigId].appliesTo) ||
        AppliesToOptions.inclusive

      const searchUsersResult: UACServices.GeneralPolicyUsersSearchAPI.Responses = searchKey
        ? yield call(policyService.generalPolicySearchUsers, searchKey)
        : {
            type: UACServices.GeneralPolicyUsersSearchAPI.SUCCESS,
            body: {
              policyUsersList: []
            }
          }
      const searchGroupsResult: UACServices.GeneralPolicyGroupsSearchAPI.Responses = searchKey
        ? yield call(policyService.generalPolicySearchGroups, searchKey)
        : {
            type: UACServices.GeneralPolicyGroupsSearchAPI.SUCCESS,
            body: {
              policyGroupsList: []
            }
          }

      if (
        searchUsersResult.type ===
          UACServices.GeneralPolicyUsersSearchAPI.SUCCESS &&
        searchGroupsResult.type ===
          UACServices.GeneralPolicyGroupsSearchAPI.SUCCESS
      ) {
        const searchUsersList = searchUsersResult.body.policyUsersList
        const searchGroupsResponseList =
          searchGroupsResult.body.policyGroupsList
        const searchGroupsList: TeamsPolicyGroup[] = searchGroupsResponseList.map(
          groupResponse => {
            return {
              id: groupResponse.cgid,
              name: groupResponse.name,
              userscount: parseInt(groupResponse.userscount, 10)
            }
          }
        )
        const toBeAddedUsers = policyAssignUsersAndGroupsFormState.usersList
        const toBeAddedGroups = policyAssignUsersAndGroupsFormState.groupsList
        const updatedToBeAddedUsers = updateToBeAddedUsers(
          toBeAddedUsers,
          searchUsersList
        )
        const updatedToBeAddedGroups = updateToBeAddedGroups(
          toBeAddedGroups,
          searchGroupsList
        )
        const existingPolicyUsers = getPolicyConfigUsersList(
          policy.policyData,
          policyConfigId,
          policyCurrentlyAppliesTo
        )
        const existingPolicyGroups = getPolicyConfigGroupsList(
          policy.policyData,
          policyConfigId,
          policyCurrentlyAppliesTo
        )
        const filteredUserList = applyUsersFilters(
          searchUsersList,
          existingPolicyUsers
        )
        const filteredGroupList = applyGroupsFilters(
          searchGroupsList,
          existingPolicyGroups
        )
        const policyConfigAssignUsersAndGroups: GeneralPolicyUsersAndGroups = {
          policyKey: policy.policyKey,
          policyConfigIndex: policyConfigId,
          policyConfigFormData: {
            appliesTo: policyCurrentlyAppliesTo,
            users: filteredUserList,
            groups: filteredGroupList
          }
        }
        yield put(
          passwordlessDrawerActions.updatePolicyAssignUsersAndGroupsToBeAdded(
            updatedToBeAddedUsers,
            updatedToBeAddedGroups
          )
        )
        yield put(
          passwordlessDrawerActions.setPolicyAssignUsersAndGroups(
            policyConfigAssignUsersAndGroups
          )
        )
        yield put(
          passwordlessDrawerActions.setPolicyUsersAndGroupsNeedsRefresh(false)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(passwordlessDrawerActions.setPolicyAssignUsersLoading(false))
      yield put(passwordlessDrawerActions.setPolicyAssignGroupsLoading(false))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetchBlob } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { DownloadGeneratedReport } from '@lastpass/admin-console-dependencies/types/generated-security-reports'

export const SUCCESS = 'success'

const success = (blob: Blob) => createResponse(SUCCESS, blob)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetchBlob): DownloadGeneratedReport {
  return async function service(reportId) {
    const [response, status] = await fetch(
      `users/vault-user-url-reports/${reportId}`,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      return success(response)
    }
    throw new UnknownServiceError()
  }
}

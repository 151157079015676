import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const DUPLICATE_NAME = 'duplicate-adminlevel-name'
export const PARTIAL_SUCCESS = 'partialSuccess'
export const FAIL = 'fail'
export const FAILURE = 'failure'

const success = (id: string) => createResponse(SUCCESS, { id })
const duplicateName = () => createResponse(DUPLICATE_NAME)
const partialSuccess = (id: string) => createResponse(PARTIAL_SUCCESS, { id })
const fail = (id: string) => createResponse(FAIL, { id })

export const Responses = {
  success,
  duplicateName,
  partialSuccess,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(adminLevel) {
    const [response, status] = await fetch(
      'adminLevels/' + adminLevel.id,
      'PUT',
      {
        name: adminLevel.name,
        description: adminLevel.description,
        permissions: adminLevel.permissions,
        mspCidOverrideAdminLevel: adminLevel.mspCidOverrideAdminLevel,
        mspCidOverridePermissions: adminLevel.mspCidOverridePermissions
      },
      { ['content-type']: 'application/json' }
    )

    if (status === StatusCodes.CONFLICT) {
      return duplicateName()
    } else if (status === StatusCodes.OK) {
      const result = JSON.parse(response)
      const { status, id } = result.content
      switch (status) {
        case SUCCESS: {
          return success(id)
        }
        case PARTIAL_SUCCESS: {
          return partialSuccess(id)
        }
        case FAILURE: {
          return fail(id)
        }
      }
    }
    throw new UnknownServiceError()
  }
}

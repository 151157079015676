import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { UserProfile } from '@lastpass/admin-console-dependencies/types/user'

const convertProfile = (profile: UserProfile) => {
  return {
    department: profile.department || '',
    description: profile.description || '',
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    phoneNumber: profile.phoneNumber || '',
    samAccountName: profile.samAccountName || '',
    secondaryEmail: profile.secondaryEmail || '',
    userId: profile.userId,
    customAttributes: profile.customAttributes
  }
}

export function createSaveUserProfileSaga(userService: UACServices.Services) {
  return function* getUserDrawerSaga(
    action: ReturnType<typeof userDrawerActions.saveUserProfile>
  ) {
    try {
      const profile = action.payload.userProfile
      const id = action.payload.id
      const userListParams = action.payload.userListParams
      if (profile) {
        yield put(userDrawerActions.setUserProfileLoading(true))

        const profileToSave = convertProfile(profile)

        try {
          const result: UACServices.SaveUserProfileAPI.Responses = yield call(
            userService.saveUserProfile,
            profileToSave,
            id
          )
          if (result.type === UACServices.SaveUserProfileAPI.SUCCESS) {
            yield put(
              globalActions.setNotification({
                message: msg`All changes have been saved.`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
            yield put(userDrawerActions.setUserProfile(profile))
            yield put(userListActions.getUserList(userListParams))
          } else {
            yield put(
              globalActions.setNotification({
                message: msg`Changes haven't been saved.`,
                type: NotificationType.success,
                autoDismiss: false
              })
            )
          }
        } finally {
          yield put(userDrawerActions.setUserProfileLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

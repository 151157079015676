import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (sharedFolderSitesResponse: SharedFolderSitesApiResponse) =>
  createResponse(SUCCESS, sharedFolderSitesResponse)

export const Responses = {
  success
}

export interface SharedFolderSitesApiType {
  domain: string
  lastused: string
}

export interface SharedFolderSitesApiResponse {
  sites: SharedFolderSitesApiType[]
  adminHasWriteAccess: boolean
}

export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function servicee(shareid: string) {
    try {
      const [response, status] = await teamsapi(
        'my-company/sharedfoldersites/' + shareid,
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const result: SharedFolderSitesApiResponse = JSON.parse(response)
        return success(result)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

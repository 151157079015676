import { AdvancedUserActivityEventTypes } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'

export const eventTypes: AdvancedUserActivityEventTypes = {
  userEvents: [
    { label: 'Add to Shared Folder', value: 'addtosharedfolder' },
    { label: 'Created LastPass Account', value: 'createLPaccount' },
    { label: 'Created Shared Folder', value: 'createdsharedfolder' },
    { label: 'Data Export', value: 'export' },
    { label: 'Data Import', value: 'import' }
  ],
  adminEvents: [
    { label: 'Add Global Equiv Domain', value: 'addglobalequivdomain' },
    { label: 'Add Global Never URLs', value: 'addglobalneverurls' },
    { label: 'Add Global Only URLs', value: 'addglobalonlyurls' },
    { label: 'Add Policy', value: 'addpolicy' },
    { label: 'Add to Group', value: 'addtogroup' }
  ]
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { AccountInformation } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
export const SUCCESS = 'success'

const success = (accountInfo: AccountInformation) =>
  createResponse(SUCCESS, { accountInfo })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'company/account-information',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const completeAccountInfo = JSON.parse(response)
        const accountInfoRequired: AccountInformation = {
          companyLastPassId: completeAccountInfo.content.companyLastPassId
        }
        return success(accountInfoRequired)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

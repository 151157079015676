import React, { FC } from 'react'

import styled from '@emotion/styled'

import { ManagedCompaniesAggregate } from '@lastpass/admin-console-dependencies/types/managed-company'

import { AverageSecurityScore } from './AverageSecurityScore'
import { Licenses } from './Licenses'

const Column = styled.div`
  width: 50%;
  margin-bottom: 60px;
  clear: both;

  &:first-child {
    margin-right: 24px;
  }
`

const Container = styled.div`
  padding: 24px;
`

const ColumnContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.neutral200};
  display: flex;
  width: 100%;
`

type ManagedCompanyAggregateProps = ManagedCompaniesAggregate

export const ManagedCompanyAggregate: FC<ManagedCompanyAggregateProps> = ({
  consumedLicenses,
  totalLicensesAllocated,
  averageSecurityScore
}) => (
  <Container>
    <ColumnContainer>
      {consumedLicenses !== null && totalLicensesAllocated !== null && (
        <Column data-qa="ManagedCompanyAggregateLicensesColumn">
          <Licenses
            consumedLicenses={consumedLicenses}
            totalLicensesAllocated={totalLicensesAllocated}
          />
        </Column>
      )}
      <Column data-qa="ManagedCompanyAggregateAverageSecurityScoreColumn">
        <AverageSecurityScore securityScore={averageSecurityScore} />
      </Column>
    </ColumnContainer>
  </Container>
)

import React, { ReactNode, useEffect } from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import { GetTriggerPropsArg, TooltipArg } from 'react-popper-tooltip/dist/types'

import styled from '@emotion/styled'
import { Modifiers, Placement } from 'popper.js'

// Can't add oveflow hidden, because it hides the tooltip arrow
const TooltipContainer = styled.div<{
  customWidth?: string
  customBorderRadius?: string
}>`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.neutral200};
  border-radius: ${props =>
    props.customBorderRadius
      ? props.customBorderRadius
      : props.theme.radius.pixel4};
  box-shadow: 0px 4px 12px rgba(33, 48, 68, 0.12);
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  ${props =>
    props.customWidth ? 'max-width:' + props.customWidth : ' max-width: 300px'};
  padding: 16px;
  margin: 0.4rem;
  text-overflow: ellipsis;
  white-space: wrap;
  word-wrap: break-word;
`
const arrowSizePixel = 20
const arrowSize = `${arrowSizePixel}px`
const halfArrowSize = `${arrowSizePixel / 2}px`
const arrowShift = `${arrowSizePixel / 2 - 2}px`
const arrowMargin = `${arrowSizePixel / 2 + 4}px`

const getArrowBorderWidth = (placement: Placement) => `
border-top-width: ${
  placement === 'top'
    ? arrowShift
    : placement === 'bottom'
    ? '0'
    : halfArrowSize
};
border-bottom-width: ${
  placement === 'bottom'
    ? arrowShift
    : placement === 'top'
    ? '0'
    : halfArrowSize
};
border-left-width: ${
  placement === 'left'
    ? arrowShift
    : placement === 'right'
    ? '0'
    : halfArrowSize
};
border-right-width: ${
  placement === 'right'
    ? arrowShift
    : placement === 'left'
    ? '0'
    : halfArrowSize
};`

const TooltipArrow = styled.div<{ placement: Placement }>`
  height: ${arrowSize};
  position: absolute;
  width: ${arrowSize};

  left: ${props => (props.placement === 'left' ? 'auto' : '0')};
  right: ${props => (props.placement === 'left' ? '0' : 'auto')};
  top: ${props => (props.placement === 'bottom' ? '0' : 'auto')};
  bottom: ${props => (props.placement === 'top' ? '0' : 'auto')};

  margin-top: ${props =>
    props.placement === 'bottom' ? '-' + arrowShift : '0'};
  margin-bottom: ${props =>
    props.placement === 'top' ? '-' + arrowSize : '0'};
  margin-right: ${props =>
    props.placement === 'left' ? '-' + arrowMargin : '0'};
  margin-left: ${props =>
    props.placement === 'right' ? '-' + arrowMargin : '0'};

  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;

    border-color: transparent;
    border-${props => props.placement}-color: ${props =>
  props.theme.colors.neutral200};

    ${props => getArrowBorderWidth(props.placement)}

    position: ${props =>
      props.placement === 'bottom' || props.placement === 'top'
        ? 'absolute'
        : 'static'};
    top: ${props =>
      props.placement === 'bottom'
        ? '-1px'
        : props.placement === 'top'
        ? '1px'
        : 'auto'};
  }

  &::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
    border-color: transparent;
    border-${props => props.placement}-color: ${props =>
  props.theme.colors.white};
    ${props => getArrowBorderWidth(props.placement)}

    left: ${props =>
      props.placement === 'right'
        ? '7px'
        : props.placement === 'left'
        ? '5px'
        : 'auto'};
    top: ${props =>
      props.placement === 'right' || props.placement === 'left' ? '0' : 'auto'}
  }
`

function TooltipContent(
  props: TooltipArg & {
    children?: ReactNode
    width?: string
    customBorderRadius?: string
    trackingEvent?: () => void
  }
) {
  useEffect(() => {
    if (props.trackingEvent) {
      props.trackingEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TooltipContainer
      {...props.getTooltipProps({
        ref: props.tooltipRef
      })}
      customWidth={props.width}
      customBorderRadius={props.customBorderRadius}
    >
      <TooltipArrow
        placement={props.placement}
        {...props.getArrowProps({
          ref: props.arrowRef
        })}
      />
      {props.children}
    </TooltipContainer>
  )
}

const modifiers: Modifiers = {
  flip: {
    behavior: ['left', 'bottom', 'top', 'right']
  },
  preventOverflow: {
    boundariesElement: 'viewport'
  }
}

const POINTER_EVENTS = 'pointer-events'

export interface WithTooltipProps {
  placement?: Placement
  tooltip: string | ReactNode
  showOnlyWhenOverflow?: boolean
  width?: string
  customBorderRadius?: string
  trackingEvent?: () => void
}

export const WithTooltip: React.FunctionComponent<WithTooltipProps> = props => {
  return (
    <TooltipTrigger
      placement={props.placement || 'auto'}
      trigger="hover"
      delayShow={500}
      delayHide={300}
      tooltip={tooltip => (
        <TooltipContent
          {...tooltip}
          width={props.width}
          customBorderRadius={props.customBorderRadius}
          trackingEvent={props.trackingEvent}
        >
          {props.tooltip}
        </TooltipContent>
      )}
      modifiers={modifiers}
      closeOnOutOfBoundaries={false}
      usePortal={true}
    >
      {({ getTriggerProps, triggerRef }) =>
        React.Children.map(props.children, child => {
          if (!child) {
            return null
          }

          const baseProps: GetTriggerPropsArg = {
            ref: triggerRef,
            className: 'trigger'
          }

          if (props.showOnlyWhenOverflow) {
            const fn = e => {
              const target = e.target
              if (!target) {
                return
              }

              if (target.offsetWidth < target.scrollWidth) {
                target.style[POINTER_EVENTS] = 'auto'
              } else {
                target.style[POINTER_EVENTS] = 'none'
                setTimeout(function() {
                  // fix in case of content inside trigger changed it's size
                  target.style[POINTER_EVENTS] = 'auto'
                }, 1000)
              }
            }

            baseProps.onMouseEnter = fn
          }

          const triggerProps = getTriggerProps(baseProps)

          if (
            typeof child === 'string' ||
            typeof child === 'number' ||
            typeof child === 'boolean' ||
            typeof child === 'undefined' ||
            !('type' in child)
          ) {
            return <span {...triggerProps}>{child}</span>
          }
          return React.cloneElement(child, { ...triggerProps })
        }).filter(el => el)
      }
    </TooltipTrigger>
  )
}

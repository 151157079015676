import { i18n } from '@lingui/core'

import { PolicyCode } from '@lastpass/admin-console-dependencies/types/policies-enum'
import { policyTexts } from '@lastpass/admin-console-dependencies/types/policy-mapping'

export const GetPolicyShortDescription = (
  policyKey: PolicyCode | string,
  policyShortDescription: string | null = null
) => {
  return i18n._(
    policyTexts[policyKey]
      ? policyTexts[policyKey].shortDescription
      : policyShortDescription || ''
  )
}

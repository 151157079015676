import React from 'react'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'

export const DialogContext = React.createContext<{
  dialogState: GlobalState['dialog']
  dialogActions: typeof globalActions
}>({
  dialogState: { type: 'empty' },
  dialogActions: globalActions
})

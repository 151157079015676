export const policyHelper = {
  checkUserListEmpty: policyData =>
    policyData.exclusiveList?.length === 0 &&
    policyData.exclusiveGroupList?.length === 0 &&
    policyData.inclusiveList?.length === 0 &&
    policyData.inclusiveGroupList?.length === 0,
  isRequiredPolicyUnused: (policyData): boolean =>
    policyData.length === 0 ||
    (policyData.length > 0 && policyHelper.checkUserListEmpty(policyData[0]))
}

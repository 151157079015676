import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSavePasswordManagerSaga(
  userService: UACServices.Services
) {
  return function* savePasswordManagerSaga(
    action: ReturnType<typeof userDrawerActions.savePasswordManager>
  ) {
    try {
      const passwordManager = action.payload.device
      const userId = action.payload.userId
      if (passwordManager && userId) {
        yield put(userDrawerActions.setUserTrustedDevicesLoading(true))

        try {
          const result: UACServices.EditLpDeviceAPI.Responses = yield call(
            userService.savePasswordManager,
            passwordManager,
            userId
          )
          if (result.type === UACServices.EditLpDeviceAPI.SUCCESS) {
            yield put(
              globalActions.setNotification({
                message: msg`All changes have been saved.`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
          } else {
            yield put(
              globalActions.setNotification({
                message: msg`Changes haven't been saved.`,
                type: NotificationType.alert,
                autoDismiss: false
              })
            )
          }
        } finally {
          yield put(userDrawerActions.setUserTrustedDevicesLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

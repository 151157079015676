import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { TeamsPolicyCategories } from '@lastpass/admin-console-dependencies/types/policy-teams'

interface SelectPolicyCategoryProps {
  updateQuery: Function
  filters?: TeamsPolicyCategories[]
}

interface PolicyCategorySelectOption {
  value: TeamsPolicyCategories
  label: string
}

const StyledSelect = styled(Select)`
  margin-left: 24px;
`

export const SelectPolicyCategory: FunctionComponent<SelectPolicyCategoryProps> = props => {
  const policyCategory: PolicyCategorySelectOption[] = [
    { value: TeamsPolicyCategories.default, label: i18n._(msg`Default`) },
    {
      value: TeamsPolicyCategories.recommended,
      label: i18n._(msg`Recommended`)
    },
    {
      value: TeamsPolicyCategories.access_controls,
      label: i18n._(msg`Access controls`)
    },
    {
      value: TeamsPolicyCategories.password_rules,
      label: i18n._(msg`Password rules`)
    },
    {
      value: TeamsPolicyCategories.account_restrictions,
      label: i18n._(msg`Account restrictions`)
    },
    {
      value: TeamsPolicyCategories.administration,
      label: i18n._(msg`Administration`)
    },
    {
      value: TeamsPolicyCategories.multifactor,
      label: i18n._(msg`Password multifactor`)
    },
    { value: TeamsPolicyCategories.other, label: i18n._(msg`Other`) }
  ]
  return (
    <StyledSelect
      dataQa={'PolicyCategoryDropdown'}
      options={policyCategory}
      value={policyCategory.filter(
        category => props.filters && props.filters.includes(category.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={i18n._(msg`Policy category`)}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateQuery({
            category: selectedItems.map(item => item.value)
          })
        } else {
          props.updateQuery({ category: undefined })
        }
      }}
      width={'325px'}
    />
  )
}

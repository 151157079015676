import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserProfile } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'

const success = (userProfile: UserProfile) =>
  createResponse(SUCCESS, userProfile)
const notFound = () => createResponse(NOT_FOUND)

export const Responses = {
  success,
  notFound
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userId: string) {
    const [response, status] = await fetch(
      'users/' + userId + '/profile',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const userProfile = JSON.parse(response).content
      return success(userProfile)
    } else if (status === StatusCodes.NOT_FOUND) {
      return notFound()
    }
    throw new UnknownServiceError()
  }
}

export enum MfaApplicationType {
  undefined = '0',
  windows = '1',
  mac = '2',
  azureAD = '3',
  universalProxy = '4',
  adfs = '5'
}

export interface MfaApplication {
  name: string
  key: string
  secret: string
  type: MfaApplicationType
  mappingKey: string
  allUser: boolean
  userIds: string[]
  groupIds: string[]
}

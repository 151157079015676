import { push } from 'connected-react-router'
import buildQuery from 'odata-query'
import { call, put, select } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { getApplicableUserOperations } from '@lastpass/admin-console-dependencies/services/user-operations/get-applicable-user-operations'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { UserOperationsType } from '@lastpass/admin-console-dependencies/state/users/view/operations/user-operations'
import { transferVaultDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/transfer-vault/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CompanySettings } from '@lastpass/admin-console-dependencies/types/company-settings'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import { User } from '@lastpass/admin-console-dependencies/types/user'

import {
  getAdfsIsEnabled,
  getCompanyDetails,
  getCompanySettings,
  getCurrentAdminLevel,
  getCurrentUserInfo,
  getPermissions,
  getSelectedUsers,
  getUserProfileInfo
} from '../users-operations'

export function createTransferVaultDrawerSaga(
  userService: UACServices.Services
) {
  function* isUserApplicableForTransferVaultOperation(userId: string) {
    try {
      const result: UACServices.UserAccountAPI.Responses = yield call(
        userService.userAccountDetails,
        userId
      )

      const accountDetails = result.body
      const companyDetails = yield select(getCompanyDetails)
      const companySettings: CompanySettings = yield select(getCompanySettings)
      const permissions: AdminPermissions[] = yield select(getPermissions)
      const currentAdminLevel: AdminLevel = yield select(getCurrentAdminLevel)
      const selectedUsers: User[] = yield select(getSelectedUsers)
      const currentUserInfo: CurrentUser = yield select(getCurrentUserInfo)
      const userProfileInfo: UsersDrawerState['profile'] = yield select(
        getUserProfileInfo
      )
      const isAdfsEnabled: boolean = yield select(getAdfsIsEnabled)
      const userOperations: UserOperationsType[] = yield call(
        getApplicableUserOperations,
        [
          [
            accountDetails.accountStatus,
            accountDetails.mfaStatus,
            accountDetails.defaultMultiFactorType || null,
            accountDetails.isSuperAdminKeyAvailableForActingAdmin || false,
            accountDetails.provisionFederatedStatus,
            accountDetails.adminLevel
              ? accountDetails.adminLevel.id === AdminLevel.superAdmin
              : false
          ]
        ],
        companyDetails,
        companySettings.isNonDisabledUserRemovalAllowed,
        permissions,
        currentAdminLevel,
        selectedUsers,
        currentUserInfo,
        userProfileInfo,
        isAdfsEnabled
      )

      return userOperations.includes(UserOperationsType.transferVault)
    } catch (e) {
      return false
    }
  }

  function* fetchTargetUserList(
    action: ReturnType<typeof transferVaultDrawerActions.getTransferVaultData>
  ) {
    const query = buildQuery({
      ...action.payload.params.query,
      search: encodeSearch(action.payload.params.query.search),
      top: action.payload.resultsPerPage
    })
    return yield call(userService.users, query)
  }

  function* handleTargetUserListResponse(
    targetUserListResponse: UACServices.UsersAPI.Responses,
    sourceUserId: string
  ) {
    const targetUserList = targetUserListResponse.body.userList.filter(
      targetUser => targetUser.id !== sourceUserId
    )
    const totalResults = targetUserListResponse.body.totalResults
    const totalCompanyUsers = targetUserListResponse.body.totalCompanyUsers
    yield put(
      transferVaultDrawerActions.setUsers(
        targetUserList,
        totalResults,
        totalCompanyUsers
      )
    )
  }

  return function*(
    action: ReturnType<typeof transferVaultDrawerActions.getTransferVaultData>
  ) {
    try {
      const { userId } = action.payload
      if (!userId) {
        throw new Error()
      }
      yield put(transferVaultDrawerActions.setIsLoading(true))
      yield put(transferVaultDrawerActions.removeTargetUser())

      try {
        const isOperationApplicable = yield call(
          isUserApplicableForTransferVaultOperation,
          userId
        )
        if (!isOperationApplicable) {
          yield put(push('/users/view'))
          return
        }

        const result: UACServices.UserProfileAPI.Responses = yield call(
          userService.userProfile,
          userId
        )

        if (result.type !== UACServices.UserProfileAPI.SUCCESS) {
          yield put(push('/users/view'))
          return
        }

        const sourceUserProfile = result.body
        yield put(
          transferVaultDrawerActions.setSourceUserData(sourceUserProfile)
        )

        const targetUserListResponse = yield call(fetchTargetUserList, action)
        if (targetUserListResponse.type === UACServices.UsersAPI.SUCCESS) {
          yield call(
            handleTargetUserListResponse,
            targetUserListResponse,
            sourceUserProfile.id
          )
        }
      } finally {
        yield put(transferVaultDrawerActions.setIsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

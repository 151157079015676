import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular } from '@lastpass/lastkit'

import { ComingSoon } from '@lastpass/admin-console-dependencies/ui/common/ComingSoon'

const Container = styled.div`
  text-align: center;
  width: 100%;
`

const StyledLink = styled.a`
  font-weight: 600;
`

export const ManagedCompaniesPage: React.FunctionComponent = () => {
  return (
    <Container>
      <ComingSoon oldUrl="/dashboard" />
      <BodyRegular>
        <StyledLink
          href="https://support.logmeininc.com/lastpass/help/what-are-managed-companies-in-lastpass-enterprise"
          target="_blank"
        >
          <Trans>Learn more about this feature</Trans>
        </StyledLink>
      </BodyRegular>
    </Container>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'

export const SUCCESS = 'success'

const success = (details: CompanyDetails) => createResponse(SUCCESS, details)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('company/details/', 'GET')
    if (status === StatusCodes.OK && response) {
      const {
        content: {
          subscriptionType,
          companyPermissions,
          subscriptionEndDateUtc,
          ...details
        }
      } = JSON.parse(response)

      return success({
        ...details,
        subscription: subscriptionType,
        subscriptionEndDate:
          subscriptionEndDateUtc && new Date(subscriptionEndDateUtc),
        permissions: companyPermissions
      })
    }

    throw new UnknownServiceError()
  }
}

import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { createApplicationsList } from '@lastpass/admin-console-dependencies/ui/applications/saml/ApplicationList'
import { ApplicationsDrawer } from '@lastpass/admin-console-dependencies/ui/applications/saml/ApplicationsDrawer'

interface ApplicationSamlPageProps {
  onClose?: () => void
}

const ApplicationsList = createApplicationsList<AppState>()

export const ApplicationsSamlPage: React.FunctionComponent<ApplicationSamlPageProps> = props => {
  const match = useRouteMatch()
  if (match) {
    const { path } = match
    return (
      <>
        <ApplicationsList />
        <AnimatedSwitch timeout={200}>
          <Route path={`${path}/add`}>
            <ApplicationsDrawer
              closeLink={`${path}`}
              initialStep={ApplicationDrawerSteps.select}
              onSsoAppDrawerClosed={props.onClose}
            />
          </Route>
          <Route path={`${path}/:id`}>
            <ApplicationsDrawer
              closeLink={`${path}`}
              initialStep={ApplicationDrawerSteps.configure}
              onSsoAppDrawerClosed={props.onClose}
            />
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

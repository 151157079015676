import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { GroupUserActionsType } from '@lastpass/admin-console-dependencies/state/users/groups/operations/group-operations'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createRemoveFromCompanyGroupSaga(
  uacServices: UACServices.Services
) {
  return function* removeFromCompanyGroupSaga(
    action: ReturnType<typeof groupOperationActions.removeFromCompany>
  ) {
    try {
      const groupId = action.payload
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Remove from company`,
        text: msg`All users in the group will be removed from the company except those already removed.`,
        discardText: msg`Cancel`,
        confirmText: msg`Remove`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const result: UACServices.GroupUserOperationsAPI.Responses = yield call(
          uacServices.groupUserOperations,
          GroupUserActionsType.removeFromCompany,
          groupId
        )
        if (result.type === UACServices.GroupUserOperationsAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: msg`The selected users have been removed. Bulk actions may take up to 15 minutes to complete.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.GroupUserOperationsAPI.FAIL) {
          yield put(
            globalActions.setNotification({
              message: msg`None of the selected users have been removed.`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'
import { addSlashes } from 'slashes'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createFetchGoogleAuditLogList(
  enterpriseOptionServices: UACServices.Services
) {
  return function* fetchGoogleAuditLogList(
    action: ReturnType<typeof directoriesActions.fetchGoogleAuditLogList>
  ) {
    try {
      const { skip, top, filters } = action.payload

      const query = buildQuery({
        skip,
        search: encodeSearch(filters.identity),
        filter: {
          auditLogStatus: filters.status
            ? `${encodeURIComponent(
                addSlashes(filters.status, '\b\f\n\r\t\v\0"\\')
              )}`
            : undefined
        },
        top
      })

      const result: UACServices.GetGoogleAuditLogsAPI.Responses = yield call(
        enterpriseOptionServices.getGoogleAuditLogs,
        query
      )

      if (result.type === UACServices.GetGoogleAuditLogsAPI.SUCCESS) {
        yield put(
          directoriesActions.setLogList(
            result.body.logs,
            result.body.numberOfItems
          )
        )
      } else {
        yield put(directoriesActions.fetchLogListFailed())
      }
    } catch {
      yield put(directoriesActions.fetchLogListFailed())
    }
  }
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyRegularStyle,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink, LocationRedirect } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import {
  isTimeRangePolicy as checkIfPolicyIsTimeRangePolicy,
  isTimeRangeValid
} from '@lastpass/admin-console-dependencies/services/policies/time-range-validator'
import { GeneralPoliciesDrawerState } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/state'
import { generalPolicySettingsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/actions'
import {
  EditGeneralPolicySettingsConfigForm,
  GeneralPolicySettingsForm,
  GeneralPolicySettingsFormState
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/state'
import { TeamsPolicyValueType } from '@lastpass/admin-console-dependencies/types/policy-teams'

import { PolicyNoteEditor } from '../editors/PolicyNoteEditor'
import { PolicyValueEditor } from '../editors/PolicyValueEditor'

const StyledDescription = styled.div`
  ${BodyRegularStyle}
  margin-top:16px;
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 18px;
`

const hasFormDataChanged = (
  initialData: EditGeneralPolicySettingsConfigForm,
  currentFormData: EditGeneralPolicySettingsConfigForm
): boolean =>
  !(
    initialData.policyEnabled === currentFormData.policyEnabled &&
    initialData.policyNotes === currentFormData.policyNotes &&
    initialData.policyValue === currentFormData.policyValue
  )

export const GeneralPolicySettings: React.FunctionComponent = () => {
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const generalPolicySettings: GeneralPolicySettingsFormState = useSelector(
    (state: AppState) => state.generalPolicySettings
  )
  const generalPoliciesDrawer: GeneralPoliciesDrawerState = useSelector(
    (state: AppState) => state.generalPoliciesDrawer
  )
  const url = `/policies/generalpolicies/${generalPolicySettings.policySettings.policyKey}`

  useEffect(() => {
    dispatch(
      generalPolicySettingsActions.getGeneralPolicySettings({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  useUnmountEffect(() => {
    dispatch(generalPolicySettingsActions.reset())
  })

  if (!generalPolicySettings.settingsNeedsRefresh) {
    const initialValues: EditGeneralPolicySettingsConfigForm = {
      policyEnabled:
        generalPolicySettings.policySettings.policyConfigFormData.policyEnabled,
      policyNotes:
        generalPolicySettings.policySettings.policyConfigFormData.policyNotes ||
        '',
      policyValue:
        generalPolicySettings.policySettings.policyConfigFormData.policyValue ||
        ''
    }

    const policyDescriptionLines = generalPolicySettings.policySettings.policyDescription
      .split('<br/>')
      .filter(line => line.trim().length > 0)

    const isTimeRangePolicy = checkIfPolicyIsTimeRangePolicy(
      generalPolicySettings.policySettings.policyKey
    )

    return (
      <>
        <StyledDescription>
          {policyDescriptionLines.map((line, index) => (
            <p key={index}> {line}</p>
          ))}
        </StyledDescription>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={values => {
            const configFormRequiredSettingsData: GeneralPolicySettingsForm = {
              policyKey:
                generalPolicySettings.policySettings.policyKey ||
                generalPoliciesDrawer.policyKey,
              policyConfigIndex:
                generalPolicySettings.policySettings.policyConfigIndex || 0,
              policyConfigFormData: values
            }
            setIsBlockingPromptEnabled(false)
            setFormSubmitted(true)
            dispatch(
              generalPolicySettingsActions.saveGeneralPolicySettings(
                configFormRequiredSettingsData
              )
            )
          }}
        >
          {formikProps => {
            return (
              <>
                {formSubmitted && <Loading color="blue900" active={true} />}
                {generalPolicySettings.formSubmitSuccess && (
                  <LocationRedirect to={url} />
                )}
                <FormContainer
                  onChange={() => setIsBlockingPromptEnabled(true)}
                >
                  {generalPolicySettings.policySettings.policyValueType ===
                    TeamsPolicyValueType.textarea && (
                    <PolicyValueEditor isTimeRangePolicy={isTimeRangePolicy} />
                  )}
                  <PolicyNoteEditor />
                </FormContainer>
                <StyledSpacer />
                <FooterContainer>
                  <DrawerButtonsContainer>
                    <LocationLink to={url}>
                      <TextButton
                        css={css`
                          margin-right: 10px;
                        `}
                      >
                        <Trans>Cancel</Trans>
                      </TextButton>
                    </LocationLink>

                    <PrimaryButton
                      disabled={
                        (isTimeRangePolicy &&
                          !isTimeRangeValid(formikProps.values.policyValue)) ||
                        !hasFormDataChanged(initialValues, formikProps.values)
                      }
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                      type="submit"
                    >
                      <Trans>Save changes</Trans>
                    </PrimaryButton>
                  </DrawerButtonsContainer>
                </FooterContainer>
              </>
            )
          }}
        </Formik>
      </>
    )
  }
  return <Loading color="blue900" active={true} />
}

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { ManagedMigrationResponse } from '@lastpass/admin-console-dependencies/state/applications/application'

export const SUCCESS = 'success'

const success = (response: ManagedMigrationResponse) =>
  createResponse(SUCCESS, response)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await identityApi(
        `api/v2/Migration/NumberOfMigratableManagedCompanies`,
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        return success(JSON.parse(response))
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

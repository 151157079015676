import React from 'react'
import { useSelector } from 'react-redux'

import { Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'
import { AdminLevelPermissionsTreeCard } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/permission-tree-card/AdminLevelPermissionsTreeCard'

interface AdminLevelPermissionTreeFormProps {
  hasManagedAdminPermissions?: boolean
}

export const AdminLevelPermissionTreeForm: React.FunctionComponent<AdminLevelPermissionTreeFormProps> = ({
  hasManagedAdminPermissions = false
}) => {
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const { adminLevel }: AdminLevelDrawerState = useSelector(
    (state: AppState) => state.adminLevelDrawer
  )

  const permissions = hasManagedAdminPermissions
    ? adminLevel.mspCidOverridePermissions
    : adminLevel.permissions

  return (
    <Formik
      initialValues={{
        levelPermission: permissions || []
      }}
      enableReinitialize={true}
      onSubmit={() => {}}
    >
      {formik => (
        <Form onChange={() => setIsBlockingPromptEnabled(true)}>
          <AdminLevelPermissionsTreeCard
            formik={formik}
            disabled
            hasManagedAdminPermissions={hasManagedAdminPermissions}
          />
        </Form>
      )}
    </Formik>
  )
}

import React, { FunctionComponent, ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ValueType } from 'react-select/src/types'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import {
  BodyRegular,
  DetailsDialog,
  Heading100,
  PrimaryButton,
  Select,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { BaseSupportDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface BaseSupportDialogProps {
  state: BaseSupportDialog
  viewSupportRequestsText: ReactElement
  sections: ReactElement[]
  supportPhones: SupportPhonesContent
}

interface SupportPhonesContent {
  english: ReactElement
  german: ReactElement
}

enum SupportLanguages {
  english = 'english',
  german = 'german'
}

export const StyledTextButton = styled(TextButton)`
  margin: 8px 0 0 -4px;
`

export const BaseSupportDialogComponent: FunctionComponent<BaseSupportDialogProps> = ({
  viewSupportRequestsText,
  supportPhones,
  sections
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<
    SupportLanguages | undefined
  >(undefined)

  const { _ } = useLingui()

  const languageOptions = [
    { value: SupportLanguages.english, label: _(msg`English`) },
    { value: SupportLanguages.german, label: _(msg`German`) }
  ]

  const dispatch = useDispatch()
  const closeDialog = () => {
    dispatch(globalActions.discardDialog())
    dispatch(globalActions.emptyDialog())
  }

  const supportDialogDropdownOnChangeHandler = (
    selectedItem: ValueType<{ value: string }>
  ) => {
    if (
      selectedItem &&
      'value' in selectedItem &&
      selectedItem.value in SupportLanguages
    ) {
      setSelectedLanguage(selectedItem.value as SupportLanguages)
    } else {
      setSelectedLanguage(undefined)
    }
  }

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={closeDialog}
      width="720px"
      title={msg`Need help?`}
      content={
        <>
          {sections}

          <Heading100>
            <Trans>Already opened a support ticket?</Trans>
          </Heading100>
          <>
            <LocationLink
              to="https://link.lastpass.com/view-my-cases"
              target="_blank"
              data-qa="SupportDialogViewYourRequestLink"
            >
              {viewSupportRequestsText}
            </LocationLink>
          </>
          <Heading100
            css={css`
              margin-top: 16px;
            `}
          >
            <Trans>Have an urgent issue? Call us.</Trans>
          </Heading100>
          <BodyRegular
            css={css`
              margin: 8px 0;
              display: block;
            `}
          >
            <Trans>
              For real-time assistance, call us. Select a supported language:
            </Trans>
          </BodyRegular>

          <Select
            dataQa="SupportDialogDropdown"
            options={languageOptions}
            placeholder={<Trans>Select a language</Trans>}
            onChange={supportDialogDropdownOnChangeHandler}
            fluid
          />

          {selectedLanguage === SupportLanguages.english && (
            <div data-qa="SupportDialogEnglishContactInformation">
              {supportPhones.english}
            </div>
          )}
          {selectedLanguage === SupportLanguages.german && (
            <div data-qa="SupportDialogGermanContactInformation">
              {supportPhones.german}
            </div>
          )}
        </>
      }
      buttons={[
        <PrimaryButton
          data-qa="PopUpContainerCloseButton"
          onClick={closeDialog}
          key="close"
          css={css`
            margin-top: 10px;
          `}
        >
          <Trans>Close</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

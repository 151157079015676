import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { PasswordlessDrawerSteps } from './state'

export enum PasswordlessDrawerActionTypes {
  INIT_DRAWER_POLICY = '[passwordless-drawer] init-drawer-policy',
  SET_DRAWER_POLICY = '[passwordless-drawer] set-drawer-policy',
  SET_DRAWER_STEP = '[passwordless-drawer] set-step',
  RESET_DRAWER_FORMS = '[passwordless-drawer] reset-forms',
  SET_SEARCH_STRING = '[passwordless-drawer] set-search-string',
  UPDATE_SEARCH_STRING = '[passwordless-drawer] update-search-string',
  SET_POLICY_CURRENTLY_APPLIES_TO = '[passwordless-drawer] set-policy-applies-to',
  SET_POLICY_DATA_TO_SAVE = '[passwordless-drawer] set-policy-data-to-save',
  CALCULATE_POLICY_DATA_TO_SAVE = '[passwordless-drawer] calculate-policy-data-to-save',

  GET_POLICY_USERS_AND_GROUPS = '[passwordless-drawer] get-policy-users-and-groups',
  SET_POLICY_USERS_AND_GROUPS = '[passwordless-drawer] set-policy-users-and-groups',
  SAVE_POLICY_USERS_AND_GROUPS = '[passwordless-drawer] save-policy-users-and-groups',
  SET_POLICY_USERS_AND_GROUPS_NEEDS_REFRESH = '[passwordless-drawer] set-policy-needs-refresh',

  ADD_SELECTED_POLICY_USERS = '[passwordless-drawer] add-selected-users',
  REMOVE_SELECTED_POLICY_USERS = '[passwordless-drawer] remove-selected-users',
  SET_SELECTED_POLICY_USERS = '[passwordless-drawer] set-selected-users',

  ADD_SELECTED_POLICY_GROUPS = '[passwordless-drawer] add-selected-groups',
  REMOVE_SELECTED_POLICY_GROUPS = '[passwordless-drawer] remove-selected-groups',
  SET_SELECTED_POLICY_GROUPS = '[passwordless-drawer] set-selected-groups',

  RESET_POLICY_USERS_AND_GROUPS_TABLES = '[passwordless-drawer] reset-policy-users-and-groups-table',

  CHECK_POLICY_APPLIES_TO = '[passwordless-drawer] check-policy-applies-to',
  SET_POLICY_USERS_AND_GROUPS_OPEN = '[passwordless-drawer] set-policy-users-and-groups-open',

  SET_POLICY_ASSIGN_USERS_LOADING = '[passwordless-drawer] set-policy-assign-users-loading',
  SET_POLICY_ASSIGN_GROUPS_LOADING = '[passwordless-drawer] set-policy-assign-groups-loading',

  SEARCH_POLICY_ASSIGN_USERS_AND_GROUPS = '[passwordless-drawer] search-policy-assign-users-and-groups',

  ADD_SELECTED_POLICY_ASSIGN_USERS = '[passwordless-drawer] add-selected-assign-users',
  REMOVE_SELECTED_POLICY_ASSIGN_USERS = '[passwordless-drawer] remove-selected-assign-users',
  SET_SELECTED_POLICY_ASSIGN_USERS = '[passwordless-drawer] set-selected-assign-users',

  ADD_SELECTED_POLICY_ASSIGN_GROUPS = '[passwordless-drawer] add-selected-assign-groups',
  REMOVE_SELECTED_POLICY_ASSIGN_GROUPS = '[passwordless-drawer] remove-selected-assign-groups',
  SET_SELECTED_POLICY_ASSIGN_GROUPS = '[passwordless-drawer] set-selected-assign-groups',

  SET_POLICY_ASSIGN_USERS_AND_GROUPS = '[passwordless-drawer] set-policy-assign-users-and-groups',
  UPDATE_POLICY_ASSIGN_USERS_AND_GROUPS_TO_BE_ADDED = '[passwordless-drawer] update-policy-assign-users-and-groups-to-be-added'
}

export const passwordlessDrawerActions = {
  initDrawerPolicy: () =>
    createAction(PasswordlessDrawerActionTypes.INIT_DRAWER_POLICY),
  setDrawerPolicy: (policy: TeamsPolicy) =>
    createAction(PasswordlessDrawerActionTypes.SET_DRAWER_POLICY, policy),
  setDrawerStep: (step: PasswordlessDrawerSteps) =>
    createAction(PasswordlessDrawerActionTypes.SET_DRAWER_STEP, step),
  resetDrawerForms: () =>
    createAction(PasswordlessDrawerActionTypes.RESET_DRAWER_FORMS),
  setSearchString: (searchString?: string) =>
    createAction(PasswordlessDrawerActionTypes.SET_SEARCH_STRING, searchString),
  updateSearchString: (searchString?: string) =>
    createAction(
      PasswordlessDrawerActionTypes.UPDATE_SEARCH_STRING,
      searchString
    ),
  setPolicyCurrentlyAppliesTo: (policyCurrentlyAppliesTo: AppliesToOptions) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_CURRENTLY_APPLIES_TO,
      {
        policyCurrentlyAppliesTo
      }
    ),
  setPolicyDataToSave: (policyData: TeamsPolicyModelData[]) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_DATA_TO_SAVE,
      policyData
    ),
  calculatePolicyDataToSave: (
    policyFormData: GeneralPolicyUsersAndGroups,
    isAssign: boolean
  ) =>
    createAction(PasswordlessDrawerActionTypes.CALCULATE_POLICY_DATA_TO_SAVE, {
      policyFormData,
      isAssign
    }),

  getPolicyUsersAndGroups: (searchString?: string) =>
    createAction(PasswordlessDrawerActionTypes.GET_POLICY_USERS_AND_GROUPS, {
      searchString
    }),
  setPolicyUsersAndGroups: (
    policyUsersAndGroups: GeneralPolicyUsersAndGroups
  ) =>
    createAction(PasswordlessDrawerActionTypes.SET_POLICY_USERS_AND_GROUPS, {
      policyUsersAndGroups
    }),
  savePolicyUsersAndGroups: (
    policyKey: string,
    policyData: TeamsPolicyModelData[]
  ) =>
    createAction(PasswordlessDrawerActionTypes.SAVE_POLICY_USERS_AND_GROUPS, {
      policyKey,
      policyData
    }),
  setPolicyUsersAndGroupsNeedsRefresh: (usersAndGroupsNeedsRefresh: boolean) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_USERS_AND_GROUPS_NEEDS_REFRESH,
      usersAndGroupsNeedsRefresh
    ),
  addSelectedPolicyUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_USERS, {
      userList
    }),
  removeSelectedPolicyUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_USERS, {
      userList
    }),
  setSelectedPolicyUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_USERS, {
      userList
    }),
  addSelectedPolicyGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_GROUPS, {
      groupList
    }),
  removeSelectedPolicyGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_GROUPS, {
      groupList
    }),
  setSelectedPolicyGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_GROUPS, {
      groupList
    }),
  resetPolicyTables: () =>
    createAction(
      PasswordlessDrawerActionTypes.RESET_POLICY_USERS_AND_GROUPS_TABLES
    ),
  checkPolicyAppliesTo: (
    policyAppliesTo: AppliesToOptions,
    searchString?: string
  ) =>
    createAction(PasswordlessDrawerActionTypes.CHECK_POLICY_APPLIES_TO, {
      policyAppliesTo,
      searchString
    }),
  setPolicyUsersAndGroupsOpen: (isUsersAndGroupsOpen: boolean) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_USERS_AND_GROUPS_OPEN,
      {
        isUsersAndGroupsOpen
      }
    ),
  setPolicyAssignUsersLoading: (loading: boolean) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_ASSIGN_USERS_LOADING,
      loading
    ),
  setPolicyAssignGroupsLoading: (loading: boolean) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_ASSIGN_GROUPS_LOADING,
      loading
    ),
  addSelectedPolicyAssignUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_ASSIGN_USERS,
      {
        userList
      }
    ),
  removeSelectedPolicyAssignUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_ASSIGN_USERS,
      {
        userList
      }
    ),
  setSelectedPolicyAssignUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_ASSIGN_USERS,
      {
        userList
      }
    ),
  addSelectedPolicyAssignGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_ASSIGN_GROUPS,
      {
        groupList
      }
    ),
  removeSelectedPolicyAssignGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_ASSIGN_GROUPS,
      {
        groupList
      }
    ),
  setSelectedPolicyAssignGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_ASSIGN_GROUPS,
      {
        groupList
      }
    ),
  searchPolicyAssignUsersAndGroups: (
    searchKey: string,
    policyConfigId: number
  ) =>
    createAction(
      PasswordlessDrawerActionTypes.SEARCH_POLICY_ASSIGN_USERS_AND_GROUPS,
      {
        searchKey,
        policyConfigId
      }
    ),
  setPolicyAssignUsersAndGroups: (
    policyUsersAndGroups: GeneralPolicyUsersAndGroups
  ) =>
    createAction(
      PasswordlessDrawerActionTypes.SET_POLICY_ASSIGN_USERS_AND_GROUPS,
      policyUsersAndGroups
    ),
  updatePolicyAssignUsersAndGroupsToBeAdded: (
    toBeAddedUsers: TeamsPolicyUserInfo[],
    toBeAddedGroups: TeamsPolicyGroup[]
  ) =>
    createAction(
      PasswordlessDrawerActionTypes.UPDATE_POLICY_ASSIGN_USERS_AND_GROUPS_TO_BE_ADDED,
      {
        toBeAddedUsers,
        toBeAddedGroups
      }
    )
}

export type PasswordlessDrawerActions = ActionsUnion<
  typeof passwordlessDrawerActions
>

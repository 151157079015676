import React from 'react'

import styled from '@emotion/styled'
import { FormikProps } from 'formik'

import { PermissionArea } from '@lastpass/admin-console-dependencies/types/permissions/permission-area'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

import { Options } from './Options'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

const FirstColumn = styled.div`
  flex-basis: 60%;
  flex-grow: 6;
`

const SecondColumn = styled.div`
  flex-basis: 55%;
  flex-grow: 4;
`

const Container = styled.div`
  display: flex;
`
export interface AreasProps {
  formik: FormikProps<AdminFormValues>
  areas: PermissionArea[]
  disabled?: boolean
}

export const Areas: React.FunctionComponent<AreasProps> = ({
  formik,
  areas,
  disabled
}) => (
  <>
    {areas.map((area, index) => (
      <Row key={index}>
        <FirstColumn data-qa="PermissionName">
          {area.permissionAreaTitle}
        </FirstColumn>
        <SecondColumn data-qa="PermissionValue">
          <Container>
            <Options
              formik={formik}
              options={area.permissionOptions}
              disabled={disabled}
            />
          </Container>
        </SecondColumn>
      </Row>
    ))}
  </>
)

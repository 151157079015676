import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import { UnknownServiceError } from '@lastpass/admin-console-dependencies/server/responses'
import { EnterpriseAPIExampleModel } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/state'

export function createEnterpriseApi(teamsapi: TeamsAPIFetch) {
  function createFetch<TResult>(
    url: string,
    method: string,
    getResult: (response: string) => TResult,
    body?: {}
  ) {
    return async function() {
      try {
        const [response, status] = await teamsapi(url, method, body)
        if (status === StatusCodes.OK && response) {
          return getResult(response)
        }
      } catch (e) {
        throw new UnknownServiceError()
      }
    }
  }

  return {
    getProvisioningHash: createFetch<boolean>(
      'my-company/has-enterprise-api-key',
      'GET',
      response => {
        const keyResponse = JSON.parse(response)
        return keyResponse.hasCustomKey
      }
    ),
    apiExamples: createFetch<EnterpriseAPIExampleModel[]>(
      'my-company/enterpriseapiexamples',
      'GET',
      response => {
        return JSON.parse(response)
      }
    ),
    emailVerified: createFetch<boolean>(
      'my-company/isemailverified',
      'GET',
      response => {
        return response === 'true'
      }
    ),
    generateProvisioningHash: createFetch<string>(
      'my-company/generateprovhash',
      'GET',
      response => response
    ),
    resetAdKey: createFetch<void>(
      'my-company/reset-enterprise-api-ad-key',
      'POST',
      () => {
        return
      },
      {}
    ),
    whitelistedIps: createFetch<string>(
      'my-company/provisionwlips',
      'GET',
      response => response
    )
  }
}

export type EnterpriseApiServiceType = ReturnType<typeof createEnterpriseApi>

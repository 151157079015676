export enum BulkActionErrors {
  cannotBeCreated = 'cannot_be_created',
  cannotBeDisabled = 'cannot_be_disabled',
  resourceNotFound = 'resource_not_found',
  failedToAssign = 'failed_to_assign',
  failedToDelete = 'failed_to_delete',
  alreadyExists = 'already_exists',
  cannotBeInvited = 'cannot_be_invite',
  cannotBeUninvited = 'cannot_be_uninvite',
  cannotrequireMpChange = 'cannot_require_master_password_change',
  userCreateUnknown = 'USER_CREATE_ERROR_UNKNOWN',
  userCreateExistsMarkedForDelete = 'USER_CREATE_ERROR_EXISTS_AND_MARKED_FOR_DELETE',
  userCreateExistsInCompany = 'USER_CREATE_ERROR_EXISTS_IN_TARGET_COMPANY',
  userCreateExistsAndInvited = 'USER_CREATE_ERROR_EXISTS_AND_ALREADY_INVITED',
  userCreateExistsNoInvite = 'USER_CREATE_ERROR_EXISTS_AND_NO_INVITE_REQUESTED',
  userCreateInvalidEmail = 'USER_CREATE_ERROR_INVALID_EMAIL',
  userCreatePolicyViolation = 'USER_CREATE_ERROR_USERNAME_POLICY_VIOLATION',
  deleteSelf = 'error_cannot_delete_self',
  notMemberOfCompany = 'error_not_member_of_the_company',
  cannotDisable = 'lastpass_error_cannot_disable',
  notMemberOfGroup = 'error_not_member_of_group',
  cannotSelectForVaultReEncryption = 'cannot_select_for_vault_reencryption',
  errorNotFederated = 'error_not_federated',
  errorDestroySessionFailed = 'error_destroy_session_failed',
  na = 'NA'
}

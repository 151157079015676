import React, { FC } from 'react'

import styled from '@emotion/styled'
import { useLingui } from '@lingui/react'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { BodyBold, WithTooltip } from '@lastpass/lastkit'

import { LegendItem as LegendItemType } from './Legend'

const LegendIcon = styled.span<{
  backgroundColor: string
  borderColor: string
}>`
  display: block;
  width: 16px;
  height: 16px;
  background-color: ${prop => prop.backgroundColor};
  border: 1px solid ${prop => prop.borderColor};
  border-radius: 4px;
  margin-right: 4px;
`
const LegendText = styled.div`
  line-height: 26px;
  font-size: 14px;
`
const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral500};
  height: 14px;
  width: 14px;
  margin-left: 8px;
`

export interface LegendItemProps extends LegendItemType {
  borderColor: string
  dataQa?: string
}

export const LegendItem: FC<LegendItemProps> = ({
  value,
  label,
  backgroundColor,
  borderColor,
  tooltip,
  dataQa
}) => {
  const { _ } = useLingui()

  return (
    <LegendItemContainer data-qa={dataQa && dataQa + 'Container'}>
      <LegendIcon
        data-qa={dataQa && dataQa + 'Icon'}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      />
      <LegendText data-qa={dataQa && dataQa + 'Text'}>
        {_(label)}
        {': '}
        <BodyBold data-qa={dataQa && dataQa + 'Value'}>{value}</BodyBold>
      </LegendText>
      {tooltip && (
        <WithTooltip tooltip={tooltip} width="450px">
          <StyledHelpIcon />
        </WithTooltip>
      )}
    </LegendItemContainer>
  )
}

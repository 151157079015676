import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { Dispatch } from 'redux'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'

import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'

export interface ManagedCompanyListOperation {
  name: MessageDescriptor | string
  callback: () => void
}

export enum ManagedCompanyListOperationTypes {
  exportManagedCompanies = 'exportManagedCompanies',
  configurePSAIntegration = 'configurePSAIntegration',
  exportUserCounts = 'exportUserCounts'
}

export type ManagedCompanyListOperationMap = {
  [key in ManagedCompanyListOperationTypes]: ManagedCompanyListOperation
}
export const getOperations = (
  dispatch: Dispatch,
  isPsaIntegrationEnabled: boolean
): ManagedCompanyListOperationMap => ({
  [ManagedCompanyListOperationTypes.exportManagedCompanies]: {
    name: msg`Export managed companies`,
    callback: () => {
      dispatch(managedCompanyOperationActions.exportManagedCompanies())
    }
  },
  [ManagedCompanyListOperationTypes.configurePSAIntegration]: {
    name: msg`Configure new PSA integration`,
    callback: () => {
      isPsaIntegrationEnabled
        ? window.location.replace('/advanced/siemIntegrations')
        : window.open(
            `
        ${defaultConfig.lastPassBaseAddress}/company/?resetconsole=true#!/settings/psa-settings`,
            '_blank'
          )
    }
  },
  [ManagedCompanyListOperationTypes.exportUserCounts]: {
    name: msg`Export user counts used for billing`,
    callback: () => {
      dispatch(managedCompanyOperationActions.showExportUserCountsDialog())
    }
  }
})

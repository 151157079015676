import {
  SecureAuthSettingsActions,
  SecureAuthSettingsActionTypes
} from './actions'
import { SecureAuthSettingsState } from './state'

export const initialState: SecureAuthSettingsState = {
  applicationId: '',
  applicationKey: '',
  realm: '',
  loading: false
}

export const secureAuthSettingsReducer = (
  previousState: SecureAuthSettingsState | undefined,
  action: SecureAuthSettingsActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_SET:
      return {
        ...state,
        ...action.payload.secureAuthSettings
      }
    case SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    default:
      return state
  }
}

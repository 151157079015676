import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createGetADVersionLastSync(userServices: UACServices.Services) {
  return function* getADVersionLastSync() {
    try {
      const lastSyncVersionResult: UACServices.ADConnectorVersionLastSyncGetAPI.Responses = yield call(
        userServices.adConnectorLastSyncVersion
      )
      if (lastSyncVersionResult) {
        yield put(
          directoriesActions.setADConnectoreVersionLastSync(
            lastSyncVersionResult.body.lastSyncVersion
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { all, call, select } from 'redux-saga/effects'
import { put } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  AccessPoliciesGetApi,
  GeneralPoliciesListGetAPI,
  GeofencePoliciesGetApi,
  IpPoliciesGetApi,
  LegacyVpnListAPI,
  WorkstationListAPI
} from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { companyHasMfa } from '@lastpass/admin-console-dependencies/services/get-company-entitlements'
import { legacyVpnListActions } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/actions'
import { workstationListActions } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import {
  GeofencePolicyMap,
  IpPolicyMap
} from '@lastpass/admin-console-dependencies/state/policies/passwordless/passwordless'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { mapGetPolicyListResultToPolicyMap } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

export const RequirePasswordlessViaLastPassPolicyKey =
  'requirepasswordlessvialastpassauth'

export const details = (state: AppState) => state.companyInfo.details

function checkUserListEmpty(policyData) {
  return (
    (!policyData.exclusiveList || policyData.exclusiveList.length === 0) &&
    (!policyData.exclusiveGroupList ||
      policyData.exclusiveGroupList.length === 0) &&
    (!policyData.inclusiveList || policyData.inclusiveList.length === 0) &&
    (!policyData.inclusiveGroupList ||
      policyData.inclusiveGroupList.length === 0)
  )
}

function isRequiredPolicyUsed(policyData) {
  return (
    policyData.length === 0 ||
    (policyData.length > 0 && checkUserListEmpty(policyData[0]))
  )
}

export const passwordlessNotConfigured = (state: AppState) =>
  state.passwordlessContainer.AccessPolicies.total === 0 &&
  state.passwordlessContainer.IpPolicies.total === 0 &&
  state.passwordlessContainer.GeofencePolicies.total === 0 &&
  (!state.passwordlessContainer.RequirePasswordlessViaLastPassPolicy.checked ||
    isRequiredPolicyUsed(
      state.passwordlessContainer.RequirePasswordlessViaLastPassPolicy
    ))

export function createInitialData(passwordlessServices: UACServices.Services) {
  return function* getInitialData() {
    try {
      yield put(passwordlessActions.setLoading(true))

      const companyDetails = yield select(details)

      const hasMfa = companyHasMfa(companyDetails)

      try {
        let getAccessPoliciesResponse:
            | AccessPoliciesGetApi.Responses
            | undefined,
          getIpPoliciesResponse: IpPoliciesGetApi.Responses | undefined,
          getGeofencePoliciesResponse:
            | GeofencePoliciesGetApi.Responses
            | undefined,
          generalPoliciesListGetResponse:
            | GeneralPoliciesListGetAPI.Responses
            | undefined,
          getWorkstationListResponse: WorkstationListAPI.Responses | undefined,
          getLegacyVpnListResponse: LegacyVpnListAPI.Responses | undefined

        if (hasMfa) {
          ;[
            getAccessPoliciesResponse,
            getIpPoliciesResponse,
            getGeofencePoliciesResponse,
            generalPoliciesListGetResponse,
            getWorkstationListResponse,
            getLegacyVpnListResponse
          ] = yield all([
            call(passwordlessServices.getAccessPolicies, '', 1, 0),
            call(passwordlessServices.getIpPolicies),
            call(passwordlessServices.getGeofencePolicies),
            call(passwordlessServices.generalPoliciesListGet),
            call(passwordlessServices.getWorkstationList),
            call(passwordlessServices.getLegacyVpnList)
          ])
        } else {
          ;[
            getAccessPoliciesResponse,
            getIpPoliciesResponse,
            generalPoliciesListGetResponse
          ] = yield all([
            call(passwordlessServices.getAccessPolicies, '', 1, 0),
            call(passwordlessServices.getIpPolicies),
            call(passwordlessServices.generalPoliciesListGet)
          ])
        }

        if (
          getAccessPoliciesResponse &&
          getAccessPoliciesResponse.type === AccessPoliciesGetApi.SUCCESS
        ) {
          yield put(
            passwordlessActions.setAccessPolicies(
              getAccessPoliciesResponse.body.response
            )
          )
        }

        if (
          getIpPoliciesResponse &&
          getIpPoliciesResponse.type === IpPoliciesGetApi.SUCCESS
        ) {
          const ipPolicies: IpPolicyMap = {
            results: getIpPoliciesResponse.body.response,
            total: getIpPoliciesResponse.body.response.length
          }
          yield put(passwordlessActions.setIpPolicies(ipPolicies))
        }

        if (
          getGeofencePoliciesResponse &&
          getGeofencePoliciesResponse.type === GeofencePoliciesGetApi.SUCCESS
        ) {
          const geofencePolicies: GeofencePolicyMap = {
            results: getGeofencePoliciesResponse.body.response,
            total: getGeofencePoliciesResponse.body.response.length
          }
          yield put(passwordlessActions.setGeofencePolicies(geofencePolicies))
        }

        if (
          generalPoliciesListGetResponse &&
          generalPoliciesListGetResponse.type ===
            GeneralPoliciesListGetAPI.SUCCESS
        ) {
          const requirePasswordlessViaLastpassPolicy: TeamsPolicy = mapGetPolicyListResultToPolicyMap(
            generalPoliciesListGetResponse.body.generalPoliciesListResponse
          )[0][RequirePasswordlessViaLastPassPolicyKey]

          yield put(
            passwordlessActions.setRequirePasswordlessViaLastPassPolicy(
              requirePasswordlessViaLastpassPolicy
            )
          )
        }

        if (
          getWorkstationListResponse &&
          getWorkstationListResponse.type ===
            UACServices.WorkstationListAPI.SUCCESS
        ) {
          const workstationListResponse =
            getWorkstationListResponse.body.workstationListResponse
          yield put(
            workstationListActions.setWorkstationList(
              workstationListResponse.results,
              workstationListResponse.total
            )
          )
        }

        if (
          getLegacyVpnListResponse &&
          getLegacyVpnListResponse.type === UACServices.LegacyVpnListAPI.SUCCESS
        ) {
          yield put(
            legacyVpnListActions.setLegacyVpnList(
              getLegacyVpnListResponse.body.legacyVpnListResponse.Value
            )
          )
        }
      } finally {
        yield put(passwordlessActions.setLoading(false))
        yield put(
          passwordlessActions.setEmptyState(
            yield select(passwordlessNotConfigured)
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'

import { AdvancedPolicyDrawerSteps } from './advanced-policy-drawer-steps'

export enum AdvancedPolicyDrawerActionTypes {
  DISCARD = '[advanced-policy-drawer] discard',
  NEXT_STEP = '[advanced-policy-drawer] next-step',
  SET_POLICY = '[advanced-policy-drawer] set-policy',
  SET_POLICY_CONFIG = '[advanced-policy-drawer] set-policy-config',
  SAVE_POLICY_CONFIG = '[advanced-policy-drawer] save-policy-config',
  SAVE_ADVANCED_POLICY = '[advanced-policy-drawer] save-advanced-policy'
}

export const advancedPolicyDrawerActions = {
  discard: () => createAction(AdvancedPolicyDrawerActionTypes.DISCARD),
  nextStep: (nextStep: AdvancedPolicyDrawerSteps) =>
    createAction(AdvancedPolicyDrawerActionTypes.NEXT_STEP, { nextStep }),
  setPolicy: (
    policy: TeamsPolicy,
    dataIndex?: number,
    policyNotes?: string,
    policyValue?: string | null
  ) =>
    createAction(AdvancedPolicyDrawerActionTypes.SET_POLICY, {
      policy,
      dataIndex,
      policyNotes,
      policyValue
    }),
  setPolicyConfig: (policyNotes?: string, policyValue?: string | null) =>
    createAction(AdvancedPolicyDrawerActionTypes.SET_POLICY_CONFIG, {
      policyValue,
      policyNotes
    }),
  savePolicyConfig: (closeLink: string) =>
    createAction(AdvancedPolicyDrawerActionTypes.SAVE_POLICY_CONFIG, {
      closeLink
    }),
  saveAdvancedPolicy: (closeLink: string) =>
    createAction(AdvancedPolicyDrawerActionTypes.SAVE_ADVANCED_POLICY, {
      closeLink
    })
}

export type AdvancedPolicyDrawerActions = ActionsUnion<
  typeof advancedPolicyDrawerActions
>

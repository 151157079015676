import React, { FunctionComponent, useContext } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'

import { Checkbox, TextInput } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'

import {
  StyledChildRow,
  StyledPrimaryButton,
  StyledTextInput
} from './StyledComponents'

const TextInputWithClipboard = styled(TextInput)`
  width: 495px;
`

export interface AdSetupProps {
  providerUrl: string
  publicKey: string
  isEnabled: boolean
  useCompanyWideKey: boolean
  providerLoginUrl: string
  isPingFederate: boolean
}

export interface AdSetupFormProps {
  initialValues: AdSetupProps

  dataQaName: string
}

export const AdSetupForm: FunctionComponent<AdSetupFormProps> = ({
  initialValues,
  dataQaName
}) => {
  const dispatch = useDispatch()
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        dispatch(
          federatedLoginActions.saveAdfsSetup({
            providerUrl: values.providerUrl,
            publicKey: values.publicKey,
            isEnabled: values.isEnabled,
            useCompanyWideKey: initialValues.isPingFederate
              ? true
              : values.useCompanyWideKey,
            providerLoginUrl: values.providerLoginUrl,
            isPingFederate: initialValues.isPingFederate
          })
        )
      }}
    >
      {formikProps => {
        return (
          <>
            <StyledChildRow>
              <Field name="providerUrl">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa={`${dataQaName}ProviderUrl`}
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Provider URL</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="publicKey">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa={`${dataQaName}PublicKey`}
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Public key</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="providerLoginUrl">
                {formData => {
                  return (
                    <TextInputWithClipboard
                      data-qa={`${dataQaName}ProviderLoginUrl`}
                      name={formData.field.name}
                      value={formData.field.value}
                      actionOnCopy={() => {
                        dispatch(
                          globalActions.setNotification(
                            CopyToClipboardNotification
                          )
                        )
                      }}
                      disabled
                      copyToClipboard
                    >
                      <Trans>
                        LastPass Assertion Consumer Service (ACS) URI
                      </Trans>
                    </TextInputWithClipboard>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="isEnabled">
                {formData => {
                  return (
                    <Checkbox
                      data-qa={`${dataQaName}EnableCheckBox`}
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Enabled</Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              {!initialValues.isPingFederate && (
                <Field name="useCompanyWideKey">
                  {formData => {
                    return (
                      <Checkbox
                        data-qa={`${dataQaName}UseCompanyWideKeyCheckBox`}
                        name={formData.field.name}
                        checked={formData.field.value}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.checked
                          )
                          dispatch(
                            federatedLoginActions.setAdAttributeVisible(
                              !e.currentTarget.checked
                            )
                          )
                        }}
                        disabled={!hasDirectoriesAndFederationModifyPermission}
                      >
                        <Trans>Use company-wide key</Trans>
                      </Checkbox>
                    )
                  }}
                </Field>
              )}
              <StyledPrimaryButton
                data-qa={`${dataQaName}SaveChangesButton`}
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                disabled={!hasDirectoriesAndFederationModifyPermission}
              >
                <Trans>Save changes</Trans>
              </StyledPrimaryButton>
            </StyledChildRow>
          </>
        )
      }}
    </Formik>
  )
}

import { takeLatest } from 'redux-saga/effects'

import { createCalculatePolicyDataToSaveSaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/calculate-policy-data-to-save'
import { createCheckPolicyAppliesToPolicySaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/check-policy-applies-to'
import { createDisablePolicySaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/disable-policy'
import { createEnableRequirePasswordlessViaLastpassPolicy } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/enable-require-passwordless-via-lastpass-policy'
import { createInitialData } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/get-initial-data'
import { createPasswordlessGetStatus } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/get-passwordless-status'
import { createGetPolicyUsersAndGroupsSaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/get-policy-users'
import { createInitDrawerPolicySaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/init-drawer-policy'
import { createSavePolicyUsersAndGroupsSaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/save-policy-users'
import { createSearchAssignUsersAndGroupsPolicySaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/search-policy-assign-users-and-groups'
import { createUpdateSearchStringSaga } from '@lastpass/admin-console-dependencies/sagas/policies/passwordless/update-search-string'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { PasswordlessActionTypes } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { PasswordlessDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'

export function createPasswordlessSaga(
  passwordlessService: UACServices.Services
) {
  const getInitialData = createInitialData(passwordlessService)
  const getPasswordlessStatus = createPasswordlessGetStatus(passwordlessService)
  const getPolicyUsersAndGroups = createGetPolicyUsersAndGroupsSaga()
  const checkPolicyAppliesToPolicy = createCheckPolicyAppliesToPolicySaga()
  const savePolicyUsersAndGroups = createSavePolicyUsersAndGroupsSaga(
    passwordlessService
  )
  const calculatePolicyDataToSave = createCalculatePolicyDataToSaveSaga()
  const initDrawerPolicy = createInitDrawerPolicySaga(passwordlessService)
  const updateSearchString = createUpdateSearchStringSaga()
  const searchAssignUsersAndGroupsPolicy = createSearchAssignUsersAndGroupsPolicySaga(
    passwordlessService
  )
  const enableRequirePasswordlessViaLastpassPolicy = createEnableRequirePasswordlessViaLastpassPolicy(
    passwordlessService
  )
  const disablePolicy = createDisablePolicySaga(passwordlessService)

  return function*() {
    yield takeLatest(PasswordlessActionTypes.FETCH_INITIAL_DATA, getInitialData)
    yield takeLatest(
      PasswordlessActionTypes.FETCH_PASSWORDLESS_STATUS,
      getPasswordlessStatus
    )
    yield takeLatest(
      PasswordlessActionTypes.ENABLE_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY,
      enableRequirePasswordlessViaLastpassPolicy
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.INIT_DRAWER_POLICY,
      initDrawerPolicy
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.GET_POLICY_USERS_AND_GROUPS,
      getPolicyUsersAndGroups
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.CHECK_POLICY_APPLIES_TO,
      checkPolicyAppliesToPolicy
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.SAVE_POLICY_USERS_AND_GROUPS,
      savePolicyUsersAndGroups
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.CALCULATE_POLICY_DATA_TO_SAVE,
      calculatePolicyDataToSave
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.UPDATE_SEARCH_STRING,
      updateSearchString
    )
    yield takeLatest(
      PasswordlessDrawerActionTypes.SEARCH_POLICY_ASSIGN_USERS_AND_GROUPS,
      searchAssignUsersAndGroupsPolicy
    )
    yield takeLatest(PasswordlessActionTypes.DISABLE_POLICY, disablePolicy)
  }
}

import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import _ from 'lodash'

import { Chevron } from '@lastpass/components'
// TODO remove circular dependency on @lastpass/routing
import { LocationLink } from '@lastpass/routing/LocationLink'

import { TableAction } from '@lastpass/admin-console-dependencies/types/record-action'
import { Table as TableType } from '@lastpass/admin-console-dependencies/types/table'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'

import { BodyRegularStyle, CaptionSemiboldStyle } from '../styles'
import { Theme as theme } from '../theme'
import { Checkbox } from './Checkbox'
import { ConditionalWrapper } from './ConditionalWrapper'
import { Heading100 } from './Heading'
import { MoreAction } from './MoreAction'
import { HeaderTextButton } from './MoreAction'
import { RadioButton } from './RadioButton'
import { WithTooltip } from './Tooltip'

export interface TableProps<T> {
  table: TableType<T>
  columns: TableColumn<T>[]
  getRecordLink?: (record: T, index: number) => string
  recordLinkDestination?: (record: T) => string
  onRowClick?: (record: T) => void
  checkable?: {
    addSelectedRecords: (records: T[]) => void
    removeSelectedRecords: (records: T[]) => void
    setSelectedRecords: (records: T[]) => void
    setSelectAllRecords?: (selectAll: boolean) => void
  }
  noRecordsView?: React.ReactElement
  noResultsView?: React.ReactElement
  loadingView?: React.ReactElement
  actions?: TableAction<T>[]
  header?: React.ReactElement
  minimumHeight?: string
  disableRowHover?: boolean
  disableIndividualRowHover?: (record: T) => boolean
  isFilterApplied?: boolean
  visibleActionsLength?: number
  qadata?: string
  orderByExpression?: string
  onOrderingChanged?: (sortString: string) => void
  hideHeaderWhenEmpty?: boolean
  isAllSelectable?: boolean
  showSelectedNumber?: boolean
  isSearchApplied?: boolean
  selectedRecordLimit?: number
  useRadioButtons?: boolean
  radioButtonAction?: (record: T) => void
  conditionalRowColor?: (record: T) => string | undefined
  rowTooltips?: {
    selectedRecordKey: keyof T
    selectedRowId: string
    content: JSX.Element
  }[]
}

export const TableContainer = styled.div<{
  alignContentCenter?: boolean
}>`
  ${BodyRegularStyle}
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  flex-wrap: wrap;
  ${props =>
    props.alignContentCenter &&
    css`
      min-height: 100%;
      align-items: center;
      justify-content: center;
    `}
`

export const Row = styled.div<{
  background?: string
  checked?: boolean
  disableRowHover?: boolean
  disableIndividualRowHover?: boolean
}>`
  display: flex;
  flex-direction: row;
  background: ${props =>
    props.checked
      ? props.theme.colors.blue200
      : props.background || 'transparent'};
  &:hover {
    background: ${props =>
      props.checked
        ? props.theme.colors.blue200
        : props.disableRowHover || props.disableIndividualRowHover
        ? 'transparent'
        : props.theme.colors.blue100};
  }
`

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px -1px 0px ${props => props.theme.colors.neutral200};
`
const LinkRow = styled(LocationLink)<{
  disableIndividualRowHover?: boolean
}>`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  cursor: ${props => (props.disableIndividualRowHover ? 'default' : 'pointer')};
`

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-self: center;
`

const TableHead = styled(Row)`
  ${CaptionSemiboldStyle}
  box-shadow: inset 0px -1px 0px ${props => props.theme.colors.neutral200};
  color: ${props => props.theme.colors.neutral600};
  height: 40px;
  overflow: visible;
  &:hover {
    background: transparent;
  }
`

const CellContent = styled.div<{ contentWidth?: string }>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TableHeader = styled(Heading100)`
  margin: 16px 0px 16px 16px;
  &:hover {
    background: transparent;
  }
`

const WarningText = styled.div`
  color: red;
  display: inline-block;
  padding-left: 10px;
`

export const Cell = styled.div<{ contentWidth?: string }>`
  color: ${props => props.theme.colors.neutral900};
  min-width: ${props => (props.contentWidth ? 'auto' : '10px')};
  ${props => !props.contentWidth && 'width: 10px;'}
  display: block;
  align-self: center;
  padding: ${props => (props.contentWidth ? '11px' : '12px')};
  padding-right: ${props => (props.contentWidth ? '4px' : '12px')};
  flex-basis: ${props => (props.contentWidth ? props.contentWidth : '100%')};
  background: transparent;
  position: relative;
  height: 48px;
  box-sizing: border-box;
`

export const CheckboxCell = styled(Cell)`
  margin-left: 13px;
  flex-shrink: 0;
`

export const HeaderCheckboxCell = styled(CheckboxCell)`
  padding-top: 12px;
  padding-bottom: 12px;
`

export const HeaderCell = styled(Cell)`
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;

  &:first-of-type {
    padding: 16px;
  }
`

const TableRowWrapper = styled.div`
  width: 100%;
`

const TableViewsWrapper = styled.div<{
  tableHasRecords: boolean
  minimumHeight: string
}>`
  min-height: ${props =>
    props.minimumHeight
      ? props.minimumHeight
      : props.tableHasRecords
      ? 'auto'
      : '400px'};
`

const HeaderCellTitle = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
`

const ArrowContainer = styled.div`
  padding-left: 5px;
  padding-top: 3px;
`

const SelectAllRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  width: 100%;
  padding: 4px 0px 4px 27px;
  justify-content: start;
  background: ${props => props.theme.colors.blue50};
`

const SelectAllRowItem = styled.div`
  padding-right: 12px;
`

const RowTooltip = styled.div`
  position: relative;
  margin: 0 20px 10px 57px;
  background-color: ${props => props.theme.colors.yellow700};

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent
      ${props => props.theme.colors.yellow700}
      ${props => props.theme.colors.yellow700};
  }
`
const RowTooltipContent = styled.p`
  margin-left: 10px;
  padding: 5px;
`

const areCheckedRecordsSameAsResults = <T extends {}>(
  checkedRecords: T[],
  tableResults: T[]
): boolean => {
  let resultSameAsChecked = true
  tableResults.forEach(el => {
    if (!checkedRecords.includes(el)) {
      resultSameAsChecked = false
    }
    return true
  })
  return resultSameAsChecked
}

const getShowSelectAllRow = <T extends {}>(props: TableProps<T>): boolean =>
  !!props.table.checkedRecords.length &&
  areCheckedRecordsSameAsResults(
    props.table.checkedRecords,
    props.table.results
  ) &&
  props.isAllSelectable === true &&
  !props.table.isAllSelected === true &&
  !props.isSearchApplied === true &&
  props.table.totalResults > props.table.resultsPerPage

const areSomeResultsChecked = <T extends {}>(
  checkedRecords: T[],
  tableResults: T[]
): boolean => {
  return tableResults.some(el => !checkedRecords.includes(el))
}

export const Table = <T extends {}>(props: TableProps<T>) => {
  const isTableLoading = props.table.isLoading

  const isTableEmpty =
    !props.table.isLoading &&
    !props.table.results.length &&
    !props.isFilterApplied
  const isFilterResultsEmpty =
    props.isFilterApplied && !props.table.results.length
  const defaultVisibleActionsLength = 3
  const visibleActionsLength =
    props.visibleActionsLength || defaultVisibleActionsLength

  const buildSortString = sortString => {
    if (
      props.orderByExpression &&
      props.orderByExpression.includes(sortString) &&
      !props.orderByExpression.includes('desc')
    ) {
      return sortString + ' desc'
    }
    return sortString
  }

  const hideHeader =
    props.hideHeaderWhenEmpty &&
    !props.isFilterApplied &&
    !props.table.results.length

  const alignContentCenter =
    hideHeader || (props.hideHeaderWhenEmpty && props.table.isLoading)

  const deselectAll = () => {
    if (props.checkable) {
      if (props.checkable.setSelectAllRecords) {
        props.checkable.setSelectAllRecords(false)
      }
      props.checkable.setSelectedRecords([])
    }
  }

  const removeSelection = (item: T) => {
    if (props.checkable) {
      if (props.table.isAllSelected && props.checkable.setSelectAllRecords) {
        props.checkable.setSelectAllRecords(false)
        props.checkable.setSelectedRecords(props.table.results)
      }
      props.checkable.removeSelectedRecords([item])
    }
  }

  const getSelectedItemCount = () => {
    if (props.table.isAllSelected) {
      return props.table.totalResults
    }

    return props.table.checkedRecords.length
  }

  const isRowChecked = (item: T) => {
    return (
      props.table.isAllSelected ||
      !!props.table.checkedRecords.find(element => _.isEqual(element, item))
    )
  }

  const executeAction = action => {
    return () => {
      const isAllSelected = props.table.isAllSelected === true
      action.callback(
        isAllSelected ? [] : props.table.checkedRecords,
        isAllSelected
      )
    }
  }

  const getMaxSelectedRecordWarning = () => {
    if (props.selectedRecordLimit === undefined) {
      return <></>
    }

    return getSelectedItemCount() > props.selectedRecordLimit ? (
      <WarningText data-qa={'TableSelectedCountWarningText'}>
        <Trans>
          You can assign up to {props.selectedRecordLimit} users at a time.
        </Trans>
      </WarningText>
    ) : (
      <></>
    )
  }

  const tableHasSelectionOrAllSelectedWithAction =
    !!props.table.checkedRecords.length ||
    (props.table.isAllSelected && props.actions)

  const tableHead = hideHeader ? null : (
    <TableHead>
      {props.checkable && props.table.results.length > 0 && (
        <HeaderCheckboxCell contentWidth={'auto'}>
          <Checkbox
            checked={
              (props.table.checkedRecords.length > 0 &&
                areCheckedRecordsSameAsResults(
                  props.table.checkedRecords,
                  props.table.results
                )) ||
              props.table.isAllSelected
            }
            onChange={event => {
              props.checkable &&
                (event.target.checked
                  ? props.checkable.setSelectedRecords(props.table.results)
                  : deselectAll())
            }}
            indeterminate={
              props.table.checkedRecords.length > 0 &&
              !areCheckedRecordsSameAsResults(
                props.table.checkedRecords,
                props.table.results
              ) &&
              areSomeResultsChecked(
                props.table.checkedRecords,
                props.table.results
              )
            }
            data-qa={'TableCheckBoxSelectAll'}
          />
        </HeaderCheckboxCell>
      )}

      {props.useRadioButtons && <HeaderCell contentWidth="80px" />}

      {(props.table.isAllSelected ||
        (!!props.table.checkedRecords.length &&
          (props.actions || props.showSelectedNumber))) && (
        <>
          <HeaderCell
            contentWidth={'110px'}
            data-qa={'TableSelectedCount'}
            css={css`
              white-space: nowrap;
            `}
          >
            <Trans>Selected:</Trans> {getSelectedItemCount()}{' '}
            {getMaxSelectedRecordWarning()}
          </HeaderCell>
          {props.table.totalResults > 0 && (
            <ActionRow>
              {props.actions &&
                (props.table.isAllSelected ||
                  !!props.table.checkedRecords.length) &&
                props.actions
                  .slice(0, visibleActionsLength)
                  .map((action, index) => {
                    const isValid = action.isValid
                      ? action.isValid
                      : () => {
                          return true
                        }

                    if (action.dropdown) {
                      return action.content
                    }

                    return (
                      !action.hidden &&
                      isValid(props.table.checkedRecords) &&
                      !(
                        action.singleItem &&
                        props.table.checkedRecords.length !== 1
                      ) && (
                        <HeaderTextButton
                          key={index}
                          color={action.color}
                          onClick={executeAction(action)}
                          data-qa={action.tag ?? 'TableActionButton'}
                        >
                          {action.name}
                        </HeaderTextButton>
                      )
                    )
                  })}
              {props.actions && props.actions.length > visibleActionsLength && (
                <MoreAction
                  actions={props.actions}
                  table={props.table}
                  visibleActionsLength={visibleActionsLength}
                  executeAction={executeAction}
                />
              )}
            </ActionRow>
          )}
        </>
      )}

      {(!tableHasSelectionOrAllSelectedWithAction || props.useRadioButtons) &&
        props.columns.map((column, index) => {
          let descending = false
          let renderArrow = false
          if (props.orderByExpression) {
            descending = props.orderByExpression.includes('desc')

            if (column.columnOrderByName) {
              renderArrow = props.orderByExpression.includes(
                column.columnOrderByName
              )
            }
          }

          const arrow = descending ? (
            <Chevron direction="down" color={theme.colors.neutral600} />
          ) : (
            <Chevron direction="up" color={theme.colors.neutral600} />
          )

          return (
            <HeaderCell
              contentWidth={column.contentWidth}
              key={index}
              data-qa={'TableHeaderCell'}
            >
              <HeaderCellTitle
                onClick={() => {
                  if (props.onOrderingChanged) {
                    props.onOrderingChanged(
                      buildSortString(column.columnOrderByName)
                    )
                  }
                }}
              >
                {column.name}
                <ArrowContainer>{renderArrow ? arrow : <></>}</ArrowContainer>
              </HeaderCellTitle>
            </HeaderCell>
          )
        })}
    </TableHead>
  )

  const showSelectAllRow = getShowSelectAllRow<T>(props)

  return (
    <TableContainer
      alignContentCenter={alignContentCenter}
      data-qa={props.qadata}
    >
      {props.header && <TableHeader>{props.header}</TableHeader>}
      {tableHead}
      {showSelectAllRow && (
        <SelectAllRow>
          <SelectAllRowItem>
            <Trans>All users on this page are selected.</Trans>
          </SelectAllRowItem>
          <p>
            <HeaderTextButton
              onClick={() => {
                props.checkable &&
                  props.checkable.setSelectAllRecords &&
                  props.checkable.setSelectAllRecords(true)
              }}
            >
              {props.isFilterApplied ? (
                <Trans>
                  Select all matching users: {props.table.totalResults}
                </Trans>
              ) : (
                <Trans>
                  Select all users in your organization:{' '}
                  {props.table.totalResults}
                </Trans>
              )}
            </HeaderTextButton>
          </p>
        </SelectAllRow>
      )}
      <TableRowWrapper>
        {props.table.results.map((record, index) => {
          const rowChecked =
            !!(
              props.checkable &&
              !!props.table.checkedRecords.find(element =>
                _.isEqual(element, record)
              )
            ) || props.table.isAllSelected
          return (
            <RowContent data-qa="TableRowContent" key={index}>
              <Row
                data-qa="TableRow"
                background={
                  props.conditionalRowColor && props.conditionalRowColor(record)
                }
                checked={rowChecked}
                disableRowHover={props.disableRowHover}
                disableIndividualRowHover={
                  props.disableIndividualRowHover
                    ? props.disableIndividualRowHover(record)
                    : false
                }
              >
                {props.checkable && (
                  <CheckboxCell contentWidth={'auto'}>
                    <Checkbox
                      onChange={event =>
                        props.checkable &&
                        (event.target.checked
                          ? props.checkable.addSelectedRecords([record])
                          : removeSelection(record))
                      }
                      checked={isRowChecked(record)}
                      data-qa={'TableCheckBoxSelectOne'}
                    />
                  </CheckboxCell>
                )}
                {props.useRadioButtons && (
                  <CheckboxCell contentWidth={'auto'}>
                    <RadioButton
                      onChange={() => {
                        if (props.radioButtonAction) {
                          props.radioButtonAction(record)
                        }
                      }}
                      option={{
                        value: ''
                      }}
                      checked={isRowChecked(record)}
                      data-qa={'TableCheckBoxSelectOne'}
                    />
                  </CheckboxCell>
                )}
                <ConditionalWrapper
                  key={index + 'ConditionalWrapper'}
                  condition={
                    props.getRecordLink &&
                    props.getRecordLink(record, index) != ''
                  }
                  wrapper={children => (
                    <LinkRow
                      key={index + 'Link'}
                      to={
                        props.getRecordLink
                          ? props.getRecordLink(record, index)
                          : ''
                      }
                      target={
                        props.recordLinkDestination
                          ? props.recordLinkDestination(record)
                          : ''
                      }
                      data-qa={'TableOneRow'}
                      onClick={() => {
                        if (props.onRowClick) {
                          props.onRowClick(record)
                        }
                      }}
                    >
                      {children}
                    </LinkRow>
                  )}
                >
                  {props.columns.map((column, index) => {
                    const ColumnComponent = column.component
                    const renderedContent = column.renderer
                      ? column.renderer(record)
                      : ColumnComponent && <ColumnComponent />
                    const renderWithTooltip =
                      column.renderer && !column.noTooltip
                    const tableCellQaTag = column.headerName
                      ? 'TableOneCell_' + column.headerName
                      : 'TableOneCell'
                    return (
                      <Cell
                        contentWidth={column.contentWidth}
                        key={index}
                        data-qa={tableCellQaTag}
                      >
                        {renderWithTooltip ? (
                          <WithTooltip
                            tooltip={renderedContent}
                            showOnlyWhenOverflow={true}
                            placement="bottom"
                          >
                            <CellContent>{renderedContent}</CellContent>
                          </WithTooltip>
                        ) : (
                          renderedContent
                        )}
                      </Cell>
                    )
                  })}
                </ConditionalWrapper>
              </Row>
              {props.rowTooltips &&
                props.rowTooltips.map(tooltip =>
                  record[`${String(tooltip.selectedRecordKey)}`] ===
                  tooltip.selectedRowId ? (
                    <RowTooltip key={tooltip.selectedRowId}>
                      <RowTooltipContent>{tooltip.content}</RowTooltipContent>
                    </RowTooltip>
                  ) : null
                )}
            </RowContent>
          )
        })}
      </TableRowWrapper>

      <TableViewsWrapper
        data-qa={'NoRecordsContainer'}
        tableHasRecords={!!props.table.results.length}
        minimumHeight={props.minimumHeight ? props.minimumHeight : ''}
      >
        {isTableLoading && props.loadingView}

        {isTableEmpty && props.noRecordsView}

        {isFilterResultsEmpty && props.noResultsView}
      </TableViewsWrapper>
    </TableContainer>
  )
}

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { NeverOnlyUrlActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/neveronlyurls/actions'

import { createGetNeverOnlyUrlRulesSaga } from './get-never-only-url-rules'
import { createSaveNeverOnlyUrlRulesSaga } from './save-never-only-url-rules'

export function createNeverOnlyUrlsSaga(
  neverOnlyUrlService: UACServices.Services
) {
  const getNeverOnlyUrlRulesSaga = createGetNeverOnlyUrlRulesSaga(
    neverOnlyUrlService
  )
  const saveNeverOnlyUrlRulesSaga = createSaveNeverOnlyUrlRulesSaga(
    neverOnlyUrlService
  )

  return function*() {
    yield takeEvery(
      NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_GET,
      getNeverOnlyUrlRulesSaga
    )
    yield takeEvery(
      NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_SAVE,
      saveNeverOnlyUrlRulesSaga
    )
  }
}

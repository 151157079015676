import { AccountStatus } from './account-status'
import { CustomAttribute } from './custom-attribute'
import { FederatedStatus } from './federated-status'
import { MFAStatus } from './mfa-status'
import { MultifactorProvider } from './multifactor-provider'
import { SecurityScoreLevel } from './security-score-level'
import { UserLevelObject } from './user-level'
import { SuperAdminKeyOwnershipForUser } from './user-super-admin-key-information'

export interface UserBase {
  id: string
  email: string
  firstName: string
  lastName: string
}

export const getFullName = (user: Partial<UserBase>) =>
  `${user.firstName ? user.firstName : ''} ${
    user.lastName ? user.lastName : ''
  }`.trim()

export interface User extends UserBase {
  accountStatus: AccountStatus
  mfaStatus: MFAStatus
  defaultMultiFactorType: MultifactorProvider | null
  lastLoginTimeUtc: string | null
  adminLevel: UserLevelObject | null
  lastPassUserId: number | null
  securityScore: SecurityScoreLevel | null
  securityChallengeScore: number | null
  lastMasterPasswordChangeTimeUtc: string | null
  inviteEmailSentTimeUtc: string | null
  provisionFederatedStatus: FederatedStatus | null
  isSuperAdminKeyAvailableForActingAdmin: boolean
  hasSharingKey: boolean
  isRecoveryKeySharingAllowed: boolean
  superAdminKeyOwnershipForUser: SuperAdminKeyOwnershipForUser
  isCreatedByCompany: boolean | null
}

export interface UserProfile extends UserBase {
  userId: string
  lastPassId?: number
  secondaryEmail?: string
  phoneNumber: string
  department: string
  description?: string
  samAccountName?: string
  customAttributes: CustomAttribute[]
  isRecoveryKeySharingAllowed: boolean
}

export interface UserSettings {
  isTrackingAccepted: boolean
}

export interface SaveUserProfile {
  department: string
  description: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  samAccountName: string
  secondaryEmail: string
  userId: string
  customAttributes: CustomAttribute[]
}

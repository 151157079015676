import React from 'react'

import styled from '@emotion/styled'

import { BodyRegularStyle, Heading100 } from '@lastpass/lastkit'

import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'

const StyledTitle = styled(Heading100)`
  color: ${props => props.theme.colors.neutral900};
  margin: 16px;
`

const StyledPolicyDescription = styled.div`
  ${BodyRegularStyle};
  margin: 16px;
`

const DetailsContainer = styled.div<{ highlight: boolean }>`
  background: ${props =>
    props.highlight ? props.theme.colors.blue100 : props.theme.colors.neutral0};
  box-shadow: ${props => props.theme.elevations.elevation100};
  border: 1px solid
    ${props =>
      props.highlight
        ? props.theme.colors.blue700
        : props.theme.colors.neutral300};
  margin-bottom: 16px;
  cursor: pointer;
`

export interface PolicyCardProps {
  highlight: boolean
  onHighlight: Function
  value: TeamsPolicy
}

export const PolicyCard: React.FunctionComponent<PolicyCardProps> = props => {
  const policyDescriptionLines = props.value.policyDescription
    ? props.value.policyDescription
        .split('<br/>')
        .filter(line => line.trim().length > 0)
    : []
  return (
    <DetailsContainer
      highlight={props.highlight}
      onClick={() => {
        props.onHighlight(props.value)
      }}
    >
      <StyledTitle>{props.value.policyTitle}</StyledTitle>
      <StyledPolicyDescription>
        {policyDescriptionLines.map((line, index) => (
          <p key={index}> {line}</p>
        ))}
      </StyledPolicyDescription>
    </DetailsContainer>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { RecoveryKeySharingUploadRequest } from '@lastpass/admin-console-dependencies/types/recovery-keys/recovery-key-sharing-upload-request'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    recoveryKeySharingUploadRequest: RecoveryKeySharingUploadRequest
  ) {
    const [response, status] = await fetch(
      'bulk/users/recovery-keys',
      'POST',
      recoveryKeySharingUploadRequest,
      { 'content-type': 'application/json' }
    )

    if (status === StatusCodes.OK && response) {
      return success()
    }
    throw new UnknownServiceError()
  }
}

import { call } from 'redux-saga/effects'
import { put } from 'redux-saga-test-plan/matchers'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPoliciesListGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { mapGetPolicyListResultToPolicyMap } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

const RequirePasswordlessViaLastPassPolicyKey =
  'requirepasswordlessvialastpassauth'

export function createPasswordlessGetStatus(
  passwordlessServices: UACServices.Services
) {
  return function* getPasswordlessStatus() {
    try {
      const generalPoliciesListGetResponse:
        | GeneralPoliciesListGetAPI.Responses
        | undefined = yield call(passwordlessServices.generalPoliciesListGet)

      if (
        generalPoliciesListGetResponse &&
        generalPoliciesListGetResponse.type ===
          GeneralPoliciesListGetAPI.SUCCESS
      ) {
        const requirePasswordlessViaLastpassPolicy: TeamsPolicy = mapGetPolicyListResultToPolicyMap(
          generalPoliciesListGetResponse.body.generalPoliciesListResponse
        )[0][RequirePasswordlessViaLastPassPolicyKey]
        yield put(
          passwordlessActions.setRequirePasswordlessViaLastPassPolicy(
            requirePasswordlessViaLastpassPolicy
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

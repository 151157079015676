import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-form.svg'
import { Loading } from '@lastpass/components/Loading'
import { DrawerTable, PrimaryButton, TableView } from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  sharedFolderDrawerActions,
  SharedFolderSite
} from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'
import { SharedFoldersSitesListState } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

export interface SharedFolderSitesListProps {
  isCidOverrideMode: boolean
}

type SharedFolderSitesListComponentProps = LastPassComponentProps<
  SharedFoldersSitesListState,
  typeof sharedFolderDrawerActions,
  SharedFolderSitesListProps
>

const StyledRow = styled.div`
  margin-left: 5px;
`

export const SharedFolderSitesListComponent: React.FunctionComponent<SharedFolderSitesListComponentProps> = ({
  state,
  actions,
  props
}) => {
  const isLastTouchHidden = useFeatureFlags(FeatureFlags.isLastTouchHidden)
  const { id } = useParams<{ id: string }>()

  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      sharedFolderDrawerActions.getSharedFolderSitesList({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  let sharedFolderId: string
  if (id) {
    sharedFolderId = id
  }

  const lastLogin = !isLastTouchHidden
    ? {
        name: <Trans>Last login</Trans>,
        renderer: function SharedFolderSecurityScoreRenderer(
          record: SharedFolderSite
        ) {
          return (
            <StyledRow>
              {record.lastused === '' ? <Trans>Never</Trans> : record.lastused}
            </StyledRow>
          )
        }
      }
    : []

  const footerNodes = !props.isCidOverrideMode
    ? [
        <PrimaryButton
          key="Assign apps"
          onClick={() => actions.sharedFolderAddSites(sharedFolderId)}
          data-qa="AddAppsButton"
        >
          <Trans>Assign apps</Trans>
        </PrimaryButton>
      ]
    : undefined

  return (
    <>
      <DrawerTable
        table={state.sitesTable}
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No apps added yet</Trans>}
            text={<Trans>Assign an app to this shared folder</Trans>}
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: function SharedFolderNameRenderer(
              record: SharedFolderSite
            ) {
              return <StyledRow>{record.domain}</StyledRow>
            }
          }
        ].concat(lastLogin)}
        footerNodes={footerNodes}
      />
    </>
  )
}

export function createSharedFolderSitesList<
  TState extends { sharedFolderDrawer: SharedFoldersSitesListState }
>() {
  return connectRedux(
    SharedFolderSitesListComponent,
    (state: TState) => state.sharedFolderDrawer,
    { ...sharedFolderDrawerActions }
  )
}

export type SharedFolderSitesList = ReturnType<
  typeof createSharedFolderSitesList
>

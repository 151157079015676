import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { addUsersDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'

const StyledResendVerificationEmailButton = styled.span`
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
`

export const NotificationUserEmailNotVerified: FunctionComponent = () => {
  const dispatch = useDispatch()

  const sendVerificationEmail = () => {
    dispatch(addUsersDrawerActions.sendUserVerificationEmail())
  }

  return (
    <>
      <Trans>Please verify your email to complete this action.</Trans>{' '}
      <StyledResendVerificationEmailButton
        onClick={sendVerificationEmail}
        data-qa="SendVerificationEmailButton"
      >
        <Trans>Resend verification email</Trans>
      </StyledResendVerificationEmailButton>
    </>
  )
}

import { call, put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { convertFileToBase64String } from '@lastpass/admin-console-dependencies/services/file-converter'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { RsaModel } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'

export function createSetRsaConfig() {
  return function* setRsaConfig(
    action: ReturnType<typeof multifactorDrawerActions.processRsaConfig>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      try {
        let encoded124Logo, encoded190Logo
        if (action.payload.formValues.logo124x124) {
          encoded124Logo = yield call(
            convertFileToBase64String,
            action.payload.formValues.logo124x124
          )
        }

        if (action.payload.formValues.logo190x41) {
          encoded190Logo = yield call(
            convertFileToBase64String,
            action.payload.formValues.logo190x41
          )
        }
        const formValues = action.payload.formValues
        const rsaConfig: RsaModel = {
          failureMessage: formValues.failureMessage,
          hasRadiusSharedSecret:
            !!formValues.radiusSharedSecret &&
            formValues.radiusSharedSecret.length > 0,
          logo124x124: encoded124Logo,
          logo124x124Length: formValues.logo124x124Length,
          logo190x41: encoded190Logo,
          logo190x41Length: formValues.logo190x41Length,
          radiusServerIp: formValues.radiusServerIp,
          radiusSharedSecret: formValues.radiusSharedSecret,
          radiusSharedSecretLength: formValues.radiusSharedSecret?.length ?? 0,
          radiusTimeout: action.payload.formValues.radiusTimeout,
          serviceName: action.payload.formValues.serviceName
        }

        yield put(multifactorDrawerActions.setRsaConfig(rsaConfig))
        yield put(
          multifactorDrawerActions.nextStep(MultifactorDrawerSteps.assign)
        )
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { SecurityReportActions, SecurityReportActionTypes } from './actions'
import { SecurityReportListState } from './state'

export const initialSecurityReportListState: SecurityReportListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  lastUpdated: null,
  isUpdateRequested: false
}

export function securityReportEvents(
  previousState: SecurityReportListState | undefined,
  action: SecurityReportActions
): SecurityReportListState {
  const state = previousState || initialSecurityReportListState
  switch (action.type) {
    case SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT:
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.events,
          isLoading: false
        }
      }
    case SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT_LAST_UPDATED:
      return {
        ...state,
        lastUpdated: action.payload.lastUpdated
      }
    case SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT_LOADING:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: action.payload.isLoading
        }
      }
    case SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT_IS_UPDATE_REQUESTED:
      return {
        ...state,
        isUpdateRequested: action.payload.isUpdateRequested
      }
    default: {
      return state
    }
  }
}

import { CompanyInfoActions, CompanyInfoActionTypes } from './actions'
import { CompanyInfoState, SubscriptionType } from './state'

export const initialState: CompanyInfoState = {
  details: {
    accountNumber: 0,
    name: '',
    subscription: SubscriptionType.none,
    isTrial: false,
    subscriptionEndDate: null,
    parentCompanyId: null,
    hasLastPassSupport: true,
    canBuyLicenses: true,
    permissions: null,
    isFederatedLoginEnabled: false,
    isAggregator: false,
    isBillingUsageBased: false,
    enterpriseVersion: null,
    minimumHashIterationCount: 600000,
    isTransferVaultHidden: false
  },
  remainingLicenses: undefined,
  totalLicenses: undefined,
  settings: {
    isNonDisabledUserRemovalAllowed: true
  },
  mspBillingDetails: undefined
}

export function companyInfoReducer(
  previousState: CompanyInfoState | undefined,
  action: CompanyInfoActions
): CompanyInfoState {
  const state = previousState || initialState
  switch (action.type) {
    case CompanyInfoActionTypes.SET_COMPANY_DETAILS: {
      return {
        ...state,
        details: action.payload
      }
    }
    case CompanyInfoActionTypes.SET_COMPANY_LICENSES: {
      return {
        ...state,
        remainingLicenses: action.payload.remainingLicenses,
        totalLicenses: action.payload.totalLicenses
      }
    }
    case CompanyInfoActionTypes.SET_MSP_BILLING_DETAILS: {
      return {
        ...state,
        mspBillingDetails: action.payload
      }
    }
    case CompanyInfoActionTypes.SET_COMPANY_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.settings
        }
      }
    }
    case CompanyInfoActionTypes.SET_CID_OVERRIDE: {
      return {
        ...state,
        cidOverride: action.payload.cid
      }
    }
    case CompanyInfoActionTypes.REMOVE_CID_OVERRIDE: {
      return {
        ...state,
        cidOverride: undefined
      }
    }
  }
  return state
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { TeamsPolicyStatus } from '@lastpass/admin-console-dependencies/types/policy-teams'

interface SelectPolicyStatusProps {
  updateQuery: Function
  filters?: TeamsPolicyStatus[]
}

export interface PolicyStatusSelectOption {
  value: TeamsPolicyStatus
  label: string
}

const StyledSelect = styled(Select)`
  margin-left: 24px;
`

export const SelectPolicyStatus: FunctionComponent<SelectPolicyStatusProps> = props => {
  const policyStatus: PolicyStatusSelectOption[] = [
    { value: TeamsPolicyStatus.enabled, label: i18n._(msg`Enabled`) },
    { value: TeamsPolicyStatus.disabled, label: i18n._(msg`Disabled`) }
  ]

  return (
    <StyledSelect
      dataQa={'PolicyStatusDropdown'}
      options={policyStatus}
      value={policyStatus.filter(
        status => props.filters && props.filters.includes(status.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={i18n._(msg`Policy status`)}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateQuery({
            status: selectedItems.map(item => item.value)
          })
        } else if (Array.isArray(selectedItems) && selectedItems.length === 0) {
          props.updateQuery({ status: undefined })
        } else {
          props.updateQuery({ status: undefined })
        }
      }}
    />
  )
}

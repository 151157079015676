import React, { FunctionComponent, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  Button,
  ExpandedButton,
  HeaderText
} from '@lastpass/lastkit/components/MoreAction'
import { useOutsideClick } from '@lastpass/ui'

import { AdminOperationType } from '@lastpass/admin-console-dependencies/state/users/admin/operations/admin-operations'
import { TableWithOperations } from '@lastpass/admin-console-dependencies/types/table'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'

import { AssignAdminLevelDropdownList } from './AssignAdminLevelDropdownList'

const Container = styled.div`
  position: relative;
  display: inline-block;
`

const DropdownArranger = styled.div`
  position: absolute;
  margin-top: 5px;
  top: 100%;
  right: 0%;
  z-index: 10;
`

const DropdownList = styled.div`
  list-style: none;
  background: ${props => props.theme.colors.neutral};
  border: 1px solid ${props => props.theme.colors.neutral200};
  box-shadow: ${props => props.theme.elevations.elevation300};
  border-radius: ${props => props.theme.radius.pixel8};
  width: 200px;
  max-height: 260px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
`

const LoadingContainer = styled.div`
  margin: 16px 0;
  position: relative;
  min-height: 225px;
`

export const AssignAdminLevelDropdown: FunctionComponent = () => {
  const [expanded, setExpanded] = useState(false)

  const {
    isLoading
  }: TableWithOperations<
    UserLevelDetails,
    AdminOperationType,
    string
  > = useSelector((appState: AppState) => appState.adminList.table)

  const containerRef = useRef<HTMLDivElement>(null)
  const onOutsideClick = () => {
    setExpanded(false)
  }
  const handleCick = () => {
    setExpanded(!expanded)
  }
  useOutsideClick(containerRef, onOutsideClick, true)

  return (
    <Container ref={containerRef}>
      <span onClick={handleCick}>
        <HeaderText data-qa="AssignAdminLevelsActionButton">
          <Trans>Assign admin level</Trans>
        </HeaderText>
      </span>

      {!expanded && <Button onClick={handleCick} expanded={expanded} />}
      {expanded && <ExpandedButton onClick={handleCick} expanded={expanded} />}

      <DropdownArranger>
        <AnimateHeight height={expanded ? 'auto' : 0}>
          <DropdownList data-qa="DropdownList">
            <AssignAdminLevelDropdownList />
            {isLoading && (
              <LoadingContainer data-qa="Loading">
                <Loading color="blue900" active={true} />
              </LoadingContainer>
            )}
          </DropdownList>
        </AnimateHeight>
      </DropdownArranger>
    </Container>
  )
}

import {
  AdvancedPolicyDrawerActions,
  AdvancedPolicyDrawerActionTypes
} from './actions'
import { AdvancedPolicyDrawerSteps } from './advanced-policy-drawer-steps'
import { AdvancedPolicyDrawerState } from './state'

export const initialState: AdvancedPolicyDrawerState = {
  actualStep: AdvancedPolicyDrawerSteps.addAdvancedPolicy,
  isLoading: false,
  policy: null
}

export function advancedPolicyDrawerReducer(
  previousState: AdvancedPolicyDrawerState | undefined,
  action: AdvancedPolicyDrawerActions
): AdvancedPolicyDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case AdvancedPolicyDrawerActionTypes.DISCARD: {
      return initialState
    }
    case AdvancedPolicyDrawerActionTypes.NEXT_STEP: {
      return {
        ...state,
        actualStep: action.payload.nextStep
      }
    }
    case AdvancedPolicyDrawerActionTypes.SET_POLICY: {
      return {
        ...state,
        policy: {
          ...state.policy,
          ...action.payload.policy,
          policyData: action.payload.policy.policyData
            ? action.payload.policy.policyData
            : []
        },
        dataIndex: action.payload.dataIndex,
        policyValue: action.payload.policyValue ?? undefined,
        policyNotes: action.payload.policyNotes ?? ''
      }
    }
    case AdvancedPolicyDrawerActionTypes.SET_POLICY_CONFIG: {
      return {
        ...state,
        policyValue: action.payload.policyValue ?? undefined,
        policyNotes: action.payload.policyNotes ?? ''
      }
    }
  }
  return state
}

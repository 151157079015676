import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPoliciesListState } from '@lastpass/admin-console-dependencies/state/policies/general/list/state'
import { generalPolicyUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import {
  GeneralPolicyUsersAndGroups,
  GeneralPolicyUsersAndGroupsFormState
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyGroup,
  TeamsPolicyMap,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPolicyInfo } from '../drawer/get-general-policy'
import { getPolicyUsersAndGroupsState } from './get-general-policy-users'

export const getCurrentUrlFilters = (state: AppState) => state.router

export const getGeneralPoliciesList = (state: AppState) =>
  state.generalPoliciesList

const getPolicyConfigUsers = (
  toBeRemovedUsers: TeamsPolicyUserInfo[],
  existingUserList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] => {
  const usersList = [...existingUserList]
  const filteredUsersList = usersList.filter(user => {
    return !toBeRemovedUsers.includes(user)
  })
  return filteredUsersList
}
const getPolicyConfigGroups = (
  toBeRemovedGroups: TeamsPolicyGroup[],
  existingGroupList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] => {
  const groupsList = [...existingGroupList]
  return groupsList.filter(user => {
    return !toBeRemovedGroups.includes(user)
  })
}

const preparePolicyData = (
  policy: TeamsPolicy,
  policyFormData: GeneralPolicyUsersAndGroups,
  toBeRemovedUsers: TeamsPolicyUserInfo[],
  toBeRemovedGroups: TeamsPolicyGroup[]
): TeamsPolicyModelData[] => {
  const policyData = policy.policyData.map((policyConfigInfo, index) => {
    if (index === policyFormData.policyConfigIndex) {
      policyConfigInfo = {
        ...policyConfigInfo,
        appliesTo: policyFormData.policyConfigFormData.appliesTo,
        inclusiveList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.inclusive && policyConfigInfo.inclusiveList
            ? getPolicyConfigUsers(
                toBeRemovedUsers,
                policyConfigInfo.inclusiveList
              )
            : [],
        inclusiveGroupList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.inclusive && policyConfigInfo.inclusiveGroupList
            ? getPolicyConfigGroups(
                toBeRemovedGroups,
                policyConfigInfo.inclusiveGroupList
              )
            : [],
        exclusiveList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.exclusive && policyConfigInfo.exclusiveList
            ? getPolicyConfigUsers(
                toBeRemovedUsers,
                policyConfigInfo.exclusiveList
              )
            : [],
        exclusiveGroupList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.exclusive && policyConfigInfo.exclusiveGroupList
            ? getPolicyConfigGroups(
                toBeRemovedGroups,
                policyConfigInfo.exclusiveGroupList
              )
            : []
      }
    }
    return policyConfigInfo
  })
  return policyData
}

export function createSaveGeneralPolicyUsersAndGroupsSaga() {
  return function* saveGeneralPolicyUsersAndGroupsSaga(
    action: ReturnType<
      typeof generalPolicyUsersAndGroupsActions.saveGeneralPolicyUsersAndGroups
    >
  ) {
    const policyFormData = action.payload.policyFormData
    const policyKey = policyFormData.policyKey
    if (policyKey) {
      try {
        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy = policyMap[policyKey]
        const policyUsersAndGroupsFormState: GeneralPolicyUsersAndGroupsFormState = yield select(
          getPolicyUsersAndGroupsState
        )
        const toBeRemovedUsers = policyUsersAndGroupsFormState.toBeRemovedUsers
        const toBeRemovedGroups =
          policyUsersAndGroupsFormState.toBeRemovedGroups

        const policyData = preparePolicyData(
          policy,
          policyFormData,
          toBeRemovedUsers,
          toBeRemovedGroups
        )

        const generalPoliciesList: GeneralPoliciesListState = yield select(
          getGeneralPoliciesList
        )
        policyMap[policyKey].policyData = policyData
        policyMap[policyKey].isChanged = true
        yield put(
          generalPoliciesListActions.setGeneralPoliciesList(
            generalPoliciesList.table.results,
            policyMap,
            generalPoliciesList.table.totalResults,
            generalPoliciesList.table.resultsPerPage
          )
        )
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      } finally {
        yield put(
          generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroupsHasReceivedServerResponse(
            true
          )
        )
        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
            false,
            policyKey
          )
        )
      }
    }
  }
}

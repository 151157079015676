import { createContext, useContext } from 'react'

export enum TopNavigationState {
  Main,
  More,
  Hidden
}

const TopNavigationStateContext = createContext(TopNavigationState.Main)

export const TopNavigationStateProvider = TopNavigationStateContext.Provider

export const useTopNavigationState = () => useContext(TopNavigationStateContext)

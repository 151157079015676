import JSZip from 'jszip'

export async function extractCsvFilesFromZip(zipFile: Blob): Promise<Blob[]> {
  try {
    const zip = await JSZip.loadAsync(zipFile)
    const csvFiles: Blob[] = []

    for (const fileName in zip.files) {
      if (fileName.endsWith('.csv')) {
        const csvContent = await zip.files[fileName].async('uint8array')

        const csvBlob = new Blob([csvContent], {
          type: 'text/csv'
        })
        csvFiles.push(csvBlob)
      }
    }

    return csvFiles
  } catch (error) {
    throw new Error('Failed to extract csv files from zip.')
  }
}

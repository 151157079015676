import { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import { SiemProvider } from '@lastpass/tracking/tracking-plans/uac'

import {
  FAILURE,
  SUCCESS
} from '@lastpass/admin-console-dependencies/server/advanced/siem-integrations/set-config'
import * as UACServices from '@lastpass/admin-console-dependencies/server/index'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { siemIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/siem-integrations/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  AlienVaultIntegrationConfig,
  AzureSentinelIntegrationConfig,
  LogRhythmIntegrationConfig,
  QRadarIntegrationConfig,
  SplunkIntegrationConfig
} from '@lastpass/admin-console-dependencies/types/siem-integrations'
import { TroubleshootLink } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/components/TroubleshootLink'

export enum ConnectionTestError {
  WrongCredentials = 'WrongCredentials',
  InvalidCertificate = 'InvalidCertificate',
  Timeout = 'Timeout',
  ConnectionRefusal = 'ConnectionRefusal',
  SplunkNonexistentIndex = 'SplunkNonexistentIndex'
}

const connectionTestMessages = {
  [ConnectionTestError.WrongCredentials]: msg`Connection test failed. Review your credentials and try again.`,
  [ConnectionTestError.InvalidCertificate]: msg`Connection test failed. Review your SIEM provider certificate and try again.`,
  [ConnectionTestError.Timeout]: msg`We couldn't establish a connection with your SIEM provider within the allowed time.`,
  [ConnectionTestError.ConnectionRefusal]: msg`Your SIEM provider refused the connection from LastPass.`,
  [ConnectionTestError.SplunkNonexistentIndex]: msg`Connection test failed. Review your Splunk index and try again.`
}

type SetConfig = <TConfig>(params: {
  api: (
    config: TConfig
  ) => Promise<UACServices.SiemIntegrationsSetConfigAPI.Responses | undefined>
  config: TConfig
  successMessage: MessageDescriptor
  siemProvider: SiemProvider
}) => Promise<void>

export const useSiemIntegrationsSetConfig = () => {
  const { siemIntegrationsSetConfig } = useContext(ServerContext)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const setConfig: SetConfig = async ({
    api,
    config,
    successMessage,
    siemProvider
  }) => {
    setIsLoading(true)

    try {
      const response = await api(config)
      switch (response?.type) {
        case SUCCESS:
          dispatch(
            globalActions.setNotification({
              message: successMessage,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
          dispatch(
            siemIntegrationsActions.setUpIntegrationTracking(siemProvider)
          )
          break

        case FAILURE:
          dispatch(
            globalActions.setNotification({
              message:
                connectionTestMessages[response.body.error] ??
                genericFailedNotification.message,
              type: NotificationType.alert,
              autoDismiss: false,
              component:
                response.body.error === ConnectionTestError.Timeout ||
                response.body.error === ConnectionTestError.ConnectionRefusal
                  ? TroubleshootLink
                  : undefined
            })
          )
          break
        default:
          dispatch(globalActions.setNotification(genericFailedNotification))
      }
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    }

    setIsLoading(false)
  }

  const setAzureSentinelConfig = async ({
    config,
    successMessage
  }: {
    config: AzureSentinelIntegrationConfig
    successMessage: MessageDescriptor
  }) =>
    setConfig<AzureSentinelIntegrationConfig>({
      api: siemIntegrationsSetConfig.azureSentinel,
      config,
      successMessage,
      siemProvider: 'Azure Sentinel'
    })

  const setSplunkConfig = async ({
    config,
    successMessage
  }: {
    config: SplunkIntegrationConfig
    successMessage: MessageDescriptor
  }) =>
    setConfig<SplunkIntegrationConfig>({
      api: siemIntegrationsSetConfig.splunk,
      config,
      successMessage,
      siemProvider: 'Splunk'
    })

  const setQRadarConfig = async ({
    config,
    successMessage
  }: {
    config: QRadarIntegrationConfig
    successMessage: MessageDescriptor
  }) =>
    setConfig<QRadarIntegrationConfig>({
      api: siemIntegrationsSetConfig.qRadar,
      config,
      successMessage,
      siemProvider: 'IBM QRadar'
    })

  const setLogRhythmConfig = async ({
    config,
    successMessage
  }: {
    config: LogRhythmIntegrationConfig
    successMessage: MessageDescriptor
  }) =>
    setConfig<LogRhythmIntegrationConfig>({
      api: siemIntegrationsSetConfig.logRhythm,
      config,
      successMessage,
      siemProvider: 'LogRhythm'
    })

  const setAlienVaultConfig = async ({
    config,
    successMessage
  }: {
    config: AlienVaultIntegrationConfig
    successMessage: MessageDescriptor
  }) =>
    setConfig<AlienVaultIntegrationConfig>({
      api: siemIntegrationsSetConfig.alienVault,
      config,
      successMessage,
      siemProvider: 'AlienVault USM Anywhere'
    })

  return {
    isLoading,
    setAzureSentinelConfig,
    setSplunkConfig,
    setQRadarConfig,
    setLogRhythmConfig,
    setAlienVaultConfig
  }
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { DashboardDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/DasboardDrawer'
import { DashboardMainContent } from '@lastpass/admin-console-dependencies/ui/dashboard/DashboardMainContent'
import { InactiveUsersDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/InactiveUsersDrawerPage'
import { createStagedUsersDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/StagedUsersDrawerPage'
import { createUnresponsiveUsersDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/UnresponsiveUsersDrawerPage'
import { SystemUpgradeImpactDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/SystemUpgradeImpactDialog'
import { AdoptionDashboard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/AdoptionDashboard'
import { ExpiredInvitationsDrawer } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/components/expired-invitations-drawer/ExpiredInvitationsDrawer'
import { PendingInvitationsDrawer } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/components/pending-invitations-drawer/PendingInvitationsDrawer'
import {
  isAggregatorMSPInManagedMSP,
  isDirectMSPOrAggregatorMSP,
  isManagedMSP,
  isSimpleAdmin,
  npSunsetDeadline
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'
import { isLastWarningToMigrateActive } from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'
import { AddUsersDrawer } from '@lastpass/admin-console-dependencies/ui/users/view/add/AddUsersDrawer'

import { AddUsersDrawerPage } from '../users/view/drawer/add/AddUsersDrawerPage'

const UnresponsiveUsersDrawer = createUnresponsiveUsersDrawer<AppState>()
const StagedUsersDrawer = createStagedUsersDrawer<AppState>()

const store_key = 'Is_Deprecation_Modal_Shown'

const isLastWarningActive: boolean = isLastWarningToMigrateActive(
  new Date(Date.now()),
  npSunsetDeadline
)

const modalShouldBeShown = (): boolean => {
  const data: string | null = isLastWarningActive
    ? 'false'
    : localStorage.getItem(store_key)
  const modalShown: boolean = data !== null ? !JSON.parse(data) : true
  if (data === null) setModalShownInStorageTo(false)
  return modalShown
}

const setModalShownInStorageTo = (storageValue: boolean): void => {
  if (!isLastWarningActive)
    localStorage.setItem(store_key, JSON.stringify(storageValue))
}

export const DashboardPage: FunctionComponent = () => {
  const match = useRouteMatch()

  const isUtilizationDashboardEnabled = useFeatureFlags(
    FeatureFlags.isUtilizationDashboardEnabled
  )

  const isNopasswordMigrationEnabled = useFeatureFlags(
    FeatureFlags.isNopasswordMigrationEnabled
  )

  const companyInfo = useSelector<AppState, CompanyInfoState>(
    appState => appState.companyInfo
  )

  const systemUpgradeState = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  const isMigrationRequired =
    isNopasswordMigrationEnabled &&
    systemUpgradeState.isMigrationRequired &&
    !systemUpgradeState.isMigrated

  const hasUserRightToSeeMigrationInformation =
    isSimpleAdmin(companyInfo) ||
    isDirectMSPOrAggregatorMSP(companyInfo) ||
    isManagedMSP(companyInfo) ||
    isAggregatorMSPInManagedMSP(companyInfo)

  const shouldCustomerBeMigratedFromNopassword =
    isMigrationRequired && hasUserRightToSeeMigrationInformation

  const showModal =
    modalShouldBeShown() && shouldCustomerBeMigratedFromNopassword

  const [
    showSystemUpgradeImpactModal,
    setShowSystemUpgradeImpactModal
  ] = useState(showModal)

  useEffect(() => {
    setShowSystemUpgradeImpactModal(showModal)
  }, [showModal])

  const ShowSystemUpgradeImpactDialog = () =>
    showSystemUpgradeImpactModal && (
      <SystemUpgradeImpactDialogComponent
        discardPopUpAndSaveStateToStorage={() => {
          setShowSystemUpgradeImpactModal(false)
          setModalShownInStorageTo(true)
        }}
      />
    )
  if (!match) {
    return null
  }
  const { url, path } = match

  return (
    <>
      {ShowSystemUpgradeImpactDialog()}
      {isUtilizationDashboardEnabled ? (
        <AdoptionDashboard />
      ) : (
        <DashboardMainContent />
      )}
      <AnimatedSwitch timeout={200}>
        <Route path={`${path}/invite`}>
          <DashboardDrawer
            closeLink={url}
            title={<Trans>Reinvite users</Trans>}
          >
            <UnresponsiveUsersDrawer />
          </DashboardDrawer>
        </Route>
        <Route path={`${path}/inactive`}>
          <InactiveUsersDrawer closeLink={url} />
        </Route>
        <Route path={`${path}/invite-pending`}>
          <PendingInvitationsDrawer closeLink={url} />
        </Route>
        <Route path={`${path}/invite-expired`}>
          <ExpiredInvitationsDrawer closeLink={url} />
        </Route>
        <Route path={`${path}/invite-staged`}>
          <DashboardDrawer
            closeLink={url}
            title={<Trans>Invite staged users</Trans>}
          >
            <StagedUsersDrawer />
          </DashboardDrawer>
        </Route>
        <Route path={`${path}/add`}>
          <AddUsersDrawer closeLink={url}>
            <AddUsersDrawerPage cancelLink="/dashboard" />
          </AddUsersDrawer>
        </Route>
      </AnimatedSwitch>
    </>
  )
}

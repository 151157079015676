import React, { FunctionComponent } from 'react'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import {
  ButtonsContainer,
  PrimaryButton,
  StyledFormFooter,
  TextButton
} from '@lastpass/lastkit'

export interface ProductSelectionDrawerFooterProps {
  isSaveDisabled: boolean
  onDiscard: () => void
  onSave: () => void
}

export const ProductSelectionDrawerFooter: FunctionComponent<ProductSelectionDrawerFooterProps> = ({
  isSaveDisabled,
  onDiscard,
  onSave
}) => (
  <StyledFormFooter>
    <ButtonsContainer>
      <TextButton
        css={css`
          margin-right: 16px;
        `}
        data-qa="DiscardButton"
        type="button"
        onClick={onDiscard}
      >
        <Trans>Discard</Trans>
      </TextButton>

      <PrimaryButton
        css={css`
          margin-right: 16px;
        `}
        data-qa="SaveButton"
        type="submit"
        disabled={isSaveDisabled}
        onClick={onSave}
      >
        <Trans>Save</Trans>
      </PrimaryButton>
    </ButtonsContainer>
  </StyledFormFooter>
)

import { StatusCodes } from 'http-status-codes'

import { BulkRotateAlpData } from '@lastpass/federation/types/key-rotation'
import { AuthFetchRetry } from '@lastpass/http'

import { KeyRotationError } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-error'
import { KeyRotationErrors } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-error-codes'
import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'

export interface KeyRotationRawDataAlpResponse {
  data: {
    k2: string
    fragmentId: string
    subject: string
    id: number
  }[]
  totalCount: number
}

export interface KeyRotationRawDataResponse {
  content: {
    items: {
      kC2: string
      fragmentId: string
      subject: string
      id: number
    }[]
    totalCount: number
  }
}
export interface KeyRotationDataResponse {
  data: BulkRotateAlpData[]
  totalCount: number
}

export const SUCCESS = 'success'
const success = (keyRotationDataResponse: KeyRotationDataResponse) =>
  createResponse<string, KeyRotationDataResponse>(
    SUCCESS,
    keyRotationDataResponse
  )

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetchRetry) {
  return async function service(
    sessionToken: string,
    offset: number,
    limit: number
  ) {
    let response: string | null
    let status: number
    try {
      ;[response, status] = await fetch(
        `company/knowledge-component-rotations/${sessionToken}/keys?offset=${offset}&limit=${limit}`,
        'GET'
      )
    } catch {
      throw new KeyRotationError({
        feErrorCode:
          KeyRotationErrors.AlpGetKeyRotationDataRequestConnectionFailed,
        sessionToken
      })
    }
    try {
      const {
        content: keyRotationData
      }: KeyRotationRawDataResponse = JSON.parse(response)
      if (
        status === StatusCodes.OK &&
        keyRotationData?.items &&
        (keyRotationData?.items.length || offset > 0)
      ) {
        const mappedKeyRotationData: BulkRotateAlpData[] = keyRotationData.items.map(
          item => ({
            ...item,
            k2: Buffer.from(item.kC2, 'base64')
          })
        )
        return success({
          data: mappedKeyRotationData,
          totalCount: keyRotationData.totalCount
        })
      }
    } catch {
      // do nothing
    }
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.AlpGetKeyRotationDataRequestFailed,
      sessionToken,
      httpErrorCode: status
    })
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { getIdentityIFrameUrl } from '@lastpass/admin-console-dependencies/services'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { legacyVpnListActions } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/actions'
import { LegacyVpnListState } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdvancedMfaMessage } from '@lastpass/admin-console-dependencies/ui/global/AdvancedMfaMessage'

import { LegacyVpnEmptyList } from './LegacyVpnEmptyList'

export interface LegacyVpnListProps {
  cidOverride: string | undefined
}

type LegacyVpnListComponentProps = LastPassComponentProps<
  LegacyVpnListState,
  typeof legacyVpnListActions & typeof globalActions,
  LegacyVpnListProps
>

export const LegacyVpnListComponent: React.FunctionComponent<LegacyVpnListComponentProps> = ({
  state,
  props,
  actions
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(legacyVpnListActions.getLegacyVpnList())
  }, [dispatch])

  const isTableEmpty = !state.table.totalResults

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  return (isTableEmpty && !state.getStarted) || !userHasMfaAddon ? (
    <LegacyVpnEmptyList actions={actions} />
  ) : (
    <>
      <AdvancedMfaMessage
        isEmpty={state.getStarted}
        nonMspMessage={msg`Legacy & VPN is available with the Advanced MFA add-on.`}
      />
      <iframe
        css={css`
          width: 100%;
          height: 100%;
        `}
        src={getIdentityIFrameUrl(
          `${defaultConfig.identityIframeUrl}/Apps/VPN`,
          props.cidOverride
        )}
      />
    </>
  )
}

export function createLegacyVpnList<
  TState extends { legacyVpnList: LegacyVpnListState }
>() {
  return connectRedux(
    LegacyVpnListComponent,
    (state: TState) => state.legacyVpnList,
    {
      ...legacyVpnListActions,
      ...globalActions
    }
  )
}

export type LegacyVpnList = ReturnType<typeof createLegacyVpnList>

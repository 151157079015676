import { call } from 'redux-saga/effects'
import { put, select } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/get-group-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  Group,
  GroupResponse
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export const getApplicationDrawerState = (state: AppState) =>
  state.applicationDrawer

export function createGroupList(applicationsServices: UACServices.Services) {
  return function* getGroupList(
    action: ReturnType<typeof applicationsDrawerActions.getGroupList>
  ) {
    try {
      const resultsPerPage = action.payload.resultsPerPage

      yield put(applicationsDrawerActions.setGroupListLoading(true))
      try {
        const SSOApplicationGroupListResult: Responses = yield call(
          applicationsServices.getIdentityGroups
        )
        if (
          SSOApplicationGroupListResult.type ===
          UACServices.SSOIdentityGroupsAPI.SUCCESS
        ) {
          const applicationDrawerState: ApplicationDrawerState = yield select(
            getApplicationDrawerState
          )
          const ssoIdentityGroupsResponse =
            SSOApplicationGroupListResult.body.groupResponse.Value
          const ssoIdentityGroupList: Group[] = ssoIdentityGroupsResponse.map(
            (group: GroupResponse) => {
              return {
                id: group.Id,
                name: group.Name
              }
            }
          )
          const keyword = applicationDrawerState.groupsSearchInput.toLowerCase()
          const resultList =
            applicationDrawerState.groupsSearchInput &&
            applicationDrawerState.groupsSearchInput.length > 0
              ? ssoIdentityGroupList.filter(group =>
                  group.name.toLowerCase().includes(keyword)
                )
              : ssoIdentityGroupList
          const filteredResultList = resultList.filter(
            groupFromResult =>
              applicationDrawerState.groups.results.filter(
                groupFromState => groupFromState.id == groupFromResult.id
              ).length === 0
          )
          yield put(
            applicationsDrawerActions.setAvailableGroupList(
              filteredResultList,
              filteredResultList.length,
              resultsPerPage
            )
          )
        }
      } finally {
        yield put(applicationsDrawerActions.setGroupListLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Chip, ChipContainer, ChipGroup } from '@lastpass/lastkit'

const StyledChip = styled(Chip)`
  color: ${props => props.theme.colors.blue700};
`

interface Props {
  stagedUsersCount: number
  licenseCount: number
  licenseConsumptionRate: number
  licenseConsumptionRateTooltip: ReactElement
}

export const LicenseConsumptionRateAccordionChips: FC<Props> = ({
  stagedUsersCount,
  licenseCount,
  licenseConsumptionRate,
  licenseConsumptionRateTooltip
}) => {
  return (
    <ChipContainer>
      <StyledChip tooltip={licenseConsumptionRateTooltip}>
        {licenseConsumptionRate}
        {'%'}
      </StyledChip>
      <ChipGroup data-qa="NumbersChipGroup">
        <Chip>
          <Trans>Staged users</Trans>
          {': '}
          {stagedUsersCount}
        </Chip>
        <Chip>
          <Trans>Total licenses</Trans>
          {': '}
          {licenseCount}
        </Chip>
      </ChipGroup>
    </ChipContainer>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'

const success = (assignAdminUserList: User[], totalResults: number) =>
  createResponse(SUCCESS, { assignAdminUserList, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>
export function create(fetch: AuthFetch) {
  return async function service(adminLevelId: string, queryPath: string) {
    const [response, status] = await fetch(
      'adminLevels/' + adminLevelId + '/users/assignable' + queryPath,
      'GET'
    )
    if (response && status === StatusCodes.OK) {
      const assignAdminUsersList = JSON.parse(response).content.items.map(
        item => ({
          ...item,
          email: item.email || '',
          firstName: item.firstName || '',
          lastName: item.lastName || ''
        })
      )
      const totalResults = JSON.parse(response).content.totalCount
      return success(assignAdminUsersList, totalResults)
    }

    throw new UnknownServiceError()
  }
}

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { WorkstationMap } from '@lastpass/admin-console-dependencies/state/applications/workstations/workstations'

export const SUCCESS = 'success'

const success = (workstationListResponse: WorkstationMap) =>
  createResponse(SUCCESS, { workstationListResponse })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await identityApi(
        'api/v2/WorkstationPolicies/?size=1&page=1&searchString=',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        return success(JSON.parse(response))
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

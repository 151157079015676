import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UrlRule } from '@lastpass/admin-console-dependencies/types/url-rule'

export const SUCCESS = 'success'

const success = (payload: { rules: UrlRule[] }) =>
  createResponse(SUCCESS, payload)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch('url-rules', 'GET')

      if (status === StatusCodes.OK && response) {
        const rules: UrlRule[] = JSON.parse(response).content

        return success({
          rules
        })
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { KeyData } from '../../types/common'
import {
  BulkRotateAlpData,
  BulkRotatedKeys,
  BulkRotatedKeysResults,
  BulkRotateError,
  BulkRotateIdpData,
  CompanyWideIdpData
} from '../../types/key-rotation'
import { FederationErrors } from '../federation-error-codes'
import { rotateKeys } from './rotate-keys'

export interface BulkRotateKeysProps {
  alpData: BulkRotateAlpData[]
  userWideIdpData?: BulkRotateIdpData[]
  companyWideIdpData?: CompanyWideIdpData
}

export interface GetK1Props {
  alpItem: BulkRotateAlpData
  userWideIdpData?: BulkRotateIdpData[]
  companyWideOldK1?: Buffer
}

export const matchIdpUser = (
  userWideIdpData: BulkRotateIdpData[],
  alpItem: BulkRotateAlpData
): BulkRotateIdpData | null => {
  let filteredUsers = userWideIdpData
  // match users by subject if available
  // some idp can not provide subject (eg azure)
  if (alpItem.subject && userWideIdpData.every(idpData => idpData.subject)) {
    filteredUsers = filteredUsers.filter(
      idpData => idpData.subject === alpItem.subject
    )
  }

  // match users by uid if available
  // alp not necessarily has userId
  if (alpItem.userId && userWideIdpData.every(idpData => idpData.userId)) {
    filteredUsers = filteredUsers.filter(
      idpData => idpData.userId === alpItem.userId
    )
  }

  // fallback: match user by fragmentId
  const matchedUser = filteredUsers.filter(
    idpData => idpData.fragmentId === alpItem.fragmentId
  )

  // fragmentId should be unique for a successful match
  return matchedUser.length === 1 ? matchedUser[0] : null
}

export async function bulkRotateKeys({
  alpData,
  userWideIdpData,
  companyWideIdpData
}: BulkRotateKeysProps): Promise<BulkRotatedKeysResults> {
  if (
    !(companyWideIdpData?.oldK1 || companyWideIdpData?.newK1) &&
    !userWideIdpData
  ) {
    throw new Error(FederationErrors.MissingCompanyOrUserWideIdpData)
  }

  const rotatedKeys: BulkRotatedKeys = {
    alpData: [],
    idpData: []
  }

  const errors: BulkRotateError[] = []

  for (const alpItem of alpData) {
    let k1
    let idpUserData
    if (companyWideIdpData?.oldK1) {
      k1 = companyWideIdpData?.oldK1
    } else if (userWideIdpData) {
      idpUserData = matchIdpUser(userWideIdpData, alpItem)
      if (idpUserData) {
        k1 = idpUserData.k1
      }
    }

    if (!k1) {
      errors.push({
        subject: alpItem.subject,
        error: 'user not found in the IDP data'
      })
      continue
    }

    const userKeyData: KeyData = {
      k1,
      k2: alpItem.k2,
      fragmentId: alpItem.fragmentId
    }

    try {
      const newUserData = await rotateKeys({
        userKeyData,
        companyWideNewK1: companyWideIdpData?.newK1
      })

      rotatedKeys.alpData.push({
        k2: newUserData.k2,
        fragmentId: newUserData.fragmentId,
        subject: alpItem.subject,
        id: alpItem.id
      })

      if (userWideIdpData && idpUserData) {
        rotatedKeys.idpData.push({
          k1: newUserData.k1,
          subject: alpItem.subject,
          userId: idpUserData.userId,
          fragmentId: newUserData.fragmentId,
          alpId: alpItem.id
        })
      }
    } catch (e) {
      if (e instanceof Error) {
        errors.push({
          subject: alpItem.subject,
          error: e.message
        })
      }
    }
  }

  return {
    rotatedKeys,
    errors
  }
}

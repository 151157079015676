import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createCompanyLicensesSaga(
  compamyServices: UACServices.Services
) {
  return function* companyLicensesSaga() {
    try {
      const result: UACServices.CompanyLicensesAPI.Responses = yield call(
        compamyServices.companyLicenses
      )
      if (result.type === UACServices.CompanyLicensesAPI.SUCCESS) {
        yield put(
          companyInfoActions.setCompanyLicenses(
            result.body.remainingLicenses,
            result.body.totalLicenses
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

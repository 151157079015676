import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { DetailsDialog, PrimaryButton } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationAccessDeniedDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const StyledBodyText = styled.div`
  ${BodyRegularStyle};
`

const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  color: ${props => props.theme.colors.neutral900};
  &:hover,
  &:visited,
  &:active {
    text-decoration: underline;
    color: ${props => props.theme.colors.neutral900};
  }
`

interface KeyRotationAccessDeniedDialogProps {
  state: KeyRotationAccessDeniedDialog
  actions: typeof globalActions
}

export const KeyRotationAccessDeniedDialogComponent: FunctionComponent<KeyRotationAccessDeniedDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  const supportLinkUrl =
    'https://link.lastpass.com/rotate-knowledge-components-azure-ad'
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={msg`Rotation process failed`}
      subTitle={msg`You used an Azure account that lacks the permissions required to rotate K1 key components. Your own key may have been rotated, but you can still log in. No other keys were rotated.`}
      disableCloseOnBackground
      content={
        <StyledBodyText>
          <Trans>
            Restart the process using an Azure account with the required{' '}
            <StyledExternalLink
              href={supportLinkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              permissions described in this article.
            </StyledExternalLink>
          </Trans>
        </StyledBodyText>
      }
      footer={<StyledBodyText>{i18n._(state.errorCodeText)}</StyledBodyText>}
      buttons={[
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => dispatch(globalActions.confirmDialog())}
          key="confirm"
        >
          <Trans>Got it</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyMap,
  TeamsPolicyModelData
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import { SaveTeamsPolicySwitchOnData } from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getCurrentUrlFilters, getPolicyValue } from './enable-general-policy'
import { getPolicyInfo } from './get-general-policy'

const preparePolicyData = (policy: TeamsPolicy): TeamsPolicyModelData[] => {
  const policyData: TeamsPolicyModelData[] = [
    ...policy.policyData,
    {
      appliesTo: AppliesToOptions.all,
      policyValue: getPolicyValue(policy.policyValueType),
      policyNotes: '',
      cpid: null,
      exclusiveList: [],
      inclusiveList: [],
      inclusiveGroupList: [],
      exclusiveGroupList: []
    }
  ]

  return policyData
}

export function createAddConfigGeneralPolicySaga(
  policyService: UACServices.Services
) {
  return function* addConfigGeneralPolicySaga(
    action: ReturnType<
      typeof generalPoliciesDrawerActions.addConfigCurrentGeneralPolicy
    >
  ) {
    const policyKey = action.payload.policyKey
    const currentUrlFilters = yield select(getCurrentUrlFilters)

    if (policyKey) {
      try {
        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
            true,
            policyKey
          )
        )
        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy = policyMap[policyKey]

        const policyData = preparePolicyData(policy)
        const policySaveData: SaveTeamsPolicySwitchOnData = {
          policyKey,
          policyData
        }
        const result: UACServices.GeneralPolicySwitchONSaveAPI.Responses = yield call(
          policyService.generalPolicySwitchOnSave,
          policySaveData
        )

        if (result.type === UACServices.GeneralPolicySwitchONSaveAPI.SUCCESS) {
          yield put(
            generalPoliciesListActions.getGeneralPoliciesList({
              query: getQueryParams(
                currentUrlFilters.location,
                Namespace.policies
              ),
              path: {}
            })
          )
        } else if (
          result.type ===
          UACServices.GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`This policy can't have multiple configurations.`
            )
          )
          yield put(
            generalPoliciesListActions.getGeneralPoliciesList({
              query: getQueryParams(
                currentUrlFilters.location,
                Namespace.policies
              ),
              path: {}
            })
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      }
    }
  }
}

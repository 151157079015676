import { take } from 'redux-saga/effects'

import { GlobalActionTypes } from '@lastpass/admin-console-dependencies/state/global/actions'

export function* takeDialogInteractions() {
  const { type, payload } = yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])

  const isConfirmed = type === GlobalActionTypes.CONFIRM_DIALOG
  const isDiscarded = type === GlobalActionTypes.DISCARD_DIALOG

  return { isConfirmed, isDiscarded, payload }
}

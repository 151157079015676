import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { merge } from 'lodash'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { SiemIntegrationsConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'

export const initialConfig: SiemIntegrationsConfig = {
  splunk: {
    instanceUrl: '',
    token: '',
    index: '',
    isDisabled: false
  },
  azureSentinel: {
    workspaceId: '',
    key: '',
    isDisabled: false
  },
  alienVault: {
    host: '',
    isDisabled: false
  },
  qRadar: {
    host: '',
    logSourceIdentifier: '',
    isDisabled: false
  },
  logRhythm: {
    host: '',
    isDisabled: false
  }
}

export const useSiemIntegrationsGetConfigs = () => {
  const { siemIntegrationsGetConfigs } = useContext(ServerContext)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [configs, setConfigs] = useState<SiemIntegrationsConfig>(initialConfig)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      try {
        const response = await siemIntegrationsGetConfigs()

        setConfigs(merge({}, configs, response?.body))
      } catch (e) {
        dispatch(globalActions.setNotification(genericFailedNotification))
      }

      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    configs
  }
}

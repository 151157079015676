import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { RecoveryKeySharingDetails } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

export enum DistributeRecoveryKeysActionTypes {
  GET_RECOVERY_SHARING_KEY_DETAILS = '[distribute-recovery-keys] get_recovery_sharing_key_details',
  SET_RECOVERY_SHARING_KEY_DETAILS = '[distribute-recovery-keys] set_recovery_sharing_key_details',
  SET_TOTAL_NUMBER_OF_KEYS = '[distribute-recovery-keys] set-total-number-of-keys',
  SET_NUMBER_OF_SHARED_KEYS = '[distribute-recovery-keys] set-number-of-shared-keys',
  SET_IS_STOPPED = '[distribute-recovery-keys] set-is-stopped',
  SET_HAS_PROGRESS_ERRORS = '[distribute-recovery-keys] set-has-progress-errors',
  SET_IS_BEING_UPLOADED = '[distribute-recovery-keys] set-is-being-uploaded',
  SET_TARGET_ADMIN_EMAIL = '[distribute-recovery-keys] set-target-admin-email',
  RESET = '[distribute-recovery-keys] reset',
  DISTRIBUTE_RECOVERY_KEYS = '[distribute-recovery-keys] distribute-recovery-keys',
  SET_IS_LOADING = '[distribute-recovery-keys] set-is-loading',
  TRACKING_SHARE_BUTTON_CLICKED = '[distribute-recovery-keys] tracking-share-button-clicked',
  TRACKING_ERROR_VIEWED = '[distribute-recovery-keys] tracking-error-viewed',
  TRACKING_SUCCESS_VIEWED = '[distribute-recovery-keys] tracking-success-viewed'
}

export const distributeRecoveryKeysActions = {
  trackRecoveryKeySharingErrorViewed: () =>
    track(
      createAction(DistributeRecoveryKeysActionTypes.TRACKING_ERROR_VIEWED),
      {
        segment: uacEvent('Share Recovery Keys Invisibly Error Viewed', {
          Reason: 'No Keys Were Shared; Not All Keys Could Be Shared'
        })
      }
    ),
  trackRecoveryKeySharingSuccessViewed: () =>
    track(
      createAction(DistributeRecoveryKeysActionTypes.TRACKING_SUCCESS_VIEWED),
      {
        segment: uacEvent('Share Recovery Keys Invisibly Success')
      }
    ),
  trackShareRecoveryKeyButton: () =>
    track(
      createAction(
        DistributeRecoveryKeysActionTypes.TRACKING_SHARE_BUTTON_CLICKED
      ),
      {
        segment: uacEvent('Share Recovery Keys Invisibly Share clicked', {
          Action: 'Confirm'
        })
      }
    ),
  getRecoveryKeySharingDetails: () =>
    createAction(
      DistributeRecoveryKeysActionTypes.GET_RECOVERY_SHARING_KEY_DETAILS
    ),
  setRecoveryKeySharingDetails: (
    RecoveryKeySharingDetails: RecoveryKeySharingDetails
  ) =>
    createAction(
      DistributeRecoveryKeysActionTypes.SET_RECOVERY_SHARING_KEY_DETAILS,
      RecoveryKeySharingDetails
    ),
  distributeRecoveryKeys: () =>
    createAction(DistributeRecoveryKeysActionTypes.DISTRIBUTE_RECOVERY_KEYS),
  setTotalNumberOfKeys: (totalNumberOfKeys: number) =>
    createAction(
      DistributeRecoveryKeysActionTypes.SET_TOTAL_NUMBER_OF_KEYS,
      totalNumberOfKeys
    ),
  setNumberOfSharedKeys: (numberOfSharedKeys: number) =>
    createAction(
      DistributeRecoveryKeysActionTypes.SET_NUMBER_OF_SHARED_KEYS,
      numberOfSharedKeys
    ),
  setIsStopped: (isStopped: boolean) =>
    track(
      createAction(DistributeRecoveryKeysActionTypes.SET_IS_STOPPED, isStopped),
      {
        segment: uacEvent('Share Recovery Keys Invisibly Stop Sharing Clicked')
      }
    ),
  setHasProgressErrors: (hasProgressErrors: boolean) =>
    createAction(
      DistributeRecoveryKeysActionTypes.SET_HAS_PROGRESS_ERRORS,
      hasProgressErrors
    ),
  setIsBeingUploaded: (isBeingUploaded: boolean) =>
    createAction(
      DistributeRecoveryKeysActionTypes.SET_IS_BEING_UPLOADED,
      isBeingUploaded
    ),
  setTargetAdminEmail: (email: string) =>
    createAction(
      DistributeRecoveryKeysActionTypes.SET_TARGET_ADMIN_EMAIL,
      email
    ),
  setIsLoading: (isLoading: boolean) =>
    createAction(DistributeRecoveryKeysActionTypes.SET_IS_LOADING, isLoading),
  reset: () => createAction(DistributeRecoveryKeysActionTypes.RESET)
}

export type DistributeRecoveryKeysActions = ActionsUnion<
  typeof distributeRecoveryKeysActions
>

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import { DrawerTable, PrimaryButton, TableView } from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  sharedFolderDrawerActions,
  SharedFolderUser
} from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'
import { SharedFoldersUsersListState } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/state'

import { UserPermissions } from '../users/view/UserSharedFoldersPermissions'

export interface SharedFolderUsersListProps {
  isCidOverrideMode: boolean
}

type SharedFolderUsersListComponentProps = LastPassComponentProps<
  SharedFoldersUsersListState,
  typeof sharedFolderDrawerActions,
  SharedFolderUsersListProps
>

const StyledRow = styled.div`
  margin-left: 5px;
`

export const SharedFolderUsersListComponent: React.FunctionComponent<SharedFolderUsersListComponentProps> = ({
  state,
  actions,
  props
}) => {
  const { id } = useParams<{ id: string }>()

  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      sharedFolderDrawerActions.getSharedFolderUsersList({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  let sharedFolderId: string
  if (id) {
    sharedFolderId = id
  }

  const footerNodes = !props.isCidOverrideMode
    ? [
        <PrimaryButton
          key="Assign users"
          onClick={() => actions.sharedFolderAddUsers(sharedFolderId)}
          data-qa="AddUsersButton"
        >
          <Trans>Assign users</Trans>
        </PrimaryButton>
      ]
    : undefined

  return (
    <>
      <DrawerTable
        table={state.usersTable}
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No users added yet</Trans>}
            text={<Trans>Assign users to shared folder</Trans>}
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: function SharedFolderNameRenderer(
              record: SharedFolderUser
            ) {
              return <StyledRow>{record.usersname}</StyledRow>
            }
          },
          {
            name: <Trans>Email</Trans>,
            renderer: function SharedFolderSecurityScoreRenderer(
              record: SharedFolderUser
            ) {
              return <StyledRow>{record.email}</StyledRow>
            }
          },
          {
            name: <Trans>Permissions</Trans>,
            renderer: function PermissionRenderer(record: SharedFolderUser) {
              return (
                <UserPermissions
                  record={{
                    id: record.uid,
                    name: '',
                    usersCount: 0,
                    isSharedByPolicy:
                      record.outsideenterprise == '0' ? false : true,
                    canAdminister: record.canadminister == '0' ? false : true,
                    isReadonly: record.readonly == '0' ? false : true,
                    canViewPass: record.canviewpass == '0' ? false : true
                  }}
                />
              )
            }
          }
        ]}
        footerNodes={footerNodes}
      />
    </>
  )
}

export function createSharedFolderUsersList<
  TState extends { sharedFolderDrawer: SharedFoldersUsersListState }
>() {
  return connectRedux(
    SharedFolderUsersListComponent,
    (state: TState) => state.sharedFolderDrawer,
    { ...sharedFolderDrawerActions }
  )
}

export type SharedFolderUsersList = ReturnType<
  typeof createSharedFolderUsersList
>

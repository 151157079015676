import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular } from '@lastpass/lastkit'
import { NumberedList } from '@lastpass/lastkit/components/NumberedList'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'

const StyledLocationLink = styled.a`
  margin-top: 14px;
  font-weight: bold;
`

const DownloadLink = styled.a`
  font-weight: bold;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-top: 16px;
`

export const UniversalProxyInstall = () => {
  return (
    <>
      <StyledBodyRegular>
        <Trans>
          Ensure that your environment meets the{' '}
          <a
            href="https://support.logmeininc.com/lastpass/help/minimum-software-requirements-for-lastpass-universal-proxy"
            data-qa="SystemRequirementsLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            system requirements
          </a>{' '}
          for LastPass Universal Proxy.
        </Trans>
      </StyledBodyRegular>
      <NumberedList
        items={[
          <DownloadLink
            key={'1'}
            href={
              'https://download.cloud.lastpass.com/universal_proxy/lastpass-proxy.zip'
            }
            data-qa={'DownloadLink'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              mfaAppsDrawerActions.configureAppClicked('Download Installer', {
                type: MfaApplicationType.universalProxy
              })
            }
          >
            <Trans>Download the installer.</Trans>
          </DownloadLink>,
          <Trans key={'2'}>
            <a
              href="https://support.logmeininc.com/lastpass/help/how-do-i-install-lastpass-universal-proxy"
              data-qa="RunTheInstallerLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              Run
            </a>{' '}
            the installer.
          </Trans>,
          <Trans key={'3'}>
            Configure the LastPass Universal Proxy settings{' '}
            <a
              href="https://support.logmeininc.com/lastpass/help/how-do-i-configure-the-lastpass-universal-proxy-via-command-line"
              data-qa="UsingTheCommandLineInterfaceLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              using either the command line interface (CLI)
            </a>{' '}
            or the{' '}
            <a
              href="https://support.logmeininc.com/lastpass/help/how-do-i-configure-lastpass-universal-proxy-with-the-serverproperties-configuration-file"
              data-qa="ServerPropertiesConfigurationFileLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              server properties configuration file
            </a>
            .
          </Trans>,
          <Trans key={'4'}>
            Configure your{' '}
            <a
              href="https://support.logmeininc.com/lastpass/help/which-vpns-does-lastpass-mfa-support"
              data-qa="ConfigureYourApplicationLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              application
            </a>
            , including entering your key and secret.
          </Trans>
        ]}
      />
      <StyledBodyRegular>
        <Trans>
          Users who have configured the LastPass Authenticator multifactor app
          will be prompted for MFA on subsequent logins.
        </Trans>
      </StyledBodyRegular>
      <StyledLocationLink
        href={
          'https://support.logmeininc.com/lastpass/help/which-vpns-does-lastpass-mfa-support'
        }
        data-qa={'LearnMoreLink'}
        target="_blank"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </>
  )
}

import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { BodyRegular, Drawer } from '@lastpass/lastkit'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'
import { DownloadReportName } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/state'
import {
  GeneratedSecurityReportResponse,
  GenerateReport,
  GetGeneratedReports
} from '@lastpass/admin-console-dependencies/types/generated-security-reports'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  ElementType,
  SecurityReportEvent
} from '@lastpass/admin-console-dependencies/types/security-report'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { GeneratedSecurityReportDrawerFooter } from '@lastpass/admin-console-dependencies/ui/reports/security/components/GeneratedSecurityReportDrawerFooter'
import { GeneratedSecurityReportDrawerTable } from '@lastpass/admin-console-dependencies/ui/reports/security/components/GeneratedSecurityReportDrawerTable'

export interface GeneratedSecurityReportDrawerProps {
  closeLink: string
  event: SecurityReportEvent
  downloadReportName: DownloadReportName
  generateReport: GenerateReport
  getReports: GetGeneratedReports
}

const styleMarginBottom = css`
  margin-bottom: 24px;
`

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
`

export const GeneratedSecurityReportDrawer: FC<GeneratedSecurityReportDrawerProps> = ({
  closeLink,
  event,
  downloadReportName,
  generateReport,
  getReports
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isJobStarted, setIsJobStarted] = useState<boolean>(false)
  const [
    reportsResponse,
    setReportsResponse
  ] = useState<GeneratedSecurityReportResponse | null>(null)

  const isUrlDecryptionEnabled = useFeatureFlags(
    FeatureFlags.isUrlDecryptionEnabled
  )

  const isSharedFolderUrlDecryptionEnabled = useFeatureFlags(
    FeatureFlags.isSharedFolderUrlDecryptionEnabled
  )

  const dispatch = useDispatch()
  const history = useHistory()

  const isGenerating: boolean =
    isJobStarted || Boolean(reportsResponse && reportsResponse.isJobInProgress)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const response = await getReports()
        setReportsResponse(response.body)
      } catch (e) {
        history.push(closeLink)
        dispatch(globalActions.setNotification(genericFailedNotification))
      } finally {
        setIsLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGenerateButtonClick = async (): Promise<void> => {
    try {
      setIsJobStarted(true)
      await generateReport()
      dispatch(
        globalActions.setNotification({
          type: NotificationType.success,
          message: msg`Generating report. We’ll send an email when it’s ready.`,
          autoDismiss: true
        })
      )
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
      setIsJobStarted(false)
    }
  }

  const handleDownloadClick = reportId => {
    dispatch(
      securityReportDrawerActions.setDownloadReportName(downloadReportName)
    )
    if (downloadReportName === 'urls-in-vaults') {
      dispatch(
        securityReportDrawerActions.downloadUrlsInVaultsReport(
          reportId,
          isUrlDecryptionEnabled,
          setIsLoading
        )
      )
    } else if (downloadReportName === 'urls-in-shared-folders') {
      dispatch(
        securityReportDrawerActions.downloadUrlsInSharedFoldersReport(
          reportId,
          isSharedFolderUrlDecryptionEnabled,
          setIsLoading
        )
      )
    }
  }

  return (
    <Drawer
      closeLink={closeLink}
      mainTitle={<>{i18n._(event.riskName)}</>}
      data-qa="GeneratedSecurityReportDrawer"
    >
      {isLoading && <Loading color="blue900" active />}
      <BodyRegular
        data-qa="GeneratedSecurityReportDrawerDescription"
        css={isGenerating && styleMarginBottom}
      >
        {isGenerating ? (
          <Trans>
            We’re generating your report. Depending on the size of your
            organization, this could take a while. We’ll send an email when it’s
            ready.
          </Trans>
        ) : (
          event.descriptions?.map(description => {
            switch (description.element) {
              case ElementType.p:
                return (
                  <p key={description.message.id} css={styleMarginBottom}>
                    {i18n._(description.message)}
                  </p>
                )
              case ElementType.ul:
                return (
                  <div css={styleMarginBottom}>
                    <p key={description.message.id}>
                      {i18n._(description.message)}
                    </p>
                    <StyledList>
                      {description.listMessages?.map(subMessage => (
                        <li key={subMessage.id}>{i18n._(subMessage)}</li>
                      ))}
                    </StyledList>
                  </div>
                )
            }
          })
        )}
      </BodyRegular>
      {reportsResponse && Boolean(reportsResponse.reports.length) && (
        <GeneratedSecurityReportDrawerTable
          reports={reportsResponse.reports}
          onDownloadClick={handleDownloadClick}
        />
      )}
      <GeneratedSecurityReportDrawerFooter
        onButtonClick={handleGenerateButtonClick}
        isGenerating={isGenerating}
      />
    </Drawer>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = (csv: string) => createResponse(SUCCESS, csv)
const failure = () => createResponse(FAILURE)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(fromDateUtc: string, toDateUtc: string) {
    const [response, status] = await fetch(
      `company/managed-companies/usage?fromDateUtc=${fromDateUtc}&toDateUtc=${toDateUtc}`,
      'GET'
    )

    if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
      throw new UnknownServiceError()
    }

    if (status !== StatusCodes.OK) {
      return failure()
    }

    return success(response)
  }
}

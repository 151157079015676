import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { BodyBold, BodyRegular, TopBanner } from '@lastpass/lastkit'

import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.white};
  height: 16px;
  vertical-align: text-bottom;
`

const Container = styled.div`
  padding: 10px 0;
`

const StyledText = styled(BodyRegular)`
  color: ${props => props.theme.colors.white};
  line-height: 20px;
`

const StyledTextBold = styled(BodyBold)`
  color: ${props => props.theme.colors.white};
  margin: 0 8px;
  line-height: 20px;
`

export const MspFirstBillingDateBanner: React.FunctionComponent = () => {
  const companyInfo: CompanyInfoState = useSelector<AppState, CompanyInfoState>(
    state => state.companyInfo
  )

  if (
    !companyInfo.mspBillingDetails ||
    !companyInfo.mspBillingDetails.firstMspBillingDateUtc
  ) {
    return null
  }

  return (
    <TopBanner backgroundColor="purple900">
      <Container data-qa="MspFirstBillingDateBanner">
        <StyledHelpIcon />
        <StyledTextBold data-qa="MspFirstBillingDateBannerBoldText">
          <Trans>{`Your first charge for LastPass MSP comes on ${moment(
            companyInfo.mspBillingDetails.firstMspBillingDateUtc
          ).format('L')}`}</Trans>
        </StyledTextBold>
        <StyledText data-qa="MspFirstBillingDateBannerText">
          <Trans>
            To avoid unwanted charges, cancel your subscription before that
            date.
          </Trans>
        </StyledText>
      </Container>
    </TopBanner>
  )
}

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { Checkbox } from '@lastpass/lastkit'

import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import { GoogleUser } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

import {
  CheckBoxContainer,
  PartialSyncItemContainer,
  PartialSyncItemListContainer,
  StyledSearchInput
} from './StyledComponents'

interface GoogleUserListProps {
  users: GoogleUser[]
  handleUserSelection: (id: string, selected: boolean) => void
  isLoading: boolean
  nextPageToken: string | null
}

const ItemTextContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
  overflow: hidden;
`

const ItemText = styled.div`
  width: 50%;
  box-sizing: border-box;
  text-align: left;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Item = styled(PartialSyncItemContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
  width: 100%;
  max-width: 100%;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
`

const Header = styled(Item)`
  padding-left: 37px;
`

const ItemList = styled(PartialSyncItemListContainer)`
  overflow-x: hidden;
  overflow-y: auto;
`
const ItemListContainer = styled.div`
  width: 100%;
  position: relative;
`

export const GoogleUserList: FunctionComponent<GoogleUserListProps> = ({
  isLoading,
  users,
  handleUserSelection,
  nextPageToken
}) => {
  const dispatch = useDispatch()

  const [googleUsersQuery, setGoogleUsersQuery] = useState<string | null>(null)

  const fetchMoreUsers = (query: string) => {
    dispatch(directoriesActions.fetchGoogleUsers(query, false))
  }

  const handleGoogleUserSearch = useCallback(
    (query: string) => {
      dispatch(directoriesActions.fetchGoogleUsers(query, true))
    },
    [dispatch]
  )

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const element = e.currentTarget

    const hasReachedListEnd = users.length !== 0 && nextPageToken === null

    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight &&
      !isLoading &&
      !hasReachedListEnd
    ) {
      fetchMoreUsers(googleUsersQuery || '')
    }
  }

  useEffect(() => {
    if (googleUsersQuery !== null) {
      handleGoogleUserSearch(googleUsersQuery)
    }
  }, [handleGoogleUserSearch, googleUsersQuery])

  return (
    <>
      <StyledSearchInput
        value={googleUsersQuery || ''}
        data-qa="GoogleIntegrationSyncOptionsSyncSpecifiedUsersSearchBar"
        onChange={e => {
          const value = e.target.value
          setGoogleUsersQuery(value)
        }}
        placeholder={msg`Search users...`}
        flexGrow={true}
      />
      <ItemListContainer>
        {isLoading && <Loading color="blue900" active={true} isFull={true} />}
        <ItemList
          onScroll={(e: React.UIEvent<HTMLElement>) => handleScroll(e)}
          data-qa="GoogleUserListScrollContainer"
        >
          {users.length > 0 && (
            <>
              <Header>
                <ItemText>
                  <Trans>Email</Trans>
                </ItemText>
                <ItemText>
                  <Trans>Name</Trans>
                </ItemText>
              </Header>
              {users.map(user => {
                return (
                  <Item key={user.id} selected={user.selected}>
                    <CheckBoxContainer>
                      <Checkbox
                        onChange={e => {
                          handleUserSelection(user.id, e.target.checked)
                        }}
                        checked={user.selected}
                      />
                    </CheckBoxContainer>
                    <ItemTextContainer>
                      <ItemText title={user.email}>{user.email}</ItemText>
                      <ItemText title={user.name}>{user.name}</ItemText>
                    </ItemTextContainer>
                  </Item>
                )
              })}
            </>
          )}
        </ItemList>
      </ItemListContainer>
    </>
  )
}

import React, { FC } from 'react'

import { Trans } from '@lingui/macro'

import { Badge } from '@lastpass/lastkit/components'
import { Theme } from '@lastpass/lastkit/theme'

import { ManagedCompanyStatus } from '@lastpass/admin-console-dependencies/types/managed-company'
import { ManagedCompanyStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/ManagedCompanyStatusMapping'

interface StatusBadgeProps {
  status: ManagedCompanyStatus
}

export const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
  const label = ManagedCompanyStatusMapping[status] || <Trans>Unknown</Trans>
  let backgroundColor: keyof Theme['colors']
  let textColor: keyof Theme['colors']

  switch (status) {
    case ManagedCompanyStatus.active:
      backgroundColor = 'teal'
      textColor = 'white'
      break
    case ManagedCompanyStatus.new:
      backgroundColor = 'green'
      textColor = 'white'
      break
    case ManagedCompanyStatus.suspended:
      backgroundColor = 'grayBorder'
      textColor = 'neutral900'
      break
    default:
      backgroundColor = 'teal'
      textColor = 'white'
      break
  }

  return (
    <Badge
      colors={{
        backgroundColor,
        textColor
      }}
    >
      {label}
    </Badge>
  )
}

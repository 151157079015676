import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { GroupUserActionsType } from '@lastpass/admin-console-dependencies/state/users/groups/operations/group-operations'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createInviteUsersGroupSaga(uacServices: UACServices.Services) {
  return function* inviteUsersGroupSaga(
    action: ReturnType<typeof groupOperationActions.inviteUsers>
  ) {
    try {
      const groupId = action.payload
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Send LastPass invitations`,
        text: msg`All users in the group who are new or haven't yet enabled their account will be sent an invitation to LastPass.`,
        discardText: msg`Cancel`,
        confirmText: msg`Send`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type !== GlobalActionTypes.CONFIRM_DIALOG) {
        return
      }
      const result: UACServices.GroupUserOperationsAPI.Responses = yield call(
        uacServices.groupUserOperations,
        GroupUserActionsType.inviteUsers,
        groupId
      )

      if (result.type === UACServices.GroupUserOperationsAPI.SUCCESS) {
        yield put(
          globalActions.setNotification({
            message: msg`New invitations have been sent to the selected users. Bulk actions may take up to 15 minutes to complete.`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put, select } from 'redux-saga/effects'
import { addSlashes } from 'slashes'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

import { handleGoogleSetupFailure } from './handle-google-setup-failure'

export function createSearchGoogleGroups(
  enterpriseOptionServices: UACServices.Services
) {
  return function* searchGoogleGroups(
    action: ReturnType<typeof directoriesActions.searchGoogleGroups>
  ) {
    try {
      const groupIdsToSync = yield select(
        (state: AppState) => state.integrations.Google.groupIdsToSync
      )
      const nextPageToken = yield select(
        (state: AppState) => state.integrations.GoogleGroups.nextPageToken
      )

      const { search, replace } = action.payload

      const result: UACServices.GoogleGroupsSearchAPI.Responses = yield call(
        enterpriseOptionServices.googleGroupsSearch,
        `${encodeURIComponent(addSlashes(search || '', "'"))}`,
        !replace ? nextPageToken : null
      )

      if (result.type === UACServices.GoogleGroupsSearchAPI.SUCCESS) {
        const groupsWithSelectionState = result.body.googleGroups.map(
          group => ({
            ...group,
            selected: groupIdsToSync.includes(group.id)
          })
        )

        yield put(
          directoriesActions.setGoogleGroups(
            groupsWithSelectionState,
            replace,
            result.body.nextPageToken || null
          )
        )
      } else {
        yield call(handleGoogleSetupFailure)
      }
    } catch {
      yield call(handleGoogleSetupFailure)
    }
  }
}

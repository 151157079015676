import React, { FunctionComponent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import DuoAuthLogo from '@lastpass/assets/png/multifactor/authlogo-duo.png'
import DuoAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-duo@2x.png'
import GoogleAuthLogo from '@lastpass/assets/png/multifactor/authlogo-google.png'
import GoogleAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-google@2x.png'
import LastpassAuthLogo from '@lastpass/assets/png/multifactor/authlogo-lastpass.png'
import LastpassAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-lastpass@2x.png'
import MicrosoftAuthLogo from '@lastpass/assets/png/multifactor/authlogo-microsoft.png'
import MicrosoftAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-microsoft@2x.png'
import SalesforceAuthLogo from '@lastpass/assets/png/multifactor/authlogo-salesforce.png'
import SalesforceAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-salesforce@2x.png'
import SecureAuthLogo from '@lastpass/assets/png/multifactor/authlogo-secureauth.png'
import SecureAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-secureauth@2x.png'
import SecureIdAuthLogo from '@lastpass/assets/png/multifactor/authlogo-securid.png'
import SecureIdAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-securid@2x.png'
import SymantecVipAuthLogo from '@lastpass/assets/png/multifactor/authlogo-symantec.png'
import SymantecVipAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-symantec@2x.png'
import ToopherAuthLogo from '@lastpass/assets/png/multifactor/authlogo-toopher.png'
import ToopherAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-toopher@2x.png'
import TranksaktAuthLogo from '@lastpass/assets/png/multifactor/authlogo-transakt.png'
import TranksaktAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-transakt@2x.png'
import YubikeyAuthLogo from '@lastpass/assets/png/multifactor/authlogo-yubico.png'
import YubikeyAuthLogoRetina from '@lastpass/assets/png/multifactor/authlogo-yubico@2x.png'
import { ReactComponent as LastPassMFALogo } from '@lastpass/assets/svg/multifactor/lastpass-mfa.svg'
import { RetinaImage } from '@lastpass/ui'

import { MultifactorProvider } from './multifactor-provider-types'

const logoCSS = css`
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
`

export const ProviderImage = styled(RetinaImage)`
  ${logoCSS}
`

const imageSources = {
  [MultifactorProvider.LASTPASS_AUTHENTICATOR]: [
    LastpassAuthLogo,
    LastpassAuthLogoRetina
  ],
  [MultifactorProvider.SALESFORCE]: [
    SalesforceAuthLogo,
    SalesforceAuthLogoRetina
  ],
  [MultifactorProvider.SECURE_AUTH]: [SecureAuthLogo, SecureAuthLogoRetina],
  [MultifactorProvider.SECURID]: [SecureIdAuthLogo, SecureIdAuthLogoRetina],
  [MultifactorProvider.SYMANTEC_VIP]: [
    SymantecVipAuthLogo,
    SymantecVipAuthLogoRetina
  ],
  [MultifactorProvider.TOOPHER]: [ToopherAuthLogo, ToopherAuthLogoRetina],
  [MultifactorProvider.DUO]: [DuoAuthLogo, DuoAuthLogoRetina],
  [MultifactorProvider.TRANSAKT]: [TranksaktAuthLogo, TranksaktAuthLogoRetina],
  [MultifactorProvider.MICROSOFT_AUTHENTICATOR]: [
    MicrosoftAuthLogo,
    MicrosoftAuthLogoRetina
  ],
  [MultifactorProvider.YUBIKEY]: [YubikeyAuthLogo, YubikeyAuthLogoRetina],
  [MultifactorProvider.GOOGLE_AUTHENTICATOR]: [
    GoogleAuthLogo,
    GoogleAuthLogoRetina
  ]
}

export const ProviderLogo: FunctionComponent<{
  type: MultifactorProvider
}> = ({ type }) => {
  if (type === MultifactorProvider.LASTPASS_MFA) {
    return <LastPassMFALogo css={logoCSS} />
  }
  const image = imageSources[type]
  if (image) {
    const [src, src2x] = image
    return <ProviderImage src={src} src2x={src2x} />
  }
  return null
}

import React, {
  FunctionComponent,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useState
} from 'react'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'
import CopyToClipboard from 'copy-to-clipboard'

import { ReactComponent as CopyToClipboardIcon } from '@lastpass/assets/svg/admin-console/icon-copy-to-clipboard.svg'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'

import {
  BodyRegularStyle,
  BodySemiboldStyle,
  CaptionRegularStyle
} from '../styles'
import { WithTooltip } from './Tooltip'

type InputProperties = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder'
>

export interface InputProps extends InputProperties {
  placeholder?: MessageDescriptor
  icon?: React.ComponentType
  error?: boolean
  errorText?: React.ReactElement
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  fluid?: boolean
  iconToRight?: boolean
  toolTip?: string
  copyToClipboard?: boolean
  actionOnCopy?: () => void
  isPassword?: boolean
  copyFormat?: string
  copyIcon?: ReactElement
}

interface StyledInputProps {
  icon?: boolean
  error?: boolean
  disabled?: boolean
  readOnly?: boolean
  iconToRight?: boolean
  copyToClipboard?: boolean
}

const Container = styled.div`
  position: relative;
  display: flex;
`

const StyledLabel = styled.label<{ fluid?: boolean }>`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
  ${props => props.fluid && 'width: 100%'};
`

const StyledError = styled.span`
  ${CaptionRegularStyle};
  position: absolute;
  color: ${props => props.theme.colors.red700};
  line-height: 15px;
  bottom: -15px;
`

const Input = styled.input<StyledInputProps>`
  ${BodyRegularStyle};
  height: 40px;
  max-width: 560px;
  width: 100%;
  border: ${props =>
    ((props.disabled || props.readOnly) &&
      '1px solid ' + props.theme.colors.neutral300) ||
    (props.error && '2px solid ' + props.theme.colors.red700) ||
    '1px solid ' + props.theme.colors.neutral400};
  background: ${props =>
    ((props.disabled || props.readOnly) && props.theme.colors.neutral200) ||
    props.theme.colors.white};
  box-sizing: border-box;
  border-top-left-radius: ${props => props.theme.radius.pixel4};
  border-top-right-radius: ${props =>
    props.copyToClipboard ? '0px' : props.theme.radius.pixel4};
  border-bottom-right-radius: ${props =>
    props.copyToClipboard ? '0px' : props.theme.radius.pixel4};
  border-bottom-left-radius: ${props => props.theme.radius.pixel4};

  padding: ${props => (props.error ? '7px 15px' : '8px 16px')};
  ${props => props.icon && !props.iconToRight && 'padding-left: 48px'};
  ${props =>
    props.icon &&
    props.iconToRight &&
    !props.copyToClipboard &&
    'padding-right: 48px'};
  ${props =>
    props.icon &&
    props.iconToRight &&
    props.copyToClipboard &&
    'padding-right: 48px'};
  ${props => props.disabled && 'color:' + props.theme.colors.neutral500};

  ::placeholder {
    color: ${props => props.theme.colors.neutral500};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${props => props.theme.colors.blue500};
    ${props => props.icon && !props.iconToRight && 'padding-left: 47px'};
    ${props => props.icon && props.iconToRight && 'padding-right: 47px'};
  }
`

const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 9px;
`
const StyledCopyToClipboardDiv = styled.div<StyledInputProps>`
  background-color: ${props => props.theme.colors.blue700};
  height: 38px;
  width: 45px;
  max-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${props =>
    ((props.disabled || props.readOnly) && props.theme.colors.neutral300) ||
    props.theme.colors.neutral400};
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: ${props => props.theme.radius.pixel4};
  border-bottom-right-radius: ${props => props.theme.radius.pixel4};
  border-bottom-left-radius: 0px;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.blue800};
  }
  :active {
    background-color: ${props => props.theme.colors.blue900};
  }
`

const copyToClipboardLogic = (content, options?) => {
  CopyToClipboard(content, options)
}

export const TextInput: FunctionComponent<InputProps> = ({
  placeholder,
  icon,
  iconToRight,
  error,
  errorText,
  children,
  toolTip,
  copyToClipboard,
  isPassword,
  copyIcon,
  ...props
}) => {
  const [inputText, setinputText] = useState(
    props.value || props.defaultValue || ''
  )
  useEffect(() => {
    setinputText(props.value || props.defaultValue || '')
  }, [props.value, props.defaultValue])

  const StyledIcon = icon
    ? styled(icon)<{ disabled?: boolean }>`
        height: 16px;
        width: 16px;
        position: absolute;
        top: 12px;
        ${iconToRight ? 'right:16px' : 'left:16px'};
        color: ${props =>
          props.disabled
            ? props.theme.colors.neutral500
            : props.theme.colors.neutral700};
      `
    : undefined

  const inputType = isPassword ? 'password' : props.type

  return (
    <>
      <StyledLabel fluid={props.fluid}>
        {children}
        <Container>
          {StyledIcon && <StyledIcon disabled={props.disabled} />}
          <InputRow>
            <Input
              {...props}
              type={inputType}
              value={inputText}
              icon={!!icon}
              iconToRight={!!iconToRight}
              error={!!error}
              placeholder={placeholder ? i18n._(placeholder) : ''}
              copyToClipboard={copyToClipboard}
              onChange={event => {
                {
                  props.onChange && props.onChange(event)
                }
                setinputText(event.target.value)
              }}
            />
            {copyToClipboard && (
              <StyledCopyToClipboardDiv
                {...props}
                onClick={() => {
                  copyToClipboardLogic(inputText, {
                    format: props.copyFormat
                  })
                  props.actionOnCopy && props.actionOnCopy()
                }}
                data-qa={'CopyToClipboardButton'}
              >
                {copyIcon || <CopyToClipboardIcon />}
              </StyledCopyToClipboardDiv>
            )}
            {toolTip && (
              <WithTooltip tooltip={toolTip} placement="bottom">
                <StyledHelpIcon />
              </WithTooltip>
            )}
          </InputRow>
          {!!errorText && <StyledError>{errorText}</StyledError>}
        </Container>
      </StyledLabel>
    </>
  )
}

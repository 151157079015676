import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { convertFileToBase64String } from '@lastpass/admin-console-dependencies/services/file-converter'
import { RSASecureIDRadius } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

const getBase64String = async (
  logo: File | string | null | undefined
): Promise<[string, number]> => {
  if (!logo) {
    return ['', 0]
  }

  if (typeof logo === 'string') {
    return [logo, logo.length]
  }

  let base64String = (await convertFileToBase64String(logo)) as string
  base64String = base64String.replace(/^data:.+;base64,/, '')
  return [base64String, logo.size]
}

export function createSaveRsaConfig(multifactorServices: UACServices.Services) {
  return function* saveRsaConfig(
    action: ReturnType<typeof multifactorDrawerActions.saveRsaConfig>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      const formValues = action.payload.rsaConfig
      const [logo124x124, logo124x124Length] = yield call(
        getBase64String,
        formValues.logo124x124
      )
      const [logo190x41, logo190x41Length] = yield call(
        getBase64String,
        formValues.logo190x41
      )
      const rsaConfig: RSASecureIDRadius = {
        failureMessage: formValues.failureMessage,
        hasRadiusSharedSecret: formValues.hasRadiusSharedSecret,
        logo124x124: logo124x124,
        logo124x124Length: logo124x124Length || formValues.logo124x124Length,
        logo190x41: logo190x41,
        logo190x41Length: logo190x41Length || formValues.logo190x41Length,
        radiusServerIp: formValues.radiusServerIp,
        radiusSharedSecret: formValues.radiusSharedSecret,
        radiusSharedSecretLength: formValues.radiusSharedSecretLength,
        radiusTimeout: Number(formValues.radiusTimeout),
        serviceName: formValues.serviceName
      }

      try {
        const response = yield call(
          multifactorServices.rsaSecureIDRadiusSave,
          rsaConfig
        )

        if (response.type === UACServices.RSASecureIDRadiusSaveAPI.SUCCESS) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { DirectoriesActionTypes } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

import { createDirectoryIntegrationsSaga } from './directories-integrations'
import { createFetchGoogleAuditLogList } from './fetch-google-audit-log-list'
import { createFetchGoogleGroups } from './fetch-google-groups'
import { createFetchGoogleUsers } from './fetch-google-users'
import { createFetchSystemLogList } from './fetch-system-log-list'
import { createGetADAPIKeyExistence } from './get-ad-api-key-existence'
import { createGetADVersionLastSync } from './get-ad-version-last-sync'
import { createGetDirectoryAccountInformation } from './get-directory-account-info'
import { createGetDirectoryProvisionToken } from './get-directory-provision-token'
import { createGetDirectorySettings } from './get-directory-settings'
import { createGoogleSyncUsers } from './google-sync-users'
import { createResetADAPIKey } from './reset-ad-api-key'
import {
  createSaveGoogleIntegration,
  createUpdateGoogleSyncOptions
} from './save-google-integration'
import { createSearchGoogleGroups } from './search-google-groups'

export function createDirectoriesSaga(
  directoriesService: UACServices.Services
) {
  const getDirectoryIntegrationsSaga = createDirectoryIntegrationsSaga()
  const getDirectoryProvisionTokenSaga = createGetDirectoryProvisionToken(
    directoriesService
  )
  const getDirectorySettingsSaga = createGetDirectorySettings(
    directoriesService
  )
  const resetADAPIKeySaga = createResetADAPIKey(directoriesService)
  const getADVersionLastSync = createGetADVersionLastSync(directoriesService)
  const getADAPIKeyExistence = createGetADAPIKeyExistence(directoriesService)
  const getDirectoryAccountInformation = createGetDirectoryAccountInformation(
    directoriesService
  )
  const saveGoogleIntegration = createSaveGoogleIntegration(directoriesService)
  const googleSyncUsers = createGoogleSyncUsers(directoriesService)
  const fetchGoogleAuditLogList = createFetchGoogleAuditLogList(
    directoriesService
  )
  const fetchSystemLogList = createFetchSystemLogList(directoriesService)
  const updateGoogleSyncOptions = createUpdateGoogleSyncOptions(
    directoriesService
  )
  const fetchGoogleUsers = createFetchGoogleUsers(directoriesService)
  const fetchGoogleGroups = createFetchGoogleGroups(directoriesService)
  const searchGoogleGroups = createSearchGoogleGroups(directoriesService)

  return function*() {
    yield takeEvery(
      DirectoriesActionTypes.GET_DIRECTORY_INTEGRATIONS,
      getDirectoryIntegrationsSaga
    )
    yield takeEvery(
      DirectoriesActionTypes.GET_DIRECTORY_INTEGRATIONS_PROVISION_TOKEN,
      getDirectoryProvisionTokenSaga
    )
    yield takeEvery(
      DirectoriesActionTypes.GET_DIRECTORY_INTEGRATIONS_SETTINGS,
      getDirectorySettingsSaga
    )
    yield takeEvery(
      DirectoriesActionTypes.RESET_AD_CONNECTOR_API_KEY,
      resetADAPIKeySaga
    )
    yield takeEvery(
      DirectoriesActionTypes.GET_AD_CONNECTOR_API_KEY_EXISTENCE,
      getADAPIKeyExistence
    )
    yield takeEvery(
      DirectoriesActionTypes.GET_AD_CONNECTOR_VERSION_LAST_SYNC,
      getADVersionLastSync
    )
    yield takeEvery(
      DirectoriesActionTypes.GET_DIRECTORY_ACCOUNT_INFORMATION,
      getDirectoryAccountInformation
    )
    yield takeLatest(
      DirectoriesActionTypes.SAVE_GOOGLE_INTEGRATION,
      saveGoogleIntegration
    )
    yield takeLatest(DirectoriesActionTypes.SYNC_GOOGLE_USERS, googleSyncUsers)
    yield takeLatest(
      DirectoriesActionTypes.FETCH_GOOGLE_AUDIT_LOG_LIST,
      fetchGoogleAuditLogList
    )
    yield takeLatest(
      DirectoriesActionTypes.FETCH_SYSTEM_LOG_LIST,
      fetchSystemLogList
    )
    yield takeLatest(
      DirectoriesActionTypes.UPDATE_GOOGLE_SYNC_OPTIONS,
      updateGoogleSyncOptions
    )

    yield takeLatest(
      DirectoriesActionTypes.FETCH_GOOGLE_USERS,
      fetchGoogleUsers
    )

    yield takeLatest(
      DirectoriesActionTypes.FETCH_GOOGLE_GROUPS,
      fetchGoogleGroups
    )

    yield takeLatest(
      DirectoriesActionTypes.SEARCH_GOOGLE_GROUPS,
      searchGoogleGroups
    )
  }
}

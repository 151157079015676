import React, { FunctionComponent, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { Drawer } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { PsaIntegrationsState } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { StyledDrawerOverlay } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/StyledDrawerOverlay'
import { ProductSelectionDrawerContent } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/products/components/ProductSelectionDrawerContent'

const HeaderContainer = styled.div`
  ${BodyRegularStyle}
  margin: -30px 0 24px 0;
`

export interface ProductSelectionDrawerProps {
  previousUrl: string
  title: ReactElement
}

export const ProductSelectionDrawer: FunctionComponent<ProductSelectionDrawerProps> = ({
  previousUrl,
  title
}) => {
  const history = useHistory()
  const { drawerInProgress } = useSelector<AppState, PsaIntegrationsState>(
    state => state.psaIntegrations
  )

  const handleOnClose = () => {
    history.push(previousUrl)
  }

  return (
    <>
      <StyledDrawerOverlay onClose={handleOnClose} />
      <Drawer
        mainTitle={title}
        closeLink={previousUrl}
        data-qa="ProductsDrawer"
      >
        <HeaderContainer>
          <Trans>
            Select the products from the PSA suite that correspond to LastPass.
          </Trans>
        </HeaderContainer>
        {drawerInProgress && (
          <Loading data-qa="Loading" color="blue900" active={true} />
        )}

        <ProductSelectionDrawerContent previousUrl={previousUrl} />
      </Drawer>
    </>
  )
}

import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components'
import {
  CardContainer,
  Pagination,
  RowContainer,
  SearchInput,
  Select,
  Table as TableComponent,
  TableView,
  Theme
} from '@lastpass/lastkit'

import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import {
  IntegrationLog,
  SystemLog
} from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'

interface AuditLogListProps<T> {
  columns: TableColumn<T>[]
  fetchLogsAction: (
    offset: number,
    pageSize: number,
    filters: { identity?: string; status: string }
  ) => void
  hasError: boolean
  isLoading: boolean
  logs: T[]
  numberOfItems: number
  searchDisabled?: boolean
}

const StyledRowContainer = styled(RowContainer)<{
  hidden: boolean
  justify: string
}>`
  align-items: 'space-between';
  justify-content: ${props => props.justify};
  visibility: ${props => props.hidden && 'hidden'};
`

const PaginationContainer = styled.div`
  margin-left: auto;
`

const FilterContainer = styled.div`
  width: 50%;
`

const DEFAULT_OFFSET = 0
const DEFAULT_PAGE_SIZE = 25

export const AuditLogList = <T extends IntegrationLog | SystemLog>({
  columns,
  fetchLogsAction,
  hasError,
  isLoading,
  logs,
  numberOfItems,
  searchDisabled = false
}: AuditLogListProps<T>) => {
  const location = useLocation()

  const statusOptions = [
    {
      value: 'success',
      label: i18n._(msg`Success`)
    },
    {
      value: 'failure',
      label: i18n._(msg`Failure`)
    }
  ]

  const [offset, setOffset] = useState(DEFAULT_OFFSET)
  const [searchExpression, setSearchExpression] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const dispatch = useDispatch()

  const tableContent: Table<T> = {
    results: logs,
    resultsPerPage: DEFAULT_PAGE_SIZE,
    totalResults: numberOfItems,
    checkedRecords: [],
    isLoading: isLoading
  }

  const handlePagination = (newOffset: number) => {
    dispatch(
      fetchLogsAction(newOffset, DEFAULT_PAGE_SIZE, {
        identity: searchExpression,
        status: statusFilter
      })
    )
    setOffset(newOffset)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    dispatch(
      fetchLogsAction(0, DEFAULT_PAGE_SIZE, {
        identity: value,
        status: statusFilter
      })
    )

    setOffset(0)
    setSearchExpression(value)
  }

  const handleStatusFilterChange = option => {
    const value = option === null ? '' : option.value

    dispatch(
      fetchLogsAction(0, DEFAULT_PAGE_SIZE, {
        identity: searchExpression,
        status: value
      })
    )

    setOffset(0)
    setStatusFilter(value)
  }

  const handleAuditLogChange = (log: T) => {
    dispatch(directoriesActions.setLogDetails(log))
  }

  useEffect(() => {
    setOffset(DEFAULT_OFFSET)
    setStatusFilter('')
    dispatch(
      fetchLogsAction(DEFAULT_OFFSET, DEFAULT_PAGE_SIZE, {
        identity: '',
        status: ''
      })
    )
  }, [dispatch, fetchLogsAction])

  return (
    <>
      <StyledRowContainer hidden={hasError} justify="end">
        {!searchDisabled && (
          <FilterContainer>
            <SearchInput
              data-qa="AuditLogIdentitySearch"
              placeholder={msg`Search identity`}
              onChange={handleSearch}
              flexGrow={true}
            />
          </FilterContainer>
        )}
        <FilterContainer>
          <Select
            classNamePrefix="list"
            dataQa="AuditLogStatusFilterSelect"
            fluid={true}
            options={statusOptions}
            onChange={handleStatusFilterChange}
            placeholder={i18n._(msg`Any state`)}
            isClearable={true}
            value={statusOptions.filter(
              option => option.value === statusFilter
            )}
          />
        </FilterContainer>
      </StyledRowContainer>
      <StyledRowContainer hidden={hasError} justify="end">
        <PaginationContainer>
          <Pagination
            compactMode
            skip={offset}
            resultsPerPage={DEFAULT_PAGE_SIZE}
            totalResults={numberOfItems}
            updateSkip={handlePagination}
          />
        </PaginationContainer>
      </StyledRowContainer>
      <CardContainer>
        <TableComponent
          table={tableContent}
          columns={columns}
          onRowClick={handleAuditLogChange}
          getRecordLink={() => location.pathname}
          conditionalRowColor={(record: T) =>
            record.status === 'failure' ? Theme.colors.red100 : undefined
          }
          noRecordsView={
            <TableView
              icon={<UserIcon />}
              title={
                hasError ? (
                  <Trans>Failed to load audit logs</Trans>
                ) : (
                  <Trans>There are no audit logs</Trans>
                )
              }
            />
          }
          loadingView={<Loading color="blue900" active={true} />}
          qadata="AuditLogListTable"
        />
      </CardContainer>
    </>
  )
}

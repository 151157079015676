import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import {
  Heading300,
  RoutedTabNavigationItem,
  TabNavigation
} from '@lastpass/lastkit'

import { getIdentityIFrameUrl } from '@lastpass/admin-console-dependencies/services'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { AdvancedMfaMessage } from '@lastpass/admin-console-dependencies/ui/global/AdvancedMfaMessage'
import {
  AdvancedPolicyPath,
  SimilarPoliciesList
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/SystemUpgradeDrawerResources'
import {
  EmptyPageAfterSystemUpgrade,
  EmptyPageTextBlock
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/EmptyPageAfterSystemUpgrade'

interface RouteInfo {
  url: string
  urls?: string[]
  tabHeader: React.ReactElement
  content: React.ReactElement
  qaData: string
  disabled?: boolean
}

const RowContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
`
const FlexRowContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`

export const PasswordlessPage: React.FunctionComponent<{
  isEmpty: boolean
}> = ({ isEmpty }) => {
  const match = useRouteMatch()
  const dispatch = useDispatch()

  const cidOverride = useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const systemUpgradeState = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  useEffect(() => {
    dispatch(systemUpgradeActions.fetchIsMigrated())
  }, [dispatch])

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  if (!match) {
    return null
  }

  const { path, url } = match
  let routes: RouteInfo[] = []
  if (!isNoPasswordDeprecated) {
    routes = [
      {
        url: '/accesspolicies',
        urls: ['', '/accesspolicies'],
        qaData: 'PasswordlessAccessPoliciesTab',
        tabHeader: <Trans>Access policies</Trans>,
        content: systemUpgradeState.isMigrated ? (
          <EmptyPageAfterSystemUpgrade
            data-qa="AccessPolicesEmptyPageAfterMigration"
            title={<Trans>Access policies have been retired</Trans>}
          >
            <EmptyPageTextBlock>
              <Trans>
                Access policies allowed/denied passwordless access to LastPass
                accounts in a number of ways. Use these policies to achieve
                similar goals:
              </Trans>
              <SimilarPoliciesList />
            </EmptyPageTextBlock>
          </EmptyPageAfterSystemUpgrade>
        ) : (
          <iframe
            css={css`
              width: 100%;
              height: 100%;
            `}
            src={getIdentityIFrameUrl(
              `${defaultConfig.identityIframeUrl}/Policies`,
              cidOverride
            )}
          />
        )
      },
      {
        url: '/ip',
        qaData: 'PasswordlessIPTab',
        tabHeader: <Trans>IP allow/deny</Trans>,
        content: systemUpgradeState.isMigrated ? (
          <EmptyPageAfterSystemUpgrade
            data-qa="IpEmptyPageAfterMigration"
            title={<Trans>IP allow/deny policy has been retired</Trans>}
          >
            <EmptyPageTextBlock>
              <Trans>
                IP allow/deny policies allowed/denied passwordless access to
                LastPass accounts from permitted/restricted IP addresses. Use
                these policies to achieve similar goals:
              </Trans>
            </EmptyPageTextBlock>
            <EmptyPageTextBlock>
              <Trans>There are some similar policies you can use:</Trans>
              <SimilarPoliciesList />
            </EmptyPageTextBlock>
          </EmptyPageAfterSystemUpgrade>
        ) : (
          <iframe
            css={css`
              width: 100%;
              height: 100%;
            `}
            src={getIdentityIFrameUrl(
              `${defaultConfig.identityIframeUrl}/Policies/Ips`,
              cidOverride
            )}
          />
        )
      },
      {
        url: '/geofences',
        qaData: 'PasswordlessGeofencesTab',
        tabHeader: <Trans>Geofences</Trans>,
        content: systemUpgradeState.isMigrated ? (
          <EmptyPageAfterSystemUpgrade
            data-qa="GeofenceEmptyPageAfterMigration"
            title={<Trans>Geofencing has moved</Trans>}
          >
            <EmptyPageTextBlock>
              <Trans>
                This policy allowed/denied passwordless access to LastPass
                accounts from permitted/restricted locations.
              </Trans>
            </EmptyPageTextBlock>
            <EmptyPageTextBlock>
              <Trans>
                To achieve similar goals after upgrade, use the location
                policies available here:
              </Trans>{' '}
              <AdvancedPolicyPath />
            </EmptyPageTextBlock>
          </EmptyPageAfterSystemUpgrade>
        ) : (
          <iframe
            css={css`
              width: 100%;
              height: 100%;
            `}
            src={getIdentityIFrameUrl(
              `${defaultConfig.identityIframeUrl}/Policies/Geolocations`,
              cidOverride
            )}
          />
        )
      },
      {
        url: '/authentication',
        qaData: 'PasswordlessAuthenticationTab',
        tabHeader: <Trans>Authentication {'&'} recovery</Trans>,
        content: systemUpgradeState.isMigrated ? (
          <EmptyPageAfterSystemUpgrade
            data-qa="AuthEmptyPageAfterMigration"
            title={<Trans>Authentication & recovery has been retired</Trans>}
          >
            <EmptyPageTextBlock>
              <Trans>
                This feature forced users to verify their identity with LastPass
                Authenticator instead of their master password when logging in
                to SSO apps, also known as Cloud Apps.
              </Trans>
            </EmptyPageTextBlock>
            <EmptyPageTextBlock>
              <Trans>
                To achieve similar goals after upgrade, enable the Allow
                passwordless login policy, as described in this article.
              </Trans>
            </EmptyPageTextBlock>
          </EmptyPageAfterSystemUpgrade>
        ) : (
          <iframe
            css={css`
              width: 100%;
              height: 100%;
            `}
            src={getIdentityIFrameUrl(
              `${defaultConfig.identityIframeUrl}/Policies/Authentication`,
              cidOverride
            )}
          />
        )
      }
    ]
  }

  const advancedMfaMessage = userHasMfaAddon ? (
    <AdvancedMfaMessage
      isEmpty={isEmpty}
      nonMspMessage={msg`Passwordless is available with the Advanced MFA add-on.`}
    />
  ) : null

  return (
    <>
      <FlexRowContainer>
        <Heading300>
          <Trans>Passwordless authentication</Trans>
        </Heading300>
        {advancedMfaMessage}
      </FlexRowContainer>
      <RowContainer>
        <TabNavigation>
          {routes
            .filter(route => !route.disabled)
            .map(routeInfo => (
              <RoutedTabNavigationItem
                data-qa={routeInfo.qaData}
                key={routeInfo.url}
                to={`${url}${routeInfo.url}`}
                route={{
                  path: `${url}${routeInfo.url}`,
                  exact: !routeInfo.url
                }}
              >
                {routeInfo.tabHeader}
              </RoutedTabNavigationItem>
            ))}
        </TabNavigation>
      </RowContainer>
      <Switch>
        {routes
          .filter(f => !f.disabled)
          .map(routeInfo => (
            <Route
              key={routeInfo.url}
              path={
                routeInfo.urls
                  ? routeInfo.urls.map(u => `${path}${u}`)
                  : `${path}${routeInfo.url}`
              }
              exact
            >
              {routeInfo.content}
            </Route>
          ))}
      </Switch>
    </>
  )
}

import { TeamsPolicyValueType } from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  GeneralPoliciesDrawerActions,
  GeneralPoliciesDrawerActionTypes
} from './actions'
import { GeneralPoliciesDrawerState } from './state'

const initialPolicy = {
  policyKey: '',
  policyData: [],
  category: '',
  policyTitle: '',
  policyShortDescription: '',
  policyDescription: '',
  policyValueType: TeamsPolicyValueType.checkbox,
  checked: false,
  switchable: false
}

export const initialState: GeneralPoliciesDrawerState = {
  policy: initialPolicy,
  unsavedPolicy: initialPolicy,
  loading: false,
  drawerNeedsRefresh: false,
  policyKey: '',
  isDrawerOpen: false,
  adminUsersTable: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true
  },
  unsavedPolicyData: []
}

export function generalPoliciesDrawerReducer(
  previousState: GeneralPoliciesDrawerState | undefined,
  action: GeneralPoliciesDrawerActions
): GeneralPoliciesDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case GeneralPoliciesDrawerActionTypes.RESET: {
      return {
        ...initialState,
        policy: state.policy,
        unsavedPolicy: state.unsavedPolicy,
        unsavedPolicyData: state.unsavedPolicyData,
        policyKey: state.policyKey
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_CURRENT_GENERAL_POLICY: {
      return {
        ...state,
        policy: action.payload.policy,
        policyKey: action.payload.policy.policyKey
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_CURRENT_POLICY_DATA: {
      const newPolicyData = state.policy.policyData || []
      newPolicyData.push(action.payload.policyData)
      return {
        ...state,
        policy: {
          ...state.policy,
          policyData: newPolicyData,
          checked: true
        },
        unsavedPolicy: {
          ...state.unsavedPolicy,
          policyData: newPolicyData,
          checked: true
        },
        unsavedPolicyData: newPolicyData
      }
    }
    case GeneralPoliciesDrawerActionTypes.UPDATE_CURRENT_POLICY_DATA: {
      const newPolicyData = [...state.policy.policyData]
      newPolicyData[action.payload.index] = action.payload.policyData
      return {
        ...state,
        policy: {
          ...state.policy,
          policyData: newPolicyData,
          checked: true
        },
        unsavedPolicy: {
          ...state.unsavedPolicy,
          policyData: newPolicyData,
          checked: true
        },
        unsavedPolicyData: newPolicyData
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_GENERAL_POLICY_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_CURRENT_GENERAL_POLICY_DATA_NEEDS_REFRESH: {
      return {
        ...state,
        drawerNeedsRefresh: action.payload.drawerNeedsRefresh,
        policyKey: action.payload.policyKey
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_CURRENT_GENERAL_POLICY_DRAWER_OPEN: {
      return {
        ...state,
        isDrawerOpen: action.payload.isDrawerOpen
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_ADMIN_LIST: {
      const selectedAdmins = action.payload.admins.filter(
        adminUser =>
          !!state.policy.policyData &&
          !!state.policy.policyData[0] &&
          !!state.policy.policyData[0].policyValue &&
          state.policy.policyData[0].policyValue.indexOf(adminUser.email) > -1
      )
      return {
        ...state,
        adminUsersTable: {
          ...state.adminUsersTable,
          results: action.payload.admins,
          isLoading: false,
          checkedRecords: selectedAdmins || []
        }
      }
    }
    case GeneralPoliciesDrawerActionTypes.ADD_SELECTED_ADMINS: {
      const table = state.adminUsersTable
      return {
        ...state,
        adminUsersTable: {
          ...table,
          checkedRecords: table.checkedRecords.concat(action.payload.admins)
        }
      }
    }
    case GeneralPoliciesDrawerActionTypes.REMOVE_SELECTED_ADMINS: {
      const table = state.adminUsersTable
      return {
        ...state,
        adminUsersTable: {
          ...table,
          checkedRecords: table.checkedRecords.filter(
            el => !action.payload.admins.includes(el)
          )
        }
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_SELECTED_ADMINS: {
      const table = state.adminUsersTable
      return {
        ...state,
        adminUsersTable: {
          ...table,
          checkedRecords: action.payload.admins
        }
      }
    }
    case GeneralPoliciesDrawerActionTypes.SET_UNSAVED_POLICY: {
      return {
        ...state,
        unsavedPolicy: action.payload.policy
      }
    }
  }
  return state
}

import React, { FunctionComponent, useEffect } from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as Icon1 } from '@lastpass/assets/svg/admin-console/applications/sso-upsell-dialog/description-icon-1.svg'
import { ReactComponent as Icon2 } from '@lastpass/assets/svg/admin-console/applications/sso-upsell-dialog/description-icon-2.svg'
import { ReactComponent as Icon3 } from '@lastpass/assets/svg/admin-console/applications/sso-upsell-dialog/description-icon-3.svg'
import { ReactComponent as Icon4 } from '@lastpass/assets/svg/admin-console/applications/sso-upsell-dialog/description-icon-4.svg'
import { ReactComponent as HeaderIllustration } from '@lastpass/assets/svg/admin-console/applications/sso-upsell-dialog/header-illustration.svg'
import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/admin-console/icon-close.svg'
import { PrimaryButton, TextButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { SsoUpsellDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { UpsellDialogCell } from './UpsellDialogCell'

interface SsoUpsellDialogProps {
  state: SsoUpsellDialog
  actions: typeof globalActions
}

const transparentBackgroundColor = props => `${props.theme.colors.neutral900}e0`
const Background = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: ${transparentBackgroundColor};
  justify-content: center;
`

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 828px;
  height: 672px;
  left: calc(50% - 828px / 2);
  top: calc(50% - 672px / 2 + 10px);
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(29, 48, 73, 0.16),
    0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08);
  border-radius: 16px;
`

const StyledTitle = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 390px;
  height: 28px;
  top: 200px;
  white-space: nowrap;
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #1d3049;
`
const StyledSubtitle = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 18px;
  height: 20px;
  top: 244px;
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #1d3049;
  white-space: nowrap;
`

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: 24px 0px;

  position: static;
  width: 223px;
  height: 80px;
  left: 302.5px;
  top: 560px;
`

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 828px;
  height: 176px;
  left: 0px;
  top: 0px;
  background: #e4e5f6;
  border-radius: 16px 16px 0px 0px;
`
const TitleDiv = styled.div`
  height: 115px;
  top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BorderedDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: static;
  width: 764px;
  height: 248px;
  left: 32px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #dcdfe4;
  box-sizing: border-box;
  border-radius: 12px;
`

const StyledHeaderIllustration = styled(HeaderIllustration)`
  position: static;
  width: 160px;
  height: 159.17px;
  left: 334px;
  top: 8.42px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0px;
`

const CloseIconContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.neutral600};

  &:hover {
    color: ${props => props.theme.colors.neutral900};
  }
`

const StyledTextButton = styled(TextButton)`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #41536b;
  margin-top: 10px;
`
const escapeKeyCode = 27

export const SsoUpsellDialogComponent: FunctionComponent<SsoUpsellDialogProps> = ({
  ...props
}) => {
  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === escapeKeyCode) {
        props.actions.discardDialogSegment(true, 'Purchase', 'Close')
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [props])
  return (
    <Background
      onClick={() =>
        props.actions.discardDialogSegment(true, 'Purchase', 'Close')
      }
    >
      <Popup onClick={e => e.stopPropagation()} data-qa={'PopUpContainer'}>
        <HeaderDiv>
          <StyledHeaderIllustration />
          <CloseIconContainer
            onClick={() =>
              props.actions.discardDialogSegment(true, 'Purchase', 'Close')
            }
            data-qa={'PopUpCloseButton'}
          >
            <StyledCloseIcon data-qa={'CloseIcon'} />
          </CloseIconContainer>
        </HeaderDiv>

        <TitleDiv data-qa={'TitleDiv'}>
          <StyledTitle>
            <Trans>Add the power and protection of Advanced SSO</Trans>
          </StyledTitle>
          <StyledSubtitle>
            <Trans>
              Employee access to unlimited cloud applications. Simplified.
            </Trans>
          </StyledSubtitle>
        </TitleDiv>

        <BorderedDiv data-qa={'DescriptionDiv'}>
          <Row>
            <UpsellDialogCell
              icon={Icon1}
              title={i18n._(msg`Increased employee productivity`)}
              subtitle={i18n._(
                msg`Streamline access to an unlimited number of cloud apps.`
              )}
            />
            <UpsellDialogCell
              icon={Icon2}
              title={i18n._(msg`Improved visibility`)}
              subtitle={i18n._(
                msg`Gain visibility into the apps employees are using to do their job.`
              )}
            />
          </Row>
          <Row>
            <UpsellDialogCell
              icon={Icon3}
              title={i18n._(msg`Fewer passwords to remember`)}
              subtitle={i18n._(
                msg`Free up employee mindspace for work and fun and cut down on password-related help desk calls.`
              )}
            />
            <UpsellDialogCell
              icon={Icon4}
              title={i18n._(msg`Consistent experience`)}
              subtitle={i18n._(
                msg`Deliver simplified access using the same app your employees trust to store their passwords.`
              )}
            />
          </Row>
        </BorderedDiv>

        <FooterButtonContainer data-qa={'PopUpButtons'}>
          <PrimaryButton
            data-qa={'ConfirmButton'}
            onClick={() => {
              props.actions.confirmDialogSegment(true, 'Purchase', 'Buy Now')
            }}
            key={'confirm'}
          >
            <Trans>Add Advanced SSO now</Trans>
          </PrimaryButton>
          <StyledTextButton
            data-qa={'MoreInfoButton'}
            onClick={() => {
              props.actions.moreInfoClicked()
            }}
            key={'learnMore'}
          >
            <Trans>Tell me more</Trans>
          </StyledTextButton>
        </FooterButtonContainer>
      </Popup>
    </Background>
  )
}

import { msg } from '@lingui/macro'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { SharedFolderDialogueType } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function createNewSharedFolderSaga() {
  return function* newSharedFolderSaga() {
    yield put(
      globalActions.setDialog({
        type: 'sharedfolderdialog',
        mainText: msg`Create shared folder`,
        secondaryText: msg`Go to your LastPass vault to create a shared folder.`,
        learnMoreText: msg`Learn more`,
        skipText: msg`Next time, take me there directly`,
        confirmText: msg`Go to vault`,
        shareType: SharedFolderDialogueType.create,
        lastpassQueryParams: 'cmd=sfadd'
      })
    )
  }
}

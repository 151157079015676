export enum UserListTableColumn {
  email,
  name,
  lastpassStatus,
  mfaStatus,
  defaultMultifactor,
  lastLogin,
  securityScore,
  lastPasswordChange,
  adminLevel,
  lastInviteEmail,
  provisionFederatedStatus,
  superAdminKeyOwnershipForUser,
  isCreatedByCompany
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { symantecVipSettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetSymantecVipSettings(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getSymantecVipSettings() {
    try {
      yield put(symantecVipSettingsActions.setSymantecVipSettingsLoading(true))
      try {
        const symantecVipSettingsResult: UACServices.SymantecVipSettingsGetAPI.Responses = yield call(
          enterpriseOptionServices.symantecVipSettingsGet
        )
        yield put(
          symantecVipSettingsActions.setSymantecVipSettings(
            symantecVipSettingsResult.body.symantecVipSettings
          )
        )
      } finally {
        yield put(
          symantecVipSettingsActions.setSymantecVipSettingsLoading(false)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import buildQuery from 'odata-query'
import { call, put, take } from 'redux-saga/effects'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import { buildFilterArray } from '@lastpass/admin-console-dependencies/sagas/users/view/helpers/build-filter-array'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'

import { AllUserSelectionResult } from './all-user-selection-result'

export const hasProvisionedUser = (userList: User[]): boolean =>
  userList.some(isProvisionedUser)

export const areAllUsersProvisioned = (userList: User[]): boolean =>
  userList.every(isProvisionedUser)

export const getManuallyAddedUsers = (userList: User[]): User[] =>
  userList.filter(user => !isProvisionedUser(user))

const isProvisionedUser = (user: User): boolean => {
  return (
    user.provisionFederatedStatus !== FederatedStatus.notEligibleForFederation
  )
}

export function* handleSelectionWithinPage(
  users: User[],
  allUserCount: number
) {
  const isProvisionedUserSelected = hasProvisionedUser(users)

  if (isProvisionedUserSelected) {
    yield put(
      globalActions.setDialog({
        type: 'manage-provisioned-user-confirmation-dialog',
        users,
        allUserCount,
        isAllSelected: false,
        areAllUsersProvisioned: areAllUsersProvisioned(users)
      })
    )

    const confirmManageProvisionedUsersClick = yield take(modalActions)

    if (
      confirmManageProvisionedUsersClick.type ===
      GlobalActionTypes.DISCARD_DIALOG
    ) {
      return
    }

    if (
      confirmManageProvisionedUsersClick.type ===
      GlobalActionTypes.MANAGE_PROVISIONED_USERS_CONTINUE_WITH_MANUALLY_ADDED_USERS_DIALOG
    ) {
      return getManuallyAddedUsers(users)
    }
  }

  return users
}

export function* handleAllUserSelection(
  users: User[],
  currentUsersFilter: UserFilter | undefined,
  allUserCount: number,
  userCountService
) {
  const result: AllUserSelectionResult = {
    shouldProceed: true,
    usersFilter: currentUsersFilter,
    selectedUsersCount: allUserCount
  }

  const userQueryParams = getUserQueryParams()
  const filters = buildFilterArray(
    setIsProvisionedFilter(true, userQueryParams.filter)
  )

  const queryString = buildQuery({
    ...userQueryParams,
    filter: filters ? { and: filters } : undefined
  })

  const provisionedUserCountResponse: UACServices.UserCountAPI.Responses = yield call(
    userCountService,
    queryString
  )

  if (
    provisionedUserCountResponse.type !== UACServices.UserCountAPI.SUCCESS ||
    provisionedUserCountResponse.body.count === 0
  ) {
    return result
  }

  yield put(
    globalActions.setDialog({
      type: 'manage-provisioned-user-confirmation-dialog',
      users,
      allUserCount,
      isAllSelected: true,
      areAllUsersProvisioned:
        provisionedUserCountResponse.body.count === allUserCount
    })
  )

  const confirmManageProvisionedUsersClick = yield take(modalActions)

  if (
    confirmManageProvisionedUsersClick.type === GlobalActionTypes.DISCARD_DIALOG
  ) {
    result.shouldProceed = false
    return result
  }

  if (
    confirmManageProvisionedUsersClick.type ===
    GlobalActionTypes.MANAGE_PROVISIONED_USERS_CONTINUE_WITH_MANUALLY_ADDED_USERS_DIALOG
  ) {
    result.usersFilter = setIsProvisionedFilter(false, currentUsersFilter)
    result.selectedUsersCount =
      allUserCount - provisionedUserCountResponse.body.count
    return result
  }

  return result
}

const getUserQueryParams = () => {
  const urlSearchParams = new URLSearchParams(location.search).get(
    Namespace.users
  )

  if (urlSearchParams === null) {
    return {}
  }

  return JSON.parse(Buffer.from(urlSearchParams, 'base64').toString())
}

const modalActions: GlobalActionTypes[] = [
  GlobalActionTypes.DISCARD_DIALOG,
  GlobalActionTypes.MANAGE_PROVISIONED_USERS_CONTINUE_WITH_ALL_USERS_DIALOG,
  GlobalActionTypes.MANAGE_PROVISIONED_USERS_CONTINUE_WITH_MANUALLY_ADDED_USERS_DIALOG
]

const setIsProvisionedFilter = (
  isProvisioned: boolean,
  originalFilter: UserFilter | undefined
): UserFilter => {
  if (originalFilter === undefined) {
    return {
      groupId: [],
      isProvisioned
    }
  }

  return { ...originalFilter, isProvisioned }
}

import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'

export const getMultifactorDrawerState = (state: AppState) =>
  state.multifactorDrawer

export function createGetAvailableUsersSaga(
  policyService: UACServices.Services
) {
  return function* getAvailableUsersSaga(
    action: ReturnType<typeof multifactorDrawerActions.getAvailableUsers>
  ) {
    const { searchKey } = action.payload
    yield put(multifactorDrawerActions.setAvailableUsersLoading(true))
    try {
      const searchUsersResult: UACServices.GeneralPolicyUsersSearchAPI.Responses = yield call(
        policyService.generalPolicySearchUsers,
        searchKey
      )
      if (
        searchUsersResult.type ===
        UACServices.GeneralPolicyUsersSearchAPI.SUCCESS
      ) {
        const multifactorDrawerState: MultifactorDrawerState = yield select(
          getMultifactorDrawerState
        )
        const searchUsersList = searchUsersResult.body.policyUsersList
        const filteredResultList = searchUsersList.filter(
          groupFromResult =>
            multifactorDrawerState.assignFormState.filteredUsers.results.filter(
              groupFromState => groupFromState.email == groupFromResult.email
            ).length === 0
        )
        yield put(
          multifactorDrawerActions.setAvailableUsers(filteredResultList)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(multifactorDrawerActions.setAvailableUsersLoading(false))
    }
  }
}

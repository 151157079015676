import React, { FC, ReactElement, useState } from 'react'

import styled from '@emotion/styled'
import { msg, Plural, Trans } from '@lingui/macro'

import {
  BodyBold,
  BodyRegularStyle,
  RadioButton,
  RadioButtonOption
} from '@lastpass/lastkit'
import { Chip } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { RemoveFromCompanyConfirmationWordDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { SharedFoldersAndSitesAccessType } from '@lastpass/admin-console-dependencies/types/shared-folders-and-sites-access'
import { ConfirmationWordDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/ConfirmationWordDialog'

interface RemoveFromCompanyConfirmationWordDialogProps {
  state: RemoveFromCompanyConfirmationWordDialogType
  actions: typeof globalActions
}

const ContentContainer = styled.div`
  ${BodyRegularStyle};
`

const Paragraph = styled.p`
  margin-bottom: 1rem;
`

const StyledListItem = styled.li`
  list-style: disc;
  margin-left: 20px;
`

const StyledList = styled.ul`
  margin-left: 8px;
`

const RadioButtonContainer = styled.div`
  display: grid;
`

const StyledChip = styled(Chip)`
  color: ${props => props.theme.colors.red700};
  background-color: ${props => props.theme.colors.red100};
  box-shadow: none;
  border: none;
  font-size: 12px;
  font-weight: 600;
`

const radioButtonOptions: RadioButtonOption[] = [
  {
    value: SharedFoldersAndSitesAccessType.allow,
    label: msg`Allow access to shared sites and folders`
  },
  {
    value: SharedFoldersAndSitesAccessType.forbid,
    label: msg`Forbid access to shared sites and folders`
  }
]

export const RemoveFromCompanyConfirmationWordDialog: FC<RemoveFromCompanyConfirmationWordDialogProps> = ({
  state
}) => {
  const [value, setValue] = useState<SharedFoldersAndSitesAccessType>(
    SharedFoldersAndSitesAccessType.forbid
  )
  const numberOfUsers = state.isAllSelected
    ? state.allUserCount
    : state.users.length

  const renderRadioButtons = (): ReactElement[] =>
    radioButtonOptions.map(option => {
      return (
        <RadioButton
          option={option}
          key={option.value}
          checked={value === option.value}
          onChange={() => {
            setValue(option.value as SharedFoldersAndSitesAccessType)
          }}
        />
      )
    })

  const Content = (
    <ContentContainer>
      <Paragraph>
        {numberOfUsers === 1 ? (
          <Trans>
            You’re about to remove this user from your LastPass organization
            without deleting their LastPass account. They’ll no longer be
            subject to your policies or settings:{' '}
            <BodyBold data-qa="RemoveFromCompanyConfirmationWordDialogUserEmail">
              {state.users[0].email}
            </BodyBold>
          </Trans>
        ) : (
          <Trans>
            You’re about to remove{' '}
            <BodyBold data-qa="RemoveFromCompanyConfirmationWordDialogUserCount">
              {numberOfUsers}
            </BodyBold>{' '}
            users from your LastPass organization without deleting their
            LastPass accounts. They’ll no longer be subject to your policies or
            settings.
          </Trans>
        )}
      </Paragraph>
      <BodyBold>
        <Trans>What can they access after removal?</Trans>
      </BodyBold>
      <StyledList>
        <StyledListItem>
          <Plural
            value={numberOfUsers}
            one="They retain access to their account and vault using their current credentials"
            other="They retain access to their accounts and vaults using their current credentials"
          />
        </StyledListItem>
        <StyledListItem>
          <Trans>
            You decide if they retain access to shared items and folders
          </Trans>
          {':'}
          <RadioButtonContainer>{renderRadioButtons()}</RadioButtonContainer>
        </StyledListItem>
      </StyledList>
    </ContentContainer>
  )

  const SecurityRiskChip = (
    <StyledChip dataQa="SecurityRiskChip">
      <Trans>Security risk</Trans>
    </StyledChip>
  )

  return (
    <ConfirmationWordDialog
      confirmationWord="REMOVE"
      title={msg`Remove from company?`}
      content={Content}
      confirmButtonText={
        numberOfUsers === 1 ? msg`Remove` : msg`Remove ${numberOfUsers} users`
      }
      onConfirmPayload={value}
      headerExtension={SecurityRiskChip}
    />
  )
}

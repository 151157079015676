import React, { FunctionComponent, useRef, useState } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { ReactComponent as IconCalendar } from '@lastpass/assets/svg/admin-console/icon-calendar.svg'
import { useOutsideClick } from '@lastpass/ui'

import 'react-day-picker/lib/style.css'
import { BodyRegularStyle } from '../styles'
import { DateRangeWithPresets } from './DateRangeWithPresets'
import { PrimaryButton } from './PrimaryButton'
import { TextButton } from './TextButton'

interface DateRangeDropdownProps {
  setRange?: (startDate: Date, endDate: Date) => void
  defaultStartDate?: Date
  defaultEndDate?: Date
  dataQa?: string
  isDirectionRight?: boolean
  minDate?: Date
}

const StyledButton = styled.button`
  ${BodyRegularStyle}
  width: 230px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  border-radius: ${props => props.theme.radius.pixel4};
  background: none;
  text-align: left;
  padding: 11px;
  margin-left: 24px;
  font-size: 14px;
  line-height: 16px;
`

const StyledIconCalendar = styled(IconCalendar)`
  float: right;
`

const StyledContainer = styled.div`
  position: relative;
`

const Container = styled.div<{ isDirectionRight: boolean | undefined }>`
  box-shadow: 0px 4px 8px rgba(29, 48, 73, 0.16),
    0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08);
  border-radius: ${props => props.theme.radius.pixel8};
  display: flex;
  width: fit-content;
  margin-top: 4px;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  min-width: 685px;
  margin-left: ${({ isDirectionRight }) => (isDirectionRight ? 16 : -431)}px;
  z-index: 5;
`

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin: 0 16px 16px 16px;
`

const today = moment().startOf('day')
const lastSevenDays = moment()
  .startOf('day')
  .subtract(7, 'days')

const getIntervalText = function(
  start: Date | undefined,
  end: Date | undefined
) {
  const days = moment(end).diff(moment(start), 'days')
  const customText = <Trans>Custom</Trans>
  const lastMonthText = <Trans>Last month</Trans>
  const lastTwoWeeksText = <Trans>Last 2 weeks</Trans>
  const lastSevenDaysText = <Trans>Last 7 days</Trans>

  if (
    !end ||
    moment(end).format('YYYY-MM-DD') !==
      moment(today.toDate()).format('YYYY-MM-DD')
  ) {
    return customText
  }

  switch (days) {
    case 30:
      return lastMonthText
    case 14:
      return lastTwoWeeksText
    case 7:
      return lastSevenDaysText
    default:
      return customText
  }
}

export const DateRangeDropdown: FunctionComponent<DateRangeDropdownProps> = props => {
  const [open, setOpen] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | undefined>(
    props.defaultStartDate || lastSevenDays.toDate()
  )
  const [endDate, setEndDate] = useState<Date | undefined>(
    props.defaultEndDate || today.toDate()
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const onOutsideClick = () => {
    setOpen(false)
  }

  useOutsideClick(containerRef, onOutsideClick, true)

  const setCustomStartDate = (date?: Date) => {
    setStartDate(date)
  }

  const setCustomEndDate = (date?: Date) => {
    setEndDate(date)
  }

  const intervalText = getIntervalText(startDate, endDate)

  const buttons = (
    <ButtonContainer>
      <TextButton
        css={css`
          margin-right: 16px;
        `}
        onClick={() => {
          setOpen(false)
        }}
      >
        <Trans>Cancel</Trans>
      </TextButton>
      <PrimaryButton
        onClick={() => {
          if (props.setRange) {
            props.setRange(
              startDate ? startDate : lastSevenDays.toDate(),
              endDate ? endDate : today.toDate()
            )
          }
          setOpen(false)
        }}
      >
        <Trans>Apply</Trans>
      </PrimaryButton>
    </ButtonContainer>
  )

  return (
    <>
      <StyledContainer ref={containerRef} data-qa={props.dataQa}>
        <StyledButton onClick={() => setOpen(!open)}>
          {intervalText} <StyledIconCalendar />
        </StyledButton>
        {open && (
          <Container isDirectionRight={props.isDirectionRight}>
            <DateRangeWithPresets
              buttons={buttons}
              setStartDate={setCustomStartDate}
              setEndDate={setCustomEndDate}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              minDate={props.minDate}
            />
          </Container>
        )}
      </StyledContainer>
    </>
  )
}

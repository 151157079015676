import {
  LMIAPI,
  LMIAPISegmentRequest,
  PostSegmentInSafariRequest
} from '../lmiapi'

export const createCustomBatchedTrackForSafariExtension = (
  lmiapi: LMIAPI | undefined,
  waitTime: number,
  maxEventsBeforeSendTrackInitiated: number
) => {
  let timer: NodeJS.Timeout | null

  const collectedTrackingEvents: PostSegmentInSafariRequest = {
    batch: []
  }

  const sendTrack = (lmiapiInstance: LMIAPI | undefined) => {
    const eventsNumber = collectedTrackingEvents.batch.length
    !!lmiapiInstance &&
      lmiapiInstance.segment
        .postSegmentInSafari(collectedTrackingEvents)
        .then(() => {
          collectedTrackingEvents.batch = collectedTrackingEvents.batch.splice(
            eventsNumber
          )
        })
        .catch(() => {
          // stopping the error propagation here and let it fail silently
          // collectedTrackingEvents data remains as it is, and at the next call will be sent
          // breaking other stuff and losing tracking data is prevented with the empty catch
        })
    !!timer && clearTimeout(timer)
    timer = null
  }

  const addEventToBatch = (traits: Partial<LMIAPISegmentRequest>) =>
    collectedTrackingEvents.batch.push({ ...traits, type: 'track' })

  return (event: Partial<LMIAPISegmentRequest>) => {
    addEventToBatch(event)

    if (
      timer &&
      collectedTrackingEvents.batch.length < maxEventsBeforeSendTrackInitiated
    ) {
      return
    }

    if (
      !timer &&
      collectedTrackingEvents.batch.length < maxEventsBeforeSendTrackInitiated
    ) {
      timer = setTimeout(() => sendTrack(lmiapi), waitTime)
      return
    }

    sendTrack(lmiapi)
  }
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular } from '@lastpass/lastkit'

export const PhoneBodyRegular = styled(BodyRegular)`
  display: block;
  margin-top: 8px;
`

export const EnglishSupportPhone: FunctionComponent = () => (
  <>
    <PhoneBodyRegular>
      <Trans>North America: 1-866-890-6809</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>Australia: +61 1800231820</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>United Kingdom: +44 (0) 8000698197</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>Ireland: 1800903156</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>Netherlands: +31 (0) 800 023 8315</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>International: +1 857-314-1324 (standard rates may apply)</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>English support is available 24/7.</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>Toll-free within the local country. </Trans>
    </PhoneBodyRegular>
  </>
)

export const GermanSupportPhone: FunctionComponent = () => (
  <>
    <PhoneBodyRegular>
      <Trans>Germany: +49 (0) 800 189 9317</Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>
        Support in German is available Monday through Friday, 8:00 AM to 5:00 PM
        (GMT+1).
      </Trans>
    </PhoneBodyRegular>
    <PhoneBodyRegular>
      <Trans>Toll-free within the local country.</Trans>
    </PhoneBodyRegular>
  </>
)

import React, { ReactFragment } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegularStyle, Collapsible, Heading100 } from '@lastpass/lastkit'
import { CodeExample } from '@lastpass/lastkit/components/CodeExample'

import { EnterpriseAPIExampleModel } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/state'

export interface ApiReferenceProps {
  examples: EnterpriseAPIExampleModel[]
}

const Title = styled.div`
  ${Heading100};
  margin-bottom: 8px;
  margin-top: 20px;
`

const TextContent = styled.div`
  ${BodyRegularStyle}
`

const Bold = styled.div`
  font-weight: ${props => props.theme.fonts.weight.semibold};
`

function renderParams(params: Record<string, string | Record<string, string>>) {
  const paramElements: ReactFragment[] = []
  for (const index in params) {
    if (typeof params[index] === 'string') {
      paramElements.push(
        <div key={index}>
          <Bold>{index}</Bold>
          {params[index]}
        </div>
      )
    } else {
      const paramValueElements: ReactFragment[] = []
      for (const index2 in params[index] as Record<string, string>) {
        paramValueElements.push(
          <div key={index2}>
            {index2}
            {'-'}
            {params[index][index2]}
          </div>
        )
      }
      paramElements.push(
        <div key={index}>
          <Bold>{index}</Bold>
          {paramValueElements}
        </div>
      )
    }
  }

  return paramElements
}

export const ApiReference: React.FunctionComponent<ApiReferenceProps> = props => {
  return (
    <>
      {props.examples.map(example => (
        <div key={example.title}>
          <Title>
            <Trans>
              JSON - {example.title} (cmd = {example.cmd})
            </Trans>
          </Title>
          <TextContent>
            <Trans>{example.desc}</Trans>
          </TextContent>
          <TextContent>{renderParams(example.params)}</TextContent>
          <Collapsible title={<Trans>Request sample</Trans>}>
            <CodeExample code={example.reqSample} allowCopyToClipboard={true} />
          </Collapsible>
          <Collapsible title={<Trans>Response sample</Trans>}>
            <CodeExample
              code={example.respSample}
              allowCopyToClipboard={true}
            />
          </Collapsible>
        </div>
      ))}
    </>
  )
}

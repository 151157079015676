import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'

export function createConfigureAdvancedPolicySaga() {
  return function* configureAdvancedPolicySaga(
    action: ReturnType<typeof multifactorActions.configureAdvancedPolicy>
  ) {
    const { dataIndex, policy } = action.payload
    const dataModel = policy.policyData[dataIndex || 0]

    yield put(
      advancedPolicyDrawerActions.setPolicy(
        policy,
        dataIndex || 0,
        dataModel.policyNotes,
        dataModel.policyValue
      )
    )

    if (policy.policyKey === 'lpauthenticatorgeoloc') {
      yield put(
        advancedPolicyDrawerActions.nextStep(AdvancedPolicyDrawerSteps.map)
      )
    } else {
      yield put(
        advancedPolicyDrawerActions.nextStep(
          AdvancedPolicyDrawerSteps.configPolicy
        )
      )
    }

    yield put(push('/policies/multifactor/add-advanced'))
  }
}

import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import { AdvancedUserActivityReport } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUserEmail = (state: AppState) =>
  state.global.currentUser.email

export const getAdvancedUserActivityEventsState = (state: AppState) =>
  state.advancedUserActivityEvents

export function createAdvancedUserActivityUpdateReport(
  userActivityServices: UACServices.Services
) {
  return function* updateAdvancedUserActivityReport(
    action: ReturnType<typeof advancedUserActivityActions.updateReport>
  ) {
    yield put(advancedUserActivityActions.setDrawerLoading(true))

    try {
      const currentUserEmail: string = yield select(getCurrentUserEmail)
      const eventState: AdvancedUserActivityListState = yield select(
        getAdvancedUserActivityEventsState
      )

      const { selectedSavedReport, savedReports } = eventState

      if (!selectedSavedReport) {
        return
      }

      const updatedSelectedSavedReport: AdvancedUserActivityReport = {
        name: selectedSavedReport.name,
        adminEmail: selectedSavedReport.adminEmail || currentUserEmail,
        filter: action.payload.filterForUpdate
      }
      const updatedSavedReports: AdvancedUserActivityReport[] = savedReports.map(
        report =>
          report.name === selectedSavedReport.name
            ? updatedSelectedSavedReport
            : report
      )

      yield call(
        userActivityServices.advancedUserActivityUpdateReport,
        updatedSelectedSavedReport
      )

      yield put(
        advancedUserActivityActions.setSelectedSavedReport(
          updatedSelectedSavedReport
        )
      )

      yield put(
        advancedUserActivityActions.setSavedReports(updatedSavedReports)
      )

      yield put(
        globalActions.setNotification({
          message: msg`Report saved.`,
          type: NotificationType.success,
          autoDismiss: true
        })
      )
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(advancedUserActivityActions.setDrawerLoading(false))
    }
  }
}

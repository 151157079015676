import React from 'react'
import ReactDOM from 'react-dom'

import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'

import { DialogContext } from './dialogs/DialogContext'
import { GlobalDialogMapping } from './dialogs/DialogMapping'

type GlobalDetailsDialogProps = LastPassComponentProps<
  GlobalState['dialog'],
  typeof globalActions,
  {}
>

export const GlobalDialogComponent: React.FunctionComponent<GlobalDetailsDialogProps> = ({
  state,
  actions
}) => {
  const modalContainer = document.getElementById('modal-container')
  if (modalContainer) {
    return ReactDOM.createPortal(
      <DialogContext.Provider
        value={{
          dialogState: state,
          dialogActions: actions
        }}
      >
        {GlobalDialogMapping[state.type]}
      </DialogContext.Provider>,
      modalContainer
    )
  }
  return null
}

export function createGlobalDialog<TState extends { global: GlobalState }>() {
  return connectRedux(
    GlobalDialogComponent,
    (state: TState) => state.global.dialog,
    globalActions
  )
}

export type GlobalDialog = ReturnType<typeof createGlobalDialog>

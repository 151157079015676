import punycode from 'punycode/'

export const truncateUrlToHostAndPathname = (urlToTruncate: string): string => {
  try {
    const url = new URL(urlToTruncate)

    const punycodePart = 'xn--'
    if (url.hostname.includes(punycodePart)) {
      const hostnameInUnicode = punycode.toUnicode(url.hostname)
      return hostnameInUnicode + url.pathname
    }

    return url.hostname + url.pathname
  } catch {
    return urlToTruncate
  }
}

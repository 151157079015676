import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState
} from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { BodySemiboldStyle, Select } from '@lastpass/lastkit'

import {
  AssignmentStatus,
  AvailabilityStatus,
  PsaCompany,
  PsaCompanyContract,
  PsaCompanyContractAssignment
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { AgreementSelectorMessage } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/common/AgreementSelectorMessage'

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  [data-qa='TableOneCell']:has(&) {
    padding: 4px 0;
  }

  [data-qa='TableRow']:has(&) {
    padding: 4px 16px 4px 0;
  }
`

const StyledLabel = styled.div`
  ${BodySemiboldStyle}
  color:${props => props.theme.colors.neutral600};
`

export type SelectOptionType = {
  label: MessageDescriptor | string
  value: string
  status: AssignmentStatus
  availability: AvailabilityStatus
}

const notSelectedOption: SelectOptionType = {
  label: msg`Not selected`,
  value: '',
  status: AssignmentStatus.NOT_ASSIGNED,
  availability: AvailabilityStatus.AVAILABLE
}

const excludeOption: SelectOptionType = {
  label: msg`Exclude from billing updates`,
  value: '-',
  status: AssignmentStatus.EXCLUDED,
  availability: AvailabilityStatus.AVAILABLE
}

export interface AgreementSelectorProp {
  actualSelectionStatus: AssignmentStatus
  actuallySelectedId: string | null
  company: PsaCompany
  contracts: PsaCompanyContract[]
  selectionMessage?: MessageDescriptor
  labelText?: ReactElement
  isMessagePositionedRight?: boolean
  onSelect: (
    assignment: PsaCompanyContractAssignment,
    availability: AvailabilityStatus
  ) => void
}

export const AgreementSelector: FunctionComponent<AgreementSelectorProp> = ({
  actualSelectionStatus,
  actuallySelectedId,
  company,
  contracts,
  labelText,
  selectionMessage,
  isMessagePositionedRight = false,
  onSelect
}) => {
  const { _ } = useLingui()
  const { id: companyId } = company
  const [selectedOption, setSelectedOption] = useState<SelectOptionType>()

  const selectOptions = useMemo(() => {
    const newOptions = contracts.map(({ id, name, availability }) => {
      return {
        value: id,
        label: name || '',
        status: AssignmentStatus.ASSIGNED,
        availability
      }
    })

    return [...newOptions]
  }, [contracts])

  useEffect(() => {
    const selectAssignedOption = () => {
      if (actuallySelectedId) {
        const assignedOption = selectOptions.find(
          ({ value }) => value === actuallySelectedId
        )

        if (assignedOption) {
          setSelectedOption(assignedOption)
        } else {
          setSelectedOption(notSelectedOption)
        }
      }
    }

    switch (actualSelectionStatus) {
      case AssignmentStatus.NOT_ASSIGNED:
        setSelectedOption(notSelectedOption)
        break
      case AssignmentStatus.EXCLUDED:
        setSelectedOption(excludeOption)
        break
      case AssignmentStatus.ASSIGNED:
        selectAssignedOption()
        break
    }
  }, [actuallySelectedId, actualSelectionStatus, selectOptions])

  const handleOnChange = (selectedOption: SelectOptionType): void => {
    const option = handleOption(selectedOption)

    const contractId =
      option.status === AssignmentStatus.ASSIGNED ? option.value : null
    const contractName =
      option.status === AssignmentStatus.ASSIGNED ? option.label : null

    setSelectedOption(option)
    onSelect(
      {
        companyId,
        contractId,
        contractName,
        assignmentStatus: option.status
      },
      option.availability
    )
  }

  const handleOption = (option: SelectOptionType) => ({
    ...option,
    label: typeof option.label === 'string' ? option.label : _(option.label)
  })

  return (
    <SelectContainer>
      {!isMessagePositionedRight && (
        <AgreementSelectorMessage message={selectionMessage} />
      )}

      <div>
        {labelText && <StyledLabel>{labelText}</StyledLabel>}

        <Select
          dataQa="AgreementSelector"
          fluid
          width="250px"
          onChange={option => handleOnChange(option as SelectOptionType)}
          placeholder={<Trans>Select an agreement</Trans>}
          options={[
            {
              label: '',
              options: [notSelectedOption, excludeOption].map(handleOption)
            },
            {
              label:
                selectOptions.length > 1 ? (
                  <Trans>Agreements</Trans>
                ) : (
                  <Trans>Agreement</Trans>
                ),
              options: selectOptions
            }
          ]}
          value={selectedOption ? handleOption(selectedOption) : null}
        />
      </div>

      {isMessagePositionedRight && (
        <AgreementSelectorMessage message={selectionMessage} />
      )}
    </SelectContainer>
  )
}

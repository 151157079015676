import { AddNewGroupActions, AddNewGroupActionTypes } from './actions'
import { NewGroupDrawerState } from './state'

export const initialState: NewGroupDrawerState = {
  newGroupForm: {
    id: '',
    validitySuccess: false,
    validityFail: false,
    isLoading: false
  }
}

export function newGroupDrawerReducer(
  previousState: NewGroupDrawerState | undefined,
  action: AddNewGroupActions
): NewGroupDrawerState {
  const state = previousState || initialState
  const newGroupFormState = state.newGroupForm
  switch (action.type) {
    case AddNewGroupActionTypes.RESET: {
      return initialState
    }
    case AddNewGroupActionTypes.SET_NEW_GROUP_ID: {
      return {
        ...state,
        newGroupForm: {
          ...newGroupFormState,
          id: action.payload.id
        }
      }
    }
    case AddNewGroupActionTypes.SET_NEW_GROUP_ISLOADING: {
      return {
        ...state,
        newGroupForm: {
          ...newGroupFormState,
          isLoading: action.payload.isLoading
        }
      }
    }
    case AddNewGroupActionTypes.SET_NEW_GROUP_VALIDITY_SUCCESS: {
      return {
        ...state,
        newGroupForm: {
          ...newGroupFormState,
          validitySuccess: action.payload.validitySuccess
        }
      }
    }
    case AddNewGroupActionTypes.SET_NEW_GROUP_VALIDITY_FAIL: {
      return {
        ...state,
        newGroupForm: {
          ...newGroupFormState,
          validityFail: action.payload.validityFail
        }
      }
    }
  }
  return state
}

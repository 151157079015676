import React, { useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { RouteFade } from '@lastpass/routing/animations'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { GroupPoliciesSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/groups/drawer/GroupPoliciesSummaryCard'
import { GroupUsersSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/groups/drawer/GroupUsersSummaryCard'
import { GroupDrawerPoliciesTable } from '@lastpass/admin-console-dependencies/ui/users/groups/GroupDrawerPoliciesTable'
import { GroupDrawerUsersTable } from '@lastpass/admin-console-dependencies/ui/users/groups/GroupDrawerUsersTable'
import { UserDrawerHome } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerHome'

enum expansionCards {
  users = 'users',
  policies = 'policies'
}

export function GroupDetailsDrawerPage() {
  const match = useRouteMatch()
  const permissions = useContext(PermissionContext)
  const canViewPolicies = permissions.requirePermission(
    AdminPermissions.policiesModify
  )

  const locationSearch = useSelector((state: AppState) => {
    return state.router.location.search
  })

  const expand = {
    [expansionCards.users]: true,
    [expansionCards.policies]: false
  }

  const expandRef = useRef(expand)
  const onExpand = (expandProp: expansionCards) => () => {
    expandRef.current[expandProp] = !expandRef.current[expandProp]
  }

  if (match) {
    const { url, path } = match
    return (
      <>
        <Route path={path} exact>
          <RouteFade>
            <UserDrawerHome>
              <GroupUsersSummaryCard
                detailLink={`${url}/users`}
                usersLink={'/users/list'}
                assignUsersLink={`${url}/add${locationSearch}`}
                expand={expandRef.current[expansionCards.users]}
                onExpand={onExpand(expansionCards.users)}
              />
              {canViewPolicies && (
                <GroupPoliciesSummaryCard
                  detailLink={`${url}/policies`}
                  policiesLink={'/policies/generalpolicies'}
                  expand={expandRef.current[expansionCards.policies]}
                  onExpand={onExpand(expansionCards.policies)}
                />
              )}
            </UserDrawerHome>
          </RouteFade>
        </Route>
        <Route path={`${path}/users`}>
          <GroupDrawerUsersTable />
        </Route>
        <Route path={`${path}/policies`}>
          <GroupDrawerPoliciesTable />
        </Route>
      </>
    )
  }
  return null
}

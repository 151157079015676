import React, { useEffect } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { Loading } from '@lastpass/components'
import {
  Chart,
  ChartItem,
  DateRangeDropdown,
  EmptyChart,
  WithTooltip
} from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import {
  DashboardState,
  UserActivityHistoryItem
} from '@lastpass/admin-console-dependencies/state/dashboard/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { ChartTop, UpdatedComponent } from './UserAdoptionHistoryChart'

type UserActivityHistoryChartProps = LastPassComponentProps<
  DashboardState,
  typeof dashboardActions
>

const RowSpacer = styled.div<{ isUtilizationDashboardEnabled: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.isUtilizationDashboardEnabled ? 'flex-end' : 'space-between'};
`

const Container = styled.div`
  display: block;
  width: 100%;
`

const Header = styled.h6`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
`

const formatNumber = (num: number) =>
  (num || 0).toLocaleString(undefined, { maximumFractionDigits: 1 })

export const getTooltipText = (item: UserActivityHistoryItem) => {
  const activeUserRate = formatNumber(item.activeUserRate)
  const activeUserCount = formatNumber(item.activeUserCount)
  return i18n._(msg`${activeUserRate}% (${activeUserCount}) Active users`)
}

export const UserActivityHistoryChartComponent: React.FunctionComponent<UserActivityHistoryChartProps> = ({
  state,
  actions
}) => {
  const activityData = state.userActivityHistory
  const showChart = state.dashboardDisplaySettings.hasUsersWithActivity
  const emptyChartUrl =
    'https://support.logmeininc.com/lastpass/help/get-started-as-a-lastpass-enterprise-user-lp010051'
  const isUtilizationDashboardEnabled = useFeatureFlags(
    FeatureFlags.isUtilizationDashboardEnabled
  )
  useEffect(() => {
    showChart &&
      actions.getUserActivityHistory(
        activityData.startDate || null,
        activityData.endDate || null
      )
  }, [actions, activityData.endDate, activityData.startDate, showChart])

  const chartItems: ChartItem[] = activityData.items.map(x => ({
    date: x.date,
    value: x.activeUserRate,
    tooltipText: getTooltipText(x)
  }))

  const dateRangeCutOffDate = new Date('2021-03-16')

  return (
    <Container data-qa={'UserActivityHistoryChart'}>
      <RowSpacer isUtilizationDashboardEnabled={isUtilizationDashboardEnabled}>
        {!isUtilizationDashboardEnabled && (
          <Header
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Trans>Utilization rate</Trans>
            <WithTooltip
              tooltip={
                <Trans>
                  How many users have used LastPass in the last 30 days? Updated
                  daily.{' '}
                  <a
                    href="https://support.logmeininc.com/lastpass/help/what-are-the-enrollment-and-utilization-rates"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </Trans>
              }
            >
              <StyledHelpIcon />
            </WithTooltip>
          </Header>
        )}
        {showChart && (
          <ChartTop>
            <UpdatedComponent
              updateTime={state.userActivityHistory.lastUpdateDateTimeUtc}
            />
            <DateRangeDropdown
              defaultStartDate={activityData.startDate}
              defaultEndDate={activityData.endDate}
              setRange={(startDate, endDate) => {
                actions.setUserActivityHistoryPeriod(startDate, endDate)
              }}
              minDate={dateRangeCutOffDate}
            />
          </ChartTop>
        )}
      </RowSpacer>

      {activityData.loading && showChart && (
        <Loading color={'blue900'} active={true} />
      )}
      {showChart ? (
        <Chart
          items={chartItems}
          tooltipText={'{tooltipText}'}
          height={'300px'}
          minValue={0}
          maxValue={100}
          minDateValue={activityData.startDate}
        />
      ) : (
        <EmptyChart
          title={<Trans>Track user activity</Trans>}
          subtitle={
            <Trans>Visualize utilization based on vault and SSO logins.</Trans>
          }
          button={{
            text: <Trans>Get folks to use LastPass</Trans>,
            onClick: () => {
              window.open(emptyChartUrl, '_blank')
            }
          }}
        />
      )}
    </Container>
  )
}

export function createUserActivityChartComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    UserActivityHistoryChartComponent,
    (state: TState) => state.dashboard,
    dashboardActions
  )
}

export type UserActivityHistoryChart = ReturnType<
  typeof createUserActivityChartComponent
>

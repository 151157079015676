import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { DashboardDisplaySettingsState } from '@lastpass/admin-console-dependencies/state/dashboard/state'

export const SUCCESS = 'success'

const success = (data: DashboardDisplaySettingsState) =>
  createResponse(SUCCESS, data)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('dashboard/state', 'GET')
    if (status === StatusCodes.OK && response) {
      const parsedResponse = JSON.parse(response).content
      const data: DashboardDisplaySettingsState = {
        hasEnrolledUsers: !parsedResponse.enrolledRate.showEmptyState,
        hasUsersWithActivity: !parsedResponse.utilizationRate.showEmptyState
      }
      return success(data)
    }

    throw new UnknownServiceError()
  }
}

import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as PoliciesIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import {
  CardTable,
  Collapsible,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { GetPolicyName } from '@lastpass/admin-console-dependencies/services/get-policy-name'
import { userDetailsActions } from '@lastpass/admin-console-dependencies/state/users/details/actions'
import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { Policy } from '@lastpass/admin-console-dependencies/types/policy'

export type UserPoliciesCardProps = LastPassComponentProps<
  UsersDrawerState['policies'],
  typeof userDrawerActions & typeof userDetailsActions,
  {
    detailLink: string
    policyLink: string
    expand: boolean
    onExpand: Function
  }
>

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const UserPoliciesSummaryCardComponent: React.FunctionComponent<UserPoliciesCardProps> = ({
  state: policies,
  props,
  actions
}) => {
  const pathParams: UserDrawerUrlParams = usePathParams()
  const policiesToShow = { ...policies, results: policies.results.slice(0, 5) }

  return (
    <Collapsible
      title={<Trans>Policies</Trans>}
      expanded={props.expand}
      onInitialExpand={() => {
        actions.getUserPolicies({ path: pathParams, query: {} }, 5)
      }}
      loading={policies.isLoading}
      onExpand={props.onExpand}
      qadata={'PoliciesCard'}
    >
      {policies && (
        <CardTable
          qadata={'UserLevelPoliciesList'}
          table={policiesToShow}
          columns={[
            {
              name: <Trans>Policy</Trans>,
              renderer: function showPolicyName(record: Policy) {
                return record.needsToBeTranslated
                  ? GetPolicyName(record.name)
                  : record.name
              }
            }
          ]}
          footerNodes={
            <>
              <TextButton blue onClick={actions.goToPoliciesPage}>
                <StyledLink
                  to={props.policyLink}
                  data-qa={'GoToPolicesPageButton'}
                >
                  <Trans>Go to Policies page</Trans>
                </StyledLink>
              </TextButton>
              <TextButton blue>
                <StyledLocationLink
                  to={props.detailLink}
                  data-qa={'ViewAllPoliciesButton'}
                >
                  <Trans>
                    View all policies: {policies && policies.totalResults}
                  </Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<PoliciesIcon />}
              title={
                <Trans>You haven’t assigned any policies to this user</Trans>
              }
              text={
                <Trans>Assign policies to control how they use LastPass</Trans>
              }
              actionButton={
                <TableViewButton onClick={actions.goToPoliciesPage}>
                  <StyledLink
                    to={props.policyLink}
                    data-qa={'GoToPolicesPageButton'}
                  >
                    <Trans>Go to Policies page</Trans>
                  </StyledLink>
                </TableViewButton>
              }
            />
          }
        />
      )}
    </Collapsible>
  )
}

export function createUserPoliciesSummaryCard<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserPoliciesSummaryCardComponent,
    (state: TState) => state.usersDrawer.policies,
    { ...userDrawerActions, ...userDetailsActions }
  )
}

export type UserPoliciesSummaryCard = ReturnType<
  typeof createUserPoliciesSummaryCard
>

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { saveAs } from 'file-saver'
import moment from 'moment'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  CaptionBold,
  CaptionRegularStyle,
  DateRangeDropdown,
  Heading300,
  NextPagination,
  PrimaryButton,
  SearchInput,
  Select,
  Table as TableComponent,
  TableView,
  WithTooltip
} from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import {
  adminActivityReportTranslationMap,
  userActivityReportTranslationMap
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-report-translations'
import {
  AdminActivityTypes,
  UserActivityTypes
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-types'
import { activityEventsResponseCacher } from '@lastpass/admin-console-dependencies/server/reports/activity-events-response-cacher'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { ObjectToCsv } from '@lastpass/admin-console-dependencies/services/object-to-csv'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  ActivityEvent,
  ActivityEventQueryParams,
  ActivityEventRequestParams,
  ActivityEventType,
  ActivityEventUserType
} from '@lastpass/admin-console-dependencies/types/activity-event'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const RowSpacer = styled.div`
  display: flex;
  justify-content: space-between;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 24px;
  margin-bottom: 24px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const StyledNextPagination = styled(NextPagination)`
  align-self: flex-end;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
`

const StyledHeader = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
`

const TooltipStyle = styled.div`
  ${CaptionRegularStyle};
  line-height: 16px;
  width: 100%;
  text-align: left;

  & ul {
    list-style-type: disc;

    & li {
      margin-left: 16px;
    }
  }
`
export interface ActivityListProps {
  type: ActivityEventUserType
  events: ActivityEvent[]
  onEventsChange: (events: ActivityEvent[]) => void
}

const resultsPerPage = 50
export const ActivityList: React.FunctionComponent<ActivityListProps> = ({
  type,
  events,
  onEventsChange
}) => {
  const isUserType = type === ActivityEventUserType.user
  const queryNamespace = isUserType
    ? Namespace.userReports
    : Namespace.adminReports

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isNextPageAvailable, setIsNextPageAvailable] = useState<boolean>(false)
  const [lastCheckedDateTimeUtc, setLastCheckedDateTimeUtc] = useState<string>(
    ''
  )
  const [eventTypes, setEventTypes] = useState<ActivityEventType[]>([])
  const queryParams: Partial<ActivityEventQueryParams> = useQueryParams(
    queryNamespace
  )
  const match = useRouteMatch()
  const matchUrl = match && match.url
  const dispatch = useDispatch()
  const updateQuery = useUpdateQuery(queryNamespace)
  const {
    getUserActivityEventTypes,
    getAdminActivityEventTypes,
    getUserActivityEvents,
    getAdminActivityEvents,
    exportUserActivityEvents,
    exportAdminActivityEvents
  } = useContext(ServerContext)

  const eventCacher = useMemo(() => {
    return activityEventsResponseCacher()
  }, [])

  const updateQueryWithPageReset = (query: {
    [key: string]: unknown
  }): void => {
    setCurrentPage(1)
    updateQuery(query)
    eventCacher.clearCache()
  }

  const createFetchParamsForFirstPage = (): ActivityEventRequestParams => {
    const { search, eventType, from, to } = queryParams
    const defaultFromDateTimeUtc = moment
      .utc()
      .subtract(7, 'days')
      .format()

    const firstPageToDateTimeUtc = moment
      .utc(to?.value)
      .endOf('day')
      .format()

    return {
      fromDateTimeUtc: from?.value || defaultFromDateTimeUtc,
      toDateTimeUtc: firstPageToDateTimeUtc,
      keyword: search || '',
      eventType:
        (eventType?.value as UserActivityTypes) || UserActivityTypes.all
    }
  }

  const createFetchParamsForCurrentPage = (): ActivityEventRequestParams => {
    const fetchParams = createFetchParamsForFirstPage()

    if (currentPage > 1) {
      fetchParams.toDateTimeUtc = lastCheckedDateTimeUtc
    }

    return fetchParams
  }

  const fetchEvents = async (): Promise<void> => {
    const params = createFetchParamsForCurrentPage()
    let responseBody = eventCacher.getResponseForPage(currentPage)

    if (!responseBody) {
      const fetchActivityEvents = isUserType
        ? getUserActivityEvents
        : getAdminActivityEvents
      const response = await fetchActivityEvents(params)
      responseBody = response.body
      eventCacher.cacheForPage(currentPage, responseBody)
    }
    const {
      events,
      areMoreEventsAvailable,
      lastCheckedDateTimeUtc
    } = responseBody
    onEventsChange(events)
    setIsNextPageAvailable(areMoreEventsAvailable)
    setLastCheckedDateTimeUtc(lastCheckedDateTimeUtc)
  }

  const fetchEventTypes = async (): Promise<void> => {
    const fetchTypes = isUserType
      ? getUserActivityEventTypes
      : getAdminActivityEventTypes
    const { body: eventTypes } = await fetchTypes()
    setEventTypes(eventTypes)
  }

  const setDateRange = (startDate: Date, endDate: Date): void => {
    if (!startDate || !endDate) {
      return
    }
    updateQueryWithPageReset({
      from: {
        label: 'startDate',
        value: moment
          .utc(startDate)
          .startOf('day')
          .format()
      },
      to: {
        label: 'endDate',
        value: moment
          .utc(endDate)
          .endOf('day')
          .format()
      },
      more: false
    })
  }

  useEffect(() => {
    ;(async function() {
      setIsLoading(true)
      try {
        await fetchEvents()
        if (!eventTypes.length) {
          await fetchEventTypes()
        }
      } catch (e) {
        dispatch(globalActions.setNotification(genericFailedNotification))
      } finally {
        setIsLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, queryParams, type])

  const exportAll = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const params = createFetchParamsForFirstPage()
      const exportEvents = isUserType
        ? exportUserActivityEvents
        : exportAdminActivityEvents
      const { body: allEvents } = await exportEvents(params)
      const allEventsCsv = ObjectToCsv(allEvents)
      const blobToSave = new Blob([allEventsCsv], {
        type: 'text/csv;charset=utf-8;'
      })
      saveAs(blobToSave, `lastpass-${type}-events.csv`)
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      setIsLoading(false)
    }
  }

  const eventInfoToolTip = (
    <TooltipStyle>
      <ul>
        <li>
          <Trans>
            <CaptionBold>URL</CaptionBold>: The site URL associated with the
            event
          </Trans>
        </li>
        <li>
          <Trans>
            <CaptionBold>VID</CaptionBold>: The Vault Item Identifier associated
            with the event
          </Trans>
        </li>
      </ul>
    </TooltipStyle>
  )

  return (
    <>
      <HeaderContainer>
        <Heading300
          data-qa={`${isUserType ? 'User' : 'Admin'}ActivityPageHeader`}
        >
          {isUserType ? (
            <Trans>User activity</Trans>
          ) : (
            <Trans>Admin activity</Trans>
          )}
        </Heading300>
        <StyledPrimaryButton
          data-qa={`${isUserType ? 'User' : 'Admin'}ActivityExportButton`}
          disabled={isLoading || events.length === 0}
          key={'export report'}
          onClick={exportAll}
        >
          <Trans>Export report</Trans>
        </StyledPrimaryButton>
      </HeaderContainer>
      <RowSpacer>
        <RowContainer>
          <SearchInput
            data-qa={`${isUserType ? 'User' : 'Admin'}ActivitySearch`}
            onChange={event => {
              updateQueryWithPageReset({
                search: event.target.value || undefined,
                more: false
              })
            }}
            value={queryParams.search || ''}
            placeholder={msg`Search event, information or user...`}
          />
          <>
            <Select
              dataQa={'EventTypesDropdown'}
              isSearchable
              onChange={option => {
                if (option != null) {
                  updateQueryWithPageReset({
                    eventType:
                      { label: option['label'], value: option['value'] } ||
                      undefined,
                    more: false
                  })
                }
              }}
              width={'240px'}
              options={eventTypes}
              defaultValue={
                queryParams.eventType
                  ? queryParams.eventType
                  : {
                      value: isUserType
                        ? UserActivityTypes.all
                        : AdminActivityTypes.all,
                      label: i18n._(msg`Show all event types`)
                    }
              }
            />
            <DateRangeDropdown
              dataQa={'DateOptionsDropdown'}
              setRange={setDateRange}
              defaultStartDate={
                queryParams.from && queryParams.from.value
                  ? new Date(queryParams.from.value)
                  : undefined
              }
              defaultEndDate={
                queryParams.to && queryParams.to.value
                  ? new Date(queryParams.to.value)
                  : undefined
              }
            />
          </>
        </RowContainer>
        <StyledNextPagination
          next={() => {
            setCurrentPage(currentPage + 1)
          }}
          previous={() => {
            setCurrentPage(currentPage - 1)
          }}
          shouldShowNextButton={!isLoading && isNextPageAvailable}
          shouldShowPreviousButton={!isLoading && currentPage > 1}
        />
      </RowSpacer>

      <TableComponent
        table={{
          results: events,
          resultsPerPage,
          totalResults: events.length,
          checkedRecords: [],
          isLoading
        }}
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={
              isUserType ? (
                <Trans>No User Activity Event Data</Trans>
              ) : (
                <Trans>No Admin Activity Event Data</Trans>
              )
            }
            text={
              isUserType ? (
                <Trans>No user data returned.</Trans>
              ) : (
                <Trans>No admin data returned.</Trans>
              )
            }
          />
        }
        getRecordLink={(record, index) => `${matchUrl}/${index}`}
        noResultsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        loadingView={<Loading color="blue900" active />}
        columns={[
          {
            name: (
              <StyledHeader>
                <Trans>Event type</Trans>

                <WithTooltip
                  tooltip={<Trans>Click to open the full list of events</Trans>}
                >
                  <StyledHelpIcon
                    onClick={() =>
                      window.open(
                        defaultConfig.lastPassBaseAddress + '/logmsgdoc.php',
                        '_blank'
                      )
                    }
                  />
                </WithTooltip>
              </StyledHeader>
            ),
            renderer: ({ type }) =>
              i18n._(
                isUserType
                  ? userActivityReportTranslationMap[type]
                  : adminActivityReportTranslationMap[type]
              )
          },
          {
            name: (
              <>
                <Trans>Event information</Trans>
                <WithTooltip
                  data-qa="EventInfoToolTip"
                  tooltip={eventInfoToolTip}
                  width="300px"
                >
                  <StyledHelpIcon />
                </WithTooltip>
              </>
            ),
            renderer: ({ data }) => data
          },
          {
            name: <Trans>User</Trans>,
            renderer: ({ userEmail, userName }) => userEmail || userName
          },
          {
            name: <Trans>Time</Trans>,
            renderer: ({ dateTimeUtc }) =>
              moment(dateTimeUtc).format('MMMM D, YYYY [at] h:mm:ss A')
          }
        ]}
        isFilterApplied={!!queryParams.eventType || !!queryParams.search}
      />
    </>
  )
}

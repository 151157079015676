import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'

export function createUserGroupsSaga(userService: UACServices.Services) {
  return function* getUserGroupsSaga(
    action: ReturnType<typeof userDrawerActions.getUserGroups>
  ) {
    try {
      const id = action.payload.params.path.id
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        top: resultsPerPage
      })
      if (id) {
        yield put(userDrawerActions.setUserGroupsLoading(true))
        try {
          const result: UACServices.UserGroupsAPI.Responses = yield call(
            userService.userGroups,
            id,
            query
          )
          if (result.type === UACServices.UserGroupsAPI.SUCCESS) {
            const groups = result.body.groups
            const totalResults = result.body.totalResults
            yield put(
              userDrawerActions.setUserGroups(
                groups.slice(0, resultsPerPage),
                totalResults,
                resultsPerPage
              )
            )
          }
        } finally {
          yield put(userDrawerActions.setUserGroupsLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

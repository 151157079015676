import { useCallback, useEffect } from 'react'

export const useKeyPress = (
  keyCode: number,
  callback: () => void | undefined
) => {
  const keyPressFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === keyCode) {
        callback()
      }
    },
    [keyCode, callback]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction, false)
    return () => {
      document.removeEventListener('keydown', keyPressFunction, false)
    }
  })
}

import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { SaveTeamsPolicySwitchOnData } from '@lastpass/admin-console-dependencies/types/policy-teams'

export function createDisablePolicySaga(services: UACServices.Services) {
  return function* disablePolicySaga(
    action: ReturnType<typeof multifactorActions.disablePolicy>
  ) {
    const dialog: ConfirmDialog = {
      type: 'confirmdialog',
      title: msg`Disable policy`,
      text: msg`Disable the policy and erase all associated configurations and data?`,
      discardText: msg`Cancel`,
      confirmText: msg`Disable`
    }

    yield put(globalActions.setDialog(dialog))
    const confirmClick = yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])

    if (confirmClick.type === GlobalActionTypes.DISCARD_DIALOG) {
      return
    }

    const { dataIndex, policy } = action.payload
    try {
      yield put(multifactorActions.setLoading(true))
      let isSuccesfullRequest: boolean
      if (dataIndex === undefined || policy.policyData.length < 2) {
        yield put(
          multifactorActions.editRequireOfPolicyAvailability(
            false,
            policy.policyKey
          )
        )

        const result: UACServices.GeneralPolicySwitchOFFSaveAPI.Responses = yield call(
          services.generalPolicySwitchOffSave,
          policy.policyKey
        )
        isSuccesfullRequest =
          result.type === UACServices.GeneralPolicySwitchOFFSaveAPI.SUCCESS
        if (isSuccesfullRequest) {
          yield put(multifactorActions.removePolicy(policy.policyKey))
        }
      } else {
        const policySaveData: SaveTeamsPolicySwitchOnData = {
          policyKey: policy.policyKey,
          policyData: policy.policyData.filter(
            (_, index) => index !== dataIndex
          )
        }

        const result: UACServices.GeneralPolicySwitchONSaveAPI.Responses = yield call(
          services.generalPolicySwitchOnSave,
          policySaveData
        )
        isSuccesfullRequest =
          result.type === UACServices.GeneralPolicySwitchONSaveAPI.SUCCESS
      }

      if (isSuccesfullRequest) {
        yield put(multifactorActions.fetchInitialData())
        yield put(
          globalActions.setNotificationSuccess(msg`Policy changes saved.`)
        )
      } else {
        yield put(
          globalActions.setNotificationAlert(
            msg`Policy changes haven't been saved.`
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(multifactorActions.setLoading(false))
    }
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { UserLevelWithPermissions } from '@lastpass/admin-console-dependencies/types/user-level'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

export const errorNotification = {
  message: msg`Admin level hasn't been created.`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const duplicateErrorNotification = {
  message: msg`There's already an admin level with that name`,
  type: NotificationType.alert,
  autoDismiss: false
}

export function createAddNewPartialdminLevelSaga() {
  return function* addNewPartialdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.addNewPartialAdminLevel>
  ) {
    try {
      const { name, description, permissions } = action.payload

      const currentAdminLevel: UserLevelWithPermissions = {
        id: '',
        description,
        name,
        permissions,
        isCustom: true,
        mspCidOverridePermissions: [],
        userCount: 0,
        mspCidOverrideAdminLevel: MspCidOverrideLevel.admin
      }

      yield put(adminLevelDrawerActions.setCurrentAdminLevel(currentAdminLevel))

      yield put(push(`/users/admin/add/managed-admin-permissions`))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

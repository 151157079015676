import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { BodyRegular, FilterBadge, TextButton } from '@lastpass/lastkit'

import { GroupFilterState } from '@lastpass/admin-console-dependencies/state/common/group-filter/state'
import { UserListQueryParams } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { getAccountStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/AccountStatusMapping'
import { AdminLevelMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/AdminLevelMapping'
import { getIsCreatedByCompanyText } from '@lastpass/admin-console-dependencies/ui/common/mappings/column-mappings/UserColumnMapping'
import { FederatedStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/FederatedStatusMapping'
import { MFAStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MfaStatusMapping'
import { SecurityScoreLevelMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SecurityScoreLevelMapping'

interface UsersListFilterRowProps {
  queryParams: Partial<UserListQueryParams>
  updateQuery: (update: Partial<UserListQueryParams>) => void
  totalCompanyUsers: number
  totalResults: number
}

export const UserListFiltersRow: FunctionComponent<UsersListFilterRowProps> = props => {
  const userFilters = props.queryParams && props.queryParams.filter
  const filterObjectKeys = !!userFilters && Object.keys(userFilters)
  const groupFilter = useSelector<AppState, GroupFilterState>(
    appState => appState.groupFilter
  )
  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )
  const accountStatusMapping = getAccountStatusMapping(isAccountStatusRenamed)

  return (
    <>
      <BodyRegular data-qa={'TotalUserCounter'}>
        {(!props.queryParams.search && !props.queryParams.filter && (
          <Trans>Showing all users: {props.totalCompanyUsers}</Trans>
        )) || (
          <>
            <Trans>
              Showing matching users: {props.totalResults} of{' '}
              {props.totalCompanyUsers}
            </Trans>
            <TextButton
              data-qa={'ClearAllFiltersButton'}
              blue
              onClick={() => {
                props.updateQuery({
                  search: undefined,
                  skip: undefined,
                  filter: undefined
                })
              }}
            >
              <Trans>Clear all filters</Trans>
            </TextButton>
          </>
        )}
      </BodyRegular>
      <div>
        {filterObjectKeys &&
          userFilters &&
          Object.keys(userFilters).map(key => {
            return userFilters[key].map(filter => {
              if (key === 'groupId' && !groupFilter.idMapping[filter]) {
                return
              }
              const remainingFilters = userFilters[key].filter(
                status => status !== filter
              )
              return (
                <FilterBadge
                  dataQa={'FilteredStatus_' + String(filter)}
                  key={filter}
                  removeBadge={() => {
                    const remainingFilterCount = Object.keys(
                      userFilters
                    ).reduce(
                      (value, filterKey) =>
                        userFilters[filterKey].length + value,
                      0
                    )

                    props.updateQuery({
                      filter:
                        key && remainingFilterCount > 1
                          ? {
                              ...userFilters,
                              [key]: remainingFilters
                            }
                          : undefined,
                      skip: undefined
                    })
                  }}
                >
                  {key === 'accountStatus' && (
                    <Trans>
                      Account status: {accountStatusMapping[filter]}
                    </Trans>
                  )}
                  {key === 'mfaStatus' && (
                    <Trans>MFA status: {MFAStatusMapping[filter]}</Trans>
                  )}
                  {key === 'securityScore' && (
                    <Trans>
                      Security score: {SecurityScoreLevelMapping[filter]}
                    </Trans>
                  )}
                  {key === 'adminLevelId' && (
                    <Trans>Admin level: {AdminLevelMapping[filter]}</Trans>
                  )}
                  {key === 'inviteEmailSentTimeUtc' && (
                    <Trans>
                      Last invitation: Older than{' '}
                      {Math.floor(
                        (new Date().getTime() - new Date(filter).getTime()) /
                          (1000 * 3600 * 24)
                      )}{' '}
                      days
                    </Trans>
                  )}
                  {key === 'provisionFederatedStatus' && (
                    <Trans>
                      Federated status: {FederatedStatusMapping[filter]}
                    </Trans>
                  )}
                  {key === 'groupId' && (
                    <Trans>Group: {groupFilter.idMapping[filter]}</Trans>
                  )}
                  {key === 'isCreatedByCompany' && (
                    <Trans>
                      Created by company: {getIsCreatedByCompanyText(filter)}
                    </Trans>
                  )}
                </FilterBadge>
              )
            })
          })}
      </div>
    </>
  )
}

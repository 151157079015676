import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { Heading300 } from '@lastpass/lastkit'

import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { useSiemIntegrationsGetConfigs } from './api-hooks/use-siem-integrations-get-configs'
import { AlienVaultIntegrationCard } from './components/AlienVaultIntegrationCard'
import { AzureSentinelIntegrationCard } from './components/AzureSentinelIntegrationCard'
import { LogRhythmIntegrationCard } from './components/LogRhythmIntegrationCard'
import { QRadarIntegrationCard } from './components/QRadarIntegrationCard'
import { SplunkIntegrationCard } from './components/SplunkIntegrationCard'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const SiemIntegrations: React.FunctionComponent = () => {
  const isSiemIntegrationEnabled = useFeatureFlags(
    FeatureFlags.isSiemIntegrationEnabled
  )
  const isSIEMQRadarEnabled = useFeatureFlags(FeatureFlags.isSIEMQRadarEnabled)
  const isSIEMLogRhythmEnabled = useFeatureFlags(
    FeatureFlags.isSIEMLogRhythmEnabled
  )
  const isSIEMAlienVaultEnabled = useFeatureFlags(
    FeatureFlags.isSIEMAlienVaultEnabled
  )
  const { isLoading, configs } = useSiemIntegrationsGetConfigs()

  if (!isSiemIntegrationEnabled) {
    return null
  }

  return (
    <>
      <Heading300
        css={css`
          margin-left: 24px;
        `}
      >
        <Trans>SIEM integrations</Trans>
      </Heading300>
      <div
        css={css`
          margin: 24px;
        `}
      >
        {isLoading && (
          <Loading
            data-qa="SiemIntegrationsLoadingIndicator"
            color="blue900"
            active={true}
          />
        )}
        <StyledContainer>
          <SplunkIntegrationCard config={{ ...configs.splunk }} />
          <AzureSentinelIntegrationCard config={{ ...configs.azureSentinel }} />
          {isSIEMQRadarEnabled && (
            <QRadarIntegrationCard config={{ ...configs.qRadar }} />
          )}
          {isSIEMLogRhythmEnabled && (
            <LogRhythmIntegrationCard config={{ ...configs.logRhythm }} />
          )}
          {isSIEMAlienVaultEnabled && (
            <AlienVaultIntegrationCard config={{ ...configs.alienVault }} />
          )}
        </StyledContainer>
      </div>
    </>
  )
}

export enum ManagedCompanyStatus {
  new = 'new',
  active = 'active',
  suspended = 'suspended'
}

export enum CompanyTemplateTypes {
  copyCompany = 'copyCompany',
  default = 'default'
}

export enum CompanyTemplateOptions {
  policy = 'policy',
  group = 'group',
  adminLevel = 'adminLevel'
}

export interface ManagedCompany {
  id: number
  name: string
  status: ManagedCompanyStatus
  licensesAvailable: number
  licensesConsumed: number
  licensesAllocated: number
  averageSecurityScore: number | null
  createdDateUtc: string
  averageSecurityScoreDateUtc: string | null
  hasMfa: boolean | null
  hasNpMigratedApp: boolean
  psa: PsaDetails | null
}

export interface PsaDetails {
  contractName: string | null
}

export interface ManagedCompanyAdmin {
  uid: number
  email: string
  name: string
}

export interface ManagedCompaniesAggregate {
  consumedLicenses: number | null
  totalLicensesAllocated: number | null
  averageSecurityScore: number | null
}

export interface ManagedCompanyUserCounts {
  id: number
  name: string
  userCounts: number
  createdDateUTC: string
}

export interface CompanyTemplateSettings {
  type: CompanyTemplateTypes
  sourceId?: number
  options: CompanyTemplateOptions[]
}

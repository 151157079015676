import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { BulkActionErrors } from '@lastpass/admin-console-dependencies/types/bulk-action-errors'

import { EnumDictionary } from '../EnumValues'

export const BulkActionErrorMapping: EnumDictionary<
  BulkActionErrors,
  ReactElement
> = {
  [BulkActionErrors.alreadyExists]: (
    <Trans>User already exists in your organization</Trans>
  ),
  [BulkActionErrors.cannotBeCreated]: <Trans>Not added</Trans>,
  [BulkActionErrors.cannotBeDisabled]: <Trans>Not disabled</Trans>,
  [BulkActionErrors.cannotBeInvited]: <Trans>Not invited</Trans>,
  [BulkActionErrors.cannotBeUninvited]: <Trans>Invitation not revoked</Trans>,
  [BulkActionErrors.cannotDisable]: <Trans>Not disabled</Trans>,
  [BulkActionErrors.cannotrequireMpChange]: (
    <Trans>Master password change not required</Trans>
  ),
  [BulkActionErrors.deleteSelf]: <Trans>Can{"'"}t delete yourself</Trans>,
  [BulkActionErrors.failedToAssign]: <Trans>Not assigned</Trans>,
  [BulkActionErrors.failedToDelete]: <Trans>Not deleted</Trans>,
  [BulkActionErrors.notMemberOfCompany]: <Trans>Couldn{"'"}t find user</Trans>,
  [BulkActionErrors.resourceNotFound]: <Trans>Item not found</Trans>,
  [BulkActionErrors.userCreateExistsAndInvited]: (
    <Trans>Not added, already invited</Trans>
  ),
  [BulkActionErrors.userCreateExistsInCompany]: (
    <Trans>Not added, already in account</Trans>
  ),
  [BulkActionErrors.userCreateExistsMarkedForDelete]: <Trans>Not added</Trans>,
  [BulkActionErrors.userCreateExistsNoInvite]: <Trans>Not added</Trans>,

  [BulkActionErrors.userCreateInvalidEmail]: (
    <Trans>Not added; check email and try again</Trans>
  ),

  [BulkActionErrors.userCreatePolicyViolation]: (
    <Trans>
      Email must conform to the policy {'"'}Restrict domain for LastPass
      username{'"'}
    </Trans>
  ),
  [BulkActionErrors.userCreateUnknown]: <Trans>Something went wrong</Trans>,
  [BulkActionErrors.notMemberOfGroup]: <Trans>User is not in group</Trans>,
  [BulkActionErrors.cannotSelectForVaultReEncryption]: (
    <Trans>Couldn{"'"}t set re-encryption</Trans>
  ),
  [BulkActionErrors.errorNotFederated]: (
    <Trans>Couldn{"'"}t set re-encryption</Trans>
  ),
  [BulkActionErrors.errorDestroySessionFailed]: (
    <Trans>Couldn{"'"}t destroy sessions</Trans>
  ),
  [BulkActionErrors.na]: <Trans>Something went wrong</Trans>
}

import React, { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'
import { useUnmountEffect } from '@lastpass/ui'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

export interface UserDrawerProps {
  closeLink: string
  backButton?: ReactElement
  childTitle?: ReactElement
  moreButton?: ReactElement
}

type UserDrawerComponentProps = LastPassComponentProps<
  UsersDrawerState,
  typeof userDrawerActions,
  UserDrawerProps
>

export const UserDrawerComponent: React.FunctionComponent<UserDrawerComponentProps> = ({
  state,
  props,
  children,
  actions
}) => {
  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(userDrawerActions.getUserProfile({ query: {}, path: pathParams }))
  }, [dispatch, pathParams])
  useUnmountEffect(() => {
    actions.reset()
  })
  return (
    <Drawer
      mainTitle={
        (state.profile && (
          <>
            {state.profile.firstName} {state.profile.lastName}
          </>
        )) || <></>
      }
      subTitle={props.childTitle}
      closeLink={props.closeLink}
      headerNodes={
        <>
          {props.backButton}
          {props.moreButton}
        </>
      }
    >
      {children}
    </Drawer>
  )
}

export function createUserDrawer<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserDrawerComponent,
    (state: TState) => state.usersDrawer,
    userDrawerActions
  )
}

export type UserDrawer = ReturnType<typeof createUserDrawer>

import React, { FunctionComponent, HTMLAttributes } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as AdvancedFeatureIllustration } from '@lastpass/assets/svg/admin-console/icon-advanced-feature.svg'

import { BodyBoldStyle } from '../styles'
import { WithTooltip } from './Tooltip'

export interface SideNavigationItemProps
  extends HTMLAttributes<HTMLDivElement> {
  icon: React.ComponentType
  active?: boolean
  tooltipText?: React.ReactElement
  tooltipWidthPixels?: number
}

const StyledAdvancedFeatureIcon = styled(AdvancedFeatureIllustration)`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 10px;
`

const SidebarItem = styled.div<{ active?: boolean }>`
  ${BodyBoldStyle}
  display: flex;
  align-items: center;
  height: 36px;
  overflow: hidden;
  cursor: pointer;
  background: ${props => (props.active ? props.theme.colors.blue200 : '')};
  color: ${props =>
    props.active ? props.theme.colors.blue : props.theme.colors.neutral800};
  border-radius: 0px 25px 25px 0px;
  :hover {
    color: ${props => props.theme.colors.blue};
  }
  padding-left: 24px;
  position: relative;
  margin-right: 24px;
`

const SidebarActiveTab = styled.div`
  background: ${props => props.theme.colors.blue};
  border-radius: 4px;
  width: 7px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2px;
`

const SidebarText = styled.div`
  margin-left: 16px;
`

export const SideNavigation = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: inset -1px 0px 0px #d5d9de;
  padding: 32px 0;
  height: 100%;
  background: ${props => props.theme.colors.blue50};
  text-decoration: none;
  min-width: 280px;
`

export const SideNavigationItem: FunctionComponent<SideNavigationItemProps> = ({
  children,
  active,
  icon,
  ...props
}) => {
  const StyledIcon = styled(icon)`
    height: 16px;
    width: 16px;
  `

  return (
    <SidebarItem {...props} active={active}>
      {active && <SidebarActiveTab data-qa={'SideMenuSelected'} />}
      <StyledIcon />
      <SidebarText>{children}</SidebarText>
      {props.tooltipText && (
        <WithTooltip
          tooltip={props.tooltipText}
          width={
            props.tooltipWidthPixels ? props.tooltipWidthPixels + 'px' : '300px'
          }
          placement={'right'}
        >
          <StyledAdvancedFeatureIcon
            style={active ? { opacity: 1 } : { opacity: 0.5 }}
            data-qa={'AdvancedFeatureIcon'}
          />
        </WithTooltip>
      )}
    </SidebarItem>
  )
}

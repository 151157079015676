import { accordionFocusLinker } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/helpers/accordion-focus-linker'

export enum AccordionNames {
  licenseConsumptionRate = 'licenseConsumptionRate',
  enrollmentRate = 'enrollmentRate',
  activeUsageRate = 'activeUsageRate'
}

export const adoptionDashboardAccordionFocusLinker = accordionFocusLinker<
  AccordionNames
>('adoptionDashboardAccordion', Object.values(AccordionNames))

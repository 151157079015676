import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { CompanyTemplateSettings } from '@lastpass/admin-console-dependencies/types/managed-company'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = () => createResponse(SUCCESS)
const failure = () => createResponse(FAILURE)

export const responses = {
  success,
  failure
}

export type Responses = ResponseTypes<typeof responses>

export interface CreateManagedCompanyBody {
  companyName: string
  numberOfLicenses: number
  isMsp: boolean
  companyTemplateSettings: CompanyTemplateSettings | null
}

export function create(fetch: AuthFetch) {
  return async function service(body: CreateManagedCompanyBody) {
    const [, status] = await fetch('company/managed-companies', 'POST', body, {
      'Content-Type': 'application/json'
    })

    if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
      throw new UnknownServiceError()
    }

    if (status !== StatusCodes.OK) {
      return failure()
    }

    return success()
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GroupUserActionsType } from '@lastpass/admin-console-dependencies/state/users/groups/operations/group-operations'

const getGroupActionUrl = (
  actionType: GroupUserActionsType,
  groupId: string
) => {
  switch (actionType) {
    case GroupUserActionsType.resendMfa:
      return {
        url: `groups/${groupId}/users/send-mfa-invitation`,
        method: 'POST'
      }
    case GroupUserActionsType.mpChange:
      return {
        url: `groups/${groupId}/users/require-master-password-change`,
        method: 'POST'
      }
    case GroupUserActionsType.vaultReEncryption:
      return {
        url: `groups/${groupId}/users/federated-users/flags/vault-reencryption`,
        method: 'POST'
      }
    case GroupUserActionsType.disableUsers:
      return { url: `groups/${groupId}/users/disable`, method: 'POST' }
    case GroupUserActionsType.destroySessions:
      return {
        url: `groups/${groupId}/users/destroy-all-sessions`,
        method: 'POST'
      }
    case GroupUserActionsType.deleteUsers:
      return { url: `groups/${groupId}/users`, method: 'DELETE' }
    case GroupUserActionsType.removeFromCompany:
      return {
        url: `groups/${groupId}/users/remove-from-company`,
        method: 'POST'
      }
    case GroupUserActionsType.disableMfa:
      return { url: `groups/${groupId}/users/disable-mfa`, method: 'POST' }
    case GroupUserActionsType.inviteUsers:
      return { url: `groups/${groupId}/users/invite `, method: 'POST' }
    default:
      return { url: '/', method: 'POST' }
  }
}

export const SUCCESS = 'success'
export const FAIL = 'fail'

const success = () => createResponse(SUCCESS)
const fail = () => createResponse(FAIL)

export const Responses = {
  success,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    actionType: GroupUserActionsType,
    groupId: string
  ) {
    const endpoint = getGroupActionUrl(actionType, groupId)
    const response = await fetch(endpoint.url, endpoint.method)

    if (response[1] === StatusCodes.ACCEPTED) {
      return success()
    } else if (response[1] === StatusCodes.NOT_FOUND) {
      return fail()
    } else throw new UnknownServiceError()
  }
}

import React, { FunctionComponent, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as AdminIcon } from '@lastpass/assets/svg/admin-console/icon-admin.svg'
import { ReactComponent as DirectoriesIcon } from '@lastpass/assets/svg/admin-console/icon-directories.svg'
import { ReactComponent as GroupIcon } from '@lastpass/assets/svg/admin-console/icon-groups.svg'
import { ReactComponent as FederatedLoginIcon } from '@lastpass/assets/svg/admin-console/icon-login.svg'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-users.svg'
import { ContentContainer, SidebarContainer } from '@lastpass/lastkit'
import { SideNavigation } from '@lastpass/lastkit/components/SideNavigation'
import { RoutedSideNavigationItem } from '@lastpass/routing'
import { getDefaultUrl, SideMenuItem } from '@lastpass/routing/get-default-url'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { Overlay } from '@lastpass/admin-console-dependencies/ui/common/OverlayComponent'
import {
  PermissionContext,
  PermissionContextProps
} from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { AdminLevelsPage } from './admin/AdminLevelsPage'
import { DirectoriesIntegrationsPage } from './directories/DirectoriesViewPage'
import { FederatedLoginPage } from './federated-login/FederatedLoginPage'
import { GroupsViewPage } from './groups/GroupsViewPage'
import { UsersViewPage } from './view/UsersViewPage'

const StyledOverlay = styled(Overlay)<{ decreaseHeight?: number }>`
  z-index: 60;
  top: ${props =>
    props.decreaseHeight ? `${props.decreaseHeight + 56}px` : '56px'};
  left: 0;
  width: calc(100vw - 588px);
  height: ${props =>
    props.decreaseHeight
      ? `calc(100vh - ${props.decreaseHeight + 56}px)`
      : 'calc(100vh - 56px)'};
`

interface PagesVisibility {
  isUsersVisible: boolean
  isDirectoriesVisible: boolean
  isFederatedLoginVisible: boolean
}

function getPagesVisibility(
  permissions: PermissionContextProps,
  isFederatedLoginEnabled: boolean
): PagesVisibility {
  return {
    isUsersVisible: permissions.requirePermission(AdminPermissions.usersView),
    isDirectoriesVisible: permissions.requirePermission(
      AdminPermissions.directoriesAndFederationModify
    ),
    isFederatedLoginVisible:
      permissions.requirePermission(
        AdminPermissions.directoriesAndFederationModify
      ) && isFederatedLoginEnabled
  }
}

function getItems(
  visibility: PagesVisibility,
  matchPath: string,
  matchUrl: string
): SideMenuItem[] {
  const items: SideMenuItem[] = []
  if (visibility.isUsersVisible) {
    items.push({
      url: `${matchUrl}/view`,
      route: { path: `${matchPath}/view` },
      icon: UserIcon,
      qaData: 'SideMenuUsers',
      header: <Trans>Users</Trans>,
      content: <UsersViewPage />
    })
  }

  if (visibility.isUsersVisible) {
    items.push({
      url: `${matchUrl}/groups`,
      route: { path: `${matchPath}/groups` },
      icon: GroupIcon,
      qaData: 'SideMenuGroups',
      header: <Trans>Groups</Trans>,
      content: <GroupsViewPage />
    })
  }

  if (visibility.isUsersVisible) {
    items.push({
      url: `${matchUrl}/admin`,
      route: { path: `${matchPath}/admin` },
      icon: AdminIcon,
      qaData: 'SideMenuAdmins',
      header: <Trans>Admin levels</Trans>,
      className: 'onboarding-tour-step-4',
      content: <AdminLevelsPage />
    })
  }

  if (visibility.isDirectoriesVisible) {
    items.push({
      url: `${matchUrl}/directories`,
      route: { path: `${matchPath}/directories` },
      icon: DirectoriesIcon,
      qaData: 'SideMenuDirectories',
      header: <Trans>Directories</Trans>,
      content: <DirectoriesIntegrationsPage />
    })
  }

  if (visibility.isFederatedLoginVisible) {
    items.push({
      url: `${matchUrl}/federatedLogin`,
      route: { path: `${matchPath}/federatedLogin` },
      icon: FederatedLoginIcon,
      qaData: 'SideMenuFederatedLogin',
      header: <Trans>Federated login</Trans>,
      content: <FederatedLoginPage />
    })
  }

  return items
}

export const UsersPage: FunctionComponent = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const isTrial =
    useSelector<AppState, boolean | undefined>(
      state => state.companyInfo.details.isTrial
    ) === true
  const isManagedCompany = !!useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )

  const permissions = useContext(PermissionContext)

  const isFederatedLoginEnabled = useFeatureFlags(
    FeatureFlags.isFederatedLoginEnabled
  )

  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const visibility = getPagesVisibility(permissions, isFederatedLoginEnabled)
    const items = getItems(visibility, matchPath, matchUrl)
    const defaultUrl = getDefaultUrl(items)
    const styledOverlayShouldVisible =
      location.pathname === `${matchUrl}/view/add` ||
      location.pathname === `${matchUrl}/admin/add` ||
      new RegExp(
        `${matchUrl}/(view/\\d+/transfer-vault)|(admin/\\d+/managed-admin-permissions)`
      ).test(location.pathname)

    const getOverlayBackLink = () => {
      switch (location.pathname) {
        case `${matchUrl}/admin/add`:
          return '/users/admin'
        default:
          return '/users/view'
      }
    }

    return (
      <>
        {styledOverlayShouldVisible && (
          <StyledOverlay
            onClick={() => {
              history.push(getOverlayBackLink())
            }}
            decreaseHeight={
              0 + (isTrial ? 40 : 0) + (isManagedCompany ? 24 : 0)
            }
          />
        )}
        <SidebarContainer>
          <SideNavigation>
            {items.map(item => (
              <RoutedSideNavigationItem
                key={item.qaData}
                data-qa={item.qaData}
                className={item.className}
                to={item.url}
                route={item.route}
                icon={item.icon}
              >
                {item.header}
              </RoutedSideNavigationItem>
            ))}
          </SideNavigation>
        </SidebarContainer>
        <ContentContainer>
          <Switch>
            {items.map(item => (
              <Route key={item.qaData} {...item.route}>
                {item.content}
              </Route>
            ))}
            <Route>
              <Redirect to={defaultUrl} />
            </Route>
          </Switch>
        </ContentContainer>
      </>
    )
  }
  return null
}

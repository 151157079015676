import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { UsersFilter } from '@lastpass/admin-console-dependencies/types/users-filter'

export enum UserFilterActionTypes {
  SET_USERS_LIST_BY_ID_LIST = '[user-filter] set-by-id-list',
  GET_USERS_LIST_BY_ID_LIST = '[user-filter] get-by-id-list'
}

export interface UserFilterQueryParams {
  search: string
}

export const userFilterActions = {
  getUserListByIdList: (idList: string[]) =>
    createAction(UserFilterActionTypes.GET_USERS_LIST_BY_ID_LIST, {
      idList
    }),
  setUserListByIdList: (users: UsersFilter[]) =>
    createAction(UserFilterActionTypes.SET_USERS_LIST_BY_ID_LIST, {
      users
    })
}

export type UserFilterActions = ActionsUnion<typeof userFilterActions>

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const DUPLICATE_NAME = 'duplicate-adminlevel-name'

const success = (id: string) => createResponse(SUCCESS, { id })
const duplicateName = () => createResponse(DUPLICATE_NAME)

export const Responses = {
  success,
  duplicateName
}
export type Responses = ResponseTypes<typeof Responses>
export function create(fetch: AuthFetch) {
  return async function service(payload) {
    const [response, status] = await fetch('adminLevels', 'POST', payload, {
      ['content-type']: 'application/json'
    })

    if (status === StatusCodes.CONFLICT) {
      return duplicateName()
    } else if (status === StatusCodes.OK) {
      const result = JSON.parse(response)
      return success(result.content.id)
    }
    throw new UnknownServiceError()
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { takeDialogInteractions } from '@lastpass/admin-console-dependencies/sagas/utils/take-dialog-interactions'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { ActivateUsersDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { getCurrentUrlFilters, invokeApi } from './operation-base'

export const totalSelectedUserCountSelector = (state: AppState) =>
  state.usersList.table.totalResults

export function createActivateUserSaga(userService: UACServices.Services) {
  return function* activateUserSaga(
    action: ReturnType<typeof userOperationActions.activateUsers>
  ) {
    try {
      const { users, isAllSelected } = action.payload.userOperationPayload
      const allUserCount = yield select(totalSelectedUserCountSelector)
      const dialog: ActivateUsersDialog = {
        type: 'activate-users-dialog',
        users,
        isAllSelected,
        allUserCount
      }

      yield put(globalActions.setDialog(dialog))
      const { isConfirmed, isDiscarded } = yield call(takeDialogInteractions)

      if (isDiscarded) return

      if (isConfirmed) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users have been activated.`,
            MultipleUsersSuccess: msg`The selected users have been activated.`,
            SingleUserFail:
              users.length > 0
                ? msg`User hasn't  been activated: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User has been activated: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users haven't been activated.`
          },
          'activateuser',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.ACTIVATE_USERS,
          action.payload.userOperationPayload.filter
        )
        const currentUrlFilters = yield select(getCurrentUrlFilters)
        yield put(push(`/users/view${currentUrlFilters.location.search || ''}`))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

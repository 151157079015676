import React from 'react'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  PrimaryButton,
  SearchInput,
  StyledSpacer,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { assignActions } from '@lastpass/admin-console-dependencies/state/common/assign/actions'
import { AssignState } from '@lastpass/admin-console-dependencies/state/common/assign/state'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { Entities } from '@lastpass/admin-console-dependencies/types/assign'

import { AppliesToRadioButtons } from './AppliesToRadioButtons'
import { GroupsTableComponent } from './GroupsTable'
import { UsersTableComponent } from './UsersTable'

export interface AssignNewProps {
  closeLink: string
}

type AssignNewEntityComponentProps = LastPassComponentProps<
  AssignState,
  typeof advancedPolicyDrawerActions & typeof assignActions,
  AssignNewProps
>

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

export const AssignNewEntityComponent: React.FunctionComponent<AssignNewEntityComponentProps> = ({
  actions,
  state,
  props
}) => {
  const history = useHistory()

  const assignDisabled =
    state.users.available.checkedRecords.length === 0 &&
    state.groups.available.checkedRecords.length === 0

  const setSearchInput = () => {
    if (state.showTable === Entities.users) {
      return state.users.searchInput
    }
    if (state.showTable === Entities.groups) {
      return state.groups.searchInput
    }
  }

  return (
    <>
      <AppliesToRadioButtons
        onChange={() => {}}
        disabled={true}
        initValue={state.appliesTo}
      />
      <SearchInput
        data-qa="AssignSearchBar"
        value={setSearchInput()}
        onChange={event => {
          if (event.target.value.length > 1) {
            if (state.showTable === Entities.users) {
              actions.setSearchInput(Entities.users, event.target.value)
              actions.getAvailableEntity(Entities.users, event.target.value)
            }
            if (state.showTable === Entities.groups) {
              actions.setSearchInput(Entities.groups, event.target.value)
              actions.getAvailableEntity(Entities.groups, event.target.value)
            }
          } else if (!event.target.value) {
            actions.resetTables()
          }
        }}
        placeholder={msg`Search users & groups...`}
      />
      <TableContainer>
        <StyledTabContainer data-qa="TabNavigationBar">
          <TabNavigation>
            <div
              data-qa="AssignUsersTab"
              onClick={() => {
                actions.showTable(Entities.users)
              }}
            >
              <StyledTabNavigationItem
                active={state.showTable === Entities.users}
              >
                <Trans>Users</Trans>
                {` (${state.users.available.totalResults})`}
              </StyledTabNavigationItem>
            </div>
            <div
              data-qa="AssignGroupsTab"
              onClick={() => {
                actions.showTable(Entities.groups)
              }}
            >
              <StyledTabNavigationItem
                active={state.showTable === Entities.groups}
              >
                <Trans>Groups</Trans>
                {` (${state.groups.available.totalResults})`}
              </StyledTabNavigationItem>
            </div>
          </TabNavigation>
        </StyledTabContainer>
      </TableContainer>
      {state.showTable === Entities.users ? (
        <UsersTableComponent
          table={state.users.available}
          checkable={{
            addSelectedRecords: actions.addSelectedUsers,
            removeSelectedRecords: actions.removeSelectedUsers,
            setSelectedRecords: actions.setSelectedUsers
          }}
          title={<Trans>Please search for users</Trans>}
          text={
            <Trans>To assign users to this application, search above.</Trans>
          }
          filterApplied={!!state.users.searchInput}
          clearSearchQuery={() => actions.resetSearchInput(Entities.users)}
          actionButton={<></>}
        />
      ) : null}
      {state.showTable === Entities.groups ? (
        <GroupsTableComponent
          table={state.groups.available}
          checkable={{
            addSelectedRecords: actions.addSelectedGroups,
            removeSelectedRecords: actions.removeSelectedGroups,
            setSelectedRecords: actions.setSelectedGroups
          }}
          title={<Trans>Please search for groups</Trans>}
          text={
            <Trans>To assign groups to this application, search above.</Trans>
          }
          filterApplied={!!state.groups.searchInput}
          clearSearchQuery={() => actions.resetSearchInput(Entities.groups)}
          actionButton={<></>}
        />
      ) : null}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Cancel"
            onClick={() => history.push(props.closeLink)}
            data-qa="CancelButton"
          >
            <Trans>Cancel</Trans>
          </TextButton>
          <PrimaryButton
            id="AssignButton"
            type="submit"
            key="Assign"
            onClick={() => {
              actions.assign()
              actions.nextStep(AdvancedPolicyDrawerSteps.allSet)
            }}
            disabled={assignDisabled}
            data-qa="AssignButton"
          >
            <Trans>Assign</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

export function createAssignNewEntity<
  TState extends { assign: AssignState }
>() {
  return connectRedux(
    AssignNewEntityComponent,
    (state: TState) => state.assign,
    { ...advancedPolicyDrawerActions, ...assignActions }
  )
}

export type AssignNewEntity = ReturnType<typeof createAssignNewEntity>

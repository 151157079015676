import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'

export enum UserLevel {
  user = 'User',
  admin = 'Admin',
  superAdmin = 'SuperAdmin',
  helpDeskAdmin = 'HelpDeskAdmin',
  legacyHelpdeskAdmin = 'LegacyHelpDeskAdmin',
  resetAdmin = 'ResetAdmin',
  userAdmin = 'UserAdmin',
  policyAdmin = 'PolicyAdmin',
  applicationAdmin = 'ApplicationAdmin',
  mfaAdmin = 'MfaAdmin',
  workstationAdmin = 'WorkstationAdmin',
  reportingAdmin = 'ReportingAdmin',
  readOnlyAdmin = 'ReadOnlyAdmin'
}

export enum MspCidOverrideLevel {
  empty = '',
  admin = 'admin',
  custom = 'custom',
  none = 'none'
}

export interface UserLevelObject {
  id: string
  name: string
  isCustom: boolean
}

export interface UserLevelDetails {
  id: string
  name: string
  description: string
  userCount: number
  isCustom: boolean
}

export interface UserLevelWithPermissions extends UserLevelDetails {
  permissions: AdminPermissions[]
  mspCidOverridePermissions: AdminPermissions[]
  mspCidOverrideAdminLevel: MspCidOverrideLevel
}

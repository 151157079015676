import React from 'react'

import { Trans } from '@lingui/macro'
import { AnyAction, Dispatch } from 'redux'

import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { GroupOperationType } from '@lastpass/admin-console-dependencies/state/users/groups/operations/group-operations'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { TableAction } from '@lastpass/admin-console-dependencies/types/record-action'

import { EnumDictionary } from '../EnumValues'

export function groupOperationsMapping<T extends Group>(
  actions: typeof groupOperationActions,
  dispatch: Dispatch<AnyAction>
): EnumDictionary<GroupOperationType, TableAction<T>> {
  return {
    [GroupOperationType.deleteGroup]: {
      name: <Trans>Delete Group</Trans>,
      callback: groups => dispatch(actions.deleteGroups(groups, true))
    }
  }
}

import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Collapsible } from '@lastpass/lastkit/components'
import {
  DetailCell,
  DetailsContainer
} from '@lastpass/lastkit/components/DetailCellContainer'
import { BodyBoldStyle } from '@lastpass/lastkit/styles'

import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'

const StyledButton = styled.button<{ hasContractName?: boolean }>`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.blue700};
  padding: 0;
  outline: none;
  margin-left: ${props => (props.hasContractName ? '5px' : '0px')};
  cursor: pointer;
  border-style: none;
  background: none;
`

const StyledDetailsContainer = styled(DetailsContainer)`
  & > div {
    display: block;
  }
`

export interface PsaIntegrationCardProps {
  isExpanded?: boolean
  managedCompany: ManagedCompany
}

export const PsaIntegrationCardComponent: FunctionComponent<PsaIntegrationCardProps> = ({
  isExpanded,
  managedCompany
}) => {
  const { url } = useRouteMatch()
  const history = useHistory()

  const contractName = managedCompany.psa ? managedCompany.psa.contractName : ''

  const handleContractSelect = () => {
    history.push(`${url}/agreements`)
  }

  return (
    <Collapsible
      title={<Trans>PSA integration</Trans>}
      expanded={isExpanded}
      qadata="PsaIntegrationCard"
    >
      <StyledDetailsContainer data-qa="PsaIntegrationCardContainer">
        <DetailCell
          field={<Trans>PSA company</Trans>}
          value={
            <span data-qa="PsaIntegrationCardCompanyInPsa">
              {managedCompany.name}
            </span>
          }
        />
        <DetailCell
          field={<Trans>Agreement for billing</Trans>}
          value={
            <>
              {contractName && (
                <span data-qa="PsaIntegrationCardAgreementForBilling">
                  {contractName}
                </span>
              )}
              <StyledButton
                onClick={handleContractSelect}
                data-qa="ContractSelectButton"
                hasContractName={!!contractName}
              >
                <Trans>Select</Trans>
              </StyledButton>
            </>
          }
        />
      </StyledDetailsContainer>
    </Collapsible>
  )
}

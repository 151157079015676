import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { saveAs } from 'file-saver'

import { Loading } from '@lastpass/components'
import {
  BodyRegular,
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton
} from '@lastpass/lastkit'

import {
  genericFailedNotification,
  ResponseWithBody
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { SecurityReportEvent } from '@lastpass/admin-console-dependencies/types/security-report'

export interface OnTheFlySecurityReportDrawerProps {
  closeLink: string
  event: SecurityReportEvent
  fetchCSV: () => Promise<ResponseWithBody<'success', string>>
  fileName: string
}

export const OnTheFlySecurityReportDrawer: FC<OnTheFlySecurityReportDrawerProps> = ({
  closeLink,
  event,
  fetchCSV,
  fileName
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleClick = async () => {
    try {
      setIsLoading(true)
      const { body: csv } = await fetchCSV()
      const blobToSave = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      })
      saveAs(blobToSave, fileName)
    } catch {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Drawer
      closeLink={closeLink}
      mainTitle={<>{i18n._(event.riskName)}</>}
      data-qa="OnTheFlySecurityReportDrawer"
    >
      {isLoading && <Loading color="blue900" active />}
      {event.descriptions?.map(description => (
        <BodyRegular
          key={description.message.id}
          data-qa="OnTheFlySecurityReportDrawerDescription"
        >
          {i18n._(description.message)}
        </BodyRegular>
      ))}
      <FooterContainer>
        <DrawerButtonsContainer>
          <PrimaryButton
            data-qa="OnTheFlySecurityReportDrawerButton"
            onClick={handleClick}
          >
            <Trans>Download CSV</Trans>
          </PrimaryButton>
        </DrawerButtonsContainer>
      </FooterContainer>
    </Drawer>
  )
}

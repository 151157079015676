import { takeLatest } from 'redux-saga/effects'

import { createGroupsListSaga } from '@lastpass/admin-console-dependencies/sagas/users/groups/groups-list'
import { createUserListSaga } from '@lastpass/admin-console-dependencies/sagas/users/view/users-list'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { MfaAppsDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaApplicationListActionTypes } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'

import { createAddMfaApplication } from './add-mfa-application'
import { createGetAvailableGroupsSaga } from './get-available-groups'
import { createGetAvailableUsersSaga } from './get-available-users'
import { createMfaApplicationList } from './get-mfa-application-list'
import { createRemoveMfaApplication } from './remove-mfa-application'
import { createSetSelectedMfaApplication } from './set-selected-mfa-application'
import { createUpdateMfaApplication } from './update-mfa-application'

export function createMfaApplicationsSaga(
  applicationService: UACServices.Services
) {
  const getMfaApplicationList = createMfaApplicationList(applicationService)
  const setSelectedMfaApplication = createSetSelectedMfaApplication(
    applicationService,
    createUserListSaga(applicationService),
    createGroupsListSaga(applicationService)
  )
  const addMfaApplicaton = createAddMfaApplication(applicationService)
  const updateMfaApplication = createUpdateMfaApplication(applicationService)
  const removeMfaApplication = createRemoveMfaApplication(applicationService)
  const getUserList = createGetAvailableUsersSaga(
    createUserListSaga(applicationService)
  )
  const getGroupList = createGetAvailableGroupsSaga(
    createGroupsListSaga(applicationService)
  )

  return function*() {
    yield takeLatest(
      MfaApplicationListActionTypes.GET_MFA_APPLICATION_LIST,
      getMfaApplicationList
    )
    yield takeLatest(
      MfaApplicationListActionTypes.SET_SELECTED_APPLICATION,
      setSelectedMfaApplication
    )
    yield takeLatest(MfaAppsDrawerActionTypes.ADD_APP, addMfaApplicaton)
    yield takeLatest(MfaAppsDrawerActionTypes.SAVE_APP, updateMfaApplication)
    yield takeLatest(MfaAppsDrawerActionTypes.REMOVE_APP, removeMfaApplication)
    yield takeLatest(MfaAppsDrawerActionTypes.GET_AVAILABLE_USERS, getUserList)
    yield takeLatest(
      MfaAppsDrawerActionTypes.GET_AVAILABLE_GROUPS,
      getGroupList
    )
  }
}

import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { RequirePasswordlessViaLastPassPolicyKey } from './get-initial-data'

export function createDisablePolicySaga(services: UACServices.Services) {
  return function* disablePolicySaga() {
    const dialog: ConfirmDialog = {
      type: 'confirmdialog',
      title: msg`Disable policy`,
      text: msg`Once disabled, users assigned to this policy will not be required to verify logins with LastPass Authenticator and will use their master password to log in to SSO apps.`,
      discardText: msg`Cancel`,
      confirmText: msg`Disable`
    }

    yield put(globalActions.setDialog(dialog))
    const confirmClick = yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])

    if (confirmClick.type === GlobalActionTypes.DISCARD_DIALOG) {
      return
    }

    try {
      yield put(passwordlessActions.setLoading(true))
      const result: UACServices.GeneralPolicySwitchOFFSaveAPI.Responses = yield call(
        services.generalPolicySwitchOffSave,
        RequirePasswordlessViaLastPassPolicyKey
      )
      const isSuccesfullRequest: boolean =
        result.type === UACServices.GeneralPolicySwitchOFFSaveAPI.SUCCESS
      if (isSuccesfullRequest) {
        yield put(
          passwordlessActions.removePolicy(
            RequirePasswordlessViaLastPassPolicyKey
          )
        )
      }

      if (isSuccesfullRequest) {
        yield put(passwordlessActions.fetchInitialData())
        yield put(
          globalActions.setNotificationSuccess(msg`Policy changes saved.`)
        )
      } else {
        yield put(
          globalActions.setNotificationAlert(
            msg`Policy changes haven't been saved.`
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(passwordlessActions.setLoading(false))
    }
  }
}

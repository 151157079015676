import React from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as SplunkIcon } from '@lastpass/assets/svg/admin-console/siem-integrations/icon-splunk.svg'
import { Heading100, LogoCard } from '@lastpass/lastkit'

import { SplunkIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledCardContainer,
  StyledDescriptionRow,
  StyledExternalLink
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { SplunkForm } from './SplunkForm'

interface SplunkIntegrationCardProps {
  config: SplunkIntegrationConfig
}

export const SplunkIntegrationCard: React.FunctionComponent<SplunkIntegrationCardProps> = ({
  config
}) => (
  <LogoCard
    dataQaName="SiemSplunkCard"
    logo={<SplunkIcon />}
    title={
      <Heading100>
        <Trans>Splunk</Trans>
      </Heading100>
    }
    subtitle={
      <Trans>
        Configure LastPass to send audit events to a Splunk instance in near
        real-time.
      </Trans>
    }
  >
    <StyledCardContainer>
      <StyledDescriptionRow>
        <Trans>LastPass related events will be visible in Splunk.</Trans>
        <StyledExternalLink
          data-qa="SiemSplunkInstructionsLink"
          href="https://link.lastpass.com/integrate-splunk-with-lastpass"
          target="_blank"
        >
          <Trans>View setup instructions</Trans>
        </StyledExternalLink>
      </StyledDescriptionRow>
      <SplunkForm config={config} />
    </StyledCardContainer>
  </LogoCard>
)

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

import { redirectToList } from './operations/helpers/redirect-to-list'

export function createCreateManagedCompanySaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*(action: ReturnType<typeof managedCompaniesActions.create>) {
    const { body } = action.payload

    const successNotificationProps: NotificationProps = {
      type: NotificationType.success,
      message: msg`Company has been created.`,
      autoDismiss: true
    }

    const failureNotificationProps: NotificationProps = {
      type: NotificationType.alert,
      message: msg`Company couldn't be created. Try again later.`,
      autoDismiss: true
    }

    try {
      const result: UACServices.createManagedCompanyAPI.Responses = yield call(
        managedCompaniesServices.createManagedCompany,
        body
      )

      if (result.type === UACServices.createManagedCompanyAPI.SUCCESS) {
        yield put(globalActions.setNotification(successNotificationProps))
        yield call(redirectToList)
        return
      }

      yield put(globalActions.setNotification(failureNotificationProps))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

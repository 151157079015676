import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

import { AdminLevelDrawerActions, AdminLevelDrawerActionTypes } from './actions'
import { AdminLevelDrawerState } from './state'

export const initialState: AdminLevelDrawerState = {
  adminLevel: {
    id: '',
    name: '',
    userCount: 0,
    description: '',
    isCustom: false,
    permissions: [],
    mspCidOverridePermissions: [],
    mspCidOverrideAdminLevel: MspCidOverrideLevel.empty
  },
  users: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  permissionCategories: [],
  managedPermissionCategories: [],
  availableMspCidOverrideAdminLevels: []
}

export function adminLevelDrawerReducer(
  previousState: AdminLevelDrawerState | undefined,
  action: AdminLevelDrawerActions
): AdminLevelDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case AdminLevelDrawerActionTypes.RESET: {
      return initialState
    }
    case AdminLevelDrawerActionTypes.SET_CURRENT_ADMIN_LEVEL: {
      return {
        ...state,
        adminLevel: action.payload.adminLevel
      }
    }
    case AdminLevelDrawerActionTypes.SET_ADMIN_LEVEL_MANAGED_PERMISSIONS: {
      return {
        ...state,
        adminLevel: {
          ...state.adminLevel,
          mspCidOverridePermissions: action.payload.mspCidOverridePermissions
        }
      }
    }
    //ADMIN LEVEL USERS
    case AdminLevelDrawerActionTypes.SET_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          results: action.payload.users,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case AdminLevelDrawerActionTypes.SET_ADMIN_LEVEL_USERS_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          isLoading: action.payload
        }
      }
    }
    case AdminLevelDrawerActionTypes.ADD_SELECTED_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          checkedRecords: state.users.checkedRecords.concat(
            action.payload.userList
          )
        }
      }
    }
    case AdminLevelDrawerActionTypes.REMOVE_SELECTED_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          checkedRecords: state.users.checkedRecords.filter(
            el => !action.payload.userList.includes(el)
          )
        }
      }
    }
    case AdminLevelDrawerActionTypes.SET_SELECTED_ADMIN_LEVEL_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          checkedRecords: action.payload.userList
        }
      }
    }
    case AdminLevelDrawerActionTypes.SET_ADMIN_PERMISSIONS: {
      return {
        ...state,
        permissionCategories: [...action.payload.permissionCategories]
      }
    }
    case AdminLevelDrawerActionTypes.SET_MANAGED_ADMIN_PERMISSIONS: {
      return {
        ...state,
        managedPermissionCategories: [
          ...action.payload.managedPermissionCategories
        ],
        availableMspCidOverrideAdminLevels:
          action.payload.availableMspCidOverrideAdminLevels
      }
    }
  }
  return state
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'
import { downloadReportName as urlsInSharedFoldersReport } from '@lastpass/admin-console-dependencies/ui/reports/security/UrlsInSharedFoldersGeneratedSecurityReportDrawer'
import { downloadReportName as urlsInVaultsReport } from '@lastpass/admin-console-dependencies/ui/reports/security/UrlsInVaultsGeneratedSecurityReportDrawer'

import { useParseSecurityReport } from './hooks/use-parse-security-report'
import { usePrepareDecryptionDataFromCsv } from './hooks/use-prepare-decryption-data-from-csv'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

const content = (
  <StyledParagraph>
    <Trans>
      URL data in this report is encrypted by default. Nobody can read it unless
      decrypted. To decrypt, you’ll need to confirm your master password in the
      next step.
    </Trans>
  </StyledParagraph>
)

const description = msg`Sorry to slow you down, but we need confirmation before allowing you to decrypt URLs.`

export const UrlDecryptionConfirmDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  const controller = new AbortController()

  const { downloadReportName } = useSelector(
    (state: AppState) => state.securityReportDrawer
  )

  const parseKeysCsv = usePrepareDecryptionDataFromCsv()
  const parseSecurityReport = useParseSecurityReport(downloadReportName)

  useEffect(() => {
    if (downloadReportName === urlsInVaultsReport) {
      dispatch(securityReportDrawerActions.getRecoveryKeyDecryptionData())
    }

    if (downloadReportName === urlsInSharedFoldersReport) {
      parseKeysCsv()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelClick = () => {
    dispatch(
      globalActions.setDialog({
        controller,
        type: 'processing-report-dialog'
      })
    )
    parseSecurityReport(controller)
  }

  const handleDecryptionClick = () => {
    dispatch(
      globalActions.setDialog({
        type: 'master-password-confirm-dialog',
        description,
        nextDialog: {
          type: 'url-decryption-progress-dialog'
        }
      })
    )
  }

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      disableCloseOnBackground
      width="634px"
      title={msg`Decrypt URL data in reports?`}
      content={content}
      buttons={[
        <TextButton
          onClick={handleCancelClick}
          key="discard"
          data-qa="UrlDecryptionCancelButton"
        >
          <Trans>Download with encrypted URLs</Trans>
        </TextButton>,
        <PrimaryButton
          onClick={handleDecryptionClick}
          data-qa="UrlDecryptionConfirmButton"
          key="UrlDecryptionConfirmButton"
        >
          <Trans>Decrypt URL data</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'
import { SystemUpgradeDrawerHeadersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SystemUpgradeMapping'

import {
  DetailsBox,
  DrawerContainer,
  LegacyConsolePath,
  SystemUpgradeDrawerFooter,
  SystemUpgradeDrawerProps,
  SystemUpgradeDrawerStyledLink,
  SystemUpgradeFeatureNotAvailable
} from './SystemUpgradeDrawerResources'

export const StyledLink = styled.span`
  text-decoration: underline;
`

const detailsBoxContent = [
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatFor],
    dataQa: 'keysDrawerWhatFeatureForContainer',
    mainContent: (
      <div data-qa="keysDrawerWhatFeatureForContainerInner">
        <Trans>
          SAML keys were unique private keys used for setting up single sign-on
          between LastPass and apps or websites. OAuth keys were used for
          setting up directory integrations between LastPass and your service
          provider to authenticate LastPass users.
        </Trans>
      </div>
    )
  },
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.where],
    dataQa: 'keysDrawerFeatureWhereContainer',
    mainContent: (
      <ul data-qa="keysDrawerFeatureWhereContainerInner">
        <li>
          <Trans>New console</Trans>
          {': '}
          <SystemUpgradeDrawerStyledLink
            url="/advanced/keys"
            dataQa="keysDrawerNewConsoleLink"
          >
            <Trans>Advanced</Trans>
            {' > '}
            <Trans>Keys</Trans>
          </SystemUpgradeDrawerStyledLink>
        </li>
        <li>
          <Trans>Old console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <LegacyConsolePath isNotAvailable={true} />
        </li>
      </ul>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[
        SystemUpgradeDrawerHeader.whatsHappening
      ],
    dataQa: 'keysDrawerFeatureWhatHappeningContainer',
    mainContent: (
      <div data-qa="keysDrawerFeatureWhatHappeningContainerInner">
        <Trans>
          All existing keys, OAuth keys, and SAML keys will be removed from your
          LastPass Admin Console once you upgrade. Deleting an OAuth key will
          invalidate any integration you’ve set up with a service provider
          that&apos;s currently using this OAuth key. Deleting a SAML key will
          invalidate any SSO integration that&apos;s currently using this SAML
          key. Please ensure that an administrator can access these integrations
          without the need for these keys.
        </Trans>
      </div>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatToDo],
    dataQa: 'keysDrawerFeatureWhatToDoContainer',
    mainContent: (
      <div data-qa="keysDrawerFeatureWhatToDoContainerInner">
        <Trans>
          Before completing the upgrade, we recommend considering the impact of
          deprecating the keys on your integrations with service providers.
          These keys will be deleted after the upgrade and there will be no way
          to reproduce the functionality provided by your Existing keys, OAuth
          keys, and SAML keys. There will be one global key in each SSO
          app&apos;s Details view in the Certificate field. If you would like to
          continue using Federated Login with LastPass please follow our quick
          start guide{' '}
          <LocationLink
            to="https://support.lastpass.com/s/document-item?language=en_US&bundleId=lastpass&topicId=LastPass/quick_start_guide_for_federated_admins.html"
            target="_blank"
            data-qa="keysDrawerStartGuideLink"
          >
            <StyledLink>here.</StyledLink>
          </LocationLink>
        </Trans>
      </div>
    )
  }
]

export const KeysDrawer: React.FunctionComponent<SystemUpgradeDrawerProps> = ({
  closeLink
}) => (
  <Drawer
    mainTitle={<Trans>Keys (Existing, OAuth, SAML)</Trans>}
    subTitle={<Trans>Details</Trans>}
    closeLink={closeLink}
    data-qa="keysDrawer"
  >
    <DrawerContainer data-qa="keysDrawerContentContainer">
      {detailsBoxContent.map(content => (
        <DetailsBox
          key={content.dataQa}
          title={content.title}
          data-qa={content.dataQa}
        >
          {content.mainContent}
        </DetailsBox>
      ))}
    </DrawerContainer>
    <SystemUpgradeDrawerFooter
      closeLink={closeLink}
      data-qa="keysDrawerFooter"
    />
  </Drawer>
)

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/get-application-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { applicationListActions } from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createApplicationList(
  applicationsServices: UACServices.Services
) {
  return function* getApplicationList(
    action: ReturnType<typeof applicationListActions.getApplicationList>
  ) {
    try {
      const resultsPerPage = action.payload.resultsPerPage
      const searchQuery = action.payload.params.query.search
      const searchKey = searchQuery ? searchQuery : ''
      const skip = action.payload.params.query.skip
        ? action.payload.params.query.skip
        : 1
      yield put(applicationListActions.setApplicationsLoading(true))
      try {
        const SSOApplicationListResult: Responses = yield call(
          applicationsServices.getSSOApplicationsList,
          searchKey,
          resultsPerPage,
          skip
        )
        if (
          SSOApplicationListResult &&
          SSOApplicationListResult.type ===
            UACServices.SSOApplicationsAPI.SUCCESS
        ) {
          yield put(
            applicationListActions.setApplicationList(
              SSOApplicationListResult.body.applicationsResponse.results,
              SSOApplicationListResult.body.applicationsResponse.total,
              resultsPerPage
            )
          )
        }
      } finally {
        yield put(applicationListActions.setApplicationsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

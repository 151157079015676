import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegularStyle, Heading100 } from '@lastpass/lastkit'
import { CodeExample } from '@lastpass/lastkit/components/CodeExample'

const Title = styled.div`
  ${Heading100};
  margin-bottom: 8px;
`

const TextContent = styled.div`
  ${BodyRegularStyle}
`

export const ExampleUsage: React.FunctionComponent = () => {
  return (
    <>
      <Title>
        <Trans>PowerShell</Trans>
      </Title>
      <CodeExample
        code={` user$ EnterpriseApi.ps1
        usage: EnterpriseApi.ps1 -Cid <cid> -ProvisioningHash <provisioningHash> -Operation [1|2|3] []
            -Cid # the company's LastPass ID
            -ProvisioningHash # the company's provisioning has generated on LastPass Admin Console
            -Operation # a numeric code for the desired operation:
                - 1: getuserdata - The getuserdata command returns information about users in the enterprise.
                - 2: getsfdata - Get Shared Folder Data returns a JSON object containing information on all Shared Folders in the enterprise and the permissions granted to them.
                - 3: reporting - The reporting command is used to gather information on events that have taken place within the Enterprise.`}
        allowCopyToClipboard={true}
      />

      <Title>
        <Trans>Examples</Trans>
      </Title>
      <TextContent>
        <Trans>Getting the userdata</Trans>
      </TextContent>
      <CodeExample
        code={`EnterpriseApi.ps1 -Cid 12507191 -ProvisioningHash 2****2d8 -Operation 1`}
        allowCopyToClipboard={true}
      />

      <TextContent>
        <Trans>
          The response will contain the following data: fullname (user{"'"}s
          full name), username (user{"'"}s LastPass username), last_login (date
          and time of user{"'"}s last login), mpstrength (strength of user{"'"}s
          master password), last_pw_change (date and time of user{"'"}s last
          master password change) Example: Kay Marsh, apiuser1@gmail.com,
          2019-11-11 08:05:40, 0, 2019-10-11 12:29:18
        </Trans>
      </TextContent>
      <br />
      <TextContent>
        <Trans>Getting the shared folder data</Trans>
      </TextContent>
      <CodeExample
        code={`EnterpriseApi.ps1 -Cid 12507191 -ProvisioningHash 2****2d8 -Operation 2`}
        allowCopyToClipboard={true}
      />

      <TextContent>
        <Trans>The response will be in JSON format.</Trans>
      </TextContent>
      <CodeExample
        code={`{
          "270222671": {
              "sharedfoldername": "first",
              "score": 18.4,
              "users": [
                  {
                      "username": "entapi@gmail.com",
                      "readonly": "0",
                      "give": "1",
                      "can_administer": "1"
                  }
              ]
          }
      }`}
        allowCopyToClipboard={true}
      />

      <TextContent>
        <Trans>Getting reporting data</Trans>
      </TextContent>
      <CodeExample
        code={`EnterpriseApi.ps1 -Cid 12507191 -ProvisioningHash 2****2d8 -Operation 3`}
        allowCopyToClipboard={true}
      />

      <TextContent>
        <Trans>
          Please provide the From date for reporting in YYYY-MM-DD format.:
          2019-11-30 The response will be in JSON format.
        </Trans>
      </TextContent>
      <CodeExample
        code={`{
          "status": "OK",
          "next": null,
          "data": {
              "Event1": {
                  "Time": "2020-04-28 06:17:35",
                  "Username": "API",
                  "IP_Address": "34.240.223.15",
                  "Action": "Get User Data",
                  "Data": ""
              },
              "Event1951": {
                  "Time": "2019-12-11 05:42:31",
                  "Username": "API",
                  "IP_Address": "34.245.77.126",
                  "Action": "Reporting",
                  "Data": ""
              }
          }
      }`}
        allowCopyToClipboard={true}
      />

      <Title>
        <Trans>PHP</Trans>
      </Title>
      <CodeExample
        code={`$post['cid']=<YOUR_CID>;
$post['apiuser']='PHP_API';
$post['provhash']='<YOUR_PROV_HASH>';
$post['cmd']='getuserdata';

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, 'https://lastpass.com/enterpriseapi.php');
curl_setopt($ch, CURLOPT_HTTPHEADER, array('Content-Type: application/json'));
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS,json_encode($post));
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
$response  = curl_exec($ch);
curl_close($ch);
echo $response;`}
        allowCopyToClipboard={true}
      />
    </>
  )
}

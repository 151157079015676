import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUserEmail = (state: AppState) =>
  state.global.currentUser.email

export function createSendUserVerificationEmailSaga(
  userService: UACServices.Services
) {
  return function*() {
    const email: string = yield select(getCurrentUserEmail)

    const successNotificationProps: NotificationProps = {
      type: NotificationType.success,
      message: msg`Verification email sent to this account: ${email}`,
      autoDismiss: true
    }

    try {
      const result: UACServices.SendUserVerificationEmailAPI.Responses = yield call(
        userService.sendUserVerificationEmail
      )
      if (result.type === UACServices.SendUserVerificationEmailAPI.SUCCESS) {
        yield put(globalActions.setNotification(successNotificationProps))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

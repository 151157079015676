import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPolicySwitchONSaveAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { DuoSecuritySettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { DuoSecurityModel } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'

export function createSaveDuoSecuritySettings(
  multifactorServices: UACServices.Services
) {
  return function* saveDuoSecuritySettings(
    action: ReturnType<typeof multifactorDrawerActions.saveDuoSecuritySettings>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))

      const duoConfig: DuoSecurityModel = action.payload.duoConfig
      const duoSecuritySettings: DuoSecuritySettingsState = {
        integrationKey: duoConfig.integration_key,
        secretKey: duoConfig.secret_key,
        apiHostName: duoConfig.api_hostname
      }

      const douPolicyData = action.payload.policyData

      try {
        const [
          saveDuoSecSettingsResponse,
          saveDuoSecPolicyDataResponse
        ] = yield all([
          call(
            multifactorServices.duoSecuritySettingsSave,
            duoSecuritySettings
          ),
          douPolicyData
            ? call(multifactorServices.generalPolicySwitchOnSave, douPolicyData)
            : Promise.resolve(GeneralPolicySwitchONSaveAPI.success())
        ])

        if (
          saveDuoSecSettingsResponse.type ===
            UACServices.DuoSecuritySettingsSaveAPI.SUCCESS &&
          saveDuoSecPolicyDataResponse.type ===
            GeneralPolicySwitchONSaveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else if (
          saveDuoSecPolicyDataResponse.type ===
          GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`This policy can't have multiple configurations.`
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

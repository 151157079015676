import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import {
  Drawer,
  DrawerSubtitleContext,
  IconButton,
  RoutedTabNavigationItem,
  TabNavigation
} from '@lastpass/lastkit'

import { capitalizeFirstLetter } from '@lastpass/admin-console-dependencies/services/text-operations/capitalize-first-letter'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import {
  IntegrationLog,
  SystemLog
} from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

import { AuditLogDetails } from './AuditLogDetails'
import { AuditLogList } from './AuditLogList'

interface AuditLogDrawerProps {
  basePath: string
}

enum AuditLogTypes {
  Integration,
  System
}

const TabNavigationContainer = styled.div`
  margin-bottom: 10px;
`

const Container = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`

const generalColumnNames = {
  dateTimeUtc: msg`Date`,
  identity: msg`Identity`,
  status: msg`State`
}

const auditLogConfig = {
  [AuditLogTypes.Integration]: {
    fetchLogsAction: directoriesActions.fetchGoogleAuditLogList,
    subtitle: <Trans>User activity log details</Trans>,
    columns: ['dateTimeUtc', 'identity', 'eventType', 'status'],
    columnsInDetails: [
      'dateTimeUtc',
      'status',
      'failureReason',
      'eventType',
      'identity',
      'details'
    ],
    columnNames: {
      ...generalColumnNames,
      failureReason: msg`Fail reason`,
      eventType: msg`Action`,
      details: msg`Details`
    }
  },
  [AuditLogTypes.System]: {
    fetchLogsAction: directoriesActions.fetchSystemLogList,
    subtitle: <Trans>System activity log details</Trans>,
    columns: ['dateTimeUtc', 'eventType', 'status'],
    columnsInDetails: ['dateTimeUtc', 'status', 'eventType', 'logMessage'],
    columnNames: {
      ...generalColumnNames,
      logMessage: msg`Fail reason`,
      eventType: msg`Event`
    }
  }
}

const extendedEventNames = {
  add: <Trans>User added</Trans>,
  delete: <Trans>User deleted</Trans>
}

const integrationLogsSubtitle = <Trans>Integration logs</Trans>

export const formatDate = timestamp =>
  moment(timestamp).format('MM/DD/YY h:mma')

export const renderValue = (column: string, value: string) => {
  switch (column) {
    case 'dateTimeUtc':
      return formatDate(value)
    case 'eventType':
      return extendedEventNames[value] || capitalizeFirstLetter(value)
    case 'status':
      return capitalizeFirstLetter(value)
    case 'details':
      return value ? (
        <>
          {value.split('\n').map(part => (
            <>
              {part}
              <br />
            </>
          ))}
        </>
      ) : (
        value
      )
    default:
      return value
  }
}

const getField = (
  column: string,
  columnNames: { [key: string]: MessageDescriptor },
  log: IntegrationLog | SystemLog
) => ({
  id: column,
  name: i18n._(columnNames[column]),
  value: renderValue(column, log[column])
})

const getTableColumn = (
  columnKey: string,
  columnNames: { [key: string]: MessageDescriptor }
) => ({
  name: <>{i18n._(columnNames[columnKey])}</>,
  renderer: (record: IntegrationLog | SystemLog) =>
    renderValue(columnKey, record[columnKey])
})

const Subtitle: React.FunctionComponent<{ subtitle: ReactElement }> = ({
  subtitle
}) => {
  const { setSubtitle } = useContext(DrawerSubtitleContext)

  useEffect(() => {
    setSubtitle(subtitle)
  }, [setSubtitle, subtitle])

  return null
}

export const AuditLogDrawer: React.FunctionComponent<AuditLogDrawerProps> = ({
  basePath
}) => {
  const location = useLocation()
  const [auditLogType, setAuditLogType] = useState(
    location.pathname.endsWith('integration-logs')
      ? AuditLogTypes.Integration
      : AuditLogTypes.System
  )

  const dispatch = useDispatch()
  const {
    selectedAuditLog,
    logs,
    numberOfItems,
    auditLogListFetchFailed,
    isAuditLogListLoading
  } = useSelector((state: AppState) => state.integrations.AuditLog)

  const {
    subtitle,
    columns,
    columnsInDetails,
    columnNames,
    fetchLogsAction
  } = auditLogConfig[auditLogType]

  const fields = useMemo(
    () =>
      selectedAuditLog
        ? columnsInDetails
            .filter(
              column =>
                Boolean(selectedAuditLog[column]) ||
                (column !== 'failureReason' && column !== 'details')
            )
            .map(column => getField(column, columnNames, selectedAuditLog))
        : [],
    [columnNames, columnsInDetails, selectedAuditLog]
  )

  const tableColumns = useMemo(
    () => columns.map(columnKey => getTableColumn(columnKey, columnNames)),
    [columnNames, columns]
  )

  const urls = {
    [AuditLogTypes.Integration]: `${basePath}/audit-log/integration-logs`,
    [AuditLogTypes.System]: `${basePath}/audit-log/system-logs`
  }

  const clearSelectedAuditLog = useCallback(() => {
    dispatch(directoriesActions.setLogDetails(null))
  }, [dispatch])

  useEffect(() => {
    return clearSelectedAuditLog
  }, [clearSelectedAuditLog])

  const backButton = (
    <IconButton
      icon={ChevronLeft}
      data-qa={'BackButton'}
      onClick={() => {
        clearSelectedAuditLog()
      }}
    />
  )

  const auditLogDetails = <AuditLogDetails fields={fields} />

  const auditLogList =
    auditLogType === AuditLogTypes.Integration ? (
      <AuditLogList
        columns={tableColumns}
        fetchLogsAction={fetchLogsAction}
        hasError={auditLogListFetchFailed}
        isLoading={isAuditLogListLoading}
        logs={logs as IntegrationLog[]}
        numberOfItems={numberOfItems}
        searchDisabled={false}
      />
    ) : (
      <AuditLogList
        columns={tableColumns}
        fetchLogsAction={fetchLogsAction}
        hasError={auditLogListFetchFailed}
        isLoading={isAuditLogListLoading}
        logs={logs as SystemLog[]}
        numberOfItems={numberOfItems}
        searchDisabled={true}
      />
    )

  const navigation = (
    <TabNavigationContainer>
      <TabNavigation>
        <RoutedTabNavigationItem
          data-qa="IntegrationLogTab"
          route={{
            path: urls[AuditLogTypes.Integration],
            exact: false
          }}
          to={urls[AuditLogTypes.Integration]}
          onClick={() => setAuditLogType(AuditLogTypes.Integration)}
        >
          <Trans>User activity</Trans>
        </RoutedTabNavigationItem>
        <RoutedTabNavigationItem
          data-qa="SystemLogTab"
          route={{
            path: urls[AuditLogTypes.System],
            exact: false
          }}
          to={urls[AuditLogTypes.System]}
          onClick={() => setAuditLogType(AuditLogTypes.System)}
        >
          <Trans>System activity</Trans>
        </RoutedTabNavigationItem>
      </TabNavigation>
    </TabNavigationContainer>
  )

  return (
    <Drawer
      mainTitle={<Trans>Google Workspace</Trans>}
      headerNodes={selectedAuditLog && backButton}
      closeLink={basePath}
      subTitle={integrationLogsSubtitle}
    >
      <Subtitle
        subtitle={selectedAuditLog ? subtitle : integrationLogsSubtitle}
      />
      {selectedAuditLog && auditLogDetails}
      <Container hidden={Boolean(selectedAuditLog)}>
        {navigation}
        {auditLogList}
      </Container>
    </Drawer>
  )
}

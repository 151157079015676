import React, { ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ProviderLogo } from '@lastpass/components'

import { MultifactorProvider } from '@lastpass/admin-console-dependencies/types/multifactor-provider'

import { EnumDictionary } from '../EnumValues'

const LogoContainer = styled.div`
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & img,
  & svg {
    margin-bottom: -5px;
    height: 24px;
  }
  & span {
    vertical-align: text-bottom;
  }
`

export const MultifactorProviderMapping: EnumDictionary<
  MultifactorProvider,
  ReactElement
> = {
  [MultifactorProvider.DUO]: <Trans>Duo Security</Trans>,
  [MultifactorProvider.FINGERPRINT]: <Trans>Multifactor</Trans>,
  [MultifactorProvider.GOOGLE_AUTHENTICATOR]: (
    <Trans>Google Authenticator</Trans>
  ),
  [MultifactorProvider.GRID]: <Trans>Grid</Trans>,
  [MultifactorProvider.LASTPASS_AUTHENTICATOR]: (
    <Trans>LastPass Authenticator</Trans>
  ),
  [MultifactorProvider.LASTPASS_MFA]: <Trans>LastPass MFA</Trans>,
  [MultifactorProvider.MICROSOFT_AUTHENTICATOR]: (
    <Trans>Microsoft Authenticator</Trans>
  ),
  [MultifactorProvider.SALESFORCE]: <Trans>Salesforce Authenticator</Trans>,
  [MultifactorProvider.SECURE_AUTH]: <Trans>Multifactor</Trans>,
  [MultifactorProvider.SECURID]: <Trans>Radius</Trans>,
  [MultifactorProvider.SESAME]: <Trans>Sesame</Trans>,
  [MultifactorProvider.SYMANTEC_VIP]: <Trans>Symantec VIP</Trans>,
  [MultifactorProvider.TOOPHER]: <Trans>Toopher</Trans>,
  [MultifactorProvider.TRANSAKT]: <Trans>Transakt</Trans>,
  [MultifactorProvider.YUBIKEY]: <Trans>YubiKey</Trans>,
  [MultifactorProvider.NO_MULTIFATOR]: <></>
}

export const MultifactorProviderIconMapping: EnumDictionary<
  MultifactorProvider,
  ReactElement
> = {
  [MultifactorProvider.DUO]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.DUO} />
      <span>
        <Trans>Duo Security</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.FINGERPRINT]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.FINGERPRINT} />
      <span>
        <Trans>Multifactor</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.GOOGLE_AUTHENTICATOR]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.GOOGLE_AUTHENTICATOR} />
      <span>
        <Trans>Google Authenticator</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.GRID]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.GRID} />
      <span>
        <Trans>Grid</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.LASTPASS_AUTHENTICATOR]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.LASTPASS_AUTHENTICATOR} />
      <span>
        <Trans>LastPass Authenticator</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.LASTPASS_MFA]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.LASTPASS_MFA} />
      <span>
        <Trans>LastPass MFA</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.MICROSOFT_AUTHENTICATOR]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.MICROSOFT_AUTHENTICATOR} />
      <span>
        <Trans>Microsoft Authenticator</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.SALESFORCE]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.SALESFORCE} />
      <span>
        <Trans>Salesforce Authenticator</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.SECURE_AUTH]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.SECURE_AUTH} />
      <span>
        <Trans>Multifactor</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.SECURID]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.SECURID} />
      <span>
        <Trans>Radius</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.SESAME]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.SESAME} />
      <span>
        <Trans>Sesame</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.SYMANTEC_VIP]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.SYMANTEC_VIP} />
      <span>
        <Trans>Symantec VIP</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.TOOPHER]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.TOOPHER} />
      <span>
        <Trans>Toopher</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.TRANSAKT]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.TRANSAKT} />
      <span>
        <Trans>Transakt</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.YUBIKEY]: (
    <LogoContainer>
      <ProviderLogo type={MultifactorProvider.YUBIKEY} />
      <span>
        {' '}
        <Trans>YubiKey</Trans>
      </span>
    </LogoContainer>
  ),
  [MultifactorProvider.NO_MULTIFATOR]: <LogoContainer />
}

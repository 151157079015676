import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/legacyvpn/get-legacyvpn-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { legacyVpnListActions } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createLegacyVpnList(legacyVpnServices: UACServices.Services) {
  return function* getApplicationList() {
    try {
      const LegacyVpnListResult: Responses = yield call(
        legacyVpnServices.getLegacyVpnList
      )
      if (LegacyVpnListResult.type === UACServices.LegacyVpnListAPI.SUCCESS) {
        yield put(
          legacyVpnListActions.setLegacyVpnListWithTracking(
            LegacyVpnListResult.body.legacyVpnListResponse.Value
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, FieldProps, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as InformationIcon } from '@lastpass/assets/svg/admin-console/icon-information-blue.svg'
import {
  BodyRegularStyle,
  Checkbox,
  Heading100,
  RadioButton,
  Select
} from '@lastpass/lastkit'

import { CreateManagedCompanyBody } from '@lastpass/admin-console-dependencies/server/home/managed-companies/create-managed-company'
import { capitalizeFirstLetter } from '@lastpass/admin-console-dependencies/services/text-operations/capitalize-first-letter'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import {
  CompanyTemplateOptions,
  CompanyTemplateTypes
} from '@lastpass/admin-console-dependencies/types/managed-company'

const SettingsDescriptionContainer = styled.div`
  ${BodyRegularStyle}
  margin-top: 16px;
`

const FieldContainer = styled.div`
  margin-top: 16px;
`

const StyledHeading = styled(Heading100)`
  margin-top: 24px;
`

const RadioButtonContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  span {
    vertical-align: middle;
  }
  label {
    display: block;
  }
`

const StyledSelect = styled.div`
  width: 320px;
  margin-left: 24px;
`
const StyledOptionTitle = styled.div`
  ${BodyRegularStyle}
  margin-top: 16px;
  padding-left: 24px;
`

const StyledInformationIconContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 13px;
  line-height: 18px;
  margin: 16px 0 136px;
  padding: 8px 12px 8px 44px;
  background-color: #e8f3ff;
  border: 1px solid #007aff;
  border-radius: 8px;
`

const StyledInformationIcon = styled(InformationIcon)`
  position: absolute;
  top: 16px;
  left: 12px;
  color: #007aff;
  width: 20px;
  height: 20px;
`

const CheckboxesContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
`

const CheckboxContainer = styled.div`
  margin-top: 16px;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 1;
`

const StyledLabel = styled.label`
  ${BodyRegularStyle}
`

interface CompanyCopyFormProps {
  formikProps: FormikProps<CreateManagedCompanyBody>
}

type CompanyType = {
  label: string
  value: string
}

export const CompanyCopyForm: FunctionComponent<CompanyCopyFormProps> = ({
  formikProps
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(managedCompaniesActions.getCompanyTemplates())
  }, [dispatch])

  const [templateOptions, setTemplateOptions] = useState<
    CompanyTemplateOptions[]
  >([])

  const managedCompanyList = useSelector(
    (state: AppState) => state.managedCompanies.table.results
  )

  const managedCompanies: CompanyType[] = managedCompanyList.map(
    ({ id, name }) => ({
      label: name,
      value: id.toString()
    })
  )

  const copyTemplateSettings = useSelector(
    (state: AppState) => state.managedCompanies.companyTemplateSettings
  )

  const copyTemplateOptions = copyTemplateSettings.length
    ? copyTemplateSettings[0].options
    : []

  const copyTemplateOptionsMap = {
    [CompanyTemplateOptions.policy]: <Trans>Policies</Trans>,
    [CompanyTemplateOptions.group]: <Trans>Groups</Trans>,
    [CompanyTemplateOptions.adminLevel]: <Trans>Admin levels</Trans>
  }

  return (
    <>
      <StyledHeading>
        <Trans>Company settings</Trans>
      </StyledHeading>

      <SettingsDescriptionContainer>
        <Trans>
          Use either default settings or apply them from an existing managed
          company with similar requirements.
        </Trans>
      </SettingsDescriptionContainer>

      <FieldContainer data-qa="CreateManagedCompanySettingsContainer">
        <>
          <RadioButtonContainer>
            <Field name="companyTemplateSettings.type">
              {({ field }) => {
                return (
                  <div data-qa="CreateManagedCompanySettingsRadioDefault">
                    <RadioButton
                      option={{
                        value: CompanyTemplateTypes.default,
                        label: msg`Default`
                      }}
                      key="company-template-default"
                      checked={field.value === CompanyTemplateTypes.default}
                      onChange={() => {
                        setTemplateOptions([])
                        formikProps.setFieldValue(
                          'companyTemplateSettings.type',
                          CompanyTemplateTypes.default
                        )
                        formikProps.setFieldValue(
                          'companyTemplateSettings.sourceId',
                          null
                        )
                      }}
                    />
                  </div>
                )
              }}
            </Field>

            <Field name="companyTemplateSettings.type">
              {({ field }) => {
                return (
                  <div data-qa="CreateManagedCompanySettingsRadioExisting">
                    <RadioButton
                      option={{
                        value: CompanyTemplateTypes.copyCompany,
                        label: msg`Apply from existing company`
                      }}
                      key="company-template-existing"
                      checked={field.value === CompanyTemplateTypes.copyCompany}
                      onChange={() => {
                        setTemplateOptions(copyTemplateOptions)
                        formikProps.setFieldValue(
                          'companyTemplateSettings.type',
                          CompanyTemplateTypes.copyCompany
                        )
                      }}
                    />
                  </div>
                )
              }}
            </Field>
          </RadioButtonContainer>

          <Field name="companyTemplateSettings.sourceId">
            {({ field }) => {
              return (
                <StyledSelect
                  onClick={() => {
                    formikProps.setFieldValue(
                      'companyTemplateSettings.type',
                      CompanyTemplateTypes.copyCompany
                    )
                    setTemplateOptions(copyTemplateOptions)
                  }}
                >
                  <Select
                    value={
                      formikProps.values.companyTemplateSettings?.type !==
                        CompanyTemplateTypes.default &&
                      formikProps.values.companyTemplateSettings?.sourceId !==
                        null
                        ? managedCompanies.find(
                            option => option.value === field.value
                          )
                        : { label: '', value: '' }
                    }
                    dataQa="CreateManagedCompanySettingsSelector"
                    options={managedCompanies}
                    fluid
                    isSearchable
                    placeholder={i18n._(msg`Search companies...`)}
                    onChange={selectedItem => {
                      formikProps.setFieldValue(
                        'companyTemplateSettings.sourceId',
                        (selectedItem as CompanyType).value
                      )
                    }}
                  />
                </StyledSelect>
              )
            }}
          </Field>
        </>

        {!!templateOptions.length && (
          <StyledOptionTitle>
            <Trans>Apply these items:</Trans>
          </StyledOptionTitle>
        )}

        <CheckboxesContainer data-qa="CheckboxesContainer">
          {templateOptions.map(optionId => {
            return (
              <CheckboxContainer
                key={optionId}
                data-qa={`CreateManagedCompanySettingsCheckbox${capitalizeFirstLetter(
                  optionId
                )}`}
              >
                <Field name="companyTemplateSettings.options">
                  {(formProps: FieldProps) => (
                    <Checkbox
                      id={optionId}
                      name={formProps.field.name}
                      checked={formProps.field.value.includes(optionId)}
                      onChange={e => {
                        if (e.currentTarget.checked) {
                          formikProps.setFieldValue(formProps.field.name, [
                            ...formProps.field.value,
                            optionId
                          ])
                        } else {
                          const options = formProps.field.value.filter(
                            option => option !== optionId
                          )
                          formikProps.setFieldValue(
                            formProps.field.name,
                            options
                          )
                        }
                      }}
                    >
                      <StyledLabel>
                        {copyTemplateOptionsMap[optionId]}
                      </StyledLabel>
                    </Checkbox>
                  )}
                </Field>
              </CheckboxContainer>
            )
          })}
        </CheckboxesContainer>

        <StyledInformationIconContainer>
          <StyledInformationIcon />
          <Trans>
            Security tip: Before rollout, make sure the applied policies and
            settings meet the needs of the new company.
          </Trans>
        </StyledInformationIconContainer>
      </FieldContainer>
    </>
  )
}

import React, { FC } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { StyledSpacer } from '@lastpass/lastkit'
import { AnimatedSwitch, LocationRedirect } from '@lastpass/routing'
import { RouteFade } from '@lastpass/routing/animations'

import { AddUsersDrawerForm } from '@lastpass/admin-console-dependencies/ui/users/view/add/components/AddUsersDrawerForm'
import { AddUsersDrawerTable } from '@lastpass/admin-console-dependencies/ui/users/view/add/components/AddUsersDrawerTable'
import { ProvisioningSection } from '@lastpass/admin-console-dependencies/ui/users/view/ProvisioningSection'

interface AddUsersDrawerPageProps {
  cancelLink: string
}

export const AddUsersDrawerPage: FC<AddUsersDrawerPageProps> = ({
  cancelLink
}) => {
  const match = useRouteMatch()
  if (match) {
    const { url, path } = match
    return (
      <AnimatedSwitch timeout={200}>
        <Route path={path}>
          <RouteFade>
            <AddUsersDrawerTable />
            <AddUsersDrawerForm cancelLink={cancelLink} />
            <ProvisioningSection
              ADSyncClientLink={'/users/directories'}
              ProvisioningAPILink={
                defaultConfig.lastPassBaseAddress + '/enterprise_apidoc.php'
              }
            />
            <StyledSpacer />
          </RouteFade>
        </Route>
        <Route>
          <LocationRedirect to={url} />
        </Route>
      </AnimatedSwitch>
    )
  }
  return null
}

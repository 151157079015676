import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import {
  BodyRegularStyle,
  Checkbox,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationInstructionsDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface KeyRotationInstructionsDialogProps {
  state: KeyRotationInstructionsDialog
  actions: typeof globalActions
}

const CheckBoxContainer = styled.div`
  margin-top: 20px;
`
const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.blue700};
`

export const KeyRotationInstructionsDialogComponent: FunctionComponent<KeyRotationInstructionsDialogProps> = ({
  state,
  actions
}) => {
  const dispatch = useDispatch()
  const [isChecked, setIsChecked] = useState<boolean>(false)

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={state.title}
      subTitle={state.subtitle}
      disableCloseOnBackground
      content={
        <>
          {state.contentText && (
            <TextBoxContainer>{i18n._(state.contentText)}</TextBoxContainer>
          )}
          <StyledExternalLink
            href="https://link.lastpass.com/rotate-knowledge-components-azure-ad"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Follow these instructions</Trans>
          </StyledExternalLink>
          <CheckBoxContainer>
            <Checkbox
              checked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
              data-qa="KeyRotationCheckbox"
            >
              {i18n._(state.checkboxText)}
            </Checkbox>
          </CheckBoxContainer>
        </>
      }
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(globalActions.discardDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            actions.confirmDialog()
          }}
          type="submit"
          key="confirm"
          disabled={!isChecked}
        >
          <Trans>Continue</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

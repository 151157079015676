import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton,
  TextInput,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { LocationLink, LocationRedirect } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  flex-basis: calc(50% - 12px);
  margin-right: 12px;
  margin-bottom: 28px;

  &:nth-of-type(2n) {
    flex-basis: calc(50% - 12px);
    margin-right: 0;
    margin-left: 12px;
  }
`

export const EditProfileDrawerPage: React.FunctionComponent = () => {
  const pathParams = usePathParams()
  const queryParams = useQueryParams(Namespace.users)
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const usersDrawerState: UsersDrawerState = useSelector(
    (state: AppState) => state.usersDrawer
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(userDrawerActions.getUserProfile({ query: {}, path: pathParams }))
  }, [dispatch, pathParams])

  useDrawerSubtitle(<Trans>Edit profile</Trans>)
  const id = pathParams.id

  const [profileSubmitted, setProfileSubmitted] = useState(false)

  if (usersDrawerState.loadingProfile || usersDrawerState.profile.id === '') {
    return <Loading color={'blue900'} active={true} />
  }

  const yupSchema = Yup.object().shape({
    secondaryEmail: Yup.string()
      .nullable(true)
      .email(i18n._(msg`Invalid email format`))
  })

  return (
    <Formik
      enableReinitialize
      initialValues={usersDrawerState.profile}
      onSubmit={values => {
        dispatch(
          userDrawerActions.saveUserProfile(values, id ?? '', {
            path: pathParams,
            query: queryParams
          })
        )
        setIsBlockingPromptEnabled(false)
        setProfileSubmitted(true)
      }}
      validationSchema={yupSchema}
    >
      {formikProps => {
        return (
          <Form>
            {profileSubmitted && <LocationRedirect to={`/users/view/${id}`} />}
            <FormContainer
              data-qa="EditProfileContainer"
              onChange={() => setIsBlockingPromptEnabled(true)}
            >
              <InputContainer>
                <TextInput
                  readOnly
                  name="email"
                  value={formikProps.values.email}
                  placeholder={msg`Email`}
                  data-qa="EditProfileEmail"
                >
                  <Trans>Email</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="secondaryEmail"
                  value={formikProps.values.secondaryEmail}
                  placeholder={msg`Secondary email`}
                  error={!!formikProps.errors.secondaryEmail}
                  errorText={<>{formikProps.errors.secondaryEmail}</>}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileSecondaryEmail"
                >
                  <Trans>Secondary email</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="firstName"
                  value={formikProps.values.firstName}
                  placeholder={msg`First name`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileFirstName"
                >
                  <Trans>First name</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="lastName"
                  value={formikProps.values.lastName}
                  placeholder={msg`Last name`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileLastName"
                >
                  <Trans>Last name</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="phoneNumber"
                  value={formikProps.values.phoneNumber}
                  placeholder={msg`Phone`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfilePhone"
                >
                  <Trans>Phone</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="department"
                  value={formikProps.values.department}
                  placeholder={msg`Department`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileDepartment"
                >
                  <Trans>Department</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="userId"
                  value={formikProps.values.userId}
                  placeholder={msg`User ID`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileUserId"
                >
                  <Trans>User ID</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="description"
                  value={formikProps.values.description}
                  placeholder={msg`Description`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileDescription"
                >
                  <Trans>Description</Trans>
                </TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="samAccountName"
                  value={formikProps.values.samAccountName}
                  placeholder={msg`SAML account name`}
                  onChange={formikProps.handleChange}
                  data-qa="EditProfileAccountName"
                >
                  <Trans>SAML account name</Trans>
                </TextInput>
              </InputContainer>
            </FormContainer>
            <StyledSpacer />
            <FooterContainer>
              <DrawerButtonsContainer>
                <LocationLink to={`/users/view/${id}`}>
                  <TextButton
                    css={css`
                      margin-right: 10px;
                    `}
                    data-qa="DiscardChangesButton"
                  >
                    <Trans>Discard</Trans>
                  </TextButton>
                </LocationLink>

                <PrimaryButton
                  disabled={!!Object.keys(formikProps.errors).length}
                  onClick={() => {
                    formikProps.handleSubmit()
                  }}
                  type="submit"
                  data-qa="SaveChangesButton"
                >
                  <Trans>Save changes</Trans>
                </PrimaryButton>
              </DrawerButtonsContainer>
            </FooterContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { HeaderContainer, PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { AdvancedUserActivityDeleteButton } from '@lastpass/admin-console-dependencies/ui/reports/view/components/AdvancedUserActivityDeleteButton'
import { AdvancedUserActivityExportButton } from '@lastpass/admin-console-dependencies/ui/reports/view/components/AdvancedUserActivityExportButton'
import { AdvancedUserActivityMainHeader } from '@lastpass/admin-console-dependencies/ui/reports/view/components/AdvancedUserActivityMainHeader'

import { ReportsViewPage } from './view/AdvancedReportsViewPage'

const PageHeaderContainer = styled(HeaderContainer)`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

export const AdvancedGeneralReportsPage: React.FunctionComponent = () => {
  const routeMatch = useRouteMatch()

  if (!routeMatch) {
    return null
  }

  const { path } = routeMatch
  return (
    <>
      <PageHeaderContainer data-qa="PageHeaderContainer">
        <AdvancedUserActivityMainHeader
          defaultHeader={i18n._(msg`General reports`)}
        />
        <div>
          <AdvancedUserActivityDeleteButton />
          <AdvancedUserActivityExportButton />
          <LocationLink to={`${path}/filter/new`}>
            <PrimaryButton data-qa={'NewReportButton'}>
              <Trans>New report</Trans>
            </PrimaryButton>
          </LocationLink>
        </div>
      </PageHeaderContainer>
      <ReportsViewPage />
    </>
  )
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPolicySwitchONSaveAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { AssignState } from '@lastpass/admin-console-dependencies/state/common/assign/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  SaveTeamsPolicySwitchOnData,
  TeamsPolicyModelData
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getAdvancedPolicyDrawerState = (
  state: AppState
): AdvancedPolicyDrawerState => state.advancedPolicyDrawer

export const getAssignState = (state: AppState): AssignState => state.assign

const getPolicyData = (state: {
  drawer: AdvancedPolicyDrawerState
  assign: AssignState
}): TeamsPolicyModelData => {
  return {
    appliesTo: state.assign.appliesTo,
    policyValue: state.drawer.policyValue ? state.drawer.policyValue : '',
    policyNotes: state.drawer.policyNotes ? state.drawer.policyNotes : '',
    cpid: null,
    exclusiveList:
      state.assign.appliesTo === AppliesToOptions.exclusive
        ? state.assign.users.list.results
        : [],
    inclusiveList:
      state.assign.appliesTo === AppliesToOptions.inclusive
        ? state.assign.users.list.results
        : [],
    exclusiveGroupList:
      state.assign.appliesTo === AppliesToOptions.exclusive
        ? state.assign.groups.list.results
        : [],
    inclusiveGroupList:
      state.assign.appliesTo === AppliesToOptions.inclusive
        ? state.assign.groups.list.results
        : []
  }
}

export function createSaveAdvancedPolicy(
  multifactorServices: UACServices.Services
) {
  return function* saveAdvancedPolicy(
    action: ReturnType<typeof advancedPolicyDrawerActions.saveAdvancedPolicy>
  ) {
    try {
      const state: {
        drawer: AdvancedPolicyDrawerState
        assign: AssignState
      } = {
        drawer: yield select(getAdvancedPolicyDrawerState),
        assign: yield select(getAssignState)
      }

      if (state.drawer.policy === null) {
        yield put(globalActions.setNotification(genericFailedNotification))
        return
      }

      const policyData: TeamsPolicyModelData = getPolicyData(state)

      let policyDatas = state.drawer.policy.policyData
      if (typeof state.drawer.dataIndex !== 'undefined') {
        const dataToRemove = policyDatas[state.drawer.dataIndex]
        policyData.cpid = dataToRemove.cpid
        policyDatas = policyDatas
          .filter(pd => pd !== dataToRemove)
          .concat(policyData)
      } else {
        policyDatas = policyDatas.concat(policyData)
      }

      const policy: SaveTeamsPolicySwitchOnData = {
        policyKey: state.drawer.policy.policyKey,
        policyData: policyDatas
      }

      try {
        const savePolicyUsersResponse = yield call(
          multifactorServices.generalPolicySwitchOnSave,
          policy
        )

        if (
          savePolicyUsersResponse.type === GeneralPolicySwitchONSaveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else if (
          savePolicyUsersResponse.type ===
          GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`This policy can't have multiple configurations.`
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

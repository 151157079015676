import { articleIds } from '@lastpass/admin-console-dependencies/resources/article-ids'
import { articleIdsDictionary } from '@lastpass/admin-console-dependencies/resources/article-ids-dictionary'

import { getFirstWord } from '../text-operations/get-first-word'
import { DefaultSsoAppSupportUrl } from './constants'

export function createSupportLink(appName: string) {
  if (!appName) {
    return DefaultSsoAppSupportUrl
  }

  const firstWord: string = getFirstWord(appName).toLowerCase()

  if (articleIds.includes(firstWord)) {
    return `https://support.logmeininc.com/lastpass/help#lastpass-${firstWord}`
  } else if (articleIdsDictionary[appName]) {
    return `https://support.logmeininc.com/lastpass/help#lastpass-${articleIdsDictionary[appName]}`
  } else {
    return DefaultSsoAppSupportUrl
  }
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { Heading100, PrimaryButton } from '@lastpass/lastkit'
import { BodyRegularStyle, BodySemiboldStyle } from '@lastpass/lastkit/styles'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { neverOnlyUrlActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/neveronlyurls/actions'
import { NeverOnlyUrlsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/neveronlyurls/state'

const MarginPaddingStyle = css`
  display: flex;
  align-items: center;
`

const HeaderContainer = styled.div`
  ${MarginPaddingStyle}
  letter-spacing: 0.24px;
  margin-top: 32px;
`
const StyledSubTitle = styled.div`
  ${BodyRegularStyle}
  ${MarginPaddingStyle}
  margin: 10px 0 20px 0px;
  width: 760px;
`

const StyledSubHeading = styled.div`
   ${BodySemiboldStyle}
   ${MarginPaddingStyle}
   color:${props => props.theme.colors.neutral600};

`
const TextBoxContainer = styled.div`
  ${MarginPaddingStyle}
  margin-bottom: 20px;
`
const StyledTextArea = styled.textarea`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 540px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
  height: 200px;
`

const Container = styled.div`
  margin-left: 24px;
`

const StyledInputContainer = styled.div`
  margin-right: 40px;
`

export const NeverOnlyUrls: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const neverOnlyUrlsState: NeverOnlyUrlsState = useSelector(
    (state: AppState) => state.neverOnlyUrls
  )

  useEffect(() => {
    dispatch(neverOnlyUrlActions.getNeverOnlyUrls())
  }, [dispatch])

  const [formTouched, setFormTouched] = useState(false)
  return (
    <>
      <Container>
        <HeaderContainer>
          <Heading100>
            <Trans>Global never/only URLs</Trans>
          </Heading100>
        </HeaderContainer>
        <StyledSubTitle>
          <Trans>
            Use global never URLs to prevent LastPass from interacting with the
            specified sites or domains. Use global only URLs to allow LastPass
            to interact only with the specified domains and no others. Enter
            URLs or domains, separated by commas or new lines.
          </Trans>
        </StyledSubTitle>
        {neverOnlyUrlsState.isLoading && (
          <Loading color={'blue900'} active={true} />
        )}
        <Formik
          enableReinitialize
          initialValues={{
            neverUrls: neverOnlyUrlsState.neverOnlyUrls.neverUrls,
            onlyUrls: neverOnlyUrlsState.neverOnlyUrls.onlyUrls
          }}
          onSubmit={values => {
            setIsBlockingPromptEnabled(false)
            dispatch(neverOnlyUrlActions.saveNeverOnlyUrls(values))
          }}
        >
          {formikProps => {
            return (
              <Form>
                <TextBoxContainer>
                  <Field name="neverUrls">
                    {formData => {
                      return (
                        <StyledInputContainer>
                          <StyledSubHeading>
                            <Trans>Global never URLs and apps</Trans>
                          </StyledSubHeading>
                          <StyledTextArea
                            data-qa="NeverUrlTextBox"
                            name={formData.field.name}
                            value={formData.field.value}
                            onChange={e => {
                              setIsBlockingPromptEnabled(true)
                              formData.field.onChange(e)
                              setFormTouched(true)
                            }}
                          />
                        </StyledInputContainer>
                      )
                    }}
                  </Field>
                  <Field name="onlyUrls">
                    {formData => {
                      return (
                        <div>
                          <StyledSubHeading>
                            <Trans>Global only URLs and apps</Trans>
                          </StyledSubHeading>
                          <StyledTextArea
                            data-qa="OnlyUrlTextBox"
                            name={formData.field.name}
                            value={formData.field.value}
                            onChange={e => {
                              formData.field.onChange(e)
                              setFormTouched(true)
                            }}
                          />
                        </div>
                      )
                    }}
                  </Field>
                </TextBoxContainer>
                <PrimaryButton
                  data-qa="UpdateButton"
                  disabled={neverOnlyUrlsState.isLoading || !formTouched}
                  onClick={() => {
                    formikProps.handleSubmit()
                  }}
                  type="submit"
                >
                  <Trans>Update</Trans>
                </PrimaryButton>
              </Form>
            )
          }}
        </Formik>
      </Container>
    </>
  )
}

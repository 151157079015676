import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { addNewGroupActions } from '@lastpass/admin-console-dependencies/state/users/groups/add/actions'
import { groupListActions } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { GroupNameErrorMessages } from '@lastpass/admin-console-dependencies/types/group'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createAddNewGroupSaga(
  groupAddNewService: UACServices.Services
) {
  return function* addNewGroupSaga(
    action: ReturnType<typeof addNewGroupActions.addNewGroup>
  ) {
    try {
      const showAssignUsersNext = action.payload.showAssignUsersNext
      const groupName = action.payload.name
      const currentUrlFilters = yield select(getCurrentUrlFilters)

      const errorNotification = {
        message: msg`Group hasn't been created.`,
        type: NotificationType.alert,
        autoDismiss: false
      }

      const groupAlreadyExistsErrorNotification = {
        message: msg`Group hasn't been created. A group already exists with this name.`,
        type: NotificationType.alert,
        autoDismiss: false
      }

      const invalidErrorNotification = {
        message: msg`Group hasn't been created. Invalid group name.`,
        type: NotificationType.alert,
        autoDismiss: false
      }

      yield put(addNewGroupActions.setNewGroupIsLoading(true))
      try {
        const result: UACServices.GroupAddNewAPI.Responses = yield call(
          groupAddNewService.groupAddNew,
          groupName
        )
        const isGroupNameInvalid =
          result.type === GroupNameErrorMessages.groupNameEmpty ||
          result.type === GroupNameErrorMessages.groupNameTooLong
        if (result.type === UACServices.GroupAddNewAPI.SUCCESS) {
          const groupId = result.body.id
          yield put(addNewGroupActions.setNewGroupValiditySuccess(true))
          yield put(addNewGroupActions.setNewGroupId(groupId))
          if (!showAssignUsersNext) {
            yield put(
              globalActions.setNotification({
                message: msg`Group has been created: ${groupName}`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
          }
          const redirectionPath = showAssignUsersNext
            ? `/users/groups/${groupId}/add-new`
            : '/users/groups'
          yield put(push(redirectionPath))
        } else if (
          result.type === GroupNameErrorMessages.groupNameAlreadyExists
        ) {
          yield put(addNewGroupActions.setNewGroupValidityFail(true))
          yield put(
            globalActions.setNotification(groupAlreadyExistsErrorNotification)
          )
        } else if (isGroupNameInvalid) {
          yield put(globalActions.setNotification(invalidErrorNotification))
        }
      } catch (e) {
        yield put(globalActions.setNotification(errorNotification))
      } finally {
        yield put(
          groupListActions.getGroupsList({
            query: getQueryParams(currentUrlFilters.location, 'groups'),
            path: {}
          })
        )
        yield put(addNewGroupActions.setNewGroupIsLoading(false))
        yield put(addNewGroupActions.setNewGroupValidityFail(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading100 } from '@lastpass/lastkit'

import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'

import { AdfsInstall } from './AdfsInstall'
import { AzureAdInstall } from './AzureAdInstall'
import { UniversalProxyInstall } from './UniversalProxyInstall'
import { WorkstationInstall } from './WorkstationInstall'

const StyledHeading100 = styled(Heading100)`
  margin-top: 24px;
`

interface HowToInstallProps {
  mfaAppType: MfaApplicationType
}

export const HowToInstall = (props: HowToInstallProps) => {
  return (
    <>
      <StyledHeading100>
        <Trans>How to install</Trans>
      </StyledHeading100>
      {props.mfaAppType === MfaApplicationType.windows && (
        <WorkstationInstall
          downloadLink={
            'https://download.cloud.lastpass.com/workstation_mfa/WorkstationMFAWindows.zip'
          }
          applicationType={props.mfaAppType}
        />
      )}
      {props.mfaAppType === MfaApplicationType.mac && (
        <WorkstationInstall
          downloadLink={
            'https://download.cloud.lastpass.com/workstation_mfa/WorkstationMFAMac.zip'
          }
          applicationType={props.mfaAppType}
        />
      )}
      {props.mfaAppType === MfaApplicationType.azureAD && <AzureAdInstall />}
      {props.mfaAppType === MfaApplicationType.adfs && (
        <AdfsInstall
          downloadLink={
            'https://download.cloud.lastpass.com/adfs_mfa/latest/setup.msi'
          }
        />
      )}
      {props.mfaAppType === MfaApplicationType.universalProxy && (
        <UniversalProxyInstall />
      )}
    </>
  )
}

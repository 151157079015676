import * as lodash from 'lodash'

import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { UserOperationsType } from '@lastpass/admin-console-dependencies/state/users/view/operations/user-operations'
import { AccountDetails } from '@lastpass/admin-console-dependencies/types/account-details'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'
import { MultifactorProvider } from '@lastpass/admin-console-dependencies/types/multifactor-provider'
import {
  User,
  UserProfile
} from '@lastpass/admin-console-dependencies/types/user'
import { EnumDictionary } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'

import * as getCompanyEntitlements from '../get-company-entitlements'

const canInviteUser = (accountStatus: AccountStatus): boolean =>
  accountStatus === AccountStatus.staged

const canReinviteUser = (accountStatus: AccountStatus): boolean =>
  accountStatus === AccountStatus.invited ||
  accountStatus === AccountStatus.expiredInvitation

const canAssignAdminLevels = (
  selectedUsers: User[],
  currentUserInfo: CurrentUser
): boolean => !selectedUsers.some(user => user.email === currentUserInfo.email)

const canResendLPMFAInvitation = (
  accountStatus: AccountStatus,
  mfaStatus: MFAStatus,
  companyDetails: CompanyDetails
) =>
  (accountStatus === AccountStatus.active ||
    accountStatus === AccountStatus.invited ||
    accountStatus === AccountStatus.expiredInvitation ||
    accountStatus === AccountStatus.staged) &&
  (mfaStatus === MFAStatus.invited ||
    mfaStatus === MFAStatus.active ||
    mfaStatus === MFAStatus.locked) &&
  getCompanyEntitlements.companyHasMfa(companyDetails)

const canActivateUsers = (accountStatus: AccountStatus) =>
  accountStatus === AccountStatus.disabled

const canSetInitialPassword = () => false

const canRequireMasterPasswordChange = (
  accountStatus: AccountStatus,
  federatedStatus: FederatedStatus,
  isAdfsEnabled: boolean
) =>
  (accountStatus === AccountStatus.active ||
    accountStatus === AccountStatus.loggedIn) &&
  (federatedStatus !== FederatedStatus.federated || !isAdfsEnabled)

const canSuperAdminMasterPasswordReset = () => false

const canDestroyAllSessionsForSelectedUsers = (
  accountStatus: AccountStatus,
  mfaStatus: MFAStatus
) =>
  accountStatus === AccountStatus.active ||
  accountStatus === AccountStatus.loggedIn ||
  mfaStatus === MFAStatus.active

const canLockDeviceForLPMFA = (
  accountStatus,
  mfaStatus: MFAStatus,
  companyDetails: CompanyDetails
) =>
  (accountStatus === AccountStatus.active ||
    accountStatus === AccountStatus.staged ||
    accountStatus === AccountStatus.invited ||
    accountStatus === AccountStatus.expiredInvitation) &&
  mfaStatus === MFAStatus.active &&
  getCompanyEntitlements.companyHasMfa(companyDetails)

const canUnlockDeviceForLPMFA = (
  accountStatus,
  mfaStatus: MFAStatus,
  companyDetails: CompanyDetails
) =>
  (accountStatus === AccountStatus.active ||
    accountStatus === AccountStatus.staged ||
    accountStatus === AccountStatus.invited ||
    accountStatus === AccountStatus.expiredInvitation) &&
  mfaStatus === MFAStatus.locked &&
  getCompanyEntitlements.companyHasMfa(companyDetails)

const canRemoveSelectedUsersFromCompany = (
  accountStatus: AccountStatus,
  mfaStatus: MFAStatus,
  isNonDisabledUserRemovalAllowed: boolean,
  selectedUsers,
  currentUserInfo,
  userProfileInfo
) => {
  if (isNonDisabledUserRemovalAllowed) {
    return (
      !isSelectedUserEqualsToCurrentUser(
        selectedUsers,
        currentUserInfo,
        userProfileInfo
      ) &&
      (accountStatus === AccountStatus.invited ||
        accountStatus === AccountStatus.expiredInvitation ||
        accountStatus === AccountStatus.staged ||
        accountStatus === AccountStatus.active ||
        accountStatus === AccountStatus.disabled ||
        mfaStatus === MFAStatus.active)
    )
  } else {
    return accountStatus === AccountStatus.disabled
  }
}

const isSelectedUserEqualsToCurrentUser = (
  selectedUsers: User[] | AccountDetails[],
  currentUserInfo: CurrentUser,
  userProfileInfo: UserProfile
): boolean =>
  userProfileInfo.lastPassId
    ? String(userProfileInfo.lastPassId) === currentUserInfo.userId
    : selectedUsers.some(
        user =>
          user.lastPassUserId !== null &&
          String(user.lastPassUserId) === currentUserInfo.userId
      )

const canDeleteSelectedUsers = (
  accountStatus: AccountStatus,
  mfaStatus: MFAStatus,
  selectedUsers: User[] | AccountDetails[],
  currentUserInfo: CurrentUser,
  userProfileInfo: UserProfile
) =>
  !isSelectedUserEqualsToCurrentUser(
    selectedUsers,
    currentUserInfo,
    userProfileInfo
  ) &&
  (accountStatus === AccountStatus.invited ||
    accountStatus === AccountStatus.expiredInvitation ||
    accountStatus === AccountStatus.staged ||
    accountStatus === AccountStatus.active ||
    accountStatus === AccountStatus.disabled ||
    mfaStatus === MFAStatus.active)

const canDisableUsers = (
  accountStatus: AccountStatus,
  mfaStatus: MFAStatus,
  selectedUsers: User[] | AccountDetails[],
  currentUserInfo: CurrentUser,
  userProfileInfo: UserProfile
) =>
  !isSelectedUserEqualsToCurrentUser(
    selectedUsers,
    currentUserInfo,
    userProfileInfo
  ) &&
  (accountStatus === AccountStatus.active || mfaStatus === MFAStatus.active)

const canDisableMultifactor = (
  accountStatus: AccountStatus,
  defaultMultiFactorType: MultifactorProvider | null
) =>
  accountStatus === AccountStatus.active &&
  !!defaultMultiFactorType &&
  defaultMultiFactorType !== MultifactorProvider.NO_MULTIFATOR

const canAcceptPendingUser = (accountStatus: AccountStatus) =>
  accountStatus === AccountStatus.awaitingApproval

const canRejectPendingUser = (accountStatus: AccountStatus) =>
  accountStatus === AccountStatus.awaitingApproval

const isSelectedUserNotASuperAdminOrAdmin = (
  selectedUsers: (User | AccountDetails)[]
): boolean => {
  return selectedUsers.every(user => {
    return (
      user.adminLevel === null ||
      (user.adminLevel.id !== AdminLevel.admin &&
        user.adminLevel.id !== AdminLevel.superAdmin)
    )
  })
}

export const canResetMasterPassword = (
  isSuperAdminKeyAvailableForActingAdmin: boolean,
  currentAdminLevel: string,
  selectedUsers: User[] | AccountDetails[]
): boolean => {
  switch (currentAdminLevel) {
    case AdminLevel.superAdmin:
      return isSuperAdminKeyAvailableForActingAdmin
    case AdminLevel.helpdeskAdmin:
      return (
        isSuperAdminKeyAvailableForActingAdmin &&
        isSelectedUserNotASuperAdminOrAdmin(selectedUsers)
      )
  }
  return false
}

const canTransferVault = (
  isSuperAdminKeyAvailableForActingAdmin: boolean,
  currentAdminLevel: AdminLevel
) =>
  isSuperAdminKeyAvailableForActingAdmin &&
  currentAdminLevel === AdminLevel.superAdmin

const canDistributeRecoveryKeys = (
  currentAdminLevel: AdminLevel,
  selectedUsers: User[],
  userProfileInfo: UserProfile
) => {
  return selectedUsers.length > 0
    ? currentAdminLevel === AdminLevel.superAdmin &&
        selectedUsers.every(user => user.isRecoveryKeySharingAllowed)
    : userProfileInfo.isRecoveryKeySharingAllowed
}

const canSelectForFederation = (
  federatedStatus: FederatedStatus,
  isFederatedLoginEnabled: boolean,
  isSuperAdmin: boolean
) =>
  federatedStatus === FederatedStatus.notSelected &&
  isFederatedLoginEnabled &&
  !isSuperAdmin

const canDeselectForFederation = (
  federatedStatus: FederatedStatus,
  isFederatedLoginEnabled: boolean,
  isSuperAdmin: boolean
) =>
  federatedStatus === FederatedStatus.selected &&
  isFederatedLoginEnabled &&
  !isSuperAdmin

export const canSelectForVaultReEncryption = (
  federatedStatus: FederatedStatus,
  currentAdminLevel: AdminLevel
) =>
  federatedStatus === FederatedStatus.federated &&
  currentAdminLevel === AdminLevel.superAdmin

const operationsThatRequireUsersModifyPermission = [
  UserOperationsType.inviteUser,
  UserOperationsType.reinviteUser,
  UserOperationsType.activateUsers,
  UserOperationsType.removeSelectedUsersFromCompany,
  UserOperationsType.deleteSelectedUsers,
  UserOperationsType.disableUsers,
  UserOperationsType.acceptPendingUser,
  UserOperationsType.rejectPendingUser,
  UserOperationsType.setInitialPassword,
  UserOperationsType.selectForFederation,
  UserOperationsType.deselectForFederation
]

const operationsThatRequireAdminModifyPermission = [
  UserOperationsType.assignAdminLevels
]

const operationsThatRequireMasterPasswordResetPermission = [
  UserOperationsType.superAdminMasterPasswordReset,
  UserOperationsType.resetMasterPassword,
  UserOperationsType.distributeRecoveryKey
]

const operationsThatRequireMasterPasswordSettingsOrMasterPasswordResetPermissions = [
  UserOperationsType.requireMasterPasswordChange,
  UserOperationsType.setInitialPassword
]

const operationsThatRequireDestroySessionPermission = [
  UserOperationsType.destroyAllSessionsForSelectedUsers
]

const operationsThatRequireTrustedDevicesModifyOrMfaModifyPermissions = [
  UserOperationsType.lockDeviceForLPMFA,
  UserOperationsType.unlockDeviceForLPMFA
]

const operationsThatRequireMasterPasswordResetPermissions = [
  UserOperationsType.transferVault
]

const operationsThatRequireMfaModifyPermission = [
  UserOperationsType.resendLPMFAInvitation,
  UserOperationsType.disableMultifactor
]

const operationsThatRequireFederation = [
  UserOperationsType.selectForFederation,
  UserOperationsType.deselectForFederation
]

const getOperations = (
  accountStatus: AccountStatus,
  mfaStatus: MFAStatus,
  defaultMultiFactorType: MultifactorProvider | null,
  companyDetails: CompanyDetails,
  isSuperAdminKeyAvailableForActingAdmin: boolean,
  isNonDisabledUserRemovalAllowed: boolean,
  federatedStatus: FederatedStatus,
  isSuperAdmin: boolean,
  currentAdminLevel: AdminLevel,
  selectedUsers: User[],
  currentUserInfo: CurrentUser,
  userProfileInfo: UserProfile,
  isAdfsEnabled: boolean
): UserOperationsType[] => {
  const userOperations: EnumDictionary<UserOperationsType, boolean> = {
    [UserOperationsType.inviteUser]: canInviteUser(accountStatus),
    [UserOperationsType.reinviteUser]: canReinviteUser(accountStatus),
    [UserOperationsType.assignAdminLevels]: canAssignAdminLevels(
      selectedUsers,
      currentUserInfo
    ),
    [UserOperationsType.resendLPMFAInvitation]: canResendLPMFAInvitation(
      accountStatus,
      mfaStatus,
      companyDetails
    ),
    [UserOperationsType.resetMasterPassword]: canResetMasterPassword(
      isSuperAdminKeyAvailableForActingAdmin,
      currentAdminLevel,
      selectedUsers
    ),
    [UserOperationsType.distributeRecoveryKey]: canDistributeRecoveryKeys(
      currentAdminLevel,
      selectedUsers,
      userProfileInfo
    ),
    [UserOperationsType.activateUsers]: canActivateUsers(accountStatus),
    [UserOperationsType.setInitialPassword]: canSetInitialPassword(),
    [UserOperationsType.requireMasterPasswordChange]: canRequireMasterPasswordChange(
      accountStatus,
      federatedStatus,
      isAdfsEnabled
    ),
    [UserOperationsType.superAdminMasterPasswordReset]: canSuperAdminMasterPasswordReset(),
    [UserOperationsType.disableMultifactor]: canDisableMultifactor(
      accountStatus,
      defaultMultiFactorType
    ),
    [UserOperationsType.destroyAllSessionsForSelectedUsers]: canDestroyAllSessionsForSelectedUsers(
      accountStatus,
      mfaStatus
    ),
    [UserOperationsType.lockDeviceForLPMFA]: canLockDeviceForLPMFA(
      accountStatus,
      mfaStatus,
      companyDetails
    ),
    [UserOperationsType.unlockDeviceForLPMFA]: canUnlockDeviceForLPMFA(
      accountStatus,
      mfaStatus,
      companyDetails
    ),
    [UserOperationsType.selectForFederation]: canSelectForFederation(
      federatedStatus,
      companyDetails.isFederatedLoginEnabled,
      isSuperAdmin
    ),
    [UserOperationsType.deselectForFederation]: canDeselectForFederation(
      federatedStatus,
      companyDetails.isFederatedLoginEnabled,
      isSuperAdmin
    ),
    [UserOperationsType.selectForVaultReEncryption]: canSelectForVaultReEncryption(
      federatedStatus,
      currentAdminLevel
    ),
    [UserOperationsType.transferVault]: canTransferVault(
      isSuperAdminKeyAvailableForActingAdmin,
      currentAdminLevel
    ),
    [UserOperationsType.removeSelectedUsersFromCompany]: canRemoveSelectedUsersFromCompany(
      accountStatus,
      mfaStatus,
      isNonDisabledUserRemovalAllowed,
      selectedUsers,
      currentUserInfo,
      userProfileInfo
    ),
    [UserOperationsType.acceptPendingUser]: canAcceptPendingUser(accountStatus),
    [UserOperationsType.rejectPendingUser]: canRejectPendingUser(accountStatus),
    [UserOperationsType.disableUsers]: canDisableUsers(
      accountStatus,
      mfaStatus,
      selectedUsers,
      currentUserInfo,
      userProfileInfo
    ),
    [UserOperationsType.deleteSelectedUsers]: canDeleteSelectedUsers(
      accountStatus,
      mfaStatus,
      selectedUsers,
      currentUserInfo,
      userProfileInfo
    )
  }

  return Object.keys(userOperations)
    .filter(userOperation => userOperations[userOperation])
    .map(userOperation => UserOperationsType[userOperation])
}

function filterByPermissions(
  adminPermissions: AdminPermissions[],
  operations: UserOperationsType[]
): UserOperationsType[] {
  let allowedUserOperations = operations
  if (!adminPermissions.includes(AdminPermissions.usersModify)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireUsersModifyPermission
    )
  }

  if (!adminPermissions.includes(AdminPermissions.adminsModify)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireAdminModifyPermission
    )
  }

  if (!adminPermissions.includes(AdminPermissions.userLevelMfaModify)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireMfaModifyPermission
    )
  }

  if (!adminPermissions.includes(AdminPermissions.usersSessionsDestroy)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireDestroySessionPermission
    )
  }

  if (!adminPermissions.includes(AdminPermissions.usersMasterPasswordReset)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireMasterPasswordResetPermission
    )
  }

  if (!adminPermissions.includes(AdminPermissions.userLevelMfaModify)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireTrustedDevicesModifyOrMfaModifyPermissions
    )
  }

  if (
    !adminPermissions.includes(AdminPermissions.usersPasswordsSettingsModify)
  ) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireMasterPasswordSettingsOrMasterPasswordResetPermissions
    )
  }

  if (!adminPermissions.includes(AdminPermissions.usersMasterPasswordReset)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireMasterPasswordResetPermissions
    )
  }

  if (
    !adminPermissions.includes(AdminPermissions.directoriesAndFederationModify)
  ) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireFederation
    )
  }

  return allowedUserOperations
}

export function getAllUserOperations(
  companyDetails: CompanyDetails,
  adminPermissions: AdminPermissions[]
): UserOperationsType[] {
  const operationsThatRequireMfa = [
    UserOperationsType.resendLPMFAInvitation,
    UserOperationsType.lockDeviceForLPMFA,
    UserOperationsType.unlockDeviceForLPMFA
  ]

  const disabledOperations = [
    UserOperationsType.setInitialPassword,
    UserOperationsType.superAdminMasterPasswordReset
  ]

  let allowedUserOperations: UserOperationsType[] = []

  for (const userOperation of Object.keys(UserOperationsType)) {
    allowedUserOperations.push(UserOperationsType[userOperation])
  }

  if (!getCompanyEntitlements.companyHasMfa(companyDetails)) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireMfa
    )
  }

  if (!companyDetails.isFederatedLoginEnabled) {
    allowedUserOperations = lodash.difference(
      allowedUserOperations,
      operationsThatRequireFederation
    )
  }

  allowedUserOperations = lodash.difference(
    allowedUserOperations,
    disabledOperations
  )

  return filterByPermissions(adminPermissions, allowedUserOperations)
}

export function getApplicableUserOperations(
  statusArray: [
    AccountStatus,
    MFAStatus,
    MultifactorProvider | null,
    boolean,
    FederatedStatus,
    boolean
  ][],
  companyDetails: CompanyDetails,
  isNonDisabledUserRemovalAllowed: boolean,
  adminPermissions: AdminPermissions[],
  currentAdminLevel: AdminLevel,
  selectedUsers: User[],
  currentUserInfo: CurrentUser,
  userProfileInfo: UserProfile,
  isAdfsEnabled: boolean
): UserOperationsType[] {
  const userOperations: UserOperationsType[][] = []
  statusArray.forEach(status => {
    userOperations.push(
      getOperations(
        status[0],
        status[1],
        status[2],
        companyDetails,
        status[3],
        isNonDisabledUserRemovalAllowed,
        status[4],
        status[5],
        currentAdminLevel,
        selectedUsers,
        currentUserInfo,
        userProfileInfo,
        isAdfsEnabled
      )
    )
  })

  if (!userOperations.length) {
    return []
  }

  const allowedOperations = userOperations.reduce((previous, current) =>
    previous.filter(element => current.includes(element))
  )

  return filterByPermissions(adminPermissions, allowedOperations)
}

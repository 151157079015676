import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as Icon } from '@lastpass/assets/svg/multifactor/blue-question-mark-icon.svg'

const AlertDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 10px 11px 12px;
  background: rgba(59, 112, 212, 0.1);
  border: 1px solid #3b70d4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 16px;
  width: 480px;
`

const TextDiv = styled.div`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1b283c;
  flex: none;
  order: 1;
  flex-grow: 1;
  width: 405px;
  word-wrap: break-word;
`

const IconContainer = styled.div`
  margin-right: 10px;
`

export const PostTrialMfaAlert = () => {
  return (
    <AlertDiv data-qa="post-trial-mfa-alert">
      <IconContainer>
        <Icon />
      </IconContainer>
      <TextDiv>
        <Trans>
          Your Advanced MFA trial has ended. Purchase Advanced MFA to maintain
          maximum security for user logins.
        </Trans>
      </TextDiv>
    </AlertDiv>
  )
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import { createQueryStringForNamespace } from '@lastpass/admin-console-dependencies/services/routing/create-query-string-for-namespace'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import {
  VaultReEncryptionOfferDetailsDialog,
  VaultReEncryptionOfferDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'

export enum VaultReEncryptionOfferStage {
  stage1 = 'stage-1',
  stage2 = 'stage-2',
  stage3 = 'stage-3',
  cancelled = 'cancelled',
  confirmed = 'confirmed'
}

export const getFederatedLoginSelector = (state: AppState) =>
  state.federatedLogin

export const getAdminLevelSelector = (state: AppState) =>
  state.usersList.currentAdminLevel

export function* vaultReEncryptionOffer() {
  const vaultReEncryptionOfferStartDialog: VaultReEncryptionOfferDialog = {
    type: 'vault-re-encryption-offer-dialog',
    title: msg`New! Recurring and forced vault re-encryption`,
    mainText: msg`Protect federated users by re-encrypting their vault and rotating their K2 knowledge component on a recurring basis or by forcing it on-demand.`,
    secondaryText: msg`This helps ensure that information stays safe even if user credentials are compromised.`,
    confirmText: msg`Next`,
    discardText: msg`Remind me later`,
    backStage: VaultReEncryptionOfferStage.cancelled,
    nextStage: VaultReEncryptionOfferStage.stage2,
    cancelStage: VaultReEncryptionOfferStage.cancelled
  }

  const vaultReEncryptionOfferDetailsDialog: VaultReEncryptionOfferDetailsDialog = {
    type: 'vault-re-encryption-offer-details-dialog'
  }
  const vaultReEncryptionOfferFinishDialog: VaultReEncryptionOfferDialog = {
    type: 'vault-re-encryption-offer-dialog',
    title: msg`How are users impacted?`,
    mainText: msg`There’s no meaningful change to your users’ daily experience. `,
    secondaryText: msg`When they next log in, they’ll see a notification that tells them their vault is being re-encrypted. That’s about it. `,
    confirmText: msg`Set it up`,
    discardText: msg`Back`,
    backStage: VaultReEncryptionOfferStage.stage2,
    nextStage: VaultReEncryptionOfferStage.confirmed,
    cancelStage: VaultReEncryptionOfferStage.cancelled
  }

  const { openId } = yield select(getFederatedLoginSelector)
  const currentAdminLevel = yield select(getAdminLevelSelector)
  const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin
  if (!openId.isEnabled || !isSuperAdmin) {
    return
  }

  let offerStage = VaultReEncryptionOfferStage.stage1

  while (
    ![
      VaultReEncryptionOfferStage.cancelled,
      VaultReEncryptionOfferStage.confirmed
    ].includes(offerStage)
  ) {
    switch (offerStage) {
      case VaultReEncryptionOfferStage.stage1:
        yield put(globalActions.setDialog(vaultReEncryptionOfferStartDialog))
        break
      case VaultReEncryptionOfferStage.stage2:
        yield put(globalActions.setDialog(vaultReEncryptionOfferDetailsDialog))
        break
      case VaultReEncryptionOfferStage.stage3:
        yield put(globalActions.setDialog(vaultReEncryptionOfferFinishDialog))
        break
    }
    const dialogResponse = yield take(GlobalActionTypes.CONFIRM_DIALOG)
    offerStage = dialogResponse.payload.data
  }

  yield put(globalActions.emptyDialog())

  if (offerStage === VaultReEncryptionOfferStage.confirmed) {
    yield put(
      push(
        `/policies/generalpolicies/recurringfederatedvaultreencryption?${createQueryStringForNamespace(
          Namespace.policies,
          {
            search: 'Recurring vault re-encryption'
          }
        )}`
      )
    )
  }
}

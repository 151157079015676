import { AdvancedUserActivityReport } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'

export const mockReports: AdvancedUserActivityReport[] = [
  {
    name: 'Some Report 1',
    adminEmail: 'attila.santa@logmein.com',
    filter: {
      group: ['438679', '438681', '438724'],
      userEvents: ['createLPaccount']
    }
  },
  {
    name: 'Some Report 2',
    adminEmail: 'admin2@email.com',
    filter: {
      user: ['11698763'],
      group: ['438717', '438747'],
      adminEvents: ['addglobalneverurls']
    }
  },
  {
    name: 'Some Report 3',
    adminEmail: '',
    filter: {
      user: ['11698763'],
      group: ['438764', '438782', '438783'],
      userEvents: ['createLPaccount', 'createdsharedfolder'],
      adminEvents: ['addglobalneverurls']
    }
  },
  {
    name: 'Some Report 4',
    adminEmail: 'gabor.szilagyi@logmein.com',
    filter: {
      user: ['11698763'],
      group: ['438764', '438782', '438783'],
      adminEvents: ['addglobalneverurls']
    }
  }
]

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { NumberedList } from '@lastpass/lastkit/components/NumberedList'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'

const StyledLocationLink = styled.a`
  margin-top: 14px;
  font-weight: bold;
`

const DownloadLink = styled.a`
  font-weight: bold;
`

interface WorkstationInstallProps {
  downloadLink: string
  applicationType: string
}

export const WorkstationInstall = (props: WorkstationInstallProps) => {
  return (
    <>
      <NumberedList
        items={[
          <DownloadLink
            key={'1'}
            href={props.downloadLink}
            data-qa={'DownloadLink'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              mfaAppsDrawerActions.configureAppClicked('Download Installer', {
                type: props.applicationType
              })
            }
          >
            <Trans>Download the installer.</Trans>
          </DownloadLink>,
          <Trans key={'2'}>
            Run the installer on the workstations you’d like to protect.
          </Trans>,
          <Trans key={'3'}>
            Enter the integration key & secret and complete the installation.
          </Trans>,
          <Trans key={'4'}>
            Users assigned to a LastPass Authenticator multifactor policy will
            be prompted for MFA after entering their password.
          </Trans>
        ]}
      />
      <StyledLocationLink
        href={'https://link.lastpass.com/help-workstation-login'}
        data-qa={'LearnMoreLink'}
        target="_blank"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </>
  )
}

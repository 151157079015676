import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'

export enum ManagedCompanyOperationActionTypes {
  REACTIVATE = '[managed-company-operation] reactivate',
  SUSPEND = '[managed-company-operation] suspend',
  ALLOCATE_LICENSES = '[managed-company-operation] allocate-licenses',
  RETURN_LICENSES = '[managed-company-operation] return-licenses',
  DETACH = '[managed-company-operation] detach',
  EXPORT_MANAGED_COMPANIES = '[managed-company-operation] export-managed-companies',
  SET_IS_EXPORT_MANAGED_COMPANIES_LOADING = '[managed-company-operation] export-managed-companies-loading',
  SHOW_EXPORT_USER_COUNTS_DIALOG = '[managed-company-operation] show-export-user-counts-dialog',
  SET_IS_EXPORT_USER_COUNTS_LOADING = '[managed-company-operation] export-user-counts-loading'
}

export const managedCompanyOperationActions = {
  reactivate: (managedCompany: ManagedCompany) =>
    createAction(ManagedCompanyOperationActionTypes.REACTIVATE, {
      managedCompany
    }),
  suspend: (managedCompany: ManagedCompany) =>
    createAction(ManagedCompanyOperationActionTypes.SUSPEND, {
      managedCompany
    }),
  allocateLicenses: (
    managedCompanyId: ManagedCompany['id'],
    numberOfLicenses: number
  ) =>
    createAction(ManagedCompanyOperationActionTypes.ALLOCATE_LICENSES, {
      managedCompanyId,
      numberOfLicenses
    }),
  returnLicenses: (
    managedCompanyId: ManagedCompany['id'],
    numberOfLicenses: number
  ) =>
    createAction(ManagedCompanyOperationActionTypes.RETURN_LICENSES, {
      managedCompanyId,
      numberOfLicenses
    }),
  detach: (managedCompany: ManagedCompany) =>
    createAction(ManagedCompanyOperationActionTypes.DETACH, {
      managedCompany
    }),
  exportManagedCompanies: () =>
    createAction(ManagedCompanyOperationActionTypes.EXPORT_MANAGED_COMPANIES),
  setIsExportManagedCompaniesLoading: (isLoading: boolean) =>
    createAction(
      ManagedCompanyOperationActionTypes.SET_IS_EXPORT_MANAGED_COMPANIES_LOADING,
      isLoading
    ),
  showExportUserCountsDialog: () =>
    createAction(
      ManagedCompanyOperationActionTypes.SHOW_EXPORT_USER_COUNTS_DIALOG
    ),
  setIsExportUserCountsLoading: (isLoading: boolean) =>
    createAction(
      ManagedCompanyOperationActionTypes.SET_IS_EXPORT_USER_COUNTS_LOADING,
      isLoading
    )
}

export type ManagedCompanyOperationActions = ActionsUnion<
  typeof managedCompanyOperationActions
>

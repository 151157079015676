import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import { SuperAdminRecoveryKey } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

const StyledLink = styled.a`
  text-decoration: underline;
`

const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 30px;
`

export const RecoveryKeySharingConfirmDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(distributeRecoveryKeysActions.getRecoveryKeySharingDetails())
  }, [dispatch])

  const email = useSelector<AppState, string>(
    state => state.distributeRecoveryKeys.targetAdminEmail
  )

  const isLoading = useSelector<AppState, boolean>(
    state => state.distributeRecoveryKeys.isLoading
  )

  const userRecoveryKeys = useSelector<AppState, SuperAdminRecoveryKey[]>(
    state =>
      state.distributeRecoveryKeys.recoveryKeySharingDetails.userRecoveryKeys
  )

  const handleConfirm = (): void => {
    dispatch(distributeRecoveryKeysActions.trackShareRecoveryKeyButton())
    if (userRecoveryKeys.length > 0) {
      dispatch(
        globalActions.setDialog({
          type: 'master-password-confirm-dialog',
          onConfirm: distributeRecoveryKeysActions.distributeRecoveryKeys,
          description: msg`Sorry to slow you down, but we need to ask for this when you make security changes.`
        })
      )
    } else {
      dispatch(
        globalActions.setDialog({
          type: 'recipient-already-has-the-keys'
        })
      )
    }
  }

  const content = (
    <>
      <StyledParagraph>
        <Trans>
          Make recovery keys available to the selected admin without exposing
          the keys in any way. The recipient will be able to reset master
          passwords and perform other high-security actions on users.
        </Trans>
      </StyledParagraph>
      <StyledParagraph>
        <Trans>
          Limitation: If you’re an MSP super admin working with a managed
          company or if your super admin account is federated, you can’t share
          recovery keys at this time.
        </Trans>
      </StyledParagraph>
      <StyledParagraph>
        <Trans>Share invisibly with this admin: {email}</Trans>
      </StyledParagraph>
      <StyledParagraph>
        <Trans>You can pause and resume the process any time.</Trans>
      </StyledParagraph>
      <StyledParagraph>
        <StyledLink
          href="https://support.lastpass.com/s/document-item?language=en_US&bundleId=lastpass&topicId=LastPass/reset-master-password-encryption.html&_LANG=enus"
          target="_blank"
        >
          <Trans>Learn more about recovery keys</Trans>
        </StyledLink>
      </StyledParagraph>
      {isLoading && (
        <StyledLoadingContainer>
          <Loading color="blue900" active={true} />
        </StyledLoadingContainer>
      )}
    </>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      disableCloseOnBackground
      width="634px"
      title={msg`Share recovery keys invisibly?`}
      content={content}
      buttons={[
        <TextButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
          data-qa="RecoveryKeySharingConfirmDialogCancelButton"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          onClick={handleConfirm}
          disabled={isLoading}
          key="confirm"
          data-qa="RecoveryKeySharingConfirmDialogConfirmButton"
        >
          <Trans>Share</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

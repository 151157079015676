import { msg } from '@lingui/macro'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationAlertDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { KeyRotationError } from './key-rotation-error'
import { KeyRotationErrors } from './key-rotation-error-codes'

export function* keyRotationErrorsCompanyWide(
  providerName: string,
  error: KeyRotationError
) {
  const keyRotationAlertDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Process failed`,
    subtitle: msg`There was an error rotating your knowledge components for the service (${providerName}).`,
    text: msg`Please try again. If the problem persists, please contact LastPass support.`
  }

  const conflictErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Contact LastPass for assistance`,
    subtitle: msg`There was an error rotating your knowledge components for the service (${providerName}).`,
    text: msg`No knowledge components were rotated. No changes have been made to your users.`,
    secondaryText: msg`To resolve the issue and continue the process, contact LastPass and we’ll make the necessary adjustments on our side.`
  }

  const connectionErrorText =
    error.sessionToken === ''
      ? msg`Error code: ${error.feErrorCode}`
      : msg`Error code: ${error.feErrorCode}, session: ${error.sessionToken}`

  const connectionErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Rotation process failed`,
    subtitle: msg`It looks like you lost your internet connection during the rotation process.`,
    text: msg`Don’t worry, no changes have been made to your users. Do not update your K1 component in the service (${providerName}).`,
    secondaryText: msg`Check your internet connection and try again.`,
    errorCodeText: connectionErrorText
  }

  const genericErrorText =
    error.sessionToken === ''
      ? msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}`
      : msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}, session: ${error.sessionToken}`

  const genericErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Rotation process failed`,
    subtitle: msg`Something went wrong during the rotation process.`,
    text: msg`Don’t worry, no changes have been made to your users. Do not update your K1 component in the service (${providerName}).`,
    secondaryText: msg`If the problem persists, please contact LastPass support.`,
    errorCodeText: genericErrorText
  }

  const incorrectCompanyWideK1ErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Rotation process failed`,
    subtitle: msg`It looks like you provided an incorrect K1 component for your organization.`,
    text: msg`Don’t worry, no changes have been made to your users. Do not update your K1 component in the service (${providerName}).`,
    secondaryText: msg`Try again using the correct K1 component for your organization.`,
    errorCodeText: msg`Error code: ${error.feErrorCode}, session: ${error.sessionToken}`
  }

  switch (error.feErrorCode) {
    case KeyRotationErrors.AlpGetSessionTokenRequestConflict:
      yield put(globalActions.setDialog(conflictErrorDialog))
      break
    case KeyRotationErrors.AlpGetSessionTokenRequestConnectionFailed:
    case KeyRotationErrors.AlpGetKeyRotationDataRequestConnectionFailed:
    case KeyRotationErrors.AlpSaveKeyRotationDataRequestConnectionFailed:
    case KeyRotationErrors.AlpSyncKeyRotationDataRequestConnectionFailed:
      yield put(globalActions.setDialog(connectionErrorDialog))
      break
    case KeyRotationErrors.AlpGetSessionTokenRequestFailed:
    case KeyRotationErrors.AlpGetKeyRotationDataRequestFailed:
    case KeyRotationErrors.KeyRotationMpMissmatchError:
    case KeyRotationErrors.AlpSaveKeyRotationDataRequestFailed:
    case KeyRotationErrors.AlpSyncKeyRotationDataRequestFailed:
      yield put(globalActions.setDialog(genericErrorDialog))
      break
    case KeyRotationErrors.AllFragmentIdValidationFailed:
      yield put(globalActions.setDialog(incorrectCompanyWideK1ErrorDialog))
      break
    default:
      yield put(globalActions.setDialog(keyRotationAlertDialog))
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton } from '@lastpass/lastkit'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminListState } from '@lastpass/admin-console-dependencies/state/users/admin/list/state'

import {
  AdminLevelsErrorText,
  MAXIMUM_ADMIN_LEVELS
} from './AdminLevelsErrorText'

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`

export const NewAdminLevelButton: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const adminList: AdminListState = useSelector(
    (state: AppState) => state.adminList
  )

  const hasReachedAdminLevelsLimit =
    adminList.table.totalResults >= MAXIMUM_ADMIN_LEVELS

  return (
    <Wrapper>
      {hasReachedAdminLevelsLimit && <AdminLevelsErrorText />}
      <PrimaryButton
        onClick={() => {
          dispatch(adminLevelDrawerActions.reset())
          history.push('/users/admin/add')
        }}
        data-qa="NewAdminLevelButton"
        disabled={hasReachedAdminLevelsLimit}
      >
        <Trans>New admin level</Trans>
      </PrimaryButton>
    </Wrapper>
  )
}

import { track, uacEvent } from '@lastpass/tracking'
import { SiemProvider } from '@lastpass/tracking/tracking-plans/uac'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum SiemIntegrationsActionTypes {
  SET_UP_INTEGRATION_TRACKING = 'SET_UP_INTEGRATION_TRACKING'
}

export const createSetUpIntegrationTrackingSegment = (
  provider: SiemProvider
) => ({
  segment: uacEvent('SIEM Integration Set Up', {
    siemProvider: provider
  })
})

export const siemIntegrationsActions = {
  setUpIntegrationTracking: (provider: SiemProvider) =>
    track(
      createAction(SiemIntegrationsActionTypes.SET_UP_INTEGRATION_TRACKING),
      createSetUpIntegrationTrackingSegment(provider)
    )
}

export type SiemIntegrationsActions = ActionsUnion<
  typeof siemIntegrationsActions
>

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

export const errorNotification = {
  message: msg`Admin level hasn't been updated.`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const duplicateErrorNotification = {
  message: msg`There's already an admin level with that name`,
  type: NotificationType.alert,
  autoDismiss: false
}

export const getCurrentAdminLevel = (state: AppState) =>
  state.adminLevelDrawer.adminLevel

export function createEditManagedAdminLevelSaga(
  editManagedAdminLevelService: UACServices.Services
) {
  return function* editManagedAdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.editManagedAdminLevel>
  ) {
    try {
      const {
        id,
        mspCidOverrideAdminLevel,
        permissions,
        closeLink
      } = action.payload

      const mspCidOverridePermissions =
        mspCidOverrideAdminLevel === MspCidOverrideLevel.custom
          ? action.payload.mspCidOverridePermissions
          : []

      try {
        const adminLevelResponse: UACServices.AdminLevelAPI.Responses = yield call(
          editManagedAdminLevelService.adminLevel,
          id
        )

        if (adminLevelResponse.type === UACServices.AdminLevelSaveAPI.SUCCESS) {
          const { id, name, description } = adminLevelResponse.body

          const result: UACServices.AdminLevelSaveAPI.Responses = yield call(
            editManagedAdminLevelService.adminLevelPartialEdit,
            {
              id,
              name,
              description,
              permissions,
              mspCidOverrideAdminLevel,
              mspCidOverridePermissions
            }
          )

          if (result.type === UACServices.AdminLevelSaveAPI.SUCCESS) {
            yield put(adminListActions.getAdminList())
            yield put(adminLevelDrawerActions.reset())

            yield put(push(closeLink))
          } else {
            throw new UnknownServiceError()
          }
        } else {
          throw new UnknownServiceError()
        }
      } catch (e) {
        yield put(globalActions.setNotification(errorNotification))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

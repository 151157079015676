import React, { useRef, useState } from 'react'
import Geocode from 'react-geocode'
import {
  Circle,
  GoogleMap,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps'

import { compose, withProps } from 'recompose'

export interface MapData {
  apiKey: string | undefined
  center: { lat: number; lng: number } | null
  radius: number | null
  onMapClicked?: {}
  onCircleMoved?: {}
  onRadiusChanged?: {}
}

export const defaultCenter = {
  lat: 37.3834193,
  lng: -122.083086
}
export const defaultRadius = 250

const Map = compose(
  withProps(props => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${props.apiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  })),
  withScriptjs,
  withGoogleMap
)(props => {
  const [currentCenter, setCurrentCenter] = useState(props.center)
  const [currentRadius, setRadius] = useState(props.radius)

  const onMapClicked = e => {
    setCurrentCenter(e.latLng)
    props.onMapClicked(e.latLng.lat(), e.latLng.lng())
  }

  const refCircle = useRef<Circle>(null)

  const onCircleMoved = () => {
    if (refCircle && refCircle.current) {
      const newCenter = refCircle.current.getCenter()
      setCurrentCenter(newCenter)
      props.onCircleMoved(newCenter.lat(), newCenter.lng())
    }
  }

  const onRadiusChanged = () => {
    if (refCircle && refCircle.current) {
      let newRadius = refCircle.current.getRadius()
      if (newRadius < 30) {
        newRadius = 30
      }
      if (newRadius > 4000000) {
        newRadius = 4000000
      }
      newRadius = Math.floor(newRadius)
      setRadius(newRadius)
      props.onRadiusChanged(newRadius)
    }
  }

  return (
    <>
      <GoogleMap
        defaultZoom={15}
        defaultCenter={currentCenter || defaultCenter}
        onClick={onMapClicked}
      >
        <Circle
          ref={refCircle}
          center={currentCenter || defaultCenter}
          radius={currentRadius || defaultRadius}
          visible={currentCenter !== undefined}
          editable={true}
          draggable={true}
          onRadiusChanged={onRadiusChanged}
          onCenterChanged={onCircleMoved}
          options={{ fillColor: '#7CFC00', strokeColor: '#32CD32' }}
        />
      </GoogleMap>
    </>
  )
})

export const calcluateAddress = (lat: number, lng: number) => {
  return Geocode.fromLatLng(lat, lng).then(
    response => response.results[0].formatted_address
  )
}

export function MapComponent(data: MapData) {
  Geocode.setApiKey(data.apiKey)
  return (
    <Map
      apiKey={data.apiKey}
      center={data.center}
      radius={data.radius}
      onMapClicked={data.onMapClicked}
      onRadiusChanged={data.onRadiusChanged}
      onCircleMoved={data.onCircleMoved}
    />
  )
}

import React, { useState } from 'react'
import AnimateHeight from 'react-animate-height'

import styled from '@emotion/styled'

import { ReactComponent as ArrowDown } from '@lastpass/assets/svg/admin-console/icon-arrow-down.svg'

import { GoogleGroup } from './GoogleGroup'
import { GoogleGroupBranch } from './utilities'

interface RecursiveGroupProps {
  group: GoogleGroupBranch
  handleGroupSelection: (id: string, checked: boolean) => void
  hasMarginLeft: boolean
  recursiveExpand?: boolean
}

const Container = styled.div<{ hasMarginLeft: boolean }>`
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? 32 : 0)}px;
`

const StyledArrowDown = styled(ArrowDown)<{ hidden: boolean }>`
  width: 16px;
  margin-right: 16px;
  margin-bottom: -3px;
  opacity: 0.65;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  cursor: pointer;
`

const StyledArrowUp = styled(StyledArrowDown)`
  transform: rotate(180deg);
  opacity: 1;
`

const CHILD_LENGTH_TRESHOLD_FOR_INDETERMINATE_STATE = 2

const isIndeterminateState = groupBranch => {
  if (
    groupBranch.children.length < CHILD_LENGTH_TRESHOLD_FOR_INDETERMINATE_STATE
  ) {
    return false
  }

  const isGroupSelectionSameAsChildSelection = groupBranch.children.every(
    child => child.selected === groupBranch.selected
  )

  const allChildHasSameSelection = groupBranch.children.every(
    child => child.selected === groupBranch.children[0].selected
  )

  return !isGroupSelectionSameAsChildSelection && !allChildHasSameSelection
}

export const RecursiveGroup = ({
  group,
  handleGroupSelection,
  hasMarginLeft,
  recursiveExpand = false
}: RecursiveGroupProps) => {
  const [expanded, setExpanded] = useState(false)

  const hasChildren = group.children.length > 0

  const indeterminate = isIndeterminateState(group)

  return (
    <Container hasMarginLeft={hasMarginLeft}>
      <GoogleGroup
        handleGroupSelection={(selected: boolean) =>
          handleGroupSelection(group.id, selected)
        }
        isIndeterminate={indeterminate}
        isSelected={group.selected}
        name={group.name}
      >
        {expanded ? (
          <StyledArrowUp
            data-qa="RecursiveGroupUpArrow"
            hidden={!hasChildren}
            onClick={() => {
              setExpanded(!expanded)
            }}
          />
        ) : (
          <StyledArrowDown
            data-qa="RecursiveGroupDownArrow"
            hidden={!hasChildren}
            onClick={() => {
              setExpanded(!expanded)
            }}
          />
        )}
      </GoogleGroup>
      <AnimateHeight
        height={expanded || recursiveExpand ? 'auto' : 0}
        data-qa="RecursiveGroupContent"
      >
        {group.children.map(child => (
          <RecursiveGroup
            key={child.id}
            group={child}
            handleGroupSelection={handleGroupSelection}
            hasMarginLeft={true}
            recursiveExpand={recursiveExpand}
          />
        ))}
      </AnimateHeight>
    </Container>
  )
}

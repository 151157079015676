import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetUrlRules(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getUrlRules() {
    try {
      yield put(urlRulesActions.setUrlRulesLoading(true))

      const urlRulesResult: UACServices.UrlRulesApi.Responses = yield call(
        enterpriseOptionServices.urlRules
      )
      yield put(urlRulesActions.setUrlRules(urlRulesResult.body.rules))

      yield put(urlRulesActions.setUrlRulesLoading(false))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

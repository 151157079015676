import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'

export function createUserLevelsSaga(userService: UACServices.Services) {
  return function* getUserLevelsSaga() {
    try {
      yield put(userDrawerActions.setUserProfileLoading(true))
      try {
        const result: UACServices.UserLevelsAPI.Responses = yield call(
          userService.userLevels
        )
        if (result.type === UACServices.UserLevelsAPI.SUCCESS) {
          const userLevels = result.body
          userLevels.unshift({
            id: 'user',
            name: 'User',
            isCustom: false
          })
          yield put(userDrawerActions.setUserLevels(userLevels))
        }
      } finally {
        yield put(userDrawerActions.setUserProfileLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { GroupOperationType } from '../operations/group-operations'
import { GroupListActions, GroupListActionTypes } from './actions'
import { GroupsListState } from './state'

export const initialState: GroupsListState = {
  table: {
    columns: [],
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false,
    operations: [GroupOperationType.deleteGroup]
  }
}

export function groupsListReducer(
  previousState: GroupsListState | undefined,
  action: GroupListActions
): GroupsListState {
  const state = previousState || initialState
  switch (action.type) {
    case GroupListActionTypes.SET_GROUPS_LIST: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: []
        }
      }
    }
    case GroupListActionTypes.ADD_GROUPS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: table.results.concat(action.payload.groups)
        }
      }
    }
    case GroupListActionTypes.ADD_SELECTED_GROUPS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.concat(action.payload.groups)
        }
      }
    }
    case GroupListActionTypes.REMOVE_SELECTED_GROUPS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.filter(
            el => !action.payload.groups.includes(el)
          )
        }
      }
    }
    case GroupListActionTypes.SET_SELECTED_GROUPS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: action.payload.groups
        }
      }
    }
    case GroupListActionTypes.SET_GROUPS_LOADING: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          isLoading: action.payload.loading
        }
      }
    }
  }
  return state
}

import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Application from '@lastpass/admin-console-dependencies/server/applications/saml/get-application'
import * as SamlKeys from '@lastpass/admin-console-dependencies/server/applications/saml/get-saml-keys'
import * as UsernameAttributes from '@lastpass/admin-console-dependencies/server/applications/saml/get-username-attributes'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createInitialData(applicationsServices: UACServices.Services) {
  return function* getInitialData(
    action: ReturnType<typeof applicationsDrawerActions.getInitialData>
  ) {
    try {
      yield put(applicationsDrawerActions.setDrawerLoading(true))
      try {
        const [getSamlKeysResponse, getUsernameAttributesResponse] = yield all([
          call(applicationsServices.getSamlKeys),
          call(applicationsServices.getUsernameAttributes)
        ])
        if (getSamlKeysResponse.type === SamlKeys.SUCCESS) {
          const getSamlKeysResult = getSamlKeysResponse.body.samlKeys
          yield put(applicationsDrawerActions.setSamlKeys(getSamlKeysResult))
        }
        if (getUsernameAttributesResponse.type === UsernameAttributes.SUCCESS) {
          const getAttributesResult =
            getUsernameAttributesResponse.body.usernameAttributes
          yield put(
            applicationsDrawerActions.setUsernameAttributes(getAttributesResult)
          )
        }

        const id = action.payload.params.path.id
        if (id) {
          const getSelectedAppResponse: Application.Responses = yield call(
            applicationsServices.getApplication,
            id
          )
          if (getSelectedAppResponse.type == Application.SUCCESS) {
            yield put(
              applicationsDrawerActions.setSelectedApplication(
                getSelectedAppResponse.body.applicationResponse
              )
            )
            yield put(applicationsDrawerActions.getAssignedElements(id))
          }
        }
      } finally {
        yield put(applicationsDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as DuoSecurityLogo } from '@lastpass/assets/svg/admin-console/policies/duo-logo.svg'
import { ReactComponent as LastPassAuthenticatorLogo } from '@lastpass/assets/svg/admin-console/policies/lastpass-authenticator-logo.svg'
import { ReactComponent as RsaLogo } from '@lastpass/assets/svg/admin-console/policies/rsa-logo.svg'
import { ReactComponent as SalesForceLogo } from '@lastpass/assets/svg/admin-console/policies/salesforce-logo.svg'
import { ReactComponent as SecureAuthLogo } from '@lastpass/assets/svg/admin-console/policies/secureauth-logo.svg'
import { ReactComponent as SymantecLogo } from '@lastpass/assets/svg/admin-console/policies/symantec-logo.svg'
import { AuthMethod, Selection } from '@lastpass/tracking/tracking-plans/uac'

import { MultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'

import { EnumDictionary } from '../EnumValues'

export const MultifactorMethodNameMapping: EnumDictionary<
  MultifactorOptionType,
  ReactElement
> = {
  [MultifactorOptionType.TotpApp]: (
    <Trans>Time-based one-time passcode (TOTP) apps</Trans>
  ),
  [MultifactorOptionType.Salesforce]: (
    <Trans>Salesforce Authenticator push</Trans>
  ),
  [MultifactorOptionType.DuoSecurity]: <Trans>Duo Security</Trans>,
  [MultifactorOptionType.SymantecVip]: <Trans>Symantec VIP</Trans>,
  [MultifactorOptionType.RsaSecureId]: <Trans>RSA SecurID</Trans>,
  [MultifactorOptionType.SecureAuth]: <Trans>SecureAuth</Trans>
}

export const StyledLpAuthLogo = styled(LastPassAuthenticatorLogo)`
  margin-right: 16px;
  width: 48px;
  height: 47.23px;
`

export const MultifactorLogoMapping: EnumDictionary<
  MultifactorOptionType,
  ReactElement
> = {
  [MultifactorOptionType.TotpApp]: <StyledLpAuthLogo />,
  [MultifactorOptionType.Salesforce]: <SalesForceLogo />,
  [MultifactorOptionType.DuoSecurity]: <DuoSecurityLogo />,
  [MultifactorOptionType.SymantecVip]: <SymantecLogo />,
  [MultifactorOptionType.RsaSecureId]: <RsaLogo />,
  [MultifactorOptionType.SecureAuth]: <SecureAuthLogo />
}

export const MultifactorTypeAuthMethodMapping: EnumDictionary<
  MultifactorOptionType,
  AuthMethod
> = {
  [MultifactorOptionType.DuoSecurity]: 'Duo Security',
  [MultifactorOptionType.RsaSecureId]: 'RSA SecureID',
  [MultifactorOptionType.Salesforce]: 'Salesforce',
  [MultifactorOptionType.SecureAuth]: 'SecureAuth',
  [MultifactorOptionType.SymantecVip]: 'Symantec VIP',
  [MultifactorOptionType.TotpApp]: 'TOTP App'
}

export const AppliesToSelectionMapping: EnumDictionary<
  AppliesToOptions,
  Selection
> = {
  [AppliesToOptions.all]: 'All',
  [AppliesToOptions.exclusive]: 'Exclude',
  [AppliesToOptions.inclusive]: 'Subset'
}

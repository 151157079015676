import React, { FunctionComponent, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'

const Container = styled.div`
  margin: 36px 24px 36px 24px;
  display: flex;
  flex-direction: column;
`

interface MultifactorOptionsContainerProps {
  setCompanyID: Function
  cards: ReactElement
}

export const MultifactorOptionsContainer: FunctionComponent<MultifactorOptionsContainerProps> = ({
  setCompanyID,
  cards
}) => {
  const companyId = useSelector(
    (state: AppState) => state.companyInfo.details.accountNumber
  )
  setCompanyID(companyId)
  return <Container>{cards}</Container>
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'

import { ReactComponent as SuccessIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/success-tick.svg'
import { Theme } from '@lastpass/lastkit/theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
const Text = styled.div`
  color: ${Theme.colors.green700};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

interface ActionSuccessProps {
  message: MessageDescriptor
}

export const ActionSuccess: FC<ActionSuccessProps> = ({ message }) => {
  const { _ } = useLingui()

  return (
    <Container data-qa="ActionSuccess">
      <SuccessIcon />
      <Text>{_(message)}</Text>
    </Container>
  )
}

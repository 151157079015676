import React, { FunctionComponent, HTMLAttributes } from 'react'

import styled from '@emotion/styled'

import { TextButton, TextButtonProps } from './TextButton'

interface ListItemProps extends HTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentType
  noMargin?: boolean
  noClick?: boolean
}

export const StyledDropdownListItem = styled(TextButton)<{
  noMargin?: boolean
  noClick?: boolean
}>`
  display: block;
  margin: 8px 16px;
  white-space: nowrap;
  &:first-of-type {
    ${props => (props.noMargin ? '' : 'margin-top: 16px;')}
  }

  &:last-of-type {
    ${props => (props.noMargin ? '' : 'margin-bottom: 16px;')}
  }
  ${props =>
    props.noClick &&
    `
    color: ${props.theme.colors.neutral700};
    cursor: default;
    &:hover{
      color: ${props.theme.colors.neutral700};
    }
  `}
`

export const DropdownListItem: FunctionComponent<ListItemProps &
  TextButtonProps> = ({ icon, ...props }) => {
  const StyledIcon = icon
    ? styled(icon)`
        height: 16px;
        width: 16px;
        padding-right: 8px;
        margin-bottom: -3px;
      `
    : undefined
  return (
    <StyledDropdownListItem {...props}>
      {StyledIcon && <StyledIcon />}
      {props.children}
    </StyledDropdownListItem>
  )
}

import React, { FC } from 'react'

import { i18n } from '@lingui/core'

import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import {
  Dropdown,
  DropdownListItem,
  DropdownProps,
  IconButton
} from '@lastpass/lastkit'

import { ManagedCompanyListOperation } from '@lastpass/admin-console-dependencies/services/managed-companies/list/operations'

interface OperationsDropdownProps extends Omit<DropdownProps, 'button'> {
  operations: ManagedCompanyListOperation[]
}

export const OperationsDropdown: FC<OperationsDropdownProps> = ({
  operations,
  ...props
}) => {
  if (!operations.length) {
    return null
  }

  return (
    <Dropdown
      {...props}
      button={props => (
        <IconButton
          {...props}
          icon={More}
          data-qa="ManagedCompanyListOperationsDropdownButton"
        />
      )}
    >
      {operations.map(({ name, callback }) => (
        <DropdownListItem
          data-qa="ManagedCompanyListOperationDropdownItem"
          key={typeof name === 'string' ? name : name.id}
          onClick={callback}
        >
          {typeof name === 'string' ? name : i18n._(name)}
        </DropdownListItem>
      ))}
    </Dropdown>
  )
}

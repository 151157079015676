import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as UsersIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components'
import { DrawerTable, TableView, TableViewButton } from '@lastpass/lastkit'

import {
  AvailabilityStatus,
  PsaCompanyContractAssignment,
  PsaCompanyContractAssignmentOption
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import {
  getTableColumns,
  OrderDirections
} from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/multiple-companies/MultipleCompaniesAssignmentTableColumns'
import { AgreementSelectionMessages } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/helpers/get-agreement-selection-message'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'

const StyledTableViewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: inherit;
`

const StyledUsersIcon = styled(UsersIcon)`
  height: 120px;
`

const createNewTableData = (
  results
): Table<PsaCompanyContractAssignmentOption> => {
  return {
    results,
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export interface MultipleCompaniesAssignmentTableProps {
  actualAssignmentsMap: AssignmentsMapping
  assignmentOptions: PsaCompanyContractAssignmentOption[]
  qaData: string
  searchText: string
  messages: AgreementSelectionMessages
  onClearSearch: () => void
  onAssignmentChange: (
    assignment: PsaCompanyContractAssignment,
    availability: AvailabilityStatus
  ) => void
}

export const MultipleCompaniesAssignmentTable: FunctionComponent<MultipleCompaniesAssignmentTableProps> = ({
  actualAssignmentsMap,
  assignmentOptions,
  qaData,
  searchText,
  messages,
  onClearSearch,
  onAssignmentChange
}) => {
  const [orderDirection, setOrderDirection] = useState<OrderDirections>(
    OrderDirections.ASC
  )

  const handleOrderingChange = useCallback(
    column => {
      if (column) {
        const newOrderDirection =
          orderDirection === OrderDirections.ASC
            ? OrderDirections.DESC
            : OrderDirections.ASC
        setOrderDirection(newOrderDirection)
      }
    },
    [orderDirection]
  )

  const sortedAgreements = useMemo(() => {
    const direction = orderDirection === OrderDirections.ASC ? 1 : -1
    const sortedAgreements = assignmentOptions.sort((agreement1, agreement2) =>
      agreement1.company.name > agreement2.company.name
        ? direction
        : direction * -1
    )
    return createNewTableData(sortedAgreements)
  }, [assignmentOptions, orderDirection])

  return (
    <DrawerTable
      table={sortedAgreements}
      qadata={qaData}
      loadingView={<Loading color="blue900" active={true} />}
      columns={getTableColumns(
        actualAssignmentsMap,
        orderDirection,
        messages,
        onAssignmentChange
      )}
      disableRowHover={true}
      footerNodes={<></>}
      onOrderingChanged={handleOrderingChange}
      isFilterApplied={!assignmentOptions.length && !!searchText}
      noResultsView={
        <StyledTableViewContainer>
          <TableView
            icon={<StyledUsersIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
            qadata="NoResultView"
            actionButton={
              <TableViewButton data-qa="ClearSearch" onClick={onClearSearch}>
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        </StyledTableViewContainer>
      }
    />
  )
}

import {
  SecurityReportDrawerActions,
  SecurityReportDrawerActionTypes
} from './actions'
import { SecurityReportDrawerState } from './state'

export const initialSecurityReportDrawerState: SecurityReportDrawerState = {
  totalNumberOfCsvRows: 0,
  recoveryKeyDecryptionData: {
    requesterIterationCount: 0,
    requesterEncryptedPrivateSharingKey: '',
    encryptedUserRecoveryKeys: { '': '' }
  },
  downloadReportName: 'urls-in-vaults',
  securityReportFiles: [],
  decryptionData: [
    {
      sharedFolderId: '',
      encryptedSharedFolderEncryptionKey: '',
      recoveryKey: '',
      privateSharingKey: '',
      encryptedSharedFolderEncryptionKeyOwner: 'user',
      encryptedSharedFolderEncryptionKeyType: 'aes',
      userId: ''
    }
  ],
  masterPasswordValidationData: {
    encryptedPrivateRsaKey: '',
    requesterAdminIterationCount: 0
  },
  isLoading: false
}

export function securityReportDrawerReducer(
  previousState: SecurityReportDrawerState | undefined,
  action: SecurityReportDrawerActions
): SecurityReportDrawerState {
  const state = previousState || initialSecurityReportDrawerState
  switch (action.type) {
    case SecurityReportDrawerActionTypes.SET_TOTAL_NUMBER_OF_CSV_ROWS:
      return {
        ...state,
        totalNumberOfCsvRows: action.payload
      }
    case SecurityReportDrawerActionTypes.SET_RECOVERY_KEY_DECRYPTION_DATA:
      return {
        ...state,
        recoveryKeyDecryptionData: action.payload.recoveryKeyDecryptionData
      }
    case SecurityReportDrawerActionTypes.SET_DOWNLOAD_REPORT_NAME:
      return {
        ...state,
        downloadReportName: action.payload
      }
    case SecurityReportDrawerActionTypes.SET_SECURITY_REPORT_FILES:
      return {
        ...state,
        securityReportFiles: action.payload
      }
    case SecurityReportDrawerActionTypes.SET_URLS_IN_SHARED_FOLDER_DECRYPTION_DATA:
      return {
        ...state,
        decryptionData: action.payload
      }
    case SecurityReportDrawerActionTypes.SET_MASTER_PASSWORD_VALIDATION_DATA:
      return {
        ...state,
        masterPasswordValidationData: action.payload
      }
    case SecurityReportDrawerActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    default: {
      return state
    }
  }
}

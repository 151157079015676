import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getApplicableUserOperations } from '@lastpass/admin-console-dependencies/services/user-operations/get-applicable-user-operations'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CompanySettings } from '@lastpass/admin-console-dependencies/types/company-settings'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import { UserProfile } from '@lastpass/admin-console-dependencies/types/user'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const getCompanyDetails = (state: AppState) => state.companyInfo.details
export const getCompanySettings = (state: AppState) =>
  state.companyInfo.settings
export const getPermissions = (state: AppState) => state.global.permissions
export const getCurrentAdminLevel = (state: AppState) =>
  state.usersList.currentAdminLevel
export const getSelectedUsers = (state: AppState) =>
  state.usersList.table.checkedRecords
export const getCurrentUserInfo = (state: AppState) => state.global.currentUser
export const getUserProfileInfo = (state: AppState) => state.usersDrawer.profile
export const getAdfsIsEnabled = (state: AppState) =>
  state.federatedLogin.adfs.isEnabled

export function createUserOperationsSaga(userService: UACServices.Services) {
  return function* getUserOperationsSaga(
    action: ReturnType<typeof userDrawerActions.getUserOperations>
  ) {
    const id = action.payload.params.path.id
    if (id) {
      try {
        yield put(userDrawerActions.setUserOperationsLoading(true))

        const companyDetails = yield select(getCompanyDetails)
        const companySettings: CompanySettings = yield select(
          getCompanySettings
        )
        const permissions: AdminPermissions[] = yield select(getPermissions)
        const currentAdminLevel: AdminLevel = yield select(getCurrentAdminLevel)
        const currentUserInfo: CurrentUser = yield select(getCurrentUserInfo)
        const userProfileInfo: UserProfile = yield select(getUserProfileInfo)
        const isAdfsEnabled: boolean = yield select(getAdfsIsEnabled)
        const selectedUsers: User[] = yield select(getSelectedUsers)

        const result: UACServices.UserAccountAPI.Responses = yield call(
          userService.userAccountDetails,
          id
        )

        if (result.type === UACServices.UserAccountAPI.SUCCESS) {
          const accountDetails = result.body
          yield put(userDrawerActions.setAccountDetails(accountDetails))
          const userOperations = yield call(
            getApplicableUserOperations,
            [
              [
                accountDetails.accountStatus,
                accountDetails.mfaStatus,
                accountDetails.defaultMultiFactorType || null,
                accountDetails.superAdminKeyOwnershipForUser?.hasActingAdmin ||
                  false,
                accountDetails.provisionFederatedStatus,
                accountDetails.adminLevel
                  ? accountDetails.adminLevel.id === AdminLevel.superAdmin
                  : false
              ]
            ],
            companyDetails,
            companySettings.isNonDisabledUserRemovalAllowed,
            permissions,
            currentAdminLevel,
            selectedUsers,
            currentUserInfo,
            userProfileInfo,
            isAdfsEnabled
          )

          yield put(userDrawerActions.setUserOperations(userOperations))
        }
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      } finally {
        yield put(userDrawerActions.setUserOperationsLoading(false))
      }
    }
  }
}

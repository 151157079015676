import { CompanySettings } from '@lastpass/admin-console-dependencies/types/company-settings'

export enum SubscriptionType {
  none = 'none',
  mfa = 'mfa',
  enterprise = 'enterprise',
  iDaaS = 'iDaaS',
  teams = 'teams',
  family = 'family'
}

export interface CompanyDetails {
  accountNumber: number
  name: string | null
  subscription: SubscriptionType
  isTrial: boolean
  subscriptionEndDate: Date | null
  canBuyLicenses: boolean
  parentCompanyId: number | null
  hasLastPassSupport: boolean
  permissions: PermissionTree | null
  enterpriseVersion: string | null
  isFederatedLoginEnabled: boolean
  isAggregator: boolean
  isBillingUsageBased: boolean
  minimumHashIterationCount: number
  isTransferVaultHidden: boolean
}

export interface CompanyInfoState {
  details: CompanyDetails
  totalLicenses?: number
  remainingLicenses?: number
  settings: CompanySettings
  cidOverride?: string
  mspBillingDetails?: MspBillingDetails
}

export interface PermissionTree {
  touchSalesOnly: boolean
  isTrial: boolean
  isMsp: boolean
  isManaged: boolean
  grandfathered: boolean
  allowStartBusinessTrial: boolean
  allowUnifiedAdminConsoleAccess: boolean
  allowUnlimitedApplicationsCount: boolean
  applicationCountLimit: number
  allowLastPassVault: boolean
  allowLastPassSso: boolean
  allowPasswordless: boolean
  allowPolicies: boolean
  allowLastPassWorkstation: boolean
  allowLastPassVpn: boolean
  allowMicrosoftAzureAd: boolean
  allowThirdPartyIdp: boolean
  allowOldAdminConsoleAccess: boolean
  allowFamilyAsABenefit: boolean
  allowContactSupport: boolean
}

export interface MspBillingDetails {
  billingInfoUrl: string
  currency: string
  enterPaymentInfoAllowed: boolean
  firstMspBillingDateUtc: string | null
  isOverride: boolean
  isPaymentInfoRequired: boolean
  isTrialExpired: boolean
  listPrice: number
  mfaAddonPrice: number
  over500Users: boolean
  trialRemainingDays: number
}

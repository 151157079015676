import { takeLatest } from 'redux-saga/effects'

import { FederatedLoginActionTypes } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

import { vaultReEncryption } from './vault-re-encryption'
import { vaultReEncryptionOffer } from './vault-re-encryption-offer'

export function createVaultReEncryptionSaga() {
  return function*() {
    yield takeLatest(
      FederatedLoginActionTypes.START_VAULT_RE_ENCRYPTION,
      vaultReEncryption
    )
  }
}

export function createVaultReEncryptionOfferSaga() {
  return function*() {
    yield takeLatest(
      FederatedLoginActionTypes.START_VAULT_RE_ENCRYPTION_OFFER,
      vaultReEncryptionOffer
    )
  }
}

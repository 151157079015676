import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Autocomplete } from '@lastpass/lastkit/components/Autocomplete'

import { groupFilterActions } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import { GroupFilterState } from '@lastpass/admin-console-dependencies/state/common/group-filter/state'
import { groupId } from '@lastpass/admin-console-dependencies/types/group'
import { GroupFilter } from '@lastpass/admin-console-dependencies/types/group-filter'

interface SelectUserGroupProps {
  updateSelection: (update: groupId[]) => void
  filters?: groupId[]
  asyncThreshold?: number
}

let loadOptionsPromiseResolves: (
  value: GroupFilter[] | PromiseLike<GroupFilter[]>
) => void

export const SelectUserGroups: FunctionComponent<SelectUserGroupProps> = ({
  updateSelection,
  filters = [],
  asyncThreshold = 1000
}) => {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState<string>('')

  const groupFilter = useSelector<AppState, GroupFilterState>(
    appState => appState.groupFilter
  )

  const autocompleteLoadOptions = (query: string) => {
    setSearchInput(query)

    return new Promise(resolve => {
      loadOptionsPromiseResolves = resolve
    })
  }

  useEffect(() => {
    dispatch(groupFilterActions.getGroupList(asyncThreshold))
  }, [asyncThreshold, dispatch])

  useEffect(() => {
    searchInput && dispatch(groupFilterActions.searchGroups(searchInput))
  }, [searchInput, dispatch])

  useEffect(() => {
    if (
      groupFilter.searchResults.searchInput === searchInput &&
      typeof loadOptionsPromiseResolves === 'function'
    ) {
      loadOptionsPromiseResolves(groupFilter.searchResults.results)
    }
  }, [searchInput, groupFilter.searchResults])

  return (
    <Autocomplete
      closeMenuOnSelect={false}
      placeholder={i18n._(msg`Show all groups`)}
      searchPlaceholder={msg`Search groups...`}
      options={
        groupFilter.initialList.totalResults < asyncThreshold
          ? groupFilter.initialList.results
          : []
      }
      hideSelectedOptions={false}
      isMulti
      fluid
      value={filters.map(groupId => ({
        value: groupId,
        label: groupFilter.idMapping[groupId] || ''
      }))}
      onChange={selectedItems => {
        if (!Array.isArray(selectedItems)) {
          return
        }
        updateSelection(selectedItems.map(item => item.value))
        dispatch(groupFilterActions.setGroupListByIdList(selectedItems))
      }}
      loadOptions={
        groupFilter.initialList.totalResults >= asyncThreshold
          ? autocompleteLoadOptions
          : null
      }
    >
      <Trans>Group</Trans>
    </Autocomplete>
  )
}

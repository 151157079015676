import { i18n, Messages } from '@lingui/core'

import { getCookie } from '@lastpass/admin-console-dependencies/services/get-cookie'

export enum CatalogLanguageCodes {
  english = 'en',
  german = 'de',
  dutch = 'nl',
  french = 'fr',
  spanish = 'es',
  italian = 'it',
  portuguese = 'pt-br'
}

export enum LastPassLanguageCodes {
  english = 'en_US',
  german = 'de_DE',
  dutch = 'nl_NL',
  french = 'fr_FR',
  spanish = 'es_ES',
  italian = 'it_IT',
  portuguese = 'pt_BR'
}

const lastPassToCatalogLanguageCodeMap = {
  [LastPassLanguageCodes.english]: CatalogLanguageCodes.english,
  [LastPassLanguageCodes.german]: CatalogLanguageCodes.german,
  [LastPassLanguageCodes.dutch]: CatalogLanguageCodes.dutch,
  [LastPassLanguageCodes.french]: CatalogLanguageCodes.french,
  [LastPassLanguageCodes.spanish]: CatalogLanguageCodes.spanish,
  [LastPassLanguageCodes.italian]: CatalogLanguageCodes.italian,
  [LastPassLanguageCodes.portuguese]: CatalogLanguageCodes.portuguese
}

const loadCatalog = async (
  languageCode: CatalogLanguageCodes
): Promise<Messages> => {
  const { messages } = await import(
    /* webpackChunkName: "[request]" */ `@lastpass/locales/${languageCode}/messages.js`
  )
  return messages
}

export const getLastPassLanguageCodeFromCookie = (): LastPassLanguageCodes => {
  const cookieKey = 'lang'
  const cookieValue = getCookie(cookieKey) as LastPassLanguageCodes
  if (!Object.values(LastPassLanguageCodes).includes(cookieValue)) {
    return LastPassLanguageCodes.english
  }
  return cookieValue
}

export const getCatalogLanguageCodeFromLastPassLanguageCode = (
  languageCode: LastPassLanguageCodes
): CatalogLanguageCodes =>
  lastPassToCatalogLanguageCodeMap[languageCode] || CatalogLanguageCodes.english

export const selectedCatalogLanguageCode: CatalogLanguageCodes = getCatalogLanguageCodeFromLastPassLanguageCode(
  getLastPassLanguageCodeFromCookie()
)

export const initLocalization = async (): Promise<void> => {
  const messages = await loadCatalog(selectedCatalogLanguageCode)
  i18n.load(selectedCatalogLanguageCode, messages)
  i18n.activate(selectedCatalogLanguageCode)
}

import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createResetMfaSharedSecrets(
  enterpriseOptionServices: UACServices.Services
) {
  return function* resetMfaSharedSecrets() {
    try {
      yield put(
        globalActions.setDialog({
          type: 'reset-mfa-shared-secrets-dialog'
        })
      )

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const enabledMultifactorOptionsResult: UACServices.ResetMfaSharedSecretsAPI.Responses = yield call(
          enterpriseOptionServices.resetMfaSharedSecrets
        )

        enabledMultifactorOptionsResult.body
          ? yield put(
              globalActions.setNotification({
                message: msg`Process started. We’ll send an email when MFA shared secret reset is complete.`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
          : yield put(
              globalActions.setNotification({
                message: msg`Already in progress. We’ll send an email when MFA shared secret reset is complete.`,
                type: NotificationType.warning,
                autoDismiss: true
              })
            )
      }
    } catch (e) {
      yield put(
        globalActions.setNotification({
          message: msg`Something went wrong. Check your connection and try again.`,
          type: NotificationType.alert,
          autoDismiss: false
        })
      )
    }
  }
}

import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createMfaApplicationsGetStartedSaga() {
  return function* createMfaApplicationGetStartedSaga(
    actions: ReturnType<typeof globalActions.mfaApplicationsGetStarted>
  ) {
    const newRoute = actions.payload.newRoute

    yield newRoute && put(push(newRoute))
  }
}

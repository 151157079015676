export async function encryptRsa(value: Buffer, key: Buffer): Promise<Buffer> {
  const publicKey = await window.crypto.subtle.importKey(
    'spki',
    key,
    { name: 'RSA-OAEP', hash: { name: 'SHA-1' } },
    false,
    ['encrypt']
  )
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP'
    },
    publicKey,
    value
  )
  return Buffer.from(encrypted)
}

export async function decrypt(encrypted: Buffer, key: Buffer): Promise<Buffer> {
  const privateKey = await window.crypto.subtle.importKey(
    'pkcs8',
    key,
    { name: 'RSA-OAEP', hash: { name: 'SHA-1' } },
    false,
    ['decrypt']
  )
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP'
    },
    privateKey,
    encrypted
  )
  const decryptedString: string = new TextDecoder().decode(decrypted)

  return Buffer.from(decryptedString, 'hex')
}

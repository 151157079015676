import React from 'react'

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Chevron } from '@lastpass/components'

import { BodySemiboldStyle, CaptionBoldStyle } from '../styles'
import { FunctionComponentWithTheme } from '../theme'
import { Dropdown, DropdownProps } from './Dropdown'

interface UserDropdownProps {
  firstName: string
  lastName: string
  email: string
  onInitialExpand?: Function
}

const ButtonContainer = styled.button`
  color: ${props => props.theme.colors.neutral};
  font-family: ${props => props.theme.fonts.primary};
  display: flex;
  padding: 0px;
  height: 56px;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral700};
  width: 32px;
  height: 32px;
  border-radius: ${props => props.theme.radius.full};
`

const UserAvatarText = styled.span`
  ${CaptionBoldStyle}
  color: ${props => props.theme.colors.neutral};
`

const UsernameText = styled.span`
  ${BodySemiboldStyle}
  color: ${props => props.theme.colors.neutral};
  margin: 0px 8px;
`

const UserDropdownWithTheme: FunctionComponentWithTheme<UserDropdownProps> = props => {
  const Button: DropdownProps['button'] = ({ expanded, ...buttonProps }) => (
    <ButtonContainer
      {...buttonProps}
      data-qa={'TopNavigationUserDropdownButton'}
    >
      <UserAvatar>
        <UserAvatarText>
          {(!!props.firstName &&
            props.firstName.slice(0, 1) + props.lastName.slice(0, 1)) ||
            props.email.slice(0, 1)}
        </UserAvatarText>
      </UserAvatar>
      <UsernameText>
        {props.firstName || props.lastName || props.email}
      </UsernameText>
      <Chevron
        color={props.theme.colors.neutral}
        direction={expanded ? 'up' : 'down'}
      />
    </ButtonContainer>
  )
  return (
    <Dropdown
      {...props}
      button={Button}
      arrangerTopMargin={-10}
      onInitialExpand={props.onInitialExpand}
    >
      {props.children}
    </Dropdown>
  )
}

export const UserDropdown = withTheme(UserDropdownWithTheme)

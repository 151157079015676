import { StatusCodes } from 'http-status-codes'

import { fetchRetry } from '@lastpass/http/fetch-retry'

import {
  BulkUpdateUsersK1Response,
  BulkUpdateUsersParam,
  IdpUserData,
  UpdateUserParam
} from '../../types/federation-api'
import { FederatedOAuthManager } from '../auth/oidc-auth-service'
import { OpenIdProvider } from '../federation-enums'
import * as azureAd from './azure-ad'
import { AzureAdModule } from './azure-ad/types'

export type FederationFetchResponse<FetchResponseType> = {
  status: StatusCodes
  response: FetchResponseType | null
}
export type FederationFetch<FetchResponseType> = (
  path: string,
  method: string,
  body?: {},
  headers?: {}
) => Promise<FederationFetchResponse<FetchResponseType>>

export interface FederationAPI {
  config: {
    batchLimit?: number
  }
  getUsers: () => Promise<IdpUserData[]>
  updateUserK1: (updateData: UpdateUserParam) => Promise<boolean>
  batchUpdateUsersK1: (
    updateData: BulkUpdateUsersParam
  ) => Promise<BulkUpdateUsersK1Response[]>
}

export interface Module<
  GetUsersResponse,
  UpdateUserK1Response,
  BatchUpdateUsersK1Response
> {
  initialize: () => FederationFetch<
    GetUsersResponse | UpdateUserK1Response | BatchUpdateUsersK1Response
  >
  config: FederationAPI['config']
  getUsers: (
    federationFetch: FederationFetch<GetUsersResponse>
  ) => FederationAPI['getUsers']
  updateUserK1: (
    federationFetch: FederationFetch<UpdateUserK1Response>
  ) => FederationAPI['updateUserK1']
  batchUpdateUsersK1: (
    federationApi: FederationFetch<BatchUpdateUsersK1Response>
  ) => FederationAPI['batchUpdateUsersK1']
}

export const getModule = (idp: OpenIdProvider) => {
  switch (idp) {
    case OpenIdProvider.AzureAD:
      return azureAd as AzureAdModule
  }
  throw new Error(`Federation module for "${idp}" is not found`)
}

export const federationAPIFactory = (idp: OpenIdProvider): FederationAPI => {
  const idpModule = getModule(idp)
  const federationFetch = idpModule.initialize()

  return {
    config: idpModule.config || {},
    getUsers: idpModule.getUsers(
      federationFetch as Parameters<typeof idpModule.getUsers>[0]
    ),
    updateUserK1: idpModule.updateUserK1(
      federationFetch as Parameters<typeof idpModule.updateUserK1>[0]
    ),
    batchUpdateUsersK1: idpModule.batchUpdateUsersK1(
      federationFetch as Parameters<typeof idpModule.batchUpdateUsersK1>[0]
    )
  }
}

export function createFederationFetch(baseUrl = '', defaultHeaders?: {}) {
  return async function federationFetch<FetchResponseType>(
    path,
    method,
    body,
    headers
  ): Promise<FederationFetchResponse<FetchResponseType>> {
    const accessToken = await FederatedOAuthManager.getAccessToken()
    if (!accessToken) {
      throw new Error('unauthorized')
    }

    const contentType = body && {
      'content-type': 'application/json'
    }

    const url = path.match(/^https:\/\//) ? path : `${baseUrl}/${path}`

    const response = await fetchRetry(url, {
      method: method,
      headers: {
        ...defaultHeaders,
        ...headers,
        ...contentType,
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(body)
    })

    return {
      response:
        response.status === StatusCodes.NO_CONTENT
          ? null
          : await response.json(),
      status: response.status
    } as FederationFetchResponse<FetchResponseType>
  }
}

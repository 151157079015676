import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { DirectoryInfo } from '@lastpass/admin-console-dependencies/types/directory-info'

import { EnumDictionary } from '../EnumValues'

export const DirectoryInfoMapping: EnumDictionary<
  DirectoryInfo,
  ReactElement
> = {
  [DirectoryInfo.adConnector]: <Trans>AD Connector</Trans>,
  [DirectoryInfo.azureAD]: <Trans>Azure AD</Trans>,
  [DirectoryInfo.okta]: <Trans>Okta</Trans>,
  [DirectoryInfo.oneLogin]: <Trans>OneLogin</Trans>
}

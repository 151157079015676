import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading300 } from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'

import { ComingSoon } from '@lastpass/admin-console-dependencies/ui/common/ComingSoon'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

export const EmailNotificationsPage: React.FunctionComponent = () => {
  return (
    <>
      <PageHeaderContainer data-qa={'PageHeaderContainer'}>
        <Heading300 data-qa={'EmailNotificationsPageHeader'}>
          <Trans>Email notifications</Trans>
        </Heading300>
      </PageHeaderContainer>
      <ComingSoon oldUrl={'/settings/email-notifications'} />
    </>
  )
}

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  TeamsPolicy,
  TeamsPolicyCategories,
  TeamsPolicyMap,
  TeamsPolicyStatus
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export enum GeneralPoliciesListActionTypes {
  SET_GENERAL_POLICIES_LIST = '[general-policies-list] set',
  GET_GENERAL_POLICIES_LIST = '[general-policies-list] get',
  ADD_GENERAL_POLICIES = '[general-policies-list] add-general-policies',
  ADD_SELECTED_GENERAL_POLICIES = '[general-policies-list] add-selected-general-policies',
  REMOVE_SELECTED_GENERAL_POLICIES = '[general-policies-list] remove-selected-general-policies',
  SET_SELECTED_GENERAL_POLICIES = '[general-policies-list] set-selected-general-policies',
  SET_GENERAL_POLICIES_LOADING = '[general-policies-list] set-table-loading',
  EXPORT_GENERAL_POLICIES = '[general-policies-list] export-general-policies',
  UPDATE_GENERAL_POLICIES_DRAWERS = '[general-policies-list] update-general-policies-drawers',
  ADD_NEW_POLICY = '[general-policies-list] add-new-policy'
}

export interface GeneralPoliciesListQueryParams {
  search: string
  skip: number
  status: TeamsPolicyStatus[] | undefined
  category: TeamsPolicyCategories[] | undefined
}

export const generalPoliciesListActions = {
  setGeneralPoliciesList: (
    list: TeamsPolicy[],
    policyMap: TeamsPolicyMap,
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(GeneralPoliciesListActionTypes.SET_GENERAL_POLICIES_LIST, {
      list,
      policyMap,
      totalResults,
      resultsPerPage
    }),
  getGeneralPoliciesList: (
    params: DataFetchParams<GeneralPoliciesListQueryParams>,
    resultsPerPage = 25
  ) =>
    track(
      createAction(GeneralPoliciesListActionTypes.GET_GENERAL_POLICIES_LIST, {
        params,
        resultsPerPage
      }),
      {
        segment: uacEvent('General Policies Overview Viewed')
      }
    ),
  addNewPolicy: () =>
    track(createAction(GeneralPoliciesListActionTypes.ADD_NEW_POLICY, {}), {
      segment: uacEvent('Add New Policy Button Clicked')
    }),
  addSelectedGeneralPolicies: (generalPolicies: TeamsPolicy[]) =>
    createAction(GeneralPoliciesListActionTypes.ADD_SELECTED_GENERAL_POLICIES, {
      generalPolicies
    }),
  removeSelectedGeneralPolicies: (generalPolicies: TeamsPolicy[]) =>
    createAction(
      GeneralPoliciesListActionTypes.REMOVE_SELECTED_GENERAL_POLICIES,
      { generalPolicies }
    ),
  setSelectedGeneralPolicies: (generalPolicies: TeamsPolicy[]) =>
    createAction(GeneralPoliciesListActionTypes.SET_SELECTED_GENERAL_POLICIES, {
      generalPolicies
    }),
  addGeneralPolicies: (generalPolicies: TeamsPolicy[]) =>
    createAction(GeneralPoliciesListActionTypes.ADD_GENERAL_POLICIES, {
      generalPolicies
    }),
  setGeneralPoliciesLoading: (loading: boolean) =>
    createAction(GeneralPoliciesListActionTypes.SET_GENERAL_POLICIES_LOADING, {
      loading
    }),
  exportGeneralPolicies: (status: boolean) =>
    createAction(
      GeneralPoliciesListActionTypes.EXPORT_GENERAL_POLICIES,
      status
    ),
  updateGeneralPoliciesDrawers: (generalPoliciesMap: TeamsPolicyMap) =>
    createAction(
      GeneralPoliciesListActionTypes.UPDATE_GENERAL_POLICIES_DRAWERS,
      { generalPoliciesMap }
    )
}

export type GeneralPoliciesListActions = ActionsUnion<
  typeof generalPoliciesListActions
>

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { PermissionAreaType } from '@lastpass/admin-console-dependencies/types/permissions/permission-area-type'

import { EnumDictionary } from '../EnumValues'

export const PermissionAreaTypeMapping: EnumDictionary<
  PermissionAreaType,
  ReactElement
> = {
  [PermissionAreaType.dashboard]: <Trans>Dashboard</Trans>,

  [PermissionAreaType.users]: <Trans>Users</Trans>,
  [PermissionAreaType.usersMasterPasswordReset]: (
    <Trans>Master password reset</Trans>
  ),
  [PermissionAreaType.managedCompanies]: <Trans>Managed companies</Trans>,
  [PermissionAreaType.addManagedCompany]: <Trans>Add managed company</Trans>,
  [PermissionAreaType.suspendManagedCompany]: (
    <Trans>Suspend managed company</Trans>
  ),
  [PermissionAreaType.detachManagedCompany]: (
    <Trans>Detach managed company</Trans>
  ),
  [PermissionAreaType.managedCompanyLicenseManagement]: (
    <Trans>License management</Trans>
  ),
  [PermissionAreaType.configureManagedServiceProviderPsa]: (
    <Trans>Configure PSA</Trans>
  ),
  [PermissionAreaType.usersPasswordsSettings]: (
    <Trans>Require password change</Trans>
  ),
  [PermissionAreaType.usersSessionsDestroy]: <Trans>Users{"'"} sessions</Trans>,
  [PermissionAreaType.groups]: <Trans>Groups</Trans>,
  [PermissionAreaType.directoriesAndFederation]: (
    <Trans>Directories and federation</Trans>
  ),

  [PermissionAreaType.sso]: <Trans>SSO</Trans>,
  [PermissionAreaType.legacyAndVpn]: <Trans>Legacy {'&'} VPN</Trans>,
  [PermissionAreaType.passwordApps]: <Trans>Password apps</Trans>,
  [PermissionAreaType.sharedFolders]: <Trans>Shared folders</Trans>,
  [PermissionAreaType.mfa]: <Trans>MFA</Trans>,

  [PermissionAreaType.policies]: <Trans>Policies</Trans>,

  [PermissionAreaType.generalReports]: <Trans>General reports</Trans>,
  [PermissionAreaType.reportingSsoLoginActivity]: (
    <Trans>LastPass SSO login activity</Trans>
  ),
  [PermissionAreaType.reportingSamlResponse]: (
    <Trans>LastPass SAML response</Trans>
  ),
  [PermissionAreaType.reportingMfaUserActivity]: (
    <Trans>LastPass MFA user activity</Trans>
  ),
  [PermissionAreaType.reportingMfaAdminActivity]: (
    <Trans>LastPass MFA admin activity</Trans>
  ),

  [PermissionAreaType.advancedEnterpriseOptions]: (
    <Trans>Enterprise options</Trans>
  ),
  [PermissionAreaType.advancedInstallSoftware]: <Trans>Install software</Trans>,
  [PermissionAreaType.emailNotifications]: <Trans>Email notifications</Trans>,
  [PermissionAreaType.advancedEnterpriseApi]: <Trans>Enterprise API</Trans>,
  [PermissionAreaType.keys]: <Trans>Keys</Trans>,
  [PermissionAreaType.userLevelMfa]: <Trans>User-level MFA</Trans>
}

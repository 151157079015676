import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { PrimaryButton, TextButton } from '@lastpass/lastkit'
import {
  SearchInput,
  StyledSpacer,
  TabNavigation,
  TabNavigationItem
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { Entities } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { GroupsTableComponent } from './GeneralApplicationsGroupsTable'
import { RolesTableComponent } from './GeneralApplicationsRolesTable'
import { UsersTableComponent } from './GeneralApplicationsUsersTable'

export interface AssignNewProps {
  closeLink: string
}

type AssignNewEntityComponentProps = LastPassComponentProps<
  ApplicationDrawerState,
  typeof applicationsDrawerActions,
  AssignNewProps
>

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

export const AssignNewEntityComponent: React.FunctionComponent<AssignNewEntityComponentProps> = ({
  actions,
  state,
  props
}) => {
  const queryParams = useQueryParams(Namespace.applications)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      applicationsDrawerActions.getUserList({ query: queryParams, path: {} })
    )
    dispatch(
      applicationsDrawerActions.getGroupList({ query: queryParams, path: {} })
    )
    dispatch(
      applicationsDrawerActions.getRoleList({ query: queryParams, path: {} })
    )
  }, [dispatch, queryParams])

  const history = useHistory()

  const assignDisabled =
    state.availableUsers.checkedRecords.length === 0 &&
    state.availableGroups.checkedRecords.length === 0 &&
    state.availableRoles.checkedRecords.length === 0

  const setSearchInput = () => {
    if (state.showTable === Entities.users) {
      return state.usersSearchInput
    }
    if (state.showTable === Entities.groups) {
      return state.groupsSearchInput
    }
    if (state.showTable === Entities.roles) {
      return state.rolesSearchInput
    }
  }

  useEffect(() => {
    applicationsDrawerActions.onAssignUsersViewed()
  }, [])

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  return (
    <>
      <TableContainer>
        <SearchInput
          data-qa={'AssignSearchBar'}
          value={setSearchInput()}
          onChange={event => {
            if (state.showTable === Entities.users) {
              actions.setUserListSearchInput(event.target.value)
              actions.getUserList({
                query: { search: event.target.value },
                path: {}
              })
            }
            if (state.showTable === Entities.groups) {
              actions.getGroupList({
                query: { search: event.target.value },
                path: {}
              })
              actions.setGroupListSearchInput(event.target.value)
            }
            if (state.showTable === Entities.roles) {
              actions.getRoleList({
                query: { search: event.target.value },
                path: {}
              })
              actions.setRoleListSearchInput(event.target.value)
            }
          }}
          placeholder={msg`Search users, groups & roles...`}
        />
        <StyledTabContainer data-qa={'TabNavigationBar'}>
          <TabNavigation>
            <div
              data-qa={'AssignUsersTab'}
              onClick={() => {
                actions.showTable(Entities.users)
              }}
            >
              <StyledTabNavigationItem
                active={state.showTable === Entities.users}
              >
                <Trans>Users</Trans>
                {` (${state.availableUsers.totalResults})`}
              </StyledTabNavigationItem>
            </div>
            <div
              data-qa={'AssignGroupsTab'}
              onClick={() => {
                actions.showTable(Entities.groups)
              }}
            >
              <StyledTabNavigationItem
                active={state.showTable === Entities.groups}
              >
                <Trans>Groups</Trans>
                {` (${state.availableGroups.totalResults})`}
              </StyledTabNavigationItem>
            </div>
            {!isNoPasswordDeprecated && (
              <div
                data-qa={'AssignRolesTab'}
                onClick={() => {
                  actions.showTable(Entities.roles)
                }}
              >
                <StyledTabNavigationItem
                  active={state.showTable === Entities.roles}
                >
                  <Trans>Roles</Trans>
                  {` (${state.availableRoles.totalResults})`}
                </StyledTabNavigationItem>
              </div>
            )}
          </TabNavigation>
        </StyledTabContainer>
      </TableContainer>
      {state.showTable === Entities.users ? (
        <UsersTableComponent
          table={state.availableUsers}
          checkable={{
            addSelectedRecords: actions.addSelectedUsers,
            removeSelectedRecords: actions.removeSelectedUsers,
            setSelectedRecords: actions.setSelectedUsers
          }}
          title={<Trans>Please search for users</Trans>}
          text={
            <Trans>To assign users to this application, search above.</Trans>
          }
          actionButton={<></>}
        />
      ) : null}
      {state.showTable === Entities.groups ? (
        <GroupsTableComponent
          table={state.availableGroups}
          checkable={{
            addSelectedRecords: actions.addSelectedGroups,
            removeSelectedRecords: actions.removeSelectedGroups,
            setSelectedRecords: actions.setSelectedGroups
          }}
          title={<Trans>Please search for groups</Trans>}
          text={
            <Trans>To assign groups to this application, search above.</Trans>
          }
          actionButton={<></>}
        />
      ) : null}
      {state.showTable === Entities.roles ? (
        <RolesTableComponent
          table={state.availableRoles}
          checkable={{
            addSelectedRecords: actions.addSelectedRoles,
            removeSelectedRecords: actions.removeSelectedRoles,
            setSelectedRecords: actions.setSelectedRoles
          }}
          title={<Trans>Please search for roles</Trans>}
          text={
            <Trans>To assign roles to this application, search above.</Trans>
          }
          actionButton={<></>}
        />
      ) : null}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Cancel"
            onClick={() => history.push(props.closeLink)}
            data-qa="CancelButton"
          >
            <Trans>Cancel</Trans>
          </TextButton>
          <PrimaryButton
            id="AssignButton"
            type="submit"
            key="Assign"
            onClick={() => {
              actions.assign()
              actions.nextStep(ApplicationDrawerSteps.assign)
            }}
            disabled={assignDisabled}
            data-qa="AssignButton"
          >
            <Trans>Assign</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

export function createAssignNewEntity<
  TState extends { applicationDrawer: ApplicationDrawerState }
>() {
  return connectRedux(
    AssignNewEntityComponent,
    (state: TState) => state.applicationDrawer,
    applicationsDrawerActions
  )
}

export type AssignUsers = ReturnType<typeof createAssignNewEntity>

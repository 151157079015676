import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPoliciesListGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { mapGetPolicyListResultToPolicyMap } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

import { RequirePasswordlessViaLastPassPolicyKey } from './get-initial-data'

export const getRequirePasswordlessViaLastPassPolicy = (state: AppState) =>
  state.passwordlessContainer.RequirePasswordlessViaLastPassPolicy

export function createInitDrawerPolicySaga(
  policyService: UACServices.Services
) {
  return function* initDrawerPolicySaga() {
    try {
      let policy: TeamsPolicy = yield select(
        getRequirePasswordlessViaLastPassPolicy
      )

      if (!policy.policyKey) {
        const result = yield call(policyService.generalPoliciesListGet)
        if (result.type === GeneralPoliciesListGetAPI.SUCCESS) {
          policy = mapGetPolicyListResultToPolicyMap(
            result.body.generalPoliciesListResponse
          )[0][RequirePasswordlessViaLastPassPolicyKey]
        }
      }

      yield put(passwordlessDrawerActions.setDrawerPolicy(policy))
      yield put(passwordlessDrawerActions.resetDrawerForms())
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

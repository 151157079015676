import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { CurrentUserInfo } from './state'

export enum MultifactorOptionsActionTypes {
  MULTIFACTOR_OPTIONS_USER_GET = '[multifactor-options-user] get',
  MULTIFACTOR_OPTIONS_USER_SET = '[multifactor-options-user] set'
}

export const multifactorOptionsActions = {
  getMultifactorOptionsUser: () =>
    createAction(MultifactorOptionsActionTypes.MULTIFACTOR_OPTIONS_USER_GET),
  setMultifactorOptionsUser: (userInfo: CurrentUserInfo) =>
    createAction(MultifactorOptionsActionTypes.MULTIFACTOR_OPTIONS_USER_SET, {
      userInfo
    })
}

export type MultifactorOptionsActions = ActionsUnion<
  typeof multifactorOptionsActions
>

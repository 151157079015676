import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { loginReportActions } from '@lastpass/admin-console-dependencies/state/reports/login/actions'

export function createGetLoginReportEvents(
  loginReportServices: UACServices.Services
) {
  return function* getLoginReportEvents() {
    try {
      yield put(loginReportActions.setLoginReportLoading(true))

      const result: UACServices.LoginReportEventsAPI.Responses = yield call(
        loginReportServices.loginReports
      )
      yield put(loginReportActions.setLoginReport(result.body))

      yield put(loginReportActions.setLoginReportLoading(false))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading200 } from '@lastpass/lastkit'
import { LogoCard } from '@lastpass/lastkit'

import { SecurityDashboardData } from '@lastpass/admin-console-dependencies/state/dashboard/state'
import { MasterPasswordStrengthDonutChartCard } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/master-password-strength/master-password-strength-donut-chart-card/MasterPasswordStrengthDonutChartCard'
import { ReusedMasterPasswordsCard } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/master-password-strength/reused-master-passwords-card/ReusedMasterPasswordsCard'
import { WeakMasterPasswordsCard } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/master-password-strength/weak-master-passwords-card/WeakMasterPasswordsCard'
import { toRoundedPercent } from '@lastpass/admin-console-dependencies/ui/home/utils/converters'

const AccordionContentContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 16px;
`
const DonutChartCardContainer = styled.div`
  display: flex;
  width: 100%;
`
const ActionCardsContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`

const StyledLogoCard = styled(LogoCard)`
  background-color: ${props => props.theme.colors.blue50};
`

interface MasterPasswordStrengthProps {
  enrolledUsersCount: SecurityDashboardData['usersCountWithMasterPassword']
  usersCountWithStrongMasterPassword: SecurityDashboardData['usersCountWithStrongMasterPassword']
  usersCountWithWeakMasterPassword: SecurityDashboardData['usersCountWithWeakMasterPassword']
  usersCountWithReusedMasterPassword: SecurityDashboardData['usersCountWithReusedMasterPassword']
  usersCountWithWeakOrReusedMasterPassword: SecurityDashboardData['usersCountWithWeakOrReusedMasterPassword']
  usersWithStrongMasterPasswordRate: SecurityDashboardData['usersWithStrongMasterPasswordRate']
}

export const MasterPasswordStrength: FC<MasterPasswordStrengthProps> = ({
  enrolledUsersCount,
  usersCountWithStrongMasterPassword,
  usersCountWithWeakMasterPassword,
  usersCountWithReusedMasterPassword,
  usersCountWithWeakOrReusedMasterPassword,
  usersWithStrongMasterPasswordRate
}) => {
  const masterPasswordStrengthRateAccordionSubtitle: ReactElement = (
    <Trans>
      Out of {enrolledUsersCount} enrolled users,{' '}
      {usersCountWithStrongMasterPassword} have a strong master password and{' '}
      {usersCountWithWeakMasterPassword} have a weak or reused master password.
      Frequency of update varies per data point.
    </Trans>
  )

  return (
    <StyledLogoCard
      expand
      hideSubtitleOnClose
      title={
        <Heading200 data-qa="MasterPasswordStrengthAccordionTitle">
          <Trans>Master password strength</Trans>
        </Heading200>
      }
      subtitle={masterPasswordStrengthRateAccordionSubtitle}
      dataQaName="MasterPasswordStrengthAccordion"
    >
      <AccordionContentContainer>
        <DonutChartCardContainer>
          <MasterPasswordStrengthDonutChartCard
            strongMasterPasswordsCount={usersCountWithStrongMasterPassword}
            weakOrReusedMasterPasswordsCount={
              usersCountWithWeakOrReusedMasterPassword
            }
            strongMasterPasswordsRate={toRoundedPercent(
              usersWithStrongMasterPasswordRate
            )}
            dataQa="MasterPasswordStrengthChartCard"
          />
        </DonutChartCardContainer>
        <ActionCardsContainer>
          <WeakMasterPasswordsCard
            count={usersCountWithWeakMasterPassword}
            dataQa="WeakMasterPasswordsCard"
          />
          <ReusedMasterPasswordsCard
            count={usersCountWithReusedMasterPassword}
            dataQa="ReusedMasterPasswordsCard"
          />
        </ActionCardsContainer>
      </AccordionContentContainer>
    </StyledLogoCard>
  )
}

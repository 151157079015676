import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import {
  DashboardDisplaySettingsState,
  SecurityScoreDataState,
  SecurityScoreHistoryItem,
  UrlEncryptionMigrationState,
  UserActivityHistoryItem,
  UserAdoptionHistoryItem,
  UserStateData
} from './state'

export enum DashboardActionTypes {
  GET_SECURITY_SCORE_HISTORY = '[dashboard-security-score-history] get',
  SET_SECURITY_SCORE_HISTORY = '[dashboard-security-score-history] set',
  SET_SECURITY_SCORE_HISTORY_PERIOD = '[dashboard-security-score-history] set-period',
  SET_SECURITY_SCORE_HISTORY_LOADING = '[dashboard-security-score-history] set-loading',

  GET_SECURITY_SCORE_DATA = '[dashboard-security-score-data] get',
  SET_SECURITY_SCORE_DATA = '[dashboard-security-score-data] set',

  GET_USER_ADOPTION_HISTORY = '[dashboard-user-adoption-history] get',
  SET_USER_ADOPTION_HISTORY = '[dashboard-user-adoption-history] set',
  SET_USER_ADOPTION_HISTORY_PERIOD = '[dashboard-user-adoption-history] set-period',
  SET_USER_ADOPTION_HISTORY_LOADING = '[dashboard-user-adoption-history] set-loading',

  GET_USER_ACTIVITY_HISTORY = '[dashboard-user-activity-history] get',
  SET_USER_ACTIVITY_HISTORY = '[dashboard-user-activity-history] set',
  SET_USER_ACTIVITY_HISTORY_PERIOD = '[dashboard-user-activity-history] set-period',
  SET_USER_ACTIVITY_HISTORY_LOADING = '[dashboard-user-activity-history] set-loading',

  SET_UNRESPONSIVE_USERS = '[dashboard-user-adoption-unresponsive-users] set',
  GET_UNRESPONSIVE_USERS = '[dashboard-user-adoption-unresponsive-users] get',

  GET_USER_STATE_DATA = '[dashboard-user-state-data] get',
  SET_USER_STATE_DATA = '[dashboard-user-state-data] set',

  GET_USER_ADOPTION = '[dashboard-user-adoption] get',
  SET_USER_ADOPTION = '[dashboard-user-adoption] set',

  GET_DISPLAY_SETTINGS = '[dashboard-display-settings] get',
  SET_DISPLAY_SETTINGS = '[dashboard-display-settings] set',

  DASHBOARD_VIEWED = '[dashboard-segment-events] dashboard-viewed',
  INVITE_USERS_BUTTON_CLICKED = '[dashboard-segment-events] invite-button-clicked',
  REINVITE_USERS_BUTTON_CLICKED = '[dashboard-segment-events] reinvite-button-clicked',
  VIEW_INACTIVE_USERS_CLICKED = '[dashboard-segment-events] view-inactive-users-clicked',
  ADD_USERS_BUTTON_CLICKED = '[dashboard-segment-events] add-users-button-clicked',

  GET_URL_ENCRYPTION_MIGRATION_STATE = '[dashboard] get-url-encryption-migration-state',
  SET_URL_ENCRYPTION_MIGRATION_STATE = '[dashboard] set-url-encryption-migration-state',
  SET_URL_ENCRYPTION_MIGRATION_STATE_LOADING = '[dashboard] set-url-encryption-migration-state-loading'
}

export const dashboardActions = {
  getSecurityScoreHistory: (
    historyStartDate: Date | null,
    historyEndDate: Date | null
  ) =>
    createAction(DashboardActionTypes.GET_SECURITY_SCORE_HISTORY, {
      historyStartDate,
      historyEndDate
    }),
  setSecurityScoreHistory: (
    items: SecurityScoreHistoryItem[],
    lastUpdated: Date
  ) =>
    createAction(DashboardActionTypes.SET_SECURITY_SCORE_HISTORY, {
      items,
      lastUpdated
    }),
  setSecurityScoreHistoryPeriod: (
    historyStartDate: Date,
    historyEndDate: Date
  ) =>
    createAction(DashboardActionTypes.SET_SECURITY_SCORE_HISTORY_PERIOD, {
      historyStartDate,
      historyEndDate
    }),
  setSecurityScoreHistoryLoading: (loading: boolean) =>
    createAction(DashboardActionTypes.SET_SECURITY_SCORE_HISTORY_LOADING, {
      loading
    }),
  getSecurityScoreData: () =>
    createAction(DashboardActionTypes.GET_SECURITY_SCORE_DATA),
  setSecurityScoreData: (data: SecurityScoreDataState) =>
    createAction(DashboardActionTypes.SET_SECURITY_SCORE_DATA, data),

  getUserAdoptionHistory: (
    historyStartDate: Date | null,
    historyEndDate: Date | null
  ) =>
    createAction(DashboardActionTypes.GET_USER_ADOPTION_HISTORY, {
      historyStartDate,
      historyEndDate
    }),
  setUserAdoptionHistory: (
    items: UserAdoptionHistoryItem[],
    lastUpdated: Date
  ) =>
    createAction(DashboardActionTypes.SET_USER_ADOPTION_HISTORY, {
      items,
      lastUpdated
    }),
  setUserAdoptionHistoryPeriod: (
    historyStartDate: Date,
    historyEndDate: Date
  ) =>
    createAction(DashboardActionTypes.SET_USER_ADOPTION_HISTORY_PERIOD, {
      historyStartDate,
      historyEndDate
    }),
  setUserAdoptionHistoryLoading: (loading: boolean) =>
    createAction(DashboardActionTypes.SET_USER_ADOPTION_HISTORY_LOADING, {
      loading
    }),

  getUserActivityHistory: (
    historyStartDate: Date | null,
    historyEndDate: Date | null
  ) =>
    createAction(DashboardActionTypes.GET_USER_ACTIVITY_HISTORY, {
      historyStartDate,
      historyEndDate
    }),
  setUserActivityHistory: (
    items: UserActivityHistoryItem[],
    lastUpdated: Date
  ) =>
    createAction(DashboardActionTypes.SET_USER_ACTIVITY_HISTORY, {
      items,
      lastUpdated
    }),
  setUserActivityHistoryPeriod: (
    historyStartDate: Date,
    historyEndDate: Date
  ) =>
    createAction(DashboardActionTypes.SET_USER_ACTIVITY_HISTORY_PERIOD, {
      historyStartDate,
      historyEndDate
    }),
  setUserActivityHistoryLoading: (loading: boolean) =>
    createAction(DashboardActionTypes.SET_USER_ACTIVITY_HISTORY_LOADING, {
      loading
    }),
  getUnresponsiveUsers: () =>
    createAction(DashboardActionTypes.GET_UNRESPONSIVE_USERS),
  setUnresponsiveUsers: (unresponsiveUsers: number) =>
    createAction(DashboardActionTypes.SET_UNRESPONSIVE_USERS, {
      unresponsiveUsers
    }),
  getUserAdoptions: () => createAction(DashboardActionTypes.GET_USER_ADOPTION),
  setUserAdoptions: (dashboardState: {
    remainingLicenses: number
    stagedUserCount: number
    totalLicenses: number
  }) =>
    createAction(DashboardActionTypes.SET_USER_ADOPTION, {
      dashboardState
    }),
  getUserStateData: () =>
    createAction(DashboardActionTypes.GET_USER_STATE_DATA, {}),
  setUserStateData: (data: UserStateData) =>
    createAction(DashboardActionTypes.SET_USER_STATE_DATA, data),
  getDisplaySettings: () =>
    createAction(DashboardActionTypes.GET_DISPLAY_SETTINGS),
  setDisplaySettings: (settings: DashboardDisplaySettingsState) =>
    createAction(DashboardActionTypes.SET_DISPLAY_SETTINGS, settings),
  inviteUsersButtonClicked: () =>
    track(createAction(DashboardActionTypes.INVITE_USERS_BUTTON_CLICKED), {
      segment: uacEvent('Send LastPass Invitation Clicked', {
        Source: 'Admin Dashboard',
        'User Count': 0,
        'LastPass User Status': 'Staged'
      })
    }),
  reinviteUsersButtonClicked: () =>
    track(createAction(DashboardActionTypes.REINVITE_USERS_BUTTON_CLICKED), {
      segment: uacEvent('Resend LastPass Invitation Clicked', {
        Source: 'Admin Dashboard',
        'User Count': 0,
        'LastPass User Status': 'Staged'
      })
    }),
  dashboardViewed: () =>
    track(createAction(DashboardActionTypes.DASHBOARD_VIEWED), {
      segment: uacEvent('Admin Dashboard Viewed')
    }),
  viewInactiveUsersClicked: () =>
    track(createAction(DashboardActionTypes.VIEW_INACTIVE_USERS_CLICKED), {
      segment: uacEvent('View Inactive Users Clicked', {
        Source: 'Admin Dashboard'
      })
    }),
  addUsersButtonClicked: () =>
    track(createAction(DashboardActionTypes.ADD_USERS_BUTTON_CLICKED), {
      segment: uacEvent('Add Users Button Clicked', {
        Source: 'Admin Dashboard'
      })
    }),
  getUrlEncryptionMigrationState: () =>
    createAction(DashboardActionTypes.GET_URL_ENCRYPTION_MIGRATION_STATE),
  setUrlEncryptionMigrationState: (
    totalEnrolledUserCount: UrlEncryptionMigrationState['totalEnrolledUserCount'],
    migratedUserCount: UrlEncryptionMigrationState['migratedUserCount'],
    isMigrateToVaultFormatVersionV2Enabled: UrlEncryptionMigrationState['isMigrateToVaultFormatVersionV2Enabled']
  ) =>
    createAction(DashboardActionTypes.SET_URL_ENCRYPTION_MIGRATION_STATE, {
      totalEnrolledUserCount,
      migratedUserCount,
      isMigrateToVaultFormatVersionV2Enabled
    }),
  setUrlEncryptionMigrationStateLoading: (isLoading: boolean) =>
    createAction(
      DashboardActionTypes.SET_URL_ENCRYPTION_MIGRATION_STATE_LOADING,
      isLoading
    )
}

export type DashboardActions = ActionsUnion<typeof dashboardActions>

import { Table } from '@lastpass/admin-console-dependencies/types/table'
import {
  User,
  UserProfile
} from '@lastpass/admin-console-dependencies/types/user'

import {
  TransferVaultDrawerActions,
  TransferVaultDrawerActionTypes
} from './actions'

export interface TransferVaultState {
  sourceUser: UserProfile | null
  targetUser: User | null
  usersTable: Table<User>
  isLoading: boolean
  isTransferCompleted: boolean
}

const initialState: TransferVaultState = {
  sourceUser: null,
  targetUser: null,
  usersTable: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  isLoading: false,
  isTransferCompleted: false
}

export function transferVaultDrawerReducer(
  state: TransferVaultState | undefined = initialState,
  action: TransferVaultDrawerActions
): TransferVaultState {
  switch (action.type) {
    case TransferVaultDrawerActionTypes.SET_SOURCE_USER_DATA: {
      return {
        ...state,
        sourceUser: action.payload
      }
    }
    case TransferVaultDrawerActionTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case TransferVaultDrawerActionTypes.SET_USERS: {
      return {
        ...state,
        usersTable: {
          ...state.usersTable,
          results: action.payload.userList,
          totalResults: action.payload.totalResults
        }
      }
    }
    case TransferVaultDrawerActionTypes.SELECT_TARGET_USER: {
      return {
        ...state,
        targetUser: action.payload,
        usersTable: {
          ...state.usersTable,
          checkedRecords: [action.payload]
        }
      }
    }
    case TransferVaultDrawerActionTypes.REMOVE_TARGET_USER: {
      return {
        ...state,
        targetUser: null,
        usersTable: {
          ...state.usersTable,
          checkedRecords: []
        }
      }
    }
    case TransferVaultDrawerActionTypes.SET_IS_TRANSFER_COMPLETED: {
      return {
        ...state,
        isTransferCompleted: action.payload
      }
    }
  }

  return state
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'

const success = (
  userList: User[],
  totalResults: number,
  totalCompanyUsers: number
) => createResponse(SUCCESS, { userList, totalResults, totalCompanyUsers })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(queryPath: string) {
    const [response, status] = await fetch('users' + queryPath, 'GET')
    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const userList = responseData.content.items
      const totalResults = responseData.content.totalCount
      const totalCompanyUsers = responseData.content.totalCompanyUserCount
      return success(userList, totalResults, totalCompanyUsers)
    }
    throw new UnknownServiceError()
  }
}

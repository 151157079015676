import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyBoldStyle, BodyRegularStyle } from '@lastpass/lastkit/styles'

const StyledLabel = styled.label`
  ${BodyRegularStyle}
`
const FileDetailsLabel = styled.label`
  ${BodyRegularStyle}
  margin-left:24px;
`
const FileUploadInput = styled.input`
  margin-left: 4px;
  display: none;
`
const FileUploadLabel = styled.label`
  ${BodyBoldStyle}
  color: ${props => props.theme.colors.blue700};
  cursor: pointer;
  margin-left: 4px;
`
const FieldLabelContainer = styled.div`
  min-width: 150px;
`
const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
`
interface FileUploadComponentProps {
  fieldNameLabel: ReactElement
  uploadElement?: ReactElement
  name: string
  onChange: Function
  fileLengthInKb: number
  fileName: string
}
export const FileUploadComponent: FunctionComponent<FileUploadComponentProps> = ({
  fieldNameLabel,
  name,
  onChange,
  fileLengthInKb,
  fileName,
  uploadElement
}) => {
  return (
    <FileUploadContainer>
      <FieldLabelContainer>
        <StyledLabel>
          {fieldNameLabel}
          {':'}
        </StyledLabel>
      </FieldLabelContainer>
      <FileUploadLabel htmlFor={name}>
        {uploadElement ? uploadElement : <Trans>Upload</Trans>}
      </FileUploadLabel>
      <FileUploadInput
        name={name}
        accept=".crt,.pem"
        id={name}
        type="file"
        onChange={e => {
          onChange(e)
        }}
      />
      {fileName.length > 0 ? (
        <FileDetailsLabel>{fileName}</FileDetailsLabel>
      ) : null}
      <FileDetailsLabel>
        {fileLengthInKb <= 0 ? (
          <Trans>No file chosen</Trans>
        ) : (
          <Trans>{fileLengthInKb} kbytes</Trans>
        )}
      </FileDetailsLabel>
    </FileUploadContainer>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UrlRule } from '@lastpass/admin-console-dependencies/types/url-rule'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(rules: UrlRule[]) {
    const requestBody = rules.map((rule: UrlRule) => rule.id)

    try {
      const [response, status] = await fetch(
        'url-rules',
        'DELETE',
        requestBody,
        { 'content-type': 'application/json' }
      )
      if (status === StatusCodes.OK && response) {
        return success()
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import { Table as TableComponent, TableView } from '@lastpass/lastkit'

import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminListState } from '@lastpass/admin-console-dependencies/state/users/admin/list/state'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'

export const AdminLevelsTable: React.FunctionComponent = () => {
  const adminList: AdminListState = useSelector(
    (state: AppState) => state.adminList
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(adminListActions.getAdminList())
  }, [dispatch])

  return (
    <TableComponent
      qadata="AdminRolesList"
      table={adminList.table}
      getRecordLink={(record: UserLevelDetails) => `/users/admin/${record.id}`}
      noRecordsView={
        <TableView
          icon={<UserIcon />}
          title={<Trans>No admin levels created yet.</Trans>}
        />
      }
      loadingView={<Loading color="blue900" active={true} />}
      columns={[
        {
          name: <Trans>Admin level</Trans>,
          // eslint-disable-next-line react/display-name
          renderer: (record: UserLevelDetails) => (
            <UserLevelLabel userLevel={record} />
          )
        },
        {
          name: <Trans>Users</Trans>,
          renderer: (record: UserLevelDetails) =>
            record.id === 'custom-admin-level'
              ? ''
              : record.userCount.toString()
        }
      ]}
    />
  )
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { BodyBold, BodyRegularStyle } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { TransferVaultConfirmationWordDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { ConfirmationWordDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/ConfirmationWordDialog'

interface TransferVaultConfirmationWordDialogProps {
  state: TransferVaultConfirmationWordDialogType
  actions: typeof globalActions
}

const ContentContainer = styled.div`
  ${BodyRegularStyle};
`

const Paragraph = styled.p`
  margin-bottom: 1rem;
`

export const TransferVaultConfirmationWordDialog: FC<TransferVaultConfirmationWordDialogProps> = ({
  state
}) => {
  const Content = (
    <ContentContainer>
      <Paragraph>
        <Trans>
          The first user will be <BodyBold>permanently deleted</BodyBold> and
          the contents of their vault will be <BodyBold>transferred</BodyBold>{' '}
          to the second user. This can’t be undone.
        </Trans>
      </Paragraph>
      <div data-qa="TransferVaultConfirmationWordDialogDeletedUser">
        <BodyBold>
          <Trans>Delete</Trans>
          {':'}
        </BodyBold>{' '}
        {state.sourceUserEmail}
      </div>
      <div data-qa="TransferVaultConfirmationWordDialogTargetUser">
        <BodyBold>
          <Trans>Transfer vault to</Trans>
          {':'}
        </BodyBold>{' '}
        {state.targetUserEmail}
      </div>
    </ContentContainer>
  )

  return (
    <ConfirmationWordDialog
      confirmationWord="TRANSFER"
      title={msg`Transfer vault and delete user?`}
      content={Content}
      confirmButtonText={msg`Transfer vault and delete user`}
    />
  )
}

import { msg } from '@lingui/macro'
import buildQuery from 'odata-query'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

import { unresponsiveUsersFilter } from './get-unresponsive-users'

export function createInviteUnresponsiveUsersSaga(
  dashboardService: UACServices.Services
) {
  return function* inviteUnresponsiveUsersSaga() {
    try {
      const query = buildQuery({
        ...unresponsiveUsersFilter
      })

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Resend invitation`,
        text: msg`Send a new invitation to each selected user?`,
        discardText: msg`Cancel`,
        confirmText: msg`Resend`
      }

      yield put(globalActions.setDialog(dialog))

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield put(dashboardDrawerActions.reinviteUsersConfirmClicked())
        const result: UACServices.InviteUsersWithFilterAPI.Responses = yield call(
          dashboardService.inviteUsersWithFilter,
          query
        )
        if (result.type === UACServices.InviteUsersWithFilterAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: msg`New invitations have been sent to the selected users. Bulk actions may take up to 15 minutes to complete.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
          yield put(dashboardActions.getUnresponsiveUsers())
        } else if (
          result.type ===
          UACServices.InviteUsersWithFilterAPI.FAIL_EMAIL_TEMPLATES_NOT_APPROVED
        ) {
          yield put(
            globalActions.setNotification({
              message: msg`A member of the LastPass team must approve your template. Approval can take up to 24 hours.`,
              type: NotificationType.warning,
              autoDismiss: true
            })
          )
          yield put(dashboardActions.getUnresponsiveUsers())
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`New invitations haven't been sent to the selected users.`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { secureAuthSettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetSecureAuthSettings(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getSecureAuthSettings() {
    try {
      yield put(secureAuthSettingsActions.setSecureAuthSettingsLoading(true))
      try {
        const secureAuthSettingsResult: UACServices.SecureAuthSettingsGetAPI.Responses = yield call(
          enterpriseOptionServices.secureAuthSettingsGet
        )
        yield put(
          secureAuthSettingsActions.setSecureAuthSettings(
            secureAuthSettingsResult.body.secureAuthSettings
          )
        )
      } finally {
        yield put(secureAuthSettingsActions.setSecureAuthSettingsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

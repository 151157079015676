import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'

import { PolicyCode } from '@lastpass/admin-console-dependencies/types/policies-enum'
import { policyTexts } from '@lastpass/admin-console-dependencies/types/policy-mapping'

export const GetPolicyName = (
  policyKey: PolicyCode | string,
  policyName: string | null = null
) => {
  return i18n._(
    policyTexts[policyKey]
      ? policyTexts[policyKey].title
      : policyName || msg`Unknown`
  )
}

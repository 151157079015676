import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { SamlResponsesQueryParams } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'

import { ExportReportButton } from './components/ExportReportButton'
import { SamlResponsesFilters } from './components/SamlResponsesFilters'
import { SamlResponsesPageHeader } from './components/SamlResponsesPageHeader'
import { SamlResponsesTable } from './components/SamlResponsesTable'
import { SamlResponsesTablePagination } from './components/SamlResponsesTablePagination'

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

export const SamlResponses: React.FunctionComponent = () => {
  const samlList = useSelector((state: AppState) => state.samlResponse)

  const queryParams: SamlResponsesQueryParams = useQueryParams(
    Namespace.samlResponses
  )

  return (
    <>
      <RowContainer>
        <SamlResponsesPageHeader />
        <ExportReportButton />
      </RowContainer>
      <RowContainer>
        <SamlResponsesFilters />
        <SamlResponsesTablePagination
          samlListTable={samlList.table}
          queryParams={queryParams}
        />
      </RowContainer>
      <SamlResponsesTable
        samlListTable={samlList.table}
        queryParams={queryParams}
      />
    </>
  )
}

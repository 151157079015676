import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { BodyRegular, Heading100, SeparatorLine } from '@lastpass/lastkit'

import {
  BoldSpan,
  StyledActionButton,
  StyledButtonContainer,
  StyledKeyRotationContainer
} from './StyledComponents'

export const KcRotationAndVaultReEncryptionBlock: FunctionComponent<{
  onClickKcRotation: () => void
  onClickReEncryption: () => void
  providerName: string
  isAutomatedVaultReEncryptionPolicyEnabled: boolean
}> = ({
  onClickKcRotation,
  onClickReEncryption,
  providerName,
  isAutomatedVaultReEncryptionPolicyEnabled
}) => (
  <>
    <SeparatorLine />
    <StyledKeyRotationContainer>
      <Heading100>
        <Trans>Knowledge component rotation</Trans>
      </Heading100>
      <BodyRegular>
        <Trans>
          Knowledge components are secret values that allow your users to log
          into LastPass through your federated service provider
          {` (${providerName})`}. Regularly rotating these knowledge components
          and re-encrypting your user’s vaults increases your protection against
          threats.
          <br />
          <BoldSpan>Security note:</BoldSpan> These locally generated values are
          not stored by LastPass in any way.
        </Trans>
      </BodyRegular>
      <StyledButtonContainer>
        <StyledActionButton
          data-qa="RotateKeysButton"
          onClick={onClickKcRotation}
          type="button"
        >
          <Trans>Rotate knowledge components</Trans>
        </StyledActionButton>
        <StyledActionButton
          data-qa="ReEncryptVaultButton"
          onClick={onClickReEncryption}
          type="button"
        >
          {isAutomatedVaultReEncryptionPolicyEnabled ? (
            <Trans>Edit recurring vault re-encryption</Trans>
          ) : (
            <Trans>Enable recurring vault re-encryption</Trans>
          )}
        </StyledActionButton>
      </StyledButtonContainer>
    </StyledKeyRotationContainer>
  </>
)

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

export const RecoveryKeySharingEndDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      distributeRecoveryKeysActions.trackRecoveryKeySharingSuccessViewed()
    )
  }, [dispatch])

  const content = (
    <>
      <StyledParagraph>
        <Trans>
          Nice. You’ve invisibly shared recovery keys with your fellow admin.
          They’ll be able to perform high-security tasks on the impacted users.
        </Trans>
      </StyledParagraph>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      disableCloseOnBackground
      width="634px"
      title={msg`Recovery keys shared invisibly`}
      content={content}
      buttons={[
        <PrimaryButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          data-qa="RecoveryKeySharingConfirmDialogConfirmButton"
        >
          <Trans>All set</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

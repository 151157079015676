import { DataFetchParams } from '@lastpass/routing'

import { CreateManagedCompanyBody } from '@lastpass/admin-console-dependencies/server/home/managed-companies/create-managed-company'
import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'
import {
  ManagedCompaniesAggregate,
  PsaDetails
} from '@lastpass/admin-console-dependencies/types/managed-company'
import { CompanyTemplateSettings } from '@lastpass/admin-console-dependencies/types/managed-company'

export enum ManagedCompaniesActionTypes {
  CREATE = '[managed-companies] create',
  SET_LIST = '[managed-companies] set',
  GET_LIST = '[managed-companies] get',
  GET_COMPANY_TEMPLATES = '[managed-companies] get-company-templates',
  SET_COMPANY_TEMPLATES = '[managed-companies] set-company-templates',
  SET_IS_LOADING = '[managed-companies] set-is-loading',
  SET_AGGREGATE = '[managed-companies] set-aggregate',
  SET_PSA_DETAILS = '[managed-companies] update psa details'
}

export const managedCompaniesActions = {
  create: (body: CreateManagedCompanyBody) =>
    createAction(ManagedCompaniesActionTypes.CREATE, {
      body
    }),
  setList: (
    results: ManagedCompany[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(ManagedCompaniesActionTypes.SET_LIST, {
      results,
      totalResults,
      resultsPerPage
    }),
  getList: (params: DataFetchParams<ManagedCompanyListQueryParams>) =>
    createAction(ManagedCompaniesActionTypes.GET_LIST, { params }),
  getCompanyTemplates: () =>
    createAction(ManagedCompaniesActionTypes.GET_COMPANY_TEMPLATES),
  setCompanyTemplates: (companyTemplateSettings: CompanyTemplateSettings[]) =>
    createAction(ManagedCompaniesActionTypes.SET_COMPANY_TEMPLATES, {
      companyTemplateSettings
    }),
  setIsLoading: (isLoading: boolean) =>
    createAction(ManagedCompaniesActionTypes.SET_IS_LOADING, {
      isLoading
    }),
  setAggregate: (aggregate: ManagedCompaniesAggregate) =>
    createAction(ManagedCompaniesActionTypes.SET_AGGREGATE, { ...aggregate }),
  setPsaDetails: (managedCompanyId: number, psaDetails: PsaDetails) =>
    createAction(ManagedCompaniesActionTypes.SET_PSA_DETAILS, {
      managedCompanyId,
      psaDetails
    })
}

export type ManagedCompaniesActions = ActionsUnion<
  typeof managedCompaniesActions
>

export interface ManagedCompanyListQueryParams {
  search: string
}

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { createLegacyVpnList } from '@lastpass/admin-console-dependencies/ui/applications/legacyvpn/LegacyVpnList'

const LegacyVpnList = createLegacyVpnList<AppState>()

export const LegacyVpnPage = ({ cidOverride }) => {
  const match = useRouteMatch()
  if (match) {
    return <LegacyVpnList cidOverride={cidOverride} />
  }
  return null
}

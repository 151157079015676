import { saveAs } from 'file-saver'
import moment from 'moment'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { pendingExportNotification } from '@lastpass/admin-console-dependencies/sagas/home/managed-companies/operations/helpers/pending-export-notification'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'

export const isExportUserCountsLoadingSelector = (state: AppState) =>
  state.managedCompanyOperations.isExportUserCountsLoading

export function createExportUserCountsSaga(
  managedCompaniesServices: UACServices.Services
) {
  const timestamp = moment()
    .startOf('day')
    .format('YYYYMMDD')

  return function* exportUserCountsSaga() {
    yield put(
      globalActions.setDialog({
        type: 'managed-company-export-user-counts-dialog'
      })
    )

    yield takeEvery(GlobalActionTypes.CONFIRM_DIALOG, function*(
      action: ReturnType<typeof globalActions.confirmExportUserCounts>
    ) {
      const isLoading = yield select(isExportUserCountsLoadingSelector)
      if (isLoading) {
        yield put(globalActions.setNotification(pendingExportNotification))
        return
      }

      try {
        if (!action.payload) {
          return
        }
        yield put(
          managedCompanyOperationActions.setIsExportUserCountsLoading(true)
        )

        const result: UACServices.exportUserCountsAPI.Responses = yield call(
          managedCompaniesServices.exportUserCounts,
          action.payload.fromDateUtc,
          action.payload.toDateUtc
        )

        if (result.type !== UACServices.exportUserCountsAPI.SUCCESS) {
          yield put(globalActions.setNotification(genericFailedNotification))
          return
        }

        const csv = result.body
        const blobToSave = new Blob([csv], {
          type: 'text/csv;charset=utf-8;'
        })
        yield call(
          saveAs,
          blobToSave,
          `lastpass-managed-companies-usage-${timestamp}.csv`
        )
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      } finally {
        yield put(
          managedCompanyOperationActions.setIsExportUserCountsLoading(false)
        )
      }
    })
  }
}

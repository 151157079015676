import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Chip, ChipContainer } from '@lastpass/lastkit'

import { useCalculateChartRateThreshold } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-calculate-chart-rate-threshold'
import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'

const ChipValue = styled.span<{ textColor?: string }>`
  color: ${props => props.textColor ?? props.theme.colors.neutral700};
`

interface Props {
  inactiveUsersCount: number
  activeUsageRate: number
  activeUsageRateTooltip: ReactElement
}

export const ActiveUsageRateAccordionChips: FC<Props> = ({
  inactiveUsersCount,
  activeUsageRate,
  activeUsageRateTooltip
}) => {
  const chartRateThresholds = useCalculateChartRateThreshold(activeUsageRate)
  const inactiveUsersCountColor = useCountColor(inactiveUsersCount)

  return (
    <ChipContainer>
      <Chip tooltip={activeUsageRateTooltip}>
        <ChipValue textColor={chartRateThresholds.colors.primary}>
          {activeUsageRate}
          {'%'}
        </ChipValue>
      </Chip>
      <Chip>
        <Trans>Inactive users</Trans>
        {':'}&nbsp;
        <ChipValue textColor={inactiveUsersCountColor}>
          {inactiveUsersCount}
        </ChipValue>
      </Chip>
    </ChipContainer>
  )
}

import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { assignActions } from '@lastpass/admin-console-dependencies/state/common/assign/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { defaultAssignFormState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/reducer'
import { GeneralPolicyUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { getMultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

function transformTeamsPolicyDataToAssignFormState(
  policyData: TeamsPolicyModelData
): GeneralPolicyUsersAndGroupsFormState {
  if (policyData.appliesTo == AppliesToOptions.all) {
    return {
      ...defaultAssignFormState,
      cpId: policyData.cpid,
      policyValue: policyData.policyValue,
      policyNotes: policyData.policyNotes
    }
  }

  let userList: TeamsPolicyUserInfo[] = []
  let groupList: TeamsPolicyGroup[] = []

  if (policyData.appliesTo == AppliesToOptions.inclusive) {
    if (policyData.inclusiveList) {
      userList = policyData.inclusiveList
    }
    if (policyData.inclusiveGroupList) {
      groupList = policyData.inclusiveGroupList
    }
  } else {
    if (policyData.exclusiveList) {
      userList = policyData.exclusiveList
    }
    if (policyData.exclusiveGroupList) {
      groupList = policyData.exclusiveGroupList
    }
  }

  const result: GeneralPolicyUsersAndGroupsFormState = {
    ...defaultAssignFormState,
    appliesTo: policyData.appliesTo,
    users: {
      ...defaultAssignFormState.users,
      results: userList,
      totalResults: userList.length
    },
    groups: {
      ...defaultAssignFormState.groups,
      results: groupList,
      totalResults: groupList.length
    },
    filteredUsers: {
      ...defaultAssignFormState.filteredUsers,
      results: userList,
      totalResults: userList.length
    },
    filteredGroups: {
      ...defaultAssignFormState.filteredGroups,
      results: groupList,
      totalResults: groupList.length
    },
    cpId: policyData.cpid,
    policyValue: policyData.policyValue,
    policyNotes: policyData.policyNotes
  }

  return result
}

export function createEditPolicyAssignmentsSaga() {
  return function* editPolicyAssignmentsSaga(
    action: ReturnType<typeof multifactorActions.editPolicyAssignments>
  ) {
    const { policy, dataIndex } = action.payload
    const optionType = getMultifactorOptionType(policy.policyKey)
    const dataModel = policy.policyData[dataIndex || 0]
    if (!optionType) {
      // advanced multifactor policy
      yield put(
        advancedPolicyDrawerActions.setPolicy(
          policy,
          dataIndex || 0,
          dataModel.policyNotes,
          dataModel.policyValue
        )
      )
      yield put(assignActions.changeAppliesTo(dataModel.appliesTo))

      let users: TeamsPolicyUserInfo[] = []
      let groups: TeamsPolicyGroup[] = []
      if (dataModel.appliesTo === AppliesToOptions.inclusive) {
        users = dataModel.inclusiveList || []
        groups = dataModel.inclusiveGroupList || []
      }

      if (dataModel.appliesTo === AppliesToOptions.exclusive) {
        users = dataModel.exclusiveList || []
        groups = dataModel.exclusiveGroupList || []
      }

      yield put(assignActions.setInitialAssignedItems(users, groups))
      yield put(
        advancedPolicyDrawerActions.nextStep(AdvancedPolicyDrawerSteps.assign)
      )
      yield put(push('/policies/multifactor/add-advanced'))
    } else {
      // main multifactor policy
      yield put(multifactorDrawerActions.setSelectedMfaMethod(optionType))
      yield put(
        multifactorDrawerActions.setPolicyUserData(
          transformTeamsPolicyDataToAssignFormState(dataModel)
        )
      )

      yield put(
        multifactorDrawerActions.nextStep(MultifactorDrawerSteps.assign)
      )
      yield put(push('/policies/multifactor/add'))
    }
  }
}

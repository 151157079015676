import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { withTheme } from '@emotion/react'

import { AnimatedSwitch, LocationRedirect } from '@lastpass/routing'
import { RouteFade } from '@lastpass/routing/animations'

import { AssignGroupUsersDrawerTable } from '@lastpass/admin-console-dependencies/ui/users/groups/add-users/AssignGroupUsersDrawerTable'

function AssignGroupUsersDrawerPageComponent() {
  const match = useRouteMatch()
  if (match) {
    const { url, path } = match
    return (
      <AnimatedSwitch timeout={300}>
        <Route path={path}>
          <RouteFade>
            <AssignGroupUsersDrawerTable newGroup={path.includes('new')} />
          </RouteFade>
        </Route>
        <Route>
          <LocationRedirect to={url} />
        </Route>
      </AnimatedSwitch>
    )
  }
  return null
}

export const AssignGroupUsersDrawerPage = withTheme(
  AssignGroupUsersDrawerPageComponent
)

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'

import {
  BodyRegular,
  ButtonsContainer,
  PrimaryButton,
  Select,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { UserLevelWithPermissions } from '@lastpass/admin-console-dependencies/types/user-level'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'
import { AdminLevelPermissionsTreeCard } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/permission-tree-card/AdminLevelPermissionsTreeCard'

const AdminLevelSelectWrapper = styled.div`
  margin-bottom: 24px;
`
const StyledDescription = styled(BodyRegular)`
  display: inline-block;
  margin-top: -18px;
  margin-bottom: 20px;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 16px;
`

interface ViewAndEditManagedAdminPermissionsProps {
  adminLevel: UserLevelWithPermissions
  closeLink: string
}

interface PageParams {
  id?: string
}

const isCustomAdminLevelEmpty = (managedAdminLevel, formikProps) =>
  managedAdminLevel === MspCidOverrideLevel.custom &&
  !formikProps.values.levelPermission.length

export const ViewAndEditManagedAdminPermissions: React.FunctionComponent<ViewAndEditManagedAdminPermissionsProps> = ({
  closeLink,
  adminLevel
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const match = useRouteMatch<PageParams>()
  const id = match?.params?.id || ''

  const adminLevelSelectOptions = [
    { label: i18n._(msg`Admin`), value: MspCidOverrideLevel.admin },
    { label: i18n._(msg`Custom`), value: MspCidOverrideLevel.custom }
  ]

  const defaultAdminLevel = adminLevelSelectOptions[0]
  const [managedAdminLevel, setManagedAdminLevel] = useState<
    MspCidOverrideLevel
  >(adminLevel.mspCidOverrideAdminLevel || defaultAdminLevel.value)

  useEffect(() => {
    dispatch(adminLevelDrawerActions.getManagedAdminPermissions())
  }, [dispatch])

  const pathParams = usePathParams()

  useEffect(() => {
    if (
      !adminLevel.permissions.includes(
        AdminPermissions.managedCompanyCidOverride
      )
    ) {
      dispatch(
        adminLevelDrawerActions.getCurrentAdminLevel({
          query: {},
          path: pathParams
        })
      )
    }
  }, [adminLevel, dispatch, pathParams])

  useUnmountEffect(() => {
    adminLevelDrawerActions.reset()
  })

  return (
    <>
      <StyledDescription>
        <Trans>
          Select the admin level and permissions to be applied when logged in to
          a managed company’s Admin Console.
        </Trans>
      </StyledDescription>
      <Formik
        initialValues={{
          levelPermission: adminLevel.mspCidOverridePermissions,
          isAssignUsers: false
        }}
        onSubmit={(values: AdminFormValues) => {
          setIsBlockingPromptEnabled(false)

          dispatch(
            adminLevelDrawerActions.editManagedAdminLevel(
              id,
              managedAdminLevel,
              values.levelPermission,
              adminLevel.permissions,
              values.isAssignUsers ? `/users/admin/${id}/add` : '/users/admin'
            )
          )
        }}
      >
        {formik => (
          <Form
            onChange={() => setIsBlockingPromptEnabled(true)}
            onSubmit={formik.handleSubmit}
          >
            <AdminLevelSelectWrapper>
              <Select
                dataQa="AdminLevelTypesDropdown"
                onChange={selectedItem => {
                  setManagedAdminLevel(
                    selectedItem ? selectedItem['value'] : managedAdminLevel
                  )
                }}
                width="320px"
                options={adminLevelSelectOptions}
                defaultValue={
                  adminLevel.mspCidOverrideAdminLevel ===
                  MspCidOverrideLevel.custom
                    ? adminLevelSelectOptions[1]
                    : adminLevelSelectOptions[0]
                }
              >
                <Trans>Managed company permissions</Trans>
              </Select>
            </AdminLevelSelectWrapper>
            {managedAdminLevel === MspCidOverrideLevel.custom && (
              <AdminLevelPermissionsTreeCard
                formik={formik}
                hasManagedAdminPermissions
              />
            )}
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <TextButton
                  css={css`
                    margin-right: 16px;
                  `}
                  data-qa="CancelButton"
                  type="button"
                  onClick={() => {
                    dispatch(adminLevelDrawerActions.reset())
                    history.push(closeLink)
                  }}
                >
                  <Trans>Cancel</Trans>
                </TextButton>
                <StyledPrimaryButton
                  data-qa="SaveChangesButton"
                  type="submit"
                  disabled={isCustomAdminLevelEmpty(managedAdminLevel, formik)}
                  onClick={() => {
                    formik.setFieldValue(
                      'isAssignUsers',
                      adminLevel.userCount === 0
                    )
                    formik.setFieldValue(
                      'mspCidOverrideAdminLevel',
                      managedAdminLevel
                    )
                  }}
                >
                  <Trans>Save changes</Trans>
                </StyledPrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

import { saveAs } from 'file-saver'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'

import { extractSecurityReportZip } from './extract-security-report-zip'

export function createDownloadUrlsInVaultsReportSaga(
  service: UACServices.Services
) {
  return function* downloadUrlsInVaultsReport(
    action: ReturnType<
      typeof securityReportDrawerActions.downloadUrlsInVaultsReport
    >
  ) {
    try {
      const downloadReportName = yield select(
        (state: AppState) => state.securityReportDrawer.downloadReportName
      )

      const currentAdminLevel = yield select(
        (state: AppState) => state.usersList.currentAdminLevel
      )

      const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin

      const { reportId, isUrlDecryptionEnabled, setIsLoading } = action.payload

      if (!isUrlDecryptionEnabled || !isSuperAdmin) {
        yield call(setIsLoading, true)
      }
      const urlsInVaultsReportDownloadResponse: UACServices.UrlsInVaultsAPI.DownloadReportAPI.Responses = yield call(
        service.urlsInVaultsReportDownload,
        reportId
      )
      if (
        urlsInVaultsReportDownloadResponse.type !==
        UACServices.UrlsInVaultsAPI.DownloadReportAPI.SUCCESS
      ) {
        throw new UnknownServiceError()
      }

      if (isUrlDecryptionEnabled && isSuperAdmin) {
        yield put(
          globalActions.setDialog({
            type: 'url-decryption-confirm-dialog'
          })
        )
        yield call(
          extractSecurityReportZip,
          urlsInVaultsReportDownloadResponse.body
        )
      } else {
        yield call(
          saveAs,
          urlsInVaultsReportDownloadResponse.body,
          `${downloadReportName}.zip`
        )
        yield call(setIsLoading, false)
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

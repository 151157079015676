export enum Namespace {
  accountDetails = 'accountDetails',
  addUsersToAdmin = 'add-users-to-admin',
  addUsersToGroup = 'add-users-to-group',
  adminUsers = 'admin-users',
  adminReports = 'admin-reports',
  applications = 'applications',
  apps = 'apps',
  upgrade = 'upgrade',
  directories = 'directories',
  groups = 'groups',
  groupUsers = 'group-users',
  inactiveUsers = 'inactive-users',
  managedCompanies = 'managed-companies',
  policies = 'policies',
  policyassignusers = 'policy-assign-users',
  samlResponses = 'saml-responses',
  sharedFolders = 'shared-folders',
  sharedfoldersdrawer = 'sharedfolders-drawer',
  sites = 'sites',
  stagedUsers = 'staged-users',
  transferVaultDrawer = 'transfer-vault-drawer',
  unresponsiveUsers = 'unresponsive-users',
  user = 'user',
  userReports = 'user-reports',
  users = 'users',
  weakMasterPasswordUsers = 'weak-master-password-users',
  weakSecurityScoreUsers = 'weak-security-score-users',
  pendingInvitations = 'pending-invitations',
  expiredInvitations = 'expired-invitations'
}

import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ConnectWiseManageIcon } from '@lastpass/assets/svg/admin-console/psa-integrations/connectwise-manage-logo.svg'
import { ReactComponent as DattoAutotaskIcon } from '@lastpass/assets/svg/admin-console/psa-integrations/datto-autotask-logo.svg'
import {
  CardContainer,
  Heading100,
  PrimaryButton
} from '@lastpass/lastkit/components'
import { BodyBoldStyle } from '@lastpass/lastkit/styles'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { PsaIntegrationsState } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { PsaBillingUpdateLink } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/providers/components/PsaBillingUpdateLink'
import { PsaOperationsDropdown } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/providers/components/PsaOperationsDropdown'
import { useCheckConfigurePermission } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/providers/hooks/use-check-configure-permission'
import { PsaProviders } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/PsaIntegrations'

const StyledContainer = styled(CardContainer)`
  box-shadow: ${props => props.theme.elevations.elevation100};
  background: ${props => props.theme.colors.neutral};
  border: 1px solid ${props => props.theme.colors.neutral300};
  width: 100%;
  border-radius: 4px;
  margin-bottom: 24px;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 21px 16px;
`
const StyleTitleContainer = styled.div`
  margin-left: 16px;
`
const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const StyledLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
`
const StyledBillingUpdateText = styled.span`
  color: ${props => props.theme.colors.neutral900};
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`
const StyledButton = styled.button`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.blue700};
  padding: 0;
  outline: none;
  margin-left: auto;
  cursor: pointer;
  border-style: none;
  background: none;
`
const StyledLinkTitle = styled.p`
  ${BodyBoldStyle}
`
const StyledStartButton = styled(PrimaryButton)`
  margin-left: auto;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 16px;
`
const StyledBillingUpdatesContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 16px 21px 16px;
`

const psaManagementAppLogo = {
  datto: DattoAutotaskIcon,
  connectwise: ConnectWiseManageIcon
}

interface PsaProviderCardProps {
  provider: string
  activeProvider: string | null
}

export const PsaProviderCard: FunctionComponent<PsaProviderCardProps> = ({
  provider,
  activeProvider
}) => {
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const { activePsa } = useSelector<AppState, PsaIntegrationsState>(
    state => state.psaIntegrations
  )

  const isContractSelectionAvailable =
    activePsa && activePsa.modules
      ? activePsa.modules.isContractSelectionAvailable
      : false

  const Logo = psaManagementAppLogo[provider.toLowerCase()] || (() => <></>)

  const handleOpeningDrawer = () => {
    dispatch(
      psaIntegrationsActions.setPsaEditCredentialsFormValues(null, provider)
    )
    history.push(`${url}/credentials/${provider}/edit`)
  }

  const handleSwitchProvider = () => {
    dispatch(
      psaIntegrationsActions.setPsaEditCredentialsFormValues(null, provider)
    )
    dispatch(psaIntegrationsActions.switchPsaProvider(provider))
  }

  const handleOpenProductSelectionDrawer = () =>
    history.push(`${url}/billing/products`)

  const handleOpenAgreementsSelectionDrawer = () =>
    history.push(`${url}/billing/agreements`)

  const startButton = useCheckConfigurePermission(() =>
    activeProvider ? null : (
      <StyledStartButton
        type="submit"
        onClick={handleOpeningDrawer}
        data-qa="StartButton"
      >
        <Trans>Get started</Trans>
      </StyledStartButton>
    )
  )

  const moreButton = useCheckConfigurePermission(() =>
    activeProvider && activeProvider === provider ? (
      <PsaOperationsDropdown provider={provider} />
    ) : null
  )

  const switchProviderButton = useCheckConfigurePermission(() =>
    activeProvider && activeProvider !== provider ? (
      <StyledButton onClick={handleSwitchProvider} data-qa="SwitchButton">
        <Trans>Switch PSA provider</Trans>
      </StyledButton>
    ) : null
  )

  const billingUpdates = useCheckConfigurePermission(() =>
    activeProvider && activeProvider === provider ? (
      <StyledBillingUpdatesContainer>
        <StyledLinkContainer>
          <StyledLinkTitle>
            <Trans>Billing updates</Trans>
          </StyledLinkTitle>
          <PsaBillingUpdateLink
            onClick={handleOpenProductSelectionDrawer}
            text={<Trans>Select products for billing</Trans>}
            qaId="PsaProductSelectionLink"
          />
          <PsaBillingUpdateLink
            onClick={handleOpenAgreementsSelectionDrawer}
            text={<Trans>Select agreements for billing</Trans>}
            qaId="PsaAgreementsSelectionLink"
            isDisabled={!isContractSelectionAvailable}
          />
          <PsaBillingUpdateLink
            onClick={() => {}}
            text={<Trans>Set up automatic billing updates</Trans>}
            qaId="PsaAutomaticSelectionLink"
            isDisabled
          />
        </StyledLinkContainer>

        <StyledButtonContainer data-qa="PsaBillingContainer">
          <StyledBillingUpdateText data-qa="PsaLastBillingUpdateText">
            <Trans>Last billing update: never</Trans>
          </StyledBillingUpdateText>

          <StyledPrimaryButton data-qa="PsaUpdateBillingButton" disabled={true}>
            <Trans>Update billing now</Trans>
          </StyledPrimaryButton>

          <StyledPrimaryButton
            disabled={true}
            data-qa="PsaImportCompaniesButton"
          >
            <Trans>Import companies</Trans>
          </StyledPrimaryButton>
        </StyledButtonContainer>
      </StyledBillingUpdatesContainer>
    ) : null
  )

  return (
    <StyledContainer data-qa="IntegrationCardContainer">
      <Container>
        <Logo />
        <StyleTitleContainer>
          <Heading100>
            <Trans>{PsaProviders[provider]}</Trans>
          </Heading100>
        </StyleTitleContainer>
        {startButton}
        {moreButton}
        {switchProviderButton}
      </Container>

      {billingUpdates}
    </StyledContainer>
  )
}

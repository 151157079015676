import { DataFetchParams } from '@lastpass/routing'
import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { Application } from '@lastpass/admin-console-dependencies/state/applications/application'

export enum ApplicationListActionTypes {
  GET_APPLICATION_LIST = '[application-list] get',
  SET_APPLICATION_LIST = '[application-list] set',
  ADD_NEW_APPLICATION = '[application-list] add-new-app',
  SET_APPLICATION_LIST_LOADING = '[application-list] set-table-loading',
  CONFIGURE_APP_CLICKED = '[application-list] configure-app-clicked',
  SHOW_SSO_UPSELL_DIALOG = '[application-list] show-sso-dialog',
  SHOW_SSO_UPSELL_CONTACTSUPPORT_DIALOG = '[application-list] show-sso-contactsupport-dialog',
  SSO_SECURITY_DASHBOARD_WIDGET_CLICKED = '[application-list] sso-security-dashboard-widget-clicked',
  SSO_SECURITY_DASHBOARD_TOOLTIP_VIEWED = '[application-list] sso-security-dashboard-widget-tooltip-viewed',
  SSO_APPS_OVERVIEW_VIEWED = '[application-list] sso-apps-overview-viewed'
}

export interface ApplicationListQueryParams {
  search: string
  skip: number
}

const ssoAppsOverview = 'SSO Apps Overview'

export const applicationListSegmentActions = {
  addNewAppButtonClicked: (totalResults: number) =>
    track(
      createAction(ApplicationListActionTypes.ADD_NEW_APPLICATION, {
        totalResults
      }),
      {
        segment: uacEvent('Add SSO App Clicked', {
          'App Count': totalResults,
          Source: 'SSO Apps Home'
        })
      }
    ),
  configureAppClicked: (
    userAction: SegmentPropertyType<'SSO Configure App Clicked', 'Action'>
  ) =>
    track(createAction(ApplicationListActionTypes.CONFIGURE_APP_CLICKED, {}), {
      segment: uacEvent('SSO Configure App Clicked', {
        Action: userAction
      })
    }),
  ssoSecurityDashboardWidgetClicked: (
    userAction: SegmentPropertyType<
      'Sso Not Configured Alert Clicked',
      'Action'
    >
  ) =>
    track(
      createAction(
        ApplicationListActionTypes.SSO_SECURITY_DASHBOARD_WIDGET_CLICKED,
        {}
      ),
      {
        segment: uacEvent('Sso Not Configured Alert Clicked', {
          Action: userAction
        })
      }
    ),
  ssoSecurityDashboardTooltipViewed: () =>
    track(
      createAction(
        ApplicationListActionTypes.SSO_SECURITY_DASHBOARD_TOOLTIP_VIEWED,
        {}
      ),
      {
        segment: uacEvent('Sso Not Configured Tooltip Viewed', {})
      }
    ),
  ssoAppsOverviewViewed: (totalResults: number) =>
    track(
      createAction(ApplicationListActionTypes.SSO_APPS_OVERVIEW_VIEWED, {}),
      {
        segment: uacEvent('SSO Apps Overview Viewed', {
          'App Count': totalResults,
          Source: ssoAppsOverview
        })
      }
    )
}

export const applicationListActions = {
  getApplicationList: (
    params: DataFetchParams<ApplicationListQueryParams>,
    resultsPerPage = 25
  ) =>
    createAction(ApplicationListActionTypes.GET_APPLICATION_LIST, {
      params,
      resultsPerPage
    }),
  setApplicationList: (
    list: Application[],
    totalResults: number,
    resultsPerPage = 25
  ) =>
    createAction(ApplicationListActionTypes.SET_APPLICATION_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  addNewApp: totalResults =>
    applicationListSegmentActions.addNewAppButtonClicked(totalResults),
  setApplicationsLoading: (loading: boolean) =>
    createAction(ApplicationListActionTypes.SET_APPLICATION_LIST_LOADING, {
      loading
    }),
  onConfigureAppClicked: (
    userAction: SegmentPropertyType<'SSO Configure App Clicked', 'Action'>
  ) => applicationListSegmentActions.configureAppClicked(userAction),
  showSsoUpsellDialog: () =>
    track(createAction(ApplicationListActionTypes.SHOW_SSO_UPSELL_DIALOG, {}), {
      segment: uacEvent('Identity Paywall Viewed', {
        'Paywall Context': 'Purchase',
        Product: 'SSO',
        'eComm Enabled': true,
        Source: ssoAppsOverview
      })
    }),
  showSsoUpsellContactSupportDialog: () =>
    track(
      createAction(
        ApplicationListActionTypes.SHOW_SSO_UPSELL_CONTACTSUPPORT_DIALOG,
        {}
      ),
      {
        segment: uacEvent('Identity Paywall Viewed', {
          'Paywall Context': 'Purchase',
          Product: 'SSO',
          'eComm Enabled': false,
          Source: ssoAppsOverview
        })
      }
    )
}

export type ApplicationListActions = ActionsUnion<typeof applicationListActions>

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { CaptionRegularStyle } from '@lastpass/lastkit'

const BoldText = styled.span`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const TooltipStyle = styled.div`
  ${CaptionRegularStyle};
  line-height: 16px;
  width: 100%;
  text-align: left;

  & ul {
    list-style-type: none;
  }
`

export const CompanyStatusTooltip: FC = () => (
  <TooltipStyle>
    <ul>
      <li>
        <Trans>
          <BoldText>Active</BoldText>: Will be billed for LastPass at the end of
          the month.
        </Trans>
      </li>
      <li>
        <Trans>
          <BoldText>Suspended</BoldText>: Won’t be billed for LastPass at the
          end of the month; you can activate it as required.
        </Trans>
      </li>
      <li>
        <Trans>
          <BoldText>New</BoldText>: Added since last billing cycle; will be
          billed monthly for LastPass until suspended or detached.
        </Trans>
      </li>
    </ul>
  </TooltipStyle>
)

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'

const success = (assignGroupUserList: User[], totalResults: number) =>
  createResponse(SUCCESS, { assignGroupUserList, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>
export function create(fetch: AuthFetch) {
  return async function service(groupId: string, queryPath: string) {
    const [response, status] = await fetch(
      'groups/' + groupId + '/users/assignable' + queryPath,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const assignGroupUsersList = JSON.parse(response).content.items
      const totalResults = JSON.parse(response).content.totalCount
      return success(assignGroupUsersList, totalResults)
    }

    throw new UnknownServiceError()
  }
}

import { call, put } from 'redux-saga/effects'

import { extractCsvFilesFromZip } from '@lastpass/admin-console-dependencies/services/extract-zip-file'
import { readFileAsText } from '@lastpass/admin-console-dependencies/services/read-file-as-text'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'

export function* extractSecurityReportZip(securityReport: Blob) {
  const securityReportFiles = yield call(extractCsvFilesFromZip, securityReport)
  yield put(
    securityReportDrawerActions.setSecurityReportFiles(securityReportFiles)
  )

  const csv = yield call(readFileAsText, securityReportFiles[0])
  const totalNumberOfRows = csv.match(/\n/g)?.length || 0

  yield put(
    securityReportDrawerActions.setTotalNumberOfCsvRows(totalNumberOfRows)
  )
}

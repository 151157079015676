import React, { FC, useState } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import moment from 'moment'
import buildQuery from 'odata-query'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyRegular,
  CardContainer,
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  Heading100,
  Pagination,
  PrimaryButton,
  RowContainer,
  StyledSpacer,
  Table,
  TableView
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'
import { useFetchFilteredUsersTable } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/components/api-hooks/use-fetch-filtered-users-table'
import { ExportButton } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/components/export-button/ExportButton'
import { SendEmailButton } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/components/send-email-button/SendEmailButton'

const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  padding: 18px;
`
interface ExpiredInvitationsDrawerProps {
  closeLink: string
}

const buildQueryForUsersWithExpiredInvitations = (
  additionalQueries?: object
): string =>
  buildQuery({
    filter: {
      accountStatus: AccountStatus.expiredInvitation
    },
    ...additionalQueries
  })

const resultsPerPage = 25
export const ExpiredInvitationsDrawer: FC<ExpiredInvitationsDrawerProps> = ({
  closeLink
}) => {
  const updateQuery = useUpdateQuery(Namespace.expiredInvitations)
  const queryParams = useQueryParams(Namespace.expiredInvitations)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { table, isRequestPending } = useFetchFilteredUsersTable(
    buildQueryForUsersWithExpiredInvitations({
      ...queryParams,
      top: resultsPerPage
    }),
    resultsPerPage
  )

  return (
    <Drawer mainTitle={<Trans>Reinvite users</Trans>} closeLink={closeLink}>
      {(isLoading || isRequestPending) && <Loading color="blue900" active />}
      <BodyRegular>
        <p>
          <Trans>
            Reinvite <b>{table.totalResults}</b> users whose invitation to
            activate LastPass has expired.
          </Trans>
        </p>
        <p>
          <Trans>
            <b>Pro tip:</b> To reinvite users individually, go to the Users
            page. You can also export to CSV.
          </Trans>
        </p>
      </BodyRegular>
      <CardContainer
        css={css`
          margin-top: 24px;
        `}
      >
        <StyledRowContainer>
          <Heading100>
            <Trans>Users</Trans>
          </Heading100>
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={table.totalResults}
            resultsPerPage={resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </StyledRowContainer>
        <Table
          qadata="ExpiredInvitationsDrawerTable"
          table={table}
          columns={[
            {
              name: <Trans>Name</Trans>,
              renderer: (record: Partial<User>) => getFullName(record)
            },
            {
              name: <Trans>Email</Trans>,
              renderer: ({ email }: Partial<User>) => email || ''
            },
            {
              name: <Trans>Last invitation</Trans>,
              renderer: ({ inviteEmailSentTimeUtc }: Partial<User>) =>
                inviteEmailSentTimeUtc
                  ? moment(inviteEmailSentTimeUtc).format('MMM DD, YYYY')
                  : ''
            }
          ]}
          loadingView={<Loading color="blue900" active />}
          actions={[]}
          noRecordsView={
            <TableView
              icon={<UserIcon />}
              title={<Trans>Congrats! Everyone has responded</Trans>}
            />
          }
        />
      </CardContainer>
      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          {table.totalResults > 0 ? (
            <>
              <ExportButton
                onIsLoading={setIsLoading}
                odataFilter={buildQueryForUsersWithExpiredInvitations()}
                fileName="users-with-expired-invitations.csv"
              />
              <SendEmailButton
                successMessage={msg`New invitations have been sent to ${table.totalResults} users.`}
                onIsLoading={setIsLoading}
                buttonText={<Trans>Reinvite users</Trans>}
                odataFilter={buildQueryForUsersWithExpiredInvitations()}
              />
            </>
          ) : (
            <LocationLink to={closeLink}>
              <PrimaryButton>
                <Trans>Close</Trans>
              </PrimaryButton>
            </LocationLink>
          )}
        </DrawerButtonsContainer>
      </FooterContainer>
    </Drawer>
  )
}

import { FunctionComponent } from 'react'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import { css } from '@emotion/react'

import { transitionTimeout } from './transition-timeout'

const fadeCSS = (timeout: CSSTransition.CSSTransitionProps['timeout']) => css`
  &.appear {
    opacity: 0;
  }
  &.appear-active {
    opacity: 1;
    transition: opacity ${transitionTimeout(timeout, 'appear')}ms;
  }

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity ${transitionTimeout(timeout, 'enter')}ms;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity ${transitionTimeout(timeout, 'exit')}ms;
  }
`

export const Fade: FunctionComponent<CSSTransition.CSSTransitionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <CSSTransition {...props}>
      <div className={className} css={fadeCSS(props.timeout)}>
        {children}
      </div>
    </CSSTransition>
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/icon-warning-circle.svg'
import { BodyRegular } from '@lastpass/lastkit'

const Container = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 24px;
  padding: 5px;
  padding-right: 10px;
  background: ${props => props.theme.colors.yellow100};
  border: 1px solid;
  border-color: ${props => props.theme.colors.yellow900};
  border-radius: 5px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledWarningIcon = styled(WarningIcon)`
  height: 22px;
  width: 22px;
  margin: 5px 10px 5px 5px;
`

export const SystemUpgradeHandleManagedCompanyWarning: React.FunctionComponent = () => (
  <Container>
    <StyledWarningIcon data-qa="HandleManagedCompanyWarningIcon" />

    <BodyRegular data-qa="HandleManagedCompanyWarningMsg">
      <Trans>
        Some managed companies are impacted by the recent system upgrade. Visit
        them and upgrade individually.
      </Trans>
    </BodyRegular>
  </Container>
)

import React, { FunctionComponent, useCallback, useState } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as CopyDuplicateIcon } from '@lastpass/assets/svg/admin-console/copy-duplicate-icon.svg'
import { OpenIdProvider, openIdProviderNameMapping } from '@lastpass/federation'
import {
  BodyBold,
  Checkbox,
  DetailsDialog,
  PrimaryButton,
  TextInput
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'
import { useBrowserSafeguard } from '@lastpass/ui/hooks'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationCopyK1Dialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'
interface KeyRotationCopyK1DialogProps {
  state: KeyRotationCopyK1Dialog
  actions: typeof globalActions
}

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 20px;
`
const CheckBoxContainer = styled.div`
  margin-top: 20px;
  display: flex;
`

const CheckBoxText = styled.div`
  margin-left: 8px;
  ${BodyRegularStyle};
`

const StyledExternalLink = styled.a`
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.blue700};
`
const StyledKeyRotationCopyContainer = styled.div`
  margin-top: 20px;
`

const StyledNoticeContainer = styled.div`
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.red700};
`

export const KeyRotationCopyK1DialogComponent: FunctionComponent<KeyRotationCopyK1DialogProps> = ({
  state,
  actions
}) => {
  const onCopy = () => {
    actions.setNotification(CopyToClipboardNotification)
  }
  const [isChecked, setIsChecked] = useState<boolean>(false)
  useBrowserSafeguard()

  const getSupportLinkUrl = useCallback(() => {
    switch (state.providerName) {
      case openIdProviderNameMapping[OpenIdProvider.Okta]:
        return 'https://link.lastpass.com/rotate-knowledge-components-okta?anchor=store_okta_k1'
      case openIdProviderNameMapping[OpenIdProvider.OneLogin]:
        return 'https://link.lastpass.com/rotate-knowledge-components-onelogin?anchor=store_onelogin_k1'
      default:
        return ''
    }
  }, [state.providerName])

  return (
    <DetailsDialog
      closeDialog={() => {}}
      width="600px"
      disableMaxHeight={true}
      title={msg`Store new knowledge component`}
      hideCloseIcon
      disableCloseOnBackground
      content={
        <>
          <TextBoxContainer>
            <Trans>
              Copy the K1 component below and paste it to the service
              {` (${state.providerName})`}. Also save it somewhere since it’s
              not stored by LastPass.
            </Trans>
            <StyledExternalLink
              href={getSupportLinkUrl()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>See setup instructions</Trans>
            </StyledExternalLink>
          </TextBoxContainer>
          <TextBoxContainer>
            <Trans>
              <BodyBold>Warning:</BodyBold> Your users{' '}
              <BodyBold>will lose access to LastPass</BodyBold> unless you paste
              the new K1 component to the service{` (${state.providerName})`}.
            </Trans>
          </TextBoxContainer>
          <StyledNoticeContainer>
            <Trans>
              Paste this key to the service {` (${state.providerName})`}.
            </Trans>
          </StyledNoticeContainer>
          <StyledKeyRotationCopyContainer>
            <TextInput
              name="generatedK1"
              data-qa="generatedK1"
              value={state.k1}
              actionOnCopy={onCopy}
              readOnly
              copyToClipboard
              copyIcon={<CopyDuplicateIcon />}
            />
          </StyledKeyRotationCopyContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
              data-qa="KeyRotationCheckbox"
            />
            <CheckBoxText>
              <Trans>
                To prevent my users from <BodyBold>losing access</BodyBold> to
                LastPass, I’ve pasted the new K1 component to the service
                {` (${state.providerName})`}. I’ve also saved it safely.
              </Trans>
            </CheckBoxText>
          </CheckBoxContainer>
        </>
      }
      buttons={[
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            actions.confirmDialog()
          }}
          type="submit"
          key="confirm"
          disabled={!isChecked}
        >
          <Trans>Finish</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

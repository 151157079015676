import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'

export const getSecurityScore: (score?: number) => SecurityScoreLevel = (
  score?: number
) => {
  if (!score) {
    return SecurityScoreLevel.na
  } else if (score < 50) {
    return SecurityScoreLevel.low
  } else if (score >= 50 && score < 75) {
    return SecurityScoreLevel.average
  } else if (score >= 75 && score < 100) {
    return SecurityScoreLevel.high
  } else if (score >= 100) {
    return SecurityScoreLevel.highest
  }

  return SecurityScoreLevel.na
}

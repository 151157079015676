import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies//types/policy-teams'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'

import {
  Entities,
  MultifactorDrawerActions,
  MultifactorDrawerActionTypes
} from './actions'
import {
  GeneralPolicyUsersAndGroupsFormState,
  MultifactorDrawerState
} from './state'

export const defaultAssignFormState: GeneralPolicyUsersAndGroupsFormState = {
  showTable: Entities.users,
  appliesTo: AppliesToOptions.all,
  usersSearchInput: '',
  groupsSearchInput: '',
  usersIsLoading: false,
  groupsIsLoading: false,
  users: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  filteredUsers: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  availableUsers: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  groups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  filteredGroups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  availableGroups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  cpId: null,
  policyValue: '',
  policyNotes: ''
}

export const initialState: MultifactorDrawerState = {
  actualStep: null,
  isLoading: false,
  selectedMultifactorOption: null,
  duoSecurityConfig: {
    api_hostname: '',
    integration_key: '',
    secret_key: ''
  },
  symantecConfig: {
    certificate: '',
    certificateLength: 0,
    certificatePassword: '',
    hasCertificatePassword: false,
    certificatePasswordLength: 0
  },
  rsaConfig: {
    failureMessage: '',
    hasRadiusSharedSecret: false,
    logo124x124: '',
    logo124x124Length: 0,
    logo190x41: '',
    logo190x41Length: 0,
    radiusServerIp: '',
    radiusSharedSecret: '',
    radiusSharedSecretLength: 0,
    radiusTimeout: '',
    serviceName: ''
  },
  secureAuthConfig: {
    applicationId: '',
    applicationKey: '',
    realm: ''
  },
  assignFormState: defaultAssignFormState,
  isConfigured: false
}

export function multifactorDrawerReducer(
  previousState: MultifactorDrawerState | undefined,
  action: MultifactorDrawerActions
): MultifactorDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case MultifactorDrawerActionTypes.SET_DRAWER_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    }
    case MultifactorDrawerActionTypes.DISCARD: {
      return initialState
    }
    case MultifactorDrawerActionTypes.NEXT_STEP: {
      return {
        ...state,
        actualStep: action.payload.nextStep
      }
    }
    case MultifactorDrawerActionTypes.SET_SELECTED_MFA_OPTION: {
      return {
        ...state,
        selectedMultifactorOption: action.payload.selectedMethod
      }
    }
    case MultifactorDrawerActionTypes.SET_DUO_CONFIG: {
      return {
        ...state,
        duoSecurityConfig: action.payload.formValues
      }
    }
    case MultifactorDrawerActionTypes.SET_SYMANTEC_CONFIG: {
      return {
        ...state,
        symantecConfig: action.payload.symantecConfig
      }
    }
    case MultifactorDrawerActionTypes.SET_RSA_CONFIG: {
      return {
        ...state,
        rsaConfig: action.payload.formValues
      }
    }
    case MultifactorDrawerActionTypes.SET_SECURE_AUTH_CONFIG: {
      return {
        ...state,
        secureAuthConfig: action.payload.formValues
      }
    }
    case MultifactorDrawerActionTypes.SHOW_TABLE: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          showTable: action.payload.table
        }
      }
    }
    case MultifactorDrawerActionTypes.SELECT_TABLE_ELEMENT: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          [action.payload.entity]: {
            ...state.assignFormState[action.payload.entity],
            checkedRecords: (state.assignFormState[action.payload.entity]
              .checkedRecords as (
              | TeamsPolicyUserInfo
              | TeamsPolicyGroup
            )[]).concat(action.payload.records)
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.DESELECT_TABLE_ELEMENT: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          [action.payload.entity]: {
            ...state.assignFormState[action.payload.entity],
            checkedRecords: (state.assignFormState[action.payload.entity]
              .checkedRecords as (TeamsPolicyUserInfo &
              TeamsPolicyGroup)[]).filter(
              record => !action.payload.records.includes(record)
            )
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          [action.payload.entity]: {
            ...state.assignFormState[action.payload.entity],
            checkedRecords: action.payload.records
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_GROUP_LIST_SEARCH_INPUT: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          groupsSearchInput: action.payload.searchString
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_USER_LIST_SEARCH_INPUT: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          usersSearchInput: action.payload.searchString
        }
      }
    }
    case MultifactorDrawerActionTypes.CHANGE_APPLIES_TO: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          appliesTo: action.payload.appliesTo
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_AVAILABLE_USERS_LOADING: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          usersIsLoading: action.payload.isLoading
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_AVAILABLE_USERS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          availableUsers: {
            ...state.assignFormState.availableUsers,
            results: action.payload.users,
            totalResults: action.payload.users.length
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_AVAILABLE_GROUPS_LOADING: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          groupsIsLoading: action.payload.isLoading
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_AVAILABLE_GROUPS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          availableGroups: {
            ...state.assignFormState.availableGroups,
            results: action.payload.groups,
            totalResults: action.payload.groups.length
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.ASSIGN: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          users: {
            ...state.assignFormState.users,
            results: state.assignFormState.users.results.concat(
              state.assignFormState.availableUsers.checkedRecords
            ),
            totalResults:
              state.assignFormState.users.results.length +
              state.assignFormState.availableUsers.checkedRecords.length
          },
          filteredUsers: {
            ...state.assignFormState.filteredUsers,
            results: state.assignFormState.filteredUsers.results.concat(
              state.assignFormState.availableUsers.checkedRecords
            ),
            totalResults:
              state.assignFormState.filteredUsers.results.length +
              state.assignFormState.availableUsers.checkedRecords.length
          },
          availableUsers: {
            ...state.assignFormState.availableUsers,
            checkedRecords: []
          },
          groups: {
            ...state.assignFormState.groups,
            results: state.assignFormState.groups.results.concat(
              state.assignFormState.availableGroups.checkedRecords
            ),
            totalResults:
              state.assignFormState.groups.results.length +
              state.assignFormState.availableGroups.checkedRecords.length
          },
          filteredGroups: {
            ...state.assignFormState.filteredGroups,
            results: state.assignFormState.filteredGroups.results.concat(
              state.assignFormState.availableGroups.checkedRecords
            ),
            totalResults:
              state.assignFormState.filteredGroups.results.length +
              state.assignFormState.availableGroups.checkedRecords.length
          },
          availableGroups: {
            ...state.assignFormState.availableGroups,
            checkedRecords: []
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.UNASSIGN: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          users: {
            ...state.assignFormState.users,
            results: state.assignFormState.users.results.filter(
              user =>
                !state.assignFormState.filteredUsers.checkedRecords.includes(
                  user
                )
            ),
            checkedRecords: state.assignFormState.users.results.filter(
              user =>
                !state.assignFormState.filteredUsers.checkedRecords.includes(
                  user
                )
            ),
            totalResults:
              state.assignFormState.users.totalResults -
              state.assignFormState.filteredUsers.checkedRecords.length
          },
          filteredUsers: {
            ...state.assignFormState.filteredUsers,
            results: state.assignFormState.filteredUsers.results.filter(
              user =>
                !state.assignFormState.filteredUsers.checkedRecords.includes(
                  user
                )
            ),
            checkedRecords: state.assignFormState.filteredUsers.checkedRecords.filter(
              user =>
                !state.assignFormState.filteredUsers.checkedRecords.includes(
                  user
                )
            ),
            totalResults:
              state.assignFormState.filteredUsers.totalResults -
              state.assignFormState.filteredUsers.checkedRecords.length
          },
          groups: {
            ...state.assignFormState.groups,
            results: state.assignFormState.groups.results.filter(
              group =>
                !state.assignFormState.filteredGroups.checkedRecords.includes(
                  group
                )
            ),
            checkedRecords: state.assignFormState.groups.checkedRecords.filter(
              group =>
                !state.assignFormState.filteredGroups.checkedRecords.includes(
                  group
                )
            ),
            totalResults:
              state.assignFormState.groups.totalResults -
              state.assignFormState.groups.checkedRecords.length
          },
          filteredGroups: {
            ...state.assignFormState.filteredGroups,
            results: state.assignFormState.filteredGroups.results.filter(
              group =>
                !state.assignFormState.filteredGroups.checkedRecords.includes(
                  group
                )
            ),
            checkedRecords: state.assignFormState.filteredGroups.checkedRecords.filter(
              group =>
                !state.assignFormState.filteredGroups.checkedRecords.includes(
                  group
                )
            ),
            totalResults:
              state.assignFormState.filteredGroups.totalResults -
              state.assignFormState.filteredGroups.checkedRecords.length
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.FILTER_USERS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          filteredUsers: {
            ...state.assignFormState.filteredUsers,
            results: state.assignFormState.users.results.filter(
              user =>
                user.email
                  .toLowerCase()
                  .includes(action.payload.filterString) ||
                user.name.toLowerCase().includes(action.payload.filterString)
            )
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.FILTER_GROUPS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          filteredGroups: {
            ...state.assignFormState.filteredGroups,
            results: state.assignFormState.groups.results.filter(group =>
              group.name.toLowerCase().includes(action.payload.filterString)
            )
          }
        }
      }
    }
    case MultifactorDrawerActionTypes.SET_POLICY_USER_DATA: {
      return {
        ...state,
        assignFormState: action.payload.policyUserData
      }
    }
    case MultifactorDrawerActionTypes.SET_IS_CONFIGURED: {
      return {
        ...state,
        isConfigured: action.payload.isConfigured
      }
    }
  }
  return state
}

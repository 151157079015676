import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { getPolicyInfo } from '@lastpass/admin-console-dependencies/sagas/policies/general/drawer/get-general-policy'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { preparePolicyData } from '@lastpass/admin-console-dependencies/services/policies/policy-initializer'
import {
  Group,
  User
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  InitialTeamsPolicyModelData,
  SaveTeamsPolicySwitchOnData,
  TeamsPolicyMap
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import {
  MapTeamsPolicyGroup,
  MapTeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/ui/common/mappings/ssoapp-mappers'

export function createInitialTeamsPolicyModelData(
  users: User[],
  groups: Group[]
) {
  let initialTeamsPolicyModelData: InitialTeamsPolicyModelData

  if (groups.length > 0) {
    const allUsersGroup: Group[] = groups.filter(
      (group: Group) => group.isAllUsersGroup
    )

    switch (allUsersGroup.length) {
      case 0:
        initialTeamsPolicyModelData = {
          appliesTo: AppliesToOptions.inclusive,
          inclusiveList: MapTeamsPolicyUserInfo(users),
          inclusiveGroupList: MapTeamsPolicyGroup(groups)
        }
        break
      case 1:
        initialTeamsPolicyModelData = {
          appliesTo: AppliesToOptions.all
        }
        break
      default:
        throw 'Multiple "All Users" Group'
    }
  } else {
    initialTeamsPolicyModelData = {
      appliesTo: AppliesToOptions.inclusive,
      inclusiveList: MapTeamsPolicyUserInfo(users),
      inclusiveGroupList: MapTeamsPolicyGroup(groups)
    }
  }

  return initialTeamsPolicyModelData
}

export function createEnableMultifactor(policyService: UACServices.Services) {
  return function* enableMultifactor(
    action: ReturnType<typeof applicationsDrawerActions.enableMultifactor>
  ) {
    try {
      try {
        const policyKey: string = action.payload.policyKey
        const users: User[] = action.payload.users
        const groups: Group[] = action.payload.groups

        if (users.length === 0 && groups.length === 0) {
          throw 'Users and groups cannot be empty'
        }

        const initialTeamsPolicyModelData: InitialTeamsPolicyModelData = createInitialTeamsPolicyModelData(
          users,
          groups
        )

        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy = policyMap[policyKey]

        const policyData = yield call(
          preparePolicyData,
          policy,
          initialTeamsPolicyModelData
        )
        const policySaveData: SaveTeamsPolicySwitchOnData = {
          policyKey,
          policyData
        }

        const result: UACServices.GeneralPolicySwitchONSaveAPI.Responses = yield call(
          policyService.generalPolicySwitchOnSave,
          policySaveData
        )

        if (result.type === UACServices.GeneralPolicySwitchONSaveAPI.SUCCESS) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }

        yield put(
          applicationsDrawerActions.nextStep(ApplicationDrawerSteps.allset)
        )
      } finally {
        yield put(applicationsDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

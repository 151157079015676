import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UsersIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/admin-console/warning-icon.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerTable,
  Pagination,
  PrimaryButton,
  RowContainer,
  SearchInput,
  TableView,
  TextButton,
  useDrawerSubtitle,
  WithTooltip
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { useUnmountEffect } from '@lastpass/ui'

import { assignAdminLevelUsersActions } from '@lastpass/admin-console-dependencies/state/users/admin/add-users/actions'
import { AssignAdminLevelUsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/add-users/state'
import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'

const selectedRowsMaxLimit = 25

const StyledTextButton = styled(TextButton)`
  margin-right: 15px;
`

const StyledWarningIcon = styled(WarningIcon)`
  color: ${props => props.theme.colors.yellow600};
  height: 16px;
  width: 16px;
  margin-bottom: -3px;
`

export const AssignAdminLevelUsersDrawerTable: React.FunctionComponent = () => {
  const assignAdminLevelUsersDrawer: AssignAdminLevelUsersDrawerState = useSelector(
    (state: AppState) => state.assignAdminLevelUsersDrawer
  )
  const adminLevelDrawer: AdminLevelDrawerState = useSelector(
    (state: AppState) => state.adminLevelDrawer
  )
  const queryParams = useQueryParams(Namespace.addUsersToAdmin)
  const updateQuery = useUpdateQuery(Namespace.addUsersToAdmin)
  const pathParams = usePathParams()
  const dispatch = useDispatch()

  const actions = {
    getAdminAssignUserList: (queryParams, pathParams) =>
      dispatch(
        assignAdminLevelUsersActions.getAdminAssignUserList({
          query: queryParams,
          path: pathParams
        })
      ),
    addSelectedAdminUsers: users =>
      dispatch(assignAdminLevelUsersActions.addSelectedAdminUsers(users)),
    removeSelectedAdminUsers: users =>
      dispatch(assignAdminLevelUsersActions.removeSelectedAdminUsers(users)),
    setSelectedAdminUsers: users =>
      dispatch(assignAdminLevelUsersActions.setSelectedAdminUsers(users)),
    addUsersToAdmin: (checkedRecords, adminLevel) =>
      dispatch(
        assignAdminLevelUsersActions.addUsersToAdmin(
          checkedRecords,
          adminLevel,
          {},
          `/users/admin/${adminLevel.id}`,
          false
        )
      )
  }

  useEffect(() => {
    actions.getAdminAssignUserList(queryParams, pathParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Assign users</Trans>)
  useUnmountEffect(() => {
    actions.setSelectedAdminUsers([])
  })

  return (
    <>
      <RowContainer>
        <SearchInput
          data-qa="DrawerSearchInputField"
          flexGrow
          value={queryParams.search || ''}
          placeholder={msg`Search users`}
          onChange={e =>
            updateQuery({
              search: e.target.value || undefined,
              skip: undefined
            })
          }
        />
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={assignAdminLevelUsersDrawer.users.totalResults}
          resultsPerPage={assignAdminLevelUsersDrawer.users.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </RowContainer>
      <DrawerTable
        table={assignAdminLevelUsersDrawer.users}
        loadingView={<Loading color="blue900" active={true} />}
        checkable={{
          addSelectedRecords: users => actions.addSelectedAdminUsers(users),
          removeSelectedRecords: users =>
            actions.removeSelectedAdminUsers(users),
          setSelectedRecords: users => actions.setSelectedAdminUsers(users)
        }}
        showSelectedNumber
        selectedRecordLimit={selectedRowsMaxLimit}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: (record: User) => getFullName(record)
          },
          {
            name: <Trans>Email</Trans>,
            renderer: (record: User) => record.email
          },
          {
            name: <Trans>Admin level</Trans>,
            renderer: function UserLevelRenderer(record: User) {
              return <UserLevelLabel userLevel={record.adminLevel} />
            }
          },
          {
            name: <></>,
            noTooltip: true,
            contentWidth: '120px',
            renderer: function WarningRenderer(record: User) {
              return record.adminLevel &&
                record.adminLevel !== adminLevelDrawer.adminLevel &&
                assignAdminLevelUsersDrawer.users.checkedRecords
                  .map(checkedRecord => checkedRecord.id)
                  .includes(record.id) ? (
                <>
                  <WithTooltip
                    tooltip={
                      <Trans>
                        You can only assign a user to one admin level at a time.
                        The most recently assigned level will be activated.
                      </Trans>
                    }
                    placement="top"
                  >
                    <StyledWarningIcon data-qa="warningIcon" />
                  </WithTooltip>
                </>
              ) : (
                <></>
              )
            }
          }
        ]}
        actions={[]}
        noRecordsView={
          <TableView
            icon={<UsersIcon />}
            title={
              <Trans>
                All of your users are already assigned to this admin level
              </Trans>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UsersIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        isFilterApplied={!!queryParams.search}
        footerNodes={[
          <LocationLink to={`/users/admin/${pathParams.id}`} key="cancel">
            <StyledTextButton>
              <Trans>Cancel</Trans>
            </StyledTextButton>
          </LocationLink>,
          <PrimaryButton
            key="assign"
            disabled={
              assignAdminLevelUsersDrawer.users.checkedRecords.length === 0 ||
              assignAdminLevelUsersDrawer.users.checkedRecords.length >
                selectedRowsMaxLimit
            }
            onClick={() =>
              actions.addUsersToAdmin(
                assignAdminLevelUsersDrawer.users.checkedRecords,
                adminLevelDrawer.adminLevel
              )
            }
          >
            <Trans>Assign users</Trans>
          </PrimaryButton>
        ]}
        disableRowHover={true}
      />
    </>
  )
}

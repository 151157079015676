export enum AccountStatus {
  na = 'na',
  active = 'active',
  invited = 'invited',
  loggedIn = 'loggedIn',
  awaitingApproval = 'awaitingApproval',
  disabled = 'disabled',
  staged = 'staged',
  expiredInvitation = 'expiredInvitation',
  enrolled = 'enrolled',
  pendingInvitation = 'pendingInvitation'
}

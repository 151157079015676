import React from 'react'
import { Link, LinkProps, RouteProps, useRouteMatch } from 'react-router-dom'

import {
  SideNavigationItem,
  SideNavigationItemProps
} from '@lastpass/lastkit/components/SideNavigation'

export const RoutedSideNavigationItem: React.FunctionComponent<SideNavigationItemProps &
  LinkProps & { route: RouteProps }> = ({ children, ...props }) => {
  const match = useRouteMatch(props.route)

  const linkProps = {
    ...props,
    route: undefined,
    icon: undefined
  }
  delete linkProps.tooltipText
  return (
    <Link {...linkProps}>
      <SideNavigationItem
        active={!!match}
        icon={props.icon}
        tooltipText={props.tooltipText}
        tooltipWidthPixels={props.tooltipWidthPixels}
      >
        {children}
      </SideNavigationItem>
    </Link>
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Checkbox } from '@lastpass/lastkit/components/Checkbox'
import {
  HeaderCell,
  HeaderCheckboxCell
} from '@lastpass/lastkit/components/Table'
import { CaptionSemiboldStyle } from '@lastpass/lastkit/styles'

const StyledHeaderCell = styled.div<{
  width?: string
  contentWidth?: string
}>`
  padding-top: 12px;
  padding-bottom: 12px;

  &:first-of-type {
    padding: 16px;
  }

  color: ${props => props.theme.colors.neutral900};
  min-width: ${props => (props.contentWidth ? 'auto' : '80px')};
  display: flex;
  align-self: center;
  flex-basis: ${props => (props.contentWidth ? props.contentWidth : '100%')};
  word-break: break-word;

  min-width: ${props => (props.width ? props.width : 'auto')};
  max-width: ${props => (props.width ? props.width : 'auto')};
  padding-right: 8px;
  padding-left: 8px;
`

const StyledHeaderCellButton = styled.button`
  ${CaptionSemiboldStyle}
  cursor: pointer;
  color: ${props => props.theme.colors.red700};
  padding: 0;
  border: none;
  background: none;
  margin-left: 20px;
`

const StyledSpacer = styled.div`
  height: 40px;
  width: 60px;
  top: 0;
  right: 0;
`

const StyledTableHead = styled.div<{
  checked?: boolean
  disableRowHover?: boolean
}>`
    display: flex;
    flex-direction: row;
    box-shadow: inset 0px -1px 0px ${props => props.theme.colors.neutral200};
    background: ${props =>
      props.checked ? props.theme.colors.blue200 : 'transparent'};

    width: 100%;
    ${CaptionSemiboldStyle}
    color: ${props => props.theme.colors.neutral600};
    height: 40px;
    &:hover {
      background: transparent;
    }
  `

export const ExtendableFormTableHead = ({
  checked,
  disabled,
  indeterminate,
  columns,
  onChange,
  totalCheckedRecords,
  clearCheckedRecords,
  setFilterToError
}: {
  checked: boolean
  disabled: boolean
  indeterminate: boolean
  columns: {
    name: string
    width?: string
  }[]
  totalCheckedRecords: number
  clearCheckedRecords: Function
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFilterToError: Function
}) => {
  return (
    <StyledTableHead>
      <HeaderCheckboxCell contentWidth={'auto'}>
        <Checkbox
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          indeterminate={indeterminate}
          data-qa={'AddUsersSelectAllCheckbox'}
        />
      </HeaderCheckboxCell>
      {totalCheckedRecords > 0 ? (
        <>
          <HeaderCell data-qa={'SelectedUsersCounter'}>
            <Trans>Selected users: {totalCheckedRecords}</Trans>
            <StyledHeaderCellButton
              type="button"
              onClick={() => {
                clearCheckedRecords()
                setFilterToError(false)
              }}
              data-qa={'RemoveSelectedUsersButton'}
            >
              <Trans>Remove</Trans>
            </StyledHeaderCellButton>
          </HeaderCell>
        </>
      ) : (
        columns.map(column => (
          <StyledHeaderCell key={`header.${column.name}`} width={column.width}>
            {column.name}
          </StyledHeaderCell>
        ))
      )}
      <StyledSpacer />
    </StyledTableHead>
  )
}

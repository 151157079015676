import { StatusCodes } from 'http-status-codes'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'

export interface ForcedFeatureFlag {
  name: string
  value: boolean
}

type FlagCache = { [userId: string]: { [flagName: string]: boolean } }

let mockLaunchDarklyUserId: string
const flagCache: FlagCache = {}

async function fetchFlags(userId: string): Promise<void> {
  const url = `${defaultConfig.launchDarklyMockAddress}/users/${userId}`
  const response = await fetch(url)

  if (response.status === StatusCodes.NOT_FOUND) return

  const { flags }: { flags: ForcedFeatureFlag[] } = await response.json()
  flagCache[userId] = Object.fromEntries(
    Object.entries(flags).map(([name, flagData]) => [name, flagData.value])
  )
}

export async function initMockedFeatureFlags(userId: string): Promise<void> {
  mockLaunchDarklyUserId = userId
  await fetchFlags(userId)
}

export function getMockedFeatureFlags(
  userId: string
): { [flagName: string]: boolean } {
  return flagCache[userId] ?? {}
}

export const getFeatureFlagsHook = () => {
  return defaultConfig.launchDarklyMockAddress
    ? () => getMockedFeatureFlags(mockLaunchDarklyUserId)
    : useFlags
}

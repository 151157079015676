import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Plural, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/admin-console/users/dialog/provisioned-users/warning.svg'
import {
  BodyBold,
  BodyRegularStyle,
  DetailsDialog,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ManageProvisionedUserConfirmationDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface ManageProvisionedUserConfirmationDialogProps {
  state: ManageProvisionedUserConfirmationDialogType
  actions: typeof globalActions
}

const ContentContainer = styled.div`
  ${BodyRegularStyle};
`

const Paragraph = styled.p`
  margin-bottom: 16px;
`

const StyledTitle = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`

const StyledIcon = styled(WarningIcon)`
  height: 120px;
  width: 120px;
  margin-top: -24px;
`
const FooterButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const ManageProvisionedUserConfirmationDialog: FC<ManageProvisionedUserConfirmationDialogProps> = ({
  state: { isAllSelected, allUserCount, users, areAllUsersProvisioned }
}) => {
  const numberOfUsers = isAllSelected ? allUserCount : users.length

  const dispatch = useDispatch()
  const { _ } = useLingui()
  const Content = (
    <ContentContainer data-qa="ManageProvisionedUserConfirmationDialog">
      <IconContainer>
        <StyledIcon />
      </IconContainer>
      <StyledTitle>
        <Plural
          value={numberOfUsers}
          one="Automatically provisioned user"
          other="Automatically provisioned users"
        />
      </StyledTitle>
      <Paragraph>
        {areAllUsersProvisioned ? (
          <Plural
            value={numberOfUsers}
            one="This user’s account was automatically provisioned from a directory service:"
            other="The selected users’ accounts were automatically provisioned from a directory service."
          />
        ) : (
          <Plural
            value={numberOfUsers}
            one="This user’s account was automatically provisioned from a directory service:"
            other="Some of the selected users’ accounts were automatically provisioned from a directory service."
          />
        )}
        {numberOfUsers === 1 && (
          <>
            {' '}
            <BodyBold>{users[0].email}</BodyBold>
          </>
        )}
      </Paragraph>
      <Paragraph>
        <Trans>
          To effectively manage automatically provisioned users, manage them in
          the directory first. Otherwise your work will be overwritten when the
          directory next syncs with LastPass.
        </Trans>{' '}
      </Paragraph>
      <FooterButtonContainer>
        <TextButton
          blue
          onClick={() => {
            dispatch(
              globalActions.confirmManageProvisionedUsersDialogWithAllUsers()
            )
          }}
          data-qa="ConfirmManageProvisionedUsersDialogWithAllUsersButton"
        >
          {areAllUsersProvisioned
            ? _(msg`I understand the risk, continue`)
            : _(msg`I understand the risk, continue with all`)}
        </TextButton>
      </FooterButtonContainer>
      {!areAllUsersProvisioned && (
        <FooterButtonContainer>
          <TextButton
            blue
            onClick={() => {
              dispatch(
                globalActions.confirmManageProvisionedUsersDialogWithManuallyAddedUsers()
              )
            }}
            data-qa="ConfirmManageProvisionedUsersDialogWithManuallyAddedUsersButton"
          >
            <Trans>Continue with manually-added users only</Trans>
          </TextButton>
        </FooterButtonContainer>
      )}
    </ContentContainer>
  )

  return (
    <DetailsDialog
      width="526px"
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      content={Content}
      disableMaxHeight
    />
  )
}

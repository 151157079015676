import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'

export function createUserAppsSaga(userService: UACServices.Services) {
  return function* getUserAppsSaga(
    action: ReturnType<typeof userDrawerActions.getUserApps>
  ) {
    try {
      const id = action.payload.params.path.id
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        search: encodeSearch(action.payload.params.query.search),
        top: resultsPerPage
      })
      if (id) {
        yield put(userDrawerActions.setUserAppsLoading(true))
        try {
          const result: UACServices.UserAppsAPI.Responses = yield call(
            userService.userApps,
            id,
            query
          )
          if (result.type === UACServices.UserAppsAPI.SUCCESS) {
            const apps = result.body.apps
            const totalResults = result.body.totalResults
            yield put(
              userDrawerActions.setUserApps(apps, totalResults, resultsPerPage)
            )
          }
        } finally {
          yield put(userDrawerActions.setUserAppsLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { invokeApi } from './operation-base'

export function createRequirePasswordChangeSaga(
  userService: UACServices.Services
) {
  return function* requirePasswordChangeSaga(
    action: ReturnType<typeof userOperationActions.requirePasswordChange>
  ) {
    try {
      const users = action.payload.userOperationPayload.users

      let text: MessageDescriptor = msg`Require master password change for the selected users? Their active sessions will be destroyed.`
      if (users.length === 1) {
        text = msg`Require this user to change their master password? ${users[0].email}. Their active sessions will be destroyed.`
      }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        confirmText: msg`Require change`,
        discardText: msg`Cancel`,
        text,
        title: msg`Require master password change`
      }

      yield put(globalActions.setDialog(dialog))

      const dialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (dialogAction.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users will be required to change their master password upon next login.`,
            MultipleUsersSuccess: msg`The selected users will be required to change their master password upon next login.`,
            SingleUserFail:
              users.length > 0
                ? msg`This user won't be required to change their master password upon next login:  ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User will be required to change their master password upon next login: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users won't be required to change their master password upon next login.`
          },
          'requiremasterpasswordchange',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.REQUIRE_PASSWORD_CHANGE,
          action.payload.userOperationPayload.filter
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { AcronisApiActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/acronis-api/actions'

import { createGenerateProvisioningHash } from './generate-provisioning-hash'
import { createGetAcronisApi } from './get-acronis-api'

export function createAcronisApiSaga(uacServices: UACServices.Services) {
  const getAcronisApi = createGetAcronisApi(uacServices.acronisApi)
  const generateHash = createGenerateProvisioningHash(uacServices.acronisApi)

  return function*() {
    yield takeEvery(AcronisApiActionTypes.ACRONIS_API_GET, getAcronisApi)
    yield takeEvery(
      AcronisApiActionTypes.GENERATE_PROVISIONING_HASH,
      generateHash
    )
  }
}

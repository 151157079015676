import React, { useEffect } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { BodyRegular, PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { DashboardState } from '@lastpass/admin-console-dependencies/state/dashboard/state'

import {
  CallToActionBoxBody,
  CallToActionBoxHeading
} from './UnresponsiveUsers'

export interface UserAdoptionBoxProps {
  isChildCompany: boolean
  showBuyLicenses: boolean
  isBillingUsageBased: boolean
}

type UserAdoptionBoxComponentProps = LastPassComponentProps<
  DashboardState,
  typeof dashboardActions,
  UserAdoptionBoxProps
>

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.blue100};
  border-radius: ${props => props.theme.radius.pixel4};
`

const StyledTop = styled.div`
  padding: 24px 24px 16px 24px;
`

const StyledBottom = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${props => props.theme.colors.grayBorder};
  margin: 0px 24px 0px 24px;
  padding: 12px 0 12px 0;
  justify-content: space-between;
  align-items: center;
`

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
`

export const UserAdoptionBox: React.FunctionComponent<UserAdoptionBoxComponentProps> = ({
  state,
  actions,
  props
}) => {
  useEffect(() => {
    actions.getUserAdoptions()
  }, [actions])
  useEffect(() => {
    actions.dashboardViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container data-qa={'UserAdoptionBox'}>
      <StyledTop>
        <CallToActionBoxHeading>
          <Trans>Bring employees on board</Trans>
        </CallToActionBoxHeading>
        <BodyRegular>
          <Trans>Add people to your LastPass organization. All aboard!</Trans>
        </BodyRegular>
      </StyledTop>

      <StyledBottom>
        <LeftPanel>
          <CallToActionBoxHeading data-qa={'StagedUserCount'}>
            {state.stagedUserCount}
          </CallToActionBoxHeading>
          <CallToActionBoxBody>
            <Trans>Staged users</Trans>
          </CallToActionBoxBody>
        </LeftPanel>
        <LocationLink to={'/dashboard/invite-staged'}>
          <PrimaryButton
            data-qa={'ViewAndInviteButton'}
            onClick={() => {
              actions.inviteUsersButtonClicked()
            }}
          >
            <Trans>View & invite</Trans>
          </PrimaryButton>
        </LocationLink>
      </StyledBottom>

      <StyledBottom>
        <LeftPanel>
          {!props.isBillingUsageBased && (
            <>
              <CallToActionBoxHeading data-qa={'RemainingLicenses'}>
                {state.remainingLicenses}
              </CallToActionBoxHeading>
              <CallToActionBoxBody>
                <Trans>Available seats</Trans>
              </CallToActionBoxBody>
            </>
          )}
        </LeftPanel>
        <LocationLink to={'/dashboard/add'}>
          <PrimaryButton
            data-qa={'AddMoreUsersButton'}
            onClick={() => {
              actions.addUsersButtonClicked()
            }}
          >
            <Trans>Add more users</Trans>
          </PrimaryButton>
        </LocationLink>
      </StyledBottom>

      {!props.isChildCompany && !props.isBillingUsageBased && (
        <StyledBottom data-qa="TotalLicensesContainer">
          <LeftPanel>
            <CallToActionBoxHeading data-qa={'TotalLicenses'}>
              {state.totalLicenses}
            </CallToActionBoxHeading>
            <CallToActionBoxBody>
              <Trans>Total licenses</Trans>
            </CallToActionBoxBody>
          </LeftPanel>
          {props.showBuyLicenses && (
            <PrimaryButton
              data-qa={'UserAdoptionBoxBuyLicensesButton'}
              customColors={{
                backgroundColor: 'yellow600',
                textColor: 'neutral900',
                hoverBackgroundColor: 'yellow700',
                focusBackgroundColor: 'yellow700'
              }}
              onClick={() => {
                window.open(
                  `${defaultConfig.lastPassBaseAddress}/enterprisepayment.php`,
                  '_blank'
                )
              }}
            >
              <Trans>Buy licenses</Trans>
            </PrimaryButton>
          )}
        </StyledBottom>
      )}
    </Container>
  )
}
export function createUserAdoptionBoxComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    UserAdoptionBox,
    (state: TState) => state.dashboard,
    dashboardActions
  )
}

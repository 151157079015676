import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { GeneralPoliciesDrawerState } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/state'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicyAssignUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/state'
import { generalPolicySettingsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/actions'
import { GeneralPolicySettingsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/state'
import { generalPolicyUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import { GeneralPolicyUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'

export const getPolicyDrawerData = (state: AppState) =>
  state.generalPoliciesDrawer
export const getPolicySettingsData = (state: AppState) =>
  state.generalPolicySettings
export const getPolicyUsersAndGroupsData = (state: AppState) =>
  state.generalPolicyUsersAndGroups
export const getPolicyAssignUsersAndGroupsData = (state: AppState) =>
  state.generalPolicyAssignUsersAndGroups

const doesDrawerNeedUpdate = (
  drawerData: GeneralPoliciesDrawerState
): boolean => drawerData.drawerNeedsRefresh || drawerData.isDrawerOpen

const doesSettingsNeedUpdate = (
  settingsData: GeneralPolicySettingsFormState
): boolean => settingsData.settingsNeedsRefresh || settingsData.isSettingsOpen

const doesUsersAndGroupsNeedUpdate = (
  usersAndGroupsData: GeneralPolicyUsersAndGroupsFormState
): boolean =>
  usersAndGroupsData.usersAndGroupsNeedsRefresh ||
  usersAndGroupsData.isUsersAndGroupsOpen

const doesAssignUsersAndGroupsNeedUpdate = (
  assignUsersAndGroupsData: GeneralPolicyAssignUsersAndGroupsFormState
): boolean =>
  assignUsersAndGroupsData.assignUsersAndGroupsNeedsRefresh ||
  assignUsersAndGroupsData.isAssignUsersAndGroupsOpen

export function createUpdateGeneralPoliciesDrawers() {
  return function* updateGeneralPoliciesDrawer(
    action: ReturnType<
      typeof generalPoliciesListActions.updateGeneralPoliciesDrawers
    >
  ) {
    const generalPoliciesMap = action.payload.generalPoliciesMap

    if (Object.keys(generalPoliciesMap).length > 0) {
      const policyDrawerData: GeneralPoliciesDrawerState = yield select(
        getPolicyDrawerData
      )
      if (doesDrawerNeedUpdate(policyDrawerData)) {
        yield put(
          generalPoliciesDrawerActions.getCurrentGeneralPolicy({
            query: {},
            path: {
              id: policyDrawerData.policyKey
            }
          })
        )
      }
      const policySettingsData: GeneralPolicySettingsFormState = yield select(
        getPolicySettingsData
      )
      if (doesSettingsNeedUpdate(policySettingsData)) {
        yield put(
          generalPolicySettingsActions.getGeneralPolicySettings({
            query: {},
            path: {
              id: policySettingsData.policySettings.policyKey,
              configId: policySettingsData.policySettings.policyConfigIndex.toString()
            }
          })
        )
      }

      const policyUsersAndGroupsData: GeneralPolicyUsersAndGroupsFormState = yield select(
        getPolicyUsersAndGroupsData
      )
      if (doesUsersAndGroupsNeedUpdate(policyUsersAndGroupsData)) {
        yield put(
          generalPolicyUsersAndGroupsActions.getGeneralPolicyUsersAndGroups({
            query: {},
            path: {
              id: policyUsersAndGroupsData.policyUsersAndGroups.policyKey,
              configId: policyUsersAndGroupsData.policyUsersAndGroups.policyConfigIndex.toString()
            }
          })
        )
      }
      const policyAssignUsersAndGroupsData: GeneralPolicyAssignUsersAndGroupsFormState = yield select(
        getPolicyAssignUsersAndGroupsData
      )
      if (doesAssignUsersAndGroupsNeedUpdate(policyAssignUsersAndGroupsData)) {
        yield put(
          generalPolicyAssignUsersAndGroupsActions.getGeneralPolicyAssignUsersAndGroups(
            {
              query: {},
              path: {
                id:
                  policyAssignUsersAndGroupsData.policyAssignUsersAndGroups
                    .policyKey,
                configId: policyAssignUsersAndGroupsData.policyAssignUsersAndGroups.policyConfigIndex.toString()
              }
            }
          )
        )
      }
    }
  }
}

import { call, put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { readFileAsText } from '@lastpass/admin-console-dependencies/services/read-file-as-text'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { SymantecModel } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'

export function createSetSymantecConfig() {
  return function* setSymantecConfig(
    action: ReturnType<typeof multifactorDrawerActions.processSymantecConfig>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      try {
        let certificateStr
        let certificateLength = 0
        const cert = action.payload.formValues.certificate
        if (cert) {
          certificateLength = typeof cert === 'string' ? cert.length : cert.size
          if (typeof cert === 'string') {
            certificateLength = cert.length
            certificateStr = cert
          } else {
            certificateLength = cert.size
            certificateStr = yield call(readFileAsText, cert)
          }
        }
        const formValues = action.payload.formValues
        const symantecConfig: SymantecModel = {
          certificate: certificateStr,
          certificateLength: certificateLength,
          certificatePassword: formValues.certificatePassword,
          certificatePasswordLength:
            formValues.certificatePassword?.length ?? 0,
          hasCertificatePassword:
            !!formValues.certificatePassword &&
            formValues.certificatePassword.length > 0
        }

        yield put(multifactorDrawerActions.setSymantecConfig(symantecConfig))
        yield put(
          multifactorDrawerActions.nextStep(MultifactorDrawerSteps.assign)
        )
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

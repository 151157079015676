import { track } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { ManagedMigrationCompanyInfo } from '@lastpass/admin-console-dependencies/state/applications/application'
import { SystemUpgradeModel } from '@lastpass/admin-console-dependencies/types/system-upgrade-model'

import { TeamsPolicy } from '../../types/policy-teams'
import {
  Application,
  ClientOauth,
  Role,
  SamlKey,
  UsernameAttribute
} from '../applications/application'
import {
  AccessPolicyMap,
  AuthenticationPolicyMap,
  GeofencePolicyMap,
  IpPolicyMap
} from '../policies/passwordless/passwordless'
import { initialState } from './reducer'

export enum SystemUpgradeSegmentActionTypes {
  SYSTEM_UPGRADE_PAGE_SHOWN = '[system-upgrade-page-shown] system-upgrade-page-shown'
}

export const systemUpgradeSegmentActions = {
  addAdminOnboardingPageShown: () =>
    track(
      createAction(SystemUpgradeSegmentActionTypes.SYSTEM_UPGRADE_PAGE_SHOWN),
      {}
    )
}

export enum SystemUpgradeActionTypes {
  SET_SYSTEM_UPGRADE = '[system-upgrade] set',
  GET_SYSTEM_UPGRADE = '[system-upgrade] get',
  COMPLETE_SYSTEM_UPGRADE_STEP = '[system-upgrade] complete-step',
  SET_STEPS_LOADING = '[system-upgrade] set-steps-loading',
  FETCH_INITIAL_DATA = '[system-upgrade] fetch-initial-data',
  SET_SAML_KEYS = '[system-upgrade] set-saml-keys',
  SET_USERNAME_ATTRIBUTES = '[system-upgrade] set-username-attributes',
  SET_APPLICATION_LIST = '[system-upgrade] set-application-list',
  SET_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY = '[system-upgrade] set-require-passwordless-via-lastpass-policy',
  SET_ROLE_LIST = '[system-upgrade] set-role-list',
  SET_ACCESS_POLICIES = '[system-upgrade] set-access-policies',
  SET_IP_POLICIES = '[system-upgrade] set-ip-policies',
  SET_GEOFENCE_POLICIES = '[system-upgrade] set-geofence-policies',
  SET_AUTHENTICATION_POLICIES = '[system-upgrade] set-authentication-policies',
  START_UPGRADE = '[system-upgrade] start-upgrade',
  UPGRADE_COMPLETED = '[system-upgrade] upgrade-completed',
  RESET_UPGRADE_PROCESS = '[system-upgrade] reset-upgrade-process',
  FETCH_IS_MIGRATION_REQUIRED = '[system-upgrade] fetch-is-migration-required',
  SET_IS_MIGRATION_REQUIRED = '[system-upgrade] set-is-migration-required',
  FETCH_IS_MIGRATED = '[system-upgrade] fetch-is-migrated',
  SET_IS_MIGRATED = '[system-upgrade] set-is-migrated',
  FETCH_MIGRATABLE_MANAGED_COMPANIES = '[system-upgrade] fetch-migratable-managed-companies',
  SET_MIGRATABLE_MANAGED_COMPANIES = '[system-upgrade] set-migratable-managed-companies',
  SET_SSO_WARNING_CONFIRMATION = '[system-upgrade] set-sso-warning-confirmation',
  SET_OAUTH_KEYS = '[system-upgrade] set-oauth-keys'
}

export const systemUpgradeActions = {
  setGettingStarted: (model: SystemUpgradeModel) =>
    createAction(SystemUpgradeActionTypes.SET_SYSTEM_UPGRADE, model),
  getGettingStarted: () =>
    createAction(SystemUpgradeActionTypes.GET_SYSTEM_UPGRADE),
  completeStep: (step: keyof SystemUpgradeModel) =>
    createAction(SystemUpgradeActionTypes.COMPLETE_SYSTEM_UPGRADE_STEP, step),
  setStepsLoading: (loading: boolean) =>
    createAction(SystemUpgradeActionTypes.SET_STEPS_LOADING, loading),
  fetchInitialData: () =>
    createAction(SystemUpgradeActionTypes.FETCH_INITIAL_DATA),
  setSamlKeys: (samlKeys: SamlKey[]) =>
    createAction(SystemUpgradeActionTypes.SET_SAML_KEYS, { samlKeys }),
  setUsernameAttributes: (attributes: UsernameAttribute[]) =>
    createAction(SystemUpgradeActionTypes.SET_USERNAME_ATTRIBUTES, {
      attributes
    }),
  setApplicationList: (
    list: Application[],
    totalResults: number,
    resultsPerPage = initialState.applicationListResultsPerPage
  ) =>
    createAction(SystemUpgradeActionTypes.SET_APPLICATION_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  setRequirePasswordlessViaLastPassPolicy: (
    requirePasswordlessViaLastPassPolicy: TeamsPolicy
  ) =>
    createAction(
      SystemUpgradeActionTypes.SET_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY,
      {
        requirePasswordlessViaLastPassPolicy
      }
    ),
  setRoleList: (list: Role[]) =>
    createAction(SystemUpgradeActionTypes.SET_ROLE_LIST, { list }),
  setAccessPolicies: (accessPolicies: AccessPolicyMap) =>
    createAction(SystemUpgradeActionTypes.SET_ACCESS_POLICIES, {
      accessPolicies
    }),
  setIpPolicies: (ipPolicies: IpPolicyMap) =>
    createAction(SystemUpgradeActionTypes.SET_IP_POLICIES, {
      ipPolicies
    }),
  setGeofencePolicies: (geofencePolicies: GeofencePolicyMap) =>
    createAction(SystemUpgradeActionTypes.SET_GEOFENCE_POLICIES, {
      geofencePolicies
    }),
  setAuthenticationPolicies: (
    authenticationPolicies: AuthenticationPolicyMap
  ) =>
    createAction(SystemUpgradeActionTypes.SET_AUTHENTICATION_POLICIES, {
      authenticationPolicies
    }),
  startUpgrade: (appList: Application[]) =>
    createAction(SystemUpgradeActionTypes.START_UPGRADE, { appList }),
  upgradeCompleted: (succeed: boolean, errorMessage = '') =>
    createAction(SystemUpgradeActionTypes.UPGRADE_COMPLETED, {
      succeed,
      errorMessage
    }),
  fetchIsMigrationRequired: () =>
    createAction(SystemUpgradeActionTypes.FETCH_IS_MIGRATION_REQUIRED),
  setIsMigrationRequired: (isMigrationRequired: boolean) =>
    createAction(SystemUpgradeActionTypes.SET_IS_MIGRATION_REQUIRED, {
      isMigrationRequired
    }),
  fetchIsMigrated: () =>
    createAction(SystemUpgradeActionTypes.FETCH_IS_MIGRATED),
  setIsMigrated: (isMigrated: boolean) =>
    createAction(SystemUpgradeActionTypes.SET_IS_MIGRATED, {
      isMigrated
    }),
  fetchMigratableManagedCompanies: () =>
    createAction(SystemUpgradeActionTypes.FETCH_MIGRATABLE_MANAGED_COMPANIES),
  setMigratableManagedCompanies: (
    migratableManagedCompanies: ManagedMigrationCompanyInfo[]
  ) =>
    createAction(SystemUpgradeActionTypes.SET_MIGRATABLE_MANAGED_COMPANIES, {
      migratableManagedCompanies
    }),
  resetUpgradeProcess: () =>
    createAction(SystemUpgradeActionTypes.RESET_UPGRADE_PROCESS),
  setSsoWarningConfirmation: (ssoWarningConfirmation: boolean) =>
    createAction(SystemUpgradeActionTypes.SET_SSO_WARNING_CONFIRMATION, {
      ssoWarningConfirmation
    }),
  setOauthKeys: (clientOauth: ClientOauth) =>
    createAction(SystemUpgradeActionTypes.SET_OAUTH_KEYS, { clientOauth })
}

export type SystemUpgradeActions = ActionsUnion<typeof systemUpgradeActions>
export type SystemUpgradeSegmentActions = ActionsUnion<
  typeof systemUpgradeSegmentActions
>

import { SegmentPropertyType } from '@lastpass/tracking'

export interface SwitchToOldConsoleClickedSourceMapping {
  [key: string]: SegmentPropertyType<'Add MFA Policy Clicked', 'Source'>
}

export const getSwitchToOldConsoleClickedSource = (
  path: string,
  sourceMapping: SwitchToOldConsoleClickedSourceMapping
) => {
  const pathMatch = Object.keys(sourceMapping).find(key => {
    return new RegExp(`^${key}$`).test(path)
  })
  return pathMatch && sourceMapping[pathMatch]
}

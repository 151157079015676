import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (sharedFolderScoresResponse: SharedFolderScoresApiResponse) =>
  createResponse(SUCCESS, sharedFolderScoresResponse)

export const Responses = {
  success
}

export interface SharedFolderScoresApiType {
  score: string
  numaccts: string
  avgstrength: string
  numblanksites: string
  numduppasswords: string
  numdupsites: string
  numweak: string
}

export type SharedFolderScoresApiResponse = SharedFolderScoresApiType

export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(shareid: string) {
    try {
      const [response, status] = await teamsapi(
        'my-company/sharedfoldersecurityscore/' + shareid,
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const result: SharedFolderScoresApiResponse = JSON.parse(response)
        return success(result)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

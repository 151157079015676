import React from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { FieldArray } from 'formik'

import { TextButton } from '@lastpass/lastkit/components/TextButton'
import { BodyBoldStyle } from '@lastpass/lastkit/styles/body-text'

import {
  CustomAttributeConstantValue,
  CustomAttributesControl
} from './CustomAttributeControl'

const StyledTextButton = styled(TextButton)`
  ${BodyBoldStyle};
  color: ${props => props.theme.colors.blue700};
  padding-left: 0px;
  padding-top: 16px;
`

export const CustomAttributes = ({
  formikPropsValues,
  customAttributesOption
}) => {
  const additionalAttributes = [
    { value: 'First Name', label: i18n._(msg`First Name`) },
    { value: 'Last Name', label: i18n._(msg`Last Name`) },
    { value: CustomAttributeConstantValue, label: i18n._(msg`Constant Value`) }
  ]

  customAttributesOption = customAttributesOption
    ? [...customAttributesOption, ...additionalAttributes]
    : [...additionalAttributes]

  return (
    <FieldArray name="samlAttributes">
      {arrayHelpers => (
        <>
          {formikPropsValues.samlAttributes &&
            formikPropsValues.samlAttributes.length > 0 &&
            formikPropsValues.samlAttributes.map((samlAttribute, index) => {
              const i = index + 1
              return (
                <CustomAttributesControl
                  label={i18n._(msg`Custom attribute ${i}`)}
                  customAttributes={customAttributesOption}
                  value={samlAttribute}
                  key={index + Math.random()}
                  onDeleteCustomAttribute={() => arrayHelpers.remove(index)}
                  onUpdateValue={v => arrayHelpers.replace(index, v)}
                />
              )
            })}
          <div>
            <StyledTextButton
              type="button"
              id={'AddSamlAttribute'}
              onClick={() => arrayHelpers.push({})}
              data-qa={'AddSamlAttribute'}
              key={'AddSamlAttribute'}
            >
              <Trans>Add SAML attribute</Trans>
            </StyledTextButton>
          </div>
        </>
      )}
    </FieldArray>
  )
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupListActions } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { WithError } from '@lastpass/admin-console-dependencies/types/with-error'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createDeleteGroupSaga(
  groupRemoveUsersService: UACServices.Services
) {
  return function* deleteGroupSaga(
    action: ReturnType<typeof groupOperationActions.deleteGroups>
  ) {
    try {
      const groups = action.payload
      const currentUrlFilters = yield select(getCurrentUrlFilters)
      const deleteGroupTexts =
        groups.length === 1
          ? {
              confirm: msg`Delete this group? ${groups[0].name}`,
              success: msg`Group has been deleted: ${groups[0].name}`,
              fail: msg`Group not deleted: ${groups[0].name}`
            }
          : {
              confirm: msg`Delete the selected groups?`,
              success: msg`The selected groups have been deleted.`,
              fail: msg`None of the selected groups have been deleted.`
            }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Delete groups`,
        text: deleteGroupTexts.confirm,
        discardText: msg`Cancel`,
        confirmText: msg`Delete`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const result: UACServices.DeleteGroupAPI.Responses = yield call(
          groupRemoveUsersService.deleteGroup,
          groups
        )
        if (result.type === UACServices.DeleteGroupAPI.SUCCESS) {
          yield put(push('/users/groups/'))
          yield put(
            globalActions.setNotification({
              message: deleteGroupTexts.success,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.DeleteGroupAPI.FAIL) {
          yield put(
            globalActions.setNotification({
              message: deleteGroupTexts.fail,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.DeleteGroupAPI.PARTIAL_SUCCESS) {
          const errorMessages = result.body.failedGroups
          const groupsWithErrors: WithError<Group>[] = groups
            .map(group => {
              return { ...group, error: errorMessages[group.id] }
            })
            .filter(group => !!group.error)

          yield put(
            globalActions.setNotification({
              message: msg`Some groups haven't been deleted.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'group-delete',
                groups: groupsWithErrors
              }
            })
          )
        }
      }
      yield put(
        groupListActions.getGroupsList({
          query: getQueryParams(currentUrlFilters.location, 'groups'),
          path: {}
        })
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'

export function createGetAdvancedUserActivityEventTypes(
  userActivityServices: UACServices.Services
) {
  return function* getAdvancedUserActivityEventTypes() {
    try {
      const advancedUserActivityEventTypesResult: UACServices.AdvancedUserActivityEventTypesAPI.Responses = yield call(
        userActivityServices.advancedUserActivityEventTypes
      )

      yield put(
        advancedUserActivityActions.setUserActivityEventTypes(
          advancedUserActivityEventTypesResult.body.eventTypes
        )
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

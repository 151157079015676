import React from 'react'

import { msg, Trans } from '@lingui/macro'

import { RowContainer, TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import { BlockHeader } from '@lastpass/admin-console-dependencies/ui/home/BlockHeader'
import { SecurityLeaderboard } from '@lastpass/admin-console-dependencies/ui/home/security-dashboard/user-security-leaderboard-block/SecurityLeaderboard'
import { createUserListColumnsQueryString } from '@lastpass/admin-console-dependencies/ui/users/view/UserList'

const usersListLink = `/users/view?${createUserListColumnsQueryString({
  orderBy: 'securityChallengeScore desc',
  userListColumns: [
    UserListTableColumn.name,
    UserListTableColumn.email,
    UserListTableColumn.securityScore,
    UserListTableColumn.lastLogin
  ]
})}`

export const UserSecurityLeaderboardBlock: React.FunctionComponent = () => {
  return (
    <>
      <BlockHeader
        title={msg`Leaderboard`}
        helperTooltip={msg`
              Who are your top users by security score?
            `}
      >
        <LocationLink to={usersListLink}>
          <TextButton data-qa="UserSecurityLeaderboardViewAllUsersButton" blue>
            <Trans>View all users</Trans>
          </TextButton>
        </LocationLink>
      </BlockHeader>
      <RowContainer data-qa="UserSecurityLeaderboardBlock">
        <SecurityLeaderboard />
      </RowContainer>
    </>
  )
}

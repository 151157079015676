import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPoliciesListState } from '@lastpass/admin-console-dependencies/state/policies/general/list/state'
import { generalPolicySettingsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/actions'
import { GeneralPolicySettingsForm } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/state'
import {
  TeamsPolicy,
  TeamsPolicyMap,
  TeamsPolicyModelData,
  TeamsPolicyValueType
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPolicyInfo } from '../drawer/get-general-policy'

export const getCurrentUrlFilters = state => state.router

const preparePolicyData = (
  policy: TeamsPolicy,
  policyFormData: GeneralPolicySettingsForm
): TeamsPolicyModelData[] => {
  return policy.policyData.map((policyConfigInfo, index) => {
    if (index === policyFormData.policyConfigIndex) {
      let policyFormValue: string | null = ''
      if (policy.policyValueType === TeamsPolicyValueType.checkbox) {
        policyFormValue = policyFormData.policyConfigFormData.policyEnabled
          ? '1'
          : '0'
      } else {
        policyFormValue = policyFormData.policyConfigFormData.policyValue
          ? policyFormData.policyConfigFormData.policyValue
          : null
      }
      policyConfigInfo = {
        ...policyConfigInfo,
        policyValue: policyFormValue,
        policyNotes: policyFormData.policyConfigFormData.policyNotes
      }
    }
    return policyConfigInfo
  })
}

export const getGeneralPoliciesList = (state: AppState) =>
  state.generalPoliciesList

export function createSaveGeneralPolicySettingsSaga() {
  return function* saveGeneralPolicySettingsSaga(
    action: ReturnType<
      typeof generalPolicySettingsActions.saveGeneralPolicySettings
    >
  ) {
    const policyFormData = action.payload.policyFormData
    const policyKey = policyFormData.policyKey
    if (policyKey) {
      try {
        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const generalPoliciesList: GeneralPoliciesListState = yield select(
          getGeneralPoliciesList
        )
        const policy = policyMap[policyKey]
        const policyData = preparePolicyData(policy, policyFormData)
        policyMap[policyKey].policyData = policyData
        policyMap[policyKey].isChanged = true

        yield put(
          generalPoliciesListActions.setGeneralPoliciesList(
            generalPoliciesList.table.results,
            policyMap,
            generalPoliciesList.table.totalResults,
            generalPoliciesList.table.resultsPerPage
          )
        )
        yield put(
          generalPoliciesDrawerActions.updateCurrentPolicyData(
            policyData[policyFormData.policyConfigIndex],
            policyFormData.policyConfigIndex
          )
        )

        yield put(
          generalPolicySettingsActions.setGeneralPolicySettingsFormSubmitSuccess(
            true
          )
        )
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      }
    }
  }
}

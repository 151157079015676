import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { RSASecureIDRadius } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(rsaSecureIDRadius: RSASecureIDRadius) {
    const { radiusSharedSecret, logo124x124, logo190x41 } = rsaSecureIDRadius
    const isRadiusSecretProtected = !!(
      radiusSharedSecret && radiusSharedSecret.indexOf('*') === 0
    )
    const isLogo124x124Empty = logo124x124 === ''
    const isLogo190x41Empty = logo190x41 === ''

    if (isRadiusSecretProtected) {
      delete rsaSecureIDRadius.radiusSharedSecret
    }
    if (isLogo124x124Empty) {
      delete rsaSecureIDRadius.logo124x124
    }
    if (isLogo190x41Empty) {
      delete rsaSecureIDRadius.logo190x41
    }

    try {
      const [response, status] = await teamsapi(
        'my-company/saversasecureidradius',
        'POST',
        {
          ...rsaSecureIDRadius
        }
      )
      if (status === StatusCodes.OK && response) {
        const saveSuccess = JSON.parse(response)
        if (saveSuccess.success) {
          return success()
        }
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

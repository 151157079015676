import React, { FC } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as Arrow } from '@lastpass/assets/svg/admin-console/icon-arrow-left.svg'

import { CaptionSemiboldStyle } from '../styles'

export interface NextPaginationProps {
  next: () => void
  previous: () => void
  shouldShowPreviousButton?: boolean
  shouldShowNextButton?: boolean
}

const IconWrapper = styled.div`
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`

const PaginationContainer = styled.div`
  ${CaptionSemiboldStyle}
  user-select: none;
  line-height: 40px;
`

export const NextPagination: FC<NextPaginationProps> = ({
  next,
  previous,
  shouldShowNextButton,
  shouldShowPreviousButton
}) => {
  return (
    <PaginationContainer>
      {shouldShowPreviousButton && (
        <IconWrapper
          data-qa="NextPaginationPrevButton"
          onClick={() => {
            previous()
          }}
          css={css`
            margin-right: 8px;
          `}
        >
          <Arrow
            css={css`
              height: 12px;
              margin: 0 5px -2px 5px;
            `}
          />

          <Trans>Prev</Trans>
        </IconWrapper>
      )}
      {shouldShowNextButton && (
        <IconWrapper
          data-qa="NextPaginationNextButton"
          onClick={() => {
            next()
          }}
          css={css`
            margin-left: 8px;
          `}
        >
          <Trans>Next</Trans>
          <Arrow
            css={css`
              height: 12px;
              margin: 0 5px -2px 5px;
              transform: rotate(180deg);
            `}
          />
        </IconWrapper>
      )}
    </PaginationContainer>
  )
}

import { Table } from '@lastpass/admin-console-dependencies/types/table'

export interface PsaIntegrationsState {
  activePsa: PsaProviderDetails | null
  metadata: PsaCredentialsState[]
  psaInProgress: boolean
  drawerInProgress: boolean
  drawerContainsUnsavedChanges: boolean
  psaEditCredentialsFormValues: PsaEditCredentialsFormValues | null
  agreements: PsaAgreementsState
  products: Table<PsaProduct>
}

export interface PsaProviderDetails {
  provider: string
  credentials: PsaProviderDetailsProperties[]
  countOfImportedCompanies?: number
  modules: PsaProviderDetailsModules | null
}

export interface PsaProviderDetailsModules {
  isContractSelectionAvailable: boolean
}

export interface PsaProviderDetailsProperties {
  name: string
  value: string
}

export interface PsaCredentialsState {
  provider: string
  credentials: PsaCredentialsProperties[]
}

export interface PsaCredentialsProperties {
  name: string
  type: string
  isMandatory: boolean
}

export interface PsaEditCredentialsFormValues {
  [key: string]: FormValues | null
}

export interface FormValues {
  [key: string]: string
}

export interface PsaAgreementsState {
  assignmentOptions: PsaCompanyContractAssignmentOption[]
  isLoading: boolean
}

export enum AssignmentStatus {
  NOT_ASSIGNED = 'notAssigned',
  EXCLUDED = 'excluded',
  ASSIGNED = 'assigned'
}

export enum AvailabilityStatus {
  NOT_AVAILABLE = 'notAvailable',
  AVAILABLE = 'available'
}

export interface PsaCompanyContractAssignmentOption {
  company: PsaCompany
  contractAssignment: {
    contracts: PsaCompanyContract[]
    assignedContractId: string | null
    assignmentStatus: AssignmentStatus
    suggestedContractId: string | null
  }
}
export interface PsaCompany {
  id: number
  name: string
}

export interface PsaCompanyContract {
  id: string
  name: string | null
  availability: AvailabilityStatus
}

export interface PsaCompanyContractAssignment {
  companyId: number
  contractId: string | null
  contractName: string | null
  assignmentStatus: AssignmentStatus
}

export interface PsaProduct {
  id: string
  name: string
  category: string
  isSelectedForBilling: boolean
}

import { call, put } from 'redux-saga/effects'

import {
  bulkRotateKeys,
  federationAPIFactory,
  OpenIdProvider
} from '@lastpass/federation'
import { FederationApiError } from '@lastpass/federation/lib/api/federation-api-error'
import { FederationErrors } from '@lastpass/federation/lib/federation-error-codes'
import {
  BulkUpdateUsersK1Response,
  IdpUserData
} from '@lastpass/federation/types/federation-api'
import { BulkRotatedKeysResults } from '@lastpass/federation/types/key-rotation'

import { keyRotationDebug } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation'
import { keyRotationCheckInterruptedSession } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-check-interrupted-session'
import { keyRotationFixUnSynchronizedUsers } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-fix-unsynchronized-users'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { KeyRotationDataResponse } from '@lastpass/admin-console-dependencies/server/users/federated-login/get-key-rotation-data'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

import { KeyRotationError } from './key-rotation-error'
import { KeyRotationErrors } from './key-rotation-error-codes'

export const keyRotationLocalStorageKeyName = 'federated-key-rotation'

export function* keyRotationCreateNewKeysUserWideIdpRollback(
  federationAPI,
  idpUsers,
  rollbackIdpData,
  sessionToken
) {
  keyRotationDebug(
    '- %cALP: K2 sync error, starting IDP rollback',
    'background: #fdd'
  )
  const rollbackUserIds = rollbackIdpData.map(idpData => idpData.userId)
  const rollbackData = idpUsers.filter(user =>
    rollbackUserIds.includes(user.userId)
  )

  let rollbackResponse: BulkUpdateUsersK1Response[]
  try {
    rollbackResponse = yield call(federationAPI.batchUpdateUsersK1, {
      usersData: rollbackData
    })
  } catch (error) {
    if (error instanceof FederationApiError) {
      switch (error.federationErrorCode) {
        case FederationErrors.IdpBatchUpdateUsersBatchLimitExceeded:
          throw new KeyRotationError({
            feErrorCode:
              KeyRotationErrors.AlpSyncAndIdpRevertBatchLimitExceeded,
            sessionToken
          })
        case FederationErrors.IdpBatchUpdateUsersConnectionFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.AlpSyncAndIdpRevertConnectionFailed,
            sessionToken
          })
        case FederationErrors.IdpBatchUpdateUsersFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.AlpSyncAndIdpRevertFailed,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
        case FederationErrors.IdpBatchUpdateUsersAccessDenied:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.AlpSyncAndIdpRevertAccessDenied,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
      }
    }
    throw new Error(
      'unknown error - keyRotationCreateNewKeysUserWideIdpRollback'
    )
  }

  const success = rollbackResponse.every(response => response.success)

  if (success) {
    keyRotationDebug('- IDP: K1 rollback is done')
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.AlpSyncFailedIdpDataSuccessfullyReverted,
      sessionToken
    })
  } else {
    keyRotationDebug(
      '- %cIDP: K1 rollback partially failed',
      'background: #fdd'
    )
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.AlpSyncFailedIdpDataPartiallyReverted,
      sessionToken
    })
  }
}

export function* keyRotationCreateNewKeysUserWideIdpCleanUp(
  federationAPI,
  cleanupIdpData,
  sessionToken
) {
  let cleanUpBatchResponse: BulkUpdateUsersK1Response[]
  try {
    cleanUpBatchResponse = yield call(federationAPI.batchUpdateUsersK1, {
      usersData: cleanupIdpData
    })
  } catch (error) {
    if (error instanceof FederationApiError) {
      switch (error.federationErrorCode) {
        case FederationErrors.IdpBatchUpdateUsersBatchLimitExceeded:
          throw new KeyRotationError({
            feErrorCode:
              KeyRotationErrors.IdpBatchUpdateUsersBatchLimitExceededCleanup,
            sessionToken
          })
        case FederationErrors.IdpBatchUpdateUsersConnectionFailed:
          throw new KeyRotationError({
            feErrorCode:
              KeyRotationErrors.IdpBatchUpdateUsersConnectionFailedCleanup,
            sessionToken
          })
        case FederationErrors.IdpBatchUpdateUsersFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpBatchUpdateUsersFailedCleanup,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
        case FederationErrors.IdpBatchUpdateUsersAccessDenied:
          throw new KeyRotationError({
            feErrorCode:
              KeyRotationErrors.IdpBatchUpdateUsersAccessDeniedCleanup,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
      }
    }
    throw new Error(
      'unknown error - keyRotationCreateNewKeysUserWideIdpCleanUp'
    )
  }

  const success = cleanUpBatchResponse.every(response => response.success)
  if (!success) {
    keyRotationDebug('- IDP: ALP sync data clean-up is partially successful')
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.IdpCleanupPartiallySuccessful,
      sessionToken
    })
  }
  keyRotationDebug('- IDP: ALP sync data clean-up is done')
}

export function* keyRotationCreateNewKeysUserWideSyncData(
  federationAPI,
  userService,
  idpUsers,
  rotatedKeyResults,
  sessionToken
) {
  // when additionalIdpProps is provided, batchUpdateUsersK1 sends sessionToken and ALP external ID to the IDP
  // it will be cleaned up when the data is synced with ALP
  const additionalIdpProps = {}
  rotatedKeyResults.rotatedKeys.idpData.forEach(idpData => {
    additionalIdpProps[idpData.userId] = {
      syncId: idpData.alpId,
      syncSession: sessionToken
    }
  })
  let batchResponse: BulkUpdateUsersK1Response[]
  try {
    batchResponse = yield call(federationAPI.batchUpdateUsersK1, {
      usersData: rotatedKeyResults.rotatedKeys.idpData,
      additionalIdpProps
    })
  } catch (error) {
    if (error instanceof FederationApiError) {
      switch (error.federationErrorCode) {
        case FederationErrors.IdpBatchUpdateUsersBatchLimitExceeded:
          throw new KeyRotationError({
            feErrorCode:
              KeyRotationErrors.IdpBatchUpdateUsersBatchLimitExceeded,
            sessionToken
          })
        case FederationErrors.IdpBatchUpdateUsersConnectionFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpBatchUpdateUsersConnectionFailed,
            sessionToken
          })
        case FederationErrors.IdpBatchUpdateUsersFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpBatchUpdateUsersFailed,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
        case FederationErrors.IdpBatchUpdateUsersAccessDenied:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpBatchUpdateUsersAccessDenied,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
      }
    }
    throw new Error('unknown error - keyRotationCreateNewKeysUserWideSyncData')
  }

  keyRotationDebug('IDP: K1 sync is done')

  const successfullyUpdatedUserIds = batchResponse
    .filter(response => response.success)
    .map(response => response.userId)

  const successfullyUpdatedIdpData = rotatedKeyResults.rotatedKeys.idpData.filter(
    idpData => successfullyUpdatedUserIds.includes(idpData.userId)
  )

  const alpIdsToSync = successfullyUpdatedIdpData.map(idpData => idpData.alpId)

  // sync ALP data. Revert IDP changes if ALP sync fails
  if (alpIdsToSync.length) {
    try {
      yield call(
        userService.syncKeyRotationData,
        sessionToken,
        KeyRotationErrors.AlpSyncKeyRotationDataRequestFailed,
        alpIdsToSync
      )
      keyRotationDebug(
        `- ALP: K2 sync is done for ${alpIdsToSync.length} users`
      )
    } catch (e) {
      yield call(
        keyRotationCreateNewKeysUserWideIdpRollback,
        federationAPI,
        idpUsers,
        successfullyUpdatedIdpData,
        sessionToken
      )
    }
  } else {
    keyRotationDebug('- ALP: K2 sync is skipped - IDP update was unsuccessful')
  }

  // clean-up temporary ALP data in IDP
  if (successfullyUpdatedIdpData.length) {
    yield call(
      keyRotationCreateNewKeysUserWideIdpCleanUp,
      federationAPI,
      successfullyUpdatedIdpData,
      sessionToken
    )
  }
}

export function* keyRotationCreateNewKeysUserWide(
  userService: UACServices.Services,
  provider: OpenIdProvider
) {
  yield put(federatedLoginActions.setDefaultKeyRotationEstimationValues())
  const sessionConfig = yield call(
    keyRotationCheckInterruptedSession,
    userService
  )
  if (!sessionConfig) {
    return false
  }

  let pageNumber = sessionConfig.pageNumber
  const sessionToken = sessionConfig.sessionToken
  const federationAPI = federationAPIFactory(provider)
  let idpUsers: IdpUserData[]
  try {
    idpUsers = yield call(federationAPI.getUsers)
  } catch (error) {
    if (error instanceof FederationApiError) {
      switch (error.federationErrorCode) {
        case FederationErrors.IdpGetUsersRequestConnectionFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpGetUsersRequestConnectionFailed,
            sessionToken
          })
        case FederationErrors.IdpGetUsersRequestFailed:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpGetUsersRequestFailed,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
        case FederationErrors.IdpGetUsersRequestAccessDenied:
          throw new KeyRotationError({
            feErrorCode: KeyRotationErrors.IdpGetUsersRequestAccessDenied,
            sessionToken,
            httpErrorCode: error.httpErrorCode
          })
      }
    }
    throw Error('unknown error - keyRotationCreateNewKeysUserWide')
  }
  const pageLimit = federationAPI.config.batchLimit || 20
  let maxPageNumber = 0

  keyRotationDebug(`IDP users fetched`, idpUsers)

  yield call(
    keyRotationFixUnSynchronizedUsers,
    userService,
    idpUsers,
    federationAPI
  )

  const startTime = Date.now()
  let rotatedPageNumber = 0

  do {
    keyRotationDebug(`User-wide rotation page ${pageNumber}`)

    localStorage.setItem(
      keyRotationLocalStorageKeyName,
      JSON.stringify({ sessionToken, pageNumber })
    )

    const offset = pageNumber * pageLimit

    const keyRotationDataResponse: {
      body: KeyRotationDataResponse
    } = yield call(
      userService.getKeyRotationData,
      sessionToken,
      offset,
      pageLimit
    )

    const keyRotationData = keyRotationDataResponse.body

    if (!maxPageNumber) {
      maxPageNumber = Math.ceil(keyRotationData.totalCount / pageLimit)
      keyRotationDebug(`Last page number is ${maxPageNumber}`)
    }

    keyRotationDebug(`- ALP data downloaded`, keyRotationData.data)

    const rotatedKeyResults: BulkRotatedKeysResults = yield call(
      bulkRotateKeys,
      {
        alpData: keyRotationData.data,
        userWideIdpData: idpUsers
      }
    )

    if (
      rotatedKeyResults.rotatedKeys.alpData.length > 0 &&
      rotatedKeyResults.rotatedKeys.idpData.length ===
        rotatedKeyResults.rotatedKeys.alpData.length
    ) {
      keyRotationDebug('- Key-rotation is done', rotatedKeyResults.rotatedKeys)

      // writes the K2 data to the ALP temporary table

      yield call(
        userService.saveKeyRotationData,
        sessionToken,
        rotatedKeyResults.rotatedKeys.alpData
      )

      keyRotationDebug('IDP: K1 save to temp table is done')

      /*
      The saga below is the CRITICAL part of the rotation
      IDP save and ALP sync calls SHOULD BE in sync
      No matter what happens to the client machine, no data should be lost under any circumstances

      How it works
      The 'saveKeyRotationData' call above saves the K2 into a temporary table in ALP.
      This table has a unique row identifier for each user, called ALP-id

      keyRotationCreateNewKeysUserWideSyncData:
      1. Saves the K1 and the ALP-id into the IDP
      2. Calls ALP and tries to copy the temporary K2 to the user's production table
      - if this call fails, it tries to roll back the IDP to the previous state
      - if the sync was successful, then it cleans up the ALP data in IDP
      3. If the sync fails, then it tries to sync the un-synchronized users with the saved ALP-ids
      at the start of the next rotation. keyRotationFixUnSynchronizedUsers saga does the magic.
      */
      yield call(
        keyRotationCreateNewKeysUserWideSyncData,
        federationAPI,
        userService,
        idpUsers,
        rotatedKeyResults,
        sessionToken
      )
      const elapsedTime = (Date.now() - startTime) / 1000
      const remainingPageNr = maxPageNumber - pageNumber
      const keyRotationRemainingTime = Math.round(
        (elapsedTime / ++rotatedPageNumber) * remainingPageNr
      )
      yield put(
        federatedLoginActions.setKeyRotationRemainingTime(
          keyRotationRemainingTime
        )
      )
    } else {
      keyRotationDebug(
        '- %cWARNING: Users can not be matched for key-rotation',
        'background: #fdd'
      )
    }

    pageNumber++

    const keyRotationProgressPercentage = Math.floor(
      (pageNumber / maxPageNumber) * 100
    )
    yield put(
      federatedLoginActions.setKeyRotationPercentage(
        keyRotationProgressPercentage
      )
    )
  } while (pageNumber < maxPageNumber)

  localStorage.removeItem(keyRotationLocalStorageKeyName)

  keyRotationDebug('Finished successfully')
  return true
}

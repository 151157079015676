import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GoogleUser } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = (googleUsers: GoogleUser[], nextPageToken: string | null) =>
  createResponse(SUCCESS, { googleUsers, nextPageToken })
const failure = () => createResponse(FAILURE)

export const Responses = {
  success,
  failure
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    nextPageToken: string | null,
    searchQuery: string
  ) {
    try {
      const searchQueryString = searchQuery ? `searchQuery=${searchQuery}` : ''
      const nextPageTokenString = nextPageToken
        ? `${searchQuery ? '&' : ''}nextPageToken=${nextPageToken}`
        : ''

      const [response, status] = await fetch(
        `google-workspace-provisioning/google-users?${searchQueryString}${nextPageTokenString}`,
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const content = JSON.parse(response).content
        const googleUsers = content.items || []

        return success(
          googleUsers.map(user => ({
            id: user.id,
            name: user.name,
            email: user.email,
            selected: false
          })),
          content.nextPageToken
        )
      } else {
        return failure()
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

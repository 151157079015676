import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'company/popup/payment-notification',
        'POST'
      )
      if (status === StatusCodes.OK && response) {
        return success()
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import { PolicyCode } from '../types/policies-enum'

export interface PolicyNamesType {
  title: MessageDescriptor
  description?: MessageDescriptor
  shortDescription?: MessageDescriptor
}

export const policyTexts: { [key in PolicyCode]?: PolicyNamesType } = {
  iprestrict: {
    shortDescription: msg`Only allow users to access their LastPass accounts when logging in from designated IP addresses.`,
    title: msg`Restrict access by IP address`,
    description: msg`Use IP address restriction to allow access only from approved IP addresses or ranges. Value: Enter each allowed IP address or partial IP address, separated by white space. For example: 71.126.154. 128.8. 120.0.0.1 would allow login from any address in 71.126.154.*, 128.8.*.* and 120.0.0.1. A matching DNS restriction or country restriction also allows entry. We also support CIDR Notation. For example: 61.12.56.0/24 allows any address in 61.12.56.* to log in.`
  },
  iprestrictnomobile: {
    shortDescription: msg`Only allow users to access their LastPass accounts when logging in from designated IP addresses. `,
    title: msg`Restrict access by IP address (mobile excluded)`,
    description: msg`Use IP address restriction (mobile excluded) to allow users of a mobile app to access their accounts from any IP address, but otherwise allow access only from approved IP addresses or ranges. Value: Enter each allowed IP address or partial IP address, separated by white space. For example: 71.126.154. 128.8. 120.0.0.1 would allow login from any address in 71.126.154.*, 128.8.*.* and 120.0.0.1.`
  },
  dnsrestrict: {
    shortDescription: msg`Only allow users access to their LastPass accounts when logging in from designated domain names (based on a reverse DNS lookup of their IP address).`,
    title: msg`Restrict access by reverse DNS`,
    description: msg`Create a list of domains from which users are permitted to log in to LastPass. Value: Enter permitted domain names, separated by white space. Example: comcast.com comcast.net verizon.net This allows users to log in when the reverse DNS ends with comcast.com, comcast.net or verizon.net. Any matching IP address allows entry. A matching IP restriction or country restriction also allows entry.`
  },
  countryrestrict: {
    shortDescription: msg`Restrict the countries from which users are permitted to log in to LastPass. `,
    title: msg`Restrict access by country`,
    description: msg`Only allow login to LastPass from specified countries. Value: Enter the two-character domain abbreviation for each permitted country, separated by white space. Example: US CA This allows users to log in when the country code for their IP is in the United States or Canada. Any matching country code allows entry. A matching IP restriction or DNS restriction also allows entry. For a list of country codes, see https://lastpass.com/listcountrycodes.php`
  },
  torrestrict: {
    shortDescription: msg`Prevent access to LastPass accounts from The Onion Router (TOR) exit node IP addresses.`,
    title: msg`Block TOR access`,
    description: msg`Restrict logins to LastPass accounts from The Onion Router (TOR) exit node IPs.`
  },
  norecovery: {
    shortDescription: msg`Prevent users from resetting their master password with the default Account Recovery process.`,
    title: msg`Prohibit account recovery`,
    description: msg`Prohibit users from using the account recovery process to access LastPass without their master password. Caution: When this policy is enabled, account recovery is impossible unless the 'Permit super admins to reset master passwords' policy is enabled.`
  },
  disableoffline: {
    shortDescription: msg`Prevent LastPass from saving a secure local copy of the user’s vault on their device.`,
    title: msg`Prohibit offline access`,
    description: msg`Prohibit users from logging in without a network connection. We do not recommend this options since user data will no longer be stored locally on the device, thus preventing access to LastPass services when connectivity is lost`
  },
  allowmasterpasswordsave: {
    shortDescription: msg`Control whether employees can select 'Remember password' for logging in to the LastPass browser extension.`,
    title: msg`Remember master password`,
    description: msg`Permit users to allow their LastPass browser extension to remember their master password. When enabled, users have the option to 'Remember master password' upon login to LastPass`
  },
  prohibitmobile: {
    shortDescription: msg`Prohibit users from accessing their LastPass account via the LastPass Password Manager app for iOS or Android.`,
    title: msg`Prohibit account access on mobile devices`,
    description: msg`Prohibit users from accessing their LastPass account via the LastPass Password Manager app for iOS or Android. Upon attempted access, users are informed that the action is prohibited by policy.`
  },
  prohibitunrestrictedmobile: {
    shortDescription: msg`Restrict mobile access to specific devices.`,
    title: msg`Prohibit login from 'Denied' devices`,
    description: msg`Only allow login to LastPass accounts from 'Allowed' mobile devices. Learn how to allow or deny access here: https://support.logmeininc.com/lastpass/help/manage-account-settings-lp010017#Mobile`
  },
  prohibitunrestrictedmobileadmin: {
    shortDescription: msg`Restrict access on mobile devices to those that are first approved by an Enterprise account admin.`,
    title: msg`Prohibit login from 'Denied' devices unless approved`,
    description: msg`Only allow login to LastPass accounts from 'Allowed' mobile devices or those devices approved on demand by an admin in the Admin Console.`
  },
  disallowjailbrokenmobile: {
    shortDescription: msg`Prevent users from logging in to LastPass on jailbroken iOS and Android phones.`,
    title: msg`Prohibit login from jailbroken phones`,
    description: msg`Prohibit users from logging in to LastPass on jailbroken iOS and Android devices.`
  },
  maxlockoutattemptsoverride: {
    shortDescription: msg`Lock users out of their account if they make too many failed login attempts.`,
    title: msg`Restrict login attempts before lockout`,
    description: msg`Allow this many failed login attempts before locking a user's account and preventing further attempts for the time period set in the 'Lockout period' policy. Values: Number of allowed attempts, between 3 and 8. For example, a value of 3 results in lockout on the fourth failed attempt.`
  },
  lockoutintervaloverride: {
    shortDescription: msg`Set the time a user must wait before they can attempt login again after too many failed login attempts.`,
    title: msg`Lockout period`,
    description: msg`Upon exceeding the number of allowed failed login attempts, a user's account remains locked for this many minutes before they can attempt login. Value: 10-60 (minutes).`
  },
  disableemailverificationoverride: {
    shortDescription: msg`Don’t force users to prove their identity via email verification link when logging in from a new device.`,
    title: msg`Disable email verification for new devices`,
    description: msg`When a user logs in to LastPass from a new device, do not force them to prove their identity via email verification link. Caution: We do not recommend enabling this policy. It significantly reduces the security of users' LastPass accounts.`
  },
  overridesessionlifetime: {
    shortDescription: msg`Expire all user sessions after this many hours, regardless of activity. Users will be warned two minutes before their session expires.`,
    title: msg`Override session lifetime`,
    description: msg`Expire all user sessions after this many hours, regardless of activity. Users will be warned two minutes before their session expires. Value: 1-9999 (hours)`
  },
  logoffclosebrowser: {
    shortDescription: msg`Automatically log the user out of the LastPass browser extension when they close their browser.`,
    title: msg`Account logoff on browser close`,
    description: msg`Automatically log users out of their LastPass account when they close their browser. This forces users to log in each time they re-open their browser. This also prevents users from setting this policy themselves in their browser extensions.`
  },
  logoffidle: {
    shortDescription: msg`Automatically log users out of the LastPass browser extension when their browser goes idle.`,
    title: msg`Account logoff on browser idle (extension)`,
    description: msg`Automatically log users out of their LastPass account after their browser remains idle for this many minutes. This also prevents users from setting this policy themselves in their browser extensions. Value: 0-9999 (minutes). IMPORTANT: Your identity provider settings may override this policy for your federated users.`
  },
  logofflock: {
    shortDescription: msg`Automatically log users out of the LastPass browser extension after the computer is locked.`,
    title: msg`Account logoff on computer lock`,
    description: msg`This policy allows you to override this setting to log off the user on computer lock. **LastPass for Windows must be running in order for this policy to work. IMPORTANT: Your identity provider settings may override this policy for your federated users.`
  },
  logoffscreen: {
    shortDescription: msg`Automatically log users out of the LastPass browser extension when the computer switches to screensaver.`,
    title: msg`Account logoff on screensaver`,
    description: msg`Enable this policy to override user settings and always log users off when screensaver is activated. **LastPass for Windows must be running in order for this policy to work. IMPORTANT: Your identity provider settings may override this policy for your federated users.`
  },
  logoffshutdown: {
    shortDescription: msg`Automatically log users out of the LastPass browser extension when they log off or shut down the computer.`,
    title: msg`Account logoff on shutdown/logoff`,
    description: msg`This policy allows you to override this setting to log off the user on computer lock. **LastPass for Windows must be running in order for this policy to work. IMPORTANT: Your identity provider settings may override this policy for your federated users.`
  },
  killother: {
    shortDescription: msg`Log users out of all other active LastPass sessions when they log in from another device or location.`,
    title: msg`End existing sessions on login`,
    description: msg`After login from a new location, based on IP address, you'll be logged out of existing sessions in most cases (your browser session must be open and polling enabled). If you close your browser session without logging out of LastPass, this feature still protects you. For example, your browser is closed at work, and you log in from home. You return to work and are forced to log in again.`
  },
  websiteautologoffoverride: {
    shortDescription: msg`Force users to automatically log off of www.lastpass.com when their browser has gone idle.`,
    title: msg`Account logoff (website)`,
    description: msg`Automatically log users out of LastPass.com after the selected period of time. This also prevents users from setting this policy themselves (Account Settings > Website Auto-Logoff). Value: 5-20160 (minutes). IMPORTANT: Your identity provider settings may override this policy for your federated users.`
  },
  pwlen: {
    shortDescription: msg`Require a minimum number of characters in each master password.`,
    title: msg`Length of master password`,
    description: msg`Force users to create a master password that includes at least this many characters. Any user attempting to save a master password that uses too few characters is prompted to meet the requirement. Value: The required number of characters. Values must be greater than or equal to 8. To have a different limit for multifactor, specify a second number, separated by a comma (for example, 12,9).`
  },
  pwdigits: {
    shortDescription: msg`Force users to create a master password that includes at least this many numeric digits.`,
    title: msg`Minimum digits in master password`,
    description: msg`Force users to create a master password that includes at least this many numeric digits. Any user attempting to save a master password that uses too few digits is prompted to meet the requirement. Value: The required number of digits.`
  },
  pwlower: {
    shortDescription: msg`Require a minimum number of lowercase letters in each master password.`,
    title: msg`Minimum lowercase in master password`,
    description: msg`Force users to create a master password that includes at least this many lowercase letters. Any user attempting to save a master password that uses too few lowercase letters is prompted to meet the requirement. Value: The required number of lowercase letters.`
  },
  pwupper: {
    shortDescription: msg`Force users to create a master password that includes at least this many uppercase characters.`,
    title: msg`Minimum uppercase in master password`,
    description: msg`Force users to create a master password that includes at least this many uppercase letters. Any user attempting to save a master password that uses too few uppercase letters is prompted to meet the requirement. Value: The required number of uppercase letters.`
  },
  pwspecial: {
    shortDescription: msg`Require a minimum number of special characters in each master password.`,
    title: msg`Minimum special characters in master password`,
    description: msg`Force users to create a master password that includes at least this many special characters or symbols, such as !@,^. Any user attempting to save a master password that uses too few special characters is prompted to meet the requirement. Value: The required number of special characters.`
  },
  numdiffcharsets: {
    shortDescription: msg`Require multiple types of characters in each master password.`,
    title: msg`Minimum character sets in master password`,
    description: msg`Force users to create a master password that includes at least this many different character sets. Any user attempting to save a master password that uses too few character sets is prompted to meet the requirement. Value: 1 (default), 2, 3, or 4. For example, enter 3 to force master passwords with at least one character from any three of the four character sets: uppercase, lowercase, numeric, and special (!#$,^ and similar)`
  },
  sitepwlen: {
    shortDescription: msg`Require a minimum number of characters in passwords for a specific site`,
    title: msg`Length of site passwords`,
    description: msg`Force the password generator to suggest passwords with a minimum number of characters on specific sites. Value: Enter each domain, separated by commas, followed by equals (=) and the minimum number of characters. Use white space to separate multiple entries. Keep in mind there is no way to force users to actually submit the suggested password. For example: twitter.com=20 google.com,gmail.com=15`
  },
  noexport: {
    shortDescription: msg`Prevent users from exporting the passwords and notes stores in their LastPass account.`,
    title: msg`Prohibit export`,
    description: msg`Prohibit users from exporting their account data. Advanced tip: To hide the export option in the client software, use the installer switch -dexp. Given that this is a client-side restriction, this policy cannot fully prevent exporting. The policy makes it more difficult for users to access the export option from the product interface.`
  },
  noimport: {
    shortDescription: msg`Prevent users from importing data from other sources to their LastPass account.`,
    title: msg`Prohibit import`,
    description: msg`Prohibit users from importing their account data. Advanced tip: To hide the import option in the client software, use the installer switch -dimp.`
  },
  noshare: {
    shortDescription: msg`Prevent users from sharing sites and Secure Notes with other users.`,
    title: msg`Prohibit sharing`,
    description: msg`Prohibit users from sharing sites, notes, and other data. Advanced tip: To hide sharing options in the client software, use the installer switch -dsha`
  },
  noshareout: {
    shortDescription: msg`Only allow users who belong to the company Enterprise account to join and use Shared Folders.`,
    title: msg`Prohibit shared folders outside enterprise`,
    description: msg`Prohibit users from sharing Shared Folders with anyone outside your Enterprise account except users at permitted domains. Value: To limit all outside sharing, enter 1. To allow access from certain domains, enter permitted domains separated by comma (example: aaa.com,bbb.com)`
  },
  noshareexceptfolders: {
    shortDescription: msg`Only allow users to share sites, notes, and other items through Shared Folders restricted to your organization.`,
    title: msg`Prohibit sharing except shared folders`,
    description: msg`Only allow sharing via the Shared Folders feature, which can be limited to internal sharing within your Enterprise account (Prohibit shared folders outside enterprise).`
  },
  sharedomainrestriction: {
    shortDescription: msg`Only allow users to share logins with others who have email addresses on specified domains (for example, company email addresses only).`,
    title: msg`Restrict sharing by domain`,
    description: msg`Only allow sharing from specified domains. Prohibit sharing from domains not listed below. Value: Enter permitted domains, separated by commas. Example: aaa.com,bbb.com`
  },
  norevert: {
    shortDescription: msg`Prevent users from reverting any changes made to their master password within the previous 60 days.`,
    title: msg`Prohibit master password reversion`,
    description: msg`Do not allow users to revert to their previous master password within 60 days of the change.`
  },
  hideidentities: {
    shortDescription: msg`Disable the Identities feature and remove it from the website and browser extensions`,
    title: msg`Disable identities`,
    description: msg`Disable the Identities feature and remove it from the website and browser extensions.`
  },
  nonotes: {
    shortDescription: msg`Prevent users from using the Secure Notes feature of LastPass`,
    title: msg`Disable secure notes`,
    description: msg`Prohibit users from using the Secure Notes feature. Once enabled, any existing notes remain available.`
  },
  noformfill: {
    shortDescription: msg`Prevent users from saving specific item types to their vault.`,
    title: msg`Disable form fillable items`,
    description: msg`Prevent users from saving specific item types to their vault. Value: Enter the number of each item to disable, separated by commas. For example, the value 1,2,15 prohibits users from saving notes, addresses, and servers. 0 - All 1 - Notes 2 - Address 3 - Payment Card 4 - Bank Account 5 - Driver’s License 6 - Passport 7 - Social Security Number 8 - Insurance Policy 9 - Health Insurance 10 - Membership 11 - Wi-Fi Password 12 - Email Account 13 - Instant Messenger 14 - Database 15 - Server 16 - SSH Key 17 - Software License 18 - Custom Item`
  },
  hidefingerprint: {
    shortDescription: msg`Prevent users from authenticating to LastPass with a supported desktop fingerprint reader instead of their master password.`,
    title: msg`Disable fingerprint reader authentication`,
    description: msg`Prohibit users from using Fingerprint Reader Authentication.`
  },
  nobookmarklets: {
    shortDescription: msg`Prevent users from installing Bookmarklets, which provide autofill and Form Fill on devices or browsers that do not support LastPass.`,
    title: msg`Prohibit bookmarklets`,
    description: msg`Disallow your users from installing Bookmarklets.`
  },
  nohint: {
    shortDescription: msg`Prevent users from creating and storing a master password hint for use in Account Recovery.`,
    title: msg`Prohibit master password hint`,
    description: msg`Prohibit users from saving a master password hint.`
  },
  dontsendemailuponaccountdeletion: {
    shortDescription: msg`Don't notify users via email when an administrator deletes their account.`,
    title: msg`Don't send email upon account deletion`,
    description: msg`Don't notify users via email when an administrator deletes their account.`
  },
  login_site_prompt: {
    shortDescription: msg`Prompt users for their master password when they log in to any site. `,
    title: msg`Require master password on site login`,
    description: msg`Force users to re-enter their master password upon login to a site from their vault.`
  },
  copyview_site_prompt: {
    shortDescription: msg`Prompt users for their master password every time they view or copy a password.`,
    title: msg`Require master password on copy/view`,
    description: msg`Force users to re-enter their master password upon attempt to copy or view a password from their vault.`
  },
  requirechange: {
    shortDescription: msg`Require users to change their master password after a set number of days.`,
    title: msg`Require master password change`,
    description: msg`Force users to change their master password after this many days. Value: The number of days between master password resets. This is recommended to be set at 90 days if you do not require multifactor usage, and 365 days if you require multifactor. To have a different limit for multifactor, specify a second number, separated by a comma (for example, 90,365).`
  },
  requirechangereuse: {
    shortDescription: msg`Force users to change their master password upon detecting that it matches the password for any site in their vault. `,
    title: msg`Require master password change when reuse detected`,
    description: msg`Force users to change their master password upon detecting that it matches the password for any site in their vault. If an employee saves a site password to their vault that matches their master password, they are immediately logged out of LastPass and, upon next login, are forced to change their master password.`
  },
  noreusemasterpass: {
    shortDescription: msg`Prevent users from reusing previous master passwords when updating their master password.`,
    title: msg`Prohibit reuse of old master passwords`,

    description: msg`Prohibit users from re-using recent master passwords. Value: The number of historical passwords to check against`
  },
  nomultifactordisable: {
    shortDescription: msg`Allow users to bypass multifactor authentication via email notification.`,
    title: msg`Prohibit multifactor disable via email`,
    description: msg`Prevent users from bypassing multifactor authentication via email notification. When enabled, only an admin can disable MFA.`
  },
  requirelastpassauth: {
    shortDescription: msg`Require use of LastPass Authenticator as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of LastPass Authenticator`,
    description: msg`Require users to set up and use LastPass Authenticator as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requirelastpassmfa: {
    shortDescription: msg`Require use of LastPass MFA as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of LastPass MFA`,
    description: msg`Require use of LastPass MFA as a second factor of authentication when logging in to LastPass. LastPass MFA is available as part of a LastPass Identity subscription. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requireyubikey: {
    shortDescription: msg`Require use of Yubico's YubiKey as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of YubiKey`,
    description: msg`Require users to set up and use YubiKey as their second factor of authentication when logging in to LastPass. Tip: Share this setup guide with your users: https://support.logmeininc.com/lastpass/help/yubikey-multifactor-authentication-lp030020 IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiresesame: {
    shortDescription: msg`Require use of LastPass Sesame as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of LastPass Sesame`,
    description: msg`Require users to set up and use LastPass Sesame as their second factor of authentication when logging in to LastPass. Tip: Share this setup guide with your users: https://support.logmeininc.com/lastpass/help/sesame-multifactor-authentication-lp030023 IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiregoogleauth: {
    shortDescription: msg`Require use of Google Authenticator or TOTP MFA as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of Google Authenticator`,
    description: msg`Require users to set up and use Google Authenticator as their second factor of authentication when logging in to LastPass. Tip: Share this setup guide with your users: https://support.logmeininc.com/lastpass/help/google-authenticator-lp030015 IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiremicrosoftauth: {
    shortDescription: msg`Require use of Microsoft Authenticator or TOTP MFA as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of Microsoft Authenticator`,
    description: msg`Require users to set up and use Microsoft Authenticator as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiretoopher: {
    shortDescription: msg`Require use of Toopher as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of Toopher`,
    description: msg`Require users to set up and use Toopher as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requireduo: {
    shortDescription: msg`Require use of Duo Security as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of Duo Security`,
    description: msg`Require use of Duo Security as a second factor of authentication when logging in to LastPass. You must enter your integration key, secret key, and API hostname in the boxes below. Duo Security must be configured by the user. To require Duo Security X days after the user account is created, enter a number in the value field below. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiresecureauth: {
    shortDescription: msg`Require use of SecureAuth as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of SecureAuth`,
    description: msg`Require users to set up and use SecureAuth as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiretransakt: {
    shortDescription: msg`Require use of Transakt as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of Transakt`,
    description: msg`Require users to set up and use Transakt as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiresalesforcehash: {
    shortDescription: msg`Require use of Salesforce Authenticator as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of Salesforce Authenticator`,
    description: msg`Require users to set up and use Salesforce Authenticator as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiresecurid: {
    shortDescription: msg`Require use of RSA SecurID/RADIUS as a second factor of authentication when logging in to LastPass.`,
    title: msg`Require use of this service`,
    description: msg`Require use of this service as a second factor of authentication when logging in to LastPass. Click the 'enabled' box to enable this policy. this service must be configured by the user. Important: This policy doesn't apply to federated users.`
  },
  requiresymantecvip: {
    shortDescription: msg`Require users to set up and use Symantec VIP as their second factor of authentication when logging in to LastPass. `,
    title: msg`Require use of Symantec VIP`,
    description: msg`Require users to set up and use Symantec VIP as their second factor of authentication when logging in to LastPass. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiremultifactor: {
    shortDescription: msg`Add another layer of protection to LastPass user accounts by adding an additional login step.`,
    title: msg`Require use of any MFA option`,
    description: msg`Require users to enable a multifactor authentication option. To define the list of valid multifactor options available to users, go to Advanced Options > Enterprise Options in the Admin Console. Currently available options: LastPass Authenticator, YubiKey, LastPass Sesame, Google Authenticator, Toopher, Duo Security, SecureAuth, Transakt, Salesforce Authenticator, RSA SecurID, and Symantec VIP. IMPORTANT: Policy does not apply for Federated Users.`
  },
  requiremultifactorafterxdays: {
    shortDescription: msg`Require users to enable a multifactor authentication option a specified number of days after account creation.`,
    title: msg`Require any MFA option after grace period`,
    description: msg`Require users to enable a multifactor authentication option a specified number of days after account creation. To define the list of valid multifactor options available to users, go to Advanced Options > Enterprise Options in the Admin Console. Currently available options: LastPass Authenticator, YubiKey, LastPass Sesame, Google Authenticator, Toopher, Duo Security, SecureAuth, Transakt, Salesforce Authenticator, RSA SecurID, and Symantec VIP. IMPORTANT: Policy doesn't apply for Federated Users.`
  },
  restricttrust: {
    shortDescription: msg`Allow users to skip multifactor authentication from trusted locations, but still require it from untrusted locations.`,
    title: msg`Allow users to skip MFA at trusted locations`,
    description: msg`Allow users to trust computers and bypass multifactor authentication when accessing LastPass from an approved location (such as your office), based on IP address. Value: Enter each allowed IP address or partial IP address, separated by white space. Example: 71.126.154. 128.8. 120.0.0.1. This allows any user located at 71.126.154.*, 128.8.*.* and 120.0.0.1 to trust their device and skip MFA. To disable trust altogether, enter 'none'. This is not retroactive. Computers previously trusted remain trusted after the policy is enabled.`
  },
  disableofflinega: {
    shortDescription: msg`When using TOTP MFA, the offline version of the user's vault can be accessed by default without entering the MFA.`,
    title: msg`Prohibit offline access for TOTP MFA`,
    description: msg`Prevent users with Google Authenticator as their MFA option from gaining offline access to their account. This ensures that the local cache can't be accessed without a second factor.`
  },
  googleauthtimeoffset: {
    shortDescription: msg`Adjust the length of time a Google Authenticator code remains valid. `,
    title: msg`Set code validity interval for Google Authenticator`,
    description: msg`Set the number of minutes for which codes generated by Google Authenticator remain valid for entry to LastPass. This helps resolve issues with clock drift on user devices. By default, LastPass considers Google Authenticator codes valid up to 4 minutes in the past and 4 minutes in the future. Value: 1-5, in minutes.`
  },
  singleyubikey: {
    shortDescription: msg`Limit users to only one YubiKey for their LastPass account. By default, LastPass allows up to five YubiKeys.`,
    title: msg`Prohibit multiple YubiKeys`,
    description: msg`Prohibit users from setting up more than one YubiKey for their account. Otherwise, they are allowed up to five YubiKeys.`
  },
  duoonlyusername: {
    shortDescription: msg`Use only the local-part (username portion before @) of a user's email address to create their Duo Security username. `,
    title: msg`Use local-part of email as Duo Security username`,
    description: msg`Use only the local-part (username portion before @) of a user's email address to create their Duo Security username. Example: If the user's email address is 'local-part@domain.com', their Duo Security username is 'local-part'.`
  },
  enterpriseconsolemultifactor: {
    shortDescription: msg`Require admins to complete multifactor authentication upon accessing the Admin Console this many minutes after login to LastPass.`,
    title: msg`Require MFA for admin console`,
    description: msg`Require logged in admins to complete MFA when accessing the admin console after this many minutes of inactivity. IMPORTANT: Policy does not apply for Federated Users.`
  },
  nooutofbandauto: {
    shortDescription: msg`When using multifactor authentication with out-of-band capability, prevent automatic push notifications during login.`,
    title: msg`Require action for out-of-band MFA`,
    description: msg`Prevent out-of-band MFA services (Toopher, Duo Security, Transakt) from creating push notifications.`
  },
  preferduowebsdk: {
    shortDescription: msg`When possible, use the Duo Web SDK instead of the LastPass Duo Security GUI.`,
    title: msg`Use Duo Web SDK when possible`,
    description: msg`When possible, use the Duo Web SDK instead of the LastPass Duo Security GUI. Some LastPass features are disabled, such as automatic push notification.`
  },
  useparentmultifactor: {
    shortDescription: msg`Require multifactor authentication for personal accounts linked to an Enterprise account. `,
    title: msg`Apply parent account MFA policy`,

    description: msg`Apply the parent account's multifactor authentication requirements to linked personal accounts`
  },
  no2faforaiprange: {
    shortDescription: msg`Do not prompt for multifactor authentication when logging in from a specified IP address.`,
    title: msg`Skip MFA at trusted locations`,
    description: msg`Users logging in from listed addresses are not prompted for multifactor authentication. Value: Enter each allowed IP address or partial IP address, separated by white space. Example: 71.126.154. 128.8. 120.0.0.1 This allows login without multifactor from any address in 71.126.154.*, 128.8.*.* and 120.0.0.1. We also support CIDR Notation. For example: 61.12.56.0/24 allows any address in 61.12.56.* to log in.`
  },
  disallowemail: {
    shortDescription: msg`Prevent users from receiving all emails other than the initial activation email.`,
    title: msg`Limit email to users`,
    description: msg`When enabled, users will only receive an initial activation email. All other emails from LastPass will be prevented.`
  },
  preventemail_accountchange: {
    shortDescription: msg`Don't notify users via email when an administrator changes their account email or master password.`,
    title: msg`Don't send email upon account change`,
    description: msg`Don't notify users via email when an administrator changes their account email or master password.`
  },
  preventemail_sapasswordreset: {
    shortDescription: msg`Don't notify users via email when an administrator resets their master password using the 'Permit super admins to reset master passwords' policy.`,
    title: msg`Don't send email upon master password reset`,
    description: msg`Don't notify users via email when an administrator resets their master password using the 'Permit super admins to reset master passwords' policy.`
  },
  noupgradeprompts: {
    shortDescription: msg`Turn off prompts when updates to the LastPass IE plugin are available, and prevent automatic updates to the LastPass plugin for IE.`,
    title: msg`Prevent upgrade prompts on Internet Explorer`,
    description: msg`If enabled, this policy will prevent the LastPass IE plugin from automatically upgrading. It will also prevent users from seeing prompts when IE upgrades are available. Click the 'enabled' box to enable this policy.`
  },
  emaillockout: {
    shortDescription: msg`Send an automated email to a designated recipients when a LastPass account is temporarily locked due to too many failed login attempts.`,
    title: msg`Notify admins upon user lockout`,
    description: msg`Send an email to the specified addresses when an account is temporarily locked out due to failed login attempts. Value: Email addresses, separated by commas`
  },
  emailnewuser: {
    shortDescription: msg`Send an automated notification to the specified email addresses when a user account is added or removed`,
    title: msg`Notify admins upon added/removed user`,
    description: msg`Send an email to the specified addresses upon user account creation or deletion, but not upon deactivation. This is useful when using automatic provisioning or our Active Directory client. Enter email addresses separated by commas.`
  },
  superadminshared: {
    shortDescription: msg`Automatically share all shared folders with the designated admins.`,
    title: msg`Permit super admins to access shared folders`,
    description: msg`Invisibly share all shared folders in your enterprise with authorized admins. Click 'Edit Users' to add admins. To disable the ability to add or change this policy, contact LastPass. Shared folders that existed prior to setting this policy are assigned the next time a user with 'Can Administer' access to that folder logs back in to LastPass.`
  },
  superadminrecovery: {
    shortDescription: msg`Allow designated admins to reset users' master passwords.`,
    title: msg`Permit super admins to reset master passwords`,
    description: msg`Allow selected admins to reset the master password of any user in your enterprise. Click 'Edit Users' to add admins. Users must log in to the browser extension at least once to capture the encryption key that makes admin reset possible. Security tip: Always protect accounts with 'super admin' rights with multifactor authentication. While not recommended, you can specify multiple admins by separating their usernames by comma, space, or semicolon. To disable the ability to add or change this policy, contact LastPass.`
  },
  restrictedadmins: {
    shortDescription: msg`Allow users to access the Admin Console with specified rights.`,
    title: msg`Grant limited access to Admin Console`,
    description: msg`Grant users limited access to the Admin Console. First, click 'Edit Users' to add users. Second, under 'Notes', enter the level of access (1, 2, 3, or 4). 1 - Only allows the Reset Master Password function for users. 2 - Disable multifactor authentication for users + (1) 3 - Management of the Users page + (1 and 2) 4 - Management of the Users and Groups pages + (1, 2 and 3) Only values between 1-4 are accepted. Important: - Once added here, you must add each user to 'Permit super admins to reset master passwords' policy. - Adding a user to this policy prevents them from being added to custom Roles. - After adding a user to this policy, they will show as an Administrator on the Users page.`
  },
  notifyonevent: {
    shortDescription: msg`Send an automatic email notification when the specified events occur for the selected users or groups. `,
    title: msg`Notify upon login event`,
    description: msg`Send a notification upon any successful or failed login event made by selected users or groups. -login: Send a notification when a user successfully logs in to their LastPass account. -failedlogin: Send a notification when a user makes a failed attempt to log in to their LastPass account. To notify on both events, separate them with a comma or space. Unless otherwise specified, email notifications are sent to the user who triggers the event. To notify someone else instead, type an equals '=' sign after the event and then the email address. Example: failedlogin=elmer@mydomain.com,login=fudd@mydomain.com`
  },
  precreatesharingkey: {
    shortDescription: msg`When autoprovisioning users, add the ability to share items or folders to them before they have logged in to their account.`,
    title: msg`Pre-create sharing key`,

    description: msg`When creating user accounts server-side, automatically create and encrypt the sharing key that allows users to share items with others. Without this policy, users must log in to the browser extension to generate the key and share. Note: The sharing key will be created server-side, as opposed to client-side upon user login. It is immediately encrypted using the user's temporary password and is never stored in plaintext`
  },
  accountrecoveryemail: {
    shortDescription: msg`Send an automated email to designated recipients when an employee uses Account Recovery.`,
    title: msg`Notify admins upon account recovery`,
    description: msg`Send an email to the specified addresses when the account recovery option is used. Value: Enter 1 to send when an end users requests account recovery. Enter 2 to send when account recovery is successfully completed and the user re-sets their master password. Enter 1,2 for both options. Example: 1,2,admin@acme.com,admin2@acme.com`
  },
  notifyonlogin: {
    shortDescription: undefined,
    title: msg`Send email on login *DEPRECATED*`,
    description: msg`Deprecated: Send an email notification whenever the specified user (users) log into their LastPass account. The value of this policy should be the email address or addresses of the user accounts to generate email on, separated by commas. (Example: elmer@mydomain.com,aloysious@mydomain.com) If you choose to send email to a different address, specify the email address of the user account to send notifications for, an equals '=' character, and then the email address to send the notifications to. (Example: root@mydomain.com=elmer@mydomain.com)`
  },
  preventsfadminnotifications: {
    shortDescription: msg`Prevent automated emails to Shared Folder admins when a user's account status changes.`,
    title: msg`Prevent user status emails to shared folder admins`,
    description: msg`Disable automated emails to shared folder admins regarding change in user account status.`
  },
  restrictemaildomain: {
    shortDescription: msg`When users create or update their LastPass account, only allow approved email domains as their LastPass username.`,
    title: msg`Restrict domain for LastPass username`,
    description: msg`Only allow users to use an email from an approved domain when creating a username for their LastPass account. No accounts can be created or updated using a username outside the approved domains. Enter the allowed domains, separated by commas.`
  },
  disableemailchange: {
    shortDescription: msg`Prevent users from changing the email address for their LastPass account.`,
    title: msg`Prohibit account email change`,
    description: msg`Prohibit users from changing their LastPass account email address.`
  },
  logusername: {
    shortDescription: msg`Show usernames in reports available to admins.`,
    title: msg`Log username in reporting`,
    description: msg`By activating this policy, you allow LastPass to store username data unencrypted and to provide that data to you in reports. Important: LastPass never stores username data unencrypted unless you activate this policy. Logged in users must log in again for this policy to take effect. EXAMPLE: If a user logs in to a site with the username 'jackhandy' with URL https://abc.com/, then by default we would display 'abc.com'. With this policy enabled, we display 'abc.com (jackhandy)'.`
  },
  logfullurl: {
    shortDescription: msg`Show the full website URL in reports available to admins, rather than just the domain name. `,
    title: msg`Log full URL in reporting`,
    description: msg`Show full URL (server + path, but no HTTP parameters) in reports rather than just the domain name of the site. This is often useful to distinguish which service is being accessed if many different resources are located on the same internal server. This policy goes into effect upon next user login. EXAMPLE If a user logs into https://def.abc.com/login.php?a=1, then by default we would display 'abc.com', but with this policy enabled we would display 'def.abc.com/login.php'.`
  },
  logname: {
    shortDescription: msg`Show the name of sites and Secure Notes in reports available to admins. `,
    title: msg`Log item name in reporting`,
    description: msg`Show name of site/note in reports. The name data (which is typically never sent to LastPass in unencrypted format) is sent by the client when reporting a login event and is shown in the admin reports. This policy goes into effect upon next user login. EXAMPLE If a user logs in to the site 'alphabet' with url https://abc.com/, then by default we display 'abc.com'. With this policy enabled, we display 'abc.com (alphabet)'.`
  },
  notifysharingkeycreated: {
    shortDescription: msg`Send a notification to the specified email addresses when a user has created their sharing key.`,
    title: msg`Notify admins upon creation of sharing key`,
    description: msg`Send an email to the specified addresses upon creation of user sharing keys. A user's sharing key is automatically created the first time they log in to the LastPass extension, or manually at LastPass.com on the Settings page. A user must have sharing keys to share or receive shared items.`
  },
  requirepin: {
    shortDescription: msg`Force users to enter a PIN code when they open the mobile app.`,
    title: msg`Require PIN`,
    description: msg`Force users to enter a PIN code when they open the mobile app.`
  },
  logoffbackground: {
    shortDescription: msg`Set a time limit for how long the LastPass mobile app can run in the background before users are logged out.`,
    title: msg`Force logoff from background`,
    description: msg`Force logoff after the app is in the background for the specified number of minutes.`
  },
  enablelogging: {
    shortDescription: msg`Log mobile activity`,
    title: msg`Log mobile activity `,
    description: msg`Gather data about password access and site fill events for use in reporting`
  },
  disallowmasterpasswordsavemobile: {
    shortDescription: msg`Prevent users from remembering their master password to the app.`,
    title: msg`Prohibit 'Remember master password' on mobile`,
    description: msg`Prevent users from remembering their master password to the app.`
  },
  mobilelockoptionoverride: {
    shortDescription: msg`Add extra security to mobile devices by forcing users to log in or re-enter their PIN to unlock the app after the specified period of inactivity.`,
    title: msg`Override mobile lock option`,
    description: msg`Force users to log in or re-enter their PIN to unlock the app after the specified period of inactivity. Supported on LastPass for iOS 4.1.8 or higher and LastPass for Android 4.2.290 or higher. Value: Allowed period of inactivity, as follows: 0 - Immediately, 1 - 1 minute, 2 - 3 minutes, 3 - 5 minutes, 4 - 15 minutes, 5 - 1 hour, 6 -'8 hours, 7 - 24 hours, 8 - Never.`
  },
  nolinkpersonal: {
    shortDescription: msg`Prevent users from linking a personal account to their Enterprise account.`,
    title: msg`Prohibit linked personal account`,
    description: msg`Prohibit users from linking their personal account to their Enterprise account.`
  },
  nochangepersonal: {
    shortDescription: msg`Restrict users' ability to add, update, or delete data in a linked personal account when logged in to their Enterprise account.`,
    title: msg`Prohibit update of personal account info`,
    description: msg`Prevent users from adding, updating, or deleting personal account data when their personal account is linked to your Enterprise account.`
  },
  savesitestopersonal: {
    shortDescription: msg`Save new logins to the user's linked personal account, except on specified websites.`,
    title: msg`Set default account for new sites`,
    description: msg`Force sites outside the specified domains to be saved to a user's linked personal account. Value: Enter each domain that should be saved to a user's work/Enterprise vault, separated by commas. Example: aaa.com,bbb.com.`
  },
  accountselectionbasedonemail: {
    shortDescription: msg`Save personal sites to personal vault`,
    title: msg`Save personal sites to personal vault`,
    description: msg`By linking their personal and work accounts, users gain access to their LastPass vaults with a single login. When this policy is enabled, sites with a username matching the user's linked personal account are saved directly to the personal vault. Sites with any other username are saved to the work vault.`
  },
  requirelinkedacct: {
    shortDescription: msg`Recommend or require users to create a personal LastPass account that is linked automatically to their Enterprise account. `,
    title: msg`Recommend or require linked personal account`,
    description: msg`Force each user to create a personal account linked to their Enterprise account. Users in your Enterprise with an existing personal account are forced to link it to their personal account. Users without a personal account are prompted to create one using their personal email address as their username. The master password is the same for both accounts. Value: Forced, enter 1. Optional, enter 2.`
  },
  sendmpstrength: {
    shortDescription: msg`Collect and display the strength of employee master passwords in reports.`,
    title: msg`Show master password strength`,

    description: msg`Collect and report data about the strength of your users' master passwords.`
  },
  pwndlistscan: {
    shortDescription: msg`Run automated security scans to check usernames against a database of known third-party breaches to check for compromised accounts.`,
    title: msg`Check for compromised user accounts`,
    description: msg`When performing a background security scan, check each username against a database of known third-party security breaches. If the username associated with a login is potentially at risk, an email is sent to the user identifying the compromised website and recommending preventative measures.`
  },
  passwordexp: {
    shortDescription: msg`Send automated notifications to inform users to change old passwords on specified domains after a designated time period. `,
    title: msg`Password expiration notification`,
    description: msg`Report on password expiration status for each employee. List domains to monitor, including the expiration period per domain (90 days if not otherwise specified). An asterisk indicates all domains. To send a report of expired accounts to an admin, include email addresses. To send the email a certain number of days after expiration, add a colon and a number. Example: domain.com:83, domain2.net:173, *:53, admin@domain.com:7 Users are notified for passwords 53 days old f all domains, 83 days old for domain.com, 173 days old for domain2.net, and admin@domain.com is alerted 7 days after user notification.`
  },
  restrictenterpriseapitoeventreporting: {
    shortDescription: msg`Prevent the LastPass Enterprise API from being used for anything other than reporting.`,
    title: msg`Restrict Enterprise API to event reporting`,
    description: msg`Prevent the LastPass Enterprise API from being used for anything other than reporting. When enabled, you can only call the Enterprise API with cmd=reporting. Any other endpoints will result in an unauthorized error. If your AD Sync gets broken after calling the Enterprise API, then you must generate a new provision hash for both AD Sync (using the latest LastPass AD Connector) and the Enterprise API.`
  },
  disablecloudapps: {
    shortDescription: msg`Remove the Cloud Apps menu item from user vaults.`,
    title: msg`Hide Cloud Apps from users`,
    description: msg`If you are not using LastPass SSO, prevent end users from accessing LastPass SSO features by removing the Cloud Apps menu item from their vault.`
  },
  sendchallengescore: {
    shortDescription: msg`Include user security scores in Admin Console reports.`,
    title: msg`Report security score`,
    description: msg`Upon user login, calculate a security score and report the results in Admin Console reports.`
  },
  disableautofill: {
    shortDescription: msg`Prohibit users from automatically filling passwords on platforms other than mobile apps. This includes, for example, Chrome, Firefox, Safari and Edge on a PC or Mac. AutoFill remains available to users of the LastPass mobile app. This policy disables the option 'Automatically Fill Login Information' in LastPass Preferences.`,
    title: msg`Disable autofill`,
    description: msg`Prohibit users from automatically filling passwords on platforms other than mobile apps. This includes, for example, Chrome, Firefox, Safari and Edge on a PC or Mac. AutoFill remains available to users of the LastPass mobile app. This policy disables the option 'Automatically Fill Login Information' in LastPass Preferences.`
  },
  lpauthenticatorgeoloc: {
    shortDescription: msg`Add a layer of security for your users by restricting login from untrusted locations, based on device GPS location.`,
    title: msg`Restrict LastPass Authenticator usage by location`,
    description: msg`Add a layer of security for your users by restricting login from untrusted locations, based on device GPS location. Set your trusted location using the map below. Anyone outside the trusted location will be denied login through LastPass Authenticator.`
  },
  dark_web_monitoring: {
    shortDescription: msg`Control how your organization uses LastPass dark web monitoring, which runs automated security scans of usernames against a database of known third-party data breaches.`,
    title: msg`Control dark web monitoring`,
    description: msg`Control how your organization uses LastPass dark web monitoring. For each monitored username, LastPass performs a background security scan against a database of known third-party security breaches. If the username associated with a login is potentially at risk, an email is sent to the user identifying the compromised website and recommending preventative measures. Values: 1: Available, user controlled. Allow users to stop monitoring any or all addresses. 2: Available, admin controlled. Don't allow users to stop monitoring in any way. 3: Restricted, admin controlled. Turn off dark web monitoring for all users, even if they had previously activated it before this policy was implemented. Don't allow monitoring in any way.`
  },
  exclude_password: {
    shortDescription: msg`Enable this policy to allow users to exclude sites from their security score calculation and to specify sites you always want to exclude for all.`,
    title: msg`Control security score calculation`,
    description: msg`Enable this policy to allow users to exclude sites from their security score calculation. Furthermore, you can specify domains that are excluded automatically for all users to which the policy applies. Value: Enter each domain you want to exclude automatically from all security score calculations, separated by commas. When no value is entered, users can exclude any site and no domains are excluded automatically.`
  },
  allowpasswordlesslogin: {
    shortDescription: msg`Allow users to enable passwordless login as a method for logging in to LastPass at LastPass.com and in the extension.`,
    title: msg`Allow passwordless login`,
    description: msg`Allow users to enable passwordless login as a method for logging in to LastPass at LastPass.com and in the extension. The Enable passwordless button will be shown on the Account Settings page. Biometric login on the mobile app is not affected.`
  },
  disable_two_factor_code: {
    shortDescription: msg`Prevent users from seeing time-based one-time passcodes (TOTP) in their LastPass vault.`,
    title: msg`Don't show TOTP in vault`,
    description: msg`Prevent users from seeing time-based one-time passcodes (TOTP) in their LastPass vault.`
  },
  dontnotifyuserwhenaddedtosharedfolder: {
    shortDescription: msg`Don't notify users automatically by email when you add them to a shared folder.`,
    title: msg`Don't send email when adding users to shared folder`,
    description: msg`Don't notify users automatically by email when you add them to a shared folder.`
  },
  overridelastpassauthmethods: {
    shortDescription: msg`Set the MFA methods that users can choose as their primary method for confirming their identity when logging in to LastPass.`,
    title: msg`Override default MFA methods`,
    description: undefined
  },
  prohibitfamilyasabenefit: {
    shortDescription: msg`This policy prevents users from claiming Families as a Benefit. This complimentary benefit provides a free LastPass Families personal account to all LastPass Business users.`,
    title: msg`Prohibit Families as a benefit`,
    description: msg`Enable this policy to prohibit users from claiming a free LastPass Families account for their own personal password management. If you don't enable this policy, your users can claim their free benefit by logging in to their Business account and clicking Account Benefits in their vault and then following the on-screen instructions. You, the Business account owner, can never access any data in your users' Families or personal accounts. You can only see whether or not a user has claimed the LastPass Families benefit.`
  },
  requirepasswordlessvialastpassauth: {
    shortDescription: msg`Let your users leverage biometric authentication on their mobile devices to access cloud apps, legacy apps, VPNs, and workstations without a password.`,
    title: msg`Require passwordless verification via LastPass Authenticator`,
    description: msg`Let your users leverage biometric authentication on their mobile devices to access cloud apps, legacy apps, VPNs, and workstations without a password.`
  },
  showsecondaryonboarding: {
    shortDescription: msg`Help users gain the skills they need to become LastPass power users. They’ll see a panel on the right side of their vault that encourages them to try LastPass features.`,
    title: msg`Show starter kit`,
    description: msg`Help users gain the skills they need to become LastPass power users. They’ll see a panel on the right side of their vault that encourages them to try LastPass features.`
  },
  requirelastpassmfaaftergraceperiod: {
    shortDescription: msg`Require users to enable LastPass MFA a specified number of days after account creation.`,
    title: msg`Require LastPass MFA after grace period`,
    description: msg`Require users to enable LastPass MFA a specified number of days after account creation.`
  }
}

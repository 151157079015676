import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'

export enum AdminListActionTypes {
  SET_ADMIN_LIST = '[admin-list] set',
  GET_ADMIN_LIST = '[admin-list] get',
  ADD_SELECTED_ADMINS = '[admin-list] add-selected-admins',
  REMOVE_SELECTED_ADMINS = '[admin-list] remove-selected-admins',
  SET_SELECTED_ADMINS = '[admin-list] set-selected-admins',
  SET_ADMINS_LOADING = '[admin-list] set-admins-loading'
}

export const adminListActions = {
  setAdminList: (list: UserLevelDetails[], totalResults: number) =>
    track(
      createAction(AdminListActionTypes.SET_ADMIN_LIST, {
        list,
        totalResults
      }),
      {
        segment: uacEvent('Admin Overview Viewed')
      }
    ),

  getAdminList: () => createAction(AdminListActionTypes.GET_ADMIN_LIST),
  addSelectedAdmins: (admins: UserLevelDetails[]) =>
    createAction(AdminListActionTypes.ADD_SELECTED_ADMINS, { admins }),
  removeSelectedAdmins: (admins: UserLevelDetails[]) =>
    createAction(AdminListActionTypes.REMOVE_SELECTED_ADMINS, { admins }),
  setSelectedAdmins: (admins: UserLevelDetails[]) =>
    createAction(AdminListActionTypes.SET_SELECTED_ADMINS, { admins }),
  setAdminsLoading: (loading: boolean) =>
    createAction(AdminListActionTypes.SET_ADMINS_LOADING, { loading })
}

export type AdminListActions = ActionsUnion<typeof adminListActions>

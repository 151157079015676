import localForage from 'localforage'

interface LocalForageRepository<T> {
  get: () => Promise<T | null>
  set: (value: T) => Promise<T>
  remove: () => Promise<void>
}

export const createRepository = <T>(key: string): LocalForageRepository<T> => ({
  get: () => localForage.getItem(key),
  set: value => localForage.setItem(key, value),
  remove: () => localForage.removeItem(key)
})

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { CompanyTemplateSettings } from '@lastpass/admin-console-dependencies/types/managed-company'

export const SUCCESS = 'success'

const success = (companyTemplateSettings: CompanyTemplateSettings[]) =>
  createResponse(SUCCESS, companyTemplateSettings)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'company-template/configuration',
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const responseData = JSON.parse(response)
        const companyTemplateSettings = responseData.content.types
        return success(companyTemplateSettings)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { MfaApp } from '@lastpass/admin-console-dependencies/types/mfa-applications-teams'

export const SUCCESS = 'success'

const success = (mfaAppResponse: MfaApp) =>
  createResponse(SUCCESS, { mfaAppResponse })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(name: string) {
    try {
      const [response, status] = await fetch(
        'company/mfaapps',
        'POST',
        {
          ['applicationName']: name
        },
        { 'content-type': 'application/json' }
      )
      if (status === StatusCodes.OK && response) {
        const mfaAppAddResponse = JSON.parse(response)
        return success(mfaAppAddResponse.content)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

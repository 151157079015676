import React, { ReactElement, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import styled from '@emotion/styled'

import { ReactComponent as ArrowDown } from '@lastpass/assets/svg/admin-console/icon-arrow-down.svg'

const ExpandableBlockTitle = styled.div`
  cursor: pointer;
  padding-top: 11px;
  padding-bottom: 11px;
  border-top: 1px solid ${props => props.theme.colors.grayBorder};
  line-height: 24px;
  font-size: 14px;
`

const ExpandableBlockContent = styled.div`
  padding: 0 11px 11px 11px;
  font-size: 14px;
  line-height: 24px;
  padding-left: 48px;
`

const StyledArrowDown = styled(ArrowDown)`
  width: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: -3px;
  opacity: 0.65;
`

const StyledArrowUp = styled(StyledArrowDown)`
  transform: rotate(180deg);
  opacity: 1;
`

export const ExpandableBlock: React.FunctionComponent<{
  title: ReactElement
}> = ({ children, title }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  return (
    <>
      <ExpandableBlockTitle
        data-qa="PermissionCategory"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <StyledArrowUp /> : <StyledArrowDown />}
        {title}
      </ExpandableBlockTitle>
      <AnimateHeight height={isExpanded ? 'auto' : 0}>
        <ExpandableBlockContent>{children}</ExpandableBlockContent>
      </AnimateHeight>
    </>
  )
}

import styled from '@emotion/styled'

import { BodyRegularStyle, PrimaryButton, TextInput } from '@lastpass/lastkit'

export const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 540px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

export const StyledExternalLink = styled.a`
  margin-left: auto;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  color: ${props => props.theme.colors.blue700};
`

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
  height: 40px;
`

export const GeneratedKeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  padding-bottom: 24px;
`

export const GeneratedKeyTextInputContainer = styled.div`
  flex-basis: 540px;
  margin-right: 10px;
`

export const BoldWrapper = styled.span`
  font-weight: bold;
`

export const StyledCardContainer = styled.div`
  margin-right: 80px;
  margin-bottom: 18px;
`

export const StyledChildRow = styled.div`
  ${BodyRegularStyle};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.colors.neutral700};
  margin-bottom: 18px;

  label {
    margin-right: 30px;
  }
`

export const StyledSubtitle = styled(StyledChildRow)`
  margin-top: -18px;
`

export const CheckBoxWithToolTipWithMargin = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  label {
    margin-right: 0 !important;
  }
`
export const StyledWarningBox = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 14px;
  font-size: 14px;
  border: 1px solid ${props => props.theme.colors.yellow900};
  border-radius: ${props => props.theme.radius.pixel4};
  background-color: ${props => props.theme.colors.yellow100};
`

export const CheckBoxWithToolTip = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-right: 0 !important;
  }
`
export const StyledKeyRotationContainer = styled.div`
  padding-top: 24px;
`

export const StyledKeyRotationContent = styled.div`
  display: flex;
  margin: 18px 0;
`

export const BoldSpan = styled.span`
  font-weight: bold;
`
export const StyledButtonContainer = styled.div`
  margin: 18px 0;
`
export const StyledActionButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-right: 20px;
  height: 40px;
`

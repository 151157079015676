import React from 'react'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { LocationRedirect } from '@lastpass/routing'

import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { createAssign } from '@lastpass/admin-console-dependencies/ui/common/DrawerAssign/Assign'
import { createAssignNewEntity } from '@lastpass/admin-console-dependencies/ui/common/DrawerAssign/AssignNewEntity'

import { AddAdvancedPolicy } from './drawerSteps/AddAdvancedPolicy'
import { AllSet } from './drawerSteps/AllSet'
import { ConfigPolicy } from './drawerSteps/ConfigPolicy'
import { MapPage } from './drawerSteps/MapPage'

const Assign = createAssign<AppState>()
const AssignNewEntities = createAssignNewEntity<AppState>()

const getStep = (step: AdvancedPolicyDrawerSteps, close: string) => {
  const closeLink = `${close}${window.location.search}`
  switch (step) {
    case AdvancedPolicyDrawerSteps.addAdvancedPolicy:
      return <AddAdvancedPolicy closeLink={closeLink} />
    case AdvancedPolicyDrawerSteps.configPolicy:
      return <ConfigPolicy closeLink={closeLink} />
    case AdvancedPolicyDrawerSteps.assign:
      return <Assign closeLink={closeLink} />
    case AdvancedPolicyDrawerSteps.assignNewEntities:
      return <AssignNewEntities closeLink={closeLink} />
    case AdvancedPolicyDrawerSteps.map:
      return <MapPage closeLink={closeLink} />
    case AdvancedPolicyDrawerSteps.allSet:
      return <AllSet closeLink={closeLink} />
    default:
      return <LocationRedirect to={closeLink} />
  }
}

export const AdvancedPolicyDrawerPage = (props: {
  step: AdvancedPolicyDrawerSteps
  closeLink: string
}) => getStep(props.step, props.closeLink)

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'

export function createAddNewAdminLevelSaga(
  addNewAdminLevelService: UACServices.Services
) {
  return function* addNewAdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.addNewAdminLevel>
  ) {
    try {
      const {
        name,
        description,
        permissions,
        closeLink,
        isPartial
      } = action.payload

      try {
        const payload = isPartial
          ? {
              name,
              description,
              permissions,
              mspCidOverridePermissions: [],
              mspCidOverrideAdminLevel: MspCidOverrideLevel.admin
            }
          : {
              name,
              description,
              permissions
            }

        const result: UACServices.AdminLevelSaveAPI.Responses = yield call(
          addNewAdminLevelService.adminLevelSave,
          payload
        )

        switch (result.type) {
          case UACServices.AdminLevelSaveAPI.SUCCESS: {
            const adminLevelId = result.body.id
            yield put(adminListActions.getAdminList())
            yield put(
              globalActions.setNotification({
                message: msg`Admin level has been created: ${name}`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )

            if (closeLink) {
              yield put(push(closeLink))
            } else {
              const nextPage = permissions.includes(
                AdminPermissions.managedCompanyCidOverride
              )
                ? 'managed-admin-permissions'
                : 'add'

              yield put(push(`/users/admin/${adminLevelId}/${nextPage}`))
            }

            break
          }
          case UACServices.AdminLevelSaveAPI.DUPLICATE_NAME: {
            yield put(
              globalActions.setNotification({
                message: msg`There's already an admin level with that name`,
                type: NotificationType.alert,
                autoDismiss: false
              })
            )
            break
          }
          default:
            throw new UnknownServiceError()
        }
      } catch (e) {
        yield put(
          globalActions.setNotification({
            message: msg`Admin level hasn't been created.`,
            type: NotificationType.alert,
            autoDismiss: false
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

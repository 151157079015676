import { call, put } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { OAuthManager, OAuthUserProfile } from '@lastpass/http/oauth-manager'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'

export const companyDetailsSelector = (state: AppState) =>
  state.companyInfo.details

export function createCurrentUserSaga() {
  return function* getCurrentUserSaga() {
    try {
      const profile: OAuthUserProfile = yield call(OAuthManager.getUserProfile)

      if (profile) {
        const currentUser: CurrentUser = {
          email: profile.email,
          firstName: profile.firstname || '',
          lastName: profile.lastname || '',
          isMsp: profile.isMsp,
          userId: profile.userId
        }

        yield put(globalActions.setCurrentUser(currentUser))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

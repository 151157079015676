import { track, uacEvent } from '@lastpass/tracking'

import { createAction } from '@lastpass/admin-console-dependencies/state/action'
import { AffectedSecurityReportUser } from '@lastpass/admin-console-dependencies/types/security-report'

export enum UserActivityActionTypes {
  REPORT_GENERAL_REPORTS_OVERVIEW_VIEWED = '[reports] general-reports-overview-viewed',
  REPORT_WEAK_SECURITY_SCORE_EXPORT = '[reports] weak-security-score-export'
}

export const userActivityActions = {
  trackGeneralReportsOverviewViewed: () =>
    track(
      createAction(
        UserActivityActionTypes.REPORT_GENERAL_REPORTS_OVERVIEW_VIEWED
      ),
      {
        segment: uacEvent('General Reports Overview Viewed')
      }
    ),
  exportWeakSecurityScoreReport: (users: AffectedSecurityReportUser[]) =>
    createAction(UserActivityActionTypes.REPORT_WEAK_SECURITY_SCORE_EXPORT, {
      users
    })
}

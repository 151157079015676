import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/icon-warning-circle.svg'
import {
  Checkbox,
  DetailsDialog,
  Heading200,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import { hasNoPasswordSsoApps } from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'

import {
  dialogWidth,
  StyledBodyRegular,
  StyledCenter
} from './SystemUpgradeDialog'

const StyledHeading = styled(Heading200)`
  margin-bottom: 20px;
  width: 100%;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 15px;
`

const StyledTextButton = styled(TextButton)`
  margin-right: 15px;
`

const StyledList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 15px;
`

const YellowWarningBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  max-width: 585px;
  border-radius: 4px;
  background: ${props => props.theme.colors.yellow100};
  border: 1px solid;
  border-color: ${props => props.theme.colors.yellow800};
  margin: 20px 0px;
`
const SsoWarningText = styled.div`
  margin-left: 15px;
`

const SsoWarningIcon = styled(WarningIcon)`
  width: 40px;
  height: 40px;
`

const Bold = styled.span`
  font-weight: bold;
`

export const SystemUpgradeStartDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  const systemUpgradeState = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  const appList = useSelector(
    (state: AppState) => state.systemUpgrade.applicationList
  )

  const ssoAppDetails = useSelector<AppState, ApplicationListState>(
    appState => appState.applicationList
  )
  const hasNoPasswordSsoApp = hasNoPasswordSsoApps(ssoAppDetails)

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      hideCloseIcon={false}
      width={dialogWidth}
      content={
        <StyledCenter data-qa="SystemUpgradeStartDialog">
          <StyledHeading>
            <Trans>Have you reviewed everything?</Trans>
          </StyledHeading>
          <StyledBodyRegular>
            <Trans>
              This is the big moment. The changes you’ve read about will be
              executed. Here’s what you’ll need to do next:
              <StyledList>
                {hasNoPasswordSsoApp ? (
                  <li data-qa="SsoAppHintLabel">
                    Follow along as we help you fix your SSO app connections
                  </li>
                ) : null}
                <li>
                  For each SSO app, make sure someone in your organization has
                  access via username & password (not SSO alone)
                </li>
                <li>
                  Review your policies and re-configure any that need attention
                </li>
              </StyledList>
              The upgrade itself should only take a few seconds.
            </Trans>
            {hasNoPasswordSsoApp && (
              <YellowWarningBox data-qa="StartDialogSsoWarningBox">
                <SsoWarningIcon />
                <SsoWarningText>
                  <Trans>
                    <Bold>Don’t lose access to your SSO apps.</Bold> For each
                    SSO app, make sure someone in your organization has access
                    via username & password (not SSO alone).
                  </Trans>
                </SsoWarningText>
              </YellowWarningBox>
            )}
          </StyledBodyRegular>
          {hasNoPasswordSsoApp && (
            <Checkbox
              data-qa="SsoWarningConfirmation"
              checked={systemUpgradeState.isSsoWarningConfirmed}
              onChange={e =>
                dispatch(
                  systemUpgradeActions.setSsoWarningConfirmation(
                    e.currentTarget.checked
                  )
                )
              }
            >
              <Trans>
                I confirm that my organization has access to each SSO app via
                username & password (not SSO alone). I understand that otherwise
                we may lose access to apps and lock out our users.
              </Trans>
            </Checkbox>
          )}
        </StyledCenter>
      }
      buttons={[
        <StyledTextButton
          data-qa="ReviewAgainButton"
          key="ReviewAgainButton"
          blue
          onClick={() => dispatch(globalActions.emptyDialog())}
        >
          <Trans>Review again</Trans>
        </StyledTextButton>,
        <StyledPrimaryButton
          data-qa="DoTheUpgradeButton"
          key="DoTheUpgradeButton"
          onClick={() => dispatch(systemUpgradeActions.startUpgrade(appList))}
          disabled={
            hasNoPasswordSsoApp && !systemUpgradeState.isSsoWarningConfirmed
          }
        >
          <Trans>Do the upgrade</Trans>
        </StyledPrimaryButton>
      ]}
    />
  )
}

import { i18n } from '@lingui/core'

import { PolicyCode } from '@lastpass/admin-console-dependencies/types/policies-enum'
import { policyTexts } from '@lastpass/admin-console-dependencies/types/policy-mapping'

export const getPolicyDescription = (
  policyKey: PolicyCode | string,
  fallbackDescription: string | null = null
) => {
  const mappedDescription =
    policyTexts[policyKey] && policyTexts[policyKey].description
  return i18n._(mappedDescription || fallbackDescription || '')
}

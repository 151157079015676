import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { assignAdminLevelUsersActions } from '@lastpass/admin-console-dependencies/state/users/admin/add-users/actions'
import { adminLevelDrawerSegmentActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { BulkActionErrors } from '@lastpass/admin-console-dependencies/types/bulk-action-errors'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserLevel } from '@lastpass/admin-console-dependencies/types/user-level'
import { WithError } from '@lastpass/admin-console-dependencies/types/with-error'

export function createUnassignUsersFromAdminLevelsSaga(
  adminLevelRemoveUsersService: UACServices.Services
) {
  return function* removeUsersFromAdminLevelSaga(
    action: ReturnType<
      typeof assignAdminLevelUsersActions.unassignSelectedUsers
    >
  ) {
    try {
      const selectedUsers = action.payload.users
      const assignUsersTexts =
        selectedUsers.length === 1
          ? {
              success: msg`User has been removed from an admin level: ${selectedUsers[0].email}`,
              fail: msg`User hasn't been removed from an admin level: ${selectedUsers[0].email}`
            }
          : {
              success: msg`The selected users have been removed from an admin level.`,
              fail: msg`None of the selected users have been removed from an admin level. You must have at least one admin. `
            }
      let numberFailed = 0
      let numberSucceeded = 0

      let confirmClick = {
        type: GlobalActionTypes.CONFIRM_DIALOG
      }

      const superAdmins = selectedUsers.filter(
        user => user.adminLevel && user.adminLevel.name === UserLevel.superAdmin
      )

      const superAdminsEmails = superAdmins
        .map(superAdmin => superAdmin.email)
        .join(', ')

      const superAdminDowngradeWarningText: MessageDescriptor =
        selectedUsers.length === 1
          ? msg`This user is a super admin: ${selectedUsers[0].email}. If you confirm this change, they will lose super admin status.`
          : msg`These users are super admins: ${superAdminsEmails}. If you confirm this change, they will lose super admin status.`

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Remove super admin status?`,
        text: superAdminDowngradeWarningText,
        discardText: msg`Cancel`,
        confirmText: msg`Confirm`
      }

      if (superAdmins.length) {
        yield put(globalActions.setDialog(dialog))
        confirmClick = yield take([
          GlobalActionTypes.CONFIRM_DIALOG,
          GlobalActionTypes.DISCARD_DIALOG
        ])
      }

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const result: UACServices.AdminLevelEditUsersAPI.Responses = yield call(
          adminLevelRemoveUsersService.adminLevelEditUsers,
          selectedUsers,
          AdminLevel.admin,
          'DELETE'
        )
        if (result.type === UACServices.AdminLevelEditUsersAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: assignUsersTexts.success,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
          numberSucceeded = selectedUsers.length
        } else if (result.type === UACServices.AdminLevelEditUsersAPI.FAILURE) {
          yield put(
            globalActions.setNotification({
              message: assignUsersTexts.fail,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
          numberFailed = selectedUsers.length
        } else if (
          result.type === UACServices.AdminLevelEditUsersAPI.PARTIAL_SUCCESS
        ) {
          const usersWithErrors: WithError<User>[] = selectedUsers
            .map(user => {
              return { ...user, error: BulkActionErrors.failedToAssign }
            })
            .filter(user => !!user.error)
          yield put(
            globalActions.setNotification({
              message: msg`Some users haven't been removed from the admin level.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'group-user',
                users: usersWithErrors
              }
            })
          )
          numberFailed = usersWithErrors.length
          numberSucceeded = selectedUsers.length - numberFailed
        }

        yield put(
          adminLevelDrawerSegmentActions.usersRemovedFromAdminLevel(
            numberFailed,
            numberSucceeded
          )
        )
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      const queryParams = action.payload.queryParams
      yield put(
        userListActions.getUserList({
          query: queryParams,
          path: {}
        })
      )
      yield put(push('/users/view'))
      yield put(adminListActions.getAdminList())
      yield put(userListActions.getUserList({ query: queryParams, path: {} }))
      yield put(assignAdminLevelUsersActions.clearAdminAssignUserList())
    }
  }
}

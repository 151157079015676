import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { LocationRedirect } from '@lastpass/routing'
import { RouteFade } from '@lastpass/routing/animations'

import { EditGeneralPolicyUsers } from '@lastpass/admin-console-dependencies/ui/policies/general/EditGeneralPolicyUsers'

export function GeneralPoliciesUsersDrawerPage() {
  const match = useRouteMatch()
  if (match) {
    const { url, path } = match
    return (
      <>
        <Route path={path} exact>
          <RouteFade>
            <EditGeneralPolicyUsers assignUserslink={`${url}/assign`} />
          </RouteFade>
        </Route>
        <Route>
          <LocationRedirect to={url} />
        </Route>
      </>
    )
  }
  return null
}

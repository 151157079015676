import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

export enum PasswordlessDrawerSteps {
  description = 'description',
  assign = 'assign',
  edit = 'edit',
  allset = 'allset'
}

export interface GeneralPolicyUsersAndGroupsFormState {
  policyUsersAndGroups: GeneralPolicyUsersAndGroups
  policyConfigUsers: Table<TeamsPolicyUserInfo>
  policyConfigGroups: Table<TeamsPolicyGroup>
  usersList: TeamsPolicyUserInfo[]
  groupsList: TeamsPolicyGroup[]
}

export interface PasswordlessDrawerState {
  policy: TeamsPolicy
  step?: PasswordlessDrawerSteps
  policyCurrentlyAppliesTo?: AppliesToOptions
  assignFormState: GeneralPolicyUsersAndGroupsFormState
  editFormState: GeneralPolicyUsersAndGroupsFormState
  usersAndGroupsNeedsRefresh?: boolean
  isUsersAndGroupsOpen: boolean
  policyDataToSave: TeamsPolicyModelData[]
  searchString?: string
}

import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { BodyRegularStyle, IconButton } from '@lastpass/lastkit'
import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'

import {
  LicenseDrawerDetails,
  LicenseOperationForm
} from './LicenseOperationForm'

const StyledDescription = styled.div`
  ${BodyRegularStyle}
  margin: -30px 0 30px 0;
`

interface AllocateLicensesDrawerProps {
  closeLink: string
  backPath: string
}

export const AllocateLicensesDrawer: FC<AllocateLicensesDrawerProps> = ({
  closeLink,
  backPath
}) => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const managedCompanyList = useSelector(
    (state: AppState) => state.managedCompanies.table.results
  )

  const currentCompanyName = useSelector(
    (state: AppState) => state.companyInfo.details.name
  )

  const managedCompany = managedCompanyList.find(
    company => company.id === Number(id)
  )

  if (!managedCompany) {
    return null
  }

  const sourceDetails: LicenseDrawerDetails = {
    label: msg`From primary account`,
    name: currentCompanyName || ''
  }

  const targetDetails: LicenseDrawerDetails = {
    label: msg`To managed company`,
    name: managedCompany.name
  }

  return (
    <Drawer
      data-qa="ManagedCompanyAllocateLicensesDrawer"
      closeLink={closeLink}
      mainTitle={<>{managedCompany.name}</>}
      subTitle={<Trans>Allocate licenses</Trans>}
      headerNodes={
        <>
          <Link
            to={generatePath(backPath, {
              id
            })}
          >
            <IconButton
              icon={ChevronLeft}
              data-qa="ManagedCompanyAllocateLicensesDrawerBackButton"
            />
          </Link>
        </>
      }
    >
      <StyledDescription>
        <Trans>
          Allocate licenses to the managed company from the primary account. All
          licenses retain the expiration date of the primary account.
        </Trans>
      </StyledDescription>
      <LicenseOperationForm
        closeLink={closeLink}
        sourceDetails={sourceDetails}
        targetDetails={targetDetails}
        valueDescriptionOfAction={msg`Allocated licenses`}
        onSubmit={numberOfLicenses => {
          dispatch(
            managedCompanyOperationActions.allocateLicenses(
              managedCompany.id,
              numberOfLicenses
            )
          )
        }}
      />
    </Drawer>
  )
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { Container, Hash, Subtitle, TitleWrapper } from './AcronisHash'

interface NewHashProps {
  hash: string
}

export const NewHash: React.FunctionComponent<NewHashProps> = ({ hash }) => (
  <Container>
    <TitleWrapper>
      <Subtitle>
        <Trans>Your Acronis hash:</Trans>
      </Subtitle>
      <Hash data-qa="AcronisHashValue">{hash}</Hash>
      <Subtitle>
        <Trans>
          This won{"'"}t be shown again. Copy it and keep it secret.
        </Trans>
      </Subtitle>
    </TitleWrapper>
  </Container>
)

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-users.svg'
import { BodyBold, Heading100 } from '@lastpass/lastkit'

import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 16px;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledUsersIcon = styled(UserIcon)`
  width: 16px;
  margin-right: 8px;
  color: ${props => props.theme.colors.neutral700};
`

const StyledBodyBold = styled(BodyBold)`
  font-size: 12px;
  color: ${props => props.theme.colors.neutral500};
`

export const UsersAndGroups: React.FunctionComponent<{
  inclusiveUsers: TeamsPolicyUserInfo[]
  exclusiveUsers: TeamsPolicyUserInfo[]
  inclusiveGroups: TeamsPolicyGroup[]
  exclusiveGroups: TeamsPolicyGroup[]
}> = ({ inclusiveUsers, exclusiveUsers, inclusiveGroups, exclusiveGroups }) => {
  const isAllEntitiesAreAssigned = () => {
    return (
      exclusiveUsers.length === 0 &&
      inclusiveUsers.length === 0 &&
      inclusiveGroups.length === 0 &&
      exclusiveGroups.length === 0
    )
  }

  const isEveryAssignedEntitiesAreInclusive = () => {
    return inclusiveUsers.length !== 0 || inclusiveGroups.length !== 0
  }

  const isEveryAssignedEntitiesAreExclusive = () => {
    return exclusiveUsers.length !== 0 || exclusiveGroups.length !== 0
  }

  const getText = () => {
    if (isAllEntitiesAreAssigned()) {
      return `all users, all groups`
    }
    if (isEveryAssignedEntitiesAreInclusive()) {
      return `${inclusiveUsers.length} users, ${inclusiveGroups.length} groups`
    }
    if (isEveryAssignedEntitiesAreExclusive()) {
      if (exclusiveUsers.length === 0) {
        return `0 users, all except ${exclusiveGroups.length} groups`
      }
      if (exclusiveGroups.length === 0) {
        return `all except ${exclusiveUsers.length} users, 0 groups`
      }
      return `all except ${exclusiveUsers.length} users, all except ${exclusiveGroups.length} groups`
    }
  }

  return (
    <div>
      <StyledHeading100>
        <Trans>Users {'&'} groups</Trans>
      </StyledHeading100>
      <FlexContainer>
        <StyledUsersIcon />
        <StyledBodyBold data-qa="PolicyAssignmentCounter">
          <Trans>{getText()}</Trans>
        </StyledBodyBold>
      </FlexContainer>
    </div>
  )
}

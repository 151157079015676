import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import {
  BodyRegular,
  Heading300,
  RowContainer,
  SingleColumnContentContainer
} from '@lastpass/lastkit'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { UtilizationData } from '@lastpass/admin-console-dependencies/state/dashboard/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adoptionDashboardActions } from '@lastpass/admin-console-dependencies/state/home/adoption-dashboard/actions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { ActiveUsageRate } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/active-usage-rate/ActiveUsageRate'
import { EnrollmentRate } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/enrollment-rate/EnrollmentRate'
import { LicenseConsumptionRate } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/license-consumption-rate/LicenseConsumptionRate'
import { UrlEncryptionMigrationOverviewCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/url-encryption-migration-overview/UrlEncryptionMigrationOverviewCard'
import {
  AccordionNames,
  adoptionDashboardAccordionFocusLinker
} from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/helpers/adoption-dashboard-accordion-focus-linker'
import { toRoundedPercent } from '@lastpass/admin-console-dependencies/ui/home/utils/converters'
import { UrlLoggingPolicyBanner } from '@lastpass/admin-console-dependencies/ui/policies/general/UrlLoggingPolicyBanner'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`
const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const PageDescription = styled.div`
  align-self: stretch;
  color: ${props => props.theme.colors.neutral900};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const accordionInitialStates = adoptionDashboardAccordionFocusLinker.getAccordionFocusedStates()

export const AdoptionDashboard: FC = () => {
  const isUrlLoggingGracePeriodBannerEnabled = useFeatureFlags(
    FeatureFlags.isUrlLoggingGracePeriodBannerEnabled
  )

  const initialState: UtilizationData = {
    stagedUsersCountWithLicenseConsumption: 0,
    stagedUsersCountWithoutLicenseConsumption: 0,
    availableLicensesCount: 0,
    enrolledUsersCount: 0,
    invitedUsersCountWithLicenseConsumption: 0,
    invitedUsersCountWithoutLicenseConsumption: 0,
    expiredInvitationsCountWithLicenseConsumption: 0,
    expiredInvitationsCountWithoutLicenseConsumption: 0,
    activeUsersCount: 0,
    inactiveUsersCount: 0,
    usersCountWithStarterKitDisabled: 0,
    usersCountWithUnclaimedFaab: 0,
    licenseConsumptionRate: 0,
    enrollmentRate: 0,
    activeUsageRate: 0
  }
  const [
    {
      stagedUsersCountWithLicenseConsumption,
      availableLicensesCount,
      enrolledUsersCount,
      invitedUsersCountWithLicenseConsumption,
      invitedUsersCountWithoutLicenseConsumption,
      expiredInvitationsCountWithLicenseConsumption,
      expiredInvitationsCountWithoutLicenseConsumption,
      activeUsersCount,
      inactiveUsersCount,
      usersCountWithStarterKitDisabled,
      usersCountWithUnclaimedFaab,
      licenseConsumptionRate,
      enrollmentRate,
      activeUsageRate
    },
    setUtilizationData
  ] = useState<UtilizationData>(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const { getUtilizationData } = useContext(ServerContext)

  const isMigrateToVaultFormatVersionV2Enabled = useSelector(
    (state: AppState) =>
      state.dashboard.urlEncryptionMigrationState
        .isMigrateToVaultFormatVersionV2Enabled
  )

  useEffect(() => {
    dispatch(dashboardActions.getUrlEncryptionMigrationState())
    dispatch(dashboardActions.getDisplaySettings())
    dispatch(adoptionDashboardActions.adoptionDashboardViewed())
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const response = await getUtilizationData()
        setUtilizationData(response.body)
      } catch {
        dispatch(globalActions.setNotification(genericFailedNotification))
      }
      setIsLoading(false)
    })()
  }, [getUtilizationData, dispatch])

  const licensesConsumedUponInvitation =
    enrolledUsersCount +
    invitedUsersCountWithLicenseConsumption +
    expiredInvitationsCountWithLicenseConsumption

  const licensesConsumed =
    licensesConsumedUponInvitation + stagedUsersCountWithLicenseConsumption

  const licenseCount = licensesConsumed + availableLicensesCount

  const totalInvitationsSent =
    licensesConsumedUponInvitation +
    expiredInvitationsCountWithoutLicenseConsumption +
    invitedUsersCountWithoutLicenseConsumption

  const totalInvitedUsersCount =
    invitedUsersCountWithLicenseConsumption +
    invitedUsersCountWithoutLicenseConsumption

  const totalExpiredInvitationsCount =
    expiredInvitationsCountWithLicenseConsumption +
    expiredInvitationsCountWithoutLicenseConsumption

  return (
    <SingleColumnContentContainer>
      {isLoading && <Loading color="blue900" active />}
      <Container>
        {isUrlLoggingGracePeriodBannerEnabled && (
          <div
            css={css`
              margin-bottom: 2rem;
            `}
          >
            <UrlLoggingPolicyBanner />
          </div>
        )}
        {isMigrateToVaultFormatVersionV2Enabled && (
          <RowContainer>
            <UrlEncryptionMigrationOverviewCard />
          </RowContainer>
        )}
        <RowContainer>
          <div>
            <Heading300 data-qa="AdoptionDashboardHeader">
              <Trans>Adoption dashboard</Trans>
            </Heading300>
            <PageDescription />
            <BodyRegular>
              <Trans>
                Take action to generate maximum security and convenience from
                your investment in LastPass.
              </Trans>
            </BodyRegular>
          </div>
        </RowContainer>
        <AccordionContainer>
          <LicenseConsumptionRate
            rate={toRoundedPercent(licenseConsumptionRate)}
            stagedUsersCount={stagedUsersCountWithLicenseConsumption}
            availableLicensesCount={availableLicensesCount}
            licenseCount={licenseCount}
            licensesConsumed={licensesConsumed}
            isOpen={
              accordionInitialStates[AccordionNames.licenseConsumptionRate]
            }
          />
          <EnrollmentRate
            rate={toRoundedPercent(enrollmentRate)}
            enrolledUsersCount={enrolledUsersCount}
            invitedUsersCount={totalInvitedUsersCount}
            expiredInvitationsCount={totalExpiredInvitationsCount}
            totalInvitationsSent={totalInvitationsSent}
            isOpen={accordionInitialStates[AccordionNames.enrollmentRate]}
          />
          <ActiveUsageRate
            rate={toRoundedPercent(activeUsageRate)}
            activeUsersCount={activeUsersCount}
            inactiveUsersCount={inactiveUsersCount}
            enrolledUsersCount={enrolledUsersCount}
            usersCountWithStarterKitDisabled={usersCountWithStarterKitDisabled}
            usersCountWithUnclaimedFaab={usersCountWithUnclaimedFaab}
            isOpen={accordionInitialStates[AccordionNames.activeUsageRate]}
          />
        </AccordionContainer>
      </Container>
    </SingleColumnContentContainer>
  )
}

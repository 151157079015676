import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { mfaPoliciesKeys } from '@lastpass/admin-console-dependencies/resources/mfa-policies-keys'
import { processFailedResult } from '@lastpass/admin-console-dependencies/sagas/applications/saml/application-common'
import { getCurrentUrlFilters } from '@lastpass/admin-console-dependencies/sagas/applications/saml/save-application-saga'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/save-application-server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import {
  applicationsDrawerActions,
  applicationsDrawerSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { applicationListActions } from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { mapGetPolicyListResultToPolicyMap } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

export const getActualDrawerStep = (state: AppState) =>
  state.applicationDrawer.actualStep

function* are2FAPoliciesEnabled(applicationsServices: UACServices.Services) {
  const generalPoliciesListResult: UACServices.GeneralPoliciesListGetAPI.Responses = yield call(
    applicationsServices.generalPoliciesListGet
  )

  if (
    generalPoliciesListResult.type ===
    UACServices.GeneralPoliciesListGetAPI.SUCCESS
  ) {
    const generalPoliciesResponse =
      generalPoliciesListResult.body.generalPoliciesListResponse

    const [
      generalPoliciesMap,
      generalPoliciesList
    ] = mapGetPolicyListResultToPolicyMap(generalPoliciesResponse)

    yield put(
      generalPoliciesListActions.setGeneralPoliciesList(
        generalPoliciesList,
        generalPoliciesMap,
        generalPoliciesList.length,
        generalPoliciesList.length
      )
    )

    return (
      generalPoliciesList.filter(
        policy => policy.checked && mfaPoliciesKeys.includes(policy.policyKey)
      ).length > 0
    )
  }
}

export function createAssignToApplication(
  applicationsServices: UACServices.Services
) {
  return function* assignToApplication(
    action: ReturnType<
      typeof applicationsDrawerSegmentActions.saveAndAssignUsers
    >
  ) {
    try {
      const currentUrlFilters = yield select(getCurrentUrlFilters)

      yield put(applicationsDrawerActions.setDrawerLoading(true))

      try {
        const assignResponse: Responses = yield call(
          applicationsServices.assignToApplication,
          action.payload.selectedApp.id,
          action.payload.users,
          action.payload.groups,
          action.payload.roles
        )

        if (assignResponse.type === UACServices.SSOApplicationsAPI.SUCCESS) {
          if (assignResponse.body.response.Succeeded) {
            yield put(
              globalActions.setNotification({
                message: msg`App changes saved.`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )

            const are2FaPoliciesEnabled = yield are2FAPoliciesEnabled(
              applicationsServices
            )

            const areUsersOrGroupsAssigned =
              action.payload.users.length > 0 ||
              action.payload.groups.length > 0

            const actualStep: ApplicationDrawerSteps = yield select(
              getActualDrawerStep
            )
            if (actualStep == ApplicationDrawerSteps.assignTestUser) {
              yield put(
                applicationsDrawerActions.getAssignedElements(
                  action.payload.selectedApp.id
                )
              )
              yield put(
                applicationsDrawerActions.nextStep(
                  ApplicationDrawerSteps.testLogin
                )
              )
            } else if (!are2FaPoliciesEnabled && areUsersOrGroupsAssigned) {
              yield put(
                applicationsDrawerActions.nextStep(
                  ApplicationDrawerSteps.enableMultifactor
                )
              )
            } else {
              yield put(
                applicationsDrawerActions.nextStep(
                  ApplicationDrawerSteps.allset
                )
              )
            }

            yield put(
              applicationListActions.getApplicationList({
                query: getQueryParams(currentUrlFilters.location, 'apps'),
                path: {}
              })
            )
          } else {
            yield processFailedResult(assignResponse.body.response.ErrorCode)
          }
        }
      } finally {
        yield put(applicationsDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

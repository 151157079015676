import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { assignGroupUsersActions } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/actions'
import { groupListActions } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { WithError } from '@lastpass/admin-console-dependencies/types/with-error'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createAddUsersToGroupSaga(
  groupAddSelectedUsersService: UACServices.Services
) {
  return function* addUsersToGroupSaga(
    action: ReturnType<typeof assignGroupUsersActions.addUsersToGroup>
  ) {
    try {
      const selectedUsers = action.payload.userList
      const group = action.payload.group
      const assignUsersTexts =
        selectedUsers.length === 1
          ? {
              success: msg`User has been added to the group: ${selectedUsers[0].email}`,
              fail: msg`User hasn't been added to group:  ${selectedUsers[0].email}`
            }
          : {
              success: msg`The selected users have been added to the group. `,
              fail: msg`None of the selected users have been added to the group. `
            }
      const currentUrlFilters = yield select(getCurrentUrlFilters)
      try {
        const result: UACServices.GroupEditUsersAPI.Responses = yield call(
          groupAddSelectedUsersService.groupEditUsers,
          selectedUsers,
          group,
          'POST'
        )
        if (result.type === UACServices.GroupEditUsersAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: assignUsersTexts.success,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.GroupEditUsersAPI.FAIL) {
          yield put(
            globalActions.setNotification({
              message: assignUsersTexts.fail,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        } else if (
          result.type === UACServices.GroupEditUsersAPI.PARTIAL_SUCCESS
        ) {
          const errorMessages = result.body.failedUsers
          const usersWithErrors: WithError<User>[] = selectedUsers
            .map(user => {
              return { ...user, error: errorMessages[user.id] }
            })
            .filter(user => !!user.error)

          yield put(
            globalActions.setNotification({
              message: msg`Some users haven't been added to the group.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'group-user',
                users: usersWithErrors
              }
            })
          )
        }
      } finally {
        yield put(assignGroupUsersActions.clearGroupDetails())
        yield put(
          groupListActions.getGroupsList({
            query: getQueryParams(currentUrlFilters.location, 'groups'),
            path: {}
          })
        )
        yield put(assignGroupUsersActions.clearGroupAssignUserList())
        yield put(assignGroupUsersActions.clearGroupDetails())
        yield put(
          push(`/users/groups/${group.id}${currentUrlFilters.location.search}`)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import {
  RSASecureIDRadiusActions,
  RSASecureIDRadiusActionTypes
} from './actions'
import { RSASecureIDRadiusState } from './state'

export const initialState: RSASecureIDRadiusState = {
  rsaSecureIDRadiusSettings: {
    radiusServerIp: '',
    radiusSharedSecret: '',
    radiusSharedSecretLength: 0,
    hasRadiusSharedSecret: false,
    radiusTimeout: 0,
    failureMessage: '',
    serviceName: '',
    logo124x124: '',
    logo190x41: '',
    logo124x124Length: 0,
    logo190x41Length: 0
  }
}

export const rsaSecureIDRadiusReducer = (
  previousState: RSASecureIDRadiusState | undefined,
  action: RSASecureIDRadiusActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_SET:
      return {
        ...state,
        rsaSecureIDRadiusSettings: { ...action.payload.rsaOptions }
      }
    case RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    default:
      return state
  }
}

export enum AppliesToOptions {
  all = 'all',
  inclusive = 'inclusive',
  exclusive = 'exclusive'
}

export enum Entities {
  availableUsers = 'availableUsers',
  filteredUsers = 'filteredUsers',
  availableGroups = 'availableGroups',
  filteredGroups = 'filteredGroups',

  users = 'users',
  groups = 'groups'
}

export enum Table {
  list = 'list',
  available = 'available',
  filtered = 'filtered'
}

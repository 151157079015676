import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

const FirstColumn = styled.div`
  flex-basis: 60%;
  flex-grow: 2;
`

const SecondColumn = styled.div`
  flex-basis: 30%;
  flex-grow: 1;
`

const ThirdColumn = styled.div`
  flex-basis: 30%;
  flex-grow: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  font-size: 12px;
  padding-left: 50px;
  padding-bottom: 9px;
  font-weight: 600;
`

export const PermissionTreeHeader: React.FunctionComponent = () => (
  <Row>
    <FirstColumn>
      <Trans>Category</Trans>
    </FirstColumn>
    <SecondColumn>
      <Trans>View</Trans>
    </SecondColumn>
    <ThirdColumn>
      <Trans>Modify</Trans>
    </ThirdColumn>
  </Row>
)

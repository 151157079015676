import React, { FunctionComponent } from 'react'

import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { Select, SelectProps } from '@lastpass/lastkit'

import { User } from '@lastpass/admin-console-dependencies/types/user'

interface SelectIsCreatedByCompanyProps {
  updateSelection: (isCreatedByCompany: [User['isCreatedByCompany']]) => void
  filters?: [User['isCreatedByCompany']]
}

export const SelectIsCreatedByCompany: FunctionComponent<SelectIsCreatedByCompanyProps> = ({
  updateSelection,
  filters
}) => {
  const { _ } = useLingui()
  const options: SelectProps['options'] = [
    {
      value: String(true),
      label: _(msg`Yes`)
    },
    {
      value: String(false),
      label: _(msg`No`)
    },
    {
      value: String(null),
      label: _(msg`N/A`)
    }
  ]

  const value: SelectProps['value'] = options.find(
    option => option.value === String(filters && filters[0])
  )

  return (
    <Select
      dataQa="IsCreatedByCompanyDropdown"
      options={options}
      value={value}
      hideSelectedOptions={false}
      placeholder={<Trans>Created by company</Trans>}
      onChange={selectedItem => {
        if (selectedItem) {
          let updatedValue: boolean | null
          switch (selectedItem['value']) {
            case String(true):
              updatedValue = true
              break
            case String(false):
              updatedValue = false
              break
            default:
              updatedValue = null
          }

          updateSelection([updatedValue])
        }
      }}
      fluid
    >
      <Trans>Created by company</Trans>
    </Select>
  )
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import * as Types from '@lastpass/admin-console-dependencies/types/creation'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export interface Response<T extends string> {
  type: T
}

export interface ResponseWithBody<T extends string, B> extends Response<T> {
  body: B
}

export function createResponse<T extends string>(type: T): Response<T>
export function createResponse<T extends string, B>(
  type: T,
  body: B
): ResponseWithBody<T, B>
export function createResponse<T extends string, B>(type: T, body?: B) {
  return body === undefined ? { type } : { type, body }
}

export class ServiceError extends Error {
  public toString(): string {
    return this.message
  }
}

export class SagaError extends Error {
  private messgeDesc: MessageDescriptor
  constructor(messgeDesc: MessageDescriptor) {
    super(messgeDesc.id)
    this.messgeDesc = messgeDesc
  }
  public getMessageDescriptor(): MessageDescriptor {
    return this.messgeDesc
  }
}

export const unauthorizedError = '401 Unauthorized'

export class UnauthorizedServiceError extends Error {
  constructor() {
    super()
    return new Error(unauthorizedError)
  }
}

export const forbiddenError = '403 Forbidden'

export class ForbiddenServiceError extends Error {
  constructor() {
    super()
    return new Error(forbiddenError)
  }
}

export class UnknownServiceError extends Error {}

export class ServiceBusyError extends Error {}

export type ResponseTypes<
  A extends Types.CreatorMapObject
> = Types.CreationUnion<A>

export const genericFailedNotification: NotificationProps = {
  type: NotificationType.alert,
  autoDismiss: false,
  details: undefined,
  message: msg`Something went wrong. Please try again.`,
  genericError: true
}

import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import { PasswordlessDrawerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getPasswordlessDrawerState = (state: AppState) =>
  state.passwordlessDrawer

export const getPolicyConfigUsersList = (
  policyData: TeamsPolicyModelData,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyUserInfo[] => {
  let usersList: TeamsPolicyUserInfo[] = []
  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData.appliesTo
  ) {
    return usersList
  }
  if (policyData.appliesTo === AppliesToOptions.inclusive) {
    usersList = policyData.inclusiveList ? policyData.inclusiveList : []
  } else if (policyData.appliesTo === AppliesToOptions.exclusive) {
    usersList = policyData.exclusiveList ? policyData.exclusiveList : []
  }
  return usersList
}
export const getPolicyConfigGroupsList = (
  policyData: TeamsPolicyModelData,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyGroup[] => {
  let groupsList: TeamsPolicyGroup[] = []
  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData.appliesTo
  ) {
    return groupsList
  }
  if (policyData.appliesTo === AppliesToOptions.inclusive) {
    groupsList = policyData.inclusiveGroupList
      ? policyData.inclusiveGroupList
      : []
  } else if (policyData.appliesTo === AppliesToOptions.exclusive) {
    groupsList = policyData.exclusiveGroupList
      ? policyData.exclusiveGroupList
      : []
  }
  return groupsList
}

const applyUsersSearchFilter = (
  usersList: TeamsPolicyUserInfo[],
  searchFilter?: string
): TeamsPolicyUserInfo[] => {
  let filteredUsersList: TeamsPolicyUserInfo[] = []
  if (searchFilter) {
    filteredUsersList = usersList.filter(user => {
      const hasName =
        user.name &&
        user.name.toLowerCase().includes(searchFilter.toLowerCase())
          ? true
          : false
      const hasEmail: boolean =
        user.email &&
        user.email.toLowerCase().includes(searchFilter.toLowerCase())
          ? true
          : false
      return hasName || hasEmail
    })
  }
  return searchFilter ? filteredUsersList : usersList
}

const applyGroupsSearchFilter = (
  groupsList: TeamsPolicyGroup[],
  searchFilter?: string
): TeamsPolicyGroup[] => {
  let filteredGroupsList: TeamsPolicyGroup[] = []
  if (searchFilter) {
    filteredGroupsList = groupsList.filter(user => {
      const hasName =
        user.name &&
        user.name.toLowerCase().includes(searchFilter.toLowerCase())
          ? true
          : false
      return hasName
    })
  }
  return searchFilter ? filteredGroupsList : groupsList
}

export function createGetPolicyUsersAndGroupsSaga() {
  return function* getPolicyUsersAndGroupsSaga(
    action: ReturnType<typeof passwordlessDrawerActions.getPolicyUsersAndGroups>
  ) {
    try {
      const searchFilter = action.payload.searchString

      yield put(passwordlessDrawerActions.setPolicyUsersAndGroupsOpen(true))
      const drawerState: PasswordlessDrawerState = yield select(
        getPasswordlessDrawerState
      )

      const policy = drawerState.policy
      const policyCurrentlyAppliesTo = drawerState.policyCurrentlyAppliesTo
      const policyConfig: TeamsPolicyModelData =
        policy.policyData && policy.policyData.length > 0
          ? policy.policyData[0]
          : {
              appliesTo: AppliesToOptions.all,
              policyValue: null,
              policyNotes: '',
              cpid: null
            }
      const policyUsers = getPolicyConfigUsersList(
        policyConfig,
        policyCurrentlyAppliesTo
      )
      const policyGroups = getPolicyConfigGroupsList(
        policyConfig,
        policyCurrentlyAppliesTo
      )

      const policyUsersSearchFilter = applyUsersSearchFilter(
        policyUsers,
        searchFilter
      )
      const policyGroupsSearchFilter = applyGroupsSearchFilter(
        policyGroups,
        searchFilter
      )
      const policyConfigUsersAndGroups: GeneralPolicyUsersAndGroups = {
        policyKey: policy.policyKey,
        policyConfigIndex: 0,
        policyConfigFormData: {
          appliesTo: policyConfig.appliesTo,
          users: policyUsersSearchFilter,
          groups: policyGroupsSearchFilter
        }
      }
      yield put(
        passwordlessDrawerActions.setPolicyUsersAndGroups(
          policyConfigUsersAndGroups
        )
      )
      yield put(
        passwordlessDrawerActions.setPolicyUsersAndGroupsNeedsRefresh(false)
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPoliciesDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { GeneralPoliciesListActionTypes } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPolicyAssignUsersAndGroupsActionTypes } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicySettingsActionTypes } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/actions'
import { GeneralPolicyUsersAndGroupsActionTypes } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'

import { createAddConfigGeneralPolicySaga } from './drawer/add-config-general-policy'
import { createDisableGeneralPolicySaga } from './drawer/disable-general-policy'
import { createEnableAdminPolicySaga } from './drawer/enable-admin-policy'
import { createEnableGeneralPolicySaga } from './drawer/enable-general-policy'
import { createGetAdminUsersSaga } from './drawer/get-admin-users'
import { createGetGeneralPolicySaga } from './drawer/get-general-policy'
import { createRemoveConfigGeneralPolicySaga } from './drawer/remove-config-general-policy'
import { createGetGeneralPoliciesList } from './list/get-general-policies-list'
import { createUpdateGeneralPoliciesDrawers } from './list/update-general-policies-drawers'
import { createCheckPolicyAssignAppliesToGeneralPolicySaga } from './policy-assign-users/check-general-policy-assign-applies-to'
import { createGetGeneralPolicyAssignUsersAndGroupsSaga } from './policy-assign-users/get-general-policy-assign-users'
import { createSaveGeneralPolicyAssignUsersAndGroupsSaga } from './policy-assign-users/save-general-policy-assign-users'
import { createSearchAssignUsersAndGroupsGeneralPolicySaga } from './policy-assign-users/search-general-policy-assign-users-and-groups'
import { createGetGeneralPolicySettingsSaga } from './policy-settings/get-general-policy-settings'
import { createSaveGeneralPolicySettingsSaga } from './policy-settings/save-general-policy-settings'
import { createCheckPolicyAppliesToGeneralPolicySaga } from './policy-users/check-general-policy-applies-to'
import { createGetGeneralPolicyUsersAndGroupsSaga } from './policy-users/get-general-policy-users'
import { createInitiatePolicyAppliesToForAssignGeneralPolicySaga } from './policy-users/initiate-general-policy-applies-to'
import { createSaveGeneralPolicyUsersAndGroupsSaga } from './policy-users/save-general-policy-users'

export function createGeneralPoliciesSaga(
  generalPoliciesService: UACServices.Services
) {
  const getGeneralPoliciesListSaga = createGetGeneralPoliciesList(
    generalPoliciesService
  )
  const getGeneralPolicySaga = createGetGeneralPolicySaga()
  const saveGeneralPolicySettingsSaga = createSaveGeneralPolicySettingsSaga()
  const getGeneralPolicySettingsSaga = createGetGeneralPolicySettingsSaga()
  const getGeneralPolicyUsersAndGroupsSaga = createGetGeneralPolicyUsersAndGroupsSaga()
  const saveGeneralPolicyUsersAndGroupsSaga = createSaveGeneralPolicyUsersAndGroupsSaga()
  const checkGeneralPolicyAppliesToSaga = createCheckPolicyAppliesToGeneralPolicySaga()

  const searchAssignGeneralPolicyUsersAndGroupsSaga = createSearchAssignUsersAndGroupsGeneralPolicySaga(
    generalPoliciesService
  )
  const getGeneralPolicyAssignUsersAndGroupsSaga = createGetGeneralPolicyAssignUsersAndGroupsSaga()
  const saveGeneralPolicyAssignUsersAndGroupsSaga = createSaveGeneralPolicyAssignUsersAndGroupsSaga()
  const checkGeneralPolicyAssignAppliesToSaga = createCheckPolicyAssignAppliesToGeneralPolicySaga()
  const initiateGeneralPolicyAppliesToForAssignSaga = createInitiatePolicyAppliesToForAssignGeneralPolicySaga()

  const updateGeneralPoliciesDrawersSaga = createUpdateGeneralPoliciesDrawers()
  const enableGeneralPolicySaga = createEnableGeneralPolicySaga(
    generalPoliciesService
  )
  const disableGeneralPolicySaga = createDisableGeneralPolicySaga(
    generalPoliciesService
  )
  const addConfigGeneralPolicySaga = createAddConfigGeneralPolicySaga(
    generalPoliciesService
  )
  const removeConfigGeneralPolicySaga = createRemoveConfigGeneralPolicySaga(
    generalPoliciesService
  )
  const getAdminUsersSaga = createGetAdminUsersSaga(generalPoliciesService)
  const enableAdminPolicySaga = createEnableAdminPolicySaga(
    generalPoliciesService
  )

  return function*() {
    yield takeEvery(
      GeneralPoliciesListActionTypes.GET_GENERAL_POLICIES_LIST,
      getGeneralPoliciesListSaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.GET_CURRENT_GENERAL_POLICY,
      getGeneralPolicySaga
    )
    yield takeEvery(
      GeneralPolicySettingsActionTypes.SAVE_GENERAL_POLICY_SETTINGS,
      saveGeneralPolicySettingsSaga
    )
    yield takeEvery(
      GeneralPolicySettingsActionTypes.GET_GENERAL_POLICY_SETTINGS,
      getGeneralPolicySettingsSaga
    )
    yield takeEvery(
      GeneralPolicyUsersAndGroupsActionTypes.GET_GENERAL_POLICY_USERS_AND_GROUPS,
      getGeneralPolicyUsersAndGroupsSaga
    )
    yield takeEvery(
      GeneralPolicyUsersAndGroupsActionTypes.SAVE_GENERAL_POLICY_USERS_AND_GROUPS,
      saveGeneralPolicyUsersAndGroupsSaga
    )
    yield takeEvery(
      GeneralPolicyUsersAndGroupsActionTypes.CHECK_GENERAL_POLICY_APPLIES_TO,
      checkGeneralPolicyAppliesToSaga
    )
    yield takeEvery(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SEARCH_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      searchAssignGeneralPolicyUsersAndGroupsSaga
    )
    yield takeEvery(
      GeneralPolicyAssignUsersAndGroupsActionTypes.GET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      getGeneralPolicyAssignUsersAndGroupsSaga
    )
    yield takeEvery(
      GeneralPolicyAssignUsersAndGroupsActionTypes.SAVE_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS,
      saveGeneralPolicyAssignUsersAndGroupsSaga
    )
    yield takeEvery(
      GeneralPolicyAssignUsersAndGroupsActionTypes.CHECK_GENERAL_POLICY_APPLIES_TO,
      checkGeneralPolicyAssignAppliesToSaga
    )
    yield takeEvery(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_APPLIES_TO_FOR_ASSIGN,
      initiateGeneralPolicyAppliesToForAssignSaga
    )
    yield takeEvery(
      GeneralPoliciesListActionTypes.UPDATE_GENERAL_POLICIES_DRAWERS,
      updateGeneralPoliciesDrawersSaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.ENABLE_CURRENT_GENERAL_POLICY,
      enableGeneralPolicySaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.DISABLE_CURRENT_GENERAL_POLICY,
      disableGeneralPolicySaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.ADD_CONFIG_CURRENT_GENERAL_POLICY,
      addConfigGeneralPolicySaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.REMOVE_CONFIG_CURRENT_GENERAL_POLICY,
      removeConfigGeneralPolicySaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.GET_ADMIN_LIST,
      getAdminUsersSaga
    )
    yield takeEvery(
      GeneralPoliciesDrawerActionTypes.ENABLE_ADMIN_POLICY,
      enableAdminPolicySaga
    )
  }
}

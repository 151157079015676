import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createPsaCredentialsSaga(services: UACServices.Services) {
  return function* getPsaCredentialsSaga() {
    try {
      yield put(psaIntegrationsActions.setPsaInProgress(true))

      const response: UACServices.GetPsaCredentialsAPI.Responses = yield call(
        services.getPsaCredentials
      )
      yield put(
        psaIntegrationsActions.setPsaCredentials(response.body.metadata)
      )
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(psaIntegrationsActions.setPsaInProgress(false))
    }
  }
}

import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum AddNewGroupActionTypes {
  RESET = '[new-group-drawer] reset',
  SET_NEW_GROUP_ID = '[new-group-id] set',
  SET_NEW_GROUP_VALIDITY_SUCCESS = '[new-group-is-validity-success] set',
  SET_NEW_GROUP_VALIDITY_FAIL = '[new-group-is-validity-fail] set',
  SET_NEW_GROUP_ISLOADING = '[new-group-is-loading] set',
  ADD_NEW_GROUP = '[new-group] add'
}

export const addNewGroupActions = {
  reset: () => createAction(AddNewGroupActionTypes.RESET),

  setNewGroupId: (id: string) =>
    createAction(AddNewGroupActionTypes.SET_NEW_GROUP_ID, { id }),

  setNewGroupValiditySuccess: (validitySuccess: boolean) =>
    createAction(AddNewGroupActionTypes.SET_NEW_GROUP_VALIDITY_SUCCESS, {
      validitySuccess
    }),

  setNewGroupValidityFail: (validityFail: boolean) =>
    createAction(AddNewGroupActionTypes.SET_NEW_GROUP_VALIDITY_FAIL, {
      validityFail
    }),

  setNewGroupIsLoading: (isLoading: boolean) =>
    createAction(AddNewGroupActionTypes.SET_NEW_GROUP_ISLOADING, { isLoading }),

  addNewGroup: (name: string, showAssignUsersNext: boolean) =>
    track(
      createAction(AddNewGroupActionTypes.ADD_NEW_GROUP, {
        name,
        showAssignUsersNext
      }),
      {
        segment: uacEvent('Save New Group Clicked', {
          Action: showAssignUsersNext ? 'Save and Assign' : 'Save'
        })
      }
    )
}

export type AddNewGroupActions = ActionsUnion<typeof addNewGroupActions>

import React from 'react'

import { Trans } from '@lingui/macro'
import { History } from 'history'

import { openEnterpriseUsersWindow } from '@lastpass/admin-console-dependencies/services/user-operations/open-enterprise-users-window'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userOperationActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { OperationOriginType } from '@lastpass/admin-console-dependencies/state/users/view/operations/operation-origin-types'
import { UserOperationsType } from '@lastpass/admin-console-dependencies/state/users/view/operations/user-operations'
import { TableAction } from '@lastpass/admin-console-dependencies/types/record-action'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'
import { AssignAdminLevelDropdown } from '@lastpass/admin-console-dependencies/ui/users/view/assignAdminLevelDropdown/AssignAdminLevelDropdown'

import { EnumDictionary } from '../EnumValues'

const actionNotImplemented = 'action is not implemented'
const colorRed = 'red700'

interface UserOperationsMapping {
  (params: {
    dispatch
    userOperationActions: typeof userOperationActions
    origin: OperationOriginType
    history: History
    filter?: UserFilter
    cidOverrideChildCompanyId?: string
    isPasswordlessEnabledOrUsed: boolean
    isForceVaultReEncryptionEnabled: boolean
    areMultipleUsersSelected: boolean
  }): EnumDictionary<UserOperationsType, TableAction<User>>
}

export const userOperationsMapping: UserOperationsMapping = ({
  dispatch,
  userOperationActions,
  origin,
  history,
  filter,
  cidOverrideChildCompanyId,
  isPasswordlessEnabledOrUsed,
  isForceVaultReEncryptionEnabled,
  areMultipleUsersSelected
}) => {
  return {
    [UserOperationsType.inviteUser]: {
      name: <Trans>Invite</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.inviteUsersAction({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'inviteUser'
    },
    [UserOperationsType.reinviteUser]: {
      name: <Trans>Resend invitation</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.reInviteUsersAction({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'reinviteUser'
    },
    [UserOperationsType.assignAdminLevels]: {
      name: <Trans>Assign admin level</Trans>,
      callback: () => {},
      dropdown: true,
      content: <AssignAdminLevelDropdown />,
      tag: 'assignAdminLevels'
    },
    [UserOperationsType.resendLPMFAInvitation]: {
      name: <Trans>Resend invitation for passwordless</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.reSendLPMFAInvitation({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      hidden: !isPasswordlessEnabledOrUsed,
      tag: 'resendLPMFAInvitation'
    },
    [UserOperationsType.disableMultifactor]: {
      name: <Trans>Disable multifactor</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.disableMultifactor({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'disableMultifactor'
    },
    [UserOperationsType.setInitialPassword]: {
      name: <Trans>Set Initial Password</Trans>,
      callback: () => {
        throw new Error(actionNotImplemented)
      },
      tag: 'setInitialPassword'
    },
    [UserOperationsType.requireMasterPasswordChange]: {
      name: <Trans>Require master password change</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.requirePasswordChange({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'requireMasterPasswordChange'
    },
    [UserOperationsType.superAdminMasterPasswordReset]: {
      name: <Trans>Super Admin Master Password Reset</Trans>,
      callback: () => {
        throw new Error(actionNotImplemented)
      },
      tag: 'superAdminMasterPasswordReset'
    },
    [UserOperationsType.destroyAllSessionsForSelectedUsers]: {
      name: <Trans>Destroy all sessions</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.destroyAllSessionsForSelectedUsers({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'destroyAllSessionsForSelectedUsers'
    },
    [UserOperationsType.lockDeviceForLPMFA]: {
      name: <Trans>Lock LastPass MFA device</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.lockDeviceForLPMFAAction({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      hidden: !isPasswordlessEnabledOrUsed,
      tag: 'lockDeviceForLPMFA'
    },
    [UserOperationsType.unlockDeviceForLPMFA]: {
      name: <Trans>Unlock LastPass MFA device</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.unLockDeviceForLPMFAAction({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      hidden: !isPasswordlessEnabledOrUsed,
      tag: 'unlockDeviceForLPMFA'
    },
    [UserOperationsType.removeSelectedUsersFromCompany]: {
      name: <Trans>Remove from company</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.removeUsersFromCompany({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'removeSelectedUsersFromCompany'
    },
    [UserOperationsType.activateUsers]: {
      name: areMultipleUsersSelected ? (
        <Trans>Activate users</Trans>
      ) : (
        <Trans>Activate user</Trans>
      ),
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.activateUsers({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'activateUsers'
    },
    [UserOperationsType.acceptPendingUser]: {
      name: areMultipleUsersSelected ? (
        <Trans>Approve users</Trans>
      ) : (
        <Trans>Approve user</Trans>
      ),
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.acceptUsers({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'acceptPendingUser'
    },
    [UserOperationsType.rejectPendingUser]: {
      name: areMultipleUsersSelected ? (
        <Trans>Reject users</Trans>
      ) : (
        <Trans>Reject user</Trans>
      ),
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.rejectUsers({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'rejectPendingUser'
    },
    [UserOperationsType.resetMasterPassword]: {
      name: <Trans>Reset master password</Trans>,
      callback: user => {
        if (user[0].lastPassUserId) {
          openEnterpriseUsersWindow({
            teamspwchange: String(user[0].lastPassUserId),
            ...(cidOverrideChildCompanyId && {
              childcid: cidOverrideChildCompanyId
            })
          })
        }
      },
      singleItem: true,
      tag: 'resetMasterPassword'
    },
    [UserOperationsType.distributeRecoveryKey]: {
      name: <Trans>Share recovery keys invisibly</Trans>,
      callback: (users, isAllSelected) => {
        dispatch(
          globalActions.setDialogWithSegment(
            {
              type: 'recovery-key-sharing-confirm-dialog',
              users,
              origin,
              isAllSelected: !!isAllSelected,
              filter
            },
            {
              name: 'Share Recovery Keys Invisibly Clicked',
              trackingData: {
                Source:
                  origin === OperationOriginType.Drawer
                    ? 'User Detail'
                    : 'Users Overview'
              }
            }
          )
        )
      },
      singleItem: true,
      tag: 'distributeRecoveryKey'
    },
    [UserOperationsType.transferVault]: {
      name: <Trans>Transfer vault and delete user</Trans>,
      callback: user => {
        history.push(`/users/view/${user[0].id}/transfer-vault`)
      },
      singleItem: true,
      tag: 'transferVault'
    },
    [UserOperationsType.selectForFederation]: {
      name: <Trans>Enable federated login</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.selectForFederation({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'selectForFederation'
    },
    [UserOperationsType.deselectForFederation]: {
      name: <Trans>Disable federated login</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.deselectForFederation({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      tag: 'deselectForFederation'
    },
    [UserOperationsType.selectForVaultReEncryption]: {
      name: <Trans>Force vault re-encryption</Trans>,
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.selectForVaultReEncryption({
            users,
            origin,
            isAllSelected: !!isAllSelected,
            filter
          })
        ),
      hidden: !isForceVaultReEncryptionEnabled,
      tag: 'selectForVaultReEncryption'
    },
    [UserOperationsType.disableUsers]: {
      name: areMultipleUsersSelected ? (
        <Trans>Disable users</Trans>
      ) : (
        <Trans>Disable user</Trans>
      ),
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.disableUsers({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      isCritical: true,
      tag: 'disableUsers'
    },
    [UserOperationsType.deleteSelectedUsers]: {
      name: areMultipleUsersSelected ? (
        <Trans>Delete users</Trans>
      ) : (
        <Trans>Delete user</Trans>
      ),
      callback: (users, isAllSelected) =>
        dispatch(
          userOperationActions.deleteUsers({
            users: users,
            origin: origin,
            isAllSelected: isAllSelected === true,
            filter: filter
          })
        ),
      color: colorRed,
      isCritical: true,
      tag: 'deleteSelectedUsers'
    }
  }
}

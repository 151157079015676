import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  AdvancedUserActivityEvent,
  TeamsApiAdvancedUserEventType
} from '@lastpass/admin-console-dependencies/types/advanced-user-activity'
import {
  AdvancedUserActivityEventTypes,
  AdvancedUserActivityFilter,
  AdvancedUserActivityReport
} from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export enum AdvancedUserActivityActionTypes {
  ADVANCED_REPORT_SET_USER_ACTIVITY_LOADING = '[advanced-reports] user-activity-loading-set',
  ADVANCED_REPORT_GET_USER_ACTIVITY = '[advanced-reports] user-activity-get',
  ADVANCED_REPORT_SET_USER_ACTIVITY = '[advanced-reports] user-activity-set',
  ADVANCED_REPORT_GET_USER_FOR_EVENT = '[advanced-reports] get-user-for-event',
  ADVANCED_REPORT_SET_USER_FOR_EVENT = '[advanced-reports] set-user-for-event',
  ADVANCED_REPORT_SET_CURRENT_PAGE_USER_ACTIVITY_ORIGINAL_EVENTS = '[advanced-reports] set-current-page-user-activity-original-events',
  ADVANCED_REPORT_SET_FILTER_FOR_SAVE = '[advanced-reports] set-filter-for-save',
  ADVANCED_REPORT_GET_USER_ACTIVITY_EVENT_TYPES = '[advanced-reports] get-user-activity-event-types',
  ADVANCED_REPORT_SET_USER_ACTIVITY_EVENT_TYPES = '[advanced-reports] set-user-activity-event-types',
  ADVANCED_REPORT_SET_SELECTED_SAVED_REPORT = '[advanced-reports] set-selected-saved-report',
  ADVANCED_REPORT_REMOVE_SELECTED_SAVED_REPORT = '[advanced-reports] remove-selected-saved-report',
  ADVANCED_REPORT_GET_SAVED_REPORTS = '[advanced-reports] get-saved-reports',
  ADVANCED_REPORT_SET_SAVED_REPORTS = '[advanced-reports] set-saved-reports',
  ADVANCED_REPORT_DELETE_SELECTED_SAVED_REPORT = '[advanced-reports] delete-selected-saved-report',
  ADVANCED_REPORT_UPDATE_REPORT = '[advanced-reports] update-report',
  ADVANCED_REPORT_SAVE_REPORT = '[advanced-reports] save-report',
  ADVANCED_REPORT_SET_DRAWER_LOADING = '[advanced-reports] set-drawer-loading'
}

export const advancedUserActivityActions = {
  setUserActivity: (
    events: AdvancedUserActivityEvent[],
    totalResults: number
  ) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_ACTIVITY,
      {
        events,
        totalResults
      }
    ),
  getUserActivity: (params: DataFetchParams<AdvancedUserActivityQueryParams>) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_ACTIVITY,
      { query: params.query }
    ),
  getUserForEvent: (selectedUserEmail: string) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_FOR_EVENT,
      { selectedUserEmail }
    ),
  setUserForEvent: (selectedUser: User) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_FOR_EVENT,
      { selectedUser }
    ),
  setCurrentPageUserActivityOriginalEvents: (
    originalEvents: TeamsApiAdvancedUserEventType[]
  ) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_CURRENT_PAGE_USER_ACTIVITY_ORIGINAL_EVENTS,
      { originalEvents }
    ),
  setUserActivityLoading: (isLoading: boolean) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_ACTIVITY_LOADING,
      { isLoading }
    ),
  setFilterForSave: (filterForSave: AdvancedUserActivityFilter) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_FILTER_FOR_SAVE,
      { filterForSave }
    ),
  getUserActivityEventTypes: () =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_ACTIVITY_EVENT_TYPES
    ),
  setUserActivityEventTypes: (eventTypes: AdvancedUserActivityEventTypes) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_USER_ACTIVITY_EVENT_TYPES,
      { eventTypes }
    ),
  setSelectedSavedReport: (selectedSavedReport: AdvancedUserActivityReport) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_SELECTED_SAVED_REPORT,
      { selectedSavedReport }
    ),
  removeSelectedSavedReport: () =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_REMOVE_SELECTED_SAVED_REPORT
    ),
  getSavedReports: () =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_SAVED_REPORTS
    ),
  setSavedReports: (savedReports: AdvancedUserActivityReport[]) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_SAVED_REPORTS,
      { savedReports }
    ),
  deleteSelectedSavedReport: (
    selectedSavedReport: AdvancedUserActivityReport,
    savedReports: AdvancedUserActivityReport[]
  ) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_DELETE_SELECTED_SAVED_REPORT,
      { selectedSavedReport, savedReports }
    ),
  updateReport: (filterForUpdate: AdvancedUserActivityFilter) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_UPDATE_REPORT,
      { filterForUpdate }
    ),
  saveReport: (reportName: string) =>
    createAction(AdvancedUserActivityActionTypes.ADVANCED_REPORT_SAVE_REPORT, {
      reportName
    }),
  setDrawerLoading: (isLoading: boolean) =>
    createAction(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SET_DRAWER_LOADING,
      { isLoading }
    )
}

export interface AdvancedUserActivityQueryParams {
  skip: number
  orderBy: string
  filter: AdvancedUserActivityFilter
}

export type AdvancedUserActivityActions = ActionsUnion<
  typeof advancedUserActivityActions
>

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { TeamsPolicyStatus } from '@lastpass/admin-console-dependencies/types/policy-teams'

import { EnumDictionary } from '../EnumValues'

export const PolicyStatusMapping: EnumDictionary<
  TeamsPolicyStatus,
  ReactElement
> = {
  [TeamsPolicyStatus.all]: <Trans>All</Trans>,
  [TeamsPolicyStatus.enabled]: <Trans>Enabled</Trans>,
  [TeamsPolicyStatus.disabled]: <Trans>Disabled</Trans>
}

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SplunkIntegrationActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/actions'

import { createGetSplunkIntegration } from './get-splunk-integration'
import { createSaveSplunkIntegration } from './save-splunk-integration'

export function createSplunkIntegrationSaga(
  splunkIntegrationService: UACServices.Services
) {
  const getSplunkIntegrationSaga = createGetSplunkIntegration(
    splunkIntegrationService
  )
  const saveSplunkIntegrationSaga = createSaveSplunkIntegration(
    splunkIntegrationService
  )

  return function*() {
    yield takeEvery(
      SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_GET,
      getSplunkIntegrationSaga
    )
    yield takeEvery(
      SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_SAVE,
      saveSplunkIntegrationSaga
    )
  }
}

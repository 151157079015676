import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import { CardContainer, Heading100, TextButton } from '@lastpass/lastkit'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'

import { MfaAppIcon } from '../MfaAppIcon'

const Container = styled(CardContainer)<{
  selected: boolean
}>`
  background: ${props =>
    props.selected ? props.theme.colors.blue100 : props.theme.colors.white};
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.colors.blue700
        : props.theme.colors.neutral300};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 16px;
  &:first-of-type {
    margin-top: 24px;
  }
`

const SelectButton = styled(TextButton)`
  padding: 0px;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
`

const StyledRadioInput = styled.input`
  width: 16px;
  height: 16px;
  margin: 24px 16px;
  flex-shrink: 0;
`

const StyledHeading100 = styled(Heading100)`
  flex-grow: 1;
`

const LogoContainer = styled.div`
  max-width: 32px;
  margin-right: 16px;
  display: flex;
`

interface MfaAppCardProps {
  selected: boolean
  appName: string
  appType: MfaApplicationType
}

export const MfaAppCard = (props: MfaAppCardProps) => {
  const dispatch = useDispatch()
  return (
    <Container data-qa={`AppCard_${props.appName}`} selected={props.selected}>
      <SelectButton
        onClick={() =>
          dispatch(mfaAppsDrawerActions.selectApp(props.appName, props.appType))
        }
      >
        <StyledRadioInput
          type={'radio'}
          onChange={() => {}}
          checked={props.selected}
        />
        <StyledHeading100>{props.appName}</StyledHeading100>
        <LogoContainer>
          <MfaAppIcon mfaAppType={props.appType} />
        </LogoContainer>
      </SelectButton>
    </Container>
  )
}

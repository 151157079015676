import React from 'react'
import { useSelector } from 'react-redux'
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  HeaderContainer,
  Heading300,
  TabContainer,
  TabNavigation
} from '@lastpass/lastkit'
import { RoutedTabNavigationItem } from '@lastpass/lastkit'

import { SsoAppsPageContainer } from '@lastpass/admin-console-dependencies/ui/applications/ssoapps/SsoAppsPage'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { LegacyVpnPage } from './legacyvpn/LegacyVpnPage'
import { WorkstationsPage } from './workstations/WorkstationsPage'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

export const PasswordlessAppsPage: React.FunctionComponent = () => {
  const cidOverride = useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )
  const match = useRouteMatch()

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  if (match && !isNoPasswordDeprecated) {
    const { path: matchPath, url: matchUrl } = match
    const [legacyvpn, workstation, ssoapps] = [
      'legacyvpn',
      'workstation',
      'ssoapps'
    ].map(path => {
      const route: RouteProps = { path: `${matchPath}/${path}` }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    })

    return (
      <>
        <PageHeaderContainer data-qa="PageHeaderContainer">
          <Heading300 data-qa="PasswordlessAppsPageHeader">
            <Trans>Passwordless apps</Trans>
          </Heading300>
        </PageHeaderContainer>
        <TabContainer data-qa="TabContainer">
          <TabNavigation>
            <RoutedTabNavigationItem
              data-qa="TabMenuSsoApps"
              to={ssoapps.url}
              route={ssoapps.route}
            >
              <Trans>SSO apps</Trans>
            </RoutedTabNavigationItem>
            <RoutedTabNavigationItem
              data-qa="TabMenuLeagcyvpn"
              to={legacyvpn.url}
              route={legacyvpn.route}
            >
              <Trans>Legacy & VPN</Trans>
            </RoutedTabNavigationItem>
            <RoutedTabNavigationItem
              data-qa="TabMenuWorkstation"
              to={workstation.url}
              route={workstation.route}
            >
              <Trans>Workstation Login</Trans>
            </RoutedTabNavigationItem>
          </TabNavigation>
        </TabContainer>
        <Switch>
          <Route {...legacyvpn.route}>
            <LegacyVpnPage cidOverride={cidOverride} />
          </Route>
          <Route {...workstation.route}>
            <WorkstationsPage cidOverride={cidOverride} />
          </Route>
          <Route {...ssoapps.route}>
            <SsoAppsPageContainer />
          </Route>
        </Switch>
      </>
    )
  }
  return null
}

import { call } from 'redux-saga/effects'
import { put, select } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { User as GenericUser } from '@lastpass/admin-console-dependencies/types/user'

export function convertUsers(userList: GenericUser[]): User[] {
  return userList.length > 0
    ? userList.map<User>((user: GenericUser) => {
        return { id: user.id, name: user.email }
      })
    : []
}

function filterAssignedUsers(
  availableUsers: GenericUser[],
  assignedUsers: User[]
): GenericUser[] {
  return availableUsers.filter(
    (availableUser: GenericUser) =>
      !assignedUsers.some(assignedUser => assignedUser.id === availableUser.id)
  )
}

export function createGetAvailableUsersSaga(getUserListSaga) {
  return function* getAvailableUsers(
    action: ReturnType<typeof mfaAppsDrawerActions.getAvailableUsers>
  ) {
    try {
      const assignedUsers: Table<User> = yield select(
        (state: AppState) => state.mfaAppsDrawer.users
      )

      yield put(mfaAppsDrawerActions.setUserListLoading(true))

      try {
        yield call(getUserListSaga, {
          payload: {
            params: action.payload.params,
            resultsPerPage: action.payload.resultsPerPage
          }
        })

        const allAvailableUsers = yield select(
          (state: AppState) => state.usersList.table
        )

        const availableUsersResults: GenericUser[] =
          allAvailableUsers.results.length > 0 &&
          assignedUsers.results.length > 0
            ? filterAssignedUsers(
                allAvailableUsers.results,
                assignedUsers.results
              )
            : allAvailableUsers.results

        yield put(
          mfaAppsDrawerActions.setAvailableUserList(
            convertUsers(availableUsersResults),
            availableUsersResults.length,
            action.payload.resultsPerPage
          )
        )
      } finally {
        yield put(mfaAppsDrawerActions.setUserListLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

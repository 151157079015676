import { useTheme } from '@emotion/react'
import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

enum ThresholdTypes {
  low = 'low',
  average = 'average',
  high = 'high',
  highest = 'highest'
}

const thresholdMaxValues = {
  [ThresholdTypes.low]: 49,
  [ThresholdTypes.average]: 84,
  [ThresholdTypes.high]: 99
}

export interface Threshold {
  subtitle: MessageDescriptor
  colors: {
    primary: string
    secondary: string
    tertiary: string
  }
}

export const useCalculateChartRateThreshold = (rate: number): Threshold => {
  const { colors } = useTheme()
  if (rate <= thresholdMaxValues.low) {
    return {
      subtitle: msg`low`,
      colors: {
        primary: colors.red700,
        secondary: colors.red300,
        tertiary: colors.red100
      }
    }
  }
  if (rate <= thresholdMaxValues.average) {
    return {
      subtitle: msg`average`,
      colors: {
        primary: colors.yellow700,
        secondary: colors.yellow300,
        tertiary: colors.yellow100
      }
    }
  }
  if (rate <= thresholdMaxValues.high) {
    return {
      subtitle: msg`high`,
      colors: {
        primary: colors.green700,
        secondary: colors.green300,
        tertiary: colors.green100
      }
    }
  }

  return {
    subtitle: msg`highest`,
    colors: {
      primary: colors.green700,
      secondary: colors.green300,
      tertiary: colors.green100
    }
  }
}

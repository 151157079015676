import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { EnabledMultifactorOptionsActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/actions'

import { createGetEnabledMultifactorOptions } from './get-enabled-multifactor-options'
import { createResetMfaSharedSecrets } from './reset-mfa-shared-secrets'
import { createSaveEnabledMultifactorOptions } from './save-enabled-multifactor-options'

export function createEnabledMultifactorOptionsSaga(
  enabledMultifactorOptionsService: UACServices.Services
) {
  const getEnabledMultifactorOptionsSaga = createGetEnabledMultifactorOptions(
    enabledMultifactorOptionsService
  )
  const saveEnabledMultifactorOptionsSaga = createSaveEnabledMultifactorOptions(
    enabledMultifactorOptionsService
  )
  const resetMfaSharedSecretsSaga = createResetMfaSharedSecrets(
    enabledMultifactorOptionsService
  )

  return function*() {
    yield takeEvery(
      EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_GET,
      getEnabledMultifactorOptionsSaga
    )
    yield takeEvery(
      EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_SAVE,
      saveEnabledMultifactorOptionsSaga
    )
    yield takeEvery(
      EnabledMultifactorOptionsActionTypes.RESET_MFA_SHARED_SECRETS,
      resetMfaSharedSecretsSaga
    )
  }
}

import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { MultipleCompaniesAgreementDrawerContent } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/multiple-companies/MultipleCompaniesAgreementDrawerContent'
import { PsaDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/drawer/PsaDrawer'

const StyledLink = styled.a`
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`

interface MultipleCompaniesAgreementDrawerProps {
  basePath: string
  title: ReactElement
}

export const MultipleCompaniesAgreementDrawer: FunctionComponent<MultipleCompaniesAgreementDrawerProps> = props => {
  const { basePath, title } = props

  return (
    <PsaDrawer
      title={title}
      closeLink={basePath}
      description={
        <>
          <Trans>
            To perform manual or automated billing updates, an agreement must be
            selected for each company. You can change these on the Managed
            companies page later.
          </Trans>
          <p>
            <StyledLink
              data-qa="LearnMoreLink"
              href="https://link.lastpass.com/msp-psa-integrations"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Learn more about billing updates</Trans>
            </StyledLink>
          </p>
        </>
      }
      dataQa="SelectAgreementsDrawer"
    >
      <MultipleCompaniesAgreementDrawerContent />
    </PsaDrawer>
  )
}

import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Collapsible, DetailCellProps } from '@lastpass/lastkit/components'
import {
  DetailCell,
  DetailsContainer
} from '@lastpass/lastkit/components/DetailCellContainer'

import { formatSecurityScore } from '@lastpass/admin-console-dependencies/services/managed-companies/security-score'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'

export interface CompanyProfileCardProps {
  isExpanded?: boolean
  managedCompany: ManagedCompany
}

const renderCells = (
  cellProps: DetailCellProps[],
  CellType: FunctionComponent<DetailCellProps>
) => {
  return cellProps.map((props, key) => <CellType key={key} {...props} />)
}
const datePattern = 'MM/DD/YYYY'
const dateFormat = (input: moment.MomentInput) =>
  moment(input).format(datePattern)

const AverageSecurityScore: FunctionComponent<{
  securityScore: ManagedCompany['averageSecurityScore']
}> = ({ securityScore }) => {
  const formattedSecurityScore = formatSecurityScore(securityScore)
  return (
    <span data-qa="CompanyProfileCardAverageSecurityScore">
      {typeof formattedSecurityScore === 'string'
        ? formattedSecurityScore
        : i18n._(formattedSecurityScore)}
    </span>
  )
}

export const CompanyProfileCardComponent: FunctionComponent<CompanyProfileCardProps> = ({
  isExpanded,
  managedCompany
}) => {
  const { isAggregator, isBillingUsageBased } = useSelector<
    AppState,
    CompanyDetails
  >(appState => appState.companyInfo.details)

  const cells = {
    companyId: {
      field: <Trans>Company ID</Trans>,
      value: (
        <span data-qa="CompanyProfileCardId">{String(managedCompany.id)}</span>
      )
    },
    createdDate: {
      field: <Trans>Created</Trans>,
      value: (
        <span data-qa="CompanyProfileCardCreatedDateUtc">
          {dateFormat(managedCompany.createdDateUtc)}
        </span>
      )
    },
    allocatedLicenses: {
      field: <Trans>Allocated licenses</Trans>,
      value: (
        <span data-qa="CompanyProfileCardLicensesAllocated">
          {String(managedCompany.licensesAllocated)}
        </span>
      )
    },
    availableLicenses: {
      field: <Trans>Available licenses</Trans>,
      value: (
        <span data-qa="CompanyProfileCardAvailableLicenses">
          {String(managedCompany.licensesAvailable)}
        </span>
      )
    },
    averageSecurityScoreCalculationDate: {
      field: <Trans>Score calculated at</Trans>,
      value: (
        <span data-qa="CompanyProfileCardAverageSecurityScoreDateUtc">
          {managedCompany.averageSecurityScoreDateUtc ? (
            dateFormat(managedCompany.averageSecurityScoreDateUtc)
          ) : (
            <Trans>N/A</Trans>
          )}
        </span>
      )
    },
    consumedLicenses: {
      field: <Trans>Consumed licenses</Trans>,
      value: (
        <span data-qa="CompanyProfileCardLicensesConsumed">
          {String(managedCompany.licensesConsumed)}
        </span>
      )
    },
    averageSecurityScore: {
      field: <Trans>Average security score</Trans>,
      value: (
        <AverageSecurityScore
          securityScore={managedCompany.averageSecurityScore}
        />
      )
    }
  }

  const filterCells = (keys: (keyof typeof cells)[]) =>
    keys.map(cellName => cells[cellName])

  const getCells = () => {
    if (isBillingUsageBased || isAggregator) {
      return filterCells([
        'companyId',
        'averageSecurityScoreCalculationDate',
        'createdDate',
        'averageSecurityScore'
      ])
    }

    return filterCells([
      'companyId',
      'allocatedLicenses',
      'availableLicenses',
      'averageSecurityScoreCalculationDate',
      'createdDate',
      'consumedLicenses',
      'averageSecurityScore'
    ])
  }

  return (
    <Collapsible
      title={<Trans>Company profile</Trans>}
      expanded={isExpanded}
      qadata="ManagedCompaniesCompanyProfileCard"
    >
      <DetailsContainer data-qa="ManagedCompaniesCompanyProfileCardContainer">
        {renderCells(getCells(), DetailCell)}
      </DetailsContainer>
    </Collapsible>
  )
}

import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'

import { ReactComponent as AdvancedFeatureIcon } from '@lastpass/assets/svg/admin-console/advanced-feature-icon.svg'
import {
  BodyRegularStyle,
  DetailsDialog,
  Heading200,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { VaultReEncryptionOfferDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface VaultReEncryptionOfferDialogProps {
  state: VaultReEncryptionOfferDialog
}

const StyledCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledAdvancedFeatureIcon = styled(AdvancedFeatureIcon)`
  margin-bottom: 44px;
`

const StyledHeading200 = styled(Heading200)`
  margin-bottom: 20px;
  width: 100%;
`

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`

export const VaultReEncryptionOfferComponent: FunctionComponent<VaultReEncryptionOfferDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.confirmDialog(state.cancelStage))
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      disableMaxHeight
      content={
        <>
          <StyledCenterContainer>
            <StyledAdvancedFeatureIcon />
          </StyledCenterContainer>
          <StyledHeading200>{i18n._(state.title)}</StyledHeading200>
          <TextBoxContainer>{i18n._(state.mainText)}</TextBoxContainer>
          <TextBoxContainer>{i18n._(state.secondaryText)}</TextBoxContainer>
        </>
      }
      buttons={[
        <TextButton
          onClick={() => dispatch(globalActions.confirmDialog(state.backStage))}
          data-qa="PopupCancel"
          key="discard"
        >
          {i18n._(state.discardText)}
        </TextButton>,
        <PrimaryButton
          onClick={() => dispatch(globalActions.confirmDialog(state.nextStage))}
          data-qa="PopupSubmit"
          key="confirm"
        >
          {i18n._(state.confirmText)}
        </PrimaryButton>
      ]}
    />
  )
}

import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { FederatedStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/FederatedStatusMapping'

interface SelectFederatedStatusProps {
  updateSelection: (update: FederatedStatus[]) => void
  filters?: FederatedStatus[]
}

export const SelectFederatedStatus: FunctionComponent<SelectFederatedStatusProps> = props => {
  const options = Object.keys(FederatedStatusMapping).map(x => ({
    value: x as FederatedStatus,
    label: FederatedStatusMapping[x]
  }))

  return (
    <Select
      dataQa={'FederatedStatusDropdown'}
      options={options}
      value={options.filter(
        status => props.filters && props.filters.includes(status.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={<Trans>Federated status</Trans>}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateSelection(selectedItems.map(status => status.value))
        } else {
          props.updateSelection([])
        }
      }}
      fluid
    >
      <Trans>Federated status</Trans>
    </Select>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { PrimaryButton, TextButton } from '@lastpass/lastkit'
import { SearchInput, StyledSpacer } from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import {
  MfaAppsDrawerState,
  MfaAppsDrawerSteps
} from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { Entities } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { NavigationBarComponent } from '@lastpass/admin-console-dependencies/ui/applications/mfa/drawersteps/assign/NavigationBar'
import { GroupsTableComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/Assign/GeneralApplicationsGroupsTable'
import { UsersTableComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/Assign/GeneralApplicationsUsersTable'

export interface AssignNewEntityProps {
  closeLink: string
}

export const AssignNewEntityComponent: React.FunctionComponent<AssignNewEntityProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const queryParams = useQueryParams(Namespace.applications)

  const state: MfaAppsDrawerState = useSelector(
    (state: AppState) => state.mfaAppsDrawer
  )

  useEffect(() => {
    dispatch(
      mfaAppsDrawerActions.getAvailableUsers({
        query: { ...queryParams, search: state.usersSearchInput },
        path: {}
      })
    )

    dispatch(
      mfaAppsDrawerActions.getAvailableGroups({
        query: { ...queryParams, search: state.groupsSearchInput },
        path: {}
      })
    )
  }, [dispatch, queryParams, state.groupsSearchInput, state.usersSearchInput])

  const assignDisabled =
    state.availableUsers.checkedRecords.length === 0 &&
    state.availableGroups.checkedRecords.length === 0

  const setSearchInput = () => {
    if (state.showTable === Entities.users) {
      return state.usersSearchInput
    }
    if (state.showTable === Entities.groups) {
      return state.groupsSearchInput
    }
  }

  const searchInputComponent = (
    <SearchInput
      data-qa={'AssignSearchBar'}
      value={setSearchInput()}
      onChange={event => {
        if (state.showTable === Entities.users) {
          dispatch(
            mfaAppsDrawerActions.setUserListSearchInput(event.target.value)
          )
          dispatch(
            mfaAppsDrawerActions.getAvailableUsers({
              query: { search: event.target.value },
              path: {}
            })
          )
        }
        if (state.showTable === Entities.groups) {
          dispatch(
            mfaAppsDrawerActions.setGroupListSearchInput(event.target.value)
          )
          dispatch(
            mfaAppsDrawerActions.getAvailableGroups({
              query: { search: event.target.value },
              path: {}
            })
          )
        }
      }}
      placeholder={msg`Search users & groups...`}
    />
  )

  return (
    <>
      {
        <NavigationBarComponent
          usersTotalResult={state.availableUsers.totalResults}
          groupsTotalResult={state.availableGroups.totalResults}
          searchInput={searchInputComponent}
        />
      }
      {state.showTable === Entities.users ? (
        <UsersTableComponent
          table={state.availableUsers}
          checkable={{
            addSelectedRecords: (users: User[]) => {
              dispatch(mfaAppsDrawerActions.addSelectedUsers(users))
            },
            removeSelectedRecords: (users: User[]) => {
              dispatch(mfaAppsDrawerActions.removeSelectedUsers(users))
            },
            setSelectedRecords: (users: User[]) => {
              dispatch(mfaAppsDrawerActions.setSelectedUsers(users))
            }
          }}
          title={<Trans>Please search for users</Trans>}
          text={
            <Trans>To assign users to this application, search above.</Trans>
          }
          actionButton={<></>}
        />
      ) : null}
      {state.showTable === Entities.groups ? (
        <GroupsTableComponent
          table={state.availableGroups}
          checkable={{
            addSelectedRecords: groups =>
              dispatch(mfaAppsDrawerActions.addSelectedGroups(groups)),
            removeSelectedRecords: groups =>
              dispatch(mfaAppsDrawerActions.removeSelectedGroups(groups)),
            setSelectedRecords: groups =>
              dispatch(mfaAppsDrawerActions.setSelectedGroups(groups))
          }}
          title={<Trans>Please search for groups</Trans>}
          text={
            <Trans>To assign groups to this application, search above.</Trans>
          }
          actionButton={<></>}
        />
      ) : null}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => history.push(closeLink)}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            id="AssignButton"
            type="submit"
            key="Assign"
            onClick={() => {
              dispatch(mfaAppsDrawerActions.assign())
              dispatch(mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.assign))
            }}
            disabled={assignDisabled}
            data-qa="AssignButton"
          >
            <Trans>Assign</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

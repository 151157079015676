import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { NO_KEYS_WERE_SHARED_ERROR } from '@lastpass/admin-console-dependencies/server/users/recovery-keys/recovery-key-sharing-details'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import {
  NoKeysWereSharedErrorDialogType,
  RecipientAlreadyHasTheKeysErrorDialogType,
  RecoveryKeySharingEndDialogType,
  RecoveryKeySharingProgressDialogType,
  SomeKeysWereSharedErrorDialogType
} from '@lastpass/admin-console-dependencies/types/dialog-types'
import { RecoveryKeySharingUploadRequest } from '@lastpass/admin-console-dependencies/types/recovery-keys/recovery-key-sharing-upload-request'
import { SuperAdminReencryptedRecoveryKey } from '@lastpass/admin-console-dependencies/types/recovery-keys/superadmin-reencrypted-recovery-key'

import { reEncryptRecoveryKeys } from './re-encrypt-recovery-keys'

export const progressDialog: RecoveryKeySharingProgressDialogType = {
  type: 'recovery-key-sharing-progress-dialog'
}

export const endDialog: RecoveryKeySharingEndDialogType = {
  type: 'recovery-key-sharing-end-dialog'
}

export const recipientAlreadyHasTheKeys: RecipientAlreadyHasTheKeysErrorDialogType = {
  type: 'recipient-already-has-the-keys'
}

export const someKeysWereSharedError: SomeKeysWereSharedErrorDialogType = {
  type: 'some-keys-were-shared-error-dialog'
}

export const noKeysWereSharedError: NoKeysWereSharedErrorDialogType = {
  type: 'no-keys-were-shared-error-dialog'
}

export const recoveryKeySharingDetailsState = (state: AppState) =>
  state.distributeRecoveryKeys.recoveryKeySharingDetails

export const isStopped = (state: AppState) =>
  state.distributeRecoveryKeys.isStopped

export const getDecryptedPrivateSharingKey = (state: AppState) =>
  state.global.decryptedPrivateSharingKey

const RECIPIENT_ALREADY_HAS_THE_KEYS = 'recipientAlreadyHasTheKeys'
const SOME_KEYS_WERE_SHARED_ERROR = 'someKeysWereSharedError'

export function createDistributeRecoveryKeySaga(
  userService: UACServices.Services
) {
  return function* distributeRecoveryKeySaga() {
    try {
      const recoveryKeySharingDetails = yield select(
        recoveryKeySharingDetailsState
      )

      const totalNumberOfUserRecoveryKeys =
        recoveryKeySharingDetails.userRecoveryKeys.length

      if (totalNumberOfUserRecoveryKeys === 0) {
        throw new Error(RECIPIENT_ALREADY_HAS_THE_KEYS)
      } else {
        yield put(globalActions.setDialog(progressDialog))

        yield put(
          distributeRecoveryKeysActions.setTotalNumberOfKeys(
            totalNumberOfUserRecoveryKeys
          )
        )
        const decryptedPrivateKeyRaw = yield select(
          getDecryptedPrivateSharingKey
        )
        let numberOfSharedKeys = 0

        let isStoppedAlready = false
        while (
          recoveryKeySharingDetails.userRecoveryKeys.length > 0 &&
          !isStoppedAlready
        ) {
          const reEncryptedRecoveryKeys: SuperAdminReencryptedRecoveryKey[] = yield call(
            reEncryptRecoveryKeys,
            recoveryKeySharingDetails,
            decryptedPrivateKeyRaw
          )

          if (reEncryptedRecoveryKeys.length > 0) {
            const recoveryKeyDistributionUploadRequest: RecoveryKeySharingUploadRequest = {
              reEncryptedRecoveryKeys
            }

            try {
              yield call(
                userService.recoveryKeyUpload,
                recoveryKeyDistributionUploadRequest
              )
            } catch (e) {
              throw new Error(NO_KEYS_WERE_SHARED_ERROR)
            }

            numberOfSharedKeys += reEncryptedRecoveryKeys.length
            yield put(
              distributeRecoveryKeysActions.setNumberOfSharedKeys(
                numberOfSharedKeys
              )
            )
          }
          isStoppedAlready = yield select(isStopped)
        }

        if (
          totalNumberOfUserRecoveryKeys !== numberOfSharedKeys &&
          numberOfSharedKeys !== 0
        ) {
          throw new Error(SOME_KEYS_WERE_SHARED_ERROR)
        }

        yield put(globalActions.setDialog(endDialog))
        yield put(globalActions.setDecryptedPrivateSharingKey(''))
        yield put(globalActions.setVaultKey(Buffer.from('')))
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === NO_KEYS_WERE_SHARED_ERROR) {
          yield put(globalActions.setDialog(noKeysWereSharedError))
        }
        if (e.message === SOME_KEYS_WERE_SHARED_ERROR) {
          yield put(globalActions.setDialog(someKeysWereSharedError))
        }
        if (e.message === RECIPIENT_ALREADY_HAS_THE_KEYS) {
          yield put(globalActions.setDialog(recipientAlreadyHasTheKeys))
        }
      } else {
        yield put(globalActions.setNotification(genericFailedNotification))
      }
    }
  }
}

export { reEncryptRecoveryKeys }

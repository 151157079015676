import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { BodyRegular } from '@lastpass/lastkit'

import {
  SystemUpgradeProcessDialog,
  SystemUpgradeProcessDialogType
} from '@lastpass/admin-console-dependencies/types/dialog-types'

import { SystemUpgradeCompletedDialog } from './SystemUpgradeCompletedDialog'
import { SystemUpgradeErrorDialog } from './SystemUpgradeErrorDialog'
import { SystemUpgradeLoadingDialog } from './SystemUpgradeLoadingDialog'
import { SystemUpgradeStartDialog } from './SystemUpgradeStartDialog'

interface SystemUpgradeProcessDialogProps {
  state: SystemUpgradeProcessDialog
}

export const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledBodyRegular = styled(BodyRegular)`
  width: 100%;
`
export const dialogWidth = '480px'

export const SystemUpgradeDialog: FunctionComponent<SystemUpgradeProcessDialogProps> = ({
  state
}) => {
  switch (state.dialogType) {
    case SystemUpgradeProcessDialogType.start:
      return <SystemUpgradeStartDialog />
    case SystemUpgradeProcessDialogType.loading:
      return <SystemUpgradeLoadingDialog />
    case SystemUpgradeProcessDialogType.completed:
      return <SystemUpgradeCompletedDialog />
    case SystemUpgradeProcessDialogType.error:
      return <SystemUpgradeErrorDialog />
    default:
      return null
  }
}

import { defaultConfig } from '@lastpass/admin-console/src/default-config'

const getCsrfToken = async () => {
  const response = await fetch(
    `${defaultConfig.lastPassBaseAddress}/teams-api/csrf`,
    {
      credentials: 'include',
      method: 'GET'
    }
  )

  return (await response.json()).token
}

export const openEnterpriseUsersWindow = (urlParams: {
  [key: string]: string
}) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async resolve => {
    const token = await getCsrfToken()
    const width = 1000
    const height = 600
    const left = screen.width / 2 - width / 2
    const top = screen.height / 2 - height / 2
    const params = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`

    urlParams['token'] = token
    const queryString = new URLSearchParams(urlParams).toString()
    const windowRef = window.open(
      `${defaultConfig.lastPassBaseAddress}/enterprise_users.php?${queryString}`,
      'LastPass',
      params
    )
    if (windowRef) {
      const popupEventListener = event => {
        if (
          event.origin !== defaultConfig.lastPassBaseAddress ||
          !event.data.shouldResetMasterPasswordPopupClose
        ) {
          return
        }
        windowRef.close()
        window.removeEventListener('message', popupEventListener, false)
      }
      window.addEventListener('message', popupEventListener, false)
      const interval = setInterval(() => {
        if (windowRef.closed) {
          clearInterval(interval)
          resolve()
        }
      }, 500)
    } else {
      resolve()
    }
  })
}

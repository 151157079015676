import { getPolicyDescription } from '@lastpass/admin-console-dependencies/services/get-policy-description'
import { GetPolicyName } from '@lastpass/admin-console-dependencies/services/get-policy-name'
import { GetPolicyShortDescription } from '@lastpass/admin-console-dependencies/services/get-policy-shortdescription'

import { MultifactorActions, MultifactorActionTypes } from './actions'
import { MultifactorPageState } from './state'

export const initialState: MultifactorPageState = {
  RequireOfPolicies: [],
  AdditionalPolicies: [],
  IsLoading: false
}

export function multifactorPageReducer(
  previousState: MultifactorPageState | undefined,
  action: MultifactorActions
): MultifactorPageState {
  const state = previousState || initialState

  switch (action.type) {
    case MultifactorActionTypes.REMOVE_POLICY: {
      return {
        ...state,
        RequireOfPolicies: state.RequireOfPolicies.map(policy => {
          if (policy.policyKey === action.payload.policyKey) {
            return {
              ...policy,
              policyData: []
            }
          }
          return policy
        })
      }
    }
    case MultifactorActionTypes.SET_LOADING: {
      return {
        ...state,
        IsLoading: action.payload.loading
      }
    }
    case MultifactorActionTypes.SET_REQUIRE_OF_POLICIES: {
      return {
        ...state,
        RequireOfPolicies: action.payload
      }
    }
    case MultifactorActionTypes.SET_ADDITIONAL_POLICIES: {
      return {
        ...state,
        AdditionalPolicies: action.payload.map(policy => {
          return {
            ...policy,
            policyTitle: GetPolicyName(policy.policyKey, policy.policyTitle),
            policyDescription: getPolicyDescription(
              policy.policyKey,
              policy.policyDescription
            ),
            policyShortDescription: GetPolicyShortDescription(
              policy.policyKey,
              policy.policyShortDescription
            )
          }
        })
      }
    }
    case MultifactorActionTypes.EDIT_REQUIRE_OF_POLICY_AVAILABILITY: {
      return {
        ...state,
        RequireOfPolicies: state.RequireOfPolicies.map(policy => {
          if (policy.policyKey === action.payload.policyKey) {
            return {
              ...policy,
              checked: action.payload.isEnable
            }
          }
          return policy
        })
      }
    }
  }

  return state
}

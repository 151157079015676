import { call } from 'redux-saga/effects'
import { put, select } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { Group } from '@lastpass/admin-console-dependencies/state/applications/application'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

function filterAssignedGroups(availableGroups, assignedGroups) {
  return availableGroups.filter(
    availableGroup =>
      !assignedGroups.some(
        assignedGroup => assignedGroup.id === availableGroup.id
      )
  )
}

export function createGetAvailableGroupsSaga(getGroupListSaga) {
  return function* getGroupList(
    action: ReturnType<typeof mfaAppsDrawerActions.getAvailableGroups>
  ) {
    try {
      const assignedGroups: Table<Group> = yield select(
        (state: AppState) => state.mfaAppsDrawer.groups
      )

      yield put(mfaAppsDrawerActions.setGroupListLoading(true))

      try {
        yield call(getGroupListSaga, {
          payload: {
            params: action.payload.params,
            resultsPerPage: action.payload.resultsPerPage
          }
        })

        const allAvailableGroups = yield select(
          (state: AppState) => state.groupsList.table
        )

        const availableGroupResults =
          allAvailableGroups.results.length > 0 &&
          assignedGroups.results.length > 0
            ? filterAssignedGroups(
                allAvailableGroups.results,
                assignedGroups.results
              )
            : allAvailableGroups.results

        yield put(
          mfaAppsDrawerActions.setAvailableGroupList(
            availableGroupResults.length > 0
              ? (availableGroupResults as Group[])
              : [],
            availableGroupResults.length,
            action.payload.resultsPerPage
          )
        )
      } finally {
        yield put(mfaAppsDrawerActions.setGroupListLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  Heading100,
  PrimaryButton,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaAppsDrawerState } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'

import { MfaAppCard } from './MfaAppCard'

export interface CreateIntegrationProps {
  closeLink: string
}

const CancelButton = styled(TextButton)`
  margin-right: 16px;
`

const SelectHeader = styled(Heading100)`
  margin-top: 24px;
  color: ${props => props.theme.colors.black};
`

export const CreateIntegration: React.FunctionComponent<CreateIntegrationProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const mfaAppsDrawerState: MfaAppsDrawerState = useSelector(
    (appState: AppState) => appState.mfaAppsDrawer
  )

  const hasMicrosoftAzureAdApp: boolean = useSelector(
    (appState: AppState) => appState.mfaApplicationList.hasAzureAdApps
  )

  const hasAdfsApp: boolean = useSelector(
    (appState: AppState) => appState.mfaApplicationList.hasAdfsApps
  )

  const appCardWindowsWorkstation = (
    <MfaAppCard
      selected={mfaAppsDrawerState.app.type === MfaApplicationType.windows}
      appName={'Windows workstations'}
      appType={MfaApplicationType.windows}
    />
  )

  const appCardMacOsWorkstation = (
    <MfaAppCard
      selected={mfaAppsDrawerState.app.type === MfaApplicationType.mac}
      appName={'macOS workstations'}
      appType={MfaApplicationType.mac}
    />
  )

  const appCardAzureAd = (
    <MfaAppCard
      selected={mfaAppsDrawerState.app.type === MfaApplicationType.azureAD}
      appName={'Microsoft Azure AD'}
      appType={MfaApplicationType.azureAD}
    />
  )

  const appCardAdfs = (
    <MfaAppCard
      selected={mfaAppsDrawerState.app.type === MfaApplicationType.adfs}
      appName={'Microsoft AD FS'}
      appType={MfaApplicationType.adfs}
    />
  )

  const appCardUniversalProxy = (
    <MfaAppCard
      selected={
        mfaAppsDrawerState.app.type === MfaApplicationType.universalProxy
      }
      appName={'Universal Proxy (LDAP, RADIUS)'}
      appType={MfaApplicationType.universalProxy}
    />
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(mfaAppsDrawerActions.selectAppViewed())
  }, [dispatch])

  return (
    <>
      <TextInput
        value={mfaAppsDrawerState.app.name}
        data-qa="MfaAppNameInputField"
        maxLength={50}
        onChange={e => {
          setIsBlockingPromptEnabled(true)
          dispatch(mfaAppsDrawerActions.setName(e.target.value))
        }}
        disabled={mfaAppsDrawerState.app.type === MfaApplicationType.azureAD}
      >
        <Trans>Name</Trans>
      </TextInput>
      <SelectHeader>
        <Trans>What would you like to protect?</Trans>
      </SelectHeader>
      {appCardWindowsWorkstation}
      {appCardMacOsWorkstation}
      {hasMicrosoftAzureAdApp && appCardAzureAd}
      {hasAdfsApp && appCardAdfs}
      {appCardUniversalProxy}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <CancelButton
            key="Cancel"
            onClick={() => {
              history.push(closeLink)
              dispatch(
                mfaAppsDrawerActions.selectAppCanceled(mfaAppsDrawerState.app)
              )
            }}
            data-qa="CancelButton"
          >
            <Trans>Cancel</Trans>
          </CancelButton>
          <PrimaryButton
            id="SaveButton"
            key="Save"
            onClick={() => {
              setIsBlockingPromptEnabled(false)
              dispatch(mfaAppsDrawerActions.addApp(mfaAppsDrawerState.app))
            }}
            disabled={!mfaAppsDrawerState.canSave}
            data-qa="SaveButton"
          >
            <Trans>Save & continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

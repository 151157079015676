import { saveAs } from 'file-saver'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'

import { extractSecurityReportZip } from './extract-security-report-zip'

export function createDownloadUrlsInSharedFoldersReportSaga(
  service: UACServices.Services
) {
  return function* downloadUrlsInSharedFoldersReport(
    action: ReturnType<
      typeof securityReportDrawerActions.downloadUrlsInSharedFoldersReport
    >
  ) {
    try {
      const downloadReportName = yield select(
        (state: AppState) => state.securityReportDrawer.downloadReportName
      )

      const currentAdminLevel = yield select(
        (state: AppState) => state.usersList.currentAdminLevel
      )

      const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin

      const {
        reportId,
        isSharedFolderUrlDecryptionEnabled,
        setIsLoading
      } = action.payload

      if (!isSharedFolderUrlDecryptionEnabled || !isSuperAdmin) {
        yield call(setIsLoading, true)
      }
      const urlsInSharedFoldersReportDownloadResponse: UACServices.UrlsInSharedFoldersAPI.DownloadReportAPI.Responses = yield call(
        service.urlsInSharedFoldersReportDownload,
        reportId
      )

      if (
        urlsInSharedFoldersReportDownloadResponse.type !==
        UACServices.UrlsInSharedFoldersAPI.DownloadReportAPI.SUCCESS
      ) {
        throw new UnknownServiceError()
      }

      if (isSharedFolderUrlDecryptionEnabled && isSuperAdmin) {
        yield call(
          extractSecurityReportZip,
          urlsInSharedFoldersReportDownloadResponse.body
        )
        yield put(
          globalActions.setDialog({
            type: 'url-decryption-confirm-dialog'
          })
        )
      } else {
        yield call(
          saveAs,
          urlsInSharedFoldersReportDownloadResponse.body,
          `${downloadReportName}.zip`
        )
        yield call(setIsLoading, false)
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

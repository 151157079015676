import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading300 } from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { TeamsPolicyResponse } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { ComingSoon } from '@lastpass/admin-console-dependencies/ui/common/ComingSoon'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { AdfsSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/AdfsSetupCard'
import { AzureAdSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/AzureAdSetupCard'
import { GoogleSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/GoogleSetupCard'
import { OktaSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OktaSetupCard'
import { OneLoginSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OneLoginSetupCard'
import { PingFederateSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/PingFederateSetupCard'
import { PingOneSetupCard } from '@lastpass/admin-console-dependencies/ui/users/federated-login/PingOneSetupCard'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px;
`

export interface AutomatedVaultReEncryptionPolicy {
  isEnabled: boolean
  isAllUser?: boolean | null
  value?: string | null
}

export const FederatedLoginPage: React.FunctionComponent = () => {
  const requireFederatedVaultReEncryptionPolicyKey =
    'recurringfederatedvaultreencryption'

  const isPingOneEnabled = useFeatureFlags(FeatureFlags.isPingOneEnabled)
  const isPingFederateEnabled = useFeatureFlags(
    FeatureFlags.isPingFederateEnabled
  )
  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )
  const [
    automatedVaultReEncryptionPolicy,
    setAutomatedVaultReEncryptionPolicy
  ] = useState<TeamsPolicyResponse | null>(null)
  const { generalPoliciesListGet: fetchPolicies } = useContext(ServerContext)
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetchPolicies()
        const requireFederatedVaultReEncryptionPolicy =
          response?.body.generalPoliciesListResponse[
            requireFederatedVaultReEncryptionPolicyKey
          ]
        if (requireFederatedVaultReEncryptionPolicy) {
          setAutomatedVaultReEncryptionPolicy(
            requireFederatedVaultReEncryptionPolicy
          )
        }
      } catch {
        dispatch(globalActions.setNotification(genericFailedNotification))
      }
    })()
  }, [dispatch, fetchPolicies])

  useEffect(() => {
    if (
      automatedVaultReEncryptionPolicy &&
      !automatedVaultReEncryptionPolicy.switched_on &&
      isAutomatedVaultReEncryptionEnabled
    ) {
      dispatch(federatedLoginActions.startVaultReEncryptionOffer())
    }
  }, [
    automatedVaultReEncryptionPolicy,
    dispatch,
    isAutomatedVaultReEncryptionEnabled
  ])

  const getAutomatedVaultReEncryptionPolicyData = (): AutomatedVaultReEncryptionPolicy => {
    if (!automatedVaultReEncryptionPolicy?.switched_on) {
      return { isEnabled: false }
    }
    const policyData = automatedVaultReEncryptionPolicy.policyData
    return {
      isEnabled: automatedVaultReEncryptionPolicy.switched_on,
      isAllUser:
        policyData &&
        !policyData[0].exclusiveList?.length &&
        !policyData[0].inclusiveList?.length &&
        !policyData[0].exclusiveGroupList?.length &&
        !policyData[0].inclusiveGroupList?.length,
      value: policyData && policyData[0].policyValue
    }
  }

  let content = (
    <StyledContainer>
      <AdfsSetupCard />
      <AzureAdSetupCard
        automatedVaultReEncryptionPolicy={getAutomatedVaultReEncryptionPolicyData()}
      />
      <OktaSetupCard
        automatedVaultReEncryptionPolicy={getAutomatedVaultReEncryptionPolicyData()}
      />
      <GoogleSetupCard
        automatedVaultReEncryptionPolicy={getAutomatedVaultReEncryptionPolicyData()}
      />
      <OneLoginSetupCard
        automatedVaultReEncryptionPolicy={getAutomatedVaultReEncryptionPolicyData()}
      />
      {isPingOneEnabled && (
        <PingOneSetupCard
          automatedVaultReEncryptionPolicy={getAutomatedVaultReEncryptionPolicyData()}
        />
      )}
      {isPingFederateEnabled && <PingFederateSetupCard />}
    </StyledContainer>
  )

  const isFederatedLoginEnabled = useFeatureFlags(
    FeatureFlags.isFederatedLoginEnabled
  )

  if (!isFederatedLoginEnabled) {
    content = <ComingSoon oldUrl="/settings/federated-login/users" />
  }

  return (
    <>
      <PageHeaderContainer data-qa="PageHeaderContainer">
        <Heading300 data-qa="FederatedLoginPageHeader">
          <Trans>Federated login</Trans>
        </Heading300>
      </PageHeaderContainer>
      {content}
    </>
  )
}

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { DashboardActionTypes } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { DashboardDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'

import { createGetDashboardSettingsSaga } from './get-dashboard-settings'
import { createGetSecurityScoreDataSaga } from './get-security-score-data'
import { createGetSecurityScoreHistorySaga } from './get-security-score-history'
import { createGetUrlEncryptionMigrationStateSaga } from './get-url-encrytion-migration-state'
import { createGetUserActivityHistorySaga } from './get-user-activity-history'
import { createGetUserAdoptionSaga } from './get-user-adoption'
import { createGetUserAdoptionHistorySaga } from './get-user-adoption-history'
import { createGetUserAdoptionUnresponsiveUsersSaga } from './get-user-adoption-unresponsive-users'
import { createGetUserStateDataSaga } from './get-user-count-data'
import { createExportInactiveUsersSaga } from './inactive-users/export-inactive-users'
import { createGetInactiveUsersSaga } from './inactive-users/get-inactive-users'
import { createExportUsersWithMasterPasswordReusedSaga } from './reused-master-password/export-reused-master-passwords'
import { createGetReusedMasterPasswordsSaga } from './reused-master-password/get-reused-master-passwords'
import { createExportStagedUsersSaga } from './staged-users/export-staged-users'
import { createGetStagedUsersSaga } from './staged-users/get-staged-users'
import { createInviteStagedUsersSaga } from './staged-users/invite-staged-users'
import { createExportUnresponsiveUsersSaga } from './unresponsive-users/export-unresponsive-users'
import { createGetUnresponsiveUsersSaga } from './unresponsive-users/get-unresponsive-users'
import { createInviteUnresponsiveUsersSaga } from './unresponsive-users/reinvite-unresponsive-users'
import { createExportUsersWithWeakSecurityScoreSaga } from './users-with-weak-security-score/export-users-with-weak-security-score'
import { createGetUsersWithWeakSecurityScore } from './users-with-weak-security-score/get-users-with-weak-security-score'
import { createExportUsersWithWeakMasterPassword } from './users-with-weakmasterpassword/export-users-with-weakmasterpassword'
import { createGetUsersWithWeakMasterPasswordScore } from './users-with-weakmasterpassword/get-users-with-weakmasterpassword'

export function createDashboardSaga(dashboardServices: UACServices.Services) {
  const getSecurityScoreHistorySaga = createGetSecurityScoreHistorySaga(
    dashboardServices
  )
  const getSecurityScoreDataSaga = createGetSecurityScoreDataSaga(
    dashboardServices
  )

  const getUserAdoptionHistorySaga = createGetUserAdoptionHistorySaga(
    dashboardServices
  )

  const getUserActivityHistorySaga = createGetUserActivityHistorySaga(
    dashboardServices
  )

  const getUnresponsiveUsersSaga = createGetUnresponsiveUsersSaga(
    dashboardServices
  )

  const getUnresponsiveUserCountSaga = createGetUserAdoptionUnresponsiveUsersSaga(
    dashboardServices
  )

  const exportUnresponsiveUsersSaga = createExportUnresponsiveUsersSaga(
    dashboardServices
  )

  const inviteUnresponsiveUsersSaga = createInviteUnresponsiveUsersSaga(
    dashboardServices
  )

  const getUserAdoptionsSaga = createGetUserAdoptionSaga(dashboardServices)

  const getUserStateDataSaga = createGetUserStateDataSaga(dashboardServices)

  const getInactiveUsersSaga = createGetInactiveUsersSaga(dashboardServices)

  const exportInactiveUsersSaga = createExportInactiveUsersSaga(
    dashboardServices
  )

  const getStagedUsersSaga = createGetStagedUsersSaga(dashboardServices)

  const exportStagedUsersSaga = createExportStagedUsersSaga(dashboardServices)

  const inviteStagedUsersSaga = createInviteStagedUsersSaga(dashboardServices)

  const getDashboardSettingsSaga = createGetDashboardSettingsSaga(
    dashboardServices
  )

  const getWeakMasterPasswordUsersSaga = createGetUsersWithWeakMasterPasswordScore(
    dashboardServices
  )

  const exportWeakMasterPasswordUsersSaga = createExportUsersWithWeakMasterPassword(
    dashboardServices
  )

  const getUsersWithWeakSecurityScoreSaga = createGetUsersWithWeakSecurityScore(
    dashboardServices
  )

  const exportUsersWithWeakSecurityScoreSaga = createExportUsersWithWeakSecurityScoreSaga(
    dashboardServices
  )

  const getMasterPasswordReuseSaga = createGetReusedMasterPasswordsSaga(
    dashboardServices
  )

  const exportMasterPasswordReuseSaga = createExportUsersWithMasterPasswordReusedSaga(
    dashboardServices
  )

  const getUrlEncryptionMigrationStateSaga = createGetUrlEncryptionMigrationStateSaga(
    dashboardServices
  )

  return function*() {
    yield takeEvery(
      DashboardActionTypes.GET_SECURITY_SCORE_HISTORY,
      getSecurityScoreHistorySaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_SECURITY_SCORE_DATA,
      getSecurityScoreDataSaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_USER_ADOPTION_HISTORY,
      getUserAdoptionHistorySaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_USER_ACTIVITY_HISTORY,
      getUserActivityHistorySaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_UNRESPONSIVE_USERS,
      getUnresponsiveUserCountSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.GET_UNRESPONSIVE_USERS,
      getUnresponsiveUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.EXPORT_UNRESPONSIVE_USERS,
      exportUnresponsiveUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.REINVITE_UNRESPONSIVE_USERS,
      inviteUnresponsiveUsersSaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_USER_ADOPTION,
      getUserAdoptionsSaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_USER_STATE_DATA,
      getUserStateDataSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.GET_INACTIVE_USERS,
      getInactiveUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.EXPORT_INACTIVE_USERS,
      exportInactiveUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.GET_STAGED_USERS,
      getStagedUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.EXPORT_STAGED_USERS,
      exportStagedUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.REINVITE_STAGED_USERS,
      inviteStagedUsersSaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_DISPLAY_SETTINGS,
      getDashboardSettingsSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.GET_WEAK_MASTERPASSWORD_USERS,
      getWeakMasterPasswordUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.EXPORT_WEAK_MASTERPASSWORD_USERS,
      exportWeakMasterPasswordUsersSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.GET_USERS_WITH_WEAK_SECURITY_SCORE,
      getUsersWithWeakSecurityScoreSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.EXPORT_USERS_WITH_WEAK_SECURITY_SCORE,
      exportUsersWithWeakSecurityScoreSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.GET_REUSED_MASTER_PASSWORD_LIST,
      getMasterPasswordReuseSaga
    )
    yield takeEvery(
      DashboardDrawerActionTypes.EXPORT_REUSED_MASTER_PASSWORD_LIST,
      exportMasterPasswordReuseSaga
    )
    yield takeEvery(
      DashboardActionTypes.GET_URL_ENCRYPTION_MIGRATION_STATE,
      getUrlEncryptionMigrationStateSaga
    )
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { takeDialogInteractions } from '@lastpass/admin-console-dependencies/sagas/utils/take-dialog-interactions'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { openEnterpriseUsersWindow } from '@lastpass/admin-console-dependencies/services/user-operations/open-enterprise-users-window'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { transferVaultDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/transfer-vault/actions'
import { TransferVaultConfirmationWordDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  getFullName,
  User,
  UserProfile
} from '@lastpass/admin-console-dependencies/types/user'

const createConfirmDialog = (
  targetUser: User,
  sourceUser: UserProfile
): TransferVaultConfirmationWordDialogType => {
  const sourceUserName = getFullName(sourceUser) || sourceUser.email
  const targetUserName = getFullName(targetUser) || targetUser.email

  return {
    type: 'transfer-vault-confirmation-word-dialog',
    sourceUserEmail: sourceUserName,
    targetUserEmail: targetUserName
  }
}

export const transferVaultSelector = (state: AppState) =>
  state.transferVaultDrawer
export const currentUrlFiltersSelector = (state: AppState) => state.router
export const cidOverrideSelector = (state: AppState) =>
  state.companyInfo.cidOverride

export function createTransferVaultOpenWindowSaga() {
  return function*() {
    try {
      const { targetUser, sourceUser } = yield select(transferVaultSelector)
      const cidOverride = yield select(cidOverrideSelector)
      if (!targetUser.hasSharingKey) {
        yield put(
          globalActions.setNotification({
            type: NotificationType.warning,
            autoDismiss: true,
            message: msg`Ask the user to generate sharing keys so they can receive the shared folder.`,
            genericError: true
          })
        )
        return
      }
      yield put(
        globalActions.setDialog(createConfirmDialog(targetUser, sourceUser))
      )
      const { isConfirmed, isDiscarded } = yield call(takeDialogInteractions)

      if (isDiscarded) return

      if (isConfirmed) {
        yield call(openEnterpriseUsersWindow, {
          teamspwchange: sourceUser.lastPassId,
          convert2sf: targetUser.lastPassUserId,
          childcid: cidOverride
        })
        yield put(transferVaultDrawerActions.setIsTransferCompleted(true))
        const currentUrlFilters = yield select(currentUrlFiltersSelector)
        yield put(
          userListActions.getUserList({
            query: getQueryParams(currentUrlFilters.location, 'users'),
            path: {}
          })
        )
        yield put(push('/users/view'))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

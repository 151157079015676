import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createPasswordlessGetStartedSaga() {
  return function* createPasswordlessGetStarted(
    actions: ReturnType<typeof globalActions.passwordlessGetStarted>
  ) {
    const newRoute = actions.payload.newRoute

    yield newRoute && put(push(newRoute))
  }
}

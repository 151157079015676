import { takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { MultifactorActionTypes } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { AdvancedPolicyDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { MultifactorDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

import { createAddAdvancedPolicySaga } from './add-advanced-policy'
import { createConfigureAdvancedPolicySaga } from './configure-advanced-policy'
import { createDisablePolicySaga } from './disable-policy'
import { createEditPolicyAssignmentsSaga } from './edit-policy-assignments'
import { createGetAvailableGroupsSaga } from './get-available-groups'
import { createGetAvailableUsersSaga } from './get-available-users'
import { createGetDuoConfig } from './get-duo-config'
import { createInitialData } from './get-initial-data'
import { createGetRsaConfig } from './get-rsa-config'
import { createGetSecureAuthConfig } from './get-secure-auth-config'
import { createGetSymantecConfig } from './get-symantec-config'
import { createSaveAdvancedPolicy } from './save-advanced-policy'
import { createSaveAdvancedPolicyConfig } from './save-advanced-policy-config'
import { createSaveDuoSecuritySettings } from './save-duo-security-settings'
import { createSavePolicyUsers } from './save-policy-users'
import { createSaveRsaConfig } from './save-rsa-config'
import { createSaveRsaSettings } from './save-rsa-settings'
import { createSaveSalesforceSettings } from './save-salesforce-settings'
import { createSaveSecureAuthSettings } from './save-secure-auth-settings'
import { createSaveSymantecConfig } from './save-symantec-config'
import { createSaveSymantecSettings } from './save-symantec-settings'
import { createSaveTotpSettings } from './save-totp-settings'
import { createSetRsaConfig } from './set-rsa-config-saga'
import { createSetSelectedMfaMethodDefaultValue } from './set-selected-mfa-method-default-value'
import { createSetSymantecConfig } from './set-symantec-config-saga'

export function createMultifactorSaga(
  multifactorService: UACServices.Services
) {
  const getInitialData = createInitialData(multifactorService)
  const setSymantecConfig = createSetSymantecConfig()
  const setRsaConfig = createSetRsaConfig()
  const saveDuoSettings = createSaveDuoSecuritySettings(multifactorService)
  const saveSymantecSettings = createSaveSymantecSettings(multifactorService)
  const saveRsaSettings = createSaveRsaSettings(multifactorService)
  const saveSecureAuthSettings = createSaveSecureAuthSettings(
    multifactorService
  )
  const saveTotpSettings = createSaveTotpSettings(multifactorService)
  const saveSalesforceSettings = createSaveSalesforceSettings(
    multifactorService
  )
  const getAvailableUsers = createGetAvailableUsersSaga(multifactorService)
  const getAvailableGroups = createGetAvailableGroupsSaga(multifactorService)
  const savePolicyData = createSavePolicyUsers(multifactorService)

  const getDuoConfig = createGetDuoConfig(multifactorService)
  const getSymantecConfig = createGetSymantecConfig(multifactorService)
  const saveSymantecConfig = createSaveSymantecConfig(multifactorService)
  const getRsaConfig = createGetRsaConfig(multifactorService)
  const saveRsaConfig = createSaveRsaConfig(multifactorService)
  const getSecureAuthConfig = createGetSecureAuthConfig(multifactorService)
  const disablePolicySaga = createDisablePolicySaga(multifactorService)
  const editPolicyAssignmentsSaga = createEditPolicyAssignmentsSaga()
  const configureAdvancedPolicySaga = createConfigureAdvancedPolicySaga()
  const addAdvancedPolicySaga = createAddAdvancedPolicySaga()
  const saveAdvancedPolicyConfigSaga = createSaveAdvancedPolicyConfig(
    multifactorService
  )
  const saveAdvancedPolicySaga = createSaveAdvancedPolicy(multifactorService)
  const setSelectedMfaMethodDefaultValue = createSetSelectedMfaMethodDefaultValue()

  return function*() {
    yield takeLatest(MultifactorActionTypes.FETCH_INITIAL_DATA, getInitialData)
    yield takeLatest(
      MultifactorDrawerActionTypes.PROCESS_SYMANTEC_CONFIG,
      setSymantecConfig
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.PROCESS_RSA_CONFIG,
      setRsaConfig
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_DUO_SECURITY_SETTINGS,
      saveDuoSettings
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_SYMANTEC_SETTINGS,
      saveSymantecSettings
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_RSA_SETTINGS,
      saveRsaSettings
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_SECURE_AUTH_SETTINGS,
      saveSecureAuthSettings
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_TOTP_SETTINGS,
      saveTotpSettings
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_SALESFORCE_SETTINGS,
      saveSalesforceSettings
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.GET_AVAILABLE_USERS,
      getAvailableUsers
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.GET_AVAILABLE_GROUPS,
      getAvailableGroups
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_POLICY_USERS,
      savePolicyData
    )
    yield takeLatest(
      AdvancedPolicyDrawerActionTypes.SAVE_ADVANCED_POLICY,
      saveAdvancedPolicySaga
    )
    yield takeLatest(MultifactorDrawerActionTypes.GET_DUO_CONFIG, getDuoConfig)
    yield takeLatest(
      MultifactorDrawerActionTypes.GET_SYMANTEC_CONFIG,
      getSymantecConfig
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_SYMANTEC_CONFIG,
      saveSymantecConfig
    )
    yield takeLatest(MultifactorDrawerActionTypes.GET_RSA_CONFIG, getRsaConfig)
    yield takeLatest(
      MultifactorDrawerActionTypes.SAVE_RSA_CONFIG,
      saveRsaConfig
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.GET_SECURE_AUTH_CONFIG,
      getSecureAuthConfig
    )
    yield takeLatest(MultifactorActionTypes.DISABLE_POLICY, disablePolicySaga)
    yield takeLatest(
      MultifactorActionTypes.EDIT_POLICY_ASSIGNMENTS,
      editPolicyAssignmentsSaga
    )
    yield takeLatest(
      MultifactorActionTypes.CONFIGURE_ADVANCED_POLICY,
      configureAdvancedPolicySaga
    )
    yield takeLatest(
      MultifactorActionTypes.ADD_ADVANCED_POLICY,
      addAdvancedPolicySaga
    )
    yield takeLatest(
      AdvancedPolicyDrawerActionTypes.SAVE_POLICY_CONFIG,
      saveAdvancedPolicyConfigSaga
    )
    yield takeLatest(
      MultifactorDrawerActionTypes.SET_SELECTED_MFA_METHOD_DEFAULT_VALUE,
      setSelectedMfaMethodDefaultValue
    )
  }
}

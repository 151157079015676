export interface UserOperationJob {
  job: UserOperationJobTypes
  filter: UserOperationFilter
}

export interface UserOperationFilter {
  companyUserIds?: string[]
  companyGroupIds?: string[]
  filterOption: FilterOption
}

export enum FilterOption {
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
  All = 'all'
}

export enum UserOperationJobTypes {
  Mfainvitation = 'enqueuesendlastpassmfainvitation'
}

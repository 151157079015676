import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { assignAdminLevelUsersActions } from '@lastpass/admin-console-dependencies/state/users/admin/add-users/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminOperationType } from '@lastpass/admin-console-dependencies/state/users/admin/operations/admin-operations'
import { UserListQueryParams } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { UserOperationsType } from '@lastpass/admin-console-dependencies/state/users/view/operations/user-operations'
import { TableWithOperations } from '@lastpass/admin-console-dependencies/types/table'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import { AssignAdminLevelItem } from '@lastpass/admin-console-dependencies/ui/users/view/assignAdminLevelDropdown/AssignAdminLevelDropdownItem'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'

import { AssignAdminLevelDropdownItem } from './AssignAdminLevelDropdownItem'

export const AssignAdminLevelDropdownList = () => {
  const queryParams: UserListQueryParams = useQueryParams(Namespace.users)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(adminListActions.getAdminList())
  }, [dispatch])

  const {
    results
  }: TableWithOperations<
    UserLevelDetails,
    AdminOperationType,
    string
  > = useSelector((appState: AppState) => appState.adminList.table)

  const {
    checkedRecords
  }: TableWithOperations<
    User,
    UserOperationsType,
    UserListTableColumn
  > = useSelector((appState: AppState) => appState.usersList.table)

  const isEveryUsersHasSameAdminLevel = checkedRecords.every(
    record => record.adminLevel?.name === checkedRecords[0].adminLevel?.name
  )

  const isEveryCheckedRecordsAreInUserLevel = checkedRecords.every(
    record => record.adminLevel === null
  )

  /**
   * if all selected users are in the same admin level,
   * that admin level will be filtered out of the list of available admin levels
   */
  const availableAdminLevels = isEveryUsersHasSameAdminLevel
    ? results.filter(
        result => result.name !== checkedRecords[0].adminLevel?.name
      )
    : results

  const userItem = {
    name: (
      <UserLevelLabel
        userLevel={{
          id: 'user',
          name: 'User',
          isCustom: false
        }}
      />
    ),
    action: () =>
      dispatch(
        assignAdminLevelUsersActions.unassignSelectedUsers(
          checkedRecords,
          queryParams
        )
      )
  }

  const items: AssignAdminLevelItem[] = []

  if (!isEveryCheckedRecordsAreInUserLevel) {
    items.push(userItem)
  }

  items.push(
    ...availableAdminLevels.map(result => ({
      name: <UserLevelLabel userLevel={result} />,
      action: () =>
        dispatch(
          assignAdminLevelUsersActions.addUsersToAdmin(
            checkedRecords,
            result,
            queryParams,
            '',
            true
          )
        )
    }))
  )

  return (
    <>
      {items.map((item, index) => (
        <AssignAdminLevelDropdownItem item={item} index={index} key={index} />
      ))}
    </>
  )
}

export enum UserOperationsType {
  inviteUser = 'inviteUser',
  reinviteUser = 'reinviteUser',
  assignAdminLevels = 'assignAdminLevels',
  resendLPMFAInvitation = 'resendLPMFAInvitation',
  activateUsers = 'activateUsers',
  setInitialPassword = 'setInitialPassword',
  requireMasterPasswordChange = 'requireMasterPasswordChange',
  superAdminMasterPasswordReset = 'superAdminMasterPasswordReset',
  destroyAllSessionsForSelectedUsers = 'destroyAllSessionsForSelectedUsers',
  lockDeviceForLPMFA = 'lockDeviceForLPMFA',
  unlockDeviceForLPMFA = 'unlockDeviceForLPMFA',
  removeSelectedUsersFromCompany = 'removeSelectedUsersFromCompany',
  deleteSelectedUsers = 'deleteSelectedUsers',
  disableUsers = 'disableUsers',
  disableMultifactor = 'disableMultifactor',
  acceptPendingUser = 'acceptPendingUser',
  rejectPendingUser = 'rejectPendingUser',
  resetMasterPassword = 'resetMasterPassword',
  transferVault = 'transferVault',
  selectForFederation = 'selectForFederation',
  deselectForFederation = 'deselectForFederation',
  selectForVaultReEncryption = 'selectForVaultReEncryption',
  distributeRecoveryKey = 'distributeRecoveryKey'
}

import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as AdvancedFeatureIllustration } from '@lastpass/assets/svg/admin-console/icon-advanced-feature.svg'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'

const AdvancedMfaMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
  padding-bottom: 1px;
  padding-top: 10px;
`
const StyledAdvancedFeatureIcon = styled(AdvancedFeatureIllustration)`
  width: 17.5px;
  height: 20px;
  margin-right: 10px;
`
const StyledLocationLink = styled.a`
  margin-left: 5px;
`

export const AdvancedMfaMessage: React.FunctionComponent<{
  isEmpty: boolean
  nonMspMessage: MessageDescriptor
}> = ({ isEmpty, nonMspMessage }) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isUserMspAndHasPolicy =
    !isEmpty && CompanyDetailsHelper.isMspOrManaged(companyDetails)

  const advancedMfaMessageText = isUserMspAndHasPolicy ? (
    <Trans>
      This is an add-on feature. You{"'"}ll be invoiced for Advanced MFA in the
      next billing cycle.
    </Trans>
  ) : (
    i18n._(nonMspMessage)
  )

  const advancedMfaLink = isUserMspAndHasPolicy
    ? 'https://link.lastpass.com/msp-advanced-mfa-add-on'
    : 'http://link.lastpass.com/help-mfa-apps'

  return (
    <AdvancedMfaMessageContainer data-qa={'MfaAppPageTrialPaywallDiv'}>
      <StyledAdvancedFeatureIcon data-qa={'AdvancedFeatureIcon'} />
      {advancedMfaMessageText}
      <StyledLocationLink
        href={advancedMfaLink}
        data-qa={'LearnMoreMfaAppsLink'}
        target="_blank"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </AdvancedMfaMessageContainer>
  )
}

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createPsaProductsSaga(products: UACServices.Services) {
  return function* getPsaProducts() {
    try {
      yield put(psaIntegrationsActions.setPsaProductsTableInProgress(true))
      const response: UACServices.GetPsaProductsAPI.Responses = yield call(
        products.getPsaProducts
      )

      if (response && response.type === UACServices.GetPsaProductsAPI.SUCCESS) {
        const { products } = response.body
        yield put(
          psaIntegrationsActions.setPsaProducts(
            products,
            products.length,
            products.length
          )
        )
      } else {
        yield put(
          globalActions.setNotification({
            message: msg`Couldn’t load products. Check your connection or try again later.`,
            type: NotificationType.alert,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(psaIntegrationsActions.setPsaProductsTableInProgress(false))
    }
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  PrimaryButton,
  SearchInput,
  StyledSpacer,
  TableViewButton,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { Entities } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { AppliesToSelectionMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

import { AppliesToRadioButtons } from './AppliesToRadioButtons'
import { GroupsTableComponent } from './MultifactorGroupsTable'
import { UsersTableComponent } from './MultifactorUsersTable'

export interface AssignNewProps {
  closeLink: string
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

export const AssignNewEntity: React.FunctionComponent<AssignNewProps> = ({
  closeLink
}) => {
  const history = useHistory()

  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )

  const dispatch = useDispatch()

  const assignDisabled =
    multifactorDrawerState.assignFormState.availableUsers.checkedRecords
      .length === 0 &&
    multifactorDrawerState.assignFormState.availableGroups.checkedRecords
      .length === 0

  const setSearchInput = () => {
    if (multifactorDrawerState.assignFormState.showTable === Entities.users) {
      return multifactorDrawerState.assignFormState.usersSearchInput
    }
    if (multifactorDrawerState.assignFormState.showTable === Entities.groups) {
      return multifactorDrawerState.assignFormState.groupsSearchInput
    }
  }

  return (
    <>
      <AppliesToRadioButtons
        onChange={() => {}}
        disabled={true}
        initValue={multifactorDrawerState.assignFormState.appliesTo}
      />
      <SearchInput
        data-qa="SearchFieldInput"
        value={setSearchInput()}
        onChange={event => {
          if (
            multifactorDrawerState.assignFormState.showTable === Entities.users
          ) {
            dispatch(
              multifactorDrawerActions.setUserListSearchInput(
                event.target.value
              )
            )
            dispatch(
              multifactorDrawerActions.getAvailableUsers(event.target.value)
            )
          }
          if (
            multifactorDrawerState.assignFormState.showTable === Entities.groups
          ) {
            dispatch(
              multifactorDrawerActions.setGroupListSearchInput(
                event.target.value
              )
            )
            dispatch(
              multifactorDrawerActions.getAvailableGroups(event.target.value)
            )
          }
        }}
        placeholder={msg`Search users & groups...`}
      />
      <TableContainer>
        <StyledTabContainer data-qa="TabNavigationBar">
          <TabNavigation>
            <div
              data-qa="AssignUsersTab"
              onClick={() => {
                dispatch(multifactorDrawerActions.showTable(Entities.users))
              }}
            >
              <StyledTabNavigationItem
                active={
                  multifactorDrawerState.assignFormState.showTable ===
                  Entities.users
                }
              >
                <Trans>Users</Trans>
                {` (${multifactorDrawerState.assignFormState.availableUsers.totalResults})`}
              </StyledTabNavigationItem>
            </div>
            <div
              data-qa="AssignGroupsTab"
              onClick={() => {
                dispatch(multifactorDrawerActions.showTable(Entities.groups))
              }}
            >
              <StyledTabNavigationItem
                active={
                  multifactorDrawerState.assignFormState.showTable ===
                  Entities.groups
                }
              >
                <Trans>Groups</Trans>
                {` (${multifactorDrawerState.assignFormState.availableGroups.totalResults})`}
              </StyledTabNavigationItem>
            </div>
          </TabNavigation>
        </StyledTabContainer>
      </TableContainer>
      {multifactorDrawerState.assignFormState.showTable === Entities.users ? (
        <UsersTableComponent
          table={multifactorDrawerState.assignFormState.availableUsers}
          checkable={{
            addSelectedRecords: record =>
              dispatch(multifactorDrawerActions.addSelectedUsers(record)),
            removeSelectedRecords: record =>
              dispatch(multifactorDrawerActions.removeSelectedUsers(record)),
            setSelectedRecords: record =>
              dispatch(multifactorDrawerActions.setSelectedUsers(record))
          }}
          title={<Trans>No users assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some users.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                dispatch(
                  multifactorDrawerActions.nextStep(
                    MultifactorDrawerSteps.assignNewEntities
                  )
                )
              }}
            >
              <TableViewButton data-qa="AssignUsersToSsoAppButton">
                <Trans>Assign users</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      {multifactorDrawerState.assignFormState.showTable === Entities.groups ? (
        <GroupsTableComponent
          table={multifactorDrawerState.assignFormState.availableGroups}
          checkable={{
            addSelectedRecords: record =>
              dispatch(multifactorDrawerActions.addSelectedGroups(record)),
            removeSelectedRecords: record =>
              dispatch(multifactorDrawerActions.removeSelectedGroups(record)),
            setSelectedRecords: record =>
              dispatch(multifactorDrawerActions.setSelectedGroups(record))
          }}
          title={<Trans>No groups assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some groups.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                dispatch(
                  multifactorDrawerActions.nextStep(
                    MultifactorDrawerSteps.assignNewEntities
                  )
                )
              }}
            >
              <TableViewButton data-qa="AssignGroupsToSsoAppButton">
                <Trans>Assign groups</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Cancel"
            onClick={() => {
              dispatch(
                multifactorDrawerActions.multifactorAssignUsersClicked(
                  AppliesToSelectionMapping[
                    multifactorDrawerState.assignFormState.appliesTo
                  ],
                  'Discard'
                )
              )
              history.push(closeLink)
            }}
            data-qa="CancelButton"
          >
            <Trans>Cancel</Trans>
          </TextButton>
          <PrimaryButton
            id="AssignButton"
            type="submit"
            key="Assign"
            onClick={() => {
              dispatch(multifactorDrawerActions.assign())
              dispatch(
                multifactorDrawerActions.nextStep(MultifactorDrawerSteps.allSet)
              )
              dispatch(
                multifactorDrawerActions.multifactorAssignUsersClicked(
                  AppliesToSelectionMapping[
                    multifactorDrawerState.assignFormState.appliesTo
                  ],
                  'Continue'
                )
              )
            }}
            disabled={assignDisabled}
            data-qa="AssignButton"
          >
            <Trans>Assign</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

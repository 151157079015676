import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps } from 'formik'

import { BodySemibold, CaptionRegularStyle } from '@lastpass/lastkit'

import { isTimeRangeValid } from '@lastpass/admin-console-dependencies/services/policies/time-range-validator'

import { TimeRangeValidationError } from '../TimeRangeValidationError'

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 24px;
`
const StyledTextArea = styled.textarea`
  background: ${props => props.theme.colors.white};
  height: 80px;
  width: 537px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const StyledLabel = styled(BodySemibold)`
  color: ${props => props.theme.colors.neutral600};
`
const StyledError = styled.span`
  ${CaptionRegularStyle};
  color: ${props => props.theme.colors.red700};
  line-height: 15px;
`

interface PolicyValueEditorProps {
  isTimeRangePolicy: boolean
}
export const PolicyValueEditor: React.FunctionComponent<PolicyValueEditorProps> = ({
  isTimeRangePolicy
}) => {
  const [valueFocused, setValueFocused] = useState(false)

  return (
    <TextAreaContainer>
      <StyledLabel>
        <Trans>Value</Trans>
      </StyledLabel>
      <Field name="policyValue">
        {(formData: FieldProps) => {
          return (
            <>
              <StyledTextArea
                name={formData.field.name}
                value={formData.field.value}
                onChange={e => {
                  formData.field.onChange(e)
                }}
                onBlur={() => {
                  setValueFocused(true)
                }}
              />
              {valueFocused &&
                !isTimeRangePolicy &&
                formData.field.value === '' && (
                  <StyledError>
                    <Trans>Please enter a value</Trans>
                  </StyledError>
                )}
              {valueFocused &&
                isTimeRangePolicy &&
                !isTimeRangeValid(formData.field.value) && (
                  <TimeRangeValidationError />
                )}
            </>
          )
        }}
      </Field>
    </TextAreaContainer>
  )
}

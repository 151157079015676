import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  Group,
  Role,
  User
} from '@lastpass/admin-console-dependencies/state/applications/application'

export const SUCCESS = 'success'

const success = response => createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service(
    appId: string,
    users: User[],
    groups: Group[],
    roles: Role[]
  ) {
    try {
      const body = {
        Users: users,
        Groups: groups,
        Roles: roles
      }

      const [response, status] = await identityApi(
        `api/v2/Apps/${appId}/selected`,
        'POST',
        body,
        undefined,
        true
      )
      if (status === StatusCodes.OK && response) {
        const applicationsResponse = JSON.parse(response)
        return success(applicationsResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

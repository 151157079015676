import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { ReactComponent as IconSuccess } from '@lastpass/assets/svg/admin-console/icon-success.svg'
import {
  BodyRegular,
  DetailsDialog,
  Heading300,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { SuccessDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface SuccessDialogProps {
  state: SuccessDialog
  actions: typeof globalActions
}

const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSuccessIcon = styled(IconSuccess)`
  margin-bottom: 20px;
  width: 145px;
  height: 145px;
`

const StyledHeading300 = styled(Heading300)`
  margin-bottom: 24px;
`

const StyledBodyRegular = styled(BodyRegular)`
  width: 100%;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: 115px;
`

export const SuccessDialogComponent: FunctionComponent<SuccessDialogProps> = ({
  state,
  actions
}) => {
  const { _ } = useLingui()
  const title = _(state.title)
  return (
    <DetailsDialog
      closeDialog={() => {
        actions.discardDialog()
        actions.emptyDialog()
      }}
      width="600px"
      hideCloseIcon
      content={
        <StyledCenter>
          <StyledSuccessIcon />
          <StyledHeading300>{title}</StyledHeading300>
          {state.content && (
            <StyledBodyRegular>
              {'key' in state.content ? state.content : _(state.content)}
            </StyledBodyRegular>
          )}
        </StyledCenter>
      }
      buttons={[
        <StyledPrimaryButton
          data-qa="PopUpDoneButton"
          onClick={() => {
            actions.discardDialog()
            actions.emptyDialog()
          }}
          key="done"
        >
          <Trans>Done</Trans>
        </StyledPrimaryButton>
      ]}
    />
  )
}

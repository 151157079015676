import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps, Formik, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton, TextInput } from '@lastpass/lastkit/components'

import { symantecVipSettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/actions'
import {
  SymantecVipSettingsFormData,
  SymantecVipSettingsState
} from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/state'

import { FileUploadComponent } from './FileUploadComponent'
import { OptionsCard } from './OptionsCard'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 516px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const InputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 16px;
`

const FileInputContainer = styled.div`
  width: 100%;
  margin: 8px 12px 16px 0px;
`
const StyledButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin: 8px 0 12px 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const SubTitleContainer = styled.div`
  display: inline-block;
  max-width: 800px;
`

interface SymantecVipSettingsProps {
  expand?: boolean
  closeOtherCards: (expandStatus: boolean) => void
  onFormActivity: (formStatus: boolean) => void
}

const SymantecVipSettingsSubTitle: FunctionComponent = () => {
  return (
    <SubTitleContainer>
      <Trans>
        LastPass supports Symantec VIP authentication. You must provide LastPass
        with a certificate. In Symantec VIP Manager, go to Account {'> '}
        Manage VIP Certificates. Request a certificate for LastPass, then
        download it in PEM format.
      </Trans>
    </SubTitleContainer>
  )
}
const checkFileDataChange = (
  formData: SymantecVipSettingsFormData,
  symantecVipSettingsData: SymantecVipSettingsFormData
): boolean => {
  return (
    formData.certificate === symantecVipSettingsData.certificate &&
    formData.certificateLength === symantecVipSettingsData.certificateLength &&
    formData.certificateName === symantecVipSettingsData.certificateName
  )
}

const hasFormDataChanged = (
  formData: SymantecVipSettingsFormData,
  symantecVipSettingsData: SymantecVipSettingsFormData,
  onFormActivity: (formStatus: boolean) => void
): boolean => {
  const formFileFields = checkFileDataChange(formData, symantecVipSettingsData)
  const formFields = !(
    formData.certificatePassword ===
      symantecVipSettingsData.certificatePassword && formFileFields
  )
  onFormActivity(formFields)
  return formFields
}

interface SymantecVipSettingsFormProps {
  initialValues: SymantecVipSettingsFormData
  handleSubmit: (formValues: SymantecVipSettingsFormData) => void
  onFormActivity: (formStatus: boolean) => void
  shouldReset?: boolean
}

const SymantecVipSettingsForm: FunctionComponent<SymantecVipSettingsFormProps> = ({
  initialValues,
  handleSubmit,
  onFormActivity,
  shouldReset
}) => {
  const formref = useRef<FormikProps<typeof initialValues>>(null)
  useEffect(() => {
    if (shouldReset) {
      if (formref.current) {
        formref.current.resetForm()
      }
    }
  }, [shouldReset])
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formref}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formikProps => {
        return (
          <>
            <FormContainer>
              <FileInputContainer>
                <Field name="certificate">
                  {(formData: FieldProps) => {
                    return (
                      <FileUploadComponent
                        name={formData.field.name}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.files[0]
                          )
                          formikProps.setFieldValue(
                            `${formData.field.name}Length`,
                            e.currentTarget.files[0]
                              ? e.currentTarget.files[0].size
                              : initialValues[`${formData.field.name}Length`]
                          )
                          formikProps.setFieldValue(
                            `${formData.field.name}Name`,
                            e.currentTarget.files[0]
                              ? e.currentTarget.files[0].name
                              : initialValues[`${formData.field.name}Name`]
                          )
                        }}
                        fieldNameLabel={<Trans>Certificate</Trans>}
                        fileLengthInKb={
                          formikProps.values.certificateLength / 1000
                        }
                        fileName={formikProps.values.certificateName}
                        uploadElement={<Trans>Choose file</Trans>}
                      />
                    )
                  }}
                </Field>
              </FileInputContainer>
              <InputContainer>
                <Field name="certificatePassword">
                  {(formData: FieldProps) => {
                    return (
                      <StyledTextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        type="password"
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Certificate password</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </InputContainer>
            </FormContainer>
            <StyledButton
              disabled={
                !(
                  hasFormDataChanged(
                    formikProps.values,
                    initialValues,
                    onFormActivity
                  ) && formikProps.values.certificateLength
                )
              }
              onClick={() => {
                formikProps.handleSubmit()
              }}
              type="submit"
            >
              <Trans>Update</Trans>
            </StyledButton>
          </>
        )
      }}
    </Formik>
  )
}

export const SymantecVipSettingsCard: FunctionComponent<SymantecVipSettingsProps> = ({
  expand,
  closeOtherCards,
  onFormActivity
}) => {
  const symantecVipSettingsState: SymantecVipSettingsState = useSelector(
    (state: AppState) => state.symantecVipSettings
  )
  const dispatch = useDispatch()
  const initialValues: SymantecVipSettingsFormData = {
    certificatePassword:
      symantecVipSettingsState.symantecVipSettings.certificatePassword,
    certificate: null,
    certificateLength:
      symantecVipSettingsState.symantecVipSettings.certificateLength,
    certificateName: ''
  }
  const formKey = JSON.stringify(initialValues)

  return (
    <OptionsCard
      title={<Trans>Symantec VIP</Trans>}
      subtitle={<SymantecVipSettingsSubTitle />}
      loading={symantecVipSettingsState.loading}
      isInitialExpand={!!expand}
      onExpansion={symantecVipSettingsActions.getSymantecVipSettings}
      closeOtherCards={closeOtherCards}
    >
      <SymantecVipSettingsForm
        key={formKey}
        initialValues={initialValues}
        handleSubmit={formValues =>
          dispatch(
            symantecVipSettingsActions.saveSymantecVipSettings(formValues)
          )
        }
        onFormActivity={onFormActivity}
        shouldReset={!expand}
      />
    </OptionsCard>
  )
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { BodyBold, DetailsDialog } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'
import { useBrowserSafeguard } from '@lastpass/ui/hooks'

import { KeyRotationStatusDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
`
const StyledStatusText = styled.div`
  margin-bottom: 20px;
`
const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 20px;
`

interface KeyRotationStatusDialogProps {
  state: KeyRotationStatusDialog
}

export const KeyRotationStatusDialogComponent: FunctionComponent<KeyRotationStatusDialogProps> = ({
  state
}) => {
  useBrowserSafeguard()
  return (
    <DetailsDialog
      closeDialog={() => {}}
      width="600px"
      title={msg`Keep this window open`}
      hideCloseIcon
      disableCloseOnBackground
      content={
        <>
          <TextBoxContainer>
            <StyledStatusText>
              <Trans>
                Don’t close this window. This could take up to a minute, but
                should only take a few seconds.
              </Trans>
            </StyledStatusText>
            <StyledStatusText>
              <Trans>
                <BodyBold>Users are not impacted</BodyBold> during this process.
              </Trans>
            </StyledStatusText>
            {state.providerName && (
              <StyledStatusText>
                <Trans>
                  Be ready to paste the new knowledge component to the service
                  {` (${state.providerName})`}.
                </Trans>
              </StyledStatusText>
            )}
          </TextBoxContainer>
          <StyledLoadingContainer>
            <Loading color="blue900" active={true} />
          </StyledLoadingContainer>
        </>
      }
    />
  )
}

import { SmartCrypto } from '@lastpass/cryptography'

import { encodeString } from '@lastpass/admin-console-dependencies/services/text-operations/encode-string'
import { RecoveryKeySharingDetails } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

const PRIVATE_KEY_PREFIX = 'LastPassPrivateKey<'
const PRIVATE_KEY_POSTFIX = '>LastPassPrivateKey'

const isPrivateKeyValid = (key: string) =>
  key.indexOf(PRIVATE_KEY_PREFIX) === 0 && key.indexOf(PRIVATE_KEY_POSTFIX) > 0

export async function prepareRecoveryKey(
  currentUserEmail: string,
  masterPassword: string,
  requesterAdminIterationCount: RecoveryKeySharingDetails['requesterAdminIterationCount'],
  requesterAdminEncryptedPrivateRsaSharingKey: RecoveryKeySharingDetails['requesterAdminEncryptedPrivateRsaSharingKey']
) {
  let decryptedPrivateKeyRaw = ''
  try {
    const derivedMasterKey = await SmartCrypto.symmetric.deriveMasterKey(
      masterPassword,
      currentUserEmail,
      false,
      requesterAdminIterationCount
    )
    const decryptedPrivateSharingKey = encodeString(
      await SmartCrypto.symmetric.decryptPrivateSharingKey(
        encodeString(
          requesterAdminEncryptedPrivateRsaSharingKey,
          'hex',
          'base64'
        ),
        derivedMasterKey
      ),
      'base64'
    )

    if (isPrivateKeyValid(decryptedPrivateSharingKey)) {
      decryptedPrivateKeyRaw = decryptedPrivateSharingKey.substring(
        PRIVATE_KEY_PREFIX.length,
        decryptedPrivateSharingKey.length - PRIVATE_KEY_POSTFIX.length
      )
    }
  } catch {
    // do nothing
  }

  return decryptedPrivateKeyRaw
}

import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { msg, Trans } from '@lingui/macro'

import { ReactComponent as EmailAccountGreenIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/email-acccount-green.svg'
import { ReactComponent as EmailAccountRedIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/email-acccount-red.svg'
import { BodyBold, PrimaryButton, TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'
import { ActionSuccess } from '@lastpass/admin-console-dependencies/ui/home/components/action-success/ActionSuccess'
import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

export interface ExpiredInvitationsCardProps {
  count: number
  dataQa?: string
}

export const ExpiredInvitationsCard: FC<ExpiredInvitationsCardProps> = ({
  count,
  dataQa
}) => {
  const countColor = useCountColor(count)
  return (
    <ActionCard
      icon={count === 0 ? EmailAccountGreenIcon : EmailAccountRedIcon}
      count={count}
      countColor={countColor}
      title={msg`Expired invitations`}
      description={
        <>
          <Trans>
            Send a follow-up email to folks who didn’t activate their account in
            time.
          </Trans>{' '}
          <BodyBold>
            <TextButton blue>
              <LocationLink
                to="https://link.lastpass.com/uac-view-user-status"
                data-qa="LearnMoreLink"
                target="_blank"
              >
                <Trans>Learn more</Trans>
              </LocationLink>
            </TextButton>
          </BodyBold>
        </>
      }
      action={
        count === 0 ? (
          <ActionSuccess message={msg`No expired invitations`} />
        ) : (
          <Link to="/dashboard/invite-expired">
            <PrimaryButton data-qa="ExpiredInvitationsReinviteButton">
              <Trans>Reinvite users</Trans>
            </PrimaryButton>
          </Link>
        )
      }
      dataQa={dataQa}
    />
  )
}

import { takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { LegacyVpnListActionTypes } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/actions'

import { createLegacyVpnList } from './get-legacyvpn-list'

export function createLegacyVpnSaga(legacyVpnServices: UACServices.Services) {
  const getLegacyVpnList = createLegacyVpnList(legacyVpnServices)

  return function*() {
    yield takeLatest(
      LegacyVpnListActionTypes.GET_LEGACY_VPN_LIST,
      getLegacyVpnList
    )
  }
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  GoogleGroup,
  GoogleUser
} from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

import { PartialSyncItemListContainer } from './StyledComponents'

interface SyncSummaryProps {
  addedGroups: GoogleGroup[]
  addedUsers: GoogleUser[]
  removedGroups: GoogleGroup[]
  removedUsers: GoogleUser[]
}

const ItemList = styled.div`
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  width: 50%;
  box-sizing: border-box;
`

const Container = styled(PartialSyncItemListContainer)`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  margin-top: 5px;
`
const SyncChangeType = styled.div`
  font-weight: normal;
`

export const SyncSummary: FunctionComponent<SyncSummaryProps> = ({
  addedGroups,
  addedUsers,
  removedGroups,
  removedUsers
}) => {
  return (
    <Container>
      <Trans>Users</Trans>
      <ItemList data-qa="SyncSummaryAddedUserList">
        <SyncChangeType>
          <Trans>Added</Trans>
        </SyncChangeType>
        {addedUsers.length === 0 ? (
          <Trans>No user selected</Trans>
        ) : (
          addedUsers.map(user => <div key={user.id}>{user.email}</div>)
        )}
      </ItemList>
      <ItemList data-qa="SyncSummaryRemovedUserList">
        <SyncChangeType>
          <Trans>Removed</Trans>
        </SyncChangeType>
        {removedUsers.length === 0 ? (
          <Trans>No user selected</Trans>
        ) : (
          removedUsers.map(user => <div key={user.id}>{user.email}</div>)
        )}
      </ItemList>
      <Trans>Groups</Trans>
      <ItemList data-qa="SyncSummaryAddedGroupList">
        <SyncChangeType>
          <Trans>Added</Trans>
        </SyncChangeType>
        {addedGroups.length === 0 ? (
          <Trans>No group selected</Trans>
        ) : (
          addedGroups.map(group => <div key={group.id}>{group.name}</div>)
        )}
      </ItemList>
      <ItemList data-qa="SyncSummaryRemovedGroupList">
        <SyncChangeType>
          <Trans>Removed</Trans>
        </SyncChangeType>
        {removedGroups.length === 0 ? (
          <Trans>No group selected</Trans>
        ) : (
          removedGroups.map(group => <div key={group.id}>{group.name}</div>)
        )}
      </ItemList>
    </Container>
  )
}

import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'

export function createUserProfileSaga(userService: UACServices.Services) {
  return function* getUserDrawerSaga(
    action: ReturnType<typeof userDrawerActions.getUserProfile>
  ) {
    try {
      const id = action.payload.params.path.id
      if (id) {
        yield put(userDrawerActions.setUserProfileLoading(true))
        try {
          const result: UACServices.UserProfileAPI.Responses = yield call(
            userService.userProfile,
            id
          )
          if (result.type === UACServices.UserProfileAPI.SUCCESS) {
            const userProfile = result.body
            yield put(userDrawerActions.setUserProfile(userProfile))
          } else if (result.type === UACServices.UserProfileAPI.NOT_FOUND) {
            yield put(push('/users/view'))
          }
        } finally {
          yield put(userDrawerActions.setUserProfileLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

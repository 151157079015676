import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyBold, BodyRegular } from '@lastpass/lastkit'

import { MspSupportDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  BaseSupportDialogComponent,
  StyledTextButton
} from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/BaseSupportDialog'
import {
  GermanSupportPhone,
  PhoneBodyRegular
} from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/SupportPhone'
import {
  SupportCenterSection,
  SupportStatusSection
} from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/SupportSection'

interface MspSupportDialogProps {
  state: MspSupportDialog
}

const StyledList = styled.ul`
  margin-left: 24px;
`
const StyledListItem = styled.li`
  list-style-type: disc;
`

export const MspSupportDialogComponent: FunctionComponent<MspSupportDialogProps> = ({
  state
}) => {
  return (
    <BaseSupportDialogComponent
      sections={[
        <SupportStatusSection key="SupportStatusSection" />,
        <SupportCenterSection key="SupportCenterSection" />
      ]}
      viewSupportRequestsText={
        <Trans>
          <StyledTextButton blue>View your support requests</StyledTextButton>
          <BodyRegular>on the LastPass Partner Allegiance site.</BodyRegular>
        </Trans>
      }
      supportPhones={{
        english: (
          <>
            <PhoneBodyRegular>
              <Trans>
                <BodyBold>Partner support in English</BodyBold> (available 24/7,
                toll-free within the local country):
              </Trans>
            </PhoneBodyRegular>
            <StyledList>
              <StyledListItem>
                <PhoneBodyRegular>
                  <Trans>US Number for partner support 1-800-972-2108</Trans>
                </PhoneBodyRegular>
              </StyledListItem>
              <StyledListItem>
                <PhoneBodyRegular>
                  <Trans>United Kingdom +44-8001026950</Trans>
                </PhoneBodyRegular>
              </StyledListItem>
              <StyledListItem>
                <PhoneBodyRegular>
                  <Trans>Ireland +353-1800851240</Trans>
                </PhoneBodyRegular>
              </StyledListItem>
              <StyledListItem>
                <PhoneBodyRegular>
                  <Trans>Netherlands +31-8000249833</Trans>
                </PhoneBodyRegular>
              </StyledListItem>
              <StyledListItem>
                <PhoneBodyRegular>
                  <Trans>Australia +61 1800 319 808</Trans>
                </PhoneBodyRegular>
              </StyledListItem>
              <StyledListItem>
                <PhoneBodyRegular>
                  <Trans>Germany +49 800 1816533</Trans>
                </PhoneBodyRegular>
              </StyledListItem>
            </StyledList>
            <PhoneBodyRegular>
              <Trans>
                All other locations, use +1-857-314-1321 (carrier charges may
                apply)
              </Trans>
            </PhoneBodyRegular>
          </>
        ),
        german: <GermanSupportPhone />
      }}
      state={state}
    />
  )
}

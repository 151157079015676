import { call } from 'redux-saga/effects'
import { put, select } from 'redux-saga-test-plan/matchers'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/get-role-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  Role,
  RoleResponse
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export const getApplicationDrawerState = (state: AppState) =>
  state.applicationDrawer

export function createRoleList(applicationsServices: UACServices.Services) {
  return function* getRoleList(
    action: ReturnType<typeof applicationsDrawerActions.getRoleList>
  ) {
    try {
      const resultsPerPage = action.payload.resultsPerPage

      yield put(applicationsDrawerActions.setRoleListLoading(true))
      try {
        const SSOApplicationRoleListResult: Responses = yield call(
          applicationsServices.getIdentityRoles
        )
        if (
          SSOApplicationRoleListResult.type ===
          UACServices.SSOIdentityUserAPI.SUCCESS
        ) {
          const applicationDrawerState: ApplicationDrawerState = yield select(
            getApplicationDrawerState
          )
          const ssoIdentityRolesResponse =
            SSOApplicationRoleListResult.body.roleResponse.Value
          const ssoIdentityRoleList: Role[] = ssoIdentityRolesResponse.map(
            (role: RoleResponse) => {
              return {
                id: role.Id,
                name: role.Name
              }
            }
          )
          const keyword = applicationDrawerState.rolesSearchInput.toLowerCase()
          const resultList =
            applicationDrawerState.rolesSearchInput &&
            applicationDrawerState.rolesSearchInput.length > 0
              ? ssoIdentityRoleList.filter(role =>
                  role.name.toLowerCase().includes(keyword)
                )
              : ssoIdentityRoleList
          const filteredResultList = resultList.filter(
            roleFromResult =>
              applicationDrawerState.roles.results.filter(
                roleFromState => roleFromState.id == roleFromResult.id
              ).length === 0
          )
          yield put(
            applicationsDrawerActions.setAvailableRoleList(
              filteredResultList,
              filteredResultList.length,
              resultsPerPage
            )
          )
        }
      } finally {
        yield put(applicationsDrawerActions.setRoleListLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

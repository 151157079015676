import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import {
  connectRedux,
  LastPassComponentProps,
  useUnmountEffect
} from '@lastpass/ui'

import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { DashboardDrawerState } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/state'
import { SecurityScoreCard } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/SecurityScoreCard'

export type UsersReusedMasterPasswordDrawerProps = LastPassComponentProps<
  DashboardDrawerState,
  typeof dashboardDrawerActions,
  { cancelLink: string }
>

export const MasterPasswordListReuseDrawer: React.FunctionComponent<UsersReusedMasterPasswordDrawerProps> = ({
  state,
  actions,
  props: { cancelLink }
}) => {
  const queryParams = useQueryParams(Namespace.weakMasterPasswordUsers)
  const updateQuery = useUpdateQuery(Namespace.weakMasterPasswordUsers)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      dashboardDrawerActions.getMasterPasswordReuse({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])
  useUnmountEffect(() => {
    actions.reset()
  })

  return (
    <>
      {state.dashboardDrawerLoading && (
        <Loading color="blue900" active={true} />
      )}

      <SecurityScoreCard
        users={state.usersReusingMasterPassword}
        queryParams={queryParams}
        updateQuery={updateQuery}
        noRecordsText={
          <Trans>
            No one is reusing their master password for other accounts
          </Trans>
        }
        qaData="UsersWithWeakMasterPasswordDrawerTable"
      />

      <>
        <StyledSpacer />
        <FooterContainer>
          <DrawerButtonsContainer>
            {state.usersReusingMasterPassword.totalResults > 0 ? (
              <>
                <LocationLink to={cancelLink}>
                  <TextButton>
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  onClick={() => {
                    actions.exportMasterPasswordReuse()
                    actions.exportUsersClicked(
                      'Reuse Master Password List Drawer'
                    )
                  }}
                  css={css`
                    margin-left: 16px;
                  `}
                >
                  <Trans>Export users</Trans>
                </PrimaryButton>
              </>
            ) : (
              <LocationLink to={cancelLink}>
                <PrimaryButton>
                  <Trans>Cancel</Trans>
                </PrimaryButton>
              </LocationLink>
            )}
          </DrawerButtonsContainer>
        </FooterContainer>
      </>
    </>
  )
}

export function createMasterPasswordReuseDrawer<
  TState extends { dashboardDrawer: DashboardDrawerState }
>() {
  return connectRedux(
    MasterPasswordListReuseDrawer,
    (state: TState) => state.dashboardDrawer,
    dashboardDrawerActions
  )
}

export type MasterPasswordReuseDrawer = ReturnType<
  typeof createMasterPasswordReuseDrawer
>

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import {
  BodyRegular,
  Collapsible,
  Heading100,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { StyledSpacer } from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { createSupportLink } from '@lastpass/admin-console-dependencies/services/support-operations/create-support-link'
import {
  createApplication,
  SelectedApplication
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { applicationListActions } from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { SetupApp } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp/SetupApp'

import { createSetupLastPass } from './ConfigureApp/SetupLastPass'

const SetupLastPass = createSetupLastPass<AppState>()

export interface ConfigureAppProps {
  closeLink: string
}

export const BodyText = styled.div`
  margin-bottom: 24px;
`

export const BodyTitle = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`

export const StyledAppIcon = styled.img`
  float: left;
  width: 48px;
  height: 48px;
  object-fit: contain;
  margin-right: 16px;
`

const CleanFormValues = values => {
  if (values.samlAttributes && values.samlAttributes.length > 0) {
    values.samlAttributes = values.samlAttributes.filter(
      a => a.Type || a.AttributeId
    )
  }

  return values
}

export const ConfigureApp: FunctionComponent<ConfigureAppProps> = ({
  closeLink
}) => {
  const appDrawerState = useSelector<AppState, ApplicationDrawerState>(
    state => state.applicationDrawer
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const pathParams = usePathParams()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const UserAttributes = [
    { value: 'Email', label: i18n._(msg`Email`) },
    { value: 'Secondary Email', label: i18n._(msg`Secondary Email`) },
    { value: 'User ID', label: i18n._(msg`User ID`) },
    { value: 'Groups', label: i18n._(msg`Groups`) },
    { value: 'Roles', label: i18n._(msg`Roles`) }
  ]

  useEffect(() => {
    dispatch(
      applicationsDrawerActions.getInitialData({
        query: {},
        path: pathParams
      })
    )
    dispatch(applicationsDrawerActions.onConfigureAppViewed())
  }, [dispatch, pathParams])

  const app: SelectedApplication = appDrawerState.selectedApplication
    ? appDrawerState.selectedApplication
    : createApplication(false)

  const continueButtonText = app.id ? (
    <Trans>Save & edit users</Trans>
  ) : (
    <Trans>Save & assign users</Trans>
  )

  const yupSchema = Yup.object().shape({
    ACS: Yup.string()
      .url(i18n._(msg`ACS must be a valid URL`))
      .required(i18n._(msg`ACS is required`)),
    stepUp: Yup.boolean(),
    entityId: Yup.string().required(i18n._(msg`Entity ID is required`)),
    nickName: Yup.string().required(i18n._(msg`Nickname is required`)),
    role: Yup.string().nullable(),
    identityProvider: Yup.string()
      .url(i18n._(msg`Identity provider must be a valid URL`))
      .required(i18n._(msg`Identity provider is required`)),
    relayState: Yup.string().nullable(),
    identifier: Yup.string(),
    SAMLSignatureMethod: Yup.string(),
    signAssertion: Yup.boolean(),
    encryptAssertion: Yup.boolean(),
    signRequest: Yup.boolean(),
    signResponse: Yup.boolean()
  })

  const logo = app.customLogoUrl.length ? (
    <StyledAppIcon src={app.customLogoUrl} />
  ) : null

  const supportLink: string = createSupportLink(app.name)
  const collapsibleProps =
    location.pathname.split('/').pop() === 'add'
      ? {
          initialExpanded: true
        }
      : null

  return (
    <>
      <BodyTitle>
        {logo}
        <Heading100 data-qa="ConfigureSsoAppName">{app.name}</Heading100>
      </BodyTitle>
      <BodyRegular>
        <BodyText>
          {/*eslint-disable*/}
          <Trans>
            To manage user logins using SAML, you must establish trust between
            the Identity Provider (LastPass) and the Service Provider ({' '}
            {app.name}) by entering data that allows the two parties to identify
            each other.
          </Trans>
          {/*eslint-enable*/}
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={supportLink}
              data-qa="ConfigureAppStepByStepLink"
            >
              <Trans>Read step-by-step instructions</Trans>
            </a>
          </div>
        </BodyText>
      </BodyRegular>
      <Formik
        initialValues={{
          certificateId: app.samlKeyId,
          ACS: app.HTMLInfo,
          stepUp: app.useStepUpAuthentication,
          entityId: app.partnerName ? app.partnerName : app.defaultPartnerName,
          nickName: app.name,
          role: app.role,
          identityProvider: app.identityProvider
            ? app.identityProvider
            : defaultConfig.identityIframeUrl,
          relayState: app.relayState,
          identifier: app.attributes ? app.attributes : 'Email',
          SAMLSignatureMethod: app.useSHA256ForSigning ? 'SHA256' : 'SHA1',
          signAssertion: app.signAssertion,
          encryptAssertion: app.encryptAssertion,
          signRequest: app.wantAuthnRequestSigned,
          signResponse: app.signSAMLResponse,
          samlAttributes: app.samlAttributes,
          closeLink: closeLink,
          closeAfterSave: false
        }}
        enableReinitialize={true}
        validationSchema={yupSchema}
        validateOnBlur={false}
        onSubmit={(values, formikActions) => {
          setIsBlockingPromptEnabled(false)
          formikActions.setSubmitting(true)

          dispatch(
            applicationsDrawerActions.saveAndExit(
              app,
              CleanFormValues(values),
              () => {
                formikActions.setSubmitting(false)
              },
              appDrawerState.users.results.length > 0 ||
                appDrawerState.groups.results.length > 0 ||
                appDrawerState.roles.results.length > 0
            )
          )
        }}
      >
        {formikProps => (
          <Form onChange={() => setIsBlockingPromptEnabled(true)}>
            <Collapsible
              title={<Trans>1. Set up app</Trans>}
              onExpand={formikProps.validateForm}
              qadata={'SetUpAppCard'}
            >
              <SetupApp formikProps={formikProps} />
            </Collapsible>
            <Collapsible
              title={<Trans>2. Set up LastPass</Trans>}
              onExpand={formikProps.validateForm}
              qadata={'SetUpLastPassCard'}
              {...collapsibleProps}
            >
              <SetupLastPass
                formikProps={formikProps}
                userAttributes={[
                  ...UserAttributes,
                  ...appDrawerState.usernameAttributes
                ]}
                selectedAppId={app.id}
              />
            </Collapsible>
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <TextButton
                  css={css`
                    margin-right: 16px;
                  `}
                  key={'Discard'}
                  type="button"
                  onClick={() => {
                    history.push(closeLink)
                    dispatch(
                      applicationListActions.onConfigureAppClicked('Discard')
                    )
                  }}
                  data-qa={'DiscardButton'}
                >
                  <Trans>Discard</Trans>
                </TextButton>
                <TextButton
                  blue
                  css={css`
                    margin-right: 16px;
                  `}
                  key={'SaveAndExit'}
                  type="submit"
                  disabled={
                    (!formikProps.isValid &&
                      (formikProps.dirty || app.id == '')) ||
                    formikProps.isSubmitting
                  }
                  data-qa={'SaveAndExitButton'}
                  onClick={() => {
                    formikProps.setFieldValue('closeAfterSave', true)
                    dispatch(
                      applicationListActions.onConfigureAppClicked(
                        'Save and Close'
                      )
                    )
                  }}
                >
                  <Trans>Save & exit</Trans>
                </TextButton>
                <PrimaryButton
                  id={'ContinueButton'}
                  type="submit"
                  key={'Continue'}
                  onClick={() => {
                    dispatch(applicationsDrawerActions.onAssignUsersClicked())
                    formikProps.setFieldValue('closeAfterSave', false)
                  }}
                  disabled={
                    (!formikProps.isValid &&
                      (formikProps.dirty || app.id == '')) ||
                    formikProps.isSubmitting
                  }
                  data-qa={
                    app.id
                      ? 'SaveAndEditUsersButton'
                      : 'SaveAndAssignUsersButton'
                  }
                >
                  {continueButtonText}
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

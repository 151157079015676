import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import { OpenIdProvider, openIdProviderNameMapping } from '@lastpass/federation'
import { CompanyWideIdpData } from '@lastpass/federation/types/key-rotation'

import { keyRotationAuthenticateCompanyAdmin } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-authenticate-company-admin'
import { keyRotationCreateNewKeysUserWide } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-create-new-keys-user-wide'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  KeyRotationAlertDialog,
  KeyRotationCompanyWideDoneDialog,
  KeyRotationCopyK1Dialog,
  KeyRotationGeneralDialog,
  KeyRotationInstructionsDialog,
  KeyRotationSaveK1Dialog,
  KeyRotationStartProcessDialog,
  KeyRotationUpdateK2ComponentsDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'

import { keyRotationCreateNewKeysCompanyWide } from './key-rotation-create-new-keys-company-wide'
import { KeyRotationError } from './key-rotation-error'
import { keyRotationErrorsCompanyWide } from './key-rotation-errors-company-wide'
import { keyRotationErrorsUserWide } from './key-rotation-errors-user-wide'
import { keyRotationGetCompanyWideK1 } from './key-rotation-get-company-wide-k-1'

export const keyRotationDebug = (...args) => {
  if (!args.length) {
    return
  }
  // console.debug(...args)
}

export function* keyRotation(
  userService: UACServices.Services,
  action: ReturnType<typeof federatedLoginActions.startedKeyRotation>
) {
  const provider: OpenIdProvider = action.payload.provider
  const providerName = openIdProviderNameMapping[provider]

  const keyRotationPreValidationDialog: KeyRotationInstructionsDialog = {
    type: 'key-rotation-instructions-dialog',
    title: msg`Before you begin`,
    subtitle: msg`Before you can start the rotation process, you must first make some changes in your ${providerName} software. This process will allow LastPass and ${providerName} to stay in sync during the rotation process.`,
    checkboxText: msg`I’ve completed the prerequisite changes in ${providerName}`
  }

  const keyRotationDeleteAppDialog: KeyRotationInstructionsDialog = {
    type: 'key-rotation-instructions-dialog',
    title: msg`Delete app from ${providerName}`,
    subtitle: msg`Your K1 and K2 knowledge components have been rotated.`,
    checkboxText: msg`I’ve deleted the app from my service (${providerName})`,
    contentText: msg`For your security, delete the app you initially created in the ${providerName} software.`
  }

  const keyRotationGeneralDialog: KeyRotationGeneralDialog = {
    type: 'key-rotation-general-dialog',
    provider: provider
  }
  const keyRotationStartProcessDialog: KeyRotationStartProcessDialog = {
    type: 'key-rotation-start-process-dialog'
  }
  const keyRotationUpdateK2ComponentsDialog: KeyRotationUpdateK2ComponentsDialog = {
    type: 'key-rotation-update-k2-components-dialog',
    providerName: providerName
  }
  const keyRotationCopyK1Dialog: KeyRotationCopyK1Dialog = {
    type: 'key-rotation-copy-k1-dialog',
    providerName: providerName,
    k1: ''
  }

  const keyRotationUserWideDoneDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Well done`,
    subtitle: msg`Your K1 and K2 knowledge components have been rotated.`,
    text: msg`What next? Test the rotated components by logging in to LastPass using a federated account. Contact LastPass if you experience any issues.`,
    buttonText: msg`All set`
  }

  const keyRotationAlertDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Process failed`,
    subtitle: msg`There was an error rotating your knowledge components for the service (${providerName}).`,
    text: msg`Please try again. If the problem persists, please contact LastPass support.`
  }

  const keyRotationCompanyWideDoneDialog: KeyRotationCompanyWideDoneDialog = {
    type: 'key-rotation-company-wide-done-dialog'
  }

  const keyRotationSaveK1Dialog: KeyRotationSaveK1Dialog = {
    type: 'key-rotation-save-k1-dialog',
    k1: ''
  }

  let companyWideK1Keys: CompanyWideIdpData | undefined = undefined

  try {
    yield put(globalActions.setDialog(keyRotationGeneralDialog))
    const keyRotationGeneralDialogAction = yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])
    if (
      keyRotationGeneralDialogAction.type != GlobalActionTypes.CONFIRM_DIALOG
    ) {
      return
    }

    if (action.payload.isCompanyWideK1) {
      // company-wide K1, show an input dialog popup
      companyWideK1Keys = yield call(keyRotationGetCompanyWideK1, providerName)
      if (companyWideK1Keys?.newK1) {
        yield put(
          globalActions.setDialog({
            ...keyRotationSaveK1Dialog,
            k1: companyWideK1Keys.newK1.toString('ascii')
          })
        )
        const keyRotationSaveK1DialogAction = yield take([
          GlobalActionTypes.CONFIRM_DIALOG,
          GlobalActionTypes.DISCARD_DIALOG
        ])
        if (
          keyRotationSaveK1DialogAction.type != GlobalActionTypes.CONFIRM_DIALOG
        ) {
          return
        }
      } else {
        // If keyRotationGetCompanyWideK1 has no return value (companyWideK1Keys), the rotation flow is discarded.
        return
      }

      yield put(globalActions.setDialog(keyRotationUpdateK2ComponentsDialog))
      const keyRotationUpdateK2ComponentsDialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (
        keyRotationUpdateK2ComponentsDialogAction.type !=
        GlobalActionTypes.CONFIRM_DIALOG
      ) {
        return
      }
    } else {
      yield put(globalActions.setDialog(keyRotationPreValidationDialog))
      const keyRotationPreValidationDialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (
        keyRotationPreValidationDialogAction.type !=
        GlobalActionTypes.CONFIRM_DIALOG
      ) {
        return
      }

      // user-wide K1, company admin idp auth dialog
      const isAuthenticated = yield call(
        keyRotationAuthenticateCompanyAdmin,
        providerName
      )
      if (!isAuthenticated) {
        // If keyRotationAuthenticateCompanyAdmin returns false, the authentication and rotation flow are discarded.
        return
      }

      yield put(globalActions.setDialog(keyRotationStartProcessDialog))
      const keyRotationStartProcessDialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (
        keyRotationStartProcessDialogAction.type !=
        GlobalActionTypes.CONFIRM_DIALOG
      ) {
        return
      }
    }

    if (action.payload.isCompanyWideK1 && companyWideK1Keys) {
      yield call(
        keyRotationCreateNewKeysCompanyWide,
        userService,
        companyWideK1Keys,
        providerName
      )
      let isConfirmed = false
      while (!isConfirmed) {
        yield put(
          globalActions.setDialog({
            ...keyRotationCopyK1Dialog,
            k1: companyWideK1Keys.newK1.toString('ascii')
          })
        )
        yield take(GlobalActionTypes.CONFIRM_DIALOG)

        yield put(globalActions.setDialog(keyRotationCompanyWideDoneDialog))

        const confirmAction = yield take(GlobalActionTypes.CONFIRM_DIALOG)
        isConfirmed = confirmAction.payload.data.isConfirmed
      }
    }

    if (!action.payload.isCompanyWideK1) {
      const isKeyRotationCreateNewKeysUserWideExecuted = yield call(
        keyRotationCreateNewKeysUserWide,
        userService,
        provider
      )

      // If interrupted session dialog is discarded, keyRotationCreateNewKeysUserWide returns false and rotation flow is dicarded.
      if (!isKeyRotationCreateNewKeysUserWideExecuted) {
        return
      }

      yield put(globalActions.setDialog(keyRotationDeleteAppDialog))
      const keyRotationDeleteAppDialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (
        keyRotationDeleteAppDialogAction.type !=
        GlobalActionTypes.CONFIRM_DIALOG
      ) {
        return
      }

      yield put(globalActions.setDialog(keyRotationUserWideDoneDialog))
      const keyRotationUserWideDoneDialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (
        keyRotationUserWideDoneDialogAction.type !=
        GlobalActionTypes.CONFIRM_DIALOG
      ) {
        return
      }
    }

    yield put(federatedLoginActions.finishedKeyRotation(provider))
  } catch (error) {
    if (error instanceof KeyRotationError && action.payload.isCompanyWideK1) {
      yield call(keyRotationErrorsCompanyWide, providerName, error)
    } else if (
      error instanceof KeyRotationError &&
      provider === OpenIdProvider.AzureAD
    ) {
      yield call(keyRotationErrorsUserWide, providerName, error)
    } else {
      yield put(globalActions.setDialog(keyRotationAlertDialog))
    }
    yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])
  }

  yield put(globalActions.emptyDialog())
}

import { useHistory } from 'react-router-dom'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import { useQueryParams } from './use-query-params'

export const useUpdateQuery = (namespace: Namespace) => {
  const queryParams = useQueryParams(namespace)
  const history = useHistory()
  const updateQuery = update => {
    const updateUrlSearchParams = new URLSearchParams(location.search)
    const updatedQueryParams = {
      ...queryParams,
      ...update
    }
    const serializedQueryParams = JSON.stringify(updatedQueryParams)

    if (serializedQueryParams !== '{}') {
      updateUrlSearchParams.set(
        namespace,
        Buffer.from(serializedQueryParams).toString('base64')
      )
    } else {
      updateUrlSearchParams.delete(namespace)
    }

    history.replace(`${location.pathname}?${updateUrlSearchParams.toString()}`)
  }
  return updateQuery
}

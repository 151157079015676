import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GroupResponse } from '@lastpass/admin-console-dependencies/types/group'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'

const success = (group: GroupResponse) => createResponse(SUCCESS, group)
const notFound = () => createResponse(NOT_FOUND)

export const Responses = {
  success,
  notFound
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(groupId: string) {
    const [response, status] = await fetch('groups/' + groupId, 'GET')
    if (status === StatusCodes.OK && response) {
      const group = JSON.parse(response).content
      return success(group)
    } else if (status === StatusCodes.NOT_FOUND) {
      return notFound()
    }
    throw new UnknownServiceError()
  }
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { groupFilterActions } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGroupSearchSaga(
  groupFilterService: UACServices.Services
) {
  return function* getGroupSearchSaga(
    action: ReturnType<typeof groupFilterActions.searchGroups>
  ) {
    try {
      const query = buildQuery({
        ...action.payload.params.query,
        search: encodeSearch(action.payload.params.query.search),
        top: action.payload.resultsPerPage
      })
      const result: UACServices.GroupFilterAPI.Responses = yield call(
        groupFilterService.groupFilter,
        query
      )
      if (result.type === UACServices.GroupListAPI.SUCCESS) {
        const groups = result.body.groupsList
        yield put(
          groupFilterActions.setGroups(
            groups,
            action.payload.params.query.search || ''
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

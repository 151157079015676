import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import { Trans } from '@lingui/macro'

import { TabNavigation } from '@lastpass/lastkit'
import { RoutedTabNavigationItem } from '@lastpass/lastkit'

import { SubscriptionType } from '@lastpass/admin-console-dependencies/state/company/state'
import { CheckPermission } from '@lastpass/admin-console-dependencies/ui/common/CheckPermission'
import { createTrustedMfaDevicesTableComponent } from '@lastpass/admin-console-dependencies/ui/users/view/TrustedMfaDevicesTable'
import { createTrustedWorkstationsTableComponent } from '@lastpass/admin-console-dependencies/ui/users/view/TrustedWorkstationsTable'
import { createUserDrawerTrustedLpDevicesTable } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerTrustedLpDevicesTable'

const TrustedLpDevicesTable = createUserDrawerTrustedLpDevicesTable()
const TrustedMfaDevicesTable = createTrustedMfaDevicesTableComponent()
const TrustedWorkstationsTable = createTrustedWorkstationsTableComponent()

export const UserDrawerTrustedDevicesPage: React.FunctionComponent = () => {
  const match = useRouteMatch()
  if (match) {
    const { path, url } = match

    return (
      <>
        <Route path={[`${path}`, `${path}/mfa`, `${path}/workstation`]} exact>
          <TabNavigation>
            <RoutedTabNavigationItem
              to={`${url}`}
              route={{ path: `${url}`, exact: true }}
            >
              <Trans>Password manager</Trans>
            </RoutedTabNavigationItem>
            <CheckPermission
              requiredSubscription={[
                SubscriptionType.iDaaS,
                SubscriptionType.mfa
              ]}
            >
              <RoutedTabNavigationItem
                to={`${url}/mfa`}
                route={{ path: `${url}/mfa` }}
              >
                <Trans>LastPass MFA</Trans>
              </RoutedTabNavigationItem>
              <RoutedTabNavigationItem
                to={`${url}/workstation`}
                route={{ path: `${url}/workstation` }}
              >
                <Trans>Workstation Login</Trans>
              </RoutedTabNavigationItem>
            </CheckPermission>
          </TabNavigation>
        </Route>
        <Switch>
          <Route path={`${path}`} exact>
            <TrustedLpDevicesTable />
          </Route>
          <CheckPermission
            requiredSubscription={[
              SubscriptionType.iDaaS,
              SubscriptionType.mfa
            ]}
          >
            <Route path={`${path}/mfa`} exact>
              <TrustedMfaDevicesTable />
            </Route>
            <Route path={`${path}/workstation`} exact>
              <TrustedWorkstationsTable />
            </Route>
          </CheckPermission>
        </Switch>
      </>
    )
  }
  return null
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'

export interface FeatureDetail {
  title: React.ReactElement
  featureLinkText: React.ReactElement
  featureLinkUrl: string
  isExternalFeatureLink: boolean
  detailsLinkUrl: string
  name: string
}

export const RequirePasswordlessVerificationViaLastPassAuthenticator: FeatureDetail = {
  title: (
    <Trans>Require passwordless verification via LastPass Authenticator</Trans>
  ),
  featureLinkText: (
    <Trans>
      Applications {'>'} Passwordless apps {'>'} SSO apps tab
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/authenticator',
  featureLinkUrl: 'applications/passwordless/ssoapps',
  isExternalFeatureLink: false,
  name: 'RequirePasswordlessVerificationViaLastPassAuthenticator'
}

export const KeysExistingOauthSAML: FeatureDetail = {
  title: <Trans>Keys (Existing, OAuth, SAML)</Trans>,
  featureLinkText: <Trans>Advanced {'>'} Keys</Trans>,
  detailsLinkUrl: '/systemupgrade/keys',
  featureLinkUrl: '/advanced/keys',
  isExternalFeatureLink: false,
  name: 'KeysExistingOauthSAML'
}

export const IdentityRoles: FeatureDetail = {
  title: <Trans>Identity Roles</Trans>,
  featureLinkText: (
    <Trans>
      Legacy Identity console {'>'} Users {'>'} Roles
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/identityroles',
  featureLinkUrl: `${defaultConfig.identityIframeUrl}/Users/Roles`,
  isExternalFeatureLink: true,
  name: 'IdentityRoles'
}

export const CustomAttributes: FeatureDetail = {
  title: <Trans>Custom attributes</Trans>,
  featureLinkText: (
    <Trans>
      Legacy Identity console {'>'} Users {'>'} Settings {'>'} User attributes
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/customattributes',
  featureLinkUrl: `${defaultConfig.identityIframeUrl}/Users/Settings`,
  isExternalFeatureLink: true,
  name: 'CustomAttributes'
}

export const Geofences: FeatureDetail = {
  title: <Trans>Geofences</Trans>,
  featureLinkText: (
    <Trans>
      Policies {'>'} Passwordless {'>'} Geofence
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/geofence',
  featureLinkUrl: '/policies/passwordless/geofences',
  isExternalFeatureLink: false,
  name: 'Geofences'
}

export const AccessPolicies: FeatureDetail = {
  title: <Trans>Access Policies</Trans>,
  featureLinkText: (
    <Trans>
      Policies {'>'} Passwordless {'>'} Access Policies
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/accesspolicy',
  featureLinkUrl: '/policies/passwordless/accesspolicies',
  isExternalFeatureLink: false,
  name: 'AccessPolicies'
}

export const IPAllowDeny: FeatureDetail = {
  title: <Trans>IP allow/deny</Trans>,
  featureLinkText: (
    <Trans>
      Policies {'>'} Passwordless {'>'} IP allow/deny
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/ipallowdeny',
  featureLinkUrl: '/policies/passwordless/ip',
  isExternalFeatureLink: false,
  name: 'IPAllowDeny'
}

export const AuthenticationAndRecovery: FeatureDetail = {
  title: <Trans>Authentication {'&'} Recovery</Trans>,
  featureLinkText: (
    <Trans>
      Policies {'>'} Passwordless {'>'} Authentication
    </Trans>
  ),
  detailsLinkUrl: '/systemupgrade/authentication',
  featureLinkUrl: '/policies/passwordless/authentication',
  isExternalFeatureLink: false,
  name: 'AuthenticationAndRecovery'
}

import { useDispatch, useSelector } from 'react-redux'

import { saveAs } from 'file-saver'
import Papa from 'papaparse'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export const useDecryptSecurityReportOnComplete = () => {
  const dispatch = useDispatch()
  const { downloadReportName, totalNumberOfCsvRows } = useSelector(
    (state: AppState) => state.securityReportDrawer
  )

  const onComplete = (
    csvContents,
    numberOfUrlsWithDecryptionFailure: number
  ) => {
    const csv = Papa.unparse(csvContents)
    const BOM = '\uFEFF'
    const csvFile = new Blob([BOM + csv], {
      type: 'text/csv;charset=utf-8;'
    })
    const numberOfHeaderRow = 1
    const numberOfUrls = totalNumberOfCsvRows - numberOfHeaderRow

    const isFullFailure = numberOfUrlsWithDecryptionFailure === numberOfUrls
    const isPartialSuccess =
      numberOfUrlsWithDecryptionFailure > 0 &&
      numberOfUrlsWithDecryptionFailure !== numberOfUrls

    if (numberOfUrlsWithDecryptionFailure === 0) {
      saveAs(csvFile, `${downloadReportName}.csv`)
      dispatch(globalActions.emptyDialog())
    } else if (isFullFailure) {
      dispatch(
        globalActions.setDialog({
          type: 'error-decrypting-url-data-dialog'
        })
      )
    } else if (isPartialSuccess) {
      dispatch(
        globalActions.setDialog({
          type: 'not-all-url-data-could-be-decrypted-dialog',
          numberOfUrls,
          numberOfDecryptedUrls:
            numberOfUrls - numberOfUrlsWithDecryptionFailure,
          csvFile
        })
      )
    }

    csvContents = []
    dispatch(globalActions.setDecryptedPrivateSharingKey(''))
    dispatch(globalActions.setVaultKey(Buffer.from('')))
  }

  return onComplete
}

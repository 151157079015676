import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { MultifactorOption } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/state'

export const SUCCESS = 'success'

const success = (enabledMultifactorOptions: MultifactorOption[]) =>
  createResponse(SUCCESS, { enabledMultifactorOptions })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>
export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'v3/company/multifactor-options',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const responseData = JSON.parse(response)
        const options = responseData.content
        return success(options)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { SegmentEvent } from './types'

export const TRACKING_SEGMENT_ACTION = '[tracking] segment event'

/**
 * This should only be used in cases where the foreground cannot make requests to the tracking services (eg. content script)
 */
export const trackingSegmentEvent = (event: SegmentEvent) => ({
  type: TRACKING_SEGMENT_ACTION,
  payload: event
})

export type TrackingAction = ReturnType<typeof trackingSegmentEvent>

export function isTrackingAction(action): action is TrackingAction {
  return action.type === TRACKING_SEGMENT_ACTION
}

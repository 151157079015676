import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { ReactComponent as CopyDuplicateIcon } from '@lastpass/assets/svg/admin-console/copy-duplicate-icon.svg'
import { ReactComponent as OneLoginIcon } from '@lastpass/assets/svg/admin-console/directories/icon-onelogin.svg'
import {
  OpenIdProvider,
  openIdProviderNameMapping
} from '@lastpass/federation/lib/federation-enums'
import { generateRandomString } from '@lastpass/federation/utils/random-string-generator'
import {
  BodyRegular,
  Checkbox,
  LogoCard,
  PrimaryButton,
  TextInput
} from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  FederatedLoginState,
  OpenIdSetupState
} from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import { KcRotationAndVaultReEncryptionBlock } from './KcRotationAndVaultReEncryptionBlock'
import { KeyRotationBlock } from './KeyRotationBlock'
import {
  BoldWrapper,
  GeneratedKeyContainer,
  GeneratedKeyTextInputContainer,
  StyledCardContainer,
  StyledChildRow,
  StyledExternalLink,
  StyledPrimaryButton,
  StyledSubtitle,
  StyledTextInput
} from './StyledComponents'
import { VaultReEncryptionBlock } from './VaultReEncryptionBlock'

interface OpenIdSetupFormProps {
  handleSubmit: (openIdSetup: OpenIdSetupState) => void
  authority: string
  clientId: string
  enabled: boolean
  isOneLoginSetupActive: boolean
}

const getClientIds = clientId => {
  try {
    return JSON.parse(clientId)
  } catch {
    return {
      spaClientId: clientId,
      iosClientId: '',
      androidClientId: '',
      desktopClientId: ''
    }
  }
}

const OneLoginSetupForm: FunctionComponent<OpenIdSetupFormProps> = ({
  handleSubmit,
  authority,
  clientId,
  enabled,
  isOneLoginSetupActive
}) => {
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )

  const clientIdCollection = getClientIds(clientId)

  const validationSchema = Yup.object().shape({
    oneLoginAuthority: Yup.string()
      .url(i18n._(msg`OpenID Connect metadata document URL must be a URL`))
      .required(i18n._(msg`OpenID Connect metadata document URL is required`))
      .nullable(),
    oneLoginWebClientId: Yup.string()
      .required(i18n._(msg`Web application Client ID is required`))
      .nullable(),
    oneLoginiOSClientId: Yup.string()
      .required(i18n._(msg`iOS Client ID is required`))
      .nullable(),
    oneLoginAndroidClientId: Yup.string()
      .required(i18n._(msg`Android Client ID is required`))
      .nullable(),
    oneLoginDesktopClientId: Yup.string()
      .required(i18n._(msg`Desktop app Client ID is required`))
      .nullable()
  })

  const initialValues = {
    oneLoginAuthority: isOneLoginSetupActive ? authority : '',
    oneLoginWebClientId: isOneLoginSetupActive
      ? clientIdCollection.spaClientId
      : '',
    oneLoginiOSClientId: isOneLoginSetupActive
      ? clientIdCollection.iosClientId
      : '',
    oneLoginAndroidClientId: isOneLoginSetupActive
      ? clientIdCollection.androidClientId
      : '',
    oneLoginDesktopClientId: isOneLoginSetupActive
      ? clientIdCollection.desktopClientId
      : '',
    oneLoginEnabled: isOneLoginSetupActive ? enabled : false,
    oneLoginUseAdConnector: false,
    oneLoginAuthCompanyWideKey: false,
    oneLoginIsEmailHintDisabled: false,
    oneLoginPkceEnabled: false
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={values => {
        handleSubmit({
          openIdUrl: values.oneLoginAuthority.trim(),
          clientId: JSON.stringify({
            spaClientId: values.oneLoginWebClientId.trim(),
            iosClientId: values.oneLoginiOSClientId.trim(),
            androidClientId: values.oneLoginAndroidClientId.trim(),
            desktopClientId: values.oneLoginDesktopClientId.trim()
          }).replace(/\\"/g, '"'),
          isEnabled: values.oneLoginEnabled,
          useLastPassADConnectorToSyncUsers: values.oneLoginUseAdConnector,
          useOktaAuthServerToStoreCompanyKey: values.oneLoginAuthCompanyWideKey,
          provider: OpenIdProvider.OneLogin,
          isEmailHintDisabled: values.oneLoginIsEmailHintDisabled,
          pkceEnabled: values.oneLoginPkceEnabled,
          useAzureMdmFlow: false
        })
      }}
    >
      {formikProps => (
        <Form>
          <StyledChildRow>
            <Field name="oneLoginAuthority">
              {formData => (
                <StyledTextInput
                  data-qa="OneLoginAuthorityInput"
                  name={formData.field.name}
                  value={formData.field.value}
                  onChange={formData.field.onChange}
                  readOnly={!hasDirectoriesAndFederationModifyPermission}
                  error={!!formikProps.errors.oneLoginAuthority}
                  errorText={<>{formikProps.errors.oneLoginAuthority}</>}
                >
                  <Trans>OpenID Connect metadata document URL</Trans>
                </StyledTextInput>
              )}
            </Field>
          </StyledChildRow>
          <StyledChildRow>
            <Field name="oneLoginWebClientId">
              {formData => (
                <StyledTextInput
                  data-qa="OneLoginWebClientIdInput"
                  name={formData.field.name}
                  value={formData.field.value}
                  onChange={formData.field.onChange}
                  readOnly={!hasDirectoriesAndFederationModifyPermission}
                  error={!!formikProps.errors.oneLoginWebClientId}
                  errorText={<>{formikProps.errors.oneLoginWebClientId}</>}
                >
                  <Trans>Web application Client ID</Trans>
                </StyledTextInput>
              )}
            </Field>
          </StyledChildRow>
          <StyledChildRow>
            <Field name="oneLoginiOSClientId">
              {formData => (
                <StyledTextInput
                  data-qa="OneLoginiOSClientIdInput"
                  name={formData.field.name}
                  value={formData.field.value}
                  onChange={formData.field.onChange}
                  readOnly={!hasDirectoriesAndFederationModifyPermission}
                  error={!!formikProps.errors.oneLoginiOSClientId}
                  errorText={<>{formikProps.errors.oneLoginiOSClientId}</>}
                >
                  <Trans>iOS Client ID</Trans>
                </StyledTextInput>
              )}
            </Field>
          </StyledChildRow>
          <StyledChildRow>
            <Field name="oneLoginAndroidClientId">
              {formData => (
                <StyledTextInput
                  data-qa="OneLoginAndroidClientIdInput"
                  name={formData.field.name}
                  value={formData.field.value}
                  onChange={formData.field.onChange}
                  readOnly={!hasDirectoriesAndFederationModifyPermission}
                  error={!!formikProps.errors.oneLoginAndroidClientId}
                  errorText={<>{formikProps.errors.oneLoginAndroidClientId}</>}
                >
                  <Trans>Android Client ID</Trans>
                </StyledTextInput>
              )}
            </Field>
          </StyledChildRow>
          <StyledChildRow>
            <Field name="oneLoginDesktopClientId">
              {formData => (
                <StyledTextInput
                  data-qa="OneLoginDesktopClientIdInput"
                  name={formData.field.name}
                  value={formData.field.value}
                  onChange={formData.field.onChange}
                  readOnly={!hasDirectoriesAndFederationModifyPermission}
                  error={!!formikProps.errors.oneLoginDesktopClientId}
                  errorText={<>{formikProps.errors.oneLoginDesktopClientId}</>}
                >
                  <Trans>Desktop app Client ID</Trans>
                </StyledTextInput>
              )}
            </Field>
          </StyledChildRow>
          <StyledChildRow>
            <Field name="oneLoginEnabled">
              {formData => (
                <Checkbox
                  data-qa="OneLoginEnabledCheckbox"
                  name={formData.field.name}
                  checked={formData.field.value}
                  onChange={e => {
                    formikProps.setFieldValue(
                      formData.field.name,
                      e.target.checked
                    )
                  }}
                  disabled={!hasDirectoriesAndFederationModifyPermission}
                >
                  <Trans>Enabled</Trans>
                </Checkbox>
              )}
            </Field>
            <StyledPrimaryButton
              data-qa="OneLoginSaveChangesButton"
              type="submit"
              disabled={
                !formikProps.isValid ||
                !formikProps.dirty ||
                !hasDirectoriesAndFederationModifyPermission
              }
            >
              <Trans>Save changes</Trans>
            </StyledPrimaryButton>
          </StyledChildRow>
        </Form>
      )}
    </Formik>
  )
}

const OneLoginGenerateKeyBlock: FunctionComponent<{ onCopy: () => void }> = ({
  onCopy
}) => {
  const keyLength = 33
  const initialGeneratedKey = generateRandomString(keyLength)
  const [generatedKey, setGeneratedKey] = useState(initialGeneratedKey)

  return (
    <>
      <Heading100>
        <Trans>Random company-wide key</Trans>
      </Heading100>
      <BodyRegular>
        <Trans>
          Copy this randomly generated key to OneLogin or generate a new one
          before copying. Once saved in OneLogin, you won{"'"}t need it again.
        </Trans>
        <br />
        <Trans>
          <b>Security note</b>: This locally generated key isn{"'"}t stored by
          LastPass in any way.
        </Trans>
      </BodyRegular>
      <GeneratedKeyContainer>
        <GeneratedKeyTextInputContainer>
          <TextInput
            name="oneLoginGeneratedKey"
            data-qa="OneLoginGeneratedKey"
            value={generatedKey}
            actionOnCopy={onCopy}
            readOnly
            copyToClipboard
            copyIcon={<CopyDuplicateIcon />}
          />
        </GeneratedKeyTextInputContainer>
        <PrimaryButton
          data-qa="OneLoginGenerateKeyButton"
          onClick={() => setGeneratedKey(generateRandomString(keyLength))}
        >
          <Trans>Regenerate key</Trans>
        </PrimaryButton>
      </GeneratedKeyContainer>
    </>
  )
}

export const OneLoginSetupCard: FunctionComponent<{
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}> = ({ automatedVaultReEncryptionPolicy }) => {
  const dispatch = useDispatch()

  const currentAdminLevel: string = useSelector(
    (state: AppState) => state.usersList.currentAdminLevel
  )
  const { openId, adfs }: FederatedLoginState = useSelector(
    (state: AppState) => state.federatedLogin
  )
  const { provider, isEnabled, clientId, openIdUrl } = openId
  const isOneLogin = provider === OpenIdProvider.OneLogin
  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )
  const isOneLoginKCRotationEnabled = useFeatureFlags(
    FeatureFlags.isOneLoginKCRotationEnabled
  )
  const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin

  const handleSubmit = (configuration: OpenIdSetupState) => {
    dispatch(
      federatedLoginActions.saveOpenIdSetup(
        configuration,
        openId,
        configuration.isEnabled &&
          isAutomatedVaultReEncryptionEnabled &&
          !automatedVaultReEncryptionPolicy.isEnabled
      )
    )
  }

  useEffect(() => {
    dispatch(federatedLoginActions.getOpenIdSetup())
  }, [dispatch])

  return (
    <LogoCard
      dataQaName="OneLoginCard"
      logo={<OneLoginIcon />}
      highlighted={provider === OpenIdProvider.OneLogin && isEnabled}
      title={
        <Heading100>
          <Trans>OneLogin</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from OneLogin. They{"'"}ll log in to
          LastPass with their OneLogin credentials instead of their master
          password.
        </Trans>
      }
    >
      <StyledCardContainer>
        <StyledChildRow>
          <BoldWrapper>
            <Trans>Configure OneLogin</Trans>
          </BoldWrapper>
          <StyledExternalLink
            data-qa="OneLoginInstructionsLink"
            href="http://link.lastpass.com/help-federated-login-one-login"
            target="_blank"
          >
            <Trans>Follow these instructions</Trans>
          </StyledExternalLink>
        </StyledChildRow>
        <StyledSubtitle>
          <Trans>
            Complete this form using information from your OneLogin portal.
          </Trans>
        </StyledSubtitle>
        {(!isOneLogin && isEnabled) || adfs.isEnabled ? (
          <div data-qa="IdpAlreadyConfiguredMessageOnOneLogin">
            <OpenIdProviderConfigurationMessage provider="OneLogin" />
          </div>
        ) : (
          <>
            <OneLoginSetupForm
              key={`onelogin ${openIdUrl} - ${clientId} - ${isEnabled}`}
              handleSubmit={handleSubmit}
              authority={openIdUrl}
              clientId={clientId}
              enabled={isEnabled}
              isOneLoginSetupActive={isOneLogin}
            />
            {!isEnabled && (
              <OneLoginGenerateKeyBlock
                onCopy={() => {
                  dispatch(
                    globalActions.setNotification(CopyToClipboardNotification)
                  )
                }}
              />
            )}
          </>
        )}
        {isSuperAdmin && isEnabled && isOneLogin ? (
          isAutomatedVaultReEncryptionEnabled && isOneLoginKCRotationEnabled ? (
            <KcRotationAndVaultReEncryptionBlock
              onClickKcRotation={() => {
                dispatch(
                  federatedLoginActions.startedKeyRotation({
                    provider: OpenIdProvider.OneLogin,
                    isCompanyWideK1: true
                  })
                )
              }}
              onClickReEncryption={() => {
                dispatch(
                  federatedLoginActions.startVaultReEncryption({
                    ...automatedVaultReEncryptionPolicy
                  })
                )
              }}
              providerName={openIdProviderNameMapping[OpenIdProvider.OneLogin]}
              isAutomatedVaultReEncryptionPolicyEnabled={
                automatedVaultReEncryptionPolicy.isEnabled
              }
            />
          ) : isAutomatedVaultReEncryptionEnabled ? (
            <VaultReEncryptionBlock
              onClickReEncryption={() => {
                dispatch(
                  federatedLoginActions.startVaultReEncryption({
                    ...automatedVaultReEncryptionPolicy
                  })
                )
              }}
              isAutomatedVaultReEncryptionPolicyEnabled={
                automatedVaultReEncryptionPolicy.isEnabled
              }
            />
          ) : (
            isOneLoginKCRotationEnabled && (
              <KeyRotationBlock
                onClick={() => {
                  dispatch(
                    federatedLoginActions.startedKeyRotation({
                      provider: OpenIdProvider.Okta,
                      isCompanyWideK1: true
                    })
                  )
                }}
                isCompanyWideK1={true}
              />
            )
          )
        ) : null}
      </StyledCardContainer>
    </LogoCard>
  )
}

import React, { FC, ReactNode, SVGProps } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'

import { Card } from '@lastpass/admin-console-dependencies/ui/home/components/card/Card'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`
const CountContainer = styled.div<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.neutral900};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`
const TitleContainer = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
const DescriptionContainer = styled.div`
  color: ${props => props.theme.colors.neutral700};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
const ActionContainer = styled.div`
  margin-top: 8px;
  height: 40px;
  display: flex;
  align-items: flex-end;
`

const CardIcon: FC<{ icon: FC<SVGProps<SVGSVGElement>> }> = ({ icon }) => {
  const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `
  const StyledIcon = styled(icon)`
    width: 56px;
    height: 56px;
  `
  return (
    <IconContainer data-qa="Icon">
      <StyledIcon />
    </IconContainer>
  )
}

export interface ActionCardProps {
  icon: FC<SVGProps<SVGSVGElement>>
  title: MessageDescriptor
  count: number
  countColor?: string
  description: ReactNode
  action: ReactNode
  dataQa?: string
}

export const ActionCard: FC<ActionCardProps> = ({
  icon,
  count,
  countColor,
  title,
  description,
  action,
  dataQa
}) => {
  const { _ } = useLingui()

  return (
    <Card>
      <Container data-qa={dataQa}>
        <Details>
          <CardIcon icon={icon} />
          <CountContainer
            data-qa={dataQa && dataQa + 'Count'}
            color={countColor}
          >
            {count}
          </CountContainer>
          <TitleContainer data-qa="Title">{_(title)}</TitleContainer>
          <DescriptionContainer>{description}</DescriptionContainer>
        </Details>
        <ActionContainer data-qa={dataQa && dataQa + 'Action'}>
          {action}
        </ActionContainer>
      </Container>
    </Card>
  )
}

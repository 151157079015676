import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { OpenIdSetupState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

import { vaultReEncryptionOfferOnSave } from '../vault-re-encryption/vault-re-encryption-offer-on-save'

export const getAdfsIsEnabled = (state: AppState) =>
  state.federatedLogin.adfs.isEnabled

export const getIsPingFederate = (state: AppState) =>
  state.federatedLogin.adfs.isPingFederate

function getNotificationMessage(
  provider: OpenIdProvider,
  success: boolean
): MessageDescriptor {
  switch (provider) {
    case OpenIdProvider.AzureAD:
      return success
        ? msg`Azure AD setup successful`
        : msg`Azure AD setup failed`
    case OpenIdProvider.Okta:
      return success ? msg`Okta setup successful` : msg`Okta setup failed`
    case OpenIdProvider.PingOne:
      return success ? msg`PingOne setup successful` : msg`PingOne setup failed`
    case OpenIdProvider.OneLogin:
      return success
        ? msg`OneLogin setup successful`
        : msg`OneLogin setup failed`
    case OpenIdProvider.Google:
      return success
        ? msg`Google Workspace setup successful`
        : msg`Google Workspace setup failed`
    default:
      return success ? msg`OpenID setup successful` : msg`OpenID setup failed`
  }
}

function getErrorMessage(provider: OpenIdProvider, reason: string) {
  if (
    reason === "These settings can't be changed while you have federated users."
  ) {
    return msg`These settings can't be changed while you have federated users.`
  } else if (
    reason ===
    'You must enable the policy "Permit super admins to reset master passwords" before enabling federated login.'
  ) {
    return msg`You must enable the policy "Permit super admins to reset master passwords" before enabling federated login.`
  } else if (
    reason === 'Settings are incorrect, please provide valid values.'
  ) {
    return msg`Settings are incorrect, please provide valid values.`
  }

  return getNotificationMessage(provider, false)
}

export function createSaveOpenIdSetup(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveOpenIdSetup(
    action: ReturnType<typeof federatedLoginActions.saveOpenIdSetup>
  ) {
    const isAdfsEnabled: boolean = yield select(getAdfsIsEnabled)
    const openIdSetup: OpenIdSetupState = action.payload.openIdSetup
    const isAutomatedVaultReEncryptionOfferRequired: boolean =
      action.payload.isAutomatedVaultReEncryptionOfferRequired
    if (isAdfsEnabled && openIdSetup.isEnabled) {
      const isPingFederate: boolean = yield select(getIsPingFederate)

      const warningMessage = isPingFederate
        ? msg`Federated login with PingFederate has already been enabled. Please disable it to proceed.`
        : msg`Federated login with ADFS has already been enabled. Please disable it to proceed.`

      yield put(
        globalActions.setNotification({
          message: warningMessage,
          type: NotificationType.warning,
          autoDismiss: false
        })
      )
      return
    }

    try {
      yield put(federatedLoginActions.setLoading(true))
      try {
        const openIdSetupResult: UACServices.OpenIdSetupSaveAPI.Responses = yield call(
          enterpriseOptionServices.openIdSetupSave,
          openIdSetup
        )
        if (openIdSetupResult.type === UACServices.OpenIdSetupSaveAPI.SUCCESS) {
          yield put(federatedLoginActions.getOpenIdSetup())
          yield put(
            globalActions.setNotification({
              message: getNotificationMessage(
                action.payload.openIdSetup.provider,
                true
              ),
              type: NotificationType.success,
              autoDismiss: true
            })
          )
          if (isAutomatedVaultReEncryptionOfferRequired) {
            yield take(GlobalActionTypes.SET_IS_LOADING)
            yield call(vaultReEncryptionOfferOnSave)
          }
        } else {
          yield put(
            globalActions.setNotification({
              message: getErrorMessage(
                action.payload.openIdSetup.provider,
                openIdSetupResult.type
              ),
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(federatedLoginActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as SearchIconSVG } from '@lastpass/assets/svg/admin-console/search.svg'

import { PlatformFreeTimeOut } from '@lastpass/admin-console-dependencies/types/creation'

import { InputProps, TextInput } from './TextInput'

export interface SearchInputProps extends InputProps {
  preventDebounce?: boolean
  flexGrow?: boolean
}

const debounceInMilliseconds = 500

const StyledTextInput = styled(TextInput)<{ flexGrow?: boolean }>`
  width: ${props => (props.flexGrow ? '100%' : '320px')};
  flex-basis: 100%;
  flex-grow: 1;
  margin-right: 24px;
  margin-bottom: 10px;
  @media (max-width: 1280px) {
    width: 240px;
  }
`

export const SearchInput: FunctionComponent<SearchInputProps> = ({
  placeholder,
  preventDebounce,
  ...props
}) => {
  const [timer, setTimer] = useState<PlatformFreeTimeOut>()
  const eventRef = useRef<ChangeEvent<HTMLInputElement>>()

  return (
    <StyledTextInput
      {...props}
      flexGrow={props.flexGrow || false}
      placeholder={placeholder}
      icon={SearchIconSVG}
      type="search"
      onChange={event => {
        if (!preventDebounce) {
          event.persist()
          clearTimeout(timer as PlatformFreeTimeOut)
          eventRef.current = event

          const timeOut = setTimeout(() => {
            return (
              props.onChange &&
              eventRef.current &&
              props.onChange(eventRef.current)
            )
          }, debounceInMilliseconds)
          setTimer(timeOut)
        } else {
          props.onChange && props.onChange(event)
        }
      }}
    />
  )
}

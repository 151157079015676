import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { SecurityReportEvent } from '@lastpass/admin-console-dependencies/types/security-report'

export enum SecurityReportActionTypes {
  REPORT_GET_SECURITY_REPORT = '[reports] security-report-get',
  REPORT_SET_SECURITY_REPORT = '[reports] security-report-set',
  REPORT_SET_SECURITY_REPORT_LAST_UPDATED = '[reports] security-report-set-last-updated',
  REPORT_SET_SECURITY_REPORT_LOADING = '[reports] security-report-loading-set',
  REPORT_TRACK_SECURITY_REPORTS_OVERVIEW_VIEWED = '[reports] security-reports-overview-viewed',
  REPORT_SET_SECURITY_REPORT_IS_UPDATE_REQUESTED = '[reports] security-reports-set-is-update-requested'
}

export const securityReportActions = {
  getSecurityReportEvents: (featureFlags: {
    isWeakSecurityScoreEnabled: boolean
    isDWMSecurityReportEnabled: boolean
    isUrlDecryptionEnabled: boolean
    isSharedFolderUrlDecryptionEnabled: boolean
  }) =>
    createAction(SecurityReportActionTypes.REPORT_GET_SECURITY_REPORT, {
      featureFlags
    }),
  setSecurityReportEvents: (events: SecurityReportEvent[]) =>
    createAction(SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT, {
      events: events
    }),
  setSecurityReportLastUpdated: (lastUpdated: string) =>
    createAction(
      SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT_LAST_UPDATED,
      { lastUpdated }
    ),
  setSecurityReportLoading: (isLoading: boolean) =>
    createAction(SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT_LOADING, {
      isLoading
    }),
  trackSecurityReportsOverviewViewed: () =>
    track(
      createAction(
        SecurityReportActionTypes.REPORT_TRACK_SECURITY_REPORTS_OVERVIEW_VIEWED
      ),
      {
        segment: uacEvent('Security Reports Overview Viewed')
      }
    ),
  setIsUpdateRequested: (isUpdateRequested: boolean) =>
    createAction(
      SecurityReportActionTypes.REPORT_SET_SECURITY_REPORT_IS_UPDATE_REQUESTED,
      {
        isUpdateRequested
      }
    )
}

export type SecurityReportActions = ActionsUnion<typeof securityReportActions>

import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  SaveTeamsPolicySwitchOnData,
  TeamsPolicy,
  TeamsPolicyMap,
  TeamsPolicyModelData
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPolicyInfo } from './get-general-policy'

export const getCurrentUrlFilters = state => state.router

export const preparePolicyData = (
  policy: TeamsPolicy,
  policyValue: string
): TeamsPolicyModelData[] => [
  {
    appliesTo: AppliesToOptions.all,
    policyValue: policyValue,
    policyNotes: '',
    cpid:
      policy.policyData && policy.policyData[0]
        ? policy.policyData[0].cpid
        : null,
    exclusiveList: [],
    inclusiveList: [],
    inclusiveGroupList: [],
    exclusiveGroupList: []
  }
]

export function createEnableAdminPolicySaga(
  policyService: UACServices.Services
) {
  return function* enableAdminPolicySaga(
    action: ReturnType<typeof generalPoliciesDrawerActions.enableAdminPolicy>
  ) {
    const policyKey = action.payload.policyKey
    const admins = action.payload.admins
    const adminEmails: string[] = []

    if (policyKey) {
      try {
        admins.forEach(admin => adminEmails.push(admin.email))
        const policyValue = adminEmails.join(',')

        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
            true,
            policyKey
          )
        )
        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy = policyMap[policyKey]

        const policyData = preparePolicyData(policy, policyValue)
        const policySaveData: SaveTeamsPolicySwitchOnData = {
          policyKey,
          policyData
        }
        const result: UACServices.GeneralPolicySwitchONSaveAPI.Responses = yield call(
          policyService.generalPolicySwitchOnSave,
          policySaveData
        )
        if (result.type === UACServices.GeneralPolicySwitchONSaveAPI.SUCCESS) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          const currentUrlFilters = yield select(getCurrentUrlFilters)
          yield put(
            generalPoliciesListActions.getGeneralPoliciesList({
              query: getQueryParams(
                currentUrlFilters.location,
                Namespace.policies
              ),
              path: {}
            })
          )
        } else if (
          result.type === UACServices.GeneralPolicySwitchONSaveAPI.FAIL
        ) {
          yield put(
            globalActions.setNotificationAlert(
              result.body.error.replace('&#039;', "'")
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      }
    }
  }
}

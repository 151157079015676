import React, { FC } from 'react'

import styled from '@emotion/styled'

import { LearnMoreLink } from '@lastpass/admin-console-dependencies/ui/home/components/action-link/LearnMoreLink'
import { ShowHistoryLink } from '@lastpass/admin-console-dependencies/ui/home/components/action-link/ShowHistoryLink'
import { Card } from '@lastpass/admin-console-dependencies/ui/home/components/card/Card'

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const ActionLinkContainer = styled.div`
  display: flex;
  gap: 32px;
`

export interface ActionLinkCardProps {
  dataQa?: string
  showHistoryAction?: (event: React.MouseEvent<HTMLDivElement>) => void
  learnMoreAction?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const ActionLinkCard: FC<ActionLinkCardProps> = ({
  children,
  dataQa,
  showHistoryAction,
  learnMoreAction
}) => (
  <Card>
    <CardContent data-qa={dataQa}>
      {children}
      <ActionLinkContainer>
        {showHistoryAction && (
          <ShowHistoryLink onClick={showHistoryAction} dataQa={dataQa} />
        )}
        {learnMoreAction && (
          <LearnMoreLink onClick={learnMoreAction} dataQa={dataQa} />
        )}
      </ActionLinkContainer>
    </CardContent>
  </Card>
)

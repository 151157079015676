import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import {
  MultifactorOptionType,
  MultifactorPolicyKeys
} from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { MultifactorPageState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/state'
import {
  MultifactorLogoMapping,
  MultifactorMethodNameMapping,
  MultifactorTypeAuthMethodMapping
} from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

export interface SelectMfaMethodProps {
  closeLink: string
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
`

const CardContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  border: 1px solid #b9c0c9;
  box-shadow: 0 1px 2px rgb(29 48 73 / 4%), 0 2px 2px rgb(29 48 73 / 4%),
    0 0 2px rgb(29 48 73 / 16%);
  border-radius: 4px;
  min-width: 370px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
  cursor: pointer;
`

const TotpCardContent = styled.div`
  margin-left: 40px;
  display: flex;
`

const Bold = styled.div`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const Link = styled.a`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const RadioTitleContainer = styled.div`
  display: flex;
`

const StyledRadioInput = styled.input`
  width: 16px;
  height: 16px;
  margin-top: 6px;
  margin-right: 16px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 16px;
`

const StyledHr = styled.hr`
  border-top: none;
  border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
  margin: 16px 16px 16px 40px;
`

const LogoContainer = styled.div`
  margin-left: auto;
  margin-right: 16px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 50%;
`

const RadioAndLabelContainer = styled.div`
  display: flex;
  margin-top: 6px;
`

const convertArrayToObject = (array, key) => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, initialValue)
}

export const SelectMfaMethod: React.FunctionComponent<SelectMfaMethodProps> = ({
  closeLink
}) => {
  const history = useHistory()

  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )
  const multifactorPageState: MultifactorPageState = useSelector(
    (state: AppState) => state.multifactorPage
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(multifactorDrawerActions.setSelectedMfaMethodDefaultValue())
  }, [dispatch])

  useEffect(() => {
    dispatch(multifactorDrawerActions.multifactorSelectMfaMethodViewed())
  }, [dispatch])

  const totpAppMethod = MultifactorOptionType.TotpApp
  let nextStep = MultifactorDrawerSteps.configureMfa

  const requireOfPoliciesMap = convertArrayToObject(
    multifactorPageState.RequireOfPolicies,
    'policyKey'
  )

  const isTotpAdded =
    requireOfPoliciesMap[MultifactorPolicyKeys.Totp] &&
    requireOfPoliciesMap[MultifactorPolicyKeys.Totp].checked

  let totpSelectedStyle
  if (
    multifactorDrawerState.selectedMultifactorOption === totpAppMethod &&
    !isTotpAdded
  ) {
    totpSelectedStyle = { border: '1px solid #3b70d4' }
  }

  const cards = Object.keys(MultifactorOptionType)
    .filter(key => MultifactorOptionType[key] !== totpAppMethod)
    .map(key => {
      const mfaMethod = MultifactorOptionType[key]
      const disabled =
        requireOfPoliciesMap[MultifactorPolicyKeys[mfaMethod]] &&
        requireOfPoliciesMap[MultifactorPolicyKeys[mfaMethod]].checked

      const selected =
        multifactorDrawerState.selectedMultifactorOption === mfaMethod &&
        !disabled
      let selectedStyle, selectedLogoStyle
      if (selected && !disabled) {
        selectedStyle = {
          border: '1px solid #3b70d4'
        }
        selectedLogoStyle = {
          filter: 'grayscale(0%)',
          opacity: '100%',
          WebkitFilter: 'grayscale(0%)'
        }
      }

      return (
        <CardContainer
          key={key}
          onClick={() =>
            !disabled
              ? dispatch(
                  multifactorDrawerActions.setSelectedMfaMethod(mfaMethod)
                )
              : {}
          }
          style={selectedStyle}
          data-qa={`MfaMethodCard-${key}`}
        >
          <RadioTitleContainer data-qa={MultifactorOptionType[mfaMethod]}>
            <RadioAndLabelContainer>
              <StyledRadioInput
                type={'radio'}
                checked={selected}
                disabled={disabled}
                onChange={() => {}}
              />
              <Heading100>{MultifactorMethodNameMapping[mfaMethod]}</Heading100>
            </RadioAndLabelContainer>
            <LogoContainer style={selectedLogoStyle}>
              {MultifactorLogoMapping[mfaMethod]}
            </LogoContainer>
          </RadioTitleContainer>
        </CardContainer>
      )
    })

  if (
    multifactorDrawerState.selectedMultifactorOption === totpAppMethod ||
    multifactorDrawerState.selectedMultifactorOption ===
      MultifactorOptionType.Salesforce
  ) {
    nextStep = MultifactorDrawerSteps.assign
  }

  return (
    <MainContainer>
      <div>
        <Trans>
          Users will be prompted for the selected method upon next login to a
          protected service.
        </Trans>
      </div>
      <CardContainer
        data-qa="TotpApp"
        key={MultifactorOptionType.TotpApp}
        onClick={() => {
          if (!isTotpAdded) {
            dispatch(
              multifactorDrawerActions.setSelectedMfaMethod(totpAppMethod)
            )
          }
        }}
        style={totpSelectedStyle}
      >
        <RadioTitleContainer>
          <StyledRadioInput
            type="radio"
            checked={
              multifactorDrawerState.selectedMultifactorOption ===
                totpAppMethod && !isTotpAdded
            }
            onChange={() => {}}
            disabled={
              requireOfPoliciesMap[MultifactorPolicyKeys.Totp] &&
              requireOfPoliciesMap[MultifactorPolicyKeys.Totp].checked
            }
          />
          <StyledHeading100>
            {MultifactorMethodNameMapping[totpAppMethod]}
          </StyledHeading100>
        </RadioTitleContainer>
        <TotpCardContent>
          <Trans>
            Users can choose SMS, phone call, or YubiKey as a backup method.
          </Trans>
        </TotpCardContent>
        <StyledHr />
        <TotpCardContent>
          {MultifactorLogoMapping[totpAppMethod]}
          <div>
            <Trans>
              <Bold>Recommended:</Bold> LastPass Authenticator with one-tap
              verification.
            </Trans>{' '}
            <Link
              data-qa="LastPassAuthenticatorLearnMoreLink"
              href="https://support.logmeininc.com/lastpass/help/lastpass-authenticator-lp030014"
              target="_blank"
            >
              <Trans>Learn more</Trans>
            </Link>
          </div>
        </TotpCardContent>
      </CardContainer>
      {cards}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => {
              const option =
                multifactorDrawerState.selectedMultifactorOption == null
                  ? MultifactorOptionType.TotpApp
                  : multifactorDrawerState.selectedMultifactorOption

              dispatch(
                multifactorDrawerActions.multifactorSelectMfaMethodClicked(
                  MultifactorTypeAuthMethodMapping[option],
                  'Discard'
                )
              )
              history.push(closeLink)
            }}
            data-qa="CancelButton"
          >
            <Trans>Cancel</Trans>
          </TextButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              const option =
                multifactorDrawerState.selectedMultifactorOption == null
                  ? MultifactorOptionType.TotpApp
                  : multifactorDrawerState.selectedMultifactorOption

              dispatch(
                multifactorDrawerActions.multifactorSelectMfaMethodClicked(
                  MultifactorTypeAuthMethodMapping[option],
                  'Continue'
                )
              )
              dispatch(multifactorDrawerActions.nextStep(nextStep))
            }}
            disabled={multifactorDrawerState.selectedMultifactorOption === null}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </MainContainer>
  )
}

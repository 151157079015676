import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import {
  EmptyPageAfterSystemUpgrade,
  EmptyPageTextBlock
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/EmptyPageAfterSystemUpgrade'

import { createPasswordlessDrawer } from './passwordless/drawer/PasswordlessDrawer'
import { createPasswordlessContainerComponent } from './passwordless/SsoAppsContainer'

const BoldText = styled.label`
  font-weight: 600;
`
const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
`

const PasswordlessContainer = createPasswordlessContainerComponent<AppState>()
const PasswordlessDrawer = createPasswordlessDrawer<AppState>()

export const SsoAppsPageContainer = () => {
  const match = useRouteMatch()

  const dispatch = useDispatch()

  const systemUpgradeState = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  useEffect(() => {
    dispatch(systemUpgradeActions.fetchIsMigrated())
  }, [dispatch])

  useEffect(() => {
    dispatch(passwordlessActions.ssoAppsOverviewViewed())
  }, [dispatch])

  if (!match) {
    return null
  }

  const { path } = match
  return systemUpgradeState.isMigrated ? (
    <EmptyPageAfterSystemUpgrade
      title={<Trans>What happened to Passwordless apps?</Trans>}
    >
      <EmptyPageTextBlock>
        <Trans>
          With our recent system upgrade, this feature went through some
          changes.
        </Trans>
      </EmptyPageTextBlock>
      <EmptyPageTextBlock>
        <Trans>
          This feature forced users to verify their identity with LastPass
          Authenticator instead of their master password when logging in to SSO
          apps, also known as Cloud Apps.
        </Trans>
      </EmptyPageTextBlock>
      <EmptyPageTextBlock>
        <Trans>
          To achieve similar goals after upgrade, enable the{' '}
          <BoldText>Allow passwordless login</BoldText> policy, as described{' '}
          <StyledLink
            href="https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass/policy_passwordless_login_for_vault.html"
            target="_blank"
          >
            in this article
          </StyledLink>
          .
        </Trans>
      </EmptyPageTextBlock>
    </EmptyPageAfterSystemUpgrade>
  ) : (
    <>
      <PasswordlessContainer />
      <AnimatedSwitch timeout={200}>
        <Route path={`${path}/policy`}>
          <PasswordlessDrawer closeLink={`${path}`} />
        </Route>
      </AnimatedSwitch>
    </>
  )
}

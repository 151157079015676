import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'

export function createShowUpsellSaga() {
  return function* showUpsellSaga(
    action: ReturnType<typeof globalActions.showMfaUpsellDialog>
  ) {
    try {
      const updateHistory = action.payload.updateHistory
      const newRoute = action.payload.newRoute

      yield put(
        globalActions.setDialog({
          type: 'upselldialog',
          mainText: msg`Your current plan doesn’t include the Advanced MFA add-on.`,
          secondaryText: msg`Try Advanced MFA for free. See how it helps you protect your business with adaptive multi-factor authentication.`,
          discardText: msg`Not now`,
          confirmText: msg`Start free trial`,
          mfaDialogContext: action.payload.context
        })
      )
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield call(
          window.open,
          'https://lastpass.com/enterprise_trial.php?createacct=1&offering=1',
          '_blank'
        )
        updateHistory && newRoute && updateHistory(newRoute)
      } else if (confirmClick.type === GlobalActionTypes.DISCARD_DIALOG) {
        updateHistory && newRoute && updateHistory(newRoute)
      }
      yield put(globalActions.emptyDialog())
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

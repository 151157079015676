import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { CaptionRegularStyle } from '@lastpass/lastkit'

const StyledError = styled.span`
  ${CaptionRegularStyle};
  color: ${props => props.theme.colors.red700};
  line-height: 15px;
`

export const TimeRangeValidationError = () => {
  return (
    <StyledError data-qa="TimeRangeValidationError">
      <Trans>Enter a valid time range</Trans>
    </StyledError>
  )
}

import { call, put, take } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'

export function createShowUpsellContactSupportSaga() {
  return function* showUpsellContactSupportSaga() {
    try {
      yield put(
        globalActions.setDialog({
          type: 'ssoupsellcontactsupportdialog'
        })
      )
      const confirmClick = yield take([
        GlobalActionTypes.DISCARD_DIALOG,
        GlobalActionTypes.MORE_INFO_CLICKED_ON_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.MORE_INFO_CLICKED_ON_DIALOG) {
        yield call(
          window.open,
          'http://www.lastpass.com/products/sso',
          '_blank'
        )
      }

      yield put(globalActions.emptyDialog())
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  AccessPolicyMap,
  GeofencePolicyMap,
  IpPolicyMap
} from '@lastpass/admin-console-dependencies/state/policies/passwordless/passwordless'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'

export enum PasswordlessActionTypes {
  FETCH_INITIAL_DATA = '[passwordless] fetch-initial-data',
  FETCH_PASSWORDLESS_STATUS = '[passwordless] fetch-passwordless-status',
  SET_LOADING = '[passwordless] set-loading',
  SET_ACCESS_POLICIES = '[passwordless] set-access-policies',
  SET_IP_POLICIES = '[passwordless] set-ip-policies',
  SET_GEOFENCE_POLICIES = '[passwordless] set-geofence-policies',
  SET_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY = '[passwordless] set-require-passwordless-via-lastpass-policy',
  ENABLE_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY = '[passwordless] enable-require-passwordless-via-lastpass-policy',
  SET_EMPTYSTATE = '[passwordless] set-empty-state',
  DISABLE_POLICY = '[passwordless] disable-policy',
  REMOVE_POLICY = '[passwordless] remove-policy',
  PASSWORDLESS_POLICIES_OVERVIEW_VIEWED = '[passwordless] passwordless-policies-overview-viewed',
  SSO_APPS_OVERVIEW_VIEWED = '[passwordless] sso-apps-overview-viewed'
}

export const passwordlessActions = {
  fetchInitialData: () =>
    createAction(PasswordlessActionTypes.FETCH_INITIAL_DATA),
  fetchPasswordlessStatus: () =>
    createAction(PasswordlessActionTypes.FETCH_PASSWORDLESS_STATUS),
  setLoading: (loading: boolean) =>
    createAction(PasswordlessActionTypes.SET_LOADING, { loading }),
  setAccessPolicies: (accessPolicies: AccessPolicyMap) =>
    createAction(PasswordlessActionTypes.SET_ACCESS_POLICIES, {
      accessPolicies
    }),
  setIpPolicies: (ipPolicies: IpPolicyMap) =>
    createAction(PasswordlessActionTypes.SET_IP_POLICIES, {
      ipPolicies
    }),
  setGeofencePolicies: (geofencePolicies: GeofencePolicyMap) =>
    createAction(PasswordlessActionTypes.SET_GEOFENCE_POLICIES, {
      geofencePolicies
    }),
  setRequirePasswordlessViaLastPassPolicy: (
    requirePasswordlessViaLastPassPolicy: TeamsPolicy
  ) =>
    createAction(
      PasswordlessActionTypes.SET_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY,
      {
        requirePasswordlessViaLastPassPolicy
      }
    ),
  enableRequirePasswordlessViaLastPassPolicy: () =>
    createAction(
      PasswordlessActionTypes.ENABLE_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY
    ),
  setEmptyState: (isEmpty: boolean) =>
    track(
      createAction(PasswordlessActionTypes.SET_EMPTYSTATE, {
        isEmpty
      }),
      {
        segment: uacEvent('Passwordless Policies Overview Viewed', {
          isEmpty: isEmpty,
          Source: 'Passwordless Policies'
        })
      }
    ),
  disablePolicy: () => createAction(PasswordlessActionTypes.DISABLE_POLICY),
  removePolicy: (policyKey: string) =>
    createAction(PasswordlessActionTypes.REMOVE_POLICY, {
      policyKey
    }),
  passwordlessPoliciesOverviewViewed: () =>
    track(
      createAction(
        PasswordlessActionTypes.PASSWORDLESS_POLICIES_OVERVIEW_VIEWED
      ),
      {
        segment: uacEvent('Passwordless Policies Overview Viewed')
      }
    ),
  ssoAppsOverviewViewed: () =>
    track(createAction(PasswordlessActionTypes.SSO_APPS_OVERVIEW_VIEWED), {
      segment: uacEvent('Passwordless SSO Apps Overview Viewed')
    })
}

export type PasswordlessActions = ActionsUnion<typeof passwordlessActions>

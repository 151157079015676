import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  BodyBold,
  BodyRegular,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ForceVaultReEncrytionConfirmationDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface ForceVaultReEncryptionConfirmationDialogComponentProps {
  state: ForceVaultReEncrytionConfirmationDialog
}

export const StyledVaultReEncryptionContent = styled.div`
  margin-bottom: 24px;
`

export const ForceVaultReEncryptionConfirmationDialogComponent: FC<ForceVaultReEncryptionConfirmationDialogComponentProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  const extensionVersion = 4.125
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={msg`Force vault re-encryption`}
      content={
        <>
          {state.isGroup ? (
            <BodyRegular>
              <Trans>
                All federated users in the group will have their active sessions
                destroyed and their vaults will be re-encrypted next time they
                log in to LastPass.
              </Trans>
            </BodyRegular>
          ) : state.email ? (
            <BodyRegular>
              <StyledVaultReEncryptionContent>
                <Trans>Force vault re-encryption for this user?</Trans>
                <br />
                {state.email}
              </StyledVaultReEncryptionContent>
              <Trans>
                Their active sessions will be destroyed and their vault will be
                re-encrypted next time they log in to LastPass.
              </Trans>
            </BodyRegular>
          ) : (
            <BodyRegular>
              <StyledVaultReEncryptionContent>
                <Trans>Force vault re-encryption for the selected users?</Trans>
              </StyledVaultReEncryptionContent>
              <Trans>
                Their active sessions will be destroyed and their vaults will be
                re-encrypted next time they log in to LastPass.
              </Trans>
            </BodyRegular>
          )}
        </>
      }
      footer={
        <BodyRegular>
          <Trans>
            <BodyBold>Requirement:</BodyBold> Users must have LastPass browser
            extension <BodyBold>version {extensionVersion} or higher.</BodyBold>
          </Trans>
        </BodyRegular>
      }
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(globalActions.discardDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="PopupSubmit"
          red
          onClick={() => {
            dispatch(globalActions.confirmDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
        >
          <Trans>Force vault re-encryption</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ActionWithPayload } from '@lastpass/admin-console-dependencies/state/action'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSavePsaCredentialsSaga(services: UACServices.Services) {
  return function* savePsaCredentialsSaga(
    action: ReturnType<typeof psaIntegrationsActions.savePsaCredentials>
  ) {
    try {
      yield put(psaIntegrationsActions.setDrawerInProgress(true))

      const response: UACServices.SavePsaCredentialsAPI.Responses = yield call(
        services.savePsaCredentials,
        action.payload.data
      )

      if (
        response &&
        response.type === UACServices.SavePsaCredentialsAPI.SUCCESS
      ) {
        yield put(psaIntegrationsActions.getPsaProviderDetails())
        yield put(push('/advanced/psaIntegrations'))
        yield put(setSuccessBanner())
      } else {
        yield put(setErrorBanner())
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(psaIntegrationsActions.setDrawerInProgress(false))
    }
  }

  function setErrorBanner(): ActionWithPayload<
    GlobalActionTypes.SET_NOTIFICATION,
    {
      notification: NotificationProps
    }
  > {
    return globalActions.setNotification({
      message: msg`Connection test failed. Check the credentials and try again.`,
      type: NotificationType.alert,
      autoDismiss: true
    })
  }

  function setSuccessBanner(): ActionWithPayload<
    GlobalActionTypes.SET_NOTIFICATION,
    {
      notification: NotificationProps
    }
  > {
    return globalActions.setNotification({
      message: msg`Connection test successful. Settings saved.`,
      type: NotificationType.success,
      autoDismiss: true
    })
  }
}

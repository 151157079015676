import {
  Action,
  ActionsUnion,
  ActionWithPayload,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import {
  FormValues,
  PsaCompanyContractAssignment,
  PsaCompanyContractAssignmentOption,
  PsaCredentialsState,
  PsaProduct,
  PsaProviderDetails
} from './state'

export enum PsaIntegrationsActionTypes {
  NAVIGATE_PSA_DRAWER = '[psa-integrations] navigate drawer',
  SET_DRAWER_CONTAINS_UNSAVED_CHANGES = '[psa-integrations] set drawer contains unsaved changes',
  SET_DRAWER_IN_PROGRESS = '[psa-integrations] set drawer in progress',
  SET_PSA_IN_PROGRESS = '[psa-integrations] set in progress',

  // PSA provider
  GET_PSA_PROVIDER_DETAILS = '[psa-integrations] get provider details',
  SET_PSA_PROVIDER_DETAILS = '[psa-integrations] set provider details',
  SWITCH_PSA_PROVIDER = '[psa-integrations] switch provider',

  // PSA credential
  DELETE_PSA_CREDENTIALS = '[psa-integrations] delete credentials',
  GET_PSA_CREDENTIALS = '[psa-integrations] get credentials',
  SAVE_PSA_CREDENTIAL = '[psa-integrations] save credentials',
  SET_PSA_CREDENTIALS = '[psa-integrations] set credentials',
  SET_PSA_EDIT_CREDENTIALS_FORM_VALUES = '[psa-integrations] set psa edit credentials form values',

  // PSA product
  GET_PSA_PRODUCTS = '[psa-integrations] get products',
  SAVE_PSA_PRODUCTS = '[psa-integrations] save products',
  SET_PSA_PRODUCTS = '[psa-integrations] set products',
  SET_PSA_PRODUCTS_TABLE_IN_PROGRESS = '[psa-integrations] set products table in progress',

  // PSA agreements
  GET_PSA_AGREEMENTS = '[psa-integrations] get agreements',
  SAVE_PSA_AGREEMENTS = '[psa-integrations] save agreements',
  SET_PSA_AGREEMENTS_ASSIGNMENT_OPTIONS = '[psa-integrations] set agreements assignment options',
  SET_PSA_AGREEMENTS_TABLE_IN_PROGRESS = '[psa-integrations] set psa agreements in progress'
}

export const psaIntegrationsActions = {
  navigatePsaDrawer: (url: string, containsUnsavedChanges: boolean) =>
    createAction(PsaIntegrationsActionTypes.NAVIGATE_PSA_DRAWER, {
      url,
      containsUnsavedChanges
    }),
  setDrawerInProgress: (isLoading: boolean) =>
    createAction(PsaIntegrationsActionTypes.SET_DRAWER_IN_PROGRESS, isLoading),
  setDrawerContainsUnsavedChanges: (containsUnsavedChanges: boolean) =>
    createAction(
      PsaIntegrationsActionTypes.SET_DRAWER_CONTAINS_UNSAVED_CHANGES,
      containsUnsavedChanges
    ),
  setPsaInProgress: (isLoading: boolean) =>
    createAction(PsaIntegrationsActionTypes.SET_PSA_IN_PROGRESS, isLoading),

  // PSA provider
  getPsaProviderDetails: () =>
    createAction(PsaIntegrationsActionTypes.GET_PSA_PROVIDER_DETAILS),
  setPsaProviderDetails: (details: PsaProviderDetails | null) =>
    createAction(PsaIntegrationsActionTypes.SET_PSA_PROVIDER_DETAILS, details),
  switchPsaProvider: (provider: string) =>
    createAction(PsaIntegrationsActionTypes.SWITCH_PSA_PROVIDER, provider),

  // PSA credential
  deletePsaCredentials: (provider: string) =>
    createAction(PsaIntegrationsActionTypes.DELETE_PSA_CREDENTIALS, provider),
  getPsaCredentials: () =>
    createAction(PsaIntegrationsActionTypes.GET_PSA_CREDENTIALS),
  savePsaCredentials: (data: PsaProviderDetails) =>
    createAction(PsaIntegrationsActionTypes.SAVE_PSA_CREDENTIAL, {
      data
    }),
  setPsaCredentials: (credentials: PsaCredentialsState[]) =>
    createAction(PsaIntegrationsActionTypes.SET_PSA_CREDENTIALS, credentials),

  setPsaEditCredentialsFormValues: (
    formValues: FormValues | null,
    provider: string
  ) =>
    createAction(
      PsaIntegrationsActionTypes.SET_PSA_EDIT_CREDENTIALS_FORM_VALUES,
      { formValues, provider }
    ),

  // PSA product
  getPsaProducts: () =>
    createAction(PsaIntegrationsActionTypes.GET_PSA_PRODUCTS),
  setPsaProducts: (
    products: PsaProduct[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(PsaIntegrationsActionTypes.SET_PSA_PRODUCTS, {
      products,
      totalResults,
      resultsPerPage
    }),
  savePsaProducts: (products: string[]) =>
    createAction(PsaIntegrationsActionTypes.SAVE_PSA_PRODUCTS, products),
  setPsaProductsTableInProgress: (isLoading: boolean) =>
    createAction(
      PsaIntegrationsActionTypes.SET_PSA_PRODUCTS_TABLE_IN_PROGRESS,
      isLoading
    ),

  // PSA agreements
  getPsaAgreements: (companyIds: number[] | null) =>
    createAction(PsaIntegrationsActionTypes.GET_PSA_AGREEMENTS, companyIds),
  savePsaAgreements: (
    assignments: PsaCompanyContractAssignment[],
    isConfirmRequired: boolean,
    successUrl?: string,
    successAction?: Action<string> | ActionWithPayload<string, unknown>
  ) =>
    createAction(PsaIntegrationsActionTypes.SAVE_PSA_AGREEMENTS, {
      assignments,
      isConfirmRequired,
      successUrl,
      successAction
    }),
  setPsaAgreementsAssignmentOptions: (
    assignmentOptions: PsaCompanyContractAssignmentOption[]
  ) =>
    createAction(
      PsaIntegrationsActionTypes.SET_PSA_AGREEMENTS_ASSIGNMENT_OPTIONS,
      assignmentOptions
    ),
  setPsaAgreementsTableInProgress: (loading: boolean) =>
    createAction(
      PsaIntegrationsActionTypes.SET_PSA_AGREEMENTS_TABLE_IN_PROGRESS,
      loading
    )
}

export type PsaIntegrationsActions = ActionsUnion<typeof psaIntegrationsActions>

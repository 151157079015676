import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import { takeDialogInteractions } from '@lastpass/admin-console-dependencies/sagas/utils/take-dialog-interactions'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ActionWithPayload } from '@lastpass/admin-console-dependencies/state/action'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export function createDeletePsaCredentialsSaga(services: UACServices.Services) {
  return function* deletePsaCredentialsSaga(
    action: ReturnType<typeof psaIntegrationsActions.deletePsaCredentials>
  ) {
    try {
      const dialog = getDeleteProviderConfirmDialog()
      yield put(globalActions.setDialog(dialog))
      const { isConfirmed, isDiscarded } = yield call(takeDialogInteractions)
      if (isDiscarded) return
      if (isConfirmed) {
        yield put(psaIntegrationsActions.setPsaInProgress(true))
        const response: UACServices.DeletePsaCredentialsAPI.Responses = yield call(
          services.deletePsaCredentials,
          action.payload
        )

        if (
          response &&
          response.type === UACServices.DeletePsaCredentialsAPI.SUCCESS
        ) {
          yield put(psaIntegrationsActions.setPsaProviderDetails(null))
          yield put(psaIntegrationsActions.getPsaProviderDetails())
          yield put(setSuccessBanner())
        } else {
          yield put(setErrorBanner())
        }
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(psaIntegrationsActions.setPsaInProgress(false))
    }
  }

  function setErrorBanner(): ActionWithPayload<
    GlobalActionTypes.SET_NOTIFICATION,
    {
      notification: NotificationProps
    }
  > {
    return globalActions.setNotification({
      message: msg`Something went wrong. Check your connection and try again.`,
      type: NotificationType.alert,
      autoDismiss: true
    })
  }

  function setSuccessBanner(): ActionWithPayload<
    GlobalActionTypes.SET_NOTIFICATION,
    {
      notification: NotificationProps
    }
  > {
    return globalActions.setNotification({
      message: msg`Settings were removed.`,
      type: NotificationType.success,
      autoDismiss: true
    })
  }

  function getDeleteProviderConfirmDialog() {
    const dialogText = msg`Billing updates will no longer be possible, and PSA credentials will be deleted. Imported managed companies remain in LastPass unchanged.`

    const dialog: ConfirmDialog = {
      type: 'confirmdialog',
      confirmText: msg`Remove`,
      discardText: msg`Cancel`,
      text: dialogText,
      title: msg`Remove integration?`
    }
    return dialog
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const FAIL_EMAIL_TEMPLATES_NOT_APPROVED = 'emailTemplatesNotApproved'

const success = () => createResponse(SUCCESS)
const emailTemplatesNotApproved = () =>
  createResponse(FAIL_EMAIL_TEMPLATES_NOT_APPROVED)
export const Responses = {
  success,
  emailTemplatesNotApproved
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(queryPath: string) {
    const [response, status] = await fetch('users/reinvite' + queryPath, 'POST')

    if (status === StatusCodes.ACCEPTED) {
      return success()
    }
    if (status === StatusCodes.OK && response) {
      const parsedResponse = JSON.parse(response)
      if (parsedResponse.content.status === 'success') {
        return success()
      } else if (
        parsedResponse.content.globalFailureReason ===
        'error_email_template_not_approved'
      ) {
        return emailTemplatesNotApproved()
      }
    }
    throw new UnknownServiceError()
  }
}

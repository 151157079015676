import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Migration from '@lastpass/admin-console-dependencies/server/applications/saml/get-is-migration-reqired-managed-companies'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'

export function createIsMigratableManagedCompanies(
  applicationsServices: UACServices.Services
) {
  return function* getIsMigratableManagedCompanies() {
    try {
      const migratableManagedCompaniesResponse: Migration.Responses = yield call(
        applicationsServices.getMigratableManagedCompanies
      )

      if (
        migratableManagedCompaniesResponse &&
        migratableManagedCompaniesResponse.type === Migration.SUCCESS
      ) {
        const migratableManagedCompanies =
          migratableManagedCompaniesResponse.body.Value
        yield put(
          systemUpgradeActions.setMigratableManagedCompanies(
            migratableManagedCompanies
          )
        )
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

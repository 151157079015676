import React, { FunctionComponent, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'
import { LogoCard } from '@lastpass/lastkit'
import {
  BodyBold,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationGeneralDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { StyledCardContainer } from '@lastpass/admin-console-dependencies/ui/users/federated-login/StyledComponents'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`
const StyledBodyText = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
`

const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
`

const AccordionContainer = styled.div`
  margin-bottom: 24px;
`

interface KeyRotationGeneralDialogProps {
  state: KeyRotationGeneralDialog
  actions: typeof globalActions
}

export const KeyRotationGeneralDialogComponent: FunctionComponent<KeyRotationGeneralDialogProps> = ({
  actions,
  state
}) => {
  const dispatch = useDispatch()
  const isAzureAd = state.provider === OpenIdProvider.AzureAD

  const getSupportLinkUrl = useCallback(() => {
    switch (state.provider) {
      case OpenIdProvider.AzureAD:
        return 'https://link.lastpass.com/rotate-knowledge-components-azure-ad'
      case OpenIdProvider.Okta:
        return 'https://link.lastpass.com/rotate-knowledge-components-okta'
      case OpenIdProvider.OneLogin:
        return 'https://link.lastpass.com/rotate-knowledge-components-onelogin'
      default:
        return ''
    }
  }, [state.provider])

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      disableMaxHeight
      title={msg`Rotate knowledge components`}
      disableCloseOnBackground
      content={
        <>
          <TextBoxContainer>
            <Trans>
              You’re about to generate new knowledge components for this
              LastPass integration. Regular rotation increases your protection
              against threats. Follow instructions carefully as you go.
            </Trans>
          </TextBoxContainer>
          <StyledBodyText>
            <Trans>
              <BodyBold>Important!</BodyBold>
            </Trans>
            <StyledList>
              <li>
                <Trans>
                  Use a <BodyBold>modern browser</BodyBold> (Chrome v110+,
                  Firefox v112+, not Internet Explorer).
                </Trans>
              </li>
              <li>
                <Trans>
                  <BodyBold>Users are not impacted</BodyBold> during this
                  process. They won’t need to take any additional actions and
                  their login process won’t change.{' '}
                  <StyledExternalLink
                    href={getSupportLinkUrl()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More info
                  </StyledExternalLink>
                </Trans>
              </li>
              <li>
                <Trans>
                  Set aside about 20 minutes for this process; there’s
                  documentation you’ll want to read carefully.
                </Trans>
              </li>
            </StyledList>
          </StyledBodyText>
        </>
      }
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(globalActions.discardDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            actions.confirmDialog()
          }}
          type="submit"
          key="confirm"
        >
          <Trans>Continue</Trans>
        </PrimaryButton>
      ]}
      superFooter={
        isAzureAd ? (
          <AccordionContainer>
            <LogoCard
              dataQaName={'KeyRotationCard'}
              title={
                <Heading100>
                  <Trans>Still have concerns?</Trans>
                </Heading100>
              }
            >
              <StyledCardContainer>
                <StyledBodyText>
                  <Trans>
                    Here’s some more info to help you move forward confidently:
                  </Trans>
                  <StyledList>
                    <li>
                      <Trans>
                        The length of the actual rotation depends on the number
                        of users in your organization. As a guideline, 1000
                        users can be rotated in about 2 minutes.
                      </Trans>
                    </li>
                    <li>
                      <Trans>
                        If anything interrupts the key rotation process (such as
                        a power outage, network disconnections, etc.) you’ll be
                        able to try again without risk to your users or account.
                      </Trans>
                    </li>
                  </StyledList>
                </StyledBodyText>
              </StyledCardContainer>
            </LogoCard>
          </AccordionContainer>
        ) : (
          undefined
        )
      }
    />
  )
}

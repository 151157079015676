import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components'
import {
  BodyRegularStyle,
  DrawerTable,
  Pagination,
  PrimaryButton,
  RowContainer,
  SearchInput,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import {
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  StyledSpacer
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { transferVaultDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/transfer-vault/actions'
import { TransferVaultState } from '@lastpass/admin-console-dependencies/state/users/view/transfer-vault/reducer'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'

interface TransferVaultDrawerProps {
  closeLink: string
}

export const TransferVaultDrawer: React.FunctionComponent<TransferVaultDrawerProps> = ({
  closeLink
}) => {
  const updateQuery = useUpdateQuery(Namespace.transferVaultDrawer)
  const queryParams = useQueryParams(Namespace.transferVaultDrawer)
  const { id: userId } = useParams<{ id: string }>()

  const {
    sourceUser,
    targetUser,
    usersTable,
    isLoading,
    isTransferCompleted
  } = useSelector<AppState, TransferVaultState>(
    appState => appState.transferVaultDrawer
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !userId ||
      (isTransferCompleted && userId === (sourceUser && sourceUser.userId))
    ) {
      return
    }

    dispatch(
      transferVaultDrawerActions.getTransferVaultData(
        userId,
        {
          query: {
            search: queryParams.search,
            skip: queryParams.skip || 0
          },
          path: ''
        },
        usersTable.resultsPerPage
      )
    )

    return () => {
      dispatch(transferVaultDrawerActions.setIsTransferCompleted(false))
    }
    /* eslint-disable-next-line */
  }, [queryParams])

  const MainTitle = () => {
    if (isLoading || !sourceUser) {
      return <></>
    }

    return <>{getFullName(sourceUser) || sourceUser.email}</>
  }

  const StyledDescription = styled.div`
    ${BodyRegularStyle}
    margin: -25px 0 30px 0;
  `
  return (
    <Drawer
      mainTitle={<MainTitle />}
      subTitle={<Trans>Transfer vault</Trans>}
      closeLink={closeLink}
    >
      <StyledDescription>
        <Trans>
          Choose who will receive the contents of this vault. The original user
          will be deleted from your account.
        </Trans>
      </StyledDescription>

      {isLoading && <Loading color="blue900" active={true} />}

      <RowContainer>
        <SearchInput
          flexGrow
          onChange={event => {
            updateQuery({
              search: event.target.value || undefined,
              skip: undefined
            })
          }}
          value={queryParams.search || ''}
          placeholder={msg`Search users...`}
          data-qa={'UsersPageSearchUsersField'}
        />

        <Pagination
          skip={queryParams.skip || 0}
          totalResults={usersTable.totalResults}
          resultsPerPage={usersTable.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </RowContainer>
      <DrawerTable
        qadata={'TransferVaultList'}
        table={usersTable}
        useRadioButtons
        radioButtonAction={record => {
          dispatch(transferVaultDrawerActions.selectTargetUser(record))
        }}
        isFilterApplied
        noResultsView={
          <TableView
            icon={<UserIcon data-qa={'NoUserIcon'} />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={
              <Trans>
                Try searching for different keywords and check your spelling for
                any typos.
              </Trans>
            }
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined
                  })
                }}
                data-qa={'ClearSearchButton'}
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: function ApplicationCellRenderer({
              firstName,
              lastName
            }: User) {
              return (
                <>
                  <span>
                    {firstName} {lastName}
                  </span>
                </>
              )
            }
          },
          {
            name: <Trans>Email</Trans>,
            renderer: function ApplicationCellRenderer({ email }: User) {
              return (
                <>
                  <span>{email}</span>
                </>
              )
            }
          }
        ]}
      />
      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          <LocationLink to={`/users/view`}>
            <TextButton
              css={css`
                margin-right: 10px;
              `}
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>

          <PrimaryButton
            disabled={!sourceUser || !targetUser}
            onClick={() => {
              dispatch(transferVaultDrawerActions.openWindow())
            }}
            type="submit"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </DrawerButtonsContainer>
      </FooterContainer>
    </Drawer>
  )
}

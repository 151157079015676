import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { readFileAsText } from '@lastpass/admin-console-dependencies/services/read-file-as-text'
import { rsaSecureIDRadiusActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/actions'
import {
  RSASecureIDRadius,
  RSASecureIDRadiusFormData
} from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getRSASecureIDRadiusInState = (state: AppState) =>
  state.rsaSecureIDRadius.rsaSecureIDRadiusSettings

export function createSaveRSASecureIDRadius(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveRSASecureIDRadius(
    action: ReturnType<typeof rsaSecureIDRadiusActions.saveRSASecureIDRadius>
  ) {
    try {
      const rsaSecureFormData: RSASecureIDRadiusFormData =
        action.payload.rsaOptions

      const rsaSecureIDRadiusInState: RSASecureIDRadius = yield select(
        getRSASecureIDRadiusInState
      )

      const rsaSecureIDRadius: RSASecureIDRadius = {
        ...rsaSecureIDRadiusInState,
        ...rsaSecureFormData,
        logo124x124: '',
        logo190x41: ''
      }
      delete rsaSecureIDRadius.logo124x124Name
      delete rsaSecureIDRadius.logo190x41Name

      if (rsaSecureFormData.logo124x124) {
        rsaSecureIDRadius.logo124x124 = yield call(
          readFileAsText,
          rsaSecureFormData.logo124x124
        )
      }
      if (rsaSecureFormData.logo190x41) {
        rsaSecureIDRadius.logo190x41 = yield call(
          readFileAsText,
          rsaSecureFormData.logo190x41
        )
      }

      yield put(rsaSecureIDRadiusActions.setRSASecureIDRadiusLoading(true))
      try {
        const rsaSecureIDRadiusResult: UACServices.RSASecureIDRadiusSaveAPI.Responses = yield call(
          enterpriseOptionServices.rsaSecureIDRadiusSave,
          rsaSecureIDRadius
        )
        if (
          rsaSecureIDRadiusResult.type ===
          UACServices.RSASecureIDRadiusSaveAPI.SUCCESS
        ) {
          yield put(rsaSecureIDRadiusActions.getRSASecureIDRadius())
          yield put(
            globalActions.setNotification({
              message: msg`RSA SecurID settings saved`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`RSA SecurID settings could not be saved`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(rsaSecureIDRadiusActions.setRSASecureIDRadiusLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { track } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import {
  createConfigureDirectoryClickedSegment,
  createIntegrationLogsClickedAction,
  createRemoveConfigureDirectoryClickedSegment,
  createUpdateGoogleSyncOptionsAction,
  directoriesSegmentActions
} from './segment-actions'
import {
  AccountInformation,
  DirectoryIntegration,
  DirectoryIntegrationBase,
  GoogleDirectoryIntegration,
  GoogleGroup,
  GoogleUser,
  IntegrationLog,
  Provider,
  SystemLog
} from './state'

export enum DirectoriesActionTypes {
  GET_DIRECTORY_INTEGRATIONS = '[directories-integrations] get',
  GET_DIRECTORY_INTEGRATIONS_PROVISION_TOKEN = '[directories-integrations] get-token',
  SET_DIRECTORY_INTEGRATIONS_PROVISION_TOKEN = '[directories-integrations] set-token',
  GET_DIRECTORY_INTEGRATIONS_SETTINGS = '[directories-integrations] get-settings',
  SET_DIRECTORY_INTEGRATIONS_SETTINGS = '[directories-integrations] set-settings',
  RESET_AD_CONNECTOR_API_KEY = '[directories-integrations] reset-ad-connector-api-key',
  SET_DIRECTORY_INTEGRATIONS_LOADING = '[directories-integrations] set-loading',
  GET_AD_CONNECTOR_API_KEY_EXISTENCE = '[directories-integrations] get-ad-connector-api-key-existence',
  SET_AD_CONNECTOR_API_KEY_EXISTENCE = '[directories-integrations] set-ad-connector-api-key-existence',
  GET_AD_CONNECTOR_VERSION_LAST_SYNC = '[dircetories-integrations] get-ad-connector-version-last-sync',
  SET_AD_CONNECTOR_VERSION_LAST_SYNC = '[dircetories-integrations] set-ad-connector-version-last-sync',
  GET_DIRECTORY_ACCOUNT_INFORMATION = '[directories-integrations] get-account-information',
  SET_DIRECTORY_ACCOUNT_INFORMATION = '[directories-integrations] set-account-information',
  SET_GOOGLE_INTEGRATION_PROVISION_INFO = '[directories-integrations] set-google-integration-provision-information',
  SAVE_GOOGLE_INTEGRATION = '[directories-integrations] save-google-integration',
  SYNC_GOOGLE_USERS = '[directories-integrations] sync-google-users',
  FETCH_GOOGLE_AUDIT_LOG_LIST = '[directories-integrations] fetch-google-audit-log-list',
  FETCH_SYSTEM_LOG_LIST = '[directories-integrations] fetch-system-log-list',
  SET_LOG_LIST = '[directories-integrations] set-log-list',
  SET_LOG_DETAILS = '[directories-integrations] set-log-details',
  FETCH_LOG_LIST_FAILED = '[directories-integrations] fetch-log-list-failed',
  SYNC_STARTED = '[directories-integrations] sync-started',
  SYNC_FINISHED = '[directories-integrations] sync-finished',
  UPDATE_GOOGLE_SYNC_OPTIONS = '[directories-integrations] update-google-sync-options',
  FETCH_GOOGLE_USERS = '[directories-integrations] fetch-google-users',
  SET_GOOGLE_USERS = '[directories-integrations] set-google-users',
  FETCH_GOOGLE_USERS_FAILED = '[directories-integrations] fetch-google-users-failed',
  FETCH_GOOGLE_GROUPS = '[directories-integrations] fetch-google-groups',
  SET_GOOGLE_GROUPS = '[directories-integrations] set-google-groups',
  FETCH_GOOGLE_GROUPS_FAILED = '[directories-integrations] fetch-google-groups-failed',
  TURN_ON_SEARCH_BASED_GROUP_SELECTION = '[directories-integrations] turn-on-search-based-group-selection',
  SEARCH_GOOGLE_GROUPS = '[directories-integrations] search-google-groups'
}

export const directoriesActions = {
  getDirectoryIntegrations: () =>
    createAction(DirectoriesActionTypes.GET_DIRECTORY_INTEGRATIONS),
  resetADConnectorApiKey: () =>
    track(
      createAction(DirectoriesActionTypes.RESET_AD_CONNECTOR_API_KEY),
      createRemoveConfigureDirectoryClickedSegment(Provider.ADConnector)
    ),
  getADConnectorApiKeyExistence: () =>
    createAction(DirectoriesActionTypes.GET_AD_CONNECTOR_API_KEY_EXISTENCE),
  setADConnectorAPIKeyExistence: (hasAPIKey: boolean) =>
    createAction(DirectoriesActionTypes.SET_AD_CONNECTOR_API_KEY_EXISTENCE, {
      hasAPIKey
    }),
  getADConnectorVersionLastSync: () =>
    createAction(DirectoriesActionTypes.GET_AD_CONNECTOR_VERSION_LAST_SYNC),
  setADConnectoreVersionLastSync: (lastSyncVersion: DirectoryIntegrationBase) =>
    createAction(DirectoriesActionTypes.SET_AD_CONNECTOR_VERSION_LAST_SYNC, {
      lastSyncVersion
    }),
  setDirectoryIntegrationsLoading: (loading: boolean) =>
    createAction(DirectoriesActionTypes.SET_DIRECTORY_INTEGRATIONS_LOADING, {
      loading
    }),
  getDirectoryAccountInformation: () =>
    createAction(DirectoriesActionTypes.GET_DIRECTORY_ACCOUNT_INFORMATION),
  setDirectoryAccountInformation: (accountInfo: AccountInformation) =>
    createAction(DirectoriesActionTypes.SET_DIRECTORY_ACCOUNT_INFORMATION, {
      accountInfo
    }),
  getDirectoryProvisionToken: (provider: Provider) =>
    track(
      createAction(
        DirectoriesActionTypes.GET_DIRECTORY_INTEGRATIONS_PROVISION_TOKEN,
        { provider }
      ),
      createConfigureDirectoryClickedSegment(provider)
    ),
  setDirectoryProvisionToken: (provisionToken: string, provider: Provider) =>
    createAction(
      DirectoriesActionTypes.SET_DIRECTORY_INTEGRATIONS_PROVISION_TOKEN,
      { provisionToken, provider }
    ),
  getDirectorySettings: (callback?: () => void) =>
    createAction(DirectoriesActionTypes.GET_DIRECTORY_INTEGRATIONS_SETTINGS, {
      callback
    }),
  setDirectorySettings: (settings: DirectoryIntegration) =>
    createAction(DirectoriesActionTypes.SET_DIRECTORY_INTEGRATIONS_SETTINGS, {
      settings
    }),
  reportConfigureDirectoryClicked: (provider: Provider) =>
    directoriesSegmentActions.configureDirectoryClicked(provider),
  setGoogleProvisioningInfo: (
    googleProvisioningInfo: GoogleDirectoryIntegration
  ) =>
    createAction(DirectoriesActionTypes.SET_GOOGLE_INTEGRATION_PROVISION_INFO, {
      googleProvisioningInfo
    }),
  saveGoogleIntegration: (
    adminEmail: string,
    provisionToken: string,
    enabled: boolean,
    integrationExists: boolean,
    callback: () => void
  ) =>
    track(
      createAction(DirectoriesActionTypes.SAVE_GOOGLE_INTEGRATION, {
        adminEmail,
        provisionToken,
        enabled,
        integrationExists,
        callback
      }),
      enabled
        ? createConfigureDirectoryClickedSegment(Provider.Google)
        : createRemoveConfigureDirectoryClickedSegment(Provider.Google)
    ),
  syncGoogleUsers: () => createAction(DirectoriesActionTypes.SYNC_GOOGLE_USERS),
  fetchGoogleAuditLogList: (
    skip: number,
    top: number,
    filters: { identity?: string; status: string }
  ) =>
    createAction(DirectoriesActionTypes.FETCH_GOOGLE_AUDIT_LOG_LIST, {
      skip,
      top,
      filters
    }),
  fetchSystemLogList: (
    skip: number,
    top: number,
    filters: { status: string }
  ) =>
    createAction(DirectoriesActionTypes.FETCH_SYSTEM_LOG_LIST, {
      skip,
      top,
      filters
    }),
  setLogList: (logs: IntegrationLog[] | SystemLog[], numberOfItems: number) =>
    createAction(DirectoriesActionTypes.SET_LOG_LIST, {
      logs,
      numberOfItems
    }),
  setLogDetails: (log: IntegrationLog | SystemLog | null) =>
    createAction(DirectoriesActionTypes.SET_LOG_DETAILS, {
      log
    }),
  fetchLogListFailed: () =>
    createAction(DirectoriesActionTypes.FETCH_LOG_LIST_FAILED),
  syncStarted: () => createAction(DirectoriesActionTypes.SYNC_STARTED),
  syncFinished: () => createAction(DirectoriesActionTypes.SYNC_FINISHED),
  updateGoogleSyncOptions: (
    partialSyncEnabled: boolean,
    adminEmail: string,
    provisionToken: string,
    enabled: boolean,
    callback: () => void
  ) =>
    createUpdateGoogleSyncOptionsAction(
      partialSyncEnabled,
      adminEmail,
      provisionToken,
      enabled,
      callback
    ),
  fetchGoogleUsers: (search: string, replace: boolean) =>
    createAction(DirectoriesActionTypes.FETCH_GOOGLE_USERS, {
      search,
      replace
    }),
  setGoogleUsers: (
    users: GoogleUser[],
    replace: boolean,
    nextPageToken: string | null
  ) =>
    createAction(DirectoriesActionTypes.SET_GOOGLE_USERS, {
      users,
      replace,
      nextPageToken
    }),
  fetchGoogleUsersFailed: () =>
    createAction(DirectoriesActionTypes.FETCH_GOOGLE_USERS_FAILED),
  fetchGoogleGroups: () =>
    createAction(DirectoriesActionTypes.FETCH_GOOGLE_GROUPS),
  setGoogleGroups: (
    groups: GoogleGroup[],
    replace?: boolean,
    nextPageToken?: string | null
  ) =>
    createAction(DirectoriesActionTypes.SET_GOOGLE_GROUPS, {
      groups,
      replace,
      nextPageToken
    }),
  fetchGoogleGroupsFailed: () =>
    createAction(DirectoriesActionTypes.FETCH_GOOGLE_GROUPS_FAILED),
  reportIntegrationLogsClicked: () => createIntegrationLogsClickedAction(),
  turnOnSearchBasedGroupSelection: () =>
    createAction(DirectoriesActionTypes.TURN_ON_SEARCH_BASED_GROUP_SELECTION),
  searchGoogleGroups: (search: string, replace: boolean) =>
    createAction(DirectoriesActionTypes.SEARCH_GOOGLE_GROUPS, {
      search,
      replace
    })
}

export type DirectoriesActions = ActionsUnion<typeof directoriesActions>

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Saml from '@lastpass/admin-console-dependencies/server/applications/saml'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'

export function createStartUpgradeSaga(
  applicationsServices: UACServices.Services
) {
  return function* startUpgradeSaga() {
    try {
      const migrationResponse: Saml.SSOMigrateAll.Responses = yield call(
        applicationsServices.migrateAll
      )
      //check responses
      let errorMessage = ''
      const succeed = migrationResponse.body.response.Succeeded
      if (!succeed) {
        errorMessage = migrationResponse.body.response.Message
      }
      yield put(systemUpgradeActions.upgradeCompleted(succeed, errorMessage))
    } catch (e) {
      yield put(systemUpgradeActions.upgradeCompleted(false, 'Internal Error'))
    }
  }
}

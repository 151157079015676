import React, { FC, ReactNode } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { BodyBold, WithTooltip } from '@lastpass/lastkit'

import { LegendItem } from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/LegendItem'

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`
const LegendTotal = styled.div`
  display: flex;
  align-items: center;
`
const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral500};
  height: 14px;
  width: 14px;
  margin-left: 8px;
`

export interface LegendItem {
  value: number
  label: MessageDescriptor
  backgroundColor: string
  tooltip?: ReactNode
}

export interface LegendTotal {
  text: MessageDescriptor
  value: number
  tooltip?: ReactNode
}

export interface LegendProps {
  legendTotal: LegendTotal
  data: LegendItem[]
  dataQa?: string
}

export const Legend: FC<LegendProps> = ({ legendTotal, data, dataQa }) => {
  const { _ } = useLingui()
  const itemBorderColor = data[0].backgroundColor

  return (
    <LegendContainer>
      <LegendTotal>
        <BodyBold data-qa={dataQa && dataQa + 'LegendTotal'}>
          {_(legendTotal.text)}
          {': ' + String(legendTotal.value)}
        </BodyBold>
        {legendTotal.tooltip && (
          <WithTooltip tooltip={legendTotal.tooltip} width="450px">
            <StyledHelpIcon data-qa={dataQa && dataQa + 'TooltipIcon'} />
          </WithTooltip>
        )}
      </LegendTotal>
      {data.map((legend, index) => (
        <LegendItem
          key={legend.label.id}
          {...legend}
          borderColor={itemBorderColor}
          dataQa={dataQa && dataQa + 'LegendItem' + index}
        />
      ))}
    </LegendContainer>
  )
}

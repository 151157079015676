import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { userFilterActions } from '@lastpass/admin-console-dependencies/state/common/user-filter/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createUserListByIdListSaga(
  userFilterService: UACServices.Services
) {
  return function* getUserListByIdListSaga(
    action: ReturnType<typeof userFilterActions.getUserListByIdList>
  ) {
    try {
      const query = buildQuery({
        filter: [
          {
            or: action.payload.idList.map(userId => ({
              userId: parseInt(userId, 10)
            }))
          }
        ],
        top: action.payload.idList.length
      })
      const result: UACServices.UserFilterAPI.Responses = yield call(
        userFilterService.userFilter,
        query
      )
      if (result.type === UACServices.UserFilterAPI.SUCCESS) {
        const users = result.body.usersList
        yield put(userFilterActions.setUserListByIdList(users))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { AdfsSetupState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)
const failure = (reason: string) => createResponse(reason)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(adfsSetup: AdfsSetupState) {
    try {
      const [response, status] = await fetch(
        'company/adfs-provider-info',
        'POST',
        adfsSetup,
        { 'content-type': 'application/json' }
      )
      const responseJson = (response && JSON.parse(response)) || {}
      if (status === StatusCodes.OK && responseJson.status === 'ok') {
        return success()
      } else {
        return failure(responseJson.message || '')
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React, { FC } from 'react'

import styled from '@emotion/styled'

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.grayBorder};
  padding: 16px;
  box-shadow: 0px 1px 2px 0px rgba(29, 48, 73, 0.04),
    0px 2px 2px 0px rgba(29, 48, 73, 0.04),
    0px 0px 2px 0px rgba(29, 48, 73, 0.16);
  background-color: ${props => props.theme.colors.white};
  width: 100%;
`

export const Card: FC<{ className?: string }> = ({ children, className }) => {
  return <Container className={className}>{children}</Container>
}

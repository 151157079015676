import { msg } from '@lingui/macro'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { sharedFolderDrawerActions } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'
import { SharedFolderDialogueType } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function createSharedFolderAddSitesSaga() {
  return function* sharedFolderAddAppsSaga(
    action: ReturnType<typeof sharedFolderDrawerActions.sharedFolderAddSites>
  ) {
    let cmd = 'cmd=sfaddsite'
    if (action && action.payload && action.payload.sharedFolderId) {
      cmd += '&shareid=' + action.payload.sharedFolderId
    }

    yield put(
      globalActions.setDialog({
        type: 'sharedfolderdialog',
        mainText: msg`Assign apps`,
        secondaryText: msg`Go to your LastPass vault to create a shared folder.`,
        learnMoreText: msg`Learn more`,
        skipText: msg`Next time, take me there directly`,
        confirmText: msg`Go to vault`,
        shareType: SharedFolderDialogueType.apps,
        lastpassQueryParams: cmd
      })
    )
  }
}

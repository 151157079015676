import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { policyHelper } from '@lastpass/admin-console-dependencies/services/policy-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { SsoAppsContainerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/state'

import { PasswordlessEmptyList } from './SsoAppsEmptyList'
import { PasswordlessPage } from './SsoAppsPage'

type SsoAppsContainerProps = LastPassComponentProps<
  SsoAppsContainerState,
  typeof passwordlessActions & typeof globalActions,
  {}
>

export const SsoAppsContainerComponent: React.FunctionComponent<SsoAppsContainerProps> = ({
  state,
  actions
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch])

  const isEmpty =
    !state.RequirePasswordlessViaLastPassPolicy.checked ||
    policyHelper.isRequiredPolicyUnused(
      state.RequirePasswordlessViaLastPassPolicy
    )

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  return isEmpty || !userHasMfaAddon ? (
    <PasswordlessEmptyList actions={actions} />
  ) : (
    <PasswordlessPage isEmpty={isEmpty} />
  )
}

export function createPasswordlessContainerComponent<
  TState extends { passwordlessContainer: SsoAppsContainerState }
>() {
  return connectRedux(
    SsoAppsContainerComponent,
    (state: TState) => state.passwordlessContainer,
    {
      ...passwordlessActions,
      ...globalActions
    }
  )
}

export type PasswordlessContainer = ReturnType<
  typeof createPasswordlessContainerComponent
>

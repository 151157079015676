import { decrypt as decryptAES, decryptPrivateKey } from '@lastpass/encryption'
import { decrypt as decryptRSA } from '@lastpass/encryption/rsa'

import { decryptRecoveryKey } from '@lastpass/admin-console-dependencies/services/recovery-keys/decrypt-recovery-keys'
import { DecryptionDataRow } from '@lastpass/admin-console-dependencies/ui/global/dialogs/security-reports/hooks/use-prepare-decryption-data-from-csv'

export interface SharedFolderEncryptionKeyWithId {
  sharedFolderId: string
  decryptedSharedFolderEncryptionKey: Buffer
}

interface UserVaultEncryptionKeyWithUserId {
  [userId: string]: Buffer
}

interface DecryptedPrivateSharingKeyWithUserId {
  [userId: string]: string
}

export const getSharedFolderEncryptionKeysWithId = async (
  decryptionData: DecryptionDataRow[],
  requesterDecryptedPrivateSharingKey: string,
  requesterVaultKey: Buffer,
  abortController: AbortController
) => {
  const sharedFolderEncryptionKeysWithId: SharedFolderEncryptionKeyWithId[] = []
  const userVaultEncryptionKeysWithUserId: UserVaultEncryptionKeyWithUserId[] = []
  const decryptedPrivateSharingKeysWithUserId: DecryptedPrivateSharingKeyWithUserId[] = []

  for (const data of decryptionData) {
    let decryptedSharedFolderEncryptionKey: Buffer | string = ''
    if (abortController.signal.aborted) {
      break
    }

    if (data.encryptedSharedFolderEncryptionKeyOwner === 'user') {
      decryptedSharedFolderEncryptionKey = await decryptSharedFolderEncryptionKeyForUser(
        data,
        requesterDecryptedPrivateSharingKey,
        userVaultEncryptionKeysWithUserId,
        decryptedPrivateSharingKeysWithUserId
      )
    } else if (data.encryptedSharedFolderEncryptionKeyOwner === 'admin') {
      decryptedSharedFolderEncryptionKey = await decryptSharedFolderEncryptionKeyForAdmin(
        data,
        requesterVaultKey
      )
    }

    if (typeof decryptedSharedFolderEncryptionKey === 'string') {
      sharedFolderEncryptionKeysWithId.push({
        sharedFolderId: data.sharedFolderId,
        decryptedSharedFolderEncryptionKey: Buffer.from(
          decryptedSharedFolderEncryptionKey,
          'hex'
        )
      })
    } else {
      sharedFolderEncryptionKeysWithId.push({
        sharedFolderId: data.sharedFolderId,
        decryptedSharedFolderEncryptionKey
      })
    }
  }

  return sharedFolderEncryptionKeysWithId
}

const decryptSharedFolderEncryptionKeyForUser = async (
  data: DecryptionDataRow,
  requesterDecryptedPrivateSharingKey: string,
  userVaultEncryptionKeysWithUserId: UserVaultEncryptionKeyWithUserId[],
  decryptedPrivateSharingKeysWithUserId: DecryptedPrivateSharingKeyWithUserId[]
): Promise<Buffer | string> => {
  if (data.recoveryKey === '') {
    throw new Error('Missing recovery key')
  }
  const userVaultEncrytionKey = await getUserVaultEncryptionKey(
    data,
    userVaultEncryptionKeysWithUserId,
    requesterDecryptedPrivateSharingKey
  )

  if (data.encryptedSharedFolderEncryptionKeyType === 'sharingkey') {
    const decryptedUsersPrivateSharingKey = await getPrivateSharingKey(
      data,
      decryptedPrivateSharingKeysWithUserId,
      userVaultEncrytionKey
    )

    return await decryptSharedFolderEncryptionKeyWithRSA(
      data.encryptedSharedFolderEncryptionKey,
      decryptedUsersPrivateSharingKey
    )
  } else if (data.encryptedSharedFolderEncryptionKeyType === 'aes') {
    return await decryptSharedFolderEncryptionKeyWithAES(
      data.encryptedSharedFolderEncryptionKey,
      userVaultEncrytionKey
    )
  }
  throw new Error('Corrupted data')
}

const decryptSharedFolderEncryptionKeyForAdmin = async (
  data: DecryptionDataRow,
  requesterVaultKey: Buffer
): Promise<Buffer | string> => {
  if (data.encryptedSharedFolderEncryptionKeyType === 'sharingkey') {
    const decryptedPrivateSharingKey = await decryptPrivateKey(
      data.privateSharingKey,
      requesterVaultKey
    )

    return await decryptSharedFolderEncryptionKeyWithRSA(
      data.encryptedSharedFolderEncryptionKey,
      decryptedPrivateSharingKey
    )
  } else if (data.encryptedSharedFolderEncryptionKeyType === 'aes') {
    return await decryptSharedFolderEncryptionKeyWithAES(
      data.encryptedSharedFolderEncryptionKey,
      requesterVaultKey
    )
  }
  throw new Error('Corrupted data')
}

const decryptSharedFolderEncryptionKeyWithRSA = async (
  encryptedSharedFolderEncryptionKey: string,
  privateSharingKey: string
) => {
  return await decryptRSA(
    Buffer.from(encryptedSharedFolderEncryptionKey, 'hex'),
    Buffer.from(privateSharingKey, 'hex')
  )
}

const decryptSharedFolderEncryptionKeyWithAES = async (
  encryptedSharedFolderEncryptionKey: string,
  vaultKey: Buffer
) => {
  return await decryptAES(
    encryptedSharedFolderEncryptionKey,
    vaultKey,
    'base64'
  )
}

const getUserVaultEncryptionKey = async (
  data: DecryptionDataRow,
  userVaultEncryptionKeysWithUserId: UserVaultEncryptionKeyWithUserId[],
  requesterDecryptedPrivateSharingKey: string
): Promise<Buffer> => {
  let userVaultEncrytionKey: Buffer =
    userVaultEncryptionKeysWithUserId[data.userId]

  if (!userVaultEncrytionKey) {
    userVaultEncrytionKey = await decryptRecoveryKey(
      data.recoveryKey,
      requesterDecryptedPrivateSharingKey
    )
    if (userVaultEncrytionKey) {
      userVaultEncryptionKeysWithUserId[data.userId] = userVaultEncrytionKey
    } else {
      throw new Error('Cannot decrypt recovery key')
    }
  }
  return userVaultEncrytionKey
}

const getPrivateSharingKey = async (
  data: DecryptionDataRow,
  decryptedPrivateSharingKeysWithUserId: DecryptedPrivateSharingKeyWithUserId[],
  usersVaultEncrytionKey: Buffer
): Promise<string> => {
  let decryptedUsersPrivateSharingKey: string =
    decryptedPrivateSharingKeysWithUserId[data.userId]

  if (!decryptedUsersPrivateSharingKey) {
    decryptedUsersPrivateSharingKey = await decryptPrivateKey(
      data.privateSharingKey,
      usersVaultEncrytionKey
    )
    if (decryptedUsersPrivateSharingKey) {
      decryptedPrivateSharingKeysWithUserId[
        data.userId
      ] = decryptedUsersPrivateSharingKey
    } else {
      throw new Error('Cannot decrypt private sharing key')
    }
  }
  return decryptedUsersPrivateSharingKey
}

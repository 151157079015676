import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (usersCSV: string) => createResponse(SUCCESS, usersCSV)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(isAccountStatusRenamed: boolean) {
    const [response, status] = await fetch(
      `users/weak-iteration/export?isAccountStatusRenamed=${isAccountStatusRenamed}`,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      return success(response)
    }
    throw new UnknownServiceError()
  }
}

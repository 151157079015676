import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'
import { ReactComponent as AzureIcon } from '@lastpass/assets/svg/admin-console/directories/icon-azure.svg'
import {
  OpenIdProvider,
  openIdProviderNameMapping
} from '@lastpass/federation/lib/federation-enums'
import { Checkbox, LogoCard } from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  FederatedLoginState,
  OpenIdSetupState
} from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import { KcRotationAndVaultReEncryptionBlock } from './KcRotationAndVaultReEncryptionBlock'
import { KeyRotationBlock } from './KeyRotationBlock'
import {
  BoldWrapper,
  CheckBoxWithToolTip,
  CheckBoxWithToolTipWithMargin,
  StyledCardContainer,
  StyledChildRow,
  StyledExternalLink,
  StyledPrimaryButton,
  StyledSubtitle,
  StyledTextInput
} from './StyledComponents'

interface OpenIdSetupFormProps {
  handleSubmit: (openIdSetup: OpenIdSetupState) => void
  authority: string
  clientId: string
  enabled: boolean
  isOktaWithAdc: boolean
  provider: OpenIdProvider
  isEmailHintDisabled: boolean
  useAzureMdmFlow: boolean
  pkceEnabled: boolean
}

const AzureAdSetupForm: FunctionComponent<OpenIdSetupFormProps> = ({
  handleSubmit,
  authority,
  clientId,
  enabled,
  provider,
  isEmailHintDisabled,
  useAzureMdmFlow,
  pkceEnabled
}) => {
  const isAzurePkceOptInEnabled = useFeatureFlags(
    FeatureFlags.isAzurePkceOptInEnabled
  )
  const isAzureAd = provider === OpenIdProvider.AzureAD
  const permissions = useContext(PermissionContext)
  const hasDirectoriesAndFederationModifyPermission = permissions.requirePermission(
    AdminPermissions.directoriesAndFederationModify
  )
  const isAzureMDMEnabled = useFeatureFlags(FeatureFlags.isAzureMDMEnabled)
  return (
    <Formik
      initialValues={{
        azureAdAuthority: isAzureAd ? authority : '',
        azureAdClientId: isAzureAd ? clientId : '',
        azureAdEnabled: isAzureAd ? enabled : false,
        azureIsEmailHintDisabled: isAzureAd ? isEmailHintDisabled : false,
        azureUseAzureMdmFlow:
          isAzureAd && isAzureMDMEnabled ? useAzureMdmFlow : false,
        azurePkceEnabled: pkceEnabled
      }}
      onSubmit={values =>
        handleSubmit({
          openIdUrl: values.azureAdAuthority.trim(),
          clientId: values.azureAdClientId.trim(),
          isEnabled: values.azureAdEnabled,
          useOktaAuthServerToStoreCompanyKey: false,
          useLastPassADConnectorToSyncUsers: false,
          provider: OpenIdProvider.AzureAD,
          isEmailHintDisabled: values.azureIsEmailHintDisabled,
          pkceEnabled: values.azurePkceEnabled,
          useAzureMdmFlow: values.azureUseAzureMdmFlow
        })
      }
    >
      {formikProps => {
        return (
          <>
            <StyledChildRow>
              <Field name="azureAdAuthority">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="AzureAdAuthorityInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>OpenID Connect meta data document URL</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="azureAdClientId">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa="AzureAdClientIdInput"
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                      readOnly={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Application (Client) ID</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <Field name="azureAdEnabled">
                {formData => {
                  return (
                    <Checkbox
                      data-qa="AzureAdEnabledCheckbox"
                      name={formData.field.name}
                      checked={formData.field.value}
                      onChange={e => {
                        formikProps.setFieldValue(
                          formData.field.name,
                          e.currentTarget.checked
                        )
                      }}
                      disabled={!hasDirectoriesAndFederationModifyPermission}
                    >
                      <Trans>Enabled</Trans>
                    </Checkbox>
                  )
                }}
              </Field>
              <Field name="azureIsEmailHintDisabled">
                {formData => {
                  return (
                    <CheckBoxWithToolTipWithMargin>
                      <Checkbox
                        data-qa="AzureAdDontSendHintCheckbox"
                        name={formData.field.name}
                        checked={formData.field.value}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.checked
                          )
                        }}
                        disabled={!hasDirectoriesAndFederationModifyPermission}
                        toolTip={i18n._(
                          msg`This prevents the username/email field from populating automatically upon user login`
                        )}
                      >
                        <Trans>Don{"'"}t send username/email hint to IdP</Trans>
                      </Checkbox>
                    </CheckBoxWithToolTipWithMargin>
                  )
                }}
              </Field>
              {isAzureMDMEnabled && (
                <Field name="azureUseAzureMdmFlow">
                  {formData => {
                    return (
                      <Checkbox
                        data-qa="AzureAdUseAzureMdmFlowCheckbox"
                        name={formData.field.name}
                        checked={formData.field.value}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.checked
                          )
                        }}
                        disabled={!hasDirectoriesAndFederationModifyPermission}
                      >
                        <Trans>Enable Conditional Access policies</Trans>
                      </Checkbox>
                    )
                  }}
                </Field>
              )}
              {isAzurePkceOptInEnabled && (
                <Field name="azurePkceEnabled">
                  {formData => {
                    return (
                      <CheckBoxWithToolTip>
                        <Checkbox
                          data-qa="AzurePkceEnabled"
                          name={formData.field.name}
                          checked={formData.field.value}
                          onChange={e => {
                            formikProps.setFieldValue(
                              formData.field.name,
                              e.currentTarget.checked
                            )
                          }}
                          disabled={
                            !hasDirectoriesAndFederationModifyPermission
                          }
                          toolTip={i18n._(
                            msg`Enable Proof Key for Code Exchange flow upon user login.`
                          )}
                        >
                          <Trans>Enable PKCE flow</Trans>
                        </Checkbox>
                      </CheckBoxWithToolTip>
                    )
                  }}
                </Field>
              )}
            </StyledChildRow>
            <StyledChildRow>
              <StyledPrimaryButton
                data-qa="AzureAdSaveChangesButton"
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                disabled={!hasDirectoriesAndFederationModifyPermission}
              >
                <Trans>Save changes</Trans>
              </StyledPrimaryButton>
            </StyledChildRow>
          </>
        )
      }}
    </Formik>
  )
}

export const AzureAdSetupCard: FunctionComponent<{
  automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy
}> = ({ automatedVaultReEncryptionPolicy }) => {
  const dispatch = useDispatch()
  const isAutomatedVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isAutomatedVaultReEncryptionEnabled
  )
  const currentAdminLevel: string = useSelector((state: AppState) => {
    return state.usersList.currentAdminLevel
  })
  const { openId, adfs }: FederatedLoginState = useSelector(
    (state: AppState) => state.federatedLogin
  )
  const {
    provider,
    isEnabled,
    clientId,
    pkceEnabled,
    openIdUrl,
    useAzureMdmFlow,
    useOktaAuthServerToStoreCompanyKey,
    isEmailHintDisabled
  } = openId
  const isAzureAd = provider === OpenIdProvider.AzureAD
  const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin

  useEffect(() => {
    dispatch(federatedLoginActions.getOpenIdSetup())
  }, [dispatch])
  return (
    <LogoCard
      dataQaName={'AzureAdCard'}
      logo={<AzureIcon />}
      highlighted={isAzureAd && isEnabled}
      title={
        <Heading100>
          <Trans>Azure AD</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from your Azure Active Directory. They
          {"'"}ll log in to LastPass with their AD credentials instead of their
          master password.
        </Trans>
      }
    >
      <StyledCardContainer>
        <StyledChildRow>
          <BoldWrapper>
            <Trans>Configure Azure AD</Trans>
          </BoldWrapper>
          <StyledExternalLink
            data-qa="AzureAdInstructionsLink"
            href={'http://link.lastpass.com/help-federated-azure'}
            target="_blank"
          >
            <Trans>Follow these instructions</Trans>
          </StyledExternalLink>
        </StyledChildRow>
        <StyledSubtitle>
          <Trans>
            Complete this form using information from your Azure portal.
          </Trans>
        </StyledSubtitle>
        {(!isAzureAd && isEnabled) || adfs.isEnabled ? (
          <div data-qa="IdpAlreadyConfiguredMessageOnAzureAd">
            <OpenIdProviderConfigurationMessage provider="Azure AD" />
          </div>
        ) : (
          <AzureAdSetupForm
            key={`azure ${openIdUrl} - ${clientId} - ${isEnabled}`}
            handleSubmit={(openIdSetup: OpenIdSetupState) => {
              dispatch(
                federatedLoginActions.saveOpenIdSetup(
                  openIdSetup,
                  openId,
                  openIdSetup.isEnabled &&
                    isAutomatedVaultReEncryptionEnabled &&
                    !automatedVaultReEncryptionPolicy.isEnabled
                )
              )
            }}
            authority={openIdUrl}
            clientId={clientId}
            isOktaWithAdc={useOktaAuthServerToStoreCompanyKey}
            provider={provider}
            enabled={isEnabled}
            isEmailHintDisabled={isEmailHintDisabled}
            useAzureMdmFlow={useAzureMdmFlow}
            pkceEnabled={pkceEnabled}
          />
        )}
        {isSuperAdmin &&
        isEnabled &&
        isAzureAd &&
        isAutomatedVaultReEncryptionEnabled ? (
          <KcRotationAndVaultReEncryptionBlock
            onClickKcRotation={() => {
              dispatch(
                federatedLoginActions.startedKeyRotation({
                  provider: OpenIdProvider.AzureAD,
                  isCompanyWideK1: false
                })
              )
            }}
            onClickReEncryption={() => {
              dispatch(
                federatedLoginActions.startVaultReEncryption({
                  ...automatedVaultReEncryptionPolicy
                })
              )
            }}
            providerName={openIdProviderNameMapping[OpenIdProvider.AzureAD]}
            isAutomatedVaultReEncryptionPolicyEnabled={
              automatedVaultReEncryptionPolicy.isEnabled
            }
          />
        ) : (
          isSuperAdmin &&
          isEnabled &&
          isAzureAd && (
            <KeyRotationBlock
              onClick={() => {
                dispatch(
                  federatedLoginActions.startedKeyRotation({
                    provider: OpenIdProvider.AzureAD,
                    isCompanyWideK1: false
                  })
                )
              }}
            />
          )
        )}
      </StyledCardContainer>
    </LogoCard>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { RecoveryKeyDecryptionData } from '@lastpass/admin-console-dependencies/types/recovery-keys/user-recovery-keys'

export const SUCCESS = 'success'

const success = (recoveryKeyDecryptionData: RecoveryKeyDecryptionData) =>
  createResponse(SUCCESS, recoveryKeyDecryptionData)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('users/recovery-keys', 'GET')

    if (status === StatusCodes.OK && response) {
      const recoveryKeys = JSON.parse(response).content
      return success(recoveryKeys)
    }
    throw new UnknownServiceError()
  }
}

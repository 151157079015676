import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Plural, Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as CreditCardIcon } from '@lastpass/assets/svg/admin-console/icon-credit-cards.svg'
import {
  BodyRegular,
  BodyRegularStyle,
  BodySemiboldStyle,
  CaptionRegularStyle,
  Checkbox,
  Heading100,
  Heading300,
  PrimaryButton
} from '@lastpass/lastkit'

import { MspPaymentReminderRepository } from '@lastpass/admin-console-dependencies/repositories'
import {
  formatCurrencySymbol,
  formatPrice
} from '@lastpass/admin-console-dependencies/services/format-price'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { MspPaymentInfoDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface MspPaymentInfoDialogProps {
  state: MspPaymentInfoDialog
  actions: typeof globalActions
}

const transparentBackgroundColor = props => `${props.theme.colors.neutral900}e0`
const Background = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: ${transparentBackgroundColor};
  justify-content: center;
`

const Popup = styled.div`
  margin: 80px auto;
  text-align: center;
  width: 520px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(29, 48, 73, 0.16),
    0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08);
  border-radius: 16px;
  overflow-x: auto;
`
const IconContainer = styled.div`
  background-color: ${props => props.theme.colors.teal100};
  margin-bottom: 24px;
  padding: 10px 0;
  width: 100%;
  border-radius: 16px 16px 0 0;
`

const DialogContainer = styled.div`
  padding: 0 50px 32px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 16px;
`

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-top: 32px;
`

const ListPriceContainer = styled(Heading300)`
  color: ${props => props.theme.colors.blue700};
  font-weight: ${props => props.theme.fonts.weight.bold};
  margin-top: 32px;
`

const ListPriceTextContainer = styled.div`
  ${BodyRegularStyle};
  color: ${props => props.theme.colors.neutral700};
  margin-bottom: 8px;
`

const MfaAddonPriceContainer = styled.div`
  ${CaptionRegularStyle};
  color: ${props => props.theme.colors.neutral700};
  margin-bottom: 32px;
`

const CheckBoxContainer = styled.div`
  margin-bottom: 32px;
`

const StyledSemiBold = styled.div`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.blue700};
  cursor: pointer;
  margin-top: 16px;
`

const StyledContactLink = styled.a`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.blue700};
  cursor: pointer;
`
const contactEmailAddress = 'msp_program@lastpass.com'

export const MspPaymentInfoDialogComponent: FC<MspPaymentInfoDialogProps> = ({
  state: { mspBillingDetails },
  actions
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const companyName = useSelector(
    (state: AppState) => state.companyInfo.details.name
  )

  const contactEmailSubject = i18n._(
    msg`LastPass MSP Request for: ${companyName}`
  )

  const isExpired =
    mspBillingDetails.trialRemainingDays === 0 ||
    mspBillingDetails.isTrialExpired
  const currencySymbol = formatCurrencySymbol(mspBillingDetails.currency)

  const dismissDialog = () => {
    actions.discardDialog()
  }

  const confirmDialog = () => {
    actions.confirmMspPaymentInfoDialog()
  }

  const remindMeLater = () => {
    actions.setMspPaymentReminder()
    MspPaymentReminderRepository.set(
      moment()
        .add(2, 'days')
        .toDate()
    )

    dismissDialog()
  }

  const contactPartnerTeam = () => {
    actions.contactMspPaymentPartner()
    window.open(
      `mailto:${contactEmailAddress}?subject=${contactEmailSubject}`,
      '_blank'
    )
  }

  return (
    <Background>
      <Popup onClick={e => e.stopPropagation()} data-qa="MspPaymentDialog">
        <IconContainer>
          <CreditCardIcon />
        </IconContainer>
        <DialogContainer>
          <StyledHeading100>
            {!isExpired ? (
              <Trans>Enter payment details</Trans>
            ) : (
              <Trans>
                Enter payment details to continue using LastPass MSP
              </Trans>
            )}
          </StyledHeading100>
          <BodyRegular>
            {/* eslint-disable @lastpass/rules/i18n */}
            <Plural
              value={mspBillingDetails.trialRemainingDays}
              _0={
                <span data-qa="CardDaysLeftLabel">
                  Enter your payment details to finish registration.
                </span>
              }
              one={
                <span data-qa="CardDaysLeftLabel">
                  You have 1 day left to try LastPass MSP. Enter your payment
                  details to finish registration.
                </span>
              }
              other={
                <span data-qa="CardDaysLeftLabel">
                  You have # days left to try LastPass MSP. Enter your payment
                  details to finish registration.
                </span>
              }
            />
          </BodyRegular>
          <ListPriceContainer>
            <span data-qa="PaymentInfoListPriceLabel">{`${currencySymbol}${formatPrice(
              mspBillingDetails.listPrice
            )}`}</span>
          </ListPriceContainer>
          <ListPriceTextContainer>
            <Trans>/month /user</Trans>
          </ListPriceTextContainer>
          <MfaAddonPriceContainer>
            <Trans>Advanced MFA add-on</Trans>:{' '}
            <span data-qa="PaymentInfoMfaAddonPriceLabel">{`${currencySymbol}${formatPrice(
              mspBillingDetails.mfaAddonPrice
            )}`}</span>{' '}
            <Trans>/month /user</Trans>
          </MfaAddonPriceContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
              data-qa="AgreeTermsCheckbox"
            >
              <Trans>
                By checking this box I agree to{' '}
                <a
                  href="https://www.lastpass.com/legal-center/msp-program-addendum"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-qa="MspOverviewLink"
                >
                  {`LastPass's Managed Services Provider Program Overview`}
                </a>{' '}
                and{' '}
                <a
                  href="https://www.lastpass.com/legal-center/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-qa="PrivacyPolicyLink"
                >
                  Privacy Policy
                </a>
              </Trans>
            </Checkbox>
          </CheckBoxContainer>
          <PrimaryButton
            onClick={confirmDialog}
            key="confirm"
            disabled={!isChecked}
            data-qa="EnterPaymentInformationSaveButton"
          >
            <Trans>Enter payment details</Trans>
          </PrimaryButton>
          {!isExpired && (
            <StyledSemiBold
              onClick={remindMeLater}
              data-qa="RemindMeLaterButton"
            >
              <Trans>Remind me later</Trans>
            </StyledSemiBold>
          )}
          <StyledParagraph>
            <Trans>
              Looking for the personal touch?{' '}
              <StyledContactLink
                onClick={contactPartnerTeam}
                data-qa="ContactPartnerTeamLink"
              >
                Contact LastPass Partner Team
              </StyledContactLink>
            </Trans>
          </StyledParagraph>
        </DialogContainer>
      </Popup>
    </Background>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { Drawer } from '@lastpass/lastkit'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { MultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import {
  AppliesToSelectionMapping,
  MultifactorMethodNameMapping,
  MultifactorTypeAuthMethodMapping
} from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

import { MultifatorDrawerPage } from './drawer/MultifactorDrawerPage'

export interface MultifactorDrawerProps {
  closeLink: string
  initialStep: string
}

const getTitle = (step, methodName) => {
  switch (step) {
    case MultifactorDrawerSteps.description:
      return <Trans>Setting up multifactor</Trans>
    case MultifactorDrawerSteps.selectMfaMethod:
      return <Trans>Require any of these methods</Trans>
    case MultifactorDrawerSteps.configureMfa:
      return <Trans>Configure {methodName}</Trans>
    case MultifactorDrawerSteps.assign:
      return <Trans>Assign users {'&'} groups</Trans>
    case MultifactorDrawerSteps.assignNewEntities:
      return <Trans>Assign users {'&'} groups</Trans>
    case MultifactorDrawerSteps.allSet:
      return <Trans>All set!</Trans>
  }
}

export const MultifactorDrawer: React.FunctionComponent<MultifactorDrawerProps> = ({
  closeLink,
  initialStep
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(multifactorDrawerActions.discard())
    }
  }, [dispatch])

  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )

  const actualStep = multifactorDrawerState.actualStep
    ? multifactorDrawerState.actualStep
    : initialStep
  const selectedMfa = multifactorDrawerState.selectedMultifactorOption
    ? multifactorDrawerState.selectedMultifactorOption
    : MultifactorOptionType.TotpApp
  const mainTitle = getTitle(
    actualStep,
    MultifactorMethodNameMapping[selectedMfa]
  )

  const configureMfaDrawerClosed = () => {
    switch (actualStep) {
      case MultifactorDrawerSteps.description:
        dispatch(
          multifactorDrawerActions.multifactorSetupInstructionsClicked(
            'Discard'
          )
        )
        return
      case MultifactorDrawerSteps.selectMfaMethod:
        dispatch(
          multifactorDrawerActions.multifactorSelectMfaMethodClicked(
            MultifactorTypeAuthMethodMapping[selectedMfa],
            'Discard'
          )
        )
        return
      case MultifactorDrawerSteps.configureMfa:
        dispatch(
          multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
            MultifactorTypeAuthMethodMapping[selectedMfa],
            'Discard'
          )
        )
        return
      case MultifactorDrawerSteps.assign:
      case MultifactorDrawerSteps.assignNewEntities:
        dispatch(
          multifactorDrawerActions.multifactorAssignUsersClicked(
            AppliesToSelectionMapping[
              multifactorDrawerState.assignFormState.appliesTo
            ],
            'Discard'
          )
        )
        return
      case MultifactorDrawerSteps.allSet:
        dispatch(
          multifactorDrawerActions.multifactorSetupConfirmationClicked(
            'Discard'
          )
        )
        return
    }
  }

  return (
    <Drawer
      mainTitle={mainTitle}
      miscTitle={<></>}
      closeLink={closeLink}
      onClose={configureMfaDrawerClosed}
    >
      {multifactorDrawerState.isLoading && (
        <Loading color="blue900" active={true} />
      )}
      <MultifatorDrawerPage step={actualStep} closeLink={closeLink} />
    </Drawer>
  )
}

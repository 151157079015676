import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { getAdfsIsEnabled } from '@lastpass/admin-console-dependencies/sagas/users/view/users-operations'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getApplicableUserOperations } from '@lastpass/admin-console-dependencies/services/user-operations/get-applicable-user-operations'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CompanySettings } from '@lastpass/admin-console-dependencies/types/company-settings'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'
import { MultifactorProvider } from '@lastpass/admin-console-dependencies/types/multifactor-provider'
import { UserProfile } from '@lastpass/admin-console-dependencies/types/user'

export const getSelectedUsers = (state: AppState) =>
  state.usersList.table.checkedRecords
export const getCompanyDetails = (state: AppState) => state.companyInfo.details
export const getCompanySettings = (state: AppState) =>
  state.companyInfo.settings
export const getPermissions = (state: AppState) => state.global.permissions
export const getCurrentAdminLevel = (state: AppState) =>
  state.usersList.currentAdminLevel
export const getCurrentUserInfo = (state: AppState) => state.global.currentUser
export const getUserProfileInfo = (state: AppState) => state.usersDrawer.profile

export function createUsersSelectionSaga() {
  return function* UsersSelectionSaga() {
    try {
      const selectedUsers = yield select(getSelectedUsers)
      const companyDetails = yield select(getCompanyDetails)
      const companySettings: CompanySettings = yield select(getCompanySettings)
      const permissions: AdminPermissions[] = yield select(getPermissions)
      const currentAdminLevel: AdminLevel = yield select(getCurrentAdminLevel)
      const currentUserInfo: CurrentUser = yield select(getCurrentUserInfo)
      const userProfileInfo: UserProfile = yield select(getUserProfileInfo)
      const isAdfsEnabled: boolean = yield select(getAdfsIsEnabled)

      const statusArray: [
        AccountStatus,
        MFAStatus,
        MultifactorProvider,
        boolean,
        FederatedStatus,
        boolean
      ][] = selectedUsers.map(user => {
        return [
          user.accountStatus,
          user.mfaStatus,
          user.defaultMultiFactorType,
          user.superAdminKeyOwnershipForUser?.hasActingAdmin || false,
          user.provisionFederatedStatus,
          user.adminLevel ? user.adminLevel.id === AdminLevel.superAdmin : false
        ]
      })

      const userOperations = yield call(
        getApplicableUserOperations,
        statusArray,
        companyDetails,
        companySettings.isNonDisabledUserRemovalAllowed,
        permissions,
        currentAdminLevel,
        selectedUsers,
        currentUserInfo,
        userProfileInfo,
        isAdfsEnabled
      )
      yield put(userListActions.setUserOperations(userOperations))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement } from 'react'

import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'
import moment from 'moment-timezone'

import { WithTooltip } from '@lastpass/lastkit'

const CellContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`

export interface TimeSinceActivityProps {
  lastActivityTime: string | Date | undefined | null
}

export const TimeSinceActivity = (props: TimeSinceActivityProps) => {
  if (!props.lastActivityTime) {
    return <Trans>Never</Trans>
  }
  const lastActivityUnixTime = moment.utc(props.lastActivityTime)
  const now = moment.utc()
  const duration = moment.duration(now.diff(lastActivityUnixTime))
  const SecondsSinceActivity = duration.asSeconds()
  const tooltip = lastActivityUnixTime.local().format('L LT') // Date (in local format)
  let shortText: ReactElement

  if (SecondsSinceActivity < 60) {
    shortText = <Trans>Less than a minute ago</Trans>
  } else if (SecondsSinceActivity < 60 * 60) {
    shortText = (
      <Plural
        value={Math.round(SecondsSinceActivity / 60)}
        one="# minute ago"
        other="# minutes ago"
      />
    )
  } else if (SecondsSinceActivity < 60 * 60 * 24) {
    shortText = (
      <Plural
        value={Math.floor(SecondsSinceActivity / (60 * 60))}
        one="# hour ago"
        other="# hours ago"
      />
    )
  } else if (SecondsSinceActivity < 60 * 60 * 24 * 31) {
    shortText = (
      <Plural
        value={Math.floor(SecondsSinceActivity / (60 * 60 * 24))}
        one="# day ago"
        other="# days ago"
      />
    )
  } else if (SecondsSinceActivity < 60 * 60 * 24 * 365) {
    shortText = (
      <Plural
        value={Math.floor(SecondsSinceActivity / (60 * 60 * 24 * 31))}
        one="# month ago"
        other="# months ago"
      />
    )
  } else {
    shortText = (
      <Plural
        value={Math.floor(SecondsSinceActivity / (60 * 60 * 24 * 365))}
        one="# year ago"
        other="# years ago"
      />
    )
  }

  return (
    <WithTooltip tooltip={tooltip} placement="bottom">
      <CellContent>{shortText}</CellContent>
    </WithTooltip>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'
import { PasswordManagerTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

export const SUCCESS = 'success'
export const FAIL = 'fail'

// Fail response is needed, because we are using a single endpoint for bulk actions
const success = () => createResponse(SUCCESS)
const fail = () => createResponse(FAIL)

export const Responses = {
  success,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    passwordManager: PasswordManagerTrustedDevice,
    userId: string
  ) {
    const [response, status] = await fetch(
      `users/${userId}/trusted-devices/lastpass-applications/${passwordManager.id}`,
      'PUT',
      { label: passwordManager.label, isEnabled: passwordManager.isEnabled },
      { 'content-type': 'application/json' }
    )
    if (status === StatusCodes.OK && response) {
      return success()
    } else {
      return fail()
    }
  }
}

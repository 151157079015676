import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegular,
  ButtonsContainer,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { MultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { BodyText } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'
import { MultifactorTypeAuthMethodMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

export interface DuoSecurityConfigProps {
  closeLink: string
  isLastStep: boolean
}

const Link = styled.a`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export const DuoSecurityConfig: React.FunctionComponent<DuoSecurityConfigProps> = ({
  closeLink,
  isLastStep
}) => {
  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(multifactorDrawerActions.getDuoConfig())
  }, [dispatch])

  return (
    <BodyRegular>
      <BodyText>
        <Trans>
          To integrate Duo with LastPass, fill out the form with the information
          provided to you by Duo.
        </Trans>{' '}
        <Link
          data-qa="DuoSecurityLearnMoreLink"
          href="https://support.logmeininc.com/lastpass/help/how-do-i-integrate-duo-security-with-my-lastpass-enterprise-account"
          target="_blank"
        >
          <Trans>Learn more</Trans>
        </Link>
      </BodyText>
      <Formik
        onSubmit={values => {
          if (isLastStep) {
            dispatch(
              multifactorDrawerActions.saveDuoSecuritySettings(
                null,
                values,
                closeLink
              )
            )
          } else {
            dispatch(multifactorDrawerActions.setDuoConfig(values))
            dispatch(multifactorDrawerActions.setIsConfigured(true))
            dispatch(
              multifactorDrawerActions.nextStep(MultifactorDrawerSteps.assign)
            )
          }
        }}
        initialValues={multifactorDrawerState.duoSecurityConfig}
        enableReinitialize
      >
        {formikProps => (
          <Form>
            <InputContainer>
              <Field name="integration_key">
                {formData => (
                  <TextInput
                    data-qa="IntegrationKey"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>Integration key</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="secret_key">
                {formData => (
                  <TextInput
                    data-qa="SecretKey"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                    isPassword={true}
                  >
                    <Trans>Secret key</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="api_hostname">
                {formData => (
                  <TextInput
                    data-qa="ApiHostName"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>API hostname</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <BodyRegular>
              <BodyText>
                <Trans>
                  <Link
                    data-qa="DuoSecurityExternalLink"
                    href="https://duo.com/partners/technology-partners/ready-partners#lastpass?lpcid=53235848"
                    target="_blank"
                  >
                    Get a Duo Security account{' '}
                  </Link>{' '}
                  if you don’t have one yet. (Be sure to choose integration type
                  of LastPass)
                </Trans>
              </BodyText>
            </BodyRegular>
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <LocationLink to={closeLink}>
                  <TextButton
                    type="button"
                    css={css`
                      margin-right: 16px;
                    `}
                    key="Discard"
                    data-qa="CancelButton"
                    onClick={() =>
                      dispatch(
                        multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                          MultifactorTypeAuthMethodMapping[
                            MultifactorOptionType.DuoSecurity
                          ],
                          'Discard'
                        )
                      )
                    }
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  id="ContinueButton"
                  key="Continue"
                  type="submit"
                  disabled={!formikProps.dirty}
                  data-qa="ContinueButton"
                  onClick={() =>
                    dispatch(
                      multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                        MultifactorTypeAuthMethodMapping[
                          MultifactorOptionType.DuoSecurity
                        ],
                        'Continue'
                      )
                    )
                  }
                >
                  {isLastStep ? (
                    <Trans>Save {'&'} exit</Trans>
                  ) : (
                    <Trans>Continue</Trans>
                  )}
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </BodyRegular>
  )
}

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SamlResponse } from '@lastpass/admin-console-dependencies/types/saml-response'

import { samlResponsesMock } from './get-saml-responses.mock'

export const SUCCESS = 'success'

const success = (payload: {
  samlResponses: SamlResponse[]
  totalResults: number
}) => createResponse(SUCCESS, payload)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(search, from, skip) {
    console.log('from', from)
    console.log('teamsapi', teamsapi)
    try {
      const mockLimit = 25
      let data = [...samlResponsesMock.results]

      if (search) {
        data = [
          ...data.filter(response => response.AppName.includes(search)),
          ...data.filter(response => response.Username.includes(search)),
          ...data.filter(response => response.IPAddress.includes(search))
        ]
      }

      const samlResponses = data.slice(skip, skip + mockLimit)
      return success({
        samlResponses: samlResponses,
        totalResults: samlResponsesMock.results.length
      })
    } catch {
      throw new UnknownServiceError()
    }
  }
}

import { call, put } from 'redux-saga/effects'

import { bulkRotateKeys } from '@lastpass/federation'
import { FederationErrors } from '@lastpass/federation/lib/federation-error-codes'
import {
  BulkRotatedKeysResults,
  CompanyWideIdpData
} from '@lastpass/federation/types/key-rotation'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { KeyRotationDataResponse } from '@lastpass/admin-console-dependencies/server/users/federated-login/get-key-rotation-data'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationStatusDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { KeyRotationError } from './key-rotation-error'
import { KeyRotationErrors } from './key-rotation-error-codes'

export function* keyRotationCreateNewKeysCompanyWide(
  userService: UACServices.Services,
  companyWideK1Keys: CompanyWideIdpData,
  providerName: string
) {
  const keyRotationStatusDialog: KeyRotationStatusDialog = {
    type: 'key-rotation-status-dialog',
    providerName: providerName
  }

  const pageLimit = 200
  let pageNumber = 0
  let maxPageNumber = 0
  let successfulRotationNum = 0

  yield put(globalActions.setDialog(keyRotationStatusDialog))

  const alpTokenResponse: { body: string } = yield call(
    userService.getKeyRotationToken
  )

  const sessionToken = alpTokenResponse.body

  do {
    const offset = pageNumber * pageLimit

    const keyRotationDataResponse: {
      body: KeyRotationDataResponse
    } = yield call(
      userService.getKeyRotationData,
      sessionToken,
      offset,
      pageLimit
    )

    const keyRotationData = keyRotationDataResponse.body

    if (!maxPageNumber) {
      maxPageNumber = Math.ceil(keyRotationData.totalCount / pageLimit)
    }

    const rotatedKeyResults: BulkRotatedKeysResults = yield call(
      bulkRotateKeys,
      {
        alpData: keyRotationData.data,
        companyWideIdpData: companyWideK1Keys,
        userWideIdpData: undefined
      }
    )
    if (
      rotatedKeyResults.errors.some(
        error => error.error === FederationErrors.KeyRotationMpMissmatchError
      )
    ) {
      throw new KeyRotationError({
        feErrorCode: KeyRotationErrors.KeyRotationMpMissmatchError,
        sessionToken
      })
    }

    if (rotatedKeyResults.rotatedKeys.alpData.length > 0) {
      successfulRotationNum += rotatedKeyResults.rotatedKeys.alpData.length
      yield call(
        userService.saveKeyRotationData,
        sessionToken,
        rotatedKeyResults.rotatedKeys.alpData
      )
    }

    pageNumber++
  } while (pageNumber < maxPageNumber)

  if (successfulRotationNum > 0) {
    yield call(
      userService.syncKeyRotationData,
      sessionToken,
      KeyRotationErrors.AlpSyncKeyRotationDataRequestFailed
    )
  } else {
    throw new KeyRotationError({
      feErrorCode: KeyRotationErrors.AllFragmentIdValidationFailed,
      sessionToken
    })
  }
}

import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionAreaType } from '@lastpass/admin-console-dependencies/types/permissions/permission-area-type'
import { PermissionCategory } from '@lastpass/admin-console-dependencies/types/permissions/permission-category'
import { PermissionCategoryType } from '@lastpass/admin-console-dependencies/types/permissions/permission-category-type'
import { PermissionOptionType } from '@lastpass/admin-console-dependencies/types/permissions/permission-option-type'
import { PermissionAreaTypeMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PermissionAreaTypeMapping'
import { PermissionCategoryTypeMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PermissionCategoryTypeMapping'

export const permissionCategoriesMapper = permissionCategories =>
  permissionCategories.map(permissionCategory => ({
    permissionCategoryTitle:
      PermissionCategoryTypeMapping[
        PermissionCategoryType[permissionCategory.permissionCategoryType]
      ],
    permissionAreas: permissionCategory.permissionAreas.map(permissionArea => {
      return {
        permissionAreaType: permissionArea.permissionAreaType,
        permissionAreaTitle:
          PermissionAreaTypeMapping[
            PermissionAreaType[permissionArea.permissionAreaType]
          ],
        permissionOptions: permissionArea.permissionOptions.map(
          permissionOption => {
            return {
              permission: AdminPermissions[permissionOption.permission],
              permissionOptionType:
                PermissionOptionType[permissionOption.permissionOptionType],
              dependencies: permissionOption.dependencies.map(dependency => {
                return {
                  dependency: AdminPermissions[dependency.dependency]
                }
              }),
              dependants: permissionOption.dependants.map(dependant => {
                return {
                  dependant: AdminPermissions[dependant.dependant]
                }
              })
            }
          }
        )
      }
    })
  }))

export const getCompanyDetails = (state: AppState) => state.companyInfo.details

export function createGetAdminPermissionsSaga(
  userService: UACServices.Services
) {
  return function* getAdminPermissionsSaga(
    action: ReturnType<typeof adminLevelDrawerActions.getAdminPermissions>
  ) {
    try {
      const companyDetails: CompanyDetails = yield select(getCompanyDetails)
      const shouldFilterRoleBasedPermissions =
        action.payload.isRoleBasedAccessControlEnabled &&
        CompanyDetailsHelper.isMsp(companyDetails)

      const result: UACServices.AdminPermissionCategoriesAPI.Responses = yield call(
        userService.adminPermissionCategories,
        shouldFilterRoleBasedPermissions
      )
      if (result.type === UACServices.AdminPermissionCategoriesAPI.SUCCESS) {
        const permissionCategories: PermissionCategory[] = result.body

        yield put(
          adminLevelDrawerActions.setAdminPermissions(
            permissionCategoriesMapper(permissionCategories)
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { mockData } from '@lastpass/admin-console-dependencies/server/reports/advanced-user-activity/advanced-user-activity-events.mock'
import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  AdvancedUserActivityEvent,
  TeamsApiAdvancedUserEventType
} from '@lastpass/admin-console-dependencies/types/advanced-user-activity'

export const SUCCESS = 'success'

const success = (payload: {
  events: AdvancedUserActivityEvent[]
  originalEvents: TeamsApiAdvancedUserEventType[]
  lastdatetime: number
  totalResults: number
}) => createResponse(SUCCESS, payload)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create() {
  return async function service(query) {
    let data: TeamsApiAdvancedUserEventType[] = []
    let filteredData: TeamsApiAdvancedUserEventType[] = []

    if (query.filter) {
      const { userEvents, adminEvents } = query.filter
      if (userEvents) {
        filteredData = [
          ...filteredData,
          ...mockData.filter(data => userEvents.includes(data.eventType))
        ]
      }
      if (adminEvents) {
        filteredData = [
          ...filteredData,
          ...mockData.filter(data => adminEvents.includes(data.eventType))
        ]
      }
    }
    data = filteredData.length ? filteredData : mockData

    if (query.search) {
      data = [
        ...data.filter(event => event.eventType.includes(query.search)),
        ...data.filter(event => event.eventInformation.includes(query.search)),
        ...data.filter(event => event.user.includes(query.search))
      ]
    }

    try {
      const [column, order] = query.orderBy.split(' ')

      order === 'desc'
        ? data.sort((a, b) => b[column].localeCompare(a[column]))
        : data.sort((a, b) => a[column].localeCompare(b[column]))

      const mockLimit = 25
      const originalEvents = data.slice(query.skip, query.skip + mockLimit)

      const events = originalEvents.map(event => ({
        id: event.id,
        eventType: event.eventType,
        eventInformation: event.eventInformation,
        user: event.user,
        timeUtc: event.timeUtc
      }))

      return success({
        originalEvents,
        events,
        lastdatetime: Date.now(),
        totalResults: mockData.length
      })
    } catch {
      throw new UnknownServiceError()
    }
  }
}

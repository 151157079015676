import { msg } from '@lingui/macro'

import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export const pendingExportNotification: NotificationProps = {
  message: msg`Export already in progress. Thanks for your patience.`,
  type: NotificationType.warning,
  autoDismiss: true
}

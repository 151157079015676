import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { DetailsDialog } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { createUserActivityChartComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/UserActivityHistoryChart'

export const ActiveUsageRateDialogComponent: FunctionComponent = () => {
  const dispatch = useDispatch()
  const UserActivityChart = createUserActivityChartComponent<AppState>()
  return (
    <DetailsDialog
      closeDialog={() => dispatch(globalActions.emptyDialog())}
      title={msg`Active usage rate`}
      content={<UserActivityChart />}
      width="780px"
    />
  )
}

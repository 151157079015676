import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GroupFilter } from '@lastpass/admin-console-dependencies/types/group-filter'

export const SUCCESS = 'success'

const success = (groupsList: GroupFilter[], totalResults: number) =>
  createResponse(SUCCESS, { groupsList, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(queryPath: string) {
    const [response, status] = await fetch('groups' + queryPath, 'GET')
    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const groupsList = responseData.content.items.map(group => ({
        label: group.name,
        value: group.id
      }))
      const totalResults = responseData.content.totalCount
      return success(groupsList, totalResults)
    }
    throw new UnknownServiceError()
  }
}

export function isTimeRangeValid(policyValue: string | undefined): boolean {
  if (policyValue) {
    const regexp = /^(\d{2})(\d{2})-(\d{2})(\d{2}),\s*UTC([+|-]\d{1,2})?\s*$/
    const match = regexp.exec(policyValue)

    return match ? true : false
  }

  return false
}

export function isTimeRangePolicy(policyKey: string): boolean {
  return policyKey === 'restrictmfabytimerange'
}

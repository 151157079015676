import { StatusCodes } from 'http-status-codes'

import { IdpUserData } from '../../../types/federation-api'
import { createFragmentId } from '../../common/create-fragment-id'
import { FederationErrors } from '../../federation-error-codes'
import { FederationApiError } from '../federation-api-error'
import {
  FederationFetch,
  FederationFetchResponse
} from '../federation-api-factory'
import {
  AzureExtensionData,
  AzureUserData,
  AzureUserMappedDataWithK1
} from './types'

const getLastPassExtension = (
  extensionsData: AzureExtensionData[] = []
): AzureExtensionData | null => {
  const lastPassExtension: AzureExtensionData | undefined = extensionsData.find(
    extensionData => extensionData.id === 'com.lastpass.keys'
  )
  return lastPassExtension || null
}

export interface AzureAdGetUsersResponse {
  value: AzureUserData[]
}

export const getUsers = (
  federationFetch: FederationFetch<AzureAdGetUsersResponse>
) => async (): Promise<IdpUserData[]> => {
  let users: IdpUserData[] = []

  let lastPage = false
  let requestUrl = 'v1.0/users?$top=999&$select=id&$expand=extensions'

  do {
    let apiResponse: FederationFetchResponse<AzureAdGetUsersResponse>
    try {
      apiResponse = await federationFetch(requestUrl, 'GET')
    } catch {
      throw new FederationApiError({
        federationErrorCode: FederationErrors.IdpGetUsersRequestConnectionFailed
      })
    }

    if (apiResponse.status !== StatusCodes.OK || !apiResponse.response) {
      if (apiResponse.status === StatusCodes.FORBIDDEN) {
        throw new FederationApiError({
          federationErrorCode: FederationErrors.IdpGetUsersRequestAccessDenied,
          httpErrorCode: StatusCodes.FORBIDDEN
        })
      }
      throw new FederationApiError({
        federationErrorCode: FederationErrors.IdpGetUsersRequestFailed,
        httpErrorCode: apiResponse.status
      })
    }

    const mappedUsersWithK1: AzureUserMappedDataWithK1[] = apiResponse.response.value.reduce(
      (
        filteredUserData: AzureUserMappedDataWithK1[],
        userData: AzureUserData
      ) => {
        const extensionData = getLastPassExtension(userData.extensions)
        if (extensionData?.LastPassK1) {
          const userDataWithK1: AzureUserMappedDataWithK1 = {
            userId: userData.id,
            k1: extensionData.LastPassK1
          }
          const syncId = parseInt(extensionData?.syncId || '', 10)
          if (extensionData.syncSession && syncId) {
            userDataWithK1.syncSession = extensionData.syncSession
            userDataWithK1.syncId = syncId
          }
          filteredUserData.push(userDataWithK1)
        }
        return filteredUserData
      },
      []
    )

    const mappedUsersWithFragmentId: IdpUserData[] = await Promise.all(
      mappedUsersWithK1.map(async user => {
        const k1Buffer = Buffer.from(user.k1, 'base64')
        return {
          ...user,
          k1: k1Buffer,
          fragmentId: await createFragmentId(k1Buffer)
        }
      })
    )

    users = users.concat(mappedUsersWithFragmentId)

    if (apiResponse.response['@odata.nextLink']) {
      requestUrl = apiResponse.response['@odata.nextLink']
    } else {
      lastPage = true
    }
  } while (!lastPage)

  return users
}

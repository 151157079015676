import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (remainingLicenses: number, totalLicenses: number) =>
  createResponse(SUCCESS, { remainingLicenses, totalLicenses })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('license-details/', 'GET')
    if (status === StatusCodes.OK && response) {
      const responseJson = JSON.parse(response).content
      return success(responseJson.remainingLicenses, responseJson.totalLicenses)
    }

    throw new UnknownServiceError()
  }
}

import { track, uacEvent, UACTracking } from '@lastpass/tracking'

import { createAction } from '@lastpass/admin-console-dependencies/state/action'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'

import { OperationOriginType } from './operation-origin-types'

export enum UserOperationActionTypes {
  REQUIRE_PASSWORD_CHANGE = '[user-operation] require-password-change',
  INVITE_USERS = '[user-operation] invite-users',
  REINVITE_USERS = '[user-operation] reInvite-users',
  UNINVITE_USERS = '[user-operation] unInvite-users',
  RESEND_LP_MFA_INVITE = '[user-operation] resend-lp-mfa-invite',
  LOCK_DEVICE_FOR_LPMFA = '[user-operation] lock-device-for-lpmfa',
  UNLOCK_DEVICE_FOR_LPMFA = '[user-operation] unLock-device-for-lpmfa',
  REMOVE_USERS_FROM_COMPANY = '[user-operation] remove-users-from-company',
  DELETE_USERS = '[user-operation] delete-users',
  DISABLE_USERS = '[user-operation] disable-users',
  ACTIVATE_USERS = '[user-operation] activate-users',
  DISABLE_MULTIFACTOR = '[user-operation] disable-multifactor',
  ACCEPT_USERS = '[user-operation] accept-users',
  REJECT_USERS = '[user-operation] reject-users',
  DESTROY_ALL_SESSIONS = '[user-operation] destroy-all-sessions-for-selected-users',
  DESTROY_ALL_SESSIONS_FOR_ITERATION_COUNT_POLICY = '[user-operation] destroy-all-sessions-for-iteration-count-policy',
  SELECT_FOR_FEDERATION = '[user-operation] select-for-federation',
  DESELECT_FOR_FEDERATION = '[user-operation] deselect-for-federation',
  SELECT_FOR_VAULT_RE_ENCRYPTION = '[user-operation] select-for-vault-re-encryption'
}

export enum UserOperationSegmentActionTypes {
  SEND_OPERATION_CLICKED = '[user-operation-segment] send-button-clicked',
  RESPONSE_RECEIVED = '[user-operation-segment] response-received'
}

const userCountPropName = 'User Count'

interface UserOperationPayload {
  users: User[]
  origin: OperationOriginType
  isAllSelected: boolean
  filter?: UserFilter
}

export const userOperationActions = {
  requirePasswordChange: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.REQUIRE_PASSWORD_CHANGE, {
      userOperationPayload
    }),
  disableMultifactor: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.DISABLE_MULTIFACTOR, {
      userOperationPayload
    }),
  inviteUsersAction: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.INVITE_USERS, {
      userOperationPayload
    }),
  reInviteUsersAction: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.REINVITE_USERS, {
      userOperationPayload
    }),
  unInviteUsersAction: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.UNINVITE_USERS, {
      userOperationPayload
    }),
  reSendLPMFAInvitation: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.RESEND_LP_MFA_INVITE, {
      userOperationPayload
    }),
  lockDeviceForLPMFAAction: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.LOCK_DEVICE_FOR_LPMFA, {
      userOperationPayload
    }),
  unLockDeviceForLPMFAAction: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.UNLOCK_DEVICE_FOR_LPMFA, {
      userOperationPayload
    }),
  removeUsersFromCompany: (userOperationPayload: UserOperationPayload) =>
    track(
      createAction(UserOperationActionTypes.REMOVE_USERS_FROM_COMPANY, {
        userOperationPayload
      }),
      {
        segment: uacEvent('Intent to Remove User from Company Clicked', {
          'Account Status': 'Active',
          Source:
            userOperationPayload.origin === OperationOriginType.Drawer
              ? 'User Detail'
              : 'Users Overview',
          [userCountPropName]: userOperationPayload.users.length
        })
      }
    ),
  deleteUsers: (userOperationPayload: UserOperationPayload) =>
    track(
      createAction(UserOperationActionTypes.DELETE_USERS, {
        userOperationPayload
      }),
      {
        segment: uacEvent('Intent to Delete User Clicked', {
          'Account Status': 'Active',
          Source:
            userOperationPayload.origin === OperationOriginType.Drawer
              ? 'User Detail'
              : 'Users Overview',
          [userCountPropName]: userOperationPayload.users.length
        })
      }
    ),
  disableUsers: (userOperationPayload: UserOperationPayload) =>
    track(
      createAction(UserOperationActionTypes.DISABLE_USERS, {
        userOperationPayload
      }),
      {
        segment: uacEvent('Intent to Disable User Clicked', {
          Source:
            userOperationPayload.origin === OperationOriginType.Drawer
              ? 'User Detail'
              : 'Users Overview',
          [userCountPropName]: userOperationPayload.users.length
        })
      }
    ),
  activateUsers: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.ACTIVATE_USERS, {
      userOperationPayload
    }),
  acceptUsers: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.ACCEPT_USERS, {
      userOperationPayload
    }),
  rejectUsers: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.REJECT_USERS, {
      userOperationPayload
    }),
  destroyAllSessionsForSelectedUsers: (
    userOperationPayload: UserOperationPayload
  ) =>
    createAction(UserOperationActionTypes.DESTROY_ALL_SESSIONS, {
      userOperationPayload
    }),
  destroyAllSessionsForIterationCountPolicy: (
    userOperationPayload: UserOperationPayload = {
      users: [],
      isAllSelected: true,
      origin: OperationOriginType.Drawer
    }
  ) =>
    createAction(
      UserOperationActionTypes.DESTROY_ALL_SESSIONS_FOR_ITERATION_COUNT_POLICY,
      {
        userOperationPayload
      }
    ),
  selectForFederation: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.SELECT_FOR_FEDERATION, {
      userOperationPayload
    }),
  deselectForFederation: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.DESELECT_FOR_FEDERATION, {
      userOperationPayload
    }),
  selectForVaultReEncryption: (userOperationPayload: UserOperationPayload) =>
    createAction(UserOperationActionTypes.SELECT_FOR_VAULT_RE_ENCRYPTION, {
      userOperationPayload
    })
}

export const userOperationSegmentActions = {
  sendButtonClicked: (
    eventName: keyof UACTracking.Plan,
    usersCount: number,
    origin: OperationOriginType
  ) =>
    track(
      createAction(UserOperationSegmentActionTypes.SEND_OPERATION_CLICKED, {
        eventName,
        usersCount,
        origin
      }),
      {
        segment: uacEvent(eventName, {
          Source:
            origin === OperationOriginType.Drawer
              ? 'User Details Drawer'
              : 'Users Overview',
          'User Count': usersCount
        })
      }
    ),

  responseReceived: (
    eventName: keyof UACTracking.Plan,
    usersSucceededCount: number,
    usersFailedCount: number
  ) =>
    track(
      createAction(UserOperationSegmentActionTypes.RESPONSE_RECEIVED, {
        eventName,
        usersSucceededCount,
        usersFailedCount
      }),
      {
        segment: uacEvent(eventName, {
          'Number Failed': usersFailedCount,
          'Number Succeeded': usersSucceededCount
        })
      }
    )
}

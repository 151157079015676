import React from 'react'
import { useLocation } from 'react-router'
import { Link, LinkProps } from 'react-router-dom'

interface LocationLinkProps extends LinkProps {
  target?: string
  discardnamespace?: string
}

export const LocationLink: React.FunctionComponent<LocationLinkProps> = props => {
  const location = useLocation()
  const discardnamespace = props.discardnamespace || ''
  const search = location.search || ''
  const targetBlank = props.target === '_blank'

  const toParts = (props.to as string).split('#', 2)
  let linkTo = toParts[0]
  const hashTo = toParts.length > 1 ? `#${toParts[1]}` : ''

  if (discardnamespace && search) {
    let updatedSearch = '&' + search.substr(1)
    updatedSearch = updatedSearch
      .split('&')
      .filter(item => !item.includes(discardnamespace))
      .join('&')
    updatedSearch = '?' + updatedSearch.substr(1)
    linkTo = linkTo + updatedSearch + hashTo
  } else if (!targetBlank) {
    linkTo = linkTo + search + hashTo
  } else {
    linkTo = linkTo + hashTo
  }

  return targetBlank ? (
    <a {...props} href={linkTo}>
      {props.children}
    </a>
  ) : (
    <Link {...props} to={linkTo}>
      {props.children}
    </Link>
  )
}

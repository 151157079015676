import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as RepairAppsIcon } from '@lastpass/assets/svg/admin-console/applications/repair-apps-illustration.svg'
import { ReactComponent as SuccessIcon } from '@lastpass/assets/svg/admin-console/icon-success.svg'
import { DetailsDialog, Heading100, PrimaryButton } from '@lastpass/lastkit'

import { ManagedMigrationCompanyInfo } from '@lastpass/admin-console-dependencies/state/applications/application'
import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import {
  hasMigratableManagedCopmanies,
  hasNoPasswordSsoApps,
  isDirectMSPOrAggregatorMSP,
  isManagedMSP
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'

import {
  dialogWidth,
  StyledBodyRegular,
  StyledCenter
} from './SystemUpgradeDialog'

const StyledSuccessIcon = styled(SuccessIcon)`
  margin-bottom: 20px;
`

const StyledRepairAppsIcon = styled(RepairAppsIcon)`
  margin-bottom: 20px;
`

const StyledHeading100 = styled(Heading100)`
  width: 100%;
  margin-bottom: 10px;
`

export const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
`

const customDetailsDialog = (
  dataQA: string,
  dialogContent: ReactElement,
  dialogButton: ReactElement
): JSX.Element => {
  const buttonsForDialog: ReactElement[] = [dialogButton]

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={() => {}}
      hideCloseIcon={true}
      width={dialogWidth}
      data-qa={dataQA}
      content={dialogContent}
      buttons={buttonsForDialog}
    />
  )
}

const dialogPrimaryButton = (
  buttonDataQa: string,
  historyPath: string,
  buttonTitle: ReactElement,
  onButtonClick: (arg: string) => void
): JSX.Element => (
  <PrimaryButton
    data-qa={buttonDataQa}
    key={buttonDataQa}
    onClick={() => {
      onButtonClick(historyPath)
    }}
  >
    {buttonTitle}
  </PrimaryButton>
)

const completedDialogsWithSsoApps = (
  onButtonClick: (arg: string) => void
): JSX.Element => {
  const dialogDataQa = 'CompletedDialogWithSsoApps'
  const buttonDataQa = 'RepairSSOAppsButton'
  const historyPath = '/applications/saml'

  const dialogsWithSsoAppsContent: ReactElement = (
    <StyledCenter data-qa="CompletedDialogWithSsoApps">
      <StyledRepairAppsIcon data-qa="RepairSSOAppsIcon" />
      <StyledHeading100 data-qa="HeadingWithSsoApps">
        <Trans>Upgrade complete - Now it&apos;s your turn</Trans>
      </StyledHeading100>
      <StyledBodyRegular data-qa="BodyWithSsoApps">
        <Trans>
          It&apos;s time to repair your SSO apps. You&apos;ll find the
          instructions in each app&apos;s configuration details.
        </Trans>
      </StyledBodyRegular>
    </StyledCenter>
  )

  const dialogsWithSsoAppsButton = dialogPrimaryButton(
    buttonDataQa,
    historyPath,
    <Trans>Go repair SSO apps</Trans>,
    onButtonClick
  )

  return customDetailsDialog(
    dialogDataQa,
    dialogsWithSsoAppsContent,
    dialogsWithSsoAppsButton
  )
}

const completedDialogWithoutSsoApps = (
  onButtonClick: (arg: string) => void
): JSX.Element => {
  const dialogDataQa = 'CompletedDialogWithoutSsoApps'
  const buttonDataQa = 'DoneButton'
  const historyPath = '/dashboard'

  const dialogWithoutSsoAppsContent: ReactElement = (
    <StyledCenter data-qa="CompletedDialogWithoutSsoApps">
      <StyledSuccessIcon data-qa="SuccessIcon" />
      <StyledHeading100 data-qa="HeadingWithoutSsoApps">
        <Trans>System upgrade complete</Trans>
      </StyledHeading100>
      <StyledBodyRegular data-qa="BodyWithoutSsoApps">
        <Trans>
          For a reminder about what the upgrade included, review this{' '}
          <StyledLink
            href="https://link.lastpass.com/system-upgrade-faqs"
            target="_blank"
            data-qa="SystemUpgradeFaqsLink"
          >
            support article
          </StyledLink>
          .
        </Trans>
      </StyledBodyRegular>
    </StyledCenter>
  )

  const dialogsWithoutSsoAppsButton = dialogPrimaryButton(
    buttonDataQa,
    historyPath,
    <Trans>Done</Trans>,
    onButtonClick
  )

  return customDetailsDialog(
    dialogDataQa,
    dialogWithoutSsoAppsContent,
    dialogsWithoutSsoAppsButton
  )
}

const completedDialogWithoutSsoAppsAsMSP = (
  onButtonClick: (arg: string) => void
): JSX.Element => {
  const dialogDataQa = 'CompletedDialogWithoutSsoAppsAsMSP'
  const buttonDataQa = 'GoToManagedCompanies'
  const historyPath = '/home/managed-companies'

  const dialogWithoutSsoAppsContent: ReactElement = (
    <StyledCenter data-qa="CompletedDialogWithoutSsoAppsAsMSP">
      <StyledSuccessIcon data-qa="SuccessIconAsMSP" />
      <StyledHeading100 data-qa="HeadingWithoutSsoAppsAsMSP">
        <Trans>System upgrade complete</Trans>
      </StyledHeading100>
      <StyledBodyRegular data-qa="BodyWithoutSsoAppsAsMSP">
        <Trans>Now let’s take care of your managed companies.</Trans>
      </StyledBodyRegular>
    </StyledCenter>
  )

  const dialogsWithoutSsoAppsButton = dialogPrimaryButton(
    buttonDataQa,
    historyPath,
    <Trans>Go to Managed companies</Trans>,
    onButtonClick
  )

  return customDetailsDialog(
    dialogDataQa,
    dialogWithoutSsoAppsContent,
    dialogsWithoutSsoAppsButton
  )
}

export const SystemUpgradeCompletedDialog: FunctionComponent = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const ssoAppDetails = useSelector<AppState, ApplicationListState>(
    appState => appState.applicationList
  )

  const companyInfo: CompanyInfoState = useSelector<AppState, CompanyInfoState>(
    appState => appState.companyInfo
  )

  const isMsp: boolean =
    isDirectMSPOrAggregatorMSP(companyInfo) || isManagedMSP(companyInfo)

  const hasNopasswordSsoApp: boolean = hasNoPasswordSsoApps(ssoAppDetails)

  useEffect(() => {
    if (isMsp) {
      dispatch(systemUpgradeActions.fetchMigratableManagedCompanies())
    }
  }, [dispatch, isMsp])

  const migratableManagedCompaniesState: ManagedMigrationCompanyInfo[] = useSelector<
    AppState,
    ManagedMigrationCompanyInfo[]
  >(appState => appState.systemUpgrade.migratableManagedCompanies)

  const hasManagedCompanies: boolean = hasMigratableManagedCopmanies(
    migratableManagedCompaniesState
  )

  const onButtonClick = (historyPath: string): void => {
    dispatch(globalActions.emptyDialog())
    history.push(historyPath)
    dispatch(systemUpgradeActions.fetchIsMigrated())
  }

  if (hasNopasswordSsoApp) return completedDialogsWithSsoApps(onButtonClick)
  else if (!hasNopasswordSsoApp && !hasManagedCompanies)
    return completedDialogWithoutSsoApps(onButtonClick)
  else if (isMsp && !hasNopasswordSsoApp && hasManagedCompanies)
    return completedDialogWithoutSsoAppsAsMSP(onButtonClick)
  else return null
}

import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { DuoSecuritySettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/state'

export const SUCCESS = 'success'

export const success = (duoSecuritySettings: DuoSecuritySettingsState) =>
  createResponse(SUCCESS, { duoSecuritySettings })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await teamsapi(
        'my-company/showduosecuritysettings',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const duoSecuritySettingsResponse = JSON.parse(response)
        const duoSecuritySettings: DuoSecuritySettingsState = {
          integrationKey: duoSecuritySettingsResponse
            ? duoSecuritySettingsResponse['integration_key']
            : '',
          secretKey: duoSecuritySettingsResponse
            ? duoSecuritySettingsResponse['secret_key']
            : '',
          apiHostName: duoSecuritySettingsResponse
            ? duoSecuritySettingsResponse['api_hostname']
            : ''
        }
        return success(duoSecuritySettings)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { SearchInput } from '@lastpass/lastkit'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import {
  AvailabilityStatus,
  PsaCompanyContractAssignment
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { MultipleCompaniesAssignmentTabs } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/multiple-companies/MultipleCompaniesAssignmentTabs'
import { createAssignmentChangesList } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/helpers/create-assignment-changes-list'
import { createAssignmentMap } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/helpers/create-assignment-map'
import { useCategorizedAssignmentOptions } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/hooks/use-categorized-assignment-options'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'
import { PsaDrawerFooter } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/drawer/PsaDrawerFooter'
import { TableDataLoader } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/TableDataLoader'

const StyledSearchInput = styled(SearchInput)`
  margin: 0 0 24px 0;
`
export const MultipleCompaniesAgreementDrawerContent: FunctionComponent = () => {
  const { assignmentOptions, isLoading } = useSelector(
    (state: AppState) => state.psaIntegrations.agreements
  )
  const dispatch = useDispatch()
  const [
    autoSelectedList,
    needsSelectionList,
    previouslySelectedList
  ] = useCategorizedAssignmentOptions(assignmentOptions)
  const [searchText, setSearchText] = useState('')
  const [initialAssignmentsMap, setInitialAssignmentsMap] = useState<
    AssignmentsMapping
  >({})
  const [actualAssignmentsMap, setActualAssignmentsMap] = useState<
    AssignmentsMapping
  >({})
  const [assignmentChanges, setAssignmentChanges] = useState<
    PsaCompanyContractAssignment[]
  >([])

  useEffect(() => {
    dispatch(psaIntegrationsActions.getPsaAgreements(null))
  }, [dispatch])

  const handleReload = () => {
    dispatch(psaIntegrationsActions.getPsaAgreements(null))
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value.toLowerCase())

  const handleClearSearch = () => setSearchText('')

  useEffect(() => {
    const initialAssignmentsMap = createAssignmentMap(
      assignmentOptions,
      ({ contractAssignment }) => contractAssignment.assignedContractId
    )
    const actualAssignmentsMap = createAssignmentMap(
      assignmentOptions,
      ({ contractAssignment }) =>
        contractAssignment.assignedContractId ||
        contractAssignment.suggestedContractId
    )

    setInitialAssignmentsMap(initialAssignmentsMap)
    setActualAssignmentsMap(actualAssignmentsMap)
    setAssignmentChanges(
      createAssignmentChangesList(initialAssignmentsMap, actualAssignmentsMap)
    )
  }, [assignmentOptions])

  const handleAssignmentChange = (
    {
      companyId,
      contractId,
      assignmentStatus,
      contractName
    }: PsaCompanyContractAssignment,
    availabilityStatus: AvailabilityStatus
  ) => {
    const newAssignmentMap = {
      ...actualAssignmentsMap,
      [companyId]: {
        contractId,
        contractName,
        assignmentStatus,
        availabilityStatus
      }
    }
    const assignmentChanges = createAssignmentChangesList(
      initialAssignmentsMap,
      newAssignmentMap
    )

    setActualAssignmentsMap(newAssignmentMap)
    setAssignmentChanges(assignmentChanges)

    dispatch(
      psaIntegrationsActions.setDrawerContainsUnsavedChanges(
        !!assignmentChanges.length
      )
    )
  }

  const handleSave = () => {
    // show confirm only if initial assignment existed
    const isConfirmRequired = !!assignmentChanges.find(
      ({ contractId, companyId }) =>
        !contractId && initialAssignmentsMap[companyId].contractId
    )

    dispatch(
      psaIntegrationsActions.savePsaAgreements(
        assignmentChanges,
        isConfirmRequired
      )
    )
  }

  return (
    <>
      <StyledSearchInput
        data-qa="SearchInputField"
        onChange={handleSearch}
        value={searchText}
        placeholder={msg`Search companies`}
        disabled={isLoading || assignmentOptions.length === 0}
      />

      <TableDataLoader
        isLoading={isLoading}
        onReload={handleReload}
        noRecordTitle={<Trans>No companies to display</Trans>}
        reloadButtonText={<Trans>Try again</Trans>}
        isDataAvailable={!!assignmentOptions.length}
      >
        <MultipleCompaniesAssignmentTabs
          actualAssignmentsMap={actualAssignmentsMap}
          initialAssignmentsMap={initialAssignmentsMap}
          searchText={searchText}
          assignmentOptions={assignmentOptions}
          autoSelectedList={autoSelectedList}
          needsSelectionList={needsSelectionList}
          previouslySelectedList={previouslySelectedList}
          onAssignmentChange={handleAssignmentChange}
          onClearSearch={handleClearSearch}
        />
      </TableDataLoader>

      <PsaDrawerFooter
        onSave={handleSave}
        isSaveButtonDisabled={isLoading || assignmentChanges.length === 0}
      />
    </>
  )
}

import { addSlashes } from 'slashes'

/**
 * Escapes then URI encodes the given OData search string
 * @param search Any OData search string or undefined
 */
export const encodeSearch = (search: string | undefined) => {
  return search
    ? `"${encodeURIComponent(addSlashes(search, '"\\'))}"`
    : undefined
}

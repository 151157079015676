import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { DuoSecuritySettingsActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/actions'

import { createGetDuoSecuritySettings } from './get-duo-security-settings'
import { createSaveDuoSecuritySettings } from './save-duo-security-settings'

export function createDuoSecuritySettingsSaga(
  duoSecuritySettingsService: UACServices.Services
) {
  const getDuoSecuritySettingsSaga = createGetDuoSecuritySettings(
    duoSecuritySettingsService
  )
  const saveDuoSecuritySettingsSaga = createSaveDuoSecuritySettings(
    duoSecuritySettingsService
  )

  return function*() {
    yield takeEvery(
      DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_GET,
      getDuoSecuritySettingsSaga
    )
    yield takeEvery(
      DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_SAVE,
      saveDuoSecuritySettingsSaga
    )
  }
}

import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import _ from 'lodash'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ArrowDown } from '@lastpass/assets/svg/admin-console/icon-arrow-down.svg'
import { ReactComponent as UsersIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components'
import {
  Checkbox,
  DrawerTable,
  SearchInput,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { initialState } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/reducer'
import {
  PsaIntegrationsState,
  PsaProduct
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { OrderTypes } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/types/order-directions'
import { ProductSelectionDrawerFooter } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/products/components/ProductSelectionDrawerFooter'
import { isLastPassService } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/products/helpers/is-lastpass-service'
import { sortAlphabetically } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/products/helpers/sort-alphabetically'

const StyledTableViewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: inherit;
`
const StyledUsersIcon = styled(UsersIcon)`
  height: 120px;
`
const StyledSearchInput = styled(SearchInput)`
  margin-right: 0;
`
const StyledArrowDown = styled(ArrowDown)`
  color: ${props => props.theme.colors.iconColorDefault};
  width: 12px;
  margin-left: 4px;
`
const StyledArrowUp = styled(StyledArrowDown)`
  transform: rotate(180deg);
`

type GetTableView = ({
  title,
  containerDataQa,
  linkDataQa,
  linkText,
  icon,
  text,
  onClick
}: GetTableViewProps) => ReactElement

export interface ProductSelectionDrawerContentProps {
  previousUrl: string
}

export interface GetTableViewProps {
  title: ReactElement
  containerDataQa: string
  linkDataQa: string
  linkText: ReactElement
  icon?: ReactElement
  text?: ReactElement
  onClick: () => void
}

export const ProductSelectionDrawerContent: FunctionComponent<ProductSelectionDrawerContentProps> = ({
  previousUrl
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { products } = useSelector<AppState, PsaIntegrationsState>(
    state => state.psaIntegrations
  )

  const getTableView: GetTableView = ({
    title,
    containerDataQa,
    linkDataQa,
    linkText,
    icon = <></>,
    text = <></>,
    onClick
  }) => {
    return (
      <StyledTableViewContainer>
        <TableView
          icon={icon}
          title={title}
          text={text}
          qadata={containerDataQa}
          actionButton={
            <TableViewButton data-qa={{ linkDataQa }} onClick={onClick}>
              {linkText}
            </TableViewButton>
          }
        />
      </StyledTableViewContainer>
    )
  }

  const [initiallySelectedServices, setInitiallySelectedServices] = useState<
    string[]
  >([])
  const [selectedServices, setSelectedServices] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [orderBy, setOrderBy] = useState<OrderTypes>(OrderTypes.ASC)

  useEffect(() => {
    dispatch(psaIntegrationsActions.getPsaProducts())
  }, [dispatch])

  useEffect(() => {
    const initiallySelectedServices = products.results
      .filter(product => product.isSelectedForBilling)
      .map(product => product.id)

    setInitiallySelectedServices(initiallySelectedServices)
    setSelectedServices(initiallySelectedServices)
  }, [products])

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const closeDrawer = () => {
    history.push(previousUrl)
  }

  const handleRequestProductList = () => {
    dispatch(psaIntegrationsActions.getPsaProducts())
  }

  const handleCheckboxOnChange = (productId: string) => {
    setSelectedServices(prevState =>
      prevState.includes(productId)
        ? prevState.filter(id => id !== productId)
        : [...prevState, productId]
    )
  }

  const handleOrderChange = (column?: string) => {
    const order = orderBy === OrderTypes.ASC ? OrderTypes.DESC : OrderTypes.ASC
    if (column === 'name') {
      setOrderBy(order)
    }
  }

  const clearSearchQuery = () => {
    setSearchQuery('')
  }

  const handleSave = () => {
    dispatch(psaIntegrationsActions.savePsaProducts(selectedServices))
  }

  const filteredServices: Table<PsaProduct> = useMemo(() => {
    const filteredServices = products.results.filter(service =>
      service.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
    )
    const lastPassServices = filteredServices
      .filter(isLastPassService)
      .sort(sortAlphabetically(orderBy))
    const notLastPassServices = filteredServices
      .filter(service => !isLastPassService(service))
      .sort(sortAlphabetically(orderBy))

    return {
      ...products,
      results: [...lastPassServices, ...notLastPassServices]
    }
  }, [products, searchQuery, orderBy])

  const isSaveDisabled =
    products.isLoading ||
    _.isEqual(initiallySelectedServices.sort(), selectedServices.sort())

  return (
    <>
      <StyledSearchInput
        data-qa="SearchProductsInput"
        onChange={handleSearchOnChange}
        value={searchQuery}
        placeholder={msg`Search products`}
        disabled={products.isLoading || products.results.length === 0}
      />
      <DrawerTable
        table={
          products.isLoading
            ? { ...initialState.products, isLoading: products.isLoading }
            : filteredServices
        }
        qadata="ProductListTable"
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <></>,
            renderer: function CheckboxRenderer(record) {
              return (
                <Checkbox
                  checked={selectedServices.includes(record.id)}
                  onChange={() => handleCheckboxOnChange(record.id)}
                  data-qa={`ProductCheckbox${record.id}`}
                />
              )
            },
            noTooltip: true,
            contentWidth: 'auto'
          },
          {
            name: (
              <>
                <Trans>Name</Trans>
                {orderBy === OrderTypes.ASC ? (
                  <StyledArrowUp data-qa="arrow-up" />
                ) : (
                  <StyledArrowDown data-qa="arrow-down" />
                )}
              </>
            ),
            renderer: (record: PsaProduct) => record.name,
            columnOrderByName: 'name'
          },
          {
            name: <Trans>ID</Trans>,
            renderer: (record: PsaProduct) => record.id.toString(),
            contentWidth: '10%'
          },
          {
            name: <Trans>Category</Trans>,
            renderer: (record: PsaProduct) => record.category,
            contentWidth: '50%'
          }
        ]}
        noRecordsView={getTableView({
          title: <Trans>Nothing to display</Trans>,
          containerDataQa: 'NoRecordsView',
          linkDataQa: 'TryAgainLink',
          linkText: <Trans>Try again</Trans>,
          onClick: handleRequestProductList
        })}
        noResultsView={getTableView({
          icon: <StyledUsersIcon />,
          title: <Trans>Sorry, no results match your search</Trans>,
          text: <Trans>Try different keywords or check your spelling</Trans>,
          containerDataQa: 'NoResultView',
          linkText: <Trans>Clear search query</Trans>,
          linkDataQa: 'ClearSearchLink',
          onClick: clearSearchQuery
        })}
        disableRowHover={true}
        footerNodes={
          <ProductSelectionDrawerFooter
            isSaveDisabled={isSaveDisabled}
            onSave={handleSave}
            onDiscard={closeDrawer}
          />
        }
        onOrderingChanged={column => handleOrderChange(column)}
        isFilterApplied={searchQuery !== ''}
      />
    </>
  )
}

import React, { useEffect, useState } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Formik, FormikProps } from 'formik'
import debounce from 'lodash.debounce'

import { Loading } from '@lastpass/components'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  SearchInput,
  StyledSpacer,
  TableViewButton,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink, LocationRedirect } from '@lastpass/routing'
import { DataFetchParams } from '@lastpass/routing'
import { useUnmountEffect } from '@lastpass/ui'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import {
  GeneralPolicyUsersAndGroupsQueryParams,
  GeneralPolicyUsersAndGroupsUrlParams
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import {
  GeneralPolicyUsersAndGroups,
  GeneralPolicyUsersAndGroupsForm
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

import { GeneralPolicyGroupsTable } from '../GeneralPolicyGroupsTable'
import { GeneralPolicyUsersTable } from '../GeneralPolicyUsersTable'
import { PolicyAppliesToEditControl } from './components/PolicyAppliesToEditControl'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5px;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

const UpperSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export interface GeneralPolicyEditAssignedItemsFormProps {
  shouldHideUsersOptions: boolean
  policyUsersAndGroups: GeneralPolicyUsersAndGroups
  usersAndGroupsNeedsRefresh?: boolean
  policyConfigUsers: Table<TeamsPolicyUserInfo>
  policyConfigGroups: Table<TeamsPolicyGroup>
  usersChangesList: TeamsPolicyUserInfo[]
  groupsChangesList: TeamsPolicyGroup[]
  policyCurrentlyAppliesTo?: AppliesToOptions
  hasReceivedServerResponse?: boolean
  currentUrl: string
  searchString?: string
  id?: string
  configId?: string
  isForAssign: boolean
  cancelLink: string
  assignUsersLink?: string
  isPolicyDisabled?: boolean
  onSubmitted: (formData: GeneralPolicyUsersAndGroups) => void
  onReset: () => void
  onSearchStringUpdated: (searchString?: string) => void
  onCheckGeneralPolicyAppliesTo: (
    policyAppliesTo: AppliesToOptions,
    params: DataFetchParams<
      GeneralPolicyUsersAndGroupsQueryParams,
      GeneralPolicyUsersAndGroupsUrlParams
    >
  ) => void
  onAddSelectedGroups: (groupList: TeamsPolicyGroup[]) => void
  onRemoveSelectedGroups: (groupList: TeamsPolicyGroup[]) => void
  onSetSelectedGroups: (groupList: TeamsPolicyGroup[]) => void
  onAddSelectedUsers: (userList: TeamsPolicyUserInfo[]) => void
  onRemoveSelectedUsers: (userList: TeamsPolicyUserInfo[]) => void
  onSetSelectedUsers: (userList: TeamsPolicyUserInfo[]) => void
  onSetGeneralPolicyUsersAndGroupsAppliesToForAssign: (
    appliedTo: AppliesToOptions
  ) => void
  onSwitchToAssign?: () => void
}

const isPolicyAppliesToChangedToAll = (
  initialData: GeneralPolicyUsersAndGroupsForm,
  currentFormData: GeneralPolicyUsersAndGroupsForm
): boolean =>
  initialData.appliesTo !== currentFormData.appliesTo &&
  currentFormData.appliesTo === AppliesToOptions.all

const hasFormDataChanged = (
  initialData: GeneralPolicyUsersAndGroupsForm,
  currentFormData: GeneralPolicyUsersAndGroupsForm,
  usersChangesList: TeamsPolicyUserInfo[],
  groupsChangesList: TeamsPolicyGroup[]
): boolean =>
  isPolicyAppliesToChangedToAll(initialData, currentFormData) ||
  usersChangesList.length > 0 ||
  groupsChangesList.length > 0

const debounceSearchRouting = debounce(
  (value: string | undefined, update: (searchString?: string) => void) => {
    if (value && value.length > 1) {
      update(value)
    } else if (!value) {
      update(undefined)
    }
  },
  300
)

const AssignContainer: React.FunctionComponent<{
  assignLink?: string
}> = props => {
  const { assignLink, ...otherProps } = props
  if (!assignLink) {
    return <>{props.children}</>
  }

  return (
    <LocationLink to={assignLink} {...otherProps}>
      {props.children}
    </LocationLink>
  )
}

export const GeneralPolicyEditAssignedItemsForm: React.FunctionComponent<GeneralPolicyEditAssignedItemsFormProps> = props => {
  const policyKey = props.policyUsersAndGroups.policyKey
  const policyAppliesTo =
    props.policyUsersAndGroups.policyConfigFormData.appliesTo
  const initialValues: GeneralPolicyUsersAndGroupsForm = {
    appliesTo: policyAppliesTo
  }

  const [showGroupsTable, setShowGroupsTable] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [radioPolicyAppliesTo, setRadioPolicyAppliesTo] = useState(
    policyAppliesTo
  )
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const isRadioPolicyAppliedToAll =
    radioPolicyAppliesTo === AppliesToOptions.all

  useUnmountEffect(() => {
    props.onReset()
  })
  useEffect(() => {
    if (!props.policyCurrentlyAppliesTo) {
      setRadioPolicyAppliesTo(policyAppliesTo)
    } else {
      setRadioPolicyAppliesTo(props.policyCurrentlyAppliesTo)
    }
  }, [policyAppliesTo, props.policyCurrentlyAppliesTo])

  const onAssignAction = (
    formikProps: FormikProps<GeneralPolicyUsersAndGroupsForm>
  ) => {
    props.onSetGeneralPolicyUsersAndGroupsAppliesToForAssign(
      formikProps.values.appliesTo
    )

    if (props.onSwitchToAssign) {
      props.onSwitchToAssign()
    }
  }

  const getAssignButton = (
    formikProps: FormikProps<GeneralPolicyUsersAndGroupsForm>
  ) =>
    props.isForAssign ? (
      undefined
    ) : (
      <AssignContainer assignLink={props.assignUsersLink}>
        <TableViewButton
          data-qa="AssignUsersAndGroupsTableButton"
          onClick={() => {
            onAssignAction(formikProps)
          }}
        >
          <Trans>Assign users {'&'} groups</Trans>
        </TableViewButton>
      </AssignContainer>
    )

  const getAssignPrimaryButton = (
    formikProps: FormikProps<GeneralPolicyUsersAndGroupsForm>
  ) =>
    !props.isForAssign ? (
      <AssignContainer
        assignLink={props.assignUsersLink}
        css={css`
          margin-bottom: 28px;
          height: 100%;
        `}
      >
        <PrimaryButton
          data-qa="AssignUsersAndGroupsButton"
          onClick={() => {
            onAssignAction(formikProps)
          }}
          css={css`
            margin-left: auto;
            margin-right: 5px;
          `}
        >
          <Trans>Assign users {'&'} groups</Trans>
        </PrimaryButton>
      </AssignContainer>
    ) : (
      <></>
    )

  const usersTableTitle = props.isForAssign ? (
    <Trans>Please search for users </Trans>
  ) : (
    <Trans>No users added yet.</Trans>
  )
  const usersTableText = props.isForAssign ? (
    <Trans>To assign users to this policy, search above.</Trans>
  ) : (
    <></>
  )
  const groupsTableTitle = props.isForAssign ? (
    <Trans>Please search for groups </Trans>
  ) : (
    <Trans>No groups added yet.</Trans>
  )
  const groupsTableText = props.isForAssign ? (
    <Trans>To assign groups to this policy, search above.</Trans>
  ) : (
    <></>
  )
  const saveButtonText = props.isForAssign ? (
    <Trans>Assign users</Trans>
  ) : (props.policyCurrentlyAppliesTo || policyAppliesTo) ===
    AppliesToOptions.all ? (
    <Trans>Save changes</Trans>
  ) : (
    <Trans>Unassign Users</Trans>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        const configFormRequiredData: GeneralPolicyUsersAndGroups = {
          policyKey: policyKey,
          policyConfigIndex: props.policyUsersAndGroups.policyConfigIndex,
          policyConfigFormData: {
            appliesTo: props.shouldHideUsersOptions
              ? radioPolicyAppliesTo
              : values.appliesTo,
            users: [],
            groups: []
          }
        }

        setFormSubmitted(true)
        setIsBlockingPromptEnabled(false)
        props.onSubmitted(configFormRequiredData)
      }}
    >
      {formikProps => {
        return (
          <>
            {(formSubmitted || props.usersAndGroupsNeedsRefresh) && (
              <Loading color="blue900" active={true} />
            )}
            {props.hasReceivedServerResponse && (
              <LocationRedirect to={props.cancelLink} />
            )}
            <FormContainer>
              <UpperSectionContainer>
                <PolicyAppliesToEditControl
                  radioPolicyAppliesTo={radioPolicyAppliesTo}
                  shouldHideUsersOptions={props.shouldHideUsersOptions}
                  onChange={(
                    currentlyAppliesTo: AppliesToOptions,
                    fieldName: string
                  ) => {
                    setRadioPolicyAppliesTo(currentlyAppliesTo)
                    formikProps.setFieldValue(fieldName, currentlyAppliesTo)
                    props.onCheckGeneralPolicyAppliesTo(currentlyAppliesTo, {
                      query: {
                        search: props.searchString || undefined
                      },
                      path: {
                        id: props.id,
                        configId: props.configId
                      }
                    })
                  }}
                />
                {!isRadioPolicyAppliedToAll &&
                  getAssignPrimaryButton(formikProps)}
              </UpperSectionContainer>
              {(!isRadioPolicyAppliedToAll || props.isForAssign) && (
                <TableContainer data-qa="TableContainer">
                  <SearchInput
                    data-qa="SearchFieldInput"
                    onChange={event => {
                      debounceSearchRouting(
                        event && event.target.value
                          ? event.target.value
                          : undefined,
                        props.onSearchStringUpdated
                      )
                    }}
                    value={props.searchString || ''}
                    placeholder={msg`Search users & groups...`}
                  />
                  <StyledTabContainer>
                    <TabNavigation>
                      <div onClick={() => setShowGroupsTable(false)}>
                        <StyledTabNavigationItem
                          active={!showGroupsTable}
                          data-qa="AssignUsersTab"
                        >
                          <Trans>Users</Trans>
                          {` (${props.policyConfigUsers.totalResults})`}
                        </StyledTabNavigationItem>
                      </div>
                      <div onClick={() => setShowGroupsTable(true)}>
                        <StyledTabNavigationItem
                          active={showGroupsTable}
                          data-qa="AssignGroupsTab"
                        >
                          <Trans>Groups</Trans>
                          {` (${props.policyConfigGroups.totalResults})`}
                        </StyledTabNavigationItem>
                      </div>
                    </TabNavigation>
                  </StyledTabContainer>
                  <div onChange={() => setIsBlockingPromptEnabled(true)}>
                    {!showGroupsTable ? (
                      <GeneralPolicyUsersTable
                        usersTable={props.policyConfigUsers}
                        addSelectedRecords={props.onAddSelectedUsers}
                        removeSelectedRecords={props.onRemoveSelectedUsers}
                        setSelectedRecords={props.onSetSelectedUsers}
                        clearSearchQuery={() => {
                          props.onSearchStringUpdated(undefined)
                        }}
                        filterApplied={!!props.searchString}
                        title={usersTableTitle}
                        text={usersTableText}
                        assignButton={getAssignButton(formikProps)}
                      />
                    ) : (
                      <GeneralPolicyGroupsTable
                        groupsTable={props.policyConfigGroups}
                        addSelectedRecords={props.onAddSelectedGroups}
                        removeSelectedRecords={props.onRemoveSelectedGroups}
                        setSelectedRecords={props.onSetSelectedGroups}
                        clearSearchQuery={() => {
                          props.onSearchStringUpdated(undefined)
                        }}
                        filterApplied={!!props.searchString}
                        assignButton={getAssignButton(formikProps)}
                        title={groupsTableTitle}
                        text={groupsTableText}
                      />
                    )}
                  </div>
                </TableContainer>
              )}
            </FormContainer>
            <StyledSpacer />
            <FooterContainer>
              <DrawerButtonsContainer>
                <LocationLink to={props.cancelLink}>
                  <TextButton
                    data-qa="CancelButton"
                    css={css`
                      margin-right: 10px;
                    `}
                  >
                    <Trans>Discard</Trans>
                  </TextButton>
                </LocationLink>

                {!props.isForAssign && (
                  <LocationLink to={props.cancelLink}>
                    <PrimaryButton
                      data-qa="PrimaryButton"
                      css={css`
                        margin-right: 10px;
                      `}
                      type="submit"
                    >
                      <Trans>Back</Trans>
                    </PrimaryButton>
                  </LocationLink>
                )}

                <PrimaryButton
                  data-qa="PrimaryButton"
                  disabled={
                    !(
                      props.isPolicyDisabled &&
                      formikProps.values.appliesTo === AppliesToOptions.all
                    ) &&
                    !hasFormDataChanged(
                      initialValues,
                      formikProps.values,
                      props.usersChangesList,
                      props.groupsChangesList
                    )
                  }
                  onClick={() => {
                    formikProps.handleSubmit()
                  }}
                  type="submit"
                >
                  {saveButtonText}
                </PrimaryButton>
              </DrawerButtonsContainer>
            </FooterContainer>
          </>
        )
      }}
    </Formik>
  )
}

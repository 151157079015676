import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import {
  BodyBoldStyle,
  BodyRegular,
  BodyRegularStyle,
  Checkbox,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

const RegularParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 24px;
`
const BoldParagraph = styled.p`
  ${BodyBoldStyle};
  margin-bottom: 8px;
`
const StyledListItem = styled.li`
  padding-left: 10px;
`
const StyledCheckboxContainer = styled.div`
  margin-top: 24px;
`

export const ResetMfaSharedSecretsDialog: FC = () => {
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState<boolean>(false)

  const discardDialog = () => {
    dispatch(globalActions.discardDialog())
    dispatch(globalActions.emptyDialog())
  }

  const confirmDialog = () => {
    dispatch(globalActions.confirmDialog())
    dispatch(globalActions.emptyDialog())
  }

  const ListItemTexts: string[] = [
    i18n._(msg`All of their LastPass sessions are destroyed`),
    i18n._(
      msg`Their app pairings are revoked and/or Grid values are invalidated`
    ),
    i18n._(
      msg`Upon next login they're forced to verify their login attempt via email`
    ),
    i18n._(
      msg`If required to set up MFA by policy, they're placed into the MFA setup flow to pair their account again`
    )
  ]

  const Content: FC = () => (
    <>
      <BoldParagraph>
        <Trans>Who is impacted?</Trans>
      </BoldParagraph>
      <RegularParagraph>
        <Trans>
          Users of these authentication methods: Google Authenticator, Microsoft
          Authenticator, LastPass MFA, Grid.
        </Trans>
      </RegularParagraph>
      <BoldParagraph>
        <Trans>What can they expect?</Trans>
      </BoldParagraph>
      <BodyRegular>
        <ul style={{ listStyleType: 'circle', listStylePosition: 'inside' }}>
          {ListItemTexts.map((listItemText, index) => (
            <StyledListItem key={index}>{listItemText}</StyledListItem>
          ))}
        </ul>
      </BodyRegular>
      <StyledCheckboxContainer>
        <Checkbox
          checked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
          data-qa="ResetMfaSharedSecretsConfirmDialogCheckbox"
        >
          <Trans>
            I understand who is impacted and what to expect after reset.
          </Trans>
        </Checkbox>
      </StyledCheckboxContainer>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={discardDialog}
      width="480px"
      disableMaxHeight={true}
      title={msg`Reset MFA shared secrets?`}
      content={<Content />}
      buttons={[
        <TextButton
          onClick={discardDialog}
          key="discard"
          data-qa="ResetMfaSharedSecretsConfirmDialogCancelButton"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          red
          onClick={confirmDialog}
          key="confirm"
          disabled={!isChecked}
          data-qa="ResetMfaSharedSecretsConfirmDialogConfirmButton"
        >
          <Trans>Reset</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import buildQuery from 'odata-query'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { FAIL_EMAIL_TEMPLATES_NOT_APPROVED } from '@lastpass/admin-console-dependencies/server/users/view/operations/user-operations'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import {
  UserOperationActionTypes,
  userOperationSegmentActions
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { OperationOriginType } from '@lastpass/admin-console-dependencies/state/users/view/operations/operation-origin-types'
import { BulkActionErrors } from '@lastpass/admin-console-dependencies/types/bulk-action-errors'
import { UserTaskDetails } from '@lastpass/admin-console-dependencies/types/notification-detail-types'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'
import { UserBase } from '@lastpass/admin-console-dependencies/types/user'
import { UserFilter } from '@lastpass/admin-console-dependencies/types/user-filter'
import { userOperationSegmentMapper } from '@lastpass/admin-console-dependencies/ui/common/mappings/user-operation-segment-mapper'

import { buildFilterArray } from '../helpers/build-filter-array'

export interface InvokeApiDialogMessages {
  SingleUserSuccess?: MessageDescriptor
  MultipleUsersSuccess: MessageDescriptor
  SingleUserFail?: MessageDescriptor
  MultipleUsersFail: MessageDescriptor
  PartialSuccess: MessageDescriptor
}

export const getCurrentUrlFilters = (state: AppState) => state.router

function createNotificationDetails(
  users: UserBase[],
  failureReasons
): UserTaskDetails {
  return {
    type: 'user',
    users: users
      .map(u => {
        return {
          email: u.email,
          firstName: u.firstName,
          lastName: u.lastName,
          error: failureReasons[u.id]
            ? failureReasons[u.id]
            : BulkActionErrors.na
        }
      })
      .filter(user => user.error !== BulkActionErrors.na)
  }
}

function getNotificationMessage(
  users: UserBase[],
  messages: InvokeApiDialogMessages
) {
  return users.length === 1
    ? messages.SingleUserFail
    : messages.MultipleUsersFail
}

const convertFilterToQueryString = (
  filter?: UserFilter
): string | undefined => {
  const filters = filter && buildFilterArray(filter)

  const queryString = buildQuery({
    filter: filters ? { and: filters } : undefined
  })

  return queryString.length > 0 ? queryString : undefined
}

export function* invokeApi(
  userService: UACServices.Services,
  users: UserBase[],
  messages: InvokeApiDialogMessages,
  actionPath: string,
  origin: OperationOriginType,
  bodyJson,
  actionType: UserOperationActionTypes,
  filter?: UserFilter
) {
  try {
    const notification: NotificationProps = {
      message: getNotificationMessage(users, messages),
      type: NotificationType.alert,
      autoDismiss: false,
      details: undefined
    }

    const asyncnotification: NotificationProps = {
      message: msg`Your request is being processed. This may take a few seconds.`,
      type: NotificationType.async,
      autoDismiss: false,
      details: undefined
    }

    let usersFailed = 0,
      usersSucceeded = 0

    const currentUrlFilters = yield select(getCurrentUrlFilters)

    try {
      yield put(globalActions.setNotification(asyncnotification))

      yield put(
        userOperationSegmentActions.sendButtonClicked(
          userOperationSegmentMapper[actionType].onClick,
          users.length,
          origin
        )
      )
      const queryString = convertFilterToQueryString(filter)
      const result: UACServices.UserOperationsAPI.Responses = yield call(
        userService.userOperations,
        actionPath,
        bodyJson,
        queryString
      )

      switch (result.type) {
        case 'partialSuccess':
          if (users.length > 1) {
            notification.type = NotificationType.warning
            notification.message = messages.PartialSuccess
            notification.details = createNotificationDetails(users, result.body)
          }
          usersFailed = result.body.length
          usersSucceeded = users.length - usersFailed
          break
        case 'failure':
          if (users.length > 1) {
            notification.details = createNotificationDetails(users, result.body)
          }
          usersFailed = result.body.length
          break
        case FAIL_EMAIL_TEMPLATES_NOT_APPROVED:
          notification.message = msg`A member of the LastPass team must approve your template. Approval can take up to 24 hours.`
          notification.type = NotificationType.warning
          notification.autoDismiss = true
          break
        case 'success':
          notification.type = NotificationType.success
          notification.autoDismiss = true
          notification.message =
            users.length > 1
              ? messages.MultipleUsersSuccess
              : messages.SingleUserSuccess
          usersSucceeded = users.length
          break
        case 'delayedJobSuccess':
          notification.type = NotificationType.success
          notification.autoDismiss = true
          notification.message = msg`Bulk actions may take up to 15 minutes to complete.`
          usersSucceeded = users.length
          break
      }
    } catch (error) {
      if (users.length > 1) {
        notification.details = createNotificationDetails(users, error)
      }
      usersFailed = users.length
    } finally {
      yield put(
        userOperationSegmentActions.responseReceived(
          userOperationSegmentMapper[actionType].onResponse,
          usersSucceeded,
          usersFailed
        )
      )

      if (origin === OperationOriginType.List) {
        yield put(userListActions.setSelectedUsers([]))
        yield put(userListActions.setSelectAllUsers(false))
      }

      yield put(
        userListActions.getUserList({
          query: getQueryParams(currentUrlFilters.location, 'users'),
          path: {}
        })
      )
      yield put(
        globalActions.setNotification({ type: NotificationType.hidden })
      )
      yield put(globalActions.setNotification(notification))
    }
  } catch (e) {
    yield put(globalActions.setNotification(genericFailedNotification))
  }
}

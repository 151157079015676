import { useMemo } from 'react'

import { PsaCompanyContractAssignmentOption } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'

type UseFilteredAssignmentOptions = (
  assignmentOptions: PsaCompanyContractAssignmentOption[],
  searchText: string
) => PsaCompanyContractAssignmentOption[]

export const useFilteredAssignmentOptions: UseFilteredAssignmentOptions = (
  assignmentOptions,
  searchText
) => {
  return useMemo(() => {
    return assignmentOptions.filter(assignmentOption =>
      assignmentOption.company.name
        .toLowerCase()
        .includes(searchText.toLowerCase())
    )
  }, [assignmentOptions, searchText])
}

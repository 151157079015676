import {
  ApplicationEmptyListActions,
  ApplicationEmptyListActionTypes
} from './actions'
import { ApplicationEmptyListState } from './state'

export const initialState: ApplicationEmptyListState = {
  availableApplications: [],
  isLoading: false
}

export function applicationEmptyListReducer(
  previousState: ApplicationEmptyListState | undefined,
  action: ApplicationEmptyListActions
): ApplicationEmptyListState {
  const state = previousState || initialState
  switch (action.type) {
    case ApplicationEmptyListActionTypes.SET_AVAILABLE_APPLICATIONS: {
      return {
        ...state,
        availableApplications: action.payload.list
      }
    }
    case ApplicationEmptyListActionTypes.SET_APPLICATION_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.payload.loading
      }
    }
  }
  return state
}

import React, { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components'
import {
  PrimaryButton,
  SearchInput,
  Table,
  TableView,
  TableViewButton,
  TextButton,
  WithTooltip
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { RecentManagedCompaniesRepository } from '@lastpass/admin-console-dependencies/repositories'
import { isCurrentUserAdminOrSuperAdmin } from '@lastpass/admin-console-dependencies/services/get-user-role-helper'
import {
  getOperations,
  ManagedCompanyListOperation,
  ManagedCompanyListOperationTypes
} from '@lastpass/admin-console-dependencies/services/managed-companies/list/operations'
import { formatMfaAddonValue } from '@lastpass/admin-console-dependencies/services/managed-companies/mfa-addon'
import { formatSecurityScore } from '@lastpass/admin-console-dependencies/services/managed-companies/security-score'
import { ManagedMigrationCompanyInfo } from '@lastpass/admin-console-dependencies/state/applications/application'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { ManagedCompanyListQueryParams } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { UsersListState } from '@lastpass/admin-console-dependencies/state/users/view/list/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CidOverrideParams } from '@lastpass/admin-console-dependencies/types/cid-override-params'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'
import { Table as TableType } from '@lastpass/admin-console-dependencies/types/table'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { CompanyStatusTooltip } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/CompanyStatusTooltip'
import { ConsumedLicensesTooltip } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/ConsumedLicensesTooltip'
import { StatusBadge } from '@lastpass/admin-console-dependencies/ui/home/managed-companies/StatusBadge'
import { hasMigratableManagedCopmanies } from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'
import { SystemUpgradeHandleManagedCompanyWarning } from '@lastpass/admin-console-dependencies/ui/upgrade/system/SystemUpgradeHandleManagedCompanyWarning'

import { OperationsDropdown } from './OperationsDropdown'

const SearchInputContainer = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
  clear: both;
`

const OperationsContainer = styled.div`
  float: right;
  padding: 0 24px;
`

const StyledSearchInput = styled(SearchInput)`
  max-width: 320px;
  margin-right: 0;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
`
const StyledOperationsDropdown = styled(OperationsDropdown)`
  margin-left: 16px;
`

const tableColumns: { [key: string]: TableColumn<ManagedCompany> } = {
  companyName: {
    name: <Trans>Company name</Trans>,
    renderer: record => record.name
  },
  companyId: {
    name: <Trans>Company ID</Trans>,
    renderer: record => String(record.id)
  },
  companyStatus: {
    name: (
      <>
        <Trans>Status</Trans>
        <WithTooltip tooltip={<CompanyStatusTooltip />} width="300px">
          <StyledHelpIcon />
        </WithTooltip>
      </>
    ),
    renderer: function StatusBadgeRenderer(record) {
      return <StatusBadge status={record.status} />
    },
    noTooltip: true
  },
  consumedLicenses: {
    name: (
      <>
        <Trans>Consumed licenses</Trans>
        <WithTooltip tooltip={<ConsumedLicensesTooltip />} width="300px">
          <StyledHelpIcon />
        </WithTooltip>
      </>
    ),
    renderer: record => String(record.licensesConsumed)
  },
  availableLicenses: {
    name: <Trans>Available licenses</Trans>,
    renderer: record => String(record.licensesAvailable)
  },
  securityScore: {
    name: <Trans>Security score</Trans>,
    renderer: function SecurityScoreRenderer({ averageSecurityScore }) {
      const formattedSecurityScore = formatSecurityScore(averageSecurityScore)
      return (
        <span data-qa="ManagedCompanyListSecurityScore">
          {typeof formattedSecurityScore === 'string'
            ? formattedSecurityScore
            : i18n._(formattedSecurityScore)}
        </span>
      )
    },
    noTooltip: true
  },
  hasMfa: {
    name: <Trans>Advanced MFA add-on</Trans>,
    renderer: function HasMfaRenderer({ hasMfa }) {
      const formattedMfaAddonValue = formatMfaAddonValue(hasMfa)
      return (
        <span data-qa="ManagedCompanyListAdvancedMfaAddon">
          {typeof formattedMfaAddonValue === 'string'
            ? formattedMfaAddonValue
            : i18n._(formattedMfaAddonValue)}
        </span>
      )
    }
  }
}

const filterColumns = (keys: (keyof typeof tableColumns)[]) =>
  keys.map(columnName => tableColumns[columnName])

const createManageCompanyButtonColumn = (
  canAddManagedCompanies,
  cidOverride
) => {
  return {
    name: <></>,
    renderer: function CidOverrideButtonRenderer({ id, name }) {
      return (
        <TextButton
          data-qa="ManagedCompanyListManageCompanyButton"
          blue
          onClick={event => {
            event.preventDefault()
            const cidOverrideParams: CidOverrideParams = {
              companyId: `${id}`,
              companyName: name
            }
            {
              !cidOverride &&
                RecentManagedCompaniesRepository.set(
                  cidOverrideParams,
                  canAddManagedCompanies
                )
            }
            window.open(`/dashboard?cid=${id}`, '_blank')
          }}
        >
          <Trans>Manage company</Trans>
        </TextButton>
      )
    },
    noTooltip: true
  }
}

export interface ManagedCompanyListProps {
  table: TableType<ManagedCompany>
}

export const ManagedCompanyList: FC<ManagedCompanyListProps> = ({ table }) => {
  const permissions = useContext(PermissionContext)
  const updateQuery = useUpdateQuery(Namespace.managedCompanies)
  const queryParams: ManagedCompanyListQueryParams = useQueryParams(
    Namespace.managedCompanies
  )

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const dispatch = useDispatch()

  const {
    details: { isAggregator, isBillingUsageBased },
    cidOverride
  } = useSelector<AppState, CompanyInfoState>(appState => appState.companyInfo)

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const isNopasswordMigrationEnabled = useFeatureFlags(
    FeatureFlags.isNopasswordMigrationEnabled
  )
  const isPsaIntegrationEnabled =
    useFeatureFlags(FeatureFlags.isPsaIntegrationEnabled) &&
    permissions.requirePermission(
      AdminPermissions.viewManagedServiceProviderPsa
    )

  const canManageCompanies = permissions.requirePermission(
    isRoleBasedAccessControlEnabled
      ? AdminPermissions.managedCompanyCidOverride
      : AdminPermissions.managedCompanyAccess
  )

  const canCreateManagedCompanies = permissions.requirePermission(
    isRoleBasedAccessControlEnabled
      ? AdminPermissions.addManagedCompany
      : AdminPermissions.managedCompanyAccess
  )

  const canAddManagedCompanies = permissions.requirePermission(
    AdminPermissions.addManagedCompany
  )

  useEffect(() => {
    dispatch(systemUpgradeActions.fetchMigratableManagedCompanies())
  }, [dispatch])

  const migratableManagedCompaniesState: ManagedMigrationCompanyInfo[] = useSelector<
    AppState,
    ManagedMigrationCompanyInfo[]
  >(appState => appState.systemUpgrade.migratableManagedCompanies)

  const hasMigratableManagedCompany: boolean = hasMigratableManagedCopmanies(
    migratableManagedCompaniesState
  )

  const getTableColumns = (): TableColumn<ManagedCompany>[] => {
    const columns: TableColumn<ManagedCompany>[] = []

    if (isAggregator || isBillingUsageBased) {
      columns.push(
        ...filterColumns([
          'companyName',
          'companyId',
          'companyStatus',
          'securityScore',
          'hasMfa'
        ])
      )
    } else {
      columns.push(
        ...filterColumns([
          'companyName',
          'companyId',
          'companyStatus',
          'consumedLicenses',
          'availableLicenses',
          'securityScore',
          'hasMfa'
        ])
      )
    }

    if (canManageCompanies && !cidOverride) {
      columns.push(
        createManageCompanyButtonColumn(canAddManagedCompanies, cidOverride)
      )
    }

    return columns
  }

  const { currentAdminLevel } = useSelector<AppState, UsersListState>(
    state => state.usersList
  )

  const isUserAdminOrSuperAdmin = isCurrentUserAdminOrSuperAdmin(
    currentAdminLevel
  )

  return (
    <>
      <OperationsContainer>
        {canCreateManagedCompanies && (
          <LocationLink to="/home/managed-companies/create">
            <PrimaryButton data-qa="CreateManagedCompanyButton">
              <Trans>Create managed company</Trans>
            </PrimaryButton>
          </LocationLink>
        )}
        <StyledOperationsDropdown
          data-qa="ManagedCompanyListOperationsDropdown"
          operations={
            isUserAdminOrSuperAdmin
              ? Object.values(getOperations(dispatch, isPsaIntegrationEnabled))
              : Object.entries(
                  getOperations(dispatch, isPsaIntegrationEnabled)
                ).reduce(
                  (
                    acc: ManagedCompanyListOperation[],
                    [operationType, operation]: [
                      string,
                      ManagedCompanyListOperation
                    ]
                  ) =>
                    operationType !==
                    ManagedCompanyListOperationTypes.configurePSAIntegration
                      ? [...acc, operation]
                      : acc,
                  []
                )
          }
        />
      </OperationsContainer>
      {isNopasswordMigrationEnabled && hasMigratableManagedCompany && (
        <SystemUpgradeHandleManagedCompanyWarning data-qa="ManagedCompanyWarningBox" />
      )}
      <SearchInputContainer>
        <StyledSearchInput
          data-qa="ManagedCompanyListSearch"
          placeholder={msg`Search...`}
          onChange={event => {
            updateQuery({
              search: event.target.value || undefined
            })
          }}
          value={queryParams.search || ''}
        />
      </SearchInputContainer>
      <Table
        qadata="ManagedCompanyListTable"
        table={table}
        getRecordLink={(record: ManagedCompany) => `${matchUrl}/${record.id}`}
        loadingView={<Loading color="blue900" active />}
        columns={getTableColumns()}
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No managed companies yet</Trans>}
            text={<Trans>Add companies under your primary account.</Trans>}
            actionButton={
              <LocationLink to="/home/managed-companies/create">
                <TableViewButton data-qa="ManagedCompanyListTableAddNewButton">
                  <Trans>Add a managed company</Trans>
                </TableViewButton>
              </LocationLink>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined
                  })
                }}
                data-qa="ManagedCompanyListTableClearSearchButton"
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        isFilterApplied={Boolean(queryParams.search)}
      />
    </>
  )
}

import { LOCATION_CHANGE } from 'connected-react-router'
import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GlobalActionTypes } from '@lastpass/admin-console-dependencies/state/global/actions'

import { createClearNotificationSaga } from './clear-notification'
import { createCurrentUserSaga } from './current-user'
import { createMfaApplicationsGetStartedSaga } from './mfa-applications-get-started'
import { createPasswordlessGetStartedSaga } from './passwordless-get-started'
import { createShowMfaAddonUpsellSaga } from './show-mfa-addon-upsell'
import { createShowMfaUsageNotificationSaga } from './show-mfa-usage-notification-dialog'
import { createShowMspPaymentInfoSaga } from './show-msp-payment-info'
import { createShowUpsellSaga } from './show-upsell'

export function createGlobalSaga(feedbackServices: UACServices.Services) {
  const currentUserSaga = createCurrentUserSaga()
  const showUpsellSaga = createShowUpsellSaga()
  const showMfaAddonUpsellSaga = createShowMfaAddonUpsellSaga()
  const clearNotificationSaga = createClearNotificationSaga()
  const showMfaUsageNotificationSaga = createShowMfaUsageNotificationSaga()
  const mfaApplicationGetStarted = createMfaApplicationsGetStartedSaga()
  const passwordlessGetStarted = createPasswordlessGetStartedSaga()
  const showMspPaymentInfoSaga = createShowMspPaymentInfoSaga(feedbackServices)

  return function*() {
    yield takeEvery(GlobalActionTypes.GET_CURRENT_USER, currentUserSaga)
    yield takeEvery(GlobalActionTypes.SHOW_MFA_UPSELL_DIALOG, showUpsellSaga)
    yield takeEvery(
      GlobalActionTypes.SHOW_MFA_USAGE_NOTIFICATION,
      showMfaUsageNotificationSaga
    )
    yield takeEvery(
      GlobalActionTypes.SHOW_MFA_ADDON_UPSELL_DIALOG,
      showMfaAddonUpsellSaga
    )
    yield takeLatest(
      GlobalActionTypes.CLEAR_NOTIFICATION,
      clearNotificationSaga
    )
    yield takeEvery(
      GlobalActionTypes.MFA_APPLICATION_GETSTARTED,
      mfaApplicationGetStarted
    )
    yield takeEvery(
      GlobalActionTypes.PASSWORDLESS_GETSTARTED,
      passwordlessGetStarted
    )
    yield takeEvery(
      GlobalActionTypes.SHOW_MSP_PAYMENT_INFO_DIALOG,
      showMspPaymentInfoSaga
    )

    // Hide notification when redirecting to a new page
    yield takeLatest(
      reduxAction =>
        reduxAction.type === LOCATION_CHANGE &&
        reduxAction.payload.action === 'PUSH',
      clearNotificationSaga
    )
  }
}

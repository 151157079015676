import { call, put } from 'redux-saga/effects'

import { CidOverrideRepository } from '@lastpass/admin-console-dependencies/repositories'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createRemoveCidOverrideSaga() {
  return function* removeCidOverrideSaga(
    action: ReturnType<typeof companyInfoActions.removeCidOverride>
  ) {
    try {
      yield call(CidOverrideRepository.remove)
      window.location.replace(
        `${window.location.origin}${
          action.payload.redirectUrl
            ? action.payload.redirectUrl
            : '/home/managed-companies'
        }`
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import { Table, TableView } from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { groupFilterActions } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import { GroupFilterState } from '@lastpass/admin-console-dependencies/state/common/group-filter/state'
import { userFilterActions } from '@lastpass/admin-console-dependencies/state/common/user-filter/actions'
import { UserFilterState } from '@lastpass/admin-console-dependencies/state/common/user-filter/state'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import { AdvancedUserActivityEvent } from '@lastpass/admin-console-dependencies/types/advanced-user-activity'

import { AdvancedUserActivityFiltersRow } from './components/AdvancedUserActivityFiltersRow'
import { AdvancedUserActivityListSearch } from './components/AdvancedUserActivityListSearch'
import { AdvancedUserActivityColumnMapping } from './shared/AdvancedUserActivityColumnMapping'

export const AdvancedUserActivityList: React.FunctionComponent = () => {
  const routeMatch = useRouteMatch()
  const queryParams = useQueryParams(Namespace.userReports)
  const updateQuery = useUpdateQuery(Namespace.userReports)
  const dispatch = useDispatch()
  const state = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )
  const userFilter = useSelector<AppState, UserFilterState>(
    appState => appState.userFilter
  )
  const groupFilter = useSelector<AppState, GroupFilterState>(
    appState => appState.groupFilter
  )

  const orderBy = queryParams.orderBy || 'timeUtc desc'

  useEffect(() => {
    dispatch(
      advancedUserActivityActions.getUserActivity({
        query: {
          ...queryParams,
          skip: queryParams.skip || 0,
          orderBy
        },
        path: {}
      })
    )
  }, [dispatch, orderBy, queryParams])

  useEffect(() => {
    dispatch(advancedUserActivityActions.getUserActivityEventTypes())
    dispatch(advancedUserActivityActions.getSavedReports())
    dispatch(advancedUserActivityActions.removeSelectedSavedReport())
  }, [dispatch])

  useEffect(() => {
    if (queryParams.filter) {
      queryParams.filter.group &&
        dispatch(
          groupFilterActions.getGroupListByIdList(queryParams.filter.group)
        )

      queryParams.filter.user &&
        dispatch(userFilterActions.getUserListByIdList(queryParams.filter.user))
    }
    // it should be loaded only on mount OR when selectedSavedReport (admin loads a saved report) changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedSavedReport])

  useEffect(() => {
    if (
      groupFilter.idMapping &&
      queryParams.filter &&
      queryParams.filter.group
    ) {
      const group = queryParams.filter.group.filter(
        groupId => groupFilter.idMapping[groupId]
      )
      group.length !== queryParams.filter.group.length &&
        updateQuery({
          filter: { ...queryParams.filter, group }
        })
    }
    // removes unavailable group ids from the query
    // it should run once, when groupFilter.idMapping is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFilter.idMapping])

  useEffect(() => {
    if (userFilter.idMapping && queryParams.filter && queryParams.filter.user) {
      const user = queryParams.filter.user.filter(
        userId => userFilter.idMapping[userId]
      )
      user.length !== queryParams.filter.user.length &&
        updateQuery({
          filter: { ...queryParams.filter, user }
        })
    }
    // removes unavailable user ids from the query
    // it should run once, when userFilter.idMapping is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter.idMapping])

  return (
    <>
      <AdvancedUserActivityListSearch state={state} />

      <AdvancedUserActivityFiltersRow />

      <Table
        table={state.table}
        getRecordLink={(record: AdvancedUserActivityEvent) =>
          routeMatch ? `${routeMatch.url}/${record.id}` : ''
        }
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No User Activity Event Data</Trans>}
            text={<Trans>No user data returned.</Trans>}
          />
        }
        noResultsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        loadingView={<Loading color="blue900" active />}
        orderByExpression={orderBy}
        onOrderingChanged={orderBy => {
          updateQuery({
            skip: 0,
            orderBy
          })
        }}
        columns={state.table.columns.map(
          column => AdvancedUserActivityColumnMapping[column]
        )}
        isFilterApplied={!!queryParams.eventType || !!queryParams.search}
      />
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SecurityScoreDataState } from '@lastpass/admin-console-dependencies/state/dashboard/state'

export const SUCCESS = 'success'

const success = (data: SecurityScoreDataState) => createResponse(SUCCESS, data)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch(
      'dashboard/usersecurity/posture',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const parsedResponse = JSON.parse(response).content
      const data: SecurityScoreDataState = {
        averageScore: parsedResponse.averageSecurityScorePercentage,
        averagePasswordStrength:
          parsedResponse.averagePasswordStrengthPercentage,
        weakMasterPassword:
          parsedResponse.usersWithWeakMasterPasswordPercentage,
        reusedMasterPassword:
          parsedResponse.usersReusedMasterPasswordPercentage,
        weakScore: parsedResponse.usersWithWeakSecurityScorePercentage
      }
      return success(data)
    }

    throw new UnknownServiceError()
  }
}

export const getIdentityIFrameUrl = (
  urlString: string,
  cidOverride: string | undefined
) => {
  const url = new URL(urlString)

  url.searchParams.set('uac', 'true')

  if (cidOverride) {
    url.searchParams.set('managedClient', cidOverride)
  }

  return url.toString()
}

import React, { FunctionComponent } from 'react'

import { i18n } from '@lingui/core'

import { DetailsDialog, PrimaryButton, TextButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface ConfirmDialogProps {
  state: ConfirmDialog
  actions: typeof globalActions
}

export const ConfirmDialogComponent: FunctionComponent<ConfirmDialogProps> = ({
  state,
  actions
}) => {
  const discardText = i18n._(state.discardText)
  const confirmText = i18n._(state.confirmText)
  return (
    <DetailsDialog
      closeDialog={() => {
        actions.discardDialog()
        actions.emptyDialog()
      }}
      width={'480px'}
      title={state.title}
      subTitle={state.text}
      content={state.content}
      buttons={[
        <TextButton
          data-qa="DiscardButton"
          onClick={() => {
            actions.discardDialog()
            actions.emptyDialog()
          }}
          key={'discard'}
        >
          {discardText}
        </TextButton>,
        <PrimaryButton
          red
          onClick={() => {
            actions.confirmDialog()
            actions.emptyDialog()
          }}
          key={'confirm'}
        >
          {confirmText}
        </PrimaryButton>
      ]}
    />
  )
}

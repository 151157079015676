import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as OpenLockIcon } from '@lastpass/assets/svg/admin-console/icon-open-lock.svg'
import { ReactComponent as WorkstationLoginIllustration } from '@lastpass/assets/svg/admin-console/policies/workstation-login-illustration.svg'
import {
  BodyRegular,
  BodySemibold,
  Heading300,
  PrimaryButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledIllustration = styled(WorkstationLoginIllustration)`
  margin-top: 120px;
  margin-bottom: 32px;
`

const StyledHeader = styled(Heading300)`
  margin-bottom: 32px;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-bottom: 32px;
`

const RedBodySemibold = styled(BodySemibold)`
  color: ${props => props.theme.colors.red700};
`

const StyledLink = styled.a`
  margin-top: 40px;
  color: ${props => props.theme.colors.blue700};
`

export const MultifactorEmptyPage: React.FunctionComponent = () => {
  return (
    <FlexContainer>
      <StyledIllustration />
      <StyledHeader>
        <Trans>Protect your organization with multifactor authentication</Trans>
      </StyledHeader>
      <StyledBodyRegular>
        <Trans>
          Empower employee access to business resources without compromising
          security.
        </Trans>
      </StyledBodyRegular>
      <StyledBodyRegular>
        <OpenLockIcon />
        <RedBodySemibold>
          <Trans>MFA is currently optional:</Trans>{' '}
        </RedBodySemibold>
        <Trans>
          Users are free to enable MFA for their vault and SSO apps in their
          account settings.
        </Trans>
      </StyledBodyRegular>
      <LocationLink to={'/policies/multifactor/add'}>
        <PrimaryButton data-qa="GetStartedButton" key="getStarted">
          <Trans>Get started</Trans>
        </PrimaryButton>
      </LocationLink>
      <StyledLink
        data-qa="LearnMoreLink"
        target="_blank"
        href="https://link.lastpass.com/mfa-policies"
      >
        <Trans>Learn more</Trans>
      </StyledLink>
    </FlexContainer>
  )
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { groupFilterActions } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGroupListByIdListSaga(
  groupFilterService: UACServices.Services
) {
  return function* getGroupListByIdListSaga(
    action: ReturnType<typeof groupFilterActions.getGroupListByIdList>
  ) {
    try {
      const query = buildQuery({
        filter: [
          {
            or: action.payload.idList.map(groupId => ({
              groupId: parseInt(groupId, 10)
            }))
          }
        ],
        top: action.payload.idList.length
      })

      const result: UACServices.GroupFilterAPI.Responses = yield call(
        groupFilterService.groupFilter,
        query
      )
      if (result.type === UACServices.GroupFilterAPI.SUCCESS) {
        const groups = result.body.groupsList
        yield put(groupFilterActions.setGroupListByIdList(groups))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg, Plural, plural, Trans } from '@lingui/macro'

import { BodyBold, BodyRegularStyle } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { DeleteUserConfirmationWordDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { ConfirmationWordDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/ConfirmationWordDialog'

interface DeleteUserConfirmationWordDialogProps {
  state: DeleteUserConfirmationWordDialogType
  actions: typeof globalActions
}

const ContentContainer = styled.div`
  ${BodyRegularStyle};
`

const Paragraph = styled.p`
  margin-bottom: 1rem;
`

const StyledListItem = styled.li`
  list-style: disc;
  margin-left: 20px;
`

const StyledList = styled.ul`
  margin-top: 16px;
  margin-left: 8px;
`

export const DeleteUserConfirmationWordDialog: FC<DeleteUserConfirmationWordDialogProps> = ({
  state
}) => {
  const numberOfUsers = state.isAllSelected
    ? state.allUserCount
    : state.users.length

  const Content = (
    <ContentContainer>
      <Paragraph>
        {numberOfUsers === 1 ? (
          <Trans>
            You’re about to permanently delete this user’s LastPass account:{' '}
            <BodyBold data-qa="DeleteUserConfirmationWordDialogUserEmail">
              {state.users[0].email}
            </BodyBold>
          </Trans>
        ) : (
          <Trans>
            You’re about to permanently delete the LastPass accounts of{' '}
            <BodyBold data-qa="DeleteUserConfirmationWordDialogUserCount">
              {numberOfUsers}
            </BodyBold>{' '}
            users.
          </Trans>
        )}
      </Paragraph>
      <StyledList>
        <StyledListItem>
          <Plural
            value={numberOfUsers}
            one="The LastPass account associated with this email will cease to exist"
            other="The LastPass accounts associated with these emails will cease to exist"
          />
        </StyledListItem>
        <StyledListItem>
          <Plural
            value={numberOfUsers}
            one="The user will be removed from your LastPass organization"
            other="The users will be removed from your LastPass organization"
          />
        </StyledListItem>
        <StyledListItem>
          <Plural
            value={numberOfUsers}
            one="Their seat becomes available"
            other="Their seats become available"
          />
        </StyledListItem>
        <StyledListItem>
          <Trans>
            Their <BodyBold>vault data</BodyBold> will be{' '}
            <BodyBold>deleted</BodyBold>, with no recovery option
          </Trans>
        </StyledListItem>
      </StyledList>
    </ContentContainer>
  )

  return (
    <ConfirmationWordDialog
      confirmationWord="DELETE"
      title={plural(numberOfUsers, {
        one: 'Delete user?',
        other: 'Delete users?'
      })}
      content={Content}
      confirmButtonText={
        numberOfUsers === 1 ? msg`Delete` : msg`Delete ${numberOfUsers} users`
      }
    />
  )
}

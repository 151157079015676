import { call, put, select } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicyAssignUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/state'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyMap,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  getPolicyAssignUsersAndGroupsState,
  getPolicyInfo
} from './get-general-policy-assign-users'

export const getPolicyConfigUsersList = (
  policyData: TeamsPolicyModelData,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyUserInfo[] => {
  let usersList: TeamsPolicyUserInfo[] = []
  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData.appliesTo
  ) {
    return usersList
  }
  if (policyData.appliesTo === AppliesToOptions.inclusive) {
    usersList = policyData.inclusiveList ? policyData.inclusiveList : []
  } else if (policyData.appliesTo === AppliesToOptions.exclusive) {
    usersList = policyData.exclusiveList ? policyData.exclusiveList : []
  }
  return usersList
}

export const getPolicyConfigGroupsList = (
  policyData: TeamsPolicyModelData,
  policyCurrentlyAppliesTo?: AppliesToOptions
): TeamsPolicyGroup[] => {
  let groupsList: TeamsPolicyGroup[] = []
  if (
    policyCurrentlyAppliesTo &&
    policyCurrentlyAppliesTo !== policyData.appliesTo
  ) {
    return groupsList
  }
  if (policyData.appliesTo === AppliesToOptions.inclusive) {
    groupsList = policyData.inclusiveGroupList
      ? policyData.inclusiveGroupList
      : []
  } else if (policyData.appliesTo === AppliesToOptions.exclusive) {
    groupsList = policyData.exclusiveGroupList
      ? policyData.exclusiveGroupList
      : []
  }
  return groupsList
}

const applyUsersFilters = (
  searchList: TeamsPolicyUserInfo[],
  existingUsersList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] => {
  const filteredUserList = searchList.filter(user => {
    return !existingUsersList.some(
      existingUser => existingUser.uid === user.uid
    )
  })
  return filteredUserList
}

const applyGroupsFilters = (
  searchList: TeamsPolicyGroup[],
  existingGroupsList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] => {
  const filteredGroupList = searchList.filter(group => {
    return !existingGroupsList.some(
      existingGroup => existingGroup.id === group.id
    )
  })
  return filteredGroupList
}

const updateToBeAddedUsers = (
  tobeAddedUsers: TeamsPolicyUserInfo[],
  searchList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] => {
  const updatedToBeAddedUsers = tobeAddedUsers.map(user => {
    const newRecord = searchList.find(searchUser => searchUser.uid === user.uid)
    if (newRecord) {
      return newRecord
    }
    return user
  })
  return updatedToBeAddedUsers
}

const updateToBeAddedGroups = (
  tobeAddedGroups: TeamsPolicyGroup[],
  searchList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] => {
  const updatedToBeAddedGroups = tobeAddedGroups.map(group => {
    const newRecord = searchList.find(
      searchGroup => searchGroup.id === group.id
    )
    if (newRecord) {
      return newRecord
    }
    return group
  })
  return updatedToBeAddedGroups
}

export function createSearchAssignUsersAndGroupsGeneralPolicySaga(
  policyService: UACServices.Services
) {
  return function* searchAssignUsersAndGroupsGeneralPolicySaga(
    action: ReturnType<
      typeof generalPolicyAssignUsersAndGroupsActions.searchGeneralPolicyAssignUsersAndGroups
    >
  ) {
    const { searchKey, policyKey, policyConfigId } = action.payload

    yield put(
      generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersLoading(
        true
      )
    )
    yield put(
      generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignGroupsLoading(
        true
      )
    )
    try {
      const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
      const policyAssignUsersAndGroupsFormState: GeneralPolicyAssignUsersAndGroupsFormState = yield select(
        getPolicyAssignUsersAndGroupsState
      )
      const policyCurrentlyAppliesTo =
        policyAssignUsersAndGroupsFormState.policyCurrentlyAppliesTo
      const policy = policyMap[policyKey]
      if (policy) {
        const searchUsersResult: UACServices.GeneralPolicyUsersSearchAPI.Responses = yield call(
          policyService.generalPolicySearchUsers,
          searchKey
        )
        const searchGroupsResult: UACServices.GeneralPolicyGroupsSearchAPI.Responses = yield call(
          policyService.generalPolicySearchGroups,
          searchKey
        )
        if (
          searchUsersResult.type ===
            UACServices.GeneralPolicyUsersSearchAPI.SUCCESS &&
          searchGroupsResult.type ===
            UACServices.GeneralPolicyGroupsSearchAPI.SUCCESS
        ) {
          const searchUsersList = searchUsersResult.body.policyUsersList
          const searchGroupsResponseList =
            searchGroupsResult.body.policyGroupsList
          const searchGroupsList: TeamsPolicyGroup[] = searchGroupsResponseList.map(
            groupResponse => {
              return {
                id: groupResponse.cgid,
                name: groupResponse.name,
                userscount: parseInt(groupResponse.userscount, 10)
              }
            }
          )
          const toBeAddedUsers =
            policyAssignUsersAndGroupsFormState.toBeAddedUsers
          const toBeAddedGroups =
            policyAssignUsersAndGroupsFormState.toBeAddedGroups
          const updatedToBeAddedUsers = updateToBeAddedUsers(
            toBeAddedUsers,
            searchUsersList
          )
          const updatedToBeAddedGroups = updateToBeAddedGroups(
            toBeAddedGroups,
            searchGroupsList
          )
          const existingPolicyUsers = getPolicyConfigUsersList(
            policy.policyData[policyConfigId],
            policyCurrentlyAppliesTo
          )
          const existingPolicyGroups = getPolicyConfigGroupsList(
            policy.policyData[policyConfigId],
            policyCurrentlyAppliesTo
          )
          const filteredUserList = applyUsersFilters(
            searchUsersList,
            existingPolicyUsers
          )
          const filteredGroupList = applyGroupsFilters(
            searchGroupsList,
            existingPolicyGroups
          )
          const policyConfigAssignUsersAndGroups: GeneralPolicyUsersAndGroups = {
            policyKey: policy.policyKey,
            policyConfigIndex: policyConfigId,
            policyConfigFormData: {
              appliesTo: policy.policyData[policyConfigId].appliesTo,
              users: filteredUserList,
              groups: filteredGroupList
            }
          }
          yield put(
            generalPolicyAssignUsersAndGroupsActions.updateGeneralpolicyAssignUsersAndGroupsToBeAdded(
              updatedToBeAddedUsers,
              updatedToBeAddedGroups
            )
          )
          yield put(
            generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroups(
              policyConfigAssignUsersAndGroups
            )
          )
          yield put(
            generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersAndGroupsNeedsRefresh(
              false,
              policyKey,
              policyConfigId
            )
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(
        generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignUsersLoading(
          false
        )
      )
      yield put(
        generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyAssignGroupsLoading(
          false
        )
      )
    }
  }
}

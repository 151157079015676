import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GenerateReport } from '@lastpass/admin-console-dependencies/types/generated-security-reports'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch): GenerateReport {
  return async function service() {
    const [, status] = await fetch('users/vault-user-url-reports/jobs', 'POST')
    if (status === StatusCodes.ACCEPTED) {
      return success()
    }
    throw new UnknownServiceError()
  }
}

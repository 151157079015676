import { SharedFolderListActions, SharedFolderListActionTypes } from './actions'
import { SharedFolderListState } from './state'

export const initialState: SharedFolderListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function sharedFolderListReducer(
  previousState: SharedFolderListState | undefined,
  action: SharedFolderListActions
): SharedFolderListState {
  const state = previousState || initialState
  switch (action.type) {
    case SharedFolderListActionTypes.SET_SHARED_FOLDER_LIST: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: []
        }
      }
    }
    case SharedFolderListActionTypes.SET_SHARED_FOLDER_LIST_LOADING: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          isLoading: action.payload.loading
        }
      }
    }
  }
  return state
}

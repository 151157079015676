import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components'
import {
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'

export interface AdvancedUserActivitySaveFiltersDrawerProps {
  closeLink: string
  cancelLink: string
}

export const AdvancedUserActivitySaveFiltersDrawer: React.FunctionComponent<AdvancedUserActivitySaveFiltersDrawerProps> = ({
  cancelLink,
  closeLink
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const updateQuery = useUpdateQuery(Namespace.userReports)
  const {
    selectedSavedReport,
    savedReports,
    drawerIsLoading,
    filterForSave
  } = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )

  useEffect(() => {
    updateQuery({
      filter: filterForSave
    })
    // it should be loaded only on mount OR when selectedSavedReport changes (because of a newly saved report)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSavedReport])

  interface SaveReport {
    (param: string): void
    (param: { reportName: string }): void
  }

  const saveReport: SaveReport = (param: string | { reportName: string }) => {
    const reportName = typeof param === 'string' ? param : param.reportName

    dispatch(advancedUserActivityActions.saveReport(reportName))
    history.push({
      pathname: cancelLink,
      search: location.search
    })
  }

  const initialValues = {
    reportName: ''
  }

  const savedReportNames = savedReports.map(report => report.name)
  const validationSchema = Yup.object().shape({
    reportName: Yup.string()
      .trim()
      .required(i18n._(msg`Report name is required`))
      .notOneOf(
        savedReportNames,
        i18n._(msg`Name already in use. Please provide another name.`)
      )
  })

  return (
    <Drawer closeLink={closeLink} mainTitle={<Trans>New report</Trans>}>
      {drawerIsLoading && <Loading color="blue900" active={true} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        enableReinitialize
        validateOnBlur={false}
        onSubmit={saveReport}
      >
        {formikProps => (
          <Form>
            <Field name="reportName">
              {formData => (
                <TextInput
                  name={formData.field.name}
                  value={formData.field.value}
                  placeholder={msg`Required`}
                  onChange={formData.field.onChange}
                  errorText={<>{formikProps.errors.reportName}</>}
                  data-qa={'SaveReportInputField'}
                >
                  <Trans>Report name</Trans>
                </TextInput>
              )}
            </Field>
            <FooterContainer>
              <DrawerButtonsContainer>
                <LocationLink to={closeLink}>
                  <TextButton
                    type="button"
                    css={css`
                      margin-right: 16px;
                    `}
                    data-qa={'SaveReportCancelButton'}
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>

                <PrimaryButton
                  disabled={
                    !formikProps.values.reportName ||
                    !!formikProps.errors.reportName
                  }
                  type="button"
                  onClick={() => saveReport(formikProps.values.reportName)}
                  data-qa={'SaveReportSaveButton'}
                >
                  <Trans>Save & view</Trans>
                </PrimaryButton>
              </DrawerButtonsContainer>
            </FooterContainer>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

import { StatusCodes } from 'http-status-codes'
import { upperFirst } from 'lodash'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserLevelWithPermissions } from '@lastpass/admin-console-dependencies/types/user-level'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'

const success = (adminLevel: UserLevelWithPermissions) =>
  createResponse(SUCCESS, adminLevel)
const notFound = () => createResponse(NOT_FOUND)

export const Responses = {
  success,
  notFound
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(adminLevelId: string) {
    const [response, status] = await fetch('adminLevels/' + adminLevelId, 'GET')
    if (status === StatusCodes.OK && response) {
      const adminLevel = JSON.parse(response).content

      if (adminLevel.permissions) {
        adminLevel.permissions = adminLevel.permissions.map(upperFirst)
      }

      if (adminLevel.mspCidOverridePermissions) {
        adminLevel.mspCidOverridePermissions = adminLevel.mspCidOverridePermissions.map(
          upperFirst
        )
      }

      return success(adminLevel)
    } else if (status === StatusCodes.NOT_FOUND) {
      return notFound()
    }

    throw new UnknownServiceError()
  }
}

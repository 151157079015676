import React from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { BodyRegularStyle, Heading100 } from '@lastpass/lastkit'
import { CodeExample } from '@lastpass/lastkit/components/CodeExample'

export interface DocumentationProps {
  companyId: string
}

const Title = styled.div`
  ${Heading100};
  margin-bottom: 8px;
`

const TextContent = styled.div`
  ${BodyRegularStyle}
`

export const Documentation: React.FunctionComponent<DocumentationProps> = props => {
  const yourAPILabelString = i18n._(msg`Your API Label`)

  return (
    <>
      <Title>
        <Trans>Downloads</Trans>
      </Title>
      <TextContent>
        <Trans>Download our PowerShell script:</Trans>
      </TextContent>
      <CodeExample
        code={`curl https://lastpass.com/download/EnterpriseApi.ps1 --output EnterpriseApi.ps1`}
        allowCopyToClipboard={true}
      />

      <Title>
        <Trans>URL</Trans>
      </Title>
      <TextContent>
        <Trans>
          Be sure to set your HTTP request{"'"}s content type to
          {"'application/json'"}. All HTTP requests should be POST requests to
          this URL:
        </Trans>
      </TextContent>
      <CodeExample
        code={`https://lastpass.com/enterpriseapi.php`}
        allowCopyToClipboard={true}
      />

      <Title>
        <Trans>Authentication parameters</Trans>
      </Title>
      <TextContent>
        <Trans>
          All requests should contain the following parameters, used for
          authentication:
        </Trans>
      </TextContent>
      <CodeExample
        code={`{
        "cid":"${props.companyId}",
        "provhash":"<Your API Secret>",
  }`}
        allowCopyToClipboard={true}
      />

      <Title>
        <Trans>Additional parameters</Trans>
      </Title>
      <TextContent>
        <Trans>
          Some requests can include additional parameters contained within a
          {"'"}data{"'"} object.
        </Trans>
      </TextContent>
      <CodeExample
        code={`{
    "cid":"${props.companyId}",
    "apiuser":"<${yourAPILabelString}>",
    "cmd": "<cmd>",
    "data": [
        {
            "param": "value"
        }
    ]
}`}
        allowCopyToClipboard={true}
      />

      <Title>
        <Trans>API user</Trans>
      </Title>
      <TextContent>
        <Trans>
          All requests can include an optional apiuser parameter. This helps
          distinguish requests in the event reporting.
        </Trans>
      </TextContent>
      <CodeExample
        code={`{
    "apiuser":"<${yourAPILabelString}>"
}`}
        allowCopyToClipboard={true}
      />
    </>
  )
}

import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  GeneralPolicyAssignUsersAndGroupsActions,
  GeneralPolicyAssignUsersAndGroupsActionTypes
} from './actions'
import { GeneralPolicyAssignUsersAndGroupsFormState } from './state'

export const initialState: GeneralPolicyAssignUsersAndGroupsFormState = {
  policyAssignUsersAndGroups: {
    policyKey: '',
    policyConfigIndex: 0,
    policyConfigFormData: {
      appliesTo: AppliesToOptions.all,
      users: [],
      groups: []
    }
  },
  assignUsersAndGroupsNeedsRefresh: false,
  isAssignUsersAndGroupsOpen: false,
  hasReceivedServerResponse: false,
  policyConfigUsers: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  policyConfigGroups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  toBeAddedGroups: [],
  toBeAddedUsers: []
}

export function generalPolicyAssignUsersAndGroupsReducer(
  previousState: GeneralPolicyAssignUsersAndGroupsFormState | undefined,
  action: GeneralPolicyAssignUsersAndGroupsActions
): GeneralPolicyAssignUsersAndGroupsFormState {
  const state = previousState || initialState
  switch (action.type) {
    case GeneralPolicyAssignUsersAndGroupsActionTypes.RESET: {
      return initialState
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS: {
      return {
        ...state,
        policyAssignUsersAndGroups: {
          policyKey: action.payload.policyAssignUsersAndGroups.policyKey,
          policyConfigIndex:
            action.payload.policyAssignUsersAndGroups.policyConfigIndex,
          policyConfigFormData: {
            ...action.payload.policyAssignUsersAndGroups.policyConfigFormData
          }
        },
        policyConfigUsers: {
          results:
            action.payload.policyAssignUsersAndGroups.policyConfigFormData
              .users,
          totalResults:
            action.payload.policyAssignUsersAndGroups.policyConfigFormData.users
              .length,
          resultsPerPage:
            action.payload.policyAssignUsersAndGroups.policyConfigFormData.users
              .length,
          checkedRecords: state.toBeAddedUsers
        },
        policyConfigGroups: {
          results:
            action.payload.policyAssignUsersAndGroups.policyConfigFormData
              .groups,
          totalResults:
            action.payload.policyAssignUsersAndGroups.policyConfigFormData
              .groups.length,
          resultsPerPage:
            action.payload.policyAssignUsersAndGroups.policyConfigFormData
              .groups.length,
          checkedRecords: state.toBeAddedGroups
        },
        assignUsersAndGroupsNeedsRefresh: true
      }
    }

    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_NEEDS_REFRESH: {
      return {
        ...state,
        policyAssignUsersAndGroups: {
          ...state.policyAssignUsersAndGroups,
          policyKey: action.payload.policyKey,
          policyConfigIndex: action.payload.configKey
        },
        assignUsersAndGroupsNeedsRefresh:
          action.payload.assignUsersAndGroupsNeedsRefresh
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.RESET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_TABLES: {
      return {
        ...state,
        policyConfigUsers: {
          results: [...initialState.policyConfigUsers.results],
          totalResults: initialState.policyConfigUsers.totalResults,
          resultsPerPage: initialState.policyConfigUsers.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        },
        policyConfigGroups: {
          results: [...initialState.policyConfigGroups.results],
          totalResults: initialState.policyConfigGroups.totalResults,
          resultsPerPage: initialState.policyConfigGroups.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        },
        toBeAddedUsers: [],
        toBeAddedGroups: []
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_ASSIGN_POLICY_USERS_LOADING: {
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          isLoading: action.payload
        }
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_ASSIGN_POLICY_USERS: {
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          checkedRecords: state.policyConfigUsers.checkedRecords.concat(
            action.payload.userList
          )
        },
        toBeAddedUsers: state.toBeAddedUsers.concat(action.payload.userList)
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_ASSIGN_POLICY_USERS: {
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          checkedRecords: state.policyConfigUsers.checkedRecords.filter(
            el => !action.payload.userList.includes(el)
          )
        },
        toBeAddedUsers: state.toBeAddedUsers.filter(
          el => !action.payload.userList.includes(el)
        )
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_ASSIGN_POLICY_USERS: {
      let newToBeAddedUsers: TeamsPolicyUserInfo[] = []
      if (action.payload.userList.length === 0) {
        newToBeAddedUsers = state.toBeAddedUsers.filter(
          el => !state.policyConfigUsers.results.includes(el)
        )
      } else {
        const newlySelectedUsers = action.payload.userList.filter(
          el => !state.toBeAddedUsers.includes(el)
        )
        newToBeAddedUsers = [...state.toBeAddedUsers, ...newlySelectedUsers]
      }
      return {
        ...state,
        policyConfigUsers: {
          ...state.policyConfigUsers,
          checkedRecords: [...newToBeAddedUsers]
        },
        toBeAddedUsers: newToBeAddedUsers
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_ASSIGN_POLICY_GROUPS_LOADING: {
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          isLoading: action.payload
        }
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS: {
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          checkedRecords: state.policyConfigGroups.checkedRecords.concat(
            action.payload.groupList
          )
        },
        toBeAddedGroups: state.toBeAddedGroups.concat(action.payload.groupList)
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS: {
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          checkedRecords: state.policyConfigGroups.checkedRecords.filter(
            el => !action.payload.groupList.includes(el)
          )
        },
        toBeAddedGroups: state.toBeAddedGroups.filter(
          el => !action.payload.groupList.includes(el)
        )
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_ASSIGN_POLICY_GROUPS: {
      let newToBeAddedGroups: TeamsPolicyGroup[] = []
      if (action.payload.groupList.length === 0) {
        newToBeAddedGroups = state.toBeAddedGroups.filter(
          el => !state.policyConfigGroups.results.includes(el)
        )
      } else {
        const newlySelectedGroups = action.payload.groupList.filter(
          el => !state.toBeAddedGroups.includes(el)
        )
        newToBeAddedGroups = [...state.toBeAddedGroups, ...newlySelectedGroups]
      }
      return {
        ...state,
        policyConfigGroups: {
          ...state.policyConfigGroups,
          checkedRecords: [...newToBeAddedGroups]
        },
        toBeAddedGroups: newToBeAddedGroups
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_CURRENTLY_APPLIES_TO: {
      return {
        ...state,
        policyCurrentlyAppliesTo: action.payload.policyCurrentlyAppliesTo
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_OPEN: {
      return {
        ...state,
        isAssignUsersAndGroupsOpen: action.payload.isAssignUsersAndGroupsOpen
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.SET_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_RECEIVED_RESPONSE_FROM_SERVER: {
      return {
        ...state,
        hasReceivedServerResponse: action.payload.hasServerResponded
      }
    }
    case GeneralPolicyAssignUsersAndGroupsActionTypes.UPDATE_GENERAL_POLICY_ASSIGN_USERS_AND_GROUPS_TO_BE_ADDED: {
      return {
        ...state,
        toBeAddedUsers: action.payload.toBeAddedUsers,
        toBeAddedGroups: action.payload.toBeAddedGroups
      }
    }
  }
  return state
}

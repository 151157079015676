import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { BodyRegular, Heading100, SeparatorLine } from '@lastpass/lastkit'

import {
  BoldWrapper,
  StyledKeyRotationContainer,
  StyledKeyRotationContent,
  StyledPrimaryButton
} from './StyledComponents'

export const KeyRotationBlock: FunctionComponent<{
  onClick: () => void
  isCompanyWideK1?: boolean
}> = ({ onClick, isCompanyWideK1 = false }) => {
  return (
    <>
      <SeparatorLine />
      <StyledKeyRotationContainer>
        <Heading100>
          <Trans>Rotate knowledge components</Trans>
        </Heading100>
        <StyledKeyRotationContent>
          <BodyRegular>
            <Trans>
              Retire and replace the knowledge components that enable federated
              login through this service.
            </Trans>
            <br />
            {isCompanyWideK1 && (
              <Trans>
                <BoldWrapper>Tip:</BoldWrapper> Be ready to validate your
                current K1 knowledge component (company-wide key) for this
                service.
              </Trans>
            )}
          </BodyRegular>
          <StyledPrimaryButton
            data-qa="RotateKeysButton"
            onClick={onClick}
            type="submit"
          >
            <Trans>Rotate knowledge components</Trans>
          </StyledPrimaryButton>
        </StyledKeyRotationContent>
      </StyledKeyRotationContainer>
    </>
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as PasswordAppsRetiredIcon } from '@lastpass/assets/svg/admin-console/applications/password-apps-retired.svg'
import { BodyRegular, Heading100 } from '@lastpass/lastkit'

const Container = styled.div`
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledHeading100 = styled(Heading100)`
  margin: 16px 0;
`

const StyledLink = styled.a`
  font-weight: 600;
`

export const PasswordAppsPage: React.FunctionComponent = () => {
  const supportPageUrl = 'https://link.lastpass.com/share-passwords-groups'

  return (
    <Container>
      <PasswordAppsRetiredIcon />
      <StyledHeading100>
        <Trans>Password apps functionality has been retired</Trans>
      </StyledHeading100>
      <BodyRegular>
        <Trans>
          <StyledLink href={supportPageUrl} target="_blank">
            Read this article
          </StyledLink>{' '}
          for secure ways to push sites and credentials into your users{"'"}
          vaults.
        </Trans>
      </BodyRegular>
    </Container>
  )
}

import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { ReactComponent as Close } from '@lastpass/assets/svg/admin-console/icon-close.svg'
import { LocationLink, LocationRedirect } from '@lastpass/routing'
import { useDragEffect, useKeyPress } from '@lastpass/ui'

import { BodyRegularStyle } from '../styles/body-text'
import { Heading200 } from './Heading'
import { IconButton } from './IconButton'

export interface DrawerProps {
  mainTitle?: ReactElement
  subTitle?: ReactElement
  miscTitle?: ReactElement
  closeLink: string
  headerNodes?: ReactNode
  onClose?: () => void
  iframedContentUrl?: string
}

export const defaultDrawerWidth = 588
export const minimumDrawerWidth = defaultDrawerWidth
export const maximumDrawerWidth = defaultDrawerWidth + 200

const clampDrawerWidth = (value: number): number =>
  Math.min(Math.max(value, minimumDrawerWidth), maximumDrawerWidth)

const drawerPadding = 24
const resizerBarWidth = 10

const DrawerContainer = styled.div`
  z-index: 999;
  box-shadow: ${props => props.theme.elevations.elevation200};
  background-color: ${props => props.theme.colors.neutral};
  display: flex;
  flex-direction: column;
  bottom: 0px;
  right: 0px;
  top: 0px;
  transform: none;
  width: ${defaultDrawerWidth}px;
  justify-content: space-between;
  align-content: stretch;
  transition: transform 0.2s;
  position: absolute;

  &.route-enter {
    transform: translateX(100%);
  }
  &.route-enter-active {
    transform: none;
  }
  &.route-exit {
    transform: none;
  }
  &.route-exit-active {
    transform: translateX(100%);
  }
`

const DrawerResizerBar = styled.div`
  position: absolute;
  width: ${resizerBarWidth}px;
  height: 100%;
  cursor: col-resize;
  transform: translateX(-${resizerBarWidth / 2}px);
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${drawerPadding}px ${drawerPadding}px ${drawerPadding * 1.25}px
    ${drawerPadding}px;
`

const IFramedTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${drawerPadding}px ${drawerPadding}px 5px ${drawerPadding}px;
  align-self: end;
`

const SubTitle = styled(Heading200)`
  position: absolute;
  top: 6px;
`

const MiscTitle = styled.div`
  ${BodyRegularStyle}
  position: absolute;
  top: 40px;
`

const MainTitleContainer = styled.span`
  flex-basis: 100%;
  position: relative;
`

const HeaderNodesContainer = styled.span`
  white-space: nowrap;
`

const MainTitle = styled(Heading200)<{ hasSubtitle?: boolean }>`
  position: absolute;
  top: ${props => (props.hasSubtitle ? '-18px' : '6px')};
  color: ${props => (props.hasSubtitle ? '#56667B' : '#1D3049')};
  font-size: ${props => (props.hasSubtitle ? '12px' : '24px')};
  transition: top 225ms cubic-bezier(0.25, 0.8, 0.25, 1),
    top 225ms cubic-bezier(0.25, 0.8, 0.25, 1),
    font-size 225ms cubic-bezier(0.25, 0.8, 0.25, 1),
    color 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Footer = styled.div`
  background: ${props => props.theme.colors.blue50};
`

export const StyledFormFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const ButtonsContainer = styled.div`
  width: fit-content;
  margin-left: auto;
  margin: ${drawerPadding}px ${drawerPadding}px ${drawerPadding}px auto;
`
// moz-document: prevent firefox from not scrolling on overflow-y https://bugzilla.mozilla.org/show_bug.cgi?id=1527949
const DrawerChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: ${drawerPadding}px;
  overflow-y: scroll;

  @-moz-document url-prefix() {
    &:after {
      content: '';
      height: ${drawerPadding}px;
      flex-shrink: 0;
    }
  }
`
const IFramedDrawerChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0;

  @-moz-document url-prefix() {
    &:after {
      content: '';
      height: 0;
      flex-shrink: 0;
    }
  }
`

export const DrawerButtonsContainer = styled.div`
  background: ${props => props.theme.colors.blue50};
  padding: ${drawerPadding}px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  line-height: 40px;
`

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 75;
`

const CloseButton = styled(IconButton)`
  margin-left: 16px;
`

export const StyledSpacer = styled.div`
  margin-bottom: 88px;
`

export const DrawerSubtitleContext = React.createContext({
  setSubtitle: (title?: ReactElement) => {
    title
  }
})

export const Drawer: FunctionComponent<DrawerProps> = props => {
  const [subtitle, setSubtitle] = useState<ReactElement | undefined>(
    props.subTitle
  )
  const [redirect, setRedirect] = useState(false)
  const [loadedAlready, setloadedAlready] = useState(false)

  const resizerElementRef = useRef<HTMLDivElement>(null)
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth)
  const [drawerWidthPreview, setDrawerWidthPreview] = useState(drawerWidth)

  const onEscKeyPress = () => {
    if (props.onClose) {
      props.onClose()
    }
    setRedirect(true)
  }

  function ScrollToTopOnMount() {
    useEffect(() => {
      if (!loadedAlready) {
        window.scrollTo(0, 0)
        // for firefox
        const container = document.getElementById('DrawerContainer')

        if (container && container.scrollTo) {
          container.scrollTo(0, 0)
        }
      }
      setloadedAlready(true)
    }, [])

    return null
  }
  const esckeyCode = 27
  useKeyPress(esckeyCode, onEscKeyPress)

  useDragEffect(
    resizerElementRef,
    distance => {
      const newWidth = clampDrawerWidth(drawerWidth - distance.x)
      setDrawerWidth(newWidth)
      setDrawerWidthPreview(newWidth)
    },
    distance => {
      setDrawerWidthPreview(clampDrawerWidth(drawerWidth - distance.x))
    }
  )

  const setFixed = element => {
    if (element && element.style.position !== 'fixed') {
      const scrollableElement = element.parentElement.parentElement
      const scrollableElementPosition = scrollableElement
        ? scrollableElement.getBoundingClientRect()
        : { top: 0 }
      const topOffset = scrollableElementPosition.top
      element.style.position = 'fixed'
      element.style.top = topOffset + 'px'
    }
  }

  return (
    <DrawerContainer
      id={'Drawer'}
      ref={setFixed}
      data-qa={'SideWindowDetailContainer'}
      className={'onboarding-tour-step-3'}
      style={{
        width: `${drawerWidthPreview}px`
      }}
    >
      {redirect ? <LocationRedirect to={props.closeLink} /> : null}
      <DrawerResizerBar data-qa={'DrawerResizer'} ref={resizerElementRef} />
      {props.iframedContentUrl ? (
        <>
          <IFramedTitleContainer>
            <HeaderNodesContainer>
              {props.headerNodes}
              <LocationLink to={props.closeLink}>
                <CloseButton
                  icon={Close}
                  {...props}
                  data-qa="CloseButton"
                  onClick={props.onClose}
                />
              </LocationLink>
            </HeaderNodesContainer>
          </IFramedTitleContainer>
          <IFramedDrawerChildrenContainer id={'DrawerContainer'}>
            <ScrollToTopOnMount />
            <iframe
              data-qa={'IframedDrawer'}
              allow="clipboard-write"
              css={css`
                width: 100%;
                height: 100%;
              `}
              src={props.iframedContentUrl}
            />
          </IFramedDrawerChildrenContainer>
        </>
      ) : (
        <>
          <TitleContainer>
            <MainTitleContainer>
              {subtitle && (
                <SubTitle data-qa={'DrawerTitleSubTitle'}>{subtitle}</SubTitle>
              )}
              <MainTitle data-qa={'DrawerTitle'} hasSubtitle={!!subtitle}>
                {props.mainTitle || '\u2026'}
              </MainTitle>
              {props.miscTitle && <MiscTitle>{props.miscTitle}</MiscTitle>}
            </MainTitleContainer>
            <HeaderNodesContainer>
              {props.headerNodes}
              <LocationLink to={props.closeLink}>
                <CloseButton
                  icon={Close}
                  {...props}
                  data-qa="CloseButton"
                  onClick={props.onClose}
                />
              </LocationLink>
            </HeaderNodesContainer>
          </TitleContainer>
          <DrawerChildrenContainer id={'DrawerContainer'}>
            <ScrollToTopOnMount />
            <DrawerSubtitleContext.Provider
              value={{
                setSubtitle: (title?: ReactElement) => {
                  setSubtitle(title)
                }
              }}
            >
              {props.children}
            </DrawerSubtitleContext.Provider>
          </DrawerChildrenContainer>
        </>
      )}
    </DrawerContainer>
  )
}

export function useDrawerSubtitle(title: ReactElement | undefined) {
  const subtitleContext = useContext(DrawerSubtitleContext)

  useEffect(() => {
    subtitleContext.setSubtitle(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

import { Config } from '@lastpass/admin-console/src/default-config'
// @ts-ignore
import pendoDesignerSrc from '@lastpass-artifactory/pendo-isolation/webpack/loaders/designer.js!./config.js'
// @ts-ignore
import pendoIsolatedSrc from '@lastpass-artifactory/pendo-isolation/webpack/loaders/isolated.js!./config.js'

import * as UACServices from '@lastpass/admin-console-dependencies/server'

interface CreatePendoHandler {
  (uacServices: UACServices.Services, config: Config): {
    initialize: () => void
    cleanup: () => void
  }
}

export const createPendoHandler: CreatePendoHandler = (
  uacServices,
  { isPendoDesigner, pendoSigningKeyName: signingKeyName }
) => {
  let frame: HTMLIFrameElement

  const createScriptElement = (src: string, jwt: string): HTMLScriptElement => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => {
      // @ts-ignore
      window.pendo.initialize({
        jwt,
        signingKeyName
      })
    })

    return script
  }

  const getJWT = async (): Promise<string | null> => {
    const response = await uacServices.getPendoToken()
    if (!response || response.type !== UACServices.getPendoTokenAPI.SUCCESS) {
      return null
    }
    return response.body
  }

  const initializeIsolated = async (): Promise<void> => {
    const jwt = await getJWT()
    if (!jwt) return
    frame = document.createElement('iframe')
    frame.style.display = 'none'
    frame.addEventListener('load', async () => {
      const script = createScriptElement(pendoIsolatedSrc, jwt)
      if (frame && frame.contentDocument) {
        frame.contentDocument.body.appendChild(script)
      }
    })
    document.body.appendChild(frame)
  }

  const initializeDesigner = async (): Promise<void> => {
    const jwt = await getJWT()
    if (!jwt) return
    const script = await createScriptElement(pendoDesignerSrc, jwt)
    document.body.appendChild(script)
  }

  const removeIsolated = (): void => {
    // @ts-ignore
    const pendo = window.pendo
    if (!pendo || !frame) return
    pendo.removeAllBadges()
    pendo.removeLauncher()
    pendo.stopGuides()
    document.body.removeChild(frame)
  }

  const initialize = async (): Promise<void> => {
    isPendoDesigner ? await initializeDesigner() : await initializeIsolated()
  }

  const cleanup = (): void => {
    !isPendoDesigner && removeIsolated()
  }

  return {
    initialize,
    cleanup
  }
}

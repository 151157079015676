import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Papa from 'papaparse'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { decrypt } from '@lastpass/encryption'
import { isCbc as isEncrypted } from '@lastpass/encryption/vault-encryption'

import {
  getUsersWithDecryptedRecoveryKeys,
  UsersWithDecryptedRecoveryKeys
} from '@lastpass/admin-console-dependencies/services/recovery-keys/decrypt-recovery-keys'
import { truncateUrlToHostAndPathname } from '@lastpass/admin-console-dependencies/services/security-reports/truncate-url-to-host-and-pathname'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { useDecryptSecurityReportOnComplete } from './use-decrypt-security-report-on-complete'
import { UrlsInVaultsData } from './use-parse-security-report'

interface DecryptedUrlsInVaultsData extends UrlsInVaultsData {
  Decrypted: 0 | 1
}

export const useDecryptUrlsInVaultsReport = () => {
  const [numberOfDecryptedCsvRows, setNumberOfDecryptedCsvRows] = useState(0)
  const dispatch = useDispatch()
  const onComplete = useDecryptSecurityReportOnComplete()

  const { decryptedPrivateSharingKey, vaultKey, currentUser } = useSelector(
    (state: AppState) => state.global
  )
  const recoveryKeyDecryptionData = useSelector(
    (state: AppState) => state.securityReportDrawer.recoveryKeyDecryptionData
  )

  const parseAndSaveCsv = useCallback(
    async (blob: Blob, abortController: AbortController) => {
      const csvContents: DecryptedUrlsInVaultsData[] = []
      let numberOfUrlsWithDecryptionFailure = 0

      const usersWithDecryptedRecoveryKeys = await getUsersWithDecryptedRecoveryKeys(
        abortController,
        decryptedPrivateSharingKey,
        recoveryKeyDecryptionData.encryptedUserRecoveryKeys
      )

      return Papa.parse(blob, {
        worker: false,
        download: false,
        skipEmptyLines: true,
        fastMode: true,
        header: true,
        chunk: async function(result, parser) {
          parser.pause()

          if (abortController.signal.aborted) {
            parser.abort()
            return
          }
          const rows: UrlsInVaultsData[] = result.data

          for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex]

            const decryptedRow = await decryptUrlsInVaultsRow(
              usersWithDecryptedRecoveryKeys,
              vaultKey,
              row,
              currentUser.email
            )

            if (isEncrypted(decryptedRow.URL)) {
              numberOfUrlsWithDecryptionFailure++
            }

            csvContents.push(decryptedRow)
            setNumberOfDecryptedCsvRows(prevState => prevState + 1)
          }

          parser.resume()
        },
        complete: async function() {
          if (abortController.signal.aborted) {
            return
          }

          onComplete(csvContents, numberOfUrlsWithDecryptionFailure)
          setNumberOfDecryptedCsvRows(0)
        },
        error: function() {
          dispatch(
            globalActions.setDialog({
              type: 'error-decrypting-url-data-dialog'
            })
          )
        }
      })
    },
    [
      currentUser.email,
      decryptedPrivateSharingKey,
      dispatch,
      onComplete,
      recoveryKeyDecryptionData.encryptedUserRecoveryKeys,
      vaultKey
    ]
  )
  return { parseAndSaveCsv, numberOfDecryptedCsvRows }
}

const decryptUrlsInVaultsRow = async (
  usersWithDecryptedRecoveryKeys: UsersWithDecryptedRecoveryKeys,
  vaultKey: Buffer,
  row: UrlsInVaultsData,
  currentUserEmail: string
): Promise<DecryptedUrlsInVaultsData> => {
  let decryptedUrl = ''
  let decryptedColumnValue: DecryptedUrlsInVaultsData['Decrypted'] = 0

  const userRecoveryKey: Buffer = usersWithDecryptedRecoveryKeys[row.Email]

  decryptedUrl =
    currentUserEmail === row.Email
      ? await decrypt(row.URL, vaultKey, 'base64')
      : await decrypt(row.URL, userRecoveryKey, 'base64')

  const isUrlDecrypted = decryptedUrl !== ''

  if (isUrlDecrypted) {
    decryptedUrl = truncateUrlToHostAndPathname(decryptedUrl)
    decryptedColumnValue = 1
  } else {
    decryptedUrl = row.URL
  }

  return {
    Email: row.Email,
    URL: decryptedUrl,
    'Vault Item Identifier': row['"Vault Item Identifier"'],
    Deleted: row.Deleted,
    'Last password change': row['"Last password change"'].replace(/"/g, ''),
    Decrypted: decryptedColumnValue
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = permissionCategories =>
  createResponse(SUCCESS, permissionCategories)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(shouldFilterRoleBasedPermissions: boolean) {
    try {
      const [response, status] = await fetch(
        shouldFilterRoleBasedPermissions
          ? `adminLevels/permissionCategories?roleBasedPermissions=true`
          : 'adminLevels/permissionCategories',
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const responseData = JSON.parse(response)
        const permissionCategories = responseData.content
        return success(permissionCategories)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

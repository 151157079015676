import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'

export function createAccountDetailsSaga(userService: UACServices.Services) {
  return function* getAccountDetailsSaga(
    action: ReturnType<typeof userDrawerActions.getAccountDetails>
  ) {
    try {
      const id = action.payload.params.path.id
      if (id) {
        yield put(userDrawerActions.setAccountDetailsLoading(true))
        try {
          const result: UACServices.UserAccountAPI.Responses = yield call(
            userService.userAccountDetails,
            id
          )
          if (result.type === UACServices.UserAccountAPI.SUCCESS) {
            const accountDetails = result.body
            yield put(userDrawerActions.setAccountDetails(accountDetails))
          }
        } finally {
          yield put(userDrawerActions.setAccountDetailsLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

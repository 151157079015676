import React, { FunctionComponent, useRef } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { TextButton } from '@lastpass/lastkit/components'
import {
  DetailCell,
  DetailsContainer
} from '@lastpass/lastkit/components/DetailCellContainer'
import { LocationLink, ScrollToTop } from '@lastpass/routing'

import { SubscriptionType } from '@lastpass/admin-console-dependencies/state/company/state'
import { AccountDetails as AccountDetailsData } from '@lastpass/admin-console-dependencies/types/account-details'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import { CheckPermission } from '@lastpass/admin-console-dependencies/ui/common/CheckPermission'
import { EnumValues } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { getAccountStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/AccountStatusMapping'
import { DirectoryInfoMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/DirectoryInfoMapping'
import { FederatedStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/FederatedStatusMapping'
import { MFAStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MfaStatusMapping'
import { MultifactorProviderMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorProviderMapping'
import { SecurityScoreLevelMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SecurityScoreLevelMapping'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'

import { UserLevelLabel } from './UserLevelLabel'

interface AccountDetailsProps {
  data: AccountDetailsData
  viewFullLink?: string
  isFederatedLoginEnabled: boolean
}

const StyledLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const AccountDetails: FunctionComponent<AccountDetailsProps> = (
  props: AccountDetailsProps
) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const dataqa = 'AccountDetailContainer'
  const inviteEmailSentTime = props.data.inviteEmailSentTimeUtc
    ? moment(props.data.inviteEmailSentTimeUtc).format('DD MMM YYYY')
    : ''
  const creationTimeUtc = props.data.creationTimeUtc
    ? moment(props.data.creationTimeUtc).format('DD MMM YYYY')
    : ''

  const isFederatedLoginStatusesEnabled = useFeatureFlags(
    FeatureFlags.isFederatedLoginStatusesEnabled
  )

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )
  const accountStatusMapping = getAccountStatusMapping(isAccountStatusRenamed)

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )

  return (
    <>
      <ScrollToTop elementRef={containerRef} />
      <DetailsContainer ref={containerRef} data-qa={dataqa}>
        <DetailCell
          qa="account-detail-last-pass-status"
          field={<Trans>LastPass status</Trans>}
          value={
            <>
              {accountStatusMapping[props.data.accountStatus]}
              {props.data.accountStatus === AccountStatus.invited &&
                ` - ${inviteEmailSentTime}`}
            </>
          }
        />
        {!isNoPasswordDeprecated && !isNpPasswordlessFeatureDisabled && (
          <CheckPermission
            requiredSubscription={[
              SubscriptionType.iDaaS,
              SubscriptionType.mfa
            ]}
          >
            <DetailCell
              qa="account-detail-passwordless-status"
              field={<Trans>Passwordless status</Trans>}
              value={MFAStatusMapping[props.data.mfaStatus]}
            />
          </CheckPermission>
        )}
        <DetailCell
          qa="account-detail-enabled-multifactor"
          field={<Trans>Enabled multifactor</Trans>}
          value={
            props.data.defaultMultiFactorType
              ? MultifactorProviderMapping[props.data.defaultMultiFactorType]
              : '-'
          }
        />
        <DetailCell
          qa="account-detail-security-score"
          field={<Trans>Security score</Trans>}
          value={SecurityScoreLevelMapping[props.data.securityScoreLevel]}
        />
        <DetailCell
          qa="account-detail-user-created"
          field={<Trans>User created</Trans>}
          value={creationTimeUtc}
        />
        <DetailCell
          qa="account-detail-last-login"
          field={<Trans>Last login</Trans>}
          value={
            <TimeSinceActivity lastActivityTime={props.data.lastLoginTimeUtc} />
          }
        />
        <DetailCell
          qa="account-detail-directory"
          field={<Trans>Directory</Trans>}
          value={
            <EnumValues
              values={props.data.directoryInfo}
              mapping={DirectoryInfoMapping}
              delimiter={<br />}
            />
          }
        />
        <DetailCell
          qa="account-detail-linked-personal-account"
          field={<Trans>Linked personal account</Trans>}
          value={props.data.linkedAccountEnabled ? 'Enabled' : 'Disabled'}
        />
        <DetailCell
          qa="account-detail-user-level"
          field={<Trans>User level</Trans>}
          value={<UserLevelLabel userLevel={props.data.adminLevel} />}
        />
        {isFederatedLoginStatusesEnabled && props.isFederatedLoginEnabled && (
          <DetailCell
            qa="account-detail-federated-status"
            field={<Trans>Federated status</Trans>}
            value={FederatedStatusMapping[props.data.provisionFederatedStatus]}
          />
        )}
      </DetailsContainer>

      {props.viewFullLink && (
        <div
          css={css`
            text-align: right;
          `}
        >
          <TextButton blue>
            <StyledLink
              to={props.viewFullLink}
              data-qa={'ViewFullAccountDetailsButton'}
            >
              <Trans>View full account details</Trans>
            </StyledLink>
          </TextButton>
        </div>
      )}
    </>
  )
}

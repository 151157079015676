import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { AnimatedSwitch } from '@lastpass/routing'

import {
  SecurityReportDrawerFactory,
  SecurityReportDrawerFactoryProps
} from '@lastpass/admin-console-dependencies/ui/reports/security/SecurityReportDrawerFactory'
import { SecurityReportList } from '@lastpass/admin-console-dependencies/ui/reports/security/SecurityReportList'

export function SecurityReportPage() {
  const match = useRouteMatch()

  if (match) {
    const { url, path } = match
    const drawerPath = `${path}/:id`
    const drawerProps: SecurityReportDrawerFactoryProps = {
      closeLink: url
    }
    return (
      <>
        <SecurityReportList />
        <AnimatedSwitch timeout={200}>
          <Route path={drawerPath}>
            <SecurityReportDrawerFactory {...drawerProps} />
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

import React, { FunctionComponent, useRef, useState } from 'react'
import ReactSelect, {
  ActionMeta,
  components,
  Props,
  ValueType
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectComponents } from 'react-select/src/components'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { Plural, Trans } from '@lingui/macro'

import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/icon-close.svg'
import { Chevron } from '@lastpass/components'
import { useOutsideClick } from '@lastpass/ui'

import { BodyRegularStyle, BodySemiboldStyle } from '../styles'
import { Theme as theme } from '../theme'
import { Checkbox } from './Checkbox'
import { Collapsible } from './Collapsible'
import { SearchInput } from './SearchInput'

interface AutocompleteProps extends Props {
  fluid?: boolean
  width?: string
  icon?: React.ComponentType
  dataQa?: string
  noOptionsCustomMessage?: string
  searchPlaceholder?: MessageDescriptor
}

export interface AutocompleteOption {
  value: string
  label: string
}

type onRemove = (
  newValue: ValueType<AutocompleteOption>,
  actionObject: ActionMeta & { removedItem: AutocompleteOption }
) => void

const autocompleteCustomStyles = {
  container: provided => ({
    ...provided,
    height: '40px',
    borderRadius: '0',
    fontSize: '14px',
    fontWeight: '400',
    '&:focus': {
      backgroundColor: theme.colors.blue200
    }
  }),
  control: (provided, state) => ({
    ...provided,
    maxHeight: '40px',
    height: '40px',
    boxShadow: state.isFocused ? '0 0 2px 1px' + theme.colors.blue500 : 'none',
    border: '1px solid ' + theme.colors.neutral400
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'red'
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: provided => ({ ...provided }),
  option: (provided, state) =>
    state.isMulti
      ? {
          ...provided,
          backgroundColor: theme.colors.white,
          cursor: 'pointer',
          paddingLeft: '12px'
        }
      : {
          ...provided,
          backgroundColor: state.isSelected
            ? theme.colors.neutral700
            : provided.backgroundColor,
          cursor: 'pointer',
          '&:focus': {
            backgroundColor: theme.colors.blue200
          }
        },
  menu: provided => ({
    ...provided,
    marginTop: '4px',
    zIndex: '80',
    padding: '8px 0 0 0'
  }),
  menuList: provided => ({
    ...provided,
    overflowX: 'hidden'
  }),
  valueContainer: provided => ({
    ...provided,
    marginTop: '-2px'
  })
}

const SelectedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 5px;
`

const SelectedItem = styled.div`
  padding: 0 8px;
  background: ${props => props.theme.colors.blue100};
  border-radius: 20px;
  color: ${props => props.theme.colors.neutral900};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
`

const SelectedItemRemoveButton = styled.button`
  all: unset;
  margin-left: 6px;
  color: ${props => props.theme.colors.neutral700};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.black};
  }
`

const SelectedItemRemoveIcon = styled(CloseIcon)`
  height: 10px;
  width: 10px;
`

const SearchInputContainer = styled.div`
  margin: 0 12px;
`

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  margin: 0;
`

const CollapsibleContainer = styled.div`
  margin-left: 12px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral100};
  box-shadow: unset;
`

const DropdownPointerWrapper = styled.div`
  cursor: pointer;
`

const AutocompleteContainer = styled.div<{ fluid?: boolean; width?: string }>`
  width: 230px;
  ${props => props.fluid && 'width: 100%'};
  ${props => props.width && 'width:' + props.width};
`

const StyledLabel = styled.label`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

const StyledReset = styled.span`
  ${BodySemiboldStyle};
  cursor: pointer;
  color: ${props => props.theme.colors.blue700};
`

const StyledBodyRegular = styled.span`
  ${BodyRegularStyle};
  margin-left: 8px;
  color: ${props => props.theme.colors.neutral900};
`

const SelectedItems = ({
  values,
  onChange
}: {
  values: AutocompleteOption[]
  onChange: onRemove
}) => {
  const handleRemoveItem = e => {
    const { name: itemName } = e.currentTarget
    const removedItem = values.find(val => val.value === itemName)
    removedItem &&
      onChange(
        values.filter(val => val.value !== itemName),
        {
          action: 'remove-value',
          removedItem
        }
      )
  }

  return (
    <SelectedItemContainer>
      {values.map(val => (
        <SelectedItem key={val.value}>
          {val.label}
          <SelectedItemRemoveButton
            data-qa={'RemoveItemButton'}
            name={val.value}
            onClick={handleRemoveItem}
          >
            <SelectedItemRemoveIcon />
          </SelectedItemRemoveButton>
        </SelectedItem>
      ))}
    </SelectedItemContainer>
  )
}

const MenuList: SelectComponents<AutocompleteOption>['MenuList'] = props => {
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    searchPlaceholder,
    value,
    onChange
  } = props.selectProps

  return (
    <>
      <SearchInputContainer>
        <StyledSearchInput
          autoFocus
          placeholder={searchPlaceholder}
          value={inputValue}
          preventDebounce
          onChange={e =>
            onInputChange &&
            onInputChange(e.target.value, {
              action: 'input-change'
            })
          }
          onMouseDown={e => {
            e.stopPropagation()
          }}
          onTouchEnd={e => {
            e.stopPropagation()
          }}
          onFocus={onMenuInputFocus}
        />
      </SearchInputContainer>
      <components.MenuList {...props}>
        {props.hasValue && props.isMulti && (
          <CollapsibleContainer>
            <Collapsible
              expanded
              title={<Trans>Active filters</Trans>}
              scrollToView={false}
              compact
            >
              <SelectedItems
                values={value as AutocompleteOption[]}
                onChange={onChange as onRemove}
              />
            </Collapsible>
          </CollapsibleContainer>
        )}
        {props.children}
      </components.MenuList>
    </>
  )
}

const ClearIndicator: SelectComponents<
  AutocompleteOption
>['ClearIndicator'] = props => (
  <components.ClearIndicator {...props}>
    <StyledReset data-qa={'ResetButtonInDropdownFilter'}>
      <Trans>Reset</Trans>
    </StyledReset>
  </components.ClearIndicator>
)

const DropdownIndicator: SelectComponents<
  AutocompleteOption
>['DropdownIndicator'] = props => (
  <components.DropdownIndicator {...props}>
    <DropdownPointerWrapper>
      <Chevron
        color={theme.colors.neutral700}
        direction={props.selectProps.menuIsOpen ? 'up' : 'down'}
        data-qa={'DropdownFilterChevron'}
      />
    </DropdownPointerWrapper>
  </components.DropdownIndicator>
)

const NoOptionsMessage: SelectComponents<
  AutocompleteOption
>['NoOptionsMessage'] = props => {
  const { isAsync, inputValue, noOptionsCustomMessage } = props.selectProps
  const message = noOptionsCustomMessage || <Trans>No options</Trans>

  return (
    <components.NoOptionsMessage {...props}>
      {!isAsync || inputValue ? message : <Trans>Start typing...</Trans>}
    </components.NoOptionsMessage>
  )
}

const LoadingMessage: SelectComponents<
  AutocompleteOption
>['LoadingMessage'] = props => (
  <components.LoadingMessage {...props}>
    <Trans>Loading...</Trans>
  </components.LoadingMessage>
)

const Option: SelectComponents<AutocompleteOption>['Option'] = props => {
  if (props.isMulti) {
    return (
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          controlled
          data-qa={'CheckboxInDropdownFilter'}
        />
        <StyledBodyRegular data-qa={'DropdownFilterOption'}>
          {props.children}
        </StyledBodyRegular>
      </components.Option>
    )
  }
  return <components.Option {...props}>{props.children}</components.Option>
}

const Placeholder: SelectComponents<
  AutocompleteOption
>['Placeholder'] = props => (
  <components.Placeholder {...props}>
    {props.selectProps.placeholder || <Trans>Options...</Trans>}
  </components.Placeholder>
)

const ValueContainer: SelectComponents<
  AutocompleteOption
>['ValueContainer'] = props => {
  if (props.isMulti) {
    const selectedElements = props.getValue() as AutocompleteOption[]

    const filterText = (
      <Plural
        value={selectedElements.length}
        one="# filter active"
        other="# filters active"
      />
    )

    const searchInput = React.Children.toArray(props.children).pop()

    return (
      <components.ValueContainer {...props}>
        <StyledBodyRegular
          data-qa={'ActiveFilterInDropdown'}
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {selectedElements.length ? filterText : props.children}
        </StyledBodyRegular>
        {searchInput}
      </components.ValueContainer>
    )
  }
  return (
    <components.ValueContainer {...props}>
      <span data-qa={'SingleValue'}>{props.children}</span>
    </components.ValueContainer>
  )
}

export const Autocomplete: FunctionComponent<AutocompleteProps> = props => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const onOutsideClick = () => {
    setMenuIsOpen(false)
    setInputValue('')
  }
  useOutsideClick(containerRef, onOutsideClick, true)

  const Control: SelectComponents<AutocompleteOption>['Control'] = props => (
    <div
      onClick={event =>
        event.target['tagName'] !== 'INPUT' && setMenuIsOpen(false)
      }
    >
      <components.Control {...props} />
    </div>
  )

  const selectProps = {
    styles: autocompleteCustomStyles,
    components: {
      Control,
      ClearIndicator,
      DropdownIndicator,
      LoadingMessage,
      MenuList,
      NoOptionsMessage,
      Option,
      Placeholder,
      ValueContainer
    },
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    backspaceRemovesValue: false,
    inputValue,
    onInputChange: val => setInputValue(val),
    isSearchable: false,
    onMenuOpen: () => {
      setMenuIsOpen(true)
      // react-select has an internal stopPropagation on mouse click events which prevents the
      // outsideClick handler to detect if another autocomplete component is opened. This little
      // hack triggers a new click event that can be caught by the useOutsideClick hook.
      containerRef && containerRef.current && containerRef.current.click()
    },
    menuIsOpen: menuIsOpen,
    isFocused: menuIsOpen,
    openMenuOnClick: true,
    ...props,
    onChange: (newValue, actionMeta) => {
      props.closeMenuOnSelect && setMenuIsOpen(false)
      props.onChange && props.onChange(newValue, actionMeta)
    }
  }

  return (
    <AutocompleteContainer
      fluid={props.fluid}
      width={props.width}
      data-qa={props.dataQa}
    >
      <StyledLabel>
        {props.children}
        <div ref={containerRef}>
          {props.loadOptions ? (
            <AsyncSelect
              isAsync
              cacheOptions
              loadOptions={props.loadOptions}
              {...selectProps}
            />
          ) : (
            <ReactSelect {...selectProps} />
          )}
        </div>
      </StyledLabel>
    </AutocompleteContainer>
  )
}

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { SecureAuthSettingsState } from './state'

export enum SecureAuthSettingsActionTypes {
  SECURE_AUTH_SETTINGS_GET = '[secure-auth-settings] get',
  SECURE_AUTH_SETTINGS_SET = '[secure-auth-settings] set',
  SECURE_AUTH_SETTINGS_SAVE = '[secure-auth-settings] save',
  SECURE_AUTH_SETTINGS_SET_LOADING = '[secure-auth-settings] set-loading'
}

export const secureAuthSettingsActions = {
  getSecureAuthSettings: () =>
    createAction(SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_GET),
  setSecureAuthSettings: (secureAuthSettings: SecureAuthSettingsState) =>
    createAction(SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_SET, {
      secureAuthSettings
    }),
  setSecureAuthSettingsLoading: (loading: boolean) =>
    createAction(
      SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_SET_LOADING,
      {
        loading
      }
    ),
  saveSecureAuthSettings: (secureAuthSettings: SecureAuthSettingsState) =>
    createAction(SecureAuthSettingsActionTypes.SECURE_AUTH_SETTINGS_SAVE, {
      secureAuthSettings
    })
}

export type SecureAuthSettingsActions = ActionsUnion<
  typeof secureAuthSettingsActions
>

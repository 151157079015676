import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegularStyle } from '@lastpass/lastkit'

import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'

export const DescriptionBlock = styled.div`
  ${BodyRegularStyle};
`

interface AdminLevelDescriptionProps {
  description: string | React.ReactElement
  adminLevelId: string
}

export const AdminLevelDescription: React.FunctionComponent<AdminLevelDescriptionProps> = ({
  description,
  adminLevelId
}) => {
  switch (adminLevelId) {
    case AdminLevel.admin:
      description = (
        <Trans>
          Admins can access the entire Admin Console and they have extensive
          rights to deploy, configure, and manage LastPass.
        </Trans>
      )
      break
    case AdminLevel.superAdmin:
      description = (
        <Trans>
          Super admins can reset the master password of any user in your
          account, and they have extensive rights to deploy, configure, and
          manage LastPass. They also have access to all shared folders in your
          account.
        </Trans>
      )
      break
    case AdminLevel.helpdeskAdmin:
    case AdminLevel.legacyHelpdeskAdmin:
      description = (
        <Trans>
          Helpdesk admins have limited rights. They can reset master passwords
          for users (but not admins), destroy user sessions, and view the Users
          and Groups pages.
        </Trans>
      )
      break
  }

  return (
    <DescriptionBlock data-qa="AdminLevelDescription">
      {description}
    </DescriptionBlock>
  )
}

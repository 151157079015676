import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import { takeDialogInteractions } from '@lastpass/admin-console-dependencies/sagas/utils/take-dialog-interactions'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import {
  ConfirmDialog,
  IterationCountDestroySessionsDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'
import { UserBase } from '@lastpass/admin-console-dependencies/types/user'

import { invokeApi } from './operation-base'

type Action =
  | ReturnType<typeof userOperationActions.destroyAllSessionsForSelectedUsers>
  | ReturnType<
      typeof userOperationActions.destroyAllSessionsForIterationCountPolicy
    >

const iterationCountDestroySessionsDialog: IterationCountDestroySessionsDialog = {
  type: 'iteration-count-destroy-sessions-dialog'
}

const createConfirmDialog = (users: UserBase[]): ConfirmDialog => {
  const isSingleUser = users.length === 1
  const text: MessageDescriptor = isSingleUser
    ? msg`Destroy all LastPass sessions for this user? ${users[0].email}`
    : msg`Destroy all LastPass sessions for the selected users?`

  return {
    type: 'confirmdialog',
    title: msg`Destroy all sessions`,
    text,
    discardText: msg`Cancel`,
    confirmText: msg`Destroy sessions`
  }
}

export function createDestroyAllSessionsForSelectedUsersSaga(
  userService: UACServices.Services
) {
  return function* destroyAllSessionsForSelectedUsersSaga(action: Action) {
    try {
      const users = action.payload.userOperationPayload.users

      const dialog: IterationCountDestroySessionsDialog | ConfirmDialog =
        action.type ===
        UserOperationActionTypes.DESTROY_ALL_SESSIONS_FOR_ITERATION_COUNT_POLICY
          ? iterationCountDestroySessionsDialog
          : createConfirmDialog(users)
      yield put(globalActions.setDialog(dialog))

      const { isConfirmed, isDiscarded } = yield call(takeDialogInteractions)

      if (isDiscarded) return

      if (isConfirmed) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users have been logged out of active sessions.`,
            MultipleUsersSuccess: msg`The selected users have been logged out of their active sessions.`,
            SingleUserFail:
              users.length > 0
                ? msg`User hasn't been logged out of active sessions: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User has been logged out of all active sessions: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users haven't been logged out of their active sessions.`
          },
          'destroyallsessions',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.DESTROY_ALL_SESSIONS,
          action.payload.userOperationPayload.filter
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

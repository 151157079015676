import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import {
  BodyBold,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { createQueryStringForNamespace } from '@lastpass/admin-console-dependencies/services/routing/create-query-string-for-namespace'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { EditVaultReEncryptionDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`

const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
`

interface EditVaultReEncryptionDialogProps {
  state: EditVaultReEncryptionDialog
}

export const EditVaultReEncryptionDialogComponent: FunctionComponent<EditVaultReEncryptionDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      disableMaxHeight
      title={msg`Edit vault re-encryption settings`}
      content={
        <>
          <TextBoxContainer>
            <Trans>
              Protect your users by re-encrypting their vault and rotating their
              K2 knowledge component. This helps ensure that information stays
              safe even if user credentials are compromised.
            </Trans>
            <br />
            <StyledExternalLink
              href="https://link.lastpass.com/recurring_vault_re_encryption"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Learn more about vault re-encryption</Trans>
            </StyledExternalLink>
          </TextBoxContainer>
          <TextBoxContainer>
            <ul>
              <li>
                <Trans>
                  <BodyBold>Status: </BodyBold>
                </Trans>
                {state.automatedVaultReEncryptionPolicy.isEnabled && (
                  <Trans>Enabled</Trans>
                )}
              </li>
              <li>
                <Trans>
                  <BodyBold>Applies to: </BodyBold>
                </Trans>
                {state.automatedVaultReEncryptionPolicy.isAllUser ? (
                  <Trans>All users</Trans>
                ) : (
                  <Trans>Only certain users/groups</Trans>
                )}
              </li>
              <li>
                <Trans>
                  <BodyBold>Recurrence interval: </BodyBold>
                  {state.automatedVaultReEncryptionPolicy.value} days
                </Trans>
              </li>
            </ul>
          </TextBoxContainer>
          <TextBoxContainer>
            <Trans>
              To view and modify these settings, go to the Policies page.
            </Trans>
          </TextBoxContainer>
        </>
      }
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(globalActions.discardDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <Link
          to={`/policies/generalpolicies/recurringfederatedvaultreencryption?${createQueryStringForNamespace(
            Namespace.policies,
            {
              search: 'Recurring vault re-encryption'
            }
          )}`}
          key="confirm"
        >
          <PrimaryButton
            data-qa="PopupSubmit"
            onClick={() => {
              dispatch(globalActions.confirmDialog())
            }}
            type="submit"
          >
            <Trans>Go to Policies</Trans>
          </PrimaryButton>
        </Link>
      ]}
    />
  )
}

import { sha256 } from '@lastpass/encryption/sha'

interface Cache {
  key: Buffer
  value: string
}

let cache: Cache[] = []
let autoCleanupTimer

export const createFragmentId = async (k1: Buffer): Promise<string> => {
  // in case of company-wide k1, every user have the same k1. for multiple users it is
  // about 5 times faster if the last 5 hashes are cached
  const cached = cache.find(
    cacheItem => Buffer.compare(k1, cacheItem.key) === 0
  )
  if (cached) {
    return cached.value
  }

  const h1HashBuffer = await sha256(k1)
  const k1Hash = Buffer.from(h1HashBuffer).toString('base64')

  cache.push({
    key: k1,
    value: k1Hash
  })
  cache = cache.slice(-5)

  // clean up the cache in 1 seconds
  // in bulk mode 15k users can be key-rotated in 200ms
  if (!autoCleanupTimer) {
    autoCleanupTimer = setTimeout(() => {
      autoCleanupTimer = null
      cache = []
    }, 1000)
  }

  return k1Hash
}

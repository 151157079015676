import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  EditApplicationResponseContainer,
  SelectedApplication
} from '@lastpass/admin-console-dependencies/state/applications/application'

export const SUCCESS = 'success'

const success = (response: EditApplicationResponseContainer) =>
  createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service(app: SelectedApplication) {
    try {
      const [response, status] = await identityApi(
        `api/v2/Apps`,
        app.id ? 'PUT' : 'POST',
        app,
        undefined,
        true,
        true
      )
      if (status === StatusCodes.OK && response) {
        const applicationsResponse = JSON.parse(response)
        return success(applicationsResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React, { createContext, FunctionComponent, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { Loading } from '@lastpass/components'
import { Drawer } from '@lastpass/lastkit'
import { IconButton } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { StyledDrawerOverlay } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/StyledDrawerOverlay'

const StyledPsaDrawerContent = styled.div`
  #DrawerContainer:has(&) {
    overflow: hidden;
    padding-right: 0;
    padding-top: 0;
  }
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;
  height: calc(100% - 64px);
`

const StyledContainer = styled.div`
  ${BodyRegularStyle}
  margin: 0px 0 24px 0;
`
export const PsaDrawerContext = createContext({
  closeDrawer: () => {},
  closeLink: ''
})

export interface PsaDrawerProps {
  closeLink: string
  backLink?: string
  title: ReactElement
  description?: ReactElement
  dataQa: string
  onCloseDrawer?: () => void
}

export const PsaDrawer: FunctionComponent<PsaDrawerProps> = props => {
  const { drawerInProgress, drawerContainsUnsavedChanges } = useSelector(
    (state: AppState) => state.psaIntegrations
  )
  const {
    closeLink,
    backLink,
    children,
    dataQa,
    description,
    title,
    onCloseDrawer
  } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const handleOnClose = () => {
    dispatch(psaIntegrationsActions.setDrawerContainsUnsavedChanges(false))

    if (onCloseDrawer) {
      onCloseDrawer()
    }

    history.push(closeLink)
  }

  const handleOnBack = () => {
    if (backLink) {
      dispatch(
        psaIntegrationsActions.navigatePsaDrawer(
          backLink,
          drawerContainsUnsavedChanges
        )
      )
    }
  }

  const renderBackButton = () => {
    return backLink ? (
      <IconButton
        icon={ChevronLeft}
        data-qa="BackButton"
        onClick={handleOnBack}
      />
    ) : null
  }

  return (
    <>
      <StyledDrawerOverlay onClose={handleOnClose} />
      <Drawer
        mainTitle={title}
        closeLink={closeLink}
        onClose={handleOnClose}
        headerNodes={renderBackButton()}
      >
        <StyledPsaDrawerContent data-qa={dataQa}>
          {description && <StyledContainer>{description}</StyledContainer>}

          {drawerInProgress && <Loading color="blue900" active={true} />}

          <PsaDrawerContext.Provider
            value={{ closeDrawer: handleOnClose, closeLink: closeLink }}
          >
            {children}
          </PsaDrawerContext.Provider>
        </StyledPsaDrawerContent>
      </Drawer>
    </>
  )
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as SamlResponseIcon } from '@lastpass/assets/svg/admin-console/icon-saml.svg'
import { Loading } from '@lastpass/components'
import {
  Table as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { samlResponsesActions } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'
import { SamlResponsesQueryParams } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'
import { SamlResponse } from '@lastpass/admin-console-dependencies/types/saml-response'
import { Table } from '@lastpass/admin-console-dependencies/types/table'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'

interface SamlResponsesTableProps {
  samlListTable: Table<SamlResponse>
  queryParams: SamlResponsesQueryParams
}

export const SamlResponsesTable: React.FunctionComponent<SamlResponsesTableProps> = ({
  samlListTable,
  queryParams
}) => {
  const dispatch = useDispatch()
  const updateQuery = useUpdateQuery(Namespace.samlResponses)

  useEffect(() => {
    dispatch(
      samlResponsesActions.getSamlResponses({ query: queryParams, path: {} })
    )
  }, [dispatch, queryParams])

  return (
    <TableComponent
      table={samlListTable}
      noRecordsView={
        <TableView
          icon={<SamlResponseIcon data-qa="NoSamlResponseIcon" />}
          title={<Trans>No SAML Responses yet</Trans>}
        />
      }
      noResultsView={
        <TableView
          icon={<SamlResponseIcon data-qa="NoSamlResponseIcon" />}
          title={<Trans>Sorry, no results match your search</Trans>}
          text={<Trans>Try different keywords or check your spelling</Trans>}
          actionButton={
            <TableViewButton
              onClick={() => {
                updateQuery({
                  search: undefined,
                  filter: undefined
                })
              }}
              data-qa="ClearSearchButton"
            >
              <Trans>Clear search query</Trans>
            </TableViewButton>
          }
        />
      }
      loadingView={<Loading color="blue900" active={true} />}
      columns={[
        {
          name: <Trans>User</Trans>,
          renderer: (record: SamlResponse) => record.Username
        },
        {
          name: <Trans>Application</Trans>,
          renderer: (record: SamlResponse) => record.AppName
        },
        {
          name: <Trans>IP address</Trans>,
          renderer: (record: SamlResponse) => record.IPAddress
        },
        {
          name: <Trans>Time</Trans>,
          // eslint-disable-next-line react/display-name
          renderer: (record: SamlResponse) => (
            <TimeSinceActivity lastActivityTime={record.Timestamp} />
          )
        }
      ]}
      isFilterApplied={Boolean(queryParams.search)}
    />
  )
}

import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { AdfsSetupState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getOpenIdIsEnabled = (state: AppState) =>
  state.federatedLogin.openId.isEnabled

const getErrorMessage = (reason: string, isPingFederate: boolean) => {
  if (reason === 'Please enter a valid provider url and public key') {
    return msg`Please enter a valid provider url and public key.`
  }

  if (
    reason ===
    'You must enable the policy "Permit super admins to reset master passwords" before enabling federated login.'
  ) {
    return msg`You must enable the policy "Permit super admins to reset master passwords" before enabling federated login.`
  }

  if (
    reason ===
    "Only the 'Provider URL' and 'Public key' can be changed while you have federated users."
  ) {
    return msg`Only the 'Provider URL' and 'Public key' can be changed while you have federated users.`
  }

  if (
    reason ===
    'Federated login with OpenID Connect has already been enabled. Please disable it to proceed.'
  ) {
    return msg`Federated login with OpenID Connect has already been enabled. Please disable it to proceed.`
  }

  return isPingFederate
    ? msg`PingFederate setup failed.`
    : msg`ADFS setup failed.`
}

export function createSaveAdfsSetup(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveAdfsSetup(
    action: ReturnType<typeof federatedLoginActions.saveAdfsSetup>
  ) {
    const isOpenIdEnabled: boolean = yield select(getOpenIdIsEnabled)
    const adfsSetup: AdfsSetupState = action.payload.adfsSetup

    if (isOpenIdEnabled && adfsSetup.isEnabled) {
      yield put(
        globalActions.setNotification({
          message: msg`Federated login with OpenID Connect has already been enabled. Please disable it to proceed.`,
          type: NotificationType.warning,
          autoDismiss: false
        })
      )
      return
    }

    try {
      yield put(federatedLoginActions.setLoading(true))
      try {
        const adfsSetupResult: UACServices.AdfsSetupSaveAPI.Responses = yield call(
          enterpriseOptionServices.adfsSetupSave,
          adfsSetup
        )
        if (adfsSetupResult.type === UACServices.AdfsSetupSaveAPI.SUCCESS) {
          yield put(federatedLoginActions.getAdfsSetup())
          yield put(
            globalActions.setNotification({
              message: adfsSetup.isPingFederate
                ? msg`PingFederate setup successful`
                : msg`ADFS setup successful`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: getErrorMessage(
                adfsSetupResult.type,
                adfsSetup.isPingFederate
              ),
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(federatedLoginActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { AddUsersDrawerActions, AddUsersDrawerActionTypes } from './actions'
import { AddUsersDrawerState } from './state'

export const initialState: AddUsersDrawerState = {
  pendingAddUsers: [],
  validatedNewUsers: [],
  passwordManagementInvite: true,
  lastPassMfaInvite: false
}

export function addUsersDrawerReducer(
  previousState: AddUsersDrawerState | undefined,
  action: AddUsersDrawerActions
): AddUsersDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case AddUsersDrawerActionTypes.RESET: {
      return initialState
    }
    case AddUsersDrawerActionTypes.SET_PENDING_ADD_USERS: {
      const newState = {
        ...state,
        pendingAddUsers: action.payload.pendingAddUsers
      }
      return newState
    }
    case AddUsersDrawerActionTypes.SET_NEW_ADD_USERS: {
      const newState = {
        ...state,
        validatedNewUsers: action.payload.newAddUsers
      }
      return newState
    }
    case AddUsersDrawerActionTypes.CLEAR_PENDING_ADD_USERS: {
      return initialState
    }
    case AddUsersDrawerActionTypes.SET_MFA_INVITE: {
      return {
        ...state,
        lastPassMfaInvite: action.payload
      }
    }
    case AddUsersDrawerActionTypes.SET_LP_INVITE: {
      return {
        ...state,
        passwordManagementInvite: action.payload
      }
    }
  }
  return state
}

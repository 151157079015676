import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as SsoIcon } from '@lastpass/assets/svg/admin-console/policies/single-sign-on.svg'
import { ReactComponent as VaultIcon } from '@lastpass/assets/svg/admin-console/policies/vault.svg'
import { ReactComponent as WorkstationLoginIcon } from '@lastpass/assets/svg/admin-console/policies/workstation-login.svg'
import {
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;

  hr {
    border-top: none;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
    margin: 24px 0;
  }
`

const Link = styled.a`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const Bold = styled.span`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

const OrderedList = styled.ol`
  margin: 17px 0;
  counter-reset: howToEnrollList;

  li {
    counter-increment: howToEnrollList;
    margin-top: 16px;
    padding-left: 32px;
    position: relative;
  }

  li:before {
    content: counters(howToEnrollList, '.') ' ';
    background: ${props => props.theme.colors.blue200};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    font-weight: 600;
    margin-right: 8px;
    position: absolute;
    left: 0;
    top: 0;
  }
`

const ProtectedServicesListItem = styled.div`
  position: relative;
  margin-top: 16px;
  padding-left: 36px;
`

const ProtectedServicesIcon = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
`

const ProtectedServiceHeader = styled.h6`
  font-weight: ${props => props.theme.fonts.weight.semibold};
  line-height: 20px;
  margin-bottom: 2px;
`

const ImportantNoteContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export interface MultifactorDescriptionProps {
  closeLink: string
}

export const MultifactorDescription: React.FunctionComponent<MultifactorDescriptionProps> = ({
  closeLink
}) => {
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(multifactorDrawerActions.multifactorSetupInstructionsViewed())
  }, [dispatch])

  return (
    <MainContainer>
      <Heading100>
        <Trans>How to enroll users</Trans>
      </Heading100>
      <OrderedList>
        <li>
          <Trans>
            You choose the multifactor methods right for your organization.
          </Trans>
        </li>
        <li>
          <Trans>You assign users {'&'} groups to the policy.</Trans>
        </li>
        <li>
          <Trans>
            Users complete enrollment by choosing one of your required
            multifactor methods the next time they log in to a protected
            service.
          </Trans>
        </li>
      </OrderedList>
      <hr />
      <Heading100>
        <Trans>Protected services</Trans>
      </Heading100>
      <ImportantNoteContainer>
        <Trans>
          <Bold>Important:</Bold> This policy doesn’t apply to federated users.
        </Trans>
      </ImportantNoteContainer>
      <div>
        <Trans>
          Once enrolled, users must verify their identity using multifactor
          authentication each time they log in to a protected service.
        </Trans>{' '}
        <Link
          data-qa="MultifactorPoliciesLearnMoreLink"
          href="https://link.lastpass.com/mfa-policies"
          target="_blank"
        >
          <Trans>Learn more</Trans>
        </Link>
      </div>
      <ProtectedServicesListItem>
        <ProtectedServicesIcon>
          <VaultIcon />
        </ProtectedServicesIcon>
        <ProtectedServiceHeader>
          <Trans>LastPass vault</Trans>
        </ProtectedServiceHeader>
        <Trans>
          Upon login to their vault, users enter their master password and
          verify their identity using LastPass Authenticator. The master
          password decrypts their vault data.
        </Trans>
      </ProtectedServicesListItem>
      <ProtectedServicesListItem>
        <ProtectedServicesIcon>{<SsoIcon />}</ProtectedServicesIcon>
        <ProtectedServiceHeader>
          <Trans>Single sign-on apps</Trans>
        </ProtectedServiceHeader>
        <Trans>
          Upon login to a configured SSO app, users verify their identity
          without a password using LastPass Authenticator.
        </Trans>
      </ProtectedServicesListItem>
      <ProtectedServicesListItem>
        <ProtectedServicesIcon>
          {<WorkstationLoginIcon />}
        </ProtectedServicesIcon>
        <ProtectedServiceHeader>
          <Trans>Other MFA apps {'&'} services</Trans>
        </ProtectedServiceHeader>
        <Trans>
          Tip: You configure MFA apps on the <Bold>Applications {'>'} MFA</Bold>{' '}
          apps page.
        </Trans>
      </ProtectedServicesListItem>
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => {
              dispatch(
                multifactorDrawerActions.multifactorSetupInstructionsClicked(
                  'Discard'
                )
              )
              history.push(closeLink)
            }}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              dispatch(
                multifactorDrawerActions.multifactorSetupInstructionsClicked(
                  'Continue'
                )
              )
              dispatch(
                multifactorDrawerActions.nextStep(
                  MultifactorDrawerSteps.selectMfaMethod
                )
              )
            }}
            disabled={false}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </MainContainer>
  )
}

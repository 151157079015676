import React from 'react'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'
import { SystemUpgradeDrawerHeadersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SystemUpgradeMapping'

import {
  DetailsBox,
  DrawerContainer,
  LegacyConsolePath,
  SystemUpgradeDrawerFooter,
  SystemUpgradeDrawerProps,
  SystemUpgradeDrawerStyledLink,
  SystemUpgradeFeatureNotAvailable
} from './SystemUpgradeDrawerResources'

const detailsBoxContent = [
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatFor],
    dataQa: 'authenticationDrawerWhatFeatureForContainer',
    mainContent: (
      <Trans>
        This feature allowed/denied passwordless access to LastPass accounts
        based on how users configured their LastPass Authenticator app.
      </Trans>
    )
  },
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.where],
    dataQa: 'authenticationDrawerFeatureWhereContainer',
    mainContent: (
      <ul>
        <li>
          <Trans>New console</Trans>
          {': '}
          <SystemUpgradeDrawerStyledLink
            url="/policies/passwordless/authentication"
            dataQa="authenticationDrawerNewConsoleLink"
          >
            <Trans>Policies</Trans>
            {' > '}
            <Trans>Passwordless</Trans>
            {' > '}
            <Trans>Authentication</Trans>
          </SystemUpgradeDrawerStyledLink>
        </li>
        <li>
          <Trans>Old console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <LegacyConsolePath
            isNotAvailable={false}
            path={<Trans>Policy{' > '}Authentication</Trans>}
          />
        </li>
      </ul>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[
        SystemUpgradeDrawerHeader.whatsHappening
      ],
    dataQa: 'authenticationDrawerFeatureWhatHappeningContainer',
    mainContent: <Trans>This policy will be removed.</Trans>
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatToDo],
    dataQa: 'authenticationDrawerFeatureWhatToDoContainer',
    mainContent: (
      <Trans>
        To achieve similar goals after upgrade, enable Step-up authentication in
        an SSO app&apos;s settings.
      </Trans>
    )
  }
]

export const AuthenticationDrawer: React.FunctionComponent<SystemUpgradeDrawerProps> = ({
  closeLink
}) => (
  <Drawer
    mainTitle={<Trans>Authentication & recovery</Trans>}
    subTitle={<Trans>Details</Trans>}
    closeLink={closeLink}
    data-qa="authenticationDrawer"
  >
    <DrawerContainer data-qa="authenticationDrawerContentContainer">
      {detailsBoxContent.map(content => (
        <DetailsBox
          key={content.dataQa}
          title={content.title}
          data-qa={content.dataQa}
        >
          {content.mainContent}
        </DetailsBox>
      ))}
    </DrawerContainer>
    <SystemUpgradeDrawerFooter
      closeLink={closeLink}
      data-qa="authenticationDrawerFooter"
    />
  </Drawer>
)

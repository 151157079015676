import React from 'react'

import { Trans } from '@lingui/macro'

import { Badge } from '@lastpass/lastkit/components'
import { Theme } from '@lastpass/lastkit/theme'

import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { getAccountStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/AccountStatusMapping'

export interface StatusBadgeProps {
  status: AccountStatus
}

export const StatusBadge = (props: StatusBadgeProps) => {
  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )
  const accountStatusMapping = getAccountStatusMapping(isAccountStatusRenamed)

  const label = accountStatusMapping[props.status] || <Trans>Unknown</Trans>
  let background: keyof Theme['colors'] = 'teal'
  let textColor: keyof Theme['colors'] = 'white'

  switch (props.status) {
    case AccountStatus.active:
      background = 'teal'
      textColor = 'white'
      break
    case AccountStatus.loggedIn:
      background = 'green'
      textColor = 'white'
      break
    case AccountStatus.invited:
      background = 'purple'
      textColor = 'white'
      break
    case AccountStatus.awaitingApproval:
      background = 'neutral100'
      textColor = 'neutral900'
      break
    case AccountStatus.disabled:
      background = 'neutral100'
      textColor = 'neutral500'
      break
    case AccountStatus.staged:
      background = 'pink'
      textColor = 'white'
      break
    case AccountStatus.expiredInvitation:
      background = 'red700'
      textColor = 'white'
      break
    default:
      break
  }

  return (
    <Badge
      colors={{
        backgroundColor: background,
        textColor: textColor
      }}
    >
      {label}
    </Badge>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as PasswordlessEmptyLogo } from '@lastpass/assets/svg/admin-console/policies/passwordless-empty-logo.svg'
import {
  BodyBold,
  BodyRegular,
  Heading300,
  PrimaryButton
} from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { PostTrialMfaAlert } from '@lastpass/admin-console-dependencies/ui/global/PostTrialMfaAlert'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHeading = styled(Heading300)`
  margin-top: 32px;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: center;
  vertical-align: middle;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
`

const StyledBodyRegular = styled(BodyRegular)`
  display: flex;
  width: 55%;
  align-self: center;
`

const StyledBodyRegular100 = styled(BodyRegular)`
  display: flex;
  align-self: center;
`

const StyledLocationLink = styled.a`
  margin-top: 32px;
`

const StyledLogo = styled(PasswordlessEmptyLogo)`
  margin-top: 171px;
`

export const PasswordlessEmptyList = () => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isTiralEligible = CompanyDetailsHelper.isUserBusinessTrialEligible(
    companyDetails
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const postTrialAlert =
    !userHasMfaAddon && !isTiralEligible ? <PostTrialMfaAlert /> : null

  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(passwordlessActions.passwordlessPoliciesOverviewViewed())
  })

  return (
    <StyledContainer>
      <StyledLogo />
      <StyledHeading>
        <Trans>
          Streamline employee access with passwordless authentication
        </Trans>
      </StyledHeading>
      <StyledTextContainer>
        <StyledBodyRegular>
          <Trans>
            Assign users to passwordless apps to let your users leverage
            biometric authentication on their mobile devices to access cloud
            apps, legacy apps, VPNs, and workstations without a password.
          </Trans>
        </StyledBodyRegular>
      </StyledTextContainer>
      <StyledTextContainer>
        <StyledBodyRegular100>
          <Trans>
            1. Go to&nbsp;<BodyBold>Passwordless apps</BodyBold>&nbsp;to assign
            users to endpoints you want to protect.
          </Trans>
        </StyledBodyRegular100>
        <StyledBodyRegular100>
          <Trans>
            2. Come back here to set up policies to control access by IP and
            geolocation.
          </Trans>
        </StyledBodyRegular100>
      </StyledTextContainer>
      {postTrialAlert}
      <StyledPrimaryButton
        data-qa="GotoPasswordlessApps"
        onClick={() => history.push('/applications/passwordless/ssoapps')}
      >
        <Trans>Go to Passwordless apps</Trans>
      </StyledPrimaryButton>
      <StyledLocationLink
        href="https://link.lastpass.com/passwordless-authentication"
        data-qa="LearnMoreAboutPasswordlessLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </StyledContainer>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetCompanySettingsSaga(
  companyServices: UACServices.Services
) {
  return function* getCompanySettingsSaga() {
    try {
      const result: UACServices.CompanySettingsAPI.Responses = yield call(
        companyServices.companySettings
      )
      if (result.type === UACServices.UsersAPI.SUCCESS) {
        yield put(companyInfoActions.setCompanySettings(result.body.settings))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

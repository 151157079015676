import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { UrlRule } from '@lastpass/admin-console-dependencies/types/url-rule'

import { URLRuleOptionsForm } from './state'

export enum UrlRulesActionTypes {
  RESET = '[url-rules] reset',
  URL_RULES_GET = '[url-rules] get',
  URL_RULES_SET = '[url-rules] set',
  URL_RULES_LOADING_SET = '[url-rules] set-loading',
  URL_RULE_ADD = '[url-rules] add',
  URL_RULES_GET_OPTIONS = '[url-rules] get-options',
  URL_RULES_SET_OPTIONS = '[url-rules] set-options',
  URL_RULES_EDIT_OPTIONS = '[url-rules] edit-options',
  URL_RULES_OPTIONS_LOADING_SET = '[url-rules] set-options-loading',

  ADD_SELECTED_RULES = '[url-rules-list] add-selected-rules',
  REMOVE_SELECTED_RULES = '[url-rules-list] remove-selected-rules',
  SET_SELECTED_RULES = '[url-rules-list] set-selected-rules',

  DELETE_URL_RULES = '[url-rules] delete'
}

export const urlRulesActions = {
  reset: () => createAction(UrlRulesActionTypes.RESET),
  getUrlRules: () => createAction(UrlRulesActionTypes.URL_RULES_GET),
  setUrlRules: (rules: UrlRule[]) =>
    createAction(UrlRulesActionTypes.URL_RULES_SET, {
      rules
    }),
  setUrlRulesLoading: (isLoading: boolean) =>
    createAction(UrlRulesActionTypes.URL_RULES_LOADING_SET, {
      isLoading: isLoading
    }),
  addUrlRule: (rule: UrlRule) =>
    createAction(UrlRulesActionTypes.URL_RULE_ADD, {
      rule: rule
    }),
  getURLRuleOptions: () =>
    createAction(UrlRulesActionTypes.URL_RULES_GET_OPTIONS),
  setURLRuleOptions: (options: URLRuleOptionsForm) =>
    createAction(UrlRulesActionTypes.URL_RULES_SET_OPTIONS, {
      options: options
    }),
  editURLRuleOptions: (options: URLRuleOptionsForm) =>
    createAction(UrlRulesActionTypes.URL_RULES_EDIT_OPTIONS, {
      options: options
    }),
  setURLRuleOptionsLoading: (isLoading: boolean) =>
    createAction(UrlRulesActionTypes.URL_RULES_OPTIONS_LOADING_SET, {
      isLoading: isLoading
    }),

  addSelectedRules: (rules: UrlRule[]) =>
    createAction(UrlRulesActionTypes.ADD_SELECTED_RULES, { rules }),
  removeSelectedRules: (rules: UrlRule[]) =>
    createAction(UrlRulesActionTypes.REMOVE_SELECTED_RULES, { rules }),
  setSelectedRules: (rules: UrlRule[]) =>
    createAction(UrlRulesActionTypes.SET_SELECTED_RULES, { rules }),

  deleteUrlRules: (rules: UrlRule[]) =>
    createAction(UrlRulesActionTypes.DELETE_URL_RULES, { rules })
}

export type UrlRulesActions = ActionsUnion<typeof urlRulesActions>

import { msg } from '@lingui/macro'
import parse from 'csv-parse/lib/sync'
import { call, put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { readFileAsText } from '@lastpass/admin-console-dependencies/services/read-file-as-text'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { addUsersDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'
import { InvalidFileDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

const errorDialog: InvalidFileDialog = {
  type: 'invalidfiledialog'
}

export function createProcessPendingAddUsersUploadSaga() {
  return function* processPendingAddUsersUploadSaga(
    action: ReturnType<typeof addUsersDrawerActions.processPendingUsersUpload>
  ) {
    try {
      const addUsersColumns = ['Email', 'Firstname', 'Lastname']

      const content = yield call(
        readFileAsText,
        action.payload.selectorFiles[0]
      )
      try {
        const parsed = yield call(parse, content, {
          columns: header =>
            header.map((column: string) => {
              if (addUsersColumns.includes(column)) {
                return column
              } else {
                put(globalActions.setDialog(errorDialog))
              }
            }),
          /* eslint-disable-next-line */
          skip_empty_lines: true
        })
        const formatedRecords = parsed.map(user => {
          return {
            email: user.Email,
            lastName: user.Lastname,
            firstName: user.Firstname
          }
        })
        const existingRecords = action.payload.currentFormPendingUsers
        const allRecords = existingRecords.concat(formatedRecords)
        let error = false
        if (allRecords.length > 100) {
          yield put(
            globalActions.setNotification({
              message: msg`You can add a maximum of 100 users at a time.`,
              type: NotificationType.alert,
              autoDismiss: false
            })
          )
          error = true
        }

        if (!error) {
          yield put(addUsersDrawerActions.setPendingAddUsers(allRecords))
        }
      } catch {
        yield put(globalActions.setDialog(errorDialog))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SplunkIntegrationState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/state'

export const SUCCESS = 'success'

const success = (splunkIntegration: SplunkIntegrationState) =>
  createResponse(SUCCESS, { splunkIntegration })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await teamsapi('my-company/showsplunk', 'GET')
      if (status === StatusCodes.OK && response) {
        const splunkIntegration = JSON.parse(response)
        return success(splunkIntegration)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

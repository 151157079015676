import {
  SharedFolderDrawerActions,
  SharedFolderDrawerActionTypes
} from './actions'
import {
  SharedFolderScoresListState,
  SharedFoldersSitesListState,
  SharedFoldersUsersListState
} from './state'

type DrawerState = SharedFoldersUsersListState &
  SharedFoldersSitesListState &
  SharedFolderScoresListState

export const initialState: DrawerState = {
  usersTable: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  sitesTable: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  scoresTable: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function sharedFolderDrawerReducer(
  previousState: DrawerState | undefined,
  action: SharedFolderDrawerActions
): DrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_SITES_LIST: {
      return {
        ...state,
        sitesTable: {
          ...state.sitesTable,
          results: action.payload.sites,
          totalResults: action.payload.sites.length
        }
      }
    }
    case SharedFolderDrawerActionTypes.SET_SHAREDFOLDER_USERS_LOADING: {
      return {
        ...state,
        usersTable: {
          ...state.usersTable,
          isLoading: action.payload
        }
      }
    }
    case SharedFolderDrawerActionTypes.SET_SHAREDFOLDER_SITES_LOADING: {
      return {
        ...state,
        sitesTable: {
          ...state.sitesTable,
          isLoading: action.payload
        }
      }
    }
    case SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_SCORE_LOADING: {
      return {
        ...state,
        scoresTable: {
          ...state.scoresTable,
          isLoading: action.payload
        }
      }
    }
    case SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_SCORE: {
      return {
        ...state,
        scoresTable: {
          ...state.scoresTable,
          results: action.payload.scores,
          totalResults: action.payload.scores.length
        }
      }
    }
    case SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_USERS_LIST: {
      return {
        ...state,
        usersTable: {
          ...state.usersTable,
          results: action.payload.users,
          totalResults: action.payload.users.length
        }
      }
    }
  }
  return state
}

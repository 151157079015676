import { Dispatch, SetStateAction } from 'react'

import { MasterPasswordValidationData } from '@lastpass/admin-console-dependencies/server/users/view/current-admin-private-rsa-sharing-key'
import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { RecoveryKeyDecryptionData } from '@lastpass/admin-console-dependencies/types/recovery-keys/user-recovery-keys'
import { DecryptionDataRow } from '@lastpass/admin-console-dependencies/ui/global/dialogs/security-reports/hooks/use-prepare-decryption-data-from-csv'

import { DownloadReportName } from './state'

export enum SecurityReportDrawerActionTypes {
  SET_TOTAL_NUMBER_OF_CSV_ROWS = '[security-reports-drawer] set-total-number-of-csv-rows',

  SET_RECOVERY_KEY_DECRYPTION_DATA = '[securiy-reports-drawer] set-recovery-key-decryption-data',
  GET_RECOVERY_KEY_DECRYPTION_DATA = '[securiy-reports-drawer] get-recovery-key-decryption-data',

  DOWNLOAD_URLS_IN_VAULTS_REPORT = '[security-reports-drawer] download-urls-in-vaults-report',
  DOWNLOAD_URLS_IN_SHARED_FOLDERS_REPORT = '[security-reports-drawer] download-urls-in-shared-folders-report',

  SET_DOWNLOAD_REPORT_NAME = '[security-reports-drawer] set-download-report-name',

  SET_SECURITY_REPORT_FILES = '[security-reports-drawer] set-security-report-files',

  SET_URLS_IN_SHARED_FOLDER_DECRYPTION_DATA = '[security-reports-drawer] set-urls-in-shared-folder-decryption-data',

  GET_MASTER_PASSWORD_VALIDATION_DATA = '[security-reports-drawer] get-master-password-validation-data',
  SET_MASTER_PASSWORD_VALIDATION_DATA = '[security-reports-drawer] set-master-password-validation-data',

  SET_IS_LOADING = '[security-reports-drawer] set-is-loading'
}

export const securityReportDrawerActions = {
  setTotalNumberOfCsvRows: (totalNumber: number) =>
    createAction(
      SecurityReportDrawerActionTypes.SET_TOTAL_NUMBER_OF_CSV_ROWS,
      totalNumber
    ),
  setRecoveryKeyDecryptionData: (
    recoveryKeyDecryptionData: RecoveryKeyDecryptionData
  ) =>
    createAction(
      SecurityReportDrawerActionTypes.SET_RECOVERY_KEY_DECRYPTION_DATA,
      { recoveryKeyDecryptionData }
    ),
  getRecoveryKeyDecryptionData: () =>
    createAction(
      SecurityReportDrawerActionTypes.GET_RECOVERY_KEY_DECRYPTION_DATA
    ),
  downloadUrlsInVaultsReport: (
    reportId: string,
    isUrlDecryptionEnabled: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>
  ) =>
    createAction(
      SecurityReportDrawerActionTypes.DOWNLOAD_URLS_IN_VAULTS_REPORT,
      {
        reportId,
        isUrlDecryptionEnabled,
        setIsLoading
      }
    ),
  downloadUrlsInSharedFoldersReport: (
    reportId: string,
    isSharedFolderUrlDecryptionEnabled: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>
  ) =>
    createAction(
      SecurityReportDrawerActionTypes.DOWNLOAD_URLS_IN_SHARED_FOLDERS_REPORT,
      {
        reportId,
        isSharedFolderUrlDecryptionEnabled,
        setIsLoading
      }
    ),
  setDownloadReportName: (reportName: DownloadReportName) =>
    createAction(
      SecurityReportDrawerActionTypes.SET_DOWNLOAD_REPORT_NAME,
      reportName
    ),
  setSecurityReportFiles: (reportFiles: Blob[]) =>
    createAction(
      SecurityReportDrawerActionTypes.SET_SECURITY_REPORT_FILES,
      reportFiles
    ),
  setUrlsInSharedFolderDecryptionData: (decryptionData: DecryptionDataRow[]) =>
    createAction(
      SecurityReportDrawerActionTypes.SET_URLS_IN_SHARED_FOLDER_DECRYPTION_DATA,
      decryptionData
    ),
  getMasterPasswordValidationData: () =>
    createAction(
      SecurityReportDrawerActionTypes.GET_MASTER_PASSWORD_VALIDATION_DATA
    ),
  setMasterPasswordValidationData: (
    validationData: MasterPasswordValidationData
  ) =>
    createAction(
      SecurityReportDrawerActionTypes.SET_MASTER_PASSWORD_VALIDATION_DATA,
      validationData
    ),
  setIsLoading: (isLoading: boolean) =>
    createAction(SecurityReportDrawerActionTypes.SET_IS_LOADING, isLoading)
}

export type SecurityReportDrawerActions = ActionsUnion<
  typeof securityReportDrawerActions
>

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AlertIcon } from '@lastpass/assets/svg/icon-warning-triangle.svg'
import { BodyRegular } from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import {
  applicationListActions,
  ApplicationListQueryParams
} from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

const Container = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin: 24px;
  padding: 5px;
  padding-right: 10px;
  background: ${props => props.theme.colors.red100};
  border: 1px solid;
  border-color: ${props => props.theme.colors.red400};
  border-radius: 5px;
  width: max-content;
  display: flex;
`

const StyledAlertIcon = styled(AlertIcon)`
  height: 22px;
  width: 22px;
  margin: 10px 10px 5px 5px;
  color: ${props => props.theme.colors.red600};
`

export const SystemUpgradeReconfigAppsAlert: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  const isNopasswordMigrationEnabled = useFeatureFlags(
    FeatureFlags.isNopasswordMigrationEnabled
  )

  const queryParams: ApplicationListQueryParams = useQueryParams(Namespace.apps)
  useEffect(() => {
    dispatch(
      applicationListActions.getApplicationList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])
  const ssoAppDetails = useSelector<AppState, ApplicationListState>(
    appState => appState.applicationList
  )

  const isSsoAppWithHasMigratedValue =
    ssoAppDetails &&
    ssoAppDetails.table.results.filter(x => x.hasNpMigratedApp).length > 0

  if (!isNopasswordMigrationEnabled || !isSsoAppWithHasMigratedValue) {
    return null
  }

  return (
    <Container>
      <StyledAlertIcon data-qa="AppReconfigAlertIcon" />

      <BodyRegular data-qa="AppReconfigMsg">
        <Trans>
          To restore access to these apps, repair their settings. We’ll show you
          what to do in each app’s configuration details. <br />
          Apps we couldn’t uniquely identify are listed as custom services.
        </Trans>
      </BodyRegular>
    </Container>
  )
}

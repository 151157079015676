import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { Workstation } from '../workstations'

export enum WorkstationListActionTypes {
  GET_WORKSTATION_LIST = '[workstation-list] get',
  SET_WORKSTATION_LIST = '[workstation-list] set',
  GET_STARTED = '[workstation-list] get-started'
}

export const workstationListActions = {
  getWorkstationList: () =>
    createAction(WorkstationListActionTypes.GET_WORKSTATION_LIST),
  setWorkstationListWithTracking: (list: Workstation[], total: number) =>
    track(
      createAction(WorkstationListActionTypes.SET_WORKSTATION_LIST, {
        list,
        total
      }),
      {
        segment: uacEvent('Workstation Login Overview Viewed', {
          isEmpty: !list || !list.length,
          'Policy Count': total,
          Source: 'Workstation Login Overview'
        })
      }
    ),
  setWorkstationList: (list: Workstation[], total: number) =>
    createAction(WorkstationListActionTypes.SET_WORKSTATION_LIST, {
      list,
      total
    }),
  getStarted: () =>
    track(createAction(WorkstationListActionTypes.GET_STARTED), {
      segment: uacEvent('Add Passwordless App Clicked', {
        'App Count': 0,
        Type: 'Workstation'
      })
    })
}

export type WorkstationListActions = ActionsUnion<typeof workstationListActions>

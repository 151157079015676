import React from 'react'
import { useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit'
import { useUnmountEffect } from '@lastpass/ui'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'

import { ViewAndEditManagedAdminPermissions } from './components/ViewAndEditManagedAdminPermissions'

interface ManagedCompanyAccessDrawerProps {
  closeLink: string
}

export const ManagedCompanyAccessDrawer: React.FunctionComponent<ManagedCompanyAccessDrawerProps> = ({
  closeLink
}) => {
  const { adminLevel } = useSelector<AppState, AdminLevelDrawerState>(
    state => state.adminLevelDrawer
  )

  useUnmountEffect(() => {
    adminLevelDrawerActions.reset()
  })

  return (
    <Drawer
      mainTitle={<>{adminLevel.name}</>}
      subTitle={<Trans>Managed company access</Trans>}
      closeLink={closeLink}
    >
      <ViewAndEditManagedAdminPermissions
        closeLink={closeLink}
        adminLevel={adminLevel}
      />
    </Drawer>
  )
}

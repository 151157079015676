import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as UpsellIcon } from '@lastpass/assets/svg/admin-console/upsell-icon.svg'
import {
  BodyRegular,
  DetailsDialog,
  Heading100,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { UpsellDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface MfaUpsellDialogProps {
  state: UpsellDialog
  actions: typeof globalActions
}

const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledUpsellIcon = styled(UpsellIcon)`
  margin-bottom: 44px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 20px;
  width: 100%;
`

const StyledBodyRegular = styled(BodyRegular)`
  width: 100%;
`

export const MfaUpsellDialogComponent: FunctionComponent<MfaUpsellDialogProps> = ({
  state,
  actions
}) => {
  const discardText = i18n._(state.discardText)
  const confirmText = i18n._(state.confirmText)
  const mainText = i18n._(state.mainText)
  const secondaryText = i18n._(state.secondaryText)

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const ecommEnabled =
    CompanyDetailsHelper.isEcommEnabled(companyDetails) || true

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={() => {
        actions.discardDialogSegment(
          ecommEnabled,
          'Trial',
          'Close',
          state.mfaDialogContext
        )
      }}
      width={'480px'}
      content={
        <StyledCenter>
          <StyledUpsellIcon />
          <StyledHeading100>{mainText}</StyledHeading100>
          <StyledBodyRegular>{secondaryText}</StyledBodyRegular>
        </StyledCenter>
      }
      buttons={[
        <TextButton
          onClick={() =>
            actions.discardDialogSegment(
              ecommEnabled,
              'Trial',
              'Close',
              state.mfaDialogContext
            )
          }
          key={'discard'}
        >
          {discardText}
        </TextButton>,
        <PrimaryButton
          data-qa="ConfirmButton"
          onClick={() => {
            actions.confirmDialogSegment(
              ecommEnabled,
              'Trial',
              'Start Trial',
              state.mfaDialogContext
            )
          }}
          key={'confirm'}
        >
          {confirmText}
        </PrimaryButton>
      ]}
    />
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { IndividualCompanyAgreementDrawerContent } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/individual-company/IndividualCompanyAgreementDrawerContent'
import { PsaDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/drawer/PsaDrawer'

const StyledTitle = styled('div')`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #5a6a81;
  height: 16px;
`

interface DrawerParams {
  id?: string
}

export const IndividualCompanyAgreementDrawer: FunctionComponent<{
  closeLink: string
  backLink: string
}> = props => {
  const { backLink, closeLink } = props
  const managedCompanyList = useSelector(
    (state: AppState) => state.managedCompanies.table.results
  )
  const dispatch = useDispatch()
  const [managedCompanyName, setManagedCompanyName] = useState('')
  const { id = '' } = useParams<DrawerParams>()

  useEffect(() => {
    if (id) {
      dispatch(psaIntegrationsActions.getPsaAgreements([Number(id)]))
    }
  }, [dispatch, id])

  useEffect(() => {
    const managedCompany = managedCompanyList.find(
      company => company.id === Number(id)
    )

    if (managedCompany) {
      setManagedCompanyName(managedCompany.name)
    }
  }, [managedCompanyList, id])

  return (
    <PsaDrawer
      title={
        <>
          <StyledTitle>{managedCompanyName}</StyledTitle>
          <Trans>Select agreement</Trans>
        </>
      }
      closeLink={closeLink}
      backLink={`${backLink}/${id}`}
      description={
        <Trans>
          To perform manual or automated billing updates, an agreement must be
          selected for each company.
        </Trans>
      }
      dataQa="IndividualCompanyAgreementsDrawer"
    >
      <IndividualCompanyAgreementDrawerContent managedCompanyId={id} />
    </PsaDrawer>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import { AdvancedUserActivityList } from '@lastpass/admin-console-dependencies/ui/reports/view/AdvancedUserActivityList'
import { AdvancedUserActivityFilterDrawer } from '@lastpass/admin-console-dependencies/ui/reports/view/filter/AdvancedUserActivityFilterDrawer'
import { AdvancedUserActivitySaveFiltersDrawer } from '@lastpass/admin-console-dependencies/ui/reports/view/save/AdvancedUserActivitySaveFiltersDrawer'
import { AdvancedUserActivityDetailDrawer } from '@lastpass/admin-console-dependencies/ui/reports/view/user-activity-id/AdvancedUserActivityDetailDrawer'

export function ReportsViewPage() {
  const routeMatch = useRouteMatch()
  const state = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )

  if (!routeMatch) {
    return null
  }

  const { url, path } = routeMatch

  return (
    <>
      <AdvancedUserActivityList />
      <AnimatedSwitch timeout={200}>
        <Route path={`${path}/filter/new`}>
          <AdvancedUserActivityFilterDrawer
            closeLink={url}
            title={<Trans>New report</Trans>}
          />
        </Route>

        <Route path={`${path}/filter`}>
          <AdvancedUserActivityFilterDrawer
            closeLink={url}
            title={<Trans>Filter reports</Trans>}
          />
        </Route>

        <Route path={`${path}/save`}>
          {state.filterForSave ? (
            <AdvancedUserActivitySaveFiltersDrawer
              cancelLink={url}
              closeLink={`${url}/filter`}
            />
          ) : (
            <Redirect to={url + location.search} />
          )}
        </Route>

        <Route path={`${path}/:id`}>
          <AdvancedUserActivityDetailDrawer closeLink={url} />
        </Route>

        <Route>
          <Redirect to={url + location.search} />
        </Route>
      </AnimatedSwitch>
    </>
  )
}

import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react'
import AnimateHeight from 'react-animate-height'

import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Chevron } from '@lastpass/components'
import { Loading } from '@lastpass/components/Loading'

import { CardContainer } from './CardContainer'
import { Heading100 } from './Heading'
import { TextButton } from './TextButton'

export interface CollapsibleProps {
  title: ReactElement
  expanded?: boolean
  initialExpanded?: boolean
  loading?: boolean
  onInitialExpand?: Function
  onExpand?: Function
  scrollToView?: boolean
  chevron?: boolean
  headerMargin?: string
  containerCssOverride?: SerializedStyles
  qadata?: string
  simple?: boolean
  compact?: boolean
}

// This component can be used in the Collapsible component to display full width content
export const FullWidthContainer = styled.div`
  margin: 16px -16px;
`

const Container = styled(CardContainer)<{
  simple?: boolean
  compact?: boolean
}>`
  padding: ${props => (props.simple || props.compact ? '0px' : '16px')};
  margin-top: ${props => (props.simple || props.compact ? '0px' : '16px')};
  &:first-of-type {
    margin-top: 0px;
  }
  ${props =>
    (props.simple || props.compact) &&
    css`
      padding-top: ${props.simple ? '12px' : ''};
      background: unset;
      border: unset;
      box-shadow: unset;
      border-radius: unset;
      width: 100%;
      min-width: ${props.compact ? '90%' : ''};
    `}
`

const HeaderContainer = styled.div<{
  simple?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.simple ? 'flex-start ' : 'space-between')};
  margin-bottom: ${props => (props.simple ? '12px' : '0px')};
`

const CollapsibleLink = styled(TextButton)<{
  compact?: boolean
}>`
  color: ${props => props.theme.colors.blue700};
  padding-left: 0px;
  ${props =>
    props.compact &&
    css`
      font-size: 12px;
    `}
`

const LoadingContainer = styled.div`
  margin: 16px 0;
  position: relative;
  min-height: 225px;
`

const StyledHeader = styled(Heading100)<{
  headerMargin?: string
  simple?: boolean
  compact?: boolean
}>`
  margin: ${props => (props.headerMargin ? props.headerMargin : '')};
  order: ${props => (props.simple ? '1' : '0')};
  cursor: ${props => (props.simple ? 'pointer' : 'auto')};
  ${props => {
    if (props.simple) {
      return css`
        color: ${props.theme.colors.blue};
        font-size: 14px;
      `
    }

    if (props.compact) {
      return css`
        color: ${props.theme.colors.gray500};
        font-size: 12px;
      `
    }

    return css`
      font-size: 18px;
    `
  }}
  min-width: 0px;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Collapsible: FunctionComponent<CollapsibleProps> = ({
  onInitialExpand,
  onExpand,
  chevron,
  headerMargin,
  containerCssOverride,
  simple,
  compact,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(props.expanded || false)
  const calledOnInitialExpand = useRef(false)

  useEffect(() => {
    setExpanded(!!props.expanded)
  }, [props.expanded])
  useEffect(() => {
    if (props.initialExpanded) {
      setExpanded(true)
    }
  }, [props.expanded, props.initialExpanded])
  const onCollapsibleLinkClick = () => {
    setExpanded(!expanded)
    if (!expanded && onInitialExpand && !calledOnInitialExpand.current) {
      calledOnInitialExpand.current = true
      onInitialExpand()
    }
    if (onExpand) {
      onExpand()
    }
  }
  return (
    <Container
      ref={containerRef}
      data-qa={props.qadata}
      simple={simple}
      compact={compact}
      css={containerCssOverride}
    >
      <HeaderContainer simple={simple}>
        <StyledHeader
          headerMargin={headerMargin}
          simple={simple}
          compact={compact}
          data-qa={'CardHeader'}
          onClick={() => {
            simple && onCollapsibleLinkClick()
          }}
        >
          {props.title}
        </StyledHeader>
        <CollapsibleLink
          type={'button'}
          onClick={() => onCollapsibleLinkClick()}
          data-qa={'CollapseExpandButton'}
          compact={compact}
        >
          {chevron || simple ? (
            <Chevron
              direction={expanded ? 'up' : 'down'}
              color={simple ? '#3B70D4' : '#41536B'}
            />
          ) : expanded ? (
            <Trans>Collapse</Trans>
          ) : (
            <Trans>Expand</Trans>
          )}
        </CollapsibleLink>
      </HeaderContainer>
      <AnimateHeight
        height={expanded ? 'auto' : 0}
        onAnimationEnd={() => {
          if (props.scrollToView !== false && containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        }}
      >
        {props.loading ? (
          <LoadingContainer>
            <Loading color="blue900" active={true} />
          </LoadingContainer>
        ) : (
          props.children
        )}
      </AnimateHeight>
    </Container>
  )
}

import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton } from '@lastpass/lastkit'
import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { RecentManagedCompaniesRepository } from '@lastpass/admin-console-dependencies/repositories'
import {
  filterApplicableOperations,
  getAllOperations,
  ManagedCompanyOperationTypes
} from '@lastpass/admin-console-dependencies/services/managed-companies/drawer/operations'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CidOverrideParams } from '@lastpass/admin-console-dependencies/types/cid-override-params'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { CompanyProfileCardComponent } from './cards/CompanyProfileCard'
import { PsaIntegrationCardComponent } from './cards/PsaIntegrationCard'
import { OperationsDropdown } from './OperationsDropdown'

interface ManagedCompanyDetailsDrawerProps {
  closeLink: string
}

const StyledOperationsDropdown = styled(OperationsDropdown)`
  margin-left: 16px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  vertical-align: bottom;
`

export const ManagedCompanyDetailsDrawerComponent: React.FunctionComponent<ManagedCompanyDetailsDrawerProps> = ({
  closeLink
}) => {
  const permissions = useContext(PermissionContext)
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const managedCompanyList = useSelector(
    (state: AppState) => state.managedCompanies.table.results
  )
  const {
    cidOverride,
    details: { isBillingUsageBased }
  } = useSelector<AppState, CompanyInfoState>(state => state.companyInfo)
  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const isPsaIntegrationEnabled =
    useFeatureFlags(FeatureFlags.isPsaIntegrationEnabled) &&
    permissions.requirePermission(
      AdminPermissions.viewManagedServiceProviderPsa
    )

  const managedCompany = managedCompanyList.find(
    company => company.id === Number(id)
  )

  if (!managedCompany) {
    return null
  }

  const canManageCompanies = permissions.requirePermission(
    AdminPermissions.managedCompanyCidOverride
  )

  const canDetachCompanies = permissions.requirePermission(
    AdminPermissions.detachManagedCompany
  )

  const canSuspendCompanies = permissions.requirePermission(
    AdminPermissions.suspendManagedCompany
  )

  const canManageLicenses = permissions.requirePermission(
    AdminPermissions.managedCompanyLicenseManagement
  )
  const hasCreateManagedCompaniesPermission = permissions.requirePermission(
    AdminPermissions.addManagedCompany
  )

  const hiddenOperations: ManagedCompanyOperationTypes[] = []

  if (isRoleBasedAccessControlEnabled) {
    if (!canDetachCompanies) {
      hiddenOperations.push(ManagedCompanyOperationTypes.detach)
    }

    if (!canSuspendCompanies) {
      hiddenOperations.push(ManagedCompanyOperationTypes.suspend)
      hiddenOperations.push(ManagedCompanyOperationTypes.reactivate)
    }

    if (!canManageLicenses) {
      hiddenOperations.push(ManagedCompanyOperationTypes.allocateLicenses)
      hiddenOperations.push(ManagedCompanyOperationTypes.returnLicenses)
    }
  }

  const isPsaOnManagedCompany = !!managedCompany.psa

  const applicableOperations = filterApplicableOperations(
    getAllOperations(managedCompany, dispatch, isBillingUsageBased),
    hiddenOperations
  )

  const shouldShowManageCompanyButton: boolean = isRoleBasedAccessControlEnabled
    ? canManageCompanies && !cidOverride
    : !cidOverride

  return (
    <Drawer
      data-qa="ManagedCompanyDetailsDrawer"
      closeLink={closeLink}
      mainTitle={<>{managedCompany.name}</>}
      headerNodes={
        <>
          {shouldShowManageCompanyButton && (
            <StyledPrimaryButton
              data-qa="ManagedCompanyDetailsDrawerManageCompanyButton"
              onClick={event => {
                event.preventDefault()

                const cidOverride: CidOverrideParams = {
                  companyId: `${managedCompany.id}`,
                  companyName: managedCompany.name
                }
                RecentManagedCompaniesRepository.set(
                  cidOverride,
                  hasCreateManagedCompaniesPermission
                )
                window.open(`/dashboard?cid=${managedCompany.id}`, '_blank')
              }}
            >
              <Trans>Manage company</Trans>
            </StyledPrimaryButton>
          )}
          <StyledOperationsDropdown operations={applicableOperations} />
        </>
      }
    >
      <CompanyProfileCardComponent managedCompany={managedCompany} isExpanded />
      {isPsaIntegrationEnabled && isPsaOnManagedCompany && (
        <PsaIntegrationCardComponent
          managedCompany={managedCompany}
          isExpanded
        />
      )}
    </Drawer>
  )
}

import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'

const success = (sharedFolderUsersResponse: SharedFolderUsersApiResponse) =>
  createResponse(SUCCESS, sharedFolderUsersResponse)
const notFound = () => createResponse(NOT_FOUND)

export const Responses = {
  success,
  notFound
}

export interface SharedFolderUsersApiType {
  uid: string
  superadmin: string
  email: string
  usersname: string
  canadminister: string
  readonly: string
  canviewpass: string
  outsideenterprise: string
}

export type SharedFolderUsersApiResponse = SharedFolderUsersApiType[]

export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function servicee(shareid: string) {
    try {
      const [response, status] = await teamsapi(
        'my-company/sharedfolderusers/' + shareid,
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const result: SharedFolderUsersApiResponse = JSON.parse(response)
        return success(result)
      } else if (
        status === StatusCodes.NOT_FOUND ||
        status === StatusCodes.INTERNAL_SERVER_ERROR
      ) {
        return notFound()
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'

import { Heading200 } from '@lastpass/lastkit'
import { LogoCard } from '@lastpass/lastkit'

import { EnrollmentRateAccordionChips } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/enrollment-rate/accordion-chips/EnrollmentRateAccordionChips'
import { EnrollmentRateDonutChartCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/enrollment-rate/donut-chart-card/EnrollmentRateDonutChartCard'
import { ExpiredInvitationsCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/enrollment-rate/expired-invitations-card/ExpiredInvitationsCard'
import { PendingInvitationsCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/enrollment-rate/pending-invitations-card/PendingInvitationsCard'

const AccordionContentContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 16px;
`
const DonutChartCardContainer = styled.div`
  display: flex;
  width: 100%;
`
const ActionCardsContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`
const StyledLogoCard = styled(LogoCard)<{}>`
  background-color: ${props => props.theme.colors.blue50};
`
interface EnrollmentRateProps {
  rate: number
  enrolledUsersCount: number
  invitedUsersCount: number
  expiredInvitationsCount: number
  totalInvitationsSent: number
  isOpen: boolean
}

export const EnrollmentRate: FC<EnrollmentRateProps> = ({
  rate,
  enrolledUsersCount,
  invitedUsersCount,
  expiredInvitationsCount,
  totalInvitationsSent,
  isOpen
}) => {
  const enrollmentRateAccordionSubtitle: ReactElement = (
    <Trans>
      Out of {totalInvitationsSent} invited users, {enrolledUsersCount}{' '}
      <Plural value={enrolledUsersCount} zero="have" one="has" other="have" />{' '}
      enrolled by activating their LastPass account. Updated in real time.
    </Trans>
  )

  return (
    <StyledLogoCard
      expand={isOpen}
      hideSubtitleOnClose
      title={
        <Heading200 data-qa="EnrollmentRateAccordionTitle">
          <Trans>Enrollment rate</Trans>
        </Heading200>
      }
      subtitle={enrollmentRateAccordionSubtitle}
      contentOnClosed={
        <EnrollmentRateAccordionChips
          invitedUsersCount={invitedUsersCount}
          expiredInvitationsCount={expiredInvitationsCount}
          enrollmentRate={rate}
          enrollmentRateTooltip={enrollmentRateAccordionSubtitle}
        />
      }
      dataQaName="EnrollmentRateAccordion"
    >
      <AccordionContentContainer>
        <DonutChartCardContainer>
          <EnrollmentRateDonutChartCard
            enrolledUsersCount={enrolledUsersCount}
            invitedUsersCount={invitedUsersCount}
            expiredInvitationsCount={expiredInvitationsCount}
            enrollmentRate={rate}
            dataQa="EnrollmentRateChartCard"
          />
        </DonutChartCardContainer>
        <ActionCardsContainer>
          <PendingInvitationsCard
            count={invitedUsersCount}
            dataQa="EnrollmentRatePendingInvitationsCard"
          />
          <ExpiredInvitationsCard
            count={expiredInvitationsCount}
            dataQa="EnrollmentRateExpiredInvitationsCard"
          />
        </ActionCardsContainer>
      </AccordionContentContainer>
    </StyledLogoCard>
  )
}

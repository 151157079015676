import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { PsaProduct } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'

export const SUCCESS = 'success'

const success = (content: { products: PsaProduct[] }) =>
  createResponse(SUCCESS, { products: content.products })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch('v3/psa-products', 'GET')

      if (status === StatusCodes.OK && response) {
        return success(JSON.parse(response).content)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import { Dropdown, DropdownListItem, IconButton } from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaAppsDrawerState } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'

export type MfaApplicationOperationsDropdownComponentProps = LastPassComponentProps<
  MfaAppsDrawerState,
  typeof mfaAppsDrawerActions,
  {}
>

const StyledIconButton = styled(IconButton)`
  margin-left: 16px;
`

const MoreButton = props => (
  <StyledIconButton
    icon={More}
    data-qa={'MfaApplicationOperationsDropdownButton'}
    {...props}
  />
)

export const MfaApplicationOperationsDropdownComponent: React.FunctionComponent<MfaApplicationOperationsDropdownComponentProps> = ({
  state,
  actions
}) => {
  return (
    <Dropdown button={MoreButton}>
      <DropdownListItem
        red
        onClick={() => {
          if (state.app) actions.removeApp(state.app.key, state.app.type)
        }}
        data-qa={'RemoveAppButton'}
      >
        <Trans>Remove app</Trans>
      </DropdownListItem>
    </Dropdown>
  )
}

export function createMfaApplicationOperationsDropdown<
  TState extends { mfaAppsDrawer: MfaAppsDrawerState }
>() {
  return connectRedux(
    MfaApplicationOperationsDropdownComponent,
    (state: TState) => state.mfaAppsDrawer,
    mfaAppsDrawerActions
  )
}

export type MfaApplicationOperationsDropdown = ReturnType<
  typeof createMfaApplicationOperationsDropdown
>

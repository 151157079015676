import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'
import { addSlashes } from 'slashes'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'

export function createGetAdvancedSelectedUserForEvent(
  userActivityServices: UACServices.Services
) {
  return function* getAdvancedSelectedUserForEvent(
    action: ReturnType<typeof advancedUserActivityActions.getUserForEvent>
  ) {
    try {
      const escapedEmail = addSlashes(
        action.payload.selectedUserEmail,
        '\b\f\n\r\t\v\0"\\'
      )
      const query = buildQuery({
        search: `"${encodeURIComponent(escapedEmail)}"`
      })
      const users: UACServices.UsersAPI.Responses = yield call(
        userActivityServices.users,
        query
      )

      yield put(
        advancedUserActivityActions.setUserForEvent(users.body.userList[0])
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { MfaApplication } from '../mfa-application'

export enum MfaApplicationListActionTypes {
  GET_MFA_APPLICATION_LIST = '[mfa-application-list] get',
  SET_MFA_APPLICATION_LIST = '[mfa-application-list] set',
  ADD_NEW_MFA_APPLICATION = '[mfa-application-list] add-new-mfa-app',
  SET_MFA_APPLICATION_LIST_LOADING = '[mfa-application-list] set-table-loading',
  SET_SELECTED_APPLICATION = '[mfa-application-list] set-selected-application',
  SET_USED_AZURE_AD_APPS = '[mfa-application-list] set-used-azure-ad-application',
  SET_USED_ADFS_APPS = '[mfa-application-list] set-used-adfs-application'
}

export interface MfaApplicationListQueryParams {
  search: string
}

export const mfaApplicationListActions = {
  getMfaApplicationList: (
    params: DataFetchParams<MfaApplicationListQueryParams, {}>
  ) =>
    createAction(MfaApplicationListActionTypes.GET_MFA_APPLICATION_LIST, {
      params
    }),
  setMfaApplicationList: (
    list: MfaApplication[],
    totalResults: number,
    resultsPerPage = 25
  ) =>
    track(
      createAction(MfaApplicationListActionTypes.SET_MFA_APPLICATION_LIST, {
        list,
        totalResults,
        resultsPerPage
      }),
      {
        segment: uacEvent('MFA Apps Overview Viewed', {
          isEmpty: !list || !list.length,
          'App Count': totalResults,
          Source: 'MFA Apps Overview'
        })
      }
    ),
  setMfaApplicationsLoading: (loading: boolean) =>
    createAction(
      MfaApplicationListActionTypes.SET_MFA_APPLICATION_LIST_LOADING,
      {
        loading
      }
    ),
  setSelectedApplication: (app: MfaApplication) =>
    createAction(MfaApplicationListActionTypes.SET_SELECTED_APPLICATION, {
      app
    }),
  addNewApp: (totalResults: number) =>
    track(
      createAction(MfaApplicationListActionTypes.ADD_NEW_MFA_APPLICATION, {
        totalResults
      }),
      {
        segment: uacEvent('Add MFA App Clicked', {
          'App Count': totalResults,
          Source: 'MFA Apps Overview'
        })
      }
    ),
  setUsedAzureAdApps: (value: boolean) =>
    createAction(MfaApplicationListActionTypes.SET_USED_AZURE_AD_APPS, value),
  setUsedAdfsApps: (value: boolean) =>
    createAction(MfaApplicationListActionTypes.SET_USED_ADFS_APPS, value)
}

export type MfaApplicationListActions = ActionsUnion<
  typeof mfaApplicationListActions
>

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = response => createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await identityApi(
        `api/v2/Migration/MigrateAll`,
        'POST',
        {},
        undefined,
        true
      )
      if (status === StatusCodes.OK && response) {
        const migrateResponse = JSON.parse(response)
        return success(migrateResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

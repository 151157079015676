import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'

import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'

import {
  StyledChildRow,
  StyledPrimaryButton,
  StyledTextInput
} from './StyledComponents'

interface AdConnectorFormProps {
  customAttribute: string
  dataQaName: string
}

export const AdConnectorForm: FunctionComponent<AdConnectorFormProps> = ({
  customAttribute,
  dataQaName
}) => {
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{
        customAttribute: customAttribute
      }}
      onSubmit={values => {
        dispatch(
          federatedLoginActions.saveAdAttributeName({
            adAttributeName: values.customAttribute
          })
        )
      }}
    >
      {formikProps => {
        return (
          <>
            <StyledChildRow>
              <Field name="customAttribute">
                {formData => {
                  return (
                    <StyledTextInput
                      data-qa={`${dataQaName}CustomAttributeInput`}
                      name={formData.field.name}
                      value={formData.field.value}
                      onChange={e => {
                        formData.field.onChange(e)
                      }}
                    >
                      <Trans>Active Directory custom attribute name</Trans>
                    </StyledTextInput>
                  )
                }}
              </Field>
            </StyledChildRow>
            <StyledChildRow>
              <StyledPrimaryButton
                data-qa={`${dataQaName}SaveAttributeButton`}
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
              >
                <Trans>Save attribute</Trans>
              </StyledPrimaryButton>
            </StyledChildRow>
          </>
        )
      }}
    </Formik>
  )
}

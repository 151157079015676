import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'

import { buildFilterArray } from './helpers/build-filter-array'

export function createUserListSaga(userService: UACServices.Services) {
  return function* getUserListSaga(
    action: ReturnType<typeof userListActions.getUserList>
  ) {
    try {
      const userFilters =
        action.payload.params.query && action.payload.params.query.filter

      const filters = userFilters && buildFilterArray(userFilters)

      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        filter: filters ? { and: filters } : undefined,
        search: encodeSearch(action.payload.params.query.search),
        top: resultsPerPage,
        orderBy: action.payload.params.query.orderBy
      })
      yield put(userListActions.setUsersLoading(true))
      try {
        const result: UACServices.UsersAPI.Responses = yield call(
          userService.users,
          query
        )
        if (result.type === UACServices.UsersAPI.SUCCESS) {
          const userList = result.body.userList
          const totalResults = result.body.totalResults
          const totalCompanyUsers = result.body.totalCompanyUsers
          yield put(
            userListActions.setUserList(userList, totalResults, resultsPerPage)
          )
          yield put(userListActions.setCompanyUserCount(totalCompanyUsers))
        }
      } finally {
        yield put(userListActions.setUsersLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  Heading100,
  Heading300,
  PrimaryButton,
  Select
} from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'
import {
  BodySemiboldStyle,
  CaptionRegularStyle
} from '@lastpass/lastkit/styles'

import { MacManualPageContainer } from '@lastpass/admin-console-dependencies/ui/advanced/install-software/MacManualPageContainer'
import { MacSilentPageContainer } from '@lastpass/admin-console-dependencies/ui/advanced/install-software/MacSilentPageContainer'
import { WindowsManualPageContainer } from '@lastpass/admin-console-dependencies/ui/advanced/install-software/WindowsManualPageContainer'
import { WindowsSilentPageContainer } from '@lastpass/admin-console-dependencies/ui/advanced/install-software/WindowsSilentPageContainer'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

const StyledHeading100 = styled(Heading100)`
  margin-top: 25px;
`

const PlatformDiv = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`

const TypeDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`

const StyledPlatformSelect = styled(Select)`
  width: 250px;
  margin-left: 10px;
`

const StyledDropdownContainer = styled.div`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 40px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 340px;
`

const StyledInstallationType = styled.div`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

const StyledLabel = styled.label`
  ${CaptionRegularStyle};
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin-right: 50px;
`

const StyledForm = styled.form`
  display: flex;
  margin-left: 10px;
  align-items: center;
`

const StyledInput = styled.input`
  margin-top: 0;
  margin-right: 8px;
`

const ContentContainer: React.FunctionComponent<{
  platform: string
  type: string
}> = ({ platform, type }) => {
  if (platform == 'win') {
    if (type == 'automatedSilentInstall') {
      return <WindowsSilentPageContainer />
    } else {
      return <WindowsManualPageContainer />
    }
  } else {
    if (type == 'automatedSilentInstall') {
      return <MacSilentPageContainer />
    } else {
      return <MacManualPageContainer />
    }
  }
}

const ButtonContainer: React.FunctionComponent<{
  platform: string
  type: string
}> = ({ platform, type }) => {
  if (platform == 'win') {
    if (type == 'automatedSilentInstall') {
      return (
        <StyledPrimaryButton
          data-qa={'DownloadSoftwareWindowsSilentButton'}
          onClick={() => {
            window.open(
              'https://download.cloud.lastpass.com/windows_installer/LastPassInstaller.msi',
              '_blank'
            )
          }}
        >
          <Trans>Download (Microsoft Windows, silent)</Trans>
        </StyledPrimaryButton>
      )
    } else {
      return (
        <StyledPrimaryButton
          data-qa={'DownloadSoftwareWindowsGuiButton'}
          onClick={() => {
            window.open(
              'https://download.cloud.lastpass.com/windows_installer/LastPassInstaller.exe',
              '_blank'
            )
          }}
        >
          <Trans>Download (Microsoft Windows, GUI)</Trans>
        </StyledPrimaryButton>
      )
    }
  } else {
    return <></>
  }
}

export const InstallSoftwaresContainer: React.FunctionComponent = () => {
  const [platform, setPlatform] = useState('win')
  const [installType, setInstallType] = useState('manualGuiInstall')
  return (
    <>
      <PageHeaderContainer data-qa={'PageHeaderContainer'}>
        <Heading300 data-qa={'InstallSoftwarePageHeader'}>
          <Trans>Install software</Trans>
        </Heading300>
        <StyledHeading100>
          <Trans>Choose your installation method</Trans>
        </StyledHeading100>
      </PageHeaderContainer>
      <PageHeaderContainer>
        <PlatformDiv>
          <StyledDropdownContainer>
            <Trans>Platform</Trans>
            <StyledPlatformSelect
              dataQa={'SelectPlatformDropdown'}
              fluid
              onChange={option => {
                if (option) {
                  setPlatform(option['value'])
                }
              }}
              options={[
                { label: 'Microsoft Windows', value: 'win' },
                { label: 'macOS', value: 'osx' }
              ]}
              defaultValue={{ label: 'Microsoft Windows', value: 'win' }}
              menuPlacement="bottom"
            />
          </StyledDropdownContainer>
          <ButtonContainer platform={platform} type={installType} />
        </PlatformDiv>
      </PageHeaderContainer>
      <PageHeaderContainer>
        <TypeDiv>
          <StyledInstallationType>
            <Trans>Installation method:</Trans>
          </StyledInstallationType>
          <StyledForm>
            <StyledLabel data-qa={'ManualGuiInstallRadio'}>
              <StyledInput
                onChange={event => {
                  setInstallType(event.currentTarget.value)
                }}
                type="radio"
                value="manualGuiInstall"
                checked={installType == 'manualGuiInstall'}
              />
              <Trans>Users install the app themselves</Trans>
            </StyledLabel>
            <StyledLabel data-qa={'AutomatedSilentInstallRadio'}>
              <StyledInput
                onChange={event => {
                  setInstallType(event.currentTarget.value)
                }}
                type="radio"
                value="automatedSilentInstall"
                checked={installType == 'automatedSilentInstall'}
              />
              <Trans>Admin performs automated silent install</Trans>
            </StyledLabel>
          </StyledForm>
        </TypeDiv>
      </PageHeaderContainer>
      <ContentContainer platform={platform} type={installType} />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Field, Formik } from 'formik'

import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  Select,
  TextButton,
  TextInput,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['trustedDevices']['passwordManager'],
  typeof userDrawerActions,
  { backPath: string }
>

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const FullWidthInputContainer = styled.div`
  width: 100%;
  margin-bottom: 28px;
`

const InputContainer = styled.div`
  flex-basis: calc(50% - 12px);
  margin-right: 12px;
  margin-bottom: 28px;

  &:nth-of-type(2n) {
    flex-basis: calc(50% - 12px);
    margin-right: 0;
    margin-left: 12px;
  }
`

export const EditLpDeviceDrawerPageComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: LpDevicesTable,
  actions,
  props
}) => {
  const queryParams = useQueryParams()
  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserTrustedDevices({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Edit password manager</Trans>)

  const [formSubmitted, setFormSubmitted] = useState(false)

  if (!LpDevicesTable || LpDevicesTable.isLoading) {
    return <Loading color={'blue900'} active={true} />
  }

  let LpDevice
  if (LpDevicesTable && LpDevicesTable.results) {
    LpDevicesTable.results.forEach(device => {
      if (device.id == pathParams.deviceId) {
        LpDevice = device
      }
    })
  }

  if (LpDevice && !formSubmitted) {
    return (
      <>
        <Formik
          initialValues={{
            label: LpDevice.label,
            isEnabled: LpDevice.isEnabled
          }}
          onSubmit={values => {
            pathParams.id &&
              actions.savePasswordManager(
                {
                  id: LpDevice.id,
                  uuid: LpDevice.uuid,
                  label: values.label,
                  type: LpDevice.type,
                  isEnabled: values.isEnabled === 'true'
                },
                pathParams.id
              )
            setFormSubmitted(true)
          }}
        >
          {formikProps => {
            return (
              <>
                <FormContainer>
                  <FullWidthInputContainer>
                    <TextInput
                      placeholder={msg`Device`}
                      value={LpDevice.type}
                      readOnly
                      fluid
                    >
                      <Trans>Device</Trans>
                    </TextInput>
                  </FullWidthInputContainer>
                  <FullWidthInputContainer>
                    <TextInput
                      placeholder={msg`UUID`}
                      value={LpDevice.UUID}
                      readOnly
                      fluid
                    >
                      <Trans>UUID</Trans>
                    </TextInput>
                  </FullWidthInputContainer>
                  <InputContainer>
                    <Field name="label">
                      {formData => (
                        <TextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          placeholder={msg`Optional`}
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Label</Trans>
                        </TextInput>
                      )}
                    </Field>
                  </InputContainer>

                  <InputContainer>
                    <Field name="isEnabled">
                      {formData => (
                        <Select
                          dataQa="SetStatusDropdown"
                          fluid
                          onChange={option =>
                            formikProps.setFieldValue(
                              formData.field.name,
                              option ? option['value'] : 'false'
                            )
                          }
                          options={[
                            { value: 'true', label: i18n._(msg`Enabled`) },
                            { value: 'false', label: i18n._(msg`Disabled`) }
                          ]}
                          defaultValue={
                            formData.field.value
                              ? { value: 'true', label: i18n._(msg`Enabled`) }
                              : { value: 'false', label: i18n._(msg`Disabled`) }
                          }
                        >
                          <Trans>Status</Trans>
                        </Select>
                      )}
                    </Field>
                  </InputContainer>
                </FormContainer>
                <FooterContainer>
                  <DrawerButtonsContainer>
                    <Link to={props.backPath}>
                      <TextButton
                        css={css`
                          margin-right: 10px;
                        `}
                        data-qa={'DiscardButton'}
                      >
                        <Trans>Discard</Trans>
                      </TextButton>
                    </Link>

                    <PrimaryButton
                      disabled={!!Object.keys(formikProps.errors).length}
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                      type="submit"
                      data-qa={'SaveChangesButton'}
                    >
                      <Trans>Save changes</Trans>
                    </PrimaryButton>
                  </DrawerButtonsContainer>
                </FooterContainer>
              </>
            )
          }}
        </Formik>
      </>
    )
  } else {
    return <Redirect to={`/users/view/${pathParams.id}/trusted-devices`} />
  }
}

export function createEditLpDeviceDrawerPage<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    EditLpDeviceDrawerPageComponent,
    (state: TState) => state.usersDrawer.trustedDevices.passwordManager,
    userDrawerActions
  )
}

export type EditProfileDrawerPage = ReturnType<
  typeof createEditLpDeviceDrawerPage
>

import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { NewAddUser } from './state'

export enum UserDrawerSegmentActionTypes {
  USER_ADDED = '[user-operation-segment] user-added'
}

export const userDrawerSegmentActions = {
  userAdded: (
    errorTypeDomainMismatch: number,
    errorTypeUserAlreadyinCompany: number,
    LPMFAInvitationSent: boolean,
    passwordManagerInvitationSent: boolean,
    numberFailed: number,
    numberSucceeded: number
  ) =>
    track(
      createAction(UserDrawerSegmentActionTypes.USER_ADDED, {
        errorTypeDomainMismatch,
        errorTypeUserAlreadyinCompany,
        LPMFAInvitationSent,
        numberFailed,
        numberSucceeded
      }),
      {
        segment: uacEvent('User Added', {
          'Error Type Domain Mismatch': errorTypeDomainMismatch,
          'Error Type User Already in Company': errorTypeUserAlreadyinCompany,
          'LP MFA Invitation Sent': LPMFAInvitationSent ? 'True' : 'False',
          'Number Failed': numberFailed,
          'Number Succeeded': numberSucceeded,
          'Password Manager Invitation Sent': passwordManagerInvitationSent
            ? 'True'
            : 'False'
        })
      }
    )
}

export enum AddUsersDrawerActionTypes {
  RESET = '[add-users-drawer] reset',
  GET_PENDING_ADD_USERS = '[add-users-drawer] get-pending-users',
  SET_PENDING_ADD_USERS = '[add-users-drawer] set-pending-users',
  SET_NEW_ADD_USERS = '[add-users-drawer] set-new-users',
  CLEAR_PENDING_ADD_USERS = '[add-users-drawer] clear-pending-users',
  SET_PENDING_ADD_USERS_LOADING = '[add-users-drawer] set-loading',
  PROCESS_PENDING_USERS_UPLOAD = '[add-users-drawer] process-pending-users-upload',
  ADD_NEW_USERS = '[add-users-drawer] add-new-users',
  SET_MFA_INVITE = '[add-users-drawer] set-mfa-invite',
  SET_LP_INVITE = ']add-users-drawer set-lp-invite',
  SEND_USER_VERIFICATION_EMAIL = '[add-users-drawer] send-user-verification-email'
}

export const addUsersDrawerActions = {
  reset: () => createAction(AddUsersDrawerActionTypes.RESET),

  getPendingAddUsers: () =>
    createAction(AddUsersDrawerActionTypes.GET_PENDING_ADD_USERS),
  setPendingAddUsers: (pendingAddUsers: NewAddUser[]) =>
    createAction(AddUsersDrawerActionTypes.SET_PENDING_ADD_USERS, {
      pendingAddUsers
    }),
  clearAddUsers: () =>
    createAction(AddUsersDrawerActionTypes.CLEAR_PENDING_ADD_USERS),
  setPendingAddUsersLoading: (loading: boolean) =>
    createAction(AddUsersDrawerActionTypes.SET_PENDING_ADD_USERS_LOADING, {
      loading
    }),
  setNewAddUsers: (newAddUsers: NewAddUser[]) =>
    createAction(AddUsersDrawerActionTypes.SET_NEW_ADD_USERS, { newAddUsers }),
  processPendingUsersUpload: (
    selectorFiles: FileList,
    currentFormPendingUsers: NewAddUser[]
  ) =>
    createAction(AddUsersDrawerActionTypes.PROCESS_PENDING_USERS_UPLOAD, {
      selectorFiles,
      currentFormPendingUsers
    }),
  addNewUsers: (
    newUsers: NewAddUser[],
    passwordManagementInvite: boolean,
    lastPassMFAInvite: boolean
  ) =>
    createAction(AddUsersDrawerActionTypes.ADD_NEW_USERS, {
      newUsers,
      passwordManagementInvite,
      lastPassMFAInvite
    }),
  setMfaInvite: (sendInvite: boolean) =>
    createAction(AddUsersDrawerActionTypes.SET_MFA_INVITE, sendInvite),
  setLpInvite: (sendInvite: boolean) =>
    createAction(AddUsersDrawerActionTypes.SET_LP_INVITE, sendInvite),
  sendUserVerificationEmail: () =>
    createAction(AddUsersDrawerActionTypes.SEND_USER_VERIFICATION_EMAIL)
}

export type AddUsersDrawerActions = ActionsUnion<typeof addUsersDrawerActions>

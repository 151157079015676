import React from 'react'
import { useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { BodyRegular, Heading300 } from '@lastpass/lastkit'

import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'

export interface AdvancedUserActivityMainHeaderProps {
  defaultHeader: string
}

export const AdvancedUserActivityMainHeader: React.FC<AdvancedUserActivityMainHeaderProps> = ({
  defaultHeader
}) => {
  const { selectedSavedReport } = useSelector<
    AppState,
    AdvancedUserActivityListState
  >(state => state.advancedUserActivityEvents)

  let reportName, adminEmail
  if (selectedSavedReport) {
    reportName = selectedSavedReport.name
    adminEmail = selectedSavedReport.adminEmail
  }

  return (
    <div>
      <Heading300 data-qa="GeneralReportsPageHeader">
        {reportName || defaultHeader}
      </Heading300>
      {adminEmail && <BodyRegular>{adminEmail}</BodyRegular>}
    </div>
  )
}

import { Group } from '@lastpass/admin-console-dependencies/state/applications/application'
import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

import { MfaApplication } from '../mfa-application'

export enum MfaAppsDrawerSteps {
  create = 'create',
  finish = 'finish',
  edit = 'edit',
  assign = 'assign',
  newAssign = 'newAssign',
  allSet = 'allSet'
}

export interface MfaAppsDrawerState {
  isLoading: boolean
  history: MfaAppsDrawerSteps[]
  actualStep: MfaAppsDrawerSteps | null
  canSave: boolean
  app: MfaApplication
  potentialMappingKeys: string[]
  showTable: string
  availableUsers: Table<User>
  usersSearchInput: string
  users: Table<User>
  availableGroups: Table<Group>
  groupsSearchInput: string
  groups: Table<Group>
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'

import { PasswordlessEmptyList } from './PasswordlessEmptyList'
import { PasswordlessPage } from './PasswordlessPage'

export const PasswordlessContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch])

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isEmpty = usePasswordlessPoliciesEmptyState()

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  return isEmpty || !userHasMfaAddon ? (
    <PasswordlessEmptyList />
  ) : (
    <PasswordlessPage isEmpty={isEmpty} />
  )
}

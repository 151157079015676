import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as PolicyIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import { Loading } from '@lastpass/components'
import {
  DrawerTable as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'

import { TeamsPolicyGroup } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

export interface GeneralPolicyGroupsTableProps {
  groupsTable: Table<TeamsPolicyGroup>
  addSelectedRecords: (groupList: TeamsPolicyGroup[]) => void
  removeSelectedRecords: (groupList: TeamsPolicyGroup[]) => void
  setSelectedRecords: (groupList: TeamsPolicyGroup[]) => void
  clearSearchQuery: () => void
  filterApplied?: boolean
  title: React.ReactElement
  text: React.ReactElement
  assignButton?: React.ReactElement
}

export const usersCountRenderer = (record: TeamsPolicyGroup) => (
  <>{record.userscount}</>
)

export const GeneralPolicyGroupsTable: FunctionComponent<GeneralPolicyGroupsTableProps> = ({
  groupsTable,
  filterApplied,
  ...props
}) => {
  return (
    <TableComponent
      table={groupsTable}
      checkable={{
        addSelectedRecords: props.addSelectedRecords,
        removeSelectedRecords: props.removeSelectedRecords,
        setSelectedRecords: props.setSelectedRecords
      }}
      noRecordsView={
        <TableView
          icon={<PolicyIcon />}
          title={props.title}
          text={props.text}
          actionButton={props.assignButton}
        />
      }
      noResultsView={
        <TableView
          icon={<PolicyIcon />}
          title={<Trans>Sorry, no results match your search</Trans>}
          text={<Trans>Try different keywords or check your spelling</Trans>}
          actionButton={
            <TableViewButton onClick={props.clearSearchQuery}>
              <Trans>Clear search query</Trans>
            </TableViewButton>
          }
        />
      }
      loadingView={<Loading color="blue900" active={true} />}
      columns={[
        {
          name: <Trans> Name</Trans>,
          renderer: (record: TeamsPolicyGroup) => record.name
        },
        {
          name: <Trans>Users</Trans>,
          renderer: usersCountRenderer
        }
      ]}
      isFilterApplied={filterApplied}
      actions={[]}
    />
  )
}

import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import { createAction } from '@lastpass/admin-console-dependencies/state/action'

import { DirectoriesActionTypes } from './actions'
import { Provider } from './state'

export const directoryMap: {
  [s: string]: SegmentPropertyType<'Configure Directory Clicked', 'Directory'>
} = {
  ADConnector: 'AD Connector',
  AzureAD: 'Azure AD',
  Okta: 'Okta',
  OneLogin: 'OneLogin',
  Google: 'Google'
}

export enum DirectoriesSegmentActionTypes {
  CONFIGURE_DIRECTORY_CLICKED = '[directories-integrations] configure-directory-clicked',
  INTEGRATION_LOGS_CLICKED = '[directories-integrations] integration-logs-clicked'
}

export const createConfigureDirectoryClickedSegment = (provider: Provider) => ({
  segment: uacEvent('Configure Directory Clicked', {
    Directory: directoryMap[provider]
  })
})

export const createRemoveConfigureDirectoryClickedSegment = (
  provider: Provider
) => ({
  segment: uacEvent('Remove Directory Configuration Clicked', {
    Directory: directoryMap[provider]
  })
})

export const directoriesSegmentActions = {
  configureDirectoryClicked: (provider: Provider) =>
    track(
      createAction(DirectoriesSegmentActionTypes.CONFIGURE_DIRECTORY_CLICKED, {
        provider
      }),
      createConfigureDirectoryClickedSegment(provider)
    )
}

export const createIntegrationLogsClickedAction = () =>
  track(createAction(DirectoriesSegmentActionTypes.INTEGRATION_LOGS_CLICKED), {
    segment: uacEvent('Google Integration Logs Opened')
  })

export const createUpdateGoogleSyncOptionsAction = (
  partialSyncEnabled: boolean,
  adminEmail: string,
  provisionToken: string,
  enabled: boolean,
  callback: () => void
) =>
  track(
    createAction(DirectoriesActionTypes.UPDATE_GOOGLE_SYNC_OPTIONS, {
      partialSyncEnabled,
      adminEmail,
      provisionToken,
      enabled,
      callback
    }),
    {
      segment: uacEvent('Google Sync options Opened')
    }
  )

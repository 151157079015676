import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  PrimaryButton,
  StyledSpacer,
  TableViewButton,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  applicationsDrawerActions,
  applicationsDrawerSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { Entities } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { GroupsTableComponent } from './GeneralApplicationsGroupsTable'
import { RolesTableComponent } from './GeneralApplicationsRolesTable'
import { UsersTableComponent } from './GeneralApplicationsUsersTable'

export interface AssignProps {
  closeLink: string
}

type AssignComponentProps = LastPassComponentProps<
  ApplicationDrawerState,
  typeof applicationsDrawerActions & typeof applicationsDrawerSegmentActions,
  AssignProps
>

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

export const AssignComponent: React.FunctionComponent<AssignComponentProps> = ({
  actions,
  state,
  props
}) => {
  const history = useHistory()
  const unassignDisabled =
    state.users.checkedRecords.length === 0 &&
    state.groups.checkedRecords.length === 0 &&
    state.roles.checkedRecords.length === 0

  useEffect(() => {
    if (
      state.selectedApplication &&
      state.selectedApplication.id &&
      !state.assignPageOpened
    ) {
      actions.getAssignedElements(state.selectedApplication.id)
      actions.setAssignPageOpened(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  return (
    <>
      <TableContainer>
        <StyledTabContainer data-qa={'TabNavigationBar'}>
          <TabNavigation>
            <div
              data-qa={'AssignUsersTab'}
              onClick={() => {
                actions.showTable(Entities.users)
              }}
            >
              <StyledTabNavigationItem
                active={state.showTable === Entities.users}
              >
                <Trans>Users</Trans>
                {` (${state.users.totalResults})`}
              </StyledTabNavigationItem>
            </div>
            <div
              data-qa={'AssignGroupsTab'}
              onClick={() => {
                actions.showTable(Entities.groups)
              }}
            >
              <StyledTabNavigationItem
                active={state.showTable === Entities.groups}
              >
                <Trans>Groups</Trans>
                {` (${state.groups.totalResults})`}
              </StyledTabNavigationItem>
            </div>
            {!isNoPasswordDeprecated && (
              <div
                data-qa={'AssignRolesTab'}
                onClick={() => {
                  actions.showTable(Entities.roles)
                }}
              >
                <StyledTabNavigationItem
                  active={state.showTable === Entities.roles}
                >
                  <Trans>Roles</Trans>
                  {` (${state.roles.totalResults})`}
                </StyledTabNavigationItem>
              </div>
            )}
          </TabNavigation>
          <PrimaryButton
            data-qa={'NewAssignButton'}
            onClick={() => {
              actions.nextStep(ApplicationDrawerSteps.newAssign)
            }}
          >
            {isNoPasswordDeprecated ? (
              <Trans>Assign users {'&'} groups</Trans>
            ) : (
              <Trans>Assign users, groups {'&'} roles</Trans>
            )}
          </PrimaryButton>
        </StyledTabContainer>
      </TableContainer>
      {state.showTable === Entities.users ? (
        <UsersTableComponent
          table={state.users}
          checkable={{
            addSelectedRecords: actions.addSelectedUsersToUnassign,
            removeSelectedRecords: actions.removeSelectedUsersToUnassign,
            setSelectedRecords: actions.setSelectedUsersToUnassign
          }}
          title={<Trans>No users assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some users.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                actions.nextStep(ApplicationDrawerSteps.newAssign)
              }}
            >
              <TableViewButton data-qa={'AssignUsersToSsoAppButton'}>
                <Trans>Assign users</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      {state.showTable === Entities.groups ? (
        <GroupsTableComponent
          table={state.groups}
          checkable={{
            addSelectedRecords: actions.addSelectedGroupsToUnassign,
            removeSelectedRecords: actions.removeSelectedGroupsToUnassign,
            setSelectedRecords: actions.setSelectedGroupsToUnassign
          }}
          title={<Trans>No groups assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some groups.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                actions.nextStep(ApplicationDrawerSteps.newAssign)
              }}
            >
              <TableViewButton data-qa={'AssignGroupsToSsoAppButton'}>
                <Trans>Assign groups</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      {state.showTable === Entities.roles ? (
        <RolesTableComponent
          table={state.roles}
          checkable={{
            addSelectedRecords: actions.addSelectedRolesToUnassign,
            removeSelectedRecords: actions.removeSelectedRolesToUnassign,
            setSelectedRecords: actions.setSelectedRolesToUnassign
          }}
          title={<Trans>No roles assigned yet</Trans>}
          text={<Trans>Make this app useful by assigning some roles.</Trans>}
          actionButton={
            <LocationLink
              to={`#`}
              onClick={() => {
                actions.nextStep(ApplicationDrawerSteps.newAssign)
              }}
            >
              <TableViewButton data-qa={'AssignRolesToSsoAppButton'}>
                <Trans>Assign roles</Trans>
              </TableViewButton>
            </LocationLink>
          }
        />
      ) : null}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => history.push(props.closeLink)}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            css={css`
              margin-right: 16px;
            `}
            id="UnassignButton"
            type="submit"
            key="Unassign"
            onClick={() => {
              actions.unassign()
            }}
            disabled={unassignDisabled}
            data-qa="UnassignButton"
          >
            <Trans>Unassign</Trans>
          </PrimaryButton>
          <PrimaryButton
            id="ContinueButton"
            type="submit"
            key="Continue"
            onClick={() => {
              if (state.selectedApplication) {
                actions.saveAndAssignUsers(
                  state.selectedApplication,
                  state.users.results,
                  state.groups.results,
                  state.roles.results
                )
              }
            }}
            disabled={false}
            data-qa="SaveAndContinueButton"
          >
            <Trans>Save & continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

export function createAssign<
  TState extends { applicationDrawer: ApplicationDrawerState }
>() {
  return connectRedux(
    AssignComponent,
    (state: TState) => state.applicationDrawer,
    {
      ...applicationsDrawerActions,
      ...applicationsDrawerSegmentActions
    }
  )
}

export type AssignUsers = ReturnType<typeof createAssign>

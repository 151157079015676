import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Checkbox } from '@lastpass/lastkit'

import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionOption } from '@lastpass/admin-console-dependencies/types/permissions/permission-option'
import { PermissionOptionType } from '@lastpass/admin-console-dependencies/types/permissions/permission-option-type'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { PermissionTooltipTextMapping } from '@lastpass/admin-console-dependencies/ui/users/admin/mappings/PermissionTooltipTextMapping'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

const FlexColumn = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
`
export interface OptionsProps {
  options: PermissionOption[]
  formik: FormikProps<AdminFormValues>
  disabled?: boolean
}

export const Options: React.FunctionComponent<OptionsProps> = ({
  options,
  formik,
  disabled
}) => {
  const {
    adminLevel,
    permissionCategories
  }: AdminLevelDrawerState = useSelector(
    (state: AppState) => state.adminLevelDrawer
  )

  const isBuiltInAdminLevel =
    adminLevel.id === AdminLevel.admin ||
    adminLevel.id === AdminLevel.helpdeskAdmin ||
    adminLevel.id === AdminLevel.superAdmin

  const permissions = useContext(PermissionContext)
  const canModifyAdmins = permissions.requirePermission(
    AdminPermissions.adminsModify
  )

  const getPermissionOptionByName = (permission: AdminPermissions) => {
    for (const permissionCategory of permissionCategories) {
      for (const permissionArea of permissionCategory.permissionAreas) {
        for (const permissionOption of permissionArea.permissionOptions) {
          if (permissionOption.permission === permission) {
            return permissionOption
          }
        }
      }
    }
  }

  const optionsToAdd: AdminPermissions[] = []
  const addOption = (option: PermissionOption | undefined) => {
    if (option) {
      if (!formik.values.levelPermission.includes(option.permission)) {
        optionsToAdd.push(option.permission)
      }
      option.dependencies.forEach(dependencyObject => {
        addOption(getPermissionOptionByName(dependencyObject.dependency))
      })
    }
  }

  const optionsToRemove: AdminPermissions[] = []
  const removeOptions = (option: PermissionOption | undefined) => {
    if (option) {
      if (option.dependants.length !== 0) {
        option.dependants.forEach(dependantObject => {
          if (
            formik.values.levelPermission.includes(dependantObject.dependant)
          ) {
            removeOptions(getPermissionOptionByName(dependantObject.dependant))
          }
        })
      }
      if (formik.values.levelPermission.includes(option.permission)) {
        optionsToRemove.push(option.permission)
      }
    }
  }

  const handleChange = (event, option) => {
    const { checked } = event.target
    if (checked) {
      addOption(option)
      formik.setFieldValue('levelPermission', [
        ...formik.values.levelPermission,
        ...optionsToAdd
      ])
    } else {
      removeOptions(option)
      formik.setFieldValue(
        'levelPermission',
        formik.values.levelPermission.filter(
          (value: AdminPermissions) => !optionsToRemove.includes(value)
        )
      )
    }
  }

  const getCheckbox = (option, index) => (
    <FlexColumn
      key={index}
      data-qa={`PermissionValue_${option.permissionOptionType}`}
    >
      <Checkbox
        id="levelPermission"
        name="levelPermission"
        data-qa="permissionCheckbox"
        value={option.permission}
        checked={formik.values.levelPermission.includes(option.permission)}
        onChange={() => handleChange(event, option)}
        toolTipWithoutHelpIcon={PermissionTooltipTextMapping[option.permission]}
        disabled={!canModifyAdmins || isBuiltInAdminLevel || disabled}
      />
    </FlexColumn>
  )

  let checkboxes = (
    <>{options.map((option, index) => getCheckbox(option, index))}</>
  )

  if (options.length === 1) {
    const option = options[0]
    if (option.permissionOptionType === PermissionOptionType.view) {
      checkboxes = getCheckbox(option, null)
    }

    if (option.permissionOptionType === PermissionOptionType.modify) {
      checkboxes = (
        <>
          <FlexColumn />
          {getCheckbox(option, null)}
        </>
      )
    }
  }

  return checkboxes
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createGetADAPIKeyExistence(userServices: UACServices.Services) {
  return function* getADAPIKeyExistence() {
    try {
      const hasAPIKeyResult: UACServices.ADConnectorKeyExistenceGetAPI.Responses = yield call(
        userServices.adConnectorAPIKeyExistence
      )
      if (hasAPIKeyResult) {
        yield put(
          directoriesActions.setADConnectorAPIKeyExistence(
            hasAPIKeyResult.body.hasAPIKey
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

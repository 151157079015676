import React, { ReactElement, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Drawer } from '@lastpass/lastkit'
import { PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  MspCidOverrideLevel,
  UserLevelWithPermissions
} from '@lastpass/admin-console-dependencies/types/user-level'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { AdminLevelPermissionTreeForm } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/components/AdminLevelPermissionTreeForm'
import { AdminOperationsDropdown } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/operations-dropdown/AdminOperationsDropdown'
import { UserLevelLabel } from '@lastpass/admin-console-dependencies/ui/users/view/UserLevelLabel'

import { AdminLevelDescription } from '../shared/admin-level-description/AdminLevelDescription'
import { AdminLevelUsersSummaryCard } from './components/AdminLevelUsersSummaryCard'

interface AdminLevelDetailsDrawerProps {
  closeLink: string
  backButton?: ReactElement
}

interface AdminLevelDetailsDrawerParams {
  id: string
}

export const AdminLevelDetailsDrawer: React.FunctionComponent<AdminLevelDetailsDrawerProps> = ({
  closeLink,
  backButton
}) => {
  const dispatch = useDispatch()
  const match = useRouteMatch<AdminLevelDetailsDrawerParams>()
  const pathParams = usePathParams()
  const permissions = useContext(PermissionContext)
  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const adminLevel: UserLevelWithPermissions = useSelector(
    (state: AppState) => state.adminLevelDrawer.adminLevel
  )

  const canModifyAdminLevels = permissions.requirePermission(
    AdminPermissions.adminsModify
  )

  useEffect(() => {
    dispatch(
      adminLevelDrawerActions.getCurrentAdminLevel({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  useUnmountEffect(() => {
    dispatch(adminLevelDrawerActions.reset())
  })

  const moreButton = adminLevel.isCustom ? <AdminOperationsDropdown /> : <></>

  const assignButton = canModifyAdminLevels && (
    <LocationLink
      to={`${location.pathname}/add`}
      discardnamespace={Namespace.addUsersToAdmin}
    >
      <PrimaryButton data-qa="AdminRolesDetailsAssignUsersButton">
        <Trans>Assign users</Trans>
      </PrimaryButton>
    </LocationLink>
  )

  if (!match) {
    return null
  }

  const { url } = match
  return (
    <Drawer
      mainTitle={<UserLevelLabel userLevel={adminLevel} />}
      closeLink={closeLink}
      headerNodes={
        <>
          {assignButton}
          {canModifyAdminLevels && backButton}
          {moreButton}
        </>
      }
    >
      <AdminLevelDescription
        description={adminLevel.description}
        adminLevelId={adminLevel.id}
      />
      <AdminLevelUsersSummaryCard url={url} />
      <AdminLevelPermissionTreeForm />
      {isRoleBasedAccessControlEnabled &&
        (adminLevel.mspCidOverrideAdminLevel === MspCidOverrideLevel.admin ||
          adminLevel.mspCidOverrideAdminLevel ===
            MspCidOverrideLevel.custom) && (
          <AdminLevelPermissionTreeForm hasManagedAdminPermissions />
        )}
    </Drawer>
  )
}

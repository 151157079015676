import React, { FunctionComponent, HTMLAttributes } from 'react'

import styled from '@emotion/styled'

interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon: React.ComponentType
  disabled?: boolean
  expanded?: boolean
}

const StyledIconButton = styled.button<{ expanded?: boolean }>`
  display: inline-flex;
  align-items: center;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.neutral300};
  box-sizing: border-box;
  box-shadow: ${props => props.theme.elevations.elevation100};
  border-radius: ${props => props.theme.radius.full};
  cursor: pointer;
  height: 40px;
  width: 40px;
  outline: none;
  &:hover {
    border: 1px solid ${props => props.theme.colors.blue800};
  }
  &:disabled {
    background: ${props => props.theme.colors.neutral200};
    border: 1px solid ${props => props.theme.colors.neutral300};
  }
  &:active {
    background: ${props => props.theme.colors.blue200};
    border: ${props => props.theme.colors.blue900};
  }
  &:focus {
    border: 2px solid ${props => props.theme.colors.teal500};
  }
  &:active:hover {
    background: ${props => props.theme.colors.blue900};
  }
  &:active svg {
    color: ${props => props.theme.colors.white};
  }

  &:disabled svg {
    color: ${props => props.theme.colors.neutral500};
  }
  ${props =>
    props.expanded &&
    `background: ${props.theme.colors.blue200}; border: 1px solid ${props.theme.colors.blue900};`}
`

export const IconButton: FunctionComponent<IconButtonProps> = ({
  icon,
  color,
  ...props
}) => {
  const iconColor = props =>
    (color && props.theme.colors[color]) || props.theme.colors.neutral700
  const StyledIcon = styled(icon)`
    color: ${iconColor};
    width: 16px;
    height: 16px;
    margin: 0 auto;
  `

  return (
    <StyledIconButton {...props}>
      <StyledIcon />
    </StyledIconButton>
  )
}

import { call, put } from 'redux-saga/effects'

import { UserTableRepository } from '@lastpass/admin-console-dependencies/repositories'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'

export function createSaveUserTableSettings() {
  return function* saveUserTableSettings(
    action: ReturnType<typeof userListActions.setUserListColumns>
  ) {
    try {
      const userListColumns = action.payload.columns
      yield call(UserTableRepository.set, userListColumns)
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { ReactComponent as SuccessIcon } from '@lastpass/assets/svg/security-dashboard/icon-check-circle.svg'
import { ReactComponent as AlertIcon } from '@lastpass/assets/svg/security-dashboard/icon-error-dialog.svg'
import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/security-dashboard/icon-warning-dialog.svg'
import {
  BodyRegularStyle,
  Heading100,
  Heading300,
  TextButton,
  WithTooltip
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  applicationListActions,
  applicationListSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import {
  DashboardState,
  SecurityScoreDataState
} from '@lastpass/admin-console-dependencies/state/dashboard/state'
import { UsersListState } from '@lastpass/admin-console-dependencies/state/users/view/list/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { createUserListColumnsQueryString } from '@lastpass/admin-console-dependencies/ui/users/view/UserList'

type SecurityScoreDataProps = LastPassComponentProps<
  SecurityScoreDataState,
  typeof dashboardActions,
  {}
>

enum SsoSecurityState {
  notSet = 'notSet',
  set = 'set',
  setAndAssigned = 'setandassigned'
}

const RowSpacer = styled.div`
  display: flex;
`

const Container = styled.div`
  display: block;
  width: 100%;
`

const Cell = styled.div`
  padding: 16px;
  width: 50%;
  border: 1px solid ${props => props.theme.colors.neutral100};
`

const Row = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral100};
  display: flex;
  justify-content: left;
  align-items: center;
  ${BodyRegularStyle}
`

const FirstColumn = styled.div`
  display: flex;
  justify-content: left;
  width: 40px;
`

const LastColumn = styled.div`
  margin-left: auto;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
`

const StyledAlertIcon = styled(AlertIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 14.57px;
  width: 15.13px;
`
const StyledWarningIcon = styled(WarningIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 14.57px;
  width: 15.13px;
`

const StyledSuccessIcon = styled(SuccessIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  width: 16px;
`

export const SecurityScoreDataComponent: React.FunctionComponent<SecurityScoreDataProps> = ({
  state,
  actions
}) => {
  useEffect(() => {
    actions.getSecurityScoreData()
  }, [actions])

  const {
    table: { columns: usersListColumns }
  } = useSelector<AppState, UsersListState>(state => state.usersList)

  const queryParams = useQueryParams(Namespace.apps)

  const permissions = useContext(PermissionContext)
  const canModifySsoApps = permissions.requirePermission(
    AdminPermissions.ssoModify
  )

  const currentPath = '/home/security-dashboard'
  const dispatch = useDispatch()
  useEffect(() => {
    if (canModifySsoApps) {
      dispatch(
        applicationListActions.getApplicationList({
          query: queryParams,
          path: {}
        })
      )
    }
  }, [dispatch, queryParams, canModifySsoApps])

  const applicationState = useSelector<AppState, ApplicationListState>(
    appState => appState.applicationList
  )

  const getSsoState = () => {
    const nonLegacyAppWithAssignedUsersExists =
      applicationState.table.totalResults > 0 &&
      applicationState.table.results.some(app => app.isUserAssigned)

    const legacyAppExists =
      applicationState.table.totalResults > 0 &&
      applicationState.table.results.some(app => app.id.startsWith('l_'))

    if (nonLegacyAppWithAssignedUsersExists || legacyAppExists) {
      return SsoSecurityState.setAndAssigned
    } else if (applicationState.table.totalResults > 0) {
      return SsoSecurityState.set
    } else {
      return SsoSecurityState.notSet
    }
  }

  let SsoWidgetIcon = <StyledAlertIcon data-qa="SsoWidgetAlertIcon" />
  let SsoWidgetTitle = <Trans>Single sign-on is not configured</Trans>
  let SsoWidgetActionName = <Trans>Get started</Trans>

  const ssoState = getSsoState()

  switch (ssoState) {
    case SsoSecurityState.setAndAssigned:
      SsoWidgetIcon = <StyledSuccessIcon data-qa="SsoWidgetSuccessIcon" />
      SsoWidgetTitle = <Trans>Single sign-on is configured</Trans>
      SsoWidgetActionName = <Trans>Go to SSO apps</Trans>
      break
    case SsoSecurityState.set:
      SsoWidgetIcon = <StyledWarningIcon data-qa="SsoWidgetWarningIcon" />
      SsoWidgetTitle = <Trans>SSO isn’t configured: No users assigned</Trans>
      SsoWidgetActionName = <Trans>Go to SSO apps</Trans>
      break
    default:
      break
  }

  return (
    <Container data-qa={'SecurityScoreDataBox'}>
      <RowSpacer>
        <Cell
          css={css`
            border-left: 0;
          `}
        >
          <Heading300 data-qa={'AverageScore'}>
            <Trans>
              {state.averageScore != null
                ? Math.round(state.averageScore) + '%'
                : 'N/A'}
            </Trans>
          </Heading300>
          <Heading100>
            <Trans>Average security score</Trans>
          </Heading100>
          <LocationLink
            to={`/users/view?${createUserListColumnsQueryString({
              orderBy: 'securityChallengeScore desc',
              userListColumns: [
                ...usersListColumns,
                UserListTableColumn.securityScore
              ]
            })}`}
          >
            <TextButton
              data-qa={'AverageSecurityScoreButton'}
              blue
              css={css`
                margin-left: -5px;
              `}
            >
              <Trans>View users</Trans>
            </TextButton>
          </LocationLink>
        </Cell>
        <Cell
          css={css`
            border-left: 0;
            border-right: 0;
          `}
        >
          <Heading300 data-qa={'AveragePasswordStrength'}>
            <Trans>
              {state.averagePasswordStrength != null
                ? Math.round(state.averagePasswordStrength) + '%'
                : 'N/A'}
            </Trans>
          </Heading300>
          <Heading100>
            <Trans>Average password strength</Trans>
          </Heading100>
        </Cell>
      </RowSpacer>
      {canModifySsoApps && (
        <Row data-qa={'SsoNotConfigured'}>
          <FirstColumn>{SsoWidgetIcon}</FirstColumn>
          {SsoWidgetTitle}
          {ssoState != SsoSecurityState.setAndAssigned && (
            <WithTooltip
              tooltip={
                <>
                  <Trans>
                    Fewer passwords, less risk. Boost security by making
                    LastPass your identity provider. Users will log into
                    SSO-capable apps using their LastPass master password.
                  </Trans>
                  <LocationLink
                    to={'https://www.lastpass.com/products/sso'}
                    data-qa={'SsoNotConfiguredTooltipLearnMore'}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      dispatch(
                        applicationListSegmentActions.ssoSecurityDashboardWidgetClicked(
                          'Learn More'
                        )
                      )
                    }
                  >
                    <>
                      &nbsp;<Trans>Learn more</Trans>
                    </>
                  </LocationLink>
                </>
              }
              trackingEvent={() =>
                dispatch(
                  applicationListSegmentActions.ssoSecurityDashboardTooltipViewed()
                )
              }
            >
              <StyledHelpIcon data-qa="BlockHeaderHelpIcon" />
            </WithTooltip>
          )}
          <LastColumn>
            <LocationLink to={'/applications/saml'}>
              <TextButton
                data-qa={'SsoNotConfiguredButton'}
                blue
                css={css`
                  margin-left: -5px;
                `}
                onClick={() => {
                  ssoState == SsoSecurityState.notSet
                    ? dispatch(
                        applicationListSegmentActions.ssoSecurityDashboardWidgetClicked(
                          'Get started'
                        )
                      )
                    : dispatch(
                        applicationListSegmentActions.ssoSecurityDashboardWidgetClicked(
                          'Go to SSO apps'
                        )
                      )
                }}
              >
                {SsoWidgetActionName}
              </TextButton>
            </LocationLink>
          </LastColumn>
        </Row>
      )}

      <Row data-qa={'WeakMasterPassword'}>
        <FirstColumn>
          <Trans>
            {state.weakMasterPassword != null
              ? Math.round(state.weakMasterPassword) + '%'
              : 'N/A'}{' '}
          </Trans>
        </FirstColumn>
        <Trans>Weak master password</Trans>
        <LastColumn>
          <LocationLink to={currentPath + '/weakmasterpasslist'}>
            <TextButton
              data-qa={'WeakMasterPasswordButton'}
              blue
              css={css`
                margin-left: -5px;
              `}
            >
              <Trans>View users</Trans>
            </TextButton>
          </LocationLink>
        </LastColumn>
      </Row>

      <Row data-qa={'ReusedMasterPassword'}>
        <FirstColumn>
          <Trans>
            {state.reusedMasterPassword != null
              ? Math.round(state.reusedMasterPassword) + '%'
              : 'N/A'}{' '}
          </Trans>
        </FirstColumn>
        <Trans>Reused master password</Trans>
        <LastColumn>
          <LocationLink to={currentPath + '/reusedmasterpasswordlist'}>
            <TextButton
              data-qa={'ReusedMasterPasswordButton'}
              blue
              css={css`
                margin-left: -5px;
              `}
            >
              <Trans>View users</Trans>
            </TextButton>
          </LocationLink>
        </LastColumn>
      </Row>
    </Container>
  )
}

export function createSecurityScoreDataComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    SecurityScoreDataComponent,
    (state: TState) => state.dashboard.securityScoreData,
    dashboardActions
  )
}

export type SecurityScoreHistoryChart = ReturnType<
  typeof createSecurityScoreDataComponent
>

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { ReactComponent as PoliciesIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import {
  DrawerTable,
  RowContainer,
  SearchInput,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { GetPolicyName } from '@lastpass/admin-console-dependencies/services/get-policy-name'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['policies'],
  typeof userDrawerActions
>

export const UserDrawerPoliciesTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: policies
}) => {
  const queryParams = useQueryParams()
  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserPolicies({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Policies</Trans>)

  const [searchQuery, setSearchQuery] = useState('')

  if (policies) {
    const translatedPolicyNames = policies.results
      .map(policy => {
        return {
          name: policy.needsToBeTranslated
            ? GetPolicyName(policy.name)
            : policy.name,
          id: policy.name
        }
      })
      .filter(policyObject => {
        const lowerPolicyName = policyObject.name.toLowerCase()
        return lowerPolicyName.indexOf(searchQuery.toLowerCase()) >= 0
      })
    const policiesToDisplay = {
      ...policies,
      results: translatedPolicyNames,
      checkedRecords: [],
      filter: undefined,
      excludedRecords: undefined,
      columns: []
    }

    return (
      <>
        <RowContainer>
          <SearchInput
            flexGrow
            value={searchQuery}
            placeholder={msg`Search policies`}
            onChange={e => setSearchQuery(e.target.value)}
            data-qa={'SearchPoliciesInputField'}
          />
        </RowContainer>
        <DrawerTable
          qadata={'UserLevelFullPoliciesList'}
          table={policiesToDisplay}
          noRecordsView={
            <TableView
              icon={<PoliciesIcon />}
              title={
                <Trans>You haven’t assigned any policies to this user</Trans>
              }
              text={
                <Trans>Assign policies to control how they use LastPass</Trans>
              }
            />
          }
          noResultsView={
            <TableView
              icon={<PoliciesIcon />}
              title={<Trans>Sorry, no results match your search</Trans>}
              text={
                <Trans>Try different keywords or check your spelling</Trans>
              }
            />
          }
          isFilterApplied={searchQuery != ''}
          columns={[
            {
              name: <Trans>Policy</Trans>,
              renderer: record => record.name
            }
          ]}
        />
      </>
    )
  }
  return null
}

export function createUserDrawerPoliciesTable<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserDrawerPoliciesTableComponent,
    (state: TState) => state.usersDrawer.policies,
    userDrawerActions
  )
}

export type UserDrawerPoliciesTable = ReturnType<
  typeof createUserDrawerPoliciesTable
>

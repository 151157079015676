import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { DuoSecuritySettingsState } from './state'

export enum DuoSecuritySettingsActionTypes {
  DUO_SECURITY_SETTINGS_GET = '[duo-security-settings] get',
  DUO_SECURITY_SETTINGS_SET = '[duo-security-settings] set',
  DUO_SECURITY_SETTINGS_SAVE = '[duo-security-settings] save',
  DUO_SECURITY_SETTINGS_SET_LOADING = '[duo-security-settings] set-loading'
}

export const duoSecuritySettingsActions = {
  getDuoSecuritySettings: () =>
    createAction(DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_GET),
  setDuoSecuritySettings: (duoSettings: DuoSecuritySettingsState) =>
    createAction(DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_SET, {
      duoSettings
    }),
  setDuoSecuritySettingsLoading: (loading: boolean) =>
    createAction(
      DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_SET_LOADING,
      {
        loading
      }
    ),
  saveDuoSecuritySettings: (duoSettings: DuoSecuritySettingsState) =>
    createAction(DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_SAVE, {
      duoSettings
    })
}

export type DuoSecuritySettingsActions = ActionsUnion<
  typeof duoSecuritySettingsActions
>

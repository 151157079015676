import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  HeaderContainer,
  MainContainer,
  PageContainer
} from '@lastpass/lastkit'
import { forceFeatureFlag } from '@lastpass/utility/force-feature-flag'

import { isCurrentUserAdminOrSuperAdmin } from '@lastpass/admin-console-dependencies/services/get-user-role-helper'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { ErrorBoundary } from '@lastpass/admin-console-dependencies/ui/global/ErrorBoundary'
import { GlobalAlert } from '@lastpass/admin-console-dependencies/ui/global/GlobalAlert'
import { createGlobalDialog } from '@lastpass/admin-console-dependencies/ui/global/GlobalDialog'
import { GlobalLoading } from '@lastpass/admin-console-dependencies/ui/global/GlobalLoading'
import { createGlobalNotification } from '@lastpass/admin-console-dependencies/ui/global/GlobalNotification'
import { createGlobalTopNavigation } from '@lastpass/admin-console-dependencies/ui/global/GlobalTopNavigation'
import { IdentityTrialBanner } from '@lastpass/admin-console-dependencies/ui/global/IdentityTrialBanner'
import { ManagedCompanyBanner } from '@lastpass/admin-console-dependencies/ui/global/ManagedCompanyBanner'
import { MspFirstBillingDateBanner } from '@lastpass/admin-console-dependencies/ui/global/MspFirstBillingDateBanner'
import { NoPasswordMigrationBanner } from '@lastpass/admin-console-dependencies/ui/global/NoPasswordMigrationBanner'
import {
  isAggregatorMSPInManagedMSP,
  isDirectMSPOrAggregatorMSP,
  isManagedMSP,
  isMSPinManagedCompany,
  isSimpleAdmin
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'

import { AppState } from '../app-store'
import { AdvancedPage } from './advanced/AdvancedPage'
import { ApplicationsPage } from './applications/ApplicationsPage'
import { BlockerPage } from './Blocker'
import { HomePage } from './home/HomePage'
import { PoliciesPage } from './policies/PoliciesPage'
import { ReportsPage } from './reports/ReportsPage'
import { SystemUpgradePage } from './upgrade/SystemUpgradePage'
import {
  KeyRotationAuth,
  KeyRotationAuthRedirectUri
} from './users/federated-login/KeyRotationAuth'
import { UsersPage } from './users/UsersPage'

const GlobalTopNavigation = createGlobalTopNavigation<AppState>()
const GlobalDialog = createGlobalDialog<AppState>()
const GlobalNotification = createGlobalNotification<AppState>()

function getDefaultUrl(
  haveAccessToDashboard: boolean,
  haveAccessToUsers: boolean,
  haveAccessToPolicies: boolean,
  haveAccessToApplications: boolean,
  haveAccessToReports: boolean,
  haveAccessToAdvanced: boolean,
  haveAccessToManagedCompanies: boolean
) {
  let defaultUrl = '/error/403.html'
  if (haveAccessToDashboard) {
    defaultUrl = '/dashboard'
  } else if (haveAccessToManagedCompanies) {
    defaultUrl = '/home/managed-companies'
  } else if (haveAccessToUsers) {
    defaultUrl = '/users'
  } else if (haveAccessToPolicies) {
    defaultUrl = '/policies'
  } else if (haveAccessToApplications) {
    defaultUrl = '/applications'
  } else if (haveAccessToReports) {
    defaultUrl = '/reports'
  } else if (haveAccessToAdvanced) {
    defaultUrl = '/advanced'
  }

  return defaultUrl
}

export const MainPage: React.FunctionComponent = () => {
  const permissions = useContext(PermissionContext)

  const currentAdminLevel: string = useSelector((state: AppState) => {
    return state.usersList.currentAdminLevel
  })

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  const isMspTrialExpired: boolean = useSelector((state: AppState) =>
    Boolean(
      state.companyInfo.mspBillingDetails &&
        state.companyInfo.mspBillingDetails.isTrialExpired
    )
  )

  const companyInfo = useSelector<AppState, CompanyInfoState>(
    appState => appState.companyInfo
  )

  const isNPMigrationEnabled: boolean = useFeatureFlags(
    FeatureFlags.isNopasswordMigrationEnabled
  )

  const haveAccessToDashboard = permissions.requirePermission(
    AdminPermissions.dashboardView
  )

  const haveAccessToUsers = permissions.requirePermission(
    AdminPermissions.usersView
  )

  const haveAccessToPolicies = permissions.requirePermission(
    AdminPermissions.policiesModify
  )

  const haveAccessToApplications = permissions.requirePermission(
    AdminPermissions.legacyAndVpnModify,
    AdminPermissions.mfaModify,
    AdminPermissions.passwordAppsModify,
    AdminPermissions.sharedFoldersModify,
    AdminPermissions.ssoModify
  )

  const haveAccessToReports = permissions.requirePermission(
    AdminPermissions.generalReportsView,
    AdminPermissions.reportingMfaAdminActivityView,
    AdminPermissions.reportingMfaUserActivityView,
    AdminPermissions.reportingSamlResponseView,
    AdminPermissions.reportingSsoLoginActivityView
  )

  const haveAccessToAdvanced = permissions.requirePermission(
    AdminPermissions.enterpriseApiModify,
    AdminPermissions.emailNotificationsModify,
    AdminPermissions.enterpriseOptionsModify,
    AdminPermissions.installSoftwareModify,
    AdminPermissions.passwordAppsModify,
    AdminPermissions.siemIntegrationModify,
    AdminPermissions.viewManagedServiceProviderPsa,
    AdminPermissions.configureManagedServiceProviderPsa
  )

  const haveAccessToManagedCompanies = permissions.requirePermission(
    isRoleBasedAccessControlEnabled
      ? AdminPermissions.managedCompanyView
      : AdminPermissions.managedCompanyAccess
  )

  const shouldShowSystemUpgrade: boolean =
    isNPMigrationEnabled &&
    haveAccessToManagedCompanies &&
    isCurrentUserAdminOrSuperAdmin(currentAdminLevel) &&
    (isSimpleAdmin(companyInfo) ||
      isDirectMSPOrAggregatorMSP(companyInfo) ||
      isMSPinManagedCompany(companyInfo) ||
      isManagedMSP(companyInfo) ||
      isAggregatorMSPInManagedMSP(companyInfo))

  const defaultUrl = getDefaultUrl(
    haveAccessToDashboard,
    haveAccessToUsers,
    haveAccessToPolicies,
    haveAccessToApplications,
    haveAccessToReports,
    haveAccessToAdvanced,
    haveAccessToManagedCompanies
  )

  if (process.env.NODE_ENV === 'development') {
    const featureFlagParam = new URLSearchParams(location.search).get('fff')
    featureFlagParam && forceFeatureFlag(featureFlagParam)
  }

  if (isMspTrialExpired) {
    return <BlockerPage />
  }

  return (
    <>
      <MainContainer>
        <ErrorBoundary>
          <HeaderContainer>
            <GlobalAlert />
            <GlobalTopNavigation
              showDashboard={haveAccessToDashboard}
              showUsers={haveAccessToUsers}
              showApplications={haveAccessToApplications}
              showPolicies={haveAccessToPolicies}
              showReports={haveAccessToReports}
              showAdvanced={haveAccessToAdvanced}
              showManagedCompanies={haveAccessToManagedCompanies}
            />
            <IdentityTrialBanner />
            <ManagedCompanyBanner />
            <MspFirstBillingDateBanner />
            <NoPasswordMigrationBanner />
          </HeaderContainer>
          <PageContainer>
            <Switch>
              <Redirect from="/" to={defaultUrl} exact />
              {haveAccessToDashboard && (
                <Route path="/dashboard">
                  <HomePage />
                </Route>
              )}
              {haveAccessToManagedCompanies && (
                <Route path="/home">
                  <HomePage />
                </Route>
              )}
              {haveAccessToUsers && (
                <Route path="/users">
                  <UsersPage />
                </Route>
              )}
              {haveAccessToReports && (
                <Route path="/reports">
                  <ReportsPage />
                </Route>
              )}
              {haveAccessToApplications && (
                <Route path="/applications">
                  <ApplicationsPage />
                </Route>
              )}
              {haveAccessToPolicies && (
                <Route path="/policies">
                  <PoliciesPage />
                </Route>
              )}
              {haveAccessToAdvanced && (
                <Route path="/advanced">
                  <AdvancedPage />
                </Route>
              )}
              {shouldShowSystemUpgrade && (
                <Route path="/systemupgrade">
                  <SystemUpgradePage />
                </Route>
              )}
              <Route path={KeyRotationAuthRedirectUri.popup}>
                <KeyRotationAuth
                  redirectUri={KeyRotationAuthRedirectUri.popup}
                />
              </Route>
              <Route path={KeyRotationAuthRedirectUri.silent}>
                <KeyRotationAuth
                  redirectUri={KeyRotationAuthRedirectUri.silent}
                />
              </Route>
              <Route>
                <Redirect to={defaultUrl} />
              </Route>
            </Switch>
          </PageContainer>
        </ErrorBoundary>
      </MainContainer>
      <GlobalNotification />
      <GlobalDialog />
      <GlobalLoading />
    </>
  )
}

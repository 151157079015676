import { track, uacEvent } from '@lastpass/tracking'

import { createAction } from '@lastpass/admin-console-dependencies/state/action'

export enum AdoptionDashboardActionTypes {
  ADOPTION_DASHBOARD_VIEWED = '[adoption-dashboard-segment-events] adoption dashboard viewed'
}

export const adoptionDashboardActions = {
  adoptionDashboardViewed: () =>
    track(
      createAction(AdoptionDashboardActionTypes.ADOPTION_DASHBOARD_VIEWED),
      {
        segment: uacEvent('Adoption Dashboard Viewed')
      }
    )
}

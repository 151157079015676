import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import { RadioButtonOption } from '@lastpass/lastkit'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'
import {
  ConfirmWithRadio,
  ManagedCompanyDetachConfirmDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'
import { ManagedCompanyAdmin } from '@lastpass/admin-console-dependencies/types/managed-company'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

import { redirectToList } from './helpers/redirect-to-list'

const successNotificationProps: NotificationProps = {
  type: NotificationType.success,
  message: msg`Company has been detached.`,
  autoDismiss: true
}

const failureNotificationProps: NotificationProps = {
  type: NotificationType.alert,
  message: msg`Company can’t be detached. Try again later.`,
  autoDismiss: true
}

const noAdminFailureNotificationProps: NotificationProps = {
  type: NotificationType.alert,
  message: msg`Company must have an admin before being detached. `,
  autoDismiss: true
}

const createConfirmIntentDialogProps = (
  managedCompanyName: string
): ManagedCompanyDetachConfirmDialog => ({
  type: 'managed-company-detach-confirm-dialog',
  managedCompanyName
})

const createConfirmOwnerDialogProps = (
  radioButtons: RadioButtonOption[]
): ConfirmWithRadio => ({
  type: 'confirmwithradio',
  confirmText: msg`Confirm`,
  discardText: msg`Cancel`,
  text: msg`Select an administrator who will become the owner of the detached account.`,
  title: msg`Select administrator`,
  radioButtons
})

const mapAdminListToRadioButtonsOptions = (
  adminList: ManagedCompanyAdmin[]
): RadioButtonOption[] =>
  adminList.map(admin => {
    return {
      value: String(admin.uid),
      label: admin.email
    }
  })

function* handleConfirmIntentDialog(managedCompanyName: string) {
  yield put(
    globalActions.setDialog(createConfirmIntentDialogProps(managedCompanyName))
  )
  return yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])
}

function* handleConfirmOwnerDialog(adminList: ManagedCompanyAdmin[]) {
  const radioButtons: RadioButtonOption[] = mapAdminListToRadioButtonsOptions(
    adminList
  )
  yield put(
    globalActions.setDialog(createConfirmOwnerDialogProps(radioButtons))
  )
  return yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])
}

export function createDetachManagedCompanySaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*(
    action: ReturnType<typeof managedCompanyOperationActions.detach>
  ) {
    const { managedCompany } = action.payload

    const intentDialogClick = yield handleConfirmIntentDialog(
      managedCompany.name
    )

    if (intentDialogClick.type !== GlobalActionTypes.CONFIRM_DIALOG) {
      return
    }

    try {
      const adminListResult: UACServices.getManagedCompanyAdminListAPI.Responses = yield call(
        managedCompaniesServices.getManagedCompanyAdminList,
        managedCompany.id
      )

      if (
        adminListResult.type !==
          UACServices.getManagedCompanyAdminListAPI.SUCCESS ||
        !adminListResult.body.length
      ) {
        yield put(globalActions.emptyDialog())
        yield put(
          globalActions.setNotification(noAdminFailureNotificationProps)
        )
        return
      }

      const ownerDialogClick = yield handleConfirmOwnerDialog(
        adminListResult.body
      )

      if (ownerDialogClick.type !== GlobalActionTypes.CONFIRM_DIALOG) {
        return
      }

      const selectedCompanyOwnerLastPassUserId = Number(
        ownerDialogClick.payload.data
      )

      const result: UACServices.detachManagedCompanyAPI.Responses = yield call(
        managedCompaniesServices.detachManagedCompany,
        managedCompany.id,
        selectedCompanyOwnerLastPassUserId
      )

      if (result.type !== UACServices.detachManagedCompanyAPI.SUCCESS) {
        yield put(globalActions.setNotification(failureNotificationProps))
        return
      }

      yield put(globalActions.setNotification(successNotificationProps))
      yield call(redirectToList)
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

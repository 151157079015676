import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { DetailsDialog, PrimaryButton, TextButton } from '@lastpass/lastkit'

const Container = styled.div`
  z-index: 1000;
`
interface FormDiscardDialogProps {
  onConfirm: () => void
  onCancel: () => void
}

export const FormDiscardDialog: FunctionComponent<FormDiscardDialogProps> = ({
  onConfirm,
  onCancel
}) => {
  return (
    <Container>
      {
        <DetailsDialog
          closeDialog={() => {
            onCancel()
          }}
          width={'640px'}
          title={msg`Discard unsaved changes?`}
          buttons={[
            <PrimaryButton
              red
              onClick={() => {
                onConfirm()
              }}
              key={'confirm'}
            >
              <Trans>Discard</Trans>
            </PrimaryButton>,
            <TextButton onClick={() => onCancel()} key={'cancel'}>
              <Trans>Cancel</Trans>
            </TextButton>
          ]}
        />
      }
    </Container>
  )
}

export const mfaPoliciesKeys = [
  'requirelastpassauth',
  'requirelastpassmfa',
  'requireyubikey',
  'requiresesame',
  'requiregoogleauth',
  'requiremicrosoftauth',
  'requiretoopher',
  'requireduo',
  'requiretransakt',
  'requiresalesforcehash',
  'requiremultifactor'
]

import {
  SymantecVipSettingsActions,
  SymantecVipSettingsActionTypes
} from './actions'
import { SymantecVipSettingsState } from './state'

export const initialState: SymantecVipSettingsState = {
  symantecVipSettings: {
    certificate: '',
    certificateLength: 0,
    certificatePassword: '',
    certificatePasswordLength: 0,
    hasCertificatePassword: false
  },
  loading: false
}

export const symantecVipSettingsReducer = (
  previousState: SymantecVipSettingsState | undefined,
  action: SymantecVipSettingsActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_SET:
      return {
        ...state,
        symantecVipSettings: { ...action.payload.symantecVipOptions }
      }
    case SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    default:
      return state
  }
}

import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as AdvancedFeatureIcon } from '@lastpass/assets/svg/admin-console/advanced-feature-icon.svg'
import {
  BodyBold,
  DetailsDialog,
  Heading200,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { VaultReEncryptionOfferStage } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/vault-re-encryption/vault-re-encryption-offer'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`
const StyledHeading200 = styled(Heading200)`
  margin-bottom: 20px;
  width: 100%;
`
const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
`
const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
`
const StyledCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledAdvancedFeatureIcon = styled(AdvancedFeatureIcon)`
  margin-bottom: 44px;
`

export const VaultReEncryptionOfferDetailsDialogComponent: FunctionComponent = () => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(
          globalActions.confirmDialog(VaultReEncryptionOfferStage.cancelled)
        )
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      disableMaxHeight
      content={
        <>
          <StyledCenterContainer>
            <StyledAdvancedFeatureIcon />
          </StyledCenterContainer>
          <StyledHeading200>
            <Trans>How does vault re-encryption work?</Trans>
          </StyledHeading200>
          <TextBoxContainer>
            <Trans>You have 2 vault re-encryption options:</Trans>
            <StyledList>
              <li>
                <Trans>
                  <BodyBold>Recurring vault re-encryption</BodyBold>
                </Trans>
                <StyledList>
                  <li>
                    <Trans>
                      Any user with an encryption key older than the recurrence
                      interval will have their vault automatically re-encrypted
                      and K2 knowledge component rotated upon next login
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      To set this up, go Users &gt; Federated login &gt; Enable
                      recurring vault re-encryption
                    </Trans>
                  </li>
                </StyledList>
              </li>
              <li>
                <Trans>
                  <BodyBold>Forced vault re-encryption</BodyBold>
                </Trans>
                <StyledList>
                  <li>
                    <Trans>
                      Selected Users or Groups will be logged out of their
                      current sessions and their vaults will be re-encrypted
                      next time they log in
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      To set this up, go Users or Groups &gt; Select Users or
                      Groups &gt; Force vault re-encryption
                    </Trans>
                  </li>
                </StyledList>
              </li>
            </StyledList>
          </TextBoxContainer>
          <TextBoxContainer>
            <StyledExternalLink
              href="https://link.lastpass.com/recurring_vault_re_encryption"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>
                For step-by-step guidance, read the support article about vault
                re-encryption
              </Trans>
            </StyledExternalLink>
          </TextBoxContainer>
        </>
      }
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(
              globalActions.confirmDialog(VaultReEncryptionOfferStage.stage1)
            )
          }}
          key="discard"
        >
          <Trans>Back</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            dispatch(
              globalActions.confirmDialog(VaultReEncryptionOfferStage.stage3)
            )
          }}
          type="submit"
          key="confirm"
        >
          <Trans>Next</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

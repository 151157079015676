import moment from 'moment'

import { Application } from '@lastpass/admin-console-dependencies/state/applications/application'
import { ManagedMigrationCompanyInfo } from '@lastpass/admin-console-dependencies/state/applications/application'
import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { AccessPolicyMap } from '@lastpass/admin-console-dependencies/state/policies/passwordless/passwordless'

import { FeatureDetail } from './FeatureDetail'

export const npSunsetDeadline = new Date('2024-03-31')

export const isGuidValid = (guid: string): boolean => {
  return new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    'i'
  ).test(guid)
}

export const hasNoPasswordSsoApps = (
  applications: ApplicationListState
): boolean => {
  if (!applications.table.results) return false

  return applications.table.results.some(app => isGuidValid(app.id))
}

export const hasNoPasswordPolicies = (policies: AccessPolicyMap): boolean => {
  return policies.results && policies.results.length > 0
}

export const isAppValid = (app: Application): boolean =>
  Boolean(app.name && app.logo?.imageUrl && isGuidValid(app.id))

export const npManagedCompaniesLength = (
  managedCompanies: ManagedMigrationCompanyInfo[]
): number => (managedCompanies === null ? 0 : managedCompanies.length)

export const hasMigratableManagedCopmanies = (
  managedCompanies: ManagedMigrationCompanyInfo[]
): boolean => npManagedCompaniesLength(managedCompanies) > 0

export const isMspWitMigratableCompaniesButWithoutMigration = (
  usedPolicies: FeatureDetail[],
  usedFeatures: FeatureDetail[],
  applications: ApplicationListState,
  companyInfo: CompanyInfoState
): boolean =>
  isDirectMSPOrAggregatorMSP(companyInfo) &&
  usedPolicies !== null &&
  usedPolicies.length === 0 &&
  usedFeatures !== null &&
  usedFeatures.length === 0 &&
  !hasNoPasswordSsoApps(applications)

export const isSimpleAdmin = (companyInfo: CompanyInfoState): boolean => {
  return (
    !companyInfo.details?.permissions?.isManaged &&
    !companyInfo.details?.permissions?.isMsp &&
    !companyInfo.cidOverride
  )
}

export const isDirectMSPOrAggregatorMSP = (
  companyInfo: CompanyInfoState
): boolean => {
  return Boolean(
    !companyInfo.details?.permissions?.isManaged &&
      companyInfo.details?.permissions?.isMsp &&
      !companyInfo.cidOverride
  )
}

export const isMSPinManagedCompany = (
  companyInfo: CompanyInfoState
): boolean => {
  return Boolean(
    companyInfo.details?.permissions?.isManaged &&
      !companyInfo.details?.permissions?.isMsp &&
      companyInfo.cidOverride
  )
}

export const isManagedMSP = (companyInfo: CompanyInfoState): boolean => {
  return Boolean(
    companyInfo.details?.permissions?.isManaged &&
      companyInfo.details?.permissions?.isMsp &&
      !companyInfo.cidOverride
  )
}

export const isAggregatorMSPInManagedMSP = (
  companyInfo: CompanyInfoState
): boolean => {
  return Boolean(
    companyInfo.details?.permissions?.isManaged &&
      companyInfo.details?.permissions?.isMsp &&
      companyInfo.cidOverride
  )
}

export const isLastWarningToMigrateActive = (
  now: Date,
  deadline: Date
): boolean => {
  return moment(deadline).diff(now, 'days') < 14
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { CompanySettings } from '@lastpass/admin-console-dependencies/types/company-settings'

export const SUCCESS = 'success'

const success = (settings: CompanySettings) =>
  createResponse(SUCCESS, { settings })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('company/settings/', 'GET')
    if (status === StatusCodes.OK && response) {
      const parsedResponse = JSON.parse(response)
      const isNonDisabledUserRemovalAllowed =
        parsedResponse.content.isNonDisabledUserRemovalAllowed

      return success({
        isNonDisabledUserRemovalAllowed: isNonDisabledUserRemovalAllowed
      })
    }

    throw new UnknownServiceError()
  }
}

import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  matchPath as testPath,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as MFAAppsIcon } from '@lastpass/assets/svg/admin-console/icon-discovery.svg'
import { ReactComponent as SharedFolderIcon } from '@lastpass/assets/svg/admin-console/icon-folder-share.svg'
import { ReactComponent as PasswordAppsIcon } from '@lastpass/assets/svg/admin-console/icon-password-apps.svg'
import { ReactComponent as PasswordlessIcon } from '@lastpass/assets/svg/admin-console/icon-passwordless.svg'
import { ReactComponent as WebAppsIcon } from '@lastpass/assets/svg/admin-console/icon-window-four.svg'
import { ReactComponent as DiscoveryIcon } from '@lastpass/assets/svg/admin-console/phone-lock.svg'
import {
  ContentContainer,
  SidebarContainer,
  SideNavigation
} from '@lastpass/lastkit'
import { RoutedSideNavigationItem } from '@lastpass/routing'
import { getDefaultUrl, SideMenuItem } from '@lastpass/routing/get-default-url'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import {
  MfaAppsDrawerState,
  MfaAppsDrawerSteps
} from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { Overlay } from '@lastpass/admin-console-dependencies/ui/common/OverlayComponent'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { AppDiscoveryPage } from './discovery/AppDiscoveryPage'
import { MfaAppsPage } from './mfa/MfaAppsPage'
import { PasswordAppsPage } from './PasswordAppsPage'
import { PasswordlessAppsPage } from './PasswordlessAppsPage'
import { ApplicationsSamlPage } from './saml/ApplicationsSamlPage'
import { SharedFoldersPage } from './SharedFoldersPage'

const styledOverlayShouldVisibleLogic = (
  path: string,
  ssoUrl: string,
  mfaUrl: string
) => {
  return (
    !!testPath(path, `${ssoUrl}/add`) ||
    !!testPath(path, `${ssoUrl}/:id`) ||
    !!testPath(path, `${mfaUrl}/add`) ||
    !!testPath(path, `${mfaUrl}/:id`)
  )
}

export const ApplicationsPage: FunctionComponent = () => {
  const cidOverride = useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )

  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const permissions = useContext(PermissionContext)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch])

  const companyDetails = useSelector<AppState, CompanyDetails>(
    state => state.companyInfo.details
  )

  const appDrawerState = useSelector<AppState, ApplicationDrawerState>(
    state => state.applicationDrawer
  )

  const mfaAppsDrawerState = useSelector<AppState, MfaAppsDrawerState>(
    state => state.mfaAppsDrawer
  )

  const userHasMfaAndNotOnTrial =
    (CompanyDetailsHelper.userHasMfaAddon(companyDetails) &&
      !CompanyDetailsHelper.isUserOnBusinessTrial(companyDetails)) ||
    false

  const applicationsPageVisibility = {
    isMFAAppsVisible: permissions.requirePermission(AdminPermissions.mfaModify)
  }

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const isPasswordAppsHidden = useFeatureFlags(
    FeatureFlags.isPasswordAppsHidden
  )

  const isSaasAppDiscoveryEnabled = useFeatureFlags(
    FeatureFlags.isSaasAppDiscoveryEnabled
  )

  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const onSsoAppDrawerClosed = () => {
    if (
      appDrawerState.actualStep == ApplicationDrawerSteps.configure ||
      (appDrawerState.selectedApplication &&
        appDrawerState.selectedApplication.id)
    ) {
      dispatch(applicationsDrawerActions.onConfigureAppClicked('Discard'))
    }

    if (
      appDrawerState.actualStep == ApplicationDrawerSteps.select ||
      !appDrawerState.actualStep
    ) {
      dispatch(
        applicationsDrawerActions.selectApp(
          'Discard',
          appDrawerState.selectedApplication
            ? appDrawerState.selectedApplication.name
            : ''
        )
      )
    }
  }

  const onMfaAppDrawerClosed = () => {
    if (
      mfaAppsDrawerState.actualStep == MfaAppsDrawerSteps.create ||
      !mfaAppsDrawerState.actualStep
    ) {
      dispatch(mfaAppsDrawerActions.selectAppCanceled(mfaAppsDrawerState.app))
    }
  }

  const styledOverlayClickHandler = () => {
    if (history.location.pathname.includes('saml')) {
      onSsoAppDrawerClosed()
    }
    if (history.location.pathname.includes('mfa')) {
      onMfaAppDrawerClosed()
    }
    history.goBack()
  }

  const getSideMenuItems = (
    matchUrl: string,
    matchPath: string
  ): SideMenuItem[] => {
    const sideMenuItems: SideMenuItem[] = []

    if (isSaasAppDiscoveryEnabled) {
      sideMenuItems.push({
        url: `${matchUrl}/discovery`,
        route: { path: `${matchPath}/discovery` },
        icon: DiscoveryIcon,
        qaData: 'SideMenuAppDiscovery',
        header: <Trans>App discovery</Trans>,
        content: <AppDiscoveryPage />
      })
    }
    sideMenuItems.push({
      url: `${matchUrl}/saml`,
      route: { path: `${matchPath}/saml` },
      icon: WebAppsIcon,
      qaData: 'SideMenuSsoApps',
      header: <Trans>SSO apps</Trans>,
      content: <ApplicationsSamlPage onClose={onSsoAppDrawerClosed} />
    })
    if (applicationsPageVisibility.isMFAAppsVisible) {
      sideMenuItems.push({
        url: `${matchUrl}/mfa`,
        route: { path: `${matchPath}/mfa` },
        icon: MFAAppsIcon,
        tooltipText: userHasMfaAndNotOnTrial ? (
          <Trans>This feature is part of the Advanced MFA add-on</Trans>
        ) : (
          <Trans>
            Get the Advanced MFA add-on to protect more endpoints with an
            additional layer of security.
          </Trans>
        ),
        qaData: 'SideMenuMFAApps',
        header: <Trans>MFA apps</Trans>,
        content: <MfaAppsPage onClose={onMfaAppDrawerClosed} />
      })
    }
    if (
      (!isNpPasswordlessFeatureDisabled ||
        (!isPasswordlessPoliciesEmpty && userHasMfaAddon)) &&
      !isNoPasswordDeprecated
    )
      sideMenuItems.push({
        url: `${matchUrl}/passwordless/ssoapps`,
        route: { path: `${matchPath}/passwordless` },
        icon: PasswordlessIcon,
        tooltipText: userHasMfaAndNotOnTrial ? (
          <Trans>This feature is part of the Advanced MFA add-on</Trans>
        ) : (
          <Trans>Get the Advanced MFA add-on to eliminate passwords.</Trans>
        ),
        qaData: 'SideMenuLegacyVpn',
        header: <Trans>Passwordless apps</Trans>,
        content: <PasswordlessAppsPage />
      })
    if (!isPasswordAppsHidden) {
      sideMenuItems.push({
        url: `${matchUrl}/password`,
        route: { path: `${matchPath}/password` },
        icon: PasswordAppsIcon,
        qaData: 'SideMenuPasswordAppsFolders',
        header: <Trans>Password apps</Trans>,
        content: <PasswordAppsPage />
      })
    }
    sideMenuItems.push({
      url: `${matchUrl}/sharedfolder`,
      route: { path: `${matchPath}/sharedfolder` },
      icon: SharedFolderIcon,
      qaData: 'SideMenuSharedFolders',
      header: <Trans>Shared folders</Trans>,
      content: (
        <SharedFoldersPage isCidOverrideMode={cidOverride !== undefined} />
      )
    })
    return sideMenuItems
  }

  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const sideMenuItems = getSideMenuItems(matchUrl, matchPath)

    const [sso, mfa] = ['saml', 'mfa'].map(path => {
      const route: RouteProps = { path: `${matchPath}/${path}` }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    })

    const StyledOverlay = styled(Overlay)<{ decreaseHeight?: number }>`
      z-index: 60;
      top: ${props =>
        props.decreaseHeight ? `${props.decreaseHeight + 56}px` : '56px'};
      left: 0;
      width: calc(100vw - 588px);
      height: ${props =>
        props.decreaseHeight
          ? `calc(100vh - ${props.decreaseHeight + 56}px)`
          : 'calc(100vh - 56px)'};
    `
    const styledOverlayShouldVisible = styledOverlayShouldVisibleLogic(
      location.pathname,
      sso.url,
      mfa.url
    )

    return (
      <>
        {styledOverlayShouldVisible && (
          <StyledOverlay
            onClick={styledOverlayClickHandler}
            decreaseHeight={0}
          />
        )}
        <SidebarContainer>
          <SideNavigation>
            {sideMenuItems.map(item => (
              <RoutedSideNavigationItem
                key={item.qaData}
                data-qa={item.qaData}
                tooltipText={item.tooltipText}
                className={item.className}
                to={item.url}
                route={item.route}
                icon={item.icon}
              >
                {item.header}
              </RoutedSideNavigationItem>
            ))}
          </SideNavigation>
        </SidebarContainer>
        <ContentContainer>
          <Switch>
            {sideMenuItems.map(item => (
              <Route key={item.qaData} {...item.route}>
                {item.content}
              </Route>
            ))}
            <Route>
              <Redirect to={getDefaultUrl(sideMenuItems)} />
            </Route>
          </Switch>
        </ContentContainer>
      </>
    )
  }
  return null
}

import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import {
  BodySemibold,
  SmallPrimaryButton,
  TopBanner,
  WithTooltip
} from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'

const StyledHelpIcon = styled(HelpIcon)`
  height: 16px;
  width: 16px;
  margin: auto 8px;
  margin-bottom: 1px;
`

const Container = styled.div`
  color: ${props => props.theme.colors.blue100};
  margin: 8px auto;
`

const Text = styled(BodySemibold)`
  color: ${props => props.theme.colors.blue100};
  vertical-align: text-bottom;
`

const StyledSmallPrimaryButton = styled(SmallPrimaryButton)`
  vertical-align: bottom;
  margin: auto 24px;
`

export const IdentityTrialBanner: React.FunctionComponent = () => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  if (
    CompanyDetailsHelper.isUserOnBusinessTrial(companyDetails) &&
    companyDetails.subscriptionEndDate
  ) {
    const currentDate = moment()
    const subscriptionEndDate = moment(companyDetails.subscriptionEndDate)
    const daysUntilEndDate = subscriptionEndDate.diff(currentDate, 'days')

    const trialName = <Trans>Business</Trans>

    return (
      <TopBanner backgroundColor="purple900">
        <Container data-qa="IdentityTrialBanner">
          <WithTooltip
            tooltip={
              <Trans>
                Your trial includes the industry-leading password management of
                LastPass Business as well as cutting edge endpoint protection
                with the Advanced MFA add-on.
              </Trans>
            }
          >
            <StyledHelpIcon data-qa="IdentityTrialBannerIcon" />
          </WithTooltip>
          <Text data-qa="IdentityTrialBannerMessage">
            {daysUntilEndDate < 1 && (
              <Trans>Your LastPass {trialName} trial expires today.</Trans>
            )}
            {daysUntilEndDate === 1 && (
              <Trans>
                {daysUntilEndDate} day remaining in your LastPass {trialName}{' '}
                trial.
              </Trans>
            )}
            {daysUntilEndDate > 1 && (
              <Trans>
                {daysUntilEndDate} days remaining in your LastPass {trialName}{' '}
                trial.
              </Trans>
            )}
          </Text>
          <StyledSmallPrimaryButton
            data-qa="IdentityTrialBannerPurchaseButton"
            onClick={() => {
              window.open(
                `${defaultConfig.lastPassBaseAddress}/enterprisepayment.php`,
                '_blank'
              )
            }}
          >
            <Trans>Purchase</Trans>
          </StyledSmallPrimaryButton>
        </Container>
      </TopBanner>
    )
  }
  return null
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes
} from '@lastpass/admin-console-dependencies/server/responses'
import { RecoveryKeySharingDetails } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

export const SUCCESS = 'success'
export const NO_KEYS_WERE_SHARED_ERROR = 'noKeysWereSharedError'

const success = (recoveryKeySharingDetails: RecoveryKeySharingDetails) =>
  createResponse(SUCCESS, recoveryKeySharingDetails)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userIds: string[]) {
    const [response, status] = await fetch(
      'bulk/users/recovery-key-distribution-details',
      'POST',
      userIds,
      {
        'content-type': 'application/json'
      }
    )

    if (status === StatusCodes.OK && response) {
      const recoveryKeySharingDetails = JSON.parse(response).content
      return success(recoveryKeySharingDetails)
    }
    throw new Error(NO_KEYS_WERE_SHARED_ERROR)
  }
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyBold,
  BodySemibold,
  ContentContainerStyle,
  Heading100,
  PrimaryButton,
  ScrollableContentContainer,
  TextInput,
  TopBanner
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'
import { LocationLink } from '@lastpass/routing'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { splunkIntegrationActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/actions'
import { SplunkIntegrationState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/state'

const MarginPaddingStyle = css`
  display: flex;
  align-items: center;
`

const HeaderContainer = styled.div`
  ${MarginPaddingStyle}
  letter-spacing: 0.24px;
  margin-top: 32px;
`

const StyledSubTitle = styled.div`
  ${BodyRegularStyle}
  ${MarginPaddingStyle}
  margin: 10px 0 20px 0px;
`

const StyledNote = styled.div`
  ${BodyRegularStyle}
  ${MarginPaddingStyle}
  color:${props => props.theme.colors.neutral900};

`
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 540px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`
const InputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 16px;

  &:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 12px;
  }
`
const StyledButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin-top: 24px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const Container = styled.div`
  margin-left: 24px;
`

const DarkBodySemiBold = styled(BodySemibold)<{ hasUnderline?: boolean }>`
  color: ${props => props.theme.colors.neutral900};

  ${props =>
    props.hasUnderline &&
    `
      text-decoration: underline;
    `}
`

const StyledScrollableContentContainer = styled(ScrollableContentContainer)`
  padding-top: 0;
`

export const StyledContentContainer = ({ children }) => (
  <ContentContainerStyle>
    <StyledScrollableContentContainer>
      {children}
    </StyledScrollableContentContainer>
  </ContentContainerStyle>
)

interface SplunkIntegrationFormProps {
  token: string
  url: string
  handleSubmit: (splunk: SplunkIntegrationState) => void
}

const SplunkIntegrationForm: FunctionComponent<SplunkIntegrationFormProps> = ({
  token,
  url,
  handleSubmit
}) => {
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()
  return (
    <Formik
      enableReinitialize
      initialValues={{
        splunkInstanceToken: token,
        splunkInstanceUrl: url
      }}
      onSubmit={values => {
        setIsBlockingPromptEnabled(false)
        handleSubmit({
          token: values.splunkInstanceToken,
          url: values.splunkInstanceUrl
        })
      }}
    >
      {formikProps => {
        return (
          <Form>
            <FormContainer
              data-qa="FormContainer"
              onChange={() => setIsBlockingPromptEnabled(true)}
            >
              <InputContainer>
                <Field name="splunkInstanceToken">
                  {formData => {
                    return (
                      <StyledTextInput
                        data-qa={'SplunkInstanceTokenInput'}
                        name={formData.field.name}
                        value={formData.field.value}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Splunk instance token</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </InputContainer>
              <InputContainer>
                <Field name="splunkInstanceUrl">
                  {formData => {
                    return (
                      <StyledTextInput
                        data-qa={'SplunkInstanceUrlInput'}
                        name={formData.field.name}
                        value={formData.field.value}
                        placeholder={msg`https://input-prd-my-instance.splunk.com:8088`}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Splunk instance URL</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </InputContainer>
            </FormContainer>
            <StyledNote>
              <Trans>
                <BodyBold>Note:&nbsp;</BodyBold>
                Be sure to use the port number.
              </Trans>
            </StyledNote>
            <StyledButton
              data-qa={'UpdateButton'}
              disabled={
                formikProps.values.splunkInstanceToken === token &&
                formikProps.values.splunkInstanceUrl === url
              }
              onClick={() => {
                formikProps.handleSubmit()
              }}
              type="submit"
            >
              <Trans>Update</Trans>
            </StyledButton>
          </Form>
        )
      }}
    </Formik>
  )
}

const SiemIntegrationsLink: FunctionComponent = () => (
  <LocationLink to="/advanced/siemIntegrations">
    {' '}
    <DarkBodySemiBold hasUnderline>
      <Trans>Check it out</Trans>
    </DarkBodySemiBold>
  </LocationLink>
)

export const SplunkMigrationBanner: FunctionComponent = () => (
  <TopBanner backgroundColor="yellow700">
    <DarkBodySemiBold>
      <Trans>
        The Splunk integration has moved. From now on, use the new Splunk
        integration page.
      </Trans>
      <SiemIntegrationsLink />
    </DarkBodySemiBold>
  </TopBanner>
)

export const SplunkIntegration: FunctionComponent = () => {
  const dispatch = useDispatch()

  const splunkIntegrationState: SplunkIntegrationState = useSelector(
    (state: AppState) => state.splunkIntegration
  )

  useEffect(() => {
    dispatch(splunkIntegrationActions.getSplunkIntegration())
  }, [dispatch])

  return (
    <Container>
      <HeaderContainer>
        <Heading100>
          <Trans>Splunk integration</Trans>
        </Heading100>
      </HeaderContainer>
      <StyledSubTitle>
        <Trans>
          Allow a Splunk administrator to collect and send LastPass events to a
          Splunk cloud instance via Rest API in near real-time. To set up data
          forwarding, configure an HTTP event collector for your Splunk cloud
          instance and copy the resulting Splunk instance token and instance URL
          to the fields below. The integration becomes active within 24 hours,
          though potentially sooner.
        </Trans>
      </StyledSubTitle>
      {splunkIntegrationState.loading ? (
        <Loading color="blue900" active={true} />
      ) : (
        <SplunkIntegrationForm
          key={`${splunkIntegrationState.token} - ${splunkIntegrationState.url}`}
          token={splunkIntegrationState.token}
          url={splunkIntegrationState.url}
          handleSubmit={splunk =>
            dispatch(splunkIntegrationActions.saveSplunkIntegration(splunk))
          }
        />
      )}
    </Container>
  )
}

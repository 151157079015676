import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { sharedFolderDrawerActions } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'

export function createGetSharedFolderUsers(
  sharedFolderServices: UACServices.Services
) {
  return function* getSharedFolderUsers(
    action: ReturnType<
      typeof sharedFolderDrawerActions.getSharedFolderUsersList
    >
  ) {
    try {
      yield put(sharedFolderDrawerActions.setSharedFolderUsersLoading(true))
      const shareId = action.payload.params.path.id
      if (shareId) {
        const sharedFolderUsersResult: UACServices.ShareFoldersUsersAPI.Responses = yield call(
          sharedFolderServices.sharedFoldersUsersGet,
          shareId
        )
        if (
          sharedFolderUsersResult.type ===
          UACServices.ShareFoldersUsersAPI.SUCCESS
        ) {
          yield put(
            sharedFolderDrawerActions.setSharedFolderUsersList(
              sharedFolderUsersResult.body
            )
          )
        } else if (
          sharedFolderUsersResult.type ===
          UACServices.ShareFoldersUsersAPI.NOT_FOUND
        ) {
          yield put(push('/applications/sharedfolder'))
        }

        yield put(sharedFolderDrawerActions.setSharedFolderUsersLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { RouteProps } from 'react-router-dom'

export interface SideMenuItem {
  url: string
  route: RouteProps
  icon: React.FunctionComponent
  tooltipText?: React.ReactElement
  tooltipWidthPixels?: number
  qaData: string
  header: React.ReactElement
  className?: string
  content: React.ReactElement
}

export function getDefaultUrl(items: SideMenuItem[]): string {
  return items.length > 0 ? items[0].url : '/'
}

import { msg } from '@lingui/macro'
import { call } from 'redux-saga/effects'
import { put } from 'redux-saga-test-plan/matchers'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  GeneralPoliciesListGetAPI,
  GeneralPolicySwitchONSaveAPI
} from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { SaveTeamsPolicySwitchOnData } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { mapGetPolicyListResultToPolicyMap } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

import { RequirePasswordlessViaLastPassPolicyKey } from './get-initial-data'

export function createEnableRequirePasswordlessViaLastpassPolicy(
  passwordlessServices: UACServices.Services
) {
  return function* enableRequirePasswordlessViaLastpassPolicy() {
    try {
      yield put(passwordlessActions.setLoading(true))
      try {
        const policyInfo: SaveTeamsPolicySwitchOnData = {
          policyKey: RequirePasswordlessViaLastPassPolicyKey,
          policyData: [
            {
              appliesTo: AppliesToOptions.all,
              policyValue: '1',
              policyNotes: '',
              cpid: null
            }
          ]
        }

        const response = yield call(
          passwordlessServices.generalPolicySwitchOnSave,
          policyInfo
        )

        if (response.type === GeneralPolicySwitchONSaveAPI.SUCCESS) {
          const generalPoliciesListGetResponse = yield call(
            passwordlessServices.generalPoliciesListGet
          )

          if (
            generalPoliciesListGetResponse.type ===
            GeneralPoliciesListGetAPI.SUCCESS
          ) {
            const requirePasswordlessViaLastPassPolicy: TeamsPolicy = mapGetPolicyListResultToPolicyMap(
              generalPoliciesListGetResponse.body.generalPoliciesListResponse
            )[0][RequirePasswordlessViaLastPassPolicyKey]

            yield put(
              passwordlessActions.setRequirePasswordlessViaLastPassPolicy(
                requirePasswordlessViaLastPassPolicy
              )
            )

            yield put(
              globalActions.setNotificationSuccess(msg`Policy changes saved.`)
            )
          }
        }
      } finally {
        yield put(passwordlessActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

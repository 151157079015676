import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'

import { SystemUpgradeActionTypes } from '../../../state/upgrade/actions'
import { createInitialData } from './get-initial-data'
import { createIsMigrated } from './get-is-migrated'
import { createIsMigrationRequired } from './get-is-migration-required'
import { createIsMigratableManagedCompanies } from './get-is-migration-required-managed-companies'
import { createStartUpgradeSaga } from './start-upgrade'

export function createSystemUpgradeSaga(
  applicationService: UACServices.Services
) {
  const getInitialData = createInitialData(applicationService)
  const startUpgradeSaga = createStartUpgradeSaga(applicationService)
  const getIsMigrationRequired = createIsMigrationRequired(applicationService)
  const getIsMigrated = createIsMigrated(applicationService)
  const getIsMigratableManagedCompanies = createIsMigratableManagedCompanies(
    applicationService
  )

  return function*() {
    yield takeLatest(
      SystemUpgradeActionTypes.FETCH_INITIAL_DATA,
      getInitialData
    )
    yield takeEvery(SystemUpgradeActionTypes.START_UPGRADE, startUpgradeSaga)
    yield takeLatest(
      SystemUpgradeActionTypes.FETCH_IS_MIGRATION_REQUIRED,
      getIsMigrationRequired
    )
    yield takeLatest(SystemUpgradeActionTypes.FETCH_IS_MIGRATED, getIsMigrated)
    yield takeLatest(
      SystemUpgradeActionTypes.FETCH_MIGRATABLE_MANAGED_COMPANIES,
      getIsMigratableManagedCompanies
    )
  }
}

import { Observable } from '@lastpass/admin-console-dependencies/types/observe-store'

export function observableFrom<T>(value: T): Observable<T> {
  return {
    subscribe: subscriber => {
      subscriber(value)
      return { unsubscribe: () => {} }
    }
  }
}

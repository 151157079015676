import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export interface AssignGroupUsersListQueryParams {
  search: string
  skip: number
}
export interface AssignGroupUsersDrawerUrlParams {
  id: string
}

export enum AssignGroupUsersActionTypes {
  RESET = '[group-assign-user-drawer] reset',

  //group
  GET_GROUP_DETAILS = '[group-details] get',
  SET_GROUP_DETAILS = '[group-details] set',
  CLEAR_GROUP_DETAILS = '[group-details] clear',

  //populate users
  SET_ASSIGN_USER_LIST_GROUP = '[group-user-list-assign] set',
  GET_ASSIGN_USER_LIST_GROUP = '[group-user-list-assign] get',
  CLEAR_USER_LIST_GROUP = '[group-user-list-assign] clear',

  ADD_SELECTED_GROUP_USERS = '[group-user-list-assign] assign-selected-users',
  REMOVE_SELECTED_GROUP_USERS = '[group-user-list-assign] remove-selected-users',
  SET_SELECTED_GROUP_USERS = '[group-user-list-assign] set-selected-users',
  SET_CURRENT_PAGE_GROUP_USERS = '[group-user-list-addassign set-current-page',
  SET_GROUP_USERS_ASSIGN_TABLE_LOADING = '[group-user-list-assign] set-table-loading',

  // Assign users to group
  ADD_USERS_TO_GROUP = '[group-users-add] set'
}

export const assignGroupUsersActions = {
  reset: () => createAction(AssignGroupUsersActionTypes.RESET),

  getGroupDetails: (
    params: DataFetchParams<{}, AssignGroupUsersDrawerUrlParams>
  ) => createAction(AssignGroupUsersActionTypes.GET_GROUP_DETAILS, { params }),
  setGroupDetails: (group: Group) =>
    createAction(AssignGroupUsersActionTypes.SET_GROUP_DETAILS, { group }),
  clearGroupDetails: () =>
    createAction(AssignGroupUsersActionTypes.CLEAR_GROUP_DETAILS),

  setGroupAssignUserList: (
    users: User[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(AssignGroupUsersActionTypes.SET_ASSIGN_USER_LIST_GROUP, {
      users,
      totalResults,
      resultsPerPage
    }),
  getGroupAssignUserList: (
    params: DataFetchParams<
      AssignGroupUsersListQueryParams,
      AssignGroupUsersDrawerUrlParams
    >,
    resultsPerPage = 25
  ) =>
    createAction(AssignGroupUsersActionTypes.GET_ASSIGN_USER_LIST_GROUP, {
      params,
      resultsPerPage
    }),
  clearGroupAssignUserList: () =>
    createAction(AssignGroupUsersActionTypes.CLEAR_USER_LIST_GROUP),
  setGroupAssignUsersTableLoading: (loading: boolean) =>
    createAction(
      AssignGroupUsersActionTypes.SET_GROUP_USERS_ASSIGN_TABLE_LOADING,
      { loading }
    ),
  addSelectedGroupUsers: (userList: User[]) =>
    createAction(AssignGroupUsersActionTypes.ADD_SELECTED_GROUP_USERS, {
      userList
    }),
  removeSelectedGroupUsers: (userList: User[]) =>
    createAction(AssignGroupUsersActionTypes.REMOVE_SELECTED_GROUP_USERS, {
      userList
    }),
  setSelectedGroupUsers: (userList: User[]) =>
    createAction(AssignGroupUsersActionTypes.SET_SELECTED_GROUP_USERS, {
      userList
    }),

  addUsersToGroup: (userList: User[], group: Group) =>
    track(
      createAction(AssignGroupUsersActionTypes.ADD_USERS_TO_GROUP, {
        userList,
        group
      }),
      {
        segment: uacEvent('Assign Users to Group Clicked')
      }
    )
}

export type AssignGroupUsersActions = ActionsUnion<
  typeof assignGroupUsersActions
>

import React from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { PrimaryButton } from '@lastpass/lastkit'

import { acronisApiActions } from '@lastpass/admin-console-dependencies/state/advanced/acronis-api/actions'

import { Container, Subtitle, Title, TitleWrapper } from './AcronisHash'

export const ExistingHash: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  return (
    <Container>
      <TitleWrapper>
        <Title>
          <Trans>Acronis hash</Trans>
        </Title>
        <Subtitle>
          <Trans>
            You{"'"}ve already created your Acronis hash. If you don{"'"}t know
            it, click to reset.
          </Trans>
        </Subtitle>
      </TitleWrapper>
      <PrimaryButton
        data-qa="ResetAcronisHashButton"
        onClick={() => {
          dispatch(acronisApiActions.generateProvisioningHash(true))
        }}
      >
        <Trans>Reset your Acronis hash</Trans>
      </PrimaryButton>
    </Container>
  )
}

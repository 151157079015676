import {
  AuthFetch,
  AuthFetchBlob,
  AuthFetchRetry,
  IdentityAPIFetch,
  TeamsAPIFetch
} from '@lastpass/http'

import { ActivityEventUserType } from '@lastpass/admin-console-dependencies/types/activity-event'

import * as Advanced from './advanced'
import * as LegacyVpn from './applications/legacyvpn'
import * as Mfa from './applications/mfa'
import * as Saml from './applications/saml'
import * as SharedFolders from './applications/shared-folders'
import * as Workstations from './applications/workstations'
import * as Company from './company'
import * as Dashboard from './dashboard'
import * as ManagedCompanies from './home/managed-companies'
import * as SecurityDashboard from './home/new-security-dashboard'
import * as Pendo from './pendo'
import * as Policies from './policies'
import {
  AccessPoliciesGetApi,
  AuthenticationApi,
  GeofencePoliciesGetApi,
  GetUacIdsByLastPassIdApi,
  IpPoliciesGetApi
} from './policies'
import * as Reports from './reports'
import * as Users from './users'

export * from './users'
export * from './reports'
export * from './advanced'
export * from './company'
export * from './policies'
export * from './applications'
export * from './applications/mfa'
export * from './dashboard'
export * from './pendo'
export * from './home/managed-companies'

export const createUACServices = (
  fetch: AuthFetch,
  teamsapi: TeamsAPIFetch,
  identityApi: IdentityAPIFetch,
  fetchBlob: AuthFetchBlob,
  fetchRetry: AuthFetchRetry
) => {
  return {
    // company
    companyDetails: Company.CompanyDetailsAPI.create(fetch),
    companyLicenses: Company.CompanyLicensesAPI.create(fetch),
    companySettings: Company.CompanySettingsAPI.create(fetch),
    mspBillingDetails: Company.MspBillingDetailsAPI.create(fetch),
    paymentInfoEmail: Company.PaymentInfoEmailAPI.create(fetch),
    //users
    users: Users.UsersAPI.create(fetch),
    userProfile: Users.UserProfileAPI.create(fetch),
    currentUserSettings: Users.CurrentUserSettingsAPI.create(fetch),
    currentAdminLevel: Users.CurrentAdminLevelAPI.create(fetch),
    userApps: Users.UserAppsAPI.create(fetch),
    userGroups: Users.UserGroupsAPI.create(fetch),
    userSites: Users.UserSitesAPI.create(fetch),
    userPolicies: Users.UserPoliciesAPI.create(fetch),
    userSharedFolders: Users.UserSharedFoldersAPI.create(fetch),
    userAccountDetails: Users.UserAccountAPI.create(fetch),
    userLevels: Users.UserLevelsAPI.create(fetch),
    saveUserProfile: Users.SaveUserProfileAPI.create(fetch),
    userTrustedDevices: Users.UserTrustedDevicesAPI.create(fetch),
    savePasswordManager: Users.EditLpDeviceAPI.create(fetch),
    userOperations: Users.UserOperationsAPI.create(fetch),
    exportUsers: Users.ExportUsersAPI.create(fetch),
    addUsers: Users.AddUsersAPI.create(fetch),
    editMfaDevice: Users.EditMfaDeviceAPI.create(fetch),
    deleteWorkstation: Users.WorkstationAPI.create(fetch),
    deleteLpDevice: Users.DeleteLpDeviceAPI.create(fetch),
    userOperationJob: Users.UserOperationsJobAPI.create(fetch),
    sendUserVerificationEmail: Users.SendUserVerificationEmailAPI.create(fetch),
    exportUsersWithLowIterationCount: Users.ExportLowIterationCountUsersAPI.create(
      fetch
    ),
    exportSharedFoldersAccessedByLowIterationCountUsers: Users.ExportSharedFoldersAccessedByLowIterationCountUsersAPI.create(
      fetch
    ),
    exportLinkedAccountIterationCount: Users.ExportLinkedAccountIterationCountUsersAPI.create(
      fetch
    ),
    recoveryKeyDistributionDetails: Users.RecoveryKeyDistributionDetailsAPI.create(
      fetch
    ),
    recoveryKeyUpload: Users.RecoveryKeyUploadAPI.create(fetch),
    recoveryKeyDownload: Users.RecoveryKeyDownloadAPI.create(fetch),
    currentAdminPrivateRsaSharingKey: Users.CurrentAdminPrivateRsaSharingKeyAPI.create(
      fetch
    ),
    userCount: Users.UserCountAPI.create(fetch),
    //groups
    groups: Users.GroupListAPI.create(fetch),
    groupList: Users.GroupListAPI.create(fetch),
    groupAssignUsers: Users.GroupAssignUsersAPI.create(fetch),
    group: Users.GroupAPI.create(fetch),
    groupEditUsers: Users.GroupEditUsersAPI.create(fetch),
    groupAddNew: Users.GroupAddNewAPI.create(fetch),
    groupUsers: Users.GroupUsersAPI.create(fetch),
    groupPolicies: Users.GroupPoliciesAPI.create(fetch),
    saveGroup: Users.SaveGroupAPI.create(fetch),
    deleteGroup: Users.DeleteGroupAPI.create(fetch),
    groupUserOperations: Users.GroupUserOperationsAPI.create(fetch),
    userFilter: Users.UserFilterAPI.create(fetch),
    groupFilter: Users.GroupFilterAPI.create(fetch),
    //admin
    admin: Users.AdminListAPI.create(fetch),
    adminLevel: Users.AdminLevelAPI.create(fetch),
    adminLevelUsers: Users.AdminLevelUsersAPI.create(fetch),
    adminAssignableUsers: Users.AdminAssignableUsersAPI.create(fetch),
    adminLevelEditUsers: Users.AdminLevelEditUsersAPI.create(fetch),
    adminCurrentPermissions: Users.AdminLevelCurrentPermissionsAPI.create(
      fetch
    ),
    adminLevelSave: Users.AdminLevelSaveAPI.create(fetch),
    adminLevelEdit: Users.AdminLevelEditAPI.create(fetch),
    adminLevelPartialEdit: Users.AdminLevelPartialEditAPI.create(fetch),
    adminLevelDelete: Users.AdminLevelDeleteAPI.create(fetch),
    adminPermissionCategories: Users.AdminPermissionCategoriesAPI.create(fetch),
    managedAdminPermissionCategories: Users.ManagedAdminPermissionCategoriesAPI.create(
      fetch
    ),
    // dashboard
    getSecurityScoreHistory: Dashboard.SecurityScoreHistoryAPI.create(fetch),
    getSecurityScoreData: Dashboard.SecurityScoreDataAPI.create(fetch),
    getUserAdoptionHistory: Dashboard.UserAdoptionHistoryAPI.create(fetch),
    getUserActivityHistory: Dashboard.UserActivityHistoryAPI.create(fetch),
    getUtilizationData: Dashboard.UtilizationAPI.create(fetch),
    exportUrlEncryptionMigrationState: Dashboard.ExportUrlEncryptionMigrationStateAPI.create(
      fetch
    ),
    exportUsersWithFilter: Dashboard.ExportUsersWithFilterAPI.create(fetch),
    inviteUsersWithFilter: Dashboard.InviteUsersWithFilterAPI.create(fetch),
    dashboardDisplaySettings: Dashboard.DashboardDisplaySettingsAPI.create(
      fetch
    ),
    sendRemindInactiveUsersEmail: Dashboard.SendRemindInactiveUsersEmailAPI.create(
      fetch
    ),
    urlEncryptionMigrationState: Dashboard.UrlEncryptionMigrationStateAPI.create(
      fetch
    ),
    getSecurityDashboardData: SecurityDashboard.securityDashboardAPI.create(
      fetch
    ),
    //directories
    adConnectorAPIKeyExistence: Users.ADConnectorKeyExistenceGetAPI.create(
      teamsapi
    ),
    adConnectorAPIKeyReset: Users.ADConnectorKeyResetAPI.create(teamsapi),
    adConnectorLastSyncVersion: Users.ADConnectorVersionLastSyncGetAPI.create(
      teamsapi
    ),
    directoryAccountInformation: Users.DirectoryAccountInformationGetAPI.create(
      fetch
    ),
    directoryProvisionToken: Users.DirectoryProvisionTokenGetAPI.create(fetch),
    directorySettings: Users.DirectorySettingsGetAPI.create(fetch),
    //federated login
    adfsSetupGet: Users.AdfsSetupGetAPI.create(fetch),
    adfsSetupSave: Users.AdfsSetupSaveAPI.create(fetch),
    openIdSetupGet: Users.OpenIdSetupGetAPI.create(fetch),
    openIdSetupSave: Users.OpenIdSetupSaveAPI.create(fetch),
    adAttributeGet: Users.AdAttributeGetAPI.create(fetch),
    adAttributeSave: Users.AdAttributeSaveAPI.create(fetch),
    googleProvisioningInfoSave: Users.GoogleProvisioningSaveAPI.create(fetch),
    googleProvisioningInfoGet: Users.GoogleProvisioningGetAPI.create(fetch),
    googleSyncUsers: Users.GoogleSyncUsersAPI.create(fetch),
    getGoogleAuditLogs: Users.GetGoogleAuditLogsAPI.create(fetch),
    getSystemLogs: Users.GetSystemLogsAPI.create(fetch),
    googleGroupsGet: Users.GoogleGroupsGetAPI.create(fetch),
    googleGroupsSaveSelected: Users.GoogleGroupsSaveSelectedAPI.create(fetch),
    googleUsersGet: Users.GoogleUsersGetAPI.create(fetch),
    googleUsersSave: Users.GoogleUsersSaveAPI.create(fetch),
    googleGroupsSearch: Users.GoogleGroupsSearchAPI.create(fetch),
    googlePartialSyncIdsClear: Users.ClearGoogleSyncIdsAPI.create(fetch),
    getKeyRotationToken: Users.KeyRotationGetAlpTokenAPI.create(fetchRetry),
    getKeyRotationData: Users.KeyRotationGetDataAPI.create(fetchRetry),
    saveKeyRotationData: Users.KeyRotationSaveDataAPI.create(fetchRetry),
    syncKeyRotationData: Users.KeyRotationSyncDataAPI.create(fetchRetry),

    //reports
    getUserActivityEvents: Reports.GetActivityEventsAPI.create(
      fetch,
      ActivityEventUserType.user
    ),
    getAdminActivityEvents: Reports.GetActivityEventsAPI.create(
      fetch,
      ActivityEventUserType.admin
    ),
    getUserActivityEventTypes: Reports.GetActivityEventTypesAPI.create(
      fetch,
      ActivityEventUserType.user
    ),
    getAdminActivityEventTypes: Reports.GetActivityEventTypesAPI.create(
      fetch,
      ActivityEventUserType.admin
    ),
    exportUserActivityEvents: Reports.ExportActivityEventsAPI.create(
      fetch,
      ActivityEventUserType.user
    ),
    exportAdminActivityEvents: Reports.ExportActivityEventsAPI.create(
      fetch,
      ActivityEventUserType.admin
    ),
    securityReport: Reports.SecurityReportAPI.create(fetch),
    requestSecurityReportUpdate: Reports.RequestSecurityReportUpdateAPI.create(
      fetch
    ),
    loginReports: Reports.LoginReportEventsAPI.create(fetch),
    advancedUserActivityEvents: Reports.AdvancedUserActivityEventsAPI.create(),
    advancedUserActivityEventTypes: Reports.AdvancedUserActivityEventTypesAPI.create(),
    advancedUserActivitySavedReports: Reports.AdvancedUserActivitySavedReportsAPI.create(),
    advancedUserActivitySaveReport: Reports.AdvancedUserActivitySaveReportAPI.create(
      fetch
    ),
    advancedUserActivityUpdateReport: Reports.AdvancedUserActivityUpdateReportAPI.create(
      fetch
    ),
    advancedUserActivityDeleteReport: Reports.AdvancedUserActivityDeleteReportAPI.create(
      fetch
    ),
    samlResponse: Reports.SamlResponsesAPI.create(teamsapi),
    userDetails: Reports.UserDetailsAPI.create(fetch),
    urlsInVaultsReportDownload: Reports.UrlsInVaultsAPI.DownloadReportAPI.create(
      fetchBlob
    ),
    urlsInVaultsReportGetReports: Reports.UrlsInVaultsAPI.GetReportsAPI.create(
      fetch
    ),
    urlsInVaultsReportGenerateReport: Reports.UrlsInVaultsAPI.GenerateReportAPI.create(
      fetch
    ),
    urlsInSharedFoldersReportDownload: Reports.UrlsInSharedFoldersAPI.DownloadReportAPI.create(
      fetchBlob
    ),
    urlsInSharedFoldersReportGetReports: Reports.UrlsInSharedFoldersAPI.GetReportsAPI.create(
      fetch
    ),
    urlsInSharedFoldersReportGenerateReport: Reports.UrlsInSharedFoldersAPI.GenerateReportAPI.create(
      fetch
    ),
    exportMfaProviders: Reports.ExportMfaProvidersAPI.create(fetch),
    urlRules: Advanced.UrlRulesApi.create(fetch),
    urlRuleOptions: Advanced.UrlRuleOptionsApi.create(fetch),
    addUrlRule: Advanced.AddUrlRuleApi.create(fetch),
    editUrlRuleOptions: Advanced.EditUrlRuleOptionsApi.create(fetch),
    deleteUrlRules: Advanced.DeleteUrlRulesAPI.create(fetch),
    neverOnlyUrlsGet: Advanced.GetNeverOnlyUrlsApi.create(fetch),
    neverOnlyUrlsSet: Advanced.SetNeverOnlyUrlsApi.create(teamsapi),
    //splunk - integration
    splunkIntegrationGet: Advanced.SplunkIntegrationGetAPI.create(teamsapi),
    splunkIntegrationSave: Advanced.SplunkIntegrationSaveAPI.create(teamsapi),
    //SIEM integration
    siemIntegrationsSetConfig: Advanced.SiemIntegrationsSetConfigAPI.create(
      fetch
    ),
    siemIntegrationsGetConfigs: Advanced.SiemIntegrationsGetConfigsAPI.create(
      fetch
    ),
    //Acronis integration
    acronisApi: Advanced.createAcronisApi(fetch),
    //applications
    sharedFoldersGet: SharedFolders.ShareFoldersAPI.create(teamsapi),
    sharedFoldersSitesGet: SharedFolders.ShareFoldersSitesAPI.create(teamsapi),
    sharedFoldersScoresGet: SharedFolders.ShareFoldersScoresAPI.create(
      teamsapi
    ),
    sharedFoldersUsersGet: SharedFolders.ShareFoldersUsersAPI.create(teamsapi),
    enterpriseApi: Advanced.createEnterpriseApi(teamsapi),
    //enabled multifactor options
    enabledMultifactorOptionsGet: Advanced.EnabledMultifactorOptionsGetAPI.create(
      fetch
    ),
    enabledMultifactorOptionsSave: Advanced.EnabledMultifactorOptionsSaveAPI.create(
      fetch
    ),
    resetMfaSharedSecrets: Advanced.ResetMfaSharedSecretsAPI.create(fetch),
    //duo security settings
    duoSecuritySettingsGet: Advanced.DuoSecuritySettingsGetAPI.create(teamsapi),
    duoSecuritySettingsSave: Advanced.DuoSecuritySettingsSaveAPI.create(
      teamsapi
    ),
    //rsa secure id radius
    rsaSecureIDRadiusGet: Advanced.RSASecureIDRadiusGetAPI.create(teamsapi),
    rsaSecureIDRadiusSave: Advanced.RSASecureIDRadiusSaveAPI.create(teamsapi),
    //symantec vip settings
    symantecVipSettingsGet: Advanced.SymantecVipSettingsGetAPI.create(teamsapi),
    symantecVipSettingsSave: Advanced.SymantecVipSettingsSaveAPI.create(
      teamsapi
    ),
    //secure auth settings
    secureAuthSettingsGet: Advanced.SecureAuthSettingsGetAPI.create(fetch),
    secureAuthSettingsSave: Advanced.SecureAuthSettingsSaveAPI.create(fetch),
    //policies
    generalPoliciesListGet: Policies.GeneralPoliciesListGetAPI.create(teamsapi),
    generalPolicySwitchOnSave: Policies.GeneralPolicySwitchONSaveAPI.create(
      teamsapi
    ),
    generalPolicySwitchOffSave: Policies.GeneralPolicySwitchOFFSaveAPI.create(
      teamsapi
    ),
    generalPolicySearchUsers: Policies.GeneralPolicyUsersSearchAPI.create(
      teamsapi
    ),
    generalPolicySearchGroups: Policies.GeneralPolicyGroupsSearchAPI.create(
      teamsapi
    ),
    getAdminUsers: Policies.AdminUsersAPI.create(fetch),
    // applications
    getSSOApplicationsList: Saml.SSOApplicationsAPI.create(identityApi),
    getAvailableApplications: Saml.SSOAvailableApplicationsAPI.create(
      identityApi
    ),
    getSamlKeys: Saml.SSOSamlKeysAPI.create(identityApi),
    getApplication: Saml.SSOApplicationAPI.create(identityApi),
    getUsernameAttributes: Saml.SSOUsernameAttributesAPI.create(identityApi),
    addSSOApplication: Saml.SSOAddApplication.create(identityApi),
    getIdentityUsers: Saml.SSOIdentityUserAPI.create(identityApi),
    assignToApplication: Saml.SSOAssignToApplication.create(identityApi),
    getIdentityGroups: Saml.SSOIdentityGroupsAPI.create(identityApi),
    getIdentityRoles: Saml.SSOIdentityRolesAPI.create(identityApi),
    getAssignedElements: Saml.SSOAssignedElementsAPI.create(identityApi),
    deleteApplication: Saml.SSODeleteApplication.create(identityApi),
    migrateApplication: Saml.SSOMigrateApplication.create(identityApi),
    migrateAll: Saml.SSOMigrateAll.create(identityApi),
    getLegacyVpnList: LegacyVpn.LegacyVpnListAPI.create(identityApi),
    getWorkstationList: Workstations.WorkstationListAPI.create(identityApi),
    getOauthKeys: Saml.SSOOauthKeys.create(identityApi),
    // passwordless
    getAccessPolicies: AccessPoliciesGetApi.create(identityApi),
    getIpPolicies: IpPoliciesGetApi.create(identityApi),
    getGeofencePolicies: GeofencePoliciesGetApi.create(identityApi),
    getAuthenticationPolicies: AuthenticationApi.create(identityApi),
    getUacIdsByLastPassIds: GetUacIdsByLastPassIdApi.create(fetch),
    getIsMigrationRequired: Saml.IsMigrationRequired.create(identityApi),
    getIsMigrated: Saml.IsMigrated.create(identityApi),
    getMigratableManagedCompanies: Saml.IsMigratableManagedCompanies.create(
      identityApi
    ),
    // mfa apps
    getMfaApplicationsList: Mfa.MfaApplicationsListGetAPI.create(fetch),
    addMfaApplication: Mfa.MfaApplicationAddAPI.create(fetch),
    addAzureMfaApplication: Mfa.AzureMfaApplicationAddAPI.create(fetch),
    updateMfaApplication: Mfa.MfaApplicationUpdateAPI.create(fetch),
    removeMfaApplication: Mfa.MfaApplicationRemoveAPI.create(fetch),
    removeAzureMfaApplication: Mfa.AzureMfaApplicationRemoveAPI.create(fetch),
    getMfaMappingKeys: Mfa.MfaMappingKeyGetApi.create(fetch),
    getMfaApplication: Mfa.MfaApplicationGetAPI.create(fetch),
    // pendo
    getPendoToken: Pendo.getPendoTokenAPI.create(fetch),
    // managed companies
    getManagedCompanies: ManagedCompanies.getManagedCompaniesAPI.create(fetch),
    getCompanyTemplates: ManagedCompanies.getCompanyTemplatesAPI.create(fetch),
    exportManagedCompanies: ManagedCompanies.exportManagedCompaniesAPI.create(
      fetch
    ),
    exportUserCounts: ManagedCompanies.exportUserCountsAPI.create(fetch),
    performSimpleManagedCompanyOperation: ManagedCompanies.performSimpleManagedCompanyOperationAPI.create(
      fetch
    ),
    performManagedCompanyLicenseOperation: ManagedCompanies.performManagedCompanyLicenseOperation.create(
      fetch
    ),
    createManagedCompany: ManagedCompanies.createManagedCompanyAPI.create(
      fetch
    ),
    detachManagedCompany: ManagedCompanies.detachManagedCompanyAPI.create(
      fetch
    ),
    getManagedCompanyAdminList: ManagedCompanies.getManagedCompanyAdminListAPI.create(
      fetch
    ),
    // PSA integrations
    getPsaProviderDetails: Advanced.GetPsaProviderDetailsAPI.create(fetch),
    getPsaCredentials: Advanced.GetPsaCredentialsAPI.create(fetch),
    savePsaCredentials: Advanced.SavePsaCredentialsAPI.create(fetch),
    getPsaAgreements: Advanced.GetPsaAgreementsAPI.create(fetch),
    savePsaAgreements: Advanced.SavePsaAgreementsAPI.create(fetch),
    getPsaProducts: Advanced.GetPsaProductsAPI.create(fetch),
    savePsaProducts: Advanced.SavePsaProductsAPI.create(fetch),
    deletePsaCredentials: Advanced.DeletePsaCredentialsAPI.create(fetch)
  }
}

export type Services = ReturnType<typeof createUACServices>

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { AddNewGroupActionTypes } from '@lastpass/admin-console-dependencies/state/users/groups/add/actions'
import { AssignGroupUsersActionTypes } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/actions'
import { GroupDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupListActionTypes } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { GroupOperationActionTypes } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'

import { createAddNewGroupSaga } from './add/group-add-new'
import { createAddUsersToGroupSaga } from './add-users/add-users-to-group'
import { createGroupAssignUsersListSaga } from './add-users/assign-users'
import { createGroupSaga } from './add-users/group-details'
import { createGetGroupPoliciesSaga } from './drawer/get-group-policies'
import { createGetGroupUsersSaga } from './drawer/get-group-users'
import { createGetGroupSaga } from './drawer/group-get'
import { createRemoveUsersFromGroupSaga } from './drawer/remove-users-from-group'
import { createGroupsListSaga } from './groups-list'
import { createDeleteGroupSaga } from './operations/delete-group'
import { createSaveGroupSaga } from './save-group'
import { createDeleteUsersGroupSaga } from './user-operations/delete-users'
import { createDestroySessionsGroupSaga } from './user-operations/destroy-sessions'
import { createDisableMfaGroupSaga } from './user-operations/disable-mfa'
import { createDisableUsersGroupSaga } from './user-operations/disable-users'
import { createInviteUsersGroupSaga } from './user-operations/invite-users'
import { createRemoveFromCompanyGroupSaga } from './user-operations/remove-from-company'
import { createRequireMpChangeGroupSaga } from './user-operations/require-mp-change'
import { createResendInvitationGroupSaga } from './user-operations/resend-mfa-invitation'
import { createVaultReEncryptionGroupSaga } from './user-operations/vault-re-encryption'

export function createGroupsSaga(groupsService: UACServices.Services) {
  const getGroupsListSaga = createGroupsListSaga(groupsService)
  const getGroupDetailsSaga = createGroupSaga(groupsService)
  const getAssignUsersListSaga = createGroupAssignUsersListSaga(groupsService)
  const addUsersToGroupSaga = createAddUsersToGroupSaga(groupsService)
  const addNewGroupSaga = createAddNewGroupSaga(groupsService)
  const getGroupSaga = createGetGroupSaga(groupsService)
  const getGroupUsersSaga = createGetGroupUsersSaga(groupsService)
  const getGroupPoliciesSaga = createGetGroupPoliciesSaga(groupsService)
  const saveGroupSaga = createSaveGroupSaga(groupsService)
  const removeUsersFromGroupSaga = createRemoveUsersFromGroupSaga(groupsService)
  const deleteGroupSaga = createDeleteGroupSaga(groupsService)
  const resendInvitationGroupSaga = createResendInvitationGroupSaga(
    groupsService
  )
  const requireMpChangeGroupSaga = createRequireMpChangeGroupSaga(groupsService)
  const vaultReEncryptionGroupSaga = createVaultReEncryptionGroupSaga(
    groupsService
  )
  const disableMfaSaga = createDisableMfaGroupSaga(groupsService)
  const destroySessionsSaga = createDestroySessionsGroupSaga(groupsService)
  const disableUsersSaga = createDisableUsersGroupSaga(groupsService)
  const removeFromComanySaga = createRemoveFromCompanyGroupSaga(groupsService)
  const deleteUsersSaga = createDeleteUsersGroupSaga(groupsService)
  const inviteUsersSaga = createInviteUsersGroupSaga(groupsService)

  return function*() {
    yield takeEvery(GroupListActionTypes.GET_GROUPS_LIST, getGroupsListSaga)
    yield takeEvery(
      AssignGroupUsersActionTypes.GET_GROUP_DETAILS,
      getGroupDetailsSaga
    )
    yield takeEvery(
      AssignGroupUsersActionTypes.GET_ASSIGN_USER_LIST_GROUP,
      getAssignUsersListSaga
    )
    yield takeEvery(
      AssignGroupUsersActionTypes.ADD_USERS_TO_GROUP,
      addUsersToGroupSaga
    )
    yield takeEvery(AddNewGroupActionTypes.ADD_NEW_GROUP, addNewGroupSaga)
    yield takeEvery(GroupDrawerActionTypes.GET_CURRENT_GROUP, getGroupSaga)
    yield takeEvery(GroupDrawerActionTypes.GET_GROUP_USERS, getGroupUsersSaga)
    yield takeEvery(
      GroupDrawerActionTypes.GET_GROUP_POLICIES,
      getGroupPoliciesSaga
    )
    yield takeEvery(GroupDrawerActionTypes.EDIT_GROUP, saveGroupSaga)
    yield takeEvery(
      GroupDrawerActionTypes.REMOVE_USERS_FROM_GROUP,
      removeUsersFromGroupSaga
    )
    yield takeEvery(GroupOperationActionTypes.DELETE_GROUPS, deleteGroupSaga)

    //User actions
    yield takeEvery(
      GroupOperationActionTypes.RESEND_MFA_INVITATION,
      resendInvitationGroupSaga
    )
    yield takeEvery(
      GroupOperationActionTypes.REQUIRE_MP_CHANGE,
      requireMpChangeGroupSaga
    )
    yield takeEvery(
      GroupOperationActionTypes.FORCE_VAULT_RE_ENCRYPTION,
      vaultReEncryptionGroupSaga
    )
    yield takeEvery(GroupOperationActionTypes.DISABLE_MFA, disableMfaSaga)
    yield takeEvery(
      GroupOperationActionTypes.DESTROY_SESSIONS,
      destroySessionsSaga
    )
    yield takeEvery(GroupOperationActionTypes.DISABLE_USERS, disableUsersSaga)
    yield takeEvery(
      GroupOperationActionTypes.REMOVE_FROM_COMPANY,
      removeFromComanySaga
    )
    yield takeEvery(GroupOperationActionTypes.DELETE_USERS, deleteUsersSaga)
    yield takeEvery(GroupOperationActionTypes.INVITE_USERS, inviteUsersSaga)
  }
}

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SymantecVipSettingsActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/actions'

import { createGetSymantecVipSettings } from './get-symantec-vip-settings'
import { createSaveSymantecVipSettings } from './save-symantec-vip-settings'

export function createSymantecVipSettingsSaga(
  symantecVipSettingsService: UACServices.Services
) {
  const getSymantecVipSettingsSaga = createGetSymantecVipSettings(
    symantecVipSettingsService
  )
  const saveSymantecVipSettingsSaga = createSaveSymantecVipSettings(
    symantecVipSettingsService
  )

  return function*() {
    yield takeEvery(
      SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_GET,
      getSymantecVipSettingsSaga
    )
    yield takeEvery(
      SymantecVipSettingsActionTypes.SYMANTEC_VIP_SETTINGS_SAVE,
      saveSymantecVipSettingsSaga
    )
  }
}

import React, { FunctionComponent } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'

interface SelectSecurityScoreProps {
  updateSelection: (update: SecurityScoreLevel[]) => void
  filters?: SecurityScoreLevel[]
}

interface SecurityScoreSelectOption {
  value: SecurityScoreLevel
  label: string
}

export const SelectSecurityScore: FunctionComponent<SelectSecurityScoreProps> = props => {
  const securityScores: SecurityScoreSelectOption[] = [
    { value: SecurityScoreLevel.low, label: i18n._(msg`Low`) },
    { value: SecurityScoreLevel.average, label: i18n._(msg`Average`) },
    { value: SecurityScoreLevel.high, label: i18n._(msg`High`) },
    { value: SecurityScoreLevel.highest, label: i18n._(msg`Highest`) },
    { value: SecurityScoreLevel.na, label: i18n._(msg`N/A`) }
  ]
  return (
    <Select
      dataQa={'SecurityScoreDropdown'}
      options={securityScores}
      value={securityScores.filter(
        status => props.filters && props.filters.includes(status.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={i18n._(msg`Security score`)}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateSelection(selectedItems.map(status => status.value))
        } else {
          props.updateSelection([])
        }
      }}
      fluid
    >
      <Trans>Security score</Trans>
    </Select>
  )
}

import {
  SplunkIntegrationActions,
  SplunkIntegrationActionTypes
} from './actions'
import { SplunkIntegrationState } from './state'

export const initialState: SplunkIntegrationState = {
  token: '',
  url: ''
}

export const splunkIntegrationReducer = (
  previousState: SplunkIntegrationState | undefined,
  action: SplunkIntegrationActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_SET:
      return {
        ...state,
        ...action.payload.splunk
      }
    case SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    default:
      return state
  }
}

import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { BodyBold, DetailsDialog, PrimaryButton } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationStartProcessDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 100px;
`
const StyledBodyText = styled.div`
  ${BodyRegularStyle};
`

interface KeyRotationStartProcessDialogProps {
  state: KeyRotationStartProcessDialog
}

export const KeyRotationStartProcessDialogComponent: FunctionComponent<KeyRotationStartProcessDialogProps> = () => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={msg`Start generating new knowledge components`}
      subTitle={msg`This could take up to a minute, but should only take a few seconds`}
      disableCloseOnBackground
      content={
        <TextBoxContainer>
          <StyledBodyText>
            <Trans>
              <BodyBold>Don’t stop</BodyBold> once you’ve started.{' '}
              <BodyBold>Users are not impacted</BodyBold> during this process.
            </Trans>
          </StyledBodyText>
        </TextBoxContainer>
      }
      buttons={[
        <PrimaryButton
          onClick={() => dispatch(globalActions.confirmDialog())}
          key="confirm"
        >
          <Trans>Start process</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import { assignGroupUsersActions } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/actions'
import { AssignGroupUsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/state'
import { UserDrawerProps } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawer'

export const AssignGroupUsersDrawer: React.FunctionComponent<UserDrawerProps> = ({
  closeLink,
  backButton,
  children
}) => {
  const assignGroupUsersDrawerState: AssignGroupUsersDrawerState['group'] = useSelector(
    (state: AppState) => state.assignGroupUsersDrawer.group
  )
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      assignGroupUsersActions.getGroupDetails({ query: {}, path: pathParams })
    )
  }, [dispatch, pathParams])

  useEffect(() => {
    return () => {
      dispatch(assignGroupUsersActions.reset())
    }
  }, [dispatch])

  return (
    <Drawer
      mainTitle={<span>{assignGroupUsersDrawerState.name}</span>}
      subTitle={<Trans>Assign users</Trans>}
      closeLink={closeLink}
      headerNodes={<>{backButton}</>}
    >
      {children}
    </Drawer>
  )
}

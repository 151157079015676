import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as ScreensIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/screens.svg'
import { TextButton } from '@lastpass/lastkit'

import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

const Link = styled(TextButton)`
  padding: 0;
`

export interface TotalLicensesCardProps {
  count: number
  dataQa?: string
}

export const TotalLicensesCard: FC<TotalLicensesCardProps> = ({
  count,
  dataQa
}) => (
  <ActionCard
    icon={ScreensIcon}
    count={count}
    title={msg`Total licenses`}
    description={
      <Trans>
        Get the licenses you need to spread security to every corner of your
        organization.
      </Trans>
    }
    action={
      <a
        href={`${defaultConfig.lastPassBaseAddress}/enterprisepayment.php`}
        target="_blank"
        rel="noreferrer"
      >
        <Link blue>
          <Trans>Buy more licenses</Trans>
        </Link>
      </a>
    }
    dataQa={dataQa}
  />
)

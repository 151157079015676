import { Middleware } from 'redux'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'

import { GlobalActionTypes } from '../actions'

const STARTING_NUMBER = 1
const INCREMENT = 1

export const genericFailedNotificationCounter: Middleware = () => next => action => {
  if (
    action.type === GlobalActionTypes.SET_NOTIFICATION &&
    action.payload &&
    action.payload.notification === genericFailedNotification
  ) {
    window.e2eTestingGenericFailedNotificationCounter = window.e2eTestingGenericFailedNotificationCounter
      ? window.e2eTestingGenericFailedNotificationCounter + INCREMENT
      : STARTING_NUMBER
  }
  return next(action)
}

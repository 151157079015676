import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum SecurityDashboardActionTypes {
  SECURITY_DASHBOARD_VIEWED = '[security-dashboard-segment-events] security dashboard viewed'
}

export const securityDashboardActions = {
  securityDashboardViewed: () =>
    track(
      createAction(SecurityDashboardActionTypes.SECURITY_DASHBOARD_VIEWED),
      {
        segment: uacEvent('UAC Security dashboard viewed')
      }
    )
}

export type SecurityDashboardActions = ActionsUnion<
  typeof securityDashboardActions
>

import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'

import { BodyBold, Heading200 } from '@lastpass/lastkit'
import { LogoCard } from '@lastpass/lastkit'

import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { ActiveUsageRateAccordionChips } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/active-usage-rate/accordion-chips/ActiveUsageRateAccordionChips'
import { ActiveUsageRateDonutChartCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/active-usage-rate/donut-chart-card/ActiveUsageRateDonutChartCard'
import { InactiveUsersCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/active-usage-rate/inactive-users-card/InactiveUsersCard'
import { AdoptionDashboardOpportunitiesBox } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/active-usage-rate/opportunities-box/AdoptionDashboardOpportunitiesBox'

const AccordionContentContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 16px;
`
const StyledLogoCard = styled(LogoCard)<{}>`
  background-color: ${props => props.theme.colors.blue50};
`

interface ActiveUsageRateProps {
  rate: number
  activeUsersCount: number
  inactiveUsersCount: number
  enrolledUsersCount: number
  usersCountWithStarterKitDisabled: number
  usersCountWithUnclaimedFaab: number
  isOpen: boolean
}

export const ActiveUsageRate: FC<ActiveUsageRateProps> = ({
  rate,
  activeUsersCount,
  inactiveUsersCount,
  enrolledUsersCount,
  usersCountWithStarterKitDisabled,
  usersCountWithUnclaimedFaab,
  isOpen
}) => {
  const isOpportunitiesBoxEnabled = useFeatureFlags(
    FeatureFlags.isOpportunitiesBoxEnabled
  )
  const activeUsageRateAccordionSubtitle: ReactElement = (
    <Trans>
      Out of {enrolledUsersCount} users who have activated their account,{' '}
      {activeUsersCount}{' '}
      <Plural value={activeUsersCount} zero="have" one="has" other="have" />{' '}
      used LastPass in the <BodyBold>last 30 days.</BodyBold> Updated daily.
    </Trans>
  )

  return (
    <StyledLogoCard
      expand={isOpen}
      hideSubtitleOnClose
      title={
        <Heading200 data-qa="ActiveUsageRateAccordionTitle">
          <Trans>Active usage rate</Trans>
        </Heading200>
      }
      subtitle={activeUsageRateAccordionSubtitle}
      contentOnClosed={
        <ActiveUsageRateAccordionChips
          inactiveUsersCount={inactiveUsersCount}
          activeUsageRate={rate}
          activeUsageRateTooltip={activeUsageRateAccordionSubtitle}
        />
      }
      dataQaName="ActiveUsageRateAccordion"
    >
      <AccordionContentContainer>
        <ActiveUsageRateDonutChartCard
          activeUsersCount={activeUsersCount}
          inactiveUsersCount={inactiveUsersCount}
          activeUsageRate={rate}
          dataQa="ActiveUsageRateChartCard"
        />
        <InactiveUsersCard
          count={inactiveUsersCount}
          dataQa="ActiveUsageRateInactiveUsersCard"
        />
      </AccordionContentContainer>
      {isOpportunitiesBoxEnabled && (
        <AccordionContentContainer>
          <AdoptionDashboardOpportunitiesBox
            usersCountWithStarterKitDisabled={usersCountWithStarterKitDisabled}
            usersCountWithUnclaimedFaab={usersCountWithUnclaimedFaab}
          />
        </AccordionContentContainer>
      )}
    </StyledLogoCard>
  )
}

import styled from '@emotion/styled'

import { BodyBoldStyle, BodyRegularStyle, BodySemiboldStyle } from '../styles'

export const BodyBold = styled.span`
  ${BodyBoldStyle}
`

export const BodySemibold = styled.span`
  ${BodySemiboldStyle}
`

export const BodyRegular = styled.span`
  ${BodyRegularStyle}
  & b {
    font-weight: bold;
  }
`

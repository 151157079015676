import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as WorkstationIllustration } from '@lastpass/assets/svg/admin-console/applications/workstation-login-illustration-transparent.svg'
import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/admin-console/icon-close.svg'
import { ReactComponent as IconSecurityDashboard } from '@lastpass/assets/svg/admin-console/security-dashboard-icon-light.svg'
import { ReactComponent as IconCheckmark } from '@lastpass/assets/svg/multifactor/checkmark-icon.svg'
import { ReactComponent as LegacyVpnIllustration } from '@lastpass/assets/svg/multifactor/legacy-vpn-illustration.svg'
import { ReactComponent as MfaAddonIllustration } from '@lastpass/assets/svg/multifactor/mfa-addon-illustration.svg'
import { ReactComponent as IconNetwork } from '@lastpass/assets/svg/multifactor/network-icon.svg'
import { ReactComponent as PasswordlessIllustration } from '@lastpass/assets/svg/multifactor/passwordless-illustration.svg'
import { ReactComponent as IconPasswordless } from '@lastpass/assets/svg/multifactor/passwordless-login-icon.svg'
import { ReactComponent as IconMobile } from '@lastpass/assets/svg/multifactor/security-mobile-icon.svg'
import { ReactComponent as IconSso } from '@lastpass/assets/svg/multifactor/single-sign-on-icon.svg'
import { ReactComponent as IconVpn } from '@lastpass/assets/svg/multifactor/vpn-icon.svg'
import { BodyRegular, PrimaryButton } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  MfaUpsellDialog,
  MfaUpsellDialogContext
} from '@lastpass/admin-console-dependencies/types/dialog-types'

import { UpsellDialogCell } from './UpsellDialogCell'

interface MfaUpsellDialogProps {
  state: MfaUpsellDialog
  actions: typeof globalActions
}

const transparentBackgroundColor = props => `${props.theme.colors.neutral900}e0`
const Background = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: ${transparentBackgroundColor};
  justify-content: center;
`
const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 828px;
  height: 672px;
  left: calc(50% - 828px / 2);
  top: calc(50% - 672px / 2 + 10px);
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(29, 48, 73, 0.16),
    0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08);
  border-radius: 16px;
`
const StyledTitle = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 390px;
  height: 28px;
  top: 200px;
  white-space: nowrap;
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #1d3049;
`
const StyledSubtitle = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 18px;
  height: 20px;
  top: 244px;
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #1d3049;
  white-space: nowrap;
`
const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 24px 0px;
  position: static;
  width: 223px;
  height: 80px;
  left: 302.5px;
  top: 560px;
`
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: static;
  width: 828px;
  height: 176px;
  left: 0px;
  top: 0px;
  background: #e4e5f6;
  border-radius: 16px 16px 0px 0px;
`
const TitleDiv = styled.div`
  height: 115px;
  top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BorderedDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: static;
  width: 764px;
  height: 248px;
  left: 32px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #dcdfe4;
  box-sizing: border-box;
  border-radius: 12px;
`
const StyledLegacyVpnHeaderIllustration = styled(LegacyVpnIllustration)`
  position: static;
  width: 185px;
  height: 185px;
  left: 321.5px;
  top: -4.5px;
`
const StyledWorkstationHeaderIllustration = styled(WorkstationIllustration)`
  position: static;
  width: 185px;
  height: 185px;
  left: 321.5px;
  top: -4.5px;
`
const StyledMfaAddonIllustration = styled(MfaAddonIllustration)`
  position: static;
  width: 185px;
  height: 185px;
  left: 321.5px;
  top: -4.5px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0px;
`
const CloseIconContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
`
const StyledCloseIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.neutral600};

  &:hover {
    color: ${props => props.theme.colors.neutral900};
  }
`
const StyledLink = styled.a`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #40526d !important;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`
const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 24px;
`
const StyledHeading100 = styled.div`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
  color: #1d3049;
  width: 100%;
`
const StyledBodyRegular = styled(BodyRegular)`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  width: 100%;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1d3049;
`
const Bold = styled.span`
  font-weight: bold;
`
const Link = styled.a`
  font-weight: ${props => props.theme.fonts.weight.bold};
`
const escapeKeyCode = 27

let BannerImage
let Subtitle
let Icon1
let Icon2
let Icon3
let Icon4
let Point1Title
let Point2Title
let Point3Title
let Point4Title
let Point1Subtitle
let Point2Subtitle
let Point3Subtitle
let Point4Subtitle

const isTouchSalesEnabled = companyDetails => {
  return CompanyDetailsHelper.isTouchSalesEnabled(companyDetails)
}

function setContextSpecificContent(context: MfaUpsellDialogContext) {
  switch (context) {
    case MfaUpsellDialogContext.legacyvpn:
      BannerImage = StyledLegacyVpnHeaderIllustration
      Subtitle = (
        <Trans>Lock down your VPN to protect your corporate network.</Trans>
      )
      Icon1 = IconNetwork
      Icon2 = IconPasswordless
      Icon3 = IconVpn
      Icon4 = IconSecurityDashboard
      Point1Title = msg`A safer corporate network`
      Point2Title = msg`Passwordless login`
      Point3Title = msg`Secure VPN access`
      Point4Title = msg`Advanced access control`
      Point1Subtitle = msg`Empower employee access to business resources without compromising security.`
      Point2Subtitle = msg`Streamline access with passwordless authentication for apps, VPNs, and workstations.`
      Point3Subtitle = msg`Protect every VPN login with seamless authentication.`
      Point4Subtitle = msg`Gain insight and control through geofencing and IP address policies.`
      break
    case MfaUpsellDialogContext.workstation:
      BannerImage = StyledWorkstationHeaderIllustration
      Subtitle = (
        <Trans>Eliminate the workstation password, eliminate the risk.</Trans>
      )
      Icon1 = IconPasswordless
      Icon2 = IconMobile
      Icon3 = IconVpn
      Icon4 = IconSecurityDashboard
      Point1Title = msg`Biometric authentication`
      Point2Title = msg`Passwordless login`
      Point3Title = msg`Less typing, easier access`
      Point4Title = msg`Advanced access control`
      Point1Subtitle = msg`Secure your workstations with biometric authentication technology.`
      Point2Subtitle = msg`Streamline access with passwordless authentication for apps, VPNs, and workstations.`
      Point3Subtitle = msg`Simplify workstation login by not requiring typed passwords.`
      Point4Subtitle = msg`Gain insight and control through geofencing and IP address policies.`
      break
    case MfaUpsellDialogContext.passwordless:
      BannerImage = PasswordlessIllustration
      Subtitle = (
        <Trans>
          Eliminate passwords for employees while maintaining control over every
          access point.
        </Trans>
      )
      Icon1 = IconPasswordless
      Icon2 = IconSso
      Icon3 = IconCheckmark
      Icon4 = IconSecurityDashboard
      Point1Title = msg`Seamless login`
      Point2Title = msg`Streamlined access`
      Point3Title = msg`Passwordless login`
      Point4Title = msg`Advanced access control`
      Point1Subtitle = msg`Authenticates employees using biometrics and integrations for seamless login.`
      Point2Subtitle = msg`Give employees passwordless authentication to cloud and legacy applications, VPNs, and workstations.`
      Point3Subtitle = msg`Eliminates the frustration of passwords to increase employee productivity.`
      Point4Subtitle = msg`Gain insight and control through geofencing and IP address policies.`
      break
    case MfaUpsellDialogContext.multifactor:
      BannerImage = StyledWorkstationHeaderIllustration
      Subtitle = (
        <Trans>
          Protect more endpoints with an additional layer of multifactor
          security.
        </Trans>
      )
      Icon1 = IconMobile
      Icon2 = IconPasswordless
      Icon3 = IconNetwork
      Icon4 = IconVpn
      Point1Title = msg`An extra layer of security`
      Point2Title = msg`Biometric logins for Active Directory`
      Point3Title = msg`Protect workstations`
      Point4Title = msg`A safer corporate network`
      Point1Subtitle = msg`Reduce risk by providing an additional security check after authenticating with a password.`
      Point2Subtitle = msg`Secure employee access to Microsoft Azure Active Directory and ADFS.`
      Point3Subtitle = msg`Add MFA to Windows and macOS workstation logins to reduce the threat of unauthorized access.`
      Point4Subtitle = msg`Integrate with services authenticating via LDAP/RADIUS.`
      break
    case MfaUpsellDialogContext.default:
    case MfaUpsellDialogContext.userActivityReporting:
    case MfaUpsellDialogContext.adminActivityReporting:
    case MfaUpsellDialogContext.ssoAppDrawer:
    default:
      BannerImage = StyledMfaAddonIllustration
      Subtitle = (
        <Trans>
          All-in-one solution to address your access and authentication needs.
        </Trans>
      )
      Icon1 = IconNetwork
      Icon2 = IconVpn
      Icon3 = IconMobile
      Icon4 = IconPasswordless
      Point1Title = msg`Protect workstations`
      Point2Title = msg`Secure VPN access`
      Point3Title = msg`An extra layer of security`
      Point4Title = msg`Seamless login`
      Point1Subtitle = msg`Add MFA to Windows and macOS workstation logins to reduce the threat of unauthorized access.`
      Point2Subtitle = msg`Protect every VPN login with seamless authentication.`
      Point3Subtitle = msg`Secure employee access to Microsoft Azure Active Directory and ADFS.`
      Point4Subtitle = msg`Authenticates employees using biometrics and integrations for seamless login.`
      break
  }
}

export const MultiContextMfaUpsellDialogComponent: FunctionComponent<MfaUpsellDialogProps> = ({
  ...props
}) => {
  setContextSpecificContent(props.state.dialogContext)

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === escapeKeyCode) {
        props.actions.discardDialogSegment(
          CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
          'Purchase',
          'Close',
          props.state.dialogContext
        )
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [companyDetails, props])

  const tellMeMore = (
    <StyledLink
      data-qa={'MoreInfoButton'}
      href={'https://www.lastpass.com/products/multifactor-authentication'}
      target="_blank"
      key={'learnMore'}
    >
      <Trans>Tell me more</Trans>
    </StyledLink>
  )

  const footerContent = isTouchSalesEnabled(companyDetails) ? (
    <>
      <StyledCenter>
        <StyledHeading100>
          <Trans>Contact us now to get Advanced MFA</Trans>
        </StyledHeading100>
        <StyledBodyRegular>
          <Trans>
            Call <Bold>+1 (781) 897-5017</Bold> or visit the{' '}
            <Link
              data-qa="SupportCenterLinkLink"
              href="https://support.logmeininc.com/lastpass"
              target="_blank"
            >
              <Trans>Support Center</Trans>
            </Link>{' '}
            to contact us.
          </Trans>
        </StyledBodyRegular>
      </StyledCenter>

      <FooterButtonContainer data-qa={'PopUpButtons'}>
        {tellMeMore}
      </FooterButtonContainer>
    </>
  ) : (
    <>
      <FooterButtonContainer data-qa={'PopUpButtons'}>
        <PrimaryButton
          data-qa={'ConfirmButton'}
          onClick={() => {
            props.actions.confirmDialogSegment(
              true,
              'Purchase',
              'Buy Now',
              props.state.dialogContext
            )
          }}
          key={'confirm'}
        >
          <Trans>Add Advanced MFA now</Trans>
        </PrimaryButton>
        {tellMeMore}
      </FooterButtonContainer>
    </>
  )

  return (
    <Background
      onClick={() =>
        props.actions.discardDialogSegment(
          CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
          'Purchase',
          'Close',
          props.state.dialogContext
        )
      }
    >
      <Popup onClick={e => e.stopPropagation()} data-qa={'PopUpContainer'}>
        <HeaderDiv>
          <BannerImage />
          <CloseIconContainer
            onClick={() =>
              props.actions.discardDialogSegment(
                CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
                'Purchase',
                'Close',
                props.state.dialogContext
              )
            }
            data-qa={'PopUpCloseButton'}
          >
            <StyledCloseIcon data-qa={'CloseIcon'} />
          </CloseIconContainer>
        </HeaderDiv>
        <TitleDiv data-qa={'TitleDiv'}>
          <StyledTitle>
            <Trans>Add the power and protection of Advanced MFA</Trans>
          </StyledTitle>
          <StyledSubtitle>{Subtitle}</StyledSubtitle>
        </TitleDiv>
        <BorderedDiv data-qa={'DescriptionDiv'}>
          <Row>
            <UpsellDialogCell
              icon={Icon1}
              title={i18n._(Point1Title)}
              subtitle={i18n._(Point1Subtitle)}
            />
            <UpsellDialogCell
              icon={Icon2}
              title={i18n._(Point2Title)}
              subtitle={i18n._(Point2Subtitle)}
            />
          </Row>
          <Row>
            <UpsellDialogCell
              icon={Icon3}
              title={i18n._(Point3Title)}
              subtitle={i18n._(Point3Subtitle)}
            />
            <UpsellDialogCell
              icon={Icon4}
              title={i18n._(Point4Title)}
              subtitle={i18n._(Point4Subtitle)}
            />
          </Row>
        </BorderedDiv>
        {footerContent}
      </Popup>
    </Background>
  )
}

import _ from 'lodash'

import { AssignGroupUsersActions, AssignGroupUsersActionTypes } from './actions'
import { AssignGroupUsersDrawerState } from './state'

export const initialState: AssignGroupUsersDrawerState = {
  users: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  group: {
    id: '',
    name: '',
    userCount: 0,
    securityScore: 0
  }
}

export function assignGroupUsersDrawerReducer(
  previousState: AssignGroupUsersDrawerState | undefined,
  action: AssignGroupUsersActions
): AssignGroupUsersDrawerState {
  const state = previousState || initialState
  const userTable = state.users
  switch (action.type) {
    case AssignGroupUsersActionTypes.RESET: {
      return initialState
    }
    case AssignGroupUsersActionTypes.SET_ASSIGN_USER_LIST_GROUP: {
      return {
        ...state,
        users: {
          ...userTable,
          results: action.payload.users,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          isLoading: false
        }
      }
    }
    case AssignGroupUsersActionTypes.CLEAR_USER_LIST_GROUP: {
      return {
        ...state,
        users: initialState.users
      }
    }
    case AssignGroupUsersActionTypes.SET_GROUP_USERS_ASSIGN_TABLE_LOADING: {
      return {
        ...state,
        users: {
          ...userTable,
          isLoading: action.payload.loading
        }
      }
    }
    case AssignGroupUsersActionTypes.SET_GROUP_DETAILS: {
      return {
        ...state,
        group: action.payload.group
      }
    }
    case AssignGroupUsersActionTypes.CLEAR_GROUP_DETAILS: {
      return {
        ...state,
        group: initialState.group
      }
    }
    case AssignGroupUsersActionTypes.ADD_SELECTED_GROUP_USERS: {
      return {
        ...state,
        users: {
          ...userTable,
          checkedRecords: userTable.checkedRecords.concat(
            action.payload.userList
          )
        }
      }
    }
    case AssignGroupUsersActionTypes.REMOVE_SELECTED_GROUP_USERS: {
      return {
        ...state,
        users: {
          ...userTable,
          checkedRecords: _.differenceWith(
            userTable.checkedRecords,
            action.payload.userList,
            _.isEqual
          )
        }
      }
    }
    case AssignGroupUsersActionTypes.SET_SELECTED_GROUP_USERS: {
      return {
        ...state,
        users: {
          ...userTable,
          checkedRecords: action.payload.userList
        }
      }
    }
  }
  return state
}

export enum ApplicationDrawerSteps {
  select = 'select',
  configure = 'configure',
  assign = 'assign',
  newAssign = 'newAssign',
  allset = 'allset',
  addUnlistedApp = 'addUnlistedApp',
  enableMultifactor = 'enableMultifactor',
  assignTestUser = 'assignTestUser',
  testLogin = 'testLogin'
}

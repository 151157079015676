import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { UserManager } from 'oidc-client-ts'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'

export enum KeyRotationAuthRedirectUri {
  popup = '/auth-popup',
  silent = '/auth-silent'
}

export const KeyRotationAuth: FunctionComponent<{
  redirectUri: KeyRotationAuthRedirectUri
}> = ({ redirectUri }) => {
  const { keyRotationAuth } = useSelector<AppState, FederatedLoginState>(
    appState => appState.federatedLogin
  )
  const { authority, clientId } = keyRotationAuth

  useEffect(() => {
    const baseUri = window.location.origin + process.env.PUBLIC_URL
    const mgr = new UserManager({
      authority: authority,
      client_id: clientId,
      redirect_uri: baseUri + redirectUri
    })

    if (redirectUri === KeyRotationAuthRedirectUri.popup) {
      mgr.signinPopupCallback()
    } else if (redirectUri === KeyRotationAuthRedirectUri.silent) {
      mgr.signinSilentCallback()
    }
  }, [authority, clientId, redirectUri])

  return <></>
}

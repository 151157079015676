import React from 'react'

import { Trans } from '@lingui/macro'
import { FormikProps } from 'formik'

import { PrimaryButton } from '@lastpass/lastkit'

import {
  AlienVaultIntegrationConfig,
  AzureSentinelIntegrationConfig,
  LogRhythmIntegrationConfig,
  QRadarIntegrationConfig,
  SplunkIntegrationConfig
} from '@lastpass/admin-console-dependencies/types/siem-integrations'

import { SubmitContainer } from './StyledComponents'

interface SiemIntegrationsSubmitButtonProps {
  dataQa: string
  formik:
    | FormikProps<AzureSentinelIntegrationConfig>
    | FormikProps<SplunkIntegrationConfig>
    | FormikProps<QRadarIntegrationConfig>
    | FormikProps<LogRhythmIntegrationConfig>
    | FormikProps<AlienVaultIntegrationConfig>
}

export const SiemIntegrationsSubmitButton: React.FC<SiemIntegrationsSubmitButtonProps> = ({
  dataQa,
  formik
}) => (
  <SubmitContainer>
    <PrimaryButton
      data-qa={dataQa}
      disabled={!formik.dirty || !!Object.values(formik.errors).length}
      type="submit"
    >
      <Trans>Save changes</Trans>
    </PrimaryButton>
  </SubmitContainer>
)

import React, { FC, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyRegular,
  CardContainer,
  Drawer,
  DrawerButtonsContainer,
  FooterContainer,
  Heading100,
  Pagination,
  PrimaryButton,
  RowContainer,
  StyledSpacer,
  Table,
  TableView,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { useUnmountEffect } from '@lastpass/ui'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { DashboardDrawerState } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'

const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  padding: 18px;
`
interface InactiveUsersDrawerProps {
  closeLink: string
}

export const InactiveUsersDrawer: FC<InactiveUsersDrawerProps> = ({
  closeLink
}) => {
  const updateQuery = useUpdateQuery(Namespace.inactiveUsers)
  const queryParams = useQueryParams(Namespace.inactiveUsers)
  const { sendRemindInactiveUsersEmail } = useContext(ServerContext)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      dashboardDrawerActions.getInactiveUsers({ query: queryParams, path: {} })
    )
  }, [dispatch, queryParams])

  useUnmountEffect(() => {
    dispatch(dashboardDrawerActions.reset())
  })

  const dashboardDrawerState = useSelector<AppState, DashboardDrawerState>(
    state => state.dashboardDrawer
  )

  const sendEmail = async (): Promise<void> => {
    try {
      dispatch(dashboardDrawerActions.setDashboardDrawerLoading(true))
      await sendRemindInactiveUsersEmail()
      dispatch(
        globalActions.setNotification({
          type: NotificationType.success,
          message: msg`A reminder email has been sent to ${dashboardDrawerState.inactiveUsers.totalResults} users`,
          autoDismiss: true
        })
      )
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      dispatch(dashboardDrawerActions.setDashboardDrawerLoading(false))
    }
  }

  return (
    <Drawer
      mainTitle={<Trans>Remind inactive users</Trans>}
      closeLink={closeLink}
    >
      {dashboardDrawerState.dashboardDrawerLoading && (
        <Loading color="blue900" active />
      )}
      <BodyRegular>
        <p>
          <Trans>
            Send an email to{' '}
            <b>{dashboardDrawerState.inactiveUsers.totalResults}</b> users who
            haven’t used LastPass in the last 30 days.
          </Trans>
        </p>
        <p>
          <Trans>
            <b>Pro tip:</b> To remind users individually, go to the Users page.
            You can also export to CSV.
          </Trans>
        </p>
      </BodyRegular>
      <CardContainer
        css={css`
          margin-top: 24px;
        `}
      >
        <StyledRowContainer>
          <Heading100>
            <Trans>Users</Trans>
          </Heading100>
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={dashboardDrawerState.inactiveUsers.totalResults}
            resultsPerPage={dashboardDrawerState.inactiveUsers.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </StyledRowContainer>
        <Table
          qadata="InactiveUsersDrawerTable"
          table={dashboardDrawerState.inactiveUsers}
          columns={[
            {
              name: <Trans>Name</Trans>,
              renderer: (record: Partial<User>) => getFullName(record)
            },
            {
              name: <Trans>Email</Trans>,
              renderer: ({ email }: Partial<User>) => email || ''
            },
            {
              name: <Trans>Last invitation</Trans>,
              renderer: ({ inviteEmailSentTimeUtc }: Partial<User>) =>
                inviteEmailSentTimeUtc
                  ? moment(inviteEmailSentTimeUtc).format('MMM DD, YYYY')
                  : ''
            }
          ]}
          loadingView={<Loading color="blue900" active />}
          actions={[]}
          noRecordsView={
            <TableView
              icon={<UserIcon />}
              title={<Trans>Congrats! Everyone is active</Trans>}
            />
          }
        />
      </CardContainer>
      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          {dashboardDrawerState.inactiveUsers.totalResults > 0 ? (
            <>
              <TextButton
                blue
                onClick={() => {
                  dispatch(dashboardDrawerActions.exportInactiveUsers())
                  dispatch(
                    dashboardDrawerActions.exportUsersClicked(
                      'Inactive Users Drawer'
                    )
                  )
                }}
                css={css`
                  margin-right: 16px;
                `}
              >
                <Trans>Export users</Trans>
              </TextButton>
              <PrimaryButton onClick={sendEmail}>
                <Trans>Send reminder email to users</Trans>
              </PrimaryButton>
            </>
          ) : (
            <LocationLink to={closeLink}>
              <PrimaryButton>
                <Trans>Close</Trans>
              </PrimaryButton>
            </LocationLink>
          )}
        </DrawerButtonsContainer>
      </FooterContainer>
    </Drawer>
  )
}

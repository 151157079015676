import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  adminLevelDrawerActions,
  adminLevelDrawerSegmentActions
} from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { BulkActionErrors } from '@lastpass/admin-console-dependencies/types/bulk-action-errors'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { WithError } from '@lastpass/admin-console-dependencies/types/with-error'

export function createRemoveUsersFromAdminLevelSaga(
  adminLevelRemoveUsersService: UACServices.Services
) {
  return function* removeUsersFromAdminLevelSaga(
    action: ReturnType<typeof adminLevelDrawerActions.removeUsersFromAdminLevel>
  ) {
    try {
      const selectedUsers = action.payload.users
      const adminLevel = action.payload.adminLevel
      const assignUsersTexts =
        selectedUsers.length === 1
          ? {
              success: msg`User has been removed from an admin level: ${selectedUsers[0].email}`,
              fail: msg`User hasn't been removed from an admin level: ${selectedUsers[0].email}`
            }
          : {
              success: msg`The selected users have been removed from an admin level.`,
              fail: msg`None of the selected users have been removed from an admin level. You must have at least one admin. `
            }
      let numberFailed = 0
      let numberSucceeded = 0

      try {
        const result: UACServices.AdminLevelEditUsersAPI.Responses = yield call(
          adminLevelRemoveUsersService.adminLevelEditUsers,
          selectedUsers,
          adminLevel.id,
          'DELETE'
        )
        if (result.type === UACServices.AdminLevelEditUsersAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: assignUsersTexts.success,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
          numberSucceeded = selectedUsers.length
        } else if (result.type === UACServices.AdminLevelEditUsersAPI.FAILURE) {
          yield put(
            globalActions.setNotification({
              message: assignUsersTexts.fail,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
          numberFailed = selectedUsers.length
        } else if (
          result.type === UACServices.AdminLevelEditUsersAPI.PARTIAL_SUCCESS
        ) {
          const usersWithErrors: WithError<User>[] = selectedUsers
            .map(user => {
              return { ...user, error: BulkActionErrors.failedToAssign }
            })
            .filter(user => !!user.error)

          yield put(
            globalActions.setNotification({
              message: msg`Some users haven't been removed from the admin level.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'group-user',
                users: usersWithErrors
              }
            })
          )
          numberFailed = usersWithErrors.length
          numberSucceeded = selectedUsers.length - numberFailed
        }

        yield put(
          adminLevelDrawerSegmentActions.usersRemovedFromAdminLevel(
            numberFailed,
            numberSucceeded
          )
        )
      } finally {
        yield put(adminListActions.getAdminList())
        yield put(push(`/users/admin/${adminLevel.id}`))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Migration from '@lastpass/admin-console-dependencies/server/applications/saml/get-is-migrated'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'

export function createIsMigrationRequired(
  applicationsServices: UACServices.Services
) {
  return function* getIsMigrationRequired() {
    try {
      const getIsMigrationRequiredResponse: Migration.Responses = yield call(
        applicationsServices.getIsMigrationRequired
      )

      if (
        getIsMigrationRequiredResponse &&
        getIsMigrationRequiredResponse.type === Migration.SUCCESS
      ) {
        const isMigrationRequired = getIsMigrationRequiredResponse.body.Value

        yield put(
          systemUpgradeActions.setIsMigrationRequired(isMigrationRequired)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { FormikProps } from 'formik'

import { BodyRegularStyle, TextInput } from '@lastpass/lastkit'

import { FormValues } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { PsaEditCredentialsType } from '@lastpass/admin-console-dependencies/types/advanced-psa-integrations'
import { PsaEditCredentialsMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PsaEditCredentialsMapping'

export interface InputContainerProps {
  required: boolean
}

const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: 20px;
`

const StyledInputTitle = styled.div<InputContainerProps>`
  ${props =>
    props.required &&
    `&:after {
    content: '*';
    color: ${props.theme.colors.red700};
  }`}
`

const StyledSecretTitle = styled.div`
  ${BodyRegularStyle};
  font-size: 12px;
  margin-top: 8px;
`

export interface EditPsaFieldProps {
  name: string
  type: string
  isMandatory: boolean
  formik: FormikProps<FormValues | null>
}

export const EditPsaField: FunctionComponent<EditPsaFieldProps> = ({
  name,
  type,
  isMandatory,
  formik
}) => {
  return (
    <InputContainer required={isMandatory} data-qa={`PsaEditForm${name}`}>
      <TextInput
        id={name}
        name={name}
        type={type}
        required={isMandatory}
        onChange={formik.handleChange}
        value={formik.values ? formik.values[name] : ''}
        onBlur={formik.handleBlur}
        error={!!(formik.errors && formik.errors[name])}
        errorText={<>{formik.errors ? formik.errors[name] : ''}</>}
        data-qa="Input"
      >
        <StyledInputTitle required={isMandatory}>
          <Trans>
            {PsaEditCredentialsMapping[PsaEditCredentialsType[name]]}
          </Trans>
        </StyledInputTitle>
      </TextInput>
      {type === 'password' && (
        <StyledSecretTitle>
          <Trans>
            You can’t see or copy this information, but you can change it.
          </Trans>
        </StyledSecretTitle>
      )}
    </InputContainer>
  )
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  PrimaryButton,
  SearchInput,
  StyledSpacer,
  TableViewButton,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { assignActions } from '@lastpass/admin-console-dependencies/state/common/assign/actions'
import { AssignState } from '@lastpass/admin-console-dependencies/state/common/assign/state'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import {
  AppliesToOptions,
  Entities
} from '@lastpass/admin-console-dependencies/types/assign'

import { AppliesToRadioButtons } from './AppliesToRadioButtons'
import { GroupsTableComponent } from './GroupsTable'
import { UsersTableComponent } from './UsersTable'

export interface AssignProps {
  closeLink: string
}

type AssignComponentProps = LastPassComponentProps<
  AssignState,
  typeof assignActions & typeof advancedPolicyDrawerActions,
  AssignProps
>

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

export const AssignComponent: React.FunctionComponent<AssignComponentProps> = ({
  actions,
  state,
  props
}) => {
  const history = useHistory()

  const getSearchInput = () => {
    if (state.showTable === Entities.users) {
      return state.users.searchInput
    }
    if (state.showTable === Entities.groups) {
      return state.groups.searchInput
    }
  }

  const unassignDisabled =
    state.users.filtered.checkedRecords.length === 0 &&
    state.groups.filtered.checkedRecords.length === 0

  return (
    <>
      <AppliesToRadioButtons
        onChange={value => {
          actions.changeAppliesTo(value)
        }}
        initValue={state.appliesTo}
      />
      {state.appliesTo !== AppliesToOptions.all && (
        <>
          <SearchInput
            data-qa="AssignSearchBar"
            value={getSearchInput()}
            onChange={event => {
              if (state.showTable === Entities.users) {
                actions.setSearchInput(Entities.users, event.target.value)
                actions.filterUsers(Entities.users, event.target.value)
              }
              if (state.showTable === Entities.groups) {
                actions.setSearchInput(Entities.groups, event.target.value)
                actions.filterUsers(Entities.groups, event.target.value)
              }
            }}
            placeholder={msg`Search users & groups...`}
          />
          <TableContainer>
            <StyledTabContainer data-qa="TabNavigationBar">
              <TabNavigation>
                <div
                  data-qa="AssignUsersTab"
                  onClick={() => {
                    actions.showTable(Entities.users)
                  }}
                >
                  <StyledTabNavigationItem
                    active={state.showTable === Entities.users}
                  >
                    <Trans>Users</Trans>
                    {` (${state.users.filtered.totalResults})`}
                  </StyledTabNavigationItem>
                </div>
                <div
                  data-qa="AssignGroupsTab"
                  onClick={() => {
                    actions.showTable(Entities.groups)
                  }}
                >
                  <StyledTabNavigationItem
                    active={state.showTable === Entities.groups}
                  >
                    <Trans>Groups</Trans>
                    {` (${state.groups.filtered.totalResults})`}
                  </StyledTabNavigationItem>
                </div>
              </TabNavigation>
              <PrimaryButton
                data-qa="NewAssignButton"
                onClick={() => {
                  actions.nextStep(AdvancedPolicyDrawerSteps.assignNewEntities)
                }}
              >
                <Trans>Assign users {'&'} groups</Trans>
              </PrimaryButton>
            </StyledTabContainer>
          </TableContainer>
          {state.showTable === Entities.users ? (
            <UsersTableComponent
              table={state.users.filtered}
              checkable={{
                addSelectedRecords: actions.addSelectedUsersToUnassign,
                removeSelectedRecords: actions.removeSelectedUsersToUnassign,
                setSelectedRecords: actions.setSelectedUsersToUnassign
              }}
              title={<Trans>No users assigned yet</Trans>}
              text={
                <Trans>Make this app useful by assigning some users.</Trans>
              }
              actionButton={
                <LocationLink
                  to={`#`}
                  onClick={() => {
                    actions.nextStep(
                      AdvancedPolicyDrawerSteps.assignNewEntities
                    )
                  }}
                >
                  <TableViewButton data-qa="AssignUsersToSsoAppButton">
                    <Trans>Assign users</Trans>
                  </TableViewButton>
                </LocationLink>
              }
            />
          ) : null}
          {state.showTable === Entities.groups ? (
            <GroupsTableComponent
              table={state.groups.filtered}
              checkable={{
                addSelectedRecords: actions.addSelectedGroupsToUnassign,
                removeSelectedRecords: actions.removeSelectedGroupsToUnassign,
                setSelectedRecords: actions.setSelectedGroupsToUnassign
              }}
              title={<Trans>No groups assigned yet</Trans>}
              text={
                <Trans>Make this app useful by assigning some groups.</Trans>
              }
              actionButton={
                <LocationLink
                  to={`#`}
                  onClick={() => {
                    actions.nextStep(
                      AdvancedPolicyDrawerSteps.assignNewEntities
                    )
                  }}
                >
                  <TableViewButton data-qa="AssignGroupsToSsoAppButton">
                    <Trans>Assign groups</Trans>
                  </TableViewButton>
                </LocationLink>
              }
            />
          ) : null}
        </>
      )}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => history.push(props.closeLink)}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            css={css`
              margin-right: 16px;
            `}
            id="UnassignButton"
            key="Unassign"
            onClick={() => {
              actions.unassign()
            }}
            disabled={unassignDisabled}
            data-qa="UnassignButton"
          >
            <Trans>Unassign</Trans>
          </PrimaryButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              actions.nextStep(AdvancedPolicyDrawerSteps.allSet)
            }}
            disabled={false}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

export function createAssign<TState extends { assign: AssignState }>() {
  return connectRedux(AssignComponent, (state: TState) => state.assign, {
    ...assignActions,
    ...advancedPolicyDrawerActions
  })
}

export type AssignUsers = ReturnType<typeof createAssign>

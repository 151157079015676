import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { samlResponsesActions } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'

export function createGetSamlResponses(samlListService: UACServices.Services) {
  return function* getSamlResponses(
    action: ReturnType<typeof samlResponsesActions.getSamlResponses>
  ) {
    const { search, from, skip = 1 } = action.payload.query

    try {
      const samlResponse: UACServices.SamlResponsesAPI.Responses = yield call(
        samlListService.samlResponse,
        search,
        from,
        skip
      )

      yield put(
        samlResponsesActions.setSamlResponses(
          samlResponse.body.samlResponses,
          samlResponse.body.totalResults
        )
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

export enum GroupOperationType {
  deleteGroup = 'deleteGroup'
}

export enum GroupUserActionsType {
  mpChange = 'mpChange',
  vaultReEncryption = 'vaultReEncryption',
  resendMfa = 'resendMfa',
  destroySessions = 'destroySessions',
  disableUsers = 'disableUsers',
  removeFromCompany = 'removeFromCompany',
  deleteUsers = 'deleteUsers',
  disableMfa = 'disableMfa',
  inviteUsers = 'inviteUsers'
}

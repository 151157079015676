import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { i18n } from '@lingui/core'

import { ReactComponent as WarningTriangle } from '@lastpass/assets/svg/admin-console/icon-warning-triangle.svg'
import { WithTooltip } from '@lastpass/lastkit'

const StyledWarningTriangle = styled(WarningTriangle)`
  color: #edbf0e;
  height: 15px;
  cursor: pointer;
`

interface AgreementSelectorMessageProp {
  message?: MessageDescriptor
}

export const AgreementSelectorMessage: FunctionComponent<AgreementSelectorMessageProp> = ({
  message
}) => {
  return message ? (
    <WithTooltip placement="top" width="auto" tooltip={i18n._(message)}>
      <StyledWarningTriangle data-qa="AgreementSelectionWarningIcon" />
    </WithTooltip>
  ) : null
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { CaptionRegularStyle } from '@lastpass/lastkit'

const TooltipStyle = styled.div`
  ${CaptionRegularStyle};
  line-height: 16px;
  width: 100%;
  text-align: left;
`

export const ConsumedLicensesTooltip: FC = () => (
  <TooltipStyle>
    <Trans>
      Each user consumes a license regardless of their LastPass Status.
    </Trans>
  </TooltipStyle>
)

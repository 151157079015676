import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPolicySwitchONSaveAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { SymantecVipSettings } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createSaveSymantecSettings(
  multifactorServices: UACServices.Services
) {
  return function* saveSymantecSettings(
    action: ReturnType<typeof multifactorDrawerActions.saveSymantecSettings>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))

      const formValues = action.payload.symantecConfig
      const symantecPolicyData = action.payload.policyData
      const symantecConfig: SymantecVipSettings = {
        certificate: formValues.certificate,
        certificateLength: formValues.certificateLength,
        certificatePassword: formValues.certificatePassword,
        certificatePasswordLength: formValues.certificatePasswordLength,
        hasCertificatePassword: formValues.hasCertificatePassword
      }

      try {
        const [
          saveSymantecSettingsResponse,
          saveSymantecPolicyDataResponse
        ] = yield all([
          call(multifactorServices.symantecVipSettingsSave, symantecConfig),
          call(
            multifactorServices.generalPolicySwitchOnSave,
            symantecPolicyData
          )
        ])

        if (
          saveSymantecSettingsResponse.type ===
            UACServices.SymantecVipSettingsSaveAPI.SUCCESS &&
          saveSymantecPolicyDataResponse.type ===
            GeneralPolicySwitchONSaveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else if (
          saveSymantecPolicyDataResponse.type ===
          GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`This policy can't have multiple configurations.`
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

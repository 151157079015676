import { put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { generalPolicyUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'

export function createInitiatePolicyAppliesToForAssignGeneralPolicySaga() {
  return function* initiatePolicyAppliesToForAssignGeneralPolicySaga(
    action: ReturnType<
      typeof generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroupsAppliesToForAssign
    >
  ) {
    try {
      const policyAppliesTo = action.payload.appliesTo
      yield put(
        generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyCurrentlyAppliesTo(
          policyAppliesTo
        )
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

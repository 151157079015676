import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { DetailsDialog } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { createUserAdoptionHistoryChartComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/UserAdoptionHistoryChart'

export const EnrollmentRateDialogComponent: FunctionComponent = () => {
  const dispatch = useDispatch()
  const UserAdoptionHistoryChart = createUserAdoptionHistoryChartComponent<
    AppState
  >()
  return (
    <DetailsDialog
      closeDialog={() => dispatch(globalActions.emptyDialog())}
      title={msg`Enrollment rate`}
      content={<UserAdoptionHistoryChart />}
      width="780px"
    />
  )
}

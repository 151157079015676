import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegular,
  ButtonsContainer,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { FileUploader } from '@lastpass/lastkit/components/FileUpload'
import { LocationLink } from '@lastpass/routing'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import {
  MultifactorOptionType,
  SymantecFormModel
} from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { BodyText } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'
import { MultifactorTypeAuthMethodMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

export interface SymantecConfigProps {
  closeLink: string
  isLastStep: boolean
}

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const FileUploadContainer = styled.div`
  display: flex;
`

const FileUploadLabel = styled.div`
  margin-right: 24px;
`

export const SymantecConfig: React.FunctionComponent<SymantecConfigProps> = ({
  closeLink,
  isLastStep
}) => {
  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(multifactorDrawerActions.getSymantecConfig())
  }, [dispatch])

  const initialValues: SymantecFormModel = {
    certificate: multifactorDrawerState.symantecConfig.certificate,
    certificatePassword:
      multifactorDrawerState.symantecConfig.certificatePassword
  }

  return (
    <BodyRegular>
      <BodyText>
        <Trans>
          LastPass supports Symantec VIP authentication. You must provide
          LastPass with a certificate. In Symantec VIP Manager, go to Account
          {'>'} Manage VIP Certificates. Request a certificate for LastPass,
          then download it in PEM format.
        </Trans>
      </BodyText>
      <Formik
        onSubmit={values => {
          if (isLastStep) {
            dispatch(
              multifactorDrawerActions.saveSymantecConfig(values, closeLink)
            )
          } else {
            dispatch(multifactorDrawerActions.processSymantecConfig(values))
            dispatch(multifactorDrawerActions.setIsConfigured(true))
          }
        }}
        initialValues={initialValues}
        enableReinitialize
      >
        {formikProps => (
          <Form>
            <InputContainer>
              <Field name="certificate">
                {formData => (
                  <FileUploadContainer>
                    <FileUploadLabel>
                      <Trans>Certificate</Trans>
                    </FileUploadLabel>
                    <FileUploader
                      label={msg`Upload`}
                      onChange={(e, file) => {
                        formikProps.setFieldValue(formData.field.name, file)
                        e.target.value = ''
                      }}
                      deleteFile={() => {
                        formikProps.setFieldValue(formData.field.name, null)
                      }}
                      acceptedFileTypes={'.pem,.cer'}
                    />
                  </FileUploadContainer>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="certificatePassword">
                {formData => (
                  <TextInput
                    data-qa="CertificatePassword"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                    isPassword={true}
                  >
                    <Trans>Certificate password</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <LocationLink to={closeLink}>
                  <TextButton
                    type="button"
                    css={css`
                      margin-right: 16px;
                    `}
                    key="Discard"
                    data-qa="CancelButton"
                    onClick={() =>
                      dispatch(
                        multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                          MultifactorTypeAuthMethodMapping[
                            MultifactorOptionType.SymantecVip
                          ],
                          'Discard'
                        )
                      )
                    }
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  id="ContinueButton"
                  key="Continue"
                  type="submit"
                  disabled={!formikProps.dirty}
                  data-qa="ContinueButton"
                  onClick={() =>
                    dispatch(
                      multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                        MultifactorTypeAuthMethodMapping[
                          MultifactorOptionType.SymantecVip
                        ],
                        'Continue'
                      )
                    )
                  }
                >
                  {isLastStep ? (
                    <Trans>Save {'&'} exit</Trans>
                  ) : (
                    <Trans>Continue</Trans>
                  )}
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </BodyRegular>
  )
}

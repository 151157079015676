import { useContext } from 'react'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

export type UseCheckConfigurePermission = (
  callback: () => JSX.Element | null
) => JSX.Element | null

export const useCheckConfigurePermission: UseCheckConfigurePermission = callback => {
  const { requirePermission } = useContext(PermissionContext)

  const isConfigurePermission = requirePermission(
    AdminPermissions.configureManagedServiceProviderPsa
  )

  return isConfigurePermission ? callback() : null
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  PrimaryButton,
  SearchInput,
  StyledSpacer,
  TableViewButton,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'

import { Entities } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import { AppliesToSelectionMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

import { AppliesToRadioButtons } from './AppliesToRadioButtons'
import { GroupsTableComponent } from './MultifactorGroupsTable'
import { UsersTableComponent } from './MultifactorUsersTable'

export interface AssignProps {
  closeLink: string
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`

export const Assign: React.FunctionComponent<AssignProps> = ({ closeLink }) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )

  useEffect(() => {
    dispatch(multifactorDrawerActions.multifactorAssignUsersViewed())
  }, [dispatch])

  const setSearchInput = () => {
    if (multifactorDrawerState.assignFormState.showTable === Entities.users) {
      return multifactorDrawerState.assignFormState.usersSearchInput
    }
    if (multifactorDrawerState.assignFormState.showTable === Entities.groups) {
      return multifactorDrawerState.assignFormState.groupsSearchInput
    }
  }

  const unassignDisabled =
    multifactorDrawerState.assignFormState.filteredUsers.checkedRecords
      .length === 0 &&
    multifactorDrawerState.assignFormState.filteredGroups.checkedRecords
      .length === 0

  return (
    <>
      <AppliesToRadioButtons
        onChange={value => {
          dispatch(multifactorDrawerActions.changeAppliesTo(value))
        }}
        initValue={multifactorDrawerState.assignFormState.appliesTo}
      />
      {multifactorDrawerState.assignFormState.appliesTo !==
        AppliesToOptions.all && (
        <>
          <SearchInput
            data-qa="SearchFieldInput"
            value={setSearchInput()}
            onChange={event => {
              if (
                multifactorDrawerState.assignFormState.showTable ===
                Entities.users
              ) {
                dispatch(
                  multifactorDrawerActions.setUserListSearchInput(
                    event.target.value
                  )
                )
                dispatch(
                  multifactorDrawerActions.filterUsers(event.target.value)
                )
              }
              if (
                multifactorDrawerState.assignFormState.showTable ===
                Entities.groups
              ) {
                dispatch(
                  multifactorDrawerActions.setGroupListSearchInput(
                    event.target.value
                  )
                )
                dispatch(
                  multifactorDrawerActions.filterGroups(event.target.value)
                )
              }
            }}
            placeholder={msg`Search users & groups...`}
          />
          <TableContainer>
            <StyledTabContainer data-qa="TabNavigationBar">
              <TabNavigation>
                <div
                  data-qa="AssignUsersTab"
                  onClick={() => {
                    dispatch(multifactorDrawerActions.showTable(Entities.users))
                  }}
                >
                  <StyledTabNavigationItem
                    active={
                      multifactorDrawerState.assignFormState.showTable ===
                      Entities.users
                    }
                  >
                    <Trans>Users</Trans>
                    {` (${multifactorDrawerState.assignFormState.filteredUsers.totalResults})`}
                  </StyledTabNavigationItem>
                </div>
                <div
                  data-qa="AssignGroupsTab"
                  onClick={() => {
                    dispatch(
                      multifactorDrawerActions.showTable(Entities.groups)
                    )
                  }}
                >
                  <StyledTabNavigationItem
                    active={
                      multifactorDrawerState.assignFormState.showTable ===
                      Entities.groups
                    }
                  >
                    <Trans>Groups</Trans>
                    {` (${multifactorDrawerState.assignFormState.filteredGroups.totalResults})`}
                  </StyledTabNavigationItem>
                </div>
              </TabNavigation>
              <PrimaryButton
                data-qa="NewAssignButton"
                onClick={() => {
                  dispatch(
                    multifactorDrawerActions.nextStep(
                      MultifactorDrawerSteps.assignNewEntities
                    )
                  )
                }}
              >
                <Trans>Assign users {'&'} groups</Trans>
              </PrimaryButton>
            </StyledTabContainer>
          </TableContainer>
          {multifactorDrawerState.assignFormState.showTable ===
          Entities.users ? (
            <UsersTableComponent
              table={multifactorDrawerState.assignFormState.filteredUsers}
              checkable={{
                addSelectedRecords: record =>
                  dispatch(
                    multifactorDrawerActions.addSelectedUsersToUnassign(record)
                  ),
                removeSelectedRecords: record =>
                  dispatch(
                    multifactorDrawerActions.removeSelectedUsersToUnassign(
                      record
                    )
                  ),
                setSelectedRecords: record =>
                  dispatch(
                    multifactorDrawerActions.setSelectedUsersToUnassign(record)
                  )
              }}
              title={<Trans>No users assigned yet</Trans>}
              text={
                <Trans>Make this app useful by assigning some users.</Trans>
              }
              actionButton={
                <LocationLink
                  to={`#`}
                  onClick={() => {
                    dispatch(
                      multifactorDrawerActions.nextStep(
                        MultifactorDrawerSteps.assignNewEntities
                      )
                    )
                  }}
                >
                  <TableViewButton data-qa="AssignUsersToSsoAppButton">
                    <Trans>Assign users</Trans>
                  </TableViewButton>
                </LocationLink>
              }
            />
          ) : null}
          {multifactorDrawerState.assignFormState.showTable ===
          Entities.groups ? (
            <GroupsTableComponent
              table={multifactorDrawerState.assignFormState.filteredGroups}
              checkable={{
                addSelectedRecords: record =>
                  dispatch(
                    multifactorDrawerActions.addSelectedGroupsToUnassign(record)
                  ),
                removeSelectedRecords: record =>
                  dispatch(
                    multifactorDrawerActions.removeSelectedGroupsToUnassign(
                      record
                    )
                  ),
                setSelectedRecords: record =>
                  dispatch(
                    multifactorDrawerActions.setSelectedGroupsToUnassign(record)
                  )
              }}
              title={<Trans>No groups assigned yet</Trans>}
              text={
                <Trans>Make this app useful by assigning some groups.</Trans>
              }
              actionButton={
                <LocationLink
                  to={`#`}
                  onClick={() => {
                    dispatch(
                      multifactorDrawerActions.nextStep(
                        MultifactorDrawerSteps.assignNewEntities
                      )
                    )
                  }}
                >
                  <TableViewButton data-qa="AssignGroupsToSsoAppButton">
                    <Trans>Assign groups</Trans>
                  </TableViewButton>
                </LocationLink>
              }
            />
          ) : null}
        </>
      )}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => {
              dispatch(
                multifactorDrawerActions.multifactorAssignUsersClicked(
                  AppliesToSelectionMapping[
                    multifactorDrawerState.assignFormState.appliesTo
                  ],
                  'Discard'
                )
              )
              history.push(closeLink)
            }}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            css={css`
              margin-right: 16px;
            `}
            id="UnassignButton"
            key="Unassign"
            onClick={() => {
              dispatch(multifactorDrawerActions.unassign())
            }}
            disabled={unassignDisabled}
            data-qa="UnassignButton"
          >
            <Trans>Unassign</Trans>
          </PrimaryButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              dispatch(
                multifactorDrawerActions.multifactorAssignUsersClicked(
                  AppliesToSelectionMapping[
                    multifactorDrawerState.assignFormState.appliesTo
                  ],
                  'Continue'
                )
              )
              dispatch(
                multifactorDrawerActions.nextStep(MultifactorDrawerSteps.allSet)
              )
            }}
            disabled={false}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

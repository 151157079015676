import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { msg, Trans } from '@lingui/macro'

import { ReactComponent as InactiveGreenIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/inactive-green.svg'
import { ReactComponent as InactiveRedIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/inactive-red.svg'
import { PrimaryButton, TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'
import { ActionSuccess } from '@lastpass/admin-console-dependencies/ui/home/components/action-success/ActionSuccess'
import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

export interface InactiveUsersCardProps {
  count: number
  dataQa?: string
}

export const InactiveUsersCard: FC<InactiveUsersCardProps> = ({
  count,
  dataQa
}) => {
  const countColor = useCountColor(count)
  return (
    <ActionCard
      icon={count === 0 ? InactiveGreenIcon : InactiveRedIcon}
      count={count}
      countColor={countColor}
      title={msg`Inactive users`}
      description={
        <>
          <Trans>
            These users haven’t used LastPass in the last 30 days. They may be
            accessing sensitive resources insecurely.
          </Trans>{' '}
          <TextButton blue>
            <LocationLink
              to="https://support.lastpass.com/help/what-are-the-enrollment-and-utilization-rates"
              data-qa="LearnMoreLink"
              target="_blank"
            >
              <Trans>Learn more</Trans>
            </LocationLink>
          </TextButton>
        </>
      }
      action={
        count === 0 ? (
          <ActionSuccess message={msg`Everyone’s using LastPass`} />
        ) : (
          <Link to="/dashboard/inactive">
            <PrimaryButton data-qa="InactiveUsersRemindUsersButton">
              <Trans>View & remind users</Trans>
            </PrimaryButton>
          </Link>
        )
      }
      dataQa={dataQa}
    />
  )
}

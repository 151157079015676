import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  addUsersDrawerActions,
  userDrawerSegmentActions
} from '@lastpass/admin-console-dependencies/state/users/view/add/actions'
import { NewAddUser } from '@lastpass/admin-console-dependencies/state/users/view/add/state'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { WithError } from '@lastpass/admin-console-dependencies/types/with-error'
import { NotificationUserEmailNotVerified } from '@lastpass/admin-console-dependencies/ui/users/view/NotificationUserEmailNotVerified'

export const getCurrentUrlFilters = (state: AppState) => state.router

let errorTypeDomainMismatch
let errorTypeUserAlreadyinCompany

export const countErrorsByType = errorMessages => {
  errorTypeDomainMismatch = 0
  errorTypeUserAlreadyinCompany = 0
  Object.values(errorMessages).forEach(message => {
    switch (message) {
      case 'already_exists': {
        errorTypeUserAlreadyinCompany += 1
        break
      }
      case 'USER_CREATE_ERROR_USERNAME_POLICY_VIOLATION': {
        errorTypeDomainMismatch += 1
        break
      }
    }
  })
}

export function createAddUsersSaga(addUsersService: UACServices.Services) {
  return function* addUsersSaga(
    action: ReturnType<typeof addUsersDrawerActions.addNewUsers>
  ) {
    try {
      const users: NewAddUser[] = action.payload.newUsers
      const sendMfaInvite: boolean = action.payload.lastPassMFAInvite
      const sendLpInvite: boolean = action.payload.passwordManagementInvite
      const currentUrlFilters = yield select(getCurrentUrlFilters)
      const result: UACServices.AddUsersAPI.Responses = yield call(
        addUsersService.addUsers,
        users,
        sendMfaInvite,
        sendLpInvite
      )
      let numberFailed = 0
      let numberSucceeded = 0

      try {
        if (result.type === UACServices.AddUsersAPI.SUCCESS) {
          numberSucceeded = users.length
          yield put(
            globalActions.setNotification({
              message: msg`Users have been added.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (
          result.type ===
          UACServices.AddUsersAPI.FAIL_EMAIL_TEMPLATES_NOT_APPROVED
        ) {
          yield put(
            globalActions.setNotification({
              message: msg`A member of the LastPass team must approve your template. Approval can take up to 24 hours.`,
              type: NotificationType.warning,
              autoDismiss: true
            })
          )
        } else if (
          result.type ===
          UACServices.AddUsersAPI.FAIL_REQUESTER_USER_EMAIL_NOT_VERIFIED
        ) {
          yield put(
            globalActions.setNotification({
              type: NotificationType.warning,
              autoDismiss: false,
              component: NotificationUserEmailNotVerified
            })
          )
        } else if (result.type === UACServices.AddUsersAPI.FAIL) {
          const errorMessages = result.body.failedUsers
          countErrorsByType(errorMessages)
          numberFailed = users.length
          yield put(
            globalActions.setNotification({
              message: msg`No users have been added.`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.AddUsersAPI.PARTIAL_SUCCESS) {
          const errorMessages = result.body.failedUsers
          countErrorsByType(errorMessages)
          const usersWithErrors: WithError<NewAddUser>[] = users
            .map(user => {
              return {
                ...user,
                error:
                  user.email && errorMessages[user.email]
                    ? errorMessages[user.email]
                    : undefined
              }
            })
            .filter(user => !!user.error)

          numberSucceeded = users.length - usersWithErrors.length
          numberFailed = usersWithErrors.length

          yield put(
            globalActions.setNotification({
              message: msg`Some users haven't been added to your company.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'user',
                users: usersWithErrors
              }
            })
          )
        } else if (
          result.type === UACServices.AddUsersAPI.DELAYED_JOB_SUCCESS
        ) {
          numberSucceeded = users.length
          yield put(
            globalActions.setNotification({
              message: msg`Your request is being processed. This may take a few seconds.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (
          result.type === UACServices.AddUsersAPI.DELAYED_JOB_PARTIAL_SUCCESS
        ) {
          const errorMessages = result.body.failedUsers
          countErrorsByType(errorMessages)
          const usersWithErrors: WithError<NewAddUser>[] = users
            .map(user => {
              return {
                ...user,
                error: errorMessages[user.email || '']
              }
            })
            .filter(user => !!user.error)

          numberSucceeded = users.length - usersWithErrors.length
          numberFailed = usersWithErrors.length

          yield put(
            globalActions.setNotification({
              message: msg`Some users couldn't be added. Others may only be added after a few minutes.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'user',
                users: usersWithErrors
              }
            })
          )
        }
      } finally {
        yield put(
          userDrawerSegmentActions.userAdded(
            errorTypeDomainMismatch,
            errorTypeUserAlreadyinCompany,
            sendMfaInvite,
            sendLpInvite,
            numberFailed,
            numberSucceeded
          )
        )
        yield put(
          userListActions.getUserList({
            query: getQueryParams(currentUrlFilters.location, 'users'),
            path: {}
          })
        )
        yield put(push('/users/view'))
        yield put(addUsersDrawerActions.clearAddUsers())
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { CardContainer, useDrawerSubtitle } from '@lastpass/lastkit/components'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

import { AccountDetails } from '../AccountDetails'
import { SecurityScoreDetails } from '../SecurityScoreDetails'

const Container = styled(CardContainer)`
  padding: 16px;
`

export const AccountDetailsFull: React.FunctionComponent = () => {
  const usersDrawerState: UsersDrawerState = useSelector(
    (state: AppState) => state.usersDrawer
  )

  const companyInfoState: CompanyInfoState = useSelector(
    (state: AppState) => state.companyInfo
  )

  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getAccountDetails({ query: {}, path: pathParams })
    )
  }, [dispatch, pathParams])

  useDrawerSubtitle(<Trans>Account details</Trans>)

  return (
    <>
      <Container>
        {usersDrawerState.accountDetails && (
          <AccountDetails
            data={usersDrawerState.accountDetails}
            isFederatedLoginEnabled={
              companyInfoState.details.isFederatedLoginEnabled
            }
          />
        )}
      </Container>
      {usersDrawerState.accountDetails &&
        usersDrawerState.accountDetails.securityScoreDetails && (
          <SecurityScoreDetails
            data={usersDrawerState.accountDetails.securityScoreDetails}
          />
        )}
    </>
  )
}

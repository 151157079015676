import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { MultifactorOption } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/enabled-multifactor-options/state'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'

const success = () => createResponse(SUCCESS)
const notFound = () => createResponse(NOT_FOUND)

export const Responses = {
  success,
  notFound
}

export type Responses = ResponseTypes<typeof Responses>
export function create(fetch: AuthFetch) {
  return async function service(
    enabledMultifactorOptions: MultifactorOption[]
  ) {
    try {
      const [response, status] = await fetch(
        'v3/company/multifactor-options',
        'POST',
        {
          ['multifactorOptions']: enabledMultifactorOptions
        },
        { 'content-type': 'application/json' }
      )
      if (status === StatusCodes.OK && response) {
        return success()
      } else if (status === StatusCodes.NOT_FOUND) {
        return notFound()
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

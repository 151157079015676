import React, { FC, useContext } from 'react'

import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import {
  GeneratedSecurityReportDrawer,
  GeneratedSecurityReportDrawerProps
} from '@lastpass/admin-console-dependencies/ui/reports/security/GeneratedSecurityReportDrawer'

type UrlsInVaultsGeneratedSecurityReportDrawerProps = Pick<
  GeneratedSecurityReportDrawerProps,
  'closeLink' | 'event'
>

export const downloadReportName = 'urls-in-vaults'

export const UrlsInVaultsGeneratedSecurityReportDrawer: FC<UrlsInVaultsGeneratedSecurityReportDrawerProps> = ({
  closeLink,
  event
}) => {
  const {
    urlsInVaultsReportGetReports,
    urlsInVaultsReportGenerateReport
  } = useContext(ServerContext)

  return (
    <GeneratedSecurityReportDrawer
      closeLink={closeLink}
      event={event}
      downloadReportName={downloadReportName}
      getReports={urlsInVaultsReportGetReports}
      generateReport={urlsInVaultsReportGenerateReport}
    />
  )
}

import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { DetailsDialog, PrimaryButton, TextButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationContinueDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
interface KeyRotationContinueDialogProps {
  state: KeyRotationContinueDialog
  actions: typeof globalActions
}

export const KeyRotationContinueDialogComponent: FunctionComponent<KeyRotationContinueDialogProps> = ({
  state,
  actions
}) => {
  return (
    <DetailsDialog
      closeDialog={() => {
        actions.discardDialog()
        actions.emptyDialog()
      }}
      width="600px"
      title={state.title}
      subTitle={state.subtitle}
      disableCloseOnBackground
      buttons={[
        <TextButton
          data-qa="StartOverButton"
          onClick={() => {
            actions.confirmDialog({ continue: false })
          }}
          key="discard"
        >
          <Trans>Start over</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="ResumeProcessButton"
          onClick={() => {
            actions.confirmDialog({ continue: true })
          }}
          key="confirm"
        >
          <Trans>Resume process</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as OauthKeys from '@lastpass/admin-console-dependencies/server/applications/saml/get-oauth-keys'
import * as SamlKeys from '@lastpass/admin-console-dependencies/server/applications/saml/get-saml-keys'
import * as UsernameAttributes from '@lastpass/admin-console-dependencies/server/applications/saml/get-username-attributes'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { Role, RoleResponse } from '../../../state/applications/application'
import {
  AuthenticationPolicyMap,
  GeofencePolicyMap,
  IpPolicyMap
} from '../../../state/policies/passwordless/passwordless'
import { systemUpgradeActions } from '../../../state/upgrade/actions'
import { TeamsPolicy } from '../../../types/policy-teams'
import { mapGetPolicyListResultToPolicyMap } from '../../../ui/common/mappings/policy-response-to-policy-map-mapper'

export function createInitialData(applicationsServices: UACServices.Services) {
  return function* getInitialData() {
    try {
      yield put(systemUpgradeActions.setStepsLoading(true))
      try {
        const [
          getSamlKeysResponse,
          getUsernameAttributesResponse,
          getSSOApplicationsListResponse,
          generalPoliciesListGetResponse,
          getIdentityRolesResponse,
          getAccessPoliciesResponse,
          getIpPoliciesResponse,
          getGeofencePoliciesResponse,
          getAuthenticationPoliciesResponse,
          getOauthKeysResponse
        ] = yield all([
          call(applicationsServices.getSamlKeys),
          call(applicationsServices.getUsernameAttributes),
          call(applicationsServices.getSSOApplicationsList, '', 100, 0),
          call(applicationsServices.generalPoliciesListGet),
          call(applicationsServices.getIdentityRoles),
          call(applicationsServices.getAccessPolicies, '', 1, 0),
          call(applicationsServices.getIpPolicies),
          call(applicationsServices.getGeofencePolicies),
          call(applicationsServices.getAuthenticationPolicies, '', 1, 0),
          call(applicationsServices.getOauthKeys)
        ])
        if (
          getSamlKeysResponse &&
          getSamlKeysResponse.type === SamlKeys.SUCCESS
        ) {
          const getSamlKeysResult = getSamlKeysResponse.body.samlKeys
          yield put(systemUpgradeActions.setSamlKeys(getSamlKeysResult))
        }
        if (getUsernameAttributesResponse.type === UsernameAttributes.SUCCESS) {
          const getAttributesResult =
            getUsernameAttributesResponse.body.usernameAttributes
          yield put(
            systemUpgradeActions.setUsernameAttributes(getAttributesResult)
          )
        }
        if (
          getSSOApplicationsListResponse.type ===
          UACServices.SSOApplicationsAPI.SUCCESS
        ) {
          yield put(
            systemUpgradeActions.setApplicationList(
              getSSOApplicationsListResponse.body.applicationsResponse.results,
              getSSOApplicationsListResponse.body.applicationsResponse.total,
              100
            )
          )
        }
        if (
          generalPoliciesListGetResponse &&
          generalPoliciesListGetResponse.type ===
            UACServices.GeneralPoliciesListGetAPI.SUCCESS
        ) {
          const requirePasswordlessViaLastpassPolicy: TeamsPolicy = mapGetPolicyListResultToPolicyMap(
            generalPoliciesListGetResponse.body.generalPoliciesListResponse
          )[0]['requirepasswordlessvialastpassauth']
          yield put(
            systemUpgradeActions.setRequirePasswordlessViaLastPassPolicy(
              requirePasswordlessViaLastpassPolicy
            )
          )
        }
        if (
          getIdentityRolesResponse.type ===
          UACServices.SSOIdentityUserAPI.SUCCESS
        ) {
          const ssoIdentityRoleList: Role[] = getIdentityRolesResponse.body.roleResponse.Value.map(
            (role: RoleResponse) => {
              return {
                id: role.Id,
                name: role.Name
              }
            }
          )

          yield put(systemUpgradeActions.setRoleList(ssoIdentityRoleList))
        }

        if (
          getAccessPoliciesResponse &&
          getAccessPoliciesResponse.type ===
            UACServices.AccessPoliciesGetApi.SUCCESS
        ) {
          yield put(
            systemUpgradeActions.setAccessPolicies(
              getAccessPoliciesResponse.body.response
            )
          )
        }

        if (
          getIpPoliciesResponse &&
          getIpPoliciesResponse.type === UACServices.IpPoliciesGetApi.SUCCESS
        ) {
          const ipPolicies: IpPolicyMap = {
            results: getIpPoliciesResponse.body.response,
            total: getIpPoliciesResponse.body.response.length
          }
          yield put(systemUpgradeActions.setIpPolicies(ipPolicies))
        }

        if (
          getGeofencePoliciesResponse &&
          getGeofencePoliciesResponse.type ===
            UACServices.GeofencePoliciesGetApi.SUCCESS
        ) {
          const geofencePolicies: GeofencePolicyMap = {
            results: getGeofencePoliciesResponse.body.response,
            total: getGeofencePoliciesResponse.body.response.length
          }
          yield put(systemUpgradeActions.setGeofencePolicies(geofencePolicies))
        }

        if (
          getAuthenticationPoliciesResponse &&
          getAuthenticationPoliciesResponse.type ===
            UACServices.AuthenticationApi.SUCCESS
        ) {
          const authenticationPolicies: AuthenticationPolicyMap = {
            results: getAuthenticationPoliciesResponse.body.response.results,
            total: getAuthenticationPoliciesResponse.body.response.total
          }
          yield put(
            systemUpgradeActions.setAuthenticationPolicies(
              authenticationPolicies
            )
          )
        }

        if (
          getOauthKeysResponse &&
          getOauthKeysResponse.type === OauthKeys.SUCCESS
        ) {
          const getOauthKeysResult = getOauthKeysResponse.body.oauthKeys
          yield put(systemUpgradeActions.setOauthKeys(getOauthKeysResult))
        }
      } finally {
        yield put(systemUpgradeActions.setStepsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(systemUpgradeActions.setStepsLoading(false))
    }
  }
}

import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { BodyBold, DetailsDialog, PrimaryButton } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationInconsistentConnectionErrorDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
`
const FooterBoxContainer = styled.div`
  ${BodyRegularStyle};
  min-height: 50px;
`

const StyledBodyText = styled.div`
  ${BodyRegularStyle};
`

interface KeyRotationInconsistentConnectionErrorDialogProps {
  state: KeyRotationInconsistentConnectionErrorDialog
  actions: typeof globalActions
}

export const KeyRotationInconsistentConnectionErrorDialogComponent: FunctionComponent<KeyRotationInconsistentConnectionErrorDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={msg`Rotation process incomplete – Action required`}
      subTitle={msg`It looks like you lost your internet connection during the rotation process.`}
      disableCloseOnBackground
      content={
        <TextBoxContainer>
          <StyledBodyText>
            <Trans>
              <BodyBold>Important:</BodyBold> Some users had their K1 keys
              rotated and will be <BodyBold>unable to connect</BodyBold> to
              LastPass until the process is complete and the key components are
              in a consistent state.
            </Trans>
          </StyledBodyText>
        </TextBoxContainer>
      }
      footer={
        <FooterBoxContainer>
          <StyledBodyText>
            <Trans>
              Restart the process as soon possible from any device. If you use
              this same device, the process continues where it left off.
            </Trans>
          </StyledBodyText>
          <StyledBodyText>{i18n._(state.errorCodeText)}</StyledBodyText>
        </FooterBoxContainer>
      }
      buttons={[
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => dispatch(globalActions.confirmDialog())}
          key="confirm"
        >
          <Trans>Got it</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { CompanySettings } from '@lastpass/admin-console-dependencies/types/company-settings'

import { CompanyDetails, MspBillingDetails } from './state'

export enum CompanyInfoActionTypes {
  SET_COMPANY_DETAILS = '[company-info] set-details',

  GET_COMPANY_LICENSES = '[company-info] get-company-licenses',
  SET_COMPANY_LICENSES = '[company-info] set-company-licenses',

  SET_MSP_BILLING_DETAILS = '[company-info] set-msp-billing-details',

  GET_COMPANY_SETTINGS = '[company-settings] get-company-settings',
  SET_COMPANY_SETTINGS = '[company-settings] set-company-settings',

  SET_CID_OVERRIDE = '[cid-override] set-cid-override',
  REMOVE_CID_OVERRIDE = '[cid-override] remove-cid-override'
}

export const companyInfoActions = {
  setCompanyDetails: (details: CompanyDetails) =>
    createAction(CompanyInfoActionTypes.SET_COMPANY_DETAILS, details),

  getCompanyLicenses: () =>
    createAction(CompanyInfoActionTypes.GET_COMPANY_LICENSES),
  setCompanyLicenses: (remainingLicenses: number, totalLicenses: number) =>
    createAction(CompanyInfoActionTypes.SET_COMPANY_LICENSES, {
      remainingLicenses,
      totalLicenses
    }),
  setMspBillingDetails: (mspBillingDetails: MspBillingDetails) =>
    createAction(
      CompanyInfoActionTypes.SET_MSP_BILLING_DETAILS,
      mspBillingDetails
    ),

  getCompanySettings: () =>
    createAction(CompanyInfoActionTypes.GET_COMPANY_SETTINGS),
  setCompanySettings: (settings: Partial<CompanySettings>) =>
    createAction(CompanyInfoActionTypes.SET_COMPANY_SETTINGS, { settings }),
  setCidOverride: (cid: string) =>
    createAction(CompanyInfoActionTypes.SET_CID_OVERRIDE, { cid }),
  removeCidOverride: (redirectUrl?: string) =>
    createAction(CompanyInfoActionTypes.REMOVE_CID_OVERRIDE, { redirectUrl })
}

export type CompanyInfoActions = ActionsUnion<typeof companyInfoActions>

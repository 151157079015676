import { takeEvery } from 'redux-saga/effects'

import { createAddNewURLRule } from '@lastpass/admin-console-dependencies/sagas/advanced/enterprise-options/urlrules/add-new-url-rule'
import { createEditURLRuleOptions } from '@lastpass/admin-console-dependencies/sagas/advanced/enterprise-options/urlrules/edit-url-rule-options'
import { createGetURLRuleOptions } from '@lastpass/admin-console-dependencies/sagas/advanced/enterprise-options/urlrules/get-url-rule-options'
import { createGetUrlRules } from '@lastpass/admin-console-dependencies/sagas/advanced/enterprise-options/urlrules/get-url-rules'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { UrlRulesActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'

import { createDeleteUrlRulesSaga } from './delete-url-rules'

export function createUrlRulesSaga(userService: UACServices.Services) {
  const getUrlRulesSaga = createGetUrlRules(userService)
  const addNewURLRuleSaga = createAddNewURLRule(userService)
  const editURLRuleOptionsSaga = createEditURLRuleOptions(userService)
  const getURLRuleOptionsSaga = createGetURLRuleOptions(userService)
  const deleteUrlRulesSaga = createDeleteUrlRulesSaga(userService)

  return function*() {
    yield takeEvery(UrlRulesActionTypes.URL_RULES_GET, getUrlRulesSaga)
    yield takeEvery(
      UrlRulesActionTypes.URL_RULES_GET_OPTIONS,
      getURLRuleOptionsSaga
    )
    yield takeEvery(UrlRulesActionTypes.URL_RULE_ADD, addNewURLRuleSaga)
    yield takeEvery(
      UrlRulesActionTypes.URL_RULES_EDIT_OPTIONS,
      editURLRuleOptionsSaga
    )
    yield takeEvery(UrlRulesActionTypes.DELETE_URL_RULES, deleteUrlRulesSaga)
  }
}

import { call, put } from 'redux-saga/effects'

import { EnterpriseApiServiceType } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { enterpriseApiActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetEnterpriseApi(
  enterpriseApiServices: EnterpriseApiServiceType
) {
  return function* getEnterpriseApi() {
    try {
      yield put(enterpriseApiActions.setInProgress(true))
      try {
        const provisioningHash = yield call(
          enterpriseApiServices.getProvisioningHash
        )
        const emailVerified = yield call(enterpriseApiServices.emailVerified)
        const whitelistedIps = yield call(enterpriseApiServices.whitelistedIps)
        const apiExamples = yield call(enterpriseApiServices.apiExamples)
        yield put(
          enterpriseApiActions.setEnterpriseApi(
            provisioningHash,
            emailVerified,
            whitelistedIps,
            apiExamples
          )
        )
      } finally {
        yield put(enterpriseApiActions.setInProgress(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

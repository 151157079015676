import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as SSOIcon } from '@lastpass/assets/svg/admin-console/icon-ssoapp.svg'
import {
  CardTable,
  Collapsible,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { ImageWithFallback } from '@lastpass/lastkit/components/ImageWithFallback'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDetailsActions } from '@lastpass/admin-console-dependencies/state/users/details/actions'
import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { SSOApp } from '@lastpass/admin-console-dependencies/types/sso-app'

export type UserAppsCardProps = LastPassComponentProps<
  UsersDrawerState['apps'],
  typeof userDrawerActions & typeof userDetailsActions,
  { detailLink: string; appLink: string; expand: boolean; onExpand: Function }
>

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const UserAppsSummaryCardComponent: React.FunctionComponent<UserAppsCardProps> = ({
  state: apps,
  props,
  actions
}) => {
  const pathParams: UserDrawerUrlParams = usePathParams()

  useEffect(() => {
    if (props.expand) {
      actions.getUserApps({ path: pathParams, query: {} }, 5)
    }
  }, [actions, pathParams, props.expand])
  return (
    <Collapsible
      title={<Trans>SSO Apps</Trans>}
      expanded={props.expand}
      onInitialExpand={() => {
        actions.getUserApps({ path: pathParams, query: {} }, 5)
      }}
      loading={apps.isLoading}
      onExpand={props.onExpand}
      qadata={'SSOAppsCard'}
    >
      {apps && (
        <CardTable
          qadata={'UserLevelAppsList'}
          table={apps}
          columns={[
            {
              name: <></>,
              contentWidth: 'auto',
              noTooltip: true,
              renderer: function AppImageRenderer(record: SSOApp) {
                return (
                  <ImageWithFallback
                    height={16}
                    width={16}
                    src={record.logoUrl}
                    alt={record.name + 'logo'}
                  />
                )
              }
            },
            {
              name: <Trans>Application</Trans>,
              renderer: (record: SSOApp) => record.name
            }
          ]}
          footerNodes={
            <>
              <TextButton blue onClick={actions.goToAppsPage}>
                <StyledLink
                  to={props.appLink}
                  data-qa={'GoToApplicationsPageButton'}
                >
                  <Trans>Go to Applications page</Trans>
                </StyledLink>
              </TextButton>
              <TextButton blue>
                <StyledLocationLink
                  to={props.detailLink}
                  data-qa={'ViewAllAppsButton'}
                  discardnamespace={Namespace.apps}
                >
                  <Trans>View all SSO apps: {apps && apps.totalResults}</Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<SSOIcon />}
              title={<Trans>This user doesn’t have any SSO Apps.</Trans>}
              text={<Trans>Assign apps on the Apps page</Trans>}
              actionButton={
                <TableViewButton onClick={actions.goToAppsPage}>
                  <StyledLink
                    to={props.appLink}
                    data-qa={'GoToApplicationsPageButton'}
                  >
                    <Trans>Go to Apps</Trans>
                  </StyledLink>
                </TableViewButton>
              }
            />
          }
        />
      )}
    </Collapsible>
  )
}

export function createUserAppsSummaryCard<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserAppsSummaryCardComponent,
    (state: TState) => state.usersDrawer.apps,
    { ...userDrawerActions, ...userDetailsActions }
  )
}

export type UserAppsSummaryCard = ReturnType<typeof createUserAppsSummaryCard>

import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton,
  TabNavigation,
  TabNavigationItem,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import {
  GoogleGroup,
  GoogleUser
} from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
import {
  GoogleIntegrationSyncOptionsDialog,
  GoogleIntegrationSyncOptionsDialogConfirmType
} from '@lastpass/admin-console-dependencies/types/dialog-types'

import { GoogleGroupList } from './GoogleGroupList'
import { GoogleUserList } from './GoogleUserList'
import { SearchBasedGoogleGroupList } from './SearchBasedGoogleGroupList'
import { SyncSummary } from './SyncSummary'
import { SyncTypeSelection } from './SyncTypeSelection'
import {
  buildGoogleGroupTree,
  getSelectedGroups,
  GoogleGroupBranch
} from './utilities'

interface GoogleIntegrationSyncOptionsDialogProps {
  state: GoogleIntegrationSyncOptionsDialog
  actions: typeof globalActions
}

enum PartialSyncTypes {
  Group = 'group',
  User = 'user'
}

enum ViewModes {
  Selection = 'selection',
  Summary = 'summary'
}

const Body = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 24px;
`

const HiddenContainer = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`

const StyledTabNavigationItem = styled(TabNavigationItem)`
  cursor: pointer;
`
const SyncSelectionContainer = styled.div`
  margin-top: 5px;
`

const SyncTypeSelectionText = (
  <Trans>
    Choose how you want to sync users and groups from your Google Workspace.
  </Trans>
)

const PartialSyncText = (
  <Trans>
    The following users and groups will be synced from your Google Workspace to
    your LastPass instance.
  </Trans>
)

export const GoogleIntegrationSyncOptionsDialogComponent: FC<GoogleIntegrationSyncOptionsDialogProps> = ({
  state,
  actions
}) => {
  const dispatch = useDispatch()

  const {
    groups,
    isLoading: isLoadingGroups,
    searchBasedGroupSelection,
    nextPageToken: googleGroupsNextPageToken
  } = useSelector((state: AppState) => state.integrations.GoogleGroups)

  const { users, isLoading: isLoadingUsers, nextPageToken } = useSelector(
    (state: AppState) => state.integrations.GoogleUsers
  )
  const { userIdsToSync, groupIdsToSync } = useSelector(
    (state: AppState) => state.integrations.Google
  )

  const [activeTab, setActiveTab] = useState(PartialSyncTypes.User)
  const [viewMode, setViewMode] = useState(ViewModes.Selection)
  const [isSpecifiedSelected, setIsSpecifiedSelected] = useState<boolean>(
    state.partialSyncEnabled
  )

  const [selectedUsers, setSelectedUsers] = useState<string[]>(userIdsToSync)
  const [
    searchBasedSelectedGroupIds,
    setSearchBasedSelectedGroupIds
  ] = useState<string[]>(groupIdsToSync)
  const [groupTree, setGroupTree] = useState<GoogleGroupBranch[]>(
    buildGoogleGroupTree(groups)
  )

  const selectedGroups = useMemo(() => getSelectedGroups(groupTree), [
    groupTree
  ])

  const selectedGroupsCache = useRef<{ [key: string]: GoogleGroup }>({})
  const selectedUsersCache = useRef<{ [key: string]: GoogleUser }>({})

  const usersWithSelectionState = users.map(user => ({
    ...user,
    selected: selectedUsers.includes(user.id)
  }))
  const groupsWithSelectionState = groups.map(group => ({
    ...group,
    selected: searchBasedSelectedGroupIds.includes(group.id)
  }))

  const userIds = users.map(user => user.id)
  const groupIds = groups.map(group => group.id)
  const selectedUsersCacheArray = Object.keys(selectedUsersCache.current)
    .filter(id => !userIds.includes(id))
    .map(id => selectedUsersCache.current[id])
  const selectedGroupsCacheArray = Object.keys(selectedGroupsCache.current)
    .filter(id => !groupIds.includes(id))
    .map(id => selectedGroupsCache.current[id])

  const usersAdded = [...users, ...selectedUsersCacheArray].filter(
    user => selectedUsers.includes(user.id) && !userIdsToSync.includes(user.id)
  )
  const usersRemoved = [...users, ...selectedUsersCacheArray].filter(
    user => !selectedUsers.includes(user.id) && userIdsToSync.includes(user.id)
  )
  const groupsAdded = [...groups, ...selectedGroupsCacheArray].filter(
    group =>
      (searchBasedGroupSelection
        ? searchBasedSelectedGroupIds
        : selectedGroups.map(g => g.id)
      ).includes(group.id) && !groupIdsToSync.includes(group.id)
  )
  const groupsRemoved = [...groups, ...selectedGroupsCacheArray].filter(
    group =>
      !(searchBasedGroupSelection
        ? searchBasedSelectedGroupIds
        : selectedGroups.map(g => g.id)
      ).includes(group.id) && groupIdsToSync.includes(group.id)
  )

  const handleGroupSelection = (id: string, selected: boolean) => {
    const recursiveSelection = (
      branch: GoogleGroupBranch[],
      applyOnAllChildren: boolean
    ) => {
      return branch.map(group => ({
        ...group,
        selected:
          group.id === id || applyOnAllChildren ? selected : group.selected,
        children:
          group.children.length > 0
            ? recursiveSelection(
                group.children,
                group.id === id || applyOnAllChildren
              )
            : []
      }))
    }

    setGroupTree(prevGroupTree => recursiveSelection(prevGroupTree, false))
  }

  const cleanup = () => {
    dispatch(directoriesActions.setGoogleUsers([], true, null))
    dispatch(directoriesActions.setGoogleGroups([]))
  }

  const handleSubmit = (
    enabled: GoogleIntegrationSyncOptionsDialogConfirmType
  ) => {
    actions.confirmDialog({
      enabled,
      groups: {
        added: groupsAdded,
        removed: groupsRemoved
      },
      users: {
        added: usersAdded,
        removed: usersRemoved
      }
    })
    actions.emptyDialog()

    cleanup()
  }

  const discardDialog = () => {
    actions.discardDialog()
    actions.emptyDialog()

    cleanup()
  }

  const handleUserSelection = (id: string, selected: boolean) => {
    const user = users.find(user => user.id === id)

    if (user) {
      selectedUsersCache.current = {
        ...selectedUsersCache.current,
        [id]: user
      }
    }

    if (selected) {
      setSelectedUsers(prevSelectedUsers => [...prevSelectedUsers, id])
    } else {
      setSelectedUsers(prevSelectedUsers =>
        prevSelectedUsers.filter(user => user !== id)
      )
    }
  }

  const handleSearchBasedGroupSelection = (id: string, selected: boolean) => {
    const group = groups.find(group => group.id === id)

    if (group) {
      selectedGroupsCache.current = {
        ...selectedGroupsCache.current,
        [id]: group
      }
    }

    if (selected) {
      setSearchBasedSelectedGroupIds(prevSelectedGroups => [
        ...prevSelectedGroups,
        id
      ])
    } else {
      setSearchBasedSelectedGroupIds(prevSelectedGroups =>
        prevSelectedGroups.filter(group => group !== id)
      )
    }
  }

  useEffect(() => {
    setGroupTree(buildGoogleGroupTree(groups))
  }, [groups])

  const hasChanges =
    usersAdded.length +
      usersRemoved.length +
      groupsAdded.length +
      groupsRemoved.length >
    0

  const removedAllGroups =
    groupIdsToSync.length > 0 &&
    groupIdsToSync.length - groupsRemoved.length === 0
  const removedAllUsers =
    userIdsToSync.length > 0 && userIdsToSync.length - usersRemoved.length === 0

  const isEmpty =
    usersAdded.length === 0 &&
    groupsAdded.length === 0 &&
    (removedAllGroups || groupIdsToSync.length === 0) &&
    (removedAllUsers || userIdsToSync.length === 0)

  const saveDisabled =
    isSpecifiedSelected &&
    !(
      isSpecifiedSelected &&
      hasChanges &&
      !isLoadingGroups &&
      !isLoadingUsers &&
      !isEmpty
    )

  const cancelButton = (
    <TextButton
      onClick={discardDialog}
      key="discard"
      data-qa="GoogleIntegrationSyncOptionsCancelButton"
    >
      <Trans>Cancel</Trans>
    </TextButton>
  )

  const nextButton = (
    <PrimaryButton
      key="Next"
      onClick={() => setViewMode(ViewModes.Summary)}
      data-qa="GoogleIntegrationSyncOptionsNextButton"
    >
      <Trans>Next</Trans>
    </PrimaryButton>
  )

  const backButton = (
    <TextButton key="Back" onClick={() => setViewMode(ViewModes.Selection)}>
      <Trans>Back</Trans>
    </TextButton>
  )

  const saveAndExitButton = (
    <PrimaryButton
      onClick={() => {
        const enabled = isSpecifiedSelected
          ? GoogleIntegrationSyncOptionsDialogConfirmType.partialExit
          : GoogleIntegrationSyncOptionsDialogConfirmType.allExit
        handleSubmit(enabled)
      }}
      key="saveandexit"
      disabled={saveDisabled}
      data-qa="GoogleIntegrationSyncOptionsSaveAndExitButton"
    >
      {state.initialSetup ? (
        <Trans>Save & exit</Trans>
      ) : (
        <Trans>Save changes</Trans>
      )}
    </PrimaryButton>
  )

  const saveAndEnableButton = (
    <>
      {state.initialSetup && (
        <PrimaryButton
          onClick={() => {
            const enabled = isSpecifiedSelected
              ? GoogleIntegrationSyncOptionsDialogConfirmType.partialEnable
              : GoogleIntegrationSyncOptionsDialogConfirmType.allEnable
            handleSubmit(enabled)
          }}
          key="saveandenable"
          disabled={saveDisabled}
          data-qa="GoogleIntegrationSyncOptionsSaveAndEnableButton"
        >
          <Trans>Save & enable</Trans>
        </PrimaryButton>
      )}
    </>
  )

  const viewModeDependentButtons =
    viewMode === ViewModes.Selection
      ? [nextButton]
      : [backButton, saveAndExitButton, saveAndEnableButton]

  const buttons = [
    cancelButton,
    ...(isSpecifiedSelected
      ? viewModeDependentButtons
      : [saveAndExitButton, saveAndEnableButton])
  ]

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={discardDialog}
      width="600px"
      title={msg`Sync options`}
      content={
        <Body>
          {viewMode === ViewModes.Selection
            ? SyncTypeSelectionText
            : PartialSyncText}

          {viewMode === ViewModes.Selection && (
            <SyncTypeSelection
              isSpecifiedSelected={isSpecifiedSelected}
              setIsSpecifiedSelected={setIsSpecifiedSelected}
            />
          )}

          {isSpecifiedSelected && (
            <>
              <HiddenContainer hidden={viewMode !== ViewModes.Selection}>
                <TabNavigation>
                  <StyledTabNavigationItem
                    active={activeTab === PartialSyncTypes.User}
                    onClick={() => setActiveTab(PartialSyncTypes.User)}
                    data-qa="GoogleIntegrationSyncOptionsUsersTab"
                  >
                    <Trans>Users</Trans>
                    {selectedUsers.length ? ` (${selectedUsers.length})` : ''}
                  </StyledTabNavigationItem>
                  <StyledTabNavigationItem
                    active={activeTab === PartialSyncTypes.Group}
                    onClick={() => setActiveTab(PartialSyncTypes.Group)}
                    data-qa="GoogleIntegrationSyncOptionsGroupsTab"
                  >
                    <Trans>Groups</Trans>
                    {(searchBasedGroupSelection
                      ? searchBasedSelectedGroupIds
                      : selectedGroups
                    ).length
                      ? ` (${
                          (searchBasedGroupSelection
                            ? searchBasedSelectedGroupIds
                            : selectedGroups
                          ).length
                        })`
                      : ''}
                  </StyledTabNavigationItem>
                </TabNavigation>
                <SyncSelectionContainer>
                  <HiddenContainer
                    hidden={activeTab !== PartialSyncTypes.User}
                    data-qa="GoogleIntegrationSyncOptionsUserListContainer"
                  >
                    <GoogleUserList
                      isLoading={isLoadingUsers}
                      users={usersWithSelectionState}
                      handleUserSelection={handleUserSelection}
                      nextPageToken={nextPageToken}
                    />
                  </HiddenContainer>
                  <HiddenContainer
                    hidden={activeTab !== PartialSyncTypes.Group}
                    data-qa="GoogleIntegrationSyncOptionsGroupListContainer"
                  >
                    {searchBasedGroupSelection ? (
                      <SearchBasedGoogleGroupList
                        groups={groupsWithSelectionState}
                        isLoading={isLoadingGroups}
                        nextPageToken={googleGroupsNextPageToken}
                        handleGroupSelection={handleSearchBasedGroupSelection}
                      />
                    ) : (
                      <GoogleGroupList
                        groups={groupTree}
                        handleGroupSelection={handleGroupSelection}
                        isLoading={isLoadingGroups}
                      />
                    )}
                  </HiddenContainer>
                </SyncSelectionContainer>
              </HiddenContainer>
              <HiddenContainer hidden={viewMode !== ViewModes.Summary}>
                <SyncSummary
                  addedUsers={usersAdded}
                  removedUsers={usersRemoved}
                  addedGroups={groupsAdded}
                  removedGroups={groupsRemoved}
                />
              </HiddenContainer>
            </>
          )}
        </Body>
      }
      buttons={buttons}
    />
  )
}

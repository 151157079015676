type AccordionFocusedStates<T extends string> = { [key in T]: boolean }

const defaultInitialValue = true

export const accordionFocusLinker = <T extends string>(
  paramName: string,
  accordionIds: T[]
) => {
  const isAnyAccordionFocused = (): boolean =>
    Boolean(new URLSearchParams(location.search).get(paramName))

  const isAccordionFocused = (accordionName: T): boolean =>
    new URLSearchParams(location.search).get(paramName) === accordionName

  const createAccordionFocusedLink = (path: string, accordionName: T): string =>
    `${path}?${new URLSearchParams({
      [paramName]: accordionName as string
    }).toString()}`

  const getAccordionFocusedStates = (): AccordionFocusedStates<T> => {
    const states: AccordionFocusedStates<T> = {} as AccordionFocusedStates<T>
    for (const key of accordionIds) {
      states[key] = !isAnyAccordionFocused()
        ? defaultInitialValue
        : isAccordionFocused(key)
    }
    return states
  }

  return {
    getAccordionFocusedStates,
    createAccordionFocusedLink
  }
}

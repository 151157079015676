import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { GroupUserActionsType } from '@lastpass/admin-console-dependencies/state/users/groups/operations/group-operations'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createDisableUsersGroupSaga(uacServices: UACServices.Services) {
  return function* disableUsersGroupSaga(
    action: ReturnType<typeof groupOperationActions.disableUsers>
  ) {
    try {
      const groupId = action.payload
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Disable users`,
        text: msg`All Enabled users in the group will be set to Disabled.`,
        discardText: msg`Cancel`,
        confirmText: msg`Disable users`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const result: UACServices.GroupUserOperationsAPI.Responses = yield call(
          uacServices.groupUserOperations,
          GroupUserActionsType.disableUsers,
          groupId
        )
        if (result.type === UACServices.GroupUserOperationsAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: msg`The selected users have been disabled. Bulk actions may take up to 15 minutes to complete.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.GroupUserOperationsAPI.FAIL) {
          yield put(
            globalActions.setNotification({
              message: msg`None of the selected users have been disabled.`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createAdvancedUserActivityDeleteReport(
  uacServices: UACServices.Services
) {
  return function* deleteAdvancedUserActivityReport(
    action: ReturnType<
      typeof advancedUserActivityActions.deleteSelectedSavedReport
    >
  ) {
    try {
      const text: MessageDescriptor = msg`This exact instance of this report will no longer be available, but you can generate the same data by applying the same filters again later.`
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Delete report?`,
        text,
        discardText: msg`Cancel`,
        confirmText: msg`Delete`
      }
      yield put(globalActions.setDialog(dialog))

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const response: UACServices.AdvancedUserActivityDeleteReportAPI.Responses = yield call(
          uacServices.advancedUserActivityDeleteReport,
          action.payload.selectedSavedReport.name
        )

        if (
          response.type ===
          UACServices.AdvancedUserActivityDeleteReportAPI.SUCCESS
        ) {
          yield put(advancedUserActivityActions.removeSelectedSavedReport())

          const updatedSavedReports = action.payload.savedReports.filter(
            report => report !== action.payload.selectedSavedReport
          )
          yield put(
            advancedUserActivityActions.setSavedReports(updatedSavedReports)
          )

          yield put(push(window.location.pathname))

          yield put(
            globalActions.setNotification({
              message: msg`Report deleted.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        }
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

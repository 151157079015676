import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'
export const PARTIAL_SUCCESS = 'partialSuccess'
export const FAIL = 'fail'

const success = () => createResponse(SUCCESS)
const partialSuccess = (failedUsers: { [key in User['id']]: string }[]) =>
  createResponse(PARTIAL_SUCCESS, { failedUsers })
const fail = () => createResponse(FAIL)

export const Responses = {
  success,
  partialSuccess,
  fail
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    selectedUsers: User[],
    group: Group,
    fetchMethod: string
  ) {
    const [response, status] = await fetch(
      'bulk/groups/' + group.id + '/users',
      fetchMethod,
      selectedUsers.map(user => user.id),
      { 'content-type': 'application/json' }
    )

    const responseJson =
      status === StatusCodes.OK && response && JSON.parse(response)
    if (responseJson && responseJson.content.status === 'success') {
      return success()
    } else if (
      responseJson &&
      responseJson.content.status === 'partialSuccess'
    ) {
      return partialSuccess(responseJson.content.failureReasons)
    } else if (responseJson && responseJson.content.status === 'failure') {
      return fail()
    } else throw new UnknownServiceError()
  }
}

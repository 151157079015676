import React from 'react'

import styled from '@emotion/styled'

import { ThemeColor } from '../theme'

interface TopBannerProps {
  backgroundColor: ThemeColor
}

const Container = styled.div<{ background: ThemeColor }>`
  display: flex;
  background: ${props => props.theme.colors[props.background]};
  justify-content: center;
`

export const TopBanner: React.FunctionComponent<TopBannerProps> = props => {
  const realignDrawer = () => {
    const drawerElement = document.getElementById('Drawer')
    if (drawerElement) {
      drawerElement.style.position = 'absolute'
    }
  }

  return (
    <Container ref={realignDrawer} background={props.backgroundColor}>
      {props.children}
    </Container>
  )
}

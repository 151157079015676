import React, { FC, useState } from 'react'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'

import { Loading } from '@lastpass/components'

import { RecursiveGroup } from './RecursiveGroup'
import {
  PartialSyncItemListContainer,
  StyledSearchInput
} from './StyledComponents'
import { filterGoogleGroupTree, GoogleGroupBranch } from './utilities'

const RecursiveGroupContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
  padding-left: 5px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
`

export const GoogleGroupList: FC<{
  groups: GoogleGroupBranch[]
  handleGroupSelection: (id: string, selected: boolean) => void
  isLoading: boolean
}> = ({ groups, handleGroupSelection, isLoading }) => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const filterCondition = (group: GoogleGroupBranch): boolean =>
    group.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0

  const filteredGroups = filterGoogleGroupTree(groups, filterCondition)

  const Loader = <Loading color="blue900" active={true} />

  return (
    <>
      <StyledSearchInput
        value={searchQuery}
        data-qa="GoogleIntegrationSyncOptionsSyncSpecifiedUsersSearchBar"
        onChange={e => {
          setSearchQuery(e.target.value)
        }}
        placeholder={msg`Search groups...`}
        flexGrow={true}
        disabled={isLoading}
      />
      <PartialSyncItemListContainer>
        {isLoading ? (
          Loader
        ) : (
          <>
            {filteredGroups.map(groupBranch => (
              <RecursiveGroupContainer key={groupBranch.id}>
                <RecursiveGroup
                  group={groupBranch}
                  handleGroupSelection={handleGroupSelection}
                  hasMarginLeft={false}
                  recursiveExpand={searchQuery !== ''}
                />
              </RecursiveGroupContainer>
            ))}
          </>
        )}
      </PartialSyncItemListContainer>
    </>
  )
}

import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field } from 'formik'
import { FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as DownloadIcon } from '@lastpass/assets/svg/admin-console/icon-download.svg'
import { BodyRegular, Select, TextInput } from '@lastpass/lastkit'

import { getIdentityIFrameUrl } from '@lastpass/admin-console-dependencies/services/identity-iframe-utils'
import { isValidUrl } from '@lastpass/admin-console-dependencies/services/is-valid-url'
import {
  SamlAttribute,
  SamlKey
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'

export interface Values {
  ACS: string
  SAMLSignatureMethod: string
  certificateId: string | null
  encryptAssertion: boolean
  entityId: string | null
  identifier: string
  identityProvider: string
  nickName: string
  relayState: string | null
  role: string | null
  signAssertion: boolean
  signRequest: boolean
  signResponse: boolean
  stepUp: boolean
  samlAttributes: SamlAttribute[]
  closeLink: string
  closeAfterSave: boolean
}

export interface SetupAppProps {
  formikProps: FormikProps<Values>
}

const BodyText = styled.div`
  margin-top: 24px;
`

const FormContainer = styled.div`
  margin-top: 20px;
`

const StyledControl = styled.div`
  margin-top: 12px;
`

const DownloadSection = styled.div`
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
`

const DownloadLinkSection = styled.span`
  margin-left: 15px;
`

const StyledDownloadIcon = styled(DownloadIcon)`
  margin-right: 6px;
`

export const SetupApp: FunctionComponent<SetupAppProps> = ({ formikProps }) => {
  const cidOverride = useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )
  const appDrawerState = useSelector<AppState, ApplicationDrawerState>(
    state => state.applicationDrawer
  )
  const dispatch = useDispatch()

  const getUrl = (urlString: string) => {
    const url = isValidUrl(urlString)
      ? urlString
      : defaultConfig.identityIframeUrl
    return getIdentityIFrameUrl(url, cidOverride)
  }

  const identityProvider = formikProps.values.identityProvider

  const ssoEndpoint = `${identityProvider}/SAML/SSOService`
  const logoutUrl = `${identityProvider}/Login/Logout`

  const certificateId: string = formikProps.values.certificateId || ''

  const certificateUrl = getUrl(
    `${identityProvider}/api/v2/DownloadKey/cer/${certificateId}`
  )
  const certificateDerUrl = getUrl(
    `${identityProvider}/api/v2/DownloadKey/der/${certificateId}`
  )
  const metadataUrl = getUrl(
    `${identityProvider}/api/v2/DownloadKey/meta/${certificateId}`
  )

  const samlKeys = appDrawerState.samlKeys || []

  const samlPropsToSelectModel = v => {
    return { value: v.id, label: v.name }
  }

  const defaultSamlKey: SamlKey = {
    fingerprint: '',
    fingerprint256: '',
    id: '',
    name: '',
    certificate: ''
  }
  let selectedSamlKey: SamlKey = defaultSamlKey
  if (samlKeys.length === 1) {
    selectedSamlKey = samlKeys[0]
  }
  if (samlKeys.length > 1) {
    selectedSamlKey =
      samlKeys.find(item => item.id === certificateId) || defaultSamlKey
  }

  const dispatchCopyToClipboardNotification = () => {
    dispatch(globalActions.setNotification(CopyToClipboardNotification))
  }

  return (
    <>
      <BodyRegular>
        <BodyText>
          <Trans>
            Go to this app’s settings to enable single sign-on. Make sure it
            recognizes LastPass as the Identity Provider. Some apps allow you to
            upload settings in an XML file, while others require you to copy &
            paste the information below.
          </Trans>
        </BodyText>
        <FormContainer>
          <StyledControl>
            <TextInput
              value={identityProvider}
              data-qa={'entityId'}
              name={`entityId`}
              actionOnCopy={dispatchCopyToClipboardNotification}
              disabled
              copyToClipboard
            >
              <Trans>Entity ID</Trans>
            </TextInput>
          </StyledControl>
          <StyledControl>
            <TextInput
              value={ssoEndpoint}
              data-qa={'ssoEndpoint'}
              name={`ssoEndpoint`}
              actionOnCopy={dispatchCopyToClipboardNotification}
              disabled
              copyToClipboard
            >
              <Trans>SSO endpoint</Trans>
            </TextInput>
          </StyledControl>

          <StyledControl>
            <TextInput
              value={logoutUrl}
              data-qa={'logoutUrl'}
              name={`logoutUrl`}
              actionOnCopy={dispatchCopyToClipboardNotification}
              disabled
              copyToClipboard
            >
              <Trans>Logout URL</Trans>
            </TextInput>
          </StyledControl>

          {appDrawerState.samlKeys.length > 1 && (
            <Field
              data-qa="certificateId"
              name="certificateId"
              enableReinitialize={true}
            >
              {formData => (
                <StyledControl>
                  <Select
                    fluid
                    options={appDrawerState.samlKeys.map(
                      samlPropsToSelectModel
                    )}
                    value={{
                      value: selectedSamlKey ? selectedSamlKey.id : '',
                      label: selectedSamlKey ? selectedSamlKey.name : ''
                    }}
                    onChange={option => {
                      formikProps.setFieldValue(
                        formData.field.name,
                        option ? option['value'] : null
                      )
                    }}
                    dataQa="CertificateIdDropdown"
                  >
                    <Trans>Certificate</Trans>
                  </Select>
                </StyledControl>
              )}
            </Field>
          )}

          <StyledControl>
            <TextInput
              value={selectedSamlKey.fingerprint}
              data-qa={'certificateFingerprint'}
              name={`certificateFingerprint`}
              actionOnCopy={dispatchCopyToClipboardNotification}
              disabled
              copyToClipboard
            >
              <Trans>Certificate fingerprint</Trans>
            </TextInput>
          </StyledControl>

          <StyledControl>
            <TextInput
              value={selectedSamlKey.fingerprint256}
              data-qa={'certificateFingerprintSha256'}
              name={`certificateFingerprintSha256`}
              actionOnCopy={dispatchCopyToClipboardNotification}
              disabled
              copyToClipboard
            >
              <Trans>Certificate fingerprint (SHA256)</Trans>
            </TextInput>
          </StyledControl>
          <StyledControl>
            <TextInput
              value={selectedSamlKey.certificate}
              data-qa={'certificate'}
              name={`certificate`}
              actionOnCopy={dispatchCopyToClipboardNotification}
              disabled
              copyToClipboard
              copyFormat="text/plain"
            >
              <Trans>Certificate (PEM)</Trans>
            </TextInput>
          </StyledControl>
        </FormContainer>
      </BodyRegular>

      <DownloadSection>
        <Trans>Download certificate</Trans>
        <DownloadLinkSection>
          <a
            href={certificateUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-qa={'DownloadCertificateLink'}
          >
            <StyledDownloadIcon />
            <Trans>PEM</Trans>
          </a>
        </DownloadLinkSection>

        <DownloadLinkSection>
          <a
            href={certificateDerUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-qa={'DownloadCertificateDerLink'}
          >
            <StyledDownloadIcon />
            <Trans>DER</Trans>
          </a>
        </DownloadLinkSection>
        <DownloadLinkSection>
          <a
            href={metadataUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-qa={'DownloadMetadataLink'}
          >
            <StyledDownloadIcon />
            <Trans>Download metadata (XML)</Trans>
          </a>
        </DownloadLinkSection>
      </DownloadSection>
    </>
  )
}

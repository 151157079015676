import { msg } from '@lingui/macro'
import { put, take } from 'redux-saga/effects'

import { AutomatedVaultReEncryptionPolicy } from '@lastpass/admin-console/src/pages/users/federated-login/FederatedLoginPage'

import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  EditVaultReEncryptionDialog,
  VaultReEncryptionDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'

export function* vaultReEncryption(
  action: ReturnType<typeof federatedLoginActions.startVaultReEncryption>
) {
  const automatedVaultReEncryptionPolicy: AutomatedVaultReEncryptionPolicy =
    action.payload.automatedVaultReEncryptionPolicy
  const vaultReEncryptionDialog: VaultReEncryptionDialog = {
    type: 'vault-re-encryption-dialog',
    title: msg`Enable recurring and forced vault re-encryption`
  }
  const editVaultReEncryptionDialog: EditVaultReEncryptionDialog = {
    type: 'edit-vault-re-encryption-dialog',
    automatedVaultReEncryptionPolicy
  }

  if (automatedVaultReEncryptionPolicy.isEnabled) {
    yield put(globalActions.setDialog(editVaultReEncryptionDialog))
  } else {
    yield put(globalActions.setDialog(vaultReEncryptionDialog))
  }
  yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])
  yield put(globalActions.emptyDialog())
}

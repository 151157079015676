import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createGetDirectoryProvisionToken(
  userServices: UACServices.Services
) {
  return function* getDirectoryProvisionToken(
    action: ReturnType<typeof directoriesActions.getDirectoryProvisionToken>
  ) {
    try {
      const directoryProvisionTokenResult: UACServices.DirectoryProvisionTokenGetAPI.Responses = yield call(
        userServices.directoryProvisionToken
      )
      yield put(
        directoriesActions.setDirectoryProvisionToken(
          directoryProvisionTokenResult.body.provisionToken,
          action.payload.provider
        )
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

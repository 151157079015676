import { TeamsPolicyValueType } from '@lastpass/admin-console-dependencies/types/policy-teams'

import { PasswordlessActions, PasswordlessActionTypes } from './actions'
import { PasswordlessContainerState } from './state'

const initialState: PasswordlessContainerState = {
  RequirePasswordlessViaLastPassPolicy: {
    policyKey: '',
    policyData: [],
    policyTitle: '',
    policyShortDescription: '',
    policyDescription: '',
    policyValueType: TeamsPolicyValueType.none,
    checked: false,
    category: '',
    switchable: false
  },
  IsLoading: false,
  IsEmpty: true,
  AccessPolicies: {
    total: 0,
    results: []
  },
  IpPolicies: {
    total: 0,
    results: []
  },
  GeofencePolicies: {
    total: 0,
    results: []
  }
}

export function passwordlessContainerReducer(
  previousState: PasswordlessContainerState | undefined,
  action: PasswordlessActions
): PasswordlessContainerState {
  const state = previousState || initialState

  switch (action.type) {
    case PasswordlessActionTypes.REMOVE_POLICY: {
      return {
        ...state,
        RequirePasswordlessViaLastPassPolicy:
          initialState.RequirePasswordlessViaLastPassPolicy
      }
    }
    case PasswordlessActionTypes.SET_LOADING: {
      return {
        ...state,
        IsLoading: action.payload.loading
      }
    }
    case PasswordlessActionTypes.SET_EMPTYSTATE: {
      return {
        ...state,
        IsEmpty: action.payload.isEmpty
      }
    }
    case PasswordlessActionTypes.SET_ACCESS_POLICIES: {
      return {
        ...state,
        AccessPolicies: action.payload.accessPolicies
      }
    }
    case PasswordlessActionTypes.SET_GEOFENCE_POLICIES: {
      return {
        ...state,
        GeofencePolicies: action.payload.geofencePolicies
      }
    }
    case PasswordlessActionTypes.SET_IP_POLICIES: {
      return {
        ...state,
        IpPolicies: action.payload.ipPolicies
      }
    }
    case PasswordlessActionTypes.SET_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY: {
      return {
        ...state,
        RequirePasswordlessViaLastPassPolicy:
          action.payload.requirePasswordlessViaLastPassPolicy
      }
    }
    case PasswordlessActionTypes.ENABLE_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY: {
      return {
        ...state
      }
    }
  }

  return state
}

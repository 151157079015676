import { i18n } from '@lingui/core'
import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  adminActivityReportTranslationMap,
  userActivityReportTranslationMap
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-report-translations'
import {
  AdminActivityTypes,
  UserActivityTypes
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-types'
import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  ActivityEventType,
  ActivityEventUserType
} from '@lastpass/admin-console-dependencies/types/activity-event'

export const SUCCESS = 'success'

const success = (eventTypes: ActivityEventType[]) =>
  createResponse(SUCCESS, eventTypes)

export const Responses = {
  success
}

export type Response = string[]

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch, userType: ActivityEventUserType) {
  return async function service() {
    const [response, status] = await fetch(
      `reports/${userType}-activity/event-types`,
      'GET'
    )
    if (status !== StatusCodes.OK || !response) {
      throw new UnknownServiceError()
    }

    const parsedResponse: Response = JSON.parse(response).content.eventTypes

    const result: ActivityEventType[] = parsedResponse.map(value => ({
      label: i18n._(
        userType === ActivityEventUserType.user
          ? userActivityReportTranslationMap[value]
          : adminActivityReportTranslationMap[value]
      ),
      value
    }))

    const compareFn = (a: ActivityEventType, b: ActivityEventType) => {
      const valueToBeFirst =
        userType === ActivityEventUserType.user
          ? UserActivityTypes.all
          : AdminActivityTypes.all
      if (a.value === valueToBeFirst) {
        return -1
      }
      if (b.value === valueToBeFirst) {
        return 1
      }
      return a.label.localeCompare(b.label)
    }

    result.sort(compareFn)

    return success(result)
  }
}

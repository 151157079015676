import { put, take } from 'redux-saga/effects'

import { Federation } from '@lastpass/federation/lib/federation-enums'
import { CompanyWideIdpData } from '@lastpass/federation/types/key-rotation'
import { generateRandomString } from '@lastpass/federation/utils/random-string-generator'

import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationK1InputDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function* keyRotationGetCompanyWideK1(providerName: string) {
  const generatedNewK1 = generateRandomString(Federation.k1Length)
  const newK1 = Buffer.from(generatedNewK1, 'ascii')
  const keyRotationK1InputDialog: KeyRotationK1InputDialog = {
    type: 'key-rotation-k1-input-dialog',
    providerName: providerName
  }

  yield put(globalActions.setDialog(keyRotationK1InputDialog))
  const k1DialogAction = yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])
  if (k1DialogAction.type != GlobalActionTypes.CONFIRM_DIALOG) {
    return
  }

  const oldK1 = Buffer.from(k1DialogAction.payload.data, 'ascii')

  const companyWideK1Keys: CompanyWideIdpData = {
    oldK1,
    newK1
  }
  return companyWideK1Keys
}

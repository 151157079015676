import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { BodyBold, DetailsDialog, PrimaryButton } from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationUpdateK2ComponentsDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const StyledBodyText = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 24px;
`

interface KeyRotationUpdateK2ComponentsDialogProps {
  state: KeyRotationUpdateK2ComponentsDialog
}

export const KeyRotationUpdateK2ComponentsDialogComponent: FunctionComponent<KeyRotationUpdateK2ComponentsDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      title={msg`Update K2 components`}
      subTitle={msg`Now we need to update your K2 components on our side to keep the new K1 and K2 components in sync.`}
      disableCloseOnBackground
      content={
        <>
          <StyledBodyText>
            <Trans>
              <BodyBold>Don’t stop</BodyBold> once you’ve started.{' '}
              <BodyBold>Users are not impacted</BodyBold> during this process
              unless you quit.
            </Trans>
          </StyledBodyText>
          <StyledBodyText>
            <Trans>
              <BodyBold>Important</BodyBold>: In the next step, once the K2 is
              updated, you <BodyBold>must</BodyBold> paste the new K1 component
              to {state.providerName}.
            </Trans>
          </StyledBodyText>
        </>
      }
      buttons={[
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => dispatch(globalActions.confirmDialog())}
          key="confirm"
        >
          <Trans>Update K2 components</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { FunctionComponent } from 'react'

import { MessageDescriptor } from '@lingui/core'

import { NotificationDetails } from './notification-detail-types'

export enum NotificationType {
  hidden = 'hidden',
  success = 'success',
  alert = 'alert',
  warning = 'warning',
  async = 'async'
}

export interface NotificationProps {
  message?: MessageDescriptor | string
  type: NotificationType
  autoDismiss?: boolean
  details?: NotificationDetails
  genericError?: boolean
  component?: FunctionComponent
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as LockIcon } from '@lastpass/assets/svg/admin-console/icon-lock.svg'
import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import {
  Dropdown,
  DropdownListItem,
  Heading200,
  IconButton,
  PrimaryButton
} from '@lastpass/lastkit'

import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import {
  getMultifactorOptionType,
  MultifactorPolicyKeys
} from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'

import { UsersAndGroups } from './UsersAndGroups'

const ComponentContainer = styled.li`
  border: 1px solid #b9c0c9;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(29, 48, 73, 0.16),
    0px 2px 2px rgba(29, 48, 73, 0.04), 0 1px 2px rgba(29, 48, 73, 0.04);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
`

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral200};
`

const StyledHeading200 = styled(Heading200)`
  padding-left: 10px;
`

const CardBody = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const HeaderButton = props => <IconButton icon={More} {...props} />

const StyledDropdown = styled(Dropdown)`
  z-index: 1;
`

export interface PolicyCardProps {
  policy: TeamsPolicy
  dataIndex?: number
  canEditValue: boolean
}

const getPolicyTitle = (policy: TeamsPolicy, dataIndex?: number): string => {
  if (dataIndex === undefined || policy.policyData.length < 2) {
    return policy.policyTitle
  }

  const note = policy.policyData[dataIndex].policyNotes
  if (note) {
    return policy.policyTitle + ' - ' + note
  }

  return policy.policyTitle + ` #${dataIndex + 1}`
}

export const PolicyCard: React.FunctionComponent<PolicyCardProps> = ({
  policy,
  dataIndex,
  canEditValue
}) => {
  const title = getPolicyTitle(policy, dataIndex)
  const key = policy.policyKey
  const optionType =
    key !== MultifactorPolicyKeys.Salesforce &&
    key !== MultifactorPolicyKeys.Totp
      ? getMultifactorOptionType(key)
      : ''

  const dispatch = useDispatch()

  const actions = {
    setSelectedMfaMethod: optionType =>
      dispatch(multifactorDrawerActions.setSelectedMfaMethod(optionType)),
    configureAdvancedPolicy: (policy, dataIndex) =>
      dispatch(multifactorActions.configureAdvancedPolicy(policy, dataIndex)),
    disablePolicy: (policy, dataIndex) =>
      dispatch(multifactorActions.disablePolicy(policy, dataIndex)),
    editPolicyAssignments: (policy, dataIndex) =>
      dispatch(multifactorActions.editPolicyAssignments(policy, dataIndex))
  }

  const dataModel = policy.policyData[dataIndex || 0]
  const history = useHistory()
  return (
    <ComponentContainer data-qa="PolicyCard">
      <CardHeader>
        <FlexContainer>
          <LockIcon />
          <StyledHeading200 data-qa="PolicyCardTitle">{title}</StyledHeading200>
        </FlexContainer>

        <StyledDropdown button={HeaderButton} data-qa="PolicyMoreButton">
          {optionType && (
            <DropdownListItem
              data-qa="PolicyConfigureMethods"
              onClick={() => {
                actions.setSelectedMfaMethod(optionType)
                history.push('/policies/multifactor/configure')
              }}
            >
              <Trans>Configure methods</Trans>
            </DropdownListItem>
          )}
          {canEditValue && (
            <DropdownListItem
              data-qa="PolicyEditValues"
              onClick={() => {
                actions.configureAdvancedPolicy(policy, dataIndex)
              }}
            >
              <Trans>Edit policy</Trans>
            </DropdownListItem>
          )}
          <DropdownListItem
            red
            data-qa="PolicyDisablePolicy"
            onClick={() => {
              actions.disablePolicy(policy, dataIndex)
            }}
          >
            <Trans>Disable policy</Trans>
          </DropdownListItem>
        </StyledDropdown>
      </CardHeader>
      <CardBody>
        <UsersAndGroups
          inclusiveUsers={dataModel.inclusiveList || []}
          exclusiveUsers={dataModel.exclusiveList || []}
          inclusiveGroups={dataModel.inclusiveGroupList || []}
          exclusiveGroups={dataModel.exclusiveGroupList || []}
        />
        <PrimaryButton
          id="editPolicyUsers"
          data-qa="editPolicyUsers"
          type="button"
          onClick={() => {
            actions.editPolicyAssignments(policy, dataIndex)
          }}
        >
          <Trans>Edit policy users</Trans>
        </PrimaryButton>
      </CardBody>
    </ComponentContainer>
  )
}

import React, { FC, useState } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import moment from 'moment'

import {
  DateRangeWithPresets,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ManagedCompanyExportUserCountsDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface ManagedCompanyExportUserCountsDialogProps {
  state: ManagedCompanyExportUserCountsDialog
  actions: typeof globalActions
}

const Container = styled.div`
  border-radius: ${props => props.theme.radius.pixel8};
  display: flex;
  justify-content: space-between;
  width: fit-content;
  margin-top: 4px;
  background-color: ${props => props.theme.colors.white};
  min-width: 685px;
  z-index: 5;
  border: 1px solid ${props => props.theme.colors.neutral100};
`

export const ManagedCompanyExportUserCountsComponent: FC<ManagedCompanyExportUserCountsDialogProps> = ({
  actions
}) => {
  const discardDialog = () => {
    actions.discardDialog()
    actions.emptyDialog()
  }

  const today = moment().startOf('day')
  const lastMonth = moment()
    .startOf('day')
    .subtract(30, 'days')

  const [startDate, setStartDate] = useState<Date>(lastMonth.toDate())
  const [endDate, setEndDate] = useState<Date | undefined>(today.toDate())

  const confirmDialog = () => {
    if (!startDate || !endDate) {
      return
    }

    actions.confirmExportUserCounts(
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD')
    )
  }

  const setCustomStartDate = (date: Date) => {
    setStartDate(date)
  }

  const setCustomEndDate = (date?: Date) => {
    setEndDate(date)
  }

  const Content: FC = () => (
    <Container>
      <DateRangeWithPresets
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        maxSelectableDays={31}
        setStartDate={setCustomStartDate}
        setEndDate={setCustomEndDate}
      />
    </Container>
  )

  return (
    <DetailsDialog
      closeDialog={discardDialog}
      width="693px"
      title={msg`Export user counts for specified period`}
      subTitle={msg`Select a date range (max. 31 days)`}
      content={<Content />}
      disableMaxHeight
      buttons={[
        <TextButton
          onClick={discardDialog}
          key="discard"
          data-qa="ManagedCompanyExportUserCountsDialogCancelButton"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <PrimaryButton
          onClick={confirmDialog}
          key="confirm"
          data-qa="ManagedCompanyExportUserCountsDialogConfirmButton"
          disabled={startDate && !endDate}
        >
          <Trans>Export</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'

import { Loading } from '@lastpass/components'
import { CardContainer, TableView, TableViewButton } from '@lastpass/lastkit'

const StyledCardContainer = styled(CardContainer)<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: ${({ height }) => (height ? height : '370px')};
  margin-top: 27px;
`

export interface TableDataLoaderProps {
  isLoading: boolean
  isDataAvailable: boolean
  noRecordTitle: ReactElement
  reloadButtonText?: ReactElement
  height?: string
  onReload: () => void
}

export const TableDataLoader: FunctionComponent<TableDataLoaderProps> = ({
  isLoading,
  isDataAvailable,
  noRecordTitle,
  reloadButtonText,
  children,
  height,
  onReload
}) => {
  if (isLoading) {
    return (
      <StyledCardContainer data-qa="TableDataLoader">
        <Loading color="blue900" active={true} isFull={false} />
      </StyledCardContainer>
    )
  }

  return isDataAvailable ? (
    <>{children}</>
  ) : (
    <StyledCardContainer height={height}>
      <TableView
        icon={<></>}
        title={noRecordTitle}
        actionButton={
          <TableViewButton onClick={onReload} data-qa="ReloadLink">
            {reloadButtonText}
          </TableViewButton>
        }
      />
    </StyledCardContainer>
  )
}

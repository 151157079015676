import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  CardContainer,
  Checkbox,
  DetailCell,
  PrimaryButton,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import {
  Drawer,
  DrawerButtonsContainer,
  FooterContainer
} from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'

import { CreateManagedCompanyBody } from '@lastpass/admin-console-dependencies/server/home/managed-companies/create-managed-company'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import { CompanyTemplateTypes } from '@lastpass/admin-console-dependencies/types/managed-company'

import { CompanyCopyForm } from './CompanyCopyForm'

const DescriptionContainer = styled.div`
  ${BodyRegularStyle}
  margin: -30px 0 30px 0;
`

const StyledCardContainer = styled(CardContainer)`
  padding: 8px 16px 35px 16px;
`

const FieldContainer = styled.div`
  margin-top: 16px;
`

const minimumNumberOfLicenses = 11

const isSubmissionDisabled = (values: CreateManagedCompanyBody): boolean =>
  !values.companyName || values.numberOfLicenses < minimumNumberOfLicenses

interface CreateManagedCompanyDrawerProps {
  closeLink: string
}

export const CreateManagedCompanyDrawer: FunctionComponent<CreateManagedCompanyDrawerProps> = ({
  closeLink
}) => {
  const dispatch = useDispatch()

  const { details: companyDetails } = useSelector<AppState, CompanyInfoState>(
    state => state.companyInfo
  )

  const copyTemplateSettings = useSelector(
    (state: AppState) => state.managedCompanies.companyTemplateSettings
  )

  const copyTemplateOptions = copyTemplateSettings.length
    ? copyTemplateSettings[0].options
    : []

  const initialValues: CreateManagedCompanyBody = {
    companyName: '',
    numberOfLicenses: minimumNumberOfLicenses,
    isMsp: false,
    companyTemplateSettings: {
      type: CompanyTemplateTypes.default,
      options: copyTemplateOptions
    }
  }

  return (
    <Drawer
      data-qa="CreateManagedCompanyDrawer"
      closeLink={closeLink}
      mainTitle={<Trans>Create managed company</Trans>}
    >
      <DescriptionContainer>
        <Trans>
          Create a managed company and allocate licenses from the primary
          account.
        </Trans>
      </DescriptionContainer>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={formValues => {
          if (!isSubmissionDisabled(formValues)) {
            const values =
              formValues.companyTemplateSettings?.type !==
              CompanyTemplateTypes.default
                ? formValues
                : { ...formValues, companyTemplateSettings: null }
            dispatch(managedCompaniesActions.create(values))
          }
        }}
      >
        {formikProps => (
          <Form>
            <StyledCardContainer data-qa="CreateManagedCompanyCardContainer">
              <DetailCell
                field={<Trans>Primary account</Trans>}
                value={companyDetails.name || ''}
                data-qa="CreateManagedCompanyPrimaryAccountCell"
              />
              <FieldContainer data-qa="CreateManagedCompanyNameFieldContainer">
                <Field name="companyName">
                  {({ field }) => (
                    <TextInput {...field} required>
                      <Trans>Managed company name</Trans>
                    </TextInput>
                  )}
                </Field>
              </FieldContainer>

              {!companyDetails.isBillingUsageBased && (
                <FieldContainer data-qa="CreateManagedCompanyNumberOfLicensesFieldContainer">
                  <Field name="numberOfLicenses">
                    {({ field }) => (
                      <TextInput
                        {...field}
                        type="number"
                        min={minimumNumberOfLicenses}
                        required
                      >
                        <Trans>Licenses to allocate</Trans>
                      </TextInput>
                    )}
                  </Field>
                </FieldContainer>
              )}

              {companyDetails.isAggregator && (
                <FieldContainer data-qa="CreateManagedCompanyIsMspFieldContainer">
                  <Field name="isMsp">
                    {({ field }) => (
                      <Checkbox {...field} checked={field.value}>
                        <Trans>Managed Service Provider</Trans>
                      </Checkbox>
                    )}
                  </Field>
                </FieldContainer>
              )}
            </StyledCardContainer>

            <CompanyCopyForm formikProps={formikProps} />

            <FooterContainer>
              <DrawerButtonsContainer>
                <LocationLink to={closeLink}>
                  <TextButton
                    css={css`
                      margin-right: 16px;
                    `}
                    type="button"
                    data-qa="CreateManagedCompanyCancelButton"
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  type="submit"
                  data-qa="CreateManagedCompanySubmitButton"
                  disabled={isSubmissionDisabled(formikProps.values)}
                >
                  <Trans>Create</Trans>
                </PrimaryButton>
              </DrawerButtonsContainer>
            </FooterContainer>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

import { DashboardDrawerActions, DashboardDrawerActionTypes } from './actions'
import { DashboardDrawerState } from './state'

const tableBase = {
  results: [],
  resultsPerPage: 25,
  totalResults: 0,
  checkedRecords: [],
  isLoading: true
}

export const initialState: DashboardDrawerState = {
  unresponsiveUsers: tableBase,
  inactiveUsers: tableBase,
  stagedUsers: tableBase,
  usersWithWeakMasterPassword: tableBase,
  usersWithWeakSecurityScore: tableBase,
  usersReusingMasterPassword: tableBase,
  dashboardDrawerLoading: false
}

export function dashboardDrawerReducer(
  previousState: DashboardDrawerState | undefined,
  action: DashboardDrawerActions
): DashboardDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case DashboardDrawerActionTypes.RESET: {
      return initialState
    }
    case DashboardDrawerActionTypes.SET_TABLE: {
      return {
        ...state,
        [action.payload.tableName]: {
          results: action.payload.users,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case DashboardDrawerActionTypes.SET_UNRESPONSIVE_USERS_LOADING: {
      return {
        ...state,
        unresponsiveUsers: {
          ...state.unresponsiveUsers,
          isLoading: action.payload
        }
      }
    }
    case DashboardDrawerActionTypes.SET_INACTIVE_USERS_LOADING: {
      return {
        ...state,
        inactiveUsers: {
          ...state.inactiveUsers,
          isLoading: action.payload
        }
      }
    }
    case DashboardDrawerActionTypes.SET_STAGED_USERS_LOADING: {
      return {
        ...state,
        stagedUsers: {
          ...state.stagedUsers,
          isLoading: action.payload
        }
      }
    }
    case DashboardDrawerActionTypes.SET_DASHBOARD_DRAWER_LOADING: {
      return {
        ...state,
        dashboardDrawerLoading: action.payload
      }
    }
    case DashboardDrawerActionTypes.SET_WEAK_MASTERPASSWORD_USERS_LOADING: {
      return {
        ...state,
        usersWithWeakMasterPassword: {
          ...state.usersWithWeakMasterPassword,
          isLoading: action.payload
        }
      }
    }
    case DashboardDrawerActionTypes.SET_USERS_WITH_WEAK_SECURITY_SCORE_LOADING: {
      return {
        ...state,
        usersWithWeakMasterPassword: {
          ...state.usersWithWeakMasterPassword,
          isLoading: action.payload
        }
      }
    }
    case DashboardDrawerActionTypes.SET_REUSED_MASTER_PASSWORD_LIST_LOADING: {
      return {
        ...state,
        usersReusingMasterPassword: {
          ...state.usersReusingMasterPassword,
          isLoading: action.payload
        }
      }
    }
  }

  return state
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = () => createResponse(SUCCESS)
const failure = () => createResponse(FAILURE)

export const responses = {
  success,
  failure
}

export type Responses = ResponseTypes<typeof responses>

export function create(fetch: AuthFetch) {
  return async function service(
    managedCompanyLastPassId: ManagedCompany['id'],
    selectedCompanyOwnerLastPassUserId: number
  ) {
    const [, status] = await fetch(
      `company/managed-companies/${managedCompanyLastPassId}/detach`,
      'POST',
      { selectedCompanyOwnerLastPassUserId },
      { 'Content-Type': 'application/json' }
    )

    if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
      throw new UnknownServiceError()
    }

    if (status !== StatusCodes.OK) {
      return failure()
    }

    return success()
  }
}

import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  CardContainer,
  Heading100,
  Heading200
} from '@lastpass/lastkit'

import { AcronisApiState } from '@lastpass/admin-console-dependencies/state/advanced/acronis-api/state'

import { EmptyHash } from './EmptyHash'
import { ExistingHash } from './ExistingHash'
import { NewHash } from './NewHash'

export const Container = styled(CardContainer)`
  padding: 16px 24px;
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.div`
  flex-grow: 2;
`

export const Title = styled(Heading100)`
  color: ${props => props.theme.colors.neutral900};
  margin-bottom: 4px;
`

export const Hash = styled(Heading200)`
  color: ${props => props.theme.colors.neutral900};
  margin-bottom: 4px;
  margin-top: 4px;
`

export const Subtitle = styled.div`
  ${BodyRegularStyle}
  color: ${props => props.theme.colors.neutral700};
`

export const AcronisHash: React.FunctionComponent = () => {
  const { provisioningHash, alreadyHasHash }: AcronisApiState = useSelector(
    (state: AppState) => state.acronisApi
  )

  if (provisioningHash) {
    return <NewHash hash={provisioningHash} />
  } else if (alreadyHasHash) {
    return <ExistingHash />
  } else {
    return <EmptyHash />
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { sharedFolderDrawerActions } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'

export function createGetSharedFolderSites(
  sharedFolderServices: UACServices.Services
) {
  return function* getSharedFolderSites(
    action: ReturnType<
      typeof sharedFolderDrawerActions.getSharedFolderSitesList
    >
  ) {
    try {
      yield put(sharedFolderDrawerActions.setSharedFolderSitesLoading(true))
      const shareId = action.payload.params.path.id
      if (shareId) {
        const sharedFolderSitesResult: UACServices.ShareFoldersSitesAPI.Responses = yield call(
          sharedFolderServices.sharedFoldersSitesGet,
          shareId
        )

        yield put(
          sharedFolderDrawerActions.setSharedFolderSitesList(
            sharedFolderSitesResult.body.sites
          )
        )

        yield put(sharedFolderDrawerActions.setSharedFolderSitesLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { UserLevelObject } from '@lastpass/admin-console-dependencies/types/user-level'
import { UserLevelMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/UserLevelMapping'

export interface UserLevelProps {
  userLevel: UserLevelObject | null
}

export const UserLevelLabel = (props: UserLevelProps) => {
  return props.userLevel ? (
    UserLevelMapping[props.userLevel.name] ||
      (props.userLevel.isCustom ? props.userLevel.name : <></>)
  ) : (
    <Trans>User</Trans>
  )
}

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useUnmountEffect } from '@lastpass/ui'

import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'

export interface GroupDrawerProps {
  closeLink: string
  backButton?: ReactElement
  childTitle?: ReactElement
  moreButton?: ReactElement
  assignUsersButton?: ReactElement
}

export const GroupDrawer: React.FunctionComponent<GroupDrawerProps> = ({
  closeLink,
  backButton,
  childTitle,
  moreButton,
  assignUsersButton,
  children
}) => {
  const groupDrawerState: GroupDrawerState = useSelector(
    (state: AppState) => state.groupDrawer
  )
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      groupDrawerActions.getCurrentGroup({ query: {}, path: pathParams })
    )
  }, [dispatch, pathParams])

  useUnmountEffect(() => {
    dispatch(groupDrawerActions.reset())
  })

  return (
    <Drawer
      mainTitle={
        (groupDrawerState.group && <>{groupDrawerState.group.name}</>) || <></>
      }
      subTitle={childTitle}
      closeLink={closeLink}
      headerNodes={
        <>
          {assignUsersButton}
          {backButton}
          {moreButton}
        </>
      }
    >
      {children}
    </Drawer>
  )
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { readFileAsText } from '@lastpass/admin-console-dependencies/services/read-file-as-text'
import { SymantecVipSettings } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createSaveSymantecConfig(
  multifactorServices: UACServices.Services
) {
  return function* saveSymantecConfig(
    action: ReturnType<typeof multifactorDrawerActions.saveSymantecConfig>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))

      const formValues = action.payload.symantecConfig
      const symantecConfig: SymantecVipSettings = {
        certificate: '',
        certificateLength: 0,
        certificatePassword: formValues.certificatePassword,
        certificatePasswordLength: formValues.certificatePassword?.length ?? 0,
        hasCertificatePassword:
          !!formValues.certificatePassword &&
          formValues.certificatePassword.length > 0
      }

      if (formValues.certificate) {
        if (typeof formValues.certificate === 'string') {
          symantecConfig.certificateLength = formValues.certificate.length
          symantecConfig.certificate = formValues.certificate
        } else {
          symantecConfig.certificate = yield call(
            readFileAsText,
            formValues.certificate
          )
          symantecConfig.certificateLength = formValues.certificate.size
        }
      }

      try {
        const saveSymantecSettingsResponse = yield call(
          multifactorServices.symantecVipSettingsSave,
          symantecConfig
        )

        if (
          saveSymantecSettingsResponse.type ===
          UACServices.SymantecVipSettingsSaveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

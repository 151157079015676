import styled from '@emotion/styled'

import {
  CaptionBoldStyle,
  CaptionRegularStyle,
  CaptionSemiboldStyle
} from '../styles'

export const CaptionBold = styled.span`
  ${CaptionBoldStyle}
`

export const CaptionSemibold = styled.span`
  ${CaptionSemiboldStyle}
`

export const CaptionRegular = styled.span`
  ${CaptionRegularStyle}
`

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as EmptyChartSvg } from '@lastpass/assets/svg/admin-console/empty-chart.svg'

import { BodyRegular } from './BodyText'
import { Heading100 } from './Heading'
import { TextButton } from './TextButton'

interface EmptyChartProps {
  title: React.ReactElement
  subtitle: React.ReactElement
  button?: {
    text: React.ReactElement
    onClick: () => void
  }
}

const StyledEmptyChartSvg = styled(EmptyChartSvg)`
  width: 100%;
  padding: 16px 16px 24px 44px;
  box-sizing: border-box;
  margin: 20px 0px;
`
const TextContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Container = styled.div`
  position: relative;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-bottom: 10px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 10px;
`

export const EmptyChart: FunctionComponent<EmptyChartProps> = props => {
  return (
    <Container>
      <TextContainer>
        <StyledHeading100>{props.title}</StyledHeading100>
        <StyledBodyRegular>{props.subtitle}</StyledBodyRegular>
        {props.button && (
          <TextButton blue onClick={props.button.onClick}>
            {props.button.text}
          </TextButton>
        )}
      </TextContainer>
      <StyledEmptyChartSvg />
    </Container>
  )
}

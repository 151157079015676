import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { SamlResponse } from '@lastpass/admin-console-dependencies/types/saml-response'

export interface SamlResponsesQueryParams {
  search: string
  skip: number
  from: {
    label: string
    value: Date
  }
}

export enum SamlResponsesActionTypes {
  SET_SAML_RESPONSES = '[reports] set-saml-responses',
  GET_SAML_RESPONSES = '[reports] get-saml-responses'
}

export const samlResponsesActions = {
  setSamlResponses: (samlResponses: SamlResponse[], totalResults) =>
    createAction(SamlResponsesActionTypes.SET_SAML_RESPONSES, {
      samlResponses,
      totalResults
    }),
  getSamlResponses: (params: DataFetchParams<SamlResponsesQueryParams>) =>
    createAction(SamlResponsesActionTypes.GET_SAML_RESPONSES, {
      query: params.query
    })
}

export type SamlResponsesActions = ActionsUnion<typeof samlResponsesActions>

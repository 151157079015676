import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

export const createQueryStringForNamespace = (
  namespace: Namespace,
  urlParams: {
    [key: string]: unknown
  }
): string =>
  new URLSearchParams({
    [namespace]: btoa(JSON.stringify(urlParams))
  }).toString()

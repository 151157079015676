import { Event } from '@sentry/browser'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum ErrorHandlingActionTypes {
  REPORT_ERROR = '[error handling] report',
  REPORT_ERROR_EVENT = '[error handling] report error event'
}

export const errorHandlingActions = {
  reportError: (error: Error) =>
    createAction(ErrorHandlingActionTypes.REPORT_ERROR, error),
  reportErrorEvent: (event: Event) =>
    createAction(ErrorHandlingActionTypes.REPORT_ERROR_EVENT, event)
}

export type ErrorHandlingActions = ActionsUnion<typeof errorHandlingActions>

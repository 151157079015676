import React from 'react'

import styled from '@emotion/styled'
import { Field, FieldProps } from 'formik'

import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  RadioContainer,
  StyledRadioLabel
} from '@lastpass/admin-console-dependencies/ui/common/DrawerAssign/AppliesToRadioButtons'
import { PolicyUsersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PolicyUsersMapping'

const StyledRadioInput = styled.input`
  cursor: pointer;
`

export const PolicyAppliesToEditControl: React.FunctionComponent<{
  radioPolicyAppliesTo: AppliesToOptions
  shouldHideUsersOptions: boolean
  onChange: (currentlyAppliesTo: AppliesToOptions, fieldName: string) => void
}> = props => {
  const policyAppliesToRadioOptions: AppliesToOptions[] = [
    AppliesToOptions.all,
    AppliesToOptions.inclusive,
    AppliesToOptions.exclusive
  ]

  return (
    <RadioContainer>
      <Field name="appliesTo">
        {(formData: FieldProps) => {
          return (
            <>
              {policyAppliesToRadioOptions.map(radioOption => {
                return (
                  <div key={radioOption}>
                    <StyledRadioInput
                      type="radio"
                      id={radioOption}
                      name={formData.field.name}
                      value={radioOption}
                      checked={props.radioPolicyAppliesTo === radioOption}
                      disabled={props.shouldHideUsersOptions}
                      onChange={e => {
                        const currentlyAppliesTo = e.currentTarget
                          .value as AppliesToOptions
                        props.onChange(currentlyAppliesTo, formData.field.name)
                      }}
                    />
                    <StyledRadioLabel htmlFor={radioOption}>
                      {PolicyUsersMapping[radioOption]}
                    </StyledRadioLabel>
                  </div>
                )
              })}
            </>
          )
        }}
      </Field>
    </RadioContainer>
  )
}

import { put, select } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicyUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import { GeneralPolicyUsersAndGroupsConfigFormData } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'

export const getPolicyConfigData = state =>
  state.generalPolicyUsersAndGroups.policyUsersAndGroups.policyConfigFormData

export function createCheckPolicyAppliesToGeneralPolicySaga() {
  return function* checkPolicyAppliesToGeneralPolicySaga(
    action: ReturnType<
      typeof generalPolicyUsersAndGroupsActions.checkGeneralPolicyAppliesTo
    >
  ) {
    const formPolicyAppliesTo = action.payload.policyAppliesTo
    const routingInfo = action.payload.params

    try {
      const policyConfigData: GeneralPolicyUsersAndGroupsConfigFormData = yield select(
        getPolicyConfigData
      )
      const policyAppliesTo = policyConfigData.appliesTo
      yield put(
        generalPolicyUsersAndGroupsActions.setGeneralPolicyCurrentlyAppliesTo(
          formPolicyAppliesTo
        )
      )
      if (formPolicyAppliesTo !== policyAppliesTo) {
        yield put(generalPolicyUsersAndGroupsActions.resetGeneralPolicyTables())
      } else {
        yield put(
          generalPolicyUsersAndGroupsActions.getGeneralPolicyUsersAndGroups({
            ...routingInfo
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

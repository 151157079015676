import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import {
  generalPolicyUsersAndGroupsActions,
  GeneralPolicyUsersAndGroupsQueryParams,
  GeneralPolicyUsersAndGroupsUrlParams
} from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/actions'
import { GeneralPolicyUsersAndGroupsFormState } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { GeneralPolicyEditAssignedItemsForm } from '@lastpass/admin-console-dependencies/ui/policies/general/GeneralPolicyEditAssignedItemsForm/GeneralPolicyEditAssignedItemsForm'

export interface EditGeneralPolicyUsersProps {
  assignUserslink: string
}

export const EditGeneralPolicyUsers: React.FunctionComponent<EditGeneralPolicyUsersProps> = ({
  assignUserslink
}) => {
  const queryParams: GeneralPolicyUsersAndGroupsQueryParams = useQueryParams(
    Namespace.users
  )
  const updateQuery = useUpdateQuery(Namespace.users)
  const pathParams: GeneralPolicyUsersAndGroupsUrlParams = usePathParams()

  const generalPolicyUsersAndGroupsState: GeneralPolicyUsersAndGroupsFormState = useSelector(
    (state: AppState) => state.generalPolicyUsersAndGroups
  )
  const dispatch = useDispatch()

  const match = useRouteMatch()
  const matchUrl = match && match.url

  useEffect(() => {
    dispatch(
      generalPolicyUsersAndGroupsActions.getGeneralPolicyUsersAndGroups({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])
  return (
    <GeneralPolicyEditAssignedItemsForm
      shouldHideUsersOptions={false}
      policyUsersAndGroups={
        generalPolicyUsersAndGroupsState.policyUsersAndGroups
      }
      usersAndGroupsNeedsRefresh={
        generalPolicyUsersAndGroupsState.usersAndGroupsNeedsRefresh
      }
      policyConfigUsers={generalPolicyUsersAndGroupsState.policyConfigUsers}
      policyConfigGroups={generalPolicyUsersAndGroupsState.policyConfigGroups}
      usersChangesList={generalPolicyUsersAndGroupsState.toBeRemovedUsers}
      groupsChangesList={generalPolicyUsersAndGroupsState.toBeRemovedGroups}
      policyCurrentlyAppliesTo={
        generalPolicyUsersAndGroupsState.policyCurrentlyAppliesTo
      }
      hasReceivedServerResponse={
        generalPolicyUsersAndGroupsState.hasReceivedServerResponse
      }
      currentUrl={matchUrl || ''}
      searchString={queryParams.search}
      id={pathParams.id}
      configId={pathParams.configId}
      isForAssign={false}
      cancelLink={`/policies/generalpolicies/${generalPolicyUsersAndGroupsState.policyUsersAndGroups.policyKey}`}
      assignUsersLink={assignUserslink}
      onSubmitted={formData =>
        dispatch(
          generalPolicyUsersAndGroupsActions.saveGeneralPolicyUsersAndGroups(
            formData
          )
        )
      }
      onReset={() => dispatch(generalPolicyUsersAndGroupsActions.reset())}
      onSearchStringUpdated={(searchString?: string) =>
        updateQuery({ search: searchString })
      }
      onCheckGeneralPolicyAppliesTo={(appliesTo, params) =>
        dispatch(
          generalPolicyUsersAndGroupsActions.checkGeneralPolicyAppliesTo(
            appliesTo,
            params
          )
        )
      }
      onAddSelectedGroups={groupList =>
        dispatch(
          generalPolicyUsersAndGroupsActions.addSelectedGeneralPolicyGroups(
            groupList
          )
        )
      }
      onRemoveSelectedGroups={groupList =>
        dispatch(
          generalPolicyUsersAndGroupsActions.removeSelectedGeneralPolicyGroups(
            groupList
          )
        )
      }
      onSetSelectedGroups={groupList =>
        dispatch(
          generalPolicyUsersAndGroupsActions.setSelectedGeneralPolicyGroups(
            groupList
          )
        )
      }
      onAddSelectedUsers={userList =>
        dispatch(
          generalPolicyUsersAndGroupsActions.addSelectedGeneralPolicyUsers(
            userList
          )
        )
      }
      onRemoveSelectedUsers={userList =>
        dispatch(
          generalPolicyUsersAndGroupsActions.removeSelectedGeneralPolicyUsers(
            userList
          )
        )
      }
      onSetSelectedUsers={userList =>
        dispatch(
          generalPolicyUsersAndGroupsActions.setSelectedGeneralPolicyUsers(
            userList
          )
        )
      }
      onSetGeneralPolicyUsersAndGroupsAppliesToForAssign={appliesTo =>
        dispatch(
          generalPolicyUsersAndGroupsActions.setGeneralPolicyUsersAndGroupsAppliesToForAssign(
            appliesTo
          )
        )
      }
    />
  )
}

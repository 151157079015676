export enum MultifactorOptionType {
  TotpApp = 'TotpApp',
  Salesforce = 'Salesforce',
  DuoSecurity = 'DuoSecurity',
  SymantecVip = 'SymantecVip',
  RsaSecureId = 'RsaSecureId',
  SecureAuth = 'SecureAuth'
}

export interface DuoSecurityModel {
  api_hostname: string
  integration_key: string
  secret_key: string
}

export interface SymantecModel {
  certificate: string
  certificateLength: number
  certificatePassword?: string
  certificatePasswordLength: number
  hasCertificatePassword: boolean
}

export interface SymantecFormModel {
  certificate: File | string | null
  certificatePassword?: string
}

export interface RsaModel {
  failureMessage: string
  hasRadiusSharedSecret: boolean
  logo124x124?: string | File | null
  logo124x124Length: number
  logo190x41?: string | File | null
  logo190x41Length: number
  radiusServerIp: string
  radiusSharedSecret?: string
  radiusSharedSecretLength: number
  radiusTimeout: string
  serviceName: string
}

export interface SecureAuthModel {
  applicationId: string
  applicationKey: string
  realm: string
}

export enum MultifactorPolicyKeys {
  Totp = 'requirelastpassauth',
  Salesforce = 'requiresalesforcehash',
  DuoSecurity = 'requireduo',
  SymantecVip = 'requiresymantecvip',
  RsaSecureId = 'requiresecurid',
  SecureAuth = 'requiresecureauth'
}

export const getMultifactorOptionType = (
  policyKey: string
): MultifactorOptionType | null => {
  switch (policyKey) {
    case MultifactorPolicyKeys.DuoSecurity:
      return MultifactorOptionType.DuoSecurity
    case MultifactorPolicyKeys.RsaSecureId:
      return MultifactorOptionType.RsaSecureId
    case MultifactorPolicyKeys.Salesforce:
      return MultifactorOptionType.Salesforce
    case MultifactorPolicyKeys.SecureAuth:
      return MultifactorOptionType.SecureAuth
    case MultifactorPolicyKeys.SymantecVip:
      return MultifactorOptionType.SymantecVip
    case MultifactorPolicyKeys.Totp:
      return MultifactorOptionType.TotpApp
  }

  return null
}

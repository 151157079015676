import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'

import { Heading100 } from '@lastpass/lastkit'

const PingFederateDownloadLink = styled.a`
  display: block;
  margin-top: 10px;
`

export interface PingFederateDownloadBlockProps {
  title: MessageDescriptor
  textForPingFederate103Link: MessageDescriptor
  textForPingFederate110Link: MessageDescriptor
  dataQaName: string
}

export const PingFederateDownloadBlock: FunctionComponent<PingFederateDownloadBlockProps> = props => {
  return (
    <>
      <Heading100>{i18n._(props.title)}</Heading100>
      <PingFederateDownloadLink
        href="https://download.cloud.lastpass.com/pingfederate/LastPassPingFederate103.zip"
        data-qa={`${props.dataQaName}LastPassPingFederate103-DownloadLink`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n._(props.textForPingFederate103Link)}
      </PingFederateDownloadLink>
      <PingFederateDownloadLink
        href="https://download.cloud.lastpass.com/pingfederate/LastPassPingFederate110.zip"
        data-qa={`${props.dataQaName}LastPassPingFederate110-DownloadLink`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n._(props.textForPingFederate110Link)}
      </PingFederateDownloadLink>
    </>
  )
}

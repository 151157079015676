import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodySemiboldStyle } from '@lastpass/lastkit/styles'

const Button = styled.button`
${BodySemiboldStyle}
color: ${props => props.theme.colors.blue700};
height: 24px;
display: flex;
align-items: center;
cursor: pointer;
padding: 0;
border: none;
background: none;
outline: none;
`

const StyledMessage = styled.div<{ toggled: boolean }>`
${BodySemiboldStyle}
  color: ${props =>
    props.toggled ? props.theme.colors.blue700 : props.theme.colors.red700};
  cursor: pointer;
  margin-top: -10px;
  font-size: 12px;
`

export const ExtendableFormErrorToggle = <T extends {}>({
  initialLoad,
  toggled,
  duplicateRecords,
  toggle,
  totalRecords,
  errorRecords,
  licensesAvailable
}: {
  initialLoad: boolean
  toggled: boolean
  duplicateRecords: T[]
  toggle: () => void
  totalRecords: T[]
  errorRecords: string[]
  licensesAvailable: number | undefined
}) => {
  let errors = 0
  totalRecords.forEach((value: T, index: number) => {
    if (errorRecords[index] != null || duplicateRecords[index] != null) {
      errors = errors + 1
    }
  })

  if (licensesAvailable && licensesAvailable < totalRecords.length) {
    return (
      <StyledMessage toggled={false}>
        <Trans>You don{"'"}t have enough licenses</Trans>
      </StyledMessage>
    )
  }

  return (
    <Button
      type="button"
      onClick={() => {
        toggle()
      }}
    >
      {!initialLoad && totalRecords.length > 1 ? (
        toggled ? (
          <StyledMessage toggled={toggled} data-qa={'AddUsersShowAllUser'}>
            <Trans>Show all users </Trans>
            {' (' + totalRecords.length + ')'}
          </StyledMessage>
        ) : errors > 0 ? (
          <StyledMessage toggled={toggled} data-qa={'AddUserErrorButton'}>
            <Trans>Only show users with errors </Trans> {' (' + errors + ')'}
          </StyledMessage>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </Button>
  )
}

import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { PasswordlessContainerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/state'
import { addUsersDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'
import { AddUsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/add/state'
import { NewAddUser } from '@lastpass/admin-console-dependencies/state/users/view/add/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { ExtendableForm, ExtendableFormProps } from './ExtendableForm'

interface AddUsersDrawerFormProps {
  cancelLink: string
}

export const AddUsersDrawerForm: FC<AddUsersDrawerFormProps> = ({
  cancelLink
}) => {
  const initialCheckedPendingAddUsers: NewAddUser[] = []
  const [checkedPendingAddUsers, setCheckedPendingAddUsers] = useState(
    initialCheckedPendingAddUsers
  )
  const passwordlessState: PasswordlessContainerState = useSelector(
    (appState: AppState) => appState.passwordlessContainer
  )

  const addUsersDrawerState: AddUsersDrawerState = useSelector(
    (appState: AppState) => appState.addUsersDrawer
  )

  const companyInfoState: CompanyInfoState = useSelector(
    (appState: AppState) => appState.companyInfo
  )

  const permissions = useContext(PermissionContext)
  const canModifySsoApps = permissions.requirePermission(
    AdminPermissions.ssoModify
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (!canModifySsoApps) {
      return
    }
    dispatch(passwordlessActions.fetchPasswordlessStatus())
    if (
      passwordlessState.RequirePasswordlessViaLastPassPolicy.checked &&
      passwordlessState.RequirePasswordlessViaLastPassPolicy.policyData[0]
        .appliesTo == AppliesToOptions.all
    ) {
      dispatch(addUsersDrawerActions.setMfaInvite(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const history = useHistory()

  const addSelectedPendingAddUsers = (pendingAddUsers: NewAddUser[]) =>
    setCheckedPendingAddUsers(checkedPendingAddUsers.concat(pendingAddUsers))
  const removeSelectedPendingAddUsers = (pendingAddUsers: NewAddUser[]) =>
    setCheckedPendingAddUsers(
      checkedPendingAddUsers.filter(user => !pendingAddUsers.includes(user))
    )
  const setSelectedPendingAddUsers = (pendingAddUsers: NewAddUser[]) =>
    setCheckedPendingAddUsers(pendingAddUsers)

  const emails: string[] = addUsersDrawerState.pendingAddUsers.map(
    (value: NewAddUser) => {
      return value.email ? value.email : ''
    }
  )

  const duplicateRecords: NewAddUser[] = []

  addUsersDrawerState.pendingAddUsers.forEach(record => {
    const count = emails.reduce(
      (previousValue: number, currentValue: string) =>
        previousValue + (currentValue === record.email ? 1 : 0),
      0
    )
    if (count > 1) {
      duplicateRecords.push(record)
    }
  })

  const closeDrawer = () => {
    dispatch(addUsersDrawerActions.clearAddUsers())
    history.push(cancelLink)
  }

  const extendableFormProps: ExtendableFormProps<NewAddUser> = {
    columns: [
      {
        name: i18n._(msg`Email`),
        width: '200px',
        required: true
      },
      { name: i18n._(msg`First name`), width: '100px' },
      { name: i18n._(msg`Last name`), width: '100px' }
    ],
    initialRecords:
      addUsersDrawerState.pendingAddUsers.length == 0
        ? [{ email: undefined, firstName: undefined, lastName: undefined }]
        : addUsersDrawerState.pendingAddUsers,
    onSubmit: (values: { records: NewAddUser[] }) => {
      if (
        companyInfoState.remainingLicenses &&
        companyInfoState.remainingLicenses > 0
      ) {
        dispatch(
          addUsersDrawerActions.addNewUsers(
            values.records,
            addUsersDrawerState.passwordManagementInvite,
            addUsersDrawerState.lastPassMfaInvite
          )
        )
      } else {
        dispatch(
          globalActions.setNotificationAlert(
            msg`Remaining licenses: 0. Buy more`
          )
        )
        closeDrawer()
      }
    },
    cancelLink,
    checkedRecords: checkedPendingAddUsers,
    checkable: {
      addSelectedRecords: addSelectedPendingAddUsers,
      removeSelectedRecords: removeSelectedPendingAddUsers,
      setSelectedRecords: setSelectedPendingAddUsers
    },

    yupSchema: Yup.object().shape({
      records: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .email(i18n._(msg`Invalid email address`))
              .required(i18n._(msg`Email address is required`))
          })
        )
        .required(i18n._(msg`You must have at least one user`))
    }),
    licensesAvailable: companyInfoState.remainingLicenses
  }
  return <ExtendableForm {...extendableFormProps} />
}

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as AddIcon } from '@lastpass/assets/svg/admin-console/icon-add.svg'
import { ReactComponent as PaperClipIcon } from '@lastpass/assets/svg/admin-console/icon-paper-clip.svg'
import { ReactComponent as SignOutIcon } from '@lastpass/assets/svg/admin-console/icon-signout.svg'
import { Chevron } from '@lastpass/components'
import { Theme } from '@lastpass/lastkit'
import { Divider } from '@lastpass/lastkit/components/Divider'
import { Dropdown, DropdownProps } from '@lastpass/lastkit/components/Dropdown'
import { DropdownListItem } from '@lastpass/lastkit/components/DropdownListItem'
import {
  BodyRegularStyle,
  BodySemiboldStyle,
  CaptionBoldStyle,
  CaptionRegularStyle
} from '@lastpass/lastkit/styles'

import { RecentManagedCompaniesRepository } from '@lastpass/admin-console-dependencies/repositories'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { CidOverrideParams } from '@lastpass/admin-console-dependencies/types/cid-override-params'
import { RecentCompaniesRepositoryType } from '@lastpass/admin-console-dependencies/types/recent-managed-companies'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

const ButtonContainer = styled.button<{
  isChildCompany: boolean
}>`
  background-color: ${props =>
    props.isChildCompany ? props.theme.colors.blue700 : 'inherit'};
  color: ${props => props.theme.colors.neutral};
  font-family: ${props => props.theme.fonts.primary};
  display: flex;
  padding: 8px 24px 8px 16px;
  height: 56px;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
`

const CompanyAvatar = styled.div<{ isChildCompany?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.isChildCompany
      ? props.theme.colors.neutral700
      : props.theme.colors.neutral100};
  width: 32px;
  height: 32px;
  border-radius: ${props => props.theme.radius.full};
`

const CompanyAvatarText = styled.span<{ isChildCompany: boolean }>`
  ${CaptionBoldStyle}
  color: ${props =>
    props.isChildCompany
      ? props.theme.colors.neutral
      : props.theme.colors.neutral800};
`
const CompanyText = styled.div`
  margin: 0px 8px;
  text-align: left;
`
const CompanyNameText = styled.p`
  ${BodySemiboldStyle}
  color: ${props => props.theme.colors.neutral};

`
const CompanyIdText = styled.p`
  ${CaptionRegularStyle}
  color: ${props => props.theme.colors.neutral};

`
const StyledDropdown = styled(Dropdown)`
  &::before {
    content: '';
    position: absolute;
    right: unset;
    left: 16px;
    width: 0;
    height: 0;
    overflow: hidden;
  }
`

const StyledDescription = styled.p`
  ${BodyRegularStyle}
  color: ${props => props.theme.colors.neutral700};
  margin: 7px 16px;
`
const getAvatarLetters = (companyName: string): string => {
  const words = companyName
    .toUpperCase()
    .trim()
    .split(' ')
  if (words.length === 1) {
    return words[0].charAt(0)
  } else {
    return words[0].charAt(0) + words[1].charAt(0)
  }
}

export const CompanyDropdown: React.FunctionComponent = () => {
  const {
    details: { name: managedCompanyName, accountNumber },
    cidOverride: managedCompanyId
  } = useSelector<AppState, CompanyInfoState>(
    (state: AppState) => state.companyInfo
  )

  const [expanded, setExpanded] = useState(false)

  const history = useHistory()

  const dispatch = useDispatch()
  const [recentCompanies, setRecentCompanies] = useState<CidOverrideParams[]>(
    []
  )

  const permissions = useContext(PermissionContext)
  const hasCreateManagedCompaniesPermission = permissions.requirePermission(
    AdminPermissions.addManagedCompany
  )
  const [
    shouldShowCreateCompanyButton,
    setShouldShowCreateCompanyButton
  ] = useState<boolean>(hasCreateManagedCompaniesPermission)

  const initializeRecentManagedCompaniesRepository = async (): Promise<void> => {
    const currentRepository: RecentCompaniesRepositoryType | null = await RecentManagedCompaniesRepository.get()

    if (currentRepository) {
      setRecentCompanies(currentRepository.recentManagedCompanies)
      setShouldShowCreateCompanyButton(
        currentRepository.isAllowedToCreateManagedCompanies
      )
    }
  }

  const renderCompanyListItems = (): JSX.Element[] | null => {
    if (recentCompanies.length) {
      return recentCompanies.reduce(
        (acc: JSX.Element[], { companyId, companyName }) => {
          if (companyId !== managedCompanyId) {
            acc.push(
              <DropdownListItem
                key={companyId}
                id={companyId}
                onClick={event => {
                  event.preventDefault()
                  setExpanded(false)
                  const cidOverride: CidOverrideParams = {
                    companyId: `${companyId}`,
                    companyName: companyName
                  }
                  RecentManagedCompaniesRepository.set(
                    cidOverride,
                    shouldShowCreateCompanyButton
                  )
                  window.open(`?cid=${companyId}`, '_blank')
                }}
              >
                {String(companyName).trim().length > 0
                  ? companyName
                  : companyId}
              </DropdownListItem>
            )
          }
          return acc
        },
        []
      )
    }
    return null
  }

  useEffect(() => {
    initializeRecentManagedCompaniesRepository()
  }, [])

  const Button: DropdownProps['button'] = ({ expanded, ...buttonProps }) => (
    <ButtonContainer
      {...buttonProps}
      isChildCompany={!!managedCompanyId}
      data-qa="CompanyDropdownButton"
    >
      <CompanyAvatar isChildCompany={!!managedCompanyId}>
        <CompanyAvatarText isChildCompany={!!managedCompanyId}>
          {managedCompanyName ? getAvatarLetters(managedCompanyName) : ''}
        </CompanyAvatarText>
      </CompanyAvatar>
      <CompanyText>
        <CompanyNameText>{managedCompanyName}</CompanyNameText>
        <CompanyIdText>{managedCompanyId || accountNumber}</CompanyIdText>
      </CompanyText>

      <Chevron
        color={Theme.colors.neutral}
        direction={expanded ? 'up' : 'down'}
      />
    </ButtonContainer>
  )
  return (
    <StyledDropdown
      button={Button}
      arrangerTopMargin={-10}
      arrangerLeft={managedCompanyId ? '' : '16px'}
      arrangerRight="auto"
      expanded={expanded}
      setExpanded={setExpanded}
      data-qa="CompanyDropdown"
    >
      {managedCompanyId && (
        <>
          <DropdownListItem
            icon={SignOutIcon}
            onClick={() => dispatch(companyInfoActions.removeCidOverride())}
            data-qa="CompanyDropdownBackToPrimaryButton"
          >
            <Trans>Back to primary account</Trans>
          </DropdownListItem>
          <Divider />
        </>
      )}
      {renderCompanyListItems()}
      {(!recentCompanies.length ||
        (managedCompanyId && recentCompanies.length === 1)) && (
        <StyledDescription>
          <Trans>Recently viewed companies will be shown here</Trans>
        </StyledDescription>
      )}
      <Divider />
      <DropdownListItem
        icon={PaperClipIcon}
        onClick={() => {
          setExpanded(false)
          if (managedCompanyId) {
            dispatch(companyInfoActions.removeCidOverride())
          }
          history.push('/home/managed-companies')
        }}
        data-qa="CompanyDropdownGoToManagedCompaniesButton"
      >
        <Trans>Go to Managed companies</Trans>
      </DropdownListItem>
      {shouldShowCreateCompanyButton && (
        <DropdownListItem
          icon={AddIcon}
          onClick={() => {
            setExpanded(false)
            if (managedCompanyId) {
              dispatch(
                companyInfoActions.removeCidOverride(
                  '/home/managed-companies/create'
                )
              )
            }
            history.push('/home/managed-companies/create')
          }}
          data-qa="CompanyDropdownCreateManagedCompanyButton"
        >
          <Trans>Create managed company</Trans>
        </DropdownListItem>
      )}
    </StyledDropdown>
  )
}

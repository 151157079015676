import React from 'react'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { DateRangeDropdown, SearchInput } from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { toDateStr } from '@lastpass/admin-console-dependencies/services/text-operations/date-string'
import { SamlResponsesQueryParams } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
`

export const SamlResponsesFilters: React.FunctionComponent = () => {
  const queryParams: SamlResponsesQueryParams = useQueryParams(
    Namespace.samlResponses
  )
  const updateQuery = useUpdateQuery(Namespace.samlResponses)

  return (
    <FilterContainer>
      <SearchInput
        data-qa="SamlResponsesSearch"
        onChange={event => {
          updateQuery({
            search: event.target.value || undefined
          })
        }}
        value={queryParams.search || ''}
        placeholder={msg`Search...`}
      />
      <DateRangeDropdown
        dataQa="SamlResponsesDateRangeDropdown"
        setRange={startDate => {
          if (startDate) {
            updateQuery({
              from: {
                label: 'startDate',
                value: toDateStr(startDate)
              }
            })
          }
        }}
        isDirectionRight
      />
    </FilterContainer>
  )
}

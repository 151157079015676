import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { MspBillingDetails } from '@lastpass/admin-console-dependencies/state/company/state'

export const SUCCESS = 'success'

const success = (mspBillingDetails: MspBillingDetails) =>
  createResponse(SUCCESS, mspBillingDetails)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('company/popup/information', 'GET')
    if (status === StatusCodes.OK && response) {
      const parsedResponse: MspBillingDetails = JSON.parse(response).content

      return success(parsedResponse)
    }

    throw new UnknownServiceError()
  }
}

import {
  ManagedCompanyOperationActions,
  ManagedCompanyOperationActionTypes
} from './actions'

export interface ManagedCompanyOperationsState {
  isExportManagedCompaniesLoading: boolean
  isExportUserCountsLoading: boolean
}

const initialState = {
  isExportManagedCompaniesLoading: false,
  isExportUserCountsLoading: false
}

export function managedCompanyOperationsReducer(
  state: ManagedCompanyOperationsState = initialState,
  action: ManagedCompanyOperationActions
) {
  switch (action.type) {
    case ManagedCompanyOperationActionTypes.SET_IS_EXPORT_MANAGED_COMPANIES_LOADING: {
      return {
        ...state,
        isExportManagedCompaniesLoading: action.payload
      }
    }
    case ManagedCompanyOperationActionTypes.SET_IS_EXPORT_USER_COUNTS_LOADING: {
      return {
        ...state,
        isExportUserCountsLoading: action.payload
      }
    }
  }
  return state
}

import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum UserDetailsActionTypes {
  GO_TO_GROUPS_PAGE = 'GO_TO_GROUPS_PAGE',
  GO_TO_APPS_PAGE = 'GO_TO_APPS_PAGE',
  GO_TO_POLICIES_PAGE = 'GO_TO_POLICIES_PAGE',
  GO_TO_SHARED_FOLDERS_PAGE = 'GO_TO_SHARED_FOLDERS_PAGE'
}

export const userDetailsActions = {
  goToGroupsPage: () =>
    track(createAction(UserDetailsActionTypes.GO_TO_GROUPS_PAGE), {
      segment: uacEvent('Redirect to Groups Overview Link Clicked', {
        Approach: 'User Detail'
      })
    }),
  goToAppsPage: () =>
    track(createAction(UserDetailsActionTypes.GO_TO_APPS_PAGE), {
      segment: uacEvent('Redirect to SSO Overview Link Clicked', {
        Approach: 'User Detail'
      })
    }),
  goToPoliciesPage: () =>
    track(createAction(UserDetailsActionTypes.GO_TO_POLICIES_PAGE), {
      segment: uacEvent('Redirect to Policies Overview Link Clicked', {
        Approach: 'User Detail'
      })
    }),
  goToSharedFoldersPage: () =>
    track(createAction(UserDetailsActionTypes.GO_TO_SHARED_FOLDERS_PAGE), {
      segment: uacEvent('Redirect to Shared Folders Link Clicked', {
        Approach: 'User Detail'
      })
    })
}

export type UserDetailsActions = ActionsUnion<typeof userDetailsActions>

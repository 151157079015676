import React, { FunctionComponent, useState } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import 'react-day-picker/lib/style.css'
import { BodyRegularStyle } from '../styles'
import { DateRangePicker } from './DateRangePicker'

interface DateRangeWithPresetsProps {
  setStartDate: (date: Date) => void
  setEndDate: (date?: Date) => void
  defaultStartDate?: Date
  defaultEndDate?: Date
  dataQa?: string
  maxDate?: Date
  minDate?: Date
  buttons?: React.ReactElement
  maxSelectableDays?: number
}

export const PresetsContainer = styled.div`
  width: 160px;
  border-right: 1px solid ${props => props.theme.colors.neutral100};
`

export const Preset = styled.div<{ active?: boolean }>`
  ${BodyRegularStyle}
  padding: 16px;
  background-color: ${props =>
    props.active ? props.theme.colors.blue100 : 'transparent'};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.blue100};
  }

  &:first-of-type {
    border-radius: ${props => props.theme.radius.pixel8} 0 0 0;
  }
`
const CalendarContainer = styled.div`
  min-width: 534px;
`

class Interval {
  constructor(
    text: React.ReactElement,
    startDate?: moment.Moment,
    endDate?: moment.Moment
  ) {
    this.text = text
    this.startDate = startDate || moment()
    this.endDate = endDate || moment()
    this.isCustom = !startDate && !endDate
  }

  readonly text: React.ReactElement
  readonly startDate: moment.Moment
  readonly endDate: moment.Moment
  readonly isCustom: boolean

  isSameInterval(anotherStartDate?: Date, anotherEndDate?: Date): boolean {
    if (this.isCustom) {
      return false
    }
    return (
      moment(anotherStartDate)
        .startOf('day')
        .diff(this.startDate, 'minutes') === 0 &&
      moment(anotherEndDate)
        .startOf('day')
        .diff(this.endDate, 'minutes') === 0
    )
  }
}

const today = moment().startOf('day')
const lastSevenDays = moment()
  .startOf('day')
  .subtract(7, 'days')
const lastTwoWeeks = moment()
  .startOf('day')
  .subtract(2, 'weeks')
const lastMonth = moment()
  .startOf('day')
  .subtract(30, 'days')

const intervals: Interval[] = [
  new Interval(
    (<Trans key={'sevenDays'}>Last 7 days</Trans>),
    lastSevenDays,
    today
  ),
  new Interval(
    (<Trans key={'twoWeeks'}>Last 2 weeks</Trans>),
    lastTwoWeeks,
    today
  ),
  new Interval((<Trans key={'lastMonth'}>Last month</Trans>), lastMonth, today),
  new Interval((<Trans key={'custom'}>Custom</Trans>))
]

export const DateRangeWithPresets: FunctionComponent<DateRangeWithPresetsProps> = props => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    props.defaultStartDate || lastSevenDays.toDate()
  )
  const [endDate, setEndDate] = useState<Date | undefined>(props.defaultEndDate)

  const setCustomStartDate = (date: Date) => {
    setStartDate(date)
    props.setStartDate(date)
  }

  const setCustomEndDate = (date?: Date) => {
    setEndDate(date)
    props.setEndDate(date)
  }

  const activeInterval =
    intervals.find(x => x.isSameInterval(startDate, endDate)) || intervals[3]

  return (
    <>
      <PresetsContainer>
        {intervals.map(interval => (
          <Preset
            key={intervals.indexOf(interval)}
            active={interval === activeInterval}
            onClick={() => {
              if (!interval.isCustom) {
                setCustomStartDate(interval.startDate.toDate())
                setCustomEndDate(interval.endDate.toDate())
              }
            }}
          >
            {interval.text}
          </Preset>
        ))}
      </PresetsContainer>
      <CalendarContainer>
        <DateRangePicker
          defaultEndDate={endDate}
          defaultStartDate={startDate}
          setStartDate={setCustomStartDate}
          setEndDate={setCustomEndDate}
          updateValues={true}
          maxDate={today.toDate()}
          minDate={props.minDate}
          maxSelectableDays={props.maxSelectableDays}
        />
        {props.buttons && props.buttons}
      </CalendarContainer>
    </>
  )
}

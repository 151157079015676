import React, { FC } from 'react'
import { ChartComponentProps, Doughnut } from 'react-chartjs-2'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Theme } from '@lastpass/lastkit'

const Container = styled.div`
  position: relative;
  height: 100%;
`

const InnerTextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const Percentage = styled.span`
  display: block;
  font-size: 28px;
  font-weight: 600;
  color: ${props => props.theme.colors.yellow};
  line-height: 40px;
`

const Label = styled.span`
  font-size: 12px;
  color: ${props => props.theme.colors.neutral700};
`

const chartSize = 165

const DoughnutContainer = styled.div`
  margin: 0 auto;
  width: ${chartSize}px;
`

interface ConsumedLicensesChartProps {
  consumedLicenses: number
  totalLicensesAllocated: number
}

export const ConsumedLicensesChart: FC<ConsumedLicensesChartProps> = ({
  consumedLicenses,
  totalLicensesAllocated
}) => {
  const unusedLicenses = totalLicensesAllocated - consumedLicenses

  const getConsumedLicensesPercentage = () => {
    if (totalLicensesAllocated === 0) {
      return 0
    }

    return Math.round((consumedLicenses / totalLicensesAllocated) * 100)
  }

  const commonDatasetProperties = {
    borderWidth: 0,
    hoverBorderWidth: 0
  }

  const emptyDataset = {
    data: [1],
    backgroundColor: [Theme.colors.grayBorder],
    hoverBackgroundColor: [Theme.colors.grayBorder],
    ...commonDatasetProperties
  }

  const dataset = {
    data: [consumedLicenses, unusedLicenses],
    backgroundColor: [Theme.colors.yellow, Theme.colors.grayBorder],
    hoverBackgroundColor: [Theme.colors.yellow, Theme.colors.grayBorder],
    ...commonDatasetProperties
  }

  const chartProps: ChartComponentProps = {
    data: {
      datasets: [totalLicensesAllocated === 0 ? emptyDataset : dataset]
    },
    options: {
      cutoutPercentage: 85,
      tooltips: {
        enabled: false
      },
      maintainAspectRatio: false,
      responsive: false
    },
    height: chartSize,
    width: chartSize
  }

  return (
    <Container>
      <DoughnutContainer>
        <Doughnut {...chartProps} />
      </DoughnutContainer>
      <InnerTextContainer>
        <Percentage data-qa="ConsumedLicensesChartPercentage">
          {`${getConsumedLicensesPercentage()}%`}
        </Percentage>
        <Label>
          <Trans>Consumed</Trans>
        </Label>
      </InnerTextContainer>
    </Container>
  )
}

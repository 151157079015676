import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'

import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'
import { MultifactorPageState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/state'
import {
  advancedPolicies,
  TeamsPolicy
} from '@lastpass/admin-console-dependencies/types/policy-teams'

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;

  hr {
    border-top: none;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
    margin: 24px 0;
  }
`

const PolicyContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.grayBorder};
  padding: 16px 16px 16px 48px;
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 16px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.grayBorder};
    box-sizing: border-box;
  }

  &::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.blue};
  }
`

const PolicyContainerActiveCss = props => css`
  background-color: ${props.colors.blue100};
  &::after {
    display: block;
  }
`

const PolicyDescriptionContainer = styled.div`
  margin-top: 16px;
`

export interface AddAdvancedPolicyProps {
  closeLink: string
}

function isActive(
  state: AdvancedPolicyDrawerState,
  policy: TeamsPolicy
): boolean {
  return state.policy !== null && policy.policyKey === state.policy.policyKey
}

function shouldDisplayPolicy(policyKey: string) {
  return advancedPolicies.includes(policyKey)
}

export const AddAdvancedPolicy: React.FunctionComponent<AddAdvancedPolicyProps> = ({
  closeLink
}) => {
  const advancedPolicyDrawerState: AdvancedPolicyDrawerState = useSelector(
    (state: AppState) => state.advancedPolicyDrawer
  )
  const multifactorPageState: MultifactorPageState = useSelector(
    (state: AppState) => state.multifactorPage
  )

  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <MainContainer>
      {multifactorPageState.AdditionalPolicies.filter(policy =>
        shouldDisplayPolicy(policy.policyKey)
      ).map(policy => (
        <PolicyContainer
          key={policy.policyKey}
          onClick={() =>
            dispatch(advancedPolicyDrawerActions.setPolicy(policy))
          }
          css={
            isActive(advancedPolicyDrawerState, policy)
              ? PolicyContainerActiveCss
              : {}
          }
          data-qa={'AdvancedPolicyItem_' + policy.policyTitle}
        >
          <Heading100>{policy.policyTitle}</Heading100>
          {isActive(advancedPolicyDrawerState, policy) && (
            <PolicyDescriptionContainer data-qa="AdvancedPolicyDescription">
              {policy.policyShortDescription}
            </PolicyDescriptionContainer>
          )}
        </PolicyContainer>
      ))}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => history.push(closeLink)}
            data-qa="DiscardButton"
          >
            <Trans>Cancel</Trans>
          </TextButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              const policy = advancedPolicyDrawerState.policy
              if (policy && policy.policyKey === 'lpauthenticatorgeoloc') {
                dispatch(
                  advancedPolicyDrawerActions.nextStep(
                    AdvancedPolicyDrawerSteps.map
                  )
                )
              } else {
                dispatch(
                  advancedPolicyDrawerActions.nextStep(
                    AdvancedPolicyDrawerSteps.configPolicy
                  )
                )
              }
            }}
            disabled={advancedPolicyDrawerState.policy === null}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </MainContainer>
  )
}

import React, { FC, ReactNode, SVGProps } from 'react'

import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const CountContainer = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
`
const TitleContainer = styled.div`
  color: ${props => props.theme.colors.neutral900};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`
const DescriptionContainer = styled.div`
  color: ${props => props.theme.colors.neutral700};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
const ActionContainer = styled.div`
  margin-top: 8px;
  height: 40px;
  display: flex;
  align-items: flex-end;
`

const CardIcon: FC<{ icon: FC<SVGProps<SVGSVGElement>> }> = ({ icon }) => {
  const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `
  const StyledIcon = styled(icon)`
    width: 48px;
    height: 48px;
  `
  return (
    <IconContainer data-qa="OpportunityCardIcon">
      <StyledIcon />
    </IconContainer>
  )
}

export interface OpportunityCardProps {
  icon: FC<SVGProps<SVGSVGElement>>
  title: ReactNode
  count?: number
  description: ReactNode
  action: ReactNode
  className?: string
}

export const OpportunityCard: FC<OpportunityCardProps> = ({
  icon,
  count,
  title,
  description,
  action,
  className
}) => (
  <Container className={className} data-qa="OpportunityCard">
    <Details>
      <CardIcon icon={icon} />
      {count !== undefined && (
        <CountContainer data-qa="OpportunityCardCount">{count}</CountContainer>
      )}
      <TitleContainer data-qa="OpportunityCardTitle">{title}</TitleContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
    </Details>
    <ActionContainer>{action}</ActionContainer>
  </Container>
)

import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { useCalculateChartRateThreshold } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-calculate-chart-rate-threshold'
import { ActionLinkCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionLinkCard'
import { DonutChart } from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/DonutChart'

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export interface SecurityScoreDonutChartCardProps {
  rate: number
  dataQa: string
}

export const SecurityScoreDonutChartCard: FC<SecurityScoreDonutChartCardProps> = ({
  rate,
  dataQa
}) => {
  const dispatch = useDispatch()
  const { colors, subtitle } = useCalculateChartRateThreshold(rate)

  return (
    <ActionLinkCard
      dataQa={dataQa}
      learnMoreAction={() =>
        window.open(
          'https://link.lastpass.com/uac-security-dashboard',
          '_blank',
          'noopener, noreferrer'
        )
      }
      showHistoryAction={() => {
        dispatch(
          globalActions.setDialog({
            type: 'security-score-history-dialog'
          })
        )
      }}
    >
      <ChartContainer>
        <DonutChart
          items={[
            {
              value: rate,
              backgroundColor: colors.primary
            },
            {
              value: 100 - rate,
              backgroundColor: colors.secondary
            }
          ]}
          dataQa={dataQa}
          percentageInfo={{
            value: rate,
            subtitle: subtitle
          }}
        />
      </ChartContainer>
    </ActionLinkCard>
  )
}

import React, { useRef, useState } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as DownloadIcon } from '@lastpass/assets/svg/admin-console/icon-download.svg'
import { DropdownListItem } from '@lastpass/lastkit/components/DropdownListItem'
import { BodySemiboldStyle } from '@lastpass/lastkit/styles'
import { CaptionRegularStyle } from '@lastpass/lastkit/styles'

const StyledIconWrapper = styled.div`
  height: 22px;
  width: 22px;
  color: ${props => props.theme.colors.white};
  background: #41536b;
  border-radius: 22px;
  cursor: default;
`
const StyledIcon = styled(DownloadIcon)`
  height: 14px;
  width: 22px;
  color: ${props => props.theme.colors.white};
`
export const Button = styled.button`
  ${BodySemiboldStyle}
  color: ${props => props.theme.colors.blue700};
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  outline: none;
`

const StyledSingleDropdownListItem = styled(DropdownListItem)`
  ${CaptionRegularStyle}
`

const RightStyledButton = styled(Button)`
  margin-right: 5px;
  &:focus {
    outline: auto;
  }
`

const StyledContainer = styled.span`
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
`

const StyledSingleDropdownList = styled.div`
  list-style: none;
  background: ${props => props.theme.colors.neutral};
  border: 1px solid ${props => props.theme.colors.neutral200};
  box-shadow: ${props => props.theme.elevations.elevation300};
  border-radius: ${props => props.theme.radius.pixel8};
  position: fixed;
  right: 10px;
  margin-top: -40px;
`

export interface UploadButtonProps<T> {
  onUpload: (selectorFiles: FileList, currentFormPendingUsers: T[]) => void
  currentFormRecords: T[]
}

export const ExtendableFormUploadButton = <T extends {}>({
  onUpload,
  currentFormRecords
}: UploadButtonProps<T>) => {
  const uploadRef = useRef<HTMLInputElement>(null)
  const [showToolTip, setShowToolTip] = useState(false)
  return (
    <>
      <input
        accept=".csv"
        id="myInput"
        type="file"
        ref={uploadRef}
        style={{ display: 'none' }}
        onChange={e => {
          e.target.files && onUpload(e.target.files, currentFormRecords)
        }}
      />
      <StyledContainer
        onMouseLeave={() => {
          setShowToolTip(false)
        }}
      >
        <RightStyledButton
          type="button"
          onClick={() => {
            uploadRef && uploadRef.current && uploadRef.current.click()
          }}
          data-qa={'UploadSCVButton'}
        >
          <Trans>Upload CSV</Trans>
        </RightStyledButton>
        <div
          onMouseOver={() => {
            setShowToolTip(true)
          }}
          data-qa={'DownloadCSVIcon'}
        >
          {showToolTip && (
            <StyledSingleDropdownList
              onMouseLeave={() => {
                setShowToolTip(false)
              }}
            >
              <StyledSingleDropdownListItem data-qa={'DownloadCSVButton'}>
                <a
                  href={
                    window.location.origin +
                    process.env.PUBLIC_URL +
                    '/assets/example-users.csv'
                  }
                >
                  <Trans>Download CSV template</Trans>
                </a>
              </StyledSingleDropdownListItem>
            </StyledSingleDropdownList>
          )}
          <StyledIconWrapper>
            <StyledIcon />
          </StyledIconWrapper>
        </div>
      </StyledContainer>
    </>
  )
}

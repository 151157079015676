import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (usersCSV: string) => createResponse(SUCCESS, usersCSV)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    queryPath: string,
    isLastLoginIncluded: boolean,
    isAccountStatusRenamed: boolean,
    isCreatedByCompanyColumnEnabled: boolean
  ) {
    const delimiter = queryPath ? '&' : '?'
    const featureFlagParams = new URLSearchParams({
      isLastLoginIncluded: String(isLastLoginIncluded),
      isAccountStatusRenamed: String(isAccountStatusRenamed),
      isCreatedByCompanyColumnEnabled: String(isCreatedByCompanyColumnEnabled)
    }).toString()
    const [response, status] = await fetch(
      `v3/users/export${queryPath}${delimiter}${featureFlagParams}`,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      return success(response)
    }
    throw new UnknownServiceError()
  }
}

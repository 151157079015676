import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { ReactComponent as DevicesIcon } from '@lastpass/assets/svg/admin-console/icon-devices.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerTable,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit/components'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { MfaTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['trustedDevices']['mfa'],
  typeof userDrawerActions
>

export const TrustedMfaDevicesTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: trustedDevices,
  actions
}) => {
  const dispatch = useDispatch()
  const pathParams = usePathParams()
  const queryParams = useQueryParams()

  useEffect(() => {
    dispatch(
      userDrawerActions.getUserTrustedDevices({
        query: queryParams,
        path: pathParams
      })
    )
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Registered devices</Trans>)

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )

  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()

  const loader = <Loading color="blue900" active={true} />

  if (!trustedDevices) {
    return loader
  } else {
    return (
      <DrawerTable
        qadata="UserLevelDevicesLastPAssMFAList"
        table={trustedDevices}
        loadingView={loader}
        columns={[
          {
            name: <Trans>Device</Trans>,
            renderer: (record: MfaTrustedDevice) => record.name
          },
          {
            name: <Trans>Last login</Trans>,
            noTooltip: true,
            renderer: function LastLoginRenderer(record: MfaTrustedDevice) {
              return (
                <TimeSinceActivity lastActivityTime={record.lastLoginTimeUtc} />
              )
            }
          },
          {
            name: <Trans>Status</Trans>,
            renderer: function StatusRenderer(record: MfaTrustedDevice) {
              return record.isEnabled ? (
                <Trans>Unlocked</Trans>
              ) : (
                <Trans>Locked</Trans>
              )
            }
          }
        ]}
        checkable={{
          setSelectedRecords: actions.setSelectedMfaDevices,
          removeSelectedRecords: actions.removeSelectedMfaDevices,
          addSelectedRecords: actions.addSelectedMfaDevices
        }}
        noRecordsView={
          <TableView
            icon={<DevicesIcon />}
            title={
              <Trans>This user has no registered LastPass MFA device</Trans>
            }
          />
        }
        actions={[
          {
            name: <Trans>Unlock devices</Trans>,
            callback: (records: MfaTrustedDevice[]) => {
              pathParams.id && actions.unlockMfaDevices(records, pathParams.id)
            },
            isValid: (records: MfaTrustedDevice[]) => {
              for (const record of records) {
                if (record.isEnabled == false) return true
              }
              return false
            },
            hidden:
              isNpPasswordlessFeatureDisabled && isPasswordlessPoliciesEmpty
          },
          {
            name: <Trans>Lock devices</Trans>,
            callback: (records: MfaTrustedDevice[]) => {
              pathParams.id && actions.lockMfaDevices(records, pathParams.id)
            },
            isValid: (records: MfaTrustedDevice[]) => {
              for (const record of records) {
                if (record.isEnabled == true) return true
              }
              return false
            },
            color: 'red700',
            hidden:
              isNpPasswordlessFeatureDisabled && isPasswordlessPoliciesEmpty
          }
        ]}
      />
    )
  }
}

export function createTrustedMfaDevicesTableComponent<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    TrustedMfaDevicesTableComponent,
    (state: TState) => state.usersDrawer.trustedDevices.mfa,
    userDrawerActions
  )
}

export type UserDrawerTrustedDevicesTable = ReturnType<
  typeof createTrustedMfaDevicesTableComponent
>

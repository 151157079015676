import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SaveTeamsPolicySwitchOnData } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const SUCCESS = 'success'
export const FAIL = 'fail'
export const NO_VALUE = 'no-value'
export const CANT_ADD_CONFIG = 'cant-add-config'
export const RESTRICTED_ADMIN_POLICY = 'restricted-admin-policy'
export const CONTROL_HASH_ITERATIONS_FOR_MASTER_PASSWORD_POLICY_VALIDATION_ERROR =
  'iteration-count-policy-validation-error'
export const LENGTH_OF_MASTER_PASSWORD_POLICY_VALIDATION_ERROR =
  'length-of-password-policy-validation-error'
export const VALUE_OF_VAULT_RE_ENCRYPTION_POLICY_VALIDATION_ERROR =
  'value-of-vault-re-encryption-policy-validation-error'

const controlHashIterationsForMasterPasswordPolicyValidationErrorResponse =
  'Changes not saved. Numbers only, 600000 or higher. No spaces, no punctuation.'

const lengthOfMasterPasswordPolicyValidationErrorResponse =
  'Changes not saved. Numbers only, 12 or higher.'

const valueOfAutomatedVaultReEncryptionValidationErrorResponse =
  'Changes not saved. Numbers only, between 30 and 365.'

export const success = () => createResponse(SUCCESS)
const fail = (error: string) => createResponse(FAIL, { error })
const noValue = () => createResponse(NO_VALUE)
const cantAddConfig = () => createResponse(CANT_ADD_CONFIG)
const restrictedAdminPolicy = () => createResponse(RESTRICTED_ADMIN_POLICY)
const controlHashIterationsForMasterPasswordPolicyValidationError = () =>
  createResponse(
    CONTROL_HASH_ITERATIONS_FOR_MASTER_PASSWORD_POLICY_VALIDATION_ERROR
  )
const lengthOfMasterPasswordPolicyValidationError = () =>
  createResponse(LENGTH_OF_MASTER_PASSWORD_POLICY_VALIDATION_ERROR)
const valueOfAutomatedVaultReEncryptionValidationError = () =>
  createResponse(VALUE_OF_VAULT_RE_ENCRYPTION_POLICY_VALIDATION_ERROR)

export const Responses = {
  success,
  fail,
  noValue,
  cantAddConfig,
  restrictedAdminPolicy,
  controlHashIterationsForMasterPasswordPolicyValidationError,
  lengthOfMasterPasswordPolicyValidationError,
  valueOfAutomatedVaultReEncryptionValidationError
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(policyInfo: SaveTeamsPolicySwitchOnData) {
    try {
      if (
        policyInfo.policyKey === 'restrictedadmins' &&
        policyInfo.policyData[0] &&
        policyInfo.policyData[0].inclusiveList
      ) {
        policyInfo.policyData[0].policyValue = policyInfo.policyData[0].inclusiveList
          .map(user => user.email)
          .join(',')
      }
      const [
        response,
        status
      ] = await teamsapi(
        `my-company/switchonpolicy/${policyInfo.policyKey}`,
        'POST',
        { policykey: policyInfo.policyKey, policyData: policyInfo.policyData }
      )
      if (status === StatusCodes.OK) {
        const saveSuccess = JSON.parse(response)
        if (saveSuccess.success) {
          return success()
        }
        if (saveSuccess.indexOf('Invalid value') > -1) {
          return noValue()
        } else if (saveSuccess.indexOf('multiple policyData') > -1) {
          return cantAddConfig()
        } else if (saveSuccess.indexOf('policyvalue is empty') > -1) {
          return restrictedAdminPolicy()
        } else if (
          saveSuccess ===
          controlHashIterationsForMasterPasswordPolicyValidationErrorResponse
        ) {
          return controlHashIterationsForMasterPasswordPolicyValidationError()
        } else if (
          saveSuccess === lengthOfMasterPasswordPolicyValidationErrorResponse
        ) {
          return lengthOfMasterPasswordPolicyValidationError()
        } else if (
          saveSuccess ===
          valueOfAutomatedVaultReEncryptionValidationErrorResponse
        ) {
          return valueOfAutomatedVaultReEncryptionValidationError()
        }
        return fail(saveSuccess)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

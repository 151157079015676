import { useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { useDecryptUrlsInSharedFoldersReport } from './use-decrypt-urls-in-shared-folders-report'
import { useDecryptUrlsInVaultsReport } from './use-decrypt-urls-in-vaults-report'

export const useDecryptSecurityReportsFactory = () => {
  const decryptUrlsInVaultsReport = useDecryptUrlsInVaultsReport()
  const decryptedSharedFoldersReport = useDecryptUrlsInSharedFoldersReport()

  const downloadReportName = useSelector(
    (state: AppState) => state.securityReportDrawer.downloadReportName
  )

  switch (downloadReportName) {
    case 'urls-in-vaults':
      return {
        parseAndSaveCsv: decryptUrlsInVaultsReport.parseAndSaveCsv,
        numberOfDecryptedCsvRows:
          decryptUrlsInVaultsReport.numberOfDecryptedCsvRows
      }
    case 'urls-in-shared-folders':
      return {
        parseAndSaveCsv: decryptedSharedFoldersReport.parseAndSaveCsv,
        numberOfDecryptedCsvRows:
          decryptedSharedFoldersReport.numberOfDecryptedCsvRows
      }
  }
}

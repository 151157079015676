import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  Checkbox,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'
import { LocationLink } from '@lastpass/routing'

import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { URLRuleOptionsForm } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/state'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  width: 100%;
`

const StyledSpan = styled.span`
    ${BodyRegularStyle}
    color: ${props => props.theme.colors.neutral900};
    margin-left: 10px;
`

const StyledExample = styled.div`
  ${BodyRegularStyle}
  margin-top: 20px;
  color: ${props => props.theme.colors.neutral900};
`

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`

const BoldSpan = styled.span`
  font-weight: bold;
`

export const UrlRuleOptionsDrawerForm: React.FunctionComponent = () => {
  const urlRuleOptionsFormState: URLRuleOptionsForm = useSelector(
    (state: AppState) => state.urlRuleOptionsForm.urlRuleOptionsForm
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(urlRulesActions.getURLRuleOptions())
  }, [dispatch])

  const [formTouched, setFormTouched] = useState(false)

  const history = useHistory()

  if (!urlRuleOptionsFormState || urlRuleOptionsFormState.isLoading) {
    return <Loading color="blue900" active={true} />
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{
            isUrlRulesCaseInsensitive:
              urlRuleOptionsFormState.isUrlRulesCaseInsensitive
          }}
          onSubmit={values => {
            dispatch(
              urlRulesActions.editURLRuleOptions({
                isUrlRulesCaseInsensitive: values.isUrlRulesCaseInsensitive,
                isLoading: false
              })
            )
            history.push('/advanced/enterpriseOptions/urlRules')
          }}
        >
          {formikProps => {
            return (
              <>
                <FormContainer>
                  <InputContainer>
                    <StyledCheckbox
                      data-qa="TreatAsCaseSensativeCheckBox"
                      name="treatAsCaseInsensitive"
                      checked={formikProps.values.isUrlRulesCaseInsensitive}
                      onChange={() => {
                        formikProps.setFieldValue(
                          'isUrlRulesCaseInsensitive',
                          !formikProps.values.isUrlRulesCaseInsensitive
                        )
                        setFormTouched(!formTouched)
                      }}
                    />
                    <StyledSpan>
                      <Trans>Treat all URL rules as case-insensitive</Trans>
                    </StyledSpan>
                  </InputContainer>
                  <StyledExample>
                    <Trans>
                      For example: <BoldSpan>example.com/test/</BoldSpan> will
                      match both
                      <BoldSpan> https://example.com/test/</BoldSpan> and
                      <BoldSpan> https://example.com/TEST/</BoldSpan>
                    </Trans>
                  </StyledExample>
                </FormContainer>

                <FooterContainer>
                  <DrawerButtonsContainer>
                    <LocationLink to="/advanced/enterpriseOptions/urlRules">
                      <TextButton
                        data-qa="CancelButton"
                        css={css`
                          margin-right: 10px;
                        `}
                      >
                        <Trans>Cancel</Trans>
                      </TextButton>
                    </LocationLink>

                    <PrimaryButton
                      data-qa="SaveButton"
                      disabled={!formTouched}
                      css={css`
                        margin-right: 5px;
                      `}
                      type="button"
                      onClick={() => {
                        formikProps.handleSubmit()
                      }}
                    >
                      <Trans>Save changes</Trans>
                    </PrimaryButton>
                  </DrawerButtonsContainer>
                </FooterContainer>
              </>
            )
          }}
        </Formik>
      </>
    )
  }
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers as FormikActions,
  FormikProps
} from 'formik'
import { ObjectSchema } from 'yup'

import { Loading } from '@lastpass/components'
import { CardContainer } from '@lastpass/lastkit/components/CardContainer'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { PrimaryButton } from '@lastpass/lastkit/components/PrimaryButton'
import { SearchInput } from '@lastpass/lastkit/components/SearchInput'
import { TableContainer } from '@lastpass/lastkit/components/Table'
import { TextButton } from '@lastpass/lastkit/components/TextButton'
import { LocationLink } from '@lastpass/routing'
import { useUnmountEffect } from '@lastpass/ui'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { addUsersDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'

import { ExtendableFormErrorToggle } from './ExtendableFormErrorToggle'
import { ExtendableFormFieldArray } from './ExtendableFormFieldArray'

export interface ExtendableFormProps<T> {
  columns: {
    name: string
    width?: string
    required?: boolean
  }[]
  initialRecords: T[]
  onSubmit: (
    values: { records: T[] },
    formikActions?: FormikActions<{ records: T[] }>
  ) => void
  checkedRecords: T[]
  checkable: {
    addSelectedRecords: (records: T[]) => void
    removeSelectedRecords: (records: T[]) => void
    setSelectedRecords: (records: T[]) => void
  }
  yupSchema: ObjectSchema<object>
  licensesAvailable: number | undefined
  cancelLink: string
}
export interface UserRecord {
  email?: string
  firstName?: string
  lastName?: string
}

const StyledSearchInput = styled(SearchInput)`
  margin-bottom: 16px;
`

const SearchContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTableContainer = styled(TableContainer)`
  overflow-x: hidden;
`

export const ExtendableForm = <T extends {}>(
  extendableFormProps: ExtendableFormProps<T>
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterToErrorToggle, setFilterToErrorToggle] = useState(false)
  const form = useRef<FormikProps<{ records: T[] }>>(null)
  const [shouldValidate, setShouldValidate] = useState(false)
  const [duplicateRecords, setDuplicateRecords] = useState([] as T[])
  const [searchInputFocused, setSearchInputFocused] = useState(false)
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()
  const dispatch = useDispatch()

  const handleDuplicates = useCallback(() => {
    if (form.current) {
      const keys: string[] = extendableFormProps.initialRecords.map(
        (value: T) => {
          return value[Object.keys(value)[0]]
            ? value[Object.keys(value)[0]]
            : ''
        }
      )
      const duplicateRecordsInner: T[] = []
      extendableFormProps.initialRecords.forEach(record => {
        const count = keys.reduce(
          (previousValue: number, currentValue: string) =>
            previousValue +
            (currentValue === record[Object.keys(record)[0]] ? 1 : 0),
          0
        )
        if (count > 1) {
          duplicateRecordsInner.push(record)
        }
      })
      setDuplicateRecords(duplicateRecordsInner)
    }
  }, [extendableFormProps.initialRecords])

  useEffect(() => {
    const initialLoadWithSingleEmptyRecord =
      extendableFormProps.initialRecords.length === 1 &&
      extendableFormProps.initialRecords[0]['email'] === undefined

    if (
      form.current &&
      !initialLoadWithSingleEmptyRecord &&
      !searchInputFocused
    ) {
      handleDuplicates()
      const totalRecords = extendableFormProps.initialRecords.length
      //last empty field with focus should not have validation
      const recordsWithoutLastEmptyRecord = extendableFormProps.initialRecords.filter(
        ({ email }: UserRecord, index) =>
          !(email === undefined && index === totalRecords - 1)
      )
      form.current
        .validateForm({ records: recordsWithoutLastEmptyRecord })
        .then((value: FormikErrors<{ records: T[] }>) => {
          if (form.current) {
            form.current.setErrors(value)
          }
        })
    }
    setShouldValidate(false)
  }, [
    extendableFormProps.initialRecords,
    shouldValidate,
    searchInputFocused,
    handleDuplicates
  ])

  useUnmountEffect(() => {
    dispatch(addUsersDrawerActions.reset())
  })

  const isAnyTextInEmailFields = !!extendableFormProps.initialRecords.filter(
    record => !!record['email']
  ).length

  return (
    <>
      {isLoading && <Loading color="blue900" active={true} />}
      <Formik
        initialValues={{
          records: extendableFormProps.initialRecords
        }}
        enableReinitialize={true}
        onSubmit={() => {}}
        validationSchema={extendableFormProps.yupSchema}
        validateOnChange={false}
        validateOnBlur={isAnyTextInEmailFields}
        isInitialValid={true}
        innerRef={form}
      >
        {formikprops => {
          return (
            <>
              <SearchContainer>
                <StyledSearchInput
                  disabled={formikprops.values.records.length <= 1}
                  value={searchTerm}
                  placeholder={msg`Search users`}
                  onChange={e => {
                    setSearchTerm(e.target.value)
                    setSearchInputFocused(true)
                  }}
                  data-qa={'AddUsersSearchField'}
                />
                <ExtendableFormErrorToggle
                  initialLoad={
                    extendableFormProps.initialRecords.length == 1 &&
                    extendableFormProps.initialRecords.filter(
                      value => Object.keys(value).length !== 0
                    ).length == 0
                  }
                  toggled={filterToErrorToggle}
                  duplicateRecords={duplicateRecords}
                  toggle={() => {
                    setFilterToErrorToggle(!filterToErrorToggle)
                  }}
                  totalRecords={formikprops.values.records}
                  errorRecords={
                    formikprops.errors.records
                      ? typeof formikprops.errors.records === 'string'
                        ? []
                        : (formikprops.errors.records as string[])
                      : []
                  }
                  licensesAvailable={extendableFormProps.licensesAvailable}
                />
              </SearchContainer>
              <Form
                onChange={() => {
                  if (extendableFormProps.licensesAvailable) {
                    setIsBlockingPromptEnabled(true)
                  }
                }}
              >
                <CardContainer>
                  <StyledTableContainer data-qa={'AddUserDrawerList'}>
                    <ExtendableFormFieldArray
                      searchInputFocused={searchInputFocused}
                      setSearchInputFocused={setSearchInputFocused}
                      initialLoad={
                        extendableFormProps.initialRecords.length == 1 &&
                        extendableFormProps.initialRecords.filter(
                          value => Object.keys(value).length !== 0
                        ).length == 0
                      }
                      checkable={extendableFormProps.checkable}
                      duplicateRecords={duplicateRecords}
                      setShouldValidate={setShouldValidate}
                      removeRecord={(record: T | T[]) => {
                        const recordsToRemove: T[] = Array.isArray(record)
                          ? record
                          : [record]
                        const newRecords = extendableFormProps.initialRecords.filter(
                          initialRecord =>
                            !recordsToRemove.includes(initialRecord)
                        )
                        if (
                          newRecords.length !==
                          extendableFormProps.initialRecords.length
                        ) {
                          dispatch(
                            addUsersDrawerActions.setPendingAddUsers(newRecords)
                          )
                          setShouldValidate(true)
                        }
                      }}
                      addRecord={({
                        email,
                        firstName,
                        lastName
                      }: UserRecord) => {
                        dispatch(
                          addUsersDrawerActions.setPendingAddUsers([
                            ...extendableFormProps.initialRecords,
                            {
                              email: email || undefined,
                              firstName: firstName || undefined,
                              lastName: lastName || undefined
                            }
                          ])
                        )
                        setShouldValidate(true)
                      }}
                      setRecordField={(
                        row: number,
                        column: string,
                        value: string
                      ) => {
                        if (extendableFormProps.initialRecords[row]) {
                          const newRecords = extendableFormProps.initialRecords
                          newRecords[row][column] = value
                          dispatch(
                            addUsersDrawerActions.setPendingAddUsers(newRecords)
                          )
                        }
                        column === 'email' && handleDuplicates()
                      }}
                      checkedRecords={extendableFormProps.checkedRecords}
                      records={extendableFormProps.initialRecords}
                      searchTerm={searchTerm}
                      columns={extendableFormProps.columns}
                      errors={formikprops.errors}
                      showOnlyErrors={filterToErrorToggle}
                      remainingLicenses={extendableFormProps.licensesAvailable}
                      setFilterToError={setFilterToErrorToggle}
                    />
                  </StyledTableContainer>
                </CardContainer>
                <StyledFormFooter>
                  <ButtonsContainer>
                    <LocationLink to={extendableFormProps.cancelLink}>
                      <TextButton
                        css={css`
                          margin-right: 16px;
                        `}
                        key={'Cancel'}
                        type="button"
                        data-qa={'CancelButton'}
                      >
                        <Trans>Cancel</Trans>
                      </TextButton>
                    </LocationLink>
                    <PrimaryButton
                      id={'ContinueButton'}
                      type="button"
                      key={'Continue'}
                      onClick={e => {
                        if (
                          formikprops.values.records[0] &&
                          formikprops.values.records[0]['email'] !==
                            undefined &&
                          (!formikprops.errors || !formikprops.errors.records)
                        ) {
                          e.currentTarget.disabled = true
                          setIsLoading(true)
                          extendableFormProps.onSubmit(formikprops.values)
                          setIsBlockingPromptEnabled(false)
                        }
                      }}
                      disabled={
                        (extendableFormProps.licensesAvailable &&
                          extendableFormProps.initialRecords.length >
                            extendableFormProps.licensesAvailable) ||
                        !!duplicateRecords.length
                      }
                      data-qa={'ContinueButton'}
                    >
                      <Trans>Add users</Trans>
                    </PrimaryButton>
                  </ButtonsContainer>
                </StyledFormFooter>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { Heading300 } from '@lastpass/lastkit'

export const SamlResponsesPageHeader: React.FunctionComponent = () => (
  <Heading300 data-qa="SamlResponsesPageHeader">
    <Trans>SAML Responses</Trans>
  </Heading300>
)

import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicyAssignUsersAndGroupsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-assign-users/actions'
import { GeneralPolicyUsersAndGroupsConfigFormData } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'

export const getPolicyConfigData = (state: AppState) =>
  state.generalPolicyAssignUsersAndGroups.policyAssignUsersAndGroups
    .policyConfigFormData

export function createCheckPolicyAssignAppliesToGeneralPolicySaga() {
  return function* checkPolicyAssignAppliesToGeneralPolicySaga(
    action: ReturnType<
      typeof generalPolicyAssignUsersAndGroupsActions.checkGeneralPolicyAppliesTo
    >
  ) {
    const formPolicyAppliesTo = action.payload.policyAppliesTo
    const routingInfo = action.payload.params

    try {
      const policyConfigData: GeneralPolicyUsersAndGroupsConfigFormData = yield select(
        getPolicyConfigData
      )
      const policyAppliesTo = policyConfigData.appliesTo
      yield put(
        generalPolicyAssignUsersAndGroupsActions.setGeneralPolicyCurrentlyAppliesTo(
          formPolicyAppliesTo
        )
      )
      if (formPolicyAppliesTo !== policyAppliesTo) {
        yield put(
          generalPolicyAssignUsersAndGroupsActions.resetGeneralPolicyTables()
        )
      } else {
        yield put(
          generalPolicyAssignUsersAndGroupsActions.getGeneralPolicyAssignUsersAndGroups(
            {
              ...routingInfo
            }
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

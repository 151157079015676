import React from 'react'

import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'

import { Assign } from './drawerSteps/AssignUsersAndGroups/Assign'
import { AssignNewEntity } from './drawerSteps/AssignUsersAndGroups/AssignNewEntity'
import { ConfigureMfaMethod } from './drawerSteps/ConfigureMfaMethod'
import { MultifactorAllSetPage } from './drawerSteps/MultifactorAllSetPage'
import { MultifactorDescription } from './drawerSteps/MultifactorDescription'
import { SelectMfaMethod } from './drawerSteps/SelectMfaMethod'

const getStep = (step, close) => {
  const closeLink = `${close}${window.location.search}`
  switch (step) {
    case MultifactorDrawerSteps.description:
      return <MultifactorDescription closeLink={closeLink} />
    case MultifactorDrawerSteps.selectMfaMethod:
      return <SelectMfaMethod closeLink={closeLink} />
    case MultifactorDrawerSteps.configureMfa:
      return <ConfigureMfaMethod closeLink={closeLink} />
    case MultifactorDrawerSteps.assign:
      return <Assign closeLink={closeLink} />
    case MultifactorDrawerSteps.assignNewEntities:
      return <AssignNewEntity closeLink={closeLink} />
    case MultifactorDrawerSteps.allSet:
      return <MultifactorAllSetPage closeLink={closeLink} />
  }
}

export const MultifatorDrawerPage = props => {
  const renderedStep = getStep(props.step, props.closeLink)
  return <>{renderedStep}</>
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { Overlay } from '@lastpass/admin-console-dependencies/ui/common/OverlayComponent'

export interface AdvancedGeneralReportsPageOverlayProps {
  url: string
}

export const AdvancedGeneralReportsPageOverlay: React.FunctionComponent<AdvancedGeneralReportsPageOverlayProps> = ({
  url
}) => {
  const history = useHistory()

  const StyledOverlay = styled(Overlay)<{ decreaseHeight?: number }>`
    z-index: 60;
    top: ${props =>
      props.decreaseHeight ? `${props.decreaseHeight + 56}px` : '56px'};
    left: 0;
    width: calc(100vw - 588px);
    height: ${props =>
      props.decreaseHeight
        ? `calc(100vh - ${props.decreaseHeight + 56}px)`
        : 'calc(100vh - 56px)'};
  `

  const closeDrawer = () => {
    history.push(url)
  }

  const styledOverlayShouldBeVisible = new RegExp(
    `${url}/(filter|save|\\d+)`
  ).test(location.pathname)

  return styledOverlayShouldBeVisible ? (
    <StyledOverlay onClick={closeDrawer} decreaseHeight={0} />
  ) : null
}

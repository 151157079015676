import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserDetails } from '@lastpass/admin-console-dependencies/types/user-details'

export const SUCCESS = 'success'

const success = (response: UserDetails[]) =>
  createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(queryPath: string) {
    const [response, status] = await fetch(
      'reports/userdetails' + queryPath,
      'GET'
    )

    if (status !== StatusCodes.OK || !response) {
      throw new UnknownServiceError()
    }

    const responseData = JSON.parse(response)
    const userDetails = responseData.content.items
    return success(userDetails)
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as ProvisionedUsersHandler from '@lastpass/admin-console-dependencies/sagas/users/view/operations/helpers/handle-provisioned-users'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { DisableUsersDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { getCurrentUrlFilters, invokeApi } from './operation-base'

export const totalSelectedUserCountSelector = (state: AppState) =>
  state.usersList.table.totalResults

export function createDisableUserSaga(userService: UACServices.Services) {
  return function* disableUserSaga(
    action: ReturnType<typeof userOperationActions.disableUsers>
  ) {
    try {
      let totalSelectedUsersCount = yield select(totalSelectedUserCountSelector)
      let { users, filter } = action.payload.userOperationPayload
      const { isAllSelected } = action.payload.userOperationPayload

      if (isAllSelected) {
        const handlerResult = yield call(
          ProvisionedUsersHandler.handleAllUserSelection,
          users,
          filter,
          totalSelectedUsersCount,
          userService.userCount
        )

        if (!handlerResult.shouldProceed) {
          return
        }

        filter = handlerResult.usersFilter
        totalSelectedUsersCount = handlerResult.selectedUsersCount
      } else {
        users = yield call(
          ProvisionedUsersHandler.handleSelectionWithinPage,
          users,
          totalSelectedUsersCount
        )
      }

      if (users === undefined) {
        return
      }

      const dialog: DisableUsersDialog = {
        type: 'disable-users-dialog',
        users,
        isAllSelected,
        allUserCount: totalSelectedUsersCount
      }

      yield put(globalActions.setDialog(dialog))

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users have been disabled.`,
            MultipleUsersSuccess: msg`The selected users have been disabled.`,
            SingleUserFail:
              users.length > 0
                ? msg`User hasn't been disabled: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User has been disabled: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users haven't been disabled.`
          },
          'disable',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.DISABLE_USERS,
          filter
        )
        const currentUrlFilters = yield select(getCurrentUrlFilters)
        yield put(push(`/users/view${currentUrlFilters.location.search || ''}`))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

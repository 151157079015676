import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { TeamsPolicyGroup } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getMultifactorDrawerState = (state: AppState) =>
  state.multifactorDrawer

export function createGetAvailableGroupsSaga(
  policyService: UACServices.Services
) {
  return function* getAvailableGroupsSaga(
    action: ReturnType<typeof multifactorDrawerActions.getAvailableGroups>
  ) {
    const { searchKey } = action.payload
    yield put(multifactorDrawerActions.setAvailableGroupsLoading(true))
    try {
      const searchGroupsResult: UACServices.GeneralPolicyGroupsSearchAPI.Responses = yield call(
        policyService.generalPolicySearchGroups,
        searchKey
      )
      if (
        searchGroupsResult.type ===
        UACServices.GeneralPolicyGroupsSearchAPI.SUCCESS
      ) {
        const multifactorDrawerState: MultifactorDrawerState = yield select(
          getMultifactorDrawerState
        )
        const searchGroupsListResponse =
          searchGroupsResult.body.policyGroupsList
        const searchGroupsList: TeamsPolicyGroup[] = searchGroupsListResponse.map(
          groupResponse => {
            return {
              id: groupResponse.cgid,
              name: groupResponse.name,
              userscount: parseInt(groupResponse.userscount, 10)
            }
          }
        )
        const filteredResultList = searchGroupsList.filter(
          groupFromResult =>
            multifactorDrawerState.assignFormState.filteredGroups.results.filter(
              groupFromState => groupFromState.id == groupFromResult.id
            ).length === 0
        )
        yield put(
          multifactorDrawerActions.setAvailableGroups(filteredResultList)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(multifactorDrawerActions.setAvailableGroupsLoading(false))
    }
  }
}

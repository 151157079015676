import { msg } from '@lingui/macro'

import {
  AdminActivityTypes,
  UserActivityTypes
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-types'

export const userActivityReportTranslationMap = {
  [UserActivityTypes.all]: msg`All event types`,
  [UserActivityTypes.addToSharedFolder]: msg`Add to shared folder`,
  [UserActivityTypes.createdLastPassAccount]: msg`Created LastPass account`,
  [UserActivityTypes.createdSharedFolder]: msg`Created shared folder`,
  [UserActivityTypes.dataExport]: msg`Data export`,
  [UserActivityTypes.dataImport]: msg`Data import`,
  [UserActivityTypes.dataPrinted]: msg`Data printed`,
  [UserActivityTypes.deletedSharedFolder]: msg`Deleted shared folder`,
  [UserActivityTypes.deletedSites]: msg`Deleted sites`,
  [UserActivityTypes.disabledGoogleAuthenticator]: msg`Disabled Google Authenticator`,
  [UserActivityTypes.disabledGrid]: msg`Disabled Grid`,
  [UserActivityTypes.disabledMicrosoftAuthenticator]: msg`Disabled Microsoft Authenticator`,
  [UserActivityTypes.disabledMultifactorAuthentication]: msg`Disabled multifactor authentication`,
  [UserActivityTypes.disabledToopher]: msg`Disabled Toopher`,
  [UserActivityTypes.disabledYubiKey]: msg`Disabled YubiKey`,
  [UserActivityTypes.editSecureNote]: msg`Edit secure note`,
  [UserActivityTypes.failedLoginAttempt]: msg`Failed login attempt`,
  [UserActivityTypes.formFilled]: msg`Form filled`,
  [UserActivityTypes.historyCleared]: msg`History cleared`,
  [UserActivityTypes.iterationsChanged]: msg`Iterations changed`,
  [UserActivityTypes.login]: msg`Log in`,
  [UserActivityTypes.loginVerificationEmailSent]: msg`Login verification email sent`,
  [UserActivityTypes.masterPasswordChanged]: msg`Master password changed`,
  [UserActivityTypes.masterPasswordReverted]: msg`Master password reverted`,
  [UserActivityTypes.moveFromSharedFolder]: msg`Move from shared folder`,
  [UserActivityTypes.moveToSharedFolder]: msg`Move to shared folder`,
  [UserActivityTypes.multifactorEnabled]: msg`Multifactor enabled`,
  [UserActivityTypes.openSecureNote]: msg`Open secure note`,
  [UserActivityTypes.passwordChanged]: msg`Password changed`,
  [UserActivityTypes.permanentlyDeletedSharedFolder]: msg`Permanently deleted shared folder`,
  [UserActivityTypes.renamedSharedFolder]: msg`Renamed shared folder`,
  [UserActivityTypes.restoredSharedFolder]: msg`Restored shared folder`,
  [UserActivityTypes.SAMLLogin]: msg`SAML login`,
  [UserActivityTypes.siteAdded]: msg`Site added`,
  [UserActivityTypes.siteDeleted]: msg`Site deleted`,
  [UserActivityTypes.usernameChanged]: msg`Username changed`,
  [UserActivityTypes.encryptionKeyRotated]: msg`Encryption key rotated`,
  [UserActivityTypes.lastpasslogin]: msg`LastPass login`
}

export const adminActivityReportTranslationMap = {
  [AdminActivityTypes.all]: msg`All event types`,
  [AdminActivityTypes.addGlobalEquivDomain]: msg`Add global equiv domain`,
  [AdminActivityTypes.addGlobalNeverURLs]: msg`Add global never URLs`,
  [AdminActivityTypes.addGlobalOnlyURLs]: msg`Add global only URLs`,
  [AdminActivityTypes.addPolicy]: msg`Add policy`,
  [AdminActivityTypes.addToGroup]: msg`Add to group`,
  [AdminActivityTypes.addToSharedFolder]: msg`Add to shared folder`,
  [AdminActivityTypes.addedUserToSAMLMap]: msg`Added user to SAML map`,
  [AdminActivityTypes.addingUserToGroup]: msg`Adding user to group`,
  [AdminActivityTypes.addingUserToSharedFolder]: msg`Adding user to shared folder`,
  [AdminActivityTypes.authenticationFailed]: msg`Authentication failed`,
  [AdminActivityTypes.changeGroupPending]: msg`Change group pending`,
  [AdminActivityTypes.changePasswordFailed]: msg`Change password failed`,
  [AdminActivityTypes.changePasswordSuccess]: msg`Change password success`,
  [AdminActivityTypes.createGroup]: msg`Create group`,
  [AdminActivityTypes.createRole]: msg`Create role`,
  [AdminActivityTypes.createdPendingUser]: msg`Created pending user`,
  [AdminActivityTypes.creatingUser]: msg`Creating user`,
  [AdminActivityTypes.deactivatedUser]: msg`Deactivated user`,
  [AdminActivityTypes.deactivatingUser]: msg`Deactivating user`,
  [AdminActivityTypes.deleteGroup]: msg`Delete group`,
  [AdminActivityTypes.deletePolicy]: msg`Delete policy`,
  [AdminActivityTypes.deleteRole]: msg`Delete role`,
  [AdminActivityTypes.deletedSAMLMapEntry]: msg`Deleted SAML map entry`,
  [AdminActivityTypes.deletingUser]: msg`Deleting user`,
  [AdminActivityTypes.destroyAllSessions]: msg`Destroy all sessions`,
  [AdminActivityTypes.disabled]: msg`Disabled`,
  [AdminActivityTypes.disabledSAMLMapEntry]: msg`Disabled SAML map entry`,
  [AdminActivityTypes.editGroup]: msg`Edit group`,
  [AdminActivityTypes.editPolicy]: msg`Edit policy`,
  [AdminActivityTypes.editRole]: msg`Edit role`,
  [AdminActivityTypes.employeeAccountCreated]: msg`Employee account created`,
  [AdminActivityTypes.employeeAccountDeleted]: msg`Employee account deleted`,
  [AdminActivityTypes.employeeAccountDisabled]: msg`Employee account disabled`,
  [AdminActivityTypes.employeeAccountRequiresActivation]: msg`Employee account requires activation`,
  [AdminActivityTypes.employeeInvited]: msg`Employee invited`,
  [AdminActivityTypes.enableDuo]: msg`Enable Duo`,
  [AdminActivityTypes.enableSecurID]: msg`Enable SecurID`,
  [AdminActivityTypes.enabledSAMLMapEntry]: msg`Enabled SAML map entry`,
  [AdminActivityTypes.getSharedFolderData]: msg`Get shared folder data`,
  [AdminActivityTypes.groupAddition]: msg`Group addition`,
  [AdminActivityTypes.groupRemoval]: msg`Group removal`,
  [AdminActivityTypes.inviteAllMatching]: msg`Invite all matching`,
  [AdminActivityTypes.loginToAdminConsole]: msg`Login to admin console`,
  [AdminActivityTypes.makeAdmin]: msg`Make admin`,
  [AdminActivityTypes.masterPasswordResetBySuperAdmin]: msg`Master password reset by super admin`,
  [AdminActivityTypes.masterPasswordReuse]: msg`Master password reuse`,
  [AdminActivityTypes.provisioningCredentialChanged]: msg`Provisioning credential changed`,
  [AdminActivityTypes.provisioningServiceAdded]: msg`Provisioning service added`,
  [AdminActivityTypes.provisioningServiceRemoved]: msg`Provisioning service removed`,
  [AdminActivityTypes.provisioningSyncHappened]: msg`Provisioning sync happened`,
  [AdminActivityTypes.pushingSitesToUser]: msg`Pushing sites to user`,
  [AdminActivityTypes.reinviteUser]: msg`Re-invite user`,
  [AdminActivityTypes.reactivatedUser]: msg`Reactivated user`,
  [AdminActivityTypes.reactivatingUser]: msg`Reactivating user`,
  [AdminActivityTypes.removeAdmin]: msg`Remove admin`,
  [AdminActivityTypes.removeFromGroup]: msg`Remove from group`,
  [AdminActivityTypes.removeGlobalEquivDomain]: msg`Remove global equiv domain`,
  [AdminActivityTypes.removeGlobalNeverURLs]: msg`Remove global never URLs`,
  [AdminActivityTypes.removeGlobalOnlyURLs]: msg`Remove global only URLs`,
  [AdminActivityTypes.removeSiteFromUser]: msg`Remove site from user`,
  [AdminActivityTypes.removeUserFromCompany]: msg`Remove user from company`,
  [AdminActivityTypes.removedFromSharedFolder]: msg`Removed from shared folder`,
  [AdminActivityTypes.removingGroup]: msg`Removing group`,
  [AdminActivityTypes.removingUserFromGroup]: msg`Removing user from group`,
  [AdminActivityTypes.removingUserFromLastPassBusiness]: msg`Removing user from LastPass Business`,
  [AdminActivityTypes.renameGroup]: msg`Rename group`,
  [AdminActivityTypes.reporting]: msg`Reporting`,
  [AdminActivityTypes.requirePasswordChange]: msg`Require password change`,
  [AdminActivityTypes.resetMasterPassword]: msg`Reset master password`,
  [AdminActivityTypes.resetMFASharedSecrets]: msg`Reset MFA shared secrets`,
  [AdminActivityTypes.resetMFASharedSecretsFailed]: msg`Reset MFA shared secrets failed`,
  [AdminActivityTypes.resetMFASharedSecretsSuccessful]: msg`Reset MFA shared secrets successful`,
  [AdminActivityTypes.setUserName]: msg`Set user name`,
  [AdminActivityTypes.sharingKeyRemoved]: msg`Sharing key removed`,
  [AdminActivityTypes.sharingKeyReplaced]: msg`Sharing key replaced`,
  [AdminActivityTypes.sharingKeyUpdated]: msg`Sharing key updated`,
  [AdminActivityTypes.recoveryKeysShared]: msg`Recovery keys shared`,
  [AdminActivityTypes.stopRequiringPasswordChange]: msg`Stop requiring password change`,
  [AdminActivityTypes.authenticationSuccessful]: msg`Successful authentication`,
  [AdminActivityTypes.updateFolderPermissions]: msg`Update folder permissions`,
  [AdminActivityTypes.updatePolicyUsers]: msg`Update policy users`,
  [AdminActivityTypes.updatedSAMLMap]: msg`Updated SAML map`,
  [AdminActivityTypes.userDeprovisioned]: msg`User deprovisioned`,
  [AdminActivityTypes.userProvisioned]: msg`User provisioned`,
  [AdminActivityTypes.viewAllMatchingInvitations]: msg`View all matching invitations`
}

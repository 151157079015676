import React from 'react'

import { Trans } from '@lingui/macro'

import { LocationLink } from '@lastpass/routing'

export const TroubleshootLink: React.FC = () => (
  <LocationLink
    data-qa="LocationLink"
    to="https://support.lastpass.com/help/troubleshooting-siem-integrations"
    target="_blank"
  >
    {' '}
    <Trans>Troubleshoot</Trans>
  </LocationLink>
)

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  ServiceError,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SharedFolder } from '@lastpass/admin-console-dependencies/types/shared-folder'

export const SUCCESS = 'success'

const success = (sharedFolders: SharedFolder[], totalResults: number) =>
  createResponse(SUCCESS, { sharedFolders, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userId: string, query: string) {
    const [response, status] = await fetch(
      'users/' + userId + '/shared-folders' + query,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const result = JSON.parse(response)
      if (result.status === 'ok') {
        const sharedFolders = JSON.parse(response).content.items
        const totalResults = JSON.parse(response).content.totalCount
        return success(sharedFolders, totalResults)
      } else {
        throw new ServiceError(result.status)
      }
    }
    throw new UnknownServiceError()
  }
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular, Heading100, PrimaryButton } from '@lastpass/lastkit'
import { StyledSpacer } from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import {
  BodyText,
  BodyTitle,
  StyledAppIcon
} from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'

const TopBodyText = styled.div`
  margin-bottom: 24px;
`

const BottomBodyText = styled.div`
  margin-top: 24px;
`

export const ListSectionContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`

export const ListTitle = styled.div`
  margin-bottom: 5px;
`

const ListItem = styled.div`
  margin-left: 15px;
  font-weight: bold;
`

const Bold = styled.span`
  font-weight: bold;
`
export interface AllSetProps {
  closeLink: string
}

export type AllSetComponentProps = LastPassComponentProps<
  ApplicationDrawerState,
  {},
  AllSetProps
>

const listCountLimit = 10

export const GetDisplayableData = e => {
  let objects = (
    <ListItem data-qa="AllSetAssignedNumber">{e.totalResults}</ListItem>
  )
  if (e.totalResults > 0 && e.totalResults <= listCountLimit) {
    objects = e.results.map(u => {
      return (
        <ListItem data-qa="AllSetAssignedNumber" key={u.id}>
          {u.name}
        </ListItem>
      )
    })
  }

  return objects
}

export const AllSetComponent: React.FunctionComponent<AllSetComponentProps> = ({
  state,
  props
}) => {
  const history = useHistory()
  let appName = '',
    appLogo

  if (state.selectedApplication) {
    appName = state.selectedApplication.applicationName
    appLogo = state.selectedApplication.customLogoUrl
  }

  return (
    <>
      <BodyTitle>
        {appLogo && <StyledAppIcon data-qa={'AppLogo'} src={appLogo} />}
        <Heading100 data-qa="AllSetAppName">{appName}</Heading100>
      </BodyTitle>
      <BodyRegular>
        <BodyText>
          <TopBodyText data-qa="AllSetTopText">
            <Trans>
              You can now try logging into <Bold>{appName}</Bold> with the
              assigned users, groups, or roles:
            </Trans>
          </TopBodyText>
          <ListSectionContainer data-qa="AllSetRolesSection">
            <ListTitle>
              <Trans>Roles:</Trans>
            </ListTitle>
            {GetDisplayableData(state.roles)}
          </ListSectionContainer>
          <ListSectionContainer data-qa="AllSetGroupsSection">
            <ListTitle>
              <Trans>Groups:</Trans>
            </ListTitle>
            {GetDisplayableData(state.groups)}
          </ListSectionContainer>
          <ListSectionContainer data-qa="AllSetUsersSection">
            <ListTitle>
              <Trans>Users:</Trans>
            </ListTitle>
            {GetDisplayableData(state.users)}
          </ListSectionContainer>
          <BottomBodyText data-qa="AllSetBottomText">
            <Trans>
              Once you’re ready, you can assign more users to your apps on the
              <Bold> Applications</Bold> page or manage multifactor requirements
              on the <Bold> Policies</Bold> page.
            </Trans>
          </BottomBodyText>
        </BodyText>
      </BodyRegular>
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <PrimaryButton
            id={'FinishButton'}
            key={'Finish'}
            onClick={() => history.push(props.closeLink)}
            data-qa={'FinishButton'}
          >
            <Trans>Finish</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

export function createAllSet<
  TState extends { applicationDrawer: ApplicationDrawerState }
>() {
  return connectRedux(
    AllSetComponent,
    (state: TState) => state.applicationDrawer
  )
}

export type AllSet = ReturnType<typeof createAllSet>

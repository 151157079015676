import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import {
  Dropdown,
  DropdownListItem,
  IconButton
} from '@lastpass/lastkit/components'
import { Divider } from '@lastpass/lastkit/components/Divider'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { userOperationActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { OperationOriginType } from '@lastpass/admin-console-dependencies/state/users/view/operations/operation-origin-types'
import { UserOperationsType } from '@lastpass/admin-console-dependencies/state/users/view/operations/user-operations'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { userOperationsMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/UserOperationsMapping'
const MoreButton = props => (
  <IconButton icon={More} {...props} data-qa={'UserOperationsDropdownButton'} />
)

export const UserOperationsDropdown: React.FunctionComponent = props => {
  const { isTransferVaultHidden } = useSelector(
    (state: AppState) => state.usersList
  )

  const usersDrawerState: UsersDrawerState = useSelector(
    (state: AppState) => state.usersDrawer
  )

  const pathParams: UserDrawerUrlParams = usePathParams()

  const dispatch = useDispatch()

  const {
    cidOverride: cidOverrideChildCompanyId
  }: CompanyInfoState = useSelector((state: AppState) => state.companyInfo)

  // Assign Admin Levels operation won't display under user details - operation dropdown
  const operations: UserOperationsType[] = usersDrawerState.operations.filter(
    operation => operation !== UserOperationsType.assignAdminLevels
  )

  let listItems: JSX.Element[] = [
    <DropdownListItem key={'noAction'} noClick={true}>
      <Trans>No actions available</Trans>
    </DropdownListItem>
  ]

  const history = useHistory()
  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch])

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )
  const isForceVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isForceVaultReEncryptionEnabled
  )

  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()
  if (operations.length > 0) {
    const criticalOperations = operations.filter(
      operation =>
        operation === UserOperationsType.deleteSelectedUsers ||
        operation === UserOperationsType.disableUsers
    )

    listItems = operations
      .filter(userOperation =>
        usersDrawerState.profile.isRecoveryKeySharingAllowed
          ? userOperation
          : userOperation !== UserOperationsType.distributeRecoveryKey
      )
      .filter(
        userOperation =>
          !(
            isTransferVaultHidden &&
            userOperation === UserOperationsType.transferVault
          )
      )
      .map(operationType => {
        const userOperation = userOperationsMapping({
          dispatch,
          userOperationActions,
          origin: OperationOriginType.Drawer,
          history,
          cidOverrideChildCompanyId,
          isPasswordlessEnabledOrUsed:
            !isNpPasswordlessFeatureDisabled || !isPasswordlessPoliciesEmpty,
          isForceVaultReEncryptionEnabled,
          areMultipleUsersSelected: false
        })[operationType]

        return (
          <>
            {!userOperation.hidden && !userOperation.isCritical && (
              <DropdownListItem
                key={operationType}
                red={userOperation.color && userOperation.color === 'red700'}
                onClick={() =>
                  userOperation.callback([
                    // @ts-ignore
                    {
                      ...usersDrawerState.profile,
                      ...usersDrawerState.accountDetails
                    }
                  ])
                }
                data-qa={userOperation.tag}
              >
                {userOperation.name}
              </DropdownListItem>
            )}
          </>
        )
      })

    const criticalListItems: JSX.Element[] = criticalOperations.map(
      operationType => {
        const userOperation = userOperationsMapping({
          dispatch,
          userOperationActions,
          origin: OperationOriginType.Drawer,
          history,
          cidOverrideChildCompanyId,
          isPasswordlessEnabledOrUsed:
            !isNpPasswordlessFeatureDisabled || !isPasswordlessPoliciesEmpty,
          isForceVaultReEncryptionEnabled,
          areMultipleUsersSelected: false
        })[operationType]

        return (
          <>
            {!userOperation.hidden && (
              <DropdownListItem
                key={operationType}
                red={userOperation.color && userOperation.color === 'red700'}
                onClick={() =>
                  userOperation.callback([
                    // @ts-ignore
                    {
                      ...usersDrawerState.profile,
                      ...usersDrawerState.accountDetails
                    }
                  ])
                }
                data-qa={userOperation.tag}
              >
                {userOperation.name}
              </DropdownListItem>
            )}
          </>
        )
      }
    )

    listItems.push(
      <>
        <Divider />
        {criticalListItems}
      </>
    )
  }

  return (
    <Dropdown
      button={MoreButton}
      onInitialExpand={() =>
        dispatch(
          userDrawerActions.getUserOperations({ path: pathParams, query: {} })
        )
      }
      loading={usersDrawerState.isOperationsLoading}
      {...props}
    >
      {listItems}
    </Dropdown>
  )
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as LogRhythmIcon } from '@lastpass/assets/svg/admin-console/siem-integrations/icon-logRhythm.svg'
import { Heading100, LogoCard, WithTooltip } from '@lastpass/lastkit'

import { LogRhythmIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledCardContainer,
  StyledDescriptionRow,
  StyledInactiveExternalLink
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { LogRhythmForm } from './LogRhythmForm'

interface LogRhythmIntegrationCardProps {
  config: LogRhythmIntegrationConfig
}

export const LogRhythmIntegrationCard: React.FunctionComponent<LogRhythmIntegrationCardProps> = ({
  config
}) => (
  <LogoCard
    dataQaName="SiemLogRhythmCard"
    logo={<LogRhythmIcon />}
    title={
      <Heading100>
        <Trans>LogRhythm</Trans>
      </Heading100>
    }
    subtitle={
      <Trans>
        Configure LastPass to send audit events to a LogRhythm instance in near
        real-time.
      </Trans>
    }
  >
    <StyledCardContainer>
      <StyledDescriptionRow>
        <Trans>LastPass related events will be visible in LogRhythm.</Trans>
        <WithTooltip
          tooltip={<Trans>Documentation still to come</Trans>}
          placement="right"
        >
          <StyledInactiveExternalLink
            data-qa="SiemLogRhythmInstructionsLink"
            href="#" // Waiting for documents to be created
            target="_blank"
            onClick={e => e.preventDefault()}
          >
            <Trans>View setup instructions</Trans>
          </StyledInactiveExternalLink>
        </WithTooltip>
      </StyledDescriptionRow>
      <LogRhythmForm config={config} />
    </StyledCardContainer>
  </LogoCard>
)

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPolicySwitchONSaveAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createSavePolicyUsers(
  multifactorServices: UACServices.Services
) {
  return function* savePolicyUsers(
    action: ReturnType<typeof multifactorDrawerActions.savePolicyUsers>
  ) {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))

      try {
        const savePolicyUsersResponse = yield call(
          multifactorServices.generalPolicySwitchOnSave,
          action.payload.policyData
        )

        if (
          savePolicyUsersResponse.type === GeneralPolicySwitchONSaveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )
          yield put(push(action.payload.closeLink))
          yield put(multifactorActions.fetchInitialData())
        } else if (
          savePolicyUsersResponse.type ===
          GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`This policy can't have multiple configurations.`
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import { AcronisApiServiceType } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { acronisApiActions } from '@lastpass/admin-console-dependencies/state/advanced/acronis-api/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetAcronisApi(acronisApiServices: AcronisApiServiceType) {
  return function* getAcronisApi() {
    try {
      yield put(acronisApiActions.setInProgress(true))
      try {
        const alreadyHasHash = yield call(
          acronisApiServices.getProvisioningHash
        )

        yield put(acronisApiActions.setAcronisApi(alreadyHasHash))
      } finally {
        yield put(acronisApiActions.setInProgress(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'
import { PurpleAdminLevel } from '@lastpass/tracking/tracking-plans/uac'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionCategory } from '@lastpass/admin-console-dependencies/types/permissions/permission-category'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import {
  UserLevelDetails,
  UserLevelWithPermissions
} from '@lastpass/admin-console-dependencies/types/user-level'
import { MspCidOverrideLevel } from '@lastpass/admin-console-dependencies/types/user-level'
export interface AdminLevelDrawerUrlParams {
  id: string
}

export interface AdminLevelDrawerQueryParams {
  search: string
  skip: number
}

const TrackingAdminLevelMap: { [key: string]: PurpleAdminLevel } = {
  SuperAdmin: 'Super Admin',
  Admin: 'Admin',
  HelpDeskAdmin: 'Helpdesk Admin'
}

export function MapAdminLevelNameForTracking(
  adminLevel: UserLevelDetails
): PurpleAdminLevel {
  if (adminLevel.isCustom) {
    return 'Custom Admin'
  }

  return TrackingAdminLevelMap[adminLevel.name]
}

export enum AdminLevelDrawerSegmentActionTypes {
  USERS_REMOVED_FROM_ADMIN_LEVEL = '[admin-level-drawer-segment] users-removed-from-admin-level'
}

export const adminLevelDrawerSegmentActions = {
  usersRemovedFromAdminLevel: (numberFailed: number, numberSucceeded: number) =>
    track(
      createAction(
        AdminLevelDrawerSegmentActionTypes.USERS_REMOVED_FROM_ADMIN_LEVEL,
        { numberFailed, numberSucceeded }
      ),
      {
        segment: uacEvent('Users Removed from Admin Level', {
          'Number Failed': numberFailed,
          'Number Succeeded': numberSucceeded
        })
      }
    )
}

export enum AdminLevelDrawerActionTypes {
  RESET = '[admin-level-drawer] reset',
  GET_CURRENT_ADMIN_LEVEL = '[admin-level-drawer] get-current-admin-level',
  SET_CURRENT_ADMIN_LEVEL = '[admin-level-drawer] set-current-admin-level',
  SET_ADMIN_LEVEL_MANAGED_PERMISSIONS = '[admin-level-drawer] set-admin-level-managed-permissions',
  GET_ADMIN_LEVEL_USERS = '[admin-level-drawer] get-admin-level-users',
  SET_ADMIN_LEVEL_USERS = '[admin-level-drawer] set-admin-level-users',
  SET_ADMIN_LEVEL_USERS_LOADING = '[admin-level-drawer] set-admin-level-users-loading',
  ADD_SELECTED_ADMIN_LEVEL_USERS = '[admin-level-drawer] add-selected-admin-level-users',
  REMOVE_SELECTED_ADMIN_LEVEL_USERS = '[admin-level-drawer] remove-selected-admin-level-users',
  SET_SELECTED_ADMIN_LEVEL_USERS = '[admin-level-drawer] set-selected-admin-level-users',
  REMOVE_USERS_FROM_ADMIN_LEVEL = '[admin-level-drawer] remove-users-from-admin-level',
  ADD_NEW_ADMIN_LEVEL = '[admin-level-drawer] add-new-admin-level',
  ADD_NEW_PARTIAL_ADMIN_LEVEL = '[admin-level-drawer] add-new-partial-admin-level',
  ADD_NEW_GRANULAR_ADMIN_LEVEL = '[admin-level-drawer] add-new-granular-admin-level',
  ADD_NEW_MANAGED_ADMIN_LEVEL = '[admin-level-drawer] add-new-managed-admin-level',
  EDIT_ADMIN_LEVEL = '[admin-level-drawer] edit-admin-level',
  EDIT_PARTIAL_ADMIN_LEVEL = '[admin-level-drawer] edit-partial-admin-level',
  EDIT_MANAGED_ADMIN_LEVEL = '[admin-level-drawer] edit-managed-admin-level',
  GET_ADMIN_PERMISSIONS = '[admin-level-drawer] get-admin-permissions',
  SET_ADMIN_PERMISSIONS = '[admin-level-drawer] set-admin-permissions',
  GET_MANAGED_ADMIN_PERMISSIONS = '[admin-level-drawer] get-managed-admin-permissions',
  SET_MANAGED_ADMIN_PERMISSIONS = '[admin-level-drawer] set-managed-admin-permissions'
}

interface EditAdminLevelParams {
  id: string
  name: string
  description: string
  permissions: string[]
  closeLink?: string
  isPartial?: boolean
}

interface NewAdminLevelParams {
  name: string
  description: string
  permissions: AdminPermissions[]
  closeLink?: string
  isPartial?: boolean
  mspCidOverrideAdminLevel?: string
  mspCidOverridePermissions?: AdminPermissions[]
}

export const adminLevelDrawerActions = {
  reset: () => createAction(AdminLevelDrawerActionTypes.RESET),
  getCurrentAdminLevel: (
    params: DataFetchParams<{}, AdminLevelDrawerUrlParams>
  ) =>
    createAction(AdminLevelDrawerActionTypes.GET_CURRENT_ADMIN_LEVEL, {
      params
    }),
  setCurrentAdminLevel: (adminLevel: UserLevelWithPermissions) =>
    createAction(AdminLevelDrawerActionTypes.SET_CURRENT_ADMIN_LEVEL, {
      adminLevel
    }),
  setAdminLevelManagedPermissions: (
    mspCidOverridePermissions: AdminPermissions[]
  ) =>
    createAction(
      AdminLevelDrawerActionTypes.SET_ADMIN_LEVEL_MANAGED_PERMISSIONS,
      {
        mspCidOverridePermissions
      }
    ),
  getAdminPermissions: (isRoleBasedAccessControlEnabled: boolean) =>
    createAction(AdminLevelDrawerActionTypes.GET_ADMIN_PERMISSIONS, {
      isRoleBasedAccessControlEnabled
    }),
  setAdminPermissions: (permissionCategories: PermissionCategory[]) =>
    createAction(AdminLevelDrawerActionTypes.SET_ADMIN_PERMISSIONS, {
      permissionCategories
    }),
  getManagedAdminPermissions: () =>
    createAction(AdminLevelDrawerActionTypes.GET_MANAGED_ADMIN_PERMISSIONS),
  setManagedAdminPermissions: (
    managedPermissionCategories: PermissionCategory[],
    availableMspCidOverrideAdminLevels: MspCidOverrideLevel[]
  ) =>
    createAction(AdminLevelDrawerActionTypes.SET_MANAGED_ADMIN_PERMISSIONS, {
      managedPermissionCategories,
      availableMspCidOverrideAdminLevels
    }),
  getAdminLevelUsers: (
    params: DataFetchParams<
      AdminLevelDrawerQueryParams,
      AdminLevelDrawerUrlParams
    >,
    resultsPerPage = 25
  ) =>
    createAction(AdminLevelDrawerActionTypes.GET_ADMIN_LEVEL_USERS, {
      params,
      resultsPerPage
    }),
  setAdminLevelUsers: (
    users: User[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(AdminLevelDrawerActionTypes.SET_ADMIN_LEVEL_USERS, {
      users,
      totalResults,
      resultsPerPage
    }),
  setAdminLevelUsersLoading: (loading: boolean) =>
    createAction(
      AdminLevelDrawerActionTypes.SET_ADMIN_LEVEL_USERS_LOADING,
      loading
    ),
  addSelectedAdminLevelUsers: (userList: User[]) =>
    createAction(AdminLevelDrawerActionTypes.ADD_SELECTED_ADMIN_LEVEL_USERS, {
      userList
    }),
  removeSelectedAdminLevelUsers: (userList: User[]) =>
    createAction(
      AdminLevelDrawerActionTypes.REMOVE_SELECTED_ADMIN_LEVEL_USERS,
      {
        userList
      }
    ),
  setSelectedAdminLevelUsers: (userList: User[]) =>
    createAction(AdminLevelDrawerActionTypes.SET_SELECTED_ADMIN_LEVEL_USERS, {
      userList
    }),
  removeUsersFromAdminLevel: (users: User[], adminLevel: UserLevelDetails) =>
    track(
      createAction(AdminLevelDrawerActionTypes.REMOVE_USERS_FROM_ADMIN_LEVEL, {
        users,
        adminLevel
      }),
      {
        segment: uacEvent('Remove Users from Admin Level Clicked', {
          'Admin Level': MapAdminLevelNameForTracking(adminLevel)
        })
      }
    ),
  addNewAdminLevel: (params: NewAdminLevelParams) =>
    track(
      createAction(AdminLevelDrawerActionTypes.ADD_NEW_ADMIN_LEVEL, {
        ...params
      }),
      {
        segment: uacEvent('Custom Admin Level Created', {
          'admin permissions': params.permissions
        })
      }
    ),
  addNewPartialAdminLevel: (
    name: string,
    description: string,
    permissions: AdminPermissions[]
  ) =>
    track(
      createAction(AdminLevelDrawerActionTypes.ADD_NEW_PARTIAL_ADMIN_LEVEL, {
        name,
        description,
        permissions,
        mspCidOverrideAdminLevel: MspCidOverrideLevel.admin,
        mspCidOverridePermissions: []
      }),
      {
        segment: uacEvent('Custom Admin Level Created', {
          'admin permissions': permissions
        })
      }
    ),
  addNewManagedAdminLevel: (
    permissions: AdminPermissions[],
    closeLink?: string
  ) =>
    createAction(AdminLevelDrawerActionTypes.ADD_NEW_MANAGED_ADMIN_LEVEL, {
      permissions,
      closeLink
    }),
  editAdminLevel: (params: EditAdminLevelParams) =>
    track(
      createAction(AdminLevelDrawerActionTypes.EDIT_ADMIN_LEVEL, {
        ...params
      }),
      {
        segment: uacEvent('Custom Admin Level Edited')
      }
    ),
  editPartialAdminLevel: (
    id: string,
    name: string,
    description: string,
    permissions: AdminPermissions[],
    closeLink?: string
  ) =>
    track(
      createAction(AdminLevelDrawerActionTypes.EDIT_PARTIAL_ADMIN_LEVEL, {
        id,
        name,
        description,
        permissions,
        closeLink
      }),
      {
        segment: uacEvent('Custom Admin Level Edited')
      }
    ),
  editManagedAdminLevel: (
    id: string,
    mspCidOverrideAdminLevel: MspCidOverrideLevel,
    mspCidOverridePermissions: AdminPermissions[],
    permissions?: AdminPermissions[],
    closeLink?: string
  ) =>
    track(
      createAction(AdminLevelDrawerActionTypes.EDIT_MANAGED_ADMIN_LEVEL, {
        id,
        mspCidOverrideAdminLevel,
        mspCidOverridePermissions,
        permissions,
        closeLink
      }),
      {
        segment: uacEvent('Custom Admin Level Edited')
      }
    )
}

export type AdminLevelDrawerActions = ActionsUnion<
  typeof adminLevelDrawerActions
>

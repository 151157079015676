import React from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'

import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'

export const GlobalLoading: React.FunctionComponent = () => {
  const globalState: GlobalState = useSelector((state: AppState) => {
    return state.global
  })
  const rootContainer = document.getElementById('root')
  if (rootContainer) {
    return ReactDOM.createPortal(
      globalState.isLoading === true && (
        <Loading
          color="blue900"
          active={true}
          isFull={true}
          isBackgroundOverlayed={true}
        />
      ),
      rootContainer
    )
  }
  return null
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as ComingSoonIcon } from '@lastpass/assets/svg/admin-console/icon-coming-soon.svg'
import { Heading100 } from '@lastpass/lastkit'

const Container = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledComingSoonIcon = styled(ComingSoonIcon)`
  height: 120px;
  width: 120px;
  margin-bottom: 26px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 16px;
`

export const ComingSoonPage: FunctionComponent = () => {
  return (
    <Container data-qa="ComingSoonPageContainer">
      <StyledComingSoonIcon />
      <StyledHeading100>
        <Trans>Coming soon!</Trans>
      </StyledHeading100>
    </Container>
  )
}

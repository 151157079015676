import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  NextPagination,
  PrimaryButton,
  SearchInput,
  SecurityScoreBadge,
  Select,
  Table as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  SharedFolder,
  sharedFolderListActions,
  SharedFolderListQueryParams
} from '@lastpass/admin-console-dependencies/state/shared-folders/list/actions'
import { SharedFolderListState } from '@lastpass/admin-console-dependencies/state/shared-folders/list/state'
import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'

import { SecurityScoreLevelMapping } from '../common/mappings/SecurityScoreLevelMapping'

export interface SharedFolderListProps {
  isCidOverrideMode: boolean
}

type SharedFolderListComponentProps = LastPassComponentProps<
  SharedFolderListState,
  typeof sharedFolderListActions,
  SharedFolderListProps
>

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const StyledContainer = styled.div`
  display: flex;
`

const StyledRow = styled.div`
  margin-left: 5px;
`

const StyledNextPagination = styled(NextPagination)`
  align-self: flex-end;
`

export const SharedFolderListComponent: React.FunctionComponent<SharedFolderListComponentProps> = ({
  state,
  actions,
  props
}) => {
  const updateQuery = useUpdateQuery(Namespace.sharedFolders)
  const queryParams: SharedFolderListQueryParams = useQueryParams(
    Namespace.sharedFolders
  )

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(sharedFolderListActions.getSharedFolderList())
  }, [dispatch])

  let filteredSharedFoldersTable = queryParams.securityScore
    ? {
        ...state.table,
        results: state.table.results.filter(
          sharedFolder =>
            sharedFolder.securityScore === queryParams.securityScore
        )
      }
    : state.table

  filteredSharedFoldersTable = queryParams.search
    ? {
        ...filteredSharedFoldersTable,
        results: filteredSharedFoldersTable.results.filter(
          sharedFolder =>
            queryParams.search &&
            sharedFolder.name
              .toLowerCase()
              .indexOf(queryParams.search.toLowerCase()) > -1
        )
      }
    : filteredSharedFoldersTable

  filteredSharedFoldersTable.results.sort(function(
    previous: SharedFolder,
    next: SharedFolder
  ) {
    if (previous.name < next.name) {
      return -1
    }
    if (previous.name > next.name) {
      return 1
    }
    return 0
  })

  return (
    <>
      <RowContainer>
        <Heading300 data-qa={'SharedFoldersPageHeader'}>
          <Trans>Shared folders</Trans>
        </Heading300>
        {!props.isCidOverrideMode && (
          <StyledPrimaryButton
            data-qa="NewSharedFoldersButton"
            key="new shared folder"
            onClick={actions.newSharedFolder}
          >
            <Trans>New shared folder</Trans>
          </StyledPrimaryButton>
        )}
      </RowContainer>

      <RowContainer>
        <StyledContainer>
          <SearchInput
            data-qa={'SharedFoldersSearch'}
            onChange={event => {
              updateQuery({
                search: event.target.value || undefined,
                skip: undefined
              })
            }}
            value={queryParams.search ? queryParams.search : ''}
            placeholder={msg`Search shared folders...`}
          />
          <Select
            dataQa={'SharedFoldersDropdown'}
            onChange={option => {
              if (option != null) {
                updateQuery({
                  securityScore: option['value'] || undefined
                })
              }
            }}
            fluid
            width={'240px'}
            options={[
              {
                value: '',
                label: i18n._(msg`Show all shared folders`)
              },
              {
                value: SecurityScoreLevel.na,
                label: i18n._(msg`N/A`)
              },
              {
                value: SecurityScoreLevel.highest,
                label: i18n._(msg`High`)
              },
              {
                value: SecurityScoreLevel.average,
                label: i18n._(msg`Average`)
              },
              {
                value: SecurityScoreLevel.low,
                label: i18n._(msg`Low`)
              }
            ]}
            defaultValue={
              queryParams.securityScore && queryParams.securityScore !== ''
                ? {
                    value: queryParams.securityScore.valueOf(),
                    label: SecurityScoreLevelMapping[queryParams.securityScore]
                  }
                : {
                    value: '',
                    label: i18n._(msg`Show all shared folders`)
                  }
            }
          />
        </StyledContainer>
        <StyledNextPagination next={() => {}} previous={() => {}} />
      </RowContainer>

      <TableComponent
        table={filteredSharedFoldersTable}
        getRecordLink={(record: SharedFolder) =>
          `${matchUrl}/${record.shareid}`
        }
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No shared folders added yet.</Trans>}
            text={
              <Trans>
                Use shared folders to give users {'&'} groups access to apps.
              </Trans>
            }
            actionButton={
              !props.isCidOverrideMode ? (
                <TableViewButton
                  onClick={actions.newSharedFolder}
                  data-qa="SharedFoldersEmptyStateButton"
                >
                  <Trans>New shared folder</Trans>
                </TableViewButton>
              ) : (
                undefined
              )
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined,
                    securityScore: undefined
                  })
                }}
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: function SharedFolderNameRenderer(record: SharedFolder) {
              return <StyledRow>{record.name}</StyledRow>
            }
          },
          {
            name: <Trans>Security score</Trans>,
            renderer: function SharedFolderSecurityScoreRenderer(
              record: SharedFolder
            ) {
              return (
                <StyledRow>
                  <SecurityScoreBadge level={record.securityScore} />
                </StyledRow>
              )
            }
          },
          {
            name: <Trans>Apps</Trans>,
            renderer: function SharedFolderNumberOfAppsRenderer(
              record: SharedFolder
            ) {
              return (
                <StyledRow>
                  {record.numberOfApps ? record.numberOfApps.toString() : '-'}
                </StyledRow>
              )
            }
          },
          {
            name: <Trans>Users</Trans>,
            renderer: function SharedFolderTotalUsersRenderer(
              record: SharedFolder
            ) {
              return (
                <StyledRow>
                  {record.totalusers ? record.totalusers.toString() : '-'}
                </StyledRow>
              )
            }
          },
          {
            name: <Trans>Status</Trans>,
            renderer: function SharedFolderDeletedRenderer(
              record: SharedFolder
            ) {
              return (
                <StyledRow>
                  {record.deleted ? (
                    <Trans>Deleted</Trans>
                  ) : (
                    <Trans>Active</Trans>
                  )}
                </StyledRow>
              )
            }
          }
        ]}
        isFilterApplied={!!queryParams.securityScore || !!queryParams.search}
      />
    </>
  )
}

export function createSharedFolderList<
  TState extends { sharedFolderList: SharedFolderListState }
>() {
  return connectRedux(
    SharedFolderListComponent,
    (state: TState) => state.sharedFolderList,
    { ...sharedFolderListActions }
  )
}

export type SharedFolderList = ReturnType<typeof createSharedFolderList>

import {
  MfaApplicationListActions,
  MfaApplicationListActionTypes
} from './actions'
import { MfaApplicationListState } from './state'

export const initialState: MfaApplicationListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  hasAzureAdApps: false,
  hasAdfsApps: false
}

export function mfaApplicationListReducer(
  previousState: MfaApplicationListState | undefined,
  action: MfaApplicationListActions
): MfaApplicationListState {
  const state = previousState || initialState

  switch (action.type) {
    case MfaApplicationListActionTypes.SET_MFA_APPLICATION_LIST: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage
        }
      }
    }
    case MfaApplicationListActionTypes.SET_MFA_APPLICATION_LIST_LOADING: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          isLoading: action.payload.loading
        }
      }
    }
    case MfaApplicationListActionTypes.SET_USED_AZURE_AD_APPS: {
      const value = action.payload
      return {
        ...state,
        hasAzureAdApps: value
      }
    }
    case MfaApplicationListActionTypes.SET_USED_ADFS_APPS: {
      const value = action.payload
      return {
        ...state,
        hasAdfsApps: value
      }
    }
  }

  return state
}

import * as localForage from 'localforage'

import { UserIdRepository } from './user-id'

export const initializeLocalForageRepositories = async (
  userId: string
): Promise<void> => {
  const storedUserId = await UserIdRepository.get()

  if (storedUserId !== userId) {
    await localForage.clear()
    await UserIdRepository.set(userId)
  }
}

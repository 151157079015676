import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { onlyExportRiskTypes } from '@lastpass/admin-console-dependencies/resources/security-reports/only-export-risk-types'
import {
  createSecurityReportEventTranslationMap,
  SecurityReportEventTranslationMap
} from '@lastpass/admin-console-dependencies/resources/security-reports/security-report-event-translations'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportActions } from '@lastpass/admin-console-dependencies/state/reports/security/actions'
import { SecurityReportEvent } from '@lastpass/admin-console-dependencies/types/security-report'

const createOnlyExportSecurityReportEvents = (
  securityReportEventTranslationMap: SecurityReportEventTranslationMap
): SecurityReportEvent[] => {
  return onlyExportRiskTypes.map(riskType => ({
    id: riskType,
    riskName: securityReportEventTranslationMap[riskType].name,
    usersAffected: [],
    numberOfUsersAffected: '',
    percentageOfUsersAffected: '',
    descriptions: securityReportEventTranslationMap[riskType].descriptions
  }))
}

export const minimumHashIterationCountSelector = (state: AppState) =>
  state.companyInfo.details.minimumHashIterationCount
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

function* handleRequestedUpdate(currentReportCreatedDateUtc: string) {
  const { lastUpdated, isUpdateRequested } = yield select(
    (state: AppState) => state.securityReportEvents
  )

  if (
    isUpdateRequested &&
    lastUpdated &&
    new Date(lastUpdated) < new Date(currentReportCreatedDateUtc)
  ) {
    yield put(securityReportActions.setIsUpdateRequested(false))
    yield put(
      globalActions.setNotification({
        type: NotificationType.success,
        message: msg`Security reports updated`,
        autoDismiss: true
      })
    )
  }
}

export function createGetSecurityReportEvents(
  reportingServices: UACServices.Services
) {
  return function* getSecurityReportEvents(
    action: ReturnType<typeof securityReportActions.getSecurityReportEvents>
  ) {
    try {
      const {
        featureFlags: {
          isDWMSecurityReportEnabled,
          isUrlDecryptionEnabled,
          isSharedFolderUrlDecryptionEnabled,
          isWeakSecurityScoreEnabled
        }
      } = action.payload
      yield put(securityReportActions.setSecurityReportLoading(true))

      const minimumHashIterationCount = yield select(
        minimumHashIterationCountSelector
      )

      const translationMap = createSecurityReportEventTranslationMap(
        minimumHashIterationCount,
        isUrlDecryptionEnabled,
        isSharedFolderUrlDecryptionEnabled
      )

      const result: UACServices.SecurityReportAPI.Responses = yield call(
        reportingServices.securityReport,
        isWeakSecurityScoreEnabled,
        isDWMSecurityReportEnabled,
        translationMap
      )

      yield put(
        securityReportActions.setSecurityReportEvents([
          ...createOnlyExportSecurityReportEvents(translationMap),
          ...result.body.events
        ])
      )

      yield call(handleRequestedUpdate, result.body.createdDateUtc)

      yield put(
        securityReportActions.setSecurityReportLastUpdated(
          result.body.createdDateUtc
        )
      )

      yield put(securityReportActions.setSecurityReportLoading(false))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

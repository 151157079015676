import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { BodySemibold, TopBanner } from '@lastpass/lastkit'

import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'

const Container = styled.div`
  color: ${props => props.theme.colors.blue100};
  margin: 0px auto;
`

const Text = styled(BodySemibold)`
  color: ${props => props.theme.colors.blue100};
  vertical-align: text-bottom;
`

const MasterAccountButton = styled(BodySemibold)`
  color: ${props => props.theme.colors.blue100};
  vertical-align: text-bottom;
  margin-left: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ManagedCompanyBanner: React.FunctionComponent = () => {
  const {
    cidOverride,
    details: { name: companyName }
  } = useSelector<AppState, CompanyInfoState>(
    (state: AppState) => state.companyInfo
  )
  const dispatch = useDispatch()

  if (!cidOverride) {
    return null
  }
  return (
    <TopBanner backgroundColor="blue">
      <Container data-qa="ManagedCompanyBanner">
        <Text data-qa="ManagedCompanyBannerMessage">
          <Trans>
            Managed company: {companyName} ({cidOverride}).
          </Trans>
        </Text>
        <MasterAccountButton
          data-qa="ManagedCompanyBannerButton"
          onClick={() => dispatch(companyInfoActions.removeCidOverride())}
        >
          <Trans>Back to primary account</Trans>
        </MasterAccountButton>
      </Container>
    </TopBanner>
  )
}

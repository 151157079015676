import { msg } from '@lingui/macro'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function* handleGoogleSetupFailure() {
  yield put(
    globalActions.setNotification({
      message: msg`Google setup unsuccessful`,
      type: NotificationType.alert,
      autoDismiss: true
    })
  )
  yield put(globalActions.discardDialog())
  yield put(globalActions.emptyDialog())
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetUserActivityHistorySaga(
  dashboardServices: UACServices.Services
) {
  return function* getUserActivityHistorySaga(
    action: ReturnType<typeof dashboardActions.getUserActivityHistory>
  ) {
    yield put(dashboardActions.setUserActivityHistoryLoading(true))
    try {
      const result: UACServices.UserActivityHistoryAPI.Responses = yield call(
        dashboardServices.getUserActivityHistory,
        action.payload.historyStartDate,
        action.payload.historyEndDate
      )
      if (result.type === UACServices.UserActivityHistoryAPI.SUCCESS) {
        yield put(
          dashboardActions.setUserActivityHistory(
            result.body.items,
            result.body.lastUpdated
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(dashboardActions.setUserActivityHistoryLoading(false))
    }
  }
}

import { MessageDescriptor } from '@lingui/core'

export interface AffectedSecurityReportUser {
  lastPassId: number
  email: string
  name: string
}

export interface SecurityReportMessageDescriptor {
  element: ElementType.p
  message: MessageDescriptor
}

export interface SecurityReportMessageListDescriptor
  extends Omit<SecurityReportMessageDescriptor, 'element'> {
  element: ElementType.ul
  listMessages: MessageDescriptor[]
}

export interface SecurityReportEvent {
  id: RiskType
  riskName: MessageDescriptor
  usersAffected: AffectedSecurityReportUser[]
  numberOfUsersAffected: number | string
  percentageOfUsersAffected: string
  descriptions?: (
    | SecurityReportMessageDescriptor
    | SecurityReportMessageListDescriptor
  )[]
}

export enum ElementType {
  p = 'p',
  ul = 'ul'
}

export enum RiskType {
  lowIterationCount = 'userswithlowiterationcount',
  sharedFoldersAccessedByLowIterationCountUsers = 'sharedFoldersAccessedByLowIterationCountUsers',
  reusedMasterPassword = 'usersreusemasterpasslist',
  weakSecurityScore = 'numofweekscorelist',
  noSharingKey = 'nosharingkeylist',
  notLoggedIn = 'notloggedinlist',
  noLinkedAccount = 'nolinkedaccountlist',
  weakPasswords = 'weakpasswordslist',
  duplicatePasswords = 'duplicatepasswordslist',
  duplicatePasswordSites = 'duplicatepasswordsiteslist',
  blankPasswords = 'blankpasswordslist',
  darkWebMonitoringAlert = 'darkWebMonitoringAlert',
  mfa = 'mfa',
  linkedAccountIterationCount = 'linkedAccountIterationCount',
  urlsInVaults = 'urlsInVaults',
  urlsInSharedFolders = 'urlsInSharedFolders'
}

import { DataFetchParams } from '@lastpass/routing'
import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { Group } from '@lastpass/admin-console-dependencies/state/applications/application'
import { User } from '@lastpass/admin-console-dependencies/state/applications/application'
import { MfaAppsDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import {
  MfaApplication,
  MfaApplicationType
} from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { GlobalActionTypes } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GroupListQueryParams } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { UserListQueryParams } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { NotificationProps } from '@lastpass/admin-console-dependencies/types/notification-type'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

export enum Entities {
  availableUsers = 'availableUsers',
  users = 'users',
  availableGroups = 'availableGroups',
  groups = 'groups'
}

export enum MfaAppsDrawerActionTypes {
  SET_DRAWER_LOADING = '[mfa-apps-drawer] set-drawer-loading',
  DISCARD = '[mfa-apps-drawer] discard',
  GO_BACK = '[mfa-apps-drawer] go-back',
  FINISH = '[mfa-apps-drawer] finish',
  SET_NAME = '[mfa-apps-drawer] set-name',
  SET_APP = '[mfa-apps-drawer] set-app',
  SELECT_APP = '[mfa-apps-drawer] select-app',
  ADD_APP = '[mfa-apps-drawer] add-app',
  SAVE_APP = '[mfa-apps-drawer] save-app',
  REMOVE_APP = '[mfa-apps-drawer] remove-app',
  CONFIGURE_APP_OPENED = '[mfa-apps-drawer] configure-app-opened',
  CONFIGURE_APP_CLICKED = '[mfa-apps-drawer] configure-app-clicked',
  SELECT_APP_OPENED = '[mfa-apps-drawer] select-app-opened',
  SELECT_APP_CANCELED = '[mfa-apps-drawer] select-app-canceled',
  SET_MAPPING_KEY = '[mfa-apps-drawer] set mapping key',
  SET_POTENTIAL_MAPPING_KEYS = '[mfa-apps-drawer] set potential mapping keys',
  NEXT_STEP = '[mfa-apps-drawer] next-step',
  SHOW_TABLE = '[mfa-apps-drawer] show-table',
  SELECT_TABLE_ELEMENT = '[mfa-apps-drawer] select-table-element',
  DESELECT_TABLE_ELEMENT = '[mfa-apps-drawer] deselect-table-element',
  SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS = '[mfa-apps-drawer] select-or-deselect-table-element',
  SET_USER_LIST_SEARCH_INPUT = '[mfa-apps-drawer] set-user-list-search-input',
  SET_GROUP_LIST_SEARCH_INPUT = '[mfa-apps-drawer] set-group-list-search-input',
  GET_AVAILABLE_USERS = '[mfa-apps-drawer] get-available-users',
  SET_AVAILABLE_USER_LIST = '[mfa-apps-drawer] set-available-user-list',
  SET_USER_LIST_LOADING = '[mfa-apps-drawer] set-user-list-loading',
  GET_AVAILABLE_GROUPS = '[mfa-apps-drawer] get-available-groups',
  SET_AVAILABLE_GROUP_LIST = '[mfa-apps-drawer] set-available-group-list',
  ASSIGN = '[mfa-apps-drawer] assign',
  UNASSIGN = '[mfa-apps-drawer] unassign',
  SET_USERS = '[mfa-apps-drawer] set users',
  SET_GROUPS = '[mfa-apps-drawer] set groups',
  SET_GROUP_LIST_LOADING = '[mfa-apps-drawer] set-group-list-loading'
}

const AppTypeMap: {
  [s: string]: SegmentPropertyType<
    'MFA Apps Select App Clicked',
    'App Selected'
  >
} = {
  1: 'Windows Workstations',
  2: 'macOS Workstations',
  3: 'Microsoft Azure AD',
  4: 'Universal Proxy',
  5: 'Microsoft AD FS',
  0: 'None'
}

const mfaSelectAppClickedEventName = 'MFA Apps Select App Clicked'

export const mfaAppsDrawerActions = {
  setDrawerLoading: (loading: boolean) =>
    createAction(MfaAppsDrawerActionTypes.SET_DRAWER_LOADING, { loading }),
  discard: () => createAction(MfaAppsDrawerActionTypes.DISCARD),
  goBack: () => createAction(MfaAppsDrawerActionTypes.GO_BACK),
  finish: () => createAction(MfaAppsDrawerActionTypes.FINISH),
  setName: (appName: string) =>
    createAction(MfaAppsDrawerActionTypes.SET_NAME, { appName }),
  setApp: (app: MfaApplication) =>
    createAction(MfaAppsDrawerActionTypes.SET_APP, { app }),
  selectApp: (name: string, type: MfaApplicationType) =>
    createAction(MfaAppsDrawerActionTypes.SELECT_APP, { name, type }),
  addApp: (app: MfaApplication) =>
    track(createAction(MfaAppsDrawerActionTypes.ADD_APP, { app }), {
      segment: uacEvent(mfaSelectAppClickedEventName, {
        Action: 'Continue',
        'App Selected': AppTypeMap[app.type]
      })
    }),
  saveApp: (app: MfaApplication) =>
    createAction(MfaAppsDrawerActionTypes.SAVE_APP, { app }),
  removeApp: (key: string, type: MfaApplicationType) =>
    createAction(MfaAppsDrawerActionTypes.REMOVE_APP, { key, type }),
  setNotification: (notification: NotificationProps) =>
    createAction(GlobalActionTypes.SET_NOTIFICATION, { notification }),
  configureAppViewed: () =>
    track(createAction(MfaAppsDrawerActionTypes.CONFIGURE_APP_OPENED, {}), {
      segment: uacEvent('MFA Apps Setup Viewed')
    }),
  configureAppClicked: (action, app) =>
    track(
      createAction(MfaAppsDrawerActionTypes.CONFIGURE_APP_CLICKED, {
        action,
        app
      }),
      {
        segment: uacEvent('MFA Apps Setup Clicked', {
          Action: action,
          'App Selected': AppTypeMap[app.type]
        })
      }
    ),
  selectAppViewed: () =>
    track(createAction(MfaAppsDrawerActionTypes.SELECT_APP_OPENED, {}), {
      segment: uacEvent('MFA Apps Select App Viewed')
    }),
  selectAppCanceled: (app: MfaApplication) =>
    track(createAction(MfaAppsDrawerActionTypes.SELECT_APP_CANCELED, {}), {
      segment: uacEvent(mfaSelectAppClickedEventName, {
        Action: 'Discard',
        'App Selected': AppTypeMap[app.type]
      })
    }),
  setMappingKey: (mappingKey: string) =>
    createAction(MfaAppsDrawerActionTypes.SET_MAPPING_KEY, { mappingKey }),
  setPotentialMappingKeys: (mappingKeys: string[]) =>
    createAction(MfaAppsDrawerActionTypes.SET_POTENTIAL_MAPPING_KEYS, {
      mappingKeys
    }),
  addSelectedGroupsToUnassign: (records: Group[]) =>
    createAction(MfaAppsDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups
    }),
  removeSelectedGroupsToUnassign: (records: Group[]) =>
    createAction(MfaAppsDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups
    }),
  setSelectedGroupsToUnassign: (records: Group[]) =>
    createAction(
      MfaAppsDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.groups
      }
    ),
  nextStep: (nextStep: MfaAppsDrawerSteps) =>
    createAction(MfaAppsDrawerActionTypes.NEXT_STEP, { nextStep }),
  showTable: (table: string) =>
    createAction(MfaAppsDrawerActionTypes.SHOW_TABLE, {
      table
    }),
  setUserListSearchInput: (searchString: string) =>
    createAction(MfaAppsDrawerActionTypes.SET_USER_LIST_SEARCH_INPUT, {
      searchString
    }),
  getAvailableUsers: (
    params: DataFetchParams<UserListQueryParams>,
    resultsPerPage = 1000
  ) =>
    createAction(MfaAppsDrawerActionTypes.GET_AVAILABLE_USERS, {
      params,
      resultsPerPage
    }),
  setAvailableUserList: (
    list: User[],
    totalResults: number,
    resultsPerPage = 1000
  ) =>
    createAction(MfaAppsDrawerActionTypes.SET_AVAILABLE_USER_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  setUserListLoading: (loading: boolean) =>
    createAction(MfaAppsDrawerActionTypes.SET_USER_LIST_LOADING, {
      loading
    }),
  addSelectedUsers: (records: User[]) =>
    createAction(MfaAppsDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableUsers
    }),
  removeSelectedUsers: (records: User[]) =>
    createAction(MfaAppsDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableUsers
    }),
  setSelectedUsers: (records: User[]) =>
    createAction(
      MfaAppsDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableUsers
      }
    ),
  getAvailableGroups: (
    params: DataFetchParams<GroupListQueryParams>,
    resultsPerPage = 1000
  ) =>
    createAction(MfaAppsDrawerActionTypes.GET_AVAILABLE_GROUPS, {
      params,
      resultsPerPage
    }),
  setGroupListSearchInput: (searchString: string) =>
    createAction(MfaAppsDrawerActionTypes.SET_GROUP_LIST_SEARCH_INPUT, {
      searchString
    }),
  addSelectedGroups: (records: Group[]) =>
    createAction(MfaAppsDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableGroups
    }),
  removeSelectedGroups: (records: Group[]) =>
    createAction(MfaAppsDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableGroups
    }),
  setSelectedGroups: (records: Group[]) =>
    createAction(
      MfaAppsDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableGroups
      }
    ),
  addSelectedUsersToUnassign: (records: User[]) =>
    createAction(MfaAppsDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users
    }),
  removeSelectedUsersToUnassign: (records: User[]) =>
    createAction(MfaAppsDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users
    }),
  setSelectedUsersToUnassign: (records: User[]) =>
    createAction(
      MfaAppsDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.users
      }
    ),
  setAvailableGroupList: (
    list: Group[],
    totalResults: number,
    resultsPerPage = 1000
  ) =>
    createAction(MfaAppsDrawerActionTypes.SET_AVAILABLE_GROUP_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  assign: () => createAction(MfaAppsDrawerActionTypes.ASSIGN),
  unassign: () => createAction(MfaAppsDrawerActionTypes.UNASSIGN),
  setUsers: (users: Table<User>) =>
    createAction(MfaAppsDrawerActionTypes.SET_USERS, users),
  setGroups: (groups: Table<Group>) =>
    createAction(MfaAppsDrawerActionTypes.SET_GROUPS, groups),
  setGroupListLoading: (loading: boolean) =>
    createAction(MfaAppsDrawerActionTypes.SET_GROUP_LIST_LOADING, {
      loading
    })
}

export type MfaAppsDrawerActions = ActionsUnion<typeof mfaAppsDrawerActions>

import React from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'

import { CenteredContentContainer } from '@lastpass/lastkit'
import { AnimatedSwitch } from '@lastpass/routing'

import { Overlay } from '@lastpass/admin-console-dependencies/ui/common/OverlayComponent'
import { GeofenceDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system//drawers/GeofenceDrawer'
import { KeysDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system//drawers/KeysDrawer'
import { AccessPolicyDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/AccessPolicyDrawer'
import { AuthenticationDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/AuthenticationDrawer'
import { AuthenticatorDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/AuthenticatorDrawer'
import { CustomAttributesDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/CustomAttributesDrawer'
import { IdentityRolesDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/IdentityRolesDrawer'
import { IpAllowDenyDrawer } from '@lastpass/admin-console-dependencies/ui/upgrade/system/drawers/IpAllowDenyDrawer'
import { SystemUpgrade } from '@lastpass/admin-console-dependencies/ui/upgrade/system/SystemUpgrade'

const StyledOverlay = styled(Overlay)`
  z-index: 60;
  top: 56px;
  left: 0;
  width: calc(100vw - 588px);
  height: calc(100vh - 56px)};
`

export function SystemUpgradePage() {
  const { path } = useRouteMatch()
  const history = useHistory()

  const animationTimeout = 200
  const overlayPaths = [
    `${path}/keys`,
    `${path}/authenticator`,
    `${path}/customattributes`,
    `${path}/identityroles`,
    `${path}/accesspolicy`,
    `${path}/ipallowdeny`,
    `${path}/geofence`,
    `${path}/authentication`
  ]
  const styledOverlayShouldVisible = overlayPaths.some(
    path => path === location.pathname
  )

  return (
    <>
      {styledOverlayShouldVisible && (
        <StyledOverlay
          onClick={() => {
            history.push(`${path}`)
          }}
        />
      )}
      <CenteredContentContainer>
        <SystemUpgrade />
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/keys`}>
            <KeysDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/authenticator`}>
            <AuthenticatorDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/customattributes`}>
            <CustomAttributesDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/identityroles`}>
            <IdentityRolesDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/accesspolicy`}>
            <AccessPolicyDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/ipallowdeny`}>
            <IpAllowDenyDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/geofence`}>
            <GeofenceDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
        <AnimatedSwitch timeout={animationTimeout}>
          <Route path={`${path}/authentication`}>
            <AuthenticationDrawer closeLink={`${path}`} />
          </Route>
        </AnimatedSwitch>
      </CenteredContentContainer>
    </>
  )
}

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as AdressIcon } from '@lastpass/assets/svg/admin-console/icon-address.svg'
import { ReactComponent as GroupsIcon } from '@lastpass/assets/svg/admin-console/icon-groups.svg'
import {
  CardTable,
  Collapsible,
  SecurityScoreBadge,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { getSecurityScore } from '@lastpass/admin-console-dependencies/services/seurity-score'
import { userDetailsActions } from '@lastpass/admin-console-dependencies/state/users/details/actions'
import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { Group } from '@lastpass/admin-console-dependencies/types/group'

export type UserGroupsCardProps = LastPassComponentProps<
  UsersDrawerState['groups'],
  typeof userDrawerActions & typeof userDetailsActions,
  {
    detailLink: string
    groupLink: string
    expand: boolean
    onExpand: Function
  }
>

const StyledGroupsIcon = styled(GroupsIcon)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const UserGroupsSummaryCardComponent: React.FunctionComponent<UserGroupsCardProps> = ({
  state: groups,
  props,
  actions
}) => {
  const pathParams: UserDrawerUrlParams = usePathParams()

  useEffect(() => {
    if (props.expand) {
      actions.getUserGroups({ path: pathParams, query: {} }, 5)
    }
  }, [actions, pathParams, props.expand])

  return (
    <Collapsible
      title={<Trans>Groups</Trans>}
      expanded={props.expand}
      onInitialExpand={() => {
        actions.getUserGroups({ path: pathParams, query: {} }, 5)
      }}
      loading={groups.isLoading}
      onExpand={props.onExpand}
      qadata="GroupsCard"
    >
      {groups && (
        <CardTable
          qadata="UserLevelGroupList"
          table={groups}
          columns={[
            {
              name: <></>,
              component: StyledGroupsIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Name</Trans>,
              renderer: (record: Group) => record.name
            },
            {
              name: <Trans>Users</Trans>,
              renderer: function UsersCountRenderer(record: Group) {
                return <>{record.userCount && record.userCount.toString()}</>
              }
            },
            {
              name: <Trans>Security score</Trans>,
              renderer: function SharedFolderSecurityScoreRenderer(
                record: Group
              ) {
                return (
                  <SecurityScoreBadge
                    level={getSecurityScore(record.securityScore)}
                  />
                )
              }
            }
          ]}
          footerNodes={
            <>
              <TextButton blue onClick={actions.goToGroupsPage}>
                <StyledLink to={props.groupLink} data-qa="GoToGroupsPageButton">
                  <Trans>Go to Groups page</Trans>
                </StyledLink>
              </TextButton>
              <TextButton blue>
                <StyledLocationLink
                  to={props.detailLink}
                  data-qa="ViewAllButton"
                  discardnamespace={Namespace.groups}
                >
                  <Trans>
                    View all groups: {groups && groups.totalResults}
                  </Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<AdressIcon />}
              title={<Trans>This user doesn{"'"}t belong to any groups.</Trans>}
              text={
                <Trans>
                  Manage users in groups to bring your LastPass account under
                  your control.
                </Trans>
              }
              actionButton={
                <TableViewButton onClick={actions.goToGroupsPage}>
                  <StyledLink
                    to={props.groupLink}
                    data-qa="GoToGroupsPageButton"
                  >
                    <Trans>Go to Groups page</Trans>
                  </StyledLink>
                </TableViewButton>
              }
            />
          }
        />
      )}
    </Collapsible>
  )
}

export function createUserGroupsSummaryCard<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserGroupsSummaryCardComponent,
    (state: TState) => state.usersDrawer.groups,
    { ...userDrawerActions, ...userDetailsActions }
  )
}

export type UserGroupsSummaryCard = ReturnType<
  typeof createUserGroupsSummaryCard
>

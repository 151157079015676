import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import {
  MultifactorOptionType,
  MultifactorPolicyKeys
} from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  SaveTeamsPolicySwitchOnData,
  TeamsPolicyModelData
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export interface AllSetPageProps {
  closeLink: string
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
`

const BlockHeader = styled.h6`
  font-weight: ${props => props.theme.fonts.weight.semibold};
  margin-top: 24px;
  margin-bottom: 4px;
`
const maxElementsInList = 10

const getPolicyData = function(
  policyKey: string,
  data: TeamsPolicyModelData[]
): SaveTeamsPolicySwitchOnData {
  return {
    policyKey: policyKey,
    policyData: data
  }
}

const getItemsList = (items: string[]) =>
  items.length > maxElementsInList
    ? items.length
    : items.map((item, index) => <div key={index}>{item}</div>)

const getBlock = (groups: string[], users: string[]) => (
  <>
    {groups.length > 0 ? (
      <>
        <BlockHeader data-qa="AssignedGroups">
          <Trans>Groups</Trans>
        </BlockHeader>
        {getItemsList(groups)}
      </>
    ) : null}
    {users.length > 0 ? (
      <>
        <BlockHeader data-qa="AssignedUsers">
          <Trans>Users</Trans>
        </BlockHeader>
        {getItemsList(users)}
      </>
    ) : null}
  </>
)

const enabledPolicyValue = '1'

const getPolicyDataToSave = (
  state: MultifactorDrawerState
): TeamsPolicyModelData => ({
  policyValue: state.assignFormState.policyValue
    ? state.assignFormState.policyValue
    : enabledPolicyValue,
  policyNotes: state.assignFormState.policyNotes
    ? state.assignFormState.policyNotes
    : '',
  cpid: state.assignFormState.cpId,
  appliesTo: state.assignFormState.appliesTo
    ? state.assignFormState.appliesTo
    : AppliesToOptions.all,
  exclusiveList:
    state.assignFormState.appliesTo === AppliesToOptions.exclusive
      ? state.assignFormState.users.results
      : [],
  inclusiveList:
    state.assignFormState.appliesTo === AppliesToOptions.inclusive
      ? state.assignFormState.users.results
      : [],
  exclusiveGroupList:
    state.assignFormState.appliesTo === AppliesToOptions.exclusive
      ? state.assignFormState.groups.results
      : [],
  inclusiveGroupList:
    state.assignFormState.appliesTo === AppliesToOptions.inclusive
      ? state.assignFormState.groups.results
      : []
})

export const MultifactorAllSetPage: React.FunctionComponent<AllSetPageProps> = ({
  closeLink
}) => {
  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )
  const dispatch = useDispatch()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const policyData: TeamsPolicyModelData = getPolicyDataToSave(
    multifactorDrawerState
  )

  const getSaveAction = () => {
    const policyDataToSave: TeamsPolicyModelData[] = [policyData]
    let saveAction
    switch (multifactorDrawerState.selectedMultifactorOption) {
      case MultifactorOptionType.TotpApp: {
        dispatch(
          multifactorDrawerActions.saveTotpSettings(
            getPolicyData(MultifactorPolicyKeys.Totp, policyDataToSave),
            closeLink
          )
        )
        break
      }
      case MultifactorOptionType.Salesforce: {
        dispatch(
          multifactorDrawerActions.saveSalesforceSettings(
            getPolicyData(MultifactorPolicyKeys.Salesforce, policyDataToSave),
            closeLink
          )
        )
        break
      }
      case MultifactorOptionType.DuoSecurity: {
        const policy = getPolicyData(
          MultifactorPolicyKeys.DuoSecurity,
          policyDataToSave
        )
        multifactorDrawerState.isConfigured
          ? dispatch(
              multifactorDrawerActions.saveDuoSecuritySettings(
                policy,
                multifactorDrawerState.duoSecurityConfig,
                closeLink
              )
            )
          : dispatch(
              multifactorDrawerActions.savePolicyUsers(policy, closeLink)
            )
        break
      }
      case MultifactorOptionType.SymantecVip: {
        const policy = getPolicyData(
          MultifactorPolicyKeys.SymantecVip,
          policyDataToSave
        )
        multifactorDrawerState.isConfigured
          ? dispatch(
              multifactorDrawerActions.saveSymantecSettings(
                policy,
                multifactorDrawerState.symantecConfig,
                closeLink
              )
            )
          : dispatch(
              multifactorDrawerActions.savePolicyUsers(policy, closeLink)
            )
        break
      }
      case MultifactorOptionType.RsaSecureId: {
        const policy = getPolicyData(
          MultifactorPolicyKeys.RsaSecureId,
          policyDataToSave
        )
        multifactorDrawerState.isConfigured
          ? dispatch(
              multifactorDrawerActions.saveRsaSettings(
                policy,
                multifactorDrawerState.rsaConfig,
                closeLink
              )
            )
          : dispatch(
              multifactorDrawerActions.savePolicyUsers(policy, closeLink)
            )
        break
      }
      case MultifactorOptionType.SecureAuth: {
        const policy = getPolicyData(
          MultifactorPolicyKeys.SecureAuth,
          policyDataToSave
        )
        multifactorDrawerState.isConfigured
          ? dispatch(
              multifactorDrawerActions.saveSecureAuthSettings(
                policy,
                multifactorDrawerState.secureAuthConfig,
                closeLink
              )
            )
          : dispatch(
              multifactorDrawerActions.savePolicyUsers(policy, closeLink)
            )
        break
      }
    }
    return saveAction
  }

  useEffect(() => {
    setIsBlockingPromptEnabled(true)
    dispatch(multifactorDrawerActions.multifactorSetupConfirmationViewed())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const isEnabledForAllUsers =
    multifactorDrawerState.assignFormState.appliesTo == AppliesToOptions.all
  const includedUsers = (policyData.inclusiveList || []).map(user => user.email)
  const includedGroups = (policyData.inclusiveGroupList || []).map(
    group => group.name
  )
  const excludedUsers = (policyData.exclusiveList || []).map(user => user.email)
  const excludedGroups = (policyData.exclusiveGroupList || []).map(
    group => group.name
  )

  let includedContent: React.ReactElement | null = null
  let excludedContent: React.ReactElement | null = null
  let allUsersContent: React.ReactElement | null = null

  if (!isEnabledForAllUsers) {
    if (includedGroups.length > 0 || includedUsers.length > 0) {
      includedContent = getBlock(includedGroups, includedUsers)
    }

    if (excludedGroups.length > 0 || excludedUsers.length > 0) {
      excludedContent = (
        <>
          <Heading100
            css={css`
              margin-top: 24px;
            `}
          >
            <Trans>All users except</Trans>
          </Heading100>
          {getBlock(excludedGroups, excludedUsers)}
        </>
      )
    }
  }

  if (includedGroups.length === 0 && includedUsers.length === 0) {
    allUsersContent = (
      <>
        <Heading100
          data-qa="AllSetHeader"
          css={css`
            margin-top: 24px;
          `}
        >
          <Trans>All users selected</Trans>
        </Heading100>
      </>
    )
  }

  return (
    <MainContainer>
      <Trans>
        These users and groups will be prompted to enroll into MFA upon next
        login to a protected service. Once enrolled, they must verify their
        identity each time they log in to a protected service.
      </Trans>{' '}
      {allUsersContent}
      {includedContent}
      {excludedContent}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <LocationLink to={closeLink}>
            <TextButton
              css={css`
                margin-right: 16px;
              `}
              key="Discard"
              data-qa="CancelButton"
              onClick={() =>
                dispatch(
                  multifactorDrawerActions.multifactorSetupConfirmationClicked(
                    'Discard'
                  )
                )
              }
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            data-qa="ContinueButton"
            onClick={() => {
              setIsBlockingPromptEnabled(false)
              dispatch(
                multifactorDrawerActions.multifactorSetupConfirmationClicked(
                  'Save and Close'
                )
              )
              getSaveAction()
            }}
          >
            <Trans>Save {'&'} finish</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </MainContainer>
  )
}

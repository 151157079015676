import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { DuoSecuritySettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/state'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(duoSecuritySettings: DuoSecuritySettingsState) {
    try {
      const [response, status] = await teamsapi(
        'my-company/saveduosecuritysettings',
        'POST',
        {
          ['integration_key']: duoSecuritySettings.integrationKey,
          ['secret_key']: duoSecuritySettings.secretKey,
          ['api_hostname']: duoSecuritySettings.apiHostName
        }
      )
      if (status === StatusCodes.OK && response) {
        const saveSuccess = JSON.parse(response)
        if (saveSuccess.success) {
          return success()
        }
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

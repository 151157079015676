import { PsaProduct } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { OrderTypes } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/types/order-directions'

export type SortAlphabetically = (
  orderBy: OrderTypes
) => (product1: PsaProduct, product2: PsaProduct) => number

export const sortAlphabetically: SortAlphabetically = orderBy => (
  product1,
  product2
) => {
  const direction = orderBy === OrderTypes.ASC ? 1 : -1
  return product1.name.localeCompare(product2.name) * direction
}

import { CryptoArray, SmartCrypto } from '@lastpass/cryptography'

import { NO_KEYS_WERE_SHARED_ERROR } from '@lastpass/admin-console-dependencies/server/users/recovery-keys/recovery-key-sharing-details'
import { encodeString } from '@lastpass/admin-console-dependencies/services/text-operations/encode-string'
import {
  RecoveryKeySharingDetails,
  TargetAdminPublicRsaSharingKeys
} from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'
import { SuperAdminReencryptedRecoveryKey } from '@lastpass/admin-console-dependencies/types/recovery-keys/superadmin-reencrypted-recovery-key'

export async function reEncryptRecoveryKeys(
  recoveryKeySharingDetails: RecoveryKeySharingDetails,
  decryptedPrivateKeyRaw: string
) {
  const reEncryptedRecoveryKeys: SuperAdminReencryptedRecoveryKey[] = []
  const batchSize = recoveryKeySharingDetails.uploadBatchSize
  const iteratorCondition =
    batchSize < recoveryKeySharingDetails.userRecoveryKeys.length
      ? batchSize
      : recoveryKeySharingDetails.userRecoveryKeys.length
  for (let i = 0; i < iteratorCondition; i++) {
    const recoveryKey = recoveryKeySharingDetails.userRecoveryKeys[0]
    recoveryKeySharingDetails.userRecoveryKeys = recoveryKeySharingDetails.userRecoveryKeys.filter(
      key => key !== recoveryKey
    )

    let userRecoveryKeyRaw = Buffer.from('')

    try {
      const privateKey = await SmartCrypto.asymmetric.importPrivateKeyLegacy(
        encodeString(decryptedPrivateKeyRaw, 'hex', 'base64')
      )
      const decryptedString = await SmartCrypto.asymmetric.decrypt(
        encodeString(
          recoveryKey.targetUserEncryptedRecoveryKey,
          'hex',
          'base64'
        ),
        privateKey
      )
      userRecoveryKeyRaw = Buffer.from(decryptedString, 'hex')
    } catch {
      // do nothing
    }

    // TODO: need to modify to support multiple targetSuperAdminUserIds, now taking the first only
    const targetSuperAdminUserId = recoveryKey.targetAdminLastPassIds[0]

    const superAdminPublicRsaSharingKey:
      | TargetAdminPublicRsaSharingKeys
      | undefined = recoveryKeySharingDetails.targetAdminPublicRsaSharingKeys.find(
      key => key.lastPassId === targetSuperAdminUserId
    )

    if (!superAdminPublicRsaSharingKey) {
      continue
    }

    if (userRecoveryKeyRaw.toString() !== '') {
      try {
        const publicKey = await SmartCrypto.asymmetric.importPublicKeyLegacy(
          encodeString(superAdminPublicRsaSharingKey.publicKey, 'hex', 'base64')
        )
        const encrypted = CryptoArray.fromBase64(
          await SmartCrypto.asymmetric.encrypt(
            userRecoveryKeyRaw.toString('base64'),
            publicKey
          )
        )
        const reencryptedUserRecoveryKey: Buffer = Buffer.from(encrypted)

        const superAdminReencryptedRecoveryKey: SuperAdminReencryptedRecoveryKey = {
          targetAdminLastPassId: targetSuperAdminUserId,
          targetUserLastPassId: recoveryKey.targetUserLastPassId,
          reEncryptedKey: reencryptedUserRecoveryKey.toString('hex')
        }

        // TODO: we may not want to push the target admin's own recovery key
        // we may need to fix this in server side
        if (
          superAdminReencryptedRecoveryKey.targetAdminLastPassId ===
          superAdminReencryptedRecoveryKey.targetUserLastPassId
        ) {
          continue
        }

        reEncryptedRecoveryKeys.push(superAdminReencryptedRecoveryKey)
      } catch (e) {
        if (e instanceof Error && e.name === 'TypeError') {
          continue
        } else {
          throw new Error(NO_KEYS_WERE_SHARED_ERROR)
        }
      }
    }
  }
  return reEncryptedRecoveryKeys
}

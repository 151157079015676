import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { DetailsDialog, Heading200 } from '@lastpass/lastkit'

import { dialogWidth, StyledCenter } from './SystemUpgradeDialog'

const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
`

export const SystemUpgradeLoadingDialog: FunctionComponent = () => (
  <DetailsDialog
    disableMaxHeight
    closeDialog={() => {}}
    hideCloseIcon={true}
    width={dialogWidth}
    content={
      <StyledCenter data-qa="SystemUpgradeLoadingDialog">
        <div>
          <StyledLoadingContainer>
            <Loading color="blue900" active={true} data-qa="LoadingAnimation" />
          </StyledLoadingContainer>
          <Heading200>
            <Trans>System upgrade in progress...</Trans>
          </Heading200>
        </div>
      </StyledCenter>
    }
  />
)

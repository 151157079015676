import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = (response: boolean) => createResponse(SUCCESS, response)

export const responses = {
  success
}

export type Responses = ResponseTypes<typeof responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch(
      'company/mfasetup/reset-shared-totp-secrets',
      'POST',
      {
        'Content-Type': 'application/json'
      }
    )

    if (status !== StatusCodes.OK) {
      throw new UnknownServiceError()
    }

    const result = JSON.parse(response)
    const isDelayedJobStarted = result.content.isDelayedJobStarted
    return success(isDelayedJobStarted)
  }
}

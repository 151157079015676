import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as GroupsIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  DrawerTable,
  FooterContainer,
  Pagination,
  PrimaryButton,
  RowContainer,
  SearchInput,
  StyledSpacer,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { useUnmountEffect } from '@lastpass/ui'

import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

export const GroupDrawerUsersTable: React.FunctionComponent = () => {
  const groupDrawerState: GroupDrawerState = useSelector(
    (state: AppState) => state.groupDrawer
  )
  const queryParams = useQueryParams(Namespace.groupUsers)
  const updateQuery = useUpdateQuery(Namespace.groupUsers)
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      groupDrawerActions.getGroupUsers({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Users</Trans>)
  useUnmountEffect(() => {
    dispatch(groupDrawerActions.setSelectedGroupUsers([]))
  })

  const permissions = useContext(PermissionContext)
  return (
    <>
      <RowContainer>
        <SearchInput
          flexGrow
          value={queryParams.search}
          placeholder={msg`Search users`}
          onChange={e =>
            updateQuery({
              search: e.target.value || undefined,
              skip: undefined
            })
          }
          data-qa={'SearchUsersInputField'}
        />
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={groupDrawerState.users.totalResults}
          resultsPerPage={groupDrawerState.users.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </RowContainer>
      <DrawerTable
        qadata={'GroupLevelFullUsersList'}
        table={groupDrawerState.users}
        noRecordsView={
          <TableView
            icon={<GroupsIcon />}
            title={<Trans>No users assigned yet</Trans>}
            text={
              <Trans>Make this group useful by assigning some users.</Trans>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<GroupsIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        checkable={{
          addSelectedRecords: records =>
            dispatch(groupDrawerActions.addSelectedGroupUsers(records)),
          removeSelectedRecords: records =>
            dispatch(groupDrawerActions.removeSelectedGroupUsers(records)),
          setSelectedRecords: records =>
            dispatch(groupDrawerActions.setSelectedGroupUsers(records))
        }}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: (record: User) => getFullName(record)
          },
          {
            name: <Trans>Email</Trans>,
            renderer: (record: User) => record.email
          }
        ]}
        loadingView={<Loading color="blue900" active={true} />}
        actions={[]}
        isFilterApplied={!!queryParams.search}
      />
      {!!groupDrawerState.users.checkedRecords.length && (
        <>
          <StyledSpacer />
          <FooterContainer>
            <DrawerButtonsContainer>
              {permissions.requirePermission(AdminPermissions.groupsModify) && (
                <PrimaryButton
                  onClick={() => {
                    dispatch(
                      groupDrawerActions.removeUsersFromGroup(
                        groupDrawerState.users.checkedRecords,
                        groupDrawerState.group
                      )
                    )
                  }}
                  data-qa={'RemoveUsersButton'}
                >
                  {(groupDrawerState.users.checkedRecords.length == 1 && (
                    <Trans>Remove User</Trans>
                  )) || <Trans>Remove Users</Trans>}
                </PrimaryButton>
              )}
            </DrawerButtonsContainer>
          </FooterContainer>
        </>
      )}
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = currentAdminLevel => createResponse(SUCCESS, currentAdminLevel)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch(
      'adminLevels/currentAdminLevelId',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const currentAdminLevel = JSON.parse(response).content
      return success(currentAdminLevel)
    }
    throw new UnknownServiceError()
  }
}

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { GroupFilter } from '@lastpass/admin-console-dependencies/types/group-filter'

export enum GroupFilterActionTypes {
  GET_GROUP_LIST = '[group-filter] get-group-list',
  SET_GROUP_LIST = '[group-filter] set-group-list',
  SEARCH_GROUPS = '[group-filter] search-groups',
  SET_GROUPS = '[group-filter] set-groups',
  SET_GROUPS_LIST_BY_ID_LIST = '[group-filter] set-by-id-list',
  GET_GROUPS_LIST_BY_ID_LIST = '[group-filter] get-by-id-list'
}

export interface GroupFilterQueryParams {
  search: string
}

export const groupFilterActions = {
  getGroupList: (resultsPerPage = 1000) =>
    createAction(GroupFilterActionTypes.GET_GROUP_LIST, {
      params: {
        query: {},
        path: {}
      },
      resultsPerPage
    }),
  setGroupList: (list: GroupFilter[], totalResults: number) =>
    createAction(GroupFilterActionTypes.SET_GROUP_LIST, {
      list,
      totalResults
    }),

  searchGroups: (searchInput: string, resultsPerPage = 100) =>
    createAction(GroupFilterActionTypes.SEARCH_GROUPS, {
      params: {
        query: {
          search: searchInput
        },
        path: {}
      },
      resultsPerPage
    }),
  setGroups: (results: GroupFilter[], searchInput: string) =>
    createAction(GroupFilterActionTypes.SET_GROUPS, {
      results,
      searchInput
    }),

  getGroupListByIdList: (idList: string[]) =>
    createAction(GroupFilterActionTypes.GET_GROUPS_LIST_BY_ID_LIST, {
      idList
    }),
  setGroupListByIdList: (groups: GroupFilter[]) =>
    createAction(GroupFilterActionTypes.SET_GROUPS_LIST_BY_ID_LIST, { groups })
}

export type GroupFilterActions = ActionsUnion<typeof groupFilterActions>

import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { RouteProps } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading300, TabNavigation } from '@lastpass/lastkit'
import { RoutedTabNavigationItem } from '@lastpass/lastkit'
import { HeaderContainer, TabContainer } from '@lastpass/lastkit'

import { NeverOnlyUrls } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/NeverOnlyUrls'
import { SplunkIntegration } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/SplunkIntegration'
import { ComingSoon } from '@lastpass/admin-console-dependencies/ui/common/ComingSoon'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { MultifactorOptions } from './MultifactorOptions'
import { UrlRules } from './UrlRulesPage'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`

export const EnterpriseOptionsPage: React.FunctionComponent = () => {
  const isSiemIntegrationEnabled = useFeatureFlags(
    FeatureFlags.isSiemIntegrationEnabled
  )

  const match = useRouteMatch()
  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const [
      urlRules,
      equivalentDomains,
      globalNeverOnlyUrls,
      splunkIntegration,
      multifactorOptions
    ] = [
      'urlRules',
      'equivalentDomains',
      'globalNeverOnlyUrls',
      'splunkIntegration',
      'multifactorOptions'
    ].map(path => {
      const route: RouteProps = { path: `${matchPath}/${path}` }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    })

    return (
      <>
        <PageHeaderContainer data-qa={'PageHeaderContainer'}>
          <Heading300 data-qa={'EnterpriseOptionsPageHeader'}>
            <Trans>Enterprise options</Trans>
          </Heading300>
        </PageHeaderContainer>
        <TabContainer data-qa={'TabContainer'}>
          <TabNavigation>
            <RoutedTabNavigationItem
              data-qa={'TabMenuUrlRules'}
              to={urlRules.url}
              route={urlRules.route}
            >
              <Trans>URL rules</Trans>
            </RoutedTabNavigationItem>
            <RoutedTabNavigationItem
              data-qa={'TabMenuEquivalentDomains'}
              to={equivalentDomains.url}
              route={equivalentDomains.route}
            >
              <Trans>Equivalent domains</Trans>
            </RoutedTabNavigationItem>
            <RoutedTabNavigationItem
              data-qa={'TabMenuGlobalNeverOnlyUrls'}
              to={globalNeverOnlyUrls.url}
              route={globalNeverOnlyUrls.route}
            >
              <Trans>Global never/only URLs</Trans>
            </RoutedTabNavigationItem>
            {!isSiemIntegrationEnabled && (
              <RoutedTabNavigationItem
                data-qa={'TabMenuSplunkIntegration'}
                to={splunkIntegration.url}
                route={splunkIntegration.route}
              >
                <Trans>Splunk integration</Trans>
              </RoutedTabNavigationItem>
            )}
            <RoutedTabNavigationItem
              data-qa={'TabMenuMultifactorOptions'}
              to={multifactorOptions.url}
              route={multifactorOptions.route}
            >
              <Trans>Multifactor options</Trans>
            </RoutedTabNavigationItem>
          </TabNavigation>
        </TabContainer>
        <Switch>
          <Route {...urlRules.route}>
            <UrlRules />
          </Route>
          <Route {...equivalentDomains.route}>
            <ComingSoon oldUrl="/settings/advanced-options/equivalent-domains" />
            {/* <EquivalentDomains /> */}
          </Route>
          <Route {...globalNeverOnlyUrls.route}>
            <NeverOnlyUrls />
          </Route>
          {!isSiemIntegrationEnabled && (
            <Route {...splunkIntegration.route}>
              <SplunkIntegration />
            </Route>
          )}
          <Route {...multifactorOptions.route}>
            <MultifactorOptions />
          </Route>
        </Switch>
      </>
    )
  }
  return null
}

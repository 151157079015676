import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'
import { SegmentPropertyType, uacEvent } from '@lastpass/tracking'
import { IdentityProvider } from '@lastpass/tracking/tracking-plans/uac'

export const directoryMap: {
  [s: string]: SegmentPropertyType<
    'Federated login enabled for company' | 'PKCE enabled for federated login',
    'identityprovider'
  >
} = {
  adConnector: 'AD Connector',
  azureAD: 'Azure AD',
  okta: 'Okta',
  oneLogin: 'OneLogin',
  google: 'Google',
  pingOne: 'PingOne'
}

const directoryMapForIdentityProvider: {
  [s in OpenIdProvider]: IdentityProvider
} = {
  [OpenIdProvider.Google]: 'Google',
  [OpenIdProvider.AzureAD]: 'Azure',
  [OpenIdProvider.Okta]: 'Okta',
  [OpenIdProvider.OneLogin]: 'OneLogin',
  [OpenIdProvider.PingOne]: 'PingOne',
  [OpenIdProvider.ADConnector]: 'ADFS'
}

export const createFederatedLoginEnabledSegment = (
  provider: OpenIdProvider,
  pkceEnabled: boolean
) => {
  return {
    segment: uacEvent('Federated login enabled for company', {
      identityprovider: directoryMap[provider],
      'PKCE flag': pkceEnabled ? 'On' : 'Off'
    })
  }
}

export const createKeyRotationStartedSegment = (provider: OpenIdProvider) => {
  return {
    segment: uacEvent('Knowledge component rotation started', {
      'Identity provider': directoryMapForIdentityProvider[provider]
    })
  }
}

export const createKeyRotationFinishedSegment = (provider: OpenIdProvider) => {
  return {
    segment: uacEvent('Knowledge component rotation finished', {
      'Identity provider': directoryMapForIdentityProvider[provider]
    })
  }
}

export const createPkceEnabledSegment = (provider: OpenIdProvider) => {
  return {
    segment: uacEvent('PKCE enabled for federated login', {
      identityprovider: directoryMap[provider]
    })
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import {
  BodyRegular,
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { FileUploader } from '@lastpass/lastkit/components/FileUpload'
import { LocationLink } from '@lastpass/routing'

import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { MultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { BodyText } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'
import { MultifactorTypeAuthMethodMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

export interface RsaConfigProps {
  closeLink: string
  isLastStep: boolean
}

const OrderedList = styled.ol`
  margin: 17px 0;
  counter-reset: howToEnrollList;

  li {
    counter-increment: howToEnrollList;
    margin-top: 16px;
    padding-left: 32px;
    position: relative;
  }

  li:before {
    content: counters(howToEnrollList, '.') ' ';
    background: ${props => props.theme.colors.blue200};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    font-weight: 600;
    margin-right: 8px;
    position: absolute;
    left: 0;
    top: 0;
  }
`

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const InputContainerWithNoBottomPadding = styled.div`
  width: 100%;
`

const FileUploadContainer = styled.div`
  display: flex;
`

const FileUploadLabel = styled.div`
  margin-right: 24px;
`

const FileUploadLabelBottom = styled.div`
  margin-right: 32px;
`

const StyledHead100 = styled(Heading100)`
  margin-bottom: 20px;
`

const InputNote = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`

export const RsaConfig: React.FunctionComponent<RsaConfigProps> = ({
  closeLink,
  isLastStep
}) => {
  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )
  const globalState: GlobalState = useSelector(
    (state: AppState) => state.global
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(multifactorDrawerActions.getRsaConfig())
  }, [dispatch])

  const randomInt = Math.floor(Math.random() * 1000)

  return (
    <BodyRegular>
      <BodyText>
        <Trans>
          Here{"'"}s how to set up RSA SecurID authentication via RADIUS:
        </Trans>
      </BodyText>
      <OrderedList>
        <li>
          <Trans>
            Go to your RSA authentication manager and set up a client using the{' '}
            {"'"}ANY Client{"'"} option.
          </Trans>
        </li>
        <li>
          <Trans>
            Set up a firewall to allow connections only from LastPass server IP
            addresses (ask your LastPass representative if unsure).
          </Trans>
        </li>
        <li>
          <Trans>
            Edit securid.ini and change CheckUserAllowedByClient from 1 to 0.
          </Trans>
        </li>
      </OrderedList>
      <BodyText>
        <Trans>
          Tip: LastPass uses an outbound firewall, so the LastPass operations
          team must allow your server{"'"}s IP for ports other than 1812 or
          1645.
        </Trans>
      </BodyText>
      <Formik
        onSubmit={values => {
          if (isLastStep) {
            dispatch(multifactorDrawerActions.saveRsaConfig(values, closeLink))
          } else {
            dispatch(multifactorDrawerActions.processRsaConfig(values))
            dispatch(multifactorDrawerActions.setIsConfigured(true))
          }
        }}
        initialValues={multifactorDrawerState.rsaConfig}
        enableReinitialize
      >
        {formikProps => (
          <Form>
            <InputContainerWithNoBottomPadding>
              <Field name="radiusServerIp">
                {formData => (
                  <TextInput
                    data-qa="RadiusServerIp"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>RADIUS server IP address</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainerWithNoBottomPadding>
            <InputNote>
              <Trans>
                Separate multiple items with commas and append {"'"}:port {"'"}{' '}
                if not 1812
              </Trans>
            </InputNote>
            <InputContainer>
              <Field name="radiusSharedSecret">
                {formData => (
                  <TextInput
                    data-qa="RadiusSharedSecret"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                    isPassword={true}
                  >
                    <Trans>RADIUS shared secret</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="radiusTimeout">
                {formData => (
                  <TextInput
                    data-qa="RadiusTimeout"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>RADIUS timeout (in seconds)</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="failureMessage">
                {formData => (
                  <TextInput
                    data-qa="FailureMessage"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>Failure message</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <BodyText>
              <Trans>
                RADIUS can also be used to support multifactor authentication
                options other than RSA SecurID (such as SafeNet).
              </Trans>
            </BodyText>
            <StyledHead100>
              <Trans>Customize service name and logos</Trans>
            </StyledHead100>
            <InputContainer>
              <Field name="serviceName">
                {formData => (
                  <TextInput
                    data-qa="ServiceName"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>Service name</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="logo124x124">
                {formData => (
                  <>
                    <FileUploadContainer>
                      <FileUploadLabel>
                        <Trans>124x124 PNG Logo:</Trans>
                      </FileUploadLabel>
                      <FileUploader
                        label={msg`Upload`}
                        onChange={(e, file) => {
                          formikProps.setFieldValue(formData.field.name, file)
                          formikProps.values.logo124x124Length = file
                            ? file.size
                            : 0
                          e.target.value = ''
                        }}
                        deleteFile={() => {
                          formikProps.setFieldValue(formData.field.name, null)
                        }}
                        acceptedFileTypes={'.png'}
                        id={'logo124x124'}
                      />
                    </FileUploadContainer>
                    {multifactorDrawerState.rsaConfig.logo124x124Length > 0 && (
                      <img
                        src={`${defaultConfig.lastPassBaseAddress}/radius_logo.php?uid=${globalState.currentUser.userId}&rand=${randomInt}`}
                      />
                    )}
                  </>
                )}
              </Field>
              <Field name="logo190x41">
                {formData => (
                  <>
                    <FileUploadContainer>
                      <FileUploadLabelBottom>
                        <Trans>190x41 PNG Logo:</Trans>
                      </FileUploadLabelBottom>
                      <FileUploader
                        label={msg`Upload`}
                        onChange={(e, file) => {
                          formikProps.setFieldValue(formData.field.name, file)
                          formikProps.values.logo190x41Length = file
                            ? file.size
                            : 0
                          e.target.value = ''
                        }}
                        deleteFile={() => {
                          formikProps.setFieldValue(formData.field.name, null)
                        }}
                        acceptedFileTypes={'.png'}
                        id={'logo190x41'}
                      />
                    </FileUploadContainer>
                    {multifactorDrawerState.rsaConfig.logo190x41Length > 0 && (
                      <img
                        src={`${defaultConfig.lastPassBaseAddress}/radius_logo.php?uid=${globalState.currentUser.userId}&size=190&rand=${randomInt}`}
                      />
                    )}
                  </>
                )}
              </Field>
            </InputContainer>
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <LocationLink to={closeLink}>
                  <TextButton
                    css={css`
                      margin-right: 16px;
                    `}
                    key="Discard"
                    data-qa="CancelButton"
                    onClick={() =>
                      dispatch(
                        multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                          MultifactorTypeAuthMethodMapping[
                            MultifactorOptionType.RsaSecureId
                          ],
                          'Discard'
                        )
                      )
                    }
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  id="ContinueButton"
                  key="Continue"
                  type="submit"
                  disabled={isLastStep && !formikProps.dirty}
                  data-qa="ContinueButton"
                  onClick={() =>
                    dispatch(
                      multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                        MultifactorTypeAuthMethodMapping[
                          MultifactorOptionType.RsaSecureId
                        ],
                        'Continue'
                      )
                    )
                  }
                >
                  {isLastStep ? (
                    <Trans>Save {'&'} exit</Trans>
                  ) : (
                    <Trans>Continue</Trans>
                  )}
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </BodyRegular>
  )
}

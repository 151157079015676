import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SymantecVipSettingsGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { SymantecVipSettings } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createGetSymantecConfig(services: UACServices.Services) {
  return function* getSymantecConfig() {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      try {
        const response = yield call(services.symantecVipSettingsGet)

        if (response.type === SymantecVipSettingsGetAPI.SUCCESS) {
          const settings: SymantecVipSettings =
            response.body.symantecVipSettings

          yield put(
            multifactorDrawerActions.setSymantecConfig({
              certificate: settings.certificate,
              certificateLength: settings.certificateLength,
              certificatePassword: settings.certificatePassword,
              certificatePasswordLength: settings.certificatePasswordLength,
              hasCertificatePassword: settings.hasCertificatePassword
            })
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

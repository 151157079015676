import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'

import { EnumDictionary } from '../EnumValues'

export const getAccountStatusMapping = (
  isAccountStatusRenamed: boolean
): EnumDictionary<AccountStatus, ReactElement> => ({
  [AccountStatus.active]: !isAccountStatusRenamed ? (
    <Trans>Active</Trans>
  ) : (
    <Trans>Enrolled</Trans>
  ),
  [AccountStatus.enrolled]: <Trans>Enrolled</Trans>,
  [AccountStatus.awaitingApproval]: <Trans>Awaiting approval</Trans>,
  [AccountStatus.disabled]: <Trans>Disabled</Trans>,
  [AccountStatus.invited]: !isAccountStatusRenamed ? (
    <Trans>Invited</Trans>
  ) : (
    <Trans>Pending invitation</Trans>
  ),
  [AccountStatus.pendingInvitation]: <Trans>Pending invitation</Trans>,
  [AccountStatus.expiredInvitation]: <Trans>Expired invitation</Trans>,
  [AccountStatus.loggedIn]: <Trans>Logged In</Trans>,
  [AccountStatus.staged]: <Trans>Staged</Trans>,
  [AccountStatus.na]: <Trans>Unknown</Trans>
})

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'

import { EnumDictionary } from '../EnumValues'

export const SecurityScoreLevelMapping: EnumDictionary<
  SecurityScoreLevel,
  ReactElement
> = {
  [SecurityScoreLevel.na]: <Trans>N/A</Trans>,
  [SecurityScoreLevel.low]: <Trans>Low</Trans>,
  [SecurityScoreLevel.average]: <Trans>Average</Trans>,
  [SecurityScoreLevel.high]: <Trans>High</Trans>,
  [SecurityScoreLevel.highest]: <Trans>Highest</Trans>
}

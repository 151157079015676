import React, { useState } from 'react'

import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Loading } from '@lastpass/components'

import { AlienVaultIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import { useSiemIntegrationsSetConfig } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/api-hooks/use-siem-integrations-set-config'
import { SiemIntegrationsEnabledCheckbox } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/SiemIntegrationsEnabledCheckbox'
import { SiemIntegrationsSubmitButton } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/SiemIntegrationsSubmitButton'
import {
  StyledFormRow,
  StyledTextInput
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

interface AlienVaultFormProps {
  config: AlienVaultIntegrationConfig
}

export const AlienVaultForm: React.FunctionComponent<AlienVaultFormProps> = ({
  config
}) => {
  const { isLoading, setAlienVaultConfig } = useSiemIntegrationsSetConfig()

  const [
    currentConfig,
    setCurrentConfig
  ] = useState<AlienVaultIntegrationConfig | null>(null)

  const yupSchema = Yup.object().shape({
    host: Yup.string()
      .trim()
      .required()
  })

  return (
    <>
      {isLoading && (
        <Loading
          data-qa="AlienVaultLoadingIndicator"
          color="blue900"
          active={true}
        />
      )}
      <Formik
        initialValues={currentConfig ?? config}
        enableReinitialize
        validationSchema={yupSchema}
        onSubmit={values => {
          setAlienVaultConfig({
            config: values,
            successMessage: msg`Nice! You've set up the AlienVault USM Anywhere integration.`
          })
          setCurrentConfig(values)
        }}
      >
        {formik => (
          <Form data-qa="AlienVaultForm">
            <StyledFormRow>
              <StyledTextInput
                data-qa="AlienVaultHostInput"
                name="host"
                placeholder={msg`Your AlienVault USM Anywhere instance URL`}
                value={formik.values.host}
                error={!!formik.errors.host}
                onChange={formik.handleChange}
              >
                <Trans>Host</Trans>
              </StyledTextInput>
            </StyledFormRow>

            <SiemIntegrationsEnabledCheckbox
              dataQa="AlienVaultEnabledCheckbox"
              formik={formik}
            />

            <SiemIntegrationsSubmitButton
              dataQa="AlienVaultSaveChangesButton"
              formik={formik}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-work-completed.svg'
import { Loading } from '@lastpass/components/Loading'
import { DrawerTable, TableView } from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import {
  sharedFolderDrawerActions,
  SharedFolderScore
} from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/actions'
import { SharedFolderScoresListState } from '@lastpass/admin-console-dependencies/state/shared-folders/drawer/state'

const StyledRow = styled.div`
  margin-left: 5px;
`

export const SharedFolderScoresList: React.FunctionComponent = () => {
  const sharedFolderScoresListState: SharedFolderScoresListState = useSelector(
    (state: AppState) => state.sharedFolderDrawer
  )

  const pathParams = usePathParams()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      sharedFolderDrawerActions.getSharedFolderScoresList({
        query: {},
        path: pathParams
      })
    )
  }, [dispatch, pathParams])

  const filteredScores = {
    ...sharedFolderScoresListState.scoresTable,
    results: sharedFolderScoresListState.scoresTable.results.filter(
      value => value.score
    )
  }

  return (
    <>
      <DrawerTable
        table={filteredScores}
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No security score available</Trans>}
            text={<Trans>To generate a score, assign apps</Trans>}
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Measurement</Trans>,
            renderer: function SharedFolderNameRenderer(
              record: SharedFolderScore
            ) {
              return <StyledRow>{i18n._(record.measurement)}</StyledRow>
            }
          },
          {
            name: <Trans>Score</Trans>,
            renderer: function SharedFolderSecurityScoreRenderer(
              record: SharedFolderScore
            ) {
              return <StyledRow>{record.score}</StyledRow>
            },
            contentWidth: '150px'
          }
        ]}
      />
    </>
  )
}

import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import { EnterpriseApiServiceType } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { enterpriseApiActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-api/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  ConfirmDialog,
  TextDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { NotificationUserEmailNotVerified } from '@lastpass/admin-console-dependencies/ui/users/view/NotificationUserEmailNotVerified'

export function createGenerateProvisioningHash(
  enterpriseApiServices: EnterpriseApiServiceType
) {
  return function* generateProvisioningHash(
    action: ReturnType<typeof enterpriseApiActions.generateProvisioningHash>
  ) {
    try {
      // TODO: add verify email flow
      let dialogText
      if (action.payload.reset) {
        dialogText = msg`Are you sure you want to reset your provisioning hash?
This will break all existing API clients and require you to use the new hash. You will also need to logout of the AD client and clear its cache.
An email will be sent to all admins informing them of the change.`
      } else {
        dialogText = msg`Save this hash in a secure place, such as your vault. If you forget your hash, you will need to reset it here.`
      }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        confirmText: msg`Ok`,
        discardText: msg`Cancel`,
        text: dialogText,
        title: msg`Create provisioning hash`
      }

      yield put(globalActions.setDialog(dialog))

      const dialogAction = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (dialogAction.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const emailVerified = yield call(enterpriseApiServices.emailVerified)
        if (emailVerified) {
          yield call(enterpriseApiServices.resetAdKey)
          const hash = yield call(
            enterpriseApiServices.generateProvisioningHash
          )
          if (hash === '') {
            const failedDialog: TextDialog = {
              type: 'textdialog',
              title: msg`Failed`,
              text: msg`Your provision hash has changed too many times today.`
            }
            yield put(globalActions.setDialog(failedDialog))
          } else {
            yield put(enterpriseApiActions.setProvisioningHash(hash))
          }
        } else {
          yield put(
            globalActions.setNotification({
              type: NotificationType.warning,
              autoDismiss: false,
              component: NotificationUserEmailNotVerified
            })
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { UrlRulesActions, UrlRulesActionTypes } from './actions'
import {
  NewUrlFormState,
  URLRuleOptionsFormState,
  UrlRulesListState
} from './state'

export const initialUrlRulesListState: UrlRulesListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function urlRules(
  previousState: UrlRulesListState | undefined,
  action: UrlRulesActions
): UrlRulesListState {
  const state = previousState || initialUrlRulesListState
  switch (action.type) {
    case UrlRulesActionTypes.URL_RULES_LOADING_SET:
      return {
        table: {
          ...state.table,
          isLoading: action.payload.isLoading
        }
      }
    case UrlRulesActionTypes.URL_RULES_SET:
      return {
        table: {
          ...state.table,
          results: action.payload.rules,
          totalResults: action.payload.rules.length
        }
      }
    case UrlRulesActionTypes.ADD_SELECTED_RULES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.concat(action.payload.rules)
        }
      }
    }
    case UrlRulesActionTypes.REMOVE_SELECTED_RULES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.filter(
            el => !action.payload.rules.includes(el)
          )
        }
      }
    }
    case UrlRulesActionTypes.SET_SELECTED_RULES: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: action.payload.rules
        }
      }
    }
    default: {
      return state
    }
  }
}

export const initialNewUrlFormState: NewUrlFormState = {
  newUrlRuleForm: {
    domainOrHost: '',
    path: '',
    isExactHostMatch: false,
    isExactPortMatch: false,
    isLoading: false
  }
}

export function newURLForm(
  previousState: NewUrlFormState | undefined,
  action: UrlRulesActions
): NewUrlFormState {
  const state = previousState || initialNewUrlFormState
  switch (action.type) {
    default: {
      return state
    }
  }
}

export const initialURLRuleOptionsFormState: URLRuleOptionsFormState = {
  urlRuleOptionsForm: {
    isUrlRulesCaseInsensitive: false,
    isLoading: false
  }
}

export function urlRuleOptionsForm(
  previousState: URLRuleOptionsFormState | undefined,
  action: UrlRulesActions
): URLRuleOptionsFormState {
  const state = previousState || initialURLRuleOptionsFormState
  switch (action.type) {
    case UrlRulesActionTypes.URL_RULES_SET_OPTIONS:
      return { urlRuleOptionsForm: action.payload.options }
    case UrlRulesActionTypes.URL_RULES_OPTIONS_LOADING_SET:
      return {
        urlRuleOptionsForm: {
          ...state.urlRuleOptionsForm,
          isLoading: action.payload.isLoading
        }
      }
    default: {
      return state
    }
  }
}

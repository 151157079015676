import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { AssignActionTypes } from '@lastpass/admin-console-dependencies/state/common/assign/actions'
import { GroupFilterActionTypes } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import { UserFilterActionTypes } from '@lastpass/admin-console-dependencies/state/common/user-filter/actions'
import { GlobalActionTypes } from '@lastpass/admin-console-dependencies/state/global/actions'

import { createDiscardFormSaga } from './create-discard-form'
import { createGetAvailableEntitySaga } from './get-available-entity'
import { createGroupListSaga } from './group-filter/group-list'
import { createGroupListByIdListSaga } from './group-filter/group-list-by-id-list'
import { createGroupSearchSaga } from './group-filter/group-search'
import { createUserListByIdListSaga } from './user-filter/user-list-by-id-list'

export function createCommonSaga(commonService: UACServices.Services) {
  const getAvailableEntity = createGetAvailableEntitySaga(commonService)
  const discardFormSaga = createDiscardFormSaga()
  const getUserListByIdListSaga = createUserListByIdListSaga(commonService)
  const getGroupListByIdListSaga = createGroupListByIdListSaga(commonService)
  const getGroupListSaga = createGroupListSaga(commonService)
  const getGroupSearchSaga = createGroupSearchSaga(commonService)

  return function*() {
    yield takeLatest(AssignActionTypes.GET_AVAILABLE_ENTITY, getAvailableEntity)
    yield takeEvery(
      GlobalActionTypes.SHOW_CONFIRM_DIALOG_ON_DISCARD_FORM,
      discardFormSaga
    )
    yield takeEvery(
      UserFilterActionTypes.GET_USERS_LIST_BY_ID_LIST,
      getUserListByIdListSaga
    )
    yield takeEvery(
      GroupFilterActionTypes.GET_GROUPS_LIST_BY_ID_LIST,
      getGroupListByIdListSaga
    )
    yield takeEvery(GroupFilterActionTypes.GET_GROUP_LIST, getGroupListSaga)
    yield takeEvery(GroupFilterActionTypes.SEARCH_GROUPS, getGroupSearchSaga)
  }
}

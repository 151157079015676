import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Mfa from '@lastpass/admin-console-dependencies/server/applications/mfa'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/delete-application-server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { mfaApplicationListActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'
import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createRemoveMfaApplication(
  applicationsServices: UACServices.Services
) {
  return function* removeMfaApplication(
    action: ReturnType<typeof mfaAppsDrawerActions.removeApp>
  ) {
    try {
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Remove app`,
        text: msg`This erases all associated data from the Admin Console and prevents users from authenticating with this app using MFA.`,
        discardText: msg`Cancel`,
        confirmText: msg`Remove`
      }

      yield put(globalActions.setDialog(dialog))

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield put(mfaAppsDrawerActions.setDrawerLoading(true))

        const removeRequest =
          action.payload.type === MfaApplicationType.azureAD
            ? applicationsServices.removeAzureMfaApplication
            : applicationsServices.removeMfaApplication
        const response: Responses = yield call(
          removeRequest,
          action.payload.key
        )

        if (
          response.type === Mfa.MfaApplicationRemoveAPI.SUCCESS ||
          response.type === Mfa.AzureMfaApplicationRemoveAPI.SUCCESS
        ) {
          yield put(
            globalActions.setNotification({
              message: msg`App deleted.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )

          const currentUrlFilters = yield select(getCurrentUrlFilters)
          yield put(
            mfaApplicationListActions.getMfaApplicationList({
              query: getQueryParams(currentUrlFilters.location, 'apps'),
              path: {}
            })
          )
          yield put(push('/applications/mfa'))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(mfaAppsDrawerActions.setDrawerLoading(false))
    }
  }
}

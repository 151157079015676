import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  ManagedCompany,
  ManagedCompanyAdmin
} from '@lastpass/admin-console-dependencies/types/managed-company'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = (managedCompanyAdminList: ManagedCompanyAdmin[]) =>
  createResponse(SUCCESS, managedCompanyAdminList)

const failure = () => createResponse(FAILURE)

export const responses = {
  success,
  failure
}

export type Responses = ResponseTypes<typeof responses>

export function create(fetch: AuthFetch) {
  return async function service(
    managedCompanyLastPassId: ManagedCompany['id']
  ) {
    const [response, status] = await fetch(
      `company/managed-companies/${managedCompanyLastPassId}/admins`,
      'GET'
    )

    if (status === StatusCodes.INTERNAL_SERVER_ERROR) {
      throw new UnknownServiceError()
    }

    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const managedCompanyAdminList = responseData.content

      if (!managedCompanyAdminList) {
        throw new Error('Response content is missing.')
      }

      return success(managedCompanyAdminList)
    }

    return failure()
  }
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { Badge } from '@lastpass/lastkit/components'
import { Theme } from '@lastpass/lastkit/theme'

import { TeamsPolicyStatus } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { PolicyStatusMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PolicyStatusMapping'

export interface PolicyStatusBadgeProps {
  status: TeamsPolicyStatus
}

export const PolicyStatusBadge = (props: PolicyStatusBadgeProps) => {
  const { status } = props
  const label = PolicyStatusMapping[status] || <Trans>Unknown</Trans>
  let background: keyof Theme['colors'] = 'green700'
  let textColor: keyof Theme['colors'] = 'white'

  switch (status) {
    case TeamsPolicyStatus.disabled:
      background = 'neutral200'
      textColor = 'neutral900'
      break
    default:
      break
  }

  return (
    <Badge
      colors={{
        backgroundColor: background,
        textColor: textColor
      }}
    >
      {label}
    </Badge>
  )
}

import React from 'react'

import styled from '@emotion/styled'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Pagination } from '@lastpass/lastkit'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { SamlResponsesQueryParams } from '@lastpass/admin-console-dependencies/state/reports/saml/actions'
import { SamlResponse } from '@lastpass/admin-console-dependencies/types/saml-response'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

interface SamlResponsesTablePaginationProps {
  samlListTable: Table<SamlResponse>
  queryParams: SamlResponsesQueryParams
}

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`

export const SamlResponsesTablePagination: React.FunctionComponent<SamlResponsesTablePaginationProps> = ({
  samlListTable,
  queryParams
}) => {
  const updateQuery = useUpdateQuery(Namespace.samlResponses)

  return (
    <StyledPagination
      skip={queryParams.skip || 0}
      totalResults={samlListTable.totalResults}
      resultsPerPage={samlListTable.resultsPerPage}
      updateSkip={skip => {
        updateQuery({ skip: skip || undefined })
      }}
    />
  )
}

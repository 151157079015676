import {
  DistributeRecoveryKeysActions,
  DistributeRecoveryKeysActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import { DistributeRecoveryKeysState } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

export const initialState: DistributeRecoveryKeysState = {
  totalNumberOfKeys: null,
  numberOfSharedKeys: 0,
  isStopped: false,
  isLoading: false,
  hasProgressErrors: false,
  targetAdminEmail: '',
  recoveryKeySharingDetails: {
    requesterAdminEncryptedPrivateRsaSharingKey: '',
    requesterAdminIterationCount: 0,
    targetAdminPublicRsaSharingKeys: [],
    uploadBatchSize: 0,
    userRecoveryKeys: []
  }
}

export function distributeRecoveryKeysReducer(
  state: DistributeRecoveryKeysState | undefined = initialState,
  action: DistributeRecoveryKeysActions
): DistributeRecoveryKeysState {
  switch (action.type) {
    case DistributeRecoveryKeysActionTypes.SET_TOTAL_NUMBER_OF_KEYS: {
      return {
        ...state,
        totalNumberOfKeys: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.SET_RECOVERY_SHARING_KEY_DETAILS: {
      return {
        ...state,
        recoveryKeySharingDetails: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.SET_NUMBER_OF_SHARED_KEYS: {
      return {
        ...state,
        numberOfSharedKeys: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.SET_IS_STOPPED: {
      return {
        ...state,
        isStopped: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.SET_HAS_PROGRESS_ERRORS: {
      return {
        ...state,
        hasProgressErrors: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.SET_TARGET_ADMIN_EMAIL: {
      return {
        ...state,
        targetAdminEmail: action.payload
      }
    }
    case DistributeRecoveryKeysActionTypes.RESET: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}

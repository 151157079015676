import { push } from 'connected-react-router'
import { put } from 'redux-saga/effects'

import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'

export function createAddAdvancedPolicySaga() {
  return function* addAdvancedPolicySaga(
    actions: ReturnType<typeof multifactorActions.showAddAdvancedMfaPolicy>
  ) {
    const newRoute = actions.payload.newRoute

    yield newRoute && put(push(newRoute))
  }
}

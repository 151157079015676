import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompaniesActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'

const filterList = (query: string, managedCompanies: ManagedCompany[]) =>
  managedCompanies.filter(managedCompany => {
    return (
      String(managedCompany.id).includes(query) ||
      managedCompany.name.toLowerCase().includes(query.toLowerCase())
    )
  })

export function createGetManagedCompaniesSaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*(action: ReturnType<typeof managedCompaniesActions.getList>) {
    yield put(managedCompaniesActions.setIsLoading(true))
    try {
      const result: UACServices.getManagedCompaniesAPI.Responses = yield call(
        managedCompaniesServices.getManagedCompanies
      )

      if (result.type === UACServices.getManagedCompaniesAPI.SUCCESS) {
        const {
          companies,
          consumedLicenses,
          totalLicensesAllocated,
          averageSecurityScore
        } = result.body
        const searchQuery = action.payload.params.query.search
        const filteredList = searchQuery
          ? filterList(searchQuery, companies)
          : companies
        yield put(
          managedCompaniesActions.setList(
            filteredList,
            filteredList.length,
            filteredList.length
          )
        )
        yield put(
          managedCompaniesActions.setAggregate({
            consumedLicenses,
            totalLicensesAllocated,
            averageSecurityScore
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(managedCompaniesActions.setIsLoading(false))
    }
  }
}

import {
  SystemUpgradeActions,
  SystemUpgradeActionTypes
} from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'

import { TeamsPolicyValueType } from '../../types/policy-teams'

export const initialState: SystemUpgradeState = {
  steps: {
    isRepairSsoAppsAck: false,
    isReviewRetiredFeaturesAck: false,
    isReviewRetiredPassPoliciesAck: false,
    isReviewManagedCompaniesAck: false
  },
  stepsLoading: true,
  applicationList: [],
  applicationListTotalResults: 0,
  applicationListResultsPerPage: 25,
  samlKeys: [],
  usernameAttributes: [],
  requirePasswordlessViaLastPassPolicy: {
    policyKey: '',
    policyData: [],
    policyTitle: '',
    policyShortDescription: '',
    policyDescription: '',
    policyValueType: TeamsPolicyValueType.none,
    checked: false,
    category: '',
    switchable: false
  },
  roles: [],
  accessPolicies: {
    total: 0,
    results: []
  },
  ipPolicies: {
    total: 0,
    results: []
  },
  geofencePolicies: {
    total: 0,
    results: []
  },
  authenticationPolicies: {
    total: 0,
    results: []
  },
  isSsoWarningConfirmed: false,
  isUpgradeStarted: false,
  isUpgradeCompleted: false,
  isUpgradeSucceed: false,
  upgradeError: '',
  isMigrationRequired: false,
  isMigrated: false,
  migratableManagedCompanies: [],
  clientOauth: {
    enabled: false,
    keys: []
  }
}

export function systemUpgradeReducer(
  previousState: SystemUpgradeState | undefined,
  action: SystemUpgradeActions
): SystemUpgradeState {
  const state = previousState || initialState
  switch (action.type) {
    case SystemUpgradeActionTypes.SET_SYSTEM_UPGRADE: {
      return {
        ...state,
        steps: action.payload
      }
    }
    case SystemUpgradeActionTypes.COMPLETE_SYSTEM_UPGRADE_STEP: {
      const steps = state.steps
      steps[action.payload] = true
      return {
        ...state,
        steps: steps
      }
    }
    case SystemUpgradeActionTypes.SET_STEPS_LOADING: {
      return {
        ...state,
        stepsLoading: action.payload
      }
    }
    case SystemUpgradeActionTypes.SET_APPLICATION_LIST: {
      return {
        ...state,
        applicationList: action.payload.list,
        applicationListTotalResults: action.payload.totalResults,
        applicationListResultsPerPage: action.payload.resultsPerPage
      }
    }
    case SystemUpgradeActionTypes.SET_SAML_KEYS: {
      return {
        ...state,
        samlKeys: action.payload.samlKeys
      }
    }
    case SystemUpgradeActionTypes.SET_USERNAME_ATTRIBUTES: {
      return {
        ...state,
        usernameAttributes: action.payload.attributes
      }
    }
    case SystemUpgradeActionTypes.SET_REQUIRE_PASSWORDLESS_VIA_LASTPASS_POLICY: {
      return {
        ...state,
        requirePasswordlessViaLastPassPolicy:
          action.payload.requirePasswordlessViaLastPassPolicy
      }
    }
    case SystemUpgradeActionTypes.SET_ROLE_LIST: {
      return {
        ...state,
        roles: action.payload.list
      }
    }
    case SystemUpgradeActionTypes.SET_ACCESS_POLICIES: {
      return {
        ...state,
        accessPolicies: action.payload.accessPolicies
      }
    }
    case SystemUpgradeActionTypes.SET_GEOFENCE_POLICIES: {
      return {
        ...state,
        geofencePolicies: action.payload.geofencePolicies
      }
    }
    case SystemUpgradeActionTypes.SET_IP_POLICIES: {
      return {
        ...state,
        ipPolicies: action.payload.ipPolicies
      }
    }
    case SystemUpgradeActionTypes.SET_AUTHENTICATION_POLICIES: {
      return {
        ...state,
        authenticationPolicies: action.payload.authenticationPolicies
      }
    }
    case SystemUpgradeActionTypes.START_UPGRADE: {
      return {
        ...state,
        isUpgradeStarted: true
      }
    }
    case SystemUpgradeActionTypes.UPGRADE_COMPLETED: {
      return {
        ...state,
        isUpgradeCompleted: true,
        isUpgradeSucceed: action.payload.succeed,
        upgradeError: action.payload.errorMessage
      }
    }
    case SystemUpgradeActionTypes.RESET_UPGRADE_PROCESS: {
      return {
        ...state,
        isUpgradeStarted: false,
        isUpgradeCompleted: false,
        isUpgradeSucceed: false,
        upgradeError: ''
      }
    }
    case SystemUpgradeActionTypes.SET_IS_MIGRATION_REQUIRED: {
      return {
        ...state,
        isMigrationRequired: action.payload.isMigrationRequired
      }
    }
    case SystemUpgradeActionTypes.SET_IS_MIGRATED: {
      return {
        ...state,
        isMigrated: action.payload.isMigrated
      }
    }
    case SystemUpgradeActionTypes.SET_MIGRATABLE_MANAGED_COMPANIES: {
      return {
        ...state,
        migratableManagedCompanies: action.payload.migratableManagedCompanies
      }
    }
    case SystemUpgradeActionTypes.SET_SSO_WARNING_CONFIRMATION: {
      return {
        ...state,
        isSsoWarningConfirmed: action.payload.ssoWarningConfirmation
      }
    }
    case SystemUpgradeActionTypes.SET_OAUTH_KEYS: {
      return {
        ...state,
        clientOauth: action.payload.clientOauth
      }
    }
  }
  return state
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton, TextButton, TextInput } from '@lastpass/lastkit'
import { StyledSpacer } from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import {
  createApplication,
  SelectedApplication
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'

export interface AddUnlistedAppProps {
  closeLink: string
}

export const AddUnlistedApp: React.FunctionComponent<AddUnlistedAppProps> = ({
  closeLink
}) => {
  const applicationDrawerState: ApplicationDrawerState = useSelector(
    (state: AppState) => state.applicationDrawer
  )
  const dispatch = useDispatch()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const history = useHistory()
  const maxNameLength = 50
  return (
    <>
      <TextInput
        data-qa="UnlistedAppInputField"
        name="unlistedApp"
        maxLength={maxNameLength}
        onChange={e => {
          setIsBlockingPromptEnabled(true)
          const value = e.target.value
          const app: SelectedApplication | null =
            value.length > 1 ? createApplication(true, value, '', value) : null
          dispatch(applicationsDrawerActions.setSelectedApplication(app))
        }}
      >
        <Trans>Name</Trans>
      </TextInput>
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => history.push(closeLink)}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              setIsBlockingPromptEnabled(false)
              dispatch(
                applicationsDrawerActions.nextStep(
                  ApplicationDrawerSteps.configure
                )
              )
            }}
            disabled={!applicationDrawerState.canMoveToNext}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

import { SegmentProperties, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum DropdownActionTypes {
  SWITCH_TO_OLD_CONSOLE_LINK_CLICKED = 'SWITCH_TO_OLD_CONSOLE_LINK_CLICKED',
  BILLING_CLICKED = 'BILLING_CLICKED',
  COMPANY_PROFILE_CLICKED = 'COMPANY_PROFILE_CLICKED',
  NEWS_AND_UPDATES_CLICKED = 'NEWS_AND_UPDATES_CLICKED'
}

export const dropdownActions = {
  switchToOldConsoleClicked: (properties?: SegmentProperties) =>
    track(
      createAction(DropdownActionTypes.SWITCH_TO_OLD_CONSOLE_LINK_CLICKED),
      {
        segment: uacEvent('Switch To Old Admin Console Clicked', properties)
      }
    ),
  billingClicked: () =>
    track(createAction(DropdownActionTypes.BILLING_CLICKED), {
      segment: uacEvent('UAC Billing Clicked')
    }),
  companyProfileClicked: () =>
    track(createAction(DropdownActionTypes.COMPANY_PROFILE_CLICKED), {
      segment: uacEvent('UAC Company Profile Clicked')
    }),
  newsAndUpdatesClicked: () =>
    track(createAction(DropdownActionTypes.NEWS_AND_UPDATES_CLICKED), {
      segment: uacEvent('UAC News And Updates Clicked')
    })
}

export type DropdownActions = ActionsUnion<typeof dropdownActions>

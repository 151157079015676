import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'

import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import {
  ElementType,
  RiskType,
  SecurityReportMessageDescriptor,
  SecurityReportMessageListDescriptor
} from '@lastpass/admin-console-dependencies/types/security-report'

type Translation = {
  name: MessageDescriptor
  descriptions?: (
    | SecurityReportMessageDescriptor
    | SecurityReportMessageListDescriptor
  )[]
}

export type SecurityReportEventTranslationMap = {
  [key in RiskType]: Translation
}

type CreateSecurityReportEventTranslationMap = (
  minimumHashIterationCount: CompanyDetails['minimumHashIterationCount'],
  isUrlDecryptionEnabled: boolean,
  isSharedFolderUrlDecryptionEnabled: boolean
) => SecurityReportEventTranslationMap

const getUrlsInVaultsDescriptions = (
  isUrlDecryptionEnabled
): (
  | SecurityReportMessageDescriptor
  | SecurityReportMessageListDescriptor
)[] => {
  return isUrlDecryptionEnabled
    ? [
        {
          element: ElementType.p,
          message: msg`Click "Generate report" to export a list of all URLs stored in all of your users’ vaults. Depending on the size of your organization, this can take a while. We’ll send an email when it’s ready. Super admins automatically decrypt URLs for plaintext view during report download since they have access to the required recovery keys.`
        },
        {
          element: ElementType.ul,
          message: msg`Limitations:`,
          listMessages: [
            msg`MSP admins working with a managed company can’t generate this report at this time.`,
            msg`Super admins logged in to LastPass with a federated account can't decrypt URL data in reports at this time.`
          ]
        },
        {
          element: ElementType.p,
          message: msg`Pro tip: For best results when decrypting URLs, super admins may need to ask another super admin with more recovery keys to "Share recovery keys invisibly" with them from the Users page before they start the process.`
        }
      ]
    : [
        {
          element: ElementType.p,
          message: msg`Click Generate report to export a list of all URLs stored in all of your users’ vaults. Depending on the size of your organization, this can take a while. We’ll send an email when it’s ready.`
        },
        {
          element: ElementType.p,
          message: msg`Pro tip: To show more than the domain name in reports (server + path, but no HTTP parameters), enable the 'Log full URLs in reporting' policy.`
        }
      ]
}

export const createSecurityReportEventTranslationMap: CreateSecurityReportEventTranslationMap = (
  minimumHashIterationCount,
  isUrlDecryptionEnabled,
  isSharedFolderUrlDecryptionEnabled
) => ({
  [RiskType.lowIterationCount]: {
    name: msg`User iteration counts`,
    descriptions: [
      {
        element: ElementType.p,
        message: msg`Click Download CSV to export the master password hash iteration count for each user in your organization.`
      }
    ]
  },
  [RiskType.sharedFoldersAccessedByLowIterationCountUsers]: {
    name: msg`Shared folders accessed by low iteration count users`,
    descriptions: [
      {
        element: ElementType.p,
        message: msg`Click Download CSV to export a list of shared folders accessed by users with a master password hash iteration count below this value: ${minimumHashIterationCount}.`
      }
    ]
  },
  [RiskType.mfa]: {
    name: msg`Enabled multifactor`,
    descriptions: [
      {
        element: ElementType.p,
        message: msg`Click Download CSV to export a list of MFA services enabled by each individual user.`
      }
    ]
  },
  [RiskType.linkedAccountIterationCount]: {
    name: msg`Linked account iteration count`,
    descriptions: [
      {
        element: ElementType.p,
        message: msg`Click Download CSV to export a list showing the master password hash iteration count for each personal LastPass account linked to a Business account in your organization.`
      }
    ]
  },
  [RiskType.urlsInVaults]: {
    name: msg`URLs in vaults`,
    descriptions: getUrlsInVaultsDescriptions(isUrlDecryptionEnabled)
  },
  [RiskType.urlsInSharedFolders]: {
    name: msg`URLs in shared folders`,
    descriptions: isSharedFolderUrlDecryptionEnabled
      ? [
          {
            element: ElementType.p,
            message: msg`Click "Generate report" to export a list of all URLs stored in all of your users’ shared folders. Depending on the size of your organization, this can take a while. We’ll send an email when it’s ready. Super admins automatically decrypt URLs for plaintext view during report download since they have access to the required keys.`
          },
          {
            element: ElementType.ul,
            message: msg`For best results:`,
            listMessages: [
              msg`Unless super admins are added to the "Permit super admins to access shared folders" policy, their ability to decrypt is limited by their access to users’ recovery keys.`,
              msg`Without access to shared folders, super admins may need to ask another super admin with more recovery keys to "Share recovery keys invisibly" with them from the Users page before they start the process.`
            ]
          },
          {
            element: ElementType.ul,
            message: msg`Limitations:`,
            listMessages: [
              msg`MSP admins working with a managed company can’t generate this report at this time.`,
              msg`Super admins logged in to LastPass with a federated account can't decrypt URL data in reports at this time.`
            ]
          }
        ]
      : [
          {
            element: ElementType.p,
            message: msg`Click Generate report to export a list of all URLs stored in all of your users’ shared folders. Depending on the size of your organization, this can take a while. We’ll send an email when it’s ready.`
          },
          {
            element: ElementType.p,
            message: msg`Pro tip: To show more than the domain name in reports (server + path, but no HTTP parameters), enable the 'Log full URLs in reporting' policy.`
          }
        ]
  },
  [RiskType.reusedMasterPassword]: { name: msg`Reused master password` },
  [RiskType.weakSecurityScore]: { name: msg`Weak security score` },
  [RiskType.noSharingKey]: { name: msg`No sharing key` },
  [RiskType.notLoggedIn]: { name: msg`Inactive during last 7 days` },
  [RiskType.noLinkedAccount]: { name: msg`No linked account` },
  [RiskType.weakPasswords]: { name: msg`More than 5 weak passwords` },
  [RiskType.duplicatePasswords]: { name: msg`More than 3 duplicate passwords` },
  [RiskType.duplicatePasswordSites]: {
    name: msg`More than 3 duplicate password sites`
  },
  [RiskType.blankPasswords]: { name: msg`More than 3 blank passwords` },
  [RiskType.darkWebMonitoringAlert]: {
    name: msg`Unresolved dark web monitoring alerts`
  }
})

import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyCategories,
  TeamsPolicyMap,
  TeamsPolicyModelData,
  TeamsPolicyResponse,
  TeamsPolicyResponseMap,
  TeamsPolicyValueType,
  TeamsPolicyWithoutPasswordlessCategories
} from '@lastpass/admin-console-dependencies/types/policy-teams'

const processTeamsPolicyResponse = (
  policyResponse: TeamsPolicyResponse
): TeamsPolicy => {
  const policy: TeamsPolicy = {
    policyKey: policyResponse.policy_key,
    policyData: policyResponse.policyData as TeamsPolicyModelData[],
    category: policyResponse.category,
    policyTitle: policyResponse.title,
    policyShortDescription: policyResponse.short_description,
    policyDescription: policyResponse.description,
    policyValueType: policyResponse.policy_type as TeamsPolicyValueType,
    checked: policyResponse.switched_on,
    switchable: policyResponse.switchable
  }
  if (policy.policyData) {
    policy.policyData.forEach(policyInfo => {
      if (
        (policyInfo.inclusiveList && policyInfo.inclusiveList.length > 0) ||
        (policyInfo.inclusiveGroupList &&
          policyInfo.inclusiveGroupList.length > 0)
      ) {
        policyInfo.appliesTo = AppliesToOptions.inclusive
      } else if (
        (policyInfo.exclusiveList && policyInfo.exclusiveList.length > 0) ||
        (policyInfo.exclusiveGroupList &&
          policyInfo.exclusiveGroupList.length > 0)
      ) {
        policyInfo.appliesTo = AppliesToOptions.exclusive
      } else {
        policyInfo.appliesTo = AppliesToOptions.all
      }
      return policyInfo
    })
  }
  return policy
}

export function mapGetPolicyListResultToPolicyMap(
  generalPoliciesResponse: TeamsPolicyResponseMap
): [TeamsPolicyMap, TeamsPolicy[]] {
  const teamsPolicyCategories: string[] = Object.values(TeamsPolicyCategories)

  const generalPoliciesMap: TeamsPolicyMap = {}

  const generalPoliciesList: TeamsPolicy[] = Object.keys(
    generalPoliciesResponse
  )
    .filter((policyKey: string) =>
      teamsPolicyCategories.includes(
        generalPoliciesResponse[policyKey].category
      )
    )
    .map((policyKey: string) => {
      const policyResponse: TeamsPolicyResponse =
        generalPoliciesResponse[policyKey]
      const policy = processTeamsPolicyResponse(policyResponse)
      generalPoliciesMap[policyKey] = policy
      return policy
    })

  return [generalPoliciesMap, generalPoliciesList]
}

export function mapGetPolicyListResultToPolicyMapWithoutPasswordless(
  generalPoliciesResponse: TeamsPolicyResponseMap
): [TeamsPolicyMap, TeamsPolicy[]] {
  const teamsPolicyWithoutPasswordlessCategories: string[] = Object.values(
    TeamsPolicyWithoutPasswordlessCategories
  )

  const generalPoliciesMap: TeamsPolicyMap = {}

  const generalPoliciesList: TeamsPolicy[] = Object.keys(
    generalPoliciesResponse
  )
    .filter((policyKey: string) =>
      teamsPolicyWithoutPasswordlessCategories.includes(
        generalPoliciesResponse[policyKey].category
      )
    )
    .map((policyKey: string) => {
      const policyResponse: TeamsPolicyResponse =
        generalPoliciesResponse[policyKey]
      const policy = processTeamsPolicyResponse(policyResponse)
      generalPoliciesMap[policyKey] = policy
      return policy
    })

  return [generalPoliciesMap, generalPoliciesList]
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as PoliciesIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import {
  CardTable,
  Collapsible,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { GetPolicyName } from '@lastpass/admin-console-dependencies/services/get-policy-name'
import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'
import { Policy } from '@lastpass/admin-console-dependencies/types/policy'

interface GroupPoliciesSummaryCardProps {
  detailLink: string
  policiesLink: string
  expand: boolean
  onExpand: Function
}

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const GroupPoliciesSummaryCard: React.FunctionComponent<GroupPoliciesSummaryCardProps> = ({
  detailLink,
  policiesLink,
  expand,
  onExpand
}) => {
  const groupDrawerPoliciesState: GroupDrawerState['policies'] = useSelector(
    (state: AppState) => state.groupDrawer.policies
  )
  const queryParams = useQueryParams()
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    if (expand) {
      dispatch(
        groupDrawerActions.getGroupPolicies(
          { path: pathParams, query: queryParams },
          5
        )
      )
    }
  }, [dispatch, pathParams, expand, queryParams])

  const policiesToShow = {
    ...groupDrawerPoliciesState,
    results: groupDrawerPoliciesState.results.slice(0, 5)
  }

  return (
    <Collapsible
      title={<Trans>Policies</Trans>}
      expanded={expand}
      onInitialExpand={() => {
        dispatch(
          groupDrawerActions.getGroupPolicies(
            { path: pathParams, query: queryParams },
            5
          )
        )
      }}
      loading={groupDrawerPoliciesState.isLoading}
      onExpand={onExpand}
      qadata={'PoliciesCard'}
    >
      {groupDrawerPoliciesState && (
        <CardTable
          qadata={'GroupLevelPoliciesList'}
          table={policiesToShow}
          columns={[
            {
              name: <Trans>Policy</Trans>,
              renderer: function showPolicyName(record: Policy) {
                return record.needsToBeTranslated
                  ? GetPolicyName(record.name)
                  : record.name
              }
            }
          ]}
          footerNodes={
            <>
              <TextButton blue>
                <StyledLink
                  to={policiesLink}
                  data-qa={'GoToPoliciesPageButton'}
                >
                  <Trans>Go to Policies page</Trans>
                </StyledLink>
              </TextButton>
              <TextButton blue>
                <StyledLocationLink
                  to={detailLink}
                  data-qa={'ViewAllPoliciesBuuton'}
                >
                  <Trans>
                    View all{' '}
                    {groupDrawerPoliciesState &&
                      groupDrawerPoliciesState.totalResults}{' '}
                    Policies
                  </Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<PoliciesIcon />}
              title={<Trans>No policies assigned yet</Trans>}
              text={
                <Trans>
                  Assign policies to control how group members can use LastPass.
                </Trans>
              }
              actionButton={
                <TableViewButton>
                  <StyledLink
                    to={policiesLink}
                    data-qa={'GoToPoliciesPageButton'}
                  >
                    <Trans>Go to Policies page</Trans>
                  </StyledLink>
                </TableViewButton>
              }
            />
          }
        />
      )}
    </Collapsible>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as IconReports } from '@lastpass/assets/svg/admin-console/icon-reports.svg'
import { Heading200 } from '@lastpass/lastkit'

import { SsoAppsContainerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/state'
import { ProtectedService } from '@lastpass/admin-console-dependencies/ui/applications/ssoapps/ProtectedService'

import { RequirePasswordlessViaLastPassAuth } from './RequirePasswordlessViaLastPassAuth'

const ProtectedServicesContainer = styled.div`
  margin: 24px;
`

const ProtectedServiceListElement = styled.li`
  margin-top: 24px;
  &:after {
    content: '';
    display: block;
    margin: 36px auto 0 64px;
    width: 95%;
    border-bottom: 1px solid ${props => props.theme.colors.neutral200};
  }
  &:last-child:after {
    border-bottom: none;
  }
`

const ProtectedServicesHeader = styled(Heading200)`
  margin-top: 24px;
`

const services = [
  {
    icon: IconReports,
    heading: <Trans>Single sign-on apps</Trans>,
    body: (
      <Trans>
        Upon login to an SSO app, users verify their identity without a password
        using LastPass Authenticator.
      </Trans>
    )
  }
]

export const UsersTab: React.FunctionComponent = () => {
  const ssoAppsContainerState: SsoAppsContainerState = useSelector(
    (state: AppState) => state.passwordlessContainer
  )

  const isEnabled =
    ssoAppsContainerState.RequirePasswordlessViaLastPassPolicy.checked
  const policyData =
    ssoAppsContainerState.RequirePasswordlessViaLastPassPolicy.policyData[0]

  return (
    <ProtectedServicesContainer>
      <RequirePasswordlessViaLastPassAuth
        isEnabled={isEnabled}
        policyData={policyData}
      />
      {isEnabled && (
        <>
          <ProtectedServicesHeader>
            <Trans>Protected services</Trans>
          </ProtectedServicesHeader>
          <ul>
            {services.map((service, index) => (
              <ProtectedServiceListElement key={index}>
                <ProtectedService service={service} />
              </ProtectedServiceListElement>
            ))}
          </ul>
        </>
      )}
    </ProtectedServicesContainer>
  )
}

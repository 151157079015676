import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { MspPaymentReminderRepository } from '@lastpass/admin-console-dependencies/repositories'
import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { MspBillingDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const mspBillingDetailsSelector = (state: AppState) =>
  state.companyInfo.mspBillingDetails

export function createShowMspPaymentInfoSaga(
  companyServices: UACServices.Services
) {
  return function* showMspPaymentInfoSaga() {
    try {
      const result: UACServices.MspBillingDetailsAPI.Responses = yield call(
        companyServices.mspBillingDetails
      )

      if (result.type === UACServices.MspBillingDetailsAPI.SUCCESS) {
        const mspBillingDetails: MspBillingDetails = result.body
        const reminderDate: Date = yield call(MspPaymentReminderRepository.get)

        if (
          mspBillingDetails.enterPaymentInfoAllowed &&
          mspBillingDetails.isPaymentInfoRequired
        ) {
          yield put(companyInfoActions.setMspBillingDetails(mspBillingDetails))

          if (
            mspBillingDetails.isTrialExpired ||
            !reminderDate ||
            reminderDate <= new Date()
          ) {
            yield put(
              globalActions.setDialog({
                type: 'msp-payment-info-dialog',
                mspBillingDetails
              })
            )

            yield call(MspPaymentReminderRepository.remove)

            const confirmClick = yield take([
              GlobalActionTypes.CONFIRM_DIALOG,
              GlobalActionTypes.DISCARD_DIALOG
            ])

            if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
              yield call(
                window.open,
                `${mspBillingDetails.billingInfoUrl}?submitFormOnLoad=true`,
                '_blank'
              )

              const emailSendResult: UACServices.PaymentInfoEmailAPI.Responses = yield call(
                companyServices.paymentInfoEmail
              )

              if (
                emailSendResult.type === UACServices.PaymentInfoEmailAPI.SUCCESS
              ) {
                yield put(
                  globalActions.setNotification({
                    message: msg`Email sent to LastPass Partner Team`,
                    type: NotificationType.success,
                    autoDismiss: true
                  })
                )
              }
            }

            if (!mspBillingDetails.isTrialExpired) {
              yield put(globalActions.emptyDialog())
            }
          }
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

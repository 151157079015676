import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UsersIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerTable,
  Pagination,
  PrimaryButton,
  RowContainer,
  SearchInput,
  TableView,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { useUnmountEffect } from '@lastpass/ui'

import { assignGroupUsersActions } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/actions'
import { AssignGroupUsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/state'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'

const selectedRowsMaxLimit = 25

const StyledTextButton = styled(TextButton)`
  margin-right: 15px;
`

interface AssignGroupUsersDrawerTableProps {
  newGroup: boolean
}

export const AssignGroupUsersDrawerTable: React.FunctionComponent<AssignGroupUsersDrawerTableProps> = newGroup => {
  const assignGroupUsersDrawerState: AssignGroupUsersDrawerState = useSelector(
    (state: AppState) => state.assignGroupUsersDrawer
  )
  const queryParams = useQueryParams(Namespace.addUsersToGroup)
  const updateQuery = useUpdateQuery(Namespace.addUsersToGroup)
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      assignGroupUsersActions.getGroupAssignUserList({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])
  useUnmountEffect(() => {
    dispatch(assignGroupUsersActions.setSelectedGroupUsers([]))
  })
  return (
    <>
      <RowContainer>
        <SearchInput
          flexGrow
          value={queryParams.search || ''}
          placeholder={msg`Search users`}
          onChange={e =>
            updateQuery({
              search: e.target.value || undefined,
              skip: undefined
            })
          }
          data-qa={'AssignUsersSearchFieldInput'}
        />
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={assignGroupUsersDrawerState.users.totalResults}
          resultsPerPage={assignGroupUsersDrawerState.users.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </RowContainer>
      <DrawerTable
        qadata={'GroupsLevelAssingUsersList'}
        table={assignGroupUsersDrawerState.users}
        loadingView={<Loading color="blue900" active={true} />}
        checkable={{
          addSelectedRecords: records =>
            dispatch(assignGroupUsersActions.addSelectedGroupUsers(records)),
          removeSelectedRecords: records =>
            dispatch(assignGroupUsersActions.removeSelectedGroupUsers(records)),
          setSelectedRecords: records =>
            dispatch(assignGroupUsersActions.setSelectedGroupUsers(records))
        }}
        showSelectedNumber
        selectedRecordLimit={selectedRowsMaxLimit}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: (record: User) => getFullName(record)
          },
          {
            name: <Trans>Email</Trans>,
            renderer: (record: User) => record.email
          }
        ]}
        noRecordsView={
          <TableView
            icon={<UsersIcon />}
            title={
              <Trans>
                All of your users are already assigned to this group
              </Trans>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UsersIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        isFilterApplied={!!queryParams.search}
        footerNodes={[
          <LocationLink
            to={newGroup ? `/users/groups` : `/users/groups/${pathParams.id}`}
            key={'skip'}
          >
            <StyledTextButton
              key={'Skip'}
              data-qa={'AssignUsersToGroupSkipButton'}
            >
              {newGroup ? <Trans>Skip</Trans> : <Trans>Cancel</Trans>}
            </StyledTextButton>
          </LocationLink>,
          <PrimaryButton
            key={'Assign users'}
            disabled={
              assignGroupUsersDrawerState.users.checkedRecords.length === 0 ||
              assignGroupUsersDrawerState.users.checkedRecords.length >
                selectedRowsMaxLimit
            }
            onClick={() => {
              return dispatch(
                assignGroupUsersActions.addUsersToGroup(
                  assignGroupUsersDrawerState.users.checkedRecords,
                  assignGroupUsersDrawerState.group
                )
              )
            }}
            data-qa={'AssignUsersToGroupAssignUsersButton'}
          >
            <Trans>Assign users</Trans>
          </PrimaryButton>
        ]}
        disableRowHover={true}
      />
    </>
  )
}

import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

import { handleGoogleSetupFailure } from './handle-google-setup-failure'

export function createFetchGoogleGroups(
  enterpriseOptionServices: UACServices.Services
) {
  return function* fetchGoogleGroups() {
    try {
      const result: UACServices.GoogleGroupsGetAPI.Responses = yield call(
        enterpriseOptionServices.googleGroupsGet
      )

      const groupIdsToSync = yield select(
        (state: AppState) => state.integrations.Google.groupIdsToSync
      )

      if (result.type === UACServices.GoogleGroupsGetAPI.SUCCESS) {
        const groupsWithSelectionState = result.body.googleGroups.map(
          group => ({
            ...group,
            selected: groupIdsToSync.includes(group.id)
          })
        )

        yield put(
          directoriesActions.setGoogleGroups(groupsWithSelectionState, true)
        )
      } else if (
        result.type ===
        UACServices.GoogleGroupsGetAPI.TURN_ON_SEARCH_BASED_GROUP_SELECTION
      ) {
        yield put(directoriesActions.turnOnSearchBasedGroupSelection())
      } else {
        yield handleGoogleSetupFailure()
      }
    } catch {
      yield handleGoogleSetupFailure()
    }
  }
}

import React, { useState } from 'react'

import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Loading } from '@lastpass/components'

import { QRadarIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import { useSiemIntegrationsSetConfig } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/api-hooks/use-siem-integrations-set-config'
import { SiemIntegrationsEnabledCheckbox } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/SiemIntegrationsEnabledCheckbox'
import { SiemIntegrationsSubmitButton } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/SiemIntegrationsSubmitButton'
import {
  StyledFormRow,
  StyledTextInput
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

interface QRadarFormProps {
  config: QRadarIntegrationConfig
}

export const QRadarForm: React.FunctionComponent<QRadarFormProps> = ({
  config
}) => {
  const { isLoading, setQRadarConfig } = useSiemIntegrationsSetConfig()

  const [
    currentConfig,
    setCurrentConfig
  ] = useState<QRadarIntegrationConfig | null>(null)

  const yupSchema = Yup.object().shape({
    host: Yup.string()
      .trim()
      .required(),
    logSourceIdentifier: Yup.string()
      .trim()
      .required()
  })

  return (
    <>
      {isLoading && (
        <Loading
          data-qa="QRadarLoadingIndicator"
          color="blue900"
          active={true}
        />
      )}
      <Formik
        initialValues={currentConfig ?? config}
        enableReinitialize
        validationSchema={yupSchema}
        onSubmit={values => {
          setQRadarConfig({
            config: values,
            successMessage: msg`Nice! You've set up the IBM QRadar integration.`
          })
          setCurrentConfig(values)
        }}
      >
        {formik => (
          <Form data-qa="QRadarForm">
            <StyledFormRow>
              <StyledTextInput
                data-qa="QRadarHostInput"
                name="host"
                placeholder={msg`Your IBM QRadar instance URL`}
                value={formik.values.host}
                error={!!formik.errors.host}
                onChange={formik.handleChange}
              >
                <Trans>Host</Trans>
              </StyledTextInput>

              <StyledTextInput
                data-qa="QRadarLogSourceIdentifier"
                name="logSourceIdentifier"
                placeholder={msg`Your log source identifier from IBM QRadar`}
                value={formik.values.logSourceIdentifier}
                error={!!formik.errors.logSourceIdentifier}
                onChange={formik.handleChange}
              >
                <Trans>Log source identifier</Trans>
              </StyledTextInput>
            </StyledFormRow>

            <SiemIntegrationsEnabledCheckbox
              dataQa="QRadarEnabledCheckbox"
              formik={formik}
            />

            <SiemIntegrationsSubmitButton
              dataQa="QRadarSaveChangesButton"
              formik={formik}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

import React, { ReactNode } from 'react'

import styled from '@emotion/styled'

import { FullWidthContainer } from './Collapsible'
import { RowContainer } from './Layout'
import { Table, TableProps } from './Table'

interface TableCardProps {
  footerNodes?: ReactNode
  noRecordsView: React.ReactElement
}

const NoRecordsContainer = styled.div`
  margin: 16px 0;
  position: relative;
  min-height: 225px;
`

export const CardTable = <T extends {}>({
  footerNodes,
  noRecordsView,
  ...tableProps
}: TableProps<T> & TableCardProps) => {
  const results = tableProps.table.results
  if (results) {
    if (results.length > 0) {
      return (
        <>
          <FullWidthContainer>
            <Table {...tableProps} />
          </FullWidthContainer>
          {footerNodes && <RowContainer>{footerNodes}</RowContainer>}
        </>
      )
    }
    return <NoRecordsContainer>{noRecordsView}</NoRecordsContainer>
  }
  return null
}

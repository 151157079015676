import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  Group,
  GroupNameErrorMessages
} from '@lastpass/admin-console-dependencies/types/group'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)
const groupAlreadyExists = () =>
  createResponse(GroupNameErrorMessages.groupNameAlreadyExists)

const groupNameTooLong = () =>
  createResponse(GroupNameErrorMessages.groupNameTooLong)

const groupNameEmpty = () =>
  createResponse(GroupNameErrorMessages.groupNameEmpty)

export const Responses = {
  success,
  groupAlreadyExists,
  groupNameEmpty,
  groupNameTooLong
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(group: Group) {
    const [response, status] = await fetch(
      'groups/' + group.id,
      'PATCH',
      { name: group.name },
      { 'content-type': 'application/json' }
    )
    const result = JSON.parse(response)

    if (status === StatusCodes.OK && result.content.id) {
      return success()
    }
    if (
      status === StatusCodes.CONFLICT &&
      result.status === GroupNameErrorMessages.groupNameAlreadyExists
    ) {
      return groupAlreadyExists()
    }
    if (
      status === StatusCodes.BAD_REQUEST &&
      result.status === GroupNameErrorMessages.groupNameTooLong
    ) {
      return groupNameTooLong()
    }
    if (
      status === StatusCodes.BAD_REQUEST &&
      result.status === GroupNameErrorMessages.groupNameEmpty
    ) {
      return groupNameEmpty()
    }
    throw new UnknownServiceError()
  }
}

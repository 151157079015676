import { saveAs } from 'file-saver'
import { call, put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ObjectToCsv } from '@lastpass/admin-console-dependencies/services/object-to-csv'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userActivityActions } from '@lastpass/admin-console-dependencies/state/reports/view/actions'

export function createExportWeakSecurityScore() {
  return function* exportWeakSecurityScore(
    action: ReturnType<typeof userActivityActions.exportWeakSecurityScoreReport>
  ) {
    try {
      const users = action.payload.users
      const usersCsv = ObjectToCsv(users)
      const blobToSave = new Blob([usersCsv], {
        type: 'text/csv;charset=utf-8;'
      })
      yield call(saveAs, blobToSave, 'weak-security-score.csv')
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as CheckIcon } from '@lastpass/assets/svg/admin-console/green-check-icon.svg'
import { ReactComponent as CheckIconUpgrade } from '@lastpass/assets/svg/admin-console/icon-upgrade-step-check.svg'
import { BodyRegular } from '@lastpass/lastkit/components/BodyText'
import { Heading100 } from '@lastpass/lastkit/components/Heading'
import { PrimaryButton } from '@lastpass/lastkit/components/PrimaryButton'
import { BodySemiboldStyle } from '@lastpass/lastkit/styles'

interface SystemUpgradeStepProps {
  completed: boolean
  icon: React.ComponentType
  title: React.ReactElement
  description: React.ReactElement
  hideLine?: boolean
  completeStep?: Function
  dataQa?: string
}

const Container = styled.div`
  position: relative;
  display: flex;
`

const Line = styled.div`
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 25px;
  width: 1px;
  background: ${props => props.theme.colors.neutral200};
`

const TextContainer = styled.div`
  max-width: 700px;
  margin-left: 24px;
  gap: 16px;
  padding: 16px;
  width: 618px;
  border: 1px solid #bac0ca;
  border-radius: 4px;
  margin-bottom: 16px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 8px;
`

const ButtonContainer = styled.div`
  margin-top: 24px;
  text-align: right;
`

const SetComplete = styled.span`
  margin-left: 16px;
  align-items: center;
  ${BodySemiboldStyle}
  color: #768498;
  padding: 0 20px;
`

const TitleContainer = styled.div`
  display: flex;
`

const StyledCheckIcon = styled(CheckIconUpgrade)`
  vertical-align: middle;
  padding-right: 8px;
  height: 40px;
`

export const SystemUpgradeStep: FunctionComponent<SystemUpgradeStepProps> = props => {
  const icon: React.ComponentType = props.completed ? CheckIcon : props.icon
  const StyledIcon = styled(icon)`
    width: 50px;
    height: 50px;
    margin: 0 0;
  `

  return (
    <Container data-qa={props.dataQa}>
      <StyledIcon data-qa={'SystemUpgradeStepIcon'} />
      {!props.hideLine && <Line data-qa={'SystemUpgradeLine'} />}
      <TextContainer>
        <TitleContainer>
          <StyledHeading100 data-qa={'SystemUpgradeStepTitle'}>
            {props.title}
          </StyledHeading100>
        </TitleContainer>
        <BodyRegular>{props.description}</BodyRegular>
        <>{props.children}</>

        {!!props.completeStep && !props.completed && (
          <ButtonContainer>
            <PrimaryButton
              data-qa={'SystemUpgradeStepMarkComplete'}
              onClick={() => {
                !!props.completeStep && props.completeStep()
              }}
            >
              <Trans>Acknowledge</Trans>
            </PrimaryButton>
          </ButtonContainer>
        )}
        {props.completed && (
          <ButtonContainer>
            <SetComplete>
              <StyledCheckIcon />
              <BodyRegular>
                <Trans>Acknowledged</Trans>
              </BodyRegular>
            </SetComplete>
          </ButtonContainer>
        )}
      </TextContainer>
    </Container>
  )
}

import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { NeverOnlyUrls } from '@lastpass/admin-console-dependencies/types/never-only-urls'
export const SUCCESS = 'success'

const success = (urlRules: NeverOnlyUrls) =>
  createResponse(SUCCESS, { urlRules })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(urlRules: NeverOnlyUrls) {
    try {
      const [response, status] = await teamsapi(
        'my-company/global-never-only-urls',
        'POST',
        urlRules
      )

      if (status === StatusCodes.OK && response) {
        const urlRulesResponse = JSON.parse(response)
        return success(urlRulesResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { TeamsApiAdvancedUserEventType } from '@lastpass/admin-console-dependencies/types/advanced-user-activity'

const description =
  'Event description for Login. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
const eventTypes = [
  'addtosharedfolder',
  'createLPaccount',
  'createdsharedfolder',
  'addglobalequivdomain',
  'addglobalneverurls',
  'addglobalonlyurls'
]
const eventInformation = ['info1', 'info2', 'info3']
const users = ['attila.santa', 'mock.user']
const dateNow = Date.now()

export const mockData: TeamsApiAdvancedUserEventType[] = new Array(30)
  .fill(null)
  .map((item, idx) => ({
    id: `${idx}`,
    eventType: eventTypes[idx % eventTypes.length],
    eventInformation: eventInformation[idx % eventInformation.length],
    user: `${users[idx % users.length]}@logmein.com`,
    timeUtc: new Date(dateNow - 5e7 * idx).toISOString(),
    name: `name${idx}`,
    ip: `84.12.45.${idx % 255}`,
    description
  }))

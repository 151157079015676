import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GeofencePolicy } from '@lastpass/admin-console-dependencies/state/policies/passwordless/passwordless'

export const SUCCESS = 'success'

const success = (response: GeofencePolicy[]) =>
  createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [responseRedZone, statusRedZone] = await identityApi(
        `api/v2/Zone/GetZones?isRed=true`,
        'GET'
      )

      const [responseGreenZone, statusGreenZone] = await identityApi(
        `api/v2/Zone/GetZones?isRed=false`,
        'GET'
      )

      if (
        statusRedZone === StatusCodes.OK &&
        responseRedZone &&
        statusGreenZone === StatusCodes.OK &&
        responseGreenZone
      ) {
        const redZoneResponse = JSON.parse(responseRedZone)
        const greenZoneResponse = JSON.parse(responseGreenZone)
        return success([...redZoneResponse, ...greenZoneResponse])
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

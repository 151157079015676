import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import { Dropdown, DropdownListItem, IconButton } from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'

export type ApplicationOperationsDropdownComponentProps = LastPassComponentProps<
  ApplicationDrawerState,
  typeof applicationsDrawerActions,
  {}
>

const StyledIconButton = styled(IconButton)`
  margin-left: 16px;
`

const MoreButton = props => (
  <StyledIconButton
    icon={More}
    data-qa={'ApplicationOperationsDropdownButton'}
    {...props}
  />
)

export const ApplicationOperationsDropdownComponent: React.FunctionComponent<ApplicationOperationsDropdownComponentProps> = ({
  state,
  actions
}) => {
  return (
    <Dropdown button={MoreButton}>
      <DropdownListItem
        red
        onClick={() => {
          if (state.selectedApplication) {
            actions.deleteApplication(
              state.selectedApplication.id,
              state.selectedApplication.name
            )
          }
        }}
        data-qa={'DeleteAppButton'}
      >
        <Trans>Delete app</Trans>
      </DropdownListItem>
    </Dropdown>
  )
}

export function createApplicationOperationsDropdown<
  TState extends { applicationDrawer: ApplicationDrawerState }
>() {
  return connectRedux(
    ApplicationOperationsDropdownComponent,
    (state: TState) => state.applicationDrawer,
    applicationsDrawerActions
  )
}

export type ApplicationOperationsDropdown = ReturnType<
  typeof createApplicationOperationsDropdown
>

import React, { ReactElement } from 'react'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { DialogTypes } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { EnumDictionary } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'
import { IterationCountDestroySessionsDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/IterationCountDestroySessionsDialog'
import { KeyRotationSaveK1DialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/key-rotation/KeyRotationSaveK1Dialog'
import { KeyRotationUpdateK2ComponentsDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/key-rotation/KeyRotationUpdateK2ComponentsDialog'
import { ResetMfaSharedSecretsDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/ResetMfaSharedSecretsDialog'
import { SecurityScoreHistoryDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/security-dashboard/SecurityScoreHistoryDialog'
import { MspSupportDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/MspSupportDialog'
import { ActivateUsersDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/users/ActivateUsersDialog'
import { DeleteUserConfirmationWordDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/users/DeleteUserConfirmationWordDialog'
import { DisableUsersDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/users/DisableUsersDialog'
import { ManageProvisionedUserConfirmationDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/users/ManageProvisionedUserConfirmationDialog'
import { RemoveFromCompanyConfirmationWordDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/users/RemoveFromCompanyConfirmationWordDialog'
import { TransferVaultConfirmationWordDialog } from '@lastpass/admin-console-dependencies/ui/global/dialogs/users/TransferVaultConfirmationWordDialog'
import { PermissionRequiredToManagePoliciesDialogComponent } from '@lastpass/admin-console-dependencies/ui/global/dialogs/utilization-dashboard/PermissionRequiredToManagePoliciesDialog'

import { ConfirmDialogComponent } from './ConfirmDialog'
import { DialogContext } from './DialogContext'
import { GoogleIntegrationSyncOptionsDialogComponent } from './GoogleIntegrationSyncOptionsDialog/GoogleIntegrationSyncOptionsDialog'
import { InvalidFileDialogComponent } from './InvalidFileDialog'
import {
  KeyRotationAlertDialogComponent,
  KeyRotationAuthenticateCompanyAdminDialogComponent,
  KeyRotationCompanyWideDoneDialogComponent,
  KeyRotationContinueDialogComponent,
  KeyRotationCopyK1DialogComponent,
  KeyRotationGeneralDialogComponent,
  KeyRotationInstructionsDialogComponent,
  KeyRotationK1InputDialogComponent,
  KeyRotationStartProcessDialogComponent,
  KeyRotationStatusDialogComponent
} from './key-rotation'
import { KeyRotationAccessDeniedDialogComponent } from './key-rotation/KeyRotationAccessDeniedDialog'
import { KeyRotationInconsistentConnectionErrorDialogComponent } from './key-rotation/KeyRotationInconsistentConnectionErrorDialog'
import { KeyRotationInconsistentGenericErrorDialogComponent } from './key-rotation/KeyRotationInconsistentGenericErrorDialog'
import { KeyRotationProgressDialogComponent } from './key-rotation/KeyRotationProgressDialog'
import { ManagedCompanyConfirmDialogComponent } from './managed-companies/ManagedCompanyConfirmDialog'
import { ManagedCompanyDetachConfirmDialogComponent } from './managed-companies/ManagedCompanyDetachConfirmDialog'
import { ManagedCompanyExportUserCountsComponent } from './managed-companies/ManagedCompanyExportUserCountsDialog'
import { MasterPasswordConfirmDialog } from './MasterPasswordConfirmDialog'
import { MfaUpsellDialogComponent } from './MfaUpsellDialog'
import { MfaUsageNotificationDialogComponent } from './MfaUsageNotificationDialog'
import { MspPaymentInfoDialogComponent } from './MspPaymentInfoDialog/MspPaymentInfoDialog'
import { MultiContextMfaUpsellDialogComponent } from './MultiContextMfaUpsellDialog'
import { NotificationDialogComponent } from './NotificationDialog'
import { RadioButtonDialogComponent } from './RadioButtonDialog'
import { NoKeysWereSharedErrorDialog } from './recovery-key-sharing/error-dialogs/NoKeysWereSharedErrorDialog'
import { RecipientAlreadyHasTheKeysErrorDialog } from './recovery-key-sharing/error-dialogs/RecipientAlreadyHasTheKeysErrorDialog'
import { SomeKeysWereSharedErrorDialog } from './recovery-key-sharing/error-dialogs/SomeKeysWereSharedErrorDialog'
import { RecoveryKeySharingConfirmDialog } from './recovery-key-sharing/RecoveryKeySharingConfirmDialog'
import { RecoveryKeySharingEndDialog } from './recovery-key-sharing/RecoveryKeySharingEndDialog'
import { RecoveryKeySharingProgressDialog } from './recovery-key-sharing/RecoveryKeySharingProgressDialog'
import { ErrorDecryptingUrlDataDialog } from './security-reports/ErrorDecryptingUrlDataDialog'
import { NotAllUrlDataCouldBeDecryptedDialog } from './security-reports/NotAllUrlDataCouldBeDecryptedDialog'
import { ProcessingReportDialog } from './security-reports/ProcessingReportDialog'
import { UrlDecryptionConfirmDialog } from './security-reports/UrlDecryptionConfirmDialog'
import { UrlDecryptionProgressDialog } from './security-reports/UrlDecryptionProgressDialog'
import { SharedFolderDialogComponent } from './SharedFolderDialog'
import { SsoUpsellContactSupportDialogComponent } from './SsoUpsellContactSupportDialog'
import { SsoUpsellDialogComponent } from './SsoUpsellDialog'
import { SuccessDialogComponent } from './SuccessDialog'
import { SupportDialogComponent } from './support-dialog/SupportDialog'
import { SystemUpgradeDialog } from './system-upgrade/SystemUpgradeDialog'
import { TextDialogComponent } from './TextDialog'
import { ActiveUsageRateDialogComponent } from './utilization-dashboard/ActiveUsageRateDialog'
import { EnrollmentRateDialogComponent } from './utilization-dashboard/EnrollmentRateDialog'
import { EditVaultReEncryptionDialogComponent } from './vault-re-encryption/EditVaultReEncryptionDialog'
import { ForceVaultReEncryptionConfirmationDialogComponent } from './vault-re-encryption/ForceVaultReEncryptionConfirmationDialog'
import { VaultReEncryptionOfferDetailsDialogComponent } from './vault-re-encryption/VaultReEncryptionOfferDetailsDialog'
import { VaultReEncryptionOfferComponent } from './vault-re-encryption/VaultReEncryptionOfferDialog'
import { VaultReEncryptionSetupDialogComponent } from './vault-re-encryption/VaultReEncryptionSetupDialog'

const WrapDialogInContext: React.FunctionComponent<{
  dialogComponent: React.FunctionComponent<{
    /* eslint-disable  @typescript-eslint/no-explicit-any */ // Needed because EnumDictionary is not filtering for correct type
    state: any
    actions: typeof globalActions
    props?: {}
  }>
}> = props => {
  const Dialog = props.dialogComponent
  return (
    <DialogContext.Consumer>
      {dialog => (
        <Dialog state={dialog.dialogState} actions={dialog.dialogActions} />
      )}
    </DialogContext.Consumer>
  )
}

export const GlobalDialogMapping: EnumDictionary<
  DialogTypes['type'],
  ReactElement
> = {
  ['empty']: <></>,
  ['notificationdialog']: (
    <WrapDialogInContext dialogComponent={NotificationDialogComponent} />
  ),
  ['textdialog']: <WrapDialogInContext dialogComponent={TextDialogComponent} />,
  ['confirmdialog']: (
    <WrapDialogInContext dialogComponent={ConfirmDialogComponent} />
  ),
  ['managed-company-confirm-dialog']: (
    <WrapDialogInContext
      dialogComponent={ManagedCompanyConfirmDialogComponent}
    />
  ),
  ['managed-company-detach-confirm-dialog']: (
    <WrapDialogInContext
      dialogComponent={ManagedCompanyDetachConfirmDialogComponent}
    />
  ),
  ['managed-company-export-user-counts-dialog']: (
    <WrapDialogInContext
      dialogComponent={ManagedCompanyExportUserCountsComponent}
    />
  ),
  ['confirmwithradio']: (
    <WrapDialogInContext dialogComponent={RadioButtonDialogComponent} />
  ),
  ['upselldialog']: (
    <WrapDialogInContext dialogComponent={MfaUpsellDialogComponent} />
  ),
  ['ssoupselldialog']: (
    <WrapDialogInContext dialogComponent={SsoUpsellDialogComponent} />
  ),
  ['ssoupsellcontactsupportdialog']: (
    <WrapDialogInContext
      dialogComponent={SsoUpsellContactSupportDialogComponent}
    />
  ),
  ['mfaupselldialog']: (
    <WrapDialogInContext
      dialogComponent={MultiContextMfaUpsellDialogComponent}
    />
  ),
  ['mfausagenotificationdialog']: (
    <WrapDialogInContext
      dialogComponent={MfaUsageNotificationDialogComponent}
    />
  ),
  ['sharedfolderdialog']: (
    <WrapDialogInContext dialogComponent={SharedFolderDialogComponent} />
  ),
  ['invalidfiledialog']: (
    <WrapDialogInContext dialogComponent={InvalidFileDialogComponent} />
  ),
  ['supportdialog']: (
    <WrapDialogInContext dialogComponent={SupportDialogComponent} />
  ),
  ['msp-support-dialog']: (
    <WrapDialogInContext dialogComponent={MspSupportDialogComponent} />
  ),
  ['successdialog']: (
    <WrapDialogInContext dialogComponent={SuccessDialogComponent} />
  ),
  ['googleintegrationsyncoptionsdialog']: (
    <WrapDialogInContext
      dialogComponent={GoogleIntegrationSyncOptionsDialogComponent}
    />
  ),
  ['msp-payment-info-dialog']: (
    <WrapDialogInContext dialogComponent={MspPaymentInfoDialogComponent} />
  ),
  ['reset-mfa-shared-secrets-dialog']: (
    <WrapDialogInContext dialogComponent={ResetMfaSharedSecretsDialog} />
  ),
  ['key-rotation-general-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationGeneralDialogComponent} />
  ),
  ['key-rotation-status-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationStatusDialogComponent} />
  ),
  ['key-rotation-progress-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationProgressDialogComponent} />
  ),
  ['key-rotation-k1-input-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationK1InputDialogComponent} />
  ),
  ['key-rotation-copy-k1-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationCopyK1DialogComponent} />
  ),
  ['key-rotation-save-k1-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationSaveK1DialogComponent} />
  ),
  ['key-rotation-start-process-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationStartProcessDialogComponent}
    />
  ),
  ['key-rotation-update-k2-components-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationUpdateK2ComponentsDialogComponent}
    />
  ),
  ['key-rotation-alert-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationAlertDialogComponent} />
  ),
  ['key-rotation-inconsistent-generic-error-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationInconsistentGenericErrorDialogComponent}
    />
  ),
  ['key-rotation-inconsistent-connection-error-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationInconsistentConnectionErrorDialogComponent}
    />
  ),
  ['key-rotation-authenticate-company-admin-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationAuthenticateCompanyAdminDialogComponent}
    />
  ),
  ['key-rotation-continue-dialog']: (
    <WrapDialogInContext dialogComponent={KeyRotationContinueDialogComponent} />
  ),
  ['key-rotation-instructions-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationInstructionsDialogComponent}
    />
  ),
  ['key-rotation-company-wide-done-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationCompanyWideDoneDialogComponent}
    />
  ),
  ['key-rotation-access-denied-dialog']: (
    <WrapDialogInContext
      dialogComponent={KeyRotationAccessDeniedDialogComponent}
    />
  ),
  ['force-vault-re-encryption-confirmation-dialog']: (
    <WrapDialogInContext
      dialogComponent={ForceVaultReEncryptionConfirmationDialogComponent}
    />
  ),
  ['vault-re-encryption-dialog']: (
    <WrapDialogInContext
      dialogComponent={VaultReEncryptionSetupDialogComponent}
    />
  ),
  ['edit-vault-re-encryption-dialog']: (
    <WrapDialogInContext
      dialogComponent={EditVaultReEncryptionDialogComponent}
    />
  ),
  ['vault-re-encryption-offer-dialog']: (
    <WrapDialogInContext dialogComponent={VaultReEncryptionOfferComponent} />
  ),
  ['vault-re-encryption-offer-details-dialog']: (
    <WrapDialogInContext
      dialogComponent={VaultReEncryptionOfferDetailsDialogComponent}
    />
  ),

  ['iteration-count-destroy-sessions-dialog']: (
    <WrapDialogInContext
      dialogComponent={IterationCountDestroySessionsDialogComponent}
    />
  ),
  ['system-upgrade-process-dialog']: (
    <WrapDialogInContext dialogComponent={SystemUpgradeDialog} />
  ),
  ['enrollment-rate-dialog']: (
    <WrapDialogInContext dialogComponent={EnrollmentRateDialogComponent} />
  ),
  ['active-usage-rate-dialog']: (
    <WrapDialogInContext dialogComponent={ActiveUsageRateDialogComponent} />
  ),
  ['permission-required-to-manage-policies-dialog']: (
    <WrapDialogInContext
      dialogComponent={PermissionRequiredToManagePoliciesDialogComponent}
    />
  ),
  ['recovery-key-sharing-confirm-dialog']: (
    <WrapDialogInContext dialogComponent={RecoveryKeySharingConfirmDialog} />
  ),
  ['master-password-confirm-dialog']: (
    <WrapDialogInContext dialogComponent={MasterPasswordConfirmDialog} />
  ),
  ['recovery-key-sharing-progress-dialog']: (
    <WrapDialogInContext dialogComponent={RecoveryKeySharingProgressDialog} />
  ),
  ['recovery-key-sharing-end-dialog']: (
    <WrapDialogInContext dialogComponent={RecoveryKeySharingEndDialog} />
  ),
  ['url-decryption-confirm-dialog']: (
    <WrapDialogInContext dialogComponent={UrlDecryptionConfirmDialog} />
  ),
  ['url-decryption-progress-dialog']: (
    <WrapDialogInContext dialogComponent={UrlDecryptionProgressDialog} />
  ),
  ['no-keys-were-shared-error-dialog']: (
    <WrapDialogInContext dialogComponent={NoKeysWereSharedErrorDialog} />
  ),
  ['recipient-already-has-the-keys']: (
    <WrapDialogInContext
      dialogComponent={RecipientAlreadyHasTheKeysErrorDialog}
    />
  ),
  ['some-keys-were-shared-error-dialog']: (
    <WrapDialogInContext dialogComponent={SomeKeysWereSharedErrorDialog} />
  ),
  ['disable-users-dialog']: (
    <WrapDialogInContext dialogComponent={DisableUsersDialogComponent} />
  ),
  ['activate-users-dialog']: (
    <WrapDialogInContext dialogComponent={ActivateUsersDialogComponent} />
  ),
  ['not-all-url-data-could-be-decrypted-dialog']: (
    <WrapDialogInContext
      dialogComponent={NotAllUrlDataCouldBeDecryptedDialog}
    />
  ),
  ['error-decrypting-url-data-dialog']: (
    <WrapDialogInContext dialogComponent={ErrorDecryptingUrlDataDialog} />
  ),
  ['transfer-vault-confirmation-word-dialog']: (
    <WrapDialogInContext
      dialogComponent={TransferVaultConfirmationWordDialog}
    />
  ),
  ['manage-provisioned-user-confirmation-dialog']: (
    <WrapDialogInContext
      dialogComponent={ManageProvisionedUserConfirmationDialog}
    />
  ),
  ['delete-user-confirmation-word-dialog']: (
    <WrapDialogInContext dialogComponent={DeleteUserConfirmationWordDialog} />
  ),
  ['remove-from-company-confirmation-word-dialog']: (
    <WrapDialogInContext
      dialogComponent={RemoveFromCompanyConfirmationWordDialog}
    />
  ),
  ['processing-report-dialog']: (
    <WrapDialogInContext dialogComponent={ProcessingReportDialog} />
  ),
  ['security-score-history-dialog']: (
    <WrapDialogInContext dialogComponent={SecurityScoreHistoryDialog} />
  )
}

import React, { FC } from 'react'

import { msg, Trans } from '@lingui/macro'

import { useCalculateChartRateThreshold } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-calculate-chart-rate-threshold'
import { ActionLinkCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionLinkCard'
import { DonutChartWithLegend } from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/DonutChartWithLegend'

export interface MasterPasswordStrengthDonutChartCardProps {
  strongMasterPasswordsRate: number
  strongMasterPasswordsCount: number
  weakOrReusedMasterPasswordsCount: number
  dataQa: string
}

export const MasterPasswordStrengthDonutChartCard: FC<MasterPasswordStrengthDonutChartCardProps> = ({
  strongMasterPasswordsRate,
  strongMasterPasswordsCount,
  weakOrReusedMasterPasswordsCount,
  dataQa
}) => {
  const { colors, subtitle } = useCalculateChartRateThreshold(
    strongMasterPasswordsRate
  )

  return (
    <ActionLinkCard
      dataQa={dataQa}
      learnMoreAction={() =>
        window.open(
          'https://link.lastpass.com/uac-security-dashboard',
          '_blank',
          'noopener, noreferrer'
        )
      }
    >
      <DonutChartWithLegend
        totalText={msg`Users with master password`}
        totalTooltip={
          <Trans>Federated users don’t have a master password</Trans>
        }
        items={[
          {
            value: strongMasterPasswordsCount,
            label: msg`Strong master passwords`,
            backgroundColor: colors.primary
          },
          {
            value: weakOrReusedMasterPasswordsCount,
            label: msg`Weak or reused master passwords`,
            backgroundColor: colors.secondary
          }
        ]}
        percentageInfo={{
          value: strongMasterPasswordsRate,
          subtitle: subtitle
        }}
        dataQa={dataQa && dataQa + 'DonutChart'}
      />
    </ActionLinkCard>
  )
}

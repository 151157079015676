import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { DetailsDialog } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { TextDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface TextDialogProps {
  state: TextDialog
}

export const TextDialogComponent: FunctionComponent<TextDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => dispatch(globalActions.emptyDialog())}
      title={state.title}
      subTitle={state.text}
    />
  )
}

import { msg } from '@lingui/macro'
import { all, call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { PasswordManagerTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

export function createDeleteLpDeviceSaga(userService: UACServices.Services) {
  return function* deleteLpDeviceSaga(
    action: ReturnType<typeof userDrawerActions.deletePasswordManagers>
  ) {
    const devices: PasswordManagerTrustedDevice[] = action.payload.devices
    const userId: string = action.payload.userId

    const texts =
      devices.length === 1
        ? {
            success: msg`Device has been deleted.`,
            fail: msg`Device hasn't been deleted.`,
            confirm: msg`Delete this device?`
          }
        : {
            success: msg`The selected devices have been deleted.`,
            fail: msg`None of the selected devices have been deleted. `,
            confirm: msg`Delete the selected devices?`
          }

    const dialog: ConfirmDialog = {
      type: 'confirmdialog',
      title: msg`Delete device`,
      text: texts.confirm,
      discardText: msg`No`,
      confirmText: msg`Yes`
    }
    yield put(globalActions.setDialog(dialog))
    const confirmClick = yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])

    if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
      yield put(userDrawerActions.setUserTrustedDevicesLoading(true))

      try {
        const results: UACServices.DeleteLpDeviceAPI.Responses[] = yield all(
          devices.map(device =>
            call(userService.deleteLpDevice, device, userId)
          )
        )

        const failedLpDevices: PasswordManagerTrustedDevice[] = []
        const successLpDevices: PasswordManagerTrustedDevice[] = []

        results.forEach((result, index) => {
          if (result.type === UACServices.UserAppsAPI.SUCCESS) {
            successLpDevices.push(devices[index])
          } else {
            failedLpDevices.push(devices[index])
          }
        })

        if (failedLpDevices.length === devices.length) {
          yield put(
            globalActions.setNotification({
              message: texts.fail,
              type: NotificationType.alert,
              autoDismiss: false
            })
          )
        } else if (successLpDevices.length === devices.length) {
          yield put(
            globalActions.setNotification({
              message: texts.success,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`Some devices haven't been deleted.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'lpdevices',
                devices: failedLpDevices
              }
            })
          )
        }
      } finally {
        yield put(
          userDrawerActions.getUserTrustedDevices({
            query: {},
            path: { id: userId }
          })
        )
      }
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SelectedApplication } from '@lastpass/admin-console-dependencies/state/applications/application'
import { mapGetAppResponseToSelectedApplication } from '@lastpass/admin-console-dependencies/ui/common/mappings/ssoapp-mappers'

export const SUCCESS = 'success'

const success = (applicationResponse: SelectedApplication) =>
  createResponse(SUCCESS, { applicationResponse })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service(id: string) {
    try {
      const [response, status] = await identityApi(
        `api/v2/Apps/GetById/${id}`,
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const applicationResponse = JSON.parse(response)
        return success(
          mapGetAppResponseToSelectedApplication(applicationResponse)
        )
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { GeneralPolicyUsersAndGroups } from './state'

export interface GeneralPolicyUsersAndGroupsUrlParams {
  id: string
  configId: string
}

export interface GeneralPolicyUsersAndGroupsQueryParams {
  search: string
}

export enum GeneralPolicyUsersAndGroupsActionTypes {
  RESET = '[general-policy-users-and-groups] reset',
  GET_GENERAL_POLICY_USERS_AND_GROUPS = '[general-policy-users-and-groups] get-general-policy-users-and-groups',
  SET_GENERAL_POLICY_USERS_AND_GROUPS = '[general-policy-users-and-groups] set-general-policy-users-and-groups',
  SAVE_GENERAL_POLICY_USERS_AND_GROUPS = '[general-policy-users-and-groups] save-general-policy-users-and-groups',
  SET_GENERAL_POLICY_USERS_AND_GROUPS_NEEDS_REFRESH = '[general-policy-users-and-groups] set-general-policy-needs-refresh',

  SET_GENERAL_POLICY_USERS_LOADING = '[general-policy-users-and-groups] set-policy-users-loading',
  ADD_SELECTED_GENERAL_POLICY_USERS = '[general-policy-users-and-groups] assign-selected-users',
  REMOVE_SELECTED_GENERAL_POLICY_USERS = '[general-policy-users-and-groups] remove-selected-users',
  SET_SELECTED_GENERAL_POLICY_USERS = '[general-policy-users-and-groups] set-selected-users',

  SET_GENERAL_POLICY_GROUPS_LOADING = '[general-policy-users-and-groups] set-policy-groups-loading',
  ADD_SELECTED_GENERAL_POLICY_GROUPS = '[general-policy-users-and-groups] assign-selected-groups',
  REMOVE_SELECTED_GENERAL_POLICY_GROUPS = '[general-policy-users-and-groups] remove-selected-groups',
  SET_SELECTED_GENERAL_POLICY_GROUPS = '[general-policy-users-and-groups] set-selected-groups',

  RESET_GENERAL_POLICY_USERS_AND_GROUPS_TABLES = '[general-policy-users-and-groups] reset-policy-users-and-groups-table',

  SET_GENERAL_POLICY_CURRENTLY_APPLIES_TO = '[general-policy-users-and-groups] set-policy-curently-applies-to',
  CHECK_GENERAL_POLICY_APPLIES_TO = '[general-policy-users-and-groups] check-policy-applies-to',
  SET_GENERAL_POLICY_USERS_AND_GROUPS_OPEN = '[general-policy-users-and-groups] set-general-policy-users-and-groups-open',
  SET_GENERAL_POLICY_USERS_AND_GROUPS_RECEIVED_RESPONSE_FROM_SERVER = '[general-policy-users-and-groups] set-general-policy-users-and-groups-received-response-from-server',
  SET_GENERAL_POLICY_USERS_AND_GROUPS_APPLIES_TO_FOR_ASSIGN = '[general-policy-users-and-groups] set-general-policy-users-and-groups-applies-to-for-assign'
}

export const generalPolicyUsersAndGroupsActions = {
  reset: () => createAction(GeneralPolicyUsersAndGroupsActionTypes.RESET),
  getGeneralPolicyUsersAndGroups: (
    params: DataFetchParams<
      GeneralPolicyUsersAndGroupsQueryParams,
      GeneralPolicyUsersAndGroupsUrlParams
    >
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.GET_GENERAL_POLICY_USERS_AND_GROUPS,
      {
        params
      }
    ),
  setGeneralPolicyUsersAndGroups: (
    policyUsersAndGroups: GeneralPolicyUsersAndGroups
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS,
      {
        policyUsersAndGroups
      }
    ),
  saveGeneralPolicyUsersAndGroups: (
    policyFormData: GeneralPolicyUsersAndGroups
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SAVE_GENERAL_POLICY_USERS_AND_GROUPS,
      {
        policyFormData
      }
    ),
  setGeneralPolicyUsersAndGroupsNeedsRefresh: (
    usersAndGroupsNeedsRefresh: boolean,
    policyKey: string,
    configKey: number
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_NEEDS_REFRESH,
      { usersAndGroupsNeedsRefresh, policyKey, configKey }
    ),
  setGeneralPolicyUsersLoading: (loading: boolean) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_LOADING,
      loading
    ),
  addSelectedGeneralPolicyUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_POLICY_USERS,
      {
        userList
      }
    ),
  removeSelectedGeneralPolicyUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_POLICY_USERS,
      {
        userList
      }
    ),
  setSelectedGeneralPolicyUsers: (userList: TeamsPolicyUserInfo[]) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_POLICY_USERS,
      {
        userList
      }
    ),
  setGeneralPolicyGroupsLoading: (loading: boolean) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_GROUPS_LOADING,
      loading
    ),
  addSelectedGeneralPolicyGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.ADD_SELECTED_GENERAL_POLICY_GROUPS,
      {
        groupList
      }
    ),
  removeSelectedGeneralPolicyGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.REMOVE_SELECTED_GENERAL_POLICY_GROUPS,
      {
        groupList
      }
    ),
  setSelectedGeneralPolicyGroups: (groupList: TeamsPolicyGroup[]) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_SELECTED_GENERAL_POLICY_GROUPS,
      {
        groupList
      }
    ),
  resetGeneralPolicyTables: () =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.RESET_GENERAL_POLICY_USERS_AND_GROUPS_TABLES
    ),
  setGeneralPolicyCurrentlyAppliesTo: (
    policyCurrentlyAppliesTo: AppliesToOptions
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_CURRENTLY_APPLIES_TO,
      { policyCurrentlyAppliesTo }
    ),
  checkGeneralPolicyAppliesTo: (
    policyAppliesTo: AppliesToOptions,
    params: DataFetchParams<
      GeneralPolicyUsersAndGroupsQueryParams,
      GeneralPolicyUsersAndGroupsUrlParams
    >
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.CHECK_GENERAL_POLICY_APPLIES_TO,
      { policyAppliesTo, params }
    ),
  setGeneralPolicyUsersAndGroupsOpen: (isUsersAndGroupsOpen: boolean) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_OPEN,
      { isUsersAndGroupsOpen }
    ),
  setGeneralPolicyUsersAndGroupsHasReceivedServerResponse: (
    hasServerResponded: boolean
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_RECEIVED_RESPONSE_FROM_SERVER,
      { hasServerResponded }
    ),
  setGeneralPolicyUsersAndGroupsAppliesToForAssign: (
    appliesTo: AppliesToOptions
  ) =>
    createAction(
      GeneralPolicyUsersAndGroupsActionTypes.SET_GENERAL_POLICY_USERS_AND_GROUPS_APPLIES_TO_FOR_ASSIGN,
      { appliesTo }
    )
}

export type GeneralPolicyUsersAndGroupsActions = ActionsUnion<
  typeof generalPolicyUsersAndGroupsActions
>

import { call, put, select } from 'redux-saga/effects'
import { addSlashes } from 'slashes'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

import { handleGoogleSetupFailure } from './handle-google-setup-failure'

export function createFetchGoogleUsers(
  enterpriseOptionServices: UACServices.Services
) {
  return function* fetchGoogleUsers(
    action: ReturnType<typeof directoriesActions.fetchGoogleUsers>
  ) {
    try {
      const userIdsToSync = yield select(
        (state: AppState) => state.integrations.Google.userIdsToSync
      )
      const nextPageToken = yield select(
        (state: AppState) => state.integrations.GoogleUsers.nextPageToken
      )

      const { search, replace } = action.payload

      const result: UACServices.GoogleUsersGetAPI.Responses = yield call(
        enterpriseOptionServices.googleUsersGet,
        !replace ? nextPageToken : null,
        `${encodeURIComponent(addSlashes(search || '', "'"))}`
      )

      if (result.type === UACServices.GoogleUsersGetAPI.SUCCESS) {
        const usersWithSelectionState = result.body.googleUsers.map(user => ({
          ...user,
          selected: userIdsToSync.includes(user.id)
        }))

        yield put(
          directoriesActions.setGoogleUsers(
            usersWithSelectionState,
            replace,
            result.body.nextPageToken || null
          )
        )
      } else {
        yield handleGoogleSetupFailure()
      }
    } catch (err) {
      yield handleGoogleSetupFailure()
    }
  }
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyRegular,
  CardContainer,
  DrawerButtonsContainer,
  FooterContainer,
  Heading100,
  Pagination,
  PrimaryButton,
  RowContainer,
  StyledSpacer,
  Table,
  TableView,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import {
  connectRedux,
  LastPassComponentProps,
  useUnmountEffect
} from '@lastpass/ui'

import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { DashboardDrawerState } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/state'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'

export type UnresponsiveUsersDrawerProps = LastPassComponentProps<
  DashboardDrawerState,
  typeof dashboardDrawerActions
>

const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  padding: 18px;
`

export const UnresponsiveUsersDrawerComponent: React.FunctionComponent<UnresponsiveUsersDrawerProps> = ({
  state,
  actions
}) => {
  const queryParams = useQueryParams(Namespace.unresponsiveUsers)
  const updateQuery = useUpdateQuery(Namespace.unresponsiveUsers)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      dashboardDrawerActions.getUnresponsiveUsers({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  useUnmountEffect(() => {
    actions.reset()
  })

  return (
    <>
      {state.dashboardDrawerLoading && (
        <Loading color="blue900" active={true} />
      )}
      <BodyRegular>
        <Trans>
          <b>{state.unresponsiveUsers.totalResults}</b> users have had the
          status <b>Invited</b> or <b>Expired invitation</b> for more than 7
          days. Reinvite them all or export to CSV to send your own reminder. To
          reinvite individually, go to the Users page.
        </Trans>
      </BodyRegular>
      <CardContainer
        css={css`
          margin-top: 24px;
        `}
      >
        <StyledRowContainer>
          <Heading100>
            <Trans>Users</Trans>
          </Heading100>
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={state.unresponsiveUsers.totalResults}
            resultsPerPage={state.unresponsiveUsers.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </StyledRowContainer>
        <Table
          qadata={'UnresponsiveUsersDrawerTable'}
          table={state.unresponsiveUsers}
          columns={[
            {
              name: <Trans>Name</Trans>,
              renderer: (record: Partial<User>) => getFullName(record)
            },
            {
              name: <Trans>Email</Trans>,
              renderer: (record: Partial<User>) =>
                record.email ? record.email : ''
            }
          ]}
          loadingView={<Loading color="blue900" active={true} />}
          actions={[]}
          noRecordsView={
            <TableView
              icon={<UserIcon />}
              title={<Trans>Congrats! Everyone has responded</Trans>}
            />
          }
        />
      </CardContainer>

      <>
        <StyledSpacer />
        <FooterContainer>
          <DrawerButtonsContainer>
            {state.unresponsiveUsers.totalResults > 0 ? (
              <>
                <LocationLink to={'/dashboard'}>
                  <TextButton>
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  onClick={() => {
                    actions.exportUnresponsiveUsers()
                    actions.exportUsersClicked('Reinvite Users Drawer')
                  }}
                  css={css`
                    margin-left: 16px;
                  `}
                >
                  <Trans>Export users</Trans>
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    actions.reinviteUnresponsiveUsers()
                    actions.reinviteUsersButtonClicked(
                      state.inactiveUsers.totalResults
                    )
                  }}
                  css={css`
                    margin-left: 16px;
                  `}
                >
                  <Trans>Resend invitations</Trans>
                </PrimaryButton>
              </>
            ) : (
              <LocationLink to={'/dashboard'}>
                <PrimaryButton>
                  <Trans>Cancel</Trans>
                </PrimaryButton>
              </LocationLink>
            )}
          </DrawerButtonsContainer>
        </FooterContainer>
      </>
    </>
  )
}

export function createUnresponsiveUsersDrawer<
  TState extends { dashboardDrawer: DashboardDrawerState }
>() {
  return connectRedux(
    UnresponsiveUsersDrawerComponent,
    (state: TState) => state.dashboardDrawer,
    dashboardDrawerActions
  )
}

export type UnresponsiveUsersDrawer = ReturnType<
  typeof createUnresponsiveUsersDrawer
>

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import AppDiscoveryEmptyIllustration from '@lastpass/assets/png/admin-console/app-discovery-empty-state-illustration.png'
import { BodyRegular, Heading300, PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.img`
  margin-top: 150px;
  margin-bottom: 24px;
  width: 35rem;
  height: 12rem;
`

const StyledHeader = styled(Heading300)`
  margin-bottom: 16px;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-bottom: 24px;
  text-align: center;
  max-width: 500px;
`

const StyledLink = styled.a`
  margin-top: 24px;
  color: ${props => props.theme.colors.blue700};
`

export const AppDiscoveryEmptyPage: React.FunctionComponent = () => {
  return (
    <FlexContainer data-qa="AppDiscoveryEmptyPageContainer">
      <Image
        src={AppDiscoveryEmptyIllustration}
        data-qa="AppDiscoveryEmptyPagePicture"
      />
      <StyledHeader>
        <Trans>Shine a light on app costs {'&'} data risk</Trans>
      </StyledHeader>
      <StyledBodyRegular>
        <Trans>
          Leverage the LastPass browser extension to reveal app logins, even for
          sites not saved in LastPass.
        </Trans>
      </StyledBodyRegular>
      <LocationLink to="/policies/generalpolicies/enableurllogging">
        <PrimaryButton data-qa="EnablePolicyButton" key="enablePolicyButton">
          <Trans>Enable app discovery</Trans>
        </PrimaryButton>
      </LocationLink>
      <StyledLink
        data-qa="HowDoesItWorkLink"
        target="_blank"
        href="https://support.lastpass.com/discovery"
      >
        <Trans>How does it work?</Trans>
      </StyledLink>
    </FlexContainer>
  )
}

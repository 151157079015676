import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { preparePolicyData } from '@lastpass/admin-console-dependencies/services/policies/policy-initializer'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { generalPoliciesListActions } from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { userOperationActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { PolicyCode } from '@lastpass/admin-console-dependencies/types/policies-enum'
import {
  InitialTeamsPolicyModelData,
  SaveTeamsPolicySwitchOnData,
  TeamsPolicy,
  TeamsPolicyMap,
  TeamsPolicyValueType
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPolicyInfo } from './get-general-policy'

const checkboxTypeEnabled = '1'

export const getCurrentUrlFilters = (state: AppState) => state.router

export const getPolicyValue = (
  policyValueType: TeamsPolicyValueType
): string | null => {
  if (policyValueType === TeamsPolicyValueType.checkbox) {
    return checkboxTypeEnabled
  } else if (
    policyValueType === TeamsPolicyValueType.textarea ||
    policyValueType === TeamsPolicyValueType.restrictedadmin
  ) {
    return ''
  } else {
    return null
  }
}

export const enableCheckboxPolicy = (policy: TeamsPolicy): TeamsPolicy => {
  if (policy.policyValueType !== TeamsPolicyValueType.checkbox) {
    return policy
  }

  return {
    ...policy,
    policyData: policy.policyData.map(data => {
      return { ...data, policyValue: checkboxTypeEnabled }
    })
  }
}

export function createEnableGeneralPolicySaga(
  policyService: UACServices.Services
) {
  return function* enableGeneralPolicySaga(
    action: ReturnType<
      typeof generalPoliciesDrawerActions.enableCurrentGeneralPolicy
    >
  ) {
    const policyKey = action.payload.policyKey
    const initialTeamsPolicyModelData: InitialTeamsPolicyModelData | null =
      action.payload.initialTeamsPolicyModelData
    const currentUrlFilters = yield select(getCurrentUrlFilters)

    if (policyKey) {
      try {
        yield put(
          generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
            true,
            policyKey
          )
        )
        const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
        const policy: TeamsPolicy = enableCheckboxPolicy(policyMap[policyKey])

        const policyData = preparePolicyData(
          policy,
          initialTeamsPolicyModelData
        )
        const policySaveData: SaveTeamsPolicySwitchOnData = {
          policyKey,
          policyData
        }
        yield put(
          push(
            `/policies/generalpolicies${currentUrlFilters.location.search ||
              ''}`
          )
        )
        const result: UACServices.GeneralPolicySwitchONSaveAPI.Responses = yield call(
          policyService.generalPolicySwitchOnSave,
          policySaveData
        )

        if (result.type === UACServices.GeneralPolicySwitchONSaveAPI.SUCCESS) {
          yield put(
            generalPoliciesListActions.getGeneralPoliciesList({
              query: getQueryParams(
                currentUrlFilters.location,
                Namespace.policies
              ),
              path: {}
            })
          )
          yield put(
            globalActions.setNotificationSuccess(msg`Policy changes saved.`)
          )

          yield put(generalPoliciesDrawerActions.setUnsavedPolicy(policy))
          yield put(
            generalPoliciesDrawerActions.setCurrentPolicyData(policyData[0])
          )
          if (policyKey === PolicyCode.hashIterationCountForMasterPassword) {
            yield put(
              userOperationActions.destroyAllSessionsForIterationCountPolicy()
            )
          }
        } else if (
          result.type === UACServices.GeneralPolicySwitchONSaveAPI.NO_VALUE ||
          result.type ===
            UACServices.GeneralPolicySwitchONSaveAPI.RESTRICTED_ADMIN_POLICY
        ) {
          yield put(
            generalPoliciesDrawerActions.setCurrentPolicyData(policyData[0])
          )
          yield put(
            generalPoliciesDrawerActions.setCurrentGeneralPolicyDataNeedsRefresh(
              false,
              policyKey
            )
          )
        } else if (
          result.type ===
          UACServices.GeneralPolicySwitchONSaveAPI
            .CONTROL_HASH_ITERATIONS_FOR_MASTER_PASSWORD_POLICY_VALIDATION_ERROR
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`Changes not saved. Numbers only, 600000 or higher. No spaces, no punctuation.`
            )
          )
        } else if (
          result.type ===
          UACServices.GeneralPolicySwitchONSaveAPI
            .LENGTH_OF_MASTER_PASSWORD_POLICY_VALIDATION_ERROR
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`Changes not saved. Numbers only, 12 or higher.`
            )
          )
        } else if (
          result.type ===
          UACServices.GeneralPolicySwitchONSaveAPI
            .VALUE_OF_VAULT_RE_ENCRYPTION_POLICY_VALIDATION_ERROR
        ) {
          yield put(
            globalActions.setNotificationAlert(
              msg`Changes not saved. Numbers only, between 30 and 365.`
            )
          )
        } else {
          yield put(
            globalActions.setNotificationAlert(
              msg`Policy changes haven't been saved.`
            )
          )
        }
      } catch (e) {
        yield put(globalActions.setNotification(genericFailedNotification))
      }
    }
  }
}

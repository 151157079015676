import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { RSASecureIDRadius, RSASecureIDRadiusFormData } from './state'

export enum RSASecureIDRadiusActionTypes {
  RSA_SECURE_ID_RADIUS_GET = '[rsa-secure-id-radius] get',
  RSA_SECURE_ID_RADIUS_SET = '[rsa-secure-id-radius] set',
  RSA_SECURE_ID_RADIUS_SAVE = '[rsa-secure-id-radius] save',
  RSA_SECURE_ID_RADIUS_SET_LOADING = '[rsa-secure-id-radius] set-loading'
}

export const rsaSecureIDRadiusActions = {
  getRSASecureIDRadius: () =>
    createAction(RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_GET),
  setRSASecureIDRadius: (rsaOptions: RSASecureIDRadius) =>
    createAction(RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_SET, {
      rsaOptions
    }),
  setRSASecureIDRadiusLoading: (loading: boolean) =>
    createAction(
      RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_SET_LOADING,
      {
        loading
      }
    ),
  saveRSASecureIDRadius: (rsaOptions: RSASecureIDRadiusFormData) =>
    createAction(RSASecureIDRadiusActionTypes.RSA_SECURE_ID_RADIUS_SAVE, {
      rsaOptions
    })
}

export type RSASecureIDRadiusActions = ActionsUnion<
  typeof rsaSecureIDRadiusActions
>

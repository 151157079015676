import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as SearchIllustration } from '@lastpass/assets/svg/admin-console/illustration-search.svg'
import { Loading } from '@lastpass/components'
import { BodyBold } from '@lastpass/lastkit'

import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import { GoogleGroup } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

import { GoogleGroup as GoogleGroupComponent } from './GoogleGroup'
import {
  PartialSyncItemListContainer,
  StyledSearchInput
} from './StyledComponents'

interface SearchBasedGoogleGroupListProps {
  groups: GoogleGroup[]
  handleGroupSelection: (id: string, selected: boolean) => void
  isLoading: boolean
  nextPageToken: string | null
}

const ItemList = styled(PartialSyncItemListContainer)`
  overflow-x: hidden;
  overflow-y: auto;
`
const ItemListContainer = styled.div`
  width: 100%;
  position: relative;
`

const GroupSearchPlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const GroupContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
  padding-left: 5px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
`

const GroupSearchPlaceholder = () => (
  <GroupSearchPlaceholderContainer>
    <SearchIllustration data-qa="SearchPlaceholderIllustration" />
    <div>
      <Trans>
        <BodyBold>Search groups</BodyBold>
      </Trans>
    </div>
    <div>
      <Trans>Use the first letters of the group name.</Trans>
    </div>
  </GroupSearchPlaceholderContainer>
)

const SPACE_KEY_CODE = 32
const QUOTE_KEY_CODE = 34

export const SearchBasedGoogleGroupList: FunctionComponent<SearchBasedGoogleGroupListProps> = ({
  isLoading,
  groups,
  handleGroupSelection,
  nextPageToken
}) => {
  const dispatch = useDispatch()

  const [googleGroupsQuery, setGoogleGroupsQuery] = useState<string | null>(
    null
  )

  const fetchMoreGroups = (query: string) => {
    dispatch(directoriesActions.searchGoogleGroups(query, false))
  }

  const handleGoogleGroupSearch = useCallback(
    (query: string) => {
      dispatch(directoriesActions.searchGoogleGroups(query, true))
    },
    [dispatch]
  )

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const element = e.currentTarget

    const hasReachedListEnd = groups.length !== 0 && nextPageToken === null

    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight &&
      !isLoading &&
      !hasReachedListEnd &&
      googleGroupsQuery
    ) {
      fetchMoreGroups(googleGroupsQuery)
    }
  }

  useEffect(() => {
    if (googleGroupsQuery) {
      handleGoogleGroupSearch(googleGroupsQuery)
    }
  }, [googleGroupsQuery, handleGoogleGroupSearch])

  const isSearchEmpty = groups.length === 0 || !googleGroupsQuery

  return (
    <>
      <StyledSearchInput
        value={googleGroupsQuery || ''}
        data-qa="GoogleIntegrationSyncOptionsSyncSpecifiedGroupsSearchBar"
        onChange={e => {
          setGoogleGroupsQuery(e.target.value)
        }}
        onKeyDown={e => {
          if (e.which === SPACE_KEY_CODE || e.which === QUOTE_KEY_CODE) {
            e.preventDefault()
            return false
          }
        }}
        placeholder={msg`Search by first letters in group name...`}
        flexGrow={true}
      />
      <ItemListContainer>
        {isLoading && <Loading color="blue900" active={true} isFull={true} />}
        <ItemList
          onScroll={(e: React.UIEvent<HTMLElement>) => handleScroll(e)}
          data-qa="GoogleGroupListScrollContainer"
        >
          <>
            {isSearchEmpty ? (
              <GroupSearchPlaceholder />
            ) : (
              <>
                {groups.map(group => (
                  <GroupContainer key={group.id}>
                    <GoogleGroupComponent
                      isSelected={group.selected}
                      isIndeterminate={false}
                      handleGroupSelection={(selected: boolean) =>
                        handleGroupSelection(group.id, selected)
                      }
                      name={group.name}
                    />
                  </GroupContainer>
                ))}
              </>
            )}
          </>
        </ItemList>
      </ItemListContainer>
    </>
  )
}

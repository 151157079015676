import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'
import {
  AuthMethod,
  LastPassMFAPolicyAddedProperties,
  Selection
} from '@lastpass/tracking/tracking-plans/uac'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  SaveTeamsPolicySwitchOnData,
  TeamsPolicy,
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  DuoSecurityModel,
  MultifactorOptionType,
  RsaModel,
  SecureAuthModel,
  SymantecFormModel,
  SymantecModel
} from '../multifactor'
import { MultifactorDrawerSteps } from './multifactor-drawer-steps'
import { GeneralPolicyUsersAndGroupsFormState } from './state'

export enum Entities {
  availableUsers = 'availableUsers',
  filteredUsers = 'filteredUsers',
  users = 'users',
  availableGroups = 'availableGroups',
  filteredGroups = 'filteredGroups',
  groups = 'groups'
}

export enum MultifactorDrawerActionTypes {
  SET_DRAWER_LOADING = '[multifactor-drawer] set-drawer-loading',
  NEXT_STEP = '[multifactor-drawer] next-step',
  DISCARD = '[multifactor-drawer] discard',
  GET_MULTIFACTOR_OPTIONS = '[multifactor-drawer] get-multifactor-options',
  SET_SELECTED_MFA_OPTION = '[multifactor-drawer] set-selected-multifactor-option',
  GET_DUO_CONFIG = '[multifactor-drawer] get-duo-config',
  SET_DUO_CONFIG = '[multifactor-drawer] set-duo-config',
  GET_SYMANTEC_CONFIG = '[multifactor-drawer] get-symantec-config',
  SET_SYMANTEC_CONFIG = '[multifactor-drawer] set-symantec-config',
  PROCESS_SYMANTEC_CONFIG = '[multifactor-drawer] process-symantec-config',
  GET_RSA_CONFIG = '[multifactor-drawer] get-rsa-config',
  SET_RSA_CONFIG = '[multifactor-drawer] set-rsa-config',
  PROCESS_RSA_CONFIG = '[multifactor-drawer] process-rsa-config',
  SAVE_RSA_CONFIG = '[multifactor-drawer] save-rsa-config',
  GET_SECURE_AUTH_CONFIG = '[multifactor-drawer] get-secure-auth-config',
  SET_SECURE_AUTH_CONFIG = '[multifactor-drawer] set-secure-auth-config',
  GET_DRAWER_POLICY = '[multifactor-drawer] init-drawer-policy',
  SET_DRAWER_POLICY = '[multifactor-drawer] set-drawer-policy',
  SHOW_TABLE = '[multifactor-drawer] show-table',
  SAVE_DUO_SECURITY_SETTINGS = '[multifactor-drawer] save-dou-security-settings',
  SAVE_SYMANTEC_SETTINGS = '[multifactor-drawer] save-symantec-settings',
  SAVE_SYMANTEC_CONFIG = '[multifactor-drawer] save-symantec-config',
  SAVE_RSA_SETTINGS = '[multifactor-drawer] save-rsa-settings',
  SAVE_SECURE_AUTH_SETTINGS = '[multifactor-drawer] save-secure-auth-settings',
  SELECT_TABLE_ELEMENT = '[multifactor-drawer] select-table-element',
  DESELECT_TABLE_ELEMENT = '[multifactor-drawer] deselect-table-element',
  SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS = '[multifactor-drawer] select-or-deselect-table-element',
  SAVE_TOTP_SETTINGS = '[multifactor-drawer] save-totp-settings',
  SAVE_SALESFORCE_SETTINGS = '[multifactor-drawer] save-salesforce-settings',
  SET_USER_LIST_SEARCH_INPUT = '[multifactor-drawer] set-user-list-search-input',
  SET_GROUP_LIST_SEARCH_INPUT = '[multifactor-drawer] set-group-list-search-input',
  CHANGE_APPLIES_TO = '[multifactor-drawer] change-applies-to',
  GET_AVAILABLE_USERS = '[multifactor-drawer] get-available-users',
  SET_AVAILABLE_USERS_LOADING = '[multifactor-drawer] set-available-users-loading',
  SET_AVAILABLE_USERS = '[multifactor-drawer] set-available-users',
  GET_AVAILABLE_GROUPS = '[multifactor-drawer] get-available-groups',
  SET_AVAILABLE_GROUPS_LOADING = '[multifactor-drawer] set-available-groups-loading',
  SET_AVAILABLE_GROUPS = '[multifactor-drawer] set-available-groups',
  ASSIGN = '[multifactor-drawer] assign',
  UNASSIGN = '[multifactor-drawer] unassign',
  FILTER_USERS = '[multifactor-drawer] filter-users',
  FILTER_GROUPS = '[multifactor-drawer] filter-groups',
  SET_POLICY_USER_DATA = '[multifactor-drawer] set-policy-user-data',
  SAVE_POLICY_USERS = '[multifactor-drawer] save-policy-users',
  SET_IS_CONFIGURED = '[multifactor-drawer] set-is-configured',
  SET_SELECTED_MFA_METHOD_DEFAULT_VALUE = '[multifactor-drawer] set-selected-mfa-method-defualt-value',
  SETUP_INSTRUCTIONS_VIEWED = '[multifactor-drawer] setup-instructions-viewed',
  SETUP_INSTRUCTIONS_CLICKED = '[multifactor-drawer] setup-instructions-clicked',
  SELECT_MFA_METHOD_VIEWED = '[multifactor-drawer] select-mfa-method-viewed',
  SELECT_MFA_METHOD_CLICKED = '[multifactor-drawer] select-mfa-method-clicked',
  CONFIGURE_MFA_METHOD_VIEWED = '[multifactor-drawer] configure-mfa-method-viewed',
  CONFIGURE_MFA_METHOD_CLICKED = '[multifactor-drawer] configure-mfa-method-clicked',
  ASSIGN_USERS_VIEWED = '[multifactor-drawer] assign-users-viewed',
  ASSIGN_USERS_CLICKED = '[multifactor-drawer] assign-users-clicked',
  SETUP_CONFIRMATION_VIEWED = '[multifactor-drawer] setup-confirmation-viewed',
  SETUP_CONFIRMATION_CLICKED = '[multifactor-drawer] setup-confirmation-clicked'
}

function getMfaPolicyAddedEventData(
  policyData: SaveTeamsPolicySwitchOnData | null,
  authMethod: SegmentPropertyType<'LastPass MFA Policy Added', 'Auth Method'>
): LastPassMFAPolicyAddedProperties {
  return {
    'All Users Assigned':
      policyData && policyData.policyData && policyData.policyData.length > 0
        ? policyData.policyData[0].appliesTo == AppliesToOptions.all
        : false,
    'Assigned User Count':
      policyData &&
      policyData.policyData &&
      policyData.policyData.length > 0 &&
      policyData.policyData[0].appliesTo === AppliesToOptions.inclusive &&
      policyData.policyData[0].inclusiveList
        ? policyData.policyData[0].inclusiveList.length
        : 0,
    'Excluded User Count':
      policyData &&
      policyData.policyData &&
      policyData.policyData.length > 0 &&
      policyData.policyData[0].appliesTo === AppliesToOptions.exclusive &&
      policyData.policyData[0].exclusiveList
        ? policyData.policyData[0].exclusiveList.length
        : 0,
    'Auth Method': authMethod,
    Source: 'Multifactor Policies'
  }
}

export const multifactorDrawerActions = {
  setDrawerLoading: (isLoading: boolean) =>
    createAction(MultifactorDrawerActionTypes.SET_DRAWER_LOADING, {
      isLoading
    }),
  nextStep: (nextStep: MultifactorDrawerSteps) =>
    createAction(MultifactorDrawerActionTypes.NEXT_STEP, { nextStep }),
  discard: () => createAction(MultifactorDrawerActionTypes.DISCARD),
  getMultifactorOptions: () =>
    createAction(MultifactorDrawerActionTypes.GET_MULTIFACTOR_OPTIONS),
  setSelectedMfaMethod: (selectedMethod: MultifactorOptionType) =>
    createAction(MultifactorDrawerActionTypes.SET_SELECTED_MFA_OPTION, {
      selectedMethod
    }),
  getDuoConfig: () => createAction(MultifactorDrawerActionTypes.GET_DUO_CONFIG),
  setDuoConfig: (formValues: DuoSecurityModel) =>
    createAction(MultifactorDrawerActionTypes.SET_DUO_CONFIG, { formValues }),
  getSymantecConfig: () =>
    createAction(MultifactorDrawerActionTypes.GET_SYMANTEC_CONFIG),
  setSymantecConfig: (symantecConfig: SymantecModel) =>
    createAction(MultifactorDrawerActionTypes.SET_SYMANTEC_CONFIG, {
      symantecConfig
    }),
  processSymantecConfig: (formValues: SymantecFormModel) =>
    createAction(MultifactorDrawerActionTypes.PROCESS_SYMANTEC_CONFIG, {
      formValues
    }),
  getRsaConfig: () => createAction(MultifactorDrawerActionTypes.GET_RSA_CONFIG),
  setRsaConfig: (formValues: RsaModel) =>
    createAction(MultifactorDrawerActionTypes.SET_RSA_CONFIG, { formValues }),
  processRsaConfig: (formValues: RsaModel) =>
    createAction(MultifactorDrawerActionTypes.PROCESS_RSA_CONFIG, {
      formValues
    }),
  saveRsaConfig: (rsaConfig: RsaModel, closeLink: string) =>
    createAction(MultifactorDrawerActionTypes.SAVE_RSA_CONFIG, {
      rsaConfig,
      closeLink
    }),
  getSecureAuthConfig: () =>
    createAction(MultifactorDrawerActionTypes.GET_SECURE_AUTH_CONFIG),
  setSecureAuthConfig: (formValues: SecureAuthModel) =>
    createAction(MultifactorDrawerActionTypes.SET_SECURE_AUTH_CONFIG, {
      formValues
    }),
  getDrawerPolicy: (policyKey: string) =>
    createAction(MultifactorDrawerActionTypes.GET_DRAWER_POLICY, { policyKey }),
  setDrawerPolicy: (policy: TeamsPolicy) =>
    createAction(MultifactorDrawerActionTypes.SET_DRAWER_POLICY, policy),
  showTable: (table: string) =>
    createAction(MultifactorDrawerActionTypes.SHOW_TABLE, {
      table
    }),
  saveDuoSecuritySettings: (
    policyData: SaveTeamsPolicySwitchOnData | null,
    duoConfig: DuoSecurityModel,
    closeLink: string
  ) =>
    track(
      createAction(MultifactorDrawerActionTypes.SAVE_DUO_SECURITY_SETTINGS, {
        policyData,
        duoConfig,
        closeLink
      }),
      {
        segment: uacEvent(
          'LastPass MFA Policy Added',
          getMfaPolicyAddedEventData(policyData, 'Duo Security')
        )
      }
    ),
  saveSymantecSettings: (
    policyData: SaveTeamsPolicySwitchOnData,
    symantecConfig: SymantecModel,
    closeLink: string
  ) =>
    track(
      createAction(MultifactorDrawerActionTypes.SAVE_SYMANTEC_SETTINGS, {
        policyData,
        symantecConfig,
        closeLink
      }),
      {
        segment: uacEvent(
          'LastPass MFA Policy Added',
          getMfaPolicyAddedEventData(policyData, 'Symantec VIP')
        )
      }
    ),
  saveSymantecConfig: (symantecConfig: SymantecFormModel, closeLink: string) =>
    createAction(MultifactorDrawerActionTypes.SAVE_SYMANTEC_CONFIG, {
      symantecConfig,
      closeLink
    }),
  saveRsaSettings: (
    policyData: SaveTeamsPolicySwitchOnData,
    rsaConfig: RsaModel,
    closeLink: string
  ) =>
    track(
      createAction(MultifactorDrawerActionTypes.SAVE_RSA_SETTINGS, {
        policyData,
        rsaConfig,
        closeLink
      }),
      {
        segment: uacEvent(
          'LastPass MFA Policy Added',
          getMfaPolicyAddedEventData(policyData, 'RSA SecureID')
        )
      }
    ),
  saveSecureAuthSettings: (
    policyData: SaveTeamsPolicySwitchOnData | null,
    secureAuthConfig: SecureAuthModel,
    closeLink: string
  ) =>
    track(
      createAction(MultifactorDrawerActionTypes.SAVE_SECURE_AUTH_SETTINGS, {
        policyData,
        secureAuthConfig,
        closeLink
      }),
      {
        segment: uacEvent(
          'LastPass MFA Policy Added',
          getMfaPolicyAddedEventData(policyData, 'SecureAuth')
        )
      }
    ),
  setUserListSearchInput: (searchString: string) =>
    createAction(MultifactorDrawerActionTypes.SET_USER_LIST_SEARCH_INPUT, {
      searchString
    }),
  addSelectedUsers: (records: TeamsPolicyUserInfo[]) =>
    createAction(MultifactorDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableUsers
    }),
  removeSelectedUsers: (records: TeamsPolicyUserInfo[]) =>
    createAction(MultifactorDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableUsers
    }),
  setSelectedUsers: (records: TeamsPolicyUserInfo[]) =>
    createAction(
      MultifactorDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableUsers
      }
    ),
  setGroupListSearchInput: (searchString: string) =>
    createAction(MultifactorDrawerActionTypes.SET_GROUP_LIST_SEARCH_INPUT, {
      searchString
    }),
  addSelectedGroups: (records: TeamsPolicyGroup[]) =>
    createAction(MultifactorDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableGroups
    }),
  removeSelectedGroups: (records: TeamsPolicyGroup[]) =>
    createAction(MultifactorDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.availableGroups
    }),
  setSelectedGroups: (records: TeamsPolicyGroup[]) =>
    createAction(
      MultifactorDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.availableGroups
      }
    ),
  addSelectedUsersToUnassign: (records: TeamsPolicyUserInfo[]) =>
    createAction(MultifactorDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.filteredUsers
    }),
  removeSelectedUsersToUnassign: (records: TeamsPolicyUserInfo[]) =>
    createAction(MultifactorDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.filteredUsers
    }),
  setSelectedUsersToUnassign: (records: TeamsPolicyUserInfo[]) =>
    createAction(
      MultifactorDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.filteredUsers
      }
    ),
  addSelectedGroupsToUnassign: (records: TeamsPolicyGroup[]) =>
    createAction(MultifactorDrawerActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.filteredGroups
    }),
  removeSelectedGroupsToUnassign: (records: TeamsPolicyGroup[]) =>
    createAction(MultifactorDrawerActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.filteredGroups
    }),
  setSelectedGroupsToUnassign: (records: TeamsPolicyGroup[]) =>
    createAction(
      MultifactorDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS,
      {
        records,
        entity: Entities.filteredGroups
      }
    ),
  changeAppliesTo: (appliesTo: AppliesToOptions) =>
    createAction(MultifactorDrawerActionTypes.CHANGE_APPLIES_TO, { appliesTo }),
  saveTotpSettings: (
    policyData: SaveTeamsPolicySwitchOnData,
    closeLink: string
  ) =>
    track(
      createAction(MultifactorDrawerActionTypes.SAVE_TOTP_SETTINGS, {
        policyData,
        closeLink
      }),
      {
        segment: uacEvent(
          'LastPass MFA Policy Added',
          getMfaPolicyAddedEventData(policyData, 'TOTP App')
        )
      }
    ),
  saveSalesforceSettings: (
    policyData: SaveTeamsPolicySwitchOnData,
    closeLink: string
  ) =>
    track(
      createAction(MultifactorDrawerActionTypes.SAVE_SALESFORCE_SETTINGS, {
        policyData,
        closeLink
      }),
      {
        segment: uacEvent(
          'LastPass MFA Policy Added',
          getMfaPolicyAddedEventData(policyData, 'Salesforce')
        )
      }
    ),
  getAvailableUsers: (searchKey: string) =>
    createAction(MultifactorDrawerActionTypes.GET_AVAILABLE_USERS, {
      searchKey
    }),
  setAvailableUsersLoading: (isLoading: boolean) =>
    createAction(MultifactorDrawerActionTypes.SET_AVAILABLE_USERS_LOADING, {
      isLoading
    }),
  setAvailableUsers: (users: TeamsPolicyUserInfo[]) =>
    createAction(MultifactorDrawerActionTypes.SET_AVAILABLE_USERS, {
      users
    }),
  getAvailableGroups: (searchKey: string) =>
    createAction(MultifactorDrawerActionTypes.GET_AVAILABLE_GROUPS, {
      searchKey
    }),
  setAvailableGroupsLoading: (isLoading: boolean) =>
    createAction(MultifactorDrawerActionTypes.SET_AVAILABLE_GROUPS_LOADING, {
      isLoading
    }),
  setAvailableGroups: (groups: TeamsPolicyGroup[]) =>
    createAction(MultifactorDrawerActionTypes.SET_AVAILABLE_GROUPS, {
      groups
    }),
  assign: () => createAction(MultifactorDrawerActionTypes.ASSIGN),
  unassign: () => createAction(MultifactorDrawerActionTypes.UNASSIGN),
  filterUsers: (filterString: string) =>
    createAction(MultifactorDrawerActionTypes.FILTER_USERS, { filterString }),
  filterGroups: (filterString: string) =>
    createAction(MultifactorDrawerActionTypes.FILTER_GROUPS, { filterString }),
  setPolicyUserData: (policyUserData: GeneralPolicyUsersAndGroupsFormState) =>
    createAction(MultifactorDrawerActionTypes.SET_POLICY_USER_DATA, {
      policyUserData
    }),
  savePolicyUsers: (
    policyData: SaveTeamsPolicySwitchOnData,
    closeLink: string
  ) =>
    createAction(MultifactorDrawerActionTypes.SAVE_POLICY_USERS, {
      policyData,
      closeLink
    }),
  setIsConfigured: (isConfigured: boolean) =>
    createAction(MultifactorDrawerActionTypes.SET_IS_CONFIGURED, {
      isConfigured
    }),
  setSelectedMfaMethodDefaultValue: () =>
    createAction(
      MultifactorDrawerActionTypes.SET_SELECTED_MFA_METHOD_DEFAULT_VALUE
    ),
  multifactorSetupInstructionsViewed: () =>
    track(
      createAction(MultifactorDrawerActionTypes.SETUP_INSTRUCTIONS_VIEWED, {}),
      {
        segment: uacEvent('MFA Policy Setup Instructions Viewed')
      }
    ),
  multifactorSetupInstructionsClicked: action =>
    track(
      createAction(MultifactorDrawerActionTypes.SETUP_INSTRUCTIONS_CLICKED, {
        action
      }),
      {
        segment: uacEvent('MFA Policy Setup Instructions Clicked', {
          Action: action
        })
      }
    ),
  multifactorSelectMfaMethodViewed: () =>
    track(
      createAction(MultifactorDrawerActionTypes.SELECT_MFA_METHOD_VIEWED, {}),
      {
        segment: uacEvent('MFA Policy Select Auth Viewed')
      }
    ),
  multifactorSelectMfaMethodClicked: (authMethod: AuthMethod, action) =>
    track(
      createAction(MultifactorDrawerActionTypes.SELECT_MFA_METHOD_CLICKED, {
        authMethod,
        action
      }),
      {
        segment: uacEvent('MFA Policy Select Auth Clicked', {
          Action: action,
          'Auth Method': authMethod
        })
      }
    ),
  multifactorConfigureMfaMethodViewed: (authMethod: AuthMethod) =>
    track(
      createAction(MultifactorDrawerActionTypes.CONFIGURE_MFA_METHOD_VIEWED, {
        authMethod
      }),
      {
        segment: uacEvent('MFA Policy Setup Configuration Viewed', {
          'Auth Method': authMethod
        })
      }
    ),
  multifactorConfigureMfaMethodClicked: (authMethod: AuthMethod, action) =>
    track(
      createAction(MultifactorDrawerActionTypes.CONFIGURE_MFA_METHOD_CLICKED, {
        authMethod,
        action
      }),
      {
        segment: uacEvent('MFA Policy Setup Configuration Clicked', {
          Action: action,
          'Auth Method': authMethod
        })
      }
    ),
  multifactorAssignUsersViewed: () =>
    track(createAction(MultifactorDrawerActionTypes.ASSIGN_USERS_VIEWED, {}), {
      segment: uacEvent('MFA Policy Assign Users Viewed')
    }),
  multifactorAssignUsersClicked: (selection: Selection, action) =>
    track(
      createAction(MultifactorDrawerActionTypes.ASSIGN_USERS_CLICKED, {
        selection,
        action
      }),
      {
        segment: uacEvent('MFA Policy Assign Users Clicked', {
          Action: action,
          Selection: selection
        })
      }
    ),
  multifactorSetupConfirmationViewed: () =>
    track(
      createAction(MultifactorDrawerActionTypes.SETUP_CONFIRMATION_VIEWED, {}),
      {
        segment: uacEvent('MFA Policy Setup Confirmation Viewed')
      }
    ),
  multifactorSetupConfirmationClicked: action =>
    track(
      createAction(MultifactorDrawerActionTypes.SETUP_CONFIRMATION_CLICKED, {
        action
      }),
      {
        segment: uacEvent('MFA Policy Setup Confirmation Clicked', {
          Action: action
        })
      }
    )
}

export type MultifactorDrawerActions = ActionsUnion<
  typeof multifactorDrawerActions
>

import { KeyData } from '../../types/common'
import { xorBuffers } from '../../utils/xor-buffers'
import { createFragmentId } from '../common/create-fragment-id'
import { generateRandomK1Buffer } from '../common/generate-random-k-1-buffer'
import { validateK1 } from '../common/validate-k-1'
import { FederationErrors } from '../federation-error-codes'

export interface RotateKeysProps {
  userKeyData: KeyData
  companyWideNewK1?: Buffer
}

export const rotateKeys = async ({
  userKeyData,
  companyWideNewK1
}: RotateKeysProps): Promise<KeyData> => {
  const isK1Valid = await validateK1(userKeyData.k1, userKeyData.fragmentId)
  if (!isK1Valid) {
    throw new Error(FederationErrors.FragmentIdValidationFailed)
  }

  const masterPassword: Buffer = xorBuffers(userKeyData.k1, userKeyData.k2)
  const newK1: Buffer = companyWideNewK1 || generateRandomK1Buffer()
  const newK2: Buffer = xorBuffers(masterPassword, newK1)
  const newUserKeyData: KeyData = {
    k1: newK1,
    k2: newK2,
    fragmentId: await createFragmentId(newK1)
  }

  const newMasterPassword: Buffer = await xorBuffers(
    newUserKeyData.k1,
    newUserKeyData.k2
  )

  // hopefully never happen
  if (Buffer.compare(newMasterPassword, masterPassword) !== 0) {
    throw new Error(FederationErrors.KeyRotationMpMissmatchError)
  }

  return newUserKeyData
}

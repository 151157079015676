import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'

import styled from '@emotion/styled'

import { AdvancedPageOverlayContainerID } from '@lastpass/admin-console/src/pages/advanced/AdvancedPage'

import { Overlay } from '@lastpass/admin-console-dependencies/ui/common/OverlayComponent'

const DRAWER_WIDTH = 588
const NAV_HEIGHT = 56

const StyledOverlay = styled(Overlay)<{ decreaseHeight?: number }>`
  cursor: pointer;
  z-index: 60;
  top: ${props =>
    props.decreaseHeight
      ? `${props.decreaseHeight + NAV_HEIGHT}px`
      : `${NAV_HEIGHT}px`};
  left: 0;
  width: calc(100vw - ${DRAWER_WIDTH}px);
  height: ${props =>
    props.decreaseHeight
      ? `calc(100vh - ${props.decreaseHeight + NAV_HEIGHT}px)`
      : `calc(100vh - ${NAV_HEIGHT}px)`};
`

export interface StyledDrawerOverlayProps {
  onClose: () => void
}

export const StyledDrawerOverlay: FunctionComponent<StyledDrawerOverlayProps> = ({
  onClose
}) => {
  const container = document.getElementById(AdvancedPageOverlayContainerID)

  return container
    ? ReactDOM.createPortal(
        <StyledOverlay
          data-qa="overlay"
          onClick={onClose}
          decreaseHeight={0}
        />,
        container
      )
    : null
}

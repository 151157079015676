import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { PsaIntegrationsActionTypes } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'

import { createGetPsaAgreementsSaga } from './agreements/get-psa-agreements'
import { createSavePsaAgreementsSaga } from './agreements/save-psa-agreements'
import { createDeletePsaCredentialsSaga } from './delete-psa-credentials'
import { createNavigatePsaDrawerSaga } from './drawer/navigate-psa-drawer'
import { createPsaCredentialsSaga } from './get-psa-credentials'
import { createPsaProviderDetailsSaga } from './get-psa-provider-details'
import { createPsaProductsSaga } from './products/get-psa-products'
import { createSavePsaProductsSaga } from './products/save-psa-products'
import { createSavePsaCredentialsSaga } from './save-psa-credentials'
import { createSwitchPsaProviderSaga } from './switch-psa-provider'

export function createPsaIntegrationsSaga(services: UACServices.Services) {
  const getPsaProviderDetailsSaga = createPsaProviderDetailsSaga(services)
  const getPsaCredentialsSaga = createPsaCredentialsSaga(services)
  const getPsaProductsSaga = createPsaProductsSaga(services)
  const savePsaCredentialsSaga = createSavePsaCredentialsSaga(services)
  const deletePsaCredentialsSaga = createDeletePsaCredentialsSaga(services)
  const switchPsaProviderSaga = createSwitchPsaProviderSaga()
  const savePsaProductsSaga = createSavePsaProductsSaga(services)
  const getPsaAgreementsSaga = createGetPsaAgreementsSaga(services)
  const savePsaAgreementsSaga = createSavePsaAgreementsSaga(services)
  const navigatePsaDrawerSaga = createNavigatePsaDrawerSaga()

  return function*() {
    yield takeEvery(
      PsaIntegrationsActionTypes.GET_PSA_PROVIDER_DETAILS,
      getPsaProviderDetailsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.GET_PSA_CREDENTIALS,
      getPsaCredentialsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.SAVE_PSA_CREDENTIAL,
      savePsaCredentialsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.SWITCH_PSA_PROVIDER,
      switchPsaProviderSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.GET_PSA_AGREEMENTS,
      getPsaAgreementsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.SAVE_PSA_AGREEMENTS,
      savePsaAgreementsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.GET_PSA_PRODUCTS,
      getPsaProductsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.SAVE_PSA_PRODUCTS,
      savePsaProductsSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.NAVIGATE_PSA_DRAWER,
      navigatePsaDrawerSaga
    )

    yield takeEvery(
      PsaIntegrationsActionTypes.DELETE_PSA_CREDENTIALS,
      deletePsaCredentialsSaga
    )
  }
}

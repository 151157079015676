import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'

import { CardContainer, Heading100 } from '@lastpass/lastkit'

const Container = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  margin: 18px 20px 0 20px;
  padding: 16px;
`
const EditLinkContainer = styled.div`
  margin-left: auto;
  margin-right: 8px;
`
interface ConfigCardProps {
  title: ReactElement
  editLink: ReactElement
}
export const ConfigCard: FunctionComponent<ConfigCardProps> = props => {
  return (
    <Container>
      <Heading100>{props.title}</Heading100>
      {props.children}
      <EditLinkContainer>{props.editLink}</EditLinkContainer>
    </Container>
  )
}

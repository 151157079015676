import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import { saveAs } from 'file-saver'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as DownloadIcon } from '@lastpass/assets/svg/admin-console/icon-download.svg'
import { IconButton } from '@lastpass/lastkit'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ObjectToCsv } from '@lastpass/admin-console-dependencies/services/object-to-csv'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'

export const AdvancedUserActivityExportButton: React.FC = () => {
  const dispatch = useDispatch()

  const {
    selectedSavedReport,
    currentPageUserActivityOriginalEvents
  } = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )

  const defaultReportName = 'LastPass_export'
  const reportName =
    (selectedSavedReport && selectedSavedReport.name) || defaultReportName

  const exportReport = () => {
    try {
      const formattedEvents = currentPageUserActivityOriginalEvents.map(
        event => {
          const { timeUtc, ...rest } = event
          return {
            ...rest,
            time: moment(timeUtc).format('MMMM D, YYYY [at] h:mm:ss A')
          }
        }
      )

      const eventsCsv = ObjectToCsv(formattedEvents)
      const eventsBlob = new Blob([eventsCsv], {
        type: 'text/csv;charset=utf-8;'
      })
      saveAs(eventsBlob, reportName)
    } catch {
      dispatch(globalActions.setNotification(genericFailedNotification))
    }
  }

  return (
    <IconButton
      data-qa="ExportReportButton"
      css={css`
        margin: 0 16px;
      `}
      icon={DownloadIcon}
      onClick={() => exportReport()}
    />
  )
}

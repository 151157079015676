import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminLevelForm } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

interface NewAdminLevelDrawerProps {
  closeLink: string
}

export const NewAdminLevelDrawer: React.FunctionComponent<NewAdminLevelDrawerProps> = ({
  closeLink
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(adminLevelDrawerActions.reset())
    }
  }, [dispatch])

  const mainTitle = <Trans>New admin level</Trans>

  const miscTitle = (
    <a
      data-qa="LearnMoreLink"
      href="https://support.logmeininc.com/lastpass/help/what-are-admin-roles"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Trans>Learn more about admin levels and permissions</Trans>
    </a>
  )

  return (
    <Drawer mainTitle={mainTitle} closeLink={closeLink} miscTitle={miscTitle}>
      <AdminLevelForm closeLink={closeLink} />
    </Drawer>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import {
  BodyRegular,
  Heading100,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { SegmentPropertyType } from '@lastpass/tracking'

import {
  applicationsDrawerActions,
  applicationsDrawerSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import {
  GetDisplayableData,
  ListSectionContainer,
  ListTitle
} from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/AllSet'
import {
  BodyText,
  BodyTitle,
  StyledAppIcon
} from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'
import { StyledRadioLabel } from '@lastpass/admin-console-dependencies/ui/common/DrawerAssign/AppliesToRadioButtons'

const TopBodyText = styled.div`
  margin-bottom: 24px;
`
const Bold = styled.span`
  font-weight: ${props => props.theme.fonts.weight.bold};
`
export interface TestLoginProps {
  closeLink: string
}

const RadioContainerDiv = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
`

const OpenAppButton = styled(PrimaryButton)`
  margin-top: 30px;
  width: fit-content;
`

export const TestLoginComponent: FunctionComponent<TestLoginProps> = props => {
  const state = useSelector<AppState, ApplicationDrawerState>(
    state => state.applicationDrawer
  )

  const currentUser = useSelector<AppState, CurrentUser>(
    state => state.global.currentUser
  )

  const history = useHistory()
  const appName = state.selectedApplication
    ? state.selectedApplication.applicationName
    : ''

  const appLogo = state.selectedApplication &&
    state.selectedApplication.customLogoUrl && (
      <StyledAppIcon
        data-qa={'AppLogo'}
        src={state.selectedApplication.customLogoUrl}
      />
    )

  const queryParams = useQueryParams(Namespace.users)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      applicationsDrawerActions.getUserList({ query: queryParams, path: {} })
    )
    dispatch(applicationsDrawerSegmentActions.testLoginViewed(appName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [userChoice, setUserChoice] = useState('none')
  const [isLinkOpened, setIsLinkOpened] = useState(false)

  const continueButtonText =
    userChoice == 'no' ? (
      <Trans>Restart configuration</Trans>
    ) : (
      <Trans>Save & continue</Trans>
    )

  const onDiscardClicked = (
    clickedButton: SegmentPropertyType<'SSO Test login Clicked', 'Action'>
  ) => {
    history.push(props.closeLink)
    dispatch(
      applicationsDrawerSegmentActions.testLoginClicked(
        appName,
        userChoice,
        clickedButton
      )
    )
  }

  return (
    <>
      <BodyTitle>
        {appLogo}
        <Heading100 data-qa="TestLoginAppName">{appName}</Heading100>
      </BodyTitle>
      <BodyRegular>
        <BodyText>
          <TopBodyText data-qa="TestLoginTopText">
            <Trans>
              Now you can try logging into <Bold>{appName}</Bold> with the
              assigned users, groups, or roles:
            </Trans>
          </TopBodyText>
        </BodyText>
        <ListSectionContainer data-qa="AllSetUsersSection">
          <ListTitle>
            <Trans>Users:</Trans>
          </ListTitle>
          {GetDisplayableData(state.users)}
        </ListSectionContainer>
        <BodyText>
          <Trans>
            <Bold>Important:</Bold> Use an incognito browser window if testing
            with a user other than{' '}
          </Trans>{' '}
          {currentUser.email}
        </BodyText>
      </BodyRegular>
      <OpenAppButton
        id="OpenAppButton"
        key="OpenAppButton"
        data-qa="OpenAppButton"
        onClick={() => {
          window.open(
            `${state.selectedApplication &&
              state.selectedApplication.HTMLInfo}`,
            '_blank'
          )
          setIsLinkOpened(true)
          dispatch(
            applicationsDrawerSegmentActions.testLoginClicked(
              appName,
              userChoice,
              'Open app'
            )
          )
        }}
      >
        <Trans>Open</Trans> {appName}
      </OpenAppButton>
      {isLinkOpened && (
        <RadioContainerDiv>
          <BodyTitle>
            <Trans>Was the login successful?</Trans>
          </BodyTitle>
          <StyledRadioLabel data-qa={'RadioButtonYes'}>
            <input
              onChange={event => {
                setUserChoice(event.currentTarget.value)
              }}
              type="radio"
              value="yes"
              checked={userChoice == 'yes'}
            />
            <Trans>Yes</Trans>
          </StyledRadioLabel>
          <StyledRadioLabel data-qa={'RadioButtonNo'}>
            <input
              onChange={event => {
                setUserChoice(event.currentTarget.value)
              }}
              type="radio"
              value="no"
              checked={userChoice == 'no'}
            />
            <Trans>No</Trans>
          </StyledRadioLabel>
        </RadioContainerDiv>
      )}
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => onDiscardClicked('Discard')}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <TextButton
            blue
            css={css`
              margin-right: 16px;
            `}
            key={'SaveAndExit'}
            data-qa={'SaveAndExitButton'}
            onClick={() => onDiscardClicked('Save and exit')}
          >
            <Trans>Save & exit</Trans>
          </TextButton>
          <PrimaryButton
            id="Continue"
            key="Continue"
            disabled={userChoice == 'none'}
            onClick={() => {
              if (userChoice == 'no') {
                dispatch(
                  applicationsDrawerActions.nextStep(
                    ApplicationDrawerSteps.configure
                  )
                )
                dispatch(
                  applicationsDrawerSegmentActions.testLoginClicked(
                    appName,
                    userChoice,
                    'Restart configuration'
                  )
                )
                dispatch(applicationsDrawerActions.setTestLoginSucessState())
              } else {
                dispatch(
                  applicationsDrawerActions.nextStep(
                    ApplicationDrawerSteps.assign
                  )
                )
                dispatch(
                  applicationsDrawerSegmentActions.testLoginClicked(
                    appName,
                    userChoice,
                    'Save and continue'
                  )
                )
              }
            }}
            data-qa={
              userChoice == 'no'
                ? 'RestartConfigurationButton'
                : 'SaveAndContinueButton'
            }
          >
            {continueButtonText}
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

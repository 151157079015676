import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SymantecVipSettings } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/state'

export const SUCCESS = 'success'
export const ERROR = 'error'

const success = () => createResponse(SUCCESS)
const error = (message: string) => createResponse(ERROR, message)

export const Responses = {
  success,
  error
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(symantecVipSettings: SymantecVipSettings) {
    const { certificatePassword, certificate } = symantecVipSettings
    const isCertificatePasswordProtected = !!(
      certificatePassword && certificatePassword.indexOf('*') === 0
    )
    const isCertificateEmpty = certificate === ''

    if (isCertificatePasswordProtected) {
      delete symantecVipSettings.certificatePassword
    }
    if (isCertificateEmpty) {
      //@ts-ignore
      delete symantecVipSettings.certificate
    }

    try {
      const [response, status] = await teamsapi(
        'my-company/savesymantecvip',
        'POST',
        {
          ...symantecVipSettings,
          certificate: Buffer.from(symantecVipSettings.certificate).toString(
            'base64'
          )
        }
      )
      if (status === StatusCodes.OK && response) {
        const saveSuccess = JSON.parse(response)
        if (saveSuccess.success) {
          return success()
        } else {
          return error(saveSuccess.errorMessage)
        }
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

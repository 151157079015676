import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import { keyRotationDebug } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation'
import { keyRotationLocalStorageKeyName } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation-create-new-keys-user-wide'
import * as UACServices from '@lastpass/admin-console-dependencies/server/index'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  KeyRotationContinueDialog,
  KeyRotationProgressDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'

export const keyRotationProgressDialog: KeyRotationProgressDialog = {
  type: 'key-rotation-progress-dialog'
}
export const keyRotationContinueDialog: KeyRotationContinueDialog = {
  type: 'key-rotation-continue-dialog',
  title: msg`Start generating new knowledge components`,
  subtitle: msg`It looks like the previous process was interrupted. You can resume the process or start over. The outcome will be the same.`
}
export function* keyRotationCheckInterruptedSession(
  userService: UACServices.Services
) {
  let previousSessionInfo: {
    pageNumber?: number
    sessionToken?: string
  } = {}
  let pageNumber: number
  let sessionToken: string
  let continuePreviousSession = false

  try {
    const previousSessionInfoSerialized = localStorage.getItem(
      keyRotationLocalStorageKeyName
    )
    if (previousSessionInfoSerialized) {
      keyRotationDebug('Found local storage data')
      previousSessionInfo = JSON.parse(previousSessionInfoSerialized)
    }
  } catch {
    // do nothing
  }

  if (previousSessionInfo?.pageNumber && previousSessionInfo?.sessionToken) {
    yield put(globalActions.setDialog(keyRotationContinueDialog))
    const continueDialogAction = yield take([
      GlobalActionTypes.CONFIRM_DIALOG,
      GlobalActionTypes.DISCARD_DIALOG
    ])
    if (continueDialogAction.type != GlobalActionTypes.CONFIRM_DIALOG) {
      return
    }

    continuePreviousSession = continueDialogAction.payload.data.continue
  }

  yield put(globalActions.setDialog(keyRotationProgressDialog))

  if (
    continuePreviousSession &&
    previousSessionInfo.sessionToken &&
    previousSessionInfo.pageNumber
  ) {
    sessionToken = previousSessionInfo.sessionToken
    pageNumber = previousSessionInfo.pageNumber
    keyRotationDebug(
      `Continuing the previous session from page ${pageNumber} with sessionToken ${sessionToken}`
    )
  } else {
    const alpTokenResponse: { body: string } = yield call(
      userService.getKeyRotationToken
    )

    pageNumber = 0
    sessionToken = alpTokenResponse.body
    keyRotationDebug(
      `Starting a new session from page ${pageNumber} with sessionToken ${sessionToken}`
    )
  }

  return {
    sessionToken,
    pageNumber
  }
}

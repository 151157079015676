import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SimpleOperation } from '@lastpass/admin-console-dependencies/server/home/managed-companies/operations/perform-simple-operation'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { DialogTypes } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { ManagedCompany } from '@lastpass/admin-console-dependencies/types/managed-company'
import { NotificationProps } from '@lastpass/admin-console-dependencies/types/notification-type'

import { redirectToList } from './redirect-to-list'

interface SimpleOperationParams {
  managedCompaniesServices: UACServices.Services
  managedCompany: ManagedCompany
  operationType: SimpleOperation
  confirmDialog: DialogTypes
  successNotificationProps: NotificationProps
  failureNotificationProps: NotificationProps
}

export function* performSimpleOperation({
  managedCompaniesServices,
  managedCompany,
  operationType,
  confirmDialog,
  successNotificationProps,
  failureNotificationProps
}: SimpleOperationParams) {
  yield put(globalActions.setDialog(confirmDialog))
  const confirmClick = yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])

  if (confirmClick.type !== GlobalActionTypes.CONFIRM_DIALOG) {
    return
  }

  try {
    const result: UACServices.performSimpleManagedCompanyOperationAPI.Responses = yield call(
      managedCompaniesServices.performSimpleManagedCompanyOperation,
      operationType,
      managedCompany.id
    )

    if (
      result.type ===
      UACServices.performSimpleManagedCompanyOperationAPI.SUCCESS
    ) {
      yield put(globalActions.setNotification(successNotificationProps))
      yield call(redirectToList)
      return
    }
    yield put(globalActions.setNotification(failureNotificationProps))
  } catch (e) {
    yield put(globalActions.setNotification(genericFailedNotification))
  }
}

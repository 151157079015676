import { ManagedCompaniesActions, ManagedCompaniesActionTypes } from './actions'
import { ManagedCompaniesState } from './state'

export const initialState: ManagedCompaniesState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: []
  },
  aggregate: {
    consumedLicenses: null,
    totalLicensesAllocated: null,
    averageSecurityScore: null
  },
  isLoading: false,
  companyTemplateSettings: []
}

export function managedCompaniesReducer(
  state: ManagedCompaniesState | undefined = initialState,
  action: ManagedCompaniesActions
): ManagedCompaniesState {
  switch (action.type) {
    case ManagedCompaniesActionTypes.SET_LIST: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.results,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage
        }
      }
    }
    case ManagedCompaniesActionTypes.SET_COMPANY_TEMPLATES: {
      return {
        ...state,
        companyTemplateSettings: action.payload.companyTemplateSettings
      }
    }
    case ManagedCompaniesActionTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    }
    case ManagedCompaniesActionTypes.SET_AGGREGATE: {
      return {
        ...state,
        aggregate: {
          ...action.payload
        }
      }
    }
    case ManagedCompaniesActionTypes.SET_PSA_DETAILS: {
      const newResults = state.table.results.map(result => ({ ...result }))
      const managedCompany = newResults.find(
        ({ id }) => id === action.payload.managedCompanyId
      )
      if (managedCompany) {
        managedCompany.psa = action.payload.psaDetails
      }
      return {
        ...state,
        table: { ...state.table, results: newResults }
      }
    }
  }
  return state
}

import {
  EnabledMultifactorOptionsActions,
  EnabledMultifactorOptionsActionTypes
} from './actions'
import { EnabledMultifactorOptionsState } from './state'

export const initialState: EnabledMultifactorOptionsState = {
  options: [],
  loading: false
}

export const enabledMultifactorOptionsReducer = (
  previousState: EnabledMultifactorOptionsState | undefined,
  action: EnabledMultifactorOptionsActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_SET:
      return {
        ...state,
        options: [...action.payload.options]
      }
    case EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    default:
      return state
  }
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps } from 'formik'

import { BodySemibold } from '@lastpass/lastkit'

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 24px;
`
const StyledTextArea = styled.textarea`
  background: ${props => props.theme.colors.white};
  height: 80px;
  width: 537px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const StyledLabel = styled(BodySemibold)`
  color: ${props => props.theme.colors.neutral600};
`

export const PolicyNoteEditor: React.FunctionComponent = () => {
  return (
    <TextAreaContainer>
      <StyledLabel>
        <Trans>Notes</Trans>
      </StyledLabel>
      <Field name="policyNotes">
        {(formData: FieldProps) => {
          return (
            <StyledTextArea
              data-qa="policyNotes"
              name={formData.field.name}
              value={formData.field.value}
              onChange={e => {
                formData.field.onChange(e)
              }}
            />
          )
        }}
      </Field>
    </TextAreaContainer>
  )
}

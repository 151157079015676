import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { RSASecureIDRadius } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'

export const SUCCESS = 'success'

const success = (rsaSecureIDRadius: RSASecureIDRadius) =>
  createResponse(SUCCESS, { rsaSecureIDRadius })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await teamsapi(
        'my-company/showrsasecureidradius',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const rsaSecureIDRadiusResponse = JSON.parse(response)

        const rsaSecureIDRadius: RSASecureIDRadius = {
          ...rsaSecureIDRadiusResponse,
          radiusSharedSecret: '',
          logo124x124: '',
          logo190x41: ''
        }

        while (
          typeof rsaSecureIDRadius.radiusSharedSecret === 'string' &&
          rsaSecureIDRadius.radiusSharedSecret.length <
            rsaSecureIDRadius.radiusSharedSecretLength
        ) {
          rsaSecureIDRadius.radiusSharedSecret += '*'
        }
        return success(rsaSecureIDRadius)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'

import { BodyRegularStyle } from '@lastpass/lastkit/styles'

const StyledContainer = styled.div`
  ${BodyRegularStyle}
  margin: -30px 0 24px 0;
`

export const DrawerDescription: FunctionComponent = props => {
  return <StyledContainer>{props.children}</StyledContainer>
}

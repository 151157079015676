import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Autocomplete } from '@lastpass/lastkit/components/Autocomplete'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'

export const AdvancedUserActivityReportSelector: FunctionComponent = () => {
  const dispatch = useDispatch()
  const updateQuery = useUpdateQuery(Namespace.userReports)
  const { selectedSavedReport, savedReports } = useSelector<
    AppState,
    AdvancedUserActivityListState
  >(state => state.advancedUserActivityEvents)

  const reportMap = report => ({
    label: report.name,
    value: report.name,
    report
  })

  return (
    <Autocomplete
      width={'258px'}
      placeholder={i18n._(msg`Select a saved report`)}
      searchPlaceholder={msg`Search saved reports...`}
      noOptionsCustomMessage={i18n._(msg`No saved reports`)}
      closeMenuOnSelect
      value={selectedSavedReport ? reportMap(selectedSavedReport) : null}
      options={savedReports.map(reportMap)}
      onChange={selectedReport => {
        // @ts-ignore: this onChange comes from react-select and only uses "label" and "value" internally.
        const { report } = selectedReport
        updateQuery({ filter: report.filter, skip: 0 })
        dispatch(advancedUserActivityActions.setSelectedSavedReport(report))
      }}
    />
  )
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { Dispatch } from 'redux'

import { RecentManagedCompaniesRepository } from '@lastpass/admin-console-dependencies/repositories'
import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'
import {
  ManagedCompany,
  ManagedCompanyStatus
} from '@lastpass/admin-console-dependencies/types/managed-company'

export interface ManagedCompanyOperation {
  name: MessageDescriptor | string
  callback: () => void
  isApplicable: boolean
  isDestructive?: boolean
}

export enum ManagedCompanyOperationTypes {
  allocateLicenses = 'allocateLicenses',
  returnLicenses = 'returnLicenses',
  reactivate = 'reactivate',
  suspend = 'suspend',
  detach = 'detach'
}

export type ManagedCompanyOperationMap = {
  [key in ManagedCompanyOperationTypes]: ManagedCompanyOperation
}

export const getAllOperations = (
  managedCompany: ManagedCompany,
  dispatch: Dispatch,
  isBillingUsageBased: boolean
): ManagedCompanyOperationMap => ({
  [ManagedCompanyOperationTypes.allocateLicenses]: {
    name: msg`Allocate licenses`,
    callback: () => {
      dispatch(
        push(`/home/managed-companies/${managedCompany.id}/allocate-licenses`)
      )
    },
    isApplicable:
      managedCompany.status !== ManagedCompanyStatus.suspended &&
      !isBillingUsageBased
  },
  [ManagedCompanyOperationTypes.returnLicenses]: {
    name: msg`Return licenses`,
    callback: () => {
      dispatch(
        push(`/home/managed-companies/${managedCompany.id}/return-licenses`)
      )
    },
    isApplicable: managedCompany.licensesAvailable > 0 && !isBillingUsageBased
  },
  [ManagedCompanyOperationTypes.reactivate]: {
    name: msg`Re-activate company`,
    callback: () => {
      dispatch(managedCompanyOperationActions.reactivate(managedCompany))
    },
    isApplicable: managedCompany.status === ManagedCompanyStatus.suspended
  },
  [ManagedCompanyOperationTypes.suspend]: {
    name: msg`Suspend company`,
    callback: () => {
      dispatch(managedCompanyOperationActions.suspend(managedCompany))
    },
    isApplicable: managedCompany.status !== ManagedCompanyStatus.suspended,
    isDestructive: true
  },
  [ManagedCompanyOperationTypes.detach]: {
    name: msg`Detach company`,
    callback: () => {
      dispatch(managedCompanyOperationActions.detach(managedCompany))
      RecentManagedCompaniesRepository.removeOneItem(
        managedCompany.id.toString()
      )
    },
    isApplicable: true,
    isDestructive: true
  }
})

export const filterApplicableOperations = (
  operations: ManagedCompanyOperationMap,
  hiddenOperations: ManagedCompanyOperationTypes[] = []
): ManagedCompanyOperation[] =>
  Object.entries(operations).reduce(
    (
      acc: ManagedCompanyOperation[],
      [operationType, operation]: [string, ManagedCompanyOperation]
    ) => {
      if (
        operation.isApplicable &&
        (!hiddenOperations ||
          !hiddenOperations.includes(
            operationType as ManagedCompanyOperationTypes
          ))
      ) {
        acc.push(operation)
      }

      return acc
    },
    []
  )

import React, { useState } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Loading } from '@lastpass/components'

import { SplunkIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledFormRow,
  StyledTextInput
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { useSiemIntegrationsSetConfig } from '../api-hooks/use-siem-integrations-set-config'
import { SiemIntegrationsEnabledCheckbox } from '../shared/SiemIntegrationsEnabledCheckbox'
import { SiemIntegrationsSubmitButton } from '../shared/SiemIntegrationsSubmitButton'

interface SplunkFormProps {
  config: SplunkIntegrationConfig
}

export const SplunkForm: React.FunctionComponent<SplunkFormProps> = ({
  config
}) => {
  const { isLoading, setSplunkConfig } = useSiemIntegrationsSetConfig()

  const [
    currentConfig,
    setCurrentConfig
  ] = useState<SplunkIntegrationConfig | null>(null)

  const yupSchema = Yup.object().shape({
    instanceUrl: Yup.string()
      .trim()
      .matches(
        /^https:\/\/[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
        i18n._(msg`Invalid URL`)
      )
      .required(' '),
    token: Yup.string()
      .trim()
      .required(),
    index: Yup.string()
      .matches(/\S/)
      .notRequired()
  })

  return (
    <>
      {isLoading && (
        <Loading
          data-qa="SplunkFormLoadingIndicator"
          color="blue900"
          active={true}
        />
      )}
      <Formik
        initialValues={currentConfig ?? config}
        enableReinitialize
        validationSchema={yupSchema}
        onSubmit={values => {
          setSplunkConfig({
            config: values,
            successMessage: msg`Nice! You've set up the Splunk integration.`
          })
          setCurrentConfig(values)
        }}
      >
        {formik => (
          <Form data-qa="SplunkForm">
            <StyledFormRow>
              <StyledTextInput
                data-qa="SplunkInstanceURLInput"
                name="instanceUrl"
                placeholder={msg`https://website.com:0000`}
                value={formik.values.instanceUrl}
                onChange={formik.handleChange}
                error={!!formik.errors.instanceUrl}
                errorText={<>{formik.errors.instanceUrl}</>}
              >
                <Trans>Splunk instance URL</Trans>
              </StyledTextInput>

              <StyledTextInput
                data-qa="SplunkTokenInput"
                name="token"
                placeholder={msg`Token code`}
                value={formik.values.token}
                error={!!formik.errors.token}
                onChange={formik.handleChange}
              >
                <Trans>Token</Trans>
              </StyledTextInput>

              <StyledTextInput
                data-qa="SplunkIndexInput"
                name="index"
                placeholder={msg`Optional`}
                value={formik.values.index}
                onChange={formik.handleChange}
              >
                <Trans>Index name</Trans>
              </StyledTextInput>
            </StyledFormRow>

            <SiemIntegrationsEnabledCheckbox
              dataQa="SplunkEnabledCheckbox"
              formik={formik}
            />

            <SiemIntegrationsSubmitButton
              dataQa="SplunkSaveChangesButton"
              formik={formik}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

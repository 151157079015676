import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'

export const masterPasswordReuseFilter = {
  filter: {
    and: [
      { reusedMasterPassword: { eq: true } },
      {
        accountStatus: AccountStatus.active
      }
    ]
  }
}

export function createGetReusedMasterPasswordsSaga(
  dashboardService: UACServices.Services
) {
  return function* GetReusedMasterPasswordsSaga(
    action: ReturnType<typeof dashboardDrawerActions.getMasterPasswordReuse>
  ) {
    try {
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        ...masterPasswordReuseFilter,
        top: resultsPerPage
      })

      yield put(dashboardDrawerActions.setMasterPasswordReuseLoading(true))
      try {
        const result: UACServices.UsersAPI.Responses = yield call(
          dashboardService.users,
          query
        )
        if (result.type === UACServices.UsersAPI.SUCCESS) {
          const userList = result.body.userList
          const totalResults = result.body.totalResults
          yield put(
            dashboardDrawerActions.setTable(
              userList,
              totalResults,
              resultsPerPage,
              'usersReusingMasterPassword'
            )
          )
        }
      } finally {
        yield put(dashboardDrawerActions.setMasterPasswordReuseLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

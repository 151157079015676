import React, { FunctionComponent } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { DashboardDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/DasboardDrawer'
import { createMasterPasswordReuseDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/UsersReusedMasterPasswordDrawerPage'
import { createUsersWithWeakMasterPasswordDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/UsersWithWeakMasterPasswordDrawer'
import { createUsersWithWeakSecurityScoreDrawer } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/UsersWithWeakSecurityScoreDrawer'
import { SecurityDashboardPageContent } from '@lastpass/admin-console-dependencies/ui/home/security-dashboard/SecurityDashboardPageContent'

const UsersWithWeakMasterPasswordDrawer = createUsersWithWeakMasterPasswordDrawer<
  AppState
>()
const UsersWithWeakSecurityScoreDrawer = createUsersWithWeakSecurityScoreDrawer<
  AppState
>()
const MasterPasswordReuseDrawer = createMasterPasswordReuseDrawer<AppState>()

export const SecurityDashboardPage: FunctionComponent = () => {
  const match = useRouteMatch()

  if (!match) {
    return null
  }
  const { url, path } = match

  return (
    <>
      <SecurityDashboardPageContent />
      <AnimatedSwitch timeout={200}>
        <Route path={`${path}/weakmasterpasslist`}>
          <DashboardDrawer
            closeLink={url}
            title={<Trans>Weak Master Passwords</Trans>}
          >
            <UsersWithWeakMasterPasswordDrawer cancelLink={url} />
          </DashboardDrawer>
        </Route>
        <Route path={`${path}/reusedmasterpasswordlist`}>
          <DashboardDrawer
            closeLink={url}
            title={<Trans>Reused Master Passwords</Trans>}
          >
            <MasterPasswordReuseDrawer cancelLink={url} />
          </DashboardDrawer>
        </Route>
        <Route path={`${path}/weaksecurityscore`}>
          <DashboardDrawer
            closeLink={url}
            title={<Trans>Weak Security Score</Trans>}
          >
            <UsersWithWeakSecurityScoreDrawer cancelLink={url} />
          </DashboardDrawer>
        </Route>
      </AnimatedSwitch>
    </>
  )
}

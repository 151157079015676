import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { PsaEditCredentialsType } from '@lastpass/admin-console-dependencies/types/advanced-psa-integrations'

import { EnumDictionary } from '../EnumValues'

export const PsaEditCredentialsMapping: EnumDictionary<
  PsaEditCredentialsType,
  ReactElement
> = {
  [PsaEditCredentialsType.CompanyId]: <Trans>Company ID</Trans>,
  [PsaEditCredentialsType.ConnectwiseUrl]: <Trans>ConnectWise URL</Trans>,
  [PsaEditCredentialsType.PrivateKey]: <Trans>Private key</Trans>,
  [PsaEditCredentialsType.PublicKey]: <Trans>Public key</Trans>,
  [PsaEditCredentialsType.Secret]: <Trans>Secret</Trans>,
  [PsaEditCredentialsType.UserName]: <Trans>Username</Trans>
}

import React, { FC } from 'react'
import { ChartComponentProps, Doughnut } from 'react-chartjs-2'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'

const chartSize = 200

const Container = styled.div`
  position: relative;
  z-index: 1;
  width: ${chartSize}px;
  height: ${chartSize}px;
`
const PercentageInfoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: -1;
`
const Percentage = styled.span<{ color: string }>`
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.color};
`
const PercentageSubtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.gray400};
`

export interface DonutChartData {
  value: number
  backgroundColor: string
  label?: MessageDescriptor
}

export interface DonutChartPercentageInfo {
  value: number
  subtitle?: MessageDescriptor
}

export interface DonutChartProps {
  items: DonutChartData[]
  percentageInfo: DonutChartPercentageInfo
  dataQa?: string
}

const areLabelsEnabled = (items: DonutChartData[]): boolean =>
  items.every(item => item.label)

export const DonutChart: FC<DonutChartProps> = ({
  items,
  percentageInfo,
  dataQa
}) => {
  const { _ } = useLingui()

  const mainColor = items[0].backgroundColor

  const dataset = {
    data: items.map(item => item.value),
    backgroundColor: items.map(item => item.backgroundColor),
    hoverBackgroundColor: items.map(item => item.backgroundColor),
    borderWidth: 0,
    hoverBorderWidth: 0,
    borderColor: mainColor
  }

  const chartProps: ChartComponentProps = {
    data: {
      datasets: [dataset],
      labels: items.map(item => item.label && _(item.label))
    },
    options: {
      cutoutPercentage: 80,
      tooltips: {
        enabled: areLabelsEnabled(items)
      },
      maintainAspectRatio: false,
      responsive: false,
      title: {
        display: false
      }
    },
    height: chartSize,
    width: chartSize,
    legend: {
      display: false
    }
  }
  return (
    <Container data-qa={dataQa}>
      <Doughnut {...chartProps} />
      <PercentageInfoContainer>
        <Percentage data-qa={dataQa && dataQa + 'Percentage'} color={mainColor}>
          {percentageInfo.value + '%'}
        </Percentage>
        {percentageInfo.subtitle && (
          <PercentageSubtitle data-qa={dataQa && dataQa + 'PercentageSubtitle'}>
            {_(percentageInfo.subtitle)}
          </PercentageSubtitle>
        )}
      </PercentageInfoContainer>
    </Container>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as InfoIcon } from '@lastpass/assets/svg/admin-console/icon-information.svg'
import { BodyRegular, TopBanner } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import {
  CompanyDetails,
  CompanyInfoState
} from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import {
  isAggregatorMSPInManagedMSP,
  isDirectMSPOrAggregatorMSP,
  isManagedMSP,
  isMSPinManagedCompany,
  isSimpleAdmin,
  npSunsetDeadline
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'
import { isLastWarningToMigrateActive } from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'

const Container = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin: 5px;
`

const StyledInfoIcon = styled(InfoIcon)`
  height: 16px;
  width: 16px;
  margin: auto 5px;
`
const StyledLink = styled(LocationLink)`
  margin-left: 7px;
  font-size: 15px;
`

export const systemUpgradePath = '/systemupgrade'

export const NoPasswordMigrationBanner: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const currentPath = useLocation().pathname
  const islastWarningActive = isLastWarningToMigrateActive(
    new Date(Date.now()),
    npSunsetDeadline
  )

  const isNopasswordMigrationEnabled = useFeatureFlags(
    FeatureFlags.isNopasswordMigrationEnabled
  )

  const companyInfo = useSelector<AppState, CompanyInfoState>(
    appState => appState.companyInfo
  )

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const systemUpgradeState = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
    dispatch(systemUpgradeActions.fetchIsMigrationRequired())
    dispatch(systemUpgradeActions.fetchIsMigrated())
  }, [dispatch])

  if (
    !isNopasswordMigrationEnabled ||
    currentPath.startsWith(systemUpgradePath) ||
    !(
      systemUpgradeState.isMigrationRequired && !systemUpgradeState.isMigrated
    ) ||
    !(
      isSimpleAdmin(companyInfo) ||
      isDirectMSPOrAggregatorMSP(companyInfo) ||
      isMSPinManagedCompany(companyInfo) ||
      isManagedMSP(companyInfo) ||
      isAggregatorMSPInManagedMSP(companyInfo)
    )
  ) {
    return null
  }

  const Text = styled(BodyRegular)`
    vertical-align: bottom;
    color: ${islastWarningActive
      ? props => props.theme.colors.white
      : props => props.theme.colors.neutral900};
  `
  const UnderlinedText = styled(Text)`
    text-decoration-line: underline;
  `

  return (
    <TopBanner backgroundColor={islastWarningActive ? 'red700' : 'yellow700'}>
      <Container data-qa="NoPasswordMigrationBanner">
        <StyledInfoIcon
          data-qa="NoPasswordMigrationBannerIcon"
          fill={islastWarningActive ? 'white' : 'neutral900'}
        />

        <Text data-qa="NoPasswordMigrationBannerMessage">
          {CompanyDetailsHelper.isMsp(companyDetails) ? (
            <span data-qa="NoPasswordMigrationBannerMessageMSP">
              <Trans>
                System upgrade – See how you and your managed companies are
                impacted
              </Trans>
            </span>
          ) : (
            <span data-qa="NoPasswordMigrationBannerMessageAdmin">
              <Trans>System upgrade – See how you&apos;re impacted</Trans>
            </span>
          )}
        </Text>

        <StyledLink to={systemUpgradePath} data-qa="SystemUpgradeLink">
          <UnderlinedText data-qa="NoPasswordMigrationBannerLinkMessage">
            <Trans>Show me</Trans>
          </UnderlinedText>
        </StyledLink>
      </Container>
    </TopBanner>
  )
}

import React, { FunctionComponent, ReactElement } from 'react'

import styled from '@emotion/styled'

import { BodyRegularStyle, CaptionRegularStyle } from '../styles'

export interface DetailCellProps {
  selected?: boolean
  field: ReactElement | string
  value: string | ReactElement | undefined
  qa?: string | undefined
  className?: string
}

const DetailField = styled.label`
  ${CaptionRegularStyle}
  color: ${props => props.theme.colors.neutral700};
`
const DetailValue = styled.div<{ selected?: boolean }>`
  ${BodyRegularStyle}
  color: ${props =>
    props.selected ? props.theme.colors.blue : props.theme.colors.neutral900};
  overflow-wrap: break-word;
`
export const DetailsContainer = styled.div`
  max-width: inherit;
  padding-bottom: 5px;
  column-count: 2;
`
const DetailCellContainer = styled.div`
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  padding-left: 0;
`

export const DetailCell: FunctionComponent<DetailCellProps> = props => {
  return (
    <DetailCellContainer className={props.className}>
      <DetailField>{props.field}</DetailField>
      <DetailValue
        selected={props.selected}
        data-qa={props.qa ? props.qa : 'DetailValue'}
      >
        {props.value ? props.value : '-'}
      </DetailValue>
    </DetailCellContainer>
  )
}

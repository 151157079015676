import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface ScrollProps {
  elementRef: React.RefObject<HTMLDivElement>
}

export const ScrollToTop: React.FunctionComponent<ScrollProps> = props => {
  const { pathname } = useLocation()
  const { elementRef } = props
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }, [pathname, elementRef])

  return null
}

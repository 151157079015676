export enum UserActivityTypes {
  all = 'all',
  masterPasswordChanged = 'masterpasswordchanged',
  iterationsChanged = 'iterationschange',
  historyCleared = 'historycleared',
  usernameChanged = 'usernamechanged',
  masterPasswordReverted = 'revertedmasterpassword',
  passwordChanged = 'passwordchanged',
  formFilled = 'formfill',
  dataExport = 'export',
  dataImport = 'import',
  moveToSharedFolder = 'movetosharedfolder',
  moveFromSharedFolder = 'movefromsharedfolder',
  dataPrinted = 'print',
  disabledYubiKey = 'disabledyubikey',
  disabledGoogleAuthenticator = 'disabledgoogleauth',
  disabledMicrosoftAuthenticator = 'disabledmicrosoftauth',
  disabledMultifactorAuthentication = 'disabledmultifactor',
  disabledGrid = 'disabledgrid',
  failedLoginAttempt = 'failedlogin',
  SAMLLogin = 'saml',
  createdLastPassAccount = 'createLPaccount',
  multifactorEnabled = 'multifactorenable',
  loginVerificationEmailSent = 'loginverification',
  disabledToopher = 'disabledtoopher',
  deletedSites = 'deletedsites:',
  login = 'Login',
  siteAdded = 'siteadded',
  siteDeleted = 'sitedeleted',
  openSecureNote = 'opensecurenote',
  editSecureNote = 'editsecurenote',
  createdSharedFolder = 'createdsharedfolder',
  renamedSharedFolder = 'renamedsharedfolder',
  deletedSharedFolder = 'deletedsharedfolder',
  restoredSharedFolder = 'undeletedsharedfolder',
  permanentlyDeletedSharedFolder = 'purgedsharedfolder',
  addToSharedFolder = 'addtosharedfolder',
  lastpasslogin = 'lastPassLogin',
  encryptionKeyRotated = 'encryptionKeyRotated'
}

export enum AdminActivityTypes {
  all = 'all',
  loginToAdminConsole = 'admin_console_login',
  authenticationFailed = 'authenticatefailed',
  authenticationSuccessful = 'authenticatesuccess',
  setUserName = 'setusername',
  enableDuo = 'enableduo',
  enableSecurID = 'enablesecurid',
  resetMasterPassword = 'resetpassword',
  addingUserToSharedFolder = 'addingusertosharedfolder',
  pushingSitesToUser = 'pushingsitestouser',
  removeSiteFromUser = 'removesitefromuser',
  reporting = 'reporting',
  changePasswordSuccess = 'changepasswordsuccess',
  changePasswordFailed = 'changepasswordfailed',
  removingUserFromGroup = 'removinguserfromgroup',
  removingGroup = 'removinggroup',
  sharingKeyRemoved = 'sharingkeyremoved',
  sharingKeyUpdated = 'sharingkeyupdated',
  sharingKeyReplaced = 'sharingkeyreplaced',
  recoveryKeysShared = 'recoverykeysshared',
  deletePolicy = 'deletepolicy',
  updatePolicyUsers = 'updatepolicyusers',
  addPolicy = 'addpolicy',
  editPolicy = 'editpolicy',
  removeGlobalNeverURLs = 'removeglobalneverurls',
  addGlobalNeverURLs = 'addglobalneverurls',
  removeGlobalOnlyURLs = 'removeglobalonlyurls',
  addGlobalOnlyURLs = 'addglobalonlyurls',
  removeGlobalEquivDomain = 'removeglobalequivdomain',
  addGlobalEquivDomain = 'addglobalequivdomain',
  addToSharedFolder = 'addtosharedfolder',
  removedFromSharedFolder = 'removedfromsharedfolder',
  updateFolderPermissions = 'updatefolderpermissions',
  masterPasswordResetBySuperAdmin = 'superadminpasswordreset',
  reactivatingUser = 'reactivatinguser',
  createdPendingUser = 'createdpendinguser',
  creatingUser = 'creatinguser',
  groupRemoval = 'groupremoval',
  groupAddition = 'groupaddition',
  deactivatingUser = 'deactivatinguser',
  removingUserFromLastPassBusiness = 'removinguserfromenterprise',
  deletingUser = 'deletinguser',
  addingUserToGroup = 'addingusertogroup',
  changeGroupPending = 'changegrouppending',
  getSharedFolderData = 'getsharedfolderdata',
  removeFromGroup = 'removefromgroup',
  deleteGroup = 'deletegroup',
  createGroup = 'creategroup',
  addToGroup = 'addtogroup',
  editGroup = 'editgroup',
  renameGroup = 'renamegroup',
  masterPasswordReuse = 'masterpasswordreuse',
  disabled = 'disabled',
  reinviteUser = 'reinviteuser',
  removeUserFromCompany = 'removeuserfromcompany',
  makeAdmin = 'makeadmin',
  removeAdmin = 'removeadmin',
  deactivatedUser = 'deactivateduser',
  reactivatedUser = 'reactivateduser',
  employeeAccountRequiresActivation = 'employeeaccountrequiresactivation',
  employeeInvited = 'employeeinvited',
  employeeAccountCreated = 'employeeaccountcreated',
  employeeAccountDeleted = 'employeeaccountdeleted',
  employeeAccountDisabled = 'employeeaccountdisabled',
  requirePasswordChange = 'requirepasswordchange',
  destroyAllSessions = 'destroyallsessions',
  stopRequiringPasswordChange = 'unrequirepasswordchange',
  viewAllMatchingInvitations = 'viewinviteallmatching',
  inviteAllMatching = 'inviteallmatching',
  updatedSAMLMap = 'updatedsamlmap',
  disabledSAMLMapEntry = 'disabledsamlmapentry',
  enabledSAMLMapEntry = 'enabledsamlmapentry',
  deletedSAMLMapEntry = 'deletedsamlmapentry',
  addedUserToSAMLMap = 'addedusertosamlmap',
  createRole = 'createrole',
  editRole = 'editrole',
  deleteRole = 'deleterole',
  userProvisioned = 'userprovisioned',
  userDeprovisioned = 'userdeprovisioned',
  provisioningServiceAdded = 'provisioningserviceadded',
  provisioningServiceRemoved = 'provisioningserviceremoved',
  provisioningSyncHappened = 'provisioningsynchappened',
  provisioningCredentialChanged = 'provisioningcredentialchanged',
  resetMFASharedSecrets = 'resetsharedtotpsecrets',
  resetMFASharedSecretsSuccessful = 'resetsharedtotpsecretsforusersuccess',
  resetMFASharedSecretsFailed = 'resetsharedtotpsecretsforuserfailed'
}

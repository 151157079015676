import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import {
  BodySemibold,
  Checkbox,
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  ReorderTable,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { UserTableRepository } from '@lastpass/admin-console-dependencies/repositories'
import {
  CompanyInfoState,
  SubscriptionType
} from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { UsersListState } from '@lastpass/admin-console-dependencies/state/users/view/list/state'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { UserColumnMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/column-mappings/UserColumnMapping'

type CustomizeUserListProps = LastPassComponentProps<
  {
    usersList: UsersListState
    companySubscription: SubscriptionType
    isFederatedLoginEnabled: boolean
  },
  typeof userListActions,
  {}
>

interface ColumnItem {
  name: UserListTableColumn
  checked: boolean
  draggable: boolean
}

export const CustomizeUserListDrawerPage: React.FunctionComponent<CustomizeUserListProps> = ({
  state,
  actions
}) => {
  const [orderedList, setOrderedList] = useState<ColumnItem[]>([])

  const [selectableColumns, setSelectableColumns] = useState<ColumnItem[]>(
    orderedList
  )

  const isFederatedLoginStatusesEnabled = useFeatureFlags(
    FeatureFlags.isFederatedLoginStatusesEnabled
  )
  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )
  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )
  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()

  const isCreatedByCompanyColumnEnabled = useFeatureFlags(
    FeatureFlags.isCreatedByCompanyColumnEnabled
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())

    const isPasswordlessEnabledOrUsed =
      !isNpPasswordlessFeatureDisabled || !isPasswordlessPoliciesEmpty

    const isVisible = (column: UserListTableColumn): boolean => {
      if (column === UserListTableColumn.mfaStatus) {
        return (
          state.companySubscription !== SubscriptionType.enterprise &&
          isPasswordlessEnabledOrUsed &&
          !isNoPasswordDeprecated
        )
      }

      if (column === UserListTableColumn.provisionFederatedStatus) {
        return isFederatedLoginStatusesEnabled
      }

      if (column === UserListTableColumn.isCreatedByCompany) {
        return isCreatedByCompanyColumnEnabled
      }

      return true
    }

    const isDisabled = (column: UserListTableColumn): boolean => {
      return (
        column !== UserListTableColumn.email &&
        (column !== UserListTableColumn.provisionFederatedStatus ||
          state.isFederatedLoginEnabled)
      )
    }

    const isChecked = (column: UserListTableColumn): boolean => {
      switch (column) {
        case UserListTableColumn.provisionFederatedStatus:
          return state.isFederatedLoginEnabled
        case UserListTableColumn.defaultMultifactor:
          return (
            !state.isFederatedLoginEnabled ||
            state.usersList.table.columns !==
              state.usersList.defaultUserListColumns
          )
        default:
          return true
      }
    }

    const visibleColumns: ColumnItem[] = state.usersList.table.columns
      .filter(isVisible)
      .map(column => {
        return {
          name: column,
          checked: isChecked(column),
          draggable: isDisabled(column)
        }
      })
    const hiddenColumns: ColumnItem[] = state.usersList.allUserListColumns
      .filter(column => !state.usersList.table.columns.includes(column))
      .filter(isVisible)
      .map(column => {
        return {
          name: column,
          checked: false,
          draggable: isDisabled(column)
        }
      })

    setSelectableColumns([...visibleColumns, ...hiddenColumns])
  }, [
    dispatch,
    isFederatedLoginStatusesEnabled,
    isNpPasswordlessFeatureDisabled,
    isPasswordlessPoliciesEmpty,
    isNoPasswordDeprecated,
    state.companySubscription,
    state.isFederatedLoginEnabled,
    state.usersList.allUserListColumns,
    state.usersList.defaultUserListColumns,
    state.usersList.table.columns,
    isCreatedByCompanyColumnEnabled
  ])

  useEffect(() => {
    orderedList.length && setSelectableColumns(orderedList)
  }, [orderedList])

  const handleCheckboxClick = (event, column: UserListTableColumn) => {
    const value = event.currentTarget.checked
    const columns = selectableColumns.map(currentColumn => {
      if (currentColumn.name === column) {
        return { ...currentColumn, checked: value }
      }
      return currentColumn
    })
    setSelectableColumns(columns)
  }

  return (
    <>
      <BodySemibold
        css={css`
          margin-bottom: 16px;
        `}
      >
        <Trans>Choose which details you want to show on the Users page.</Trans>
      </BodySemibold>
      <ReorderTable
        table={{ results: selectableColumns }}
        columns={[
          {
            name: <Trans>Column name</Trans>,
            renderer: column => UserColumnMapping[column.name].name,
            cellWidth: '70%'
          },
          {
            name: <Trans>Visible</Trans>,
            renderer: function columnVisiblerenderer(column) {
              return (
                <Checkbox
                  disabled={!column.draggable}
                  checked={column.checked}
                  onChange={e => handleCheckboxClick(e, column.name)}
                />
              )
            },
            cellWidth: '30%'
          }
        ]}
        setNewList={setOrderedList}
      />

      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          <LocationLink to={`/users/view`}>
            <TextButton
              css={css`
                margin-right: 10px;
              `}
              data-qa={'DiscardChangesButton'}
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>

          <LocationLink to={`/users/view`}>
            <TextButton
              css={css`
                margin-right: 10px;
              `}
              data-qa={'DiscardChangesButton'}
              onClick={() => {
                actions.resetUserListColumns()
                UserTableRepository.remove()
              }}
            >
              <Trans>Restore defaults</Trans>
            </TextButton>
          </LocationLink>

          <LocationLink to={`/users/view`}>
            <PrimaryButton
              onClick={() => {
                actions.setUserListColumns(
                  selectableColumns
                    .filter(column => column.checked)
                    .map(column => column.name),
                  true
                )
              }}
              type="submit"
            >
              <Trans>Apply filters</Trans>
            </PrimaryButton>
          </LocationLink>
        </DrawerButtonsContainer>
      </FooterContainer>
    </>
  )
}

export function createCustomizeUserListDrawerPage<
  TState extends { usersList: UsersListState; companyInfo: CompanyInfoState }
>() {
  return connectRedux(
    CustomizeUserListDrawerPage,
    (state: TState) => {
      return {
        usersList: state.usersList,
        companySubscription: state.companyInfo.details.subscription,
        isFederatedLoginEnabled:
          state.companyInfo.details.isFederatedLoginEnabled
      }
    },
    userListActions
  )
}

export type CustomizeUserListDrawerPage = ReturnType<
  typeof createCustomizeUserListDrawerPage
>

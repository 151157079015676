import { takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { WorkstationListActionTypes } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/actions'

import { createWorkstationList } from './get-workstation-list'

export function createWorkstationsSaga(
  workstationServices: UACServices.Services
) {
  const getWorkstationList = createWorkstationList(workstationServices)

  return function*() {
    yield takeLatest(
      WorkstationListActionTypes.GET_WORKSTATION_LIST,
      getWorkstationList
    )
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UsersIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  DrawerTable,
  FooterContainer,
  Pagination,
  PrimaryButton,
  RowContainer,
  SearchInput,
  StyledSpacer,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { adminLevelDrawerActions } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminLevelDrawerState } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/state'
import {
  getFullName,
  User
} from '@lastpass/admin-console-dependencies/types/user'

export const AdminLevelDrawerUsersTable: React.FunctionComponent = () => {
  const updateQuery = useUpdateQuery(Namespace.adminUsers)
  const queryParams = useQueryParams(Namespace.adminUsers)
  const pathParams = usePathParams()

  const { users, adminLevel }: AdminLevelDrawerState = useSelector(
    (state: AppState) => state.adminLevelDrawer
  )

  const dispatch = useDispatch()

  const actions = {
    getAdminLevelUsers: (queryParams, pathParams) =>
      dispatch(
        adminLevelDrawerActions.getAdminLevelUsers({
          query: queryParams,
          path: pathParams
        })
      ),
    addSelectedAdminLevelUsers: users =>
      dispatch(adminLevelDrawerActions.addSelectedAdminLevelUsers(users)),
    removeSelectedAdminLevelUsers: users =>
      dispatch(adminLevelDrawerActions.removeSelectedAdminLevelUsers(users)),
    setSelectedAdminLevelUsers: users =>
      dispatch(adminLevelDrawerActions.setSelectedAdminLevelUsers(users)),
    removeUsersFromAdminLevel: (checkedRecords, adminLevel) =>
      dispatch(
        adminLevelDrawerActions.removeUsersFromAdminLevel(
          checkedRecords,
          adminLevel
        )
      )
  }

  useEffect(() => {
    actions.getAdminLevelUsers(queryParams, pathParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Users</Trans>)

  return (
    <>
      <RowContainer>
        <SearchInput
          flexGrow
          value={queryParams.search}
          placeholder={msg`Search users`}
          onChange={e =>
            updateQuery({
              search: e.target.value || undefined,
              skip: undefined
            })
          }
          data-qa="AdminRoleAssignUsersSearchInputField"
        />
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={users.totalResults}
          resultsPerPage={users.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </RowContainer>
      <DrawerTable
        qadata="AdminLevelFullUsersList"
        table={users}
        noRecordsView={
          <TableView
            icon={<UsersIcon />}
            title={<Trans>No users assigned to this admin role yet</Trans>}
            text={
              <Trans>
                Manage admin users by assigning them to admin levels
              </Trans>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UsersIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        checkable={{
          addSelectedRecords: users =>
            actions.addSelectedAdminLevelUsers(users),
          removeSelectedRecords: users =>
            actions.removeSelectedAdminLevelUsers(users),
          setSelectedRecords: users => actions.setSelectedAdminLevelUsers(users)
        }}
        columns={[
          {
            name: <Trans>Name</Trans>,
            renderer: (record: User) => getFullName(record)
          },
          {
            name: <Trans>Email</Trans>,
            renderer: (record: User) => record.email
          }
        ]}
        loadingView={<Loading color="blue900" active={true} />}
        actions={[]}
        isFilterApplied={!!queryParams.search}
      />
      {!!users.checkedRecords.length && (
        <>
          <StyledSpacer />
          <FooterContainer>
            <DrawerButtonsContainer>
              <PrimaryButton
                onClick={() => {
                  actions.removeUsersFromAdminLevel(
                    users.checkedRecords,
                    adminLevel
                  )
                }}
                data-qa="AdminRolesUnassignUserButton"
              >
                {(users.checkedRecords.length == 1 && (
                  <Trans>Remove User</Trans>
                )) || <Trans>Remove Users</Trans>}
              </PrimaryButton>
            </DrawerButtonsContainer>
          </FooterContainer>
        </>
      )}
    </>
  )
}

import { track, uacEvent, UACTracking } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { Group } from '@lastpass/admin-console-dependencies/types/group'

export enum GroupOperationActionTypes {
  EDIT_GROUP_NAME = '[group-operation] edit-group-name',
  EXPORT_GROUP_DATA = '[group-operation] export-group-data',
  DELETE_GROUPS = '[group-operation] delete-groups',

  REQUIRE_MP_CHANGE = '[group-user-operations] require-mp-change',
  FORCE_VAULT_RE_ENCRYPTION = '[group-user-operations] force-vault-re-encryption',
  RESEND_MFA_INVITATION = '[group-user-operations] resend-invitation',
  DESTROY_SESSIONS = '[group-user-operations] destroy-sessions',
  DISABLE_USERS = '[group-user-operations] disable-users',
  REMOVE_FROM_COMPANY = '[group-user-operations] remove-from-company',
  DELETE_USERS = '[group-user-operations] delete-users',
  DISABLE_MFA = '[group-user-operations] disable-mfa',
  INVITE_USERS = '[group-user-operations] invite-users'
}

const createGroupDrawerTrackingSegment = (
  eventName: keyof UACTracking.Plan
) => ({
  segment: uacEvent(eventName, {
    Source: 'Group Detail'
  })
})

export const groupOperationActions = {
  exportGroupData: (groupId: string) =>
    createAction(GroupOperationActionTypes.EXPORT_GROUP_DATA, groupId),
  deleteGroups: (groups: Group[], source?: boolean) =>
    track(createAction(GroupOperationActionTypes.DELETE_GROUPS, groups), {
      segment: uacEvent('Delete Group Clicked', {
        Source: source ? 'Groups Overview' : 'Group Details',
        'User Count': groups
          .map(group => group.userCount)
          .reduce((acc, curr) => acc + curr)
      })
    }),
  requireMpChange: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.REQUIRE_MP_CHANGE, groupId),
      createGroupDrawerTrackingSegment('Require Master Password Change Clicked')
    ),
  vaultReEncryption: (groupId: string) =>
    track(
      createAction(
        GroupOperationActionTypes.FORCE_VAULT_RE_ENCRYPTION,
        groupId
      ),
      createGroupDrawerTrackingSegment('Force Vault Re-encryption Clicked')
    ),
  resendMfaInvitations: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.RESEND_MFA_INVITATION, groupId),
      createGroupDrawerTrackingSegment('Resend LastPass MFA Invitation Clicked')
    ),
  destroySessions: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.DESTROY_SESSIONS, groupId),
      createGroupDrawerTrackingSegment('Destroy All Sessions Clicked')
    ),
  disableUsers: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.DISABLE_USERS, groupId),
      createGroupDrawerTrackingSegment('Disable User Clicked')
    ),
  removeFromCompany: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.REMOVE_FROM_COMPANY, groupId),
      createGroupDrawerTrackingSegment('Remove User from Company Clicked')
    ),
  deleteUsers: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.DELETE_USERS, groupId),
      createGroupDrawerTrackingSegment('Delete User Clicked')
    ),
  disableMfa: (groupId: string) =>
    track(
      createAction(GroupOperationActionTypes.DISABLE_MFA, groupId),
      createGroupDrawerTrackingSegment('Disable Multifactor Clicked')
    ),
  inviteUsers: (groupId: string) =>
    createAction(GroupOperationActionTypes.INVITE_USERS, groupId)
}

export type GroupOperationActions = ActionsUnion<typeof groupOperationActions>

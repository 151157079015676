import { StatusCodes } from 'http-status-codes'

import { UpdateUserParam } from '../../../types/federation-api'
import { FederationErrors } from '../../federation-error-codes'
import { FederationApiError } from '../federation-api-error'
import { FederationFetch } from '../federation-api-factory'

export interface AzureADExtensionData {
  LastPassK1: string
  syncSession?: string
  syncId?: number
}

export type AzureAdUpdateUserK1Response = null

export const updateUserK1 = (
  federationFetch: FederationFetch<AzureAdUpdateUserK1Response>
) => async ({
  userData,
  additionalIdpProps = {}
}: UpdateUserParam): Promise<boolean> => {
  const extensionData: AzureADExtensionData = {
    LastPassK1: userData.k1.toString('base64')
  }
  let httpStatusCode: number

  try {
    const response = await federationFetch(
      `v1.0/users/${userData.userId}/extensions/com.lastpass.keys`,
      'PATCH',
      { ...additionalIdpProps, ...extensionData }
    )

    if (response.status === StatusCodes.NO_CONTENT) {
      return true
    }
    httpStatusCode = response.status
  } catch {
    throw new FederationApiError({
      federationErrorCode: FederationErrors.IdpUpdateUserConnectionFailed
    })
  }
  if (httpStatusCode === StatusCodes.FORBIDDEN) {
    throw new FederationApiError({
      federationErrorCode: FederationErrors.IdpUpdateUserAccessDenied,
      httpErrorCode: StatusCodes.FORBIDDEN
    })
  }
  throw new FederationApiError({
    federationErrorCode: FederationErrors.IdpUpdateUserFailed,
    httpErrorCode: httpStatusCode
  })
}

import React, { FunctionComponent } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'

interface SelectMfaStatusProps {
  updateSelection: (update: MFAStatus[]) => void
  filters?: MFAStatus[]
}

interface MfaStatusSelectOption {
  value: MFAStatus
  label: string
}

export const SelectMfaStatus: FunctionComponent<SelectMfaStatusProps> = props => {
  const accountStatus: MfaStatusSelectOption[] = [
    { value: MFAStatus.active, label: i18n._(msg`Active`) },
    { value: MFAStatus.invited, label: i18n._(msg`Invited`) },
    {
      value: MFAStatus.locked,
      label: i18n._(msg`Locked`)
    },
    {
      value: MFAStatus.notInvited,
      label: i18n._(msg`Not invited`)
    },
    { value: MFAStatus.notRequired, label: i18n._(msg`Not required`) }
  ]
  return (
    <Select
      dataQa={'MfaStatusDropdown'}
      options={accountStatus}
      value={accountStatus.filter(
        status => props.filters && props.filters.includes(status.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={i18n._(msg`Passwordless status`)}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateSelection(selectedItems.map(status => status.value))
        } else {
          props.updateSelection([])
        }
      }}
      fluid
    >
      <Trans>Passwordless status</Trans>
    </Select>
  )
}

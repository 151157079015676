import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { HeaderContainer, Heading300 } from '@lastpass/lastkit'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { PsaIntegrations } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/PsaIntegrations'

const PageHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
`
const RowContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
const StyledLocationLink = styled.a`
  padding-left: 0.25em;
`

export const PsaIntegrationsPage: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { psaInProgress } = useSelector(
    (state: AppState) => state.psaIntegrations
  )

  useEffect(() => {
    dispatch(psaIntegrationsActions.getPsaCredentials())
    dispatch(psaIntegrationsActions.getPsaProviderDetails())
  }, [dispatch])

  return psaInProgress ? (
    <Loading color="blue900" active={true} />
  ) : (
    <>
      <PageHeaderContainer data-qa="PageHeaderContainer">
        <Heading300 data-qa="PsaIntegrationsPageHeader">
          <Trans>PSA integrations</Trans>
        </Heading300>
      </PageHeaderContainer>
      <RowContainer>
        <Trans>
          Import managed companies, users, and billing information from
          professional services automation (PSA) suites.
        </Trans>
        <StyledLocationLink
          href="https://support.lastpass.com/help/what-is-a-professional-services-integration-in-lastpass"
          data-qa="LearnMoreAboutPSALink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>Learn more</Trans>
        </StyledLocationLink>
      </RowContainer>

      <PsaIntegrations />
    </>
  )
}

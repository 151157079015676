import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyRegular,
  Heading300,
  Table as TableComponent,
  TextButton
} from '@lastpass/lastkit'
import { PrimaryButton } from '@lastpass/lastkit/components/PrimaryButton'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { onlyExportRiskTypes } from '@lastpass/admin-console-dependencies/resources/security-reports/only-export-risk-types'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportActions } from '@lastpass/admin-console-dependencies/state/reports/security/actions'
import { SecurityReportListState } from '@lastpass/admin-console-dependencies/state/reports/security/state'
import { SecurityReportEvent } from '@lastpass/admin-console-dependencies/types/security-report'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const RightContainer = styled.div`
  ${BodyRegularStyle}
  justify-content: flex-end;
  color: ${props => props.theme.colors.neutral700};
`

const TopBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 32px;
  margin: -32px 0 32px 0;
  background-color: ${props => props.theme.colors.yellow600};
`
const StyledTextButton = styled(TextButton)`
  text-decoration: underline;
  padding: 0;
`

const columns: TableColumn<SecurityReportEvent>[] = [
  {
    name: <Trans>Report</Trans>,
    renderer: function SecurityReportRiskNameRenderer({ riskName }) {
      return <>{i18n._(riskName)}</>
    },
    noTooltip: true
  },
  {
    name: <Trans>Impacted users</Trans>,
    renderer: function SecurityReportUsersAffectedRenderer({
      numberOfUsersAffected
    }) {
      return <>{numberOfUsersAffected}</>
    },
    noTooltip: true
  },
  {
    name: <Trans>% of users impacted</Trans>,
    renderer: function SecurityReportPercentageOfUsersAffectedRenderer({
      percentageOfUsersAffected
    }) {
      return percentageOfUsersAffected ? (
        <>{percentageOfUsersAffected} %</>
      ) : (
        <></>
      )
    },
    noTooltip: true
  }
]

export const SecurityReportList: React.FunctionComponent = () => {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { table, lastUpdated, isUpdateRequested } = useSelector<
    AppState,
    SecurityReportListState
  >(state => state.securityReportEvents)
  const { requestSecurityReportUpdate } = useContext(ServerContext)

  const isWeakSecurityScoreEnabled = useFeatureFlags(
    FeatureFlags.isWeakSecurityScoreEnabled
  )
  const isDWMSecurityReportEnabled = useFeatureFlags(
    FeatureFlags.isDWMSecurityReportEnabled
  )
  const isUrlDecryptionEnabled = useFeatureFlags(
    FeatureFlags.isUrlDecryptionEnabled
  )
  const isSharedFolderUrlDecryptionEnabled = useFeatureFlags(
    FeatureFlags.isSharedFolderUrlDecryptionEnabled
  )

  const dispatchGetSecurityReportEvents = (): void => {
    dispatch(
      securityReportActions.getSecurityReportEvents({
        isWeakSecurityScoreEnabled,
        isDWMSecurityReportEnabled,
        isUrlDecryptionEnabled,
        isSharedFolderUrlDecryptionEnabled
      })
    )
  }

  const handleUpdateRequest = async (): Promise<void> => {
    try {
      dispatch(securityReportActions.setIsUpdateRequested(true))
      await requestSecurityReportUpdate()
    } catch (e) {
      dispatch(securityReportActions.setIsUpdateRequested(false))
      dispatch(globalActions.setNotification(genericFailedNotification))
    }
  }

  useEffect(() => {
    dispatchGetSecurityReportEvents()
    dispatch(securityReportActions.trackSecurityReportsOverviewViewed())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isUpdateRequested && (
        <TopBanner data-qa="SecurityReportPageUpdateRequestedTopBanner">
          <Trans>
            <BodyRegular>
              Update in progress.{' '}
              <StyledTextButton blue onClick={dispatchGetSecurityReportEvents}>
                Try to refresh now
              </StyledTextButton>{' '}
              or work elsewhere and come back in a few minutes.
            </BodyRegular>
          </Trans>
        </TopBanner>
      )}
      <HeaderContainer>
        <Heading300 data-qa="SecurityReportPageHeader">
          <Trans>Security reports</Trans>
        </Heading300>
        <RightContainer>
          {lastUpdated && (
            <span data-qa="SecurityReportPageLastUpdated">
              <Trans>
                Last updated on{' '}
                {moment(lastUpdated).format('D MMMM YYYY, h:mm A')}
              </Trans>
            </span>
          )}
          <StyledPrimaryButton
            data-qa="SecurityReportPageRequestUpdateButton"
            key="request update"
            onClick={handleUpdateRequest}
            disabled={isUpdateRequested}
          >
            {isUpdateRequested ? (
              <Trans>Update requested</Trans>
            ) : (
              <Trans>Request update</Trans>
            )}
          </StyledPrimaryButton>
        </RightContainer>
      </HeaderContainer>
      <TableComponent
        table={table}
        getRecordLink={({ id, numberOfUsersAffected }: SecurityReportEvent) =>
          (numberOfUsersAffected || onlyExportRiskTypes.includes(id)) && match
            ? `${match.url}/${id}`
            : ''
        }
        loadingView={<Loading color="blue900" active />}
        columns={columns}
      />
    </>
  )
}

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { GroupFilter } from '@lastpass/admin-console-dependencies/types/group-filter'

export enum GroupListSegmentActionTypes {
  ADD_GROUP_BUTTON_CLICKED = '[group-list-segment] add-group-button-clicked'
}

export const groupListSegmentActions = {
  addGroupButtonClicked: () =>
    track(
      createAction(GroupListSegmentActionTypes.ADD_GROUP_BUTTON_CLICKED, {}),
      {
        segment: uacEvent('Add Group Button Clicked')
      }
    )
}

export enum GroupListActionTypes {
  SET_GROUPS_LIST = '[group-list] set',
  GET_GROUPS_LIST = '[group-list] get',
  ADD_GROUPS = '[group-list] add-groups',
  ADD_SELECTED_GROUPS = '[group-list] add-selected-groups',
  REMOVE_SELECTED_GROUPS = '[group-list] remove-selected-groups',
  SET_SELECTED_GROUPS = '[group-list] set-selected-groups',
  SET_CURRENT_PAGE = '[group-list] set-current-page',
  SET_GROUPS_LOADING = '[group-list] set-table-loading'
}

export interface GroupListQueryParams {
  search: string
  filter: GroupFilter
  skip: number
}

export const groupListActions = {
  setGroupsList: (
    list: Group[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    track(
      createAction(GroupListActionTypes.SET_GROUPS_LIST, {
        list,
        totalResults,
        resultsPerPage
      }),
      {
        segment: uacEvent('Groups Overview Viewed')
      }
    ),

  getGroupsList: (
    params: DataFetchParams<GroupListQueryParams>,
    resultsPerPage = 25
  ) =>
    createAction(GroupListActionTypes.GET_GROUPS_LIST, {
      params,
      resultsPerPage
    }),
  addSelectedGroups: (groups: Group[]) =>
    createAction(GroupListActionTypes.ADD_SELECTED_GROUPS, { groups }),
  removeSelectedGroups: (groups: Group[]) =>
    createAction(GroupListActionTypes.REMOVE_SELECTED_GROUPS, { groups }),
  setSelectedGroups: (groups: Group[]) =>
    createAction(GroupListActionTypes.SET_SELECTED_GROUPS, { groups }),
  addGroups: (groups: Group[]) =>
    createAction(GroupListActionTypes.ADD_GROUPS, { groups }),
  setGroupsLoading: (loading: boolean) =>
    createAction(GroupListActionTypes.SET_GROUPS_LOADING, { loading }),
  reportAddGroupButtonClicked: () =>
    groupListSegmentActions.addGroupButtonClicked()
}

export type GroupListActions = ActionsUnion<typeof groupListActions>

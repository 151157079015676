import { takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { ApplicationDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationEmptyListActionTypes } from '@lastpass/admin-console-dependencies/state/applications/saml/emptylist/actions'
import { ApplicationListActionTypes } from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'

import { createAssignToApplication } from './assign-to-application-saga'
import { createDeleteApplication } from './delete-application-saga'
import { createEnableMultifactor } from './enable-multifactor-saga'
import { createApplicationList } from './get-application-list'
import { createGetAssignedElements } from './get-assigned-elements-saga'
import { createAvailableApplications } from './get-available-applications'
import { createAvailableApplicationsForEmptyList } from './get-available-applications-for-emptylist'
import { createGroupList } from './get-group-list'
import { createInitialData } from './get-initial-data'
import { createRoleList } from './get-role-list'
import { createUserList } from './get-user-list'
import { createAddApplication } from './save-application-saga'
import { createShowUpsellSaga } from './show-sso-upsell'
import { createShowUpsellContactSupportSaga } from './show-sso-upsell-contactsupport'

export function createApplicationsSaga(
  applicationService: UACServices.Services
) {
  const getApplicationList = createApplicationList(applicationService)
  const getAvailableApplications = createAvailableApplications(
    applicationService
  )
  const getAvailableApplicationsForEmptyList = createAvailableApplicationsForEmptyList(
    applicationService
  )
  const getInitialData = createInitialData(applicationService)
  const addApplication = createAddApplication(applicationService)
  const getUserList = createUserList(applicationService)
  const assignToApplication = createAssignToApplication(applicationService)
  const getGroupList = createGroupList(applicationService)
  const getRoleList = createRoleList(applicationService)
  const getAssignedElements = createGetAssignedElements(applicationService)
  const showSsoUpsellDialog = createShowUpsellSaga()
  const showSsoUpsellContactSupportDialog = createShowUpsellContactSupportSaga()
  const deleteApplication = createDeleteApplication(applicationService)
  const enableMultifactor = createEnableMultifactor(applicationService)

  return function*() {
    yield takeLatest(
      ApplicationListActionTypes.GET_APPLICATION_LIST,
      getApplicationList
    )
    yield takeLatest(
      ApplicationDrawerActionTypes.GET_AVAILABLE_APPLICATIONS,
      getAvailableApplications
    )
    yield takeLatest(
      ApplicationEmptyListActionTypes.GET_AVAILABLE_APPLICATIONS,
      getAvailableApplicationsForEmptyList
    )
    yield takeLatest(
      ApplicationDrawerActionTypes.GET_INITIAL_DATA,
      getInitialData
    )
    yield takeLatest(ApplicationDrawerActionTypes.SAVE_AND_EXIT, addApplication)
    yield takeLatest(ApplicationDrawerActionTypes.GET_USER_LIST, getUserList)
    yield takeLatest(
      ApplicationDrawerActionTypes.SAVE_AND_ASSIGN_USERS,
      assignToApplication
    )
    yield takeLatest(ApplicationDrawerActionTypes.GET_GROUP_LIST, getGroupList)
    yield takeLatest(ApplicationDrawerActionTypes.GET_ROLE_LIST, getRoleList)
    yield takeLatest(
      ApplicationDrawerActionTypes.GET_ASSIGNED_ELEMENTS,
      getAssignedElements
    )
    yield takeLatest(
      ApplicationDrawerActionTypes.DELETE_APPLICATION,
      deleteApplication
    )
    yield takeLatest(
      ApplicationListActionTypes.SHOW_SSO_UPSELL_DIALOG,
      showSsoUpsellDialog
    )
    yield takeLatest(
      ApplicationListActionTypes.SHOW_SSO_UPSELL_CONTACTSUPPORT_DIALOG,
      showSsoUpsellContactSupportDialog
    )
    yield takeLatest(
      ApplicationDrawerActionTypes.ENABLE_MULTIFACTOR,
      enableMultifactor
    )
  }
}

import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as IconDnD } from '@lastpass/assets/svg/admin-console/icon-drag-and-drop.svg'

import { BodyRegularStyle, CaptionSemiboldStyle } from '../styles'

type TableResults<T extends {}> = T & {
  checked?: boolean
  draggable?: boolean
}

export interface ReorderTableProps<T extends {}> {
  table: { results: TableResults<T>[] }
  columns: {
    name: React.ReactElement
    renderer: (record: T) => React.ReactElement | string
    cellWidth?: string
  }[]
  setNewList?: (records: T[]) => void
}

const TableContainer = styled.div`
  ${BodyRegularStyle}
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  flex-wrap: wrap;
  border: 1px solid ${props => props.theme.colors.neutral300};
  border-radius: ${props => props.theme.radius.pixel4};
`

const Row = styled.div<{
  checked?: boolean
  disableRowHover?: boolean
  disableIndividualRowHover?: boolean
}>`
  display: flex;
  flex-direction: row;
  box-shadow: inset 0px -1px 0px ${props => props.theme.colors.neutral200};
  background: ${props =>
    props.checked ? props.theme.colors.blue200 : 'transparent'};
  &:hover {
    background: ${props =>
      props.checked
        ? props.theme.colors.blue200
        : props.disableRowHover || props.disableIndividualRowHover
        ? 'transparent'
        : props.theme.colors.blue100};
  }

  &:last-of-type {
    box-shadow: none;
  }

  &.dropArea {
    position: relative;
    height: 45px;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      border-bottom: 1px solid transparent;
      background: ${props => props.theme.colors.neutral200};
    }
  }
`

const TableHead = styled(Row)`
  ${CaptionSemiboldStyle}
  color: ${props => props.theme.colors.neutral600};
  height: 40px;
  overflow: visible;
  &:hover {
    background: transparent;
  }
`

const Cell = styled.div<{ contentWidth?: string }>`
  color: ${props => props.theme.colors.neutral900};
  min-width: ${props => (props.contentWidth ? 'auto' : '10px')};
  ${props => !props.contentWidth && 'width: 10px;'}
  display: block;
  align-self: center;
  padding: ${props => (props.contentWidth ? '11px' : '12px')};
  padding-right: ${props => (props.contentWidth ? '4px' : '12px')};
  flex-basis: ${props => (props.contentWidth ? props.contentWidth : '100%')};
  background: transparent;
  position: relative;
  height: 48px;
  box-sizing: border-box;
`

const StyledIconDnD = styled(IconDnD)`
  cursor: ns-resize;
`

const HeaderCell = styled(Cell)`
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;

  &:first-of-type {
    padding: 16px;
  }
`

const TableRowWrapper = styled.div`
  width: 100%;
  user-select: none;
`

const HeaderCellTitle = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
`

const iconWidth = '25px'

const HeaderSpacer = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '65px'};
`

interface DragAndDropStateType<T> {
  draggedFrom: number | null
  draggedTo: number | null
  isDragging: boolean
  originalOrder: T[]
  updatedOrder: T[]
}

export const ReorderTable = <T extends {}>(props: ReorderTableProps<T>) => {
  const [dragAndDrop, setDragAndDrop] = useState<DragAndDropStateType<T>>({
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
  })

  const [list, setList] = useState<TableResults<T>[]>(props.table.results)

  useEffect(() => {
    setList(props.table.results)
  }, [props.table.results])

  const onDragStart = event => {
    const initialPosition = Number(event.currentTarget.dataset.position)

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list
    })

    // needed for FF support:
    event.dataTransfer.setData('text/html', '')
  }

  const onDragOver = event => {
    event.preventDefault()

    let newList = dragAndDrop.originalOrder
    const draggedFrom = dragAndDrop.draggedFrom
    const draggedTo = Number(event.currentTarget.dataset.position)
    const itemDragged = newList[draggedFrom || 0]

    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    )

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ]

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }
  }

  const onDrop = () => {
    setList(dragAndDrop.updatedOrder)
    !!props.setNewList && props.setNewList(dragAndDrop.updatedOrder)

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    })
  }

  return (
    <TableContainer>
      <TableHead>
        <HeaderSpacer width={props.columns[0].cellWidth && iconWidth} />
        {props.columns.map((column, index) => {
          return (
            <HeaderCell
              key={index}
              data-qa="TableHeaderCell"
              contentWidth={column.cellWidth}
            >
              <HeaderCellTitle>{column.name}</HeaderCellTitle>
            </HeaderCell>
          )
        })}
      </TableHead>
      <TableRowWrapper>
        {list.map((record, index) => {
          const isCurrentElementDraggable =
            dragAndDrop &&
            dragAndDrop.draggedTo === Number(index) &&
            record.draggable
          return (
            <Row
              key={index}
              draggable={record.draggable}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={isCurrentElementDraggable ? onDrop : () => {}}
              data-position={index}
              className={isCurrentElementDraggable ? 'dropArea' : ''}
            >
              <Cell contentWidth={iconWidth}>
                <StyledIconDnD />
              </Cell>
              {props.columns.map((column, columnIndex) => {
                const renderedContent = column.renderer(record)
                return (
                  <Cell
                    key={columnIndex}
                    data-qa="TableOneCell"
                    contentWidth={column.cellWidth}
                  >
                    {renderedContent}
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </TableRowWrapper>
    </TableContainer>
  )
}

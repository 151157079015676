import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { WithTooltip } from '@lastpass/lastkit'

import { AdvancedUserActivityEvent } from '@lastpass/admin-console-dependencies/types/advanced-user-activity'
import { AdvancedUserActivityTableColumn } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-table-column'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'
import { EnumDictionary } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'
import { TimeSinceActivity } from '@lastpass/admin-console-dependencies/ui/common/TimeSinceActivity'

import { FlexContainer } from './AdvancedUserActivityStyledComponents'

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
`

export const AdvancedUserActivityColumnMapping: EnumDictionary<
  AdvancedUserActivityTableColumn,
  TableColumn<AdvancedUserActivityEvent>
> = {
  [AdvancedUserActivityTableColumn.eventType]: {
    name: (
      <FlexContainer>
        <Trans>Event type</Trans>
        <WithTooltip
          tooltip={<Trans>Click to open the full list of events</Trans>}
        >
          <StyledHelpIcon
            onClick={() =>
              window.open(
                `${defaultConfig.lastPassBaseAddress}/logmsgdoc.php`,
                '_blank'
              )
            }
          />
        </WithTooltip>
      </FlexContainer>
    ),
    renderer: (record: AdvancedUserActivityEvent) => record.eventType,
    columnOrderByName: 'eventType'
  },
  [AdvancedUserActivityTableColumn.eventInformation]: {
    name: <Trans>Event information</Trans>,
    renderer: (record: AdvancedUserActivityEvent) => record.eventInformation,
    columnOrderByName: 'eventInformation'
  },
  [AdvancedUserActivityTableColumn.user]: {
    name: <Trans>User</Trans>,
    renderer: (record: AdvancedUserActivityEvent) => record.user,
    columnOrderByName: 'user'
  },
  [AdvancedUserActivityTableColumn.timeUtc]: {
    name: <Trans>Time</Trans>,
    renderer: function getEventTime(record: AdvancedUserActivityEvent) {
      return <TimeSinceActivity lastActivityTime={record.timeUtc} />
    },
    columnOrderByName: 'timeUtc'
  }
}

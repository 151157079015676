import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { GroupUserActionsType } from '@lastpass/admin-console-dependencies/state/users/groups/operations/group-operations'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createDisableMfaGroupSaga(uacServices: UACServices.Services) {
  return function* disableMfaGroupSaga(
    action: ReturnType<typeof groupOperationActions.disableMfa>
  ) {
    try {
      const groupId = action.payload
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Disable multifactor authentication`,
        text: msg`All users in the group will have multifactor authentication disabled except those who haven't activated it.`,
        discardText: msg`Cancel`,
        confirmText: msg`Disable MFA`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        const result: UACServices.GroupUserOperationsAPI.Responses = yield call(
          uacServices.groupUserOperations,
          GroupUserActionsType.disableMfa,
          groupId
        )
        if (result.type === UACServices.GroupUserOperationsAPI.SUCCESS) {
          yield put(
            globalActions.setNotification({
              message: msg`Multifactor authentication has been disabled for the selected users. Bulk actions may take up to 15 minutes to complete.`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (result.type === UACServices.GroupUserOperationsAPI.FAIL) {
          yield put(
            globalActions.setNotification({
              message: msg`Multifactor authentication hasn't been disabled for the selected users.`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { SharedFolderDrawer } from '@lastpass/admin-console-dependencies/ui/shared-folders/SharedFolderDrawer'
import { createSharedFolderList } from '@lastpass/admin-console-dependencies/ui/shared-folders/SharedFolderList'

import { SharedFoldersDrawerPage } from './SharedFoldersDrawerPage'

const SharedFolderList = createSharedFolderList<AppState>()

export interface SharedFoldersPageProps {
  isCidOverrideMode: boolean
}

export interface SharedFolderDrawerProps {
  closeLink: string
  backButton?: ReactElement
}

export function SharedFoldersPage(props: SharedFoldersPageProps) {
  const match = useRouteMatch()
  if (match) {
    const { url, path } = match
    const drawerPath = `${path}/:id`

    const drawerProps: SharedFolderDrawerProps = {
      closeLink: url
    }

    return (
      <>
        <SharedFolderList isCidOverrideMode={props.isCidOverrideMode} />
        <AnimatedSwitch timeout={200}>
          <Route path={drawerPath}>
            <SharedFolderDrawer {...drawerProps}>
              <SharedFoldersDrawerPage
                isCidOverrideMode={props.isCidOverrideMode}
              />
            </SharedFolderDrawer>
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

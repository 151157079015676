import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { DetailsDialog, PrimaryButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

const StyledText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const PermissionRequiredToManagePoliciesDialogComponent: FunctionComponent = () => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => dispatch(globalActions.emptyDialog())}
      title={msg`Permission required`}
      content={
        <StyledText>
          <Trans>
            You don’t have permission to manage policies. Reach out to an admin
            in your organization with proper permissions.
          </Trans>
        </StyledText>
      }
      width="526px"
      buttons={[
        <PrimaryButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          data-qa="PermissionRequiredToManagePoliciesDialogOkButton"
        >
          <Trans>Got it</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = googleInfo => createResponse(SUCCESS, { googleInfo })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'google-workspace-provisioning/settings',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const googleInfo = JSON.parse(response).content

        const groupSyncActive =
          googleInfo.groupIdsToSync && googleInfo.groupIdsToSync.length > 0
        const userSyncActive =
          googleInfo.userIdsToSync && googleInfo.userIdsToSync.length > 0

        return success({
          enabled: googleInfo.isProvisioningEnabled,
          adminEmail: googleInfo.administratorEmail,
          hasProvisionToken: googleInfo.hasCredentialJson,
          integrationExists: true,
          partialSyncEnabled: groupSyncActive || userSyncActive,
          groupIdsToSync: googleInfo.groupIdsToSync || [],
          userIdsToSync: googleInfo.userIdsToSync || []
        })
      } else if (
        status === StatusCodes.NOT_FOUND ||
        status === StatusCodes.NO_CONTENT
      ) {
        return success({
          enabled: false,
          adminEmail: null,
          hasProvisionToken: false,
          integrationExists: false,
          partialSyncEnabled: false,
          groupIdsToSync: []
        })
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

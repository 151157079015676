import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { AdvancedUserActivityActionTypes } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { SecurityReportDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'

import { createDownloadUrlsInSharedFoldersReportSaga } from '../security-reports/download-urls-in-shared-folders-report'
import { createDownloadUrlsInVaultsReportSaga } from '../security-reports/download-urls-in-vaults-report'
import { createGetMasterPasswordValidationDataSaga } from '../security-reports/get-master-password-validation-data'
import { createGetRecoveryKeyDecryptionDataSaga } from '../security-reports/get-recovery-key-decryption-data'
import { createAdvancedUserActivityDeleteReport } from './delete-advanced-user-activity-report'
import { createGetAdvancedUserActivityEventTypes } from './get-advanced-user-activity-event-types'
// reports
import { createGetAdvancedUserActivityEvents } from './get-advanced-user-activity-events'
import { createGetAdvancedUserActivitySavedReports } from './get-advanced-user-activity-saved-reports'
import { createGetAdvancedSelectedUserForEvent } from './get-advanced-user-for-event'
import { createAdvancedUserActivitySaveReport } from './save-advanced-user-activity-report'
import { createAdvancedUserActivityUpdateReport } from './update-advanced-user-activity-report'

export function createReportsSaga(userService: UACServices.Services) {
  const getAdvancedUserActivityEvents = createGetAdvancedUserActivityEvents(
    userService
  )
  const getAdvancedSelectedUserForEvents = createGetAdvancedSelectedUserForEvent(
    userService
  )
  const getAdvancedUserActivityEventTypes = createGetAdvancedUserActivityEventTypes(
    userService
  )
  const getAdvancedUserActivitySavedReports = createGetAdvancedUserActivitySavedReports(
    userService
  )
  const deleteAdvancedUserActivityReport = createAdvancedUserActivityDeleteReport(
    userService
  )
  const updateAdvancedUserActivityReport = createAdvancedUserActivityUpdateReport(
    userService
  )
  const saveAdvancedUserActivityReport = createAdvancedUserActivitySaveReport(
    userService
  )
  const getRecoveryKeyDecryptionDataSaga = createGetRecoveryKeyDecryptionDataSaga(
    userService
  )
  const downloadUrlsInVaultsReportSaga = createDownloadUrlsInVaultsReportSaga(
    userService
  )
  const downloadUrlsInSharedFoldersReportSaga = createDownloadUrlsInSharedFoldersReportSaga(
    userService
  )
  const getMasterPasswordValidationDataSaga = createGetMasterPasswordValidationDataSaga(
    userService
  )

  return function*() {
    yield takeEvery(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_ACTIVITY,
      getAdvancedUserActivityEvents
    )
    yield takeLatest(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_FOR_EVENT,
      getAdvancedSelectedUserForEvents
    )
    yield takeLatest(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_USER_ACTIVITY_EVENT_TYPES,
      getAdvancedUserActivityEventTypes
    )
    yield takeLatest(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_GET_SAVED_REPORTS,
      getAdvancedUserActivitySavedReports
    )
    yield takeLatest(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_DELETE_SELECTED_SAVED_REPORT,
      deleteAdvancedUserActivityReport
    )
    yield takeLatest(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_UPDATE_REPORT,
      updateAdvancedUserActivityReport
    )
    yield takeLatest(
      AdvancedUserActivityActionTypes.ADVANCED_REPORT_SAVE_REPORT,
      saveAdvancedUserActivityReport
    )
    yield takeEvery(
      SecurityReportDrawerActionTypes.GET_RECOVERY_KEY_DECRYPTION_DATA,
      getRecoveryKeyDecryptionDataSaga
    )
    yield takeEvery(
      SecurityReportDrawerActionTypes.DOWNLOAD_URLS_IN_VAULTS_REPORT,
      downloadUrlsInVaultsReportSaga
    )
    yield takeEvery(
      SecurityReportDrawerActionTypes.DOWNLOAD_URLS_IN_SHARED_FOLDERS_REPORT,
      downloadUrlsInSharedFoldersReportSaga
    )
    yield takeEvery(
      SecurityReportDrawerActionTypes.GET_MASTER_PASSWORD_VALIDATION_DATA,
      getMasterPasswordValidationDataSaga
    )
  }
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { NumberedList } from '@lastpass/lastkit/components/NumberedList'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaApplicationType } from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'

const StyledLocationLink = styled.a`
  margin-top: 14px;
  font-weight: bold;
`

export const AzureAdInstall = () => {
  return (
    <>
      <NumberedList
        items={[
          <StyledLocationLink
            key={'1'}
            href={'http://link.lastpass.com/help-mfa-apps-azure-ad'}
            data-qa={'InstructionsLink'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              mfaAppsDrawerActions.configureAppClicked('Download Installer', {
                type: MfaApplicationType.azureAD
              })
            }
          >
            <Trans>Follow these instructions to complete installation.</Trans>
          </StyledLocationLink>,
          <Trans key={'2'}>
            Users assigned to a LastPass Authenticator multifactor policy will
            be prompted for MFA after entering their password.
          </Trans>
        ]}
      />
    </>
  )
}

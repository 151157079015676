import { OpenIdProvider } from '@lastpass/federation/lib/federation-enums'

import { FederatedLoginActions, FederatedLoginActionTypes } from './actions'
import { FederatedLoginState } from './state'

export const initialState: FederatedLoginState = {
  adfs: {
    useCompanyWideKey: true,
    isEnabled: false,
    providerUrl: '',
    publicKey: '',
    providerLoginUrl: '',
    isPingFederate: false
  },
  adAttribute: { adAttributeName: '' },
  adAttributeVisible: false,
  openId: {
    openIdUrl: '',
    clientId: '',
    isEnabled: false,
    provider: OpenIdProvider.AzureAD,
    useOktaAuthServerToStoreCompanyKey: false,
    useLastPassADConnectorToSyncUsers: false,
    isEmailHintDisabled: false,
    pkceEnabled: false,
    useAzureMdmFlow: false
  },
  keyRotationEstimation: {
    remainingTime: null,
    percentage: 0
  },
  keyRotationAuth: {
    authority: '',
    clientId: ''
  },
  loading: true
}

export const federatedLoginReducer = (
  previousState: FederatedLoginState | undefined,
  action: FederatedLoginActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case FederatedLoginActionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case FederatedLoginActionTypes.ADFS_SETUP_SET: {
      return {
        ...state,
        adfs: action.payload.adfsSetup,
        adAttributeVisible: !action.payload.adfsSetup.useCompanyWideKey
      }
    }
    case FederatedLoginActionTypes.AD_ATTRIBUTE_SET: {
      return {
        ...state,
        adAttribute: action.payload.adAttribute
      }
    }
    case FederatedLoginActionTypes.AD_ATTRIBUTE_VISIBLE_SET: {
      return {
        ...state,
        adAttributeVisible: action.payload
      }
    }
    case FederatedLoginActionTypes.OPEN_ID_SETUP_SET: {
      return {
        ...state,
        openId: action.payload.openIdSetup
      }
    }
    case FederatedLoginActionTypes.SET_KEY_ROTATION_REMAINING_TIME: {
      return {
        ...state,
        keyRotationEstimation: {
          ...state.keyRotationEstimation,
          remainingTime: action.payload.keyRotationRemainingTime
        }
      }
    }
    case FederatedLoginActionTypes.SET_KEY_ROTATION_PERCENTAGE: {
      return {
        ...state,
        keyRotationEstimation: {
          ...state.keyRotationEstimation,
          percentage: action.payload.keyRotationProgressPercentage
        }
      }
    }
    case FederatedLoginActionTypes.SET_DEFAULT_KEY_ROTATION_ESTIMATION_VALUES: {
      return {
        ...state,
        keyRotationEstimation: initialState.keyRotationEstimation
      }
    }
    case FederatedLoginActionTypes.SET_KEY_ROTATION_AUTH_VALUES: {
      return {
        ...state,
        keyRotationAuth: action.payload.keyRotationAuth
      }
    }
    default:
      return state
  }
}

import React, { FunctionComponent } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'

interface SelectAdminLevelProps {
  updateSelection: (update: AdminLevel[]) => void
  filters?: AdminLevel[]
}

interface AdminLevelSelectOption {
  value: AdminLevel
  label: string
}

export const SelectAdminLevel: FunctionComponent<SelectAdminLevelProps> = props => {
  const adminLevel: AdminLevelSelectOption[] = [
    { value: AdminLevel.user, label: i18n._(msg`User`) },
    { value: AdminLevel.admin, label: i18n._(msg`Admin`) },
    { value: AdminLevel.superAdmin, label: i18n._(msg`Super admin`) },
    {
      value: AdminLevel.legacyHelpdeskAdmin,
      label: i18n._(msg`Legacy helpdesk admin`)
    },
    { value: AdminLevel.helpdeskAdmin, label: i18n._(msg`Helpdesk admin`) }
  ]
  return (
    <Select
      dataQa={'AdminLevelDropdown'}
      options={adminLevel}
      value={adminLevel.filter(
        item => props.filters && props.filters.includes(item.value)
      )}
      isMulti
      hideSelectedOptions={false}
      placeholder={i18n._(msg`Admin level`)}
      onChange={selectedItems => {
        if (Array.isArray(selectedItems) && selectedItems.length) {
          props.updateSelection(selectedItems.map(item => item.value))
        } else {
          props.updateSelection([])
        }
      }}
      fluid
    >
      <Trans>Admin level</Trans>
    </Select>
  )
}

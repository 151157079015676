import { push } from 'connected-react-router'
import { put, take } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GlobalActionTypes } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createDiscardFormSaga() {
  return function* discardFormSaga(
    action: ReturnType<typeof globalActions.showConfirmDialogOnDiscard>
  ) {
    try {
      const { dialog, closeLink, unblock } = action.payload

      yield put(globalActions.setDialog(dialog))

      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.DISCARD_DIALOG) {
        unblock && unblock()
        yield put(push(closeLink))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { msg, Trans } from '@lingui/macro'

import { ReactComponent as HourglassGreenIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/hourglass-green.svg'
import { ReactComponent as HourglassRedIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/hourglass-red.svg'
import { PrimaryButton } from '@lastpass/lastkit'

import { useCountColor } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-count-color'
import { ActionSuccess } from '@lastpass/admin-console-dependencies/ui/home/components/action-success/ActionSuccess'
import { ActionCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionCard'

export interface PendingInvitationsCardProps {
  count: number
  dataQa?: string
}

export const PendingInvitationsCard: FC<PendingInvitationsCardProps> = ({
  count,
  dataQa
}) => {
  const countColor = useCountColor(count)
  return (
    <ActionCard
      icon={count === 0 ? HourglassGreenIcon : HourglassRedIcon}
      count={count}
      countColor={countColor}
      title={msg`Pending invitations`}
      description={
        <Trans>
          Send a follow-up email to folks who haven’t activated their account.
        </Trans>
      }
      action={
        count === 0 ? (
          <ActionSuccess message={msg`No pending invitations`} />
        ) : (
          <Link to="/dashboard/invite-pending">
            <PrimaryButton data-qa="PendingInvitationsRemindUsersButton">
              <Trans>View & remind users</Trans>
            </PrimaryButton>
          </Link>
        )
      }
      dataQa={dataQa}
    />
  )
}

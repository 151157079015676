import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

export const RecipientAlreadyHasTheKeysErrorDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(distributeRecoveryKeysActions.trackRecoveryKeySharingErrorViewed())
  }, [dispatch])

  const content = (
    <StyledParagraph>
      <Trans>
        You either have no keys associated with your account or the recipient
        already has all the keys you could share.
      </Trans>
    </StyledParagraph>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="650px"
      title={msg`No keys were shared.`}
      titleColor="red"
      content={content}
      buttons={[
        <PrimaryButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          data-qa="ErrorSharingRecoveryKeysInvisiblyDialogConfirmButton"
        >
          <Trans>OK</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

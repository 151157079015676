import React, { PropsWithChildren } from 'react'

import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const TabNavigation: React.FunctionComponent<PropsWithChildren<{}>> = props => {
  return <Container>{props.children}</Container>
}

import * as UACTracking from './uac'
import { SegmentEvent, SegmentProperties } from '../types'

export function segmentEvent(
  event: string,
  properties?: SegmentProperties
): SegmentEvent {
  return {
    event,
    properties
  }
}

export function uacEvent<E extends keyof UACTracking.Plan>(
  event: E,
  properties: Required<UACTracking.Plan>[E]['properties'] = undefined
): SegmentEvent {
  return segmentEvent(event, properties)
}
export { UACTracking }

export type SegmentPropertyType<
  E extends keyof UACTracking.Plan,
  P extends keyof Required<UACTracking.Plan[E]>['properties']
> = Required<UACTracking.Plan[E]>['properties'][P]

import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { CompanyInfoActionTypes } from '@lastpass/admin-console-dependencies/state/company/actions'

import { createCompanyLicensesSaga } from './get-company-licenses'
import { createGetCompanySettingsSaga } from './get-company-settings'
import { createRemoveCidOverrideSaga } from './remove-cid-override'

export function createCompanySaga(companyServices: UACServices.Services) {
  const companyLicensesSaga = createCompanyLicensesSaga(companyServices)
  const getCompanySettingsSaga = createGetCompanySettingsSaga(companyServices)
  const removeCidOverrideSaga = createRemoveCidOverrideSaga()

  return function*() {
    yield takeEvery(
      CompanyInfoActionTypes.GET_COMPANY_LICENSES,
      companyLicensesSaga
    )
    yield takeEvery(
      CompanyInfoActionTypes.GET_COMPANY_SETTINGS,
      getCompanySettingsSaga
    )
    yield takeEvery(
      CompanyInfoActionTypes.REMOVE_CID_OVERRIDE,
      removeCidOverrideSaga
    )
  }
}

import React, { FunctionComponent, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { Drawer } from '@lastpass/lastkit'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { DrawerDescription } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/drawer/DrawerDescription'
import { StyledDrawerOverlay } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/StyledDrawerOverlay'
import { EditPsaForm } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/edit/EditPsaForm'
import { PsaProviders } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/PsaIntegrations'

export interface EditPsaDrawerProps {
  basePath: string
  title: ReactElement
}

export const EditPsaDrawer: FunctionComponent<EditPsaDrawerProps> = ({
  basePath,
  title
}) => {
  const { drawerInProgress } = useSelector(
    (state: AppState) => state.psaIntegrations
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const handleOnClose = () => {
    dispatch(psaIntegrationsActions.setPsaEditCredentialsFormValues(null, id))
    history.push(basePath)
  }

  return (
    <>
      <StyledDrawerOverlay onClose={handleOnClose} />
      <Drawer
        mainTitle={title}
        closeLink={basePath}
        onClose={handleOnClose}
        data-qa="EditPsaDrawer"
      >
        <DrawerDescription>
          <Trans>
            Complete this form using information from your {PsaProviders[id]}{' '}
            admin portal.
          </Trans>
        </DrawerDescription>
        {drawerInProgress && (
          <Loading data-qa="Loading" color="blue900" active={true} />
        )}
        <EditPsaForm closeLink={basePath} />
      </Drawer>
    </>
  )
}

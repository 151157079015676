import React, { FunctionComponent, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  DetailsDialog,
  PrimaryButton,
  TextButton,
  TextInput
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { KeyRotationAuthenticateCompanyAdminDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
interface KeyRotationAuthenticateCompanyAdminDialogProps {
  state: KeyRotationAuthenticateCompanyAdminDialog
  actions: typeof globalActions
}
const TextBoxContainer = styled.div`
  margin-top: 20px;
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.blue700};
`
const StyledTextInput = styled(TextInput)`
  max-width: 100%;
`

export const KeyRotationAuthenticateCompanyAdminDialogComponent: FunctionComponent<KeyRotationAuthenticateCompanyAdminDialogProps> = ({
  actions,
  state
}) => {
  const dispatch = useDispatch()

  const federatedLoginState = useSelector<AppState, FederatedLoginState>(
    appState => appState.federatedLogin
  )

  const initRef = useRef(true)

  const validationSchema = Yup.object().shape({
    applicationURL: Yup.string().url(i18n._(msg`Must be a valid URL`)),
    applicationID: Yup.string().required()
  })

  return (
    <Formik
      initialValues={{ applicationURL: '', applicationID: '' }}
      validationSchema={validationSchema}
      onSubmit={values => {
        actions.confirmDialog({
          applicationURL: values.applicationURL.trim(),
          applicationID: values.applicationID.trim()
        })
        initRef.current = true
      }}
    >
      {formikProps => {
        return (
          <DetailsDialog
            closeDialog={() => {
              dispatch(globalActions.discardDialog())
              dispatch(globalActions.emptyDialog())
            }}
            title={msg`Specify application details`}
            subTitle={msg`Enter application details from the service  (${state.providerName}).`}
            width="600px"
            disableCloseOnBackground
            content={
              <>
                <StyledExternalLink
                  href="https://support.lastpass.com/s/document-item?language=en_US&bundleId=lastpass&topicId=LastPass/rotate_knowledge_components_for_azure_ad.html&_LANG=enus"
                  target="_blank"
                >
                  <Trans>How to find application details</Trans>
                </StyledExternalLink>
                <Form>
                  <TextBoxContainer>
                    <StyledTextInput
                      data-qa="ApplicationURLInput"
                      name="applicationURL"
                      value={formikProps.values.applicationURL}
                      onChange={e => {
                        initRef.current = false
                        formikProps.handleChange(e)
                      }}
                      type="text"
                      error={
                        !!formikProps.errors.applicationURL ||
                        (state.isIdpUrlInvalid && initRef.current)
                      }
                      errorText={
                        state.isIdpUrlInvalid && initRef.current ? (
                          <Trans>
                            Confirm that you copied the correct OpenID Connect
                            meta data document URL from
                            {` (${state.providerName})`}
                          </Trans>
                        ) : (
                          <>{formikProps.errors.applicationURL}</>
                        )
                      }
                    >
                      <Trans>OpenID Connect meta data document URL</Trans>
                    </StyledTextInput>
                  </TextBoxContainer>
                  <TextBoxContainer>
                    <StyledTextInput
                      data-qa="ApplicationIDInput"
                      name="applicationID"
                      value={formikProps.values.applicationID}
                      onChange={formikProps.handleChange}
                      type="text"
                    >
                      <Trans>Application (client) ID</Trans>
                    </StyledTextInput>
                  </TextBoxContainer>
                </Form>
              </>
            }
            buttons={[
              <TextButton
                data-qa="PopupCancel"
                onClick={() => {
                  dispatch(globalActions.discardDialog())
                  dispatch(globalActions.emptyDialog())
                }}
                key="discard"
              >
                <Trans>Cancel</Trans>
              </TextButton>,
              <PrimaryButton
                data-qa="PopupSubmit"
                disabled={
                  federatedLoginState.loading ||
                  !formikProps.isValid ||
                  !formikProps.dirty ||
                  !formikProps.values.applicationURL.length ||
                  !formikProps.values.applicationID.length
                }
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                key="confirm"
              >
                <Trans>Validate</Trans>
              </PrimaryButton>
            ]}
          />
        )
      }}
    </Formik>
  )
}

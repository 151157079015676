import { takeEvery } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { AssignAdminLevelUsersActionTypes } from '@lastpass/admin-console-dependencies/state/users/admin/add-users/actions'
import { AdminLevelDrawerActionTypes } from '@lastpass/admin-console-dependencies/state/users/admin/drawer/actions'
import { AdminListActionTypes } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminOperationActionTypes } from '@lastpass/admin-console-dependencies/state/users/admin/operations/actions'

import { createAddUsersToAdminLevelSaga } from './add-users/add-users-to-admin-level'
import { createAdminAssignableUsersSaga } from './add-users/get-assignable-users'
import { createUnassignUsersFromAdminLevelsSaga } from './add-users/unassign-users-from-admin-levels'
import { createAdminListSaga } from './admin-list'
import { createAddNewAdminLevelSaga } from './drawer/add-new-admin-level'
import { createAddNewManagedAdminLevelSaga } from './drawer/add-new-managed-admin-level'
import { createAddNewPartialdminLevelSaga } from './drawer/add-new-partial-admin-level'
import { createAdminLevelSaga } from './drawer/admin-level-get'
import { createEditAdminLevelSaga } from './drawer/edit-admin-level'
import { createEditManagedAdminLevelSaga } from './drawer/edit-managed-admin-level'
import { createEditPartialAdminLevelSaga } from './drawer/edit-partial-admin-level'
import { createGetAdminLevelUsersSaga } from './drawer/get-admin-level-users'
import { createGetAdminPermissionsSaga } from './drawer/get-admin-permissions'
import { createGetManagedAdminPermissionsSaga } from './drawer/get-managed-admin-permissions'
import { createRemoveUsersFromAdminLevelSaga } from './drawer/remove-users-from-admin-level'
import { createDeleteAdminLevelSaga } from './operations/delete-admin-level'

export function createAdminSaga(adminService: UACServices.Services) {
  const getAdminListSaga = createAdminListSaga(adminService)
  const getAdminLevelSaga = createAdminLevelSaga(adminService)
  const getAdminLevelUsersSaga = createGetAdminLevelUsersSaga(adminService)
  const adminAssignableUsersSaga = createAdminAssignableUsersSaga(adminService)
  const removeUsersFromAdminLevelSaga = createRemoveUsersFromAdminLevelSaga(
    adminService
  )
  const addUsersToAdminLevelSaga = createAddUsersToAdminLevelSaga(adminService)
  const addAdminLevelSaga = createAddNewAdminLevelSaga(adminService)
  const addPartialAdminLevelSaga = createAddNewPartialdminLevelSaga()
  const addManagedAdminLevelSaga = createAddNewManagedAdminLevelSaga(
    adminService
  )
  const unassignUsersFromAdminLevels = createUnassignUsersFromAdminLevelsSaga(
    adminService
  )
  const editAdminLevelSaga = createEditAdminLevelSaga(adminService)
  const editPartialAdminLevelSaga = createEditPartialAdminLevelSaga(
    adminService
  )
  const editManagedAdminLevelSaga = createEditManagedAdminLevelSaga(
    adminService
  )
  const deleteAdminLevelSaga = createDeleteAdminLevelSaga(adminService)
  const getAdminPermissionsSaga = createGetAdminPermissionsSaga(adminService)
  const getManagedAdminPermissionsSaga = createGetManagedAdminPermissionsSaga(
    adminService
  )

  return function*() {
    yield takeEvery(AdminListActionTypes.GET_ADMIN_LIST, getAdminListSaga)
    yield takeEvery(
      AdminLevelDrawerActionTypes.GET_CURRENT_ADMIN_LEVEL,
      getAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.GET_ADMIN_LEVEL_USERS,
      getAdminLevelUsersSaga
    )
    yield takeEvery(
      AssignAdminLevelUsersActionTypes.GET_ASSIGN_USER_LIST_ADMIN_LEVEL,
      adminAssignableUsersSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.REMOVE_USERS_FROM_ADMIN_LEVEL,
      removeUsersFromAdminLevelSaga
    )
    yield takeEvery(
      AssignAdminLevelUsersActionTypes.ADD_USERS_TO_ADMIN_LEVEL,
      addUsersToAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.ADD_NEW_ADMIN_LEVEL,
      addAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.ADD_NEW_PARTIAL_ADMIN_LEVEL,
      addAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.ADD_NEW_GRANULAR_ADMIN_LEVEL,
      addPartialAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.ADD_NEW_MANAGED_ADMIN_LEVEL,
      addManagedAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.EDIT_ADMIN_LEVEL,
      editAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.EDIT_PARTIAL_ADMIN_LEVEL,
      editPartialAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.EDIT_MANAGED_ADMIN_LEVEL,
      editManagedAdminLevelSaga
    )
    yield takeEvery(
      AdminOperationActionTypes.DELETE_ADMIN_LEVEL,
      deleteAdminLevelSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.GET_ADMIN_PERMISSIONS,
      getAdminPermissionsSaga
    )
    yield takeEvery(
      AdminLevelDrawerActionTypes.GET_MANAGED_ADMIN_PERMISSIONS,
      getManagedAdminPermissionsSaga
    )
    yield takeEvery(
      AssignAdminLevelUsersActionTypes.UNASSIGN_USERS_FROM_ADMIN_LEVELS,
      unassignUsersFromAdminLevels
    )
  }
}

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { AddUrlRuleApi } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createAddNewURLRule(
  enterpriseOptionServices: UACServices.Services
) {
  return function* addNewURLRule(
    action: ReturnType<typeof urlRulesActions.addUrlRule>
  ) {
    try {
      yield put(urlRulesActions.setUrlRulesLoading(true))

      const addUrlRuleResult: UACServices.AddUrlRuleApi.Responses = yield call(
        enterpriseOptionServices.addUrlRule,
        action.payload.rule
      )

      if (addUrlRuleResult.type == AddUrlRuleApi.SUCCESS) {
        yield put(urlRulesActions.setUrlRulesLoading(false))
        yield put(urlRulesActions.getUrlRules())
        yield put(
          globalActions.setNotification({
            message: msg`URL rule created`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { splunkIntegrationActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/splunk-integration/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetSplunkIntegration(
  enterpriseOptionServices: UACServices.Services
) {
  return function* getSplunkIntegration() {
    try {
      yield put(splunkIntegrationActions.setSplunkIntegrationLoading(true))
      try {
        const splunkIntegrationResult: UACServices.SplunkIntegrationGetAPI.Responses = yield call(
          enterpriseOptionServices.splunkIntegrationGet
        )
        yield put(
          splunkIntegrationActions.setSplunkIntegration(
            splunkIntegrationResult.body.splunkIntegration
          )
        )
      } finally {
        yield put(splunkIntegrationActions.setSplunkIntegrationLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

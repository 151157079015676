import React from 'react'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'
import { SystemUpgradeDrawerHeadersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SystemUpgradeMapping'

import {
  DetailsBox,
  DrawerContainer,
  LegacyConsolePath,
  SystemUpgradeDrawerFooter,
  SystemUpgradeDrawerProps,
  SystemUpgradeFeatureNotAvailable
} from './SystemUpgradeDrawerResources'

const detailsBoxContent = [
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatFor],
    dataQa: 'identityRolesDrawerWhatFeatureForContainer',
    mainContent: (
      <Trans>
        Some organizations used the Roles feature at identity.lastpass.com to
        assign applications or policies to users depending on their function or
        department, such as Finance, Marketing, or similar.
      </Trans>
    )
  },
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.where],
    dataQa: 'identityRolesDrawerFeatureWhereContainer',
    mainContent: (
      <ul>
        <li>
          <Trans>New console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <Trans>Old console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <LegacyConsolePath
            isNotAvailable={false}
            path={<Trans>Users{' > '}Roles</Trans>}
          />
        </li>
      </ul>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[
        SystemUpgradeDrawerHeader.whatsHappening
      ],
    dataQa: 'identityRolesDrawerFeatureWhatHappeningContainer',
    mainContent: (
      <Trans>
        Identity Roles have been migrated automatically from
        identity.lastpass.com to groups in the new Admin Console. Migrated items
        will include the word “role” in their group name until renamed.
      </Trans>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatToDo],
    dataQa: 'identityRolesDrawerFeatureWhatToDoContainer',
    mainContent: (
      <Trans>
        To achieve similar goals after upgrade, use the Groups feature.
      </Trans>
    )
  }
]

export const IdentityRolesDrawer: React.FunctionComponent<SystemUpgradeDrawerProps> = ({
  closeLink
}) => (
  <Drawer
    mainTitle={<Trans>Identity Roles</Trans>}
    subTitle={<Trans>Details</Trans>}
    closeLink={closeLink}
    data-qa="identityRolesDrawer"
  >
    <DrawerContainer data-qa="identityRolesDrawerContentContainer">
      {detailsBoxContent.map(content => (
        <DetailsBox
          key={content.dataQa}
          title={content.title}
          data-qa={content.dataQa}
        >
          {content.mainContent}
        </DetailsBox>
      ))}
    </DrawerContainer>
    <SystemUpgradeDrawerFooter
      closeLink={closeLink}
      data-qa="identityRolesDrawerFooter"
    />
  </Drawer>
)

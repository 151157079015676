import React from 'react'

import styled from '@emotion/styled'

import BackgroundImage from '@lastpass/assets/jpg/admin_console_placeholder.jpg'

import { createGlobalDialog } from '@lastpass/admin-console-dependencies/ui/global/GlobalDialog'

import { AppState } from '../app-store'

interface BackgroundProps {
  backgroundImage: string
}

export const Background = styled.div<BackgroundProps>`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  overflow: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('${props => props.backgroundImage}');
`

const GlobalDialog = createGlobalDialog<AppState>()

export const BlockerPage: React.FunctionComponent = () => {
  return (
    <>
      <Background backgroundImage={BackgroundImage} />
      <GlobalDialog />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as PoliciesIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerTable,
  RowContainer,
  SearchInput,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import { GetPolicyName } from '@lastpass/admin-console-dependencies/services/get-policy-name'
import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'

export const GroupDrawerPoliciesTable: React.FunctionComponent = () => {
  const groupDrawerState: GroupDrawerState = useSelector(
    (state: AppState) => state.groupDrawer
  )
  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      groupDrawerActions.getGroupPolicies({ query: {}, path: pathParams })
    )
  }, [dispatch, pathParams])

  useDrawerSubtitle(<Trans>Policies</Trans>)

  const [searchQuery, setSearchQuery] = useState('')
  const translatedPolicyNames = groupDrawerState.policies.results
    .map(policy => {
      return {
        name: policy.needsToBeTranslated
          ? GetPolicyName(policy.name)
          : policy.name,
        id: policy.name
      }
    })
    .filter(policyObject => {
      const lowerPolicyName = policyObject.name.toLowerCase()
      return lowerPolicyName.indexOf(searchQuery.toLowerCase()) >= 0
    })
  const policiesToDisplay = {
    ...groupDrawerState.policies,
    results: translatedPolicyNames,
    checkedRecords: [],
    filter: undefined,
    excludedRecords: undefined,
    columns: []
  }

  return (
    <>
      <RowContainer>
        <SearchInput
          flexGrow
          value={searchQuery}
          placeholder={msg`Search policies`}
          onChange={e => setSearchQuery(e.target.value)}
          data-qa={'SearchPolicesInputField'}
        />
      </RowContainer>
      <DrawerTable
        qadata={'GroupLevelFullPoliciesList'}
        table={policiesToDisplay}
        noRecordsView={
          <TableView
            icon={<PoliciesIcon />}
            title={<Trans>No policies assigned yet</Trans>}
            text={
              <Trans>
                Assign policies to control how group members can use LastPass.
              </Trans>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<PoliciesIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
          />
        }
        isFilterApplied={!!searchQuery}
        columns={[
          {
            name: <Trans>Policy</Trans>,
            renderer: record => record.name
          }
        ]}
        loadingView={<Loading color="blue900" active={true} />}
      />
    </>
  )
}

import React, { useEffect } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as LastPassAuthenticator } from '@lastpass/assets/svg/admin-console/icon-lastpass-authenticator.svg'
import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/admin-console/icon-warning-triangle.svg'
import {
  BodyRegular,
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'

import { GetDisplayableData } from './AllSet'

const mfaPolicyKey = 'requirelastpassauth'

const TopBodyText = styled.div`
  margin-bottom: 24px;
`

export interface EnableMultifactorProps {
  closeLink: string
}

export type EnableMultifactorComponentProps = LastPassComponentProps<
  ApplicationDrawerState,
  typeof applicationsDrawerActions,
  EnableMultifactorProps
>

export const BodyText = styled.div`
  margin-bottom: 24px;
`

export const BodyTitle = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`

const ListSectionContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`

const ListTitle = styled.div`
  margin-bottom: 5px;
`

const DisplayRolesSection = styled.div`
  margin-top: 24px;
`

const DisplayRolesWarningText = styled.span``

const BottomBodyText = styled.div`
  margin-top: 24px;
`

const LearnMoreText = styled.div`
  margin-top: 12px;
`

const Bold = styled.span`
  font-weight: bold;
`

const LastPassAuthenticatorIconComponent = styled(LastPassAuthenticator)`
  vertical-align: middle;
  float: left;
  margin-right: 12px;
`

const WarningIconComponent = styled(WarningIcon)`
  vertical-align: middle;
  float: left;
  margin-right: 8px;
  color: orange;
`

const LastPassAuthenticatorIconDescription = styled.span`
  font-size: small;
`

export const EnableMultifactorComponent: React.FunctionComponent<EnableMultifactorComponentProps> = ({
  state,
  actions
}) => {
  useEffect(() => {
    if (state.selectedApplication && state.selectedApplication.id) {
      actions.getAssignedElements(state.selectedApplication.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayRolesWarning: boolean = state.roles.totalResults > 0

  return (
    <>
      <BodyTitle>
        <Heading100>
          <Trans>Require use of LastPass Authenticator</Trans>
        </Heading100>
      </BodyTitle>
      <BodyRegular>
        <BodyText>
          <TopBodyText>
            <LastPassAuthenticatorIconComponent />
            <LastPassAuthenticatorIconDescription>
              <Trans>
                For heightened security, require use of the{' '}
                <Bold>LastPass Authenticator</Bold> app for logins. Users verify
                logins with push notifications, TOTP codes, SMS, or voice.
              </Trans>
            </LastPassAuthenticatorIconDescription>
          </TopBodyText>
          <TopBodyText>
            <Trans>Try it with the users you’ve just assigned:</Trans>
          </TopBodyText>
          <ListSectionContainer data-qa="EnableMultifactorRolesSection">
            <ListTitle>
              <Trans>Roles:</Trans>
            </ListTitle>
            {GetDisplayableData(state.roles)}
          </ListSectionContainer>
          <ListSectionContainer data-qa="EnableMultifactorGroupsSection">
            <ListTitle>
              <Trans>Groups:</Trans>
            </ListTitle>
            {GetDisplayableData(state.groups)}
          </ListSectionContainer>
          <ListSectionContainer data-qa="EnableMultifactorUsersSection">
            <ListTitle>
              <Trans>Users:</Trans>
            </ListTitle>
            {GetDisplayableData(state.users)}
          </ListSectionContainer>
          <DisplayRolesSection data-qa="EnableMultifactorDisplayRolesSection">
            {displayRolesWarning ? (
              <div>
                <WarningIconComponent />
                <DisplayRolesWarningText>
                  <Trans>
                    Users assigned a Role won’t be prompted to use multifactor
                    authentication. The legacy Roles feature is not compatible
                    with MFA.
                  </Trans>
                </DisplayRolesWarningText>
              </div>
            ) : null}
          </DisplayRolesSection>
          <BottomBodyText data-qa="EnableMultifactorBottomText">
            <Trans>
              To require use of LastPass Authenticator more widely, go to{' '}
              <Bold>Policies</Bold>.
            </Trans>
          </BottomBodyText>
          <LearnMoreText data-qa="EnableMultifactorLearnMoreText">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.lastpass.com/2016/03/lastpass-authenticator-makes-two-factor-easy/"
              data-qa="ConfigureAppStepByStepLink"
            >
              <Trans>Learn more</Trans>
            </a>
          </LearnMoreText>
        </BodyText>
      </BodyRegular>
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key={'SkipEnableMultifactor'}
            type="button"
            onClick={() => actions.nextStep(ApplicationDrawerSteps.allset)}
            data-qa={'SkipEnableMultifactorButton'}
          >
            <Trans>Skip for now</Trans>
          </TextButton>
          <PrimaryButton
            id={'EnableMultifactorButton'}
            key={'EnableMultifactor'}
            onClick={() => {
              actions.enableMultifactor(
                mfaPolicyKey,
                state.users.results,
                state.groups.results
              )
            }}
            data-qa={'EnableMultifactorButton'}
          >
            <Trans>Enable for these users</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

export function createEnableMultifactor<
  TState extends { applicationDrawer: ApplicationDrawerState }
>() {
  return connectRedux(
    EnableMultifactorComponent,
    (state: TState) => state.applicationDrawer,
    applicationsDrawerActions
  )
}

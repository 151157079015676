import { StatusCodes } from 'http-status-codes'

export class FederationApiError extends Error {
  federationErrorCode: string
  httpErrorCode: StatusCodes | undefined
  constructor(errorDetails: {
    federationErrorCode: string
    httpErrorCode?: StatusCodes
  }) {
    super(errorDetails.federationErrorCode)
    this.federationErrorCode = errorDetails.federationErrorCode
    this.httpErrorCode = errorDetails?.httpErrorCode
  }
}

import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import {
  MultifactorOptionType,
  MultifactorPolicyKeys
} from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { MultifactorPageState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/state'

export const multifactorPageState = (state: AppState) => state.multifactorPage

export function createSetSelectedMfaMethodDefaultValue() {
  return function* setSelectedMfaMethodDefaultValue() {
    const pageState: MultifactorPageState = yield select(multifactorPageState)
    const totpPolicy = pageState.RequireOfPolicies.find(
      ({ policyKey }) => policyKey === MultifactorPolicyKeys.Totp
    )

    if (totpPolicy && !totpPolicy.checked) {
      yield put(
        multifactorDrawerActions.setSelectedMfaMethod(
          MultifactorOptionType.TotpApp
        )
      )
    }
  }
}

import {
  AppliesToOptions,
  Entities
} from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { AssignActions, AssignActionTypes } from './actions'
import { AssignState } from './state'

const defaultTable = {
  results: [],
  resultsPerPage: 25,
  totalResults: 0,
  checkedRecords: [],
  isLoading: false
}

const defaultEntity = {
  searchInput: '',
  list: defaultTable,
  filtered: defaultTable,
  available: defaultTable,
  isLoading: false
}

export const initialState = {
  showTable: Entities.users,
  appliesTo: AppliesToOptions.all,
  users: defaultEntity,
  groups: defaultEntity
}

export function assignReducer(
  previousState: AssignState | undefined,
  action: AssignActions
): AssignState {
  const state = previousState || initialState
  switch (action.type) {
    case AssignActionTypes.CHANGE_APPLIES_TO: {
      return {
        ...state,
        appliesTo: action.payload.appliesTo
      }
    }
    case AssignActionTypes.SET_SEARCH_INPUT: {
      return {
        ...state,
        [action.payload.serachedEntity]: {
          ...state[action.payload.serachedEntity],
          searchInput: action.payload.searchString
        }
      }
    }
    case AssignActionTypes.FILTER: {
      return {
        ...state,
        [action.payload.filteredEntity]: {
          ...state[action.payload.filteredEntity],
          filtered: {
            ...state[action.payload.filteredEntity].filtered,
            results: state[
              action.payload.filteredEntity
            ].list.results.filter(group =>
              group.name.toLowerCase().includes(action.payload.filterString)
            )
          }
        }
      }
    }
    case AssignActionTypes.SHOW_TABLE: {
      return {
        ...state,
        showTable: action.payload.table
      }
    }
    case AssignActionTypes.SELECT_TABLE_ELEMENT: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          [action.payload.table]: {
            ...state[action.payload.entity][action.payload.table],
            checkedRecords: (state[action.payload.entity][action.payload.table]
              .checkedRecords as (
              | TeamsPolicyUserInfo
              | TeamsPolicyGroup
            )[]).concat(action.payload.records)
          }
        }
      }
    }
    case AssignActionTypes.DESELECT_TABLE_ELEMENT: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          [action.payload.table]: {
            ...state[action.payload.entity][action.payload.table],
            checkedRecords: (state[action.payload.entity][action.payload.table]
              .checkedRecords as (TeamsPolicyUserInfo &
              TeamsPolicyGroup)[]).filter(
              record => !action.payload.records.includes(record)
            )
          }
        }
      }
    }
    case AssignActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          [action.payload.table]: {
            ...state[action.payload.entity][action.payload.table],
            checkedRecords: action.payload.records
          }
        }
      }
    }
    case AssignActionTypes.SET_AVAILABLE_ENTITY_LOADING: {
      return {
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          isLoading: action.payload.isLoading
        }
      }
    }
    case AssignActionTypes.SET_AVAILABLE_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          available: {
            ...state.users.available,
            results: action.payload.users,
            totalResults: action.payload.users.length
          }
        }
      }
    }
    case AssignActionTypes.SET_AVAILABLE_GROUPS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          available: {
            ...state.groups.available,
            results: action.payload.groups,
            totalResults: action.payload.groups.length
          }
        }
      }
    }
    case AssignActionTypes.ASSIGN: {
      return {
        ...state,
        users: {
          ...state.users,
          list: {
            ...state.users.list,
            results: (state.users.list.results as TeamsPolicyUserInfo[]).concat(
              state.users.available.checkedRecords
            ),
            totalResults:
              state.users.list.results.length +
              state.users.available.checkedRecords.length
          },
          filtered: {
            ...state.users.filtered,
            results: (state.users.filtered
              .results as TeamsPolicyUserInfo[]).concat(
              state.users.available.checkedRecords
            ),
            totalResults:
              state.users.filtered.results.length +
              state.users.available.checkedRecords.length
          },
          available: {
            ...state.users.available,
            checkedRecords: []
          }
        },
        groups: {
          ...state.groups,
          list: {
            ...state.groups.list,
            results: (state.groups.list.results as TeamsPolicyGroup[]).concat(
              state.groups.available.checkedRecords
            ),
            totalResults:
              state.groups.list.results.length +
              state.groups.available.checkedRecords.length
          },
          filtered: {
            ...state.groups.filtered,
            results: (state.groups.filtered
              .results as TeamsPolicyGroup[]).concat(
              state.groups.available.checkedRecords
            ),
            totalResults:
              state.groups.filtered.results.length +
              state.groups.available.checkedRecords.length
          },
          available: {
            ...state.groups.available,
            checkedRecords: []
          }
        }
      }
    }
    case AssignActionTypes.UNASSIGN: {
      return {
        ...state,
        users: {
          ...state.users,
          list: {
            ...state.users.list,
            results: state.users.list.results.filter(
              user =>
                !(state.users.filtered
                  .checkedRecords as TeamsPolicyUserInfo[]).includes(user)
            ),
            checkedRecords: state.users.list.checkedRecords.filter(
              user =>
                !(state.users.filtered
                  .checkedRecords as TeamsPolicyUserInfo[]).includes(user)
            ),
            totalResults:
              state.users.list.totalResults -
              state.users.filtered.checkedRecords.length
          },
          filtered: {
            ...state.users.filtered,
            results: state.users.filtered.results.filter(
              user =>
                !(state.users.filtered
                  .checkedRecords as TeamsPolicyUserInfo[]).includes(user)
            ),
            checkedRecords: state.users.list.checkedRecords.filter(
              user =>
                !(state.users.filtered
                  .checkedRecords as TeamsPolicyUserInfo[]).includes(user)
            ),
            totalResults:
              state.users.filtered.totalResults -
              state.users.filtered.checkedRecords.length
          }
        },
        groups: {
          ...state.groups,
          list: {
            ...state.groups.list,
            results: state.groups.list.results.filter(
              group =>
                !(state.groups.filtered
                  .checkedRecords as TeamsPolicyGroup[]).includes(group)
            ),
            checkedRecords: state.groups.list.checkedRecords.filter(
              group =>
                !(state.groups.filtered
                  .checkedRecords as TeamsPolicyGroup[]).includes(group)
            ),
            totalResults:
              state.groups.list.totalResults -
              state.groups.filtered.checkedRecords.length
          },
          filtered: {
            ...state.groups.filtered,
            results: state.groups.filtered.results.filter(
              group =>
                !(state.groups.filtered
                  .checkedRecords as TeamsPolicyGroup[]).includes(group)
            ),
            checkedRecords: state.groups.list.checkedRecords.filter(
              group =>
                !(state.groups.filtered
                  .checkedRecords as TeamsPolicyGroup[]).includes(group)
            ),
            totalResults:
              state.groups.filtered.totalResults -
              state.groups.filtered.checkedRecords.length
          }
        }
      }
    }
    case AssignActionTypes.RESET: {
      return initialState
    }
    case AssignActionTypes.RESET_TABLES: {
      return {
        ...state,
        users: {
          ...state.users,
          list: defaultTable,
          filtered: defaultTable,
          available: defaultTable
        },
        groups: {
          ...state.groups,
          list: defaultTable,
          filtered: defaultTable,
          available: defaultTable
        }
      }
    }
    case AssignActionTypes.RESET_SEARCH_INPUT: {
      return {
        ...state,
        [action.payload.entityType]: {
          ...state[action.payload.entityType],
          searchInput: initialState[action.payload.entityType].searchInput
        }
      }
    }
    case AssignActionTypes.SET_INITIAL_ASSIGNED_ITEMS: {
      return {
        ...state,
        users: {
          ...state.users,
          list: {
            ...state.users.list,
            results: action.payload.users,
            totalResults: action.payload.users.length
          },
          filtered: {
            ...state.users.filtered,
            results: action.payload.users,
            totalResults: action.payload.users.length
          }
        },
        groups: {
          ...state.groups,
          list: {
            ...state.groups.list,
            results: action.payload.groups,
            totalResults: action.payload.groups.length
          },
          filtered: {
            ...state.groups.filtered,
            results: action.payload.groups,
            totalResults: action.payload.groups.length
          }
        }
      }
    }
  }
  return state
}

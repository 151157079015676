import React from 'react'

import styled from '@emotion/styled'

import { CardContainer, DetailCell, DetailsContainer } from '@lastpass/lastkit'

interface Field {
  id: string
  name: string
  value: string
}

interface AuditLogDetailsProps {
  fields: Field[]
}

const Details = styled.div`
  width: 100%;
  padding: 16px;
`

export const AuditLogDetails: React.FunctionComponent<AuditLogDetailsProps> = ({
  fields
}) => {
  return (
    <CardContainer>
      <Details data-qa="AuditLogDetails">
        <DetailsContainer>
          {fields.map(field => (
            <DetailCell key={field.id} field={field.name} value={field.value} />
          ))}
        </DetailsContainer>
      </Details>
    </CardContainer>
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { FilterBadge } from '@lastpass/lastkit'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { GroupFilterState } from '@lastpass/admin-console-dependencies/state/common/group-filter/state'
import { UserFilterState } from '@lastpass/admin-console-dependencies/state/common/user-filter/state'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import { AdvancedUserActivityFilter } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'
import { createEventMappings } from '@lastpass/admin-console-dependencies/ui/reports/view/filter/components/SelectEvents'

export const AdvancedUserActivityFiltersRow: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const updateQuery = useUpdateQuery(Namespace.userReports)
  const queryParams = useQueryParams(Namespace.userReports)

  const { idMapping: userMapping } = useSelector<AppState, UserFilterState>(
    state => state.userFilter
  )
  const { idMapping: groupMapping } = useSelector<AppState, GroupFilterState>(
    state => state.groupFilter
  )
  const {
    eventTypes: { userEvents, adminEvents }
  } = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )
  const { userEventMapping, adminEventMapping } = createEventMappings(
    userEvents,
    adminEvents
  )

  const reportFilter: AdvancedUserActivityFilter = queryParams.filter

  if (!reportFilter) {
    return null
  }

  const removeBadge = <T extends keyof AdvancedUserActivityFilter>(
    arrayName: T,
    badgeId: string
  ) => {
    const array = reportFilter[arrayName]
    const filteredArray = array ? array.filter(id => id !== badgeId) : []
    const isLastBadgeToRemove = Object.values(reportFilter).flat().length === 1

    isLastBadgeToRemove
      ? history.push({
          pathname: window.location.pathname
        })
      : updateQuery({
          filter: {
            ...reportFilter,
            [arrayName]: filteredArray.length ? filteredArray : undefined
          }
        })

    dispatch(advancedUserActivityActions.removeSelectedSavedReport())
  }

  return (
    <div
      data-qa="ReportFilterRow"
      css={css`
        margin: -16px 0 15px 25px;
      `}
    >
      {Object.keys(reportFilter).map(arrayName =>
        reportFilter[arrayName].map(id => (
          <>
            {arrayName === 'user' && userMapping[id] && (
              <FilterBadge
                key={id}
                removeBadge={() => removeBadge(arrayName, id)}
              >
                <Trans>User: {userMapping[id]}</Trans>
              </FilterBadge>
            )}

            {arrayName === 'group' && groupMapping[id] && (
              <FilterBadge
                key={id}
                removeBadge={() => removeBadge(arrayName, id)}
              >
                <Trans>Group: {groupMapping[id]}</Trans>
              </FilterBadge>
            )}

            {arrayName === 'userEvents' && userEventMapping[id] && (
              <FilterBadge
                key={id}
                removeBadge={() => removeBadge(arrayName, id)}
              >
                <Trans>User event: {userEventMapping[id]}</Trans>
              </FilterBadge>
            )}

            {arrayName === 'adminEvents' && adminEventMapping[id] && (
              <FilterBadge
                key={id}
                removeBadge={() => removeBadge(arrayName, id)}
              >
                <Trans>Admin event: {adminEventMapping[id]}</Trans>
              </FilterBadge>
            )}
          </>
        ))
      )}
    </div>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as PingIdentityIcon } from '@lastpass/assets/svg/admin-console/directories/icon-pingidentity.svg'
import { Loading } from '@lastpass/components/Loading'
import { LogoCard } from '@lastpass/lastkit'
import { Heading100 } from '@lastpass/lastkit/components/Heading'

import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { FederatedLoginState } from '@lastpass/admin-console-dependencies/state/users/federated-login/state'
import { OpenIdProviderConfigurationMessage } from '@lastpass/admin-console-dependencies/ui/users/federated-login/OpenIdProviderConfigurationMessage'

import { AdConnectorForm } from './AdConnectorForm'
import { AdSetupForm } from './AdSetupForm'
import { PingFederateDownloadBlock } from './PingFederateDownloadBlock'
import {
  BoldWrapper,
  StyledCardContainer,
  StyledChildRow,
  StyledExternalLink,
  StyledSubtitle
} from './StyledComponents'

export const PingFederateSetupCard: FunctionComponent = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(federatedLoginActions.getAdfsSetup())
    dispatch(federatedLoginActions.getAdAttributeName())
  }, [dispatch])

  const state = useSelector<AppState, FederatedLoginState>(
    appState => appState.federatedLogin
  )

  const isPingFederate = state.adfs.isPingFederate

  return (
    <LogoCard
      dataQaName="PingFederateCard"
      logo={<PingIdentityIcon />}
      highlighted={state.adfs.isEnabled && state.adfs.isPingFederate}
      title={
        <Heading100>
          <Trans>PingFederate</Trans>
        </Heading100>
      }
      subtitle={
        <Trans>
          Provision users to LastPass from Active Directory (AD). They{"'"}ll
          log in to LastPass with their AD credentials instead of their master
          password.
        </Trans>
      }
    >
      {state.loading ? (
        <Loading color="blue900" active={true} />
      ) : (
        <StyledCardContainer>
          <StyledChildRow>
            <BoldWrapper>
              <Trans>Configure PingFederate</Trans>
            </BoldWrapper>
            <StyledExternalLink
              data-qa="PingFederateInstructionsLink"
              href="http://link.lastpass.com/help-federated-pingfederate"
              target="_blank"
            >
              <Trans>Follow these instructions</Trans>
            </StyledExternalLink>
          </StyledChildRow>
          <StyledSubtitle>
            <Trans>
              To configure federated login on your organization, log in to your
              PingFederate server and copy the relevant details to the form
              below.
            </Trans>
          </StyledSubtitle>
          {(!state.adfs.isPingFederate && state.adfs.isEnabled) ||
          state.openId.isEnabled ? (
            <div data-qa="IdpAlreadyConfiguredMessageOnPingFederate">
              <OpenIdProviderConfigurationMessage provider="PingFederate" />
            </div>
          ) : (
            <>
              <AdSetupForm
                key={`adfs ${state.adfs.providerUrl} - ${state.adfs.publicKey} - ${state.adfs.isEnabled}`}
                initialValues={{
                  providerUrl: isPingFederate ? state.adfs.providerUrl : '',
                  publicKey: isPingFederate ? state.adfs.publicKey : '',
                  isEnabled: isPingFederate ? state.adfs.isEnabled : false,
                  useCompanyWideKey: isPingFederate
                    ? state.adfs.useCompanyWideKey
                    : true,
                  providerLoginUrl: isPingFederate
                    ? state.adfs.providerLoginUrl
                    : '',
                  isPingFederate: true
                }}
                dataQaName="PingFederate"
              />
              {state.adAttributeVisible && (
                <AdConnectorForm
                  key={`adconnect ${state.adAttribute.adAttributeName}`}
                  customAttribute={
                    isPingFederate ? state.adAttribute.adAttributeName : ''
                  }
                  dataQaName="PingFederate"
                />
              )}
              <PingFederateDownloadBlock
                title={msg`LastPass custom data store`}
                textForPingFederate103Link={msg`Download for PingFederate Server v10.3`}
                textForPingFederate110Link={msg`Download for PingFederate Server v11.0`}
                dataQaName="PingFederate"
              />
            </>
          )}
        </StyledCardContainer>
      )}
    </LogoCard>
  )
}

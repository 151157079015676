import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    key: string,
    name: string,
    mappingKey: string,
    allUser: boolean,
    userIds: string[],
    groupIds: string[]
  ) {
    try {
      const [, status] = await fetch(
        'company/mfaapps',
        'PUT',
        {
          ['integrationKey']: key,
          ['applicationName']: name,
          ['mappingKey']: mappingKey,
          ['allUser']: allUser,
          ['userIds']: userIds,
          ['groupIds']: groupIds
        },
        { ['content-type']: 'application/json' }
      )
      if (status === StatusCodes.OK) {
        return success()
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React, { useContext, useRef } from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'
import { RouteFade } from '@lastpass/routing/animations'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { createAccountDetailsCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/AccountDetailsCard'
import { AccountDetailsFull } from '@lastpass/admin-console-dependencies/ui/users/view/cards/AccountDetailsFull'
import { createUserAppsSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserAppsSummaryCard'
import { createUserGroupsSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserGroupsSummaryCard'
import { createUserPoliciesSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserPoliciesSummaryCard'
import { createUserProfileCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserProfileCard'
import { createUserSharedFoldersSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserSharedFoldersSummaryCard'
import { UserSitesSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserSitesSummaryCard'
import { createUserTrustedDevicesSummaryCard } from '@lastpass/admin-console-dependencies/ui/users/view/cards/UserTrustedDevicesSummaryCard'
import { createEditLpDeviceDrawerPage } from '@lastpass/admin-console-dependencies/ui/users/view/edit/EditLpDevice'
import { EditProfileDrawerPage } from '@lastpass/admin-console-dependencies/ui/users/view/edit/EditProfile'
import { createUserDrawerAppsTable } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerAppsTable'
import { createUserDrawerGroupsTable } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerGroupsTable'
import { UserDrawerHome } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerHome'
import { createUserDrawerPoliciesTable } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerPoliciesTable'
import { createUserDrawerSharedFoldersTable } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerSharedFoldersTable'
import { createUserDrawerSitesTable } from '@lastpass/admin-console-dependencies/ui/users/view/UserDrawerSitesTable'

import { UserDrawerTrustedDevicesPage } from './trusteddevices/UserDrawerTrustedDevicesPage'

const UserProfileCard = createUserProfileCard<AppState>()
const AccountDetailsCard = createAccountDetailsCard<AppState>()
const UserGroupsSummaryCard = createUserGroupsSummaryCard<AppState>()
const UserAppsSummaryCard = createUserAppsSummaryCard<AppState>()
const UserSharedFoldersSummaryCard = createUserSharedFoldersSummaryCard<
  AppState
>()
const UserDrawerSharedFoldersTable = createUserDrawerSharedFoldersTable<
  AppState
>()
const UserPoliciesSummaryCard = createUserPoliciesSummaryCard<AppState>()
const UserDrawerGroupsTable = createUserDrawerGroupsTable<AppState>()
const UserDrawerAppsTable = createUserDrawerAppsTable<AppState>()
const UserDrawerPoliciesTable = createUserDrawerPoliciesTable<AppState>()
const UserDrawerSitesTable = createUserDrawerSitesTable<AppState>()
const EditTrustedLpDevices = createEditLpDeviceDrawerPage()
const UserTrustedDevicesSummaryCard = createUserTrustedDevicesSummaryCard<
  AppState
>()

enum expansionCards {
  profile = 'profile',
  accountDetails = 'accountDetails',
  groups = 'groups',
  apps = 'apps',
  sites = 'sites',
  sharedFolders = 'sharedFolders',
  trustedDevices = 'trustedDevices',
  policies = 'policies'
}

export const UsersViewDrawerPage: React.FunctionComponent = () => {
  const match = useRouteMatch()
  const expand = {
    [expansionCards.profile]: true,
    [expansionCards.accountDetails]: false,
    [expansionCards.groups]: false,
    [expansionCards.apps]: false,
    [expansionCards.sites]: false,
    [expansionCards.sharedFolders]: false,
    [expansionCards.trustedDevices]: false,
    [expansionCards.policies]: false
  }

  const expandRef = useRef(expand)
  const onExpand = (expandProp: expansionCards) => () => {
    expandRef.current[expandProp] = !expandRef.current[expandProp]
  }

  const permissions = useContext(PermissionContext)

  const canModifyUsers = permissions.requirePermission(
    AdminPermissions.usersModify
  )
  const canModifyPolicies = permissions.requirePermission(
    AdminPermissions.policiesModify
  )
  const canModifySharedFolders = permissions.requirePermission(
    AdminPermissions.sharedFoldersModify
  )
  const canModifySsoApps = permissions.requirePermission(
    AdminPermissions.ssoModify
  )
  const canViewTrustedDevices = permissions.requirePermission(
    AdminPermissions.userLevelMfaModify
  )
  const canViewUsersSites = permissions.requirePermission(
    AdminPermissions.usersSitesView
  )
  if (match) {
    const { url, path } = match
    return (
      <AnimatedSwitch timeout={200}>
        <Route path={path} exact>
          <RouteFade>
            <UserDrawerHome>
              <UserProfileCard
                editLink={`${url}/profile`}
                expand={expandRef.current[expansionCards.profile]}
                onExpand={onExpand(expansionCards.profile)}
                showEditButton={canModifyUsers}
              />
              <AccountDetailsCard
                viewFullLink={`${url}/accountDetails`}
                expand={expandRef.current[expansionCards.accountDetails]}
                onExpand={onExpand(expansionCards.accountDetails)}
              />
              <UserGroupsSummaryCard
                detailLink={`${url}/groups`}
                groupLink={'/users/groups'}
                expand={expandRef.current[expansionCards.groups]}
                onExpand={onExpand(expansionCards.groups)}
              />
              {canModifySsoApps && (
                <UserAppsSummaryCard
                  detailLink={`${url}/apps`}
                  appLink={'/applications/saml'}
                  expand={expandRef.current[expansionCards.apps]}
                  onExpand={onExpand(expansionCards.apps)}
                />
              )}
              {canViewUsersSites && <UserSitesSummaryCard />}
              {canModifySharedFolders && (
                <UserSharedFoldersSummaryCard
                  detailLink={`${url}/shared-folders`}
                  sharedFoldersLink={'/applications/sharedfolder'}
                  expand={expandRef.current[expansionCards.sharedFolders]}
                  onExpand={onExpand(expansionCards.sharedFolders)}
                />
              )}
              {canViewTrustedDevices && (
                <UserTrustedDevicesSummaryCard
                  detailLink={`${url}/trusted-devices`}
                  trustedDevicesLink={'/trusted-devices'}
                  expand={expandRef.current[expansionCards.trustedDevices]}
                  onExpand={onExpand(expansionCards.trustedDevices)}
                />
              )}

              {canModifyPolicies && (
                <UserPoliciesSummaryCard
                  detailLink={`${url}/policies`}
                  policyLink={'/policies/generalpolicies'}
                  expand={expandRef.current[expansionCards.policies]}
                  onExpand={onExpand(expansionCards.policies)}
                />
              )}
            </UserDrawerHome>
          </RouteFade>
        </Route>
        <Route path={`${path}/apps`}>
          <RouteFade>
            <UserDrawerAppsTable />
          </RouteFade>
        </Route>
        <Route path={`${path}/groups`}>
          <RouteFade>
            <UserDrawerGroupsTable />
          </RouteFade>
        </Route>
        {canModifyUsers && (
          <Route path={`${path}/sites`}>
            <RouteFade>
              <UserDrawerSitesTable />
            </RouteFade>
          </Route>
        )}
        <Route path={`${path}/trusted-devices/password-manager/:deviceId`}>
          <RouteFade>
            <EditTrustedLpDevices backPath={`${url}/trusted-devices`} />
          </RouteFade>
        </Route>
        <Route
          path={[
            `${path}/trusted-devices`,
            `${path}/trusted-devices/mfa`,
            `${path}/trusted-devices/workstation`
          ]}
        >
          <RouteFade>
            <UserDrawerTrustedDevicesPage />
          </RouteFade>
        </Route>
        <Route path={`${path}/shared-folders`}>
          <RouteFade>
            <UserDrawerSharedFoldersTable />
          </RouteFade>
        </Route>
        <Route path={`${path}/policies`}>
          <RouteFade>
            <UserDrawerPoliciesTable />
          </RouteFade>
        </Route>
        <Route path={`${path}/accountDetails`}>
          <RouteFade>
            <AccountDetailsFull />
          </RouteFade>
        </Route>
        {canModifyUsers && (
          <Route path={`${path}/profile`}>
            <RouteFade>
              <EditProfileDrawerPage />
            </RouteFade>
          </Route>
        )}
        <Route>
          <Redirect to={url} />
        </Route>
      </AnimatedSwitch>
    )
  }
  return null
}

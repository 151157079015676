import React from 'react'

import { Checkbox } from '@lastpass/lastkit'

import { CheckBoxContainer, PartialSyncItemContainer } from './StyledComponents'

interface GoogleGroupProps {
  children?: React.ReactNode
  handleGroupSelection: (checked: boolean) => void
  isSelected: boolean
  isIndeterminate: boolean
  name: string
}

export const GoogleGroup: React.FunctionComponent<GoogleGroupProps> = ({
  children,
  handleGroupSelection,
  isSelected,
  isIndeterminate,
  name
}) => {
  return (
    <PartialSyncItemContainer data-qa="RecursiveGroupItemContainer">
      <CheckBoxContainer>
        <Checkbox
          data-qa="Checkbox"
          onChange={e => {
            handleGroupSelection(e.target.checked)
          }}
          checked={!isIndeterminate ? isSelected : undefined}
          indeterminate={isIndeterminate}
        />
      </CheckBoxContainer>
      {children}
      {name}
    </PartialSyncItemContainer>
  )
}

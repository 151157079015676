import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import {
  BodyRegular,
  Heading100,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import {
  BodyText,
  BodyTitle,
  StyledAppIcon
} from '@lastpass/admin-console-dependencies//ui/applications/saml/add/drawerSteps/ConfigureApp'
import {
  applicationsDrawerActions,
  applicationsDrawerSegmentActions
} from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import { CurrentUser } from '@lastpass/admin-console-dependencies/types/current-user'
import {
  RadioContainer,
  StyledRadioInput,
  StyledRadioLabel
} from '@lastpass/admin-console-dependencies/ui/common/DrawerAssign/AppliesToRadioButtons'
import { EnumDictionary } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'

const TopBodyText = styled.div`
  margin-bottom: 24px;
`
const Bold = styled.span`
  font-weight: ${props => props.theme.fonts.weight.bold};
`

export enum AssignTestUserOptions {
  assignMyUser = 'assignMyUser',
  assignOtherUsers = 'assignOtherUsers'
}

export interface AssignTestUserProps {
  closeLink: string
}

export const AssignTestUserComponent: FunctionComponent<AssignTestUserProps> = props => {
  const state = useSelector<AppState, ApplicationDrawerState>(
    state => state.applicationDrawer
  )

  const history = useHistory()
  const appName = state.selectedApplication
    ? state.selectedApplication.applicationName
    : ''

  const appLogo = state.selectedApplication &&
    state.selectedApplication.customLogoUrl && (
      <StyledAppIcon
        data-qa={'AppLogo'}
        src={state.selectedApplication.customLogoUrl}
      />
    )
  const currentUser = useSelector<AppState, CurrentUser>(
    state => state.global.currentUser
  )
  const queryParams = useQueryParams(Namespace.users)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      applicationsDrawerActions.getUserList({ query: queryParams, path: {} })
    )
    dispatch(applicationsDrawerSegmentActions.assignTestUserViewed(appName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AssignTestUserMapping: EnumDictionary<
    AssignTestUserOptions,
    ReactElement
  > = {
    [AssignTestUserOptions.assignMyUser]: (
      <>
        <Trans>Assign my user: </Trans> <Bold>{currentUser.email}</Bold>
      </>
    ),
    [AssignTestUserOptions.assignOtherUsers]: (
      <Trans>Assign other users & groups</Trans>
    )
  }

  const assignTestUserOptions: AssignTestUserOptions[] = [
    AssignTestUserOptions.assignMyUser,
    AssignTestUserOptions.assignOtherUsers
  ]

  const onDiscardClicked = (assignmentChoice: AssignTestUserOptions) => {
    switch (assignmentChoice) {
      case AssignTestUserOptions.assignOtherUsers:
        dispatch(
          applicationsDrawerSegmentActions.assignTestUserClicked(
            appName,
            'Assign other users',
            'Discard'
          )
        )
        break
      case AssignTestUserOptions.assignMyUser:
      default:
        dispatch(
          applicationsDrawerSegmentActions.assignTestUserClicked(
            appName,
            'Assign my user',
            'Discard'
          )
        )
        break
    }
    history.push(props.closeLink)
  }

  const onContinueClicked = (assignmentChoice: AssignTestUserOptions) => {
    switch (assignmentChoice) {
      case AssignTestUserOptions.assignOtherUsers:
        dispatch(
          applicationsDrawerSegmentActions.assignTestUserClicked(
            appName,
            'Assign other users',
            'Continue'
          )
        )
        dispatch(
          applicationsDrawerActions.nextStep(ApplicationDrawerSteps.assign)
        )
        break
      case AssignTestUserOptions.assignMyUser:
      default:
        if (state.selectedApplication) {
          const currentUserData = state.availableUsers.results.filter(
            user => user.name == currentUser.email
          )
          dispatch(
            applicationsDrawerSegmentActions.assignTestUserClicked(
              appName,
              'Assign my user',
              'Continue'
            )
          )
          dispatch(
            applicationsDrawerSegmentActions.saveAndAssignUsers(
              state.selectedApplication,
              currentUserData,
              state.groups.results,
              state.roles.results
            )
          )
        }
        break
    }
  }

  return (
    <>
      <BodyTitle>
        {appLogo}
        <Heading100 data-qa="AssignTestUserAppName">{appName}</Heading100>
      </BodyTitle>
      <BodyRegular>
        <BodyText>
          <TopBodyText data-qa="AssingTestUserTopText">
            <Trans>
              Only assigned users will have access to this app. We recommend
              assigning your own user first. You can assign more users later
              once you’ve made sure everything’s fine.
            </Trans>
          </TopBodyText>
        </BodyText>
      </BodyRegular>
      <Formik
        initialValues={{
          selectedAssignTestUserOption: AssignTestUserOptions.assignMyUser
        }}
        onSubmit={values =>
          onContinueClicked(values.selectedAssignTestUserOption)
        }
      >
        {({ values }) => (
          <Form data-qa="AssignTestUserForm">
            <RadioContainer role="group">
              {assignTestUserOptions.map(option => (
                <StyledRadioLabel key={option}>
                  <StyledRadioInput
                    type="radio"
                    name="selectedAssignTestUserOption"
                    value={option}
                    checked={values.selectedAssignTestUserOption === option}
                    data-qa={option}
                  />
                  {AssignTestUserMapping[option]}
                </StyledRadioLabel>
              ))}
            </RadioContainer>

            <StyledFormFooter>
              <ButtonsContainer>
                <TextButton
                  css={css`
                    margin-right: 16px;
                  `}
                  key="Discard"
                  type={'button'}
                  onClick={() =>
                    onDiscardClicked(values.selectedAssignTestUserOption)
                  }
                  data-qa="DiscardButton"
                >
                  <Trans>Discard</Trans>
                </TextButton>
                <PrimaryButton
                  id="ContinueButton"
                  key="Continue"
                  type="submit"
                  disabled={false}
                  data-qa="SaveAndContinueButton"
                >
                  <Trans>Save & continue</Trans>
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  GeneratedSecurityReportResponse,
  GetGeneratedReports
} from '@lastpass/admin-console-dependencies/types/generated-security-reports'

export const SUCCESS = 'success'

const success = (response: GeneratedSecurityReportResponse) =>
  createResponse(SUCCESS, response)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch): GetGeneratedReports {
  return async function service() {
    const [response, status] = await fetch(
      'users/vault-shared-folder-url-reports',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const parsedResponse: GeneratedSecurityReportResponse = JSON.parse(
        response
      ).content
      return success(parsedResponse)
    }
    throw new UnknownServiceError()
  }
}

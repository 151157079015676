import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { invokeApi } from './operation-base'

export function createAcceptUserSaga(userService: UACServices.Services) {
  return function* acceptUserSaga(
    action: ReturnType<typeof userOperationActions.acceptUsers>
  ) {
    try {
      const users = action.payload.userOperationPayload.users
      let text: MessageDescriptor = msg`Approve the selected users?`

      if (users.length === 1) {
        text = msg`Approve this user? ${users[0].email}`
      }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Approve users`,
        text,
        discardText: msg`Cancel`,
        confirmText: msg`Approve`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      const isAllSelected = action.payload.userOperationPayload.isAllSelected
      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`None of the selected users have been approved.`,
            MultipleUsersSuccess: msg`The selected users have been approved.`,
            SingleUserFail:
              users.length > 0
                ? msg`User not approved: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`User has been approved: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Some users haven't been approved.`
          },
          'approvependinguser',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: isAllSelected
          },
          UserOperationActionTypes.ACCEPT_USERS,
          action.payload.userOperationPayload.filter
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

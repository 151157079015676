import { css } from '@emotion/react'

import { Theme } from '../theme'

const CaptionText = css`
  color: ${Theme.colors.neutral900};
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-line-clamp: 3;
`

export const CaptionBoldStyle = css`
  ${CaptionText}
  font-weight: ${Theme.fonts.weight.bold};
`

export const CaptionSemiboldStyle = css`
  ${CaptionText}
  font-weight: ${Theme.fonts.weight.semibold};
`

export const CaptionRegularStyle = css`
  ${CaptionText}
  font-weight: ${Theme.fonts.weight.regular};
`

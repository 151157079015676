import { useEffect, useState } from 'react'

import {
  AssignmentStatus,
  AvailabilityStatus,
  PsaCompanyContractAssignmentOption
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'

type UseCategorizedAssignmentOptions = (
  assignmentOptions: PsaCompanyContractAssignmentOption[]
) => [
  PsaCompanyContractAssignmentOption[],
  PsaCompanyContractAssignmentOption[],
  PsaCompanyContractAssignmentOption[]
]

export const useCategorizedAssignmentOptions: UseCategorizedAssignmentOptions = assignmentOptions => {
  const [autoSelectedList, setAutoSelectedList] = useState<
    PsaCompanyContractAssignmentOption[]
  >([])
  const [previouslySelectedList, setPreviouslySelectedList] = useState<
    PsaCompanyContractAssignmentOption[]
  >([])
  const [needsSelectionList, setNeedsSelectionList] = useState<
    PsaCompanyContractAssignmentOption[]
  >([])

  useEffect(() => {
    const autoSelectedList: PsaCompanyContractAssignmentOption[] = []
    const previouslySelectedList: PsaCompanyContractAssignmentOption[] = []
    const needsSelectionList: PsaCompanyContractAssignmentOption[] = []

    assignmentOptions.forEach((item: PsaCompanyContractAssignmentOption) => {
      const {
        contracts,
        assignedContractId,
        suggestedContractId,
        assignmentStatus
      } = item.contractAssignment
      const assignedContract = assignedContractId
        ? contracts.find(({ id }) => id === assignedContractId)
        : null

      contracts.sort((a, b) => {
        const name = a.name || ''
        return name.localeCompare(b.name || '')
      })

      if (
        assignmentStatus === AssignmentStatus.EXCLUDED ||
        (assignmentStatus === AssignmentStatus.ASSIGNED &&
          assignedContract &&
          assignedContract.availability === AvailabilityStatus.AVAILABLE)
      ) {
        previouslySelectedList.push(item)
      } else if (
        assignmentStatus === AssignmentStatus.NOT_ASSIGNED &&
        suggestedContractId &&
        !assignedContractId
      ) {
        autoSelectedList.push(item)
      } else {
        needsSelectionList.push(item)
      }
    })

    setAutoSelectedList(autoSelectedList)
    setNeedsSelectionList(needsSelectionList)
    setPreviouslySelectedList(previouslySelectedList)
  }, [assignmentOptions])

  return [autoSelectedList, needsSelectionList, previouslySelectedList]
}

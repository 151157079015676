import React, { FC, ReactElement } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'

import { WithTooltip } from './Tooltip'

export const ChipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.neutral700};
`
export const ChipGroup = styled.div`
  display: flex;
  gap: 16px;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${props => props.theme.colors.grayBorder};
  padding: 4px 12px;
  box-shadow: 0px 1px 2px 0px rgba(29, 48, 73, 0.04),
    0px 2px 2px 0px rgba(29, 48, 73, 0.04),
    0px 0px 2px 0px rgba(29, 48, 73, 0.16);
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.neutral900};
`
const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral500};
  height: 14px;
  width: 14px;
  margin-left: 6px;
`

interface ChipProps {
  tooltip?: ReactElement
  className?: string
  dataQa?: string
}

export const Chip: FC<ChipProps> = ({
  tooltip,
  dataQa,
  children,
  className
}) => {
  return (
    <Container data-qa={dataQa} className={className}>
      {children}
      {tooltip && (
        <WithTooltip tooltip={tooltip} width="290px">
          <StyledHelpIcon data-qa="TooltipIcon" />
        </WithTooltip>
      )}
    </Container>
  )
}

import React, { FunctionComponent, useEffect } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/admin-console/icon-close.svg'
import { ReactComponent as NotificationIcon } from '@lastpass/assets/svg/admin-console/notification-icon.svg'
import { Heading100, PrimaryButton, TextButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { MfaUsageNotificationDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface MfaUsageNotificationDialogProps {
  state: MfaUsageNotificationDialog
  actions: typeof globalActions
}

const transparentBackgroundColor = props => `${props.theme.colors.neutral900}e0`
const Background = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: ${transparentBackgroundColor};
  justify-content: center;
`

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 528px;
  height: 476px;
  left: calc(50% - 528px / 2);
  top: calc(50% - 476px / 2 + 10px);
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(29, 48, 73, 0.16),
    0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08);
  border-radius: 16px;
`
const CloseIconContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
`
const StyledCloseIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.neutral600};

  &:hover {
    color: ${props => props.theme.colors.neutral900};
  }
`
const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const StyledNotificationlIcon = styled(NotificationIcon)`
  margin-top: 64px;
`
const StyledContainer = styled.div`
  margin: 24px;
`
const StyledDivRegular = styled.div`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`
const StyledLink = styled.a`
  font-family: '-apple-system', 'BlinkMacSystemFont', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`
const StyledRowDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  column-gap: 16px;
  margin-top: 24px;
`

const escapeKeyCode = 27

export const MfaUsageNotificationDialogComponent: FunctionComponent<MfaUsageNotificationDialogProps> = ({
  ...props
}) => {
  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === escapeKeyCode) {
        props.actions.discardMfaUsageDialogSegment(
          props.state.mfaDialogContext,
          'Close'
        )
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [props])

  return (
    <Background>
      <Popup onClick={e => e.stopPropagation()} data-qa={'PopUpContainer'}>
        <CloseIconContainer data-qa={'PopUpCloseButton'}>
          <StyledCloseIcon
            data-qa={'CloseIcon'}
            onClick={() =>
              props.actions.discardMfaUsageDialogSegment(
                props.state.mfaDialogContext,
                'Close'
              )
            }
          />
        </CloseIconContainer>
        <StyledCenter>
          <StyledNotificationlIcon />
        </StyledCenter>
        <StyledContainer>
          <StyledDiv>
            <Heading100>
              <Trans>A friendly note about potential charges...</Trans>
            </Heading100>
          </StyledDiv>
          <StyledDivRegular>
            <Trans>
              If you are still using this feature when we generate your next
              invoice, you’ll be charged for the Advanced MFA add-on. For
              detailed information on pricing, refer to your contract.
            </Trans>{' '}
            <StyledLink
              data-qa={'LearnMoreButton'}
              href={'https://link.lastpass.com/msp-advanced-mfa-add-on'}
              target="_blank"
              key={'learnMore'}
              onClick={() =>
                props.actions.discardMfaUsageDialogSegment(
                  props.state.mfaDialogContext,
                  'Learn More'
                )
              }
            >
              <Trans>Learn more</Trans>
            </StyledLink>
          </StyledDivRegular>
          <StyledRowDiv>
            <PrimaryButton
              data-qa={'ContinueButton'}
              key={'confirm'}
              onClick={() =>
                props.actions.confirmMfaUsageDialogSegment(
                  props.state.mfaDialogContext,
                  'Continue'
                )
              }
            >
              <Trans>Continue</Trans>
            </PrimaryButton>
            <TextButton
              data-qa={'CancelButton'}
              key={'discard'}
              onClick={() =>
                props.actions.discardMfaUsageDialogSegment(
                  props.state.mfaDialogContext,
                  'Close'
                )
              }
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </StyledRowDiv>
        </StyledContainer>
      </Popup>
    </Background>
  )
}

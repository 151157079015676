import React, { FunctionComponent, InputHTMLAttributes, useState } from 'react'

import styled from '@emotion/styled'

import { ReactComponent as Check } from '@lastpass/assets/svg/admin-console/icon-check.svg'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { ReactComponent as Indeterminate } from '@lastpass/assets/svg/admin-console/icon-indeterminate.svg'

import { BodyRegularStyle } from '../styles'
import { WithTooltip } from './Tooltip'

export interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  focus?: boolean
  indeterminate?: boolean
  controlled?: boolean
  toolTip?: string
  toolTipWithoutHelpIcon?: string
}

const CheckBoxContainer = styled.div<{ hasMargin?: boolean }>`
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  width: 16px;
  height: 16px;
  margin-right: ${props => (props.hasMargin ? '8px' : 'auto')};
`

const StyledLabel = styled.label`
  ${BodyRegularStyle}
  cursor: pointer;
  user-select: none;
`

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  border: 0;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  white-space: nowrap;
  cursor: pointer;
`

const IconWrapper = styled.div<{ error?: boolean }>`
  padding: ${props => (props.error ? '0px' : '1px')};
  color: ${props => props.theme.colors.neutral};
`

const CheckedWrapper = styled(IconWrapper)<{ checked?: boolean }>`
  display: ${props => (props.checked ? 'block' : 'none')};
`

const IndeterminateWrapper = styled(IconWrapper)<{ indeterminate?: boolean }>`
  display: ${props => (props.indeterminate ? 'block' : 'none')};
`

const StyledCheck = styled(Check)`
  display: block;
  margin: auto;
`

const StyledIndeterminate = styled(Indeterminate)`
  display: block;
  margin: auto;
`

const StyledCheckbox = styled.div<CheckBoxProps>`
  box-sizing: border-box;
  border-radius: ${props => props.theme.radius.pixel4};
  background: ${props =>
    (props.disabled && props.theme.colors.neutral200) ||
    (props.checked && props.theme.colors.blue700) ||
    (props.indeterminate && props.theme.colors.blue700)};
  border: ${props =>
    (props.disabled && '1px solid ' + props.theme.colors.neutral400) ||
    (props.error && '2px solid ' + props.theme.colors.red700) ||
    (props.checked && '1px solid ' + props.theme.colors.blue700) ||
    (props.indeterminate && '1px solid ' + props.theme.colors.blue700) ||
    '1px solid ' + props.theme.colors.neutral400};
  transition: all 150ms;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  box-shadow: ${props =>
    props.focus && '0 0 2px 1px ' + props.theme.colors.blue500};
`
const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 9px;
`

export const Checkbox: FunctionComponent<CheckBoxProps> = (
  props: CheckBoxProps
) => {
  const [buttonState, setbuttonState] = useState(props || false)

  const [focusState, setFocusState] = useState(false)

  React.useEffect(() => {
    setbuttonState(props)
  }, [props])

  const checkbox = (
    <StyledLabel className="check-box-label">
      <CheckBoxContainer
        className="check-box-container"
        hasMargin={!!props.children}
      >
        {!props.controlled && (
          <HiddenCheckbox
            data-qa="CheckBoxHidden"
            type="checkbox"
            onFocus={() => {
              setFocusState(true)
            }}
            onBlur={() => {
              setFocusState(false)
            }}
            onChange={event => {
              {
                props.onChange && props.onChange(event)
              }
              setbuttonState({
                ...buttonState,
                checked: event.target.checked,
                indeterminate: false
              })
            }}
            ref={el => {
              el && (el.indeterminate = buttonState.indeterminate || false)
            }}
            disabled={buttonState.disabled || false}
            checked={buttonState.checked || false}
            value={buttonState.value || undefined}
            name={buttonState.name}
          />
        )}
        <StyledCheckbox {...buttonState} focus={focusState}>
          <CheckedWrapper
            data-qa="CheckBoxChecked"
            checked={buttonState.checked}
            error={buttonState.error}
          >
            <StyledCheck />
          </CheckedWrapper>
          <IndeterminateWrapper
            indeterminate={buttonState.indeterminate}
            error={buttonState.error}
          >
            <StyledIndeterminate />
          </IndeterminateWrapper>
        </StyledCheckbox>
      </CheckBoxContainer>
      {props.children}
    </StyledLabel>
  )

  return (
    <>
      {props.toolTipWithoutHelpIcon && !props.disabled ? (
        <WithTooltip tooltip={props.toolTipWithoutHelpIcon}>
          {checkbox}
        </WithTooltip>
      ) : (
        <>{checkbox}</>
      )}
      {props.toolTip && (
        <WithTooltip tooltip={props.toolTip}>
          <StyledHelpIcon />
        </WithTooltip>
      )}
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import { SecurityReportEventTranslationMap } from '@lastpass/admin-console-dependencies/resources/security-reports/security-report-event-translations'
import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import {
  RiskType,
  SecurityReportEvent
} from '@lastpass/admin-console-dependencies/types/security-report'

export const SUCCESS = 'success'

const success = (events: SecurityReportEvent[], createdDateUtc: string) =>
  createResponse(SUCCESS, { events, createdDateUtc })

export const Responses = {
  success
}

export interface SecurityReportEventsResponse {
  risks: {
    id: RiskType
    users: {
      lastPassId: number
      email: string
      name: string
    }[]
  }[]
  createdDateUtc: string
  totalUsers: number
}

export type Responses = ResponseTypes<typeof Responses>

const getFormattedAffectedUsersPercentage = (
  numberOfUsers: number,
  totalNumberOfUsers: number
): string =>
  !totalNumberOfUsers
    ? '0'
    : ((numberOfUsers / totalNumberOfUsers) * 100).toFixed(2)

const createSecurityReportEvents = (
  risks: SecurityReportEventsResponse['risks'],
  totalNumberOfUsers,
  securityReportEventTranslationMap: SecurityReportEventTranslationMap
): SecurityReportEvent[] =>
  risks.map(({ id, users }) => {
    return {
      id,
      riskName: securityReportEventTranslationMap[id].name,
      usersAffected: users,
      numberOfUsersAffected: users.length,
      percentageOfUsersAffected: getFormattedAffectedUsersPercentage(
        users.length,
        totalNumberOfUsers
      )
    }
  })

export function create(fetch: AuthFetch) {
  return async function service(
    isWeakSecurityScoreEnabled: boolean,
    isDWMSecurityReportEnabled: boolean,
    securityReportEventTranslationMap: SecurityReportEventTranslationMap
  ) {
    try {
      const [response, status] = await fetch('company/security-report', 'GET')
      if (status === StatusCodes.OK && response) {
        const {
          risks,
          totalUsers,
          createdDateUtc
        }: SecurityReportEventsResponse = JSON.parse(response).content

        const availableRisks = risks.filter(risk => {
          const shouldFilterDarkWebMonitoringAlert =
            !isDWMSecurityReportEnabled &&
            risk.id === RiskType.darkWebMonitoringAlert

          const shouldFilterWeakSecurityScore =
            !isWeakSecurityScoreEnabled &&
            risk.id === RiskType.weakSecurityScore

          return shouldFilterDarkWebMonitoringAlert ||
            shouldFilterWeakSecurityScore
            ? false
            : securityReportEventTranslationMap[risk.id]
        })

        const securityReportEvents: SecurityReportEvent[] = createSecurityReportEvents(
          availableRisks,
          totalUsers,
          securityReportEventTranslationMap
        )

        return success(securityReportEvents, createdDateUtc)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

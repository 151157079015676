import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading100 } from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'
import { CodeExample } from '@lastpass/lastkit/components/CodeExample'
import { NumberedList } from '@lastpass/lastkit/components/NumberedList'
import { BodyRegularStyle, BodySemiboldStyle } from '@lastpass/lastkit/styles'

const TextContent = styled.div`
  ${BodyRegularStyle};
`

const StyledTextContent = styled(TextContent)`
  white-space: nowrap;
  margin-bottom: 5px;
`

const PageContentContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 18px;
  max-width: 760px;
`

const PageItemContainer = styled(Heading100)`
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 0 24px;
`

const StyledList = styled.ul`
  list-style-type: disc;
  margin: 15px 0 20px 20px;
`

const Title = styled.div`
  ${BodySemiboldStyle};
  margin-bottom: 8px;
`

export const MacSilentPageContainer: React.FunctionComponent = () => {
  const deployMacApp = 'https://link.lastpass.com/deploy-mac-app'
  return (
    <>
      <PageItemContainer data-qa={'PageItemContainerMacOsSilentInstall'}>
        <Trans>How to silently install LastPass</Trans>
      </PageItemContainer>
      <PageContentContainer>
        <Title>
          <Trans> For Safari users, install the LastPass Mac App:</Trans>
        </Title>
        <StyledTextContent>
          <StyledList>
            <li>
              <Trans>
                Follow the instructions for deploying the LastPass Mac App using
                Apple Business Manager:{'  '}
              </Trans>
              <br />
              <a href={deployMacApp} target="_blank" rel="noopener noreferrer">
                {deployMacApp}
              </a>
            </li>
          </StyledList>
        </StyledTextContent>
        <Title>
          <Trans>For Chrome users, install the LastPass extension:</Trans>
        </Title>
        <StyledTextContent>
          <NumberedList
            items={[
              <Trans key="1">
                Safari installer installs the latest LastPass macOS application.
              </Trans>,
              <Trans key="2">
                Google Chrome extension installer needs administrator rights to
                access Chrome{"'"}s install directory.
              </Trans>,
              <Trans key="3">
                Firefox extension installer needs Python version 2.7 or greater.
              </Trans>
            ]}
          />
          <CodeExample
            code={
              'curl -s "https://download.cloud.lastpass.com/mac/installer/chrome.sh" | bash'
            }
            allowCopyToClipboard={true}
          />
        </StyledTextContent>
        <Title>
          <Trans>For Firefox users, install the LastPass extension:</Trans>
        </Title>
        <StyledTextContent>
          <NumberedList
            items={[
              <Trans key="1">
                Make sure the person running the Firefox extension installer has
                Python version 2.7 or greater.
              </Trans>,
              <Trans key="2">Run the following command from a Terminal.</Trans>,
              <Trans key="3">
                Review and approve any additional instructions provided by the
                browser during installation.
              </Trans>
            ]}
          />
          <CodeExample
            code={
              'curl -s "https://download.cloud.lastpass.com/mac/installer/firefox.sh" | bash'
            }
            allowCopyToClipboard={true}
          />
        </StyledTextContent>
      </PageContentContainer>
    </>
  )
}

import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import { DistributeRecoveryKeysState } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 30px;
`

export const RecoveryKeySharingProgressDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  const handleStopping = (): void => {
    dispatch(distributeRecoveryKeysActions.setIsStopped(true))
    dispatch(globalActions.emptyDialog())
    dispatch(globalActions.discardDialog())
  }

  const { totalNumberOfKeys, numberOfSharedKeys, isStopped } = useSelector<
    AppState,
    DistributeRecoveryKeysState
  >(state => state.distributeRecoveryKeys)

  const Content: FunctionComponent = () => (
    <>
      <StyledLoadingContainer>
        <Loading color="blue900" active={true} />
      </StyledLoadingContainer>
      {!isStopped ? (
        <StyledParagraph>
          <Trans>
            {numberOfSharedKeys} / {totalNumberOfKeys} keys shared
          </Trans>
        </StyledParagraph>
      ) : (
        <StyledParagraph>
          <Trans>
            {numberOfSharedKeys} / {totalNumberOfKeys} recovery keys have been
            shared invisibly so far. This can’t be undone.
          </Trans>
        </StyledParagraph>
      )}
    </>
  )

  return (
    <DetailsDialog
      closeDialog={handleStopping}
      disableCloseOnBackground
      width="634px"
      title={
        isStopped
          ? msg`Invisible sharing of recovery keys stopped`
          : msg`Sharing recovery keys invisibly`
      }
      content={<Content />}
      hideCloseIcon={!isStopped}
      buttons={[
        isStopped ? (
          <PrimaryButton
            onClick={() => {
              dispatch(globalActions.emptyDialog())
              dispatch(globalActions.discardDialog())
            }}
            key="confirm"
            data-qa="RecoveryKeySharingConfirmDialogConfirmButton"
          >
            <Trans>OK</Trans>
          </PrimaryButton>
        ) : (
          <PrimaryButton
            onClick={handleStopping}
            key="stop"
            data-qa="RecoveryKeySharingProgressDialogStopButton"
          >
            <Trans>Stop sharing</Trans>
          </PrimaryButton>
        )
      ]}
    />
  )
}

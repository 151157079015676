import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { readFileAsText } from '@lastpass/admin-console-dependencies/services/read-file-as-text'
import { symantecVipSettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/actions'
import {
  SymantecVipSettings,
  SymantecVipSettingsFormData
} from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/symantec-vip-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getSymantecVipSettingsInState = (state: AppState) =>
  state.symantecVipSettings.symantecVipSettings

export function createSaveSymantecVipSettings(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveSymantecVipSettings(
    action: ReturnType<
      typeof symantecVipSettingsActions.saveSymantecVipSettings
    >
  ) {
    try {
      const symantecVipSettingsFormData: SymantecVipSettingsFormData =
        action.payload.symantecVipOptions

      const symantecVipSettingsInState: SymantecVipSettings = yield select(
        getSymantecVipSettingsInState
      )
      const symantecVipSettings: SymantecVipSettings = {
        ...symantecVipSettingsInState,
        ...symantecVipSettingsFormData,
        certificate: ''
      }
      delete symantecVipSettings.certificateName

      if (symantecVipSettingsFormData.certificate) {
        symantecVipSettings.certificate = yield call(
          readFileAsText,
          symantecVipSettingsFormData.certificate
        )
      }

      yield put(symantecVipSettingsActions.setSymantecVipSettingsLoading(true))
      try {
        const symantecVipSettingsResult: UACServices.SymantecVipSettingsSaveAPI.Responses = yield call(
          enterpriseOptionServices.symantecVipSettingsSave,
          symantecVipSettings
        )
        if (
          symantecVipSettingsResult.type ===
          UACServices.SymantecVipSettingsSaveAPI.SUCCESS
        ) {
          yield put(symantecVipSettingsActions.getSymantecVipSettings())
          yield put(
            globalActions.setNotification({
              message: msg`Symantec VIP settings Save Success`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else if (
          symantecVipSettingsResult.type ===
            UACServices.SymantecVipSettingsSaveAPI.ERROR &&
          symantecVipSettingsResult.body
        ) {
          yield put(
            globalActions.setNotification({
              message: symantecVipSettingsResult.body,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`Symantec VIP settings Save Failed`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(
          symantecVipSettingsActions.setSymantecVipSettingsLoading(false)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { msg, Trans } from '@lingui/macro'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { useCalculateChartRateThreshold } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-calculate-chart-rate-threshold'
import { ActionLinkCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionLinkCard'
import { DonutChartWithLegend } from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/DonutChartWithLegend'

export interface ActiveUsageRateDonutChartCardProps {
  activeUsersCount: number
  inactiveUsersCount: number
  activeUsageRate: number
  dataQa: string
}

export const ActiveUsageRateDonutChartCard: FC<ActiveUsageRateDonutChartCardProps> = ({
  activeUsersCount,
  inactiveUsersCount,
  activeUsageRate,
  dataQa
}) => {
  const dispatch = useDispatch()

  const { colors, subtitle } = useCalculateChartRateThreshold(activeUsageRate)

  return (
    <ActionLinkCard
      dataQa={dataQa}
      learnMoreAction={() =>
        window.open(
          'https://support.lastpass.com/help/what-are-the-enrollment-and-utilization-rates',
          '_blank',
          'noopener, noreferrer'
        )
      }
      showHistoryAction={() => {
        dispatch(
          globalActions.setDialog({
            type: 'active-usage-rate-dialog'
          })
        )
      }}
    >
      <DonutChartWithLegend
        totalText={msg`Total enrolled users`}
        items={[
          {
            value: activeUsersCount,
            label: msg`Active users`,
            backgroundColor: colors.primary,
            tooltip: <Trans>Have used LastPass in the last 30 days.</Trans>
          },
          {
            value: inactiveUsersCount,
            label: msg`Inactive users`,
            backgroundColor: colors.tertiary,
            tooltip: <Trans>Haven’t used LastPass in the last 30 days.</Trans>
          }
        ]}
        percentageInfo={{
          value: activeUsageRate,
          subtitle: subtitle
        }}
        dataQa={dataQa && dataQa + 'DonutChart'}
      />
    </ActionLinkCard>
  )
}

import React, { FC } from 'react'

import styled from '@emotion/styled'

const ProgressBarContainer = styled.div`
  height: 36px;
  background-color: #d3def2;
  border-radius: 4px;
  overflow: hidden;
`

const Progress = styled.div<{ progress: number }>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: ${props => props.theme.colors.blue700};
`

export const ProgressBar: FC<{ progress: number }> = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <Progress data-qa="ProgressBar" progress={progress} />
    </ProgressBarContainer>
  )
}

import React, { FunctionComponent } from 'react'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Collapsible } from '@lastpass/lastkit'

import { FeatureDetail } from './FeatureDetail'
import { SystemUpgradeFeatureBox } from './SystemUpgradeFeatureBox'

interface SystemUpgradeReviewRetiredFeaturesStepProps {
  usedFeatures: FeatureDetail[]
  unusedFeatures: FeatureDetail[]
}

const ContentContainer = styled.div`
  margin-top: 20px;
`
const Container = styled.div`
  margin-left: 8px;
`

export const SystemUpgradeReviewRetiredFeaturesStep: FunctionComponent<SystemUpgradeReviewRetiredFeaturesStepProps> = ({
  usedFeatures,
  unusedFeatures
}) => {
  const usedFeaturesCount = usedFeatures.length
  const unusedFeaturesCount = unusedFeatures.length

  return (
    <>
      <Collapsible
        expanded={usedFeaturesCount > 0}
        onExpand={() => {}}
        chevron
        title={<Trans>Used by your organization ({usedFeaturesCount})</Trans>}
        containerCssOverride={css`
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        `}
      >
        <Container>
          <ContentContainer data-qa="UsedFeaturesContainer">
            {usedFeatures.map((feature, index) => (
              <SystemUpgradeFeatureBox
                key={feature.name}
                title={feature.title}
                featureLinkText={feature.featureLinkText}
                featureLinkUrl={feature.featureLinkUrl}
                detailsLinkText={<Trans>Details</Trans>}
                detailsLinkUrl={feature.detailsLinkUrl}
                showBottomBorder={index !== usedFeatures.length - 1}
                textDisabled={false}
                isExternalFeatureLink={feature.isExternalFeatureLink}
              />
            ))}
          </ContentContainer>
        </Container>
      </Collapsible>
      <Collapsible
        expanded={false}
        onExpand={() => {}}
        chevron
        title={
          <Trans>Not used by your organization ({unusedFeaturesCount})</Trans>
        }
        containerCssOverride={css`
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          background: #f9fafb;
        `}
      >
        <Container>
          <ContentContainer data-qa="UnusedFeaturesContainer">
            {unusedFeatures.map((feature, index) => (
              <SystemUpgradeFeatureBox
                key={feature.name}
                title={feature.title}
                featureLinkText={feature.featureLinkText}
                featureLinkUrl={feature.featureLinkUrl}
                detailsLinkText={<Trans>Details</Trans>}
                detailsLinkUrl={feature.detailsLinkUrl}
                showBottomBorder={index !== unusedFeatures.length - 1}
                textDisabled={true}
                isExternalFeatureLink={feature.isExternalFeatureLink}
              />
            ))}
          </ContentContainer>
        </Container>
      </Collapsible>
    </>
  )
}

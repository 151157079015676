import { ApplicationListActions, ApplicationListActionTypes } from './actions'
import { ApplicationListState } from './state'

export const initialState: ApplicationListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function applicationReducer(
  previousState: ApplicationListState | undefined,
  action: ApplicationListActions
): ApplicationListState {
  const state = previousState || initialState

  switch (action.type) {
    case ApplicationListActionTypes.SET_APPLICATION_LIST: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage
        }
      }
    }
    case ApplicationListActionTypes.SET_APPLICATION_LIST_LOADING: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          isLoading: action.payload.loading
        }
      }
    }
  }

  return state
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as DarkWebMonitoringIcon } from '@lastpass/assets/svg/admin-console/security-dashboard/control-dark-web-monitoring.svg'
import { ReactComponent as EnforceMultifactorIcon } from '@lastpass/assets/svg/admin-console/security-dashboard/enforce-multifactor.svg'
import { TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { OpportunitiesBox } from '@lastpass/admin-console-dependencies/ui/home/components/opportunities-box/OpportunitiesBox'

const StyledTextButton = styled(TextButton)`
  padding: 0;
`

export const SecurityDashboardOpportunitiesBox: FC = () => {
  const cardData = [
    {
      title: (
        <p data-qa="OpportunityBoxEnforceMFATitle">
          <Trans>Enforce the use of multifactor</Trans>
        </p>
      ),
      icon: EnforceMultifactorIcon,
      description: (
        <Trans>
          Multifactor authentication (MFA) is a second form of authentication
          that verifies a user’s identity before granting them access.
        </Trans>
      ),
      action: (
        <StyledTextButton blue>
          <LocationLink
            to="/policies/multifactor"
            data-qa="GoToMultifactorLink"
          >
            <Trans>Go to Multifactor</Trans>
          </LocationLink>
        </StyledTextButton>
      ),
      key: 'multifactor'
    },
    {
      title: (
        <span data-qa="OpportunityBoxControlDarkWebMonitoringTitle">
          <Trans>Control dark web monitoring</Trans>
        </span>
      ),
      icon: DarkWebMonitoringIcon,
      description: (
        <Trans>
          LastPass can monitor your email addresses within a database of
          breached credentials and immediately alert you via email if
          compromised.
        </Trans>
      ),
      action: (
        <StyledTextButton blue>
          <LocationLink
            to="/policies/generalpolicies/dark_web_monitoring"
            data-qa="ConfigurePolicy"
          >
            <Trans>Configure policy</Trans>
          </LocationLink>
        </StyledTextButton>
      ),
      key: 'dwm'
    }
  ]

  return (
    <OpportunitiesBox
      cardData={cardData}
      title={<Trans>Opportunities to boost security score</Trans>}
    />
  )
}

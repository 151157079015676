import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { CardContainer, RadioButton, WithTooltip } from '@lastpass/lastkit'

import { addUsersDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'
import { AddUsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/add/state'

const StyledCardContainer = styled(CardContainer)`
  padding: 15px;
  margin-bottom: 24px;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 9px;
`

const StyledCheckBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-of-type {
    margin-bottom: 8px;
  }
`

export const AddUsersDrawerTable: React.FunctionComponent = () => {
  const addUsersDrawerState: AddUsersDrawerState = useSelector(
    (appState: AppState) => appState.addUsersDrawer
  )
  const dispatch = useDispatch()
  return (
    <>
      <StyledCardContainer>
        <StyledCheckBoxRow>
          <RadioButton
            option={{
              value: 'true',
              label: msg`Invite: Add to company and send activation email`
            }}
            checked={addUsersDrawerState.passwordManagementInvite}
            onChange={() => {
              dispatch(
                addUsersDrawerActions.setLpInvite(
                  !addUsersDrawerState.passwordManagementInvite
                )
              )
            }}
            data-qa="SendInvitationToPasswordManagerCheckbox"
          />
          <WithTooltip
            tooltip={
              <Trans>
                Add the user to your LastPass organization and send them an
                activation email. Set their LastPass Status to Invited.
              </Trans>
            }
            placement="bottom"
          >
            <StyledHelpIcon />
          </WithTooltip>
        </StyledCheckBoxRow>

        <StyledCheckBoxRow>
          <RadioButton
            option={{
              value: 'false',
              label: msg` Stage: Add to company without sending activation email`
            }}
            checked={!addUsersDrawerState.passwordManagementInvite}
            onChange={() => {
              dispatch(
                addUsersDrawerActions.setLpInvite(
                  !addUsersDrawerState.passwordManagementInvite
                )
              )
            }}
            data-qa="SendInvitationToPasswordManagerCheckbox"
          />
          <WithTooltip
            tooltip={
              <Trans>
                Add the user to your LastPass organization, but don{"'"}t notify
                them that their LastPass account has been created. Set their
                LastPass Status to Staged, allowing admins to further configure
                the account before the user can gain access.
              </Trans>
            }
            placement="bottom"
          >
            <StyledHelpIcon />
          </WithTooltip>
        </StyledCheckBoxRow>
      </StyledCardContainer>
    </>
  )
}

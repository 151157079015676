import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { AdvancedUserActivityEventTypes } from '@lastpass/admin-console-dependencies/types/advanced-user-activity-filter'

import { eventTypes } from './advanced-user-activity-event-types.mock'

export const SUCCESS = 'success'

const success = (payload: { eventTypes: AdvancedUserActivityEventTypes }) =>
  createResponse(SUCCESS, payload)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create() {
  return async function service() {
    try {
      return success({ eventTypes })
    } catch {
      throw new UnknownServiceError()
    }
  }
}

import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { BodyRegular, Heading100, SeparatorLine } from '@lastpass/lastkit'

import {
  StyledActionButton,
  StyledButtonContainer,
  StyledKeyRotationContainer
} from './StyledComponents'

export const VaultReEncryptionBlock: FunctionComponent<{
  onClickReEncryption: () => void
  isAutomatedVaultReEncryptionPolicyEnabled: boolean
}> = ({ onClickReEncryption, isAutomatedVaultReEncryptionPolicyEnabled }) => (
  <>
    <SeparatorLine />
    <StyledKeyRotationContainer>
      <Heading100>
        <Trans>Recurring vault re-encryption</Trans>
      </Heading100>
      <BodyRegular>
        <Trans>
          Protect your users by re-encrypting their vault and rotating their K2
          knowledge component. This helps ensure that information stays safe
          even if user credentials are compromised. Any user with an encryption
          key older than the recurrence interval will have their vault
          automatically re-encrypted and K2 knowledge component rotated upon
          next login.
        </Trans>
      </BodyRegular>
      <StyledButtonContainer>
        <StyledActionButton
          data-qa="ReEncryptVaultButton"
          onClick={onClickReEncryption}
          type="button"
        >
          {isAutomatedVaultReEncryptionPolicyEnabled ? (
            <Trans>Edit recurring vault re-encryption</Trans>
          ) : (
            <Trans>Enable recurring vault re-encryption</Trans>
          )}
        </StyledActionButton>
      </StyledButtonContainer>
    </StyledKeyRotationContainer>
  </>
)

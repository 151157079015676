import { MessageDescriptor } from '@lingui/core'

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export interface SharedFolderDrawerUrlParams {
  id: string
  page: string
}

export interface SharedFolderUser {
  uid: string
  superadmin: string
  email: string
  usersname: string
  canadminister: string
  readonly: string
  canviewpass: string
  outsideenterprise: string
}

export interface SharedFolderSite {
  domain: string
  lastused: string
}

export interface SharedFolderScore {
  measurement: MessageDescriptor
  score: string
}

export enum SharedFolderDrawerActionTypes {
  GET_SHARED_FOLDER_SITES_LIST = '[shared-folder] get-sites-list',
  GET_SHARED_FOLDER_USERS_LIST = '[shared-folder] get-users-list',
  GET_SHARED_FOLDER_SCORE = '[shared-folder] get-score',
  SET_SHARED_FOLDER_SITES_LIST = '[shared-folder] set-sites-list',
  SET_SHARED_FOLDER_USERS_LIST = '[shared-folder] set-users-list',
  SET_SHARED_FOLDER_SCORE = '[shared-folder] set-score',
  SET_SHARED_FOLDER_SCORE_LOADING = '[shared-folder] set-score-loading',
  SHARED_FOLDER_ADD_USERS = '[shared-folder] add-users',
  SHARED_FOLDER_ADD_SITES = '[shared-folder] add-sites',
  SET_SHAREDFOLDER_USERS_LOADING = '[shared-folders] set-user-list-loading',
  SET_SHAREDFOLDER_SITES_LOADING = '[shared-folders] set-site-list-loading'
}

export const sharedFolderDrawerActions = {
  getSharedFolderUsersList: (
    params: DataFetchParams<{}, SharedFolderDrawerUrlParams>
  ) =>
    createAction(SharedFolderDrawerActionTypes.GET_SHARED_FOLDER_USERS_LIST, {
      params
    }),
  getSharedFolderSitesList: (
    params: DataFetchParams<{}, SharedFolderDrawerUrlParams>
  ) =>
    createAction(SharedFolderDrawerActionTypes.GET_SHARED_FOLDER_SITES_LIST, {
      params
    }),
  setSharedFolderUsersLoading: (loading: boolean) =>
    createAction(
      SharedFolderDrawerActionTypes.SET_SHAREDFOLDER_USERS_LOADING,
      loading
    ),
  getSharedFolderScoresList: (
    params: DataFetchParams<{}, SharedFolderDrawerUrlParams>
  ) =>
    createAction(SharedFolderDrawerActionTypes.GET_SHARED_FOLDER_SCORE, {
      params
    }),
  setSharedFolderUsersList: (users: SharedFolderUser[]) =>
    createAction(SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_USERS_LIST, {
      users
    }),
  setSharedFolderSitesList: (sites: SharedFolderSite[]) =>
    createAction(SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_SITES_LIST, {
      sites
    }),
  setSharedFolderScoresList: (scores: SharedFolderScore[]) =>
    createAction(SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_SCORE, {
      scores
    }),
  sharedFolderAddUsers: (sharedFolderId: string) =>
    track(
      createAction(SharedFolderDrawerActionTypes.SHARED_FOLDER_ADD_USERS, {
        sharedFolderId
      }),
      {
        segment: uacEvent('Assign Users to Shared Folder Clicked')
      }
    ),
  sharedFolderAddSites: (sharedFolderId: string) =>
    track(
      createAction(SharedFolderDrawerActionTypes.SHARED_FOLDER_ADD_SITES, {
        sharedFolderId
      }),
      {
        segment: uacEvent('Assign Apps to Shared Folder Clicked')
      }
    ),
  setSharedFolderSitesLoading: (loading: boolean) =>
    createAction(
      SharedFolderDrawerActionTypes.SET_SHAREDFOLDER_SITES_LOADING,
      loading
    ),
  setSharedFolderScoresLoading: (loading: boolean) =>
    createAction(
      SharedFolderDrawerActionTypes.SET_SHARED_FOLDER_SCORE_LOADING,
      loading
    )
}

export type SharedFolderDrawerActions = ActionsUnion<
  typeof sharedFolderDrawerActions
>

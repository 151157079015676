import React, { FC } from 'react'

import { Trans } from '@lingui/macro'

import { BodyBold, BodyRegular } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ManagedCompanyConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { ConfirmDialogComponent } from '../ConfirmDialog'

interface ManagedCompanyConfirmDialogComponentProps {
  state: ManagedCompanyConfirmDialog
  actions: typeof globalActions
}

const Content: FC<{ managedCompanyName: string }> = ({
  managedCompanyName
}) => {
  if (!managedCompanyName) {
    return null
  }
  return (
    <>
      <BodyRegular>
        <Trans>Company:</Trans>
      </BodyRegular>{' '}
      <BodyBold>{managedCompanyName}</BodyBold>
    </>
  )
}

export const ManagedCompanyConfirmDialogComponent: FC<ManagedCompanyConfirmDialogComponentProps> = ({
  state,
  actions
}) => {
  const type = 'confirmdialog' as const
  const dialogState = {
    ...state,
    type,
    content: <Content managedCompanyName={state.managedCompanyName} />
  }

  return <ConfirmDialogComponent state={dialogState} actions={actions} />
}

import { ActionCreatorsMapObject, Middleware } from 'redux'

import { arrayFrom } from '@lastpass/utility'

import { Action } from '@lastpass/admin-console-dependencies/state/action'

import { errorHandlingActions } from './actions'
import { GoogleAnalyticsService } from './google-analytics'
import { isTrackingAction, TrackingAction } from './tracking-action'
import {
  AnalyticsServiceEvent,
  SegmentEvent,
  SegmentService,
  TrackingMetadata
} from './types'

/**
 * A resolver that runs on every state update until a
 * tracking event is returned or false, indicating
 * that an event will not be generated
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface EventResolver<E, S = any> {
  (state: S, initialState: S): E | E[] | boolean | undefined
}

/**
 * Can be either a simple event, an event resolver or a tuple
 * containing the initial event following by one that should be resolved.
 *
 * For example, a click by a user might yield an initial tracking event
 * and then a second tracking event depending on the success/failure of
 * the original action
 */
type TrackingEvent<E> = E | EventResolver<E> | (E | EventResolver<E>)[]

/**
 * Called by the middleware for each action to send any
 * pending tracking events as well as filter the remaining
 * event resolvers
 */
interface EventResolverFilter {
  (state: unknown): boolean
}

interface EventSender<E> {
  (event: E): void
}

enum TrackingService {
  /**
   * The old way of sending segment events routed them through PHP
   */
  LEGACY_SEGMENT = 'legacySegment',
  SEGMENT = 'segment',
  GOOGLE_ANALYTICS = 'google'
}

interface TrackingServices {
  [TrackingService.LEGACY_SEGMENT]: SegmentService
  [TrackingService.SEGMENT]: SegmentService
  [TrackingService.GOOGLE_ANALYTICS]: GoogleAnalyticsService
}

interface TrackingEvents {
  [TrackingService.SEGMENT]: TrackingEvent<SegmentEvent>
  [TrackingService.LEGACY_SEGMENT]: TrackingEvent<SegmentEvent>
  [TrackingService.GOOGLE_ANALYTICS]: TrackingEvent<AnalyticsServiceEvent>
}

export interface TrackedAction extends Action<string> {
  events: TrackingEvents
  metadata?: TrackingMetadata
}

interface WhitelistedEvents {
  [TrackingService.LEGACY_SEGMENT]?: string[]
  [TrackingService.SEGMENT]?: string[]
}

export function track<T extends Action<string>>(
  action: T,
  events: Partial<TrackingEvents>
): T {
  return {
    ...action,
    events
  }
}

export function trackActions<T extends ActionCreatorsMapObject<Action<string>>>(
  creators: T,
  metadata?: TrackingMetadata
): T {
  if (metadata) {
    const creatorsWithMetadata: T = { ...creators }
    for (const id in creators) {
      const creator = creators[id]
      const creatorWithMetadata = (...args) => {
        const action = creator(...args)
        return { ...action, metadata }
      }
      creatorsWithMetadata[id] = (creatorWithMetadata as unknown) as T[Extract<
        keyof T,
        string
      >]
    }
    return creatorsWithMetadata
  }
  return creators
}

function sendSegmentEvent(
  segmentService: SegmentService | undefined,
  { event, properties }: SegmentEvent,
  metadata?: TrackingMetadata
) {
  if (segmentService) {
    segmentService(event, properties, metadata)
  }
}

function sendAnalyticsServiceEvent(
  googleAnalytics: GoogleAnalyticsService | undefined,
  { hitType, event }: AnalyticsServiceEvent
) {
  if (googleAnalytics) {
    googleAnalytics(hitType, event)
  }
}

function isResolver<E>(event: E | EventResolver<E>): event is EventResolver<E> {
  return typeof event === 'function'
}

function createEventResolverFilter<E>(
  eventResolver: EventResolver<E>,
  initialState: unknown,
  eventSender: EventSender<E>
): EventResolverFilter {
  return state => {
    const trackingEvent = eventResolver(state, initialState)
    switch (typeof trackingEvent) {
      case 'boolean':
        return trackingEvent
      case 'undefined':
        return true
      default: {
        if (trackingEvent) {
          if (Array.isArray(trackingEvent)) {
            trackingEvent.forEach(eventSender)
          } else {
            eventSender(trackingEvent)
          }
        }
        return false
      }
    }
  }
}

function createEventSender(
  services: Partial<TrackingServices>,
  addEventResolver: (resolver: EventResolverFilter) => void
) {
  const forEachEvent = <E>(
    events: TrackingEvent<E>,
    initialState: unknown,
    eventSender: EventSender<E>
  ) => {
    arrayFrom(events).forEach(event => {
      if (isResolver(event)) {
        addEventResolver(
          createEventResolverFilter(event, initialState, eventSender)
        )
      } else {
        eventSender(event)
      }
    })
  }

  return function sendEvents(
    isTrackingEnabled: boolean,
    events: TrackingEvents,
    serviceType: string,
    initialState: unknown,
    metadata?: TrackingMetadata,
    whitelistedEvents?: WhitelistedEvents
  ) {
    const isEventWhitelisted = (eventName: string): boolean => {
      if (!whitelistedEvents || !whitelistedEvents[serviceType]) {
        return false
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return whitelistedEvents[serviceType]!.includes(eventName)
    }

    switch (serviceType) {
      case TrackingService.LEGACY_SEGMENT:
      case TrackingService.SEGMENT: {
        forEachEvent(events[serviceType], initialState, segmentEvent => {
          if (isTrackingEnabled || isEventWhitelisted(segmentEvent.event)) {
            sendSegmentEvent(services[serviceType], segmentEvent, metadata)
          }
        })
        break
      }
      case TrackingService.GOOGLE_ANALYTICS: {
        if (isTrackingEnabled) {
          forEachEvent(events[serviceType], initialState, event => {
            sendAnalyticsServiceEvent(services[serviceType], event)
          })
        }
        break
      }
    }
  }
}

interface TrackingOptions<T> {
  /**
   * Used in cases where the tracking middleware
   * should not make the actual analytics API request.
   * Used by the content script to avoid cross domain requests.
   */
  passThrough?: boolean

  /**
   * Allows tracking to be disabled by a user setting stored in
   * the redux store
   */
  enabledSelector?: (state: T) => boolean
  /**
   * Allows certain events to be sent while tracking is disabled
   */
  whitelistedEvents?: WhitelistedEvents
}

export function createTrackingMiddleware<T>({
  passThrough,
  enabledSelector,
  whitelistedEvents
}: TrackingOptions<T> = {}) {
  let services: Partial<TrackingServices> = {}
  let eventResolvers: EventResolverFilter[] = []
  let sendEvents: ReturnType<typeof createEventSender>

  const middleware: Middleware & {
    initialize: (services: Partial<TrackingServices>) => void
  } = store => next => (trackedAction: TrackedAction | TrackingAction) => {
    if (isTrackingAction(trackedAction)) {
      if (passThrough) {
        next(trackedAction)
      } else {
        sendSegmentEvent(
          services[TrackingService.SEGMENT],
          trackedAction.payload
        )
      }
      return
    }

    const { events, metadata, ...action } = trackedAction
    if (events) {
      const initialState = store.getState()
      const enabled = enabledSelector ? enabledSelector(initialState) : true
      if (enabled || whitelistedEvents) {
        for (const serviceType in events) {
          sendEvents(
            enabled,
            events,
            serviceType,
            initialState,
            metadata,
            whitelistedEvents
          )
        }
      }
    }

    // Trigger eventual state update
    next(action)

    // Resolve any remaining event promises
    eventResolvers = eventResolvers.filter(resolver => {
      try {
        return resolver(store.getState())
      } catch (e) {
        store.dispatch(errorHandlingActions.reportError(e as Error))
        /**
         * Returning false removes this resolver from the
         * array of remaining resolvers to check
         */
        return false
      }
    })
  }

  middleware.initialize = trackingServices => {
    services = trackingServices
    sendEvents = createEventSender(services, resolver => {
      eventResolvers.push(resolver)
    })
  }

  return middleware
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'

export function createGetAdvancedUserActivitySavedReports(
  userActivityServices: UACServices.Services
) {
  return function* getAdvancedUserActivitySavedReports() {
    try {
      const advancedUserActivitySavedReportsResult: UACServices.AdvancedUserActivitySavedReportsAPI.Responses = yield call(
        userActivityServices.advancedUserActivitySavedReports
      )

      yield put(
        advancedUserActivityActions.setSavedReports(
          advancedUserActivitySavedReportsResult.body.savedReports
        )
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { TrustedDeviceType } from '@lastpass/admin-console-dependencies/types/trusted-device'

import { EnumDictionary } from '../EnumValues'

export const TrustedDeviceTypeMapping: EnumDictionary<
  TrustedDeviceType,
  ReactElement
> = {
  [TrustedDeviceType.lastPassApplications]: <Trans>Password manager</Trans>,
  [TrustedDeviceType.lastPassMfaApplications]: <Trans>LastPass MFA</Trans>,
  [TrustedDeviceType.workstations]: <Trans>Workstation Login</Trans>
}

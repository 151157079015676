import { getFeatureFlagsHook } from '@lastpass/utility/feature-flag-wrapper'
import {
  ForcedFeatureFlag,
  getForcedFeatureFlags
} from '@lastpass/utility/force-feature-flag'

export enum FeatureFlags {
  isFederatedLoginEnabled = 'isFederatedLoginEnabled',
  isFederatedLoginStatusesEnabled = 'isFederatedLoginStatusesEnabled',
  isPendoEnabled = 'isPendoEnabled',
  isPkceOptInEnabled = 'isPkceOptInEnabled',
  isAzurePkceOptInEnabled = 'isAzurePkceOptInEnabled',
  isPingOneEnabled = 'isPingOneEnabled',
  isAzureMDMEnabled = 'isAzureMdmEnabled',
  isDWMSecurityReportEnabled = 'isDwmSecurityReportEnabled',
  isNewReporting = 'isNewReporting',
  isPingFederateEnabled = 'isPingFederateEnabled',
  isUserGroupFilteringEnabled = 'isUserGroupFilteringEnabled',
  isSiemIntegrationEnabled = 'isSiemIntegrationEnabled',
  isMspPaymentDialogEnabled = 'isMspPaymentDialogEnabled',
  isMspSupportEnabled = 'isMspSupportEnabled',
  isNativeSamlReportEnabled = 'isNativeSamlReportEnabled',
  isLastTouchHidden = 'isLastTouchHidden',
  isPsaIntegrationEnabled = 'isPsaIntegrationEnabled',
  isAlternateSsoAppConfigurationEnabled = 'isAlternateSsoAppConfigurationEnabled',
  isNpPasswordlessFeatureDisabled = 'isNpPasswordlessFeatureDisabled',
  isNopasswordMigrationEnabled = 'isNopasswordMigrationEnabled',
  isRoleBasedAccessControlEnabled = 'isRoleBasedAccessControlEnabled',
  isPasswordAppsHidden = 'isPasswordAppsHidden',
  isLastLoginIncludedInUsersExport = 'isLastLoginIncludedInUsersExport',
  isForceVaultReEncryptionEnabled = 'isForceVaultReEncryptionEnabled',
  isOneLoginKCRotationEnabled = 'isOneLoginKCRotationEnabled',
  isAssignAdminLevelActionEnabled = 'isAssignAdminLevelActionEnabled',
  isAutomatedVaultReEncryptionEnabled = 'isAutomatedVaultReEncryptionEnabled',
  isUtilizationDashboardEnabled = 'isUtilizationDashboardEnabled',
  isNoPasswordDeprecated = 'isNoPasswordDeprecated',
  isUrlDecryptionEnabled = 'isUrlDecryptionEnabled',
  isUrlLoggingGracePeriodBannerEnabled = 'isUrlLoggingGracePeriodBannerEnabled',
  isAccountStatusRenamed = 'isAccountStatusRenamed',
  isOpportunitiesBoxEnabled = 'isOpportunitiesBoxEnabled',
  isSharedFolderUrlDecryptionEnabled = 'isSharedFolderUrlDecryptionEnabled',
  isWeakSecurityScoreEnabled = 'isWeakSecurityScoreEnabled',
  isNewSecurityDashboardEnabled = 'isNewSecurityDashboardEnabled',
  isSaasAppDiscoveryEnabled = 'isSaasAppDiscoveryEnabled',
  isOktaKeyRotationEnabled = 'isOktaKeyRotationEnabled',
  isAzureKeyRotationEnabled = 'isAzureKeyRotationEnabled',
  isSIEMQRadarEnabled = 'isSIEMQRadarEnabled',
  isSIEMLogRhythmEnabled = 'isSIEMLogRhythmEnabled',
  isSIEMAlienVaultEnabled = 'isSIEMAlienVaultEnabled',
  isCreatedByCompanyColumnEnabled = 'isCreatedByCompanyColumnEnabled'
}

export const useFeatureFlags = (
  featureFlag: FeatureFlags,
  defaultValue = false
): boolean => {
  const useFeatureFlag = getFeatureFlagsHook()
  const keys = useFeatureFlag()
  if (process.env.NODE_ENV === 'development') {
    const forcedFeatureFlags: ForcedFeatureFlag[] = getForcedFeatureFlags()
    const isForced = forcedFeatureFlags.find(e => e.name === featureFlag)

    if (isForced) {
      return isForced.value
    }
  }

  const featureFlagValue = keys[featureFlag] ? keys[featureFlag] : defaultValue
  return featureFlagValue
}

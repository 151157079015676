import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { PermissionCategoryType } from '@lastpass/admin-console-dependencies/types/permissions/permission-category-type'

import { EnumDictionary } from '../EnumValues'

export const PermissionCategoryTypeMapping: EnumDictionary<
  PermissionCategoryType,
  ReactElement
> = {
  [PermissionCategoryType.dashboard]: <Trans>Dashboard</Trans>,
  [PermissionCategoryType.managedCompanies]: <Trans>Managed companies</Trans>,

  [PermissionCategoryType.usersAndGroups]: <Trans>Users and groups</Trans>,
  [PermissionCategoryType.appsAndSharedFolders]: (
    <Trans>Apps and shared folders</Trans>
  ),
  [PermissionCategoryType.policies]: <Trans>Policies</Trans>,
  [PermissionCategoryType.reporting]: <Trans>Reporting</Trans>,
  [PermissionCategoryType.general]: <Trans>General</Trans>
}

import { put, select } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { generalPolicySettingsActions } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/actions'
import { GeneralPolicySettings } from '@lastpass/admin-console-dependencies/state/policies/general/policy-settings/state'
import { TeamsPolicyMap } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getPolicyInfo = state => state.generalPoliciesList.policyMap
export const getUnsavedPolicyData = state =>
  state.generalPoliciesDrawer.unsavedPolicyData

const getPolicyValue = (policyValue: string | null): string => {
  return policyValue ? (policyValue as string) : ''
}

const getPolicyNotes = (policyNotes: string): string => {
  return policyNotes ? (policyNotes as string) : ''
}
export function createGetGeneralPolicySettingsSaga() {
  return function* getGeneralPolicySettingsSaga(
    action: ReturnType<
      typeof generalPolicySettingsActions.getGeneralPolicySettings
    >
  ) {
    try {
      const policyKey = action.payload.params.path.id
      const policyConfigIdParam = action.payload.params.path.configId
      if (policyKey && policyConfigIdParam) {
        yield put(
          generalPolicySettingsActions.setGeneralPolicySettingsLoading(true)
        )
        yield put(
          generalPolicySettingsActions.setGeneralPolicySettingsOpen(true)
        )
        try {
          const policyMap: TeamsPolicyMap = yield select(getPolicyInfo)
          const policyData = yield select(getUnsavedPolicyData)
          const policy = policyMap[policyKey]
          const policyConfigId = parseInt(policyConfigIdParam)
          if (!policy.policyData || policy.policyData.length === 0) {
            policy.policyData = policyData
            policy.checked = true
          }
          if (policy && policy.policyData.length) {
            const policyConfigSettings: GeneralPolicySettings = {
              policyKey: policy.policyKey,
              policyConfigIndex: policyConfigId,
              policyDescription: policy.policyDescription,
              policyValueType: policy.policyValueType,
              policyConfigFormData: {
                policyEnabled: policy.checked,
                policyValue: getPolicyValue(
                  policy.policyData[policyConfigId].policyValue
                ),
                policyNotes: getPolicyNotes(
                  policy.policyData[policyConfigId].policyNotes
                )
              }
            }
            yield put(
              generalPolicySettingsActions.setGeneralPolicySettings(
                policyConfigSettings
              )
            )
            yield put(
              generalPolicySettingsActions.setGeneralPolicySettingsNeedsRefresh(
                false,
                policyKey,
                policyConfigId
              )
            )
          } else {
            yield put(
              generalPolicySettingsActions.setGeneralPolicySettingsNeedsRefresh(
                true,
                policyKey,
                policyConfigId
              )
            )
          }
        } finally {
          yield put(
            generalPolicySettingsActions.setGeneralPolicySettingsLoading(false)
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

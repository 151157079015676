import { Location } from 'history'

export const getQueryParams = (value: Location, param: string) => {
  const searchParam = new URLSearchParams(value.search).get(param)
  if (searchParam) {
    try {
      return JSON.parse(
        Buffer.from(decodeURIComponent(searchParam), 'base64').toString()
      )
    } catch {
      return {}
    }
  }
  return {}
}

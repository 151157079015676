import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { assignGroupUsersActions } from '@lastpass/admin-console-dependencies/state/users/groups/add-users/actions'

export function createGroupAssignUsersListSaga(
  groupNewUsersService: UACServices.Services
) {
  return function* getGroupAssignUsersListSaga(
    action: ReturnType<typeof assignGroupUsersActions.getGroupAssignUserList>
  ) {
    try {
      const id = action.payload.params.path.id
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        search: encodeSearch(action.payload.params.query.search),
        top: resultsPerPage
      })
      yield put(assignGroupUsersActions.setGroupAssignUsersTableLoading(true))
      if (id) {
        try {
          const result: UACServices.GroupAssignUsersAPI.Responses = yield call(
            groupNewUsersService.groupAssignUsers,
            id,
            query
          )
          if (result.type === UACServices.GroupAssignUsersAPI.SUCCESS) {
            const assignGroupUserList = result.body.assignGroupUserList
            const totalResults = result.body.totalResults
            yield put(
              assignGroupUsersActions.setGroupAssignUserList(
                assignGroupUserList,
                totalResults,
                resultsPerPage
              )
            )
          }
        } finally {
          yield put(
            assignGroupUsersActions.setGroupAssignUsersTableLoading(false)
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { SwitchToOldConsoleClickedSourceMapping } from '@lastpass/admin-console-dependencies/services/segment-helpers'

export const switchToOldConsoleClickedSourceMapping: SwitchToOldConsoleClickedSourceMapping = {
  '/dashboard': 'Admin Dashboard',
  '/dashboard/inactive': 'Inactive Users Drawer',
  '/dashboard/invite': 'Reinvite Users Drawer',
  '/dashboard/invite-staged': 'Invite Users Drawer',
  '/dashboard/add': 'Add Users Drawer',
  '/users': 'Users Overview',
  '/users/view/add': 'Add Users Drawer',
  '/users/view/\\d+': 'User Details Drawer',
  '/users/groups': 'Groups Overview',
  '/users/admin': 'Admin Level Overview',
  '/users/directories': 'Directory Integration Configuration',
  '/applications/saml': 'SSO Apps Overview',
  '/applications/mfa': 'MFA Apps Overview',
  '/applications/passwordless/workstation': 'Workstation Login Overview',
  '/applications/passwordless/legacyvpn': 'Legacy VPN Overview',
  '/applications/password': 'Password Apps Overview',
  '/applications/sharedfolder': 'Shared Folder Overview',
  '/policies/generalpolicies': 'Password Management Policies',
  '/policies/multifactor': 'Multifactor Policies',
  '/policies/passwordless': 'Passwordless Policies',
  '/reports/generalReports/userActivity': 'User Activity Reporting',
  '/reports/generalReports/adminActivity': 'Admin Activity Reporting',
  '/reports/generalReports/loginReport': 'Login Activity Reporting',
  '/reports/generalReports/securityReport': 'Security Reporting',
  '/reports/apps': 'SSO Login Reporting',
  '/reports/saml': 'SAML Response Reporting',
  '/reports/users': 'MFA User Activity Reporting',
  '/reports/admins': 'MFA Admin Activity Reporting',
  '/advanced/enterpriseOptions/urlRules': 'URL Rules Configuration',
  '/advanced/enterpriseOptions/equivalentDomains':
    'Equivalent Domains Configuration',
  '/advanced/enterpriseOptions/globalNeverOnlyUrls':
    'Global Never URL Configuration',
  '/advanced/enterpriseOptions/splunkIntegration':
    'Splunk Integration Configuration',
  '/advanced/enterpriseOptions/multifactorOptions':
    'Multifactor Options Configuration',
  '/advanced/enterpriseApi': 'Enterprise API Configuration',
  '/advanced/installSoftware': 'Install Software Overview',
  '/advanced/federatedLogin': 'Federated Login Configuration',
  '/advanced/emailNotification': 'Email Notification Overview',
  '/advanced/keys': 'Key Management Overview'
}

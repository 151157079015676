import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyBold,
  BodyRegular,
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextInput
} from '@lastpass/lastkit'

import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { MfaAppsDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import {
  MfaApplication,
  MfaApplicationType
} from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'

import { MfaAdvancedSettingsComponent } from '../MfaAdvancedSettingsComponent'
import { HowToInstall } from './install/HowToInstall'

export interface FinishIntegrationProps {
  closeLink: string
}

const SecretContainer = styled.div`
  margin-top: 12px;
`

const StyledBodyBold = styled(BodyBold)`
  margin-top: 16px;
`

export const FinishIntegrationComponent: React.FunctionComponent<FinishIntegrationProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const application = useSelector<AppState, MfaApplication>(
    state => state.mfaAppsDrawer.app
  )

  useEffect(() => {
    dispatch(mfaAppsDrawerActions.configureAppViewed())
  }, [dispatch])

  const isApplicationAzureAd = application.type === MfaApplicationType.azureAD

  return (
    <>
      <Heading100>
        <Trans>Integration key & secret</Trans>
      </Heading100>
      <StyledBodyBold>
        <Trans>This is the only time we display this info.</Trans>
      </StyledBodyBold>
      <BodyRegular>
        <Trans>Save your key & secret and keep them safe!</Trans>
      </BodyRegular>
      <SecretContainer>
        <TextInput
          data-qa="AppKeyField"
          disabled
          copyToClipboard
          value={application.key}
          actionOnCopy={() => {
            dispatch(
              mfaAppsDrawerActions.configureAppClicked('Copy Key', application)
            )
            dispatch(
              mfaAppsDrawerActions.setNotification(CopyToClipboardNotification)
            )
          }}
        >
          <Trans>Key</Trans>
        </TextInput>
      </SecretContainer>
      <SecretContainer>
        <TextInput
          data-qa="AppSecretField"
          disabled
          copyToClipboard
          value={application.secret}
          actionOnCopy={() => {
            dispatch(
              mfaAppsDrawerActions.configureAppClicked(
                'Copy Secret',
                application
              )
            )
            dispatch(
              mfaAppsDrawerActions.setNotification(CopyToClipboardNotification)
            )
          }}
        >
          <Trans>Secret</Trans>
        </TextInput>
      </SecretContainer>
      <HowToInstall mfaAppType={application.type} />
      {!isApplicationAzureAd && (
        <MfaAdvancedSettingsComponent isReadonly={false} />
      )}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          {!isApplicationAzureAd ? (
            <PrimaryButton
              id="ContinueButton"
              key="Continue"
              data-qa="ContinueButton"
              onClick={() => {
                dispatch(mfaAppsDrawerActions.saveApp(application))
                dispatch(
                  mfaAppsDrawerActions.nextStep(MfaAppsDrawerSteps.assign)
                )
              }}
            >
              <Trans>Save {'&'} assign users</Trans>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              id="FinishButton"
              key="Finish"
              data-qa="FinishButton"
              onClick={() => {
                dispatch(
                  mfaAppsDrawerActions.configureAppClicked(
                    'Finish',
                    application
                  )
                )
                history.push(closeLink)
              }}
            >
              <Trans>Finish</Trans>
            </PrimaryButton>
          )}
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

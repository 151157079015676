import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import {
  BodyBold,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { createQueryStringForNamespace } from '@lastpass/admin-console-dependencies/services/routing/create-query-string-for-namespace'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { VaultReEncryptionDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
`

const StyledExternalLink = styled.a`
  font-size: 14px;
  line-height: 24px;
`

interface VaultReEncryptionSetupProps {
  state: VaultReEncryptionDialog
}

export const VaultReEncryptionSetupDialogComponent: FunctionComponent<VaultReEncryptionSetupProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      disableMaxHeight
      title={i18n._(state.title)}
      content={
        <>
          <TextBoxContainer>
            <Trans>
              Protect federated users by re-encrypting their vault and rotating
              their K2 knowledge component. This helps ensure that information
              stays safe even if user credentials are compromised.
            </Trans>
          </TextBoxContainer>
          <TextBoxContainer>
            <Trans>Your options:</Trans>
            <StyledList>
              <li>
                <Trans>
                  <BodyBold>Recurring vault re-encryption.</BodyBold> If you
                  enable this policy on the Policies page, any user with an
                  encryption key older than the recurrence interval will have
                  their vault automatically re-encrypted and K2 knowledge
                  component rotated upon next login.
                </Trans>
              </li>
              <li>
                <Trans>
                  <BodyBold>Forced vault re-encryption.</BodyBold> On the Users
                  or Groups page, select users or groups and then the Force
                  vault re-encryption option.
                </Trans>
              </li>
            </StyledList>
            <StyledExternalLink
              href="https://link.lastpass.com/recurring_vault_re_encryption"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Learn more about vault re-encryption</Trans>
            </StyledExternalLink>
          </TextBoxContainer>
          <TextBoxContainer>
            <Trans>
              There’s no meaningful change to your users’ daily experience. When
              they next log in, they’ll see a notification that tells them their
              vault is being re-encrypted. That’s about it.
            </Trans>
          </TextBoxContainer>
        </>
      }
      buttons={[
        <TextButton
          data-qa="PopupCancel"
          onClick={() => {
            dispatch(globalActions.discardDialog())
            dispatch(globalActions.emptyDialog())
          }}
          key="discard"
        >
          <Trans>Cancel</Trans>
        </TextButton>,
        <Link
          to={`/policies/generalpolicies/recurringfederatedvaultreencryption?${createQueryStringForNamespace(
            Namespace.policies,
            {
              search: 'Recurring vault re-encryption'
            }
          )}`}
          key="confirm"
        >
          <PrimaryButton
            data-qa="PopupSubmit"
            onClick={() => {
              dispatch(globalActions.confirmDialog())
            }}
            type="submit"
          >
            <Trans>Go to Policies</Trans>
          </PrimaryButton>
        </Link>
      ]}
    />
  )
}

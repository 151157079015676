import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { Loading } from '@lastpass/components'
import { BodyRegularStyle, DetailsDialog, TextButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { ProcessingReportDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
  text-align: center;
`

const StyledLoadingContainer = styled.div`
  position: relative;
  padding: 30px;
`

interface ProcessingReportDialogProps {
  state: ProcessingReportDialogType
}

export const ProcessingReportDialog: FunctionComponent<ProcessingReportDialogProps> = ({
  state: { controller }
}) => {
  const dispatch = useDispatch()

  const handleAbort = () => {
    controller.abort()
    dispatch(globalActions.emptyDialog())
  }

  const Content: FunctionComponent = () => (
    <>
      <StyledLoadingContainer>
        <Loading color="blue900" active={true} />
      </StyledLoadingContainer>
      <StyledParagraph>
        <Trans>
          Don’t close this page until processing is complete. The file will be
          downloaded automatically.
        </Trans>
      </StyledParagraph>
    </>
  )

  return (
    <DetailsDialog
      closeDialog={handleAbort}
      width="634px"
      disableCloseOnBackground
      title={msg`Processing report`}
      content={<Content />}
      buttons={[
        <TextButton
          onClick={handleAbort}
          key="stop"
          data-qa="ProcessingReportDialogCancelButton"
        >
          <Trans>Cancel</Trans>
        </TextButton>
      ]}
    />
  )
}

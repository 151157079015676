import { RefObject, useEffect, useState } from 'react'

interface Vec2 {
  x: number
  y: number
}

export const useDragEffect = (
  target: RefObject<HTMLElement>,
  callback: (distance: Vec2) => void,
  progress?: (distance: Vec2) => void
) => {
  const [isDragged, setIsDragged] = useState(false)
  const [dragStart, setDragStart] = useState<Vec2>({ x: 0, y: 0 })

  const handleMouseDown = (e: MouseEvent) => {
    if (!target.current || target.current !== e.target) {
      return
    }

    setIsDragged(true)
    setDragStart({ x: e.clientX, y: e.clientY })
    e.preventDefault()
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragged || !progress) {
      return
    }

    progress({ x: e.clientX - dragStart.x, y: e.clientY - dragStart.y })
    e.preventDefault()
  }

  const handleMouseUp = (e: MouseEvent) => {
    if (!isDragged) {
      return
    }

    callback({ x: e.clientX - dragStart.x, y: e.clientY - dragStart.y })
    setIsDragged(false)
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  })
}

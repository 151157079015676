import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { Loading } from '@lastpass/components'
import { IconButton } from '@lastpass/lastkit'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'
import {
  applicationListActions,
  ApplicationListQueryParams
} from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { ApplicationsDrawerPage } from './add/ApplicationsDrawerPage'
import { createApplicationOperationsDropdown } from './ApplicationOperationsDropdown'

export interface ApplicationDrawerProps {
  closeLink: string
  initialStep: ApplicationDrawerSteps
  backButton?: React.ReactElement
  onSsoAppDrawerClosed?: () => void
}

const getTitle = (
  step: ApplicationDrawerSteps,
  isNoPasswordDeprecated: boolean
) => {
  switch (step) {
    case ApplicationDrawerSteps.select: {
      return <Trans>Select app</Trans>
    }
    case ApplicationDrawerSteps.configure: {
      return <Trans>Configure app</Trans>
    }
    case ApplicationDrawerSteps.assign:
    case ApplicationDrawerSteps.newAssign: {
      return isNoPasswordDeprecated ? (
        <Trans>Assign users {'&'} groups</Trans>
      ) : (
        <Trans>Assign users, groups {'&'} roles</Trans>
      )
    }
    case ApplicationDrawerSteps.allset: {
      return <Trans>All set!</Trans>
    }
    case ApplicationDrawerSteps.addUnlistedApp: {
      return <Trans>Add unlisted app</Trans>
    }
    case ApplicationDrawerSteps.enableMultifactor: {
      return <Trans>Enable multifactor authentication</Trans>
    }
    case ApplicationDrawerSteps.assignTestUser: {
      return <Trans>Assign a test user</Trans>
    }
    case ApplicationDrawerSteps.testLogin: {
      return <Trans>Test login</Trans>
    }
  }
}

export const ApplicationsDrawer: React.FunctionComponent<ApplicationDrawerProps> = ({
  closeLink,
  initialStep,
  onSsoAppDrawerClosed
}) => {
  const applicationDrawerState: ApplicationDrawerState = useSelector(
    (state: AppState) => state.applicationDrawer
  )
  const { cidOverride } = useSelector<AppState, CompanyInfoState>(
    (state: AppState) => state.companyInfo
  )
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(applicationsDrawerActions.discard())
    }
  }, [dispatch])

  const queryParams: ApplicationListQueryParams = useQueryParams(Namespace.apps)
  const onClose = () => {
    onSsoAppDrawerClosed
    dispatch(
      applicationListActions.getApplicationList({
        query: queryParams,
        path: {}
      })
    )
  }

  const isAlternateSsoAppConfigurationEnabled = useFeatureFlags(
    FeatureFlags.isAlternateSsoAppConfigurationEnabled
  )
  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const backButton =
    applicationDrawerState.actualStep === ApplicationDrawerSteps.newAssign ? (
      <LocationLink
        to={'#'}
        onClick={() => {
          dispatch(
            applicationsDrawerActions.nextStep(ApplicationDrawerSteps.assign)
          )
        }}
      >
        <IconButton icon={ChevronLeft} data-qa="BackButton" />
      </LocationLink>
    ) : (
      <></>
    )

  let moreButton = <></>
  if (
    applicationDrawerState.selectedApplication &&
    applicationDrawerState.selectedApplication.id
  ) {
    const ApplicationOperationsDropdown = createApplicationOperationsDropdown<
      AppState
    >()
    moreButton = <ApplicationOperationsDropdown />
  }

  const actualStep = applicationDrawerState.actualStep
    ? applicationDrawerState.actualStep
    : initialStep
  const mainTitle = getTitle(actualStep, isNoPasswordDeprecated)

  const getIframeUrl = () => {
    const hash = window.location.hash
    const urlParams = {}

    if (hash) {
      urlParams['service'] = hash.replace('#', '')
    }
    if (cidOverride) {
      urlParams['childcid'] = cidOverride
    }
    const queryString = new URLSearchParams(urlParams).toString()
    let url = `${defaultConfig.lastPassBaseAddress}/enterprise_saml.php`
    if (queryString) {
      url += '?' + queryString
    }
    return url
  }

  const isIframedLpLegacyApp = () => {
    const { pathname } = window.location
    return (
      isAlternateSsoAppConfigurationEnabled &&
      (pathname.endsWith('add') || pathname.endsWith('/0'))
    )
  }

  return (
    <Drawer
      mainTitle={isIframedLpLegacyApp() ? <></> : mainTitle}
      miscTitle={<></>}
      closeLink={closeLink}
      headerNodes={
        <>
          {backButton}
          {moreButton}
        </>
      }
      onClose={onClose}
      iframedContentUrl={isIframedLpLegacyApp() ? getIframeUrl() : undefined}
    >
      {applicationDrawerState.isLoading && (
        <Loading color="blue900" active={true} />
      )}
      {isIframedLpLegacyApp() ? (
        <></>
      ) : (
        <ApplicationsDrawerPage step={actualStep} closeLink={closeLink} />
      )}
    </Drawer>
  )
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'
import { addSlashes } from 'slashes'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'

export function createFetchSystemLogList(
  enterpriseOptionServices: UACServices.Services
) {
  return function* fetchGoogleAuditLogList(
    action: ReturnType<typeof directoriesActions.fetchSystemLogList>
  ) {
    try {
      const { skip, top, filters } = action.payload

      const query = buildQuery({
        skip,
        top,
        filter: {
          sysLogStatus: filters.status
            ? `${encodeURIComponent(
                addSlashes(filters.status, '\b\f\n\r\t\v\0"\\')
              )}`
            : undefined
        }
      })

      const result: UACServices.GetSystemLogsAPI.Responses = yield call(
        enterpriseOptionServices.getSystemLogs,
        query
      )

      if (result.type === UACServices.GetSystemLogsAPI.SUCCESS) {
        yield put(
          directoriesActions.setLogList(
            result.body.logs,
            result.body.numberOfItems
          )
        )
      } else {
        yield put(directoriesActions.fetchLogListFailed())
      }
    } catch {
      yield put(directoriesActions.fetchLogListFailed())
    }
  }
}

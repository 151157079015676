import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { SupportDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  BaseSupportDialogComponent,
  StyledTextButton
} from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/BaseSupportDialog'
import {
  EnglishSupportPhone,
  GermanSupportPhone
} from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/SupportPhone'
import {
  SupportCenterSection,
  SupportCommunitySection,
  SupportStatusSection
} from '@lastpass/admin-console-dependencies/ui/global/dialogs/support-dialog/SupportSection'

interface SupportDialogProps {
  state: SupportDialog
}

export const SupportDialogComponent: FunctionComponent<SupportDialogProps> = ({
  state
}) => {
  return (
    <BaseSupportDialogComponent
      sections={[
        <SupportStatusSection key="SupportStatusSection" />,
        <SupportCommunitySection key="SupportCommunitySection" />,
        <SupportCenterSection key="SupportCenterSection" />
      ]}
      viewSupportRequestsText={
        <StyledTextButton blue>
          <Trans>View your support requests</Trans>
        </StyledTextButton>
      }
      supportPhones={{
        english: <EnglishSupportPhone />,
        german: <GermanSupportPhone />
      }}
      state={state}
    />
  )
}

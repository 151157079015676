import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as PolicyIcon } from '@lastpass/assets/svg/admin-console/icon-policy.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  PrimaryButton,
  SearchInput,
  Table as TableComponent,
  TableProps,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import {
  generalPoliciesListActions,
  GeneralPoliciesListQueryParams
} from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPoliciesListState } from '@lastpass/admin-console-dependencies/state/policies/general/list/state'
import {
  TeamsPolicy,
  TeamsPolicyStatus,
  TeamsPolicyValueType
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PolicyUsersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PolicyUsersMapping'

import { PolicyStatusBadge } from './PolicyStatusBadge'
import { SelectPolicyCategory } from './SelectPolicyCategory'
import { SelectPolicyStatus } from './SelectPolicyStatus'

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const StyledContainer = styled.div`
  display: flex;
`

export const GeneralPoliciesList: React.FunctionComponent = () => {
  const queryParams: GeneralPoliciesListQueryParams = useQueryParams(
    Namespace.policies
  )
  const updateQuery = useUpdateQuery(Namespace.policies)

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const generalPoliciesList: GeneralPoliciesListState = useSelector(
    (state: AppState) => state.generalPoliciesList
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      generalPoliciesListActions.getGeneralPoliciesList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  const isUrlLoggingGracePeriodBannerEnabled = useFeatureFlags(
    FeatureFlags.isUrlLoggingGracePeriodBannerEnabled
  )

  const rowTooltips: TableProps<TeamsPolicy>['rowTooltips'] = [
    {
      selectedRecordKey: 'policyKey',
      selectedRowId: 'enableurllogging',
      content: (
        <Trans>
          Action required: To continue logging URLs in General reports and SIEM
          after 10 June 2024, enable this policy.
        </Trans>
      )
    }
  ]

  return (
    <>
      <RowContainer>
        <Heading300 data-qa={'GeneralPoliciesPageHeader'}>
          <Trans>General policies</Trans>
        </Heading300>
        <div>
          <LocationLink
            to={'/policies/generalpolicies/add'}
            onClick={() => {
              dispatch(generalPoliciesListActions.addNewPolicy())
            }}
          >
            <StyledPrimaryButton data-qa={'NewPolicyButton'} key={'add policy'}>
              <Trans>New policy</Trans>
            </StyledPrimaryButton>
          </LocationLink>
        </div>
      </RowContainer>

      <RowContainer>
        <StyledContainer>
          <SearchInput
            data-qa={'PoliciesSearch'}
            onChange={event => {
              updateQuery({
                search: event.target.value || undefined,
                skip: undefined
              })
            }}
            value={queryParams.search || ''}
            placeholder={msg`Search policies...`}
          />
          <SelectPolicyStatus
            updateQuery={updateQuery}
            filters={queryParams.status}
          />
          <SelectPolicyCategory
            updateQuery={updateQuery}
            filters={queryParams.category}
          />
        </StyledContainer>
      </RowContainer>

      <TableComponent
        table={generalPoliciesList.table}
        checkable={{
          addSelectedRecords: (teamsPolicy: TeamsPolicy[]) =>
            dispatch(
              generalPoliciesListActions.addSelectedGeneralPolicies(teamsPolicy)
            ),
          removeSelectedRecords: (teamsPolicy: TeamsPolicy[]) =>
            dispatch(
              generalPoliciesListActions.removeSelectedGeneralPolicies(
                teamsPolicy
              )
            ),
          setSelectedRecords: (teamsPolicy: TeamsPolicy[]) =>
            dispatch(
              generalPoliciesListActions.setSelectedGeneralPolicies(teamsPolicy)
            )
        }}
        getRecordLink={(record: TeamsPolicy) =>
          `${matchUrl}/${record.policyKey}`
        }
        noRecordsView={
          <TableView
            icon={<PolicyIcon />}
            title={<Trans>No policies added yet.</Trans>}
            actionButton={
              <LocationLink to={'/policies/generalpolicies/add'}>
                <TableViewButton>
                  <Trans>Add Policy</Trans>
                </TableViewButton>
              </LocationLink>
            }
          />
        }
        noResultsView={
          <TableView
            icon={<PolicyIcon />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={<Trans>Try different keywords or check your spelling</Trans>}
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined,
                    category: undefined,
                    status: undefined
                  })
                }}
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={[
          {
            name: <Trans>Policy name</Trans>,
            renderer: (record: TeamsPolicy) => record.policyTitle
          },
          {
            name: <Trans>Description</Trans>,
            renderer: (record: TeamsPolicy) => record.policyShortDescription
          },
          {
            name: <Trans>Users</Trans>,
            renderer: (record: TeamsPolicy) => {
              if (!record.checked) {
                return ''
              }
              if (record.policyValueType === TeamsPolicyValueType.adminusers) {
                return <Trans>Only selected admins</Trans>
              }
              const policyAppliesTo =
                record.policyData && record.policyData.length > 0
                  ? record.policyData[record.policyData.length - 1].appliesTo
                  : ''
              return PolicyUsersMapping[policyAppliesTo]
            }
          },
          {
            name: <Trans>Status</Trans>,
            renderer: function StatusBadgerenderer(record: TeamsPolicy) {
              return (
                <PolicyStatusBadge
                  status={
                    record.checked
                      ? TeamsPolicyStatus.enabled
                      : TeamsPolicyStatus.disabled
                  }
                />
              )
            },
            noTooltip: true
          }
        ]}
        isFilterApplied={
          !!queryParams.category || !!queryParams.status || !!queryParams.search
        }
        rowTooltips={
          isUrlLoggingGracePeriodBannerEnabled ? rowTooltips : undefined
        }
      />
    </>
  )
}

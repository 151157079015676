import React, { useEffect } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular, PrimaryButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import {
  DashboardState,
  UserStateData
} from '@lastpass/admin-console-dependencies/state/dashboard/state'

import {
  CallToActionBoxBody,
  CallToActionBoxHeading,
  StyledBottom,
  StyledTop
} from './UnresponsiveUsers'

type RemindInactiveUsersBlockProps = LastPassComponentProps<
  UserStateData,
  typeof dashboardActions,
  {}
>

const Container = styled.div`
  background-color: ${props => props.theme.colors.blue100};
  padding: 18px 24px;
  margin-top: 24px;
`

const FirstCell = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
`

const SecondCell = styled.div`
  padding-top: 10px;
`

export const RemindInactiveUsersBlockComponent: React.FunctionComponent<RemindInactiveUsersBlockProps> = ({
  state,
  actions
}) => {
  useEffect(() => {
    actions.getUserStateData()
  }, [actions])

  return (
    <Container data-qa={'RemindInactiveUsersBox'}>
      <StyledTop>
        <CallToActionBoxHeading>
          <Trans>Remind inactive users</Trans>
        </CallToActionBoxHeading>
        <BodyRegular>
          <Trans>
            Send a reminder to folks who haven\u0027t used LastPass in the last
            30 days.
          </Trans>
        </BodyRegular>
      </StyledTop>

      <StyledBottom>
        <FirstCell>
          <CallToActionBoxHeading data-qa={'InactiveUsersCount'}>
            {state.inactiveUsersCount != null ? (
              state.inactiveUsersCount
            ) : (
              <Trans>N/A</Trans>
            )}
          </CallToActionBoxHeading>
          <CallToActionBoxBody>
            <Trans>Users enrolled but not active</Trans>
          </CallToActionBoxBody>
        </FirstCell>
        <SecondCell>
          <LocationLink to={'/dashboard/inactive'}>
            <PrimaryButton
              data-qa={'ViewAndRemindButton'}
              onClick={() => {
                actions.viewInactiveUsersClicked()
              }}
            >
              <Trans>View &amp; remind</Trans>
            </PrimaryButton>
          </LocationLink>
        </SecondCell>
      </StyledBottom>
    </Container>
  )
}

export function createRemindInactiveUsersBlockComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    RemindInactiveUsersBlockComponent,
    (state: TState) => state.dashboard.userStateData,
    dashboardActions
  )
}

export type RemindInactiveUsersBlock = ReturnType<
  typeof createRemindInactiveUsersBlockComponent
>

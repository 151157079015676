import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import buildQuery from 'odata-query'

import { Loading } from '@lastpass/components'
import { DetailCell, DetailsContainer } from '@lastpass/lastkit'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import {
  adminActivityReportTranslationMap,
  userActivityReportTranslationMap
} from '@lastpass/admin-console-dependencies/resources/activity-reports/activity-report-translations'
import * as UACServices from '@lastpass/admin-console-dependencies/server/index'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  ActivityEvent,
  ActivityEventUserType
} from '@lastpass/admin-console-dependencies/types/activity-event'
import { UserDetails } from '@lastpass/admin-console-dependencies/types/user-details'
export interface ActivityDetailDrawerProps {
  closeLink: string
  events: ActivityEvent[]
  userType: ActivityEventUserType
}

export const ActivityDetailDrawer: React.FunctionComponent<ActivityDetailDrawerProps> = ({
  closeLink,
  events,
  userType
}) => {
  const dispatch = useDispatch()
  const { id } = usePathParams()
  const event = events.find((activity, index) => Number(id) === index)

  const [userDetails, setUserDetails] = useState<UserDetails | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { userDetails: fetchUserDetails } = useContext(ServerContext)

  useEffect(() => {
    ;(async () => {
      if (!event) {
        return
      }
      try {
        const query = buildQuery({
          search: encodeSearch(event.userEmail)
        })
        const {
          body: { response }
        }: UACServices.UserDetailsAPI.Responses = await fetchUserDetails(query)
        setUserDetails(response[0])
      } catch (e) {
        dispatch(globalActions.setNotification(genericFailedNotification))
      } finally {
        setIsLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  if (!event) {
    return null
  }

  const { data, userName, userEmail, dateTimeUtc, ip } = event

  const mainTitle = i18n._(
    userType === ActivityEventUserType.user
      ? userActivityReportTranslationMap[event.type]
      : adminActivityReportTranslationMap[event.type]
  )

  return (
    <Drawer closeLink={closeLink} mainTitle={<>{mainTitle}</>}>
      {isLoading && <Loading color="blue900" active />}
      <DetailsContainer>
        <DetailCell field={<Trans>Event information</Trans>} value={data} />
        <DetailCell field={<Trans>User</Trans>} value={userName || userEmail} />
        <DetailCell field={<Trans>IP address</Trans>} value={ip} />
        <DetailCell
          field={<Trans>Time</Trans>}
          value={moment(dateTimeUtc).format('MMMM D, YYYY [at] h:mm:ss A')}
        />
        {userDetails && (
          <>
            <DetailCell field={<Trans>Email</Trans>} value={userEmail} />
            <DetailCell
              field={<Trans>Last login</Trans>}
              value={moment(userDetails.lastLoginTimeUtc).format(
                'MMMM D, YYYY [at] h:mm:ss A'
              )}
            />
          </>
        )}
      </DetailsContainer>
    </Drawer>
  )
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import {
  BodyBold,
  DetailsDialog,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'
import { useBrowserSafeguard } from '@lastpass/ui/hooks'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationCompanyWideDoneDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`
const StyledBodyText = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 25px;
`

const StyledList = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
`

interface KeyRotationCompanyWideDoneDialogProps {
  state: KeyRotationCompanyWideDoneDialog
  actions: typeof globalActions
}

export const KeyRotationCompanyWideDoneDialogComponent: FunctionComponent<KeyRotationCompanyWideDoneDialogProps> = ({
  actions
}) => {
  useBrowserSafeguard()

  return (
    <DetailsDialog
      closeDialog={() => {}}
      hideCloseIcon
      width="600px"
      title={msg`Well done`}
      disableCloseOnBackground
      content={
        <>
          <TextBoxContainer>
            <Trans>
              Your K1 and K2 knowledge components have been rotated.
            </Trans>
          </TextBoxContainer>
          <StyledBodyText>
            <Trans>Have you pasted the new K1 to the service?</Trans>
            <StyledList>
              <li>
                <Trans>
                  If so, your users will continue using LastPass as if nothing
                  happened.
                </Trans>
              </li>
              <li>
                <Trans>
                  If not, go back to the previous screen and copy/paste it now.
                  Otherwise users <BodyBold>lose access</BodyBold> to LastPass.
                </Trans>
              </li>
            </StyledList>
          </StyledBodyText>
          <TextBoxContainer>
            <Trans>
              What next? Test the rotated components by logging in to LastPass
              using a federated account. Contact LastPass if you experience any
              issues.
            </Trans>
          </TextBoxContainer>
        </>
      }
      buttons={[
        <TextButton
          data-qa="PreviousScreenBtn"
          onClick={() => {
            actions.confirmDialog({ isConfirmed: false })
          }}
          key="discard"
        >
          <Trans>Previous screen</Trans>
        </TextButton>,
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            actions.confirmDialog({ isConfirmed: true })
          }}
          type="submit"
          key="confirm"
        >
          <Trans>All set</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

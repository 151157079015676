export enum Provider {
  ADConnector = 'ADConnector',
  AzureAD = 'AzureAD',
  Okta = 'Okta',
  OneLogin = 'OneLogin',
  Google = 'Google',
  PingOne = 'PingOne'
}

export interface AccountInformation {
  companyLastPassId: string
}

export interface DirectoryIntegrationBase {
  lastSync?: string
  version?: string
}

export interface ADConnectorDirectoryIntegration
  extends DirectoryIntegrationBase {
  hasApiKey: boolean
}

export interface DirectoryIntegration extends DirectoryIntegrationBase {
  hasProvisionToken: boolean
  provisionToken?: string
}

export interface GoogleDirectoryIntegration extends DirectoryIntegration {
  adminEmail?: string
  enabled: boolean
  integrationExists: boolean
  partialSyncEnabled: boolean
  groupIdsToSync: string[]
  userIdsToSync: string[]
}

export interface GoogleGroup {
  id: string
  name: string
  selected: boolean
  children: string[] | null
}

export interface GoogleUser {
  id: string
  name: string
  email: string
  selected: boolean
}

interface Log {
  id: number
  dateTimeUtc: string
  status: string
  eventType: string
}

export interface IntegrationLog extends Log {
  identity: string
  failureReason: string | null
  details: string | null
}

export interface SystemLog extends Log {
  logMessage: string | null
}

export interface DirectoriesIntegrationsState {
  isLoading: boolean
  isSyncing: boolean
  ADConnector: ADConnectorDirectoryIntegration
  AzureAD: DirectoryIntegration
  Okta: DirectoryIntegration
  OneLogin: DirectoryIntegration
  Google: GoogleDirectoryIntegration
  AccountInfo: AccountInformation
  PingOne: DirectoryIntegration
  AuditLog: {
    logs: IntegrationLog[] | SystemLog[]
    numberOfItems: number
    selectedAuditLog: IntegrationLog | SystemLog | null
    isAuditLogListLoading: boolean
    auditLogListFetchFailed: boolean
  }
  GoogleUsers: {
    isLoading: boolean
    users: GoogleUser[]
    nextPageToken: string | null
  }
  GoogleGroups: {
    isLoading: boolean
    groups: GoogleGroup[]
    searchBasedGroupSelection: boolean
    nextPageToken: string | null
  }
}

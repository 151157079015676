import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { ReactComponent as OpenExternalLinkIcon } from '@lastpass/assets/svg/admin-console/icon-open-external-link.svg'

interface SystemUpgradeFeatureBoxProps {
  title: React.ReactElement
  featureLinkText: React.ReactElement
  featureLinkUrl: string
  isExternalFeatureLink: boolean
  detailsLinkText: React.ReactElement
  detailsLinkUrl: string
  showBottomBorder: boolean
  textDisabled: boolean
}

const Container = styled.div<{ showBottomBorder: boolean }>`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0px;

  background: #ffffff;

  border-bottom: ${props =>
    props.showBottomBorder ? '1px solid #bac0ca' : 'none'};

  flex: none;
  order: 0;
  flex-grow: 0;
`
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  flex: none;
  order: 0;
  flex-grow: 1;
`
const DetailsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  flex: none;
  order: 1;
  flex-grow: 0;
`
const FeatureName = styled.div<{ textDisabled: boolean }>`
  display: flex;
  align-items: center;

  color: ${props => (props.textDisabled ? '#768498' : '#1D3049')};

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`
const FeatureLink = styled.a<{ textDisabled: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: ${props =>
    props.textDisabled ? '#768498' : props.theme.colors.neutral900} !important;

  flex: none;
  order: 0;
  flex-grow: 0;
`
const DetailsLink = styled.a`
  font-weight: bold;
`

const StyledOpenExternalLinkIcon = styled(OpenExternalLinkIcon)`
  width: 16px;
  height: 16px;
  margin-left: 5px;
`
const FeatureLinkContainer = styled.div`
  display: flex;
`
export const SystemUpgradeFeatureBox: FunctionComponent<SystemUpgradeFeatureBoxProps> = props => {
  const history = useHistory()
  return (
    <Container showBottomBorder={props.showBottomBorder}>
      <DetailsContainer>
        <FeatureName textDisabled={props.textDisabled} data-qa="FeatureBoxName">
          {props.title}
        </FeatureName>
        <FeatureLinkContainer>
          {props.isExternalFeatureLink ? (
            <FeatureLink
              textDisabled={props.textDisabled}
              href={props.featureLinkUrl}
              target="_blank"
              data-qa="FeatureBoxExternalLink"
            >
              {props.featureLinkText}
            </FeatureLink>
          ) : (
            <FeatureLink
              textDisabled={props.textDisabled}
              href="#"
              onClick={() => {
                history.push(props.featureLinkUrl)
              }}
              data-qa="FeatureBoxInternalLink"
            >
              {props.featureLinkText}
            </FeatureLink>
          )}

          <StyledOpenExternalLinkIcon />
        </FeatureLinkContainer>
      </DetailsContainer>
      <DetailsButtonContainer>
        {' '}
        <DetailsLink
          href="#"
          onClick={() => {
            history.push(props.detailsLinkUrl)
          }}
          data-qa="FeatureBoxDetailsLink"
        >
          {props.detailsLinkText}
        </DetailsLink>
      </DetailsButtonContainer>
    </Container>
  )
}

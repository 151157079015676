import styled from '@emotion/styled'

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #1d3049dd;
`

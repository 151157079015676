import React, { FC, useContext } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Heading200 } from '@lastpass/lastkit'
import { LogoCard } from '@lastpass/lastkit'

import { SecurityDashboardData } from '@lastpass/admin-console-dependencies/state/dashboard/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { GroupsWithLowSecurityScoreCard } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/security-score/groups-with-low-security-score/GroupsWithLowSecurityScoreCard'
import { SecurityDashboardOpportunitiesBox } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/security-score/opportunities-box/SecurityDashboardOpportunitiesBox'
import { SecurityScoreDonutChartCard } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/security-score/security-score-donut-chart-card/SecurityScoreDonutChartCard'
import { UsersWithLowSecurityScoreCard } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/security-score/users-with-low-security-score/UsersWithLowSecurityScoreCard'
import { toRoundedPercent } from '@lastpass/admin-console-dependencies/ui/home/utils/converters'

const AccordionContentContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 16px;
`
const DonutChartCardContainer = styled.div`
  display: flex;
  width: 100%;
`
const StyledLogoCard = styled(LogoCard)`
  background-color: ${props => props.theme.colors.blue50};
`

type SecurityScoreProps = Pick<
  SecurityDashboardData,
  | 'companySecurityScoreRate'
  | 'usersCountWithLowSecurityScore'
  | 'groupsCountWithLowSecurityScore'
>

export const SecurityScore: FC<SecurityScoreProps> = ({
  companySecurityScoreRate,
  usersCountWithLowSecurityScore,
  groupsCountWithLowSecurityScore
}) => {
  const permissions = useContext(PermissionContext)
  const hasAccessToPolicies = permissions.requirePermission(
    AdminPermissions.policiesModify
  )

  return (
    <StyledLogoCard
      expand
      hideSubtitleOnClose
      title={
        <Heading200 data-qa="SecurityScoreAccordionTitle">
          <Trans>Security score</Trans>
        </Heading200>
      }
      subtitle={
        <Trans>
          We added the security scores of all users and divided by the number of
          users.
        </Trans>
      }
      dataQaName="SecurityScoreAccordion"
    >
      <AccordionContentContainer>
        <DonutChartCardContainer>
          <SecurityScoreDonutChartCard
            rate={toRoundedPercent(companySecurityScoreRate)}
            dataQa="SecurityScoreDonutChartCard"
          />
        </DonutChartCardContainer>
        <UsersWithLowSecurityScoreCard
          count={usersCountWithLowSecurityScore}
          dataQa="UsersWithLowSecurityScoreCard"
        />
        <GroupsWithLowSecurityScoreCard
          count={groupsCountWithLowSecurityScore}
          dataQa="GroupsWithLowSecurityScoreCard"
        />
      </AccordionContentContainer>
      {hasAccessToPolicies && (
        <AccordionContentContainer>
          <SecurityDashboardOpportunitiesBox />
        </AccordionContentContainer>
      )}
    </StyledLogoCard>
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as AdvancedFeatureIllustration } from '@lastpass/assets/svg/admin-console/icon-advanced-feature.svg'
import { ReactComponent as IconLaptopLock } from '@lastpass/assets/svg/admin-console/icon-laptop-lock.svg'
import { ReactComponent as IconReports } from '@lastpass/assets/svg/admin-console/icon-reports.svg'
import { ReactComponent as IconVault } from '@lastpass/assets/svg/admin-console/icon-vault.svg'
import {
  BodyRegular,
  BodySemibold,
  Heading200,
  Heading300
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { PostTrialMfaAlert } from '@lastpass/admin-console-dependencies/ui/global/PostTrialMfaAlert'

import { ProtectedService } from '../ProtectedService'
import { PolicyCard } from './PolicyCard'

const MultifactorPageContainer = styled.div`
  margin: 0 24px;
`

const StyledHeading300 = styled(Heading300)`
  margin-bottom: 24px;
`

const StyledHeading200 = styled(Heading200)`
  margin-bottom: 24px;
`

const StyledBodyRegular = styled(BodyRegular)`
  display: block;
  margin-bottom: 24px;
`

const ProtectedServiceListElement = styled.li`
  margin-top: 24px;
  &:after {
    content: '';
    display: block;
    margin: 36px auto 0 64px;
    width: 95%;
    border-bottom: 1px solid ${props => props.theme.colors.neutral200};
  }
  &:last-child:after {
    border-bottom: none;
  }
`

const ServiceBodySection = styled.div`
  margin-top: 16px;
`

const StyledList = styled.li`
  list-style: disc;
  margin-left: 20px;
`

const StyledUl = styled.ul`
  margin-bottom: 20px;
`

const StyledUlWithBadge = styled.ul`
  display: flex;
  align-items: flex-start;
`

export const StyledSemiBold = styled(BodySemibold)`
  display: flex;
`

const StyledLink = styled(LocationLink)`
  display: flex;
  margin-bottom: 32px;
  margin-right: 16px;
`

const StyledAdvancedFeatureBadge = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`

interface Service {
  icon: React.FunctionComponent
  heading: React.ReactElement
  body?: React.ReactElement
  visible: boolean
}

const getServices: () => Service[] = () => [
  {
    icon: IconVault,
    heading: <Trans>LastPass vault</Trans>,
    visible: true
  },
  {
    icon: IconReports,
    heading: <Trans>Single sign-on apps</Trans>,
    visible: true,
    body: (
      <>
        <LocationLink data-qa="GoToWorkstationLink" to="/applications/saml">
          <Trans>
            <b>Go to SSO apps</b>
          </Trans>
        </LocationLink>
      </>
    )
  },
  {
    icon: IconLaptopLock,
    heading: <Trans>Other MFA apps {'&'} services</Trans>,
    visible: true,
    body: (
      <>
        <ServiceBodySection>
          <Trans>
            <b>Supported services</b>
          </Trans>
          <ul>
            <StyledList>
              <Trans>Windows {'&'} Mac workstations</Trans>
            </StyledList>
            <StyledList>
              <Trans>Microsoft Azure AD and ADFS</Trans>
            </StyledList>
            <StyledList>
              <Trans>On-premise services using LDAP or RADIUS</Trans>
            </StyledList>
          </ul>
        </ServiceBodySection>
        <ServiceBodySection>
          <LocationLink data-qa="GoToMFALink" to="/applications/mfa">
            <Trans>
              <b>Go to MFA apps</b>
            </Trans>
          </LocationLink>
        </ServiceBodySection>
      </>
    )
  }
]

export const MultifactorContentPageComponent: React.FunctionComponent<{
  mainPolicies: TeamsPolicy[]
  additionalPolicies: TeamsPolicy[]
}> = ({ mainPolicies, additionalPolicies }) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )
  const dispatch = useDispatch()

  const isTrialEligible = CompanyDetailsHelper.isUserBusinessTrialEligible(
    companyDetails
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const postTrialAlert =
    !userHasMfaAddon && !isTrialEligible ? <PostTrialMfaAlert /> : null

  const advancedPolicyClicked = () => {
    if (CompanyDetailsHelper.isMspOrManaged(companyDetails)) {
      dispatch(
        globalActions.showMfaUsageNotificationDialog(
          MfaUpsellDialogContext.multifactor,
          '/policies/multifactor/add-advanced'
        )
      )
      return
    }

    if (
      userHasMfaAddon ||
      CompanyDetailsHelper.isPermissionNull(companyDetails)
    ) {
      dispatch(
        multifactorActions.showAddAdvancedMfaPolicy(
          '/policies/multifactor/add-advanced'
        )
      )
      return
    }
    if (isTrialEligible) {
      dispatch(
        globalActions.showMfaUpsellDialog(
          CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
          MfaUpsellDialogContext.multifactor
        )
      )
    } else {
      dispatch(
        globalActions.showMfaAddonUpsellDialog(
          MfaUpsellDialogContext.multifactor,
          CompanyDetailsHelper.isEcommEnabled(companyDetails) || true
        )
      )
    }
  }

  return (
    <MultifactorPageContainer>
      <StyledHeading300 data-qa="MultifactorPageHeader">
        <Trans>Multifactor</Trans>
      </StyledHeading300>
      <StyledHeading200 data-qa="PoliciesSectionHeader">
        <Trans>Policies</Trans>
      </StyledHeading200>
      <ul>
        {mainPolicies.map(policy => (
          <PolicyCard
            policy={policy}
            key={policy.policyKey}
            canEditValue={false}
          />
        ))}
      </ul>
      <ul>
        {additionalPolicies.flatMap(policy =>
          policy.policyData.map((_, index) => (
            <PolicyCard
              policy={policy}
              key={policy.policyKey + index}
              dataIndex={index}
              canEditValue={true}
            />
          ))
        )}
      </ul>
      <StyledUl>{postTrialAlert}</StyledUl>
      <StyledUlWithBadge>
        <StyledSemiBold>
          <StyledLink
            to="/policies/multifactor/add"
            data-qa="AdditionalMfaPolicyLink"
          >
            <Trans>Add an additional Multifactor policy</Trans>
          </StyledLink>
        </StyledSemiBold>
        <StyledSemiBold>
          <StyledLink
            data-qa="AdvancedMfaPolicyLink"
            onClick={advancedPolicyClicked}
            to="#"
          >
            <Trans>Add an advanced policy</Trans>
            <StyledAdvancedFeatureBadge>
              <AdvancedFeatureIllustration data-qa="AdvancedFeatureIcon" />
            </StyledAdvancedFeatureBadge>
          </StyledLink>
        </StyledSemiBold>
      </StyledUlWithBadge>
      <StyledHeading200 data-qa="ProtectedServicesSectionHeader">
        <Trans>Protected services</Trans>
      </StyledHeading200>
      <StyledBodyRegular>
        <Trans>
          <b>Important:</b> Policy doesn{"'"}t apply to federated users.
        </Trans>
      </StyledBodyRegular>
      <StyledBodyRegular>
        <Trans>
          Once enrolled, users must verify their identity using multifactor
          authentication each time they log in to a protected service.
        </Trans>
      </StyledBodyRegular>
      <ul>
        {getServices()
          .filter(s => s.visible)
          .map((service, index) => (
            <ProtectedServiceListElement key={index}>
              <ProtectedService service={service} />
            </ProtectedServiceListElement>
          ))}
      </ul>
    </MultifactorPageContainer>
  )
}

export const MultifactorContentPage = MultifactorContentPageComponent

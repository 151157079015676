import React from 'react'

import { MfaAppsDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { AllSetComponent } from '@lastpass/admin-console-dependencies/ui/applications/mfa/drawersteps/assign/AllSet'
import { AssignComponent } from '@lastpass/admin-console-dependencies/ui/applications/mfa/drawersteps/assign/Assign'
import { AssignNewEntityComponent } from '@lastpass/admin-console-dependencies/ui/applications/mfa/drawersteps/assign/AssignNewEntity'
import { FinishIntegrationComponent } from '@lastpass/admin-console-dependencies/ui/applications/mfa/drawersteps/FinishIntegration'

import { CreateIntegration } from './drawersteps/CreateIntegration'
import { EditIntegrationComponent } from './drawersteps/EditIntegration'

const getStep = (step, close) => {
  const closeLink = `${close}${window.location.search}`
  switch (step) {
    case MfaAppsDrawerSteps.create: {
      return <CreateIntegration closeLink={closeLink} />
    }
    case MfaAppsDrawerSteps.finish: {
      return <FinishIntegrationComponent closeLink={closeLink} />
    }
    case MfaAppsDrawerSteps.edit: {
      return <EditIntegrationComponent closeLink={closeLink} />
    }
    case MfaAppsDrawerSteps.assign:
      return <AssignComponent closeLink={closeLink} />
    case MfaAppsDrawerSteps.newAssign: {
      return <AssignNewEntityComponent closeLink={closeLink} />
    }
    case MfaAppsDrawerSteps.allSet: {
      return <AllSetComponent closeLink={closeLink} />
    }
  }
}

export const MfaAppsDrawerPage = props => {
  const renderedStep = getStep(props.step, props.closeLink)
  return <>{renderedStep}</>
}

import React, { FunctionComponent, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as WarningTriangle } from '@lastpass/assets/svg/admin-console/icon-warning-triangle.svg'
import { TabNavigation } from '@lastpass/lastkit/components/TabNavigation'
import { TabNavigationItem } from '@lastpass/lastkit/components/TabNavigationItem'

import {
  AvailabilityStatus,
  PsaCompanyContractAssignment,
  PsaCompanyContractAssignmentOption
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { MultipleCompaniesAssignmentTable } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/multiple-companies/MultipleCompaniesAssignmentTable'
import {
  AgreementSelectionMessages,
  getAgreementSelectionMessages
} from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/helpers/get-agreement-selection-message'
import { useFilteredAssignmentOptions } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/hooks/use-filtered-assignment-options'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'

const StyledTabNavigationItem = styled(TabNavigationItem)`
  margin-left: 2px;
  cursor: pointer;
`
const StyledWarningTriangle = styled(WarningTriangle)`
  color: #edbf0e;
  height: 15px;
  position: relative;
  top: 3px;
`

export enum TabKeys {
  AUTO_SELECTED,
  NEEDS_SELECTION,
  PREVIOUSLY_SELECTED
}

const getWarningIcon = (
  assignmentOptions: PsaCompanyContractAssignmentOption[],
  messages: AgreementSelectionMessages
) => {
  const containsError = !!assignmentOptions.find(
    ({ company }) => messages[company.id]
  )
  return containsError ? (
    <StyledWarningTriangle data-qa="TabHeaderErrorIcon" />
  ) : null
}

const getFormattedCounter = (length: number) => (
  <>
    {' ('}
    <span data-qa="TabHeaderCounter">{length}</span>
    {')'}
  </>
)

interface MultipleCompaniesAgreementTabsProp {
  actualAssignmentsMap: AssignmentsMapping
  initialAssignmentsMap: AssignmentsMapping
  assignmentOptions: PsaCompanyContractAssignmentOption[]
  searchText: string
  autoSelectedList: PsaCompanyContractAssignmentOption[]
  needsSelectionList: PsaCompanyContractAssignmentOption[]
  previouslySelectedList: PsaCompanyContractAssignmentOption[]
  onClearSearch: () => void
  onAssignmentChange: (
    assignment: PsaCompanyContractAssignment,
    availability: AvailabilityStatus
  ) => void
}

export const MultipleCompaniesAssignmentTabs: FunctionComponent<MultipleCompaniesAgreementTabsProp> = ({
  actualAssignmentsMap,
  initialAssignmentsMap,
  assignmentOptions,
  searchText,
  autoSelectedList,
  needsSelectionList,
  previouslySelectedList,
  onAssignmentChange,
  onClearSearch
}) => {
  const [activeTabKey, setActiveTabKey] = useState<TabKeys | null>(null)
  const [messages, setMessages] = useState<AgreementSelectionMessages>({})

  const filteredAutoSelectedList = useFilteredAssignmentOptions(
    autoSelectedList,
    searchText
  )
  const filteredNeedsSelectionList = useFilteredAssignmentOptions(
    needsSelectionList,
    searchText
  )
  const filteredPreviouslySelectedList = useFilteredAssignmentOptions(
    previouslySelectedList,
    searchText
  )

  useEffect(() => {
    if (autoSelectedList.length) {
      setActiveTabKey(TabKeys.AUTO_SELECTED)
    } else if (needsSelectionList.length) {
      setActiveTabKey(TabKeys.NEEDS_SELECTION)
    } else {
      setActiveTabKey(TabKeys.PREVIOUSLY_SELECTED)
    }
  }, [autoSelectedList, needsSelectionList])

  useEffect(() => {
    const messages = getAgreementSelectionMessages(
      initialAssignmentsMap,
      actualAssignmentsMap,
      assignmentOptions
    )

    setMessages(messages)
  }, [assignmentOptions, actualAssignmentsMap, initialAssignmentsMap])

  const handleTabSelection = (tabKey: TabKeys) => setActiveTabKey(tabKey)

  return (
    <>
      <TabNavigation>
        {autoSelectedList.length > 0 && (
          <StyledTabNavigationItem
            active={activeTabKey === TabKeys.AUTO_SELECTED}
            onClick={() => handleTabSelection(TabKeys.AUTO_SELECTED)}
            data-qa="AutoSelectedTabItem"
          >
            <Trans>Auto-selected</Trans>
            {getFormattedCounter(filteredAutoSelectedList.length)}
            {getWarningIcon(filteredAutoSelectedList, messages)}
          </StyledTabNavigationItem>
        )}
        {needsSelectionList.length > 0 && (
          <StyledTabNavigationItem
            active={activeTabKey === TabKeys.NEEDS_SELECTION}
            onClick={() => handleTabSelection(TabKeys.NEEDS_SELECTION)}
            data-qa="NeedsSelectionTabItem"
          >
            <Trans>Needs selection</Trans>
            {getFormattedCounter(filteredNeedsSelectionList.length)}
            {getWarningIcon(filteredNeedsSelectionList, messages)}
          </StyledTabNavigationItem>
        )}
        {previouslySelectedList.length > 0 && (
          <StyledTabNavigationItem
            active={activeTabKey === TabKeys.PREVIOUSLY_SELECTED}
            onClick={() => handleTabSelection(TabKeys.PREVIOUSLY_SELECTED)}
            data-qa="PreviouslySelectedTabItem"
          >
            <Trans>Previously selected</Trans>
            {getFormattedCounter(filteredPreviouslySelectedList.length)}
            {getWarningIcon(filteredPreviouslySelectedList, messages)}
          </StyledTabNavigationItem>
        )}
      </TabNavigation>

      {activeTabKey === TabKeys.AUTO_SELECTED && (
        <MultipleCompaniesAssignmentTable
          actualAssignmentsMap={actualAssignmentsMap}
          assignmentOptions={filteredAutoSelectedList}
          qaData="AutoSelectedTable"
          searchText={searchText}
          onAssignmentChange={onAssignmentChange}
          onClearSearch={onClearSearch}
          messages={messages}
        />
      )}

      {activeTabKey === TabKeys.NEEDS_SELECTION && (
        <MultipleCompaniesAssignmentTable
          actualAssignmentsMap={actualAssignmentsMap}
          assignmentOptions={filteredNeedsSelectionList}
          qaData="NeedsSelectionTable"
          searchText={searchText}
          onAssignmentChange={onAssignmentChange}
          onClearSearch={onClearSearch}
          messages={messages}
        />
      )}

      {activeTabKey === TabKeys.PREVIOUSLY_SELECTED && (
        <MultipleCompaniesAssignmentTable
          actualAssignmentsMap={actualAssignmentsMap}
          assignmentOptions={filteredPreviouslySelectedList}
          qaData="PreviouslySelectedTable"
          searchText={searchText}
          onAssignmentChange={onAssignmentChange}
          onClearSearch={onClearSearch}
          messages={messages}
        />
      )}
    </>
  )
}

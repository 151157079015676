import React from 'react'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { AddUnlistedApp } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/AddUnlistedApp'
import { createAllSet } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/AllSet'
import { createAssign } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/Assign/Assign'
import { createAssignNewEntity } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/Assign/AssignNewEntity'
import { AssignTestUserComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/AssignTestUser'
import { ConfigureApp } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'
import { createEnableMultifactor } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/EnableMultifactor'
import { SelectApp } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/SelectApp'
import { TestLoginComponent } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/TestLogin'

const Assign = createAssign<AppState>()
const AssignNewEntity = createAssignNewEntity<AppState>()

const AllSet = createAllSet<AppState>()
const EnableMultiFactor = createEnableMultifactor<AppState>()

const getStep = (step, close) => {
  const closeLink = `${close}${window.location.search}`
  switch (step) {
    case ApplicationDrawerSteps.select: {
      return <SelectApp closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.configure: {
      return <ConfigureApp closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.assign: {
      return <Assign closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.newAssign: {
      return <AssignNewEntity closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.allset: {
      return <AllSet closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.addUnlistedApp: {
      return <AddUnlistedApp closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.enableMultifactor: {
      return <EnableMultiFactor closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.assignTestUser: {
      return <AssignTestUserComponent closeLink={closeLink} />
    }
    case ApplicationDrawerSteps.testLogin: {
      return <TestLoginComponent closeLink={closeLink} />
    }
  }
}

export const ApplicationsDrawerPage = props => {
  const renderedStep = getStep(props.step, props.closeLink)
  return <>{renderedStep}</>
}

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { EnumDictionary } from '@lastpass/admin-console-dependencies/ui/common/EnumValues'

export const PermissionTooltipTextMapping: EnumDictionary<
  AdminPermissions,
  ReactElement
> = {
  // Dashboard
  [AdminPermissions.dashboardView]: (
    <Trans>
      Allow members to view adoption and security info on the Admin Console
      dashboard.
    </Trans>
  ),

  // Managed companies
  [AdminPermissions.managedCompanyView]: (
    <Trans>
      Allow members view-only access to the Managed companies page in the
      primary account.
    </Trans>
  ),
  [AdminPermissions.managedCompanyCidOverride]: (
    <Trans>
      Allow members to access the Admin Console for managed companies under the
      primary account. Their admin level determines the actions they can take.
    </Trans>
  ),
  [AdminPermissions.addManagedCompany]: (
    <Trans>
      Allow members to create a new managed company under the primary account.
    </Trans>
  ),
  [AdminPermissions.suspendManagedCompany]: (
    <Trans>
      Allow members to suspend a managed company. Suspending returns all
      licenses to the primary account.
    </Trans>
  ),
  [AdminPermissions.detachManagedCompany]: (
    <Trans>
      Allow members to detach a managed company. Detaching removes the company
      from the primary account’s managed company list and converts it to an
      independent LastPass Business trial account
    </Trans>
  ),
  [AdminPermissions.managedCompanyLicenseManagement]: (
    <Trans>
      Allow members to allocate or return licenses to the primary account from
      the managed company.
    </Trans>
  ),
  [AdminPermissions.configureManagedServiceProviderPsa]: (
    <Trans>Allow members to make changes to PSA integration settings.</Trans>
  ),

  // Users and groups
  [AdminPermissions.usersView]: (
    <Trans>
      Allow members to view info on the Users page and in user profiles.
    </Trans>
  ),
  [AdminPermissions.usersModify]: (
    <Trans>
      Allow members to take action on the Users page and to modify info in user
      profiles.
    </Trans>
  ),
  [AdminPermissions.groupsModify]: (
    <Trans>
      Allow members to take action on the Groups page and group members and to
      assign/remove group members.
    </Trans>
  ),
  [AdminPermissions.directoriesAndFederationModify]: (
    <Trans>
      Allow members to set up third-party directory integrations and to
      configure federated login.
    </Trans>
  ),
  [AdminPermissions.userLevelMfaModify]: (
    <Trans>
      Allow members to modify multifactor options under Advanced {'>'}{' '}
      Enterprise options.
    </Trans>
  ),

  // Reporting
  [AdminPermissions.generalReportsView]: (
    <Trans>
      Allow members to view reports showing actions taken by users and admins in
      LastPass.
    </Trans>
  ),
  [AdminPermissions.reportingSsoLoginActivityView]: (
    <Trans>
      Allow members to view a report showing user login events through SSO.
    </Trans>
  ),
  [AdminPermissions.reportingSamlResponseView]: (
    <Trans>
      Allow members to view a report showing SAML response activity.
    </Trans>
  ),
  [AdminPermissions.reportingMfaUserActivityView]: (
    <Trans>
      Allow members to view a report showing user login events using MFA.
    </Trans>
  ),
  [AdminPermissions.reportingMfaAdminActivityView]: (
    <Trans>
      Allow members to view a report showing actions taken by admins in the
      Admin Console.
    </Trans>
  ),

  // Other
  [AdminPermissions.usersSitesView]: <></>,
  [AdminPermissions.usersMasterPasswordReset]: <></>,
  [AdminPermissions.usersPasswordsSettingsModify]: <></>,
  [AdminPermissions.usersSessionsDestroy]: <></>,
  [AdminPermissions.adminsModify]: <></>,
  [AdminPermissions.adminsSuperAdminAssign]: <></>,
  [AdminPermissions.ssoModify]: <></>,
  [AdminPermissions.legacyAndVpnModify]: <></>,
  [AdminPermissions.passwordAppsModify]: <></>,
  [AdminPermissions.sharedFoldersModify]: <></>,
  [AdminPermissions.mfaModify]: <></>,
  [AdminPermissions.policiesModify]: <></>,
  [AdminPermissions.enterpriseOptionsModify]: <></>,
  [AdminPermissions.installSoftwareModify]: <></>,
  [AdminPermissions.emailNotificationsModify]: <></>,
  [AdminPermissions.enterpriseApiModify]: <></>,
  [AdminPermissions.keysModify]: <></>,
  [AdminPermissions.companyProfileModify]: <></>,
  [AdminPermissions.managedCompanyAccess]: <></>,
  [AdminPermissions.siemIntegrationModify]: <></>,
  [AdminPermissions.viewManagedServiceProviderPsa]: <></>
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { SecureAuthSettingsGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { SecureAuthSettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createGetSecureAuthConfig(services: UACServices.Services) {
  return function* getSecureAuthConfig() {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      try {
        const response = yield call(services.secureAuthSettingsGet)

        if (response.type === SecureAuthSettingsGetAPI.SUCCESS) {
          const settings: SecureAuthSettingsState =
            response.body.secureAuthSettings

          yield put(
            multifactorDrawerActions.setSecureAuthConfig({
              applicationId: settings.applicationId,
              applicationKey: settings.applicationKey,
              realm: settings.realm
            })
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { BodyRegular, PrimaryButton } from '@lastpass/lastkit'
import { StyledSpacer } from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'

import { MfaAppsDrawerState } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/state'
import { BodyText } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'

const TopBodyText = styled.div`
  margin-bottom: 24px;
`

const ListSectionContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`

const ListTitle = styled.div`
  margin-bottom: 5px;
`

const ListItem = styled.div`
  margin-left: 15px;
  font-weight: bold;
`

const Bold = styled.span`
  font-weight: bold;
`
export interface AllSetProps {
  closeLink: string
}

const listCountLimit = 10

const GetDisplayableData = e => {
  let objects = (
    <ListItem data-qa="AllSetAssignedNumber">{e.totalResults}</ListItem>
  )
  if (e.totalResults > 0 && e.totalResults <= listCountLimit) {
    objects = e.results.map(u => {
      return (
        <ListItem data-qa="AllSetAssignedNumber" key={u.id}>
          {u.name}
        </ListItem>
      )
    })
  }

  return objects
}

export const AllSetComponent: React.FunctionComponent<AllSetProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const state: MfaAppsDrawerState = useSelector(
    (state: AppState) => state.mfaAppsDrawer
  )

  return (
    <>
      <BodyRegular>
        <BodyText>
          <TopBodyText data-qa="AllSetTopText">
            <Trans>
              You can now try logging into <Bold>{state.app.name}</Bold> with
              the assigned users or groups:
            </Trans>
          </TopBodyText>
          <ListSectionContainer data-qa="AllSetGroupsSection">
            <ListTitle>
              <Trans>Groups:</Trans>
            </ListTitle>
            {GetDisplayableData(state.groups)}
          </ListSectionContainer>
          <ListSectionContainer data-qa="AllSetUsersSection">
            <ListTitle>
              <Trans>Users:</Trans>
            </ListTitle>
            {GetDisplayableData(state.users)}
          </ListSectionContainer>
        </BodyText>
      </BodyRegular>
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <PrimaryButton
            id={'FinishButton'}
            key={'Finish'}
            onClick={() => history.push(closeLink)}
            data-qa={'FinishButton'}
          >
            <Trans>Finish</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as WorkstationLogin } from '@lastpass/assets/svg/admin-console/applications/workstation-login-illustration.svg'
import { BodyRegular, Heading300, PrimaryButton } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { PostTrialMfaAlert } from '@lastpass/admin-console-dependencies/ui/global/PostTrialMfaAlert'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHeading = styled(Heading300)`
  margin-top: 32px;
  max-width: 920px;
  text-align: center;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
`

const StyledBodyRegular = styled(BodyRegular)`
  display: flex;
  justify-content: center;
  max-width: 540px;
  text-align: center;
`

const StyledLocationLink = styled.a`
  margin-top: 40px;
`

export const MfaAppsEmptyList = ({ actions }) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isTrialEligible = CompanyDetailsHelper.isUserBusinessTrialEligible(
    companyDetails
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const postTrialAlert =
    !userHasMfaAddon && !isTrialEligible ? <PostTrialMfaAlert /> : null

  const onGetStartedClicked = () => {
    if (CompanyDetailsHelper.isMspOrManaged(companyDetails)) {
      actions.showMfaUsageNotificationDialog(
        MfaUpsellDialogContext.multifactor,
        '/applications/mfa/add'
      )
      return
    }

    if (
      userHasMfaAddon ||
      CompanyDetailsHelper.isPermissionNull(companyDetails)
    ) {
      actions.mfaApplicationsGetStarted('/applications/mfa/add')
      return
    }
    if (isTrialEligible) {
      actions.showMfaUpsellDialog(
        CompanyDetailsHelper.isEcommEnabled(companyDetails) || true,
        MfaUpsellDialogContext.multifactor
      )
    } else {
      actions.showMfaAddonUpsellDialog(
        MfaUpsellDialogContext.multifactor,
        CompanyDetailsHelper.isEcommEnabled(companyDetails) || true
      )
    }
  }

  return (
    <StyledContainer>
      <WorkstationLogin />
      <StyledHeading>
        <Trans>
          Protect workstations, Active Directory, and on-premise LDAP services
          with MFA
        </Trans>
      </StyledHeading>
      <StyledTextContainer>
        <StyledBodyRegular>
          <Trans>
            Enforce multifactor authentication (app push, TOTP, SMS, phone call,
            or YubiKey) on services that support identity provider integration.
          </Trans>
        </StyledBodyRegular>
      </StyledTextContainer>
      {postTrialAlert}
      <StyledPrimaryButton
        data-qa={'ApplicationsMfaAppsAddMfaAppButton'}
        onClick={onGetStartedClicked}
      >
        <Trans>Get started</Trans>
      </StyledPrimaryButton>
      <StyledLocationLink
        href="http://link.lastpass.com/help-mfa-apps"
        data-qa={'LearnMoreMfaAppsLink'}
        target="_blank"
      >
        <Trans>Learn more</Trans>
      </StyledLocationLink>
    </StyledContainer>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegular,
  ButtonsContainer,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton,
  TextInput
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { MultifactorDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/state'
import { MultifactorOptionType } from '@lastpass/admin-console-dependencies/state/policies/multifactor/multifactor'
import { BodyText } from '@lastpass/admin-console-dependencies/ui/applications/saml/add/drawerSteps/ConfigureApp'
import { MultifactorTypeAuthMethodMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/MultifactorMappings'

export interface SecureAuthConfigProps {
  closeLink: string
  isLastStep: boolean
}

const InputContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export const SecureAuthConfig: React.FunctionComponent<SecureAuthConfigProps> = ({
  closeLink,
  isLastStep
}) => {
  const multifactorDrawerState: MultifactorDrawerState = useSelector(
    (state: AppState) => state.multifactorDrawer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(multifactorDrawerActions.getSecureAuthConfig())
  }, [dispatch])

  return (
    <BodyRegular>
      <BodyText>
        <Trans>
          LastPass supports SecureAuth authentication. You must provide LastPass
          with your SecureAuth application ID, application key, and realm.
          LastPass uses an outbound firewall, so your server{"'"}s IP must be
          explicitly allowed by our Operations team. Please contact your
          LastPass account representative to request a change.
        </Trans>
      </BodyText>
      <Formik
        onSubmit={values => {
          if (isLastStep) {
            dispatch(
              multifactorDrawerActions.saveSecureAuthSettings(
                null,
                values,
                closeLink
              )
            )
          } else {
            dispatch(multifactorDrawerActions.setSecureAuthConfig(values))
            dispatch(multifactorDrawerActions.setIsConfigured(true))
            dispatch(
              multifactorDrawerActions.nextStep(MultifactorDrawerSteps.assign)
            )
          }
        }}
        initialValues={multifactorDrawerState.secureAuthConfig}
        enableReinitialize
      >
        {formikProps => (
          <Form>
            <InputContainer>
              <Field name="applicationId">
                {formData => (
                  <TextInput
                    data-qa="ApplicationId"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>Application ID</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="applicationKey">
                {formData => (
                  <TextInput
                    data-qa="ApplicationKey"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>Application key</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <InputContainer>
              <Field name="realm">
                {formData => (
                  <TextInput
                    data-qa="Realm"
                    name={formData.field.name}
                    value={formData.field.value}
                    onChange={e => formData.field.onChange(e)}
                  >
                    <Trans>Realm</Trans>
                  </TextInput>
                )}
              </Field>
            </InputContainer>
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <LocationLink to={closeLink}>
                  <TextButton
                    css={css`
                      margin-right: 16px;
                    `}
                    key="Discard"
                    data-qa="CancelButton"
                    onClick={() =>
                      dispatch(
                        multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                          MultifactorTypeAuthMethodMapping[
                            MultifactorOptionType.SecureAuth
                          ],
                          'Discard'
                        )
                      )
                    }
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <PrimaryButton
                  id="ContinueButton"
                  key="Continue"
                  type="submit"
                  disabled={isLastStep && !formikProps.dirty}
                  data-qa="ContinueButton"
                  onClick={() =>
                    dispatch(
                      multifactorDrawerActions.multifactorConfigureMfaMethodClicked(
                        MultifactorTypeAuthMethodMapping[
                          MultifactorOptionType.SecureAuth
                        ],
                        'Continue'
                      )
                    )
                  }
                >
                  {isLastStep ? (
                    <Trans>Save {'&'} exit</Trans>
                  ) : (
                    <Trans>Continue</Trans>
                  )}
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </BodyRegular>
  )
}

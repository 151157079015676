import { msg } from '@lingui/macro'
import { all, call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import { MfaTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createMfaDeviceUnlockSaga(userService: UACServices.Services) {
  return function* mfaDeviceUnlockSaga(
    action: ReturnType<typeof userDrawerActions.unlockMfaDevices>
  ) {
    const mfaDevices: MfaTrustedDevice[] = action.payload.mfaDevices
    const userId: string = action.payload.userId

    const texts =
      mfaDevices.length === 1
        ? {
            success: msg`Device has been unlocked.`,
            fail: msg`Device hasn't been unlocked.`
          }
        : {
            success: msg`The selected devices have been unlocked.`,
            fail: msg`None of the selected devices have been unlocked.`
          }
    const currentUrlFilters = yield select(getCurrentUrlFilters)

    if (userId && mfaDevices.length) {
      yield put(userDrawerActions.setUserTrustedDevicesLoading(true))
      try {
        const results: UACServices.EditMfaDeviceAPI.Responses[] = yield all(
          mfaDevices.map(mfaDevice =>
            call(userService.editMfaDevice, mfaDevice, userId, true)
          )
        )

        const failedMfaDevices: MfaTrustedDevice[] = []
        const successMfaDevices: MfaTrustedDevice[] = []

        results.forEach((result, index) => {
          if (result.type === UACServices.UserAppsAPI.SUCCESS) {
            successMfaDevices.push(mfaDevices[index])
          } else {
            failedMfaDevices.push(mfaDevices[index])
          }
        })

        if (failedMfaDevices.length === mfaDevices.length) {
          yield put(
            globalActions.setNotification({
              message: texts.fail,
              type: NotificationType.alert,
              autoDismiss: false
            })
          )
        } else if (successMfaDevices.length === mfaDevices.length) {
          yield put(
            globalActions.setNotification({
              message: texts.success,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`Some devices haven't been unlocked.`,
              type: NotificationType.alert,
              autoDismiss: false,
              details: {
                type: 'mfadevices',
                mfaDevices: failedMfaDevices
              }
            })
          )
        }
      } finally {
        yield put(
          userDrawerActions.getUserTrustedDevices({
            query: {},
            path: { id: userId }
          })
        )
        yield put(
          userListActions.getUserList({
            query: getQueryParams(currentUrlFilters.location, 'users'),
            path: {}
          })
        )
      }
    }
  }
}

import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo,
  TeamsPolicyValueType
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import {
  PasswordlessDrawerActions,
  PasswordlessDrawerActionTypes
} from './actions'
import {
  GeneralPolicyUsersAndGroupsFormState,
  PasswordlessDrawerState,
  PasswordlessDrawerSteps
} from './state'

const defaultAssignFormState: GeneralPolicyUsersAndGroupsFormState = {
  policyUsersAndGroups: {
    policyKey: '',
    policyConfigIndex: 0,
    policyConfigFormData: {
      appliesTo: AppliesToOptions.all,
      users: [],
      groups: []
    }
  },
  policyConfigUsers: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  policyConfigGroups: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  usersList: [],
  groupsList: []
}

export const initialState: PasswordlessDrawerState = {
  assignFormState: defaultAssignFormState,
  editFormState: defaultAssignFormState,
  usersAndGroupsNeedsRefresh: false,
  isUsersAndGroupsOpen: false,
  policyDataToSave: [],
  policy: {
    policyKey: '',
    policyData: [],
    policyTitle: '',
    policyShortDescription: '',
    policyDescription: '',
    policyValueType: TeamsPolicyValueType.none,
    checked: false,
    category: '',
    switchable: false
  }
}

export function passwordlessDrawerReducer(
  previousState: PasswordlessDrawerState | undefined,
  action: PasswordlessDrawerActions
): PasswordlessDrawerState {
  const state = previousState || initialState

  switch (action.type) {
    case PasswordlessDrawerActionTypes.SET_DRAWER_POLICY: {
      return {
        ...state,
        policy: action.payload
      }
    }
    case PasswordlessDrawerActionTypes.SET_DRAWER_STEP: {
      return {
        ...state,
        step: action.payload,
        searchString:
          action.payload === PasswordlessDrawerSteps.assign
            ? undefined
            : state.searchString
      }
    }
    case PasswordlessDrawerActionTypes.RESET_DRAWER_FORMS: {
      return {
        ...state,
        editFormState: defaultAssignFormState,
        assignFormState: defaultAssignFormState,
        usersAndGroupsNeedsRefresh: false,
        isUsersAndGroupsOpen: false,
        policyCurrentlyAppliesTo: undefined,
        searchString: undefined
      }
    }
    case PasswordlessDrawerActionTypes.SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.payload
      }
    }
    case PasswordlessDrawerActionTypes.SET_POLICY_CURRENTLY_APPLIES_TO: {
      return {
        ...state,
        policyCurrentlyAppliesTo: action.payload.policyCurrentlyAppliesTo
      }
    }
    case PasswordlessDrawerActionTypes.SET_POLICY_DATA_TO_SAVE: {
      return {
        ...state,
        policyDataToSave: action.payload
      }
    }
    case PasswordlessDrawerActionTypes.SET_POLICY_USERS_AND_GROUPS: {
      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyUsersAndGroups: {
            policyKey: action.payload.policyUsersAndGroups.policyKey,
            policyConfigIndex:
              action.payload.policyUsersAndGroups.policyConfigIndex,
            policyConfigFormData: {
              ...action.payload.policyUsersAndGroups.policyConfigFormData
            }
          },
          policyConfigUsers: {
            results:
              action.payload.policyUsersAndGroups.policyConfigFormData.users,
            totalResults:
              action.payload.policyUsersAndGroups.policyConfigFormData.users
                .length,
            resultsPerPage:
              action.payload.policyUsersAndGroups.policyConfigFormData.users
                .length,
            checkedRecords: state.editFormState.usersList
          },
          policyConfigGroups: {
            results:
              action.payload.policyUsersAndGroups.policyConfigFormData.groups,
            totalResults:
              action.payload.policyUsersAndGroups.policyConfigFormData.groups
                .length,
            resultsPerPage:
              action.payload.policyUsersAndGroups.policyConfigFormData.groups
                .length,
            checkedRecords: state.editFormState.groupsList
          }
        },
        usersAndGroupsNeedsRefresh: true
      }
    }
    case PasswordlessDrawerActionTypes.SET_POLICY_USERS_AND_GROUPS_NEEDS_REFRESH: {
      return {
        ...state,
        usersAndGroupsNeedsRefresh: action.payload
      }
    }
    case PasswordlessDrawerActionTypes.RESET_POLICY_USERS_AND_GROUPS_TABLES: {
      return {
        ...state,
        editFormState: defaultAssignFormState,
        assignFormState: defaultAssignFormState
      }
    }

    case PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_USERS: {
      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyConfigUsers: {
            ...state.editFormState.policyConfigUsers,
            checkedRecords: state.editFormState.policyConfigUsers.checkedRecords.concat(
              action.payload.userList
            )
          },
          usersList: state.editFormState.usersList.concat(
            action.payload.userList
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_USERS: {
      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyConfigUsers: {
            ...state.editFormState.policyConfigUsers,
            checkedRecords: state.editFormState.policyConfigUsers.checkedRecords.filter(
              el => !action.payload.userList.includes(el)
            )
          },
          usersList: state.editFormState.usersList.filter(
            el => !action.payload.userList.includes(el)
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_USERS: {
      let newList: TeamsPolicyUserInfo[] = []
      if (action.payload.userList.length === 0) {
        newList = state.editFormState.usersList.filter(
          el => !state.editFormState.policyConfigUsers.results.includes(el)
        )
      } else {
        const newlySelectedUsers = action.payload.userList.filter(
          el => !state.editFormState.usersList.includes(el)
        )
        newList = [...state.editFormState.usersList, ...newlySelectedUsers]
      }

      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyConfigUsers: {
            ...state.editFormState.policyConfigUsers,
            checkedRecords: [...newList]
          },
          usersList: newList
        }
      }
    }

    case PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_GROUPS: {
      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyConfigGroups: {
            ...state.editFormState.policyConfigGroups,
            checkedRecords: state.editFormState.policyConfigGroups.checkedRecords.concat(
              action.payload.groupList
            )
          },
          groupsList: state.editFormState.groupsList.concat(
            action.payload.groupList
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_GROUPS: {
      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyConfigGroups: {
            ...state.editFormState.policyConfigGroups,
            checkedRecords: state.editFormState.policyConfigGroups.checkedRecords.filter(
              el => !action.payload.groupList.includes(el)
            )
          },
          groupsList: state.editFormState.groupsList.filter(
            el => !action.payload.groupList.includes(el)
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_GROUPS: {
      let newList: TeamsPolicyGroup[] = []
      if (action.payload.groupList.length === 0) {
        newList = state.editFormState.groupsList.filter(
          el => !state.editFormState.policyConfigGroups.results.includes(el)
        )
      } else {
        const newlySelectedGroups = action.payload.groupList.filter(
          el => !state.editFormState.groupsList.includes(el)
        )
        newList = [...state.editFormState.groupsList, ...newlySelectedGroups]
      }
      return {
        ...state,
        editFormState: {
          ...state.editFormState,
          policyConfigGroups: {
            ...state.editFormState.policyConfigGroups,
            checkedRecords: [...newList]
          },
          groupsList: [...newList]
        }
      }
    }

    case PasswordlessDrawerActionTypes.SET_POLICY_USERS_AND_GROUPS_OPEN: {
      return {
        ...state,
        isUsersAndGroupsOpen: action.payload.isUsersAndGroupsOpen
      }
    }

    case PasswordlessDrawerActionTypes.SET_POLICY_ASSIGN_USERS_LOADING: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigUsers: {
            ...state.assignFormState.policyConfigUsers,
            isLoading: action.payload
          }
        }
      }
    }
    case PasswordlessDrawerActionTypes.SET_POLICY_ASSIGN_GROUPS_LOADING: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigGroups: {
            ...state.assignFormState.policyConfigGroups,
            isLoading: action.payload
          }
        }
      }
    }
    case PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_ASSIGN_USERS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigUsers: {
            ...state.assignFormState.policyConfigUsers,
            checkedRecords: state.assignFormState.policyConfigUsers.checkedRecords.concat(
              action.payload.userList
            )
          },
          usersList: state.assignFormState.usersList.concat(
            action.payload.userList
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_ASSIGN_USERS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigUsers: {
            ...state.assignFormState.policyConfigUsers,
            checkedRecords: state.assignFormState.policyConfigUsers.checkedRecords.filter(
              el => !action.payload.userList.includes(el)
            )
          },
          usersList: state.assignFormState.usersList.filter(
            el => !action.payload.userList.includes(el)
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_ASSIGN_USERS: {
      let newList: TeamsPolicyUserInfo[] = []
      if (action.payload.userList.length === 0) {
        newList = state.assignFormState.usersList.filter(
          el => !state.assignFormState.policyConfigUsers.results.includes(el)
        )
      } else {
        const newlySelectedUsers = action.payload.userList.filter(
          el => !state.assignFormState.usersList.includes(el)
        )
        newList = [...state.assignFormState.usersList, ...newlySelectedUsers]
      }

      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigUsers: {
            ...state.assignFormState.policyConfigUsers,
            checkedRecords: [...newList]
          },
          usersList: newList
        }
      }
    }

    case PasswordlessDrawerActionTypes.ADD_SELECTED_POLICY_ASSIGN_GROUPS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigGroups: {
            ...state.assignFormState.policyConfigGroups,
            checkedRecords: state.assignFormState.policyConfigGroups.checkedRecords.concat(
              action.payload.groupList
            )
          },
          groupsList: state.assignFormState.groupsList.concat(
            action.payload.groupList
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.REMOVE_SELECTED_POLICY_ASSIGN_GROUPS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigGroups: {
            ...state.assignFormState.policyConfigGroups,
            checkedRecords: state.assignFormState.policyConfigGroups.checkedRecords.filter(
              el => !action.payload.groupList.includes(el)
            )
          },
          groupsList: state.assignFormState.groupsList.filter(
            el => !action.payload.groupList.includes(el)
          )
        }
      }
    }
    case PasswordlessDrawerActionTypes.SET_SELECTED_POLICY_ASSIGN_GROUPS: {
      let newList: TeamsPolicyGroup[] = []
      if (action.payload.groupList.length === 0) {
        newList = state.assignFormState.groupsList.filter(
          el => !state.assignFormState.policyConfigGroups.results.includes(el)
        )
      } else {
        const newlySelectedGroups = action.payload.groupList.filter(
          el => !state.assignFormState.groupsList.includes(el)
        )
        newList = [...state.assignFormState.groupsList, ...newlySelectedGroups]
      }
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyConfigGroups: {
            ...state.assignFormState.policyConfigGroups,
            checkedRecords: [...newList]
          },
          groupsList: [...newList]
        }
      }
    }
    case PasswordlessDrawerActionTypes.UPDATE_POLICY_ASSIGN_USERS_AND_GROUPS_TO_BE_ADDED: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          groupsList: [...action.payload.toBeAddedGroups],
          usersList: [...action.payload.toBeAddedUsers]
        }
      }
    }
    case PasswordlessDrawerActionTypes.SET_POLICY_ASSIGN_USERS_AND_GROUPS: {
      return {
        ...state,
        assignFormState: {
          ...state.assignFormState,
          policyUsersAndGroups: {
            policyKey: action.payload.policyKey,
            policyConfigIndex: action.payload.policyConfigIndex,
            policyConfigFormData: {
              ...action.payload.policyConfigFormData
            }
          },
          policyConfigUsers: {
            results: action.payload.policyConfigFormData.users,
            totalResults: action.payload.policyConfigFormData.users.length,
            resultsPerPage: action.payload.policyConfigFormData.users.length,
            checkedRecords: state.assignFormState.usersList
          },
          policyConfigGroups: {
            results: action.payload.policyConfigFormData.groups,
            totalResults: action.payload.policyConfigFormData.groups.length,
            resultsPerPage: action.payload.policyConfigFormData.groups.length,
            checkedRecords: state.assignFormState.groupsList
          }
        },
        usersAndGroupsNeedsRefresh: true
      }
    }
  }

  return state
}

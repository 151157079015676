import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'

export function createGetUserAdoptionSaga(uacService: UACServices.Services) {
  return function* getUserAdoptionSaga() {
    try {
      const companyLicenseResult: UACServices.CompanyLicensesAPI.Responses = yield call(
        uacService.companyLicenses
      )

      const query = buildQuery({
        filter: { accountStatus: { eq: AccountStatus.staged } },
        top: 0
      })
      const stagedUsers: UACServices.UsersAPI.Responses = yield call(
        uacService.users,
        query
      )
      if (
        companyLicenseResult.type === UACServices.CompanyLicensesAPI.SUCCESS &&
        stagedUsers.type === UACServices.UsersAPI.SUCCESS
      ) {
        const dashboardState: {
          remainingLicenses: number
          stagedUserCount: number
          totalLicenses: number
        } = {
          remainingLicenses: companyLicenseResult.body.remainingLicenses,
          totalLicenses: companyLicenseResult.body.totalLicenses,
          stagedUserCount: stagedUsers.body.totalResults
        }
        yield put(dashboardActions.setUserAdoptions(dashboardState))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

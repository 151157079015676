import {
  EquivalentDomainsActions,
  EquivalentDomainsActionTypes
} from './actions'
import { EquivalentDomainsState } from './state'

export const initialState: EquivalentDomainsState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export const equivalentDomains = (
  previousState: EquivalentDomainsState | undefined,
  action: EquivalentDomainsActions
): EquivalentDomainsState => {
  const state = previousState || initialState
  switch (action.type) {
    case EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_SET:
      return {
        table: {
          ...state.table,
          results: action.payload.equivalentDomainsList,
          checkedRecords: []
        }
      }
    case EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_LOADING_SET:
      return {
        table: {
          ...state.table,
          isLoading: action.payload.isLoading
        }
      }
    case EquivalentDomainsActionTypes.ADD_SELECTED_EQUIVALENT_DOMAINS:
      return {
        ...state,
        table: {
          ...state.table,
          checkedRecords: state.table.checkedRecords.concat(
            action.payload.equivalentDomainsList
          )
        }
      }

    case EquivalentDomainsActionTypes.REMOVE_SELECTED_EQUIVALENT_DOMAINS:
      return {
        ...state,
        table: {
          ...state.table,
          checkedRecords: state.table.checkedRecords.filter(
            el => !action.payload.equivalentDomainsList.includes(el)
          )
        }
      }

    case EquivalentDomainsActionTypes.SET_SELECTED_EQUIVALENT_DOMAINS:
      return {
        ...state,
        table: {
          ...state.table,
          checkedRecords: action.payload.equivalentDomainsList
        }
      }
    case EquivalentDomainsActionTypes.EQUIVALENT_DOMAINS_ADD_NEW:
      return {
        ...state,
        table: {
          ...state.table,
          results: [...state.table.results, action.payload.equivalentDomains]
        }
      }

    default:
      return state
  }
}

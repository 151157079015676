import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import buildQuery from 'odata-query'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Autocomplete } from '@lastpass/lastkit/components/Autocomplete'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { userFilterActions } from '@lastpass/admin-console-dependencies/state/common/user-filter/actions'
import { UserFilterState } from '@lastpass/admin-console-dependencies/state/common/user-filter/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { UsersFilter } from '@lastpass/admin-console-dependencies/types/users-filter'

interface SelectUsersProps {
  updateSelection: (update: string[]) => void
  filters?: string[]
  asyncThreshold?: number
  initialResults?: number
}

export const SelectUsers: FunctionComponent<SelectUsersProps> = ({
  updateSelection,
  filters = [],
  initialResults = 0,
  asyncThreshold = 1000
}) => {
  const dispatch = useDispatch()
  const { userFilter } = useContext(ServerContext)

  const [totalResults, setTotalResults] = useState<number>(initialResults)
  const [initialList, setInitialList] = useState<UsersFilter[]>([])

  const userFilterState = useSelector<AppState, UserFilterState>(
    appState => appState.userFilter
  )

  const autocompleteLoadOptions = async (queryString: string) => {
    try {
      const query = buildQuery({
        search: encodeSearch(queryString),
        top: 100
      })
      const result = await userFilter(query)

      return result.type === UACServices.UsersAPI.SUCCESS
        ? result.body.usersList
        : []
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
      return []
    }
  }

  const getUserList = useCallback(async () => {
    try {
      const query = buildQuery({
        params: {
          query: {},
          path: {}
        },
        top: asyncThreshold
      })

      const result = await userFilter(query)

      if (result.type === UACServices.UsersAPI.SUCCESS) {
        setTotalResults(result.body.totalResults)
        setInitialList(result.body.usersList)
      }
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    }
  }, [asyncThreshold, dispatch, userFilter])

  useEffect(() => {
    getUserList().then()
  }, [getUserList])

  return (
    <Autocomplete
      closeMenuOnSelect={false}
      placeholder={i18n._(msg`Show all users`)}
      options={totalResults < asyncThreshold ? initialList : []}
      hideSelectedOptions={false}
      isMulti
      fluid
      value={filters.map(userId => ({
        value: userId,
        label: userFilterState.idMapping[userId] || ''
      }))}
      onChange={selectedItems => {
        if (!Array.isArray(selectedItems)) {
          return
        }
        updateSelection(selectedItems.map(item => item.value))
        dispatch(userFilterActions.setUserListByIdList(selectedItems))
      }}
      loadOptions={
        totalResults >= asyncThreshold ? autocompleteLoadOptions : null
      }
    >
      <Trans>User</Trans>
    </Autocomplete>
  )
}

import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  matchPath,
  Redirect,
  Route,
  useLocation,
  useRouteMatch
} from 'react-router-dom'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { IconButton } from '@lastpass/lastkit'
import { AnimatedSwitch, LocationLink } from '@lastpass/routing'

import { AdminListState } from '@lastpass/admin-console-dependencies/state/users/admin/list/state'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { NewAdminLevelDrawer } from '@lastpass/admin-console-dependencies/ui/users/admin/add/NewAdminLevelDrawer'
import { AssignUsersDrawer } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/add/AssignUsersDrawer'
import { AdminLevelDetailsDrawer } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/AdminLevelDetailsDrawer'
import { EditAdminLevelDrawer } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/edit/EditAdminLevelDrawer'
import { ManagedCompanyAccessDrawer } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/managed-admin-permissions/ManagedCompanyAccessDrawer'
import { ViewAndEditAssignedUsersDrawer } from '@lastpass/admin-console-dependencies/ui/users/admin/admin-id/users/ViewAndEditAssignedUsersDrawer'
import { AdminLevels } from '@lastpass/admin-console-dependencies/ui/users/admin/AdminLevels'
import { MAXIMUM_ADMIN_LEVELS } from '@lastpass/admin-console-dependencies/ui/users/admin/components/AdminLevelsErrorText'

export const AdminLevelsPage: React.FunctionComponent = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const permissions = useContext(PermissionContext)
  const adminList: AdminListState = useSelector(
    (state: AppState) => state.adminList
  )

  const hasReachedAdminLevelsLimit =
    adminList.table.totalResults >= MAXIMUM_ADMIN_LEVELS

  const isRoleBasedAccessControlEnabled = useFeatureFlags(
    FeatureFlags.isRoleBasedAccessControlEnabled
  )

  if (!match) {
    return null
  }

  const { url, path } = match
  const drawerPath = `${path}/:id`
  const drawerMatch = matchPath(location.pathname, drawerPath)

  const canModifyAdminLevels = permissions.requirePermission(
    AdminPermissions.adminsModify
  )

  let backButton = <></>

  // If the drawer is shown but it is not exact that means a child view is shown
  if (drawerMatch && !drawerMatch.isExact) {
    backButton = (
      <LocationLink to={drawerMatch.url}>
        <IconButton icon={ChevronLeft} data-qa="BackButton" />
      </LocationLink>
    )
  }

  return (
    <>
      <AdminLevels />
      <AnimatedSwitch timeout={200}>
        {canModifyAdminLevels && !hasReachedAdminLevelsLimit && (
          <Route path={`${path}/add`}>
            <NewAdminLevelDrawer closeLink={url} />
          </Route>
        )}
        {canModifyAdminLevels && (
          <Route path={`${path}/:id/add`}>
            <AssignUsersDrawer closeLink={url} backButton={backButton} />
          </Route>
        )}
        {isRoleBasedAccessControlEnabled && canModifyAdminLevels && (
          <Route path={`${path}/:id/managed-admin-permissions`}>
            <ManagedCompanyAccessDrawer closeLink={url} />
          </Route>
        )}
        {canModifyAdminLevels && (
          <Route path={`${path}/:id/users`}>
            <ViewAndEditAssignedUsersDrawer
              closeLink={url}
              backButton={backButton}
            />
          </Route>
        )}
        {Object.values(AdminLevel).map((adminLevel, index) => (
          <Redirect
            strict
            key={index}
            from={`${path}/${adminLevel}/edit`}
            to={`${path}/${adminLevel}`}
          />
        ))}
        {canModifyAdminLevels && (
          <Route path={`${path}/:id/edit`}>
            <EditAdminLevelDrawer closeLink={url} />
          </Route>
        )}
        <Route path={`${path}/:id`}>
          <AdminLevelDetailsDrawer closeLink={url} backButton={backButton} />
        </Route>
      </AnimatedSwitch>
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { TeamsPolicyUserInfo } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const SUCCESS = 'success'

const success = (policyUsersList: TeamsPolicyUserInfo[]) =>
  createResponse(SUCCESS, { policyUsersList })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service(searchKey: string) {
    try {
      const [response, status] = await teamsapi(
        `my-company/searchuser/${searchKey}`,
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const policyUsersResponse = JSON.parse(response)
        return success(policyUsersResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

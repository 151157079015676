import React from 'react'

import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'

import { BodyRegularStyle } from '@lastpass/lastkit'

import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import { PolicyUsersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PolicyUsersMapping'

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

export const StyledRadioInput = styled(Field)`
  cursor: pointer;
`

export const StyledRadioLabel = styled.label`
  ${BodyRegularStyle}
  cursor: pointer;
`

export const AppliesToRadioButtons: React.FunctionComponent<{
  onChange: (currentlyAppliesTo: AppliesToOptions) => void
  disabled?: boolean
  initValue: AppliesToOptions
}> = ({ onChange, disabled, initValue }) => {
  const policyAppliesToRadioOptions: AppliesToOptions[] = [
    AppliesToOptions.all,
    AppliesToOptions.inclusive,
    AppliesToOptions.exclusive
  ]

  return (
    <Formik
      initialValues={{
        users: initValue
      }}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <Form>
          <RadioContainer role="group">
            {policyAppliesToRadioOptions.map(option => (
              <StyledRadioLabel key={option}>
                <StyledRadioInput
                  type="radio"
                  name="users"
                  value={option}
                  checked={values.users === option}
                  onClick={() => onChange(option)}
                  disabled={disabled}
                />
                {PolicyUsersMapping[option]}
              </StyledRadioLabel>
            ))}
          </RadioContainer>
        </Form>
      )}
    </Formik>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { AnimatedSwitch } from '@lastpass/routing'

import { PsaCredentialsState } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { MultipleCompaniesAgreementDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/components/multiple-companies/MultipleCompaniesAgreementDrawer'
import { EditPsaDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/edit/EditPsaDrawer'
import { ProductSelectionDrawer } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/products/components/ProductSelectionDrawer'
import { PsaProviderCard } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/providers/components/PsaProviderCard'

const StyledList = styled.ul`
  margin: 24px;
`

function sortMetadata(activeProvider: string | null) {
  return (a: PsaCredentialsState, b: PsaCredentialsState) => {
    if (a.provider === activeProvider) {
      return -1
    } else if (b.provider === activeProvider) {
      return 1
    } else {
      return a.provider.localeCompare(b.provider)
    }
  }
}

export enum PsaProviders {
  Datto = 'Datto Autotask',
  ConnectWise = 'ConnectWise Manage'
}

export const PsaIntegrations: FunctionComponent = () => {
  const match = useRouteMatch()
  const { activePsa, metadata } = useSelector(
    (state: AppState) => state.psaIntegrations
  )
  const activeProvider = activePsa ? activePsa.provider : null
  const [sortedMetadata, setSortedMetadata] = useState<PsaCredentialsState[]>(
    []
  )

  useEffect(() => {
    setSortedMetadata(metadata.sort(sortMetadata(activeProvider)))
  }, [metadata, activeProvider])

  if (!match) {
    return null
  }

  return (
    <>
      <StyledList data-qa="PsaIntegrationsList">
        {sortedMetadata.map(psa => (
          <PsaProviderCard
            key={psa.provider}
            provider={psa.provider}
            activeProvider={activeProvider}
          />
        ))}
      </StyledList>
      <AnimatedSwitch timeout={200}>
        <Route path={`${match.path}/credentials/:id/edit`}>
          <EditPsaDrawer
            basePath={match.url}
            title={<Trans>Set up connection</Trans>}
          />
        </Route>
        <Route path={`${match.path}/billing/agreements`}>
          <MultipleCompaniesAgreementDrawer
            basePath={match.url}
            title={<Trans>Select agreements for billing</Trans>}
          />
        </Route>
        <Route path={`${match.path}/billing/products`}>
          <ProductSelectionDrawer
            previousUrl={match.url}
            title={<Trans>Select products for billing</Trans>}
          />
        </Route>
      </AnimatedSwitch>
    </>
  )
}

import React, { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { LogoCard } from '@lastpass/lastkit'
import { Heading200 } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { LicenseConsumptionRateAccordionChips } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/license-consumption-rate/accordion-chips/LicenseConsumptionRateAccordionChips'
import { LicenseConsumptionRateDonutChartCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/license-consumption-rate/donut-chart-card/LicenseConsumptionRateDonutChartCard'
import { StagedUsersCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/license-consumption-rate/staged-users-card/StagedUsersCard'
import { TotalLicensesCard } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/license-consumption-rate/total-licenses-card/TotalLicensesCard'

const DonutChartCardContainer = styled.div`
  display: flex;
  width: 100%;
`
const ActionCardsContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`
const AccordionContentContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  margin-right: 16px;
`
const StyledLogoCard = styled(LogoCard)<{}>`
  background-color: ${props => props.theme.colors.blue50};
`
interface LicenseConsumptionRateProps {
  stagedUsersCount: number
  availableLicensesCount: number
  licenseCount: number
  licensesConsumed: number
  rate: number
  isOpen: boolean
}

export const LicenseConsumptionRate: FC<LicenseConsumptionRateProps> = ({
  stagedUsersCount,
  availableLicensesCount,
  licenseCount,
  licensesConsumed,
  rate,
  isOpen
}) => {
  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const shouldShowTotalLicensesCard = !(
    CompanyDetailsHelper.isMspOrManaged(companyDetails) ||
    CompanyDetailsHelper.isAggregatorMsp(companyDetails) ||
    CompanyDetailsHelper.isDirectMsp(companyDetails)
  )

  const subtitle: ReactElement = (
    <Trans>
      Out of {licenseCount} purchased licenses (also called seats) you’ve
      consumed {licensesConsumed}. Updated in real time.
    </Trans>
  )

  return (
    <StyledLogoCard
      expand={isOpen}
      hideSubtitleOnClose
      title={
        <Heading200 data-qa="LicenseConsumptionRateAccordionTitle">
          <Trans>License consumption rate</Trans>
        </Heading200>
      }
      subtitle={subtitle}
      contentOnClosed={
        <LicenseConsumptionRateAccordionChips
          stagedUsersCount={stagedUsersCount}
          licenseCount={licenseCount}
          licenseConsumptionRate={rate}
          licenseConsumptionRateTooltip={subtitle}
        />
      }
      dataQaName="LicenseConsumptionRateAccordion"
    >
      <AccordionContentContainer>
        <DonutChartCardContainer>
          <LicenseConsumptionRateDonutChartCard
            licensesConsumedCount={licensesConsumed}
            availableLicensesCount={availableLicensesCount}
            licenseConsumptionRate={rate}
            dataQa="LicenseConsumptionRateChartCard"
          />
        </DonutChartCardContainer>
        <ActionCardsContainer>
          <StagedUsersCard
            count={stagedUsersCount}
            dataQa="LicenseConsumptionRateStagedUsersCard"
          />
          {shouldShowTotalLicensesCard && (
            <TotalLicensesCard
              count={licenseCount}
              dataQa="LicenseConsumptionRateTotalLicensesCard"
            />
          )}
        </ActionCardsContainer>
      </AccordionContentContainer>
    </StyledLogoCard>
  )
}

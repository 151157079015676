import { AdminOperationType } from '../operations/admin-operations'
import { AdminListActions, AdminListActionTypes } from './actions'
import { AdminListState } from './state'

export const initialState: AdminListState = {
  table: {
    columns: [],
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: true,
    operations: [AdminOperationType.deleteAdmins]
  }
}

export function adminListReducer(
  previousState: AdminListState | undefined,
  action: AdminListActions
): AdminListState {
  const state = previousState || initialState
  switch (action.type) {
    case AdminListActionTypes.SET_ADMIN_LIST: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case AdminListActionTypes.ADD_SELECTED_ADMINS: {
      return {
        ...state,
        table: {
          ...state.table,
          checkedRecords: state.table.checkedRecords.concat(
            action.payload.admins
          )
        }
      }
    }
    case AdminListActionTypes.REMOVE_SELECTED_ADMINS: {
      return {
        ...state,
        table: {
          ...state.table,
          checkedRecords: state.table.checkedRecords.filter(
            el => !action.payload.admins.includes(el)
          )
        }
      }
    }
    case AdminListActionTypes.SET_SELECTED_ADMINS: {
      return {
        ...state,
        table: {
          ...state.table,
          checkedRecords: action.payload.admins
        }
      }
    }
    case AdminListActionTypes.SET_ADMINS_LOADING: {
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: action.payload.loading
        }
      }
    }
  }
  return state
}

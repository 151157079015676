import { msg } from '@lingui/macro'
import { put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { VaultReEncryptionDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

export const getAdminLevelSelector = (state: AppState) =>
  state.usersList.currentAdminLevel

export function* vaultReEncryptionOfferOnSave() {
  const currentAdminLevel = yield select(getAdminLevelSelector)
  const isSuperAdmin = currentAdminLevel === AdminLevel.superAdmin
  if (!isSuperAdmin) {
    return
  }

  const vaultReEncryptionDialog: VaultReEncryptionDialog = {
    type: 'vault-re-encryption-dialog',
    title: msg`New! Recurring and forced vault re-encryption`
  }
  yield put(globalActions.setDialog(vaultReEncryptionDialog))
  yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])
  yield put(globalActions.emptyDialog())
}

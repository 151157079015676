import { call } from 'redux-saga/effects'

import { FederationAPI } from '@lastpass/federation'
import { FederationApiError } from '@lastpass/federation/lib/api/federation-api-error'
import { FederationErrors } from '@lastpass/federation/lib/federation-error-codes'
import { IdpUserData, UserK1 } from '@lastpass/federation/types/federation-api'

import { keyRotationDebug } from '@lastpass/admin-console-dependencies/sagas/users/federated-login/key-rotation/key-rotation'
import * as UACServices from '@lastpass/admin-console-dependencies/server/index'

import { KeyRotationError } from './key-rotation-error'
import { KeyRotationErrors } from './key-rotation-error-codes'

export function* keyRotationFixUnSynchronizedUsers(
  userService: UACServices.Services,
  idpUsers: IdpUserData[],
  federationAPI: FederationAPI
) {
  // search for unSynchronized users
  const unSynchronizedUsers = idpUsers.filter(
    user => user.syncSession && user.syncId
  )

  if (unSynchronizedUsers.length === 0) {
    return
  }

  keyRotationDebug(`Fixing ${unSynchronizedUsers.length} un-synced users`)

  // when the `unSynchronizedUsers` array is not empty that means the previous key-rotation flow
  // was interrupted by an unexpected event (network outage, power outage, server issue etc)
  // for these users the IDP is already updated with the new K1 value, the new K2 value is written into a
  // temporary ALP table, but it needs to be synced using a sessionToken and an ALP External ID

  const alpIdsGroupedBySessionTokens = unSynchronizedUsers.reduce(
    (acc, curr) => {
      if (curr.syncSession && curr.syncId) {
        acc[curr.syncSession] = acc[curr.syncSession] || []
        acc[curr.syncSession].push(curr.syncId)
      }
      return acc
    },
    {}
  )

  for (const sessionToken in alpIdsGroupedBySessionTokens) {
    const alpIdsToSync = alpIdsGroupedBySessionTokens[sessionToken]
    // sync the unSynchronized users in ALP, using the saved sessionToken and ALP Ids
    if (alpIdsToSync.length) {
      yield call(
        userService.syncKeyRotationData,
        sessionToken,
        KeyRotationErrors.UnsynchronisedUsersAlpSyncFailed,
        alpIdsToSync
      )

      keyRotationDebug(
        `Synching ALP IDs ${alpIdsToSync} with sessionToken ${sessionToken}`
      )
    }
  }

  // clean-up the temporary sync data in the IDP
  const chunkSize = federationAPI.config.batchLimit || 20
  for (let i = 0; i < unSynchronizedUsers.length; i += chunkSize) {
    const chunk: UserK1[] = unSynchronizedUsers.slice(i, i + chunkSize)
    try {
      yield call(federationAPI.batchUpdateUsersK1, {
        usersData: chunk
      })
    } catch (error) {
      if (error instanceof FederationApiError) {
        switch (error.federationErrorCode) {
          case FederationErrors.IdpBatchUpdateUsersBatchLimitExceeded:
            throw new KeyRotationError({
              feErrorCode:
                KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateBatchLimitExceeded
            })
          case FederationErrors.IdpBatchUpdateUsersConnectionFailed:
            throw new KeyRotationError({
              feErrorCode:
                KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateConnectionFailed
            })
          case FederationErrors.IdpBatchUpdateUsersFailed:
            throw new KeyRotationError({
              feErrorCode:
                KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateFailed,
              httpErrorCode: error.httpErrorCode
            })
          case FederationErrors.IdpBatchUpdateUsersAccessDenied:
            throw new KeyRotationError({
              feErrorCode:
                KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateAccesDenied,
              httpErrorCode: error.httpErrorCode
            })
        }
      }
      throw new Error('unknown error - keyRotationFixUnSynchronizedUsers')
    }
  }
}

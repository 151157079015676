import { SamlResponse } from '@lastpass/admin-console-dependencies/types/saml-response'

const generateMockData = () => {
  const returnArray: SamlResponse[] = []
  const mockData = {
    Username: 'admin@democompany.com',
    AppName: '15five',
    Timestamp: '2022-06-01T11:00:32.113',
    Request: null,
    Response:
      '<samlp:Response ID="_5c067614-b6ca-45be-b8f9-c8e5902f1566" Version="2.0" IssueInstant="2022-06-01T11:00:32Z" Destination="https://example.com/" xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol"><saml:Issuer xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion">https://identity-dev.cloud.lastpass.com</saml:Issuer><samlp:Status><samlp:StatusCode Value="urn:oasis:names:tc:SAML:2.0:status:Success" /></samlp:Status><saml:Assertion Version="2.0" ID="_1d9ca76d-00c3-47db-b2c7-bf729279a375" IssueInstant="2022-06-01T11:00:32Z" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion"><saml:Issuer>https://identity-dev.cloud.lastpass.com</saml:Issuer><saml:Subject><saml:NameID Format="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">admin@democompany.com</saml:NameID><saml:SubjectConfirmation Method="urn:oasis:names:tc:SAML:2.0:cm:bearer"><saml:SubjectConfirmationData NotOnOrAfter="2022-06-01T11:03:32Z" Recipient="https://example.com/" /></saml:SubjectConfirmation></saml:Subject><saml:Conditions NotBefore="2022-06-01T10:57:32Z" NotOnOrAfter="2022-06-01T11:03:32Z"><saml:AudienceRestriction><saml:Audience>15five</saml:Audience></saml:AudienceRestriction></saml:Conditions><saml:AuthnStatement AuthnInstant="2022-06-01T11:00:32Z" SessionIndex="_1d9ca76d-00c3-47db-b2c7-bf729279a375"><saml:AuthnContext><saml:AuthnContextClassRef>urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified</saml:AuthnContextClassRef></saml:AuthnContext></saml:AuthnStatement><saml:AttributeStatement><saml:Attribute Name="Email" NameFormat="urn:oasis:names:tc:SAML:2.0:attrname-format:unspecified"><saml:AttributeValue xsi:type="xs:string" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">admin@democompany.com</saml:AttributeValue></saml:Attribute></saml:AttributeStatement></saml:Assertion></samlp:Response>',
    Error: null,
    IPAddress: '185.36.20.10'
  }
  for (let i = 0; i <= 30; i++) {
    returnArray.push({
      ...mockData,
      AppName: `${mockData.AppName} ${i}`
    })
  }
  return returnArray
}

export const samlResponsesMock = {
  total: 31,
  results: generateMockData()
}

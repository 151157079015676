import React, { useContext } from 'react'

import styled from '@emotion/styled'

import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { AdminLevelsPageHeader } from './components/AdminLevelsPageHeader'
import { AdminLevelsTable } from './components/AdminLevelsTable'
import { NewAdminLevelButton } from './components/NewAdminLevelButton'

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

export const AdminLevels: React.FunctionComponent = () => {
  const permissions = useContext(PermissionContext)

  const canModifyAdmins = permissions.requirePermission(
    AdminPermissions.adminsModify
  )

  return (
    <>
      <RowContainer>
        <AdminLevelsPageHeader />
        {canModifyAdmins && <NewAdminLevelButton />}
      </RowContainer>
      <AdminLevelsTable />
    </>
  )
}

import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { LocationRedirect } from '@lastpass/routing'
import { RouteFade } from '@lastpass/routing/animations'

import { EditGroup } from '@lastpass/admin-console-dependencies/ui/users/groups/edit/EditGroup'

export function EditGroupDrawerPage() {
  const match = useRouteMatch()

  if (match) {
    const { url, path } = match
    return (
      <>
        <Route path={path} exact>
          <RouteFade>
            <EditGroup />
          </RouteFade>
        </Route>
        <Route>
          <LocationRedirect to={url} />
        </Route>
      </>
    )
  }
  return null
}

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  AppliesToOptions,
  Entities,
  Table
} from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicyGroup,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

export enum AssignActionTypes {
  CHANGE_APPLIES_TO = '[assign] change-applies-to',
  SET_SEARCH_INPUT = '[assign] set-search-input',
  FILTER = '[assign] filter',
  SHOW_TABLE = '[assign] show-table',
  SELECT_TABLE_ELEMENT = '[assign] select-table-element',
  DESELECT_TABLE_ELEMENT = '[assign] deselect-table-element',
  SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS = '[assign] select-or-deselect-table-element',
  ASSIGN = '[assign] assign',
  UNASSIGN = '[assign] unassign',

  GET_AVAILABLE_ENTITY = '[assign] get-available-entity',
  SET_AVAILABLE_GROUPS = '[assign] set-available-groups',
  SET_AVAILABLE_USERS = '[assign] set-available-users',
  SET_AVAILABLE_ENTITY_LOADING = '[assign] set-available-entity-loading',
  RESET = '[assign] reset',
  RESET_TABLES = '[assign] reset-tables',
  RESET_SEARCH_INPUT = '[assign] reset-search-input',
  SET_INITIAL_ASSIGNED_ITEMS = '[assign] set-initial-assigned-items'
}

export const assignActions = {
  changeAppliesTo: (appliesTo: AppliesToOptions) =>
    createAction(AssignActionTypes.CHANGE_APPLIES_TO, { appliesTo }),
  setSearchInput: (serachedEntity: Entities, searchString: string) =>
    createAction(AssignActionTypes.SET_SEARCH_INPUT, {
      serachedEntity,
      searchString
    }),
  filterUsers: (filteredEntity: Entities, filterString: string) =>
    createAction(AssignActionTypes.FILTER, { filteredEntity, filterString }),
  showTable: (table: Entities) =>
    createAction(AssignActionTypes.SHOW_TABLE, {
      table
    }),

  /** new user assign table actions */
  addSelectedUsers: (records: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users,
      table: Table.available
    }),
  removeSelectedUsers: (records: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users,
      table: Table.available
    }),
  setSelectedUsers: (records: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS, {
      records,
      entity: Entities.users,
      table: Table.available
    }),

  /** already assigned users table actions for unassign */
  addSelectedUsersToUnassign: (records: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users,
      table: Table.filtered
    }),
  removeSelectedUsersToUnassign: (records: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.users,
      table: Table.filtered
    }),
  setSelectedUsersToUnassign: (records: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS, {
      records,
      entity: Entities.users,
      table: Table.filtered
    }),

  /** new group assign table actions */
  addSelectedGroups: (records: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups,
      table: Table.available
    }),
  removeSelectedGroups: (records: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups,
      table: Table.available
    }),
  setSelectedGroups: (records: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS, {
      records,
      entity: Entities.groups,
      table: Table.available
    }),

  /** already assigned groups table actions for unassign */
  addSelectedGroupsToUnassign: (records: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.SELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups,
      table: Table.filtered
    }),
  removeSelectedGroupsToUnassign: (records: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.DESELECT_TABLE_ELEMENT, {
      records,
      entity: Entities.groups,
      table: Table.filtered
    }),
  setSelectedGroupsToUnassign: (records: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS, {
      records,
      entity: Entities.groups,
      table: Table.filtered
    }),

  getAvailableEntity: (entityType: Entities, searchKey: string) =>
    createAction(AssignActionTypes.GET_AVAILABLE_ENTITY, {
      entityType,
      searchKey
    }),
  setAvailableEntityLoading: (entityType: Entities, isLoading: boolean) =>
    createAction(AssignActionTypes.SET_AVAILABLE_ENTITY_LOADING, {
      entityType,
      isLoading
    }),
  setAvailableUsers: (users: TeamsPolicyUserInfo[]) =>
    createAction(AssignActionTypes.SET_AVAILABLE_USERS, {
      users
    }),
  setAvailableGroups: (groups: TeamsPolicyGroup[]) =>
    createAction(AssignActionTypes.SET_AVAILABLE_GROUPS, {
      groups
    }),

  assign: () => createAction(AssignActionTypes.ASSIGN),
  unassign: () => createAction(AssignActionTypes.UNASSIGN),
  reset: () => createAction(AssignActionTypes.RESET),
  resetTables: () => createAction(AssignActionTypes.RESET_TABLES),
  resetSearchInput: (entityType: Entities) =>
    createAction(AssignActionTypes.RESET_SEARCH_INPUT, {
      entityType
    }),
  setInitialAssignedItems: (
    users: TeamsPolicyUserInfo[],
    groups: TeamsPolicyGroup[]
  ) =>
    createAction(AssignActionTypes.SET_INITIAL_ASSIGNED_ITEMS, {
      users,
      groups
    })
}

export type AssignActions = ActionsUnion<typeof assignActions>

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'

const success = (users: User[], totalResults: number) =>
  createResponse(SUCCESS, { users, totalResults })
const notFound = () => createResponse(NOT_FOUND)

export const Responses = {
  success,
  notFound
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(adminLevelId: string, queryPath: string) {
    const [response, status] = await fetch(
      'adminLevels/' + adminLevelId + '/users' + queryPath,
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const users = JSON.parse(response).content.items
      const totalResults = JSON.parse(response).content.totalCount
      return success(users, totalResults)
    } else if (status === StatusCodes.NOT_FOUND) {
      return notFound()
    }
    throw new UnknownServiceError()
  }
}

import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'

import { EnumDictionary } from '../EnumValues'

export const SystemUpgradeDrawerHeadersMapping: EnumDictionary<
  SystemUpgradeDrawerHeader,
  ReactElement
> = {
  [SystemUpgradeDrawerHeader.whatFor]: (
    <Trans>What was this feature for?</Trans>
  ),
  [SystemUpgradeDrawerHeader.where]: (
    <Trans>Where was this feature available?</Trans>
  ),
  [SystemUpgradeDrawerHeader.whatsHappening]: (
    <Trans>What&apos;s happening to it?</Trans>
  ),
  [SystemUpgradeDrawerHeader.whatToDo]: <Trans>What should I do?</Trans>
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'

export function createGetGroupPoliciesSaga(userService: UACServices.Services) {
  return function* getGroupPoliciesSaga(
    action: ReturnType<typeof groupDrawerActions.getGroupPolicies>
  ) {
    try {
      const id = action.payload.params.path.id
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        top: resultsPerPage
      })
      if (id) {
        yield put(groupDrawerActions.setGroupPoliciesLoading(true))
        try {
          const result: UACServices.GroupPoliciesAPI.Responses = yield call(
            userService.groupPolicies,
            id,
            query
          )
          if (result.type === UACServices.GroupPoliciesAPI.SUCCESS) {
            const policies = result.body.policies
            const totalResults = result.body.totalResults
            yield put(
              groupDrawerActions.setGroupPolicies(
                policies,
                totalResults,
                resultsPerPage
              )
            )
          }
        } finally {
          yield put(groupDrawerActions.setGroupPoliciesLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

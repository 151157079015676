import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { MultifactorPageState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/state'
import { MultifactorContentPage } from '@lastpass/admin-console-dependencies/ui/policies/multifactor/MultifactorContentPage'
import { MultifactorEmptyPage } from '@lastpass/admin-console-dependencies/ui/policies/multifactor/MultifactorEmptyPage'

export const MultifactorPage: React.FunctionComponent = () => {
  const multifactorPageState: MultifactorPageState = useSelector(
    (state: AppState) => state.multifactorPage
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(multifactorActions.fetchInitialData())
  }, [dispatch])

  const activeMainPolicies = multifactorPageState.RequireOfPolicies.filter(
    policy => policy.checked
  )
  const activeAdditionalPolicies = multifactorPageState.AdditionalPolicies.filter(
    policy => policy.checked
  )

  useEffect(() => {
    dispatch(
      multifactorActions.mfaPoliciesOverviewViewed(
        activeMainPolicies.length + activeAdditionalPolicies.length
      )
    )
  }, [activeAdditionalPolicies.length, activeMainPolicies.length, dispatch])

  return activeMainPolicies.length > 0 ||
    activeAdditionalPolicies.length > 0 ? (
    <MultifactorContentPage
      mainPolicies={activeMainPolicies}
      additionalPolicies={activeAdditionalPolicies}
    />
  ) : (
    <MultifactorEmptyPage />
  )
}

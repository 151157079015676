import { UsersListState } from '@lastpass/admin-console-dependencies/state/users/view/list/state'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'

import { UserListActions, UserListActionTypes } from './actions'

const allUserListColumns: UserListTableColumn[] = [
  UserListTableColumn.email,
  UserListTableColumn.name,
  UserListTableColumn.lastpassStatus,
  UserListTableColumn.lastLogin,
  UserListTableColumn.defaultMultifactor,
  UserListTableColumn.mfaStatus,
  UserListTableColumn.securityScore,
  UserListTableColumn.lastPasswordChange,
  UserListTableColumn.adminLevel,
  UserListTableColumn.lastInviteEmail,
  UserListTableColumn.provisionFederatedStatus,
  UserListTableColumn.superAdminKeyOwnershipForUser,
  UserListTableColumn.isCreatedByCompany
]

const defaultUserListColumns: UserListTableColumn[] = [
  UserListTableColumn.email,
  UserListTableColumn.name,
  UserListTableColumn.lastpassStatus,
  UserListTableColumn.lastLogin,
  UserListTableColumn.defaultMultifactor,
  UserListTableColumn.mfaStatus,
  UserListTableColumn.provisionFederatedStatus
]

export const initialState: UsersListState = {
  table: {
    columns: defaultUserListColumns,
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false,
    operations: [],
    isAllSelected: false
  },
  totalCompanyUserCount: 0,
  defaultUserListColumns: defaultUserListColumns,
  allUserListColumns: allUserListColumns,
  currentAdminLevel: '',
  isExportUsersLoading: false,
  isTransferVaultHidden: false
}

export function usersListReducer(
  previousState: UsersListState | undefined,
  action: UserListActions
): UsersListState {
  const state = previousState || initialState
  switch (action.type) {
    case UserListActionTypes.SET_USER_LIST: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: action.payload.list,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          checkedRecords: []
        }
      }
    }
    case UserListActionTypes.ADD_USERS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          results: table.results.concat(action.payload.users)
        }
      }
    }
    case UserListActionTypes.ADD_SELECTED_USERS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.concat(action.payload.users)
        }
      }
    }
    case UserListActionTypes.REMOVE_SELECTED_USERS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: table.checkedRecords.filter(
            item => !action.payload.users.includes(item)
          ),
          isAllSelected: false
        }
      }
    }
    case UserListActionTypes.SET_SELECTED_USERS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          checkedRecords: action.payload.users
        }
      }
    }
    case UserListActionTypes.SET_USERS_LOADING: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          isLoading: action.payload.loading
        }
      }
    }
    case UserListActionTypes.SET_USER_OPERATIONS: {
      const table = state.table
      return {
        ...state,
        table: {
          ...table,
          operations: action.payload.userOperations
        }
      }
    }
    case UserListActionTypes.SET_COMPANY_USER_COUNT: {
      return {
        ...state,
        totalCompanyUserCount: action.payload
      }
    }
    case UserListActionTypes.SET_USER_LIST_COLUMNS: {
      return {
        ...state,
        table: {
          ...state.table,
          columns: action.payload.columns
        }
      }
    }
    case UserListActionTypes.RESET_USER_LIST_COLUMNS: {
      return {
        ...state,
        table: {
          ...state.table,
          columns: initialState.table.columns
        }
      }
    }
    case UserListActionTypes.SET_SELECT_ALL_USERS: {
      return {
        ...state,
        table: {
          ...state.table,
          isAllSelected: action.payload.allUsersSelected
        }
      }
    }
    case UserListActionTypes.SET_CURRENT_ADMIN_LEVEL: {
      return {
        ...state,
        currentAdminLevel: action.payload
      }
    }
    case UserListActionTypes.SET_IS_EXPORT_USERS_LOADING: {
      return {
        ...state,
        isExportUsersLoading: action.payload
      }
    }
    case UserListActionTypes.SET_IS_TRANSFER_VAULT_HIDDEN: {
      return {
        ...state,
        isTransferVaultHidden: action.payload
      }
    }
  }

  return state
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { SharedFolder } from '@lastpass/admin-console-dependencies/types/shared-folder'

interface PermissionProps {
  record: SharedFolder
}
export const UserPermissions = (props: PermissionProps) => {
  return (
    <div>
      {props.record.isSharedByPolicy ? <Trans>Shared by Policy</Trans> : null}
      {props.record.isSharedByPolicy &&
      (props.record.canAdminister ||
        props.record.isReadonly ||
        props.record.canViewPass)
        ? ', '
        : null}
      {props.record.canAdminister ? <Trans>Can Administer</Trans> : null}
      {props.record.canAdminister &&
      (props.record.isReadonly || props.record.canViewPass)
        ? ', '
        : null}
      {props.record.isReadonly ? <Trans>Read Only</Trans> : null}
      {props.record.isReadonly && props.record.canViewPass ? ', ' : null}
      {props.record.canViewPass ? <Trans>Can View Password</Trans> : null}
    </div>
  )
}

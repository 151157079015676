import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { EditUrlRuleOptionsApi } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createEditURLRuleOptions(
  enterpriseOptionServices: UACServices.Services
) {
  return function* editURLRuleOptions(
    action: ReturnType<typeof urlRulesActions.setURLRuleOptions>
  ) {
    try {
      yield put(urlRulesActions.setURLRuleOptionsLoading(true))

      const urlRuleOptionsResult: EditUrlRuleOptionsApi.Responses = yield call(
        enterpriseOptionServices.editUrlRuleOptions,
        {
          isUrlRulesCaseInsensitive:
            action.payload.options.isUrlRulesCaseInsensitive
        }
      )

      if (urlRuleOptionsResult.type == EditUrlRuleOptionsApi.SUCCESS) {
        yield put(urlRulesActions.setURLRuleOptionsLoading(false))
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

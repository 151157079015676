import { msg } from '@lingui/macro'
import { put } from 'redux-saga/effects'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  KeyRotationAccessDeniedDialog,
  KeyRotationAlertDialog,
  KeyRotationInconsistentConnectionErrorDialog,
  KeyRotationInconsistentGenericErrorDialog
} from '@lastpass/admin-console-dependencies/types/dialog-types'

import { KeyRotationError } from './key-rotation-error'
import { KeyRotationErrors } from './key-rotation-error-codes'

export function* keyRotationErrorsUserWide(
  providerName: string,
  error: KeyRotationError
) {
  const keyRotationAlertDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Process failed`,
    subtitle: msg`There was an error rotating your knowledge components for the service (${providerName}).`,
    text: msg`Please try again. If the problem persists, please contact LastPass support.`
  }

  const conflictErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Contact LastPass for assistance`,
    subtitle: msg`There was an error rotating your knowledge components for the service (${providerName}).`,
    text: msg`No knowledge components were rotated. No changes have been made to your users.`,
    secondaryText: msg`To resolve the issue and continue the process, contact LastPass and we’ll make the necessary adjustments on our side.`
  }

  const connectionErrorText =
    error.sessionToken === ''
      ? msg`Error code: ${error.feErrorCode}`
      : msg`Error code: ${error.feErrorCode}, session: ${error.sessionToken}`

  const connectionErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Rotation process incomplete`,
    subtitle: msg`It looks like you lost your internet connection during the rotation process.`,
    text: msg`Some users had their K1 component rotated, but they can still log in to LastPass.`,
    secondaryText: msg`Connect to the internet and try again from any device. If you use this same device, the process continues where it left off.`,
    errorCodeText: connectionErrorText
  }

  const genericErrorText =
    error.sessionToken === ''
      ? msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}`
      : msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}, session: ${error.sessionToken}`

  const genericErrorDialog: KeyRotationAlertDialog = {
    type: 'key-rotation-alert-dialog',
    title: msg`Rotation process failed`,
    subtitle: msg`Something went wrong during the rotation process.`,
    text: msg`Some users had their key components rotated, but they can still log in to LastPass.`,
    secondaryText: msg`Try again from any device. If you use this same device, the process continues where it left off.`,
    errorCodeText: genericErrorText
  }

  const inconsistentGenericErrorDialog: KeyRotationInconsistentGenericErrorDialog = {
    type: 'key-rotation-inconsistent-generic-error-dialog',
    errorCodeText: msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}, session: ${error.sessionToken}`
  }

  const inconsistentConnectionErrorDialog: KeyRotationInconsistentConnectionErrorDialog = {
    type: 'key-rotation-inconsistent-connection-error-dialog',
    errorCodeText: msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}, session: ${error.sessionToken}`
  }

  const keyRotationAccessDeniedDialog: KeyRotationAccessDeniedDialog = {
    type: 'key-rotation-access-denied-dialog',
    errorCodeText: msg`Error code: ${error.feErrorCode}, status code: ${error.httpErrorCode}, session: ${error.sessionToken}`
  }

  switch (error.feErrorCode) {
    case KeyRotationErrors.AlpGetSessionTokenRequestConflict:
      yield put(globalActions.setDialog(conflictErrorDialog))
      break
    case KeyRotationErrors.AlpGetSessionTokenRequestConnectionFailed:
    case KeyRotationErrors.IdpGetUsersRequestConnectionFailed:
    case KeyRotationErrors.UnsynchronisedUsersAlpSyncConnectionFailed:
    case KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateConnectionFailed:
    case KeyRotationErrors.AlpGetKeyRotationDataRequestConnectionFailed:
    case KeyRotationErrors.AlpSaveKeyRotationDataRequestConnectionFailed:
    case KeyRotationErrors.IdpBatchUpdateUsersConnectionFailed:
    case KeyRotationErrors.IdpBatchUpdateUsersConnectionFailedCleanup:
      yield put(globalActions.setDialog(connectionErrorDialog))
      break
    case KeyRotationErrors.AlpGetSessionTokenRequestFailed:
    case KeyRotationErrors.IdpGetUsersRequestFailed:
    case KeyRotationErrors.UnsynchronisedUsersAlpSyncFailed:
    case KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateBatchLimitExceeded:
    case KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateFailed:
    case KeyRotationErrors.AlpGetKeyRotationDataRequestFailed:
    case KeyRotationErrors.AlpSaveKeyRotationDataRequestFailed:
    case KeyRotationErrors.IdpBatchUpdateUsersBatchLimitExceeded:
    case KeyRotationErrors.IdpBatchUpdateUsersFailed:
    case KeyRotationErrors.AlpSyncFailedIdpDataSuccessfullyReverted:
    case KeyRotationErrors.IdpBatchUpdateUsersFailedCleanup:
    case KeyRotationErrors.IdpBatchUpdateUsersBatchLimitExceededCleanup:
    case KeyRotationErrors.IdpCleanupPartiallySuccessful:
      yield put(globalActions.setDialog(genericErrorDialog))
      break
    case KeyRotationErrors.AlpSyncAndIdpRevertBatchLimitExceeded:
    case KeyRotationErrors.AlpSyncAndIdpRevertFailed:
    case KeyRotationErrors.AlpSyncFailedIdpDataPartiallyReverted:
      yield put(globalActions.setDialog(inconsistentGenericErrorDialog))
      break
    case KeyRotationErrors.AlpSyncAndIdpRevertConnectionFailed:
      yield put(globalActions.setDialog(inconsistentConnectionErrorDialog))
      break
    case KeyRotationErrors.IdpBatchUpdateUsersAccessDenied:
    case KeyRotationErrors.IdpBatchUpdateUsersAccessDeniedCleanup:
    case KeyRotationErrors.UnsynchronisedUsersIdpBatchUpdateAccesDenied:
    case KeyRotationErrors.AlpSyncAndIdpRevertAccessDenied:
    case KeyRotationErrors.IdpGetUsersRequestAccessDenied:
      yield put(globalActions.setDialog(keyRotationAccessDeniedDialog))
      break
    default:
      yield put(globalActions.setDialog(keyRotationAlertDialog))
  }
}

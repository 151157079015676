import React, { FC } from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { formatSecurityScore } from '@lastpass/admin-console-dependencies/services/managed-companies/security-score'
import { BlockHeader } from '@lastpass/admin-console-dependencies/ui/home/BlockHeader'

const Container = styled.div`
  padding: 0 16px;
`

const Score = styled.div`
  font-size: 100px;
  line-height: 100px;
  font-weight: 600;
  color: ${props => props.theme.colors.yellow};
  margin-bottom: 12px;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.neutral900};
`

interface AverageSecurityScoreProps {
  securityScore: number | null
}

export const AverageSecurityScore: FC<AverageSecurityScoreProps> = ({
  securityScore
}) => {
  const formattedSecurityScore = formatSecurityScore(securityScore)
  return (
    <>
      <BlockHeader title={msg`User security`} />
      <Container>
        <Score data-qa="AverageSecurityScorePercentage">
          {typeof formattedSecurityScore === 'string'
            ? formattedSecurityScore
            : i18n._(formattedSecurityScore)}
        </Score>
        <Title>
          <Trans>Average company-level security score</Trans>
        </Title>
      </Container>
    </>
  )
}

import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import {
  GoogleGroup,
  GoogleUser
} from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
import {
  GoogleIntegrationSyncOptionsDialog,
  GoogleIntegrationSyncOptionsDialogConfirmType
} from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const createGoogleIntegrationSyncOptionsDialogProps = (
  partialSyncEnabled: boolean,
  initialSetup
): GoogleIntegrationSyncOptionsDialog => ({
  type: 'googleintegrationsyncoptionsdialog',
  partialSyncEnabled,
  initialSetup
})

function* handleGoogleIntegrationSyncOptionsDialog(
  partialSyncEnabled,
  initialSetup
) {
  yield put(
    globalActions.setDialog(
      createGoogleIntegrationSyncOptionsDialogProps(
        partialSyncEnabled,
        initialSetup
      )
    )
  )

  return yield take([
    GlobalActionTypes.CONFIRM_DIALOG,
    GlobalActionTypes.DISCARD_DIALOG
  ])
}

function* showFailedNotification() {
  yield put(
    globalActions.setNotification({
      message: msg`Google setup unsuccessful`,
      type: NotificationType.alert,
      autoDismiss: true
    })
  )
}

function* showSuccessNotification() {
  yield put(
    globalActions.setNotification({
      message: msg`Google setup successful`,
      type: NotificationType.success,
      autoDismiss: true
    })
  )
}

function* saveSelectedGoogleGroups(
  enterpriseOptionServices: UACServices.Services,
  googleGroups: { added: GoogleGroup[]; removed: GoogleGroup[] }
) {
  const result: UACServices.GoogleGroupsSaveSelectedAPI.Responses = yield call(
    enterpriseOptionServices.googleGroupsSaveSelected,
    googleGroups
  )

  return result.type === UACServices.GoogleGroupsSaveSelectedAPI.SUCCESS
}

function* saveSelectedGoogleUsers(
  enterpriseOptionServices: UACServices.Services,
  googleUsers: { added: GoogleUser[]; removed: GoogleUser[] }
) {
  const result: UACServices.GoogleUsersSaveAPI.Responses = yield call(
    enterpriseOptionServices.googleUsersSave,
    googleUsers
  )

  return result.type === UACServices.GoogleUsersSaveAPI.SUCCESS
}

function* clearGooglePartialSyncIds(
  enterpriseOptionServices: UACServices.Services
) {
  const result: UACServices.ClearGoogleSyncIdsAPI.Responses = yield call(
    enterpriseOptionServices.googlePartialSyncIdsClear
  )

  return result.type === UACServices.ClearGoogleSyncIdsAPI.SUCCESS
}

function* saveIntegration(
  enterpriseOptionServices: UACServices.Services,
  adminEmail: string,
  provisionToken: string,
  enabled: boolean
) {
  const result: UACServices.GoogleProvisioningSaveAPI.Responses = yield call(
    enterpriseOptionServices.googleProvisioningInfoSave,
    adminEmail,
    enabled,
    provisionToken
  )

  return result.type === UACServices.GoogleProvisioningSaveAPI.SUCCESS
}

function* handleSyncSetup(
  enterpriseOptionServices: UACServices.Services,
  partialSyncEnabled: boolean,
  initialSetup: boolean
) {
  try {
    yield put(directoriesActions.fetchGoogleGroups())
    yield put(directoriesActions.fetchGoogleUsers('', true))

    const { type, payload } = yield handleGoogleIntegrationSyncOptionsDialog(
      partialSyncEnabled,
      initialSetup
    )

    if (type === GlobalActionTypes.DISCARD_DIALOG) {
      return
    }

    if (type === GlobalActionTypes.CONFIRM_DIALOG) {
      yield put(globalActions.setIsLoading(true))

      const partial =
        payload.data.enabled ===
          GoogleIntegrationSyncOptionsDialogConfirmType.partialExit ||
        payload.data.enabled ===
          GoogleIntegrationSyncOptionsDialogConfirmType.partialEnable

      const groups: { added: GoogleGroup[]; removed: GoogleGroup[] } = partial
        ? payload.data.groups
        : { added: [], removed: [] }
      const users: { added: GoogleUser[]; removed: GoogleUser[] } = partial
        ? payload.data.users
        : { added: [], removed: [] }

      if (partial) {
        const groupsSuccess = yield saveSelectedGoogleGroups(
          enterpriseOptionServices,
          groups
        )

        const usersSuccess = yield saveSelectedGoogleUsers(
          enterpriseOptionServices,
          users
        )

        if (!groupsSuccess || !usersSuccess) {
          yield showFailedNotification()
          return
        }
      } else {
        const clearGooglePartialSyncIdsSuccess = yield clearGooglePartialSyncIds(
          enterpriseOptionServices
        )

        if (!clearGooglePartialSyncIdsSuccess) {
          yield showFailedNotification()
          return
        }
      }

      yield put(globalActions.setIsLoading(false))

      return (
        payload.data.enabled ===
          GoogleIntegrationSyncOptionsDialogConfirmType.allEnable ||
        payload.data.enabled ===
          GoogleIntegrationSyncOptionsDialogConfirmType.partialEnable
      )
    }
  } catch (e) {
    yield showFailedNotification()
    yield put(globalActions.setIsLoading(false))
  }
}

function* handleFirstSyncSetup(
  enterpriseOptionServices: UACServices.Services,
  adminEmail: string,
  provisionToken: string
) {
  yield put(globalActions.setIsLoading(true))

  const saveSuccess = yield saveIntegration(
    enterpriseOptionServices,
    adminEmail,
    provisionToken,
    false
  )

  yield put(globalActions.setIsLoading(false))

  if (!saveSuccess) {
    yield showFailedNotification()
    return
  }

  const enabled = yield handleSyncSetup(enterpriseOptionServices, false, true)

  return enabled
}

function* handleIntegrationSaveProcess(
  enterpriseOptionServices: UACServices.Services,
  data: { adminEmail: string; provisionToken: string; enabled: boolean },
  callback: () => void
) {
  const { adminEmail, provisionToken, enabled } = data

  const integrationSaveSucceeded = yield saveIntegration(
    enterpriseOptionServices,
    adminEmail,
    provisionToken,
    enabled
  )

  if (integrationSaveSucceeded) {
    yield put(directoriesActions.getDirectorySettings(callback))
    yield showSuccessNotification()
  } else {
    yield showFailedNotification()
  }
}

export function createUpdateGoogleSyncOptions(
  enterpriseOptionServices: UACServices.Services
) {
  return function* updateGoogleSyncOptions(
    action: ReturnType<typeof directoriesActions.updateGoogleSyncOptions>
  ) {
    const {
      partialSyncEnabled,
      adminEmail,
      provisionToken,
      enabled,
      callback
    } = action.payload

    try {
      const syncSetupResult = yield handleSyncSetup(
        enterpriseOptionServices,
        partialSyncEnabled,
        false
      )

      if (syncSetupResult === undefined) {
        callback()
        return
      }

      yield handleIntegrationSaveProcess(
        enterpriseOptionServices,
        {
          adminEmail,
          provisionToken,
          enabled
        },
        callback
      )
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
      callback()
    }
  }
}

export function createSaveGoogleIntegration(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveGoogleIntegration(
    action: ReturnType<typeof directoriesActions.saveGoogleIntegration>
  ) {
    const { adminEmail, integrationExists, provisionToken } = action.payload
    try {
      let enabled = action.payload.enabled

      if (!integrationExists) {
        enabled = yield handleFirstSyncSetup(
          enterpriseOptionServices,
          action.payload.adminEmail,
          action.payload.provisionToken
        )

        if (enabled === undefined) {
          return
        }
      }

      yield handleIntegrationSaveProcess(
        enterpriseOptionServices,
        {
          adminEmail,
          provisionToken,
          enabled
        },
        action.payload.callback
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
      action.payload.callback()
    }
  }
}

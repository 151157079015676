import React, { FunctionComponent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Collapsible, TextButton } from '@lastpass/lastkit/components'
import {
  DetailCell,
  DetailsContainer
} from '@lastpass/lastkit/components/DetailCellContainer'
import { LocationLink } from '@lastpass/routing'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'

const StyledLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export type UserProfileProps = LastPassComponentProps<
  {
    profile: UsersDrawerState['profile']
    loadingInfo: UsersDrawerState['loadingProfile']
  },
  typeof userDrawerActions,
  {
    editLink: string
    expand: boolean
    onExpand: Function
    showEditButton: boolean
  }
>

export const UserProfileCardComponent: FunctionComponent<UserProfileProps> = ({
  state,
  props
}) => {
  const dataqa = 'ProfileDetailContainer'
  return (
    <Collapsible
      title={<Trans>Profile</Trans>}
      expanded={props.expand}
      loading={state.loadingInfo}
      onExpand={props.onExpand}
      qadata={'UserProfileCard'}
    >
      {state && (
        <>
          <DetailsContainer data-qa={dataqa}>
            <DetailCell
              field={<Trans>First name</Trans>}
              value={state.profile.firstName}
            />
            <DetailCell
              field={<Trans>Primary email</Trans>}
              value={state.profile.email}
              selected
            />
            <DetailCell
              field={<Trans>Phone</Trans>}
              value={state.profile.phoneNumber}
            />
            <DetailCell
              field={<Trans>User ID</Trans>}
              value={state.profile.userId}
            />
            <DetailCell
              field={<Trans>SAML account name</Trans>}
              value={state.profile.samAccountName}
            />
            <DetailCell
              field={<Trans>Last name</Trans>}
              value={state.profile.lastName}
            />
            <DetailCell
              field={<Trans>Secondary email</Trans>}
              value={state.profile.secondaryEmail}
              selected
            />
            <DetailCell
              field={<Trans>Department</Trans>}
              value={state.profile.department}
            />
            <DetailCell
              field={<Trans>Description</Trans>}
              value={state.profile.description}
            />
          </DetailsContainer>

          {props.showEditButton && (
            <div
              css={css`
                text-align: right;
              `}
            >
              <TextButton blue>
                <StyledLink
                  to={props.editLink}
                  data-qa={'ProfileCardEditUserProfileButton'}
                >
                  <Trans>Edit user profile</Trans>
                </StyledLink>
              </TextButton>
            </div>
          )}
        </>
      )}
    </Collapsible>
  )
}

export function createUserProfileCard<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserProfileCardComponent,
    (state: TState) => {
      return {
        profile: state.usersDrawer.profile,
        loadingInfo: state.usersDrawer.loadingProfile
      }
    },
    userDrawerActions
  )
}

export type UserProfileCard = ReturnType<typeof createUserProfileCard>

import { saveAs } from 'file-saver'
import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

import { masterPasswordReuseFilter } from './get-reused-master-passwords'

export function createExportUsersWithMasterPasswordReusedSaga(
  dashboardService: UACServices.Services
) {
  return function* exportUsersWithMasterPasswordReusedSaga() {
    try {
      yield put(dashboardDrawerActions.setMasterPasswordReuseLoading(true))
      const query = buildQuery({
        ...masterPasswordReuseFilter
      })
      const result: UACServices.ExportUsersAPI.Responses = yield call(
        dashboardService.exportUsersWithFilter,
        query
      )
      if (result.type === UACServices.ExportUsersWithFilterAPI.SUCCESS) {
        const usersCSV = result.body
        const blobToSave = new Blob([usersCSV], {
          type: 'text/csv;charset=utf-8;'
        })
        yield call(saveAs, blobToSave, 'users.csv')
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(dashboardDrawerActions.setMasterPasswordReuseLoading(false))
    }
  }
}

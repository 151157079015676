import { DependencyList, EffectCallback, useEffect } from 'react'

export const useWindowResizeEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  return useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    const resizeHandler = () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(effect, 10)
    }
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
    /* eslint-disable-next-line */
  }, deps)
}

import { msg } from '@lingui/macro'
import { call } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'
import { ManagedCompanyConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

import { performSimpleOperation } from './helpers/perform-simple-operation'

export function createReactivateManagedCompanySaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*(
    action: ReturnType<typeof managedCompanyOperationActions.reactivate>
  ) {
    const { managedCompany } = action.payload
    const successNotificationProps: NotificationProps = {
      type: NotificationType.success,
      message: msg`Company has been re-activated.`,
      autoDismiss: true
    }

    const failureNotificationProps: NotificationProps = {
      type: NotificationType.alert,
      message: msg`Company can’t be re-activated. Try again later.`,
      autoDismiss: true
    }

    const confirmDialog: ManagedCompanyConfirmDialog = {
      type: 'managed-company-confirm-dialog',
      title: msg`Re-activate company`,
      text: msg`Re-activate this managed company and allocate licenses to all previously active users?`,
      managedCompanyName: managedCompany.name,
      discardText: msg`Cancel`,
      confirmText: msg`Re-activate`
    }

    yield call(performSimpleOperation, {
      operationType: 'activate',
      managedCompaniesServices,
      managedCompany,
      confirmDialog,
      successNotificationProps,
      failureNotificationProps
    })
  }
}

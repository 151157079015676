import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'

export class CompanyDetailsHelper {
  public static isUserBusinessTrialEligible(
    companyDetails: CompanyDetails
  ): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowStartBusinessTrial
    )
  }

  public static isPermissionNull(companyDetails: CompanyDetails): boolean {
    return companyDetails && companyDetails.permissions === null
  }

  public static isUserOnBusinessTrial(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.isTrial
    )
  }

  public static isLegacyVpnAllowed(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowLastPassVpn
    )
  }

  public static isWorkstationAllowed(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowLastPassWorkstation
    )
  }

  public static isMicrosoftAzureAdAllowed(
    companyDetails: CompanyDetails
  ): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowMicrosoftAzureAd
    )
  }

  public static isThirdPartyIdpAllowed(
    companyDetails: CompanyDetails
  ): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowThirdPartyIdp
    )
  }

  public static isPasswordlessAllowed(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowPasswordless
    )
  }

  public static isMultifactorAllowed(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowPolicies
    )
  }

  public static isDirectMsp(companyDetails: CompanyDetails): boolean {
    return (
      this.isMsp(companyDetails) &&
      !this.isLegacyVpnAllowed(companyDetails) &&
      !this.isWorkstationAllowed(companyDetails) &&
      !this.isMicrosoftAzureAdAllowed(companyDetails) &&
      !this.isThirdPartyIdpAllowed(companyDetails) &&
      !this.isPasswordlessAllowed(companyDetails) &&
      !this.isMultifactorAllowed(companyDetails)
    )
  }

  public static isAggregatorMsp(companyDetails: CompanyDetails): boolean {
    return (
      this.isMsp(companyDetails) &&
      this.isLegacyVpnAllowed(companyDetails) &&
      this.isWorkstationAllowed(companyDetails) &&
      this.isMicrosoftAzureAdAllowed(companyDetails) &&
      this.isThirdPartyIdpAllowed(companyDetails) &&
      this.isPasswordlessAllowed(companyDetails) &&
      this.isMultifactorAllowed(companyDetails)
    )
  }

  public static isMsp(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.isMsp
    )
  }

  public static isManaged(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.isManaged
    )
  }

  public static isMspOrManaged(companyDetails: CompanyDetails): boolean {
    return this.isManaged(companyDetails) || this.isMsp(companyDetails)
  }

  public static isTouchSalesEnabled(companyDetails: CompanyDetails): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.touchSalesOnly
    )
  }

  public static isEcommEnabled(companyDetails: CompanyDetails): boolean {
    return !this.isTouchSalesEnabled(companyDetails)
  }

  public static userHasMfaAddon(companyDetails: CompanyDetails): boolean {
    return (
      CompanyDetailsHelper.isLegacyVpnAllowed(companyDetails) ||
      CompanyDetailsHelper.isWorkstationAllowed(companyDetails) ||
      CompanyDetailsHelper.isPasswordlessAllowed(companyDetails) ||
      CompanyDetailsHelper.isMultifactorAllowed(companyDetails) ||
      this.isMspOrManaged(companyDetails)
    )
  }

  public static hasAnyPermission(companyDetails: CompanyDetails): boolean {
    return Boolean(companyDetails && companyDetails.permissions)
  }

  public static canAccessUnifiedAdminConsole(
    companyDetails: CompanyDetails
  ): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowUnifiedAdminConsoleAccess
    )
  }

  public static isContactSupportAllowed(
    companyDetails: CompanyDetails
  ): boolean {
    return Boolean(
      companyDetails &&
        companyDetails.permissions &&
        companyDetails.permissions.allowContactSupport
    )
  }
}

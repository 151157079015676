import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

import { mockReports } from './advanced-user-activity-reports.mock'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(reportName: string) {
    // TODO: there is no BE side for this story at the moment
    // a follow-up story will be needed to fix this request and remove the test data
    if (mockReports.some(report => report.name === reportName)) {
      return success()
    }

    const [response, status] = await fetch(
      'advanced-reporting',
      'DELETE',
      { reportName },
      {
        ['content-type']: 'application/json'
      }
    )
    if (status === StatusCodes.OK && response) {
      return success()
    }
    throw new UnknownServiceError()
  }
}

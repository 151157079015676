import React, { FunctionComponent } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { Select } from '@lastpass/lastkit'

interface SelectLastInvitationDateProps {
  updateSelection: (update: Date[]) => void
  filters?: Date[]
}

interface LastInvitationSelectOptions {
  value: string
  label: string
}

export const SelectLastInvitationDate: FunctionComponent<SelectLastInvitationDateProps> = props => {
  const now = new Date()

  const invitationDates: LastInvitationSelectOptions[] = [
    {
      value: new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 7)
      ).toString(),
      label: i18n._(msg`Older than 7 days`)
    },
    {
      value: new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 14)
      ).toString(),
      label: i18n._(msg`Older than 14 days`)
    },
    {
      value: new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 30)
      ).toString(),
      label: i18n._(msg`Older than 30 days`)
    }
  ]
  return (
    <Select
      dataQa={'LastInvitationDropdown'}
      options={invitationDates}
      value={invitationDates.find(invitationDate => {
        return (
          props.filters &&
          new Date(props.filters[0]).getTime() ===
            new Date(invitationDate.value).getTime()
        )
      })}
      hideSelectedOptions={false}
      placeholder={i18n._(msg`Invitation date`)}
      onChange={selectedItem => {
        if (selectedItem) {
          props.updateSelection([selectedItem['value']])
        }
      }}
      fluid
    >
      <Trans>Last invitation</Trans>
    </Select>
  )
}

import { msg } from '@lingui/macro'

import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

export const genericDiscardFormConfirmDialog: ConfirmDialog = {
  type: 'confirmdialog',
  title: msg`You have unsaved changes`,
  text: msg`Discard unsaved changes?`,
  discardText: msg`Discard`,
  confirmText: msg`Cancel`
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { encodeSearch } from '@lastpass/admin-console-dependencies/services/odata'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'

export function createGetGroupUsersSaga(userService: UACServices.Services) {
  return function* getGroupUsersSaga(
    action: ReturnType<typeof groupDrawerActions.getGroupUsers>
  ) {
    try {
      const id = action.payload.params.path.id
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        search: encodeSearch(action.payload.params.query.search),
        top: resultsPerPage
      })
      if (id) {
        yield put(groupDrawerActions.setGroupUsersLoading(true))
        try {
          const result: UACServices.GroupUsersAPI.Responses = yield call(
            userService.groupUsers,
            id,
            query
          )
          if (result.type === UACServices.GroupUsersAPI.SUCCESS) {
            const users = result.body.users
            const totalResults = result.body.totalResults
            yield put(
              groupDrawerActions.setGroupUsers(
                users,
                totalResults,
                resultsPerPage
              )
            )
          }
        } finally {
          yield put(groupDrawerActions.setGroupUsersLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FunctionComponent } from 'react'

import styled from '@emotion/styled'
import { msg, Plural, Trans } from '@lingui/macro'

import { BodyBold, BodyRegular } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { DisableUsersDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { UserBase } from '@lastpass/admin-console-dependencies/types/user'

import { ConfirmDialogComponent } from '../ConfirmDialog'

const StyledList = styled.ul`
  margin-top: 16px;
  margin-left: 8px;
`

const StyledListItem = styled.li`
  list-style: disc;
  margin-left: 20px;
`

interface DialogProps {
  state: DisableUsersDialog
  actions: typeof globalActions
}

const Content: FunctionComponent<{
  users: UserBase[]
  numberOfUsers: number
}> = ({ users, numberOfUsers }) => (
  <BodyRegular data-qa="DialogContent">
    {numberOfUsers === 1 ? (
      <Trans>
        You’re about to disable this user: <BodyBold>{users[0].email}</BodyBold>
      </Trans>
    ) : (
      <Trans>
        You’re about to disable <BodyBold>{numberOfUsers}</BodyBold> users.
      </Trans>
    )}
    <StyledList>
      <StyledListItem>
        <Plural
          value={numberOfUsers}
          one="They won’t be able to access their vault until you reactivate them"
          other="They won’t be able to access their vaults until you reactivate them"
        />
      </StyledListItem>
      <StyledListItem>
        <Plural
          value={numberOfUsers}
          one="Their seat becomes available"
          other="Their seats become available"
        />
      </StyledListItem>
    </StyledList>
  </BodyRegular>
)

export const DisableUsersDialogComponent: FunctionComponent<DialogProps> = ({
  state,
  actions
}) => {
  const numberOfUsers = state.isAllSelected
    ? state.allUserCount
    : state.users.length
  const confirmDialogState = {
    ...state,
    type: 'confirmdialog' as const,
    title: numberOfUsers === 1 ? msg`Disable user?` : msg`Disable users?`,
    discardText: msg`Cancel`,
    confirmText:
      numberOfUsers === 1 ? msg`Disable` : msg`Disable ${numberOfUsers} users`,
    content: <Content users={state.users} numberOfUsers={numberOfUsers} />
  }

  return <ConfirmDialogComponent state={confirmDialogState} actions={actions} />
}

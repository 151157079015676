import { DirectoriesActions, DirectoriesActionTypes } from './actions'
import { DirectoriesIntegrationsState, Provider } from './state'

export const initialState: DirectoriesIntegrationsState = {
  isLoading: false,
  isSyncing: false,
  ADConnector: {
    lastSync: '',
    hasApiKey: false,
    version: ''
  },
  AzureAD: {
    lastSync: '',
    hasProvisionToken: false,
    provisionToken: ''
  },
  Okta: { lastSync: '', hasProvisionToken: false },
  OneLogin: { lastSync: '', hasProvisionToken: false },
  Google: {
    lastSync: '',
    hasProvisionToken: false,
    adminEmail: '',
    enabled: false,
    integrationExists: false,
    partialSyncEnabled: false,
    groupIdsToSync: [],
    userIdsToSync: []
  },
  PingOne: {
    lastSync: '',
    hasProvisionToken: false
  },
  AccountInfo: { companyLastPassId: '' },
  AuditLog: {
    logs: [],
    numberOfItems: 0,
    selectedAuditLog: null,
    isAuditLogListLoading: false,
    auditLogListFetchFailed: false
  },
  GoogleUsers: {
    isLoading: false,
    users: [],
    nextPageToken: null
  },
  GoogleGroups: {
    isLoading: false,
    groups: [],
    searchBasedGroupSelection: false,
    nextPageToken: null
  }
}

export function directoriesIntegrationsReducer(
  previousState: DirectoriesIntegrationsState | undefined,
  action: DirectoriesActions
): DirectoriesIntegrationsState {
  const state = previousState || initialState
  switch (action.type) {
    case DirectoriesActionTypes.SET_DIRECTORY_INTEGRATIONS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.loading
      }
    }
    case DirectoriesActionTypes.SET_AD_CONNECTOR_API_KEY_EXISTENCE: {
      return {
        ...state,
        ADConnector: {
          ...state.ADConnector,
          hasApiKey: action.payload.hasAPIKey
        }
      }
    }
    case DirectoriesActionTypes.SET_AD_CONNECTOR_VERSION_LAST_SYNC: {
      return {
        ...state,
        ADConnector: {
          ...state.ADConnector,
          version: action.payload.lastSyncVersion
            ? action.payload.lastSyncVersion.version
            : undefined,
          lastSync: action.payload.lastSyncVersion
            ? action.payload.lastSyncVersion.lastSync
            : undefined
        }
      }
    }
    case DirectoriesActionTypes.SET_DIRECTORY_ACCOUNT_INFORMATION: {
      return {
        ...state,
        AccountInfo: {
          companyLastPassId: action.payload.accountInfo.companyLastPassId
        }
      }
    }
    case DirectoriesActionTypes.SET_DIRECTORY_INTEGRATIONS_PROVISION_TOKEN: {
      switch (action.payload.provider) {
        case Provider.AzureAD: {
          return {
            ...state,
            AzureAD: {
              ...state.AzureAD,
              provisionToken: action.payload.provisionToken
            }
          }
        }
        case Provider.Okta: {
          return {
            ...state,
            Okta: {
              ...state.Okta,
              provisionToken: action.payload.provisionToken
            }
          }
        }
        case Provider.OneLogin: {
          return {
            ...state,
            OneLogin: {
              ...state.OneLogin,
              provisionToken: action.payload.provisionToken
            }
          }
        }
        case Provider.PingOne: {
          return {
            ...state,
            PingOne: {
              ...state.PingOne,
              provisionToken: action.payload.provisionToken
            }
          }
        }
        default: {
          return state
        }
      }
    }
    case DirectoriesActionTypes.SET_DIRECTORY_INTEGRATIONS_SETTINGS: {
      return {
        ...state,
        AzureAD: {
          ...state.AzureAD,
          hasProvisionToken: action.payload.settings.hasProvisionToken,
          lastSync: action.payload.settings.lastSync
        },
        Okta: {
          ...state.Okta,
          hasProvisionToken: action.payload.settings.hasProvisionToken,
          lastSync: action.payload.settings.lastSync
        },
        OneLogin: {
          ...state.OneLogin,
          hasProvisionToken: action.payload.settings.hasProvisionToken,
          lastSync: action.payload.settings.lastSync
        },
        Google: {
          ...state.Google,
          lastSync: action.payload.settings.lastSync
        },
        PingOne: {
          ...state.PingOne,
          hasProvisionToken: action.payload.settings.hasProvisionToken,
          lastSync: action.payload.settings.lastSync
        }
      }
    }
    case DirectoriesActionTypes.SET_GOOGLE_INTEGRATION_PROVISION_INFO: {
      return {
        ...state,
        Google: {
          ...state.Google,
          hasProvisionToken:
            action.payload.googleProvisioningInfo.hasProvisionToken,
          adminEmail: action.payload.googleProvisioningInfo.adminEmail,
          enabled: action.payload.googleProvisioningInfo.enabled,
          integrationExists:
            action.payload.googleProvisioningInfo.integrationExists,
          partialSyncEnabled:
            action.payload.googleProvisioningInfo.partialSyncEnabled,
          groupIdsToSync: action.payload.googleProvisioningInfo.groupIdsToSync,
          userIdsToSync: action.payload.googleProvisioningInfo.userIdsToSync
        }
      }
    }
    case DirectoriesActionTypes.FETCH_GOOGLE_AUDIT_LOG_LIST: {
      return {
        ...state,
        AuditLog: {
          ...state.AuditLog,
          isAuditLogListLoading: true,
          auditLogListFetchFailed: false
        }
      }
    }
    case DirectoriesActionTypes.FETCH_SYSTEM_LOG_LIST: {
      return {
        ...state,
        AuditLog: {
          ...state.AuditLog,
          isAuditLogListLoading: true,
          auditLogListFetchFailed: false
        }
      }
    }
    case DirectoriesActionTypes.SET_LOG_LIST: {
      return {
        ...state,
        AuditLog: {
          ...state.AuditLog,
          logs: action.payload.logs,
          numberOfItems: action.payload.numberOfItems,
          isAuditLogListLoading: false
        }
      }
    }
    case DirectoriesActionTypes.FETCH_LOG_LIST_FAILED: {
      return {
        ...state,
        AuditLog: {
          ...state.AuditLog,
          isAuditLogListLoading: false,
          auditLogListFetchFailed: true,
          selectedAuditLog: null,
          logs: []
        }
      }
    }
    case DirectoriesActionTypes.SET_LOG_DETAILS: {
      return {
        ...state,
        AuditLog: {
          ...state.AuditLog,
          selectedAuditLog: action.payload.log
        }
      }
    }
    case DirectoriesActionTypes.SYNC_STARTED: {
      return {
        ...state,
        isSyncing: true
      }
    }
    case DirectoriesActionTypes.SYNC_FINISHED: {
      return {
        ...state,
        isSyncing: false
      }
    }
    case DirectoriesActionTypes.FETCH_GOOGLE_USERS: {
      return {
        ...state,
        GoogleUsers: {
          isLoading: true,
          users: action.payload.replace ? [] : state.GoogleUsers.users,
          nextPageToken: state.GoogleUsers.nextPageToken
        }
      }
    }
    case DirectoriesActionTypes.SET_GOOGLE_USERS: {
      const { replace, users, nextPageToken } = action.payload

      const currentIds = state.GoogleUsers.users.map(user => user.id)
      const filteredUsers = users.filter(user => !currentIds.includes(user.id))

      return {
        ...state,
        GoogleUsers: {
          isLoading: false,
          users: replace
            ? users
            : [...state.GoogleUsers.users, ...filteredUsers],
          nextPageToken: nextPageToken
        }
      }
    }
    case DirectoriesActionTypes.FETCH_GOOGLE_GROUPS: {
      return {
        ...state,
        GoogleGroups: {
          isLoading: true,
          groups: [],
          searchBasedGroupSelection: false,
          nextPageToken: null
        }
      }
    }
    case DirectoriesActionTypes.SET_GOOGLE_GROUPS: {
      const { replace, groups, nextPageToken } = action.payload

      const currentIds = state.GoogleUsers.users.map(user => user.id)
      const filteredGroups = groups.filter(
        user => !currentIds.includes(user.id)
      )

      return {
        ...state,
        GoogleGroups: {
          ...state.GoogleGroups,
          isLoading: false,
          groups: replace
            ? groups
            : [...state.GoogleGroups.groups, ...filteredGroups],
          nextPageToken: nextPageToken || null
        }
      }
    }
    case DirectoriesActionTypes.SEARCH_GOOGLE_GROUPS: {
      return {
        ...state,
        GoogleGroups: {
          ...state.GoogleGroups,
          isLoading: true,
          groups: action.payload.replace ? [] : state.GoogleGroups.groups,
          nextPageToken: state.GoogleGroups.nextPageToken
        }
      }
    }
    case DirectoriesActionTypes.TURN_ON_SEARCH_BASED_GROUP_SELECTION: {
      return {
        ...state,
        GoogleGroups: {
          isLoading: false,
          groups: [],
          searchBasedGroupSelection: true,
          nextPageToken: null
        }
      }
    }
    default: {
      return state
    }
  }
}

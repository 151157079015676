import { StatusCodes } from 'http-status-codes'

import {
  BulkUpdateUsersK1Response,
  BulkUpdateUsersParam
} from '../../../types/federation-api'
import { FederationErrors } from '../../federation-error-codes'
import { FederationApiError } from '../federation-api-error'
import {
  FederationFetch,
  FederationFetchResponse
} from '../federation-api-factory'
import { config } from './config'
import { AzureADExtensionData } from './update-user-k-1'

export interface AzureAdBatchUpdateUsersK1Response {
  responses: {
    id: string
    status: number
  }[]
}

export const batchUpdateUsersK1 = (
  federationFetch: FederationFetch<AzureAdBatchUpdateUsersK1Response>
) => async ({
  usersData,
  additionalIdpProps
}: BulkUpdateUsersParam): Promise<BulkUpdateUsersK1Response[]> => {
  const accessDenied = 'Access denied'
  let httpStatusCode: number
  if (usersData.length > config.batchLimit) {
    throw new FederationApiError({
      federationErrorCode:
        FederationErrors.IdpBatchUpdateUsersBatchLimitExceeded
    })
  }

  try {
    const requests = usersData.map((userData, index) => {
      const extensionData: AzureADExtensionData = {
        LastPassK1: userData.k1.toString('base64')
      }
      let selectedAdditionalIdpData = {}

      if (additionalIdpProps) {
        selectedAdditionalIdpData = additionalIdpProps[userData.userId]
      }

      return {
        url: `users/${userData.userId}/extensions/com.lastpass.keys`,
        method: 'PATCH',
        id: index,
        body: { ...selectedAdditionalIdpData, ...extensionData },
        headers: {
          'content-type': 'application/json'
        }
      }
    })

    const batchAPIResponse: FederationFetchResponse<AzureAdBatchUpdateUsersK1Response> = await federationFetch(
      'v1.0/$batch',
      'POST',
      {
        requests
      }
    )

    if (batchAPIResponse.status === StatusCodes.OK) {
      return usersData.map(
        (userData, index): BulkUpdateUsersK1Response => {
          const apiResponse = batchAPIResponse.response?.responses.find(
            apiResponseItem => index === parseInt(apiResponseItem.id, 10)
          )
          if (apiResponse?.status === StatusCodes.FORBIDDEN) {
            throw new Error(accessDenied)
          }
          return {
            userId: userData.userId,
            success: apiResponse?.status === StatusCodes.NO_CONTENT
          }
        }
      ) as BulkUpdateUsersK1Response[]
    }

    httpStatusCode = batchAPIResponse.status
  } catch (error) {
    if (error instanceof Error && error.message === accessDenied) {
      throw new FederationApiError({
        federationErrorCode: FederationErrors.IdpBatchUpdateUsersAccessDenied,
        httpErrorCode: StatusCodes.FORBIDDEN
      })
    }
    throw new FederationApiError({
      federationErrorCode: FederationErrors.IdpBatchUpdateUsersConnectionFailed
    })
  }
  throw new FederationApiError({
    federationErrorCode: FederationErrors.IdpBatchUpdateUsersFailed,
    httpErrorCode: httpStatusCode
  })
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GoogleUser } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = () => createResponse(SUCCESS)
const failure = (reason: string) => createResponse(FAILURE, reason)

export const Responses = {
  success,
  failure
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(googleUsers: {
    added: GoogleUser[]
    removed: GoogleUser[]
  }) {
    try {
      const { added, removed } = googleUsers

      const usersAdded = added.map(user => ({
        id: user.id,
        name: user.name
      }))
      const usersRemoved = removed.map(user => ({
        id: user.id,
        name: user.name
      }))

      const [response, status] = await fetch(
        'google-workspace-provisioning/user-ids-to-sync',
        'POST',
        {
          entitiesAdded: usersAdded,
          entitiesRemoved: usersRemoved
        },
        { 'content-type': 'application/json' }
      )

      const responseJson = (response && JSON.parse(response)) || {}

      if (status === StatusCodes.OK) {
        return success()
      } else {
        return failure(responseJson.message || '')
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

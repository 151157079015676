/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useRef, useState } from 'react'

import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { withTheme } from '@emotion/react'
import _uniqueId from 'lodash/uniqueId'

import { FunctionComponentWithTheme } from '../theme'

export interface ChartItem {
  date: Date
  value: number
  tooltipText?: string
}

export interface ChartProps {
  items: ChartItem[]
  height?: string
  tooltipText?: string
  minValue?: number
  maxValue?: number
  minDateValue?: Date
}

const ChartComponent: FunctionComponentWithTheme<ChartProps> = props => {
  am4core.addLicense('CH259640185')

  const chart = useRef<am4charts.XYChart | null>(null)
  const [id] = useState(_uniqueId('chart-'))
  const now = new Date()
  const sevenDaysAgo = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 7)
  )

  useLayoutEffect(() => {
    const x = am4core.create(id, am4charts.XYChart)
    const rightPaddingToAvoidTextCutOff = 20

    chart.current = x

    am4core.useTheme(am4themes_animated)
    x.data = props.items
    x.fontSize = 12
    x.paddingRight = rightPaddingToAvoidTextCutOff

    // Create axes
    const dateAxis = x.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 60

    const basePercentage = 0.5
    const divisor = 500
    const dynamicStartLocationBasedOnDataPointCount =
      basePercentage - x.data.length / divisor
    const dynamicEndLocationBasedOnDataPointCount =
      basePercentage + x.data.length / divisor
    dateAxis.startLocation = dynamicStartLocationBasedOnDataPointCount
    dateAxis.endLocation = dynamicEndLocationBasedOnDataPointCount

    if (props.items.length === 1) {
      dateAxis.max = now.getTime()
      dateAxis.min = sevenDaysAgo.getTime()
    }

    if (props.minDateValue) {
      dateAxis.min = props.minDateValue.getTime()
    }

    // Force the date axis to display 1 day granularity
    dateAxis.baseInterval = {
      timeUnit: 'day',
      count: 1
    }

    const valueAxis = new am4charts.ValueAxis()
    valueAxis.cursorTooltipEnabled = false
    valueAxis.min = props.minValue !== undefined ? props.minValue : undefined
    valueAxis.max = props.maxValue !== undefined ? props.maxValue : undefined
    valueAxis.numberFormatter.numberFormat = "#.## '%'"
    x.yAxes.push(valueAxis)

    // Create series
    const series = x.series.push(new am4charts.LineSeries())
    series.dataFields.valueY = 'value'
    series.dataFields.dateX = 'date'
    series.tooltipText = props.tooltipText || '{value}'
    series.fillOpacity = 0.15
    series.fill = am4core.color(props.theme.colors.blue400)
    series.strokeWidth = 2
    series.stroke = am4core.color(props.theme.colors.blue700)

    series.tooltip = new am4core.Tooltip()
    series.tooltip.getFillFromObject = false

    series.tooltip.background.fill = am4core.color(props.theme.colors.gray400)
    series.tooltip.background.fillOpacity = 1
    series.tooltip.background.strokeWidth = 0
    series.tooltip.label.stroke = am4core.color(props.theme.colors.white)
    series.tooltip.label.strokeOpacity = 1
    series.tooltip.fontSize = 12
    series.tooltip.fontWeight = '100'
    series.bullets.push(new am4charts.CircleBullet())
    x.cursor = new am4charts.XYCursor()
    x.cursor.xAxis = dateAxis
    x.cursor.yAxis = valueAxis
    x.cursor.lineY.disabled = true
    x.cursor.behavior = 'none'

    return () => {
      x.dispose()
    }
  }, [
    id,
    now,
    props.items,
    props.maxValue,
    props.minDateValue,
    props.minValue,
    props.theme.colors.blue400,
    props.theme.colors.blue700,
    props.theme.colors.gray400,
    props.theme.colors.white,
    props.tooltipText,
    sevenDaysAgo
  ])

  const height = props.height || '300px'
  return <div id={id} style={{ width: '100%', height }} />
}

export const Chart = withTheme(ChartComponent)

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { groupFilterActions } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGroupListSaga(groupFilterService: UACServices.Services) {
  return function* getGroupListSaga(
    action: ReturnType<typeof groupFilterActions.getGroupList>
  ) {
    try {
      const query = buildQuery({
        ...action.payload.params.query,
        top: action.payload.resultsPerPage
      })
      const result: UACServices.GroupFilterAPI.Responses = yield call(
        groupFilterService.groupFilter,
        query
      )
      if (result.type === UACServices.GroupListAPI.SUCCESS) {
        const groups = result.body.groupsList
        const totalResults = result.body.totalResults
        yield put(groupFilterActions.setGroupList(groups, totalResults))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

export type groupId = string

export interface Group {
  id: groupId
  name: string
  userCount: number
  securityScore: number
}

export interface GroupResponse {
  id: groupId
  name: string
  userCount: number
  securityScore: number
  hasFederatedUsers: boolean | null
}

export enum GroupNameErrorMessages {
  groupNameTooLong = 'groupNameTooLong',
  groupNameEmpty = 'groupNameEmpty',
  groupNameAlreadyExists = 'groupNameAlreadyExists'
}

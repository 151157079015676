import React, { ReactElement } from 'react'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

export interface CustomizeUserListProps {
  closeLink: string
  mainTitle: ReactElement
}

export const UserListDrawer: React.FunctionComponent<CustomizeUserListProps> = props => {
  return (
    <Drawer mainTitle={props.mainTitle} closeLink={props.closeLink}>
      {props.children}
    </Drawer>
  )
}

import { put, select } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GeneralPolicyUsersAndGroups } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import {
  PasswordlessDrawerState,
  PasswordlessDrawerSteps
} from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  TeamsPolicy,
  TeamsPolicyGroup,
  TeamsPolicyModelData,
  TeamsPolicyUserInfo
} from '@lastpass/admin-console-dependencies/types/policy-teams'

import { getPasswordlessDrawerState } from './get-policy-users'

const getPolicyConfigUsers = (
  toBeRemovedUsers: TeamsPolicyUserInfo[],
  toBeAddedUsers: TeamsPolicyUserInfo[],
  existingUserList: TeamsPolicyUserInfo[]
): TeamsPolicyUserInfo[] => {
  const usersList = [...existingUserList]
  const filteredUsersList = usersList.filter(user => {
    return !toBeRemovedUsers.includes(user)
  })
  return [...filteredUsersList, ...toBeAddedUsers]
}
const getPolicyConfigGroups = (
  toBeRemovedGroups: TeamsPolicyGroup[],
  toBeAddedGroups: TeamsPolicyGroup[],
  existingGroupList: TeamsPolicyGroup[]
): TeamsPolicyGroup[] => {
  const groupsList = [...existingGroupList]
  const filteredGroupsList = groupsList.filter(user => {
    return !toBeRemovedGroups.includes(user)
  })
  return [...filteredGroupsList, ...toBeAddedGroups]
}

const preparePolicyData = (
  policy: TeamsPolicy,
  policyFormData: GeneralPolicyUsersAndGroups,
  toBeRemovedUsers: TeamsPolicyUserInfo[],
  toBeRemovedGroups: TeamsPolicyGroup[],
  toBeAddedUsers: TeamsPolicyUserInfo[],
  toBeAddedGroups: TeamsPolicyGroup[]
): TeamsPolicyModelData[] => {
  const policyData: TeamsPolicyModelData[] =
    (policy.policyData || []).length > 0
      ? policy.policyData
      : [
          {
            appliesTo: policyFormData.policyConfigFormData.appliesTo,
            policyValue: '1',
            policyNotes: '',
            cpid: null,
            inclusiveList: [],
            inclusiveGroupList: [],
            exclusiveList: [],
            exclusiveGroupList: []
          }
        ]
  return policyData.map((policyConfigInfo, index) => {
    if (index === policyFormData.policyConfigIndex) {
      policyConfigInfo = {
        ...policyConfigInfo,
        appliesTo: policyFormData.policyConfigFormData.appliesTo,
        inclusiveList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.inclusive && policyConfigInfo.inclusiveList
            ? getPolicyConfigUsers(
                toBeRemovedUsers,
                toBeAddedUsers,
                policyConfigInfo.inclusiveList
              )
            : [],
        inclusiveGroupList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.inclusive && policyConfigInfo.inclusiveGroupList
            ? getPolicyConfigGroups(
                toBeRemovedGroups,
                toBeAddedGroups,
                policyConfigInfo.inclusiveGroupList
              )
            : [],
        exclusiveList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.exclusive && policyConfigInfo.exclusiveList
            ? getPolicyConfigUsers(
                toBeRemovedUsers,
                toBeAddedUsers,
                policyConfigInfo.exclusiveList
              )
            : [],
        exclusiveGroupList:
          policyFormData.policyConfigFormData.appliesTo ===
            AppliesToOptions.exclusive && policyConfigInfo.exclusiveGroupList
            ? getPolicyConfigGroups(
                toBeRemovedGroups,
                toBeAddedGroups,
                policyConfigInfo.exclusiveGroupList
              )
            : []
      }
    }
    return policyConfigInfo
  })
}

export function createCalculatePolicyDataToSaveSaga() {
  return function* calculatePolicyDataToSave(
    action: ReturnType<
      typeof passwordlessDrawerActions.calculatePolicyDataToSave
    >
  ) {
    const policyFormData = action.payload.policyFormData
    const isAssign = action.payload.isAssign
    try {
      const passwordlessState: PasswordlessDrawerState = yield select(
        getPasswordlessDrawerState
      )
      const toBeRemovedUsers = isAssign
        ? []
        : passwordlessState.editFormState.usersList
      const toBeRemovedGroups = isAssign
        ? []
        : passwordlessState.editFormState.groupsList
      const toBeAddedUsers = isAssign
        ? passwordlessState.assignFormState.usersList
        : []
      const toBeAddedGroups = isAssign
        ? passwordlessState.assignFormState.groupsList
        : []

      const policyData = preparePolicyData(
        passwordlessState.policy,
        policyFormData,
        toBeRemovedUsers,
        toBeRemovedGroups,
        toBeAddedUsers,
        toBeAddedGroups
      )

      yield put(passwordlessDrawerActions.setPolicyDataToSave(policyData))

      yield put(
        passwordlessDrawerActions.setDrawerStep(PasswordlessDrawerSteps.allset)
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

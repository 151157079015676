import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

import { SystemUpgradeDrawerHeader } from '@lastpass/admin-console-dependencies/types/system-upgrade'
import { SystemUpgradeDrawerHeadersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/SystemUpgradeMapping'

import {
  DetailsBox,
  DrawerContainer,
  LegacyConsolePath,
  StyledLink,
  SystemUpgradeDrawerFooter,
  SystemUpgradeDrawerProps,
  SystemUpgradeDrawerStyledLink,
  SystemUpgradeFeatureNotAvailable
} from './SystemUpgradeDrawerResources'

const BoldText = styled.label`
  font-weight: 600;
`

const detailsBoxContent = [
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatFor],
    dataQa: 'authenticatorDrawerWhatFeatureForContainer',
    mainContent: (
      <Trans>
        This forced users to verify their identity with LastPass Authenticator
        instead of their master password when logging in to SSO apps, also known
        as Cloud Apps.
      </Trans>
    )
  },
  {
    title: SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.where],
    dataQa: 'authenticatorDrawerFeatureWhereContainer',
    mainContent: (
      <ul>
        <li>
          <Trans>New console</Trans>
          {': '}
          <SystemUpgradeDrawerStyledLink
            url="/applications/passwordless/ssoapps"
            dataQa="authenticatorDrawerNewConsoleLink"
          >
            <Trans>Applications</Trans>
            {' > '}
            <Trans>Passwordless apps</Trans>
            {' > '}
            <Trans>SSO apps tab</Trans>
          </SystemUpgradeDrawerStyledLink>
        </li>
        <li>
          <Trans>Old console</Trans>
          {': '}
          <SystemUpgradeFeatureNotAvailable />
        </li>
        <li>
          <LegacyConsolePath isNotAvailable={true} />
        </li>
      </ul>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[
        SystemUpgradeDrawerHeader.whatsHappening
      ],
    dataQa: 'authenticatorDrawerFeatureWhatHappeningContainer',
    mainContent: (
      <Trans>
        After upgrade, this feature will be removed from your LastPass Admin
        Console.
      </Trans>
    )
  },
  {
    title:
      SystemUpgradeDrawerHeadersMapping[SystemUpgradeDrawerHeader.whatToDo],
    dataQa: 'authenticatorDrawerFeatureWhatToDoContainer',
    mainContent: (
      <Trans>
        To achieve similar goals after upgrade, enable the{' '}
        <BoldText>Allow passwordless login</BoldText> policy, as described{' '}
        <StyledLink
          href="https://support.lastpass.com/s/document-item?bundleId=lastpass&topicId=LastPass/policy_passwordless_login_for_vault.html"
          target="_blank"
          data-qa="authenticatorSupportArticleLink"
        >
          in this article
        </StyledLink>
        {'.'}
      </Trans>
    )
  }
]

export const AuthenticatorDrawer: React.FunctionComponent<SystemUpgradeDrawerProps> = ({
  closeLink
}) => (
  <Drawer
    mainTitle={
      <Trans>
        Require passwordless verification via LastPass Authenticator
      </Trans>
    }
    subTitle={<Trans>Details</Trans>}
    closeLink={closeLink}
    data-qa="authenticatorDrawer"
  >
    <DrawerContainer data-qa="authenticatorDrawerContentContainer">
      {detailsBoxContent.map(content => (
        <DetailsBox
          key={content.dataQa}
          title={content.title}
          data-qa={content.dataQa}
        >
          {content.mainContent}
        </DetailsBox>
      ))}
    </DrawerContainer>
    <SystemUpgradeDrawerFooter
      closeLink={closeLink}
      data-qa="authenticatorDrawerFooter"
    />
  </Drawer>
)

import { Plan } from '@lastpass/tracking/tracking-plans/uac'

import { UserOperationActionTypes } from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'

import { EnumDictionary } from '../EnumValues'

interface SegmentTrackingEventNames {
  onClick: keyof Plan
  onResponse: keyof Plan
}

export const userOperationSegmentMapper: EnumDictionary<
  UserOperationActionTypes,
  SegmentTrackingEventNames
> = {
  [UserOperationActionTypes.ACCEPT_USERS]: {
    onClick: 'Accept User Clicked',
    onResponse: 'User Accepted'
  },
  [UserOperationActionTypes.DESTROY_ALL_SESSIONS]: {
    onClick: 'Destroy All Sessions Clicked',
    onResponse: 'All Sessions Destroyed'
  },
  [UserOperationActionTypes.DESTROY_ALL_SESSIONS_FOR_ITERATION_COUNT_POLICY]: {
    onClick: 'Destroy All Sessions Clicked',
    onResponse: 'All Sessions Destroyed'
  },
  [UserOperationActionTypes.LOCK_DEVICE_FOR_LPMFA]: {
    onClick: 'Lock Device for LastPass MFA Clicked',
    onResponse: 'LastPass MFA Device Locked'
  },
  [UserOperationActionTypes.REINVITE_USERS]: {
    onClick: 'Resend LastPass Invitation Clicked',
    onResponse: 'LastPass Invitation Resent'
  },
  [UserOperationActionTypes.REJECT_USERS]: {
    onClick: 'Reject User Clicked',
    onResponse: 'User Rejected'
  },
  [UserOperationActionTypes.RESEND_LP_MFA_INVITE]: {
    onClick: 'Resend LastPass MFA Invitation Clicked',
    onResponse: 'LastPass Invitation Resent'
  },
  [UserOperationActionTypes.UNINVITE_USERS]: {
    onClick: 'Uninvite User Clicked',
    onResponse: 'User Uninvited'
  },
  [UserOperationActionTypes.UNLOCK_DEVICE_FOR_LPMFA]: {
    onClick: 'Unlock Device for LastPass MFA Clicked',
    onResponse: 'LastPass MFA Device Unlocked'
  },
  [UserOperationActionTypes.DISABLE_USERS]: {
    onClick: 'Disable User Clicked',
    onResponse: 'User Disabled'
  },
  [UserOperationActionTypes.ACTIVATE_USERS]: {
    onClick: 'Activate User Clicked',
    onResponse: 'User Activated'
  },
  [UserOperationActionTypes.REQUIRE_PASSWORD_CHANGE]: {
    onClick: 'Require Master Password Change Clicked',
    onResponse: 'Master Password Change Required'
  },
  [UserOperationActionTypes.SELECT_FOR_VAULT_RE_ENCRYPTION]: {
    onClick: 'Force Vault Re-encryption Clicked',
    onResponse: 'Force Vault Re-encryption Enabled'
  },
  [UserOperationActionTypes.DELETE_USERS]: {
    onClick: 'Delete User Clicked',
    onResponse: 'Enterprise User Deleted'
  },
  [UserOperationActionTypes.DISABLE_MULTIFACTOR]: {
    onClick: 'Disable Multifactor Clicked',
    onResponse: 'Multifactor Disabled'
  },
  [UserOperationActionTypes.INVITE_USERS]: {
    onClick: 'Send LastPass Invitation Clicked',
    onResponse: 'LastPass Invitation Sent'
  },
  [UserOperationActionTypes.REMOVE_USERS_FROM_COMPANY]: {
    onClick: 'Remove User from Company Clicked',
    onResponse: 'User Removed from Company'
  },
  [UserOperationActionTypes.SELECT_FOR_FEDERATION]: {
    onClick: 'Select For Federation Clicked',
    onResponse: 'User Selected For Federation'
  },
  [UserOperationActionTypes.DESELECT_FOR_FEDERATION]: {
    onClick: 'De-select For Federation Clicked',
    onResponse: 'User De-selected For Federation'
  }
}

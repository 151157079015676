import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserSettings } from '@lastpass/admin-console-dependencies/types/user'

export const SUCCESS = 'success'
export const NOT_FOUND = 'notFound'
export const BAD_REQUEST = 'badRequest'

const success = (currentUserSettings: UserSettings) =>
  createResponse(SUCCESS, currentUserSettings)
const notFound = () => createResponse(NOT_FOUND)
const badRequest = () => createResponse(BAD_REQUEST)

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('users/current/settings', 'GET')

    if (status === StatusCodes.OK && response) {
      const userSettings = JSON.parse(response).content
      return success(userSettings)
    } else if (status === StatusCodes.NOT_FOUND) {
      return notFound()
    } else if (status === StatusCodes.BAD_REQUEST) {
      return badRequest()
    }
    throw new UnknownServiceError()
  }
}

import React from 'react'

import { Trans } from '@lingui/macro'
import { Field, FormikProps } from 'formik'

import { Checkbox } from '@lastpass/lastkit'

import {
  AzureSentinelIntegrationConfig,
  LogRhythmIntegrationConfig,
  QRadarIntegrationConfig,
  SplunkIntegrationConfig
} from '@lastpass/admin-console-dependencies/types/siem-integrations'

import { CheckboxContainer } from './StyledComponents'

interface SiemIntegrationsEnabledCheckboxProps {
  dataQa: string
  formik:
    | FormikProps<AzureSentinelIntegrationConfig>
    | FormikProps<SplunkIntegrationConfig>
    | FormikProps<QRadarIntegrationConfig>
    | FormikProps<LogRhythmIntegrationConfig>
}

export const SiemIntegrationsEnabledCheckbox: React.FC<SiemIntegrationsEnabledCheckboxProps> = ({
  dataQa,
  formik
}) => {
  return (
    <CheckboxContainer>
      <Field name="isDisabled">
        {({ field }) => (
          <Checkbox
            {...field}
            data-qa={dataQa}
            checked={!field.value}
            onChange={() => formik.setFieldValue('isDisabled', !field.value)}
          >
            <Trans>Enabled</Trans>
          </Checkbox>
        )}
      </Field>
    </CheckboxContainer>
  )
}

import { push } from 'connected-react-router'
import { delay, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentNotification: (state: AppState) => NotificationProps = (
  state: AppState
) => state.global.notification

export function createClearNotificationSaga() {
  return function* clearNotificationSaga(
    action:
      | ReturnType<typeof globalActions.clearNotification>
      | ReturnType<typeof push>
  ) {
    try {
      if (action.type === GlobalActionTypes.CLEAR_NOTIFICATION) {
        const delayTime = action.payload.delay
        if (delayTime) {
          yield delay(delayTime)
        }
        yield put(
          globalActions.setNotification({ type: NotificationType.hidden })
        )
      } else {
        const currentNotification: NotificationProps = yield select(
          getCurrentNotification
        )
        if (currentNotification.genericError) {
          yield put(
            globalActions.setNotification({ type: NotificationType.hidden })
          )
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import { UserTableRepository } from '@lastpass/admin-console-dependencies/repositories'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'

export function createGetUserTableSettings() {
  return function* getUserTableSettings() {
    try {
      const userTableColumns = yield call(UserTableRepository.get)
      if (userTableColumns) {
        yield put(userListActions.setUserListColumns(userTableColumns, false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

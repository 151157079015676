export enum MultifactorProvider {
  DUO = 'duo',
  GOOGLE_AUTHENTICATOR = 'googleauth',
  GRID = 'grid',
  LASTPASS_AUTHENTICATOR = 'lastpassauth',
  LASTPASS_MFA = 'lastpassmfa',
  FINGERPRINT = 'multifactor',
  MICROSOFT_AUTHENTICATOR = 'microsoftauth',
  SALESFORCE = 'salesforcehash',
  SECURE_AUTH = 'secureauth',
  SECURID = 'securid',
  SESAME = 'sesame',
  SYMANTEC_VIP = 'symantecvip',
  TOOPHER = 'toopher',
  TRANSAKT = 'transakt',
  YUBIKEY = 'yubikey',
  NO_MULTIFATOR = 'no_multifactor'
}

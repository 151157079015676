import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { SplunkIntegrationState } from './state'

export enum SplunkIntegrationActionTypes {
  SPLUNK_INTEGRATION_GET = '[splunk-integration] get',
  SPLUNK_INTEGRATION_SET = '[splunk-integration] set',
  SPLUNK_INTEGRATION_SAVE = '[splunk-integration] save',
  SPLUNK_INTEGRATION_SET_LOADING = '[splunk-integration] set-loading'
}

export const splunkIntegrationActions = {
  getSplunkIntegration: () =>
    createAction(SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_GET),
  setSplunkIntegration: (splunk: SplunkIntegrationState) =>
    createAction(SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_SET, {
      splunk
    }),
  setSplunkIntegrationLoading: (loading: boolean) =>
    createAction(SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_SET_LOADING, {
      loading
    }),
  saveSplunkIntegration: (splunk: SplunkIntegrationState) =>
    createAction(SplunkIntegrationActionTypes.SPLUNK_INTEGRATION_SAVE, {
      splunk
    })
}

export type SplunkIntegrationActions = ActionsUnion<
  typeof splunkIntegrationActions
>

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

import { MultifactorOption } from './state'

export enum EnabledMultifactorOptionsActionTypes {
  ENABLED_MULTIFACTOR_OPTIONS_GET = '[enabled-multifactor-options] get',
  ENABLED_MULTIFACTOR_OPTIONS_SET = '[enabled-multifactor-options] set',
  ENABLED_MULTIFACTOR_OPTIONS_SAVE = '[enabled-multifactor-options] save',
  ENABLED_MULTIFACTOR_OPTIONS_SET_LOADING = '[enabled-multifactor-options] set-loading',
  RESET_MFA_SHARED_SECRETS = '[enabled-multifactor-options] reset-mfa-shared-secrets'
}

export const enabledMultifactorOptionsActions = {
  getEnabledMultifactorOptions: () =>
    createAction(
      EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_GET
    ),
  setEnabledMultifactorOptions: (options: MultifactorOption[]) =>
    createAction(
      EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_SET,
      {
        options
      }
    ),
  setEnabledMultifactorOptionsLoading: (loading: boolean) =>
    createAction(
      EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_SET_LOADING,
      {
        loading
      }
    ),
  saveEnabledMultifactorOptions: (options: MultifactorOption[]) =>
    createAction(
      EnabledMultifactorOptionsActionTypes.ENABLED_MULTIFACTOR_OPTIONS_SAVE,
      {
        options
      }
    ),
  resetMfaSharedSecrets: () =>
    createAction(EnabledMultifactorOptionsActionTypes.RESET_MFA_SHARED_SECRETS)
}

export type EnabledMultifactorOptionsActions = ActionsUnion<
  typeof enabledMultifactorOptionsActions
>

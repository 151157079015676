import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { DetailsDialog, Heading200, PrimaryButton } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'

import { StyledBodyRegular, StyledCenter } from './SystemUpgradeDialog'

const StyledHeading = styled(Heading200)`
  margin-bottom: 20px;
  width: 100%;
`

const BodyElement = styled.div`
  margin-bottom: 20px;
  width: 100%;
`

export const SystemUpgradeErrorDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={() => {}}
      hideCloseIcon={true}
      width={'480px'}
      content={
        <StyledCenter data-qa="SystemUpgradeErrorDialog">
          <StyledHeading data-qa="SystemUpgradeErrorDialogHeading">
            <Trans>Partial success – Action required</Trans>
          </StyledHeading>
          <StyledBodyRegular data-qa="SystemUpgradeErrorDialogBody">
            <BodyElement>
              <Trans>
                Something went wrong during the update. It could be a connection
                issue or something on our side.
              </Trans>
            </BodyElement>
            <Trans>
              Go back to the overview page to see what’s left to do.
            </Trans>
          </StyledBodyRegular>
        </StyledCenter>
      }
      buttons={[
        <PrimaryButton
          data-qa="SystemUpgradeErrorDialogCloseButton"
          key="CloseDialogButton"
          onClick={() => {
            dispatch(systemUpgradeActions.resetUpgradeProcess())
            dispatch(globalActions.emptyDialog())
          }}
        >
          <Trans>Got it</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'

const now = new Date()
const sevenDaysAgo = new Date(
  Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 7)
)
export const unresponsiveUsersFilter = {
  filter: {
    and: [
      { or: [{ inviteEmailSentTimeUtc: { lt: sevenDaysAgo } }] },
      {
        or: [
          {
            accountStatus: AccountStatus.invited
          },
          {
            accountStatus: AccountStatus.expiredInvitation
          }
        ]
      }
    ]
  }
}

export function createGetUnresponsiveUsersSaga(
  dashboardService: UACServices.Services
) {
  return function* getUnresponsiveUsersSaga(
    action: ReturnType<typeof dashboardDrawerActions.getUnresponsiveUsers>
  ) {
    try {
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        ...unresponsiveUsersFilter,
        top: resultsPerPage
      })

      yield put(dashboardDrawerActions.setUnresponsiveUsersLoading(true))
      try {
        const result: UACServices.UsersAPI.Responses = yield call(
          dashboardService.users,
          query
        )
        if (result.type === UACServices.UsersAPI.SUCCESS) {
          const userList = result.body.userList
          const totalResults = result.body.totalResults
          yield put(
            dashboardDrawerActions.setTable(
              userList,
              totalResults,
              resultsPerPage,
              'unresponsiveUsers'
            )
          )
        }
      } finally {
        yield put(dashboardDrawerActions.setUnresponsiveUsersLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

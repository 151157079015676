import { OAuthManager } from '@lastpass/http/oauth-manager'
import { resolveUrl } from '@lastpass/utility'

import { getCookie } from '@lastpass/admin-console-dependencies/services/get-cookie'
import { capitalizeFirstLetter } from '@lastpass/admin-console-dependencies/services/text-operations/capitalize-first-letter'

import { FetchAPI } from './types'

type LegacyParam = string | number | boolean | undefined
/* eslint-disable-next-line */
type LegacyResponse = any

type Selector<T> = () => T | undefined

function isValidParam(param: LegacyParam): param is boolean | string | number {
  const type = typeof param
  return type === 'boolean' || type === 'string' || type === 'number'
}

export interface LegacyParams {
  [s: string]: LegacyParam
}

export function urlEncode(values: LegacyParams): string {
  return Object.keys(values).reduce((encoded, key) => {
    const value = values[key]
    if (isValidParam(value)) {
      return (
        encoded +
        (encoded ? '&' : '') +
        key +
        '=' +
        encodeURIComponent(value.toString())
      )
    }
    return encoded
  }, '')
}

/**
 * Redirects the user to the MFA reprompt page with the current page as a callback URL param
 * @param lastPassBaseUrl LastPass base URL
 */
export const mfaRedirect = (lastPassBaseUrl: string) => {
  const callbackUrl = window.location.href
  const urlParams = urlEncode({ from_uri: callbackUrl })
  window.location.replace(
    `${lastPassBaseUrl}/enterprise_multifactor_reprompt.php?${urlParams}`
  )
}

const contentType = 'Content-Type'
const headers = {
  [contentType]: 'application/json'
}

/**
 * Makes a request to an LastPass teams-api endpoint
 * @param path the path representing the server API to call (defined in sso/lmiapi/config/url_rules.php)
 * @param body the jsonifiable request body
 * @param abortSignal used to cancel the request if necessary
 */
function createTeamsAPI(
  baseUrl: Selector<string>,
  getCSRF: Selector<string>,
  fetchAPI: FetchAPI,
  promptMfa: () => Promise<void>,
  defaultHeaders?: {}
) {
  return async function fetchTeamsAPI(
    path: string,
    method: string,
    body?: {},
    abortSignal?: AbortSignal
  ): Promise<[string, number]> {
    const csrfResponse = await fetchAPI(`${baseUrl()}/teams-api/csrf`, {
      signal: abortSignal,
      credentials: 'include',
      headers: headers,
      method: 'GET'
    })
    const csrf = await csrfResponse.json()
    const response = await fetchAPI(`${baseUrl()}/teams-api/${path}`, {
      signal: abortSignal,
      credentials: 'include',
      headers: csrf
        ? { ...defaultHeaders, ...headers, 'X-CSRF-TOKEN': csrf.token }
        : { ...defaultHeaders, ...headers },
      ...(body
        ? {
            method: method,
            body: JSON.stringify(body)
          }
        : {})
    })

    const responseBody = await response.text()

    const unauthorizedStatusCode = 401
    const needMultifactorCode = 'UserNeedMultifactor'
    const userNotLoggedIn = 'UserNotLoggedIn'

    if (response.status === unauthorizedStatusCode) {
      const responseJson = JSON.parse(responseBody)
      if (responseJson.code && responseJson.code === needMultifactorCode) {
        await promptMfa()
        return ['', 0]
      } else if (responseJson.code && responseJson.code === userNotLoggedIn)
        await OAuthManager.login()
    }
    return [responseBody, response.status]
  }
}
export type TeamsAPIFetch = ReturnType<typeof createTeamsAPI>

function createFormDataBodyAndHeader(
  localHeaders: { [contentType]: string },
  useCsrefAuth: boolean,
  csrfHeader,
  body: {} | undefined,
  localBody: string | FormData
) {
  localHeaders = useCsrefAuth ? csrfHeader : {}

  const formDataBody = new FormData()
  for (const key in body) {
    if (body[key]) {
      formDataBody.append(
        capitalizeFirstLetter(key),
        Array.isArray(body[key]) ? JSON.stringify(body[key]) : body[key]
      )
    }
  }
  localBody = formDataBody
  return { localHeaders, localBody }
}

export function createIdentityAPI(
  baseUrl: string,
  fetchAPI: FetchAPI,
  defaultUrlParams: string
) {
  return async function fetchIdentityAPI(
    path: string,
    method: string,
    body?: {},
    abortSignal?: AbortSignal,
    useCsrefAuth = false,
    useFormDataBody = false
  ): Promise<[string, number]> {
    const unauthorized = 403
    let input = `${baseUrl}/${path}`
    if (defaultUrlParams) {
      const params = input.includes('?')
        ? `&${defaultUrlParams}`
        : `?${defaultUrlParams}`
      input += params
    }

    let localHeaders = { ...headers },
      csrfHeader,
      localBody: string | FormData

    localBody = JSON.stringify(body)
    if (useCsrefAuth) {
      const xCsrfToken = 'X-CSRF-TOKEN'
      const csrf = getCookie(xCsrfToken)
      if (!csrf) {
        return ['', unauthorized]
      }

      csrfHeader = { [xCsrfToken]: csrf }
      localHeaders = { ...localHeaders, ...csrfHeader }
    }

    if (useFormDataBody) {
      const __ret = createFormDataBodyAndHeader(
        localHeaders,
        useCsrefAuth,
        csrfHeader,
        body,
        localBody
      )
      localHeaders = __ret.localHeaders
      localBody = __ret.localBody
    }

    const response = await fetchAPI(input, {
      signal: abortSignal,
      credentials: 'include',
      headers: localHeaders,
      ...(body
        ? {
            method: method,
            body: localBody
          }
        : {})
    })

    const responseBody = await response.text()
    return [responseBody, response.status]
  }
}
export type IdentityAPIFetch = ReturnType<typeof createIdentityAPI>

/**
 * Makes a request to a legacy LastPass endpoint with application/x-www-form-urlencoded content
 * @param url the legacy php endpoint
 * @param body the body to include in the POST response
 */
function createFetchLegacy(
  baseUrlSelector: Selector<string>,
  csrfSelector: Selector<string>,
  fetchAPI: FetchAPI
) {
  return async function fetchLegacy(
    path: string,
    body?: { [s: string]: LegacyParam },
    abortSignal?: AbortSignal
  ): Promise<[string, number]> {
    const csrf = csrfSelector()
    const baseUrl = baseUrlSelector() || location.origin
    const response = await fetchAPI(resolveUrl(baseUrl, path), {
      signal: abortSignal,
      credentials: 'same-origin',
      ...(body
        ? {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: urlEncode(csrf ? { ...body, token: csrf } : body)
          }
        : {})
    })
    const text = await response.text()
    return [text, response.status]
  }
}

export function postForm(url: string, body?: { [s: string]: LegacyParam }) {
  const form = document.createElement('form')
  form.method = 'post'
  form.action = url
  if (body) {
    Object.keys(body).forEach(key => {
      const value = body[key]
      if (isValidParam(value)) {
        const input = document.createElement('input')
        input.name = key
        input.value = value.toString()
        form.appendChild(input)
      }
    })
  }
  document.documentElement.appendChild(form)
  form.submit()
}

export function createFetch(
  csrfSelector: Selector<string>,
  baseUrl: Selector<string> | string = '',
  identityBaseUrl = '',
  fetchAPI: FetchAPI = function() {
    /**
     * TODO This is kind of a crappy workaround to make fetch-mock
     * work since it only replaces a global fetch during the execution
     * of the test so we need to call fetch dynamically instead of
     * caching the fetch function, which would remain unmocked
     */
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return fetch.apply(window, arguments)
  },
  defaultHeaders?: {},
  defaultUrlParams = ''
) {
  const baseUrlSelector =
    typeof baseUrl === 'function' ? baseUrl : () => baseUrl
  const legacy = createFetchLegacy(baseUrlSelector, csrfSelector, fetchAPI)
  const promptMfa = async () => {
    mfaRedirect(baseUrlSelector() || '')
  }
  const teamsapi = createTeamsAPI(
    baseUrlSelector,
    csrfSelector,
    fetchAPI,
    promptMfa,
    defaultHeaders
  )

  const identityApi = createIdentityAPI(
    identityBaseUrl,
    fetchAPI,
    defaultUrlParams
  )
  return {
    legacy,
    teamsapi,
    identityApi: identityApi
  }
}

export type Fetch = ReturnType<typeof createFetch>

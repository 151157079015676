import { CSSTransition } from 'react-transition-group'

export function transitionTimeout(
  timeout: CSSTransition.CSSTransitionProps['timeout'],
  type: 'enter' | 'exit' | 'appear'
): number {
  if (typeof timeout === 'number') {
    return timeout
  }
  return timeout[type] || 0
}

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { groupDrawerActions } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/actions'
import { groupListActions } from '@lastpass/admin-console-dependencies/state/users/groups/list/actions'
import { GroupNameErrorMessages } from '@lastpass/admin-console-dependencies/types/group'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUrlFilters = (state: AppState) => state.router

const errorResultMessageMapping: {
  [key in GroupNameErrorMessages | 'default']: MessageDescriptor
} = {
  [GroupNameErrorMessages.groupNameAlreadyExists]: msg`Group hasn't been changed. A group already exists with this name.`,
  [GroupNameErrorMessages.groupNameEmpty]: msg`Group hasn't been changed. Invalid group name.`,
  [GroupNameErrorMessages.groupNameTooLong]: msg`Group hasn't been changed. Invalid group name.`,
  default: msg`Changes haven't been saved.`
}

export function createSaveGroupSaga(userService: UACServices.Services) {
  return function* saveGroupSaga(
    action: ReturnType<typeof groupDrawerActions.editGroup>
  ) {
    try {
      const group = action.payload.group
      const currentUrlFilters = yield select(getCurrentUrlFilters)
      if (group) {
        yield put(groupDrawerActions.setGroupLoading(true))

        try {
          const result: UACServices.SaveGroupAPI.Responses = yield call(
            userService.saveGroup,
            group
          )
          if (result.type === UACServices.SaveGroupAPI.SUCCESS) {
            yield put(
              globalActions.setNotification({
                message: msg`Changes saved.`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
            yield put(groupDrawerActions.setCurrentGroup(group))
          } else {
            yield put(
              globalActions.setNotification({
                message:
                  errorResultMessageMapping[result.type] ||
                  errorResultMessageMapping.default,
                type: NotificationType.alert,
                autoDismiss: false
              })
            )
          }
        } finally {
          yield put(
            groupListActions.getGroupsList({
              query: getQueryParams(currentUrlFilters.location, 'groups'),
              path: {}
            })
          )
          yield put(groupDrawerActions.setGroupLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { adminListActions } from '@lastpass/admin-console-dependencies/state/users/admin/list/actions'
import { AdminRoles } from '@lastpass/admin-console-dependencies/types/admin-roles'

export function createAdminListSaga(adminListService: UACServices.Services) {
  return function* getAdminListSaga() {
    try {
      yield put(adminListActions.setAdminsLoading(true))
      try {
        const result: UACServices.AdminListAPI.Responses = yield call(
          adminListService.admin
        )
        if (result.type === UACServices.AdminListAPI.SUCCESS) {
          const adminList = result.body.adminList.filter(
            admin => admin.id !== AdminRoles.legacyHelpdeskAdminId
          )
          const totalResults = adminList.length
          yield put(adminListActions.setAdminList(adminList, totalResults))
        }
      } finally {
        yield put(adminListActions.setAdminsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import {
  DuoSecuritySettingsActions,
  DuoSecuritySettingsActionTypes
} from './actions'
import { DuoSecuritySettingsState } from './state'

export const initialState: DuoSecuritySettingsState = {
  integrationKey: '',
  secretKey: '',
  apiHostName: '',
  loading: false
}

export const duoSecuritySettingsReducer = (
  previousState: DuoSecuritySettingsState | undefined,
  action: DuoSecuritySettingsActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_SET:
      return {
        ...state,
        ...action.payload.duoSettings
      }
    case DuoSecuritySettingsActionTypes.DUO_SECURITY_SETTINGS_SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }
    default:
      return state
  }
}

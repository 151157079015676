import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { genericDiscardFormConfirmDialog } from '@lastpass/admin-console-dependencies/resources/generic-discard-form-confirm-dialog'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export const useNavigationBlockingDialog = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isBlockingPromptEnabled, setIsBlockingPromptEnabled] = useState(false)
  useEffect(() => {
    let unblock
    if (isBlockingPromptEnabled) {
      unblock = history.block(location => {
        dispatch(
          globalActions.showConfirmDialogOnDiscard(
            genericDiscardFormConfirmDialog,
            `${location.pathname}${location.search}`,
            unblock
          )
        )
        return false
      })
    }
    return () => {
      unblock && unblock()
    }
  }, [dispatch, history, isBlockingPromptEnabled])

  return { setIsBlockingPromptEnabled }
}

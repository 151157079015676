import { WorkstationListActions, WorkstationListActionTypes } from './actions'
import { WorkstationListState } from './state'

export const initialState: WorkstationListState = {
  table: {
    results: [],
    resultsPerPage: 1,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  getStarted: false
}

export function workstationListReducer(
  previousState: WorkstationListState | undefined,
  action: WorkstationListActions
): WorkstationListState {
  const state = previousState || initialState

  switch (action.type) {
    case WorkstationListActionTypes.SET_WORKSTATION_LIST: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.list,
          totalResults: action.payload.total
        }
      }
    }
    case WorkstationListActionTypes.GET_STARTED:
      return {
        ...state,
        getStarted: true
      }
  }

  return state
}

import { Log, UserManager, UserManagerSettings } from 'oidc-client-ts'

import {
  CidOverrideRepository,
  MspPaymentReminderRepository
} from '@lastpass/admin-console-dependencies/repositories'

function createConfig(
  authServerUrl: string,
  clientId: string
): UserManagerSettings {
  const baseUri = window.location.origin + process.env.PUBLIC_URL
  const config: UserManagerSettings = {
    authority: authServerUrl,
    client_id: clientId,
    redirect_uri: `${baseUri}/auth/callback.html`,
    post_logout_redirect_uri: baseUri,

    // if we choose to use popup window instead for logins
    popup_redirect_uri: `${baseUri}/auth/popup.html`,
    popupWindowFeatures: {
      menubar: true,
      location: true,
      toolbar: true,
      width: 1200,
      height: 800,
      left: 100,
      top: 100,
      resizable: true
    },

    response_type: 'code',
    scope: 'openid profile company_details lpuacmiddlewareapi.full_access',

    loadUserInfo: true,

    silent_redirect_uri: `${baseUri}/auth/silent.html`,
    automaticSilentRenew: true,
    revokeTokensOnSignout: true,
    filterProtocolClaims: false,
    monitorSession: process.env.NODE_ENV !== 'development'
  }

  return config
}

let _manager: UserManager
const AUTH_MANAGER_NOT_DEFINED = 'Authentication manager is not initialized'

export function setManager(mgr: UserManager) {
  _manager = mgr
}

async function login(): Promise<void> {
  if (!_manager) {
    throw new Error(AUTH_MANAGER_NOT_DEFINED)
  }

  const redirectUrl =
    window.location.pathname + window.location.search + window.location.hash
  await _manager.signinRedirect({ state: redirectUrl, prompt: 'login' })
}

function initialize(
  authServerUrl: string,
  clientId: string,
  addLogging = false
): void {
  const config = createConfig(authServerUrl, clientId)
  _manager = new UserManager(config)
  _manager.clearStaleState()

  if (addLogging) {
    Log.setLogger(console)
    Log.setLevel(Log.DEBUG)

    _manager.events.addUserLoaded(user => {
      console.log('addUserLoaded')
      console.log(user)
    })
    _manager.events.addUserUnloaded(() => {
      console.log('User logged out locally')
    })
    _manager.events.addAccessTokenExpiring(() => {
      console.log('Access token expiring...')
    })
  }

  _manager.events.addSilentRenewError(err => {
    console.log('Silent renew error: ' + err.message)
    login()
  })
  _manager.events.addUserSignedOut(() => {
    console.log('User signed out of OP')
    if (process.env.NODE_ENV !== 'development') {
      login()
    }
  })
}

async function getAuthToken(): Promise<string> {
  if (!_manager) {
    throw new Error(AUTH_MANAGER_NOT_DEFINED)
  }

  const user = await _manager.getUser()
  if (!user) {
    await login()
    return ''
  }

  if (!user.access_token) {
    await login()
    return ''
  }

  return user.access_token
}

export interface OAuthUserProfile {
  email: string
  firstname?: string
  lastname?: string
  timezone?: string
  userId: string
  isEuUser: boolean
  isMsp?: boolean
}

async function getUserProfile(): Promise<OAuthUserProfile | null> {
  if (!_manager) {
    throw new Error(AUTH_MANAGER_NOT_DEFINED)
  }

  const user = await _manager.getUser()
  if (!user) {
    await login()
    return null
  }

  const profile: OAuthUserProfile = {
    email: user.profile.sub,
    firstname: user.profile.given_name,
    lastname: user.profile.family_name,
    timezone: typeof user.profile.tz === 'string' ? user.profile.tz : undefined,
    userId:
      typeof user.profile.user_id === 'string' ? user.profile.user_id : '',
    isEuUser: user.profile.eu_user === 'True',
    isMsp: user.profile.isMsp === 'True'
  }

  return profile
}

async function logout(): Promise<void> {
  if (!_manager) {
    throw new Error(AUTH_MANAGER_NOT_DEFINED)
  }

  try {
    await _manager.revokeTokens(['access_token'])

    // remove CID override from local storage
    await CidOverrideRepository.remove()

    // remove payment reminder from local storage
    await MspPaymentReminderRepository.remove()
  } catch {
    // continue regardless of error
  }

  await _manager.signoutRedirect()
}

export const OAuthManager = {
  initialize,
  login,
  getAuthToken,
  logout,
  getUserProfile
}

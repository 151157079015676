import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { ManagedCompaniesActionTypes } from '@lastpass/admin-console-dependencies/state/home/managed-companies/actions'
import { ManagedCompanyOperationActionTypes } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'

import { createCreateManagedCompanySaga } from './create-managed-company'
import { createGetCompanyTemplatesSaga } from './get-company-templates'
import { createGetManagedCompaniesSaga } from './get-managed-companies'
import { createAllocateLicensesToManagedCompanySaga } from './operations/allocate-licenses'
import { createDetachManagedCompanySaga } from './operations/detach'
import { createExportManagedCompaniesSaga } from './operations/export-managed-companies'
import { createExportUserCountsSaga } from './operations/export-user-counts'
import { createReactivateManagedCompanySaga } from './operations/reactivate'
import { createReturnLicensesFromManagedCompanySaga } from './operations/return-licenses'
import { createSuspendManagedCompanySaga } from './operations/suspend'

export function createManagedCompaniesSaga(
  managedCompanyServices: UACServices.Services
) {
  const getManagedCompaniesSaga = createGetManagedCompaniesSaga(
    managedCompanyServices
  )

  const getCompanyTemplatesSaga = createGetCompanyTemplatesSaga(
    managedCompanyServices
  )

  const reactivateManagedCompanySaga = createReactivateManagedCompanySaga(
    managedCompanyServices
  )

  const suspendManagedCompanySaga = createSuspendManagedCompanySaga(
    managedCompanyServices
  )

  const allocateLicensesSaga = createAllocateLicensesToManagedCompanySaga(
    managedCompanyServices
  )

  const returnLicensesSaga = createReturnLicensesFromManagedCompanySaga(
    managedCompanyServices
  )

  const createSaga = createCreateManagedCompanySaga(managedCompanyServices)

  const detachManagedCompanySaga = createDetachManagedCompanySaga(
    managedCompanyServices
  )
  const exportManagedCompanySaga = createExportManagedCompaniesSaga(
    managedCompanyServices
  )

  const exportUserCountsSaga = createExportUserCountsSaga(
    managedCompanyServices
  )

  return function*() {
    yield takeLatest(
      ManagedCompaniesActionTypes.GET_LIST,
      getManagedCompaniesSaga
    )

    yield takeLatest(
      ManagedCompaniesActionTypes.GET_COMPANY_TEMPLATES,
      getCompanyTemplatesSaga
    )

    yield takeLatest(
      ManagedCompanyOperationActionTypes.REACTIVATE,
      reactivateManagedCompanySaga
    )

    yield takeLatest(
      ManagedCompanyOperationActionTypes.SUSPEND,
      suspendManagedCompanySaga
    )

    yield takeLatest(
      ManagedCompanyOperationActionTypes.ALLOCATE_LICENSES,
      allocateLicensesSaga
    )

    yield takeLatest(
      ManagedCompanyOperationActionTypes.RETURN_LICENSES,
      returnLicensesSaga
    )

    yield takeLatest(ManagedCompaniesActionTypes.CREATE, createSaga)

    yield takeLatest(
      ManagedCompanyOperationActionTypes.DETACH,
      detachManagedCompanySaga
    )
    yield takeEvery(
      ManagedCompanyOperationActionTypes.EXPORT_MANAGED_COMPANIES,
      exportManagedCompanySaga
    )
    yield takeLatest(
      ManagedCompanyOperationActionTypes.SHOW_EXPORT_USER_COUNTS_DIALOG,
      exportUserCountsSaga
    )
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'
export const FAILURE = 'failure'

const success = () => createResponse(SUCCESS)
const failure = (reason: string) => createResponse(FAILURE, reason)

export const Responses = {
  success,
  failure
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(
    adminEmail: string,
    enabled: boolean,
    provisioningToken: string
  ) {
    try {
      const credentialJson = provisioningToken
        ? JSON.parse(provisioningToken)
        : null
      const [response, status] = await fetch(
        'google-workspace-provisioning/settings',
        'POST',
        {
          administratorEmail: adminEmail,
          isProvisioningEnabled: enabled,
          credentialJson
        },
        { 'content-type': 'application/json' }
      )
      const responseJson = (response && JSON.parse(response)) || {}
      if (status === StatusCodes.OK) {
        return success()
      } else {
        return failure(responseJson.message || '')
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

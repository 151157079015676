import { msg } from '@lingui/macro'

import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

export const CopyToClipboardNotification: NotificationProps = {
  type: NotificationType.success,
  autoDismiss: true,
  details: undefined,
  message: msg`Copied to clipboard`,
  genericError: false
}

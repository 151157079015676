import React, { useEffect } from 'react'

import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import {
  DashboardState,
  UserStateData
} from '@lastpass/admin-console-dependencies/state/dashboard/state'

type RemindInactiveUsersBlockProps = LastPassComponentProps<
  UserStateData,
  typeof dashboardActions,
  {}
>

export const DashboardContentComponent: React.FunctionComponent<RemindInactiveUsersBlockProps> = ({
  actions,
  children
}) => {
  useEffect(() => {
    actions.getDisplaySettings()
  }, [actions])

  return <>{children}</>
}

export function createDashboardContentComponent<
  TState extends { dashboard: DashboardState }
>() {
  return connectRedux(
    DashboardContentComponent,
    (state: TState) => state.dashboard.userStateData,
    dashboardActions
  )
}

export type DashboardContent = ReturnType<
  typeof createDashboardContentComponent
>

import { all, fork } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'

import {
  createAcronisApiSaga,
  createDuoSecuritySettingsSaga,
  createEnabledMultifactorOptionsSaga,
  createEnterpriseApiSaga,
  createNeverOnlyUrlsSaga,
  createPsaIntegrationsSaga,
  createRSASecureIDRadiusSaga,
  createSecureAuthSettingsSaga,
  createSplunkIntegrationSaga,
  createSymantecVipSettingsSaga,
  createUrlRulesSaga
} from './advanced'
import {
  createApplicationsSaga,
  createLegacyVpnSaga,
  createMfaApplicationsSaga,
  createSharedFolderSaga,
  createWorkstationsSaga
} from './applications'
import { createCommonSaga } from './common'
import { createCompanySaga } from './company'
import { createDashboardSaga } from './dashboard'
import { createGlobalSaga } from './global'
import { createManagedCompaniesSaga } from './home/managed-companies'
import {
  createGeneralPoliciesSaga,
  createMultifactorSaga,
  createPasswordlessSaga
} from './policies'
import { createReportsSaga, createSamlResponsesSaga } from './reports'
import { createSystemUpgradeSaga } from './upgrade/system'
import {
  createAdfsSetupSaga,
  createAdminSaga,
  createDirectoriesSaga,
  createFederatedKeyRotationSaga,
  createGroupsSaga,
  createOpenIdSetupSaga,
  createUsersSaga,
  createVaultReEncryptionOfferSaga,
  createVaultReEncryptionSaga
} from './users'

export function createRootSaga(userService: UACServices.Services) {
  const userSagas = createUsersSaga(userService)
  const groupsSaga = createGroupsSaga(userService)
  const adminSaga = createAdminSaga(userService)
  const directoriesSaga = createDirectoriesSaga(userService)
  const samlResponsesSaga = createSamlResponsesSaga(userService)
  const reportSagas = createReportsSaga(userService)

  const globalSagas = createGlobalSaga(userService)
  const urlRulesSaga = createUrlRulesSaga(userService)
  const neverOnlyUrlsSaga = createNeverOnlyUrlsSaga(userService)
  const splunkIntegrationSaga = createSplunkIntegrationSaga(userService)
  const adfsSetupSaga = createAdfsSetupSaga(userService)
  const openIdSetupSaga = createOpenIdSetupSaga(userService)
  const enterpriseApiSaga = createEnterpriseApiSaga(userService)
  const psaIntegrationsSaga = createPsaIntegrationsSaga(userService)
  const enabledMultifactorOptionsSaga = createEnabledMultifactorOptionsSaga(
    userService
  )
  const duoSecuritySettingsSaga = createDuoSecuritySettingsSaga(userService)
  const rsaSecureIDRadiusSaga = createRSASecureIDRadiusSaga(userService)
  const symantecVipSettingsSaga = createSymantecVipSettingsSaga(userService)
  const secureAuthSettingsSaga = createSecureAuthSettingsSaga(userService)

  const companySagas = createCompanySaga(userService)
  const generalPoliciesSaga = createGeneralPoliciesSaga(userService)
  const multifactorSaga = createMultifactorSaga(userService)
  const sharedFolderSaga = createSharedFolderSaga(userService)
  const applicationSaga = createApplicationsSaga(userService)
  const mfaApplicationSaga = createMfaApplicationsSaga(userService)
  const dashboardSaga = createDashboardSaga(userService)
  const legacyVpnSaga = createLegacyVpnSaga(userService)
  const workstationsSaga = createWorkstationsSaga(userService)
  const passwordlessSaga = createPasswordlessSaga(userService)
  const managedCompaniesSaga = createManagedCompaniesSaga(userService)
  const commonSaga = createCommonSaga(userService)
  const keyRotationSaga = createFederatedKeyRotationSaga(userService)
  const vaultReEncryption = createVaultReEncryptionSaga()
  const vaultReEncryptionOffer = createVaultReEncryptionOfferSaga()
  const systemUpgradeSaga = createSystemUpgradeSaga(userService)
  const acronisApiSaga = createAcronisApiSaga(userService)

  const sagas = [
    fork(userSagas),
    fork(globalSagas),
    fork(groupsSaga),
    fork(adminSaga),
    fork(directoriesSaga),
    fork(samlResponsesSaga),
    fork(reportSagas),
    fork(urlRulesSaga),
    fork(neverOnlyUrlsSaga),
    fork(splunkIntegrationSaga),
    fork(adfsSetupSaga),
    fork(openIdSetupSaga),
    fork(companySagas),
    fork(enterpriseApiSaga),
    fork(psaIntegrationsSaga),
    fork(enabledMultifactorOptionsSaga),
    fork(duoSecuritySettingsSaga),
    fork(generalPoliciesSaga),
    fork(multifactorSaga),
    fork(rsaSecureIDRadiusSaga),
    fork(symantecVipSettingsSaga),
    fork(secureAuthSettingsSaga),
    fork(sharedFolderSaga),
    fork(applicationSaga),
    fork(mfaApplicationSaga),
    fork(dashboardSaga),
    fork(legacyVpnSaga),
    fork(workstationsSaga),
    fork(passwordlessSaga),
    fork(managedCompaniesSaga),
    fork(commonSaga),
    fork(keyRotationSaga),
    fork(vaultReEncryption),
    fork(vaultReEncryptionOffer),
    fork(systemUpgradeSaga),
    fork(acronisApiSaga)
  ]

  return function*() {
    yield all(sagas)
  }
}

import { GroupFilterActions, GroupFilterActionTypes } from './actions'
import { GroupFilterState } from './state'

export const initialState: GroupFilterState = {
  initialList: {
    results: [],
    totalResults: 0
  },
  searchResults: {
    results: [],
    searchInput: ''
  },
  idMapping: {}
}

export function groupFilterReducer(
  state: GroupFilterState | undefined = initialState,
  action: GroupFilterActions
): GroupFilterState {
  switch (action.type) {
    case GroupFilterActionTypes.SET_GROUP_LIST: {
      return {
        ...state,
        initialList: {
          results: action.payload.list,
          totalResults: action.payload.totalResults
        }
      }
    }
    case GroupFilterActionTypes.SET_GROUPS: {
      return {
        ...state,
        searchResults: {
          results: action.payload.results,
          searchInput: action.payload.searchInput
        }
      }
    }
    case GroupFilterActionTypes.SET_GROUPS_LIST_BY_ID_LIST: {
      const idMapping = {}
      action.payload.groups.forEach(
        group => (idMapping[group.value] = group.label)
      )
      return {
        ...state,
        idMapping
      }
    }
  }
  return state
}

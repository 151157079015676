import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { Drawer } from '@lastpass/lastkit/components/Drawer'

interface URLRuleOptionsDrawerProps {
  closeLink: string
  children: ReactElement
}

export const URLRuleOptionsDrawer: React.FunctionComponent<URLRuleOptionsDrawerProps> = ({
  closeLink,
  children
}) => (
  <Drawer
    mainTitle={<></>}
    subTitle={<Trans>URL rule options</Trans>}
    closeLink={closeLink}
  >
    {children}
  </Drawer>
)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink, LocationRedirect } from '@lastpass/routing'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import { AssignState } from '@lastpass/admin-console-dependencies/state/common/assign/state'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'

export interface AllSetProps {
  closeLink: string
}

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
`

const BlockHeader = styled.h6`
  font-weight: ${props => props.theme.fonts.weight.semibold};
  margin-top: 24px;
  margin-bottom: 4px;
`
const maxElementsInList = 10

const getItemsList = (items: string[]) =>
  items.length > maxElementsInList
    ? items.length
    : items.map((item, index) => <div key={index}>{item}</div>)

const getBlock = (groups: string[], users: string[]) => (
  <>
    {groups.length > 0 ? (
      <>
        <BlockHeader data-qa="AssignedGroups">
          <Trans>Groups</Trans>
        </BlockHeader>
        {getItemsList(groups)}
      </>
    ) : null}
    {users.length > 0 ? (
      <>
        <BlockHeader data-qa="AssignedUsers">
          <Trans>Users</Trans>
        </BlockHeader>
        {getItemsList(users)}
      </>
    ) : null}
  </>
)

export const AllSet: React.FunctionComponent<AllSetProps> = ({ closeLink }) => {
  const assignState: AssignState = useSelector(
    (state: AppState) => state.assign
  )
  const advancedPolicyDrawerState: AdvancedPolicyDrawerState = useSelector(
    (state: AppState) => state.advancedPolicyDrawer
  )

  const dispatch = useDispatch()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsBlockingPromptEnabled(true), [])

  if (advancedPolicyDrawerState.policy === null) {
    return <LocationRedirect to={closeLink} />
  }

  const usersList = (assignState.users.list.results || []).map(
    user => user.email
  )
  const groupsList = (assignState.groups.list.results || []).map(
    group => group.name
  )

  let content: React.ReactElement = <></>
  if (assignState.appliesTo === AppliesToOptions.inclusive) {
    content = getBlock(groupsList, usersList)
  } else if (assignState.appliesTo === AppliesToOptions.exclusive) {
    content = (
      <>
        <Heading100
          css={css`
            margin-top: 24px;
          `}
        >
          <Trans>All users except</Trans>
        </Heading100>
        {getBlock(groupsList, usersList)}
      </>
    )
  }

  //get data
  if (
    (usersList.length === 0 && groupsList.length === 0) ||
    assignState.appliesTo === AppliesToOptions.all
  ) {
    content = (
      <>
        <Heading100
          css={css`
            margin-top: 24px;
          `}
        >
          <Trans>All users selected</Trans>
        </Heading100>
      </>
    )
  }

  return (
    <MainContainer>
      <Trans>
        These users and groups will be assigned to the following policy:
      </Trans>{' '}
      {advancedPolicyDrawerState.policy.policyTitle}
      {content}
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <LocationLink to={closeLink}>
            <TextButton
              css={css`
                margin-right: 16px;
              `}
              key="Discard"
              data-qa="DiscardButton"
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            data-qa="ContinueButton"
            onClick={() => {
              setIsBlockingPromptEnabled(false)
              dispatch(
                advancedPolicyDrawerActions.saveAdvancedPolicy(closeLink)
              )
            }}
          >
            <Trans>Save {'&'} finish</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </MainContainer>
  )
}

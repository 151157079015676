import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select, take } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses } from '@lastpass/admin-console-dependencies/server/applications/saml/delete-application-server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { applicationListActions } from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createDeleteApplication(
  applicationsServices: UACServices.Services
) {
  return function* deleteApplication(
    action: ReturnType<typeof applicationsDrawerActions.deleteApplication>
  ) {
    try {
      const currentUrlFilters = yield select(getCurrentUrlFilters)
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Delete app`,
        text: msg`Delete this app form LastPass: ${action.payload.appName}? This removes the app configuration and prevents users from logging in via SSO.`,
        discardText: msg`Cancel`,
        confirmText: msg`Delete`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield put(applicationsDrawerActions.setDrawerLoading(true))

        try {
          const response: Responses = yield call(
            applicationsServices.deleteApplication,
            action.payload.appId
          )

          if (response.type === UACServices.SSOApplicationsAPI.SUCCESS) {
            if (response.body.response.Succeeded) {
              yield put(
                globalActions.setNotification({
                  message: msg`App deleted.`,
                  type: NotificationType.success,
                  autoDismiss: true
                })
              )
              yield put(
                applicationListActions.getApplicationList({
                  query: getQueryParams(currentUrlFilters.location, 'apps'),
                  path: {}
                })
              )
              yield put(push('/applications/saml'))
            } else {
              yield put(
                globalActions.setNotification(genericFailedNotification)
              )
            }
          }
        } finally {
          yield put(applicationsDrawerActions.setDrawerLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

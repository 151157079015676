import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CSSTransition } from 'react-transition-group'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodySemiboldStyle, PrimaryButton } from '@lastpass/lastkit'

export const StyledPrimaryButton = styled(PrimaryButton)`
  height: 40px;
  width: 202px;
  white-space: nowrap;
  text-align: center;
`

export const BoldWrapper = styled.span`
  ${BodySemiboldStyle}
`

export const StyledCopySpan = styled.span`
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 100;
  &.copyText-enter-active {
    cursor: default;
    opacity: 0;
  }
  color: ${props => props.theme.colors.blue};
`

export const StyledCopiedText = styled.span`
  color: ${props => props.theme.colors.green};
  transition: opacity 0.3s;
  opacity: 0;
  &.copyText-enter-active {
    opacity: 100;
  }
`

export const StyledSetupDiv = styled.div`
  margin: 5px 0 5px 0;
`
export const StyledTokenDiv = styled.div`
  margin: 10px 0 10px 0;
`
export const StyledInstructionDiv = styled.div`
  margin-top: 7px;
`

export const StyledTextRow = styled.span`
  margin-right: 20px;
`

export const CopiedComponent: React.FunctionComponent<{
  url: string
}> = props => {
  const [copied, setCopied] = useState(false)
  const handleClickShowAlert = () => {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }
  return (
    <>
      <CopyToClipboard text={props.url} onCopy={() => handleClickShowAlert()}>
        <CSSTransition classNames={'copyText'} timeout={5000} in={copied}>
          <StyledCopySpan>
            <Trans>Copy</Trans>
          </StyledCopySpan>
        </CSSTransition>
      </CopyToClipboard>
      <CSSTransition classNames={'copyText'} timeout={5000} in={copied}>
        <StyledCopiedText>
          <Trans>Copied</Trans>
        </StyledCopiedText>
      </CSSTransition>
    </>
  )
}

import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as GeneralPoliciesIcon } from '@lastpass/assets/svg/admin-console/icon-general-policies.svg'
import { ReactComponent as MFAAuthenticationIcon } from '@lastpass/assets/svg/admin-console/icon-mfa-authentication.svg'
import { ReactComponent as PasswordlessIcon } from '@lastpass/assets/svg/admin-console/icon-passwordless.svg'
import {
  ContentContainerStyle,
  ScrollableContentContainer,
  SidebarContainer
} from '@lastpass/lastkit'
import { SideNavigation } from '@lastpass/lastkit/components/SideNavigation'
import { RoutedSideNavigationItem } from '@lastpass/routing'
import { getDefaultUrl, SideMenuItem } from '@lastpass/routing/get-default-url'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { PasswordlessContainer } from '@lastpass/admin-console-dependencies/ui/policies/passwordless/PasswordlessContainer'

import { GeneralPoliciesViewPage } from './general-policies/GeneralPoliciesPage'
import { MultifactorPageContainer } from './multifactor/MultifactorPageContainer'

const StyledScrollableContentContainer = styled(ScrollableContentContainer)`
  padding-top: 50px;
`

export const PoliciesPage: FunctionComponent = () => {
  const match = useRouteMatch()
  const permissions = useContext(PermissionContext)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch])

  const companyDetails = useSelector<AppState, CompanyDetails>(
    state => state.companyInfo.details
  )

  const userHasMfaAndNotOnTrial =
    (CompanyDetailsHelper.userHasMfaAddon(companyDetails) &&
      !CompanyDetailsHelper.isUserOnBusinessTrial(companyDetails)) ||
    false

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()

  const policiesPageVisibility = {
    isMultifactorVisible: permissions.requirePermission(
      AdminPermissions.mfaModify
    ),
    isPasswordlessVisible: permissions.requirePermission(
      AdminPermissions.mfaModify
    ),
    isPasswordlessEnabledOrUsed:
      !isNpPasswordlessFeatureDisabled || !isPasswordlessPoliciesEmpty
  }

  const getSideMenuItems = (
    matchUrl: string,
    matchPath: string
  ): SideMenuItem[] => {
    const sideMenuItems: SideMenuItem[] = []
    sideMenuItems.push({
      url: `${matchUrl}/generalpolicies`,
      route: { path: `${matchPath}/generalpolicies` },
      icon: GeneralPoliciesIcon,
      qaData: 'SideMenuGeneralPolicies',
      header: <Trans>General policies</Trans>,
      content: <GeneralPoliciesViewPage />
    })
    if (policiesPageVisibility.isMultifactorVisible) {
      sideMenuItems.push({
        url: `${matchUrl}/multifactor`,
        route: { path: `${matchPath}/multifactor` },
        icon: MFAAuthenticationIcon,
        qaData: 'SideMenuMultifactor',
        header: <Trans>Multifactor</Trans>,
        content: <MultifactorPageContainer />
      })
    }
    if (
      policiesPageVisibility.isPasswordlessVisible &&
      policiesPageVisibility.isPasswordlessEnabledOrUsed &&
      !isNoPasswordDeprecated
    ) {
      sideMenuItems.push({
        url: `${matchUrl}/passwordless/accesspolicies`,
        route: { path: `${matchPath}/passwordless` },
        icon: PasswordlessIcon,
        tooltipText: userHasMfaAndNotOnTrial ? (
          <Trans>This feature is part of the Advanced MFA add-on</Trans>
        ) : (
          <Trans>
            Get the Advanced MFA add-on to eliminate passwords for employees.
          </Trans>
        ),
        qaData: 'SideMenuPasswordlessPolicies',
        header: <Trans>Passwordless</Trans>,
        content: <PasswordlessContainer />
      })
    }

    return sideMenuItems
  }

  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const sideMenuItems = getSideMenuItems(matchUrl, matchPath)

    return (
      <>
        <SidebarContainer>
          <SideNavigation>
            {sideMenuItems.map(item => (
              <RoutedSideNavigationItem
                key={item.qaData}
                data-qa={item.qaData}
                className={item.className}
                tooltipText={item.tooltipText}
                tooltipWidthPixels={userHasMfaAndNotOnTrial ? 310 : 300}
                to={item.url}
                route={item.route}
                icon={item.icon}
              >
                {item.header}
              </RoutedSideNavigationItem>
            ))}
          </SideNavigation>
        </SidebarContainer>
        <ContentContainerStyle>
          <StyledScrollableContentContainer>
            <Switch>
              {sideMenuItems.map(item => (
                <Route key={item.qaData} {...item.route}>
                  {item.content}
                </Route>
              ))}
              <Route>
                <Redirect to={getDefaultUrl(sideMenuItems)} />
              </Route>
            </Switch>
          </StyledScrollableContentContainer>
        </ContentContainerStyle>
      </>
    )
  }
  return null
}

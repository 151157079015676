import React, { FC } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { BodyRegularStyle } from '@lastpass/lastkit'

import { BlockHeader } from '@lastpass/admin-console-dependencies/ui/home/BlockHeader'

import { ConsumedLicensesChart } from './ConsumedLicensesChart'

const Container = styled.div`
  display: flex;
`

const DataColumn = styled.div`
  width: 40%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ChartColumn = styled.div`
  width: 60%;
  max-height: 300px;
`

const NumberRow = styled.div`
  ${BodyRegularStyle}
  padding: 12px 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutral200};
  display: flex;

  &:last-child {
    border: none;
  }

  & > *:first-of-type {
    margin-right: 16px;
  }
`

export interface LicensesProps {
  consumedLicenses: number
  totalLicensesAllocated: number
}

export const Licenses: FC<LicensesProps> = ({
  consumedLicenses,
  totalLicensesAllocated
}) => (
  <>
    <BlockHeader title={msg`Licenses`} />
    <Container>
      <DataColumn>
        <NumberRow>
          <span data-qa="ManagedCompanyAggregateConsumedLicenses">
            {consumedLicenses}
          </span>
          <span>
            <Trans>Consumed by users</Trans>
          </span>
        </NumberRow>
        <NumberRow>
          <span data-qa="ManagedCompanyAggregateTotalLicensesAllocated">
            {totalLicensesAllocated}
          </span>
          <span>
            <Trans>Allocated to companies</Trans>
          </span>
        </NumberRow>
      </DataColumn>
      <ChartColumn>
        <ConsumedLicensesChart
          consumedLicenses={consumedLicenses}
          totalLicensesAllocated={totalLicensesAllocated}
        />
      </ChartColumn>
    </Container>
  </>
)

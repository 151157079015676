import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardActions } from '@lastpass/admin-console-dependencies/state/dashboard/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

export function createGetUrlEncryptionMigrationStateSaga(
  dashboardServices: UACServices.Services
) {
  return function* getUrlEncryptionMigrationStateSaga() {
    try {
      yield put(dashboardActions.setUrlEncryptionMigrationStateLoading(true))

      const result: UACServices.UrlEncryptionMigrationStateAPI.Responses = yield call(
        dashboardServices.urlEncryptionMigrationState
      )
      if (result.type === UACServices.UrlEncryptionMigrationStateAPI.SUCCESS) {
        const { totalEnrolledUserCount, migratedUserCount } = result.body

        const isMigrateToVaultFormatVersionV2Enabled =
          migratedUserCount !== null && totalEnrolledUserCount !== null

        yield put(
          dashboardActions.setUrlEncryptionMigrationState(
            totalEnrolledUserCount,
            migratedUserCount,
            isMigrateToVaultFormatVersionV2Enabled
          )
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(dashboardActions.setUrlEncryptionMigrationStateLoading(false))
    }
  }
}

import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  DetailsDialog,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import { DistributeRecoveryKeysState } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/state'

const StyledParagraph = styled.p`
  ${BodyRegularStyle};
  margin-bottom: 8px;
`

export const SomeKeysWereSharedErrorDialog: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(distributeRecoveryKeysActions.trackRecoveryKeySharingErrorViewed())
  }, [dispatch])

  const { totalNumberOfKeys, numberOfSharedKeys } = useSelector<
    AppState,
    DistributeRecoveryKeysState
  >(state => state.distributeRecoveryKeys)

  const content = (
    <StyledParagraph>
      <Trans>
        {numberOfSharedKeys} / {totalNumberOfKeys} recovery keys have been
        shared invisibly. To share the missing items, try again. If the problem
        persists, contact LastPass support:{' '}
        <a href="https://support.lastpass.com" target="_blank" rel="noreferrer">
          https://support.lastpass.com
        </a>
      </Trans>
    </StyledParagraph>
  )

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.emptyDialog())
      }}
      width="650px"
      title={msg`Not all recovery keys could be shared`}
      titleColor="red"
      content={content}
      buttons={[
        <PrimaryButton
          onClick={() => {
            dispatch(globalActions.emptyDialog())
          }}
          key="confirm"
          data-qa="RecoveryKeySharingConfirmDialogConfirmButton"
        >
          <Trans>OK</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'
import { Field } from 'formik'

import { BodyRegularStyle, CaptionRegularStyle } from '@lastpass/lastkit/styles'

export interface ExtendableFormCellProps {
  value: string
  width?: string
  error?: string
  required?: boolean
  focus?: boolean
  name: string
  recordIndex: number
  columnIndex: number
  lastFieldOfLastRecord: boolean
  lastRecord: boolean
  addRecord: Function
  setRecord: (row: number, column: number, value: string) => void
  updateValue: (value: string) => void
  showDuplicateError: boolean
}

const Cell = styled.span<{ contentWidth?: string }>`
  color: ${props => props.theme.colors.neutral900};
  min-width: ${props => (props.contentWidth ? 'auto' : '120px')};
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: ${props => (props.contentWidth ? '16px' : '5px')};
  padding-right: ${props => (props.contentWidth ? '4px' : '8px')};
  padding-left: ${props => (props.contentWidth ? '4px' : '8px')};
  flex-basis: ${props => (props.contentWidth ? props.contentWidth : '100%')};
  word-break: break-word;
`

const StyledCell = styled(Cell)<{
  error: boolean
  width?: string
  columnIndex?: number
}>`
  padding: ${props => (props.error ? '16px 16px -1px 4px' : 'auto')};
  padding-top: ${props => (props.error ? 'auto' : '20px')};
  padding-bottom: ${props => (props.error ? 'auto' : '20px')};
  min-width: ${props => (props.width ? props.width : 'auto')};
  max-width: ${props => (props.width ? props.width : 'auto')};
  flex-grow: ${props => (props.columnIndex && props.columnIndex == 0 ? 2 : 1)};
`

const StyledField = styled(Field)<{ error?: boolean }>`
  ${BodyRegularStyle};
  height: 40px;
  border: ${props => '1px solid ' + props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: ${props => props.theme.radius.pixel4};
  padding-left: 10px;
  border-color: ${props => (props.error ? 'red' : 'auto')};
  margin-top: ${props => (props.error ? '15px' : 'auto')};
  width: 100%;
`

const StyledError = styled.span`
  ${CaptionRegularStyle};
  position: relative;
  color: ${props => props.theme.colors.red700};
  line-height: 15px;
`

export const ExtendableFormCell = ({ ...extendableFormCellProps }) => {
  const shouldFocus =
    (extendableFormCellProps.focus || extendableFormCellProps.lastRecord) &&
    extendableFormCellProps.columnIndex === 0 &&
    !extendableFormCellProps.searchInputFocused
  return (
    <>
      <StyledCell
        error={
          extendableFormCellProps.error ||
          extendableFormCellProps.showDuplicateError
        }
        width={extendableFormCellProps.width}
        columnIndex={extendableFormCellProps.columnIndex}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          extendableFormCellProps.updateValue(event.target.value)
        }}
        onKeyDown={event => {
          const isTabClick = event.nativeEvent.key === 'Tab'
          const isEnterClick = event.nativeEvent.key === 'Enter'
          const isRowEmpty = extendableFormCellProps.value === undefined
          if (
            isTabClick &&
            extendableFormCellProps.lastFieldOfLastRecord &&
            !isRowEmpty
          ) {
            event.preventDefault()
            extendableFormCellProps.addRecord()
          }

          if (isEnterClick) {
            event.preventDefault()
            extendableFormCellProps.addRecord()
          }
          extendableFormCellProps.setSearchInputFocused(false)
        }}
      >
        <StyledField
          type="text"
          name={`records[${extendableFormCellProps.recordIndex}].${extendableFormCellProps.name}`}
          placeholder={
            extendableFormCellProps.required
              ? i18n._(msg`Required`)
              : i18n._(msg`Optional`)
          }
          value={extendableFormCellProps.value || ''}
          autoFocus={shouldFocus}
          error={
            !(
              extendableFormCellProps.focus &&
              extendableFormCellProps.columnIndex === 0
            ) && extendableFormCellProps.error
              ? extendableFormCellProps.error
              : extendableFormCellProps.showDuplicateError
              ? 'Duplicate'
              : null
          }
          data-qa={'AddUserInputField'}
        />
        {!(
          extendableFormCellProps.focus &&
          extendableFormCellProps.columnIndex == 0
        ) &&
          (extendableFormCellProps.error ||
            extendableFormCellProps.showDuplicateError) && (
            <StyledError>
              {extendableFormCellProps.error
                ? extendableFormCellProps.error
                : 'Duplicate'}
            </StyledError>
          )}
      </StyledCell>
    </>
  )
}

import { DataFetchParams } from '@lastpass/routing'
import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import { Policy } from '@lastpass/admin-console-dependencies/types/policy'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export interface GroupDrawerUrlParams {
  id: string
}

export interface GroupDrawerQueryParams {
  search: string
  skip: number
}

export enum GroupDrawerActionTypes {
  RESET = '[group-drawer] reset',
  GET_CURRENT_GROUP = '[group-drawer] get-current-group',
  SET_CURRENT_GROUP = '[group-drawer] set-current-group',
  SET_CURRENT_GROUP_HAS_FEDERATED_USERS = '[group-drawer] set-current-group-has-federated-users',
  SET_GROUP_LOADING = '[group-drawer] set-group-loading',

  GET_GROUP_USERS = '[group-drawer] get-group-users',
  SET_GROUP_USERS = '[group-drawer] set-group-users',
  SET_GROUP_USERS_LOADING = '[group-drawer] set-group-users-loading',
  ADD_SELECTED_GROUP_USERS = '[group-user-list-assign] assign-selected-users',
  REMOVE_SELECTED_GROUP_USERS = '[group-user-list-assign] remove-selected-users',
  SET_SELECTED_GROUP_USERS = '[group-user-list-assign] set-selected-users',
  REMOVE_USERS_FROM_GROUP = '[group-operations] remove-users',

  GET_GROUP_POLICIES = '[group-drawer] get-group-policies',
  SET_GROUP_POLICIES = '[group-drawer] set-group-policies',
  SET_GROUP_POLICIES_LOADING = '[group-drawer] set-group-policies-loading',

  EDIT_GROUP = '[group-drawer] edit-group'
}

export const groupDrawerActions = {
  reset: () => createAction(GroupDrawerActionTypes.RESET),
  getCurrentGroup: (params: DataFetchParams<{}, GroupDrawerUrlParams>) =>
    createAction(GroupDrawerActionTypes.GET_CURRENT_GROUP, {
      params
    }),
  setCurrentGroup: (group: Group) =>
    createAction(GroupDrawerActionTypes.SET_CURRENT_GROUP, {
      group
    }),
  setCurrentGroupHasFederatedUsers: (hasFederatedUsers: boolean | null) =>
    createAction(GroupDrawerActionTypes.SET_CURRENT_GROUP_HAS_FEDERATED_USERS, {
      hasFederatedUsers
    }),
  setGroupLoading: (loading: boolean) =>
    createAction(GroupDrawerActionTypes.SET_GROUP_LOADING, loading),

  // GROUP USERS
  getGroupUsers: (
    params: DataFetchParams<GroupDrawerQueryParams, GroupDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(GroupDrawerActionTypes.GET_GROUP_USERS, {
      params,
      resultsPerPage
    }),
  setGroupUsers: (
    users: User[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(GroupDrawerActionTypes.SET_GROUP_USERS, {
      users,
      totalResults,
      resultsPerPage
    }),
  setGroupUsersLoading: (loading: boolean) =>
    createAction(GroupDrawerActionTypes.SET_GROUP_USERS_LOADING, loading),
  addSelectedGroupUsers: (userList: User[]) =>
    createAction(GroupDrawerActionTypes.ADD_SELECTED_GROUP_USERS, {
      userList
    }),
  removeSelectedGroupUsers: (userList: User[]) =>
    createAction(GroupDrawerActionTypes.REMOVE_SELECTED_GROUP_USERS, {
      userList
    }),
  setSelectedGroupUsers: (userList: User[]) =>
    createAction(GroupDrawerActionTypes.SET_SELECTED_GROUP_USERS, {
      userList
    }),
  removeUsersFromGroup: (userList: User[], group: Group) =>
    track(
      createAction(GroupDrawerActionTypes.REMOVE_USERS_FROM_GROUP, {
        userList,
        group
      }),
      {
        segment: uacEvent('Remove Users from Group Clicked')
      }
    ),

  // GROUP POLICIES
  getGroupPolicies: (
    params: DataFetchParams<{}, GroupDrawerUrlParams>,
    resultsPerPage = 25
  ) =>
    createAction(GroupDrawerActionTypes.GET_GROUP_POLICIES, {
      params,
      resultsPerPage
    }),
  setGroupPolicies: (
    policies: Policy[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(GroupDrawerActionTypes.SET_GROUP_POLICIES, {
      policies,
      totalResults,
      resultsPerPage
    }),
  setGroupPoliciesLoading: (loading: boolean) =>
    createAction(GroupDrawerActionTypes.SET_GROUP_POLICIES_LOADING, loading),

  // EDIT GROUP
  editGroup: (group: Group) =>
    createAction(GroupDrawerActionTypes.EDIT_GROUP, { group })
}

export type GroupDrawerActions = ActionsUnion<typeof groupDrawerActions>

import {
  CompanyDetails,
  SubscriptionType
} from '@lastpass/admin-console-dependencies/state/company/state'

import { CompanyDetailsHelper } from './company-details-helper'

export const companyHasMfa = (companyDetails: CompanyDetails): boolean =>
  CompanyDetailsHelper.hasAnyPermission(companyDetails)
    ? CompanyDetailsHelper.isLegacyVpnAllowed(companyDetails) === true ||
      CompanyDetailsHelper.isWorkstationAllowed(companyDetails) === true ||
      CompanyDetailsHelper.isPasswordlessAllowed(companyDetails) === true ||
      CompanyDetailsHelper.isMultifactorAllowed(companyDetails) === true ||
      CompanyDetailsHelper.isMicrosoftAzureAdAllowed(companyDetails) === true ||
      CompanyDetailsHelper.isThirdPartyIdpAllowed(companyDetails) === true
    : companyDetails.subscription === SubscriptionType.mfa ||
      companyDetails.subscription === SubscriptionType.iDaaS

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { secureAuthSettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/actions'
import { SecureAuthSettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSaveSecureAuthSettings(
  enterpriseOptionServices: UACServices.Services
) {
  return function* saveSecureAuthSettings(
    action: ReturnType<typeof secureAuthSettingsActions.saveSecureAuthSettings>
  ) {
    try {
      const secureAuthSettings: SecureAuthSettingsState =
        action.payload.secureAuthSettings
      yield put(secureAuthSettingsActions.setSecureAuthSettingsLoading(true))
      try {
        const secureAuthSettingsResult: UACServices.SecureAuthSettingsSaveAPI.Responses = yield call(
          enterpriseOptionServices.secureAuthSettingsSave,
          secureAuthSettings
        )
        if (
          secureAuthSettingsResult.type ===
          UACServices.SecureAuthSettingsSaveAPI.SUCCESS
        ) {
          yield put(
            secureAuthSettingsActions.setSecureAuthSettings(secureAuthSettings)
          )
          yield put(
            globalActions.setNotification({
              message: msg`SecureAuth settings saved`,
              type: NotificationType.success,
              autoDismiss: true
            })
          )
        } else {
          yield put(
            globalActions.setNotification({
              message: msg`SecureAuth settings could not be saved`,
              type: NotificationType.alert,
              autoDismiss: true
            })
          )
        }
      } finally {
        yield put(secureAuthSettingsActions.setSecureAuthSettingsLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactNode } from 'react'

import styled from '@emotion/styled'

import { CardContainer } from './CardContainer'
import { DrawerButtonsContainer, FooterContainer, StyledSpacer } from './Drawer'
import { Table, TableProps } from './Table'

const DrawerTableContainer = styled(CardContainer)`
  margin-top: 16px;
`

export interface DrawerTableProps<T> extends TableProps<T> {
  footerNodes?: ReactNode
}

export const DrawerTable = <T extends {}>(props: DrawerTableProps<T>) => {
  return (
    <>
      <DrawerTableContainer>
        <Table {...props} />
      </DrawerTableContainer>
      {props.footerNodes ? (
        <>
          <StyledSpacer />
          <FooterContainer>
            <DrawerButtonsContainer>{props.footerNodes}</DrawerButtonsContainer>
          </FooterContainer>
        </>
      ) : (
        <div />
      )}
    </>
  )
}

import React, { ReactElement } from 'react'

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}

export interface EnumValuesProps<T extends string | symbol | number> {
  values?: T[]
  mapping: EnumDictionary<T, ReactElement>
  delimiter?: string | ReactElement | undefined
  emptyValues?: string | ReactElement | undefined
}

interface EnumValueProps {
  value: ReactElement
  delimiter?: string | ReactElement | undefined
}

const EnumValue = (props: EnumValueProps) => (
  <>
    {props.value}
    {props.delimiter || ' '}
  </>
)

export const EnumValues = <T extends string | symbol | number>(
  props: EnumValuesProps<T>
) => {
  if (!props.values || props.values.length === 0) {
    return <>{props.emptyValues || '-'}</>
  }

  return (
    <>
      {props.values.map((x, i) => (
        <EnumValue
          key={i}
          value={props.mapping[x]}
          delimiter={props.delimiter}
        />
      ))}
    </>
  )
}

import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as CopyDuplicateIcon } from '@lastpass/assets/svg/admin-console/copy-duplicate-icon.svg'
import {
  BodyBold,
  Checkbox,
  DetailsDialog,
  PrimaryButton,
  TextInput
} from '@lastpass/lastkit'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationSaveK1Dialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { CopyToClipboardNotification } from '@lastpass/admin-console-dependencies/ui/global/dialogs/DefaultNotifications'
interface KeyRotationSaveK1DialogProps {
  state: KeyRotationSaveK1Dialog
  actions: typeof globalActions
}

const TextBoxContainer = styled.div`
  ${BodyRegularStyle};
  margin-bottom: 20px;
`
const CheckBoxContainer = styled.div`
  margin-top: 20px;
  display: flex;
`

const CheckBoxText = styled.div`
  margin-left: 8px;
  ${BodyRegularStyle};
`

const StyledKeyRotationCopyContainer = styled.div`
  margin-top: 20px;
`

export const KeyRotationSaveK1DialogComponent: FunctionComponent<KeyRotationSaveK1DialogProps> = ({
  state,
  actions
}) => {
  const onCopy = () => {
    actions.setNotification(CopyToClipboardNotification)
  }
  const dispatch = useDispatch()
  const [isChecked, setIsChecked] = useState<boolean>(false)

  return (
    <DetailsDialog
      closeDialog={() => {
        dispatch(globalActions.discardDialog())
        dispatch(globalActions.emptyDialog())
      }}
      width="600px"
      disableMaxHeight={true}
      title={msg`Save new K1 component`}
      disableCloseOnBackground
      content={
        <>
          <TextBoxContainer>
            <Trans>
              This is your new K1 component. <BodyBold>Save it</BodyBold>{' '}
              somewhere safe since it’s not stored by LastPass.
            </Trans>
          </TextBoxContainer>
          <StyledKeyRotationCopyContainer>
            <TextInput
              name="newK1"
              data-qa="newK1"
              value={state.k1}
              actionOnCopy={onCopy}
              readOnly
              copyToClipboard
              copyIcon={<CopyDuplicateIcon />}
            />
          </StyledKeyRotationCopyContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
              data-qa="KeyRotationCheckbox"
            />
            <CheckBoxText>
              <Trans>
                I’ve saved the new K1 component. I’ll have it ready when I need
                it later in this process.
              </Trans>
            </CheckBoxText>
          </CheckBoxContainer>
        </>
      }
      buttons={[
        <PrimaryButton
          data-qa="PopupSubmit"
          onClick={() => {
            actions.confirmDialog()
          }}
          type="submit"
          key="confirm"
          disabled={!isChecked}
        >
          <Trans>Continue</Trans>
        </PrimaryButton>
      ]}
    />
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as QuestionIcon } from '@lastpass/assets/svg/admin-console/icon-question-mark.svg'
import { ReactComponent as Step1Icon } from '@lastpass/assets/svg/admin-console/icon-upgrade-step-1.svg'
import { ReactComponent as Step2Icon } from '@lastpass/assets/svg/admin-console/icon-upgrade-step-2.svg'
import { ReactComponent as Step3Icon } from '@lastpass/assets/svg/admin-console/icon-upgrade-step-3.svg'
import { ReactComponent as Step4Icon } from '@lastpass/assets/svg/admin-console/icon-upgrade-step-4.svg'
import { ReactComponent as WarningIcon } from '@lastpass/assets/svg/icon-warning-circle.svg'
import {
  BodyRegular,
  Heading300,
  IconButton,
  PrimaryButton
} from '@lastpass/lastkit'
import { BodySemiboldStyle } from '@lastpass/lastkit/styles'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { ManagedMigrationCompanyInfo } from '@lastpass/admin-console-dependencies/state/applications/application'
import {
  applicationListActions,
  ApplicationListQueryParams
} from '@lastpass/admin-console-dependencies/state/applications/saml/list/actions'
import { ApplicationListState } from '@lastpass/admin-console-dependencies/state/applications/saml/list/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import { SystemUpgradeProcessDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'
import {
  hasMigratableManagedCopmanies,
  hasNoPasswordSsoApps,
  isAppValid,
  isDirectMSPOrAggregatorMSP,
  isManagedMSP,
  isMspWitMigratableCompaniesButWithoutMigration,
  npManagedCompaniesLength
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/system-upgrade-helper'
import { SystemUpgradeStep } from '@lastpass/admin-console-dependencies/ui/upgrade/system/SystemUpgradeStep'

import {
  AccessPolicies,
  AuthenticationAndRecovery,
  CustomAttributes,
  FeatureDetail,
  Geofences,
  IdentityRoles,
  IPAllowDeny,
  KeysExistingOauthSAML,
  RequirePasswordlessVerificationViaLastPassAuthenticator
} from './FeatureDetail'
import { SystemUpgradeReviewRetiredFeaturesStep } from './SystemUpgradeReviewRetiredFeaturesStep'

const Container = styled.div`
  max-width: fit-content;
  margin: 0 auto;
  margin-bottom: 50px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: ${props => props.theme.colors.blue50};
  padding: 16px 24px;
  box-shadow: 0px 1px 0px 0px ${props => props.theme.colors.neutral200} inset;
`

const Header = styled.div`
  display: flex;
`

const Spacing = styled.div`
  height: 32px;
`

const ButtonContainer = styled.div`
  margin-left: auto;
`

const TextContainer = styled.div`
  max-width: 700px;
  margin-top: 12px;
  margin-left: 24px;
`

const TileSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0px;
  gap: 16px;

  max-width: 585px;
  height: 140 px;

  margin-bottom: 20px;
  margin-top: 20px;
`

const MspTileSection = styled(TileSection)`
  min-height: 60px;
`

const Tile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;

  width: auto;
  max-height: 60px;

  border: 1px solid #bac0ca;
  border-radius: 4px;
`

const StyledIcon = styled.img`
  float: left;
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 10px;
`

const SizedWarningIcon = styled(WarningIcon)`
  width: 16px;
  height: 16px;
`

const AlignedWarningIcon = styled(SizedWarningIcon)`
  vertical-align: -2px;
  padding-left: 4px;
  padding-right: 4px;
`

const TileStyledWarningIcon = styled(SizedWarningIcon)`
  float: left;
  object-fit: contain;
  margin-right: 10px;
`

const SsoWarningIcon = styled(WarningIcon)`
  width: 35px;
  height: 35px;
`

const OAuthWarningIcon = styled(WarningIcon)`
  width: 55px;
  height: 55px;
`

const StyledBodyRegular = styled(BodyRegular)`
  margin-right: 10px;
`

const BlueBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  max-width: 585px;
  height: 50px;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: 1px solid;
  border-color: ${props => props.theme.colors.neutral300};
`
const RightAlignedDownloadLink = styled.a`
  margin-left: auto;
  margin-right: 0;
`

const YellowWarningBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  max-width: 585px;
  border-radius: 4px;
  background: ${props => props.theme.colors.yellow100};
  border: 1px solid;
  border-color: ${props => props.theme.colors.yellow800};
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
`
const WarningText = styled.div`
  margin-left: 15px;
`

const MspNote = styled.p`
  margin-top: 16px;
`

const MspStyledButton = styled.a`
  ${BodySemiboldStyle}
  color: ${props => props.theme.colors.blue700};
`

const LeftAlignedMspButton = styled(MspStyledButton)`
  margin-right: 30px;
`

const Bold = styled.span`
  font-weight: bold;
`

const isRepairSsoSectionAcknowledged = (
  applications: ApplicationListState,
  state: SystemUpgradeState
): boolean =>
  hasNoPasswordSsoApps(applications) ? state.steps.isRepairSsoAppsAck : true

const isManagedCompanySectionAcknowledged = (
  companyDetails: CompanyDetails,
  state: SystemUpgradeState
): boolean =>
  CompanyDetailsHelper.isMsp(companyDetails) &&
  npManagedCompaniesLength(state.migratableManagedCompanies)
    ? state.steps.isReviewManagedCompaniesAck
    : true

const listSsoAppTiles = (applications: ApplicationListState): JSX.Element => {
  return (
    <TileSection data-qa={'RepairSsoAppsContainer'}>
      {applications.table.results
        .filter(app => isAppValid(app))
        .map(app => (
          <Tile key={app.id} data-qa={'RepairSsoAppTile'}>
            <StyledIcon src={app.logo.imageUrl} />
            <StyledBodyRegular>{app.name}</StyledBodyRegular>
          </Tile>
        ))}
    </TileSection>
  )
}

const listManagedCompanyTiles = (
  managedCompanyNames: ManagedMigrationCompanyInfo[]
): JSX.Element => {
  return (
    <MspTileSection data-qa={'RepairManagedCompaniesContainer'}>
      {managedCompanyNames.map(company => (
        <Tile key={company.Id} data-qa={'RepairManagedCompanyTile'}>
          <TileStyledWarningIcon />
          <StyledBodyRegular>{company.Name}</StyledBodyRegular>
        </Tile>
      ))}
    </MspTileSection>
  )
}

const renderReviewManagedCompaniesButton = (
  isMspOrAggregateMsp: boolean,
  isMspWithOnlyManagedCompaniesToMigrate: boolean,
  onClickAction: Function
): JSX.Element => {
  if (!isMspOrAggregateMsp) return <></>

  return isMspWithOnlyManagedCompaniesToMigrate ? (
    <MspStyledButton href="#" onClick={() => onClickAction()}>
      <Trans>Review managed companies</Trans>
    </MspStyledButton>
  ) : (
    <LeftAlignedMspButton href="#" onClick={() => onClickAction()}>
      <Trans>Review managed companies first</Trans>
    </LeftAlignedMspButton>
  )
}

const identityProvider = defaultConfig.identityIframeUrl
const ssoMetadataUrl = `${identityProvider}/api/v2/Apps/meta/sso`

export const SystemUpgrade: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const state = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  const companyInfo = useSelector<AppState, CompanyInfoState>(
    (state: AppState) => state.companyInfo
  )

  const isMsp =
    isDirectMSPOrAggregatorMSP(companyInfo) || isManagedMSP(companyInfo)

  useEffect(() => {
    dispatch(systemUpgradeActions.fetchInitialData())
  }, [dispatch])

  useEffect(() => {
    if (isMsp) {
      dispatch(systemUpgradeActions.fetchMigratableManagedCompanies())
    }
  }, [dispatch, isMsp])

  const mspManagedCompanies = state.migratableManagedCompanies

  const usedFeatures: FeatureDetail[] = []
  const unusedFeatures: FeatureDetail[] = []

  if (
    state.requirePasswordlessViaLastPassPolicy &&
    state.requirePasswordlessViaLastPassPolicy.checked
  ) {
    usedFeatures.push(RequirePasswordlessVerificationViaLastPassAuthenticator)
  } else {
    unusedFeatures.push(RequirePasswordlessVerificationViaLastPassAuthenticator)
  }

  if (
    state.usernameAttributes &&
    state.usernameAttributes.some(attribute => attribute.serverAttribute)
  ) {
    usedFeatures.push(CustomAttributes)
  } else {
    unusedFeatures.push(CustomAttributes)
  }

  const hasSamlKeys: boolean =
    state.samlKeys &&
    state.samlKeys.length > 0 &&
    state.samlKeys.some(key => key.name !== 'Default')

  const hasOauthKeys: boolean =
    state.clientOauth &&
    state.clientOauth.enabled &&
    state.clientOauth.keys &&
    state.clientOauth.keys.length > 0

  if (hasSamlKeys || hasOauthKeys) {
    usedFeatures.push(KeysExistingOauthSAML)
  } else {
    unusedFeatures.push(KeysExistingOauthSAML)
  }

  if (state.roles && state.roles.length > 0) {
    usedFeatures.push(IdentityRoles)
  } else {
    unusedFeatures.push(IdentityRoles)
  }

  const usedPolicies: FeatureDetail[] = []
  const unusedPolicies: FeatureDetail[] = []

  if (state.accessPolicies && state.accessPolicies.total > 0) {
    usedPolicies.push(AccessPolicies)
  } else {
    unusedPolicies.push(AccessPolicies)
  }

  if (state.geofencePolicies && state.geofencePolicies.total > 0) {
    usedPolicies.push(Geofences)
  } else {
    unusedPolicies.push(Geofences)
  }

  if (state.ipPolicies && state.ipPolicies.total > 0) {
    usedPolicies.push(IPAllowDeny)
  } else {
    unusedPolicies.push(IPAllowDeny)
  }

  if (state.authenticationPolicies && state.authenticationPolicies.total > 0) {
    usedPolicies.push(AuthenticationAndRecovery)
  } else {
    unusedPolicies.push(AuthenticationAndRecovery)
  }
  //Calling the get-application-list saga here to get the sso apps state
  const queryParams: ApplicationListQueryParams = useQueryParams(Namespace.apps)
  useEffect(() => {
    dispatch(
      applicationListActions.getApplicationList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  const ssoAppDetails = useSelector<AppState, ApplicationListState>(
    appState => appState.applicationList
  )

  const hasNopasswordSsoApp = hasNoPasswordSsoApps(ssoAppDetails)

  const mspWithoutMigrationButCompaniesWithMigration = isMspWitMigratableCompaniesButWithoutMigration(
    usedPolicies,
    usedFeatures,
    ssoAppDetails,
    companyInfo
  )

  return (
    <>
      <Container data-qa="SystemUpgradeContainer">
        <TextContainer>
          <Header>
            <Heading300 data-qa="SystemUpgradePageHeader">
              {isMsp ? (
                <div data-qa="SystemUpgradePageHeaderMSP">
                  <Trans>
                    System upgrade – See how you and your managed companies are
                    impacted
                  </Trans>
                </div>
              ) : (
                <div data-qa="SystemUpgradePageHeaderAdmin">
                  <Trans>System upgrade – See how you’re impacted</Trans>
                </div>
              )}
            </Heading300>
            <ButtonContainer>
              <LocationLink
                to="https://link.lastpass.com/system-upgrade-faqs"
                data-qa="MigrationSupportLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  icon={QuestionIcon}
                  data-qa="MigrationSupportIcon"
                />
              </LocationLink>
            </ButtonContainer>
          </Header>
        </TextContainer>
        <TextContainer>
          <BodyRegular>
            <Trans>
              We’ve responded to calls for a more consistent SSO and MFA
              experience. Along the way we’re also retiring legacy passwordless
              policies and changing the way some SSO app settings work.
            </Trans>
            <Spacing />
            <Trans>Once you upgrade, here’s what you’ll need to do.</Trans>
          </BodyRegular>
        </TextContainer>
        <Spacing />
        {hasNopasswordSsoApp && (
          <SystemUpgradeStep
            dataQa="SystemUpgradeRepairSSOStep"
            completed={state.steps.isRepairSsoAppsAck}
            icon={Step1Icon}
            title={<Trans>Repair these SSO apps</Trans>}
            description={
              <div>
                <Trans>
                  The upgrade will break existing SSO connections for these
                  apps; users will lose access until you make repairs. After
                  upgrade, go to Applications {'>'} SSO apps and look for apps
                  with a broken connection. In each broken app’s configuration
                  details, we’ll show you what to do.
                </Trans>
                {listSsoAppTiles(ssoAppDetails)}
                <BlueBanner data-qa={'SsoAppMetadataDownloadBanner'}>
                  <StyledBodyRegular>
                    <Trans>
                      Download your current SSO apps data stored in LastPass
                    </Trans>
                  </StyledBodyRegular>
                  <RightAlignedDownloadLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ssoMetadataUrl}
                    data-qa={'DownloadMetadataLink'}
                  >
                    <Trans>Download SSO data</Trans>
                  </RightAlignedDownloadLink>
                </BlueBanner>
                {hasNopasswordSsoApp && (
                  <YellowWarningBox data-qa="SsoWarningBox">
                    <SsoWarningIcon />
                    <WarningText>
                      <Trans>
                        <Bold>Don’t lose access to your SSO apps.</Bold> For
                        each SSO app, make sure someone in your organization has
                        access via username & password (not SSO alone).
                      </Trans>
                    </WarningText>
                  </YellowWarningBox>
                )}
              </div>
            }
            completeStep={() =>
              dispatch(systemUpgradeActions.completeStep('isRepairSsoAppsAck'))
            }
          />
        )}
        <SystemUpgradeStep
          dataQa="SystemUpgradeReviewRetiredFeaturesStep"
          completed={state.steps.isReviewRetiredFeaturesAck}
          icon={hasNopasswordSsoApp ? Step2Icon : Step1Icon}
          title={<Trans>Review these retired features</Trans>}
          description={
            <Trans>We&apos;ll remove 4 features from the Admin Console.</Trans>
          }
          completeStep={() =>
            dispatch(
              systemUpgradeActions.completeStep('isReviewRetiredFeaturesAck')
            )
          }
        >
          <SystemUpgradeReviewRetiredFeaturesStep
            usedFeatures={usedFeatures}
            unusedFeatures={unusedFeatures}
          />
          {hasOauthKeys && (
            <YellowWarningBox data-qa="OAuthWarningBox">
              <OAuthWarningIcon />
              <WarningText>
                <Trans>
                  <Bold>Don&apos;t lose access to your integrations.</Bold> Make
                  sure someone in your organization has admin-level access to
                  each integration without the use of federated login. That is,
                  someone must be able to restore each integration and set up
                  each app again.
                </Trans>
              </WarningText>
            </YellowWarningBox>
          )}
        </SystemUpgradeStep>
        <SystemUpgradeStep
          dataQa="SystemUpgradeReviewRetiredPasswordlessPoliciesStep"
          completed={state.steps.isReviewRetiredPassPoliciesAck}
          hideLine={!isMsp}
          icon={hasNopasswordSsoApp ? Step3Icon : Step2Icon}
          title={<Trans>Review these retired passwordless policies</Trans>}
          description={
            <Trans>
              We&apos;ll remove all 4 Advanced MFA passwordless authentication
              policies from the Admin Console.
            </Trans>
          }
          completeStep={() =>
            dispatch(
              systemUpgradeActions.completeStep(
                'isReviewRetiredPassPoliciesAck'
              )
            )
          }
        >
          <SystemUpgradeReviewRetiredFeaturesStep
            usedFeatures={usedPolicies}
            unusedFeatures={unusedPolicies}
          />
        </SystemUpgradeStep>
        {isMsp && hasMigratableManagedCopmanies(mspManagedCompanies) && (
          <SystemUpgradeStep
            dataQa="SystemUpgradeReviewManagedCompaniesStep"
            completed={state.steps.isReviewManagedCompaniesAck}
            hideLine={true}
            icon={hasNopasswordSsoApp ? Step4Icon : Step3Icon}
            title={<Trans>Review these managed companies</Trans>}
            description={
              <div>
                <Trans>
                  You have <Bold>{mspManagedCompanies.length}</Bold> managed
                  companies using impacted features or policies or with SSO apps
                  that need to be repaired after the upgrade. Look for this icon
                  {<AlignedWarningIcon />}and review each impacted company on
                  the Managed companies page. Perform each upgrade individually
                  at your convenience.
                </Trans>
                <MspNote>
                  <Trans>
                    <Bold>Note:</Bold> We’ve already updated managed companies
                    that weren’t using impacted features, policies, or SSO.
                  </Trans>
                </MspNote>
                {listManagedCompanyTiles(mspManagedCompanies)}
              </div>
            }
            completeStep={() =>
              dispatch(
                systemUpgradeActions.completeStep('isReviewManagedCompaniesAck')
              )
            }
          />
        )}
        {state.isUpgradeStarted &&
          dispatch(
            globalActions.setDialog({
              type: 'system-upgrade-process-dialog',
              dialogType: SystemUpgradeProcessDialogType.loading
            })
          )}
        {state.isUpgradeSucceed &&
          dispatch(
            globalActions.setDialog({
              type: 'system-upgrade-process-dialog',
              dialogType: SystemUpgradeProcessDialogType.completed
            })
          )}
        {state.upgradeError &&
          dispatch(
            globalActions.setDialog({
              type: 'system-upgrade-process-dialog',
              dialogType: SystemUpgradeProcessDialogType.error
            })
          )}
      </Container>
      <Footer data-qa="SystemUpgradeFooter">
        {renderReviewManagedCompaniesButton(
          isMsp,
          mspWithoutMigrationButCompaniesWithMigration,
          () => {
            history.push('/home/managed-companies')
          }
        )}
        {!mspWithoutMigrationButCompaniesWithMigration && (
          <PrimaryButton
            disabled={
              !(
                state.steps.isReviewRetiredPassPoliciesAck &&
                state.steps.isReviewRetiredFeaturesAck &&
                isRepairSsoSectionAcknowledged(ssoAppDetails, state) &&
                isManagedCompanySectionAcknowledged(companyInfo.details, state)
              )
            }
            data-qa="SystemUpgradeButton"
            key="systemUpgradeReviewed"
            onClick={() => {
              dispatch(
                globalActions.setDialog({
                  type: 'system-upgrade-process-dialog',
                  dialogType: SystemUpgradeProcessDialogType.start
                })
              )
            }}
          >
            <Trans>I reviewed everything – Let&apos;s upgrade now</Trans>
          </PrimaryButton>
        )}
      </Footer>
    </>
  )
}

import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { LocationRedirect } from '@lastpass/routing'
import { RouteFade } from '@lastpass/routing/animations'

import { AssignGeneralPolicyUsers } from '@lastpass/admin-console-dependencies/ui/policies/general/AssignGeneralPolicyUsers'

export function GeneralPoliciesAssignUsersDrawerPage() {
  const match = useRouteMatch()
  if (match) {
    const { url, path } = match
    return (
      <>
        <Route path={path}>
          <RouteFade>
            <AssignGeneralPolicyUsers
              shouldHideUsersOptions={path.includes('edit')}
            />
          </RouteFade>
        </Route>
        <Route>
          <LocationRedirect to={url} />
        </Route>
      </>
    )
  }
  return null
}

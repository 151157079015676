import buildQuery from 'odata-query'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'

export const usersWithWeakMasterPasswordScoreFilter = {
  filter: {
    and: [
      { masterPasswordScore: { lt: 75 } },
      {
        accountStatus: AccountStatus.active
      }
    ]
  }
}

export function createGetUsersWithWeakMasterPasswordScore(
  dashboardService: UACServices.Services
) {
  return function* getUsersWithWeakMasterPasswordScore(
    action: ReturnType<typeof dashboardDrawerActions.getUnresponsiveUsers>
  ) {
    try {
      const resultsPerPage = action.payload.resultsPerPage
      const query = buildQuery({
        ...action.payload.params.query,
        ...usersWithWeakMasterPasswordScoreFilter,
        top: resultsPerPage
      })

      yield put(dashboardDrawerActions.setWeakMasterPasswordUsersLoading(true))
      try {
        const result: UACServices.UsersAPI.Responses = yield call(
          dashboardService.users,
          query
        )
        if (result.type === UACServices.UsersAPI.SUCCESS) {
          const userList = result.body.userList
          const totalResults = result.body.totalResults
          yield put(
            dashboardDrawerActions.setTable(
              userList,
              totalResults,
              resultsPerPage,
              'usersWithWeakMasterPassword'
            )
          )
        }
      } finally {
        yield put(
          dashboardDrawerActions.setWeakMasterPasswordUsersLoading(false)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

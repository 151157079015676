import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import * as _ from 'lodash'
import moment from 'moment'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegularStyle,
  DetailCell,
  DetailsContainer,
  Drawer
} from '@lastpass/lastkit'

import { advancedUserActivityActions } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/actions'
import { AdvancedUserActivityListState } from '@lastpass/admin-console-dependencies/state/reports/advanced-view/state'
import { TeamsApiAdvancedUserEventType } from '@lastpass/admin-console-dependencies/types/advanced-user-activity'

export interface AdvancedUserActivityDetailDrawerProps {
  closeLink: string
}

export const AdvancedUserActivityDetailDrawer: React.FunctionComponent<AdvancedUserActivityDetailDrawerProps> = ({
  closeLink
}) => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const state = useSelector<AppState, AdvancedUserActivityListState>(
    state => state.advancedUserActivityEvents
  )

  const userActivity = _.find(state.currentPageUserActivityOriginalEvents, {
    id
  }) as TeamsApiAdvancedUserEventType

  useEffect(() => {
    if (userActivity !== undefined) {
      dispatch(advancedUserActivityActions.getUserForEvent(userActivity.user))
    }
  }, [dispatch, userActivity])

  if (!userActivity) {
    return <Redirect to={closeLink + location.search} />
  }

  return (
    <Drawer
      closeLink={closeLink}
      mainTitle={<>{i18n._(userActivity.eventType)}</>}
    >
      {userActivity.description && (
        <div
          data-qa="AdvancedUserActivityDescription"
          css={css`
            ${BodyRegularStyle};
            margin-bottom: 16px;
          `}
        >
          {userActivity.description}
        </div>
      )}

      <div
        css={css`
          max-width: inherit;
          padding-bottom: 5px;
        `}
      >
        <DetailCell
          field={<Trans>Event information</Trans>}
          value={userActivity.eventInformation}
        />
      </div>

      <DetailsContainer>
        <DetailCell
          field={<Trans>User</Trans>}
          value={
            state.selectedUser &&
            `${state.selectedUser.firstName} ${state.selectedUser.lastName}`
          }
        />
        <DetailCell
          field={<Trans>Time</Trans>}
          value={moment(userActivity.timeUtc).format(
            'MMMM D, YYYY [at] h:mm:ss A'
          )}
        />
        <DetailCell
          field={<Trans>Email</Trans>}
          value={state.selectedUser && state.selectedUser.email}
        />
        <DetailCell field={<Trans>IP Address</Trans>} value={userActivity.ip} />
        <DetailCell
          field={<Trans>Last login</Trans>}
          value={
            state.selectedUser &&
            moment(state.selectedUser.lastLoginTimeUtc).format(
              'MMMM D, YYYY [at] h:mm:ss A'
            )
          }
        />
      </DetailsContainer>
    </Drawer>
  )
}

import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { FormikErrors } from 'formik'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { addUsersDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/add/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

import { ExtendableFormRow } from './ExtendableFormRow'
import { ExtendableFormTableHead } from './ExtendableFormTableHead'
import {
  Button,
  ExtendableFormUploadButton
} from './ExtendableFormUploadButton'

const LeftStyledButton = styled(Button)`
  margin-left: 25px;
  &:focus {
    outline: auto;
  }
`

const TableFooter = styled.span`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
`

const StyledRowContainer = styled.div`
  width: 100%;
`

export interface ExtendableFormFieldArrayProps<T> {
  records: T[]
  columns: {
    name: string
    width?: string
    required?: boolean
  }[]
  checkedRecords: T[]
  searchTerm: string
  checkable: {
    addSelectedRecords: (records: T[]) => void
    removeSelectedRecords: (records: T[]) => void
    setSelectedRecords: (records: T[]) => void
  }
  removeRecord: (record: T | T[]) => void
  addRecord: (record: T) => void
  setRecordField: (row: number, column: string, value: string) => void
  duplicateRecords: T[]
  setShouldValidate: React.Dispatch<React.SetStateAction<boolean>>
  initialLoad?: boolean
  showOnlyErrors?: boolean
  errors: FormikErrors<{
    records: T[]
  }>
  remainingLicenses: number | undefined
  setFilterToError: Function
  searchInputFocused: boolean
  setSearchInputFocused: Function
}

const shouldHideRecord = <T extends {}>(
  record: T,
  searchTerm: string,
  showOnlyErrors: boolean,
  index: number,
  errors: FormikErrors<{
    records: T[]
  }>,
  isDuplicate: boolean
) => {
  const searchShouldHide =
    searchTerm != ''
      ? Object.keys(record).filter(key => {
          // eslint-disable-next-line no-prototype-builtins
          return record.hasOwnProperty(key) && record[key]
            ? record[key]
                .toLocaleLowerCase()
                .includes(searchTerm.toLocaleLowerCase())
            : false
        }).length == 0
      : false

  const hasError =
    isDuplicate || (errors && errors.records && errors.records[index] != null)

  return searchShouldHide || (showOnlyErrors && !hasError)
}

export const ExtendableFormFieldArray = <T extends {}>(
  extendableFormFieldArrayProps: ExtendableFormFieldArrayProps<T>
) => {
  const dispatch = useDispatch()
  const errorRecords: T[] = []
  extendableFormFieldArrayProps.records.forEach((record, index) => {
    if (
      extendableFormFieldArrayProps.errors.records &&
      extendableFormFieldArrayProps.errors.records[index]
    ) {
      errorRecords.push(record)
    }
  })

  return (
    <>
      <ExtendableFormTableHead
        checked={
          extendableFormFieldArrayProps.checkedRecords.length ==
            extendableFormFieldArrayProps.records.length &&
          extendableFormFieldArrayProps.checkedRecords.length != 0
        }
        disabled={extendableFormFieldArrayProps.records.length === 1}
        onChange={event =>
          event.currentTarget.checked
            ? extendableFormFieldArrayProps.checkable.setSelectedRecords(
                extendableFormFieldArrayProps.showOnlyErrors
                  ? errorRecords
                  : extendableFormFieldArrayProps.records
              )
            : extendableFormFieldArrayProps.checkable.setSelectedRecords([])
        }
        indeterminate={
          extendableFormFieldArrayProps.checkedRecords.length > 0 &&
          extendableFormFieldArrayProps.checkedRecords.length !=
            extendableFormFieldArrayProps.records.length
        }
        columns={extendableFormFieldArrayProps.columns}
        totalCheckedRecords={
          extendableFormFieldArrayProps.checkedRecords.length
        }
        clearCheckedRecords={() => {
          extendableFormFieldArrayProps.removeRecord(
            extendableFormFieldArrayProps.checkedRecords
          )

          extendableFormFieldArrayProps.checkable.removeSelectedRecords(
            extendableFormFieldArrayProps.checkedRecords
          )
        }}
        setFilterToError={extendableFormFieldArrayProps.setFilterToError}
      />
      <StyledRowContainer>
        {extendableFormFieldArrayProps.records.map((record, index) => {
          const isDuplicate =
            extendableFormFieldArrayProps.duplicateRecords.indexOf(record) != -1
          const hide = shouldHideRecord(
            record,
            extendableFormFieldArrayProps.searchTerm.toLocaleLowerCase(),
            extendableFormFieldArrayProps.showOnlyErrors
              ? extendableFormFieldArrayProps.showOnlyErrors
              : false,
            index,
            extendableFormFieldArrayProps.errors,
            isDuplicate
          )
          return (
            !hide && (
              <ExtendableFormRow
                key={`records[${index}].row`}
                isDuplicate={isDuplicate}
                checkboxDisabled={
                  extendableFormFieldArrayProps.records.length === 1
                }
                focus={extendableFormFieldArrayProps.initialLoad}
                searchInputFocused={
                  extendableFormFieldArrayProps.searchInputFocused
                }
                setSearchInputFocused={
                  extendableFormFieldArrayProps.setSearchInputFocused
                }
                record={record}
                recordIndex={index}
                setRecordField={extendableFormFieldArrayProps.setRecordField}
                lastRecord={
                  index == extendableFormFieldArrayProps.records.length - 1
                }
                addRecord={() => {
                  /* eslint-disable-next-line */
                  extendableFormFieldArrayProps.addRecord({} as T)
                }}
                columns={extendableFormFieldArrayProps.columns}
                error={
                  extendableFormFieldArrayProps.errors &&
                  extendableFormFieldArrayProps.errors.records &&
                  extendableFormFieldArrayProps.errors.records[index]
                }
                remove={extendableFormFieldArrayProps.removeRecord}
                checkable={extendableFormFieldArrayProps.checkable}
                checkedRecords={extendableFormFieldArrayProps.checkedRecords}
              />
            )
          )
        })}
        <TableFooter>
          <LeftStyledButton
            type="button"
            onClick={() => {
              if (extendableFormFieldArrayProps.records.length < 100) {
                /* eslint-disable-next-line */
                extendableFormFieldArrayProps.addRecord({} as T)
              } else {
                dispatch(
                  globalActions.setNotification({
                    message: msg`You can only add a maximum of 100 users at a time`,
                    type: NotificationType.alert,
                    autoDismiss: true
                  })
                )
              }
            }}
            data-qa={'AddAnotherUserButton'}
          >
            <Trans>Add another user</Trans>
          </LeftStyledButton>
          <ExtendableFormUploadButton
            onUpload={(
              selectorFiles: FileList,
              currentFormPendingUsers: T[]
            ) => {
              dispatch(
                addUsersDrawerActions.processPendingUsersUpload(
                  selectorFiles,
                  currentFormPendingUsers
                )
              )
              extendableFormFieldArrayProps.setShouldValidate(true)
            }}
            currentFormRecords={extendableFormFieldArrayProps.records.filter(
              /* eslint-disable-next-line */
              (record: any) =>
                Object.keys(record).length !== 0 && record.email !== undefined
            )}
          />
        </TableFooter>
      </StyledRowContainer>
    </>
  )
}

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as RocketImage } from '@lastpass/assets/svg/admin-console/rocket.svg'
import {
  BodyBold,
  BodyRegular,
  DetailsDialog,
  Heading100,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

interface SystemUpgradeImpactDialogProps {
  discardPopUpAndSaveStateToStorage: () => void
}

const FrontwardsContainer = styled.div`
  z-index: 1000;
`

const CenteredRocketImage = styled(RocketImage)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`

const ContentContainer = styled.div`
  display: block;
  align-items: center;
`

const StyledTextButton = styled(TextButton)`
  padding-top: 6px;
  margin-right: 15px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 20px;
`

const StyledParagraph = styled(BodyRegular)`
  display: block;
  padding-bottom: 20px;
`

export const SystemUpgradeImpactDialogComponent: FC<SystemUpgradeImpactDialogProps> = ({
  discardPopUpAndSaveStateToStorage
}) => {
  const Content = () => (
    <ContentContainer data-qa="SystemUpgradeImpactIntroduction">
      <CenteredRocketImage data-qa="SystemUpgradeImpactImage" />
      <StyledHeading100 data-qa="SystemUpgradeImpactTitle">
        <Trans>System upgrade – See how you&apos;re impacted</Trans>
      </StyledHeading100>
      <StyledParagraph data-qa="SystemUpgradeImpactContent">
        <Trans>
          We&apos;re improving the way we handle SSO and MFA and removing legacy
          Advanced MFA passwordless policies. To make the upgrade a success,
          there may be some effort on your side, too. We&apos;ll show you{' '}
          <BodyBold>how you&apos;re impacted </BodyBold>
          and <BodyBold>what you&apos;ll need to do.</BodyBold>
        </Trans>
      </StyledParagraph>
      <StyledParagraph data-qa="SystemUpgradeImpactFooter">
        <Trans>
          Spoiler: It depends on how you&apos;re using the features we&apos;re
          upgrading.
        </Trans>
      </StyledParagraph>
    </ContentContainer>
  )

  const LaterButton = () => (
    <StyledTextButton
      onClick={() => {
        discardPopUpAndSaveStateToStorage()
      }}
      key="later"
      data-qa="SystemUpgradeImpactLaterButton"
    >
      <Trans>Later</Trans>
    </StyledTextButton>
  )

  const ShowMeButton = () => (
    <a
      href="/systemupgrade"
      rel="noopener noreferrer"
      key="showme"
      data-qa="SystemUpgradeImpactShowMeButton"
      onClick={() => {
        discardPopUpAndSaveStateToStorage()
      }}
    >
      <PrimaryButton>
        <Trans>Show me</Trans>
      </PrimaryButton>
    </a>
  )

  return (
    <FrontwardsContainer>
      {
        <DetailsDialog
          closeDialog={() => {
            discardPopUpAndSaveStateToStorage()
          }}
          width="500px"
          disableMaxHeight
          content={<Content />}
          buttons={[LaterButton(), ShowMeButton()]}
        />
      }
    </FrontwardsContainer>
  )
}

import React, { FunctionComponent, useRef, useState } from 'react'

import { DuoSecuritySettingsCard } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/multifactor-options/DuoSecuritySettingsCard'
import { EnabledMultifactorOptionsCard } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/multifactor-options/EnabledMultifactorOptionsCard'
import { FormDiscardDialog } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/multifactor-options/FormDiscardDialog'
import { RSASecureIDRadiusCard } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/multifactor-options/RsaSecureIdRadiusCard'
import { SecureAuthSettingsCard } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/multifactor-options/SecureAuthSettingsCard'
import { SymantecVipSettingsCard } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/multifactor-options/SymantecVipSettingsCard'
import { MultifactorOptionsContainer } from '@lastpass/admin-console-dependencies/ui/advanced/enterprise-options/MultifactorOptionsContainer'

enum multifactorCards {
  enabledMFCard = 'enabledMFCard',
  duoCard = 'duoCard ',
  rsaCard = 'rsaCard',
  symantecCard = 'symantecCard',
  secureCard = 'secureCard'
}
export const MultifactorOptions: FunctionComponent = () => {
  const [companyID, setcompanyID] = useState('')
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [openCard, setOpenCard] = useState(multifactorCards.enabledMFCard)
  const [openCardStatus, setOpenCardStatus] = useState(false)

  const [expand, setExpand] = useState({
    [multifactorCards.enabledMFCard]: true,
    [multifactorCards.duoCard]: false,
    [multifactorCards.rsaCard]: false,
    [multifactorCards.symantecCard]: false,
    [multifactorCards.secureCard]: false
  })
  const formActiveStatus = {
    [multifactorCards.enabledMFCard]: false,
    [multifactorCards.duoCard]: false,
    [multifactorCards.rsaCard]: false,
    [multifactorCards.symantecCard]: false,
    [multifactorCards.secureCard]: false
  }
  const formActiveStatusRef = useRef(formActiveStatus)
  const checkAnyCardFormActive = () => {
    if (formActiveStatusRef.current) {
      const currentFormActiveStatus = formActiveStatusRef.current
      for (const cardForm in currentFormActiveStatus) {
        if (currentFormActiveStatus[cardForm]) {
          return true
        }
      }
    }
    return false
  }

  const setMultifactorCardsExpansionStatus = () => {
    setExpand({
      [multifactorCards.enabledMFCard]: false,
      [multifactorCards.duoCard]: false,
      [multifactorCards.rsaCard]: false,
      [multifactorCards.symantecCard]: false,
      [multifactorCards.secureCard]: false,
      [openCard]: openCardStatus
    })
  }

  const resetFormActiveStatus = () => {
    if (formActiveStatusRef.current) {
      formActiveStatusRef.current = { ...formActiveStatus }
    }
  }

  const closeOtherCards = (initiatingCard: multifactorCards) => (
    expandStatus: boolean
  ) => {
    const isAnyFormActive = checkAnyCardFormActive()
    if (!isAnyFormActive) {
      setExpand({
        [multifactorCards.enabledMFCard]: false,
        [multifactorCards.duoCard]: false,
        [multifactorCards.rsaCard]: false,
        [multifactorCards.symantecCard]: false,
        [multifactorCards.secureCard]: false,
        [initiatingCard]: expandStatus
      })
    }
    setOpenCard(initiatingCard)
    setOpenCardStatus(expandStatus)
    setShowConfirmDialog(isAnyFormActive)
  }
  const onFormActivity = (formActiveStatusProp: multifactorCards) => (
    isFormActiveStatus: boolean
  ) => {
    formActiveStatusRef.current[formActiveStatusProp] = isFormActiveStatus
  }

  return (
    <MultifactorOptionsContainer
      setCompanyID={setcompanyID}
      cards={
        <>
          {showConfirmDialog && (
            <FormDiscardDialog
              onConfirm={() => {
                setMultifactorCardsExpansionStatus()
                resetFormActiveStatus()
                setShowConfirmDialog(false)
              }}
              onCancel={() => {
                setShowConfirmDialog(false)
              }}
            />
          )}
          <EnabledMultifactorOptionsCard
            expand={expand[multifactorCards.enabledMFCard]}
            closeOtherCards={closeOtherCards(multifactorCards.enabledMFCard)}
            onFormActivity={onFormActivity(multifactorCards.enabledMFCard)}
          />
          <DuoSecuritySettingsCard
            cid={companyID}
            expand={expand[multifactorCards.duoCard]}
            closeOtherCards={closeOtherCards(multifactorCards.duoCard)}
            onFormActivity={onFormActivity(multifactorCards.duoCard)}
          />
          <RSASecureIDRadiusCard
            expand={expand[multifactorCards.rsaCard]}
            closeOtherCards={closeOtherCards(multifactorCards.rsaCard)}
            onFormActivity={onFormActivity(multifactorCards.rsaCard)}
          />
          <SymantecVipSettingsCard
            expand={expand[multifactorCards.symantecCard]}
            closeOtherCards={closeOtherCards(multifactorCards.symantecCard)}
            onFormActivity={onFormActivity(multifactorCards.symantecCard)}
          />
          <SecureAuthSettingsCard
            expand={expand[multifactorCards.secureCard]}
            closeOtherCards={closeOtherCards(multifactorCards.secureCard)}
            onFormActivity={onFormActivity(multifactorCards.secureCard)}
          />
        </>
      }
    />
  )
}

import React, { FC, useContext } from 'react'

import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import {
  GeneratedSecurityReportDrawer,
  GeneratedSecurityReportDrawerProps
} from '@lastpass/admin-console-dependencies/ui/reports/security/GeneratedSecurityReportDrawer'

type UrlsInSharedFoldersGeneratedSecurityReportDrawerProps = Pick<
  GeneratedSecurityReportDrawerProps,
  'closeLink' | 'event'
>

export const downloadReportName = 'urls-in-shared-folders'

export const UrlsInSharedFoldersGeneratedSecurityReportDrawer: FC<UrlsInSharedFoldersGeneratedSecurityReportDrawerProps> = ({
  closeLink,
  event
}) => {
  const {
    urlsInSharedFoldersReportGetReports,
    urlsInSharedFoldersReportGenerateReport
  } = useContext(ServerContext)

  return (
    <GeneratedSecurityReportDrawer
      closeLink={closeLink}
      event={event}
      downloadReportName={downloadReportName}
      getReports={urlsInSharedFoldersReportGetReports}
      generateReport={urlsInSharedFoldersReportGenerateReport}
    />
  )
}

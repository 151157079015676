import React, { useState } from 'react'

import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Loading } from '@lastpass/components'

import { AzureSentinelIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledFormRow,
  StyledTextInput
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { useSiemIntegrationsSetConfig } from '../api-hooks/use-siem-integrations-set-config'
import { SiemIntegrationsEnabledCheckbox } from '../shared/SiemIntegrationsEnabledCheckbox'
import { SiemIntegrationsSubmitButton } from '../shared/SiemIntegrationsSubmitButton'

interface AzureSentinelFormProps {
  config: AzureSentinelIntegrationConfig
}

export const AzureSentinelForm: React.FunctionComponent<AzureSentinelFormProps> = ({
  config
}) => {
  const { isLoading, setAzureSentinelConfig } = useSiemIntegrationsSetConfig()

  const [
    currentConfig,
    setCurrentConfig
  ] = useState<AzureSentinelIntegrationConfig | null>(null)

  const yupSchema = Yup.object().shape({
    workspaceId: Yup.string()
      .trim()
      .matches(
        /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i,
        i18n._(msg`Enter a valid GUID using the "D" format specifier`)
      )
      .required(' '),
    key: Yup.string()
      .trim()
      .required()
  })

  return (
    <>
      {isLoading && (
        <Loading
          data-qa="AzureSentinelLoadingIndicator"
          color="blue900"
          active={true}
        />
      )}
      <Formik
        initialValues={currentConfig ?? config}
        enableReinitialize
        validationSchema={yupSchema}
        onSubmit={values => {
          setAzureSentinelConfig({
            config: values,
            successMessage: msg`Nice! You've set up the Azure Sentinel integration.`
          })
          setCurrentConfig(values)
        }}
      >
        {formik => (
          <Form data-qa="AzureSentinelForm">
            <StyledFormRow>
              <StyledTextInput
                data-qa="AzureSentinelWorkspaceIdInput"
                name="workspaceId"
                placeholder={msg`1a2b3c4c-1a2b-1a2b-1a2b-1a2b3c4c5d6e`}
                value={formik.values.workspaceId}
                onChange={formik.handleChange}
                error={!!formik.errors.workspaceId}
                errorText={<>{formik.errors.workspaceId}</>}
              >
                <Trans>Workspace ID</Trans>
              </StyledTextInput>

              <StyledTextInput
                data-qa="AzureSentinelKeyInput"
                name="key"
                placeholder={msg`Your primary key from Azure Sentinel`}
                value={formik.values.key}
                error={!!formik.errors.key}
                onChange={formik.handleChange}
              >
                <Trans>Key</Trans>
              </StyledTextInput>
            </StyledFormRow>

            <SiemIntegrationsEnabledCheckbox
              dataQa="AzureSentinelEnabledCheckbox"
              formik={formik}
            />

            <SiemIntegrationsSubmitButton
              dataQa="AzureSentinelSaveChangesButton"
              formik={formik}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

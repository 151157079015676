import { useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { policyHelper } from '@lastpass/admin-console-dependencies/services/policy-helper'
import { LegacyVpnListState } from '@lastpass/admin-console-dependencies/state/applications/legacyvpn/list/state'
import { WorkstationListState } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/state'
import { PasswordlessContainerState } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/state'

export const usePasswordlessPoliciesEmptyState = () => {
  const passwordlessContainerState: PasswordlessContainerState = useSelector(
    (state: AppState) => state.passwordlessContainer
  )
  const workstationListState = useSelector<AppState, WorkstationListState>(
    appState => appState.workstationList
  )

  const legacyVpnListState = useSelector<AppState, LegacyVpnListState>(
    appState => appState.legacyVpnList
  )

  const isPasswordlessPolicyEnabled =
    !passwordlessContainerState.RequirePasswordlessViaLastPassPolicy.checked ||
    policyHelper.isRequiredPolicyUnused(
      passwordlessContainerState.RequirePasswordlessViaLastPassPolicy
    )
  return (
    passwordlessContainerState.AccessPolicies.total === 0 &&
    passwordlessContainerState.IpPolicies.total === 0 &&
    passwordlessContainerState.GeofencePolicies.total === 0 &&
    isPasswordlessPolicyEnabled &&
    workstationListState.table.totalResults === 0 &&
    legacyVpnListState.table.totalResults === 0
  )
}

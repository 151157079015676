import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = response => createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service(id: string) {
    try {
      const [response, status] = await identityApi(
        `api/v2/Apps/${id}`,
        'DELETE',
        {},
        undefined,
        true
      )
      if (status === StatusCodes.OK && response) {
        const deleteResponse = JSON.parse(response)
        return success(deleteResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

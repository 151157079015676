import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import {
  GeneralPolicySwitchONSaveAPI,
  Services
} from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'
import { SaveTeamsPolicySwitchOnData } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getAdvancedPolicyDrawerState = (
  state: AppState
): AdvancedPolicyDrawerState => state.advancedPolicyDrawer

export function createSaveAdvancedPolicyConfig(services: Services) {
  return function* saveAdvancedPolicyConfig(
    action: ReturnType<typeof advancedPolicyDrawerActions.savePolicyConfig>
  ) {
    const state: AdvancedPolicyDrawerState = yield select(
      getAdvancedPolicyDrawerState
    )

    if (state.policy === null) {
      yield put(globalActions.setNotification(genericFailedNotification))
      return
    }

    const policyData = state.policy.policyData[state.dataIndex || 0]
    policyData.policyValue = state.policyValue ?? null
    policyData.policyNotes = state.policyNotes ?? ''

    const policySaveData: SaveTeamsPolicySwitchOnData = {
      policyKey: state.policy.policyKey,
      policyData: state.policy.policyData
    }

    try {
      const response = yield call(
        services.generalPolicySwitchOnSave,
        policySaveData
      )

      if (response.type === GeneralPolicySwitchONSaveAPI.SUCCESS) {
        yield put(
          globalActions.setNotificationSuccess(msg`Policy changes saved.`)
        )
        yield put(push(action.payload.closeLink))
        yield put(multifactorActions.fetchInitialData())
      } else if (
        response.type === GeneralPolicySwitchONSaveAPI.CANT_ADD_CONFIG
      ) {
        yield put(
          globalActions.setNotificationAlert(
            msg`This policy can't have multiple configurations.`
          )
        )
      } else {
        yield put(
          globalActions.setNotificationAlert(
            msg`Policy changes haven't been saved.`
          )
        )
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { msg } from '@lingui/macro'
import { push } from 'connected-react-router'
import { put, take } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

export function createNavigatePsaDrawerSaga() {
  return function* navigatePsaDrawerSaga(
    action: ReturnType<typeof psaIntegrationsActions.navigatePsaDrawer>
  ) {
    try {
      const { url, containsUnsavedChanges } = action.payload

      if (containsUnsavedChanges) {
        const dialog = getConfirmDialog()
        yield put(globalActions.setDialog(dialog))

        const dialogAction = yield take([
          GlobalActionTypes.CONFIRM_DIALOG,
          GlobalActionTypes.DISCARD_DIALOG
        ])

        if (dialogAction.type === GlobalActionTypes.DISCARD_DIALOG) {
          yield navigate(url)
        }
      } else {
        yield navigate(url)
      }
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

function* navigate(url) {
  yield put(push(url))
  yield put(psaIntegrationsActions.setDrawerContainsUnsavedChanges(false))
}

function getConfirmDialog() {
  const dialog: ConfirmDialog = {
    type: 'confirmdialog',
    confirmText: msg`Cancel`,
    discardText: msg`Discard`,
    text: msg`Discard unsaved changes?`,
    title: msg`You have unsaved changes`
  }
  return dialog
}

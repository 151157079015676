import { css } from '@emotion/react'

import { Theme } from '../theme'

export const GlobalStyles = css`
  *,
  body {
    font-family: ${Theme.fonts.primary};
  }
  a,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
    color: ${Theme.colors.blue700};
  }

  html,
  body {
    height: 100%;
  }

  input[type='search'] {
    -webkit-appearance: textfield;
  }

  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  sup {
    font-size: 8px;
    vertical-align: super;
  }
`

import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as Download } from '@lastpass/assets/svg/admin-console/icon-download.svg'
import { ReactComponent as Filter } from '@lastpass/assets/svg/admin-console/icon-filter.svg'
import { ReactComponent as Gear } from '@lastpass/assets/svg/admin-console/icon-gear.svg'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  Heading300,
  IconButton,
  Pagination,
  PrimaryButton,
  SearchInput,
  Table as TableComponent,
  TableView,
  TableViewButton,
  TextButton,
  WithTooltip
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { createQueryStringForNamespace } from '@lastpass/admin-console-dependencies/services/routing/create-query-string-for-namespace'
import { getAllUserOperations } from '@lastpass/admin-console-dependencies/services/user-operations/get-applicable-user-operations'
import { groupFilterActions } from '@lastpass/admin-console-dependencies/state/common/group-filter/actions'
import {
  CompanyDetails,
  CompanyInfoState,
  SubscriptionType
} from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import {
  userListActions,
  UserListQueryParams
} from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { UsersListState } from '@lastpass/admin-console-dependencies/state/users/view/list/state'
import { userOperationActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { OperationOriginType } from '@lastpass/admin-console-dependencies/state/users/view/operations/operation-origin-types'
import { UserOperationsType } from '@lastpass/admin-console-dependencies/state/users/view/operations/user-operations'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { UserListTableColumn } from '@lastpass/admin-console-dependencies/types/user-list-table-column'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { UserColumnMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/column-mappings/UserColumnMapping'
import { userOperationsMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/UserOperationsMapping'
import {
  PermissionContext,
  PermissionContextProps
} from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { UserListFiltersRow } from './UserListFiltersRow'

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 4px;
`

const FilterContainer = styled.div`
  display: flex;
  padding: 0 24px;
  margin-bottom: 12px;
  flex-direction: column;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
`

const StyledContainer = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
`

const StyledCustomizeUsersTableButton = styled(Gear)`
  margin: 12px;
`

const StyledFilterButton = styled(Filter)`
  margin: 12px 7px 12px 12px;
`

const Spacer = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const columnsQueryStringKey = 'userListColumns'

export const createUserListColumnsQueryString = (urlParams: {
  [columnsQueryStringKey]: UserListTableColumn[]
  [key: string]: unknown
}): string => createQueryStringForNamespace(Namespace.users, urlParams)

const DefaultSortString = 'email'

function getUserHasMfa(
  companyDetails: CompanyDetails,
  permissions: PermissionContextProps
) {
  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)
  const userHasMfaSubscription =
    !CompanyDetailsHelper.hasAnyPermission(companyDetails) &&
    permissions.requireSubscription(
      SubscriptionType.mfa,
      SubscriptionType.iDaaS
    )
  return userHasMfaAddon || userHasMfaSubscription
}

function getColumnsMap(
  columns: UserListTableColumn[],
  userHasMfa: boolean,
  isPasswordlessEnabledOrUsed: boolean,
  isFederatedLoginStatusesColumnEnabled: boolean,
  isDefaultMultifactorTypeColumnEnabled: boolean,
  isNoPasswordDeprecated: boolean,
  isCreatedByCompanyColumnEnabled: boolean
) {
  return columns
    .filter(column => {
      if (column === UserListTableColumn.mfaStatus) {
        return (
          userHasMfa && isPasswordlessEnabledOrUsed && !isNoPasswordDeprecated
        )
      } else if (column === UserListTableColumn.provisionFederatedStatus) {
        return isFederatedLoginStatusesColumnEnabled
      } else if (column === UserListTableColumn.defaultMultifactor) {
        return isDefaultMultifactorTypeColumnEnabled
      } else if (column === UserListTableColumn.isCreatedByCompany) {
        return isCreatedByCompanyColumnEnabled
      } else {
        return true
      }
    })
    .map(column => UserColumnMapping[column])
}

const UserListSearch: React.FunctionComponent<{
  usersList: UsersListState
  actions: typeof userListActions
  queryParams: Partial<UserListQueryParams>
}> = ({ usersList, actions, queryParams }) => {
  const updateQuery = useUpdateQuery(Namespace.users)
  return (
    <SearchContainer>
      <StyledContainer className={'onboarding-tour-step-1'}>
        <SearchInput
          onChange={event => {
            actions.setSelectAllUsers(false)
            updateQuery({
              search: event.target.value || undefined,
              skip: undefined
            })
          }}
          value={queryParams.search || ''}
          placeholder={msg`Search users...`}
          data-qa={'UsersPageSearchUsersField'}
        />
        <LocationLink to={'/users/view/filter'}>
          <Spacer>
            <StyledFilterButton />
            <TextButton data-qa={'FilterUsersButton'} blue>
              <Trans>Filter users</Trans>
            </TextButton>
          </Spacer>
        </LocationLink>
      </StyledContainer>
      <StyledContainer>
        <LocationLink to={'/users/view/customize'}>
          <WithTooltip tooltip={<Trans>Table view settings</Trans>}>
            <StyledCustomizeUsersTableButton />
          </WithTooltip>
        </LocationLink>
        <Pagination
          skip={queryParams.skip || 0}
          totalResults={usersList.table.totalResults}
          resultsPerPage={usersList.table.resultsPerPage}
          updateSkip={skip => {
            updateQuery({ skip: skip || undefined })
          }}
        />
      </StyledContainer>
    </SearchContainer>
  )
}

export const UserList: React.FunctionComponent = () => {
  const queryParams: UserListQueryParams = useQueryParams(Namespace.users)
  const updateQuery = useUpdateQuery(Namespace.users)

  const {
    details: companyDetails,
    cidOverride: cidOverrideChildCompanyId
  } = useSelector<AppState, CompanyInfoState>(appState => appState.companyInfo)

  const usersListState: UsersListState = useSelector(
    (state: AppState) => state.usersList
  )
  const userListTableColumns = usersListState.table.columns
  const isTransferVaultHidden = usersListState.isTransferVaultHidden

  const customColumnOrder: UserListTableColumn[] | undefined =
    queryParams[columnsQueryStringKey]

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(passwordlessActions.fetchInitialData())
    dispatch(userListActions.getIsTransferVaultHidden())
  }, [dispatch])

  useEffect(() => {
    dispatch(userListActions.getUserList({ query: queryParams, path: {} }))
  }, [dispatch, queryParams])

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )
  const isForceVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isForceVaultReEncryptionEnabled
  )

  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()

  const isPasswordlessEnabledOrUsed =
    !isNpPasswordlessFeatureDisabled || !isPasswordlessPoliciesEmpty

  const permissions = useContext(PermissionContext)
  const userHasMfa = getUserHasMfa(companyDetails, permissions)

  const isUserGroupFilteringEnabled = useFeatureFlags(
    FeatureFlags.isUserGroupFilteringEnabled
  )

  useEffect(() => {
    if (
      permissions.requirePermission(
        AdminPermissions.directoriesAndFederationModify
      )
    ) {
      dispatch(federatedLoginActions.getAdfsSetup())
    }
  }, [dispatch, permissions])

  const isAssignAdminLevelActionEnabled = useFeatureFlags(
    FeatureFlags.isAssignAdminLevelActionEnabled
  )

  const [columns, setColumns] = useState(userListTableColumns)
  useEffect(() => {
    if (customColumnOrder) {
      return
    }

    dispatch(userListActions.getUserListColumns())
    if (
      queryParams.filter &&
      queryParams.filter.groupId &&
      isUserGroupFilteringEnabled
    ) {
      dispatch(
        groupFilterActions.getGroupListByIdList(queryParams.filter.groupId)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setColumns(userListTableColumns)
  }, [userListTableColumns])

  useEffect(() => {
    if (!customColumnOrder || !customColumnOrder.length) {
      return
    }
    const uniqueColumns = Array.from(new Set(customColumnOrder))
    setColumns([...uniqueColumns])
  }, [customColumnOrder])

  const history = useHistory()

  const selectAllUsers = (isAllUsersSelected: boolean) => {
    dispatch(userListActions.setSelectAllUsers(isAllUsersSelected))
    const userOperations = getAllUserOperations(
      companyDetails,
      permissions.permissions
    )

    let filteredUserOperations = isAssignAdminLevelActionEnabled
      ? userOperations.filter(
          userOperation =>
            userOperation !== UserOperationsType.assignAdminLevels
        )
      : userOperations

    filteredUserOperations = filteredUserOperations.filter(
      userOperation =>
        userOperation !== UserOperationsType.distributeRecoveryKey
    )

    dispatch(userListActions.setUserOperations(filteredUserOperations))
  }

  const isFederatedLoginStatusesColumnEnabled =
    useFeatureFlags(FeatureFlags.isFederatedLoginStatusesEnabled) &&
    companyDetails.isFederatedLoginEnabled

  const isDefaultMultifactorTypeColumnEnabled =
    !isFederatedLoginStatusesColumnEnabled ||
    userListTableColumns !== usersListState.defaultUserListColumns

  const isLastLoginIncludedInUsersExport = useFeatureFlags(
    FeatureFlags.isLastLoginIncludedInUsersExport
  )

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const isAccountStatusRenamed = useFeatureFlags(
    FeatureFlags.isAccountStatusRenamed
  )

  const isCreatedByCompanyColumnEnabled = useFeatureFlags(
    FeatureFlags.isCreatedByCompanyColumnEnabled
  )

  const userOperationsMappingObject = userOperationsMapping({
    dispatch,
    userOperationActions,
    origin: OperationOriginType.List,
    history,
    filter: usersListState.table.isAllSelected ? queryParams.filter : undefined,
    cidOverrideChildCompanyId,
    isPasswordlessEnabledOrUsed: isPasswordlessEnabledOrUsed,
    isForceVaultReEncryptionEnabled,
    areMultipleUsersSelected: usersListState.table.checkedRecords.length > 1
  })

  return (
    <>
      <RowContainer>
        <Heading300 className={'onboarding-tour-step-1'}>
          <Trans>Users</Trans>
        </Heading300>
        <div>
          <IconButton
            data-qa="ExportUsersButton"
            icon={Download}
            onClick={() => {
              dispatch(
                userListActions.exportUsers(
                  queryParams.filter,
                  isLastLoginIncludedInUsersExport,
                  isAccountStatusRenamed,
                  isCreatedByCompanyColumnEnabled
                )
              )
            }}
          />
          {permissions.requirePermission(AdminPermissions.usersModify) && (
            <LocationLink
              to={'/users/view/add'}
              onClick={() =>
                dispatch(userListActions.reportAddUserButtonClicked())
              }
            >
              <StyledPrimaryButton
                key={'add users'}
                data-qa={'UsersPageAddUsersButton'}
              >
                <Trans>Add users</Trans>
              </StyledPrimaryButton>
            </LocationLink>
          )}
        </div>
      </RowContainer>
      <UserListSearch
        usersList={usersListState}
        actions={userListActions}
        queryParams={queryParams}
      />
      <FilterContainer>
        <UserListFiltersRow
          queryParams={queryParams}
          updateQuery={updateQuery}
          totalCompanyUsers={usersListState.totalCompanyUserCount}
          totalResults={usersListState.table.totalResults}
        />
      </FilterContainer>

      <TableComponent
        qadata={'UsersMainList'}
        table={usersListState.table}
        orderByExpression={
          queryParams.orderBy ? queryParams.orderBy : DefaultSortString
        }
        onOrderingChanged={sortString => {
          updateQuery({
            orderBy: sortString,
            skip: undefined
          })
        }}
        checkable={{
          addSelectedRecords: record =>
            dispatch(userListActions.addSelectedUsers(record)),
          removeSelectedRecords: record =>
            dispatch(userListActions.removeSelectedUsers(record)),
          setSelectedRecords: record =>
            dispatch(userListActions.setSelectedUsers(record)),
          setSelectAllRecords: selectAllUsers
        }}
        getRecordLink={(record: User) => `${matchUrl}/${record.id}`}
        isAllSelectable
        noRecordsView={
          <TableView
            icon={<UserIcon />}
            title={<Trans>No users added yet</Trans>}
            text={<Trans>Add users individually or in bulk</Trans>}
            actionButton={
              permissions.requirePermission(AdminPermissions.usersModify) ? (
                <LocationLink to={'/users/view/add'}>
                  <TableViewButton>
                    <Trans>Add users</Trans>
                  </TableViewButton>
                </LocationLink>
              ) : (
                <></>
              )
            }
          />
        }
        noResultsView={
          <TableView
            icon={<UserIcon data-qa={'NoUserIcon'} />}
            title={<Trans>Sorry, no results match your search</Trans>}
            text={
              <Trans>
                Try searching for different keywords and check your spelling for
                any typos.
              </Trans>
            }
            actionButton={
              <TableViewButton
                onClick={() => {
                  updateQuery({
                    search: undefined,
                    filter: undefined
                  })
                }}
                data-qa={'ClearSearchButton'}
              >
                <Trans>Clear search query</Trans>
              </TableViewButton>
            }
          />
        }
        loadingView={<Loading color="blue900" active={true} />}
        columns={getColumnsMap(
          columns,
          userHasMfa,
          isPasswordlessEnabledOrUsed,
          isFederatedLoginStatusesColumnEnabled,
          isDefaultMultifactorTypeColumnEnabled,
          isNoPasswordDeprecated,
          isCreatedByCompanyColumnEnabled
        )}
        actions={
          usersListState.table.operations
            ? usersListState.table.operations
                .filter(userOperation =>
                  isAssignAdminLevelActionEnabled
                    ? userOperation
                    : userOperation !== UserOperationsType.assignAdminLevels
                )
                .map(
                  userOperation => userOperationsMappingObject[userOperation]
                )
                .filter(
                  item =>
                    !(
                      isTransferVaultHidden &&
                      item.name.props.id ===
                        userOperationsMappingObject[
                          UserOperationsType.transferVault
                        ].name.props.id
                    )
                )
            : []
        }
        isFilterApplied={!!queryParams.filter || !!queryParams.search}
        isSearchApplied={!!queryParams.search}
      />
    </>
  )
}

import React, { FunctionComponent, ReactElement, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import styled from '@emotion/styled'

import { Chevron } from '@lastpass/components'
import { BodyRegularStyle, BodySemiboldStyle } from '@lastpass/lastkit/styles'

export interface LogoCardProps {
  logo?: ReactElement
  title: ReactElement
  subtitle?: ReactElement
  hideSubtitleOnClose?: boolean
  expand?: boolean
  highlighted?: boolean
  description?: ReactElement
  dataQaName?: string
  contentOnClosed?: ReactElement
  className?: string
}

const StyledContainer = styled.div<{
  expanded: boolean
}>`
  box-shadow: ${props => props.theme.elevations.elevation100};
  background: ${props => props.theme.colors.neutral};
  border: 1px solid ${props => props.theme.colors.neutral300};
  width: 100%;
  border-radius: 4px;
  cursor: ${props => (props.expanded ? 'default' : 'pointer')};
`

const StyledHeaderContainer = styled.div`
    ${BodySemiboldStyle}
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
  `

const StyledIconContainer = styled.div`
  margin: 16px;
`

const StyledTitleWrapper = styled.div<{ $hasLogo?: boolean }>`
  flex-grow: 2;
  margin-top: 16px;
  margin-bottom: 16px;
  ${props => (props.$hasLogo ? null : `margin-left: 16px;`)}
`

const StyledTitleContainer = styled.div`
  display: flex;
`

const StyledSubtitle = styled.div`
    ${BodyRegularStyle}
    color: ${props => props.theme.colors.neutral700};
  `

const StyledToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`

const StyledToggleButton = styled.button`
  outline: none;
  border: none;
  height: 30px;
  width: 30px;
  margin: 16px;
  background: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`

const StyledChildrenContainer = styled.div<{ $hasLogo?: boolean }>`
  margin-left: ${props => (props.$hasLogo ? '72px' : '16px')};
  display: flex;
  flex-direction: column;
`

const ContentOnClosedContainer = styled.div`
  margin-left: 12px;
  flex-grow: 1;
`

export const LogoCard: FunctionComponent<LogoCardProps> = props => {
  const [expanded, setExpanded] = useState(props.expand ? props.expand : false)
  return (
    <StyledContainer
      expanded={expanded}
      onClick={() => {
        if (!expanded) {
          setExpanded(true)
        }
      }}
      className={props.className}
      data-qa={props.dataQaName}
    >
      <StyledHeaderContainer
        data-qa={props.dataQaName && props.dataQaName + 'Container'}
      >
        {props.logo ? (
          <StyledIconContainer>{props.logo}</StyledIconContainer>
        ) : null}
        <StyledTitleWrapper $hasLogo={props.logo !== undefined}>
          <StyledTitleContainer>
            {props.title}
            {props.contentOnClosed && (
              <ContentOnClosedContainer>
                <AnimateHeight height={expanded ? 0 : 'auto'}>
                  {props.contentOnClosed}
                </AnimateHeight>
              </ContentOnClosedContainer>
            )}
          </StyledTitleContainer>
          <AnimateHeight
            height={!props.hideSubtitleOnClose || expanded ? 'auto' : 0}
          >
            <StyledSubtitle>{props.subtitle}</StyledSubtitle>
          </AnimateHeight>
        </StyledTitleWrapper>
        <StyledToggleButtonContainer>
          <StyledToggleButton
            onClick={() => {
              setExpanded(!expanded)
            }}
            data-qa={props.dataQaName && props.dataQaName + 'ToggleButton'}
          >
            <Chevron direction={expanded ? 'up' : 'down'} color={'#41536B'} />
          </StyledToggleButton>
        </StyledToggleButtonContainer>
      </StyledHeaderContainer>
      <AnimateHeight height={expanded ? 'auto' : 0}>
        <StyledChildrenContainer $hasLogo={props.logo !== undefined}>
          {props.children}
        </StyledChildrenContainer>
      </AnimateHeight>
    </StyledContainer>
  )
}

import { msg } from '@lingui/macro'
import { saveAs } from 'file-saver'
import buildQuery from 'odata-query'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

import { buildFilterArray } from './helpers/build-filter-array'

export const isLoadingSelector = (state: AppState) =>
  state.usersList.isExportUsersLoading

const pendingNotification: NotificationProps = {
  message: msg`Export already in progress. Thanks for your patience.`,
  type: NotificationType.warning,
  autoDismiss: true
}

export function createExportUsersSaga(userService: UACServices.Services) {
  return function* exportUsersSaga(
    action: ReturnType<typeof userListActions.exportUsers>
  ) {
    const isLoading = yield select(isLoadingSelector)

    if (isLoading) {
      yield put(globalActions.setNotification(pendingNotification))
      return
    }

    try {
      yield put(userListActions.setIsExportUsersLoading(true))
      const {
        userFilter,
        isLastLoginIncluded,
        isAccountStatusRenamed,
        isCreatedByCompanyColumnEnabled
      } = action.payload

      const filters = buildFilterArray(userFilter)

      const query = buildQuery({
        filter: filters ? { and: filters } : undefined
      })

      const result: UACServices.ExportUsersAPI.Responses = yield call(
        userService.exportUsers,
        query,
        isLastLoginIncluded,
        isAccountStatusRenamed,
        isCreatedByCompanyColumnEnabled
      )
      if (result.type === UACServices.ExportUsersAPI.SUCCESS) {
        const usersCSV = result.body
        const blobToSave = new Blob([usersCSV], {
          type: 'text/csv;charset=utf-8;'
        })
        yield call(saveAs, blobToSave, 'users.csv')
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(userListActions.setIsExportUsersLoading(false))
    }
  }
}

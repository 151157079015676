import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Heading300 } from '@lastpass/lastkit'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { UsersTab } from '@lastpass/admin-console-dependencies/ui/applications/ssoapps/passwordless/users/UsersTabComponent'
import { AdvancedMfaMessage } from '@lastpass/admin-console-dependencies/ui/global/AdvancedMfaMessage'

const RowContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
`
const FlexRowContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`

export const PasswordlessPage: React.FunctionComponent<{
  isEmpty: boolean
}> = ({ isEmpty }) => {
  const match = useRouteMatch()

  const companyDetails: CompanyDetails = useSelector(
    (state: AppState) => state.companyInfo.details
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  if (!match) {
    return null
  }

  const advancedMfaMessage = userHasMfaAddon ? (
    <AdvancedMfaMessage
      isEmpty={isEmpty}
      nonMspMessage={msg`Passwordless is available with the Advanced MFA add-on.`}
    />
  ) : null

  return (
    <>
      <FlexRowContainer>
        <Heading300>
          <Trans>Passwordless authentication</Trans>
        </Heading300>
        {advancedMfaMessage}
      </FlexRowContainer>
      <RowContainer>
        <UsersTab />
      </RowContainer>
    </>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Drawer } from '@lastpass/lastkit'

import { UserLevelWithPermissions } from '@lastpass/admin-console-dependencies/types/user-level'
import { AdminLevelForm } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'
import { AdminFormValues } from '@lastpass/admin-console-dependencies/ui/users/admin/shared/admin-level-form/AdminLevelForm'

export interface EditAdminLevelDrawerProps {
  closeLink: string
}

export const EditAdminLevelDrawer: React.FunctionComponent<EditAdminLevelDrawerProps> = ({
  closeLink
}) => {
  const adminLevel: UserLevelWithPermissions = useSelector(
    (state: AppState) => state.adminLevelDrawer.adminLevel
  )

  const formValues: AdminFormValues = {
    levelName: adminLevel.name,
    levelDescription: adminLevel.description,
    levelPermission: adminLevel.permissions,
    mspCidOverrideAdminLevel: adminLevel.mspCidOverrideAdminLevel,
    mspCidOverridePermissions: adminLevel.mspCidOverridePermissions
  }

  return (
    <Drawer
      mainTitle={<Trans>Admin level details</Trans>}
      closeLink={closeLink}
    >
      <AdminLevelForm closeLink={closeLink} formValues={formValues} editMode />
    </Drawer>
  )
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import {
  connectRedux,
  LastPassComponentProps,
  useUnmountEffect
} from '@lastpass/ui'

import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { DashboardDrawerState } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/state'
import { SecurityScoreCard } from '@lastpass/admin-console-dependencies/ui/dashboard/drawer/SecurityScoreCard'

export type UsersWithWeakSecurityScoreDrawerProps = LastPassComponentProps<
  DashboardDrawerState,
  typeof dashboardDrawerActions,
  { cancelLink: string }
>

export const UsersWithWeakSecurityScoreDrawer: React.FunctionComponent<UsersWithWeakSecurityScoreDrawerProps> = ({
  state,
  actions,
  props: { cancelLink }
}) => {
  const queryParams = useQueryParams(Namespace.weakSecurityScoreUsers)
  const updateQuery = useUpdateQuery(Namespace.weakSecurityScoreUsers)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      dashboardDrawerActions.getUsersWithWeakSecurityScore({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  useUnmountEffect(() => {
    actions.reset()
  })

  const loading = <Loading color="blue900" active={true} />

  return (
    <>
      {state.dashboardDrawerLoading && loading}

      <SecurityScoreCard
        users={state.usersWithWeakSecurityScore}
        queryParams={queryParams}
        updateQuery={updateQuery}
        noRecordsText={
          <Trans>Impressive! Everyone has a good security score.</Trans>
        }
        qaData="UsersWithWeakSecurityScoreDrawerTable"
      />

      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          {state.usersWithWeakSecurityScore.totalResults > 0 ? (
            <>
              <LocationLink to={cancelLink}>
                <TextButton>
                  <Trans>Cancel</Trans>
                </TextButton>
              </LocationLink>
              <PrimaryButton
                onClick={() => {
                  actions.exportUsersWithWeakSecurityScore()
                  actions.exportUsersClicked('Weak Security Score Users Drawer')
                }}
                css={css`
                  margin-left: 16px;
                `}
              >
                <Trans>Export users</Trans>
              </PrimaryButton>
            </>
          ) : (
            <LocationLink to={cancelLink}>
              <PrimaryButton>
                <Trans>Cancel</Trans>
              </PrimaryButton>
            </LocationLink>
          )}
        </DrawerButtonsContainer>
      </FooterContainer>
    </>
  )
}

export function createUsersWithWeakSecurityScoreDrawer<
  TState extends { dashboardDrawer: DashboardDrawerState }
>() {
  return connectRedux(
    UsersWithWeakSecurityScoreDrawer,
    (state: TState) => state.dashboardDrawer,
    dashboardDrawerActions
  )
}

export type UsersWithWeakSecurityScoreDrawer = ReturnType<
  typeof createUsersWithWeakSecurityScoreDrawer
>

import React, { FC } from 'react'

import styled from '@emotion/styled'
import { MessageDescriptor } from '@lingui/core'
import { i18n } from '@lingui/core'

import { ReactComponent as HelpIcon } from '@lastpass/assets/svg/admin-console/icon-help.svg'
import { Heading100, WithTooltip } from '@lastpass/lastkit'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.blue100};
  border-radius: ${props => props.theme.radius.pixel4}
    ${props => props.theme.radius.pixel4} 0 0;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.3333;
  padding: 12px 16px;
`

const StyledHeading100 = styled(Heading100)`
  display: flex;
  align-items: center;
`

const StyledHelpIcon = styled(HelpIcon)`
  color: ${props => props.theme.colors.neutral700};
  height: 16px;
  margin-left: 4px;
`

interface BlockHeaderProps {
  title: MessageDescriptor
  helperTooltip?: MessageDescriptor
}

export const BlockHeader: FC<BlockHeaderProps> = ({
  title,
  helperTooltip,
  children
}) => (
  <Container>
    <StyledHeading100>
      {i18n._(title)}
      {helperTooltip && (
        <WithTooltip tooltip={i18n._(helperTooltip)}>
          <StyledHelpIcon data-qa="BlockHeaderHelpIcon" />
        </WithTooltip>
      )}
    </StyledHeading100>
    {children}
  </Container>
)

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router'

import { Trans } from '@lingui/macro'

import { ReactComponent as DevicesIcon } from '@lastpass/assets/svg/admin-console/icon-devices.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  DrawerTable,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit/components'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { PasswordManagerTrustedDevice } from '@lastpass/admin-console-dependencies/types/trusted-device'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['trustedDevices']['passwordManager'],
  typeof userDrawerActions
>

export const UserDrawerTrustedLpDevicesTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: trustedDevices,
  actions
}) => {
  const queryParams = useQueryParams()
  const pathParams: UserDrawerUrlParams = usePathParams()

  const match = useRouteMatch()
  const matchUrl = match && match.url

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserTrustedDevices({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])
  useDrawerSubtitle(<Trans>Registered devices</Trans>)
  const routerHistory = useHistory()

  if (!trustedDevices) {
    return <Loading color={'blue900'} active={true} />
  } else {
    return (
      <DrawerTable
        qadata={'UserLevelDevicesPasswordManagerList'}
        table={trustedDevices}
        loadingView={<Loading color="blue900" active={true} />}
        getRecordLink={(record: PasswordManagerTrustedDevice) =>
          `${matchUrl}/password-manager/${record.id}`
        }
        columns={[
          {
            name: <Trans>Device</Trans>,
            renderer: (record: PasswordManagerTrustedDevice) => record.type
          },
          {
            name: <Trans>UUID</Trans>,
            renderer: (record: PasswordManagerTrustedDevice) => record.uuid
          },
          {
            name: <Trans>Label</Trans>,
            renderer: (record: PasswordManagerTrustedDevice) => record.label
          },
          {
            name: <Trans>Status</Trans>,
            renderer: function EnabledRenderer(
              record: PasswordManagerTrustedDevice
            ) {
              return record.isEnabled ? (
                <Trans>Enabled</Trans>
              ) : (
                <Trans>Disabled</Trans>
              )
            }
          }
        ]}
        checkable={{
          setSelectedRecords: actions.setSelectedLpDevices,
          removeSelectedRecords: actions.removeSelectedLpDevices,
          addSelectedRecords: actions.addSelectedLpDevices
        }}
        noRecordsView={
          <TableView
            icon={<DevicesIcon />}
            title={<Trans>This user has no registered password manager</Trans>}
          />
        }
        actions={[
          {
            name: <Trans>Edit device</Trans>,
            callback: (records: PasswordManagerTrustedDevice[]) => {
              routerHistory.push(
                `${matchUrl}/password-manager/${records[0].id}`
              )
            },
            isValid: () => true,
            singleItem: true
          },
          {
            name: <Trans>Enable</Trans>,
            callback: (records: PasswordManagerTrustedDevice[]) => {
              pathParams.id &&
                actions.enablePasswordManagers(records, true, pathParams.id)
            },
            isValid: (records: PasswordManagerTrustedDevice[]) => {
              for (const record of records) {
                if (!record.isEnabled) return true
              }
              return false
            }
          },
          {
            name: <Trans>Disable</Trans>,
            callback: (records: PasswordManagerTrustedDevice[]) => {
              pathParams.id &&
                actions.enablePasswordManagers(records, false, pathParams.id)
            },
            isValid: (records: PasswordManagerTrustedDevice[]) => {
              for (const record of records) {
                if (record.isEnabled) return true
              }
              return false
            },
            color: 'red700'
          },
          {
            name: <Trans>Delete</Trans>,
            callback: (records: PasswordManagerTrustedDevice[]) => {
              pathParams.id &&
                actions.deletePasswordManagers(records, pathParams.id)
            },
            isValid: () => true,
            color: 'red700'
          }
        ]}
      />
    )
  }
}

export function createUserDrawerTrustedLpDevicesTable<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserDrawerTrustedLpDevicesTableComponent,
    (state: TState) => state.usersDrawer.trustedDevices.passwordManager,
    userDrawerActions
  )
}

export type UserDrawerTrustedDevicesTable = ReturnType<
  typeof createUserDrawerTrustedLpDevicesTable
>

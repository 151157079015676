import {
  AssignmentStatus,
  AvailabilityStatus,
  PsaCompanyContractAssignmentOption
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'

type CreateAssignmentMap = (
  assignmentOptions: PsaCompanyContractAssignmentOption[],
  getId: (assignmentOption: PsaCompanyContractAssignmentOption) => string | null
) => AssignmentsMapping

export const createAssignmentMap: CreateAssignmentMap = (
  assignmentOptions,
  getId
) => {
  return assignmentOptions.reduce<AssignmentsMapping>(
    (acc, assignmentOption) => {
      const { company, contractAssignment } = assignmentOption
      const foundContract = contractAssignment.contracts.find(
        contract => contract.id === getId(assignmentOption)
      )
      const contractName = foundContract ? foundContract.name : ''
      const availabilityStatus = foundContract
        ? foundContract.availability
        : AvailabilityStatus.AVAILABLE

      const contractId =
        contractAssignment.assignmentStatus !== AssignmentStatus.EXCLUDED
          ? getId(assignmentOption)
          : null

      acc[company.id] = {
        contractId,
        contractName,
        assignmentStatus: contractId
          ? AssignmentStatus.ASSIGNED
          : contractAssignment.assignmentStatus,
        availabilityStatus
      }

      return acc
    },
    {}
  )
}

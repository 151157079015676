import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const responses = {
  success
}

export type Responses = ResponseTypes<typeof responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [, status] = await fetch('company/security-report/update', 'POST')

    if (status !== StatusCodes.ACCEPTED) {
      throw new UnknownServiceError()
    }

    return success()
  }
}

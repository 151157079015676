import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  BodyRegularStyle,
  CardContainer,
  Heading100,
  Heading200,
  PrimaryButton
} from '@lastpass/lastkit'

export interface ProvisioningHashProps {
  alreadyHasHash: boolean
  hash?: string
  generateHash: () => void
  resetHash: () => void
}

const Container = styled(CardContainer)`
  padding: 16px 24px;
  display: flex;
  align-items: center;
`

const TitleWrapper = styled.div`
  flex-grow: 2;
`

const Title = styled.div`
  ${Heading100};
  color: ${props => props.theme.colors.neutral900};
  margin-bottom: 4px;
`

const Hash = styled.div`
  ${Heading200};
  color: ${props => props.theme.colors.neutral900};
  margin-bottom: 4px;
  margin-top: 4px;
`

const Subtitle = styled.div`
  ${BodyRegularStyle}
  color: ${props => props.theme.colors.neutral700};
`

function noHash(generateHash: () => void) {
  return (
    <Container>
      <TitleWrapper>
        <Title>
          <Trans>Provisioning hash</Trans>
        </Title>
        <Subtitle>
          <Trans>You haven{"'"}t created your provisioning hash yet.</Trans>
        </Subtitle>
      </TitleWrapper>
      <PrimaryButton
        data-qa={'CreateProvisioningHashButton'}
        onClick={generateHash}
      >
        <Trans>Create provisioning hash</Trans>
      </PrimaryButton>
    </Container>
  )
}

function justGenerated(hash: string) {
  return (
    <Container>
      <TitleWrapper>
        <Subtitle>
          <Trans>Your provisioning hash:</Trans>
        </Subtitle>
        <Hash>{hash}</Hash>
        <Subtitle>
          <Trans>
            This won{"'"}t be shown again. Copy it and keep it secret.
          </Trans>
        </Subtitle>
      </TitleWrapper>
    </Container>
  )
}

function alreadyHasHash(resetHash: () => void) {
  return (
    <Container>
      <TitleWrapper>
        <Title>
          <Trans>Provisioning hash</Trans>
        </Title>
        <Subtitle>
          <Trans>
            You{"'"}ve already created your provisioning hash. If you don{"'"}t
            know it, click to reset.
          </Trans>
        </Subtitle>
      </TitleWrapper>
      <PrimaryButton
        data-qa={'ResetProvisioningHashButton'}
        onClick={resetHash}
      >
        <Trans>Reset your provisioning hash</Trans>
      </PrimaryButton>
    </Container>
  )
}

export const ProvisioningHash: React.FunctionComponent<ProvisioningHashProps> = props => {
  if (props.hash) {
    return justGenerated(props.hash)
  } else if (props.alreadyHasHash) {
    return alreadyHasHash(props.resetHash)
  } else {
    return noHash(props.generateHash)
  }
}

import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GroupMap } from '@lastpass/admin-console-dependencies/state/applications/application'

export const SUCCESS = 'success'

const success = (groupResponse: GroupMap) =>
  createResponse(SUCCESS, { groupResponse })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await identityApi(
        `api/v2/Groups/getGroups/`,
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const groupResponse = JSON.parse(response)
        return success(groupResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { DirectoryIntegration } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'
export const SUCCESS = 'success'

const success = (settings: DirectoryIntegration) =>
  createResponse(SUCCESS, { settings })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch('company/scim-settings', 'GET')
      if (status === StatusCodes.OK && response) {
        const settingsResponse = JSON.parse(response).content
        const localDate = new Date(settingsResponse.lastSyncUtc).toString()
        const settings = {
          lastSync: localDate,
          hasProvisionToken: settingsResponse.hasProvisionToken
        }
        return success(settings)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  AvailableApplication,
  SelectedApplication
} from '@lastpass/admin-console-dependencies/state/applications/application'

export enum ApplicationEmptyListActionTypes {
  ADD_NEW_APPLICATION = '[application-empty-list] add-new-app',
  HIGHLIGHTED_SSO_APP_CLICKED = '[application-empty-list] highlighted-sso-app-clicked',
  GET_AVAILABLE_APPLICATIONS = '[application-empty-list] get-available-applications',
  SET_AVAILABLE_APPLICATIONS = '[application-empty-list] set-available-applications',
  SET_APPLICATION_LIST_LOADING = '[application-empty-list] set-application-list-loading'
}

export const applicationEmptyListSegmentActions = {
  addNewAppButtonClicked: () =>
    track(
      createAction(ApplicationEmptyListActionTypes.ADD_NEW_APPLICATION, {}),
      {
        segment: uacEvent('Add SSO App Clicked', {
          'App Count': 0,
          Source: 'SSO Apps Home'
        })
      }
    ),
  highlightedSsoAppClicked: (selectedApp: SelectedApplication) =>
    track(
      createAction(
        ApplicationEmptyListActionTypes.HIGHLIGHTED_SSO_APP_CLICKED,
        { selectedApp }
      ),
      {
        segment: uacEvent('Highlighted SSO App Clicked', {
          'SSO App Name': selectedApp.name
        })
      }
    )
}

export const applicationEmptyListActions = {
  addNewApp: () => applicationEmptyListSegmentActions.addNewAppButtonClicked(),
  getAvailableApplications: () =>
    createAction(ApplicationEmptyListActionTypes.GET_AVAILABLE_APPLICATIONS),
  setAvailableApplications: (list: AvailableApplication[]) =>
    createAction(ApplicationEmptyListActionTypes.SET_AVAILABLE_APPLICATIONS, {
      list
    }),
  setApplicationsLoading: (loading: boolean) =>
    createAction(ApplicationEmptyListActionTypes.SET_APPLICATION_LIST_LOADING, {
      loading
    })
}

export type ApplicationEmptyListActions = ActionsUnion<
  typeof applicationEmptyListActions
>

import React, { FC } from 'react'

import { msg } from '@lingui/macro'

import { ReactComponent as ChartIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/trending-chart-upwards.svg'

import { ActionLink } from '@lastpass/admin-console-dependencies/ui/home/components/action-link/ActionLink'

export interface ShowHistoryLinkProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  dataQa?: string
}

export const ShowHistoryLink: FC<ShowHistoryLinkProps> = ({
  onClick,
  dataQa
}) => (
  <ActionLink
    icon={ChartIcon}
    text={msg`Show history`}
    onClick={onClick}
    dataQa={dataQa && dataQa + 'ShowHistoryAction'}
  />
)

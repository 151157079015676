import React from 'react'
import {
  match,
  matchPath,
  Route,
  useLocation,
  useRouteMatch
} from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { ReactComponent as ChevronLeft } from '@lastpass/assets/svg/admin-console/icon-chevron-left.svg'
import { IconButton } from '@lastpass/lastkit'
import { AnimatedSwitch, LocationLink } from '@lastpass/routing'

import { AddPolicyDrawer } from '@lastpass/admin-console-dependencies/ui/policies/general/AddPolicyDrawer'
import { AddPolicyDrawerPage } from '@lastpass/admin-console-dependencies/ui/policies/general/AddPolicyDrawerPage'
import { GeneralPolicySettings } from '@lastpass/admin-console-dependencies/ui/policies/general/EditGeneralPolicySettings'
import {
  GeneralPoliciesDrawer,
  GeneralPoliciesDrawerProps
} from '@lastpass/admin-console-dependencies/ui/policies/general/GeneralPoliciesDrawer'
import { GeneralPoliciesList } from '@lastpass/admin-console-dependencies/ui/policies/general/GeneralPoliciesList'

import { GeneralPoliciesAssignUsersDrawerPage } from './drawer/GeneralPoliciesAssignUsersDrawerPage'
import { GeneralPoliciesDetailsDrawerPage } from './drawer/GeneralPoliciesDetailsDrawerPage'
import { GeneralPoliciesUsersDrawerPage } from './drawer/GeneralPoliciesUsersDrawerPage'

export interface AddPolicyDrawerProps {
  closeLink?: string
}

const getMatchUrl = (pathMatch: match<{}> | null): string => {
  return (pathMatch && pathMatch.url) || '/'
}

const buildDrawerProps = (
  url: string,
  drawerMatch: match<{}> | null,
  settingsDrawerMatch: match<{}> | null,
  usersDrawerMatch: match<{}> | null,
  assignUsersDrawerMatch: match<{}> | null
): GeneralPoliciesDrawerProps => {
  const drawerProps: GeneralPoliciesDrawerProps = {
    closeLink: url,
    title: <Trans>Policy details</Trans>
  }

  if (settingsDrawerMatch) {
    drawerProps.title = <Trans>Settings</Trans>
  }
  if (usersDrawerMatch) {
    drawerProps.title = <Trans>Users {'&'} Groups</Trans>
  }
  if (settingsDrawerMatch || usersDrawerMatch) {
    drawerProps.closeLink = getMatchUrl(drawerMatch)
    drawerProps.backButton = (
      <LocationLink to={getMatchUrl(drawerMatch)}>
        <IconButton icon={ChevronLeft} data-qa={'BackButton'} />
      </LocationLink>
    )
    drawerProps.displayPolicyTitle = true
  }
  if (assignUsersDrawerMatch) {
    drawerProps.title = <Trans>Assign users {'&'} groups</Trans>
    drawerProps.displayPolicyTitle = true
    drawerProps.backButton = (
      <LocationLink to={getMatchUrl(usersDrawerMatch)}>
        <IconButton icon={ChevronLeft} data-qa={'BackButton'} />
      </LocationLink>
    )
  }
  return drawerProps
}

export const GeneralPoliciesViewPage = () => {
  const routeMatch = useRouteMatch()
  const location = useLocation()
  if (routeMatch) {
    const { url, path } = routeMatch
    const drawerPath = `${path}/:id`
    const drawerMatch = matchPath(location.pathname, drawerPath)
    const settingsDrawerMatch = matchPath(
      location.pathname,
      `${drawerPath}/settings/:configId`
    )
    const usersDrawerMatch = matchPath(
      location.pathname,
      `${drawerPath}/edit-users/:configId`
    )

    const assignUsersDrawerMatch =
      matchPath(location.pathname, `${drawerPath}/assign-users`) ||
      matchPath(location.pathname, `${drawerPath}/edit-users/:configId/assign`)

    const drawerProps = buildDrawerProps(
      url,
      drawerMatch,
      settingsDrawerMatch,
      usersDrawerMatch,
      assignUsersDrawerMatch
    )

    return (
      <>
        <GeneralPoliciesList />
        <AnimatedSwitch timeout={200}>
          <Route path={`${path}/add`}>
            <AddPolicyDrawer {...drawerProps}>
              <AddPolicyDrawerPage />
            </AddPolicyDrawer>
          </Route>
          <Route path={`${drawerPath}/settings/:configId`}>
            <GeneralPoliciesDrawer {...drawerProps}>
              <GeneralPolicySettings />
            </GeneralPoliciesDrawer>
          </Route>
          <Route
            path={[
              `${drawerPath}/assign-users/:configId`,
              `${drawerPath}/edit-users/:configId/assign`
            ]}
          >
            <GeneralPoliciesDrawer {...drawerProps}>
              <GeneralPoliciesAssignUsersDrawerPage />
            </GeneralPoliciesDrawer>
          </Route>
          <Route path={`${drawerPath}/edit-users/:configId`}>
            <GeneralPoliciesDrawer {...drawerProps}>
              <GeneralPoliciesUsersDrawerPage />
            </GeneralPoliciesDrawer>
          </Route>
          <Route path={drawerPath}>
            <GeneralPoliciesDrawer {...drawerProps}>
              <GeneralPoliciesDetailsDrawerPage />
            </GeneralPoliciesDrawer>
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserOperationJob } from '@lastpass/admin-console-dependencies/types/user-operation-job'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = { success }

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(userOperationJob: UserOperationJob) {
    const response = await fetch(
      'users/' + userOperationJob.job,
      'POST',
      userOperationJob.filter,
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    )

    if (response[1] === StatusCodes.ACCEPTED) {
      return success()
    }

    throw new UnknownServiceError()
  }
}

import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as ExternalLinkIcon } from '@lastpass/assets/svg/admin-console/adoption-dashboard/external-link.svg'
import { Loading } from '@lastpass/components'
import {
  Heading300,
  RowContainer,
  SingleColumnContentContainer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { SecurityDashboardData } from '@lastpass/admin-console-dependencies/state/dashboard/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import { MasterPasswordStrength } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/master-password-strength/MasterPasswordStrength'
import { SecurityScore } from '@lastpass/admin-console-dependencies/ui/home/new-security-dashboard/accordion-containers/security-score/SecurityScore'

const StyledTextButton = styled(TextButton)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  height: 18px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`

const initialState: SecurityDashboardData = {
  companySecurityScoreRate: 0,
  usersCountWithLowSecurityScore: 0,
  groupsCountWithLowSecurityScore: 0,
  usersWithStrongMasterPasswordRate: 0,
  usersCountWithMasterPassword: 0,
  usersCountWithStrongMasterPassword: 0,
  usersCountWithWeakOrReusedMasterPassword: 0,
  usersCountWithWeakMasterPassword: 0,
  usersCountWithReusedMasterPassword: 0
}

export const NewSecurityDashboardPageContent: FC = () => {
  const [
    {
      companySecurityScoreRate,
      usersCountWithLowSecurityScore,
      groupsCountWithLowSecurityScore,
      usersWithStrongMasterPasswordRate,
      usersCountWithMasterPassword,
      usersCountWithStrongMasterPassword,
      usersCountWithWeakOrReusedMasterPassword,
      usersCountWithWeakMasterPassword,
      usersCountWithReusedMasterPassword
    },
    setSecurityDashboardData
  ] = useState<SecurityDashboardData>(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const permissions = useContext(PermissionContext)
  const { getSecurityDashboardData } = useContext(ServerContext)

  const isGeneralReportsVisible = permissions.requirePermission(
    AdminPermissions.generalReportsView
  )

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const response = await getSecurityDashboardData()
        setSecurityDashboardData(response.body)
      } catch {
        dispatch(globalActions.setNotification(genericFailedNotification))
      }
      setIsLoading(false)
    })()
  }, [getSecurityDashboardData, dispatch])

  return (
    <SingleColumnContentContainer>
      {isLoading && <Loading color="blue900" active />}
      <Container>
        <RowContainer>
          <Heading300 data-qa="SecurityDashboardHeader">
            <Trans>Security</Trans>
          </Heading300>
          {isGeneralReportsVisible && (
            <LocationLink to="/reports/securityReport">
              <StyledTextButton blue>
                <Trans>More insights in Security reports</Trans>
                <StyledExternalLinkIcon />
              </StyledTextButton>
            </LocationLink>
          )}
        </RowContainer>
        <SecurityScore
          companySecurityScoreRate={companySecurityScoreRate}
          groupsCountWithLowSecurityScore={groupsCountWithLowSecurityScore}
          usersCountWithLowSecurityScore={usersCountWithLowSecurityScore}
        />
        <MasterPasswordStrength
          enrolledUsersCount={usersCountWithMasterPassword}
          usersCountWithStrongMasterPassword={
            usersCountWithStrongMasterPassword
          }
          usersCountWithWeakMasterPassword={usersCountWithWeakMasterPassword}
          usersCountWithReusedMasterPassword={
            usersCountWithReusedMasterPassword
          }
          usersCountWithWeakOrReusedMasterPassword={
            usersCountWithWeakOrReusedMasterPassword
          }
          usersWithStrongMasterPasswordRate={usersWithStrongMasterPasswordRate}
        />
      </Container>
    </SingleColumnContentContainer>
  )
}

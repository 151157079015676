import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as GroupsIcon } from '@lastpass/assets/svg/admin-console/icon-groups.svg'
import { SecurityScoreBadge } from '@lastpass/lastkit'
import {
  DrawerTable,
  Pagination,
  RowContainer,
  SearchInput,
  TableView,
  useDrawerSubtitle
} from '@lastpass/lastkit/components'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { getSecurityScore } from '@lastpass/admin-console-dependencies/services/seurity-score'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import { Group } from '@lastpass/admin-console-dependencies/types/group'

type UserDrawerProps = LastPassComponentProps<
  UsersDrawerState['groups'],
  typeof userDrawerActions
>

const StyledGroupIcon = styled(GroupsIcon)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

export const UserDrawerGroupsTableComponent: React.FunctionComponent<UserDrawerProps> = ({
  state: groups
}) => {
  const queryParams = useQueryParams(Namespace.groups)
  const updateQuery = useUpdateQuery(Namespace.groups)

  const pathParams = usePathParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      userDrawerActions.getUserGroups({
        query: queryParams,
        path: pathParams
      })
    )
  }, [dispatch, pathParams, queryParams])

  useDrawerSubtitle(<Trans>Groups</Trans>)
  const [searchQuery, setSearchQuery] = useState('')

  if (groups) {
    const filteredGroups = {
      ...groups,
      results: groups.results.filter(
        group =>
          group.name
            .toLocaleLowerCase()
            .indexOf(searchQuery.toLocaleLowerCase()) >= 0
      )
    }

    return (
      <>
        <RowContainer>
          <SearchInput
            flexGrow
            value={searchQuery}
            placeholder={msg`Search Groups`}
            onChange={e => setSearchQuery(e.target.value)}
            data-qa={'SearchGroupsInputField'}
          />
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={groups.totalResults}
            resultsPerPage={groups.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </RowContainer>
        <DrawerTable
          qadata={'UserLevelFullGroupsList'}
          table={filteredGroups}
          columns={[
            {
              name: <></>,
              component: StyledGroupIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Name</Trans>,
              renderer: (record: Group) => record.name
            },
            {
              name: <Trans>Users</Trans>,
              renderer: (record: Group) => record.userCount.toString()
            },
            {
              name: <Trans>Security score</Trans>,
              renderer: function DrawerTableSecurityScoreRenderer(
                record: Group
              ) {
                return (
                  <SecurityScoreBadge
                    level={getSecurityScore(record.securityScore)}
                  />
                )
              }
            }
          ]}
          noRecordsView={
            <TableView
              icon={<GroupsIcon />}
              title={<Trans>This user doesn’t belong to any groups</Trans>}
              text={
                <Trans>
                  Manage users in groups to bring your LastPass account under
                  your control
                </Trans>
              }
            />
          }
          noResultsView={
            <TableView
              icon={<GroupsIcon />}
              title={<Trans>Sorry, no results match your search</Trans>}
              text={
                <Trans>Try different keywords or check your spelling</Trans>
              }
            />
          }
          isFilterApplied={!!queryParams.search}
        />
      </>
    )
  }
  return null
}

export function createUserDrawerGroupsTable<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserDrawerGroupsTableComponent,
    (state: TState) => state.usersDrawer.groups,
    userDrawerActions
  )
}

export type UserDrawerGroupsTable = ReturnType<
  typeof createUserDrawerGroupsTable
>

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createGoogleSyncUsers(
  enterpriseOptionServices: UACServices.Services
) {
  return function* googleSyncUsers() {
    try {
      const googleProvisioningResult: UACServices.GoogleSyncUsersAPI.Responses = yield call(
        enterpriseOptionServices.googleSyncUsers
      )

      if (
        googleProvisioningResult.type === UACServices.GoogleSyncUsersAPI.SUCCESS
      ) {
        yield put(
          globalActions.setNotification({
            message: msg`User sync successful`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
      } else if (
        googleProvisioningResult.type ===
        UACServices.GoogleSyncUsersAPI.SYNC_IN_PROGRESS
      ) {
        yield put(
          globalActions.setNotification({
            message: msg`Sync already in progress. Try again when the current sync has completed.`,
            type: NotificationType.alert,
            autoDismiss: true
          })
        )
      } else {
        yield put(
          globalActions.setNotification({
            message: msg`User sync unsuccessful`,
            type: NotificationType.alert,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(directoriesActions.syncFinished())
    }
  }
}

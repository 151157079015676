import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { LoginReportEvent } from '@lastpass/admin-console-dependencies/types/login-report'

export enum LoginReportActionTypes {
  REPORT_GET_LOGIN_REPORT = '[reports] login-report-get',
  REPORT_SET_LOGIN_REPORT = '[reports] login-report-set',
  REPORT_SET_LOGIN_REPORT_LOADING = '[reports] login-report-loading-set'
}

export const loginReportActions = {
  getLoginReports: () =>
    createAction(LoginReportActionTypes.REPORT_GET_LOGIN_REPORT),

  setLoginReport: (events: LoginReportEvent[]) =>
    createAction(LoginReportActionTypes.REPORT_SET_LOGIN_REPORT, {
      events
    }),

  setLoginReportLoading: (isLoading: boolean) =>
    createAction(LoginReportActionTypes.REPORT_SET_LOGIN_REPORT_LOADING, {
      isLoading
    })
}

export interface LoginReportQueryParams {
  search: string
  skip: number
  eventType: string
  between: { from: string; to: string }
}

export type AdminActivityActions = ActionsUnion<typeof loginReportActions>

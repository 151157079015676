import { NeverOnlyUrlActions, NeverOnlyUrlActionTypes } from './actions'
import { NeverOnlyUrlsState } from './state'

export const initialState: NeverOnlyUrlsState = {
  neverOnlyUrls: { neverUrls: '', onlyUrls: '' },
  isLoading: true
}

export const neverOnlyUrls = (
  previousState: NeverOnlyUrlsState | undefined,
  action: NeverOnlyUrlActions
): NeverOnlyUrlsState => {
  const state = previousState || initialState
  switch (action.type) {
    case NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_LOADING_SET:
      return { ...state, isLoading: action.payload.isLoading }
    case NeverOnlyUrlActionTypes.NEVER_ONLY_URLS_SET:
      return {
        ...state,
        isLoading: false,
        neverOnlyUrls: action.payload.neverOnlyUrls
      }
    default:
      return state
  }
}

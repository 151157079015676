import React, { FunctionComponent, ReactElement, useEffect } from 'react'

import styled from '@emotion/styled'
import { i18n, MessageDescriptor } from '@lingui/core'

import { ReactComponent as CloseIcon } from '@lastpass/assets/svg/admin-console/icon-close.svg'

import { BodyRegularStyle } from '../styles/body-text'
import { Heading200 } from './Heading'
import { RowContainer } from './Layout'

export interface DetailsDialogProps {
  closeDialog: () => void
  title?: MessageDescriptor | string
  titleColor?: string
  subTitle?: MessageDescriptor
  content?: ReactElement
  footer?: ReactElement
  superFooter?: ReactElement
  width?: string
  buttons?: ReactElement[]
  disableMaxHeight?: boolean
  hideCloseIcon?: boolean
  disableCloseOnBackground?: boolean
  headerExtension?: ReactElement
}

const Background = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  /* e0 is for setting 0.88 opacity using HEX color */
  background: ${props => props.theme.colors.neutral900 + 'e0'};
  justify-content: center;
  overflow: overlay;
`

const Popup = styled.div<{ width: string; disableMaxHeight: boolean }>`
  max-width: ${props => props.width};
  ${props => !props.disableMaxHeight && `max-height: 400px`};
  overflow-x: auto;
  background: ${props => props.theme.colors.white};
  margin: 80px auto;
  height: auto;
  box-shadow: ${props => props.theme.elevations.elevation300};
  border-radius: ${props => props.theme.radius.pixel16};
  padding: 24px;
  color: ${props => props.theme.colors.neutral900};
  position: relative;
`

const StyledRowContainer = styled(RowContainer)`
  margin-bottom: 24px;
`

const HeaderContainerLeftContent = styled.div`
  display: flex;
  align-items: center;
`

const StyledTitle = styled(Heading200)<{ titleColor?: string }>`
  color: ${props =>
    props.titleColor
      ? props.theme.colors[props.titleColor]
      : props.theme.colors.neutral900};
`

const HeaderExtensionContainer = styled.div`
  margin-left: 8px;
`

const StyledSubtitle = styled.span<{ hasMargin: boolean }>`
  ${BodyRegularStyle};
  display: block;
  margin-bottom: ${props => (props.hasMargin ? '24px' : 'none')};
`

const CloseIconContainer = styled.div`
  width: 32px;
  height: 32px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.neutral700};

  &:hover {
    color: ${props => props.theme.colors.neutral900};
  }
`

export const DialogFullWidthContainer = styled.div`
  margin: 0px -24px;
`

const FooterContainer = styled.div`
  margin-top: 24px;
`

const FooterButtonContainer = styled(FooterContainer)`
  display: flex;
  justify-content: flex-end;
`

const Spacer = styled.div`
  width: 16px;
`

export const DetailsDialog: FunctionComponent<DetailsDialogProps> = ({
  ...props
}) => {
  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        props.closeDialog()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [props])

  return (
    <Background
      data-qa="PopUpBackground"
      onMouseDown={() => !props.disableCloseOnBackground && props.closeDialog()}
    >
      <Popup
        onMouseDown={e => e.stopPropagation()}
        width={props.width ? props.width : '800px'}
        disableMaxHeight={!!props.disableMaxHeight}
        data-qa={'PopUpContainer'}
      >
        {(props.title || !props.hideCloseIcon) && (
          <StyledRowContainer data-qa="PopUpRowContainer">
            <HeaderContainerLeftContent>
              <StyledTitle data-qa="PopUpHeader" titleColor={props.titleColor}>
                {typeof props.title === 'string'
                  ? props.title
                  : !!props.title && i18n._(props.title)}
              </StyledTitle>
              <HeaderExtensionContainer>
                {props.headerExtension}
              </HeaderExtensionContainer>
            </HeaderContainerLeftContent>
            {!props.hideCloseIcon && (
              <CloseIconContainer
                onClick={() => props.closeDialog()}
                data-qa="PopUpCloseButton"
              >
                <StyledCloseIcon />
              </CloseIconContainer>
            )}
          </StyledRowContainer>
        )}
        {!!props.subTitle && (
          <StyledSubtitle
            hasMargin={Boolean(props.content)}
            data-qa={'PopUpContent'}
          >
            {i18n._(props.subTitle)}
          </StyledSubtitle>
        )}
        {!!props.content && <div>{props.content}</div>}
        {!!props.footer && <FooterContainer>{props.footer}</FooterContainer>}
        {props.buttons && (
          <FooterButtonContainer data-qa={'PopUpButtons'}>
            {props.buttons.map((button, index) => (
              <React.Fragment key={'fragment' + index}>
                <Spacer />
                {button}
              </React.Fragment>
            ))}
          </FooterButtonContainer>
        )}
        {!!props.superFooter && (
          <FooterContainer>{props.superFooter}</FooterContainer>
        )}
      </Popup>
    </Background>
  )
}

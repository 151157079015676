import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'

export const SUCCESS = 'success'

export interface MasterPasswordValidationData {
  encryptedPrivateRsaKey: string
  requesterAdminIterationCount: number
}

const success = (masterPasswordValidationData: MasterPasswordValidationData) =>
  createResponse(SUCCESS, masterPasswordValidationData)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch(
      'users/current/private-rsa-sharing-key',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const masterPasswordValidationData: MasterPasswordValidationData = JSON.parse(
        response
      ).content
      return success(masterPasswordValidationData)
    }
    throw new UnknownServiceError()
  }
}

import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import * as UACServices from '@lastpass/admin-console-dependencies/server/index'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { Table as TableType } from '@lastpass/admin-console-dependencies/types/table'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export const useFetchFilteredUsersTable = (
  odataQuery: string,
  resultsPerPage: number
): { table: TableType<User>; isRequestPending: boolean } => {
  const dispatch = useDispatch()
  const { users: fetchUsers } = useContext(ServerContext)
  const [isRequestPending, setIsRequestPending] = useState<boolean>(true)
  const [table, setTable] = useState<TableType<User>>({
    totalResults: 0,
    results: [],
    resultsPerPage,
    checkedRecords: [],
    isLoading: true
  })

  const fetchFilteredUsers = useCallback(async (): Promise<void> => {
    try {
      setIsRequestPending(true)
      const result = await fetchUsers(odataQuery)

      if (result.type === UACServices.UsersAPI.SUCCESS) {
        setTable({
          totalResults: result.body.totalResults,
          results: result.body.userList,
          resultsPerPage,
          checkedRecords: [],
          isLoading: false
        })
      }
    } catch (e) {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      setIsRequestPending(false)
    }
  }, [dispatch, fetchUsers, odataQuery, resultsPerPage])

  useEffect(() => {
    fetchFilteredUsers()
  }, [fetchFilteredUsers])

  return {
    table,
    isRequestPending
  }
}

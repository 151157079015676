import React, { FunctionComponent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'

import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as SharedFolderAddApps } from '@lastpass/assets/svg/admin-console/applications/shared-folder-add-apps.svg'
import { ReactComponent as SharedFolderAddUsers } from '@lastpass/assets/svg/admin-console/applications/shared-folder-add-users.svg'
import { ReactComponent as SharedFolderVault } from '@lastpass/assets/svg/admin-console/applications/shared-folder-vault.svg'
import {
  BodyRegular,
  DetailsDialog,
  Heading100,
  PrimaryButton
} from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  SharedFolderDialog,
  SharedFolderDialogueType
} from '@lastpass/admin-console-dependencies/types/dialog-types'

interface SharedFolderDialogProps {
  state: SharedFolderDialog
  actions: typeof globalActions
}

const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSharedFolderVault = styled(SharedFolderVault)`
  margin-bottom: 44px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 20px;
  width: 100%;
`

const StyledBodyRegular = styled(BodyRegular)`
  width: 100%;
  margin-bottom: 20px;
`

export const SharedFolderDialogComponent: FunctionComponent<SharedFolderDialogProps> = ({
  state,
  actions
}) => {
  const confirmText = i18n._(state.confirmText)
  const mainText = i18n._(state.mainText)
  const secondaryText = i18n._(state.secondaryText)
  const learnMoreText = i18n._(state.learnMoreText)

  return (
    <DetailsDialog
      disableMaxHeight
      closeDialog={() => {
        actions.emptyDialog()
      }}
      width={'480px'}
      content={
        <StyledCenter>
          <StyledHeading100>{mainText}</StyledHeading100>
          <StyledBodyRegular>{secondaryText}</StyledBodyRegular>
          <StyledBodyRegular
            onClick={() => {
              window.open(
                'https://support.logmeininc.com/lastpass/help/manage-lastpass-enterprise-shared-folders-users-lp010053'
              )
            }}
            css={css`
              cursor: pointer;
            `}
          >
            {learnMoreText}
          </StyledBodyRegular>
          {state.shareType === SharedFolderDialogueType.create && (
            <StyledSharedFolderVault />
          )}
          {state.shareType === SharedFolderDialogueType.apps && (
            <SharedFolderAddApps />
          )}
          {state.shareType === SharedFolderDialogueType.users && (
            <SharedFolderAddUsers />
          )}
        </StyledCenter>
      }
      buttons={[
        <PrimaryButton
          onClick={() => {
            window.open(
              `${defaultConfig.lastPassBaseAddress}/home.php?` +
                state.lastpassQueryParams
            )
          }}
          key={'confirm'}
        >
          {confirmText}
        </PrimaryButton>
      ]}
    />
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegular } from '@lastpass/lastkit'

const Background = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Content = styled.p`
  display: flex;
  background: ${props => props.theme.colors.yellow600};
  justify-content: center;
  padding: 9px 0;
  text-align: center;
`

export const UrlLoggingPolicyBanner: React.FunctionComponent = () => (
  <Background data-qa="UrlLoggingPolicyBanner">
    <Content>
      <BodyRegular>
        <Trans>
          Action required: To continue logging URLs in General reports and SIEM
          after 10 June 2024, enable the “Log URLs in General reports and SIEM”
          policy.
        </Trans>
      </BodyRegular>
    </Content>
  </Background>
)

import { matchPath } from 'react-router'

import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { Responses as MfaResponses } from '@lastpass/admin-console-dependencies/server/applications/mfa/get-mfa-application-list'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { Responses as AdminCurrentResponses } from '@lastpass/admin-console-dependencies/server/users/admin/admin-current-permissions'
import { companyHasMfa } from '@lastpass/admin-console-dependencies/services/get-company-entitlements'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { mfaApplicationListActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'
import {
  MfaApplication,
  MfaApplicationType
} from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { mapMfaApplicationListResponse } from '@lastpass/admin-console-dependencies/ui/common/mappings/mfaapp-mappers'

export const getCompanyDetails = (state: AppState) => state.companyInfo.details

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createMfaApplicationList(
  applicationsServices: UACServices.Services
) {
  return function* getMfaApplicationList(
    action: ReturnType<typeof mfaApplicationListActions.getMfaApplicationList>
  ) {
    try {
      const searchQuery = action.payload.params.query.search

      yield put(mfaApplicationListActions.setMfaApplicationsLoading(true))
      yield put(mfaAppsDrawerActions.setDrawerLoading(true))

      const companyDetails: CompanyDetails = yield select(getCompanyDetails)

      const adminCurrentPermissions: AdminCurrentResponses = yield call(
        applicationsServices.adminCurrentPermissions
      )

      const canViewMfaApps = adminCurrentPermissions.body.includes(
        AdminPermissions.mfaModify
      )

      try {
        if (companyHasMfa(companyDetails) && canViewMfaApps) {
          const mfaApplicationListResult: MfaResponses = yield call(
            applicationsServices.getMfaApplicationsList
          )

          if (
            mfaApplicationListResult.type ===
            UACServices.MfaApplicationsListGetAPI.SUCCESS
          ) {
            const apps: MfaApplication[] = mapMfaApplicationListResponse(
              mfaApplicationListResult.body.mfaAppListResponse.content
            )

            let filteredApps: MfaApplication[] = apps.filter(
              app => app.type !== MfaApplicationType.undefined
            )

            if (searchQuery) {
              filteredApps = filteredApps.filter(app =>
                app.name
                  .toLocaleLowerCase()
                  .includes(searchQuery.toLocaleLowerCase())
              )
            }

            if (apps && apps.length > 0) {
              const hasAdApp = apps.some(
                app => app && app.type === MfaApplicationType.azureAD
              )

              if (hasAdApp)
                yield put(mfaApplicationListActions.setUsedAzureAdApps(true))
              else
                yield put(mfaApplicationListActions.setUsedAzureAdApps(false))

              const hasAdfsApp = apps.some(
                app => app && app.type === MfaApplicationType.adfs
              )

              if (hasAdfsApp)
                yield put(mfaApplicationListActions.setUsedAdfsApps(true))
              else yield put(mfaApplicationListActions.setUsedAdfsApps(false))
            }

            yield put(
              mfaApplicationListActions.setMfaApplicationList(
                filteredApps,
                filteredApps.length
              )
            )

            if (matchPath(location.pathname, `/applications/mfa/:id`)) {
              const key = location.pathname.split('/').pop()
              const mfaApp = filteredApps.find(a => a.key == key)
              if (mfaApp) yield put(mfaAppsDrawerActions.setApp(mfaApp))
            }
          }
        }
      } finally {
        yield put(mfaApplicationListActions.setMfaApplicationsLoading(false))
        yield put(mfaAppsDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components'
import { Drawer } from '@lastpass/lastkit/components/Drawer'
import { BodyRegularStyle, BodySemiboldStyle } from '@lastpass/lastkit/styles'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'

export interface AddUsersDrawerProps {
  closeLink: string
  backButton?: ReactElement
  childTitle?: ReactElement
  moreButton?: ReactElement
}

const StyledExternalLink = styled.a`
  ${BodySemiboldStyle}
  color: ${props => props.theme.colors.blue700};
  margin-left: 8px;
`

const LicenseWrapper = styled.span<{
  licenses: number | undefined
  totalLicenses: number | undefined
}>`
  ${BodyRegularStyle}
  color: ${props =>
    props.licenses &&
    !(props.licenses === 0 && props.totalLicenses === 0) &&
    props.licenses < 10 &&
    props.theme.colors.red700};
`

export const AddUsersDrawer: React.FunctionComponent<AddUsersDrawerProps> = ({
  closeLink,
  backButton,
  children
}) => {
  const queryParams = useQueryParams(Namespace.users)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(companyInfoActions.getCompanyLicenses())
    dispatch(userListActions.getUserList({ query: queryParams, path: {} }))
  }, [dispatch, queryParams])

  const companyInfoState: CompanyInfoState = useSelector(
    (state: AppState) => state.companyInfo
  )
  const miscTitle = (
    <>
      <LicenseWrapper
        licenses={companyInfoState.remainingLicenses}
        totalLicenses={companyInfoState.totalLicenses}
        data-qa="AddUserRemainingLicence"
      >
        <Trans>
          Licenses: {companyInfoState.totalLicenses}. Remaining:{' '}
          {companyInfoState.remainingLicenses}.
        </Trans>
      </LicenseWrapper>
      {companyInfoState.details.canBuyLicenses && (
        <StyledExternalLink
          href={`${defaultConfig.lastPassBaseAddress}/enterprisepayment.php`}
          target="_blank"
          data-qa="AddUsersBuyLicence"
        >
          <Trans>Buy more licenses</Trans>
        </StyledExternalLink>
      )}
    </>
  )

  return (
    <Drawer
      mainTitle={<Trans>Add users</Trans>}
      miscTitle={
        !companyInfoState.details.isBillingUsageBased ? miscTitle : undefined
      }
      closeLink={closeLink}
      headerNodes={<> {backButton}</>}
    >
      {companyInfoState.remainingLicenses === undefined &&
        companyInfoState.totalLicenses === undefined && (
          <Loading color="blue900" active={true} />
        )}
      {children}
    </Drawer>
  )
}

import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { BodyRegularStyle, Heading100 } from '@lastpass/lastkit'
import { CodeExample } from '@lastpass/lastkit/components/CodeExample'

const Title = styled.div`
  ${Heading100};
  margin-bottom: 8px;
`

const TextContent = styled.div`
  ${BodyRegularStyle}
`

const Bold = styled.span`
  font-weight: ${props => props.theme.fonts.weight.semibold};
`

export const ResponseError: React.FunctionComponent = () => {
  return (
    <>
      <Title>
        <Trans>URL</Trans>
      </Title>
      <TextContent
        css={css`
          margin-bottom: 12px;
        `}
      >
        <Trans>
          All commands return a response that is either the requested data or a
          status. For example, data for {"'"}getuserdata{"'"}, or a status for
          {"'"}batchadd{"'"}.
        </Trans>
      </TextContent>
      <TextContent
        css={css`
          margin-bottom: 12px;
        `}
      >
        <Trans>
          For a status, the response contains a {"'"}status{"'"} field and, if
          errors occur, an {"'"}errors{"'"} field containing an array of
          problematic strings
        </Trans>
      </TextContent>
      <TextContent
        css={css`
          margin-bottom: 12px;
        `}
      >
        <Bold>{'OK'}</Bold>{' '}
        <Trans> - Indicates all operations were successful.</Trans>
        <br />
        <Bold>{'WARN'}</Bold>
        <Trans>
          - Indicates that some operations were successful but some errors were
          encountered.
        </Trans>
        <br />
        <Bold>{'FAIL'}</Bold>
        <Trans> - Indicates all operations failed.</Trans>
        <br />
      </TextContent>
      <CodeExample
        code={`{
    "status": "WARN",
    "errors": [
        "userdomain.com is not a valid email address"
    ]
}`}
        allowCopyToClipboard={true}
      />
    </>
  )
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { PsaCredentialsState } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'

export const SUCCESS = 'success'

export interface PsaCredentialsResponse {
  metadata: PsaCredentialsState[]
}

const success = (credentials: PsaCredentialsResponse) =>
  createResponse(SUCCESS, credentials)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        `v3/psa-credentials/metadata`,
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        return success(JSON.parse(response).content)
      }
    } catch {
      throw new UnknownServiceError()
    }
  }
}

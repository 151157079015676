import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { TabNavigation } from '@lastpass/lastkit'
import { RoutedTabNavigationItem } from '@lastpass/lastkit'

import { SharedFolderScoresList } from '@lastpass/admin-console-dependencies/ui/shared-folders/SharedFolderScoresList'
import { createSharedFolderSitesList } from '@lastpass/admin-console-dependencies/ui/shared-folders/SharedFolderSitesList'
import { createSharedFolderUsersList } from '@lastpass/admin-console-dependencies/ui/shared-folders/SharedFolderUsersList'

const SharedFolderUsersList = createSharedFolderUsersList<AppState>()
const SharedFolderSitesList = createSharedFolderSitesList<AppState>()

export interface SharedFoldersDrawerPageProps {
  isCidOverrideMode: boolean
}

export const SharedFoldersDrawerPage: React.FunctionComponent<SharedFoldersDrawerPageProps> = ({
  isCidOverrideMode
}) => {
  const match = useRouteMatch()

  if (match) {
    const { path, url } = match

    return (
      <>
        <Route
          path={[`${path}`, `${path}/security-scores`, `${path}/apps`]}
          exact
        >
          <TabNavigation>
            <RoutedTabNavigationItem
              to={`${url}`}
              route={{ path: `${url}`, exact: true }}
            >
              <Trans>Users</Trans>
            </RoutedTabNavigationItem>
            <RoutedTabNavigationItem
              to={`${url}/security-scores`}
              route={{ path: `${url}/security-scores` }}
            >
              <Trans>Security Scores</Trans>
            </RoutedTabNavigationItem>
            <RoutedTabNavigationItem
              to={`${url}/apps`}
              route={{ path: `${url}/apps` }}
            >
              <Trans>Apps</Trans>
            </RoutedTabNavigationItem>
          </TabNavigation>
        </Route>
        <Switch>
          <Route path={`${path}`} exact>
            <SharedFolderUsersList isCidOverrideMode={isCidOverrideMode} />
          </Route>
          <Route path={`${path}/security-scores`} exact>
            <SharedFolderScoresList />
          </Route>
          <Route path={`${path}/apps`} exact>
            <SharedFolderSitesList isCidOverrideMode={isCidOverrideMode} />
          </Route>
        </Switch>
      </>
    )
  }
  return null
}

export interface MfaApp {
  applicationName: string
  integrationKey: string
  integrationSecret: string
  mappingKey: string
  allUser: boolean
  userIds: string[]
  groupIds: string[]
}

export const initialState: MfaApp = {
  applicationName: '',
  integrationKey: '',
  integrationSecret: '',
  mappingKey: 'email',
  allUser: true,
  userIds: [],
  groupIds: []
}

export interface MfaAppListResponseMap {
  content: MfaApp[]
}

import { msg } from '@lingui/macro'
import { put } from 'redux-saga-test-plan/matchers'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

const ssoAppLimitReachedErrorCode = 'PaymentRequired'
export const addSsoAppLimitReachedNotification: NotificationProps = {
  type: NotificationType.alert,
  autoDismiss: false,
  details: undefined,
  message: msg`You’ve reached your 3 app limit. Get Advanced SSO for more.`,
  genericError: true
}

export function* processFailedResult(errorCode) {
  errorCode === ssoAppLimitReachedErrorCode
    ? yield put(
        globalActions.setNotification(addSsoAppLimitReachedNotification)
      )
    : yield put(globalActions.setNotification(genericFailedNotification))
}

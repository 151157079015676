import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  ButtonsContainer,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'

import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import {
  FormValues,
  PsaCredentialsState,
  PsaEditCredentialsFormValues,
  PsaProviderDetailsProperties
} from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { EditPsaField } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/edit/EditPsaField'

const ButtonSpacing = css`
  margin-right: 16px;
`

const StyledTextButton = styled(TextButton)`
  ${ButtonSpacing}
`

const StyledPrimaryButton = styled(PrimaryButton)`
  ${ButtonSpacing}
`

export interface EditPsaFormProps {
  closeLink: string
}

export const EditPsaForm: FunctionComponent<EditPsaFormProps> = ({
  closeLink
}) => {
  const { metadata, activePsa, psaEditCredentialsFormValues } = useSelector(
    (state: AppState) => state.psaIntegrations
  )
  const activeProvider = activePsa ? activePsa.provider : ''
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [initialState, setInitialState] = useState<
    PsaEditCredentialsFormValues
  >({})

  useEffect(() => {
    /* Creates an initial state for the form with the active PSA fields and values */
    const initialState = metadata.reduce(
      (acc: PsaEditCredentialsFormValues, psa) => ({
        ...acc,
        [psa.provider]: psa.credentials.reduce((fieldAcc, field) => {
          if (!activePsa || psa.provider !== activePsa.provider) {
            return fieldAcc
          }

          const activePsaCredential = activePsa.credentials.find(
            cred => cred.name === field.name
          )

          return {
            ...fieldAcc,
            [field.name]:
              (activePsaCredential && activePsaCredential.value) || ''
          }
        }, {})
      }),
      {}
    )

    setInitialState(initialState)
  }, [activePsa, metadata])

  const handleFormSubmit = (values: FormValues | null) => {
    if (values) {
      const current = metadata.find(({ provider }) => provider === id)
      const passwordTypeFormValue =
        current &&
        current.credentials
          .filter(({ type }) => type === 'password')
          .map(pwtypes => pwtypes.name)

      const formValuesWithoutPasswords = Object.keys(values).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]:
            passwordTypeFormValue && passwordTypeFormValue.includes(curr)
              ? ''
              : values[curr]
        }),
        {}
      )

      const credentials = Object.keys(values).reduce(
        (acc: PsaProviderDetailsProperties[], key: string) => [
          ...acc,
          { name: key, value: values[key] }
        ],
        []
      )

      dispatch(
        psaIntegrationsActions.setPsaEditCredentialsFormValues(
          formValuesWithoutPasswords,
          id
        )
      )

      dispatch(
        psaIntegrationsActions.savePsaCredentials({
          provider: id,
          credentials,
          modules: null
        })
      )
    }
  }

  const handleDiscard = () => {
    dispatch(psaIntegrationsActions.setPsaEditCredentialsFormValues(null, id))
    history.push(closeLink)
  }

  const getYupSchema = (provider: PsaCredentialsState | undefined) => {
    if (!provider) {
      return Yup.object().shape({})
    }

    const yupShape = {}
    provider.credentials.forEach(({ name, type, isMandatory }) => {
      let fieldShape = Yup.string().trim()

      if (type === 'url') {
        fieldShape = fieldShape.matches(
          /^https:\/\/[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
          i18n._(msg`Invalid URL`)
        )
      }

      yupShape[name] = isMandatory
        ? fieldShape.required(' ')
        : fieldShape.notRequired()
    })

    return Yup.object().shape(yupShape)
  }

  const current = metadata.find(c => c.provider === id)
  const yupSchema = getYupSchema(current)
  return (
    <Formik
      enableReinitialize
      initialValues={
        (psaEditCredentialsFormValues && psaEditCredentialsFormValues[id]
          ? psaEditCredentialsFormValues[id]
          : initialState[id]) || {}
      }
      validationSchema={yupSchema}
      onSubmit={values => handleFormSubmit(values)}
    >
      {(formik: FormikProps<FormValues | null>) => {
        return (
          <Form data-qa={`${activeProvider}CredentialsForm`}>
            {current &&
              current.credentials.map(({ name, type, isMandatory }) => (
                <EditPsaField
                  key={name}
                  name={name}
                  type={type}
                  isMandatory={isMandatory}
                  formik={formik}
                />
              ))}

            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <StyledTextButton
                  data-qa="DiscardButton"
                  type="button"
                  onClick={handleDiscard}
                >
                  <Trans>Discard</Trans>
                </StyledTextButton>

                <StyledPrimaryButton
                  data-qa="TestConnectionAndSaveButton"
                  type="submit"
                  disabled={!current || !formik.dirty || !formik.isValid}
                >
                  <Trans>Test connection and save</Trans>
                </StyledPrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

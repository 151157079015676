import React, { useState } from 'react'

import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { ReactComponent as RemoveItemIcon } from '@lastpass/assets/svg/admin-console/icon-trash.svg'
import { Select, TextInput } from '@lastpass/lastkit/components'
import { BodySemiboldStyle } from '@lastpass/lastkit/styles/body-text'

const StyledLabel = styled.label<{ fluid?: boolean }>`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

const StyledSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CustomTextInput = styled(TextInput)`
  width: 50%;
`

const ControlContainer = styled.div`
  padding-top: 12px;
`

const StyledRemoveItem = styled.div`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  :hover {
    cursor: pointer;
  }
`

export const CustomAttributeConstantValue = 'Constant value'

const EvaluteSelectValue = (selectValue, customAttributes) => {
  if (selectValue && selectValue.AttributeId) {
    return {
      value: selectValue.AttributeId,
      label: customAttributes.find(i => i.value === selectValue.AttributeId)
        .label
    }
  }

  if (selectValue && selectValue.Type) {
    return {
      value: selectValue.Type,
      label: customAttributes.find(i => i.value === selectValue.Type).label
    }
  }
  return { value: '', label: '' }
}

export const CustomAttributesControl = ({
  label,
  customAttributes,
  value,
  onDeleteCustomAttribute,
  onUpdateValue
}) => {
  const [selectValue, setSelectValue] = useState(value ? value : {})
  const type = EvaluteSelectValue(selectValue, customAttributes).label

  return (
    <ControlContainer>
      <StyledLabel data-qa="AttributeLabel">
        <Trans>{label}</Trans>
      </StyledLabel>
      <StyledSelect>
        <Select
          options={customAttributes}
          isSearchable
          dataQa="customAttributeSelect"
          fluid
          onChange={option => {
            if (option) {
              const s = { ...selectValue }
              const ca = customAttributes.find(i => i.value === option['value'])

              s.Type = ca && ca.serverAttribute ? undefined : option['value']
              s.AttributeId =
                ca && ca.serverAttribute ? option['value'] : undefined

              setSelectValue(s)
              onUpdateValue(s)
            }
          }}
          value={EvaluteSelectValue(selectValue, customAttributes)}
        />
        <StyledRemoveItem
          data-qa="RemoveAttribute"
          onClick={onDeleteCustomAttribute}
        >
          <RemoveItemIcon />
        </StyledRemoveItem>
      </StyledSelect>
      {(selectValue.Type || selectValue.AttributeId) && (
        <ControlContainer>
          <CustomTextInput
            data-qa={'customAttributeName'}
            value={selectValue.Name}
            onBlur={e => {
              const s = { ...selectValue }
              s.Name = e.target.value
              setSelectValue(s)
              onUpdateValue(s)
            }}
          >
            {i18n._(msg`SAML Attribute name (${type})`)}
          </CustomTextInput>
          {selectValue.Type === CustomAttributeConstantValue && (
            <CustomTextInput
              data-qa={'customAttributeInputConstant'}
              key={'customAttributeInputConstant'}
              value={selectValue.ConstantValue}
              onBlur={e => {
                const s = { ...selectValue }
                s.ConstantValue = e.target.value
                setSelectValue(s)
                onUpdateValue(s)
              }}
            >
              <Trans>Constant Value</Trans>
            </CustomTextInput>
          )}
        </ControlContainer>
      )}
    </ControlContainer>
  )
}

import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as DevicesIcon } from '@lastpass/assets/svg/admin-console/icon-devices.svg'
import { ReactComponent as PlaceholderIcon } from '@lastpass/assets/svg/admin-console/icon-placeholder.svg'
import {
  CardTable,
  Collapsible,
  TableView,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import {
  userDrawerActions,
  UserDrawerUrlParams
} from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'
import { UsersDrawerState } from '@lastpass/admin-console-dependencies/state/users/view/drawer/state'
import {
  TrustedDevice,
  TrustedDeviceType
} from '@lastpass/admin-console-dependencies/types/trusted-device'
import { TrustedDeviceTypeMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/TrustedDeviceTypeMapping'

export type UserTrustedDevicesCardProps = LastPassComponentProps<
  UsersDrawerState['trustedDevices'],
  typeof userDrawerActions,
  {
    detailLink: string
    trustedDevicesLink: string
    expand: boolean
    onExpand: Function
  }
>

const StyledPlaceholderIcon = styled(PlaceholderIcon)`
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
`

const StyledLocationLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

export const UserTrustedDevicesSummaryCardComponent: React.FunctionComponent<UserTrustedDevicesCardProps> = ({
  state: trustedDevices,
  props,
  actions
}) => {
  const pathParams: UserDrawerUrlParams = usePathParams()

  return (
    <Collapsible
      title={<Trans>Registered devices</Trans>}
      expanded={props.expand}
      onInitialExpand={() => {
        actions.getUserTrustedDevices({ path: pathParams, query: {} })
      }}
      loading={!trustedDevices.all}
      onExpand={props.onExpand}
      qadata={'RegisteredDevicesCard'}
    >
      {trustedDevices.all && (
        <CardTable
          qadata={'UserLevelRegisteredDevicesList'}
          table={trustedDevices.all}
          columns={[
            {
              name: <></>,
              component: StyledPlaceholderIcon,
              contentWidth: 'auto'
            },
            {
              name: <Trans>Device</Trans>,
              renderer: (record: TrustedDevice) => record.device
            },
            {
              name: <Trans>Type</Trans>,
              // eslint-disable-next-line react/display-name
              renderer: (record: TrustedDevice) =>
                TrustedDeviceTypeMapping[record.type] || <Trans>Unknown</Trans>
            },
            {
              name: <Trans>Status</Trans>,
              renderer: function StatusRenderer(record: TrustedDevice) {
                if (record.type == TrustedDeviceType.workstations) {
                  return <Trans>Active</Trans>
                }
                if (record.type == TrustedDeviceType.lastPassApplications) {
                  return record.status ? (
                    <Trans>Enabled</Trans>
                  ) : (
                    <Trans>Disabled</Trans>
                  )
                }
                return record.status ? (
                  <Trans>Unlocked</Trans>
                ) : (
                  <Trans>Locked</Trans>
                )
              }
            }
          ]}
          footerNodes={
            <>
              {/* <TextButton blue>
                <StyledLink
                  to={props.trustedDevicesLink}
                  data-qa={'GoToRegisteredDevicesPageButton'}
                >
                  <Trans>Go to Registered Devices page</Trans>
                </StyledLink>
              </TextButton> */}
              <TextButton blue>
                <StyledLocationLink
                  to={props.detailLink}
                  data-qa={'ViewAllRegisteredDevicesButton'}
                >
                  <Trans>
                    View & edit user{"'"}s devices
                    {`(${trustedDevices.all &&
                      trustedDevices.all.totalResults})`}
                  </Trans>
                </StyledLocationLink>
              </TextButton>
            </>
          }
          noRecordsView={
            <TableView
              icon={<DevicesIcon />}
              title={<Trans>This user has no registered devices.</Trans>}
              text={
                <Trans>
                  They haven{"'"}t yet used a LastPass app to access their
                  account
                </Trans>
              }
              /* actionButton={
          <TableViewButton>
            <StyledLink
              to={props.trustedDevicesLink}
              data-qa={'GoToRegisteredDevicesPageButton'}
            >
              <Trans>Go to Registered Devices page</Trans>
            </StyledLink>
          </TableViewButton>
        } */
            />
          }
        />
      )}
    </Collapsible>
  )
}

export function createUserTrustedDevicesSummaryCard<
  TState extends { usersDrawer: UsersDrawerState }
>() {
  return connectRedux(
    UserTrustedDevicesSummaryCardComponent,
    (state: TState) => state.usersDrawer.trustedDevices,
    userDrawerActions
  )
}

export type UserTrustedDevicesSummaryCard = ReturnType<
  typeof createUserTrustedDevicesSummaryCard
>

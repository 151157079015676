import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'

export enum AdminOperationActionTypes {
  DELETE_ADMIN_LEVEL = '[admin-operation] delete-admins'
}

export const adminOperationActions = {
  deleteAdmins: (adminLevels: UserLevelDetails[]) =>
    track(
      createAction(AdminOperationActionTypes.DELETE_ADMIN_LEVEL, adminLevels),
      {
        segment: uacEvent('Custom Admin Level Deleted')
      }
    )
}

export type AdminOperationActions = ActionsUnion<typeof adminOperationActions>

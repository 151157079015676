import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UsersFilter } from '@lastpass/admin-console-dependencies/types/users-filter'

export const SUCCESS = 'success'
const success = (usersList: UsersFilter[], totalResults: number) =>
  createResponse(SUCCESS, { usersList, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(queryPath: string) {
    const [response, status] = await fetch('users' + queryPath, 'GET')
    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const usersList = responseData.content.items.map(user => ({
        label: user.email,
        value: user.id
      }))
      const totalResults = responseData.content.totalCount
      return success(usersList, totalResults)
    }
    throw new UnknownServiceError()
  }
}

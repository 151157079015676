import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link, LinkProps, RouteProps, useRouteMatch } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { OAuthManager } from '@lastpass/http'
import { TopNavigation, TopNavigationItem } from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { isCurrentUserAdminOrSuperAdmin } from '@lastpass/admin-console-dependencies/services/get-user-role-helper'
import { getSwitchToOldConsoleClickedSource } from '@lastpass/admin-console-dependencies/services/segment-helpers'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { dropdownActions } from '@lastpass/admin-console-dependencies/state/global/dropdown/actions'
import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'

import { switchToOldConsoleClickedSourceMapping } from '../common/mappings/switch-to-old-console-clicked-source-mapping'

export const MainMenuItem: React.FunctionComponent<LinkProps & {
  route: RouteProps
}> = props => {
  const match = useRouteMatch(props.route)

  return (
    <Link {...props}>
      <TopNavigationItem active={!!match}>{props.children}</TopNavigationItem>
    </Link>
  )
}

interface GlobalTopNavigationComponentProps {
  showDashboard: boolean
  showUsers: boolean
  showApplications: boolean
  showPolicies: boolean
  showReports: boolean
  showAdvanced: boolean
  showManagedCompanies: boolean
}

function getItems(
  props: GlobalTopNavigationComponentProps,
  CompanyDetails
): JSX.Element[] {
  const shouldShowManagedCompaniesPage =
    props.showManagedCompanies && CompanyDetailsHelper.isMsp(CompanyDetails)

  const items: JSX.Element[] = []
  if (props.showDashboard || props.showManagedCompanies) {
    items.push(
      <MainMenuItem
        data-qa={
          shouldShowManagedCompaniesPage
            ? 'TopNavigationHome'
            : 'TopNavigationDashboard'
        }
        to={'/dashboard'}
        route={{ path: ['/dashboard', '/home'] }}
        key={'dashboard'}
      >
        {shouldShowManagedCompaniesPage ? (
          <Trans>Home</Trans>
        ) : (
          <Trans>Dashboard</Trans>
        )}
      </MainMenuItem>
    )
  }

  if (props.showUsers) {
    items.push(
      <MainMenuItem
        data-qa={'TopNavigationUsers'}
        to={'/users'}
        route={{ path: '/users' }}
        key={'users'}
      >
        <Trans>Users</Trans>
      </MainMenuItem>
    )
  }

  if (props.showApplications) {
    items.push(
      <MainMenuItem
        data-qa={'TopNavigationApplications'}
        to={'/applications/discovery'}
        route={{ path: '/applications' }}
        key={'apps'}
      >
        <span className={'onboarding-tour-step-5'}>
          <Trans>Applications</Trans>
        </span>
      </MainMenuItem>
    )
  }

  if (props.showPolicies) {
    const policiesLink = '/policies/generalpolicies'

    items.push(
      <MainMenuItem
        data-qa={'TopNavigationPolicies'}
        to={policiesLink}
        route={{ path: '/policies' }}
        key={'policies'}
      >
        <span className={'onboarding-tour-step-6'}>
          <Trans>Policies</Trans>
        </span>
      </MainMenuItem>
    )
  }

  if (props.showReports) {
    items.push(
      <MainMenuItem
        data-qa={'TopNavigationReporting'}
        to={'/reports'}
        route={{ path: '/reports' }}
        key={'reports'}
      >
        <span className={'onboarding-tour-step-7'}>
          <Trans>Reporting</Trans>
        </span>
      </MainMenuItem>
    )
  }

  if (props.showAdvanced) {
    items.push(
      <MainMenuItem
        data-qa={'TopNavigationAdvanced'}
        to={'/advanced/enterpriseOptions/urlRules'}
        route={{ path: '/advanced' }}
        key={'advanced'}
      >
        <Trans>Advanced</Trans>
      </MainMenuItem>
    )
  }

  return items
}

export type GlobalTopNavigationProps = LastPassComponentProps<
  {
    user: GlobalState['currentUser']
    company: CompanyInfoState
  },
  typeof globalActions & typeof dropdownActions,
  GlobalTopNavigationComponentProps
>

export const GlobalTopNavigationComponent: React.FunctionComponent<GlobalTopNavigationProps> = ({
  state,
  actions,
  props
}) => {
  const { pathname } = useLocation()
  const companyDetails = state.company.details

  const items = getItems(props, companyDetails)
  const switchToOldConsoleClicked = () => {
    const source = getSwitchToOldConsoleClickedSource(
      pathname,
      switchToOldConsoleClickedSourceMapping
    )
    const properties = source ? { Source: source } : undefined
    actions.switchToOldConsoleClicked(properties)
  }
  const currentAdminLevel: string = useSelector((appState: AppState) => {
    return appState.usersList.currentAdminLevel
  })
  const isUserAdminOrSuperAdmin = isCurrentUserAdminOrSuperAdmin(
    currentAdminLevel
  )

  return (
    <TopNavigation
      logout={() => {
        OAuthManager.logout()
      }}
      firstname={state.user ? state.user.firstName : ''}
      lastname={state.user ? state.user.lastName : ''}
      email={state.user ? state.user.email : ''}
      items={items}
      showBuyLicenses={
        state.company.details.canBuyLicenses &&
        !state.company.details.isBillingUsageBased
      }
      buyLicensesLink={`${defaultConfig.lastPassBaseAddress}/enterprisepayment.php`}
      startOnboardingAction={actions.startOnboardingTour}
      billingClicked={actions.billingClicked}
      companyProfileClicked={actions.companyProfileClicked}
      newsAndUpdatesClicked={actions.newsAndUpdatesClicked}
      switchToOldConsoleClicked={switchToOldConsoleClicked}
      config={defaultConfig}
      cidOverride={state.company.cidOverride}
      hasLastPassSupport={!!state.company.details.hasLastPassSupport}
      isUserAdminOrSuperAdmin={isUserAdminOrSuperAdmin}
      isMsp={Boolean(CompanyDetailsHelper.isMsp(companyDetails))}
      isAggregator={state.company.details.isAggregator}
      companyName={state.company.details.name}
      companyAccountNumber={state.company.details.accountNumber}
      isContactSupportAllowed={CompanyDetailsHelper.isContactSupportAllowed(
        companyDetails
      )}
    />
  )
}

export function createGlobalTopNavigation<
  TState extends {
    global: GlobalState
    companyInfo: CompanyInfoState
  }
>() {
  return connectRedux(
    GlobalTopNavigationComponent,
    (state: TState) => {
      return {
        user: state.global.currentUser,
        company: state.companyInfo
      }
    },
    { ...globalActions, ...dropdownActions }
  )
}

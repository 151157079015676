import {
  MultifactorOptionsActions,
  MultifactorOptionsActionTypes
} from './actions'
import { MultifactorOptionsState } from './state'

export const initialState: MultifactorOptionsState = {
  userInfo: {
    email: '',
    usersName: '',
    companyName: '',
    timeZone: '',
    parentcid: '',
    companyId: '',
    displaySupportPhone: false,
    countryCode: '',
    isFederatedCompany: false,
    isChildCompany: false,
    isTransferVaultHidden: false
  }
}

export const multifactorOptionsReducer = (
  previousState: MultifactorOptionsState | undefined,
  action: MultifactorOptionsActions
) => {
  const state = previousState || initialState
  switch (action.type) {
    case MultifactorOptionsActionTypes.MULTIFACTOR_OPTIONS_USER_SET:
      return {
        ...state,
        userInfo: { ...action.payload.userInfo }
      }
    default:
      return state
  }
}

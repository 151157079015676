import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'

export enum AcronisApiActionTypes {
  ACRONIS_API_GET = '[acronis-api] get',
  ACRONIS_API_SET = '[acronis-api] set',
  SET_INPROGRESS = '[acronis-api] set in progress',
  GENERATE_PROVISIONING_HASH = '[acronis-api] generate hash',
  SET_PROVISIONING_HASH = '[acronis-api] set hash'
}

export const acronisApiActions = {
  getAcronisApi: () => createAction(AcronisApiActionTypes.ACRONIS_API_GET),
  setAcronisApi: (alreadyHasHash: boolean) =>
    createAction(AcronisApiActionTypes.ACRONIS_API_SET, {
      alreadyHasHash
    }),
  setInProgress: (inProgress: boolean) =>
    createAction(AcronisApiActionTypes.SET_INPROGRESS, inProgress),
  generateProvisioningHash: (reset?: boolean) =>
    createAction(AcronisApiActionTypes.GENERATE_PROVISIONING_HASH, { reset }),
  setProvisioningHash: (provisioningHash: string) =>
    createAction(AcronisApiActionTypes.SET_PROVISIONING_HASH, {
      provisioningHash
    })
}

export type AcronisApiActions = ActionsUnion<typeof acronisApiActions>

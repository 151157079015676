import { SamlResponsesActions, SamlResponsesActionTypes } from './actions'
import { SamlResponseListState } from './state'

export const initialSamlResponseListState: SamlResponseListState = {
  table: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function samlResponseReducer(
  previousState: SamlResponseListState | undefined,
  action: SamlResponsesActions
): SamlResponseListState {
  const state = previousState || initialSamlResponseListState
  switch (action.type) {
    case SamlResponsesActionTypes.SET_SAML_RESPONSES: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.samlResponses,
          totalResults: action.payload.totalResults
        }
      }
    }
    default: {
      return state
    }
  }
}

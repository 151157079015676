import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { assignActions } from '@lastpass/admin-console-dependencies/state/common/assign/actions'
import { AssignState } from '@lastpass/admin-console-dependencies/state/common/assign/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { Entities } from '@lastpass/admin-console-dependencies/types/assign'
import { TeamsPolicyGroup } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const getAssignState = (state: AppState) => state.assign

export function createGetAvailableEntitySaga(
  policyService: UACServices.Services
) {
  return function* getAvailableEntitySaga(
    action: ReturnType<typeof assignActions.getAvailableEntity>
  ) {
    const { entityType, searchKey } = action.payload

    yield put(assignActions.setAvailableEntityLoading(entityType, true))

    try {
      const assignState: AssignState = yield select(getAssignState)
      if (entityType === Entities.users) {
        const searchUsersResult: UACServices.GeneralPolicyUsersSearchAPI.Responses = yield call(
          policyService.generalPolicySearchUsers,
          searchKey
        )
        if (
          searchUsersResult.type ===
          UACServices.GeneralPolicyUsersSearchAPI.SUCCESS
        ) {
          const searchUsersList = searchUsersResult.body.policyUsersList
          const filteredResultList = searchUsersList.filter(
            userFromResult =>
              assignState.users.filtered.results.filter(
                userFromState => userFromState.email == userFromResult.email
              ).length === 0
          )
          yield put(assignActions.setAvailableUsers(filteredResultList))
        }
      }
      if (entityType === Entities.groups) {
        const searchGroupsResult: UACServices.GeneralPolicyGroupsSearchAPI.Responses = yield call(
          policyService.generalPolicySearchGroups,
          searchKey
        )
        if (
          searchGroupsResult.type ===
          UACServices.GeneralPolicyGroupsSearchAPI.SUCCESS
        ) {
          const searchGroupsListResponse =
            searchGroupsResult.body.policyGroupsList
          const searchGroupsList: TeamsPolicyGroup[] = searchGroupsListResponse.map(
            groupResponse => {
              return {
                id: groupResponse.cgid,
                name: groupResponse.name,
                userscount: parseInt(groupResponse.userscount, 10)
              }
            }
          )
          const filteredResultList = searchGroupsList.filter(
            groupFromResult =>
              assignState.groups.filtered.results.filter(
                groupFromState => groupFromState.id == groupFromResult.id
              ).length === 0
          )
          yield put(assignActions.setAvailableGroups(filteredResultList))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(assignActions.setAvailableEntityLoading(entityType, false))
    }
  }
}

import { track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'

export enum SharedFolderListActionTypes {
  SET_SHARED_FOLDER_LIST = '[shared-folder-list] set',
  GET_SHARED_FOLDER_LIST = '[shared-folder-list] get',
  SET_SHARED_FOLDER_LIST_LOADING = '[shared-folder-list] set-table-loading',
  NEW_SHARED_FOLDER = '[shared-folder] new'
}

export interface SharedFolder {
  shareid: string
  name: string
  securityScore: SecurityScoreLevel
  numberOfApps: number
  totalusers: number
  deleted?: number
}

export interface SharedFolderListQueryParams {
  search: string
  securityScore: SecurityScoreLevel | string
  skip: number
}

export const sharedFolderListActions = {
  setSharedFolderList: (
    list: SharedFolder[],
    totalResults: number,
    resultsPerPage: number
  ) =>
    createAction(SharedFolderListActionTypes.SET_SHARED_FOLDER_LIST, {
      list,
      totalResults,
      resultsPerPage
    }),
  getSharedFolderList: () =>
    track(createAction(SharedFolderListActionTypes.GET_SHARED_FOLDER_LIST), {
      segment: uacEvent('Shared Folders Overview Viewed')
    }),
  setSharedFolderListLoading: (loading: boolean) =>
    createAction(SharedFolderListActionTypes.SET_SHARED_FOLDER_LIST_LOADING, {
      loading
    }),
  newSharedFolder: () =>
    track(createAction(SharedFolderListActionTypes.NEW_SHARED_FOLDER), {
      segment: uacEvent('Add Shared Folder Button Clicked')
    })
}

export type SharedFolderListActions = ActionsUnion<
  typeof sharedFolderListActions
>

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { managedCompanyOperationActions } from '@lastpass/admin-console-dependencies/state/home/managed-companies/operations/actions'
import {
  NotificationProps,
  NotificationType
} from '@lastpass/admin-console-dependencies/types/notification-type'

import { redirectToList } from './helpers/redirect-to-list'

export function createReturnLicensesFromManagedCompanySaga(
  managedCompaniesServices: UACServices.Services
) {
  return function*(
    action: ReturnType<typeof managedCompanyOperationActions.returnLicenses>
  ) {
    const { managedCompanyId, numberOfLicenses } = action.payload

    const successNotificationProps: NotificationProps = {
      type: NotificationType.success,
      message: msg`${numberOfLicenses} licenses have been returned.`,
      autoDismiss: true
    }

    const failureNotEnoughLicensesNotification: NotificationProps = {
      type: NotificationType.alert,
      message: msg`Licenses can’t be returned. Try again later.`,
      autoDismiss: true
    }

    try {
      const result: UACServices.performManagedCompanyLicenseOperation.Responses = yield call(
        managedCompaniesServices.performManagedCompanyLicenseOperation,
        'return-licenses',
        managedCompanyId,
        numberOfLicenses
      )

      if (
        result.type ===
        UACServices.performManagedCompanyLicenseOperation.SUCCESS
      ) {
        yield put(globalActions.setNotification(successNotificationProps))
        yield call(redirectToList)
        return
      }

      yield put(
        globalActions.setNotification(failureNotEnoughLicensesNotification)
      )
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

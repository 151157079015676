import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'

import { UserLevel } from '@lastpass/admin-console-dependencies/types/user-level'

import { EnumDictionary } from '../EnumValues'

export const UserLevelMapping: EnumDictionary<UserLevel, ReactElement> = {
  [UserLevel.user]: <Trans>User</Trans>,
  [UserLevel.superAdmin]: <Trans>Super admin</Trans>,
  [UserLevel.admin]: <Trans>Admin</Trans>,
  [UserLevel.helpDeskAdmin]: <Trans>Helpdesk admin</Trans>,
  [UserLevel.legacyHelpdeskAdmin]: <Trans>Legacy helpdesk admin</Trans>,
  [UserLevel.resetAdmin]: <Trans>Reset Admin</Trans>,
  [UserLevel.userAdmin]: <Trans>User Admin</Trans>,
  [UserLevel.policyAdmin]: <Trans>Policy Admin</Trans>,
  [UserLevel.applicationAdmin]: <Trans>Application Admin</Trans>,
  [UserLevel.mfaAdmin]: <Trans>MFA Admin</Trans>,
  [UserLevel.workstationAdmin]: <Trans>Workstation Admin</Trans>,
  [UserLevel.reportingAdmin]: <Trans>Reporting Admin</Trans>,
  [UserLevel.readOnlyAdmin]: <Trans>Read-Only Admin</Trans>
}

import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'

import { Trans } from '@lingui/macro'

import { DetailsDialog, Table } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NewAddUser } from '@lastpass/admin-console-dependencies/state/users/view/add/state'
import { NotificationDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { Group } from '@lastpass/admin-console-dependencies/types/group'
import {
  MfaTrustedDevice,
  PasswordManagerTrustedDevice,
  WorkstationTrustedDevice
} from '@lastpass/admin-console-dependencies/types/trusted-device'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import { WithError } from '@lastpass/admin-console-dependencies/types/with-error'
import { BulkActionErrorMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/BulkActionErrorMapping'

interface NotificationDialogProps {
  state: NotificationDialog
}

export const NotificationDialogComponent: FunctionComponent<NotificationDialogProps> = ({
  state
}) => {
  const dispatch = useDispatch()
  if (state.notification.details && state.notification.message) {
    const dialogContent = (notificationDetails => {
      switch (notificationDetails.type) {
        case 'user':
          return (
            <Table
              table={{
                results: notificationDetails.users,
                resultsPerPage: notificationDetails.users.length,
                totalResults: notificationDetails.users.length,
                checkedRecords: []
              }}
              columns={[
                {
                  name: <Trans>Email</Trans>,
                  renderer: (record: WithError<NewAddUser>) =>
                    record.email || '-'
                },
                {
                  name: <Trans>First name</Trans>,
                  renderer: (record: WithError<NewAddUser>) =>
                    record.firstName || '-'
                },
                {
                  name: <Trans>Last name</Trans>,
                  renderer: (record: WithError<NewAddUser>) =>
                    record.lastName || '-'
                },
                {
                  name: <Trans>Error</Trans>,
                  // eslint-disable-next-line react/display-name
                  renderer: (record: WithError<NewAddUser>) =>
                    BulkActionErrorMapping[record.error] || (
                      <Trans>Unknown error</Trans>
                    )
                }
              ]}
            />
          )
        case 'group-user':
          return (
            <Table
              table={{
                results: notificationDetails.users,
                resultsPerPage: notificationDetails.users.length,
                totalResults: notificationDetails.users.length,
                checkedRecords: []
              }}
              columns={[
                {
                  name: <Trans>Email</Trans>,
                  renderer: (record: WithError<User>) => record.email
                },
                {
                  name: <Trans>Name</Trans>,
                  renderer: (record: WithError<User>) =>
                    record.firstName + ' ' + record.lastName
                },
                {
                  name: <Trans>Error</Trans>,
                  renderer: (record: WithError<User>) =>
                    BulkActionErrorMapping[record.error]
                }
              ]}
            />
          )
        case 'group-delete':
          return (
            <Table
              table={{
                results: notificationDetails.groups,
                resultsPerPage: notificationDetails.groups.length,
                totalResults: notificationDetails.groups.length,
                checkedRecords: []
              }}
              columns={[
                {
                  name: <Trans>Name</Trans>,
                  renderer: (record: WithError<Group>) => record.name
                },
                {
                  name: <Trans>Error</Trans>,
                  renderer: (record: WithError<Group>) =>
                    BulkActionErrorMapping[record.error]
                }
              ]}
            />
          )
        case 'mfadevices':
          return (
            <Table
              table={{
                results: notificationDetails.mfaDevices,
                resultsPerPage: notificationDetails.mfaDevices.length,
                totalResults: notificationDetails.mfaDevices.length,
                checkedRecords: []
              }}
              columns={[
                {
                  name: <Trans>Name</Trans>,
                  renderer: (record: MfaTrustedDevice) => record.name
                },
                {
                  name: <Trans>Type</Trans>,
                  renderer: (record: MfaTrustedDevice) => record.type
                }
              ]}
            />
          )
        case 'workstations':
          return (
            <Table
              table={{
                results: notificationDetails.wokstations,
                resultsPerPage: notificationDetails.wokstations.length,
                totalResults: notificationDetails.wokstations.length,
                checkedRecords: []
              }}
              columns={[
                {
                  name: <Trans>Name</Trans>,
                  renderer: (record: WorkstationTrustedDevice) => record.name
                },
                {
                  name: <Trans>last login</Trans>,
                  renderer: (record: WorkstationTrustedDevice) =>
                    record.lastLoginTimeUtc
                }
              ]}
            />
          )
        case 'lpdevices':
          return (
            <Table
              table={{
                results: notificationDetails.devices,
                resultsPerPage: notificationDetails.devices.length,
                totalResults: notificationDetails.devices.length,
                checkedRecords: []
              }}
              columns={[
                {
                  name: <Trans>Device</Trans>,
                  renderer: (record: PasswordManagerTrustedDevice) =>
                    record.type
                },
                {
                  name: <Trans>UUID</Trans>,
                  renderer: (record: PasswordManagerTrustedDevice) =>
                    record.uuid
                },
                {
                  name: <Trans>Label</Trans>,
                  renderer: (record: PasswordManagerTrustedDevice) =>
                    record.label
                },
                {
                  name: <Trans>Status</Trans>,
                  renderer: (record: PasswordManagerTrustedDevice) =>
                    record.isEnabled ? 'Enabled' : 'Disabled'
                }
              ]}
            />
          )
      }
    })(state.notification.details)

    return (
      <DetailsDialog
        closeDialog={() => dispatch(globalActions.emptyDialog())}
        title={state.notification.message}
        content={dialogContent}
      />
    )
  }

  return null
}

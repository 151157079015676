import React, { FC } from 'react'

import { Trans } from '@lingui/macro'

import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton
} from '@lastpass/lastkit'

export interface GeneratedSecurityDrawerFooterProps {
  onButtonClick: () => void
  isGenerating: boolean
}

export const GeneratedSecurityReportDrawerFooter: FC<GeneratedSecurityDrawerFooterProps> = ({
  onButtonClick,
  isGenerating
}) => (
  <FooterContainer>
    <DrawerButtonsContainer>
      <PrimaryButton
        data-qa="GeneratedSecurityReportDrawerButton"
        onClick={onButtonClick}
        disabled={isGenerating}
      >
        {isGenerating ? (
          <Trans>Generating...</Trans>
        ) : (
          <Trans>Generate report</Trans>
        )}
      </PrimaryButton>
    </DrawerButtonsContainer>
  </FooterContainer>
)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Loading } from '@lastpass/components'
import { Drawer } from '@lastpass/lastkit'

import { assignActions } from '@lastpass/admin-console-dependencies/state/common/assign/actions'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'

import { AdvancedPolicyDrawerPage } from './advancedPolicyDrawer/AdvancedPolicyDrawerPage'

export interface AdvancedPolicyDrawerProps {
  closeLink: string
  initialStep: AdvancedPolicyDrawerSteps
}

const getTitle = step => {
  switch (step) {
    case AdvancedPolicyDrawerSteps.addAdvancedPolicy:
      return <Trans>Add advanced policy</Trans>
    case AdvancedPolicyDrawerSteps.configPolicy:
    case AdvancedPolicyDrawerSteps.map:
      return <Trans>Policy details</Trans>
    case AdvancedPolicyDrawerSteps.assign:
      return <Trans>Assign users {'&'} groups</Trans>
    case AdvancedPolicyDrawerSteps.assignNewEntities:
      return <Trans>Assign users {'&'} groups</Trans>
    case AdvancedPolicyDrawerSteps.allSet:
      return <Trans>All set!</Trans>
  }
}

export const AdvancedPolicyDrawer: React.FunctionComponent<AdvancedPolicyDrawerProps> = ({
  closeLink,
  initialStep
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(advancedPolicyDrawerActions.discard())
      dispatch(assignActions.reset())
    }
  }, [dispatch])

  const advancedPolicyDrawerState: AdvancedPolicyDrawerState = useSelector(
    (state: AppState) => state.advancedPolicyDrawer
  )

  const actualStep = advancedPolicyDrawerState.actualStep
    ? advancedPolicyDrawerState.actualStep
    : initialStep
  const mainTitle = getTitle(actualStep)
  return (
    <Drawer mainTitle={mainTitle} miscTitle={<></>} closeLink={closeLink}>
      {advancedPolicyDrawerState.isLoading && (
        <Loading color="blue900" active={true} />
      )}
      <AdvancedPolicyDrawerPage step={actualStep} closeLink={closeLink} />
    </Drawer>
  )
}

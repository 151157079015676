import { msg } from '@lingui/macro'
import { put } from 'redux-saga/effects'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { federatedLoginActions } from '@lastpass/admin-console-dependencies/state/users/federated-login/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createSetAdAttributeVisibility() {
  return function* setAdAttributeVisibility(
    action: ReturnType<typeof federatedLoginActions.setAdAttributeVisible>
  ) {
    try {
      const visible: boolean = action.payload
      if (visible) {
        yield put(
          globalActions.setNotification({
            message: msg`To enable a user-specific unique key, you must update your AD schema. For details, refer to the setup instructions.`,
            type: NotificationType.warning,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { DropdownListItem } from '@lastpass/lastkit'
import { HeaderTextButton } from '@lastpass/lastkit/components/MoreAction'

export interface AssignAdminLevelItem {
  name: React.ReactElement
  action(): void
}

interface AssignAdminLevelDropdownItemProps {
  item: AssignAdminLevelItem
  index: number
}

export const AssignAdminLevelDropdownItem: React.FunctionComponent<AssignAdminLevelDropdownItemProps> = ({
  item,
  index
}) => (
  <DropdownListItem key={index}>
    <p key={index}>
      <HeaderTextButton data-qa="AdminLevelName" onClick={item.action}>
        <Trans>{item.name}</Trans>
      </HeaderTextButton>
    </p>
  </DropdownListItem>
)

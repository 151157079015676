import React from 'react'
import { CSSTransition } from 'react-transition-group'

import styled from '@emotion/styled'

import { ReactComponent as Check } from '@lastpass/assets/svg/admin-console/icon-check-filled.svg'
import { ReactComponent as Triangle } from '@lastpass/assets/svg/admin-console/icon-warning-triangle.svg'
import { Loading } from '@lastpass/components'
import { Notification, ThemeColor } from '@lastpass/lastkit'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GlobalState } from '@lastpass/admin-console-dependencies/state/global/state'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

const CustomCheck = styled(Check)`
  width: 16px;
  height: 16px;
  margin: 16px;
`

const CustomTriangle = styled(Triangle)`
  width: 16px;
  height: 16px;
  margin: 16px;
`

const LoadingContainer = styled.div`
  position: absolute;
  height: 47px;
  width: 58px;
`

export type GlobalNotificationProps = LastPassComponentProps<
  GlobalState['notification'],
  typeof globalActions,
  {}
>

const transitionName = `notification`

const BottomCenterContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0%);
  margin: 0 auto;

  transition: opacity 0.3s;
  &.${transitionName}-enter {
    opacity: 0;
  }

  // enter to
  &.${transitionName}-enter-active {
    opacity: 1;
  }

  // exit from
  &.${transitionName}-exit {
    opacity: 1;
  }

  // exit to
  &.${transitionName}-exit-active {
    opacity: 0;
  }
`
let icon: React.ReactElement
let color: ThemeColor

export const GlobalNotificationComponent: React.FunctionComponent<GlobalNotificationProps> = ({
  state,
  actions
}) => {
  switch (state.type) {
    case NotificationType.alert:
      color = 'red700'
      icon = <CustomTriangle />
      break
    case NotificationType.success:
      color = 'green700'
      icon = <CustomCheck />
      break
    case NotificationType.warning:
      color = 'yellow700'
      icon = <CustomTriangle />
      break
    case NotificationType.async:
      color = 'white'
      icon = (
        <LoadingContainer>
          <Loading color={'blue900'} active />
        </LoadingContainer>
      )
      break
  }
  if (state.autoDismiss) {
    actions.clearNotification(10000)
  }

  return (
    <CSSTransition
      classNames={transitionName}
      timeout={500}
      in={state.type !== NotificationType.hidden}
      unmountOnExit
    >
      <BottomCenterContainer>
        <Notification
          text={state.message || undefined}
          component={state.component}
          icon={icon}
          backgroundColor={color}
          onDismissClick={() => actions.clearNotification()}
          onDetailsClick={
            state.details &&
            (() => {
              return actions.setDialog({
                type: 'notificationdialog',
                notification: state
              })
            })
          }
        />
      </BottomCenterContainer>
    </CSSTransition>
  )
}

export function createGlobalNotification<
  TState extends { global: GlobalState }
>() {
  return connectRedux(
    GlobalNotificationComponent,
    (state: TState) => state.global.notification,
    globalActions
  )
}

export type GlobalNotification = ReturnType<typeof createGlobalNotification>

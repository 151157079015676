import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UserLevelDetails } from '@lastpass/admin-console-dependencies/types/user-level'

export const SUCCESS = 'success'

const success = (adminList: UserLevelDetails[], totalResults: number) =>
  createResponse(SUCCESS, { adminList, totalResults })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch('adminLevels', 'GET')
    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const adminList = responseData.content
      const totalResults = adminList.length
      return success(adminList, totalResults)
    }
    throw new UnknownServiceError()
  }
}

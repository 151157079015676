import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'

import {
  generalPoliciesListActions,
  GeneralPoliciesListQueryParams
} from '@lastpass/admin-console-dependencies/state/policies/general/list/actions'
import { GeneralPoliciesListState } from '@lastpass/admin-console-dependencies/state/policies/general/list/state'
import { AppliesToOptions } from '@lastpass/admin-console-dependencies/types/assign'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'

import { AppDiscoveryEmptyPage } from './empty-pages/AppDiscoveryEmptyPage'
import { ComingSoonPage } from './empty-pages/ComingSoonPage'

export const AppDiscovery: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  const enabledPolicyValue = '1'

  const queryParams: GeneralPoliciesListQueryParams = useQueryParams(
    Namespace.policies
  )

  const isSaasAppDiscoveryEnabled = useFeatureFlags(
    FeatureFlags.isSaasAppDiscoveryEnabled
  )

  useEffect(() => {
    dispatch(
      generalPoliciesListActions.getGeneralPoliciesList({
        query: queryParams,
        path: {}
      })
    )
  }, [dispatch, queryParams])

  const generalPoliciesList: GeneralPoliciesListState = useSelector(
    (state: AppState) => state.generalPoliciesList
  )

  const isUrlLoggingDisabled = !generalPoliciesList?.policyMap?.enableurllogging?.policyData?.some(
    p =>
      p.appliesTo == AppliesToOptions.all &&
      p.policyValue === enabledPolicyValue
  )

  if (isSaasAppDiscoveryEnabled) {
    return isUrlLoggingDisabled ? <AppDiscoveryEmptyPage /> : <ComingSoonPage />
  }
  return null
}

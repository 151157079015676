import React, { FC } from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import moment from 'moment'

import { ReactComponent as DownloadIcon } from '@lastpass/assets/svg/admin-console/icon-download.svg'
import { CardContainer, Table } from '@lastpass/lastkit'

import { GeneratedSecurityReport } from '@lastpass/admin-console-dependencies/types/generated-security-reports'
import { TableColumn } from '@lastpass/admin-console-dependencies/types/table-column'

const StyledDownloadIcon = styled(DownloadIcon)`
  color: ${props => props.theme.colors.blue};
  display: block;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  padding-right: 7px;
`

interface GeneratedSecurityDrawerTableProps {
  reports: GeneratedSecurityReport[]
  onDownloadClick: (reportId: string) => void
}

const getColumns = (
  onDownloadClick: GeneratedSecurityDrawerTableProps['onDownloadClick']
): TableColumn<GeneratedSecurityReport>[] => [
  {
    name: <Trans>Generated on</Trans>,
    renderer: function GeneratedOnRenderer({ createdAtDateTimeUtc }) {
      return (
        <span data-qa="GeneratedSecurityReportDrawerTableGeneratedOn">
          {moment(createdAtDateTimeUtc).format('MMMM D, YYYY [at] h:mm A')}
        </span>
      )
    },
    noTooltip: true
  },
  {
    name: <Trans>Generated by</Trans>,
    renderer: function GeneratedByRenderer({ requesterEmail }) {
      return (
        <span data-qa="GeneratedSecurityReportDrawerTableGeneratedBy">
          {requesterEmail}
        </span>
      )
    },
    noTooltip: true
  },
  {
    name: <></>,
    renderer: function DownloadIconRenderer({ id }) {
      return (
        <StyledDownloadIcon
          data-qa="GeneratedSecurityReportDrawerTableDownloadIcon"
          onClick={() => {
            onDownloadClick(id)
          }}
        />
      )
    },
    noTooltip: true,
    contentWidth: '15%'
  }
]

export const GeneratedSecurityReportDrawerTable: FC<GeneratedSecurityDrawerTableProps> = ({
  reports,
  onDownloadClick
}) => (
  <CardContainer>
    <Table
      qadata="GeneratedSecurityReportDrawerTable"
      table={{
        results: reports,
        resultsPerPage: reports.length,
        totalResults: reports.length,
        checkedRecords: []
      }}
      columns={getColumns(onDownloadClick)}
    />
  </CardContainer>
)

import React, { FunctionComponent, useCallback, useRef } from 'react'

import styled from '@emotion/styled'
import { msg, Trans } from '@lingui/macro'
import { Form, Formik, FormikProps } from 'formik'

import {
  Federation,
  OpenIdProvider,
  openIdProviderNameMapping
} from '@lastpass/federation/lib/federation-enums'
import { DetailsDialog, PrimaryButton, TextInput } from '@lastpass/lastkit'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { KeyRotationK1InputDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

interface KeyRotationK1InputDialogProps {
  state: KeyRotationK1InputDialog
  actions: typeof globalActions
}
const TextBoxContainer = styled.div`
  margin-top: 20px;
`
const StyledSubTitle = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
`
export const StyledExternalLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.blue700};
`
const StyledTextInput = styled(TextInput)`
  max-width: 100%;
`

export const KeyRotationK1InputDialogComponent: FunctionComponent<KeyRotationK1InputDialogProps> = ({
  state,
  actions
}) => {
  const form = useRef<FormikProps<{ legacyK1: string }>>(null)

  const getSupportLinkUrl = useCallback(() => {
    switch (state.providerName) {
      case openIdProviderNameMapping[OpenIdProvider.Okta]:
        return 'https://link.lastpass.com/rotate-knowledge-components-okta?anchor=find_okta_k1'
      case openIdProviderNameMapping[OpenIdProvider.OneLogin]:
        return 'https://link.lastpass.com/rotate-knowledge-components-onelogin?anchor=find_onelogin_k1'
      default:
        return ''
    }
  }, [state.providerName])

  return (
    <Formik
      initialValues={{ legacyK1: '' }}
      onSubmit={values => {
        actions.confirmDialog(values.legacyK1.trim())
      }}
      innerRef={form}
    >
      {formikProps => {
        return (
          <DetailsDialog
            closeDialog={() => {
              actions.discardDialog()
              actions.emptyDialog()
            }}
            title={msg`Validate existing K1 component`}
            width="600px"
            disableCloseOnBackground
            content={
              <>
                <StyledSubTitle>
                  <Trans>
                    Enter your existing K1 knowledge component from the service
                    ({state.providerName}).
                  </Trans>
                </StyledSubTitle>
                <StyledExternalLink
                  href={getSupportLinkUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>How to find it</Trans>
                </StyledExternalLink>
                <Form>
                  <TextBoxContainer>
                    <StyledTextInput
                      name="legacyK1"
                      value={formikProps.values.legacyK1}
                      onChange={formikProps.handleChange}
                      type="text"
                    >
                      <Trans>Existing K1 knowledge component</Trans>
                    </StyledTextInput>
                  </TextBoxContainer>
                </Form>
              </>
            }
            buttons={[
              <PrimaryButton
                data-qa="PopupSubmit"
                disabled={
                  formikProps.values.legacyK1.trim().length !==
                  Federation.k1Length
                }
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
                key="confirm"
              >
                <Trans>Validate</Trans>
              </PrimaryButton>
            ]}
          />
        )
      }}
    </Formik>
  )
}

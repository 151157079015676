import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as AddressIcon } from '@lastpass/assets/svg/admin-console/icon-address.svg'
import { Loading } from '@lastpass/components'
import {
  DrawerTable as TableComponent,
  TableView,
  TableViewButton
} from '@lastpass/lastkit'

import { TeamsPolicyUserInfo } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { Table } from '@lastpass/admin-console-dependencies/types/table'

const StyledNoAppIcon = styled(AddressIcon)`
  width: 180px;
  height: 180px;
`

export interface UsersTableComponentProps {
  table: Table<TeamsPolicyUserInfo>
  checkable: {
    addSelectedRecords: (userList: TeamsPolicyUserInfo[]) => void
    removeSelectedRecords: (userList: TeamsPolicyUserInfo[]) => void
    setSelectedRecords: (userList: TeamsPolicyUserInfo[]) => void
  }
  title: React.ReactElement
  text: React.ReactElement
  actionButton: React.ReactElement
  filterApplied?: boolean
  clearSearchQuery?: () => void
}

export const UsersTableComponent: React.FunctionComponent<UsersTableComponentProps> = props => {
  return (
    <TableComponent
      table={props.table}
      checkable={props.checkable}
      noRecordsView={
        <TableView
          icon={<StyledNoAppIcon data-qa="NoAppsIcon" />}
          title={props.title}
          text={props.text}
          actionButton={props.actionButton}
        />
      }
      noResultsView={
        <TableView
          icon={<StyledNoAppIcon data-qa="NoAppsIcon" />}
          title={<Trans>Sorry, no results match your search</Trans>}
          text={<Trans>Try different keywords or check your spelling</Trans>}
          actionButton={
            <TableViewButton onClick={props.clearSearchQuery}>
              <Trans>Clear search query</Trans>
            </TableViewButton>
          }
        />
      }
      loadingView={<Loading color="blue900" active={true} />}
      columns={[
        {
          name: <Trans>Name</Trans>,
          renderer: (record: TeamsPolicyUserInfo) => record.name
        },
        {
          name: <Trans>Email</Trans>,
          renderer: (record: TeamsPolicyUserInfo) => record.email
        }
      ]}
      isFilterApplied={props.filterApplied}
      actions={[]}
    />
  )
}

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Papa from 'papaparse'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'

export interface DecryptionDataRow {
  sharedFolderId: string
  encryptedSharedFolderEncryptionKey: string
  recoveryKey: string
  privateSharingKey: string
  encryptedSharedFolderEncryptionKeyOwner: 'user' | 'admin'
  encryptedSharedFolderEncryptionKeyType: 'aes' | 'sharingkey'
  userId: string
}

export const usePrepareDecryptionDataFromCsv = () => {
  const dispatch = useDispatch()

  const securityReportFiles = useSelector(
    (state: AppState) => state.securityReportDrawer.securityReportFiles
  )

  const csvFileWithDecryptionData = securityReportFiles[1]

  const parseKeysCsv = useCallback(() => {
    let decryptionData: DecryptionDataRow[] = []
    return Papa.parse(csvFileWithDecryptionData, {
      worker: false,
      download: false,
      skipEmptyLines: true,
      fastMode: true,
      chunk: async function(result, parser) {
        parser.pause()

        const rows = result.data

        decryptionData = rows.slice(1).map(row => ({
          sharedFolderId: row[0],
          encryptedSharedFolderEncryptionKey: row[1],
          recoveryKey: row[2],
          privateSharingKey: row[3],
          encryptedSharedFolderEncryptionKeyOwner: row[4],
          encryptedSharedFolderEncryptionKeyType: row[5],
          userId: row[6]
        }))

        parser.resume()
      },
      complete: async function() {
        dispatch(
          securityReportDrawerActions.setUrlsInSharedFolderDecryptionData(
            decryptionData
          )
        )
      },
      error: function() {
        dispatch(globalActions.setNotification(genericFailedNotification))
      }
    })
  }, [csvFileWithDecryptionData, dispatch])

  return parseKeysCsv
}

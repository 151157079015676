import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { AccessPolicyMap } from '@lastpass/admin-console-dependencies/state/policies/passwordless/passwordless'

export const SUCCESS = 'success'

const success = (response: AccessPolicyMap) =>
  createResponse(SUCCESS, { response })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service(
    searchString: string,
    resultsPerPage: number,
    skip: number
  ) {
    const page = Math.floor(skip / resultsPerPage) + 1
    const search = encodeURIComponent(searchString)
    try {
      const [response, status] = await identityApi(
        `api/v2/AccessPolicy/GetPolicies?size=${resultsPerPage}&page=${page}&searchString=${search}`,
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const accessPoliciesResponse = JSON.parse(response)
        return success(accessPoliciesResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

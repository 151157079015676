import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps, Formik, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton, TextInput } from '@lastpass/lastkit/components'
import { BodyRegularStyle, CaptionRegularStyle } from '@lastpass/lastkit/styles'

import { rsaSecureIDRadiusActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/actions'
import {
  RSASecureIDRadiusFormData,
  RSASecureIDRadiusState
} from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'

import { FileUploadComponent } from './FileUploadComponent'
import { OptionsCard } from './OptionsCard'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 516px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const InputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 16px;

  &:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 12px;
  }
`
const SingleInputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 16px;
`
const InfoContainer = styled.div`
  ${BodyRegularStyle}
  margin-top:16px;
  margin-bottom: 20px;
  width: 100%;
`
const FileInputContainer = styled.div`
  width: 100%;
  margin: 8px 12px 16px 0px;
`
const StyledButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin: 8px 0 12px 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const InputCaption = styled.span`
  ${CaptionRegularStyle}
  margin:0px;
`
const SubTitleContainer = styled.div`
  display: inline-block;
  max-width: 800px;
`

interface RSASecureIDRadiusProps {
  expand?: boolean
  closeOtherCards: (expandStatus: boolean) => void
  onFormActivity: (formStatus: boolean) => void
}

const RSASecureIDRadiusSubTitle: FunctionComponent = () => {
  return (
    <>
      <SubTitleContainer>
        <Trans>
          Here{`'`}s how to set up RSA SecurID authentication via RADIUS: First,
          go to your RSA authentication manager and set up a client using the{' '}
          {`'`}ANY Client{`'`} option. Second, set up a firewall to allow
          connections only from LastPass server IP addresses (ask your LastPass
          representative if unsure). Finally, edit securid.ini and change
          CheckUserAllowedByClient from 1 to 0.
        </Trans>
      </SubTitleContainer>
      <SubTitleContainer>
        <Trans>
          Note that LastPass uses an outbound firewall, so your server{`'`}s IP
          must be explicitly allowed by the LastPass operations team for ports
          other than 1812 or 1645.
        </Trans>
      </SubTitleContainer>
    </>
  )
}
const checkFileDataChange = (
  formData: RSASecureIDRadiusFormData,
  rsaInitialData: RSASecureIDRadiusFormData
): boolean => {
  return (
    formData.logo124x124 === rsaInitialData.logo124x124 &&
    formData.logo124x124Length === rsaInitialData.logo124x124Length &&
    formData.logo124x124Name === rsaInitialData.logo124x124Name &&
    formData.logo190x41 === rsaInitialData.logo190x41 &&
    formData.logo190x41Length === rsaInitialData.logo190x41Length &&
    formData.logo190x41Name === rsaInitialData.logo190x41Name
  )
}

const hasFormDataChanged = (
  formData: RSASecureIDRadiusFormData,
  rsaInitialData: RSASecureIDRadiusFormData,
  onFormActivity: (formStatus: boolean) => void
): boolean => {
  const formFileFields = checkFileDataChange(formData, rsaInitialData)
  const formFields = !(
    formData.radiusServerIp === rsaInitialData.radiusServerIp &&
    formData.radiusSharedSecret === rsaInitialData.radiusSharedSecret &&
    formData.radiusTimeout == rsaInitialData.radiusTimeout &&
    formData.failureMessage === rsaInitialData.failureMessage &&
    formData.serviceName === rsaInitialData.serviceName &&
    formFileFields
  )
  onFormActivity(formFields)
  return formFields
}

interface RSASecureIdFormProps {
  initialValues: RSASecureIDRadiusFormData
  handleSubmit: (formValues: RSASecureIDRadiusFormData) => void
  onFormActivity: (formStatus: boolean) => void
  shouldReset?: boolean
}

const RSASecureIdForm: FunctionComponent<RSASecureIdFormProps> = ({
  initialValues,
  handleSubmit,
  onFormActivity,
  shouldReset
}) => {
  const formref = useRef<FormikProps<typeof initialValues>>(null)
  useEffect(() => {
    if (shouldReset) {
      if (formref.current) {
        formref.current.resetForm()
      }
    }
  }, [shouldReset])
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formref}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formikProps => {
        return (
          <>
            <FormContainer>
              <InputContainer>
                <Field name="radiusServerIp">
                  {(formData: FieldProps) => {
                    return (
                      <StyledTextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>RADIUS server IP address</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
                <InputCaption>
                  <Trans>
                    Separate multiple items with commas and append {"'"}:port
                    {"'"} if not 1812
                  </Trans>
                </InputCaption>
              </InputContainer>
              <InputContainer>
                <Field name="radiusSharedSecret">
                  {(formData: FieldProps) => {
                    return (
                      <StyledTextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        type="password"
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>RADIUS shared secret</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </InputContainer>
              <InputContainer>
                <Field name="radiusTimeout">
                  {(formData: FieldProps) => {
                    return (
                      <StyledTextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>RADIUS timeout (in seconds)</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </InputContainer>
              <InputContainer>
                <Field name="failureMessage">
                  {(formData: FieldProps) => {
                    return (
                      <StyledTextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Failure message</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </InputContainer>
              <InfoContainer>
                <Trans>
                  RADIUS can also be used to support multifactor authentication
                  options other than RSA SecurID (such as SafeNet).
                </Trans>
                <div>
                  <Trans>
                    Customize the name and logos seen by your users:
                  </Trans>
                </div>
              </InfoContainer>
              <SingleInputContainer>
                <Field name="serviceName">
                  {(formData: FieldProps) => {
                    return (
                      <StyledTextInput
                        name={formData.field.name}
                        value={formData.field.value}
                        onChange={e => {
                          formData.field.onChange(e)
                        }}
                      >
                        <Trans>Service name</Trans>
                      </StyledTextInput>
                    )
                  }}
                </Field>
              </SingleInputContainer>
              <FileInputContainer>
                <Field name="logo124x124">
                  {(formData: FieldProps) => {
                    return (
                      <FileUploadComponent
                        name={formData.field.name}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.files[0]
                          )
                          formikProps.setFieldValue(
                            `${formData.field.name}Length`,
                            e.currentTarget.files[0]
                              ? e.currentTarget.files[0].size
                              : initialValues[`${formData.field.name}Length`]
                          )
                          formikProps.setFieldValue(
                            `${formData.field.name}Name`,
                            e.currentTarget.files[0]
                              ? e.currentTarget.files[0].name
                              : initialValues[`${formData.field.name}Name`]
                          )
                        }}
                        fieldNameLabel={<Trans>124x124 PNG logo</Trans>}
                        fileLengthInKb={
                          formikProps.values.logo124x124Length / 1000
                        }
                        fileName={formikProps.values.logo124x124Name}
                      />
                    )
                  }}
                </Field>
              </FileInputContainer>
              <FileInputContainer>
                <Field name="logo190x41">
                  {(formData: FieldProps) => {
                    return (
                      <FileUploadComponent
                        name={formData.field.name}
                        onChange={e => {
                          formikProps.setFieldValue(
                            formData.field.name,
                            e.currentTarget.files[0]
                          )
                          formikProps.setFieldValue(
                            `${formData.field.name}Length`,
                            e.currentTarget.files[0]
                              ? e.currentTarget.files[0].size
                              : initialValues[`${formData.field.name}Length`]
                          )
                          formikProps.setFieldValue(
                            `${formData.field.name}Name`,
                            e.currentTarget.files[0]
                              ? e.currentTarget.files[0].name
                              : initialValues[`${formData.field.name}Name`]
                          )
                        }}
                        fieldNameLabel={<Trans>190x41 PNG logo</Trans>}
                        fileLengthInKb={
                          formikProps.values.logo190x41Length / 1000
                        }
                        fileName={formikProps.values.logo190x41Name}
                      />
                    )
                  }}
                </Field>
              </FileInputContainer>
            </FormContainer>
            <StyledButton
              disabled={
                !hasFormDataChanged(
                  formikProps.values,
                  initialValues,
                  onFormActivity
                )
              }
              onClick={() => {
                formikProps.handleSubmit()
              }}
              type="submit"
            >
              <Trans>Update</Trans>
            </StyledButton>
          </>
        )
      }}
    </Formik>
  )
}

export const RSASecureIDRadiusCard: FunctionComponent<RSASecureIDRadiusProps> = ({
  expand,
  closeOtherCards,
  onFormActivity
}) => {
  const rsaSecureIDRadiusState: RSASecureIDRadiusState = useSelector(
    (state: AppState) => state.rsaSecureIDRadius
  )
  const dispatch = useDispatch()
  const initialValues: RSASecureIDRadiusFormData = {
    radiusServerIp:
      rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.radiusServerIp,
    radiusSharedSecret:
      rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.radiusSharedSecret,
    radiusTimeout:
      rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.radiusTimeout,
    failureMessage:
      rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.failureMessage,
    serviceName: rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.serviceName,
    logo124x124: null,
    logo190x41: null,
    logo124x124Length:
      rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.logo124x124Length,
    logo190x41Length:
      rsaSecureIDRadiusState.rsaSecureIDRadiusSettings.logo190x41Length,
    logo124x124Name: '',
    logo190x41Name: ''
  }
  const formKey = JSON.stringify(initialValues)
  return (
    <OptionsCard
      title={<Trans>RSA SecurID</Trans>}
      subtitle={<RSASecureIDRadiusSubTitle />}
      loading={rsaSecureIDRadiusState.loading}
      isInitialExpand={!!expand}
      onExpansion={rsaSecureIDRadiusActions.getRSASecureIDRadius}
      closeOtherCards={closeOtherCards}
    >
      <RSASecureIdForm
        key={formKey}
        initialValues={initialValues}
        handleSubmit={formValues =>
          dispatch(rsaSecureIDRadiusActions.saveRSASecureIDRadius(formValues))
        }
        onFormActivity={onFormActivity}
        shouldReset={!expand}
      />
    </OptionsCard>
  )
}

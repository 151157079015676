import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { UrlRuleOptions } from '@lastpass/admin-console-dependencies/types/url-rule-options'

export const SUCCESS = 'success'

const success = () => createResponse(SUCCESS)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service(urlRuleOptions: UrlRuleOptions) {
    try {
      const [response, status] = await fetch(
        'url-rules/options',
        'PUT',
        urlRuleOptions,
        { 'content-type': 'application/json' }
      )

      if (status === StatusCodes.OK && response) {
        return success()
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Form, Formik } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  ButtonsContainer,
  Heading100,
  PrimaryButton,
  StyledFormFooter,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationRedirect } from '@lastpass/routing'
import { useNavigationBlockingDialog } from '@lastpass/ui/hooks/use-navigation-blocking-dialog'

import {
  isTimeRangePolicy as checkIfPolicyIsTimeRangePolicy,
  isTimeRangeValid
} from '@lastpass/admin-console-dependencies/services/policies/time-range-validator'
import { advancedPolicyDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/actions'
import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { AdvancedPolicyDrawerState } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/state'
import { TeamsPolicyValueType } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { PolicyNoteEditor } from '@lastpass/admin-console-dependencies/ui/policies/editors/PolicyNoteEditor'
import { PolicyValueEditor } from '@lastpass/admin-console-dependencies/ui/policies/editors/PolicyValueEditor'

const MainContainer = styled.div`
  font-size: 14px;
  line-height: 24px;

  hr {
    border-top: none;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
    margin: 24px 0;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 16px;
`

export interface ConfigPolicyProps {
  closeLink: string
}

interface FormData {
  policyValue?: string
  policyNotes?: string
}

function canSubmit(
  initialData: FormData,
  currentFormData: FormData,
  canEditValue: boolean,
  isAdding: boolean,
  isTimeRangePolicy: boolean
): boolean {
  if (
    canEditValue &&
    (!currentFormData.policyValue ||
      (isTimeRangePolicy && !isTimeRangeValid(currentFormData.policyValue)))
  ) {
    return false
  }

  if (isAdding) {
    return true
  }

  const hasChanged = !(
    initialData.policyNotes === currentFormData.policyNotes &&
    initialData.policyValue === currentFormData.policyValue
  )
  return hasChanged
}

export const ConfigPolicy: React.FunctionComponent<ConfigPolicyProps> = ({
  closeLink
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setIsBlockingPromptEnabled } = useNavigationBlockingDialog()

  const advancedPolicyDrawerState: AdvancedPolicyDrawerState = useSelector(
    (state: AppState) => state.advancedPolicyDrawer
  )

  if (advancedPolicyDrawerState.policy === null) {
    return <LocationRedirect to={closeLink} />
  }

  const policyDescriptionLines = advancedPolicyDrawerState.policy.policyDescription
    .split('<br/>')
    .filter(line => line.trim().length > 0)
  const initialValues: FormData = {
    policyValue: advancedPolicyDrawerState.policyValue,
    policyNotes: advancedPolicyDrawerState.policyNotes
  }
  const canEditValue =
    advancedPolicyDrawerState.policy.policyValueType ===
    TeamsPolicyValueType.textarea
  const isAdding = typeof advancedPolicyDrawerState.dataIndex === 'undefined'
  const isTimeRangePolicy = checkIfPolicyIsTimeRangePolicy(
    advancedPolicyDrawerState.policy.policyKey
  )
  return (
    <MainContainer>
      <Heading100
        css={css`
          margin-bottom: 16px;
        `}
      >
        {advancedPolicyDrawerState.policy.policyTitle}
      </Heading100>
      <div>
        {policyDescriptionLines.map((line, index) => (
          <p key={index}> {line}</p>
        ))}
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => {
          setIsBlockingPromptEnabled(false)
          dispatch(
            advancedPolicyDrawerActions.setPolicyConfig(
              values.policyNotes,
              values.policyValue
            )
          )
          if (isAdding) {
            dispatch(
              advancedPolicyDrawerActions.nextStep(
                AdvancedPolicyDrawerSteps.assign
              )
            )
          } else {
            dispatch(advancedPolicyDrawerActions.savePolicyConfig(closeLink))
          }
        }}
      >
        {formikProps => (
          <Form>
            <FormContainer
              data-qa="FormContainer"
              onChange={() => setIsBlockingPromptEnabled(true)}
            >
              {canEditValue && (
                <PolicyValueEditor isTimeRangePolicy={isTimeRangePolicy} />
              )}
              <PolicyNoteEditor />
            </FormContainer>
            <StyledSpacer />
            <StyledFormFooter>
              <ButtonsContainer>
                <TextButton
                  css={css`
                    margin-right: 16px;
                  `}
                  key="Discard"
                  onClick={() => history.push(closeLink)}
                  type="button"
                  data-qa="DiscardButton"
                >
                  <Trans>Cancel</Trans>
                </TextButton>
                <PrimaryButton
                  id="ContinueButton"
                  key="Continue"
                  onClick={() => {
                    formikProps.handleSubmit()
                  }}
                  disabled={
                    !canSubmit(
                      initialValues,
                      formikProps.values,
                      canEditValue,
                      isAdding,
                      isTimeRangePolicy
                    )
                  }
                  type="submit"
                  data-qa="ContinueButton"
                >
                  {isAdding ? (
                    <Trans>Continue</Trans>
                  ) : (
                    <Trans>Save {'&'} exit</Trans>
                  )}
                </PrimaryButton>
              </ButtonsContainer>
            </StyledFormFooter>
          </Form>
        )}
      </Formik>
    </MainContainer>
  )
}

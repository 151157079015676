import { StatusCodes } from 'http-status-codes'

import { TeamsAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { TeamsPolicyResponseMap } from '@lastpass/admin-console-dependencies/types/policy-teams'

export const SUCCESS = 'success'

const success = (generalPoliciesListResponse: TeamsPolicyResponseMap) =>
  createResponse(SUCCESS, { generalPoliciesListResponse })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(teamsapi: TeamsAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await teamsapi('my-company/policies', 'GET')
      if (status === StatusCodes.OK && response) {
        const policyResponse = JSON.parse(response)
        return success(policyResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

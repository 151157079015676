import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { Field, FieldProps, Formik, FormikProps } from 'formik'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { PrimaryButton, TextInput } from '@lastpass/lastkit/components'

import { duoSecuritySettingsActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/actions'
import { DuoSecuritySettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/duo-security-settings/state'

import { OptionsCard } from './OptionsCard'

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  width: 516px;
  border: 1px solid ${props => props.theme.colors.neutral400};
  box-sizing: border-box;
  border-radius: 4px;
`

const InputContainer = styled.div`
  margin-right: 12px;
  margin-bottom: 16px;

  &:nth-of-type(2n) {
    margin-right: 0;
    margin-left: 12px;
  }
`
const StyledButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin: 24px 0 12px 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`

interface DuoSecuritySettingsProps {
  expand: boolean
  cid: string
  onFormActivity: (formStatus: boolean) => void
  closeOtherCards: (expandStatus: boolean) => void
}

interface DuoSecuritySubtitleProps {
  cid: string
}
const DuoSecuritySubTitle: FunctionComponent<DuoSecuritySubtitleProps> = ({
  cid
}) => {
  return (
    <span>
      <StyledLink
        href={`https://duo.com/partners/technology-partners/ready-partners#lastpass?lpcid=${cid}`}
      >
        <Trans>Click here</Trans>
      </StyledLink>{' '}
      <Trans>
        if you need a Duo Security account (be sure to choose an integration
        type of LastPass)
      </Trans>
    </span>
  )
}
const hasFormDataChanged = (
  formData: Record<string, string>,
  duoSecuritySettingsData: DuoSecuritySettingsState,
  onFormActivity: (formStatus: boolean) => void
): boolean => {
  const hasDataChanged = !(
    formData.duoSecuritySettingsIntegrationKey ===
      duoSecuritySettingsData.integrationKey &&
    formData.duoSecuritySettingsSecretKey ===
      duoSecuritySettingsData.secretKey &&
    formData.duoSecuritySettingsApiHostName ===
      duoSecuritySettingsData.apiHostName
  )
  onFormActivity(hasDataChanged)
  return hasDataChanged
}

export const DuoSecuritySettingsCard: FunctionComponent<DuoSecuritySettingsProps> = ({
  expand,
  cid,
  onFormActivity,
  closeOtherCards
}) => {
  const duoSecuritySettingsState: DuoSecuritySettingsState = useSelector(
    (state: AppState) => state.duoSecuritySettings
  )
  const dispatch = useDispatch()
  const initialValues = {
    duoSecuritySettingsIntegrationKey: duoSecuritySettingsState.integrationKey,
    duoSecuritySettingsSecretKey: duoSecuritySettingsState.secretKey,
    duoSecuritySettingsApiHostName: duoSecuritySettingsState.apiHostName
  }
  const formref = useRef<FormikProps<typeof initialValues>>(null)
  useEffect(() => {
    if (!expand) {
      if (formref.current) {
        formref.current.resetForm()
      }
    }
  }, [expand])

  return (
    <OptionsCard
      title={<Trans>Duo Security</Trans>}
      subtitle={<DuoSecuritySubTitle cid={cid} />}
      loading={duoSecuritySettingsState.loading}
      isInitialExpand={expand}
      onExpansion={duoSecuritySettingsActions.getDuoSecuritySettings}
      closeOtherCards={closeOtherCards}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formref}
        onSubmit={values => {
          dispatch(
            duoSecuritySettingsActions.saveDuoSecuritySettings({
              integrationKey: values.duoSecuritySettingsIntegrationKey,
              secretKey: values.duoSecuritySettingsSecretKey,
              apiHostName: values.duoSecuritySettingsApiHostName
            })
          )
        }}
      >
        {formikProps => {
          return (
            <>
              <FormContainer>
                <InputContainer>
                  <Field name="duoSecuritySettingsIntegrationKey">
                    {(formData: FieldProps) => {
                      return (
                        <StyledTextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Duo Security integration key</Trans>
                        </StyledTextInput>
                      )
                    }}
                  </Field>
                </InputContainer>
                <InputContainer>
                  <Field name="duoSecuritySettingsSecretKey">
                    {(formData: FieldProps) => {
                      return (
                        <StyledTextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          type="password"
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Duo Security secret key</Trans>
                        </StyledTextInput>
                      )
                    }}
                  </Field>
                </InputContainer>
                <InputContainer>
                  <Field name="duoSecuritySettingsApiHostName">
                    {(formData: FieldProps) => {
                      return (
                        <StyledTextInput
                          name={formData.field.name}
                          value={formData.field.value}
                          onChange={e => {
                            formData.field.onChange(e)
                          }}
                        >
                          <Trans>Duo Security API hostname</Trans>
                        </StyledTextInput>
                      )
                    }}
                  </Field>
                </InputContainer>
              </FormContainer>
              <StyledButton
                disabled={
                  !hasFormDataChanged(
                    formikProps.values,
                    duoSecuritySettingsState,
                    onFormActivity
                  )
                }
                onClick={() => {
                  formikProps.handleSubmit()
                }}
                type="submit"
              >
                <Trans>Update</Trans>
              </StyledButton>
            </>
          )
        }}
      </Formik>
    </OptionsCard>
  )
}

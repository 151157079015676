export enum UserType {
  ENTERPRISE = 'Enterprise',
  ENTERPRISE_EXPIRED = 'Enterprise_Expired',
  ENTERPRISE_TRIAL = 'Enterprise_Trial',
  ENTERPRISE_TRIAL_EXPIRED = 'Enterprise_Trial_Expired',
  FAMILY = 'Family',
  FAMILY_EXPIRED = 'Family_Expired',
  FAMILY_TRIAL = 'Family_Trial',
  FAMILY_TRIAL_EXPIRED = 'Family_Trial_Expired',
  FREE = 'Free',
  PREMIUM = 'Premium',
  PREMIUM_TRIAL = 'Premium_Trial',
  TEAMS = 'Teams',
  TEAMS_EXPIRED = 'Teams_Expired',
  TEAMS_TRIAL = 'Teams_Trial',
  TEAMS_TRIAL_EXPIRED = 'Teams_Trial_Expired'
}

import { Entities } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import {
  ApplicationDrawerActions,
  ApplicationDrawerActionTypes
} from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'

export const initialState: ApplicationDrawerState = {
  isLoading: false,
  actualStep: null,
  canMoveToNext: false,
  availableApplications: [],
  selectedApplication: null,
  samlKeys: [],
  usernameAttributes: [],
  usersSearchInput: '',
  groupsSearchInput: '',
  rolesSearchInput: '',
  showTable: Entities.users,
  availableUsers: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  users: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  availableGroups: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  groups: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  availableRoles: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  roles: {
    results: [],
    resultsPerPage: 0,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  },
  assignPageOpened: false,
  testLoginSuccessState: false
}

export function applicationDrawerReducer(
  previousState: ApplicationDrawerState | undefined,
  action: ApplicationDrawerActions
): ApplicationDrawerState {
  const state = previousState || initialState
  switch (action.type) {
    case ApplicationDrawerActionTypes.SET_AVAILABLE_APPLICATIONS: {
      return {
        ...state,
        availableApplications: action.payload.list
      }
    }
    case ApplicationDrawerActionTypes.SET_SELECTED_APPLICATION: {
      const selectedApp = action.payload.selectedApp
      return {
        ...state,
        selectedApplication: selectedApp,
        canMoveToNext: selectedApp != null
      }
    }
    case ApplicationDrawerActionTypes.NEXT_STEP: {
      const nextStep = action.payload.nextStep
      return {
        ...state,
        selectedApplication:
          nextStep === ApplicationDrawerSteps.addUnlistedApp
            ? null
            : state.selectedApplication,
        actualStep: nextStep,
        canMoveToNext: false
      }
    }
    case ApplicationDrawerActionTypes.SET_STEP: {
      const step = action.payload.step
      const application = action.payload.application
      return {
        ...state,
        selectedApplication: application,
        actualStep: step,
        canMoveToNext: false
      }
    }
    case ApplicationDrawerActionTypes.DISCARD: {
      return initialState
    }
    case ApplicationDrawerActionTypes.SET_DRAWER_LOADING: {
      return {
        ...state,
        isLoading: action.payload.loading
      }
    }
    case ApplicationDrawerActionTypes.SET_SAML_KEYS: {
      return {
        ...state,
        samlKeys: action.payload.samlKeys
      }
    }
    case ApplicationDrawerActionTypes.SET_USERNAME_ATTRIBUTES: {
      return {
        ...state,
        usernameAttributes: action.payload.attributes
      }
    }
    case ApplicationDrawerActionTypes.SET_USER_LIST_SEARCH_INPUT: {
      return {
        ...state,
        usersSearchInput: action.payload.searchString
      }
    }
    case ApplicationDrawerActionTypes.SET_AVAILABLE_USER_LIST: {
      return {
        ...state,
        availableUsers: {
          ...state.availableUsers,
          results: action.payload.list,
          resultsPerPage: 1000,
          totalResults: action.payload.totalResults,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case ApplicationDrawerActionTypes.SET_USER_LIST_LOADING: {
      return {
        ...state,
        availableUsers: {
          ...state.availableUsers,
          isLoading: action.payload.loading
        }
      }
    }
    case ApplicationDrawerActionTypes.SELECT_TABLE_ELEMENT: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          checkedRecords: state[action.payload.entity].checkedRecords.concat(
            action.payload.records
          )
        }
      }
    }
    case ApplicationDrawerActionTypes.DESELECT_TABLE_ELEMENT: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          checkedRecords: state[action.payload.entity].checkedRecords.filter(
            el => !action.payload.records.includes(el)
          )
        }
      }
    }
    case ApplicationDrawerActionTypes.SELECT_OR_DESELECT_ALL_TABLE_ELEMENTS: {
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          checkedRecords: action.payload.records
        }
      }
    }
    case ApplicationDrawerActionTypes.ASSIGN: {
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.concat(
            state.availableUsers.checkedRecords
          ),
          totalResults:
            state.users.results.length +
            state.availableUsers.checkedRecords.length
        },
        groups: {
          ...state.groups,
          results: state.groups.results.concat(
            state.availableGroups.checkedRecords
          ),
          totalResults:
            state.groups.results.length +
            state.availableGroups.checkedRecords.length
        },
        roles: {
          ...state.roles,
          results: state.roles.results.concat(
            state.availableRoles.checkedRecords
          ),
          totalResults:
            state.roles.results.length +
            state.availableRoles.checkedRecords.length
        }
      }
    }
    case ApplicationDrawerActionTypes.UNASSIGN: {
      return {
        ...state,
        users: {
          ...state.users,
          results: state.users.results.filter(
            user => !state.users.checkedRecords.includes(user)
          ),
          checkedRecords: state.users.checkedRecords.filter(
            user => !state.users.checkedRecords.includes(user)
          ),
          totalResults:
            state.users.totalResults - state.users.checkedRecords.length
        },
        groups: {
          ...state.groups,
          results: state.groups.results.filter(
            group => !state.groups.checkedRecords.includes(group)
          ),
          checkedRecords: state.groups.checkedRecords.filter(
            group => !state.groups.checkedRecords.includes(group)
          ),
          totalResults:
            state.groups.totalResults - state.groups.checkedRecords.length
        },
        roles: {
          ...state.roles,
          results: state.roles.results.filter(
            role => !state.roles.checkedRecords.includes(role)
          ),
          checkedRecords: state.roles.checkedRecords.filter(
            role => !state.roles.checkedRecords.includes(role)
          ),
          totalResults:
            state.roles.totalResults - state.roles.checkedRecords.length
        }
      }
    }
    case ApplicationDrawerActionTypes.SET_GROUP_LIST_SEARCH_INPUT: {
      return {
        ...state,
        groupsSearchInput: action.payload.searchString
      }
    }
    case ApplicationDrawerActionTypes.SET_AVAILABLE_GROUP_LIST: {
      return {
        ...state,
        availableGroups: {
          ...state.availableGroups,
          results: action.payload.list,
          resultsPerPage: 1000,
          totalResults: action.payload.totalResults,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case ApplicationDrawerActionTypes.SET_ROLE_LIST_SEARCH_INPUT: {
      return {
        ...state,
        rolesSearchInput: action.payload.searchString
      }
    }
    case ApplicationDrawerActionTypes.SET_AVAILABLE_ROLE_LIST: {
      return {
        ...state,
        availableRoles: {
          ...state.availableRoles,
          results: action.payload.list,
          resultsPerPage: 1000,
          totalResults: action.payload.totalResults,
          checkedRecords: [],
          isLoading: false
        }
      }
    }
    case ApplicationDrawerActionTypes.SET_ROLE_LIST_LOADING: {
      return {
        ...state,
        availableRoles: {
          ...state.availableRoles,
          isLoading: action.payload.loading
        }
      }
    }
    case ApplicationDrawerActionTypes.SET_ASSIGNED_ELEMENTS: {
      return {
        ...state,
        users: {
          ...state.users,
          results: action.payload.users,
          checkedRecords: [],
          totalResults: action.payload.users.length
        },
        groups: {
          ...state.groups,
          results: action.payload.groups,
          checkedRecords: [],
          totalResults: action.payload.groups.length
        },
        roles: {
          ...state.roles,
          results: action.payload.roles,
          checkedRecords: [],
          totalResults: action.payload.roles.length
        }
      }
    }
    case ApplicationDrawerActionTypes.SET_ASSIGN_PAGE_OPENED: {
      return {
        ...state,
        assignPageOpened: action.payload.opened
      }
    }
    case ApplicationDrawerActionTypes.SHOW_TABLE: {
      return {
        ...state,
        showTable: action.payload.table
      }
    }
    case ApplicationDrawerActionTypes.SET_TEST_LOGIN_SUCCESS_STATE: {
      return {
        ...state,
        testLoginSuccessState: true
      }
    }
  }
  return state
}

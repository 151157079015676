import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { getIdentityIFrameUrl } from '@lastpass/admin-console-dependencies/services'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { workstationListActions } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/actions'
import { WorkstationListState } from '@lastpass/admin-console-dependencies/state/applications/workstations/list/state'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { AdvancedMfaMessage } from '@lastpass/admin-console-dependencies/ui/global/AdvancedMfaMessage'

import { WorkstationEmptyList } from './WorkstationEmptyList'

export interface WorkstationListProps {
  cidOverride: string | undefined
}

type WorkstationListComponentProps = LastPassComponentProps<
  WorkstationListState,
  typeof workstationListActions & typeof globalActions,
  WorkstationListProps
>

export const WorkstationListComponent: React.FunctionComponent<WorkstationListComponentProps> = ({
  state,
  props,
  actions
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(workstationListActions.getWorkstationList())
  }, [dispatch])

  const isTableEmpty = !state.table.totalResults

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const userHasMfaAddon = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  return (isTableEmpty && !state.getStarted) || !userHasMfaAddon ? (
    <WorkstationEmptyList actions={actions} />
  ) : (
    <>
      <AdvancedMfaMessage
        isEmpty={state.getStarted}
        nonMspMessage={msg`Workstation Login is available with the Advanced MFA add-on.`}
      />
      <iframe
        css={css`
          width: 100%;
          height: 100%;
        `}
        src={getIdentityIFrameUrl(
          `${defaultConfig.identityIframeUrl}/Policies/Workstations`,
          props.cidOverride
        )}
      />
    </>
  )
}

export function createWorkstationList<
  TState extends { workstationList: WorkstationListState }
>() {
  return connectRedux(
    WorkstationListComponent,
    (state: TState) => state.workstationList,
    {
      ...workstationListActions,
      ...globalActions
    }
  )
}

export type WorkstationList = ReturnType<typeof createWorkstationList>

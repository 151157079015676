import styled from '@emotion/styled'

import { BodyRegularStyle, TextInput } from '@lastpass/lastkit'

export const StyledCardContainer = styled.div`
  margin-right: 80px;
  margin-bottom: 18px;
`

export const StyledDescriptionRow = styled.div`
  ${BodyRegularStyle};
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.neutral700};
  margin-bottom: 18px;
`

export const StyledTextInput = styled(TextInput)`
  background: ${props => props.theme.colors.white};
  height: 40px;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: ${props => props.theme.radius.pixel4};
`

export const StyledExternalLink = styled.a`
  align-self: flex-start;
`

export const StyledInactiveExternalLink = styled.a`
  align-self: flex-start;
  cursor: default;
  color: ${props => props.theme.colors.neutral400};
  &:hover {
    color: ${props => props.theme.colors.neutral400};
  }
`

export const StyledFormRow = styled.div`
  ${BodyRegularStyle};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 24px;
  color: ${props => props.theme.colors.neutral700};

  span {
    top: 43px;
    bottom: auto;
  }
`

export const CheckboxContainer = styled.div`
  margin-top: 10px;
`

export const SubmitContainer = styled.div`
  text-align: right;
  padding-top: 14px;
`

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyBoldStyle,
  BodyRegularStyle,
  BodySemibold,
  CaptionRegular,
  CaptionSemiboldStyle,
  CardTable,
  Collapsible,
  DrawerButtonsContainer,
  FooterContainer,
  Heading100,
  PrimaryButton,
  Select,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

import { generalPoliciesDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/actions'
import { GeneralPoliciesDrawerState } from '@lastpass/admin-console-dependencies/state/policies/general/drawer/state'
import { PolicyAdminUser } from '@lastpass/admin-console-dependencies/types/policy'
import {
  TeamsPolicy,
  TeamsPolicyStatus,
  TeamsPolicyValueType
} from '@lastpass/admin-console-dependencies/types/policy-teams'
import { PolicyUsersMapping } from '@lastpass/admin-console-dependencies/ui/common/mappings/PolicyUsersMapping'

import { ConfigCard } from './ConfigCard'
import { PolicyStatusSelectOption } from './SelectPolicyStatus'

const StyledConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`
const StyledAddConfigButton = styled.button`
  ${BodyBoldStyle}
  cursor: pointer;
  color: ${props => props.theme.colors.blue700};
  padding: 0;
  border: none;
  background: none;
  outline: none;
  margin-left: auto;
  margin-right: 5px;
  margin-top: 10px;
`

const StyledDescription = styled.div`
  ${BodyRegularStyle}
  margin-top:16px;
`
const FieldContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`
const FieldName = styled(CaptionRegular)`
  color: ${props => props.theme.colors.neutral700};
`
const FieldValue = styled.span`
  ${BodyRegularStyle}
  color: ${props => props.theme.colors.black};
`
const StyledLink = styled(LocationLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.blue700};
`
const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 24px;
`
const StyledLabel = styled(BodySemibold)`
  color: ${props => props.theme.colors.neutral600};
`
const StyledDeleteConfigButton = styled.button`
  ${CaptionSemiboldStyle}
  cursor: pointer;
  color: ${props => props.theme.colors.red700};
  padding: 0;
  border: none;
  background: none;
  outline: none;
  margin-top: 20px;
`

const UnsavedChangesText = styled.p`
  ${CaptionSemiboldStyle}
  color: ${props => props.theme.colors.red700};
  line-height: unset;
`

const StyledDrawerButtonsContainer = styled(DrawerButtonsContainer)`
  justify-content: space-between;
`

export interface GeneralPoliciesDetailsProps {
  editSettingslink: string
  editUsersLink: string
}

export interface PolicyConfigCardsProps {
  policy: TeamsPolicy
  editUsersLink: string
  editSettingsLink: string
  removeConfigCurrentGeneralPolicy: (
    policyKey: string,
    policyConfigIndex: number
  ) => void
}

export const PolicyConfigCardsComponent: FunctionComponent<PolicyConfigCardsProps> = ({
  policy,
  editUsersLink,
  editSettingsLink,
  removeConfigCurrentGeneralPolicy
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  })
  const dispatch = useDispatch()
  return (
    <>
      {policy.policyData ? (
        policy.policyData.map((policyData, index) => {
          const policyValue = policyData.policyValue
          const policyAppliesTo = policyData.appliesTo
          return (
            <Collapsible
              title={
                <>
                  {policyData.policyNotes
                    ? policyData.policyNotes
                    : `#${index + 1}`}
                </>
              }
              expanded={index === policy.policyData.length - 1}
              key={index}
              scrollToView
            >
              <div
                ref={
                  index === policy.policyData.length - 1
                    ? containerRef
                    : undefined
                }
              >
                <ConfigCard
                  title={<Trans>Settings</Trans>}
                  editLink={
                    <TextButton data-qa="EditPolicySettingsButton" blue>
                      <StyledLink to={`${editSettingsLink}/${index}`}>
                        <Trans>Edit policy settings</Trans>
                      </StyledLink>
                    </TextButton>
                  }
                >
                  {policy.policyValueType === TeamsPolicyValueType.textarea && (
                    <FieldContainer>
                      <FieldName>
                        <Trans>Value</Trans>
                      </FieldName>
                      <FieldValue>{policyValue ? policyValue : '-'}</FieldValue>
                    </FieldContainer>
                  )}
                </ConfigCard>
                <ConfigCard
                  title={<Trans>Users</Trans>}
                  editLink={
                    <TextButton data-qa="EditPolicyUsersButton" blue>
                      <StyledLink
                        to={`${editUsersLink}/${index}`}
                        discardnamespace={Namespace.users}
                      >
                        <Trans>Edit policy users</Trans>
                      </StyledLink>
                    </TextButton>
                  }
                >
                  {policyAppliesTo.length > 0 ? (
                    <FieldValue data-qa="AssignedUsersCount">
                      {PolicyUsersMapping[policyAppliesTo]}
                    </FieldValue>
                  ) : null}
                </ConfigCard>
                {index !== 0 && (
                  <StyledDeleteConfigButton
                    data-qa="DeleteConfigButton"
                    onClick={() => {
                      dispatch(
                        removeConfigCurrentGeneralPolicy(
                          policy.policyKey,
                          index
                        )
                      )
                    }}
                  >
                    <Trans>Delete configuration</Trans>
                  </StyledDeleteConfigButton>
                )}
              </div>
            </Collapsible>
          )
        })
      ) : (
        <></>
      )}
    </>
  )
}

const handleSelectOnChange = (
  selectedItem,
  policySelectedStatus,
  setPolicySelectedStatus
) => {
  if (selectedItem['value'] !== policySelectedStatus) {
    setPolicySelectedStatus(selectedItem['value'])
  }
}

const policyStatusMap = {
  [TeamsPolicyStatus.enabled]: true,
  [TeamsPolicyStatus.disabled]: false
}

export const GeneralPolicyDetails: FunctionComponent<GeneralPoliciesDetailsProps> = ({
  editSettingslink,
  editUsersLink
}) => {
  const generalPoliciesDrawerState: GeneralPoliciesDrawerState = useSelector(
    (state: AppState) => state.generalPoliciesDrawer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(generalPoliciesDrawerActions.getAdminList())
  }, [dispatch])

  const policyStatus: PolicyStatusSelectOption[] = [
    {
      value: TeamsPolicyStatus.enabled,
      label: i18n._(msg`Enabled`)
    },
    {
      value: TeamsPolicyStatus.disabled,
      label: i18n._(msg`Disabled`)
    }
  ]

  const policyKey = generalPoliciesDrawerState.policy.policyKey

  const policyDescriptionLines = generalPoliciesDrawerState.policy.policyKey
    ? generalPoliciesDrawerState.policy.policyDescription
        .split('<br/>')
        .filter(line => line.trim().length > 0)
    : []

  const [policySelectedStatus, setPolicySelectedStatus] = useState(
    TeamsPolicyStatus.disabled
  )

  useEffect(() => {
    setPolicySelectedStatus(
      generalPoliciesDrawerState.unsavedPolicy.checked
        ? TeamsPolicyStatus.enabled
        : TeamsPolicyStatus.disabled
    )
  }, [generalPoliciesDrawerState.unsavedPolicy.checked])

  useEffect(() => {
    if (
      generalPoliciesDrawerState.unsavedPolicy.policyKey !==
      generalPoliciesDrawerState.policy.policyKey
    ) {
      dispatch(
        generalPoliciesDrawerActions.setUnsavedPolicy(
          generalPoliciesDrawerState.policy
        )
      )
    }
  }, [
    dispatch,
    generalPoliciesDrawerState.policy,
    generalPoliciesDrawerState.unsavedPolicy.policyKey
  ])

  const isPolicyChanged =
    generalPoliciesDrawerState.policy.isChanged ||
    policyStatusMap[policySelectedStatus] !==
      generalPoliciesDrawerState.policy.checked

  const adminPolicy =
    generalPoliciesDrawerState.policy.policyValueType ===
    TeamsPolicyValueType.adminusers
  const isEnabledAdminPolicy =
    policySelectedStatus === TeamsPolicyStatus.enabled && adminPolicy

  if (generalPoliciesDrawerState.drawerNeedsRefresh) {
    return <Loading color="blue900" active={true} />
  } else {
    return (
      <>
        <Heading100>{generalPoliciesDrawerState.policy.policyTitle}</Heading100>
        <StyledDescription>
          {policyDescriptionLines.map((line, index) => (
            <p key={index}> {line}</p>
          ))}
        </StyledDescription>
        <StyledConfigContainer>
          <SelectContainer>
            <StyledLabel>
              <Trans>Status</Trans>
            </StyledLabel>
            <Select
              dataQa={'PolicyStatusDetailsDropdown'}
              options={policyStatus}
              isDisabled={!generalPoliciesDrawerState.policy.switchable}
              value={
                policySelectedStatus === TeamsPolicyStatus.enabled ||
                isEnabledAdminPolicy
                  ? policyStatus[0]
                  : policyStatus[1]
              }
              onChange={selectedItem => {
                if (selectedItem) {
                  handleSelectOnChange(
                    selectedItem,
                    policySelectedStatus,
                    setPolicySelectedStatus
                  )
                  dispatch(
                    generalPoliciesDrawerActions.setUnsavedPolicy({
                      ...generalPoliciesDrawerState.policy,
                      checked: policyStatusMap[selectedItem['value']]
                    })
                  )
                }
              }}
            />
          </SelectContainer>
          {generalPoliciesDrawerState.policy.checked &&
            generalPoliciesDrawerState.policy.policyValueType ===
              TeamsPolicyValueType.textarea && (
              <StyledAddConfigButton
                data-qa="AddConfigButton"
                onClick={() => {
                  dispatch(
                    generalPoliciesDrawerActions.addConfigCurrentGeneralPolicy(
                      policyKey
                    )
                  )
                }}
              >
                <Trans>Add configuration</Trans>
              </StyledAddConfigButton>
            )}
        </StyledConfigContainer>
        {generalPoliciesDrawerState.policy.policyValueType !==
          TeamsPolicyValueType.adminusers && (
          <>
            <PolicyConfigCardsComponent
              editSettingsLink={editSettingslink}
              editUsersLink={editUsersLink}
              policy={generalPoliciesDrawerState.policy}
              removeConfigCurrentGeneralPolicy={
                generalPoliciesDrawerActions.removeConfigCurrentGeneralPolicy
              }
            />
            <StyledSpacer />
            <FooterContainer>
              <StyledDrawerButtonsContainer>
                <div>
                  {isPolicyChanged && (
                    <UnsavedChangesText>
                      <Trans>You have unsaved changes</Trans>
                    </UnsavedChangesText>
                  )}
                </div>
                <div>
                  <LocationLink to={`/policies/generalpolicies`}>
                    <TextButton
                      data-qa={'CancelButton'}
                      css={css`
                        margin-right: 16px;
                      `}
                    >
                      <Trans>Cancel</Trans>
                    </TextButton>
                  </LocationLink>
                  <PrimaryButton
                    data-qa={'SaveButton'}
                    disabled={!isPolicyChanged}
                    onClick={() => {
                      if (policySelectedStatus === TeamsPolicyStatus.enabled) {
                        dispatch(
                          generalPoliciesDrawerActions.enableCurrentGeneralPolicy(
                            policyKey
                          )
                        )
                        return
                      }
                      dispatch(
                        generalPoliciesDrawerActions.disableCurrentGeneralPolicy(
                          policyKey
                        )
                      )
                    }}
                    type="submit"
                  >
                    <Trans>Save changes</Trans>
                  </PrimaryButton>
                </div>
              </StyledDrawerButtonsContainer>
            </FooterContainer>
          </>
        )}

        {isEnabledAdminPolicy && (
          <>
            <Collapsible
              title={<Trans>Admins</Trans>}
              expanded={true}
              loading={generalPoliciesDrawerState.adminUsersTable.isLoading}
            >
              <CardTable
                table={generalPoliciesDrawerState.adminUsersTable}
                columns={[
                  {
                    name: <Trans>Name</Trans>,
                    renderer: (record: PolicyAdminUser) => record.name
                  },
                  {
                    name: <Trans>Email</Trans>,
                    renderer: (record: PolicyAdminUser) => record.email
                  }
                ]}
                checkable={{
                  addSelectedRecords: record =>
                    dispatch(
                      generalPoliciesDrawerActions.addSelectedAdmins(record)
                    ),
                  removeSelectedRecords: record =>
                    dispatch(
                      generalPoliciesDrawerActions.removeSelectedAdmins(record)
                    ),
                  setSelectedRecords: record =>
                    dispatch(
                      generalPoliciesDrawerActions.setSelectedAdmins(record)
                    )
                }}
                noRecordsView={<></>}
              />
            </Collapsible>
            <StyledSpacer />
            <FooterContainer>
              <DrawerButtonsContainer>
                <LocationLink to={`/policies/generalpolicies`}>
                  <TextButton
                    data-qa={'CancelButton'}
                    css={css`
                      margin-right: 16px;
                    `}
                  >
                    <Trans>Cancel</Trans>
                  </TextButton>
                </LocationLink>
                <LocationLink to={`/policies/generalpolicies`}>
                  <PrimaryButton
                    data-qa={'SaveButton'}
                    disabled={
                      generalPoliciesDrawerState.adminUsersTable.checkedRecords
                        .length === 0
                    }
                    onClick={() => {
                      dispatch(
                        generalPoliciesDrawerActions.enableAdminPolicy(
                          policyKey,
                          generalPoliciesDrawerState.adminUsersTable
                            .checkedRecords
                        )
                      )
                    }}
                    type="submit"
                  >
                    <Trans>Save changes</Trans>
                  </PrimaryButton>
                </LocationLink>
              </DrawerButtonsContainer>
            </FooterContainer>
          </>
        )}
      </>
    )
  }
}

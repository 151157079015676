import React from 'react'
import ReactDOM from 'react-dom'

import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import createSagaMiddleware from 'redux-saga'

import { Config } from '@lastpass/admin-console/src/default-config'
import {
  createAuthFetch,
  createAuthFetchBlob,
  createFetch,
  OAuthManager
} from '@lastpass/http'
import { createTrackingMiddleware } from '@lastpass/tracking'
import { UACTracking } from '@lastpass/tracking'
import { initMockedFeatureFlags } from '@lastpass/utility/feature-flag-wrapper'

import { CidOverrideRepository } from '@lastpass/admin-console-dependencies/repositories'
import { initializeLocalForageRepositories } from '@lastpass/admin-console-dependencies/repositories/initialize-local-forage-repositories'
import {
  createUACServices,
  Services
} from '@lastpass/admin-console-dependencies/server'
import { SUCCESS } from '@lastpass/admin-console-dependencies/server/users/view/current-user-settings'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { initLocalization } from '@lastpass/admin-console-dependencies/services/translation-handler'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { GlobalActionTypes } from '@lastpass/admin-console-dependencies/state/global/actions'
import { genericFailedNotificationCounter } from '@lastpass/admin-console-dependencies/state/global/middlewares/generic-failed-notification-counter'
import { CidOverrideParams } from '@lastpass/admin-console-dependencies/types/cid-override-params'

import { createApp } from './App'
import { createAppStore } from './app-store'
import { applyLastPassEuConfig, defaultConfig } from './default-config'

function isSubscriptionExpired(subscriptionEndDate: Date | null) {
  if (!subscriptionEndDate) {
    return false
  }

  const endDateUtc: Date = subscriptionEndDate as Date
  const now: Date = new Date()
  return now > endDateUtc
}

const getCidOverride: () => Promise<CidOverrideParams | null> = async () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const childCompanyId = urlParams.get('cid')
  const backofficeCompanyId = urlParams.get('bcid')
  const removeCid = urlParams.has('removeCid')

  if (removeCid) {
    await CidOverrideRepository.remove()
    return null
  } else if (backofficeCompanyId) {
    return {
      companyId: backofficeCompanyId,
      isChildCompany: false
    }
  } else if (childCompanyId) {
    return {
      companyId: childCompanyId,
      isChildCompany: true
    }
  }

  const cidParams = await CidOverrideRepository.get()
  if (!cidParams || !cidParams.companyId) {
    return null
  }

  return cidParams
}

const createFetchServices: (
  config: Config,
  cidOverride: CidOverrideParams | null
) => Services = (config: Config, cidOverride: CidOverrideParams | null) => {
  let defaultMiddlewareHeaders = {}
  let defaultTeamsApiHeaders = {}
  let defaultUrlParams = ''

  if (cidOverride) {
    if (cidOverride.isChildCompany) {
      defaultTeamsApiHeaders = {
        'X-ChildCompanyId-Override': cidOverride.companyId
      }
    } else {
      defaultTeamsApiHeaders = {
        'X-CompanyId-Override': cidOverride.companyId
      }
    }

    defaultMiddlewareHeaders = {
      'X-CompanyId-Override': cidOverride.companyId
    }
    defaultUrlParams = `managedClient=${cidOverride.companyId}`
  }

  const uacFetch = createAuthFetch(
    config.middlewareApiUrl,
    defaultMiddlewareHeaders
  )

  const uacFetchBlob = createAuthFetchBlob(
    config.middlewareApiUrl,
    defaultMiddlewareHeaders
  )

  const uacFetchRetry = createAuthFetch(
    config.middlewareApiUrl,
    defaultMiddlewareHeaders
  )

  const fetch = createFetch(
    () => '',
    config.lastPassBaseAddress,
    config.identityIframeUrl,
    undefined,
    defaultTeamsApiHeaders,
    defaultUrlParams
  )

  return createUACServices(
    uacFetch,
    fetch.teamsapi,
    fetch.identityApi,
    uacFetchBlob,
    uacFetchRetry
  )
}

async function redirectWhenSubscriptionExpired(
  config: Config,
  cidOverride: CidOverrideParams | null
) {
  let redirectUrl: string

  if (cidOverride) {
    await CidOverrideRepository.remove()
    if (cidOverride.isChildCompany) {
      redirectUrl = '/company/?resetconsole=true#!/managed-companies'
    } else {
      redirectUrl = `/company/?resetconsole=true&cidoverride=${cidOverride.companyId}`
    }
  } else {
    redirectUrl = '/enterprisepayment.php'
  }

  window.location.href = config.lastPassBaseAddress + redirectUrl
}

export async function initialize(config: Config = defaultConfig) {
  OAuthManager.initialize(config.authServerUrl, config.serverClientId)
  const userProfile = await OAuthManager.getUserProfile()

  if (userProfile?.userId) {
    await initializeLocalForageRepositories(userProfile.userId)
  }

  if (userProfile?.isEuUser) {
    applyLastPassEuConfig()
  }

  const cidOverride = await getCidOverride()
  const fetchServices = createFetchServices(config, cidOverride)

  const [
    companyDetailsResponse,
    adminLevelResponse,
    currentAdminLevelResponse
  ] = await Promise.all([
    fetchServices.companyDetails(),
    fetchServices.adminCurrentPermissions(),
    fetchServices.currentAdminLevel()
  ])

  const companyDetails = companyDetailsResponse.body
  const currentPermissions = adminLevelResponse.body
  const currentAdminLevel = currentAdminLevelResponse.body
  const isExpired = isSubscriptionExpired(companyDetails.subscriptionEndDate)
  const isNotAllowedToAccessUac = !CompanyDetailsHelper.canAccessUnifiedAdminConsole(
    companyDetails
  )

  if (CompanyDetailsHelper.hasAnyPermission(companyDetails)) {
    if (isNotAllowedToAccessUac) {
      redirectWhenSubscriptionExpired(config, cidOverride)
      return
    }
  } else if (isExpired) {
    redirectWhenSubscriptionExpired(config, cidOverride)
    return
  }

  const isTrackingEnabledForCurrentUser = await fetchServices
    .currentUserSettings()
    .then(
      currentUserSettingsResponse =>
        currentUserSettingsResponse.type === SUCCESS &&
        currentUserSettingsResponse.body.isTrackingAccepted
    )

  const segmentWhitelist: (keyof UACTracking.Plan)[] = [
    'Admin Dashboard Viewed',
    'Users Overview Viewed',
    'Send LastPass Invitation Clicked',
    'Resend LastPass Invitation Clicked'
  ]
  const trackingMiddleware = createTrackingMiddleware({
    enabledSelector: () => isTrackingEnabledForCurrentUser,
    whitelistedEvents: {
      segment: segmentWhitelist
    }
  })
  const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
  })
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      store.dispatch({
        type: GlobalActionTypes.SHOW_GLOBAL_ERROR,
        payload: { showError: true }
      })
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`SAGA unhandled error, ${error}`)
      }
    }
  })
  const store = createAppStore(
    history,
    sagaMiddleware,
    routerMiddleware(history),
    trackingMiddleware,
    genericFailedNotificationCounter
  )

  if (cidOverride) {
    await CidOverrideRepository.set(cidOverride)
    store.dispatch(companyInfoActions.setCidOverride(cidOverride.companyId))
  }

  if (!userProfile) {
    return
  }

  let LDProvider
  if (defaultConfig.launchDarklyMockAddress) {
    await initMockedFeatureFlags(userProfile.userId)
  } else {
    LDProvider = await asyncWithLDProvider({
      clientSideID: config.ldClientSideId,
      reactOptions: {
        useCamelCaseFlagKeys: false
      },
      context: {
        kind: 'user',
        key: userProfile.userId,
        anonymous: false,
        cid: companyDetails.accountNumber,
        uid: userProfile.userId
      },
      options: {
        bootstrap: 'localStorage'
      }
    })
  }

  await initLocalization()

  const App = createApp(
    store,
    sagaMiddleware,
    trackingMiddleware,
    companyDetails,
    currentPermissions,
    currentAdminLevel,
    userProfile.userId,
    history,
    fetchServices,
    config
  )

  const domElements = defaultConfig.launchDarklyMockAddress ? (
    <App />
  ) : (
    <LDProvider>
      <App />
    </LDProvider>
  )
  ReactDOM.render(domElements, document.getElementById('root'))
}

/**
 * In production we deploy compiled applications as part of docker containers
 * and allow configuration to be supplied by docker configuration at runtime
 *
 * For development, we initialize automatically with configuration from
 * .env files
 */
initialize()

import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

export const usePathParams = () => {
  const match = useRouteMatch()
  const matchUrl = match && match.url
  /**
   * Memoize the url params so we know when to do a data fetch. useRouteMatch
   * returns a different object each time so we need to use an immutable
   * object like the match.url as the dependency defining when the pathParams
   * should be updated
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pathParams: any = useMemo(() => {
    if (match) {
      return match.params
    }
    return {}
    /* eslint-disable-next-line */
  }, [matchUrl])

  return pathParams
}

import React, { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch
} from 'react-router-dom'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { defaultConfig } from '@lastpass/admin-console/src/default-config'
import { ReactComponent as EmailNotificationIcon } from '@lastpass/assets/svg/admin-console/icon-alarm-bell.svg'
import { ReactComponent as CustomizedIcon } from '@lastpass/assets/svg/admin-console/icon-customized.svg'
import { ReactComponent as EnterpriseAPIIcon } from '@lastpass/assets/svg/admin-console/icon-enterprise-api.svg'
import { ReactComponent as KeysIcon } from '@lastpass/assets/svg/admin-console/icon-keys.svg'
import { ReactComponent as LoginIcon } from '@lastpass/assets/svg/admin-console/icon-login.svg'
import { ReactComponent as WindowDownloadIcon } from '@lastpass/assets/svg/admin-console/icon-window-download.svg'
import { ContentContainer, SidebarContainer } from '@lastpass/lastkit'
import { SideNavigation } from '@lastpass/lastkit/components/SideNavigation'
import { RoutedSideNavigationItem } from '@lastpass/routing'

import { getIdentityIFrameUrl } from '@lastpass/admin-console-dependencies/services'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { systemUpgradeActions } from '@lastpass/admin-console-dependencies/state/upgrade/actions'
import { SystemUpgradeState } from '@lastpass/admin-console-dependencies/state/upgrade/state'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import { InstallSoftwaresContainer } from '@lastpass/admin-console-dependencies/ui/advanced/install-software/InstallSoftwareContainer'
import { IntegrationsPage } from '@lastpass/admin-console-dependencies/ui/advanced/integrations/IntegrationsPage'
import { SiemIntegrations } from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/SiemIntegrations'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'
import {
  EmptyPageAfterSystemUpgrade,
  EmptyPageTextBlock
} from '@lastpass/admin-console-dependencies/ui/upgrade/system/EmptyPageAfterSystemUpgrade'

import { FederatedLoginPage } from '../users/federated-login/FederatedLoginPage'
import { EmailNotificationsPage } from './email-notifications/EmailNotoficationsPage'
import { createEnterpriseApiPage } from './enterprise-api/EnterpriseApiPage'
import { EnterpriseOptionsPage } from './enterprise-options/EnterpriseOptionsPage'
import { ManagedCompaniesPage } from './managed-companies/ManagedCompaniesPage'
import { PsaIntegrationsPage } from './psa-integrations/PsaIntegrationsPage'

const EnterpriseApiPage = createEnterpriseApiPage<AppState>()

export const AdvancedPageOverlayContainerID = 'advancedPageOverlayContainer'

export const AdvancedPage: FunctionComponent = () => {
  const dispatch = useDispatch()

  const isFederatedLoginEnabled = useFeatureFlags(
    FeatureFlags.isFederatedLoginEnabled
  )

  const isSiemIntegrationEnabled = useFeatureFlags(
    FeatureFlags.isSiemIntegrationEnabled
  )
  const permissions = useContext(PermissionContext)
  const isPsaIntegrationEnabled =
    useFeatureFlags(FeatureFlags.isPsaIntegrationEnabled) &&
    permissions.requirePermission(
      AdminPermissions.viewManagedServiceProviderPsa
    )

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const isNoPasswordDeprecated = useFeatureFlags(
    FeatureFlags.isNoPasswordDeprecated
  )

  const isAcronisApiEnabled =
    CompanyDetailsHelper.isMsp(companyDetails) ||
    CompanyDetailsHelper.isAggregatorMsp(companyDetails)

  const hasAccessToIntegrationsPage =
    isSiemIntegrationEnabled || isAcronisApiEnabled

  const cidOverride = useSelector<AppState, string | undefined>(
    state => state.companyInfo.cidOverride
  )

  const systemUpgradeState = useSelector<AppState, SystemUpgradeState>(
    appState => appState.systemUpgrade
  )

  useEffect(() => {
    dispatch(systemUpgradeActions.fetchIsMigrated())
  }, [dispatch])

  const match = useRouteMatch()
  const urlRules = '/urlRules'

  if (match) {
    const { path: matchPath, url: matchUrl } = match
    const [
      enterpriseOptions,
      enterpriseApi,
      integrations,
      installSoftware,
      federatedLogin,
      emailNotification,
      managedCompanies,
      keys,
      siemIntegrations,
      psaIntegrations
    ] = [
      'enterpriseOptions',
      'enterpriseApi',
      'integrations',
      'installSoftware',
      'federatedLogin',
      'emailNotification',
      'managedCompanies',
      'keys',
      'siemIntegrations',
      'psaIntegrations'
    ].map(path => {
      const route: RouteProps = { path: `${matchPath}/${path}` }
      const url = `${matchUrl}/${path}`
      return {
        route,
        url
      }
    })
    return (
      <>
        <div id="advancedPageOverlayContainer" />
        <SidebarContainer>
          <SideNavigation>
            <RoutedSideNavigationItem
              data-qa={'SideMenuEnterpriseOptions'}
              to={enterpriseOptions.url + urlRules}
              route={enterpriseOptions.route}
              icon={CustomizedIcon}
            >
              <Trans>Enterprise options</Trans>
            </RoutedSideNavigationItem>
            <RoutedSideNavigationItem
              data-qa={'SideMenuEnterpriseApi'}
              to={enterpriseApi.url}
              route={enterpriseApi.route}
              icon={EnterpriseAPIIcon}
            >
              <Trans>Enterprise API</Trans>
            </RoutedSideNavigationItem>
            {hasAccessToIntegrationsPage && (
              <RoutedSideNavigationItem
                data-qa={'SideMenuIntegrations'}
                to={
                  integrations.url +
                  (isAcronisApiEnabled ? '/acronisApi' : '/siemIntegrations')
                }
                route={integrations.route}
                icon={CustomizedIcon}
              >
                <Trans>Integrations</Trans>
              </RoutedSideNavigationItem>
            )}
            <RoutedSideNavigationItem
              data-qa={'SideMenuInstallSoftware'}
              to={installSoftware.url}
              route={installSoftware.route}
              icon={WindowDownloadIcon}
            >
              <Trans>Install software</Trans>
            </RoutedSideNavigationItem>
            {!isFederatedLoginEnabled && (
              <RoutedSideNavigationItem
                data-qa={'SideMenuFederatedLogin'}
                to={federatedLogin.url}
                route={federatedLogin.route}
                icon={LoginIcon}
              >
                <Trans>Federated login</Trans>
              </RoutedSideNavigationItem>
            )}
            <RoutedSideNavigationItem
              data-qa={'SideMenuEmailNotification'}
              to={emailNotification.url}
              route={emailNotification.route}
              icon={EmailNotificationIcon}
            >
              <Trans>Email notifications</Trans>
            </RoutedSideNavigationItem>
            {!isNoPasswordDeprecated && (
              <RoutedSideNavigationItem
                data-qa={'SideMenuKeys'}
                to={keys.url}
                route={keys.route}
                icon={KeysIcon}
              >
                <Trans>Keys</Trans>
              </RoutedSideNavigationItem>
            )}
            {isPsaIntegrationEnabled && (
              <RoutedSideNavigationItem
                data-qa="psaIntegrations"
                to={psaIntegrations.url}
                route={psaIntegrations.route}
                icon={CustomizedIcon}
              >
                <Trans>PSA integrations</Trans>
              </RoutedSideNavigationItem>
            )}
          </SideNavigation>
        </SidebarContainer>
        <Switch>
          <Route {...federatedLogin.route}>
            <ContentContainer>
              <FederatedLoginPage />
            </ContentContainer>
          </Route>
          <Route {...installSoftware.route}>
            <ContentContainer>
              <InstallSoftwaresContainer />
            </ContentContainer>
          </Route>
          <Route {...emailNotification.route}>
            <ContentContainer>
              <EmailNotificationsPage />
            </ContentContainer>
          </Route>
          <Route {...enterpriseApi.route}>
            <ContentContainer>
              <EnterpriseApiPage />
            </ContentContainer>
          </Route>
          {hasAccessToIntegrationsPage && (
            <Route {...integrations.route}>
              <ContentContainer>
                <IntegrationsPage />
              </ContentContainer>
            </Route>
          )}
          <Route {...enterpriseOptions.route}>
            <ContentContainer>
              <EnterpriseOptionsPage />
            </ContentContainer>
          </Route>
          <Route {...managedCompanies.route}>
            <ContentContainer>
              <ManagedCompaniesPage />
            </ContentContainer>
          </Route>
          <Route {...keys.route}>
            {!isNoPasswordDeprecated &&
              (systemUpgradeState.isMigrated ? (
                <ContentContainer>
                  <EmptyPageAfterSystemUpgrade
                    title={<Trans>Key management has been retired</Trans>}
                  >
                    <EmptyPageTextBlock>
                      <Trans>
                        SAML keys were unique private keys used for setting up
                        single sign-on between LastPass and apps or websites.
                      </Trans>
                    </EmptyPageTextBlock>
                    <EmptyPageTextBlock>
                      <Trans>
                        Due to the recent system upgrade, there’s no way to
                        reproduce this feature’s functionality. Now there’s one
                        global key in each SSO app&apos;s Details view in the
                        Certificate field.
                      </Trans>
                    </EmptyPageTextBlock>
                  </EmptyPageAfterSystemUpgrade>
                </ContentContainer>
              ) : (
                <iframe
                  css={css`
                    width: 100%;
                    height: 100%;
                  `}
                  src={getIdentityIFrameUrl(
                    `${defaultConfig.identityIframeUrl}/Keys`,
                    cidOverride
                  )}
                />
              ))}
          </Route>
          <Route {...siemIntegrations.route}>
            <ContentContainer>
              <SiemIntegrations />
            </ContentContainer>
          </Route>
          {isPsaIntegrationEnabled && (
            <Route {...psaIntegrations.route}>
              <ContentContainer>
                <PsaIntegrationsPage />
              </ContentContainer>
            </Route>
          )}
          <Route>
            <Redirect to={enterpriseOptions.url} />
          </Route>
        </Switch>
      </>
    )
  }
  return null
}

import React from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as AlienVaultIcon } from '@lastpass/assets/svg/admin-console/siem-integrations/icon-alienVault.svg'
import { Heading100, LogoCard, WithTooltip } from '@lastpass/lastkit'

import { AlienVaultIntegrationConfig } from '@lastpass/admin-console-dependencies/types/siem-integrations'
import {
  StyledCardContainer,
  StyledDescriptionRow,
  StyledInactiveExternalLink
} from '@lastpass/admin-console-dependencies/ui/advanced/siem-integrations/shared/StyledComponents'

import { AlienVaultForm } from './AlienVaultForm'

interface AlienVaultIntegrationCardProps {
  config: AlienVaultIntegrationConfig
}

export const AlienVaultIntegrationCard: React.FunctionComponent<AlienVaultIntegrationCardProps> = ({
  config
}) => (
  <LogoCard
    dataQaName="SiemAlienVaultCard"
    logo={<AlienVaultIcon />}
    title={
      <Heading100>
        <Trans>AlienVault USM Anywhere</Trans>
      </Heading100>
    }
    subtitle={
      <Trans>
        Configure LastPass to send audit events to an AlienVault USM Anywhere
        instance in near real-time.
      </Trans>
    }
  >
    <StyledCardContainer>
      <StyledDescriptionRow>
        <Trans>
          LastPass related events will be visible in AlienVault USM Anywhere.
        </Trans>
        <WithTooltip
          tooltip={<Trans>Documentation still to come</Trans>}
          placement="right"
        >
          <StyledInactiveExternalLink
            data-qa="SiemAlienVaultInstructionsLink"
            href="#" // Waiting for documents to be created
            target="_blank"
            onClick={e => e.preventDefault()}
          >
            <Trans>View setup instructions</Trans>
          </StyledInactiveExternalLink>
        </WithTooltip>
      </StyledDescriptionRow>
      <AlienVaultForm config={config} />
    </StyledCardContainer>
  </LogoCard>
)

import React from 'react'

import { AppDiscovery } from '@lastpass/admin-console-dependencies/ui/applications/app-discovery/AppDiscovery'

interface AppDiscoveryPageProps {
  onClose?: () => void
}

export const AppDiscoveryPage: React.FunctionComponent<AppDiscoveryPageProps> = () => {
  return (
    <>
      <AppDiscovery />
    </>
  )
}

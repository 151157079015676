import React, { FC, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import { saveAs } from 'file-saver'

import { TextButton } from '@lastpass/lastkit'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'

interface ExportButtonProps {
  onIsLoading: (isLoading: boolean) => void
  odataFilter: string
  fileName: string
}

export const ExportButton: FC<ExportButtonProps> = ({
  onIsLoading,
  odataFilter,
  fileName
}) => {
  const dispatch = useDispatch()
  const { exportUsersWithFilter } = useContext(ServerContext)

  const exportUsersWithPendingInvitation = useCallback(async (): Promise<
    void
  > => {
    try {
      onIsLoading(true)
      const { body: csv } = await exportUsersWithFilter(odataFilter)
      const blobToSave = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      })
      saveAs(blobToSave, fileName)
    } catch {
      dispatch(globalActions.setNotification(genericFailedNotification))
    } finally {
      onIsLoading(false)
    }
  }, [dispatch, exportUsersWithFilter, fileName, odataFilter, onIsLoading])

  return (
    <TextButton
      blue
      onClick={exportUsersWithPendingInvitation}
      css={css`
        margin-right: 16px;
      `}
    >
      <Trans>Export users</Trans>
    </TextButton>
  )
}

import React, { FunctionComponent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { ReactComponent as CommunityIcon } from '@lastpass/assets/svg/admin-console/community-icon.svg'
import { ReactComponent as SupportQuestionIcon } from '@lastpass/assets/svg/admin-console/support-question-icon.svg'
import { BodyRegular, Heading100, TextButton } from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'

const StyledSupportQuestionIcon = styled(SupportQuestionIcon)`
  margin-right: 16px;
`

const StyledCommunityIcon = styled(CommunityIcon)`
  margin-right: 16px;
`

const StyledHeading100 = styled(Heading100)`
  margin-bottom: 8px;
`

const StyledTextButton = styled(TextButton)`
  margin: 8px 0 0 -4px;
`

const SupportSection = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${props => props.theme.colors.neutral300};
  border-radius: ${props => props.theme.radius.pixel4};
  padding: 16px;
  align-items: top;
  box-sizing: border-box;
  margin-bottom: 16px;
`

export const SupportStatusSection: FunctionComponent = () => (
  <SupportSection>
    <div>
      <StyledSupportQuestionIcon />
    </div>
    <div>
      <StyledHeading100 data-qa="SupportDialogStatusHeader">
        <Trans>LastPass status</Trans>
      </StyledHeading100>
      <BodyRegular>
        <Trans>
          Check our status page for any known issues. Depending on the systems
          or services impacted, you may not see the same issues as other
          customers.
        </Trans>
      </BodyRegular>
      <LocationLink
        to="https://status.lastpass.com/"
        target="_blank"
        data-qa="SupportDialogStatusButton"
      >
        <StyledTextButton
          blue
          css={css`
            display: block;
          `}
        >
          <Trans>Visit the status page</Trans>
        </StyledTextButton>
      </LocationLink>
    </div>
  </SupportSection>
)

export const SupportCommunitySection: FunctionComponent = () => (
  <SupportSection>
    <div>
      <StyledCommunityIcon />
    </div>
    <div>
      <StyledHeading100 data-qa="SupportDialogCommunityHeader">
        <Trans>Community</Trans>
      </StyledHeading100>
      <BodyRegular>
        <Trans>
          Join the conversation in the LastPass Community. Chat with other
          LastPass customers, report issues, make suggestions for improvements,
          and share your recommendations.
        </Trans>
      </BodyRegular>
      <LocationLink
        to="https://support.lastpass.com/s/community?language=en_US"
        target="_blank"
        data-qa="SupportDialogCommunityButton"
      >
        <StyledTextButton
          blue
          css={css`
            display: block;
          `}
        >
          <Trans>Visit the forums</Trans>
        </StyledTextButton>
      </LocationLink>
    </div>
  </SupportSection>
)

export const SupportCenterSection: FunctionComponent = () => (
  <SupportSection>
    <div>
      <StyledSupportQuestionIcon />
    </div>
    <div>
      <StyledHeading100 data-qa="SupportDialogCenterHeader">
        <Trans>Support center</Trans>
      </StyledHeading100>
      <BodyRegular>
        <Trans>
          Everything you need to know about LastPass in one helpful place.
          Search our online library of help articles, user guides, and FAQs, or
          submit a support case to our customer support team.
        </Trans>
      </BodyRegular>
      <LocationLink
        to="https://link.lastpass.com/help-support-site"
        target="_blank"
        data-qa="SupportDialogCenterButton"
      >
        <StyledTextButton
          blue
          css={css`
            display: block;
          `}
        >
          <Trans>Find solutions and assistance here</Trans>
        </StyledTextButton>
      </LocationLink>
    </div>
  </SupportSection>
)

import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import {
  userOperationActions,
  UserOperationActionTypes
} from '@lastpass/admin-console-dependencies/state/users/view/operations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'

import { invokeApi } from './operation-base'

export function createDeselectForFederationSaga(
  userService: UACServices.Services
) {
  return function* deselectForFederationSaga(
    action: ReturnType<typeof userOperationActions.deselectForFederation>
  ) {
    try {
      const users = action.payload.userOperationPayload.users
      let text: MessageDescriptor = msg`Disable federated login for the selected users?`

      if (users.length === 1) {
        text = msg`Disable federated login for this user? ${users[0].email}`
      }

      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Disable federated login`,
        text,
        discardText: msg`Cancel`,
        confirmText: msg`Disable`
      }

      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])
      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield invokeApi(
          userService,
          users,
          {
            MultipleUsersFail: msg`Federated login couldn't be disabled for the selected users.`,
            MultipleUsersSuccess: msg`Federated login has been disabled for the selected users.`,
            SingleUserFail:
              users.length > 0
                ? msg`Federated login couldn't be disabled for this user: ${users[0].email}`
                : undefined,
            SingleUserSuccess:
              users.length > 0
                ? msg`Federated login has been disabled for this user: ${users[0].email}`
                : undefined,
            PartialSuccess: msg`Federated login has been disabled for some of the selected users.`
          },
          'deselectforfederation',
          action.payload.userOperationPayload.origin,
          {
            userIds: users.map(u => u.id),
            selectAll: action.payload.userOperationPayload.isAllSelected
          },
          UserOperationActionTypes.DESELECT_FOR_FEDERATION,
          action.payload.userOperationPayload.filter
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { all, call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { GeneralPoliciesListGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/actions'
import { TeamsPolicy } from '@lastpass/admin-console-dependencies/types/policy-teams'
import { mapGetPolicyListResultToPolicyMap } from '@lastpass/admin-console-dependencies/ui/common/mappings/policy-response-to-policy-map-mapper'

const policyKeys = [
  'requirelastpassmfa',
  'requiresesame',
  'requiremicrosoftauth',
  'requireduo',
  'requiresalesforcehash',
  'requiresymantecvip',
  'requirelastpassauth',
  'requireyubikey',
  'requiregoogleauth',
  'requiretoopher',
  'requiretransakt',
  'requiresecurid',
  'requiremultifactor',
  'requiresecureauth'
]

export function createInitialData(services: UACServices.Services) {
  return function* getInitialData() {
    try {
      yield put(multifactorActions.setLoading(true))
      try {
        const [generalPoliciesListGetResponse] = yield all([
          call(services.generalPoliciesListGet)
        ])

        if (
          generalPoliciesListGetResponse.type ===
          GeneralPoliciesListGetAPI.SUCCESS
        ) {
          const [policyMap, policyList] = mapGetPolicyListResultToPolicyMap(
            generalPoliciesListGetResponse.body.generalPoliciesListResponse
          )
          const mainPolicies: TeamsPolicy[] = policyKeys
            .map(policyKey => policyMap[policyKey])
            .filter(policy => !!policy)
          const additionalPoliciesKeys = [
            'countryrestrict',
            'iprestrict',
            'lpauthenticatorgeoloc',
            'overridelastpassauthmethods',
            'lpauthenticatorrequireextraauth'
          ]
          const additionalPolicies: TeamsPolicy[] = policyList.filter(
            policy =>
              (policyKeys.indexOf(policy.policyKey) < 0 &&
                policy.category === 'multifactor') ||
              additionalPoliciesKeys.includes(policy.policyKey)
          )

          additionalPolicies.sort((a, b) => {
            return (
              additionalPoliciesKeys.indexOf(b.policyKey) -
              additionalPoliciesKeys.indexOf(a.policyKey)
            )
          })

          yield put(multifactorActions.setRequireOfPolicies(mainPolicies))
          yield put(
            multifactorActions.setAdditionalPolicies(additionalPolicies)
          )
        }
      } finally {
        yield put(multifactorActions.setLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { NO_KEYS_WERE_SHARED_ERROR } from '@lastpass/admin-console-dependencies/server/users/recovery-keys/recovery-key-sharing-details'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { distributeRecoveryKeysActions } from '@lastpass/admin-console-dependencies/state/users/view/operations/distribute-recovery-keys/actions'
import { NoKeysWereSharedErrorDialogType } from '@lastpass/admin-console-dependencies/types/dialog-types'

export const noKeysWereSharedError: NoKeysWereSharedErrorDialogType = {
  type: 'no-keys-were-shared-error-dialog'
}

export const getSelectedUsers = (state: AppState) =>
  state.usersList.table.checkedRecords

export function createGetRecoveryKeySharingDetails(
  userService: UACServices.Services
) {
  return function* getRecoveryKeySharingDetails() {
    try {
      yield put(distributeRecoveryKeysActions.reset())
      yield put(distributeRecoveryKeysActions.setIsLoading(true))

      const selectedUsers = yield select(getSelectedUsers)
      const targetAdmin = selectedUsers[0]

      yield put(
        distributeRecoveryKeysActions.setTargetAdminEmail(targetAdmin.email)
      )

      const result: UACServices.RecoveryKeyDistributionDetailsAPI.Responses = yield call(
        userService.recoveryKeyDistributionDetails,
        [targetAdmin.id]
      )

      if (
        result.type !== UACServices.RecoveryKeyDistributionDetailsAPI.SUCCESS
      ) {
        throw new Error(NO_KEYS_WERE_SHARED_ERROR)
      }

      yield put(
        distributeRecoveryKeysActions.setRecoveryKeySharingDetails(result.body)
      )
      yield put(distributeRecoveryKeysActions.setIsLoading(false))
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === NO_KEYS_WERE_SHARED_ERROR) {
          yield put(globalActions.setDialog(noKeysWereSharedError))
        }
      } else {
        yield put(globalActions.setNotification(genericFailedNotification))
      }
    }
  }
}

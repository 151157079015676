import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'

export const SUCCESS = 'success'

const success = (permissions: AdminPermissions[]) =>
  createResponse(SUCCESS, permissions)

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    const [response, status] = await fetch(
      'adminLevels/currentPermissions',
      'GET'
    )
    if (status === StatusCodes.OK && response) {
      const responseData = JSON.parse(response)
      const adminList = responseData.content
      return success(adminList)
    }
    throw new UnknownServiceError()
  }
}

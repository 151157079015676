import React, { FunctionComponent, useContext } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  TextButton
} from '@lastpass/lastkit'

import { PsaDrawerContext } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/common/drawer/PsaDrawer'

const ButtonSpacing = css`
  margin-right: 16px;
`

const StyledTextButton = styled(TextButton)`
  ${ButtonSpacing}
`

const StyledPrimaryButton = styled(PrimaryButton)`
  ${ButtonSpacing}
`

export interface PsaDrawerFooterProps {
  onSave: () => void
  isSaveButtonDisabled: boolean
}

export const PsaDrawerFooter: FunctionComponent<PsaDrawerFooterProps> = ({
  isSaveButtonDisabled,
  onSave
}) => {
  const { closeDrawer } = useContext(PsaDrawerContext)

  return (
    <FooterContainer>
      <DrawerButtonsContainer>
        <StyledTextButton
          data-qa="DiscardButton"
          type="button"
          onClick={closeDrawer}
        >
          <Trans>Discard</Trans>
        </StyledTextButton>

        <StyledPrimaryButton
          data-qa="SaveButton"
          type="submit"
          disabled={isSaveButtonDisabled}
          onClick={onSave}
        >
          <Trans>Save</Trans>
        </StyledPrimaryButton>
      </DrawerButtonsContainer>
    </FooterContainer>
  )
}

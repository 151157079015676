import { PsaIntegrationsActions, PsaIntegrationsActionTypes } from './actions'
import { PsaIntegrationsState } from './state'

export const initialState: PsaIntegrationsState = {
  activePsa: null,
  metadata: [],
  psaInProgress: false,
  drawerInProgress: false,
  drawerContainsUnsavedChanges: false,
  psaEditCredentialsFormValues: null,
  agreements: {
    assignmentOptions: [],
    isLoading: false
  },
  products: {
    results: [],
    resultsPerPage: 25,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function psaIntegrationsReducer(
  state: PsaIntegrationsState = initialState,
  action: PsaIntegrationsActions
): PsaIntegrationsState {
  switch (action.type) {
    case PsaIntegrationsActionTypes.SET_PSA_PROVIDER_DETAILS:
      return {
        ...state,
        activePsa: action.payload
      }

    case PsaIntegrationsActionTypes.SET_PSA_CREDENTIALS:
      return {
        ...state,
        metadata: action.payload
      }

    case PsaIntegrationsActionTypes.SET_PSA_IN_PROGRESS:
      return {
        ...state,
        psaInProgress: action.payload
      }

    case PsaIntegrationsActionTypes.SET_DRAWER_IN_PROGRESS:
      return {
        ...state,
        drawerInProgress: action.payload
      }

    case PsaIntegrationsActionTypes.SET_DRAWER_CONTAINS_UNSAVED_CHANGES:
      return {
        ...state,
        drawerContainsUnsavedChanges: action.payload
      }

    case PsaIntegrationsActionTypes.SET_PSA_EDIT_CREDENTIALS_FORM_VALUES:
      return {
        ...state,
        psaEditCredentialsFormValues: {
          ...state.psaEditCredentialsFormValues,
          [action.payload.provider]: action.payload.formValues
        }
      }

    case PsaIntegrationsActionTypes.SET_PSA_AGREEMENTS_ASSIGNMENT_OPTIONS:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          assignmentOptions: action.payload
        }
      }

    case PsaIntegrationsActionTypes.SET_PSA_AGREEMENTS_TABLE_IN_PROGRESS:
      return {
        ...state,
        agreements: {
          ...state.agreements,
          isLoading: action.payload
        }
      }

    case PsaIntegrationsActionTypes.SET_PSA_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          results: action.payload.products,
          totalResults: action.payload.totalResults,
          resultsPerPage: action.payload.resultsPerPage,
          isLoading: false
        }
      }

    case PsaIntegrationsActionTypes.SET_PSA_PRODUCTS_TABLE_IN_PROGRESS:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: action.payload
        }
      }

    case PsaIntegrationsActionTypes.GET_PSA_PROVIDER_DETAILS:
    default:
      return state
  }
}

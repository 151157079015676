import { PsaCompanyContractAssignment } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/state'
import { AssignmentsMapping } from '@lastpass/admin-console-dependencies/ui/advanced/psa-integrations/agreements/types/assignments-mapping-type'

type CreateAssignmentChangesList = (
  initialAssignmentsMap: AssignmentsMapping,
  actualAssignmentsMap: AssignmentsMapping
) => PsaCompanyContractAssignment[]

export const createAssignmentChangesList: CreateAssignmentChangesList = (
  initialAssignmentsMap,
  actualAssignmentsMap
) => {
  return Object.keys(initialAssignmentsMap).reduce<
    PsaCompanyContractAssignment[]
  >((acc, companyId) => {
    const initialAssignmentsMapItem = initialAssignmentsMap[companyId]
    const actualAssignmentsMapItem = actualAssignmentsMap[companyId]

    if (
      initialAssignmentsMapItem.contractId !==
        actualAssignmentsMapItem.contractId ||
      initialAssignmentsMapItem.assignmentStatus !==
        actualAssignmentsMapItem.assignmentStatus
    ) {
      acc.push({
        companyId: Number(companyId),
        contractId: actualAssignmentsMapItem.contractId,
        contractName: actualAssignmentsMapItem.contractName,
        assignmentStatus: actualAssignmentsMapItem.assignmentStatus
      })
    }
    return acc
  }, [])
}

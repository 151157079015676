import { msg } from '@lingui/macro'
import { call, put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import * as Mfa from '@lastpass/admin-console-dependencies/server/applications/mfa'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { getQueryParams } from '@lastpass/admin-console-dependencies/services'
import { mfaAppsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/drawer/actions'
import { mfaApplicationListActions } from '@lastpass/admin-console-dependencies/state/applications/mfa/list/actions'
import {
  MfaApplication,
  MfaApplicationType
} from '@lastpass/admin-console-dependencies/state/applications/mfa/mfa-application'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { MfaApp } from '@lastpass/admin-console-dependencies/types/mfa-applications-teams'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'
import {
  mapMfaApplicationResponse,
  oauthKeyToMfaApp
} from '@lastpass/admin-console-dependencies/ui/common/mappings/mfaapp-mappers'

export const getCurrentUrlFilters = (state: AppState) => state.router

export function createAddMfaApplication(
  applicationsServices: UACServices.Services
) {
  return function* addMfaApplication(
    action: ReturnType<typeof mfaAppsDrawerActions.addApp>
  ) {
    try {
      yield put(mfaAppsDrawerActions.setDrawerLoading(true))

      const name = `${action.payload.app.type}#${action.payload.app.name}`

      let appResult: MfaApp | null = null
      if (action.payload.app.type === MfaApplicationType.azureAD) {
        const response: Mfa.AzureMfaApplicationAddAPI.Responses = yield call(
          applicationsServices.addAzureMfaApplication
        )
        if (response.type === Mfa.AzureMfaApplicationAddAPI.SUCCESS) {
          appResult = oauthKeyToMfaApp(name, response.body.oauthKey)
        }
      } else {
        const response: Mfa.MfaApplicationAddAPI.Responses = yield call(
          applicationsServices.addMfaApplication,
          name
        )
        if (response.type === Mfa.MfaApplicationAddAPI.SUCCESS) {
          appResult = response.body.mfaAppResponse
        }
      }

      if (appResult != null) {
        if (action.payload.app.type !== MfaApplicationType.azureAD) {
          const response: Mfa.MfaMappingKeyGetApi.Responses = yield call(
            applicationsServices.getMfaMappingKeys
          )
          if (response.type === Mfa.MfaMappingKeyGetApi.SUCCESS) {
            yield put(
              mfaAppsDrawerActions.setPotentialMappingKeys(response.body)
            )
          }
        }

        const app: MfaApplication = mapMfaApplicationResponse(appResult)
        app.secret = appResult.integrationSecret

        yield put(mfaAppsDrawerActions.setApp(app))
        yield put(
          globalActions.setNotification({
            message: msg`App added.`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
        const currentUrlFilters = yield select(getCurrentUrlFilters)
        yield put(
          mfaApplicationListActions.getMfaApplicationList({
            query: getQueryParams(currentUrlFilters.location, 'apps'),
            path: {}
          })
        )
        yield put(mfaAppsDrawerActions.finish())
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    } finally {
      yield put(mfaAppsDrawerActions.setDrawerLoading(false))
    }
  }
}

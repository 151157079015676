import { DataFetchParams } from '@lastpass/routing'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import {
  User,
  UserProfile
} from '@lastpass/admin-console-dependencies/types/user'

export enum TransferVaultDrawerActionTypes {
  GET_TRANSFER_VAULT_DRAWER_DATA = '[transfer-vault-drawer] get',
  SET_SOURCE_USER_DATA = '[transfer-vault-drawer] set-source-user-data',
  SET_IS_LOADING = '[transfer-vault-drawer] set-is-loading',
  SET_USERS = '[transfer-vault-drawer] set-users',
  SELECT_TARGET_USER = '[transfer-vault-drawer] set-target-user',
  REMOVE_TARGET_USER = '[transfer-vault-drawer] remove-target-user',
  OPEN_WINDOW = '[transfer-vault-drawer] open-window',
  SET_IS_TRANSFER_COMPLETED = '[transfer-vault-drawer] set-is-transfer-completed'
}

export interface TransferVaultDrawerQueryParams {
  search: string
  skip: number
}

export const transferVaultDrawerActions = {
  getTransferVaultData: (
    userId: string,
    params: DataFetchParams<TransferVaultDrawerQueryParams>,
    resultsPerPage = 25
  ) =>
    createAction(
      TransferVaultDrawerActionTypes.GET_TRANSFER_VAULT_DRAWER_DATA,
      {
        userId,
        params,
        resultsPerPage
      }
    ),
  setUsers: (
    userList: User[],
    totalResults: number,
    totalCompanyUsers: number
  ) =>
    createAction(TransferVaultDrawerActionTypes.SET_USERS, {
      userList,
      totalResults,
      totalCompanyUsers
    }),
  setSourceUserData: (userProfile: UserProfile) =>
    createAction(
      TransferVaultDrawerActionTypes.SET_SOURCE_USER_DATA,
      userProfile
    ),
  setIsLoading: (isLoading: boolean) =>
    createAction(TransferVaultDrawerActionTypes.SET_IS_LOADING, isLoading),
  selectTargetUser: (user: User) =>
    createAction(TransferVaultDrawerActionTypes.SELECT_TARGET_USER, user),
  removeTargetUser: () =>
    createAction(TransferVaultDrawerActionTypes.REMOVE_TARGET_USER),
  openWindow: () => createAction(TransferVaultDrawerActionTypes.OPEN_WINDOW),
  setIsTransferCompleted: (isTransferCompleted: boolean) =>
    createAction(
      TransferVaultDrawerActionTypes.SET_IS_TRANSFER_COMPLETED,
      isTransferCompleted
    )
}

export type TransferVaultDrawerActions = ActionsUnion<
  typeof transferVaultDrawerActions
>

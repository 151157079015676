import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import { ReactComponent as UserIcon } from '@lastpass/assets/svg/admin-console/icon-user-list.svg'
import { Loading } from '@lastpass/components/Loading'
import {
  BodyRegular,
  CardContainer,
  DrawerButtonsContainer,
  FooterContainer,
  Heading100,
  Pagination,
  PrimaryButton,
  RowContainer,
  StyledSpacer,
  Table,
  TableView,
  TableViewButton,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import {
  connectRedux,
  LastPassComponentProps,
  useUnmountEffect
} from '@lastpass/ui'

import { dashboardDrawerActions } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/actions'
import { DashboardDrawerState } from '@lastpass/admin-console-dependencies/state/dashboard/drawer/state'
import { User } from '@lastpass/admin-console-dependencies/types/user'

export type StagedUsersDrawerProps = LastPassComponentProps<
  DashboardDrawerState,
  typeof dashboardDrawerActions
>

const StyledRowContainer = styled(RowContainer)`
  align-items: center;
  padding: 18px;
`

export const StagedUsersDrawerComponent: React.FunctionComponent<StagedUsersDrawerProps> = ({
  state,
  actions
}) => {
  const queryParams = useQueryParams(Namespace.stagedUsers)
  const updateQuery = useUpdateQuery(Namespace.stagedUsers)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      dashboardDrawerActions.getStagedUsers({ query: queryParams, path: {} })
    )
  }, [dispatch, queryParams])

  useUnmountEffect(() => {
    actions.reset()
  })

  return (
    <>
      {state.dashboardDrawerLoading && (
        <Loading color="blue900" active={true} />
      )}
      <BodyRegular>
        <p>
          <Trans>
            Send an invitation email to <b>{state.stagedUsers.totalResults}</b>{' '}
            users who were added to LastPass without being invited.
          </Trans>
        </p>
        <p>
          <Trans>
            <b>Pro tip:</b> To invite users individually, go to the Users page.
            You can also export to CSV.
          </Trans>
        </p>
      </BodyRegular>
      <CardContainer
        css={css`
          margin-top: 24px;
        `}
      >
        <StyledRowContainer>
          <Heading100>
            <Trans>Users</Trans>
          </Heading100>
          <Pagination
            skip={queryParams.skip || 0}
            totalResults={state.stagedUsers.totalResults}
            resultsPerPage={state.stagedUsers.resultsPerPage}
            updateSkip={skip => {
              updateQuery({ skip: skip || undefined })
            }}
          />
        </StyledRowContainer>
        <Table
          qadata={'StagedUsersDrawerTable'}
          table={state.stagedUsers}
          columns={[
            {
              name: <Trans>Name</Trans>,
              renderer: ({ firstName, lastName }: Partial<User>) =>
                `${firstName ? firstName : ''} ${
                  lastName ? lastName : ''
                }`.trim()
            },
            {
              name: <Trans>Email</Trans>,
              renderer: (record: Partial<User>) =>
                record.email ? record.email : ''
            }
          ]}
          loadingView={<Loading color="blue900" active={true} />}
          actions={[]}
          noRecordsView={
            <TableView
              icon={<UserIcon />}
              title={<Trans>No users in Staged status</Trans>}
              text={<Trans>Add users individually or in bulk.</Trans>}
              actionButton={
                <LocationLink to={'/dashboard/add'}>
                  <TableViewButton>
                    <Trans>Add users</Trans>
                  </TableViewButton>
                </LocationLink>
              }
            />
          }
        />
      </CardContainer>

      <>
        <StyledSpacer />
        <FooterContainer>
          <DrawerButtonsContainer>
            {state.stagedUsers.totalResults > 0 ? (
              <>
                <TextButton
                  blue
                  onClick={() => {
                    actions.exportStagedUsers()
                    actions.exportUsersClicked('Invite Users Drawer')
                  }}
                  css={css`
                    margin-right: 16px;
                  `}
                >
                  <Trans>Export users</Trans>
                </TextButton>
                <PrimaryButton
                  onClick={() => {
                    actions.reinviteStagedUsers()
                    actions.inviteUsersButtonClicked(
                      state.stagedUsers.totalResults
                    )
                  }}
                  css={css`
                    margin-left: 16px;
                  `}
                >
                  <Trans>Invite users</Trans>
                </PrimaryButton>
              </>
            ) : (
              <LocationLink to={'/dashboard'}>
                <PrimaryButton>
                  <Trans>Cancel</Trans>
                </PrimaryButton>
              </LocationLink>
            )}
          </DrawerButtonsContainer>
        </FooterContainer>
      </>
    </>
  )
}

export function createStagedUsersDrawer<
  TState extends { dashboardDrawer: DashboardDrawerState }
>() {
  return connectRedux(
    StagedUsersDrawerComponent,
    (state: TState) => state.dashboardDrawer,
    dashboardDrawerActions
  )
}

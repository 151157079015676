import { msg } from '@lingui/macro'
import { call, put, take } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import {
  globalActions,
  GlobalActionTypes
} from '@lastpass/admin-console-dependencies/state/global/actions'
import { directoriesActions } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/actions'
import { ConfirmDialog } from '@lastpass/admin-console-dependencies/types/dialog-types'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createResetADAPIKey(userServices: UACServices.Services) {
  return function* resetADAPIKey() {
    try {
      const text = msg`This will break all existing API clients and require you to use the new hash. You will also need to logout of the AD client and clear its cache and log back in. An email will be sent to all LastPass Enterprise Administrators informing them of the change.`
      const dialog: ConfirmDialog = {
        type: 'confirmdialog',
        title: msg`Reset your API Secret Key?`,
        text,
        discardText: msg`CANCEL`,
        confirmText: msg`OK`
      }
      yield put(globalActions.setDialog(dialog))
      const confirmClick = yield take([
        GlobalActionTypes.CONFIRM_DIALOG,
        GlobalActionTypes.DISCARD_DIALOG
      ])

      if (confirmClick.type === GlobalActionTypes.CONFIRM_DIALOG) {
        yield put(directoriesActions.setDirectoryIntegrationsLoading(true))
        try {
          const resetAPIKeyResult: UACServices.ADConnectorKeyResetAPI.Responses = yield call(
            userServices.adConnectorAPIKeyReset
          )
          if (
            resetAPIKeyResult.type ===
            UACServices.ADConnectorKeyResetAPI.SUCCESS
          ) {
            yield put(directoriesActions.setADConnectorAPIKeyExistence(false))
            yield put(
              globalActions.setNotification({
                message: msg`API key has been reset`,
                type: NotificationType.success,
                autoDismiss: true
              })
            )
          } else {
            yield put(
              globalActions.setNotification({
                message: msg`API key reset failed`,
                type: NotificationType.alert,
                autoDismiss: true
              })
            )
          }
        } finally {
          yield put(directoriesActions.setDirectoryIntegrationsLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

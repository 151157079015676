import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import {
  genericFailedNotification,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { securityReportDrawerActions } from '@lastpass/admin-console-dependencies/state/reports/security/drawer/actions'

export function createGetRecoveryKeyDecryptionDataSaga(
  service: UACServices.Services
) {
  return function* getRecoveryKeyDecryptionData() {
    try {
      const recoveryKeyDownloadResponse: UACServices.RecoveryKeyDownloadAPI.Responses = yield call(
        service.recoveryKeyDownload
      )

      if (
        recoveryKeyDownloadResponse.type !==
        UACServices.RecoveryKeyDownloadAPI.SUCCESS
      ) {
        throw new UnknownServiceError()
      }

      yield put(
        securityReportDrawerActions.setRecoveryKeyDecryptionData(
          recoveryKeyDownloadResponse.body
        )
      )
    } catch {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

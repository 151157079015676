import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { RSASecureIDRadiusGetAPI } from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { RSASecureIDRadius } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/rsa-secure-id-radius/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { multifactorDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/actions'

export function createGetRsaConfig(services: UACServices.Services) {
  return function* getRsaConfig() {
    try {
      yield put(multifactorDrawerActions.setDrawerLoading(true))
      try {
        const response = yield call(services.rsaSecureIDRadiusGet)

        if (response.type === RSASecureIDRadiusGetAPI.SUCCESS) {
          const settings: RSASecureIDRadius = response.body.rsaSecureIDRadius

          yield put(
            multifactorDrawerActions.setRsaConfig({
              failureMessage: settings.failureMessage,
              hasRadiusSharedSecret: settings.hasRadiusSharedSecret,
              logo124x124: settings.logo124x124,
              logo124x124Length: settings.logo124x124Length,
              logo190x41: settings.logo190x41,
              logo190x41Length: settings.logo190x41Length,
              radiusServerIp: settings.radiusServerIp,
              radiusSharedSecret: settings.radiusSharedSecret,
              radiusSharedSecretLength: settings.radiusSharedSecretLength,
              radiusTimeout: settings.radiusTimeout
                ? settings.radiusTimeout.toString()
                : '',
              serviceName: settings.serviceName
            })
          )
        }
      } finally {
        yield put(multifactorDrawerActions.setDrawerLoading(false))
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

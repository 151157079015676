import React from 'react'
import { useDispatch } from 'react-redux'

import { ReactComponent as DownloadIcon } from '@lastpass/assets/svg/admin-console/icon-download.svg'
import { IconButton } from '@lastpass/lastkit'

import { userActivityActions } from '@lastpass/admin-console-dependencies/state/reports/view/actions'
import { AffectedSecurityReportUser } from '@lastpass/admin-console-dependencies/types/security-report'

interface WeakSecurityScoreProps {
  users: AffectedSecurityReportUser[]
}

export const WeakSecurityScoreReportButton: React.FunctionComponent<WeakSecurityScoreProps> = ({
  users
}) => {
  const dispatch = useDispatch()

  return (
    <IconButton
      icon={DownloadIcon}
      data-qa={'exportReportButton'}
      onClick={() => {
        dispatch(userActivityActions.exportWeakSecurityScoreReport(users))
      }}
    />
  )
}

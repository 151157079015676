import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { msg, Trans } from '@lingui/macro'

import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { useCalculateChartRateThreshold } from '@lastpass/admin-console-dependencies/ui/home/adoption-dashboard/accordion-containers/use-calculate-chart-rate-threshold'
import { ActionLinkCard } from '@lastpass/admin-console-dependencies/ui/home/components/card/ActionLinkCard'
import { DonutChartWithLegend } from '@lastpass/admin-console-dependencies/ui/home/components/donut-chart/DonutChartWithLegend'

export interface EnrollmentRateDonutChartCardProps {
  enrolledUsersCount: number
  invitedUsersCount: number
  expiredInvitationsCount: number
  enrollmentRate: number
  dataQa: string
}

export const EnrollmentRateDonutChartCard: FC<EnrollmentRateDonutChartCardProps> = ({
  enrolledUsersCount,
  invitedUsersCount,
  expiredInvitationsCount,
  enrollmentRate,
  dataQa
}) => {
  const dispatch = useDispatch()

  const { colors, subtitle } = useCalculateChartRateThreshold(enrollmentRate)

  return (
    <ActionLinkCard
      dataQa={dataQa}
      learnMoreAction={() =>
        window.open(
          'https://support.lastpass.com/help/what-are-the-enrollment-and-utilization-rates',
          '_blank',
          'noopener, noreferrer'
        )
      }
      showHistoryAction={() => {
        dispatch(
          globalActions.setDialog({
            type: 'enrollment-rate-dialog'
          })
        )
      }}
    >
      <DonutChartWithLegend
        totalText={msg`Total invitations sent`}
        items={[
          {
            value: enrolledUsersCount,
            label: msg`Enrolled users`,
            backgroundColor: colors.primary,
            tooltip: <Trans>Users with LastPass status “Enrolled”</Trans>
          },
          {
            value: invitedUsersCount,
            label: msg`Pending invitations`,
            backgroundColor: colors.secondary,
            tooltip: (
              <Trans>Users with LastPass status “Pending invitation”</Trans>
            )
          },
          {
            value: expiredInvitationsCount,
            label: msg`Expired invitations`,
            backgroundColor: colors.tertiary,
            tooltip: (
              <Trans>Users with LastPass status “Expired invitation”</Trans>
            )
          }
        ]}
        percentageInfo={{
          value: enrollmentRate,
          subtitle: subtitle
        }}
        dataQa={dataQa && dataQa + 'DonutChart'}
      />
    </ActionLinkCard>
  )
}

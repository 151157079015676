import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { SecureAuthSettingsState } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/multifactor-options/secure-auth-settings/state'

export const SUCCESS = 'success'

const success = (secureAuthSettings: SecureAuthSettingsState) =>
  createResponse(SUCCESS, { secureAuthSettings })

export const Responses = {
  success
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'v3/company/secure-auth-settings',
        'GET'
      )
      if (status === StatusCodes.OK && response) {
        const { content: secureAuthSettings } = JSON.parse(response)

        return success(secureAuthSettings)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { AdminActivityActions, LoginReportActionTypes } from './actions'
import { LoginReportListState } from './state'

export const initialLoginReportListState: LoginReportListState = {
  table: {
    results: [],
    resultsPerPage: 7,
    totalResults: 0,
    checkedRecords: [],
    isLoading: false
  }
}

export function loginReportEvents(
  previousState: LoginReportListState | undefined,
  action: AdminActivityActions
): LoginReportListState {
  const state = previousState || initialLoginReportListState
  switch (action.type) {
    case LoginReportActionTypes.REPORT_SET_LOGIN_REPORT: {
      return {
        ...state,
        table: {
          ...state.table,
          results: action.payload.events
        }
      }
    }
    case LoginReportActionTypes.REPORT_SET_LOGIN_REPORT_LOADING:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: action.payload.isLoading
        }
      }
    default: {
      return state
    }
  }
}

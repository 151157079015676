import React from 'react'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  Heading100,
  Heading200,
  Table as TableComponent
} from '@lastpass/lastkit'
import { HeaderContainer } from '@lastpass/lastkit'
import { CodeExample } from '@lastpass/lastkit/components/CodeExample'
import { BodyRegularStyle } from '@lastpass/lastkit/styles'

import { Table } from '@lastpass/admin-console-dependencies/types/table'

const TextContent = styled.div`
  ${BodyRegularStyle};
  padding-bottom: 12px;
`

const PageContentContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 48px;
  margin-bottom: 18px;
  max-width: 760px;
`

const PageItemContainer = styled(Heading200)`
  margin-top: 40px;
  margin-bottom: 25px;
  padding: 0 24px;
`

const StyledTextContent = styled(TextContent)`
  margin-bottom: 24px;
`

const featuresData = [
  {
    feature: 'ChromeExtension',
    description: (
      <Trans>Install the Chrome extension and binary component</Trans>
    )
  },
  {
    feature: 'EdgeExtension',
    description: <Trans>Install the Edge extension</Trans>
  },
  {
    feature: 'LastpassUwpApp',
    description: (
      <Trans>
        Install the LastPass UWP application (available from MS Store)
      </Trans>
    )
  },
  {
    feature: 'PasswordImporter',
    description: <Trans>Install the Password Importer tool</Trans>
  },
  {
    feature: 'GenericShortcuts',
    description: (
      <Trans>
        Install start menu shortcuts pointing to the vault, help, uninstall, and
        website
      </Trans>
    )
  },
  {
    feature: 'DesktopShortcut',
    description: <Trans>Install desktop shortcut pointing to vault</Trans>
  },
  {
    feature: 'Updater',
    description: <Trans>Install the automatic updater</Trans>
  },
  {
    feature: 'BinaryComponent',
    description: (
      <Trans>Install the binary component for Chrome and Firefox</Trans>
    )
  }
]

const parametersData = [
  {
    parameter: 'INSTALLDIR',
    description: (
      <Trans>
        Use a full path to set the installation directory. Default is C:/
        Program Files (x86)/LastPass.
      </Trans>
    )
  },
  {
    parameter: 'KEEPUSERDATA',
    description: (
      <Trans>
        1: User data is not removed during uninstall. Takes effect with the /x
        parameter.
      </Trans>
    )
  },
  { parameter: 'DISABLEIEBROKER', description: <Trans>Disable Broker</Trans> },
  {
    parameter: 'UNINSTALLSURVEY',
    description: <Trans>1: Disable the Uninstall Survey. Default is 0.</Trans>
  },
  {
    parameter: 'NOLOGGEDOFFJSINJECTION',
    description: (
      <Trans>Don{"'"}t inject JavaScript into sites when logged off</Trans>
    )
  },
  {
    parameter: 'DISABLENOTES',
    description: <Trans>Disable Secure Notes</Trans>
  },
  { parameter: 'DISABLEEXPORT', description: <Trans>Disable Export</Trans> },
  { parameter: 'DISABLEIMPORT', description: <Trans>Disable Import</Trans> },
  { parameter: 'DISABLESHARING', description: <Trans>Disable Sharing</Trans> },
  { parameter: 'DISABLEPRINT', description: <Trans>Disable Printing</Trans> },
  {
    parameter: 'CID',
    description: (
      <Trans>
        The ID of the company to which the user belongs. Example: CID=1543453455
      </Trans>
    )
  },
  {
    parameter: 'PROXY',
    description: (
      <Trans>
        Proxy setting for auto-updater. Example: PROXY=http://123.123.123:8080
      </Trans>
    )
  },
  {
    parameter: 'NODISABLECHROMEPWMGR',
    description: (
      <Trans>
        1: Allow Chrome password manager. 0: Disable the Chrome password
        manager.
      </Trans>
    )
  },
  {
    parameter: 'AUTOLOGOFFONCLOSEIE',
    description: (
      <Trans>
        1: Automatically log users out of LastPass when they close Internet
        Explorer.
      </Trans>
    )
  },
  {
    parameter: 'TRYENABLESIDELOADING',
    description: (
      <Trans>
        1: The installer tries to enable sideloading on the machine. Default is
        0.
      </Trans>
    )
  },
  {
    parameter: 'TRYENABLESIDELOADINGFORINSTALL',
    description: (
      <Trans>
        1: Installer tries to enable sideloading on the machine only for the
        install. Default is 0.
      </Trans>
    )
  }
]

const features: Table<{
  feature: string
  description: JSX.Element
}> = {
  results: featuresData,
  resultsPerPage: 0,
  totalResults: 0,
  checkedRecords: []
}

const parameters: Table<{
  parameter: string
  description: JSX.Element
}> = {
  results: parametersData,
  resultsPerPage: 0,
  totalResults: 0,
  checkedRecords: []
}

export const WindowsSilentPageContainer: React.FunctionComponent = () => {
  return (
    <>
      <PageItemContainer data-qa={'PageItemContainerWindowsSilentInstall'}>
        <Trans>Instructions - Microsoft Windows silent install</Trans>
      </PageItemContainer>
      <PageContentContainer>
        <Heading100>
          <Trans>Available features</Trans>
        </Heading100>
        <StyledTextContent>
          <Trans>
            To select features to install, add the selected Feature ID to the
            ADDLOCAL parameter as a comma separated list. This example shows how
            to install the Chrome extension and all desktop shortcuts:
          </Trans>
          <CodeExample
            code={`msiexec /quiet /i LastPassInstaller.msi ADDLOCAL=ChromeExtension,GenericShortcuts,DesktopShortcut`}
            allowCopyToClipboard={true}
          />
        </StyledTextContent>

        <Heading100>
          <Trans>To limit installed features, use the ADDLOCAL parameter</Trans>
        </Heading100>
        <StyledTextContent>
          <TableComponent
            table={features}
            columns={[
              {
                name: <Trans>Feature ID</Trans>,
                renderer: function PermissionRenderer(record: {
                  feature: string
                  description: JSX.Element
                }) {
                  return record.feature
                }
              },
              {
                name: <Trans>Description</Trans>,
                renderer: function PermissionRenderer(record: {
                  feature: string
                  description: JSX.Element
                }) {
                  return record.description
                }
              }
            ]}
            noRecordsView={<></>}
          />
        </StyledTextContent>

        <Heading100>
          <Trans>Parameters</Trans>
        </Heading100>
        <StyledTextContent>
          <TableComponent
            table={parameters}
            columns={[
              {
                name: <Trans>Parameter</Trans>,
                renderer: function PermissionRenderer(record: {
                  parameter: string
                  description: JSX.Element
                }) {
                  return record.parameter
                }
              },
              {
                name: <Trans>Description, values</Trans>,
                renderer: function PermissionRenderer(record: {
                  parameter: string
                  description: JSX.Element
                }) {
                  return record.description
                }
              }
            ]}
          />
        </StyledTextContent>

        <Heading100>
          <Trans>Logging</Trans>
        </Heading100>
        <StyledTextContent>
          <Trans>
            To enable logging during install, use /l*v -filename- parameter. For
            example:
          </Trans>
          <CodeExample
            code={`msiexec /quiet /l*v log.txt /i LastPassInstaller.msi`}
            allowCopyToClipboard={true}
          />
          <Trans>This also works during uninstall:</Trans>
          <CodeExample
            code={`msiexec /quiet /l*v log.txt /x LastPassInstaller.msi`}
            allowCopyToClipboard={true}
          />
          <Trans>
            Upon success, a similar line appears at the end of the logfile:
          </Trans>
          <CodeExample
            code={`MSI (s) (94:C4) [16:45:06:919]: Windows Installer installed the product. Product Name: LastPass.
Product Version: 4.24.0.568. Product Language: 1033. Manufacturer: LogMeIn. Installation success or error status: 0.`}
            allowCopyToClipboard={true}
          />
          <Trans>
            Look closely at the end of the line. Unless error status is 0, there
            was an error during installation. To troubleshoot, search for the
            error code or the word {"'"}error{"'"} or {"'"}exception{"'"} in the
            log file.
          </Trans>
          <Trans>
            See more details:{' '}
            <a href="http://link.lastpass.com/help-msi-installer-guide">
              Administrator manual
            </a>
          </Trans>
        </StyledTextContent>
      </PageContentContainer>
    </>
  )
}

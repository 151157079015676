import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { msg, Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import {
  BodyRegular,
  PrimaryButton,
  Select,
  TextButton
} from '@lastpass/lastkit'
import { StyledSpacer } from '@lastpass/lastkit'
import {
  ButtonsContainer,
  StyledFormFooter
} from '@lastpass/lastkit/components/Drawer'

import {
  AvailableApplication,
  createApplication
} from '@lastpass/admin-console-dependencies/state/applications/application'
import { applicationsDrawerActions } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/actions'
import { ApplicationDrawerSteps } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/application-steps'
import { ApplicationDrawerState } from '@lastpass/admin-console-dependencies/state/applications/saml/drawer/state'

const BodyText = styled.div`
  margin-bottom: 24px;
`

const StyledTextButton = styled(TextButton)`
  font-weight: normal;
`

interface ApplicationSelectOption {
  value: string
  label: string
}

const getApplicationSelectOptions = (
  availableApplications: AvailableApplication[]
): ApplicationSelectOption[] =>
  availableApplications.map(app => ({ value: app.id, label: app.text }))

export interface SelectAppProps {
  closeLink: string
}

export const SelectApp: React.FunctionComponent<SelectAppProps> = ({
  closeLink
}) => {
  const applicationDrawerState: ApplicationDrawerState = useSelector(
    (state: AppState) => state.applicationDrawer
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(applicationsDrawerActions.getAvailableApplications())
    dispatch(applicationsDrawerActions.onSelectAppViewed())
  }, [dispatch])

  const history = useHistory()
  const availableApplications = applicationDrawerState.availableApplications
  const applicationSelectOptions = getApplicationSelectOptions(
    availableApplications
  )

  return (
    <>
      <BodyRegular>
        <BodyText>
          <Trans>
            LastPass SSO uses the SAML standard to authorize users with
            third-party apps. Search our catalog of apps to get started.{' '}
            <a
              data-qa="LearnMoreLink"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.logmeininc.com/lastpass/help/lastpass-admin-toolkit-using-single-sign-on-sso"
            >
              <Trans>Learn more</Trans>
            </a>
          </Trans>
        </BodyText>
        <BodyText>
          <Trans>
            Can&apos;t find your app?{' '}
            <StyledTextButton
              data-qa="AddUnlistedAppLink"
              blue
              onClick={() =>
                dispatch(
                  applicationsDrawerActions.nextStep(
                    ApplicationDrawerSteps.addUnlistedApp
                  )
                )
              }
            >
              <Trans>Add an unlisted app</Trans>
            </StyledTextButton>
          </Trans>
        </BodyText>
      </BodyRegular>
      <Select
        dataQa="SelectApplicationDropdown"
        options={applicationSelectOptions}
        fluid
        isSearchable
        placeholder={i18n._(msg`Search`)}
        onChange={selectedItem => {
          // Hacky workaround, there is a typescript error on ReactSelect component:
          // Property 'value' does not exist on type '{ label: string; value: string; } | readonly { label: string; value: string; }[]'.
          const selectedValue = JSON.parse(JSON.stringify(selectedItem)).value
          const selectedApp = availableApplications.find(
            app => app.id === selectedValue
          )
          if (selectedApp != undefined) {
            dispatch(
              applicationsDrawerActions.setSelectedApplication(
                createApplication(
                  false,
                  selectedApp.text,
                  selectedApp.id,
                  selectedApp.defaultPartnerName,
                  selectedApp.defaultIdentityProvider,
                  selectedApp.signAssertion,
                  selectedApp.wantAuthnRequestSigned,
                  selectedApp.signSAMLResponse,
                  selectedApp.encryptAssertion,
                  selectedApp.logo.imageUrl
                )
              )
            )
          }
        }}
      />
      <StyledSpacer />
      <StyledFormFooter>
        <ButtonsContainer>
          <TextButton
            css={css`
              margin-right: 16px;
            `}
            key="Discard"
            onClick={() => {
              history.push(closeLink)
              dispatch(
                applicationsDrawerActions.selectApp(
                  'Discard',
                  applicationDrawerState.selectedApplication
                    ? applicationDrawerState.selectedApplication.name
                    : ''
                )
              )
            }}
            data-qa="DiscardButton"
          >
            <Trans>Discard</Trans>
          </TextButton>
          <PrimaryButton
            id="ContinueButton"
            key="Continue"
            onClick={() => {
              dispatch(
                applicationsDrawerActions.selectApp(
                  'Continue',
                  applicationDrawerState.selectedApplication
                    ? applicationDrawerState.selectedApplication.name
                    : ''
                )
              )
              dispatch(
                applicationsDrawerActions.nextStep(
                  ApplicationDrawerSteps.configure
                )
              )
            }}
            disabled={!applicationDrawerState.canMoveToNext}
            data-qa="ContinueButton"
          >
            <Trans>Continue</Trans>
          </PrimaryButton>
        </ButtonsContainer>
      </StyledFormFooter>
    </>
  )
}

import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { AnimatedSwitch } from '@lastpass/routing'

import { AdvancedPolicyDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/advancedPolicyDrawer/advanced-policy-drawer-steps'
import { MultifactorDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/multifactor/drawer/multifactor-drawer-steps'
import { AdvancedPolicyDrawer } from '@lastpass/admin-console-dependencies/ui/policies/multifactor/AdvancedPolicyDrawer'
import { MultifactorDrawer } from '@lastpass/admin-console-dependencies/ui/policies/multifactor/MultifactorDrawer'
import { MultifactorPage } from '@lastpass/admin-console-dependencies/ui/policies/multifactor/MultifactorPage'

export const MultifactorPageContainer = () => {
  const match = useRouteMatch()
  if (match) {
    const { path } = match
    return (
      <>
        <MultifactorPage />
        <AnimatedSwitch timeout={200}>
          <Route path={`${path}/add`}>
            <MultifactorDrawer
              closeLink={`${path}`}
              initialStep={MultifactorDrawerSteps.description}
            />
          </Route>
          <Route path={`${path}/configure`}>
            <MultifactorDrawer
              closeLink={`${path}`}
              initialStep={MultifactorDrawerSteps.configureMfa}
            />
          </Route>
          <Route path={`${path}/add-advanced`}>
            <AdvancedPolicyDrawer
              closeLink={`${path}`}
              initialStep={AdvancedPolicyDrawerSteps.addAdvancedPolicy}
            />
          </Route>
        </AnimatedSwitch>
      </>
    )
  }
  return null
}

export const createIdentitySessionExpiredHandler = (
  allowedOrigin: string,
  onExpiration: () => void
) => {
  const eventListenerType = 'message'

  const eventHandler = ({ origin, data }: MessageEvent) => {
    const sessionExpiredMessage = 'identity-session-expired'
    const warningMessage =
      'Identity session expired event received - logout started'

    if (origin === allowedOrigin && data === sessionExpiredMessage) {
      console.warn(warningMessage) // NOSONAR
      onExpiration()
    }
  }

  const register = () => {
    window.addEventListener(eventListenerType, eventHandler, false)
  }

  const cleanup = () => {
    window.removeEventListener(eventListenerType, eventHandler)
  }

  return {
    register,
    cleanup
  }
}

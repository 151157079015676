import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
import { PasswordlessDrawerSteps } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/state'

export const getCurrentPasswordlessDrawerStep = (state: AppState) =>
  state.passwordlessDrawer.step

export function createUpdateSearchStringSaga() {
  return function* updateSearchStringSaga(
    action: ReturnType<typeof passwordlessDrawerActions.updateSearchString>
  ) {
    try {
      const step: PasswordlessDrawerSteps | undefined = yield select(
        getCurrentPasswordlessDrawerStep
      )
      yield put(passwordlessDrawerActions.setSearchString(action.payload))
      if (step === PasswordlessDrawerSteps.assign) {
        yield put(
          passwordlessDrawerActions.searchPolicyAssignUsersAndGroups(
            action.payload || '',
            0
          )
        )
      } else {
        yield put(
          passwordlessDrawerActions.getPolicyUsersAndGroups(action.payload)
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

import { css, Global, ThemeProvider } from '@emotion/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { ConnectedRouter } from 'connected-react-router'
import emotionReset from 'emotion-reset'
import { History } from 'history'
import { Store } from 'redux'
import { SagaMiddleware } from 'redux-saga'

import { OAuthManager } from '@lastpass/http'
import { GlobalStyles, Theme } from '@lastpass/lastkit'
import {
  accountTypeMapper,
  createSegmentClientService
} from '@lastpass/tracking'
import { observableFrom } from '@lastpass/utility'

import { createRootSaga } from '@lastpass/admin-console-dependencies/sagas'
import { Services } from '@lastpass/admin-console-dependencies/server'
import { ServerContext } from '@lastpass/admin-console-dependencies/server/ServerContext'
import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import { createIdentitySessionExpiredHandler } from '@lastpass/admin-console-dependencies/services/identity-session-expired'
import { createPendoHandler } from '@lastpass/admin-console-dependencies/services/pendo/handler'
import { getLastPassLanguageCodeFromCookie } from '@lastpass/admin-console-dependencies/services/translation-handler'
import { companyInfoActions } from '@lastpass/admin-console-dependencies/state/company/actions'
import { CompanyDetails } from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { createCheckPermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

import { AppState } from './app-store'
import { defaultConfig } from './default-config'
import { MainPage } from './pages/MainPage'

export function createApp(
  appStore: Store,
  sagaMiddleware: SagaMiddleware,
  trackingMiddleware: { initialize: Function },
  companyDetails: CompanyDetails,
  permissions: AdminPermissions[],
  currentAdminLevel: string,
  userId: string,
  history: History,
  uacServices: Services,
  config = defaultConfig
) {
  // Sagas
  const rootSaga = createRootSaga(uacServices)
  sagaMiddleware.run(rootSaga)

  appStore.dispatch(companyInfoActions.setCompanyDetails(companyDetails))
  appStore.dispatch(
    globalActions.setCurrentUserLanguage(getLastPassLanguageCodeFromCookie())
  )
  appStore.dispatch(globalActions.getCurrentUser())
  appStore.dispatch(globalActions.setCurrentUserPermission(permissions))
  appStore.dispatch(companyInfoActions.getCompanySettings())
  appStore.dispatch(userListActions.setCurrentAdminLevel(currentAdminLevel))
  appStore.dispatch(
    userListActions.setIsTransferVaultHidden(
      companyDetails.isTransferVaultHidden
    )
  )

  const accountType = accountTypeMapper(
    companyDetails.isTrial || false,
    companyDetails.subscriptionEndDate
      ? companyDetails.subscriptionEndDate.toString()
      : ''
  )

  const segment = createSegmentClientService(
    observableFrom(config.segmentUrl),
    {
      subscribe: subscriber => {
        subscriber(
          userId
            ? {
                userId,
                traits: {
                  'Account Type': accountType,
                  'Enterprise Version': companyDetails.enterpriseVersion || '',
                  'Admin Console': 'Unified Admin Console'
                }
              }
            : undefined
        )
        return { unsubscribe: () => {} }
      }
    }
  )
  trackingMiddleware.initialize({ segment })

  const PermissionContext = createCheckPermissionContext<AppState>()

  const identitySessionExpiredHandler = createIdentitySessionExpiredHandler(
    config.identityIframeUrl,
    OAuthManager.logout
  )

  function App() {
    useEffect(() => {
      identitySessionExpiredHandler.register()

      return () => {
        identitySessionExpiredHandler.cleanup()
      }
    }, [])

    const isPendoEnabled = useFeatureFlags(FeatureFlags.isPendoEnabled)
    useEffect(() => {
      const { pendoSigningKeyName } = config
      if (!isPendoEnabled || !pendoSigningKeyName) {
        return
      }
      const pendoHandler = createPendoHandler(uacServices, config)
      pendoHandler.initialize()

      return () => {
        pendoHandler.cleanup()
      }
    }, [isPendoEnabled])

    const isMspPaymentDialogEnabled = useFeatureFlags(
      FeatureFlags.isMspPaymentDialogEnabled
    )
    useEffect(() => {
      if (
        isMspPaymentDialogEnabled &&
        CompanyDetailsHelper.isMsp(companyDetails)
      ) {
        appStore.dispatch(globalActions.showMspPaymentInfoDialog())
      }
    }, [isMspPaymentDialogEnabled])

    return (
      <ThemeProvider theme={Theme}>
        <Global
          styles={css`
            ${emotionReset}
            ${GlobalStyles}
          `}
        />
        <Provider store={appStore}>
          <I18nProvider i18n={i18n}>
            <ConnectedRouter history={history}>
              <PermissionContext>
                <ServerContext.Provider value={uacServices}>
                  <MainPage />
                </ServerContext.Provider>
              </PermissionContext>
              <iframe
                css={css`
                  width: 0;
                  height: 0;
                  border-style: none;
                  border-width: 0;
                `}
                src={config.identityIframeUrl + '/system/keepalive'}
              />
            </ConnectedRouter>
          </I18nProvider>
        </Provider>
      </ThemeProvider>
    )
  }
  return App
}

import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { urlRulesActions } from '@lastpass/admin-console-dependencies/state/advanced/enterprise-options/urlrules/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createDeleteUrlRulesSaga(
  enterpriseOptionServices: UACServices.Services
) {
  return function* deleteUrlRulesSaga(
    action: ReturnType<typeof urlRulesActions.deleteUrlRules>
  ) {
    try {
      yield put(urlRulesActions.setUrlRulesLoading(true))

      const result: UACServices.DeleteUrlRulesAPI.Responses = yield call(
        enterpriseOptionServices.deleteUrlRules,
        action.payload.rules
      )

      if (result.type == UACServices.DeleteUrlRulesAPI.SUCCESS) {
        yield put(urlRulesActions.getUrlRules())
        yield put(urlRulesActions.setUrlRulesLoading(false))
        yield put(urlRulesActions.setSelectedRules([]))
        yield put(
          globalActions.setNotification({
            message: msg`URL rules deleted`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
      } else {
        yield put(
          globalActions.setNotification({
            message: msg`URL rule was not deleted`,
            type: NotificationType.success,
            autoDismiss: true
          })
        )
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { FunctionComponent } from 'react'

import { Trans } from '@lingui/macro'

import { ReactComponent as PingIdentityIcon } from '@lastpass/assets/svg/admin-console/directories/icon-pingidentity.svg'

import {
  BoldWrapper,
  CopiedComponent,
  StyledInstructionDiv,
  StyledPrimaryButton,
  StyledSetupDiv,
  StyledTextRow,
  StyledTokenDiv
} from './DirectoriesPageCopiedComponent'
import { IntegrationCard } from './IntegrationCard'

interface PingOneIntegrationProps {
  expand?: boolean
  getToken: () => void
  lastSync?: string
  provisionToken?: string
  hasProvisionToken: boolean
  cid: string
}

export const PingOneIntegrationCard: FunctionComponent<PingOneIntegrationProps> = props => {
  const pingOneUrl = `https://pingone-scim.lastpass.com/scimapi/${props.cid}`
  return (
    <IntegrationCard
      expand={props.expand}
      logo={<PingIdentityIcon data-qa={'PingOneIcon'} />}
      title={<Trans>PingOne</Trans>}
      subtitle={
        <Trans>
          Automatically provision users to LastPass from PingOne by establishing
          a SCIM connection.
        </Trans>
      }
      description={
        <StyledSetupDiv>
          <Trans>
            Copy the URL and bearer token and continue setup in PingOne.
          </Trans>
          <StyledInstructionDiv>
            <a
              href="https://link.lastpass.com/help-pingone-scim-lastpass"
              data-qa={'PingOneViewSetupInstructionLink'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>View setup instructions</Trans>
            </a>
          </StyledInstructionDiv>
        </StyledSetupDiv>
      }
      primaryText={
        <>
          <div data-qa={'PingOneUrl'}>
            <BoldWrapper>
              <Trans>URL</Trans>
              {': '}
            </BoldWrapper>
            <StyledTextRow>{pingOneUrl}</StyledTextRow>
            <CopiedComponent url={pingOneUrl} />
          </div>
          <StyledTokenDiv>
            {props.provisionToken ? (
              <>
                <BoldWrapper>
                  <Trans>Token</Trans>
                  {': '}
                </BoldWrapper>
                <StyledTextRow data-qa="PingOneToken">
                  {props.provisionToken}
                </StyledTextRow>
                <CopiedComponent url={props.provisionToken} />
              </>
            ) : props.hasProvisionToken ? (
              <span>
                <Trans>
                  You{"'"}ve already created your provisioning token.
                </Trans>{' '}
                <Trans>If you don{"'"}t know it, click to reset.</Trans>
              </span>
            ) : (
              <></>
            )}
          </StyledTokenDiv>
        </>
      }
      secondaryButton={
        <StyledPrimaryButton
          data-qa={
            props.hasProvisionToken
              ? 'PingOneResetProvisioningTokenButton'
              : 'PingOneCreateTokenButton'
          }
          onClick={props.getToken}
        >
          {props.hasProvisionToken ? (
            <Trans>Reset provisioning token</Trans>
          ) : (
            <Trans>Create token</Trans>
          )}
        </StyledPrimaryButton>
      }
      lastSync={props.lastSync}
    />
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { CompanyDetailsHelper } from '@lastpass/admin-console-dependencies/services/company-details-helper'
import {
  CompanyDetails,
  SubscriptionType
} from '@lastpass/admin-console-dependencies/state/company/state'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { MfaUpsellDialogContext } from '@lastpass/admin-console-dependencies/types/dialog-types'

export interface CheckPermissionProps {
  requiredSubscription: SubscriptionType[]
  alretnativeComponent?: React.ReactNode
  showDialog?: {
    redirectAfterClose: string
  }
  source?: MfaUpsellDialogContext
}

export const CheckPermission: React.FunctionComponent<CheckPermissionProps> = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const companyDetails = useSelector<AppState, CompanyDetails>(
    appState => appState.companyInfo.details
  )

  const userHasAnyPermission = CompanyDetailsHelper.hasAnyPermission(
    companyDetails
  )

  const userHasMfa = CompanyDetailsHelper.userHasMfaAddon(companyDetails)

  const isTrialEligible = CompanyDetailsHelper.isUserBusinessTrialEligible(
    companyDetails
  )

  const userHasRequiredSubscription =
    companyDetails.subscription !== SubscriptionType.none &&
    props.requiredSubscription.includes(companyDetails.subscription)

  if (
    (!userHasAnyPermission && userHasRequiredSubscription) ||
    (userHasAnyPermission && userHasMfa)
  ) {
    return <>{props.children}</>
  } else if (props.alretnativeComponent) {
    return <>{props.alretnativeComponent}</>
  } else if (
    props.showDialog &&
    (companyDetails.subscription !== SubscriptionType.none || !userHasMfa)
  ) {
    if (!isTrialEligible) {
      dispatch(
        globalActions.showMfaAddonUpsellDialog(
          props.source != null ? props.source : MfaUpsellDialogContext.default,
          CompanyDetailsHelper.isTouchSalesEnabled(companyDetails) || false
        )
      )
      return null
    }

    dispatch(
      globalActions.showMfaUpsellDialog(
        CompanyDetailsHelper.isTouchSalesEnabled(companyDetails) || false,
        props.source != null ? props.source : MfaUpsellDialogContext.default,
        history.push,
        props.showDialog.redirectAfterClose
      )
    )
  }
  return null
}

import { StatusCodes } from 'http-status-codes'

import { AuthFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { GoogleGroup } from '@lastpass/admin-console-dependencies/state/users/directories/integrations/state'

export const SUCCESS = 'success'
export const FAILURE = 'failure'
export const TURN_ON_SEARCH_BASED_GROUP_SELECTION =
  'turn_on_search_based_selection'

const success = (googleGroups: GoogleGroup[]) =>
  createResponse(SUCCESS, { googleGroups })
const failure = () => createResponse(FAILURE)
const turnOnSearchBasedGroupSelection = () =>
  createResponse(TURN_ON_SEARCH_BASED_GROUP_SELECTION)

export const Responses = {
  success,
  failure,
  turnOnSearchBasedGroupSelection
}
export type Responses = ResponseTypes<typeof Responses>

export function create(fetch: AuthFetch) {
  return async function service() {
    try {
      const [response, status] = await fetch(
        'google-workspace-provisioning/google-groups-beta',
        'GET'
      )

      if (status === StatusCodes.OK && response) {
        const content = JSON.parse(response).content
        const googleGroups = content.groups || []

        if (content.isGroupCountLimitExceeded) {
          return turnOnSearchBasedGroupSelection()
        }

        return success(
          googleGroups.map(group => {
            return {
              id: group.id,
              name: group.name,
              selected: false,
              children: group.childGroupIds || []
            }
          })
        )
      } else {
        return failure()
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

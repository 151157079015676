import { StatusCodes } from 'http-status-codes'

import { IdentityAPIFetch } from '@lastpass/http'

import {
  createResponse,
  ResponseTypes,
  UnknownServiceError
} from '@lastpass/admin-console-dependencies/server/responses'
import { ClientOauth } from '@lastpass/admin-console-dependencies/state/applications/application'

export const SUCCESS = 'success'

const success = (oauthKeys: ClientOauth) =>
  createResponse(SUCCESS, { oauthKeys: oauthKeys })

export const Responses = {
  success
}

export type Responses = ResponseTypes<typeof Responses>

export function create(identityApi: IdentityAPIFetch) {
  return async function service() {
    try {
      const [response, status] = await identityApi(`api/v2/Key/oauth`, 'GET')
      if (status === StatusCodes.OK && response) {
        const oauthKeysResponse = JSON.parse(response)

        return success(oauthKeysResponse)
      }
    } catch (e) {
      throw new UnknownServiceError()
    }
  }
}

import { put, select } from 'redux-saga/effects'

import { AppState } from '@lastpass/admin-console/src/app-store'

import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { GeneralPolicyUsersAndGroupsConfigFormData } from '@lastpass/admin-console-dependencies/state/policies/general/policy-users/state'
import { passwordlessDrawerActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/drawer/actions'
export const getPolicyConfigData = (state: AppState) =>
  state.passwordlessDrawer.editFormState.policyUsersAndGroups
    .policyConfigFormData

export function createCheckPolicyAppliesToPolicySaga() {
  return function* checkPolicyAppliesToPolicySaga(
    action: ReturnType<typeof passwordlessDrawerActions.checkPolicyAppliesTo>
  ) {
    const formPolicyAppliesTo = action.payload.policyAppliesTo
    const searchString = action.payload.searchString

    try {
      const policyConfigData: GeneralPolicyUsersAndGroupsConfigFormData = yield select(
        getPolicyConfigData
      )
      const policyAppliesTo = policyConfigData.appliesTo
      yield put(
        passwordlessDrawerActions.setPolicyCurrentlyAppliesTo(
          formPolicyAppliesTo
        )
      )
      if (formPolicyAppliesTo !== policyAppliesTo) {
        yield put(passwordlessDrawerActions.resetPolicyTables())
      }

      yield put(passwordlessDrawerActions.getPolicyUsersAndGroups(searchString))
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

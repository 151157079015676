import { DataFetchParams } from '@lastpass/routing'
import { SegmentPropertyType, track, uacEvent } from '@lastpass/tracking'

import {
  ActionsUnion,
  createAction
} from '@lastpass/admin-console-dependencies/state/action'
import { User } from '@lastpass/admin-console-dependencies/types/user'

const userCountIndex = 'User Count'
const userStatusIndex = 'LastPass User Status'

export interface DashboardDrawerQueryParams {
  skip: number
}

export enum DashboardDrawerActionTypes {
  RESET = '[dashboard-drawer] reset',
  GET_UNRESPONSIVE_USERS = '[dashboard-drawer] get-unresponsive-users',
  SET_UNRESPONSIVE_USERS_LOADING = '[dashboard-drawer] set-unresponsive-users-loading',

  EXPORT_UNRESPONSIVE_USERS = '[dashboard-drawer] export-unresponsive-users',
  REINVITE_UNRESPONSIVE_USERS = '[dashboard-drawer] reinvite-unresponsive-users',

  GET_INACTIVE_USERS = '[dashboard-drawer] get-inactive-users',
  SET_INACTIVE_USERS_LOADING = '[dashboard-drawer] set-inactive-users-loading',

  EXPORT_INACTIVE_USERS = '[dashboard-drawer] export-inactive-users',

  GET_STAGED_USERS = '[dashboard-drawer] get-staged-users',
  SET_STAGED_USERS = '[dashboard-drawer] set-staged-users',
  SET_STAGED_USERS_LOADING = '[dashboard-drawer] set-staged-users-loading',

  EXPORT_STAGED_USERS = '[dashboard-drawer] export-staged-users',
  REINVITE_STAGED_USERS = '[dashboard-drawer] reinvite-staged-users',

  INVITE_STAGED_USERS_BUTTON_CLICKED = '[dashboard-drawer-segment-events] invite-staged-button-clicked',

  REINVITE_UNRESPONSIVE_USERS_BUTTON_CLICKED = '[dashboard-drawer-segment-events] reinvite-unresponsive-button-clicked',
  REINVITE_UNRESPONSIVE_USERS_CONFIRM_CLICKED = '[dashboard-drawer-segment-events] reinvite-confirm-clicked',
  EXPORT_USERS_BUTTON_CLICKED = '[dashboard-drawer-segment-events] export-users-button-clicked',

  SET_DASHBOARD_DRAWER_LOADING = '[dashboard-drawer] set-loading',

  GET_WEAK_MASTERPASSWORD_USERS = '[dashboard-drawer] get-weak-masterpassword-users',
  SET_WEAK_MASTERPASSWORD_USERS_LOADING = '[dashboard-drawer] set-weak-masterpassword-users-loading',
  EXPORT_WEAK_MASTERPASSWORD_USERS = '[dashboard-drawer] export-weak-masterpassword-users',

  GET_REUSED_MASTER_PASSWORD_LIST = '[dashboard-drawer] get-reused-master-password-list',
  SET_REUSED_MASTER_PASSWORD_LIST_LOADING = '[dashboard-drawer] set-reused-master-password-list-loading',
  EXPORT_REUSED_MASTER_PASSWORD_LIST = '[dashboard-drawer] export-reused-master-password-list',

  GET_USERS_WITH_WEAK_SECURITY_SCORE = '[dashboard-drawer] get-users-with-weak-security-score',
  SET_USERS_WITH_WEAK_SECURITY_SCORE_LOADING = '[dashboard-drawer] set-users-with-weak-security-score-loading',
  EXPORT_USERS_WITH_WEAK_SECURITY_SCORE = '[dashboard-drawer] export-users-with-weak-security-score',

  SET_TABLE = '[dashboard-drawer] set-table'
}

export const dashboardDrawerActions = {
  reset: () => createAction(DashboardDrawerActionTypes.RESET),
  getUnresponsiveUsers: (
    params: DataFetchParams<DashboardDrawerQueryParams, {}>,
    resultsPerPage = 25
  ) =>
    createAction(DashboardDrawerActionTypes.GET_UNRESPONSIVE_USERS, {
      params,
      resultsPerPage
    }),
  setUnresponsiveUsersLoading: (loading: boolean) =>
    createAction(
      DashboardDrawerActionTypes.SET_UNRESPONSIVE_USERS_LOADING,
      loading
    ),
  exportUnresponsiveUsers: () =>
    createAction(DashboardDrawerActionTypes.EXPORT_UNRESPONSIVE_USERS),
  reinviteUnresponsiveUsers: () =>
    createAction(DashboardDrawerActionTypes.REINVITE_UNRESPONSIVE_USERS),

  getInactiveUsers: (
    params: DataFetchParams<DashboardDrawerQueryParams>,
    resultsPerPage = 25
  ) =>
    createAction(DashboardDrawerActionTypes.GET_INACTIVE_USERS, {
      params,
      resultsPerPage
    }),
  setInactiveUsersLoading: (loading: boolean) =>
    createAction(
      DashboardDrawerActionTypes.SET_INACTIVE_USERS_LOADING,
      loading
    ),
  exportInactiveUsers: () =>
    createAction(DashboardDrawerActionTypes.EXPORT_INACTIVE_USERS),
  getStagedUsers: (
    params: DataFetchParams<DashboardDrawerQueryParams, {}>,
    resultsPerPage = 25
  ) =>
    createAction(DashboardDrawerActionTypes.GET_STAGED_USERS, {
      params,
      resultsPerPage
    }),
  setStagedUsersLoading: (loading: boolean) =>
    createAction(DashboardDrawerActionTypes.SET_STAGED_USERS_LOADING, loading),
  exportStagedUsers: () =>
    createAction(DashboardDrawerActionTypes.EXPORT_STAGED_USERS),
  reinviteStagedUsers: () =>
    createAction(DashboardDrawerActionTypes.REINVITE_STAGED_USERS),
  inviteUsersButtonClicked: (count: number) =>
    track(
      createAction(
        DashboardDrawerActionTypes.INVITE_STAGED_USERS_BUTTON_CLICKED
      ),
      {
        segment: uacEvent('Send LastPass Invitation Clicked', {
          Source: 'Invite Users Drawer',
          [userCountIndex]: count,
          [userStatusIndex]: 'Staged'
        })
      }
    ),
  reinviteUsersButtonClicked: (count: number) =>
    track(
      createAction(
        DashboardDrawerActionTypes.REINVITE_UNRESPONSIVE_USERS_BUTTON_CLICKED
      ),
      {
        segment: uacEvent('Resend LastPass Invitation Clicked', {
          Source: 'Reinvite Users Drawer',
          [userCountIndex]: count,
          [userStatusIndex]: ' Invited'
        })
      }
    ),
  reinviteUsersConfirmClicked: () =>
    track(
      createAction(
        DashboardDrawerActionTypes.REINVITE_UNRESPONSIVE_USERS_CONFIRM_CLICKED
      ),
      {
        segment: uacEvent('Resend LastPass Invitation Clicked', {
          Source: 'Confirm Modal',
          [userCountIndex]: 0,
          [userStatusIndex]: ' Invited'
        })
      }
    ),
  exportUsersClicked: (
    source: SegmentPropertyType<'Export Users Clicked', 'Source'>
  ) =>
    track(
      createAction(DashboardDrawerActionTypes.EXPORT_USERS_BUTTON_CLICKED),
      {
        segment: uacEvent('Export Users Clicked', {
          Source: source
        })
      }
    ),
  setDashboardDrawerLoading: (loading: boolean) =>
    createAction(
      DashboardDrawerActionTypes.SET_DASHBOARD_DRAWER_LOADING,
      loading
    ),
  getWeakMasterPasswordUsers: (
    params: DataFetchParams<DashboardDrawerQueryParams, {}>,
    resultsPerPage = 25
  ) =>
    createAction(DashboardDrawerActionTypes.GET_WEAK_MASTERPASSWORD_USERS, {
      params,
      resultsPerPage
    }),
  setWeakMasterPasswordUsersLoading: (loading: boolean) =>
    createAction(
      DashboardDrawerActionTypes.SET_WEAK_MASTERPASSWORD_USERS_LOADING,
      loading
    ),
  exportWeakMasterPasswordUsers: () =>
    createAction(DashboardDrawerActionTypes.EXPORT_WEAK_MASTERPASSWORD_USERS),

  getUsersWithWeakSecurityScore: (
    params: DataFetchParams<DashboardDrawerQueryParams, {}>,
    resultsPerPage = 25
  ) =>
    createAction(
      DashboardDrawerActionTypes.GET_USERS_WITH_WEAK_SECURITY_SCORE,
      {
        params,
        resultsPerPage
      }
    ),
  setUsersWithWeakSecurityScoreLoading: (loading: boolean) =>
    createAction(
      DashboardDrawerActionTypes.SET_USERS_WITH_WEAK_SECURITY_SCORE_LOADING,
      loading
    ),
  exportUsersWithWeakSecurityScore: () =>
    createAction(
      DashboardDrawerActionTypes.EXPORT_USERS_WITH_WEAK_SECURITY_SCORE
    ),
  getMasterPasswordReuse: (
    params: DataFetchParams<DashboardDrawerQueryParams, {}>,
    resultsPerPage = 25
  ) =>
    createAction(DashboardDrawerActionTypes.GET_REUSED_MASTER_PASSWORD_LIST, {
      params,
      resultsPerPage
    }),
  setMasterPasswordReuseLoading: (loading: boolean) =>
    createAction(
      DashboardDrawerActionTypes.SET_REUSED_MASTER_PASSWORD_LIST_LOADING,
      loading
    ),
  setTable: (
    users: User[],
    totalResults: number,
    resultsPerPage: number,
    tableName: string
  ) =>
    createAction(DashboardDrawerActionTypes.SET_TABLE, {
      users,
      totalResults,
      resultsPerPage,
      tableName
    }),
  exportMasterPasswordReuse: () =>
    createAction(DashboardDrawerActionTypes.EXPORT_REUSED_MASTER_PASSWORD_LIST)
}

export type DashboardDrawerActions = ActionsUnion<typeof dashboardDrawerActions>

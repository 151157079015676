import { msg } from '@lingui/macro'
import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { psaIntegrationsActions } from '@lastpass/admin-console-dependencies/state/advanced/psa-integrations/actions'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { NotificationType } from '@lastpass/admin-console-dependencies/types/notification-type'

export function createGetPsaAgreementsSaga(services: UACServices.Services) {
  return function* getPsaAgreementsSaga(
    action: ReturnType<typeof psaIntegrationsActions.getPsaAgreements>
  ) {
    try {
      yield put(psaIntegrationsActions.setPsaAgreementsTableInProgress(true))
      yield put(psaIntegrationsActions.setPsaAgreementsAssignmentOptions([]))

      try {
        const response: UACServices.GetPsaAgreementsAPI.Responses = yield call(
          services.getPsaAgreements,
          action.payload
        )
        const { companyContractAssignmentOptions } = response.body

        yield put(
          psaIntegrationsActions.setPsaAgreementsAssignmentOptions(
            companyContractAssignmentOptions
          )
        )
      } finally {
        yield put(psaIntegrationsActions.setPsaAgreementsTableInProgress(false))
      }
    } catch {
      yield put(
        globalActions.setNotification({
          type: NotificationType.alert,
          autoDismiss: false,
          message: msg`Couldn’t load agreements. Check your connection or try again later.`
        })
      )
    }
  }
}

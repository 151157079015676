import React, { FunctionComponent } from 'react'
import ReactSelect, { components, Props } from 'react-select'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Chevron } from '@lastpass/components'

import { BodyRegularStyle, BodySemiboldStyle } from '../styles'
import { Theme as theme } from '../theme'
import { Checkbox } from './Checkbox'

export interface SelectProps extends Props {
  fluid?: boolean
  width?: string
  icon?: React.ComponentType
  dataQa?: string
}

const StyledContainer = styled.div<{ fluid?: boolean; width?: string }>`
  width: 230px;
  ${props => props.fluid && 'width: 100%'};
  ${props => props.width && 'width:' + props.width};
`

const StyledReset = styled.span`
  ${BodySemiboldStyle};
  cursor: pointer;
  color: ${props => props.theme.colors.blue700};
`

const StyledBodyRegular = styled.span`
  ${BodyRegularStyle};
  margin-left: 8px;
  color: ${props => props.theme.colors.neutral900};
`

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <StyledReset data-qa={'ResetButtonInDropdownFilter'}>
        <Trans>Reset</Trans>
      </StyledReset>
    </components.ClearIndicator>
  )
}

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <Trans>No Options</Trans>
    </components.NoOptionsMessage>
  )
}

const ValueContainer = props => {
  if (props.isMulti) {
    const selectedElements = props.getValue()

    return (
      <components.ValueContainer {...props}>
        <StyledBodyRegular
          data-qa={'ActiveFilterInDropdown'}
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
        >
          {(selectedElements.length > 1 && (
            <Trans> {props.selectProps.value.length} Filters active</Trans>
          )) ||
            (selectedElements[0] && selectedElements[0].label) ||
            props.children}
        </StyledBodyRegular>
      </components.ValueContainer>
    )
  }
  return (
    <components.ValueContainer {...props}>
      {props.children}
    </components.ValueContainer>
  )
}

const Option = props => {
  if (props.isMulti) {
    return (
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          controlled
          data-qa={'CheckboxInDropdownFilter'}
        />
        <StyledBodyRegular data-qa={'DropdownFilterOption'}>
          {props.children}
        </StyledBodyRegular>
      </components.Option>
    )
  }
  return <components.Option {...props}>{props.children}</components.Option>
}

const Placeholder = props => {
  return (
    <components.Placeholder {...props}>
      {(!!props.selectProps.placeholder && props.selectProps.placeholder) || (
        <Trans>Options...</Trans>
      )}
    </components.Placeholder>
  )
}

const PointerWrapper = styled.div`
  cursor: pointer;
`

const StyledLabel = styled.label`
  ${BodySemiboldStyle};
  color: ${props => props.theme.colors.neutral600};
`

export const Select: FunctionComponent<SelectProps> = props => {
  const customStyles = {
    container: provided => ({
      ...provided,
      height: '40px',
      borderRadius: '0',
      fontSize: '14px',
      fontWeight: '400',
      '&:focus': {
        backgroundColor: theme.colors.blue200
      }
    }),
    control: (provided, state) => ({
      ...provided,
      maxHeight: '40px',
      height: '40px',
      boxShadow: state.isFocused
        ? '0 0 2px 1px' + theme.colors.blue500
        : 'none',
      border: '1px solid ' + theme.colors.neutral400
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'red'
    }),
    option: (provided, state) =>
      state.isMulti
        ? {
            ...provided,
            backgroundColor: theme.colors.white,
            cursor: 'pointer'
          }
        : {
            ...provided,
            backgroundColor: state.isSelected
              ? theme.colors.neutral700
              : provided.backgroundColor,
            cursor: 'pointer',
            '&:focus': {
              backgroundColor: theme.colors.blue200
            }
          },
    menu: provided => ({
      ...provided,
      marginTop: '4px'
    }),
    multiValueRemove: provided => ({
      ...provided,
      '&:hover': {
        color: theme.colors.black,
        backgroundColor: theme.colors.blue500
      }
    }),
    multiValue: provided => ({
      ...provided,
      boxSizing: 'content-box',
      margin: '0 8px'
    }),
    valueContainer: provided => ({
      ...provided,
      marginTop: '-2px'
    }),
    indicatorContainer: provided => ({
      ...provided,
      marginTop: '-2px'
    })
  }

  const DropdownIndicator = dropdownProps => {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        {dropdownProps.selectProps.icon ? (
          <PointerWrapper>
            {dropdownProps.selectProps.icon.render()}
          </PointerWrapper>
        ) : (
          <PointerWrapper>
            <Chevron
              color={theme.colors.neutral700}
              direction={dropdownProps.selectProps.menuIsOpen ? 'up' : 'down'}
              data-qa={'DropdownFilterChevron'}
            />
          </PointerWrapper>
        )}
      </components.DropdownIndicator>
    )
  }

  return (
    <StyledContainer
      fluid={props.fluid}
      width={props.width}
      data-qa={props.dataQa}
    >
      <StyledLabel>
        {props.children}
        <ReactSelect
          {...props}
          styles={customStyles}
          components={{
            DropdownIndicator,
            ClearIndicator,
            NoOptionsMessage,
            Placeholder,
            ValueContainer,
            Option
          }}
          isSearchable={props.isSearchable ? props.isSearchable : false}
        />
      </StyledLabel>
    </StyledContainer>
  )
}

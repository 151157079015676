import React from 'react'

import styled from '@emotion/styled'
import { msg } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { RowContainer } from '@lastpass/lastkit'

import { createSecurityScoreDataComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/SecurityScoreData'
import { createSecurityScoreHistoryChartComponent } from '@lastpass/admin-console-dependencies/ui/dashboard/SecurityScoreHistoryChart'
import { BlockHeader } from '@lastpass/admin-console-dependencies/ui/home/BlockHeader'

const FirstColumn = styled.div`
  width: 500px;
`

const SecondColumn = styled.div`
  flex-grow: 1;
  margin-left: 24px;
`

const SecurityScoreData = createSecurityScoreDataComponent<AppState>()
const SecurityScoreHistoryChart = createSecurityScoreHistoryChartComponent<
  AppState
>()

export const UserSecurityBlock: React.FunctionComponent = () => {
  return (
    <>
      <BlockHeader
        title={msg`User security`}
        helperTooltip={msg`
              How secure are your users? Are they using strong passwords? Are they following best practices for their master password?`}
      />
      <RowContainer data-qa="UserSecurityBlock">
        <FirstColumn>
          <SecurityScoreData />
        </FirstColumn>
        <SecondColumn>
          <SecurityScoreHistoryChart />
        </SecondColumn>
      </RowContainer>
    </>
  )
}

import { call, put } from 'redux-saga/effects'

import * as UACServices from '@lastpass/admin-console-dependencies/server'
import { genericFailedNotification } from '@lastpass/admin-console-dependencies/server/responses'
import { globalActions } from '@lastpass/admin-console-dependencies/state/global/actions'
import { userDrawerActions } from '@lastpass/admin-console-dependencies/state/users/view/drawer/actions'

export function createUsersTrustedDevicesSaga(
  userService: UACServices.Services
) {
  return function* getUserTrustedDevicesSaga(
    action: ReturnType<typeof userDrawerActions.getUserTrustedDevices>
  ) {
    try {
      const id = action.payload.params.path.id
      if (id) {
        yield put(userDrawerActions.setUserTrustedDevicesLoading(true))
        try {
          const result: UACServices.UserTrustedDevicesAPI.Responses = yield call(
            userService.userTrustedDevices,
            id
          )
          if (result.type === UACServices.UserTrustedDevicesAPI.SUCCESS) {
            const devices = result.body
            yield put(userDrawerActions.setUserTrustedDevices(devices))
          }
        } finally {
          yield put(userDrawerActions.setUserTrustedDevicesLoading(false))
        }
      }
    } catch (e) {
      yield put(globalActions.setNotification(genericFailedNotification))
    }
  }
}

import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { AppState } from '@lastpass/admin-console/src/app-store'
import { ReactComponent as More } from '@lastpass/assets/svg/admin-console/icon-more.svg'
import {
  Divider,
  Dropdown,
  DropdownListItem,
  IconButton
} from '@lastpass/lastkit/components'
import { LocationLink } from '@lastpass/routing'
import { usePathParams } from '@lastpass/routing/hooks/use-path-params'

import { GroupDrawerState } from '@lastpass/admin-console-dependencies/state/users/groups/drawer/state'
import { groupOperationActions } from '@lastpass/admin-console-dependencies/state/users/groups/operations/actions'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { AdminPermissions } from '@lastpass/admin-console-dependencies/types/admin-permissions'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { PermissionContext } from '@lastpass/admin-console-dependencies/ui/common/PermissionContext'

interface UserOperationsDropdownProps {
  editGroupLink: string
}

const StyledIconButton = styled(IconButton)`
  margin-left: 15px;
`

const StyledDropdown = styled(Dropdown)`
  z-index: 2;
`

const MoreButton = props => (
  <StyledIconButton
    icon={More}
    {...props}
    data-qa="GroupOperationsDropdownButton"
  />
)

export const GroupOperationsDropdown: React.FunctionComponent<UserOperationsDropdownProps> = ({
  editGroupLink
}) => {
  const dispatch = useDispatch()

  const isForceVaultReEncryptionEnabled = useFeatureFlags(
    FeatureFlags.isForceVaultReEncryptionEnabled
  )

  const groupDrawerState: GroupDrawerState = useSelector(
    (state: AppState) => state.groupDrawer
  )
  const currentAdminLevel = useSelector(
    (state: AppState) => state.usersList.currentAdminLevel
  )
  const hasFederatedUsers = useSelector(
    (state: AppState) => state.groupDrawer.hasFederatedUsers
  )

  const permissions = useContext(PermissionContext)
  const pathParams = usePathParams()

  const canModifyGroups = permissions.requirePermission(
    AdminPermissions.groupsModify
  )
  const canModifyUsers = permissions.requirePermission(
    AdminPermissions.usersModify
  )
  const canDestroySession = permissions.requirePermission(
    AdminPermissions.usersSessionsDestroy
  )
  const canModifyUsersMasterPasswordReset = permissions.requirePermission(
    AdminPermissions.usersPasswordsSettingsModify,
    AdminPermissions.usersMasterPasswordReset
  )
  const canMfaModify = permissions.requirePermission(
    AdminPermissions.userLevelMfaModify
  )

  const canSelectForVaultReEncryption =
    isForceVaultReEncryptionEnabled &&
    hasFederatedUsers &&
    currentAdminLevel === AdminLevel.superAdmin

  const hasAnyPermissions =
    canMfaModify ||
    canModifyGroups ||
    canModifyUsers ||
    canDestroySession ||
    canModifyUsersMasterPasswordReset
  const hasAnyUsers = groupDrawerState.users.totalResults > 0

  return hasAnyPermissions ? (
    <StyledDropdown button={MoreButton}>
      {hasAnyUsers && (
        <>
          {canModifyUsers && (
            <DropdownListItem
              onClick={() => {
                pathParams.id &&
                  dispatch(
                    groupOperationActions.inviteUsers(groupDrawerState.group.id)
                  )
              }}
              data-qa="GroupOperationInviteUsers"
            >
              <Trans>Invite</Trans>
            </DropdownListItem>
          )}
          {canMfaModify && (
            <DropdownListItem
              onClick={() => {
                pathParams.id &&
                  dispatch(
                    groupOperationActions.resendMfaInvitations(
                      groupDrawerState.group.id
                    )
                  )
              }}
              data-qa="GroupOperationResendInvitation"
            >
              <Trans>Resend invitations for passwordless</Trans>
            </DropdownListItem>
          )}
          {canModifyUsersMasterPasswordReset && (
            <DropdownListItem
              onClick={() => {
                pathParams.id &&
                  dispatch(
                    groupOperationActions.requireMpChange(
                      groupDrawerState.group.id
                    )
                  )
              }}
              data-qa="GroupOperationRequireMpChange"
            >
              <Trans>Require master password change</Trans>
            </DropdownListItem>
          )}
          {canSelectForVaultReEncryption && (
            <DropdownListItem
              onClick={() => {
                pathParams.id &&
                  dispatch(
                    groupOperationActions.vaultReEncryption(
                      groupDrawerState.group.id
                    )
                  )
              }}
              data-qa="GroupOperationVaultReEncryption"
            >
              <Trans>Force vault re-encryption</Trans>
            </DropdownListItem>
          )}
          {canMfaModify && (
            <DropdownListItem
              onClick={() => {
                pathParams.id &&
                  dispatch(
                    groupOperationActions.disableMfa(groupDrawerState.group.id)
                  )
              }}
              data-qa="GroupOperationDisableMfa"
            >
              <Trans>Disable multifactor authentication</Trans>
            </DropdownListItem>
          )}
          {canDestroySession && (
            <DropdownListItem
              onClick={() => {
                pathParams.id &&
                  dispatch(
                    groupOperationActions.destroySessions(
                      groupDrawerState.group.id
                    )
                  )
              }}
              data-qa="GroupOperationDestroySessions"
            >
              <Trans>Destroy all sessions</Trans>
            </DropdownListItem>
          )}
          {canModifyUsers && (
            <>
              <DropdownListItem
                onClick={() => {
                  pathParams.id &&
                    dispatch(
                      groupOperationActions.disableUsers(
                        groupDrawerState.group.id
                      )
                    )
                }}
                data-qa="GroupOperationDisableUser"
              >
                <Trans>Disable users</Trans>
              </DropdownListItem>
              <DropdownListItem
                red
                onClick={() => {
                  pathParams.id &&
                    dispatch(
                      groupOperationActions.deleteUsers(
                        groupDrawerState.group.id
                      )
                    )
                }}
                data-qa="GroupOperationDeleteUsers"
              >
                <Trans>Delete users</Trans>
              </DropdownListItem>
              <DropdownListItem
                red
                onClick={() => {
                  pathParams.id &&
                    dispatch(
                      groupOperationActions.removeFromCompany(
                        groupDrawerState.group.id
                      )
                    )
                }}
                data-qa="GroupOperationRemoveFromCompany"
              >
                <Trans>Remove from company</Trans>
              </DropdownListItem>
            </>
          )}
          {canModifyGroups && <Divider />}
        </>
      )}
      {canModifyGroups && (
        <LocationLink to={editGroupLink}>
          <DropdownListItem onClick={() => {}}>
            <Trans>Edit group name</Trans>
          </DropdownListItem>
        </LocationLink>
      )}
      {canModifyGroups && (
        <DropdownListItem
          red
          onClick={() => {
            pathParams.id &&
              dispatch(
                groupOperationActions.deleteGroups([groupDrawerState.group])
              )
          }}
          data-qa="GroupOperationDropDownItem"
        >
          <Trans>Delete group</Trans>
        </DropdownListItem>
      )}
      {!hasAnyUsers && !canModifyGroups && (
        <DropdownListItem key="noAction" noClick={true}>
          <Trans>No actions available</Trans>
        </DropdownListItem>
      )}
    </StyledDropdown>
  ) : (
    <StyledDropdown button={MoreButton} onInitialExpand={() => {}}>
      <DropdownListItem key="noAction" noClick={true}>
        <Trans>No actions available</Trans>
      </DropdownListItem>
    </StyledDropdown>
  )
}

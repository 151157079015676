import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Namespace } from '@lastpass/admin-console/src/pages/search-namepaces'
import {
  DrawerButtonsContainer,
  FooterContainer,
  PrimaryButton,
  StyledSpacer,
  TextButton
} from '@lastpass/lastkit'
import { LocationLink } from '@lastpass/routing'
import { useQueryParams } from '@lastpass/routing/hooks/use-query-params'
import { useUpdateQuery } from '@lastpass/routing/hooks/use-update-query'
import { connectRedux, LastPassComponentProps } from '@lastpass/ui'

import { usePasswordlessPoliciesEmptyState } from '@lastpass/admin-console-dependencies/hooks/use-passwordless-policies-empty-state'
import { CompanyInfoState } from '@lastpass/admin-console-dependencies/state/company/state'
import { passwordlessActions } from '@lastpass/admin-console-dependencies/state/policies/passwordless/container/actions'
import { userListActions } from '@lastpass/admin-console-dependencies/state/users/view/list/actions'
import { AccountStatus } from '@lastpass/admin-console-dependencies/types/account-status'
import { AdminLevel } from '@lastpass/admin-console-dependencies/types/admin-level'
import { FederatedStatus } from '@lastpass/admin-console-dependencies/types/federated-status'
import { MFAStatus } from '@lastpass/admin-console-dependencies/types/mfa-status'
import { SecurityScoreLevel } from '@lastpass/admin-console-dependencies/types/security-score-level'
import { User } from '@lastpass/admin-console-dependencies/types/user'
import {
  FeatureFlags,
  useFeatureFlags
} from '@lastpass/admin-console-dependencies/ui/common/FeatureFlags'
import { SelectUserGroups } from '@lastpass/admin-console-dependencies/ui/common/SelectUserGroups'
import { SelectIsCreatedByCompany } from '@lastpass/admin-console-dependencies/ui/users/view/filter/SelectIsCreatedByCompany'

import { SelectAccountStatus } from './SelectAccountStatus'
import { SelectAdminLevel } from './SelectAdminLevel'
import { SelectFederatedStatus } from './SelectFederatedStatus'
import { SelectLastInvitationDate } from './SelectLastInvitationDate'
import { SelectMfaStatus } from './SelectMfaStatus'
import { SelectSecurityScore } from './SelectSecurityScore'

type FilterUserListProps = LastPassComponentProps<
  { isFederatedLoginEnabled: boolean },
  typeof userListActions,
  {}
>

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const InputContainer = styled.div`
  flex-basis: calc(50% - 12px);
  margin-right: 12px;
  margin-bottom: 28px;

  &:nth-of-type(2n) {
    flex-basis: calc(50% - 12px);
    margin-right: 0;
    margin-left: 12px;
  }
`

export const FilterUserListDrawerPage: React.FunctionComponent<FilterUserListProps> = ({
  actions,
  state
}) => {
  const history = useHistory()

  const updateQuery = useUpdateQuery(Namespace.users)
  const queryParams = useQueryParams(Namespace.users)

  const isNpPasswordlessFeatureDisabled = useFeatureFlags(
    FeatureFlags.isNpPasswordlessFeatureDisabled
  )

  const isPasswordlessPoliciesEmpty = usePasswordlessPoliciesEmptyState()

  const isPasswordlessEnabledOrUsed =
    !isNpPasswordlessFeatureDisabled || !isPasswordlessPoliciesEmpty

  const [accountStatusFilters, setAccountStatusFilters] = useState<
    AccountStatus[] | undefined
  >(queryParams.filter && queryParams.filter.accountStatus)

  const [mfaStatusFilters, setMfaStatusFilters] = useState<
    MFAStatus[] | undefined
  >(queryParams.filter && queryParams.filter.mfaStatus)

  const [adminLevelFilters, setAdminLevelFilters] = useState<
    AdminLevel[] | undefined
  >(queryParams.filter && queryParams.filter.adminLevelId)

  const [securityScoreFilters, setSecurityScoreFilters] = useState<
    SecurityScoreLevel[] | undefined
  >(queryParams.filter && queryParams.filter.securityScore)

  const [lastInvitationDateFilter, setLastInvitationDateFilter] = useState<
    Date[] | undefined
  >(queryParams.filter && queryParams.filter.inviteEmailSentTimeUtc)

  const [federatedStatusFilters, setFederatedStatusFilters] = useState<
    FederatedStatus[] | undefined
  >(queryParams.filter && queryParams.filter.provisionFederatedStatus)

  const [groupFilters, setGroupFilters] = useState<string[] | undefined>(
    queryParams.filter && queryParams.filter.groupId
  )

  const [isCreatedByCompanyFilter, setIsCreatedByCompanyFilter] = useState<
    [User['isCreatedByCompany']] | undefined
  >(queryParams.filter && queryParams.filter.isCreatedByCompany)

  const dispatch = useDispatch()
  useEffect(() => {
    if (queryParams.filter) {
      setAccountStatusFilters(queryParams.filter.accountStatus)
      setMfaStatusFilters(queryParams.filter.mfaStatus)
      setAdminLevelFilters(queryParams.filter.adminLevelId)
      setSecurityScoreFilters(queryParams.filter.securityScore)
      setLastInvitationDateFilter(queryParams.filter.inviteEmailSentTimeUtc)
      setFederatedStatusFilters(queryParams.filter.provisionFederatedStatus)
      setGroupFilters(queryParams.filter.groupId)
      setIsCreatedByCompanyFilter(queryParams.filter.isCreatedByCompany)
    }
    dispatch(passwordlessActions.fetchInitialData())
  }, [dispatch, queryParams])

  const isFederatedLoginStatusesEnabled =
    useFeatureFlags(FeatureFlags.isFederatedLoginStatusesEnabled) &&
    state.isFederatedLoginEnabled

  const isUserGroupFilteringEnabled = useFeatureFlags(
    FeatureFlags.isUserGroupFilteringEnabled
  )

  const isCreatedByCompanyColumnEnabled = useFeatureFlags(
    FeatureFlags.isCreatedByCompanyColumnEnabled
  )

  return (
    <>
      <FlexBox>
        <InputContainer>
          <SelectAccountStatus
            updateSelection={setAccountStatusFilters}
            filters={accountStatusFilters}
          />
        </InputContainer>
        {isPasswordlessEnabledOrUsed && (
          <InputContainer>
            <SelectMfaStatus
              updateSelection={setMfaStatusFilters}
              filters={mfaStatusFilters}
            />
          </InputContainer>
        )}
        <InputContainer>
          <SelectAdminLevel
            updateSelection={setAdminLevelFilters}
            filters={adminLevelFilters}
          />
        </InputContainer>
        <InputContainer>
          <SelectSecurityScore
            updateSelection={setSecurityScoreFilters}
            filters={securityScoreFilters}
          />
        </InputContainer>
        <InputContainer>
          <SelectLastInvitationDate
            updateSelection={setLastInvitationDateFilter}
            filters={lastInvitationDateFilter}
          />
        </InputContainer>
        {isFederatedLoginStatusesEnabled && (
          <InputContainer>
            <SelectFederatedStatus
              updateSelection={setFederatedStatusFilters}
              filters={federatedStatusFilters}
            />
          </InputContainer>
        )}
        {isUserGroupFilteringEnabled && (
          <InputContainer>
            <SelectUserGroups
              updateSelection={setGroupFilters}
              filters={groupFilters}
            />
          </InputContainer>
        )}
        {isCreatedByCompanyColumnEnabled && (
          <InputContainer>
            <SelectIsCreatedByCompany
              updateSelection={setIsCreatedByCompanyFilter}
              filters={isCreatedByCompanyFilter}
            />
          </InputContainer>
        )}
      </FlexBox>

      <StyledSpacer />
      <FooterContainer>
        <DrawerButtonsContainer>
          <LocationLink to={`/users/view`}>
            <TextButton
              css={css`
                margin-right: 10px;
              `}
              data-qa={'DiscardChangesButton'}
            >
              <Trans>Cancel</Trans>
            </TextButton>
          </LocationLink>

          <PrimaryButton
            onClick={() => {
              actions.setSelectAllUsers(false)
              updateQuery({
                ...queryParams,
                filter: {
                  accountStatus: accountStatusFilters,
                  mfaStatus: mfaStatusFilters,
                  adminLevelId: adminLevelFilters,
                  securityScore: securityScoreFilters,
                  inviteEmailSentTimeUtc: lastInvitationDateFilter,
                  provisionFederatedStatus: federatedStatusFilters,
                  groupId: groupFilters,
                  isCreatedByCompany: isCreatedByCompanyFilter
                },
                skip: undefined
              })
              history.push({
                pathname: '/users/view',
                search: location.search
              })
            }}
            type="submit"
          >
            <Trans>Apply filters</Trans>
          </PrimaryButton>
        </DrawerButtonsContainer>
      </FooterContainer>
    </>
  )
}

export function createFilterUserListDrawerPage<
  TState extends {
    companyInfo: CompanyInfoState
  }
>() {
  return connectRedux(
    FilterUserListDrawerPage,
    (state: TState) => {
      return {
        isFederatedLoginEnabled:
          state.companyInfo.details.isFederatedLoginEnabled
      }
    },
    { ...userListActions }
  )
}

export type FilterUserListDrawerPage = ReturnType<
  typeof createFilterUserListDrawerPage
>
